import React, {useEffect, useState} from "react";
import {Tabs, Select, Button, Input, Rate, InputNumber, Result, Checkbox, Radio, Switch} from "antd";
import Title from "antd/lib/typography/Title";
import {safeGet} from "../../Lib/Dal";
import {useTranslation} from "react-i18next";
import CustomForm from "../Shared/CustomForm";
import DatePickerW from "../Shared/DatePickerW";

const ClaimInfoStep = (props) => {
    const [currentStep, setCurrentStep] = useState(undefined);
    const [t]=useTranslation();
    const [formValues,setFormValues]=useState({});
    const [loading,setLoading]=useState(false)

    useEffect(()=>{
        if(steps&&steps.length>0) setCurrentStep(steps[0].name)
    },[])

    useEffect(()=>{
        setLoading(props.loading);
    },[props.loading])

    const onContinue = (currentIndex,step) => {

        //parsing values that need conversion
        if(step.control==="form"){
            formValues["full"+step.name]=window[step.name].userData;
            formValues[step.name]={};
            window[step.name].userData.forEach(item=>{
                if(item.userData) formValues[step.name][item.name]=item.userData[0];
            });
        }
        console.log(formValues);

        //next step
        if (currentIndex===steps.length-1) {
            setLoading(true)
            props.onFinish(formValues);
        }else setCurrentStep(steps[currentIndex+1].name);
    };

    const renderControl=(controlName,step)=>{
        switch (controlName){
            case "date":
                return <DatePickerW size={"large"} value={formValues[step.name]} onChange={v=>setFormValues({...formValues,[step.name]:v})} />;
            case "gender":
                return   <Radio.Group size="large" value={formValues[step.name]} onChange={v=>setFormValues({...formValues,[step.name]:v.target.value})}>
                    <Radio.Button value="M">{t("Male")}</Radio.Button>
                    <Radio.Button value="F">{t("Female")}</Radio.Button>
                </Radio.Group>
            case "yesno":
                return <Switch checkedChildren="Yes" unCheckedChildren="No" checked={formValues[step.name]} onChange={v=>setFormValues({...formValues,[step.name]:v})} />;
            case "rating":
                return <Rate value={formValues[step.name]} onChange={v=>setFormValues({...formValues,[step.name]:v})} />
            case "number":
                return <InputNumber value={formValues[step.name]} onChange={v=>setFormValues({...formValues,[step.name]:v})} />
            case "form":
                return <CustomForm formId={step.formId} variable={step.name} />
            default:
                return <Input value={formValues[step.name]} onChange={v=>setFormValues({...formValues,[step.name]:v})} />
        }
    }

    const steps=safeGet(["SelfService","Claim","Steps"],props.productConfig,[]);
    if(steps.length===0) return <Result
        status="500"
        title="500"
        subTitle={t("Sorry, no configuration found for this product")}
        extra={<Button type="primary" onClick={()=>window.location="#/SelfService/home"}>{t("Back Home")}</Button>}
    />
    return (
        <div>
            <Tabs tabPosition="right" activeKey={currentStep} onTabClick={(key) => setCurrentStep(key)}>
                {steps.map((step,index)=><Tabs.TabPane tab={step.title} key={step.name}>
                    <Title>{step.text}</Title>
                    {renderControl(step.control,step)}
                    <br/>
                    <Button size="large" type="primary" style={{ marginTop: 20 }} onClick={() => onContinue(index,step)} loading={loading}>
                        Continue
                    </Button>
                </Tabs.TabPane>)}
            </Tabs>
        </div>
    );
};

export default ClaimInfoStep
