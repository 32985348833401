import { Button, Popconfirm, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exe } from '../../Lib/Dal';
import moment from 'moment';
import config from "../../config";
import { DeleteOutlined } from '@ant-design/icons';

const ContactIssueDocs=props=>{
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [t]=useTranslation();

    useEffect(()=>{
        if(props.contactId&&props.issueId) load(); else setData([]);
    },[props.contactId,props.issueId]);

    const load=()=>{
        if(props.contactId&&props.issueId){
            setLoading(true);
            exe("RepoContactDocument",{operation:"GET",filter:`contactId=${props.contactId} and issueId=${props.issueId}`}).then(r=>{
                setLoading(false);
                if(r.ok) setData(r.outData);
                else message.error(r.msg);
            })
        }
    }
    const onOpenFile = (v) => {
        try {
          fetch(config.ssApiUrl + "/proxy" + v, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.token,
            },
          })
            .then((response) => response.blob())
            .then((data) => window.open(URL.createObjectURL(data)))
            .catch((err) => console.log(err));
        } catch (error) {
          console.log(error);
        }
      };
      const onDeleteFile = (record) => {
        setLoading(true);
        exe("RepoContactDocument", { operation: "DELETE", entity: record }).then((r) => {
          setLoading(false);
          if (r.ok) {
            message.success(t("Record deleted"));
            load(props.contactId);
          }else {
            message.error(r.msg);
          }
        });
      }

    return  <div>
        {data.length>0&&<Table dataSource={data} scroll={{ x: true }} rowKey="id" pagination={false} loading={loading}>
    <Table.Column title={t("ID")} dataIndex="id" />
    <Table.Column
      title={t("File")}
      dataIndex="url"
      render={(v, r) => (
        <Button type="link" onClick={() => onOpenFile(r.url)}>
          {t("Open")}
        </Button>
      )}
    />
    <Table.Column title={t("File Name")} dataIndex="fileName" />
    <Table.Column title={t("Created")} dataIndex="created" render={(t) => moment.utc(t).fromNow()} />
    <Table.Column title={t("Actions")} key="actions" render={(r) =><div>
      <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={()=>onDeleteFile(r)}>
        <Button type="link" icon={<DeleteOutlined />}  />
      </Popconfirm>
      </div>} />
  </Table>}
  </div>
}
export default ContactIssueDocs;