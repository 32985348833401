import React, {useEffect, useState} from "react";
import {
    CodeOutlined,
    CoffeeOutlined,
    FileOutlined,
    FormOutlined,
    HomeOutlined,
    RedoOutlined,
    RocketOutlined,
    SaveOutlined,
    TeamOutlined,
    UndoOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Table,
    Select,
    Tabs,
    Row,
    Col,
    Input,
    message,
    Radio,
    Modal,
    Dropdown,
    Menu,
    Tag,
    notification,
    Switch,
    Alert,
    InputNumber,
} from "antd";
import yaml from "js-yaml";
import {exe} from "Lib/Dal";
import moment from "moment";
import Status from "../Shared/Status";
import DefaultPage from "../Shared/DefaultPage";
import Title from "antd/lib/typography/Title";
import Currency from "../Shared/Currency";
import DistributionSimulator from "../Contract/DistributionSimulator";
import Accounts from "../Life/Accounts";
import {withTranslation} from "react-i18next";
import Participants from "../Contract/Participants";
import NewCurrentAccount from "../Contract/NewCurrentAccount";
import AccountSelect from "../Shared/AccountSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import FormSelect from "../Property/FormSelect";
import CustomForm from "../Shared/CustomForm";
import CoContractParticipants from "./CoContractParticipants";
import DatePickerW from "../Shared/DatePickerW";
import CodeEditorW from "../Shared/CodeEditorW";

const ButtonGroup = Button.Group;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const {Column, ColumnGroup} = Table;
const {TextArea} = Input;

const CoContract = props => {
    const {t, i18n, match} = props;
    const [contract, setContract] = useState({});
    const [isPerson, setIsPerson] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeKeyTab, setActiveKeyTab] = useState("1");
    const [simulatorVisible, setSimulatorVisible] = useState(false);
    const [newAccountVisible, setNewAccountVisible] = useState(false);
    const [proportional, setProportional] = useState(true);
    const [configJson, setConfigJson] = useState("");

    useEffect(() => {
        load(match.params.contractId);
    }, [match.params.contractId]);
    const save = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                //extra fields
                values.config = window.global.myCodeMirror.getValue();
                //yaml validation
                try {
                    const result = yaml.safeLoad(values.config);
                    values.configJson = JSON.stringify(result);
                } catch (error) {
                    notification["error"]({
                        message: error.reason,
                        description: error.message,
                    });
                    return;
                }
                //custom form
                if (values.formId && window.customReForm && window.customReForm.instanceContainers[0]) {
                    if (window.customReForm.instanceContainers[0].reportValidity()) {
                        values.jFormValues = JSON.stringify(window.customReForm.userData);
                    } else {
                        message.error(t("Please check custom form validation errors"));
                        setActiveKeyTab("customForm");
                        return;
                    }
                }

                //validation OK
                values.id = props.match.params.contractId;
                console.log("Received values of form: ", values);

                setLoading(true)
                const operation= values.id==0?"ADD":"UPDATE";
                exe("RepoCoContract", {operation,entity:values}).then((r) => {
                    setLoading(false)
                    if (r.ok) {
                        message.success(r.msg);
                        props.form.setFieldsValue(r.outData[0]);
                        if (operation === "ADD") window.location.hash = "#/coContract/" + r.outData[0].id;
                    } else {
                        message.error(r.msg);
                    }
                });
            } else {
                //validation error
                console.warn("Validation error: ", err);
            }
        });
    };
    const onSimulate = () => {
        const values = {};
        //extra fields
        values.config = window.global.myCodeMirror.getValue();
        //yaml validation
        try {
            const result = yaml.safeLoad(values.config);
            values.configJson = JSON.stringify(result);
            setConfigJson(values.configJson);
            setSimulatorVisible(true);
        } catch (error) {
            notification["error"]({
                message: error.reason,
                description: error.message,
            });
            return;
        }
    };
    const onNew = () => {
        window.location.hash = "#/coContract/0";
        setIsPerson(true);
        setContract({});
    };
    const reset = () => {
        props.form.resetFields();
    };
    const clone = () => {
        setLoading(true)
        exe("CloneContract", {contractId: props.match.params.contractId}).then((r) => {
            setLoading(false)
            if (r.ok) {
                message.success(r.msg);
                window.location.hash = "#/contract/" + r.outData.contract.id;
            } else {
                message.error(r.msg);
            }
        });
    };
    const load = (contractId) => {
        if (contractId == 0) {
            props.form.resetFields();
            return;
        }
        setLoading(true)
        exe("RepoCoContract", {operation:"GET",
            filter: "id=" + contractId,
            include: ["Contact", "Process"],
        }).then((r) => {
            setLoading(false)
            if (r.ok) {
                const d = r.outData[0];
                if (!d) {
                    message.error(r.msg);
                } else {
                    d.createdDate = moment(d.createdDate);
                    if (d.effectiveDate) d.effectiveDate = moment(d.effectiveDate);
                    if (d.endDate) d.endDate = moment(d.endDate);
                    if (d.nextAccountDate) d.nextAccountDate = moment(d.nextAccountDate);

                    //extra fields
                    if (r.outData[0].config) window.global.myCodeMirror.setValue(r.outData[0].config);

                    props.form.setFieldsValue(d);

                    //triggering onchange events
                    changeIsProportional(d.proportional);

                    setContract(d);
                }
            } else {
                message.error(r.msg);
            }
        });
    };

    const activateContract = () => {
        const {t, i18n} = props;
        const openModal = Modal.info({
            title: t("Activation"),
            maskClosable: true,
            onOk: activate,
            content: (
                <div>
                    <DatePickerW/>
                    <Select
                        style={{width: "100%", marginTop: 5}}
                        placeholder={t("Activation mode")}
                        onSelect={(id) => {
                            //openModal.destroy();
                        }}>
                        <Select.Option key="a">{t("Traditional")}</Select.Option>
                        <Select.Option key="b">{t("Special")}</Select.Option>
                    </Select>
                    <Input style={{marginTop: 5}} placeholder={t("Signature")}></Input>
                </div>
            ),
        });

    };
    const activate = () => {
        exe("ActivateContract", {contractId: contract.id, next: true}, null, true).then((r) => {
            if (r.ok) reload();
        });
    };
    const reload = () => {
        load(contract.id);
    }
    const onTemplateClick = (v) => {
        let templateValue;
        switch (v.key) {
            case "quotashare":
                templateValue = `#Quota Share 20-80 limit=10 000 0000 
Lines:
  - ced: Math.min(_cov.limit*0.2,2500000)
    re: Math.min(_cov.limit*0.8,10000000)
    commission: 0.15*_re
    id: Layer 0 QS`;
                break;
            case "surplus":
                templateValue = `#Surplus 20 lines, retention 1 000 000
Lines:
  - ced: Math.min(_cov.limit,1000000)
    re: Math.min(_rsi,20000000)
    commission: 0.15*_re
    id: Layer 0 QS`;
                break;
            case "mixed":
                templateValue = `#Retention=1 0000 000, Quota Share 10-90 limit=9 000 0000 , 1st surplus 10 lines, 2nd surplus 10 lines, fac-man 5 000 000
Lines:
  - ced: Math.min(_cov.limit*0.1,1000000)
    re: Math.min(_cov.limit*0.9,9000000)
    commission: 0.15*_re
    id: Layer 0 QS
  - ced: 0
    re: Math.min(_rsi,10000000)
    commission: 0.15*_re
    id: Layer 1 1st surplus
  - ced: 0
    re: Math.min(_rsi,10000000)
    commission: 0.15*_re
    id: Layer 2 2nd surplus
  - ced: 0
    re: Math.min(_rsi,5000000)
    commission: 0.15*_re
    id: Layer 3 Facultative-Mandatory`;
                break;
        }

        window.global.myCodeMirror.setValue(templateValue);
    }
    const onNewAccount = () => {
        setNewAccountVisible(false);
        reload();
    }
    const changeIsProportional = (value) => {
        setProportional(value);
    }

    const {getFieldDecorator} = props.form;
    const contractId = props.match.params.contractId;
    const contactFullName = contract.Contact && contract.Contact.FullName;
    const entityState = contract.Process ? contract.Process.entityState : "";
    const currency = props.form.getFieldValue("currency");
    const values = props.form.getFieldsValue();

    return (
        <div style={{}}>
            <DefaultPage
                routes={{
                    routes: [
                        {breadcrumbName: t("Home"), path: "/"},
                        {breadcrumbName: t("Contract List"), path: "coContractList"},
                        {breadcrumbName: t("Coinsurance Contract"), path: ""},
                    ],
                }}
                title={values.name ?<span>{`${t("Contract")} ${values.name}`}{entityState&&<Tag>{entityState}</Tag>}</span>: t("New Coinsurance Contract")}
                icon="file-protect"
                tags={<Status process={contract.Process} reload={() => reload()}/>}
                loading={loading}
                extra={
                    <div>
                        <ButtonGroup>
                            <Button onClick={() => reload()} icon={<RedoOutlined />}>
                                {t("Reload")}
                            </Button>
                            {/*<Button onClick={clone} icon="copy">*/}
                            {/*    {t("Clone")}*/}
                            {/*</Button>*/}
                            <Button onClick={onNew} icon={<FileOutlined />}>
                                {t("New")}
                            </Button>
                            <Button onClick={save} icon={<SaveOutlined />} type="primary">
                                {t("Save")}
                            </Button>
                            <Button onClick={reset} icon={<UndoOutlined />}>
                                {t("Reset")}
                            </Button>
                        </ButtonGroup>
                    </div>
                }>
                <Form layout="vertical">
                    <Tabs defaultActiveKey="1" activeKey={activeKeyTab} onTabClick={(tab) => setActiveKeyTab(tab)}>
                        <Tabs.TabPane
                            tab={<span><HomeOutlined />{t("Main")}</span>}
                            key="1">
                            <Row gutter={16}>
                                {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                                <Col span={12}>
                                    {getFieldDecorator("jFormValues")}
                                    {getFieldDecorator("processId")}
                                    <Form.Item label={t("Contract Name")}>
                                        {getFieldDecorator("name", {
                                            rules: [{required: true}],
                                        })(<Input placeholder={t("Contract Name")}/>)}
                                    </Form.Item>

                                    <Form.Item label={values.inward ? t("Cedant") : t("Lead Reinsurer")}>
                                        {getFieldDecorator("contactId", {
                                            rules: [{required: true}],
                                        })(<SimpleContactSelect allowNew/>)}
                                    </Form.Item>
                                    <Form.Item label={t("Code")}>
                                        {getFieldDecorator("code", {
                                            rules: [{required: true}],
                                        })(<Input/>)}
                                    </Form.Item>
                                    <Form.Item label={t("Description")}>
                                        {getFieldDecorator("description", {})(<TextArea rows={4}
                                                                                        placeholder={t("Free text contact description")}/>)}
                                    </Form.Item>

                                </Col>
                                {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
                                <Col span={12}>
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <Form.Item label={t("Direction")}>
                                                {getFieldDecorator("inward", {
                                                    rules: [{required: true}],
                                                })(
                                                    <RadioGroup name="radiogroup" type="">
                                                        <Radio.Button value={true}>{t("Inward")}</Radio.Button>
                                                        <Radio.Button value={false}>{t("Outward")}</Radio.Button>
                                                    </RadioGroup>
                                                )}
                                            </Form.Item>
                                            
                                            <Form.Item label={t("Operating Account")}>
                                                {getFieldDecorator("operatingAccountId", {rules: [{required: false}]})(
                                                    <AccountSelect/>)}
                                            </Form.Item>
                                            <Form.Item label={t("Created")}>{getFieldDecorator("createdDate", {})(
                                                <DatePickerW style={{width: "100%"}}/>)}
                                            </Form.Item>
                                            <Form.Item label={t("Paid on collection")}>
                                                {getFieldDecorator("paidOnCollection", {valuePropName: "checked"})(<Switch/>)}
                                            </Form.Item>
                                           
                                        </Col>
                                        {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                                        <Col span={12}>
                                            <div style={{display: "flex"}}>
                                                <Form.Item label={t("System ID")}>{getFieldDecorator("id", {})(<Input
                                                    readOnly/>)}</Form.Item>
                                                <Form.Item label={t("Active")}
                                                           style={{marginBottom: 4, marginLeft: 10}}>
                                                    {getFieldDecorator("active", {valuePropName: "checked"})(<Switch/>)}
                                                </Form.Item>
                                            </div>
                                            
                                            <Form.Item label={t("Currency")}>{getFieldDecorator("currency", {})(
                                                <Currency/>)}</Form.Item>
                                           
                                            <Form.Item label={t("Effective")}>{getFieldDecorator("effectiveDate", {})(
                                                <DatePickerW style={{width: "100%"}}/>)}</Form.Item>
                                            <Form.Item label={t("End")}>{getFieldDecorator("endDate", {})(<DatePickerW
                                                style={{width: "100%"}}/>)}</Form.Item>
                                            <Form.Item label={t("Custom Form")}>
                                                {getFieldDecorator("formId", {})(<FormSelect/>)}
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col span={12}/>
                                        <Col span={12}/>
                                    </Row>
                                </Col>
                            </Row>
                        </Tabs.TabPane>

                        {values.formId && <Tabs.TabPane
                            forceRender
                            tab={<span><FormOutlined />{t("Custom")}</span>}
                            key="customForm">
                            {values.formId && (
                                <CustomForm formId={values.formId} variable="customReForm" value={values.jFormValues}/>
                            )}
                        </Tabs.TabPane>}
                        <Tabs.TabPane
                            forceRender={true}
                            tab={
                                <span>
                    <CodeOutlined />
                                    {t("Behaviour")}
                  </span>
                            }
                            key="2">
                            <Title style={{marginTop: 5}} level={4}>
                                {t("Advanced Contract Behaviour")}
                            </Title>
                            <Button type="link" icon={<RocketOutlined />} onClick={() => onSimulate()}>
                                {t("Simulator")}
                            </Button>
                            <Dropdown
                                overlay={
                                    <Menu onClick={(v) => onTemplateClick(v)}>
                                        <Menu.Item key="quotashare">{t("Quota Share")}</Menu.Item>
                                        <Menu.Item key="surplus">{t("Surplus")}</Menu.Item>
                                        <Menu.Item key="mixed">{t("Mixed")}</Menu.Item>
                                    </Menu>
                                }>
                                <Button type="link" icon={<CoffeeOutlined />}>
                                    {t("Templates")}
                                </Button>
                            </Dropdown>
                            <CodeEditorW/>
                        </Tabs.TabPane>

                        <Tabs.TabPane
                            key="participants"
                            tab={
                                <span>
                    <TeamOutlined />
                                    {t("Participants")}
                  </span>
                            }>
                            <CoContractParticipants contractId={values.id}/>
                        </Tabs.TabPane>


                    </Tabs>
                </Form>
            </DefaultPage>
            <DistributionSimulator
                visible={simulatorVisible}
                onClose={() => setSimulatorVisible(false)}
                configJson={configJson}
                contractId={values.id}
                coinsurance
            />
            <NewCurrentAccount
                contractId={values.id}
                visible={newAccountVisible}
                onCancel={() => setNewAccountVisible(false)}
                onOk={() => onNewAccount()}
            />
        </div>
    );

}

const WrappedContactForm = Form.create({name: "register"})(CoContract);
export default withTranslation()(WrappedContactForm);
