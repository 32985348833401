import React from "react";
import { InputNumber, Select } from "antd";
import { useTranslation } from "react-i18next";

const PaymentDuration = (props) => {
  const [t] = useTranslation();
  if (props.value > 0) return <InputNumber value={props.value} onChange={(v) => props.onChange(v)} min={0} max={20} />;

  return (
    <div>
      <Select value={props.value} onChange={(v) => props.onChange(v)} disabled={props.disabled}>
        <Select.Option value={-1}>{t("Lump sum")}</Select.Option>
        <Select.Option value={0}>{t("Same as contract")}</Select.Option>
        <Select.Option value={1}>{t("Custom")}</Select.Option>
      </Select>
    </div>
  );
};

export default PaymentDuration;
