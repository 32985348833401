import React from "react";
import { Modal, Input, message, notification, Transfer } from "antd";
import { post, put, get, exe } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";

class GrupoUsuarios extends React.Component {
  componentDidMount() {
    this.loadUsers();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.grupo && this.props.grupo.id != this.state.grupoId) {
      this.setState({
        targetKeys: this.props.grupo.Members.map((p) => p.usrEmail),
        grupoId: this.props.grupo.id,
      });
    }
  }
  state = {
    grupoId: null,
    targetKeys: [],
    usuarios: [],
  };
  loadUsers() {
    exe("GetUsers").then((r) => {
      this.setState({ usuarios: r.outData });
    });
  }

  handleOk = () => {
    const record = { ...this.props.grupo, Members: this.state.targetKeys.map((p) => ({ usrEmail: p })) };
    const { t, i18n } = this.props;
    exe("RepoUsrGroup", { operation: "UPDATE", entity: record }).then((r) => {
      if (r.ok) {
        message.success(t("Done"));
        this.props.afterOk();
      } else {
        message.error(t("Error assigning group"));
      }
    });
  };
  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetKeys: nextTargetKeys });

    console.log("targetKeys: ", nextTargetKeys);
    console.log("direction: ", direction);
    console.log("moveKeys: ", moveKeys);
  };

  render() {
    const { targetKeys, selectedKeys } = this.state;
    const { t, i18n } = this.props;

    return (
      <Modal title={t("Users")} visible={this.props.visible} onOk={this.handleOk} onCancel={this.props.onClose}>
        <Transfer
          dataSource={this.state.usuarios}
          titles={["", t("Group")]}
          rowKey={(record) => record.email}
          onChange={this.handleChange}
          targetKeys={targetKeys}
          render={(item) => item.nombre}
        />
      </Modal>
    );
  }
}

export default withTranslation()(GrupoUsuarios);
