import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Steps, Button, Row, Col, Input } from "antd";
import Money from "../Shared/Money";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const ConvertToCheck = (props) => {
  const [t, i18n] = useTranslation();
  const field = props.form.getFieldDecorator;

  return (
    <div style={{ display: "grid" }}>
      <Form>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label={t("Account Balance")}>{field("balance")(<Money />)}</Form.Item>
            <Form.Item label={t("Check Amount")}>{field("chekcAmount")(<Money />)}</Form.Item>
            <Form.Item label={t("Payee")}>{field("payee")(<Input />)}</Form.Item>
            <Form.Item label={t("Check Date")}>{field("checkDate")(<DatePickerW />)}</Form.Item>
            <Form.Item label={t("Memo")}>{field("checkDate")(<Input />)}</Form.Item>
            <Button type="primary">{t("Execute")}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(ConvertToCheck);
