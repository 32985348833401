import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Button, Input, Radio, Select, DatePicker, Tag, Switch, Checkbox } from "antd";
import { exe } from "../../Lib/Dal";
import moment from "moment";
import Compare from "../Shared/Compare";
import PolicySelect from "../Health/PolicySelect";
import Currency from "../Shared/Currency";
import ContactSelect from "../Contact/ContactSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import { useTranslation } from "react-i18next";
import BranchSelect from "./BranchSelect";

const AccountsFilter = (props) => {
  const [t, i18n] = useTranslation();
  const [matches, setMatches] = useState(null);

  useEffect(() => {
    if (window.location.hash.includes("?date=")) {
      const date = window.location.hash.split("?date=")[1];
      props.form.setFieldsValue({ date: moment(date) });
      submit();
    }
  }, []);

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      console.log(values, "FORM VALUES");
      let queries = [];

      if (values.id !== undefined) queries.push(`id=${values.id}`);
      if (values.accNo !== undefined) queries.push(`accNo LIKE N'%${values.accNo}%'`);
      if (values.policyId !== undefined) queries.push(`lifePolicyId=${values.policyId}`);
      if (values.currency !== undefined) queries.push(`currency='${values.currency}'`);
      if (values.type !== undefined) queries.push(`type='${values.type}'`);
      if (values.holderId !== undefined) queries.push(`holderId=${values.holderId}`);
      if (values.branchCode !== undefined) queries.push(`branchCode='${values.branchCode}'`);

      const filterQuery = queries.join(" AND ");
      console.log(queries, filterQuery);

      exe("RepoAccount", { operation: "GET", filter: filterQuery }).then((r) => {
        console.log(r);
        props.onResults(r.outData);
        setMatches(r.total);
      });
    });
  };

  return (
    <div>
      <Drawer title={t("Account Filter Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Form.Item label={t("ID")}>{getFieldDecorator("id")(<Input />)}</Form.Item>
          <Form.Item label={t("Acc No")}>{getFieldDecorator("accNo")(<Input />)}</Form.Item>
          <Form.Item label={t("Policy")}>{getFieldDecorator("policyId")(<PolicySelect />)}</Form.Item>
          <Form.Item label={t("Currency")}>{getFieldDecorator("currency")(<Currency />)}</Form.Item>
          <Form.Item label={t("Type")}>{getFieldDecorator("type")(<Input />)}</Form.Item>
          <Form.Item label={t("Holder")}>{getFieldDecorator("holderId")(<SimpleContactSelect />)}</Form.Item>
          <Form.Item label={t("Branch")}>{getFieldDecorator("branchCode")(<BranchSelect />)}</Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(AccountsFilter);
