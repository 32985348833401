import { InputNumber, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { round2 } from "../../Lib/Helpers";
import { exe, safeGet, safeParse } from "../../Lib/Dal";

const ReEvaluationSelect = (props) => {
  const [t] = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [tableValue, setTableValue] = useState();

  useEffect(() => {
    if (props.policyId && props.config) checkTableValue();
  }, [props.policyId]);

  useEffect(() => {
    if (tableValue && !props.disabled && props.value !== null) props.onChange(tableValue); //if no value set, setting table value if available
  }, [props.value]);

  const checkTableValue = () => {
    if (props.config.Main.reEvaluationTable) {
      exe("GetTable", { table: props.config.Main.reEvaluationTable, column: "policyId", row: props.policyId, getColumn: "reEvaluationPercentage" }).then(
        (r) => {
          console.log(r);
          if (r.ok) {
            setTableValue(+r.outData);
            setDisabled(true);
          }
        }
      );
    }
  };

  const onChangeYesNo = (v) => {
    if (v) props.onChange(tableValue || 0.05);
    else props.onChange(0);
  };
  return (
    <div style={{ display: "flex" }}>
      <Select value={props.value ? true : false} onChange={onChangeYesNo} style={{ marginRight: 5, flex: 1 }} disabled={props.disabled}>
        <Select.Option value={false}>{t("No")}</Select.Option>
        <Select.Option value={true}>{t("Yes")}</Select.Option>
      </Select>
      <InputNumber
        value={props.value}
        disabled={props.disabled || disabled}
        step={0.01}
        min={0}
        onChange={(v) => props.onChange(v)}
        style={{ flex: 1, display: props.value !== null && props.value !== undefined ? "block" : "none" }}
      />
    </div>
  );
};

export default ReEvaluationSelect;
