import React, { Component } from "react";
import { InputNumber } from "antd";

class Percentage extends Component {
  render() {
    return (
      <InputNumber
        value={this.props.decimal ? this.props.value * 100 : this.props.value}
        onChange={(v) => (this.props.decimal ? this.props.onChange(v / 100) : this.props.onChange(v))}
        min={this.props.min?this.props.min:0}
        max={this.props.max?this.props.max:100}
        precision={this.props.precision}
        formatter={(value) => `${value}%`}
        parser={(value) => value.replace("%", "")}
        style={{ width: "100%" }}
        disabled={this.props.disabled}
      />
    );
  }
}

export default Percentage;
