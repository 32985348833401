import React, { useState, useRef, useEffect } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { FileOutlined, GlobalOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Tabs, message, Empty, Avatar, Tag, Button } from "antd";
import TransferList from "./TransferList";
import CollectionData from "../Collection/CollectionData";
import AllocationTask from "./AllocationTask";
import AllocationTaskList from "./AllocationTaskList";
import AccountMov from "../Financial/AccountMov";
import Title from "antd/lib/typography/Title";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

const Allocation = (props) => {
  const [t] = useTranslation();
  const [selectedTransfers, setSelectedTransfers] = useState([]);
  const [selectedPremiums, setSelectedPremiums] = useState([]);
  const [refresh, doRefresh] = useState(0);
  const [affectedPolicies, setAffectedPolicies] = useState([]);
  const [save, doSave] = useState(0);
  const [activeTab, setActiveTab] = useState("transfers");
  const [loading, setLoading] = useState(false);
  const [markAllocation, setMarkAllocation] = useState(null);

  useEffect(() => checkUrl(), []);

  const checkUrl = () => {
    if (window.location.hash.includes("id=")) {
      const qs = queryString.parse(window.location.hash);
      setActiveTab("allocations");
      setMarkAllocation(qs["/allocation?id"]);
    }
  };

  const currency = selectedTransfers.length == 0 ? undefined : selectedTransfers[0].currency;

  const transferRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (!selectedRows) return;
      if (selectedRows.some((p) => p.currency !== selectedRows[0].currency)) {
        message.error(t("Currencies must be of same type"));
        return;
      }
      setSelectedTransfers(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: selectedTransfers.length == 0 ? false : record.currency !== currency,
    }),
  };
  const premiumRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
      //to preserve selected rows between pagination changes
      const currentlySelectedRows=selectedPremiums.filter(p=>selectedRowKeys.find(q=>q===p.id));
      setSelectedPremiums([...currentlySelectedRows,...selectedRows.filter(p=>!currentlySelectedRows.find(q=>q.id===p.id))]);
    },
    getCheckboxProps: (record) => ({
      disabled: !record.pending,
    }),
    onSelectAll:(selected, selectedRows, changeRows)=>{
      if(!selected) setSelectedPremiums([]); //clearing selections
    }
  };
  const onAllocation = () => {
    setSelectedTransfers([]);
    setSelectedPremiums([]);
    doRefresh(refresh + 1);
    setActiveTab("allocations");
  };
  const onDraftAllocation = (draft) => {
    //receives results from AllocatoinTask component
    setAffectedPolicies(draft.policies);
    setActiveTab("draftAllocation");
  };
  const onSaveAllocationTask = () => {
    doSave(save + 1);
  };
  const onNewClick = () => {
    window.location.hash = "#/allocation";
    window.location.reload();
    // setSelectedTransfers([]);
    // setSelectedPremiums([]);
    // setAffectedPolicies([]);
    // doRefresh(refresh + 1);
    // setActiveTab("transfers");
    // message.success("New allocation ready");
  };
  return (
    <DefaultPage
      title={t("Allocation")}
      icon="api"
      extra={
        <div>
          <Button icon={<FileOutlined />} onClick={onNewClick}>
            {t("New")}
          </Button>
        </div>
      }>
      <Tabs onChange={(tab) => setActiveTab(tab)} activeKey={activeTab}>
        <Tabs.TabPane tab={t("Money In")} key="transfers">
          <TransferList rowSelection={transferRowSelection} allocation reset />
          <Button type="primary" icon={<RightOutlined />} onClick={() => setActiveTab("pendingPremiums")}>
            {t("Next")}
          </Button>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Pending Premiums")} key="pendingPremiums">
          <CollectionData rowSelection={premiumRowSelection} currency={currency} />
          <Button type="primary" icon={<RightOutlined />} onClick={() => setActiveTab("newAllocation")}>
            {t("Next")}
          </Button>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Allocation")} key="newAllocation">
          <AllocationTask
            selectedTransfers={selectedTransfers}
            selectedPremiums={selectedPremiums}
            onAllocation={onAllocation}
            setLoading={setLoading}
            onDraftAllocation={onDraftAllocation}
            doSave={save}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Draft Allocation")} key="draftAllocation" disabled={affectedPolicies.length == 0}>
          <Button style={{ marginBottom: 15 }} type="primary" icon={<SaveOutlined />} onClick={() => onSaveAllocationTask()} loading={loading}>
            {t("Accept Draft")}
          </Button>

          {affectedPolicies.map((policy) => (
            <div>
              <span>{policy.code}</span>
              {policy.Accounts.filter((p) => p.type == "TRANSIT").map((p) => (
                <div>
                  <Title level={4}>
                    <Avatar icon={<GlobalOutlined />} style={{ marginRight: 5 }} size="small" />
                    {p.name}
                    <Tag style={{ marginLeft: 5 }}>{p.InvestmentPlan ? p.InvestmentPlan.name : p.type}</Tag>
                  </Title>
                  <AccountMov value={p.Movements} currency={currency} account={p} noFilter />
                </div>
              ))}
            </div>
          ))}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("History")} key="allocations">
          <AllocationTaskList refresh={refresh} mark={markAllocation} />
        </Tabs.TabPane>
      </Tabs>
    </DefaultPage>
  );
};

export default Allocation;
