import React, { Component } from "react";
import DefaultPage from "../Shared/DefaultPage";
import {
  CopyOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Popover, Table, Input, Divider, Button, message, Space } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { exe } from "../../Lib/Dal";
import Product from "./Product";
import Text from "antd/lib/typography/Text";
import { withTranslation } from "react-i18next";
import RecentSelect from "../Shared/RecentSelect";

class Products extends Component {
  state = {
    products: [],
    loading: false,
  };
  componentDidMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });
    exe("GetProducts", {}).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState({ products: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  }
  onClone(record) {
    this.setState({ loading: true });
    exe("CloneProduct", { code: record.code, newCode: this.state.productCode }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.load();
      } else {
        message.error(r.msg);
      }
    });
  }
  newProduct() {
    window.location.href = "#/products/0";
  }
  onExport(record) {
    this.setState({ loading: true });
    exe("ExportProduct", { code: record.code }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);

        var element = document.createElement("a");
        element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(r.outData));
        element.setAttribute("download", record.code + ".yaml");

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } else {
        message.error(r.msg);
      }
    });
  }
  //search
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(ele) => (this.searchInput = ele)}
          placeholder={`${this.props.t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(this.state.selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          {this.props.t("Search")}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {this.props.t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => (record[dataIndex] || "").toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys ? selectedKeys[0] : "", searchedColumn: dataIndex });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  //end search
  render() {
    const { t, i18n } = this.props;
    return (
      <DefaultPage
        title={t("Products")}
        icon="dropbox"
        extra={
          <Space>
            <RecentSelect variable="productHistory" onSelect={v=>window.location="#/products/"+v} />
            <ButtonGroup>
              <Button icon={<FileOutlined />} onClick={() => this.newProduct()}>
                {t("New")}
              </Button>
              <Button icon={<ReloadOutlined />} onClick={() => this.load()}>
                {t("Reload")}
              </Button>
            </ButtonGroup>
          </Space>
        }>
        <Table columns={this.columns} dataSource={this.state.products} loading={this.state.loading}>
          <Table.Column
            title={t("Code")}
            dataIndex="code"
            render={(text) => <a href={"#/products/" + text}>{text}</a>}
            {...this.getColumnSearchProps("code")}
          />
          <Table.Column title={t("Name")} dataIndex="name" {...this.getColumnSearchProps("name")} />
          <Table.Column title={t("LoB")} dataIndex="lobCode" render={(v) => <Text code>{v}</Text>} {...this.getColumnSearchProps("lobCode")} />
          <Table.Column title={t("Sub LoB")} dataIndex="subLobCode" render={(v) => <Text code>{v}</Text>} {...this.getColumnSearchProps("subLobCode")} />
          <Table.Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <a href={"#/products/" + record.code}>{t("Edit")}</a>
                <Divider type="vertical" />
                <Popover
                  content={
                    <div style={{ width: 150 }}>
                      {t("Please specify unique product code for the cloned plan")}
                      <Input
                        style={{ marginTop: 5, marginBottom: 5 }}
                        value={this.state.productCode}
                        placeholder={t("Please insert code")}
                        onChange={(e) => this.setState({ productCode: e.target.value })}
                      />
                      <Button type="primary" icon={<CopyOutlined />} onClick={() => this.onClone(record)}>
                        {t("Clone")}
                      </Button>
                    </div>
                  }
                  title={
                    <div>
                      <ExclamationCircleOutlined style={{ marginRight: 5, color: "orange" }} />
                      {t("Product Code")}
                    </div>
                  }
                  trigger="click">
                  <Button type="link">{t("Clone")}</Button>
                </Popover>
                <Divider type="vertical" />
                <Button type="link" onClick={() => this.onExport(record)}>
                  {t("Export")}
                </Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => (window.location = "#/configurator/" + record.code)}>
                  {t("Inspect")}
                </Button>
              </span>
            )}
          />
        </Table>
      </DefaultPage>
    );
  }
}

export default withTranslation()(Products);
