import { exe } from "Lib/Dal";

window.GLOBAL = (() => {
  class SingletonClass {
    constructor() {}
    currentUser = {};

    loaded = false;
  }
  var instance;
  return {
    getInstance: () => {
      if (instance == null) {
        instance = new SingletonClass();
        // Hide the constructor so the returned object can't be new'd...
        instance.constructor = null;
      }
      return instance;
    },
    async checkGlobalIsLoaded() {
      while (!window.GLOBAL.currentUser) {
        await new Promise((r) => setTimeout(r, 50));
      }
    },
  };
})();

export const Global = window.GLOBAL.getInstance();
