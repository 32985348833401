import React, {useContext, useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select, Tooltip,ConfigProvider} from "antd";
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

const FundSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    const [data,setData]=useState([]);
    const configContext = useContext(ConfigProvider.ConfigContext);
    const direction = configContext.direction;
    
    useEffect(()=>{
        load();
    },[])
    
    const load=()=>{
        setLoading(true);
        exe("RepoFund",{operation:"GET",filter:`active=1`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
            }else message.error(r.msg)
        })
    }
    return (
        <div>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled} loading={loading} placeholder={t("Please select fund")}>
              {data.map(p=><Select.Option value={p.id} key={p.id}>{p.name}</Select.Option> )}
          </Select>
          <Tooltip title={t("Open fund")}>
            <a href={"#/fund/" + props.value}>
            <InfoCircleOutlined
                style={{ position: "absolute", top: "8px", cursor: "pointer", 
                color: "rgba(0, 0, 0, 0.25)", fontSize: 16,...(direction=="rtl"?{left:"30px"}:{right:"30px"}) }} />
            </a>
            </Tooltip>  
        </div>
    );
};

export default FundSelect;