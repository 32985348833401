import React from 'react';
import {Select} from "antd";
import {useTranslation} from "react-i18next";

const ProductChannelSelect = (props) => {
    const [t]=useTranslation();
    
    //transforming multi boolean in form to select
    const value=props.value.filter(v=>v).map((active,i)=>i===0&&active?"selfService":undefined);
    const onChange=(v)=>{
        props.onChange({selfService:v.includes("selfService")});
    }
    return (
        <div>
            <Select mode={"multiple"} showArrow value={value} onChange={onChange} allowClear showSearch>
                <Select.Option value={"selfService"}>{t("Self-Service")}</Select.Option>
                {/*<Select.Option value={"WEB"}>{t("Web")}</Select.Option>*/}
            </Select>
        </div>
    );
};

export default ProductChannelSelect;