import { SaveOutlined, DollarOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Badge,
    Button,
    Col,
    DatePicker,
    Divider,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Row,
    Switch,
    Table,
    Tabs,
    Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import FormLabel from "../Shared/FormLabel";
import moment from "moment";
import Title from "antd/lib/typography/Title";
import Currency from "../Shared/Currency";
import DistributionModeSelect from "./DistributionModeSelect";
import CessionParticipants from "./CessionParticipants";
import Section from "../Shared/Section";
import Percentage from "../Shared/Percentage";
import ContractSelect from "./ContractSelect";
import {round2} from "../../Lib/Helpers";
import UpdateRetention from "./UpdateRetention";
import Money from "../Shared/Money";
import MoneyLabel from "../Shared/MoneyLabel";
import ContactLink from "../Contact/ContactLink";
import FastForm from './FastForm';
const ClaimCessionEdit = (props) => {
    const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [changeRetentionVisible,setChangeRetentionVisible]=useState(false);
    const [participantPaidAmount,setParticipantPaidAmount]=useState();

    useEffect(() => {
        if (props.value) {
            props.form.setFieldsValue(props.value);
            console.log(props.value);
        }
    }, [props.value]);
    
    const field = props.form.getFieldDecorator;

    // const save = () => {
    //     props.form.validateFields((err, values) => {
    //         if (err) return;
    //         setLoading(true);
    //         exe("RepoCession", { operation: "UPDATE", entity: values }).then((r) => {
    //             setLoading(false);
    //             if (r.ok) {
    //                 message.success(r.msg);
    //                 props.onEdit && props.onEdit();
    //             } else {
    //                 message.error(r.msg);
    //             }
    //         });
    //     });
    // };

    const updatePaidAmount=(participant)=>{
        const myModal=Modal.info({
            title:t("Update Paid Amount"),
            okButtonProps:{style:{display:"none"}},
            maskClosable:true,
            closable:true,
            content:<FastForm onOk={values=>onOkForm({lossCessionPartId:participant.id,...values},myModal)} 
            controls={[{name:"paid",label:"Paid Amount",rules:[{required:true}], component:<Money currency={formValues.currency} style={{width:"100%"}} />}]} />
            });
        
    }
    const onOkForm=(values,modal)=>{
        setLoading(true);
            exe("UpdateParticipantPaidAmount",values).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onEdit && props.onEdit();
                    modal && modal.destroy();
                }else{
                    message.error(r.msg);
                }
            })
    }


    const formValues = props.form.getFieldsValue();
    return (
        <div>
            <Button type="link" icon={<SaveOutlined />}  disabled>
                {t("Save")}
            </Button>
            <Tabs>
                <Tabs.TabPane tab={t("Claim Distribution")} key="distribution">
                    {field("currency")}
                    <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 25 }}>
                            <Section text={t("Claim")}>
                                <Form.Item label={t("Reserve")}>
                                    {field("reserve")(<Money style={{ width: "171px" }} currency={formValues.currency} disabled  />)}
                                </Form.Item>
                                <Form.Item label={t("Loss")}>
                                    {field("loss")(<Money style={{ width: "171px" }} currency={formValues.currency} disabled/>)}
                                </Form.Item>
                            </Section>
                        </div>
                        <div style={{ marginRight: 25 }}>
                            <Section text={t("Cedant")}>
                                <Form.Item label={t("Reserve Cedant")}>
                                    {field("retainedReserve")(<Money style={{ width: "171px" }} currency={formValues.currency} disabled/>)}
                                </Form.Item>  
                                <Form.Item label={t("Loss Cedant")}>
                                    {field("retainedLoss")(<Money style={{ width: "171px" }} currency={formValues.currency} disabled/>)}
                                </Form.Item>
                               
                            </Section>
                        </div>
                        <div style={{ marginRight: 25 }}>
                            <Section text={t("Reinsurer(s)")}>
                                <Form.Item label={t("Reserve Reinsurer")}>
                                    {field("cededReserve")(<Money style={{ width: "171px" }}  currency={formValues.currency} disabled/>)}
                                </Form.Item>
                                <Form.Item label={t("Loss Reinsurer")}>
                                    {field("cededLoss")(<Money style={{ width: "171px" }} currency={formValues.currency} disabled/>)}
                                </Form.Item>
                            </Section>
                        </div>
                    </div>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("Participants")} key="participants">
                    <Table dataSource={props.value.Participants} loading={loading}>
                        <Table.Column title={t("Participant")} dataIndex="name" render={(v,r)=><ContactLink contact={{id:r.contactId,FullName:r.name}} />} />
                        <Table.Column title={t("Line ID")} dataIndex="lineId"/>
                        <Table.Column title={t("Split %")} dataIndex="split"/>
                        <Table.Column title={t("Loss")} dataIndex="loss" render={(v,r)=><MoneyLabel currency={r.currency} value={v}/>} />
                        <Table.Column title={t("Paid")} dataIndex="paid" render={(v,r)=><MoneyLabel currency={r.currency} value={v}/>} />
                        <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div style={{whiteSpace:"nowrap"}}>
                            <Tooltip title={t("Update paid amount")}>
                                <Button type={"link"} icon={<DollarOutlined />} onClick={()=>updatePaidAmount(r)} />
                            </Tooltip>
                        </div>} />
                       
                    </Table>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

export default Form.create()(ClaimCessionEdit);
