import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Collapse, Input } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { round2 } from "../../Lib/Helpers";
import Money from "../Shared/Money";

const Coverage = (props) => {
  const [t] = useTranslation();

  useEffect(() => {
    props.form.setFieldsValue(props.value);
  }, [props.value.limit, props.value.basePremium]);

  const computeFormula = (f, policyContext) => {
    try {
      const context = {};
      context.pol = policyContext;
      context.cfg = props.config;
      f = f.replace("_", "context.");
      return eval(f);
    } catch (error) {
      console.log("Unable to evaluate formula client side. Please quote the policy.", error);
      return undefined;
    }
  };
  const onChangeLimit = (v) => {
    const covConfig = props.config.Coverages.find((p) => p.code == props.value.code);
    if (covConfig.premiumFunctionOfLimit) {
      const updatedContext = props.policy;
      updatedContext.Coverages = updatedContext.Coverages.map((cov) => (cov.code == props.value.code ? { ...cov, limit: v } : cov));

      const formula = covConfig.premiumFunctionOfLimit.replace("_limit", v);
      const premium = computeFormula(formula, updatedContext);
      if (premium) props.form.setFieldsValue({ basePremium: round2(premium) });
    }
  };
  const onChangePremium = (v) => {
    const covConfig = props.config.Coverages.find((p) => p.code == props.value.code);
    if (covConfig.limitFunctionOfPremium) {
      const updatedContext = props.policy;
      updatedContext.Coverages = updatedContext.Coverages.map((cov) => (cov.code == props.value.code ? { ...cov, basePremium: v } : cov));

      const formula = covConfig.limitFunctionOfPremium.replace("_premium", v);
      const limit = computeFormula(formula, updatedContext);
      if (limit) props.form.setFieldsValue({ limit: round2(limit) });
    }
  };
  const getParameter = (parameter) => {
    const covConfig = props.config.Coverages.find((p) => p.code === props.value.code);
    return covConfig[parameter] == undefined || covConfig[parameter] == -1 ? undefined : covConfig[parameter];
  };

  const field = props.form.getFieldDecorator;
  return (
    <div className={props.disabled ? "disabled" : ""}>
      <Form.Item label={t("Sum Insured")}>
        {field("limit")(<Money min={getParameter("minLimit")} max={getParameter("maxLimit")} currency={props.currency} onChange={(v) => onChangeLimit(v)} />)}
      </Form.Item>
      <Form.Item label={t("Premium")}>{field("basePremium")(<Money currency={props.currency} onChange={(v) => onChangePremium(v)} />)}</Form.Item>
    </div>
  );
};
const CoverageForm = Form.create({
  onValuesChange: (props, changedValues, allValues) => props.onChange(allValues),
})(Coverage);

const CoverageEdition = (props) => {
  const onChangeCoverage = (value, index) => {
    const newCovs = [...coverages];
    newCovs[index] = { ...newCovs[index], ...value };
    props.onChange(JSON.stringify(newCovs));
  };
  const coverages = JSON.parse(props.value || []);
  const getConfig = (code) => props.config.Coverages.find((p) => p.code == code);

  return (
    <div>
      <Collapse>
        {coverages.map((coverage, index) => (
          <Collapse.Panel header={`${coverage.code}-${coverage.name}`} key={coverage.code}>
            <CoverageForm
              currency={props.currency}
              value={coverage}
              onChange={(p) => onChangeCoverage(p, index)}
              disabled={props.disabled || getConfig(coverage.code).restrictUserEdition}
              config={props.config}
              policy={props.policy}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default CoverageEdition;
