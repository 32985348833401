import React, { Component } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Divider, message, Input } from "antd";
import { exe } from "Lib/Dal";
import Filter from "./Filter";
import DefaultPage from "../Shared/DefaultPage";
import { formatDate, formatDateShort } from "../../Lib/Helpers";
import { withTranslation } from "react-i18next";

const ButtonGroup = Button.Group;
const { Column } = Table;

class ContractList extends Component {
  state = {
    loading: false,
    data: [],
    filter: false,
  };
  componentDidMount() {
    exe("GetContracts", { include: ["Tags", "Contact"] }).then((r) => {
      console.log(r);
      if (r.ok) {
        this.setState({ data: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  }
  filterByTag(tag) {
    exe("GetContracts", { include: ["Tags"], tagFilterAny: [tag] }).then((r) => {
      if (r.ok) {
        this.setState({ data: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  }
  //table search ini
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
              ref={(ele) => (this.searchInput = ele)}
              placeholder={`${this.props.t("Search")} ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
              type="primary"
              onClick={() => this.handleSearch(this.state.selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90, marginRight: 8 }}>
            {this.props.t("Search")}
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {this.props.t("Reset")}
          </Button>
        </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys ? selectedKeys[0] : "", searchedColumn: dataIndex });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  //table search end

  render() {
    const { t, i18n } = this.props;
    return (
      <DefaultPage
        title={t("Contracts")}
        icon="bars"
        loading={this.state.loading}
        extra={
          <div>
            <ButtonGroup>
              <Button type="primary" onClick={() => this.setState({ filter: true })} icon={<SearchOutlined />}>
                {t("Filter")}
              </Button>
            </ButtonGroup>
          </div>
        }>
        <Table dataSource={this.state.data}>
          <Column title={t("Contract Name")} dataIndex="name" render={(v, r) => <a href={"#/contract/" + r.id}>{v}</a>} {...this.getColumnSearchProps("name")} />
          <Column title={t("Contract Code")} dataIndex="code" {...this.getColumnSearchProps("code")}/>
          <Column title={t("Company")} dataIndex="contactId" render={(v, r) => <a href={"#/contact/" + v}>{r.Contact ? r.Contact.FullName : "-"}</a>} />
          <Column title={t("Direction")} dataIndex="inward" render={(field) => (field ? "Inward" : "Outward")} />
          <Column title={t("Active")} dataIndex="active" render={(v) => (v ? <Tag color="green">Active</Tag> : <Tag>Draft</Tag>)} />
          <Column title={t("Effective")} dataIndex="effectiveDate" render={(v, r) => (v ? formatDateShort(v) : "-")} />
          <Column title={t("End")} dataIndex="endDate" render={(v, r) => (v ? formatDateShort(v) : "-")} />
          <Column
            title={t("Tags")}
            dataIndex="Tags"
            render={(tags) => (
              <span>
                {tags.map((tag) => (
                  <Tag color="blue" key={tag.tag} onClick={(p) => this.filterByTag(tag.tag)}>
                    {tag.tag}
                  </Tag>
                ))}
              </span>
            )}
          />
        </Table>
        <Filter
          visible={this.state.filter}
          onClose={() => {
            this.setState({ filter: false });
          }}
          onSearch={(d) => this.setState({ data: d })}
        />
      </DefaultPage>
    );
  }
}
export default withTranslation()(ContractList);
