import React, {useEffect, useState} from 'react';
import { CheckOutlined, CloseOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Input, InputNumber, message, Modal, Switch, Table } from "antd";
import {useTranslation} from "react-i18next";
import DateField from "../Shared/DateField";
import BranchSelect from "./BranchSelect";
import moment from "moment";
import {exe} from "../../Lib/Dal";
import {formatDate, formatMoney} from "../../Lib/Helpers";
import DatePickerW from '../Shared/DatePickerW';

const WorkspaceDetail = props => {
    const [loading, setLoading] = useState(false);
    const [cashFundData, setCashFundData] = useState([]);
    const [t] = useTranslation();
    const field = props.form.getFieldDecorator;
    
    useEffect(()=>{
        if(props.cashFundControl) loadCashFunds();
    },[]);
    const onSave = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            if(props.cashFundControl){
                //must confirm cash funds
                if(!values.cashFundsConfirmed){
                    message.error(t("You must confirm the cash funds"));
                    return;
                }
                //must have cash funds
                if(cashFundData.length===0){
                    message.error(t("You must have cash funds"));
                    return;
                }
                
            }
            props.onOk(values);
        })
    }
    const loadCashFunds = () => {
        setLoading(true);
        exe("RepoCashFund",{operation:"GET",filter:`[user]='${localStorage.email}'`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setCashFundData(r.outData);
            }else message.error(r.msg)
        })
    }
    return (
        <div>
                <Button type="link" icon={<LeftOutlined />} onClick={() => props.onCancel()}>{t("Back")}</Button>
                <Button type="link" icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
                <div style={{width:400,marginBottom:5}}>
                    <Form.Item label={t("User")}>{field("user", {
                        initialValue: localStorage.email,
                        rules: [{required: true}]
                    })(<Input disabled/>)}</Form.Item>
                    <Form.Item label={t("Date")}>{field("date", {initialValue:moment(),rules: [{required: true}]})(<DatePickerW disabled={!props.allowDateDefinition}/>)}</Form.Item>
                    <Form.Item label={t("Branch")}>{field("branchCode",{rules: [{required: true}]})(<BranchSelect/>)}</Form.Item>
                </div>
            {props.cashFundControl&&<div>
                    <Form.Item label={t("Cash fund confirmation")}>
                        {field("cashFundsConfirmed", {rules: [{required: false}],valuePropName:"checked"})(<Switch
                            checkedChildren={<CheckOutlined />}
                            unCheckedChildren={<CloseOutlined />}
                        />)}
                    </Form.Item>
                    <Table dataSource={cashFundData} loading={loading} pagination={false} rowKey="id">
                        <Table.Column title={t("Currency")} dataIndex="currency" />
                        <Table.Column title={t("Amount")} dataIndex="balance" render={(v,r)=>formatMoney(v,r.currency)} />
                        <Table.Column title={t("Date updated")} dataIndex="updated" render={v=>formatDate(v)} />
                    </Table>
                </div>}
            </div>
    );
};

export default Form.create()(WorkspaceDetail);