import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const EmailSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    if (props.contactId) load(props.contactId);
  }, [props.contactId]);

  const load = (contactId) => {
    setLoading(true);
    exe("GetContactEmails", { contactId: contactId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        showArrow
        placeholder={t("Please select or enter email address")}
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        style={{ width: "100%" }}
        mode="tags"
        showSearch>
        {data.map((p) => (
          <Select.Option value={p.email} key={p.email}>
            {p.email} ({p.type})
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default EmailSelect;
