import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import ContactBranchForm from "./ContactBranchForm";

const ContactBranches = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.contactId) load();
    },[props.contactId])
    
    const load=()=>{
        setLoading(true);
        exe("RepoContactBranch",{operation:"GET",filter:`contactId=${props.contactId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    
    const onSave=values=>{
        let operation;
        if(values.id){
            operation="UPDATE";
        }else{
            operation="ADD"
            values.contactId=props.contactId;
        }
        setLoading(true);
        exe("RepoContactBranch",{operation:operation,entity:values}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })
        
    }
    const onDelete=record=>{
        setLoading(true);
        exe("RepoContactBranch",{operation:"DELETE",entity:record}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })

    }
    
    return (
        <div>
            {!selected&&<div>
                <Button type={"primary"} icon={<LegacyIcon type={"plus"} />} onClick={()=>setSelected({id:0})}>{t("New")}</Button>
                <Table dataSource={data} rowKey={"id"} style={{marginTop:5}}>
                    <Table.Column title={t("Code")} dataIndex={"code"} />
                    <Table.Column title={t("Name")} dataIndex={"name"} />
                    <Table.Column title={t("Id Aux")} dataIndex={"idAux"} />
                    <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} /> </div>}/>
                </Table>
            </div>}
            {selected&&<ContactBranchForm onBack={()=>setSelected(null)} onSave={onSave} selected={selected} onDelete={onDelete} />}
        </div>
    );
};

export default ContactBranches;