import { DeleteOutlined, FileOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { Button, Checkbox, Divider, message, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";

const Presentations = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoading(true);
    exe("RepoPresentationDefinition", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onDelete = (id) => {
    setLoading(true);
    exe("RepoPresentationDefinition", { operation: "DELETE", entity: { id: id } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onOpen = (id) => {
    window.location.hash = "#/presentation/" + id;
  };
  const onNew = () => {
    window.location.hash = "#/presentation/0";
  };

  return (
    <DefaultPage
      title={t("Presentation")}
      icon="camera"
      extra={
        <div>
          <Button type="primary" onClick={onNew} icon={<FileOutlined />}>
            {t("New")}
          </Button>
        </div>
      }>
      <Table loading={loading} dataSource={data}>
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("Name")} dataIndex="name" />
        <Table.Column
          title={t("Actions")}
          key="actions"
          render={(v, r) => (
            <div>
              <Button type="link" icon={<FolderOpenOutlined />} onClick={() => onOpen(r.id)} />
              <Divider type="vertical" />
              <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={() => onDelete(r.id)}>
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
            </div>
          )}
        />
      </Table>
    </DefaultPage>
  );
};

export default Presentations;
