import React from "react";
import { MedicineBoxOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Modal,
  Table,
  Select,
  DatePicker,
  Tabs,
  Row,
  Col,
  Input,
  message,
  Radio,
  InputNumber,
  Divider,
  Upload,
  Switch,
  Tag,
  Typography,
  Checkbox,
  Alert,
  Timeline,
} from "antd";
import { exe } from "Lib/Dal";
import moment from "moment";
import DiagnosisTree from "./DiagnosisTree";
import ContactSelect from "../Contact/ContactSelect";
import Status from "../Shared/Status";
import ProcedureTable from "./ProcedureTable";
import RequirementsTable from "./RequirementsTable";
import ClaimTimeline from "./ClaimTimeline";
import CptSelect from "./CptSelect";
import ProcedureRestrictions from "./ProcedureRestrictions";
import DiagnosisRequirements from "./DiagnosisRequirements";
import { type } from "os";
import DefaultPage from "../Shared/DefaultPage";
import { withTranslation } from "react-i18next";

const { Text, Title } = Typography;
const { Dragger } = Upload;
const ButtonGroup = Button.Group;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;
class Diagnosis extends React.Component {
  state = {
    loading: false,
    activeTab: "02",
  };

  save = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        console.log("Received values of form: ", values);
        this.setState({ loading: true });
        exe("RepoDiagnosis", { entity: values, operation: "ADDORUPDATE" }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            if (r.outData) this.setState({ diagnosis: r.outData[0] });
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
        message.error(this.props.t("Please fill all required fiedls in all tabs"));
      }
    });
  };
  new = () => {
    window.location.hash = "#/diagnosis/0";
    this.setState({ isPerson: true, diagnosis: {}, procedures: [] });
  };
  reset = () => {
    this.props.form.resetFields();
  };
  load = (code) => {
    if (code == 0) {
      this.props.form.resetFields();
      return;
    }
    this.setState({ loading: true });
    exe("RepoDiagnosis", { operation: "GET", filter: `code='${code}'`}).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        const d = r.outData[0];
        this.props.form.setFieldsValue(d);
        this.setState({ diagnosis: d, diagnosisCode: code });
      } else {
        message.error(r.msg);
      }
    });
  };

  onTabChange = (v) => this.setState({ activeTab: v });

  reload() {
    this.load(this.state.claim.id);
  }

  onDiagnosisSelected = (r) => {
    const f = {
      code: r.icd9||r.code,
      icd: r.icd9||r.code,
      name: r.descShort||r.name,
      description: r.descLong||r.name,
    };
    this.props.form.resetFields();
    this.props.form.setFieldsValue(f);
    this.setState({ activeTab: "01" });
    this.load(f.code);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const saved = this.state.diagnosis && this.state.diagnosis.code;
    const { t, i18n } = this.props;

    return (
      <div style={{}}>
        <DefaultPage
          title={t("Diagnosis and Procedure Management")}
          icon="environment"
          loading={this.state.loading}
          extra={
            <Row type="flex" justify="end" gutter={12}>
              <Col></Col>
              <Col>
                <ButtonGroup>
                  <Button onClick={this.save} icon={<SaveOutlined />}>
                    {t("Save")}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          }>
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <Tabs activeKey={this.state.activeTab} onChange={this.onTabChange}>
              <Tabs.TabPane
                tab={
                  <span>
                    <SearchOutlined />
                    {t("Search")}
                  </span>
                }
                key="02">
                <DiagnosisTree onSelect={this.onDiagnosisSelected} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <MedicineBoxOutlined />
                    {t("Diagnosis")}
                  </span>
                }
                key="01">
                {/* ////////////////////////////////TOP MENU ////////////////////////////////////////////////////////////////// */}

                <Title level={4}>{t("Info")}</Title>
                <Row gutter={16}>
                  {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                  <Col span={12}>
                    <Form.Item label={t("Name")}>{getFieldDecorator("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
                    <Form.Item label={t("Description")}>
                      {getFieldDecorator("description", { rules: [{ required: true }] })(<Input.TextArea autosize />)}
                    </Form.Item>
                    <Form.Item label={t("Audit Notes")}>
                      {getFieldDecorator("auditNotes", { rules: [{ required: false }] })(<Input.TextArea autosize placeholder={t("Optional auditor notes")} />)}
                    </Form.Item>
                  </Col>
                  {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <Form.Item label={t("ICD9 Code")}>{getFieldDecorator("code", { rules: [{ required: true }] })(<Input />)}</Form.Item>
                    <Form.Item label={t("Category")}>{getFieldDecorator("category", { rules: [{ required: false }] })(<Input />)}</Form.Item>
                    <Form.Item label={t("Rules")}>{getFieldDecorator("rules", { rules: [{ required: false }] })(<Input />)}</Form.Item>
                  </Col>
                  {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <Form.Item label={t("Average Cost")}>
                      {getFieldDecorator("averageCost", { rules: [{ required: false }] })(<InputNumber style={{ width: "100%" }} />)}
                    </Form.Item>
                    <Form.Item label={t("Expected Cost")}>
                      {getFieldDecorator("expectedCost", { rules: [{ required: false }] })(<InputNumber style={{ width: "100%" }} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <ProcedureRestrictions disabled={!saved} diagnosisCode={this.state.diagnosisCode} />
                </Row>
                <Row>
                  <DiagnosisRequirements disabled={!saved} diagnosisCode={this.state.diagnosisCode} />
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </DefaultPage>
      </div>
    );
  }
}

const WrappedContactForm = Form.create()(Diagnosis);
export default withTranslation()(WrappedContactForm);
