import React, {useEffect} from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, Popconfirm } from "antd";
import {useTranslation} from "react-i18next";
import CostCenterSelect from "./CostCenterSelect";
import {exe} from "../../Lib/Dal";
import CostCenterValueSelect from "./CostCenterValueSelect";

const CostCenterEdit = (props) => {
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    const values=props.form.getFieldsValue();
    
    useEffect(()=>{
        props.form.setFieldsValue(props.selected);
    },[props.visible])
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            console.log(values);
            props.onOk&&props.onOk(values);
            
        })
    }
    return (
        <div>
            <Button type={"link"} icon={<LegacyIcon type={"left"} />} onClick={()=>props.onBack()}>{t("Back")}</Button>
            <Button type="link" icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
            <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={()=>props.onDelete(props.selected)}>
                <Button type="link" icon={<DeleteOutlined />} >{t("Delete")}</Button>
            </Popconfirm>
            {field("id")}
            {field("transactionLineId")}
            {field("value")}
            <Form.Item label={t("Line")}>{field("line",{rules:[{required:true}]})(<InputNumber min={1} />)}</Form.Item>
            <Form.Item label={t("Cost Center")}>{field("costCenterCode",{rules:[{required:true}]})(<CostCenterSelect />)}</Form.Item>
            <Form.Item label={t("Cost Center Value")}>{field("valueCode",{rules:[{required:true}]})(<CostCenterValueSelect costCenterCode={values.costCenterCode} />)}</Form.Item>
        </div>
    );
};

export default Form.create()(CostCenterEdit);