import { Button, message } from 'antd';
import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const CodeBlock = ({ language, value }) => {
    const [t]=useTranslation();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    //Display a message or change button state upon copying
    message.success(t("Code copied to clipboard"));
  };

  return (
    <div>
      <SyntaxHighlighter language={language} >
        {value}
      </SyntaxHighlighter>
      <Button type="link" onClick={() => copyToClipboard(value)} icon={<CopyOutlined />} style={{padding:0}}>{t("Copy code")}</Button>
    </div>
  );
};
export default CodeBlock;
