import React, { useState, useEffect,useContext } from "react";
import { FileProtectOutlined } from '@ant-design/icons';
import { Select, Tooltip, ConfigProvider } from "antd";
import { exe } from "Lib/Dal";
import { useTranslation } from "react-i18next";

//used inside forms = props = fn onChange, string value
const GroupPolicySelect = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const configContext = useContext(ConfigProvider.ConfigContext);
  const direction = configContext.direction;

  useEffect(() => {
    if (props.display) {
      setData([{ id: props.value, code: props.display }]);
    }
    if (!props.display && (data.length == 0||props.refresh) && props.value) {
      exe("RepoLifePolicy", { operation: "GET", filter: `id=${props.value}`, include: ["Holder"] }).then((r) => {
        setData(r.outData || []);
        if(r.total>0){
        console.log(r,"Selection group policy");
            onSelect(r.outData[0].id,r.outData[0]) //fires select on load
        }
        // if (r.outData) {
        //   onSelect(r.outData[0]);
        //   props.onChange(r.outData[0].id);
        // }
      });
    }
  }, [props.value]);

  const handleSearch = (v) => {
      console.log("HAndling searc-------h",v)
    if (!v) return;
    exe("RepoLifePolicy", { operation: "GET", filter: `policyType='G' AND (code LIKE N'%${v}%' OR id LIKE N'%${v}%')`, include: ["Holder"] }).then((r) => {
      setData(r.outData || []);
    });
  };
  const onSelect = (policyId, policy) => {
    if (props.onSelect) props.onSelect(policy);
  };

  return (
    <div style={{ position: "relative" }}>
      <Select
        style={{ width: "100%" }}
        onSearch={handleSearch}
        onChange={props.onChange}
        value={props.value}
        showSearch
        filterOption={false}
        allowClear
        showArrow={false}
        onSelect={(v, o) => onSelect(v, o.props.policy)}
        placeholder={t("Type to search policy...")}>
        {data.map((d) => (
          <Select.Option key={d.id} value={d.id} policy={d}>
            {d.id}-{d.code}-{d.Holder.FullName}
          </Select.Option>
        ))}
      </Select>
      <a href={"#/lifePolicy/" + props.value}>
        <Tooltip title={t("Open Policy")}>
          <FileProtectOutlined
            style={{
              position: "absolute",
              top: "9px",
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.25)",
              fontSize: 12,...(direction=="rtl"?{left:"30px"}:{right:"30px"})
            }} />
        </Tooltip>
      </a>
    </div>
  );
};

export default GroupPolicySelect;
