import React, { Component, useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Modal, InputNumber } from "antd";
import CoverageCombo from "./CoverageCombo";
import { useTranslation } from "react-i18next";

const ManualExclusion = (props) => {
  const [t] = useTranslation();
  
  useEffect(() => {
    if(props.editRecord){
      props.form.setFieldsValue(props.editRecord)
    }else props.form.resetFields();
  }, [props.visible]);
  
  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        props.okOk(values);
      }
    });
  };
  const field = props.form.getFieldDecorator;
  return (
    <div>
      <Modal title={props.editRecord?t("Edit Exclusion"):t("Add Manual Exclusion")} visible={props.visible} onCancel={props.onCancel} onOk={onOk}>
        <Form>
          {field("id")}
          {field("lifePolicyId")}
          {field("code")}
          {field("mandatory")}
          <Form.Item label={t("Exclusion Summary")}>
            {field("name", {
              rules: [{ required: true }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t("Description")}>
            {field("description", {
              rules: [{ required: true }],
            })(<Input.TextArea />)}
          </Form.Item>
          <Form.Item label={t("Coverage")}>
            {field("coverageCode", {
              rules: [{ required: false }],
            })(<CoverageCombo coverages={props.coverages} />)}
          </Form.Item>
          <Form.Item label={t("Rule")}>
            {field("rule", {
              rules: [{ required: false }],
            })(<Input placeholder={t("Excluded procedures and events")} />)}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Form.create()(ManualExclusion);
