import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, InputNumber, message, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {exe, safeGet, safeGetRaw} from "../../Lib/Dal";
import { getConfigProfile } from "../../Lib/Helpers";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import AccountSelect from "../Shared/AccountSelect";
import ReasonSelect from "../Life/ReasonSelect";

const ComParticipantDetail = (props) => {
  const field = props.form.getFieldDecorator;
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation();
  const [contactRoles, setContactRoles] = useState([]);
  const [deactivationReasonVisible, setDeactivationReasonVisible] = useState(false);
  const [deactivationReasonRequired, setDeactivationReasonRequired] = useState(false);

  useEffect(() => {
    getConfigProfile().then((profile) => {
      setContactRoles(safeGet(["Commissions", "contactRoles"], window.global.configProfile, []));
      setDeactivationReasonRequired(safeGetRaw(["Commissions", "participantDeactivationReason"], window.global.configProfile, false));
    });
  }, []);

  useEffect(() => {
    if (props.selected) {
      setDeactivationReasonVisible(props.selected.statusReason);
      props.form.setFieldsValue(props.selected);
    }
  }, [props.selected]);

  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if(values.lineId.includes(",")){
        multiLine(values);
        return;
      }
      setLoading(true);
      exe("RepoComParticipant", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          props.onSave && props.onSave();
          props.form.resetFields();
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const multiLine=(values)=>{
    const lines=values.lineId.split(",");
    lines.forEach((lineName,index)=>{
      const lineParticipant={...values,lineId:lineName.trim()};
      exe("RepoComParticipant", { operation: "ADD", entity: lineParticipant }).then((r) => {
        setLoading(false);
        if (r.ok) {
          if(index===lines.length-1){
            props.onSave && props.onSave();
            props.form.resetFields();
          }
        } else {
          message.error(r.msg);
        }
      });
      
    })
  }
  const onCancel = () => {
    props.form.resetFields();
    props.onCancel && props.onCancel();
  };
  const onActiveChange=v=>{
    if(deactivationReasonRequired){
      setDeactivationReasonVisible(values.id&&values.active&&!v); 
      props.form.setFieldsValue({statusReason:undefined});
    }
  }
const values=props.form.getFieldsValue();
  return (
    <div>
      <Modal title={t("Participant")} visible={props.visible} onCancel={onCancel} onOk={onSubmit} okButtonProps={{ loading: loading }}>
        {field("id")}
        {field("comContractId", { initialValue: props.contractId })}
        <Form.Item label={t("Active")}>{field("active", { valuePropName: "checked" })(<Switch onChange={onActiveChange} />)}</Form.Item>
        {deactivationReasonRequired&&deactivationReasonVisible&&<Form.Item label={t("Deactivation Reason")} >{field("statusReason",{rules:[{required:deactivationReasonVisible}]})(<ReasonSelect catalog="ComParticipant" style={{width:"100%"}} />)}</Form.Item>}
        <Form.Item label={t("Participant")}>
          {field("contactId", { rules: [{ required: true }] })(
            <SimpleContactSelect allowNew
              filter={
                contactRoles.length == 0
                  ? undefined
                  : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `
              }
            />
          )}
        </Form.Item>
        <Form.Item label={t("Line ID")}>{field("lineId", { rules: [{ required: true }] })(<Input />)}</Form.Item>
        {!props.isUmbrella && <Form.Item label={t("Split %")}>{field("split", { rules: [{ required: true }] })(<InputNumber />)}</Form.Item>}
        <Form.Item label={t("Account")}>{field("accountId", { rules: [{ required: false }] })(<AccountSelect style={{ width: "100%" }} />)}</Form.Item>
        <Form.Item label={t("Notes")}>{field("notes", { rules: [{ required: false }] })(<Input.TextArea />)}</Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(ComParticipantDetail);
