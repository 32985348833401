import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const PlanSelect = (props) => {
  const [t] = useTranslation();
  const config = props.config;
  return (
    <Select {...props} allowClear onChange={(v) => props.onChange(v)} placeholder={t("No plan selected")} value={props.value}>
      {props.config
        ? props.config.Plans
          ? props.config.Plans.map((plan) => (
              <Select.Option value={plan.code} key={plan.code}>
                {plan.name}
              </Select.Option>
            ))
          : null
        : null}
    </Select>
  );
};

export default PlanSelect;
