import React, { useEffect, useState } from "react";
import { Select, InputNumber, Space } from "antd";
import { useTranslation } from "react-i18next";

const Compare = (props) => {
  const [t] = useTranslation();
  const [isBetween, setIsBetween] = useState(false);

  useEffect(() => {
    if (!props.value) setIsBetween(false);
  }, [props.value]);

  const onChange = (s, n) => {
    if (s !== undefined){
      setIsBetween(s === " BETWEEN ");
      props.onChange(s + getNumber());
    } 
    if (n !== undefined) props.onChange(getSymbol() + n);
  };
  const onChangeBetween = (lower, upper) => {
    if(lower!==undefined){
      props.onChange(" BETWEEN "+lower + " AND " + getUpperNumber());
    }
    if(upper!==undefined){
      props.onChange(" BETWEEN "+getLowerNumber() + " AND " + upper);
    }
  }

  const getSymbol = () => {
    if(isBetween){
      return props.value?" BETWEEN ":"";
    }
    return props.value ? props.value.charAt(0) : "";
  };
  const getNumber = () => {
    if(isBetween) return ""; //resetting the value
    return props.value ? props.value.slice(1) : "";
  };
  const getLowerNumber = () => {
    return props.value ? (props.value.split(" BETWEEN ")[1].split(" AND ")[0]||"") : "";
  }
  const getUpperNumber = () => {
    return props.value ? (props.value.split(" AND ")[1]||"") : "";
  }
  const onClear = () => {
    props.onChange(undefined);
    setIsBetween(false);
  }

  return (
    <div style={{ display: "flex" }}>
      <Select allowClear onClear={onClear} value={getSymbol() || undefined} onChange={(v) => onChange(v, undefined)} style={{ marginRight: 5,width:isBetween?200:"100%" }} placeholder={t("Comparison mode")}>
        <Select.Option value="=">{t("Equal")}</Select.Option>
        <Select.Option value=">">{t("Greater than")}</Select.Option>
        <Select.Option value="<">{t("Less than")}</Select.Option>
        <Select.Option value=" BETWEEN ">{t("Between")}</Select.Option>
      </Select>
      {!isBetween&&<InputNumber style={{ width: "100%" }} value={isBetween?getUpperNumber():getNumber()} onChange={(v) => onChange(undefined, v)} placeholder={t("Value")} />}
      {isBetween&&(<Space >
        <InputNumber style={{ width: "100%" }} value={getLowerNumber()} onChange={(v) => onChangeBetween(v, undefined)} placeholder={t("Lower Value")} />
        <InputNumber style={{ width: "100%" }} value={getUpperNumber()} onChange={(v) => onChangeBetween(undefined, v)} placeholder={t("Upper value")} />
        </Space>)}
    </div>
  );
};

export default Compare;
