import React from "react";
import { DownOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Card, Input, Button, message, Dropdown, Menu, Typography } from "antd";
import { get, put, setValues, post, exe } from "../Lib/Dal";
import CambioPass from "./Configuracion/CambioPass";
import DefaultPage from "./Shared/DefaultPage";
import Section from "./Shared/Section";
import QRCode from "qrcode.react";
import Otp from "./Otp";
import { withTranslation } from "react-i18next";
import UserSignature from "./UserSignature";

class MisDatos extends React.Component {
  state = {
    cambioPass: false,
    user: null,
  };
  componentDidMount() {
    exe("GetCurrentUser").then((r) => {
      this.props.form.setFieldsValue(r.outData);
      this.setState({ user: r.outData });
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return;
      exe("ChangeUserName", { email: values.email,name:values.nombre}).then(r=>{
        if(r.ok){
          message.success(r.msg);
        }else message.error(r.msg);
      });
    });
  };
  showCambioPass() {
    this.setState({ cambioPass: true });
  }
  onHideCambioPass = () => {
    this.setState({ cambioPass: false });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
      },
    };
    const { t } = this.props;
    const otpEnabled = this.state.user ? Boolean(this.state.user.otp) : false;
    return (
      <DefaultPage title={t("User profile")} icon="user">
        <Card
          style={{ width: 500 }}
          title={t("User Profile")}
          extra={
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item onClick={(p) => this.showCambioPass()}>{t("Change Password")}</Menu.Item>
                </Menu>
              }>
              <Button>
                {t("Actions")}
                <DownOutlined />
              </Button>
            </Dropdown>
          }>
          <Section text={t("Basic User Data")}>
            <Form onSubmit={this.handleSubmit}>
              <Form.Item {...formItemLayout} label={t("E-mail")}>
                {getFieldDecorator("email", {
                  rules: [
                    { type: "email", message: t("Please insert a valid Email") },
                    { required: true, message: t("Please insert Email") },
                  ],
                })(<Input disabled />)}
              </Form.Item>
              <Form.Item {...formItemLayout} label={t("Full Name")}>
                {getFieldDecorator("nombre", {
                  rules: [{ required: true, message: t("Please insert your name") }],
                })(<Input />)}
              </Form.Item>

              <Button icon={<SaveOutlined />} type="primary" htmlType="submit">
                {t("Save")}
              </Button>
            </Form>
          </Section>
          {/* {this.state.cambioPass&&<CambioPass visible={this.state.cambioPass} userId={this.state.userId} onHide={this.onHideCambioPass}  />} */}
          <CambioPass visible={this.state.cambioPass} email={this.state.user ? this.state.user.email : null} onHide={this.onHideCambioPass} configProfile={this.props.configProfile} />
        </Card>
        <Card title="2FA" style={{ width: 500, marginTop: 5 }}>
          <Otp creating={!otpEnabled} disabling={otpEnabled} secret={this.state.user ? this.state.user.otp : ""} />
        </Card>
        <Card title={t("Signature")} style={{ width: 500, marginTop: 5 }}>
          <UserSignature />
        </Card>
      </DefaultPage>
    );
  }
}
const WrappedForm = Form.create()(MisDatos);
export default withTranslation()(WrappedForm);
