import React from "react";
import { PlusOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Select, Spin, Tooltip, Empty, Modal, Button, Tag, Radio, Divider,ConfigProvider, Popover } from "antd";
import { exe, safeGet } from "../../Lib/Dal";
import NewContactModal from "./NewContactModal";
import { withTranslation } from "react-i18next";
import Contact from "../Contact";
import BeneficiaryBranchSelect from "../Life/BeneficiaryBranchSelect";
import GuardianForm from "./GuardianForm";
import { formatDateShort } from "../../Lib/Helpers";

const Option = Select.Option;

class ContactSelect extends React.Component {
  static contextType = ConfigProvider.ConfigContext;
  static getDerivedStateFromProps(nextProps, state) {
    // Should be a controlled component if inside form
    if ("value" in nextProps) {
      if (typeof nextProps.value == "object") {
        //passed key & label
        return { value: nextProps.value.key, selectValue: nextProps.value };
      } else {
        //passed obly key
        return { value: nextProps.value };
      }
    }

    return null;
  }

  static defaultProps = {
    exclude: [], //excluded contacts from list
    contactChange: (value) => {
      console.log("Contact selected:", value);
    },
  };
  state = {
    data: [],
    fetching: false,
    newContactModalVisible: false,
    isMinor:false,
    noGuardian:true,
    guardianFormVisible:false,
    searchBy:"name",
  };
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.value!==this.props.value){
      const contact=safeGet(["value","Contact",],this.props,null);
      if(contact&&contact.currentAge<18&&contact.isPerson!==this.state.isMinor){
        this.setState({isMinor:contact.currentAge<18&&contact.isPerson});
      }
    }
    
  }

  onSearch = (value) => {
    if (!value) return;
    console.log("fetching user", value, this.props.exclude);
    this.setState({ data: [], fetching: true });
    let query;
    let field;
    if(this.state.searchBy==="name") {
      //custom search
      if (value.startsWith("!")) {
        if (value.length < 3) return;
        if (!value.includes("=")) return;
        field = value.substring(1).split("=")[0];
        const fieldValue = value.substring(1).split("=")[1];
        if (!fieldValue) return;
        query = `${field} LIKE N'%${fieldValue}%'`;
        //text search
      } else if (isNaN(value)) {
        query = `(RTRIM(ISNULL([name],''))+' '+RTRIM(ISNULL(surname1,''))+' '+RTRIM(ISNULL(surname2,''))) like N'%${value}%'`;
        //numeric search
      } else {
        field = "cnp";
        query = `cnp LIKE N'%${value}%'`;
      }
    }else if(this.state.searchBy==="nationalId"){
      field = "nationalId";
      query = `nationalId LIKE N'%${value}%'`;
    }else if(this.state.searchBy==="passport") {
      field = "passport";
      query = `passport LIKE N'%${value}%'`;
    }else if(this.state.searchBy==="nif")
    {
      field = "nif";
      query = `nif LIKE N'%${value}%'`;
    }
    exe("GetContacts", { filter: query, size:10 }).then((r) => {
      console.log(r);
      const data = r.outData
        .filter((item) => this.props.exclude.indexOf(item.id) == -1)
        .map((user) => ({
          text: field ? `${user.FullName} (${field ? field + "=" : ""}${user[field]})` : `${user.FullName}`,
          value: +user.id,
          contact: user,
        }));
      this.setState({ data, fetching: false });
    });
  };

  onCloseNewContact(contact) {
    if (contact) {
      this.handleChange({ key: contact.id, label: `${contact.FullName}` }, { props: { contact } });
    }
    this.setState({ newContactModalVisible: false });
  }

  handleChange = (value, opt) => {
    if (value) {
      console.log("selected", value, opt);
      value.contact = opt.props.contact;
      //can be an array because of the minor tag
      value.label = (Array.isArray(value.label)?value.label[0]:value.label).replace(/\(.*\)/, ""); //removing text between parenthesis
    }

    this.setState({
      value: value ? value.key : 0,
      display: value ? value.label : undefined,
      data: [],
      fetching: false,
      selectValue: value ? value : undefined,
      isMinor: value && value.contact && value.contact.currentAge<18&&value.contact.isPerson,
    });
    this.props.contactChange && this.props.contactChange(value);
    this.props.onChange && this.props.onChange(value ? value.key : 0);

  };
  onBranchChange(branchId){
    const newValue={...this.state.selectValue,branchId:branchId};
    this.handleChange(newValue,{props:{contact:this.state.selectValue.Contact}})
  }
  onChangeSearchBy(e) {
    this.setState({searchBy:e.target.value})
  }
  onSelectRadioItem(e,item) {
    //workaround for antd bug
    e.preventDefault();
    this.setState({searchBy:item})
  }
  render() {
    const { t, i18n } = this.props;
    const { fetching, data, selectValue,isMinor } = this.state;

    //mapping component value to Select
    let comboValue = selectValue || { key: this.state.value, label: this.props.display }; //2. user selected value or 1. initial passed prop values
    if (comboValue.key !== this.state.value) comboValue = { key: this.state.value, label: this.props.display }; //3.update from outside
    if (!this.state.value) comboValue = undefined; //new redord or reseted value

    const contact = safeGet(["contact"], this.props, null);
    const isPerson = contact ? contact.isPerson : true;
    const direction=this.context.direction;

    let Action;
    if (!comboValue) {
      Action = (
          this.props.disableAddContact?null:<Tooltip title={t("New Contact")} >
          <PlusOutlined
            style={{ position: "absolute", top: "8px", cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", 
            fontSize: 16,...(direction=="rtl"?{left:"5px"}:{right:"5px"})} }
            onClick={() => this.setState({ newContactModalVisible: true })} />
        </Tooltip>
      );
    } else {
      Action = (<div>
            {isMinor &&<div><Modal visible={this.state.guardianFormVisible} title={t("Minor Status")}
                                   okButtonProps={{ style: { display: 'none' } }}
                                   forceRender 
                                   onCancel={()=>this.setState({guardianFormVisible:false})}>
              <GuardianForm contactId={this.state.value}
                            onNoGuardian={(v)=>this.setState({noGuardian:v})}
                            onOk={(v)=>this.setState({noGuardian:false,guardianFormVisible:false})} />
              </Modal>
              <Tooltip title={t("Click for minor status details")}>
              <WarningOutlined
                style={{ position: "absolute", top: "8px", 
                cursor: "pointer", color: this.state.noGuardian?"red":"rgba(0, 0, 0, 0.25)", fontSize: 16,...(direction=="rtl"?{left:"50px"}:{right:"50px"}) }}
                onClick={()=>this.setState({guardianFormVisible:true})} />
                </Tooltip>
            </div>
           }
        <Tooltip title={t("View contact")}>
          <LegacyIcon
            type={isPerson ? "user" : "bank"}
            style={{ position: "absolute", top: "8px", cursor: "pointer", color: "rgba(0, 0, 0, 0.25)",
             fontSize: 16,...(direction=="rtl"?{left:"20px"}:{right:"30px"}) }}
            onClick={() => (window.location.hash = "#/contact/" + this.state.value)}
          />
        </Tooltip>
      </div>
      );
    }
  
const selectNewContactFull=safeGet(["Contacts","selectNewContactFull"],this.props.configProfile,false);
    return (
      <div style={{ ...this.props.style, ...{ position: "relative" } }}>
        <Select
          labelInValue
          value={comboValue}
          showSearch
          allowClear
          onDropdownVisibleChange={(open) => console.log(open)}
          disabled={this.props.disabled}
          //suffixIcon={<div> <Icon type="search" /> </div>}
          showArrow={false}
          placeholder={t("Type to search contact...")}
          notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
          filterOption={false}
          onSearch={this.onSearch}
          onChange={this.handleChange}
          dropdownRender={(menu) => <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div onMouseDown={e => e.preventDefault()}
                style={{ margin: '4px 4px 4px 4px', padding:"4px 4px 8px 4px" }}>
              <Radio.Group onChange={e=>this.onChangeSearchBy(e)} value={this.state.searchBy}>
                <Radio value={"name"}>{<span onClick={e=>this.onSelectRadioItem(e,"name")}>{t("Name")}</span>}</Radio>
                <Radio value={"nationalId"}>{<span onClick={e=>this.onSelectRadioItem(e,"nationalId")}>{t("National ID")}</span>}</Radio>
                <Radio value={"passport"}>{<span onClick={e=>this.onSelectRadioItem(e,"passport")}>{t("Passport")}</span>}</Radio>
                <Radio value={"nif"}>{<span onClick={e=>this.onSelectRadioItem(e,"nif")}>{t("NIF")}</span>}</Radio>
              </Radio.Group>
            </div>
          </div>}
          style={{ width: "100%" }}>
          {data.map((d) => (
            <Option key={d.value} contact={d.contact}>
              {d.text} {d.contact.currentAge<18&&d.contact.isPerson&&<Tag color="orange">{t("Minor")}</Tag>} {<Popover zIndex={9999} content={<div>
                <div>{t("Date of Birth")}: {d.contact.birth?formatDateShort(d.contact.birth):""}</div>
                <div>{t("Age")}: {d.contact.currentAge}</div>
              </div>} title={t("More Info")}><InfoCircleOutlined style={{color:"rgba(0, 0, 0, 0.25)"}} /></Popover>} 
            </Option>
          ))}

        </Select>
        {Action}
        {!selectNewContactFull&&<NewContactModal visible={this.state.newContactModalVisible} onClose={(newContact) => this.onCloseNewContact(newContact)} />}
        {selectNewContactFull&&<Modal visible={this.state.newContactModalVisible} width={"90%"} onCancel={(newContact) => this.onCloseNewContact(newContact)} footer={[
          <Button  onClick={() => this.onCloseNewContact()}>
            {t("Close")}
          </Button>,
        ]}>
          <Contact match={{params:{contactId:0}}} configProfile={this.props.configProfile} modal onAdd={(newContact) => this.onCloseNewContact(newContact)}/>
        </Modal>}
        {this.props.showContactBranch&&(this.state.selectValue&&this.state.selectValue.Contact&&!this.state.selectValue.Contact.isPerson)&&<BeneficiaryBranchSelect value={this.state.selectValue.branchId} contactId={this.state.selectValue.key} onBranchChange={v=>this.onBranchChange(v)} />}
      </div>
    );
  }
}
export default withTranslation()(ContactSelect);
