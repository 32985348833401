import React, { useEffect } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input } from "antd";
import Money from "../Shared/Money";
import { useTranslation } from "react-i18next";
import ReceiptTypeSelect from "../Tax/ReceiptTypeSelect";
import SurchargesDetailField from "./SurchargesDetailField";

const BillUnique = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const currency = props.currency;

  useEffect(() => {
    setValues(props.value);
  }, [props.value]);

  const setValues = (v) => {
    props.form.setFieldsValue(v);
    props.onChange(v);
  };
  
  const getSurcharges=()=>{
    if(props.change&&props.change.Surcharges) return props.change.Surcharges;
    if(props.value&&props.value.Surcharges) return props.value.Surcharges;
    
    return [];
  }

  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t("Concept")}>{field("concept", {})(<Input />)}</Form.Item>
          <Form.Item label={t("Charges")}>{field("charges", {})(<SurchargesDetailField Surcharges={getSurcharges()} currency={currency}/>)}</Form.Item>
          <Form.Item label={t("Tax")}>{field("uniqueTax", {})(<Money currency={currency} />)}</Form.Item>
          <Form.Item label={t("Total")}>{field("uniqueTotal", {})(<Money currency={currency} />)}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("Fiscal Document Type")}>
            {field("receiptTypeCode")(<ReceiptTypeSelect  />)}
          </Form.Item>
          <Form.Item label={t("Fiscal Document Number")}>
            {field("fiscalNumber")(<Input disabled   />)}
          </Form.Item>
          <div style={{ visibility: "hidden" }}>
            <Form.Item>{field("id", {})(<Input />)}</Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

const onValuesChange = (props, changed, all) => {
  props.onChange(all);
};
export default Form.create({ onValuesChange: onValuesChange })(BillUnique);
