import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContactSelect from "../Contact/ContactSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import DefaultPage from "../Shared/DefaultPage";
import BillingSummaryData from "./BillingSummaryData";

const BillingSummary = () => {
  const [t, i18n] = useTranslation();
  const [contactId, setContactId] = useState(null);
  return (
    <div>
      <DefaultPage title={t("Billing Summary")} icon="file-sync">
        <div style={{ marginLeft: 5, marginBottom: 5, width: 500, display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 5 }}>{t("Policyholder")}:</span>
          <SimpleContactSelect value={contactId} onChange={(v) => setContactId(v)} searchNif />
        </div>
        <BillingSummaryData contactId={contactId} />
          
      </DefaultPage>
    </div>
  );
};

export default BillingSummary;
