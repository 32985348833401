import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const RiskTypeSelect = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => load(), []);
  const load = () => {
    exe("RepoRiskTypeCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select allowClear placeholder={t("Risk Type")} onChange={(v) => props.onChange(v)} value={props.value}>
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default RiskTypeSelect;
