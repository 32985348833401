import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const DisputeStatusSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState([
      { code: 0, name: "Undisputed" },
      { code: 1, name: "Dispute Started" },
      { code: 2, name: "Dispute Ended" }
  ]);
  
  return (
    <div>
      <Select
        showArrow
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default DisputeStatusSelect;
