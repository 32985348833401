import React, { useState, useEffect } from "react";
import ButtonGroup from "antd/lib/button/button-group";
import { FileOutlined, FilterOutlined, UserOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Button, Divider } from "antd";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import AccountsFilter from "./AccountsFilter";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";

const Accounts = () => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });

  useEffect(() => load(), []);

  const load = (current = 1) => {
    exe("RepoAccount", { operation: "GET", size: pagination.pageSize, page: current - 1 }).then((r) => {
      setData(r.outData);
      setPagination({ ...pagination, total: r.total, current: current });
    });
  };
  const onNewAccount = () => {
    window.location = "#/account/0";
  };
  const onReload = () => {
    load();
  };

  const onFilterResults = (results) => {
    setData(results);
  };
  const onChangePagination = (pag) => {
    setPagination(pag);
    load(pag.current);
  };

  return (
    <DefaultPage
      title={t("Accounts")}
      icon="global"
      extra={
        <ButtonGroup>
          <Button icon={<FileOutlined />} onClick={() => onNewAccount()}>
            {t("New")}
          </Button>
          <Button icon={<ReloadOutlined />} onClick={() => onReload()}>{t("Reload")}</Button>
          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        </ButtonGroup>
      }>
      <div>
        <Table rowKey="id" dataSource={data} pagination={pagination} onChange={(pag) => onChangePagination(pag)}>
          <Table.Column title={t("Number")} dataIndex="accNo" />
          <Table.Column title={t("Currency")} dataIndex="currency" render={(v) => <Currency.Flag currency={v} />} />
          <Table.Column title={t("Product")} dataIndex="investmentPlanCode" />
          <Table.Column title={t("Holder")} dataIndex="holderId" />
          <Table.Column title={t("Policy")} dataIndex="lifePolicyId" />
          <Table.Column title={t("Type")} dataIndex="type" />

          <Table.Column
            title={t("Action")}
            render={(text, record) => (
              <span>
                <a href={"#/account/" + record.id}>{t("Open")}</a>
                <Divider type="vertical" />
                <a href={"#/contact/" + record.holderId}>
                  <UserOutlined></UserOutlined>
                </a>
              </span>
            )}
          />
        </Table>
      </div>
      <AccountsFilter visible={filterVisible} onClose={() => setFilterVisible(false)} onResults={(r) => onFilterResults(r)} />
    </DefaultPage>
  );
};

export default Accounts;
