import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const AccountNatureSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setData([
      { code: "D", name: t("Debit") },
      { code: "C", name: t("Credit") },
      { code: "DC", name: t("Debit/Credit") }
    ])
  };
  const onSelect = (v, opt) => {
    props.onSelect && props.onSelect(opt.props.record);
  };
  return (
    <Select
      value={props.value ? props.value : undefined}
      onChange={(v) => props.onChange(v)}
      onSelect={onSelect}
      loading={loading}
      style={{ width: "100%", ...props.style }}
      placeholder={t("Please select...")}
      disabled={props.disabled}
      allowClear>
      {data.map((p) => (
        <Select.Option value={p.code} key={p.code} record={p}>
          {p.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AccountNatureSelect;