import { FileOutlined, FolderOpenOutlined, ReloadOutlined } from '@ant-design/icons';
import {Button, Checkbox, message, Table, Tabs} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FiscalDocForm from "./FiscalDocForm";
import {formatDateShort} from "../../Lib/Helpers";

const FiscalDocs = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [selected,setSelected]=useState();
    
    useEffect(() => {
        load();
    }, []);
    
    const load = () => {
        setLoading(true);
        exe("RepoFiscalDoc", { operation: "GET",include:["ReceiptType"] }).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else {
                message.error(r.msg);
            }
        });
    };
    const save=(values)=>{
        setLoading(true);
        exe("RepoFiscalDoc",{operation:values.id?"UPDATE":"ADD",entity:values}).then(r=>{
            setLoading(false);
            if(r.ok){
                setSelected();
                load();
                message.success(r.msg);
            }else message.error(r.msg);
        })
    }

    return (
        <DefaultPage
            title={t("Fiscal Documents Series")}
            icon="account-book"
            extra={
                <div>
                </div>
            }>
           
            <div style={{display:selected?"none":"block"}}>
                <Button type="primary" onClick={()=>setSelected({id:0})} icon={<FileOutlined />}>
                    {t("New")}
                </Button>
                <Button type="link" onClick={()=>load()} icon={<ReloadOutlined />}>
                    {t("Reload")}
                </Button>
                <Table loading={loading} dataSource={data} style={{marginTop:5}}>
                    <Table.Column title={t("Id")} dataIndex="id" />
                    <Table.Column title={t("Record Name")} dataIndex="name" />
                    <Table.Column title={t("Receipt Type")} dataIndex={["ReceiptType","name"]} />
                    <Table.Column title={t("Receipt Type Code")} dataIndex="receiptTypeCode" />
                    <Table.Column title={t("Active")} dataIndex="active" render={(v) => <Checkbox checked={v} />} />
                    <Table.Column title={t("Start")} dataIndex="numStart" />
                    <Table.Column title={t("End")} dataIndex="numEnd" />
                    <Table.Column title={t("Last")} dataIndex="numLast" />
                    <Table.Column title={t("Expires")} dataIndex="expires" render={v=>v?formatDateShort(v):"-"} />
                    <Table.Column
                        title={t("Actions")}
                        key="actions"
                        render={(v, r) => (
                            <div>
                                <Button type="link" icon={<FolderOpenOutlined />} onClick={() => setSelected(r)} />
                            </div>
                        )}
                    />
                </Table>
            </div>
            <div style={{display:selected?"block":"none"}}>
                <FiscalDocForm onCancel={()=>setSelected()} onSave={values=>save(values)} selected={selected} />
            </div>
        </DefaultPage>
    );
};

export default FiscalDocs;
