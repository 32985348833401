import {Badge, message, Select} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AuditStatusSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setData([
        {code:0,name:t("None")},
        {code:1,name:t("Ready for audit")},
        {code:2,name:t("Audited")},
    ])
  };
  const getStatus=v=>{
      switch (v) {
          case 0:
              return "default"
          case 1:
              return "processing"
          case 2:
              return "success"
      }
  }
  return (
    <div>
      <Select
        showArrow
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children">
          {data.map(p=><Select.Option value={p.code} key={p.code}>
            <Badge status={getStatus(p.code)}  />{p.name}
          </Select.Option>)}
      </Select>
    </div>
  );
};

export default AuditStatusSelect;
