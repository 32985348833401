import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, Table, Tooltip } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatMoney } from "../../Lib/Helpers";
import Money from "../Shared/Money";

const CommissionDetailField = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);

    
    return (
        <div style={{ display: "flex" }}>
            <Money value={props.value} onChange={props.onChange} currency={props.currency} disabled={props.disabled} />
            <Tooltip title={t("Click for detail")}>
                <Button type="link" icon={<InfoCircleOutlined />} onClick={() => window.location.hash=`#/commissions?policyId=${props.lifePolicyId}`} />
            </Tooltip>
        </div>
    );
};

export default CommissionDetailField;
