import React,{useContext} from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FolderOpenTwoTone } from '@ant-design/icons';
import { Row, Button, Spin, Descriptions, Tag, ConfigProvider } from "antd";
import { formatDate } from "../../Lib/Helpers";
import { formatter } from "../Shared/Money";
import { safeGet } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";
import PolicyLink from "./PolicyLink";

const PolicyHeader = (props) => {
  const [t] = useTranslation();
  const policy = props.policy;
  const holder = props.policy.Holder ? props.policy.Holder.FullName : "";
  const holderId = props.policy ? props.policy.holderId : "";
  const product = props.policy.Product ? props.policy.Product.name : "";
  const start = props.policy ? (props.policy.start ? formatDate(props.policy.start) : t("Not Defined")) : t("Not Defined");
  const capital = props.policy ? formatter(props.policy.currency).format(props.policy.insuredSum) : t("Not Defined");
  const isPerson = props.policy.Holder ? props.policy.Holder.isPerson : true;
  const configContext = useContext(ConfigProvider.ConfigContext);
  const direction = configContext.direction;


  const getStatus = (code) => {
    switch (code) {
      case 0:
        return <Tag color="green">{t("Up to date")}</Tag>;
      case 1:
        return <Tag color="orange">{t("Grace period")}</Tag>;
      case -1:
        return <Tag color="red">{t("Lapsed")}</Tag>;
      default:
        return <Tag>{t("N/A")}</Tag>;
    }
  };
  const billingStatus = props.policy ? getStatus(props.policy.billingStatus) : "";
  const lastUpdate = props.policy ? (props.policy.lastUpdate ? formatDate(props.policy.lastUpdate) : t("Never")) : "";

  return (
    <div>
      <Descriptions size="small" column={policy.inactiveReason ? 4 : 3}>
        {/* ********************** FIRST ROW *********************** */}
        <Descriptions.Item label={t("Holder")}>
          <a href={"#/contact/" + holderId}>
            <LegacyIcon type={isPerson ? "user" : "bank"} style={{ marginLeft: 5, marginRight: 5 }} />
            {holder}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={t("Capital")}>{capital}</Descriptions.Item>
        <Descriptions.Item label={t("Billing Status")}>{billingStatus}</Descriptions.Item>
        {policy.inactiveReason && (
          <Descriptions.Item label={t("Inactive Reason")}>
            <Tag color="red">{policy.inactiveReasonText?policy.inactiveReasonText:policy.inactiveReason}</Tag>
          </Descriptions.Item>
        )}
        {/* ********************** SECOND  ROW *********************** */}
        <Descriptions.Item label={t("Product")}>
          <a href="#/policies">
            <FolderOpenTwoTone style={direction=="rtl"?{marginLeft:5}:{ marginRight: 5 }} />
            {product}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={t("Effective")}>{start}</Descriptions.Item>
        <Descriptions.Item label={t("Updated")}>{lastUpdate}</Descriptions.Item>
        {/* ********************** THIRD  ROW *********************** */}
        {policy.id && !policy.active && (
          <Descriptions.Item label={t("Status")}>
            <Tag color={policy.active ? "green" : "red"}>{policy.active ? t("Active") : t("Inactive")}</Tag>
          </Descriptions.Item>
        )}
        {policy.inactiveDate && <Descriptions.Item label={t("Inactive Date")}>{formatDate(policy.inactiveDate)}</Descriptions.Item>}
        {policy.groupPolicyId && <Descriptions.Item label={t("Group Policy")}>{<PolicyLink policyId={policy.groupPolicyId} /> }</Descriptions.Item>}
      </Descriptions>

      {/* <Steps size="small" style={{ marginTop: 10, width: 500 }}>
        <Steps.Step status="finish" title="Draft">
          hola
        </Steps.Step>
        <Steps.Step status="finish" title="Aproval" />
        <Steps.Step status="process" title="Active" />
        <Steps.Step status="wait" title="Inactive" />
      </Steps> */}
    </div>
  );
};

export default PolicyHeader;
