import React from 'react';
import {useTranslation} from "react-i18next";
import {Select} from "antd";

const ReinstatementInstallmentStatus = ({value,onChange}) => {
    const [t]=useTranslation();
    const data=[{code:0,name:t("Pending")},{code:1,name:t("Paid")}]
    return (
        <div>
            <Select value={value} onChange={onChange}>
                {data.map(p=><Select.Option value={p.code}>{p.name}</Select.Option>)}
            </Select>
        </div>
    );
};

export default ReinstatementInstallmentStatus;