import React, { useState, useEffect } from "react";
import { exe } from "../../Lib/Dal";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip, Badge } from "antd";
import { useTranslation } from "react-i18next";

const InvestmentPlanSelect = (props) => {
  const { t, i18n } = useTranslation();
  const [investmentPlans, setInvestmentPlans] = useState([]);

  useEffect(() => {
    exe("RepoInvestmentPlan", { operation: "GET" }).then((d) => {
      let selectablePlans = d.outData;
      if (props.strategies && props.strategies.length > 0) {
        const planCodes = props.strategies.flatMap(p => p.selections).map(p => p.investmentPlanCode);
        selectablePlans = selectablePlans.filter(p => planCodes.includes(p.code));
      }
      setInvestmentPlans(selectablePlans);
    });
  }, [props.strategies]);


  const onChange = (v) => {
    props.onChange && props.onChange(v);
  };

  const getStatusColor = (status) => {
    const st = (status || "").toLowerCase();
    if (st.includes("high")) {
      return "error";
    } else if (st.includes("moderate")) {
      return "warning";
    } else {
      return "success";
    }
  };

  let InvestmentPlanInfo;
  if (props.value) {
    InvestmentPlanInfo = (
      <Tooltip title={t("View investment plan configuration")}>
        <a href={"#/investmentPlans/" + props.value}>
          <InfoCircleOutlined
            style={{ position: "absolute", right: "30px", top: "8px", cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }}
          />
        </a>
      </Tooltip>
    );
  }

  return (
    <div style={{ ...props.style, position: "relative" }}>
      <Select
        placeholder={t("Please select option")}
        onChange={onChange}
        value={props.value}
        disabled={props.disabled} 
      >
        {investmentPlans.map((p) => (
          <Select.Option key={p.code} value={p.code}>
            <Badge status={getStatusColor(p.type)} />
            <Tooltip title={p.description}>
              <span>{p.name}</span>
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
      {InvestmentPlanInfo}
    </div>
  );
};

export default InvestmentPlanSelect;
