import React, { Component } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Tabs,
  Row,
  Col,
  Input,
  message,
  InputNumber,
  Typography,
  Select,
  Slider,
  Table,
  Divider,
} from "antd";
import { exe } from "Lib/Dal";
import ProviderSelect from "./ProviderSelect";
import { withTranslation } from "react-i18next";

const { Title } = Typography;
const { Column } = Table;

class ClaimSummary extends Component {
  render() {
    const { t, i18n } = this.props;
    return (
      <div>
        <Title level={4}>{t("Claim Summary")}</Title>

        <Row gutter={16}>
          <Table dataSource={[]}>
            <Column title={t("Date")} dataIndex="date" />
            <Column title={t("Diagnosis")} dataIndex="diagnosis" />
            <Column title={t("Medical Provider")} dataIndex="provider" />
            <Column title={t("Status")} dataIndex="status" />
            <Column title={t("Auditor")} dataIndex="auditor" />
            <Column title={t("Paid")} dataIndex="payed" />
            <Column title={t("Claim ID")} dataIndex="claimId" />

            <Column
              title={t("Action")}
              key="action"
              render={(text, record) => (
                <span>
                  <Button type="link" onClick={() => this.handleOpenRecord(record)}>
                    {t("Open")}
                  </Button>
                  <Divider type="vertical" />
                </span>
              )}
            />
          </Table>
        </Row>
        <Title level={4}>{t("Claim Detail")}</Title>
        <Row gutter={16}>
          <Table dataSource={[]}>
            <Column title={t("Date")} dataIndex="date" />
            <Column title={t("Procedure")} dataIndex="procedure" />
            <Column title={t("Total Bill")} dataIndex="total" />
            <Column title={t("Covered")} dataIndex="covered" />
            <Column title={t("Not Covered")} dataIndex="notCovered" />
            <Column title={t("Copay")} dataIndex="covered" />
            <Column title={t("Reimbursement")} dataIndex="reimbursement" />

            <Column
              title={t("Action")}
              key="action"
              render={(text, record) => (
                <span>
                  <Button type="link" onClick={() => this.handleOpenRecord(record)}>
                    {t("Open")}
                  </Button>
                  <Divider type="vertical" />
                </span>
              )}
            />
          </Table>
        </Row>
      </div>
    );
  }
}

export default withTranslation()(ClaimSummary);
