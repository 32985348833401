import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {exe, safeGetRaw} from "../../Lib/Dal";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Select } from "antd";

const CancellationOptions = ({config,style,field}) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        load();
    }, []);
    
    const load = () => {
        const productData=safeGetRaw(["Main","cancellationOptions"],config,[]);
        setData(productData);
    };
    return (
        <div style={{display:data.length>0?"block":"none"}}>
            <Form.Item label={t("Cancellation Option")}>
                    {field("cancellationOption",{ rules: [{ required: data.length>0 }] })(<Select  placeholder={t("Please select option")} style={style}  allowClear>
                        {data.map((p) => (
                            <Select.Option value={p.code}>{p.name}</Select.Option>
                        ))}
                </Select>)}
            </Form.Item>
        </div>
    );
};

export default CancellationOptions;