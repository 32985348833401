import { FileOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Breadcrumb,
  Button,
  Col,
  Input,
  InputNumber,
  message,
  notification,
  Row,
  Switch,
  Tabs,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FormLabel from "../Shared/FormLabel";
import moment from "moment";
import yaml from "js-yaml";
import GoalMembers from "./GoalMembers";
import Currency from "../Shared/Currency";
import GoalStatus from "./GoalStatus";
import DatePickerW from "../Shared/DatePickerW";
import CodeEditorW from '../Shared/CodeEditorW';

const GoalDefinition = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.match.params.goalDefinitionId) return;
    if (props.match.params.goalDefinitionId == 0) {
      props.form.resetFields();
    } else {
      load(props.match.params.goalDefinitionId);
    }
  }, [props.match.params.goalDefinitionId]);

  const load = (id) => {
    setLoading(true);
    exe("RepoGoalDefinition", { operation: "GET", filter: `id=${id}` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.form.setFieldsValue(r.outData[0]);
        if (r.outData[0].config) window.global.myCodeMirror.setValue(r.outData[0].config);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      //extra fields
      values.config = window.global.myCodeMirror.getValue();
      //yaml validation
      try {
        const result = yaml.safeLoad(values.config);
        values.configJson = JSON.stringify(result);
      } catch (error) {
        notification["error"]({
          message: error.reason,
          description: error.message,
        });
        return;
      }
      const operation = values.id ? "UPDATE" : "ADD";
      setLoading(true);
      exe("RepoGoalDefinition", { operation: operation, entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          if (operation == "ADD") window.location = "#/goalDefinitionList";
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onNew = () => {
    window.location.hash = "#/goalDefinition/0";
  };
  const field = props.form.getFieldDecorator;

  return (
    <DefaultPage
      title={
        <span>
          {field("name")(<FormLabel />)} {t("Definition")}
        </span>
      }
      icon="trophy"
      routes={{
        routes: [
          { path: "/", breadcrumbName: t("Home") },
          { path: "goalDefinitionlist", breadcrumbName: t("Goals") },
          { path: "", breadcrumbName: t("Goal Definition") },
        ],
      }}
      extra={
        <div>
          <Button type="primary" onClick={onSave} icon={<SaveOutlined />} loading={loading} style={{ marginRight: 5 }}>
            {t("Save")}
          </Button>
          <Button onClick={onNew} icon={<FileOutlined />}>
            {t("New")}
          </Button>
        </div>
      }>
      <Tabs>
        <Tabs.TabPane tab={t("Main")} key="main">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
              <Form.Item label={t("Description")}>{field("description")(<Input.TextArea />)}</Form.Item>
            </Col>
            <Col span={8}>
              <div style={{ display: "flex" }}>
                <Form.Item label={t("Period Start")}>
                  {field("periodStart", { rules: [{ required: true }], normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}
                </Form.Item>
                <Form.Item label={t("Period Month Duration")}>
                  {field("periodDuration", { rules: [{ required: true }] })(<InputNumber min={1} style={{ marginLeft: 5 }} />)}
                </Form.Item>
              </div>
              <div style={{ display: "flex" }}>
                <Form.Item label={t("Reward Currency")}>
                  {field("rewardCurrency", { rules: [{ required: true }] })(<Currency style={{ width: 180 }} />)}
                </Form.Item>
                <Form.Item label={t("Max Periods")}>
                  {field("maxPeriods", { rules: [{ required: false }] })(<InputNumber  style={{ marginLeft: 5 }} />)}
                </Form.Item>
              </div>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Active")}>{field("active", { valuePropName: "checked" })(<Switch />)}</Form.Item>
              <Form.Item label={t("ID")}>{field("id")(<FormLabel />)}</Form.Item>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Members")} key="members" disabled={props.match.params.goalDefinitionId == 0}>
          <GoalMembers goalDefinitionId={props.match.params.goalDefinitionId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Behaviour")} key="behaviour" forceRender>
          <Title level={4}>{t("Goal Behaviour")}</Title>
          <CodeEditorW />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Goal Status")} key="status" disabled={props.match.params.goalDefinitionId == 0}>
          <GoalStatus definitionPage goalDefinitionId={props.match.params.goalDefinitionId} />
        </Tabs.TabPane>
      </Tabs>
    </DefaultPage>
  );
};

export default Form.create()(GoalDefinition);
