import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const CoverageCombo = (props) => {
  const data = props.coverages || [];
  const [t] = useTranslation();

  const Options = data.map((d) => (
    <Select.Option key={d.code} value={props.valueField?d[props.valueField]:d.code} label={d.code + " - " + d.name} coverage={d}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          {d.code} - {d.name}
        </div>
      </div>
    </Select.Option>
  ));
  const onSelect = (coverage,coverageObj) => {
    if (props.onSelect) props.onSelect(coverage,coverageObj);
  };
  const onClear = () => {
    if (props.onClear) props.onClear();
  };
  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      optionLabelProp="label"
      placeholder={t("Please select coverage")}
      style={props.style}
      onSelect={(v, o) => onSelect(v, o.props.coverage)}
      onClear={onClear}
      showArrow
      allowClear
      filterOption={false}>
      {Options}
    </Select>
  );
};

export default CoverageCombo;
