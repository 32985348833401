import React, { useState, useEffect } from "react";
import { MinusCircleOutlined, PlusCircleOutlined, ColumnHeightOutlined, ColumnWidthOutlined } from '@ant-design/icons';
import { Button, InputNumber, Select, Tooltip, message } from "antd";
import InvestmentPlanSelect from "../InvestmentPlans/InvestmentPlanSelect";
import { useTranslation } from "react-i18next";

const InvestmentSelection = (props) => {
  const [t, i18n] = useTranslation();
  const investments = props.value || [];

  const addInvestment = () => {
    let splitPercent = 100;
    let premiumType = 0;
    if (investments.length > 0) {
      //trying to complement standard premium
      splitPercent = 100 - investments.filter(p=>p.premiumType==0).reduce((acc, p) => acc + p.splitPercent, 0);
      if(splitPercent==0){
        //trying to complement adhoc premium
        premiumType = 1;
        splitPercent = 100 - investments.filter(p=>p.premiumType==1).reduce((acc, p) => acc + p.splitPercent, 0);
      }
    }
    props.onChange([...investments, { investmentPlanCode: undefined, splitPercent: splitPercent,premiumType:premiumType }]);
  };
  const removeInvestment = () => {
    props.onChange(investments.slice(0, investments.length - 1));
  };
  const onChange = (index, investmentPlanCode, splitPercent,premiumType) => {
      //validating if the sum of the splitPercent is less than 100 for each premiumType
      let totalStandard = 0;
      let totalAdhoc = 0;
      investments.forEach((p, i) => {
        if (i != index) {
          if (!p.premiumType) {
            totalStandard += p.splitPercent;
          } else {
            totalAdhoc += p.splitPercent;
          }
        }
      });
      
      if (!premiumType) {
        totalStandard += splitPercent;
      } else {
        totalAdhoc += splitPercent;
      }
      console.log(investments, splitPercent,premiumType,totalStandard,totalAdhoc);

      if (totalStandard > 100 || totalAdhoc > 100) {
        message.error(t("Total split percent for each premium type should not exceed 100"));
        //rejecting changes
        investments[index] = { ...investments[index], ...{ investmentPlanCode: null, splitPercent: 0, premiumType:premiumType }};
      }else{
        investments[index] = { ...investments[index], ...{ investmentPlanCode: investmentPlanCode, splitPercent: splitPercent, premiumType:premiumType } };
      }
      

    props.onChange([...investments]);
  };

  return (
    <div>
      {investments.map((p, index) => (
        <span style={{ display: "flex",alignItems:"flex-start" }} key={"investment" + index}>
          <InvestmentPlanSelect
            disabled={props.disabled}
            style={{ flex: 1, marginRight: 5, marginBottom: 5, fontSize: "0.5vw", overflow: "hidden" }}
            value={p.investmentPlanCode}
            onChange={(v) => onChange(index, v, p.splitPercent,p.premiumType)}
            strategies={props.strategies}
          />
          <InputNumber
            style={{ width: 75 }}
            defaultValue={100}
            min={0}
            max={100}
            formatter={(value) => `${value}%`}
            parser={(value) => value.replace("%", "")}
            value={p.splitPercent}
            disabled={props.disabled}
            onChange={(v) => onChange(index, p.investmentPlanCode, v,p.premiumType)}
          />
          <div style={{width:120,marginLeft:5}}>
            <Tooltip title={t("Premium Type")}>
              <Select value={p.premiumType?p.premiumType:0} onChange={v=>onChange(index,p.investmentPlanCode,p.splitPercent,v)}>
                <Select.Option value={0}>{t("Standard")}</Select.Option>
                <Select.Option value={1}>{t("Ad-hoc")}</Select.Option>
              </Select>
            </Tooltip>
          </div>
        </span>
      ))}
      <Button type="link" icon={<PlusCircleOutlined />} onClick={() => addInvestment()} disabled={props.disabled}>
        {t("Add")}
      </Button>
      <Button type="link" icon={<MinusCircleOutlined />} onClick={() => removeInvestment()} disabled={investments.length == 0 || props.disabled}>
        {t("Remove")}
      </Button>
      {/* <Button type="link" icon={showPremiumType?<ColumnHeightOutlined />:<ColumnWidthOutlined/>} onClick={()=>setShowPremiumType(!showPremiumType)} disabled={investments.length == 0 || props.disabled}>
        {showPremiumType?t("Hide Premium Type"):t("Show Premium Type")}
        </Button> */}

    </div>
  );
};

export default InvestmentSelection;
