import React, {useEffect, useState, useTransition} from 'react';
import {Select,message} from "antd";
import {exe} from "../../Lib/Dal";
import {useTranslation} from "react-i18next";

const LanguageSelect = (props) => {
    const [t,i18n]=useTranslation();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>load(),[]);
    
    const load=()=>{
        setLoading(true);
        exe("GetLanguages").then((r) => {
            setLoading(false);
            if (r.ok) setData(r.outData);else message.eror(r.msg);
        });
    }
    const onChange=code=>{
        i18n.changeLanguage(code);
        localStorage.setItem("language", code);
    }
    return (
        <div>
            <Select onChange={onChange} value={localStorage.language} style={{width:"100%"}}>
                {data.map(r=><Select.Option value={r.code}>{r.code === localStorage.language ? <b>{r.name}</b> : r.name}</Select.Option> )}
            </Select>
        </div>
    );
};

export default LanguageSelect;