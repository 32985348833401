import React, {useEffect, useState, useTransition} from 'react';
import config from "../../config";
import {Button, Result} from "antd";
import {useTranslation} from "react-i18next";
import queryString from "query-string";

const OpenFile = (props) => {
    const [t]=useTranslation();
    const [error,setError]=useState(false);
    
    useEffect(()=>{
        if (window.location.hash.includes("url=")) {
            const url=parseUrl();
            if(url) openFile(url);
        }
    },[window.location.hash])
    
    const gotoHome=()=>{
        window.location="#/home";
    }
    const parseUrl=()=>{
        const qs = queryString.parse(window.location.hash);
        console.log(qs, "QUERY");
        return qs.url
    }
    const openFile = (f) => {
        fetch(config.ssApiUrl + "/proxy/" + f, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.token,
            },
        })
            .then((response) =>{
                if(response.ok) {
                    setError(false);
                    return response.blob();
                }
                else Promise.reject("404");
            } )
            .then((data) => window.open(URL.createObjectURL(data)))
        .catch(e=>{
            setError(true);
            console.log(e);
        })
        
    };
    
    return (
        <div>
            {!error&&<Result
                status="success"
                title="Success"
                subTitle={<div><p>{t("Access to the file has been granted")}</p><p>
                    {t("Your browser may have blocked a pop-up window. If this is the case you can download the file below")}
                </p></div>}
                extra={[
                    <Button type="primary" onClick={()=>openFile(parseUrl())}>{t("Open File")}</Button>,
                    <Button key={"home"} onClick={()=>gotoHome()}>{t("Home")}</Button>,
                ]}
            />}
            {error&&<Result
                status="404"
                title="404"
                subTitle={t("Sorry, the file you requested does not exist or access has not been granted")}
                extra={<Button type="primary" onClick={gotoHome}>{t("Back Home")}</Button>}
            />}
        </div>
    );
};

export default OpenFile;