import React, { useState, useEffect } from "react";
import { DeleteOutlined, ReloadOutlined, RocketOutlined, SaveOutlined, SyncOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Typography, Input, Button, Row, Col, Switch, Descriptions, message } from "antd";
import { exe } from "../../Lib/Dal";
import FormLabel from "../Shared/FormLabel";
import { formatDate } from "../../Lib/Helpers";
import Inspector from "react-inspector";
import moment from "moment";
import { useTranslation } from "react-i18next";

const Job = (props) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [saving,setSaving]=useState(false)
  const [result, setResult] = useState(null);
  const field = props.form.getFieldDecorator;

  useEffect(() => {
    loadJob(props.value);
  }, props.value);

  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (values.lastExecution) values.lastExecution = values.lastExecution.format("YYYY-MM-DDTHH:mm:ss"); //eliminatign timezone info to use server time
      setSaving(true)
      exe("RepoJob", { operation: updateMode ? "UPDATE" : "ADD", entity: { ...props.value, ...values } }).then((r) => {
        setSaving(false);
        if(r.ok){
          message.success(r.msg)
          props.form.setFieldsValue(r.outData[0]);
          if (props.onExit) props.onExit();
        }else {
          message.error(r.msg);
        }
      });
      
    });
  };
  const onExecuteAppServer = (job) => {
    const jobCmd = eval("window.a=" + job.command);
    setLoading(true);
    exe(jobCmd.cmd, jobCmd.data, null, true).then((r) => {
      setLoading(false);
      props.form.setFieldsValue({ lastExecution: moment(), lastResultOk: r.ok, lastResultDto: JSON.stringify(r) });
      setResult(r);
    });
  };
  const onExecute = (job) => {
    setLoading(true);
    exe("LaunchJob", { id: job.id, jobName: job.name }, null, true).then((r) => {
      setLoading(false);
      props.form.setFieldsValue({ lastExecution: moment() });
    });
  };
  const onRefresh = (job) => {
    setReloading(true);
    exe("RepoJob", { operation: "GET", filter: "id=" + job.id }).then((r) => {
      setReloading(false);
      if (r.ok) {
        loadJob(r.outData[0]);
      } else {
        message.error(r.msg);
      }
    });
  };
  const loadJob = (job) => {
    if (job.id) setUpdateMode(true);
    props.form.setFieldsValue(job);
    if (job.lastResultDto) {
      setResult(JSON.parse(job.lastResultDto));
    }
  };
  const onDelete = () => {
    exe("RepoJob", { operation: "DELETE", entity: props.value }, null, true).then((r) => {
      if (props.onExit) props.onExit();
    });
  };
  const getFinishDate = (job) => {
    if (!job.lastFinish) return "-";
    if (moment(job.lastExecution).isAfter(job.lastFinish)) return <SyncOutlined spin />;
    else return formatDate(currentJob.lastFinish);
  };

  const currentJob = props.form.getFieldsValue();

  return (
    <div>
      <Typography.Title level={4}>{t("Job Description")}</Typography.Title>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t("Job Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          <Form.Item label={t("Command")}>{field("command", { rules: [{ required: true }] })(<Input.TextArea />)}</Form.Item>
          <Form.Item label={t("Cron")}>{field("cron", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          <Form.Item label={t("Disabled")}>{field("disabled", { valuePropName: "checked" })(<Switch />)}</Form.Item>
          <Row justify="space-between" type="flex" style={{ marginTop: 10 }}>
            <Col>
              <Button type="primary" icon={<SaveOutlined />} onClick={onSave} style={{ marginRight: 5 }} loading={saving}>
                {t("Save")}
              </Button>
              <Button icon={<RocketOutlined />} onClick={() => onExecute(props.value)} disabled={!updateMode} loading={loading}>
                {t("Execute Now")}
              </Button>
              <Button icon={<ReloadOutlined />} onClick={() => onRefresh(props.value)} disabled={!updateMode} loading={reloading} style={{ marginLeft: 5 }}>
                {t("Refresh")}
              </Button>
            </Col>
            <Col>
              <Button type="danger" icon={<DeleteOutlined />} onClick={onDelete}>
                {t("Delete")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <div style={{ display: "none" }}>
            <Form.Item>{field("lastExecution")(<Input />)}</Form.Item>
            <Form.Item>{field("lastFinish")(<Input />)}</Form.Item>
            <Form.Item>{field("lastResultOk")(<Input />)}</Form.Item>
            <Form.Item>{field("id")(<Input />)}</Form.Item>
            <Form.Item>{field("messageId")(<Input />)}</Form.Item>
            <Form.Item>{field("created")(<Input />)}</Form.Item>
          </div>
          <div style={{ display: updateMode ? "block" : "none" }}>
            <Form.Item label={t("Last Result")}>
              <Inspector data={result} expandLevel={2} />
            </Form.Item>

            <Descriptions column={1} size="small">
              <Descriptions.Item label={t("Id")}>{currentJob.id}</Descriptions.Item>
              <Descriptions.Item label={t("Created")}>{formatDate(currentJob.created)}</Descriptions.Item>
              <Descriptions.Item label={t("Last Launched")}>{currentJob.lastExecution ? formatDate(currentJob.lastExecution) : "-"}</Descriptions.Item>
              <Descriptions.Item label={t("Last Finished")}>{getFinishDate(currentJob)}</Descriptions.Item>
              <Descriptions.Item label={t("Last Event Bus Id")}>{currentJob.messageId ? currentJob.messageId : "-"}</Descriptions.Item>
              <Descriptions.Item label={t("Last OK")}>
                <FormLabel type="Boolean" value={currentJob.lastResultOk} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Col>
      </Row>
      <Form></Form>
    </div>
  );
};

export default Form.create()(Job);
