import {
  CheckSquareOutlined,
  EnterOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  StopOutlined,
  ToolOutlined,
} from '@ant-design/icons';

import { Button, Dropdown, Menu, Table } from "antd";
import React, { useState } from "react";
import AmendmentAddCoverage from "./AmendmentAddCoverage";
import AmendmentClause from "./AmendmentClause";
import AmendmentExclusion from "./AmendmentExclusion";
import AmendmentLoading from "./AmendmentLoading";
import AmendmentRemoveCoverage from "./AmendmentRemoveCoverage";

const Amendments = (props) => {
  const [typeSelected, setTypeSelected] = useState();
  const [amendmentSelected, setAmendmentSelected] = useState();
  const data = props.value || [];

  const onChangeType = (type) => {
    setTypeSelected(type.key);
  };
  const onAmendementChange = (v) => {
    const newAmendments = data.filter((p) => p.type !== typeSelected);
    newAmendments.push({ ...v, type: typeSelected });
    props.onChange(newAmendments);
  };
  const onSubmitAmendment = () => {
    setTypeSelected();
  };
  const onSelectAmendment = (r) => {
    setAmendmentSelected(r);
    setTypeSelected(r.type);
  };
  return (
    <div>
      {!typeSelected && (
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu onClick={onChangeType}>
              <Menu.Item key="AmendmentLoading">
                <ToolOutlined />
                Loading
              </Menu.Item>
              <Menu.Item key="AmendmentClause">
                <CheckSquareOutlined />
                Clauses
              </Menu.Item>
              <Menu.Item key="AmendmentExclusion">
                <StopOutlined />
                Exclusions
              </Menu.Item>
              <Menu.Item key="AmendmentAddCoverage">
                <PlusCircleOutlined />
                Add Coverage
              </Menu.Item>
              <Menu.Item key="AmendmentRemoveCoverage">
                <MinusCircleOutlined />
                Remove Coverage
              </Menu.Item>
            </Menu>
          }>
          <Button type="link" icon={<PlusCircleOutlined />} style={{ paddingLeft: 0 }}>
            Add Amendment
          </Button>
        </Dropdown>
      )}

      {typeSelected && (
        <Button type="link" icon={<EnterOutlined />} style={{ paddingLeft: 0 }} onClick={onSubmitAmendment}>
          Submit Amendment
        </Button>
      )}

      {data.length > 0 && !typeSelected && (
        <Table dataSource={data} size="middle">
          <Table.Column title="Type" dataIndex="type" />
          <Table.Column
            title="Actions"
            key="actions"
            render={(v, r) => (
              <Button type="link" onClick={() => onSelectAmendment(r)}>
                Open
              </Button>
            )}
          />
        </Table>
      )}
      {typeSelected == "AmendmentLoading" && (
        <AmendmentLoading value={amendmentSelected} config={props.config} policy={props.policy} onChange={onAmendementChange} />
      )}
      {typeSelected == "AmendmentClause" && (
        <AmendmentClause
          value={amendmentSelected}
          config={props.config}
          policy={props.policy}
          currentClauses={props.policy.Clauses}
          onChange={onAmendementChange}
        />
      )}
      {typeSelected == "AmendmentExclusion" && (
        <AmendmentExclusion
          value={amendmentSelected}
          config={props.config}
          policy={props.policy}
          currentExclusions={props.policy.Exclusions}
          onChange={onAmendementChange}
        />
      )}
      {typeSelected == "AmendmentAddCoverage" && (
        <AmendmentAddCoverage
          value={amendmentSelected}
          config={props.config}
          policy={props.policy}
          currentCoverages={props.policy.Coverages}
          onChange={onAmendementChange}
        />
      )}
      {typeSelected == "AmendmentRemoveCoverage" && (
        <AmendmentRemoveCoverage
          value={amendmentSelected}
          config={props.config}
          policy={props.policy}
          currentCoverages={props.policy.Coverages}
          onChange={onAmendementChange}
        />
      )}
    </div>
  );
};

export default Amendments;
