import React, { Component } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Tooltip,
  Modal,
  Table,
  Select,
  DatePicker,
  Tabs,
  Row,
  Col,
  Input,
  message,
  Radio,
  InputNumber,
  Divider,
  Upload,
  Switch,
  Tag,
  Typography,
  Checkbox,
  Alert,
  Timeline,
} from "antd";
import CptSelect from "./CptSelect";
import { exe } from "Lib/Dal";
import RequirementSelect from "./RequirementSelect";
import { withTranslation } from "react-i18next";
const { Text, Title } = Typography;
const { Column, ColumnGroup } = Table;
const requirementTypes = [
  { code: 1, value: "Optional" },
  { code: 2, value: "Required" },
  { code: 3, value: "Blocker" },
];
//props string diagnosisCode,
class DiagnosisRequirements extends Component {
  state = {
    modalVisible: false,
  };
  componentDidMount() {
    if (this.props.diagnosisCode) this.load(this.props.diagnosisCode);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.diagnosisCode != prevProps.diagnosisCode) this.load(this.props.diagnosisCode);
  }

  load = (diagnosisCode) => {
    this.setState({ loading: true });
    exe("RepoDiagRequirement", { operation: "GET", filter: `diagnosisCode='${diagnosisCode}'`, include: ["Requirement"] }).then((r) => {
      this.setState({ loading: false, data: r.outData });
    });
  };
  handleModalVisible = () => {
    this.setState({ modalVisible: true, operation: "ADD" }, () => {
      this.props.form.resetFields();
      this.props.form.setFieldsValue({ diagnosisCode: this.props.diagnosisCode });
    });
  };
  handleModalCancel = () => this.setState({ modalVisible: false });
  handleModalOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.setState({ loading: true });
        exe("RepoDiagRequirement", { entity: values, operation: this.state.operation }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            this.setState({ modalVisible: false });
            this.load(this.props.diagnosisCode); //reloading table
          } else {
            message.error(r.msg);
          }
        });
      } else {
        console.log(err, "ERROR VAL");
        message.error(this.props.t("Please fill all required fiedls"));
      }
    });
  };
  handleOpenRecord = (r) => {
    this.setState({ modalVisible: true, operation: "UPDATE" }, this.props.form.setFieldsValue(r));
  };
  handleRemoveRecord = (record) => {
    this.setState({ loading: true });
    exe("RepoDiagRequirement", { entity: record, operation: "DELETE" }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.load(this.props.diagnosisCode); //reloading table
      } else {
        message.error(r.msg);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const addDisabled = this.props.disabled;
    const { t, i18n } = this.props;
    return (
      <div>
        <Modal title={t("Diagnosis Level Requirements")} visible={this.state.modalVisible} onOk={this.handleModalOk} onCancel={this.handleModalCancel}>
          <Form layout="vertical">
            <Form.Item label={t("Diagnosis Code ICD9")}>{getFieldDecorator("diagnosisCode")(<Input disabled />)}</Form.Item>
            <Form.Item label={t("Requirement Code")}>{getFieldDecorator("requirementCode")(<RequirementSelect />)}</Form.Item>

            <Form.Item label={t("Type")}>
              {getFieldDecorator("type")(
                <Select>
                  {requirementTypes.map((r) => (
                    <Select.Option value={r.code}>{r.value}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t("Rule")}>
              {getFieldDecorator("rule")(<Input.TextArea placeholder={t("Autosize height based on content lines")} autosize />)}
            </Form.Item>
            <Form.Item label={t("Audit Notes")}>
              {getFieldDecorator("auditNotes")(<Input.TextArea placeholder={t("Autosize height based on content lines")} autosize />)}
            </Form.Item>
            <Form.Item label={t("Comments")}>
              {getFieldDecorator("comments")(<Input.TextArea placeholder={t("Autosize height based on content lines")} autosize />)}
            </Form.Item>
            <Form.Item label={t("Relationship ID")}>{getFieldDecorator("id")(<Input disabled />)}</Form.Item>
          </Form>
        </Modal>
        <Title level={4}>{t("Requirements")}</Title>
        <Tooltip title={addDisabled ? t("Save diagnosis first") : t("Add Requirement")}>
          <Button type="primary" disabled={addDisabled} icon={<PlusOutlined />} onClick={this.handleModalVisible} style={{ marginBottom: 5 }}>
            {t("Add")}
          </Button>
        </Tooltip>
        <Table dataSource={this.state.data}>
          <Column title={t("Code")} dataIndex="requirementCode" />
          <Column title={t("Document / Deliverable")} dataIndex={["Requirement","document"]} />
          <Column title={t("Type")} dataIndex="type" render={(v) => requirementTypes.find((p) => p.code == v).value} />
          <Column title={t("Rules")} dataIndex="rule" />
          <Column title={t("Audit Notes")} dataIndex="auditNotes" />
          <Column title={t("Comments")} dataIndex="comments" />
          <Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <Button type="link" onClick={() => this.handleOpenRecord(record)}>
                  {t("Open")}
                </Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => this.handleRemoveRecord(record)}>
                  {t("Remove")}
                </Button>
              </span>
            )}
          />
        </Table>
      </div>
    );
  }
}

const Wrapped = Form.create()(DiagnosisRequirements);
export default withTranslation()(Wrapped);
