import React, { useState, useEffect } from "react";
import PolicyCoverageSelect from "./PolicyCoverageSelect";
import { DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Collapse, Empty, Dropdown, Menu, message } from "antd";
import PolicyCoverageClaim from "./PolicyCoverageClaim";
import { exe } from "../../Lib/Dal";
import { formatter } from "../Shared/Money";
import PolicySituationGraph from "./PolicySituationGraph";
import moment from "moment";
import CoverageClaimList from "../Life/CoverageClaimList";
import { useTranslation } from "react-i18next";
import useUpdateEffect from "../../Lib/Helpers";

const Payouts = (props) => {
  //state
  const [t, i18n] = useTranslation();
  const [selectedCoverageId, setSelectedCoverageId] = useState(undefined);
  const [affectedCoverages, setAffectedCoverages] = useState([]);
  const [allPayouts, setAllPayouts] = useState([]);

  useEffect(() => props.claim && props.claim.Policy && load(props.claim.Policy.id), [props.claim.id]);
  useUpdateEffect(()=>load(props.claim.Policy.id),[props.doRefresh])

  //props dependencies
  const claim = props.claim;
  const policy = props.claim.Policy;
  const currency = policy ? policy.currency : undefined;
  const money = formatter(props.currency);
  const config = policy ? JSON.parse(policy.Product.configJson) : null;

  //commands
  const load = (policyId) => {
    exe("RepoLifeCoveragePayout", { operation: "GET", filter: "lifepolicyid=" + policyId }).then((r) => {
      const payouts = r.outData || [];
      const affected = policy.Coverages.filter((p) => payouts.map((q) => q.lifeCoverageId).includes(p.id));
      affected.forEach((p) => (p.payouts = payouts.filter((q) => q.lifeCoverageId == p.id))); //injecting payouts to coverage
      setAffectedCoverages(affected);
      setAllPayouts(payouts);
      props.updateClaim(payouts);
    });
  };

  //ui events
  const onAddCoverage = () => {
    //date validation
    const cov = policy.Coverages.find((p) => p.id == selectedCoverageId);
    if (cov.start) {
      if (moment.utc(cov.start).isAfter(claim.occurrence.utc())) {
        message.error(
          `${t("The validity of this coverage")} (${moment(cov.start).format("YYYY-MM-DD")}) ${t("starts after the date of occurrence")} (${moment(
            claim.occurrence
          ).format("YYYY-MM-DD")} )`
        );
        return;
      }
    }
    if (cov.end) {
      if (moment.utc(cov.end).isBefore(claim.occurrence.utc())) {
        message.error(
          `${t("The validity of this coverage")} (${moment(cov.end).format("YYYY-MM-DD")}) ${t("has ended before the date of occurrence")} (${moment(
            claim.occurrence
          ).format("YYYY-MM-DD")}) `
        );
        return;
      }
    }
    setAffectedCoverages([...affectedCoverages, cov]);
    setSelectedCoverageId(undefined);
  };

  const doRefresh = () => {
    load(policy.id);
  };

  const checkIfInternalIsHidden=(covergeCode)=>{
    if(props.config&&props.config.Coverages){
        const coverageConfig = config.Coverages.find(p=>p.code==covergeCode);
        if(coverageConfig&&coverageConfig.hidden){
            return true;
        }
    }
    return false;
}
  const covs=(policy ? policy.Coverages.filter((p) => !affectedCoverages.map((q) => q.id).includes(p.id)) : []).filter((p) => !(p.isInternal && checkIfInternalIsHidden(p.code)));

  //calculated values
  const reserved = allPayouts.reduce((sum, x) => sum + x.reserved, 0);
  const payed = allPayouts.reduce((sum, x) => sum + x.payed, 0);

  //view
  return (
    <div>
      <PolicySituationGraph data={allPayouts} claimId={claim.id} />

      <div style={{ display: "flex", marginBottom: 15 }}>
        <span>{t("Affected Coverage")}:</span>
        <PolicyCoverageSelect
          coverages={covs}
          currency={currency}
          onChange={(v) => setSelectedCoverageId(v)}
          elegibleCoverages={props.claim.elegibleCoverages}
          value={selectedCoverageId}
        />
        <Button type="primary" icon={<PlusOutlined />} style={{ marginLeft: 5 }} onClick={onAddCoverage} disabled={!selectedCoverageId}>
          {t("Add Affected Coverage")}
        </Button>
      </div>

      {affectedCoverages.length == 0 && <Empty />}
      {affectedCoverages.length > 0 && (
        <Collapse accordion defaultActiveKey={"0"}>
          {affectedCoverages.map((p, index) => (
            <Collapse.Panel
              header={<b>{p.name}</b>}
              key={index}
              extra={
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <Button type="link" icon={<DeleteOutlined />} onClick={() => setAffectedCoverages(affectedCoverages.filter((c) => c.id !== p.id))}>
                          {t("Remove")}
                        </Button>
                      </Menu.Item>
                    </Menu>
                  }>
                  <SettingOutlined
                    onClick={(event) => {
                      event.stopPropagation();
                    }} />
                </Dropdown>
              }>
              <PolicyCoverageClaim coverage={p} currency={currency} claimId={claim.id} doRefresh={doRefresh} config={config} />
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
};

export default Payouts;
