import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message, Modal, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import {formatDate} from "../../Lib/Helpers";
import Inspector from "react-inspector";

const WorkerActivity = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        load();
    },[])
    
    const load=()=>{
        setLoading(true);
        exe("GetWorkerActivity").then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData.activity);
            }else message.error(r.msg);
        })
    }

    const onOpenDto = (message,title) => {
        let parsedDto;
        try {
            parsedDto = JSON.parse(message);
        } catch (error) {
            parsedDto = message; //cant parse, showing text
        }
        Modal.info({
            title: title,
            maskClosable: true,
            width: "60%",
            zIndex: 2000,
            content: (
                <div>
                    <Inspector data={parsedDto} expandLevel={1} />
                </div>
            ),
            onOk() {},
        });
    };
    const onDelete=workerName=>{
        setLoading(true);
        exe("ClearWorkerActivity",{workerName}).then(r=>{
            setLoading(false);
            if(r.ok){
                load();
            }else message.error(r.msg);
        })
    }
    const renderResult=(v,r)=>{
        if(!v||v==="") return null;
        if(v.toLowerCase()==="true") return <Tag color={"#87d068"}>{t("Ok")}</Tag>;
        return <Tag color={"red"}>{t("Error")}</Tag>;
    }
    const renderStatus=(v,r)=> {
        if (v === "1") return <Tag color={"orange"}>{t("Processing")}</Tag>;
        if (v === "2") return <Tag>{t("Finished")}</Tag>;
    }
    return (
        <div>
            <Button type={"link"} icon={<LegacyIcon type={"reload"} />} onClick={()=>load()}>{t("Refresh")}</Button>
            <Table dataSource={data} rowKey={"messageId"} style={{marginTop:5}} loading={loading}>
                <Table.Column title={t("Worker")} dataIndex={"workerName"} />
                <Table.Column title={t("Message Id")} dataIndex={"messageId"}  
                              render={(v, r) => <Button type="link" onClick={() => onOpenDto(r.message,r.messageId)}>{v}</Button>
                } />
                <Table.Column title={t("Batch")} dataIndex={"batch"} />
                <Table.Column title={t("Row")} dataIndex={"index"} />
                <Table.Column title={t("Start")} dataIndex={"start"} render={v=>formatDate(v)} />
                <Table.Column title={t("Status")} dataIndex={"status"} render={renderStatus}/>
                <Table.Column title={t("End")} dataIndex={"end"} render={v=>v?formatDate(v):""} />
                <Table.Column title={t("Result")} dataIndex={"result"}  render={renderResult} />
                <Table.Column title={t("Result Msg")} dataIndex={"msg"} />
                <Table.Column title={t("Last Message Id")} dataIndex={"lastMessageId"}
                              render={(v, r) => <Button type="link" onClick={() => onOpenDto(r.lastMessage,r.lastMessageId)}>{v}</Button>
                              } />
               
                <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"delete"} />} onClick={()=>onDelete(r.workerName)} /> </div>} />
               

            </Table>
            </div>
    );
};

export default WorkerActivity