import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import CustomPlanOptionsForm from "./CustomPlanOptionsForm";

const CustomPlanOptions = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);

    useEffect(()=>{
        if(props.visible) load();
    },[props.visible])

    const load=()=>{
        setData(props.value?JSON.parse(props.value):props.planOptionsConfig);
    }

    const onSave=values=>{
        setData(data.map(p=>p.code===values.code?values:p));
        setSelected(null);

    }
    const onDelete=record=>{
        setData(data.filter(p=>p.code!==record.code));
        setSelected(null);
    }

    const onSaveChanges=()=>{
        setLoading(true);
        props.onOk(data)
    }

    return (
        <div>
            <Modal title={t("Plan Options")} visible={props.visible} onCancel={props.onCancel} onOk={onSaveChanges} okText={t("Save")} okButtonProps={{disabled:selected}} >
                {!selected&&<div>
                    <Table dataSource={data} rowKey={"code"} style={{marginTop:5}} pagination={false}>
                        <Table.Column title={t("Code")} dataIndex={"code"} />
                        <Table.Column title={t("Name")} dataIndex={"name"} />
                        <Table.Column title={t("Sum Insured")} dataIndex={"sumInsured"} />
                        <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} /> </div>}/>
                    </Table>
                </div>}
                {selected&&<CustomPlanOptionsForm onBack={()=>setSelected(null)} onSave={onSave} selected={selected} onDelete={onDelete} />}
            </Modal>
        </div>
    );
};

export default CustomPlanOptions;