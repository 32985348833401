import { Button, Form, Input, Popover, message } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SaveOutlined } from '@ant-design/icons';
import { exe } from '../../Lib/Dal';

const SaveSelectedRecords = (props) => {
    const [t]=useTranslation();
    const [name,setName]=useState("");
    const [loading,setLoading]=useState(false);
    const [visible,setVisible]=useState(false);

    const onSave=()=>{
        if(name.length==0){
            message.error(t("A name is required"));
            return;
        }
        setLoading(true);
        exe("RepoSelection",{operation:"ADD",entity:{name:name,jSelection:JSON.stringify(props.selectedRowKeys),entity:props.entity}}).then(r=>{
            setLoading(false);
            if(r.ok){
                setName("");
                message.success(t("Selection Saved"));
                setVisible(false);
            }else message.error(r.msg);
        }
        );
    }

    return <Popover title={t("Save Selected Records")} open={visible} trigger={props.selectedRowKeys.length==0?"none":"click"} onOpenChange={newValue=>setVisible(newValue)} content={<div>
            <Form.Item label={t("Name")}>
              <Input placeholder={t("Selection Name")} value={name} onChange={(e)=>setName(e.target.value)} />
            </Form.Item>
            <Form.Item label={t("Records")}>
              {props.selectedRowKeys.length}
            </Form.Item>
            <Button type="primary" icon={<SaveOutlined />} onClick={onSave} loading={loading}>{t("Save")}</Button>
            </div>} >
            <Button type="link" icon={<SaveOutlined />} disabled={props.selectedRowKeys.length == 0} onClick={()=>setVisible(!visible)}>
              {t("Save Selection")}
            </Button>
          </Popover>
    ;
};

export default SaveSelectedRecords;
