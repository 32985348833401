import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {Select, message, Tag} from "antd";
import {useTranslation} from "react-i18next";

const PolicySelectStep = (props) => {
    const [t]=useTranslation();
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);

    useEffect(()=>{
        if(props.contact) load(props.contact.id);
    },[props.contact]);
    
    const load=(contactId)=> {
        setLoading(true);
        exe("GetContactPolicies",{contactId:contactId}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData.records);
            }else message.error(r.msg);
        });
    }
    return (
        <div>
            <Select placeholder={t("Please select")} {...props} value={props.value} onChange={props.onChange}>
                {data.map(p=><Select.Option key={p.id} value={p.id} record={p}><Tag>{p.lob}</Tag>{p.code}</Select.Option>)}
            </Select>
        </div>
    );
};

export default PolicySelectStep;