import React from "react";
import { Button, Modal, Result, Table, Tag, message } from "antd";
import { useEffect, useState } from "react";
import { RocketOutlined, PlusOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDate, formatDateShort, formatMoney } from "../../Lib/Helpers";
import BulkTransactionRecord from "./BulkTransactionRecord";
import DefaultPage from "../Shared/DefaultPage";

const BulkTransactions = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [selected,setSelected] = useState(null);
    const [loading,setLoading] = useState(false);
    const [executeVisible,setExecuteVisible] = useState(false);

    useEffect(()=>{
        load();
    },[])

    const load=()=>{
        setLoading(true);
        exe("RepoBulkTransactions",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }
    const onExecute=(id)=>{
        setLoading(true);
        exe("ExecuteBulkTransaction",{bulkTransactionId:id,simulate:true}).then(r=>{
            setLoading(false);
            if(r.ok){
                setExecuteVisible(id);
            }else{
                message.error(r.msg);
            }
        })
    }
    const execute=()=>{
        setLoading(true);
        exe("ExecuteBulkTransaction",{bulkTransactionId:executeVisible,simulate:false}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                load();
                setExecuteVisible(false);
            }else{
                message.error(r.msg);
            }
        })
    }
    const getStatus=(status)=>{
        switch(status){
            case 0: return <Tag color="blue">{t("Draft")}</Tag>;
            case 1: return <Tag color="green">{t("Executed")}</Tag>;
        }
    }


    return  <DefaultPage
    title={t("Bulk Transactions")}
    icon="import">
        {!selected&&<div>
            <Button type="link" icon={<PlusOutlined />} onClick={()=>setSelected({id:0})}>{t("Add")}</Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={load}>{t("Reload")}</Button>
            <Table dataSource={data} rowKey={"id"} loading={loading}>
                <Table.Column title={t("Id")} dataIndex="id" />
                <Table.Column title={t("Name")} dataIndex="name" />
                <Table.Column title={t("Date")} dataIndex="transactionDate" render={(v,r)=>formatDate(v)} />
                <Table.Column title={t("Status")} dataIndex="status" render={(v,r)=>getStatus(v)} />
                <Table.Column title={t("Actions")} render={(v,r)=><div>
                    <Button type="link" icon={<EditOutlined />} onClick={()=>setSelected(r)} />
                    <Button type="link" icon={<RocketOutlined />} onClick={()=>onExecute(r.id)} />
                </div>} />
            </Table>
        </div>}
    {selected&&<div>
        <BulkTransactionRecord value={selected} onSave={load} onCancel={()=>setSelected(null)} />
    </div>}
    <Modal title={t("Execute")} visible={executeVisible} 
    onOk={execute}
    okText={t("Execute")}
    onCancel={()=>setExecuteVisible(false)} >
        <Result
        status="success"
        title={t("Successfully validated bulk transaction")}
        
    />
    </Modal>
    </DefaultPage>
       
    };

    export default BulkTransactions;