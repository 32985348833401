import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined, FilterOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Button,
  Drawer,
  Switch,
  DatePicker,
  InputNumber,
  Select,
  Tag,
  Divider,
  Input,
} from "antd";
import { exe } from "../../Lib/Dal";
import { formatDate, formatMoney } from "../../Lib/Helpers";
import ProductSelect from "../Life/ProductSelect";
import moment from "moment";
import { useTranslation } from "react-i18next";

const PremiumSummary = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);

  useEffect(() => load(), []);

  const load = () => {
    const values = props.form.getFieldsValue();
    setLoading(true);
    exe("GetPremiumSummary", { movementDate: values.movementDate, lob: values.lob, productCode: values.productCode ? values.productCode.key : "" }).then(
      (r) => {
        setLoading(false);
        setData(r.outData || []);
      }
    );
  };

  const field = props.form.getFieldDecorator;
  const totalPremium = data.reduce((t, c) => t + c.anualTotal, 0);
  const earnedPremium = data.reduce((t, c) => t + c.earned, 0);
  return (
    <div>
      <div>
        <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
          {t("Filter")}
        </Button>
        <CSVLink data={data} filename={"reserves.csv"} target="_blank" disabled={data.length == 0}>
          <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
            {t("Download")}
          </Button>
        </CSVLink>
      </div>
      <Table
        dataSource={data}
        loading={loading}
        footer={() => (
          <span>
            <b>{t("Total Written Premium")}:</b>
            {formatMoney(totalPremium)}
            <Divider type="vertical" />
            <b>{t("Total Earned Premium")}:</b>
            {formatMoney(earnedPremium)}
          </span>
        )}>
        <Table.Column title={t("Product")} dataIndex="productCode" render={(v) => <Tag>{v}</Tag>} />
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
        <Table.Column title={t("Policy")} dataIndex="code" render={(v, r) => <a href={"#/lifePolicy/" + r.id}>{v}</a>} />
        <Table.Column title={t("Start")} dataIndex="start" render={(v) => formatDate(v)} />
        <Table.Column title={t("Written Premium")} dataIndex="anualTotal" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Earned Premium")} dataIndex="earned" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("LoB")} dataIndex="lob" />
      </Table>

      <Drawer title={t("Filter")} visible={filterVisible} width={512} onClose={() => setFilterVisible(false)}>
        <Form layout="vertical">
          <Form.Item label={t("Year")}>{field("movementYear", { initialValue: new Date().getFullYear() })(<InputNumber />)}</Form.Item>
          <Form.Item label={t("Movement Month")}>{field("movementDate")(<DatePicker.MonthPicker />)}</Form.Item>
          <Form.Item label={t("LoB")}>{field("lob")(<Input />)}</Form.Item>
          <Form.Item label={t("Product")}>{field("productCode")(<ProductSelect />)}</Form.Item>
        </Form>
        <Button type="link" icon={<ThunderboltOutlined />} onClick={load}>
          {t("Compute now")}
        </Button>
      </Drawer>
    </div>
  );
};

export default Form.create()(PremiumSummary);
