import React, {useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber, Modal, message } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";

const UpdateRetention = (props) => {
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            setLoading(true);
            exe("UpdateRetention",{cessionId:props.cessionId,retention:values.retention}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onOk();
                }else message.error(r.msg);
            })
            props.onOk&&props.onOk(values);
            
        })
    }
    return (
        <div>
            <Modal title={t("Update Retention")} visible={props.visible} onOk={onSave} onCancel={props.onCancel} okButtonProps={{loading:loading}}>
                <Form.Item label={t("Retention")}>{field("retention",{rules:[{required:true}]})(<InputNumber min={0} />)}</Form.Item>
            </Modal>
        </div>
    );
};

export default Form.create()(UpdateRetention);