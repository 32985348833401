import React from "react";
import {
  CheckOutlined,
  InfoCircleTwoTone,
  PaperClipOutlined,
  PlusOutlined,
  ReloadOutlined,
  StopTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Tag,
  Divider,
  Button,
  Modal,
  Input,
  message,
  Row,
  Col,
  Select,
  Typography,
  Switch,
  Tooltip,
} from "antd";
import equal from "fast-deep-equal";
import { exe } from "Lib/Dal";
import moment from "moment";
import { withTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";
const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;
const locale = "en";

//props= claimId onChange reload
class RequirementsTable extends React.Component {
  state = {
    visible: false,
    data: [],
    loading: false,
  };
  componentDidMount() {
    this.load(this.props.claimId);
  }
  componentDidUpdate(prevProps) {
    if (this.props.claimId != prevProps.claimId || (this.props.reload && !prevProps.reload)) {
      //claim changed or reloaded
      this.load(this.props.claimId);
    }
  }
  load = (claimId) => {
    if (!this.props.claimId) {
      //new claim
      this.setState({ data: [] });
      this.props.onChange && this.props.onChange([]);
      return;
    }
    this.setState({ loading: true });
    exe("RepoRequirement", {
      operation: "GET",
      filter: "claimId=" + claimId,
    }).then((r) => {
      this.setValues(r.outData);
    });
  };
  showModal = () => {
    this.props.form.resetFields();
    this.setState({
      visible: true,
    });
  };
  setValues = (values) => {
    values.forEach((element) => {
      element.requestDate = moment(element.requestDate);
      element.responseDate = moment(element.responseDate);
      element.date = moment(element.date);
      //if(element.tags) element.tags=JSON.parse(element.tags);
    });
    this.setState({ loading: false, data: values });
    this.props.onChange(values);
  };
  handleOk = (e) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        const operation = values.id == undefined ? "ADD" : "UPDATE";

        this.setState({ loading: true });

        exe("RepoRequirement", { entity: values, operation: operation }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            const d = this.state.data;
            if (r.data.operation == "ADD") {
              const newRecord = r.outData[0];
              newRecord.requestDate = moment(newRecord.requestDate);
              newRecord.responseDate = moment(newRecord.responseDate);
              newRecord.date = moment(newRecord.date);
              d.push(newRecord);
            }
            if (r.data.operation == "UPDATE") d[d.findIndex((p) => p.id == values.id)] = values;

            this.setState({ visible: false, data: [...d] }, () => this.props.onChange(this.state.data));
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
        message.error(this.props.t("Please fill all required fields"));
      }
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  openRecord = (r) => {
    this.setState({ visible: true });
    this.props.form.setFieldsValue(r);
  };
  removeRecord = (record) => {
    this.setState({ loading: true });
    exe("RepoRequirement", { operation: "DELETE", entity: {id:record.id,type:record.type} }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState(
          {
            data: this.state.data.filter((p) => p.id != record.id),
          },
          () => this.props.onChange(this.state.data)
        );
      } else {
        message.error(r.msg);
      }
    });
  };
  onRequested = (requested) => {
    if (requested) {
      this.props.form.setFieldsValue({ requestDate: moment() });
    } else {
      this.props.form.setFieldsValue({ requestDate: moment(null) });
    }
  };
  onReceived = (received) => {
    if (received) {
      this.props.form.setFieldsValue({ responseDate: moment() });
    } else {
      this.props.form.setFieldsValue({ responseDate: moment(null) });
    }
  };
  onRaiseRequirements = () => {
    this.setState({ loading: true });
    exe("RaiseClaimRequirements", { claimId: this.props.claimId, action: "DEMAND" }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.setValues(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Title level={4}>{t("Requirements")}</Title>
        <Button type="primary" icon={<PlusOutlined />} onClick={this.showModal} hidden={this.props.hideAdd}>
          {t("Add")}
        </Button>
        <Button type="link" icon={<PaperClipOutlined />} onClick={() => this.onRaiseRequirements()} loading={this.state.loading}>
          {t("Raise Requirements Now")}
        </Button>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => this.load(this.props.claimId)} >
          {t("Reload")}
        </Button>
        <Table dataSource={this.state.data} style={{ marginTop: 10 }} loading={this.state.loading}>
          <Column title={t("Code")} dataIndex="code" />
          <Column title={t("Document")} dataIndex="document" />
          <Column
            title={t("Request")}
            dataIndex="request"
            render={(text, record) => {
              if (record.request && record.response)
                return (
                  <span>
                    <CheckOutlined style={{ color: "green" }} />
                    <CheckOutlined style={{ color: "green" }} />
                  </span>
                );
              if (record.request) return <CheckOutlined style={{ color: "green" }} />;
            }}
          />
          <Column title={t("Request Date")} dataIndex="requestDate" render={(t) => (t && t.isValid() ? t.locale(locale).format("llll") : "-")} />
          <Column title={t("Response Date")} dataIndex="responseDate" render={(t) => (t && t.isValid() ? t.locale(locale).format("llll") : "-")} />
          <Column
            title={t("Block Level")}
            dataIndex="type"
            render={(v) =>
              v == 3 ? (
                <Tooltip title={t("Blocker")}>
                  <StopTwoTone twoToneColor="red" />
                </Tooltip>
              ) : v == 1 ? (
                <Tooltip title={t("Optional")}>
                  <InfoCircleTwoTone />
                </Tooltip>
              ) : (
                <Tooltip title={t("Required")}>
                  <WarningTwoTone twoToneColor="orange" />
                </Tooltip>
              )
            }
          />
          <Column
            title={t("Tags")}
            dataIndex="tags"
            key="tags"
            render={(tags) => (
              <span>
                {tags &&
                  Array.isArray(tags) &&
                  tags.map((tag) => (
                    <Tag color="red" key={tag}>
                      {tag}
                    </Tag>
                  ))}
              </span>
            )}
          />
          <Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <Button type="link" onClick={() => this.openRecord(record)}>
                  {t("Open")}
                </Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => this.removeRecord(record)}>
                  {t("Remove")}
                </Button>
              </span>
            )}
          />
        </Table>
        <Modal title={t("Requirement Detail")} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <Form.Item style={{ display: "none" }}>{getFieldDecorator("id")(<Input hidden />)}</Form.Item>
            <Form.Item style={{ display: "none" }}>
              {getFieldDecorator("claimId", {
                initialValue: this.props.claimId,
              })(<Input hidden />)}
            </Form.Item>
            <Form.Item label={t("Block Level")}>
              {getFieldDecorator("type")(
                <Select style={{ width: 120 }}>
                  <Option value={1}>{t("Optional")}</Option>
                  <Option value={2}>{t("Required")}</Option>
                  <Option value={3}>{t("Blocker")}</Option>
                </Select>
              )}
            </Form.Item>
            <Row gutter={6}>
              <Col span={6}>
                <Form.Item label={t("Document Code")}>{getFieldDecorator("code")(<Input />)}</Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item label={t("Document Name")}>{getFieldDecorator("document")(<Input />)}</Form.Item>
              </Col>
            </Row>
            <h2>{t("Request")}</h2>
            <Row gutter={6}>
              <Col span={6}>
                <Form.Item label={t("Requested")}>
                  {getFieldDecorator("request", { valuePropName: "checked" })(<Switch onChange={(v) => this.onRequested(v)} />)}
                </Form.Item>
              </Col>
              <Col span={18}>
                {this.props.form.getFieldValue("request") && (
                  <Form.Item label={t("Request Date")}>{getFieldDecorator("requestDate", { initialValue: moment() })(<DatePickerW />)}</Form.Item>
                )}
              </Col>
            </Row>
            {/* <Form.Item label="Request Link">{getFieldDecorator("requestLink")(<Input />)}</Form.Item> */}
            <h2>{t("Response")}</h2>
            <Row gutter={6}>
              <Col span={6}>
                <Form.Item label={t("Responded")}>
                  {getFieldDecorator("response", { valuePropName: "checked" })(<Switch onChange={(v) => this.onReceived(v)} />)}
                </Form.Item>
              </Col>
              <Col span={18}>
                {this.props.form.getFieldValue("response") && (
                  <Form.Item label={t("Response Date")}>{getFieldDecorator("responseDate", { initialValue: moment() })(<DatePickerW />)}</Form.Item>
                )}
              </Col>
            </Row>
            {/* <Form.Item label="Response Link">{getFieldDecorator("responseLink")(<Input />)}</Form.Item> */}

            <Form.Item label={t("Description")}>
              {getFieldDecorator("comments")(<Input.TextArea placeholder={t("Autosize height based on content lines")} autosize />)}
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const Wrapped = Form.create()(RequirementsTable);
export default withTranslation()(Wrapped);
