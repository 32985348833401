import React from "react";
import moment from "moment";
import { formatDate } from "../../Lib/Helpers";
import Text from "antd/lib/typography/Text";

class ComentarioRender extends React.Component {
  render() {
    return (
      <div style={{ marginBottom: 15 }}>
        <span style={{ color: "cornFlowerBlue", fontWeight: 600 }}>{this.props.msg.usuario}</span> {formatDate(this.props.msg.fecha)}{" "}
        <Text code>{this.props.msg.estado}</Text>
        <div
          style={{ borderRadius: 4, margin: 5, padding: 5, borderStyle: "solid", borderColor: "#ccc", borderWidth: "1px" }}
          dangerouslySetInnerHTML={{ __html: this.props.msg.html }}></div>
      </div>
    );
  }
}
export default ComentarioRender;
