import { useTranslation } from "react-i18next";
import DefaultPage from "../Shared/DefaultPage";
import { SearchOutlined, ReloadOutlined, FolderOpenOutlined, UndoOutlined, FilterOutlined } from '@ant-design/icons';
import React,{ useEffect, useState } from "react";
import { Button, Tag, Drawer, Form, Input, InputNumber, Space, Table, message, Modal, Descriptions } from "antd";
import { PlusOutlined } from '@ant-design/icons';

import Currency from "../Shared/Currency";
import { exe } from "../../Lib/Dal";
import ContributionLine from "./ContributionLine";
import useUpdateEffect, { formatDateShort, formatMoney, formatNumber } from "../../Lib/Helpers";
import PensionSchemeSelect from "./PensionSchemeSelect";
import PensionMemberSelect from "./PensionMemberSelect";
import ContributionStatusSelect from "./ContributionStatusSelect";
import ContributionTypeSelect from "./ContributionTypeSelect";
import ContributionPayPeriodSelect from "./ContributionPayPeriodSelect";

const ContributionData = props => {
    const [filterVisible, setFilterVisible] = useState(false);
    const [data, setData] = useState([]);
    const [loading,setLoading]=useState(false);
    const [t] = useTranslation();;
    const [form]=Form.useForm();
    const [selectedRecord,setSelectedRecord]=useState(null);
    const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`});
    const [totals,setTotals]=useState({contributions:0,medical:0,groupLife:0,groupLastExpense:0,draft:0,posted:0,invalid:0,records:0});

    useUpdateEffect(()=>{
        onSearch();
    },[pagination.current])

    //initial load
    useEffect(()=>{
        if(props.pensionId){
            form.setFieldsValue({pensionSchemeId:props.pensionId});
            onSearch();
        }else onSearch();
    },[props.pensionId])

    useEffect(()=>{
        if(props.filterVisible!==undefined){
            setFilterVisible(props.filterVisible);
        }
    },[props.filterVisible])

    const onSearch=()=>{
        const values=form.getFieldsValue();
        let filter="1=1";
        if(values.id) filter+=` and id=${values.id}`;
        if(values.currency) filter+=` and currency='${values.currency}'`;
        if(values.pensionSchemeId) filter+=` and pensionSchemeId=${values.pensionSchemeId}`;
        if(values.pensionMemberId) filter+=` and pensionMemberId=${values.pensionMemberId}`;
        if(values.year) filter+=` and year=${values.year}`;
        if(values.month) filter+=` and month=${values.month}`;
        if(values.day) filter+=` and day=${values.day}`;
        if(values.contributionType!==undefined) filter+=` and contributionType=${values.contributionType}`;
        if(values.payPeriod!==undefined) filter+=` and payPeriod=${values.payPeriod}`;
        if(values.status!==undefined) filter+=` and status=${values.status}`;


        setLoading(true);
        exe("RepoContributionLine",{operation:"GET",size:pagination.pageSize,page:pagination.current-1,
        filter:filter,include:["PensionScheme","PensionMember"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
                setTotals(r.outDataAux)
                setPagination({...pagination,total:r.total,current:pagination.total!==r.total?1:pagination.current,showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`});
            }else message.error(r.msg);
        })
    }
    const onContributionLineOk=(values)=>{
        setSelectedRecord(null);
        onSearch();
    }
    const statusRender=v=>{
        switch(v){
            case 0: return <Tag>{t("Draft")}</Tag>;
            case 1: return <Tag color="green">{t("Posted")}</Tag>;
            case -1: return <Tag color="red">{t("Invalid")}</Tag>;
            default: return "";
        }
    }
    const contributionTypeRender=v=>{
        switch(v){
            case 0: return <Tag >{t("Normal")}</Tag>;
            case 1: return <Tag color="orange">{t("Arrears")}</Tag>;
            default: return "";
        }
    }
    const payPeriodRender=v=>{
        switch(v){
            case 0: return <span>{t("Monthly")}</span>;
            case 1: return <span>{t("Bi-Monthly")}</span>;
            case 2: return <span>{t("Half-Yearly")}</span>;
            case 3: return <span>{t("Yearly")}</span>;
            default: return "";
        }
    }
    const pensionSchemeId = Form.useWatch('pensionSchemeId', form);
    return <div>
        <Button type="link" onClick={()=>onSearch()} icon={<ReloadOutlined />} >{t("Reload")}</Button>
        <Button type="link" onClick={()=>setSelectedRecord({id:0})} icon={<PlusOutlined />} >{t("Manual Add")}</Button>
        <Button type="link" onClick={()=>setFilterVisible(true)} icon={<FilterOutlined />} >{t("Filter")}</Button>
        <Descriptions  column={4} size="small" bordered>
            <Descriptions.Item label={t("Contributions")}>{formatNumber(totals.contributions)}</Descriptions.Item>
            <Descriptions.Item label={t("Medical")}>{formatNumber(totals.medical)}</Descriptions.Item>
            <Descriptions.Item label={t("Group Life")}>{formatNumber(totals.groupLife)}</Descriptions.Item>
            <Descriptions.Item label={t("Group Last Expense")}>{formatNumber(totals.groupLastExpense)}</Descriptions.Item>

            <Descriptions.Item label={t("Draft")}>{totals.draft}</Descriptions.Item>
            <Descriptions.Item label={t("Posted")}>{totals.posted}</Descriptions.Item>
            <Descriptions.Item label={t("Invalid")}>{totals.invalid}</Descriptions.Item>
            <Descriptions.Item label={t("Records")}>{totals.records}</Descriptions.Item>
        </Descriptions>
        <Table dataSource={data} rowKey={"id"} size="small" pagination={pagination} onChange={pag=>setPagination(pag)}>
            <Table.Column title={t("Actions")} key="action" render={(v,r)=><Button type="link" icon={<FolderOpenOutlined/>} onClick={()=>setSelectedRecord(r)} />}/>
            <Table.Column title={t("Id")} dataIndex="id" />
            <Table.Column title={t("Scheme")} dataIndex="schemeName" render={(v,r)=> <a href={`#/pensionScheme/${r.pensionSchemeId}`}>{v}</a>} />
            <Table.Column title={t("Member")} dataIndex="memberName" />
            <Table.Column title={t("Status")} dataIndex="status" key="status" render={v=>statusRender(v)} />
            <Table.Column title={t("Year")} dataIndex="year" key="year" />
            <Table.Column title={t("Month")} dataIndex="month" key="month" />
            <Table.Column title={t("Day")} dataIndex="day" key="day" />
            <Table.Column title={t("Pay Period")} dataIndex="payPeriod" key="payPeriod" render={v=>payPeriodRender(v)} />
            <Table.Column title={t("Date")} dataIndex="date" key="date" render={v=><span style={{whiteSpace:"nowrap"}}>{formatDateShort(v)}</span>} />
            <Table.Column title={t("Type")} dataIndex="contributionType" key="contributionType" render={v=>contributionTypeRender(v)} />
            
            <Table.Column title={t("Employee Contribution")} dataIndex="ee" key="ee" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Employer Contribution")} dataIndex="er" key="er" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Employee Additional Voluntary Contribution")} dataIndex="avcEe" key="avcEe" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Employer Additional Voluntary Contribution")} dataIndex="avcEr" key="avcEr" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            
            <Table.Column title={t("Employee Statutory Contribution Tier 1")} dataIndex="statutoryEe1" key="statutoryEe1" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Employer Statutory Contribution Tier 1")} dataIndex="statutoryEr1" key="statutoryEr1" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Employee Statutory Contribution Tier 2")} dataIndex="statutoryEe2" key="statutoryEe2" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Employer Statutory Contribution Tier 2")} dataIndex="statutoryEr2" key="statutoryEr2" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Employee Statutory Contribution Tier 3")} dataIndex="statutoryEe3" key="statutoryEe3" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Employer Statutory Contribution Tier 3")} dataIndex="statutoryEr3" key="statutoryEr3" render={(v,r)=>formatMoney(v,r.currency,true)}/>

            <Table.Column title={t("Medical Employee")} dataIndex="medicalEe" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Medical Employer")} dataIndex="medicalEr" render={(v,r)=>formatMoney(v,r.currency,true)}/>

            <Table.Column title={t("Group Life")} dataIndex="groupLifeEr" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            <Table.Column title={t("Group Last Expense")} dataIndex="groupLastExpenseEr" render={(v,r)=>formatMoney(v,r.currency,true)}/>
            
            <Table.Column title={t("Currency")} dataIndex="currency" key="currency" />

            <Table.Column title={t("Basic Salary")} dataIndex="basicSalary" key="basicSalary" render={(v,r)=>formatMoney(v,r.currency,true)} />
            <Table.Column title={t("House Allowance")} dataIndex="houseAllowance" key="houseAllowance" render={(v,r)=>formatMoney(v,r.currency,true)} />
            <Table.Column title={t("Gross Salary")} dataIndex="grossSalary" key="grossSalary" render={(v,r)=>formatMoney(v,r.currency,true)} />

            
        </Table>
     
        <Drawer title={t("Contribution Filter Search")} placement="right" width={512} forceRender
        visible={filterVisible} onClose={()=>setFilterVisible(false)}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>
            <Form layout="vertical" form={form}>
            <Form.Item name="id" label={t("Id")}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="status" label={t("Status")}>
                <ContributionStatusSelect />
            </Form.Item>
            <Form.Item name="currency" label={t("Currency")}>
                <Currency />
            </Form.Item>
            <Form.Item name="pensionSchemeId" label={t("Pension Scheme")}>
                <PensionSchemeSelect />
            </Form.Item>
            <Form.Item name="pensionMemberId" label={t("Pension Scheme Member")}>
                <PensionMemberSelect pensionSchemeId={pensionSchemeId} disabled={!pensionSchemeId} />
            </Form.Item>
            <Form.Item name="year" label={t("Year")}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="month" label={t("Month")}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="day" label={t("Day")}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="contributionType" label={t("Contribution Type")}>
                <ContributionTypeSelect />
            </Form.Item>
            <Form.Item name="payPeriod" label={t("Pay Period")}>
                <ContributionPayPeriodSelect />
            </Form.Item>
            
            <Button type="primary" icon={<SearchOutlined />} onClick={onSearch} loading={loading}>{t("Search")}</Button>
            </Form>
        </Drawer>
            
            <ContributionLine visible={selectedRecord!=null} 
            record={selectedRecord}
            pensionId={props.pensionId}
            onCancel={()=>setSelectedRecord(null)} 
            onOk={onContributionLineOk} />
    </div>
}
export default ContributionData;