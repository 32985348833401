import React from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Table, message, Popconfirm, notification,Select } from "antd";
import { get, put, del } from "../../Lib/Dal";
import EditableCell from "../Shared/EditableCell";
import EstadoDetalle from "./EstadoDetalle";
const Option=Select.Option;

class Base extends React.Component {
  state = {
    loading: true,
    data: [],
    new: false,
    edit: false,
    detalleVisible: false,
    tipoMaestro:'estadopoliza' //default
  };

  componentDidMount() {
    this.getData();
  }

  getData = (m="estadopoliza") => {
    this.setState({
      loading: true,
      new: false,
      edit: false,
      data: [],
      tipoMaestro:m,
      detalleVisible: false
    });
    get("/"+m).then(r => {
      this.setState({ loading: false, data: r });
    });
  };
  
  onEdit = record => {
    console.log(record);
    this.setState({
      edit: true,
      new: false,
      record: record,
      detalleVisible: true
    });
  };


  onConfirmDeleteRecord = record => {
    del("/"+this.state.tipoMaestro+"/" + record.idInterno).then(r => {
      console.log(r,'respuesta delete');
        if(r.ok){
            message.success("Registro eliminado");
        }else{
            notification.error({
                message: "Error",
                description: r.msg
              });
        }
      
      this.getData();
    });
  };
  onAdd = () => {
    this.setState({ new: true, edit: false, detalleVisible: true });
  };
  afterAdd = () => {
    this.getData(this.state.tipoMaestro);
  };
  onClose = () => {
    this.setState({ detalleVisible: false, new: false, record: null });
  };
  onTipoMaestroChange(v){
   this.getData(v);
  }
  render() {
    const columns = [
      {
        title: "Nombre",
        dataIndex: "nombre"
      },
      {
        title: "Id Interno",
        dataIndex: "idInterno"
      },
      {
        title: "Id Externo",
        dataIndex: "idExterno"
      },
      //columnas particualres
      {
        title: this.state.tipoMaestro=="estadosiniestro"?"% Avance":null,
        dataIndex: "pAvance"
      },
      {
        title: "Acciones",
        key: "acciones",
        render: (text, record) => (
          <div>
            <Popconfirm
              title="¿Confirma eliminar este registro?"
              onConfirm={p => this.onConfirmDeleteRecord(record)}
              okText="Si"
              cancelText="No"
            >
              <Button icon={<DeleteOutlined />} style={{ marginLeft: 5 }} />
            </Popconfirm>
            <Button
              icon={<EditOutlined />}
              style={{ marginLeft: 5 }}
              onClick={p => this.onEdit(record)}
            />
          </div>
        )
      }
    ];
    return (
      <div>
        <Button
          onClick={this.onAdd}
          type="primary"
          style={{ marginBottom: 16 }}
          icon={<PlusOutlined />}
        >
          Nuevo
        </Button>
        <Select defaultValue="estadopoliza" style={{ width: 120,marginLeft:5 }} onChange={p=>this.onTipoMaestroChange(p)}>
          <Option value="estadopoliza">Pólizas</Option>
          <Option value="estadosiniestro">SIniestros</Option>
        </Select>
        <Table
          loading={this.state.loading}
          dataSource={this.state.data}
          columns={columns}
          rowKey="idInterno"
          size="small"
        />
        <EstadoDetalle
          visible={this.state.detalleVisible}
          onClose={this.onClose}
          afterAdd={this.afterAdd}
          new={this.state.new}
          record={this.state.record}
          tipoMaestro={this.state.tipoMaestro}
        />
      </div>
    );
  }
}
export default Base;
