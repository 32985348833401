import React from "react";
import {
  BankOutlined,
  DeleteOutlined,
  KeyOutlined,
  LockOutlined,
  PlusOutlined,
  ReloadOutlined,
  SearchOutlined,
  StarOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Button, Table, message, Popconfirm, Input, Checkbox, Divider, Tooltip } from "antd";
import { get, put, del, exe } from "../../Lib/Dal";
import EditableCell from "../Shared/EditableCell";
import NewUser from "./NewUser";
import CambioPass from "./CambioPass";
import { withTranslation } from "react-i18next";
import UserGroups from "./UserGroups";
import { formatDate } from "../../Lib/Helpers";

let allUsers = [];

class Usuarios extends React.Component {
  state = {
    loading: true,
    users: [],
    newUser: false,
    cambioPass: false,
    filterDropdownVisible: false,
    searchText: "",
    filtered: false,
    showUserGroups: undefined,
  };

  getUsers = () => {
    this.setState({ loading: true });
    exe("GetUsers", { include: ["Groups","Branch"] }).then((r) => {
      this.setState({ loading: false, users: r.outData, newUser: false, cambioPass: false });
      allUsers = r.outData;
    });
  };

  componentDidMount() {
    this.getUsers();
  }

  showCambioPass = (user) => {
    this.setState({ cambioPass: true, user: user });
  };
  onCellChange = (id, dataIndex, value) => {
    const users = [...this.state.users];
    const target = users.find((item) => item.email === id);
    if (target) {
      target[dataIndex] = value;
      exe("AddOrUpdateUser", target, { operation: "UPDATE" }).then((r) => {
        message[r.ok ? "success" : "error"](r.msg);
        if(r.ok){
          const updated=[...users.map(p=>p.email===id?r.outData:p)];
          this.setState({ users:updated });
        }
      });
    }
  };
  onConfirmAdmin = (record) => {
    const dto = { ...record, esAdmin: !record.esAdmin };
    exe("AddOrUpdateUser", dto, { operation: "UPDATE" }).then((r) => {
      message[r.ok ? "success" : "error"](r.msg);
      this.getUsers();
    });
  };
  toggleExternalUser = (record) => {
    const dto = { ...record, external: !record.external };
    exe("AddOrUpdateUser", dto, { operation: "UPDATE" }).then((r) => {
      message[r.ok ? "success" : "error"](r.msg);
      this.getUsers();
    });
  };
  onConfirmDeleteUser = (record) => {
    exe("AddOrUpdateUser", record, { operation: "DELETE" }).then((r) => {
      message[r.ok ? "success" : "error"](r.msg);
      this.getUsers();
    });
  };
  onAdd = () => {
    this.setState({ newUser: true });
  };
  afterAdd = () => {
    this.getUsers();
  };
  onHideNewUSer = () => {
    this.setState({ newUser: false });
  };
  onHideCambioPass = () => {
    this.setState({ cambioPass: false });
  };
  ////////////////////FILTRO//////////////////////////////
  filtro = (campo, placeholder, bool = false) => {
    const { t, i18n } = this.props;
    return {
      filterDropdown: (
        <div style={{padding:5}}>
          {bool?<Checkbox style={{marginRight:15}} checked={this.state[campo]} onChange={(e) => this.setState({ [campo]: e.target.checked })} />:
          <Input
            ref={(ele) => (this.searchInput = ele)}
            placeholder={placeholder}
            value={this.state[campo]}
            onChange={(e) => this.setState({ [campo]: e.target.value })}
            onPressEnter={() => this.onSearch(campo, bool)}
          />}
          <Button type="primary" style={{marginTop:5}} onClick={() => this.onSearch(campo, bool)}>
            {t("Find")}
          </Button>
          <Button type="link" onClick={() => this.setState({ [campo]: "", [campo + "Visible"]: false },()=>this.onSearch(campo,bool))} icon={<DeleteOutlined />} />
        </div>
      ),
      filterIcon: <SearchOutlined style={{ color: this.state.filtered === campo ? "#108ee9" : "#aaa" }} />,
      filterDropdownVisible: this.state[campo + "Visible"],
      onFilterDropdownVisibleChange: (visible) => {
        this.setState({ [campo + "Visible"]: visible, [campo]: bool&&visible?false:this.state[campo] }, () => this.searchInput && this.searchInput.focus());
      },
    };
  };
  onSearch = (campo, bool = false) => {
    const campoV = this.state[campo];
    const reg = new RegExp(campoV, "gi");
    this.setState({
      filterDropdownVisible: false,
      filtered: campoV !== "" ? campo : false,
      users:
        campoV === ""
          ? allUsers
          : allUsers
              .map((record) => {
                if (bool) {
                  return campoV?(record[campo]?record:null): (!record[campo]?record:null);
                } else {
                  const match = record[campo] ? record[campo].match(reg) : null;
                  return match ? record : null;
                }
              })
              .filter((record) => !!record),
    });
  };
  ////////////////FIN FILTRO //////////////////////////////////
  showGroups(record) {
    this.setState({ showUserGroups: record });
  }
  onCloseUserGroups() {
    this.setState({ showUserGroups: null });
  }
  onBlockUser(record) {
    exe("BlockUser", { email: record.email, block: !record.blocked }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
        this.getUsers();
      } else {
        message.error(r.msg);
      }
    });
  }
  render() {
    const { t, i18n } = this.props;

    const columns = [
      {
        ...this.filtro("email", t("Search User")),
        title: t("User"),
        dataIndex: "email",
        key: "email",
      },
      {
        ...this.filtro("nombre", t("Search Name")),
        title: t("Name"),
        dataIndex: "nombre",
        key: "nommbre",
        render: (text, record) => <EditableCell value={text} onChange={(text) => this.onCellChange(record.email, "nombre", text)} />,
      }, {
        title: t("Branch"),
        dataIndex: ["Branch","name"],
        key: "branchName",
        render: (text, record) => <EditableCell value={text} onChange={(text) => this.onCellChange(record.email, "branchCode", text)} type={"branches"} />
      },
      { ...this.filtro("otp", t("True/False 2FA"), true), title: t("2FA"), dataIndex: "otp", key: "2fa", render: (v, r) => <Checkbox checked={r.otp} /> },

      {
        ...this.filtro("esAdmin", t("True/False Admin"), true),
        title: t("Role"),
        dataIndex: "esAdmin",
        key: "esAdmin",
        render: (text, record) => (record.esAdmin ? t("Admin") : t("User")),
      },
      {
        ...this.filtro("blocked", t("True/False Blocked"), true),
        title: t("Blocked"),
        dataIndex: "blocked",
        key: "blocked",
        render: (v, r) => <Checkbox checked={v} />,
      },
      {
        ...this.filtro("external", t("True/False External"), true),
        title: t("External"),
        dataIndex: "external",
        key: "external",
        render: (v, r) => <Checkbox checked={v} />,
      },
      {
        ...this.filtro("contactId", t("True/False"), true),
        title: t("SelfService"),
        dataIndex: "contactId",
        key: "contactId",
        render: (v, r) => <Checkbox checked={v} />,
      },
      {
        title: t("Last Login"),
        dataIndex: "lastLogin",
        render: (v, r) => (v ? formatDate(v) : "-"),
      },
      {
        title: t("Actions"),
        key: "acciones",
        render: (text, record) => (
          <div style={{whiteSpace:"nowrap"}}>
            <Tooltip title={t("Toggle administrator role")}>
              <Popconfirm title={t("Are you sure you want to change user's role?")} onConfirm={(p) => this.onConfirmAdmin(record)}>
                <Button icon={<StarOutlined />} />
              </Popconfirm>
            </Tooltip>
            <Tooltip title={t("Delete user")}>
              <Popconfirm title={t("Are you sure you want to delete this user?")} onConfirm={(p) => this.onConfirmDeleteUser(record)}>
                <Button icon={<DeleteOutlined />} style={{ marginLeft: 5 }} />
              </Popconfirm>
            </Tooltip>
            <Tooltip title={t("Change password")}>
              <Button icon={<KeyOutlined />} style={{ marginLeft: 5 }} onClick={(p) => this.showCambioPass(record)} />
            </Tooltip>
            <Tooltip title={t("Toggle user blocked")}>
              <Popconfirm
                title={t("Are you sure you want to ") + (record.blocked ? t("unblock") : t("block")) + t("this user?")}
                onConfirm={(p) => this.onBlockUser(record)}>
                <Button icon={<LockOutlined />} style={{ marginLeft: 5 }} />
              </Popconfirm>
            </Tooltip>
            <Tooltip title={t("Show user groups")}>
              <Button icon={<TeamOutlined />} style={{ marginLeft: 5 }} onClick={(p) => this.showGroups(record)} />
            </Tooltip>
            <Tooltip title={t("Toggle external user")}>
              <Button icon={<BankOutlined />} style={{ marginLeft: 5 }} onClick={(p) => this.toggleExternalUser(record)} />
            </Tooltip>
          </div>
        ),
      },
    ];

    return (
      <div>
        <Button onClick={this.onAdd} type="primary" style={{ marginBottom: 16 }} icon={<PlusOutlined />}>
          {t("New")}
        </Button>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => this.getUsers()}>
          {t("Reload")}
        </Button>
        <Table loading={this.state.loading} dataSource={this.state.users} columns={columns} rowKey="email" />
        {this.state.newUser && <NewUser visible={this.state.newUser} afterAdd={this.afterAdd} onHide={this.onHideNewUSer} configProfile={this.props.configProfile} />}
        {this.state.cambioPass && <CambioPass visible={this.state.cambioPass} email={this.state.user.email} onHide={this.onHideCambioPass} configProfile={this.props.configProfile} />}
        <UserGroups
          value={this.state.showUserGroups}
          visible={this.state.showUserGroups}
          onOk={() => this.setState({ showUserGroups: null })}
          onCancel={() => this.setState({ showUserGroups: null })}
        />
      </div>
    );
  }
}
export default withTranslation()(Usuarios);
