import React, {useEffect, useRef, useState} from 'react';
import $ from 'jquery';
import 'jquery-ui-bundle';
import 'jquery-ui-bundle/jquery-ui.css';
import {downloadFile, exe, get} from "../Lib/Dal";
import {message} from "antd";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const formRender = require('formBuilder/dist/form-render.min.js');

const FormRenderReadOnly = (props) => {
    const formInstance=useRef();
    
    useEffect(()=>{
        if(props.value){
            const fbRender = document.getElementById(divName);
            formInstance.current=$(fbRender).formRender({container:fbRender,formData: props.value});
            window.formInstance1=formInstance;
        }
    },[props.value]);
    
    useEffect(()=>{
        if(props.formId>0) loadCustomLogic();
    },[props.formId])
    
    const extractOnStepRender=(logic)=>{
        if(logic&&logic.includes("onStepRender")){
            return "("+logic.split("onStepRender")[1].substring(1)+")()";
        }else return "";
    }
    const loadCustomLogic=()=>{
        //loading form for custom logic
        exe("GetForms", { filter: "id=" + props.formId }).then((v) => {
            const extractedLogic=extractOnStepRender(v.outData[0].logic);
            if (extractedLogic) {
                try {
                    console.log("Executing onStepRender logic:", extractedLogic, { processId: props.processId });
                    evalInContext(extractedLogic, {
                        exe: exe,
                        loadCatalog: loadCatalog,
                        processId: props.processId,
                        downloadFile: downloadFile,
                        message,
                    });
                    console.log("Success");
                } catch (error) {
                    console.warn(error);
                }
            }})
    }

    const evalInContext=(js, context)=> {
        return function () {
            return eval(js);
        }.call(context);
    }
    const loadCatalog = (catalog, element, filter, valueField = "id", displayField = "name") => {
        exe(catalog, { operation: "GET", filter: filter }).then((r) => {
            r.outData.map((r) => {
                $(element).append($("<option></option>").attr("value", r[valueField]).text(r[displayField]));
            });
        });
    };
    
    
    const divName="fb-renderRO"+props.itemId;
    
    return <form id={divName} ></form>
};
export default FormRenderReadOnly;