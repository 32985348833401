import React from "react";
import {
  DeleteOutlined,
  FileOutlined,
  MedicineBoxOutlined,
  SaveOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Tabs,
  Row,
  Col,
  Input,
  message,
  InputNumber,
  Typography,
  Popconfirm,
} from "antd";
import { exe } from "Lib/Dal";
import ProcedureTree from "./ProcedureTree";
import DiagnosisRequirements from "./DiagnosisRequirements";
import DefaultPage from "../Shared/DefaultPage";
import { withTranslation } from "react-i18next";
const { Text, Title } = Typography;
const ButtonGroup = Button.Group;

class Procedure extends React.Component {
  state = {
    loading: false,
    activeTab: "02",
    operation: "UPDATE",
  };

  componentDidMount() {
    if (this.props.match.params.procedureCode) {
      this.onProcedureSelected(this.props.match.params.procedureCode);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.procedureCode != prevProps.match.params.procedureCode) {
      this.onProcedureSelected(this.props.match.params.procedureCode);
    }
  }
  delete = () => {
    if (this.props.match.params.procedureCode) {
      this.setState({ loading: true });
      exe("RepoCpt", { entity: { code: this.props.match.params.procedureCode }, operation: "DELETE" }).then((r) => {
        this.setState({ loading: false });
        if (r.ok) {
          message.success(r.msg);
          this.setState({ activeTab: "02" });
          window.location.hash = "#/procedure";
        } else {
          message.error(r.msg);
        }
      });
    }
  };
  save = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        console.log("Received values of form: ", values);
        this.setState({ loading: true });
        exe("RepoCpt", { entity: values, operation: this.state.operation }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            this.setState({ operation: "UPDATE" });
            window.location.hash = "#/procedure/" + values.code;
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
        message.error(this.props.t("Please fill all required fiedls in all tabs"));
      }
    });
  };
  new = () => {
    window.location.hash = "#/procedure/0";
  };
  load = (code) => {
    if (code == 0) {
      this.props.form.resetFields();
      this.setState({ operation: "ADD" });
      return;
    }
    this.setState({ loading: true });
    exe("RepoCpt", { operation: "GET", filter: "code='" + code + "'" }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        const d = r.outData[0];
        this.props.form.setFieldsValue(d);
      } else {
        message.error(r.msg);
      }
    });
  };

  onTabChange = (v) => this.setState({ activeTab: v });

  reload() {
    this.load(this.state.claim.id);
  }

  selected = (key) => {
    window.location.hash = "procedure/" + key;
  };

  onProcedureSelected = (key) => {
    this.setState({ activeTab: "01" });
    this.load(key);
  };

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const saved = this.state.diagnosis && this.state.diagnosis.code;

    return (
      <div style={{}}>
        <DefaultPage
          title={t("Procedure Management")}
          icon="global"
          loading={this.state.loading}
          extra={
            <Row type="flex" justify="end" gutter={12}>
              <Col />
              <Col>
                <ButtonGroup>
                  <Button onClick={this.new} icon={<FileOutlined />}>
                    {t("New")}
                  </Button>
                  <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={this.delete}>
                    <Button icon={<DeleteOutlined />}>{t("Delete")}</Button>
                  </Popconfirm>
                  <Button onClick={this.save} type="primary" icon={<SaveOutlined />}>
                    {t("Save")}
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          }>
          <Tabs activeKey={this.state.activeTab} onChange={this.onTabChange}>
            <Tabs.TabPane
              tab={
                <span>
                  <SearchOutlined />
                  {t("Search")}
                </span>
              }
              key="02">
              <ProcedureTree onSelect={this.selected} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <span>
                  <MedicineBoxOutlined />
                  {t("Procedure")}
                </span>
              }
              key="01">
              {/* ////////////////////////////////TOP MENU ////////////////////////////////////////////////////////////////// */}

              <Form layout="vertical" onSubmit={this.handleSubmit}>
                <Title level={4}>{t("Info")}</Title>
                <Row gutter={16}>
                  {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                  <Col span={12}>
                    <Form.Item label={t("Name")}>
                      {getFieldDecorator("description", {
                        rules: [{ required: true }],
                      })(<Input />)}
                    </Form.Item>
                    <Form.Item label={t("Description")}>
                      {getFieldDecorator("longDescription", {
                        rules: [{ required: true }],
                      })(<Input.TextArea autosize />)}
                    </Form.Item>
                    <Form.Item label={t("Audit Notes")}>
                      {getFieldDecorator("auditNotes", {
                        rules: [{ required: false }],
                      })(<Input.TextArea autosize placeholder={t("Optional auditor notes")} />)}
                    </Form.Item>
                  </Col>
                  {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <Form.Item label={t("ICD9 Code")}>
                      {getFieldDecorator("code", {
                        rules: [{ required: true }],
                      })(<Input readOnly={this.state.operation == "UPDATE"} />)}
                    </Form.Item>
                    <Form.Item label={t("Level")}>
                      {getFieldDecorator("level", {
                        rules: [{ required: false }],
                      })(<InputNumber style={{ width: "100%" }} />)}
                    </Form.Item>
                    <Form.Item label={t("Rules")}>
                      {getFieldDecorator("rules", {
                        rules: [{ required: false }],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                  {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                  <Col span={6}>
                    <Form.Item label={t("Average Cost")}>
                      {getFieldDecorator("averageCost", {
                        rules: [{ required: false }],
                      })(<InputNumber style={{ width: "100%" }} />)}
                    </Form.Item>
                    <Form.Item label={t("Expected Cost")}>
                      {getFieldDecorator("expectedCost", {
                        rules: [{ required: false }],
                      })(<InputNumber style={{ width: "100%" }} />)}
                    </Form.Item>
                    <Form.Item label={t("Parent")}>
                      {getFieldDecorator("parent", {
                        rules: [{ required: false }],
                      })(<Input />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Tabs.TabPane>
          </Tabs>
        </DefaultPage>
      </div>
    );
  }
}

const WrappedForm = Form.create()(Procedure);
export default withTranslation()(WrappedForm);
