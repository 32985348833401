import React, { useState } from "react";
import { Table, Button, Space, Popconfirm } from "antd";
import { formatDate } from "../../Lib/Helpers";
import { formatter } from "../Shared/Money";
import { useTranslation } from "react-i18next";
import { UndoOutlined, FolderOpenOutlined,UngroupOutlined,GroupOutlined } from "@ant-design/icons";
import { safeGet } from "../../Lib/Dal";

const ClaimPaymentList = (props) => {
  const [t, i18n] = useTranslation();
  const [grouped,setGrouped]=useState(true);
  const data = props.payments || [];
  const money = formatter(data.length > 0 ? data[0].currency : null); //should all be same currency

  const onOpen = (r) => {
    props.onOpen(r);
  };
  const renderCoverageName = (coverageId) => {
    const coverages=safeGet(["claim","Policy","Coverages"],props,[]);
    const coverage = coverages.find((c) => c.id === coverageId);
    return coverage ? coverage.name : coverageId;
  }
  const groupBy = (array, key) => {
    return array.reduce((result, item) => {
        // Get the value of the property to group by
        const groupKey = item[key];
        // Find the group in the result array
        let group = result.find(g => g[key] === groupKey);
        // If the group does not exist, create a new group
        if (!group) {
            group = { [key]: groupKey, subData: [] };
            result.push(group);
        }
        // Add the current item to the group's children
        group.subData.push(item);
        return result;
    }, []); // Initialize the result as an empty array
};
const getCoverageTotal=subData=>{
  const total=subData.reduce((acc,curr)=>acc+curr.total,0);
  return money.format(total);
}
const getCoverageAvailable=coverageId=>{
  const coveragePayed = props.claim.Payments.filter((p) => p.coverageId == coverageId).reduce((total, item) => total + item.grossAmount, 0);
  const aprovedForPayment = props.claim.Payouts.filter((q) => (q.status == 1 || q.status == 3) && q.lifeCoverageId == coverageId).reduce((total, item) => total + item.payed, 0)-coveragePayed;
  return money.format(aprovedForPayment);

}
  const detailRender = (parentRecord) => <Table dataSource={parentRecord.subData} rowKey={q=>q.id} >
  <Table.Column title={t("Request")} dataIndex="id" render={(v) => <a href={"#/payments/" + v}>{v}</a>} />
  <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
  {!grouped&&<Table.Column title={t("Coverage")} dataIndex="coverageId" render={v=>renderCoverageName(v)} />}
  <Table.Column title={t("User")} dataIndex="user" />
  <Table.Column title={t("Ref")} dataIndex="reference" />
  <Table.Column title={t("Total")} dataIndex="total" render={(v, r) => money.format(v)} />
  <Table.Column
    title={t("Actions")}
    dataIndex="id"
    render={(v, r) => (<Space>
        <Button type="link" onClick={() => onOpen(r)} icon={<FolderOpenOutlined />}>
          {t("Open")}
        </Button>
        <Popconfirm title={t("Are you sure you want to revert this payment?")} onConfirm={() => props.onRevert(r)} disabled={r.entityState=="REVERTED"}>
          <Button type="link" icon={<UndoOutlined />} disabled={r.entityState=="REVERTED"}>
            {t("Revert")}
          </Button>
        </Popconfirm>
    </Space>
    )}
  />
</Table>
const parentTable=()=><Table dataSource={groupBy(data,"coverageId")} expandable={{expandedRowRender:detailRender,defaultExpandAllRows:true}} rowKey={r=>r.coverageId}>
<Table.Column title={t("Coverage")} dataIndex="coverageId" render={v=><b style={{color:"rgba(0, 0, 0, 0.7)"}}>{renderCoverageName(v)}</b>} />
<Table.Column title={t("Available")} dataIndex="coverageId" render={v=>getCoverageAvailable(v)} key="available" />
<Table.Column title={t("Total")} dataIndex="subData" render={v=>getCoverageTotal(v)} key={"total"}  />
</Table>

return (
    <div>
      <Button type="link" icon={grouped?<UngroupOutlined />:<GroupOutlined />} onClick={()=>setGrouped(!grouped)}>{grouped?t("Ungroup"):t("Group by Coverage")}</Button>
      {grouped?parentTable():detailRender({subData:data})}
    </div>
  );
};

export default ClaimPaymentList;
