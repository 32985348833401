import { ReloadOutlined } from '@ant-design/icons';
import { Button, notification } from "antd";
import React from "react";
import i18n from "./i18n";

export const showUpdateVersion = () => {
  let seconds = 60;
  let closed = false;
  const countdown = setInterval(() => {
    const update = () => {
      console.log("Updating version");
      clearInterval(countdown);
      onUpdateVersion();
    };

    seconds--;
    if (seconds <= 0) {
      update();
    }

    if (!closed)
      notification.info({
        message: i18n.t("A new version is available"),
        key: "updatePanel",
        onClose: () => {
          closed = true;
        },
        description: (
          <div>
            <div>{i18n.t("Please save your work and update your version by closing other SISos tabs and clicking the button below.")}</div>
            <Button type="primary" style={{ marginTop: 15 }} icon={<ReloadOutlined />} onClick={() => update()}>
              {i18n.t("Update Version")} {seconds}
            </Button>
          </div>
        ),
        duration: 0,
      });
  }, 1000);
};

const currentVersion = process.env.REACT_APP_VERSION;
const interval = setInterval(function () {
  fetch("/version.json?tick=" + new Date().getTime())
    .then((r) => r.json())
    .then((r) => {
      if (r.version !== currentVersion) {
        showUpdateVersion();
        clearInterval(interval);
      }
    })
    .catch((e) => console.log("Unable to check version", e));
}, 30000);

const onUpdateVersion = () => {
  window.location.reload();
};
