import React, {useEffect, useState} from 'react';
import {DatePicker} from "antd"
import { setDate } from "../../Lib/Helpers";

const DatePickerW = (props) => {
    const [value, setValue] = useState(undefined);
    useEffect(() => {
        //need to hold state and to this conversion because of the way v4 antd works
        setValue(typeof props.value === "string" ? setDate(props.value) : props.value);
    }, [props.value]);

    const onChange=v=>{
        setValue(v);
        props.onChange&&props.onChange(v)
    }

    return (
        <DatePicker {...props} value={value} onChange={v=>onChange(v)}  />
    );
};

export default DatePickerW;