import React, {useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Modal } from "antd";
import {useTranslation} from "react-i18next";
import Currency from "../Shared/Currency";
import AccountGroupSelect from "./AcountGroupSelect";
import {exe} from "../../Lib/Dal";

const DepositControlApproval = ({visible,onOk,onCancel,form,deposit,workspaceId}) => {
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    const field=form.getFieldDecorator;
    
    const onOkInternal=()=>{
        form.validateFields((err,values)=>{
            if(err) return;
            if(!workspaceId) return message.error(t("Please select a workspace"));  
            setLoading(true);
            //deposit approval
            exe("ApproveDeposit",{transferId:deposit.transferId,destinationAccountId:values.destinationAccountId,concept:values.concept,workspaceId:workspaceId }).then(r=>{
                setLoading(false);
                if(r.ok) {
                    onOk&&onOk(values);
                }else message.error(r.msg);
            })
            
        })
    }
    return (
        <Modal visible={visible} onOk={onOkInternal} onCancel={onCancel}>
            <Form.Item label={t("Destination")} >
                {field("destinationAccountId",{rules:[{required:true}]})(<AccountGroupSelect group={"SUPERVISORDEPOSITS"} currency={deposit&&deposit.currency} style={{width:"100%"}}/>)}
            </Form.Item>
            <Form.Item label={t("Concept")} >
                {field("concept")(<Input />)}
            </Form.Item>
        </Modal>
    );
};

export default Form.create()(DepositControlApproval);