import React from "react";
import { Tree, message } from "antd";
import CptSelect from "./CptSelect";
import { exe } from "Lib/Dal";

const { TreeNode } = Tree;

//props fn onSelect
export default class ProcedureTree extends React.Component {
  state = {
    treeData: [],
    expandedKeys: [],
    autoExpandParent: true,
    selectedKeys: [],
    loading: false
  };
  componentDidMount() {
    this.setState({ loading: true });
    exe("RepoCpt", { operation: "GET", filter: "level=1" }).then(r => {
      this.setState({ loading: false });
      if (r.ok) {
        const treeData = r.outData.map(q => ({
          key: q.code,
          title: q.code + "-" + q.longDescription,
          short: q.description,
          category: q.category
        }));
        this.setState({ treeData });
      } else {
        message.error(r.msg);
      }
    });
  }

  onLoadData = treeNode =>
    new Promise(resolve => {
      if (treeNode.props.children) {
        resolve();
        return;
      }
      this.loadParent(treeNode.props.eventKey).then(() => resolve());
    });

  loadParent = parentKey =>
    new Promise(resolve => {
      if (this.state.treeData.find(p => p.key == parentKey).children) resolve(); //already loaded
      exe("RepoCpt", {
        operation: "GET",
        filter: "parent='" + parentKey + "'"
      }).then(r => {
        if (r.ok) {
          const children = r.outData.map(q => ({
            key: q.code,
            title: q.code + "-" + q.longDescription,
            short: q.description,
            category: q.category,
            isLeaf: true
          }));
          const td = [...this.state.treeData];
          td.find(p => p.key == parentKey).children = children;
          this.setState({ treeData: td });
        } else {
          message.error(r.msg);
        }
        resolve();
      });
    });

  onExpand = expandedKeys => {
    console.log("onExpand", expandedKeys);
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };
  onSelect = (selectedKeys, info) => {
    console.log(selectedKeys, info, "SELECT TREE");
    if (info && !info.node.props.isLeaf) return;
    this.setState({ selectedKeys });
    this.props.onSelect(selectedKeys[0]);
  };
  onAutoCompleteSelect = r => {
    console.log(r, "SELECT AUTOCOMPLETE");
    this.onSelect([r.code]);
    this.setState({
      expandedKeys: [r.parent],
      selectedKeys: [r.code],
      autoExpandParent: true
    });
    // this.props.onSelect(r);
    // this.loadParent(r.parent).then(() => {
    //   this.setState({
    //     expandedKeys: [r.parent],
    //     selectedKeys: [r.code],
    //     autoExpandParent: true
    //   });
    // });
  };
  renderTreeNodes = data =>
    data.map(item => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

  render() {
    return (
      <div>
        <CptSelect onSelect={this.onAutoCompleteSelect} />
        <Tree.DirectoryTree
          loading={this.state.loading}
          loadData={this.onLoadData}
          onExpand={this.onExpand}
          expandedKeys={this.state.expandedKeys}
          autoExpandParent={this.state.autoExpandParent}
          onSelect={this.onSelect}
          selectedKeys={this.state.selectedKeys}>
          {this.renderTreeNodes(this.state.treeData)}
        </Tree.DirectoryTree>
      </div>
    );
  }
}
