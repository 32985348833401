import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  DatePicker,
  Tag,
  Switch,
  Checkbox,
  InputNumber,
  Badge,
} from "antd";
import { exe } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";
import moment from "moment";
import Compare from "../Shared/Compare";
import ProductSelect from "../Life/ProductSelect";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import AccountSelectDetail from "./AccountSelectDetail";
import UserSelect from "../Bpm/UserSelect";
import TransactionTemplateSelect from "./TransactionTemplateSelect";
import DatePickerW from "../Shared/DatePickerW";

const TransactionsFilter = (props) => {
  const [matches, setMatches] = useState(null);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (props.filter) {
      props.form.setFieldsValue(props.filter);
    }
    if (window.location.hash.includes("?date=")) {
      const date = window.location.hash.split("?date=")[1];
      props.form.setFieldsValue({ date: moment(date) });
      submit();
    }
    if (window.location.hash.includes("?id=")) {
      const id = window.location.hash.split("?id=")[1];
      props.form.setFieldsValue({ id: id });
      submit();
    }
  }, []);
  
  useEffect(()=>{
   submit();
  },[props.pagination.current,props.reload])

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      console.log(values, "FORM VALUES");
      let queries = [];
      if (values.date)
        queries.push(`YEAR(date)=${values.date.format("YYYY")} AND MONTH(date)=${values.date.format("M")} AND DAY(date)=${values.date.format("D")}`);
      if (values.month) queries.push(`YEAR(date)=${values.month.format("YYYY")} AND MONTH(date)=${values.month.format("M")}`);
      if (values.code) queries.push(`code='${values.code}'`);
      if (values.reference) queries.push(`reference LIKE '%${values.reference}%'`);
      if (values.operationCode) queries.push(`operationCode=${values.operationCode}`);
      if (values.status !== undefined) queries.push(`status=${values.status}`);
      if (values.id !== undefined) queries.push(`id=${values.id}`);
      if (values.user !== undefined) queries.push(`[user]='${values.user}'`);
      if (values.template !== undefined) queries.push(`[templateCode]='${values.template}'`);
      if (values.account !== undefined) queries.push(`id in (SELECT transactionId FROM TransactionLine WHERE account='${values.account}')`);
      if (values.range) {
        queries.push(`date between '${values.range[0].format("YYYY-MM-DD")}' and  '${values.range[1].format("YYYY-MM-DD")}'`);
      }

      const filterQuery = queries.join(" AND ");
      console.log(queries, filterQuery);

      exe("RepoTransaction", { operation: "GET", filter: filterQuery,include:["Lines","Process","Lines.CostCenters","Lines.CostCenters.CostCenter","Lines.Account"],size:props.pagination.pageSize,page: props.pagination.current - 1 }).then((r) => {
        console.log(r);
        props.onResults(r);
        setMatches(r.total);
      });
    });
  };

  return (
    <div>
      <Drawer title={t("Transaction Filter")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Form.Item label={t("Transaction ID")} >
            {getFieldDecorator("id")(<InputNumber  />)}
          </Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Specific Day")} className="flexGrow">{getFieldDecorator("date")(<DatePickerW style={{width:"100%"}}  />)}</Form.Item>
            <Form.Item label={t("Month")} className="flexGrow" style={{ marginLeft: 5 }}>
              {getFieldDecorator("month")(<DatePicker.MonthPicker style={{width:"100%"}}  />)}
            </Form.Item>
          </div>
          <Form.Item label={t("Date Range")}>{getFieldDecorator("range")(<DatePicker.RangePicker />)}</Form.Item>
          <Form.Item label={t("Code")}>{getFieldDecorator("code")(<Input placeholder={t("Transaction code")} />)}</Form.Item>
          <Form.Item label={t("Reference")}>{getFieldDecorator("reference")(<Input placeholder={t("Transaction reference")} />)}</Form.Item>
          <Form.Item label={t("Status")}>
            {getFieldDecorator("status")(
              <Select style={{ width: "100%" }} allowClear>
                <Select.Option value={0}>
                  <Badge status="error" />
                  {t("Draft")}
                </Select.Option>
                <Select.Option value={1}>
                  <Badge status="success" />
                  {t("Posted")}
                </Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={t("Operation Code")} style={{ width: "100%" }}>
            {getFieldDecorator("operationCode")(<InputNumber style={{ width: "100%" }} />)}
          </Form.Item>
          <Form.Item label={t("Affected Account")} style={{ width: "100%" }}>
            {getFieldDecorator("account")(<AccountSelectDetail style={{ width: "100%" }} />)}
          </Form.Item>
          <Form.Item label={t("User")} style={{ width: "100%" }}>
            {getFieldDecorator("user")(<UserSelect />)}
          </Form.Item>
          <Form.Item label={t("Template")} style={{ width: "100%" }}>
            {getFieldDecorator("template")(<TransactionTemplateSelect />)}
          </Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(TransactionsFilter);
