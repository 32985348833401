import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Form, Input, Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { exe } from '../../Lib/Dal';
import { message } from 'antd';
import { getColor } from '../../Lib/Helpers';
import Status from '../Shared/Status';
import BenefitOperationTypeSelect from './BenefitOperationTypeSelect';
import { PlusOutlined } from '@ant-design/icons';
import PensionMemberSelect from './PensionMemberSelect';

const BenefitOperations = (props) => {
    const [selected, setSelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [t]=useTranslation();
    const [form]=Form.useForm();
 
    useEffect(()=>{
        if(props.pensionSchemeId) load();
    },[])

    const load=()=>{
        setLoading(true);
        exe("RepoBenefitOperation",{operation:"GET",filter:`pensionSchemeId=${props.pensionSchemeId}`,include:["Process","PensionMember.Contact"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else {
                message.error(r.msg);
            }
        })
    }
    const onOk=()=>{
        form.validateFields().then(values=>{
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoBenefitOperation",{operation:operation,entity:{...values,pensionSchemeId:props.pensionSchemeId}}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    load();
                    setSelected();
                }else message.error(r.msg);
            })
        })
    }
    const onNewRecord=()=>{
        setSelected({id:0,pensionSchemeId:props.pensionSchemeId});
        form.resetFields();
    }
    const benefitRender=(v,r)=>{
        switch(v){
            case 0: return t("Annuity");
            case 1: return t("Lump Sum");
            case 2: return t("Partial Lump Sum");
            default: return "";}
    }
    const renderStatus=(v)=>{
        switch(v){
            case 0: return t("Pending");
            case 1: return t("Executed");
            default: return "";
        }
    }

    return (<div>
<Button type="link" icon={<PlusOutlined />} onClick={onNewRecord}>{t("New Request")}</Button>
<Table dataSource={data} rowKey={"id"} loading={loading}>
    <Table.Column title={t("Id")} dataIndex="id"  />
    <Table.Column title={t("Member")} dataIndex="pensionMemberId" render={(v,r)=>r.PensionMember.Contact.FullName} />
    <Table.Column title={t("Description")} dataIndex="name" />
    <Table.Column title={t("Code")} dataIndex="code" />
    <Table.Column title={t("Status")} dataIndex="status" render={renderStatus} />
    <Table.Column title={t("Benefit")} dataIndex="operationType" render={benefitRender} />
    <Table.Column title={t("Amount")} dataIndex="amount" />
    <Table.Column title={t("Execution")} dataIndex="executionDate" />
    <Table.Column
          dataIndex="Process"
          title={t("Workflow")}
          render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load()} />}
        />
    </Table>  
    <Modal title={t("Benefit Operation Request")} visible={selected} okText={t("Request")} onOk={onOk} onCancel={()=>setSelected()}>
        <Form layout="vertical" form={form}>
            <Form.Item label={t("Description")} name="name" rules={[{required:true}]}>
                <Input />
            </Form.Item>
            <Form.Item label={t("Member")} name="pensionMemberId" rules={[{required:true}]}>
                <PensionMemberSelect pensionSchemeId={props.pensionSchemeId} />
            </Form.Item>
            <Form.Item label={t("Benefit")} name="operationType" rules={[{required:true}]}>
                <BenefitOperationTypeSelect />
            </Form.Item>

        </Form>
    </Modal>
    </div>)
}
export default BenefitOperations;