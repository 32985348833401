import {
  CheckOutlined,
  DeleteOutlined,
  FilterOutlined,
  PlusOutlined,
  ReloadOutlined,
  FolderOpenOutlined
} from '@ant-design/icons';

import { Button, Divider, message, Popconfirm, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeGet } from "../../Lib/Dal";
import { formatDate, getColor } from "../../Lib/Helpers";
import Status from "../Shared/Status";
import ContactIssueDetail from "./ContactIssueDetail";
import ContactIssuesFilter from "./ContactIssuesFilter";

const ContactIssues = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [t] = useTranslation();
  const [filterVisible, setFilterVisible] = useState(false);
  const [issueTypes,setIssueTypes]=useState([])
  const [issueReasons,setIssueReasons]=useState([])
  const [priorities,setPriorities]=useState([]);
  const [selected,setSelected]=useState(null);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });

  useEffect(() => {
    load(props.contactId);
  }, [props.contactId, pagination.current]);
  
  useEffect(()=>{
    loadIssueTypes();
    loadIssueReasons();
    loadPriorities();
  },[]);

  const load = (contactId,searchFilter="") => {
    const filter=searchFilter?searchFilter:(contactId?`contactId=${contactId}`:"");
    setLoading(true);
    exe("RepoIssue", { operation: "GET", filter: filter, include: ["Process"],extraFields:contactId?false:true,
    size:pagination.pageSize,page:pagination.current-1 }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setPagination({ ...pagination, total: r.total, current: pagination.total !== r.total ? 1 : pagination.current });
        setData(r.outData);
        if (props.count) props.count(r.outData.filter((p) => !p.closed).length);
      } else message.error(r.msg);
    });
  };
  const loadIssueTypes=()=>{
    exe("RepoIssueTypeCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setIssueTypes(r.outData);
      } else message.error(r.msg);
    });
  }
  const loadIssueReasons=()=>{
    exe("RepoIssueReasonCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setIssueReasons(r.outData);
      } else message.error(r.msg);
    });
  }
  const loadPriorities=()=>{
    exe("RepoPriorityCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setPriorities(r.outData);
      } else message.error(r.msg);
    });
  }
  const onNewIssue = () => {
    setDetailVisible(true);
    setSelected(null);
  };
  const newIssue = (values,uploadedFiles) => {
    const contactId=props.contactId?props.contactId:values.contactId; //update from tracker
    const operation=values.id?"UPDATE":"ADD";
    setLoading(true);
    exe("RepoIssue", { operation: operation, entity: values }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.contactId);
        setDetailVisible(false);
        //adding files
        if(uploadedFiles&&uploadedFiles.length>0){
          setLoading(true);
          uploadedFiles.forEach((p,i) => {
            p.contactId = contactId;
            p.issueId=r.outData[0].id;
              exe("RepoContactDocument", { operation: "ADD", entity:p }).then((r) => {
                if(i+1===uploadedFiles.length) setLoading(false);
                if (r.ok) {
                  //if(i+1===uploadedFiles.length) load(props.contactId);
                } else {
                  message.error(r.msg);
                }
              });
          });
        }
      } else message.error(r.msg);
    });
  };
  const onDelete = (issueId) => {
    setLoading(true);
    exe("RepoIssue", { operation: "DELETE", entity: { id: issueId } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.contactId);
      } else message.error(r.msg);
    });
  };
  const closeIssue = (issue) => {
    issue.closed = true;
    setLoading(true);
    exe("RepoIssue", { operation: "UPDATE", entity: issue }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.contactId);
      } else message.error(r.msg);
    });
  };
  const getAction = (r) => {
    if (!r.closed) {
      const enityState = safeGet(["Process", "entityState"], r, "");
      if (enityState == "APROVED" || enityState == "CLOSED") {
        return (
          <Button icon={<CheckOutlined />} type="primary" onClick={() => closeIssue(r)}>
            {t("Close")}
          </Button>
        );
      } else {
        return <Tag>{t("Pending")}</Tag>;
      }
    } else return <Tag color="green">{t("Closed")}</Tag>;
  };
  const onFilter = (filterFields) => {
    console.log(filterFields);
    let filter = props.contactId?`contactId=${props.contactId}`:"1=1"; //can be called from contact tab or issue tracker

    if (filterFields.id) filter += ` AND id=${filterFields.id}`;
    if (filterFields.contactId) filter += ` AND contactId=${filterFields.contactId}`;
    if (filterFields.policyId) filter += ` AND policyId=${filterFields.policyId}`;
    if (filterFields.closed != undefined) filter += ` AND closed=${filterFields.closed}`;
    if (filterFields.issueType) filter += ` AND issueType='${filterFields.issueType}'`;
    if (filterFields.issueReason) filter += ` AND issueReason='${filterFields.issueReason}'`;
    if (filterFields.priorityCode) filter += ` AND priorityCode='${filterFields.priorityCode}'`;
    if (filterFields.externalId) filter += ` AND externalId='${filterFields.externalId}'`;
    if (filterFields.created != undefined && filterFields.created.length > 0)
      filter += ` AND created BETWEEN '${filterFields.created[0].format("YYYY-MM-DD")}' and '${filterFields.created[1].format("YYYY-MM-DD")}'`;
    if (filterFields.closeDate != undefined && filterFields.closeDate.length > 0)
      filter += ` AND closeDate BETWEEN '${filterFields.closeDate[0].format("YYYY-MM-DD")}' and '${filterFields.closeDate[1].format("YYYY-MM-DD")}'`;

    load(props.contactId,filter);
  };
  
  const getIssueTypeName=(code)=>{
    const issueType=issueTypes.find(p=>p.code===code);
    return issueType?t(issueType.name):code;
  }
  const getIssueReasonName=(code)=>{
    const issueReason=issueReasons.find(p=>p.code===code);
    return issueReason?t(issueReason.name):code;
  }
  const getPriorityName=(code)=>{
    const priority=priorities.find(p=>p.code===code);
    return priority?t(priority.name):code;
  }
  const onSelectIssue=(issue)=>{
    setSelected(issue);
    setDetailVisible(true);
  }
  return (
    <div>
      <Button type="link" icon={<PlusOutlined />} onClick={onNewIssue}>
        {t("New Issue")}
      </Button>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => load(props.contactId)}>
        {t("Reload")}
      </Button>
      <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
        {t("Filter")}
      </Button>
      <Table dataSource={data} loading={loading} pagination={pagination} onChange={(pag) => setPagination(pag)}>
        <Table.Column dataIndex="id" title={t("Id")} />
        {props.contactId ? null : <Table.Column dataIndex="contactName" title={t("Contact")} render={(v,r)=><a href={'#/contact/'+r.contactId+'/tabissues'}>{v}</a>} />}
        <Table.Column dataIndex="title" title={t("Title")} render={(v) => <b>{v}</b>} />
        <Table.Column dataIndex="description" title={t("Description")} />
        <Table.Column dataIndex="issueType" title={t("Type")} render={v=>getIssueTypeName(v)} />
        <Table.Column dataIndex="issueReason" title={t("Reason")} render={v=>getIssueReasonName(v)} />
        <Table.Column dataIndex="priorityCode" title={t("Priority")} render={v=>getPriorityName(v)} />
        <Table.Column dataIndex="created" title={t("Opened")} render={(v) => formatDate(v)} />
        <Table.Column dataIndex="closed" title={t("Closed")} render={(v, r) => (v ? formatDate(r.closeDate) : "-")} />
        <Table.Column key="action" title={t("Action")} render={(v, r) => getAction(r)} />
        <Table.Column
          dataIndex="Process"
          title={t("Workflow")}
          render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load(props.contactId)} />}
        />
        <Table.Column
          key="actions"
          title={t("Actions")}
          render={(v, r) => (
            <div>
              <Button type="link" icon={<FolderOpenOutlined />} onClick={()=>onSelectIssue(r)} />
              <Divider type="vertical" />
              <Popconfirm title={t("Are you sure you want to delete this issue?")} onConfirm={() => onDelete(r.id)}>
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
            </div>
          )}
        />
      </Table>
      <ContactIssueDetail visible={detailVisible} onCancel={() => setDetailVisible(false)} 
      onOk={(v,uploadedFiles) => newIssue(v,uploadedFiles)} selected={selected} contactId={props.contactId} />
      <ContactIssuesFilter visible={filterVisible} onClose={() => setFilterVisible(false)} onSearch={(v) => onFilter(v)} />
    </div>
  );
};

export default ContactIssues;
