export default [
  {
    "key": "Infectious And Parasitic Diseases",
    "title": "001-139 Infectious And Parasitic Diseases",
    "threedigit": "001",
    "children": [
      {
        "key": "Intestinal Infectious Diseases",
        "title": "001-009 Intestinal Infectious Diseases",
        "threedigit": "001",
        "children": [
          {
            "key": "Cholera",
            "title": "001 - Cholera",
            "threedigit": "001",
            "children": [
              {
                "key": "0010",
                "title": "0010 Cholera d/t vib cholerae",
                "threedigit": "001",
                "isLeaf": true
              },
              {
                "key": "0011",
                "title": "0011 Cholera d/t vib el tor",
                "threedigit": "001",
                "isLeaf": true
              },
              {
                "key": "0019",
                "title": "0019 Cholera NOS",
                "threedigit": "001",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Typhoid and paratyphoid fevers",
            "title": "002 - Typhoid and paratyphoid fevers",
            "threedigit": "002",
            "children": [
              {
                "key": "0020",
                "title": "0020 Typhoid fever",
                "threedigit": "002",
                "isLeaf": true
              },
              {
                "key": "0021",
                "title": "0021 Paratyphoid fever a",
                "threedigit": "002",
                "isLeaf": true
              },
              {
                "key": "0022",
                "title": "0022 Paratyphoid fever b",
                "threedigit": "002",
                "isLeaf": true
              },
              {
                "key": "0023",
                "title": "0023 Paratyphoid fever c",
                "threedigit": "002",
                "isLeaf": true
              },
              {
                "key": "0029",
                "title": "0029 Paratyphoid fever NOS",
                "threedigit": "002",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other salmonella infections",
            "title": "003 - Other salmonella infections",
            "threedigit": "003",
            "children": [
              {
                "key": "0030",
                "title": "0030 Salmonella enteritis",
                "threedigit": "003",
                "isLeaf": true
              },
              {
                "key": "0031",
                "title": "0031 Salmonella septicemia",
                "threedigit": "003",
                "isLeaf": true
              },
              {
                "key": "00320",
                "title": "00320 Local salmonella inf NOS",
                "threedigit": "003",
                "isLeaf": true
              },
              {
                "key": "00321",
                "title": "00321 Salmonella meningitis",
                "threedigit": "003",
                "isLeaf": true
              },
              {
                "key": "00322",
                "title": "00322 Salmonella pneumonia",
                "threedigit": "003",
                "isLeaf": true
              },
              {
                "key": "00323",
                "title": "00323 Salmonella arthritis",
                "threedigit": "003",
                "isLeaf": true
              },
              {
                "key": "00324",
                "title": "00324 Salmonella osteomyelitis",
                "threedigit": "003",
                "isLeaf": true
              },
              {
                "key": "00329",
                "title": "00329 Local salmonella inf NEC",
                "threedigit": "003",
                "isLeaf": true
              },
              {
                "key": "0038",
                "title": "0038 Salmonella infection NEC",
                "threedigit": "003",
                "isLeaf": true
              },
              {
                "key": "0039",
                "title": "0039 Salmonella infection NOS",
                "threedigit": "003",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Shigellosis",
            "title": "004 - Shigellosis",
            "threedigit": "004",
            "children": [
              {
                "key": "0040",
                "title": "0040 Shigella dysenteriae",
                "threedigit": "004",
                "isLeaf": true
              },
              {
                "key": "0041",
                "title": "0041 Shigella flexneri",
                "threedigit": "004",
                "isLeaf": true
              },
              {
                "key": "0042",
                "title": "0042 Shigella boydii",
                "threedigit": "004",
                "isLeaf": true
              },
              {
                "key": "0043",
                "title": "0043 Shigella sonnei",
                "threedigit": "004",
                "isLeaf": true
              },
              {
                "key": "0048",
                "title": "0048 Shigella infection NEC",
                "threedigit": "004",
                "isLeaf": true
              },
              {
                "key": "0049",
                "title": "0049 Shigellosis NOS",
                "threedigit": "004",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other food poisoning (bacterial)",
            "title": "005 - Other food poisoning (bacterial)",
            "threedigit": "005",
            "children": [
              {
                "key": "0050",
                "title": "0050 Staph food poisoning",
                "threedigit": "005",
                "isLeaf": true
              },
              {
                "key": "0051",
                "title": "0051 Botulism food poisoning",
                "threedigit": "005",
                "isLeaf": true
              },
              {
                "key": "0052",
                "title": "0052 Food pois d/t c. perfrin",
                "threedigit": "005",
                "isLeaf": true
              },
              {
                "key": "0053",
                "title": "0053 Food pois: clostrid NEC",
                "threedigit": "005",
                "isLeaf": true
              },
              {
                "key": "0054",
                "title": "0054 Food pois: v. parahaem",
                "threedigit": "005",
                "isLeaf": true
              },
              {
                "key": "00581",
                "title": "00581 Food poisn d/t v. vulnif",
                "threedigit": "005",
                "isLeaf": true
              },
              {
                "key": "00589",
                "title": "00589 Bact food poisoning NEC",
                "threedigit": "005",
                "isLeaf": true
              },
              {
                "key": "0059",
                "title": "0059 Food poisoning NOS",
                "threedigit": "005",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Amebiasis",
            "title": "006 - Amebiasis",
            "threedigit": "006",
            "children": [
              {
                "key": "0060",
                "title": "0060 Ac amebiasis w/o abscess",
                "threedigit": "006",
                "isLeaf": true
              },
              {
                "key": "0061",
                "title": "0061 Chr amebiasis w/o absces",
                "threedigit": "006",
                "isLeaf": true
              },
              {
                "key": "0062",
                "title": "0062 Amebic nondysent colitis",
                "threedigit": "006",
                "isLeaf": true
              },
              {
                "key": "0063",
                "title": "0063 Amebic liver abscess",
                "threedigit": "006",
                "isLeaf": true
              },
              {
                "key": "0064",
                "title": "0064 Amebic lung abscess",
                "threedigit": "006",
                "isLeaf": true
              },
              {
                "key": "0065",
                "title": "0065 Amebic brain abscess",
                "threedigit": "006",
                "isLeaf": true
              },
              {
                "key": "0066",
                "title": "0066 Amebic skin ulceration",
                "threedigit": "006",
                "isLeaf": true
              },
              {
                "key": "0068",
                "title": "0068 Amebic infection NEC",
                "threedigit": "006",
                "isLeaf": true
              },
              {
                "key": "0069",
                "title": "0069 Amebiasis NOS",
                "threedigit": "006",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other protozoal intestinal diseases",
            "title": "007 - Other protozoal intestinal diseases",
            "threedigit": "007",
            "children": [
              {
                "key": "0070",
                "title": "0070 Balantidiasis",
                "threedigit": "007",
                "isLeaf": true
              },
              {
                "key": "0071",
                "title": "0071 Giardiasis",
                "threedigit": "007",
                "isLeaf": true
              },
              {
                "key": "0072",
                "title": "0072 Coccidiosis",
                "threedigit": "007",
                "isLeaf": true
              },
              {
                "key": "0073",
                "title": "0073 Intest trichomoniasis",
                "threedigit": "007",
                "isLeaf": true
              },
              {
                "key": "0074",
                "title": "0074 Cryptosporidiosis",
                "threedigit": "007",
                "isLeaf": true
              },
              {
                "key": "0075",
                "title": "0075 Cyclosporiasis",
                "threedigit": "007",
                "isLeaf": true
              },
              {
                "key": "0078",
                "title": "0078 Protozoal intest dis NEC",
                "threedigit": "007",
                "isLeaf": true
              },
              {
                "key": "0079",
                "title": "0079 Protozoal intest dis NOS",
                "threedigit": "007",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Intestinal infections due to other organisms",
            "title": "008 - Intestinal infections due to other organisms",
            "threedigit": "008",
            "children": [
              {
                "key": "00800",
                "title": "00800 Intest infec e coli NOS",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00801",
                "title": "00801 Int inf e coli entrpath",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00802",
                "title": "00802 Int inf e coli entrtoxgn",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00803",
                "title": "00803 Int inf e coli entrnvsv",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00804",
                "title": "00804 Int inf e coli entrhmrg",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00809",
                "title": "00809 Int inf e coli spcf NEC",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "0081",
                "title": "0081 Arizona enteritis",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "0082",
                "title": "0082 Aerobacter enteritis",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "0083",
                "title": "0083 Proteus enteritis",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00841",
                "title": "00841 Staphylococc enteritis",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00842",
                "title": "00842 Pseudomonas enteritis",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00843",
                "title": "00843 Int infec campylobacter",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00844",
                "title": "00844 Int inf yrsnia entrcltca",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00845",
                "title": "00845 Int inf clstrdium dfcile",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00846",
                "title": "00846 Intes infec oth anerobes",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00847",
                "title": "00847 Int inf oth grm neg bctr",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00849",
                "title": "00849 Bacterial enteritis NEC",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "0085",
                "title": "0085 Bacterial enteritis NOS",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00861",
                "title": "00861 Intes infec rotavirus",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00862",
                "title": "00862 Intes infec adenovirus",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00863",
                "title": "00863 Int inf norwalk virus",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00864",
                "title": "00864 Int inf oth sml rnd vrus",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00865",
                "title": "00865 Enteritis d/t calicivirs",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00866",
                "title": "00866 Intes infec astrovirus",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00867",
                "title": "00867 Int inf enterovirus NEC",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "00869",
                "title": "00869 Other viral intes infec",
                "threedigit": "008",
                "isLeaf": true
              },
              {
                "key": "0088",
                "title": "0088 Viral enteritis NOS",
                "threedigit": "008",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ill-defined intestinal infections",
            "title": "009 - Ill-defined intestinal infections",
            "threedigit": "009",
            "children": [
              {
                "key": "0090",
                "title": "0090 Infectious enteritis NOS",
                "threedigit": "009",
                "isLeaf": true
              },
              {
                "key": "0091",
                "title": "0091 Enteritis of infect orig",
                "threedigit": "009",
                "isLeaf": true
              },
              {
                "key": "0092",
                "title": "0092 Infectious diarrhea NOS",
                "threedigit": "009",
                "isLeaf": true
              },
              {
                "key": "0093",
                "title": "0093 Diarrhea of infect orig",
                "threedigit": "009",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Tuberculosis",
        "title": "010-018 Tuberculosis",
        "threedigit": "010",
        "children": [
          {
            "key": "Primary tuberculous infection",
            "title": "010 - Primary tuberculous infection",
            "threedigit": "010",
            "children": [
              {
                "key": "01000",
                "title": "01000 Prim TB complex-unspec",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01001",
                "title": "01001 Prim TB complex-no exam",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01002",
                "title": "01002 Prim TB complex-exm unkn",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01003",
                "title": "01003 Prim TB complex-micro dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01004",
                "title": "01004 Prim TB complex-cult dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01005",
                "title": "01005 Prim TB complex-histo dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01006",
                "title": "01006 Prim TB complex-oth test",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01010",
                "title": "01010 Prim TB pleurisy-unspec",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01011",
                "title": "01011 Prim TB pleurisy-no exam",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01012",
                "title": "01012 Prim TB pleur-exam unkn",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01013",
                "title": "01013 Prim TB pleuris-micro dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01014",
                "title": "01014 Prim TB pleurisy-cult dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01015",
                "title": "01015 Prim TB pleuris-histo dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01016",
                "title": "01016 Prim TB pleuris-oth test",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01080",
                "title": "01080 Prim prog TB NEC-unspec",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01081",
                "title": "01081 Prim prog TB NEC-no exam",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01082",
                "title": "01082 Prim pr TB NEC-exam unkn",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01083",
                "title": "01083 Prim prg TB NEC-micro dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01084",
                "title": "01084 Prim prog TB NEC-cult dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01085",
                "title": "01085 Prim prg TB NEC-histo dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01086",
                "title": "01086 Prim prg TB NEC-oth test",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01090",
                "title": "01090 Primary TB NOS-unspec",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01091",
                "title": "01091 Primary TB NOS-no exam",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01092",
                "title": "01092 Primary TB NOS-exam unkn",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01093",
                "title": "01093 Primary TB NOS-micro dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01094",
                "title": "01094 Primary TB NOS-cult dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01095",
                "title": "01095 Primary TB NOS-histo dx",
                "threedigit": "010",
                "isLeaf": true
              },
              {
                "key": "01096",
                "title": "01096 Primary TB NOS-oth test",
                "threedigit": "010",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pulmonary tuberculosis",
            "title": "011 - Pulmonary tuberculosis",
            "threedigit": "011",
            "children": [
              {
                "key": "01100",
                "title": "01100 TB lung infiltr-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01101",
                "title": "01101 TB lung infiltr-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01102",
                "title": "01102 TB lung infiltr-exm unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01103",
                "title": "01103 TB lung infiltr-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01104",
                "title": "01104 TB lung infiltr-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01105",
                "title": "01105 TB lung infiltr-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01106",
                "title": "01106 TB lung infiltr-oth test",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01110",
                "title": "01110 TB lung nodular-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01111",
                "title": "01111 TB lung nodular-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01112",
                "title": "01112 TB lung nodul-exam unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01113",
                "title": "01113 TB lung nodular-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01114",
                "title": "01114 TB lung nodular-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01115",
                "title": "01115 TB lung nodular-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01116",
                "title": "01116 TB lung nodular-oth test",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01120",
                "title": "01120 TB lung w cavity-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01121",
                "title": "01121 TB lung w cavity-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01122",
                "title": "01122 TB lung cavity-exam unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01123",
                "title": "01123 TB lung w cavit-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01124",
                "title": "01124 TB lung w cavity-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01125",
                "title": "01125 TB lung w cavit-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01126",
                "title": "01126 TB lung w cavit-oth test",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01130",
                "title": "01130 TB of bronchus-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01131",
                "title": "01131 TB of bronchus-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01132",
                "title": "01132 TB of bronchus-exam unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01133",
                "title": "01133 TB of bronchus-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01134",
                "title": "01134 TB of bronchus-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01135",
                "title": "01135 TB of bronchus-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01136",
                "title": "01136 TB of bronchus-oth test",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01140",
                "title": "01140 TB lung fibrosis-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01141",
                "title": "01141 TB lung fibrosis-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01142",
                "title": "01142 TB lung fibros-exam unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01143",
                "title": "01143 TB lung fibros-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01144",
                "title": "01144 TB lung fibrosis-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01145",
                "title": "01145 TB lung fibros-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01146",
                "title": "01146 TB lung fibros-oth test",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01150",
                "title": "01150 TB bronchiectasis-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01151",
                "title": "01151 TB bronchiect-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01152",
                "title": "01152 TB bronchiect-exam unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01153",
                "title": "01153 TB bronchiect-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01154",
                "title": "01154 TB bronchiect-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01155",
                "title": "01155 TB bronchiect-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01156",
                "title": "01156 TB bronchiect-oth test",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01160",
                "title": "01160 TB pneumonia-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01161",
                "title": "01161 TB pneumonia-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01162",
                "title": "01162 TB pneumonia-exam unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01163",
                "title": "01163 TB pneumonia-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01164",
                "title": "01164 TB pneumonia-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01165",
                "title": "01165 TB pneumonia-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01166",
                "title": "01166 TB pneumonia-oth test",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01170",
                "title": "01170 TB pneumothorax-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01171",
                "title": "01171 TB pneumothorax-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01172",
                "title": "01172 TB pneumothorx-exam unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01173",
                "title": "01173 TB pneumothorax-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01174",
                "title": "01174 TB pneumothorax-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01175",
                "title": "01175 TB pneumothorax-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01176",
                "title": "01176 TB pneumothorax-oth test",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01180",
                "title": "01180 Pulmonary TB NEC-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01181",
                "title": "01181 Pulmonary TB NEC-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01182",
                "title": "01182 Pulmon TB NEC-exam unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01183",
                "title": "01183 Pulmon TB NEC-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01184",
                "title": "01184 Pulmon TB NEC-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01185",
                "title": "01185 Pulmon TB NEC-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01186",
                "title": "01186 Pulmon TB NEC-oth test",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01190",
                "title": "01190 Pulmonary TB NOS-unspec",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01191",
                "title": "01191 Pulmonary TB NOS-no exam",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01192",
                "title": "01192 Pulmon TB NOS-exam unkn",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01193",
                "title": "01193 Pulmon TB NOS-micro dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01194",
                "title": "01194 Pulmon TB NOS-cult dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01195",
                "title": "01195 Pulmon TB NOS-histo dx",
                "threedigit": "011",
                "isLeaf": true
              },
              {
                "key": "01196",
                "title": "01196 Pulmon TB NOS-oth test",
                "threedigit": "011",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other respiratory tuberculosis",
            "title": "012 - Other respiratory tuberculosis",
            "threedigit": "012",
            "children": [
              {
                "key": "01200",
                "title": "01200 TB pleurisy-unspec",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01201",
                "title": "01201 TB pleurisy-no exam",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01202",
                "title": "01202 TB pleurisy-exam unkn",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01203",
                "title": "01203 TB pleurisy-micro dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01204",
                "title": "01204 TB pleurisy-cult dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01205",
                "title": "01205 TB pleurisy-histolog dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01206",
                "title": "01206 TB pleurisy-oth test",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01210",
                "title": "01210 TB thoracic nodes-unspec",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01211",
                "title": "01211 TB thorax node-no exam",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01212",
                "title": "01212 TB thorax node-exam unkn",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01213",
                "title": "01213 TB thorax node-micro dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01214",
                "title": "01214 TB thorax node-cult dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01215",
                "title": "01215 TB thorax node-histo dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01216",
                "title": "01216 TB thorax node-oth test",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01220",
                "title": "01220 Isol tracheal tb-unspec",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01221",
                "title": "01221 Isol tracheal tb-no exam",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01222",
                "title": "01222 Isol trach tb-exam unkn",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01223",
                "title": "01223 Isolat trach tb-micro dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01224",
                "title": "01224 Isol tracheal tb-cult dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01225",
                "title": "01225 Isolat trach tb-histo dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01226",
                "title": "01226 Isolat trach tb-oth test",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01230",
                "title": "01230 TB laryngitis-unspec",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01231",
                "title": "01231 TB laryngitis-no exam",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01232",
                "title": "01232 TB laryngitis-exam unkn",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01233",
                "title": "01233 TB laryngitis-micro dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01234",
                "title": "01234 TB laryngitis-cult dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01235",
                "title": "01235 TB laryngitis-histo dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01236",
                "title": "01236 TB laryngitis-oth test",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01280",
                "title": "01280 Resp TB NEC-unspec",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01281",
                "title": "01281 Resp TB NEC-no exam",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01282",
                "title": "01282 Resp TB NEC-exam unkn",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01283",
                "title": "01283 Resp TB NEC-micro dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01284",
                "title": "01284 Resp TB NEC-cult dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01285",
                "title": "01285 Resp TB NEC-histo dx",
                "threedigit": "012",
                "isLeaf": true
              },
              {
                "key": "01286",
                "title": "01286 Resp TB NEC-oth test",
                "threedigit": "012",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Tuberculosis of meninges and central nervous system",
            "title": "013 - Tuberculosis of meninges and central nervous system",
            "threedigit": "013",
            "children": [
              {
                "key": "01300",
                "title": "01300 TB meningitis-unspec",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01301",
                "title": "01301 TB meningitis-no exam",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01302",
                "title": "01302 TB meningitis-exam unkn",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01303",
                "title": "01303 TB meningitis-micro dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01304",
                "title": "01304 TB meningitis-cult dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01305",
                "title": "01305 TB meningitis-histo dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01306",
                "title": "01306 TB meningitis-oth test",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01310",
                "title": "01310 Tubrclma meninges-unspec",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01311",
                "title": "01311 Tubrclma mening-no exam",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01312",
                "title": "01312 Tubrclma menin-exam unkn",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01313",
                "title": "01313 Tubrclma mening-micro dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01314",
                "title": "01314 Tubrclma mening-cult dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01315",
                "title": "01315 Tubrclma mening-histo dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01316",
                "title": "01316 Tubrclma mening-oth test",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01320",
                "title": "01320 Tuberculoma brain-unspec",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01321",
                "title": "01321 Tubrcloma brain-no exam",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01322",
                "title": "01322 Tubrclma brain-exam unkn",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01323",
                "title": "01323 Tubrcloma brain-micro dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01324",
                "title": "01324 Tubrcloma brain-cult dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01325",
                "title": "01325 Tubrcloma brain-histo dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01326",
                "title": "01326 Tubrcloma brain-oth test",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01330",
                "title": "01330 TB brain abscess-unspec",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01331",
                "title": "01331 TB brain abscess-no exam",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01332",
                "title": "01332 TB brain absc-exam unkn",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01333",
                "title": "01333 TB brain absc-micro dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01334",
                "title": "01334 TB brain abscess-cult dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01335",
                "title": "01335 TB brain absc-histo dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01336",
                "title": "01336 TB brain absc-oth test",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01340",
                "title": "01340 Tubrclma sp cord-unspec",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01341",
                "title": "01341 Tubrclma sp cord-no exam",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01342",
                "title": "01342 Tubrclma sp cd-exam unkn",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01343",
                "title": "01343 Tubrclma sp crd-micro dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01344",
                "title": "01344 Tubrclma sp cord-cult dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01345",
                "title": "01345 Tubrclma sp crd-histo dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01346",
                "title": "01346 Tubrclma sp crd-oth test",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01350",
                "title": "01350 TB sp crd abscess-unspec",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01351",
                "title": "01351 TB sp crd absc-no exam",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01352",
                "title": "01352 TB sp crd absc-exam unkn",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01353",
                "title": "01353 TB sp crd absc-micro dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01354",
                "title": "01354 TB sp crd absc-cult dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01355",
                "title": "01355 TB sp crd absc-histo dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01356",
                "title": "01356 TB sp crd absc-oth test",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01360",
                "title": "01360 TB encephalitis-unspec",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01361",
                "title": "01361 TB encephalitis-no exam",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01362",
                "title": "01362 TB encephalit-exam unkn",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01363",
                "title": "01363 TB encephalitis-micro dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01364",
                "title": "01364 TB encephalitis-cult dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01365",
                "title": "01365 TB encephalitis-histo dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01366",
                "title": "01366 TB encephalitis-oth test",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01380",
                "title": "01380 Cns TB NEC-unspec",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01381",
                "title": "01381 Cns TB NEC-no exam",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01382",
                "title": "01382 Cns TB NEC-exam unkn",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01383",
                "title": "01383 Cns TB NEC-micro dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01384",
                "title": "01384 Cns TB NEC-cult dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01385",
                "title": "01385 Cns TB NEC-histo dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01386",
                "title": "01386 Cns TB NEC-oth test",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01390",
                "title": "01390 Cns TB NOS-unspec",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01391",
                "title": "01391 Cns TB NOS-no exam",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01392",
                "title": "01392 Cns TB NOS-exam unkn",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01393",
                "title": "01393 Cns TB NOS-micro dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01394",
                "title": "01394 Cns TB NOS-cult dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01395",
                "title": "01395 Cns TB NOS-histo dx",
                "threedigit": "013",
                "isLeaf": true
              },
              {
                "key": "01396",
                "title": "01396 Cns TB NOS-oth test",
                "threedigit": "013",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Tuberculosis of intestines peritoneum and mesenteric glands",
            "title": "014 - Tuberculosis of intestines peritoneum and mesenteric glands",
            "threedigit": "014",
            "children": [
              {
                "key": "01400",
                "title": "01400 TB peritonitis-unspec",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01401",
                "title": "01401 TB peritonitis-no exam",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01402",
                "title": "01402 TB peritonitis-exam unkn",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01403",
                "title": "01403 TB peritonitis-micro dx",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01404",
                "title": "01404 TB peritonitis-cult dx",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01405",
                "title": "01405 TB peritonitis-histo dx",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01406",
                "title": "01406 TB peritonitis-oth test",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01480",
                "title": "01480 Intestinal TB NEC-unspec",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01481",
                "title": "01481 Intestin TB NEC-no exam",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01482",
                "title": "01482 Intest TB NEC-exam unkn",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01483",
                "title": "01483 Intestin TB NEC-micro dx",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01484",
                "title": "01484 Intestin TB NEC-cult dx",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01485",
                "title": "01485 Intestin TB NEC-histo dx",
                "threedigit": "014",
                "isLeaf": true
              },
              {
                "key": "01486",
                "title": "01486 Intestin TB NEC-oth test",
                "threedigit": "014",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Tuberculosis of bones and joints",
            "title": "015 - Tuberculosis of bones and joints",
            "threedigit": "015",
            "children": [
              {
                "key": "01500",
                "title": "01500 TB of vertebra-unspec",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01501",
                "title": "01501 TB of vertebra-no exam",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01502",
                "title": "01502 TB of vertebra-exam unkn",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01503",
                "title": "01503 TB of vertebra-micro dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01504",
                "title": "01504 TB of vertebra-cult dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01505",
                "title": "01505 TB of vertebra-histo dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01506",
                "title": "01506 TB of vertebra-oth test",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01510",
                "title": "01510 TB of hip-unspec",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01511",
                "title": "01511 TB of hip-no exam",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01512",
                "title": "01512 TB of hip-exam unkn",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01513",
                "title": "01513 TB of hip-micro dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01514",
                "title": "01514 TB of hip-cult dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01515",
                "title": "01515 TB of hip-histo dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01516",
                "title": "01516 TB of hip-oth test",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01520",
                "title": "01520 TB of knee-unspec",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01521",
                "title": "01521 TB of knee-no exam",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01522",
                "title": "01522 TB of knee-exam unkn",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01523",
                "title": "01523 TB of knee-micro dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01524",
                "title": "01524 TB of knee-cult dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01525",
                "title": "01525 TB of knee-histo dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01526",
                "title": "01526 TB of knee-oth test",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01550",
                "title": "01550 TB of limb bones-unspec",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01551",
                "title": "01551 TB limb bones-no exam",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01552",
                "title": "01552 TB limb bones-exam unkn",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01553",
                "title": "01553 TB limb bones-micro dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01554",
                "title": "01554 TB limb bones-cult dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01555",
                "title": "01555 TB limb bones-histo dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01556",
                "title": "01556 TB limb bones-oth test",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01560",
                "title": "01560 TB of mastoid-unspec",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01561",
                "title": "01561 TB of mastoid-no exam",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01562",
                "title": "01562 TB of mastoid-exam unkn",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01563",
                "title": "01563 TB of mastoid-micro dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01564",
                "title": "01564 TB of mastoid-cult dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01565",
                "title": "01565 TB of mastoid-histo dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01566",
                "title": "01566 TB of mastoid-oth test",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01570",
                "title": "01570 TB of bone NEC-unspec",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01571",
                "title": "01571 TB of bone NEC-no exam",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01572",
                "title": "01572 TB of bone NEC-exam unkn",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01573",
                "title": "01573 TB of bone NEC-micro dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01574",
                "title": "01574 TB of bone NEC-cult dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01575",
                "title": "01575 TB of bone NEC-histo dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01576",
                "title": "01576 TB of bone NEC-oth test",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01580",
                "title": "01580 TB of joint NEC-unspec",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01581",
                "title": "01581 TB of joint NEC-no exam",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01582",
                "title": "01582 TB joint NEC-exam unkn",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01583",
                "title": "01583 TB of joint NEC-micro dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01584",
                "title": "01584 TB of joint NEC-cult dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01585",
                "title": "01585 TB of joint NEC-histo dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01586",
                "title": "01586 TB of joint NEC-oth test",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01590",
                "title": "01590 TB bone/joint NOS-unspec",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01591",
                "title": "01591 TB bone/jt NOS-no exam",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01592",
                "title": "01592 TB bone/jt NOS-exam unkn",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01593",
                "title": "01593 TB bone/jt NOS-micro dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01594",
                "title": "01594 TB bone/jt NOS-cult dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01595",
                "title": "01595 TB bone/jt NOS-histo dx",
                "threedigit": "015",
                "isLeaf": true
              },
              {
                "key": "01596",
                "title": "01596 TB bone/jt NOS-oth test",
                "threedigit": "015",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Tuberculosis of genitourinary system",
            "title": "016 - Tuberculosis of genitourinary system",
            "threedigit": "016",
            "children": [
              {
                "key": "01600",
                "title": "01600 TB of kidney-unspec",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01601",
                "title": "01601 TB of kidney-no exam",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01602",
                "title": "01602 TB of kidney-exam unkn",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01603",
                "title": "01603 TB of kidney-micro dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01604",
                "title": "01604 TB of kidney-cult dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01605",
                "title": "01605 TB of kidney-histo dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01606",
                "title": "01606 TB of kidney-oth test",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01610",
                "title": "01610 TB of bladder-unspec",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01611",
                "title": "01611 TB of bladder-no exam",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01612",
                "title": "01612 TB of bladder-exam unkn",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01613",
                "title": "01613 TB of bladder-micro dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01614",
                "title": "01614 TB of bladder-cult dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01615",
                "title": "01615 TB of bladder-histo dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01616",
                "title": "01616 TB of bladder-oth test",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01620",
                "title": "01620 TB of ureter-unspec",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01621",
                "title": "01621 TB of ureter-no exam",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01622",
                "title": "01622 TB of ureter-exam unkn",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01623",
                "title": "01623 TB of ureter-micro dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01624",
                "title": "01624 TB of ureter-cult dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01625",
                "title": "01625 TB of ureter-histo dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01626",
                "title": "01626 TB of ureter-oth test",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01630",
                "title": "01630 TB urinary NEC-unspec",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01631",
                "title": "01631 TB urinary NEC-no exam",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01632",
                "title": "01632 TB urinary NEC-exam unkn",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01633",
                "title": "01633 TB urinary NEC-micro dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01634",
                "title": "01634 TB urinary NEC-cult dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01635",
                "title": "01635 TB urinary NEC-histo dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01636",
                "title": "01636 TB urinary NEC-oth test",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01640",
                "title": "01640 TB epididymis-unspec",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01641",
                "title": "01641 TB epididymis-no exam",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01642",
                "title": "01642 TB epididymis-exam unkn",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01643",
                "title": "01643 TB epididymis-micro dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01644",
                "title": "01644 TB epididymis-cult dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01645",
                "title": "01645 TB epididymis-histo dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01646",
                "title": "01646 TB epididymis-oth test",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01650",
                "title": "01650 TB male genit NEC-unspec",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01651",
                "title": "01651 TB male gen NEC-no exam",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01652",
                "title": "01652 TB male gen NEC-ex unkn",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01653",
                "title": "01653 TB male gen NEC-micro dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01654",
                "title": "01654 TB male gen NEC-cult dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01655",
                "title": "01655 TB male gen NEC-histo dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01656",
                "title": "01656 TB male gen NEC-oth test",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01660",
                "title": "01660 TB ovary & tube-unspec",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01661",
                "title": "01661 TB ovary & tube-no exam",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01662",
                "title": "01662 TB ovary/tube-exam unkn",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01663",
                "title": "01663 TB ovary & tube-micro dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01664",
                "title": "01664 TB ovary & tube-cult dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01665",
                "title": "01665 TB ovary & tube-histo dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01666",
                "title": "01666 TB ovary & tube-oth test",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01670",
                "title": "01670 TB female gen NEC-unspec",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01671",
                "title": "01671 TB fem gen NEC-no exam",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01672",
                "title": "01672 TB fem gen NEC-exam unkn",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01673",
                "title": "01673 TB fem gen NEC-micro dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01674",
                "title": "01674 TB fem gen NEC-cult dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01675",
                "title": "01675 TB fem gen NEC-histo dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01676",
                "title": "01676 TB fem gen NEC-oth test",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01690",
                "title": "01690 Gu TB NOS-unspec",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01691",
                "title": "01691 Gu TB NOS-no exam",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01692",
                "title": "01692 Gu TB NOS-exam unkn",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01693",
                "title": "01693 Gu TB NOS-micro dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01694",
                "title": "01694 Gu TB NOS-cult dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01695",
                "title": "01695 Gu TB NOS-histo dx",
                "threedigit": "016",
                "isLeaf": true
              },
              {
                "key": "01696",
                "title": "01696 Gu TB NOS-oth test",
                "threedigit": "016",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Tuberculosis of other organs",
            "title": "017 - Tuberculosis of other organs",
            "threedigit": "017",
            "children": [
              {
                "key": "01700",
                "title": "01700 TB skin/subcutan-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01701",
                "title": "01701 TB skin/subcut-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01702",
                "title": "01702 TB skin/subcut-exam unkn",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01703",
                "title": "01703 TB skin/subcut-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01704",
                "title": "01704 TB skin/subcut-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01705",
                "title": "01705 TB skin/subcut-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01706",
                "title": "01706 TB skin/subcut-oth test",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01710",
                "title": "01710 Erythema nodos tb-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01711",
                "title": "01711 Erythem nodos tb-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01712",
                "title": "01712 Erythem nod tb-exam unkn",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01713",
                "title": "01713 Erythem nod tb-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01714",
                "title": "01714 Erythem nodos tb-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01715",
                "title": "01715 Erythem nod tb-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01716",
                "title": "01716 Erythem nod tb-oth test",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01720",
                "title": "01720 TB periph lymph-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01721",
                "title": "01721 TB periph lymph-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01722",
                "title": "01722 TB periph lymph-exam unk",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01723",
                "title": "01723 TB periph lymph-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01724",
                "title": "01724 TB periph lymph-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01725",
                "title": "01725 TB periph lymph-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01726",
                "title": "01726 TB periph lymph-oth test",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01730",
                "title": "01730 TB of eye-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01731",
                "title": "01731 TB of eye-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01732",
                "title": "01732 TB of eye-exam unkn",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01733",
                "title": "01733 TB of eye-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01734",
                "title": "01734 TB of eye-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01735",
                "title": "01735 TB of eye-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01736",
                "title": "01736 TB of eye-oth test",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01740",
                "title": "01740 TB of ear-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01741",
                "title": "01741 TB of ear-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01742",
                "title": "01742 TB of ear-exam unkn",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01743",
                "title": "01743 TB of ear-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01744",
                "title": "01744 TB of ear-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01745",
                "title": "01745 TB of ear-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01746",
                "title": "01746 TB of ear-oth test",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01750",
                "title": "01750 TB of thyroid-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01751",
                "title": "01751 TB of thyroid-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01752",
                "title": "01752 TB of thyroid-exam unkn",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01753",
                "title": "01753 TB of thyroid-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01754",
                "title": "01754 TB of thyroid-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01755",
                "title": "01755 TB of thyroid-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01756",
                "title": "01756 TB of thyroid-oth test",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01760",
                "title": "01760 TB of adrenal-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01761",
                "title": "01761 TB of adrenal-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01762",
                "title": "01762 TB of adrenal-exam unkn",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01763",
                "title": "01763 TB of adrenal-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01764",
                "title": "01764 TB of adrenal-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01765",
                "title": "01765 TB of adrenal-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01766",
                "title": "01766 TB of adrenal-oth test",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01770",
                "title": "01770 TB of spleen-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01771",
                "title": "01771 TB of spleen-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01772",
                "title": "01772 TB of spleen-exam unkn",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01773",
                "title": "01773 TB of spleen-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01774",
                "title": "01774 TB of spleen-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01775",
                "title": "01775 TB of spleen-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01776",
                "title": "01776 TB of spleen-oth test",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01780",
                "title": "01780 TB esophagus-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01781",
                "title": "01781 TB esophagus-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01782",
                "title": "01782 TB esophagus-exam unkn",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01783",
                "title": "01783 TB esophagus-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01784",
                "title": "01784 TB esophagus-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01785",
                "title": "01785 TB esophagus-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01786",
                "title": "01786 TB esophagus-oth test",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01790",
                "title": "01790 TB of organ NEC-unspec",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01791",
                "title": "01791 TB of organ NEC-no exam",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01792",
                "title": "01792 TB organ NEC-exam unkn",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01793",
                "title": "01793 TB of organ NEC-micro dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01794",
                "title": "01794 TB of organ NEC-cult dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01795",
                "title": "01795 TB of organ NEC-histo dx",
                "threedigit": "017",
                "isLeaf": true
              },
              {
                "key": "01796",
                "title": "01796 TB of organ NEC-oth test",
                "threedigit": "017",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Miliary tuberculosis",
            "title": "018 - Miliary tuberculosis",
            "threedigit": "018",
            "children": [
              {
                "key": "01800",
                "title": "01800 Acute miliary tb-unspec",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01801",
                "title": "01801 Acute miliary tb-no exam",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01802",
                "title": "01802 Ac miliary tb-exam unkn",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01803",
                "title": "01803 Ac miliary tb-micro dx",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01804",
                "title": "01804 Acute miliary tb-cult dx",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01805",
                "title": "01805 Ac miliary tb-histo dx",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01806",
                "title": "01806 Ac miliary tb-oth test",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01880",
                "title": "01880 Miliary TB NEC-unspec",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01881",
                "title": "01881 Miliary TB NEC-no exam",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01882",
                "title": "01882 Miliary TB NEC-exam unkn",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01883",
                "title": "01883 Miliary TB NEC-micro dx",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01884",
                "title": "01884 Miliary TB NEC-cult dx",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01885",
                "title": "01885 Miliary TB NEC-histo dx",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01886",
                "title": "01886 Miliary TB NEC-oth test",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01890",
                "title": "01890 Miliary TB NOS-unspec",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01891",
                "title": "01891 Miliary TB NOS-no exam",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01892",
                "title": "01892 Miliary TB NOS-exam unkn",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01893",
                "title": "01893 Miliary TB NOS-micro dx",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01894",
                "title": "01894 Miliary TB NOS-cult dx",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01895",
                "title": "01895 Miliary TB NOS-histo dx",
                "threedigit": "018",
                "isLeaf": true
              },
              {
                "key": "01896",
                "title": "01896 Miliary TB NOS-oth test",
                "threedigit": "018",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Zoonotic Bacterial Diseases",
        "title": "020-027 Zoonotic Bacterial Diseases",
        "threedigit": "020",
        "children": [
          {
            "key": "Plague",
            "title": "020 - Plague",
            "threedigit": "020",
            "children": [
              {
                "key": "0200",
                "title": "0200 Bubonic plague",
                "threedigit": "020",
                "isLeaf": true
              },
              {
                "key": "0201",
                "title": "0201 Cellulocutaneous plague",
                "threedigit": "020",
                "isLeaf": true
              },
              {
                "key": "0202",
                "title": "0202 Septicemic plague",
                "threedigit": "020",
                "isLeaf": true
              },
              {
                "key": "0203",
                "title": "0203 Primary pneumonic plague",
                "threedigit": "020",
                "isLeaf": true
              },
              {
                "key": "0204",
                "title": "0204 Secondary pneumon plague",
                "threedigit": "020",
                "isLeaf": true
              },
              {
                "key": "0205",
                "title": "0205 Pneumonic plague NOS",
                "threedigit": "020",
                "isLeaf": true
              },
              {
                "key": "0208",
                "title": "0208 Other types of plague",
                "threedigit": "020",
                "isLeaf": true
              },
              {
                "key": "0209",
                "title": "0209 Plague NOS",
                "threedigit": "020",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Tularemia",
            "title": "021 - Tularemia",
            "threedigit": "021",
            "children": [
              {
                "key": "0210",
                "title": "0210 Ulceroglandul tularemia",
                "threedigit": "021",
                "isLeaf": true
              },
              {
                "key": "0211",
                "title": "0211 Enteric tularemia",
                "threedigit": "021",
                "isLeaf": true
              },
              {
                "key": "0212",
                "title": "0212 Pulmonary tularemia",
                "threedigit": "021",
                "isLeaf": true
              },
              {
                "key": "0213",
                "title": "0213 Oculoglandular tularemia",
                "threedigit": "021",
                "isLeaf": true
              },
              {
                "key": "0218",
                "title": "0218 Tularemia NEC",
                "threedigit": "021",
                "isLeaf": true
              },
              {
                "key": "0219",
                "title": "0219 Tularemia NOS",
                "threedigit": "021",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Anthrax",
            "title": "022 - Anthrax",
            "threedigit": "022",
            "children": [
              {
                "key": "0220",
                "title": "0220 Cutaneous anthrax",
                "threedigit": "022",
                "isLeaf": true
              },
              {
                "key": "0221",
                "title": "0221 Pulmonary anthrax",
                "threedigit": "022",
                "isLeaf": true
              },
              {
                "key": "0222",
                "title": "0222 Gastrointestinal anthrax",
                "threedigit": "022",
                "isLeaf": true
              },
              {
                "key": "0223",
                "title": "0223 Anthrax septicemia",
                "threedigit": "022",
                "isLeaf": true
              },
              {
                "key": "0228",
                "title": "0228 Other anthrax manifest",
                "threedigit": "022",
                "isLeaf": true
              },
              {
                "key": "0229",
                "title": "0229 Anthrax NOS",
                "threedigit": "022",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Brucellosis",
            "title": "023 - Brucellosis",
            "threedigit": "023",
            "children": [
              {
                "key": "0230",
                "title": "0230 Brucella melitensis",
                "threedigit": "023",
                "isLeaf": true
              },
              {
                "key": "0231",
                "title": "0231 Brucella abortus",
                "threedigit": "023",
                "isLeaf": true
              },
              {
                "key": "0232",
                "title": "0232 Brucella suis",
                "threedigit": "023",
                "isLeaf": true
              },
              {
                "key": "0233",
                "title": "0233 Brucella canis",
                "threedigit": "023",
                "isLeaf": true
              },
              {
                "key": "0238",
                "title": "0238 Brucellosis NEC",
                "threedigit": "023",
                "isLeaf": true
              },
              {
                "key": "0239",
                "title": "0239 Brucellosis NOS",
                "threedigit": "023",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Glanders",
            "title": "024 - Glanders",
            "threedigit": "024",
            "children": [
              {
                "key": "024",
                "title": "024 Glanders",
                "threedigit": "024",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Melioidosis",
            "title": "025 - Melioidosis",
            "threedigit": "025",
            "children": [
              {
                "key": "025",
                "title": "025 Melioidosis",
                "threedigit": "025",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Rat-bite fever",
            "title": "026 - Rat-bite fever",
            "threedigit": "026",
            "children": [
              {
                "key": "0260",
                "title": "0260 Spirillary fever",
                "threedigit": "026",
                "isLeaf": true
              },
              {
                "key": "0261",
                "title": "0261 Streptobacillary fever",
                "threedigit": "026",
                "isLeaf": true
              },
              {
                "key": "0269",
                "title": "0269 Rat-bite fever NOS",
                "threedigit": "026",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other zoonotic bacterial diseases",
            "title": "027 - Other zoonotic bacterial diseases",
            "threedigit": "027",
            "children": [
              {
                "key": "0270",
                "title": "0270 Listeriosis",
                "threedigit": "027",
                "isLeaf": true
              },
              {
                "key": "0271",
                "title": "0271 Erysipelothrix infection",
                "threedigit": "027",
                "isLeaf": true
              },
              {
                "key": "0272",
                "title": "0272 Pasteurellosis",
                "threedigit": "027",
                "isLeaf": true
              },
              {
                "key": "0278",
                "title": "0278 Zoonotic bact dis NEC",
                "threedigit": "027",
                "isLeaf": true
              },
              {
                "key": "0279",
                "title": "0279 Zoonotic bact dis NOS",
                "threedigit": "027",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Bacterial Diseases",
        "title": "030-041 Other Bacterial Diseases",
        "threedigit": "030",
        "children": [
          {
            "key": "Leprosy",
            "title": "030 - Leprosy",
            "threedigit": "030",
            "children": [
              {
                "key": "0300",
                "title": "0300 Lepromatous leprosy",
                "threedigit": "030",
                "isLeaf": true
              },
              {
                "key": "0301",
                "title": "0301 Tuberculoid leprosy",
                "threedigit": "030",
                "isLeaf": true
              },
              {
                "key": "0302",
                "title": "0302 Indeterminate leprosy",
                "threedigit": "030",
                "isLeaf": true
              },
              {
                "key": "0303",
                "title": "0303 Borderline leprosy",
                "threedigit": "030",
                "isLeaf": true
              },
              {
                "key": "0308",
                "title": "0308 Leprosy NEC",
                "threedigit": "030",
                "isLeaf": true
              },
              {
                "key": "0309",
                "title": "0309 Leprosy NOS",
                "threedigit": "030",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases due to other mycobacteria",
            "title": "031 - Diseases due to other mycobacteria",
            "threedigit": "031",
            "children": [
              {
                "key": "0310",
                "title": "0310 Pulmonary mycobacteria",
                "threedigit": "031",
                "isLeaf": true
              },
              {
                "key": "0311",
                "title": "0311 Cutaneous mycobacteria",
                "threedigit": "031",
                "isLeaf": true
              },
              {
                "key": "0312",
                "title": "0312 DMAC bacteremia",
                "threedigit": "031",
                "isLeaf": true
              },
              {
                "key": "0318",
                "title": "0318 Mycobacterial dis NEC",
                "threedigit": "031",
                "isLeaf": true
              },
              {
                "key": "0319",
                "title": "0319 Mycobacterial dis NOS",
                "threedigit": "031",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diphtheria",
            "title": "032 - Diphtheria",
            "threedigit": "032",
            "children": [
              {
                "key": "0320",
                "title": "0320 Faucial diphtheria",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "0321",
                "title": "0321 Nasopharynx diphtheria",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "0322",
                "title": "0322 Ant nasal diphtheria",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "0323",
                "title": "0323 Laryngeal diphtheria",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "03281",
                "title": "03281 Conjunctival diphtheria",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "03282",
                "title": "03282 Diphtheritic myocarditis",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "03283",
                "title": "03283 Diphtheritic peritonitis",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "03284",
                "title": "03284 Diphtheritic cystitis",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "03285",
                "title": "03285 Cutaneous diphtheria",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "03289",
                "title": "03289 Diphtheria NEC",
                "threedigit": "032",
                "isLeaf": true
              },
              {
                "key": "0329",
                "title": "0329 Diphtheria NOS",
                "threedigit": "032",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Whooping cough",
            "title": "033 - Whooping cough",
            "threedigit": "033",
            "children": [
              {
                "key": "0330",
                "title": "0330 Bordetella pertussis",
                "threedigit": "033",
                "isLeaf": true
              },
              {
                "key": "0331",
                "title": "0331 Bordetella parapertussis",
                "threedigit": "033",
                "isLeaf": true
              },
              {
                "key": "0338",
                "title": "0338 Whooping cough NEC",
                "threedigit": "033",
                "isLeaf": true
              },
              {
                "key": "0339",
                "title": "0339 Whooping cough NOS",
                "threedigit": "033",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Streptococcal sore throat and scarlet fever",
            "title": "034 - Streptococcal sore throat and scarlet fever",
            "threedigit": "034",
            "children": [
              {
                "key": "0340",
                "title": "0340 Strep sore throat",
                "threedigit": "034",
                "isLeaf": true
              },
              {
                "key": "0341",
                "title": "0341 Scarlet fever",
                "threedigit": "034",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Erysipelas",
            "title": "035 - Erysipelas",
            "threedigit": "035",
            "children": [
              {
                "key": "035",
                "title": "035 Erysipelas",
                "threedigit": "035",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Meningococcal infection",
            "title": "036 - Meningococcal infection",
            "threedigit": "036",
            "children": [
              {
                "key": "0360",
                "title": "0360 Meningococcal meningitis",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "0361",
                "title": "0361 Meningococc encephalitis",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "0362",
                "title": "0362 Meningococcemia",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "0363",
                "title": "0363 Meningococc adrenal synd",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "03640",
                "title": "03640 Meningococc carditis NOS",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "03641",
                "title": "03641 Meningococc pericarditis",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "03642",
                "title": "03642 Meningococc endocarditis",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "03643",
                "title": "03643 Meningococc myocarditis",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "03681",
                "title": "03681 Meningococc optic neurit",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "03682",
                "title": "03682 Meningococc arthropathy",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "03689",
                "title": "03689 Meningococcal infect NEC",
                "threedigit": "036",
                "isLeaf": true
              },
              {
                "key": "0369",
                "title": "0369 Meningococcal infect NOS",
                "threedigit": "036",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Tetanus",
            "title": "037 - Tetanus",
            "threedigit": "037",
            "children": [
              {
                "key": "037",
                "title": "037 Tetanus",
                "threedigit": "037",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Septicemia",
            "title": "038 - Septicemia",
            "threedigit": "038",
            "children": [
              {
                "key": "0380",
                "title": "0380 Streptococcal septicemia",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03810",
                "title": "03810 Staphylcocc septicem NOS",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03811",
                "title": "03811 Meth susc Staph aur sept",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03812",
                "title": "03812 MRSA septicemia",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03819",
                "title": "03819 Staphylcocc septicem NEC",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "0382",
                "title": "0382 Pneumococcal septicemia",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "0383",
                "title": "0383 Anaerobic septicemia",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03840",
                "title": "03840 Gram-neg septicemia NOS",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03841",
                "title": "03841 H. influenae septicemia",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03842",
                "title": "03842 E coli septicemia",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03843",
                "title": "03843 Pseudomonas septicemia",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03844",
                "title": "03844 Serratia septicemia",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "03849",
                "title": "03849 Gram-neg septicemia NEC",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "0388",
                "title": "0388 Septicemia NEC",
                "threedigit": "038",
                "isLeaf": true
              },
              {
                "key": "0389",
                "title": "0389 Septicemia NOS",
                "threedigit": "038",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Actinomycotic infections",
            "title": "039 - Actinomycotic infections",
            "threedigit": "039",
            "children": [
              {
                "key": "0390",
                "title": "0390 Cutaneous actinomycosis",
                "threedigit": "039",
                "isLeaf": true
              },
              {
                "key": "0391",
                "title": "0391 Pulmonary actinomycosis",
                "threedigit": "039",
                "isLeaf": true
              },
              {
                "key": "0392",
                "title": "0392 Abdominal actinomycosis",
                "threedigit": "039",
                "isLeaf": true
              },
              {
                "key": "0393",
                "title": "0393 Cervicofac actinomycosis",
                "threedigit": "039",
                "isLeaf": true
              },
              {
                "key": "0394",
                "title": "0394 Madura foot",
                "threedigit": "039",
                "isLeaf": true
              },
              {
                "key": "0398",
                "title": "0398 Actinomycosis NEC",
                "threedigit": "039",
                "isLeaf": true
              },
              {
                "key": "0399",
                "title": "0399 Actinomycosis NOS",
                "threedigit": "039",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other bacterial diseases",
            "title": "040 - Other bacterial diseases",
            "threedigit": "040",
            "children": [
              {
                "key": "0400",
                "title": "0400 Gas gangrene",
                "threedigit": "040",
                "isLeaf": true
              },
              {
                "key": "0401",
                "title": "0401 Rhinoscleroma",
                "threedigit": "040",
                "isLeaf": true
              },
              {
                "key": "0402",
                "title": "0402 Whipple's disease",
                "threedigit": "040",
                "isLeaf": true
              },
              {
                "key": "0403",
                "title": "0403 Necrobacillosis",
                "threedigit": "040",
                "isLeaf": true
              },
              {
                "key": "04041",
                "title": "04041 Infant botulism",
                "threedigit": "040",
                "isLeaf": true
              },
              {
                "key": "04042",
                "title": "04042 Wound botulism",
                "threedigit": "040",
                "isLeaf": true
              },
              {
                "key": "04081",
                "title": "04081 Tropical pyomyositis",
                "threedigit": "040",
                "isLeaf": true
              },
              {
                "key": "04082",
                "title": "04082 Toxic shock syndrome",
                "threedigit": "040",
                "isLeaf": true
              },
              {
                "key": "04089",
                "title": "04089 Bacterial diseases NEC",
                "threedigit": "040",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Bacterial infection in conditions classified elsewhere and of unspecified site",
            "title": "041 - Bacterial infection in conditions classified elsewhere and of unspecified site",
            "threedigit": "041",
            "children": [
              {
                "key": "04100",
                "title": "04100 Streptococcus unspecf",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04101",
                "title": "04101 Streptococcus group a",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04102",
                "title": "04102 Streptococcus group b",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04103",
                "title": "04103 Streptococcus group c",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04104",
                "title": "04104 Enterococcus group d",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04105",
                "title": "04105 Streptococcus group g",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04109",
                "title": "04109 Other streptococcus",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04110",
                "title": "04110 Staphylococcus unspcfied",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04111",
                "title": "04111 Mth sus Stph aur els/NOS",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04112",
                "title": "04112 MRSA elsewhere/NOS",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04119",
                "title": "04119 Other staphylococcus",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "0412",
                "title": "0412 Pneumococcus infect NOS",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "0413",
                "title": "0413 Klebsiella pneumoniae",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04141",
                "title": "04141 Shiga txn-produce E.coli",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04142",
                "title": "04142 Shga txn prod E.coli NEC",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04143",
                "title": "04143 Shga txn prod E.coli NOS",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04149",
                "title": "04149 E.coli infection NEC/NOS",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "0415",
                "title": "0415 H. influenzae infect NOS",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "0416",
                "title": "0416 Proteus infection NOS",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "0417",
                "title": "0417 Pseudomonas infect NOS",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04181",
                "title": "04181 Mycoplasma",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04182",
                "title": "04182 Bacteroides fragilis",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04183",
                "title": "04183 Clostridium perfringens",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04184",
                "title": "04184 Other anaerobes",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04185",
                "title": "04185 Oth gram negatv bacteria",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04186",
                "title": "04186 Helicobacter pylori",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "04189",
                "title": "04189 Oth specf bacteria",
                "threedigit": "041",
                "isLeaf": true
              },
              {
                "key": "0419",
                "title": "0419 Bacterial infection NOS",
                "threedigit": "041",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Human Immunodeficiency Virus",
        "title": "042-042 Human Immunodeficiency Virus",
        "threedigit": "042",
        "children": [
          {
            "key": "Human immunodeficiency virus [HIV] disease",
            "title": "042 - Human immunodeficiency virus [HIV] disease",
            "threedigit": "042",
            "children": [
              {
                "key": "042",
                "title": "042 Human immuno virus dis",
                "threedigit": "042",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Poliomyelitis And Other Non-Arthropod-Borne Viral Diseases Of Central Nervous System",
        "title": "045-049 Poliomyelitis And Other Non-Arthropod-Borne Viral Diseases Of Central Nervous System",
        "threedigit": "045",
        "children": [
          {
            "key": "Acute poliomyelitis",
            "title": "045 - Acute poliomyelitis",
            "threedigit": "045",
            "children": [
              {
                "key": "04500",
                "title": "04500 Ac bulbar polio-type NOS",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04501",
                "title": "04501 Ac bulbar polio-type 1",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04502",
                "title": "04502 Ac bulbar polio-type 2",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04503",
                "title": "04503 Ac bulbar polio-type 3",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04510",
                "title": "04510 Paral polio NEC-type NOS",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04511",
                "title": "04511 Paral polio NEC-type 1",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04512",
                "title": "04512 Paral polio NEC-type 2",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04513",
                "title": "04513 Paral polio NEC-type 3",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04520",
                "title": "04520 Nonparaly polio-type NOS",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04521",
                "title": "04521 Nonparalyt polio-type 1",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04522",
                "title": "04522 Nonparalyt polio-type 2",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04523",
                "title": "04523 Nonparalyt polio-type 3",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04590",
                "title": "04590 Ac polio NOS-type NOS",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04591",
                "title": "04591 Ac polio NOS-type 1",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04592",
                "title": "04592 Ac polio NOS-type 2",
                "threedigit": "045",
                "isLeaf": true
              },
              {
                "key": "04593",
                "title": "04593 Ac polio NOS-type 3",
                "threedigit": "045",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Slow virus infection and prion diseases of central nervous system",
            "title": "046 - Slow virus infection and prion diseases of central nervous system",
            "threedigit": "046",
            "children": [
              {
                "key": "0460",
                "title": "0460 Kuru",
                "threedigit": "046",
                "isLeaf": true
              },
              {
                "key": "04611",
                "title": "04611 Varnt Creutzfeldt-Jakob",
                "threedigit": "046",
                "isLeaf": true
              },
              {
                "key": "04619",
                "title": "04619 Creutzfldt-Jakob NEC/NOS",
                "threedigit": "046",
                "isLeaf": true
              },
              {
                "key": "0462",
                "title": "0462 Subac scleros panenceph",
                "threedigit": "046",
                "isLeaf": true
              },
              {
                "key": "0463",
                "title": "0463 Prog multifoc leukoencep",
                "threedigit": "046",
                "isLeaf": true
              },
              {
                "key": "04671",
                "title": "04671 Gerstmn-Straus-Schnk syn",
                "threedigit": "046",
                "isLeaf": true
              },
              {
                "key": "04672",
                "title": "04672 Fatal familial insomnia",
                "threedigit": "046",
                "isLeaf": true
              },
              {
                "key": "04679",
                "title": "04679 Prion dis of CNS NEC/NOS",
                "threedigit": "046",
                "isLeaf": true
              },
              {
                "key": "0468",
                "title": "0468 Cns slow virus infec NEC",
                "threedigit": "046",
                "isLeaf": true
              },
              {
                "key": "0469",
                "title": "0469 Cns slow virus infec NOS",
                "threedigit": "046",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Meningitis due to enterovirus",
            "title": "047 - Meningitis due to enterovirus",
            "threedigit": "047",
            "children": [
              {
                "key": "0470",
                "title": "0470 Coxsackie virus mening",
                "threedigit": "047",
                "isLeaf": true
              },
              {
                "key": "0471",
                "title": "0471 Echo virus meningitis",
                "threedigit": "047",
                "isLeaf": true
              },
              {
                "key": "0478",
                "title": "0478 Viral meningitis NEC",
                "threedigit": "047",
                "isLeaf": true
              },
              {
                "key": "0479",
                "title": "0479 Viral meningitis NOS",
                "threedigit": "047",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other enterovirus diseases of central nervous system",
            "title": "048 - Other enterovirus diseases of central nervous system",
            "threedigit": "048",
            "children": [
              {
                "key": "048",
                "title": "048 Oth enteroviral cns dis",
                "threedigit": "048",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other non-arthropod-borne viral diseases of central nervous system",
            "title": "049 - Other non-arthropod-borne viral diseases of central nervous system",
            "threedigit": "049",
            "children": [
              {
                "key": "0490",
                "title": "0490 Lymphocytic choriomening",
                "threedigit": "049",
                "isLeaf": true
              },
              {
                "key": "0491",
                "title": "0491 Adenoviral meningitis",
                "threedigit": "049",
                "isLeaf": true
              },
              {
                "key": "0498",
                "title": "0498 Viral encephalitis NEC",
                "threedigit": "049",
                "isLeaf": true
              },
              {
                "key": "0499",
                "title": "0499 Viral encephalitis NOS",
                "threedigit": "049",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Viral Diseases Accompanied By Exanthem",
        "title": "050-059 Viral Diseases Accompanied By Exanthem",
        "threedigit": "050",
        "children": [
          {
            "key": "Smallpox",
            "title": "050 - Smallpox",
            "threedigit": "050",
            "children": [
              {
                "key": "0500",
                "title": "0500 Variola major",
                "threedigit": "050",
                "isLeaf": true
              },
              {
                "key": "0501",
                "title": "0501 Alastrim",
                "threedigit": "050",
                "isLeaf": true
              },
              {
                "key": "0502",
                "title": "0502 Modified smallpox",
                "threedigit": "050",
                "isLeaf": true
              },
              {
                "key": "0509",
                "title": "0509 Smallpox NOS",
                "threedigit": "050",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cowpox and paravaccinia",
            "title": "051 - Cowpox and paravaccinia",
            "threedigit": "051",
            "children": [
              {
                "key": "05101",
                "title": "05101 Cowpox",
                "threedigit": "051",
                "isLeaf": true
              },
              {
                "key": "05102",
                "title": "05102 Vaccinia n/f vaccination",
                "threedigit": "051",
                "isLeaf": true
              },
              {
                "key": "0511",
                "title": "0511 Pseudocowpox",
                "threedigit": "051",
                "isLeaf": true
              },
              {
                "key": "0512",
                "title": "0512 Contagious pustular derm",
                "threedigit": "051",
                "isLeaf": true
              },
              {
                "key": "0519",
                "title": "0519 Paravaccinia NOS",
                "threedigit": "051",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chickenpox",
            "title": "052 - Chickenpox",
            "threedigit": "052",
            "children": [
              {
                "key": "0520",
                "title": "0520 Postvaricella encephalit",
                "threedigit": "052",
                "isLeaf": true
              },
              {
                "key": "0521",
                "title": "0521 Varicella pneumonitis",
                "threedigit": "052",
                "isLeaf": true
              },
              {
                "key": "0522",
                "title": "0522 Postvaricella myelitis",
                "threedigit": "052",
                "isLeaf": true
              },
              {
                "key": "0527",
                "title": "0527 Varicella complicat NEC",
                "threedigit": "052",
                "isLeaf": true
              },
              {
                "key": "0528",
                "title": "0528 Varicella complicat NOS",
                "threedigit": "052",
                "isLeaf": true
              },
              {
                "key": "0529",
                "title": "0529 Varicella uncomplicated",
                "threedigit": "052",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Herpes zoster",
            "title": "053 - Herpes zoster",
            "threedigit": "053",
            "children": [
              {
                "key": "0530",
                "title": "0530 Herpes zoster meningitis",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05310",
                "title": "05310 H zoster nerv syst NOS",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05311",
                "title": "05311 Geniculate herpes zoster",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05312",
                "title": "05312 Postherpes trigem neural",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05313",
                "title": "05313 Postherpes polyneuropath",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05314",
                "title": "05314 Herpes zoster myelitis",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05319",
                "title": "05319 H zoster nerv syst NEC",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05320",
                "title": "05320 Herpes zoster of eyelid",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05321",
                "title": "05321 H zoster keratoconjunct",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05322",
                "title": "05322 H zoster iridocyclitis",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05329",
                "title": "05329 Herpes zoster of eye NEC",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05371",
                "title": "05371 H zoster otitis externa",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "05379",
                "title": "05379 H zoster complicated NEC",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "0538",
                "title": "0538 H zoster complicated NOS",
                "threedigit": "053",
                "isLeaf": true
              },
              {
                "key": "0539",
                "title": "0539 Herpes zoster NOS",
                "threedigit": "053",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Herpes simplex",
            "title": "054 - Herpes simplex",
            "threedigit": "054",
            "children": [
              {
                "key": "0540",
                "title": "0540 Eczema herpeticum",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05410",
                "title": "05410 Genital herpes NOS",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05411",
                "title": "05411 Herpetic vulvovaginitis",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05412",
                "title": "05412 Herpetic ulcer of vulva",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05413",
                "title": "05413 Herpetic infect of penis",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05419",
                "title": "05419 Genital herpes NEC",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "0542",
                "title": "0542 Herpetic gingivostomat",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "0543",
                "title": "0543 Herpetic encephalitis",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05440",
                "title": "05440 Herpes simplex eye NOS",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05441",
                "title": "05441 Herpes simplex of eyelid",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05442",
                "title": "05442 Dendritic keratitis",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05443",
                "title": "05443 H simplex keratitis",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05444",
                "title": "05444 H simplex iridocyclitis",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05449",
                "title": "05449 Herpes simplex eye NEC",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "0545",
                "title": "0545 Herpetic septicemia",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "0546",
                "title": "0546 Herpetic whitlow",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05471",
                "title": "05471 Visceral herpes simplex",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05472",
                "title": "05472 H simplex meningitis",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05473",
                "title": "05473 H simplex otitis externa",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05474",
                "title": "05474 Herpes simplex myelitis",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "05479",
                "title": "05479 H simplex complicat NEC",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "0548",
                "title": "0548 H simplex complicat NOS",
                "threedigit": "054",
                "isLeaf": true
              },
              {
                "key": "0549",
                "title": "0549 Herpes simplex NOS",
                "threedigit": "054",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Measles",
            "title": "055 - Measles",
            "threedigit": "055",
            "children": [
              {
                "key": "0550",
                "title": "0550 Postmeasles encephalitis",
                "threedigit": "055",
                "isLeaf": true
              },
              {
                "key": "0551",
                "title": "0551 Postmeasles pneumonia",
                "threedigit": "055",
                "isLeaf": true
              },
              {
                "key": "0552",
                "title": "0552 Postmeasles otitis media",
                "threedigit": "055",
                "isLeaf": true
              },
              {
                "key": "05571",
                "title": "05571 Measles keratitis",
                "threedigit": "055",
                "isLeaf": true
              },
              {
                "key": "05579",
                "title": "05579 Measles complication NEC",
                "threedigit": "055",
                "isLeaf": true
              },
              {
                "key": "0558",
                "title": "0558 Measles complication NOS",
                "threedigit": "055",
                "isLeaf": true
              },
              {
                "key": "0559",
                "title": "0559 Measles uncomplicated",
                "threedigit": "055",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Rubella",
            "title": "056 - Rubella",
            "threedigit": "056",
            "children": [
              {
                "key": "05600",
                "title": "05600 Rubella nerve compl NOS",
                "threedigit": "056",
                "isLeaf": true
              },
              {
                "key": "05601",
                "title": "05601 Rubella encephalitis",
                "threedigit": "056",
                "isLeaf": true
              },
              {
                "key": "05609",
                "title": "05609 Rubella nerve compl NEC",
                "threedigit": "056",
                "isLeaf": true
              },
              {
                "key": "05671",
                "title": "05671 Arthritis due to rubella",
                "threedigit": "056",
                "isLeaf": true
              },
              {
                "key": "05679",
                "title": "05679 Rubella complication NEC",
                "threedigit": "056",
                "isLeaf": true
              },
              {
                "key": "0568",
                "title": "0568 Rubella complication NOS",
                "threedigit": "056",
                "isLeaf": true
              },
              {
                "key": "0569",
                "title": "0569 Rubella uncomplicated",
                "threedigit": "056",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other viral exanthemata",
            "title": "057 - Other viral exanthemata",
            "threedigit": "057",
            "children": [
              {
                "key": "0570",
                "title": "0570 Erythema infectiosum",
                "threedigit": "057",
                "isLeaf": true
              },
              {
                "key": "0578",
                "title": "0578 Viral exanthemata NEC",
                "threedigit": "057",
                "isLeaf": true
              },
              {
                "key": "0579",
                "title": "0579 Viral exanthemata NOS",
                "threedigit": "057",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other human herpesvirus",
            "title": "058 - Other human herpesvirus",
            "threedigit": "058",
            "children": [
              {
                "key": "05810",
                "title": "05810 Roseola infantum NOS",
                "threedigit": "058",
                "isLeaf": true
              },
              {
                "key": "05811",
                "title": "05811 Roseola infant d/t HHV-6",
                "threedigit": "058",
                "isLeaf": true
              },
              {
                "key": "05812",
                "title": "05812 Roseola infant d/t HHV-7",
                "threedigit": "058",
                "isLeaf": true
              },
              {
                "key": "05821",
                "title": "05821 Human herpesvir 6 enceph",
                "threedigit": "058",
                "isLeaf": true
              },
              {
                "key": "05829",
                "title": "05829 Human herpesvr encph NEC",
                "threedigit": "058",
                "isLeaf": true
              },
              {
                "key": "05881",
                "title": "05881 Human herpesvirus 6 infc",
                "threedigit": "058",
                "isLeaf": true
              },
              {
                "key": "05882",
                "title": "05882 Human herpesvirus 7 infc",
                "threedigit": "058",
                "isLeaf": true
              },
              {
                "key": "05889",
                "title": "05889 Human herpesvirs inf NEC",
                "threedigit": "058",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other poxvirus infections",
            "title": "059 - Other poxvirus infections",
            "threedigit": "059",
            "children": [
              {
                "key": "05900",
                "title": "05900 Orthopoxvirus infect NOS",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "05901",
                "title": "05901 Monkeypox",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "05909",
                "title": "05909 Orthopoxvirus infect NEC",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "05910",
                "title": "05910 Parapoxvirus infectn NOS",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "05911",
                "title": "05911 Bovine stomatitis",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "05912",
                "title": "05912 Sealpox",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "05919",
                "title": "05919 Parapoxvirus infectn NEC",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "05920",
                "title": "05920 Yatapoxvirus infectn NOS",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "05921",
                "title": "05921 Tanapox",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "05922",
                "title": "05922 Yaba monkey tumor virus",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "0598",
                "title": "0598 Poxvirus infections NEC",
                "threedigit": "059",
                "isLeaf": true
              },
              {
                "key": "0599",
                "title": "0599 Poxvirus infection NOS",
                "threedigit": "059",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Arthropod-Borne Viral Diseases",
        "title": "060-066 Arthropod-Borne Viral Diseases",
        "threedigit": "060",
        "children": [
          {
            "key": "Yellow fever",
            "title": "060 - Yellow fever",
            "threedigit": "060",
            "children": [
              {
                "key": "0600",
                "title": "0600 Sylvatic yellow fever",
                "threedigit": "060",
                "isLeaf": true
              },
              {
                "key": "0601",
                "title": "0601 Urban yellow fever",
                "threedigit": "060",
                "isLeaf": true
              },
              {
                "key": "0609",
                "title": "0609 Yellow fever NOS",
                "threedigit": "060",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dengue",
            "title": "061 - Dengue",
            "threedigit": "061",
            "children": [
              {
                "key": "061",
                "title": "061 Dengue",
                "threedigit": "061",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Mosquito-borne viral encephalitis",
            "title": "062 - Mosquito-borne viral encephalitis",
            "threedigit": "062",
            "children": [
              {
                "key": "0620",
                "title": "0620 Japanese encephalitis",
                "threedigit": "062",
                "isLeaf": true
              },
              {
                "key": "0621",
                "title": "0621 West equine encephalitis",
                "threedigit": "062",
                "isLeaf": true
              },
              {
                "key": "0622",
                "title": "0622 East equine encephalitis",
                "threedigit": "062",
                "isLeaf": true
              },
              {
                "key": "0623",
                "title": "0623 St Louis encephalitis",
                "threedigit": "062",
                "isLeaf": true
              },
              {
                "key": "0624",
                "title": "0624 Australian encephalitis",
                "threedigit": "062",
                "isLeaf": true
              },
              {
                "key": "0625",
                "title": "0625 California encephalitis",
                "threedigit": "062",
                "isLeaf": true
              },
              {
                "key": "0628",
                "title": "0628 Mosquit-borne enceph NEC",
                "threedigit": "062",
                "isLeaf": true
              },
              {
                "key": "0629",
                "title": "0629 Mosquit-borne enceph NOS",
                "threedigit": "062",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Tick-borne viral encephalitis",
            "title": "063 - Tick-borne viral encephalitis",
            "threedigit": "063",
            "children": [
              {
                "key": "0630",
                "title": "0630 Russia spr-summer enceph",
                "threedigit": "063",
                "isLeaf": true
              },
              {
                "key": "0631",
                "title": "0631 Louping ill",
                "threedigit": "063",
                "isLeaf": true
              },
              {
                "key": "0632",
                "title": "0632 Cent Europe encephalitis",
                "threedigit": "063",
                "isLeaf": true
              },
              {
                "key": "0638",
                "title": "0638 Tick-borne enceph NEC",
                "threedigit": "063",
                "isLeaf": true
              },
              {
                "key": "0639",
                "title": "0639 Tick-borne enceph NOS",
                "threedigit": "063",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Viral encephalitis transmitted by other and unspecified arthropods",
            "title": "064 - Viral encephalitis transmitted by other and unspecified arthropods",
            "threedigit": "064",
            "children": [
              {
                "key": "064",
                "title": "064 Vir enceph arthropod NEC",
                "threedigit": "064",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Arthropod-borne hemorrhagic fever",
            "title": "065 - Arthropod-borne hemorrhagic fever",
            "threedigit": "065",
            "children": [
              {
                "key": "0650",
                "title": "0650 Crimean hemorrhagic fev",
                "threedigit": "065",
                "isLeaf": true
              },
              {
                "key": "0651",
                "title": "0651 Omsk hemorrhagic fever",
                "threedigit": "065",
                "isLeaf": true
              },
              {
                "key": "0652",
                "title": "0652 Kyasanur forest disease",
                "threedigit": "065",
                "isLeaf": true
              },
              {
                "key": "0653",
                "title": "0653 Tick-borne hem fever NEC",
                "threedigit": "065",
                "isLeaf": true
              },
              {
                "key": "0654",
                "title": "0654 Mosquito-borne hem fever",
                "threedigit": "065",
                "isLeaf": true
              },
              {
                "key": "0658",
                "title": "0658 Arthropod hem fever NEC",
                "threedigit": "065",
                "isLeaf": true
              },
              {
                "key": "0659",
                "title": "0659 Arthropod hem fever NOS",
                "threedigit": "065",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other arthropod-borne viral diseases",
            "title": "066 - Other arthropod-borne viral diseases",
            "threedigit": "066",
            "children": [
              {
                "key": "0660",
                "title": "0660 Phlebotomus fever",
                "threedigit": "066",
                "isLeaf": true
              },
              {
                "key": "0661",
                "title": "0661 Tick-borne fever",
                "threedigit": "066",
                "isLeaf": true
              },
              {
                "key": "0662",
                "title": "0662 Venezuelan equine fever",
                "threedigit": "066",
                "isLeaf": true
              },
              {
                "key": "0663",
                "title": "0663 Mosquito-borne fever NEC",
                "threedigit": "066",
                "isLeaf": true
              },
              {
                "key": "06640",
                "title": "06640 West Nile Fever NOS",
                "threedigit": "066",
                "isLeaf": true
              },
              {
                "key": "06641",
                "title": "06641 West Nile Fever w/enceph",
                "threedigit": "066",
                "isLeaf": true
              },
              {
                "key": "06642",
                "title": "06642 West Nile neuro man NEC",
                "threedigit": "066",
                "isLeaf": true
              },
              {
                "key": "06649",
                "title": "06649 West Nile w complic NEC",
                "threedigit": "066",
                "isLeaf": true
              },
              {
                "key": "0668",
                "title": "0668 Arthropod virus NEC",
                "threedigit": "066",
                "isLeaf": true
              },
              {
                "key": "0669",
                "title": "0669 Arthropod virus NOS",
                "threedigit": "066",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Diseases Due To Viruses And Chlamydiae",
        "title": "070-079 Other Diseases Due To Viruses And Chlamydiae",
        "threedigit": "070",
        "children": [
          {
            "key": "Viral hepatitis",
            "title": "070 - Viral hepatitis",
            "threedigit": "070",
            "children": [
              {
                "key": "0700",
                "title": "0700 Hepatitis A with coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "0701",
                "title": "0701 Hepatitis A w/o coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07020",
                "title": "07020 Hpt B acte coma wo dlta",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07021",
                "title": "07021 Hpt B acte coma w dlta",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07022",
                "title": "07022 Hpt B chrn coma wo dlta",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07023",
                "title": "07023 Hpt B chrn coma w dlta",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07030",
                "title": "07030 Hpt B acte wo cm wo dlta",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07031",
                "title": "07031 Hpt B acte wo cm w dlta",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07032",
                "title": "07032 Hpt B chrn wo cm wo dlta",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07033",
                "title": "07033 Hpt B chrn wo cm w dlta",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07041",
                "title": "07041 Hpt C acute w hepat Coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07042",
                "title": "07042 Hpt dlt wo b w hpt coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07043",
                "title": "07043 Hpt E w hepat Coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07044",
                "title": "07044 Chrnc hpt C w hepat Coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07049",
                "title": "07049 Oth vrl hepat w hpt coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07051",
                "title": "07051 Hpt C acute wo hpat coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07052",
                "title": "07052 Hpt dlt wo b wo hpt coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07053",
                "title": "07053 Hpt E wo hepat Coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07054",
                "title": "07054 Chrnc hpt C wo hpat coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07059",
                "title": "07059 Oth vrl hpat wo hpt coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "0706",
                "title": "0706 Viral hepat NOS w coma",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07070",
                "title": "07070 Hpt C w/o hepat coma NOS",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "07071",
                "title": "07071 Hpt C w hepatic coma NOS",
                "threedigit": "070",
                "isLeaf": true
              },
              {
                "key": "0709",
                "title": "0709 Viral hepat NOS w/o coma",
                "threedigit": "070",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Rabies",
            "title": "071 - Rabies",
            "threedigit": "071",
            "children": [
              {
                "key": "071",
                "title": "071 Rabies",
                "threedigit": "071",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Mumps",
            "title": "072 - Mumps",
            "threedigit": "072",
            "children": [
              {
                "key": "0720",
                "title": "0720 Mumps orchitis",
                "threedigit": "072",
                "isLeaf": true
              },
              {
                "key": "0721",
                "title": "0721 Mumps meningitis",
                "threedigit": "072",
                "isLeaf": true
              },
              {
                "key": "0722",
                "title": "0722 Mumps encephalitis",
                "threedigit": "072",
                "isLeaf": true
              },
              {
                "key": "0723",
                "title": "0723 Mumps pancreatitis",
                "threedigit": "072",
                "isLeaf": true
              },
              {
                "key": "07271",
                "title": "07271 Mumps hepatitis",
                "threedigit": "072",
                "isLeaf": true
              },
              {
                "key": "07272",
                "title": "07272 Mumps polyneuropathy",
                "threedigit": "072",
                "isLeaf": true
              },
              {
                "key": "07279",
                "title": "07279 Mumps complication NEC",
                "threedigit": "072",
                "isLeaf": true
              },
              {
                "key": "0728",
                "title": "0728 Mumps complication NOS",
                "threedigit": "072",
                "isLeaf": true
              },
              {
                "key": "0729",
                "title": "0729 Mumps uncomplicated",
                "threedigit": "072",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ornithosis",
            "title": "073 - Ornithosis",
            "threedigit": "073",
            "children": [
              {
                "key": "0730",
                "title": "0730 Ornithosis pneumonia",
                "threedigit": "073",
                "isLeaf": true
              },
              {
                "key": "0737",
                "title": "0737 Ornithosis complicat NEC",
                "threedigit": "073",
                "isLeaf": true
              },
              {
                "key": "0738",
                "title": "0738 Ornithosis complicat NOS",
                "threedigit": "073",
                "isLeaf": true
              },
              {
                "key": "0739",
                "title": "0739 Ornithosis NOS",
                "threedigit": "073",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Specific diseases due to coxsackie virus",
            "title": "074 - Specific diseases due to coxsackie virus",
            "threedigit": "074",
            "children": [
              {
                "key": "0740",
                "title": "0740 Herpangina",
                "threedigit": "074",
                "isLeaf": true
              },
              {
                "key": "0741",
                "title": "0741 Epidemic pleurodynia",
                "threedigit": "074",
                "isLeaf": true
              },
              {
                "key": "07420",
                "title": "07420 Coxsackie carditis NOS",
                "threedigit": "074",
                "isLeaf": true
              },
              {
                "key": "07421",
                "title": "07421 Coxsackie pericarditis",
                "threedigit": "074",
                "isLeaf": true
              },
              {
                "key": "07422",
                "title": "07422 Coxsackie endocarditis",
                "threedigit": "074",
                "isLeaf": true
              },
              {
                "key": "07423",
                "title": "07423 Coxsackie myocarditis",
                "threedigit": "074",
                "isLeaf": true
              },
              {
                "key": "0743",
                "title": "0743 Hand, foot & mouth dis",
                "threedigit": "074",
                "isLeaf": true
              },
              {
                "key": "0748",
                "title": "0748 Coxsackie virus NEC",
                "threedigit": "074",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Infectious mononucleosis",
            "title": "075 - Infectious mononucleosis",
            "threedigit": "075",
            "children": [
              {
                "key": "075",
                "title": "075 Infectious mononucleosis",
                "threedigit": "075",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Trachoma",
            "title": "076 - Trachoma",
            "threedigit": "076",
            "children": [
              {
                "key": "0760",
                "title": "0760 Trachoma, initial stage",
                "threedigit": "076",
                "isLeaf": true
              },
              {
                "key": "0761",
                "title": "0761 Trachoma, active stage",
                "threedigit": "076",
                "isLeaf": true
              },
              {
                "key": "0769",
                "title": "0769 Trachoma NOS",
                "threedigit": "076",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of conjunctiva due to viruses and chlamydiae",
            "title": "077 - Other diseases of conjunctiva due to viruses and chlamydiae",
            "threedigit": "077",
            "children": [
              {
                "key": "0770",
                "title": "0770 Inclusion conjunctivitis",
                "threedigit": "077",
                "isLeaf": true
              },
              {
                "key": "0771",
                "title": "0771 Epidem keratoconjunctiv",
                "threedigit": "077",
                "isLeaf": true
              },
              {
                "key": "0772",
                "title": "0772 Pharyngoconjunct fever",
                "threedigit": "077",
                "isLeaf": true
              },
              {
                "key": "0773",
                "title": "0773 Adenoviral conjunct NEC",
                "threedigit": "077",
                "isLeaf": true
              },
              {
                "key": "0774",
                "title": "0774 Epidem hem conjunctivit",
                "threedigit": "077",
                "isLeaf": true
              },
              {
                "key": "0778",
                "title": "0778 Viral conjunctivitis NEC",
                "threedigit": "077",
                "isLeaf": true
              },
              {
                "key": "07798",
                "title": "07798 Unsp ds conjuc chlamydia",
                "threedigit": "077",
                "isLeaf": true
              },
              {
                "key": "07799",
                "title": "07799 Unsp ds conjuc viruses",
                "threedigit": "077",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases due to viruses and chlamydiae",
            "title": "078 - Other diseases due to viruses and chlamydiae",
            "threedigit": "078",
            "children": [
              {
                "key": "0780",
                "title": "0780 Molluscum contagiosum",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "07810",
                "title": "07810 Viral warts NOS",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "07811",
                "title": "07811 Condyloma acuminatum",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "07812",
                "title": "07812 Plantar wart",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "07819",
                "title": "07819 Oth specfd viral warts",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "0782",
                "title": "0782 Sweating fever",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "0783",
                "title": "0783 Cat-scratch disease",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "0784",
                "title": "0784 Foot & mouth disease",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "0785",
                "title": "0785 Cytomegaloviral disease",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "0786",
                "title": "0786 Hem nephrosonephritis",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "0787",
                "title": "0787 Arenaviral hem fever",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "07881",
                "title": "07881 Epidemic vertigo",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "07882",
                "title": "07882 Epidemic vomiting synd",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "07888",
                "title": "07888 Oth spec dis chlamydiae",
                "threedigit": "078",
                "isLeaf": true
              },
              {
                "key": "07889",
                "title": "07889 Oth spec dis viruses",
                "threedigit": "078",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Viral and chlamydial infection in conditions classified elsewhere and of unspecified site",
            "title": "079 - Viral and chlamydial infection in conditions classified elsewhere and of unspecified site",
            "threedigit": "079",
            "children": [
              {
                "key": "0790",
                "title": "0790 Adenovirus infect NOS",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "0791",
                "title": "0791 Echo virus infect NOS",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "0792",
                "title": "0792 Coxsackie virus inf NOS",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "0793",
                "title": "0793 Rhinovirus infect NOS",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "0794",
                "title": "0794 Human papillomavirus",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07950",
                "title": "07950 Retrovirus, unspecified",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07951",
                "title": "07951 Htlv-1 infection oth dis",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07952",
                "title": "07952 Htlv-ii infectn oth dis",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07953",
                "title": "07953 Hiv-2 infection oth dis",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07959",
                "title": "07959 Oth specfied retrovirus",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "0796",
                "title": "0796 Resprtry syncytial virus",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07981",
                "title": "07981 Hantavirus infection",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07982",
                "title": "07982 SARS assoc coronavirus",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07983",
                "title": "07983 Parvovirus B19",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07988",
                "title": "07988 Oth spcf chlamydial infc",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07989",
                "title": "07989 Oth specf viral infectn",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07998",
                "title": "07998 Chlamydial infection NOS",
                "threedigit": "079",
                "isLeaf": true
              },
              {
                "key": "07999",
                "title": "07999 Viral infection NOS",
                "threedigit": "079",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Rickettsioses And Other Arthropod-Borne Diseases",
        "title": "080-088 Rickettsioses And Other Arthropod-Borne Diseases",
        "threedigit": "080",
        "children": [
          {
            "key": "Louse-borne (epidemic) typhus",
            "title": "080 - Louse-borne (epidemic) typhus",
            "threedigit": "080",
            "children": [
              {
                "key": "080",
                "title": "080 Louse-borne typhus",
                "threedigit": "080",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other typhus",
            "title": "081 - Other typhus",
            "threedigit": "081",
            "children": [
              {
                "key": "0810",
                "title": "0810 Murine typhus",
                "threedigit": "081",
                "isLeaf": true
              },
              {
                "key": "0811",
                "title": "0811 Brill's disease",
                "threedigit": "081",
                "isLeaf": true
              },
              {
                "key": "0812",
                "title": "0812 Scrub typhus",
                "threedigit": "081",
                "isLeaf": true
              },
              {
                "key": "0819",
                "title": "0819 Typhus NOS",
                "threedigit": "081",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Tick-borne rickettsioses",
            "title": "082 - Tick-borne rickettsioses",
            "threedigit": "082",
            "children": [
              {
                "key": "0820",
                "title": "0820 Spotted fevers",
                "threedigit": "082",
                "isLeaf": true
              },
              {
                "key": "0821",
                "title": "0821 Boutonneuse fever",
                "threedigit": "082",
                "isLeaf": true
              },
              {
                "key": "0822",
                "title": "0822 North asian tick fever",
                "threedigit": "082",
                "isLeaf": true
              },
              {
                "key": "0823",
                "title": "0823 Queensland tick typhus",
                "threedigit": "082",
                "isLeaf": true
              },
              {
                "key": "08240",
                "title": "08240 Ehrlichiosis NOS",
                "threedigit": "082",
                "isLeaf": true
              },
              {
                "key": "08241",
                "title": "08241 Ehrlichiosis chafeensis",
                "threedigit": "082",
                "isLeaf": true
              },
              {
                "key": "08249",
                "title": "08249 Ehrlichiosis NEC",
                "threedigit": "082",
                "isLeaf": true
              },
              {
                "key": "0828",
                "title": "0828 Tick-borne ricketts NEC",
                "threedigit": "082",
                "isLeaf": true
              },
              {
                "key": "0829",
                "title": "0829 Tick-borne ricketts NOS",
                "threedigit": "082",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other rickettsioses",
            "title": "083 - Other rickettsioses",
            "threedigit": "083",
            "children": [
              {
                "key": "0830",
                "title": "0830 Q fever",
                "threedigit": "083",
                "isLeaf": true
              },
              {
                "key": "0831",
                "title": "0831 Trench fever",
                "threedigit": "083",
                "isLeaf": true
              },
              {
                "key": "0832",
                "title": "0832 Rickettsialpox",
                "threedigit": "083",
                "isLeaf": true
              },
              {
                "key": "0838",
                "title": "0838 Rickettsioses NEC",
                "threedigit": "083",
                "isLeaf": true
              },
              {
                "key": "0839",
                "title": "0839 Rickettsiosis NOS",
                "threedigit": "083",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malaria",
            "title": "084 - Malaria",
            "threedigit": "084",
            "children": [
              {
                "key": "0840",
                "title": "0840 Falciparum malaria",
                "threedigit": "084",
                "isLeaf": true
              },
              {
                "key": "0841",
                "title": "0841 Vivax malaria",
                "threedigit": "084",
                "isLeaf": true
              },
              {
                "key": "0842",
                "title": "0842 Quartan malaria",
                "threedigit": "084",
                "isLeaf": true
              },
              {
                "key": "0843",
                "title": "0843 Ovale malaria",
                "threedigit": "084",
                "isLeaf": true
              },
              {
                "key": "0844",
                "title": "0844 Malaria NEC",
                "threedigit": "084",
                "isLeaf": true
              },
              {
                "key": "0845",
                "title": "0845 Mixed malaria",
                "threedigit": "084",
                "isLeaf": true
              },
              {
                "key": "0846",
                "title": "0846 Malaria NOS",
                "threedigit": "084",
                "isLeaf": true
              },
              {
                "key": "0847",
                "title": "0847 Induced malaria",
                "threedigit": "084",
                "isLeaf": true
              },
              {
                "key": "0848",
                "title": "0848 Blackwater fever",
                "threedigit": "084",
                "isLeaf": true
              },
              {
                "key": "0849",
                "title": "0849 Malaria complicated NEC",
                "threedigit": "084",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Leishmaniasis",
            "title": "085 - Leishmaniasis",
            "threedigit": "085",
            "children": [
              {
                "key": "0850",
                "title": "0850 Visceral leishmaniasis",
                "threedigit": "085",
                "isLeaf": true
              },
              {
                "key": "0851",
                "title": "0851 Cutan leishmanias urban",
                "threedigit": "085",
                "isLeaf": true
              },
              {
                "key": "0852",
                "title": "0852 Cutan leishmanias asian",
                "threedigit": "085",
                "isLeaf": true
              },
              {
                "key": "0853",
                "title": "0853 Cutan leishmanias ethiop",
                "threedigit": "085",
                "isLeaf": true
              },
              {
                "key": "0854",
                "title": "0854 Cutan leishmanias amer",
                "threedigit": "085",
                "isLeaf": true
              },
              {
                "key": "0855",
                "title": "0855 Mucocutan leishmaniasis",
                "threedigit": "085",
                "isLeaf": true
              },
              {
                "key": "0859",
                "title": "0859 Leishmaniasis NOS",
                "threedigit": "085",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Trypanosomiasis",
            "title": "086 - Trypanosomiasis",
            "threedigit": "086",
            "children": [
              {
                "key": "0860",
                "title": "0860 Chagas disease of heart",
                "threedigit": "086",
                "isLeaf": true
              },
              {
                "key": "0861",
                "title": "0861 Chagas dis of oth organ",
                "threedigit": "086",
                "isLeaf": true
              },
              {
                "key": "0862",
                "title": "0862 Chagas disease NOS",
                "threedigit": "086",
                "isLeaf": true
              },
              {
                "key": "0863",
                "title": "0863 Gambian trypanosomiasis",
                "threedigit": "086",
                "isLeaf": true
              },
              {
                "key": "0864",
                "title": "0864 Rhodesian trypanosomias",
                "threedigit": "086",
                "isLeaf": true
              },
              {
                "key": "0865",
                "title": "0865 African trypanosoma NOS",
                "threedigit": "086",
                "isLeaf": true
              },
              {
                "key": "0869",
                "title": "0869 Trypanosomiasis NOS",
                "threedigit": "086",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Relapsing fever",
            "title": "087 - Relapsing fever",
            "threedigit": "087",
            "children": [
              {
                "key": "0870",
                "title": "0870 Louse-borne relaps fever",
                "threedigit": "087",
                "isLeaf": true
              },
              {
                "key": "0871",
                "title": "0871 Tick-borne relaps fever",
                "threedigit": "087",
                "isLeaf": true
              },
              {
                "key": "0879",
                "title": "0879 Relapsing fever NOS",
                "threedigit": "087",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other arthropod-borne diseases",
            "title": "088 - Other arthropod-borne diseases",
            "threedigit": "088",
            "children": [
              {
                "key": "0880",
                "title": "0880 Bartonellosis",
                "threedigit": "088",
                "isLeaf": true
              },
              {
                "key": "08881",
                "title": "08881 Lyme disease",
                "threedigit": "088",
                "isLeaf": true
              },
              {
                "key": "08882",
                "title": "08882 Babesiosis",
                "threedigit": "088",
                "isLeaf": true
              },
              {
                "key": "08889",
                "title": "08889 Oth arthropod-borne dis",
                "threedigit": "088",
                "isLeaf": true
              },
              {
                "key": "0889",
                "title": "0889 Arthropod-borne dis NOS",
                "threedigit": "088",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Syphilis And Other Venereal Diseases",
        "title": "090-099 Syphilis And Other Venereal Diseases",
        "threedigit": "090",
        "children": [
          {
            "key": "Congenital syphilis",
            "title": "090 - Congenital syphilis",
            "threedigit": "090",
            "children": [
              {
                "key": "0900",
                "title": "0900 Early cong syph symptom",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "0901",
                "title": "0901 Early congen syph latent",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "0902",
                "title": "0902 Early congen syph NOS",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "0903",
                "title": "0903 Syphilitic keratitis",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "09040",
                "title": "09040 Juvenile neurosyph NOS",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "09041",
                "title": "09041 Congen syph encephalitis",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "09042",
                "title": "09042 Congen syph meningitis",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "09049",
                "title": "09049 Juvenile neurosyph NEC",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "0905",
                "title": "0905 Late congen syph symptom",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "0906",
                "title": "0906 Late congen syph latent",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "0907",
                "title": "0907 Late congen syph NOS",
                "threedigit": "090",
                "isLeaf": true
              },
              {
                "key": "0909",
                "title": "0909 Congenital syphilis NOS",
                "threedigit": "090",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Early syphilis symptomatic",
            "title": "091 - Early syphilis symptomatic",
            "threedigit": "091",
            "children": [
              {
                "key": "0910",
                "title": "0910 Primary genital syphilis",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "0911",
                "title": "0911 Primary anal syphilis",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "0912",
                "title": "0912 Primary syphilis NEC",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "0913",
                "title": "0913 Secondary syph skin",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "0914",
                "title": "0914 Syphilitic adenopathy",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "09150",
                "title": "09150 Syphilitic uveitis NOS",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "09151",
                "title": "09151 Syphilit chorioretinitis",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "09152",
                "title": "09152 Syphilitic iridocyclitis",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "09161",
                "title": "09161 Syphilitic periostitis",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "09162",
                "title": "09162 Syphilitic hepatitis",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "09169",
                "title": "09169 Second syph viscera NEC",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "0917",
                "title": "0917 Second syphilis relapse",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "09181",
                "title": "09181 Acute syphil meningitis",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "09182",
                "title": "09182 Syphilitic alopecia",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "09189",
                "title": "09189 Secondary syphilis NEC",
                "threedigit": "091",
                "isLeaf": true
              },
              {
                "key": "0919",
                "title": "0919 Secondary syphilis NOS",
                "threedigit": "091",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Early syphilis latent",
            "title": "092 - Early syphilis latent",
            "threedigit": "092",
            "children": [
              {
                "key": "0920",
                "title": "0920 Early syph latent relaps",
                "threedigit": "092",
                "isLeaf": true
              },
              {
                "key": "0929",
                "title": "0929 Early syphil latent NOS",
                "threedigit": "092",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cardiovascular syphilis",
            "title": "093 - Cardiovascular syphilis",
            "threedigit": "093",
            "children": [
              {
                "key": "0930",
                "title": "0930 Aortic aneurysm, syphil",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "0931",
                "title": "0931 Syphilitic aortitis",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "09320",
                "title": "09320 Syphil endocarditis NOS",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "09321",
                "title": "09321 Syphilitic mitral valve",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "09322",
                "title": "09322 Syphilitic aortic valve",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "09323",
                "title": "09323 Syphil tricuspid valve",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "09324",
                "title": "09324 Syphil pulmonary valve",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "09381",
                "title": "09381 Syphilitic pericarditis",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "09382",
                "title": "09382 Syphilitic myocarditis",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "09389",
                "title": "09389 Cardiovascular syph NEC",
                "threedigit": "093",
                "isLeaf": true
              },
              {
                "key": "0939",
                "title": "0939 Cardiovascular syph NOS",
                "threedigit": "093",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Neurosyphilis",
            "title": "094 - Neurosyphilis",
            "threedigit": "094",
            "children": [
              {
                "key": "0940",
                "title": "0940 Tabes dorsalis",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "0941",
                "title": "0941 General paresis",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "0942",
                "title": "0942 Syphilitic meningitis",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "0943",
                "title": "0943 Asymptomat neurosyphilis",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "09481",
                "title": "09481 Syphilitic encephalitis",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "09482",
                "title": "09482 Syphilitic parkinsonism",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "09483",
                "title": "09483 Syph dissem retinitis",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "09484",
                "title": "09484 Syphilitic optic atrophy",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "09485",
                "title": "09485 Syph retrobulb neuritis",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "09486",
                "title": "09486 Syphil acoustic neuritis",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "09487",
                "title": "09487 Syph rupt cereb aneurysm",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "09489",
                "title": "09489 Neurosyphilis NEC",
                "threedigit": "094",
                "isLeaf": true
              },
              {
                "key": "0949",
                "title": "0949 Neurosyphilis NOS",
                "threedigit": "094",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other forms of late syphilis with symptoms",
            "title": "095 - Other forms of late syphilis with symptoms",
            "threedigit": "095",
            "children": [
              {
                "key": "0950",
                "title": "0950 Syphilitic episcleritis",
                "threedigit": "095",
                "isLeaf": true
              },
              {
                "key": "0951",
                "title": "0951 Syphilis of lung",
                "threedigit": "095",
                "isLeaf": true
              },
              {
                "key": "0952",
                "title": "0952 Syphilitic peritonitis",
                "threedigit": "095",
                "isLeaf": true
              },
              {
                "key": "0953",
                "title": "0953 Syphilis of liver",
                "threedigit": "095",
                "isLeaf": true
              },
              {
                "key": "0954",
                "title": "0954 Syphilis of kidney",
                "threedigit": "095",
                "isLeaf": true
              },
              {
                "key": "0955",
                "title": "0955 Syphilis of bone",
                "threedigit": "095",
                "isLeaf": true
              },
              {
                "key": "0956",
                "title": "0956 Syphilis of muscle",
                "threedigit": "095",
                "isLeaf": true
              },
              {
                "key": "0957",
                "title": "0957 Syphilis of tendon/bursa",
                "threedigit": "095",
                "isLeaf": true
              },
              {
                "key": "0958",
                "title": "0958 Late sympt syphilis NEC",
                "threedigit": "095",
                "isLeaf": true
              },
              {
                "key": "0959",
                "title": "0959 Late sympt syphilis NOS",
                "threedigit": "095",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late syphilis, latent",
            "title": "096 - Late syphilis, latent",
            "threedigit": "096",
            "children": [
              {
                "key": "096",
                "title": "096 Late syphilis latent",
                "threedigit": "096",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified syphilis",
            "title": "097 - Other and unspecified syphilis",
            "threedigit": "097",
            "children": [
              {
                "key": "0970",
                "title": "0970 Late syphilis NOS",
                "threedigit": "097",
                "isLeaf": true
              },
              {
                "key": "0971",
                "title": "0971 Latent syphilis NOS",
                "threedigit": "097",
                "isLeaf": true
              },
              {
                "key": "0979",
                "title": "0979 Syphilis NOS",
                "threedigit": "097",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Gonococcal infections",
            "title": "098 - Gonococcal infections",
            "threedigit": "098",
            "children": [
              {
                "key": "0980",
                "title": "0980 Acute gc infect lower gu",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09810",
                "title": "09810 Gc (acute) upper gu NOS",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09811",
                "title": "09811 Gc cystitis (acute)",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09812",
                "title": "09812 Gc prostatitis (acute)",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09813",
                "title": "09813 Gc orchitis (acute)",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09814",
                "title": "09814 Gc sem vesiculit (acute)",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09815",
                "title": "09815 Gc cervicitis (acute)",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09816",
                "title": "09816 Gc endometritis (acute)",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09817",
                "title": "09817 Acute gc salpingitis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09819",
                "title": "09819 Gc (acute) upper gu NEC",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "0982",
                "title": "0982 Chr gc infect lower gu",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09830",
                "title": "09830 Chr gc upper gu NOS",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09831",
                "title": "09831 Gc cystitis, chronic",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09832",
                "title": "09832 Gc prostatitis, chronic",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09833",
                "title": "09833 Gc orchitis, chronic",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09834",
                "title": "09834 Gc sem vesiculitis, chr",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09835",
                "title": "09835 Gc cervicitis, chronic",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09836",
                "title": "09836 Gc endometritis, chronic",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09837",
                "title": "09837 Gc salpingitis (chronic)",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09839",
                "title": "09839 Chr gc upper gu NEC",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09840",
                "title": "09840 Gonococcal conjunctivit",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09841",
                "title": "09841 Gonococcal iridocyclitis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09842",
                "title": "09842 Gonococcal endophthalmia",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09843",
                "title": "09843 Gonococcal keratitis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09849",
                "title": "09849 Gonococcal eye NEC",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09850",
                "title": "09850 Gonococcal arthritis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09851",
                "title": "09851 Gonococcal synovitis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09852",
                "title": "09852 Gonococcal bursitis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09853",
                "title": "09853 Gonococcal spondylitis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09859",
                "title": "09859 Gc infect joint NEC",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "0986",
                "title": "0986 Gonococcal infec pharynx",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "0987",
                "title": "0987 Gc infect anus & rectum",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09881",
                "title": "09881 Gonococcal keratosis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09882",
                "title": "09882 Gonococcal meningitis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09883",
                "title": "09883 Gonococcal pericarditis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09884",
                "title": "09884 Gonococcal endocarditis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09885",
                "title": "09885 Gonococcal heart dis NEC",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09886",
                "title": "09886 Gonococcal peritonitis",
                "threedigit": "098",
                "isLeaf": true
              },
              {
                "key": "09889",
                "title": "09889 Gonococcal inf site NEC",
                "threedigit": "098",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other venereal diseases",
            "title": "099 - Other venereal diseases",
            "threedigit": "099",
            "children": [
              {
                "key": "0990",
                "title": "0990 Chancroid",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "0991",
                "title": "0991 Lymphogranuloma venereum",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "0992",
                "title": "0992 Granuloma inguinale",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "0993",
                "title": "0993 Reiter's disease",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09940",
                "title": "09940 Unspcf nongnccl urethrts",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09941",
                "title": "09941 Chlmyd trachomatis ureth",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09949",
                "title": "09949 Nongc urth oth spf orgsm",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09950",
                "title": "09950 Oth VD chlm trch unsp st",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09951",
                "title": "09951 Oth VD chlm trch pharynx",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09952",
                "title": "09952 Oth VD chlm trch ans rct",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09953",
                "title": "09953 Oth VD chlm trch lowr gu",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09954",
                "title": "09954 Oth VD chlm trch oth gu",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09955",
                "title": "09955 Ot VD chlm trch unspf gu",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09956",
                "title": "09956 Ot VD chlm trch prtoneum",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "09959",
                "title": "09959 Oth VD chlm trch spcf st",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "0998",
                "title": "0998 Venereal disease NEC",
                "threedigit": "099",
                "isLeaf": true
              },
              {
                "key": "0999",
                "title": "0999 Venereal disease NOS",
                "threedigit": "099",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Spirochetal Diseases",
        "title": "100-104 Other Spirochetal Diseases",
        "threedigit": "100",
        "children": [
          {
            "key": "Leptospirosis",
            "title": "100 - Leptospirosis",
            "threedigit": "100",
            "children": [
              {
                "key": "1000",
                "title": "1000 Leptospiros icterohem",
                "threedigit": "100",
                "isLeaf": true
              },
              {
                "key": "10081",
                "title": "10081 Leptospiral meningitis",
                "threedigit": "100",
                "isLeaf": true
              },
              {
                "key": "10089",
                "title": "10089 Leptospiral infect NEC",
                "threedigit": "100",
                "isLeaf": true
              },
              {
                "key": "1009",
                "title": "1009 Leptospirosis NOS",
                "threedigit": "100",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Vincent's angina",
            "title": "101 - Vincent's angina",
            "threedigit": "101",
            "children": [
              {
                "key": "101",
                "title": "101 Vincent's angina",
                "threedigit": "101",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Yaws",
            "title": "102 - Yaws",
            "threedigit": "102",
            "children": [
              {
                "key": "1020",
                "title": "1020 Initial lesions yaws",
                "threedigit": "102",
                "isLeaf": true
              },
              {
                "key": "1021",
                "title": "1021 Multiple papillomata",
                "threedigit": "102",
                "isLeaf": true
              },
              {
                "key": "1022",
                "title": "1022 Early skin yaws NEC",
                "threedigit": "102",
                "isLeaf": true
              },
              {
                "key": "1023",
                "title": "1023 Hyperkeratosis of yaws",
                "threedigit": "102",
                "isLeaf": true
              },
              {
                "key": "1024",
                "title": "1024 Gummata and ulcers, yaws",
                "threedigit": "102",
                "isLeaf": true
              },
              {
                "key": "1025",
                "title": "1025 Gangosa",
                "threedigit": "102",
                "isLeaf": true
              },
              {
                "key": "1026",
                "title": "1026 Yaws of bone & joint",
                "threedigit": "102",
                "isLeaf": true
              },
              {
                "key": "1027",
                "title": "1027 Yaws manifestations NEC",
                "threedigit": "102",
                "isLeaf": true
              },
              {
                "key": "1028",
                "title": "1028 Latent yaws",
                "threedigit": "102",
                "isLeaf": true
              },
              {
                "key": "1029",
                "title": "1029 Yaws NOS",
                "threedigit": "102",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pinta",
            "title": "103 - Pinta",
            "threedigit": "103",
            "children": [
              {
                "key": "1030",
                "title": "1030 Pinta primary lesions",
                "threedigit": "103",
                "isLeaf": true
              },
              {
                "key": "1031",
                "title": "1031 Pinta intermed lesions",
                "threedigit": "103",
                "isLeaf": true
              },
              {
                "key": "1032",
                "title": "1032 Pinta late lesions",
                "threedigit": "103",
                "isLeaf": true
              },
              {
                "key": "1033",
                "title": "1033 Pinta mixed lesions",
                "threedigit": "103",
                "isLeaf": true
              },
              {
                "key": "1039",
                "title": "1039 Pinta NOS",
                "threedigit": "103",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other spirochetal infection",
            "title": "104 - Other spirochetal infection",
            "threedigit": "104",
            "children": [
              {
                "key": "1040",
                "title": "1040 Nonvenereal endemic syph",
                "threedigit": "104",
                "isLeaf": true
              },
              {
                "key": "1048",
                "title": "1048 Spirochetal infect NEC",
                "threedigit": "104",
                "isLeaf": true
              },
              {
                "key": "1049",
                "title": "1049 Spirochetal infect NOS",
                "threedigit": "104",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Mycoses",
        "title": "110-118 Mycoses",
        "threedigit": "110",
        "children": [
          {
            "key": "Dermatophytosis",
            "title": "110 - Dermatophytosis",
            "threedigit": "110",
            "children": [
              {
                "key": "1100",
                "title": "1100 Dermatophyt scalp/beard",
                "threedigit": "110",
                "isLeaf": true
              },
              {
                "key": "1101",
                "title": "1101 Dermatophytosis of nail",
                "threedigit": "110",
                "isLeaf": true
              },
              {
                "key": "1102",
                "title": "1102 Dermatophytosis of hand",
                "threedigit": "110",
                "isLeaf": true
              },
              {
                "key": "1103",
                "title": "1103 Dermatophytosis of groin",
                "threedigit": "110",
                "isLeaf": true
              },
              {
                "key": "1104",
                "title": "1104 Dermatophytosis of foot",
                "threedigit": "110",
                "isLeaf": true
              },
              {
                "key": "1105",
                "title": "1105 Dermatophytosis of body",
                "threedigit": "110",
                "isLeaf": true
              },
              {
                "key": "1106",
                "title": "1106 Deep dermatophytosis",
                "threedigit": "110",
                "isLeaf": true
              },
              {
                "key": "1108",
                "title": "1108 Dermatophytosis site NEC",
                "threedigit": "110",
                "isLeaf": true
              },
              {
                "key": "1109",
                "title": "1109 Dermatophytosis site NOS",
                "threedigit": "110",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dermatomycosis other and unspecified",
            "title": "111 - Dermatomycosis other and unspecified",
            "threedigit": "111",
            "children": [
              {
                "key": "1110",
                "title": "1110 Pityriasis versicolor",
                "threedigit": "111",
                "isLeaf": true
              },
              {
                "key": "1111",
                "title": "1111 Tinea nigra",
                "threedigit": "111",
                "isLeaf": true
              },
              {
                "key": "1112",
                "title": "1112 Tinea blanca",
                "threedigit": "111",
                "isLeaf": true
              },
              {
                "key": "1113",
                "title": "1113 Black piedra",
                "threedigit": "111",
                "isLeaf": true
              },
              {
                "key": "1118",
                "title": "1118 Dermatomycoses NEC",
                "threedigit": "111",
                "isLeaf": true
              },
              {
                "key": "1119",
                "title": "1119 Dermatomycosis NOS",
                "threedigit": "111",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Candidiasis",
            "title": "112 - Candidiasis",
            "threedigit": "112",
            "children": [
              {
                "key": "1120",
                "title": "1120 Thrush",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "1121",
                "title": "1121 Candidal vulvovaginitis",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "1122",
                "title": "1122 Candidias urogenital NEC",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "1123",
                "title": "1123 Cutaneous candidiasis",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "1124",
                "title": "1124 Candidiasis of lung",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "1125",
                "title": "1125 Disseminated candidiasis",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "11281",
                "title": "11281 Candidal endocarditis",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "11282",
                "title": "11282 Candidal otitis externa",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "11283",
                "title": "11283 Candidal meningitis",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "11284",
                "title": "11284 Candidal esophagitis",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "11285",
                "title": "11285 Candidal enteritis",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "11289",
                "title": "11289 Candidiasis site NEC",
                "threedigit": "112",
                "isLeaf": true
              },
              {
                "key": "1129",
                "title": "1129 Candidiasis site NOS",
                "threedigit": "112",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Coccidioidomycosis",
            "title": "114 - Coccidioidomycosis",
            "threedigit": "114",
            "children": [
              {
                "key": "1140",
                "title": "1140 Primary coccidioidomycos",
                "threedigit": "114",
                "isLeaf": true
              },
              {
                "key": "1141",
                "title": "1141 Prim cutan coccidioid",
                "threedigit": "114",
                "isLeaf": true
              },
              {
                "key": "1142",
                "title": "1142 Coccidioidal meningitis",
                "threedigit": "114",
                "isLeaf": true
              },
              {
                "key": "1143",
                "title": "1143 Progress coccidioid NEC",
                "threedigit": "114",
                "isLeaf": true
              },
              {
                "key": "1144",
                "title": "1144 Ch pl coccidioidomycosis",
                "threedigit": "114",
                "isLeaf": true
              },
              {
                "key": "1145",
                "title": "1145 Pl cocidioidomycosis NOS",
                "threedigit": "114",
                "isLeaf": true
              },
              {
                "key": "1149",
                "title": "1149 Coccidioidomycosis NOS",
                "threedigit": "114",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Histoplasmosis",
            "title": "115 - Histoplasmosis",
            "threedigit": "115",
            "children": [
              {
                "key": "11500",
                "title": "11500 Histoplasma capsulat NOS",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11501",
                "title": "11501 Histoplasm capsul mening",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11502",
                "title": "11502 Histoplasm capsul retina",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11503",
                "title": "11503 Histoplasm caps pericard",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11504",
                "title": "11504 Histoplasm caps endocard",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11505",
                "title": "11505 Histoplasm caps pneumon",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11509",
                "title": "11509 Histoplasma capsulat NEC",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11510",
                "title": "11510 Histoplasma duboisii NOS",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11511",
                "title": "11511 Histoplasm dubois mening",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11512",
                "title": "11512 Histoplasm dubois retina",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11513",
                "title": "11513 Histoplasm dub pericard",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11514",
                "title": "11514 Histoplasm dub endocard",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11515",
                "title": "11515 Histoplasm dub pneumonia",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11519",
                "title": "11519 Histoplasma duboisii NEC",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11590",
                "title": "11590 Histoplasmosis NOS",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11591",
                "title": "11591 Histoplasmosis meningit",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11592",
                "title": "11592 Histoplasmosis retinitis",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11593",
                "title": "11593 Histoplasmosis pericard",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11594",
                "title": "11594 Histoplasmosis endocard",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11595",
                "title": "11595 Histoplasmosis pneumonia",
                "threedigit": "115",
                "isLeaf": true
              },
              {
                "key": "11599",
                "title": "11599 Histoplasmosis NEC",
                "threedigit": "115",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Blastomycotic infection",
            "title": "116 - Blastomycotic infection",
            "threedigit": "116",
            "children": [
              {
                "key": "1160",
                "title": "1160 Blastomycosis",
                "threedigit": "116",
                "isLeaf": true
              },
              {
                "key": "1161",
                "title": "1161 Paracoccidioidomycosis",
                "threedigit": "116",
                "isLeaf": true
              },
              {
                "key": "1162",
                "title": "1162 Lobomycosis",
                "threedigit": "116",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other mycoses",
            "title": "117 - Other mycoses",
            "threedigit": "117",
            "children": [
              {
                "key": "1170",
                "title": "1170 Rhinosporidiosis",
                "threedigit": "117",
                "isLeaf": true
              },
              {
                "key": "1171",
                "title": "1171 Sporotrichosis",
                "threedigit": "117",
                "isLeaf": true
              },
              {
                "key": "1172",
                "title": "1172 Chromoblastomycosis",
                "threedigit": "117",
                "isLeaf": true
              },
              {
                "key": "1173",
                "title": "1173 Aspergillosis",
                "threedigit": "117",
                "isLeaf": true
              },
              {
                "key": "1174",
                "title": "1174 Mycotic mycetomas",
                "threedigit": "117",
                "isLeaf": true
              },
              {
                "key": "1175",
                "title": "1175 Cryptococcosis",
                "threedigit": "117",
                "isLeaf": true
              },
              {
                "key": "1176",
                "title": "1176 Allescheriosis",
                "threedigit": "117",
                "isLeaf": true
              },
              {
                "key": "1177",
                "title": "1177 Zygomycosis",
                "threedigit": "117",
                "isLeaf": true
              },
              {
                "key": "1178",
                "title": "1178 Dematiacious fungi inf",
                "threedigit": "117",
                "isLeaf": true
              },
              {
                "key": "1179",
                "title": "1179 Mycoses NEC & NOS",
                "threedigit": "117",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Opportunistic mycoses",
            "title": "118 - Opportunistic mycoses",
            "threedigit": "118",
            "children": [
              {
                "key": "118",
                "title": "118 Opportunistic mycoses",
                "threedigit": "118",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Helminthiases",
        "title": "120-129 Helminthiases",
        "threedigit": "120",
        "children": [
          {
            "key": "Schistosomiasis (bilharziasis)",
            "title": "120 - Schistosomiasis (bilharziasis)",
            "threedigit": "120",
            "children": [
              {
                "key": "1200",
                "title": "1200 Schistosoma haematobium",
                "threedigit": "120",
                "isLeaf": true
              },
              {
                "key": "1201",
                "title": "1201 Schistosoma mansoni",
                "threedigit": "120",
                "isLeaf": true
              },
              {
                "key": "1202",
                "title": "1202 Schistosoma japonicum",
                "threedigit": "120",
                "isLeaf": true
              },
              {
                "key": "1203",
                "title": "1203 Cutaneous schistosoma",
                "threedigit": "120",
                "isLeaf": true
              },
              {
                "key": "1208",
                "title": "1208 Schistosomiasis NEC",
                "threedigit": "120",
                "isLeaf": true
              },
              {
                "key": "1209",
                "title": "1209 Schistosomiasis NOS",
                "threedigit": "120",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other trematode infections",
            "title": "121 - Other trematode infections",
            "threedigit": "121",
            "children": [
              {
                "key": "1210",
                "title": "1210 Opisthorchiasis",
                "threedigit": "121",
                "isLeaf": true
              },
              {
                "key": "1211",
                "title": "1211 Clonorchiasis",
                "threedigit": "121",
                "isLeaf": true
              },
              {
                "key": "1212",
                "title": "1212 Paragonimiasis",
                "threedigit": "121",
                "isLeaf": true
              },
              {
                "key": "1213",
                "title": "1213 Fascioliasis",
                "threedigit": "121",
                "isLeaf": true
              },
              {
                "key": "1214",
                "title": "1214 Fasciolopsiasis",
                "threedigit": "121",
                "isLeaf": true
              },
              {
                "key": "1215",
                "title": "1215 Metagonimiasis",
                "threedigit": "121",
                "isLeaf": true
              },
              {
                "key": "1216",
                "title": "1216 Heterophyiasis",
                "threedigit": "121",
                "isLeaf": true
              },
              {
                "key": "1218",
                "title": "1218 Trematode infection NEC",
                "threedigit": "121",
                "isLeaf": true
              },
              {
                "key": "1219",
                "title": "1219 Trematode infection NOS",
                "threedigit": "121",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Echinococcosis",
            "title": "122 - Echinococcosis",
            "threedigit": "122",
            "children": [
              {
                "key": "1220",
                "title": "1220 Echinococc granul liver",
                "threedigit": "122",
                "isLeaf": true
              },
              {
                "key": "1221",
                "title": "1221 Echinococc granul lung",
                "threedigit": "122",
                "isLeaf": true
              },
              {
                "key": "1222",
                "title": "1222 Echinococc gran thyroid",
                "threedigit": "122",
                "isLeaf": true
              },
              {
                "key": "1223",
                "title": "1223 Echinococc granul NEC",
                "threedigit": "122",
                "isLeaf": true
              },
              {
                "key": "1224",
                "title": "1224 Echinococc granul NOS",
                "threedigit": "122",
                "isLeaf": true
              },
              {
                "key": "1225",
                "title": "1225 Echinococ multiloc liver",
                "threedigit": "122",
                "isLeaf": true
              },
              {
                "key": "1226",
                "title": "1226 Echinococc multiloc NEC",
                "threedigit": "122",
                "isLeaf": true
              },
              {
                "key": "1227",
                "title": "1227 Echinococc multiloc NOS",
                "threedigit": "122",
                "isLeaf": true
              },
              {
                "key": "1228",
                "title": "1228 Echinococcosis NOS liver",
                "threedigit": "122",
                "isLeaf": true
              },
              {
                "key": "1229",
                "title": "1229 Echinococcosis NEC/NOS",
                "threedigit": "122",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other cestode infection",
            "title": "123 - Other cestode infection",
            "threedigit": "123",
            "children": [
              {
                "key": "1230",
                "title": "1230 Taenia solium intestine",
                "threedigit": "123",
                "isLeaf": true
              },
              {
                "key": "1231",
                "title": "1231 Cysticercosis",
                "threedigit": "123",
                "isLeaf": true
              },
              {
                "key": "1232",
                "title": "1232 Taenia saginata infect",
                "threedigit": "123",
                "isLeaf": true
              },
              {
                "key": "1233",
                "title": "1233 Taeniasis NOS",
                "threedigit": "123",
                "isLeaf": true
              },
              {
                "key": "1234",
                "title": "1234 Diphyllobothrias intest",
                "threedigit": "123",
                "isLeaf": true
              },
              {
                "key": "1235",
                "title": "1235 Sparganosis",
                "threedigit": "123",
                "isLeaf": true
              },
              {
                "key": "1236",
                "title": "1236 Hymenolepiasis",
                "threedigit": "123",
                "isLeaf": true
              },
              {
                "key": "1238",
                "title": "1238 Cestode infection NEC",
                "threedigit": "123",
                "isLeaf": true
              },
              {
                "key": "1239",
                "title": "1239 Cestode infection NOS",
                "threedigit": "123",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Trichinosis",
            "title": "124 - Trichinosis",
            "threedigit": "124",
            "children": [
              {
                "key": "124",
                "title": "124 Trichinosis",
                "threedigit": "124",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Filarial infection and dracontiasis",
            "title": "125 - Filarial infection and dracontiasis",
            "threedigit": "125",
            "children": [
              {
                "key": "1250",
                "title": "1250 Bancroftian filariasis",
                "threedigit": "125",
                "isLeaf": true
              },
              {
                "key": "1251",
                "title": "1251 Malayan filariasis",
                "threedigit": "125",
                "isLeaf": true
              },
              {
                "key": "1252",
                "title": "1252 Loiasis",
                "threedigit": "125",
                "isLeaf": true
              },
              {
                "key": "1253",
                "title": "1253 Onchocerciasis",
                "threedigit": "125",
                "isLeaf": true
              },
              {
                "key": "1254",
                "title": "1254 Dipetalonemiasis",
                "threedigit": "125",
                "isLeaf": true
              },
              {
                "key": "1255",
                "title": "1255 Mansonella ozzardi infec",
                "threedigit": "125",
                "isLeaf": true
              },
              {
                "key": "1256",
                "title": "1256 Filariasis NEC",
                "threedigit": "125",
                "isLeaf": true
              },
              {
                "key": "1257",
                "title": "1257 Dracontiasis",
                "threedigit": "125",
                "isLeaf": true
              },
              {
                "key": "1259",
                "title": "1259 Filariasis NOS",
                "threedigit": "125",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ancylostomiasis and necatoriasis",
            "title": "126 - Ancylostomiasis and necatoriasis",
            "threedigit": "126",
            "children": [
              {
                "key": "1260",
                "title": "1260 Ancylostoma duodenale",
                "threedigit": "126",
                "isLeaf": true
              },
              {
                "key": "1261",
                "title": "1261 Necator Americanus",
                "threedigit": "126",
                "isLeaf": true
              },
              {
                "key": "1262",
                "title": "1262 Ancylostoma braziliense",
                "threedigit": "126",
                "isLeaf": true
              },
              {
                "key": "1263",
                "title": "1263 Ancylostoma ceylanicum",
                "threedigit": "126",
                "isLeaf": true
              },
              {
                "key": "1268",
                "title": "1268 Ancylostoma NEC",
                "threedigit": "126",
                "isLeaf": true
              },
              {
                "key": "1269",
                "title": "1269 Ancylostomiasis NOS",
                "threedigit": "126",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other intestinal helminthiases",
            "title": "127 - Other intestinal helminthiases",
            "threedigit": "127",
            "children": [
              {
                "key": "1270",
                "title": "1270 Ascariasis",
                "threedigit": "127",
                "isLeaf": true
              },
              {
                "key": "1271",
                "title": "1271 Anisakiasis",
                "threedigit": "127",
                "isLeaf": true
              },
              {
                "key": "1272",
                "title": "1272 Strongyloidiasis",
                "threedigit": "127",
                "isLeaf": true
              },
              {
                "key": "1273",
                "title": "1273 Trichuriasis",
                "threedigit": "127",
                "isLeaf": true
              },
              {
                "key": "1274",
                "title": "1274 Enterobiasis",
                "threedigit": "127",
                "isLeaf": true
              },
              {
                "key": "1275",
                "title": "1275 Capillariasis",
                "threedigit": "127",
                "isLeaf": true
              },
              {
                "key": "1276",
                "title": "1276 Trichostrongyliasis",
                "threedigit": "127",
                "isLeaf": true
              },
              {
                "key": "1277",
                "title": "1277 Intest helminthiasis NEC",
                "threedigit": "127",
                "isLeaf": true
              },
              {
                "key": "1278",
                "title": "1278 Mixed intestine helminth",
                "threedigit": "127",
                "isLeaf": true
              },
              {
                "key": "1279",
                "title": "1279 Intest helminthiasis NOS",
                "threedigit": "127",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified helminthiases",
            "title": "128 - Other and unspecified helminthiases",
            "threedigit": "128",
            "children": [
              {
                "key": "1280",
                "title": "1280 Toxocariasis",
                "threedigit": "128",
                "isLeaf": true
              },
              {
                "key": "1281",
                "title": "1281 Gnathostomiasis",
                "threedigit": "128",
                "isLeaf": true
              },
              {
                "key": "1288",
                "title": "1288 Helminthiasis NEC",
                "threedigit": "128",
                "isLeaf": true
              },
              {
                "key": "1289",
                "title": "1289 Helminthiasis NOS",
                "threedigit": "128",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Intestinal parasitism, unspecified",
            "title": "129 - Intestinal parasitism, unspecified",
            "threedigit": "129",
            "children": [
              {
                "key": "129",
                "title": "129 Intestin parasitism NOS",
                "threedigit": "129",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Infectious And Parasitic Diseases",
        "title": "130-136 Other Infectious And Parasitic Diseases",
        "threedigit": "130",
        "children": [
          {
            "key": "Toxoplasmosis",
            "title": "130 - Toxoplasmosis",
            "threedigit": "130",
            "children": [
              {
                "key": "1300",
                "title": "1300 Toxoplasm meningoenceph",
                "threedigit": "130",
                "isLeaf": true
              },
              {
                "key": "1301",
                "title": "1301 Toxoplasm conjunctivitis",
                "threedigit": "130",
                "isLeaf": true
              },
              {
                "key": "1302",
                "title": "1302 Toxoplasm chorioretinit",
                "threedigit": "130",
                "isLeaf": true
              },
              {
                "key": "1303",
                "title": "1303 Toxoplasma myocarditis",
                "threedigit": "130",
                "isLeaf": true
              },
              {
                "key": "1304",
                "title": "1304 Toxoplasma pneumonitis",
                "threedigit": "130",
                "isLeaf": true
              },
              {
                "key": "1305",
                "title": "1305 Toxoplasma hepatitis",
                "threedigit": "130",
                "isLeaf": true
              },
              {
                "key": "1307",
                "title": "1307 Toxoplasmosis site NEC",
                "threedigit": "130",
                "isLeaf": true
              },
              {
                "key": "1308",
                "title": "1308 Multisystem toxoplasmos",
                "threedigit": "130",
                "isLeaf": true
              },
              {
                "key": "1309",
                "title": "1309 Toxoplasmosis NOS",
                "threedigit": "130",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Trichomoniasis",
            "title": "131 - Trichomoniasis",
            "threedigit": "131",
            "children": [
              {
                "key": "13100",
                "title": "13100 Urogenital trichomon NOS",
                "threedigit": "131",
                "isLeaf": true
              },
              {
                "key": "13101",
                "title": "13101 Trichomonal vaginitis",
                "threedigit": "131",
                "isLeaf": true
              },
              {
                "key": "13102",
                "title": "13102 Trichomonal urethritis",
                "threedigit": "131",
                "isLeaf": true
              },
              {
                "key": "13103",
                "title": "13103 Trichomonal prostatitis",
                "threedigit": "131",
                "isLeaf": true
              },
              {
                "key": "13109",
                "title": "13109 Urogenital trichomon NEC",
                "threedigit": "131",
                "isLeaf": true
              },
              {
                "key": "1318",
                "title": "1318 Trichomoniasis NEC",
                "threedigit": "131",
                "isLeaf": true
              },
              {
                "key": "1319",
                "title": "1319 Trichomoniasis NOS",
                "threedigit": "131",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pediculosis and phthirus infestation",
            "title": "132 - Pediculosis and phthirus infestation",
            "threedigit": "132",
            "children": [
              {
                "key": "1320",
                "title": "1320 Pediculus capitis",
                "threedigit": "132",
                "isLeaf": true
              },
              {
                "key": "1321",
                "title": "1321 Pediculus corporis",
                "threedigit": "132",
                "isLeaf": true
              },
              {
                "key": "1322",
                "title": "1322 Phthirus pubis",
                "threedigit": "132",
                "isLeaf": true
              },
              {
                "key": "1323",
                "title": "1323 Mixed pedicul & phthirus",
                "threedigit": "132",
                "isLeaf": true
              },
              {
                "key": "1329",
                "title": "1329 Pediculosis NOS",
                "threedigit": "132",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acariasis",
            "title": "133 - Acariasis",
            "threedigit": "133",
            "children": [
              {
                "key": "1330",
                "title": "1330 Scabies",
                "threedigit": "133",
                "isLeaf": true
              },
              {
                "key": "1338",
                "title": "1338 Acariasis NEC",
                "threedigit": "133",
                "isLeaf": true
              },
              {
                "key": "1339",
                "title": "1339 Acariasis NOS",
                "threedigit": "133",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other infestation",
            "title": "134 - Other infestation",
            "threedigit": "134",
            "children": [
              {
                "key": "1340",
                "title": "1340 Myiasis",
                "threedigit": "134",
                "isLeaf": true
              },
              {
                "key": "1341",
                "title": "1341 Arthropod infest NEC",
                "threedigit": "134",
                "isLeaf": true
              },
              {
                "key": "1342",
                "title": "1342 Hirudiniasis",
                "threedigit": "134",
                "isLeaf": true
              },
              {
                "key": "1348",
                "title": "1348 Infestation NEC",
                "threedigit": "134",
                "isLeaf": true
              },
              {
                "key": "1349",
                "title": "1349 Infestation NOS",
                "threedigit": "134",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sarcoidosis",
            "title": "135 - Sarcoidosis",
            "threedigit": "135",
            "children": [
              {
                "key": "135",
                "title": "135 Sarcoidosis",
                "threedigit": "135",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified infectious and parasitic diseases",
            "title": "136 - Other and unspecified infectious and parasitic diseases",
            "threedigit": "136",
            "children": [
              {
                "key": "1360",
                "title": "1360 Ainhum",
                "threedigit": "136",
                "isLeaf": true
              },
              {
                "key": "1361",
                "title": "1361 Behcet's syndrome",
                "threedigit": "136",
                "isLeaf": true
              },
              {
                "key": "13621",
                "title": "13621 Infectn d/t acanthamoeba",
                "threedigit": "136",
                "isLeaf": true
              },
              {
                "key": "13629",
                "title": "13629 Infc free-liv amebae NEC",
                "threedigit": "136",
                "isLeaf": true
              },
              {
                "key": "1363",
                "title": "1363 Pneumocystosis",
                "threedigit": "136",
                "isLeaf": true
              },
              {
                "key": "1364",
                "title": "1364 Psorospermiasis",
                "threedigit": "136",
                "isLeaf": true
              },
              {
                "key": "1365",
                "title": "1365 Sarcosporidiosis",
                "threedigit": "136",
                "isLeaf": true
              },
              {
                "key": "1368",
                "title": "1368 Infect/parasite dis NEC",
                "threedigit": "136",
                "isLeaf": true
              },
              {
                "key": "1369",
                "title": "1369 Infect/parasite dis NOS",
                "threedigit": "136",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Late Effects Of Infectious And Parasitic Diseases",
        "title": "137-139 Late Effects Of Infectious And Parasitic Diseases",
        "threedigit": "137",
        "children": [
          {
            "key": "Late effects of tuberculosis",
            "title": "137 - Late effects of tuberculosis",
            "threedigit": "137",
            "children": [
              {
                "key": "1370",
                "title": "1370 Late effect tb, resp/NOS",
                "threedigit": "137",
                "isLeaf": true
              },
              {
                "key": "1371",
                "title": "1371 Late effect cns TB",
                "threedigit": "137",
                "isLeaf": true
              },
              {
                "key": "1372",
                "title": "1372 Late effect gu TB",
                "threedigit": "137",
                "isLeaf": true
              },
              {
                "key": "1373",
                "title": "1373 Late eff bone & joint TB",
                "threedigit": "137",
                "isLeaf": true
              },
              {
                "key": "1374",
                "title": "1374 Late effect TB NEC",
                "threedigit": "137",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of acute poliomyelitis",
            "title": "138 - Late effects of acute poliomyelitis",
            "threedigit": "138",
            "children": [
              {
                "key": "138",
                "title": "138 Late effect acute polio",
                "threedigit": "138",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of other infectious and parasitic diseases",
            "title": "139 - Late effects of other infectious and parasitic diseases",
            "threedigit": "139",
            "children": [
              {
                "key": "1390",
                "title": "1390 Late eff viral encephal",
                "threedigit": "139",
                "isLeaf": true
              },
              {
                "key": "1391",
                "title": "1391 Late effect of trachoma",
                "threedigit": "139",
                "isLeaf": true
              },
              {
                "key": "1398",
                "title": "1398 Late eff infect dis NEC",
                "threedigit": "139",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Neoplasms",
    "title": "140-239 Neoplasms",
    "threedigit": "140",
    "children": [
      {
        "key": "Malignant Neoplasm Of Lip, Oral Cavity, And Pharynx",
        "title": "140-149 Malignant Neoplasm Of Lip, Oral Cavity, And Pharynx",
        "threedigit": "140",
        "children": [
          {
            "key": "Malignant neoplasm of lip",
            "title": "140 - Malignant neoplasm of lip",
            "threedigit": "140",
            "children": [
              {
                "key": "1400",
                "title": "1400 Mal neo upper vermilion",
                "threedigit": "140",
                "isLeaf": true
              },
              {
                "key": "1401",
                "title": "1401 Mal neo lower vermilion",
                "threedigit": "140",
                "isLeaf": true
              },
              {
                "key": "1403",
                "title": "1403 Mal neo upper lip, inner",
                "threedigit": "140",
                "isLeaf": true
              },
              {
                "key": "1404",
                "title": "1404 Mal neo lower lip, inner",
                "threedigit": "140",
                "isLeaf": true
              },
              {
                "key": "1405",
                "title": "1405 Mal neo lip, inner NOS",
                "threedigit": "140",
                "isLeaf": true
              },
              {
                "key": "1406",
                "title": "1406 Mal neo lip, commissure",
                "threedigit": "140",
                "isLeaf": true
              },
              {
                "key": "1408",
                "title": "1408 Mal neo lip NEC",
                "threedigit": "140",
                "isLeaf": true
              },
              {
                "key": "1409",
                "title": "1409 Mal neo lip/vermil NOS",
                "threedigit": "140",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of tongue",
            "title": "141 - Malignant neoplasm of tongue",
            "threedigit": "141",
            "children": [
              {
                "key": "1410",
                "title": "1410 Mal neo tongue base",
                "threedigit": "141",
                "isLeaf": true
              },
              {
                "key": "1411",
                "title": "1411 Mal neo dorsal tongue",
                "threedigit": "141",
                "isLeaf": true
              },
              {
                "key": "1412",
                "title": "1412 Mal neo tip/lat tongue",
                "threedigit": "141",
                "isLeaf": true
              },
              {
                "key": "1413",
                "title": "1413 Mal neo ventral tongue",
                "threedigit": "141",
                "isLeaf": true
              },
              {
                "key": "1414",
                "title": "1414 Mal neo ant 2/3 tongue",
                "threedigit": "141",
                "isLeaf": true
              },
              {
                "key": "1415",
                "title": "1415 Mal neo tongue junction",
                "threedigit": "141",
                "isLeaf": true
              },
              {
                "key": "1416",
                "title": "1416 Mal neo lingual tonsil",
                "threedigit": "141",
                "isLeaf": true
              },
              {
                "key": "1418",
                "title": "1418 Malig neo tongue NEC",
                "threedigit": "141",
                "isLeaf": true
              },
              {
                "key": "1419",
                "title": "1419 Malig neo tongue NOS",
                "threedigit": "141",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of major salivary glands",
            "title": "142 - Malignant neoplasm of major salivary glands",
            "threedigit": "142",
            "children": [
              {
                "key": "1420",
                "title": "1420 Malig neo parotid",
                "threedigit": "142",
                "isLeaf": true
              },
              {
                "key": "1421",
                "title": "1421 Malig neo submandibular",
                "threedigit": "142",
                "isLeaf": true
              },
              {
                "key": "1422",
                "title": "1422 Malig neo sublingual",
                "threedigit": "142",
                "isLeaf": true
              },
              {
                "key": "1428",
                "title": "1428 Mal neo maj salivary NEC",
                "threedigit": "142",
                "isLeaf": true
              },
              {
                "key": "1429",
                "title": "1429 Mal neo salivary NOS",
                "threedigit": "142",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of gum",
            "title": "143 - Malignant neoplasm of gum",
            "threedigit": "143",
            "children": [
              {
                "key": "1430",
                "title": "1430 Malig neo upper gum",
                "threedigit": "143",
                "isLeaf": true
              },
              {
                "key": "1431",
                "title": "1431 Malig neo lower gum",
                "threedigit": "143",
                "isLeaf": true
              },
              {
                "key": "1438",
                "title": "1438 Malig neo gum NEC",
                "threedigit": "143",
                "isLeaf": true
              },
              {
                "key": "1439",
                "title": "1439 Malig neo gum NOS",
                "threedigit": "143",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of floor of mouth",
            "title": "144 - Malignant neoplasm of floor of mouth",
            "threedigit": "144",
            "children": [
              {
                "key": "1440",
                "title": "1440 Mal neo ant floor mouth",
                "threedigit": "144",
                "isLeaf": true
              },
              {
                "key": "1441",
                "title": "1441 Mal neo lat floor mouth",
                "threedigit": "144",
                "isLeaf": true
              },
              {
                "key": "1448",
                "title": "1448 Mal neo mouth floor NEC",
                "threedigit": "144",
                "isLeaf": true
              },
              {
                "key": "1449",
                "title": "1449 Mal neo mouth floor NOS",
                "threedigit": "144",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of other and unspecified parts of mouth",
            "title": "145 - Malignant neoplasm of other and unspecified parts of mouth",
            "threedigit": "145",
            "children": [
              {
                "key": "1450",
                "title": "1450 Mal neo cheek mucosa",
                "threedigit": "145",
                "isLeaf": true
              },
              {
                "key": "1451",
                "title": "1451 Mal neo mouth vestibule",
                "threedigit": "145",
                "isLeaf": true
              },
              {
                "key": "1452",
                "title": "1452 Malig neo hard palate",
                "threedigit": "145",
                "isLeaf": true
              },
              {
                "key": "1453",
                "title": "1453 Malig neo soft palate",
                "threedigit": "145",
                "isLeaf": true
              },
              {
                "key": "1454",
                "title": "1454 Malignant neoplasm uvula",
                "threedigit": "145",
                "isLeaf": true
              },
              {
                "key": "1455",
                "title": "1455 Malignant neo palate NOS",
                "threedigit": "145",
                "isLeaf": true
              },
              {
                "key": "1456",
                "title": "1456 Malig neo retromolar",
                "threedigit": "145",
                "isLeaf": true
              },
              {
                "key": "1458",
                "title": "1458 Malig neoplasm mouth NEC",
                "threedigit": "145",
                "isLeaf": true
              },
              {
                "key": "1459",
                "title": "1459 Malig neoplasm mouth NOS",
                "threedigit": "145",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of oropharynx",
            "title": "146 - Malignant neoplasm of oropharynx",
            "threedigit": "146",
            "children": [
              {
                "key": "1460",
                "title": "1460 Malignant neopl tonsil",
                "threedigit": "146",
                "isLeaf": true
              },
              {
                "key": "1461",
                "title": "1461 Mal neo tonsillar fossa",
                "threedigit": "146",
                "isLeaf": true
              },
              {
                "key": "1462",
                "title": "1462 Mal neo tonsil pillars",
                "threedigit": "146",
                "isLeaf": true
              },
              {
                "key": "1463",
                "title": "1463 Malign neopl vallecula",
                "threedigit": "146",
                "isLeaf": true
              },
              {
                "key": "1464",
                "title": "1464 Mal neo ant epiglottis",
                "threedigit": "146",
                "isLeaf": true
              },
              {
                "key": "1465",
                "title": "1465 Mal neo epiglottis junct",
                "threedigit": "146",
                "isLeaf": true
              },
              {
                "key": "1466",
                "title": "1466 Mal neo lat oropharynx",
                "threedigit": "146",
                "isLeaf": true
              },
              {
                "key": "1467",
                "title": "1467 Mal neo post oropharynx",
                "threedigit": "146",
                "isLeaf": true
              },
              {
                "key": "1468",
                "title": "1468 Mal neo oropharynx NEC",
                "threedigit": "146",
                "isLeaf": true
              },
              {
                "key": "1469",
                "title": "1469 Malig neo oropharynx NOS",
                "threedigit": "146",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of nasopharynx",
            "title": "147 - Malignant neoplasm of nasopharynx",
            "threedigit": "147",
            "children": [
              {
                "key": "1470",
                "title": "1470 Mal neo super nasopharyn",
                "threedigit": "147",
                "isLeaf": true
              },
              {
                "key": "1471",
                "title": "1471 Mal neo post nasopharynx",
                "threedigit": "147",
                "isLeaf": true
              },
              {
                "key": "1472",
                "title": "1472 Mal neo lat nasopharynx",
                "threedigit": "147",
                "isLeaf": true
              },
              {
                "key": "1473",
                "title": "1473 Mal neo ant nasopharynx",
                "threedigit": "147",
                "isLeaf": true
              },
              {
                "key": "1478",
                "title": "1478 Mal neo nasopharynx NEC",
                "threedigit": "147",
                "isLeaf": true
              },
              {
                "key": "1479",
                "title": "1479 Mal neo nasopharynx NOS",
                "threedigit": "147",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of hypopharynx",
            "title": "148 - Malignant neoplasm of hypopharynx",
            "threedigit": "148",
            "children": [
              {
                "key": "1480",
                "title": "1480 Mal neo postcricoid",
                "threedigit": "148",
                "isLeaf": true
              },
              {
                "key": "1481",
                "title": "1481 Mal neo pyriform sinus",
                "threedigit": "148",
                "isLeaf": true
              },
              {
                "key": "1482",
                "title": "1482 Mal neo aryepiglott fold",
                "threedigit": "148",
                "isLeaf": true
              },
              {
                "key": "1483",
                "title": "1483 Mal neo post hypopharynx",
                "threedigit": "148",
                "isLeaf": true
              },
              {
                "key": "1488",
                "title": "1488 Mal neo hypopharynx NEC",
                "threedigit": "148",
                "isLeaf": true
              },
              {
                "key": "1489",
                "title": "1489 Mal neo hypopharynx NOS",
                "threedigit": "148",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of other and ill-defined sites within the lip oral cavity and pharynx",
            "title": "149 - Malignant neoplasm of other and ill-defined sites within the lip oral cavity and pharynx",
            "threedigit": "149",
            "children": [
              {
                "key": "1490",
                "title": "1490 Mal neo pharynx NOS",
                "threedigit": "149",
                "isLeaf": true
              },
              {
                "key": "1491",
                "title": "1491 Mal neo waldeyer's ring",
                "threedigit": "149",
                "isLeaf": true
              },
              {
                "key": "1498",
                "title": "1498 Mal neo oral/pharynx NEC",
                "threedigit": "149",
                "isLeaf": true
              },
              {
                "key": "1499",
                "title": "1499 Mal neo orophryn ill-def",
                "threedigit": "149",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Malignant Neoplasm Of Digestive Organs And Peritoneum",
        "title": "150-159 Malignant Neoplasm Of Digestive Organs And Peritoneum",
        "threedigit": "150",
        "children": [
          {
            "key": "Malignant neoplasm of esophagus",
            "title": "150 - Malignant neoplasm of esophagus",
            "threedigit": "150",
            "children": [
              {
                "key": "1500",
                "title": "1500 Mal neo cervical esophag",
                "threedigit": "150",
                "isLeaf": true
              },
              {
                "key": "1501",
                "title": "1501 Mal neo thoracic esophag",
                "threedigit": "150",
                "isLeaf": true
              },
              {
                "key": "1502",
                "title": "1502 Mal neo abdomin esophag",
                "threedigit": "150",
                "isLeaf": true
              },
              {
                "key": "1503",
                "title": "1503 Mal neo upper 3rd esoph",
                "threedigit": "150",
                "isLeaf": true
              },
              {
                "key": "1504",
                "title": "1504 Mal neo middle 3rd esoph",
                "threedigit": "150",
                "isLeaf": true
              },
              {
                "key": "1505",
                "title": "1505 Mal neo lower 3rd esoph",
                "threedigit": "150",
                "isLeaf": true
              },
              {
                "key": "1508",
                "title": "1508 Mal neo esophagus NEC",
                "threedigit": "150",
                "isLeaf": true
              },
              {
                "key": "1509",
                "title": "1509 Mal neo esophagus NOS",
                "threedigit": "150",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of stomach",
            "title": "151 - Malignant neoplasm of stomach",
            "threedigit": "151",
            "children": [
              {
                "key": "1510",
                "title": "1510 Mal neo stomach cardia",
                "threedigit": "151",
                "isLeaf": true
              },
              {
                "key": "1511",
                "title": "1511 Malignant neo pylorus",
                "threedigit": "151",
                "isLeaf": true
              },
              {
                "key": "1512",
                "title": "1512 Mal neo pyloric antrum",
                "threedigit": "151",
                "isLeaf": true
              },
              {
                "key": "1513",
                "title": "1513 Mal neo stomach fundus",
                "threedigit": "151",
                "isLeaf": true
              },
              {
                "key": "1514",
                "title": "1514 Mal neo stomach body",
                "threedigit": "151",
                "isLeaf": true
              },
              {
                "key": "1515",
                "title": "1515 Mal neo stom lesser curv",
                "threedigit": "151",
                "isLeaf": true
              },
              {
                "key": "1516",
                "title": "1516 Mal neo stom great curv",
                "threedigit": "151",
                "isLeaf": true
              },
              {
                "key": "1518",
                "title": "1518 Malig neopl stomach NEC",
                "threedigit": "151",
                "isLeaf": true
              },
              {
                "key": "1519",
                "title": "1519 Malig neopl stomach NOS",
                "threedigit": "151",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of small intestine including duodenum",
            "title": "152 - Malignant neoplasm of small intestine including duodenum",
            "threedigit": "152",
            "children": [
              {
                "key": "1520",
                "title": "1520 Malignant neopl duodenum",
                "threedigit": "152",
                "isLeaf": true
              },
              {
                "key": "1521",
                "title": "1521 Malignant neopl jejunum",
                "threedigit": "152",
                "isLeaf": true
              },
              {
                "key": "1522",
                "title": "1522 Malignant neoplasm ileum",
                "threedigit": "152",
                "isLeaf": true
              },
              {
                "key": "1523",
                "title": "1523 Mal neo meckel's divert",
                "threedigit": "152",
                "isLeaf": true
              },
              {
                "key": "1528",
                "title": "1528 Mal neo small bowel NEC",
                "threedigit": "152",
                "isLeaf": true
              },
              {
                "key": "1529",
                "title": "1529 Mal neo small bowel NOS",
                "threedigit": "152",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of colon",
            "title": "153 - Malignant neoplasm of colon",
            "threedigit": "153",
            "children": [
              {
                "key": "1530",
                "title": "1530 Mal neo hepatic flexure",
                "threedigit": "153",
                "isLeaf": true
              },
              {
                "key": "1531",
                "title": "1531 Mal neo transverse colon",
                "threedigit": "153",
                "isLeaf": true
              },
              {
                "key": "1532",
                "title": "1532 Mal neo descend colon",
                "threedigit": "153",
                "isLeaf": true
              },
              {
                "key": "1533",
                "title": "1533 Mal neo sigmoid colon",
                "threedigit": "153",
                "isLeaf": true
              },
              {
                "key": "1534",
                "title": "1534 Malignant neoplasm cecum",
                "threedigit": "153",
                "isLeaf": true
              },
              {
                "key": "1535",
                "title": "1535 Malignant neo appendix",
                "threedigit": "153",
                "isLeaf": true
              },
              {
                "key": "1536",
                "title": "1536 Malig neo ascend colon",
                "threedigit": "153",
                "isLeaf": true
              },
              {
                "key": "1537",
                "title": "1537 Mal neo splenic flexure",
                "threedigit": "153",
                "isLeaf": true
              },
              {
                "key": "1538",
                "title": "1538 Malignant neo colon NEC",
                "threedigit": "153",
                "isLeaf": true
              },
              {
                "key": "1539",
                "title": "1539 Malignant neo colon NOS",
                "threedigit": "153",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of rectum rectosigmoid junction and anus",
            "title": "154 - Malignant neoplasm of rectum rectosigmoid junction and anus",
            "threedigit": "154",
            "children": [
              {
                "key": "1540",
                "title": "1540 Mal neo rectosigmoid jct",
                "threedigit": "154",
                "isLeaf": true
              },
              {
                "key": "1541",
                "title": "1541 Malignant neopl rectum",
                "threedigit": "154",
                "isLeaf": true
              },
              {
                "key": "1542",
                "title": "1542 Malig neopl anal canal",
                "threedigit": "154",
                "isLeaf": true
              },
              {
                "key": "1543",
                "title": "1543 Malignant neo anus NOS",
                "threedigit": "154",
                "isLeaf": true
              },
              {
                "key": "1548",
                "title": "1548 Mal neo rectum/anus NEC",
                "threedigit": "154",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of liver and intrahepatic bile ducts",
            "title": "155 - Malignant neoplasm of liver and intrahepatic bile ducts",
            "threedigit": "155",
            "children": [
              {
                "key": "1550",
                "title": "1550 Mal neo liver, primary",
                "threedigit": "155",
                "isLeaf": true
              },
              {
                "key": "1551",
                "title": "1551 Mal neo intrahepat ducts",
                "threedigit": "155",
                "isLeaf": true
              },
              {
                "key": "1552",
                "title": "1552 Malignant neo liver NOS",
                "threedigit": "155",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of gallbladder and extrahepatic bile ducts",
            "title": "156 - Malignant neoplasm of gallbladder and extrahepatic bile ducts",
            "threedigit": "156",
            "children": [
              {
                "key": "1560",
                "title": "1560 Malig neo gallbladder",
                "threedigit": "156",
                "isLeaf": true
              },
              {
                "key": "1561",
                "title": "1561 Mal neo extrahepat ducts",
                "threedigit": "156",
                "isLeaf": true
              },
              {
                "key": "1562",
                "title": "1562 Mal neo ampulla of vater",
                "threedigit": "156",
                "isLeaf": true
              },
              {
                "key": "1568",
                "title": "1568 Malig neo biliary NEC",
                "threedigit": "156",
                "isLeaf": true
              },
              {
                "key": "1569",
                "title": "1569 Malig neo biliary NOS",
                "threedigit": "156",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of pancreas",
            "title": "157 - Malignant neoplasm of pancreas",
            "threedigit": "157",
            "children": [
              {
                "key": "1570",
                "title": "1570 Mal neo pancreas head",
                "threedigit": "157",
                "isLeaf": true
              },
              {
                "key": "1571",
                "title": "1571 Mal neo pancreas body",
                "threedigit": "157",
                "isLeaf": true
              },
              {
                "key": "1572",
                "title": "1572 Mal neo pancreas tail",
                "threedigit": "157",
                "isLeaf": true
              },
              {
                "key": "1573",
                "title": "1573 Mal neo pancreatic duct",
                "threedigit": "157",
                "isLeaf": true
              },
              {
                "key": "1574",
                "title": "1574 Mal neo islet langerhans",
                "threedigit": "157",
                "isLeaf": true
              },
              {
                "key": "1578",
                "title": "1578 Malig neo pancreas NEC",
                "threedigit": "157",
                "isLeaf": true
              },
              {
                "key": "1579",
                "title": "1579 Malig neo pancreas NOS",
                "threedigit": "157",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of retroperitoneum and peritoneum",
            "title": "158 - Malignant neoplasm of retroperitoneum and peritoneum",
            "threedigit": "158",
            "children": [
              {
                "key": "1580",
                "title": "1580 Mal neo retroperitoneum",
                "threedigit": "158",
                "isLeaf": true
              },
              {
                "key": "1588",
                "title": "1588 Mal neo peritoneum NEC",
                "threedigit": "158",
                "isLeaf": true
              },
              {
                "key": "1589",
                "title": "1589 Mal neo peritoneum NOS",
                "threedigit": "158",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of other and ill-defined sites within the digestive organs and peritoneum",
            "title": "159 - Malignant neoplasm of other and ill-defined sites within the digestive organs and peritoneum",
            "threedigit": "159",
            "children": [
              {
                "key": "1590",
                "title": "1590 Malig neo intestine NOS",
                "threedigit": "159",
                "isLeaf": true
              },
              {
                "key": "1591",
                "title": "1591 Malignant neo spleen NEC",
                "threedigit": "159",
                "isLeaf": true
              },
              {
                "key": "1598",
                "title": "1598 Mal neo gi/intra-abd NEC",
                "threedigit": "159",
                "isLeaf": true
              },
              {
                "key": "1599",
                "title": "1599 Mal neo GI tract ill-def",
                "threedigit": "159",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Malignant Neoplasm Of Respiratory And Intrathoracic Organs",
        "title": "160-165 Malignant Neoplasm Of Respiratory And Intrathoracic Organs",
        "threedigit": "160",
        "children": [
          {
            "key": "Malignant neoplasm of nasal cavities middle ear and accessory sinuses",
            "title": "160 - Malignant neoplasm of nasal cavities middle ear and accessory sinuses",
            "threedigit": "160",
            "children": [
              {
                "key": "1600",
                "title": "1600 Mal neo nasal cavities",
                "threedigit": "160",
                "isLeaf": true
              },
              {
                "key": "1601",
                "title": "1601 Malig neo middle ear",
                "threedigit": "160",
                "isLeaf": true
              },
              {
                "key": "1602",
                "title": "1602 Mal neo maxillary sinus",
                "threedigit": "160",
                "isLeaf": true
              },
              {
                "key": "1603",
                "title": "1603 Mal neo ethmoidal sinus",
                "threedigit": "160",
                "isLeaf": true
              },
              {
                "key": "1604",
                "title": "1604 Malig neo frontal sinus",
                "threedigit": "160",
                "isLeaf": true
              },
              {
                "key": "1605",
                "title": "1605 Mal neo sphenoid sinus",
                "threedigit": "160",
                "isLeaf": true
              },
              {
                "key": "1608",
                "title": "1608 Mal neo access sinus NEC",
                "threedigit": "160",
                "isLeaf": true
              },
              {
                "key": "1609",
                "title": "1609 Mal neo access sinus NOS",
                "threedigit": "160",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of larynx",
            "title": "161 - Malignant neoplasm of larynx",
            "threedigit": "161",
            "children": [
              {
                "key": "1610",
                "title": "1610 Malignant neo glottis",
                "threedigit": "161",
                "isLeaf": true
              },
              {
                "key": "1611",
                "title": "1611 Malig neo supraglottis",
                "threedigit": "161",
                "isLeaf": true
              },
              {
                "key": "1612",
                "title": "1612 Malig neo subglottis",
                "threedigit": "161",
                "isLeaf": true
              },
              {
                "key": "1613",
                "title": "1613 Mal neo cartilage larynx",
                "threedigit": "161",
                "isLeaf": true
              },
              {
                "key": "1618",
                "title": "1618 Malignant neo larynx NEC",
                "threedigit": "161",
                "isLeaf": true
              },
              {
                "key": "1619",
                "title": "1619 Malignant neo larynx NOS",
                "threedigit": "161",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of trachea bronchus and lung",
            "title": "162 - Malignant neoplasm of trachea bronchus and lung",
            "threedigit": "162",
            "children": [
              {
                "key": "1620",
                "title": "1620 Malignant neo trachea",
                "threedigit": "162",
                "isLeaf": true
              },
              {
                "key": "1622",
                "title": "1622 Malig neo main bronchus",
                "threedigit": "162",
                "isLeaf": true
              },
              {
                "key": "1623",
                "title": "1623 Mal neo upper lobe lung",
                "threedigit": "162",
                "isLeaf": true
              },
              {
                "key": "1624",
                "title": "1624 Mal neo middle lobe lung",
                "threedigit": "162",
                "isLeaf": true
              },
              {
                "key": "1625",
                "title": "1625 Mal neo lower lobe lung",
                "threedigit": "162",
                "isLeaf": true
              },
              {
                "key": "1628",
                "title": "1628 Mal neo bronch/lung NEC",
                "threedigit": "162",
                "isLeaf": true
              },
              {
                "key": "1629",
                "title": "1629 Mal neo bronch/lung NOS",
                "threedigit": "162",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of pleura",
            "title": "163 - Malignant neoplasm of pleura",
            "threedigit": "163",
            "children": [
              {
                "key": "1630",
                "title": "1630 Mal neo parietal pleura",
                "threedigit": "163",
                "isLeaf": true
              },
              {
                "key": "1631",
                "title": "1631 Mal neo visceral pleura",
                "threedigit": "163",
                "isLeaf": true
              },
              {
                "key": "1638",
                "title": "1638 Malig neopl pleura NEC",
                "threedigit": "163",
                "isLeaf": true
              },
              {
                "key": "1639",
                "title": "1639 Malig neopl pleura NOS",
                "threedigit": "163",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of thymus heart and mediastinum",
            "title": "164 - Malignant neoplasm of thymus heart and mediastinum",
            "threedigit": "164",
            "children": [
              {
                "key": "1640",
                "title": "1640 Malignant neopl thymus",
                "threedigit": "164",
                "isLeaf": true
              },
              {
                "key": "1641",
                "title": "1641 Malignant neopl heart",
                "threedigit": "164",
                "isLeaf": true
              },
              {
                "key": "1642",
                "title": "1642 Mal neo ant mediastinum",
                "threedigit": "164",
                "isLeaf": true
              },
              {
                "key": "1643",
                "title": "1643 Mal neo post mediastinum",
                "threedigit": "164",
                "isLeaf": true
              },
              {
                "key": "1648",
                "title": "1648 Mal neo mediastinum NEC",
                "threedigit": "164",
                "isLeaf": true
              },
              {
                "key": "1649",
                "title": "1649 Mal neo mediastinum NOS",
                "threedigit": "164",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of other and ill-defined sites within the respiratory system and intrathoracic organs",
            "title": "165 - Malignant neoplasm of other and ill-defined sites within the respiratory system and intrathoracic organs",
            "threedigit": "165",
            "children": [
              {
                "key": "1650",
                "title": "1650 Mal neo upper resp NOS",
                "threedigit": "165",
                "isLeaf": true
              },
              {
                "key": "1658",
                "title": "1658 Mal neo thorax/resp NEC",
                "threedigit": "165",
                "isLeaf": true
              },
              {
                "key": "1659",
                "title": "1659 Mal neo resp system NOS",
                "threedigit": "165",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Malignant Neoplasm Of Bone, Connective Tissue, Skin, And Breast",
        "title": "170-176 Malignant Neoplasm Of Bone, Connective Tissue, Skin, And Breast",
        "threedigit": "170",
        "children": [
          {
            "key": "Malignant neoplasm of bone and articular cartilage",
            "title": "170 - Malignant neoplasm of bone and articular cartilage",
            "threedigit": "170",
            "children": [
              {
                "key": "1700",
                "title": "1700 Mal neo skull/face bone",
                "threedigit": "170",
                "isLeaf": true
              },
              {
                "key": "1701",
                "title": "1701 Malignant neo mandible",
                "threedigit": "170",
                "isLeaf": true
              },
              {
                "key": "1702",
                "title": "1702 Malig neo vertebrae",
                "threedigit": "170",
                "isLeaf": true
              },
              {
                "key": "1703",
                "title": "1703 Mal neo ribs/stern/clav",
                "threedigit": "170",
                "isLeaf": true
              },
              {
                "key": "1704",
                "title": "1704 Mal neo long bones arm",
                "threedigit": "170",
                "isLeaf": true
              },
              {
                "key": "1705",
                "title": "1705 Mal neo bones wrist/hand",
                "threedigit": "170",
                "isLeaf": true
              },
              {
                "key": "1706",
                "title": "1706 Mal neo pelvic girdle",
                "threedigit": "170",
                "isLeaf": true
              },
              {
                "key": "1707",
                "title": "1707 Mal neo long bones leg",
                "threedigit": "170",
                "isLeaf": true
              },
              {
                "key": "1708",
                "title": "1708 Mal neo bones ankle/foot",
                "threedigit": "170",
                "isLeaf": true
              },
              {
                "key": "1709",
                "title": "1709 Malig neopl bone NOS",
                "threedigit": "170",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of connective and other soft tissue",
            "title": "171 - Malignant neoplasm of connective and other soft tissue",
            "threedigit": "171",
            "children": [
              {
                "key": "1710",
                "title": "1710 Mal neo soft tissue head",
                "threedigit": "171",
                "isLeaf": true
              },
              {
                "key": "1712",
                "title": "1712 Mal neo soft tissue arm",
                "threedigit": "171",
                "isLeaf": true
              },
              {
                "key": "1713",
                "title": "1713 Mal neo soft tissue leg",
                "threedigit": "171",
                "isLeaf": true
              },
              {
                "key": "1714",
                "title": "1714 Mal neo soft tis thorax",
                "threedigit": "171",
                "isLeaf": true
              },
              {
                "key": "1715",
                "title": "1715 Mal neo soft tis abdomen",
                "threedigit": "171",
                "isLeaf": true
              },
              {
                "key": "1716",
                "title": "1716 Mal neo soft tis pelvis",
                "threedigit": "171",
                "isLeaf": true
              },
              {
                "key": "1717",
                "title": "1717 Mal neopl trunk NOS",
                "threedigit": "171",
                "isLeaf": true
              },
              {
                "key": "1718",
                "title": "1718 Mal neo soft tissue NEC",
                "threedigit": "171",
                "isLeaf": true
              },
              {
                "key": "1719",
                "title": "1719 Mal neo soft tissue NOS",
                "threedigit": "171",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant melanoma of skin",
            "title": "172 - Malignant melanoma of skin",
            "threedigit": "172",
            "children": [
              {
                "key": "1720",
                "title": "1720 Malig melanoma lip",
                "threedigit": "172",
                "isLeaf": true
              },
              {
                "key": "1721",
                "title": "1721 Malig melanoma eyelid",
                "threedigit": "172",
                "isLeaf": true
              },
              {
                "key": "1722",
                "title": "1722 Malig melanoma ear",
                "threedigit": "172",
                "isLeaf": true
              },
              {
                "key": "1723",
                "title": "1723 Mal melanom face NEC/NOS",
                "threedigit": "172",
                "isLeaf": true
              },
              {
                "key": "1724",
                "title": "1724 Mal melanoma scalp/neck",
                "threedigit": "172",
                "isLeaf": true
              },
              {
                "key": "1725",
                "title": "1725 Malig melanoma trunk",
                "threedigit": "172",
                "isLeaf": true
              },
              {
                "key": "1726",
                "title": "1726 Malig melanoma arm",
                "threedigit": "172",
                "isLeaf": true
              },
              {
                "key": "1727",
                "title": "1727 Malig melanoma leg",
                "threedigit": "172",
                "isLeaf": true
              },
              {
                "key": "1728",
                "title": "1728 Malig melanoma skin NEC",
                "threedigit": "172",
                "isLeaf": true
              },
              {
                "key": "1729",
                "title": "1729 Malig melanoma skin NOS",
                "threedigit": "172",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Oth and unspec malignant neoplasm of skin",
            "title": "173 - Oth and unspec malignant neoplasm of skin",
            "threedigit": "173",
            "children": [
              {
                "key": "17300",
                "title": "17300 Malig neopl skin lip NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17301",
                "title": "17301 Basal cell ca skin lip",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17302",
                "title": "17302 Squamous cell ca skn lip",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17309",
                "title": "17309 Malig neo skin lip NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17310",
                "title": "17310 Mal neo eyelid/canth NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17311",
                "title": "17311 Basal cell ca lid/canth",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17312",
                "title": "17312 Squam cell ca lid/canth",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17319",
                "title": "17319 Mal neo eyelid/canth NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17320",
                "title": "17320 Malig neo skin ear NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17321",
                "title": "17321 Basal cell ca skin ear",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17322",
                "title": "17322 Squam cell ca skin ear",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17329",
                "title": "17329 Neo skin ear/ex canl NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17330",
                "title": "17330 Mal neo skn face NEC/NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17331",
                "title": "17331 Bsl cel skn face NEC/NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17332",
                "title": "17332 Sqm cel skn face NEC/NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17339",
                "title": "17339 Mal neo skn face NEC/NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17340",
                "title": "17340 Mal neo sclp/skn nck NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17341",
                "title": "17341 Bsl cell ca scalp/skn nk",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17342",
                "title": "17342 Sqam cell ca sclp/skn nk",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17349",
                "title": "17349 Mal neo sclp/skn nck NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17350",
                "title": "17350 Malig neo skin trunk NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17351",
                "title": "17351 Basal cell ca skin trunk",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17352",
                "title": "17352 Squam cell ca skin trunk",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17359",
                "title": "17359 Malig neo skin trunk NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17360",
                "title": "17360 Mal neo skin up limb NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17361",
                "title": "17361 Basal cell ca skn up lmb",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17362",
                "title": "17362 Squam cell ca skn up lmb",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17369",
                "title": "17369 Malig neo skn up lmb NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17370",
                "title": "17370 Mal neo skn low limb NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17371",
                "title": "17371 Basl cell ca skn low lmb",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17372",
                "title": "17372 Sqam cell ca skn low lmb",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17379",
                "title": "17379 Mal neo skin low lmb NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17380",
                "title": "17380 Mal neo skn site NEC/NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17381",
                "title": "17381 Bsl cell ca skn site NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17382",
                "title": "17382 Sqm cell ca skn site NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17389",
                "title": "17389 Oth mal neo skn site NEC",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17390",
                "title": "17390 Malig neo skin site NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17391",
                "title": "17391 Basal cell ca skin NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17392",
                "title": "17392 Squam cell ca skin NOS",
                "threedigit": "173",
                "isLeaf": true
              },
              {
                "key": "17399",
                "title": "17399 Oth mal neo skn site NOS",
                "threedigit": "173",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of female breast",
            "title": "174 - Malignant neoplasm of female breast",
            "threedigit": "174",
            "children": [
              {
                "key": "1740",
                "title": "1740 Malig neo nipple",
                "threedigit": "174",
                "isLeaf": true
              },
              {
                "key": "1741",
                "title": "1741 Mal neo breast-central",
                "threedigit": "174",
                "isLeaf": true
              },
              {
                "key": "1742",
                "title": "1742 Mal neo breast up-inner",
                "threedigit": "174",
                "isLeaf": true
              },
              {
                "key": "1743",
                "title": "1743 Mal neo breast low-inner",
                "threedigit": "174",
                "isLeaf": true
              },
              {
                "key": "1744",
                "title": "1744 Mal neo breast up-outer",
                "threedigit": "174",
                "isLeaf": true
              },
              {
                "key": "1745",
                "title": "1745 Mal neo breast low-outer",
                "threedigit": "174",
                "isLeaf": true
              },
              {
                "key": "1746",
                "title": "1746 Mal neo breast-axillary",
                "threedigit": "174",
                "isLeaf": true
              },
              {
                "key": "1748",
                "title": "1748 Malign neopl breast NEC",
                "threedigit": "174",
                "isLeaf": true
              },
              {
                "key": "1749",
                "title": "1749 Malign neopl breast NOS",
                "threedigit": "174",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of male breast",
            "title": "175 - Malignant neoplasm of male breast",
            "threedigit": "175",
            "children": [
              {
                "key": "1750",
                "title": "1750 Mal neo male nipple",
                "threedigit": "175",
                "isLeaf": true
              },
              {
                "key": "1759",
                "title": "1759 Mal neo male breast NEC",
                "threedigit": "175",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Kaposi's sarcoma",
            "title": "176 - Kaposi's sarcoma",
            "threedigit": "176",
            "children": [
              {
                "key": "1760",
                "title": "1760 Skin - kaposi's sarcoma",
                "threedigit": "176",
                "isLeaf": true
              },
              {
                "key": "1761",
                "title": "1761 Sft tisue - kpsi's srcma",
                "threedigit": "176",
                "isLeaf": true
              },
              {
                "key": "1762",
                "title": "1762 Palate - kpsi's sarcoma",
                "threedigit": "176",
                "isLeaf": true
              },
              {
                "key": "1763",
                "title": "1763 GI sites - kpsi's srcoma",
                "threedigit": "176",
                "isLeaf": true
              },
              {
                "key": "1764",
                "title": "1764 Lung - kaposi's sarcoma",
                "threedigit": "176",
                "isLeaf": true
              },
              {
                "key": "1765",
                "title": "1765 Lym nds - kpsi's sarcoma",
                "threedigit": "176",
                "isLeaf": true
              },
              {
                "key": "1768",
                "title": "1768 Spf sts - kpsi's sarcoma",
                "threedigit": "176",
                "isLeaf": true
              },
              {
                "key": "1769",
                "title": "1769 Kaposi's sarcoma NOS",
                "threedigit": "176",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Malignant Neoplasm Of Genitourinary Organs",
        "title": "179-189 Malignant Neoplasm Of Genitourinary Organs",
        "threedigit": "179",
        "children": [
          {
            "key": "Malignant neoplasm of uterus, part unspecified",
            "title": "179 - Malignant neoplasm of uterus, part unspecified",
            "threedigit": "179",
            "children": [
              {
                "key": "179",
                "title": "179 Malig neopl uterus NOS",
                "threedigit": "179",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of cervix uteri",
            "title": "180 - Malignant neoplasm of cervix uteri",
            "threedigit": "180",
            "children": [
              {
                "key": "1800",
                "title": "1800 Malig neo endocervix",
                "threedigit": "180",
                "isLeaf": true
              },
              {
                "key": "1801",
                "title": "1801 Malig neo exocervix",
                "threedigit": "180",
                "isLeaf": true
              },
              {
                "key": "1808",
                "title": "1808 Malig neo cervix NEC",
                "threedigit": "180",
                "isLeaf": true
              },
              {
                "key": "1809",
                "title": "1809 Mal neo cervix uteri NOS",
                "threedigit": "180",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of placenta",
            "title": "181 - Malignant neoplasm of placenta",
            "threedigit": "181",
            "children": [
              {
                "key": "181",
                "title": "181 Malignant neopl placenta",
                "threedigit": "181",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of body of uterus",
            "title": "182 - Malignant neoplasm of body of uterus",
            "threedigit": "182",
            "children": [
              {
                "key": "1820",
                "title": "1820 Malig neo corpus uteri",
                "threedigit": "182",
                "isLeaf": true
              },
              {
                "key": "1821",
                "title": "1821 Mal neo uterine isthmus",
                "threedigit": "182",
                "isLeaf": true
              },
              {
                "key": "1828",
                "title": "1828 Mal neo body uterus NEC",
                "threedigit": "182",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of ovary and other uterine adnexa",
            "title": "183 - Malignant neoplasm of ovary and other uterine adnexa",
            "threedigit": "183",
            "children": [
              {
                "key": "1830",
                "title": "1830 Malign neopl ovary",
                "threedigit": "183",
                "isLeaf": true
              },
              {
                "key": "1832",
                "title": "1832 Mal neo fallopian tube",
                "threedigit": "183",
                "isLeaf": true
              },
              {
                "key": "1833",
                "title": "1833 Mal neo broad ligament",
                "threedigit": "183",
                "isLeaf": true
              },
              {
                "key": "1834",
                "title": "1834 Malig neo parametrium",
                "threedigit": "183",
                "isLeaf": true
              },
              {
                "key": "1835",
                "title": "1835 Mal neo round ligament",
                "threedigit": "183",
                "isLeaf": true
              },
              {
                "key": "1838",
                "title": "1838 Mal neo adnexa NEC",
                "threedigit": "183",
                "isLeaf": true
              },
              {
                "key": "1839",
                "title": "1839 Mal neo adnexa NOS",
                "threedigit": "183",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of other and unspecified female genital organs",
            "title": "184 - Malignant neoplasm of other and unspecified female genital organs",
            "threedigit": "184",
            "children": [
              {
                "key": "1840",
                "title": "1840 Malign neopl vagina",
                "threedigit": "184",
                "isLeaf": true
              },
              {
                "key": "1841",
                "title": "1841 Mal neo labia majora",
                "threedigit": "184",
                "isLeaf": true
              },
              {
                "key": "1842",
                "title": "1842 Mal neo labia minora",
                "threedigit": "184",
                "isLeaf": true
              },
              {
                "key": "1843",
                "title": "1843 Malign neopl clitoris",
                "threedigit": "184",
                "isLeaf": true
              },
              {
                "key": "1844",
                "title": "1844 Malign neopl vulva NOS",
                "threedigit": "184",
                "isLeaf": true
              },
              {
                "key": "1848",
                "title": "1848 Mal neo female genit NEC",
                "threedigit": "184",
                "isLeaf": true
              },
              {
                "key": "1849",
                "title": "1849 Mal neo female genit NOS",
                "threedigit": "184",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of prostate",
            "title": "185 - Malignant neoplasm of prostate",
            "threedigit": "185",
            "children": [
              {
                "key": "185",
                "title": "185 Malign neopl prostate",
                "threedigit": "185",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of testis",
            "title": "186 - Malignant neoplasm of testis",
            "threedigit": "186",
            "children": [
              {
                "key": "1860",
                "title": "1860 Mal neo undescend testis",
                "threedigit": "186",
                "isLeaf": true
              },
              {
                "key": "1869",
                "title": "1869 Malig neo testis NEC",
                "threedigit": "186",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of penis and other male genital organs",
            "title": "187 - Malignant neoplasm of penis and other male genital organs",
            "threedigit": "187",
            "children": [
              {
                "key": "1871",
                "title": "1871 Malign neopl prepuce",
                "threedigit": "187",
                "isLeaf": true
              },
              {
                "key": "1872",
                "title": "1872 Malig neo glans penis",
                "threedigit": "187",
                "isLeaf": true
              },
              {
                "key": "1873",
                "title": "1873 Malig neo penis body",
                "threedigit": "187",
                "isLeaf": true
              },
              {
                "key": "1874",
                "title": "1874 Malig neo penis NOS",
                "threedigit": "187",
                "isLeaf": true
              },
              {
                "key": "1875",
                "title": "1875 Malig neo epididymis",
                "threedigit": "187",
                "isLeaf": true
              },
              {
                "key": "1876",
                "title": "1876 Mal neo spermatic cord",
                "threedigit": "187",
                "isLeaf": true
              },
              {
                "key": "1877",
                "title": "1877 Malign neopl scrotum",
                "threedigit": "187",
                "isLeaf": true
              },
              {
                "key": "1878",
                "title": "1878 Mal neo male genital NEC",
                "threedigit": "187",
                "isLeaf": true
              },
              {
                "key": "1879",
                "title": "1879 Mal neo male genital NOS",
                "threedigit": "187",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of bladder",
            "title": "188 - Malignant neoplasm of bladder",
            "threedigit": "188",
            "children": [
              {
                "key": "1880",
                "title": "1880 Mal neo bladder-trigone",
                "threedigit": "188",
                "isLeaf": true
              },
              {
                "key": "1881",
                "title": "1881 Mal neo bladder-dome",
                "threedigit": "188",
                "isLeaf": true
              },
              {
                "key": "1882",
                "title": "1882 Mal neo bladder-lateral",
                "threedigit": "188",
                "isLeaf": true
              },
              {
                "key": "1883",
                "title": "1883 Mal neo bladder-anterior",
                "threedigit": "188",
                "isLeaf": true
              },
              {
                "key": "1884",
                "title": "1884 Mal neo bladder-post",
                "threedigit": "188",
                "isLeaf": true
              },
              {
                "key": "1885",
                "title": "1885 Mal neo bladder neck",
                "threedigit": "188",
                "isLeaf": true
              },
              {
                "key": "1886",
                "title": "1886 Mal neo ureteric orifice",
                "threedigit": "188",
                "isLeaf": true
              },
              {
                "key": "1887",
                "title": "1887 Malig neo urachus",
                "threedigit": "188",
                "isLeaf": true
              },
              {
                "key": "1888",
                "title": "1888 Malig neo bladder NEC",
                "threedigit": "188",
                "isLeaf": true
              },
              {
                "key": "1889",
                "title": "1889 Malig neo bladder NOS",
                "threedigit": "188",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of kidney and other and unspecified urinary organs",
            "title": "189 - Malignant neoplasm of kidney and other and unspecified urinary organs",
            "threedigit": "189",
            "children": [
              {
                "key": "1890",
                "title": "1890 Malig neopl kidney",
                "threedigit": "189",
                "isLeaf": true
              },
              {
                "key": "1891",
                "title": "1891 Malig neo renal pelvis",
                "threedigit": "189",
                "isLeaf": true
              },
              {
                "key": "1892",
                "title": "1892 Malign neopl ureter",
                "threedigit": "189",
                "isLeaf": true
              },
              {
                "key": "1893",
                "title": "1893 Malign neopl urethra",
                "threedigit": "189",
                "isLeaf": true
              },
              {
                "key": "1894",
                "title": "1894 Mal neo paraurethral",
                "threedigit": "189",
                "isLeaf": true
              },
              {
                "key": "1898",
                "title": "1898 Mal neo urinary NEC",
                "threedigit": "189",
                "isLeaf": true
              },
              {
                "key": "1899",
                "title": "1899 Mal neo urinary NOS",
                "threedigit": "189",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Malignant Neoplasm Of Other And Unspecified Sites",
        "title": "190-199 Malignant Neoplasm Of Other And Unspecified Sites",
        "threedigit": "190",
        "children": [
          {
            "key": "Malignant neoplasm of eye",
            "title": "190 - Malignant neoplasm of eye",
            "threedigit": "190",
            "children": [
              {
                "key": "1900",
                "title": "1900 Malign neopl eyeball",
                "threedigit": "190",
                "isLeaf": true
              },
              {
                "key": "1901",
                "title": "1901 Malign neopl orbit",
                "threedigit": "190",
                "isLeaf": true
              },
              {
                "key": "1902",
                "title": "1902 Mal neo lacrimal gland",
                "threedigit": "190",
                "isLeaf": true
              },
              {
                "key": "1903",
                "title": "1903 Mal neo conjunctiva",
                "threedigit": "190",
                "isLeaf": true
              },
              {
                "key": "1904",
                "title": "1904 Malign neopl cornea",
                "threedigit": "190",
                "isLeaf": true
              },
              {
                "key": "1905",
                "title": "1905 Malign neopl retina",
                "threedigit": "190",
                "isLeaf": true
              },
              {
                "key": "1906",
                "title": "1906 Malign neopl choroid",
                "threedigit": "190",
                "isLeaf": true
              },
              {
                "key": "1907",
                "title": "1907 Mal neo lacrimal duct",
                "threedigit": "190",
                "isLeaf": true
              },
              {
                "key": "1908",
                "title": "1908 Malign neopl eye NEC",
                "threedigit": "190",
                "isLeaf": true
              },
              {
                "key": "1909",
                "title": "1909 Malign neopl eye NOS",
                "threedigit": "190",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of brain",
            "title": "191 - Malignant neoplasm of brain",
            "threedigit": "191",
            "children": [
              {
                "key": "1910",
                "title": "1910 Malign neopl cerebrum",
                "threedigit": "191",
                "isLeaf": true
              },
              {
                "key": "1911",
                "title": "1911 Malig neo frontal lobe",
                "threedigit": "191",
                "isLeaf": true
              },
              {
                "key": "1912",
                "title": "1912 Mal neo temporal lobe",
                "threedigit": "191",
                "isLeaf": true
              },
              {
                "key": "1913",
                "title": "1913 Mal neo parietal lobe",
                "threedigit": "191",
                "isLeaf": true
              },
              {
                "key": "1914",
                "title": "1914 Mal neo occipital lobe",
                "threedigit": "191",
                "isLeaf": true
              },
              {
                "key": "1915",
                "title": "1915 Mal neo cereb ventricle",
                "threedigit": "191",
                "isLeaf": true
              },
              {
                "key": "1916",
                "title": "1916 Mal neo cerebellum NOS",
                "threedigit": "191",
                "isLeaf": true
              },
              {
                "key": "1917",
                "title": "1917 Mal neo brain stem",
                "threedigit": "191",
                "isLeaf": true
              },
              {
                "key": "1918",
                "title": "1918 Malig neo brain NEC",
                "threedigit": "191",
                "isLeaf": true
              },
              {
                "key": "1919",
                "title": "1919 Malig neo brain NOS",
                "threedigit": "191",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of other and unspecified parts of nervous system",
            "title": "192 - Malignant neoplasm of other and unspecified parts of nervous system",
            "threedigit": "192",
            "children": [
              {
                "key": "1920",
                "title": "1920 Mal neo cranial nerves",
                "threedigit": "192",
                "isLeaf": true
              },
              {
                "key": "1921",
                "title": "1921 Mal neo cerebral mening",
                "threedigit": "192",
                "isLeaf": true
              },
              {
                "key": "1922",
                "title": "1922 Mal neo spinal cord",
                "threedigit": "192",
                "isLeaf": true
              },
              {
                "key": "1923",
                "title": "1923 Mal neo spinal meninges",
                "threedigit": "192",
                "isLeaf": true
              },
              {
                "key": "1928",
                "title": "1928 Mal neo nervous syst NEC",
                "threedigit": "192",
                "isLeaf": true
              },
              {
                "key": "1929",
                "title": "1929 Mal neo nervous syst NOS",
                "threedigit": "192",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of thyroid gland",
            "title": "193 - Malignant neoplasm of thyroid gland",
            "threedigit": "193",
            "children": [
              {
                "key": "193",
                "title": "193 Malign neopl thyroid",
                "threedigit": "193",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of other endocrine glands and related structures",
            "title": "194 - Malignant neoplasm of other endocrine glands and related structures",
            "threedigit": "194",
            "children": [
              {
                "key": "1940",
                "title": "1940 Malign neopl adrenal",
                "threedigit": "194",
                "isLeaf": true
              },
              {
                "key": "1941",
                "title": "1941 Malig neo parathyroid",
                "threedigit": "194",
                "isLeaf": true
              },
              {
                "key": "1943",
                "title": "1943 Malig neo pituitary",
                "threedigit": "194",
                "isLeaf": true
              },
              {
                "key": "1944",
                "title": "1944 Malign neo pineal gland",
                "threedigit": "194",
                "isLeaf": true
              },
              {
                "key": "1945",
                "title": "1945 Mal neo carotid body",
                "threedigit": "194",
                "isLeaf": true
              },
              {
                "key": "1946",
                "title": "1946 Mal neo paraganglia NEC",
                "threedigit": "194",
                "isLeaf": true
              },
              {
                "key": "1948",
                "title": "1948 Mal neo endocrine NEC",
                "threedigit": "194",
                "isLeaf": true
              },
              {
                "key": "1949",
                "title": "1949 Mal neo endocrine NOS",
                "threedigit": "194",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm of other and ill-defined sites",
            "title": "195 - Malignant neoplasm of other and ill-defined sites",
            "threedigit": "195",
            "children": [
              {
                "key": "1950",
                "title": "1950 Mal neo head/face/neck",
                "threedigit": "195",
                "isLeaf": true
              },
              {
                "key": "1951",
                "title": "1951 Malign neopl thorax",
                "threedigit": "195",
                "isLeaf": true
              },
              {
                "key": "1952",
                "title": "1952 Malig neo abdomen",
                "threedigit": "195",
                "isLeaf": true
              },
              {
                "key": "1953",
                "title": "1953 Malign neopl pelvis",
                "threedigit": "195",
                "isLeaf": true
              },
              {
                "key": "1954",
                "title": "1954 Malign neopl arm",
                "threedigit": "195",
                "isLeaf": true
              },
              {
                "key": "1955",
                "title": "1955 Malign neopl leg",
                "threedigit": "195",
                "isLeaf": true
              },
              {
                "key": "1958",
                "title": "1958 Malig neo site NEC",
                "threedigit": "195",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Secondary and unspecified malignant neoplasm of lymph nodes",
            "title": "196 - Secondary and unspecified malignant neoplasm of lymph nodes",
            "threedigit": "196",
            "children": [
              {
                "key": "1960",
                "title": "1960 Mal neo lymph-head/neck",
                "threedigit": "196",
                "isLeaf": true
              },
              {
                "key": "1961",
                "title": "1961 Mal neo lymph-intrathor",
                "threedigit": "196",
                "isLeaf": true
              },
              {
                "key": "1962",
                "title": "1962 Mal neo lymph intra-abd",
                "threedigit": "196",
                "isLeaf": true
              },
              {
                "key": "1963",
                "title": "1963 Mal neo lymph-axilla/arm",
                "threedigit": "196",
                "isLeaf": true
              },
              {
                "key": "1965",
                "title": "1965 Mal neo lymph-inguin/leg",
                "threedigit": "196",
                "isLeaf": true
              },
              {
                "key": "1966",
                "title": "1966 Mal neo lymph-intrapelv",
                "threedigit": "196",
                "isLeaf": true
              },
              {
                "key": "1968",
                "title": "1968 Mal neo lymph node-mult",
                "threedigit": "196",
                "isLeaf": true
              },
              {
                "key": "1969",
                "title": "1969 Mal neo lymph node NOS",
                "threedigit": "196",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Secondary malignant neoplasm of respiratory and digestive systems",
            "title": "197 - Secondary malignant neoplasm of respiratory and digestive systems",
            "threedigit": "197",
            "children": [
              {
                "key": "1970",
                "title": "1970 Secondary malig neo lung",
                "threedigit": "197",
                "isLeaf": true
              },
              {
                "key": "1971",
                "title": "1971 Sec mal neo mediastinum",
                "threedigit": "197",
                "isLeaf": true
              },
              {
                "key": "1972",
                "title": "1972 Second malig neo pleura",
                "threedigit": "197",
                "isLeaf": true
              },
              {
                "key": "1973",
                "title": "1973 Sec malig neo resp NEC",
                "threedigit": "197",
                "isLeaf": true
              },
              {
                "key": "1974",
                "title": "1974 Sec malig neo sm bowel",
                "threedigit": "197",
                "isLeaf": true
              },
              {
                "key": "1975",
                "title": "1975 Sec malig neo lg bowel",
                "threedigit": "197",
                "isLeaf": true
              },
              {
                "key": "1976",
                "title": "1976 Sec mal neo peritoneum",
                "threedigit": "197",
                "isLeaf": true
              },
              {
                "key": "1977",
                "title": "1977 Second malig neo liver",
                "threedigit": "197",
                "isLeaf": true
              },
              {
                "key": "1978",
                "title": "1978 Sec mal neo GI NEC",
                "threedigit": "197",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Secondary malignant neoplasm of other specified sites",
            "title": "198 - Secondary malignant neoplasm of other specified sites",
            "threedigit": "198",
            "children": [
              {
                "key": "1980",
                "title": "1980 Second malig neo kidney",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "1981",
                "title": "1981 Sec malig neo urin NEC",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "1982",
                "title": "1982 Secondary malig neo skin",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "1983",
                "title": "1983 Sec mal neo brain/spine",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "1984",
                "title": "1984 Sec malig neo nerve NEC",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "1985",
                "title": "1985 Secondary malig neo bone",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "1986",
                "title": "1986 Second malig neo ovary",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "1987",
                "title": "1987 Second malig neo adrenal",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "19881",
                "title": "19881 Second malig neo breast",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "19882",
                "title": "19882 Second malig neo genital",
                "threedigit": "198",
                "isLeaf": true
              },
              {
                "key": "19889",
                "title": "19889 Secondary malig neo NEC",
                "threedigit": "198",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malignant neoplasm without specification of site",
            "title": "199 - Malignant neoplasm without specification of site",
            "threedigit": "199",
            "children": [
              {
                "key": "1990",
                "title": "1990 Malig neo disseminated",
                "threedigit": "199",
                "isLeaf": true
              },
              {
                "key": "1991",
                "title": "1991 Malignant neoplasm NOS",
                "threedigit": "199",
                "isLeaf": true
              },
              {
                "key": "1992",
                "title": "1992 Malig neopl-transp organ",
                "threedigit": "199",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Malignant Neoplasm Of Lymphatic And Hematopoietic Tissue",
        "title": "200-209 Malignant Neoplasm Of Lymphatic And Hematopoietic Tissue",
        "threedigit": "200",
        "children": [
          {
            "key": "Lymphosarcoma and reticulosarcoma and other specified malignant tumors of lymphatic tissue",
            "title": "200 - Lymphosarcoma and reticulosarcoma and other specified malignant tumors of lymphatic tissue",
            "threedigit": "200",
            "children": [
              {
                "key": "20000",
                "title": "20000 Retclsrc unsp xtrndl org",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20001",
                "title": "20001 Reticulosarcoma head",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20002",
                "title": "20002 Reticulosarcoma thorax",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20003",
                "title": "20003 Reticulosarcoma abdom",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20004",
                "title": "20004 Reticulosarcoma axilla",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20005",
                "title": "20005 Reticulosarcoma inguin",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20006",
                "title": "20006 Reticulosarcoma pelvic",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20007",
                "title": "20007 Reticulosarcoma spleen",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20008",
                "title": "20008 Reticulosarcoma mult",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20010",
                "title": "20010 Lymphsrc unsp xtrndl org",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20011",
                "title": "20011 Lymphosarcoma head",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20012",
                "title": "20012 Lymphosarcoma thorax",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20013",
                "title": "20013 Lymphosarcoma abdom",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20014",
                "title": "20014 Lymphosarcoma axilla",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20015",
                "title": "20015 Lymphosarcoma inguin",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20016",
                "title": "20016 Lymphosarcoma pelvic",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20017",
                "title": "20017 Lymphosarcoma spleen",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20018",
                "title": "20018 Lymphosarcoma mult",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20020",
                "title": "20020 Brkt tmr unsp xtrndl org",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20021",
                "title": "20021 Burkitt's tumor head",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20022",
                "title": "20022 Burkitt's tumor thorax",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20023",
                "title": "20023 Burkitt's tumor abdom",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20024",
                "title": "20024 Burkitt's tumor axilla",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20025",
                "title": "20025 Burkitt's tumor inguin",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20026",
                "title": "20026 Burkitt's tumor pelvic",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20027",
                "title": "20027 Burkitt's tumor spleen",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20028",
                "title": "20028 Burkitt's tumor mult",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20030",
                "title": "20030 Margnl zone lym xtrndl",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20031",
                "title": "20031 Margin zone lym head",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20032",
                "title": "20032 Margin zone lym thorax",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20033",
                "title": "20033 Margin zone lym abdom",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20034",
                "title": "20034 Margin zone lym axilla",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20035",
                "title": "20035 Margin zone lym inguin",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20036",
                "title": "20036 Margin zone lym pelvic",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20037",
                "title": "20037 Margin zone lymph spleen",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20038",
                "title": "20038 Margin zone lymph multip",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20040",
                "title": "20040 Mantle cell lym xtrrndl",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20041",
                "title": "20041 Mantle cell lymph head",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20042",
                "title": "20042 Mantle cell lymph thorax",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20043",
                "title": "20043 Mantle cell lymph abdom",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20044",
                "title": "20044 Mantle cell lymph axilla",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20045",
                "title": "20045 Mantle cell lymph inguin",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20046",
                "title": "20046 Mantle cell lymph pelvic",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20047",
                "title": "20047 Mantle cell lymph spleen",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20048",
                "title": "20048 Mantle cell lymph multip",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20050",
                "title": "20050 Primary CNS lymph xtrndl",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20051",
                "title": "20051 Primary CNS lymph head",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20052",
                "title": "20052 Primary CNS lymph thorax",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20053",
                "title": "20053 Primary CNS lymph abdom",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20054",
                "title": "20054 Primary CNS lymph axilla",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20055",
                "title": "20055 Primary CNS lym inguin",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20056",
                "title": "20056 Primary CNS lymph pelvic",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20057",
                "title": "20057 Primary CNS lymph spleen",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20058",
                "title": "20058 Primary CNS lymph multip",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20060",
                "title": "20060 Anaplastic lymph xtrndl",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20061",
                "title": "20061 Anaplastic lymph head",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20062",
                "title": "20062 Anaplastic lymph thorax",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20063",
                "title": "20063 Anaplastic lymph abdom",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20064",
                "title": "20064 Anaplastic lymph axilla",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20065",
                "title": "20065 Anaplastic lymph inguin",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20066",
                "title": "20066 Anaplastic lymph pelvic",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20067",
                "title": "20067 Anaplastic lymph spleen",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20068",
                "title": "20068 Anaplastic lymph multip",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20070",
                "title": "20070 Large cell lymph xtrndl",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20071",
                "title": "20071 Large cell lymphoma head",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20072",
                "title": "20072 Large cell lymph thorax",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20073",
                "title": "20073 Large cell lymph abdom",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20074",
                "title": "20074 Large cell lymph axilla",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20075",
                "title": "20075 Large cell lymph inguin",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20076",
                "title": "20076 Large cell lymph pelvic",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20077",
                "title": "20077 Large cell lymph spleen",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20078",
                "title": "20078 Large cell lymph multip",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20080",
                "title": "20080 Oth varn unsp xtrndl org",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20081",
                "title": "20081 Mixed lymphosarc head",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20082",
                "title": "20082 Mixed lymphosarc thorax",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20083",
                "title": "20083 Mixed lymphosarc abdom",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20084",
                "title": "20084 Mixed lymphosarc axilla",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20085",
                "title": "20085 Mixed lymphosarc inguin",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20086",
                "title": "20086 Mixed lymphosarc pelvic",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20087",
                "title": "20087 Mixed lymphosarc spleen",
                "threedigit": "200",
                "isLeaf": true
              },
              {
                "key": "20088",
                "title": "20088 Mixed lymphosarc mult",
                "threedigit": "200",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hodgkin's disease",
            "title": "201 - Hodgkin's disease",
            "threedigit": "201",
            "children": [
              {
                "key": "20100",
                "title": "20100 Hdgk prg unsp xtrndl org",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20101",
                "title": "20101 Hodgkins paragran head",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20102",
                "title": "20102 Hodgkins paragran thorax",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20103",
                "title": "20103 Hodgkins paragran abdom",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20104",
                "title": "20104 Hodgkins paragran axilla",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20105",
                "title": "20105 Hodgkins paragran inguin",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20106",
                "title": "20106 Hodgkins paragran pelvic",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20107",
                "title": "20107 Hodgkins paragran spleen",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20108",
                "title": "20108 Hodgkins paragran mult",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20110",
                "title": "20110 Hdgk grn unsp xtrndl org",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20111",
                "title": "20111 Hodgkins granulom head",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20112",
                "title": "20112 Hodgkins granulom thorax",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20113",
                "title": "20113 Hodgkins granulom abdom",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20114",
                "title": "20114 Hodgkins granulom axilla",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20115",
                "title": "20115 Hodgkins granulom inguin",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20116",
                "title": "20116 Hodgkins granulom pelvic",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20117",
                "title": "20117 Hodgkins granulom spleen",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20118",
                "title": "20118 Hodgkins granulom mult",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20120",
                "title": "20120 Hdgk src unsp xtrndl org",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20121",
                "title": "20121 Hodgkins sarcoma head",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20122",
                "title": "20122 Hodgkins sarcoma thorax",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20123",
                "title": "20123 Hodgkins sarcoma abdom",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20124",
                "title": "20124 Hodgkins sarcoma axilla",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20125",
                "title": "20125 Hodgkins sarcoma inguin",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20126",
                "title": "20126 Hodgkins sarcoma pelvic",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20127",
                "title": "20127 Hodgkins sarcoma spleen",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20128",
                "title": "20128 Hodgkins sarcoma mult",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20140",
                "title": "20140 Lym-hst unsp xtrndl orgn",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20141",
                "title": "20141 Hodg lymph-histio head",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20142",
                "title": "20142 Hodg lymph-histio thorax",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20143",
                "title": "20143 Hodg lymph-histio abdom",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20144",
                "title": "20144 Hodg lymph-histio axilla",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20145",
                "title": "20145 Hodg lymph-histio inguin",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20146",
                "title": "20146 Hodg lymph-histio pelvic",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20147",
                "title": "20147 Hodg lymph-histio spleen",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20148",
                "title": "20148 Hodg lymph-histio mult",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20150",
                "title": "20150 Ndr sclr unsp xtrndl org",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20151",
                "title": "20151 Hodg nodul sclero head",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20152",
                "title": "20152 Hodg nodul sclero thorax",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20153",
                "title": "20153 Hodg nodul sclero abdom",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20154",
                "title": "20154 Hodg nodul sclero axilla",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20155",
                "title": "20155 Hodg nodul sclero inguin",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20156",
                "title": "20156 Hodg nodul sclero pelvic",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20157",
                "title": "20157 Hodg nodul sclero spleen",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20158",
                "title": "20158 Hodg nodul sclero mult",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20160",
                "title": "20160 Mxd celr unsp xtrndl org",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20161",
                "title": "20161 Hodgkins mix cell head",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20162",
                "title": "20162 Hodgkins mix cell thorax",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20163",
                "title": "20163 Hodgkins mix cell abdom",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20164",
                "title": "20164 Hodgkins mix cell axilla",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20165",
                "title": "20165 Hodgkins mix cell inguin",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20166",
                "title": "20166 Hodgkins mix cell pelvic",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20167",
                "title": "20167 Hodgkins mix cell spleen",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20168",
                "title": "20168 Hodgkins mix cell mult",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20170",
                "title": "20170 Lym dplt unsp xtrndl org",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20171",
                "title": "20171 Hodg lymph deplet head",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20172",
                "title": "20172 Hodg lymph deplet thorax",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20173",
                "title": "20173 Hodg lymph deplet abdom",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20174",
                "title": "20174 Hodg lymph deplet axilla",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20175",
                "title": "20175 Hodg lymph deplet inguin",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20176",
                "title": "20176 Hodg lymph deplet pelvic",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20177",
                "title": "20177 Hodg lymph deplet spleen",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20178",
                "title": "20178 Hodg lymph deplet mult",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20190",
                "title": "20190 Hdgk dis unsp xtrndl org",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20191",
                "title": "20191 Hodgkins dis NOS head",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20192",
                "title": "20192 Hodgkins dis NOS thorax",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20193",
                "title": "20193 Hodgkins dis NOS abdom",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20194",
                "title": "20194 Hodgkins dis NOS axilla",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20195",
                "title": "20195 Hodgkins dis NOS inguin",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20196",
                "title": "20196 Hodgkins dis NOS pelvic",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20197",
                "title": "20197 Hodgkins dis NOS spleen",
                "threedigit": "201",
                "isLeaf": true
              },
              {
                "key": "20198",
                "title": "20198 Hodgkins dis NOS mult",
                "threedigit": "201",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other malignant neoplasms of lymphoid and histiocytic tissue",
            "title": "202 - Other malignant neoplasms of lymphoid and histiocytic tissue",
            "threedigit": "202",
            "children": [
              {
                "key": "20200",
                "title": "20200 Ndlr lym unsp xtrndl org",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20201",
                "title": "20201 Nodular lymphoma head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20202",
                "title": "20202 Nodular lymphoma thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20203",
                "title": "20203 Nodular lymphoma abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20204",
                "title": "20204 Nodular lymphoma axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20205",
                "title": "20205 Nodular lymphoma inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20206",
                "title": "20206 Nodular lymphoma pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20207",
                "title": "20207 Nodular lymphoma spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20208",
                "title": "20208 Nodular lymphoma mult",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20210",
                "title": "20210 Mycs fng unsp xtrndl org",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20211",
                "title": "20211 Mycosis fungoides head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20212",
                "title": "20212 Mycosis fungoides thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20213",
                "title": "20213 Mycosis fungoides abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20214",
                "title": "20214 Mycosis fungoides axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20215",
                "title": "20215 Mycosis fungoides inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20216",
                "title": "20216 Mycosis fungoides pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20217",
                "title": "20217 Mycosis fungoides spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20218",
                "title": "20218 Mycosis fungoides mult",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20220",
                "title": "20220 Szry dis unsp xtrndl org",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20221",
                "title": "20221 Sezary's disease head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20222",
                "title": "20222 Sezary's disease thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20223",
                "title": "20223 Sezary's disease abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20224",
                "title": "20224 Sezary's disease axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20225",
                "title": "20225 Sezary's disease inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20226",
                "title": "20226 Sezary's disease pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20227",
                "title": "20227 Sezary's disease spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20228",
                "title": "20228 Sezary's disease mult",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20230",
                "title": "20230 Mlg hist unsp xtrndl org",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20231",
                "title": "20231 Mal histiocytosis head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20232",
                "title": "20232 Mal histiocytosis thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20233",
                "title": "20233 Mal histiocytosis abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20234",
                "title": "20234 Mal histiocytosis axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20235",
                "title": "20235 Mal histiocytosis inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20236",
                "title": "20236 Mal histiocytosis pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20237",
                "title": "20237 Mal histiocytosis spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20238",
                "title": "20238 Mal histiocytosis mult",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20240",
                "title": "20240 Lk rtctl unsp xtrndl org",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20241",
                "title": "20241 Hairy-cell leukem head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20242",
                "title": "20242 Hairy-cell leukem thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20243",
                "title": "20243 Hairy-cell leukem abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20244",
                "title": "20244 Hairy-cell leukem axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20245",
                "title": "20245 Hairy-cell leukem inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20246",
                "title": "20246 Hairy-cell leukem pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20247",
                "title": "20247 Hairy-cell leukem spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20248",
                "title": "20248 Hairy-cell leukem mult",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20250",
                "title": "20250 Ltr-siwe unsp xtrndl org",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20251",
                "title": "20251 Letterer-siwe dis head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20252",
                "title": "20252 Letterer-siwe dis thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20253",
                "title": "20253 Letterer-siwe dis abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20254",
                "title": "20254 Letterer-siwe dis axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20255",
                "title": "20255 Letterer-siwe dis inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20256",
                "title": "20256 Letterer-siwe dis pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20257",
                "title": "20257 Letterer-siwe dis spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20258",
                "title": "20258 Letterer-siwe dis mult",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20260",
                "title": "20260 Mlg mast unsp xtrndl org",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20261",
                "title": "20261 Mal mastocytosis head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20262",
                "title": "20262 Mal mastocytosis thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20263",
                "title": "20263 Mal mastocytosis abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20264",
                "title": "20264 Mal mastocytosis axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20265",
                "title": "20265 Mal mastocytosis inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20266",
                "title": "20266 Mal mastocytosis pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20267",
                "title": "20267 Mal mastocytosis spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20268",
                "title": "20268 Mal mastocytosis mult",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20270",
                "title": "20270 Periph T cell lym xtrndl",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20271",
                "title": "20271 Periph T cell lymph head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20272",
                "title": "20272 Periph T cell lym thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20273",
                "title": "20273 Periph T cell lym abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20274",
                "title": "20274 Periph T cell lym axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20275",
                "title": "20275 Periph T cell lym inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20276",
                "title": "20276 Periph T cell lym pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20277",
                "title": "20277 Periph T cell lym spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20278",
                "title": "20278 Periph T cell lym multip",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20280",
                "title": "20280 Oth lymp unsp xtrndl org",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20281",
                "title": "20281 Lymphomas NEC head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20282",
                "title": "20282 Lymphomas NEC thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20283",
                "title": "20283 Lymphomas NEC abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20284",
                "title": "20284 Lymphomas NEC axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20285",
                "title": "20285 Lymphomas NEC inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20286",
                "title": "20286 Lymphomas NEC pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20287",
                "title": "20287 Lymphomas NEC spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20288",
                "title": "20288 Lymphomas NEC mult",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20290",
                "title": "20290 Unsp lym unsp xtrndl org",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20291",
                "title": "20291 Lymphoid mal NEC head",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20292",
                "title": "20292 Lymphoid mal NEC thorax",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20293",
                "title": "20293 Lymphoid mal NEC abdom",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20294",
                "title": "20294 Lymphoid mal NEC axilla",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20295",
                "title": "20295 Lymphoid mal NEC inguin",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20296",
                "title": "20296 Lymphoid mal NEC pelvic",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20297",
                "title": "20297 Lymphoid mal NEC spleen",
                "threedigit": "202",
                "isLeaf": true
              },
              {
                "key": "20298",
                "title": "20298 Lymphoid mal NEC mult",
                "threedigit": "202",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Multiple myeloma and immunoproliferative neoplasms",
            "title": "203 - Multiple myeloma and immunoproliferative neoplasms",
            "threedigit": "203",
            "children": [
              {
                "key": "20300",
                "title": "20300 Mult mye w/o achv rmson",
                "threedigit": "203",
                "isLeaf": true
              },
              {
                "key": "20301",
                "title": "20301 Mult myelm w remission",
                "threedigit": "203",
                "isLeaf": true
              },
              {
                "key": "20302",
                "title": "20302 Mult myeloma in relapse",
                "threedigit": "203",
                "isLeaf": true
              },
              {
                "key": "20310",
                "title": "20310 Pls cl leu w/o achv rmsn",
                "threedigit": "203",
                "isLeaf": true
              },
              {
                "key": "20311",
                "title": "20311 Plsm cell leuk w rmson",
                "threedigit": "203",
                "isLeaf": true
              },
              {
                "key": "20312",
                "title": "20312 Plsm cel leuk in relapse",
                "threedigit": "203",
                "isLeaf": true
              },
              {
                "key": "20380",
                "title": "20380 Oth imno npl wo ach rmsn",
                "threedigit": "203",
                "isLeaf": true
              },
              {
                "key": "20381",
                "title": "20381 Oth imnprfl npl w rmsn",
                "threedigit": "203",
                "isLeaf": true
              },
              {
                "key": "20382",
                "title": "20382 Oth imnprlf neo-relapse",
                "threedigit": "203",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Lymphoid leukemia",
            "title": "204 - Lymphoid leukemia",
            "threedigit": "204",
            "children": [
              {
                "key": "20400",
                "title": "20400 Ac lym leuk wo achv rmsn",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20401",
                "title": "20401 Act lym leuk w rmsion",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20402",
                "title": "20402 Act lymp leuk in relapse",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20410",
                "title": "20410 Ch lym leuk wo achv rmsn",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20411",
                "title": "20411 Chr lym leuk w rmsion",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20412",
                "title": "20412 Chr lymp leuk in relapse",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20420",
                "title": "20420 Sbac lym leu wo ach rmsn",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20421",
                "title": "20421 Sbac lym leuk w rmsion",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20422",
                "title": "20422 Sbac lym leuk in relapse",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20480",
                "title": "20480 Oth lym leu wo achv rmsn",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20481",
                "title": "20481 Oth lym leuk w rmsion",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20482",
                "title": "20482 Oth lym leuk in relapse",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20490",
                "title": "20490 Uns lym leu wo ach rmsn",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20491",
                "title": "20491 Uns lym leuk w rmsion",
                "threedigit": "204",
                "isLeaf": true
              },
              {
                "key": "20492",
                "title": "20492 Lymp leuk NOS relapse",
                "threedigit": "204",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Myeloid leukemia",
            "title": "205 - Myeloid leukemia",
            "threedigit": "205",
            "children": [
              {
                "key": "20500",
                "title": "20500 Ac myl leuk wo achv rmsn",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20501",
                "title": "20501 Act myl leuk w rmsion",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20502",
                "title": "20502 Act myel leuk in relapse",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20510",
                "title": "20510 Ch myl leuk wo achv rmsn",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20511",
                "title": "20511 Chr myl leuk w rmsion",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20512",
                "title": "20512 Chr myel leuk in relapse",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20520",
                "title": "20520 Sbac myl leu wo ach rmsn",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20521",
                "title": "20521 Sbac myl leuk w rmsion",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20522",
                "title": "20522 Sbac myl leuk in relapse",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20530",
                "title": "20530 Myl sarcoma wo achv rmsn",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20531",
                "title": "20531 Myl srcoma w rmsion",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20532",
                "title": "20532 Myel sarcoma in relapse",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20580",
                "title": "20580 Oth my leuk wo achv rmsn",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20581",
                "title": "20581 Oth myl leuk w rmsion",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20582",
                "title": "20582 Oth myel leuk in relapse",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20590",
                "title": "20590 Uns my leu wo ach rmsn",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20591",
                "title": "20591 Uns myl leuk w rmsion",
                "threedigit": "205",
                "isLeaf": true
              },
              {
                "key": "20592",
                "title": "20592 Myel leuk NOS in relapse",
                "threedigit": "205",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Monocytic leukemia",
            "title": "206 - Monocytic leukemia",
            "threedigit": "206",
            "children": [
              {
                "key": "20600",
                "title": "20600 Ac mono leu wo achv rmsn",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20601",
                "title": "20601 Act mono leuk w rmsion",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20602",
                "title": "20602 Act mono leuk in relapse",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20610",
                "title": "20610 Ch mono leu wo achv rmsn",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20611",
                "title": "20611 Chr mono leuk w rmsion",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20612",
                "title": "20612 Chr mono leuk in relapse",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20620",
                "title": "20620 Sbac mno leu wo ach rmsn",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20621",
                "title": "20621 Sbac mono leuk w rmsion",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20622",
                "title": "20622 Sbac mono leu in relapse",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20680",
                "title": "20680 Ot mono leu wo achv rmsn",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20681",
                "title": "20681 Oth mono leuk w rmsion",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20682",
                "title": "20682 Oth mono leuk in relapse",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20690",
                "title": "20690 Uns mno leu wo ach rmsn",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20691",
                "title": "20691 Uns mono leuk w rmsion",
                "threedigit": "206",
                "isLeaf": true
              },
              {
                "key": "20692",
                "title": "20692 Mono leuk NOS relapse",
                "threedigit": "206",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other specified leukemia",
            "title": "207 - Other specified leukemia",
            "threedigit": "207",
            "children": [
              {
                "key": "20700",
                "title": "20700 Ac erth/erlk wo ach rmsn",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20701",
                "title": "20701 Act erth/erylk w rmson",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20702",
                "title": "20702 Ac erth/erylk in relapse",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20710",
                "title": "20710 Chr erythrm w/o ach rmsn",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20711",
                "title": "20711 Chr erythrm w remision",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20712",
                "title": "20712 Chr erythrmia in relapse",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20720",
                "title": "20720 Mgkrcyt leuk wo ach rmsn",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20721",
                "title": "20721 Mgkrycyt leuk w rmsion",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20722",
                "title": "20722 Mgkrycyt leuk in relapse",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20780",
                "title": "20780 Oth leuk w/o achv rmsn",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20781",
                "title": "20781 Oth spf leuk w remsion",
                "threedigit": "207",
                "isLeaf": true
              },
              {
                "key": "20782",
                "title": "20782 Oth spf leuk in relapse",
                "threedigit": "207",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Leukemia of unspecified cell type",
            "title": "208 - Leukemia of unspecified cell type",
            "threedigit": "208",
            "children": [
              {
                "key": "20800",
                "title": "20800 Ac leu un cl wo ach rmsn",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20801",
                "title": "20801 Act leuk uns cl w rmson",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20802",
                "title": "20802 Ac leuk uns cl relapse",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20810",
                "title": "20810 Ch leu un cl wo ach rmsn",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20811",
                "title": "20811 Chr leuk uns cl w rmson",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20812",
                "title": "20812 Ch leu uns cl in relapse",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20820",
                "title": "20820 Sbc leu un cl wo ah rmsn",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20821",
                "title": "20821 Sbac leuk uns cl w rmson",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20822",
                "title": "20822 Sbac leu uns cl-relapse",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20880",
                "title": "20880 Ot leu un cl wo ach rmsn",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20881",
                "title": "20881 Oth leuk uns cl w rmson",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20882",
                "title": "20882 Oth leuk uns cl-relapse",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20890",
                "title": "20890 Leuk NOS w/o achv rmsn",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20891",
                "title": "20891 Leukemia NOS w remission",
                "threedigit": "208",
                "isLeaf": true
              },
              {
                "key": "20892",
                "title": "20892 Leukemia NOS in relapse",
                "threedigit": "208",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Neuroendocrine tumors",
            "title": "209 - Neuroendocrine tumors",
            "threedigit": "209",
            "children": [
              {
                "key": "20900",
                "title": "20900 Mal crcnoid sm intst NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20901",
                "title": "20901 Malig carcinoid duodenum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20902",
                "title": "20902 Malig carcinoid jejunum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20903",
                "title": "20903 Malig carcinoid ileum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20910",
                "title": "20910 Mal crcnoid lg intst NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20911",
                "title": "20911 Malig carcinoid appendix",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20912",
                "title": "20912 Malig carcinoid cecum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20913",
                "title": "20913 Mal crcnoid ascend colon",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20914",
                "title": "20914 Mal crcnoid transv colon",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20915",
                "title": "20915 Mal carcinoid desc colon",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20916",
                "title": "20916 Mal carcinoid sig colon",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20917",
                "title": "20917 Malig carcinoid rectum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20920",
                "title": "20920 Mal crcnd prim site unkn",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20921",
                "title": "20921 Mal carcinoid bronc/lung",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20922",
                "title": "20922 Malig carcinoid thymus",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20923",
                "title": "20923 Malig carcinoid stomach",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20924",
                "title": "20924 Malig carcinoid kidney",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20925",
                "title": "20925 Mal carcnoid foregut NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20926",
                "title": "20926 Mal carcinoid midgut NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20927",
                "title": "20927 Mal carcnoid hindgut NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20929",
                "title": "20929 Malig carcinoid oth site",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20930",
                "title": "20930 Malig neuroendo ca NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20931",
                "title": "20931 Merkel cell ca-face",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20932",
                "title": "20932 Merkel cell ca-sclp/neck",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20933",
                "title": "20933 Merkel cell ca-up limb",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20934",
                "title": "20934 Merkel cell ca-low limb",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20935",
                "title": "20935 Merkel cell ca-trunk",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20936",
                "title": "20936 Merkel cell ca-oth sites",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20940",
                "title": "20940 Ben crcnoid sm intst NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20941",
                "title": "20941 Ben carcinoid duodenum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20942",
                "title": "20942 Benign carcinoid jejunum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20943",
                "title": "20943 Benign carcinoid ileum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20950",
                "title": "20950 Ben crcnoid lg intst NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20951",
                "title": "20951 Ben carcinoid appendix",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20952",
                "title": "20952 Benign carcinoid cecum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20953",
                "title": "20953 Ben carcinoid asc colon",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20954",
                "title": "20954 Ben crcinoid trans colon",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20955",
                "title": "20955 Ben carcinoid desc colon",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20956",
                "title": "20956 Ben carcinoid sig colon",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20957",
                "title": "20957 Benign carcinoid rectum",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20960",
                "title": "20960 Ben crcnd prim site unkn",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20961",
                "title": "20961 Ben carcinoid bronc/lung",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20962",
                "title": "20962 Benign carcinoid thymus",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20963",
                "title": "20963 Benign carcinoid stomach",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20964",
                "title": "20964 Benign carcinoid kidney",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20965",
                "title": "20965 Ben crcinoid foregut NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20966",
                "title": "20966 Ben crcinoid midgut NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20967",
                "title": "20967 Ben crcnoid hindgut NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20969",
                "title": "20969 Bengn carcinoid oth site",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20970",
                "title": "20970 Sec neuroendo tumor NOS",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20971",
                "title": "20971 Sec neuroend tu dist lym",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20972",
                "title": "20972 Sec neuroend tumor-liver",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20973",
                "title": "20973 Sec neuroendo tumor-bone",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20974",
                "title": "20974 Sec neuroendo tu-periton",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20975",
                "title": "20975 Secondary Merkel cell ca",
                "threedigit": "209",
                "isLeaf": true
              },
              {
                "key": "20979",
                "title": "20979 Sec neuroend tu oth site",
                "threedigit": "209",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Benign Neoplasms",
        "title": "210-229 Benign Neoplasms",
        "threedigit": "210",
        "children": [
          {
            "key": "Benign neoplasm of lip oral cavity and pharynx",
            "title": "210 - Benign neoplasm of lip oral cavity and pharynx",
            "threedigit": "210",
            "children": [
              {
                "key": "2100",
                "title": "2100 Benign neoplasm lip",
                "threedigit": "210",
                "isLeaf": true
              },
              {
                "key": "2101",
                "title": "2101 Benign neoplasm tongue",
                "threedigit": "210",
                "isLeaf": true
              },
              {
                "key": "2102",
                "title": "2102 Ben neo major salivary",
                "threedigit": "210",
                "isLeaf": true
              },
              {
                "key": "2103",
                "title": "2103 Benign neo mouth floor",
                "threedigit": "210",
                "isLeaf": true
              },
              {
                "key": "2104",
                "title": "2104 Benign neo mouth NEC/NOS",
                "threedigit": "210",
                "isLeaf": true
              },
              {
                "key": "2105",
                "title": "2105 Benign neoplasm tonsil",
                "threedigit": "210",
                "isLeaf": true
              },
              {
                "key": "2106",
                "title": "2106 Benign neo oropharyn NEC",
                "threedigit": "210",
                "isLeaf": true
              },
              {
                "key": "2107",
                "title": "2107 Benign neo nasopharynx",
                "threedigit": "210",
                "isLeaf": true
              },
              {
                "key": "2108",
                "title": "2108 Benign neo hypopharynx",
                "threedigit": "210",
                "isLeaf": true
              },
              {
                "key": "2109",
                "title": "2109 Benign neo pharynx NOS",
                "threedigit": "210",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of other parts of digestive system",
            "title": "211 - Benign neoplasm of other parts of digestive system",
            "threedigit": "211",
            "children": [
              {
                "key": "2110",
                "title": "2110 Benign neo esophagus",
                "threedigit": "211",
                "isLeaf": true
              },
              {
                "key": "2111",
                "title": "2111 Benign neoplasm stomach",
                "threedigit": "211",
                "isLeaf": true
              },
              {
                "key": "2112",
                "title": "2112 Benign neoplasm sm bowel",
                "threedigit": "211",
                "isLeaf": true
              },
              {
                "key": "2113",
                "title": "2113 Benign neoplasm lg bowel",
                "threedigit": "211",
                "isLeaf": true
              },
              {
                "key": "2114",
                "title": "2114 Benign neopl rectum/anus",
                "threedigit": "211",
                "isLeaf": true
              },
              {
                "key": "2115",
                "title": "2115 Ben neo liver/bile ducts",
                "threedigit": "211",
                "isLeaf": true
              },
              {
                "key": "2116",
                "title": "2116 Benign neoplasm pancreas",
                "threedigit": "211",
                "isLeaf": true
              },
              {
                "key": "2117",
                "title": "2117 Ben neo islets langerhan",
                "threedigit": "211",
                "isLeaf": true
              },
              {
                "key": "2118",
                "title": "2118 Ben neo peritoneum",
                "threedigit": "211",
                "isLeaf": true
              },
              {
                "key": "2119",
                "title": "2119 Ben neo GI tract NEC/NOS",
                "threedigit": "211",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of respiratory and intrathoracic organs",
            "title": "212 - Benign neoplasm of respiratory and intrathoracic organs",
            "threedigit": "212",
            "children": [
              {
                "key": "2120",
                "title": "2120 Ben neo nasal cav/sinus",
                "threedigit": "212",
                "isLeaf": true
              },
              {
                "key": "2121",
                "title": "2121 Benign neo larynx",
                "threedigit": "212",
                "isLeaf": true
              },
              {
                "key": "2122",
                "title": "2122 Benign neo trachea",
                "threedigit": "212",
                "isLeaf": true
              },
              {
                "key": "2123",
                "title": "2123 Benign neo bronchus/lung",
                "threedigit": "212",
                "isLeaf": true
              },
              {
                "key": "2124",
                "title": "2124 Benign neoplasm pleura",
                "threedigit": "212",
                "isLeaf": true
              },
              {
                "key": "2125",
                "title": "2125 Benign neo mediastinum",
                "threedigit": "212",
                "isLeaf": true
              },
              {
                "key": "2126",
                "title": "2126 Benign neoplasm thymus",
                "threedigit": "212",
                "isLeaf": true
              },
              {
                "key": "2127",
                "title": "2127 Benign neoplasm heart",
                "threedigit": "212",
                "isLeaf": true
              },
              {
                "key": "2128",
                "title": "2128 Benign neo resp sys NEC",
                "threedigit": "212",
                "isLeaf": true
              },
              {
                "key": "2129",
                "title": "2129 Benign neo resp sys NOS",
                "threedigit": "212",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of bone and articular cartilage",
            "title": "213 - Benign neoplasm of bone and articular cartilage",
            "threedigit": "213",
            "children": [
              {
                "key": "2130",
                "title": "2130 Ben neo skull/face bone",
                "threedigit": "213",
                "isLeaf": true
              },
              {
                "key": "2131",
                "title": "2131 Ben neo lower jaw bone",
                "threedigit": "213",
                "isLeaf": true
              },
              {
                "key": "2132",
                "title": "2132 Benign neo vertebrae",
                "threedigit": "213",
                "isLeaf": true
              },
              {
                "key": "2133",
                "title": "2133 Ben neo ribs/stern/clav",
                "threedigit": "213",
                "isLeaf": true
              },
              {
                "key": "2134",
                "title": "2134 Ben neo long bones arm",
                "threedigit": "213",
                "isLeaf": true
              },
              {
                "key": "2135",
                "title": "2135 Ben neo bones wrist/hand",
                "threedigit": "213",
                "isLeaf": true
              },
              {
                "key": "2136",
                "title": "2136 Benign neo pelvic girdle",
                "threedigit": "213",
                "isLeaf": true
              },
              {
                "key": "2137",
                "title": "2137 Ben neo long bones leg",
                "threedigit": "213",
                "isLeaf": true
              },
              {
                "key": "2138",
                "title": "2138 Ben neo bones ankle/foot",
                "threedigit": "213",
                "isLeaf": true
              },
              {
                "key": "2139",
                "title": "2139 Benign neo bone NOS",
                "threedigit": "213",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Lipoma",
            "title": "214 - Lipoma",
            "threedigit": "214",
            "children": [
              {
                "key": "2140",
                "title": "2140 Lipoma skin face",
                "threedigit": "214",
                "isLeaf": true
              },
              {
                "key": "2141",
                "title": "2141 Lipoma skin NEC",
                "threedigit": "214",
                "isLeaf": true
              },
              {
                "key": "2142",
                "title": "2142 Lipoma intrathoracic",
                "threedigit": "214",
                "isLeaf": true
              },
              {
                "key": "2143",
                "title": "2143 Lipoma intra-abdominal",
                "threedigit": "214",
                "isLeaf": true
              },
              {
                "key": "2144",
                "title": "2144 Lipoma spermatic cord",
                "threedigit": "214",
                "isLeaf": true
              },
              {
                "key": "2148",
                "title": "2148 Lipoma NEC",
                "threedigit": "214",
                "isLeaf": true
              },
              {
                "key": "2149",
                "title": "2149 Lipoma NOS",
                "threedigit": "214",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other benign neoplasm of connective and other soft tissue",
            "title": "215 - Other benign neoplasm of connective and other soft tissue",
            "threedigit": "215",
            "children": [
              {
                "key": "2150",
                "title": "2150 Ben neo soft tissue head",
                "threedigit": "215",
                "isLeaf": true
              },
              {
                "key": "2152",
                "title": "2152 Ben neo soft tissue arm",
                "threedigit": "215",
                "isLeaf": true
              },
              {
                "key": "2153",
                "title": "2153 Ben neo soft tissue leg",
                "threedigit": "215",
                "isLeaf": true
              },
              {
                "key": "2154",
                "title": "2154 Ben neo soft tis thorax",
                "threedigit": "215",
                "isLeaf": true
              },
              {
                "key": "2155",
                "title": "2155 Ben neo soft tis abdomen",
                "threedigit": "215",
                "isLeaf": true
              },
              {
                "key": "2156",
                "title": "2156 Ben neo soft tis pelvis",
                "threedigit": "215",
                "isLeaf": true
              },
              {
                "key": "2157",
                "title": "2157 Benign neo trunk NOS",
                "threedigit": "215",
                "isLeaf": true
              },
              {
                "key": "2158",
                "title": "2158 Ben neo soft tissue NEC",
                "threedigit": "215",
                "isLeaf": true
              },
              {
                "key": "2159",
                "title": "2159 Ben neo soft tissue NOS",
                "threedigit": "215",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of skin",
            "title": "216 - Benign neoplasm of skin",
            "threedigit": "216",
            "children": [
              {
                "key": "2160",
                "title": "2160 Benign neo skin lip",
                "threedigit": "216",
                "isLeaf": true
              },
              {
                "key": "2161",
                "title": "2161 Benign neo skin eyelid",
                "threedigit": "216",
                "isLeaf": true
              },
              {
                "key": "2162",
                "title": "2162 Benign neo skin ear",
                "threedigit": "216",
                "isLeaf": true
              },
              {
                "key": "2163",
                "title": "2163 Benign neo skin face NEC",
                "threedigit": "216",
                "isLeaf": true
              },
              {
                "key": "2164",
                "title": "2164 Ben neo scalp/skin neck",
                "threedigit": "216",
                "isLeaf": true
              },
              {
                "key": "2165",
                "title": "2165 Benign neo skin trunk",
                "threedigit": "216",
                "isLeaf": true
              },
              {
                "key": "2166",
                "title": "2166 Benign neo skin arm",
                "threedigit": "216",
                "isLeaf": true
              },
              {
                "key": "2167",
                "title": "2167 Benign neo skin leg",
                "threedigit": "216",
                "isLeaf": true
              },
              {
                "key": "2168",
                "title": "2168 Benign neoplasm skin NEC",
                "threedigit": "216",
                "isLeaf": true
              },
              {
                "key": "2169",
                "title": "2169 Benign neoplasm skin NOS",
                "threedigit": "216",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of breast",
            "title": "217 - Benign neoplasm of breast",
            "threedigit": "217",
            "children": [
              {
                "key": "217",
                "title": "217 Benign neoplasm breast",
                "threedigit": "217",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Uterine leiomyoma",
            "title": "218 - Uterine leiomyoma",
            "threedigit": "218",
            "children": [
              {
                "key": "2180",
                "title": "2180 Submucous leiomyoma",
                "threedigit": "218",
                "isLeaf": true
              },
              {
                "key": "2181",
                "title": "2181 Intramural leiomyoma",
                "threedigit": "218",
                "isLeaf": true
              },
              {
                "key": "2182",
                "title": "2182 Subserous leiomyoma",
                "threedigit": "218",
                "isLeaf": true
              },
              {
                "key": "2189",
                "title": "2189 Uterine leiomyoma NOS",
                "threedigit": "218",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other benign neoplasm of uterus",
            "title": "219 - Other benign neoplasm of uterus",
            "threedigit": "219",
            "children": [
              {
                "key": "2190",
                "title": "2190 Benign neo cervix uteri",
                "threedigit": "219",
                "isLeaf": true
              },
              {
                "key": "2191",
                "title": "2191 Benign neo corpus uteri",
                "threedigit": "219",
                "isLeaf": true
              },
              {
                "key": "2198",
                "title": "2198 Benign neo uterus NEC",
                "threedigit": "219",
                "isLeaf": true
              },
              {
                "key": "2199",
                "title": "2199 Benign neo uterus NOS",
                "threedigit": "219",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of ovary",
            "title": "220 - Benign neoplasm of ovary",
            "threedigit": "220",
            "children": [
              {
                "key": "220",
                "title": "220 Benign neoplasm ovary",
                "threedigit": "220",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of other female genital organs",
            "title": "221 - Benign neoplasm of other female genital organs",
            "threedigit": "221",
            "children": [
              {
                "key": "2210",
                "title": "2210 Ben neo fallopian tube",
                "threedigit": "221",
                "isLeaf": true
              },
              {
                "key": "2211",
                "title": "2211 Benign neoplasm vagina",
                "threedigit": "221",
                "isLeaf": true
              },
              {
                "key": "2212",
                "title": "2212 Benign neoplasm vulva",
                "threedigit": "221",
                "isLeaf": true
              },
              {
                "key": "2218",
                "title": "2218 Ben neo fem genital NEC",
                "threedigit": "221",
                "isLeaf": true
              },
              {
                "key": "2219",
                "title": "2219 Ben neo fem genital NOS",
                "threedigit": "221",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of male genital organs",
            "title": "222 - Benign neoplasm of male genital organs",
            "threedigit": "222",
            "children": [
              {
                "key": "2220",
                "title": "2220 Benign neoplasm testis",
                "threedigit": "222",
                "isLeaf": true
              },
              {
                "key": "2221",
                "title": "2221 Benign neoplasm penis",
                "threedigit": "222",
                "isLeaf": true
              },
              {
                "key": "2222",
                "title": "2222 Benign neoplasm prostate",
                "threedigit": "222",
                "isLeaf": true
              },
              {
                "key": "2223",
                "title": "2223 Benign neo epididymis",
                "threedigit": "222",
                "isLeaf": true
              },
              {
                "key": "2224",
                "title": "2224 Benign neoplasm scrotum",
                "threedigit": "222",
                "isLeaf": true
              },
              {
                "key": "2228",
                "title": "2228 Ben neo male genital NEC",
                "threedigit": "222",
                "isLeaf": true
              },
              {
                "key": "2229",
                "title": "2229 Ben neo male genital NOS",
                "threedigit": "222",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of kidney and other urinary organs",
            "title": "223 - Benign neoplasm of kidney and other urinary organs",
            "threedigit": "223",
            "children": [
              {
                "key": "2230",
                "title": "2230 Benign neoplasm kidney",
                "threedigit": "223",
                "isLeaf": true
              },
              {
                "key": "2231",
                "title": "2231 Benign neo renal pelvis",
                "threedigit": "223",
                "isLeaf": true
              },
              {
                "key": "2232",
                "title": "2232 Benign neoplasm ureter",
                "threedigit": "223",
                "isLeaf": true
              },
              {
                "key": "2233",
                "title": "2233 Benign neoplasm bladder",
                "threedigit": "223",
                "isLeaf": true
              },
              {
                "key": "22381",
                "title": "22381 Benign neoplasm urethra",
                "threedigit": "223",
                "isLeaf": true
              },
              {
                "key": "22389",
                "title": "22389 Benign neo urinary NEC",
                "threedigit": "223",
                "isLeaf": true
              },
              {
                "key": "2239",
                "title": "2239 Benign neo urinary NOS",
                "threedigit": "223",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of eye",
            "title": "224 - Benign neoplasm of eye",
            "threedigit": "224",
            "children": [
              {
                "key": "2240",
                "title": "2240 Benign neoplasm eyeball",
                "threedigit": "224",
                "isLeaf": true
              },
              {
                "key": "2241",
                "title": "2241 Benign neoplasm orbit",
                "threedigit": "224",
                "isLeaf": true
              },
              {
                "key": "2242",
                "title": "2242 Ben neo lacrimal gland",
                "threedigit": "224",
                "isLeaf": true
              },
              {
                "key": "2243",
                "title": "2243 Benign neo conjunctiva",
                "threedigit": "224",
                "isLeaf": true
              },
              {
                "key": "2244",
                "title": "2244 Benign neoplasm cornea",
                "threedigit": "224",
                "isLeaf": true
              },
              {
                "key": "2245",
                "title": "2245 Benign neoplasm retina",
                "threedigit": "224",
                "isLeaf": true
              },
              {
                "key": "2246",
                "title": "2246 Benign neoplasm choroid",
                "threedigit": "224",
                "isLeaf": true
              },
              {
                "key": "2247",
                "title": "2247 Ben neo lacrimal duct",
                "threedigit": "224",
                "isLeaf": true
              },
              {
                "key": "2248",
                "title": "2248 Benign neoplasm eye NEC",
                "threedigit": "224",
                "isLeaf": true
              },
              {
                "key": "2249",
                "title": "2249 Benign neoplasm eye NOS",
                "threedigit": "224",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of brain and other parts of nervous system",
            "title": "225 - Benign neoplasm of brain and other parts of nervous system",
            "threedigit": "225",
            "children": [
              {
                "key": "2250",
                "title": "2250 Benign neoplasm brain",
                "threedigit": "225",
                "isLeaf": true
              },
              {
                "key": "2251",
                "title": "2251 Benign neo cranial nerve",
                "threedigit": "225",
                "isLeaf": true
              },
              {
                "key": "2252",
                "title": "2252 Ben neo cerebr meninges",
                "threedigit": "225",
                "isLeaf": true
              },
              {
                "key": "2253",
                "title": "2253 Benign neo spinal cord",
                "threedigit": "225",
                "isLeaf": true
              },
              {
                "key": "2254",
                "title": "2254 Ben neo spinal meninges",
                "threedigit": "225",
                "isLeaf": true
              },
              {
                "key": "2258",
                "title": "2258 Benign neo nerv sys NEC",
                "threedigit": "225",
                "isLeaf": true
              },
              {
                "key": "2259",
                "title": "2259 Benign neo nerv sys NOS",
                "threedigit": "225",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of thyroid glands",
            "title": "226 - Benign neoplasm of thyroid glands",
            "threedigit": "226",
            "children": [
              {
                "key": "226",
                "title": "226 Benign neoplasm thyroid",
                "threedigit": "226",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of other endocrine glands and related structures",
            "title": "227 - Benign neoplasm of other endocrine glands and related structures",
            "threedigit": "227",
            "children": [
              {
                "key": "2270",
                "title": "2270 Benign neoplasm adrenal",
                "threedigit": "227",
                "isLeaf": true
              },
              {
                "key": "2271",
                "title": "2271 Benign neo parathyroid",
                "threedigit": "227",
                "isLeaf": true
              },
              {
                "key": "2273",
                "title": "2273 Benign neo pituitary",
                "threedigit": "227",
                "isLeaf": true
              },
              {
                "key": "2274",
                "title": "2274 Ben neopl pineal gland",
                "threedigit": "227",
                "isLeaf": true
              },
              {
                "key": "2275",
                "title": "2275 Benign neo carotid body",
                "threedigit": "227",
                "isLeaf": true
              },
              {
                "key": "2276",
                "title": "2276 Ben neo paraganglia NEC",
                "threedigit": "227",
                "isLeaf": true
              },
              {
                "key": "2278",
                "title": "2278 Benign neo endocrine NEC",
                "threedigit": "227",
                "isLeaf": true
              },
              {
                "key": "2279",
                "title": "2279 Benign neo endocrine NOS",
                "threedigit": "227",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hemangioma and lymphangioma any site",
            "title": "228 - Hemangioma and lymphangioma any site",
            "threedigit": "228",
            "children": [
              {
                "key": "22800",
                "title": "22800 Hemangioma NOS",
                "threedigit": "228",
                "isLeaf": true
              },
              {
                "key": "22801",
                "title": "22801 Hemangioma skin",
                "threedigit": "228",
                "isLeaf": true
              },
              {
                "key": "22802",
                "title": "22802 Hemangioma intracranial",
                "threedigit": "228",
                "isLeaf": true
              },
              {
                "key": "22803",
                "title": "22803 Hemangioma retina",
                "threedigit": "228",
                "isLeaf": true
              },
              {
                "key": "22804",
                "title": "22804 Hemangioma intra-abdom",
                "threedigit": "228",
                "isLeaf": true
              },
              {
                "key": "22809",
                "title": "22809 Hemangioma NEC",
                "threedigit": "228",
                "isLeaf": true
              },
              {
                "key": "2281",
                "title": "2281 Lymphangioma, any site",
                "threedigit": "228",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Benign neoplasm of other and unspecified sites",
            "title": "229 - Benign neoplasm of other and unspecified sites",
            "threedigit": "229",
            "children": [
              {
                "key": "2290",
                "title": "2290 Benign neo lymph nodes",
                "threedigit": "229",
                "isLeaf": true
              },
              {
                "key": "2298",
                "title": "2298 Benign neoplasm NEC",
                "threedigit": "229",
                "isLeaf": true
              },
              {
                "key": "2299",
                "title": "2299 Benign neoplasm NOS",
                "threedigit": "229",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Carcinoma In Situ",
        "title": "230-234 Carcinoma In Situ",
        "threedigit": "230",
        "children": [
          {
            "key": "Carcinoma in situ of digestive organs",
            "title": "230 - Carcinoma in situ of digestive organs",
            "threedigit": "230",
            "children": [
              {
                "key": "2300",
                "title": "2300 Ca in situ oral cav/phar",
                "threedigit": "230",
                "isLeaf": true
              },
              {
                "key": "2301",
                "title": "2301 Ca in situ esophagus",
                "threedigit": "230",
                "isLeaf": true
              },
              {
                "key": "2302",
                "title": "2302 Ca in situ stomach",
                "threedigit": "230",
                "isLeaf": true
              },
              {
                "key": "2303",
                "title": "2303 Ca in situ colon",
                "threedigit": "230",
                "isLeaf": true
              },
              {
                "key": "2304",
                "title": "2304 Ca in situ rectum",
                "threedigit": "230",
                "isLeaf": true
              },
              {
                "key": "2305",
                "title": "2305 Ca in situ anal canal",
                "threedigit": "230",
                "isLeaf": true
              },
              {
                "key": "2306",
                "title": "2306 Ca in situ anus NOS",
                "threedigit": "230",
                "isLeaf": true
              },
              {
                "key": "2307",
                "title": "2307 Ca in situ bowel NEC/NOS",
                "threedigit": "230",
                "isLeaf": true
              },
              {
                "key": "2308",
                "title": "2308 Ca in situ liver/biliary",
                "threedigit": "230",
                "isLeaf": true
              },
              {
                "key": "2309",
                "title": "2309 Ca in situ GI NEC/NOS",
                "threedigit": "230",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Carcinoma in situ of respiratory system",
            "title": "231 - Carcinoma in situ of respiratory system",
            "threedigit": "231",
            "children": [
              {
                "key": "2310",
                "title": "2310 Ca in situ larynx",
                "threedigit": "231",
                "isLeaf": true
              },
              {
                "key": "2311",
                "title": "2311 Ca in situ trachea",
                "threedigit": "231",
                "isLeaf": true
              },
              {
                "key": "2312",
                "title": "2312 Ca in situ bronchus/lung",
                "threedigit": "231",
                "isLeaf": true
              },
              {
                "key": "2318",
                "title": "2318 Ca in situ resp sys NEC",
                "threedigit": "231",
                "isLeaf": true
              },
              {
                "key": "2319",
                "title": "2319 Ca in situ resp sys NOS",
                "threedigit": "231",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Carcinoma in situ of skin",
            "title": "232 - Carcinoma in situ of skin",
            "threedigit": "232",
            "children": [
              {
                "key": "2320",
                "title": "2320 Ca in situ skin lip",
                "threedigit": "232",
                "isLeaf": true
              },
              {
                "key": "2321",
                "title": "2321 Ca in situ eyelid",
                "threedigit": "232",
                "isLeaf": true
              },
              {
                "key": "2322",
                "title": "2322 Ca in situ skin ear",
                "threedigit": "232",
                "isLeaf": true
              },
              {
                "key": "2323",
                "title": "2323 Ca in situ skin face NEC",
                "threedigit": "232",
                "isLeaf": true
              },
              {
                "key": "2324",
                "title": "2324 Ca in situ scalp",
                "threedigit": "232",
                "isLeaf": true
              },
              {
                "key": "2325",
                "title": "2325 Ca in situ skin trunk",
                "threedigit": "232",
                "isLeaf": true
              },
              {
                "key": "2326",
                "title": "2326 Ca in situ skin arm",
                "threedigit": "232",
                "isLeaf": true
              },
              {
                "key": "2327",
                "title": "2327 Ca in situ skin leg",
                "threedigit": "232",
                "isLeaf": true
              },
              {
                "key": "2328",
                "title": "2328 Ca in situ skin NEC",
                "threedigit": "232",
                "isLeaf": true
              },
              {
                "key": "2329",
                "title": "2329 Ca in situ skin NOS",
                "threedigit": "232",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Carcinoma in situ of breast and genitourinary system",
            "title": "233 - Carcinoma in situ of breast and genitourinary system",
            "threedigit": "233",
            "children": [
              {
                "key": "2330",
                "title": "2330 Ca in situ breast",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "2331",
                "title": "2331 Ca in situ cervix uteri",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "2332",
                "title": "2332 Ca in situ uterus NEC",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "23330",
                "title": "23330 Ca in situ fem gen NOS",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "23331",
                "title": "23331 Carcinoma in situ vagina",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "23332",
                "title": "23332 Carcinoma in situ vulva",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "23339",
                "title": "23339 Ca in situ fem gen NEC",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "2334",
                "title": "2334 Ca in situ prostate",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "2335",
                "title": "2335 Ca in situ penis",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "2336",
                "title": "2336 Ca in situ male gen NEC",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "2337",
                "title": "2337 Ca in situ bladder",
                "threedigit": "233",
                "isLeaf": true
              },
              {
                "key": "2339",
                "title": "2339 Ca in situ urinary NEC",
                "threedigit": "233",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Carcinoma in situ of other and unspecified sites",
            "title": "234 - Carcinoma in situ of other and unspecified sites",
            "threedigit": "234",
            "children": [
              {
                "key": "2340",
                "title": "2340 Ca in situ eye",
                "threedigit": "234",
                "isLeaf": true
              },
              {
                "key": "2348",
                "title": "2348 Ca in situ NEC",
                "threedigit": "234",
                "isLeaf": true
              },
              {
                "key": "2349",
                "title": "2349 Ca in situ NOS",
                "threedigit": "234",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Neoplasms Of Uncertain Behavior",
        "title": "235-238 Neoplasms Of Uncertain Behavior",
        "threedigit": "235",
        "children": [
          {
            "key": "Neoplasm of uncertain behavior of digestive and respiratory systems",
            "title": "235 - Neoplasm of uncertain behavior of digestive and respiratory systems",
            "threedigit": "235",
            "children": [
              {
                "key": "2350",
                "title": "2350 Unc behav neo salivary",
                "threedigit": "235",
                "isLeaf": true
              },
              {
                "key": "2351",
                "title": "2351 Unc behav neo oral/phar",
                "threedigit": "235",
                "isLeaf": true
              },
              {
                "key": "2352",
                "title": "2352 Unc behav neo intestine",
                "threedigit": "235",
                "isLeaf": true
              },
              {
                "key": "2353",
                "title": "2353 Unc behav neo liver",
                "threedigit": "235",
                "isLeaf": true
              },
              {
                "key": "2354",
                "title": "2354 Unc behav neo peritoneum",
                "threedigit": "235",
                "isLeaf": true
              },
              {
                "key": "2355",
                "title": "2355 Unc behav neo GI NEC",
                "threedigit": "235",
                "isLeaf": true
              },
              {
                "key": "2356",
                "title": "2356 Unc behav neo larynx",
                "threedigit": "235",
                "isLeaf": true
              },
              {
                "key": "2357",
                "title": "2357 Unc behav neo lung",
                "threedigit": "235",
                "isLeaf": true
              },
              {
                "key": "2358",
                "title": "2358 Unc behav neo pleura",
                "threedigit": "235",
                "isLeaf": true
              },
              {
                "key": "2359",
                "title": "2359 Unc behav neo resp NEC",
                "threedigit": "235",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Neoplasm of uncertain behavior of genitourinary organs",
            "title": "236 - Neoplasm of uncertain behavior of genitourinary organs",
            "threedigit": "236",
            "children": [
              {
                "key": "2360",
                "title": "2360 Uncert behav neo uterus",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "2361",
                "title": "2361 Unc behav neo placenta",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "2362",
                "title": "2362 Unc behav neo ovary",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "2363",
                "title": "2363 Unc behav neo female NEC",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "2364",
                "title": "2364 Unc behav neo testis",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "2365",
                "title": "2365 Unc behav neo prostate",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "2366",
                "title": "2366 Unc behav neo male NEC",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "2367",
                "title": "2367 Unc behav neo bladder",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "23690",
                "title": "23690 Unc behav neo urinar NOS",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "23691",
                "title": "23691 Unc behav neo kidney",
                "threedigit": "236",
                "isLeaf": true
              },
              {
                "key": "23699",
                "title": "23699 Unc behav neo urinar NEC",
                "threedigit": "236",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Neoplasm of uncertain behavior of endocrine glands and nervous system",
            "title": "237 - Neoplasm of uncertain behavior of endocrine glands and nervous system",
            "threedigit": "237",
            "children": [
              {
                "key": "2370",
                "title": "2370 Unc behav neo pituitary",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "2371",
                "title": "2371 Unc behav neo pineal",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "2372",
                "title": "2372 Unc behav neo adrenal",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "2373",
                "title": "2373 Unc behav neo paragang",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "2374",
                "title": "2374 Uncer neo endocrine NEC",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "2375",
                "title": "2375 Unc beh neo brain/spinal",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "2376",
                "title": "2376 Unc behav neo meninges",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "23770",
                "title": "23770 Neurofibromatosis NOS",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "23771",
                "title": "23771 Neurofibromatosis type I",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "23772",
                "title": "23772 Neurofibromatosis typ II",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "23773",
                "title": "23773 Schwannomatosis",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "23779",
                "title": "23779 Neurofibromatosis NEC",
                "threedigit": "237",
                "isLeaf": true
              },
              {
                "key": "2379",
                "title": "2379 Unc beh neo nerv sys NEC",
                "threedigit": "237",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Neoplasm of uncertain behavior of other and unspecified sites and tissues",
            "title": "238 - Neoplasm of uncertain behavior of other and unspecified sites and tissues",
            "threedigit": "238",
            "children": [
              {
                "key": "2380",
                "title": "2380 Unc behav neo bone",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "2381",
                "title": "2381 Unc behav neo soft tissu",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "2382",
                "title": "2382 Unc behav neo skin",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "2383",
                "title": "2383 Unc behav neo breast",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "2384",
                "title": "2384 Polycythemia vera",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "2385",
                "title": "2385 Mastocytoma NOS",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "2386",
                "title": "2386 Plasmacytoma NOS",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "23871",
                "title": "23871 Essntial thrombocythemia",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "23872",
                "title": "23872 Low grde myelody syn les",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "23873",
                "title": "23873 Hi grde myelodys syn les",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "23874",
                "title": "23874 Myelodyspls syn w 5q del",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "23875",
                "title": "23875 Myelodysplastic synd NOS",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "23876",
                "title": "23876 Myelofi w myelo metaplas",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "23877",
                "title": "23877 Post tp lymphprolif dis",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "23879",
                "title": "23879 Lymph/hematpoitc tis NEC",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "2388",
                "title": "2388 Uncert behavior neo NEC",
                "threedigit": "238",
                "isLeaf": true
              },
              {
                "key": "2389",
                "title": "2389 Uncert behavior neo NOS",
                "threedigit": "238",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Neoplasms Of Unspecified Nature",
        "title": "239-239 Neoplasms Of Unspecified Nature",
        "threedigit": "239",
        "children": [
          {
            "key": "Neoplasms of unspecified nature",
            "title": "239 - Neoplasms of unspecified nature",
            "threedigit": "239",
            "children": [
              {
                "key": "2390",
                "title": "2390 Digestive neoplasm NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "2391",
                "title": "2391 Respiratory neoplasm NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "2392",
                "title": "2392 Bone/skin neoplasm NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "2393",
                "title": "2393 Breast neoplasm NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "2394",
                "title": "2394 Bladder neoplasm NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "2395",
                "title": "2395 Other gu neoplasm NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "2396",
                "title": "2396 Brain neoplasm NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "2397",
                "title": "2397 Endocrine/nerv neo NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "23981",
                "title": "23981 Neo retina/choroid NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "23989",
                "title": "23989 Neoplasm other sites NOS",
                "threedigit": "239",
                "isLeaf": true
              },
              {
                "key": "2399",
                "title": "2399 Neoplasm NOS",
                "threedigit": "239",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Endocrine, Nutritional And Metabolic Diseases, And Immunity Disorders",
    "title": "240-279 Endocrine, Nutritional And Metabolic Diseases, And Immunity Disorders",
    "threedigit": "240",
    "children": [
      {
        "key": "Disorders Of Thyroid Gland",
        "title": "240-246 Disorders Of Thyroid Gland",
        "threedigit": "240",
        "children": [
          {
            "key": "Simple and unspecified goiter",
            "title": "240 - Simple and unspecified goiter",
            "threedigit": "240",
            "children": [
              {
                "key": "2400",
                "title": "2400 Simple goiter",
                "threedigit": "240",
                "isLeaf": true
              },
              {
                "key": "2409",
                "title": "2409 Goiter NOS",
                "threedigit": "240",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nontoxic nodular goiter",
            "title": "241 - Nontoxic nodular goiter",
            "threedigit": "241",
            "children": [
              {
                "key": "2410",
                "title": "2410 Nontox uninodular goiter",
                "threedigit": "241",
                "isLeaf": true
              },
              {
                "key": "2411",
                "title": "2411 Nontox multinodul goiter",
                "threedigit": "241",
                "isLeaf": true
              },
              {
                "key": "2419",
                "title": "2419 Nontox nodul goiter NOS",
                "threedigit": "241",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Thyrotoxicosis with or without goiter",
            "title": "242 - Thyrotoxicosis with or without goiter",
            "threedigit": "242",
            "children": [
              {
                "key": "24200",
                "title": "24200 Tox dif goiter no crisis",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24201",
                "title": "24201 Tox dif goiter w crisis",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24210",
                "title": "24210 Tox uninod goit no cris",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24211",
                "title": "24211 Tox uninod goit w crisis",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24220",
                "title": "24220 Tox multnod goit no cris",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24221",
                "title": "24221 Tox multnod goit w cris",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24230",
                "title": "24230 Tox nod goiter no crisis",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24231",
                "title": "24231 Tox nod goiter w crisis",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24240",
                "title": "24240 Thyrotox-ect nod no cris",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24241",
                "title": "24241 Thyrotox-ect nod w cris",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24280",
                "title": "24280 Thyrtox orig NEC no cris",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24281",
                "title": "24281 Thyrotox orig NEC w cris",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24290",
                "title": "24290 Thyrotox NOS no crisis",
                "threedigit": "242",
                "isLeaf": true
              },
              {
                "key": "24291",
                "title": "24291 Thyrotox NOS w crisis",
                "threedigit": "242",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Congenital hypothyroidism",
            "title": "243 - Congenital hypothyroidism",
            "threedigit": "243",
            "children": [
              {
                "key": "243",
                "title": "243 Congenital hypothyroidsm",
                "threedigit": "243",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acquired hypothyroidism",
            "title": "244 - Acquired hypothyroidism",
            "threedigit": "244",
            "children": [
              {
                "key": "2440",
                "title": "2440 Postsurgical hypothyroid",
                "threedigit": "244",
                "isLeaf": true
              },
              {
                "key": "2441",
                "title": "2441 Postablat hypothyr NEC",
                "threedigit": "244",
                "isLeaf": true
              },
              {
                "key": "2442",
                "title": "2442 Iodine hypothyroidism",
                "threedigit": "244",
                "isLeaf": true
              },
              {
                "key": "2443",
                "title": "2443 Iatrogen hypothyroid NEC",
                "threedigit": "244",
                "isLeaf": true
              },
              {
                "key": "2448",
                "title": "2448 Acquired hypothyroid NEC",
                "threedigit": "244",
                "isLeaf": true
              },
              {
                "key": "2449",
                "title": "2449 Hypothyroidism NOS",
                "threedigit": "244",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Thyroiditis",
            "title": "245 - Thyroiditis",
            "threedigit": "245",
            "children": [
              {
                "key": "2450",
                "title": "2450 Acute thyroiditis",
                "threedigit": "245",
                "isLeaf": true
              },
              {
                "key": "2451",
                "title": "2451 Subacute thyroiditis",
                "threedigit": "245",
                "isLeaf": true
              },
              {
                "key": "2452",
                "title": "2452 Chr lymphocyt thyroidit",
                "threedigit": "245",
                "isLeaf": true
              },
              {
                "key": "2453",
                "title": "2453 Chr fibrous thyroiditis",
                "threedigit": "245",
                "isLeaf": true
              },
              {
                "key": "2454",
                "title": "2454 Iatrogenic thyroiditis",
                "threedigit": "245",
                "isLeaf": true
              },
              {
                "key": "2458",
                "title": "2458 Chr thyroiditis NEC/NOS",
                "threedigit": "245",
                "isLeaf": true
              },
              {
                "key": "2459",
                "title": "2459 Thyroiditis NOS",
                "threedigit": "245",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of thyroid",
            "title": "246 - Other disorders of thyroid",
            "threedigit": "246",
            "children": [
              {
                "key": "2460",
                "title": "2460 Dis thyrocalciton secret",
                "threedigit": "246",
                "isLeaf": true
              },
              {
                "key": "2461",
                "title": "2461 Dyshormonogenic goiter",
                "threedigit": "246",
                "isLeaf": true
              },
              {
                "key": "2462",
                "title": "2462 Cyst of thyroid",
                "threedigit": "246",
                "isLeaf": true
              },
              {
                "key": "2463",
                "title": "2463 Hemorr/infarc thyroid",
                "threedigit": "246",
                "isLeaf": true
              },
              {
                "key": "2468",
                "title": "2468 Disorders of thyroid NEC",
                "threedigit": "246",
                "isLeaf": true
              },
              {
                "key": "2469",
                "title": "2469 Disorder of thyroid NOS",
                "threedigit": "246",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Diseases Of Other Endocrine Glands",
        "title": "249-259 Diseases Of Other Endocrine Glands",
        "threedigit": "249",
        "children": [
          {
            "key": "Secondary diabetes mellitus",
            "title": "249 - Secondary diabetes mellitus",
            "threedigit": "249",
            "children": [
              {
                "key": "24900",
                "title": "24900 Sec DM wo cmp nt st uncn",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24901",
                "title": "24901 Sec DM wo comp uncontrld",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24910",
                "title": "24910 Sec DM keto nt st uncntr",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24911",
                "title": "24911 Sec DM ketoacd uncntrld",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24920",
                "title": "24920 Sec DM hpros nt st uncnr",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24921",
                "title": "24921 Sec DM hprosmlr uncntrld",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24930",
                "title": "24930 Sec DM ot cma nt st uncn",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24931",
                "title": "24931 Sec DM oth coma uncntrld",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24940",
                "title": "24940 Sec DM renl nt st uncntr",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24941",
                "title": "24941 Sec DM renal uncontrld",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24950",
                "title": "24950 Sec DM ophth nt st uncn",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24951",
                "title": "24951 Sec DM ophth uncontrld",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24960",
                "title": "24960 Sec DM neuro nt st uncn",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24961",
                "title": "24961 Sec DM neuro uncontrld",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24970",
                "title": "24970 Sec DM circ nt st uncntr",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24971",
                "title": "24971 Sec DM circ uncontrld",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24980",
                "title": "24980 Sec DM oth nt st uncontr",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24981",
                "title": "24981 Sec DM other uncontrld",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24990",
                "title": "24990 Sec DM unsp nt st uncon",
                "threedigit": "249",
                "isLeaf": true
              },
              {
                "key": "24991",
                "title": "24991 Sec DM unsp uncontrold",
                "threedigit": "249",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diabetes mellitus",
            "title": "250 - Diabetes mellitus",
            "threedigit": "250",
            "children": [
              {
                "key": "25000",
                "title": "25000 DMII wo cmp nt st uncntr",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25001",
                "title": "25001 DMI wo cmp nt st uncntrl",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25002",
                "title": "25002 DMII wo cmp uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25003",
                "title": "25003 DMI wo cmp uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25010",
                "title": "25010 DMII keto nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25011",
                "title": "25011 DMI keto nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25012",
                "title": "25012 DMII ketoacd uncontrold",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25013",
                "title": "25013 DMI ketoacd uncontrold",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25020",
                "title": "25020 DMII hprsm nt st uncntrl",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25021",
                "title": "25021 DMI hprsm nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25022",
                "title": "25022 DMII hprosmlr uncontrold",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25023",
                "title": "25023 DMI hprosmlr uncontrold",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25030",
                "title": "25030 DMII o cm nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25031",
                "title": "25031 DMI o cm nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25032",
                "title": "25032 DMII oth coma uncontrold",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25033",
                "title": "25033 DMI oth coma uncontrold",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25040",
                "title": "25040 DMII renl nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25041",
                "title": "25041 DMI renl nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25042",
                "title": "25042 DMII renal uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25043",
                "title": "25043 DMI renal uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25050",
                "title": "25050 DMII ophth nt st uncntrl",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25051",
                "title": "25051 DMI ophth nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25052",
                "title": "25052 DMII ophth uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25053",
                "title": "25053 DMI ophth uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25060",
                "title": "25060 DMII neuro nt st uncntrl",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25061",
                "title": "25061 DMI neuro nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25062",
                "title": "25062 DMII neuro uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25063",
                "title": "25063 DMI neuro uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25070",
                "title": "25070 DMII circ nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25071",
                "title": "25071 DMI circ nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25072",
                "title": "25072 DMII circ uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25073",
                "title": "25073 DMI circ uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25080",
                "title": "25080 DMII oth nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25081",
                "title": "25081 DMI oth nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25082",
                "title": "25082 DMII oth uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25083",
                "title": "25083 DMI oth uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25090",
                "title": "25090 DMII unspf nt st uncntrl",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25091",
                "title": "25091 DMI unspf nt st uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25092",
                "title": "25092 DMII unspf uncntrld",
                "threedigit": "250",
                "isLeaf": true
              },
              {
                "key": "25093",
                "title": "25093 DMI unspf uncntrld",
                "threedigit": "250",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of pancreatic internal secretion",
            "title": "251 - Other disorders of pancreatic internal secretion",
            "threedigit": "251",
            "children": [
              {
                "key": "2510",
                "title": "2510 Hypoglycemic coma",
                "threedigit": "251",
                "isLeaf": true
              },
              {
                "key": "2511",
                "title": "2511 Oth spcf hypoglycemia",
                "threedigit": "251",
                "isLeaf": true
              },
              {
                "key": "2512",
                "title": "2512 Hypoglycemia NOS",
                "threedigit": "251",
                "isLeaf": true
              },
              {
                "key": "2513",
                "title": "2513 Postsurg hypoinsulinemia",
                "threedigit": "251",
                "isLeaf": true
              },
              {
                "key": "2514",
                "title": "2514 Abn secretion glucagon",
                "threedigit": "251",
                "isLeaf": true
              },
              {
                "key": "2515",
                "title": "2515 Abnorm secretion gastrin",
                "threedigit": "251",
                "isLeaf": true
              },
              {
                "key": "2518",
                "title": "2518 Pancreatic disorder NEC",
                "threedigit": "251",
                "isLeaf": true
              },
              {
                "key": "2519",
                "title": "2519 Pancreatic disorder NOS",
                "threedigit": "251",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of parathyroid gland",
            "title": "252 - Disorders of parathyroid gland",
            "threedigit": "252",
            "children": [
              {
                "key": "25200",
                "title": "25200 Hyperparathyroidism NOS",
                "threedigit": "252",
                "isLeaf": true
              },
              {
                "key": "25201",
                "title": "25201 Primary hyperparathyroid",
                "threedigit": "252",
                "isLeaf": true
              },
              {
                "key": "25202",
                "title": "25202 Sec hyprprthyrd nonrenal",
                "threedigit": "252",
                "isLeaf": true
              },
              {
                "key": "25208",
                "title": "25208 Hyperparathyroidism NEC",
                "threedigit": "252",
                "isLeaf": true
              },
              {
                "key": "2521",
                "title": "2521 Hypoparathyroidism",
                "threedigit": "252",
                "isLeaf": true
              },
              {
                "key": "2528",
                "title": "2528 Parathyroid disorder NEC",
                "threedigit": "252",
                "isLeaf": true
              },
              {
                "key": "2529",
                "title": "2529 Parathyroid disorder NOS",
                "threedigit": "252",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of the pituitary gland and its hypothalamic control",
            "title": "253 - Disorders of the pituitary gland and its hypothalamic control",
            "threedigit": "253",
            "children": [
              {
                "key": "2530",
                "title": "2530 Acromegaly and gigantism",
                "threedigit": "253",
                "isLeaf": true
              },
              {
                "key": "2531",
                "title": "2531 Ant pituit hyperfunc NEC",
                "threedigit": "253",
                "isLeaf": true
              },
              {
                "key": "2532",
                "title": "2532 Panhypopituitarism",
                "threedigit": "253",
                "isLeaf": true
              },
              {
                "key": "2533",
                "title": "2533 Pituitary dwarfism",
                "threedigit": "253",
                "isLeaf": true
              },
              {
                "key": "2534",
                "title": "2534 Anter pituitary dis NEC",
                "threedigit": "253",
                "isLeaf": true
              },
              {
                "key": "2535",
                "title": "2535 Diabetes insipidus",
                "threedigit": "253",
                "isLeaf": true
              },
              {
                "key": "2536",
                "title": "2536 Neurohypophysis dis NEC",
                "threedigit": "253",
                "isLeaf": true
              },
              {
                "key": "2537",
                "title": "2537 Iatrogenic pituitary dis",
                "threedigit": "253",
                "isLeaf": true
              },
              {
                "key": "2538",
                "title": "2538 Pituitary disorder NEC",
                "threedigit": "253",
                "isLeaf": true
              },
              {
                "key": "2539",
                "title": "2539 Pituitary disorder NOS",
                "threedigit": "253",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of thymus gland",
            "title": "254 - Diseases of thymus gland",
            "threedigit": "254",
            "children": [
              {
                "key": "2540",
                "title": "2540 Persist hyperplas thymus",
                "threedigit": "254",
                "isLeaf": true
              },
              {
                "key": "2541",
                "title": "2541 Abscess of thymus",
                "threedigit": "254",
                "isLeaf": true
              },
              {
                "key": "2548",
                "title": "2548 Diseases of thymus NEC",
                "threedigit": "254",
                "isLeaf": true
              },
              {
                "key": "2549",
                "title": "2549 Disease of thymus NOS",
                "threedigit": "254",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of adrenal glands",
            "title": "255 - Disorders of adrenal glands",
            "threedigit": "255",
            "children": [
              {
                "key": "2550",
                "title": "2550 Cushing's syndrome",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "25510",
                "title": "25510 Hyperaldosteronism NOS",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "25511",
                "title": "25511 Glucrtcod-rem aldsternsm",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "25512",
                "title": "25512 Conn's syndrome",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "25513",
                "title": "25513 Bartter's syndrome",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "25514",
                "title": "25514 Secondry aldosternsm NEC",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "2552",
                "title": "2552 Adrenogenital disorders",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "2553",
                "title": "2553 Corticoadren overact NEC",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "25541",
                "title": "25541 Glucocorticoid deficient",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "25542",
                "title": "25542 Mineralcorticoid defcnt",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "2555",
                "title": "2555 Adrenal hypofunction NEC",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "2556",
                "title": "2556 Medulloadrenal hyperfunc",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "2558",
                "title": "2558 Adrenal disorder NEC",
                "threedigit": "255",
                "isLeaf": true
              },
              {
                "key": "2559",
                "title": "2559 Adrenal disorder NOS",
                "threedigit": "255",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ovarian dysfunction",
            "title": "256 - Ovarian dysfunction",
            "threedigit": "256",
            "children": [
              {
                "key": "2560",
                "title": "2560 Hyperestrogenism",
                "threedigit": "256",
                "isLeaf": true
              },
              {
                "key": "2561",
                "title": "2561 Ovarian hyperfunc NEC",
                "threedigit": "256",
                "isLeaf": true
              },
              {
                "key": "2562",
                "title": "2562 Postablativ ovarian fail",
                "threedigit": "256",
                "isLeaf": true
              },
              {
                "key": "25631",
                "title": "25631 Premature menopause",
                "threedigit": "256",
                "isLeaf": true
              },
              {
                "key": "25639",
                "title": "25639 Ovarian failure NEC",
                "threedigit": "256",
                "isLeaf": true
              },
              {
                "key": "2564",
                "title": "2564 Polycystic ovaries",
                "threedigit": "256",
                "isLeaf": true
              },
              {
                "key": "2568",
                "title": "2568 Ovarian dysfunction NEC",
                "threedigit": "256",
                "isLeaf": true
              },
              {
                "key": "2569",
                "title": "2569 Ovarian dysfunction NOS",
                "threedigit": "256",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Testicular dysfunction",
            "title": "257 - Testicular dysfunction",
            "threedigit": "257",
            "children": [
              {
                "key": "2570",
                "title": "2570 Testicular hyperfunction",
                "threedigit": "257",
                "isLeaf": true
              },
              {
                "key": "2571",
                "title": "2571 Postablat testic hypofun",
                "threedigit": "257",
                "isLeaf": true
              },
              {
                "key": "2572",
                "title": "2572 Testicular hypofunc NEC",
                "threedigit": "257",
                "isLeaf": true
              },
              {
                "key": "2578",
                "title": "2578 Testicular dysfunct NEC",
                "threedigit": "257",
                "isLeaf": true
              },
              {
                "key": "2579",
                "title": "2579 Testicular dysfunct NOS",
                "threedigit": "257",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Polyglandular dysfunction and related disorders",
            "title": "258 - Polyglandular dysfunction and related disorders",
            "threedigit": "258",
            "children": [
              {
                "key": "25801",
                "title": "25801 Mult endo neoplas type I",
                "threedigit": "258",
                "isLeaf": true
              },
              {
                "key": "25802",
                "title": "25802 Mult endo neop type IIA",
                "threedigit": "258",
                "isLeaf": true
              },
              {
                "key": "25803",
                "title": "25803 Mult endo neop type IIB",
                "threedigit": "258",
                "isLeaf": true
              },
              {
                "key": "2581",
                "title": "2581 Comb endocr dysfunct NEC",
                "threedigit": "258",
                "isLeaf": true
              },
              {
                "key": "2588",
                "title": "2588 Polyglandul dysfunc NEC",
                "threedigit": "258",
                "isLeaf": true
              },
              {
                "key": "2589",
                "title": "2589 Polyglandul dysfunc NOS",
                "threedigit": "258",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other endocrine disorders",
            "title": "259 - Other endocrine disorders",
            "threedigit": "259",
            "children": [
              {
                "key": "2590",
                "title": "2590 Delay sexual develop NEC",
                "threedigit": "259",
                "isLeaf": true
              },
              {
                "key": "2591",
                "title": "2591 Sexual precocity NEC",
                "threedigit": "259",
                "isLeaf": true
              },
              {
                "key": "2592",
                "title": "2592 Carcinoid syndrome",
                "threedigit": "259",
                "isLeaf": true
              },
              {
                "key": "2593",
                "title": "2593 Ectopic hormone secr NEC",
                "threedigit": "259",
                "isLeaf": true
              },
              {
                "key": "2594",
                "title": "2594 Dwarfism NEC",
                "threedigit": "259",
                "isLeaf": true
              },
              {
                "key": "25950",
                "title": "25950 Androgen insensitvty NOS",
                "threedigit": "259",
                "isLeaf": true
              },
              {
                "key": "25951",
                "title": "25951 Androgen insensitvty syn",
                "threedigit": "259",
                "isLeaf": true
              },
              {
                "key": "25952",
                "title": "25952 Part androgen insnsitvty",
                "threedigit": "259",
                "isLeaf": true
              },
              {
                "key": "2598",
                "title": "2598 Endocrine disorders NEC",
                "threedigit": "259",
                "isLeaf": true
              },
              {
                "key": "2599",
                "title": "2599 Endocrine disorder NOS",
                "threedigit": "259",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Nutritional Deficiencies",
        "title": "260-269 Nutritional Deficiencies",
        "threedigit": "260",
        "children": [
          {
            "key": "Kwashiorkor",
            "title": "260 - Kwashiorkor",
            "threedigit": "260",
            "children": [
              {
                "key": "260",
                "title": "260 Kwashiorkor",
                "threedigit": "260",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nutritional marasmus",
            "title": "261 - Nutritional marasmus",
            "threedigit": "261",
            "children": [
              {
                "key": "261",
                "title": "261 Nutritional marasmus",
                "threedigit": "261",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other severe protein-calorie malnutrition",
            "title": "262 - Other severe protein-calorie malnutrition",
            "threedigit": "262",
            "children": [
              {
                "key": "262",
                "title": "262 Oth severe malnutrition",
                "threedigit": "262",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified protein-calorie malnutrition",
            "title": "263 - Other and unspecified protein-calorie malnutrition",
            "threedigit": "263",
            "children": [
              {
                "key": "2630",
                "title": "2630 Malnutrition mod degree",
                "threedigit": "263",
                "isLeaf": true
              },
              {
                "key": "2631",
                "title": "2631 Malnutrition mild degree",
                "threedigit": "263",
                "isLeaf": true
              },
              {
                "key": "2632",
                "title": "2632 Arrest devel d/t malnutr",
                "threedigit": "263",
                "isLeaf": true
              },
              {
                "key": "2638",
                "title": "2638 Protein-cal malnutr NEC",
                "threedigit": "263",
                "isLeaf": true
              },
              {
                "key": "2639",
                "title": "2639 Protein-cal malnutr NOS",
                "threedigit": "263",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Vitamin a deficiency",
            "title": "264 - Vitamin a deficiency",
            "threedigit": "264",
            "children": [
              {
                "key": "2640",
                "title": "2640 Vit A conjunctiv xerosis",
                "threedigit": "264",
                "isLeaf": true
              },
              {
                "key": "2641",
                "title": "2641 Vit A bitot's spot",
                "threedigit": "264",
                "isLeaf": true
              },
              {
                "key": "2642",
                "title": "2642 Vit A corneal xerosis",
                "threedigit": "264",
                "isLeaf": true
              },
              {
                "key": "2643",
                "title": "2643 Vit A cornea ulcer/xeros",
                "threedigit": "264",
                "isLeaf": true
              },
              {
                "key": "2644",
                "title": "2644 Vit A keratomalacia",
                "threedigit": "264",
                "isLeaf": true
              },
              {
                "key": "2645",
                "title": "2645 Vit A night blindness",
                "threedigit": "264",
                "isLeaf": true
              },
              {
                "key": "2646",
                "title": "2646 Vit A def w corneal scar",
                "threedigit": "264",
                "isLeaf": true
              },
              {
                "key": "2647",
                "title": "2647 Vit A ocular defic NEC",
                "threedigit": "264",
                "isLeaf": true
              },
              {
                "key": "2648",
                "title": "2648 Vitamin A deficiency NEC",
                "threedigit": "264",
                "isLeaf": true
              },
              {
                "key": "2649",
                "title": "2649 Vitamin A deficiency NOS",
                "threedigit": "264",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Thiamine and niacin deficiency states",
            "title": "265 - Thiamine and niacin deficiency states",
            "threedigit": "265",
            "children": [
              {
                "key": "2650",
                "title": "2650 Beriberi",
                "threedigit": "265",
                "isLeaf": true
              },
              {
                "key": "2651",
                "title": "2651 Thiamine defic NEC/NOS",
                "threedigit": "265",
                "isLeaf": true
              },
              {
                "key": "2652",
                "title": "2652 Pellagra",
                "threedigit": "265",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Deficiency of b-complex components",
            "title": "266 - Deficiency of b-complex components",
            "threedigit": "266",
            "children": [
              {
                "key": "2660",
                "title": "2660 Ariboflavinosis",
                "threedigit": "266",
                "isLeaf": true
              },
              {
                "key": "2661",
                "title": "2661 Vitamin b6 deficiency",
                "threedigit": "266",
                "isLeaf": true
              },
              {
                "key": "2662",
                "title": "2662 B-complex defic NEC",
                "threedigit": "266",
                "isLeaf": true
              },
              {
                "key": "2669",
                "title": "2669 Vitamin b deficiency NOS",
                "threedigit": "266",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ascorbic acid deficiency",
            "title": "267 - Ascorbic acid deficiency",
            "threedigit": "267",
            "children": [
              {
                "key": "267",
                "title": "267 Ascorbic acid deficiency",
                "threedigit": "267",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Vitamin d deficiency",
            "title": "268 - Vitamin d deficiency",
            "threedigit": "268",
            "children": [
              {
                "key": "2680",
                "title": "2680 Rickets, active",
                "threedigit": "268",
                "isLeaf": true
              },
              {
                "key": "2681",
                "title": "2681 Rickets, late effect",
                "threedigit": "268",
                "isLeaf": true
              },
              {
                "key": "2682",
                "title": "2682 Osteomalacia NOS",
                "threedigit": "268",
                "isLeaf": true
              },
              {
                "key": "2689",
                "title": "2689 Vitamin D deficiency NOS",
                "threedigit": "268",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other nutritional deficiencies",
            "title": "269 - Other nutritional deficiencies",
            "threedigit": "269",
            "children": [
              {
                "key": "2690",
                "title": "2690 Deficiency of vitamin k",
                "threedigit": "269",
                "isLeaf": true
              },
              {
                "key": "2691",
                "title": "2691 Vitamin Deficiency NEC",
                "threedigit": "269",
                "isLeaf": true
              },
              {
                "key": "2692",
                "title": "2692 Vitamin Deficiency NOS",
                "threedigit": "269",
                "isLeaf": true
              },
              {
                "key": "2693",
                "title": "2693 Mineral deficiency NEC",
                "threedigit": "269",
                "isLeaf": true
              },
              {
                "key": "2698",
                "title": "2698 Nutrition deficiency NEC",
                "threedigit": "269",
                "isLeaf": true
              },
              {
                "key": "2699",
                "title": "2699 Nutrition deficiency NOS",
                "threedigit": "269",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Metabolic Disorders And Immunity Disorders",
        "title": "270-279 Other Metabolic Disorders And Immunity Disorders",
        "threedigit": "270",
        "children": [
          {
            "key": "Disorders of amino-acid transport and metabolism",
            "title": "270 - Disorders of amino-acid transport and metabolism",
            "threedigit": "270",
            "children": [
              {
                "key": "2700",
                "title": "2700 Amino-acid transport dis",
                "threedigit": "270",
                "isLeaf": true
              },
              {
                "key": "2701",
                "title": "2701 Phenylketonuria - pku",
                "threedigit": "270",
                "isLeaf": true
              },
              {
                "key": "2702",
                "title": "2702 Arom amin-acid metab NEC",
                "threedigit": "270",
                "isLeaf": true
              },
              {
                "key": "2703",
                "title": "2703 Bran-chain amin-acid dis",
                "threedigit": "270",
                "isLeaf": true
              },
              {
                "key": "2704",
                "title": "2704 Sulph amino-acid met dis",
                "threedigit": "270",
                "isLeaf": true
              },
              {
                "key": "2705",
                "title": "2705 Dis histidine metabolism",
                "threedigit": "270",
                "isLeaf": true
              },
              {
                "key": "2706",
                "title": "2706 Dis urea cycle metabol",
                "threedigit": "270",
                "isLeaf": true
              },
              {
                "key": "2707",
                "title": "2707 Straig amin-acid met NEC",
                "threedigit": "270",
                "isLeaf": true
              },
              {
                "key": "2708",
                "title": "2708 Dis amino-acid metab NEC",
                "threedigit": "270",
                "isLeaf": true
              },
              {
                "key": "2709",
                "title": "2709 Dis amino-acid metab NOS",
                "threedigit": "270",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of carbohydrate transport and metabolism",
            "title": "271 - Disorders of carbohydrate transport and metabolism",
            "threedigit": "271",
            "children": [
              {
                "key": "2710",
                "title": "2710 Glycogenosis",
                "threedigit": "271",
                "isLeaf": true
              },
              {
                "key": "2711",
                "title": "2711 Galactosemia",
                "threedigit": "271",
                "isLeaf": true
              },
              {
                "key": "2712",
                "title": "2712 Hered fructose intoleran",
                "threedigit": "271",
                "isLeaf": true
              },
              {
                "key": "2713",
                "title": "2713 Disaccharidase def/malab",
                "threedigit": "271",
                "isLeaf": true
              },
              {
                "key": "2714",
                "title": "2714 Renal glycosuria",
                "threedigit": "271",
                "isLeaf": true
              },
              {
                "key": "2718",
                "title": "2718 Dis carbohydr metab NEC",
                "threedigit": "271",
                "isLeaf": true
              },
              {
                "key": "2719",
                "title": "2719 Dis carbohydr metab NOS",
                "threedigit": "271",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of lipoid metabolism",
            "title": "272 - Disorders of lipoid metabolism",
            "threedigit": "272",
            "children": [
              {
                "key": "2720",
                "title": "2720 Pure hypercholesterolem",
                "threedigit": "272",
                "isLeaf": true
              },
              {
                "key": "2721",
                "title": "2721 Pure hyperglyceridemia",
                "threedigit": "272",
                "isLeaf": true
              },
              {
                "key": "2722",
                "title": "2722 Mixed hyperlipidemia",
                "threedigit": "272",
                "isLeaf": true
              },
              {
                "key": "2723",
                "title": "2723 Hyperchylomicronemia",
                "threedigit": "272",
                "isLeaf": true
              },
              {
                "key": "2724",
                "title": "2724 Hyperlipidemia NEC/NOS",
                "threedigit": "272",
                "isLeaf": true
              },
              {
                "key": "2725",
                "title": "2725 Lipoprotein deficiencies",
                "threedigit": "272",
                "isLeaf": true
              },
              {
                "key": "2726",
                "title": "2726 Lipodystrophy",
                "threedigit": "272",
                "isLeaf": true
              },
              {
                "key": "2727",
                "title": "2727 Lipidoses",
                "threedigit": "272",
                "isLeaf": true
              },
              {
                "key": "2728",
                "title": "2728 Lipoid metabol dis NEC",
                "threedigit": "272",
                "isLeaf": true
              },
              {
                "key": "2729",
                "title": "2729 Lipoid metabol dis NOS",
                "threedigit": "272",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of plasma protein metabolism",
            "title": "273 - Disorders of plasma protein metabolism",
            "threedigit": "273",
            "children": [
              {
                "key": "2730",
                "title": "2730 Polyclon hypergammaglobu",
                "threedigit": "273",
                "isLeaf": true
              },
              {
                "key": "2731",
                "title": "2731 Monoclon paraproteinemia",
                "threedigit": "273",
                "isLeaf": true
              },
              {
                "key": "2732",
                "title": "2732 Paraproteinemia NEC",
                "threedigit": "273",
                "isLeaf": true
              },
              {
                "key": "2733",
                "title": "2733 Macroglobulinemia",
                "threedigit": "273",
                "isLeaf": true
              },
              {
                "key": "2734",
                "title": "2734 Alpha-1-antitrypsin def",
                "threedigit": "273",
                "isLeaf": true
              },
              {
                "key": "2738",
                "title": "2738 Dis plas protein met NEC",
                "threedigit": "273",
                "isLeaf": true
              },
              {
                "key": "2739",
                "title": "2739 Dis plas protein met NOS",
                "threedigit": "273",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Gout",
            "title": "274 - Gout",
            "threedigit": "274",
            "children": [
              {
                "key": "27400",
                "title": "27400 Gouty arthropathy NOS",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "27401",
                "title": "27401 Acute gouty arthropathy",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "27402",
                "title": "27402 Chr gouty atrph wo tophi",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "27403",
                "title": "27403 Chr gouty atroph w tophi",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "27410",
                "title": "27410 Gouty nephropathy NOS",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "27411",
                "title": "27411 Uric acid nephrolithias",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "27419",
                "title": "27419 Gouty nephropathy NEC",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "27481",
                "title": "27481 Gouty tophi of ear",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "27482",
                "title": "27482 Gouty tophi site NEC",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "27489",
                "title": "27489 Gout w manifestation NEC",
                "threedigit": "274",
                "isLeaf": true
              },
              {
                "key": "2749",
                "title": "2749 Gout NOS",
                "threedigit": "274",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of mineral metabolism",
            "title": "275 - Disorders of mineral metabolism",
            "threedigit": "275",
            "children": [
              {
                "key": "27501",
                "title": "27501 Heredit hemochromatosis",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "27502",
                "title": "27502 Hemochromatos-rbc trans",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "27503",
                "title": "27503 Hemochromatosis NEC",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "27509",
                "title": "27509 Disord iron metablsm NEC",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "2751",
                "title": "2751 Dis copper metabolism",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "2752",
                "title": "2752 Dis magnesium metabolism",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "2753",
                "title": "2753 Dis phosphorus metabol",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "27540",
                "title": "27540 Dis calcium metablsm NOS",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "27541",
                "title": "27541 Hypocalcemia",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "27542",
                "title": "27542 Hypercalcemia",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "27549",
                "title": "27549 Dis calcium metablsm NEC",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "2755",
                "title": "2755 Hungry bone syndrome",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "2758",
                "title": "2758 Dis mineral metabol NEC",
                "threedigit": "275",
                "isLeaf": true
              },
              {
                "key": "2759",
                "title": "2759 Dis mineral metabol NOS",
                "threedigit": "275",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of fluid electrolyte and acid-base balance",
            "title": "276 - Disorders of fluid electrolyte and acid-base balance",
            "threedigit": "276",
            "children": [
              {
                "key": "2760",
                "title": "2760 Hyperosmolality",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "2761",
                "title": "2761 Hyposmolality",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "2762",
                "title": "2762 Acidosis",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "2763",
                "title": "2763 Alkalosis",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "2764",
                "title": "2764 Mixed acid-base bal dis",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "27650",
                "title": "27650 Volume depletion NOS",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "27651",
                "title": "27651 Dehydration",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "27652",
                "title": "27652 Hypovolemia",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "27661",
                "title": "27661 Transfsn w circ overload",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "27669",
                "title": "27669 Fluid overload NEC",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "2767",
                "title": "2767 Hyperpotassemia",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "2768",
                "title": "2768 Hypopotassemia",
                "threedigit": "276",
                "isLeaf": true
              },
              {
                "key": "2769",
                "title": "2769 Electrolyt/fluid dis NEC",
                "threedigit": "276",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified disorders of metabolism",
            "title": "277 - Other and unspecified disorders of metabolism",
            "threedigit": "277",
            "children": [
              {
                "key": "27700",
                "title": "27700 Cystic fibros w/o ileus",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27701",
                "title": "27701 Cystic fibrosis w ileus",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27702",
                "title": "27702 Cystic fibros w pul man",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27703",
                "title": "27703 Cystic fibrosis w GI man",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27709",
                "title": "27709 Cystic fibrosis NEC",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "2771",
                "title": "2771 Dis porphyrin metabolism",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "2772",
                "title": "2772 Purine/pyrimid dis NEC",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27730",
                "title": "27730 Amyloidosis NOS",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27731",
                "title": "27731 Fam Mediterranean fever",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27739",
                "title": "27739 Amyloidosis NEC",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "2774",
                "title": "2774 Dis bilirubin excretion",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "2775",
                "title": "2775 Mucopolysaccharidosis",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "2776",
                "title": "2776 Defic circul enzyme NEC",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "2777",
                "title": "2777 Dysmetabolic syndrome x",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27781",
                "title": "27781 Primary carnitine defncy",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27782",
                "title": "27782 Crnitne def d/t nb met",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27783",
                "title": "27783 Iatrogenic carnitine def",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27784",
                "title": "27784 Sec carnitine defncy NEC",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27785",
                "title": "27785 Disorders acid oxidation",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27786",
                "title": "27786 Peroxisomal disorders",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27787",
                "title": "27787 Dis mitochondrial metab",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27788",
                "title": "27788 Tumor lysis syndrome",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "27789",
                "title": "27789 Metabolism disorder NEC",
                "threedigit": "277",
                "isLeaf": true
              },
              {
                "key": "2779",
                "title": "2779 Metabolism disorder NOS",
                "threedigit": "277",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Overweight, obesity and other hyperalimentation",
            "title": "278 - Overweight, obesity and other hyperalimentation",
            "threedigit": "278",
            "children": [
              {
                "key": "27800",
                "title": "27800 Obesity NOS",
                "threedigit": "278",
                "isLeaf": true
              },
              {
                "key": "27801",
                "title": "27801 Morbid obesity",
                "threedigit": "278",
                "isLeaf": true
              },
              {
                "key": "27802",
                "title": "27802 Overweight",
                "threedigit": "278",
                "isLeaf": true
              },
              {
                "key": "27803",
                "title": "27803 Obesity hypovent synd",
                "threedigit": "278",
                "isLeaf": true
              },
              {
                "key": "2781",
                "title": "2781 Localized adiposity",
                "threedigit": "278",
                "isLeaf": true
              },
              {
                "key": "2782",
                "title": "2782 Hypervitaminosis a",
                "threedigit": "278",
                "isLeaf": true
              },
              {
                "key": "2783",
                "title": "2783 Hypercarotinemia",
                "threedigit": "278",
                "isLeaf": true
              },
              {
                "key": "2784",
                "title": "2784 Hypervitaminosis d",
                "threedigit": "278",
                "isLeaf": true
              },
              {
                "key": "2788",
                "title": "2788 Other hyperalimentation",
                "threedigit": "278",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders involving the immune mechanism",
            "title": "279 - Disorders involving the immune mechanism",
            "threedigit": "279",
            "children": [
              {
                "key": "27900",
                "title": "27900 Hypogammaglobulinem NOS",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27901",
                "title": "27901 Selective iga immunodef",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27902",
                "title": "27902 Selective IgM immunodef",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27903",
                "title": "27903 Selective ig defic NEC",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27904",
                "title": "27904 Cong hypogammaglobulinem",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27905",
                "title": "27905 Immunodefic w hyper-igm",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27906",
                "title": "27906 Common variabl immunodef",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27909",
                "title": "27909 Humoral immunity def NEC",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27910",
                "title": "27910 Immundef t-cell def NOS",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27911",
                "title": "27911 Digeorge's syndrome",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27912",
                "title": "27912 Wiskott-aldrich syndrome",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27913",
                "title": "27913 Nezelof's syndrome",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27919",
                "title": "27919 Defic cell immunity NOS",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "2792",
                "title": "2792 Combined immunity defic",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "2793",
                "title": "2793 Immunity deficiency NOS",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27941",
                "title": "27941 Autoimmun lymphprof synd",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27949",
                "title": "27949 Autoimmune disease NEC",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27950",
                "title": "27950 Graft-versus-host NOS",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27951",
                "title": "27951 Ac graft-versus-host dis",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27952",
                "title": "27952 Chronc graft-vs-host dis",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "27953",
                "title": "27953 Ac on chrn grft-vs-host",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "2798",
                "title": "2798 Immune mechanism dis NEC",
                "threedigit": "279",
                "isLeaf": true
              },
              {
                "key": "2799",
                "title": "2799 Immune mechanism dis NOS",
                "threedigit": "279",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Diseases Of The Blood And Blood-Forming Organs",
    "title": "280-289 Diseases Of The Blood And Blood-Forming Organs",
    "threedigit": "280",
    "children": [
      {
        "title": "280-289 undefined",
        "threedigit": "280",
        "children": [
          {
            "key": "Iron deficiency anemias",
            "title": "280 - Iron deficiency anemias",
            "threedigit": "280",
            "children": [
              {
                "key": "2800",
                "title": "2800 Chr blood loss anemia",
                "threedigit": "280",
                "isLeaf": true
              },
              {
                "key": "2801",
                "title": "2801 Iron def anemia dietary",
                "threedigit": "280",
                "isLeaf": true
              },
              {
                "key": "2808",
                "title": "2808 Iron defic anemia NEC",
                "threedigit": "280",
                "isLeaf": true
              },
              {
                "key": "2809",
                "title": "2809 Iron defic anemia NOS",
                "threedigit": "280",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other deficiency anemias",
            "title": "281 - Other deficiency anemias",
            "threedigit": "281",
            "children": [
              {
                "key": "2810",
                "title": "2810 Pernicious anemia",
                "threedigit": "281",
                "isLeaf": true
              },
              {
                "key": "2811",
                "title": "2811 B12 defic anemia NEC",
                "threedigit": "281",
                "isLeaf": true
              },
              {
                "key": "2812",
                "title": "2812 Folate-deficiency anemia",
                "threedigit": "281",
                "isLeaf": true
              },
              {
                "key": "2813",
                "title": "2813 Megaloblastic anemia NEC",
                "threedigit": "281",
                "isLeaf": true
              },
              {
                "key": "2814",
                "title": "2814 Protein defic anemia",
                "threedigit": "281",
                "isLeaf": true
              },
              {
                "key": "2818",
                "title": "2818 Nutritional anemia NEC",
                "threedigit": "281",
                "isLeaf": true
              },
              {
                "key": "2819",
                "title": "2819 Deficiency anemia NOS",
                "threedigit": "281",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hereditary hemolytic anemias",
            "title": "282 - Hereditary hemolytic anemias",
            "threedigit": "282",
            "children": [
              {
                "key": "2820",
                "title": "2820 Hereditary spherocytosis",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "2821",
                "title": "2821 Heredit elliptocytosis",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "2822",
                "title": "2822 Glutathione dis anemia",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "2823",
                "title": "2823 Enzyme defic anemia NEC",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28240",
                "title": "28240 Thalassemia, unspecified",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28241",
                "title": "28241 Thlasema Hb-S w/o crisis",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28242",
                "title": "28242 Thlassemia Hb-S w crisis",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28243",
                "title": "28243 Alpha thalassemia",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28244",
                "title": "28244 Beta thalassemia",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28245",
                "title": "28245 Delta-beta thalassemia",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28246",
                "title": "28246 Thalassemia minor",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28247",
                "title": "28247 Hgb E-beta thalassemia",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28249",
                "title": "28249 Thalassemia NEC",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "2825",
                "title": "2825 Sickle-cell trait",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28260",
                "title": "28260 Sickle cell disease NOS",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28261",
                "title": "28261 Hb-SS disease w/o crisis",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28262",
                "title": "28262 Hb-SS disease w crisis",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28263",
                "title": "28263 Hb-SS/hb-C dis w/o crsis",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28264",
                "title": "28264 Hb-S/Hb-C dis w crisis",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28268",
                "title": "28268 Hb-S dis w/o crisis NEC",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "28269",
                "title": "28269 Hb-SS dis NEC w crisis",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "2827",
                "title": "2827 Hemoglobinopathies NEC",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "2828",
                "title": "2828 Hered hemolytic anem NEC",
                "threedigit": "282",
                "isLeaf": true
              },
              {
                "key": "2829",
                "title": "2829 Hered hemolytic anem NOS",
                "threedigit": "282",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acquired hemolytic anemias",
            "title": "283 - Acquired hemolytic anemias",
            "threedigit": "283",
            "children": [
              {
                "key": "2830",
                "title": "2830 Autoimmun hemolytic anem",
                "threedigit": "283",
                "isLeaf": true
              },
              {
                "key": "28310",
                "title": "28310 Nonauto hem anemia NOS",
                "threedigit": "283",
                "isLeaf": true
              },
              {
                "key": "28311",
                "title": "28311 Hemolytic uremic synd",
                "threedigit": "283",
                "isLeaf": true
              },
              {
                "key": "28319",
                "title": "28319 Oth nonauto hem anemia",
                "threedigit": "283",
                "isLeaf": true
              },
              {
                "key": "2832",
                "title": "2832 Hemolytic hemoglobinuria",
                "threedigit": "283",
                "isLeaf": true
              },
              {
                "key": "2839",
                "title": "2839 Acq hemolytic anemia NOS",
                "threedigit": "283",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Aplastic anemia and other bone marrow failure syndromes",
            "title": "284 - Aplastic anemia and other bone marrow failure syndromes",
            "threedigit": "284",
            "children": [
              {
                "key": "28401",
                "title": "28401 Constitution RBC aplasia",
                "threedigit": "284",
                "isLeaf": true
              },
              {
                "key": "28409",
                "title": "28409 Const aplastc anemia NEC",
                "threedigit": "284",
                "isLeaf": true
              },
              {
                "key": "28411",
                "title": "28411 Antin chemo indcd pancyt",
                "threedigit": "284",
                "isLeaf": true
              },
              {
                "key": "28412",
                "title": "28412 Oth drg indcd pancytopna",
                "threedigit": "284",
                "isLeaf": true
              },
              {
                "key": "28419",
                "title": "28419 Other pancytopenia",
                "threedigit": "284",
                "isLeaf": true
              },
              {
                "key": "2842",
                "title": "2842 Myelophthisis",
                "threedigit": "284",
                "isLeaf": true
              },
              {
                "key": "28481",
                "title": "28481 Red cell aplasia",
                "threedigit": "284",
                "isLeaf": true
              },
              {
                "key": "28489",
                "title": "28489 Aplastic anemias NEC",
                "threedigit": "284",
                "isLeaf": true
              },
              {
                "key": "2849",
                "title": "2849 Aplastic anemia NOS",
                "threedigit": "284",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified anemias",
            "title": "285 - Other and unspecified anemias",
            "threedigit": "285",
            "children": [
              {
                "key": "2850",
                "title": "2850 Sideroblastic anemia",
                "threedigit": "285",
                "isLeaf": true
              },
              {
                "key": "2851",
                "title": "2851 Ac posthemorrhag anemia",
                "threedigit": "285",
                "isLeaf": true
              },
              {
                "key": "28521",
                "title": "28521 Anemia in chr kidney dis",
                "threedigit": "285",
                "isLeaf": true
              },
              {
                "key": "28522",
                "title": "28522 Anemia in neoplastic dis",
                "threedigit": "285",
                "isLeaf": true
              },
              {
                "key": "28529",
                "title": "28529 Anemia-other chronic dis",
                "threedigit": "285",
                "isLeaf": true
              },
              {
                "key": "2853",
                "title": "2853 Anemia d/t antineo chemo",
                "threedigit": "285",
                "isLeaf": true
              },
              {
                "key": "2858",
                "title": "2858 Anemia NEC",
                "threedigit": "285",
                "isLeaf": true
              },
              {
                "key": "2859",
                "title": "2859 Anemia NOS",
                "threedigit": "285",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Coagulation defects",
            "title": "286 - Coagulation defects",
            "threedigit": "286",
            "children": [
              {
                "key": "2860",
                "title": "2860 Cong factor viii diord",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "2861",
                "title": "2861 Cong factor IX disorder",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "2862",
                "title": "2862 Cong factor xi disorder",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "2863",
                "title": "2863 Cong def clot factor NEC",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "2864",
                "title": "2864 Von willebrand's disease",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "28652",
                "title": "28652 Acquired hemophilia",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "28653",
                "title": "28653 Antiphospholipid w hemor",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "28659",
                "title": "28659 Ot hem d/t circ anticoag",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "2866",
                "title": "2866 Defibrination syndrome",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "2867",
                "title": "2867 Acq coagul factor defic",
                "threedigit": "286",
                "isLeaf": true
              },
              {
                "key": "2869",
                "title": "2869 Coagulat defect NEC/NOS",
                "threedigit": "286",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Purpura and other hemorrhagic conditions",
            "title": "287 - Purpura and other hemorrhagic conditions",
            "threedigit": "287",
            "children": [
              {
                "key": "2870",
                "title": "2870 Allergic purpura",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "2871",
                "title": "2871 Thrombocytopathy",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "2872",
                "title": "2872 Purpura NOS",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "28730",
                "title": "28730 Prim thrombocytopen NOS",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "28731",
                "title": "28731 Immune thrombocyt purpra",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "28732",
                "title": "28732 Evans' syndrome",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "28733",
                "title": "28733 Cong/herid thromb purpra",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "28739",
                "title": "28739 Prim thrombocytopen NEC",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "28741",
                "title": "28741 Posttransfusion purpura",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "28749",
                "title": "28749 Sec thrombocytpenia NEC",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "2875",
                "title": "2875 Thrombocytopenia NOS",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "2878",
                "title": "2878 Hemorrhagic cond NEC",
                "threedigit": "287",
                "isLeaf": true
              },
              {
                "key": "2879",
                "title": "2879 Hemorrhagic cond NOS",
                "threedigit": "287",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of white blood cells",
            "title": "288 - Diseases of white blood cells",
            "threedigit": "288",
            "children": [
              {
                "key": "28800",
                "title": "28800 Neutropenia NOS",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28801",
                "title": "28801 Congenital neutropenia",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28802",
                "title": "28802 Cyclic neutropenia",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28803",
                "title": "28803 Drug induced neutropenia",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28804",
                "title": "28804 Neutropenia d/t infectn",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28809",
                "title": "28809 Neutropenia NEC",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "2881",
                "title": "2881 Function dis neutrophils",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "2882",
                "title": "2882 Genetic anomaly leukocyt",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "2883",
                "title": "2883 Eosinophilia",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "2884",
                "title": "2884 Hemophagocytic syndromes",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28850",
                "title": "28850 Leukocytopenia NOS",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28851",
                "title": "28851 Lymphocytopenia",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28859",
                "title": "28859 Decreased WBC count NEC",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28860",
                "title": "28860 Leukocytosis NOS",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28861",
                "title": "28861 Lymphocytosis-symptomatc",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28862",
                "title": "28862 Leukemoid reaction",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28863",
                "title": "28863 Monocytosis-symptomatic",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28864",
                "title": "28864 Plasmacytosis",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28865",
                "title": "28865 Basophilia",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28866",
                "title": "28866 Bandemia",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "28869",
                "title": "28869 Elevated WBC count NEC",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "2888",
                "title": "2888 Wbc disease NEC",
                "threedigit": "288",
                "isLeaf": true
              },
              {
                "key": "2889",
                "title": "2889 Wbc disease NOS",
                "threedigit": "288",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of blood and blood-forming organs",
            "title": "289 - Other diseases of blood and blood-forming organs",
            "threedigit": "289",
            "children": [
              {
                "key": "2890",
                "title": "2890 Secondary polycythemia",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "2891",
                "title": "2891 Chronic lymphadenitis",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "2892",
                "title": "2892 Mesenteric lymphadenitis",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "2893",
                "title": "2893 Lymphadenitis NOS",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "2894",
                "title": "2894 Hypersplenism",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28950",
                "title": "28950 Spleen disease NOS",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28951",
                "title": "28951 Chr congest splenomegaly",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28952",
                "title": "28952 Splenic sequestration",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28953",
                "title": "28953 Neutropenic splenomegaly",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28959",
                "title": "28959 Spleen disease NEC",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "2896",
                "title": "2896 Familial polycythemia",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "2897",
                "title": "2897 Methemoglobinemia",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28981",
                "title": "28981 Prim hypercoagulable st",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28982",
                "title": "28982 Sec hypercoagulable st",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28983",
                "title": "28983 Myelofibrosis",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28984",
                "title": "28984 Heparin-indu thrombocyto",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "28989",
                "title": "28989 Blood diseases NEC",
                "threedigit": "289",
                "isLeaf": true
              },
              {
                "key": "2899",
                "title": "2899 Blood disease NOS",
                "threedigit": "289",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Mental Disorders",
    "title": "290-319 Mental Disorders",
    "threedigit": "290",
    "children": [
      {
        "key": "Organic Psychotic Conditions",
        "title": "290-294 Organic Psychotic Conditions",
        "threedigit": "290",
        "children": [
          {
            "key": "Dementias",
            "title": "290 - Dementias",
            "threedigit": "290",
            "children": [
              {
                "key": "2900",
                "title": "2900 Senile dementia uncomp",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29010",
                "title": "29010 Presenile dementia",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29011",
                "title": "29011 Presenile delirium",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29012",
                "title": "29012 Presenile delusion",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29013",
                "title": "29013 Presenile depression",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29020",
                "title": "29020 Senile delusion",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29021",
                "title": "29021 Senile depressive",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "2903",
                "title": "2903 Senile delirium",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29040",
                "title": "29040 Vascular dementia,uncomp",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29041",
                "title": "29041 Vasc dementia w delirium",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29042",
                "title": "29042 Vasc dementia w delusion",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "29043",
                "title": "29043 Vasc dementia w depressn",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "2908",
                "title": "2908 Senile psychosis NEC",
                "threedigit": "290",
                "isLeaf": true
              },
              {
                "key": "2909",
                "title": "2909 Senile psychot cond NOS",
                "threedigit": "290",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Alcohol-induced mental disorders",
            "title": "291 - Alcohol-induced mental disorders",
            "threedigit": "291",
            "children": [
              {
                "key": "2910",
                "title": "2910 Delirium tremens",
                "threedigit": "291",
                "isLeaf": true
              },
              {
                "key": "2911",
                "title": "2911 Alcohol amnestic disordr",
                "threedigit": "291",
                "isLeaf": true
              },
              {
                "key": "2912",
                "title": "2912 Alcohol persist dementia",
                "threedigit": "291",
                "isLeaf": true
              },
              {
                "key": "2913",
                "title": "2913 Alcoh psy dis w hallucin",
                "threedigit": "291",
                "isLeaf": true
              },
              {
                "key": "2914",
                "title": "2914 Pathologic alcohol intox",
                "threedigit": "291",
                "isLeaf": true
              },
              {
                "key": "2915",
                "title": "2915 Alcoh psych dis w delus",
                "threedigit": "291",
                "isLeaf": true
              },
              {
                "key": "29181",
                "title": "29181 Alcohol withdrawal",
                "threedigit": "291",
                "isLeaf": true
              },
              {
                "key": "29182",
                "title": "29182 Alcoh induce sleep disor",
                "threedigit": "291",
                "isLeaf": true
              },
              {
                "key": "29189",
                "title": "29189 Alcohol mental disor NEC",
                "threedigit": "291",
                "isLeaf": true
              },
              {
                "key": "2919",
                "title": "2919 Alcohol mental disor NOS",
                "threedigit": "291",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Drug-induced mental disorders",
            "title": "292 - Drug-induced mental disorders",
            "threedigit": "292",
            "children": [
              {
                "key": "2920",
                "title": "2920 Drug withdrawal",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "29211",
                "title": "29211 Drug psych disor w delus",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "29212",
                "title": "29212 Drug psy dis w hallucin",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "2922",
                "title": "2922 Pathologic drug intox",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "29281",
                "title": "29281 Drug-induced delirium",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "29282",
                "title": "29282 Drug persisting dementia",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "29283",
                "title": "29283 Drug persist amnestc dis",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "29284",
                "title": "29284 Drug-induced mood disord",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "29285",
                "title": "29285 Drug induced sleep disor",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "29289",
                "title": "29289 Drug mental disorder NEC",
                "threedigit": "292",
                "isLeaf": true
              },
              {
                "key": "2929",
                "title": "2929 Drug mental disorder NOS",
                "threedigit": "292",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Transient mental disorders due to conditions classified elsewhere",
            "title": "293 - Transient mental disorders due to conditions classified elsewhere",
            "threedigit": "293",
            "children": [
              {
                "key": "2930",
                "title": "2930 Delirium d/t other cond",
                "threedigit": "293",
                "isLeaf": true
              },
              {
                "key": "2931",
                "title": "2931 Subacute delirium",
                "threedigit": "293",
                "isLeaf": true
              },
              {
                "key": "29381",
                "title": "29381 Psy dis w delus oth dis",
                "threedigit": "293",
                "isLeaf": true
              },
              {
                "key": "29382",
                "title": "29382 Psy dis w halluc oth dis",
                "threedigit": "293",
                "isLeaf": true
              },
              {
                "key": "29383",
                "title": "29383 Mood disorder other dis",
                "threedigit": "293",
                "isLeaf": true
              },
              {
                "key": "29384",
                "title": "29384 Anxiety disorder oth dis",
                "threedigit": "293",
                "isLeaf": true
              },
              {
                "key": "29389",
                "title": "29389 Transient mental dis NEC",
                "threedigit": "293",
                "isLeaf": true
              },
              {
                "key": "2939",
                "title": "2939 Transient mental dis NOS",
                "threedigit": "293",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Persistent mental disorders due to conditions classified elsewhere",
            "title": "294 - Persistent mental disorders due to conditions classified elsewhere",
            "threedigit": "294",
            "children": [
              {
                "key": "2940",
                "title": "2940 Amnestic disord oth dis",
                "threedigit": "294",
                "isLeaf": true
              },
              {
                "key": "29410",
                "title": "29410 Dementia w/o behav dist",
                "threedigit": "294",
                "isLeaf": true
              },
              {
                "key": "29411",
                "title": "29411 Dementia w behavior dist",
                "threedigit": "294",
                "isLeaf": true
              },
              {
                "key": "29420",
                "title": "29420 Demen NOS w/o behv dstrb",
                "threedigit": "294",
                "isLeaf": true
              },
              {
                "key": "29421",
                "title": "29421 Demen NOS w behav distrb",
                "threedigit": "294",
                "isLeaf": true
              },
              {
                "key": "2948",
                "title": "2948 Mental disor NEC oth dis",
                "threedigit": "294",
                "isLeaf": true
              },
              {
                "key": "2949",
                "title": "2949 Mental disor NOS oth dis",
                "threedigit": "294",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Psychoses",
        "title": "295-299 Other Psychoses",
        "threedigit": "295",
        "children": [
          {
            "key": "Schizophrenic disorders",
            "title": "295 - Schizophrenic disorders",
            "threedigit": "295",
            "children": [
              {
                "key": "29500",
                "title": "29500 Simpl schizophren-unspec",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29501",
                "title": "29501 Simpl schizophren-subchr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29502",
                "title": "29502 Simple schizophren-chr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29503",
                "title": "29503 Simp schiz-subchr/exacer",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29504",
                "title": "29504 Simpl schizo-chr/exacerb",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29505",
                "title": "29505 Simpl schizophren-remiss",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29510",
                "title": "29510 Hebephrenia-unspec",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29511",
                "title": "29511 Hebephrenia-subchronic",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29512",
                "title": "29512 Hebephrenia-chronic",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29513",
                "title": "29513 Hebephren-subchr/exacerb",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29514",
                "title": "29514 Hebephrenia-chr/exacerb",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29515",
                "title": "29515 Hebephrenia-remission",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29520",
                "title": "29520 Catatonia-unspec",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29521",
                "title": "29521 Catatonia-subchronic",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29522",
                "title": "29522 Catatonia-chronic",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29523",
                "title": "29523 Catatonia-subchr/exacerb",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29524",
                "title": "29524 Catatonia-chr/exacerb",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29525",
                "title": "29525 Catatonia-remission",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29530",
                "title": "29530 Paranoid schizo-unspec",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29531",
                "title": "29531 Paranoid schizo-subchr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29532",
                "title": "29532 Paranoid schizo-chronic",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29533",
                "title": "29533 Paran schizo-subchr/exac",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29534",
                "title": "29534 Paran schizo-chr/exacerb",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29535",
                "title": "29535 Paranoid schizo-remiss",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29540",
                "title": "29540 Schizophreniform dis NOS",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29541",
                "title": "29541 Schizophrenic dis-subchr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29542",
                "title": "29542 Schizophren dis-chronic",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29543",
                "title": "29543 Schizo dis-subchr/exacer",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29544",
                "title": "29544 Schizophr dis-chr/exacer",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29545",
                "title": "29545 Schizophrenic dis-remiss",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29550",
                "title": "29550 Latent schizophren-unsp",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29551",
                "title": "29551 Lat schizophren-subchr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29552",
                "title": "29552 Latent schizophren-chr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29553",
                "title": "29553 Lat schizo-subchr/exacer",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29554",
                "title": "29554 Latent schizo-chr/exacer",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29555",
                "title": "29555 Lat schizophren-remiss",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29560",
                "title": "29560 Schizophr dis resid NOS",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29561",
                "title": "29561 Schizoph dis resid-subch",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29562",
                "title": "29562 Schizophr dis resid-chr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29563",
                "title": "29563 Schizo resid subchr/exac",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29564",
                "title": "29564 Schizoph resid-chro/exac",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29565",
                "title": "29565 Schizoph dis resid-remis",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29570",
                "title": "29570 Schizoaffective dis NOS",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29571",
                "title": "29571 Schizoaffectv dis-subchr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29572",
                "title": "29572 Schizoaffective dis-chr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29573",
                "title": "29573 Schizoaff dis-subch/exac",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29574",
                "title": "29574 Schizoafftv dis-chr/exac",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29575",
                "title": "29575 Schizoaffectve dis-remis",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29580",
                "title": "29580 Schizophrenia NEC-unspec",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29581",
                "title": "29581 Schizophrenia NEC-subchr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29582",
                "title": "29582 Schizophrenia NEC-chr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29583",
                "title": "29583 Schizo NEC-subchr/exacer",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29584",
                "title": "29584 Schizo NEC-chr/exacerb",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29585",
                "title": "29585 Schizophrenia NEC-remiss",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29590",
                "title": "29590 Schizophrenia NOS-unspec",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29591",
                "title": "29591 Schizophrenia NOS-subchr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29592",
                "title": "29592 Schizophrenia NOS-chr",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29593",
                "title": "29593 Schizo NOS-subchr/exacer",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29594",
                "title": "29594 Schizo NOS-chr/exacerb",
                "threedigit": "295",
                "isLeaf": true
              },
              {
                "key": "29595",
                "title": "29595 Schizophrenia NOS-remiss",
                "threedigit": "295",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Episodic mood disorders",
            "title": "296 - Episodic mood disorders",
            "threedigit": "296",
            "children": [
              {
                "key": "29600",
                "title": "29600 Bipol I single manic NOS",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29601",
                "title": "29601 Bipol I single manc-mild",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29602",
                "title": "29602 Bipol I single manic-mod",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29603",
                "title": "29603 Bipol I sing-sev w/o psy",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29604",
                "title": "29604 Bipo I sin man-sev w psy",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29605",
                "title": "29605 Bipol I sing man rem NOS",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29606",
                "title": "29606 Bipol I single manic rem",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29610",
                "title": "29610 Recur manic dis-unspec",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29611",
                "title": "29611 Recur manic dis-mild",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29612",
                "title": "29612 Recur manic dis-mod",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29613",
                "title": "29613 Recur manic dis-severe",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29614",
                "title": "29614 Recur manic-sev w psycho",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29615",
                "title": "29615 Recur manic-part remiss",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29616",
                "title": "29616 Recur manic-full remiss",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29620",
                "title": "29620 Depress psychosis-unspec",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29621",
                "title": "29621 Depress psychosis-mild",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29622",
                "title": "29622 Depressive psychosis-mod",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29623",
                "title": "29623 Depress psychosis-severe",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29624",
                "title": "29624 Depr psychos-sev w psych",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29625",
                "title": "29625 Depr psychos-part remiss",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29626",
                "title": "29626 Depr psychos-full remiss",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29630",
                "title": "29630 Recurr depr psychos-unsp",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29631",
                "title": "29631 Recurr depr psychos-mild",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29632",
                "title": "29632 Recurr depr psychos-mod",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29633",
                "title": "29633 Recur depr psych-severe",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29634",
                "title": "29634 Rec depr psych-psychotic",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29635",
                "title": "29635 Recur depr psyc-part rem",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29636",
                "title": "29636 Recur depr psyc-full rem",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29640",
                "title": "29640 Bipol I currnt manic NOS",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29641",
                "title": "29641 Bipol I curnt manic-mild",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29642",
                "title": "29642 Bipol I currnt manic-mod",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29643",
                "title": "29643 Bipol I manc-sev w/o psy",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29644",
                "title": "29644 Bipol I manic-sev w psy",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29645",
                "title": "29645 Bipol I cur man part rem",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29646",
                "title": "29646 Bipol I cur man full rem",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29650",
                "title": "29650 Bipol I cur depres NOS",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29651",
                "title": "29651 Bipol I cur depress-mild",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29652",
                "title": "29652 Bipol I cur depress-mod",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29653",
                "title": "29653 Bipol I curr dep w/o psy",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29654",
                "title": "29654 Bipol I currnt dep w psy",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29655",
                "title": "29655 Bipol I cur dep rem NOS",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29656",
                "title": "29656 Bipol I currnt dep remis",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29660",
                "title": "29660 Bipol I currnt mixed NOS",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29661",
                "title": "29661 Bipol I currnt mix-mild",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29662",
                "title": "29662 Bipol I currnt mixed-mod",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29663",
                "title": "29663 Bipol I cur mix w/o psy",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29664",
                "title": "29664 Bipol I cur mixed w psy",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29665",
                "title": "29665 Bipol I cur mix-part rem",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29666",
                "title": "29666 Bipol I cur mixed remiss",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "2967",
                "title": "2967 Bipolor I current NOS",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29680",
                "title": "29680 Bipolar disorder NOS",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29681",
                "title": "29681 Atypical manic disorder",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29682",
                "title": "29682 Atypical depressive dis",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29689",
                "title": "29689 Bipolar disorder NEC",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29690",
                "title": "29690 Episodic mood disord NOS",
                "threedigit": "296",
                "isLeaf": true
              },
              {
                "key": "29699",
                "title": "29699 Episodic mood disord NEC",
                "threedigit": "296",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Delusional disorders",
            "title": "297 - Delusional disorders",
            "threedigit": "297",
            "children": [
              {
                "key": "2970",
                "title": "2970 Paranoid state, simple",
                "threedigit": "297",
                "isLeaf": true
              },
              {
                "key": "2971",
                "title": "2971 Delusional disorder",
                "threedigit": "297",
                "isLeaf": true
              },
              {
                "key": "2972",
                "title": "2972 Paraphrenia",
                "threedigit": "297",
                "isLeaf": true
              },
              {
                "key": "2973",
                "title": "2973 Shared psychotic disord",
                "threedigit": "297",
                "isLeaf": true
              },
              {
                "key": "2978",
                "title": "2978 Paranoid states NEC",
                "threedigit": "297",
                "isLeaf": true
              },
              {
                "key": "2979",
                "title": "2979 Paranoid state NOS",
                "threedigit": "297",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other nonorganic psychoses",
            "title": "298 - Other nonorganic psychoses",
            "threedigit": "298",
            "children": [
              {
                "key": "2980",
                "title": "2980 React depress psychosis",
                "threedigit": "298",
                "isLeaf": true
              },
              {
                "key": "2981",
                "title": "2981 Excitativ type psychosis",
                "threedigit": "298",
                "isLeaf": true
              },
              {
                "key": "2982",
                "title": "2982 Reactive confusion",
                "threedigit": "298",
                "isLeaf": true
              },
              {
                "key": "2983",
                "title": "2983 Acute paranoid reaction",
                "threedigit": "298",
                "isLeaf": true
              },
              {
                "key": "2984",
                "title": "2984 Psychogen paranoid psych",
                "threedigit": "298",
                "isLeaf": true
              },
              {
                "key": "2988",
                "title": "2988 React psychosis NEC/NOS",
                "threedigit": "298",
                "isLeaf": true
              },
              {
                "key": "2989",
                "title": "2989 Psychosis NOS",
                "threedigit": "298",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pervasive developmental disorders",
            "title": "299 - Pervasive developmental disorders",
            "threedigit": "299",
            "children": [
              {
                "key": "29900",
                "title": "29900 Autistic disord-current",
                "threedigit": "299",
                "isLeaf": true
              },
              {
                "key": "29901",
                "title": "29901 Autistic disord-residual",
                "threedigit": "299",
                "isLeaf": true
              },
              {
                "key": "29910",
                "title": "29910 Childhd disintegr-active",
                "threedigit": "299",
                "isLeaf": true
              },
              {
                "key": "29911",
                "title": "29911 Childhd disintegr-resid",
                "threedigit": "299",
                "isLeaf": true
              },
              {
                "key": "29980",
                "title": "29980 Pervasv dev dis-cur NEC",
                "threedigit": "299",
                "isLeaf": true
              },
              {
                "key": "29981",
                "title": "29981 Pervasv dev dis-res NEC",
                "threedigit": "299",
                "isLeaf": true
              },
              {
                "key": "29990",
                "title": "29990 Pervasv dev dis-cur NOS",
                "threedigit": "299",
                "isLeaf": true
              },
              {
                "key": "29991",
                "title": "29991 Pervasv dev dis-res NOS",
                "threedigit": "299",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Neurotic Disorders, Personality Disorders, And Other Nonpsychotic Mental Disorders",
        "title": "300-316 Neurotic Disorders, Personality Disorders, And Other Nonpsychotic Mental Disorders",
        "threedigit": "300",
        "children": [
          {
            "key": "Anxiety, dissociative and somatoform disorders",
            "title": "300 - Anxiety, dissociative and somatoform disorders",
            "threedigit": "300",
            "children": [
              {
                "key": "30000",
                "title": "30000 Anxiety state NOS",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30001",
                "title": "30001 Panic dis w/o agorphobia",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30002",
                "title": "30002 Generalized anxiety dis",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30009",
                "title": "30009 Anxiety state NEC",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30010",
                "title": "30010 Hysteria NOS",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30011",
                "title": "30011 Conversion disorder",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30012",
                "title": "30012 Dissociative amnesia",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30013",
                "title": "30013 Dissociative fugue",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30014",
                "title": "30014 Dissociatve identity dis",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30015",
                "title": "30015 Dissociative react NOS",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30016",
                "title": "30016 Factitious dis w symptom",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30019",
                "title": "30019 Factitious ill NEC/NOS",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30020",
                "title": "30020 Phobia NOS",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30021",
                "title": "30021 Agoraphobia w panic dis",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30022",
                "title": "30022 Agoraphobia w/o panic",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30023",
                "title": "30023 Social phobia",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30029",
                "title": "30029 Isolated/spec phobia NEC",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "3003",
                "title": "3003 Obsessive-compulsive dis",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "3004",
                "title": "3004 Dysthymic disorder",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "3005",
                "title": "3005 Neurasthenia",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "3006",
                "title": "3006 Depersonalization disord",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "3007",
                "title": "3007 Hypochondriasis",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30081",
                "title": "30081 Somatization disorder",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30082",
                "title": "30082 Undiff somatoform disrdr",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "30089",
                "title": "30089 Somatoform disorders NEC",
                "threedigit": "300",
                "isLeaf": true
              },
              {
                "key": "3009",
                "title": "3009 Nonpsychotic disord NOS",
                "threedigit": "300",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Personality disorders",
            "title": "301 - Personality disorders",
            "threedigit": "301",
            "children": [
              {
                "key": "3010",
                "title": "3010 Paranoid personality",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30110",
                "title": "30110 Affectiv personality NOS",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30111",
                "title": "30111 Chronic hypomanic person",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30112",
                "title": "30112 Chr depressive person",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30113",
                "title": "30113 Cyclothymic disorder",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30120",
                "title": "30120 Schizoid personality NOS",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30121",
                "title": "30121 Introverted personality",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30122",
                "title": "30122 Schizotypal person dis",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "3013",
                "title": "3013 Explosive personality",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "3014",
                "title": "3014 Obsessive-compulsive dis",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30150",
                "title": "30150 Histrionic person NOS",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30151",
                "title": "30151 Chr factitious illness",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30159",
                "title": "30159 Histrionic person NEC",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "3016",
                "title": "3016 Dependent personality",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "3017",
                "title": "3017 Antisocial personality",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30181",
                "title": "30181 Narcissistic personality",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30182",
                "title": "30182 Avoidant personality dis",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30183",
                "title": "30183 Borderline personality",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30184",
                "title": "30184 Passive-aggressiv person",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "30189",
                "title": "30189 Personality disorder NEC",
                "threedigit": "301",
                "isLeaf": true
              },
              {
                "key": "3019",
                "title": "3019 Personality disorder NOS",
                "threedigit": "301",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sexual and gender identity disorders",
            "title": "302 - Sexual and gender identity disorders",
            "threedigit": "302",
            "children": [
              {
                "key": "3020",
                "title": "3020 Ego-dystonic sex orient",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "3021",
                "title": "3021 Zoophilia",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "3022",
                "title": "3022 Pedophilia",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "3023",
                "title": "3023 Transvestic fetishism",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "3024",
                "title": "3024 Exhibitionism",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30250",
                "title": "30250 Trans-sexualism NOS",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30251",
                "title": "30251 Trans-sexualism, asexual",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30252",
                "title": "30252 Trans-sexual, homosexual",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30253",
                "title": "30253 Trans-sex, heterosexual",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "3026",
                "title": "3026 Gendr identity dis-child",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30270",
                "title": "30270 Psychosexual dysfunc NOS",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30271",
                "title": "30271 Hypoactive sex desire",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30272",
                "title": "30272 Inhibited sex excitement",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30273",
                "title": "30273 Female orgasmic disorder",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30274",
                "title": "30274 Male orgasmic disorder",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30275",
                "title": "30275 Premature ejaculation",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30276",
                "title": "30276 Dyspareunia,psychogenic",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30279",
                "title": "30279 Psychosexual dysfunc NEC",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30281",
                "title": "30281 Fetishism",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30282",
                "title": "30282 Voyeurism",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30283",
                "title": "30283 Sexual masochism",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30284",
                "title": "30284 Sexual sadism",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30285",
                "title": "30285 Gend iden dis,adol/adult",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "30289",
                "title": "30289 Psychosexual dis NEC",
                "threedigit": "302",
                "isLeaf": true
              },
              {
                "key": "3029",
                "title": "3029 Psychosexual dis NOS",
                "threedigit": "302",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Alcohol dependence syndrome",
            "title": "303 - Alcohol dependence syndrome",
            "threedigit": "303",
            "children": [
              {
                "key": "30300",
                "title": "30300 Ac alcohol intox-unspec",
                "threedigit": "303",
                "isLeaf": true
              },
              {
                "key": "30301",
                "title": "30301 Ac alcohol intox-contin",
                "threedigit": "303",
                "isLeaf": true
              },
              {
                "key": "30302",
                "title": "30302 Ac alcohol intox-episod",
                "threedigit": "303",
                "isLeaf": true
              },
              {
                "key": "30303",
                "title": "30303 Ac alcohol intox-remiss",
                "threedigit": "303",
                "isLeaf": true
              },
              {
                "key": "30390",
                "title": "30390 Alcoh dep NEC/NOS-unspec",
                "threedigit": "303",
                "isLeaf": true
              },
              {
                "key": "30391",
                "title": "30391 Alcoh dep NEC/NOS-contin",
                "threedigit": "303",
                "isLeaf": true
              },
              {
                "key": "30392",
                "title": "30392 Alcoh dep NEC/NOS-episod",
                "threedigit": "303",
                "isLeaf": true
              },
              {
                "key": "30393",
                "title": "30393 Alcoh dep NEC/NOS-remiss",
                "threedigit": "303",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Drug dependence",
            "title": "304 - Drug dependence",
            "threedigit": "304",
            "children": [
              {
                "key": "30400",
                "title": "30400 Opioid dependence-unspec",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30401",
                "title": "30401 Opioid dependence-contin",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30402",
                "title": "30402 Opioid dependence-episod",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30403",
                "title": "30403 Opioid dependence-remiss",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30410",
                "title": "30410 Sed,hyp,anxiolyt dep-NOS",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30411",
                "title": "30411 Sed,hyp,anxiolyt dep-con",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30412",
                "title": "30412 Sed,hyp,anxiolyt dep-epi",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30413",
                "title": "30413 Sed,hyp,anxiolyt dep-rem",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30420",
                "title": "30420 Cocaine depend-unspec",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30421",
                "title": "30421 Cocaine depend-contin",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30422",
                "title": "30422 Cocaine depend-episodic",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30423",
                "title": "30423 Cocaine depend-remiss",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30430",
                "title": "30430 Cannabis depend-unspec",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30431",
                "title": "30431 Cannabis depend-contin",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30432",
                "title": "30432 Cannabis depend-episodic",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30433",
                "title": "30433 Cannabis depend-remiss",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30440",
                "title": "30440 Amphetamin depend-unspec",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30441",
                "title": "30441 Amphetamin depend-contin",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30442",
                "title": "30442 Amphetamin depend-episod",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30443",
                "title": "30443 Amphetamin depend-remiss",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30450",
                "title": "30450 Hallucinogen dep-unspec",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30451",
                "title": "30451 Hallucinogen dep-contin",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30452",
                "title": "30452 Hallucinogen dep-episod",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30453",
                "title": "30453 Hallucinogen dep-remiss",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30460",
                "title": "30460 Drug depend NEC-unspec",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30461",
                "title": "30461 Drug depend NEC-contin",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30462",
                "title": "30462 Drug depend NEC-episodic",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30463",
                "title": "30463 Drug depend NEC-in rem",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30470",
                "title": "30470 Opioid/other dep-unspec",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30471",
                "title": "30471 Opioid/other dep-contin",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30472",
                "title": "30472 Opioid/other dep-episod",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30473",
                "title": "30473 Opioid/other dep-remiss",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30480",
                "title": "30480 Comb drug dep NEC-unspec",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30481",
                "title": "30481 Comb drug dep NEC-contin",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30482",
                "title": "30482 Comb drug dep NEC-episod",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30483",
                "title": "30483 Comb drug dep NEC-remiss",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30490",
                "title": "30490 Drug depend NOS-unspec",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30491",
                "title": "30491 Drug depend NOS-contin",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30492",
                "title": "30492 Drug depend NOS-episodic",
                "threedigit": "304",
                "isLeaf": true
              },
              {
                "key": "30493",
                "title": "30493 Drug depend NOS-remiss",
                "threedigit": "304",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nondependent abuse of drugs",
            "title": "305 - Nondependent abuse of drugs",
            "threedigit": "305",
            "children": [
              {
                "key": "30500",
                "title": "30500 Alcohol abuse-unspec",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30501",
                "title": "30501 Alcohol abuse-continuous",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30502",
                "title": "30502 Alcohol abuse-episodic",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30503",
                "title": "30503 Alcohol abuse-in remiss",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "3051",
                "title": "3051 Tobacco use disorder",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30520",
                "title": "30520 Cannabis abuse-unspec",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30521",
                "title": "30521 Cannabis abuse-contin",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30522",
                "title": "30522 Cannabis abuse-episodic",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30523",
                "title": "30523 Cannabis abuse-in remiss",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30530",
                "title": "30530 Hallucinog abuse-unspec",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30531",
                "title": "30531 Hallucinog abuse-contin",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30532",
                "title": "30532 Hallucinog abuse-episod",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30533",
                "title": "30533 Hallucinog abuse-remiss",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30540",
                "title": "30540 Sed,hyp,anxiolytc ab-NOS",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30541",
                "title": "30541 Sed,hyp,anxiolytc ab-con",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30542",
                "title": "30542 Sed,hyp,anxiolytc ab-epi",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30543",
                "title": "30543 Sed,hyp,anxiolytc ab-rem",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30550",
                "title": "30550 Opioid abuse-unspec",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30551",
                "title": "30551 Opioid abuse-continuous",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30552",
                "title": "30552 Opioid abuse-episodic",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30553",
                "title": "30553 Opioid abuse-in remiss",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30560",
                "title": "30560 Cocaine abuse-unspec",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30561",
                "title": "30561 Cocaine abuse-continuous",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30562",
                "title": "30562 Cocaine abuse-episodic",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30563",
                "title": "30563 Cocaine abuse-in remiss",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30570",
                "title": "30570 Amphetamine abuse-unspec",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30571",
                "title": "30571 Amphetamine abuse-contin",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30572",
                "title": "30572 Amphetamine abuse-episod",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30573",
                "title": "30573 Amphetamine abuse-remiss",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30580",
                "title": "30580 Antidepress abuse-unspec",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30581",
                "title": "30581 Antidepress abuse-contin",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30582",
                "title": "30582 Antidepress abuse-episod",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30583",
                "title": "30583 Antidepress abuse-remiss",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30590",
                "title": "30590 Drug abuse NEC-unspec",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30591",
                "title": "30591 Drug abuse NEC-contin",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30592",
                "title": "30592 Drug abuse NEC-episodic",
                "threedigit": "305",
                "isLeaf": true
              },
              {
                "key": "30593",
                "title": "30593 Drug abuse NEC-in remiss",
                "threedigit": "305",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Physiological malfunction arising from mental factors",
            "title": "306 - Physiological malfunction arising from mental factors",
            "threedigit": "306",
            "children": [
              {
                "key": "3060",
                "title": "3060 Psychogen musculskel dis",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "3061",
                "title": "3061 Psychogenic respir dis",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "3062",
                "title": "3062 Psychogen cardiovasc dis",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "3063",
                "title": "3063 Psychogenic skin disease",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "3064",
                "title": "3064 Psychogenic GI disease",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "30650",
                "title": "30650 Psychogenic gu dis NOS",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "30651",
                "title": "30651 Psychogenic vaginismus",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "30652",
                "title": "30652 Psychogenic dysmenorrhea",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "30653",
                "title": "30653 Psychogenic dysuria",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "30659",
                "title": "30659 Psychogenic gu dis NEC",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "3066",
                "title": "3066 Psychogen endocrine dis",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "3067",
                "title": "3067 Psychogenic sensory dis",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "3068",
                "title": "3068 Psychogenic disorder NEC",
                "threedigit": "306",
                "isLeaf": true
              },
              {
                "key": "3069",
                "title": "3069 Psychogenic disorder NOS",
                "threedigit": "306",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special symptoms or syndromes not elsewhere classified",
            "title": "307 - Special symptoms or syndromes not elsewhere classified",
            "threedigit": "307",
            "children": [
              {
                "key": "3070",
                "title": "3070 Adult onset flncy disord",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "3071",
                "title": "3071 Anorexia nervosa",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30720",
                "title": "30720 Tic disorder NOS",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30721",
                "title": "30721 Transient tic disorder",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30722",
                "title": "30722 Chr motor/vocal tic dis",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30723",
                "title": "30723 Tourette's disorder",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "3073",
                "title": "3073 Stereotypic movement dis",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30740",
                "title": "30740 Nonorganic sleep dis NOS",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30741",
                "title": "30741 Transient insomnia",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30742",
                "title": "30742 Persistent insomnia",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30743",
                "title": "30743 Transient hypersomnia",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30744",
                "title": "30744 Persistent hypersomnia",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30745",
                "title": "30745 Nonorganic circadian rhy",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30746",
                "title": "30746 Sleep arousal disorder",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30747",
                "title": "30747 Sleep stage dysfunc NEC",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30748",
                "title": "30748 Repetit sleep intrusion",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30749",
                "title": "30749 Nonorganic sleep dis NEC",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30750",
                "title": "30750 Eating disorder NOS",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30751",
                "title": "30751 Bulimia nervosa",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30752",
                "title": "30752 Pica",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30753",
                "title": "30753 Rumination disorder",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30754",
                "title": "30754 Psychogenic vomiting",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30759",
                "title": "30759 Eating disorder NEC",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "3076",
                "title": "3076 Enuresis",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "3077",
                "title": "3077 Encopresis",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30780",
                "title": "30780 Psychogenic pain NOS",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30781",
                "title": "30781 Tension headache",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "30789",
                "title": "30789 Psychogenic pain NEC",
                "threedigit": "307",
                "isLeaf": true
              },
              {
                "key": "3079",
                "title": "3079 Special symptom NEC/NOS",
                "threedigit": "307",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute reaction to stress",
            "title": "308 - Acute reaction to stress",
            "threedigit": "308",
            "children": [
              {
                "key": "3080",
                "title": "3080 Stress react, emotional",
                "threedigit": "308",
                "isLeaf": true
              },
              {
                "key": "3081",
                "title": "3081 Stress reaction, fugue",
                "threedigit": "308",
                "isLeaf": true
              },
              {
                "key": "3082",
                "title": "3082 Stress react, psychomot",
                "threedigit": "308",
                "isLeaf": true
              },
              {
                "key": "3083",
                "title": "3083 Acute stress react NEC",
                "threedigit": "308",
                "isLeaf": true
              },
              {
                "key": "3084",
                "title": "3084 Stress react, mixed dis",
                "threedigit": "308",
                "isLeaf": true
              },
              {
                "key": "3089",
                "title": "3089 Acute stress react NOS",
                "threedigit": "308",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Adjustment reaction",
            "title": "309 - Adjustment reaction",
            "threedigit": "309",
            "children": [
              {
                "key": "3090",
                "title": "3090 Adjustmnt dis w depressn",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "3091",
                "title": "3091 Prolong depressive react",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30921",
                "title": "30921 Separation anxiety",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30922",
                "title": "30922 Emancipation disorder",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30923",
                "title": "30923 Academic/work inhibition",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30924",
                "title": "30924 Adjustment dis w anxiety",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30928",
                "title": "30928 Adjust dis w anxiety/dep",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30929",
                "title": "30929 Adj react-emotion NEC",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "3093",
                "title": "3093 Adjust disor/dis conduct",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "3094",
                "title": "3094 Adj dis-emotion/conduct",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30981",
                "title": "30981 Posttraumatic stress dis",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30982",
                "title": "30982 Adjust react-phys sympt",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30983",
                "title": "30983 Adjust react-withdrawal",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "30989",
                "title": "30989 Adjustment reaction NEC",
                "threedigit": "309",
                "isLeaf": true
              },
              {
                "key": "3099",
                "title": "3099 Adjustment reaction NOS",
                "threedigit": "309",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Specific nonpsychotic mental disorders due to brain damage",
            "title": "310 - Specific nonpsychotic mental disorders due to brain damage",
            "threedigit": "310",
            "children": [
              {
                "key": "3100",
                "title": "3100 Frontal lobe syndrome",
                "threedigit": "310",
                "isLeaf": true
              },
              {
                "key": "3101",
                "title": "3101 Personality chg oth dis",
                "threedigit": "310",
                "isLeaf": true
              },
              {
                "key": "3102",
                "title": "3102 Postconcussion syndrome",
                "threedigit": "310",
                "isLeaf": true
              },
              {
                "key": "31081",
                "title": "31081 Pseudobulbar affect",
                "threedigit": "310",
                "isLeaf": true
              },
              {
                "key": "31089",
                "title": "31089 Nonpsych mntl disord NEC",
                "threedigit": "310",
                "isLeaf": true
              },
              {
                "key": "3109",
                "title": "3109 Nonpsychot brain syn NOS",
                "threedigit": "310",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Depressive disorder, not elsewhere classified",
            "title": "311 - Depressive disorder, not elsewhere classified",
            "threedigit": "311",
            "children": [
              {
                "key": "311",
                "title": "311 Depressive disorder NEC",
                "threedigit": "311",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disturbance of conduct not elsewhere classified",
            "title": "312 - Disturbance of conduct not elsewhere classified",
            "threedigit": "312",
            "children": [
              {
                "key": "31200",
                "title": "31200 Unsocial aggress-unspec",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31201",
                "title": "31201 Unsocial aggression-mild",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31202",
                "title": "31202 Unsocial aggression-mod",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31203",
                "title": "31203 Unsocial aggress-severe",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31210",
                "title": "31210 Unsocial unaggress-unsp",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31211",
                "title": "31211 Unsocial unaggress-mild",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31212",
                "title": "31212 Unsocial unaggress-mod",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31213",
                "title": "31213 Unsocial unaggr-severe",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31220",
                "title": "31220 Social conduct dis-unsp",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31221",
                "title": "31221 Social conduct dis-mild",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31222",
                "title": "31222 Social conduct dis-mod",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31223",
                "title": "31223 Social conduct dis-sev",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31230",
                "title": "31230 Impulse control dis NOS",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31231",
                "title": "31231 Pathological gambling",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31232",
                "title": "31232 Kleptomania",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31233",
                "title": "31233 Pyromania",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31234",
                "title": "31234 Intermitt explosive dis",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31235",
                "title": "31235 Isolated explosive dis",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31239",
                "title": "31239 Impulse control dis NEC",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "3124",
                "title": "3124 Mix dis conduct/emotion",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31281",
                "title": "31281 Cndct dsrdr chldhd onst",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31282",
                "title": "31282 Cndct dsrdr adlscnt onst",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "31289",
                "title": "31289 Other conduct disorder",
                "threedigit": "312",
                "isLeaf": true
              },
              {
                "key": "3129",
                "title": "3129 Conduct disturbance NOS",
                "threedigit": "312",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disturbance of emotions specific to childhood and adolescence",
            "title": "313 - Disturbance of emotions specific to childhood and adolescence",
            "threedigit": "313",
            "children": [
              {
                "key": "3130",
                "title": "3130 Overanxious disorder",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "3131",
                "title": "3131 Misery & unhappiness dis",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "31321",
                "title": "31321 Shyness disorder-child",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "31322",
                "title": "31322 Introverted dis-child",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "31323",
                "title": "31323 Selective mutism",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "3133",
                "title": "3133 Relationship problems",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "31381",
                "title": "31381 Opposition defiant disor",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "31382",
                "title": "31382 Identity disorder",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "31383",
                "title": "31383 Academic underachievment",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "31389",
                "title": "31389 Emotional dis child NEC",
                "threedigit": "313",
                "isLeaf": true
              },
              {
                "key": "3139",
                "title": "3139 Emotional dis child NOS",
                "threedigit": "313",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hyperkinetic syndrome of childhood",
            "title": "314 - Hyperkinetic syndrome of childhood",
            "threedigit": "314",
            "children": [
              {
                "key": "31400",
                "title": "31400 Attn defic nonhyperact",
                "threedigit": "314",
                "isLeaf": true
              },
              {
                "key": "31401",
                "title": "31401 Attn deficit w hyperact",
                "threedigit": "314",
                "isLeaf": true
              },
              {
                "key": "3141",
                "title": "3141 Hyperkinet w devel delay",
                "threedigit": "314",
                "isLeaf": true
              },
              {
                "key": "3142",
                "title": "3142 Hyperkinetic conduct dis",
                "threedigit": "314",
                "isLeaf": true
              },
              {
                "key": "3148",
                "title": "3148 Other hyperkinetic synd",
                "threedigit": "314",
                "isLeaf": true
              },
              {
                "key": "3149",
                "title": "3149 Hyperkinetic synd NOS",
                "threedigit": "314",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Specific delays in development",
            "title": "315 - Specific delays in development",
            "threedigit": "315",
            "children": [
              {
                "key": "31500",
                "title": "31500 Reading disorder NOS",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "31501",
                "title": "31501 Alexia",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "31502",
                "title": "31502 Developmental dyslexia",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "31509",
                "title": "31509 Reading disorder NEC",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "3151",
                "title": "3151 Mathematics disorder",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "3152",
                "title": "3152 Oth learning difficulty",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "31531",
                "title": "31531 Expressive language dis",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "31532",
                "title": "31532 Recp-expres language dis",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "31534",
                "title": "31534 Speech del d/t hear loss",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "31535",
                "title": "31535 Chldhd onset flncy disor",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "31539",
                "title": "31539 Speech/language dis NEC",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "3154",
                "title": "3154 Devel coordination dis",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "3155",
                "title": "3155 Mixed development dis",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "3158",
                "title": "3158 Development delays NEC",
                "threedigit": "315",
                "isLeaf": true
              },
              {
                "key": "3159",
                "title": "3159 Development delay NOS",
                "threedigit": "315",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Psychic factors associated with diseases classified elsewhere",
            "title": "316 - Psychic factors associated with diseases classified elsewhere",
            "threedigit": "316",
            "children": [
              {
                "key": "316",
                "title": "316 Psychic factor w oth dis",
                "threedigit": "316",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Intellectual Disabilities",
        "title": "317-319 Intellectual Disabilities",
        "threedigit": "317",
        "children": [
          {
            "key": "Mild intellectual disabilities",
            "title": "317 - Mild intellectual disabilities",
            "threedigit": "317",
            "children": [
              {
                "key": "317",
                "title": "317 Mild intellect disabilty",
                "threedigit": "317",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other specified intellectual disabilities",
            "title": "318 - Other specified intellectual disabilities",
            "threedigit": "318",
            "children": [
              {
                "key": "3180",
                "title": "3180 Mod intellect disability",
                "threedigit": "318",
                "isLeaf": true
              },
              {
                "key": "3181",
                "title": "3181 Sev intellect disability",
                "threedigit": "318",
                "isLeaf": true
              },
              {
                "key": "3182",
                "title": "3182 Profnd intellct disablty",
                "threedigit": "318",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Unspecified intellectual disabilities",
            "title": "319 - Unspecified intellectual disabilities",
            "threedigit": "319",
            "children": [
              {
                "key": "319",
                "title": "319 Intellect disability NOS",
                "threedigit": "319",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Diseases Of The Nervous System And Sense Organs",
    "title": "320-389 Diseases Of The Nervous System And Sense Organs",
    "threedigit": "320",
    "children": [
      {
        "key": "Inflammatory Diseases Of The Central Nervous System",
        "title": "320-327 Inflammatory Diseases Of The Central Nervous System",
        "threedigit": "320",
        "children": [
          {
            "key": "Bacterial meningitis",
            "title": "320 - Bacterial meningitis",
            "threedigit": "320",
            "children": [
              {
                "key": "3200",
                "title": "3200 Hemophilus meningitis",
                "threedigit": "320",
                "isLeaf": true
              },
              {
                "key": "3201",
                "title": "3201 Pneumococcal meningitis",
                "threedigit": "320",
                "isLeaf": true
              },
              {
                "key": "3202",
                "title": "3202 Streptococcal meningitis",
                "threedigit": "320",
                "isLeaf": true
              },
              {
                "key": "3203",
                "title": "3203 Staphylococc meningitis",
                "threedigit": "320",
                "isLeaf": true
              },
              {
                "key": "3207",
                "title": "3207 Mening in oth bact dis",
                "threedigit": "320",
                "isLeaf": true
              },
              {
                "key": "32081",
                "title": "32081 Anaerobic meningitis",
                "threedigit": "320",
                "isLeaf": true
              },
              {
                "key": "32082",
                "title": "32082 Mningts gram-neg bct NEC",
                "threedigit": "320",
                "isLeaf": true
              },
              {
                "key": "32089",
                "title": "32089 Meningitis oth spcf bact",
                "threedigit": "320",
                "isLeaf": true
              },
              {
                "key": "3209",
                "title": "3209 Bacterial meningitis NOS",
                "threedigit": "320",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Meningitis due to other organisms",
            "title": "321 - Meningitis due to other organisms",
            "threedigit": "321",
            "children": [
              {
                "key": "3210",
                "title": "3210 Cryptococcal meningitis",
                "threedigit": "321",
                "isLeaf": true
              },
              {
                "key": "3211",
                "title": "3211 Mening in oth fungal dis",
                "threedigit": "321",
                "isLeaf": true
              },
              {
                "key": "3212",
                "title": "3212 Mening in oth viral dis",
                "threedigit": "321",
                "isLeaf": true
              },
              {
                "key": "3213",
                "title": "3213 Trypanosomiasis meningit",
                "threedigit": "321",
                "isLeaf": true
              },
              {
                "key": "3214",
                "title": "3214 Meningit d/t sarcoidosis",
                "threedigit": "321",
                "isLeaf": true
              },
              {
                "key": "3218",
                "title": "3218 Mening in oth nonbac dis",
                "threedigit": "321",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Meningitis of unspecified cause",
            "title": "322 - Meningitis of unspecified cause",
            "threedigit": "322",
            "children": [
              {
                "key": "3220",
                "title": "3220 Nonpyogenic meningitis",
                "threedigit": "322",
                "isLeaf": true
              },
              {
                "key": "3221",
                "title": "3221 Eosinophilic meningitis",
                "threedigit": "322",
                "isLeaf": true
              },
              {
                "key": "3222",
                "title": "3222 Chronic meningitis",
                "threedigit": "322",
                "isLeaf": true
              },
              {
                "key": "3229",
                "title": "3229 Meningitis NOS",
                "threedigit": "322",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Encephalitis myelitis and encephalomyelitis",
            "title": "323 - Encephalitis myelitis and encephalomyelitis",
            "threedigit": "323",
            "children": [
              {
                "key": "32301",
                "title": "32301 Enceph/encephmye oth dis",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32302",
                "title": "32302 Myelitis-oth viral dis",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "3231",
                "title": "3231 Rickettsial encephalitis",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "3232",
                "title": "3232 Protozoal encephalitis",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32341",
                "title": "32341 Ot encph/mye ot inf else",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32342",
                "title": "32342 Oth myelitis ot inf else",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32351",
                "title": "32351 Enceph/myel folwg immune",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32352",
                "title": "32352 Myelitis follwg immune",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32361",
                "title": "32361 Inf ac dis encephalomyel",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32362",
                "title": "32362 Postinf encephalitis NEC",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32363",
                "title": "32363 Postinfectious myelitis",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32371",
                "title": "32371 Toxic encph & encephlomy",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32372",
                "title": "32372 Toxic myelitis",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32381",
                "title": "32381 Enceph & encephlalo NEC",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "32382",
                "title": "32382 Myelitis cause NEC",
                "threedigit": "323",
                "isLeaf": true
              },
              {
                "key": "3239",
                "title": "3239 Encephalitis NOS",
                "threedigit": "323",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Intracranial and intraspinal abscess",
            "title": "324 - Intracranial and intraspinal abscess",
            "threedigit": "324",
            "children": [
              {
                "key": "3240",
                "title": "3240 Intracranial abscess",
                "threedigit": "324",
                "isLeaf": true
              },
              {
                "key": "3241",
                "title": "3241 Intraspinal abscess",
                "threedigit": "324",
                "isLeaf": true
              },
              {
                "key": "3249",
                "title": "3249 Cns abscess NOS",
                "threedigit": "324",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Phlebitis and thrombophlebitis of intracranial venous sinuses",
            "title": "325 - Phlebitis and thrombophlebitis of intracranial venous sinuses",
            "threedigit": "325",
            "children": [
              {
                "key": "325",
                "title": "325 Phlebitis intrcran sinus",
                "threedigit": "325",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of intracranial abscess or pyogenic infection",
            "title": "326 - Late effects of intracranial abscess or pyogenic infection",
            "threedigit": "326",
            "children": [
              {
                "key": "326",
                "title": "326 Late eff cns abscess",
                "threedigit": "326",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Organic sleep disorders",
            "title": "327 - Organic sleep disorders",
            "threedigit": "327",
            "children": [
              {
                "key": "32700",
                "title": "32700 Organic insomnia NOS",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32701",
                "title": "32701 Insomnia in other dis",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32702",
                "title": "32702 Insomnia dt mental disor",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32709",
                "title": "32709 Organic insomnia NEC",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32710",
                "title": "32710 Organic hypersomnia NOS",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32711",
                "title": "32711 Idio hypersom-long sleep",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32712",
                "title": "32712 Idio hypersom-no lng slp",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32713",
                "title": "32713 Recurrent hypersomnia",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32714",
                "title": "32714 Hypersomnia in other dis",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32715",
                "title": "32715 Hypersom dt mental disor",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32719",
                "title": "32719 Organic hypersomnia NEC",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32720",
                "title": "32720 Organic sleep apnea NOS",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32721",
                "title": "32721 Prim central sleep apnea",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32722",
                "title": "32722 High altitude breathing",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32723",
                "title": "32723 Obstructive sleep apnea",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32724",
                "title": "32724 Idiopath sleep hypovent",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32725",
                "title": "32725 Cong cntrl hypovent synd",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32726",
                "title": "32726 Sleep hypovent oth dis",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32727",
                "title": "32727 Cntrl sleep apnea ot dis",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32729",
                "title": "32729 Organic sleep apnea NEC",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32730",
                "title": "32730 Circadian rhym sleep NOS",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32731",
                "title": "32731 Circadian rhy-delay slp",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32732",
                "title": "32732 Circadian rhy-advc sleep",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32733",
                "title": "32733 Circadian rhym-irreg slp",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32734",
                "title": "32734 Circadian rhym-free run",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32735",
                "title": "32735 Circadian rhythm-jetlag",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32736",
                "title": "32736 Circadian rhy-shift work",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32737",
                "title": "32737 Circadian rhym oth dis",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32739",
                "title": "32739 Circadian rhym sleep NEC",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32740",
                "title": "32740 Organic parasomnia NOS",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32741",
                "title": "32741 Confusional arousals",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32742",
                "title": "32742 REM sleep behavior dis",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32743",
                "title": "32743 Recurrnt sleep paralysis",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32744",
                "title": "32744 Parasomnia oth diseases",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32749",
                "title": "32749 Organic parasomnia NEC",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32751",
                "title": "32751 Periodic limb movement",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32752",
                "title": "32752 Sleep related leg cramps",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32753",
                "title": "32753 Sleep related bruxism",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "32759",
                "title": "32759 Organic sleep movemt NEC",
                "threedigit": "327",
                "isLeaf": true
              },
              {
                "key": "3278",
                "title": "3278 Sleep organic disord NEC",
                "threedigit": "327",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Hereditary And Degenerative Diseases Of The Central Nervous System",
        "title": "330-337 Hereditary And Degenerative Diseases Of The Central Nervous System",
        "threedigit": "330",
        "children": [
          {
            "key": "Cerebral degenerations usually manifest in childhood",
            "title": "330 - Cerebral degenerations usually manifest in childhood",
            "threedigit": "330",
            "children": [
              {
                "key": "3300",
                "title": "3300 Leukodystrophy",
                "threedigit": "330",
                "isLeaf": true
              },
              {
                "key": "3301",
                "title": "3301 Cerebral lipidoses",
                "threedigit": "330",
                "isLeaf": true
              },
              {
                "key": "3302",
                "title": "3302 Cereb degen in lipidosis",
                "threedigit": "330",
                "isLeaf": true
              },
              {
                "key": "3303",
                "title": "3303 Cerb deg chld in oth dis",
                "threedigit": "330",
                "isLeaf": true
              },
              {
                "key": "3308",
                "title": "3308 Cereb degen in child NEC",
                "threedigit": "330",
                "isLeaf": true
              },
              {
                "key": "3309",
                "title": "3309 Cereb degen in child NOS",
                "threedigit": "330",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other cerebral degenerations",
            "title": "331 - Other cerebral degenerations",
            "threedigit": "331",
            "children": [
              {
                "key": "3310",
                "title": "3310 Alzheimer's disease",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "33111",
                "title": "33111 Pick's disease",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "33119",
                "title": "33119 Frontotemp dementia NEC",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "3312",
                "title": "3312 Senile degenerat brain",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "3313",
                "title": "3313 Communicat hydrocephalus",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "3314",
                "title": "3314 Obstructiv hydrocephalus",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "3315",
                "title": "3315 Norml pressure hydroceph",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "3316",
                "title": "3316 Corticobasal degneration",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "3317",
                "title": "3317 Cereb degen in oth dis",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "33181",
                "title": "33181 Reye's syndrome",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "33182",
                "title": "33182 Dementia w Lewy bodies",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "33183",
                "title": "33183 Mild cognitive impairemt",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "33189",
                "title": "33189 Cereb degeneration NEC",
                "threedigit": "331",
                "isLeaf": true
              },
              {
                "key": "3319",
                "title": "3319 Cereb degeneration NOS",
                "threedigit": "331",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Parkinson's disease",
            "title": "332 - Parkinson's disease",
            "threedigit": "332",
            "children": [
              {
                "key": "3320",
                "title": "3320 Paralysis agitans",
                "threedigit": "332",
                "isLeaf": true
              },
              {
                "key": "3321",
                "title": "3321 Secondary parkinsonism",
                "threedigit": "332",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other extrapyramidal disease and abnormal movement disorders",
            "title": "333 - Other extrapyramidal disease and abnormal movement disorders",
            "threedigit": "333",
            "children": [
              {
                "key": "3330",
                "title": "3330 Degen basal ganglia NEC",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "3331",
                "title": "3331 Tremor NEC",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "3332",
                "title": "3332 Myoclonus",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "3333",
                "title": "3333 Tics of organic origin",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "3334",
                "title": "3334 Huntington's chorea",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "3335",
                "title": "3335 Chorea NEC",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "3336",
                "title": "3336 Genetic torsion dystonia",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33371",
                "title": "33371 Athetoid cerebral palsy",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33372",
                "title": "33372 Acute dystonia d/t drugs",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33379",
                "title": "33379 Acq torsion dystonia NEC",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33381",
                "title": "33381 Blepharospasm",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33382",
                "title": "33382 Orofacial dyskinesia",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33383",
                "title": "33383 Spasmodic torticollis",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33384",
                "title": "33384 Organic writers' cramp",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33385",
                "title": "33385 Subac dyskinesa d/t drug",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33389",
                "title": "33389 Fragm torsion dyston NEC",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33390",
                "title": "33390 Extrapyramidal dis NOS",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33391",
                "title": "33391 Stiff-man syndrome",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33392",
                "title": "33392 Neuroleptic malgnt synd",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33393",
                "title": "33393 Bnign shuddering attacks",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33394",
                "title": "33394 Restless legs syndrome",
                "threedigit": "333",
                "isLeaf": true
              },
              {
                "key": "33399",
                "title": "33399 Extrapyramidal dis NEC",
                "threedigit": "333",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Spinocerebellar disease",
            "title": "334 - Spinocerebellar disease",
            "threedigit": "334",
            "children": [
              {
                "key": "3340",
                "title": "3340 Friedreich's ataxia",
                "threedigit": "334",
                "isLeaf": true
              },
              {
                "key": "3341",
                "title": "3341 Hered spastic paraplegia",
                "threedigit": "334",
                "isLeaf": true
              },
              {
                "key": "3342",
                "title": "3342 Primary cerebellar degen",
                "threedigit": "334",
                "isLeaf": true
              },
              {
                "key": "3343",
                "title": "3343 Cerebellar ataxia NEC",
                "threedigit": "334",
                "isLeaf": true
              },
              {
                "key": "3344",
                "title": "3344 Cerebel atax in oth dis",
                "threedigit": "334",
                "isLeaf": true
              },
              {
                "key": "3348",
                "title": "3348 Spinocerebellar dis NEC",
                "threedigit": "334",
                "isLeaf": true
              },
              {
                "key": "3349",
                "title": "3349 Spinocerebellar dis NOS",
                "threedigit": "334",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Anterior horn cell disease",
            "title": "335 - Anterior horn cell disease",
            "threedigit": "335",
            "children": [
              {
                "key": "3350",
                "title": "3350 Werdnig-hoffmann disease",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "33510",
                "title": "33510 Spinal muscl atrophy NOS",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "33511",
                "title": "33511 Kugelberg-welander dis",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "33519",
                "title": "33519 Spinal muscl atrophy NEC",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "33520",
                "title": "33520 Amyotrophic sclerosis",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "33521",
                "title": "33521 Prog muscular atrophy",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "33522",
                "title": "33522 Progressive bulbar palsy",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "33523",
                "title": "33523 Pseudobulbar palsy",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "33524",
                "title": "33524 Prim lateral sclerosis",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "33529",
                "title": "33529 Motor neuron disease NEC",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "3358",
                "title": "3358 Ant horn cell dis NEC",
                "threedigit": "335",
                "isLeaf": true
              },
              {
                "key": "3359",
                "title": "3359 Ant horn cell dis NOS",
                "threedigit": "335",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of spinal cord",
            "title": "336 - Other diseases of spinal cord",
            "threedigit": "336",
            "children": [
              {
                "key": "3360",
                "title": "3360 Syringomyelia",
                "threedigit": "336",
                "isLeaf": true
              },
              {
                "key": "3361",
                "title": "3361 Vascular myelopathies",
                "threedigit": "336",
                "isLeaf": true
              },
              {
                "key": "3362",
                "title": "3362 Comb deg cord in oth dis",
                "threedigit": "336",
                "isLeaf": true
              },
              {
                "key": "3363",
                "title": "3363 Myelopathy in oth dis",
                "threedigit": "336",
                "isLeaf": true
              },
              {
                "key": "3368",
                "title": "3368 Myelopathy NEC",
                "threedigit": "336",
                "isLeaf": true
              },
              {
                "key": "3369",
                "title": "3369 Spinal cord disease NOS",
                "threedigit": "336",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of the autonomic nervous system",
            "title": "337 - Disorders of the autonomic nervous system",
            "threedigit": "337",
            "children": [
              {
                "key": "33700",
                "title": "33700 Idio perph auto neur NOS",
                "threedigit": "337",
                "isLeaf": true
              },
              {
                "key": "33701",
                "title": "33701 Carotid sinus syndrome",
                "threedigit": "337",
                "isLeaf": true
              },
              {
                "key": "33709",
                "title": "33709 Idio perph auto neur NEC",
                "threedigit": "337",
                "isLeaf": true
              },
              {
                "key": "3371",
                "title": "3371 Aut neuropthy in oth dis",
                "threedigit": "337",
                "isLeaf": true
              },
              {
                "key": "33720",
                "title": "33720 Unsp rflx sympth dystrph",
                "threedigit": "337",
                "isLeaf": true
              },
              {
                "key": "33721",
                "title": "33721 Rflx sym dystrph up limb",
                "threedigit": "337",
                "isLeaf": true
              },
              {
                "key": "33722",
                "title": "33722 Rflx sym dystrph lwr lmb",
                "threedigit": "337",
                "isLeaf": true
              },
              {
                "key": "33729",
                "title": "33729 Rflx sym dystrph oth st",
                "threedigit": "337",
                "isLeaf": true
              },
              {
                "key": "3373",
                "title": "3373 Autonomic dysreflexia",
                "threedigit": "337",
                "isLeaf": true
              },
              {
                "key": "3379",
                "title": "3379 Autonomic nerve dis NEC",
                "threedigit": "337",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Pain",
        "title": "338-338 Pain",
        "threedigit": "338",
        "children": [
          {
            "key": "Pain, not elsewhere classified",
            "title": "338 - Pain, not elsewhere classified",
            "threedigit": "338",
            "children": [
              {
                "key": "3380",
                "title": "3380 Central pain syndrome",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "33811",
                "title": "33811 Acute pain due to trauma",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "33812",
                "title": "33812 Acute post-thoracot pain",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "33818",
                "title": "33818 Acute postop pain NEC",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "33819",
                "title": "33819 Acute pain NEC",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "33821",
                "title": "33821 Chronc pain d/t trauma",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "33822",
                "title": "33822 Chron post-thoracot pain",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "33828",
                "title": "33828 Chronic postop pain NEC",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "33829",
                "title": "33829 Chronic pain NEC",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "3383",
                "title": "3383 Neoplasm related pain",
                "threedigit": "338",
                "isLeaf": true
              },
              {
                "key": "3384",
                "title": "3384 Chronic pain syndrome",
                "threedigit": "338",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Headache Syndromes",
        "title": "339-339 Other Headache Syndromes",
        "threedigit": "339",
        "children": [
          {
            "key": "Other headache syndromes",
            "title": "339 - Other headache syndromes",
            "threedigit": "339",
            "children": [
              {
                "key": "33900",
                "title": "33900 Cluster headache syn NOS",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33901",
                "title": "33901 Episodc cluster headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33902",
                "title": "33902 Chronic cluster headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33903",
                "title": "33903 Episdc paroxyml hemicran",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33904",
                "title": "33904 Chr paroxysml hemicrania",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33905",
                "title": "33905 Shrt lst uni nral hdache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33909",
                "title": "33909 Trigem autonmc cephl NEC",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33910",
                "title": "33910 Tension headache NOS",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33911",
                "title": "33911 Episdic tension headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33912",
                "title": "33912 Chronic tension headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33920",
                "title": "33920 Post-trauma headache NOS",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33921",
                "title": "33921 Ac post-trauma headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33922",
                "title": "33922 Chr post-trauma headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "3393",
                "title": "3393 Drug induce headache NEC",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33941",
                "title": "33941 Hemicrania continua",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33942",
                "title": "33942 New daily pers headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33943",
                "title": "33943 Prim thnderclap headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33944",
                "title": "33944 Comp headache synd NEC",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33981",
                "title": "33981 Hypnic headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33982",
                "title": "33982 Headache w sex activity",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33983",
                "title": "33983 Primary cough headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33984",
                "title": "33984 Prim exertion headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33985",
                "title": "33985 Prim stabbing headache",
                "threedigit": "339",
                "isLeaf": true
              },
              {
                "key": "33989",
                "title": "33989 Headache syndrome NEC",
                "threedigit": "339",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Disorders Of The Central Nervous System",
        "title": "340-349 Other Disorders Of The Central Nervous System",
        "threedigit": "340",
        "children": [
          {
            "key": "Multiple sclerosis",
            "title": "340 - Multiple sclerosis",
            "threedigit": "340",
            "children": [
              {
                "key": "340",
                "title": "340 Multiple sclerosis",
                "threedigit": "340",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other demyelinating diseases of central nervous system",
            "title": "341 - Other demyelinating diseases of central nervous system",
            "threedigit": "341",
            "children": [
              {
                "key": "3410",
                "title": "3410 Neuromyelitis optica",
                "threedigit": "341",
                "isLeaf": true
              },
              {
                "key": "3411",
                "title": "3411 Schilder's disease",
                "threedigit": "341",
                "isLeaf": true
              },
              {
                "key": "34120",
                "title": "34120 Acute myelitis NOS",
                "threedigit": "341",
                "isLeaf": true
              },
              {
                "key": "34121",
                "title": "34121 Acute myelitis oth cond",
                "threedigit": "341",
                "isLeaf": true
              },
              {
                "key": "34122",
                "title": "34122 Idiopathc trans myelitis",
                "threedigit": "341",
                "isLeaf": true
              },
              {
                "key": "3418",
                "title": "3418 Cns demyelination NEC",
                "threedigit": "341",
                "isLeaf": true
              },
              {
                "key": "3419",
                "title": "3419 Cns demyelination NOS",
                "threedigit": "341",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hemiplegia and hemiparesis",
            "title": "342 - Hemiplegia and hemiparesis",
            "threedigit": "342",
            "children": [
              {
                "key": "34200",
                "title": "34200 Flccd hmiplga unspf side",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34201",
                "title": "34201 Flccd hmiplga domnt side",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34202",
                "title": "34202 Flccd hmiplg nondmnt sde",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34210",
                "title": "34210 Spstc hmiplga unspf side",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34211",
                "title": "34211 Spstc hmiplga domnt side",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34212",
                "title": "34212 Spstc hmiplg nondmnt sde",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34280",
                "title": "34280 Ot sp hmiplga unspf side",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34281",
                "title": "34281 Ot sp hmiplga domnt side",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34282",
                "title": "34282 Ot sp hmiplg nondmnt sde",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34290",
                "title": "34290 Unsp hemiplga unspf side",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34291",
                "title": "34291 Unsp hemiplga domnt side",
                "threedigit": "342",
                "isLeaf": true
              },
              {
                "key": "34292",
                "title": "34292 Unsp hmiplga nondmnt sde",
                "threedigit": "342",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Infantile cerebral palsy",
            "title": "343 - Infantile cerebral palsy",
            "threedigit": "343",
            "children": [
              {
                "key": "3430",
                "title": "3430 Congenital diplegia",
                "threedigit": "343",
                "isLeaf": true
              },
              {
                "key": "3431",
                "title": "3431 Congenital hemiplegia",
                "threedigit": "343",
                "isLeaf": true
              },
              {
                "key": "3432",
                "title": "3432 Congenital quadriplegia",
                "threedigit": "343",
                "isLeaf": true
              },
              {
                "key": "3433",
                "title": "3433 Congenital monoplegia",
                "threedigit": "343",
                "isLeaf": true
              },
              {
                "key": "3434",
                "title": "3434 Infantile hemiplegia",
                "threedigit": "343",
                "isLeaf": true
              },
              {
                "key": "3438",
                "title": "3438 Cerebral palsy NEC",
                "threedigit": "343",
                "isLeaf": true
              },
              {
                "key": "3439",
                "title": "3439 Cerebral palsy NOS",
                "threedigit": "343",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other paralytic syndromes",
            "title": "344 - Other paralytic syndromes",
            "threedigit": "344",
            "children": [
              {
                "key": "34400",
                "title": "34400 Quadriplegia, unspecifd",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34401",
                "title": "34401 Quadrplg c1-c4, complete",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34402",
                "title": "34402 Quadrplg c1-c4, incomplt",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34403",
                "title": "34403 Quadrplg c5-c7, complete",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34404",
                "title": "34404 Quadrplg c5-c7, incomplt",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34409",
                "title": "34409 Other quadriplegia",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "3441",
                "title": "3441 Paraplegia NOS",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "3442",
                "title": "3442 Diplegia of upper limbs",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34430",
                "title": "34430 Monplga lwr lmb unsp sde",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34431",
                "title": "34431 Monplga lwr lmb dmnt sde",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34432",
                "title": "34432 Mnplg lwr lmb nondmnt sd",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34440",
                "title": "34440 Monplga upr lmb unsp sde",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34441",
                "title": "34441 Monplga upr lmb dmnt sde",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34442",
                "title": "34442 Mnplg upr lmb nondmnt sd",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "3445",
                "title": "3445 Monoplegia NOS",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34460",
                "title": "34460 Cauda equina synd NOS",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34461",
                "title": "34461 Neurogenic bladder",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34481",
                "title": "34481 Locked-in state",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "34489",
                "title": "34489 Oth spcf paralytic synd",
                "threedigit": "344",
                "isLeaf": true
              },
              {
                "key": "3449",
                "title": "3449 Paralysis NOS",
                "threedigit": "344",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Epilepsy and recurrent seizures",
            "title": "345 - Epilepsy and recurrent seizures",
            "threedigit": "345",
            "children": [
              {
                "key": "34500",
                "title": "34500 Gen noncv ep w/o intr ep",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34501",
                "title": "34501 Gen nonconv ep w intr ep",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34510",
                "title": "34510 Gen cnv epil w/o intr ep",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34511",
                "title": "34511 Gen cnv epil w intr epil",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "3452",
                "title": "3452 Petit mal status",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "3453",
                "title": "3453 Grand mal status",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34540",
                "title": "34540 Psymotr epil w/o int epi",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34541",
                "title": "34541 Psymotr epil w intr epil",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34550",
                "title": "34550 Part epil w/o intr epil",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34551",
                "title": "34551 Part epil w intr epil",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34560",
                "title": "34560 Inf spasm w/o intr epil",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34561",
                "title": "34561 Inf spasm w intract epil",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34570",
                "title": "34570 Epil par cont w/o int ep",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34571",
                "title": "34571 Epil par cont w intr epi",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34580",
                "title": "34580 Epilep NEC w/o intr epil",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34581",
                "title": "34581 Epilepsy NEC w intr epil",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34590",
                "title": "34590 Epilep NOS w/o intr epil",
                "threedigit": "345",
                "isLeaf": true
              },
              {
                "key": "34591",
                "title": "34591 Epilepsy NOS w intr epil",
                "threedigit": "345",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Migraine",
            "title": "346 - Migraine",
            "threedigit": "346",
            "children": [
              {
                "key": "34600",
                "title": "34600 Mgrn w aura wo ntrc mgrn",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34601",
                "title": "34601 Mgrn w aura w ntrc mgrn",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34602",
                "title": "34602 Mgrn w aur wo ntrc mgrn",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34603",
                "title": "34603 Mgrn w aura w ntrc mgrn",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34610",
                "title": "34610 Mgrn wo aura wo ntrc mgr",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34611",
                "title": "34611 Mgrn wo aura w ntrc mgrn",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34612",
                "title": "34612 Mgrn wo aura wo ntrc mgr",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34613",
                "title": "34613 Mgrn wo aura w ntrc mgrn",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34620",
                "title": "34620 Vrnt mgrn wo ntr mgr NEC",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34621",
                "title": "34621 Vrnt mgrn w ntrc mgr NEC",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34622",
                "title": "34622 Var mgr NEC wo ntc mgr",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34623",
                "title": "34623 Var mgrn NEC w ntrc mgr",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34630",
                "title": "34630 Hmplg mgr wo ntrc wo st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34631",
                "title": "34631 Hmplg mgrn w ntrc wo st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34632",
                "title": "34632 Hemplg mgr wo ntrc w st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34633",
                "title": "34633 Hmplg mgrn w ntrc w st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34640",
                "title": "34640 Menst mgr wo ntrc wo st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34641",
                "title": "34641 Menstl mgrn w ntrc wo st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34642",
                "title": "34642 Menstl mgr wo ntrc w st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34643",
                "title": "34643 Menstl mgrn w ntrc w st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34650",
                "title": "34650 Prst aura wo inf/ntr/st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34651",
                "title": "34651 Prs ara w ntr wo inf/st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34652",
                "title": "34652 Prs ara wo inf/ntr w st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34653",
                "title": "34653 Prs ara wo inf w ntr/st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34660",
                "title": "34660 Prs ara w inf wo ntr/st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34661",
                "title": "34661 Prs ara w/inf/ntr wo st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34662",
                "title": "34662 Prs ara wo ntr w inf/st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34663",
                "title": "34663 Prst ara w inf w ntr/st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34670",
                "title": "34670 Ch mgr wo ar wo nt wo st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34671",
                "title": "34671 Ch mgr wo ara w nt wo st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34672",
                "title": "34672 Ch mgr wo ara wo nt w st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34673",
                "title": "34673 Ch mgr wo ara w ntr w st",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34680",
                "title": "34680 Othr migrne wo ntrc mgrn",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34681",
                "title": "34681 Othr migrne w ntrc mgrne",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34682",
                "title": "34682 Oth mgr wo ntrc w st mgr",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34683",
                "title": "34683 Oth mgr w ntrc w st mgr",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34690",
                "title": "34690 Migrne unsp wo ntrc mgrn",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34691",
                "title": "34691 Mgrn unsp w ntrc mgr std",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34692",
                "title": "34692 Mgr NOS wo ntrc w st mgr",
                "threedigit": "346",
                "isLeaf": true
              },
              {
                "key": "34693",
                "title": "34693 Mgrn NOS w ntrc w st mgr",
                "threedigit": "346",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cataplexy and narcolepsy",
            "title": "347 - Cataplexy and narcolepsy",
            "threedigit": "347",
            "children": [
              {
                "key": "34700",
                "title": "34700 Narcolepsy w/o cataplexy",
                "threedigit": "347",
                "isLeaf": true
              },
              {
                "key": "34701",
                "title": "34701 Narcolepsy w cataplexy",
                "threedigit": "347",
                "isLeaf": true
              },
              {
                "key": "34710",
                "title": "34710 Narclpsy w/o cat oth dis",
                "threedigit": "347",
                "isLeaf": true
              },
              {
                "key": "34711",
                "title": "34711 Narcolepsy w cat oth dis",
                "threedigit": "347",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other conditions of brain",
            "title": "348 - Other conditions of brain",
            "threedigit": "348",
            "children": [
              {
                "key": "3480",
                "title": "3480 Cerebral cysts",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "3481",
                "title": "3481 Anoxic brain damage",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "3482",
                "title": "3482 Pseudotumor cerebri",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "34830",
                "title": "34830 Encephalopathy NOS",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "34831",
                "title": "34831 Metabolic encephalopathy",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "34839",
                "title": "34839 Encephalopathy NEC",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "3484",
                "title": "3484 Compression of brain",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "3485",
                "title": "3485 Cerebral edema",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "34881",
                "title": "34881 Temporal sclerosis",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "34882",
                "title": "34882 Brain death",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "34889",
                "title": "34889 Brain conditions NEC",
                "threedigit": "348",
                "isLeaf": true
              },
              {
                "key": "3489",
                "title": "3489 Brain condition NOS",
                "threedigit": "348",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified disorders of the nervous system",
            "title": "349 - Other and unspecified disorders of the nervous system",
            "threedigit": "349",
            "children": [
              {
                "key": "3490",
                "title": "3490 Lumbar puncture reaction",
                "threedigit": "349",
                "isLeaf": true
              },
              {
                "key": "3491",
                "title": "3491 Complication cns device",
                "threedigit": "349",
                "isLeaf": true
              },
              {
                "key": "3492",
                "title": "3492 Disorder of meninges NEC",
                "threedigit": "349",
                "isLeaf": true
              },
              {
                "key": "34931",
                "title": "34931 Accid punc/op lac dura",
                "threedigit": "349",
                "isLeaf": true
              },
              {
                "key": "34939",
                "title": "34939 Dural tear NEC",
                "threedigit": "349",
                "isLeaf": true
              },
              {
                "key": "34981",
                "title": "34981 Cerebrospinal rhinorrhea",
                "threedigit": "349",
                "isLeaf": true
              },
              {
                "key": "34982",
                "title": "34982 Toxic encephalopathy",
                "threedigit": "349",
                "isLeaf": true
              },
              {
                "key": "34989",
                "title": "34989 Cns disorder NEC",
                "threedigit": "349",
                "isLeaf": true
              },
              {
                "key": "3499",
                "title": "3499 Cns disorder NOS",
                "threedigit": "349",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Disorders Of The Peripheral Nervous System",
        "title": "350-359 Disorders Of The Peripheral Nervous System",
        "threedigit": "350",
        "children": [
          {
            "key": "Trigeminal nerve disorders",
            "title": "350 - Trigeminal nerve disorders",
            "threedigit": "350",
            "children": [
              {
                "key": "3501",
                "title": "3501 Trigeminal neuralgia",
                "threedigit": "350",
                "isLeaf": true
              },
              {
                "key": "3502",
                "title": "3502 Atypical face pain",
                "threedigit": "350",
                "isLeaf": true
              },
              {
                "key": "3508",
                "title": "3508 Trigeminal nerve dis NEC",
                "threedigit": "350",
                "isLeaf": true
              },
              {
                "key": "3509",
                "title": "3509 Trigeminal nerve dis NOS",
                "threedigit": "350",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Facial nerve disorders",
            "title": "351 - Facial nerve disorders",
            "threedigit": "351",
            "children": [
              {
                "key": "3510",
                "title": "3510 Bell's palsy",
                "threedigit": "351",
                "isLeaf": true
              },
              {
                "key": "3511",
                "title": "3511 Geniculate ganglionitis",
                "threedigit": "351",
                "isLeaf": true
              },
              {
                "key": "3518",
                "title": "3518 Facial nerve dis NEC",
                "threedigit": "351",
                "isLeaf": true
              },
              {
                "key": "3519",
                "title": "3519 Facial nerve dis NOS",
                "threedigit": "351",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of other cranial nerves",
            "title": "352 - Disorders of other cranial nerves",
            "threedigit": "352",
            "children": [
              {
                "key": "3520",
                "title": "3520 Olfactory nerve disorder",
                "threedigit": "352",
                "isLeaf": true
              },
              {
                "key": "3521",
                "title": "3521 Glossopharyng neuralgia",
                "threedigit": "352",
                "isLeaf": true
              },
              {
                "key": "3522",
                "title": "3522 Glossophar nerve dis NEC",
                "threedigit": "352",
                "isLeaf": true
              },
              {
                "key": "3523",
                "title": "3523 Pneumogastric nerve dis",
                "threedigit": "352",
                "isLeaf": true
              },
              {
                "key": "3524",
                "title": "3524 Accessory nerve disorder",
                "threedigit": "352",
                "isLeaf": true
              },
              {
                "key": "3525",
                "title": "3525 Hypoglossal nerve dis",
                "threedigit": "352",
                "isLeaf": true
              },
              {
                "key": "3526",
                "title": "3526 Mult cranial nerve palsy",
                "threedigit": "352",
                "isLeaf": true
              },
              {
                "key": "3529",
                "title": "3529 Cranial nerve dis NOS",
                "threedigit": "352",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nerve root and plexus disorders",
            "title": "353 - Nerve root and plexus disorders",
            "threedigit": "353",
            "children": [
              {
                "key": "3530",
                "title": "3530 Brachial plexus lesions",
                "threedigit": "353",
                "isLeaf": true
              },
              {
                "key": "3531",
                "title": "3531 Lumbosacral plex lesion",
                "threedigit": "353",
                "isLeaf": true
              },
              {
                "key": "3532",
                "title": "3532 Cervical root lesion NEC",
                "threedigit": "353",
                "isLeaf": true
              },
              {
                "key": "3533",
                "title": "3533 Thoracic root lesion NEC",
                "threedigit": "353",
                "isLeaf": true
              },
              {
                "key": "3534",
                "title": "3534 Lumbsacral root les NEC",
                "threedigit": "353",
                "isLeaf": true
              },
              {
                "key": "3535",
                "title": "3535 Neuralgic amyotrophy",
                "threedigit": "353",
                "isLeaf": true
              },
              {
                "key": "3536",
                "title": "3536 Phantom limb (syndrome)",
                "threedigit": "353",
                "isLeaf": true
              },
              {
                "key": "3538",
                "title": "3538 Nerv root/plexus dis NEC",
                "threedigit": "353",
                "isLeaf": true
              },
              {
                "key": "3539",
                "title": "3539 Nerv root/plexus dis NOS",
                "threedigit": "353",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Mononeuritis of upper limb and mononeuritis multiplex",
            "title": "354 - Mononeuritis of upper limb and mononeuritis multiplex",
            "threedigit": "354",
            "children": [
              {
                "key": "3540",
                "title": "3540 Carpal tunnel syndrome",
                "threedigit": "354",
                "isLeaf": true
              },
              {
                "key": "3541",
                "title": "3541 Median nerve lesion NEC",
                "threedigit": "354",
                "isLeaf": true
              },
              {
                "key": "3542",
                "title": "3542 Ulnar nerve lesion",
                "threedigit": "354",
                "isLeaf": true
              },
              {
                "key": "3543",
                "title": "3543 Radial nerve lesion",
                "threedigit": "354",
                "isLeaf": true
              },
              {
                "key": "3544",
                "title": "3544 Causalgia upper limb",
                "threedigit": "354",
                "isLeaf": true
              },
              {
                "key": "3545",
                "title": "3545 Mononeuritis multiplex",
                "threedigit": "354",
                "isLeaf": true
              },
              {
                "key": "3548",
                "title": "3548 Mononeuritis arm NEC",
                "threedigit": "354",
                "isLeaf": true
              },
              {
                "key": "3549",
                "title": "3549 Mononeuritis arm NOS",
                "threedigit": "354",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Mononeuritis of lower limb and unspecified site",
            "title": "355 - Mononeuritis of lower limb and unspecified site",
            "threedigit": "355",
            "children": [
              {
                "key": "3550",
                "title": "3550 Sciatic nerve lesion",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "3551",
                "title": "3551 Meralgia paresthetica",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "3552",
                "title": "3552 Femoral nerve lesion NEC",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "3553",
                "title": "3553 Lat popliteal nerve les",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "3554",
                "title": "3554 Med popliteal nerve les",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "3555",
                "title": "3555 Tarsal tunnel syndrome",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "3556",
                "title": "3556 Plantar nerve lesion",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "35571",
                "title": "35571 Causalgia lower limb",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "35579",
                "title": "35579 Oth mononeur lower limb",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "3558",
                "title": "3558 Mononeuritis leg NOS",
                "threedigit": "355",
                "isLeaf": true
              },
              {
                "key": "3559",
                "title": "3559 Mononeuritis NOS",
                "threedigit": "355",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hereditary and idiopathic peripheral neuropathy",
            "title": "356 - Hereditary and idiopathic peripheral neuropathy",
            "threedigit": "356",
            "children": [
              {
                "key": "3560",
                "title": "3560 Hered periph neuropathy",
                "threedigit": "356",
                "isLeaf": true
              },
              {
                "key": "3561",
                "title": "3561 Peroneal muscle atrophy",
                "threedigit": "356",
                "isLeaf": true
              },
              {
                "key": "3562",
                "title": "3562 Hered sensory neuropathy",
                "threedigit": "356",
                "isLeaf": true
              },
              {
                "key": "3563",
                "title": "3563 Refsum's disease",
                "threedigit": "356",
                "isLeaf": true
              },
              {
                "key": "3564",
                "title": "3564 Idio prog polyneuropathy",
                "threedigit": "356",
                "isLeaf": true
              },
              {
                "key": "3568",
                "title": "3568 Idio periph neurpthy NEC",
                "threedigit": "356",
                "isLeaf": true
              },
              {
                "key": "3569",
                "title": "3569 Idio periph neurpthy NOS",
                "threedigit": "356",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Inflammatory and toxic neuropathy",
            "title": "357 - Inflammatory and toxic neuropathy",
            "threedigit": "357",
            "children": [
              {
                "key": "3570",
                "title": "3570 Ac infect polyneuritis",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "3571",
                "title": "3571 Neurpthy in col vasc dis",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "3572",
                "title": "3572 Neuropathy in diabetes",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "3573",
                "title": "3573 Neuropathy in malig dis",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "3574",
                "title": "3574 Neuropathy in other dis",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "3575",
                "title": "3575 Alcoholic polyneuropathy",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "3576",
                "title": "3576 Neuropathy due to drugs",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "3577",
                "title": "3577 Neurpthy toxic agent NEC",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "35781",
                "title": "35781 Chr inflam polyneuritis",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "35782",
                "title": "35782 Crit illness neuropathy",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "35789",
                "title": "35789 Inflam/tox neuropthy NEC",
                "threedigit": "357",
                "isLeaf": true
              },
              {
                "key": "3579",
                "title": "3579 Inflam/tox neuropthy NOS",
                "threedigit": "357",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Myoneural disorders",
            "title": "358 - Myoneural disorders",
            "threedigit": "358",
            "children": [
              {
                "key": "35800",
                "title": "35800 Mysthna grvs w/o ac exac",
                "threedigit": "358",
                "isLeaf": true
              },
              {
                "key": "35801",
                "title": "35801 Myasthna gravs w ac exac",
                "threedigit": "358",
                "isLeaf": true
              },
              {
                "key": "3581",
                "title": "3581 Myasthenia in oth dis",
                "threedigit": "358",
                "isLeaf": true
              },
              {
                "key": "3582",
                "title": "3582 Toxic myoneural disorder",
                "threedigit": "358",
                "isLeaf": true
              },
              {
                "key": "35830",
                "title": "35830 Lambert-Eaton synd NOS",
                "threedigit": "358",
                "isLeaf": true
              },
              {
                "key": "35831",
                "title": "35831 Lambert-Eaton synd neopl",
                "threedigit": "358",
                "isLeaf": true
              },
              {
                "key": "35839",
                "title": "35839 Lambert-Eaton syn ot dis",
                "threedigit": "358",
                "isLeaf": true
              },
              {
                "key": "3588",
                "title": "3588 Myoneural disorders NEC",
                "threedigit": "358",
                "isLeaf": true
              },
              {
                "key": "3589",
                "title": "3589 Myoneural disorders NOS",
                "threedigit": "358",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Muscular dystrophies and other myopathies",
            "title": "359 - Muscular dystrophies and other myopathies",
            "threedigit": "359",
            "children": [
              {
                "key": "3590",
                "title": "3590 Cong hered musc dystrphy",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "3591",
                "title": "3591 Hered prog musc dystrphy",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "35921",
                "title": "35921 Myotonic musclr dystrphy",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "35922",
                "title": "35922 Myotonia congenita",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "35923",
                "title": "35923 Myotonic chondrodystrphy",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "35924",
                "title": "35924 Drug induced myotonia",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "35929",
                "title": "35929 Myotonic disorder NEC",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "3593",
                "title": "3593 Periodic paralysis",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "3594",
                "title": "3594 Toxic myopathy",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "3595",
                "title": "3595 Myopathy in endocrin dis",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "3596",
                "title": "3596 Infl myopathy in oth dis",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "35971",
                "title": "35971 Inclusion body myositis",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "35979",
                "title": "35979 Inflm/immune myopath NEC",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "35981",
                "title": "35981 Critical illness myopthy",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "35989",
                "title": "35989 Myopathies NEC",
                "threedigit": "359",
                "isLeaf": true
              },
              {
                "key": "3599",
                "title": "3599 Myopathy NOS",
                "threedigit": "359",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Disorders Of The Eye And Adnexa",
        "title": "360-379 Disorders Of The Eye And Adnexa",
        "threedigit": "360",
        "children": [
          {
            "key": "Disorders of the globe",
            "title": "360 - Disorders of the globe",
            "threedigit": "360",
            "children": [
              {
                "key": "36000",
                "title": "36000 Purulent endophthalm NOS",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36001",
                "title": "36001 Acute endophthalmitis",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36002",
                "title": "36002 Panophthalmitis",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36003",
                "title": "36003 Chronic endophthalmitis",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36004",
                "title": "36004 Vitreous abscess",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36011",
                "title": "36011 Sympathetic uveitis",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36012",
                "title": "36012 Panuveitis",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36013",
                "title": "36013 Parasitic endophthal NOS",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36014",
                "title": "36014 Ophthalmia nodosa",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36019",
                "title": "36019 Endophthalmitis NEC",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36020",
                "title": "36020 Degenerat globe dis NOS",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36021",
                "title": "36021 Progressive high myopia",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36023",
                "title": "36023 Siderosis",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36024",
                "title": "36024 Other metallosis, eye",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36029",
                "title": "36029 Degenerative globe NEC",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36030",
                "title": "36030 Hypotony NOS, eye",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36031",
                "title": "36031 Primary hypotony",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36032",
                "title": "36032 Hypotony due to fistula",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36033",
                "title": "36033 Hypotony w eye dis NEC",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36034",
                "title": "36034 Flat anterior chamber",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36040",
                "title": "36040 Degeneration of eye NOS",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36041",
                "title": "36041 Blind hypotensive eye",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36042",
                "title": "36042 Blind hypertensive eye",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36043",
                "title": "36043 Hemophthalmos",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36044",
                "title": "36044 Leucocoria",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36050",
                "title": "36050 Old magnet fb, eye NOS",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36051",
                "title": "36051 Old magnet fb, ant chamb",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36052",
                "title": "36052 Old magnet fb, iris",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36053",
                "title": "36053 Old magnet fb, lens",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36054",
                "title": "36054 Old magnet fb, vitreous",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36055",
                "title": "36055 Old magnet fb, post wall",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36059",
                "title": "36059 Old magnet fb, eye NEC",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36060",
                "title": "36060 Intraocular FB NOS",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36061",
                "title": "36061 FB in anterior chamber",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36062",
                "title": "36062 FB in iris or ciliary",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36063",
                "title": "36063 Foreign body in lens",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36064",
                "title": "36064 Foreign body in vitreous",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36065",
                "title": "36065 FB in posterior wall",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36069",
                "title": "36069 Intraocular FB NEC",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36081",
                "title": "36081 Luxation of globe",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "36089",
                "title": "36089 Disorder of globe NEC",
                "threedigit": "360",
                "isLeaf": true
              },
              {
                "key": "3609",
                "title": "3609 Disorder of globe NOS",
                "threedigit": "360",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Retinal detachments and defects",
            "title": "361 - Retinal detachments and defects",
            "threedigit": "361",
            "children": [
              {
                "key": "36100",
                "title": "36100 Detachmnt w defect NOS",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36101",
                "title": "36101 Part detach-singl defec",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36102",
                "title": "36102 Part detach-mult defect",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36103",
                "title": "36103 Part detach-giant tear",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36104",
                "title": "36104 Part detach-dialysis",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36105",
                "title": "36105 Recent detachment, total",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36106",
                "title": "36106 Old detachment, partial",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36107",
                "title": "36107 Old detachment, total",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36110",
                "title": "36110 Retinoschisis NOS",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36111",
                "title": "36111 Flat retinoschisis",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36112",
                "title": "36112 Bullous retinoschisis",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36113",
                "title": "36113 Primary retinal cysts",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36114",
                "title": "36114 Secondary retinal cysts",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36119",
                "title": "36119 Retinoshisis or cyst NEC",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "3612",
                "title": "3612 Serous retina detachment",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36130",
                "title": "36130 Retinal defect NOS",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36131",
                "title": "36131 Round hole of retina",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36132",
                "title": "36132 Horseshoe tear of retina",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36133",
                "title": "36133 Mult defects of retina",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36181",
                "title": "36181 Retinal traction detach",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "36189",
                "title": "36189 Retinal detachment NEC",
                "threedigit": "361",
                "isLeaf": true
              },
              {
                "key": "3619",
                "title": "3619 Retinal detachment NOS",
                "threedigit": "361",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other retinal disorders",
            "title": "362 - Other retinal disorders",
            "threedigit": "362",
            "children": [
              {
                "key": "36201",
                "title": "36201 Diabetic retinopathy NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36202",
                "title": "36202 Prolif diab retinopathy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36203",
                "title": "36203 Nonprolf db retnoph NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36204",
                "title": "36204 Mild nonprolf db retnoph",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36205",
                "title": "36205 Mod nonprolf db retinoph",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36206",
                "title": "36206 Sev nonprolf db retinoph",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36207",
                "title": "36207 Diabetic macular edema",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36210",
                "title": "36210 Backgrnd retinopathy NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36211",
                "title": "36211 Hypertensive retinopathy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36212",
                "title": "36212 Exudative retinopathy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36213",
                "title": "36213 Retinal vascular changes",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36214",
                "title": "36214 Retina microaneurysm NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36215",
                "title": "36215 Retinal telangiectasia",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36216",
                "title": "36216 Retinal neovascular NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36217",
                "title": "36217 Retinal varices",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36218",
                "title": "36218 Retinal vasculitis",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36220",
                "title": "36220 Retinoph prematurity NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36221",
                "title": "36221 Retrolental fibroplasia",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36222",
                "title": "36222 Retinoph prematr,stage 0",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36223",
                "title": "36223 Retinoph prematr,stage 1",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36224",
                "title": "36224 Retinoph prematr,stage 2",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36225",
                "title": "36225 Retinoph prematr,stage 3",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36226",
                "title": "36226 Retinoph prematr.stage 4",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36227",
                "title": "36227 Retinoph prematr,stage 5",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36229",
                "title": "36229 Prolif retinopathy NEC",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36230",
                "title": "36230 Retinal vasc occlus NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36231",
                "title": "36231 Cent retina artery occlu",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36232",
                "title": "36232 Arterial branch occlus",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36233",
                "title": "36233 Part arterial occlusion",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36234",
                "title": "36234 Transient arterial occlu",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36235",
                "title": "36235 Cent retinal vein occlus",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36236",
                "title": "36236 Venous tributary occlus",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36237",
                "title": "36237 Retina venous engorgemnt",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36240",
                "title": "36240 Retina layer separat NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36241",
                "title": "36241 Cent serous retinopathy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36242",
                "title": "36242 Serous detach pigm epith",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36243",
                "title": "36243 Hem detach pigmnt epith",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36250",
                "title": "36250 Macular degeneration NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36251",
                "title": "36251 Nonexudat macular degen",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36252",
                "title": "36252 Exudative macular degen",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36253",
                "title": "36253 Cystoid macular degen",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36254",
                "title": "36254 Macular cyst or hole",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36255",
                "title": "36255 Toxic maculopathy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36256",
                "title": "36256 Macular puckering",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36257",
                "title": "36257 Drusen (degenerative)",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36260",
                "title": "36260 Periph retina degen NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36261",
                "title": "36261 Paving stone degenerat",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36262",
                "title": "36262 Microcystoid degenerat",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36263",
                "title": "36263 Lattice degeneration",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36264",
                "title": "36264 Senile reticular degen",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36265",
                "title": "36265 Secondry pigment degen",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36266",
                "title": "36266 Sec vitreoretina degen",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36270",
                "title": "36270 Hered retin dystrphy NOS",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36271",
                "title": "36271 Ret dystrph in lipidoses",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36272",
                "title": "36272 Ret dystrph in syst dis",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36273",
                "title": "36273 Vitreoretinal dystrophy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36274",
                "title": "36274 Pigment retina dystrophy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36275",
                "title": "36275 Sensory retina dystrophy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36276",
                "title": "36276 Vitelliform dystrophy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36277",
                "title": "36277 Bruch membrane dystrophy",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36281",
                "title": "36281 Retinal hemorrhage",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36282",
                "title": "36282 Retina exudates/deposits",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36283",
                "title": "36283 Retinal edema",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36284",
                "title": "36284 Retinal ischemia",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36285",
                "title": "36285 Retinal nerv fiber defec",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "36289",
                "title": "36289 Retinal disorders NEC",
                "threedigit": "362",
                "isLeaf": true
              },
              {
                "key": "3629",
                "title": "3629 Retinal disorder NOS",
                "threedigit": "362",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chorioretinal inflammations scars and other disorders of choroid",
            "title": "363 - Chorioretinal inflammations scars and other disorders of choroid",
            "threedigit": "363",
            "children": [
              {
                "key": "36300",
                "title": "36300 Focal chorioretinit NOS",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36301",
                "title": "36301 Juxtapap foc choroiditis",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36303",
                "title": "36303 Foc choroiditis post NEC",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36304",
                "title": "36304 Periph focal choroiditis",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36305",
                "title": "36305 Juxtapap focal retinitis",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36306",
                "title": "36306 Macular focal retinitis",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36307",
                "title": "36307 Foc retinitis post NEC",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36308",
                "title": "36308 Periph focal retinitis",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36310",
                "title": "36310 Dissem chorioretinit NOS",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36311",
                "title": "36311 Dissem choroiditis, post",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36312",
                "title": "36312 Periph disem choroiditis",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36313",
                "title": "36313 Gen dissem choroiditis",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36314",
                "title": "36314 Metastat dissem retinit",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36315",
                "title": "36315 Pigment epitheliopathy",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36320",
                "title": "36320 Chorioretinitis NOS",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36321",
                "title": "36321 Pars planitis",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36322",
                "title": "36322 Harada's disease",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36330",
                "title": "36330 Chorioretinal scar NOS",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36331",
                "title": "36331 Solar retinopathy",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36332",
                "title": "36332 Macular scars NEC",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36333",
                "title": "36333 Posterior pole scar NEC",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36334",
                "title": "36334 Peripheral retinal scars",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36335",
                "title": "36335 Disseminated retina scar",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36340",
                "title": "36340 Choroidal degen NOS",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36341",
                "title": "36341 Senile atrophy, choroid",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36342",
                "title": "36342 Difus sec atroph choroid",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36343",
                "title": "36343 Angioid streaks, choroid",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36350",
                "title": "36350 Hered choroid atroph NOS",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36351",
                "title": "36351 Prt circmpap choroid dys",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36352",
                "title": "36352 Tot circmpap choroid dys",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36353",
                "title": "36353 Part cent choroid dystr",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36354",
                "title": "36354 Tot cent choroid atrophy",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36355",
                "title": "36355 Choroideremia",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36356",
                "title": "36356 Prt gen choroid dyst NEC",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36357",
                "title": "36357 Tot gen choroid dyst NEC",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36361",
                "title": "36361 Choroidal hemorrhage NOS",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36362",
                "title": "36362 Expulsive choroid hemorr",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36363",
                "title": "36363 Choroidal rupture",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36370",
                "title": "36370 Choroidal detachment NOS",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36371",
                "title": "36371 Serous choroid detachmnt",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "36372",
                "title": "36372 Hemorr choroid detachmnt",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "3638",
                "title": "3638 Disorders of choroid NEC",
                "threedigit": "363",
                "isLeaf": true
              },
              {
                "key": "3639",
                "title": "3639 Choroidal disorder NOS",
                "threedigit": "363",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of iris and ciliary body",
            "title": "364 - Disorders of iris and ciliary body",
            "threedigit": "364",
            "children": [
              {
                "key": "36400",
                "title": "36400 Acute iridocyclitis NOS",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36401",
                "title": "36401 Primary iridocyclitis",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36402",
                "title": "36402 Recurrent iridocyclitis",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36403",
                "title": "36403 Secondry iritis, infect",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36404",
                "title": "36404 Second iritis, noninfec",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36405",
                "title": "36405 Hypopyon",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36410",
                "title": "36410 Chr iridocyclitis NOS",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36411",
                "title": "36411 Chr iridocyl in oth dis",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36421",
                "title": "36421 Fuch hetrochrom cyclitis",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36422",
                "title": "36422 Glaucomatocyclit crises",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36423",
                "title": "36423 Lens-induced iridocyclit",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36424",
                "title": "36424 Vogt-koyanagi syndrome",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "3643",
                "title": "3643 Iridocyclitis NOS",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36441",
                "title": "36441 Hyphema",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36442",
                "title": "36442 Rubeosis iridis",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36451",
                "title": "36451 Progressive iris atrophy",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36452",
                "title": "36452 Iridoschisis",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36453",
                "title": "36453 Pigment iris degenerat",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36454",
                "title": "36454 Pupillary margin degen",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36455",
                "title": "36455 Miotic cyst pupil margin",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36456",
                "title": "36456 Degen chamber angle",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36457",
                "title": "36457 Degen ciliary body",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36459",
                "title": "36459 Iris atrophy NEC",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36460",
                "title": "36460 Idiopathic cysts",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36461",
                "title": "36461 Implantation cysts",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36462",
                "title": "36462 Exud cyst iris/ant chamb",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36463",
                "title": "36463 Primary cyst pars plana",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36464",
                "title": "36464 Exudat cyst pars plana",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36470",
                "title": "36470 Adhesions of iris NOS",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36471",
                "title": "36471 Posterior synechiae",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36472",
                "title": "36472 Anterior synechiae",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36473",
                "title": "36473 Goniosynechiae",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36474",
                "title": "36474 Pupillary membranes",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36475",
                "title": "36475 Pupillary abnormalities",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36476",
                "title": "36476 Iridodialysis",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36477",
                "title": "36477 Recession, chamber angle",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36481",
                "title": "36481 Floppy iris syndrome",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36482",
                "title": "36482 Plateau iris syndrome",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "36489",
                "title": "36489 Iris/ciliary disord NEC",
                "threedigit": "364",
                "isLeaf": true
              },
              {
                "key": "3649",
                "title": "3649 Iris/ciliary dis NOS",
                "threedigit": "364",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Glaucoma",
            "title": "365 - Glaucoma",
            "threedigit": "365",
            "children": [
              {
                "key": "36500",
                "title": "36500 Preglaucoma NOS",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36501",
                "title": "36501 Opn angl brderln lo risk",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36502",
                "title": "36502 Anatomical narrow angle",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36503",
                "title": "36503 Steroid responders",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36504",
                "title": "36504 Ocular hypertension",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36505",
                "title": "36505 Opn ang w brdrlne hi rsk",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36506",
                "title": "36506 Prim angle clos w/o dmg",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36510",
                "title": "36510 Open-angle glaucoma NOS",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36511",
                "title": "36511 Prim open angle glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36512",
                "title": "36512 Low tension glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36513",
                "title": "36513 Pigmentary glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36514",
                "title": "36514 Glaucoma of childhood",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36515",
                "title": "36515 Residual opn ang glaucma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36520",
                "title": "36520 Prim angl-clos glauc NOS",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36521",
                "title": "36521 Intermit angl-clos glauc",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36522",
                "title": "36522 Acute angl-clos glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36523",
                "title": "36523 Chr angle-clos glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36524",
                "title": "36524 Residual angl-clos glauc",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36531",
                "title": "36531 Glauc stage-ster induced",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36532",
                "title": "36532 Glauc resid-ster induced",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36541",
                "title": "36541 Glauc w chamb angle anom",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36542",
                "title": "36542 Glaucoma w iris anomaly",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36543",
                "title": "36543 Glauc w ant seg anom NEC",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36544",
                "title": "36544 Glaucoma w systemic synd",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36551",
                "title": "36551 Phacolytic glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36552",
                "title": "36552 Pseudoexfoliat glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36559",
                "title": "36559 Glaucoma w lens dis NEC",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36560",
                "title": "36560 Glauc w ocular dis NOS",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36561",
                "title": "36561 Glauc w pupillary block",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36562",
                "title": "36562 Glaucoma w ocular inflam",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36563",
                "title": "36563 Glaucoma w vascular dis",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36564",
                "title": "36564 Glaucoma w tumor or cyst",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36565",
                "title": "36565 Glaucoma w ocular trauma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36570",
                "title": "36570 Glaucoma stage NOS",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36571",
                "title": "36571 Mild stage glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36572",
                "title": "36572 Moderate stage glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36573",
                "title": "36573 Severe stage glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36574",
                "title": "36574 Indeterm stage glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36581",
                "title": "36581 Hypersecretion glaucoma",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36582",
                "title": "36582 Glauc w inc episcl press",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36583",
                "title": "36583 Aqueous misdirection",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "36589",
                "title": "36589 Glaucoma NEC",
                "threedigit": "365",
                "isLeaf": true
              },
              {
                "key": "3659",
                "title": "3659 Glaucoma NOS",
                "threedigit": "365",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cataract",
            "title": "366 - Cataract",
            "threedigit": "366",
            "children": [
              {
                "key": "36600",
                "title": "36600 Nonsenile cataract NOS",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36601",
                "title": "36601 Ant subcaps pol cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36602",
                "title": "36602 Post subcaps pol catarct",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36603",
                "title": "36603 Cortical cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36604",
                "title": "36604 Nuclear cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36609",
                "title": "36609 Nonsenile cataract NEC",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36610",
                "title": "36610 Senile cataract NOS",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36611",
                "title": "36611 Pseudoexfol lens capsule",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36612",
                "title": "36612 Incipient cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36613",
                "title": "36613 Ant subcaps senile catar",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36614",
                "title": "36614 Post subcap senile catar",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36615",
                "title": "36615 Cortical senile cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36616",
                "title": "36616 Senile nuclear cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36617",
                "title": "36617 Mature cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36618",
                "title": "36618 Hypermature cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36619",
                "title": "36619 Senile cataract NEC",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36620",
                "title": "36620 Traumatic cataract NOS",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36621",
                "title": "36621 Local traumatic opacity",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36622",
                "title": "36622 Total traumatic cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36623",
                "title": "36623 Part resolv traum catar",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36630",
                "title": "36630 Cataracta complicata NOS",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36631",
                "title": "36631 Glaucomatous flecks",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36632",
                "title": "36632 Cataract in inflam dis",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36633",
                "title": "36633 Cataract w neovasculizat",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36634",
                "title": "36634 Cataract in degen dis",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36641",
                "title": "36641 Diabetic cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36642",
                "title": "36642 Tetanic cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36643",
                "title": "36643 Myotonic cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36644",
                "title": "36644 Cataract w syndrome NEC",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36645",
                "title": "36645 Toxic cataract",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36646",
                "title": "36646 Cataract w radiation",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36650",
                "title": "36650 After-cataract NOS",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36651",
                "title": "36651 Soemmering's ring",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36652",
                "title": "36652 After-cataract NEC",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "36653",
                "title": "36653 Aftr-catar obscur vision",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "3668",
                "title": "3668 Cataract NEC",
                "threedigit": "366",
                "isLeaf": true
              },
              {
                "key": "3669",
                "title": "3669 Cataract NOS",
                "threedigit": "366",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of refraction and accommodation",
            "title": "367 - Disorders of refraction and accommodation",
            "threedigit": "367",
            "children": [
              {
                "key": "3670",
                "title": "3670 Hypermetropia",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "3671",
                "title": "3671 Myopia",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36720",
                "title": "36720 Astigmatism NOS",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36721",
                "title": "36721 Regular astigmatism",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36722",
                "title": "36722 Irregular astigmatism",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36731",
                "title": "36731 Anisometropia",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36732",
                "title": "36732 Aniseikonia",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "3674",
                "title": "3674 Presbyopia",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36751",
                "title": "36751 Paresis of accommodation",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36752",
                "title": "36752 Tot intern ophthalmopleg",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36753",
                "title": "36753 Spasm of accommodation",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36781",
                "title": "36781 Transient refract change",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "36789",
                "title": "36789 Refraction disorder NEC",
                "threedigit": "367",
                "isLeaf": true
              },
              {
                "key": "3679",
                "title": "3679 Refraction disorder NOS",
                "threedigit": "367",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Visual disturbances",
            "title": "368 - Visual disturbances",
            "threedigit": "368",
            "children": [
              {
                "key": "36800",
                "title": "36800 Amblyopia NOS",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36801",
                "title": "36801 Strabismic amblyopia",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36802",
                "title": "36802 Deprivation amblyopia",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36803",
                "title": "36803 Refractive amblyopia",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36810",
                "title": "36810 Subj visual disturb NOS",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36811",
                "title": "36811 Sudden visual loss",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36812",
                "title": "36812 Transient visual loss",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36813",
                "title": "36813 Visual discomfort",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36814",
                "title": "36814 Distortion of shape/size",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36815",
                "title": "36815 Visual distortions NEC",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36816",
                "title": "36816 Psychophysic visual dist",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "3682",
                "title": "3682 Diplopia",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36830",
                "title": "36830 Binocular vision dis NOS",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36831",
                "title": "36831 Binocular vis suppress",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36832",
                "title": "36832 Visual percept w/o fusn",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36833",
                "title": "36833 Fusion w def stereopsis",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36834",
                "title": "36834 Abn retina correspond",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36840",
                "title": "36840 Visual field defect NOS",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36841",
                "title": "36841 Central scotoma",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36842",
                "title": "36842 Scotoma of blind spot",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36843",
                "title": "36843 Sector or arcuate defect",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36844",
                "title": "36844 Visual field defect NEC",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36845",
                "title": "36845 Gen visual contraction",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36846",
                "title": "36846 Homonymous hemianopsia",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36847",
                "title": "36847 Heteronymous hemianopsia",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36851",
                "title": "36851 Protan defect",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36852",
                "title": "36852 Deutan defect",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36853",
                "title": "36853 Tritan defect",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36854",
                "title": "36854 Achromatopsia",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36855",
                "title": "36855 Acq color deficiency",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36859",
                "title": "36859 Color deficiency NEC",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36860",
                "title": "36860 Night blindness NOS",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36861",
                "title": "36861 Congen night blindness",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36862",
                "title": "36862 Acquired night blindness",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36863",
                "title": "36863 Abn dark adaptat curve",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "36869",
                "title": "36869 Night blindness NEC",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "3688",
                "title": "3688 Visual disturbances NEC",
                "threedigit": "368",
                "isLeaf": true
              },
              {
                "key": "3689",
                "title": "3689 Visual disturbance NOS",
                "threedigit": "368",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Blindness and low vision",
            "title": "369 - Blindness and low vision",
            "threedigit": "369",
            "children": [
              {
                "key": "36900",
                "title": "36900 Both eyes blind-who def",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36901",
                "title": "36901 Tot impairment-both eyes",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36902",
                "title": "36902 One eye-near tot/oth-NOS",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36903",
                "title": "36903 One eye-near tot/oth-tot",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36904",
                "title": "36904 Near-tot impair-both eye",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36905",
                "title": "36905 One eye-profound/oth-NOS",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36906",
                "title": "36906 One eye-profound/oth-tot",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36907",
                "title": "36907 One eye-prfnd/oth-nr tot",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36908",
                "title": "36908 Profound impair both eye",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36910",
                "title": "36910 Blindness/low vision",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36911",
                "title": "36911 1 eye-sev/oth-blind NOS",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36912",
                "title": "36912 One eye-severe/oth-total",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36913",
                "title": "36913 One eye-sev/oth-near tot",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36914",
                "title": "36914 One eye-sev/oth-prfnd",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36915",
                "title": "36915 One eye-mod/oth-blind",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36916",
                "title": "36916 One eye-moderate/oth-tot",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36917",
                "title": "36917 One eye-mod/oth-near tot",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36918",
                "title": "36918 One eye-mod/oth-profound",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36920",
                "title": "36920 Low vision, 2 eyes NOS",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36921",
                "title": "36921 One eye-severe/oth-NOS",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36922",
                "title": "36922 Severe impair-both eyes",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36923",
                "title": "36923 One eye-moderate/oth-NOS",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36924",
                "title": "36924 One eye-moderate/oth-sev",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36925",
                "title": "36925 Moderate impair-both eye",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "3693",
                "title": "3693 Blindness NOS, both eyes",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "3694",
                "title": "3694 Legal blindness-usa def",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36960",
                "title": "36960 Blindness, one eye",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36961",
                "title": "36961 One eye-total/oth-unknwn",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36962",
                "title": "36962 One eye-tot/oth-near nor",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36963",
                "title": "36963 One eye-total/oth-normal",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36964",
                "title": "36964 One eye-near tot/oth-NOS",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36965",
                "title": "36965 Near-tot imp/near-normal",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36966",
                "title": "36966 Near-total impair/normal",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36967",
                "title": "36967 One eye-prfound/oth-unkn",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36968",
                "title": "36968 Profnd impair/near norm",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36969",
                "title": "36969 Profound impair/normal",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36970",
                "title": "36970 Low vision, one eye",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36971",
                "title": "36971 One eye-severe/oth-unknw",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36972",
                "title": "36972 One eye-sev/oth-nr norm",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36973",
                "title": "36973 One eye-severe/oth-norm",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36974",
                "title": "36974 One eye-mod/other-unknwn",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36975",
                "title": "36975 One eye-mod/oth-nr norm",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "36976",
                "title": "36976 One eye-mod/oth normal",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "3698",
                "title": "3698 Visual loss, one eye NOS",
                "threedigit": "369",
                "isLeaf": true
              },
              {
                "key": "3699",
                "title": "3699 Visual loss NOS",
                "threedigit": "369",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Keratitis",
            "title": "370 - Keratitis",
            "threedigit": "370",
            "children": [
              {
                "key": "37000",
                "title": "37000 Corneal ulcer NOS",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37001",
                "title": "37001 Marginal corneal ulcer",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37002",
                "title": "37002 Ring corneal ulcer",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37003",
                "title": "37003 Central corneal ulcer",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37004",
                "title": "37004 Hypopyon ulcer",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37005",
                "title": "37005 Mycotic corneal ulcer",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37006",
                "title": "37006 Perforated corneal ulcer",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37007",
                "title": "37007 Mooren's ulcer",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37020",
                "title": "37020 Superfic keratitis NOS",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37021",
                "title": "37021 Punctate keratitis",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37022",
                "title": "37022 Macular keratitis",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37023",
                "title": "37023 Filamentary keratitis",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37024",
                "title": "37024 Photokeratitis",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37031",
                "title": "37031 Phlycten keratoconjunct",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37032",
                "title": "37032 Limbar keratoconjunctiv",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37033",
                "title": "37033 Keratoconjunctivit sicca",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37034",
                "title": "37034 Expsure keratoconjunctiv",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37035",
                "title": "37035 Neurotroph keratoconjunc",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37040",
                "title": "37040 Keratoconjunctivitis NOS",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37044",
                "title": "37044 Keratitis in exanthema",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37049",
                "title": "37049 Keratoconjunctivitis NEC",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37050",
                "title": "37050 Interstit keratitis NOS",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37052",
                "title": "37052 Diffus interstit keratit",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37054",
                "title": "37054 Sclerosing keratitis",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37055",
                "title": "37055 Corneal abscess",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37059",
                "title": "37059 Interstit keratitis NEC",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37060",
                "title": "37060 Cornea neovasculariz NOS",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37061",
                "title": "37061 Local vasculariza cornea",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37062",
                "title": "37062 Corneal pannus",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37063",
                "title": "37063 Deep vasculariza cornea",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "37064",
                "title": "37064 Corneal ghost vessels",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "3708",
                "title": "3708 Keratitis NEC",
                "threedigit": "370",
                "isLeaf": true
              },
              {
                "key": "3709",
                "title": "3709 Keratitis NOS",
                "threedigit": "370",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Corneal opacity and other disorders of cornea",
            "title": "371 - Corneal opacity and other disorders of cornea",
            "threedigit": "371",
            "children": [
              {
                "key": "37100",
                "title": "37100 Corneal opacity NOS",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37101",
                "title": "37101 Minor opacity of cornea",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37102",
                "title": "37102 Periph opacity of cornea",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37103",
                "title": "37103 Central opacity, cornea",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37104",
                "title": "37104 Adherent leucoma",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37105",
                "title": "37105 Phthisical cornea",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37110",
                "title": "37110 Corneal deposit NOS",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37111",
                "title": "37111 Ant cornea pigmentation",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37112",
                "title": "37112 Stromal cornea pigment",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37113",
                "title": "37113 Post cornea pigmentation",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37114",
                "title": "37114 Kayser-fleischer ring",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37115",
                "title": "37115 Oth deposit w metab dis",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37116",
                "title": "37116 Argentous cornea deposit",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37120",
                "title": "37120 Corneal edema NOS",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37121",
                "title": "37121 Idiopathic corneal edema",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37122",
                "title": "37122 Secondary corneal edema",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37123",
                "title": "37123 Bullous keratopathy",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37124",
                "title": "37124 Edema d/t contact lens",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37130",
                "title": "37130 Cornea memb change NOS",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37131",
                "title": "37131 Fold of bowman membrane",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37132",
                "title": "37132 Fold in descemet membran",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37133",
                "title": "37133 Rupture descemet membran",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37140",
                "title": "37140 Corneal degeneration NOS",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37141",
                "title": "37141 Senile corneal changes",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37142",
                "title": "37142 Recurrent cornea erosion",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37143",
                "title": "37143 Band-shaped keratopathy",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37144",
                "title": "37144 Calcer cornea degen NEC",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37145",
                "title": "37145 Keratomalacia NOS",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37146",
                "title": "37146 Nodular cornea degen",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37148",
                "title": "37148 Peripheral cornea degen",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37149",
                "title": "37149 Cornea degeneration NEC",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37150",
                "title": "37150 Corneal dystrophy NOS",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37151",
                "title": "37151 Juv epith cornea dystrph",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37152",
                "title": "37152 Ant cornea dystrophy NEC",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37153",
                "title": "37153 Granular cornea dystrphy",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37154",
                "title": "37154 Lattice cornea dystrophy",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37155",
                "title": "37155 Macular cornea dystrophy",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37156",
                "title": "37156 Strom cornea dystrph NEC",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37157",
                "title": "37157 Endothel cornea dystrphy",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37158",
                "title": "37158 Post cornea dystrphy NEC",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37160",
                "title": "37160 Keratoconus NOS",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37161",
                "title": "37161 Keratoconus, stable",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37162",
                "title": "37162 Keratoconus, ac hydrops",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37170",
                "title": "37170 Corneal deformity NOS",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37171",
                "title": "37171 Corneal ectasia",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37172",
                "title": "37172 Descemetocele",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37173",
                "title": "37173 Corneal staphyloma",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37181",
                "title": "37181 Corneal anesthesia",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37182",
                "title": "37182 Corneal dsdr contct lens",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "37189",
                "title": "37189 Corneal disorder NEC",
                "threedigit": "371",
                "isLeaf": true
              },
              {
                "key": "3719",
                "title": "3719 Corneal disorder NOS",
                "threedigit": "371",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of conjunctiva",
            "title": "372 - Disorders of conjunctiva",
            "threedigit": "372",
            "children": [
              {
                "key": "37200",
                "title": "37200 Acute conjunctivitis NOS",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37201",
                "title": "37201 Serous conjunctivitis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37202",
                "title": "37202 Ac follic conjunctivitis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37203",
                "title": "37203 Mucopur conjunctivit NEC",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37204",
                "title": "37204 Pseudomemb conjunctivit",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37205",
                "title": "37205 Ac atopic conjunctivitis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37206",
                "title": "37206 Ac chem conjunctivitis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37210",
                "title": "37210 Chr conjunctivitis NOS",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37211",
                "title": "37211 Simpl chr conjunctivitis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37212",
                "title": "37212 Chr follic conjunctivit",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37213",
                "title": "37213 Vernal conjunctivitis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37214",
                "title": "37214 Chr allrg conjunctiv NEC",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37215",
                "title": "37215 Parasitic conjunctivitis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37220",
                "title": "37220 Blepharoconjunctivit NOS",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37221",
                "title": "37221 Angular blepharoconjunct",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37222",
                "title": "37222 Contact blepharoconjunct",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37230",
                "title": "37230 Conjunctivitis NOS",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37231",
                "title": "37231 Rosacea conjunctivitis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37233",
                "title": "37233 Mucocutan dis conjunctiv",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37234",
                "title": "37234 Pingueculitis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37239",
                "title": "37239 Conjunctivitis NEC",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37240",
                "title": "37240 Pterygium NOS",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37241",
                "title": "37241 Periph station pterygium",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37242",
                "title": "37242 Periph progess pterygium",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37243",
                "title": "37243 Central pterygium",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37244",
                "title": "37244 Double pterygium",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37245",
                "title": "37245 Recurrent pterygium",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37250",
                "title": "37250 Conjunctival degen NOS",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37251",
                "title": "37251 Pinguecula",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37252",
                "title": "37252 Pseudopterygium",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37253",
                "title": "37253 Conjunctival xerosis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37254",
                "title": "37254 Conjunctival concretions",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37255",
                "title": "37255 Conjunctiva pigmentation",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37256",
                "title": "37256 Conjunctival deposits",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37261",
                "title": "37261 Granuloma of conjunctiva",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37262",
                "title": "37262 Local conjunctiva adhes",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37263",
                "title": "37263 Symblepharon",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37264",
                "title": "37264 Scarring of conjunctiva",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37271",
                "title": "37271 Hyperemia of conjunctiva",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37272",
                "title": "37272 Conjunctival hemorrhage",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37273",
                "title": "37273 Conjunctival edema",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37274",
                "title": "37274 Conjunctiva vasc anomaly",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37275",
                "title": "37275 Conjunctival cysts",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37281",
                "title": "37281 Conjunctivochalasis",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "37289",
                "title": "37289 Conjunctiva disorder NEC",
                "threedigit": "372",
                "isLeaf": true
              },
              {
                "key": "3729",
                "title": "3729 Conjunctiva disorder NOS",
                "threedigit": "372",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Inflammation of eyelids",
            "title": "373 - Inflammation of eyelids",
            "threedigit": "373",
            "children": [
              {
                "key": "37300",
                "title": "37300 Blepharitis NOS",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "37301",
                "title": "37301 Ulcerative blepharitis",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "37302",
                "title": "37302 Squamous blepharitis",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "37311",
                "title": "37311 Hordeolum externum",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "37312",
                "title": "37312 Hordeolum internum",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "37313",
                "title": "37313 Abscess of eyelid",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "3732",
                "title": "3732 Chalazion",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "37331",
                "title": "37331 Eczem dermatitis eyelid",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "37332",
                "title": "37332 Contact dermatit eyelid",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "37333",
                "title": "37333 Xeroderma of eyelid",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "37334",
                "title": "37334 Disc lup erythematos lid",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "3734",
                "title": "3734 Infect derm lid w deform",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "3735",
                "title": "3735 Infec dermatitis lid NEC",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "3736",
                "title": "3736 Parasitic infest eyelid",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "3738",
                "title": "3738 Inflammation eyelid NEC",
                "threedigit": "373",
                "isLeaf": true
              },
              {
                "key": "3739",
                "title": "3739 Inflammation eyelid NOS",
                "threedigit": "373",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of eyelids",
            "title": "374 - Other disorders of eyelids",
            "threedigit": "374",
            "children": [
              {
                "key": "37400",
                "title": "37400 Entropion NOS",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37401",
                "title": "37401 Senile entropion",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37402",
                "title": "37402 Mechanical entropion",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37403",
                "title": "37403 Spastic entropion",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37404",
                "title": "37404 Cicatricial entropion",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37405",
                "title": "37405 Trichiasis w/o entropion",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37410",
                "title": "37410 Ectropion NOS",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37411",
                "title": "37411 Senile ectropion",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37412",
                "title": "37412 Mechanical ectropion",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37413",
                "title": "37413 Spastic ectropion",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37414",
                "title": "37414 Cicatricial ectropion",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37420",
                "title": "37420 Lagophthalmos NOS",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37421",
                "title": "37421 Paralytic lagophthalmos",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37422",
                "title": "37422 Mechanical lagophthalmos",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37423",
                "title": "37423 Cicatricial lagophthalm",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37430",
                "title": "37430 Ptosis of eyelid NOS",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37431",
                "title": "37431 Paralytic ptosis",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37432",
                "title": "37432 Myogenic ptosis",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37433",
                "title": "37433 Mechanical ptosis",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37434",
                "title": "37434 Blepharochalasis",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37441",
                "title": "37441 Lid retraction or lag",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37443",
                "title": "37443 Abnorm innervation synd",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37444",
                "title": "37444 Sensory disorders, lid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37445",
                "title": "37445 Sensormotr disor lid NEC",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37446",
                "title": "37446 Blepharophimosis",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37450",
                "title": "37450 Degen disorder NOS, lid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37451",
                "title": "37451 Xanthelasma",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37452",
                "title": "37452 Hyperpigmentation lid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37453",
                "title": "37453 Hypopigmentation lid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37454",
                "title": "37454 Hypertrichosis of eyelid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37455",
                "title": "37455 Hypotrichosis of eyelid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37456",
                "title": "37456 Degen dis eyelid NEC",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37481",
                "title": "37481 Hemorrhage of eyelid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37482",
                "title": "37482 Edema of eyelid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37483",
                "title": "37483 Elephantiasis of eyelid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37484",
                "title": "37484 Cysts of eyelids",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37485",
                "title": "37485 Vascular anomaly, eyelid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37486",
                "title": "37486 Old foreign body, eyelid",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37487",
                "title": "37487 Dermatochalasis",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "37489",
                "title": "37489 Disorders of eyelid NEC",
                "threedigit": "374",
                "isLeaf": true
              },
              {
                "key": "3749",
                "title": "3749 Disorder of eyelid NOS",
                "threedigit": "374",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of lacrimal system",
            "title": "375 - Disorders of lacrimal system",
            "threedigit": "375",
            "children": [
              {
                "key": "37500",
                "title": "37500 Dacryoadenitis NOS",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37501",
                "title": "37501 Acute dacryoadenitis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37502",
                "title": "37502 Chronic dacryoadenitis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37503",
                "title": "37503 Ch enlargmnt lacrim glnd",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37511",
                "title": "37511 Dacryops",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37512",
                "title": "37512 Lacrimal gland cyst NEC",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37513",
                "title": "37513 Primary lacrimal atrophy",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37514",
                "title": "37514 Secondary lacrim atrophy",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37515",
                "title": "37515 Tear film insuffic NOS",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37516",
                "title": "37516 Lacrimal gland dislocat",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37520",
                "title": "37520 Epiphora NOS",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37521",
                "title": "37521 Epiphora d/t excess tear",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37522",
                "title": "37522 Epiphora d/t insuf drain",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37530",
                "title": "37530 Dacryocystitis NOS",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37531",
                "title": "37531 Acute canaliculitis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37532",
                "title": "37532 Acute dacryocystitis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37533",
                "title": "37533 Phlegmon dacryocystitis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37541",
                "title": "37541 Chronic canaliculitis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37542",
                "title": "37542 Chronic dacryocystitis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37543",
                "title": "37543 Lacrimal mucocele",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37551",
                "title": "37551 Lacriml punctum eversion",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37552",
                "title": "37552 Lacriml punctum stenosis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37553",
                "title": "37553 Lacrim canalic stenosis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37554",
                "title": "37554 Lacrimal sac stenosis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37555",
                "title": "37555 Neonatal nasolacrml obst",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37556",
                "title": "37556 Acq nasolacrml stenosis",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37557",
                "title": "37557 Dacryolith",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37561",
                "title": "37561 Lacrimal fistula",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37569",
                "title": "37569 Lacrim passge change NEC",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37581",
                "title": "37581 Lacrim passage granuloma",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "37589",
                "title": "37589 Lacrimal syst dis NEC",
                "threedigit": "375",
                "isLeaf": true
              },
              {
                "key": "3759",
                "title": "3759 Lacrimal syst dis NOS",
                "threedigit": "375",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of the orbit",
            "title": "376 - Disorders of the orbit",
            "threedigit": "376",
            "children": [
              {
                "key": "37600",
                "title": "37600 Acute inflam NOS, orbit",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37601",
                "title": "37601 Orbital cellulitis",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37602",
                "title": "37602 Orbital periostitis",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37603",
                "title": "37603 Orbital osteomyelitis",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37604",
                "title": "37604 Orbital tenonitis",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37610",
                "title": "37610 Chr inflam NOS, orbit",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37611",
                "title": "37611 Orbital granuloma",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37612",
                "title": "37612 Orbital myositis",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37613",
                "title": "37613 Parasite infest, orbit",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37621",
                "title": "37621 Thyrotoxic exophthalmos",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37622",
                "title": "37622 Exophthalm ophthalmopleg",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37630",
                "title": "37630 Exophthalmos NOS",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37631",
                "title": "37631 Constant exophthalmos",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37632",
                "title": "37632 Orbital hemorrhage",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37633",
                "title": "37633 Orbital edema",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37634",
                "title": "37634 Intermittnt exophthalmos",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37635",
                "title": "37635 Pulsating exophthalmos",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37636",
                "title": "37636 Lateral globe displacmnt",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37640",
                "title": "37640 Deformity of orbit NOS",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37641",
                "title": "37641 Hypertelorism of orbit",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37642",
                "title": "37642 Exostosis of orbit",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37643",
                "title": "37643 Orbt deform d/t bone dis",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37644",
                "title": "37644 Craniofacial-orbit defor",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37645",
                "title": "37645 Atrophy of orbit",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37646",
                "title": "37646 Enlargement of orbit",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37647",
                "title": "37647 Orbit deform d/t trauma",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37650",
                "title": "37650 Enophthalmos NOS",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37651",
                "title": "37651 Enophth d/t orbit atrphy",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37652",
                "title": "37652 Enophthalmos d/t trauma",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "3766",
                "title": "3766 Old foreign body, orbit",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37681",
                "title": "37681 Orbital cysts",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37682",
                "title": "37682 Extraocul muscl myopathy",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "37689",
                "title": "37689 Orbital disorders NEC",
                "threedigit": "376",
                "isLeaf": true
              },
              {
                "key": "3769",
                "title": "3769 Orbital disorder NOS",
                "threedigit": "376",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of optic nerve and visual pathways",
            "title": "377 - Disorders of optic nerve and visual pathways",
            "threedigit": "377",
            "children": [
              {
                "key": "37700",
                "title": "37700 Papilledema NOS",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37701",
                "title": "37701 Papilledema w incr press",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37702",
                "title": "37702 Papilledema w decr press",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37703",
                "title": "37703 Papilledema w retina dis",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37704",
                "title": "37704 Foster-kennedy syndrome",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37710",
                "title": "37710 Optic atrophy NOS",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37711",
                "title": "37711 Primary optic atrophy",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37712",
                "title": "37712 Postinflam optic atrophy",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37713",
                "title": "37713 Optic atrph w retin dyst",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37714",
                "title": "37714 Cupping of optic disc",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37715",
                "title": "37715 Partial optic atrophy",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37716",
                "title": "37716 Hereditary optic atrophy",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37721",
                "title": "37721 Drusen of optic disc",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37722",
                "title": "37722 Crater-like hole op disc",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37723",
                "title": "37723 Coloboma of optic disc",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37724",
                "title": "37724 Pseudopapilledema",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37730",
                "title": "37730 Optic neuritis NOS",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37731",
                "title": "37731 Optic papillitis",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37732",
                "title": "37732 Retrobulbar neuritis",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37733",
                "title": "37733 Nutrition optc neuropthy",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37734",
                "title": "37734 Toxic optic neuropathy",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37739",
                "title": "37739 Optic neuritis NEC",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37741",
                "title": "37741 Ischemic optic neuropthy",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37742",
                "title": "37742 Optic nerve sheath hemor",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37743",
                "title": "37743 Optic nerve hypoplasia",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37749",
                "title": "37749 Optic nerve disorder NEC",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37751",
                "title": "37751 Opt chiasm w pituit dis",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37752",
                "title": "37752 Opt chiasm dis/neopl NEC",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37753",
                "title": "37753 Opt chiasm w vascul dis",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37754",
                "title": "37754 Op chiasm dis w infl dis",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37761",
                "title": "37761 Vis path dis w neoplasms",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37762",
                "title": "37762 Vis path dis w vasc dis",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37763",
                "title": "37763 Vis path dis w infl dis",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37771",
                "title": "37771 Vis cortx dis w neoplasm",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37772",
                "title": "37772 Vis cortx dis w vasc dis",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37773",
                "title": "37773 Vis cortex dis w inflam",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "37775",
                "title": "37775 Cortical blindness",
                "threedigit": "377",
                "isLeaf": true
              },
              {
                "key": "3779",
                "title": "3779 Optic nerve disorder NOS",
                "threedigit": "377",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Strabismus and other disorders of binocular eye movements",
            "title": "378 - Strabismus and other disorders of binocular eye movements",
            "threedigit": "378",
            "children": [
              {
                "key": "37800",
                "title": "37800 Esotropia NOS",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37801",
                "title": "37801 Monocular esotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37802",
                "title": "37802 Monoc esotrop w a pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37803",
                "title": "37803 Monoc esotrop w v pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37804",
                "title": "37804 Monoc esotrop w x/y pat",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37805",
                "title": "37805 Alternating esotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37806",
                "title": "37806 Alt esotropia w a pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37807",
                "title": "37807 Alt esotropia w v pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37808",
                "title": "37808 Alt esotrop w x/y pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37810",
                "title": "37810 Exotropia NOS",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37811",
                "title": "37811 Monocular exotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37812",
                "title": "37812 Monoc exotrop w a pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37813",
                "title": "37813 Monoc exotrop w v pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37814",
                "title": "37814 Monoc exotrop w x/y pat",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37815",
                "title": "37815 Alternating exotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37816",
                "title": "37816 Alt exotropia w a pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37817",
                "title": "37817 Alt exotropia w v pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37818",
                "title": "37818 Alt exotrop w x/y pattrn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37820",
                "title": "37820 Intermit heterotrop NOS",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37821",
                "title": "37821 Intermit monoc esotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37822",
                "title": "37822 Intermit altrn esotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37823",
                "title": "37823 Intermit monoc exotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37824",
                "title": "37824 Intermit altrn exotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37830",
                "title": "37830 Heterotropia NOS",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37831",
                "title": "37831 Hypertropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37832",
                "title": "37832 Hypotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37833",
                "title": "37833 Cyclotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37834",
                "title": "37834 Monofixation syndrome",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37835",
                "title": "37835 Accommodative esotropia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37840",
                "title": "37840 Heterophoria NOS",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37841",
                "title": "37841 Esophoria",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37842",
                "title": "37842 Exophoria",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37843",
                "title": "37843 Vertical heterophoria",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37844",
                "title": "37844 Cyclophoria",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37845",
                "title": "37845 Alternating hyperphoria",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37850",
                "title": "37850 Paralytic strabismus NOS",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37851",
                "title": "37851 Partial third nerv palsy",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37852",
                "title": "37852 Total third nerve palsy",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37853",
                "title": "37853 Fourth nerve palsy",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37854",
                "title": "37854 Sixth nerve palsy",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37855",
                "title": "37855 External ophthalmoplegia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37856",
                "title": "37856 Total ophthalmoplegia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37860",
                "title": "37860 Mechanical strabism NOS",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37861",
                "title": "37861 Brown's sheath syndrome",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37862",
                "title": "37862 Mech strab d/t muscl dis",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37863",
                "title": "37863 Mech strab w oth conditn",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37871",
                "title": "37871 Duane's syndrome",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37872",
                "title": "37872 Prog ext ophthalmoplegia",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37873",
                "title": "37873 Neuromuscle dis strabism",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37881",
                "title": "37881 Palsy of conjugate gaze",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37882",
                "title": "37882 Spasm of conjugate gaze",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37883",
                "title": "37883 Convergenc insufficiency",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37884",
                "title": "37884 Convergence excess",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37885",
                "title": "37885 Anomalies of divergence",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37886",
                "title": "37886 Internucl ophthalmopleg",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "37887",
                "title": "37887 Skew deviation, eye",
                "threedigit": "378",
                "isLeaf": true
              },
              {
                "key": "3789",
                "title": "3789 Eye movemnt disorder NOS",
                "threedigit": "378",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of eye",
            "title": "379 - Other disorders of eye",
            "threedigit": "379",
            "children": [
              {
                "key": "37900",
                "title": "37900 Scleritis NOS",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37901",
                "title": "37901 Episclerit periodic fugx",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37902",
                "title": "37902 Nodular episcleritis",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37903",
                "title": "37903 Anterior scleritis",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37904",
                "title": "37904 Scleromalacia perforans",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37905",
                "title": "37905 Scleritis w cornea invol",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37906",
                "title": "37906 Brawny scleritis",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37907",
                "title": "37907 Posterior scleritis",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37909",
                "title": "37909 Scleritis NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37911",
                "title": "37911 Scleral ectasia",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37912",
                "title": "37912 Staphyloma posticum",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37913",
                "title": "37913 Equatorial staphyloma",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37914",
                "title": "37914 Local anterior staphylma",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37915",
                "title": "37915 Ring staphyloma",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37916",
                "title": "37916 Scleral degen dis NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37919",
                "title": "37919 Disorder of sclera NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37921",
                "title": "37921 Vitreous degeneration",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37922",
                "title": "37922 Crystal deposit vitreous",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37923",
                "title": "37923 Vitreous hemorrhage",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37924",
                "title": "37924 Vitreous opacities NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37925",
                "title": "37925 Vitreous membranes",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37926",
                "title": "37926 Vitreous prolapse",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37927",
                "title": "37927 Vitreomacular adhesion",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37929",
                "title": "37929 Vitreous disorders NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37931",
                "title": "37931 Aphakia",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37932",
                "title": "37932 Subluxation of lens",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37933",
                "title": "37933 Ant dislocation of lens",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37934",
                "title": "37934 Post dislocation of lens",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37939",
                "title": "37939 Disorders of lens NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37940",
                "title": "37940 Abn pupil function NOS",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37941",
                "title": "37941 Anisocoria",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37942",
                "title": "37942 Miosis not d/t miotics",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37943",
                "title": "37943 Mydriasis not d/t mydrtc",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37945",
                "title": "37945 Argyll robertson pupil",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37946",
                "title": "37946 Tonic pupillary reaction",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37949",
                "title": "37949 Pupil funct anomaly NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37950",
                "title": "37950 Nystagmus NOS",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37951",
                "title": "37951 Congenital nystagmus",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37952",
                "title": "37952 Latent nystagmus",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37953",
                "title": "37953 Visual deprivatn nystagm",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37954",
                "title": "37954 Nystagms w vestibulr dis",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37955",
                "title": "37955 Dissociated nystagmus",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37956",
                "title": "37956 Nystagmus NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37957",
                "title": "37957 Saccadic eye movmnt def",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37958",
                "title": "37958 Smooth pursuit mvmnt def",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37959",
                "title": "37959 Irregular eye mvmnts NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37960",
                "title": "37960 Inflam postproc bleb NOS",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37961",
                "title": "37961 Inflam postproc bleb, 1",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37962",
                "title": "37962 Inflam postproc bleb, 2",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37963",
                "title": "37963 Inflam postproc bleb, 3",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "3798",
                "title": "3798 Eye disorders NEC",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37990",
                "title": "37990 Eye disorder NOS",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37991",
                "title": "37991 Pain in or around eye",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37992",
                "title": "37992 Swelling or mass of eye",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37993",
                "title": "37993 Redness/discharge of eye",
                "threedigit": "379",
                "isLeaf": true
              },
              {
                "key": "37999",
                "title": "37999 Ill-defined eye dis NEC",
                "threedigit": "379",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Diseases Of The Ear And Mastoid Process",
        "title": "380-389 Diseases Of The Ear And Mastoid Process",
        "threedigit": "380",
        "children": [
          {
            "key": "Disorders of external ear",
            "title": "380 - Disorders of external ear",
            "threedigit": "380",
            "children": [
              {
                "key": "38000",
                "title": "38000 Perichondritis pinna NOS",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38001",
                "title": "38001 Ac perichondritis pinna",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38002",
                "title": "38002 Chr perichondritis pinna",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38003",
                "title": "38003 Chondritis of pinna",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38010",
                "title": "38010 Infec otitis externa NOS",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38011",
                "title": "38011 Acute infection of pinna",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38012",
                "title": "38012 Acute swimmers' ear",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38013",
                "title": "38013 Ac infect extern ear NEC",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38014",
                "title": "38014 Malignant otitis externa",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38015",
                "title": "38015 Chr mycot otitis externa",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38016",
                "title": "38016 Chr inf otit externa NEC",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38021",
                "title": "38021 Cholesteatoma extern ear",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38022",
                "title": "38022 Acute otitis externa NEC",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38023",
                "title": "38023 Chr otitis externa NEC",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38030",
                "title": "38030 Disorder of pinna NOS",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38031",
                "title": "38031 Hematoma auricle/pinna",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38032",
                "title": "38032 Acq deform auricle/pinna",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38039",
                "title": "38039 Noninfect dis pinna NEC",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "3804",
                "title": "3804 Impacted cerumen",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38050",
                "title": "38050 Acq stenos ear canal NOS",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38051",
                "title": "38051 Stenosis ear d/t trauma",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38052",
                "title": "38052 Stenosis ear d/t surgery",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38053",
                "title": "38053 Stenosis ear d/t inflam",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38081",
                "title": "38081 Exostosis ext ear canal",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "38089",
                "title": "38089 Dis external ear NEC",
                "threedigit": "380",
                "isLeaf": true
              },
              {
                "key": "3809",
                "title": "3809 Dis external ear NOS",
                "threedigit": "380",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nonsuppurative otitis media and eustachian tube disorders",
            "title": "381 - Nonsuppurative otitis media and eustachian tube disorders",
            "threedigit": "381",
            "children": [
              {
                "key": "38100",
                "title": "38100 Ac nonsup otitis med NOS",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38101",
                "title": "38101 Ac serous otitis media",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38102",
                "title": "38102 Ac mucoid otitis media",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38103",
                "title": "38103 Ac sanguin otitis media",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38104",
                "title": "38104 Ac allergic serous OM",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38105",
                "title": "38105 Ac allergic mucoid OM",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38106",
                "title": "38106 Ac allerg sanguinous OM",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38110",
                "title": "38110 Chr serous OM simp/NOS",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38119",
                "title": "38119 Chr serous OM NEC",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38120",
                "title": "38120 Chr mucoid OM simp/NOS",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38129",
                "title": "38129 Chr mucoid OM NEC",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "3813",
                "title": "3813 Chr nonsup OM NOS/NEC",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "3814",
                "title": "3814 Nonsupp otitis media NOS",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38150",
                "title": "38150 Eustachian salping NOS",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38151",
                "title": "38151 Ac eustachian salping",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38152",
                "title": "38152 Chr eustachian salping",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38160",
                "title": "38160 Obstr eustach tube NOS",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38161",
                "title": "38161 Osseous eustachian obstr",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38162",
                "title": "38162 Intrinsic eustach obstr",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38163",
                "title": "38163 Extrinsic eustach obstr",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "3817",
                "title": "3817 Patulous eustachian tube",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38181",
                "title": "38181 Dysfunct eustachian tube",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "38189",
                "title": "38189 Eustachian tube dis NEC",
                "threedigit": "381",
                "isLeaf": true
              },
              {
                "key": "3819",
                "title": "3819 Eustachian tube dis NOS",
                "threedigit": "381",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Suppurative and unspecified otitis media",
            "title": "382 - Suppurative and unspecified otitis media",
            "threedigit": "382",
            "children": [
              {
                "key": "38200",
                "title": "38200 Ac supp otitis media NOS",
                "threedigit": "382",
                "isLeaf": true
              },
              {
                "key": "38201",
                "title": "38201 Ac supp OM w drum rupt",
                "threedigit": "382",
                "isLeaf": true
              },
              {
                "key": "38202",
                "title": "38202 Ac supp OM in oth dis",
                "threedigit": "382",
                "isLeaf": true
              },
              {
                "key": "3821",
                "title": "3821 Chr tubotympan suppur OM",
                "threedigit": "382",
                "isLeaf": true
              },
              {
                "key": "3822",
                "title": "3822 Chr atticoantral sup OM",
                "threedigit": "382",
                "isLeaf": true
              },
              {
                "key": "3823",
                "title": "3823 Chr sup otitis media NOS",
                "threedigit": "382",
                "isLeaf": true
              },
              {
                "key": "3824",
                "title": "3824 Suppur otitis media NOS",
                "threedigit": "382",
                "isLeaf": true
              },
              {
                "key": "3829",
                "title": "3829 Otitis media NOS",
                "threedigit": "382",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Mastoiditis and related conditions",
            "title": "383 - Mastoiditis and related conditions",
            "threedigit": "383",
            "children": [
              {
                "key": "38300",
                "title": "38300 Ac mastoiditis w/o compl",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38301",
                "title": "38301 Subperi mastoid abscess",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38302",
                "title": "38302 Ac mastoiditis-compl NEC",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "3831",
                "title": "3831 Chronic mastoiditis",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38320",
                "title": "38320 Petrositis NOS",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38321",
                "title": "38321 Acute petrositis",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38322",
                "title": "38322 Chronic petrositis",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38330",
                "title": "38330 Postmastoid compl NOS",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38331",
                "title": "38331 Postmastoid mucosal cyst",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38332",
                "title": "38332 Postmastoid cholesteatma",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38333",
                "title": "38333 Postmastoid granulations",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38381",
                "title": "38381 Postauricular fistula",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "38389",
                "title": "38389 Disorders of mastoid NEC",
                "threedigit": "383",
                "isLeaf": true
              },
              {
                "key": "3839",
                "title": "3839 Mastoiditis NOS",
                "threedigit": "383",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of tympanic membrane",
            "title": "384 - Other disorders of tympanic membrane",
            "threedigit": "384",
            "children": [
              {
                "key": "38400",
                "title": "38400 Acute myringitis NOS",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38401",
                "title": "38401 Bullous myringitis",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38409",
                "title": "38409 Acute myringitis NEC",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "3841",
                "title": "3841 Chronic myringitis",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38420",
                "title": "38420 Perforat tympan memb NOS",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38421",
                "title": "38421 Cent perf tympanic memb",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38422",
                "title": "38422 Attic perf tympanic memb",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38423",
                "title": "38423 Marginal perf tymp NEC",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38424",
                "title": "38424 Mult perf tympanic memb",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38425",
                "title": "38425 Total perf tympanic memb",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38481",
                "title": "38481 Atrophic flaccid tympan",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "38482",
                "title": "38482 Atrophic nonflaccid tymp",
                "threedigit": "384",
                "isLeaf": true
              },
              {
                "key": "3849",
                "title": "3849 Dis tympanic memb NOS",
                "threedigit": "384",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of middle ear and mastoid",
            "title": "385 - Other disorders of middle ear and mastoid",
            "threedigit": "385",
            "children": [
              {
                "key": "38500",
                "title": "38500 Tympanosclerosis NOS",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38501",
                "title": "38501 Tympanoscl-tympanic memb",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38502",
                "title": "38502 Tympnoscler-tymp/ossicle",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38503",
                "title": "38503 Tympanoscler-all parts",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38509",
                "title": "38509 Tympnsclr-oth site comb",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38510",
                "title": "38510 Adhesive mid ear dis NOS",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38511",
                "title": "38511 Adhesion tympanum-incus",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38512",
                "title": "38512 Adhesion tympanum-stapes",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38513",
                "title": "38513 Adhesion tymp-promontor",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38519",
                "title": "38519 Adhesive mid ear dis NEC",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38521",
                "title": "38521 Ankylosis malleus",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38522",
                "title": "38522 Ankylosis ear ossicl NEC",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38523",
                "title": "38523 Dislocation ear ossicle",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38524",
                "title": "38524 Partial loss ear ossicle",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38530",
                "title": "38530 Cholesteatoma NOS",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38531",
                "title": "38531 Cholesteatoma of attic",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38532",
                "title": "38532 Cholesteatoma middle ear",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38533",
                "title": "38533 Cholestma mid ear/mstoid",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38535",
                "title": "38535 Diffuse cholesteatosis",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38582",
                "title": "38582 Cholesterin granuloma",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38583",
                "title": "38583 Foreign body middle ear",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "38589",
                "title": "38589 Dis mid ear/mastoid NEC",
                "threedigit": "385",
                "isLeaf": true
              },
              {
                "key": "3859",
                "title": "3859 Dis mid ear/mastoid NOS",
                "threedigit": "385",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Vertiginous syndromes and other disorders of vestibular system",
            "title": "386 - Vertiginous syndromes and other disorders of vestibular system",
            "threedigit": "386",
            "children": [
              {
                "key": "38600",
                "title": "38600 Meniere's disease NOS",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38601",
                "title": "38601 Actv Meniere,cochlvestib",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38602",
                "title": "38602 Active Meniere, cochlear",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38603",
                "title": "38603 Actv Meniere, vestibular",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38604",
                "title": "38604 Inactive Meniere's dis",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38610",
                "title": "38610 Peripheral vertigo NOS",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38611",
                "title": "38611 Benign parxysmal vertigo",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38612",
                "title": "38612 Vestibular neuronitis",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38619",
                "title": "38619 Peripheral vertigo NEC",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "3862",
                "title": "3862 Central origin vertigo",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38630",
                "title": "38630 Labyrinthitis NOS",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38631",
                "title": "38631 Serous labyrinthitis",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38632",
                "title": "38632 Circumscri labyrinthitis",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38633",
                "title": "38633 Suppurativ labyrinthitis",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38634",
                "title": "38634 Toxic labyrinthitis",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38635",
                "title": "38635 Viral labyrinthitis",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38640",
                "title": "38640 Labyrinthine fistula NOS",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38641",
                "title": "38641 Round window fistula",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38642",
                "title": "38642 Oval window fistula",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38643",
                "title": "38643 Semicircul canal fistula",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38648",
                "title": "38648 Labyrinth fistula comb",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38650",
                "title": "38650 Labyrinthine dysfunc NOS",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38651",
                "title": "38651 Hypract labyrinth unilat",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38652",
                "title": "38652 Hyperact labyrinth bilat",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38653",
                "title": "38653 Hypoact labyrinth unilat",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38654",
                "title": "38654 Hypoact labyrinth bilat",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38655",
                "title": "38655 Loss labyrn react unilat",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38656",
                "title": "38656 Loss labyrin react bilat",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "38658",
                "title": "38658 Labyrinthine dysfunc NEC",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "3868",
                "title": "3868 Disorders labyrinth NEC",
                "threedigit": "386",
                "isLeaf": true
              },
              {
                "key": "3869",
                "title": "3869 Vertiginous synd NOS",
                "threedigit": "386",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Otosclerosis",
            "title": "387 - Otosclerosis",
            "threedigit": "387",
            "children": [
              {
                "key": "3870",
                "title": "3870 Otoscler-oval wnd nonobl",
                "threedigit": "387",
                "isLeaf": true
              },
              {
                "key": "3871",
                "title": "3871 Otoscler-oval wndw oblit",
                "threedigit": "387",
                "isLeaf": true
              },
              {
                "key": "3872",
                "title": "3872 Cochlear otosclerosis",
                "threedigit": "387",
                "isLeaf": true
              },
              {
                "key": "3878",
                "title": "3878 Otosclerosis NEC",
                "threedigit": "387",
                "isLeaf": true
              },
              {
                "key": "3879",
                "title": "3879 Otosclerosis NOS",
                "threedigit": "387",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of ear",
            "title": "388 - Other disorders of ear",
            "threedigit": "388",
            "children": [
              {
                "key": "38800",
                "title": "38800 Degen/vascul dis ear NOS",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38801",
                "title": "38801 Presbyacusis",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38802",
                "title": "38802 Trans ischemic deafness",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38810",
                "title": "38810 Noise effect-ear/NOS",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38811",
                "title": "38811 Acoustic trauma",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38812",
                "title": "38812 Hearing loss d/t noise",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "3882",
                "title": "3882 Sudden hearing loss NOS",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38830",
                "title": "38830 Tinnitus NOS",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38831",
                "title": "38831 Subjective tinnitus",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38832",
                "title": "38832 Objective tinnitus",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38840",
                "title": "38840 Abn auditory percept NOS",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38841",
                "title": "38841 Diplacusis",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38842",
                "title": "38842 Hyperacusis",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38843",
                "title": "38843 Impairm auditory discrim",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38844",
                "title": "38844 Auditory recruitment",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38845",
                "title": "38845 Acq auditory process dis",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "3885",
                "title": "3885 Acoustic nerve disorders",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38860",
                "title": "38860 Otorrhea NOS",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38861",
                "title": "38861 Cerebrosp fluid otorrhea",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38869",
                "title": "38869 Otorrhea NEC",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38870",
                "title": "38870 Otalgia NOS",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38871",
                "title": "38871 Otogenic pain",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "38872",
                "title": "38872 Referred pain of ear",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "3888",
                "title": "3888 Disorders of ear NEC",
                "threedigit": "388",
                "isLeaf": true
              },
              {
                "key": "3889",
                "title": "3889 Disorder of ear NOS",
                "threedigit": "388",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hearing loss",
            "title": "389 - Hearing loss",
            "threedigit": "389",
            "children": [
              {
                "key": "38900",
                "title": "38900 Conduct hearing loss NOS",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38901",
                "title": "38901 Conduc hear loss ext ear",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38902",
                "title": "38902 Conduct hear loss tympan",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38903",
                "title": "38903 Conduc hear loss mid ear",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38904",
                "title": "38904 Cond hear loss inner ear",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38905",
                "title": "38905 Condctv hear loss,unilat",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38906",
                "title": "38906 Condctv hear loss, bilat",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38908",
                "title": "38908 Cond hear loss comb type",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38910",
                "title": "38910 Sensorneur hear loss NOS",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38911",
                "title": "38911 Sensry hearng loss,bilat",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38912",
                "title": "38912 Neural hearng loss,bilat",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38913",
                "title": "38913 Neural hear loss, unilat",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38914",
                "title": "38914 Central hearing loss",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38915",
                "title": "38915 Sensorneur hear loss uni",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38916",
                "title": "38916 Sensoneur hear loss asym",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38917",
                "title": "38917 Sensory hear loss,unilat",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38918",
                "title": "38918 Sensonrl hear loss,bilat",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38920",
                "title": "38920 Mixed hearing loss NOS",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38921",
                "title": "38921 Mixed hearing loss,unilt",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "38922",
                "title": "38922 Mixed hearing loss,bilat",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "3897",
                "title": "3897 Deaf, nonspeaking NEC",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "3898",
                "title": "3898 Hearing loss NEC",
                "threedigit": "389",
                "isLeaf": true
              },
              {
                "key": "3899",
                "title": "3899 Hearing loss NOS",
                "threedigit": "389",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Diseases Of The Circulatory System",
    "title": "390-459 Diseases Of The Circulatory System",
    "threedigit": "390",
    "children": [
      {
        "key": "Acute Rheumatic Fever",
        "title": "390-392 Acute Rheumatic Fever",
        "threedigit": "390",
        "children": [
          {
            "key": "Rheumatic fever without mention of heart involvement",
            "title": "390 - Rheumatic fever without mention of heart involvement",
            "threedigit": "390",
            "children": [
              {
                "key": "390",
                "title": "390 Rheum fev w/o hrt involv",
                "threedigit": "390",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Rheumatic fever with heart involvement",
            "title": "391 - Rheumatic fever with heart involvement",
            "threedigit": "391",
            "children": [
              {
                "key": "3910",
                "title": "3910 Acute rheumatic pericard",
                "threedigit": "391",
                "isLeaf": true
              },
              {
                "key": "3911",
                "title": "3911 Acute rheumatic endocard",
                "threedigit": "391",
                "isLeaf": true
              },
              {
                "key": "3912",
                "title": "3912 Ac rheumatic myocarditis",
                "threedigit": "391",
                "isLeaf": true
              },
              {
                "key": "3918",
                "title": "3918 Ac rheumat hrt dis NEC",
                "threedigit": "391",
                "isLeaf": true
              },
              {
                "key": "3919",
                "title": "3919 Ac rheumat hrt dis NOS",
                "threedigit": "391",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Rheumatic chorea",
            "title": "392 - Rheumatic chorea",
            "threedigit": "392",
            "children": [
              {
                "key": "3920",
                "title": "3920 Rheum chorea w hrt invol",
                "threedigit": "392",
                "isLeaf": true
              },
              {
                "key": "3929",
                "title": "3929 Rheumatic chorea NOS",
                "threedigit": "392",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Chronic Rheumatic Heart Disease",
        "title": "393-398 Chronic Rheumatic Heart Disease",
        "threedigit": "393",
        "children": [
          {
            "key": "Chronic rheumatic pericarditis",
            "title": "393 - Chronic rheumatic pericarditis",
            "threedigit": "393",
            "children": [
              {
                "key": "393",
                "title": "393 Chr rheumatic pericard",
                "threedigit": "393",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of mitral valve",
            "title": "394 - Diseases of mitral valve",
            "threedigit": "394",
            "children": [
              {
                "key": "3940",
                "title": "3940 Mitral stenosis",
                "threedigit": "394",
                "isLeaf": true
              },
              {
                "key": "3941",
                "title": "3941 Rheumatic mitral insuff",
                "threedigit": "394",
                "isLeaf": true
              },
              {
                "key": "3942",
                "title": "3942 Mitral stenosis w insuff",
                "threedigit": "394",
                "isLeaf": true
              },
              {
                "key": "3949",
                "title": "3949 Mitral valve dis NEC/NOS",
                "threedigit": "394",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of aortic valve",
            "title": "395 - Diseases of aortic valve",
            "threedigit": "395",
            "children": [
              {
                "key": "3950",
                "title": "3950 Rheumat aortic stenosis",
                "threedigit": "395",
                "isLeaf": true
              },
              {
                "key": "3951",
                "title": "3951 Rheumatic aortic insuff",
                "threedigit": "395",
                "isLeaf": true
              },
              {
                "key": "3952",
                "title": "3952 Rheum aortic sten/insuff",
                "threedigit": "395",
                "isLeaf": true
              },
              {
                "key": "3959",
                "title": "3959 Rheum aortic dis NEC/NOS",
                "threedigit": "395",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of mitral and aortic valves",
            "title": "396 - Diseases of mitral and aortic valves",
            "threedigit": "396",
            "children": [
              {
                "key": "3960",
                "title": "3960 Mitral/aortic stenosis",
                "threedigit": "396",
                "isLeaf": true
              },
              {
                "key": "3961",
                "title": "3961 Mitral stenos/aort insuf",
                "threedigit": "396",
                "isLeaf": true
              },
              {
                "key": "3962",
                "title": "3962 Mitral insuf/aort stenos",
                "threedigit": "396",
                "isLeaf": true
              },
              {
                "key": "3963",
                "title": "3963 Mitral/aortic val insuff",
                "threedigit": "396",
                "isLeaf": true
              },
              {
                "key": "3968",
                "title": "3968 Mitr/aortic mult involv",
                "threedigit": "396",
                "isLeaf": true
              },
              {
                "key": "3969",
                "title": "3969 Mitral/aortic v dis NOS",
                "threedigit": "396",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of other endocardial structures",
            "title": "397 - Diseases of other endocardial structures",
            "threedigit": "397",
            "children": [
              {
                "key": "3970",
                "title": "3970 Tricuspid valve disease",
                "threedigit": "397",
                "isLeaf": true
              },
              {
                "key": "3971",
                "title": "3971 Rheum pulmon valve dis",
                "threedigit": "397",
                "isLeaf": true
              },
              {
                "key": "3979",
                "title": "3979 Rheum endocarditis NOS",
                "threedigit": "397",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other rheumatic heart disease",
            "title": "398 - Other rheumatic heart disease",
            "threedigit": "398",
            "children": [
              {
                "key": "3980",
                "title": "3980 Rheumatic myocarditis",
                "threedigit": "398",
                "isLeaf": true
              },
              {
                "key": "39890",
                "title": "39890 Rheumatic heart dis NOS",
                "threedigit": "398",
                "isLeaf": true
              },
              {
                "key": "39891",
                "title": "39891 Rheumatic heart failure",
                "threedigit": "398",
                "isLeaf": true
              },
              {
                "key": "39899",
                "title": "39899 Rheumatic heart dis NEC",
                "threedigit": "398",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Hypertensive Disease",
        "title": "401-405 Hypertensive Disease",
        "threedigit": "401",
        "children": [
          {
            "key": "Essential hypertension",
            "title": "401 - Essential hypertension",
            "threedigit": "401",
            "children": [
              {
                "key": "4010",
                "title": "4010 Malignant hypertension",
                "threedigit": "401",
                "isLeaf": true
              },
              {
                "key": "4011",
                "title": "4011 Benign hypertension",
                "threedigit": "401",
                "isLeaf": true
              },
              {
                "key": "4019",
                "title": "4019 Hypertension NOS",
                "threedigit": "401",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hypertensive heart disease",
            "title": "402 - Hypertensive heart disease",
            "threedigit": "402",
            "children": [
              {
                "key": "40200",
                "title": "40200 Mal hyp ht dis w/o hf",
                "threedigit": "402",
                "isLeaf": true
              },
              {
                "key": "40201",
                "title": "40201 Mal hypert hrt dis w hf",
                "threedigit": "402",
                "isLeaf": true
              },
              {
                "key": "40210",
                "title": "40210 Benign hyp ht dis w/o hf",
                "threedigit": "402",
                "isLeaf": true
              },
              {
                "key": "40211",
                "title": "40211 Benign hyp ht dis w hf",
                "threedigit": "402",
                "isLeaf": true
              },
              {
                "key": "40290",
                "title": "40290 Hyp hrt dis NOS w/o hf",
                "threedigit": "402",
                "isLeaf": true
              },
              {
                "key": "40291",
                "title": "40291 Hyp ht dis NOS w ht fail",
                "threedigit": "402",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hypertensive chronic kidney disease",
            "title": "403 - Hypertensive chronic kidney disease",
            "threedigit": "403",
            "children": [
              {
                "key": "40300",
                "title": "40300 Mal hy kid w cr kid I-IV",
                "threedigit": "403",
                "isLeaf": true
              },
              {
                "key": "40301",
                "title": "40301 Mal hyp kid w cr kid V",
                "threedigit": "403",
                "isLeaf": true
              },
              {
                "key": "40310",
                "title": "40310 Ben hy kid w cr kid I-IV",
                "threedigit": "403",
                "isLeaf": true
              },
              {
                "key": "40311",
                "title": "40311 Ben hyp kid w cr kid V",
                "threedigit": "403",
                "isLeaf": true
              },
              {
                "key": "40390",
                "title": "40390 Hy kid NOS w cr kid I-IV",
                "threedigit": "403",
                "isLeaf": true
              },
              {
                "key": "40391",
                "title": "40391 Hyp kid NOS w cr kid V",
                "threedigit": "403",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hypertensive heart and chronic kidney disease",
            "title": "404 - Hypertensive heart and chronic kidney disease",
            "threedigit": "404",
            "children": [
              {
                "key": "40400",
                "title": "40400 Mal hy ht/kd I-IV w/o hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40401",
                "title": "40401 Mal hyp ht/kd I-IV w hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40402",
                "title": "40402 Mal hy ht/kd st V w/o hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40403",
                "title": "40403 Mal hyp ht/kd stg V w hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40410",
                "title": "40410 Ben hy ht/kd I-IV w/o hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40411",
                "title": "40411 Ben hyp ht/kd I-IV w hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40412",
                "title": "40412 Ben hy ht/kd st V w/o hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40413",
                "title": "40413 Ben hyp ht/kd stg V w hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40490",
                "title": "40490 Hy ht/kd NOS I-IV w/o hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40491",
                "title": "40491 Hyp ht/kd NOS I-IV w hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40492",
                "title": "40492 Hy ht/kd NOS st V w/o hf",
                "threedigit": "404",
                "isLeaf": true
              },
              {
                "key": "40493",
                "title": "40493 Hyp ht/kd NOS st V w hf",
                "threedigit": "404",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Secondary hypertension",
            "title": "405 - Secondary hypertension",
            "threedigit": "405",
            "children": [
              {
                "key": "40501",
                "title": "40501 Mal renovasc hypertens",
                "threedigit": "405",
                "isLeaf": true
              },
              {
                "key": "40509",
                "title": "40509 Mal second hyperten NEC",
                "threedigit": "405",
                "isLeaf": true
              },
              {
                "key": "40511",
                "title": "40511 Benign renovasc hyperten",
                "threedigit": "405",
                "isLeaf": true
              },
              {
                "key": "40519",
                "title": "40519 Benign second hypert NEC",
                "threedigit": "405",
                "isLeaf": true
              },
              {
                "key": "40591",
                "title": "40591 Renovasc hypertension",
                "threedigit": "405",
                "isLeaf": true
              },
              {
                "key": "40599",
                "title": "40599 Second hypertension NEC",
                "threedigit": "405",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Ischemic Heart Disease",
        "title": "410-414 Ischemic Heart Disease",
        "threedigit": "410",
        "children": [
          {
            "key": "Acute myocardial infarction",
            "title": "410 - Acute myocardial infarction",
            "threedigit": "410",
            "children": [
              {
                "key": "41000",
                "title": "41000 AMI anterolateral,unspec",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41001",
                "title": "41001 AMI anterolateral, init",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41002",
                "title": "41002 AMI anterolateral,subseq",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41010",
                "title": "41010 AMI anterior wall,unspec",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41011",
                "title": "41011 AMI anterior wall, init",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41012",
                "title": "41012 AMI anterior wall,subseq",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41020",
                "title": "41020 AMI inferolateral,unspec",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41021",
                "title": "41021 AMI inferolateral, init",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41022",
                "title": "41022 AMI inferolateral,subseq",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41030",
                "title": "41030 AMI inferopost, unspec",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41031",
                "title": "41031 AMI inferopost, initial",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41032",
                "title": "41032 AMI inferopost, subseq",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41040",
                "title": "41040 AMI inferior wall,unspec",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41041",
                "title": "41041 AMI inferior wall, init",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41042",
                "title": "41042 AMI inferior wall,subseq",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41050",
                "title": "41050 AMI lateral NEC, unspec",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41051",
                "title": "41051 AMI lateral NEC, initial",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41052",
                "title": "41052 AMI lateral NEC, subseq",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41060",
                "title": "41060 True post infarct,unspec",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41061",
                "title": "41061 True post infarct, init",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41062",
                "title": "41062 True post infarct,subseq",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41070",
                "title": "41070 Subendo infarct, unspec",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41071",
                "title": "41071 Subendo infarct, initial",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41072",
                "title": "41072 Subendo infarct, subseq",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41080",
                "title": "41080 AMI NEC, unspecified",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41081",
                "title": "41081 AMI NEC, initial",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41082",
                "title": "41082 AMI NEC, subsequent",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41090",
                "title": "41090 AMI NOS, unspecified",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41091",
                "title": "41091 AMI NOS, initial",
                "threedigit": "410",
                "isLeaf": true
              },
              {
                "key": "41092",
                "title": "41092 AMI NOS, subsequent",
                "threedigit": "410",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other acute and subacute forms of ischemic heart disease",
            "title": "411 - Other acute and subacute forms of ischemic heart disease",
            "threedigit": "411",
            "children": [
              {
                "key": "4110",
                "title": "4110 Post MI syndrome",
                "threedigit": "411",
                "isLeaf": true
              },
              {
                "key": "4111",
                "title": "4111 Intermed coronary synd",
                "threedigit": "411",
                "isLeaf": true
              },
              {
                "key": "41181",
                "title": "41181 Acute cor occlsn w/o MI",
                "threedigit": "411",
                "isLeaf": true
              },
              {
                "key": "41189",
                "title": "41189 Ac ischemic hrt dis NEC",
                "threedigit": "411",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Old myocardial infarction",
            "title": "412 - Old myocardial infarction",
            "threedigit": "412",
            "children": [
              {
                "key": "412",
                "title": "412 Old myocardial infarct",
                "threedigit": "412",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Angina pectoris",
            "title": "413 - Angina pectoris",
            "threedigit": "413",
            "children": [
              {
                "key": "4130",
                "title": "4130 Angina decubitus",
                "threedigit": "413",
                "isLeaf": true
              },
              {
                "key": "4131",
                "title": "4131 Prinzmetal angina",
                "threedigit": "413",
                "isLeaf": true
              },
              {
                "key": "4139",
                "title": "4139 Angina pectoris NEC/NOS",
                "threedigit": "413",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other forms of chronic ischemic heart disease",
            "title": "414 - Other forms of chronic ischemic heart disease",
            "threedigit": "414",
            "children": [
              {
                "key": "41400",
                "title": "41400 Cor ath unsp vsl ntv/gft",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41401",
                "title": "41401 Crnry athrscl natve vssl",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41402",
                "title": "41402 Crn ath atlg vn bps grft",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41403",
                "title": "41403 Crn ath nonatlg blg grft",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41404",
                "title": "41404 Cor ath artry bypas grft",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41405",
                "title": "41405 Cor ath bypass graft NOS",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41406",
                "title": "41406 Cor ath natv art tp hrt",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41407",
                "title": "41407 Cor ath bps graft tp hrt",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41410",
                "title": "41410 Aneurysm of heart",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41411",
                "title": "41411 Aneurysm coronary vessel",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41412",
                "title": "41412 Dissection cor artery",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "41419",
                "title": "41419 Aneurysm of heart NEC",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "4142",
                "title": "4142 Chr tot occlus cor artry",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "4143",
                "title": "4143 Cor ath d/t lpd rch plaq",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "4144",
                "title": "4144 Cor ath d/t calc cor lsn",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "4148",
                "title": "4148 Chr ischemic hrt dis NEC",
                "threedigit": "414",
                "isLeaf": true
              },
              {
                "key": "4149",
                "title": "4149 Chr ischemic hrt dis NOS",
                "threedigit": "414",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Diseases Of Pulmonary Circulation",
        "title": "415-417 Diseases Of Pulmonary Circulation",
        "threedigit": "415",
        "children": [
          {
            "key": "Acute pulmonary heart disease",
            "title": "415 - Acute pulmonary heart disease",
            "threedigit": "415",
            "children": [
              {
                "key": "4150",
                "title": "4150 Acute cor pulmonale",
                "threedigit": "415",
                "isLeaf": true
              },
              {
                "key": "41511",
                "title": "41511 Iatrogen pulm emb/infarc",
                "threedigit": "415",
                "isLeaf": true
              },
              {
                "key": "41512",
                "title": "41512 Septic pulmonary embolsm",
                "threedigit": "415",
                "isLeaf": true
              },
              {
                "key": "41513",
                "title": "41513 Saddle embol pulmon art",
                "threedigit": "415",
                "isLeaf": true
              },
              {
                "key": "41519",
                "title": "41519 Pulm embol/infarct NEC",
                "threedigit": "415",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic pulmonary heart disease",
            "title": "416 - Chronic pulmonary heart disease",
            "threedigit": "416",
            "children": [
              {
                "key": "4160",
                "title": "4160 Prim pulm hypertension",
                "threedigit": "416",
                "isLeaf": true
              },
              {
                "key": "4161",
                "title": "4161 Kyphoscoliotic heart dis",
                "threedigit": "416",
                "isLeaf": true
              },
              {
                "key": "4162",
                "title": "4162 Chr pulmonary embolism",
                "threedigit": "416",
                "isLeaf": true
              },
              {
                "key": "4168",
                "title": "4168 Chr pulmon heart dis NEC",
                "threedigit": "416",
                "isLeaf": true
              },
              {
                "key": "4169",
                "title": "4169 Chr pulmon heart dis NOS",
                "threedigit": "416",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of pulmonary circulation",
            "title": "417 - Other diseases of pulmonary circulation",
            "threedigit": "417",
            "children": [
              {
                "key": "4170",
                "title": "4170 Arterioven fistu pul ves",
                "threedigit": "417",
                "isLeaf": true
              },
              {
                "key": "4171",
                "title": "4171 Pulmon artery aneurysm",
                "threedigit": "417",
                "isLeaf": true
              },
              {
                "key": "4178",
                "title": "4178 Pulmon circulat dis NEC",
                "threedigit": "417",
                "isLeaf": true
              },
              {
                "key": "4179",
                "title": "4179 Pulmon circulat dis NOS",
                "threedigit": "417",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Forms Of Heart Disease",
        "title": "420-429 Other Forms Of Heart Disease",
        "threedigit": "420",
        "children": [
          {
            "key": "Acute pericarditis",
            "title": "420 - Acute pericarditis",
            "threedigit": "420",
            "children": [
              {
                "key": "4200",
                "title": "4200 Ac pericardit in oth dis",
                "threedigit": "420",
                "isLeaf": true
              },
              {
                "key": "42090",
                "title": "42090 Acute pericarditis NOS",
                "threedigit": "420",
                "isLeaf": true
              },
              {
                "key": "42091",
                "title": "42091 Ac idiopath pericarditis",
                "threedigit": "420",
                "isLeaf": true
              },
              {
                "key": "42099",
                "title": "42099 Acute pericarditis NEC",
                "threedigit": "420",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute and subacute endocarditis",
            "title": "421 - Acute and subacute endocarditis",
            "threedigit": "421",
            "children": [
              {
                "key": "4210",
                "title": "4210 Ac/subac bact endocard",
                "threedigit": "421",
                "isLeaf": true
              },
              {
                "key": "4211",
                "title": "4211 Ac endocardit in oth dis",
                "threedigit": "421",
                "isLeaf": true
              },
              {
                "key": "4219",
                "title": "4219 Ac/subac endocardit NOS",
                "threedigit": "421",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute myocarditis",
            "title": "422 - Acute myocarditis",
            "threedigit": "422",
            "children": [
              {
                "key": "4220",
                "title": "4220 Ac myocardit in oth dis",
                "threedigit": "422",
                "isLeaf": true
              },
              {
                "key": "42290",
                "title": "42290 Acute myocarditis NOS",
                "threedigit": "422",
                "isLeaf": true
              },
              {
                "key": "42291",
                "title": "42291 Idiopathic myocarditis",
                "threedigit": "422",
                "isLeaf": true
              },
              {
                "key": "42292",
                "title": "42292 Septic myocarditis",
                "threedigit": "422",
                "isLeaf": true
              },
              {
                "key": "42293",
                "title": "42293 Toxic myocarditis",
                "threedigit": "422",
                "isLeaf": true
              },
              {
                "key": "42299",
                "title": "42299 Acute myocarditis NEC",
                "threedigit": "422",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of pericardium",
            "title": "423 - Other diseases of pericardium",
            "threedigit": "423",
            "children": [
              {
                "key": "4230",
                "title": "4230 Hemopericardium",
                "threedigit": "423",
                "isLeaf": true
              },
              {
                "key": "4231",
                "title": "4231 Adhesive pericarditis",
                "threedigit": "423",
                "isLeaf": true
              },
              {
                "key": "4232",
                "title": "4232 Constrictiv pericarditis",
                "threedigit": "423",
                "isLeaf": true
              },
              {
                "key": "4233",
                "title": "4233 Cardiac tamponade",
                "threedigit": "423",
                "isLeaf": true
              },
              {
                "key": "4238",
                "title": "4238 Pericardial disease NEC",
                "threedigit": "423",
                "isLeaf": true
              },
              {
                "key": "4239",
                "title": "4239 Pericardial disease NOS",
                "threedigit": "423",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of endocardium",
            "title": "424 - Other diseases of endocardium",
            "threedigit": "424",
            "children": [
              {
                "key": "4240",
                "title": "4240 Mitral valve disorder",
                "threedigit": "424",
                "isLeaf": true
              },
              {
                "key": "4241",
                "title": "4241 Aortic valve disorder",
                "threedigit": "424",
                "isLeaf": true
              },
              {
                "key": "4242",
                "title": "4242 Nonrheum tricusp val dis",
                "threedigit": "424",
                "isLeaf": true
              },
              {
                "key": "4243",
                "title": "4243 Pulmonary valve disorder",
                "threedigit": "424",
                "isLeaf": true
              },
              {
                "key": "42490",
                "title": "42490 Endocarditis NOS",
                "threedigit": "424",
                "isLeaf": true
              },
              {
                "key": "42491",
                "title": "42491 Endocarditis in oth dis",
                "threedigit": "424",
                "isLeaf": true
              },
              {
                "key": "42499",
                "title": "42499 Endocarditis NEC",
                "threedigit": "424",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cardiomyopathy",
            "title": "425 - Cardiomyopathy",
            "threedigit": "425",
            "children": [
              {
                "key": "4250",
                "title": "4250 Endomyocardial fibrosis",
                "threedigit": "425",
                "isLeaf": true
              },
              {
                "key": "42511",
                "title": "42511 Hyprtrophc obst cardiomy",
                "threedigit": "425",
                "isLeaf": true
              },
              {
                "key": "42518",
                "title": "42518 Oth hyprtrophic cardiomy",
                "threedigit": "425",
                "isLeaf": true
              },
              {
                "key": "4252",
                "title": "4252 Obsc afric cardiomyopath",
                "threedigit": "425",
                "isLeaf": true
              },
              {
                "key": "4253",
                "title": "4253 Endocard fibroelastosis",
                "threedigit": "425",
                "isLeaf": true
              },
              {
                "key": "4254",
                "title": "4254 Prim cardiomyopathy NEC",
                "threedigit": "425",
                "isLeaf": true
              },
              {
                "key": "4255",
                "title": "4255 Alcoholic cardiomyopathy",
                "threedigit": "425",
                "isLeaf": true
              },
              {
                "key": "4257",
                "title": "4257 Metabolic cardiomyopathy",
                "threedigit": "425",
                "isLeaf": true
              },
              {
                "key": "4258",
                "title": "4258 Cardiomyopath in oth dis",
                "threedigit": "425",
                "isLeaf": true
              },
              {
                "key": "4259",
                "title": "4259 Second cardiomyopath NOS",
                "threedigit": "425",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Conduction disorders",
            "title": "426 - Conduction disorders",
            "threedigit": "426",
            "children": [
              {
                "key": "4260",
                "title": "4260 Atriovent block complete",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42610",
                "title": "42610 Atriovent block NOS",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42611",
                "title": "42611 Atriovent block-1st degr",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42612",
                "title": "42612 Atrioven block-mobitz ii",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42613",
                "title": "42613 Av block-2nd degree NEC",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "4262",
                "title": "4262 Left bb hemiblock",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "4263",
                "title": "4263 Left bb block NEC",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "4264",
                "title": "4264 Rt bundle branch block",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42650",
                "title": "42650 Bundle branch block NOS",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42651",
                "title": "42651 Rt bbb/lft post fasc blk",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42652",
                "title": "42652 Rt bbb/lft ant fasc blk",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42653",
                "title": "42653 Bilat bb block NEC",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42654",
                "title": "42654 Trifascicular block",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "4266",
                "title": "4266 Other heart block",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "4267",
                "title": "4267 Anomalous av excitation",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42681",
                "title": "42681 Lown-ganong-levine synd",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42682",
                "title": "42682 Long QT syndrome",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "42689",
                "title": "42689 Conduction disorder NEC",
                "threedigit": "426",
                "isLeaf": true
              },
              {
                "key": "4269",
                "title": "4269 Conduction disorder NOS",
                "threedigit": "426",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cardiac dysrhythmias",
            "title": "427 - Cardiac dysrhythmias",
            "threedigit": "427",
            "children": [
              {
                "key": "4270",
                "title": "4270 Parox atrial tachycardia",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "4271",
                "title": "4271 Parox ventric tachycard",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "4272",
                "title": "4272 Parox tachycardia NOS",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "42731",
                "title": "42731 Atrial fibrillation",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "42732",
                "title": "42732 Atrial flutter",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "42741",
                "title": "42741 Ventricular fibrillation",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "42742",
                "title": "42742 Ventricular flutter",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "4275",
                "title": "4275 Cardiac arrest",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "42760",
                "title": "42760 Premature beats NOS",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "42761",
                "title": "42761 Atrial premature beats",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "42769",
                "title": "42769 Premature beats NEC",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "42781",
                "title": "42781 Sinoatrial node dysfunct",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "42789",
                "title": "42789 Cardiac dysrhythmias NEC",
                "threedigit": "427",
                "isLeaf": true
              },
              {
                "key": "4279",
                "title": "4279 Cardiac dysrhythmia NOS",
                "threedigit": "427",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Heart failure",
            "title": "428 - Heart failure",
            "threedigit": "428",
            "children": [
              {
                "key": "4280",
                "title": "4280 CHF NOS",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "4281",
                "title": "4281 Left heart failure",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42820",
                "title": "42820 Systolic hrt failure NOS",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42821",
                "title": "42821 Ac systolic hrt failure",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42822",
                "title": "42822 Chr systolic hrt failure",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42823",
                "title": "42823 Ac on chr syst hrt fail",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42830",
                "title": "42830 Diastolc hrt failure NOS",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42831",
                "title": "42831 Ac diastolic hrt failure",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42832",
                "title": "42832 Chr diastolic hrt fail",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42833",
                "title": "42833 Ac on chr diast hrt fail",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42840",
                "title": "42840 Syst/diast hrt fail NOS",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42841",
                "title": "42841 Ac syst/diastol hrt fail",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42842",
                "title": "42842 Chr syst/diastl hrt fail",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "42843",
                "title": "42843 Ac/chr syst/dia hrt fail",
                "threedigit": "428",
                "isLeaf": true
              },
              {
                "key": "4289",
                "title": "4289 Heart failure NOS",
                "threedigit": "428",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ill-defined descriptions and complications of heart disease",
            "title": "429 - Ill-defined descriptions and complications of heart disease",
            "threedigit": "429",
            "children": [
              {
                "key": "4290",
                "title": "4290 Myocarditis NOS",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "4291",
                "title": "4291 Myocardial degeneration",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "4292",
                "title": "4292 Ascvd",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "4293",
                "title": "4293 Cardiomegaly",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "4294",
                "title": "4294 Hrt dis postcardiac surg",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "4295",
                "title": "4295 Chordae tendinae rupture",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "4296",
                "title": "4296 Papillary muscle rupture",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "42971",
                "title": "42971 Acq cardiac septl defect",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "42979",
                "title": "42979 Other sequelae of MI NEC",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "42981",
                "title": "42981 Papillary muscle dis NEC",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "42982",
                "title": "42982 Hyperkinetic heart dis",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "42983",
                "title": "42983 Takotsubo syndrome",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "42989",
                "title": "42989 Ill-defined hrt dis NEC",
                "threedigit": "429",
                "isLeaf": true
              },
              {
                "key": "4299",
                "title": "4299 Heart disease NOS",
                "threedigit": "429",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Cerebrovascular Disease",
        "title": "430-438 Cerebrovascular Disease",
        "threedigit": "430",
        "children": [
          {
            "key": "Subarachnoid hemorrhage",
            "title": "430 - Subarachnoid hemorrhage",
            "threedigit": "430",
            "children": [
              {
                "key": "430",
                "title": "430 Subarachnoid hemorrhage",
                "threedigit": "430",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Intracerebral hemorrhage",
            "title": "431 - Intracerebral hemorrhage",
            "threedigit": "431",
            "children": [
              {
                "key": "431",
                "title": "431 Intracerebral hemorrhage",
                "threedigit": "431",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified intracranial hemorrhage",
            "title": "432 - Other and unspecified intracranial hemorrhage",
            "threedigit": "432",
            "children": [
              {
                "key": "4320",
                "title": "4320 Nontraum extradural hem",
                "threedigit": "432",
                "isLeaf": true
              },
              {
                "key": "4321",
                "title": "4321 Subdural hemorrhage",
                "threedigit": "432",
                "isLeaf": true
              },
              {
                "key": "4329",
                "title": "4329 Intracranial hemorr NOS",
                "threedigit": "432",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Occlusion and stenosis of precerebral arteries",
            "title": "433 - Occlusion and stenosis of precerebral arteries",
            "threedigit": "433",
            "children": [
              {
                "key": "43300",
                "title": "43300 Ocl bslr art wo infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43301",
                "title": "43301 Ocl bslr art w infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43310",
                "title": "43310 Ocl crtd art wo infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43311",
                "title": "43311 Ocl crtd art w infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43320",
                "title": "43320 Ocl vrtb art wo infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43321",
                "title": "43321 Ocl vrtb art w infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43330",
                "title": "43330 Ocl mlt bi art wo infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43331",
                "title": "43331 Ocl mlt bi art w infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43380",
                "title": "43380 Ocl spcf art wo infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43381",
                "title": "43381 Ocl spcf art w infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43390",
                "title": "43390 Ocl art NOS wo infrct",
                "threedigit": "433",
                "isLeaf": true
              },
              {
                "key": "43391",
                "title": "43391 Ocl art NOS w infrct",
                "threedigit": "433",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Occlusion of cerebral arteries",
            "title": "434 - Occlusion of cerebral arteries",
            "threedigit": "434",
            "children": [
              {
                "key": "43400",
                "title": "43400 Crbl thrmbs wo infrct",
                "threedigit": "434",
                "isLeaf": true
              },
              {
                "key": "43401",
                "title": "43401 Crbl thrmbs w infrct",
                "threedigit": "434",
                "isLeaf": true
              },
              {
                "key": "43410",
                "title": "43410 Crbl emblsm wo infrct",
                "threedigit": "434",
                "isLeaf": true
              },
              {
                "key": "43411",
                "title": "43411 Crbl emblsm w infrct",
                "threedigit": "434",
                "isLeaf": true
              },
              {
                "key": "43490",
                "title": "43490 Crbl art oc NOS wo infrc",
                "threedigit": "434",
                "isLeaf": true
              },
              {
                "key": "43491",
                "title": "43491 Crbl art ocl NOS w infrc",
                "threedigit": "434",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Transient cerebral ischemia",
            "title": "435 - Transient cerebral ischemia",
            "threedigit": "435",
            "children": [
              {
                "key": "4350",
                "title": "4350 Basilar artery syndrome",
                "threedigit": "435",
                "isLeaf": true
              },
              {
                "key": "4351",
                "title": "4351 Vertebral artery syndrom",
                "threedigit": "435",
                "isLeaf": true
              },
              {
                "key": "4352",
                "title": "4352 Subclavian steal syndrom",
                "threedigit": "435",
                "isLeaf": true
              },
              {
                "key": "4353",
                "title": "4353 Vertbrobaslr artery synd",
                "threedigit": "435",
                "isLeaf": true
              },
              {
                "key": "4358",
                "title": "4358 Trans cereb ischemia NEC",
                "threedigit": "435",
                "isLeaf": true
              },
              {
                "key": "4359",
                "title": "4359 Trans cereb ischemia NOS",
                "threedigit": "435",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute, but ill-defined, cerebrovascular disease",
            "title": "436 - Acute, but ill-defined, cerebrovascular disease",
            "threedigit": "436",
            "children": [
              {
                "key": "436",
                "title": "436 Cva",
                "threedigit": "436",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and ill-defined cerebrovascular disease",
            "title": "437 - Other and ill-defined cerebrovascular disease",
            "threedigit": "437",
            "children": [
              {
                "key": "4370",
                "title": "4370 Cerebral atherosclerosis",
                "threedigit": "437",
                "isLeaf": true
              },
              {
                "key": "4371",
                "title": "4371 Ac cerebrovasc insuf NOS",
                "threedigit": "437",
                "isLeaf": true
              },
              {
                "key": "4372",
                "title": "4372 Hypertens encephalopathy",
                "threedigit": "437",
                "isLeaf": true
              },
              {
                "key": "4373",
                "title": "4373 Nonrupt cerebral aneurym",
                "threedigit": "437",
                "isLeaf": true
              },
              {
                "key": "4374",
                "title": "4374 Cerebral arteritis",
                "threedigit": "437",
                "isLeaf": true
              },
              {
                "key": "4375",
                "title": "4375 Moyamoya disease",
                "threedigit": "437",
                "isLeaf": true
              },
              {
                "key": "4376",
                "title": "4376 Nonpyogen thrombos sinus",
                "threedigit": "437",
                "isLeaf": true
              },
              {
                "key": "4377",
                "title": "4377 Transient global amnesia",
                "threedigit": "437",
                "isLeaf": true
              },
              {
                "key": "4378",
                "title": "4378 Cerebrovasc disease NEC",
                "threedigit": "437",
                "isLeaf": true
              },
              {
                "key": "4379",
                "title": "4379 Cerebrovasc disease NOS",
                "threedigit": "437",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of cerebrovascular disease",
            "title": "438 - Late effects of cerebrovascular disease",
            "threedigit": "438",
            "children": [
              {
                "key": "4380",
                "title": "4380 Late ef CV dis-cognf def",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43810",
                "title": "43810 Late ef-spch/lng def NOS",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43811",
                "title": "43811 Late eff CV dis-aphasia",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43812",
                "title": "43812 Late eff CV dis-dysphsia",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43813",
                "title": "43813 Late eff CV-dysarthria",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43814",
                "title": "43814 Late eff CV-fluency dis",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43819",
                "title": "43819 Late ef-spch/lang df NEC",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43820",
                "title": "43820 Late ef-hemplga side NOS",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43821",
                "title": "43821 Late ef-hemplga dom side",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43822",
                "title": "43822 Late ef-hemiplga non-dom",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43830",
                "title": "43830 Late ef-mplga up lmb NOS",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43831",
                "title": "43831 Late ef-mplga up lmb dom",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43832",
                "title": "43832 Lt ef-mplga uplmb nondom",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43840",
                "title": "43840 Lte ef-mplga low lmb NOS",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43841",
                "title": "43841 Lte ef-mplga low lmb dom",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43842",
                "title": "43842 Lt ef-mplga lowlmb nondm",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43850",
                "title": "43850 Lt ef oth paral side NOS",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43851",
                "title": "43851 Lt ef oth paral dom side",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43852",
                "title": "43852 Lt ef oth parals non-dom",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43853",
                "title": "43853 Lt ef oth parals-bilat",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "4386",
                "title": "4386 Alteration of sensations",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "4387",
                "title": "4387 Disturbances of vision",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43881",
                "title": "43881 Late eff CV dis-apraxia",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43882",
                "title": "43882 Late ef CV dis dysphagia",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43883",
                "title": "43883 Facial weakness",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43884",
                "title": "43884 Ataxia",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43885",
                "title": "43885 Vertigo",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "43889",
                "title": "43889 Late effect CV dis NEC",
                "threedigit": "438",
                "isLeaf": true
              },
              {
                "key": "4389",
                "title": "4389 Late effect CV dis NOS",
                "threedigit": "438",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Diseases Of Arteries, Arterioles, And Capillaries",
        "title": "440-449 Diseases Of Arteries, Arterioles, And Capillaries",
        "threedigit": "440",
        "children": [
          {
            "key": "Atherosclerosis",
            "title": "440 - Atherosclerosis",
            "threedigit": "440",
            "children": [
              {
                "key": "4400",
                "title": "4400 Aortic atherosclerosis",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "4401",
                "title": "4401 Renal artery atheroscler",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "44020",
                "title": "44020 Athscl extrm ntv art NOS",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "44021",
                "title": "44021 Ath ext ntv at w claudct",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "44022",
                "title": "44022 Ath ext ntv at w rst pn",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "44023",
                "title": "44023 Ath ext ntv art ulcrtion",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "44024",
                "title": "44024 Ath ext ntv art gngrene",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "44029",
                "title": "44029 Athrsc extrm ntv art oth",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "44030",
                "title": "44030 Athscl extrm bps gft NOS",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "44031",
                "title": "44031 Ath ext autologs bps gft",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "44032",
                "title": "44032 Ath ext nonautlg bps gft",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "4404",
                "title": "4404 Chr tot occl art extrem",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "4408",
                "title": "4408 Atherosclerosis NEC",
                "threedigit": "440",
                "isLeaf": true
              },
              {
                "key": "4409",
                "title": "4409 Atherosclerosis NOS",
                "threedigit": "440",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Aortic aneurysm and dissection",
            "title": "441 - Aortic aneurysm and dissection",
            "threedigit": "441",
            "children": [
              {
                "key": "44100",
                "title": "44100 Dsct of aorta unsp site",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "44101",
                "title": "44101 Dsct of thoracic aorta",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "44102",
                "title": "44102 Dsct of abdominal aorta",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "44103",
                "title": "44103 Dsct of thoracoabd aorta",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "4411",
                "title": "4411 Ruptur thoracic aneurysm",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "4412",
                "title": "4412 Thoracic aortic aneurysm",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "4413",
                "title": "4413 Rupt abd aortic aneurysm",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "4414",
                "title": "4414 Abdom aortic aneurysm",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "4415",
                "title": "4415 Rupt aortic aneurysm NOS",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "4416",
                "title": "4416 Thoracoabd aneurysm rupt",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "4417",
                "title": "4417 Thracabd anurysm wo rupt",
                "threedigit": "441",
                "isLeaf": true
              },
              {
                "key": "4419",
                "title": "4419 Aortic aneurysm NOS",
                "threedigit": "441",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other aneurysm",
            "title": "442 - Other aneurysm",
            "threedigit": "442",
            "children": [
              {
                "key": "4420",
                "title": "4420 Upper extremity aneurysm",
                "threedigit": "442",
                "isLeaf": true
              },
              {
                "key": "4421",
                "title": "4421 Renal artery aneurysm",
                "threedigit": "442",
                "isLeaf": true
              },
              {
                "key": "4422",
                "title": "4422 Iliac artery aneurysm",
                "threedigit": "442",
                "isLeaf": true
              },
              {
                "key": "4423",
                "title": "4423 Lower extremity aneurysm",
                "threedigit": "442",
                "isLeaf": true
              },
              {
                "key": "44281",
                "title": "44281 Aneurysm of neck",
                "threedigit": "442",
                "isLeaf": true
              },
              {
                "key": "44282",
                "title": "44282 Subclavian aneurysm",
                "threedigit": "442",
                "isLeaf": true
              },
              {
                "key": "44283",
                "title": "44283 Splenic artery aneurysm",
                "threedigit": "442",
                "isLeaf": true
              },
              {
                "key": "44284",
                "title": "44284 Visceral aneurysm NEC",
                "threedigit": "442",
                "isLeaf": true
              },
              {
                "key": "44289",
                "title": "44289 Aneurysm NEC",
                "threedigit": "442",
                "isLeaf": true
              },
              {
                "key": "4429",
                "title": "4429 Aneurysm NOS",
                "threedigit": "442",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other peripheral vascular disease",
            "title": "443 - Other peripheral vascular disease",
            "threedigit": "443",
            "children": [
              {
                "key": "4430",
                "title": "4430 Raynaud's syndrome",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "4431",
                "title": "4431 Thromboangiit obliterans",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "44321",
                "title": "44321 Dissect carotid artery",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "44322",
                "title": "44322 Dissection iliac artery",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "44323",
                "title": "44323 Dissection renal artery",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "44324",
                "title": "44324 Dissect vertebral artery",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "44329",
                "title": "44329 Dissection artery NEC",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "44381",
                "title": "44381 Angiopathy in other dis",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "44382",
                "title": "44382 Erythromelalgia",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "44389",
                "title": "44389 Periph vascular dis NEC",
                "threedigit": "443",
                "isLeaf": true
              },
              {
                "key": "4439",
                "title": "4439 Periph vascular dis NOS",
                "threedigit": "443",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Arterial embolism and thrombosis",
            "title": "444 - Arterial embolism and thrombosis",
            "threedigit": "444",
            "children": [
              {
                "key": "44401",
                "title": "44401 Saddle embolus abd aorta",
                "threedigit": "444",
                "isLeaf": true
              },
              {
                "key": "44409",
                "title": "44409 Ot art emb/thrm abd aort",
                "threedigit": "444",
                "isLeaf": true
              },
              {
                "key": "4441",
                "title": "4441 Thoracic aortic embolism",
                "threedigit": "444",
                "isLeaf": true
              },
              {
                "key": "44421",
                "title": "44421 Upper extremity embolism",
                "threedigit": "444",
                "isLeaf": true
              },
              {
                "key": "44422",
                "title": "44422 Lower extremity embolism",
                "threedigit": "444",
                "isLeaf": true
              },
              {
                "key": "44481",
                "title": "44481 Iliac artery embolism",
                "threedigit": "444",
                "isLeaf": true
              },
              {
                "key": "44489",
                "title": "44489 Arterial embolism NEC",
                "threedigit": "444",
                "isLeaf": true
              },
              {
                "key": "4449",
                "title": "4449 Arterial embolism NOS",
                "threedigit": "444",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Atheroembolism",
            "title": "445 - Atheroembolism",
            "threedigit": "445",
            "children": [
              {
                "key": "44501",
                "title": "44501 Atheroembolism,upper ext",
                "threedigit": "445",
                "isLeaf": true
              },
              {
                "key": "44502",
                "title": "44502 Atheroembolism,lower ext",
                "threedigit": "445",
                "isLeaf": true
              },
              {
                "key": "44581",
                "title": "44581 Atheroembolism, kidney",
                "threedigit": "445",
                "isLeaf": true
              },
              {
                "key": "44589",
                "title": "44589 Atheroembolism, site NEC",
                "threedigit": "445",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Polyarteritis nodosa and allied conditions",
            "title": "446 - Polyarteritis nodosa and allied conditions",
            "threedigit": "446",
            "children": [
              {
                "key": "4460",
                "title": "4460 Polyarteritis nodosa",
                "threedigit": "446",
                "isLeaf": true
              },
              {
                "key": "4461",
                "title": "4461 Mucocutan lymph node syn",
                "threedigit": "446",
                "isLeaf": true
              },
              {
                "key": "44620",
                "title": "44620 Hypersensit angiitis NOS",
                "threedigit": "446",
                "isLeaf": true
              },
              {
                "key": "44621",
                "title": "44621 Goodpasture's syndrome",
                "threedigit": "446",
                "isLeaf": true
              },
              {
                "key": "44629",
                "title": "44629 Hypersensit angiitis NEC",
                "threedigit": "446",
                "isLeaf": true
              },
              {
                "key": "4463",
                "title": "4463 Lethal midline granuloma",
                "threedigit": "446",
                "isLeaf": true
              },
              {
                "key": "4464",
                "title": "4464 Wegener's granulomatosis",
                "threedigit": "446",
                "isLeaf": true
              },
              {
                "key": "4465",
                "title": "4465 Giant cell arteritis",
                "threedigit": "446",
                "isLeaf": true
              },
              {
                "key": "4466",
                "title": "4466 Thrombot microangiopathy",
                "threedigit": "446",
                "isLeaf": true
              },
              {
                "key": "4467",
                "title": "4467 Takayasu's disease",
                "threedigit": "446",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of arteries and arterioles",
            "title": "447 - Other disorders of arteries and arterioles",
            "threedigit": "447",
            "children": [
              {
                "key": "4470",
                "title": "4470 Acq arterioven fistula",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "4471",
                "title": "4471 Stricture of artery",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "4472",
                "title": "4472 Rupture of artery",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "4473",
                "title": "4473 Renal artery hyperplasia",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "4474",
                "title": "4474 Celiac art compress syn",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "4475",
                "title": "4475 Necrosis of artery",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "4476",
                "title": "4476 Arteritis NOS",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "44770",
                "title": "44770 Aortic ectasia, site NOS",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "44771",
                "title": "44771 Thoracic aortic ectasia",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "44772",
                "title": "44772 Abdominal aortic ectasia",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "44773",
                "title": "44773 Thoracoabd aortc ectasia",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "4478",
                "title": "4478 Arterial disease NEC",
                "threedigit": "447",
                "isLeaf": true
              },
              {
                "key": "4479",
                "title": "4479 Arterial disease NOS",
                "threedigit": "447",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disease of capillaries",
            "title": "448 - Disease of capillaries",
            "threedigit": "448",
            "children": [
              {
                "key": "4480",
                "title": "4480 Heredit hemorr telangiec",
                "threedigit": "448",
                "isLeaf": true
              },
              {
                "key": "4481",
                "title": "4481 Nevus, non-neoplastic",
                "threedigit": "448",
                "isLeaf": true
              },
              {
                "key": "4489",
                "title": "4489 Capillary dis NEC/NOS",
                "threedigit": "448",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Septic arterial embolism",
            "title": "449 - Septic arterial embolism",
            "threedigit": "449",
            "children": [
              {
                "key": "449",
                "title": "449 Septic arterial embolism",
                "threedigit": "449",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Diseases Of Veins And Lymphatics, And Other Diseases Of Circulatory System",
        "title": "451-459 Diseases Of Veins And Lymphatics, And Other Diseases Of Circulatory System",
        "threedigit": "451",
        "children": [
          {
            "key": "Phlebitis and thrombophlebitis",
            "title": "451 - Phlebitis and thrombophlebitis",
            "threedigit": "451",
            "children": [
              {
                "key": "4510",
                "title": "4510 Superfic phlebitis-leg",
                "threedigit": "451",
                "isLeaf": true
              },
              {
                "key": "45111",
                "title": "45111 Femoral vein phlebitis",
                "threedigit": "451",
                "isLeaf": true
              },
              {
                "key": "45119",
                "title": "45119 Deep phlebitis-leg NEC",
                "threedigit": "451",
                "isLeaf": true
              },
              {
                "key": "4512",
                "title": "4512 Thrombophlebitis leg NOS",
                "threedigit": "451",
                "isLeaf": true
              },
              {
                "key": "45181",
                "title": "45181 Iliac thrombophlebitis",
                "threedigit": "451",
                "isLeaf": true
              },
              {
                "key": "45182",
                "title": "45182 Phlbts sprfc vn up extrm",
                "threedigit": "451",
                "isLeaf": true
              },
              {
                "key": "45183",
                "title": "45183 Phlbts deep vn up extrm",
                "threedigit": "451",
                "isLeaf": true
              },
              {
                "key": "45184",
                "title": "45184 Phlbts vn NOS up extrm",
                "threedigit": "451",
                "isLeaf": true
              },
              {
                "key": "45189",
                "title": "45189 Thrombophlebitis NEC",
                "threedigit": "451",
                "isLeaf": true
              },
              {
                "key": "4519",
                "title": "4519 Thrombophlebitis NOS",
                "threedigit": "451",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Portal vein thrombosis",
            "title": "452 - Portal vein thrombosis",
            "threedigit": "452",
            "children": [
              {
                "key": "452",
                "title": "452 Portal vein thrombosis",
                "threedigit": "452",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other venous embolism and thrombosis",
            "title": "453 - Other venous embolism and thrombosis",
            "threedigit": "453",
            "children": [
              {
                "key": "4530",
                "title": "4530 Budd-chiari syndrome",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "4531",
                "title": "4531 Thrombophlebitis migrans",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "4532",
                "title": "4532 Oth inf vena cava thromb",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "4533",
                "title": "4533 Renal vein thrombosis",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45340",
                "title": "45340 Ac DVT/embl low ext NOS",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45341",
                "title": "45341 Ac DVT/emb prox low ext",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45342",
                "title": "45342 Ac DVT/emb distl low ext",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45350",
                "title": "45350 Ch DVT/embl low ext NOS",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45351",
                "title": "45351 Ch DVT/embl prox low ext",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45352",
                "title": "45352 Ch DVT/embl dstl low ext",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "4536",
                "title": "4536 Embl suprfcl ves low ext",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45371",
                "title": "45371 Ch emblsm suprfcl up ext",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45372",
                "title": "45372 Ch DVT/embl up ext",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45373",
                "title": "45373 Ch emblsm up ext NOS",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45374",
                "title": "45374 Ch emblsm axillary veins",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45375",
                "title": "45375 Ch emblsm subclav veins",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45376",
                "title": "45376 Ch embl internl jug vein",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45377",
                "title": "45377 Ch embl thorac vein NEC",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45379",
                "title": "45379 Ch emblsm veins NEC",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45381",
                "title": "45381 Ac embl suprfcl up ext",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45382",
                "title": "45382 Ac DVT/embl up ext",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45383",
                "title": "45383 Ac emblsm up ext NOS",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45384",
                "title": "45384 Ac emblsm axillary veins",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45385",
                "title": "45385 Ac embl subclav veins",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45386",
                "title": "45386 Ac embl internl jug vein",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45387",
                "title": "45387 Ac embl thorac vein NEC",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "45389",
                "title": "45389 Ac embolism veins NEC",
                "threedigit": "453",
                "isLeaf": true
              },
              {
                "key": "4539",
                "title": "4539 Venous thrombosis NOS",
                "threedigit": "453",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Varicose veins of lower extremities",
            "title": "454 - Varicose veins of lower extremities",
            "threedigit": "454",
            "children": [
              {
                "key": "4540",
                "title": "4540 Leg varicosity w ulcer",
                "threedigit": "454",
                "isLeaf": true
              },
              {
                "key": "4541",
                "title": "4541 Leg varicosity w inflam",
                "threedigit": "454",
                "isLeaf": true
              },
              {
                "key": "4542",
                "title": "4542 Varicos leg ulcer/inflam",
                "threedigit": "454",
                "isLeaf": true
              },
              {
                "key": "4548",
                "title": "4548 Varic vein leg,comp NEC",
                "threedigit": "454",
                "isLeaf": true
              },
              {
                "key": "4549",
                "title": "4549 Asympt varicose veins",
                "threedigit": "454",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hemorrhoids",
            "title": "455 - Hemorrhoids",
            "threedigit": "455",
            "children": [
              {
                "key": "4550",
                "title": "4550 Int hemorrhoid w/o compl",
                "threedigit": "455",
                "isLeaf": true
              },
              {
                "key": "4551",
                "title": "4551 Int thrombos hemorrhoid",
                "threedigit": "455",
                "isLeaf": true
              },
              {
                "key": "4552",
                "title": "4552 Int hemrrhoid w comp NEC",
                "threedigit": "455",
                "isLeaf": true
              },
              {
                "key": "4553",
                "title": "4553 Ext hemorrhoid w/o compl",
                "threedigit": "455",
                "isLeaf": true
              },
              {
                "key": "4554",
                "title": "4554 Ext thrombos hemorrhoid",
                "threedigit": "455",
                "isLeaf": true
              },
              {
                "key": "4555",
                "title": "4555 Ext hemrrhoid w comp NEC",
                "threedigit": "455",
                "isLeaf": true
              },
              {
                "key": "4556",
                "title": "4556 Hemorrhoids NOS",
                "threedigit": "455",
                "isLeaf": true
              },
              {
                "key": "4557",
                "title": "4557 Thrombos hemorrhoids NOS",
                "threedigit": "455",
                "isLeaf": true
              },
              {
                "key": "4558",
                "title": "4558 Hemrrhoid NOS w comp NEC",
                "threedigit": "455",
                "isLeaf": true
              },
              {
                "key": "4559",
                "title": "4559 Residual hemorrhoid tags",
                "threedigit": "455",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Varicose veins of other sites",
            "title": "456 - Varicose veins of other sites",
            "threedigit": "456",
            "children": [
              {
                "key": "4560",
                "title": "4560 Esophag varices w bleed",
                "threedigit": "456",
                "isLeaf": true
              },
              {
                "key": "4561",
                "title": "4561 Esoph varices w/o bleed",
                "threedigit": "456",
                "isLeaf": true
              },
              {
                "key": "45620",
                "title": "45620 Bleed esoph var oth dis",
                "threedigit": "456",
                "isLeaf": true
              },
              {
                "key": "45621",
                "title": "45621 Esoph varice oth dis NOS",
                "threedigit": "456",
                "isLeaf": true
              },
              {
                "key": "4563",
                "title": "4563 Sublingual varices",
                "threedigit": "456",
                "isLeaf": true
              },
              {
                "key": "4564",
                "title": "4564 Scrotal varices",
                "threedigit": "456",
                "isLeaf": true
              },
              {
                "key": "4565",
                "title": "4565 Pelvic varices",
                "threedigit": "456",
                "isLeaf": true
              },
              {
                "key": "4566",
                "title": "4566 Vulval varices",
                "threedigit": "456",
                "isLeaf": true
              },
              {
                "key": "4568",
                "title": "4568 Varices of other sites",
                "threedigit": "456",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Noninfectious disorders of lymphatic channels",
            "title": "457 - Noninfectious disorders of lymphatic channels",
            "threedigit": "457",
            "children": [
              {
                "key": "4570",
                "title": "4570 Postmastect lymphedema",
                "threedigit": "457",
                "isLeaf": true
              },
              {
                "key": "4571",
                "title": "4571 Other lymphedema",
                "threedigit": "457",
                "isLeaf": true
              },
              {
                "key": "4572",
                "title": "4572 Lymphangitis",
                "threedigit": "457",
                "isLeaf": true
              },
              {
                "key": "4578",
                "title": "4578 Noninfect lymph dis NEC",
                "threedigit": "457",
                "isLeaf": true
              },
              {
                "key": "4579",
                "title": "4579 Noninfect lymph dis NOS",
                "threedigit": "457",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hypotension",
            "title": "458 - Hypotension",
            "threedigit": "458",
            "children": [
              {
                "key": "4580",
                "title": "4580 Orthostatic hypotension",
                "threedigit": "458",
                "isLeaf": true
              },
              {
                "key": "4581",
                "title": "4581 Chronic hypotension",
                "threedigit": "458",
                "isLeaf": true
              },
              {
                "key": "45821",
                "title": "45821 Hemododialysis hypotensn",
                "threedigit": "458",
                "isLeaf": true
              },
              {
                "key": "45829",
                "title": "45829 Iatrogenc hypotnsion NEC",
                "threedigit": "458",
                "isLeaf": true
              },
              {
                "key": "4588",
                "title": "4588 Hypotension NEC",
                "threedigit": "458",
                "isLeaf": true
              },
              {
                "key": "4589",
                "title": "4589 Hypotension NOS",
                "threedigit": "458",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of circulatory system",
            "title": "459 - Other disorders of circulatory system",
            "threedigit": "459",
            "children": [
              {
                "key": "4590",
                "title": "4590 Hemorrhage NOS",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45910",
                "title": "45910 Postphlbtc synd w/o comp",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45911",
                "title": "45911 Postphlebtc synd w ulcer",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45912",
                "title": "45912 Postphlebtc syn w inflam",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45913",
                "title": "45913 Postphl syn w ulc&inflam",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45919",
                "title": "45919 Postphleb synd comp NEC",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "4592",
                "title": "4592 Compression of vein",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45930",
                "title": "45930 Chr venous hypr w/o comp",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45931",
                "title": "45931 Chr venous hyper w ulcer",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45932",
                "title": "45932 Chr venous hypr w inflam",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45933",
                "title": "45933 Chr ven hyp w ulc&inflam",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45939",
                "title": "45939 Chr venous hyp comp NEC",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45981",
                "title": "45981 Venous insufficiency NOS",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "45989",
                "title": "45989 Circulatory disease NEC",
                "threedigit": "459",
                "isLeaf": true
              },
              {
                "key": "4599",
                "title": "4599 Circulatory disease NOS",
                "threedigit": "459",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Diseases Of The Respiratory System",
    "title": "460-519 Diseases Of The Respiratory System",
    "threedigit": "460",
    "children": [
      {
        "key": "Acute Respiratory Infections",
        "title": "460-466 Acute Respiratory Infections",
        "threedigit": "460",
        "children": [
          {
            "key": "Acute nasopharyngitis [common cold]",
            "title": "460 - Acute nasopharyngitis [common cold]",
            "threedigit": "460",
            "children": [
              {
                "key": "460",
                "title": "460 Acute nasopharyngitis",
                "threedigit": "460",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute sinusitis",
            "title": "461 - Acute sinusitis",
            "threedigit": "461",
            "children": [
              {
                "key": "4610",
                "title": "4610 Ac maxillary sinusitis",
                "threedigit": "461",
                "isLeaf": true
              },
              {
                "key": "4611",
                "title": "4611 Ac frontal sinusitis",
                "threedigit": "461",
                "isLeaf": true
              },
              {
                "key": "4612",
                "title": "4612 Ac ethmoidal sinusitis",
                "threedigit": "461",
                "isLeaf": true
              },
              {
                "key": "4613",
                "title": "4613 Ac sphenoidal sinusitis",
                "threedigit": "461",
                "isLeaf": true
              },
              {
                "key": "4618",
                "title": "4618 Other acute sinusitis",
                "threedigit": "461",
                "isLeaf": true
              },
              {
                "key": "4619",
                "title": "4619 Acute sinusitis NOS",
                "threedigit": "461",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute pharyngitis",
            "title": "462 - Acute pharyngitis",
            "threedigit": "462",
            "children": [
              {
                "key": "462",
                "title": "462 Acute pharyngitis",
                "threedigit": "462",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute tonsillitis",
            "title": "463 - Acute tonsillitis",
            "threedigit": "463",
            "children": [
              {
                "key": "463",
                "title": "463 Acute tonsillitis",
                "threedigit": "463",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute laryngitis and tracheitis",
            "title": "464 - Acute laryngitis and tracheitis",
            "threedigit": "464",
            "children": [
              {
                "key": "46400",
                "title": "46400 Ac laryngitis w/o obst",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "46401",
                "title": "46401 Ac laryngitis w obstruct",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "46410",
                "title": "46410 Ac tracheitis no obstruc",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "46411",
                "title": "46411 Ac tracheitis w obstruct",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "46420",
                "title": "46420 Ac laryngotrach no obstr",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "46421",
                "title": "46421 Ac laryngotrach w obstr",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "46430",
                "title": "46430 Ac epiglottitis no obstr",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "46431",
                "title": "46431 Ac epiglottitis w obstr",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "4644",
                "title": "4644 Croup",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "46450",
                "title": "46450 Supraglottis w/o obs NOS",
                "threedigit": "464",
                "isLeaf": true
              },
              {
                "key": "46451",
                "title": "46451 Supraglottis w obstr NOS",
                "threedigit": "464",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute upper respiratory infections of multiple or unspecified sites",
            "title": "465 - Acute upper respiratory infections of multiple or unspecified sites",
            "threedigit": "465",
            "children": [
              {
                "key": "4650",
                "title": "4650 Acute laryngopharyngitis",
                "threedigit": "465",
                "isLeaf": true
              },
              {
                "key": "4658",
                "title": "4658 Acute uri mult sites NEC",
                "threedigit": "465",
                "isLeaf": true
              },
              {
                "key": "4659",
                "title": "4659 Acute uri NOS",
                "threedigit": "465",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute bronchitis and bronchiolitis",
            "title": "466 - Acute bronchitis and bronchiolitis",
            "threedigit": "466",
            "children": [
              {
                "key": "4660",
                "title": "4660 Acute bronchitis",
                "threedigit": "466",
                "isLeaf": true
              },
              {
                "key": "46611",
                "title": "46611 Acu broncholitis d/t RSV",
                "threedigit": "466",
                "isLeaf": true
              },
              {
                "key": "46619",
                "title": "46619 Acu brnchlts d/t oth org",
                "threedigit": "466",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Diseases Of Upper Respiratory Tract",
        "title": "470-478 Other Diseases Of Upper Respiratory Tract",
        "threedigit": "470",
        "children": [
          {
            "key": "Deviated nasal septum",
            "title": "470 - Deviated nasal septum",
            "threedigit": "470",
            "children": [
              {
                "key": "470",
                "title": "470 Deviated nasal septum",
                "threedigit": "470",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nasal polyps",
            "title": "471 - Nasal polyps",
            "threedigit": "471",
            "children": [
              {
                "key": "4710",
                "title": "4710 Polyp of nasal cavity",
                "threedigit": "471",
                "isLeaf": true
              },
              {
                "key": "4711",
                "title": "4711 Polypoid sinus degen",
                "threedigit": "471",
                "isLeaf": true
              },
              {
                "key": "4718",
                "title": "4718 Nasal sinus polyp NEC",
                "threedigit": "471",
                "isLeaf": true
              },
              {
                "key": "4719",
                "title": "4719 Nasal polyp NOS",
                "threedigit": "471",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic pharyngitis and nasopharyngitis",
            "title": "472 - Chronic pharyngitis and nasopharyngitis",
            "threedigit": "472",
            "children": [
              {
                "key": "4720",
                "title": "4720 Chronic rhinitis",
                "threedigit": "472",
                "isLeaf": true
              },
              {
                "key": "4721",
                "title": "4721 Chronic pharyngitis",
                "threedigit": "472",
                "isLeaf": true
              },
              {
                "key": "4722",
                "title": "4722 Chronic nasopharyngitis",
                "threedigit": "472",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic sinusitis",
            "title": "473 - Chronic sinusitis",
            "threedigit": "473",
            "children": [
              {
                "key": "4730",
                "title": "4730 Chr maxillary sinusitis",
                "threedigit": "473",
                "isLeaf": true
              },
              {
                "key": "4731",
                "title": "4731 Chr frontal sinusitis",
                "threedigit": "473",
                "isLeaf": true
              },
              {
                "key": "4732",
                "title": "4732 Chr ethmoidal sinusitis",
                "threedigit": "473",
                "isLeaf": true
              },
              {
                "key": "4733",
                "title": "4733 Chr sphenoidal sinusitis",
                "threedigit": "473",
                "isLeaf": true
              },
              {
                "key": "4738",
                "title": "4738 Chronic sinusitis NEC",
                "threedigit": "473",
                "isLeaf": true
              },
              {
                "key": "4739",
                "title": "4739 Chronic sinusitis NOS",
                "threedigit": "473",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic disease of tonsils and adenoids",
            "title": "474 - Chronic disease of tonsils and adenoids",
            "threedigit": "474",
            "children": [
              {
                "key": "47400",
                "title": "47400 Chronic tonsillitis",
                "threedigit": "474",
                "isLeaf": true
              },
              {
                "key": "47401",
                "title": "47401 Chronic adenoiditis",
                "threedigit": "474",
                "isLeaf": true
              },
              {
                "key": "47402",
                "title": "47402 Chronic tonsils&adenoids",
                "threedigit": "474",
                "isLeaf": true
              },
              {
                "key": "47410",
                "title": "47410 Hypertrophy T and A",
                "threedigit": "474",
                "isLeaf": true
              },
              {
                "key": "47411",
                "title": "47411 Hypertrophy tonsils",
                "threedigit": "474",
                "isLeaf": true
              },
              {
                "key": "47412",
                "title": "47412 Hypertrophy adenoids",
                "threedigit": "474",
                "isLeaf": true
              },
              {
                "key": "4742",
                "title": "4742 Adenoid vegetations",
                "threedigit": "474",
                "isLeaf": true
              },
              {
                "key": "4748",
                "title": "4748 Chr T & A dis NEC",
                "threedigit": "474",
                "isLeaf": true
              },
              {
                "key": "4749",
                "title": "4749 Chr T & A dis NOS",
                "threedigit": "474",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Peritonsillar abscess",
            "title": "475 - Peritonsillar abscess",
            "threedigit": "475",
            "children": [
              {
                "key": "475",
                "title": "475 Peritonsillar abscess",
                "threedigit": "475",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic laryngitis and laryngotracheitis",
            "title": "476 - Chronic laryngitis and laryngotracheitis",
            "threedigit": "476",
            "children": [
              {
                "key": "4760",
                "title": "4760 Chronic laryngitis",
                "threedigit": "476",
                "isLeaf": true
              },
              {
                "key": "4761",
                "title": "4761 Chr laryngotracheitis",
                "threedigit": "476",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Allergic rhinitis",
            "title": "477 - Allergic rhinitis",
            "threedigit": "477",
            "children": [
              {
                "key": "4770",
                "title": "4770 Rhinitis due to pollen",
                "threedigit": "477",
                "isLeaf": true
              },
              {
                "key": "4771",
                "title": "4771 Allergic rhinitis-food",
                "threedigit": "477",
                "isLeaf": true
              },
              {
                "key": "4772",
                "title": "4772 Allerg rhinitis-cat/dog",
                "threedigit": "477",
                "isLeaf": true
              },
              {
                "key": "4778",
                "title": "4778 Allergic rhinitis NEC",
                "threedigit": "477",
                "isLeaf": true
              },
              {
                "key": "4779",
                "title": "4779 Allergic rhinitis NOS",
                "threedigit": "477",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of upper respiratory tract",
            "title": "478 - Other diseases of upper respiratory tract",
            "threedigit": "478",
            "children": [
              {
                "key": "4780",
                "title": "4780 Hypertrph nasal turbinat",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47811",
                "title": "47811 Nasal mucositis (ulcer)",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47819",
                "title": "47819 Nasal & sinus dis NEC",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47820",
                "title": "47820 Disease of pharynx NOS",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47821",
                "title": "47821 Cellulitis of pharynx",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47822",
                "title": "47822 Parapharyngeal abscess",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47824",
                "title": "47824 Retropharyngeal abscess",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47825",
                "title": "47825 Edema pharynx/nasopharyx",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47826",
                "title": "47826 Cyst pharynx/nasopharynx",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47829",
                "title": "47829 Disease of pharynx NEC",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47830",
                "title": "47830 Vocal cord paralysis NOS",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47831",
                "title": "47831 Vocal paral unilat part",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47832",
                "title": "47832 Vocal paral unilat total",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47833",
                "title": "47833 Vocal paral bilat part",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47834",
                "title": "47834 Vocal paral bilat total",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "4784",
                "title": "4784 Vocal cord/larynx polyp",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "4785",
                "title": "4785 Vocal cord disease NEC",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "4786",
                "title": "4786 Edema of larynx",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47870",
                "title": "47870 Disease of larynx NOS",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47871",
                "title": "47871 Laryngeal cellulitis",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47874",
                "title": "47874 Stenosis of larynx",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47875",
                "title": "47875 Laryngeal spasm",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "47879",
                "title": "47879 Disease of larynx NEC",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "4788",
                "title": "4788 Urt hypersens react NOS",
                "threedigit": "478",
                "isLeaf": true
              },
              {
                "key": "4789",
                "title": "4789 Upper resp dis NEC/NOS",
                "threedigit": "478",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Pneumonia And Influenza",
        "title": "480-488 Pneumonia And Influenza",
        "threedigit": "480",
        "children": [
          {
            "key": "Viral pneumonia",
            "title": "480 - Viral pneumonia",
            "threedigit": "480",
            "children": [
              {
                "key": "4800",
                "title": "4800 Adenoviral pneumonia",
                "threedigit": "480",
                "isLeaf": true
              },
              {
                "key": "4801",
                "title": "4801 Resp syncyt viral pneum",
                "threedigit": "480",
                "isLeaf": true
              },
              {
                "key": "4802",
                "title": "4802 Parinfluenza viral pneum",
                "threedigit": "480",
                "isLeaf": true
              },
              {
                "key": "4803",
                "title": "4803 Pneumonia due to SARS",
                "threedigit": "480",
                "isLeaf": true
              },
              {
                "key": "4808",
                "title": "4808 Viral pneumonia NEC",
                "threedigit": "480",
                "isLeaf": true
              },
              {
                "key": "4809",
                "title": "4809 Viral pneumonia NOS",
                "threedigit": "480",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumococcal pneumonia [Streptococcus pneumoniae pneumonia]",
            "title": "481 - Pneumococcal pneumonia [Streptococcus pneumoniae pneumonia]",
            "threedigit": "481",
            "children": [
              {
                "key": "481",
                "title": "481 Pneumococcal pneumonia",
                "threedigit": "481",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other bacterial pneumonia",
            "title": "482 - Other bacterial pneumonia",
            "threedigit": "482",
            "children": [
              {
                "key": "4820",
                "title": "4820 K. pneumoniae pneumonia",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "4821",
                "title": "4821 Pseudomonal pneumonia",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "4822",
                "title": "4822 H.influenzae pneumonia",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48230",
                "title": "48230 Streptococcal pneumn NOS",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48231",
                "title": "48231 Pneumonia strptococcus a",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48232",
                "title": "48232 Pneumonia strptococcus b",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48239",
                "title": "48239 Pneumonia oth strep",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48240",
                "title": "48240 Staphylococcal pneu NOS",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48241",
                "title": "48241 Meth sus pneum d/t Staph",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48242",
                "title": "48242 Meth res pneu d/t Staph",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48249",
                "title": "48249 Staph pneumonia NEC",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48281",
                "title": "48281 Pneumonia anaerobes",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48282",
                "title": "48282 Pneumonia e coli",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48283",
                "title": "48283 Pneumo oth grm-neg bact",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48284",
                "title": "48284 Legionnaires' disease",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "48289",
                "title": "48289 Pneumonia oth spcf bact",
                "threedigit": "482",
                "isLeaf": true
              },
              {
                "key": "4829",
                "title": "4829 Bacterial pneumonia NOS",
                "threedigit": "482",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumonia due to other specified organism",
            "title": "483 - Pneumonia due to other specified organism",
            "threedigit": "483",
            "children": [
              {
                "key": "4830",
                "title": "4830 Pneu mycplsm pneumoniae",
                "threedigit": "483",
                "isLeaf": true
              },
              {
                "key": "4831",
                "title": "4831 Pneumonia d/t chlamydia",
                "threedigit": "483",
                "isLeaf": true
              },
              {
                "key": "4838",
                "title": "4838 Pneumon oth spec orgnsm",
                "threedigit": "483",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumonia in infectious diseases classified elsewhere",
            "title": "484 - Pneumonia in infectious diseases classified elsewhere",
            "threedigit": "484",
            "children": [
              {
                "key": "4841",
                "title": "4841 Pneum w cytomeg incl dis",
                "threedigit": "484",
                "isLeaf": true
              },
              {
                "key": "4843",
                "title": "4843 Pneumonia in whoop cough",
                "threedigit": "484",
                "isLeaf": true
              },
              {
                "key": "4845",
                "title": "4845 Pneumonia in anthrax",
                "threedigit": "484",
                "isLeaf": true
              },
              {
                "key": "4846",
                "title": "4846 Pneum in aspergillosis",
                "threedigit": "484",
                "isLeaf": true
              },
              {
                "key": "4847",
                "title": "4847 Pneum in oth sys mycoses",
                "threedigit": "484",
                "isLeaf": true
              },
              {
                "key": "4848",
                "title": "4848 Pneum in infect dis NEC",
                "threedigit": "484",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Bronchopneumonia, organism unspecified",
            "title": "485 - Bronchopneumonia, organism unspecified",
            "threedigit": "485",
            "children": [
              {
                "key": "485",
                "title": "485 Bronchopneumonia org NOS",
                "threedigit": "485",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumonia, organism unspecified",
            "title": "486 - Pneumonia, organism unspecified",
            "threedigit": "486",
            "children": [
              {
                "key": "486",
                "title": "486 Pneumonia, organism NOS",
                "threedigit": "486",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Influenza",
            "title": "487 - Influenza",
            "threedigit": "487",
            "children": [
              {
                "key": "4870",
                "title": "4870 Influenza with pneumonia",
                "threedigit": "487",
                "isLeaf": true
              },
              {
                "key": "4871",
                "title": "4871 Flu w resp manifest NEC",
                "threedigit": "487",
                "isLeaf": true
              },
              {
                "key": "4878",
                "title": "4878 Flu w manifestation NEC",
                "threedigit": "487",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Influenza due to certain identified influenza viruses",
            "title": "488 - Influenza due to certain identified influenza viruses",
            "threedigit": "488",
            "children": [
              {
                "key": "48801",
                "title": "48801 Flu dt iden avian w pneu",
                "threedigit": "488",
                "isLeaf": true
              },
              {
                "key": "48802",
                "title": "48802 Flu dt avian w oth resp",
                "threedigit": "488",
                "isLeaf": true
              },
              {
                "key": "48809",
                "title": "48809 Flu dt avian manfest NEC",
                "threedigit": "488",
                "isLeaf": true
              },
              {
                "key": "48811",
                "title": "48811 Flu dt 2009 H1N1 w pneu",
                "threedigit": "488",
                "isLeaf": true
              },
              {
                "key": "48812",
                "title": "48812 Flu-2009 H1N1 w oth resp",
                "threedigit": "488",
                "isLeaf": true
              },
              {
                "key": "48819",
                "title": "48819 Flu-2009 H1N1 w oth man",
                "threedigit": "488",
                "isLeaf": true
              },
              {
                "key": "48881",
                "title": "48881 Flu dt nvl A vrs w pneu",
                "threedigit": "488",
                "isLeaf": true
              },
              {
                "key": "48882",
                "title": "48882 Flu dt nvl A w oth resp",
                "threedigit": "488",
                "isLeaf": true
              },
              {
                "key": "48889",
                "title": "48889 Flu dt novel A w oth man",
                "threedigit": "488",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Chronic Obstructive Pulmonary Disease And Allied Conditions",
        "title": "490-496 Chronic Obstructive Pulmonary Disease And Allied Conditions",
        "threedigit": "490",
        "children": [
          {
            "key": "Bronchitis, not specified as acute or chronic",
            "title": "490 - Bronchitis, not specified as acute or chronic",
            "threedigit": "490",
            "children": [
              {
                "key": "490",
                "title": "490 Bronchitis NOS",
                "threedigit": "490",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic bronchitis",
            "title": "491 - Chronic bronchitis",
            "threedigit": "491",
            "children": [
              {
                "key": "4910",
                "title": "4910 Simple chr bronchitis",
                "threedigit": "491",
                "isLeaf": true
              },
              {
                "key": "4911",
                "title": "4911 Mucopurul chr bronchitis",
                "threedigit": "491",
                "isLeaf": true
              },
              {
                "key": "49120",
                "title": "49120 Obst chr bronc w/o exac",
                "threedigit": "491",
                "isLeaf": true
              },
              {
                "key": "49121",
                "title": "49121 Obs chr bronc w(ac) exac",
                "threedigit": "491",
                "isLeaf": true
              },
              {
                "key": "49122",
                "title": "49122 Obs chr bronc w ac bronc",
                "threedigit": "491",
                "isLeaf": true
              },
              {
                "key": "4918",
                "title": "4918 Chronic bronchitis NEC",
                "threedigit": "491",
                "isLeaf": true
              },
              {
                "key": "4919",
                "title": "4919 Chronic bronchitis NOS",
                "threedigit": "491",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Emphysema",
            "title": "492 - Emphysema",
            "threedigit": "492",
            "children": [
              {
                "key": "4920",
                "title": "4920 Emphysematous bleb",
                "threedigit": "492",
                "isLeaf": true
              },
              {
                "key": "4928",
                "title": "4928 Emphysema NEC",
                "threedigit": "492",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Asthma",
            "title": "493 - Asthma",
            "threedigit": "493",
            "children": [
              {
                "key": "49300",
                "title": "49300 Extrinsic asthma NOS",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49301",
                "title": "49301 Ext asthma w status asth",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49302",
                "title": "49302 Ext asthma w(acute) exac",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49310",
                "title": "49310 Intrinsic asthma NOS",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49311",
                "title": "49311 Int asthma w status asth",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49312",
                "title": "49312 Int asthma w (ac) exac",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49320",
                "title": "49320 Chronic obst asthma NOS",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49321",
                "title": "49321 Ch ob asthma w stat asth",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49322",
                "title": "49322 Ch obst asth w (ac) exac",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49381",
                "title": "49381 Exercse ind bronchospasm",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49382",
                "title": "49382 Cough variant asthma",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49390",
                "title": "49390 Asthma NOS",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49391",
                "title": "49391 Asthma w status asthmat",
                "threedigit": "493",
                "isLeaf": true
              },
              {
                "key": "49392",
                "title": "49392 Asthma NOS w (ac) exac",
                "threedigit": "493",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Bronchiectasis",
            "title": "494 - Bronchiectasis",
            "threedigit": "494",
            "children": [
              {
                "key": "4940",
                "title": "4940 Bronchiectas w/o ac exac",
                "threedigit": "494",
                "isLeaf": true
              },
              {
                "key": "4941",
                "title": "4941 Bronchiectasis w ac exac",
                "threedigit": "494",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Extrinsic allergic alveolitis",
            "title": "495 - Extrinsic allergic alveolitis",
            "threedigit": "495",
            "children": [
              {
                "key": "4950",
                "title": "4950 Farmers' lung",
                "threedigit": "495",
                "isLeaf": true
              },
              {
                "key": "4951",
                "title": "4951 Bagassosis",
                "threedigit": "495",
                "isLeaf": true
              },
              {
                "key": "4952",
                "title": "4952 Bird-fanciers' lung",
                "threedigit": "495",
                "isLeaf": true
              },
              {
                "key": "4953",
                "title": "4953 Suberosis",
                "threedigit": "495",
                "isLeaf": true
              },
              {
                "key": "4954",
                "title": "4954 Malt workers' lung",
                "threedigit": "495",
                "isLeaf": true
              },
              {
                "key": "4955",
                "title": "4955 Mushroom workers' lung",
                "threedigit": "495",
                "isLeaf": true
              },
              {
                "key": "4956",
                "title": "4956 Mapl bark-stripprs' lung",
                "threedigit": "495",
                "isLeaf": true
              },
              {
                "key": "4957",
                "title": "4957 \"ventilation\" pneumonit",
                "threedigit": "495",
                "isLeaf": true
              },
              {
                "key": "4958",
                "title": "4958 Allerg alveol/pneum NEC",
                "threedigit": "495",
                "isLeaf": true
              },
              {
                "key": "4959",
                "title": "4959 Allerg alveol/pneum NOS",
                "threedigit": "495",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic airway obstruction, not elsewhere classified",
            "title": "496 - Chronic airway obstruction, not elsewhere classified",
            "threedigit": "496",
            "children": [
              {
                "key": "496",
                "title": "496 Chr airway obstruct NEC",
                "threedigit": "496",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Pneumoconioses And Other Lung Diseases Due To External Agents",
        "title": "500-508 Pneumoconioses And Other Lung Diseases Due To External Agents",
        "threedigit": "500",
        "children": [
          {
            "key": "Coal workers' pneumoconiosis",
            "title": "500 - Coal workers' pneumoconiosis",
            "threedigit": "500",
            "children": [
              {
                "key": "500",
                "title": "500 Coal workers' pneumocon",
                "threedigit": "500",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Asbestosis",
            "title": "501 - Asbestosis",
            "threedigit": "501",
            "children": [
              {
                "key": "501",
                "title": "501 Asbestosis",
                "threedigit": "501",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumoconiosis due to other silica or silicates",
            "title": "502 - Pneumoconiosis due to other silica or silicates",
            "threedigit": "502",
            "children": [
              {
                "key": "502",
                "title": "502 Silica pneumocon NEC",
                "threedigit": "502",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumoconiosis due to other inorganic dust",
            "title": "503 - Pneumoconiosis due to other inorganic dust",
            "threedigit": "503",
            "children": [
              {
                "key": "503",
                "title": "503 Inorg dust pneumocon NEC",
                "threedigit": "503",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumonopathy due to inhalation of other dust",
            "title": "504 - Pneumonopathy due to inhalation of other dust",
            "threedigit": "504",
            "children": [
              {
                "key": "504",
                "title": "504 Dust pneumonopathy NEC",
                "threedigit": "504",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumoconiosis, unspecified",
            "title": "505 - Pneumoconiosis, unspecified",
            "threedigit": "505",
            "children": [
              {
                "key": "505",
                "title": "505 Pneumoconiosis NOS",
                "threedigit": "505",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Respiratory conditions due to chemical fumes and vapors",
            "title": "506 - Respiratory conditions due to chemical fumes and vapors",
            "threedigit": "506",
            "children": [
              {
                "key": "5060",
                "title": "5060 Fum/vapor bronc/pneumon",
                "threedigit": "506",
                "isLeaf": true
              },
              {
                "key": "5061",
                "title": "5061 Fum/vapor ac pulm edema",
                "threedigit": "506",
                "isLeaf": true
              },
              {
                "key": "5062",
                "title": "5062 Fum/vapor up resp inflam",
                "threedigit": "506",
                "isLeaf": true
              },
              {
                "key": "5063",
                "title": "5063 Fum/vap ac resp cond NEC",
                "threedigit": "506",
                "isLeaf": true
              },
              {
                "key": "5064",
                "title": "5064 Fum/vapor chr resp cond",
                "threedigit": "506",
                "isLeaf": true
              },
              {
                "key": "5069",
                "title": "5069 Fum/vapor resp cond NOS",
                "threedigit": "506",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumonitis due to solids and liquids",
            "title": "507 - Pneumonitis due to solids and liquids",
            "threedigit": "507",
            "children": [
              {
                "key": "5070",
                "title": "5070 Food/vomit pneumonitis",
                "threedigit": "507",
                "isLeaf": true
              },
              {
                "key": "5071",
                "title": "5071 Oil/essence pneumonitis",
                "threedigit": "507",
                "isLeaf": true
              },
              {
                "key": "5078",
                "title": "5078 Solid/liq pneumonit NEC",
                "threedigit": "507",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Respiratory conditions due to other and unspecified external agents",
            "title": "508 - Respiratory conditions due to other and unspecified external agents",
            "threedigit": "508",
            "children": [
              {
                "key": "5080",
                "title": "5080 Ac pul manif d/t radiat",
                "threedigit": "508",
                "isLeaf": true
              },
              {
                "key": "5081",
                "title": "5081 Chr pul manif d/t radiat",
                "threedigit": "508",
                "isLeaf": true
              },
              {
                "key": "5082",
                "title": "5082 Resp cond dt smoke inhal",
                "threedigit": "508",
                "isLeaf": true
              },
              {
                "key": "5088",
                "title": "5088 Resp cond: ext agent NEC",
                "threedigit": "508",
                "isLeaf": true
              },
              {
                "key": "5089",
                "title": "5089 Resp cond: ext agent NOS",
                "threedigit": "508",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Diseases Of Respiratory System",
        "title": "510-519 Other Diseases Of Respiratory System",
        "threedigit": "510",
        "children": [
          {
            "key": "Empyema",
            "title": "510 - Empyema",
            "threedigit": "510",
            "children": [
              {
                "key": "5100",
                "title": "5100 Empyema with fistula",
                "threedigit": "510",
                "isLeaf": true
              },
              {
                "key": "5109",
                "title": "5109 Empyema w/o fistula",
                "threedigit": "510",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pleurisy",
            "title": "511 - Pleurisy",
            "threedigit": "511",
            "children": [
              {
                "key": "5110",
                "title": "5110 Pleurisy w/o effus or TB",
                "threedigit": "511",
                "isLeaf": true
              },
              {
                "key": "5111",
                "title": "5111 Bact pleur/effus not TB",
                "threedigit": "511",
                "isLeaf": true
              },
              {
                "key": "51181",
                "title": "51181 Malignant pleural effusn",
                "threedigit": "511",
                "isLeaf": true
              },
              {
                "key": "51189",
                "title": "51189 Effusion NEC exc tb",
                "threedigit": "511",
                "isLeaf": true
              },
              {
                "key": "5119",
                "title": "5119 Pleural effusion NOS",
                "threedigit": "511",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pneumothorax and air leak",
            "title": "512 - Pneumothorax and air leak",
            "threedigit": "512",
            "children": [
              {
                "key": "5120",
                "title": "5120 Spont tens pneumothorax",
                "threedigit": "512",
                "isLeaf": true
              },
              {
                "key": "5121",
                "title": "5121 Iatrogenic pneumothorax",
                "threedigit": "512",
                "isLeaf": true
              },
              {
                "key": "5122",
                "title": "5122 Postoperative air leak",
                "threedigit": "512",
                "isLeaf": true
              },
              {
                "key": "51281",
                "title": "51281 Prim spont pneumothorax",
                "threedigit": "512",
                "isLeaf": true
              },
              {
                "key": "51282",
                "title": "51282 Sec spont pneumothorax",
                "threedigit": "512",
                "isLeaf": true
              },
              {
                "key": "51283",
                "title": "51283 Chronic pneumothorax",
                "threedigit": "512",
                "isLeaf": true
              },
              {
                "key": "51284",
                "title": "51284 Other air leak",
                "threedigit": "512",
                "isLeaf": true
              },
              {
                "key": "51289",
                "title": "51289 Other pneumothorax",
                "threedigit": "512",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Abscess of lung and mediastinum",
            "title": "513 - Abscess of lung and mediastinum",
            "threedigit": "513",
            "children": [
              {
                "key": "5130",
                "title": "5130 Abscess of lung",
                "threedigit": "513",
                "isLeaf": true
              },
              {
                "key": "5131",
                "title": "5131 Abscess of mediastinum",
                "threedigit": "513",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pulmonary congestion and hypostasis",
            "title": "514 - Pulmonary congestion and hypostasis",
            "threedigit": "514",
            "children": [
              {
                "key": "514",
                "title": "514 Pulm congest/hypostasis",
                "threedigit": "514",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Postinflammatory pulmonary fibrosis",
            "title": "515 - Postinflammatory pulmonary fibrosis",
            "threedigit": "515",
            "children": [
              {
                "key": "515",
                "title": "515 Postinflam pulm fibrosis",
                "threedigit": "515",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other alveolar and parietoalveolar pneumonopathy",
            "title": "516 - Other alveolar and parietoalveolar pneumonopathy",
            "threedigit": "516",
            "children": [
              {
                "key": "5160",
                "title": "5160 Pul alveolar proteinosis",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "5161",
                "title": "5161 Idio pulm hemosiderosis",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "5162",
                "title": "5162 Pulm alveolar microlith",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51630",
                "title": "51630 Idiopath inters pneu NOS",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51631",
                "title": "51631 Idiopath pulmon fibrosis",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51632",
                "title": "51632 Idio non-spec inter pneu",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51633",
                "title": "51633 Acute interstitial pneum",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51634",
                "title": "51634 Resp brncio interst lung",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51635",
                "title": "51635 Idiopth lym interst pneu",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51636",
                "title": "51636 Cryptogenic organiz pneu",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51637",
                "title": "51637 Desquamatv interst pneu",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "5164",
                "title": "5164 Lymphangioleiomyomatosis",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "5165",
                "title": "5165 Adlt pul Langs cell hist",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51661",
                "title": "51661 Neuroend cell hyprpl inf",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51662",
                "title": "51662 Pulm interstitl glycogen",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51663",
                "title": "51663 Surfactant mutation lung",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51664",
                "title": "51664 Alv cap dysp w vn misaln",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "51669",
                "title": "51669 Oth intrst lung dis chld",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "5168",
                "title": "5168 Alveol pneumonopathy NEC",
                "threedigit": "516",
                "isLeaf": true
              },
              {
                "key": "5169",
                "title": "5169 Alveol pneumonopathy NOS",
                "threedigit": "516",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Lung involvement in conditions classified elsewhere",
            "title": "517 - Lung involvement in conditions classified elsewhere",
            "threedigit": "517",
            "children": [
              {
                "key": "5171",
                "title": "5171 Rheumatic pneumonia",
                "threedigit": "517",
                "isLeaf": true
              },
              {
                "key": "5172",
                "title": "5172 Syst sclerosis lung dis",
                "threedigit": "517",
                "isLeaf": true
              },
              {
                "key": "5173",
                "title": "5173 Acute chest syndrome",
                "threedigit": "517",
                "isLeaf": true
              },
              {
                "key": "5178",
                "title": "5178 Lung involv in oth dis",
                "threedigit": "517",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of lung",
            "title": "518 - Other diseases of lung",
            "threedigit": "518",
            "children": [
              {
                "key": "5180",
                "title": "5180 Pulmonary collapse",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "5181",
                "title": "5181 Interstitial emphysema",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "5182",
                "title": "5182 Compensatory emphysema",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "5183",
                "title": "5183 Pulmonary eosinophilia",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "5184",
                "title": "5184 Acute lung edema NOS",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "51851",
                "title": "51851 Ac resp flr fol trma/srg",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "51852",
                "title": "51852 Ot pul insuf fol trm/srg",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "51853",
                "title": "51853 Ac/chr rsp flr fol tr/sg",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "5186",
                "title": "5186 Alrgc brncpul asprglosis",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "5187",
                "title": "5187 Transfsn rel ac lung inj",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "51881",
                "title": "51881 Acute respiratry failure",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "51882",
                "title": "51882 Other pulmonary insuff",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "51883",
                "title": "51883 Chronic respiratory fail",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "51884",
                "title": "51884 Acute & chronc resp fail",
                "threedigit": "518",
                "isLeaf": true
              },
              {
                "key": "51889",
                "title": "51889 Other lung disease NEC",
                "threedigit": "518",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of respiratory system",
            "title": "519 - Other diseases of respiratory system",
            "threedigit": "519",
            "children": [
              {
                "key": "51900",
                "title": "51900 Tracheostomy comp NOS",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "51901",
                "title": "51901 Tracheostomy infection",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "51902",
                "title": "51902 Tracheostomy - mech comp",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "51909",
                "title": "51909 Tracheostomy comp NEC",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "51911",
                "title": "51911 Acute bronchospasm",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "51919",
                "title": "51919 Trachea & bronch dis NEC",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "5192",
                "title": "5192 Mediastinitis",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "5193",
                "title": "5193 Mediastinum disease NEC",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "5194",
                "title": "5194 Disorders of diaphragm",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "5198",
                "title": "5198 Resp system disease NEC",
                "threedigit": "519",
                "isLeaf": true
              },
              {
                "key": "5199",
                "title": "5199 Resp system disease NOS",
                "threedigit": "519",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Diseases Of The Digestive System",
    "title": "520-579 Diseases Of The Digestive System",
    "threedigit": "520",
    "children": [
      {
        "key": "Diseases Of Oral Cavity, Salivary Glands, And Jaws",
        "title": "520-529 Diseases Of Oral Cavity, Salivary Glands, And Jaws",
        "threedigit": "520",
        "children": [
          {
            "key": "Disorders of tooth development and eruption",
            "title": "520 - Disorders of tooth development and eruption",
            "threedigit": "520",
            "children": [
              {
                "key": "5200",
                "title": "5200 Anodontia",
                "threedigit": "520",
                "isLeaf": true
              },
              {
                "key": "5201",
                "title": "5201 Supernumerary teeth",
                "threedigit": "520",
                "isLeaf": true
              },
              {
                "key": "5202",
                "title": "5202 Abnormal tooth size/form",
                "threedigit": "520",
                "isLeaf": true
              },
              {
                "key": "5203",
                "title": "5203 Mottled teeth",
                "threedigit": "520",
                "isLeaf": true
              },
              {
                "key": "5204",
                "title": "5204 Tooth formation disturb",
                "threedigit": "520",
                "isLeaf": true
              },
              {
                "key": "5205",
                "title": "5205 Heredit tooth struct NEC",
                "threedigit": "520",
                "isLeaf": true
              },
              {
                "key": "5206",
                "title": "5206 Tooth eruption disturb",
                "threedigit": "520",
                "isLeaf": true
              },
              {
                "key": "5207",
                "title": "5207 Teething syndrome",
                "threedigit": "520",
                "isLeaf": true
              },
              {
                "key": "5208",
                "title": "5208 Tooth devel/erup dis NEC",
                "threedigit": "520",
                "isLeaf": true
              },
              {
                "key": "5209",
                "title": "5209 Tooth devel/erup dis NOS",
                "threedigit": "520",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of hard tissues of teeth",
            "title": "521 - Diseases of hard tissues of teeth",
            "threedigit": "521",
            "children": [
              {
                "key": "52100",
                "title": "52100 Dental caries NOS",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52101",
                "title": "52101 Dental caries - enamel",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52102",
                "title": "52102 Dental caries - dentine",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52103",
                "title": "52103 Dental caries - pulp",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52104",
                "title": "52104 Dental caries - arrested",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52105",
                "title": "52105 Odontoclasia",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52106",
                "title": "52106 Dentl caries-pit/fissure",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52107",
                "title": "52107 Dentl caries-smooth surf",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52108",
                "title": "52108 Dental caries-root surf",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52109",
                "title": "52109 Dental caries NEC",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52110",
                "title": "52110 Excessive attrition NOS",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52111",
                "title": "52111 Excess attrition-enamel",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52112",
                "title": "52112 Excess attrition-dentine",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52113",
                "title": "52113 Excessive attrition-pulp",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52114",
                "title": "52114 Excess attrition-local",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52115",
                "title": "52115 Excess attrition-general",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52120",
                "title": "52120 Abrasion NOS",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52121",
                "title": "52121 Abrasion-enamel",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52122",
                "title": "52122 Abrasion-dentine",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52123",
                "title": "52123 Abrasion-pulp",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52124",
                "title": "52124 Abrasion-localized",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52125",
                "title": "52125 Abrasion-generalized",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52130",
                "title": "52130 Erosion NOS",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52131",
                "title": "52131 Erosion-enamel",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52132",
                "title": "52132 Erosion-dentine",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52133",
                "title": "52133 Erosion-pulp",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52134",
                "title": "52134 Erosion-localized",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52135",
                "title": "52135 Erosion-generalized",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52140",
                "title": "52140 Path resorption NOS",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52141",
                "title": "52141 Path resorption-internal",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52142",
                "title": "52142 Path resorption-external",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52149",
                "title": "52149 Path resorption NEC",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "5215",
                "title": "5215 Hypercementosis",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "5216",
                "title": "5216 Ankylosis of teeth",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "5217",
                "title": "5217 Intrin posteruptv color",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52181",
                "title": "52181 Cracked tooth",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "52189",
                "title": "52189 Dis hard tiss teeth NEC",
                "threedigit": "521",
                "isLeaf": true
              },
              {
                "key": "5219",
                "title": "5219 Hard tiss dis teeth NOS",
                "threedigit": "521",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of pulp and periapical tissues",
            "title": "522 - Diseases of pulp and periapical tissues",
            "threedigit": "522",
            "children": [
              {
                "key": "5220",
                "title": "5220 Pulpitis",
                "threedigit": "522",
                "isLeaf": true
              },
              {
                "key": "5221",
                "title": "5221 Necrosis of tooth pulp",
                "threedigit": "522",
                "isLeaf": true
              },
              {
                "key": "5222",
                "title": "5222 Tooth pulp degeneration",
                "threedigit": "522",
                "isLeaf": true
              },
              {
                "key": "5223",
                "title": "5223 Abn hard tiss-tooth pulp",
                "threedigit": "522",
                "isLeaf": true
              },
              {
                "key": "5224",
                "title": "5224 Ac apical periodontitis",
                "threedigit": "522",
                "isLeaf": true
              },
              {
                "key": "5225",
                "title": "5225 Periapical abscess",
                "threedigit": "522",
                "isLeaf": true
              },
              {
                "key": "5226",
                "title": "5226 Chr apical periodontitis",
                "threedigit": "522",
                "isLeaf": true
              },
              {
                "key": "5227",
                "title": "5227 Periapical absc w sinus",
                "threedigit": "522",
                "isLeaf": true
              },
              {
                "key": "5228",
                "title": "5228 Radicular cyst",
                "threedigit": "522",
                "isLeaf": true
              },
              {
                "key": "5229",
                "title": "5229 Pulp/periapical dis NEC",
                "threedigit": "522",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Gingival and periodontal diseases",
            "title": "523 - Gingival and periodontal diseases",
            "threedigit": "523",
            "children": [
              {
                "key": "52300",
                "title": "52300 Acute gingititis, plaque",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52301",
                "title": "52301 Ac gingivitis,nonplaque",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52310",
                "title": "52310 Chronc gingititis,plaque",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52311",
                "title": "52311 Chr gingivitis-nonplaque",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52320",
                "title": "52320 Gingival recession NOS",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52321",
                "title": "52321 Gingival recess-minimal",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52322",
                "title": "52322 Gingival recess-moderate",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52323",
                "title": "52323 Gingival recess-severe",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52324",
                "title": "52324 Gingival recession-local",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52325",
                "title": "52325 Gingival recess-general",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52330",
                "title": "52330 Aggres periodontitis NOS",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52331",
                "title": "52331 Aggres periodontitis,loc",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52332",
                "title": "52332 Aggres periodontitis,gen",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52333",
                "title": "52333 Acute periodontitis",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52340",
                "title": "52340 Chronc periodontitis NOS",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52341",
                "title": "52341 Chr periodontitis, local",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "52342",
                "title": "52342 Chron periodontitis,gen",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "5235",
                "title": "5235 Periodontosis",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "5236",
                "title": "5236 Accretions on teeth",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "5238",
                "title": "5238 Periodontal disease NEC",
                "threedigit": "523",
                "isLeaf": true
              },
              {
                "key": "5239",
                "title": "5239 Gingiv/periodont dis NOS",
                "threedigit": "523",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dentofacial anomalies, including malocclusion",
            "title": "524 - Dentofacial anomalies, including malocclusion",
            "threedigit": "524",
            "children": [
              {
                "key": "52400",
                "title": "52400 Unspcf anomaly jaw size",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52401",
                "title": "52401 Maxillary hyperplasia",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52402",
                "title": "52402 Mandibular hyperplasia",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52403",
                "title": "52403 Maxillary hypoplasia",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52404",
                "title": "52404 Mandibular hypoplasia",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52405",
                "title": "52405 Macrogenia",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52406",
                "title": "52406 Microgenia",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52407",
                "title": "52407 Excessive tuberosity-jaw",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52409",
                "title": "52409 Oth spcf anmly jaw size",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52410",
                "title": "52410 Unspcf anm jaw cranl bse",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52411",
                "title": "52411 Maxillary asymmetry",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52412",
                "title": "52412 Other jaw asymmetry",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52419",
                "title": "52419 Spcfd anom jaw cranl bse",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52420",
                "title": "52420 Anomaly dental arch NOS",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52421",
                "title": "52421 Malocc- Angle's class I",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52422",
                "title": "52422 Malocc-Angle's class II",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52423",
                "title": "52423 Malocc-Angle's class III",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52424",
                "title": "52424 Open anterior occlusion",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52425",
                "title": "52425 Open posterior occlusion",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52426",
                "title": "52426 Excess horizontl overlap",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52427",
                "title": "52427 Reverse articulation",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52428",
                "title": "52428 Anom interarch distance",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52429",
                "title": "52429 Anomaly dental arch NEC",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52430",
                "title": "52430 Tooth position anom NOS",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52431",
                "title": "52431 Crowding of teeth",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52432",
                "title": "52432 Excessive spacing-teeth",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52433",
                "title": "52433 Horizontl displace-teeth",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52434",
                "title": "52434 Vertical displace-teeth",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52435",
                "title": "52435 Rotation of teeth",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52436",
                "title": "52436 Insuf interocclusl-teeth",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52437",
                "title": "52437 Exces interocclusl-teeth",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52439",
                "title": "52439 Tooth position anom NEC",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "5244",
                "title": "5244 Malocclusion NOS",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52450",
                "title": "52450 Dentofac funct abnor NOS",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52451",
                "title": "52451 Abnormal jaw closure",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52452",
                "title": "52452 Limited mandibular ROM",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52453",
                "title": "52453 Dev open/close mandible",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52454",
                "title": "52454 Insuff anterior guidance",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52455",
                "title": "52455 Centric occl intrcsp dis",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52456",
                "title": "52456 Nonwork side interfrnce",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52457",
                "title": "52457 Lack post occlsl support",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52459",
                "title": "52459 Dentofac funct abnor NEC",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52460",
                "title": "52460 TMJ disorders NOS",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52461",
                "title": "52461 Adhesns/ankylosis - TMJ",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52462",
                "title": "52462 Arthralgia TMJ",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52463",
                "title": "52463 Articular disc disorder",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52464",
                "title": "52464 TMJ sounds opn/close jaw",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52469",
                "title": "52469 Other specf TMJ disordrs",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52470",
                "title": "52470 Unspf dent alvelr anmaly",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52471",
                "title": "52471 Alveolar maxil hyprplsia",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52472",
                "title": "52472 Alveolar mandib hyprplas",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52473",
                "title": "52473 Alveolar maxil hypoplsia",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52474",
                "title": "52474 Alveolar mandb hypoplsia",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52475",
                "title": "52475 Vertical displace teeth",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52476",
                "title": "52476 Occlusal plane deviation",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52479",
                "title": "52479 Oth spcf alveolar anmaly",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52481",
                "title": "52481 Anterior soft tiss impg",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52482",
                "title": "52482 Posterior soft tiss impg",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "52489",
                "title": "52489 Dentofacial anomaly NEC",
                "threedigit": "524",
                "isLeaf": true
              },
              {
                "key": "5249",
                "title": "5249 Dentofacial anomaly NOS",
                "threedigit": "524",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases and conditions of the teeth and supporting structures",
            "title": "525 - Other diseases and conditions of the teeth and supporting structures",
            "threedigit": "525",
            "children": [
              {
                "key": "5250",
                "title": "5250 Exfoliation of teeth",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52510",
                "title": "52510 Acq absence of teeth NOS",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52511",
                "title": "52511 Loss of teeth d/t trauma",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52512",
                "title": "52512 Loss teeth d/t peri dis",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52513",
                "title": "52513 Loss of teeth d/t caries",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52519",
                "title": "52519 Loss of teeth NEC",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52520",
                "title": "52520 Atrophy alvlar ridge NOS",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52521",
                "title": "52521 Atrophy mandible-minimal",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52522",
                "title": "52522 Atrophy mandible-modrate",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52523",
                "title": "52523 Atrophy mandible-severe",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52524",
                "title": "52524 Atrophy maxilla-minimal",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52525",
                "title": "52525 Atrophy maxilla-moderate",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52526",
                "title": "52526 Atrophy maxilla-severe",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "5253",
                "title": "5253 Retained dental root",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52540",
                "title": "52540 Complete edentulism NOS",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52541",
                "title": "52541 Comp edentulism,class I",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52542",
                "title": "52542 Comp edentulism,class II",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52543",
                "title": "52543 Comp edentulsm,class III",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52544",
                "title": "52544 Comp edentulism,class IV",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52550",
                "title": "52550 Partial edentulism NOS",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52551",
                "title": "52551 Part edentulism,class I",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52552",
                "title": "52552 Part edentulism,class II",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52553",
                "title": "52553 Part edentulsm,class III",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52554",
                "title": "52554 Part edentulism,class IV",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52560",
                "title": "52560 Unsat restore tooth NOS",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52561",
                "title": "52561 Open restoration margins",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52562",
                "title": "52562 Overhang dental restore",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52563",
                "title": "52563 Fx dental mat w/o loss",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52564",
                "title": "52564 Fx dentl material w loss",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52565",
                "title": "52565 Contour restore tooth",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52566",
                "title": "52566 Allergy dental res mat",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52567",
                "title": "52567 Poor aesthetics restore",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52569",
                "title": "52569 Unsat restore tooth NEC",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52571",
                "title": "52571 Osseo fail dental implnt",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52572",
                "title": "52572 Post-osse biol fail impl",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52573",
                "title": "52573 Post-osse mech fail impl",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "52579",
                "title": "52579 Endos dentl imp fail NEC",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "5258",
                "title": "5258 Dental disorder NEC",
                "threedigit": "525",
                "isLeaf": true
              },
              {
                "key": "5259",
                "title": "5259 Dental disorder NOS",
                "threedigit": "525",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of the jaws",
            "title": "526 - Diseases of the jaws",
            "threedigit": "526",
            "children": [
              {
                "key": "5260",
                "title": "5260 Devel odontogenic cysts",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "5261",
                "title": "5261 Fissural cysts of jaw",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "5262",
                "title": "5262 Cysts of jaws NEC",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "5263",
                "title": "5263 Cent giant cell granulom",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "5264",
                "title": "5264 Inflammation of jaw",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "5265",
                "title": "5265 Alveolitis of jaw",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "52661",
                "title": "52661 Perfor root canal space",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "52662",
                "title": "52662 Endodontic overfill",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "52663",
                "title": "52663 Endodontic underfill",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "52669",
                "title": "52669 Periradicular path NEC",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "52681",
                "title": "52681 Exostosis of jaw",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "52689",
                "title": "52689 Jaw disease NEC",
                "threedigit": "526",
                "isLeaf": true
              },
              {
                "key": "5269",
                "title": "5269 Jaw disease NOS",
                "threedigit": "526",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of the salivary glands",
            "title": "527 - Diseases of the salivary glands",
            "threedigit": "527",
            "children": [
              {
                "key": "5270",
                "title": "5270 Salivary gland atrophy",
                "threedigit": "527",
                "isLeaf": true
              },
              {
                "key": "5271",
                "title": "5271 Salivary glnd hyprtrophy",
                "threedigit": "527",
                "isLeaf": true
              },
              {
                "key": "5272",
                "title": "5272 Sialoadenitis",
                "threedigit": "527",
                "isLeaf": true
              },
              {
                "key": "5273",
                "title": "5273 Salivary gland abscess",
                "threedigit": "527",
                "isLeaf": true
              },
              {
                "key": "5274",
                "title": "5274 Salivary gland fistula",
                "threedigit": "527",
                "isLeaf": true
              },
              {
                "key": "5275",
                "title": "5275 Sialolithiasis",
                "threedigit": "527",
                "isLeaf": true
              },
              {
                "key": "5276",
                "title": "5276 Salivary gland mucocele",
                "threedigit": "527",
                "isLeaf": true
              },
              {
                "key": "5277",
                "title": "5277 Salivary secretion dis",
                "threedigit": "527",
                "isLeaf": true
              },
              {
                "key": "5278",
                "title": "5278 Salivary gland dis NEC",
                "threedigit": "527",
                "isLeaf": true
              },
              {
                "key": "5279",
                "title": "5279 Salivary gland dis NOS",
                "threedigit": "527",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of the oral soft tissues excluding lesions specific for gingiva and tongue",
            "title": "528 - Diseases of the oral soft tissues excluding lesions specific for gingiva and tongue",
            "threedigit": "528",
            "children": [
              {
                "key": "52800",
                "title": "52800 Stomatitis/mucositis NOS",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "52801",
                "title": "52801 Mucosits d/t antineo rx",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "52802",
                "title": "52802 Mucositis d/t drugs NEC",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "52809",
                "title": "52809 Stomatits & mucosits NEC",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "5281",
                "title": "5281 Cancrum oris",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "5282",
                "title": "5282 Oral aphthae",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "5283",
                "title": "5283 Cellulitis/abscess mouth",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "5284",
                "title": "5284 Oral soft tissue cyst",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "5285",
                "title": "5285 Diseases of lips",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "5286",
                "title": "5286 Leukoplakia oral mucosa",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "52871",
                "title": "52871 Keratin ridge mucosa-min",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "52872",
                "title": "52872 Keratin ridge muc-excess",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "52879",
                "title": "52879 Dist oral epithelium NEC",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "5288",
                "title": "5288 Oral submucosal fibrosis",
                "threedigit": "528",
                "isLeaf": true
              },
              {
                "key": "5289",
                "title": "5289 Oral soft tissue dis NEC",
                "threedigit": "528",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases and other conditions of the tongue",
            "title": "529 - Diseases and other conditions of the tongue",
            "threedigit": "529",
            "children": [
              {
                "key": "5290",
                "title": "5290 Glossitis",
                "threedigit": "529",
                "isLeaf": true
              },
              {
                "key": "5291",
                "title": "5291 Geographic tongue",
                "threedigit": "529",
                "isLeaf": true
              },
              {
                "key": "5292",
                "title": "5292 Med rhomboid glossitis",
                "threedigit": "529",
                "isLeaf": true
              },
              {
                "key": "5293",
                "title": "5293 Hypertroph tongue papill",
                "threedigit": "529",
                "isLeaf": true
              },
              {
                "key": "5294",
                "title": "5294 Atrophy tongue papillae",
                "threedigit": "529",
                "isLeaf": true
              },
              {
                "key": "5295",
                "title": "5295 Plicated tongue",
                "threedigit": "529",
                "isLeaf": true
              },
              {
                "key": "5296",
                "title": "5296 Glossodynia",
                "threedigit": "529",
                "isLeaf": true
              },
              {
                "key": "5298",
                "title": "5298 Tongue disorder NEC",
                "threedigit": "529",
                "isLeaf": true
              },
              {
                "key": "5299",
                "title": "5299 Tongue disorder NOS",
                "threedigit": "529",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Diseases Of Esophagus, Stomach, And Duodenum",
        "title": "530-539 Diseases Of Esophagus, Stomach, And Duodenum",
        "threedigit": "530",
        "children": [
          {
            "key": "Diseases of esophagus",
            "title": "530 - Diseases of esophagus",
            "threedigit": "530",
            "children": [
              {
                "key": "5300",
                "title": "5300 Achalasia & cardiospasm",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53010",
                "title": "53010 Esophagitis, unspecified",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53011",
                "title": "53011 Reflux esophagitis",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53012",
                "title": "53012 Acute esophagitis",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53013",
                "title": "53013 Eosinophilic esophagitis",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53019",
                "title": "53019 Other esophagitis",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53020",
                "title": "53020 Ulc esophagus w/o bleed",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53021",
                "title": "53021 Ulcer esophagus w bleed",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "5303",
                "title": "5303 Esophageal stricture",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "5304",
                "title": "5304 Perforation of esophagus",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "5305",
                "title": "5305 Dyskinesia of esophagus",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "5306",
                "title": "5306 Acq esophag diverticulum",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "5307",
                "title": "5307 Mallory-weiss syndrome",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53081",
                "title": "53081 Esophageal reflux",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53082",
                "title": "53082 Esophageal hemorrhage",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53083",
                "title": "53083 Esophageal leukoplakia",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53084",
                "title": "53084 Tracheoesophageal fstula",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53085",
                "title": "53085 Barrett's esophagus",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53086",
                "title": "53086 Esophagostomy infection",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53087",
                "title": "53087 Mech comp esophagostomy",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "53089",
                "title": "53089 Other dsrders esophagus",
                "threedigit": "530",
                "isLeaf": true
              },
              {
                "key": "5309",
                "title": "5309 Esophageal disorder NOS",
                "threedigit": "530",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Gastric ulcer",
            "title": "531 - Gastric ulcer",
            "threedigit": "531",
            "children": [
              {
                "key": "53100",
                "title": "53100 Ac stomach ulcer w hem",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53101",
                "title": "53101 Ac stomac ulc w hem-obst",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53110",
                "title": "53110 Ac stomach ulcer w perf",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53111",
                "title": "53111 Ac stom ulc w perf-obst",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53120",
                "title": "53120 Ac stomac ulc w hem/perf",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53121",
                "title": "53121 Ac stom ulc hem/perf-obs",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53130",
                "title": "53130 Acute stomach ulcer NOS",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53131",
                "title": "53131 Ac stomach ulc NOS-obstr",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53140",
                "title": "53140 Chr stomach ulc w hem",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53141",
                "title": "53141 Chr stom ulc w hem-obstr",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53150",
                "title": "53150 Chr stomach ulcer w perf",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53151",
                "title": "53151 Chr stom ulc w perf-obst",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53160",
                "title": "53160 Chr stomach ulc hem/perf",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53161",
                "title": "53161 Chr stom ulc hem/perf-ob",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53170",
                "title": "53170 Chr stomach ulcer NOS",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53171",
                "title": "53171 Chr stomach ulc NOS-obst",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53190",
                "title": "53190 Stomach ulcer NOS",
                "threedigit": "531",
                "isLeaf": true
              },
              {
                "key": "53191",
                "title": "53191 Stomach ulcer NOS-obstr",
                "threedigit": "531",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Duodenal ulcer",
            "title": "532 - Duodenal ulcer",
            "threedigit": "532",
            "children": [
              {
                "key": "53200",
                "title": "53200 Ac duodenal ulcer w hem",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53201",
                "title": "53201 Ac duoden ulc w hem-obst",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53210",
                "title": "53210 Ac duodenal ulcer w perf",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53211",
                "title": "53211 Ac duoden ulc perf-obstr",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53220",
                "title": "53220 Ac duoden ulc w hem/perf",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53221",
                "title": "53221 Ac duod ulc hem/perf-obs",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53230",
                "title": "53230 Acute duodenal ulcer NOS",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53231",
                "title": "53231 Ac duodenal ulc NOS-obst",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53240",
                "title": "53240 Chr duoden ulcer w hem",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53241",
                "title": "53241 Chr duoden ulc hem-obstr",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53250",
                "title": "53250 Chr duoden ulcer w perf",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53251",
                "title": "53251 Chr duoden ulc perf-obst",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53260",
                "title": "53260 Chr duoden ulc hem/perf",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53261",
                "title": "53261 Chr duod ulc hem/perf-ob",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53270",
                "title": "53270 Chr duodenal ulcer NOS",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53271",
                "title": "53271 Chr duoden ulc NOS-obstr",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53290",
                "title": "53290 Duodenal ulcer NOS",
                "threedigit": "532",
                "isLeaf": true
              },
              {
                "key": "53291",
                "title": "53291 Duodenal ulcer NOS-obstr",
                "threedigit": "532",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Peptic ulcer site unspecified",
            "title": "533 - Peptic ulcer site unspecified",
            "threedigit": "533",
            "children": [
              {
                "key": "53300",
                "title": "53300 Ac peptic ulcer w hemorr",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53301",
                "title": "53301 Ac peptic ulc w hem-obst",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53310",
                "title": "53310 Ac peptic ulcer w perfor",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53311",
                "title": "53311 Ac peptic ulc w perf-obs",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53320",
                "title": "53320 Ac peptic ulc w hem/perf",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53321",
                "title": "53321 Ac pept ulc hem/perf-obs",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53330",
                "title": "53330 Acute peptic ulcer NOS",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53331",
                "title": "53331 Ac peptic ulcer NOS-obst",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53340",
                "title": "53340 Chr peptic ulcer w hem",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53341",
                "title": "53341 Chr peptic ulc w hem-obs",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53350",
                "title": "53350 Chr peptic ulcer w perf",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53351",
                "title": "53351 Chr peptic ulc perf-obst",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53360",
                "title": "53360 Chr pept ulc w hem/perf",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53361",
                "title": "53361 Chr pept ulc hem/perf-ob",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53370",
                "title": "53370 Chronic peptic ulcer NOS",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53371",
                "title": "53371 Chr peptic ulcer NOS-obs",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53390",
                "title": "53390 Peptic ulcer NOS",
                "threedigit": "533",
                "isLeaf": true
              },
              {
                "key": "53391",
                "title": "53391 Peptic ulcer NOS-obstruc",
                "threedigit": "533",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Gastrojejunal ulcer",
            "title": "534 - Gastrojejunal ulcer",
            "threedigit": "534",
            "children": [
              {
                "key": "53400",
                "title": "53400 Ac marginal ulcer w hem",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53401",
                "title": "53401 Ac margin ulc w hem-obst",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53410",
                "title": "53410 Ac marginal ulcer w perf",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53411",
                "title": "53411 Ac margin ulc w perf-obs",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53420",
                "title": "53420 Ac margin ulc w hem/perf",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53421",
                "title": "53421 Ac marg ulc hem/perf-obs",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53430",
                "title": "53430 Ac marginal ulcer NOS",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53431",
                "title": "53431 Ac marginal ulc NOS-obst",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53440",
                "title": "53440 Chr marginal ulcer w hem",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53441",
                "title": "53441 Chr margin ulc w hem-obs",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53450",
                "title": "53450 Chr marginal ulc w perf",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53451",
                "title": "53451 Chr margin ulc perf-obst",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53460",
                "title": "53460 Chr margin ulc hem/perf",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53461",
                "title": "53461 Chr marg ulc hem/perf-ob",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53470",
                "title": "53470 Chr marginal ulcer NOS",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53471",
                "title": "53471 Chr marginal ulc NOS-obs",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53490",
                "title": "53490 Gastrojejunal ulcer NOS",
                "threedigit": "534",
                "isLeaf": true
              },
              {
                "key": "53491",
                "title": "53491 Gastrojejun ulc NOS-obst",
                "threedigit": "534",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Gastritis and duodenitis",
            "title": "535 - Gastritis and duodenitis",
            "threedigit": "535",
            "children": [
              {
                "key": "53500",
                "title": "53500 Acute gastrtis w/o hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53501",
                "title": "53501 Acute gastritis w hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53510",
                "title": "53510 Atrph gastrtis w/o hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53511",
                "title": "53511 Atrph gastritis w hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53520",
                "title": "53520 Gstr mcsl hyprt w/o hmrg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53521",
                "title": "53521 Gstr mcsl hyprt w hmrg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53530",
                "title": "53530 Alchl gastrtis w/o hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53531",
                "title": "53531 Alchl gstritis w hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53540",
                "title": "53540 Oth spf gstrt w/o hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53541",
                "title": "53541 Oth spf gastrt w hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53550",
                "title": "53550 Gstr/ddnts NOS w/o hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53551",
                "title": "53551 Gstr/ddnts NOS w hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53560",
                "title": "53560 Duodenitis w/o hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53561",
                "title": "53561 Duodenitis w hmrhg",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53570",
                "title": "53570 Eosinophil gastrt wo hem",
                "threedigit": "535",
                "isLeaf": true
              },
              {
                "key": "53571",
                "title": "53571 Eosinophilc gastrt w hem",
                "threedigit": "535",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of function of stomach",
            "title": "536 - Disorders of function of stomach",
            "threedigit": "536",
            "children": [
              {
                "key": "5360",
                "title": "5360 Achlorhydria",
                "threedigit": "536",
                "isLeaf": true
              },
              {
                "key": "5361",
                "title": "5361 Ac dilation of stomach",
                "threedigit": "536",
                "isLeaf": true
              },
              {
                "key": "5362",
                "title": "5362 Persistent vomiting",
                "threedigit": "536",
                "isLeaf": true
              },
              {
                "key": "5363",
                "title": "5363 Gastroparesis",
                "threedigit": "536",
                "isLeaf": true
              },
              {
                "key": "53640",
                "title": "53640 Gastrostomy comp NOS",
                "threedigit": "536",
                "isLeaf": true
              },
              {
                "key": "53641",
                "title": "53641 Gastrostomy infection",
                "threedigit": "536",
                "isLeaf": true
              },
              {
                "key": "53642",
                "title": "53642 Gastrostomy comp - mech",
                "threedigit": "536",
                "isLeaf": true
              },
              {
                "key": "53649",
                "title": "53649 Gastrostomy comp NEC",
                "threedigit": "536",
                "isLeaf": true
              },
              {
                "key": "5368",
                "title": "5368 Stomach function dis NEC",
                "threedigit": "536",
                "isLeaf": true
              },
              {
                "key": "5369",
                "title": "5369 Stomach function dis NOS",
                "threedigit": "536",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of stomach and duodenum",
            "title": "537 - Other disorders of stomach and duodenum",
            "threedigit": "537",
            "children": [
              {
                "key": "5370",
                "title": "5370 Acq pyloric stenosis",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "5371",
                "title": "5371 Gastric diverticulum",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "5372",
                "title": "5372 Chronic duodenal ileus",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "5373",
                "title": "5373 Duodenal obstruction NEC",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "5374",
                "title": "5374 Gastric/duodenal fistula",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "5375",
                "title": "5375 Gastroptosis",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "5376",
                "title": "5376 Hourglass stricture stom",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "53781",
                "title": "53781 Pylorospasm",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "53782",
                "title": "53782 Angio stm/dudn w/o hmrhg",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "53783",
                "title": "53783 Angio stm/dudn w hmrhg",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "53784",
                "title": "53784 Dieulafoy les,stom&duod",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "53789",
                "title": "53789 Gastroduodenal dis NEC",
                "threedigit": "537",
                "isLeaf": true
              },
              {
                "key": "5379",
                "title": "5379 Gastroduodenal dis NOS",
                "threedigit": "537",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Gastrointestinal mucositis (ulcerative)",
            "title": "538 - Gastrointestinal mucositis (ulcerative)",
            "threedigit": "538",
            "children": [
              {
                "key": "538",
                "title": "538 GI mucositis (ulceratve)",
                "threedigit": "538",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Complications of bariatric procedures",
            "title": "539 - Complications of bariatric procedures",
            "threedigit": "539",
            "children": [
              {
                "key": "53901",
                "title": "53901 Inf d/t gastrc band proc",
                "threedigit": "539",
                "isLeaf": true
              },
              {
                "key": "53909",
                "title": "53909 Oth cmp gastrc band proc",
                "threedigit": "539",
                "isLeaf": true
              },
              {
                "key": "53981",
                "title": "53981 Inf d/t ot bariatrc proc",
                "threedigit": "539",
                "isLeaf": true
              },
              {
                "key": "53989",
                "title": "53989 Ot comp ot bariatrc proc",
                "threedigit": "539",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Appendicitis",
        "title": "540-543 Appendicitis",
        "threedigit": "540",
        "children": [
          {
            "key": "Acute appendicitis",
            "title": "540 - Acute appendicitis",
            "threedigit": "540",
            "children": [
              {
                "key": "5400",
                "title": "5400 Ac append w peritonitis",
                "threedigit": "540",
                "isLeaf": true
              },
              {
                "key": "5401",
                "title": "5401 Abscess of appendix",
                "threedigit": "540",
                "isLeaf": true
              },
              {
                "key": "5409",
                "title": "5409 Acute appendicitis NOS",
                "threedigit": "540",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Appendicitis, unqualified",
            "title": "541 - Appendicitis, unqualified",
            "threedigit": "541",
            "children": [
              {
                "key": "541",
                "title": "541 Appendicitis NOS",
                "threedigit": "541",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other appendicitis",
            "title": "542 - Other appendicitis",
            "threedigit": "542",
            "children": [
              {
                "key": "542",
                "title": "542 Other appendicitis",
                "threedigit": "542",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other diseases of appendix",
            "title": "543 - Other diseases of appendix",
            "threedigit": "543",
            "children": [
              {
                "key": "5430",
                "title": "5430 Hyperplasia of appendix",
                "threedigit": "543",
                "isLeaf": true
              },
              {
                "key": "5439",
                "title": "5439 Diseases of appendix NEC",
                "threedigit": "543",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Hernia Of Abdominal Cavity",
        "title": "550-553 Hernia Of Abdominal Cavity",
        "threedigit": "550",
        "children": [
          {
            "key": "Inguinal hernia",
            "title": "550 - Inguinal hernia",
            "threedigit": "550",
            "children": [
              {
                "key": "55000",
                "title": "55000 Unilat ing hernia w gang",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55001",
                "title": "55001 Recur unil ing hern-gang",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55002",
                "title": "55002 Bilat ing hernia w gang",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55003",
                "title": "55003 Recur bil ing hern-gang",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55010",
                "title": "55010 Unilat ing hernia w obst",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55011",
                "title": "55011 Recur unil ing hern-obst",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55012",
                "title": "55012 Bilat ing hernia w obst",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55013",
                "title": "55013 Recur bil ing hern-obstr",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55090",
                "title": "55090 Unilat inguinal hernia",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55091",
                "title": "55091 Recur unilat inguin hern",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55092",
                "title": "55092 Bilat inguinal hernia",
                "threedigit": "550",
                "isLeaf": true
              },
              {
                "key": "55093",
                "title": "55093 Recur bilat inguin hern",
                "threedigit": "550",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other hernia of abdominal cavity with gangrene",
            "title": "551 - Other hernia of abdominal cavity with gangrene",
            "threedigit": "551",
            "children": [
              {
                "key": "55100",
                "title": "55100 Unil femoral hern w gang",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "55101",
                "title": "55101 Rec unil fem hern w gang",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "55102",
                "title": "55102 Bilat fem hern w gang",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "55103",
                "title": "55103 Recur bil fem hern-gang",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "5511",
                "title": "5511 Umbilical hernia w gangr",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "55120",
                "title": "55120 Gangr ventral hernia NOS",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "55121",
                "title": "55121 Gangr incisional hernia",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "55129",
                "title": "55129 Gang ventral hernia NEC",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "5513",
                "title": "5513 Diaphragm hernia w gangr",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "5518",
                "title": "5518 Hernia, site NEC w gangr",
                "threedigit": "551",
                "isLeaf": true
              },
              {
                "key": "5519",
                "title": "5519 Hernia, site NOS w gangr",
                "threedigit": "551",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other hernia of abdominal cavity with obstruction but without mention of gangrene",
            "title": "552 - Other hernia of abdominal cavity with obstruction but without mention of gangrene",
            "threedigit": "552",
            "children": [
              {
                "key": "55200",
                "title": "55200 Unil femoral hern w obst",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "55201",
                "title": "55201 Rec unil fem hern w obst",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "55202",
                "title": "55202 Bil femoral hern w obstr",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "55203",
                "title": "55203 Rec bil fem hern w obstr",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "5521",
                "title": "5521 Umbilical hernia w obstr",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "55220",
                "title": "55220 Obstr ventral hernia NOS",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "55221",
                "title": "55221 Obstr incisional hernia",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "55229",
                "title": "55229 Obstr ventral hernia NEC",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "5523",
                "title": "5523 Diaphragm hernia w obstr",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "5528",
                "title": "5528 Hernia, site NEC w obstr",
                "threedigit": "552",
                "isLeaf": true
              },
              {
                "key": "5529",
                "title": "5529 Hernia, site NOS w obstr",
                "threedigit": "552",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other hernia of abdominal cavity without mention of obstruction or gangrene",
            "title": "553 - Other hernia of abdominal cavity without mention of obstruction or gangrene",
            "threedigit": "553",
            "children": [
              {
                "key": "55300",
                "title": "55300 Unilat femoral hernia",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "55301",
                "title": "55301 Recur unil femoral hern",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "55302",
                "title": "55302 Bilateral femoral hernia",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "55303",
                "title": "55303 Recur bilat femoral hern",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "5531",
                "title": "5531 Umbilical hernia",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "55320",
                "title": "55320 Ventral hernia NOS",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "55321",
                "title": "55321 Incisional hernia",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "55329",
                "title": "55329 Ventral hernia NEC",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "5533",
                "title": "5533 Diaphragmatic hernia",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "5538",
                "title": "5538 Hernia NEC",
                "threedigit": "553",
                "isLeaf": true
              },
              {
                "key": "5539",
                "title": "5539 Hernia NOS",
                "threedigit": "553",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Noninfective Enteritis And Colitis",
        "title": "555-558 Noninfective Enteritis And Colitis",
        "threedigit": "555",
        "children": [
          {
            "key": "Regional enteritis",
            "title": "555 - Regional enteritis",
            "threedigit": "555",
            "children": [
              {
                "key": "5550",
                "title": "5550 Reg enteritis, sm intest",
                "threedigit": "555",
                "isLeaf": true
              },
              {
                "key": "5551",
                "title": "5551 Reg enteritis, lg intest",
                "threedigit": "555",
                "isLeaf": true
              },
              {
                "key": "5552",
                "title": "5552 Reg enterit sm/lg intest",
                "threedigit": "555",
                "isLeaf": true
              },
              {
                "key": "5559",
                "title": "5559 Regional enteritis NOS",
                "threedigit": "555",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ulcerative enterocolitis",
            "title": "556 - Ulcerative enterocolitis",
            "threedigit": "556",
            "children": [
              {
                "key": "5560",
                "title": "5560 Ulcerative enterocolitis",
                "threedigit": "556",
                "isLeaf": true
              },
              {
                "key": "5561",
                "title": "5561 Ulcerative ileocolitis",
                "threedigit": "556",
                "isLeaf": true
              },
              {
                "key": "5562",
                "title": "5562 Ulcerative proctitis",
                "threedigit": "556",
                "isLeaf": true
              },
              {
                "key": "5563",
                "title": "5563 Ulcertve prctosigmoidtis",
                "threedigit": "556",
                "isLeaf": true
              },
              {
                "key": "5564",
                "title": "5564 Pseudopolyposis colon",
                "threedigit": "556",
                "isLeaf": true
              },
              {
                "key": "5565",
                "title": "5565 Lftsded ulcertve colitis",
                "threedigit": "556",
                "isLeaf": true
              },
              {
                "key": "5566",
                "title": "5566 Univrsl ulcertve colitis",
                "threedigit": "556",
                "isLeaf": true
              },
              {
                "key": "5568",
                "title": "5568 Other ulcerative colitis",
                "threedigit": "556",
                "isLeaf": true
              },
              {
                "key": "5569",
                "title": "5569 Ulceratve colitis unspcf",
                "threedigit": "556",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Vascular insufficiency of intestine",
            "title": "557 - Vascular insufficiency of intestine",
            "threedigit": "557",
            "children": [
              {
                "key": "5570",
                "title": "5570 Ac vasc insuff intestine",
                "threedigit": "557",
                "isLeaf": true
              },
              {
                "key": "5571",
                "title": "5571 Chr vasc insuff intest",
                "threedigit": "557",
                "isLeaf": true
              },
              {
                "key": "5579",
                "title": "5579 Vasc insuff intest NOS",
                "threedigit": "557",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified noninfectious gastroenteritis and colitis",
            "title": "558 - Other and unspecified noninfectious gastroenteritis and colitis",
            "threedigit": "558",
            "children": [
              {
                "key": "5581",
                "title": "5581 Radiation gastroenterit",
                "threedigit": "558",
                "isLeaf": true
              },
              {
                "key": "5582",
                "title": "5582 Toxic gastroenteritis",
                "threedigit": "558",
                "isLeaf": true
              },
              {
                "key": "5583",
                "title": "5583 Allrgic gastro & colitis",
                "threedigit": "558",
                "isLeaf": true
              },
              {
                "key": "55841",
                "title": "55841 Eosinophilic gastroent",
                "threedigit": "558",
                "isLeaf": true
              },
              {
                "key": "55842",
                "title": "55842 Eosinophilic colitis",
                "threedigit": "558",
                "isLeaf": true
              },
              {
                "key": "5589",
                "title": "5589 Noninf gastroenterit NEC",
                "threedigit": "558",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Diseases Of Intestines And Peritoneum",
        "title": "560-569 Other Diseases Of Intestines And Peritoneum",
        "threedigit": "560",
        "children": [
          {
            "key": "Intestinal obstruction without mention of hernia",
            "title": "560 - Intestinal obstruction without mention of hernia",
            "threedigit": "560",
            "children": [
              {
                "key": "5600",
                "title": "5600 Intussusception",
                "threedigit": "560",
                "isLeaf": true
              },
              {
                "key": "5601",
                "title": "5601 Paralytic ileus",
                "threedigit": "560",
                "isLeaf": true
              },
              {
                "key": "5602",
                "title": "5602 Volvulus of intestine",
                "threedigit": "560",
                "isLeaf": true
              },
              {
                "key": "56030",
                "title": "56030 Impaction intestine NOS",
                "threedigit": "560",
                "isLeaf": true
              },
              {
                "key": "56031",
                "title": "56031 Gallstone ileus",
                "threedigit": "560",
                "isLeaf": true
              },
              {
                "key": "56032",
                "title": "56032 Fecal impaction",
                "threedigit": "560",
                "isLeaf": true
              },
              {
                "key": "56039",
                "title": "56039 Impaction intestine NEC",
                "threedigit": "560",
                "isLeaf": true
              },
              {
                "key": "56081",
                "title": "56081 Intestinal adhes w obstr",
                "threedigit": "560",
                "isLeaf": true
              },
              {
                "key": "56089",
                "title": "56089 Intestinal obstruct NEC",
                "threedigit": "560",
                "isLeaf": true
              },
              {
                "key": "5609",
                "title": "5609 Intestinal obstruct NOS",
                "threedigit": "560",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diverticula of intestine",
            "title": "562 - Diverticula of intestine",
            "threedigit": "562",
            "children": [
              {
                "key": "56200",
                "title": "56200 Dvrtclo sml int w/o hmrg",
                "threedigit": "562",
                "isLeaf": true
              },
              {
                "key": "56201",
                "title": "56201 Dvrtcli sml int w/o hmrg",
                "threedigit": "562",
                "isLeaf": true
              },
              {
                "key": "56202",
                "title": "56202 Dvrtclo sml int w hmrhg",
                "threedigit": "562",
                "isLeaf": true
              },
              {
                "key": "56203",
                "title": "56203 Dvrtcli sml int w hmrhg",
                "threedigit": "562",
                "isLeaf": true
              },
              {
                "key": "56210",
                "title": "56210 Dvrtclo colon w/o hmrhg",
                "threedigit": "562",
                "isLeaf": true
              },
              {
                "key": "56211",
                "title": "56211 Dvrtcli colon w/o hmrhg",
                "threedigit": "562",
                "isLeaf": true
              },
              {
                "key": "56212",
                "title": "56212 Dvrtclo colon w hmrhg",
                "threedigit": "562",
                "isLeaf": true
              },
              {
                "key": "56213",
                "title": "56213 Dvrtcli colon w hmrhg",
                "threedigit": "562",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Functional digestive disorders not elsewhere classified",
            "title": "564 - Functional digestive disorders not elsewhere classified",
            "threedigit": "564",
            "children": [
              {
                "key": "56400",
                "title": "56400 Constipation NOS",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "56401",
                "title": "56401 Slow transt constipation",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "56402",
                "title": "56402 Outlet dysfnc constption",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "56409",
                "title": "56409 Constipation NEC",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "5641",
                "title": "5641 Irritable bowel syndrome",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "5642",
                "title": "5642 Postgastric surgery synd",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "5643",
                "title": "5643 Vomiting post-gi surgery",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "5644",
                "title": "5644 Postop GI funct dis NEC",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "5645",
                "title": "5645 Functional diarrhea",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "5646",
                "title": "5646 Anal spasm",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "5647",
                "title": "5647 Megacolon NEC",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "56481",
                "title": "56481 Neurogenic bowel",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "56489",
                "title": "56489 Funct dis intestine NEC",
                "threedigit": "564",
                "isLeaf": true
              },
              {
                "key": "5649",
                "title": "5649 Funct dis intestine NOS",
                "threedigit": "564",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Anal fissure and fistula",
            "title": "565 - Anal fissure and fistula",
            "threedigit": "565",
            "children": [
              {
                "key": "5650",
                "title": "5650 Anal fissure",
                "threedigit": "565",
                "isLeaf": true
              },
              {
                "key": "5651",
                "title": "5651 Anal fistula",
                "threedigit": "565",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Abscess of anal and rectal regions",
            "title": "566 - Abscess of anal and rectal regions",
            "threedigit": "566",
            "children": [
              {
                "key": "566",
                "title": "566 Anal & rectal abscess",
                "threedigit": "566",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Peritonitis and retroperitoneal infections",
            "title": "567 - Peritonitis and retroperitoneal infections",
            "threedigit": "567",
            "children": [
              {
                "key": "5670",
                "title": "5670 Peritonitis in infec dis",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "5671",
                "title": "5671 Pneumococcal peritonitis",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56721",
                "title": "56721 Peritonitis (acute) gen",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56722",
                "title": "56722 Peritoneal abscess",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56723",
                "title": "56723 Spontan bact peritonitis",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56729",
                "title": "56729 Suppurat peritonitis NEC",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56731",
                "title": "56731 Psoas muscle abscess",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56738",
                "title": "56738 Retroperiton abscess NEC",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56739",
                "title": "56739 Retroperiton infect NEC",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56781",
                "title": "56781 Choleperitonitis",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56782",
                "title": "56782 Sclerosing mesenteritis",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "56789",
                "title": "56789 Peritonitis NEC",
                "threedigit": "567",
                "isLeaf": true
              },
              {
                "key": "5679",
                "title": "5679 Peritonitis NOS",
                "threedigit": "567",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of peritoneum",
            "title": "568 - Other disorders of peritoneum",
            "threedigit": "568",
            "children": [
              {
                "key": "5680",
                "title": "5680 Peritoneal adhesions",
                "threedigit": "568",
                "isLeaf": true
              },
              {
                "key": "56881",
                "title": "56881 Hemoperitoneum",
                "threedigit": "568",
                "isLeaf": true
              },
              {
                "key": "56882",
                "title": "56882 Peritoneal effusion",
                "threedigit": "568",
                "isLeaf": true
              },
              {
                "key": "56889",
                "title": "56889 Peritoneal disorder NEC",
                "threedigit": "568",
                "isLeaf": true
              },
              {
                "key": "5689",
                "title": "5689 Peritoneal disorder NOS",
                "threedigit": "568",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of intestine",
            "title": "569 - Other disorders of intestine",
            "threedigit": "569",
            "children": [
              {
                "key": "5690",
                "title": "5690 Anal & rectal polyp",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "5691",
                "title": "5691 Rectal prolapse",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "5692",
                "title": "5692 Rectal & anal stenosis",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "5693",
                "title": "5693 Rectal & anal hemorrhage",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56941",
                "title": "56941 Rectal & anal ulcer",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56942",
                "title": "56942 Anal or rectal pain",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56943",
                "title": "56943 Anal sphincter tear-old",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56944",
                "title": "56944 Dysplasia of anus",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56949",
                "title": "56949 Rectal & anal dis NEC",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "5695",
                "title": "5695 Intestinal abscess",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56960",
                "title": "56960 Colstomy/enter comp NOS",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56961",
                "title": "56961 Colosty/enterost infectn",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56962",
                "title": "56962 Colosty/enter comp-mech",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56969",
                "title": "56969 Colstmy/enteros comp NEC",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56971",
                "title": "56971 Pouchitis",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56979",
                "title": "56979 Comp intest pouch NEC",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56981",
                "title": "56981 Intestinal fistula",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56982",
                "title": "56982 Ulceration of intestine",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56983",
                "title": "56983 Perforation of intestine",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56984",
                "title": "56984 Angio intes w/o hmrhg",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56985",
                "title": "56985 Angio intes w hmrhg",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56986",
                "title": "56986 Dieulafoy les, intestine",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56987",
                "title": "56987 Vomiting of fecal matter",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "56989",
                "title": "56989 Intestinal disorders NEC",
                "threedigit": "569",
                "isLeaf": true
              },
              {
                "key": "5699",
                "title": "5699 Intestinal disorder NOS",
                "threedigit": "569",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Diseases Of Digestive System",
        "title": "570-579 Other Diseases Of Digestive System",
        "threedigit": "570",
        "children": [
          {
            "key": "Acute and subacute necrosis of liver",
            "title": "570 - Acute and subacute necrosis of liver",
            "threedigit": "570",
            "children": [
              {
                "key": "570",
                "title": "570 Acute necrosis of liver",
                "threedigit": "570",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic liver disease and cirrhosis",
            "title": "571 - Chronic liver disease and cirrhosis",
            "threedigit": "571",
            "children": [
              {
                "key": "5710",
                "title": "5710 Alcoholic fatty liver",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "5711",
                "title": "5711 Ac alcoholic hepatitis",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "5712",
                "title": "5712 Alcohol cirrhosis liver",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "5713",
                "title": "5713 Alcohol liver damage NOS",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "57140",
                "title": "57140 Chronic hepatitis NOS",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "57141",
                "title": "57141 Chr persistent hepatitis",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "57142",
                "title": "57142 Autoimmune hepatitis",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "57149",
                "title": "57149 Chronic hepatitis NEC",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "5715",
                "title": "5715 Cirrhosis of liver NOS",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "5716",
                "title": "5716 Biliary cirrhosis",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "5718",
                "title": "5718 Chronic liver dis NEC",
                "threedigit": "571",
                "isLeaf": true
              },
              {
                "key": "5719",
                "title": "5719 Chronic liver dis NOS",
                "threedigit": "571",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Liver abscess and sequelae of chronic liver disease",
            "title": "572 - Liver abscess and sequelae of chronic liver disease",
            "threedigit": "572",
            "children": [
              {
                "key": "5720",
                "title": "5720 Abscess of liver",
                "threedigit": "572",
                "isLeaf": true
              },
              {
                "key": "5721",
                "title": "5721 Portal pyemia",
                "threedigit": "572",
                "isLeaf": true
              },
              {
                "key": "5722",
                "title": "5722 Hepatic encephalopathy",
                "threedigit": "572",
                "isLeaf": true
              },
              {
                "key": "5723",
                "title": "5723 Portal hypertension",
                "threedigit": "572",
                "isLeaf": true
              },
              {
                "key": "5724",
                "title": "5724 Hepatorenal syndrome",
                "threedigit": "572",
                "isLeaf": true
              },
              {
                "key": "5728",
                "title": "5728 Oth sequela, chr liv dis",
                "threedigit": "572",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of liver",
            "title": "573 - Other disorders of liver",
            "threedigit": "573",
            "children": [
              {
                "key": "5730",
                "title": "5730 Chr passiv congest liver",
                "threedigit": "573",
                "isLeaf": true
              },
              {
                "key": "5731",
                "title": "5731 Hepatitis in viral dis",
                "threedigit": "573",
                "isLeaf": true
              },
              {
                "key": "5732",
                "title": "5732 Hepatitis in oth inf dis",
                "threedigit": "573",
                "isLeaf": true
              },
              {
                "key": "5733",
                "title": "5733 Hepatitis NOS",
                "threedigit": "573",
                "isLeaf": true
              },
              {
                "key": "5734",
                "title": "5734 Hepatic infarction",
                "threedigit": "573",
                "isLeaf": true
              },
              {
                "key": "5735",
                "title": "5735 Hepatopulmonary syndrome",
                "threedigit": "573",
                "isLeaf": true
              },
              {
                "key": "5738",
                "title": "5738 Liver disorders NEC",
                "threedigit": "573",
                "isLeaf": true
              },
              {
                "key": "5739",
                "title": "5739 Liver disorder NOS",
                "threedigit": "573",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cholelithiasis",
            "title": "574 - Cholelithiasis",
            "threedigit": "574",
            "children": [
              {
                "key": "57400",
                "title": "57400 Cholelith w ac cholecyst",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57401",
                "title": "57401 Cholelith/ac gb inf-obst",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57410",
                "title": "57410 Cholelith w cholecys NEC",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57411",
                "title": "57411 Cholelith/gb inf NEC-obs",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57420",
                "title": "57420 Cholelithiasis NOS",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57421",
                "title": "57421 Cholelithias NOS w obstr",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57430",
                "title": "57430 Choledocholith/ac gb inf",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57431",
                "title": "57431 Choledochlith/ac gb-obst",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57440",
                "title": "57440 Choledochlith/gb inf NEC",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57441",
                "title": "57441 Choledochlith/gb NEC-obs",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57450",
                "title": "57450 Choledocholithiasis NOS",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57451",
                "title": "57451 Choledochlith NOS w obst",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57460",
                "title": "57460 Gall&bil cal w/ac w/o ob",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57461",
                "title": "57461 Gall&bil cal w/ac w obs",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57470",
                "title": "57470 Gal&bil cal w/oth w/o ob",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57471",
                "title": "57471 Gall&bil cal w/oth w obs",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57480",
                "title": "57480 Gal&bil cal w/ac&chr w/o",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57481",
                "title": "57481 Gal&bil cal w/ac&ch w ob",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57490",
                "title": "57490 Gall&bil cal w/o cho w/o",
                "threedigit": "574",
                "isLeaf": true
              },
              {
                "key": "57491",
                "title": "57491 Gall&bil cal w/o ch w ob",
                "threedigit": "574",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of gallbladder",
            "title": "575 - Other disorders of gallbladder",
            "threedigit": "575",
            "children": [
              {
                "key": "5750",
                "title": "5750 Acute cholecystitis",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "57510",
                "title": "57510 Cholecystitis NOS",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "57511",
                "title": "57511 Chronic cholecystitis",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "57512",
                "title": "57512 Acte & chr cholecystitis",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "5752",
                "title": "5752 Obstruction gallbladder",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "5753",
                "title": "5753 Hydrops of gallbladder",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "5754",
                "title": "5754 Perforation gallbladder",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "5755",
                "title": "5755 Fistula of gallbladder",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "5756",
                "title": "5756 Gb cholesterolosis",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "5758",
                "title": "5758 Dis of gallbladder NEC",
                "threedigit": "575",
                "isLeaf": true
              },
              {
                "key": "5759",
                "title": "5759 Dis of gallbladder NOS",
                "threedigit": "575",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of biliary tract",
            "title": "576 - Other disorders of biliary tract",
            "threedigit": "576",
            "children": [
              {
                "key": "5760",
                "title": "5760 Postcholecystectomy synd",
                "threedigit": "576",
                "isLeaf": true
              },
              {
                "key": "5761",
                "title": "5761 Cholangitis",
                "threedigit": "576",
                "isLeaf": true
              },
              {
                "key": "5762",
                "title": "5762 Obstruction of bile duct",
                "threedigit": "576",
                "isLeaf": true
              },
              {
                "key": "5763",
                "title": "5763 Perforation of bile duct",
                "threedigit": "576",
                "isLeaf": true
              },
              {
                "key": "5764",
                "title": "5764 Fistula of bile duct",
                "threedigit": "576",
                "isLeaf": true
              },
              {
                "key": "5765",
                "title": "5765 Spasm sphincter of oddi",
                "threedigit": "576",
                "isLeaf": true
              },
              {
                "key": "5768",
                "title": "5768 Dis of biliary tract NEC",
                "threedigit": "576",
                "isLeaf": true
              },
              {
                "key": "5769",
                "title": "5769 Dis of biliary tract NOS",
                "threedigit": "576",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of pancreas",
            "title": "577 - Diseases of pancreas",
            "threedigit": "577",
            "children": [
              {
                "key": "5770",
                "title": "5770 Acute pancreatitis",
                "threedigit": "577",
                "isLeaf": true
              },
              {
                "key": "5771",
                "title": "5771 Chronic pancreatitis",
                "threedigit": "577",
                "isLeaf": true
              },
              {
                "key": "5772",
                "title": "5772 Pancreat cyst/pseudocyst",
                "threedigit": "577",
                "isLeaf": true
              },
              {
                "key": "5778",
                "title": "5778 Pancreatic disease NEC",
                "threedigit": "577",
                "isLeaf": true
              },
              {
                "key": "5779",
                "title": "5779 Pancreatic disease NOS",
                "threedigit": "577",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Gastrointestinal hemorrhage",
            "title": "578 - Gastrointestinal hemorrhage",
            "threedigit": "578",
            "children": [
              {
                "key": "5780",
                "title": "5780 Hematemesis",
                "threedigit": "578",
                "isLeaf": true
              },
              {
                "key": "5781",
                "title": "5781 Blood in stool",
                "threedigit": "578",
                "isLeaf": true
              },
              {
                "key": "5789",
                "title": "5789 Gastrointest hemorr NOS",
                "threedigit": "578",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Intestinal malabsorption",
            "title": "579 - Intestinal malabsorption",
            "threedigit": "579",
            "children": [
              {
                "key": "5790",
                "title": "5790 Celiac disease",
                "threedigit": "579",
                "isLeaf": true
              },
              {
                "key": "5791",
                "title": "5791 Tropical sprue",
                "threedigit": "579",
                "isLeaf": true
              },
              {
                "key": "5792",
                "title": "5792 Blind loop syndrome",
                "threedigit": "579",
                "isLeaf": true
              },
              {
                "key": "5793",
                "title": "5793 Intest postop nonabsorb",
                "threedigit": "579",
                "isLeaf": true
              },
              {
                "key": "5794",
                "title": "5794 Pancreatic steatorrhea",
                "threedigit": "579",
                "isLeaf": true
              },
              {
                "key": "5798",
                "title": "5798 Intest malabsorption NEC",
                "threedigit": "579",
                "isLeaf": true
              },
              {
                "key": "5799",
                "title": "5799 Intest malabsorption NOS",
                "threedigit": "579",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Diseases Of The Genitourinary System",
    "title": "580-629 Diseases Of The Genitourinary System",
    "threedigit": "580",
    "children": [
      {
        "key": "Nephritis, Nephrotic Syndrome, And Nephrosis",
        "title": "580-589 Nephritis, Nephrotic Syndrome, And Nephrosis",
        "threedigit": "580",
        "children": [
          {
            "key": "Acute glomerulonephritis",
            "title": "580 - Acute glomerulonephritis",
            "threedigit": "580",
            "children": [
              {
                "key": "5800",
                "title": "5800 Ac proliferat nephritis",
                "threedigit": "580",
                "isLeaf": true
              },
              {
                "key": "5804",
                "title": "5804 Ac rapidly progr nephrit",
                "threedigit": "580",
                "isLeaf": true
              },
              {
                "key": "58081",
                "title": "58081 Ac nephritis in oth dis",
                "threedigit": "580",
                "isLeaf": true
              },
              {
                "key": "58089",
                "title": "58089 Acute nephritis NEC",
                "threedigit": "580",
                "isLeaf": true
              },
              {
                "key": "5809",
                "title": "5809 Acute nephritis NOS",
                "threedigit": "580",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nephrotic syndrome",
            "title": "581 - Nephrotic syndrome",
            "threedigit": "581",
            "children": [
              {
                "key": "5810",
                "title": "5810 Nephrotic syn, prolifer",
                "threedigit": "581",
                "isLeaf": true
              },
              {
                "key": "5811",
                "title": "5811 Epimembranous nephritis",
                "threedigit": "581",
                "isLeaf": true
              },
              {
                "key": "5812",
                "title": "5812 Membranoprolif nephrosis",
                "threedigit": "581",
                "isLeaf": true
              },
              {
                "key": "5813",
                "title": "5813 Minimal change nephrosis",
                "threedigit": "581",
                "isLeaf": true
              },
              {
                "key": "58181",
                "title": "58181 Nephrotic syn in oth dis",
                "threedigit": "581",
                "isLeaf": true
              },
              {
                "key": "58189",
                "title": "58189 Nephrotic syndrome NEC",
                "threedigit": "581",
                "isLeaf": true
              },
              {
                "key": "5819",
                "title": "5819 Nephrotic syndrome NOS",
                "threedigit": "581",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic glomerulonephritis",
            "title": "582 - Chronic glomerulonephritis",
            "threedigit": "582",
            "children": [
              {
                "key": "5820",
                "title": "5820 Chr proliferat nephritis",
                "threedigit": "582",
                "isLeaf": true
              },
              {
                "key": "5821",
                "title": "5821 Chr membranous nephritis",
                "threedigit": "582",
                "isLeaf": true
              },
              {
                "key": "5822",
                "title": "5822 Chr membranoprolif nephr",
                "threedigit": "582",
                "isLeaf": true
              },
              {
                "key": "5824",
                "title": "5824 Chr rapid progr nephrit",
                "threedigit": "582",
                "isLeaf": true
              },
              {
                "key": "58281",
                "title": "58281 Chr nephritis in oth dis",
                "threedigit": "582",
                "isLeaf": true
              },
              {
                "key": "58289",
                "title": "58289 Chronic nephritis NEC",
                "threedigit": "582",
                "isLeaf": true
              },
              {
                "key": "5829",
                "title": "5829 Chronic nephritis NOS",
                "threedigit": "582",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nephritis and nephropathy not specified as acute or chronic",
            "title": "583 - Nephritis and nephropathy not specified as acute or chronic",
            "threedigit": "583",
            "children": [
              {
                "key": "5830",
                "title": "5830 Proliferat nephritis NOS",
                "threedigit": "583",
                "isLeaf": true
              },
              {
                "key": "5831",
                "title": "5831 Membranous nephritis NOS",
                "threedigit": "583",
                "isLeaf": true
              },
              {
                "key": "5832",
                "title": "5832 Membranoprolif nephr NOS",
                "threedigit": "583",
                "isLeaf": true
              },
              {
                "key": "5834",
                "title": "5834 Rapidly prog nephrit NOS",
                "threedigit": "583",
                "isLeaf": true
              },
              {
                "key": "5836",
                "title": "5836 Renal cort necrosis NOS",
                "threedigit": "583",
                "isLeaf": true
              },
              {
                "key": "5837",
                "title": "5837 Nephr NOS/medull necros",
                "threedigit": "583",
                "isLeaf": true
              },
              {
                "key": "58381",
                "title": "58381 Nephritis NOS in oth dis",
                "threedigit": "583",
                "isLeaf": true
              },
              {
                "key": "58389",
                "title": "58389 Nephritis NEC",
                "threedigit": "583",
                "isLeaf": true
              },
              {
                "key": "5839",
                "title": "5839 Nephritis NOS",
                "threedigit": "583",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute kidney failure",
            "title": "584 - Acute kidney failure",
            "threedigit": "584",
            "children": [
              {
                "key": "5845",
                "title": "5845 Ac kidny fail, tubr necr",
                "threedigit": "584",
                "isLeaf": true
              },
              {
                "key": "5846",
                "title": "5846 Ac kidny fail, cort necr",
                "threedigit": "584",
                "isLeaf": true
              },
              {
                "key": "5847",
                "title": "5847 Ac kidny fail, medu necr",
                "threedigit": "584",
                "isLeaf": true
              },
              {
                "key": "5848",
                "title": "5848 Acute kidney failure NEC",
                "threedigit": "584",
                "isLeaf": true
              },
              {
                "key": "5849",
                "title": "5849 Acute kidney failure NOS",
                "threedigit": "584",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic kidney disease (ckd)",
            "title": "585 - Chronic kidney disease (ckd)",
            "threedigit": "585",
            "children": [
              {
                "key": "5851",
                "title": "5851 Chro kidney dis stage I",
                "threedigit": "585",
                "isLeaf": true
              },
              {
                "key": "5852",
                "title": "5852 Chro kidney dis stage II",
                "threedigit": "585",
                "isLeaf": true
              },
              {
                "key": "5853",
                "title": "5853 Chr kidney dis stage III",
                "threedigit": "585",
                "isLeaf": true
              },
              {
                "key": "5854",
                "title": "5854 Chr kidney dis stage IV",
                "threedigit": "585",
                "isLeaf": true
              },
              {
                "key": "5855",
                "title": "5855 Chron kidney dis stage V",
                "threedigit": "585",
                "isLeaf": true
              },
              {
                "key": "5856",
                "title": "5856 End stage renal disease",
                "threedigit": "585",
                "isLeaf": true
              },
              {
                "key": "5859",
                "title": "5859 Chronic kidney dis NOS",
                "threedigit": "585",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Renal failure, unspecified",
            "title": "586 - Renal failure, unspecified",
            "threedigit": "586",
            "children": [
              {
                "key": "586",
                "title": "586 Renal failure NOS",
                "threedigit": "586",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Renal sclerosis, unspecified",
            "title": "587 - Renal sclerosis, unspecified",
            "threedigit": "587",
            "children": [
              {
                "key": "587",
                "title": "587 Renal sclerosis NOS",
                "threedigit": "587",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders resulting from impaired renal function",
            "title": "588 - Disorders resulting from impaired renal function",
            "threedigit": "588",
            "children": [
              {
                "key": "5880",
                "title": "5880 Renal osteodystrophy",
                "threedigit": "588",
                "isLeaf": true
              },
              {
                "key": "5881",
                "title": "5881 Nephrogen diabetes insip",
                "threedigit": "588",
                "isLeaf": true
              },
              {
                "key": "58881",
                "title": "58881 Sec hyperparathyrd-renal",
                "threedigit": "588",
                "isLeaf": true
              },
              {
                "key": "58889",
                "title": "58889 Impair ren funct dis NEC",
                "threedigit": "588",
                "isLeaf": true
              },
              {
                "key": "5889",
                "title": "5889 Impaired renal funct NOS",
                "threedigit": "588",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Small kidney of unknown cause",
            "title": "589 - Small kidney of unknown cause",
            "threedigit": "589",
            "children": [
              {
                "key": "5890",
                "title": "5890 Unilateral small kidney",
                "threedigit": "589",
                "isLeaf": true
              },
              {
                "key": "5891",
                "title": "5891 Bilateral small kidneys",
                "threedigit": "589",
                "isLeaf": true
              },
              {
                "key": "5899",
                "title": "5899 Small kidney NOS",
                "threedigit": "589",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Diseases Of Urinary System",
        "title": "590-599 Other Diseases Of Urinary System",
        "threedigit": "590",
        "children": [
          {
            "key": "Infections of kidney",
            "title": "590 - Infections of kidney",
            "threedigit": "590",
            "children": [
              {
                "key": "59000",
                "title": "59000 Chr pyelonephritis NOS",
                "threedigit": "590",
                "isLeaf": true
              },
              {
                "key": "59001",
                "title": "59001 Chr pyeloneph w med necr",
                "threedigit": "590",
                "isLeaf": true
              },
              {
                "key": "59010",
                "title": "59010 Ac pyelonephritis NOS",
                "threedigit": "590",
                "isLeaf": true
              },
              {
                "key": "59011",
                "title": "59011 Ac pyelonephr w med necr",
                "threedigit": "590",
                "isLeaf": true
              },
              {
                "key": "5902",
                "title": "5902 Renal/perirenal abscess",
                "threedigit": "590",
                "isLeaf": true
              },
              {
                "key": "5903",
                "title": "5903 Pyeloureteritis cystica",
                "threedigit": "590",
                "isLeaf": true
              },
              {
                "key": "59080",
                "title": "59080 Pyelonephritis NOS",
                "threedigit": "590",
                "isLeaf": true
              },
              {
                "key": "59081",
                "title": "59081 Pyelonephrit in oth dis",
                "threedigit": "590",
                "isLeaf": true
              },
              {
                "key": "5909",
                "title": "5909 Infection of kidney NOS",
                "threedigit": "590",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hydronephrosis",
            "title": "591 - Hydronephrosis",
            "threedigit": "591",
            "children": [
              {
                "key": "591",
                "title": "591 Hydronephrosis",
                "threedigit": "591",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Calculus of kidney and ureter",
            "title": "592 - Calculus of kidney and ureter",
            "threedigit": "592",
            "children": [
              {
                "key": "5920",
                "title": "5920 Calculus of kidney",
                "threedigit": "592",
                "isLeaf": true
              },
              {
                "key": "5921",
                "title": "5921 Calculus of ureter",
                "threedigit": "592",
                "isLeaf": true
              },
              {
                "key": "5929",
                "title": "5929 Urinary calculus NOS",
                "threedigit": "592",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of kidney and ureter",
            "title": "593 - Other disorders of kidney and ureter",
            "threedigit": "593",
            "children": [
              {
                "key": "5930",
                "title": "5930 Nephroptosis",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "5931",
                "title": "5931 Hypertrophy of kidney",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "5932",
                "title": "5932 Cyst of kidney, acquired",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "5933",
                "title": "5933 Stricture of ureter",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "5934",
                "title": "5934 Ureteric obstruction NEC",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "5935",
                "title": "5935 Hydroureter",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "5936",
                "title": "5936 Postural proteinuria",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "59370",
                "title": "59370 Vescouretrl rflux unspcf",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "59371",
                "title": "59371 Vscurt rflx npht uniltrl",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "59372",
                "title": "59372 Vscourtl rflx npht bltrl",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "59373",
                "title": "59373 Vscourtl rflx w npht NOS",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "59381",
                "title": "59381 Renal vascular disorder",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "59382",
                "title": "59382 Ureteral fistula",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "59389",
                "title": "59389 Renal & ureteral dis NEC",
                "threedigit": "593",
                "isLeaf": true
              },
              {
                "key": "5939",
                "title": "5939 Renal & ureteral dis NOS",
                "threedigit": "593",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Calculus of lower urinary tract",
            "title": "594 - Calculus of lower urinary tract",
            "threedigit": "594",
            "children": [
              {
                "key": "5940",
                "title": "5940 Blad diverticulum calcul",
                "threedigit": "594",
                "isLeaf": true
              },
              {
                "key": "5941",
                "title": "5941 Bladder calculus NEC",
                "threedigit": "594",
                "isLeaf": true
              },
              {
                "key": "5942",
                "title": "5942 Urethral calculus",
                "threedigit": "594",
                "isLeaf": true
              },
              {
                "key": "5948",
                "title": "5948 Lower urin calcul NEC",
                "threedigit": "594",
                "isLeaf": true
              },
              {
                "key": "5949",
                "title": "5949 Lower urin calcul NOS",
                "threedigit": "594",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cystitis",
            "title": "595 - Cystitis",
            "threedigit": "595",
            "children": [
              {
                "key": "5950",
                "title": "5950 Acute cystitis",
                "threedigit": "595",
                "isLeaf": true
              },
              {
                "key": "5951",
                "title": "5951 Chr interstit cystitis",
                "threedigit": "595",
                "isLeaf": true
              },
              {
                "key": "5952",
                "title": "5952 Chronic cystitis NEC",
                "threedigit": "595",
                "isLeaf": true
              },
              {
                "key": "5953",
                "title": "5953 Trigonitis",
                "threedigit": "595",
                "isLeaf": true
              },
              {
                "key": "5954",
                "title": "5954 Cystitis in oth dis",
                "threedigit": "595",
                "isLeaf": true
              },
              {
                "key": "59581",
                "title": "59581 Cystitis cystica",
                "threedigit": "595",
                "isLeaf": true
              },
              {
                "key": "59582",
                "title": "59582 Irradiation cystitis",
                "threedigit": "595",
                "isLeaf": true
              },
              {
                "key": "59589",
                "title": "59589 Cystitis NEC",
                "threedigit": "595",
                "isLeaf": true
              },
              {
                "key": "5959",
                "title": "5959 Cystitis NOS",
                "threedigit": "595",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of bladder",
            "title": "596 - Other disorders of bladder",
            "threedigit": "596",
            "children": [
              {
                "key": "5960",
                "title": "5960 Bladder neck obstruction",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "5961",
                "title": "5961 Intestinovesical fistula",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "5962",
                "title": "5962 Vesical fistula NEC",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "5963",
                "title": "5963 Diverticulum of bladder",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "5964",
                "title": "5964 Atony of bladder",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59651",
                "title": "59651 Hypertonicity of bladder",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59652",
                "title": "59652 Low bladder compliance",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59653",
                "title": "59653 Paralysis of bladder",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59654",
                "title": "59654 Neurogenic bladder NOS",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59655",
                "title": "59655 Detrusr sphinc dyssnrgia",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59659",
                "title": "59659 Oth func dsdr bladder",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "5966",
                "title": "5966 Bladder rupt, nontraum",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "5967",
                "title": "5967 Bladder wall hemorrhage",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59681",
                "title": "59681 Infection of cystostomy",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59682",
                "title": "59682 Mech comp of cystostomy",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59683",
                "title": "59683 Other comp of cystostomy",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "59689",
                "title": "59689 Disorders of bladder NEC",
                "threedigit": "596",
                "isLeaf": true
              },
              {
                "key": "5969",
                "title": "5969 Bladder disorder NOS",
                "threedigit": "596",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Urethritis not sexually transmitted and urethral syndrome",
            "title": "597 - Urethritis not sexually transmitted and urethral syndrome",
            "threedigit": "597",
            "children": [
              {
                "key": "5970",
                "title": "5970 Urethral abscess",
                "threedigit": "597",
                "isLeaf": true
              },
              {
                "key": "59780",
                "title": "59780 Urethritis NOS",
                "threedigit": "597",
                "isLeaf": true
              },
              {
                "key": "59781",
                "title": "59781 Urethral syndrome NOS",
                "threedigit": "597",
                "isLeaf": true
              },
              {
                "key": "59789",
                "title": "59789 Urethritis NEC",
                "threedigit": "597",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Urethral stricture",
            "title": "598 - Urethral stricture",
            "threedigit": "598",
            "children": [
              {
                "key": "59800",
                "title": "59800 Urethr strict:infect NOS",
                "threedigit": "598",
                "isLeaf": true
              },
              {
                "key": "59801",
                "title": "59801 Ureth strict:oth infect",
                "threedigit": "598",
                "isLeaf": true
              },
              {
                "key": "5981",
                "title": "5981 Traum urethral stricture",
                "threedigit": "598",
                "isLeaf": true
              },
              {
                "key": "5982",
                "title": "5982 Postop urethral strictur",
                "threedigit": "598",
                "isLeaf": true
              },
              {
                "key": "5988",
                "title": "5988 Urethral stricture NEC",
                "threedigit": "598",
                "isLeaf": true
              },
              {
                "key": "5989",
                "title": "5989 Urethral stricture NOS",
                "threedigit": "598",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of urethra and urinary tract",
            "title": "599 - Other disorders of urethra and urinary tract",
            "threedigit": "599",
            "children": [
              {
                "key": "5990",
                "title": "5990 Urin tract infection NOS",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "5991",
                "title": "5991 Urethral fistula",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "5992",
                "title": "5992 Urethral diverticulum",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "5993",
                "title": "5993 Urethral caruncle",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "5994",
                "title": "5994 Urethral false passage",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "5995",
                "title": "5995 Prolapse urethral mucosa",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59960",
                "title": "59960 Urinary obstruction NOS",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59969",
                "title": "59969 Urinary obstruction NEC",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59970",
                "title": "59970 Hematuria NOS",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59971",
                "title": "59971 Gross hematuria",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59972",
                "title": "59972 Microscopic hematuria",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59981",
                "title": "59981 Urethral hypermobility",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59982",
                "title": "59982 Intrinsc sphnctr dficncy",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59983",
                "title": "59983 Urethral instability",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59984",
                "title": "59984 Oth spcf dsdr urethra",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "59989",
                "title": "59989 Oth spcf dsdr urnry trct",
                "threedigit": "599",
                "isLeaf": true
              },
              {
                "key": "5999",
                "title": "5999 Urinary tract dis NOS",
                "threedigit": "599",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Diseases Of Male Genital Organs",
        "title": "600-608 Diseases Of Male Genital Organs",
        "threedigit": "600",
        "children": [
          {
            "key": "Hyperplasia of prostate",
            "title": "600 - Hyperplasia of prostate",
            "threedigit": "600",
            "children": [
              {
                "key": "60000",
                "title": "60000 BPH w/o urinary obs/LUTS",
                "threedigit": "600",
                "isLeaf": true
              },
              {
                "key": "60001",
                "title": "60001 BPH w urinary obs/LUTS",
                "threedigit": "600",
                "isLeaf": true
              },
              {
                "key": "60010",
                "title": "60010 Nod prostate w/o ur obst",
                "threedigit": "600",
                "isLeaf": true
              },
              {
                "key": "60011",
                "title": "60011 Nod prostate w ur obst",
                "threedigit": "600",
                "isLeaf": true
              },
              {
                "key": "60020",
                "title": "60020 BPH loc w/o ur obs/LUTS",
                "threedigit": "600",
                "isLeaf": true
              },
              {
                "key": "60021",
                "title": "60021 BPH loc w urin obs/LUTS",
                "threedigit": "600",
                "isLeaf": true
              },
              {
                "key": "6003",
                "title": "6003 Cyst of prostate",
                "threedigit": "600",
                "isLeaf": true
              },
              {
                "key": "60090",
                "title": "60090 BPH NOS w/o ur obs/LUTS",
                "threedigit": "600",
                "isLeaf": true
              },
              {
                "key": "60091",
                "title": "60091 BPH NOS w ur obs/LUTS",
                "threedigit": "600",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Inflammatory diseases of prostate",
            "title": "601 - Inflammatory diseases of prostate",
            "threedigit": "601",
            "children": [
              {
                "key": "6010",
                "title": "6010 Acute prostatitis",
                "threedigit": "601",
                "isLeaf": true
              },
              {
                "key": "6011",
                "title": "6011 Chronic prostatitis",
                "threedigit": "601",
                "isLeaf": true
              },
              {
                "key": "6012",
                "title": "6012 Abscess of prostate",
                "threedigit": "601",
                "isLeaf": true
              },
              {
                "key": "6013",
                "title": "6013 Prostatocystitis",
                "threedigit": "601",
                "isLeaf": true
              },
              {
                "key": "6014",
                "title": "6014 Prostatitis in oth dis",
                "threedigit": "601",
                "isLeaf": true
              },
              {
                "key": "6018",
                "title": "6018 Prostatic inflam dis NEC",
                "threedigit": "601",
                "isLeaf": true
              },
              {
                "key": "6019",
                "title": "6019 Prostatitis NOS",
                "threedigit": "601",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of prostate",
            "title": "602 - Other disorders of prostate",
            "threedigit": "602",
            "children": [
              {
                "key": "6020",
                "title": "6020 Calculus of prostate",
                "threedigit": "602",
                "isLeaf": true
              },
              {
                "key": "6021",
                "title": "6021 Prostatic congest/hemorr",
                "threedigit": "602",
                "isLeaf": true
              },
              {
                "key": "6022",
                "title": "6022 Atrophy of prostate",
                "threedigit": "602",
                "isLeaf": true
              },
              {
                "key": "6023",
                "title": "6023 Dysplasia of prostate",
                "threedigit": "602",
                "isLeaf": true
              },
              {
                "key": "6028",
                "title": "6028 Prostatic disorders NEC",
                "threedigit": "602",
                "isLeaf": true
              },
              {
                "key": "6029",
                "title": "6029 Prostatic disorder NOS",
                "threedigit": "602",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hydrocele",
            "title": "603 - Hydrocele",
            "threedigit": "603",
            "children": [
              {
                "key": "6030",
                "title": "6030 Encysted hydrocele",
                "threedigit": "603",
                "isLeaf": true
              },
              {
                "key": "6031",
                "title": "6031 Infected hydrocele",
                "threedigit": "603",
                "isLeaf": true
              },
              {
                "key": "6038",
                "title": "6038 Hydrocele NEC",
                "threedigit": "603",
                "isLeaf": true
              },
              {
                "key": "6039",
                "title": "6039 Hydrocele NOS",
                "threedigit": "603",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Orchitis and epididymitis",
            "title": "604 - Orchitis and epididymitis",
            "threedigit": "604",
            "children": [
              {
                "key": "6040",
                "title": "6040 Orchitis with abscess",
                "threedigit": "604",
                "isLeaf": true
              },
              {
                "key": "60490",
                "title": "60490 Orchitis/epididymit NOS",
                "threedigit": "604",
                "isLeaf": true
              },
              {
                "key": "60491",
                "title": "60491 Orchitis in oth disease",
                "threedigit": "604",
                "isLeaf": true
              },
              {
                "key": "60499",
                "title": "60499 Orchitis/epididymit NEC",
                "threedigit": "604",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Redundant prepuce and phimosis",
            "title": "605 - Redundant prepuce and phimosis",
            "threedigit": "605",
            "children": [
              {
                "key": "605",
                "title": "605 Redun prepuce & phimosis",
                "threedigit": "605",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Male infertility",
            "title": "606 - Male infertility",
            "threedigit": "606",
            "children": [
              {
                "key": "6060",
                "title": "6060 Azoospermia",
                "threedigit": "606",
                "isLeaf": true
              },
              {
                "key": "6061",
                "title": "6061 Oligospermia",
                "threedigit": "606",
                "isLeaf": true
              },
              {
                "key": "6068",
                "title": "6068 Male infertility NEC",
                "threedigit": "606",
                "isLeaf": true
              },
              {
                "key": "6069",
                "title": "6069 Male infertility NOS",
                "threedigit": "606",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of penis",
            "title": "607 - Disorders of penis",
            "threedigit": "607",
            "children": [
              {
                "key": "6070",
                "title": "6070 Leukoplakia of penis",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "6071",
                "title": "6071 Balanoposthitis",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "6072",
                "title": "6072 Inflam dis, penis NEC",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "6073",
                "title": "6073 Priapism",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "60781",
                "title": "60781 Balanitis xerotica oblit",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "60782",
                "title": "60782 Vascular disorder, penis",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "60783",
                "title": "60783 Edema of penis",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "60784",
                "title": "60784 Impotence, organic orign",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "60785",
                "title": "60785 Peyronie's disease",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "60789",
                "title": "60789 Disorder of penis NEC",
                "threedigit": "607",
                "isLeaf": true
              },
              {
                "key": "6079",
                "title": "6079 Disorder of penis NOS",
                "threedigit": "607",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of male genital organs",
            "title": "608 - Other disorders of male genital organs",
            "threedigit": "608",
            "children": [
              {
                "key": "6080",
                "title": "6080 Seminal vesiculitis",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "6081",
                "title": "6081 Spermatocele",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60820",
                "title": "60820 Torsion of testis NOS",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60821",
                "title": "60821 Extravag tors sperm cord",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60822",
                "title": "60822 Intravag tors sperm cord",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60823",
                "title": "60823 Torsion appendix testis",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60824",
                "title": "60824 Torsion appy epididymis",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "6083",
                "title": "6083 Atrophy of testis",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "6084",
                "title": "6084 Male gen inflam dis NEC",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60881",
                "title": "60881 Male gen dis in oth dis",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60882",
                "title": "60882 Hematospermia",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60883",
                "title": "60883 Male gen vascul dis NEC",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60884",
                "title": "60884 Chylocele, tunic vaginal",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60885",
                "title": "60885 Stricture, male gen orgn",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60886",
                "title": "60886 Edema, male genital orgn",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60887",
                "title": "60887 Retrograde ejaculation",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "60889",
                "title": "60889 Male genital dis NEC",
                "threedigit": "608",
                "isLeaf": true
              },
              {
                "key": "6089",
                "title": "6089 Male genital dis NOS",
                "threedigit": "608",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Disorders Of Breast",
        "title": "610-612 Disorders Of Breast",
        "threedigit": "610",
        "children": [
          {
            "key": "Benign mammary dysplasias",
            "title": "610 - Benign mammary dysplasias",
            "threedigit": "610",
            "children": [
              {
                "key": "6100",
                "title": "6100 Solitary cyst of breast",
                "threedigit": "610",
                "isLeaf": true
              },
              {
                "key": "6101",
                "title": "6101 Diffus cystic mastopathy",
                "threedigit": "610",
                "isLeaf": true
              },
              {
                "key": "6102",
                "title": "6102 Fibroadenosis of breast",
                "threedigit": "610",
                "isLeaf": true
              },
              {
                "key": "6103",
                "title": "6103 Fibrosclerosis of breast",
                "threedigit": "610",
                "isLeaf": true
              },
              {
                "key": "6104",
                "title": "6104 Mammary duct ectasia",
                "threedigit": "610",
                "isLeaf": true
              },
              {
                "key": "6108",
                "title": "6108 Benign mamm dysplas NEC",
                "threedigit": "610",
                "isLeaf": true
              },
              {
                "key": "6109",
                "title": "6109 Benign mamm dysplas NOS",
                "threedigit": "610",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of breast",
            "title": "611 - Other disorders of breast",
            "threedigit": "611",
            "children": [
              {
                "key": "6110",
                "title": "6110 Inflam disease of breast",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "6111",
                "title": "6111 Hypertrophy of breast",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "6112",
                "title": "6112 Fissure of nipple",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "6113",
                "title": "6113 Fat necrosis of breast",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "6114",
                "title": "6114 Atrophy of breast",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "6115",
                "title": "6115 Galactocele",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "6116",
                "title": "6116 Galactorrhea-nonobstet",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "61171",
                "title": "61171 Mastodynia",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "61172",
                "title": "61172 Lump or mass in breast",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "61179",
                "title": "61179 Symptoms in breast NEC",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "61181",
                "title": "61181 Ptosis of breast",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "61182",
                "title": "61182 Hypoplasia of breast",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "61183",
                "title": "61183 Capslr contrctr brst imp",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "61189",
                "title": "61189 Disorders breast NEC",
                "threedigit": "611",
                "isLeaf": true
              },
              {
                "key": "6119",
                "title": "6119 Breast disorder NOS",
                "threedigit": "611",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Deformity and disproportion of reconstructed breast",
            "title": "612 - Deformity and disproportion of reconstructed breast",
            "threedigit": "612",
            "children": [
              {
                "key": "6120",
                "title": "6120 Deformity reconst breast",
                "threedigit": "612",
                "isLeaf": true
              },
              {
                "key": "6121",
                "title": "6121 Disproportn reconst brst",
                "threedigit": "612",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Inflammatory Disease Of Female Pelvic Organs",
        "title": "614-616 Inflammatory Disease Of Female Pelvic Organs",
        "threedigit": "614",
        "children": [
          {
            "key": "Inflammatory disease of ovary fallopian tube pelvic cellular tissue and peritoneum",
            "title": "614 - Inflammatory disease of ovary fallopian tube pelvic cellular tissue and peritoneum",
            "threedigit": "614",
            "children": [
              {
                "key": "6140",
                "title": "6140 Ac salpingo-oophoritis",
                "threedigit": "614",
                "isLeaf": true
              },
              {
                "key": "6141",
                "title": "6141 Chr salpingo-oophoritis",
                "threedigit": "614",
                "isLeaf": true
              },
              {
                "key": "6142",
                "title": "6142 Salpingo-oophoritis NOS",
                "threedigit": "614",
                "isLeaf": true
              },
              {
                "key": "6143",
                "title": "6143 Acute parametritis",
                "threedigit": "614",
                "isLeaf": true
              },
              {
                "key": "6144",
                "title": "6144 Chronic parametritis",
                "threedigit": "614",
                "isLeaf": true
              },
              {
                "key": "6145",
                "title": "6145 Ac pelv peritonitis-fem",
                "threedigit": "614",
                "isLeaf": true
              },
              {
                "key": "6146",
                "title": "6146 Fem pelvic periton adhes",
                "threedigit": "614",
                "isLeaf": true
              },
              {
                "key": "6147",
                "title": "6147 Chr pelv periton NEC-fem",
                "threedigit": "614",
                "isLeaf": true
              },
              {
                "key": "6148",
                "title": "6148 Fem pelv inflam dis NEC",
                "threedigit": "614",
                "isLeaf": true
              },
              {
                "key": "6149",
                "title": "6149 Fem pelv inflam dis NOS",
                "threedigit": "614",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Inflammatory diseases of uterus except cervix",
            "title": "615 - Inflammatory diseases of uterus except cervix",
            "threedigit": "615",
            "children": [
              {
                "key": "6150",
                "title": "6150 Ac uterine inflammation",
                "threedigit": "615",
                "isLeaf": true
              },
              {
                "key": "6151",
                "title": "6151 Chr uterine inflammation",
                "threedigit": "615",
                "isLeaf": true
              },
              {
                "key": "6159",
                "title": "6159 Uterine inflam dis NOS",
                "threedigit": "615",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Inflammatory disease of cervix vagina and vulva",
            "title": "616 - Inflammatory disease of cervix vagina and vulva",
            "threedigit": "616",
            "children": [
              {
                "key": "6160",
                "title": "6160 Cervicitis",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "61610",
                "title": "61610 Vaginitis NOS",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "61611",
                "title": "61611 Vaginitis in oth disease",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "6162",
                "title": "6162 Bartholin's gland cyst",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "6163",
                "title": "6163 Bartholin's glnd abscess",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "6164",
                "title": "6164 Abscess of vulva NEC",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "61650",
                "title": "61650 Ulceration of vulva NOS",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "61651",
                "title": "61651 Vulvar ulcer in oth dis",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "61681",
                "title": "61681 Mucositis cerv,vag,vulva",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "61689",
                "title": "61689 Inflm cerv,vag,vulva NEC",
                "threedigit": "616",
                "isLeaf": true
              },
              {
                "key": "6169",
                "title": "6169 Female gen inflam NOS",
                "threedigit": "616",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Disorders Of Female Genital Tract",
        "title": "617-629 Other Disorders Of Female Genital Tract",
        "threedigit": "617",
        "children": [
          {
            "key": "Endometriosis",
            "title": "617 - Endometriosis",
            "threedigit": "617",
            "children": [
              {
                "key": "6170",
                "title": "6170 Uterine endometriosis",
                "threedigit": "617",
                "isLeaf": true
              },
              {
                "key": "6171",
                "title": "6171 Ovarian endometriosis",
                "threedigit": "617",
                "isLeaf": true
              },
              {
                "key": "6172",
                "title": "6172 Tubal endometriosis",
                "threedigit": "617",
                "isLeaf": true
              },
              {
                "key": "6173",
                "title": "6173 Pelv perit endometriosis",
                "threedigit": "617",
                "isLeaf": true
              },
              {
                "key": "6174",
                "title": "6174 Vaginal endometriosis",
                "threedigit": "617",
                "isLeaf": true
              },
              {
                "key": "6175",
                "title": "6175 Intestinal endometriosis",
                "threedigit": "617",
                "isLeaf": true
              },
              {
                "key": "6176",
                "title": "6176 Endometriosis in scar",
                "threedigit": "617",
                "isLeaf": true
              },
              {
                "key": "6178",
                "title": "6178 Endometriosis NEC",
                "threedigit": "617",
                "isLeaf": true
              },
              {
                "key": "6179",
                "title": "6179 Endometriosis NOS",
                "threedigit": "617",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Genital prolapse",
            "title": "618 - Genital prolapse",
            "threedigit": "618",
            "children": [
              {
                "key": "61800",
                "title": "61800 Vaginal wall prolpse NOS",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61801",
                "title": "61801 Cystocele, midline",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61802",
                "title": "61802 Cystocele, lateral",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61803",
                "title": "61803 Urethrocele",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61804",
                "title": "61804 Rectocele",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61805",
                "title": "61805 Perineocele",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61809",
                "title": "61809 Cystourethrocele",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "6181",
                "title": "6181 Uterine prolapse",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "6182",
                "title": "6182 Uterovag prolaps-incompl",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "6183",
                "title": "6183 Uterovag prolaps-complet",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "6184",
                "title": "6184 Utervaginal prolapse NOS",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "6185",
                "title": "6185 Postop vaginal prolapse",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "6186",
                "title": "6186 Vaginal enterocele",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "6187",
                "title": "6187 Old lacer pelvic muscle",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61881",
                "title": "61881 Incomptnce pubocerv tiss",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61882",
                "title": "61882 Incomptnce rectovag tiss",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61883",
                "title": "61883 Pelvic muscle wasting",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61884",
                "title": "61884 Cervical stump prolapse",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "61889",
                "title": "61889 Genital prolapse NEC",
                "threedigit": "618",
                "isLeaf": true
              },
              {
                "key": "6189",
                "title": "6189 Genital prolapse NOS",
                "threedigit": "618",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fistula involving female genital tract",
            "title": "619 - Fistula involving female genital tract",
            "threedigit": "619",
            "children": [
              {
                "key": "6190",
                "title": "6190 Urin-genital fistul, fem",
                "threedigit": "619",
                "isLeaf": true
              },
              {
                "key": "6191",
                "title": "6191 Digest-genit fistul, fem",
                "threedigit": "619",
                "isLeaf": true
              },
              {
                "key": "6192",
                "title": "6192 Genital-skin fistul, fem",
                "threedigit": "619",
                "isLeaf": true
              },
              {
                "key": "6198",
                "title": "6198 Fem genital fistula NEC",
                "threedigit": "619",
                "isLeaf": true
              },
              {
                "key": "6199",
                "title": "6199 Fem genital fistula NOS",
                "threedigit": "619",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Noninflammatory disorders of ovary fallopian tube and broad ligament",
            "title": "620 - Noninflammatory disorders of ovary fallopian tube and broad ligament",
            "threedigit": "620",
            "children": [
              {
                "key": "6200",
                "title": "6200 Follicular cyst of ovary",
                "threedigit": "620",
                "isLeaf": true
              },
              {
                "key": "6201",
                "title": "6201 Corpus luteum cyst",
                "threedigit": "620",
                "isLeaf": true
              },
              {
                "key": "6202",
                "title": "6202 Ovarian cyst NEC/NOS",
                "threedigit": "620",
                "isLeaf": true
              },
              {
                "key": "6203",
                "title": "6203 Acq atrophy ovary & tube",
                "threedigit": "620",
                "isLeaf": true
              },
              {
                "key": "6204",
                "title": "6204 Prolapse of ovary & tube",
                "threedigit": "620",
                "isLeaf": true
              },
              {
                "key": "6205",
                "title": "6205 Torsion of ovary or tube",
                "threedigit": "620",
                "isLeaf": true
              },
              {
                "key": "6206",
                "title": "6206 Broad ligament lacer syn",
                "threedigit": "620",
                "isLeaf": true
              },
              {
                "key": "6207",
                "title": "6207 Broad ligament hematoma",
                "threedigit": "620",
                "isLeaf": true
              },
              {
                "key": "6208",
                "title": "6208 Noninfl dis ova/adnx NEC",
                "threedigit": "620",
                "isLeaf": true
              },
              {
                "key": "6209",
                "title": "6209 Noninfl dis ova/adnx NOS",
                "threedigit": "620",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of uterus not elsewhere classified",
            "title": "621 - Disorders of uterus not elsewhere classified",
            "threedigit": "621",
            "children": [
              {
                "key": "6210",
                "title": "6210 Polyp of corpus uteri",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "6211",
                "title": "6211 Chr uterine subinvolutn",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "6212",
                "title": "6212 Hypertrophy of uterus",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "62130",
                "title": "62130 Endometrial hyperpla NOS",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "62131",
                "title": "62131 Simp endo hyper w/o atyp",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "62132",
                "title": "62132 Comp endo hyper w/o atyp",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "62133",
                "title": "62133 Endomet hyperpla w atyp",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "62134",
                "title": "62134 Ben endomet hyperplasia",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "62135",
                "title": "62135 Endomet intraepithl neop",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "6214",
                "title": "6214 Hematometra",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "6215",
                "title": "6215 Intrauterine synechiae",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "6216",
                "title": "6216 Malposition of uterus",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "6217",
                "title": "6217 Chr inversion of uterus",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "6218",
                "title": "6218 Disorders of uterus NEC",
                "threedigit": "621",
                "isLeaf": true
              },
              {
                "key": "6219",
                "title": "6219 Disorder of uterus NOS",
                "threedigit": "621",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Noninflammatory disorders of cervix",
            "title": "622 - Noninflammatory disorders of cervix",
            "threedigit": "622",
            "children": [
              {
                "key": "6220",
                "title": "6220 Erosion/ectropion cervix",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "62210",
                "title": "62210 Dysplasia of cervix NOS",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "62211",
                "title": "62211 Mild dysplasia of cervix",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "62212",
                "title": "62212 Mod dysplasia of cervix",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "6222",
                "title": "6222 Leukoplakia of cervix",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "6223",
                "title": "6223 Old laceration of cervix",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "6224",
                "title": "6224 Stricture of cervix",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "6225",
                "title": "6225 Incompetence of cervix",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "6226",
                "title": "6226 Hypertrophic elong cervx",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "6227",
                "title": "6227 Mucous polyp of cervix",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "6228",
                "title": "6228 Noninflam dis cervix NEC",
                "threedigit": "622",
                "isLeaf": true
              },
              {
                "key": "6229",
                "title": "6229 Noninflam dis cervix NOS",
                "threedigit": "622",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Noninflammatory disorders of vagina",
            "title": "623 - Noninflammatory disorders of vagina",
            "threedigit": "623",
            "children": [
              {
                "key": "6230",
                "title": "6230 Dysplasia of vagina",
                "threedigit": "623",
                "isLeaf": true
              },
              {
                "key": "6231",
                "title": "6231 Leukoplakia of vagina",
                "threedigit": "623",
                "isLeaf": true
              },
              {
                "key": "6232",
                "title": "6232 Stricture of vagina",
                "threedigit": "623",
                "isLeaf": true
              },
              {
                "key": "6233",
                "title": "6233 Tight hymenal ring",
                "threedigit": "623",
                "isLeaf": true
              },
              {
                "key": "6234",
                "title": "6234 Old vaginal laceration",
                "threedigit": "623",
                "isLeaf": true
              },
              {
                "key": "6235",
                "title": "6235 Noninfect vag leukorrhea",
                "threedigit": "623",
                "isLeaf": true
              },
              {
                "key": "6236",
                "title": "6236 Vaginal hematoma",
                "threedigit": "623",
                "isLeaf": true
              },
              {
                "key": "6237",
                "title": "6237 Polyp of vagina",
                "threedigit": "623",
                "isLeaf": true
              },
              {
                "key": "6238",
                "title": "6238 Noninflam dis vagina NEC",
                "threedigit": "623",
                "isLeaf": true
              },
              {
                "key": "6239",
                "title": "6239 Noninflam dis vagina NOS",
                "threedigit": "623",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Noninflammatory disorders of vulva and perineum",
            "title": "624 - Noninflammatory disorders of vulva and perineum",
            "threedigit": "624",
            "children": [
              {
                "key": "62401",
                "title": "62401 Vulvar intraeph neopl I",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "62402",
                "title": "62402 Vulvr intraepth neopl II",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "62409",
                "title": "62409 Dystrophy of vulva NEC",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "6241",
                "title": "6241 Atrophy of vulva",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "6242",
                "title": "6242 Hypertrophy of clitoris",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "6243",
                "title": "6243 Hypertrophy of labia",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "6244",
                "title": "6244 Old laceration of vulva",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "6245",
                "title": "6245 Hematoma of vulva",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "6246",
                "title": "6246 Polyp of labia and vulva",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "6248",
                "title": "6248 Noninflam dis vulva NEC",
                "threedigit": "624",
                "isLeaf": true
              },
              {
                "key": "6249",
                "title": "6249 Noninflam dis vulva NOS",
                "threedigit": "624",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pain and other symptoms associated with female genital organs",
            "title": "625 - Pain and other symptoms associated with female genital organs",
            "threedigit": "625",
            "children": [
              {
                "key": "6250",
                "title": "6250 Dyspareunia",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "6251",
                "title": "6251 Vaginismus",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "6252",
                "title": "6252 Mittelschmerz",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "6253",
                "title": "6253 Dysmenorrhea",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "6254",
                "title": "6254 Premenstrual tension",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "6255",
                "title": "6255 Pelvic congestion synd",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "6256",
                "title": "6256 Fem stress incontinence",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "62570",
                "title": "62570 Vulvodynia NOS",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "62571",
                "title": "62571 Vulvar vestibulitis",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "62579",
                "title": "62579 Other vulvodynia",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "6258",
                "title": "6258 Fem genital symptoms NEC",
                "threedigit": "625",
                "isLeaf": true
              },
              {
                "key": "6259",
                "title": "6259 Fem genital symptoms NOS",
                "threedigit": "625",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of menstruation and other abnormal bleeding from female genital tract",
            "title": "626 - Disorders of menstruation and other abnormal bleeding from female genital tract",
            "threedigit": "626",
            "children": [
              {
                "key": "6260",
                "title": "6260 Absence of menstruation",
                "threedigit": "626",
                "isLeaf": true
              },
              {
                "key": "6261",
                "title": "6261 Scanty menstruation",
                "threedigit": "626",
                "isLeaf": true
              },
              {
                "key": "6262",
                "title": "6262 Excessive menstruation",
                "threedigit": "626",
                "isLeaf": true
              },
              {
                "key": "6263",
                "title": "6263 Pubertal menorrhagia",
                "threedigit": "626",
                "isLeaf": true
              },
              {
                "key": "6264",
                "title": "6264 Irregular menstruation",
                "threedigit": "626",
                "isLeaf": true
              },
              {
                "key": "6265",
                "title": "6265 Ovulation bleeding",
                "threedigit": "626",
                "isLeaf": true
              },
              {
                "key": "6266",
                "title": "6266 Metrorrhagia",
                "threedigit": "626",
                "isLeaf": true
              },
              {
                "key": "6267",
                "title": "6267 Postcoital bleeding",
                "threedigit": "626",
                "isLeaf": true
              },
              {
                "key": "6268",
                "title": "6268 Menstrual disorder NEC",
                "threedigit": "626",
                "isLeaf": true
              },
              {
                "key": "6269",
                "title": "6269 Menstrual disorder NOS",
                "threedigit": "626",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Menopausal and postmenopausal disorders",
            "title": "627 - Menopausal and postmenopausal disorders",
            "threedigit": "627",
            "children": [
              {
                "key": "6270",
                "title": "6270 Premenopause menorrhagia",
                "threedigit": "627",
                "isLeaf": true
              },
              {
                "key": "6271",
                "title": "6271 Postmenopausal bleeding",
                "threedigit": "627",
                "isLeaf": true
              },
              {
                "key": "6272",
                "title": "6272 Sympt fem climact state",
                "threedigit": "627",
                "isLeaf": true
              },
              {
                "key": "6273",
                "title": "6273 Atrophic vaginitis",
                "threedigit": "627",
                "isLeaf": true
              },
              {
                "key": "6274",
                "title": "6274 Sympt state w artif meno",
                "threedigit": "627",
                "isLeaf": true
              },
              {
                "key": "6278",
                "title": "6278 Menopausal disorder NEC",
                "threedigit": "627",
                "isLeaf": true
              },
              {
                "key": "6279",
                "title": "6279 Menopausal disorder NOS",
                "threedigit": "627",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Female infertility",
            "title": "628 - Female infertility",
            "threedigit": "628",
            "children": [
              {
                "key": "6280",
                "title": "6280 Infertility-anovulation",
                "threedigit": "628",
                "isLeaf": true
              },
              {
                "key": "6281",
                "title": "6281 Infertil-pituitary orig",
                "threedigit": "628",
                "isLeaf": true
              },
              {
                "key": "6282",
                "title": "6282 Infertility-tubal origin",
                "threedigit": "628",
                "isLeaf": true
              },
              {
                "key": "6283",
                "title": "6283 Infertility-uterine orig",
                "threedigit": "628",
                "isLeaf": true
              },
              {
                "key": "6284",
                "title": "6284 Infertil-cervical orig",
                "threedigit": "628",
                "isLeaf": true
              },
              {
                "key": "6288",
                "title": "6288 Female infertility NEC",
                "threedigit": "628",
                "isLeaf": true
              },
              {
                "key": "6289",
                "title": "6289 Female infertility NOS",
                "threedigit": "628",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of female genital organs",
            "title": "629 - Other disorders of female genital organs",
            "threedigit": "629",
            "children": [
              {
                "key": "6290",
                "title": "6290 Hematocele, female NEC",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "6291",
                "title": "6291 Hydrocele canal nuck-fem",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "62920",
                "title": "62920 Genital mutilation NOS",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "62921",
                "title": "62921 Genital mutilatn type I",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "62922",
                "title": "62922 Genital mutilatn type II",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "62923",
                "title": "62923 Genital muilatn type III",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "62929",
                "title": "62929 Fem genital mutilate NEC",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "62931",
                "title": "62931 Eros imp vag mesh in tis",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "62932",
                "title": "62932 Exp imp vag mesh-vagina",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "62981",
                "title": "62981 Rec preg loss wo cur prg",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "62989",
                "title": "62989 Female genital disor NEC",
                "threedigit": "629",
                "isLeaf": true
              },
              {
                "key": "6299",
                "title": "6299 Female genital dis NOS",
                "threedigit": "629",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Complications Of Pregnancy, Childbirth, And The Puerperium",
    "title": "630-679 Complications Of Pregnancy, Childbirth, And The Puerperium",
    "threedigit": "630",
    "children": [
      {
        "key": "Ectopic And Molar Pregnancy And Other Pregnancy With Abortive Outcome",
        "title": "630-639 Ectopic And Molar Pregnancy And Other Pregnancy With Abortive Outcome",
        "threedigit": "630",
        "children": [
          {
            "key": "Hydatidiform mole",
            "title": "630 - Hydatidiform mole",
            "threedigit": "630",
            "children": [
              {
                "key": "630",
                "title": "630 Hydatidiform mole",
                "threedigit": "630",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other abnormal product of conception",
            "title": "631 - Other abnormal product of conception",
            "threedigit": "631",
            "children": [
              {
                "key": "6310",
                "title": "6310 Inapp chg hCG early preg",
                "threedigit": "631",
                "isLeaf": true
              },
              {
                "key": "6318",
                "title": "6318 Oth abn prod conception",
                "threedigit": "631",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Missed abortion",
            "title": "632 - Missed abortion",
            "threedigit": "632",
            "children": [
              {
                "key": "632",
                "title": "632 Missed abortion",
                "threedigit": "632",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ectopic pregnancy",
            "title": "633 - Ectopic pregnancy",
            "threedigit": "633",
            "children": [
              {
                "key": "63300",
                "title": "63300 Abd preg w/o intrau preg",
                "threedigit": "633",
                "isLeaf": true
              },
              {
                "key": "63301",
                "title": "63301 Abd preg w intraut preg",
                "threedigit": "633",
                "isLeaf": true
              },
              {
                "key": "63310",
                "title": "63310 Tubal preg w/o intra prg",
                "threedigit": "633",
                "isLeaf": true
              },
              {
                "key": "63311",
                "title": "63311 Tubal preg w intra preg",
                "threedigit": "633",
                "isLeaf": true
              },
              {
                "key": "63320",
                "title": "63320 Ovarn preg w/o intra prg",
                "threedigit": "633",
                "isLeaf": true
              },
              {
                "key": "63321",
                "title": "63321 Ovarian preg w intra prg",
                "threedigit": "633",
                "isLeaf": true
              },
              {
                "key": "63380",
                "title": "63380 Ect preg NEC w/o int prg",
                "threedigit": "633",
                "isLeaf": true
              },
              {
                "key": "63381",
                "title": "63381 Ectpc prg NEC w int preg",
                "threedigit": "633",
                "isLeaf": true
              },
              {
                "key": "63390",
                "title": "63390 Ect preg NOS w/o int prg",
                "threedigit": "633",
                "isLeaf": true
              },
              {
                "key": "63391",
                "title": "63391 Ectp preg NOS w int preg",
                "threedigit": "633",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Spontaneous abortion",
            "title": "634 - Spontaneous abortion",
            "threedigit": "634",
            "children": [
              {
                "key": "63400",
                "title": "63400 Spon abor w pel inf-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63401",
                "title": "63401 Spon abor w pelv inf-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63402",
                "title": "63402 Spon abor w pel inf-comp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63410",
                "title": "63410 Spon abort w hemorr-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63411",
                "title": "63411 Spon abort w hemorr-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63412",
                "title": "63412 Spon abort w hemorr-comp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63420",
                "title": "63420 Spon ab w pel damag-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63421",
                "title": "63421 Spon ab w pelv damag-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63422",
                "title": "63422 Spon ab w pel damag-comp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63430",
                "title": "63430 Spon ab w ren fail-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63431",
                "title": "63431 Spon ab w ren fail-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63432",
                "title": "63432 Spon ab w ren fail-comp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63440",
                "title": "63440 Spon ab w metab dis-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63441",
                "title": "63441 Spon ab w metab dis-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63442",
                "title": "63442 Spon ab w metab dis-comp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63450",
                "title": "63450 Spon abort w shock-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63451",
                "title": "63451 Spon abort w shock-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63452",
                "title": "63452 Spon abort w shock-comp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63460",
                "title": "63460 Spon abort w embol-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63461",
                "title": "63461 Spon abort w embol-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63462",
                "title": "63462 Spon abort w embol-comp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63470",
                "title": "63470 Spon ab w compl NEC-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63471",
                "title": "63471 Spon ab w compl NEC-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63472",
                "title": "63472 Spon ab w compl NEC-comp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63480",
                "title": "63480 Spon ab w compl NOS-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63481",
                "title": "63481 Spon ab w compl NOS-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63482",
                "title": "63482 Spon ab w compl NOS-comp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63490",
                "title": "63490 Spon abort uncompl-unsp",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63491",
                "title": "63491 Spon abort uncompl-inc",
                "threedigit": "634",
                "isLeaf": true
              },
              {
                "key": "63492",
                "title": "63492 Spon abort uncompl-comp",
                "threedigit": "634",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Legally induced abortion",
            "title": "635 - Legally induced abortion",
            "threedigit": "635",
            "children": [
              {
                "key": "63500",
                "title": "63500 Leg abor w pelv inf-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63501",
                "title": "63501 Leg abor w pelv inf-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63502",
                "title": "63502 Leg abor w pelv inf-comp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63510",
                "title": "63510 Legal abor w hemorr-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63511",
                "title": "63511 Legal abort w hemorr-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63512",
                "title": "63512 Legal abor w hemorr-comp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63520",
                "title": "63520 Leg ab w pelv damag-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63521",
                "title": "63521 Leg ab w pelv damag-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63522",
                "title": "63522 Leg ab w pelv damag-comp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63530",
                "title": "63530 Leg abor w ren fail-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63531",
                "title": "63531 Leg abor w ren fail-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63532",
                "title": "63532 Leg abor w ren fail-comp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63540",
                "title": "63540 Leg ab w metab dis-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63541",
                "title": "63541 Leg ab w metab dis-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63542",
                "title": "63542 Leg ab w metab dis-comp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63550",
                "title": "63550 Legal abort w shock-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63551",
                "title": "63551 Legal abort w shock-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63552",
                "title": "63552 Legal abort w shock-comp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63560",
                "title": "63560 Legal abort w embol-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63561",
                "title": "63561 Legal abort w embol-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63562",
                "title": "63562 Legal abort w embol-comp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63570",
                "title": "63570 Leg ab w compl NEC-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63571",
                "title": "63571 Leg ab w compl NEC-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63572",
                "title": "63572 Leg ab w compl NEC-comp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63580",
                "title": "63580 Leg ab w compl NOS-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63581",
                "title": "63581 Leg ab w compl NOS-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63582",
                "title": "63582 Leg ab w compl NOS-comp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63590",
                "title": "63590 Legal abort uncompl-unsp",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63591",
                "title": "63591 Legal abort uncompl-inc",
                "threedigit": "635",
                "isLeaf": true
              },
              {
                "key": "63592",
                "title": "63592 Legal abort uncompl-comp",
                "threedigit": "635",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Illegal abortion",
            "title": "636 - Illegal abortion",
            "threedigit": "636",
            "children": [
              {
                "key": "63600",
                "title": "63600 Illeg ab w pelv inf-unsp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63601",
                "title": "63601 Illeg ab w pelv inf-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63602",
                "title": "63602 Illeg ab w pelv inf-comp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63610",
                "title": "63610 Illeg ab w hemorr-unspec",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63611",
                "title": "63611 Illeg ab w hemorr-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63612",
                "title": "63612 Illeg ab w hemorr-comp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63620",
                "title": "63620 Illeg ab w pel damg-unsp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63621",
                "title": "63621 Illeg ab w pel damag-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63622",
                "title": "63622 Illeg ab w pel damg-comp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63630",
                "title": "63630 Illeg ab w ren fail-unsp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63631",
                "title": "63631 Illeg ab w ren fail-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63632",
                "title": "63632 Illeg ab w ren fail-comp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63640",
                "title": "63640 Illeg ab w met dis-unsp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63641",
                "title": "63641 Illeg ab w metab dis-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63642",
                "title": "63642 Illeg ab w met dis-comp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63650",
                "title": "63650 Illeg abort w shock-unsp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63651",
                "title": "63651 Illeg abort w shock-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63652",
                "title": "63652 Illeg abort w shock-comp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63660",
                "title": "63660 Illeg ab w embolism-unsp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63661",
                "title": "63661 Illeg ab w embolism-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63662",
                "title": "63662 Illeg ab w embolism-comp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63670",
                "title": "63670 Illg ab w compl NEC-unsp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63671",
                "title": "63671 Illeg ab w compl NEC-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63672",
                "title": "63672 Illg ab w compl NEC-comp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63680",
                "title": "63680 Illg ab w compl NOS-unsp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63681",
                "title": "63681 Illeg ab w compl NOS-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63682",
                "title": "63682 Illg ab w compl NOS-comp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63690",
                "title": "63690 Illeg abort uncompl-unsp",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63691",
                "title": "63691 Illeg abort uncompl-inc",
                "threedigit": "636",
                "isLeaf": true
              },
              {
                "key": "63692",
                "title": "63692 Illeg abort uncompl-comp",
                "threedigit": "636",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Unspecified abortion",
            "title": "637 - Unspecified abortion",
            "threedigit": "637",
            "children": [
              {
                "key": "63700",
                "title": "63700 Abort NOS w pel inf-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63701",
                "title": "63701 Abort NOS w pel inf-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63702",
                "title": "63702 Abort NOS w pel inf-comp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63710",
                "title": "63710 Abort NOS w hemorr-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63711",
                "title": "63711 Abort NOS w hemorr-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63712",
                "title": "63712 Abort NOS w hemorr-comp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63720",
                "title": "63720 Ab NOS w pelv damag-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63721",
                "title": "63721 Ab NOS w pelv damag-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63722",
                "title": "63722 Ab NOS w pelv damag-comp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63730",
                "title": "63730 Ab NOS w renal fail-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63731",
                "title": "63731 Ab NOS w renal fail-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63732",
                "title": "63732 Ab NOS w renal fail-comp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63740",
                "title": "63740 Ab NOS w metab dis-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63741",
                "title": "63741 Ab NOS w metab dis-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63742",
                "title": "63742 Ab NOS w metab dis-comp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63750",
                "title": "63750 Abort NOS w shock-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63751",
                "title": "63751 Abort NOS w shock-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63752",
                "title": "63752 Abort NOS w shock-comp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63760",
                "title": "63760 Ab NOS w embolism-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63761",
                "title": "63761 Ab NOS w embolism-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63762",
                "title": "63762 Ab NOS w embolism-comp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63770",
                "title": "63770 Ab NOS w compl NEC-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63771",
                "title": "63771 Ab NOS w compl NEC-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63772",
                "title": "63772 Ab NOS w compl NEC-comp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63780",
                "title": "63780 Ab NOS w compl NOS-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63781",
                "title": "63781 Ab NOS w compl NOS-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63782",
                "title": "63782 Ab NOS w compl NOS-comp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63790",
                "title": "63790 Ab NOS uncomplicat-unsp",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63791",
                "title": "63791 Ab NOS uncomplicat-inc",
                "threedigit": "637",
                "isLeaf": true
              },
              {
                "key": "63792",
                "title": "63792 Ab NOS uncomplicat-comp",
                "threedigit": "637",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Failed attempted abortion",
            "title": "638 - Failed attempted abortion",
            "threedigit": "638",
            "children": [
              {
                "key": "6380",
                "title": "6380 Attem abort w pelvic inf",
                "threedigit": "638",
                "isLeaf": true
              },
              {
                "key": "6381",
                "title": "6381 Attem abort w hemorrhage",
                "threedigit": "638",
                "isLeaf": true
              },
              {
                "key": "6382",
                "title": "6382 Attem abort w pelv damag",
                "threedigit": "638",
                "isLeaf": true
              },
              {
                "key": "6383",
                "title": "6383 Attem abort w renal fail",
                "threedigit": "638",
                "isLeaf": true
              },
              {
                "key": "6384",
                "title": "6384 Attem abor w metabol dis",
                "threedigit": "638",
                "isLeaf": true
              },
              {
                "key": "6385",
                "title": "6385 Attem abortion w shock",
                "threedigit": "638",
                "isLeaf": true
              },
              {
                "key": "6386",
                "title": "6386 Attemp abort w embolism",
                "threedigit": "638",
                "isLeaf": true
              },
              {
                "key": "6387",
                "title": "6387 Attemp abort w compl NEC",
                "threedigit": "638",
                "isLeaf": true
              },
              {
                "key": "6388",
                "title": "6388 Attemp abort w compl NOS",
                "threedigit": "638",
                "isLeaf": true
              },
              {
                "key": "6389",
                "title": "6389 Attempted abort uncompl",
                "threedigit": "638",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Complications following abortion and ectopic and molar pregnancies",
            "title": "639 - Complications following abortion and ectopic and molar pregnancies",
            "threedigit": "639",
            "children": [
              {
                "key": "6390",
                "title": "6390 Postabortion gu infect",
                "threedigit": "639",
                "isLeaf": true
              },
              {
                "key": "6391",
                "title": "6391 Postabortion hemorrhage",
                "threedigit": "639",
                "isLeaf": true
              },
              {
                "key": "6392",
                "title": "6392 Postabort pelvic damage",
                "threedigit": "639",
                "isLeaf": true
              },
              {
                "key": "6393",
                "title": "6393 Postabort kidney failure",
                "threedigit": "639",
                "isLeaf": true
              },
              {
                "key": "6394",
                "title": "6394 Postabort metabolic dis",
                "threedigit": "639",
                "isLeaf": true
              },
              {
                "key": "6395",
                "title": "6395 Postabortion shock",
                "threedigit": "639",
                "isLeaf": true
              },
              {
                "key": "6396",
                "title": "6396 Postabortion embolism",
                "threedigit": "639",
                "isLeaf": true
              },
              {
                "key": "6398",
                "title": "6398 Postabortion compl NEC",
                "threedigit": "639",
                "isLeaf": true
              },
              {
                "key": "6399",
                "title": "6399 Postabortion compl NOS",
                "threedigit": "639",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Complications Mainly Related To Pregnancy",
        "title": "640-649 Complications Mainly Related To Pregnancy",
        "threedigit": "640",
        "children": [
          {
            "key": "Hemorrhage in early pregnancy",
            "title": "640 - Hemorrhage in early pregnancy",
            "threedigit": "640",
            "children": [
              {
                "key": "64000",
                "title": "64000 Threatened abort-unspec",
                "threedigit": "640",
                "isLeaf": true
              },
              {
                "key": "64001",
                "title": "64001 Threatened abort-deliver",
                "threedigit": "640",
                "isLeaf": true
              },
              {
                "key": "64003",
                "title": "64003 Threaten abort-antepart",
                "threedigit": "640",
                "isLeaf": true
              },
              {
                "key": "64080",
                "title": "64080 Hem early preg NEC-unsp",
                "threedigit": "640",
                "isLeaf": true
              },
              {
                "key": "64081",
                "title": "64081 Hem early preg NEC-deliv",
                "threedigit": "640",
                "isLeaf": true
              },
              {
                "key": "64083",
                "title": "64083 Hem early pg NEC-antepar",
                "threedigit": "640",
                "isLeaf": true
              },
              {
                "key": "64090",
                "title": "64090 Hemorr early preg-unspec",
                "threedigit": "640",
                "isLeaf": true
              },
              {
                "key": "64091",
                "title": "64091 Hem early preg-delivered",
                "threedigit": "640",
                "isLeaf": true
              },
              {
                "key": "64093",
                "title": "64093 Hem early preg-antepart",
                "threedigit": "640",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Antepartum hemorrhage abruptio placentae and placenta previa",
            "title": "641 - Antepartum hemorrhage abruptio placentae and placenta previa",
            "threedigit": "641",
            "children": [
              {
                "key": "64100",
                "title": "64100 Placenta previa-unspec",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64101",
                "title": "64101 Placenta previa-deliver",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64103",
                "title": "64103 Placenta previa-antepart",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64110",
                "title": "64110 Placenta prev hem-unspec",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64111",
                "title": "64111 Placenta prev hem-deliv",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64113",
                "title": "64113 Placen prev hem-antepart",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64120",
                "title": "64120 Prem separ placen-unspec",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64121",
                "title": "64121 Prem separ placen-deliv",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64123",
                "title": "64123 Prem separ plac-antepart",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64130",
                "title": "64130 Coag def hemorr-unspec",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64131",
                "title": "64131 Coag def hemorr-deliver",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64133",
                "title": "64133 Coag def hemorr-antepart",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64180",
                "title": "64180 Antepart hem NEC-unspec",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64181",
                "title": "64181 Antepartum hem NEC-deliv",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64183",
                "title": "64183 Antepart hem NEC-antepar",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64190",
                "title": "64190 Antepart hem NOS-unspec",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64191",
                "title": "64191 Antepartum hem NOS-deliv",
                "threedigit": "641",
                "isLeaf": true
              },
              {
                "key": "64193",
                "title": "64193 Antepart hem NOS-antepar",
                "threedigit": "641",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hypertension complicating pregnancy childbirth and the puerperium",
            "title": "642 - Hypertension complicating pregnancy childbirth and the puerperium",
            "threedigit": "642",
            "children": [
              {
                "key": "64200",
                "title": "64200 Essen hyperten preg-unsp",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64201",
                "title": "64201 Essen hyperten-delivered",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64202",
                "title": "64202 Essen hyperten-del w p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64203",
                "title": "64203 Essen hyperten-antepart",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64204",
                "title": "64204 Essen hyperten-postpart",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64210",
                "title": "64210 Renal hyperten preg-unsp",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64211",
                "title": "64211 Renal hyperten pg-deliv",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64212",
                "title": "64212 Renal hyperten-del p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64213",
                "title": "64213 Renal hyperten-antepart",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64214",
                "title": "64214 Renal hyperten-postpart",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64220",
                "title": "64220 Old hyperten preg-unspec",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64221",
                "title": "64221 Old hyperten NEC-deliver",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64222",
                "title": "64222 Old hyperten-deliv w p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64223",
                "title": "64223 Old hyperten NEC-antepar",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64224",
                "title": "64224 Old hyperten NEC-postpar",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64230",
                "title": "64230 Trans hyperten preg-unsp",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64231",
                "title": "64231 Trans hyperten-delivered",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64232",
                "title": "64232 Trans hyperten-del w p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64233",
                "title": "64233 Trans hyperten-antepart",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64234",
                "title": "64234 Trans hyperten-postpart",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64240",
                "title": "64240 Mild/NOS preeclamp-unsp",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64241",
                "title": "64241 Mild/NOS preeclamp-deliv",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64242",
                "title": "64242 Mild preeclamp-del w p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64243",
                "title": "64243 Mild/NOS preeclamp-antep",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64244",
                "title": "64244 Mild/NOS preeclamp-p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64250",
                "title": "64250 Severe preeclamp-unspec",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64251",
                "title": "64251 Severe preeclamp-deliver",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64252",
                "title": "64252 Sev preeclamp-del w p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64253",
                "title": "64253 Sev preeclamp-antepartum",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64254",
                "title": "64254 Sev preeclamp-postpartum",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64260",
                "title": "64260 Eclampsia-unspecified",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64261",
                "title": "64261 Eclampsia-delivered",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64262",
                "title": "64262 Eclampsia-deliv w p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64263",
                "title": "64263 Eclampsia-antepartum",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64264",
                "title": "64264 Eclampsia-postpartum",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64270",
                "title": "64270 Tox w old hyperten-unsp",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64271",
                "title": "64271 Tox w old hyperten-deliv",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64272",
                "title": "64272 Tox w old hyp-del w p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64273",
                "title": "64273 Tox w old hyper-antepart",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64274",
                "title": "64274 Tox w old hyper-postpart",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64290",
                "title": "64290 Hyperten preg NOS-unspec",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64291",
                "title": "64291 Hypertens NOS-delivered",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64292",
                "title": "64292 Hypertens NOS-del w p/p",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64293",
                "title": "64293 Hypertens NOS-antepartum",
                "threedigit": "642",
                "isLeaf": true
              },
              {
                "key": "64294",
                "title": "64294 Hypertens NOS-postpartum",
                "threedigit": "642",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Excessive vomiting in pregnancy",
            "title": "643 - Excessive vomiting in pregnancy",
            "threedigit": "643",
            "children": [
              {
                "key": "64300",
                "title": "64300 Mild hyperem grav-unspec",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64301",
                "title": "64301 Mild hyperem grav-deliv",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64303",
                "title": "64303 Mild hyperemesis-antepar",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64310",
                "title": "64310 Hyperem w metab dis-unsp",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64311",
                "title": "64311 Hyperem w metab dis-del",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64313",
                "title": "64313 Hyperem w metab-antepart",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64320",
                "title": "64320 Late vomit of preg-unsp",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64321",
                "title": "64321 Late vomit of preg-deliv",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64323",
                "title": "64323 Late vomit preg-antepart",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64380",
                "title": "64380 Vomit compl preg-unspec",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64381",
                "title": "64381 Vomit compl preg-deliver",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64383",
                "title": "64383 Vomit compl preg-antepar",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64390",
                "title": "64390 Vomit of preg NOS-unspec",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64391",
                "title": "64391 Vomit of preg NOS-deliv",
                "threedigit": "643",
                "isLeaf": true
              },
              {
                "key": "64393",
                "title": "64393 Vomit of pg NOS-antepart",
                "threedigit": "643",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Early or threatened labor",
            "title": "644 - Early or threatened labor",
            "threedigit": "644",
            "children": [
              {
                "key": "64400",
                "title": "64400 Threat prem labor-unspec",
                "threedigit": "644",
                "isLeaf": true
              },
              {
                "key": "64403",
                "title": "64403 Thrt prem labor-antepart",
                "threedigit": "644",
                "isLeaf": true
              },
              {
                "key": "64410",
                "title": "64410 Threat labor NEC-unspec",
                "threedigit": "644",
                "isLeaf": true
              },
              {
                "key": "64413",
                "title": "64413 Threat labor NEC-antepar",
                "threedigit": "644",
                "isLeaf": true
              },
              {
                "key": "64420",
                "title": "64420 Early onset deliv-unspec",
                "threedigit": "644",
                "isLeaf": true
              },
              {
                "key": "64421",
                "title": "64421 Early onset delivery-del",
                "threedigit": "644",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late pregnancy",
            "title": "645 - Late pregnancy",
            "threedigit": "645",
            "children": [
              {
                "key": "64510",
                "title": "64510 Post term preg-unsp",
                "threedigit": "645",
                "isLeaf": true
              },
              {
                "key": "64511",
                "title": "64511 Post term preg-del",
                "threedigit": "645",
                "isLeaf": true
              },
              {
                "key": "64513",
                "title": "64513 Post term preg-antepar",
                "threedigit": "645",
                "isLeaf": true
              },
              {
                "key": "64520",
                "title": "64520 Prolonged preg-unsp",
                "threedigit": "645",
                "isLeaf": true
              },
              {
                "key": "64521",
                "title": "64521 Prolonged preg-del",
                "threedigit": "645",
                "isLeaf": true
              },
              {
                "key": "64523",
                "title": "64523 Prolonged preg-antepar",
                "threedigit": "645",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other complications of pregnancy not elsewhere classified",
            "title": "646 - Other complications of pregnancy not elsewhere classified",
            "threedigit": "646",
            "children": [
              {
                "key": "64600",
                "title": "64600 Papyraceous fetus-unspec",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64601",
                "title": "64601 Papyraceous fetus-deliv",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64603",
                "title": "64603 Papyraceous fet-antepar",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64610",
                "title": "64610 Edema in preg-unspec",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64611",
                "title": "64611 Edema in preg-delivered",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64612",
                "title": "64612 Edema in preg-del w p/p",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64613",
                "title": "64613 Edema in preg-antepartum",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64614",
                "title": "64614 Edema in preg-postpartum",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64620",
                "title": "64620 Renal dis preg NOS-unsp",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64621",
                "title": "64621 Renal dis NOS-delivered",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64622",
                "title": "64622 Renal dis NOS-del w p/p",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64623",
                "title": "64623 Renal dis NOS-antepartum",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64624",
                "title": "64624 Renal dis NOS-postpartum",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64630",
                "title": "64630 Recur preg loss-unspec",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64631",
                "title": "64631 Recurnt preg loss-deliv",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64633",
                "title": "64633 Recurnt preg loss-antep",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64640",
                "title": "64640 Neuritis of preg-unspec",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64641",
                "title": "64641 Neuritis-delivered",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64642",
                "title": "64642 Neuritis-delivered w p/p",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64643",
                "title": "64643 Neuritis of preg-antepar",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64644",
                "title": "64644 Neuritis of preg-postpar",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64650",
                "title": "64650 Bacteriuria preg-unspec",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64651",
                "title": "64651 Asym bacteriuria-deliver",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64652",
                "title": "64652 Asy bacteruria-del w p/p",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64653",
                "title": "64653 Asy bacteriuria-antepart",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64654",
                "title": "64654 Asy bacteriuria-postpart",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64660",
                "title": "64660 Gu infect in preg-unspec",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64661",
                "title": "64661 Gu infection-delivered",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64662",
                "title": "64662 Gu infection-deliv w p/p",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64663",
                "title": "64663 Gu infection-antepartum",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64664",
                "title": "64664 Gu infection-postpartum",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64670",
                "title": "64670 Liver/bil trct disr-unsp",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64671",
                "title": "64671 Liver/bil trct disr-del",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64673",
                "title": "64673 Liver/bil trct disr-ante",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64680",
                "title": "64680 Preg compl NEC-unspec",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64681",
                "title": "64681 Preg compl NEC-delivered",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64682",
                "title": "64682 Preg compl NEC-del w p/p",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64683",
                "title": "64683 Preg compl NEC-antepart",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64684",
                "title": "64684 Preg compl NEC-postpart",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64690",
                "title": "64690 Preg compl NOS-unspec",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64691",
                "title": "64691 Preg compl NOS-delivered",
                "threedigit": "646",
                "isLeaf": true
              },
              {
                "key": "64693",
                "title": "64693 Preg compl NOS-antepart",
                "threedigit": "646",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Infectious and parasitic conditions in the mother classifiable elsewhere but complicating pregnancy childbirth or the puerperium",
            "title": "647 - Infectious and parasitic conditions in the mother classifiable elsewhere but complicating pregnancy childbirth or the puerperium",
            "threedigit": "647",
            "children": [
              {
                "key": "64700",
                "title": "64700 Syphilis in preg-unspec",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64701",
                "title": "64701 Syphilis-delivered",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64702",
                "title": "64702 Syphilis-delivered w p/p",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64703",
                "title": "64703 Syphilis-antepartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64704",
                "title": "64704 Syphilis-postpartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64710",
                "title": "64710 Gonorrhea in preg-unspec",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64711",
                "title": "64711 Gonorrhea-delivered",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64712",
                "title": "64712 Gonorrhea-deliver w p/p",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64713",
                "title": "64713 Gonorrhea-antepartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64714",
                "title": "64714 Gonorrhea-postpartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64720",
                "title": "64720 Other VD in preg-unspec",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64721",
                "title": "64721 Other vd-delivered",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64722",
                "title": "64722 Other vd-delivered w p/p",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64723",
                "title": "64723 Other vd-antepartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64724",
                "title": "64724 Other vd-postpartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64730",
                "title": "64730 TB in preg-unspecified",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64731",
                "title": "64731 Tuberculosis-delivered",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64732",
                "title": "64732 Tuberculosis-deliv w p/p",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64733",
                "title": "64733 Tuberculosis-antepartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64734",
                "title": "64734 Tuberculosis-postpartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64740",
                "title": "64740 Malaria in preg-unspec",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64741",
                "title": "64741 Malaria-delivered",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64742",
                "title": "64742 Malaria-delivered w p/p",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64743",
                "title": "64743 Malaria-antepartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64744",
                "title": "64744 Malaria-postpartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64750",
                "title": "64750 Rubella in preg-unspec",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64751",
                "title": "64751 Rubella-delivered",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64752",
                "title": "64752 Rubella-delivered w p/p",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64753",
                "title": "64753 Rubella-antepartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64754",
                "title": "64754 Rubella-postpartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64760",
                "title": "64760 Oth virus in preg-unspec",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64761",
                "title": "64761 Oth viral dis-delivered",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64762",
                "title": "64762 Oth viral dis-del w p/p",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64763",
                "title": "64763 Oth viral dis-antepartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64764",
                "title": "64764 Oth viral dis-postpartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64780",
                "title": "64780 Inf dis in preg NEC-unsp",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64781",
                "title": "64781 Infect dis NEC-delivered",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64782",
                "title": "64782 Infect dis NEC-del w p/p",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64783",
                "title": "64783 Infect dis NEC-antepart",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64784",
                "title": "64784 Infect dis NEC-postpart",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64790",
                "title": "64790 Infect in preg NOS-unsp",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64791",
                "title": "64791 Infect NOS-delivered",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64792",
                "title": "64792 Infect NOS-deliver w p/p",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64793",
                "title": "64793 Infect NOS-antepartum",
                "threedigit": "647",
                "isLeaf": true
              },
              {
                "key": "64794",
                "title": "64794 Infect NOS-postpartum",
                "threedigit": "647",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other current conditions in the mother classifiable elsewhere but complicating pregnancy childbirth or the puerperium",
            "title": "648 - Other current conditions in the mother classifiable elsewhere but complicating pregnancy childbirth or the puerperium",
            "threedigit": "648",
            "children": [
              {
                "key": "64800",
                "title": "64800 Diabetes in preg-unspec",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64801",
                "title": "64801 Diabetes-delivered",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64802",
                "title": "64802 Diabetes-delivered w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64803",
                "title": "64803 Diabetes-antepartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64804",
                "title": "64804 Diabetes-postpartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64810",
                "title": "64810 Thyroid dysfun preg-unsp",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64811",
                "title": "64811 Thyroid dysfunc-deliver",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64812",
                "title": "64812 Thyroid dysfun-del w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64813",
                "title": "64813 Thyroid dysfunc-antepart",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64814",
                "title": "64814 Thyroid dysfunc-postpart",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64820",
                "title": "64820 Anemia in preg-unspec",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64821",
                "title": "64821 Anemia-delivered",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64822",
                "title": "64822 Anemia-delivered w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64823",
                "title": "64823 Anemia-antepartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64824",
                "title": "64824 Anemia-postpartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64830",
                "title": "64830 Drug depend preg-unspec",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64831",
                "title": "64831 Drug dependence-deliver",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64832",
                "title": "64832 Drug dependen-del w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64833",
                "title": "64833 Drug dependence-antepart",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64834",
                "title": "64834 Drug dependence-postpart",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64840",
                "title": "64840 Mental dis preg-unspec",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64841",
                "title": "64841 Mental disorder-deliver",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64842",
                "title": "64842 Mental dis-deliv w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64843",
                "title": "64843 Mental disorder-antepart",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64844",
                "title": "64844 Mental disorder-postpart",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64850",
                "title": "64850 Congen CV dis preg-unsp",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64851",
                "title": "64851 Congen CV dis-delivered",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64852",
                "title": "64852 Congen CV dis-del w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64853",
                "title": "64853 Congen CV dis-antepartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64854",
                "title": "64854 Congen CV dis-postpartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64860",
                "title": "64860 CV dis NEC preg-unspec",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64861",
                "title": "64861 CV dis NEC preg-deliver",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64862",
                "title": "64862 CV dis NEC-deliver w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64863",
                "title": "64863 CV dis NEC-antepartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64864",
                "title": "64864 CV dis NEC-postpartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64870",
                "title": "64870 Bone disord in preg-unsp",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64871",
                "title": "64871 Bone disorder-delivered",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64872",
                "title": "64872 Bone disorder-del w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64873",
                "title": "64873 Bone disorder-antepartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64874",
                "title": "64874 Bone disorder-postpartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64880",
                "title": "64880 Abn glucose in preg-unsp",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64881",
                "title": "64881 Abn glucose toler-deliv",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64882",
                "title": "64882 Abn glucose-deliv w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64883",
                "title": "64883 Abn glucose-antepartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64884",
                "title": "64884 Abn glucose-postpartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64890",
                "title": "64890 Oth curr cond preg-unsp",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64891",
                "title": "64891 Oth curr cond-delivered",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64892",
                "title": "64892 Oth curr cond-del w p/p",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64893",
                "title": "64893 Oth curr cond-antepartum",
                "threedigit": "648",
                "isLeaf": true
              },
              {
                "key": "64894",
                "title": "64894 Oth curr cond-postpartum",
                "threedigit": "648",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other conditions or status of the mother complicating pregnancy, childbirth, or the puerperium",
            "title": "649 - Other conditions or status of the mother complicating pregnancy, childbirth, or the puerperium",
            "threedigit": "649",
            "children": [
              {
                "key": "64900",
                "title": "64900 Tobacco use disord-unsp",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64901",
                "title": "64901 Tobacco use disor-delliv",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64902",
                "title": "64902 Tobacco use dis-del-p/p",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64903",
                "title": "64903 Tobacco use dis-antepart",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64904",
                "title": "64904 Tobacco use dis-postpart",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64910",
                "title": "64910 Obesity-unspecified",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64911",
                "title": "64911 Obesity-delivered",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64912",
                "title": "64912 Obesity-delivered w p/p",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64913",
                "title": "64913 Obesity-antepartum",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64914",
                "title": "64914 Obesity-postpartum",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64920",
                "title": "64920 Bariatric surg stat-unsp",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64921",
                "title": "64921 Bariatric surg stat-del",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64922",
                "title": "64922 Bariatric surg-del w p/p",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64923",
                "title": "64923 Bariatrc surg stat-antep",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64924",
                "title": "64924 Bariatrc surg stat w p/p",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64930",
                "title": "64930 Coagulation def-unspec",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64931",
                "title": "64931 Coagulation def-deliv",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64932",
                "title": "64932 Coagulatn def-del w p/p",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64933",
                "title": "64933 Coagulation def-antepart",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64934",
                "title": "64934 Coagulation def-postpart",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64940",
                "title": "64940 Epilepsy-unspecified",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64941",
                "title": "64941 Epilepsy-delivered",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64942",
                "title": "64942 Epilepsy-delivered w p/p",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64943",
                "title": "64943 Epilepsy-antepartum",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64944",
                "title": "64944 Epilepsy-postpartum",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64950",
                "title": "64950 Spotting-unspecified",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64951",
                "title": "64951 Spotting-delivered",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64953",
                "title": "64953 Spotting-antepartum",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64960",
                "title": "64960 Uterine size descrp-unsp",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64961",
                "title": "64961 Uterine size descrep-del",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64962",
                "title": "64962 Uterine size-del w p/p",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64963",
                "title": "64963 Uterine size des-antepar",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64964",
                "title": "64964 Uterine size descrep-p/p",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64970",
                "title": "64970 Cervical shortening-unsp",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64971",
                "title": "64971 Cervical shortening-del",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64973",
                "title": "64973 Cervical shortening-ante",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64981",
                "title": "64981 Spon labr w plan C/S-del",
                "threedigit": "649",
                "isLeaf": true
              },
              {
                "key": "64982",
                "title": "64982 Lbr w plan C/S-del w p/p",
                "threedigit": "649",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Normal Delivery, And Other Indications For Care In Pregnancy, Labor, And Delivery",
        "title": "650-659 Normal Delivery, And Other Indications For Care In Pregnancy, Labor, And Delivery",
        "threedigit": "650",
        "children": [
          {
            "key": "Normal delivery",
            "title": "650 - Normal delivery",
            "threedigit": "650",
            "children": [
              {
                "key": "650",
                "title": "650 Normal delivery",
                "threedigit": "650",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Multiple gestation",
            "title": "651 - Multiple gestation",
            "threedigit": "651",
            "children": [
              {
                "key": "65100",
                "title": "65100 Twin pregnancy-unspec",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65101",
                "title": "65101 Twin pregnancy-delivered",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65103",
                "title": "65103 Twin pregnancy-antepart",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65110",
                "title": "65110 Triplet pregnancy-unspec",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65111",
                "title": "65111 Triplet pregnancy-deliv",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65113",
                "title": "65113 Triplet preg-antepartum",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65120",
                "title": "65120 Quadruplet preg-unspec",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65121",
                "title": "65121 Quadruplet preg-deliver",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65123",
                "title": "65123 Quadruplet preg-antepart",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65130",
                "title": "65130 Twins w fetal loss-unsp",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65131",
                "title": "65131 Twins w fetal loss-del",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65133",
                "title": "65133 Twins w fetal loss-ante",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65140",
                "title": "65140 Triplets w fet loss-unsp",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65141",
                "title": "65141 Triplets w fet loss-del",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65143",
                "title": "65143 Triplets w fet loss-ante",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65150",
                "title": "65150 Quads w fetal loss-unsp",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65151",
                "title": "65151 Quads w fetal loss-del",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65153",
                "title": "65153 Quads w fetal loss-ante",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65160",
                "title": "65160 Mult ges w fet loss-unsp",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65161",
                "title": "65161 Mult ges w fet loss-del",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65163",
                "title": "65163 Mult ges w fet loss-ante",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65170",
                "title": "65170 Mul gest-fet reduct unsp",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65171",
                "title": "65171 Mult gest-fet reduct del",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65173",
                "title": "65173 Mul gest-fet reduct ante",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65180",
                "title": "65180 Multi gestat NEC-unspec",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65181",
                "title": "65181 Multi gestat NEC-deliver",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65183",
                "title": "65183 Multi gest NEC-antepart",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65190",
                "title": "65190 Multi gestat NOS-unspec",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65191",
                "title": "65191 Mult gestation NOS-deliv",
                "threedigit": "651",
                "isLeaf": true
              },
              {
                "key": "65193",
                "title": "65193 Multi gest NOS-antepart",
                "threedigit": "651",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Malposition and malpresentation of fetus",
            "title": "652 - Malposition and malpresentation of fetus",
            "threedigit": "652",
            "children": [
              {
                "key": "65200",
                "title": "65200 Unstable lie-unspecified",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65201",
                "title": "65201 Unstable lie-delivered",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65203",
                "title": "65203 Unstable lie-antepartum",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65210",
                "title": "65210 Cephalic vers NOS-unspec",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65211",
                "title": "65211 Cephalic vers NOS-deliv",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65213",
                "title": "65213 Cephal vers NOS-antepart",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65220",
                "title": "65220 Breech presentat-unspec",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65221",
                "title": "65221 Breech presentat-deliver",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65223",
                "title": "65223 Breech present-antepart",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65230",
                "title": "65230 Transv/obliq lie-unspec",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65231",
                "title": "65231 Transver/obliq lie-deliv",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65233",
                "title": "65233 Transv/obliq lie-antepar",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65240",
                "title": "65240 Face/brow present-unspec",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65241",
                "title": "65241 Face/brow present-deliv",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65243",
                "title": "65243 Face/brow pres-antepart",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65250",
                "title": "65250 High head at term-unspec",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65251",
                "title": "65251 High head at term-deliv",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65253",
                "title": "65253 High head term-antepart",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65260",
                "title": "65260 Mult gest malpresen-unsp",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65261",
                "title": "65261 Mult gest malpres-deliv",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65263",
                "title": "65263 Mult ges malpres-antepar",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65270",
                "title": "65270 Prolapsed arm-unspec",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65271",
                "title": "65271 Prolapsed arm-delivered",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65273",
                "title": "65273 Prolapsed arm-antepart",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65280",
                "title": "65280 Malposition NEC-unspec",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65281",
                "title": "65281 Malposition NEC-deliver",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65283",
                "title": "65283 Malposition NEC-antepart",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65290",
                "title": "65290 Malposition NOS-unspec",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65291",
                "title": "65291 Malposition NOS-deliver",
                "threedigit": "652",
                "isLeaf": true
              },
              {
                "key": "65293",
                "title": "65293 Malposition NOS-antepart",
                "threedigit": "652",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disproportion in pregnancy labor and delivery",
            "title": "653 - Disproportion in pregnancy labor and delivery",
            "threedigit": "653",
            "children": [
              {
                "key": "65300",
                "title": "65300 Pelvic deform NOS-unspec",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65301",
                "title": "65301 Pelvic deform NOS-deliv",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65303",
                "title": "65303 Pelv deform NOS-antepart",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65310",
                "title": "65310 Contract pelv NOS-unspec",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65311",
                "title": "65311 Contract pelv NOS-deliv",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65313",
                "title": "65313 Contrac pelv NOS-antepar",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65320",
                "title": "65320 Inlet contraction-unspec",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65321",
                "title": "65321 Inlet contraction-deliv",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65323",
                "title": "65323 Inlet contract-antepart",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65330",
                "title": "65330 Outlet contraction-unsp",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65331",
                "title": "65331 Outlet contraction-deliv",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65333",
                "title": "65333 Outlet contract-antepart",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65340",
                "title": "65340 Fetopelv disprop-unspec",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65341",
                "title": "65341 Fetopelv dispropor-deliv",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65343",
                "title": "65343 Fetopel disprop-antepart",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65350",
                "title": "65350 Fetal disprop NOS-unspec",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65351",
                "title": "65351 Fetal disprop NOS-deliv",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65353",
                "title": "65353 Fetal dispro NOS-antepar",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65360",
                "title": "65360 Hydrocephal fetus-unspec",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65361",
                "title": "65361 Hydroceph fetus-deliver",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65363",
                "title": "65363 Hydroceph fetus-antepart",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65370",
                "title": "65370 Oth abn fet disprop-unsp",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65371",
                "title": "65371 Oth abn fet dispro-deliv",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65373",
                "title": "65373 Oth abn fet dispro-antep",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65380",
                "title": "65380 Disproportion NEC-unspec",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65381",
                "title": "65381 Disproportion NEC-deliv",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65383",
                "title": "65383 Dispropor NEC-antepartum",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65390",
                "title": "65390 Disproportion NOS-unspec",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65391",
                "title": "65391 Disproportion NOS-deliv",
                "threedigit": "653",
                "isLeaf": true
              },
              {
                "key": "65393",
                "title": "65393 Dispropor NOS-antepartum",
                "threedigit": "653",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Abnormality of organs and soft tissues of pelvis",
            "title": "654 - Abnormality of organs and soft tissues of pelvis",
            "threedigit": "654",
            "children": [
              {
                "key": "65400",
                "title": "65400 Cong abn uter preg-unsp",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65401",
                "title": "65401 Congen abn uterus-deliv",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65402",
                "title": "65402 Cong abn uter-del w p/p",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65403",
                "title": "65403 Congen abn uter-antepart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65404",
                "title": "65404 Congen abn uter-postpart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65410",
                "title": "65410 Uter tumor in preg-unsp",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65411",
                "title": "65411 Uterine tumor-delivered",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65412",
                "title": "65412 Uterine tumor-del w p/p",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65413",
                "title": "65413 Uterine tumor-antepartum",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65414",
                "title": "65414 Uterine tumor-postpartum",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65420",
                "title": "65420 Prev c-delivery unspec",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65421",
                "title": "65421 Prev c-delivery-delivrd",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65423",
                "title": "65423 Prev c-delivery-antepart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65430",
                "title": "65430 Retrovert uterus-unspec",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65431",
                "title": "65431 Retrovert uterus-deliver",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65432",
                "title": "65432 Retrovert uter-del w p/p",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65433",
                "title": "65433 Retrovert uter-antepart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65434",
                "title": "65434 Retrovert uter-postpart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65440",
                "title": "65440 Abn grav uterus NEC-unsp",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65441",
                "title": "65441 Abn uterus NEC-delivered",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65442",
                "title": "65442 Abn uterus NEC-del w p/p",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65443",
                "title": "65443 Abn uterus NEC-antepart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65444",
                "title": "65444 Abn uterus NEC-postpart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65450",
                "title": "65450 Cerv incompet preg-unsp",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65451",
                "title": "65451 Cervical incompet-deliv",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65452",
                "title": "65452 Cerv incompet-del w p/p",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65453",
                "title": "65453 Cerv incompet-antepartum",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65454",
                "title": "65454 Cerv incompet-postpartum",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65460",
                "title": "65460 Abn cervix NEC preg-unsp",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65461",
                "title": "65461 Abn cervix NEC-delivered",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65462",
                "title": "65462 Abn cervix NEC-del w p/p",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65463",
                "title": "65463 Abn cervix NEC-antepart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65464",
                "title": "65464 Abn cervix NEC-postpart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65470",
                "title": "65470 Abn vagina in preg-unsp",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65471",
                "title": "65471 Abnorm vagina-delivered",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65472",
                "title": "65472 Abnorm vagina-del w p/p",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65473",
                "title": "65473 Abnorm vagina-antepartum",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65474",
                "title": "65474 Abnorm vagina-postpartum",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65480",
                "title": "65480 Abn vulva in preg-unspec",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65481",
                "title": "65481 Abnormal vulva-delivered",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65482",
                "title": "65482 Abnormal vulva-del w p/p",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65483",
                "title": "65483 Abnormal vulva-antepart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65484",
                "title": "65484 Abnormal vulva-postpart",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65490",
                "title": "65490 Abn pel NEC in preg-unsp",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65491",
                "title": "65491 Abn pelv org NEC-deliver",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65492",
                "title": "65492 Abn pelv NEC-deliv w p/p",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65493",
                "title": "65493 Abn pelv org NEC-antepar",
                "threedigit": "654",
                "isLeaf": true
              },
              {
                "key": "65494",
                "title": "65494 Abn pelv org NEC-postpar",
                "threedigit": "654",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Known or suspected fetal abnormality affecting management of mother",
            "title": "655 - Known or suspected fetal abnormality affecting management of mother",
            "threedigit": "655",
            "children": [
              {
                "key": "65500",
                "title": "65500 Fetal cns malform-unspec",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65501",
                "title": "65501 Fetal cns malform-deliv",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65503",
                "title": "65503 Fetal cns malfor-antepar",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65510",
                "title": "65510 Fetal chromos abn-unspec",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65511",
                "title": "65511 Fetal chromoso abn-deliv",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65513",
                "title": "65513 Fet chromo abn-antepart",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65520",
                "title": "65520 Famil heredit dis-unspec",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65521",
                "title": "65521 Famil heredit dis-deliv",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65523",
                "title": "65523 Famil hered dis-antepart",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65530",
                "title": "65530 Fet damg d/t virus-unsp",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65531",
                "title": "65531 Fet damg d/t virus-deliv",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65533",
                "title": "65533 Fet damg d/t virus-antep",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65540",
                "title": "65540 Fet damg d/t dis-unspec",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65541",
                "title": "65541 Fet damg d/t dis-deliver",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65543",
                "title": "65543 Fet damg d/t dis-antepar",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65550",
                "title": "65550 Fetal damg d/t drug-unsp",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65551",
                "title": "65551 Fet damag d/t drug-deliv",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65553",
                "title": "65553 Fet damg d/t drug-antepa",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65560",
                "title": "65560 Radiat fetal damag-unsp",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65561",
                "title": "65561 Radiat fetal damag-deliv",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65563",
                "title": "65563 Radiat fet damag-antepar",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65570",
                "title": "65570 Decrease fetl movmt unsp",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65571",
                "title": "65571 Decrease fetal movmt del",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65573",
                "title": "65573 Dec fetal movmt antepart",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65580",
                "title": "65580 Fetal abnorm NEC-unspec",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65581",
                "title": "65581 Fetal abnorm NEC-deliver",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65583",
                "title": "65583 Fetal abnorm NEC-antepar",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65590",
                "title": "65590 Fetal abnorm NOS-unspec",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65591",
                "title": "65591 Fetal abnorm NOS-deliver",
                "threedigit": "655",
                "isLeaf": true
              },
              {
                "key": "65593",
                "title": "65593 Fetal abnorm NOS-antepar",
                "threedigit": "655",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other known or suspected fetal and placental problems affecting management of mother",
            "title": "656 - Other known or suspected fetal and placental problems affecting management of mother",
            "threedigit": "656",
            "children": [
              {
                "key": "65600",
                "title": "65600 Fetal-maternal hem-unsp",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65601",
                "title": "65601 Fetal-maternal hem-deliv",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65603",
                "title": "65603 Fetal-matern hem-antepar",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65610",
                "title": "65610 Rh isoimmunization-unsp",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65611",
                "title": "65611 Rh isoimmunizat-deliver",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65613",
                "title": "65613 Rh isoimmunizat-antepart",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65620",
                "title": "65620 Abo isoimmunization-unsp",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65621",
                "title": "65621 Abo isoimmunizat-deliver",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65623",
                "title": "65623 Abo isoimmunizat-antepar",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65630",
                "title": "65630 Fetal distress-unspec",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65631",
                "title": "65631 Fetal distress-delivered",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65633",
                "title": "65633 Fetal distress-antepart",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65640",
                "title": "65640 Intrauterine death-unsp",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65641",
                "title": "65641 Intrauter death-deliver",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65643",
                "title": "65643 Intrauter death-antepart",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65650",
                "title": "65650 Poor fetal growth-unspec",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65651",
                "title": "65651 Poor fetal growth-deliv",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65653",
                "title": "65653 Poor fetal grth-antepart",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65660",
                "title": "65660 Excess fetal grth-unspec",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65661",
                "title": "65661 Excess fetal grth-deliv",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65663",
                "title": "65663 Excess fet grth-antepart",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65670",
                "title": "65670 Oth placent cond-unspec",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65671",
                "title": "65671 Oth placent cond-deliver",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65673",
                "title": "65673 Oth placent cond-antepar",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65680",
                "title": "65680 Fet/plac prob NEC-unspec",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65681",
                "title": "65681 Fet/plac prob NEC-deliv",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65683",
                "title": "65683 Fet/plac prob NEC-antepa",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65690",
                "title": "65690 Fet/plac prob NOS-unspec",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65691",
                "title": "65691 Fet/plac prob NOS-deliv",
                "threedigit": "656",
                "isLeaf": true
              },
              {
                "key": "65693",
                "title": "65693 Fet/plac prob NOS-antepa",
                "threedigit": "656",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Polyhydramnios",
            "title": "657 - Polyhydramnios",
            "threedigit": "657",
            "children": [
              {
                "key": "65700",
                "title": "65700 Polyhydramnios-unspec",
                "threedigit": "657",
                "isLeaf": true
              },
              {
                "key": "65701",
                "title": "65701 Polyhydramnios-delivered",
                "threedigit": "657",
                "isLeaf": true
              },
              {
                "key": "65703",
                "title": "65703 Polyhydramnios-antepart",
                "threedigit": "657",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other problems associated with amniotic cavity and membranes",
            "title": "658 - Other problems associated with amniotic cavity and membranes",
            "threedigit": "658",
            "children": [
              {
                "key": "65800",
                "title": "65800 Oligohydramnios-unspec",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65801",
                "title": "65801 Oligohydramnios-deliver",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65803",
                "title": "65803 Oligohydramnios-antepar",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65810",
                "title": "65810 Prem rupt membran-unspec",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65811",
                "title": "65811 Prem rupt membran-deliv",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65813",
                "title": "65813 Prem rupt memb-antepart",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65820",
                "title": "65820 Prolong rupt memb-unspec",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65821",
                "title": "65821 Prolong rupt memb-deliv",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65823",
                "title": "65823 Prolong rup memb-antepar",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65830",
                "title": "65830 Artific rupt membr-unsp",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65831",
                "title": "65831 Artific rupt membr-deliv",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65833",
                "title": "65833 Artif rupt memb-antepart",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65840",
                "title": "65840 Amniotic infection-unsp",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65841",
                "title": "65841 Amniotic infection-deliv",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65843",
                "title": "65843 Amniotic infect-antepart",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65880",
                "title": "65880 Amniotic prob NEC-unspec",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65881",
                "title": "65881 Amniotic prob NEC-deliv",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65883",
                "title": "65883 Amnion prob NEC-antepart",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65890",
                "title": "65890 Amniotic prob NOS-unspec",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65891",
                "title": "65891 Amniotic prob NOS-deliv",
                "threedigit": "658",
                "isLeaf": true
              },
              {
                "key": "65893",
                "title": "65893 Amnion prob NOS-antepart",
                "threedigit": "658",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other indications for care or intervention related to labor and delivery not elsewhere classified",
            "title": "659 - Other indications for care or intervention related to labor and delivery not elsewhere classified",
            "threedigit": "659",
            "children": [
              {
                "key": "65900",
                "title": "65900 Fail mechan induct-unsp",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65901",
                "title": "65901 Fail mech induct-deliver",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65903",
                "title": "65903 Fail mech induct-antepar",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65910",
                "title": "65910 Fail induction NOS-unsp",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65911",
                "title": "65911 Fail induction NOS-deliv",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65913",
                "title": "65913 Fail induct NOS-antepart",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65920",
                "title": "65920 Pyrexia in labor-unspec",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65921",
                "title": "65921 Pyrexia in labor-deliver",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65923",
                "title": "65923 Pyrexia in labor-antepar",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65930",
                "title": "65930 Septicemia in labor-unsp",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65931",
                "title": "65931 Septicem in labor-deliv",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65933",
                "title": "65933 Septicem in labor-antepa",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65940",
                "title": "65940 Grand multiparity-unspec",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65941",
                "title": "65941 Grand multiparity-deliv",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65943",
                "title": "65943 Grand multiparity-antepa",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65950",
                "title": "65950 Elderly primigravid-unsp",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65951",
                "title": "65951 Elderly primigravida-del",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65953",
                "title": "65953 Elder primigravid-antepa",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65960",
                "title": "65960 Elderly multigravida-uns",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65961",
                "title": "65961 Elderly multigravida-del",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65963",
                "title": "65963 Elderly multigravd-antep",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65970",
                "title": "65970 Abn ftl hrt rate/rhy-uns",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65971",
                "title": "65971 Abn ftl hrt rate/rhy-del",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65973",
                "title": "65973 Abn ftl hrt rate/rhy-ant",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65980",
                "title": "65980 Complic labor NEC-unsp",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65981",
                "title": "65981 Complic labor NEC-deliv",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65983",
                "title": "65983 Compl labor NEC-antepart",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65990",
                "title": "65990 Complic labor NOS-unsp",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65991",
                "title": "65991 Complic labor NOS-deliv",
                "threedigit": "659",
                "isLeaf": true
              },
              {
                "key": "65993",
                "title": "65993 Compl labor NOS-antepart",
                "threedigit": "659",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Complications Occurring Mainly In The Course Of Labor And Delivery",
        "title": "660-669 Complications Occurring Mainly In The Course Of Labor And Delivery",
        "threedigit": "660",
        "children": [
          {
            "key": "Obstructed labor",
            "title": "660 - Obstructed labor",
            "threedigit": "660",
            "children": [
              {
                "key": "66000",
                "title": "66000 Obstruct/fet malpos-unsp",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66001",
                "title": "66001 Obstruc/fet malpos-deliv",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66003",
                "title": "66003 Obstruc/fet malpos-antep",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66010",
                "title": "66010 Bony pelv obstruc-unspec",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66011",
                "title": "66011 Bony pelv obstruct-deliv",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66013",
                "title": "66013 Bony pelv obstruc-antepa",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66020",
                "title": "66020 Abn pelv tiss obstr-unsp",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66021",
                "title": "66021 Abn pelv tis obstr-deliv",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66023",
                "title": "66023 Abn pelv tis obstr-antep",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66030",
                "title": "66030 Persist occipitpost-unsp",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66031",
                "title": "66031 Persist occiptpost-deliv",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66033",
                "title": "66033 Persist occiptpost-antep",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66040",
                "title": "66040 Shoulder dystocia-unspec",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66041",
                "title": "66041 Shoulder dystocia-deliv",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66043",
                "title": "66043 Shoulder dystocia-antepa",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66050",
                "title": "66050 Locked twins-unspecified",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66051",
                "title": "66051 Locked twins-delivered",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66053",
                "title": "66053 Locked twins-antepartum",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66060",
                "title": "66060 Fail trial lab NOS-unsp",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66061",
                "title": "66061 Fail trial lab NOS-deliv",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66063",
                "title": "66063 Fail trial lab NOS-antep",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66070",
                "title": "66070 Failed forcep NOS-unspec",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66071",
                "title": "66071 Failed forceps NOS-deliv",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66073",
                "title": "66073 Fail forceps NOS-antepar",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66080",
                "title": "66080 Obstruc labor NEC-unspec",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66081",
                "title": "66081 Obstruct labor NEC-deliv",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66083",
                "title": "66083 Obstruc labor NEC-antepa",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66090",
                "title": "66090 Obstruc labor NOS-unspec",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66091",
                "title": "66091 Obstruct labor NOS-deliv",
                "threedigit": "660",
                "isLeaf": true
              },
              {
                "key": "66093",
                "title": "66093 Obstruc labor NOS-antepa",
                "threedigit": "660",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Abnormality of forces of labor",
            "title": "661 - Abnormality of forces of labor",
            "threedigit": "661",
            "children": [
              {
                "key": "66100",
                "title": "66100 Prim uterine inert-unsp",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66101",
                "title": "66101 Prim uterine inert-deliv",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66103",
                "title": "66103 Prim uter inert-antepart",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66110",
                "title": "66110 Sec uterine inert-unspec",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66111",
                "title": "66111 Sec uterine inert-deliv",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66113",
                "title": "66113 Sec uterine inert-antepa",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66120",
                "title": "66120 Uterine inertia NEC-unsp",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66121",
                "title": "66121 Uterine inert NEC-deliv",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66123",
                "title": "66123 Uterine inert NEC-antepa",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66130",
                "title": "66130 Precipitate labor-unspec",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66131",
                "title": "66131 Precipitate labor-deliv",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66133",
                "title": "66133 Precipitate labor-antepa",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66140",
                "title": "66140 Uter dystocia NOS-unspec",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66141",
                "title": "66141 Uter dystocia NOS-deliv",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66143",
                "title": "66143 Uter dystocia NOS-antepa",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66190",
                "title": "66190 Abnormal labor NOS-unsp",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66191",
                "title": "66191 Abnormal labor NOS-deliv",
                "threedigit": "661",
                "isLeaf": true
              },
              {
                "key": "66193",
                "title": "66193 Abnorm labor NOS-antepar",
                "threedigit": "661",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Long labor",
            "title": "662 - Long labor",
            "threedigit": "662",
            "children": [
              {
                "key": "66200",
                "title": "66200 Prolonged 1st stage-unsp",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66201",
                "title": "66201 Prolong 1st stage-deliv",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66203",
                "title": "66203 Prolong 1st stage-antepa",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66210",
                "title": "66210 Prolonged labor NOS-unsp",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66211",
                "title": "66211 Prolong labor NOS-deliv",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66213",
                "title": "66213 Prolong labor NOS-antepa",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66220",
                "title": "66220 Prolonged 2nd stage-unsp",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66221",
                "title": "66221 Prolong 2nd stage-deliv",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66223",
                "title": "66223 Prolong 2nd stage-antepa",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66230",
                "title": "66230 Delay del 2nd twin-unsp",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66231",
                "title": "66231 Delay del 2nd twin-deliv",
                "threedigit": "662",
                "isLeaf": true
              },
              {
                "key": "66233",
                "title": "66233 Delay del 2 twin-antepar",
                "threedigit": "662",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Umbilical cord complications during labor and delivery",
            "title": "663 - Umbilical cord complications during labor and delivery",
            "threedigit": "663",
            "children": [
              {
                "key": "66300",
                "title": "66300 Cord prolapse-unspec",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66301",
                "title": "66301 Cord prolapse-delivered",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66303",
                "title": "66303 Cord prolapse-antepartum",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66310",
                "title": "66310 Cord around neck-unspec",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66311",
                "title": "66311 Cord around neck-deliver",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66313",
                "title": "66313 Cord around neck-antepar",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66320",
                "title": "66320 Cord compress NEC-unspec",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66321",
                "title": "66321 Cord compress NEC-deliv",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66323",
                "title": "66323 Cord compres NEC-antepar",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66330",
                "title": "66330 Cord entangle NEC-unspec",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66331",
                "title": "66331 Cord entangle NEC-deliv",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66333",
                "title": "66333 Cord entangl NEC-antepar",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66340",
                "title": "66340 Short cord-unspecified",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66341",
                "title": "66341 Short cord-delivered",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66343",
                "title": "66343 Short cord-antepartum",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66350",
                "title": "66350 Vasa previa-unspecified",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66351",
                "title": "66351 Vasa previa-delivered",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66353",
                "title": "66353 Vasa previa-antepartum",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66360",
                "title": "66360 Vasc lesion cord-unspec",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66361",
                "title": "66361 Vasc lesion cord-deliver",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66363",
                "title": "66363 Vasc lesion cord-antepar",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66380",
                "title": "66380 Cord complicat NEC-unsp",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66381",
                "title": "66381 Cord complicat NEC-deliv",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66383",
                "title": "66383 Cord compl NEC-antepart",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66390",
                "title": "66390 Cord complicat NOS-unsp",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66391",
                "title": "66391 Cord complicat NOS-deliv",
                "threedigit": "663",
                "isLeaf": true
              },
              {
                "key": "66393",
                "title": "66393 Cord compl NOS-antepart",
                "threedigit": "663",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Trauma to perineum and vulva during delivery",
            "title": "664 - Trauma to perineum and vulva during delivery",
            "threedigit": "664",
            "children": [
              {
                "key": "66400",
                "title": "66400 Del w 1 deg lacerat-unsp",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66401",
                "title": "66401 Del w 1 deg lacerat-del",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66404",
                "title": "66404 Del w 1 deg lac-postpart",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66410",
                "title": "66410 Del w 2 deg lacerat-unsp",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66411",
                "title": "66411 Del w 2 deg lacerat-del",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66414",
                "title": "66414 Del w 2 deg lac-postpart",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66420",
                "title": "66420 Del w 3 deg lacerat-unsp",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66421",
                "title": "66421 Del w 3 deg lacerat-del",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66424",
                "title": "66424 Del w 3 deg lac-postpart",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66430",
                "title": "66430 Del w 4 deg lacerat-unsp",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66431",
                "title": "66431 Del w 4 deg lacerat-del",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66434",
                "title": "66434 Del w 4 deg lac-postpart",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66440",
                "title": "66440 Ob perineal lac NOS-unsp",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66441",
                "title": "66441 Ob perineal lac NOS-del",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66444",
                "title": "66444 Perineal lac NOS-postpar",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66450",
                "title": "66450 Ob perineal hematom-unsp",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66451",
                "title": "66451 Ob perineal hematoma-del",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66454",
                "title": "66454 Perin hematoma-postpart",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66460",
                "title": "66460 Anal sphincter tear NOS",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66461",
                "title": "66461 Anal sphincter tear-del",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66464",
                "title": "66464 Anal sphinctr tear w p/p",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66480",
                "title": "66480 Ob perin traum NEC-unsp",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66481",
                "title": "66481 Ob perineal trau NEC-del",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66484",
                "title": "66484 Perin traum NEC-postpart",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66490",
                "title": "66490 Ob perin traum NOS-unsp",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66491",
                "title": "66491 Ob perineal trau NOS-del",
                "threedigit": "664",
                "isLeaf": true
              },
              {
                "key": "66494",
                "title": "66494 Perin traum NOS-postpart",
                "threedigit": "664",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other obstetrical trauma",
            "title": "665 - Other obstetrical trauma",
            "threedigit": "665",
            "children": [
              {
                "key": "66500",
                "title": "66500 Prelabor rupt uter-unsp",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66501",
                "title": "66501 Prelabor rupt uterus-del",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66503",
                "title": "66503 Prelab rupt uter-antepar",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66510",
                "title": "66510 Rupture uterus NOS-unsp",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66511",
                "title": "66511 Rupture uterus NOS-deliv",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66520",
                "title": "66520 Inversion of uterus-unsp",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66522",
                "title": "66522 Invers uterus-del w p/p",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66524",
                "title": "66524 Invers uterus-postpart",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66530",
                "title": "66530 Lacerat of cervix-unspec",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66531",
                "title": "66531 Lacerat of cervix-deliv",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66534",
                "title": "66534 Lacer of cervix-postpart",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66540",
                "title": "66540 High vaginal lacer-unsp",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66541",
                "title": "66541 High vaginal lacer-deliv",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66544",
                "title": "66544 High vaginal lac-postpar",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66550",
                "title": "66550 Ob inj pelv org NEC-unsp",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66551",
                "title": "66551 Ob inj pelv org NEC-del",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66554",
                "title": "66554 Inj pelv org NEC-postpar",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66560",
                "title": "66560 Damage to pelvic jt-unsp",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66561",
                "title": "66561 Damage to pelvic jt-del",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66564",
                "title": "66564 Damage pelvic jt-postpar",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66570",
                "title": "66570 Ob pelvic hematoma-unsp",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66571",
                "title": "66571 Ob pelvic hematoma-deliv",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66572",
                "title": "66572 Pelvic hematom-del w pp",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66574",
                "title": "66574 Pelvic hematoma-postpart",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66580",
                "title": "66580 Ob trauma NEC-unspec",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66581",
                "title": "66581 Ob trauma NEC-delivered",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66582",
                "title": "66582 Ob trauma NEC-del w p/p",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66583",
                "title": "66583 Ob trauma NEC-antepartum",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66584",
                "title": "66584 Ob trauma NEC-postpartum",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66590",
                "title": "66590 Ob trauma NOS-unspec",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66591",
                "title": "66591 Ob trauma NOS-delivered",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66592",
                "title": "66592 Ob trauma NOS-del w p/p",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66593",
                "title": "66593 Ob trauma NOS-antepartum",
                "threedigit": "665",
                "isLeaf": true
              },
              {
                "key": "66594",
                "title": "66594 Ob trauma NOS-postpartum",
                "threedigit": "665",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Postpartum hemorrhage",
            "title": "666 - Postpartum hemorrhage",
            "threedigit": "666",
            "children": [
              {
                "key": "66600",
                "title": "66600 Third-stage hem-unspec",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66602",
                "title": "66602 Thrd-stage hem-del w p/p",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66604",
                "title": "66604 Third-stage hem-postpart",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66610",
                "title": "66610 Postpartum hem NEC-unsp",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66612",
                "title": "66612 Postpa hem NEC-del w p/p",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66614",
                "title": "66614 Postpart hem NEC-postpar",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66620",
                "title": "66620 Delay p/part hem-unspec",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66622",
                "title": "66622 Delay p/p hem-del w p/p",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66624",
                "title": "66624 Delay p/part hem-postpar",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66630",
                "title": "66630 Postpart coagul def-unsp",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66632",
                "title": "66632 P/p coag def-del w p/p",
                "threedigit": "666",
                "isLeaf": true
              },
              {
                "key": "66634",
                "title": "66634 Postpart coag def-postpa",
                "threedigit": "666",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Retained placenta or membranes without hemorrhage",
            "title": "667 - Retained placenta or membranes without hemorrhage",
            "threedigit": "667",
            "children": [
              {
                "key": "66700",
                "title": "66700 Retain placenta NOS-unsp",
                "threedigit": "667",
                "isLeaf": true
              },
              {
                "key": "66702",
                "title": "66702 Retnd plac NOS-del w p/p",
                "threedigit": "667",
                "isLeaf": true
              },
              {
                "key": "66704",
                "title": "66704 Retain plac NOS-postpart",
                "threedigit": "667",
                "isLeaf": true
              },
              {
                "key": "66710",
                "title": "66710 Retain prod concept-unsp",
                "threedigit": "667",
                "isLeaf": true
              },
              {
                "key": "66712",
                "title": "66712 Ret prod conc-del w p/p",
                "threedigit": "667",
                "isLeaf": true
              },
              {
                "key": "66714",
                "title": "66714 Ret prod concept-postpar",
                "threedigit": "667",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Complications of the administration of anesthetic or other sedation in labor and delivery",
            "title": "668 - Complications of the administration of anesthetic or other sedation in labor and delivery",
            "threedigit": "668",
            "children": [
              {
                "key": "66800",
                "title": "66800 Pulm compl in del-unspec",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66801",
                "title": "66801 Pulm compl in del-deliv",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66802",
                "title": "66802 Pulm complic-del w p/p",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66803",
                "title": "66803 Pulm complicat-antepart",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66804",
                "title": "66804 Pulm complicat-postpart",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66810",
                "title": "66810 Heart compl in del-unsp",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66811",
                "title": "66811 Heart compl in del-deliv",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66812",
                "title": "66812 Heart compl-del w p/p",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66813",
                "title": "66813 Heart complic-antepart",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66814",
                "title": "66814 Heart complic-postpart",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66820",
                "title": "66820 Cns compl labor/del-unsp",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66821",
                "title": "66821 Cns compl lab/del-deliv",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66822",
                "title": "66822 Cns complic-del w p/p",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66823",
                "title": "66823 Cns compl in del-antepar",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66824",
                "title": "66824 Cns compl in del-postpar",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66880",
                "title": "66880 Anesth comp del NEC-unsp",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66881",
                "title": "66881 Anesth compl NEC-deliver",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66882",
                "title": "66882 Anesth compl NEC-del p/p",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66883",
                "title": "66883 Anesth compl antepartum",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66884",
                "title": "66884 Anesth compl-postpartum",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66890",
                "title": "66890 Anesth comp del NOS-unsp",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66891",
                "title": "66891 Anesth compl NOS-deliver",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66892",
                "title": "66892 Anesth compl NOS-del p/p",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66893",
                "title": "66893 Anesth compl-antepartum",
                "threedigit": "668",
                "isLeaf": true
              },
              {
                "key": "66894",
                "title": "66894 Anesth compl-postpartum",
                "threedigit": "668",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other complications of labor and delivery not elsewhere classified",
            "title": "669 - Other complications of labor and delivery not elsewhere classified",
            "threedigit": "669",
            "children": [
              {
                "key": "66900",
                "title": "66900 Maternal distress-unspec",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66901",
                "title": "66901 Maternal distress-deliv",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66902",
                "title": "66902 Matern distres-del w p/p",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66903",
                "title": "66903 Matern distress-antepar",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66904",
                "title": "66904 Matern distress-postpart",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66910",
                "title": "66910 Obstetric shock-unspec",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66911",
                "title": "66911 Obstetric shock-deliver",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66912",
                "title": "66912 Obstet shock-deliv w p/p",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66913",
                "title": "66913 Obstetric shock-antepar",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66914",
                "title": "66914 Obstetric shock-postpart",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66920",
                "title": "66920 Matern hypotens syn-unsp",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66921",
                "title": "66921 Matern hypoten syn-deliv",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66922",
                "title": "66922 Matern hypoten-del w p/p",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66923",
                "title": "66923 Matern hypotens-antepar",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66924",
                "title": "66924 Matern hypotens-postpart",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66930",
                "title": "66930 Ac kidny fail w del-unsp",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66932",
                "title": "66932 Ac kidney fail-del w p/p",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66934",
                "title": "66934 Ac kidney fail-postpart",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66940",
                "title": "66940 Oth ob surg compl-unspec",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66941",
                "title": "66941 Oth ob compl-delivered",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66942",
                "title": "66942 Oth ob compl-deliv w p/p",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66943",
                "title": "66943 Complc ob surg anteprtm",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66944",
                "title": "66944 Oth ob surg compl-postpa",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66950",
                "title": "66950 Forcep deliv NOS-unspec",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66951",
                "title": "66951 Forcep deliv NOS-deliver",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66960",
                "title": "66960 Breech extr NOS-unspec",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66961",
                "title": "66961 Breech extr NOS-deliver",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66970",
                "title": "66970 Cesarean deliv NOS-unsp",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66971",
                "title": "66971 Cesarean delivery NOS",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66980",
                "title": "66980 Compl lab/deliv NEC-unsp",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66981",
                "title": "66981 Comp lab/deliv NEC-deliv",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66982",
                "title": "66982 Compl del NEC-del w p/p",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66983",
                "title": "66983 Compl deliv NEC-antepar",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66984",
                "title": "66984 Compl deliv NEC-postpart",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66990",
                "title": "66990 Compl lab/deliv NOS-unsp",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66991",
                "title": "66991 Comp lab/deliv NOS-deliv",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66992",
                "title": "66992 Compl del NOS-del w p/p",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66993",
                "title": "66993 Compl deliv NOS-antepar",
                "threedigit": "669",
                "isLeaf": true
              },
              {
                "key": "66994",
                "title": "66994 Compl deliv NOS-postpart",
                "threedigit": "669",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Complications Of The Puerperium",
        "title": "670-677 Complications Of The Puerperium",
        "threedigit": "670",
        "children": [
          {
            "key": "Major puerperal infection",
            "title": "670 - Major puerperal infection",
            "threedigit": "670",
            "children": [
              {
                "key": "67000",
                "title": "67000 Maj puerp inf NOS-unsp",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67002",
                "title": "67002 Maj puer inf NOS-del p/p",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67004",
                "title": "67004 Major puerp inf NOS-p/p",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67010",
                "title": "67010 Puerp endometritis-unsp",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67012",
                "title": "67012 Puerp endomet del w p/p",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67014",
                "title": "67014 Puerp endomet-postpart",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67020",
                "title": "67020 Puerperal sepsis-unsp",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67022",
                "title": "67022 Puerprl sepsis-del w p/p",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67024",
                "title": "67024 Puerperl sepsis-postpart",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67030",
                "title": "67030 Puerp septc thromb-unsp",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67032",
                "title": "67032 Prp sptc thrmb-del w p/p",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67034",
                "title": "67034 Prp septc thrmb-postpart",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67080",
                "title": "67080 Maj prp infec NEC-unspec",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67082",
                "title": "67082 Maj prp inf NEC-dl w p/p",
                "threedigit": "670",
                "isLeaf": true
              },
              {
                "key": "67084",
                "title": "67084 Maj puerp infec NEC-p/p",
                "threedigit": "670",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Venous complications in pregnancy and the puerperium",
            "title": "671 - Venous complications in pregnancy and the puerperium",
            "threedigit": "671",
            "children": [
              {
                "key": "67100",
                "title": "67100 Varic vein leg preg-unsp",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67101",
                "title": "67101 Varicose vein leg-deliv",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67102",
                "title": "67102 Varic vein leg-del w p/p",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67103",
                "title": "67103 Varic vein leg-antepart",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67104",
                "title": "67104 Varic vein leg-postpart",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67110",
                "title": "67110 Varic vulva preg-unspec",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67111",
                "title": "67111 Varicose vulva-delivered",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67112",
                "title": "67112 Varicose vulva-del w p/p",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67113",
                "title": "67113 Varicose vulva-antepart",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67114",
                "title": "67114 Varicose vulva-postpart",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67120",
                "title": "67120 Thrombophleb preg-unspec",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67121",
                "title": "67121 Thrombophlebitis-deliver",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67122",
                "title": "67122 Thrombophleb-deliv w p/p",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67123",
                "title": "67123 Thrombophlebit-antepart",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67124",
                "title": "67124 Thrombophlebit-postpart",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67130",
                "title": "67130 Deep thromb antepar-unsp",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67131",
                "title": "67131 Deep throm antepar-deliv",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67133",
                "title": "67133 Deep vein thromb-antepar",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67140",
                "title": "67140 Deep thromb postpar-unsp",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67142",
                "title": "67142 Thromb postpar-del w p/p",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67144",
                "title": "67144 Deep vein thromb-postpar",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67150",
                "title": "67150 Thrombosis NEC preg-unsp",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67151",
                "title": "67151 Thrombosis NEC-delivered",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67152",
                "title": "67152 Thromb NEC-deliv w p/p",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67153",
                "title": "67153 Thrombosis NEC-antepart",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67154",
                "title": "67154 Thrombosis NEC-postpart",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67180",
                "title": "67180 Ven compl preg NEC-unsp",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67181",
                "title": "67181 Venous compl NEC-deliver",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67182",
                "title": "67182 Ven comp NEC-deliv w p/p",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67183",
                "title": "67183 Venous compl NEC-antepar",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67184",
                "title": "67184 Venous compl NEC-postpar",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67190",
                "title": "67190 Ven compl preg NOS-unsp",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67191",
                "title": "67191 Venous compl NOS-deliver",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67192",
                "title": "67192 Ven comp NOS-deliv w p/p",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67193",
                "title": "67193 Venous compl NOS-antepar",
                "threedigit": "671",
                "isLeaf": true
              },
              {
                "key": "67194",
                "title": "67194 Venous compl NOS-postpar",
                "threedigit": "671",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pyrexia of unknown origin during the puerperium",
            "title": "672 - Pyrexia of unknown origin during the puerperium",
            "threedigit": "672",
            "children": [
              {
                "key": "67200",
                "title": "67200 Puerperal pyrexia-unspec",
                "threedigit": "672",
                "isLeaf": true
              },
              {
                "key": "67202",
                "title": "67202 Puerp pyrexia-del w p/p",
                "threedigit": "672",
                "isLeaf": true
              },
              {
                "key": "67204",
                "title": "67204 Puerp pyrexia-postpartum",
                "threedigit": "672",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Obstetrical pulmonary embolism",
            "title": "673 - Obstetrical pulmonary embolism",
            "threedigit": "673",
            "children": [
              {
                "key": "67300",
                "title": "67300 Ob air embolism-unspec",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67301",
                "title": "67301 Ob air embolism-deliver",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67302",
                "title": "67302 Ob air embol-deliv w p/p",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67303",
                "title": "67303 Ob air embolism-antepart",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67304",
                "title": "67304 Ob air embolism-postpart",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67310",
                "title": "67310 Amniotic embolism-unspec",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67311",
                "title": "67311 Amniotic embolism-deliv",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67312",
                "title": "67312 Amniot embol-deliv w p/p",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67313",
                "title": "67313 Amniotic embol-antepart",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67314",
                "title": "67314 Amniotic embol-postpart",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67320",
                "title": "67320 Ob pulm embol NOS-unspec",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67321",
                "title": "67321 Pulm embol NOS-delivered",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67322",
                "title": "67322 Pulm embol NOS-del w p/p",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67323",
                "title": "67323 Pulm embol NOS-antepart",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67324",
                "title": "67324 Pulm embol NOS-postpart",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67330",
                "title": "67330 Ob pyemic embol-unspec",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67331",
                "title": "67331 Ob pyemic embol-deliver",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67332",
                "title": "67332 Ob pyem embol-del w p/p",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67333",
                "title": "67333 Ob pyemic embol-antepart",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67334",
                "title": "67334 Ob pyemic embol-postpart",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67380",
                "title": "67380 Ob pulmon embol NEC-unsp",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67381",
                "title": "67381 Pulmon embol NEC-deliver",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67382",
                "title": "67382 Pulm embol NEC-del w p/p",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67383",
                "title": "67383 Pulmon embol NEC-antepar",
                "threedigit": "673",
                "isLeaf": true
              },
              {
                "key": "67384",
                "title": "67384 Pulmon embol NEC-postpar",
                "threedigit": "673",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified complications of the puerperium not elsewhere classified",
            "title": "674 - Other and unspecified complications of the puerperium not elsewhere classified",
            "threedigit": "674",
            "children": [
              {
                "key": "67400",
                "title": "67400 Puerp cerebvasc dis-unsp",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67401",
                "title": "67401 Puerp cerebvas dis-deliv",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67402",
                "title": "67402 Cerebvas dis-deliv w p/p",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67403",
                "title": "67403 Cerebrovasc dis-antepart",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67404",
                "title": "67404 Cerebrovasc dis-postpart",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67410",
                "title": "67410 Disrupt c-sect wnd-unsp",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67412",
                "title": "67412 Disrupt c-sect-del w p/p",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67414",
                "title": "67414 Disrupt c-sect-postpart",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67420",
                "title": "67420 Disrupt perineum-unspec",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67422",
                "title": "67422 Disrupt perin-del w p/p",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67424",
                "title": "67424 Disrupt perineum-postpar",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67430",
                "title": "67430 Ob surg compl NEC-unspec",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67432",
                "title": "67432 Ob surg compl-del w p/p",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67434",
                "title": "67434 Ob surg comp NEC-postpar",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67440",
                "title": "67440 Placental polyp-unspec",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67442",
                "title": "67442 Placent polyp-del w p/p",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67444",
                "title": "67444 Placental polyp-postpart",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67450",
                "title": "67450 Peripart cardiomy-unspec",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67451",
                "title": "67451 Peripartum cardiomy-del",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67452",
                "title": "67452 Peripart card del w p/p",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67453",
                "title": "67453 Peripartum card-antepart",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67454",
                "title": "67454 Peripartum card-postpart",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67480",
                "title": "67480 Puerp compl NEC-unspec",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67482",
                "title": "67482 Puerp comp NEC-del w p/p",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67484",
                "title": "67484 Puerp compl NEC-postpart",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67490",
                "title": "67490 Puerp compl NOS-unspec",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67492",
                "title": "67492 Puerp comp NOS-del w p/p",
                "threedigit": "674",
                "isLeaf": true
              },
              {
                "key": "67494",
                "title": "67494 Puerp compl NOS-postpart",
                "threedigit": "674",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Infections of the breast and nipple associated with childbirth",
            "title": "675 - Infections of the breast and nipple associated with childbirth",
            "threedigit": "675",
            "children": [
              {
                "key": "67500",
                "title": "67500 Infect nipple preg-unsp",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67501",
                "title": "67501 Infect nipple-delivered",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67502",
                "title": "67502 Infect nipple-del w p/p",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67503",
                "title": "67503 Infect nipple-antepartum",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67504",
                "title": "67504 Infect nipple-postpartum",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67510",
                "title": "67510 Breast abscess preg-unsp",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67511",
                "title": "67511 Breast abscess-delivered",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67512",
                "title": "67512 Breast abscess-del w p/p",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67513",
                "title": "67513 Breast abscess-antepart",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67514",
                "title": "67514 Breast abscess-postpart",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67520",
                "title": "67520 Mastitis in preg-unspec",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67521",
                "title": "67521 Mastitis-delivered",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67522",
                "title": "67522 Mastitis-deliv w p/p",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67523",
                "title": "67523 Mastitis-antepartum",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67524",
                "title": "67524 Mastitis-postpartum",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67580",
                "title": "67580 Breast inf preg NEC-unsp",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67581",
                "title": "67581 Breast infect NEC-deliv",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67582",
                "title": "67582 Breast inf NEC-del w p/p",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67583",
                "title": "67583 Breast inf NEC-antepart",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67584",
                "title": "67584 Breast inf NEC-postpart",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67590",
                "title": "67590 Breast inf preg NOS-unsp",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67591",
                "title": "67591 Breast infect NOS-deliv",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67592",
                "title": "67592 Breast inf NOS-del w p/p",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67593",
                "title": "67593 Breast inf NOS-antepart",
                "threedigit": "675",
                "isLeaf": true
              },
              {
                "key": "67594",
                "title": "67594 Breast inf NOS-postpart",
                "threedigit": "675",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of the breast associated with childbirth and disorders of lactation",
            "title": "676 - Other disorders of the breast associated with childbirth and disorders of lactation",
            "threedigit": "676",
            "children": [
              {
                "key": "67600",
                "title": "67600 Retract nipple preg-unsp",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67601",
                "title": "67601 Retracted nipple-deliver",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67602",
                "title": "67602 Retract nipple-del w p/p",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67603",
                "title": "67603 Retract nipple-antepart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67604",
                "title": "67604 Retract nipple-postpart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67610",
                "title": "67610 Cracked nipple preg-unsp",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67611",
                "title": "67611 Cracked nipple-delivered",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67612",
                "title": "67612 Cracked nipple-del w p/p",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67613",
                "title": "67613 Cracked nipple-antepart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67614",
                "title": "67614 Cracked nipple-postpart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67620",
                "title": "67620 Breast engorge-unspec",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67621",
                "title": "67621 Breast engorge-delivered",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67622",
                "title": "67622 Breast engorge-del w p/p",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67623",
                "title": "67623 Breast engorge-antepart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67624",
                "title": "67624 Breast engorge-postpart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67630",
                "title": "67630 Breast dis preg NEC-unsp",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67631",
                "title": "67631 Breast dis NEC-delivered",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67632",
                "title": "67632 Breast dis NEC-del w p/p",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67633",
                "title": "67633 Breast dis NEC-antepart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67634",
                "title": "67634 Breast dis NEC-postpart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67640",
                "title": "67640 Lactation fail-unspec",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67641",
                "title": "67641 Lactation fail-delivered",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67642",
                "title": "67642 Lactation fail-del w p/p",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67643",
                "title": "67643 Lactation fail-antepart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67644",
                "title": "67644 Lactation fail-postpart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67650",
                "title": "67650 Suppr lactation-unspec",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67651",
                "title": "67651 Suppr lactation-deliver",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67652",
                "title": "67652 Suppr lactat-del w p/p",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67653",
                "title": "67653 Suppr lactation-antepar",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67654",
                "title": "67654 Suppr lactation-postpart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67660",
                "title": "67660 Galactorrhea preg-unspec",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67661",
                "title": "67661 Galactorrhea-delivered",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67662",
                "title": "67662 Galactorrhea-del w p/p",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67663",
                "title": "67663 Galactorrhea-antepartum",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67664",
                "title": "67664 Galactorrhea-postpartum",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67680",
                "title": "67680 Lactation dis NEC-unspec",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67681",
                "title": "67681 Lactation dis NEC-deliv",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67682",
                "title": "67682 Lactat dis NEC-del w p/p",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67683",
                "title": "67683 Lactat dis NEC-antepart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67684",
                "title": "67684 Lactat dis NEC-postpart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67690",
                "title": "67690 Lactation dis NOS-unspec",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67691",
                "title": "67691 Lactation dis NOS-deliv",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67692",
                "title": "67692 Lactat dis NOS-del w p/p",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67693",
                "title": "67693 Lactat dis NOS-antepart",
                "threedigit": "676",
                "isLeaf": true
              },
              {
                "key": "67694",
                "title": "67694 Lactat dis NOS-postpart",
                "threedigit": "676",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effect of complication of pregnancy, childbirth, and the puerperium",
            "title": "677 - Late effect of complication of pregnancy, childbirth, and the puerperium",
            "threedigit": "677",
            "children": [
              {
                "key": "677",
                "title": "677 Late effct cmplcatn preg",
                "threedigit": "677",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Maternal And Fetal Complications",
        "title": "678-679 Other Maternal And Fetal Complications",
        "threedigit": "678",
        "children": [
          {
            "key": "Other fetal conditions",
            "title": "678 - Other fetal conditions",
            "threedigit": "678",
            "children": [
              {
                "key": "67800",
                "title": "67800 Fetal hematologic-unspec",
                "threedigit": "678",
                "isLeaf": true
              },
              {
                "key": "67801",
                "title": "67801 Fetal hematologic-deliv",
                "threedigit": "678",
                "isLeaf": true
              },
              {
                "key": "67803",
                "title": "67803 Fetal hematologic-ante",
                "threedigit": "678",
                "isLeaf": true
              },
              {
                "key": "67810",
                "title": "67810 Fetal conjoin twins-unsp",
                "threedigit": "678",
                "isLeaf": true
              },
              {
                "key": "67811",
                "title": "67811 Fetal conjoin twins-del",
                "threedigit": "678",
                "isLeaf": true
              },
              {
                "key": "67813",
                "title": "67813 Fetal conjoin twins-ante",
                "threedigit": "678",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Complications of in utero procedures",
            "title": "679 - Complications of in utero procedures",
            "threedigit": "679",
            "children": [
              {
                "key": "67900",
                "title": "67900 Mat comp in utero-unsp",
                "threedigit": "679",
                "isLeaf": true
              },
              {
                "key": "67901",
                "title": "67901 Mat comp in utero-del",
                "threedigit": "679",
                "isLeaf": true
              },
              {
                "key": "67902",
                "title": "67902 Mat comp in utro-del-p/p",
                "threedigit": "679",
                "isLeaf": true
              },
              {
                "key": "67903",
                "title": "67903 Mat comp in utero-ante",
                "threedigit": "679",
                "isLeaf": true
              },
              {
                "key": "67904",
                "title": "67904 Mat comp in utero-p/p",
                "threedigit": "679",
                "isLeaf": true
              },
              {
                "key": "67910",
                "title": "67910 Fetal comp in utero-unsp",
                "threedigit": "679",
                "isLeaf": true
              },
              {
                "key": "67911",
                "title": "67911 Fetal comp in utero-del",
                "threedigit": "679",
                "isLeaf": true
              },
              {
                "key": "67912",
                "title": "67912 Ftl cmp in utro-del-p/p",
                "threedigit": "679",
                "isLeaf": true
              },
              {
                "key": "67913",
                "title": "67913 Fetal comp in utero-ante",
                "threedigit": "679",
                "isLeaf": true
              },
              {
                "key": "67914",
                "title": "67914 Fetal comp in utero-p/p",
                "threedigit": "679",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Diseases Of The Skin And Subcutaneous Tissue",
    "title": "680-709 Diseases Of The Skin And Subcutaneous Tissue",
    "threedigit": "680",
    "children": [
      {
        "key": "Infections Of Skin And Subcutaneous Tissue",
        "title": "680-686 Infections Of Skin And Subcutaneous Tissue",
        "threedigit": "680",
        "children": [
          {
            "key": "Carbuncle and furuncle",
            "title": "680 - Carbuncle and furuncle",
            "threedigit": "680",
            "children": [
              {
                "key": "6800",
                "title": "6800 Carbuncle of face",
                "threedigit": "680",
                "isLeaf": true
              },
              {
                "key": "6801",
                "title": "6801 Carbuncle of neck",
                "threedigit": "680",
                "isLeaf": true
              },
              {
                "key": "6802",
                "title": "6802 Carbuncle of trunk",
                "threedigit": "680",
                "isLeaf": true
              },
              {
                "key": "6803",
                "title": "6803 Carbuncle of arm",
                "threedigit": "680",
                "isLeaf": true
              },
              {
                "key": "6804",
                "title": "6804 Carbuncle of hand",
                "threedigit": "680",
                "isLeaf": true
              },
              {
                "key": "6805",
                "title": "6805 Carbuncle of buttock",
                "threedigit": "680",
                "isLeaf": true
              },
              {
                "key": "6806",
                "title": "6806 Carbuncle of leg",
                "threedigit": "680",
                "isLeaf": true
              },
              {
                "key": "6807",
                "title": "6807 Carbuncle of foot",
                "threedigit": "680",
                "isLeaf": true
              },
              {
                "key": "6808",
                "title": "6808 Carbuncle, site NEC",
                "threedigit": "680",
                "isLeaf": true
              },
              {
                "key": "6809",
                "title": "6809 Carbuncle NOS",
                "threedigit": "680",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cellulitis and abscess of finger and toe",
            "title": "681 - Cellulitis and abscess of finger and toe",
            "threedigit": "681",
            "children": [
              {
                "key": "68100",
                "title": "68100 Cellulitis, finger NOS",
                "threedigit": "681",
                "isLeaf": true
              },
              {
                "key": "68101",
                "title": "68101 Felon",
                "threedigit": "681",
                "isLeaf": true
              },
              {
                "key": "68102",
                "title": "68102 Onychia of finger",
                "threedigit": "681",
                "isLeaf": true
              },
              {
                "key": "68110",
                "title": "68110 Cellulitis, toe NOS",
                "threedigit": "681",
                "isLeaf": true
              },
              {
                "key": "68111",
                "title": "68111 Onychia of toe",
                "threedigit": "681",
                "isLeaf": true
              },
              {
                "key": "6819",
                "title": "6819 Cellulitis of digit NOS",
                "threedigit": "681",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other cellulitis and abscess",
            "title": "682 - Other cellulitis and abscess",
            "threedigit": "682",
            "children": [
              {
                "key": "6820",
                "title": "6820 Cellulitis of face",
                "threedigit": "682",
                "isLeaf": true
              },
              {
                "key": "6821",
                "title": "6821 Cellulitis of neck",
                "threedigit": "682",
                "isLeaf": true
              },
              {
                "key": "6822",
                "title": "6822 Cellulitis of trunk",
                "threedigit": "682",
                "isLeaf": true
              },
              {
                "key": "6823",
                "title": "6823 Cellulitis of arm",
                "threedigit": "682",
                "isLeaf": true
              },
              {
                "key": "6824",
                "title": "6824 Cellulitis of hand",
                "threedigit": "682",
                "isLeaf": true
              },
              {
                "key": "6825",
                "title": "6825 Cellulitis of buttock",
                "threedigit": "682",
                "isLeaf": true
              },
              {
                "key": "6826",
                "title": "6826 Cellulitis of leg",
                "threedigit": "682",
                "isLeaf": true
              },
              {
                "key": "6827",
                "title": "6827 Cellulitis of foot",
                "threedigit": "682",
                "isLeaf": true
              },
              {
                "key": "6828",
                "title": "6828 Cellulitis, site NEC",
                "threedigit": "682",
                "isLeaf": true
              },
              {
                "key": "6829",
                "title": "6829 Cellulitis NOS",
                "threedigit": "682",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acute lymphadenitis",
            "title": "683 - Acute lymphadenitis",
            "threedigit": "683",
            "children": [
              {
                "key": "683",
                "title": "683 Acute lymphadenitis",
                "threedigit": "683",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Impetigo",
            "title": "684 - Impetigo",
            "threedigit": "684",
            "children": [
              {
                "key": "684",
                "title": "684 Impetigo",
                "threedigit": "684",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pilonidal cyst",
            "title": "685 - Pilonidal cyst",
            "threedigit": "685",
            "children": [
              {
                "key": "6850",
                "title": "6850 Pilonidal cyst w abscess",
                "threedigit": "685",
                "isLeaf": true
              },
              {
                "key": "6851",
                "title": "6851 Pilonidal cyst w/o absc",
                "threedigit": "685",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other local infections of skin and subcutaneous tissue",
            "title": "686 - Other local infections of skin and subcutaneous tissue",
            "threedigit": "686",
            "children": [
              {
                "key": "68600",
                "title": "68600 Pyoderma NOS",
                "threedigit": "686",
                "isLeaf": true
              },
              {
                "key": "68601",
                "title": "68601 Pyoderma gangrenosum",
                "threedigit": "686",
                "isLeaf": true
              },
              {
                "key": "68609",
                "title": "68609 Pyoderma NEC",
                "threedigit": "686",
                "isLeaf": true
              },
              {
                "key": "6861",
                "title": "6861 Pyogenic granuloma",
                "threedigit": "686",
                "isLeaf": true
              },
              {
                "key": "6868",
                "title": "6868 Local skin infection NEC",
                "threedigit": "686",
                "isLeaf": true
              },
              {
                "key": "6869",
                "title": "6869 Local skin infection NOS",
                "threedigit": "686",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Inflammatory Conditions Of Skin And Subcutaneous Tissue",
        "title": "690-698 Other Inflammatory Conditions Of Skin And Subcutaneous Tissue",
        "threedigit": "690",
        "children": [
          {
            "key": "Erythematosquamous dermatosis",
            "title": "690 - Erythematosquamous dermatosis",
            "threedigit": "690",
            "children": [
              {
                "key": "69010",
                "title": "69010 Sebrrheic dermatitis NOS",
                "threedigit": "690",
                "isLeaf": true
              },
              {
                "key": "69011",
                "title": "69011 Seborrhea capitis",
                "threedigit": "690",
                "isLeaf": true
              },
              {
                "key": "69012",
                "title": "69012 Sbrheic infantl drmtitis",
                "threedigit": "690",
                "isLeaf": true
              },
              {
                "key": "69018",
                "title": "69018 Sebrrheic dermatitis NEC",
                "threedigit": "690",
                "isLeaf": true
              },
              {
                "key": "6908",
                "title": "6908 Erythmtsquamous derm NEC",
                "threedigit": "690",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Atopic dermatitis and related conditions",
            "title": "691 - Atopic dermatitis and related conditions",
            "threedigit": "691",
            "children": [
              {
                "key": "6910",
                "title": "6910 Diaper or napkin rash",
                "threedigit": "691",
                "isLeaf": true
              },
              {
                "key": "6918",
                "title": "6918 Other atopic dermatitis",
                "threedigit": "691",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Contact dermatitis and other eczema",
            "title": "692 - Contact dermatitis and other eczema",
            "threedigit": "692",
            "children": [
              {
                "key": "6920",
                "title": "6920 Detergent dermatitis",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "6921",
                "title": "6921 Oil & grease dermatitis",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "6922",
                "title": "6922 Solvent dermatitis",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "6923",
                "title": "6923 Topical med dermatitis",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "6924",
                "title": "6924 Chemical dermatitis NEC",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "6925",
                "title": "6925 Topical food dermatitis",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "6926",
                "title": "6926 Dermatitis due to plant",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69270",
                "title": "69270 Solar dermatitis NOS",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69271",
                "title": "69271 Sunburn",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69272",
                "title": "69272 Act drmtitis solar rdiat",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69273",
                "title": "69273 Actnc retic actnc grnlma",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69274",
                "title": "69274 Oth chr drmtit solar rad",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69275",
                "title": "69275 Dis sup actnc porokrtsis",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69276",
                "title": "69276 2nd degree sunburn",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69277",
                "title": "69277 3rd degree sunburn",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69279",
                "title": "69279 Oth dermatitis solar rad",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69281",
                "title": "69281 Cosmetic dermatitis",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69282",
                "title": "69282 Dermatitis oth radiation",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69283",
                "title": "69283 Dermatitis metals",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69284",
                "title": "69284 Contact drmatitis-animal",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "69289",
                "title": "69289 Dermatitis NEC",
                "threedigit": "692",
                "isLeaf": true
              },
              {
                "key": "6929",
                "title": "6929 Dermatitis NOS",
                "threedigit": "692",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dermatitis due to substances taken internally",
            "title": "693 - Dermatitis due to substances taken internally",
            "threedigit": "693",
            "children": [
              {
                "key": "6930",
                "title": "6930 Drug dermatitis NOS",
                "threedigit": "693",
                "isLeaf": true
              },
              {
                "key": "6931",
                "title": "6931 Dermat d/t food ingest",
                "threedigit": "693",
                "isLeaf": true
              },
              {
                "key": "6938",
                "title": "6938 Dermat d/t int agent NEC",
                "threedigit": "693",
                "isLeaf": true
              },
              {
                "key": "6939",
                "title": "6939 Dermat d/t int agent NOS",
                "threedigit": "693",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Bullous dermatoses",
            "title": "694 - Bullous dermatoses",
            "threedigit": "694",
            "children": [
              {
                "key": "6940",
                "title": "6940 Dermatitis herpetiformis",
                "threedigit": "694",
                "isLeaf": true
              },
              {
                "key": "6941",
                "title": "6941 Subcorneal pust dermatos",
                "threedigit": "694",
                "isLeaf": true
              },
              {
                "key": "6942",
                "title": "6942 Juven dermat herpetiform",
                "threedigit": "694",
                "isLeaf": true
              },
              {
                "key": "6943",
                "title": "6943 Impetigo herpetiformis",
                "threedigit": "694",
                "isLeaf": true
              },
              {
                "key": "6944",
                "title": "6944 Pemphigus",
                "threedigit": "694",
                "isLeaf": true
              },
              {
                "key": "6945",
                "title": "6945 Pemphigoid",
                "threedigit": "694",
                "isLeaf": true
              },
              {
                "key": "69460",
                "title": "69460 Bn mucous memb pemph NOS",
                "threedigit": "694",
                "isLeaf": true
              },
              {
                "key": "69461",
                "title": "69461 Ocular pemphigus",
                "threedigit": "694",
                "isLeaf": true
              },
              {
                "key": "6948",
                "title": "6948 Bullous dermatoses NEC",
                "threedigit": "694",
                "isLeaf": true
              },
              {
                "key": "6949",
                "title": "6949 Bullous dermatoses NOS",
                "threedigit": "694",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Erythematous conditions",
            "title": "695 - Erythematous conditions",
            "threedigit": "695",
            "children": [
              {
                "key": "6950",
                "title": "6950 Toxic erythema",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69510",
                "title": "69510 Erythema multiforme NOS",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69511",
                "title": "69511 Erythma multiforme minor",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69512",
                "title": "69512 Erythema multiforme maj",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69513",
                "title": "69513 Stevens-Johnson syndrome",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69514",
                "title": "69514 Stevens-Johnson-TEN syn",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69515",
                "title": "69515 Toxic epidrml necrolysis",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69519",
                "title": "69519 Erythema multiforme NEC",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "6952",
                "title": "6952 Erythema nodosum",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "6953",
                "title": "6953 Rosacea",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "6954",
                "title": "6954 Lupus erythematosus",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69550",
                "title": "69550 Exfol d/t eryth <10% bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69551",
                "title": "69551 Exfl d/t eryth 10-19 bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69552",
                "title": "69552 Exfl d/t eryth 20-29 bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69553",
                "title": "69553 Exfl d/t eryth 30-39 bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69554",
                "title": "69554 Exfl d/t eryth 40-49 bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69555",
                "title": "69555 Exfl d/t eryth 50-59 bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69556",
                "title": "69556 Exfl d/t eryth 60-69 bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69557",
                "title": "69557 Exfl d/t eryth 70-79 bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69558",
                "title": "69558 Exfl d/t eryth 80-89 bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69559",
                "title": "69559 Exfl d/t eryth >=90% bdy",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69581",
                "title": "69581 Ritter's disease",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "69589",
                "title": "69589 Erythematous cond NEC",
                "threedigit": "695",
                "isLeaf": true
              },
              {
                "key": "6959",
                "title": "6959 Erythematous cond NOS",
                "threedigit": "695",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Psoriasis and similar disorders",
            "title": "696 - Psoriasis and similar disorders",
            "threedigit": "696",
            "children": [
              {
                "key": "6960",
                "title": "6960 Psoriatic arthropathy",
                "threedigit": "696",
                "isLeaf": true
              },
              {
                "key": "6961",
                "title": "6961 Other psoriasis",
                "threedigit": "696",
                "isLeaf": true
              },
              {
                "key": "6962",
                "title": "6962 Parapsoriasis",
                "threedigit": "696",
                "isLeaf": true
              },
              {
                "key": "6963",
                "title": "6963 Pityriasis rosea",
                "threedigit": "696",
                "isLeaf": true
              },
              {
                "key": "6964",
                "title": "6964 Pityriasis rubra pilaris",
                "threedigit": "696",
                "isLeaf": true
              },
              {
                "key": "6965",
                "title": "6965 Pityriasis NEC & NOS",
                "threedigit": "696",
                "isLeaf": true
              },
              {
                "key": "6968",
                "title": "6968 Psorias related dis NEC",
                "threedigit": "696",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Lichen",
            "title": "697 - Lichen",
            "threedigit": "697",
            "children": [
              {
                "key": "6970",
                "title": "6970 Lichen planus",
                "threedigit": "697",
                "isLeaf": true
              },
              {
                "key": "6971",
                "title": "6971 Lichen nitidus",
                "threedigit": "697",
                "isLeaf": true
              },
              {
                "key": "6978",
                "title": "6978 Lichen NEC",
                "threedigit": "697",
                "isLeaf": true
              },
              {
                "key": "6979",
                "title": "6979 Lichen NOS",
                "threedigit": "697",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Pruritus and related conditions",
            "title": "698 - Pruritus and related conditions",
            "threedigit": "698",
            "children": [
              {
                "key": "6980",
                "title": "6980 Pruritus ani",
                "threedigit": "698",
                "isLeaf": true
              },
              {
                "key": "6981",
                "title": "6981 Pruritus of genitalia",
                "threedigit": "698",
                "isLeaf": true
              },
              {
                "key": "6982",
                "title": "6982 Prurigo",
                "threedigit": "698",
                "isLeaf": true
              },
              {
                "key": "6983",
                "title": "6983 Lichenification",
                "threedigit": "698",
                "isLeaf": true
              },
              {
                "key": "6984",
                "title": "6984 Dermatitis factitia",
                "threedigit": "698",
                "isLeaf": true
              },
              {
                "key": "6988",
                "title": "6988 Pruritic conditions NEC",
                "threedigit": "698",
                "isLeaf": true
              },
              {
                "key": "6989",
                "title": "6989 Pruritic disorder NOS",
                "threedigit": "698",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Diseases Of Skin And Subcutaneous Tissue",
        "title": "700-709 Other Diseases Of Skin And Subcutaneous Tissue",
        "threedigit": "700",
        "children": [
          {
            "key": "Corns and callosities",
            "title": "700 - Corns and callosities",
            "threedigit": "700",
            "children": [
              {
                "key": "700",
                "title": "700 Corns and callosities",
                "threedigit": "700",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other hypertrophic and atrophic conditions of skin",
            "title": "701 - Other hypertrophic and atrophic conditions of skin",
            "threedigit": "701",
            "children": [
              {
                "key": "7010",
                "title": "7010 Circumscribe scleroderma",
                "threedigit": "701",
                "isLeaf": true
              },
              {
                "key": "7011",
                "title": "7011 Keratoderma, acquired",
                "threedigit": "701",
                "isLeaf": true
              },
              {
                "key": "7012",
                "title": "7012 Acq acanthosis nigricans",
                "threedigit": "701",
                "isLeaf": true
              },
              {
                "key": "7013",
                "title": "7013 Striae atrophicae",
                "threedigit": "701",
                "isLeaf": true
              },
              {
                "key": "7014",
                "title": "7014 Keloid scar",
                "threedigit": "701",
                "isLeaf": true
              },
              {
                "key": "7015",
                "title": "7015 Abnormal granulation NEC",
                "threedigit": "701",
                "isLeaf": true
              },
              {
                "key": "7018",
                "title": "7018 Skin hypertro/atroph NEC",
                "threedigit": "701",
                "isLeaf": true
              },
              {
                "key": "7019",
                "title": "7019 Skin hypertro/atroph NOS",
                "threedigit": "701",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other dermatoses",
            "title": "702 - Other dermatoses",
            "threedigit": "702",
            "children": [
              {
                "key": "7020",
                "title": "7020 Actinic keratosis",
                "threedigit": "702",
                "isLeaf": true
              },
              {
                "key": "70211",
                "title": "70211 Inflamed sbrheic keratos",
                "threedigit": "702",
                "isLeaf": true
              },
              {
                "key": "70219",
                "title": "70219 Other sborheic keratosis",
                "threedigit": "702",
                "isLeaf": true
              },
              {
                "key": "7028",
                "title": "7028 Other specf dermatoses",
                "threedigit": "702",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of nail",
            "title": "703 - Diseases of nail",
            "threedigit": "703",
            "children": [
              {
                "key": "7030",
                "title": "7030 Ingrowing nail",
                "threedigit": "703",
                "isLeaf": true
              },
              {
                "key": "7038",
                "title": "7038 Diseases of nail NEC",
                "threedigit": "703",
                "isLeaf": true
              },
              {
                "key": "7039",
                "title": "7039 Disease of nail NOS",
                "threedigit": "703",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of hair and hair follicles",
            "title": "704 - Diseases of hair and hair follicles",
            "threedigit": "704",
            "children": [
              {
                "key": "70400",
                "title": "70400 Alopecia NOS",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "70401",
                "title": "70401 Alopecia areata",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "70402",
                "title": "70402 Telogen effluvium",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "70409",
                "title": "70409 Alopecia NEC",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "7041",
                "title": "7041 Hirsutism",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "7042",
                "title": "7042 Abnormalities of hair",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "7043",
                "title": "7043 Variations in hair color",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "70441",
                "title": "70441 Pilar cyst",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "70442",
                "title": "70442 Trichilemmal cyst",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "7048",
                "title": "7048 Hair diseases NEC",
                "threedigit": "704",
                "isLeaf": true
              },
              {
                "key": "7049",
                "title": "7049 Hair disease NOS",
                "threedigit": "704",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of sweat glands",
            "title": "705 - Disorders of sweat glands",
            "threedigit": "705",
            "children": [
              {
                "key": "7050",
                "title": "7050 Anhidrosis",
                "threedigit": "705",
                "isLeaf": true
              },
              {
                "key": "7051",
                "title": "7051 Prickly heat",
                "threedigit": "705",
                "isLeaf": true
              },
              {
                "key": "70521",
                "title": "70521 Primary focal hyprhidros",
                "threedigit": "705",
                "isLeaf": true
              },
              {
                "key": "70522",
                "title": "70522 Sec focal hyperhidrosis",
                "threedigit": "705",
                "isLeaf": true
              },
              {
                "key": "70581",
                "title": "70581 Dyshidrosis",
                "threedigit": "705",
                "isLeaf": true
              },
              {
                "key": "70582",
                "title": "70582 Fox-fordyce disease",
                "threedigit": "705",
                "isLeaf": true
              },
              {
                "key": "70583",
                "title": "70583 Hidradenitis",
                "threedigit": "705",
                "isLeaf": true
              },
              {
                "key": "70589",
                "title": "70589 Sweat gland disorder NEC",
                "threedigit": "705",
                "isLeaf": true
              },
              {
                "key": "7059",
                "title": "7059 Sweat gland disorder NOS",
                "threedigit": "705",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Diseases of sebaceous glands",
            "title": "706 - Diseases of sebaceous glands",
            "threedigit": "706",
            "children": [
              {
                "key": "7060",
                "title": "7060 Acne varioliformis",
                "threedigit": "706",
                "isLeaf": true
              },
              {
                "key": "7061",
                "title": "7061 Acne NEC",
                "threedigit": "706",
                "isLeaf": true
              },
              {
                "key": "7062",
                "title": "7062 Sebaceous cyst",
                "threedigit": "706",
                "isLeaf": true
              },
              {
                "key": "7063",
                "title": "7063 Seborrhea",
                "threedigit": "706",
                "isLeaf": true
              },
              {
                "key": "7068",
                "title": "7068 Sebaceous gland dis NEC",
                "threedigit": "706",
                "isLeaf": true
              },
              {
                "key": "7069",
                "title": "7069 Sebaceous gland dis NOS",
                "threedigit": "706",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chronic ulcer of skin",
            "title": "707 - Chronic ulcer of skin",
            "threedigit": "707",
            "children": [
              {
                "key": "70700",
                "title": "70700 Pressure ulcer, site NOS",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70701",
                "title": "70701 Pressure ulcer, elbow",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70702",
                "title": "70702 Pressure ulcer, upr back",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70703",
                "title": "70703 Pressure ulcer, low back",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70704",
                "title": "70704 Pressure ulcer, hip",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70705",
                "title": "70705 Pressure ulcer, buttock",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70706",
                "title": "70706 Pressure ulcer, ankle",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70707",
                "title": "70707 Pressure ulcer, heel",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70709",
                "title": "70709 Pressure ulcer, site NEC",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70710",
                "title": "70710 Ulcer of lower limb NOS",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70711",
                "title": "70711 Ulcer of thigh",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70712",
                "title": "70712 Ulcer of calf",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70713",
                "title": "70713 Ulcer of ankle",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70714",
                "title": "70714 Ulcer of heel & midfoot",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70715",
                "title": "70715 Ulcer other part of foot",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70719",
                "title": "70719 Ulcer oth part low limb",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70720",
                "title": "70720 Pressure ulcer,stage NOS",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70721",
                "title": "70721 Pressure ulcer, stage I",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70722",
                "title": "70722 Pressure ulcer, stage II",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70723",
                "title": "70723 Pressure ulcer,stage III",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70724",
                "title": "70724 Pressure ulcer, stage IV",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "70725",
                "title": "70725 Pressure ulcer,unstagebl",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "7078",
                "title": "7078 Chronic skin ulcer NEC",
                "threedigit": "707",
                "isLeaf": true
              },
              {
                "key": "7079",
                "title": "7079 Chronic skin ulcer NOS",
                "threedigit": "707",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Urticaria",
            "title": "708 - Urticaria",
            "threedigit": "708",
            "children": [
              {
                "key": "7080",
                "title": "7080 Allergic urticaria",
                "threedigit": "708",
                "isLeaf": true
              },
              {
                "key": "7081",
                "title": "7081 Idiopathic urticaria",
                "threedigit": "708",
                "isLeaf": true
              },
              {
                "key": "7082",
                "title": "7082 Urticaria from cold/heat",
                "threedigit": "708",
                "isLeaf": true
              },
              {
                "key": "7083",
                "title": "7083 Dermatographic urticaria",
                "threedigit": "708",
                "isLeaf": true
              },
              {
                "key": "7084",
                "title": "7084 Vibratory urticaria",
                "threedigit": "708",
                "isLeaf": true
              },
              {
                "key": "7085",
                "title": "7085 Cholinergic urticaria",
                "threedigit": "708",
                "isLeaf": true
              },
              {
                "key": "7088",
                "title": "7088 Urticaria NEC",
                "threedigit": "708",
                "isLeaf": true
              },
              {
                "key": "7089",
                "title": "7089 Urticaria NOS",
                "threedigit": "708",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of skin and subcutaneous tissue",
            "title": "709 - Other disorders of skin and subcutaneous tissue",
            "threedigit": "709",
            "children": [
              {
                "key": "70900",
                "title": "70900 Dyschromia, unspecified",
                "threedigit": "709",
                "isLeaf": true
              },
              {
                "key": "70901",
                "title": "70901 Vitiligo",
                "threedigit": "709",
                "isLeaf": true
              },
              {
                "key": "70909",
                "title": "70909 Other dyschromia",
                "threedigit": "709",
                "isLeaf": true
              },
              {
                "key": "7091",
                "title": "7091 Vascular disord of skin",
                "threedigit": "709",
                "isLeaf": true
              },
              {
                "key": "7092",
                "title": "7092 Scar & fibrosis of skin",
                "threedigit": "709",
                "isLeaf": true
              },
              {
                "key": "7093",
                "title": "7093 Degenerative skin disord",
                "threedigit": "709",
                "isLeaf": true
              },
              {
                "key": "7094",
                "title": "7094 Foreign body granul-skin",
                "threedigit": "709",
                "isLeaf": true
              },
              {
                "key": "7098",
                "title": "7098 Skin disorders NEC",
                "threedigit": "709",
                "isLeaf": true
              },
              {
                "key": "7099",
                "title": "7099 Skin disorder NOS",
                "threedigit": "709",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Diseases Of The Musculoskeletal System And Connective Tissue",
    "title": "710-739 Diseases Of The Musculoskeletal System And Connective Tissue",
    "threedigit": "710",
    "children": [
      {
        "key": "Arthropathies And Related Disorders",
        "title": "710-719 Arthropathies And Related Disorders",
        "threedigit": "710",
        "children": [
          {
            "key": "Diffuse diseases of connective tissue",
            "title": "710 - Diffuse diseases of connective tissue",
            "threedigit": "710",
            "children": [
              {
                "key": "7100",
                "title": "7100 Syst lupus erythematosus",
                "threedigit": "710",
                "isLeaf": true
              },
              {
                "key": "7101",
                "title": "7101 Systemic sclerosis",
                "threedigit": "710",
                "isLeaf": true
              },
              {
                "key": "7102",
                "title": "7102 Sicca syndrome",
                "threedigit": "710",
                "isLeaf": true
              },
              {
                "key": "7103",
                "title": "7103 Dermatomyositis",
                "threedigit": "710",
                "isLeaf": true
              },
              {
                "key": "7104",
                "title": "7104 Polymyositis",
                "threedigit": "710",
                "isLeaf": true
              },
              {
                "key": "7105",
                "title": "7105 Eosinophilia myalgia snd",
                "threedigit": "710",
                "isLeaf": true
              },
              {
                "key": "7108",
                "title": "7108 Diff connect tis dis NEC",
                "threedigit": "710",
                "isLeaf": true
              },
              {
                "key": "7109",
                "title": "7109 Diff connect tis dis NOS",
                "threedigit": "710",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Arthropathy associated with infections",
            "title": "711 - Arthropathy associated with infections",
            "threedigit": "711",
            "children": [
              {
                "key": "71100",
                "title": "71100 Pyogen arthritis-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71101",
                "title": "71101 Pyogen arthritis-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71102",
                "title": "71102 Pyogen arthritis-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71103",
                "title": "71103 Pyogen arthritis-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71104",
                "title": "71104 Pyogen arthritis-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71105",
                "title": "71105 Pyogen arthritis-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71106",
                "title": "71106 Pyogen arthritis-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71107",
                "title": "71107 Pyogen arthritis-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71108",
                "title": "71108 Pyogen arthritis NEC",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71109",
                "title": "71109 Pyogen arthritis-mult",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71110",
                "title": "71110 Reiter arthritis-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71111",
                "title": "71111 Reiter arthritis-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71112",
                "title": "71112 Reiter arthritis-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71113",
                "title": "71113 Reiter arthritis-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71114",
                "title": "71114 Reiter arthritis-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71115",
                "title": "71115 Reiter arthritis-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71116",
                "title": "71116 Reiter arthritis-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71117",
                "title": "71117 Reiter arthritis-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71118",
                "title": "71118 Reiter arthritis NEC",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71119",
                "title": "71119 Reiter arthritis-mult",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71120",
                "title": "71120 Behcet arthritis-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71121",
                "title": "71121 Behcet arthritis-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71122",
                "title": "71122 Behcet arthritis-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71123",
                "title": "71123 Behcet arthritis-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71124",
                "title": "71124 Behcet arthritis-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71125",
                "title": "71125 Behcet arthritis-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71126",
                "title": "71126 Behcet arthritis-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71127",
                "title": "71127 Behcet arthritis-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71128",
                "title": "71128 Behcet arthritis NEC",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71129",
                "title": "71129 Behcet arthritis-mult",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71130",
                "title": "71130 Dysenter arthrit-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71131",
                "title": "71131 Dysenter arthrit-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71132",
                "title": "71132 Dysenter arthrit-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71133",
                "title": "71133 Dysenter arthrit-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71134",
                "title": "71134 Dysenter arthrit-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71135",
                "title": "71135 Dysenter arthrit-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71136",
                "title": "71136 Dysenter arthrit-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71137",
                "title": "71137 Dysenter arthrit-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71138",
                "title": "71138 Dysenter arthrit NEC",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71139",
                "title": "71139 Dysenter arthrit-mult",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71140",
                "title": "71140 Bact arthritis-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71141",
                "title": "71141 Bact arthritis-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71142",
                "title": "71142 Bact arthritis-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71143",
                "title": "71143 Bact arthritis-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71144",
                "title": "71144 Bact arthritis-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71145",
                "title": "71145 Bact arthritis-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71146",
                "title": "71146 Bact arthritis-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71147",
                "title": "71147 Bact arthritis-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71148",
                "title": "71148 Bact arthritis NEC",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71149",
                "title": "71149 Bact arthritis-mult",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71150",
                "title": "71150 Viral arthritis-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71151",
                "title": "71151 Viral arthritis-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71152",
                "title": "71152 Viral arthritis-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71153",
                "title": "71153 Viral arthritis-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71154",
                "title": "71154 Viral arthritis-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71155",
                "title": "71155 Viral arthritis-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71156",
                "title": "71156 Viral arthritis-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71157",
                "title": "71157 Viral arthritis-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71158",
                "title": "71158 Viral arthritis NEC",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71159",
                "title": "71159 Viral arthritis-mult",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71160",
                "title": "71160 Mycotic arthritis-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71161",
                "title": "71161 Mycotic arthritis-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71162",
                "title": "71162 Mycotic arthritis-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71163",
                "title": "71163 Mycotic arthrit-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71164",
                "title": "71164 Mycotic arthritis-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71165",
                "title": "71165 Mycotic arthritis-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71166",
                "title": "71166 Mycotic arthritis-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71167",
                "title": "71167 Mycotic arthritis-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71168",
                "title": "71168 Mycotic arthritis NEC",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71169",
                "title": "71169 Mycotic arthritis-mult",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71170",
                "title": "71170 Helminth arthrit-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71171",
                "title": "71171 Helminth arthrit-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71172",
                "title": "71172 Helminth arthrit-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71173",
                "title": "71173 Helminth arthrit-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71174",
                "title": "71174 Helminth arthrit-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71175",
                "title": "71175 Helminth arthrit-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71176",
                "title": "71176 Helminth arthrit-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71177",
                "title": "71177 Helminth arthrit-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71178",
                "title": "71178 Helminth arthrit NEC",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71179",
                "title": "71179 Helminth arthrit-mult",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71180",
                "title": "71180 Inf arthritis NEC-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71181",
                "title": "71181 Inf arthritis NEC-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71182",
                "title": "71182 Inf arthritis NEC-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71183",
                "title": "71183 Inf arthrit NEC-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71184",
                "title": "71184 Inf arthritis NEC-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71185",
                "title": "71185 Inf arthritis NEC-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71186",
                "title": "71186 Inf arthritis NEC-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71187",
                "title": "71187 Inf arthritis NEC-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71188",
                "title": "71188 Inf arthrit NEC-oth site",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71189",
                "title": "71189 Inf arthritis NEC-mult",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71190",
                "title": "71190 Inf arthritis NOS-unspec",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71191",
                "title": "71191 Inf arthritis NOS-shlder",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71192",
                "title": "71192 Inf arthritis NOS-up/arm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71193",
                "title": "71193 Inf arthrit NOS-forearm",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71194",
                "title": "71194 Inf arthrit NOS-hand",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71195",
                "title": "71195 Inf arthrit NOS-pelvis",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71196",
                "title": "71196 Inf arthrit NOS-l/leg",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71197",
                "title": "71197 Inf arthrit NOS-ankle",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71198",
                "title": "71198 Inf arthrit NOS-oth site",
                "threedigit": "711",
                "isLeaf": true
              },
              {
                "key": "71199",
                "title": "71199 Inf arthritis NOS-mult",
                "threedigit": "711",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Crystal arthropathies",
            "title": "712 - Crystal arthropathies",
            "threedigit": "712",
            "children": [
              {
                "key": "71210",
                "title": "71210 Dicalc phos cryst-unspec",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71211",
                "title": "71211 Dicalc phos cryst-shlder",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71212",
                "title": "71212 Dicalc phos cryst-up/arm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71213",
                "title": "71213 Dicalc phos crys-forearm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71214",
                "title": "71214 Dicalc phos cryst-hand",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71215",
                "title": "71215 Dicalc phos cryst-pelvis",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71216",
                "title": "71216 Dicalc phos cryst-l/leg",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71217",
                "title": "71217 Dicalc phos cryst-ankle",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71218",
                "title": "71218 Dicalc phos cry-site NEC",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71219",
                "title": "71219 Dicalc phos cryst-mult",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71220",
                "title": "71220 Pyrophosph cryst-unspec",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71221",
                "title": "71221 Pyrophosph cryst-shlder",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71222",
                "title": "71222 Pyrophosph cryst-up/arm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71223",
                "title": "71223 Pyrophosph cryst-forearm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71224",
                "title": "71224 Pyrophosph cryst-hand",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71225",
                "title": "71225 Pyrophosph cryst-pelvis",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71226",
                "title": "71226 Pyrophosph cryst-l/leg",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71227",
                "title": "71227 Pyrophosph cryst-ankle",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71228",
                "title": "71228 Pyrophos cryst-site NEC",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71229",
                "title": "71229 Pyrophos cryst-mult",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71230",
                "title": "71230 Chondrocalcin NOS-unspec",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71231",
                "title": "71231 Chondrocalcin NOS-shlder",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71232",
                "title": "71232 Chondrocalcin NOS-up/arm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71233",
                "title": "71233 Chondrocalc NOS-forearm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71234",
                "title": "71234 Chondrocalcin NOS-hand",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71235",
                "title": "71235 Chondrocalcin NOS-pelvis",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71236",
                "title": "71236 Chondrocalcin NOS-l/leg",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71237",
                "title": "71237 Chondrocalcin NOS-ankle",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71238",
                "title": "71238 Chondrocalc NOS-oth site",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71239",
                "title": "71239 Chondrocalcin NOS-mult",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71280",
                "title": "71280 Cryst arthrop NEC-unspec",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71281",
                "title": "71281 Cryst arthrop NEC-shlder",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71282",
                "title": "71282 Cryst arthrop NEC-up/arm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71283",
                "title": "71283 Crys arthrop NEC-forearm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71284",
                "title": "71284 Cryst arthrop NEC-hand",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71285",
                "title": "71285 Cryst arthrop NEC-pelvis",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71286",
                "title": "71286 Cryst arthrop NEC-l/leg",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71287",
                "title": "71287 Cryst arthrop NEC-ankle",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71288",
                "title": "71288 Cry arthrop NEC-oth site",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71289",
                "title": "71289 Cryst arthrop NEC-mult",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71290",
                "title": "71290 Cryst arthrop NOS-unspec",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71291",
                "title": "71291 Cryst arthrop NOS-shldr",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71292",
                "title": "71292 Cryst arthrop NOS-up/arm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71293",
                "title": "71293 Crys arthrop NOS-forearm",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71294",
                "title": "71294 Cryst arthrop NOS-hand",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71295",
                "title": "71295 Cryst arthrop NOS-pelvis",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71296",
                "title": "71296 Cryst arthrop NOS-l/leg",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71297",
                "title": "71297 Cryst arthrop NOS-ankle",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71298",
                "title": "71298 Cry arthrop NOS-oth site",
                "threedigit": "712",
                "isLeaf": true
              },
              {
                "key": "71299",
                "title": "71299 Cryst arthrop NOS-mult",
                "threedigit": "712",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Arthropathy associated with other disorders classified elsewhere",
            "title": "713 - Arthropathy associated with other disorders classified elsewhere",
            "threedigit": "713",
            "children": [
              {
                "key": "7130",
                "title": "7130 Arthrop w endocr/met dis",
                "threedigit": "713",
                "isLeaf": true
              },
              {
                "key": "7131",
                "title": "7131 Arthrop w noninf GI dis",
                "threedigit": "713",
                "isLeaf": true
              },
              {
                "key": "7132",
                "title": "7132 Arthropath w hematol dis",
                "threedigit": "713",
                "isLeaf": true
              },
              {
                "key": "7133",
                "title": "7133 Arthropathy w skin dis",
                "threedigit": "713",
                "isLeaf": true
              },
              {
                "key": "7134",
                "title": "7134 Arthropathy w resp dis",
                "threedigit": "713",
                "isLeaf": true
              },
              {
                "key": "7135",
                "title": "7135 Arthropathy w nerve dis",
                "threedigit": "713",
                "isLeaf": true
              },
              {
                "key": "7136",
                "title": "7136 Arthrop w hypersen react",
                "threedigit": "713",
                "isLeaf": true
              },
              {
                "key": "7137",
                "title": "7137 Arthrop w system dis NEC",
                "threedigit": "713",
                "isLeaf": true
              },
              {
                "key": "7138",
                "title": "7138 Arthrop w oth dis NEC",
                "threedigit": "713",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Rheumatoid arthritis and other inflammatory polyarthropathies",
            "title": "714 - Rheumatoid arthritis and other inflammatory polyarthropathies",
            "threedigit": "714",
            "children": [
              {
                "key": "7140",
                "title": "7140 Rheumatoid arthritis",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "7141",
                "title": "7141 Felty's syndrome",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "7142",
                "title": "7142 Syst rheum arthritis NEC",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "71430",
                "title": "71430 Juv rheum arthritis NOS",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "71431",
                "title": "71431 Polyart juv rheum arthr",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "71432",
                "title": "71432 Pauciart juv rheum arthr",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "71433",
                "title": "71433 Monoart juv rheum arthr",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "7144",
                "title": "7144 Chr postrheum arthritis",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "71481",
                "title": "71481 Rheumatoid lung",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "71489",
                "title": "71489 Inflamm polyarthrop NEC",
                "threedigit": "714",
                "isLeaf": true
              },
              {
                "key": "7149",
                "title": "7149 Inflamm polyarthrop NOS",
                "threedigit": "714",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Osteoarthrosis and allied disorders",
            "title": "715 - Osteoarthrosis and allied disorders",
            "threedigit": "715",
            "children": [
              {
                "key": "71500",
                "title": "71500 General osteoarthrosis",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71504",
                "title": "71504 Gen osteoarthros-hand",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71509",
                "title": "71509 General osteoarthrosis",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71510",
                "title": "71510 Loc prim osteoart-unspec",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71511",
                "title": "71511 Loc prim osteoart-shlder",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71512",
                "title": "71512 Loc prim osteoart-up/arm",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71513",
                "title": "71513 Loc prim osteoart-forarm",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71514",
                "title": "71514 Loc prim osteoarth-hand",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71515",
                "title": "71515 Loc prim osteoart-pelvis",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71516",
                "title": "71516 Loc prim osteoart-l/leg",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71517",
                "title": "71517 Loc prim osteoarth-ankle",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71518",
                "title": "71518 Loc prim osteoarthr NEC",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71520",
                "title": "71520 Loc 2nd osteoarth-unspec",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71521",
                "title": "71521 Loc 2nd osteoarth-shlder",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71522",
                "title": "71522 Loc 2nd osteoarth-up/arm",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71523",
                "title": "71523 Loc 2nd osteoart-forearm",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71524",
                "title": "71524 Loc 2nd osteoarthro-hand",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71525",
                "title": "71525 Loc 2nd osteoarth-pelvis",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71526",
                "title": "71526 Loc 2nd osteoarthr-l/leg",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71527",
                "title": "71527 Loc 2nd osteoarthr-ankle",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71528",
                "title": "71528 Loc 2nd osteoarthros NEC",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71530",
                "title": "71530 Loc osteoarth NOS-unspec",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71531",
                "title": "71531 Loc osteoarth NOS-shlder",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71532",
                "title": "71532 Loc osteoarth NOS-up/arm",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71533",
                "title": "71533 Loc osteoart NOS-forearm",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71534",
                "title": "71534 Loc osteoarth NOS-hand",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71535",
                "title": "71535 Loc osteoarth NOS-pelvis",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71536",
                "title": "71536 Loc osteoarth NOS-l/leg",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71537",
                "title": "71537 Loc osteoarth NOS-ankle",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71538",
                "title": "71538 Loc osteoar NOS-site NEC",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71580",
                "title": "71580 Osteoarthrosis-mult site",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71589",
                "title": "71589 Osteoarthrosis-mult site",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71590",
                "title": "71590 Osteoarthros NOS-unspec",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71591",
                "title": "71591 Osteoarthros NOS-shlder",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71592",
                "title": "71592 Osteoarthros NOS-up/arm",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71593",
                "title": "71593 Osteoarthros NOS-forearm",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71594",
                "title": "71594 Osteoarthros NOS-hand",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71595",
                "title": "71595 Osteoarthros NOS-pelvis",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71596",
                "title": "71596 Osteoarthros NOS-l/leg",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71597",
                "title": "71597 Osteoarthros NOS-ankle",
                "threedigit": "715",
                "isLeaf": true
              },
              {
                "key": "71598",
                "title": "71598 Osteoarthro NOS-oth site",
                "threedigit": "715",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified arthropathies",
            "title": "716 - Other and unspecified arthropathies",
            "threedigit": "716",
            "children": [
              {
                "key": "71600",
                "title": "71600 Kaschin-beck dis-unspec",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71601",
                "title": "71601 Kaschin-beck dis-shlder",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71602",
                "title": "71602 Kaschin-beck dis-up/arm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71603",
                "title": "71603 Kaschin-beck dis-forearm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71604",
                "title": "71604 Kaschin-beck dis-hand",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71605",
                "title": "71605 Kaschin-beck dis-pelvis",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71606",
                "title": "71606 Kaschin-beck dis-l/leg",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71607",
                "title": "71607 Kaschin-beck dis-ankle",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71608",
                "title": "71608 Kaschin-beck dis NEC",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71609",
                "title": "71609 Kaschin-beck dis-mult",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71610",
                "title": "71610 Traum arthropathy-unspec",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71611",
                "title": "71611 Traum arthropathy-shlder",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71612",
                "title": "71612 Traum arthropathy-up/arm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71613",
                "title": "71613 Traum arthropath-forearm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71614",
                "title": "71614 Traum arthropathy-hand",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71615",
                "title": "71615 Traum arthropathy-pelvis",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71616",
                "title": "71616 Traum arthropathy-l/leg",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71617",
                "title": "71617 Traum arthropathy-ankle",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71618",
                "title": "71618 Traum arthropathy NEC",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71619",
                "title": "71619 Traum arthropathy-mult",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71620",
                "title": "71620 Allerg arthritis-unspec",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71621",
                "title": "71621 Allerg arthritis-shlder",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71622",
                "title": "71622 Allerg arthritis-up/arm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71623",
                "title": "71623 Allerg arthritis-forearm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71624",
                "title": "71624 Allerg arthritis-hand",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71625",
                "title": "71625 Allerg arthritis-pelvis",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71626",
                "title": "71626 Allerg arthritis-l/leg",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71627",
                "title": "71627 Allerg arthritis-ankle",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71628",
                "title": "71628 Allerg arthritis NEC",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71629",
                "title": "71629 Allerg arthritis-mult",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71630",
                "title": "71630 Climact arthritis-unspec",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71631",
                "title": "71631 Climact arthritis-shlder",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71632",
                "title": "71632 Climact arthritis-up/arm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71633",
                "title": "71633 Climact arthrit-forearm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71634",
                "title": "71634 Climact arthritis-hand",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71635",
                "title": "71635 Climact arthritis-pelvis",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71636",
                "title": "71636 Climact arthritis-l/leg",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71637",
                "title": "71637 Climact arthritis-ankle",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71638",
                "title": "71638 Climact arthritis NEC",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71639",
                "title": "71639 Climact arthritis-mult",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71640",
                "title": "71640 Trans arthropathy-unspec",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71641",
                "title": "71641 Trans arthropathy-shlder",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71642",
                "title": "71642 Trans arthropathy-up/arm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71643",
                "title": "71643 Trans arthropath-forearm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71644",
                "title": "71644 Trans arthropathy-hand",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71645",
                "title": "71645 Trans arthropathy-pelvis",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71646",
                "title": "71646 Trans arthropathy-l/leg",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71647",
                "title": "71647 Trans arthropathy-ankle",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71648",
                "title": "71648 Trans arthropathy NEC",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71649",
                "title": "71649 Trans arthropathy-mult",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71650",
                "title": "71650 Polyarthritis NOS-unspec",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71651",
                "title": "71651 Polyarthritis NOS-shlder",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71652",
                "title": "71652 Polyarthritis NOS-up/arm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71653",
                "title": "71653 Polyarthrit NOS-forearm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71654",
                "title": "71654 Polyarthritis NOS-hand",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71655",
                "title": "71655 Polyarthritis NOS-pelvis",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71656",
                "title": "71656 Polyarthritis NOS-l/leg",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71657",
                "title": "71657 Polyarthritis NOS-ankle",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71658",
                "title": "71658 Polyarthrit NOS-oth site",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71659",
                "title": "71659 Polyarthritis NOS-mult",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71660",
                "title": "71660 Monoarthritis NOS-unspec",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71661",
                "title": "71661 Monoarthritis NOS-shlder",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71662",
                "title": "71662 Monoarthritis NOS-up/arm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71663",
                "title": "71663 Monoarthrit NOS-forearm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71664",
                "title": "71664 Monoarthritis NOS-hand",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71665",
                "title": "71665 Monoarthritis NOS-pelvis",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71666",
                "title": "71666 Monoarthritis NOS-l/leg",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71667",
                "title": "71667 Monoarthritis NOS-ankle",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71668",
                "title": "71668 Monoarthrit NOS-oth site",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71680",
                "title": "71680 Arthropathy NEC-unspec",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71681",
                "title": "71681 Arthropathy NEC-shlder",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71682",
                "title": "71682 Arthropathy NEC-up/arm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71683",
                "title": "71683 Arthropathy NEC-forearm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71684",
                "title": "71684 Arthropathy NEC-hand",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71685",
                "title": "71685 Arthropathy NEC-pelvis",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71686",
                "title": "71686 Arthropathy NEC-l/leg",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71687",
                "title": "71687 Arthropathy NEC-ankle",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71688",
                "title": "71688 Arthropathy NEC-oth site",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71689",
                "title": "71689 Arthropathy NEC-mult",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71690",
                "title": "71690 Arthropathy NOS-unspec",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71691",
                "title": "71691 Arthropathy NOS-shlder",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71692",
                "title": "71692 Arthropathy NOS-up/arm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71693",
                "title": "71693 Arthropathy NOS-forearm",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71694",
                "title": "71694 Arthropathy NOS-hand",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71695",
                "title": "71695 Arthropathy NOS-pelvis",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71696",
                "title": "71696 Arthropathy NOS-l/leg",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71697",
                "title": "71697 Arthropathy NOS-ankle",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71698",
                "title": "71698 Arthropathy NOS-oth site",
                "threedigit": "716",
                "isLeaf": true
              },
              {
                "key": "71699",
                "title": "71699 Arthropathy NOS-mult",
                "threedigit": "716",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Internal derangement of knee",
            "title": "717 - Internal derangement of knee",
            "threedigit": "717",
            "children": [
              {
                "key": "7170",
                "title": "7170 Old bucket tear med men",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "7171",
                "title": "7171 Derang ant med meniscus",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "7172",
                "title": "7172 Derang post med meniscus",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "7173",
                "title": "7173 Derang med meniscus NEC",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71740",
                "title": "71740 Derang lat meniscus NOS",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71741",
                "title": "71741 Old bucket tear lat men",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71742",
                "title": "71742 Derange ant lat meniscus",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71743",
                "title": "71743 Derang post lat meniscus",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71749",
                "title": "71749 Derang lat meniscus NEC",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "7175",
                "title": "7175 Derangement meniscus NEC",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "7176",
                "title": "7176 Loose body in knee",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "7177",
                "title": "7177 Chondromalacia patellae",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71781",
                "title": "71781 Old disrupt lat collat",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71782",
                "title": "71782 Old disrupt med collat",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71783",
                "title": "71783 Old disrupt ant cruciate",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71784",
                "title": "71784 Old disrupt post cruciat",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71785",
                "title": "71785 Old disrupt knee lig NEC",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "71789",
                "title": "71789 Int derangement knee NEC",
                "threedigit": "717",
                "isLeaf": true
              },
              {
                "key": "7179",
                "title": "7179 Int derangement knee NOS",
                "threedigit": "717",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other derangement of joint",
            "title": "718 - Other derangement of joint",
            "threedigit": "718",
            "children": [
              {
                "key": "71800",
                "title": "71800 Artic cartil dis-unspec",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71801",
                "title": "71801 Artic cartil dis-shlder",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71802",
                "title": "71802 Artic cartil dis-up/arm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71803",
                "title": "71803 Artic cartil dis-forearm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71804",
                "title": "71804 Artic cartil dis-hand",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71805",
                "title": "71805 Artic cartil dis-pelvis",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71807",
                "title": "71807 Artic cartil dis-ankle",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71808",
                "title": "71808 Artic cartil dis-jt NEC",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71809",
                "title": "71809 Artic cartil dis-mult jt",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71810",
                "title": "71810 Loose body-unspec",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71811",
                "title": "71811 Loose body-shlder",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71812",
                "title": "71812 Loose body-up/arm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71813",
                "title": "71813 Loose body-forearm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71814",
                "title": "71814 Loose body-hand",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71815",
                "title": "71815 Loose body-pelvis",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71817",
                "title": "71817 Loose body-ankle",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71818",
                "title": "71818 Loose body-joint NEC",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71819",
                "title": "71819 Loose body-mult joints",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71820",
                "title": "71820 Pathol dislocat-unspec",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71821",
                "title": "71821 Pathol dislocat-shlder",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71822",
                "title": "71822 Pathol dislocat-up/arm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71823",
                "title": "71823 Pathol dislocat-forearm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71824",
                "title": "71824 Pathol dislocat-hand",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71825",
                "title": "71825 Pathol dislocat-pelvis",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71826",
                "title": "71826 Pathol dislocat-l/leg",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71827",
                "title": "71827 Pathol dislocat-ankle",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71828",
                "title": "71828 Pathol dislocat-jt NEC",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71829",
                "title": "71829 Pathol dislocat-mult jts",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71830",
                "title": "71830 Recur dislocat-unspec",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71831",
                "title": "71831 Recur dislocat-shlder",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71832",
                "title": "71832 Recur dislocat-up/arm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71833",
                "title": "71833 Recur dislocat-forearm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71834",
                "title": "71834 Recur dislocat-hand",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71835",
                "title": "71835 Recur dislocat-pelvis",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71836",
                "title": "71836 Recur dislocat-l/leg",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71837",
                "title": "71837 Recur dislocat-ankle",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71838",
                "title": "71838 Recur dislocat-jt NEC",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71839",
                "title": "71839 Recur dislocat-mult jts",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71840",
                "title": "71840 Jt contracture-unspec",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71841",
                "title": "71841 Jt contracture-shlder",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71842",
                "title": "71842 Jt contracture-up/arm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71843",
                "title": "71843 Jt contracture-forearm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71844",
                "title": "71844 Jt contracture-hand",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71845",
                "title": "71845 Jt contracture-pelvis",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71846",
                "title": "71846 Jt contracture-l/leg",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71847",
                "title": "71847 Jt contracture-ankle",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71848",
                "title": "71848 Jt contracture-jt NEC",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71849",
                "title": "71849 Jt contracture-mult jts",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71850",
                "title": "71850 Ankylosis-unspec",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71851",
                "title": "71851 Ankylosis-shoulder",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71852",
                "title": "71852 Ankylosis-upper/arm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71853",
                "title": "71853 Ankylosis-forearm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71854",
                "title": "71854 Ankylosis-hand",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71855",
                "title": "71855 Ankylosis-pelvis",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71856",
                "title": "71856 Ankylosis-lower/leg",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71857",
                "title": "71857 Ankylosis-ankle",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71858",
                "title": "71858 Ankylosis-joint NEC",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71859",
                "title": "71859 Ankylosis-mult joints",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71865",
                "title": "71865 Protrusio acetabuli NOS",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71870",
                "title": "71870 Dev dislocat jt site NOS",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71871",
                "title": "71871 Dev dislocat joint-shldr",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71872",
                "title": "71872 Dev dislocat jt-up/arm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71873",
                "title": "71873 Dev dislocat jt-forearm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71874",
                "title": "71874 Dev dislocat joint-hand",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71875",
                "title": "71875 Dev dis jt-pelvic/thigh",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71876",
                "title": "71876 Dev disloc jt-lower leg",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71877",
                "title": "71877 Dev disloc jt-ankle/foot",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71878",
                "title": "71878 Dev disloc jt-site NEC",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71879",
                "title": "71879 Dev disloc jt-mult sites",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71880",
                "title": "71880 Jt derangmnt NEC-unsp jt",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71881",
                "title": "71881 Jt derangment NEC-shlder",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71882",
                "title": "71882 Jt derangment NEC-up/arm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71883",
                "title": "71883 Jt derangmnt NEC-forearm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71884",
                "title": "71884 Jt derangement NEC-hand",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71885",
                "title": "71885 Jt derangment NEC-pelvis",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71886",
                "title": "71886 Jt derangement NEC-l/leg",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71887",
                "title": "71887 Jt derangement NEC-ankle",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71888",
                "title": "71888 Jt derangment NEC-oth jt",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71889",
                "title": "71889 Jt derangement NEC-mult",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71890",
                "title": "71890 Jt derangmnt NOS-unsp jt",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71891",
                "title": "71891 Jt derangment NOS-shlder",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71892",
                "title": "71892 Jt derangment NOS-up/arm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71893",
                "title": "71893 Jt derangmnt NOS-forearm",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71894",
                "title": "71894 Jt derangement NOS-hand",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71895",
                "title": "71895 Jt derangment NOS-pelvis",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71897",
                "title": "71897 Jt derangement NOS-ankle",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71898",
                "title": "71898 Jt derangment NOS-oth jt",
                "threedigit": "718",
                "isLeaf": true
              },
              {
                "key": "71899",
                "title": "71899 Jt derangement NOS-mult",
                "threedigit": "718",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified disorders of joint",
            "title": "719 - Other and unspecified disorders of joint",
            "threedigit": "719",
            "children": [
              {
                "key": "71900",
                "title": "71900 Joint effusion-unspec",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71901",
                "title": "71901 Joint effusion-shlder",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71902",
                "title": "71902 Joint effusion-up/arm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71903",
                "title": "71903 Joint effusion-forearm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71904",
                "title": "71904 Joint effusion-hand",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71905",
                "title": "71905 Joint effusion-pelvis",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71906",
                "title": "71906 Joint effusion-l/leg",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71907",
                "title": "71907 Joint effusion-ankle",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71908",
                "title": "71908 Joint effusion-jt NEC",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71909",
                "title": "71909 Joint effusion-mult jts",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71910",
                "title": "71910 Hemarthrosis-unspec",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71911",
                "title": "71911 Hemarthrosis-shlder",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71912",
                "title": "71912 Hemarthrosis-up/arm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71913",
                "title": "71913 Hemarthrosis-forearm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71914",
                "title": "71914 Hemarthrosis-hand",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71915",
                "title": "71915 Hemarthrosis-pelvis",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71916",
                "title": "71916 Hemarthrosis-l/leg",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71917",
                "title": "71917 Hemarthrosis-ankle",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71918",
                "title": "71918 Hemarthrosis-jt NEC",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71919",
                "title": "71919 Hemarthrosis-mult jts",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71920",
                "title": "71920 Villonod synovit-unspec",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71921",
                "title": "71921 Villonod synovit-shlder",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71922",
                "title": "71922 Villonod synovit-up/arm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71923",
                "title": "71923 Villonod synovit-forearm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71924",
                "title": "71924 Villonod synovit-hand",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71925",
                "title": "71925 Villonod synovit-pelvis",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71926",
                "title": "71926 Villonod synovit-l/leg",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71927",
                "title": "71927 Villonod synovit-ankle",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71928",
                "title": "71928 Villonod synovit-jt NEC",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71929",
                "title": "71929 Villonod synovit-mult jt",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71930",
                "title": "71930 Palindrom rheum-unspec",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71931",
                "title": "71931 Palindrom rheum-shlder",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71932",
                "title": "71932 Palindrom rheum-up/arm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71933",
                "title": "71933 Palindrom rheum-forearm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71934",
                "title": "71934 Palindrom rheum-hand",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71935",
                "title": "71935 Palindrom rheum-pelvis",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71936",
                "title": "71936 Palindrom rheum-l/leg",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71937",
                "title": "71937 Palindrom rheum-ankle",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71938",
                "title": "71938 Palindrom rheum-jt NEC",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71939",
                "title": "71939 Palindrom rheum-mult jts",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71940",
                "title": "71940 Joint pain-unspec",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71941",
                "title": "71941 Joint pain-shlder",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71942",
                "title": "71942 Joint pain-up/arm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71943",
                "title": "71943 Joint pain-forearm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71944",
                "title": "71944 Joint pain-hand",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71945",
                "title": "71945 Joint pain-pelvis",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71946",
                "title": "71946 Joint pain-l/leg",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71947",
                "title": "71947 Joint pain-ankle",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71948",
                "title": "71948 Joint pain-jt NEC",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71949",
                "title": "71949 Joint pain-mult jts",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71950",
                "title": "71950 Jt stiffness NEC-unspec",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71951",
                "title": "71951 Jt stiffness NEC-shlder",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71952",
                "title": "71952 Jt stiffness NEC-up/arm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71953",
                "title": "71953 Jt stiffnes NEC-forearm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71954",
                "title": "71954 Jt stiffness NEC-hand",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71955",
                "title": "71955 Jt stiffness NEC-pelvis",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71956",
                "title": "71956 Jt stiffness NEC-l/leg",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71957",
                "title": "71957 Jt stiffness NEC-ankle",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71958",
                "title": "71958 Jt stiffness NEC-oth jt",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71959",
                "title": "71959 Jt stiffness NEC-mult jt",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71960",
                "title": "71960 Joint sympt NEC-unsp jt",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71961",
                "title": "71961 Joint symptom NEC-shlder",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71962",
                "title": "71962 Joint symptom NEC-up/arm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71963",
                "title": "71963 Joint sympt NEC-forearm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71964",
                "title": "71964 Joint symptom NEC-hand",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71965",
                "title": "71965 Joint symptom NEC-pelvis",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71966",
                "title": "71966 Joint symptom NEC-l/leg",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71967",
                "title": "71967 Joint symptom NEC-ankle",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71968",
                "title": "71968 Joint symptom NEC-oth jt",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71969",
                "title": "71969 Joint sympt NEC-mult jts",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "7197",
                "title": "7197 Difficulty in walking",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71980",
                "title": "71980 Joint dis NEC-unspec",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71981",
                "title": "71981 Joint dis NEC-shlder",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71982",
                "title": "71982 Joint dis NEC-up/arm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71983",
                "title": "71983 Joint dis NEC-forearm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71984",
                "title": "71984 Joint dis NEC-hand",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71985",
                "title": "71985 Joint dis NEC-pelvis",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71986",
                "title": "71986 Joint dis NEC-l/leg",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71987",
                "title": "71987 Joint dis NEC-ankle",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71988",
                "title": "71988 Joint dis NEC-oth jt",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71989",
                "title": "71989 Joint dis NEC-mult jts",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71990",
                "title": "71990 Joint dis NOS-unspec jt",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71991",
                "title": "71991 Joint dis NOS-shlder",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71992",
                "title": "71992 Joint dis NOS-up/arm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71993",
                "title": "71993 Joint dis NOS-forearm",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71994",
                "title": "71994 Joint dis NOS-hand",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71995",
                "title": "71995 Joint dis NOS-pelvis",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71996",
                "title": "71996 Joint dis NOS-l/leg",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71997",
                "title": "71997 Joint dis NOS-ankle",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71998",
                "title": "71998 Joint dis NOS-oth jt",
                "threedigit": "719",
                "isLeaf": true
              },
              {
                "key": "71999",
                "title": "71999 Joint dis NOS-mult jts",
                "threedigit": "719",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Dorsopathies",
        "title": "720-724 Dorsopathies",
        "threedigit": "720",
        "children": [
          {
            "key": "Ankylosing spondylitis and other inflammatory spondylopathies",
            "title": "720 - Ankylosing spondylitis and other inflammatory spondylopathies",
            "threedigit": "720",
            "children": [
              {
                "key": "7200",
                "title": "7200 Ankylosing spondylitis",
                "threedigit": "720",
                "isLeaf": true
              },
              {
                "key": "7201",
                "title": "7201 Spinal enthesopathy",
                "threedigit": "720",
                "isLeaf": true
              },
              {
                "key": "7202",
                "title": "7202 Sacroiliitis NEC",
                "threedigit": "720",
                "isLeaf": true
              },
              {
                "key": "72081",
                "title": "72081 Spondylopathy in oth dis",
                "threedigit": "720",
                "isLeaf": true
              },
              {
                "key": "72089",
                "title": "72089 Inflam spondylopathy NEC",
                "threedigit": "720",
                "isLeaf": true
              },
              {
                "key": "7209",
                "title": "7209 Inflam spondylopathy NOS",
                "threedigit": "720",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Spondylosis and allied disorders",
            "title": "721 - Spondylosis and allied disorders",
            "threedigit": "721",
            "children": [
              {
                "key": "7210",
                "title": "7210 Cervical spondylosis",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "7211",
                "title": "7211 Cerv spondyl w myelopath",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "7212",
                "title": "7212 Thoracic spondylosis",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "7213",
                "title": "7213 Lumbosacral spondylosis",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "72141",
                "title": "72141 Spond compr thor sp cord",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "72142",
                "title": "72142 Spond compr lumb sp cord",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "7215",
                "title": "7215 Kissing spine",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "7216",
                "title": "7216 Ankyl vert hyperostosis",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "7217",
                "title": "7217 Traumatic spondylopathy",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "7218",
                "title": "7218 Spinal disorders NEC",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "72190",
                "title": "72190 Spondylos NOS w/o myelop",
                "threedigit": "721",
                "isLeaf": true
              },
              {
                "key": "72191",
                "title": "72191 Spondylosis NOS w myelop",
                "threedigit": "721",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Intervertebral disc disorders",
            "title": "722 - Intervertebral disc disorders",
            "threedigit": "722",
            "children": [
              {
                "key": "7220",
                "title": "7220 Cervical disc displacmnt",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72210",
                "title": "72210 Lumbar disc displacement",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72211",
                "title": "72211 Thoracic disc displacmnt",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "7222",
                "title": "7222 Disc displacement NOS",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72230",
                "title": "72230 Schmorl's nodes NOS",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72231",
                "title": "72231 Schmorls node-thoracic",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72232",
                "title": "72232 Schmorls node-lumbar",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72239",
                "title": "72239 Schmorls node-region NEC",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "7224",
                "title": "7224 Cervical disc degen",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72251",
                "title": "72251 Thoracic disc degen",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72252",
                "title": "72252 Lumb/lumbosac disc degen",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "7226",
                "title": "7226 Disc degeneration NOS",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72270",
                "title": "72270 Disc dis w myelopath NOS",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72271",
                "title": "72271 Cerv disc dis w myelopat",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72272",
                "title": "72272 Thor disc dis w myelopat",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72273",
                "title": "72273 Lumb disc dis w myelopat",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72280",
                "title": "72280 Postlaminectomy synd NOS",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72281",
                "title": "72281 Postlaminect synd-cerv",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72282",
                "title": "72282 Postlaminect synd-thorac",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72283",
                "title": "72283 Postlaminect synd-lumbar",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72290",
                "title": "72290 Disc dis NEC/NOS-unspec",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72291",
                "title": "72291 Disc dis NEC/NOS-cerv",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72292",
                "title": "72292 Disc dis NEC/NOS-thorac",
                "threedigit": "722",
                "isLeaf": true
              },
              {
                "key": "72293",
                "title": "72293 Disc dis NEC/NOS-lumbar",
                "threedigit": "722",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of cervical region",
            "title": "723 - Other disorders of cervical region",
            "threedigit": "723",
            "children": [
              {
                "key": "7230",
                "title": "7230 Cervical spinal stenosis",
                "threedigit": "723",
                "isLeaf": true
              },
              {
                "key": "7231",
                "title": "7231 Cervicalgia",
                "threedigit": "723",
                "isLeaf": true
              },
              {
                "key": "7232",
                "title": "7232 Cervicocranial syndrome",
                "threedigit": "723",
                "isLeaf": true
              },
              {
                "key": "7233",
                "title": "7233 Cervicobrachial syndrome",
                "threedigit": "723",
                "isLeaf": true
              },
              {
                "key": "7234",
                "title": "7234 Brachial neuritis NOS",
                "threedigit": "723",
                "isLeaf": true
              },
              {
                "key": "7235",
                "title": "7235 Torticollis NOS",
                "threedigit": "723",
                "isLeaf": true
              },
              {
                "key": "7236",
                "title": "7236 Panniculitis of neck",
                "threedigit": "723",
                "isLeaf": true
              },
              {
                "key": "7237",
                "title": "7237 Ossification cerv lig",
                "threedigit": "723",
                "isLeaf": true
              },
              {
                "key": "7238",
                "title": "7238 Cervical syndrome NEC",
                "threedigit": "723",
                "isLeaf": true
              },
              {
                "key": "7239",
                "title": "7239 Neck disorder/sympt NOS",
                "threedigit": "723",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified disorders of back",
            "title": "724 - Other and unspecified disorders of back",
            "threedigit": "724",
            "children": [
              {
                "key": "72400",
                "title": "72400 Spinal stenosis NOS",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "72401",
                "title": "72401 Spinal stenosis-thoracic",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "72402",
                "title": "72402 Spin sten,lumbr wo claud",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "72403",
                "title": "72403 Spin sten,lumbr w claud",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "72409",
                "title": "72409 Spinal stenosis-oth site",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "7241",
                "title": "7241 Pain in thoracic spine",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "7242",
                "title": "7242 Lumbago",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "7243",
                "title": "7243 Sciatica",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "7244",
                "title": "7244 Lumbosacral neuritis NOS",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "7245",
                "title": "7245 Backache NOS",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "7246",
                "title": "7246 Disorders of sacrum",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "72470",
                "title": "72470 Disorder of coccyx NOS",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "72471",
                "title": "72471 Hypermobility of coccyx",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "72479",
                "title": "72479 Disorder of coccyx NEC",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "7248",
                "title": "7248 Other back symptoms",
                "threedigit": "724",
                "isLeaf": true
              },
              {
                "key": "7249",
                "title": "7249 Back disorder NOS",
                "threedigit": "724",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Rheumatism, Excluding The Back",
        "title": "725-729 Rheumatism, Excluding The Back",
        "threedigit": "725",
        "children": [
          {
            "key": "Polymyalgia rheumatica",
            "title": "725 - Polymyalgia rheumatica",
            "threedigit": "725",
            "children": [
              {
                "key": "725",
                "title": "725 Polymyalgia rheumatica",
                "threedigit": "725",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Peripheral enthesopathies and allied syndromes",
            "title": "726 - Peripheral enthesopathies and allied syndromes",
            "threedigit": "726",
            "children": [
              {
                "key": "7260",
                "title": "7260 Adhesive capsulit shlder",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72610",
                "title": "72610 Rotator cuff synd NOS",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72611",
                "title": "72611 Calcif tendinitis shlder",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72612",
                "title": "72612 Bicipital tenosynovitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72613",
                "title": "72613 Partial tear rotatr cuff",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72619",
                "title": "72619 Rotator cuff dis NEC",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "7262",
                "title": "7262 Shoulder region dis NEC",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72630",
                "title": "72630 Elbow enthesopathy NOS",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72631",
                "title": "72631 Medial epicondylitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72632",
                "title": "72632 Lateral epicondylitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72633",
                "title": "72633 Olecranon bursitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72639",
                "title": "72639 Elbow enthesopathy NEC",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "7264",
                "title": "7264 Enthesopathy of wrist",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "7265",
                "title": "7265 Enthesopathy of hip",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72660",
                "title": "72660 Enthesopathy of knee NOS",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72661",
                "title": "72661 Pes anserinus tendinitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72662",
                "title": "72662 Tibial coll lig bursitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72663",
                "title": "72663 Fibula coll lig bursitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72664",
                "title": "72664 Patellar tendinitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72665",
                "title": "72665 Prepatellar bursitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72669",
                "title": "72669 Enthesopathy of knee NEC",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72670",
                "title": "72670 Ankle enthesopathy NOS",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72671",
                "title": "72671 Achilles tendinitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72672",
                "title": "72672 Tibialis tendinitis",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72673",
                "title": "72673 Calcaneal spur",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72679",
                "title": "72679 Ankle enthesopathy NEC",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "7268",
                "title": "7268 Periph enthesopathy NEC",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72690",
                "title": "72690 Enthesopathy, site NOS",
                "threedigit": "726",
                "isLeaf": true
              },
              {
                "key": "72691",
                "title": "72691 Exostosis, site NOS",
                "threedigit": "726",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of synovium tendon and bursa",
            "title": "727 - Other disorders of synovium tendon and bursa",
            "threedigit": "727",
            "children": [
              {
                "key": "72700",
                "title": "72700 Synovitis NOS",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72701",
                "title": "72701 Synovitis in oth dis",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72702",
                "title": "72702 Giant cell tumor tendon",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72703",
                "title": "72703 Trigger finger",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72704",
                "title": "72704 Radial styloid tenosynov",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72705",
                "title": "72705 Tenosynov hand/wrist NEC",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72706",
                "title": "72706 Tenosynovitis foot/ankle",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72709",
                "title": "72709 Synovitis NEC",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "7271",
                "title": "7271 Bunion",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "7272",
                "title": "7272 Occupational bursitis",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "7273",
                "title": "7273 Bursitis NEC",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72740",
                "title": "72740 Synovial cyst NOS",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72741",
                "title": "72741 Ganglion of joint",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72742",
                "title": "72742 Ganglion of tendon",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72743",
                "title": "72743 Ganglion NOS",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72749",
                "title": "72749 Bursal cyst NEC",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72750",
                "title": "72750 Rupture of synovium NOS",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72751",
                "title": "72751 Popliteal synovial cyst",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72759",
                "title": "72759 Rupture of synovium NEC",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72760",
                "title": "72760 Nontraum tendon rupt NOS",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72761",
                "title": "72761 Rotator cuff rupture",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72762",
                "title": "72762 Biceps tendon rupture",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72763",
                "title": "72763 Rupt exten tendon hand",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72764",
                "title": "72764 Rupt flexor tendon hand",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72765",
                "title": "72765 Rupture quadricep tendon",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72766",
                "title": "72766 Rupture patellar tendon",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72767",
                "title": "72767 Rupture achilles tendon",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72768",
                "title": "72768 Rupture tendon foot NEC",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72769",
                "title": "72769 Nontraum tendon rupt NEC",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72781",
                "title": "72781 Contracture of tendon",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72782",
                "title": "72782 Calcium deposit tendon",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72783",
                "title": "72783 Plica syndrome",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "72789",
                "title": "72789 Synov/tend/bursa dis NEC",
                "threedigit": "727",
                "isLeaf": true
              },
              {
                "key": "7279",
                "title": "7279 Synov/tend/bursa dis NOS",
                "threedigit": "727",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders of muscle ligament and fascia",
            "title": "728 - Disorders of muscle ligament and fascia",
            "threedigit": "728",
            "children": [
              {
                "key": "7280",
                "title": "7280 Infective myositis",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72810",
                "title": "72810 Muscular calcificat NOS",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72811",
                "title": "72811 Prog myositis ossificans",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72812",
                "title": "72812 Traum myositis ossifican",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72813",
                "title": "72813 Postop heterotopic calc",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72819",
                "title": "72819 Muscular calcificat NEC",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "7282",
                "title": "7282 Musc disuse atrophy NEC",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "7283",
                "title": "7283 Muscle disorders NEC",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "7284",
                "title": "7284 Laxity of ligament",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "7285",
                "title": "7285 Hypermobility syndrome",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "7286",
                "title": "7286 Contracted palmar fascia",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72871",
                "title": "72871 Plantar fibromatosis",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72879",
                "title": "72879 Fibromatoses NEC",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72881",
                "title": "72881 Interstitial myositis",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72882",
                "title": "72882 FB granuloma of muscle",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72883",
                "title": "72883 Nontraum muscle rupture",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72884",
                "title": "72884 Diastasis of muscle",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72885",
                "title": "72885 Spasm of muscle",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72886",
                "title": "72886 Necrotizing fasciitis",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72887",
                "title": "72887 Muscle weakness-general",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72888",
                "title": "72888 Rhabdomyolysis",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "72889",
                "title": "72889 Muscle/ligament dis NEC",
                "threedigit": "728",
                "isLeaf": true
              },
              {
                "key": "7289",
                "title": "7289 Muscle/ligament dis NOS",
                "threedigit": "728",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of soft tissues",
            "title": "729 - Other disorders of soft tissues",
            "threedigit": "729",
            "children": [
              {
                "key": "7290",
                "title": "7290 Rheumatism NOS",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "7291",
                "title": "7291 Myalgia and myositis NOS",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "7292",
                "title": "7292 Neuralgia/neuritis NOS",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72930",
                "title": "72930 Panniculitis, unsp site",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72931",
                "title": "72931 Hypertrophy of fat pad",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72939",
                "title": "72939 Panniculitis, site NEC",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "7294",
                "title": "7294 Fasciitis NOS",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "7295",
                "title": "7295 Pain in limb",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "7296",
                "title": "7296 Old FB in soft tissue",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72971",
                "title": "72971 Nontraum comp syn-up ext",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72972",
                "title": "72972 Nontraum comp syn-low ex",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72973",
                "title": "72973 Nontrauma comp syn-abd",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72979",
                "title": "72979 Nontrauma comp syn NEC",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72981",
                "title": "72981 Swelling of limb",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72982",
                "title": "72982 Cramp in limb",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72989",
                "title": "72989 Muscskel sympt limb NEC",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72990",
                "title": "72990 Soft tissue disord NOS",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72991",
                "title": "72991 Post-traumatic seroma",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72992",
                "title": "72992 Nontrauma hema soft tiss",
                "threedigit": "729",
                "isLeaf": true
              },
              {
                "key": "72999",
                "title": "72999 Soft tissue disorder NEC",
                "threedigit": "729",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Osteopathies, Chondropathies, And Acquired Musculoskeletal Deformities",
        "title": "730-739 Osteopathies, Chondropathies, And Acquired Musculoskeletal Deformities",
        "threedigit": "730",
        "children": [
          {
            "key": "Osteomyelitis periostitis and other infections involving bone",
            "title": "730 - Osteomyelitis periostitis and other infections involving bone",
            "threedigit": "730",
            "children": [
              {
                "key": "73000",
                "title": "73000 Ac osteomyelitis-unspec",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73001",
                "title": "73001 Ac osteomyelitis-shlder",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73002",
                "title": "73002 Ac osteomyelitis-up/arm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73003",
                "title": "73003 Ac osteomyelitis-forearm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73004",
                "title": "73004 Ac osteomyelitis-hand",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73005",
                "title": "73005 Ac osteomyelitis-pelvis",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73006",
                "title": "73006 Ac osteomyelitis-l/leg",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73007",
                "title": "73007 Ac osteomyelitis-ankle",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73008",
                "title": "73008 Ac osteomyelitis NEC",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73009",
                "title": "73009 Ac osteomyelitis-mult",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73010",
                "title": "73010 Chr osteomyelitis-unsp",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73011",
                "title": "73011 Chr osteomyelit-shlder",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73012",
                "title": "73012 Chr osteomyelit-up/arm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73013",
                "title": "73013 Chr osteomyelit-forearm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73014",
                "title": "73014 Chr osteomyelit-hand",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73015",
                "title": "73015 Chr osteomyelit-pelvis",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73016",
                "title": "73016 Chr osteomyelit-l/leg",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73017",
                "title": "73017 Chr osteomyelit-ankle",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73018",
                "title": "73018 Chr osteomyelit NEC",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73019",
                "title": "73019 Chr osteomyelit-mult",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73020",
                "title": "73020 Osteomyelitis NOS-unspec",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73021",
                "title": "73021 Osteomyelitis NOS-shlder",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73022",
                "title": "73022 Osteomyelitis NOS-up/arm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73023",
                "title": "73023 Osteomyelit NOS-forearm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73024",
                "title": "73024 Osteomyelitis NOS-hand",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73025",
                "title": "73025 Osteomyelitis NOS-pelvis",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73026",
                "title": "73026 Osteomyelitis NOS-l/leg",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73027",
                "title": "73027 Osteomyelitis NOS-ankle",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73028",
                "title": "73028 Osteomyelit NOS-oth site",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73029",
                "title": "73029 Osteomyelitis NOS-mult",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73030",
                "title": "73030 Periostitis-unspec",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73031",
                "title": "73031 Periostitis-shlder",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73032",
                "title": "73032 Periostitis-up/arm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73033",
                "title": "73033 Periostitis-forearm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73034",
                "title": "73034 Periostitis-hand",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73035",
                "title": "73035 Periostitis-pelvis",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73036",
                "title": "73036 Periostitis-l/leg",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73037",
                "title": "73037 Periostitis-ankle",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73038",
                "title": "73038 Periostitis NEC",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73039",
                "title": "73039 Periostitis-mult",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73070",
                "title": "73070 Polio osteopathy-unspec",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73071",
                "title": "73071 Polio osteopathy-shlder",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73072",
                "title": "73072 Polio osteopathy-up/arm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73073",
                "title": "73073 Polio osteopathy-forearm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73074",
                "title": "73074 Polio osteopathy-hand",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73075",
                "title": "73075 Polio osteopathy-pelvis",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73076",
                "title": "73076 Polio osteopathy-l/leg",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73077",
                "title": "73077 Polio osteopathy-ankle",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73078",
                "title": "73078 Polio osteopathy NEC",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73079",
                "title": "73079 Polio osteopathy-mult",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73080",
                "title": "73080 Bone infect NEC-unspec",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73081",
                "title": "73081 Bone infect NEC-shlder",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73082",
                "title": "73082 Bone infect NEC-up/arm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73083",
                "title": "73083 Bone infect NEC-forearm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73084",
                "title": "73084 Bone infect NEC-hand",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73085",
                "title": "73085 Bone infect NEC-pelvis",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73086",
                "title": "73086 Bone infect NEC-l/leg",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73087",
                "title": "73087 Bone infect NEC-ankle",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73088",
                "title": "73088 Bone infect NEC-oth site",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73089",
                "title": "73089 Bone infect NEC-mult",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73090",
                "title": "73090 Bone infec NOS-unsp site",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73091",
                "title": "73091 Bone infect NOS-shlder",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73092",
                "title": "73092 Bone infect NOS-up/arm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73093",
                "title": "73093 Bone infect NOS-forearm",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73094",
                "title": "73094 Bone infect NOS-hand",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73095",
                "title": "73095 Bone infect NOS-pelvis",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73096",
                "title": "73096 Bone infect NOS-l/leg",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73097",
                "title": "73097 Bone infect NOS-ankle",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73098",
                "title": "73098 Bone infect NOS-oth site",
                "threedigit": "730",
                "isLeaf": true
              },
              {
                "key": "73099",
                "title": "73099 Bone infect NOS-mult",
                "threedigit": "730",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Osteitis deformans and osteopathies associated with other disorders classified elsewhere",
            "title": "731 - Osteitis deformans and osteopathies associated with other disorders classified elsewhere",
            "threedigit": "731",
            "children": [
              {
                "key": "7310",
                "title": "7310 Osteitis deformans NOS",
                "threedigit": "731",
                "isLeaf": true
              },
              {
                "key": "7311",
                "title": "7311 Osteitis def in oth dis",
                "threedigit": "731",
                "isLeaf": true
              },
              {
                "key": "7312",
                "title": "7312 Hypertroph osteoarthrop",
                "threedigit": "731",
                "isLeaf": true
              },
              {
                "key": "7313",
                "title": "7313 Major osseous defects",
                "threedigit": "731",
                "isLeaf": true
              },
              {
                "key": "7318",
                "title": "7318 Bone involv in oth dis",
                "threedigit": "731",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Osteochondropathies",
            "title": "732 - Osteochondropathies",
            "threedigit": "732",
            "children": [
              {
                "key": "7320",
                "title": "7320 Juv osteochondros spine",
                "threedigit": "732",
                "isLeaf": true
              },
              {
                "key": "7321",
                "title": "7321 Juv osteochondros pelvis",
                "threedigit": "732",
                "isLeaf": true
              },
              {
                "key": "7322",
                "title": "7322 Femoral epiphysiolysis",
                "threedigit": "732",
                "isLeaf": true
              },
              {
                "key": "7323",
                "title": "7323 Juv osteochondrosis arm",
                "threedigit": "732",
                "isLeaf": true
              },
              {
                "key": "7324",
                "title": "7324 Juv osteochondrosis leg",
                "threedigit": "732",
                "isLeaf": true
              },
              {
                "key": "7325",
                "title": "7325 Juv osteochondrosis foot",
                "threedigit": "732",
                "isLeaf": true
              },
              {
                "key": "7326",
                "title": "7326 Juv osteochondrosis NEC",
                "threedigit": "732",
                "isLeaf": true
              },
              {
                "key": "7327",
                "title": "7327 Osteochondrit dissecans",
                "threedigit": "732",
                "isLeaf": true
              },
              {
                "key": "7328",
                "title": "7328 Osteochondropathy NEC",
                "threedigit": "732",
                "isLeaf": true
              },
              {
                "key": "7329",
                "title": "7329 Osteochondropathy NOS",
                "threedigit": "732",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other disorders of bone and cartilage",
            "title": "733 - Other disorders of bone and cartilage",
            "threedigit": "733",
            "children": [
              {
                "key": "73300",
                "title": "73300 Osteoporosis NOS",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73301",
                "title": "73301 Senile osteoporosis",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73302",
                "title": "73302 Idiopathic osteoporosis",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73303",
                "title": "73303 Disuse osteoporosis",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73309",
                "title": "73309 Osteoporosis NEC",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73310",
                "title": "73310 Path fx unspecified site",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73311",
                "title": "73311 Path fx humerus",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73312",
                "title": "73312 Path fx dstl radius ulna",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73313",
                "title": "73313 Path fx vertebrae",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73314",
                "title": "73314 Path fx neck of femur",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73315",
                "title": "73315 Path fx oth spcf prt fmr",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73316",
                "title": "73316 Path fx tibia fibula",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73319",
                "title": "73319 Path fx oth specif site",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73320",
                "title": "73320 Cyst of bone NOS",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73321",
                "title": "73321 Solitary bone cyst",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73322",
                "title": "73322 Aneurysmal bone cyst",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73329",
                "title": "73329 Bone cyst NEC",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "7333",
                "title": "7333 Hyperostosis of skull",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73340",
                "title": "73340 Asept necrosis bone NOS",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73341",
                "title": "73341 Aseptic necrosis humerus",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73342",
                "title": "73342 Aseptic necrosis femur",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73343",
                "title": "73343 Asept necro femur condyl",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73344",
                "title": "73344 Aseptic necrosis talus",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73345",
                "title": "73345 Aseptic necrosis of jaw",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73349",
                "title": "73349 Asept necrosis bone NEC",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "7335",
                "title": "7335 Osteitis condensans",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "7336",
                "title": "7336 Tietze's disease",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "7337",
                "title": "7337 Algoneurodystrophy",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73381",
                "title": "73381 Malunion of fracture",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73382",
                "title": "73382 Nonunion of fracture",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73390",
                "title": "73390 Bone & cartilage dis NOS",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73391",
                "title": "73391 Arrest of bone growth",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73392",
                "title": "73392 Chondromalacia",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73393",
                "title": "73393 Stress fx tibia/fibula",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73394",
                "title": "73394 Stress fx metatarsals",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73395",
                "title": "73395 Stress fracture bone NEC",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73396",
                "title": "73396 Stress fx femoral neck",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73397",
                "title": "73397 Stress fx shaft femur",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73398",
                "title": "73398 Stress fx pelvis",
                "threedigit": "733",
                "isLeaf": true
              },
              {
                "key": "73399",
                "title": "73399 Bone & cartilage dis NEC",
                "threedigit": "733",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Flat foot",
            "title": "734 - Flat foot",
            "threedigit": "734",
            "children": [
              {
                "key": "734",
                "title": "734 Flat foot",
                "threedigit": "734",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Acquired deformities of toe",
            "title": "735 - Acquired deformities of toe",
            "threedigit": "735",
            "children": [
              {
                "key": "7350",
                "title": "7350 Hallux valgus",
                "threedigit": "735",
                "isLeaf": true
              },
              {
                "key": "7351",
                "title": "7351 Hallux varus",
                "threedigit": "735",
                "isLeaf": true
              },
              {
                "key": "7352",
                "title": "7352 Hallux rigidus",
                "threedigit": "735",
                "isLeaf": true
              },
              {
                "key": "7353",
                "title": "7353 Hallux malleus",
                "threedigit": "735",
                "isLeaf": true
              },
              {
                "key": "7354",
                "title": "7354 Other hammer toe",
                "threedigit": "735",
                "isLeaf": true
              },
              {
                "key": "7355",
                "title": "7355 Claw toe",
                "threedigit": "735",
                "isLeaf": true
              },
              {
                "key": "7358",
                "title": "7358 Acq deformity of toe NEC",
                "threedigit": "735",
                "isLeaf": true
              },
              {
                "key": "7359",
                "title": "7359 Acq deformity of toe NOS",
                "threedigit": "735",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other acquired deformities of limbs",
            "title": "736 - Other acquired deformities of limbs",
            "threedigit": "736",
            "children": [
              {
                "key": "73600",
                "title": "73600 Forearm deformity NOS",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73601",
                "title": "73601 Cubitus valgus",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73602",
                "title": "73602 Cubitus varus",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73603",
                "title": "73603 Valgus deformity wrist",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73604",
                "title": "73604 Varus deformity wrist",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73605",
                "title": "73605 Wrist drop",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73606",
                "title": "73606 Claw hand",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73607",
                "title": "73607 Club hand, acquired",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73609",
                "title": "73609 Forearm deformity NEC",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "7361",
                "title": "7361 Mallet finger",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73620",
                "title": "73620 Acq finger deformity NOS",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73621",
                "title": "73621 Boutonniere deformity",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73622",
                "title": "73622 Swan-neck deformity",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73629",
                "title": "73629 Acq finger deformity NEC",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73630",
                "title": "73630 Acq hip deformity NOS",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73631",
                "title": "73631 Coxa valga",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73632",
                "title": "73632 Coxa vara",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73639",
                "title": "73639 Acq hip deformity NEC",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73641",
                "title": "73641 Genu valgum",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73642",
                "title": "73642 Genu varum",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "7365",
                "title": "7365 Genu recurvatum",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "7366",
                "title": "7366 Acq knee deformity NEC",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73670",
                "title": "73670 Acq ankle-foot def NOS",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73671",
                "title": "73671 Acq equinovarus",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73672",
                "title": "73672 Acq equinus deformity",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73673",
                "title": "73673 Cavus deformity of foot",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73674",
                "title": "73674 Claw foot, acquired",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73675",
                "title": "73675 Acq cavovarus deformity",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73676",
                "title": "73676 Calcaneus deformity NEC",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73679",
                "title": "73679 Acq ankle-foot def NEC",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73681",
                "title": "73681 Unequal leg length",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "73689",
                "title": "73689 Oth acq limb deformity",
                "threedigit": "736",
                "isLeaf": true
              },
              {
                "key": "7369",
                "title": "7369 Acq limb deformity NOS",
                "threedigit": "736",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Curvature of spine",
            "title": "737 - Curvature of spine",
            "threedigit": "737",
            "children": [
              {
                "key": "7370",
                "title": "7370 Adoles postural kyphosis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73710",
                "title": "73710 Kyphosis NOS",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73711",
                "title": "73711 Radiation kyphosis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73712",
                "title": "73712 Postlaminectomy kyphosis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73719",
                "title": "73719 Kyphosis NEC",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73720",
                "title": "73720 Lordosis NOS",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73721",
                "title": "73721 Postlaminectomy lordosis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73722",
                "title": "73722 Oth postsurgery lordosis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73729",
                "title": "73729 Lordosis NEC",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73730",
                "title": "73730 Idiopathic scoliosis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73731",
                "title": "73731 Resolv idiopath scolios",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73732",
                "title": "73732 Progr idiopath scoliosis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73733",
                "title": "73733 Radiation scoliosis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73734",
                "title": "73734 Thoracogenic scoliosis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73739",
                "title": "73739 Scoliosis NEC",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73740",
                "title": "73740 Spin curv NOS in oth dis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73741",
                "title": "73741 Kyphosis in oth dis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73742",
                "title": "73742 Lordosis in oth dis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "73743",
                "title": "73743 Scoliosis in oth dis",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "7378",
                "title": "7378 Curvature of spine NEC",
                "threedigit": "737",
                "isLeaf": true
              },
              {
                "key": "7379",
                "title": "7379 Curvature of spine NOS",
                "threedigit": "737",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other acquired musculoskeletal deformity",
            "title": "738 - Other acquired musculoskeletal deformity",
            "threedigit": "738",
            "children": [
              {
                "key": "7380",
                "title": "7380 Acq nose deformity",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "73810",
                "title": "73810 Unspf acq deformity head",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "73811",
                "title": "73811 Zygomatic hyperplasia",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "73812",
                "title": "73812 Zygomatic hypoplasia",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "73819",
                "title": "73819 Oth spcf deformity head",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "7382",
                "title": "7382 Acq neck deformity",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "7383",
                "title": "7383 Acq chest deformity",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "7384",
                "title": "7384 Acq spondylolisthesis",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "7385",
                "title": "7385 Other acq back deformity",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "7386",
                "title": "7386 Acq pelvic deformity",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "7387",
                "title": "7387 Cauliflower ear",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "7388",
                "title": "7388 Acq deformity NEC",
                "threedigit": "738",
                "isLeaf": true
              },
              {
                "key": "7389",
                "title": "7389 Acq deformity NOS",
                "threedigit": "738",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nonallopathic lesions not elsewhere classified",
            "title": "739 - Nonallopathic lesions not elsewhere classified",
            "threedigit": "739",
            "children": [
              {
                "key": "7390",
                "title": "7390 Somat dys head region",
                "threedigit": "739",
                "isLeaf": true
              },
              {
                "key": "7391",
                "title": "7391 Somat dysfunc cervic reg",
                "threedigit": "739",
                "isLeaf": true
              },
              {
                "key": "7392",
                "title": "7392 Somat dysfunc thorac reg",
                "threedigit": "739",
                "isLeaf": true
              },
              {
                "key": "7393",
                "title": "7393 Somat dysfunc lumbar reg",
                "threedigit": "739",
                "isLeaf": true
              },
              {
                "key": "7394",
                "title": "7394 Somat dysfunc sacral reg",
                "threedigit": "739",
                "isLeaf": true
              },
              {
                "key": "7395",
                "title": "7395 Somat dysfunc pelvic reg",
                "threedigit": "739",
                "isLeaf": true
              },
              {
                "key": "7396",
                "title": "7396 Somat dysfunc lower extr",
                "threedigit": "739",
                "isLeaf": true
              },
              {
                "key": "7397",
                "title": "7397 Somat dysfunc upper extr",
                "threedigit": "739",
                "isLeaf": true
              },
              {
                "key": "7398",
                "title": "7398 Somat dysfunc rib cage",
                "threedigit": "739",
                "isLeaf": true
              },
              {
                "key": "7399",
                "title": "7399 Somatic dysfunction NEC",
                "threedigit": "739",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Congenital Anomalies",
    "title": "740-759 Congenital Anomalies",
    "threedigit": "740",
    "children": [
      {
        "title": "740-759 undefined",
        "threedigit": "740",
        "children": [
          {
            "key": "Anencephalus and similar anomalies",
            "title": "740 - Anencephalus and similar anomalies",
            "threedigit": "740",
            "children": [
              {
                "key": "7400",
                "title": "7400 Anencephalus",
                "threedigit": "740",
                "isLeaf": true
              },
              {
                "key": "7401",
                "title": "7401 Craniorachischisis",
                "threedigit": "740",
                "isLeaf": true
              },
              {
                "key": "7402",
                "title": "7402 Iniencephaly",
                "threedigit": "740",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Spina bifida",
            "title": "741 - Spina bifida",
            "threedigit": "741",
            "children": [
              {
                "key": "74100",
                "title": "74100 Spin bif w hydroceph NOS",
                "threedigit": "741",
                "isLeaf": true
              },
              {
                "key": "74101",
                "title": "74101 Spin bif w hydrceph-cerv",
                "threedigit": "741",
                "isLeaf": true
              },
              {
                "key": "74102",
                "title": "74102 Spin bif w hydrceph-dors",
                "threedigit": "741",
                "isLeaf": true
              },
              {
                "key": "74103",
                "title": "74103 Spin bif w hydrceph-lumb",
                "threedigit": "741",
                "isLeaf": true
              },
              {
                "key": "74190",
                "title": "74190 Spina bifida",
                "threedigit": "741",
                "isLeaf": true
              },
              {
                "key": "74191",
                "title": "74191 Spina bifida-cerv",
                "threedigit": "741",
                "isLeaf": true
              },
              {
                "key": "74192",
                "title": "74192 Spina bifida-dorsal",
                "threedigit": "741",
                "isLeaf": true
              },
              {
                "key": "74193",
                "title": "74193 Spina bifida-lumbar",
                "threedigit": "741",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other congenital anomalies of nervous system",
            "title": "742 - Other congenital anomalies of nervous system",
            "threedigit": "742",
            "children": [
              {
                "key": "7420",
                "title": "7420 Encephalocele",
                "threedigit": "742",
                "isLeaf": true
              },
              {
                "key": "7421",
                "title": "7421 Microcephalus",
                "threedigit": "742",
                "isLeaf": true
              },
              {
                "key": "7422",
                "title": "7422 Reduction deform, brain",
                "threedigit": "742",
                "isLeaf": true
              },
              {
                "key": "7423",
                "title": "7423 Congenital hydrocephalus",
                "threedigit": "742",
                "isLeaf": true
              },
              {
                "key": "7424",
                "title": "7424 Brain anomaly NEC",
                "threedigit": "742",
                "isLeaf": true
              },
              {
                "key": "74251",
                "title": "74251 Diastematomyelia",
                "threedigit": "742",
                "isLeaf": true
              },
              {
                "key": "74253",
                "title": "74253 Hydromyelia",
                "threedigit": "742",
                "isLeaf": true
              },
              {
                "key": "74259",
                "title": "74259 Spinal cord anomaly NEC",
                "threedigit": "742",
                "isLeaf": true
              },
              {
                "key": "7428",
                "title": "7428 Nervous system anom NEC",
                "threedigit": "742",
                "isLeaf": true
              },
              {
                "key": "7429",
                "title": "7429 Nervous system anom NOS",
                "threedigit": "742",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Congenital anomalies of eye",
            "title": "743 - Congenital anomalies of eye",
            "threedigit": "743",
            "children": [
              {
                "key": "74300",
                "title": "74300 Clinic anophthalmos NOS",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74303",
                "title": "74303 Congen cystic eyeball",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74306",
                "title": "74306 Cryptophthalmos",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74310",
                "title": "74310 Microphthalmos NOS",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74311",
                "title": "74311 Simple microphthalmos",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74312",
                "title": "74312 Microphth w oth eye anom",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74320",
                "title": "74320 Buphthalmos NOS",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74321",
                "title": "74321 Simple buphthalmos",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74322",
                "title": "74322 Buphthal w oth eye anom",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74330",
                "title": "74330 Congenital cataract NOS",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74331",
                "title": "74331 Capsular cataract",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74332",
                "title": "74332 Cortical/zonular catarac",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74333",
                "title": "74333 Nuclear cataract",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74334",
                "title": "74334 Cong tot/subtot cataract",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74335",
                "title": "74335 Congenital aphakia",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74336",
                "title": "74336 Anomalies of lens shape",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74337",
                "title": "74337 Congenital ectopic lens",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74339",
                "title": "74339 Cong catar/lens anom NEC",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74341",
                "title": "74341 Anom corneal size/shape",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74342",
                "title": "74342 Cong cornea opac aff vis",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74343",
                "title": "74343 Cong corneal opacit NEC",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74344",
                "title": "74344 Anom anter chamber-eye",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74345",
                "title": "74345 Aniridia",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74346",
                "title": "74346 Anom iris & cil body NEC",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74347",
                "title": "74347 Anomalies of sclera",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74348",
                "title": "74348 Mult anom anter seg-eye",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74349",
                "title": "74349 Anom anter seg NEC-eye",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74351",
                "title": "74351 Vitreous anomalies",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74352",
                "title": "74352 Fundus coloboma",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74353",
                "title": "74353 Cong chorioretinal degen",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74354",
                "title": "74354 Cong fold/cyst post eye",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74355",
                "title": "74355 Cong macular change-eye",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74356",
                "title": "74356 Cong retinal changes NEC",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74357",
                "title": "74357 Optic disc anomalies",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74358",
                "title": "74358 Vascular anom post eye",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74359",
                "title": "74359 Post segmnt anom NEC-eye",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74361",
                "title": "74361 Congenital ptosis",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74362",
                "title": "74362 Congenital eyelid deform",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74363",
                "title": "74363 Spec anom of eyelid NEC",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74364",
                "title": "74364 Spec lacrimal gland anom",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74365",
                "title": "74365 Spec lacrimal pass anom",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74366",
                "title": "74366 Spec anomaly of orbit",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "74369",
                "title": "74369 Anom eyelid/lacr/orb NEC",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "7438",
                "title": "7438 Eye anomalies NEC",
                "threedigit": "743",
                "isLeaf": true
              },
              {
                "key": "7439",
                "title": "7439 Eye anomaly NOS",
                "threedigit": "743",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Congenital anomalies of ear face and neck",
            "title": "744 - Congenital anomalies of ear face and neck",
            "threedigit": "744",
            "children": [
              {
                "key": "74400",
                "title": "74400 Ear anom NOS/impair hear",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74401",
                "title": "74401 Cong absence ext ear",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74402",
                "title": "74402 Ex ear anm NEC-impr hear",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74403",
                "title": "74403 Middle ear anomaly NEC",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74404",
                "title": "74404 Anomalies ear ossicles",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74405",
                "title": "74405 Anomalies of inner ear",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74409",
                "title": "74409 Ear anom NEC/impair hear",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "7441",
                "title": "7441 Accessory auricle",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74421",
                "title": "74421 Cong absence of ear lobe",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74422",
                "title": "74422 Macrotia",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74423",
                "title": "74423 Microtia",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74424",
                "title": "74424 Eustachian tube anom NEC",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74429",
                "title": "74429 Ear anomalies NEC",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "7443",
                "title": "7443 Ear anomaly NOS",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74441",
                "title": "74441 Branch cleft sinus/fistu",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74442",
                "title": "74442 Branchial cleft cyst",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74443",
                "title": "74443 Cervical auricle",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74446",
                "title": "74446 Preauricular sinus/fistu",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74447",
                "title": "74447 Preauricular cyst",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74449",
                "title": "74449 Branchial cleft anom NEC",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "7445",
                "title": "7445 Webbing of neck",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74481",
                "title": "74481 Macrocheilia",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74482",
                "title": "74482 Microcheilia",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74483",
                "title": "74483 Macrostomia",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74484",
                "title": "74484 Microstomia",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "74489",
                "title": "74489 Cong face/neck anom NEC",
                "threedigit": "744",
                "isLeaf": true
              },
              {
                "key": "7449",
                "title": "7449 Cong face/neck anom NOS",
                "threedigit": "744",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Bulbus cordis anomalies and anomalies of cardiac septal closure",
            "title": "745 - Bulbus cordis anomalies and anomalies of cardiac septal closure",
            "threedigit": "745",
            "children": [
              {
                "key": "7450",
                "title": "7450 Common truncus",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "74510",
                "title": "74510 Compl transpos great ves",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "74511",
                "title": "74511 Double outlet rt ventric",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "74512",
                "title": "74512 Correct transpos grt ves",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "74519",
                "title": "74519 Transpos great vess NEC",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "7452",
                "title": "7452 Tetralogy of fallot",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "7453",
                "title": "7453 Common ventricle",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "7454",
                "title": "7454 Ventricular sept defect",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "7455",
                "title": "7455 Secundum atrial sept def",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "74560",
                "title": "74560 Endocard cushion def NOS",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "74561",
                "title": "74561 Ostium primum defect",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "74569",
                "title": "74569 Endocard cushion def NEC",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "7457",
                "title": "7457 Cor biloculare",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "7458",
                "title": "7458 Septal closure anom NEC",
                "threedigit": "745",
                "isLeaf": true
              },
              {
                "key": "7459",
                "title": "7459 Septal closure anom NOS",
                "threedigit": "745",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other congenital anomalies of heart",
            "title": "746 - Other congenital anomalies of heart",
            "threedigit": "746",
            "children": [
              {
                "key": "74600",
                "title": "74600 Pulmonary valve anom NOS",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74601",
                "title": "74601 Cong pulmon valv atresia",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74602",
                "title": "74602 Cong pulmon valve stenos",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74609",
                "title": "74609 Pulmonary valve anom NEC",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "7461",
                "title": "7461 Cong tricusp atres/sten",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "7462",
                "title": "7462 Ebstein's anomaly",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "7463",
                "title": "7463 Cong aorta valv stenosis",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "7464",
                "title": "7464 Cong aorta valv insuffic",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "7465",
                "title": "7465 Congen mitral stenosis",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "7466",
                "title": "7466 Cong mitral insufficienc",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "7467",
                "title": "7467 Hypoplas left heart synd",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74681",
                "title": "74681 Cong subaortic stenosis",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74682",
                "title": "74682 Cor triatriatum",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74683",
                "title": "74683 Infundib pulmon stenosis",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74684",
                "title": "74684 Obstruct heart anom NEC",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74685",
                "title": "74685 Coronary artery anomaly",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74686",
                "title": "74686 Congenital heart block",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74687",
                "title": "74687 Malposition of heart",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "74689",
                "title": "74689 Cong heart anomaly NEC",
                "threedigit": "746",
                "isLeaf": true
              },
              {
                "key": "7469",
                "title": "7469 Cong heart anomaly NOS",
                "threedigit": "746",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other congenital anomalies of circulatory system",
            "title": "747 - Other congenital anomalies of circulatory system",
            "threedigit": "747",
            "children": [
              {
                "key": "7470",
                "title": "7470 Patent ductus arteriosus",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74710",
                "title": "74710 Coarctation of aorta",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74711",
                "title": "74711 Interrupt of aortic arch",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74720",
                "title": "74720 Cong anom of aorta NOS",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74721",
                "title": "74721 Anomalies of aortic arch",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74722",
                "title": "74722 Aortic atresia/stenosis",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74729",
                "title": "74729 Cong anom of aorta NEC",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74731",
                "title": "74731 Pulmon art coarct/atres",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74732",
                "title": "74732 Pulmonary AV malformatn",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74739",
                "title": "74739 Oth anom pul artery/circ",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74740",
                "title": "74740 Great vein anomaly NOS",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74741",
                "title": "74741 Tot anom pulm ven connec",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74742",
                "title": "74742 Part anom pulm ven conn",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74749",
                "title": "74749 Great vein anomaly NEC",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "7475",
                "title": "7475 Umbilical artery absence",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74760",
                "title": "74760 Unsp prpherl vasc anomal",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74761",
                "title": "74761 Gstrontest vesl anomaly",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74762",
                "title": "74762 Renal vessel anomaly",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74763",
                "title": "74763 Upr limb vessel anomaly",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74764",
                "title": "74764 Lwr limb vessel anomaly",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74769",
                "title": "74769 Oth spcf prph vscl anoml",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74781",
                "title": "74781 Cerebrovascular anomaly",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74782",
                "title": "74782 Spinal vessel anomaly",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74783",
                "title": "74783 Persistent fetal circ",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "74789",
                "title": "74789 Circulatory anomaly NEC",
                "threedigit": "747",
                "isLeaf": true
              },
              {
                "key": "7479",
                "title": "7479 Circulatory anomaly NOS",
                "threedigit": "747",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Congenital anomalies of respiratory system",
            "title": "748 - Congenital anomalies of respiratory system",
            "threedigit": "748",
            "children": [
              {
                "key": "7480",
                "title": "7480 Choanal atresia",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "7481",
                "title": "7481 Nose anomaly NEC",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "7482",
                "title": "7482 Laryngeal web",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "7483",
                "title": "7483 Laryngotrach anomaly NEC",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "7484",
                "title": "7484 Congenital cystic lung",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "7485",
                "title": "7485 Agenesis of lung",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "74860",
                "title": "74860 Lung anomaly NOS",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "74861",
                "title": "74861 Congen bronchiectasis",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "74869",
                "title": "74869 Lung anomaly NEC",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "7488",
                "title": "7488 Respiratory anomaly NEC",
                "threedigit": "748",
                "isLeaf": true
              },
              {
                "key": "7489",
                "title": "7489 Respiratory anomaly NOS",
                "threedigit": "748",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cleft palate and cleft lip",
            "title": "749 - Cleft palate and cleft lip",
            "threedigit": "749",
            "children": [
              {
                "key": "74900",
                "title": "74900 Cleft palate NOS",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74901",
                "title": "74901 Unilat cleft palate-comp",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74902",
                "title": "74902 Unilat cleft palate-inc",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74903",
                "title": "74903 Bilat cleft palate-compl",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74904",
                "title": "74904 Bilat cleft palate-inc",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74910",
                "title": "74910 Cleft lip NOS",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74911",
                "title": "74911 Unilat cleft lip-compl",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74912",
                "title": "74912 Unilat cleft lip-imcompl",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74913",
                "title": "74913 Bilat cleft lip-complete",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74914",
                "title": "74914 Bilat cleft lip-incompl",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74920",
                "title": "74920 Cleft palate & lip NOS",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74921",
                "title": "74921 Unil cleft palat/lip-com",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74922",
                "title": "74922 Unil cleft palat/lip-inc",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74923",
                "title": "74923 Bilat clft palat/lip-com",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74924",
                "title": "74924 Bilat clft palat/lip-inc",
                "threedigit": "749",
                "isLeaf": true
              },
              {
                "key": "74925",
                "title": "74925 Cleft palate & lip NEC",
                "threedigit": "749",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other congenital anomalies of upper alimentary tract",
            "title": "750 - Other congenital anomalies of upper alimentary tract",
            "threedigit": "750",
            "children": [
              {
                "key": "7500",
                "title": "7500 Tongue tie",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75010",
                "title": "75010 Tongue anomaly NOS",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75011",
                "title": "75011 Aglossia",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75012",
                "title": "75012 Cong adhesions of tongue",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75013",
                "title": "75013 Cong fissure of tongue",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75015",
                "title": "75015 Cong macroglossia",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75016",
                "title": "75016 Microglossia",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75019",
                "title": "75019 Tongue anomaly NEC",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75021",
                "title": "75021 Salivary gland absence",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75022",
                "title": "75022 Accessory salivary gland",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75023",
                "title": "75023 Cong atresia, saliv duct",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75024",
                "title": "75024 Cong salivary fistula",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75025",
                "title": "75025 Congenital lip fistula",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75026",
                "title": "75026 Mouth anomaly NEC",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75027",
                "title": "75027 Diverticulum of pharynx",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "75029",
                "title": "75029 Pharyngeal anomaly NEC",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "7503",
                "title": "7503 Cong esoph fistula/atres",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "7504",
                "title": "7504 Esophageal anomaly NEC",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "7505",
                "title": "7505 Cong pyloric stenosis",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "7506",
                "title": "7506 Congenital hiatus hernia",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "7507",
                "title": "7507 Gastric anomaly NEC",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "7508",
                "title": "7508 Upper GI anomaly NEC",
                "threedigit": "750",
                "isLeaf": true
              },
              {
                "key": "7509",
                "title": "7509 Upper GI anomaly NOS",
                "threedigit": "750",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other congenital anomalies of digestive system",
            "title": "751 - Other congenital anomalies of digestive system",
            "threedigit": "751",
            "children": [
              {
                "key": "7510",
                "title": "7510 Meckel's diverticulum",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "7511",
                "title": "7511 Atresia small intestine",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "7512",
                "title": "7512 Atresia large intestine",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "7513",
                "title": "7513 Hirschsprung's disease",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "7514",
                "title": "7514 Intestinal fixation anom",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "7515",
                "title": "7515 Intestinal anomaly NEC",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "75160",
                "title": "75160 Biliary & liver anom NOS",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "75161",
                "title": "75161 Biliary atresia",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "75162",
                "title": "75162 Cong cystic liver dis",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "75169",
                "title": "75169 Biliary & liver anom NEC",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "7517",
                "title": "7517 Pancreas anomalies",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "7518",
                "title": "7518 Anom digestive syst NEC",
                "threedigit": "751",
                "isLeaf": true
              },
              {
                "key": "7519",
                "title": "7519 Anom digestive syst NOS",
                "threedigit": "751",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Congenital anomalies of genital organs",
            "title": "752 - Congenital anomalies of genital organs",
            "threedigit": "752",
            "children": [
              {
                "key": "7520",
                "title": "7520 Anomalies of ovaries",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75210",
                "title": "75210 Tubal/broad lig anom NOS",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75211",
                "title": "75211 Embryonic cyst of adnexa",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75219",
                "title": "75219 Tubal/broad lig anom NEC",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "7522",
                "title": "7522 Doubling of uterus",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75231",
                "title": "75231 Agenesis of uterus",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75232",
                "title": "75232 Hypoplasia of uterus",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75233",
                "title": "75233 Unicornuate uterus",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75234",
                "title": "75234 Bicornuate uterus",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75235",
                "title": "75235 Septate uterus",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75236",
                "title": "75236 Arcuate uterus",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75239",
                "title": "75239 Anomalies of uterus NEC",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75240",
                "title": "75240 Cervix/fem gen anom NOS",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75241",
                "title": "75241 Embryon cyst fem gen NEC",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75242",
                "title": "75242 Imperforate hymen",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75243",
                "title": "75243 Cervical agenesis",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75244",
                "title": "75244 Cervical duplication",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75245",
                "title": "75245 Vaginal agenesis",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75246",
                "title": "75246 Transv vaginal septum",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75247",
                "title": "75247 Longitud vaginal septum",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75249",
                "title": "75249 Cervix/fem gen anom NEC",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75251",
                "title": "75251 Undescended testis",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75252",
                "title": "75252 Retractile testis",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75261",
                "title": "75261 Hypospadias",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75262",
                "title": "75262 Epispadias",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75263",
                "title": "75263 Congenital chordee",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75264",
                "title": "75264 Micropenis",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75265",
                "title": "75265 Hidden penis",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75269",
                "title": "75269 Penile anomalies NEC",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "7527",
                "title": "7527 Indeterminate sex",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75281",
                "title": "75281 Scrotal transposition",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "75289",
                "title": "75289 Genital organ anom NEC",
                "threedigit": "752",
                "isLeaf": true
              },
              {
                "key": "7529",
                "title": "7529 Genital organ anom NOS",
                "threedigit": "752",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Congenital anomalies of urinary system",
            "title": "753 - Congenital anomalies of urinary system",
            "threedigit": "753",
            "children": [
              {
                "key": "7530",
                "title": "7530 Renal agenesis",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75310",
                "title": "75310 Cystic kidney diseas NOS",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75311",
                "title": "75311 Congenital renal cyst",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75312",
                "title": "75312 Polycystic kidney NOS",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75313",
                "title": "75313 Polycyst kid-autosom dom",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75314",
                "title": "75314 Polycyst kid-autosom rec",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75315",
                "title": "75315 Renal dysplasia",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75316",
                "title": "75316 Medullary cystic kidney",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75317",
                "title": "75317 Medullary sponge kidney",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75319",
                "title": "75319 Cystic kidney diseas NEC",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75320",
                "title": "75320 Obs dfct ren plv&urt NOS",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75321",
                "title": "75321 Congen obst urtroplv jnc",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75322",
                "title": "75322 Cong obst ureteroves jnc",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75323",
                "title": "75323 Congenital ureterocele",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "75329",
                "title": "75329 Obst def ren plv&urt NEC",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "7533",
                "title": "7533 Kidney anomaly NEC",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "7534",
                "title": "7534 Ureteral anomaly NEC",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "7535",
                "title": "7535 Bladder exstrophy",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "7536",
                "title": "7536 Congen urethral stenosis",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "7537",
                "title": "7537 Anomalies of urachus",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "7538",
                "title": "7538 Cystourethral anom NEC",
                "threedigit": "753",
                "isLeaf": true
              },
              {
                "key": "7539",
                "title": "7539 Urinary anomaly NOS",
                "threedigit": "753",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Certain congenital musculoskeletal deformities",
            "title": "754 - Certain congenital musculoskeletal deformities",
            "threedigit": "754",
            "children": [
              {
                "key": "7540",
                "title": "7540 Cong skull/face/jaw def",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "7541",
                "title": "7541 Congenital torticollis",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "7542",
                "title": "7542 Cong postural deformity",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75430",
                "title": "75430 Cong hip disloc, unilat",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75431",
                "title": "75431 Congen hip disloc, bilat",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75432",
                "title": "75432 Cong hip sublux, unilat",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75433",
                "title": "75433 Cong hip sublux, bilat",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75435",
                "title": "75435 Cong hip disloc w sublux",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75440",
                "title": "75440 Cong genu recurvatum",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75441",
                "title": "75441 Cong knee dislocation",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75442",
                "title": "75442 Congen bowing of femur",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75443",
                "title": "75443 Cong bowing tibia/fibula",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75444",
                "title": "75444 Cong bowing leg NOS",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75450",
                "title": "75450 Talipes varus",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75451",
                "title": "75451 Talipes equinovarus",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75452",
                "title": "75452 Metatarsus primus varus",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75453",
                "title": "75453 Metatarsus varus",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75459",
                "title": "75459 Cong varus foot def NEC",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75460",
                "title": "75460 Talipes valgus",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75461",
                "title": "75461 Congenital pes planus",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75462",
                "title": "75462 Talipes calcaneovalgus",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75469",
                "title": "75469 Cong valgus foot def NEC",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75470",
                "title": "75470 Talipes NOS",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75471",
                "title": "75471 Talipes cavus",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75479",
                "title": "75479 Cong foot deform NEC",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75481",
                "title": "75481 Pectus excavatum",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75482",
                "title": "75482 Pectus carinatum",
                "threedigit": "754",
                "isLeaf": true
              },
              {
                "key": "75489",
                "title": "75489 Nonteratogenic anom NEC",
                "threedigit": "754",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other congenital anomalies of limbs",
            "title": "755 - Other congenital anomalies of limbs",
            "threedigit": "755",
            "children": [
              {
                "key": "75500",
                "title": "75500 Polydactyly NOS",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75501",
                "title": "75501 Polydactyly, fingers",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75502",
                "title": "75502 Polydactyly, toes",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75510",
                "title": "75510 Syndactyly, multiple/NOS",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75511",
                "title": "75511 Syndactyl fing-no fusion",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75512",
                "title": "75512 Syndactyl fing w fusion",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75513",
                "title": "75513 Syndactyl toe-no fusion",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75514",
                "title": "75514 Syndactyl toe w fusion",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75520",
                "title": "75520 Reduc deform up limb NOS",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75521",
                "title": "75521 Transverse defic arm",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75522",
                "title": "75522 Longitud defic arm NEC",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75523",
                "title": "75523 Combin longit defic arm",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75524",
                "title": "75524 Longitudin defic humerus",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75525",
                "title": "75525 Longitud defic radioulna",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75526",
                "title": "75526 Longitud defic radius",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75527",
                "title": "75527 Longitudinal defic ulna",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75528",
                "title": "75528 Longitudinal defic hand",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75529",
                "title": "75529 Longitud defic phalanges",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75530",
                "title": "75530 Reduction deform leg NOS",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75531",
                "title": "75531 Transverse defic leg",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75532",
                "title": "75532 Longitudin defic leg NEC",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75533",
                "title": "75533 Comb longitudin def leg",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75534",
                "title": "75534 Longitudinal defic femur",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75535",
                "title": "75535 Tibiofibula longit defic",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75536",
                "title": "75536 Longitudinal defic tibia",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75537",
                "title": "75537 Longitudin defic fibula",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75538",
                "title": "75538 Longitudinal defic foot",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75539",
                "title": "75539 Longitud defic phalanges",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "7554",
                "title": "7554 Reduct deform limb NOS",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75550",
                "title": "75550 Upper limb anomaly NOS",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75551",
                "title": "75551 Cong deformity-clavicle",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75552",
                "title": "75552 Cong elevation-scapula",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75553",
                "title": "75553 Radioulnar synostosis",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75554",
                "title": "75554 Madelung's deformity",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75555",
                "title": "75555 Acrocephalosyndactyly",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75556",
                "title": "75556 Accessory carpal bones",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75557",
                "title": "75557 Macrodactylia (fingers)",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75558",
                "title": "75558 Congenital cleft hand",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75559",
                "title": "75559 Upper limb anomaly NEC",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75560",
                "title": "75560 Lower limb anomaly NOS",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75561",
                "title": "75561 Congenital coxa valga",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75562",
                "title": "75562 Congenital coxa vara",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75563",
                "title": "75563 Cong hip deformity NEC",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75564",
                "title": "75564 Cong knee deformity",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75565",
                "title": "75565 Macrodactylia of toes",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75566",
                "title": "75566 Anomalies of toes NEC",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75567",
                "title": "75567 Anomalies of foot NEC",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "75569",
                "title": "75569 Lower limb anomaly NEC",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "7558",
                "title": "7558 Congen limb anomaly NEC",
                "threedigit": "755",
                "isLeaf": true
              },
              {
                "key": "7559",
                "title": "7559 Congen limb anomaly NOS",
                "threedigit": "755",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other congenital musculoskeletal anomalies",
            "title": "756 - Other congenital musculoskeletal anomalies",
            "threedigit": "756",
            "children": [
              {
                "key": "7560",
                "title": "7560 Anomal skull/face bones",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75610",
                "title": "75610 Anomaly of spine NOS",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75611",
                "title": "75611 Lumbosacr spondylolysis",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75612",
                "title": "75612 Spondylolisthesis",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75613",
                "title": "75613 Cong absence of vertebra",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75614",
                "title": "75614 Hemivertebra",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75615",
                "title": "75615 Congen fusion of spine",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75616",
                "title": "75616 Klippel-feil syndrome",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75617",
                "title": "75617 Spina bifida occulta",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75619",
                "title": "75619 Anomaly of spine NEC",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "7562",
                "title": "7562 Cervical rib",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "7563",
                "title": "7563 Rib & sternum anomal NEC",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "7564",
                "title": "7564 Chondrodystrophy",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75650",
                "title": "75650 Osteodystrophy NOS",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75651",
                "title": "75651 Osteogenesis imperfecta",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75652",
                "title": "75652 Osteopetrosis",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75653",
                "title": "75653 Osteopoikilosis",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75654",
                "title": "75654 Polyostotic fibros dyspl",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75655",
                "title": "75655 Chondroectoderm dysplas",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75656",
                "title": "75656 Mult epiphyseal dysplas",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75659",
                "title": "75659 Osteodystrophy NEC",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "7566",
                "title": "7566 Anomalies of diaphragm",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75670",
                "title": "75670 Congn anoml abd wall NOS",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75671",
                "title": "75671 Prune belly syndrome",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75672",
                "title": "75672 Omphalocele",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75673",
                "title": "75673 Gastroschisis",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75679",
                "title": "75679 Congn anoml abd wall NEC",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75681",
                "title": "75681 Absence of muscle/tendon",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75682",
                "title": "75682 Accessory muscle",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75683",
                "title": "75683 Ehlers-danlos syndrome",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "75689",
                "title": "75689 Soft tissue anomaly NEC",
                "threedigit": "756",
                "isLeaf": true
              },
              {
                "key": "7569",
                "title": "7569 Musculoskel anom NEC/NOS",
                "threedigit": "756",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Congenital anomalies of the integument",
            "title": "757 - Congenital anomalies of the integument",
            "threedigit": "757",
            "children": [
              {
                "key": "7570",
                "title": "7570 Hereditary edema of legs",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "7571",
                "title": "7571 Ichthyosis congenita",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "7572",
                "title": "7572 Dermatoglyphic anomalies",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "75731",
                "title": "75731 Cong ectodermal dysplas",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "75732",
                "title": "75732 Vascular hamartomas",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "75733",
                "title": "75733 Cong skin pigment anomal",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "75739",
                "title": "75739 Skin anomaly NEC",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "7574",
                "title": "7574 Hair anomalies NEC",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "7575",
                "title": "7575 Nail anomalies NEC",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "7576",
                "title": "7576 Cong breast anomaly NEC",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "7578",
                "title": "7578 Oth integument anomalies",
                "threedigit": "757",
                "isLeaf": true
              },
              {
                "key": "7579",
                "title": "7579 Integument anomaly NOS",
                "threedigit": "757",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Chromosomal anomalies",
            "title": "758 - Chromosomal anomalies",
            "threedigit": "758",
            "children": [
              {
                "key": "7580",
                "title": "7580 Down's syndrome",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "7581",
                "title": "7581 Patau's syndrome",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "7582",
                "title": "7582 Edwards' syndrome",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "75831",
                "title": "75831 Cri-du-chat syndrome",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "75832",
                "title": "75832 Velo-cardio-facial synd",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "75833",
                "title": "75833 Microdeletions NEC",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "75839",
                "title": "75839 Autosomal deletions NEC",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "7584",
                "title": "7584 Balance autosom transloc",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "7585",
                "title": "7585 Autosomal anomalies NEC",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "7586",
                "title": "7586 Gonadal dysgenesis",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "7587",
                "title": "7587 Klinefelter's syndrome",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "75881",
                "title": "75881 Oth cond due to sex chrm",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "75889",
                "title": "75889 Oth con d/t chrm anm NEC",
                "threedigit": "758",
                "isLeaf": true
              },
              {
                "key": "7589",
                "title": "7589 Chromosome anomaly NOS",
                "threedigit": "758",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified congenital anomalies",
            "title": "759 - Other and unspecified congenital anomalies",
            "threedigit": "759",
            "children": [
              {
                "key": "7590",
                "title": "7590 Anomalies of spleen",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "7591",
                "title": "7591 Adrenal gland anomaly",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "7592",
                "title": "7592 Endocrine anomaly NEC",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "7593",
                "title": "7593 Situs inversus",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "7594",
                "title": "7594 Conjoined twins",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "7595",
                "title": "7595 Tuberous sclerosis",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "7596",
                "title": "7596 Hamartoses NEC",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "7597",
                "title": "7597 Mult congen anomal NEC",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "75981",
                "title": "75981 Prader-willi syndrome",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "75982",
                "title": "75982 Marfan syndrome",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "75983",
                "title": "75983 Fragile x syndrome",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "75989",
                "title": "75989 Specfied cong anomal NEC",
                "threedigit": "759",
                "isLeaf": true
              },
              {
                "key": "7599",
                "title": "7599 Congenital anomaly NOS",
                "threedigit": "759",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Certain Conditions Originating In The Perinatal Period",
    "title": "760-779 Certain Conditions Originating In The Perinatal Period",
    "threedigit": "760",
    "children": [
      {
        "key": "Maternal Causes Of Perinatal Morbidity And Mortality",
        "title": "760-763 Maternal Causes Of Perinatal Morbidity And Mortality",
        "threedigit": "760",
        "children": [
          {
            "key": "Fetus or newborn affected by maternal conditions which may be unrelated to present pregnancy",
            "title": "760 - Fetus or newborn affected by maternal conditions which may be unrelated to present pregnancy",
            "threedigit": "760",
            "children": [
              {
                "key": "7600",
                "title": "7600 Matern hyperten aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "7601",
                "title": "7601 Matern urine dis aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "7602",
                "title": "7602 Maternal infec aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "7603",
                "title": "7603 Matern cardioresp aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "7604",
                "title": "7604 Matern nutrit dis aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "7605",
                "title": "7605 Maternal injury aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76061",
                "title": "76061 Amniocentesis affect NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76062",
                "title": "76062 In utero proc NEC aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76063",
                "title": "76063 Mat surg dur preg aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76064",
                "title": "76064 Prev matern surg aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76070",
                "title": "76070 Nox sub NOS aff NB/fetus",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76071",
                "title": "76071 Maternl alc aff NB/fetus",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76072",
                "title": "76072 Matern narc aff NB/fetus",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76073",
                "title": "76073 Matern halluc af NB/fet",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76074",
                "title": "76074 Mat anti-inf aff NB/fet",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76075",
                "title": "76075 Mat cocaine aff NB/fet",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76076",
                "title": "76076 Maternal DES af NB/fetus",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76077",
                "title": "76077 Mat anticonv aff NB/fet",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76078",
                "title": "76078 Mat antimetabol aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "76079",
                "title": "76079 Nox sub NEC aff NB/fetus",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "7608",
                "title": "7608 Maternal cond NEC aff NB",
                "threedigit": "760",
                "isLeaf": true
              },
              {
                "key": "7609",
                "title": "7609 Maternal cond NOS aff NB",
                "threedigit": "760",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fetus or newborn affected by maternal complications of pregnancy",
            "title": "761 - Fetus or newborn affected by maternal complications of pregnancy",
            "threedigit": "761",
            "children": [
              {
                "key": "7610",
                "title": "7610 Incompetnt cervix aff NB",
                "threedigit": "761",
                "isLeaf": true
              },
              {
                "key": "7611",
                "title": "7611 Premat rupt memb aff NB",
                "threedigit": "761",
                "isLeaf": true
              },
              {
                "key": "7612",
                "title": "7612 Oligohydramnios aff NB",
                "threedigit": "761",
                "isLeaf": true
              },
              {
                "key": "7613",
                "title": "7613 Polyhydramnios aff NB",
                "threedigit": "761",
                "isLeaf": true
              },
              {
                "key": "7614",
                "title": "7614 Ectopic pregnancy aff NB",
                "threedigit": "761",
                "isLeaf": true
              },
              {
                "key": "7615",
                "title": "7615 Mult pregnancy aff NB",
                "threedigit": "761",
                "isLeaf": true
              },
              {
                "key": "7616",
                "title": "7616 Maternal death aff NB",
                "threedigit": "761",
                "isLeaf": true
              },
              {
                "key": "7617",
                "title": "7617 Antepart malpres aff NB",
                "threedigit": "761",
                "isLeaf": true
              },
              {
                "key": "7618",
                "title": "7618 Matern compl NEC aff NB",
                "threedigit": "761",
                "isLeaf": true
              },
              {
                "key": "7619",
                "title": "7619 Matern compl NOS aff NB",
                "threedigit": "761",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fetus or newborn affected by complications of placenta cord and membranes",
            "title": "762 - Fetus or newborn affected by complications of placenta cord and membranes",
            "threedigit": "762",
            "children": [
              {
                "key": "7620",
                "title": "7620 Placenta previa aff NB",
                "threedigit": "762",
                "isLeaf": true
              },
              {
                "key": "7621",
                "title": "7621 Placenta hem NEC aff NB",
                "threedigit": "762",
                "isLeaf": true
              },
              {
                "key": "7622",
                "title": "7622 Abn plac NEC/NOS aff NB",
                "threedigit": "762",
                "isLeaf": true
              },
              {
                "key": "7623",
                "title": "7623 Placent transfusion syn",
                "threedigit": "762",
                "isLeaf": true
              },
              {
                "key": "7624",
                "title": "7624 Prolapsed cord aff NB",
                "threedigit": "762",
                "isLeaf": true
              },
              {
                "key": "7625",
                "title": "7625 Oth umbil cord compress",
                "threedigit": "762",
                "isLeaf": true
              },
              {
                "key": "7626",
                "title": "7626 Umbil cond NEC aff NB",
                "threedigit": "762",
                "isLeaf": true
              },
              {
                "key": "7627",
                "title": "7627 Chorioamnionitis aff NB",
                "threedigit": "762",
                "isLeaf": true
              },
              {
                "key": "7628",
                "title": "7628 Abn amnion NEC aff NB",
                "threedigit": "762",
                "isLeaf": true
              },
              {
                "key": "7629",
                "title": "7629 Abn amnion NOS aff NB",
                "threedigit": "762",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fetus or newborn affected by other complications of labor and delivery",
            "title": "763 - Fetus or newborn affected by other complications of labor and delivery",
            "threedigit": "763",
            "children": [
              {
                "key": "7630",
                "title": "7630 Breech del/extrac aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "7631",
                "title": "7631 Malpos/dispro NEC aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "7632",
                "title": "7632 Forceps delivery aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "7633",
                "title": "7633 Vacuum extrac del aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "7634",
                "title": "7634 Cesarean delivery aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "7635",
                "title": "7635 Mat anesth/analg aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "7636",
                "title": "7636 Precipitate del aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "7637",
                "title": "7637 Abn uterine contr aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "76381",
                "title": "76381 Ab ftl hrt rt/rh b/f lab",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "76382",
                "title": "76382 Ab ftl hrt rt/rh dur lab",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "76383",
                "title": "76383 Ab ftl hrt rt/rhy NOS",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "76384",
                "title": "76384 Meconium dur del aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "76389",
                "title": "76389 Compl del NEC aff NB",
                "threedigit": "763",
                "isLeaf": true
              },
              {
                "key": "7639",
                "title": "7639 Compl deliv NOS aff NB",
                "threedigit": "763",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Conditions Originating In The Perinatal Period",
        "title": "764-779 Other Conditions Originating In The Perinatal Period",
        "threedigit": "764",
        "children": [
          {
            "key": "Slow fetal growth and fetal malnutrition",
            "title": "764 - Slow fetal growth and fetal malnutrition",
            "threedigit": "764",
            "children": [
              {
                "key": "76400",
                "title": "76400 Light-for-dates wtNOS",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76401",
                "title": "76401 Light-for-dates <500g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76402",
                "title": "76402 Lt-for-dates 500-749g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76403",
                "title": "76403 Lt-for-dates 750-999g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76404",
                "title": "76404 Lt-for-dates 1000-1249g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76405",
                "title": "76405 Lt-for-dates 1250-1499g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76406",
                "title": "76406 Lt-for-dates 1500-1749g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76407",
                "title": "76407 Lt-for-dates 1750-1999g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76408",
                "title": "76408 Lt-for-dates 2000-2499g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76409",
                "title": "76409 Lt-for-dates 2500+g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76410",
                "title": "76410 Lt-for-date w/mal wtNOS",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76411",
                "title": "76411 Lt-for-date w/mal <500g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76412",
                "title": "76412 Lt-date w/mal 500-749g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76413",
                "title": "76413 Lt-date w/mal 750-999g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76414",
                "title": "76414 Lt-date w/mal 1000-1249g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76415",
                "title": "76415 Lt-date w/mal 1250-1499g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76416",
                "title": "76416 Lt-date w/mal 1500-1749g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76417",
                "title": "76417 Lt-date w/mal 1750-1999g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76418",
                "title": "76418 Lt-date w/mal 2000-2499g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76419",
                "title": "76419 Lt-for-date w/mal 2500+g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76420",
                "title": "76420 Fetal malnutrition wtNOS",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76421",
                "title": "76421 Fetal malnutrition <500g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76422",
                "title": "76422 Fetal malnutr 500-749g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76423",
                "title": "76423 Fetal mal 750-999g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76424",
                "title": "76424 Fetal mal 1000-1249g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76425",
                "title": "76425 Fetal mal 1250-1499g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76426",
                "title": "76426 Fetal mal 1500-1749g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76427",
                "title": "76427 Fetal malnutr 1750-1999g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76428",
                "title": "76428 Fetal malnutr 2000-2499g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76429",
                "title": "76429 Fetal malnutr 2500+g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76490",
                "title": "76490 Fet growth retard wtNOS",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76491",
                "title": "76491 Fet growth retard <500g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76492",
                "title": "76492 Fet growth ret 500-749g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76493",
                "title": "76493 Fet growth ret 750-999g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76494",
                "title": "76494 Fet grwth ret 1000-1249g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76495",
                "title": "76495 Fet grwth ret 1250-1499g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76496",
                "title": "76496 Fet grwth ret 1500-1749g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76497",
                "title": "76497 Fet grwth ret 1750-1999g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76498",
                "title": "76498 Fet grwth ret 2000-2499g",
                "threedigit": "764",
                "isLeaf": true
              },
              {
                "key": "76499",
                "title": "76499 Fet growth ret 2500+g",
                "threedigit": "764",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders relating to short gestation and low birthweight",
            "title": "765 - Disorders relating to short gestation and low birthweight",
            "threedigit": "765",
            "children": [
              {
                "key": "76500",
                "title": "76500 Extreme immatur wtNOS",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76501",
                "title": "76501 Extreme immatur <500g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76502",
                "title": "76502 Extreme immatur 500-749g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76503",
                "title": "76503 Extreme immatur 750-999g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76504",
                "title": "76504 Extreme immat 1000-1249g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76505",
                "title": "76505 Extreme immat 1250-1499g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76506",
                "title": "76506 Extreme immat 1500-1749g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76507",
                "title": "76507 Extreme immat 1750-1999g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76508",
                "title": "76508 Extreme immat 2000-2499g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76509",
                "title": "76509 Extreme immat 2500+g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76510",
                "title": "76510 Preterm infant NEC wtNOS",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76511",
                "title": "76511 Preterm NEC <500g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76512",
                "title": "76512 Preterm NEC 500-749g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76513",
                "title": "76513 Preterm NEC 750-999g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76514",
                "title": "76514 Preterm NEC 1000-1249g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76515",
                "title": "76515 Preterm NEC 1250-1499g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76516",
                "title": "76516 Preterm NEC 1500-1749g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76517",
                "title": "76517 Preterm NEC 1750-1999g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76518",
                "title": "76518 Preterm NEC 2000-2499g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76519",
                "title": "76519 Preterm NEC 2500+g",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76520",
                "title": "76520 Weeks of gestation NOS",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76521",
                "title": "76521 <24 comp wks gestation",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76522",
                "title": "76522 24 comp weeks gestation",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76523",
                "title": "76523 25-26 comp wks gestation",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76524",
                "title": "76524 27-28 comp wks gestation",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76525",
                "title": "76525 29-30 comp wks gestation",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76526",
                "title": "76526 31-32 comp wks gestation",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76527",
                "title": "76527 33-34 comp wks gestation",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76528",
                "title": "76528 35-36 comp wks gestation",
                "threedigit": "765",
                "isLeaf": true
              },
              {
                "key": "76529",
                "title": "76529 37+ comp wks gestation",
                "threedigit": "765",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Disorders relating to long gestation and high birthweight",
            "title": "766 - Disorders relating to long gestation and high birthweight",
            "threedigit": "766",
            "children": [
              {
                "key": "7660",
                "title": "7660 Exceptionally large baby",
                "threedigit": "766",
                "isLeaf": true
              },
              {
                "key": "7661",
                "title": "7661 Heavy-for-date infan NEC",
                "threedigit": "766",
                "isLeaf": true
              },
              {
                "key": "76621",
                "title": "76621 Post-term infant",
                "threedigit": "766",
                "isLeaf": true
              },
              {
                "key": "76622",
                "title": "76622 Prolong gestation-infant",
                "threedigit": "766",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Birth trauma",
            "title": "767 - Birth trauma",
            "threedigit": "767",
            "children": [
              {
                "key": "7670",
                "title": "7670 Cerebral hem at birth",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "76711",
                "title": "76711 Epicranial subapo hemorr",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "76719",
                "title": "76719 Injuries to scalp NEC",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "7672",
                "title": "7672 Clavicle fx at birth",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "7673",
                "title": "7673 Bone injury NEC at birth",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "7674",
                "title": "7674 Spinal cord inj at birth",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "7675",
                "title": "7675 Facial nerve inj-birth",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "7676",
                "title": "7676 Brach plexus inj-birth",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "7677",
                "title": "7677 Nerve inj NEC at birth",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "7678",
                "title": "7678 Birth trauma NEC",
                "threedigit": "767",
                "isLeaf": true
              },
              {
                "key": "7679",
                "title": "7679 Birth trauma NOS",
                "threedigit": "767",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Intrauterine hypoxia and birth asphyxia",
            "title": "768 - Intrauterine hypoxia and birth asphyxia",
            "threedigit": "768",
            "children": [
              {
                "key": "7680",
                "title": "7680 Fetal death-anoxia NOS",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "7681",
                "title": "7681 Fet death-anoxia dur lab",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "7682",
                "title": "7682 Fet distress befor labor",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "7683",
                "title": "7683 Fetal distrs dur lab/del",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "7684",
                "title": "7684 Fetal distress NOS",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "7685",
                "title": "7685 Severe birth asphyxia",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "7686",
                "title": "7686 Mild/mod birth asphyxia",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "76870",
                "title": "76870 Hypoxc-ischem enceph NOS",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "76871",
                "title": "76871 Mild hypox-ischem enceph",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "76872",
                "title": "76872 Mod hypox-ischem enceph",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "76873",
                "title": "76873 Sev hypox-ischem enceph",
                "threedigit": "768",
                "isLeaf": true
              },
              {
                "key": "7689",
                "title": "7689 Birth asphyxia NOS",
                "threedigit": "768",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Respiratory distress syndrome in newborn",
            "title": "769 - Respiratory distress syndrome in newborn",
            "threedigit": "769",
            "children": [
              {
                "key": "769",
                "title": "769 Respiratory distress syn",
                "threedigit": "769",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other respiratory conditions of fetus and newborn",
            "title": "770 - Other respiratory conditions of fetus and newborn",
            "threedigit": "770",
            "children": [
              {
                "key": "7700",
                "title": "7700 Congenital pneumonia",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77010",
                "title": "77010 Fetal & newborn asp NOS",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77011",
                "title": "77011 Meconium asp wo resp sym",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77012",
                "title": "77012 Meconium asp w resp symp",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77013",
                "title": "77013 Amniotc asp w/o resp sym",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77014",
                "title": "77014 Amniotic asp w resp sym",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77015",
                "title": "77015 Blood asp w/o resp sympt",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77016",
                "title": "77016 Blood asp w resp sympt",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77017",
                "title": "77017 NB asp w/o resp symp NEC",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77018",
                "title": "77018 NB asp w resp symp NEC",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "7702",
                "title": "7702 NB interstit emphysema",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "7703",
                "title": "7703 NB pulmonary hemorrhage",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "7704",
                "title": "7704 Primary atelectasis",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "7705",
                "title": "7705 NB atelectasis NEC/NOS",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "7706",
                "title": "7706 NB transitory tachypnea",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "7707",
                "title": "7707 Perinatal chr resp dis",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77081",
                "title": "77081 Primary apnea of newborn",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77082",
                "title": "77082 Other apnea of newborn",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77083",
                "title": "77083 Cyanotic attack, newborn",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77084",
                "title": "77084 Resp failure of newborn",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77085",
                "title": "77085 Stomch cont asp w/o resp",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77086",
                "title": "77086 Stomach cont asp w resp",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77087",
                "title": "77087 NB respiratory arrest",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77088",
                "title": "77088 NB hypoxia",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "77089",
                "title": "77089 Resp prob after brth NEC",
                "threedigit": "770",
                "isLeaf": true
              },
              {
                "key": "7709",
                "title": "7709 NB respiratory cond NOS",
                "threedigit": "770",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Infections specific to the perinatal period",
            "title": "771 - Infections specific to the perinatal period",
            "threedigit": "771",
            "children": [
              {
                "key": "7710",
                "title": "7710 Congenital rubella",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "7711",
                "title": "7711 Cong cytomegalovirus inf",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "7712",
                "title": "7712 Congenital infec NEC",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "7713",
                "title": "7713 Tetanus neonatorum",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "7714",
                "title": "7714 Omphalitis of newborn",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "7715",
                "title": "7715 Neonatal infec mastitis",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "7716",
                "title": "7716 Neonatal conjunctivitis",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "7717",
                "title": "7717 Neonatal candida infect",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "77181",
                "title": "77181 NB septicemia [sepsis]",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "77182",
                "title": "77182 NB urinary tract infectn",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "77183",
                "title": "77183 Bacteremia of newborn",
                "threedigit": "771",
                "isLeaf": true
              },
              {
                "key": "77189",
                "title": "77189 Perinatal infection NEC",
                "threedigit": "771",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fetal and neonatal hemorrhage",
            "title": "772 - Fetal and neonatal hemorrhage",
            "threedigit": "772",
            "children": [
              {
                "key": "7720",
                "title": "7720 NB fetal blood loss NEC",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "77210",
                "title": "77210 NB intraven hem NOS",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "77211",
                "title": "77211 NB intraven hem,grade i",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "77212",
                "title": "77212 NB intraven hem,grade ii",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "77213",
                "title": "77213 NB intravn hem,grade iii",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "77214",
                "title": "77214 NB intraven hem,grade iv",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "7722",
                "title": "7722 NB subarachnoid hemorr",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "7723",
                "title": "7723 Post-birth umbil hemorr",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "7724",
                "title": "7724 NB GI hemorrhage",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "7725",
                "title": "7725 NB adrenal hemorrhage",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "7726",
                "title": "7726 NB cutaneous hemorrhage",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "7728",
                "title": "7728 Neonatal hemorrhage NEC",
                "threedigit": "772",
                "isLeaf": true
              },
              {
                "key": "7729",
                "title": "7729 Neonatal hemorrhage NOS",
                "threedigit": "772",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hemolytic disease of fetus or newborn due to isoimmunization",
            "title": "773 - Hemolytic disease of fetus or newborn due to isoimmunization",
            "threedigit": "773",
            "children": [
              {
                "key": "7730",
                "title": "7730 NB hemolyt dis:rh isoimm",
                "threedigit": "773",
                "isLeaf": true
              },
              {
                "key": "7731",
                "title": "7731 NB hemolyt dis-abo isoim",
                "threedigit": "773",
                "isLeaf": true
              },
              {
                "key": "7732",
                "title": "7732 NB hemolyt dis-isoim NEC",
                "threedigit": "773",
                "isLeaf": true
              },
              {
                "key": "7733",
                "title": "7733 Hydrops fetalis:isoimm",
                "threedigit": "773",
                "isLeaf": true
              },
              {
                "key": "7734",
                "title": "7734 NB kernicterus:isoimmun",
                "threedigit": "773",
                "isLeaf": true
              },
              {
                "key": "7735",
                "title": "7735 NB late anemia:isoimmun",
                "threedigit": "773",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other perinatal jaundice",
            "title": "774 - Other perinatal jaundice",
            "threedigit": "774",
            "children": [
              {
                "key": "7740",
                "title": "7740 Perinat jaund-hered anem",
                "threedigit": "774",
                "isLeaf": true
              },
              {
                "key": "7741",
                "title": "7741 Perinat jaund:hemolysis",
                "threedigit": "774",
                "isLeaf": true
              },
              {
                "key": "7742",
                "title": "7742 Neonat jaund preterm del",
                "threedigit": "774",
                "isLeaf": true
              },
              {
                "key": "77430",
                "title": "77430 Delay conjugat jaund NOS",
                "threedigit": "774",
                "isLeaf": true
              },
              {
                "key": "77431",
                "title": "77431 Neonat jaund in oth dis",
                "threedigit": "774",
                "isLeaf": true
              },
              {
                "key": "77439",
                "title": "77439 Delay conjugat jaund NEC",
                "threedigit": "774",
                "isLeaf": true
              },
              {
                "key": "7744",
                "title": "7744 Fetal/neonatal hepatitis",
                "threedigit": "774",
                "isLeaf": true
              },
              {
                "key": "7745",
                "title": "7745 Perinatal jaundice NEC",
                "threedigit": "774",
                "isLeaf": true
              },
              {
                "key": "7746",
                "title": "7746 Fetal/neonatal jaund NOS",
                "threedigit": "774",
                "isLeaf": true
              },
              {
                "key": "7747",
                "title": "7747 NB kernicterus",
                "threedigit": "774",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Endocrine and metabolic disturbances specific to the fetus and newborn",
            "title": "775 - Endocrine and metabolic disturbances specific to the fetus and newborn",
            "threedigit": "775",
            "children": [
              {
                "key": "7750",
                "title": "7750 Infant diabet mother syn",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "7751",
                "title": "7751 Neonat diabetes mellitus",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "7752",
                "title": "7752 Neonat myasthenia gravis",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "7753",
                "title": "7753 Neonatal thyrotoxicosis",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "7754",
                "title": "7754 Hypocalcem/hypomagnes NB",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "7755",
                "title": "7755 Neonatal dehydration",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "7756",
                "title": "7756 Neonatal hypoglycemia",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "7757",
                "title": "7757 Late metab acidosis NB",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "77581",
                "title": "77581 NB acidosis NEC",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "77589",
                "title": "77589 Neonat endo/met dis NEC",
                "threedigit": "775",
                "isLeaf": true
              },
              {
                "key": "7759",
                "title": "7759 Transient met dis NB NOS",
                "threedigit": "775",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hematological disorders of newborn",
            "title": "776 - Hematological disorders of newborn",
            "threedigit": "776",
            "children": [
              {
                "key": "7760",
                "title": "7760 NB hemorrhagic disease",
                "threedigit": "776",
                "isLeaf": true
              },
              {
                "key": "7761",
                "title": "7761 Neonatal thrombocytopen",
                "threedigit": "776",
                "isLeaf": true
              },
              {
                "key": "7762",
                "title": "7762 Dissem intravasc coag NB",
                "threedigit": "776",
                "isLeaf": true
              },
              {
                "key": "7763",
                "title": "7763 Oth neonatal coag dis",
                "threedigit": "776",
                "isLeaf": true
              },
              {
                "key": "7764",
                "title": "7764 Polycythemia neonatorum",
                "threedigit": "776",
                "isLeaf": true
              },
              {
                "key": "7765",
                "title": "7765 Congenital anemia",
                "threedigit": "776",
                "isLeaf": true
              },
              {
                "key": "7766",
                "title": "7766 Anemia of prematurity",
                "threedigit": "776",
                "isLeaf": true
              },
              {
                "key": "7767",
                "title": "7767 Neonatal neutropenia",
                "threedigit": "776",
                "isLeaf": true
              },
              {
                "key": "7768",
                "title": "7768 Transient hemat dis NEC",
                "threedigit": "776",
                "isLeaf": true
              },
              {
                "key": "7769",
                "title": "7769 NB hematological dis NOS",
                "threedigit": "776",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Perinatal disorders of digestive system",
            "title": "777 - Perinatal disorders of digestive system",
            "threedigit": "777",
            "children": [
              {
                "key": "7771",
                "title": "7771 Meconium obstruction",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "7772",
                "title": "7772 Intest obst-inspiss milk",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "7773",
                "title": "7773 Swallowed blood syndrome",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "7774",
                "title": "7774 Transitory ileus of NB",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "77750",
                "title": "77750 Nec enterocoltis NB NOS",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "77751",
                "title": "77751 Stg I nec enterocol NB",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "77752",
                "title": "77752 Stg II nec enterocol NB",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "77753",
                "title": "77753 Stg III nec enterocol NB",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "7776",
                "title": "7776 Perinatal intest perfor",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "7778",
                "title": "7778 Perinat GI sys dis NEC",
                "threedigit": "777",
                "isLeaf": true
              },
              {
                "key": "7779",
                "title": "7779 Perinat GI sys dis NOS",
                "threedigit": "777",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Conditions involving the integument and temperature regulation of fetus and newborn",
            "title": "778 - Conditions involving the integument and temperature regulation of fetus and newborn",
            "threedigit": "778",
            "children": [
              {
                "key": "7780",
                "title": "7780 Hydrops fetalis no isoim",
                "threedigit": "778",
                "isLeaf": true
              },
              {
                "key": "7781",
                "title": "7781 Sclerema neonatorum",
                "threedigit": "778",
                "isLeaf": true
              },
              {
                "key": "7782",
                "title": "7782 NB cold injury syndrome",
                "threedigit": "778",
                "isLeaf": true
              },
              {
                "key": "7783",
                "title": "7783 NB hypothermia NEC",
                "threedigit": "778",
                "isLeaf": true
              },
              {
                "key": "7784",
                "title": "7784 NB temp regulat dis NEC",
                "threedigit": "778",
                "isLeaf": true
              },
              {
                "key": "7785",
                "title": "7785 Edema of newborn NEC/NOS",
                "threedigit": "778",
                "isLeaf": true
              },
              {
                "key": "7786",
                "title": "7786 Congenital hydrocele",
                "threedigit": "778",
                "isLeaf": true
              },
              {
                "key": "7787",
                "title": "7787 NB breast engorgement",
                "threedigit": "778",
                "isLeaf": true
              },
              {
                "key": "7788",
                "title": "7788 NB integument cond NEC",
                "threedigit": "778",
                "isLeaf": true
              },
              {
                "key": "7789",
                "title": "7789 NB integument cond NOS",
                "threedigit": "778",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and ill-defined conditions originating in the perinatal period",
            "title": "779 - Other and ill-defined conditions originating in the perinatal period",
            "threedigit": "779",
            "children": [
              {
                "key": "7790",
                "title": "7790 Convulsions in newborn",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "7791",
                "title": "7791 NB cereb irrit NEC/NOS",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "7792",
                "title": "7792 Cns dysfunction syn NB",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77931",
                "title": "77931 NB feeding problems",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77932",
                "title": "77932 NB bilious vomiting",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77933",
                "title": "77933 NB other vomiting",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77934",
                "title": "77934 NB failure to thrive",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "7794",
                "title": "7794 NB drug reaction/intoxic",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "7795",
                "title": "7795 NB drug withdrawal syndr",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "7796",
                "title": "7796 Termination of pregnancy",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "7797",
                "title": "7797 Perivent leukomalacia",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77981",
                "title": "77981 Neonatal bradycardia",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77982",
                "title": "77982 Neonatal tachycardia",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77983",
                "title": "77983 Delay separate umbl cord",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77984",
                "title": "77984 Meconium staining",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77985",
                "title": "77985 NB cardiac arrest",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "77989",
                "title": "77989 Perinatal condition NEC",
                "threedigit": "779",
                "isLeaf": true
              },
              {
                "key": "7799",
                "title": "7799 Perinatal condition NOS",
                "threedigit": "779",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Symptoms, Signs, And Ill-Defined Conditions",
    "title": "780-799 Symptoms, Signs, And Ill-Defined Conditions",
    "threedigit": "780",
    "children": [
      {
        "key": "Symptoms",
        "title": "780-789 Symptoms",
        "threedigit": "780",
        "children": [
          {
            "key": "General symptoms",
            "title": "780 - General symptoms",
            "threedigit": "780",
            "children": [
              {
                "key": "78001",
                "title": "78001 Coma",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78002",
                "title": "78002 Trans alter awareness",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78003",
                "title": "78003 Persistent vegtv state",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78009",
                "title": "78009 Other alter consciousnes",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "7801",
                "title": "7801 Hallucinations",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "7802",
                "title": "7802 Syncope and collapse",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78031",
                "title": "78031 Febrile convulsions NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78032",
                "title": "78032 Complx febrile convulsns",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78033",
                "title": "78033 Post traumatic seizures",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78039",
                "title": "78039 Convulsions NEC",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "7804",
                "title": "7804 Dizziness and giddiness",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78050",
                "title": "78050 Sleep disturbance NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78051",
                "title": "78051 Insomn w sleep apnea NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78052",
                "title": "78052 Insomnia NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78053",
                "title": "78053 Hypersom w slp apnea NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78054",
                "title": "78054 Hypersomnia NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78055",
                "title": "78055 Irreg sleep-wake rhy NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78056",
                "title": "78056 Sleep stage dysfunctions",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78057",
                "title": "78057 Sleep apnea NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78058",
                "title": "78058 Sleep rel move disor NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78059",
                "title": "78059 Sleep disturbances NEC",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78060",
                "title": "78060 Fever NOS",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78061",
                "title": "78061 Fever in other diseases",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78062",
                "title": "78062 Postprocedural fever",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78063",
                "title": "78063 Postvaccination fever",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78064",
                "title": "78064 Chills (without fever)",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78065",
                "title": "78065 Hypothrm-wo low env tmp",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78066",
                "title": "78066 Feb nonhemo transf react",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78071",
                "title": "78071 Chronic fatigue syndrome",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78072",
                "title": "78072 Functional quadriplegia",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78079",
                "title": "78079 Malaise and fatigue NEC",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "7808",
                "title": "7808 Generalizd hyperhidrosis",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78091",
                "title": "78091 Fussy infant/baby",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78092",
                "title": "78092 Excess cry infant/baby",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78093",
                "title": "78093 Memory loss",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78094",
                "title": "78094 Early satiety",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78095",
                "title": "78095 Excs cry chld,adol,adult",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78096",
                "title": "78096 Generalized pain",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78097",
                "title": "78097 Altered mental status",
                "threedigit": "780",
                "isLeaf": true
              },
              {
                "key": "78099",
                "title": "78099 Other general symptoms",
                "threedigit": "780",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Symptoms involving nervous and musculoskeletal systems",
            "title": "781 - Symptoms involving nervous and musculoskeletal systems",
            "threedigit": "781",
            "children": [
              {
                "key": "7810",
                "title": "7810 Abn involun movement NEC",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "7811",
                "title": "7811 Smell & taste disturb",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "7812",
                "title": "7812 Abnormality of gait",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "7813",
                "title": "7813 Lack of coordination",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "7814",
                "title": "7814 Transient limb paralysis",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "7815",
                "title": "7815 Clubbing of fingers",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "7816",
                "title": "7816 Meningismus",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "7817",
                "title": "7817 Tetany",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "7818",
                "title": "7818 Neurologic neglect syndr",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "78191",
                "title": "78191 Loss of height",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "78192",
                "title": "78192 Abnormal posture",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "78193",
                "title": "78193 Ocular torticollis",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "78194",
                "title": "78194 Facial weakness",
                "threedigit": "781",
                "isLeaf": true
              },
              {
                "key": "78199",
                "title": "78199 Nerve/musculskel sym NEC",
                "threedigit": "781",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Symptoms involving skin and other integumentary tissue",
            "title": "782 - Symptoms involving skin and other integumentary tissue",
            "threedigit": "782",
            "children": [
              {
                "key": "7820",
                "title": "7820 Skin sensation disturb",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "7821",
                "title": "7821 Nonspecif skin erupt NEC",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "7822",
                "title": "7822 Local suprficial swellng",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "7823",
                "title": "7823 Edema",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "7824",
                "title": "7824 Jaundice NOS",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "7825",
                "title": "7825 Cyanosis",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "78261",
                "title": "78261 Pallor",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "78262",
                "title": "78262 Flushing",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "7827",
                "title": "7827 Spontaneous ecchymoses",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "7828",
                "title": "7828 Changes in skin texture",
                "threedigit": "782",
                "isLeaf": true
              },
              {
                "key": "7829",
                "title": "7829 Integument tiss symp NEC",
                "threedigit": "782",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Symptoms concerning nutrition metabolism and development",
            "title": "783 - Symptoms concerning nutrition metabolism and development",
            "threedigit": "783",
            "children": [
              {
                "key": "7830",
                "title": "7830 Anorexia",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "7831",
                "title": "7831 Abnormal weight gain",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "78321",
                "title": "78321 Abnormal loss of weight",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "78322",
                "title": "78322 Underweight",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "7833",
                "title": "7833 Feeding problem",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "78340",
                "title": "78340 Lack norm physio dev NOS",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "78341",
                "title": "78341 Failure to thrive-child",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "78342",
                "title": "78342 Delayed milestones",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "78343",
                "title": "78343 Short stature",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "7835",
                "title": "7835 Polydipsia",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "7836",
                "title": "7836 Polyphagia",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "7837",
                "title": "7837 Failure to thrive-adult",
                "threedigit": "783",
                "isLeaf": true
              },
              {
                "key": "7839",
                "title": "7839 Nutr/metab/devel sym NEC",
                "threedigit": "783",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Symptoms involving head and neck",
            "title": "784 - Symptoms involving head and neck",
            "threedigit": "784",
            "children": [
              {
                "key": "7840",
                "title": "7840 Headache",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "7841",
                "title": "7841 Throat pain",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "7842",
                "title": "7842 Swelling in head & neck",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "7843",
                "title": "7843 Aphasia",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78440",
                "title": "78440 Voice/resonance dis NOS",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78441",
                "title": "78441 Aphonia",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78442",
                "title": "78442 Dysphonia",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78443",
                "title": "78443 Hypernasality",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78444",
                "title": "78444 Hyponasality",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78449",
                "title": "78449 Voice/resonance dis NEC",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78451",
                "title": "78451 Dysarthria",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78452",
                "title": "78452 Flncy dsord cond elsewhr",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78459",
                "title": "78459 Speech disturbance NEC",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78460",
                "title": "78460 Symbolic dysfunction NOS",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78461",
                "title": "78461 Alexia and dyslexia",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78469",
                "title": "78469 Symbolic dysfunction NEC",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "7847",
                "title": "7847 Epistaxis",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "7848",
                "title": "7848 Hemorrhage from throat",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78491",
                "title": "78491 Postnasal drip",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78492",
                "title": "78492 Jaw pain",
                "threedigit": "784",
                "isLeaf": true
              },
              {
                "key": "78499",
                "title": "78499 Head & neck symptoms NEC",
                "threedigit": "784",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Symptoms involving cardiovascular system",
            "title": "785 - Symptoms involving cardiovascular system",
            "threedigit": "785",
            "children": [
              {
                "key": "7850",
                "title": "7850 Tachycardia NOS",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "7851",
                "title": "7851 Palpitations",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "7852",
                "title": "7852 Cardiac murmurs NEC",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "7853",
                "title": "7853 Abnorm heart sounds NEC",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "7854",
                "title": "7854 Gangrene",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "78550",
                "title": "78550 Shock NOS",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "78551",
                "title": "78551 Cardiogenic shock",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "78552",
                "title": "78552 Septic shock",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "78559",
                "title": "78559 Shock w/o trauma NEC",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "7856",
                "title": "7856 Enlargement lymph nodes",
                "threedigit": "785",
                "isLeaf": true
              },
              {
                "key": "7859",
                "title": "7859 Cardiovas sys symp NEC",
                "threedigit": "785",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Symptoms involving respiratory system and other chest symptoms",
            "title": "786 - Symptoms involving respiratory system and other chest symptoms",
            "threedigit": "786",
            "children": [
              {
                "key": "78600",
                "title": "78600 Respiratory abnorm NOS",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78601",
                "title": "78601 Hyperventilation",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78602",
                "title": "78602 Orthopnea",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78603",
                "title": "78603 Apnea",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78604",
                "title": "78604 Cheyne-stokes respiratn",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78605",
                "title": "78605 Shortness of breath",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78606",
                "title": "78606 Tachypnea",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78607",
                "title": "78607 Wheezing",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78609",
                "title": "78609 Respiratory abnorm NEC",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "7861",
                "title": "7861 Stridor",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "7862",
                "title": "7862 Cough",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78630",
                "title": "78630 Hemoptysis NOS",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78631",
                "title": "78631 Ac idio pul hemrg infant",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78639",
                "title": "78639 Hemoptysis NEC",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "7864",
                "title": "7864 Abnormal sputum",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78650",
                "title": "78650 Chest pain NOS",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78651",
                "title": "78651 Precordial pain",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78652",
                "title": "78652 Painful respiration",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "78659",
                "title": "78659 Chest pain NEC",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "7866",
                "title": "7866 Chest swelling/mass/lump",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "7867",
                "title": "7867 Abnormal chest sounds",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "7868",
                "title": "7868 Hiccough",
                "threedigit": "786",
                "isLeaf": true
              },
              {
                "key": "7869",
                "title": "7869 Resp sys/chest symp NEC",
                "threedigit": "786",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Symptoms involving digestive system",
            "title": "787 - Symptoms involving digestive system",
            "threedigit": "787",
            "children": [
              {
                "key": "78701",
                "title": "78701 Nausea with vomiting",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78702",
                "title": "78702 Nausea alone",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78703",
                "title": "78703 Vomiting alone",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78704",
                "title": "78704 Bilious emesis",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "7871",
                "title": "7871 Heartburn",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78720",
                "title": "78720 Dysphagia NOS",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78721",
                "title": "78721 Dysphagia, oral phase",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78722",
                "title": "78722 Dysphagia, oropharyngeal",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78723",
                "title": "78723 Dysphagia, pharyngeal",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78724",
                "title": "78724 Dysphagia,pharyngoesoph",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78729",
                "title": "78729 Dysphagia NEC",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "7873",
                "title": "7873 Flatul/eructat/gas pain",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "7874",
                "title": "7874 Visible peristalsis",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "7875",
                "title": "7875 Abnormal bowel sounds",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78760",
                "title": "78760 Full incontinence-feces",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78761",
                "title": "78761 Incomplete defecation",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78762",
                "title": "78762 Fecal smearing",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78763",
                "title": "78763 Fecal urgency",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "7877",
                "title": "7877 Abnormal feces",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78791",
                "title": "78791 Diarrhea",
                "threedigit": "787",
                "isLeaf": true
              },
              {
                "key": "78799",
                "title": "78799 Digestve syst symptm NEC",
                "threedigit": "787",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Symptoms involving urinary system",
            "title": "788 - Symptoms involving urinary system",
            "threedigit": "788",
            "children": [
              {
                "key": "7880",
                "title": "7880 Renal colic",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "7881",
                "title": "7881 Dysuria",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78820",
                "title": "78820 Retention urine NOS",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78821",
                "title": "78821 Incmplet bldder emptying",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78829",
                "title": "78829 Oth spcf retention urine",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78830",
                "title": "78830 Urinary incontinence NOS",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78831",
                "title": "78831 Urge incontinence",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78832",
                "title": "78832 Stress incontinence male",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78833",
                "title": "78833 Mixed incontinence",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78834",
                "title": "78834 Incontnce wo sensr aware",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78835",
                "title": "78835 Post-void dribbling",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78836",
                "title": "78836 Nocturnal enuresis",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78837",
                "title": "78837 Continuous leakage",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78838",
                "title": "78838 Overflow incontinence",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78839",
                "title": "78839 Oth urinry incontinence",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78841",
                "title": "78841 Urinary frequency",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78842",
                "title": "78842 Polyuria",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78843",
                "title": "78843 Nocturia",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "7885",
                "title": "7885 Oliguria & anuria",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78861",
                "title": "78861 Splitting urinary stream",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78862",
                "title": "78862 Slowing urinary stream",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78863",
                "title": "78863 Urgency of urination",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78864",
                "title": "78864 Urinary hesitancy",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78865",
                "title": "78865 Straining on urination",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78869",
                "title": "78869 Oth abnormalt urination",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "7887",
                "title": "7887 Urethral discharge",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "7888",
                "title": "7888 Extravasation of urine",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78891",
                "title": "78891 Fnctnl urinary incontnce",
                "threedigit": "788",
                "isLeaf": true
              },
              {
                "key": "78899",
                "title": "78899 Oth symptm urinary systm",
                "threedigit": "788",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other symptoms involving abdomen and pelvis",
            "title": "789 - Other symptoms involving abdomen and pelvis",
            "threedigit": "789",
            "children": [
              {
                "key": "78900",
                "title": "78900 Abdmnal pain unspcf site",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78901",
                "title": "78901 Abdmnal pain rt upr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78902",
                "title": "78902 Abdmnal pain lft up quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78903",
                "title": "78903 Abdmnal pain rt lwr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78904",
                "title": "78904 Abdmnal pain lt lwr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78905",
                "title": "78905 Abdmnal pain periumbilic",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78906",
                "title": "78906 Abdmnal pain epigastric",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78907",
                "title": "78907 Abdmnal pain generalized",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78909",
                "title": "78909 Abdmnal pain oth spcf st",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "7891",
                "title": "7891 Hepatomegaly",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "7892",
                "title": "7892 Splenomegaly",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78930",
                "title": "78930 Abdmnal mass unspcf site",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78931",
                "title": "78931 Abdmnal mass rt upr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78932",
                "title": "78932 Abdmnal mass lft up quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78933",
                "title": "78933 Abdmnal mass rt lwr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78934",
                "title": "78934 Abdmnal mass lt lwr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78935",
                "title": "78935 Abdmnal mass periumbilic",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78936",
                "title": "78936 Abdmnal mass epigastric",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78937",
                "title": "78937 Abdmnal mass generalized",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78939",
                "title": "78939 Abdmnal mass oth spcf st",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78940",
                "title": "78940 Abdmnal rgdt unspcf site",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78941",
                "title": "78941 Abdmnal rgdt rt upr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78942",
                "title": "78942 Abdmnal rgdt lft up quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78943",
                "title": "78943 Abdmnal rgdt rt lwr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78944",
                "title": "78944 Abdmnal rgdt lt lwr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78945",
                "title": "78945 Abdmnal rgdt periumbilic",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78946",
                "title": "78946 Abdmnal rgdt epigastric",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78947",
                "title": "78947 Abdmnal rgdt generalized",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78949",
                "title": "78949 Abdmnal rgdt oth spcf st",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78951",
                "title": "78951 Malignant ascites",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78959",
                "title": "78959 Ascites NEC",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78960",
                "title": "78960 Abdmnal tndr unspcf site",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78961",
                "title": "78961 Abdmnal tndr rt upr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78962",
                "title": "78962 Abdmnal tndr lft up quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78963",
                "title": "78963 Abdmnal tndr rt lwr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78964",
                "title": "78964 Abdmnal tndr lt lwr quad",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78965",
                "title": "78965 Abdmnal tndr periumbilic",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78966",
                "title": "78966 Abdmnal tndr epigastric",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78967",
                "title": "78967 Abdmnal tndr generalized",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "78969",
                "title": "78969 Abdmnal tndr oth spcf st",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "7897",
                "title": "7897 Colic",
                "threedigit": "789",
                "isLeaf": true
              },
              {
                "key": "7899",
                "title": "7899 Abdomen/pelvis symp NEC",
                "threedigit": "789",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Nonspecific Abnormal Findings",
        "title": "790-796 Nonspecific Abnormal Findings",
        "threedigit": "790",
        "children": [
          {
            "key": "Nonspecific findings on examination of blood",
            "title": "790 - Nonspecific findings on examination of blood",
            "threedigit": "790",
            "children": [
              {
                "key": "79001",
                "title": "79001 Drop, hematocrit, precip",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79009",
                "title": "79009 Abnormal RBC NEC",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "7901",
                "title": "7901 Elevated sediment rate",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79021",
                "title": "79021 Impaired fasting glucose",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79022",
                "title": "79022 Impaired oral glucse tol",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79029",
                "title": "79029 Abnormal glucose NEC",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "7903",
                "title": "7903 Excess blood-alcohol lev",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "7904",
                "title": "7904 Elev transaminase/ldh",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "7905",
                "title": "7905 Abn serum enzy level NEC",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "7906",
                "title": "7906 Abn blood chemistry NEC",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "7907",
                "title": "7907 Bacteremia",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "7908",
                "title": "7908 Viremia NOS",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79091",
                "title": "79091 Abnrml art blood gases",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79092",
                "title": "79092 Abnrml coagultion prfile",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79093",
                "title": "79093 Elvtd prstate spcf antgn",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79094",
                "title": "79094 Euthyroid sick syndrome",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79095",
                "title": "79095 Elev C-reactive protein",
                "threedigit": "790",
                "isLeaf": true
              },
              {
                "key": "79099",
                "title": "79099 Oth nspcf finding blood",
                "threedigit": "790",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nonspecific findings on examination of urine",
            "title": "791 - Nonspecific findings on examination of urine",
            "threedigit": "791",
            "children": [
              {
                "key": "7910",
                "title": "7910 Proteinuria",
                "threedigit": "791",
                "isLeaf": true
              },
              {
                "key": "7911",
                "title": "7911 Chyluria",
                "threedigit": "791",
                "isLeaf": true
              },
              {
                "key": "7912",
                "title": "7912 Hemoglobinuria",
                "threedigit": "791",
                "isLeaf": true
              },
              {
                "key": "7913",
                "title": "7913 Myoglobinuria",
                "threedigit": "791",
                "isLeaf": true
              },
              {
                "key": "7914",
                "title": "7914 Biliuria",
                "threedigit": "791",
                "isLeaf": true
              },
              {
                "key": "7915",
                "title": "7915 Glycosuria",
                "threedigit": "791",
                "isLeaf": true
              },
              {
                "key": "7916",
                "title": "7916 Acetonuria",
                "threedigit": "791",
                "isLeaf": true
              },
              {
                "key": "7917",
                "title": "7917 Oth cells/casts in urine",
                "threedigit": "791",
                "isLeaf": true
              },
              {
                "key": "7919",
                "title": "7919 Abn urine findings NEC",
                "threedigit": "791",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nonspecific abnormal findings in other body substances",
            "title": "792 - Nonspecific abnormal findings in other body substances",
            "threedigit": "792",
            "children": [
              {
                "key": "7920",
                "title": "7920 Abn fnd-cerebrospinal fl",
                "threedigit": "792",
                "isLeaf": true
              },
              {
                "key": "7921",
                "title": "7921 Abn find-stool contents",
                "threedigit": "792",
                "isLeaf": true
              },
              {
                "key": "7922",
                "title": "7922 Abn findings-semen",
                "threedigit": "792",
                "isLeaf": true
              },
              {
                "key": "7923",
                "title": "7923 Abn find-amniotic fluid",
                "threedigit": "792",
                "isLeaf": true
              },
              {
                "key": "7924",
                "title": "7924 Abn findings-saliva",
                "threedigit": "792",
                "isLeaf": true
              },
              {
                "key": "7925",
                "title": "7925 Cloudy dialysis effluent",
                "threedigit": "792",
                "isLeaf": true
              },
              {
                "key": "7929",
                "title": "7929 Abn find-body subst NEC",
                "threedigit": "792",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nonspecific (abnormal) findings on radiological and other examination of body structure",
            "title": "793 - Nonspecific (abnormal) findings on radiological and other examination of body structure",
            "threedigit": "793",
            "children": [
              {
                "key": "7930",
                "title": "7930 Nonsp abn fd-skull/head",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "79311",
                "title": "79311 Solitary pulmonry nodule",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "79319",
                "title": "79319 Ot nonsp ab fnd lung fld",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "7932",
                "title": "7932 Nonsp abn intrathor NEC",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "7933",
                "title": "7933 Nonsp abn fd-bilry tract",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "7934",
                "title": "7934 Nonsp abn find-gi tract",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "7935",
                "title": "7935 Nonsp abn find-gu organs",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "7936",
                "title": "7936 Nonsp abn fnd-abdom area",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "7937",
                "title": "7937 Nonsp abn find-ms system",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "79380",
                "title": "79380 Ab mammogram NOS",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "79381",
                "title": "79381 Mammographic microcalcif",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "79382",
                "title": "79382 Inconclusive mammogram",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "79389",
                "title": "79389 Abn finding-breast NEC",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "79391",
                "title": "79391 Image test incon d/t fat",
                "threedigit": "793",
                "isLeaf": true
              },
              {
                "key": "79399",
                "title": "79399 Nonsp abn find-body NEC",
                "threedigit": "793",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nonspecific abnormal results of function studies",
            "title": "794 - Nonspecific abnormal results of function studies",
            "threedigit": "794",
            "children": [
              {
                "key": "79400",
                "title": "79400 Abn cns funct study NOS",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79401",
                "title": "79401 Abnorm echoencephalogram",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79402",
                "title": "79402 Abn electroencephalogram",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79409",
                "title": "79409 Abn cns funct study NEC",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79410",
                "title": "79410 Abn stimul response NOS",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79411",
                "title": "79411 Abn retinal funct study",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79412",
                "title": "79412 Abnorm electro-oculogram",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79413",
                "title": "79413 Abnormal vep",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79414",
                "title": "79414 Abn oculomotor studies",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79415",
                "title": "79415 Abn auditory funct study",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79416",
                "title": "79416 Abn vestibular func stud",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79417",
                "title": "79417 Abnorm electromyogram",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79419",
                "title": "79419 Abn periph nerv stud NEC",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "7942",
                "title": "7942 Abn pulmonary func study",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79430",
                "title": "79430 Abn cardiovasc study NOS",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79431",
                "title": "79431 Abnorm electrocardiogram",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "79439",
                "title": "79439 Abn cardiovasc study NEC",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "7944",
                "title": "7944 Abn kidney funct study",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "7945",
                "title": "7945 Abn thyroid funct study",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "7946",
                "title": "7946 Abn endocrine study NEC",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "7947",
                "title": "7947 Abn basal metabol study",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "7948",
                "title": "7948 Abn liver function study",
                "threedigit": "794",
                "isLeaf": true
              },
              {
                "key": "7949",
                "title": "7949 Abn function study NEC",
                "threedigit": "794",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and nonspecific abnormal cytological, histological, immunological, and dna test findings",
            "title": "795 - Other and nonspecific abnormal cytological, histological, immunological, and dna test findings",
            "threedigit": "795",
            "children": [
              {
                "key": "79500",
                "title": "79500 Abn glandular pap smear",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79501",
                "title": "79501 Pap smear (ASC-US)",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79502",
                "title": "79502 Pap smear (ASC-H)",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79503",
                "title": "79503 Pap smear cervix w LGSIL",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79504",
                "title": "79504 Pap smear cervix w HGSIL",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79505",
                "title": "79505 Cervical (HPV) DNA pos",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79506",
                "title": "79506 Pap smr cytol evid malig",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79507",
                "title": "79507 Sat cerv smr-no trnsfrm",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79508",
                "title": "79508 Unsat cerv cytlogy smear",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79509",
                "title": "79509 Abn pap cervix HPV NEC",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79510",
                "title": "79510 Abn gland pap smr vagina",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79511",
                "title": "79511 Pap smear vag w ASC-US",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79512",
                "title": "79512 Pap smear vagina w ASC-H",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79513",
                "title": "79513 Pap smear vagina w LGSIL",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79514",
                "title": "79514 Pap smear vagina w HGSIL",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79515",
                "title": "79515 Vag hi risk HPV-DNA pos",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79516",
                "title": "79516 Pap smr vag-cytol malig",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79518",
                "title": "79518 Vaginl cytol smr unsatis",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79519",
                "title": "79519 Oth abn Pap smr vag/HPV",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "7952",
                "title": "7952 Abn chromosomal analysis",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79531",
                "title": "79531 Nonsp postv find-anthrax",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79539",
                "title": "79539 Nonsp positive cult NEC",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "7954",
                "title": "7954 Abn histologic find NEC",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79551",
                "title": "79551 Nonsp rea skn test wo tb",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79552",
                "title": "79552 Nonsp rea gma interferon",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "7956",
                "title": "7956 False pos sero test-syph",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79571",
                "title": "79571 Nonspcf serlgc evdnc hiv",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79579",
                "title": "79579 Oth unspcf nspf imun fnd",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79581",
                "title": "79581 Elev ca-embryoic antigen",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79582",
                "title": "79582 Elev ca antigen 125",
                "threedigit": "795",
                "isLeaf": true
              },
              {
                "key": "79589",
                "title": "79589 Abnorml tumor marker NEC",
                "threedigit": "795",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other nonspecific abnormal findings",
            "title": "796 - Other nonspecific abnormal findings",
            "threedigit": "796",
            "children": [
              {
                "key": "7960",
                "title": "7960 Abn toxicologic finding",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "7961",
                "title": "7961 Abnormal reflex",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "7962",
                "title": "7962 Elev bl pres w/o hypertn",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "7963",
                "title": "7963 Low blood press reading",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "7964",
                "title": "7964 Abn clinical finding NEC",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "7965",
                "title": "7965 Abn find antenatl screen",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "7966",
                "title": "7966 Abnorm neonate screening",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79670",
                "title": "79670 Abn gland pap smear anus",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79671",
                "title": "79671 Pap smear anus w ASC-US",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79672",
                "title": "79672 Pap smear anus w ASC-H",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79673",
                "title": "79673 Pap smear anus w LGSIL",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79674",
                "title": "79674 Pap smear anus w HGSIL",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79675",
                "title": "79675 Anal hi risk HPV-DNA pos",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79676",
                "title": "79676 Pap smr anus-cytol malig",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79677",
                "title": "79677 Sat anal smr-no trnsfrm",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79678",
                "title": "79678 Anal cytolgy smr unsatis",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "79679",
                "title": "79679 Oth abn Pap smr anus/HPV",
                "threedigit": "796",
                "isLeaf": true
              },
              {
                "key": "7969",
                "title": "7969 Abnormal findings NEC",
                "threedigit": "796",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Ill-Defined And Unknown Causes Of Morbidity And Mortality",
        "title": "797-799 Ill-Defined And Unknown Causes Of Morbidity And Mortality",
        "threedigit": "797",
        "children": [
          {
            "key": "Senility without mention of psychosis",
            "title": "797 - Senility without mention of psychosis",
            "threedigit": "797",
            "children": [
              {
                "key": "797",
                "title": "797 Senility w/o psychosis",
                "threedigit": "797",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sudden death cause unknown",
            "title": "798 - Sudden death cause unknown",
            "threedigit": "798",
            "children": [
              {
                "key": "7980",
                "title": "7980 Sudden infant death synd",
                "threedigit": "798",
                "isLeaf": true
              },
              {
                "key": "7981",
                "title": "7981 Instantaneous death",
                "threedigit": "798",
                "isLeaf": true
              },
              {
                "key": "7982",
                "title": "7982 Death within 24 hr sympt",
                "threedigit": "798",
                "isLeaf": true
              },
              {
                "key": "7989",
                "title": "7989 Unattended death",
                "threedigit": "798",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other ill-defined and unknown causes of morbidity and mortality",
            "title": "799 - Other ill-defined and unknown causes of morbidity and mortality",
            "threedigit": "799",
            "children": [
              {
                "key": "79901",
                "title": "79901 Asphyxia",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79902",
                "title": "79902 Hypoxemia",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "7991",
                "title": "7991 Respiratory arrest",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79921",
                "title": "79921 Nervousness",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79922",
                "title": "79922 Irritability",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79923",
                "title": "79923 Impulsiveness",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79924",
                "title": "79924 Emotional lability",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79925",
                "title": "79925 Demoralization & apathy",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79929",
                "title": "79929 Emotional state sym NEC",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "7993",
                "title": "7993 Debility NOS",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "7994",
                "title": "7994 Cachexia",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79951",
                "title": "79951 Attn/concentrate deficit",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79952",
                "title": "79952 Cog communicate deficit",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79953",
                "title": "79953 Visuospatial deficit",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79954",
                "title": "79954 Psychomotor deficit",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79955",
                "title": "79955 Frontal lobe deficit",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79959",
                "title": "79959 Cognition sign/sympt NEC",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79981",
                "title": "79981 Decreased libido",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79982",
                "title": "79982 Appar life threat-infant",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "79989",
                "title": "79989 Ill-define condition NEC",
                "threedigit": "799",
                "isLeaf": true
              },
              {
                "key": "7999",
                "title": "7999 Unkn cause morb/mort NEC",
                "threedigit": "799",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Injury And Poisoning",
    "title": "800-999 Injury And Poisoning",
    "threedigit": "800",
    "children": [
      {
        "key": "Fracture Of Skull",
        "title": "800-804 Fracture Of Skull",
        "threedigit": "800",
        "children": [
          {
            "key": "Fracture of vault of skull",
            "title": "800 - Fracture of vault of skull",
            "threedigit": "800",
            "children": [
              {
                "key": "80000",
                "title": "80000 Closed skull vault fx",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80001",
                "title": "80001 Cl skull vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80002",
                "title": "80002 Cl skull vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80003",
                "title": "80003 Cl skull vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80004",
                "title": "80004 Cl skl vlt fx-proln coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80005",
                "title": "80005 Cl skul vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80006",
                "title": "80006 Cl skull vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80009",
                "title": "80009 Cl skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80010",
                "title": "80010 Cl skl vlt fx/cerebr lac",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80011",
                "title": "80011 Cl skull vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80012",
                "title": "80012 Cl skull vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80013",
                "title": "80013 Cl skull vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80014",
                "title": "80014 Cl skl vlt fx-proln coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80015",
                "title": "80015 Cl skul vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80016",
                "title": "80016 Cl skull vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80019",
                "title": "80019 Cl skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80020",
                "title": "80020 Cl skl vlt fx/mening hem",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80021",
                "title": "80021 Cl skull vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80022",
                "title": "80022 Cl skull vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80023",
                "title": "80023 Cl skull vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80024",
                "title": "80024 Cl skl vlt fx-proln coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80025",
                "title": "80025 Cl skul vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80026",
                "title": "80026 Cl skull vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80029",
                "title": "80029 Cl skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80030",
                "title": "80030 Cl skull vlt fx/hem NEC",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80031",
                "title": "80031 Cl skull vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80032",
                "title": "80032 Cl skull vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80033",
                "title": "80033 Cl skull vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80034",
                "title": "80034 Cl skl vlt fx-proln coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80035",
                "title": "80035 Cl skul vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80036",
                "title": "80036 Cl skull vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80039",
                "title": "80039 Cl skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80040",
                "title": "80040 Cl skl vlt fx/br inj NEC",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80041",
                "title": "80041 Cl skull vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80042",
                "title": "80042 Cl skull vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80043",
                "title": "80043 Cl skull vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80044",
                "title": "80044 Cl skl vlt fx-proln coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80045",
                "title": "80045 Cl skul vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80046",
                "title": "80046 Cl skull vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80049",
                "title": "80049 Cl skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80050",
                "title": "80050 Opn skull vault fracture",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80051",
                "title": "80051 Opn skul vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80052",
                "title": "80052 Opn skul vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80053",
                "title": "80053 Opn skul vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80054",
                "title": "80054 Opn skl vlt fx-proln com",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80055",
                "title": "80055 Opn skl vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80056",
                "title": "80056 Opn skul vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80059",
                "title": "80059 Op skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80060",
                "title": "80060 Opn skl vlt fx/cereb lac",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80061",
                "title": "80061 Opn skul vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80062",
                "title": "80062 Opn skul vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80063",
                "title": "80063 Opn skul vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80064",
                "title": "80064 Opn skl vlt fx-proln com",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80065",
                "title": "80065 Opn skl vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80066",
                "title": "80066 Opn skul vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80069",
                "title": "80069 Op skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80070",
                "title": "80070 Opn skl vlt fx/menin hem",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80071",
                "title": "80071 Opn skul vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80072",
                "title": "80072 Opn skul vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80073",
                "title": "80073 Opn skul vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80074",
                "title": "80074 Opn skl vlt fx-proln com",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80075",
                "title": "80075 Opn skl vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80076",
                "title": "80076 Opn skul vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80079",
                "title": "80079 Op skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80080",
                "title": "80080 Opn skull vlt fx/hem NEC",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80081",
                "title": "80081 Opn skul vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80082",
                "title": "80082 Opn skul vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80083",
                "title": "80083 Opn skul vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80084",
                "title": "80084 Opn skl vlt fx-proln com",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80085",
                "title": "80085 Opn skl vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80086",
                "title": "80086 Opn skul vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80089",
                "title": "80089 Op skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80090",
                "title": "80090 Op skl vlt fx/br inj NEC",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80091",
                "title": "80091 Opn skul vlt fx w/o coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80092",
                "title": "80092 Opn skul vlt fx-brf coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80093",
                "title": "80093 Opn skul vlt fx-mod coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80094",
                "title": "80094 Opn skl vlt fx-proln com",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80095",
                "title": "80095 Op skul vlt fx-deep coma",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80096",
                "title": "80096 Opn skul vlt fx-coma NOS",
                "threedigit": "800",
                "isLeaf": true
              },
              {
                "key": "80099",
                "title": "80099 Op skl vlt fx-concus NOS",
                "threedigit": "800",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of base of skull",
            "title": "801 - Fracture of base of skull",
            "threedigit": "801",
            "children": [
              {
                "key": "80100",
                "title": "80100 Clos skull base fracture",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80101",
                "title": "80101 Cl skul base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80102",
                "title": "80102 Cl skul base fx-brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80103",
                "title": "80103 Cl skul base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80104",
                "title": "80104 Cl skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80105",
                "title": "80105 Cl skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80106",
                "title": "80106 Cl skul base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80109",
                "title": "80109 Cl skull base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80110",
                "title": "80110 Cl skl base fx/cereb lac",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80111",
                "title": "80111 Cl skul base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80112",
                "title": "80112 Cl skul base fx-brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80113",
                "title": "80113 Cl skul base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80114",
                "title": "80114 Cl skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80115",
                "title": "80115 Cl skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80116",
                "title": "80116 Cl skul base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80119",
                "title": "80119 Cl skull base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80120",
                "title": "80120 Cl skl base fx/menin hem",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80121",
                "title": "80121 Cl skul base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80122",
                "title": "80122 Cl skul base fx/brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80123",
                "title": "80123 Cl skul base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80124",
                "title": "80124 Cl skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80125",
                "title": "80125 Cl skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80126",
                "title": "80126 Cl skul base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80129",
                "title": "80129 Cl skull base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80130",
                "title": "80130 Cl skull base fx/hem NEC",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80131",
                "title": "80131 Cl skul base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80132",
                "title": "80132 Cl skul base fx-brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80133",
                "title": "80133 Cl skul base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80134",
                "title": "80134 Cl skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80135",
                "title": "80135 Cl skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80136",
                "title": "80136 Cl skul base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80139",
                "title": "80139 Cl skull base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80140",
                "title": "80140 Cl sk base fx/br inj NEC",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80141",
                "title": "80141 Cl skul base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80142",
                "title": "80142 Cl skul base fx-brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80143",
                "title": "80143 Cl skul base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80144",
                "title": "80144 Cl skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80145",
                "title": "80145 Cl skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80146",
                "title": "80146 Cl skul base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80149",
                "title": "80149 Cl skull base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80150",
                "title": "80150 Open skull base fracture",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80151",
                "title": "80151 Opn skl base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80152",
                "title": "80152 Opn skl base fx-brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80153",
                "title": "80153 Opn skl base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80154",
                "title": "80154 Op skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80155",
                "title": "80155 Op skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80156",
                "title": "80156 Opn skl base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80159",
                "title": "80159 Opn skul base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80160",
                "title": "80160 Op skl base fx/cereb lac",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80161",
                "title": "80161 Opn skl base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80162",
                "title": "80162 Opn skl base fx-brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80163",
                "title": "80163 Opn skl base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80164",
                "title": "80164 Op skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80165",
                "title": "80165 Op skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80166",
                "title": "80166 Opn skl base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80169",
                "title": "80169 Opn skul base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80170",
                "title": "80170 Op skl base fx/menin hem",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80171",
                "title": "80171 Opn skl base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80172",
                "title": "80172 Opn skl base fx-brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80173",
                "title": "80173 Opn skl base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80174",
                "title": "80174 Op skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80175",
                "title": "80175 Op skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80176",
                "title": "80176 Opn skl base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80179",
                "title": "80179 Opn skul base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80180",
                "title": "80180 Opn skul base fx/hem NEC",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80181",
                "title": "80181 Opn skl base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80182",
                "title": "80182 Opn skl base fx-brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80183",
                "title": "80183 Opn skl base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80184",
                "title": "80184 Op skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80185",
                "title": "80185 Op skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80186",
                "title": "80186 Opn skl base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80189",
                "title": "80189 Opn skul base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80190",
                "title": "80190 Op sk base fx/br inj NEC",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80191",
                "title": "80191 Op skul base fx w/o coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80192",
                "title": "80192 Opn skl base fx-brf coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80193",
                "title": "80193 Opn skl base fx-mod coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80194",
                "title": "80194 Op skl base fx-prol coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80195",
                "title": "80195 Op skl base fx-deep coma",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80196",
                "title": "80196 Opn skl base fx-coma NOS",
                "threedigit": "801",
                "isLeaf": true
              },
              {
                "key": "80199",
                "title": "80199 Opn skul base fx-concuss",
                "threedigit": "801",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of face bones",
            "title": "802 - Fracture of face bones",
            "threedigit": "802",
            "children": [
              {
                "key": "8020",
                "title": "8020 Nasal bone fx-closed",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "8021",
                "title": "8021 Nasal bone fx-open",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80220",
                "title": "80220 Mandible fx NOS-closed",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80221",
                "title": "80221 Fx condyl proc mandib-cl",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80222",
                "title": "80222 Subcondylar fx mandib-cl",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80223",
                "title": "80223 Fx coron proc mandib-cl",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80224",
                "title": "80224 Fx ramus NOS-closed",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80225",
                "title": "80225 Fx angle of jaw-closed",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80226",
                "title": "80226 Fx symphy mandib body-cl",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80227",
                "title": "80227 Fx alveolar bord mand-cl",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80228",
                "title": "80228 Fx mandible body NEC-cl",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80229",
                "title": "80229 Mult fx mandible-closed",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80230",
                "title": "80230 Mandible fx NOS-open",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80231",
                "title": "80231 Fx condyl proc mand-open",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80232",
                "title": "80232 Subcondyl fx mandib-open",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80233",
                "title": "80233 Fx coron proc mandib-opn",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80234",
                "title": "80234 Fx ramus NOS-open",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80235",
                "title": "80235 Fx angle of jaw-open",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80236",
                "title": "80236 Fx symphy mandib bdy-opn",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80237",
                "title": "80237 Fx alv bord mand bdy-opn",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80238",
                "title": "80238 Fx mandible body NEC-opn",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "80239",
                "title": "80239 Mult fx mandible-open",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "8024",
                "title": "8024 Fx malar/maxillary-close",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "8025",
                "title": "8025 Fx malar/maxillary-open",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "8026",
                "title": "8026 Fx orbital floor-closed",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "8027",
                "title": "8027 Fx orbital floor-open",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "8028",
                "title": "8028 Fx facial bone NEC-close",
                "threedigit": "802",
                "isLeaf": true
              },
              {
                "key": "8029",
                "title": "8029 Fx facial bone NEC-open",
                "threedigit": "802",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unqualified skull fractures",
            "title": "803 - Other and unqualified skull fractures",
            "threedigit": "803",
            "children": [
              {
                "key": "80300",
                "title": "80300 Close skull fracture NEC",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80301",
                "title": "80301 Cl skull fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80302",
                "title": "80302 Cl skull fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80303",
                "title": "80303 Cl skull fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80304",
                "title": "80304 Cl skl fx NEC-proln coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80305",
                "title": "80305 Cl skul fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80306",
                "title": "80306 Cl skull fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80309",
                "title": "80309 Cl skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80310",
                "title": "80310 Cl skl fx NEC/cerebr lac",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80311",
                "title": "80311 Cl skull fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80312",
                "title": "80312 Cl skull fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80313",
                "title": "80313 Cl skull fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80314",
                "title": "80314 Cl skl fx NEC-proln coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80315",
                "title": "80315 Cl skul fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80316",
                "title": "80316 Cl skull fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80319",
                "title": "80319 Cl skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80320",
                "title": "80320 Cl skl fx NEC/mening hem",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80321",
                "title": "80321 Cl skull fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80322",
                "title": "80322 Cl skull fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80323",
                "title": "80323 Cl skull fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80324",
                "title": "80324 Cl skl fx NEC-proln coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80325",
                "title": "80325 Cl skul fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80326",
                "title": "80326 Cl skull fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80329",
                "title": "80329 Cl skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80330",
                "title": "80330 Cl skull fx NEC/hem NEC",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80331",
                "title": "80331 Cl skull fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80332",
                "title": "80332 Cl skull fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80333",
                "title": "80333 Cl skull fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80334",
                "title": "80334 Cl skl fx NEC-proln coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80335",
                "title": "80335 Cl skul fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80336",
                "title": "80336 Cl skull fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80339",
                "title": "80339 Cl skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80340",
                "title": "80340 Cl skl fx NEC/br inj NEC",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80341",
                "title": "80341 Cl skull fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80342",
                "title": "80342 Cl skull fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80343",
                "title": "80343 Cl skull fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80344",
                "title": "80344 Cl skl fx NEC-proln coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80345",
                "title": "80345 Cl skul fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80346",
                "title": "80346 Cl skull fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80349",
                "title": "80349 Cl skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80350",
                "title": "80350 Open skull fracture NEC",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80351",
                "title": "80351 Opn skul fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80352",
                "title": "80352 Opn skul fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80353",
                "title": "80353 Opn skul fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80354",
                "title": "80354 Opn skl fx NEC-prol coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80355",
                "title": "80355 Opn skl fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80356",
                "title": "80356 Opn skul fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80359",
                "title": "80359 Opn skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80360",
                "title": "80360 Opn skl fx NEC/cereb lac",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80361",
                "title": "80361 Opn skul fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80362",
                "title": "80362 Opn skul fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80363",
                "title": "80363 Opn skul fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80364",
                "title": "80364 Opn skl fx NEC-proln com",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80365",
                "title": "80365 Opn skl fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80366",
                "title": "80366 Opn skul fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80369",
                "title": "80369 Opn skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80370",
                "title": "80370 Opn skl fx NEC/menin hem",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80371",
                "title": "80371 Opn skul fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80372",
                "title": "80372 Opn skul fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80373",
                "title": "80373 Opn skul fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80374",
                "title": "80374 Opn skl fx NEC-prol coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80375",
                "title": "80375 Opn skl fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80376",
                "title": "80376 Opn skul fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80379",
                "title": "80379 Opn skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80380",
                "title": "80380 Opn skull fx NEC/hem NEC",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80381",
                "title": "80381 Opn skul fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80382",
                "title": "80382 Opn skul fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80383",
                "title": "80383 Opn skul fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80384",
                "title": "80384 Opn skl fx NEC-prol coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80385",
                "title": "80385 Opn skl fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80386",
                "title": "80386 Opn skul fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80389",
                "title": "80389 Opn skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80390",
                "title": "80390 Op skl fx NEC/br inj NEC",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80391",
                "title": "80391 Opn skul fx NEC w/o coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80392",
                "title": "80392 Opn skul fx NEC-brf coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80393",
                "title": "80393 Opn skul fx NEC-mod coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80394",
                "title": "80394 Opn skl fx NEC-prol coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80395",
                "title": "80395 Opn skl fx NEC-deep coma",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80396",
                "title": "80396 Opn skul fx NEC-coma NOS",
                "threedigit": "803",
                "isLeaf": true
              },
              {
                "key": "80399",
                "title": "80399 Opn skull fx NEC-concuss",
                "threedigit": "803",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Multiple fractures involving skull or face with other bones",
            "title": "804 - Multiple fractures involving skull or face with other bones",
            "threedigit": "804",
            "children": [
              {
                "key": "80400",
                "title": "80400 Cl skul fx w oth bone fx",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80401",
                "title": "80401 Cl skl w oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80402",
                "title": "80402 Cl skl w oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80403",
                "title": "80403 Cl skl w oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80404",
                "title": "80404 Cl skl/oth fx-proln coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80405",
                "title": "80405 Cl skul/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80406",
                "title": "80406 Cl skl w oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80409",
                "title": "80409 Cl skul w oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80410",
                "title": "80410 Cl sk w oth fx/cereb lac",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80411",
                "title": "80411 Cl skl w oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80412",
                "title": "80412 Cl skl w oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80413",
                "title": "80413 Cl skl w oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80414",
                "title": "80414 Cl skl/oth fx-proln coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80415",
                "title": "80415 Cl skul/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80416",
                "title": "80416 Cl skl w oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80419",
                "title": "80419 Cl skul w oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80420",
                "title": "80420 Cl skl/oth fx/mening hem",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80421",
                "title": "80421 Cl skl w oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80422",
                "title": "80422 Cl skl w oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80423",
                "title": "80423 Cl skl w oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80424",
                "title": "80424 Cl skl/oth fx-proln coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80425",
                "title": "80425 Cl skul/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80426",
                "title": "80426 Cl skl w oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80429",
                "title": "80429 Cl skul w oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80430",
                "title": "80430 Cl skul w oth fx/hem NEC",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80431",
                "title": "80431 Cl skl w oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80432",
                "title": "80432 Cl skl w oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80433",
                "title": "80433 Cl skl w oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80434",
                "title": "80434 Cl skl/oth fx-proln coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80435",
                "title": "80435 Cl skul/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80436",
                "title": "80436 Cl skl w oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80439",
                "title": "80439 Cl skul w oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80440",
                "title": "80440 Cl skl/oth fx/br inj NEC",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80441",
                "title": "80441 Cl skl w oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80442",
                "title": "80442 Cl skl w oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80443",
                "title": "80443 Cl skl w oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80444",
                "title": "80444 Cl skl/oth fx-proln coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80445",
                "title": "80445 Cl skul/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80446",
                "title": "80446 Cl skl w oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80449",
                "title": "80449 Cl skul w oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80450",
                "title": "80450 Opn skull fx/oth bone fx",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80451",
                "title": "80451 Opn skul/oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80452",
                "title": "80452 Opn skul/oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80453",
                "title": "80453 Opn skul/oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80454",
                "title": "80454 Opn skl/oth fx-prol coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80455",
                "title": "80455 Opn skl/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80456",
                "title": "80456 Opn skul/oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80459",
                "title": "80459 Opn skull/oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80460",
                "title": "80460 Opn skl/oth fx/cereb lac",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80461",
                "title": "80461 Opn skul/oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80462",
                "title": "80462 Opn skul/oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80463",
                "title": "80463 Opn skul/oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80464",
                "title": "80464 Opn skl/oth fx-prol coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80465",
                "title": "80465 Opn skl/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80466",
                "title": "80466 Opn skul/oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80469",
                "title": "80469 Opn skull/oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80470",
                "title": "80470 Opn skl/oth fx/menin hem",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80471",
                "title": "80471 Opn skul/oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80472",
                "title": "80472 Opn skul/oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80473",
                "title": "80473 Opn skul/oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80474",
                "title": "80474 Opn skl/oth fx-prol coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80475",
                "title": "80475 Opn skl/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80476",
                "title": "80476 Opn skul/oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80479",
                "title": "80479 Opn skull/oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80480",
                "title": "80480 Opn skl w oth fx/hem NEC",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80481",
                "title": "80481 Opn skul/oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80482",
                "title": "80482 Opn skul/oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80483",
                "title": "80483 Opn skul/oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80484",
                "title": "80484 Opn skl/oth fx-prol coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80485",
                "title": "80485 Opn skl/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80486",
                "title": "80486 Opn skul/oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80489",
                "title": "80489 Opn skull/oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80490",
                "title": "80490 Op skl/oth fx/br inj NEC",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80491",
                "title": "80491 Opn skul/oth fx w/o coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80492",
                "title": "80492 Opn skul/oth fx-brf coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80493",
                "title": "80493 Opn skul/oth fx-mod coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80494",
                "title": "80494 Opn skl/oth fx-prol coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80495",
                "title": "80495 Opn skl/oth fx-deep coma",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80496",
                "title": "80496 Opn skul/oth fx-coma NOS",
                "threedigit": "804",
                "isLeaf": true
              },
              {
                "key": "80499",
                "title": "80499 Opn skull/oth fx-concuss",
                "threedigit": "804",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Fracture Of Spine And Trunk",
        "title": "805-809 Fracture Of Spine And Trunk",
        "threedigit": "805",
        "children": [
          {
            "key": "Fracture of vertebral column without mention of spinal cord injury",
            "title": "805 - Fracture of vertebral column without mention of spinal cord injury",
            "threedigit": "805",
            "children": [
              {
                "key": "80500",
                "title": "80500 Fx cervical vert NOS-cl",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80501",
                "title": "80501 Fx c1 vertebra-closed",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80502",
                "title": "80502 Fx c2 vertebra-closed",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80503",
                "title": "80503 Fx c3 vertebra-closed",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80504",
                "title": "80504 Fx c4 vertebra-closed",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80505",
                "title": "80505 Fx c5 vertebra-closed",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80506",
                "title": "80506 Fx c6 vertebra-closed",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80507",
                "title": "80507 Fx c7 vertebra-closed",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80508",
                "title": "80508 Fx mult cervical vert-cl",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80510",
                "title": "80510 Fx cervical vert NOS-opn",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80511",
                "title": "80511 Fx c1 vertebra-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80512",
                "title": "80512 Fx c2 vertebra-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80513",
                "title": "80513 Fx c3 vertebra-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80514",
                "title": "80514 Fx c4 vertebra-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80515",
                "title": "80515 Fx c5 vertebra-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80516",
                "title": "80516 Fx c6 vertebra-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80517",
                "title": "80517 Fx c7 vertebra-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "80518",
                "title": "80518 Fx mlt cervical vert-opn",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "8052",
                "title": "8052 Fx dorsal vertebra-close",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "8053",
                "title": "8053 Fx dorsal vertebra-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "8054",
                "title": "8054 Fx lumbar vertebra-close",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "8055",
                "title": "8055 Fx lumbar vertebra-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "8056",
                "title": "8056 Fx sacrum/coccyx-closed",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "8057",
                "title": "8057 Fx sacrum/coccyx-open",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "8058",
                "title": "8058 Vertebral fx NOS-closed",
                "threedigit": "805",
                "isLeaf": true
              },
              {
                "key": "8059",
                "title": "8059 Vertebral fx NOS-open",
                "threedigit": "805",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of vertebral column with spinal cord injury",
            "title": "806 - Fracture of vertebral column with spinal cord injury",
            "threedigit": "806",
            "children": [
              {
                "key": "80600",
                "title": "80600 C1-c4 fx-cl/cord inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80601",
                "title": "80601 C1-c4 fx-cl/com cord les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80602",
                "title": "80602 C1-c4 fx-cl/ant cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80603",
                "title": "80603 C1-c4 fx-cl/cen cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80604",
                "title": "80604 C1-c4 fx-cl/cord inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80605",
                "title": "80605 C5-c7 fx-cl/cord inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80606",
                "title": "80606 C5-c7 fx-cl/com cord les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80607",
                "title": "80607 C5-c7 fx-cl/ant cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80608",
                "title": "80608 C5-c7 fx-cl/cen cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80609",
                "title": "80609 C5-c7 fx-cl/cord inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80610",
                "title": "80610 C1-c4 fx-op/cord inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80611",
                "title": "80611 C1-c4 fx-op/com cord les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80612",
                "title": "80612 C1-c4 fx-op/ant cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80613",
                "title": "80613 C1-c4 fx-op/cen cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80614",
                "title": "80614 C1-c4 fx-op/cord inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80615",
                "title": "80615 C5-c7 fx-op/cord inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80616",
                "title": "80616 C5-c7 fx-op/com cord les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80617",
                "title": "80617 C5-c7 fx-op/ant cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80618",
                "title": "80618 C5-c7 fx-op/cen cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80619",
                "title": "80619 C5-c7 fx-op/cord inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80620",
                "title": "80620 T1-t6 fx-cl/cord inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80621",
                "title": "80621 T1-t6 fx-cl/com cord les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80622",
                "title": "80622 T1-t6 fx-cl/ant cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80623",
                "title": "80623 T1-t6 fx-cl/cen cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80624",
                "title": "80624 T1-t6 fx-cl/cord inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80625",
                "title": "80625 T7-t12 fx-cl/crd inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80626",
                "title": "80626 T7-t12 fx-cl/com crd les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80627",
                "title": "80627 T7-t12 fx-cl/ant crd syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80628",
                "title": "80628 T7-t12 fx-cl/cen crd syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80629",
                "title": "80629 T7-t12 fx-cl/crd inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80630",
                "title": "80630 T1-t6 fx-op/cord inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80631",
                "title": "80631 T1-t6 fx-op/com cord les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80632",
                "title": "80632 T1-t6 fx-op/ant cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80633",
                "title": "80633 T1-t6 fx-op/cen cord syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80634",
                "title": "80634 T1-t6 fx-op/cord inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80635",
                "title": "80635 T7-t12 fx-op/crd inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80636",
                "title": "80636 T7-t12 fx-op/com crd les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80637",
                "title": "80637 T7-t12 fx-op/ant crd syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80638",
                "title": "80638 T7-t12 fx-op/cen crd syn",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80639",
                "title": "80639 T7-t12 fx-op/crd inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "8064",
                "title": "8064 Cl lumbar fx w cord inj",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "8065",
                "title": "8065 Opn lumbar fx w cord inj",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80660",
                "title": "80660 Fx sacrum-cl/crd inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80661",
                "title": "80661 Fx sacr-cl/cauda equ les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80662",
                "title": "80662 Fx sacr-cl/cauda inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80669",
                "title": "80669 Fx sacrum-cl/crd inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80670",
                "title": "80670 Fx sacrum-op/crd inj NOS",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80671",
                "title": "80671 Fx sacr-op/cauda equ les",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80672",
                "title": "80672 Fx sacr-op/cauda inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "80679",
                "title": "80679 Fx sacrum-op/crd inj NEC",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "8068",
                "title": "8068 Vert fx NOS-cl w crd inj",
                "threedigit": "806",
                "isLeaf": true
              },
              {
                "key": "8069",
                "title": "8069 Vert fx NOS-op w crd inj",
                "threedigit": "806",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of rib(s) sternum larynx and trachea",
            "title": "807 - Fracture of rib(s) sternum larynx and trachea",
            "threedigit": "807",
            "children": [
              {
                "key": "80700",
                "title": "80700 Fracture rib NOS-closed",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80701",
                "title": "80701 Fracture one rib-closed",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80702",
                "title": "80702 Fracture two ribs-closed",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80703",
                "title": "80703 Fracture three ribs-clos",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80704",
                "title": "80704 Fracture four ribs-close",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80705",
                "title": "80705 Fracture five ribs-close",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80706",
                "title": "80706 Fracture six ribs-closed",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80707",
                "title": "80707 Fracture seven ribs-clos",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80708",
                "title": "80708 Fx eight/more rib-closed",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80709",
                "title": "80709 Fx mult ribs NOS-closed",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80710",
                "title": "80710 Fracture rib NOS-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80711",
                "title": "80711 Fracture one rib-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80712",
                "title": "80712 Fracture two ribs-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80713",
                "title": "80713 Fracture three ribs-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80714",
                "title": "80714 Fracture four ribs-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80715",
                "title": "80715 Fracture five ribs-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80716",
                "title": "80716 Fracture six ribs-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80717",
                "title": "80717 Fracture seven ribs-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80718",
                "title": "80718 Fx eight/more ribs-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "80719",
                "title": "80719 Fx mult ribs NOS-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "8072",
                "title": "8072 Fracture of sternum-clos",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "8073",
                "title": "8073 Fracture of sternum-open",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "8074",
                "title": "8074 Flail chest",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "8075",
                "title": "8075 Fx larynx/trachea-closed",
                "threedigit": "807",
                "isLeaf": true
              },
              {
                "key": "8076",
                "title": "8076 Fx larynx/trachea-open",
                "threedigit": "807",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of pelvis",
            "title": "808 - Fracture of pelvis",
            "threedigit": "808",
            "children": [
              {
                "key": "8080",
                "title": "8080 Fracture acetabulum-clos",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "8081",
                "title": "8081 Fracture acetabulum-open",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "8082",
                "title": "8082 Fracture of pubis-closed",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "8083",
                "title": "8083 Fracture of pubis-open",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80841",
                "title": "80841 Fracture of ilium-closed",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80842",
                "title": "80842 Fracture ischium-closed",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80843",
                "title": "80843 Pelv fx-clos/pelv disrup",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80844",
                "title": "80844 Pelv fx-cl w/o plv disrp",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80849",
                "title": "80849 Pelvic fracture NEC-clos",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80851",
                "title": "80851 Fracture of ilium-open",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80852",
                "title": "80852 Fracture of ischium-open",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80853",
                "title": "80853 Pelv fx-open/pelv disrup",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80854",
                "title": "80854 Pelv fx-opn w/o pelv dis",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "80859",
                "title": "80859 Pelvic fracture NEC-open",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "8088",
                "title": "8088 Pelvic fracture NOS-clos",
                "threedigit": "808",
                "isLeaf": true
              },
              {
                "key": "8089",
                "title": "8089 Pelvic fracture NOS-open",
                "threedigit": "808",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ill-defined fractures of bones of trunk",
            "title": "809 - Ill-defined fractures of bones of trunk",
            "threedigit": "809",
            "children": [
              {
                "key": "8090",
                "title": "8090 Fracture trunk bone-clos",
                "threedigit": "809",
                "isLeaf": true
              },
              {
                "key": "8091",
                "title": "8091 Fracture trunk bone-open",
                "threedigit": "809",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Fracture Of Upper Limb",
        "title": "810-819 Fracture Of Upper Limb",
        "threedigit": "810",
        "children": [
          {
            "key": "Fracture of clavicle",
            "title": "810 - Fracture of clavicle",
            "threedigit": "810",
            "children": [
              {
                "key": "81000",
                "title": "81000 Fx clavicle NOS-closed",
                "threedigit": "810",
                "isLeaf": true
              },
              {
                "key": "81001",
                "title": "81001 Fx clavicl, stern end-cl",
                "threedigit": "810",
                "isLeaf": true
              },
              {
                "key": "81002",
                "title": "81002 Fx clavicle shaft-closed",
                "threedigit": "810",
                "isLeaf": true
              },
              {
                "key": "81003",
                "title": "81003 Fx clavicl, acrom end-cl",
                "threedigit": "810",
                "isLeaf": true
              },
              {
                "key": "81010",
                "title": "81010 Fx clavicle NOS-open",
                "threedigit": "810",
                "isLeaf": true
              },
              {
                "key": "81011",
                "title": "81011 Fx clavic, stern end-opn",
                "threedigit": "810",
                "isLeaf": true
              },
              {
                "key": "81012",
                "title": "81012 Fx clavicle shaft-open",
                "threedigit": "810",
                "isLeaf": true
              },
              {
                "key": "81013",
                "title": "81013 Fx clavic, acrom end-opn",
                "threedigit": "810",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of scapula",
            "title": "811 - Fracture of scapula",
            "threedigit": "811",
            "children": [
              {
                "key": "81100",
                "title": "81100 Fx scapula NOS-closed",
                "threedigit": "811",
                "isLeaf": true
              },
              {
                "key": "81101",
                "title": "81101 Fx scapul, acrom proc-cl",
                "threedigit": "811",
                "isLeaf": true
              },
              {
                "key": "81102",
                "title": "81102 Fx scapul, corac proc-cl",
                "threedigit": "811",
                "isLeaf": true
              },
              {
                "key": "81103",
                "title": "81103 Fx scap, glen cav/nck-cl",
                "threedigit": "811",
                "isLeaf": true
              },
              {
                "key": "81109",
                "title": "81109 Fx scapula NEC-closed",
                "threedigit": "811",
                "isLeaf": true
              },
              {
                "key": "81110",
                "title": "81110 Fx scapula NOS-open",
                "threedigit": "811",
                "isLeaf": true
              },
              {
                "key": "81111",
                "title": "81111 Fx scapul, acrom proc-op",
                "threedigit": "811",
                "isLeaf": true
              },
              {
                "key": "81112",
                "title": "81112 Fx scapul, corac proc-op",
                "threedigit": "811",
                "isLeaf": true
              },
              {
                "key": "81113",
                "title": "81113 Fx scap, glen cav/nck-op",
                "threedigit": "811",
                "isLeaf": true
              },
              {
                "key": "81119",
                "title": "81119 Fx scapula NEC-open",
                "threedigit": "811",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of humerus",
            "title": "812 - Fracture of humerus",
            "threedigit": "812",
            "children": [
              {
                "key": "81200",
                "title": "81200 Fx up end humerus NOS-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81201",
                "title": "81201 Fx surg nck humerus-clos",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81202",
                "title": "81202 Fx anatom nck humerus-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81203",
                "title": "81203 Fx gr tuberos humerus-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81209",
                "title": "81209 Fx upper humerus NEC-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81210",
                "title": "81210 Fx upper humerus NOS-opn",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81211",
                "title": "81211 Fx surg neck humerus-opn",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81212",
                "title": "81212 Fx anat neck humerus-opn",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81213",
                "title": "81213 Fx gr tuberos humer-open",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81219",
                "title": "81219 Fx upper humerus NEC-opn",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81220",
                "title": "81220 Fx humerus NOS-closed",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81221",
                "title": "81221 Fx humerus shaft-closed",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81230",
                "title": "81230 Fx humerus NOS-open",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81231",
                "title": "81231 Fx humerus shaft-open",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81240",
                "title": "81240 Fx lower humerus NOS-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81241",
                "title": "81241 Suprcondyl fx humerus-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81242",
                "title": "81242 Fx humer, lat condyl-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81243",
                "title": "81243 Fx humer, med condyl-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81244",
                "title": "81244 Fx humer, condyl NOS-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81249",
                "title": "81249 Fx lower humerus NEC-cl",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81250",
                "title": "81250 Fx lower humer NOS-open",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81251",
                "title": "81251 Supracondyl fx humer-opn",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81252",
                "title": "81252 Fx humer, lat condyl-opn",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81253",
                "title": "81253 Fx humer, med condyl-opn",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81254",
                "title": "81254 Fx humer, condyl NOS-opn",
                "threedigit": "812",
                "isLeaf": true
              },
              {
                "key": "81259",
                "title": "81259 Fx lower humer NEC-open",
                "threedigit": "812",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of radius and ulna",
            "title": "813 - Fracture of radius and ulna",
            "threedigit": "813",
            "children": [
              {
                "key": "81300",
                "title": "81300 Fx upper forearm NOS-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81301",
                "title": "81301 Fx olecran proc ulna-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81302",
                "title": "81302 Fx coronoid proc ulna-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81303",
                "title": "81303 Monteggia's fx-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81304",
                "title": "81304 Fx upper ulna NEC/NOS-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81305",
                "title": "81305 Fx radius head-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81306",
                "title": "81306 Fx radius neck-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81307",
                "title": "81307 Fx up radius NEC/NOS-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81308",
                "title": "81308 Fx up radius w ulna-clos",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81310",
                "title": "81310 Fx upper forearm NOS-opn",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81311",
                "title": "81311 Fx olecran proc ulna-opn",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81312",
                "title": "81312 Fx coronoid pro ulna-opn",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81313",
                "title": "81313 Monteggia's fx-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81314",
                "title": "81314 Fx up ulna NEC/NOS-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81315",
                "title": "81315 Fx radius head-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81316",
                "title": "81316 Fx radius neck-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81317",
                "title": "81317 Fx up radius NEC/NOS-opn",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81318",
                "title": "81318 Fx up radius w ulna-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81320",
                "title": "81320 Fx shaft forearm NOS-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81321",
                "title": "81321 Fx radius shaft-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81322",
                "title": "81322 Fx ulna shaft-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81323",
                "title": "81323 Fx shaft rad w ulna-clos",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81330",
                "title": "81330 Fx shaft forearm NOS-opn",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81331",
                "title": "81331 Fx radius shaft-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81332",
                "title": "81332 Fx ulna shaft-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81333",
                "title": "81333 Fx shaft rad w ulna-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81340",
                "title": "81340 Fx lower forearm NOS-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81341",
                "title": "81341 Colles' fracture-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81342",
                "title": "81342 Fx distal radius NEC-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81343",
                "title": "81343 Fx distal ulna-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81344",
                "title": "81344 Fx low radius w ulna-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81345",
                "title": "81345 Torus fx radius-cl/alone",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81346",
                "title": "81346 Torus fx ulna-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81347",
                "title": "81347 Torus fx radius/ulna-clo",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81350",
                "title": "81350 Fx lower forearm NOS-opn",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81351",
                "title": "81351 Colles' fracture-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81352",
                "title": "81352 Fx distal radius NEC-opn",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81353",
                "title": "81353 Fx distal ulna-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81354",
                "title": "81354 Fx low radius w ulna-opn",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81380",
                "title": "81380 Fx forearm NOS-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81381",
                "title": "81381 Fx radius NOS-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81382",
                "title": "81382 Fracture ulna NOS-closed",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81383",
                "title": "81383 Fx radius w ulna NOS-cl",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81390",
                "title": "81390 Fx forearm NOS-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81391",
                "title": "81391 Fracture radius NOS-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81392",
                "title": "81392 Fracture ulna NOS-open",
                "threedigit": "813",
                "isLeaf": true
              },
              {
                "key": "81393",
                "title": "81393 Fx radius w ulna NOS-opn",
                "threedigit": "813",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of carpal bone(s)",
            "title": "814 - Fracture of carpal bone(s)",
            "threedigit": "814",
            "children": [
              {
                "key": "81400",
                "title": "81400 Fx carpal bone NOS-close",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81401",
                "title": "81401 Fx navicular, wrist-clos",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81402",
                "title": "81402 Fx lunate, wrist-closed",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81403",
                "title": "81403 Fx triquetral, wrist-cl",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81404",
                "title": "81404 Fx pisiform-closed",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81405",
                "title": "81405 Fx trapezium bone-closed",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81406",
                "title": "81406 Fx trapezoid bone-closed",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81407",
                "title": "81407 Fx capitate bone-closed",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81408",
                "title": "81408 Fx hamate bone-closed",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81409",
                "title": "81409 Fx carpal bone NEC-close",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81410",
                "title": "81410 Fx carpal bone NOS-open",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81411",
                "title": "81411 Fx navicular, wrist-open",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81412",
                "title": "81412 Fx lunate, wrist-open",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81413",
                "title": "81413 Fx triquetral, wrist-opn",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81414",
                "title": "81414 Fx pisiform-open",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81415",
                "title": "81415 Fx trapezium bone-open",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81416",
                "title": "81416 Fx trapezoid bone-open",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81417",
                "title": "81417 Fx capitate bone-open",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81418",
                "title": "81418 Fx hamate bone-open",
                "threedigit": "814",
                "isLeaf": true
              },
              {
                "key": "81419",
                "title": "81419 Fx carpal bone NEC-open",
                "threedigit": "814",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of metacarpal bone(s)",
            "title": "815 - Fracture of metacarpal bone(s)",
            "threedigit": "815",
            "children": [
              {
                "key": "81500",
                "title": "81500 Fx metacarpal NOS-closed",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81501",
                "title": "81501 Fx 1st metacarp base-cl",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81502",
                "title": "81502 Fx metacarp base NEC-cl",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81503",
                "title": "81503 Fx metacarpal shaft-clos",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81504",
                "title": "81504 Fx metacarpal neck-close",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81509",
                "title": "81509 Mult fx metacarpus-close",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81510",
                "title": "81510 Fx metacarpal NOS-open",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81511",
                "title": "81511 Fx 1st metacarp base-opn",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81512",
                "title": "81512 Fx metacarp base NEC-opn",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81513",
                "title": "81513 Fx metacarpal shaft-open",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81514",
                "title": "81514 Fx metacarpal neck-open",
                "threedigit": "815",
                "isLeaf": true
              },
              {
                "key": "81519",
                "title": "81519 Mult fx metacarpus-open",
                "threedigit": "815",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of one or more phalanges of hand",
            "title": "816 - Fracture of one or more phalanges of hand",
            "threedigit": "816",
            "children": [
              {
                "key": "81600",
                "title": "81600 Fx phalanx, hand NOS-cl",
                "threedigit": "816",
                "isLeaf": true
              },
              {
                "key": "81601",
                "title": "81601 Fx mid/prx phal, hand-cl",
                "threedigit": "816",
                "isLeaf": true
              },
              {
                "key": "81602",
                "title": "81602 Fx dist phalanx, hand-cl",
                "threedigit": "816",
                "isLeaf": true
              },
              {
                "key": "81603",
                "title": "81603 Fx mult phalan, hand-cl",
                "threedigit": "816",
                "isLeaf": true
              },
              {
                "key": "81610",
                "title": "81610 Fx phalanx, hand NOS-opn",
                "threedigit": "816",
                "isLeaf": true
              },
              {
                "key": "81611",
                "title": "81611 Fx mid/prx phal, hand-op",
                "threedigit": "816",
                "isLeaf": true
              },
              {
                "key": "81612",
                "title": "81612 Fx distal phal, hand-opn",
                "threedigit": "816",
                "isLeaf": true
              },
              {
                "key": "81613",
                "title": "81613 Fx mult phalan, hand-opn",
                "threedigit": "816",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Multiple fractures of hand bones",
            "title": "817 - Multiple fractures of hand bones",
            "threedigit": "817",
            "children": [
              {
                "key": "8170",
                "title": "8170 Multiple fx hand-closed",
                "threedigit": "817",
                "isLeaf": true
              },
              {
                "key": "8171",
                "title": "8171 Multiple fx hand-open",
                "threedigit": "817",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ill-defined fractures of upper limb",
            "title": "818 - Ill-defined fractures of upper limb",
            "threedigit": "818",
            "children": [
              {
                "key": "8180",
                "title": "8180 Fx arm mult/NOS-closed",
                "threedigit": "818",
                "isLeaf": true
              },
              {
                "key": "8181",
                "title": "8181 Fx arm mult/NOS-open",
                "threedigit": "818",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Multiple fractures involving both upper limbs and upper limb with rib(s) and sternum",
            "title": "819 - Multiple fractures involving both upper limbs and upper limb with rib(s) and sternum",
            "threedigit": "819",
            "children": [
              {
                "key": "8190",
                "title": "8190 Fx arms w rib/sternum-cl",
                "threedigit": "819",
                "isLeaf": true
              },
              {
                "key": "8191",
                "title": "8191 Fx arms w rib/stern-open",
                "threedigit": "819",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Fracture Of Lower Limb",
        "title": "820-829 Fracture Of Lower Limb",
        "threedigit": "820",
        "children": [
          {
            "key": "Fracture of neck of femur",
            "title": "820 - Fracture of neck of femur",
            "threedigit": "820",
            "children": [
              {
                "key": "82000",
                "title": "82000 Fx femur intrcaps NOS-cl",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82001",
                "title": "82001 Fx up femur epiphy-clos",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82002",
                "title": "82002 Fx femur, midcervic-clos",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82003",
                "title": "82003 Fx base femoral nck-clos",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82009",
                "title": "82009 Fx femur intrcaps NEC-cl",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82010",
                "title": "82010 Fx femur intrcap NOS-opn",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82011",
                "title": "82011 Fx up femur epiphy-open",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82012",
                "title": "82012 Fx femur, midcervic-open",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82013",
                "title": "82013 Fx base femoral nck-open",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82019",
                "title": "82019 Fx femur intrcap NEC-opn",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82020",
                "title": "82020 Trochanteric fx NOS-clos",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82021",
                "title": "82021 Intertrochanteric fx-cl",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82022",
                "title": "82022 Subtrochanteric fx-close",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82030",
                "title": "82030 Trochanteric fx NOS-open",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82031",
                "title": "82031 Intertrochanteric fx-opn",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "82032",
                "title": "82032 Subtrochanteric fx-open",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "8208",
                "title": "8208 Fx neck of femur NOS-cl",
                "threedigit": "820",
                "isLeaf": true
              },
              {
                "key": "8209",
                "title": "8209 Fx neck of femur NOS-opn",
                "threedigit": "820",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of other and unspecified parts of femur",
            "title": "821 - Fracture of other and unspecified parts of femur",
            "threedigit": "821",
            "children": [
              {
                "key": "82100",
                "title": "82100 Fx femur NOS-closed",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82101",
                "title": "82101 Fx femur shaft-closed",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82110",
                "title": "82110 Fx femur NOS-open",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82111",
                "title": "82111 Fx femur shaft-open",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82120",
                "title": "82120 Fx low end femur NOS-cl",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82121",
                "title": "82121 Fx femoral condyle-close",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82122",
                "title": "82122 Fx low femur epiphy-clos",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82123",
                "title": "82123 Supracondyl fx femur-cl",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82129",
                "title": "82129 Fx low end femur NEC-cl",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82130",
                "title": "82130 Fx low end femur NOS-opn",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82131",
                "title": "82131 Fx femoral condyle-open",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82132",
                "title": "82132 Fx low femur epiphy-open",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82133",
                "title": "82133 Supracondyl fx femur-opn",
                "threedigit": "821",
                "isLeaf": true
              },
              {
                "key": "82139",
                "title": "82139 Fx low end femur NEC-opn",
                "threedigit": "821",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of patella",
            "title": "822 - Fracture of patella",
            "threedigit": "822",
            "children": [
              {
                "key": "8220",
                "title": "8220 Fracture patella-closed",
                "threedigit": "822",
                "isLeaf": true
              },
              {
                "key": "8221",
                "title": "8221 Fracture patella-open",
                "threedigit": "822",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of tibia and fibula",
            "title": "823 - Fracture of tibia and fibula",
            "threedigit": "823",
            "children": [
              {
                "key": "82300",
                "title": "82300 Fx upper end tibia-close",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82301",
                "title": "82301 Fx upper end fibula-clos",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82302",
                "title": "82302 Fx up tibia w fibula-cl",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82310",
                "title": "82310 Fx upper end tibia-open",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82311",
                "title": "82311 Fx upper end fibula-open",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82312",
                "title": "82312 Fx up tibia w fibula-opn",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82320",
                "title": "82320 Fx shaft tibia-closed",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82321",
                "title": "82321 Fx shaft fibula-closed",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82322",
                "title": "82322 Fx shaft fib w tib-clos",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82330",
                "title": "82330 Fx tibia shaft-open",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82331",
                "title": "82331 Fx fibula shaft-open",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82332",
                "title": "82332 Fx shaft tibia w fib-opn",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82340",
                "title": "82340 Torus fracture of tibia",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82341",
                "title": "82341 Torus fracture of fibula",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82342",
                "title": "82342 Torus fx tibia/fibula",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82380",
                "title": "82380 Fx tibia NOS-closed",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82381",
                "title": "82381 Fx fibula NOS-closed",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82382",
                "title": "82382 Fx tibia w fibula NOS-cl",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82390",
                "title": "82390 Fx tibia NOS-open",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82391",
                "title": "82391 Fx fibula NOS-open",
                "threedigit": "823",
                "isLeaf": true
              },
              {
                "key": "82392",
                "title": "82392 Fx tibia w fib NOS-open",
                "threedigit": "823",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of ankle",
            "title": "824 - Fracture of ankle",
            "threedigit": "824",
            "children": [
              {
                "key": "8240",
                "title": "8240 Fx medial malleolus-clos",
                "threedigit": "824",
                "isLeaf": true
              },
              {
                "key": "8241",
                "title": "8241 Fx medial malleolus-open",
                "threedigit": "824",
                "isLeaf": true
              },
              {
                "key": "8242",
                "title": "8242 Fx lateral malleolus-cl",
                "threedigit": "824",
                "isLeaf": true
              },
              {
                "key": "8243",
                "title": "8243 Fx lateral malleolus-opn",
                "threedigit": "824",
                "isLeaf": true
              },
              {
                "key": "8244",
                "title": "8244 Fx bimalleolar-closed",
                "threedigit": "824",
                "isLeaf": true
              },
              {
                "key": "8245",
                "title": "8245 Fx bimalleolar-open",
                "threedigit": "824",
                "isLeaf": true
              },
              {
                "key": "8246",
                "title": "8246 Fx trimalleolar-closed",
                "threedigit": "824",
                "isLeaf": true
              },
              {
                "key": "8247",
                "title": "8247 Fx trimalleolar-open",
                "threedigit": "824",
                "isLeaf": true
              },
              {
                "key": "8248",
                "title": "8248 Fx ankle NOS-closed",
                "threedigit": "824",
                "isLeaf": true
              },
              {
                "key": "8249",
                "title": "8249 Fx ankle NOS-open",
                "threedigit": "824",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of one or more tarsal and metatarsal bones",
            "title": "825 - Fracture of one or more tarsal and metatarsal bones",
            "threedigit": "825",
            "children": [
              {
                "key": "8250",
                "title": "8250 Fracture calcaneus-close",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "8251",
                "title": "8251 Fracture calcaneus-open",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82520",
                "title": "82520 Fx foot bone NOS-closed",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82521",
                "title": "82521 Fx astragalus-closed",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82522",
                "title": "82522 Fx navicular, foot-clos",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82523",
                "title": "82523 Fx cuboid-closed",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82524",
                "title": "82524 Fx cuneiform, foot-clos",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82525",
                "title": "82525 Fx metatarsal-closed",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82529",
                "title": "82529 Fx foot bone NEC-closed",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82530",
                "title": "82530 Fx foot bone NOS-open",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82531",
                "title": "82531 Fx astragalus-open",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82532",
                "title": "82532 Fx navicular, foot-open",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82533",
                "title": "82533 Fx cuboid-open",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82534",
                "title": "82534 Fx cuneiform, foot-open",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82535",
                "title": "82535 Fx metatarsal-open",
                "threedigit": "825",
                "isLeaf": true
              },
              {
                "key": "82539",
                "title": "82539 Fx foot bone NEC-open",
                "threedigit": "825",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of one or more phalanges of foot",
            "title": "826 - Fracture of one or more phalanges of foot",
            "threedigit": "826",
            "children": [
              {
                "key": "8260",
                "title": "8260 Fx phalanx, foot-closed",
                "threedigit": "826",
                "isLeaf": true
              },
              {
                "key": "8261",
                "title": "8261 Fx phalanx, foot-open",
                "threedigit": "826",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other multiple and ill-defined fractures of lower limb",
            "title": "827 - Other multiple and ill-defined fractures of lower limb",
            "threedigit": "827",
            "children": [
              {
                "key": "8270",
                "title": "8270 Fx lower limb NEC-closed",
                "threedigit": "827",
                "isLeaf": true
              },
              {
                "key": "8271",
                "title": "8271 Fx lower limb NEC-open",
                "threedigit": "827",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Multiple fractures involving both lower limbs lower with upper limb and lower limb(s) with rib(s) and sternum",
            "title": "828 - Multiple fractures involving both lower limbs lower with upper limb and lower limb(s) with rib(s) and sternum",
            "threedigit": "828",
            "children": [
              {
                "key": "8280",
                "title": "8280 Fx legs w arm/rib-closed",
                "threedigit": "828",
                "isLeaf": true
              },
              {
                "key": "8281",
                "title": "8281 Fx legs w arm/rib-open",
                "threedigit": "828",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture of unspecified bones",
            "title": "829 - Fracture of unspecified bones",
            "threedigit": "829",
            "children": [
              {
                "key": "8290",
                "title": "8290 Fracture NOS-closed",
                "threedigit": "829",
                "isLeaf": true
              },
              {
                "key": "8291",
                "title": "8291 Fracture NOS-open",
                "threedigit": "829",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Dislocation",
        "title": "830-839 Dislocation",
        "threedigit": "830",
        "children": [
          {
            "key": "Dislocation of jaw",
            "title": "830 - Dislocation of jaw",
            "threedigit": "830",
            "children": [
              {
                "key": "8300",
                "title": "8300 Dislocation jaw-closed",
                "threedigit": "830",
                "isLeaf": true
              },
              {
                "key": "8301",
                "title": "8301 Dislocation jaw-open",
                "threedigit": "830",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dislocation of shoulder",
            "title": "831 - Dislocation of shoulder",
            "threedigit": "831",
            "children": [
              {
                "key": "83100",
                "title": "83100 Disloc shoulder NOS-clos",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83101",
                "title": "83101 Ant disloc humerus-close",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83102",
                "title": "83102 Post disloc humerus-clos",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83103",
                "title": "83103 Infer disloc humerus-cl",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83104",
                "title": "83104 Disloc acromioclavic-cl",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83109",
                "title": "83109 Disloc shoulder NEC-clos",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83110",
                "title": "83110 Disloc shoulder NOS-open",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83111",
                "title": "83111 Ant disloc humerus-open",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83112",
                "title": "83112 Post disloc humerus-open",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83113",
                "title": "83113 Infer disloc humerus-opn",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83114",
                "title": "83114 Disloc acromioclavic-opn",
                "threedigit": "831",
                "isLeaf": true
              },
              {
                "key": "83119",
                "title": "83119 Disloc shoulder NEC-open",
                "threedigit": "831",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dislocation of elbow",
            "title": "832 - Dislocation of elbow",
            "threedigit": "832",
            "children": [
              {
                "key": "83200",
                "title": "83200 Dislocat elbow NOS-close",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83201",
                "title": "83201 Ant disloc elbow-closed",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83202",
                "title": "83202 Post disloc elbow-closed",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83203",
                "title": "83203 Med disloc elbow-closed",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83204",
                "title": "83204 Lat disloc elbow-closed",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83209",
                "title": "83209 Dislocat elbow NEC-close",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83210",
                "title": "83210 Dislocat elbow NOS-open",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83211",
                "title": "83211 Ant disloc elbow-open",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83212",
                "title": "83212 Post disloc elbow-open",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83213",
                "title": "83213 Med disloc elbow-open",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83214",
                "title": "83214 Lat dislocat elbow-open",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "83219",
                "title": "83219 Dislocat elbow NEC-open",
                "threedigit": "832",
                "isLeaf": true
              },
              {
                "key": "8322",
                "title": "8322 Nursemaid's elbow",
                "threedigit": "832",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dislocation of wrist",
            "title": "833 - Dislocation of wrist",
            "threedigit": "833",
            "children": [
              {
                "key": "83300",
                "title": "83300 Disloc wrist NOS-closed",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83301",
                "title": "83301 Disloc dist radiouln-cl",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83302",
                "title": "83302 Disloc radiocarpal-clos",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83303",
                "title": "83303 Disloca midcarpal-closed",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83304",
                "title": "83304 Disloc carpometacarp-cl",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83305",
                "title": "83305 Disloc metacarpal-closed",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83309",
                "title": "83309 Disloc wrist NEC-closed",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83310",
                "title": "83310 Dislocat wrist NOS-open",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83311",
                "title": "83311 Disloc dist radiouln-opn",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83312",
                "title": "83312 Disloc radiocarpal-open",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83313",
                "title": "83313 Dislocat midcarpal-open",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83314",
                "title": "83314 Disloc carpometacarp-opn",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83315",
                "title": "83315 Dislocat metacarpal-open",
                "threedigit": "833",
                "isLeaf": true
              },
              {
                "key": "83319",
                "title": "83319 Dislocat wrist NEC-open",
                "threedigit": "833",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dislocation of finger",
            "title": "834 - Dislocation of finger",
            "threedigit": "834",
            "children": [
              {
                "key": "83400",
                "title": "83400 Disl finger NOS-closed",
                "threedigit": "834",
                "isLeaf": true
              },
              {
                "key": "83401",
                "title": "83401 Disloc metacarpophaln-cl",
                "threedigit": "834",
                "isLeaf": true
              },
              {
                "key": "83402",
                "title": "83402 Disl interphaln hand-cl",
                "threedigit": "834",
                "isLeaf": true
              },
              {
                "key": "83410",
                "title": "83410 Disloc finger NOS-open",
                "threedigit": "834",
                "isLeaf": true
              },
              {
                "key": "83411",
                "title": "83411 Disl metacarpophalan-opn",
                "threedigit": "834",
                "isLeaf": true
              },
              {
                "key": "83412",
                "title": "83412 Disl interphaln hand-opn",
                "threedigit": "834",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dislocation of hip",
            "title": "835 - Dislocation of hip",
            "threedigit": "835",
            "children": [
              {
                "key": "83500",
                "title": "83500 Dislocat hip NOS-closed",
                "threedigit": "835",
                "isLeaf": true
              },
              {
                "key": "83501",
                "title": "83501 Posterior disloc hip-cl",
                "threedigit": "835",
                "isLeaf": true
              },
              {
                "key": "83502",
                "title": "83502 Obturator disloc hip-cl",
                "threedigit": "835",
                "isLeaf": true
              },
              {
                "key": "83503",
                "title": "83503 Ant disloc hip NEC-clos",
                "threedigit": "835",
                "isLeaf": true
              },
              {
                "key": "83510",
                "title": "83510 Dislocation hip NOS-open",
                "threedigit": "835",
                "isLeaf": true
              },
              {
                "key": "83511",
                "title": "83511 Posterior disloc hip-opn",
                "threedigit": "835",
                "isLeaf": true
              },
              {
                "key": "83512",
                "title": "83512 Obturator disloc hip-opn",
                "threedigit": "835",
                "isLeaf": true
              },
              {
                "key": "83513",
                "title": "83513 Ant disloc hip NEC-open",
                "threedigit": "835",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dislocation of knee",
            "title": "836 - Dislocation of knee",
            "threedigit": "836",
            "children": [
              {
                "key": "8360",
                "title": "8360 Tear med menisc knee-cur",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "8361",
                "title": "8361 Tear lat menisc knee-cur",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "8362",
                "title": "8362 Tear meniscus NEC-curren",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "8363",
                "title": "8363 Dislocat patella-closed",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "8364",
                "title": "8364 Dislocation patella-open",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83650",
                "title": "83650 Dislocat knee NOS-closed",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83651",
                "title": "83651 Ant disloc prox tibia-cl",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83652",
                "title": "83652 Post disl prox tibia-cl",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83653",
                "title": "83653 Med disloc prox tibia-cl",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83654",
                "title": "83654 Lat disloc prox tibia-cl",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83659",
                "title": "83659 Dislocat knee NEC-closed",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83660",
                "title": "83660 Dislocat knee NOS-open",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83661",
                "title": "83661 Ant disl prox tibia-open",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83662",
                "title": "83662 Post disl prox tibia-opn",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83663",
                "title": "83663 Med disl prox tibia-open",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83664",
                "title": "83664 Lat disl prox tibia-open",
                "threedigit": "836",
                "isLeaf": true
              },
              {
                "key": "83669",
                "title": "83669 Dislocat knee NEC-open",
                "threedigit": "836",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dislocation of ankle",
            "title": "837 - Dislocation of ankle",
            "threedigit": "837",
            "children": [
              {
                "key": "8370",
                "title": "8370 Dislocation ankle-closed",
                "threedigit": "837",
                "isLeaf": true
              },
              {
                "key": "8371",
                "title": "8371 Dislocation ankle-open",
                "threedigit": "837",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Dislocation of foot",
            "title": "838 - Dislocation of foot",
            "threedigit": "838",
            "children": [
              {
                "key": "83800",
                "title": "83800 Dislocat foot NOS-closed",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83801",
                "title": "83801 Disloc tarsal NOS-closed",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83802",
                "title": "83802 Disloc midtarsal-closed",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83803",
                "title": "83803 Disloc tarsometatars-cl",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83804",
                "title": "83804 Disloc metatarsal NOS-cl",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83805",
                "title": "83805 Disl metatarsophalang-cl",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83806",
                "title": "83806 Disl interphalan foot-cl",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83809",
                "title": "83809 Dislocat foot NEC-closed",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83810",
                "title": "83810 Dislocat foot NOS-open",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83811",
                "title": "83811 Disloc tarsal NOS-open",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83812",
                "title": "83812 Disloc midtarsal-open",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83813",
                "title": "83813 Disl tarsometatarsal-opn",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83814",
                "title": "83814 Disl metatarsal NOS-open",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83815",
                "title": "83815 Disloc metatarsophal-opn",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83816",
                "title": "83816 Dis interphalan foot-opn",
                "threedigit": "838",
                "isLeaf": true
              },
              {
                "key": "83819",
                "title": "83819 Dislocat foot NEC-open",
                "threedigit": "838",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other multiple and ill-defined dislocations",
            "title": "839 - Other multiple and ill-defined dislocations",
            "threedigit": "839",
            "children": [
              {
                "key": "83900",
                "title": "83900 Disloc cerv vert NOS-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83901",
                "title": "83901 Disloc 1st cerv vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83902",
                "title": "83902 Disloc 2nd cerv vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83903",
                "title": "83903 Disloc 3rd cerv vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83904",
                "title": "83904 Disloc 4th cerv vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83905",
                "title": "83905 Disloc 5th cerv vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83906",
                "title": "83906 Disloc 6th cerv vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83907",
                "title": "83907 Disloc 7th cerv vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83908",
                "title": "83908 Disloc mult cerv vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83910",
                "title": "83910 Disloc cerv vert NOS-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83911",
                "title": "83911 Disloc lst cerv vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83912",
                "title": "83912 Disloc 2nd cerv vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83913",
                "title": "83913 Disloc 3rd cerv vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83914",
                "title": "83914 Disloc 4th cerv vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83915",
                "title": "83915 Disloc 5th cerv vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83916",
                "title": "83916 Disloc 6th cerv vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83917",
                "title": "83917 Disloc 7th cerv vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83918",
                "title": "83918 Disloc mlt cerv vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83920",
                "title": "83920 Dislocat lumbar vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83921",
                "title": "83921 Disloc thoracic vert-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83930",
                "title": "83930 Dislocat lumbar vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83931",
                "title": "83931 Disloc thoracic vert-opn",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83940",
                "title": "83940 Dislocat vertebra NOS-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83941",
                "title": "83941 Dislocat coccyx-closed",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83942",
                "title": "83942 Dislocat sacrum-closed",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83949",
                "title": "83949 Dislocat vertebra NEC-cl",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83950",
                "title": "83950 Disloc vertebra NOS-open",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83951",
                "title": "83951 Dislocat coccyx-open",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83952",
                "title": "83952 Dislocat sacrum-open",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83959",
                "title": "83959 Disloc vertebra NEC-open",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83961",
                "title": "83961 Dislocat sternum-closed",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83969",
                "title": "83969 Dislocat site NEC-closed",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83971",
                "title": "83971 Dislocation sternum-open",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "83979",
                "title": "83979 Dislocat site NEC-open",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "8398",
                "title": "8398 Dislocation NEC-closed",
                "threedigit": "839",
                "isLeaf": true
              },
              {
                "key": "8399",
                "title": "8399 Dislocation NEC-open",
                "threedigit": "839",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Sprains And Strains Of Joints And Adjacent Muscles",
        "title": "840-848 Sprains And Strains Of Joints And Adjacent Muscles",
        "threedigit": "840",
        "children": [
          {
            "key": "Sprains and strains of shoulder and upper arm",
            "title": "840 - Sprains and strains of shoulder and upper arm",
            "threedigit": "840",
            "children": [
              {
                "key": "8400",
                "title": "8400 Sprain acromioclavicular",
                "threedigit": "840",
                "isLeaf": true
              },
              {
                "key": "8401",
                "title": "8401 Sprain coracoclavicular",
                "threedigit": "840",
                "isLeaf": true
              },
              {
                "key": "8402",
                "title": "8402 Sprain coracohumeral",
                "threedigit": "840",
                "isLeaf": true
              },
              {
                "key": "8403",
                "title": "8403 Sprain infraspinatus",
                "threedigit": "840",
                "isLeaf": true
              },
              {
                "key": "8404",
                "title": "8404 Sprain rotator cuff",
                "threedigit": "840",
                "isLeaf": true
              },
              {
                "key": "8405",
                "title": "8405 Sprain subscapularis",
                "threedigit": "840",
                "isLeaf": true
              },
              {
                "key": "8406",
                "title": "8406 Sprain supraspinatus",
                "threedigit": "840",
                "isLeaf": true
              },
              {
                "key": "8407",
                "title": "8407 Sup glenoid labrm lesion",
                "threedigit": "840",
                "isLeaf": true
              },
              {
                "key": "8408",
                "title": "8408 Sprain shoulder/arm NEC",
                "threedigit": "840",
                "isLeaf": true
              },
              {
                "key": "8409",
                "title": "8409 Sprain shoulder/arm NOS",
                "threedigit": "840",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sprains and strains of elbow and forearm",
            "title": "841 - Sprains and strains of elbow and forearm",
            "threedigit": "841",
            "children": [
              {
                "key": "8410",
                "title": "8410 Sprain radial collat lig",
                "threedigit": "841",
                "isLeaf": true
              },
              {
                "key": "8411",
                "title": "8411 Sprain ulnar collat lig",
                "threedigit": "841",
                "isLeaf": true
              },
              {
                "key": "8412",
                "title": "8412 Sprain radiohumeral",
                "threedigit": "841",
                "isLeaf": true
              },
              {
                "key": "8413",
                "title": "8413 Sprain ulnohumeral",
                "threedigit": "841",
                "isLeaf": true
              },
              {
                "key": "8418",
                "title": "8418 Sprain elbow/forearm NEC",
                "threedigit": "841",
                "isLeaf": true
              },
              {
                "key": "8419",
                "title": "8419 Sprain elbow/forearm NOS",
                "threedigit": "841",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sprains and strains of wrist and hand",
            "title": "842 - Sprains and strains of wrist and hand",
            "threedigit": "842",
            "children": [
              {
                "key": "84200",
                "title": "84200 Sprain of wrist NOS",
                "threedigit": "842",
                "isLeaf": true
              },
              {
                "key": "84201",
                "title": "84201 Sprain carpal",
                "threedigit": "842",
                "isLeaf": true
              },
              {
                "key": "84202",
                "title": "84202 Sprain radiocarpal",
                "threedigit": "842",
                "isLeaf": true
              },
              {
                "key": "84209",
                "title": "84209 Sprain of wrist NEC",
                "threedigit": "842",
                "isLeaf": true
              },
              {
                "key": "84210",
                "title": "84210 Sprain of hand NOS",
                "threedigit": "842",
                "isLeaf": true
              },
              {
                "key": "84211",
                "title": "84211 Sprain carpometacarpal",
                "threedigit": "842",
                "isLeaf": true
              },
              {
                "key": "84212",
                "title": "84212 Sprain metacarpophalang",
                "threedigit": "842",
                "isLeaf": true
              },
              {
                "key": "84213",
                "title": "84213 Sprain interphalangeal",
                "threedigit": "842",
                "isLeaf": true
              },
              {
                "key": "84219",
                "title": "84219 Sprain of hand NEC",
                "threedigit": "842",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sprains and strains of hip and thigh",
            "title": "843 - Sprains and strains of hip and thigh",
            "threedigit": "843",
            "children": [
              {
                "key": "8430",
                "title": "8430 Sprain iliofemoral",
                "threedigit": "843",
                "isLeaf": true
              },
              {
                "key": "8431",
                "title": "8431 Sprain ischiocapsular",
                "threedigit": "843",
                "isLeaf": true
              },
              {
                "key": "8438",
                "title": "8438 Sprain hip & thigh NEC",
                "threedigit": "843",
                "isLeaf": true
              },
              {
                "key": "8439",
                "title": "8439 Sprain hip & thigh NOS",
                "threedigit": "843",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sprains and strains of knee and leg",
            "title": "844 - Sprains and strains of knee and leg",
            "threedigit": "844",
            "children": [
              {
                "key": "8440",
                "title": "8440 Sprain lateral coll lig",
                "threedigit": "844",
                "isLeaf": true
              },
              {
                "key": "8441",
                "title": "8441 Sprain medial collat lig",
                "threedigit": "844",
                "isLeaf": true
              },
              {
                "key": "8442",
                "title": "8442 Sprain cruciate lig knee",
                "threedigit": "844",
                "isLeaf": true
              },
              {
                "key": "8443",
                "title": "8443 Sprain super tibiofibula",
                "threedigit": "844",
                "isLeaf": true
              },
              {
                "key": "8448",
                "title": "8448 Sprain of knee & leg NEC",
                "threedigit": "844",
                "isLeaf": true
              },
              {
                "key": "8449",
                "title": "8449 Sprain of knee & leg NOS",
                "threedigit": "844",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sprains and strains of ankle and foot",
            "title": "845 - Sprains and strains of ankle and foot",
            "threedigit": "845",
            "children": [
              {
                "key": "84500",
                "title": "84500 Sprain of ankle NOS",
                "threedigit": "845",
                "isLeaf": true
              },
              {
                "key": "84501",
                "title": "84501 Sprain of ankle deltoid",
                "threedigit": "845",
                "isLeaf": true
              },
              {
                "key": "84502",
                "title": "84502 Sprain calcaneofibular",
                "threedigit": "845",
                "isLeaf": true
              },
              {
                "key": "84503",
                "title": "84503 Sprain distal tibiofibul",
                "threedigit": "845",
                "isLeaf": true
              },
              {
                "key": "84509",
                "title": "84509 Sprain of ankle NEC",
                "threedigit": "845",
                "isLeaf": true
              },
              {
                "key": "84510",
                "title": "84510 Sprain of foot NOS",
                "threedigit": "845",
                "isLeaf": true
              },
              {
                "key": "84511",
                "title": "84511 Sprain tarsometatarsal",
                "threedigit": "845",
                "isLeaf": true
              },
              {
                "key": "84512",
                "title": "84512 Sprain metatarsophalang",
                "threedigit": "845",
                "isLeaf": true
              },
              {
                "key": "84513",
                "title": "84513 Sprain interphalang toe",
                "threedigit": "845",
                "isLeaf": true
              },
              {
                "key": "84519",
                "title": "84519 Sprain of foot NEC",
                "threedigit": "845",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sprains and strains of sacroiliac region",
            "title": "846 - Sprains and strains of sacroiliac region",
            "threedigit": "846",
            "children": [
              {
                "key": "8460",
                "title": "8460 Sprain lumbosacral",
                "threedigit": "846",
                "isLeaf": true
              },
              {
                "key": "8461",
                "title": "8461 Sprain sacroiliac",
                "threedigit": "846",
                "isLeaf": true
              },
              {
                "key": "8462",
                "title": "8462 Sprain sacrospinatus",
                "threedigit": "846",
                "isLeaf": true
              },
              {
                "key": "8463",
                "title": "8463 Sprain sacrotuberous",
                "threedigit": "846",
                "isLeaf": true
              },
              {
                "key": "8468",
                "title": "8468 Sprain sacroiliac NEC",
                "threedigit": "846",
                "isLeaf": true
              },
              {
                "key": "8469",
                "title": "8469 Sprain sacroiliac NOS",
                "threedigit": "846",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sprains and strains of other and unspecified parts of back",
            "title": "847 - Sprains and strains of other and unspecified parts of back",
            "threedigit": "847",
            "children": [
              {
                "key": "8470",
                "title": "8470 Sprain of neck",
                "threedigit": "847",
                "isLeaf": true
              },
              {
                "key": "8471",
                "title": "8471 Sprain thoracic region",
                "threedigit": "847",
                "isLeaf": true
              },
              {
                "key": "8472",
                "title": "8472 Sprain lumbar region",
                "threedigit": "847",
                "isLeaf": true
              },
              {
                "key": "8473",
                "title": "8473 Sprain of sacrum",
                "threedigit": "847",
                "isLeaf": true
              },
              {
                "key": "8474",
                "title": "8474 Sprain of coccyx",
                "threedigit": "847",
                "isLeaf": true
              },
              {
                "key": "8479",
                "title": "8479 Sprain of back NOS",
                "threedigit": "847",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and ill-defined sprains and strains",
            "title": "848 - Other and ill-defined sprains and strains",
            "threedigit": "848",
            "children": [
              {
                "key": "8480",
                "title": "8480 Sprain of nasal septum",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "8481",
                "title": "8481 Sprain of jaw",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "8482",
                "title": "8482 Sprain of thyroid region",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "8483",
                "title": "8483 Sprain of ribs",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "84840",
                "title": "84840 Sprain of sternum NOS",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "84841",
                "title": "84841 Sprain sternoclavicular",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "84842",
                "title": "84842 Sprain chondrosternal",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "84849",
                "title": "84849 Sprain of sternum NEC",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "8485",
                "title": "8485 Sprain of pelvis",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "8488",
                "title": "8488 Sprain NEC",
                "threedigit": "848",
                "isLeaf": true
              },
              {
                "key": "8489",
                "title": "8489 Sprain NOS",
                "threedigit": "848",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Intracranial Injury, Excluding Those With Skull Fracture",
        "title": "850-854 Intracranial Injury, Excluding Those With Skull Fracture",
        "threedigit": "850",
        "children": [
          {
            "key": "Concussion",
            "title": "850 - Concussion",
            "threedigit": "850",
            "children": [
              {
                "key": "8500",
                "title": "8500 Concussion w/o coma",
                "threedigit": "850",
                "isLeaf": true
              },
              {
                "key": "85011",
                "title": "85011 Concus-brief coma <31 mn",
                "threedigit": "850",
                "isLeaf": true
              },
              {
                "key": "85012",
                "title": "85012 Concus-brf coma 31-59 mn",
                "threedigit": "850",
                "isLeaf": true
              },
              {
                "key": "8502",
                "title": "8502 Concussion-moderate coma",
                "threedigit": "850",
                "isLeaf": true
              },
              {
                "key": "8503",
                "title": "8503 Concussion-prolong coma",
                "threedigit": "850",
                "isLeaf": true
              },
              {
                "key": "8504",
                "title": "8504 Concussion-deep coma",
                "threedigit": "850",
                "isLeaf": true
              },
              {
                "key": "8505",
                "title": "8505 Concussion w coma NOS",
                "threedigit": "850",
                "isLeaf": true
              },
              {
                "key": "8509",
                "title": "8509 Concussion NOS",
                "threedigit": "850",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Cerebral laceration and contusion",
            "title": "851 - Cerebral laceration and contusion",
            "threedigit": "851",
            "children": [
              {
                "key": "85100",
                "title": "85100 Cerebral cortx contusion",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85101",
                "title": "85101 Cortex contusion-no coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85102",
                "title": "85102 Cortex contus-brief coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85103",
                "title": "85103 Cortex contus-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85104",
                "title": "85104 Cortx contus-prolng coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85105",
                "title": "85105 Cortex contus-deep coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85106",
                "title": "85106 Cortex contus-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85109",
                "title": "85109 Cortex contus-concus NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85110",
                "title": "85110 Cortex contusion/opn wnd",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85111",
                "title": "85111 Opn cortx contus-no coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85112",
                "title": "85112 Opn cort contus-brf coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85113",
                "title": "85113 Opn cort contus-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85114",
                "title": "85114 Opn cort contu-prol coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85115",
                "title": "85115 Opn cort contu-deep coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85116",
                "title": "85116 Opn cort contus-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85119",
                "title": "85119 Opn cortx contus-concuss",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85120",
                "title": "85120 Cerebral cortex lacerat",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85121",
                "title": "85121 Cortex lacerat w/o coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85122",
                "title": "85122 Cortex lacera-brief coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85123",
                "title": "85123 Cortex lacerat-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85124",
                "title": "85124 Cortex lacerat-prol coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85125",
                "title": "85125 Cortex lacerat-deep coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85126",
                "title": "85126 Cortex lacerat-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85129",
                "title": "85129 Cortex lacerat-concuss",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85130",
                "title": "85130 Cortex lacer w opn wound",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85131",
                "title": "85131 Opn cortex lacer-no coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85132",
                "title": "85132 Opn cortx lac-brief coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85133",
                "title": "85133 Opn cortx lacer-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85134",
                "title": "85134 Opn cortx lac-proln coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85135",
                "title": "85135 Opn cortex lac-deep coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85136",
                "title": "85136 Opn cortx lacer-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85139",
                "title": "85139 Opn cortx lacer-concuss",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85140",
                "title": "85140 Cerebel/brain stm contus",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85141",
                "title": "85141 Cerebell contus w/o coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85142",
                "title": "85142 Cerebell contus-brf coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85143",
                "title": "85143 Cerebell contus-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85144",
                "title": "85144 Cerebel contus-prol coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85145",
                "title": "85145 Cerebel contus-deep coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85146",
                "title": "85146 Cerebell contus-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85149",
                "title": "85149 Cerebell contus-concuss",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85150",
                "title": "85150 Cerebel contus w opn wnd",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85151",
                "title": "85151 Opn cerebe cont w/o coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85152",
                "title": "85152 Opn cerebe cont-brf coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85153",
                "title": "85153 Opn cerebe cont-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85154",
                "title": "85154 Opn cerebe cont-prol com",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85155",
                "title": "85155 Opn cerebe cont-deep com",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85156",
                "title": "85156 Opn cerebe cont-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85159",
                "title": "85159 Opn cerebel cont-concuss",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85160",
                "title": "85160 Cerebel/brain stem lacer",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85161",
                "title": "85161 Cerebel lacerat w/o coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85162",
                "title": "85162 Cerebel lacer-brief coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85163",
                "title": "85163 Cerebel lacerat-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85164",
                "title": "85164 Cerebel lacer-proln coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85165",
                "title": "85165 Cerebell lacer-deep coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85166",
                "title": "85166 Cerebel lacerat-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85169",
                "title": "85169 Cerebel lacer-concussion",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85170",
                "title": "85170 Cerebel lacer w open wnd",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85171",
                "title": "85171 Opn cerebel lac w/o coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85172",
                "title": "85172 Opn cerebel lac-brf coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85173",
                "title": "85173 Opn cerebel lac-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85174",
                "title": "85174 Opn cerebe lac-prol coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85175",
                "title": "85175 Opn cerebe lac-deep coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85176",
                "title": "85176 Opn cerebel lac-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85179",
                "title": "85179 Opn cerebell lac-concuss",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85180",
                "title": "85180 Brain laceration NEC",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85181",
                "title": "85181 Brain lacer NEC w/o coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85182",
                "title": "85182 Brain lac NEC-brief coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85183",
                "title": "85183 Brain lacer NEC-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85184",
                "title": "85184 Brain lac NEC-proln coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85185",
                "title": "85185 Brain lac NEC-deep coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85186",
                "title": "85186 Brain lacer NEC-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85189",
                "title": "85189 Brain lacer NEC-concuss",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85190",
                "title": "85190 Brain lac NEC w open wnd",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85191",
                "title": "85191 Opn brain lacer w/o coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85192",
                "title": "85192 Opn brain lac-brief coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85193",
                "title": "85193 Opn brain lacer-mod coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85194",
                "title": "85194 Opn brain lac-proln coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85195",
                "title": "85195 Open brain lac-deep coma",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85196",
                "title": "85196 Opn brain lacer-coma NOS",
                "threedigit": "851",
                "isLeaf": true
              },
              {
                "key": "85199",
                "title": "85199 Open brain lacer-concuss",
                "threedigit": "851",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Subarachnoid subdural and extradural hemorrhage following injury",
            "title": "852 - Subarachnoid subdural and extradural hemorrhage following injury",
            "threedigit": "852",
            "children": [
              {
                "key": "85200",
                "title": "85200 Traum subarachnoid hem",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85201",
                "title": "85201 Subarachnoid hem-no coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85202",
                "title": "85202 Subarach hem-brief coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85203",
                "title": "85203 Subarach hem-mod coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85204",
                "title": "85204 Subarach hem-prolng coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85205",
                "title": "85205 Subarach hem-deep coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85206",
                "title": "85206 Subarach hem-coma NOS",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85209",
                "title": "85209 Subarach hem-concussion",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85210",
                "title": "85210 Subarach hem w opn wound",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85211",
                "title": "85211 Opn subarach hem-no coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85212",
                "title": "85212 Op subarach hem-brf coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85213",
                "title": "85213 Op subarach hem-mod coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85214",
                "title": "85214 Op subarach hem-prol com",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85215",
                "title": "85215 Op subarach hem-deep com",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85216",
                "title": "85216 Op subarach hem-coma NOS",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85219",
                "title": "85219 Opn subarach hem-concuss",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85220",
                "title": "85220 Traumatic subdural hem",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85221",
                "title": "85221 Subdural hem w/o coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85222",
                "title": "85222 Subdural hem-brief coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85223",
                "title": "85223 Subdural hemorr-mod coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85224",
                "title": "85224 Subdural hem-prolng coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85225",
                "title": "85225 Subdural hem-deep coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85226",
                "title": "85226 Subdural hemorr-coma NOS",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85229",
                "title": "85229 Subdural hem-concussion",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85230",
                "title": "85230 Subdural hem w opn wound",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85231",
                "title": "85231 Open subdur hem w/o coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85232",
                "title": "85232 Opn subdur hem-brf coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85233",
                "title": "85233 Opn subdur hem-mod coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85234",
                "title": "85234 Opn subdur hem-prol coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85235",
                "title": "85235 Opn subdur hem-deep coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85236",
                "title": "85236 Opn subdur hem-coma NOS",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85239",
                "title": "85239 Opn subdur hem-concuss",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85240",
                "title": "85240 Traumatic extradural hem",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85241",
                "title": "85241 Extradural hem w/o coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85242",
                "title": "85242 Extradur hem-brief coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85243",
                "title": "85243 Extradural hem-mod coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85244",
                "title": "85244 Extradur hem-proln coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85245",
                "title": "85245 Extradural hem-deep coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85246",
                "title": "85246 Extradural hem-coma NOS",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85249",
                "title": "85249 Extadural hem-concuss",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85250",
                "title": "85250 Extradural hem w opn wnd",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85251",
                "title": "85251 Extradural hemor-no coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85252",
                "title": "85252 Extradur hem-brief coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85253",
                "title": "85253 Extradural hem-mod coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85254",
                "title": "85254 Extradur hem-proln coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85255",
                "title": "85255 Extradur hem-deep coma",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85256",
                "title": "85256 Extradural hem-coma NOS",
                "threedigit": "852",
                "isLeaf": true
              },
              {
                "key": "85259",
                "title": "85259 Extradural hem-concuss",
                "threedigit": "852",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified intracranial hemorrhage following injury",
            "title": "853 - Other and unspecified intracranial hemorrhage following injury",
            "threedigit": "853",
            "children": [
              {
                "key": "85300",
                "title": "85300 Traumatic brain hem NEC",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85301",
                "title": "85301 Brain hem NEC w/o coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85302",
                "title": "85302 Brain hem NEC-brief coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85303",
                "title": "85303 Brain hem NEC-mod coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85304",
                "title": "85304 Brain hem NEC-proln coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85305",
                "title": "85305 Brain hem NEC-deep coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85306",
                "title": "85306 Brain hem NEC-coma NOS",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85309",
                "title": "85309 Brain hem NEC-concussion",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85310",
                "title": "85310 Brain hem NEC w opn wnd",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85311",
                "title": "85311 Brain hem opn w/o coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85312",
                "title": "85312 Brain hem opn-brf coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85313",
                "title": "85313 Brain hem open-mod coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85314",
                "title": "85314 Brain hem opn-proln coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85315",
                "title": "85315 Brain hem open-deep coma",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85316",
                "title": "85316 Brain hem open-coma NOS",
                "threedigit": "853",
                "isLeaf": true
              },
              {
                "key": "85319",
                "title": "85319 Brain hem opn-concussion",
                "threedigit": "853",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Intracranial injury of other and unspecified nature",
            "title": "854 - Intracranial injury of other and unspecified nature",
            "threedigit": "854",
            "children": [
              {
                "key": "85400",
                "title": "85400 Brain injury NEC",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85401",
                "title": "85401 Brain injury NEC-no coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85402",
                "title": "85402 Brain inj NEC-brief coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85403",
                "title": "85403 Brain inj NEC-mod coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85404",
                "title": "85404 Brain inj NEC-proln coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85405",
                "title": "85405 Brain inj NEC-deep coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85406",
                "title": "85406 Brain inj NEC-coma NOS",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85409",
                "title": "85409 Brain inj NEC-concussion",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85410",
                "title": "85410 Brain injury w opn wnd",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85411",
                "title": "85411 Opn brain inj w/o coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85412",
                "title": "85412 Opn brain inj-brief coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85413",
                "title": "85413 Opn brain inj-mod coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85414",
                "title": "85414 Opn brain inj-proln coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85415",
                "title": "85415 Opn brain inj-deep coma",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85416",
                "title": "85416 Open brain inj-coma NOS",
                "threedigit": "854",
                "isLeaf": true
              },
              {
                "key": "85419",
                "title": "85419 Opn brain inj-concussion",
                "threedigit": "854",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Internal Injury Of Chest, Abdomen, And Pelvis",
        "title": "860-869 Internal Injury Of Chest, Abdomen, And Pelvis",
        "threedigit": "860",
        "children": [
          {
            "key": "Traumatic pneumothorax and hemothorax",
            "title": "860 - Traumatic pneumothorax and hemothorax",
            "threedigit": "860",
            "children": [
              {
                "key": "8600",
                "title": "8600 Traum pneumothorax-close",
                "threedigit": "860",
                "isLeaf": true
              },
              {
                "key": "8601",
                "title": "8601 Traum pneumothorax-open",
                "threedigit": "860",
                "isLeaf": true
              },
              {
                "key": "8602",
                "title": "8602 Traum hemothorax-closed",
                "threedigit": "860",
                "isLeaf": true
              },
              {
                "key": "8603",
                "title": "8603 Traum hemothorax-open",
                "threedigit": "860",
                "isLeaf": true
              },
              {
                "key": "8604",
                "title": "8604 Traum pneumohemothor-cl",
                "threedigit": "860",
                "isLeaf": true
              },
              {
                "key": "8605",
                "title": "8605 Traum pneumohemothor-opn",
                "threedigit": "860",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to heart and lung",
            "title": "861 - Injury to heart and lung",
            "threedigit": "861",
            "children": [
              {
                "key": "86100",
                "title": "86100 Heart injury NOS-closed",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86101",
                "title": "86101 Heart contusion-closed",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86102",
                "title": "86102 Heart laceration-closed",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86103",
                "title": "86103 Heart chamber lacerat-cl",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86110",
                "title": "86110 Heart injury NOS-open",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86111",
                "title": "86111 Heart contusion-open",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86112",
                "title": "86112 Heart laceration-open",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86113",
                "title": "86113 Heart chamber lacer-opn",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86120",
                "title": "86120 Lung injury NOS-closed",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86121",
                "title": "86121 Lung contusion-closed",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86122",
                "title": "86122 Lung laceration-closed",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86130",
                "title": "86130 Lung injury NOS-open",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86131",
                "title": "86131 Lung contusion-open",
                "threedigit": "861",
                "isLeaf": true
              },
              {
                "key": "86132",
                "title": "86132 Lung laceration-open",
                "threedigit": "861",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to other and unspecified intrathoracic organs",
            "title": "862 - Injury to other and unspecified intrathoracic organs",
            "threedigit": "862",
            "children": [
              {
                "key": "8620",
                "title": "8620 Diaphragm injury-closed",
                "threedigit": "862",
                "isLeaf": true
              },
              {
                "key": "8621",
                "title": "8621 Diaphragm injury-open",
                "threedigit": "862",
                "isLeaf": true
              },
              {
                "key": "86221",
                "title": "86221 Bronchus injury-closed",
                "threedigit": "862",
                "isLeaf": true
              },
              {
                "key": "86222",
                "title": "86222 Esophagus injury-closed",
                "threedigit": "862",
                "isLeaf": true
              },
              {
                "key": "86229",
                "title": "86229 Intrathoracic inj NEC-cl",
                "threedigit": "862",
                "isLeaf": true
              },
              {
                "key": "86231",
                "title": "86231 Bronchus injury-open",
                "threedigit": "862",
                "isLeaf": true
              },
              {
                "key": "86232",
                "title": "86232 Esophagus injury-open",
                "threedigit": "862",
                "isLeaf": true
              },
              {
                "key": "86239",
                "title": "86239 Intrathorac inj NEC-open",
                "threedigit": "862",
                "isLeaf": true
              },
              {
                "key": "8628",
                "title": "8628 Intrathoracic inj NOS-cl",
                "threedigit": "862",
                "isLeaf": true
              },
              {
                "key": "8629",
                "title": "8629 Intrathorac inj NOS-open",
                "threedigit": "862",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to gastrointestinal tract",
            "title": "863 - Injury to gastrointestinal tract",
            "threedigit": "863",
            "children": [
              {
                "key": "8630",
                "title": "8630 Stomach injury-closed",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "8631",
                "title": "8631 Stomach injury-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86320",
                "title": "86320 Small intest inj NOS-cl",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86321",
                "title": "86321 Duodenum injury-closed",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86329",
                "title": "86329 Small intest inj NEC-cl",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86330",
                "title": "86330 Small intest inj NOS-opn",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86331",
                "title": "86331 Duodenum injury-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86339",
                "title": "86339 Small intest inj NEC-opn",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86340",
                "title": "86340 Colon injury NOS-closed",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86341",
                "title": "86341 Ascending colon inj-clos",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86342",
                "title": "86342 Transverse colon inj-cl",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86343",
                "title": "86343 Descending colon inj-cl",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86344",
                "title": "86344 Sigmoid colon inj-closed",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86345",
                "title": "86345 Rectum injury-closed",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86346",
                "title": "86346 Colon inj mult site-clos",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86349",
                "title": "86349 Colon injury NEC-closed",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86350",
                "title": "86350 Colon injury NOS-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86351",
                "title": "86351 Ascending colon inj-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86352",
                "title": "86352 Transverse colon inj-opn",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86353",
                "title": "86353 Descending colon inj-opn",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86354",
                "title": "86354 Sigmoid colon inj-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86355",
                "title": "86355 Rectum injury-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86356",
                "title": "86356 Colon inj mult site-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86359",
                "title": "86359 Colon injury NEC-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86380",
                "title": "86380 GI injury NOS-closed",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86381",
                "title": "86381 Pancreas, head inj-close",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86382",
                "title": "86382 Pancreas, body inj-close",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86383",
                "title": "86383 Pancreas, tail inj-close",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86384",
                "title": "86384 Pancreas injury NOS-clos",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86385",
                "title": "86385 Appendix injury-closed",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86389",
                "title": "86389 GI injury NEC-closed",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86390",
                "title": "86390 GI injury NOS-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86391",
                "title": "86391 Pancreas, head inj-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86392",
                "title": "86392 Pancreas, body inj-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86393",
                "title": "86393 Pancreas, tail inj-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86394",
                "title": "86394 Pancreas injury NOS-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86395",
                "title": "86395 Appendix injury-open",
                "threedigit": "863",
                "isLeaf": true
              },
              {
                "key": "86399",
                "title": "86399 GI injury NEC-open",
                "threedigit": "863",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to liver",
            "title": "864 - Injury to liver",
            "threedigit": "864",
            "children": [
              {
                "key": "86400",
                "title": "86400 Liver injury NOS-closed",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86401",
                "title": "86401 Liver hematoma/contusion",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86402",
                "title": "86402 Liver laceration, minor",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86403",
                "title": "86403 Liver laceration, mod",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86404",
                "title": "86404 Liver laceration, major",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86405",
                "title": "86405 Liver lacerat unspcf cls",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86409",
                "title": "86409 Liver injury NEC-closed",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86410",
                "title": "86410 Liver injury NOS-open",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86411",
                "title": "86411 Liver hematom/contus-opn",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86412",
                "title": "86412 Liver lacerat, minor-opn",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86413",
                "title": "86413 Liver lacerat, mod-open",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86414",
                "title": "86414 Liver lacerat, major-opn",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86415",
                "title": "86415 Liver lacerat unspcf opn",
                "threedigit": "864",
                "isLeaf": true
              },
              {
                "key": "86419",
                "title": "86419 Liver injury NEC-open",
                "threedigit": "864",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to spleen",
            "title": "865 - Injury to spleen",
            "threedigit": "865",
            "children": [
              {
                "key": "86500",
                "title": "86500 Spleen injury NOS-closed",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86501",
                "title": "86501 Spleen hematoma-closed",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86502",
                "title": "86502 Spleen capsular tear",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86503",
                "title": "86503 Spleen parenchyma lacer",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86504",
                "title": "86504 Spleen disruption-clos",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86509",
                "title": "86509 Spleen injury NEC-closed",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86510",
                "title": "86510 Spleen injury NOS-open",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86511",
                "title": "86511 Spleen hematoma-open",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86512",
                "title": "86512 Spleen capsular tear-opn",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86513",
                "title": "86513 Spleen parnchym lac-opn",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86514",
                "title": "86514 Spleen disruption-open",
                "threedigit": "865",
                "isLeaf": true
              },
              {
                "key": "86519",
                "title": "86519 Spleen injury NEC-open",
                "threedigit": "865",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to kidney",
            "title": "866 - Injury to kidney",
            "threedigit": "866",
            "children": [
              {
                "key": "86600",
                "title": "86600 Kidney injury NOS-closed",
                "threedigit": "866",
                "isLeaf": true
              },
              {
                "key": "86601",
                "title": "86601 Kidney hematoma-closed",
                "threedigit": "866",
                "isLeaf": true
              },
              {
                "key": "86602",
                "title": "86602 Kidney laceration-closed",
                "threedigit": "866",
                "isLeaf": true
              },
              {
                "key": "86603",
                "title": "86603 Kidney disruption-closed",
                "threedigit": "866",
                "isLeaf": true
              },
              {
                "key": "86610",
                "title": "86610 Kidney injury NOS-open",
                "threedigit": "866",
                "isLeaf": true
              },
              {
                "key": "86611",
                "title": "86611 Kidney hematoma-open",
                "threedigit": "866",
                "isLeaf": true
              },
              {
                "key": "86612",
                "title": "86612 Kidney laceration-open",
                "threedigit": "866",
                "isLeaf": true
              },
              {
                "key": "86613",
                "title": "86613 Kidney disruption-open",
                "threedigit": "866",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to pelvic organs",
            "title": "867 - Injury to pelvic organs",
            "threedigit": "867",
            "children": [
              {
                "key": "8670",
                "title": "8670 Bladder/urethra inj-clos",
                "threedigit": "867",
                "isLeaf": true
              },
              {
                "key": "8671",
                "title": "8671 Bladder/urethra inj-open",
                "threedigit": "867",
                "isLeaf": true
              },
              {
                "key": "8672",
                "title": "8672 Ureter injury-closed",
                "threedigit": "867",
                "isLeaf": true
              },
              {
                "key": "8673",
                "title": "8673 Ureter injury-open",
                "threedigit": "867",
                "isLeaf": true
              },
              {
                "key": "8674",
                "title": "8674 Uterus injury-closed",
                "threedigit": "867",
                "isLeaf": true
              },
              {
                "key": "8675",
                "title": "8675 Uterus injury-open",
                "threedigit": "867",
                "isLeaf": true
              },
              {
                "key": "8676",
                "title": "8676 Pelvic organ inj NEC-cl",
                "threedigit": "867",
                "isLeaf": true
              },
              {
                "key": "8677",
                "title": "8677 Pelvic organ inj NEC-opn",
                "threedigit": "867",
                "isLeaf": true
              },
              {
                "key": "8678",
                "title": "8678 Pelvic organ inj NOS-cl",
                "threedigit": "867",
                "isLeaf": true
              },
              {
                "key": "8679",
                "title": "8679 Pelvic organ inj NOS-opn",
                "threedigit": "867",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to other intra-abdominal organs",
            "title": "868 - Injury to other intra-abdominal organs",
            "threedigit": "868",
            "children": [
              {
                "key": "86800",
                "title": "86800 Intra-abdom inj NOS-clos",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86801",
                "title": "86801 Adrenal gland injury-cl",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86802",
                "title": "86802 Biliary tract injury-cl",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86803",
                "title": "86803 Peritoneum injury-closed",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86804",
                "title": "86804 Retroperitoneum inj-cl",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86809",
                "title": "86809 Intra-abdom inj NEC-clos",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86810",
                "title": "86810 Intra-abdom inj NOS-open",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86811",
                "title": "86811 Adrenal gland injury-opn",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86812",
                "title": "86812 Biliary tract injury-opn",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86813",
                "title": "86813 Peritoneum injury-open",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86814",
                "title": "86814 Retroperitoneum inj-open",
                "threedigit": "868",
                "isLeaf": true
              },
              {
                "key": "86819",
                "title": "86819 Intra-abdom inj NEC-open",
                "threedigit": "868",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Internal injury to unspecified or ill-defined organs",
            "title": "869 - Internal injury to unspecified or ill-defined organs",
            "threedigit": "869",
            "children": [
              {
                "key": "8690",
                "title": "8690 Internal inj NOS-closed",
                "threedigit": "869",
                "isLeaf": true
              },
              {
                "key": "8691",
                "title": "8691 Internal injury NOS-open",
                "threedigit": "869",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Open Wound Of Head, Neck, And Trunk",
        "title": "870-879 Open Wound Of Head, Neck, And Trunk",
        "threedigit": "870",
        "children": [
          {
            "key": "Open wound of ocular adnexa",
            "title": "870 - Open wound of ocular adnexa",
            "threedigit": "870",
            "children": [
              {
                "key": "8700",
                "title": "8700 Lac eyelid skn/perioculr",
                "threedigit": "870",
                "isLeaf": true
              },
              {
                "key": "8701",
                "title": "8701 Full-thicknes lac eyelid",
                "threedigit": "870",
                "isLeaf": true
              },
              {
                "key": "8702",
                "title": "8702 Lac eyelid inv lacrm pas",
                "threedigit": "870",
                "isLeaf": true
              },
              {
                "key": "8703",
                "title": "8703 Penetr wnd orbit w/o FB",
                "threedigit": "870",
                "isLeaf": true
              },
              {
                "key": "8704",
                "title": "8704 Penetrat wnd orbit w FB",
                "threedigit": "870",
                "isLeaf": true
              },
              {
                "key": "8708",
                "title": "8708 Opn wnd ocular adnex NEC",
                "threedigit": "870",
                "isLeaf": true
              },
              {
                "key": "8709",
                "title": "8709 Opn wnd ocular adnex NOS",
                "threedigit": "870",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of eyeball",
            "title": "871 - Open wound of eyeball",
            "threedigit": "871",
            "children": [
              {
                "key": "8710",
                "title": "8710 Ocular lac w/o prolapse",
                "threedigit": "871",
                "isLeaf": true
              },
              {
                "key": "8711",
                "title": "8711 Ocular lacera w prolapse",
                "threedigit": "871",
                "isLeaf": true
              },
              {
                "key": "8712",
                "title": "8712 Rupture eye w tissu loss",
                "threedigit": "871",
                "isLeaf": true
              },
              {
                "key": "8713",
                "title": "8713 Avulsion of eye",
                "threedigit": "871",
                "isLeaf": true
              },
              {
                "key": "8714",
                "title": "8714 Laceration of eye NOS",
                "threedigit": "871",
                "isLeaf": true
              },
              {
                "key": "8715",
                "title": "8715 Penetrat magnet FB eye",
                "threedigit": "871",
                "isLeaf": true
              },
              {
                "key": "8716",
                "title": "8716 Penetrat FB NEC eye",
                "threedigit": "871",
                "isLeaf": true
              },
              {
                "key": "8717",
                "title": "8717 Ocular penetration NOS",
                "threedigit": "871",
                "isLeaf": true
              },
              {
                "key": "8719",
                "title": "8719 Opn wound of eyeball NOS",
                "threedigit": "871",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of ear",
            "title": "872 - Open wound of ear",
            "threedigit": "872",
            "children": [
              {
                "key": "87200",
                "title": "87200 Opn wound extern ear NOS",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87201",
                "title": "87201 Open wound of auricle",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87202",
                "title": "87202 Opn wound auditory canal",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87210",
                "title": "87210 Opn wnd ex ear NOS-compl",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87211",
                "title": "87211 Open wound auricle-compl",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87212",
                "title": "87212 Open wnd aud canal-compl",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87261",
                "title": "87261 Open wound of ear drum",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87262",
                "title": "87262 Open wound of ossicles",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87263",
                "title": "87263 Open wnd eustachian tube",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87264",
                "title": "87264 Open wound of cochlea",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87269",
                "title": "87269 Open wound of ear NEC",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87271",
                "title": "87271 Open wnd ear drum-compl",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87272",
                "title": "87272 Open wnd ossicles-compl",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87273",
                "title": "87273 Opn wnd eustach tb-compl",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87274",
                "title": "87274 Open wound cochlea-compl",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "87279",
                "title": "87279 Open wound ear NEC-compl",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "8728",
                "title": "8728 Open wound of ear NOS",
                "threedigit": "872",
                "isLeaf": true
              },
              {
                "key": "8729",
                "title": "8729 Open wound ear NOS-compl",
                "threedigit": "872",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other open wound of head",
            "title": "873 - Other open wound of head",
            "threedigit": "873",
            "children": [
              {
                "key": "8730",
                "title": "8730 Open wound of scalp",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "8731",
                "title": "8731 Open wound scalp-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87320",
                "title": "87320 Open wound of nose NOS",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87321",
                "title": "87321 Open wound nasal septum",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87322",
                "title": "87322 Open wound nasal cavity",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87323",
                "title": "87323 Open wound nasal sinus",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87329",
                "title": "87329 Mult open wound nose",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87330",
                "title": "87330 Open wnd nose NOS-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87331",
                "title": "87331 Opn wnd nas septum-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87332",
                "title": "87332 Open wnd nasal cav-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87333",
                "title": "87333 Open wnd nas sinus-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87339",
                "title": "87339 Mult open wnd nose-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87340",
                "title": "87340 Open wound of face NOS",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87341",
                "title": "87341 Open wound of cheek",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87342",
                "title": "87342 Open wound of forehead",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87343",
                "title": "87343 Open wound of lip",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87344",
                "title": "87344 Open wound of jaw",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87349",
                "title": "87349 Open wound of face NEC",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87350",
                "title": "87350 Open wnd face NOS-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87351",
                "title": "87351 Open wound cheek-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87352",
                "title": "87352 Open wnd forehead-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87353",
                "title": "87353 Open wound lip-complicat",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87354",
                "title": "87354 Open wound jaw-complicat",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87359",
                "title": "87359 Open wnd face NEC-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87360",
                "title": "87360 Open wound of mouth NOS",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87361",
                "title": "87361 Open wound buccal mucosa",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87362",
                "title": "87362 Open wound of gum",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87363",
                "title": "87363 Broken tooth-uncomplic",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87364",
                "title": "87364 Opn wnd tongue/mouth flr",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87365",
                "title": "87365 Open wound of palate",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87369",
                "title": "87369 Open wound mouth NEC",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87370",
                "title": "87370 Open wnd mouth NOS-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87371",
                "title": "87371 Opn wnd buc mucosa-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87372",
                "title": "87372 Open wound gum-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87373",
                "title": "87373 Broken tooth-complicated",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87374",
                "title": "87374 Open wound tongue-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87375",
                "title": "87375 Open wound palate-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "87379",
                "title": "87379 Open wnd mouth NOS-compl",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "8738",
                "title": "8738 Open wound of head NEC",
                "threedigit": "873",
                "isLeaf": true
              },
              {
                "key": "8739",
                "title": "8739 Open wnd head NEC-compl",
                "threedigit": "873",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of neck",
            "title": "874 - Open wound of neck",
            "threedigit": "874",
            "children": [
              {
                "key": "87400",
                "title": "87400 Opn wnd larynx w trachea",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "87401",
                "title": "87401 Open wound of larynx",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "87402",
                "title": "87402 Open wound of trachea",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "87410",
                "title": "87410 Opn wnd lary w trac-comp",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "87411",
                "title": "87411 Open wound larynx-compl",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "87412",
                "title": "87412 Open wound trachea-compl",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "8742",
                "title": "8742 Open wound thyroid gland",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "8743",
                "title": "8743 Open wound thyroid-compl",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "8744",
                "title": "8744 Open wound of pharynx",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "8745",
                "title": "8745 Open wound pharynx-compl",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "8748",
                "title": "8748 Open wound of neck NEC",
                "threedigit": "874",
                "isLeaf": true
              },
              {
                "key": "8749",
                "title": "8749 Opn wound neck NEC-compl",
                "threedigit": "874",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of chest (wall)",
            "title": "875 - Open wound of chest (wall)",
            "threedigit": "875",
            "children": [
              {
                "key": "8750",
                "title": "8750 Open wound of chest",
                "threedigit": "875",
                "isLeaf": true
              },
              {
                "key": "8751",
                "title": "8751 Open wound chest-compl",
                "threedigit": "875",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of back",
            "title": "876 - Open wound of back",
            "threedigit": "876",
            "children": [
              {
                "key": "8760",
                "title": "8760 Open wound of back",
                "threedigit": "876",
                "isLeaf": true
              },
              {
                "key": "8761",
                "title": "8761 Open wound back-compl",
                "threedigit": "876",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of buttock",
            "title": "877 - Open wound of buttock",
            "threedigit": "877",
            "children": [
              {
                "key": "8770",
                "title": "8770 Open wound of buttock",
                "threedigit": "877",
                "isLeaf": true
              },
              {
                "key": "8771",
                "title": "8771 Open wound buttock-compl",
                "threedigit": "877",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of genital organs (external) including traumatic amputation",
            "title": "878 - Open wound of genital organs (external) including traumatic amputation",
            "threedigit": "878",
            "children": [
              {
                "key": "8780",
                "title": "8780 Open wound of penis",
                "threedigit": "878",
                "isLeaf": true
              },
              {
                "key": "8781",
                "title": "8781 Open wound penis-compl",
                "threedigit": "878",
                "isLeaf": true
              },
              {
                "key": "8782",
                "title": "8782 Opn wound scrotum/testes",
                "threedigit": "878",
                "isLeaf": true
              },
              {
                "key": "8783",
                "title": "8783 Opn wnd scrot/test-compl",
                "threedigit": "878",
                "isLeaf": true
              },
              {
                "key": "8784",
                "title": "8784 Open wound of vulva",
                "threedigit": "878",
                "isLeaf": true
              },
              {
                "key": "8785",
                "title": "8785 Open wound vulva-compl",
                "threedigit": "878",
                "isLeaf": true
              },
              {
                "key": "8786",
                "title": "8786 Open wound of vagina",
                "threedigit": "878",
                "isLeaf": true
              },
              {
                "key": "8787",
                "title": "8787 Open wound vagina-compl",
                "threedigit": "878",
                "isLeaf": true
              },
              {
                "key": "8788",
                "title": "8788 Open wound genital NEC",
                "threedigit": "878",
                "isLeaf": true
              },
              {
                "key": "8789",
                "title": "8789 Opn wnd genital NEC-comp",
                "threedigit": "878",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of other and unspecified sites except limbs",
            "title": "879 - Open wound of other and unspecified sites except limbs",
            "threedigit": "879",
            "children": [
              {
                "key": "8790",
                "title": "8790 Open wound of breast",
                "threedigit": "879",
                "isLeaf": true
              },
              {
                "key": "8791",
                "title": "8791 Open wound breast-compl",
                "threedigit": "879",
                "isLeaf": true
              },
              {
                "key": "8792",
                "title": "8792 Opn wnd anterior abdomen",
                "threedigit": "879",
                "isLeaf": true
              },
              {
                "key": "8793",
                "title": "8793 Opn wnd ant abdomen-comp",
                "threedigit": "879",
                "isLeaf": true
              },
              {
                "key": "8794",
                "title": "8794 Opn wnd lateral abdomen",
                "threedigit": "879",
                "isLeaf": true
              },
              {
                "key": "8795",
                "title": "8795 Opn wnd lat abdomen-comp",
                "threedigit": "879",
                "isLeaf": true
              },
              {
                "key": "8796",
                "title": "8796 Open wound of trunk NEC",
                "threedigit": "879",
                "isLeaf": true
              },
              {
                "key": "8797",
                "title": "8797 Open wnd trunk NEC-compl",
                "threedigit": "879",
                "isLeaf": true
              },
              {
                "key": "8798",
                "title": "8798 Open wound site NOS",
                "threedigit": "879",
                "isLeaf": true
              },
              {
                "key": "8799",
                "title": "8799 Opn wound site NOS-compl",
                "threedigit": "879",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Open Wound Of Upper Limb",
        "title": "880-887 Open Wound Of Upper Limb",
        "threedigit": "880",
        "children": [
          {
            "key": "Open wound of shoulder and upper arm",
            "title": "880 - Open wound of shoulder and upper arm",
            "threedigit": "880",
            "children": [
              {
                "key": "88000",
                "title": "88000 Open wound of shoulder",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88001",
                "title": "88001 Open wound of scapula",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88002",
                "title": "88002 Open wound of axilla",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88003",
                "title": "88003 Open wound of upper arm",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88009",
                "title": "88009 Mult open wound shoulder",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88010",
                "title": "88010 Open wnd shoulder-compl",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88011",
                "title": "88011 Open wound scapula-compl",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88012",
                "title": "88012 Open wound axilla-compl",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88013",
                "title": "88013 Open wnd upper arm-compl",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88019",
                "title": "88019 Mult opn wnd should-comp",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88020",
                "title": "88020 Opn wnd shouldr w tendon",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88021",
                "title": "88021 Opn wnd scapula w tendon",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88022",
                "title": "88022 Open wnd axilla w tendon",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88023",
                "title": "88023 Open wnd up arm w tendon",
                "threedigit": "880",
                "isLeaf": true
              },
              {
                "key": "88029",
                "title": "88029 Mlt opn wnd shldr w tend",
                "threedigit": "880",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of elbow forearm and wrist",
            "title": "881 - Open wound of elbow forearm and wrist",
            "threedigit": "881",
            "children": [
              {
                "key": "88100",
                "title": "88100 Open wound of forearm",
                "threedigit": "881",
                "isLeaf": true
              },
              {
                "key": "88101",
                "title": "88101 Open wound of elbow",
                "threedigit": "881",
                "isLeaf": true
              },
              {
                "key": "88102",
                "title": "88102 Open wound of wrist",
                "threedigit": "881",
                "isLeaf": true
              },
              {
                "key": "88110",
                "title": "88110 Open wound forearm-compl",
                "threedigit": "881",
                "isLeaf": true
              },
              {
                "key": "88111",
                "title": "88111 Open wound elbow-complic",
                "threedigit": "881",
                "isLeaf": true
              },
              {
                "key": "88112",
                "title": "88112 Open wound wrist-complic",
                "threedigit": "881",
                "isLeaf": true
              },
              {
                "key": "88120",
                "title": "88120 Open wnd forearm w tendn",
                "threedigit": "881",
                "isLeaf": true
              },
              {
                "key": "88121",
                "title": "88121 Opn wound elbow w tendon",
                "threedigit": "881",
                "isLeaf": true
              },
              {
                "key": "88122",
                "title": "88122 Opn wound wrist w tendon",
                "threedigit": "881",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of hand except finger(s) alone",
            "title": "882 - Open wound of hand except finger(s) alone",
            "threedigit": "882",
            "children": [
              {
                "key": "8820",
                "title": "8820 Open wound of hand",
                "threedigit": "882",
                "isLeaf": true
              },
              {
                "key": "8821",
                "title": "8821 Opn wound hand-complicat",
                "threedigit": "882",
                "isLeaf": true
              },
              {
                "key": "8822",
                "title": "8822 Open wound hand w tendon",
                "threedigit": "882",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of finger(s)",
            "title": "883 - Open wound of finger(s)",
            "threedigit": "883",
            "children": [
              {
                "key": "8830",
                "title": "8830 Open wound of finger",
                "threedigit": "883",
                "isLeaf": true
              },
              {
                "key": "8831",
                "title": "8831 Open wound finger-compl",
                "threedigit": "883",
                "isLeaf": true
              },
              {
                "key": "8832",
                "title": "8832 Open wnd finger w tendon",
                "threedigit": "883",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Multiple and unspecified open wound of upper limb",
            "title": "884 - Multiple and unspecified open wound of upper limb",
            "threedigit": "884",
            "children": [
              {
                "key": "8840",
                "title": "8840 Open wound arm mult/NOS",
                "threedigit": "884",
                "isLeaf": true
              },
              {
                "key": "8841",
                "title": "8841 Open wound arm NOS-compl",
                "threedigit": "884",
                "isLeaf": true
              },
              {
                "key": "8842",
                "title": "8842 Opn wnd arm NOS w tendon",
                "threedigit": "884",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Traumatic amputation of thumb (complete) (partial)",
            "title": "885 - Traumatic amputation of thumb (complete) (partial)",
            "threedigit": "885",
            "children": [
              {
                "key": "8850",
                "title": "8850 Amputation thumb",
                "threedigit": "885",
                "isLeaf": true
              },
              {
                "key": "8851",
                "title": "8851 Amputation thumb-compl",
                "threedigit": "885",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Traumatic amputation of other finger(s) (complete) (partial)",
            "title": "886 - Traumatic amputation of other finger(s) (complete) (partial)",
            "threedigit": "886",
            "children": [
              {
                "key": "8860",
                "title": "8860 Amputation finger",
                "threedigit": "886",
                "isLeaf": true
              },
              {
                "key": "8861",
                "title": "8861 Amputation finger-compl",
                "threedigit": "886",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Traumatic amputation of arm and hand (complete) (partial)",
            "title": "887 - Traumatic amputation of arm and hand (complete) (partial)",
            "threedigit": "887",
            "children": [
              {
                "key": "8870",
                "title": "8870 Amput below elb, unilat",
                "threedigit": "887",
                "isLeaf": true
              },
              {
                "key": "8871",
                "title": "8871 Amp below elb, unil-comp",
                "threedigit": "887",
                "isLeaf": true
              },
              {
                "key": "8872",
                "title": "8872 Amput abv elbow, unilat",
                "threedigit": "887",
                "isLeaf": true
              },
              {
                "key": "8873",
                "title": "8873 Amput abv elb, unil-comp",
                "threedigit": "887",
                "isLeaf": true
              },
              {
                "key": "8874",
                "title": "8874 Amputat arm, unilat NOS",
                "threedigit": "887",
                "isLeaf": true
              },
              {
                "key": "8875",
                "title": "8875 Amput arm, unil NOS-comp",
                "threedigit": "887",
                "isLeaf": true
              },
              {
                "key": "8876",
                "title": "8876 Amputation arm, bilat",
                "threedigit": "887",
                "isLeaf": true
              },
              {
                "key": "8877",
                "title": "8877 Amputat arm, bilat-compl",
                "threedigit": "887",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Open Wound Of Lower Limb",
        "title": "890-897 Open Wound Of Lower Limb",
        "threedigit": "890",
        "children": [
          {
            "key": "Open wound of hip and thigh",
            "title": "890 - Open wound of hip and thigh",
            "threedigit": "890",
            "children": [
              {
                "key": "8900",
                "title": "8900 Open wound of hip/thigh",
                "threedigit": "890",
                "isLeaf": true
              },
              {
                "key": "8901",
                "title": "8901 Open wnd hip/thigh-compl",
                "threedigit": "890",
                "isLeaf": true
              },
              {
                "key": "8902",
                "title": "8902 Opn wnd hip/thigh w tend",
                "threedigit": "890",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of knee leg (except thigh) and ankle",
            "title": "891 - Open wound of knee leg (except thigh) and ankle",
            "threedigit": "891",
            "children": [
              {
                "key": "8910",
                "title": "8910 Open wnd knee/leg/ankle",
                "threedigit": "891",
                "isLeaf": true
              },
              {
                "key": "8911",
                "title": "8911 Open wnd knee/leg-compl",
                "threedigit": "891",
                "isLeaf": true
              },
              {
                "key": "8912",
                "title": "8912 Opn wnd knee/leg w tendn",
                "threedigit": "891",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of foot except toe(s) alone",
            "title": "892 - Open wound of foot except toe(s) alone",
            "threedigit": "892",
            "children": [
              {
                "key": "8920",
                "title": "8920 Open wound of foot",
                "threedigit": "892",
                "isLeaf": true
              },
              {
                "key": "8921",
                "title": "8921 Open wound foot-compl",
                "threedigit": "892",
                "isLeaf": true
              },
              {
                "key": "8922",
                "title": "8922 Open wound foot w tendon",
                "threedigit": "892",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Open wound of toe(s)",
            "title": "893 - Open wound of toe(s)",
            "threedigit": "893",
            "children": [
              {
                "key": "8930",
                "title": "8930 Open wound of toe",
                "threedigit": "893",
                "isLeaf": true
              },
              {
                "key": "8931",
                "title": "8931 Open wound toe-compl",
                "threedigit": "893",
                "isLeaf": true
              },
              {
                "key": "8932",
                "title": "8932 Open wound toe w tendon",
                "threedigit": "893",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Multiple and unspecified open wound of lower limb",
            "title": "894 - Multiple and unspecified open wound of lower limb",
            "threedigit": "894",
            "children": [
              {
                "key": "8940",
                "title": "8940 Open wound of leg NEC",
                "threedigit": "894",
                "isLeaf": true
              },
              {
                "key": "8941",
                "title": "8941 Open wound leg NEC-compl",
                "threedigit": "894",
                "isLeaf": true
              },
              {
                "key": "8942",
                "title": "8942 Opn wnd leg NEC w tendon",
                "threedigit": "894",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Traumatic amputation of toe(s) (complete) (partial)",
            "title": "895 - Traumatic amputation of toe(s) (complete) (partial)",
            "threedigit": "895",
            "children": [
              {
                "key": "8950",
                "title": "8950 Amputation toe",
                "threedigit": "895",
                "isLeaf": true
              },
              {
                "key": "8951",
                "title": "8951 Amputation toe-complicat",
                "threedigit": "895",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Traumatic amputation of foot (complete) (partial)",
            "title": "896 - Traumatic amputation of foot (complete) (partial)",
            "threedigit": "896",
            "children": [
              {
                "key": "8960",
                "title": "8960 Amputation foot, unilat",
                "threedigit": "896",
                "isLeaf": true
              },
              {
                "key": "8961",
                "title": "8961 Amput foot, unilat-compl",
                "threedigit": "896",
                "isLeaf": true
              },
              {
                "key": "8962",
                "title": "8962 Amputation foot, bilat",
                "threedigit": "896",
                "isLeaf": true
              },
              {
                "key": "8963",
                "title": "8963 Amputat foot, bilat-comp",
                "threedigit": "896",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Traumatic amputation of leg(s) (complete) (partial)",
            "title": "897 - Traumatic amputation of leg(s) (complete) (partial)",
            "threedigit": "897",
            "children": [
              {
                "key": "8970",
                "title": "8970 Amput below knee, unilat",
                "threedigit": "897",
                "isLeaf": true
              },
              {
                "key": "8971",
                "title": "8971 Amputat bk, unilat-compl",
                "threedigit": "897",
                "isLeaf": true
              },
              {
                "key": "8972",
                "title": "8972 Amput above knee, unilat",
                "threedigit": "897",
                "isLeaf": true
              },
              {
                "key": "8973",
                "title": "8973 Amput abv kn, unil-compl",
                "threedigit": "897",
                "isLeaf": true
              },
              {
                "key": "8974",
                "title": "8974 Amputat leg, unilat NOS",
                "threedigit": "897",
                "isLeaf": true
              },
              {
                "key": "8975",
                "title": "8975 Amput leg, unil NOS-comp",
                "threedigit": "897",
                "isLeaf": true
              },
              {
                "key": "8976",
                "title": "8976 Amputation leg, bilat",
                "threedigit": "897",
                "isLeaf": true
              },
              {
                "key": "8977",
                "title": "8977 Amputat leg, bilat-compl",
                "threedigit": "897",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Injury To Blood Vessels",
        "title": "900-904 Injury To Blood Vessels",
        "threedigit": "900",
        "children": [
          {
            "key": "Injury to blood vessels of head and neck",
            "title": "900 - Injury to blood vessels of head and neck",
            "threedigit": "900",
            "children": [
              {
                "key": "90000",
                "title": "90000 Injur carotid artery NOS",
                "threedigit": "900",
                "isLeaf": true
              },
              {
                "key": "90001",
                "title": "90001 Inj common carotid arter",
                "threedigit": "900",
                "isLeaf": true
              },
              {
                "key": "90002",
                "title": "90002 Inj external carotid art",
                "threedigit": "900",
                "isLeaf": true
              },
              {
                "key": "90003",
                "title": "90003 Inj internal carotid art",
                "threedigit": "900",
                "isLeaf": true
              },
              {
                "key": "9001",
                "title": "9001 Inj internl jugular vein",
                "threedigit": "900",
                "isLeaf": true
              },
              {
                "key": "90081",
                "title": "90081 Inj extern jugular vein",
                "threedigit": "900",
                "isLeaf": true
              },
              {
                "key": "90082",
                "title": "90082 Inj mlt head/neck vessel",
                "threedigit": "900",
                "isLeaf": true
              },
              {
                "key": "90089",
                "title": "90089 Inj head/neck vessel NEC",
                "threedigit": "900",
                "isLeaf": true
              },
              {
                "key": "9009",
                "title": "9009 Inj head/neck vessel NOS",
                "threedigit": "900",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to blood vessels of thorax",
            "title": "901 - Injury to blood vessels of thorax",
            "threedigit": "901",
            "children": [
              {
                "key": "9010",
                "title": "9010 Injury thoracic aorta",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "9011",
                "title": "9011 Inj innomin/subclav art",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "9012",
                "title": "9012 Inj superior vena cava",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "9013",
                "title": "9013 Inj innomin/subclav vein",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "90140",
                "title": "90140 Inj pulmonary vessel NOS",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "90141",
                "title": "90141 Injury pulmonary artery",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "90142",
                "title": "90142 Injury pulmonary vein",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "90181",
                "title": "90181 Inj intercostal art/vein",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "90182",
                "title": "90182 Inj int mammary art/vein",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "90183",
                "title": "90183 Inj mult thoracic vessel",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "90189",
                "title": "90189 Inj thoracic vessel NEC",
                "threedigit": "901",
                "isLeaf": true
              },
              {
                "key": "9019",
                "title": "9019 Inj thoracic vessel NOS",
                "threedigit": "901",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to blood vessels of abdomen and pelvis",
            "title": "902 - Injury to blood vessels of abdomen and pelvis",
            "threedigit": "902",
            "children": [
              {
                "key": "9020",
                "title": "9020 Injury abdominal aorta",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90210",
                "title": "90210 Inj infer vena cava NOS",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90211",
                "title": "90211 Injury hepatic veins",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90219",
                "title": "90219 Inj infer vena cava NEC",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90220",
                "title": "90220 Inj celiac/mesen art NOS",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90221",
                "title": "90221 Injury gastric artery",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90222",
                "title": "90222 Injury hepatic artery",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90223",
                "title": "90223 Injury splenic artery",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90224",
                "title": "90224 Injury celiac axis NEC",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90225",
                "title": "90225 Inj super mesenteric art",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90226",
                "title": "90226 Inj brnch sup mesent art",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90227",
                "title": "90227 Inj infer mesenteric art",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90229",
                "title": "90229 Inj mesenteric vess NEC",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90231",
                "title": "90231 Inj superior mesent vein",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90232",
                "title": "90232 Inj inferior mesent vein",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90233",
                "title": "90233 Injury portal vein",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90234",
                "title": "90234 Injury splenic vein",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90239",
                "title": "90239 Inj port/splen vess NEC",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90240",
                "title": "90240 Injury renal vessel NOS",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90241",
                "title": "90241 Injury renal artery",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90242",
                "title": "90242 Injury renal vein",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90249",
                "title": "90249 Injury renal vessel NEC",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90250",
                "title": "90250 Injury iliac vessel NOS",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90251",
                "title": "90251 Inj hypogastric artery",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90252",
                "title": "90252 Injury hypogastric vein",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90253",
                "title": "90253 Injury iliac artery",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90254",
                "title": "90254 Injury iliac vein",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90255",
                "title": "90255 Injury uterine artery",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90256",
                "title": "90256 Injury uterine vein",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90259",
                "title": "90259 Injury iliac vessel NEC",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90281",
                "title": "90281 Injury ovarian artery",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90282",
                "title": "90282 Injury ovarian vein",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90287",
                "title": "90287 Inj mult abd/pelv vessel",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "90289",
                "title": "90289 Inj abdominal vessel NEC",
                "threedigit": "902",
                "isLeaf": true
              },
              {
                "key": "9029",
                "title": "9029 Inj abdominal vessel NOS",
                "threedigit": "902",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to blood vessels of upper extremity",
            "title": "903 - Injury to blood vessels of upper extremity",
            "threedigit": "903",
            "children": [
              {
                "key": "90300",
                "title": "90300 Inj axillary vessel NOS",
                "threedigit": "903",
                "isLeaf": true
              },
              {
                "key": "90301",
                "title": "90301 Injury axillary artery",
                "threedigit": "903",
                "isLeaf": true
              },
              {
                "key": "90302",
                "title": "90302 Injury axillary vein",
                "threedigit": "903",
                "isLeaf": true
              },
              {
                "key": "9031",
                "title": "9031 Injury brachial vessels",
                "threedigit": "903",
                "isLeaf": true
              },
              {
                "key": "9032",
                "title": "9032 Injury radial vessels",
                "threedigit": "903",
                "isLeaf": true
              },
              {
                "key": "9033",
                "title": "9033 Injury ulnar vessels",
                "threedigit": "903",
                "isLeaf": true
              },
              {
                "key": "9034",
                "title": "9034 Injury palmar artery",
                "threedigit": "903",
                "isLeaf": true
              },
              {
                "key": "9035",
                "title": "9035 Injury finger vessels",
                "threedigit": "903",
                "isLeaf": true
              },
              {
                "key": "9038",
                "title": "9038 Injury arm vessels NEC",
                "threedigit": "903",
                "isLeaf": true
              },
              {
                "key": "9039",
                "title": "9039 Injury arm vessel NOS",
                "threedigit": "903",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to blood vessels of lower extremity and unspecified sites",
            "title": "904 - Injury to blood vessels of lower extremity and unspecified sites",
            "threedigit": "904",
            "children": [
              {
                "key": "9040",
                "title": "9040 Inj common femoral arter",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "9041",
                "title": "9041 Inj superfic femoral art",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "9042",
                "title": "9042 Injury femoral vein",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "9043",
                "title": "9043 Injury saphenous vein",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "90440",
                "title": "90440 Inj popliteal vessel NOS",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "90441",
                "title": "90441 Injury popliteal artery",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "90442",
                "title": "90442 Injury popliteal vein",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "90450",
                "title": "90450 Injury tibial vessel NOS",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "90451",
                "title": "90451 Inj anter tibial artery",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "90452",
                "title": "90452 Inj anterior tibial vein",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "90453",
                "title": "90453 Inj post tibial artery",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "90454",
                "title": "90454 Inj post tibial vein",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "9046",
                "title": "9046 Inj deep plantar vessel",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "9047",
                "title": "9047 Injury leg vessels NEC",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "9048",
                "title": "9048 Injury leg vessel NOS",
                "threedigit": "904",
                "isLeaf": true
              },
              {
                "key": "9049",
                "title": "9049 Blood vessel injury NOS",
                "threedigit": "904",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Late Effects Of Injuries, Poisonings, Toxic Effects, And Other External Causes",
        "title": "905-909 Late Effects Of Injuries, Poisonings, Toxic Effects, And Other External Causes",
        "threedigit": "905",
        "children": [
          {
            "key": "Late effects of musculoskeletal and connective tissue injuries",
            "title": "905 - Late effects of musculoskeletal and connective tissue injuries",
            "threedigit": "905",
            "children": [
              {
                "key": "9050",
                "title": "9050 Late effec skull/face fx",
                "threedigit": "905",
                "isLeaf": true
              },
              {
                "key": "9051",
                "title": "9051 Late eff spine/trunk fx",
                "threedigit": "905",
                "isLeaf": true
              },
              {
                "key": "9052",
                "title": "9052 Late effect arm fx",
                "threedigit": "905",
                "isLeaf": true
              },
              {
                "key": "9053",
                "title": "9053 Late eff femoral neck fx",
                "threedigit": "905",
                "isLeaf": true
              },
              {
                "key": "9054",
                "title": "9054 Late effect leg fx",
                "threedigit": "905",
                "isLeaf": true
              },
              {
                "key": "9055",
                "title": "9055 Late effect fracture NEC",
                "threedigit": "905",
                "isLeaf": true
              },
              {
                "key": "9056",
                "title": "9056 Late effect dislocation",
                "threedigit": "905",
                "isLeaf": true
              },
              {
                "key": "9057",
                "title": "9057 Late effec sprain/strain",
                "threedigit": "905",
                "isLeaf": true
              },
              {
                "key": "9058",
                "title": "9058 Late effec tendon injury",
                "threedigit": "905",
                "isLeaf": true
              },
              {
                "key": "9059",
                "title": "9059 Late eff traumat amputat",
                "threedigit": "905",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of injuries to skin and subcutaneous tissues",
            "title": "906 - Late effects of injuries to skin and subcutaneous tissues",
            "threedigit": "906",
            "children": [
              {
                "key": "9060",
                "title": "9060 Lt eff opn wnd head/trnk",
                "threedigit": "906",
                "isLeaf": true
              },
              {
                "key": "9061",
                "title": "9061 Late eff open wnd extrem",
                "threedigit": "906",
                "isLeaf": true
              },
              {
                "key": "9062",
                "title": "9062 Late eff superficial inj",
                "threedigit": "906",
                "isLeaf": true
              },
              {
                "key": "9063",
                "title": "9063 Late effect of contusion",
                "threedigit": "906",
                "isLeaf": true
              },
              {
                "key": "9064",
                "title": "9064 Late effect of crushing",
                "threedigit": "906",
                "isLeaf": true
              },
              {
                "key": "9065",
                "title": "9065 Late eff head/neck burn",
                "threedigit": "906",
                "isLeaf": true
              },
              {
                "key": "9066",
                "title": "9066 Late eff wrist/hand burn",
                "threedigit": "906",
                "isLeaf": true
              },
              {
                "key": "9067",
                "title": "9067 Late eff burn extrem NEC",
                "threedigit": "906",
                "isLeaf": true
              },
              {
                "key": "9068",
                "title": "9068 Late effect of burns NEC",
                "threedigit": "906",
                "isLeaf": true
              },
              {
                "key": "9069",
                "title": "9069 Late effect of burn NOS",
                "threedigit": "906",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of injuries to the nervous system",
            "title": "907 - Late effects of injuries to the nervous system",
            "threedigit": "907",
            "children": [
              {
                "key": "9070",
                "title": "9070 Lt eff intracranial inj",
                "threedigit": "907",
                "isLeaf": true
              },
              {
                "key": "9071",
                "title": "9071 Late eff cran nerve inj",
                "threedigit": "907",
                "isLeaf": true
              },
              {
                "key": "9072",
                "title": "9072 Late eff spinal cord inj",
                "threedigit": "907",
                "isLeaf": true
              },
              {
                "key": "9073",
                "title": "9073 Lt eff nerv inj trnk NEC",
                "threedigit": "907",
                "isLeaf": true
              },
              {
                "key": "9074",
                "title": "9074 Lt eff nerv inj shld/arm",
                "threedigit": "907",
                "isLeaf": true
              },
              {
                "key": "9075",
                "title": "9075 Lt eff nerv inj pelv/leg",
                "threedigit": "907",
                "isLeaf": true
              },
              {
                "key": "9079",
                "title": "9079 Late eff nerve inj NEC",
                "threedigit": "907",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of other and unspecified injuries",
            "title": "908 - Late effects of other and unspecified injuries",
            "threedigit": "908",
            "children": [
              {
                "key": "9080",
                "title": "9080 Late eff int injur chest",
                "threedigit": "908",
                "isLeaf": true
              },
              {
                "key": "9081",
                "title": "9081 Late eff int inj abdomen",
                "threedigit": "908",
                "isLeaf": true
              },
              {
                "key": "9082",
                "title": "9082 Late eff int injury NEC",
                "threedigit": "908",
                "isLeaf": true
              },
              {
                "key": "9083",
                "title": "9083 Late eff inj periph vess",
                "threedigit": "908",
                "isLeaf": true
              },
              {
                "key": "9084",
                "title": "9084 Lt eff inj thor/abd vess",
                "threedigit": "908",
                "isLeaf": true
              },
              {
                "key": "9085",
                "title": "9085 Late eff FB in orifice",
                "threedigit": "908",
                "isLeaf": true
              },
              {
                "key": "9086",
                "title": "9086 Late eff complic trauma",
                "threedigit": "908",
                "isLeaf": true
              },
              {
                "key": "9089",
                "title": "9089 Late effect injury NOS",
                "threedigit": "908",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of other and unspecified external causes",
            "title": "909 - Late effects of other and unspecified external causes",
            "threedigit": "909",
            "children": [
              {
                "key": "9090",
                "title": "9090 Late eff drug poisoning",
                "threedigit": "909",
                "isLeaf": true
              },
              {
                "key": "9091",
                "title": "9091 Late eff nonmed substanc",
                "threedigit": "909",
                "isLeaf": true
              },
              {
                "key": "9092",
                "title": "9092 Late effect of radiation",
                "threedigit": "909",
                "isLeaf": true
              },
              {
                "key": "9093",
                "title": "9093 Late eff surg/med compl",
                "threedigit": "909",
                "isLeaf": true
              },
              {
                "key": "9094",
                "title": "9094 Late eff cert ext cause",
                "threedigit": "909",
                "isLeaf": true
              },
              {
                "key": "9095",
                "title": "9095 Lte efct advrs efct drug",
                "threedigit": "909",
                "isLeaf": true
              },
              {
                "key": "9099",
                "title": "9099 Late eff exter cause NEC",
                "threedigit": "909",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Superficial Injury",
        "title": "910-919 Superficial Injury",
        "threedigit": "910",
        "children": [
          {
            "key": "Superficial injury of face neck and scalp except eye",
            "title": "910 - Superficial injury of face neck and scalp except eye",
            "threedigit": "910",
            "children": [
              {
                "key": "9100",
                "title": "9100 Abrasion head",
                "threedigit": "910",
                "isLeaf": true
              },
              {
                "key": "9101",
                "title": "9101 Abrasion head-infected",
                "threedigit": "910",
                "isLeaf": true
              },
              {
                "key": "9102",
                "title": "9102 Blister head",
                "threedigit": "910",
                "isLeaf": true
              },
              {
                "key": "9103",
                "title": "9103 Blister head-infected",
                "threedigit": "910",
                "isLeaf": true
              },
              {
                "key": "9104",
                "title": "9104 Insect bite head",
                "threedigit": "910",
                "isLeaf": true
              },
              {
                "key": "9105",
                "title": "9105 Insect bite head-infect",
                "threedigit": "910",
                "isLeaf": true
              },
              {
                "key": "9106",
                "title": "9106 Foreign body head",
                "threedigit": "910",
                "isLeaf": true
              },
              {
                "key": "9107",
                "title": "9107 Foreign body head-infect",
                "threedigit": "910",
                "isLeaf": true
              },
              {
                "key": "9108",
                "title": "9108 Superfic inj head NEC",
                "threedigit": "910",
                "isLeaf": true
              },
              {
                "key": "9109",
                "title": "9109 Superf inj head NEC-inf",
                "threedigit": "910",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Superficial injury of trunk",
            "title": "911 - Superficial injury of trunk",
            "threedigit": "911",
            "children": [
              {
                "key": "9110",
                "title": "9110 Abrasion trunk",
                "threedigit": "911",
                "isLeaf": true
              },
              {
                "key": "9111",
                "title": "9111 Abrasion trunk-infected",
                "threedigit": "911",
                "isLeaf": true
              },
              {
                "key": "9112",
                "title": "9112 Blister trunk",
                "threedigit": "911",
                "isLeaf": true
              },
              {
                "key": "9113",
                "title": "9113 Blister trunk-infected",
                "threedigit": "911",
                "isLeaf": true
              },
              {
                "key": "9114",
                "title": "9114 Insect bite trunk",
                "threedigit": "911",
                "isLeaf": true
              },
              {
                "key": "9115",
                "title": "9115 Insect bite trunk-infec",
                "threedigit": "911",
                "isLeaf": true
              },
              {
                "key": "9116",
                "title": "9116 Foreign body trunk",
                "threedigit": "911",
                "isLeaf": true
              },
              {
                "key": "9117",
                "title": "9117 Foreign body trunk-infec",
                "threedigit": "911",
                "isLeaf": true
              },
              {
                "key": "9118",
                "title": "9118 Superfic inj trunk NEC",
                "threedigit": "911",
                "isLeaf": true
              },
              {
                "key": "9119",
                "title": "9119 Superf inj trnk NEC-inf",
                "threedigit": "911",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Superficial injury of shoulder and upper arm",
            "title": "912 - Superficial injury of shoulder and upper arm",
            "threedigit": "912",
            "children": [
              {
                "key": "9120",
                "title": "9120 Abrasion shoulder/arm",
                "threedigit": "912",
                "isLeaf": true
              },
              {
                "key": "9121",
                "title": "9121 Abrasion shldr/arm-infec",
                "threedigit": "912",
                "isLeaf": true
              },
              {
                "key": "9122",
                "title": "9122 Blister shoulder & arm",
                "threedigit": "912",
                "isLeaf": true
              },
              {
                "key": "9123",
                "title": "9123 Blister shoulder/arm-inf",
                "threedigit": "912",
                "isLeaf": true
              },
              {
                "key": "9124",
                "title": "9124 Insect bite shoulder/arm",
                "threedigit": "912",
                "isLeaf": true
              },
              {
                "key": "9125",
                "title": "9125 Insect bite shld/arm-inf",
                "threedigit": "912",
                "isLeaf": true
              },
              {
                "key": "9126",
                "title": "9126 Foreign body shouldr/arm",
                "threedigit": "912",
                "isLeaf": true
              },
              {
                "key": "9127",
                "title": "9127 FB shoulder/arm-infect",
                "threedigit": "912",
                "isLeaf": true
              },
              {
                "key": "9128",
                "title": "9128 Superf inj shldr/arm NEC",
                "threedigit": "912",
                "isLeaf": true
              },
              {
                "key": "9129",
                "title": "9129 Superf inj shldr NEC-inf",
                "threedigit": "912",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Superficial injury of elbow forearm and wrist",
            "title": "913 - Superficial injury of elbow forearm and wrist",
            "threedigit": "913",
            "children": [
              {
                "key": "9130",
                "title": "9130 Abrasion forearm",
                "threedigit": "913",
                "isLeaf": true
              },
              {
                "key": "9131",
                "title": "9131 Abrasion forearm-infect",
                "threedigit": "913",
                "isLeaf": true
              },
              {
                "key": "9132",
                "title": "9132 Blister forearm",
                "threedigit": "913",
                "isLeaf": true
              },
              {
                "key": "9133",
                "title": "9133 Blister forearm-infected",
                "threedigit": "913",
                "isLeaf": true
              },
              {
                "key": "9134",
                "title": "9134 Insect bite forearm",
                "threedigit": "913",
                "isLeaf": true
              },
              {
                "key": "9135",
                "title": "9135 Insect bite forearm-inf",
                "threedigit": "913",
                "isLeaf": true
              },
              {
                "key": "9136",
                "title": "9136 Foreign body forearm",
                "threedigit": "913",
                "isLeaf": true
              },
              {
                "key": "9137",
                "title": "9137 Foreign body forearm-inf",
                "threedigit": "913",
                "isLeaf": true
              },
              {
                "key": "9138",
                "title": "9138 Superf inj forearm NEC",
                "threedigit": "913",
                "isLeaf": true
              },
              {
                "key": "9139",
                "title": "9139 Suprf inj forarm NEC-inf",
                "threedigit": "913",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Superficial injury of hand(s) except finger(s) alone",
            "title": "914 - Superficial injury of hand(s) except finger(s) alone",
            "threedigit": "914",
            "children": [
              {
                "key": "9140",
                "title": "9140 Abrasion hand",
                "threedigit": "914",
                "isLeaf": true
              },
              {
                "key": "9141",
                "title": "9141 Abrasion hand-infected",
                "threedigit": "914",
                "isLeaf": true
              },
              {
                "key": "9142",
                "title": "9142 Blister hand",
                "threedigit": "914",
                "isLeaf": true
              },
              {
                "key": "9143",
                "title": "9143 Blister hand-infected",
                "threedigit": "914",
                "isLeaf": true
              },
              {
                "key": "9144",
                "title": "9144 Insect bite hand",
                "threedigit": "914",
                "isLeaf": true
              },
              {
                "key": "9145",
                "title": "9145 Insect bite hand-infect",
                "threedigit": "914",
                "isLeaf": true
              },
              {
                "key": "9146",
                "title": "9146 Foreign body hand",
                "threedigit": "914",
                "isLeaf": true
              },
              {
                "key": "9147",
                "title": "9147 Foreign body hand-infect",
                "threedigit": "914",
                "isLeaf": true
              },
              {
                "key": "9148",
                "title": "9148 Superficial inj hand NEC",
                "threedigit": "914",
                "isLeaf": true
              },
              {
                "key": "9149",
                "title": "9149 Superf inj hand NEC-inf",
                "threedigit": "914",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Superficial injury of finger(s)",
            "title": "915 - Superficial injury of finger(s)",
            "threedigit": "915",
            "children": [
              {
                "key": "9150",
                "title": "9150 Abrasion finger",
                "threedigit": "915",
                "isLeaf": true
              },
              {
                "key": "9151",
                "title": "9151 Abrasion finger-infected",
                "threedigit": "915",
                "isLeaf": true
              },
              {
                "key": "9152",
                "title": "9152 Blister finger",
                "threedigit": "915",
                "isLeaf": true
              },
              {
                "key": "9153",
                "title": "9153 Blister finger-infected",
                "threedigit": "915",
                "isLeaf": true
              },
              {
                "key": "9154",
                "title": "9154 Insect bite finger",
                "threedigit": "915",
                "isLeaf": true
              },
              {
                "key": "9155",
                "title": "9155 Insect bite finger-infec",
                "threedigit": "915",
                "isLeaf": true
              },
              {
                "key": "9156",
                "title": "9156 Foreign body finger",
                "threedigit": "915",
                "isLeaf": true
              },
              {
                "key": "9157",
                "title": "9157 Foreign body finger-inf",
                "threedigit": "915",
                "isLeaf": true
              },
              {
                "key": "9158",
                "title": "9158 Superfic inj finger-NEC",
                "threedigit": "915",
                "isLeaf": true
              },
              {
                "key": "9159",
                "title": "9159 Suprf inj finger NEC-inf",
                "threedigit": "915",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Superficial injury of hip thigh leg and ankle",
            "title": "916 - Superficial injury of hip thigh leg and ankle",
            "threedigit": "916",
            "children": [
              {
                "key": "9160",
                "title": "9160 Abrasion hip & leg",
                "threedigit": "916",
                "isLeaf": true
              },
              {
                "key": "9161",
                "title": "9161 Abrasion hip/leg-infect",
                "threedigit": "916",
                "isLeaf": true
              },
              {
                "key": "9162",
                "title": "9162 Blister hip & leg",
                "threedigit": "916",
                "isLeaf": true
              },
              {
                "key": "9163",
                "title": "9163 Blister hip & leg-infect",
                "threedigit": "916",
                "isLeaf": true
              },
              {
                "key": "9164",
                "title": "9164 Insect bite hip & leg",
                "threedigit": "916",
                "isLeaf": true
              },
              {
                "key": "9165",
                "title": "9165 Insect bite hip/leg-inf",
                "threedigit": "916",
                "isLeaf": true
              },
              {
                "key": "9166",
                "title": "9166 Foreign body hip/leg",
                "threedigit": "916",
                "isLeaf": true
              },
              {
                "key": "9167",
                "title": "9167 Foreign bdy hip/leg-inf",
                "threedigit": "916",
                "isLeaf": true
              },
              {
                "key": "9168",
                "title": "9168 Superfic inj hip/leg NEC",
                "threedigit": "916",
                "isLeaf": true
              },
              {
                "key": "9169",
                "title": "9169 Superf inj leg NEC-infec",
                "threedigit": "916",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Superficial injury of foot and toe(s)",
            "title": "917 - Superficial injury of foot and toe(s)",
            "threedigit": "917",
            "children": [
              {
                "key": "9170",
                "title": "9170 Abrasion foot & toe",
                "threedigit": "917",
                "isLeaf": true
              },
              {
                "key": "9171",
                "title": "9171 Abrasion foot/toe-infec",
                "threedigit": "917",
                "isLeaf": true
              },
              {
                "key": "9172",
                "title": "9172 Blister foot & toe",
                "threedigit": "917",
                "isLeaf": true
              },
              {
                "key": "9173",
                "title": "9173 Blister foot & toe-infec",
                "threedigit": "917",
                "isLeaf": true
              },
              {
                "key": "9174",
                "title": "9174 Insect bite foot/toe",
                "threedigit": "917",
                "isLeaf": true
              },
              {
                "key": "9175",
                "title": "9175 Insect bite foot/toe-inf",
                "threedigit": "917",
                "isLeaf": true
              },
              {
                "key": "9176",
                "title": "9176 Foreign body foot & toe",
                "threedigit": "917",
                "isLeaf": true
              },
              {
                "key": "9177",
                "title": "9177 Foreign bdy foot/toe-inf",
                "threedigit": "917",
                "isLeaf": true
              },
              {
                "key": "9178",
                "title": "9178 Superf inj foot/toe NEC",
                "threedigit": "917",
                "isLeaf": true
              },
              {
                "key": "9179",
                "title": "9179 Superf inj foot NEC-inf",
                "threedigit": "917",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Superficial injury of eye and adnexa",
            "title": "918 - Superficial injury of eye and adnexa",
            "threedigit": "918",
            "children": [
              {
                "key": "9180",
                "title": "9180 Superfic inj periocular",
                "threedigit": "918",
                "isLeaf": true
              },
              {
                "key": "9181",
                "title": "9181 Superficial inj cornea",
                "threedigit": "918",
                "isLeaf": true
              },
              {
                "key": "9182",
                "title": "9182 Superfic inj conjunctiva",
                "threedigit": "918",
                "isLeaf": true
              },
              {
                "key": "9189",
                "title": "9189 Superficial inj eye NEC",
                "threedigit": "918",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Superficial injury of other multiple and unspecified sites",
            "title": "919 - Superficial injury of other multiple and unspecified sites",
            "threedigit": "919",
            "children": [
              {
                "key": "9190",
                "title": "9190 Abrasion NEC",
                "threedigit": "919",
                "isLeaf": true
              },
              {
                "key": "9191",
                "title": "9191 Abrasion NEC-infected",
                "threedigit": "919",
                "isLeaf": true
              },
              {
                "key": "9192",
                "title": "9192 Blister NEC",
                "threedigit": "919",
                "isLeaf": true
              },
              {
                "key": "9193",
                "title": "9193 Blister NEC-infected",
                "threedigit": "919",
                "isLeaf": true
              },
              {
                "key": "9194",
                "title": "9194 Insect bite NEC",
                "threedigit": "919",
                "isLeaf": true
              },
              {
                "key": "9195",
                "title": "9195 Insect bite NEC-infected",
                "threedigit": "919",
                "isLeaf": true
              },
              {
                "key": "9196",
                "title": "9196 Superfic foreign bdy NEC",
                "threedigit": "919",
                "isLeaf": true
              },
              {
                "key": "9197",
                "title": "9197 Superficial FB NEC-infec",
                "threedigit": "919",
                "isLeaf": true
              },
              {
                "key": "9198",
                "title": "9198 Superficial injury NEC",
                "threedigit": "919",
                "isLeaf": true
              },
              {
                "key": "9199",
                "title": "9199 Superfic inj NEC-infect",
                "threedigit": "919",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Contusion With Intact Skin Surface",
        "title": "920-924 Contusion With Intact Skin Surface",
        "threedigit": "920",
        "children": [
          {
            "key": "Contusion of face, scalp, and neck except eye(s)",
            "title": "920 - Contusion of face, scalp, and neck except eye(s)",
            "threedigit": "920",
            "children": [
              {
                "key": "920",
                "title": "920 Contusion face/scalp/nck",
                "threedigit": "920",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Contusion of eye and adnexa",
            "title": "921 - Contusion of eye and adnexa",
            "threedigit": "921",
            "children": [
              {
                "key": "9210",
                "title": "9210 Black eye NOS",
                "threedigit": "921",
                "isLeaf": true
              },
              {
                "key": "9211",
                "title": "9211 Contusion periocular",
                "threedigit": "921",
                "isLeaf": true
              },
              {
                "key": "9212",
                "title": "9212 Contusion orbital tissue",
                "threedigit": "921",
                "isLeaf": true
              },
              {
                "key": "9213",
                "title": "9213 Contusion of eyeball",
                "threedigit": "921",
                "isLeaf": true
              },
              {
                "key": "9219",
                "title": "9219 Contusion of eye NOS",
                "threedigit": "921",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Contusion of trunk",
            "title": "922 - Contusion of trunk",
            "threedigit": "922",
            "children": [
              {
                "key": "9220",
                "title": "9220 Contusion of breast",
                "threedigit": "922",
                "isLeaf": true
              },
              {
                "key": "9221",
                "title": "9221 Contusion of chest wall",
                "threedigit": "922",
                "isLeaf": true
              },
              {
                "key": "9222",
                "title": "9222 Contusion abdominal wall",
                "threedigit": "922",
                "isLeaf": true
              },
              {
                "key": "92231",
                "title": "92231 Back contusion",
                "threedigit": "922",
                "isLeaf": true
              },
              {
                "key": "92232",
                "title": "92232 Buttock contusion",
                "threedigit": "922",
                "isLeaf": true
              },
              {
                "key": "92233",
                "title": "92233 Interscplr reg contusion",
                "threedigit": "922",
                "isLeaf": true
              },
              {
                "key": "9224",
                "title": "9224 Contusion genital organs",
                "threedigit": "922",
                "isLeaf": true
              },
              {
                "key": "9228",
                "title": "9228 Multiple contusion trunk",
                "threedigit": "922",
                "isLeaf": true
              },
              {
                "key": "9229",
                "title": "9229 Contusion trunk NOS",
                "threedigit": "922",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Contusion of upper limb",
            "title": "923 - Contusion of upper limb",
            "threedigit": "923",
            "children": [
              {
                "key": "92300",
                "title": "92300 Contusion shoulder reg",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "92301",
                "title": "92301 Contusion scapul region",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "92302",
                "title": "92302 Contusion axillary reg",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "92303",
                "title": "92303 Contusion of upper arm",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "92309",
                "title": "92309 Contusion shoulder & arm",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "92310",
                "title": "92310 Contusion of forearm",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "92311",
                "title": "92311 Contusion of elbow",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "92320",
                "title": "92320 Contusion of hand(s)",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "92321",
                "title": "92321 Contusion of wrist",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "9233",
                "title": "9233 Contusion of finger",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "9238",
                "title": "9238 Multiple contusion arm",
                "threedigit": "923",
                "isLeaf": true
              },
              {
                "key": "9239",
                "title": "9239 Contusion upper limb NOS",
                "threedigit": "923",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Contusion of lower limb and of other and unspecified sites",
            "title": "924 - Contusion of lower limb and of other and unspecified sites",
            "threedigit": "924",
            "children": [
              {
                "key": "92400",
                "title": "92400 Contusion of thigh",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "92401",
                "title": "92401 Contusion of hip",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "92410",
                "title": "92410 Contusion of lower leg",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "92411",
                "title": "92411 Contusion of knee",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "92420",
                "title": "92420 Contusion of foot",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "92421",
                "title": "92421 Contusion of ankle",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "9243",
                "title": "9243 Contusion of toe",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "9244",
                "title": "9244 Multiple contusion leg",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "9245",
                "title": "9245 Contusion leg NOS",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "9248",
                "title": "9248 Multiple contusions NEC",
                "threedigit": "924",
                "isLeaf": true
              },
              {
                "key": "9249",
                "title": "9249 Contusion NOS",
                "threedigit": "924",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Crushing Injury",
        "title": "925-929 Crushing Injury",
        "threedigit": "925",
        "children": [
          {
            "key": "Crushing injury of face scalp and neck",
            "title": "925 - Crushing injury of face scalp and neck",
            "threedigit": "925",
            "children": [
              {
                "key": "9251",
                "title": "9251 Crush inj face scalp",
                "threedigit": "925",
                "isLeaf": true
              },
              {
                "key": "9252",
                "title": "9252 Crush inj neck",
                "threedigit": "925",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Crushing injury of trunk",
            "title": "926 - Crushing injury of trunk",
            "threedigit": "926",
            "children": [
              {
                "key": "9260",
                "title": "9260 Crush inj ext genitalia",
                "threedigit": "926",
                "isLeaf": true
              },
              {
                "key": "92611",
                "title": "92611 Crushing injury back",
                "threedigit": "926",
                "isLeaf": true
              },
              {
                "key": "92612",
                "title": "92612 Crushing injury buttock",
                "threedigit": "926",
                "isLeaf": true
              },
              {
                "key": "92619",
                "title": "92619 Crushing inj trunk NEC",
                "threedigit": "926",
                "isLeaf": true
              },
              {
                "key": "9268",
                "title": "9268 Mult crushing inj trunk",
                "threedigit": "926",
                "isLeaf": true
              },
              {
                "key": "9269",
                "title": "9269 Crushing inj trunk NOS",
                "threedigit": "926",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Crushing injury of upper limb",
            "title": "927 - Crushing injury of upper limb",
            "threedigit": "927",
            "children": [
              {
                "key": "92700",
                "title": "92700 Crush inj shoulder reg",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "92701",
                "title": "92701 Crush inj scapul region",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "92702",
                "title": "92702 Crush inj axillary reg",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "92703",
                "title": "92703 Crushing inj upper arm",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "92709",
                "title": "92709 Crush inj shoulder & arm",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "92710",
                "title": "92710 Crushing injury forearm",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "92711",
                "title": "92711 Crushing injury elbow",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "92720",
                "title": "92720 Crushing injury of hand",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "92721",
                "title": "92721 Crushing injury of wrist",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "9273",
                "title": "9273 Crushing injury finger",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "9278",
                "title": "9278 Mult crushing injury arm",
                "threedigit": "927",
                "isLeaf": true
              },
              {
                "key": "9279",
                "title": "9279 Crushing injury arm NOS",
                "threedigit": "927",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Crushing injury of lower limb",
            "title": "928 - Crushing injury of lower limb",
            "threedigit": "928",
            "children": [
              {
                "key": "92800",
                "title": "92800 Crushing injury thigh",
                "threedigit": "928",
                "isLeaf": true
              },
              {
                "key": "92801",
                "title": "92801 Crushing injury hip",
                "threedigit": "928",
                "isLeaf": true
              },
              {
                "key": "92810",
                "title": "92810 Crushing inj lower leg",
                "threedigit": "928",
                "isLeaf": true
              },
              {
                "key": "92811",
                "title": "92811 Crushing injury knee",
                "threedigit": "928",
                "isLeaf": true
              },
              {
                "key": "92820",
                "title": "92820 Crushing injury foot",
                "threedigit": "928",
                "isLeaf": true
              },
              {
                "key": "92821",
                "title": "92821 Crushing injury ankle",
                "threedigit": "928",
                "isLeaf": true
              },
              {
                "key": "9283",
                "title": "9283 Crushing injury toe",
                "threedigit": "928",
                "isLeaf": true
              },
              {
                "key": "9288",
                "title": "9288 Mult crushing injury leg",
                "threedigit": "928",
                "isLeaf": true
              },
              {
                "key": "9289",
                "title": "9289 Crushing injury leg NOS",
                "threedigit": "928",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Crushing injury of multiple and unspecified sites",
            "title": "929 - Crushing injury of multiple and unspecified sites",
            "threedigit": "929",
            "children": [
              {
                "key": "9290",
                "title": "9290 Crush inj mult site NEC",
                "threedigit": "929",
                "isLeaf": true
              },
              {
                "key": "9299",
                "title": "9299 Crushing injury NOS",
                "threedigit": "929",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Effects Of Foreign Body Entering Through Orifice",
        "title": "930-939 Effects Of Foreign Body Entering Through Orifice",
        "threedigit": "930",
        "children": [
          {
            "key": "Foreign body on external eye",
            "title": "930 - Foreign body on external eye",
            "threedigit": "930",
            "children": [
              {
                "key": "9300",
                "title": "9300 Corneal foreign body",
                "threedigit": "930",
                "isLeaf": true
              },
              {
                "key": "9301",
                "title": "9301 FB in conjunctival sac",
                "threedigit": "930",
                "isLeaf": true
              },
              {
                "key": "9302",
                "title": "9302 FB in lacrimal punctum",
                "threedigit": "930",
                "isLeaf": true
              },
              {
                "key": "9308",
                "title": "9308 Foreign bdy ext eye NEC",
                "threedigit": "930",
                "isLeaf": true
              },
              {
                "key": "9309",
                "title": "9309 Foreign bdy ext eye NOS",
                "threedigit": "930",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body in ear",
            "title": "931 - Foreign body in ear",
            "threedigit": "931",
            "children": [
              {
                "key": "931",
                "title": "931 Foreign body in ear",
                "threedigit": "931",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body in nose",
            "title": "932 - Foreign body in nose",
            "threedigit": "932",
            "children": [
              {
                "key": "932",
                "title": "932 Foreign body in nose",
                "threedigit": "932",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body in pharynx and larynx",
            "title": "933 - Foreign body in pharynx and larynx",
            "threedigit": "933",
            "children": [
              {
                "key": "9330",
                "title": "9330 Foreign body in pharynx",
                "threedigit": "933",
                "isLeaf": true
              },
              {
                "key": "9331",
                "title": "9331 Foreign body in larynx",
                "threedigit": "933",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body in trachea bronchus and lung",
            "title": "934 - Foreign body in trachea bronchus and lung",
            "threedigit": "934",
            "children": [
              {
                "key": "9340",
                "title": "9340 Foreign body in trachea",
                "threedigit": "934",
                "isLeaf": true
              },
              {
                "key": "9341",
                "title": "9341 Foreign body bronchus",
                "threedigit": "934",
                "isLeaf": true
              },
              {
                "key": "9348",
                "title": "9348 FB trach/bronch/lung NEC",
                "threedigit": "934",
                "isLeaf": true
              },
              {
                "key": "9349",
                "title": "9349 FB respiratory tree NOS",
                "threedigit": "934",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body in mouth esophagus and stomach",
            "title": "935 - Foreign body in mouth esophagus and stomach",
            "threedigit": "935",
            "children": [
              {
                "key": "9350",
                "title": "9350 Foreign body in mouth",
                "threedigit": "935",
                "isLeaf": true
              },
              {
                "key": "9351",
                "title": "9351 Foreign body esophagus",
                "threedigit": "935",
                "isLeaf": true
              },
              {
                "key": "9352",
                "title": "9352 Foreign body in stomach",
                "threedigit": "935",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body in intestine and colon",
            "title": "936 - Foreign body in intestine and colon",
            "threedigit": "936",
            "children": [
              {
                "key": "936",
                "title": "936 FB in intestine & colon",
                "threedigit": "936",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body in anus and rectum",
            "title": "937 - Foreign body in anus and rectum",
            "threedigit": "937",
            "children": [
              {
                "key": "937",
                "title": "937 Foreign body anus/rectum",
                "threedigit": "937",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body in digestive system, unspecified",
            "title": "938 - Foreign body in digestive system, unspecified",
            "threedigit": "938",
            "children": [
              {
                "key": "938",
                "title": "938 Foreign body GI NOS",
                "threedigit": "938",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body in genitourinary tract",
            "title": "939 - Foreign body in genitourinary tract",
            "threedigit": "939",
            "children": [
              {
                "key": "9390",
                "title": "9390 FB bladder & urethra",
                "threedigit": "939",
                "isLeaf": true
              },
              {
                "key": "9391",
                "title": "9391 Foreign body uterus",
                "threedigit": "939",
                "isLeaf": true
              },
              {
                "key": "9392",
                "title": "9392 Foreign bdy vulva/vagina",
                "threedigit": "939",
                "isLeaf": true
              },
              {
                "key": "9393",
                "title": "9393 Foreign body penis",
                "threedigit": "939",
                "isLeaf": true
              },
              {
                "key": "9399",
                "title": "9399 Foreign bdy gu tract NOS",
                "threedigit": "939",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Burns",
        "title": "940-949 Burns",
        "threedigit": "940",
        "children": [
          {
            "key": "Burn confined to eye and adnexa",
            "title": "940 - Burn confined to eye and adnexa",
            "threedigit": "940",
            "children": [
              {
                "key": "9400",
                "title": "9400 Chemical burn periocular",
                "threedigit": "940",
                "isLeaf": true
              },
              {
                "key": "9401",
                "title": "9401 Burn periocular area NEC",
                "threedigit": "940",
                "isLeaf": true
              },
              {
                "key": "9402",
                "title": "9402 Alkal burn cornea/conjun",
                "threedigit": "940",
                "isLeaf": true
              },
              {
                "key": "9403",
                "title": "9403 Acid burn cornea/conjunc",
                "threedigit": "940",
                "isLeaf": true
              },
              {
                "key": "9404",
                "title": "9404 Burn cornea/conjunct NEC",
                "threedigit": "940",
                "isLeaf": true
              },
              {
                "key": "9405",
                "title": "9405 Burn w eyeball destruct",
                "threedigit": "940",
                "isLeaf": true
              },
              {
                "key": "9409",
                "title": "9409 Burn eye & adnexa NOS",
                "threedigit": "940",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Burn of face head and neck",
            "title": "941 - Burn of face head and neck",
            "threedigit": "941",
            "children": [
              {
                "key": "94100",
                "title": "94100 Burn NOS head-unspec",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94101",
                "title": "94101 Burn NOS ear",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94102",
                "title": "94102 Burn NOS eye",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94103",
                "title": "94103 Burn NOS lip",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94104",
                "title": "94104 Burn NOS chin",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94105",
                "title": "94105 Burn NOS nose",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94106",
                "title": "94106 Burn NOS scalp",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94107",
                "title": "94107 Burn NOS face NEC",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94108",
                "title": "94108 Burn NOS neck",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94109",
                "title": "94109 Burn NOS head-mult",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94110",
                "title": "94110 1st deg burn head NOS",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94111",
                "title": "94111 1st deg burn ear",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94112",
                "title": "94112 1st deg burn eye",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94113",
                "title": "94113 1st deg burn lip",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94114",
                "title": "94114 1st deg burn chin",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94115",
                "title": "94115 1st deg burn nose",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94116",
                "title": "94116 1st deg burn scalp",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94117",
                "title": "94117 1st deg burn face NEC",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94118",
                "title": "94118 1st deg burn neck",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94119",
                "title": "94119 1st deg burn head-mult",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94120",
                "title": "94120 2nd deg burn head NOS",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94121",
                "title": "94121 2nd deg burn ear",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94122",
                "title": "94122 2nd deg burn eye",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94123",
                "title": "94123 2nd deg burn lip",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94124",
                "title": "94124 2nd deg burn chin",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94125",
                "title": "94125 2nd deg burn nose",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94126",
                "title": "94126 2nd deg burn scalp",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94127",
                "title": "94127 2nd deg burn face NEC",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94128",
                "title": "94128 2nd deg burn neck",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94129",
                "title": "94129 2nd deg burn head-mult",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94130",
                "title": "94130 3rd deg burn head NOS",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94131",
                "title": "94131 3rd deg burn ear",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94132",
                "title": "94132 3rd deg burn eye",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94133",
                "title": "94133 3rd deg burn lip",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94134",
                "title": "94134 3rd deg burn chin",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94135",
                "title": "94135 3rd deg burn nose",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94136",
                "title": "94136 3rd deg burn scalp",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94137",
                "title": "94137 3rd deg burn face NEC",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94138",
                "title": "94138 3rd deg burn neck",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94139",
                "title": "94139 3rd deg burn head-mult",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94140",
                "title": "94140 Deep 3 deg burn head NOS",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94141",
                "title": "94141 Deep 3rd deg burn ear",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94142",
                "title": "94142 Deep 3rd deg burn eye",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94143",
                "title": "94143 Deep 3rd deg burn lip",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94144",
                "title": "94144 Deep 3rd deg burn chin",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94145",
                "title": "94145 Deep 3rd deg burn nose",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94146",
                "title": "94146 Deep 3rd deg burn scalp",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94147",
                "title": "94147 Deep 3rd burn face NEC",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94148",
                "title": "94148 Deep 3rd deg burn neck",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94149",
                "title": "94149 Deep 3 deg brn head-mult",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94150",
                "title": "94150 3rd burn w loss-head NOS",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94151",
                "title": "94151 3rd deg burn w loss-ear",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94152",
                "title": "94152 3rd deg burn w loss-eye",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94153",
                "title": "94153 3rd deg burn w loss-lip",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94154",
                "title": "94154 3rd deg burn w loss-chin",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94155",
                "title": "94155 3rd deg burn w loss-nose",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94156",
                "title": "94156 3rd deg brn w loss-scalp",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94157",
                "title": "94157 3rd burn w loss-face NEC",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94158",
                "title": "94158 3rd deg burn w loss-neck",
                "threedigit": "941",
                "isLeaf": true
              },
              {
                "key": "94159",
                "title": "94159 3rd brn w loss-head mult",
                "threedigit": "941",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Burn of trunk",
            "title": "942 - Burn of trunk",
            "threedigit": "942",
            "children": [
              {
                "key": "94200",
                "title": "94200 Burn NOS trunk-unspec",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94201",
                "title": "94201 Burn NOS breast",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94202",
                "title": "94202 Burn NOS chest wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94203",
                "title": "94203 Burn NOS abdominal wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94204",
                "title": "94204 Burn NOS back",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94205",
                "title": "94205 Burn NOS genitalia",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94209",
                "title": "94209 Burn NOS trunk NEC",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94210",
                "title": "94210 1st deg burn trunk NOS",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94211",
                "title": "94211 1st deg burn breast",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94212",
                "title": "94212 1st deg burn chest wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94213",
                "title": "94213 1st deg burn abdomn wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94214",
                "title": "94214 1st deg burn back",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94215",
                "title": "94215 1st deg burn genitalia",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94219",
                "title": "94219 1st deg burn trunk NEC",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94220",
                "title": "94220 2nd deg burn trunk NOS",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94221",
                "title": "94221 2nd deg burn breast",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94222",
                "title": "94222 2nd deg burn chest wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94223",
                "title": "94223 2nd deg burn abdomn wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94224",
                "title": "94224 2nd deg burn back",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94225",
                "title": "94225 2nd deg burn genitalia",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94229",
                "title": "94229 2nd deg burn trunk NEC",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94230",
                "title": "94230 3rd deg burn trunk NOS",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94231",
                "title": "94231 3rd deg burn breast",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94232",
                "title": "94232 3rd deg burn chest wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94233",
                "title": "94233 3rd deg burn abdomn wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94234",
                "title": "94234 3rd deg burn back",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94235",
                "title": "94235 3rd deg burn genitalia",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94239",
                "title": "94239 3rd deg burn trunk NEC",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94240",
                "title": "94240 Deep 3rd burn trunk NOS",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94241",
                "title": "94241 Deep 3rd deg burn breast",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94242",
                "title": "94242 Deep 3rd burn chest wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94243",
                "title": "94243 Deep 3rd burn abdom wall",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94244",
                "title": "94244 Deep 3rd deg burn back",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94245",
                "title": "94245 Deep 3rd burn genitalia",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94249",
                "title": "94249 Deep 3rd burn trunk NEC",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94250",
                "title": "94250 3rd brn w loss-trunk NOS",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94251",
                "title": "94251 3rd burn w loss-breast",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94252",
                "title": "94252 3rd brn w loss-chest wll",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94253",
                "title": "94253 3rd brn w loss-abdom wll",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94254",
                "title": "94254 3rd deg burn w loss-back",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94255",
                "title": "94255 3rd brn w loss-genitalia",
                "threedigit": "942",
                "isLeaf": true
              },
              {
                "key": "94259",
                "title": "94259 3rd brn w loss-trunk NEC",
                "threedigit": "942",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Burn of upper limb except wrist and hand",
            "title": "943 - Burn of upper limb except wrist and hand",
            "threedigit": "943",
            "children": [
              {
                "key": "94300",
                "title": "94300 Burn NOS arm-unspec",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94301",
                "title": "94301 Burn NOS forearm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94302",
                "title": "94302 Burn NOS elbow",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94303",
                "title": "94303 Burn NOS upper arm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94304",
                "title": "94304 Burn NOS axilla",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94305",
                "title": "94305 Burn NOS shoulder",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94306",
                "title": "94306 Burn NOS scapula",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94309",
                "title": "94309 Burn NOS arm-multiple",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94310",
                "title": "94310 1st deg burn arm NOS",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94311",
                "title": "94311 1st deg burn forearm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94312",
                "title": "94312 1st deg burn elbow",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94313",
                "title": "94313 1st deg burn upper arm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94314",
                "title": "94314 1st deg burn axilla",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94315",
                "title": "94315 1st deg burn shoulder",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94316",
                "title": "94316 1st deg burn scapula",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94319",
                "title": "94319 1st deg burn arm-mult",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94320",
                "title": "94320 2nd deg burn arm NOS",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94321",
                "title": "94321 2nd deg burn forearm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94322",
                "title": "94322 2nd deg burn elbow",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94323",
                "title": "94323 2nd deg burn upper arm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94324",
                "title": "94324 2nd deg burn axilla",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94325",
                "title": "94325 2nd deg burn shoulder",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94326",
                "title": "94326 2nd deg burn scapula",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94329",
                "title": "94329 2nd deg burn arm-mult",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94330",
                "title": "94330 3rd deg burn arm NOS",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94331",
                "title": "94331 3rd deg burn forearm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94332",
                "title": "94332 3rd deg burn elbow",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94333",
                "title": "94333 3rd deg burn upper arm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94334",
                "title": "94334 3rd deg burn axilla",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94335",
                "title": "94335 3rd deg burn shoulder",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94336",
                "title": "94336 3rd deg burn scapula",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94339",
                "title": "94339 3rd deg burn arm-mult",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94340",
                "title": "94340 Deep 3 deg burn arm NOS",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94341",
                "title": "94341 Deep 3 deg burn forearm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94342",
                "title": "94342 Deep 3 deg burn elbow",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94343",
                "title": "94343 Deep 3 deg brn upper arm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94344",
                "title": "94344 Deep 3 deg burn axilla",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94345",
                "title": "94345 Deep 3 deg burn shoulder",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94346",
                "title": "94346 Deep 3 deg burn scapula",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94349",
                "title": "94349 Deep 3 deg burn arm-mult",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94350",
                "title": "94350 3rd burn w loss-arm NOS",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94351",
                "title": "94351 3rd burn w loss-forearm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94352",
                "title": "94352 3rd burn w loss-elbow",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94353",
                "title": "94353 3rd brn w loss-upper arm",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94354",
                "title": "94354 3rd burn w loss-axilla",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94355",
                "title": "94355 3rd burn w loss-shoulder",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94356",
                "title": "94356 3rd burn w loss-scapula",
                "threedigit": "943",
                "isLeaf": true
              },
              {
                "key": "94359",
                "title": "94359 3rd burn w loss arm-mult",
                "threedigit": "943",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Burn of wrist(s) and hand(s)",
            "title": "944 - Burn of wrist(s) and hand(s)",
            "threedigit": "944",
            "children": [
              {
                "key": "94400",
                "title": "94400 Burn NOS hand-unspec",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94401",
                "title": "94401 Burn NOS finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94402",
                "title": "94402 Burn NOS thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94403",
                "title": "94403 Burn NOS mult fingers",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94404",
                "title": "94404 Burn NOS finger w thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94405",
                "title": "94405 Burn NOS palm",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94406",
                "title": "94406 Burn NOS back of hand",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94407",
                "title": "94407 Burn NOS wrist",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94408",
                "title": "94408 Burn NOS hand-multiple",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94410",
                "title": "94410 1st deg burn hand NOS",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94411",
                "title": "94411 1st deg burn finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94412",
                "title": "94412 1st deg burn thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94413",
                "title": "94413 1st deg burn mult finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94414",
                "title": "94414 1 deg burn fingr w thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94415",
                "title": "94415 1st deg burn palm",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94416",
                "title": "94416 1 deg burn back of hand",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94417",
                "title": "94417 1st deg burn wrist",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94418",
                "title": "94418 1st deg burn hand-mult",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94420",
                "title": "94420 2nd deg burn hand NOS",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94421",
                "title": "94421 2nd deg burn finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94422",
                "title": "94422 2nd deg burn thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94423",
                "title": "94423 2nd deg burn mult finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94424",
                "title": "94424 2 deg burn fingr w thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94425",
                "title": "94425 2nd deg burn palm",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94426",
                "title": "94426 2 deg burn back of hand",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94427",
                "title": "94427 2nd deg burn wrist",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94428",
                "title": "94428 2nd deg burn hand-mult",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94430",
                "title": "94430 3rd deg burn hand NOS",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94431",
                "title": "94431 3rd deg burn finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94432",
                "title": "94432 3rd deg burn thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94433",
                "title": "94433 3rd deg burn mult finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94434",
                "title": "94434 3 deg burn fingr w thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94435",
                "title": "94435 3rd deg burn palm",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94436",
                "title": "94436 3 deg burn back of hand",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94437",
                "title": "94437 3rd deg burn wrist",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94438",
                "title": "94438 3rd deg burn hand-mult",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94440",
                "title": "94440 Deep 3 deg brn hand NOS",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94441",
                "title": "94441 Deep 3 deg burn finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94442",
                "title": "94442 Deep 3 deg burn thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94443",
                "title": "94443 Deep 3rd brn mult finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94444",
                "title": "94444 Deep 3rd brn fngr w thmb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94445",
                "title": "94445 Deep 3 deg burn palm",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94446",
                "title": "94446 Deep 3rd brn back of hnd",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94447",
                "title": "94447 Deep 3 deg burn wrist",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94448",
                "title": "94448 Deep 3 deg brn hand-mult",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94450",
                "title": "94450 3rd brn w loss-hand NOS",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94451",
                "title": "94451 3rd burn w loss-finger",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94452",
                "title": "94452 3rd burn w loss-thumb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94453",
                "title": "94453 3rd brn w loss-mult fngr",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94454",
                "title": "94454 3rd brn w loss-fngr/thmb",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94455",
                "title": "94455 3rd burn w loss-palm",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94456",
                "title": "94456 3rd brn w loss-bk of hnd",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94457",
                "title": "94457 3rd burn w loss-wrist",
                "threedigit": "944",
                "isLeaf": true
              },
              {
                "key": "94458",
                "title": "94458 3rd brn w loss hand-mult",
                "threedigit": "944",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Burn of lower limb(s)",
            "title": "945 - Burn of lower limb(s)",
            "threedigit": "945",
            "children": [
              {
                "key": "94500",
                "title": "94500 Burn NOS leg-unspec",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94501",
                "title": "94501 Burn NOS toe",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94502",
                "title": "94502 Burn NOS foot",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94503",
                "title": "94503 Burn NOS ankle",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94504",
                "title": "94504 Burn NOS lower leg",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94505",
                "title": "94505 Burn NOS knee",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94506",
                "title": "94506 Burn NOS thigh",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94509",
                "title": "94509 Burn NOS leg-multiple",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94510",
                "title": "94510 1st deg burn leg NOS",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94511",
                "title": "94511 1st deg burn toe",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94512",
                "title": "94512 1st deg burn foot",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94513",
                "title": "94513 1st deg burn ankle",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94514",
                "title": "94514 1st deg burn lower leg",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94515",
                "title": "94515 1st deg burn knee",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94516",
                "title": "94516 1st deg burn thigh",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94519",
                "title": "94519 1st deg burn leg-mult",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94520",
                "title": "94520 2nd deg burn leg NOS",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94521",
                "title": "94521 2nd deg burn toe",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94522",
                "title": "94522 2nd deg burn foot",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94523",
                "title": "94523 2nd deg burn ankle",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94524",
                "title": "94524 2nd deg burn lower leg",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94525",
                "title": "94525 2nd deg burn knee",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94526",
                "title": "94526 2nd deg burn thigh",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94529",
                "title": "94529 2nd deg burn leg-mult",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94530",
                "title": "94530 3rd deg burn leg NOS",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94531",
                "title": "94531 3rd deg burn toe",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94532",
                "title": "94532 3rd deg burn foot",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94533",
                "title": "94533 3rd deg burn ankle",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94534",
                "title": "94534 3rd deg burn low leg",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94535",
                "title": "94535 3rd deg burn knee",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94536",
                "title": "94536 3rd deg burn thigh",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94539",
                "title": "94539 3rd deg burn leg-mult",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94540",
                "title": "94540 Deep 3rd deg brn leg NOS",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94541",
                "title": "94541 Deep 3rd deg burn toe",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94542",
                "title": "94542 Deep 3rd deg burn foot",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94543",
                "title": "94543 Deep 3rd deg burn ankle",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94544",
                "title": "94544 Deep 3rd deg brn low leg",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94545",
                "title": "94545 Deep 3rd deg burn knee",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94546",
                "title": "94546 Deep 3rd deg burn thigh",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94549",
                "title": "94549 Deep 3 deg burn leg-mult",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94550",
                "title": "94550 3 deg brn w loss-leg NOS",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94551",
                "title": "94551 3 deg burn w loss-toe",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94552",
                "title": "94552 3 deg burn w loss-foot",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94553",
                "title": "94553 3 deg burn w loss-ankle",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94554",
                "title": "94554 3 deg brn w loss-low leg",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94555",
                "title": "94555 3 deg burn w loss-knee",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94556",
                "title": "94556 3 deg burn w loss-thigh",
                "threedigit": "945",
                "isLeaf": true
              },
              {
                "key": "94559",
                "title": "94559 3 deg brn w loss leg-mlt",
                "threedigit": "945",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Burns of multiple specified sites",
            "title": "946 - Burns of multiple specified sites",
            "threedigit": "946",
            "children": [
              {
                "key": "9460",
                "title": "9460 Burn NOS multiple site",
                "threedigit": "946",
                "isLeaf": true
              },
              {
                "key": "9461",
                "title": "9461 1st deg burn mult site",
                "threedigit": "946",
                "isLeaf": true
              },
              {
                "key": "9462",
                "title": "9462 2nd deg burn mult site",
                "threedigit": "946",
                "isLeaf": true
              },
              {
                "key": "9463",
                "title": "9463 3rd deg burn mult site",
                "threedigit": "946",
                "isLeaf": true
              },
              {
                "key": "9464",
                "title": "9464 Deep 3 deg brn mult site",
                "threedigit": "946",
                "isLeaf": true
              },
              {
                "key": "9465",
                "title": "9465 3rd brn w loss-mult site",
                "threedigit": "946",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Burn of internal organs",
            "title": "947 - Burn of internal organs",
            "threedigit": "947",
            "children": [
              {
                "key": "9470",
                "title": "9470 Burn of mouth & pharynx",
                "threedigit": "947",
                "isLeaf": true
              },
              {
                "key": "9471",
                "title": "9471 Burn larynx/trachea/lung",
                "threedigit": "947",
                "isLeaf": true
              },
              {
                "key": "9472",
                "title": "9472 Burn of esophagus",
                "threedigit": "947",
                "isLeaf": true
              },
              {
                "key": "9473",
                "title": "9473 Burn of GI tract",
                "threedigit": "947",
                "isLeaf": true
              },
              {
                "key": "9474",
                "title": "9474 Burn of vagina & uterus",
                "threedigit": "947",
                "isLeaf": true
              },
              {
                "key": "9478",
                "title": "9478 Burn internal organ NEC",
                "threedigit": "947",
                "isLeaf": true
              },
              {
                "key": "9479",
                "title": "9479 Burn internal organ NOS",
                "threedigit": "947",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Burns classified according to extent of body surface involved",
            "title": "948 - Burns classified according to extent of body surface involved",
            "threedigit": "948",
            "children": [
              {
                "key": "94800",
                "title": "94800 Bdy brn < 10%/3d deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94810",
                "title": "94810 10-19% bdy brn/3 deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94811",
                "title": "94811 10-19% bdy brn/10-19% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94820",
                "title": "94820 20-29% bdy brn/3 deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94821",
                "title": "94821 20-29% bdy brn/10-19% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94822",
                "title": "94822 20-29% bdy brn/20-29% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94830",
                "title": "94830 30-39% bdy brn/3 deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94831",
                "title": "94831 30-39% bdy brn/10-19% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94832",
                "title": "94832 30-39% bdy brn/20-29% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94833",
                "title": "94833 30-39% bdy brn/30-39% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94840",
                "title": "94840 40-49% bdy brn/3 deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94841",
                "title": "94841 40-49% bdy brn/10-19% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94842",
                "title": "94842 40-49% bdy brn/20-29% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94843",
                "title": "94843 40-49% bdy brn/30-39% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94844",
                "title": "94844 40-49% bdy brn/40-49% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94850",
                "title": "94850 50-59% bdy brn/3 deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94851",
                "title": "94851 50-59% bdy brn/10-19% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94852",
                "title": "94852 50-59% bdy brn/20-29% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94853",
                "title": "94853 50-59% bdy brn/30-39% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94854",
                "title": "94854 50-59% bdy brn/40-49% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94855",
                "title": "94855 50-59% bdy brn/50-59% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94860",
                "title": "94860 60-69% bdy brn/3 deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94861",
                "title": "94861 60-69% bdy brn/10-19% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94862",
                "title": "94862 60-69% bdy brn/20-29% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94863",
                "title": "94863 60-69% bdy brn/30-39% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94864",
                "title": "94864 60-69% bdy brn/40-49% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94865",
                "title": "94865 60-69% bdy brn/50-59% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94866",
                "title": "94866 60-69% bdy brn/60-69% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94870",
                "title": "94870 70-79% bdy brn/3 deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94871",
                "title": "94871 70-79% bdy brn/10-19% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94872",
                "title": "94872 70-79% bdy brn/20-29% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94873",
                "title": "94873 70-79% bdy brn/30-39% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94874",
                "title": "94874 70-79% bdy brn/40-49% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94875",
                "title": "94875 70-79% bdy brn/50-59% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94876",
                "title": "94876 70-79% bdy brn/60-69% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94877",
                "title": "94877 70-79% bdy brn/70-79% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94880",
                "title": "94880 80-89% bdy brn/3 deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94881",
                "title": "94881 80-89% bdy brn/10-19% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94882",
                "title": "94882 80-89% bdy brn/20-29% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94883",
                "title": "94883 80-89% bdy brn/30-39% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94884",
                "title": "94884 80-89% bdy brn/40-49% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94885",
                "title": "94885 80-89% bdy brn/50-59% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94886",
                "title": "94886 80-89% bdy brn/60-69% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94887",
                "title": "94887 80-89% bdy brn/70-79% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94888",
                "title": "94888 80-89% bdy brn/80-89% 3d",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94890",
                "title": "94890 90% + bdy brn/3d deg NOS",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94891",
                "title": "94891 90% + bdy brn/10-19% 3rd",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94892",
                "title": "94892 90% + bdy brn/20-29% 3rd",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94893",
                "title": "94893 90% + bdy brn/30-39% 3rd",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94894",
                "title": "94894 90% + bdy brn/40-49% 3rd",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94895",
                "title": "94895 90% + bdy brn/50-59% 3rd",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94896",
                "title": "94896 90% + bdy brn/60-69% 3rd",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94897",
                "title": "94897 90% + bdy brn/70-79% 3rd",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94898",
                "title": "94898 90% + bdy brn/80-89% 3rd",
                "threedigit": "948",
                "isLeaf": true
              },
              {
                "key": "94899",
                "title": "94899 90% + bdy brn/90% + 3rd",
                "threedigit": "948",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Burn unspecified site",
            "title": "949 - Burn unspecified site",
            "threedigit": "949",
            "children": [
              {
                "key": "9490",
                "title": "9490 Burn NOS",
                "threedigit": "949",
                "isLeaf": true
              },
              {
                "key": "9491",
                "title": "9491 1st degree burn NOS",
                "threedigit": "949",
                "isLeaf": true
              },
              {
                "key": "9492",
                "title": "9492 2nd degree burn NOS",
                "threedigit": "949",
                "isLeaf": true
              },
              {
                "key": "9493",
                "title": "9493 3rd degree burn NOS",
                "threedigit": "949",
                "isLeaf": true
              },
              {
                "key": "9494",
                "title": "9494 Deep 3rd deg burn NOS",
                "threedigit": "949",
                "isLeaf": true
              },
              {
                "key": "9495",
                "title": "9495 3rd burn w loss-site NOS",
                "threedigit": "949",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Injury To Nerves And Spinal Cord",
        "title": "950-957 Injury To Nerves And Spinal Cord",
        "threedigit": "950",
        "children": [
          {
            "key": "Injury to optic nerve and pathways",
            "title": "950 - Injury to optic nerve and pathways",
            "threedigit": "950",
            "children": [
              {
                "key": "9500",
                "title": "9500 Optic nerve injury",
                "threedigit": "950",
                "isLeaf": true
              },
              {
                "key": "9501",
                "title": "9501 Injury to optic chiasm",
                "threedigit": "950",
                "isLeaf": true
              },
              {
                "key": "9502",
                "title": "9502 Injury to optic pathways",
                "threedigit": "950",
                "isLeaf": true
              },
              {
                "key": "9503",
                "title": "9503 Injury to visual cortex",
                "threedigit": "950",
                "isLeaf": true
              },
              {
                "key": "9509",
                "title": "9509 Inj optic nerv/path NOS",
                "threedigit": "950",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to other cranial nerve(s)",
            "title": "951 - Injury to other cranial nerve(s)",
            "threedigit": "951",
            "children": [
              {
                "key": "9510",
                "title": "9510 Injury oculomotor nerve",
                "threedigit": "951",
                "isLeaf": true
              },
              {
                "key": "9511",
                "title": "9511 Injury trochlear nerve",
                "threedigit": "951",
                "isLeaf": true
              },
              {
                "key": "9512",
                "title": "9512 Injury trigeminal nerve",
                "threedigit": "951",
                "isLeaf": true
              },
              {
                "key": "9513",
                "title": "9513 Injury abducens nerve",
                "threedigit": "951",
                "isLeaf": true
              },
              {
                "key": "9514",
                "title": "9514 Injury to facial nerve",
                "threedigit": "951",
                "isLeaf": true
              },
              {
                "key": "9515",
                "title": "9515 Injury to acoustic nerve",
                "threedigit": "951",
                "isLeaf": true
              },
              {
                "key": "9516",
                "title": "9516 Injury accessory nerve",
                "threedigit": "951",
                "isLeaf": true
              },
              {
                "key": "9517",
                "title": "9517 Injury hypoglossal nerve",
                "threedigit": "951",
                "isLeaf": true
              },
              {
                "key": "9518",
                "title": "9518 Injury cranial nerve NEC",
                "threedigit": "951",
                "isLeaf": true
              },
              {
                "key": "9519",
                "title": "9519 Injury cranial nerve NOS",
                "threedigit": "951",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Spinal cord injury without evidence of spinal bone injury",
            "title": "952 - Spinal cord injury without evidence of spinal bone injury",
            "threedigit": "952",
            "children": [
              {
                "key": "95200",
                "title": "95200 C1-c4 spin cord inj NOS",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95201",
                "title": "95201 Complete les cord/c1-c4",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95202",
                "title": "95202 Anterior cord synd/c1-c4",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95203",
                "title": "95203 Central cord synd/c1-c4",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95204",
                "title": "95204 C1-c4 spin cord inj NEC",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95205",
                "title": "95205 C5-c7 spin cord inj NOS",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95206",
                "title": "95206 Complete les cord/c5-c7",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95207",
                "title": "95207 Anterior cord synd/c5-c7",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95208",
                "title": "95208 Central cord synd/c5-c7",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95209",
                "title": "95209 C5-c7 spin cord inj NEC",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95210",
                "title": "95210 T1-t6 spin cord inj NOS",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95211",
                "title": "95211 Complete les cord/t1-t6",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95212",
                "title": "95212 Anterior cord synd/t1-t6",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95213",
                "title": "95213 Central cord synd/t1-t6",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95214",
                "title": "95214 T1-t6 spin cord inj NEC",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95215",
                "title": "95215 T7-t12 spin cord inj NOS",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95216",
                "title": "95216 Complete les cord/t7-t12",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95217",
                "title": "95217 Anterior cord syn/t7-t12",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95218",
                "title": "95218 Central cord syn/t7-t12",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "95219",
                "title": "95219 T7-t12 spin cord inj NEC",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "9522",
                "title": "9522 Lumbar spinal cord injur",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "9523",
                "title": "9523 Sacral spinal cord injur",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "9524",
                "title": "9524 Cauda equina injury",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "9528",
                "title": "9528 Spin cord inj-mult site",
                "threedigit": "952",
                "isLeaf": true
              },
              {
                "key": "9529",
                "title": "9529 Spinal cord injury NOS",
                "threedigit": "952",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to nerve roots and spinal plexus",
            "title": "953 - Injury to nerve roots and spinal plexus",
            "threedigit": "953",
            "children": [
              {
                "key": "9530",
                "title": "9530 Cervical root injury",
                "threedigit": "953",
                "isLeaf": true
              },
              {
                "key": "9531",
                "title": "9531 Dorsal root injury",
                "threedigit": "953",
                "isLeaf": true
              },
              {
                "key": "9532",
                "title": "9532 Lumbar root injury",
                "threedigit": "953",
                "isLeaf": true
              },
              {
                "key": "9533",
                "title": "9533 Sacral root injury",
                "threedigit": "953",
                "isLeaf": true
              },
              {
                "key": "9534",
                "title": "9534 Brachial plexus injury",
                "threedigit": "953",
                "isLeaf": true
              },
              {
                "key": "9535",
                "title": "9535 Lumbosacral plex injury",
                "threedigit": "953",
                "isLeaf": true
              },
              {
                "key": "9538",
                "title": "9538 Mult nerve root/plex inj",
                "threedigit": "953",
                "isLeaf": true
              },
              {
                "key": "9539",
                "title": "9539 Inj nerve root/plex NOS",
                "threedigit": "953",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to other nerve(s) of trunk excluding shoulder and pelvic girdles",
            "title": "954 - Injury to other nerve(s) of trunk excluding shoulder and pelvic girdles",
            "threedigit": "954",
            "children": [
              {
                "key": "9540",
                "title": "9540 Inj cerv sympath nerve",
                "threedigit": "954",
                "isLeaf": true
              },
              {
                "key": "9541",
                "title": "9541 Inj sympath nerve NEC",
                "threedigit": "954",
                "isLeaf": true
              },
              {
                "key": "9548",
                "title": "9548 Injury trunk nerve NEC",
                "threedigit": "954",
                "isLeaf": true
              },
              {
                "key": "9549",
                "title": "9549 Injury trunk nerve NOS",
                "threedigit": "954",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to peripheral nerve(s) of shoulder girdle and upper limb",
            "title": "955 - Injury to peripheral nerve(s) of shoulder girdle and upper limb",
            "threedigit": "955",
            "children": [
              {
                "key": "9550",
                "title": "9550 Injury axillary nerve",
                "threedigit": "955",
                "isLeaf": true
              },
              {
                "key": "9551",
                "title": "9551 Injury median nerve",
                "threedigit": "955",
                "isLeaf": true
              },
              {
                "key": "9552",
                "title": "9552 Injury ulnar nerve",
                "threedigit": "955",
                "isLeaf": true
              },
              {
                "key": "9553",
                "title": "9553 Injury radial nerve",
                "threedigit": "955",
                "isLeaf": true
              },
              {
                "key": "9554",
                "title": "9554 Inj musculocutan nerve",
                "threedigit": "955",
                "isLeaf": true
              },
              {
                "key": "9555",
                "title": "9555 Inj cutan senso nerv/arm",
                "threedigit": "955",
                "isLeaf": true
              },
              {
                "key": "9556",
                "title": "9556 Injury digital nerve",
                "threedigit": "955",
                "isLeaf": true
              },
              {
                "key": "9557",
                "title": "9557 Inj nerve shldr/arm NEC",
                "threedigit": "955",
                "isLeaf": true
              },
              {
                "key": "9558",
                "title": "9558 Inj mult nerve shldr/arm",
                "threedigit": "955",
                "isLeaf": true
              },
              {
                "key": "9559",
                "title": "9559 Inj nerve shldr/arm NOS",
                "threedigit": "955",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to peripheral nerve(s) of pelvic girdle and lower limb",
            "title": "956 - Injury to peripheral nerve(s) of pelvic girdle and lower limb",
            "threedigit": "956",
            "children": [
              {
                "key": "9560",
                "title": "9560 Injury sciatic nerve",
                "threedigit": "956",
                "isLeaf": true
              },
              {
                "key": "9561",
                "title": "9561 Injury femoral nerve",
                "threedigit": "956",
                "isLeaf": true
              },
              {
                "key": "9562",
                "title": "9562 Inj posterior tib nerve",
                "threedigit": "956",
                "isLeaf": true
              },
              {
                "key": "9563",
                "title": "9563 Injury peroneal nerve",
                "threedigit": "956",
                "isLeaf": true
              },
              {
                "key": "9564",
                "title": "9564 Inj cutan senso nerv/leg",
                "threedigit": "956",
                "isLeaf": true
              },
              {
                "key": "9565",
                "title": "9565 Inj nerve pelv/leg NEC",
                "threedigit": "956",
                "isLeaf": true
              },
              {
                "key": "9568",
                "title": "9568 Inj mult nerve pelv/leg",
                "threedigit": "956",
                "isLeaf": true
              },
              {
                "key": "9569",
                "title": "9569 Inj nerve pelv/leg NOS",
                "threedigit": "956",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury to other and unspecified nerves",
            "title": "957 - Injury to other and unspecified nerves",
            "threedigit": "957",
            "children": [
              {
                "key": "9570",
                "title": "9570 Inj superf nerv head/nck",
                "threedigit": "957",
                "isLeaf": true
              },
              {
                "key": "9571",
                "title": "9571 Injury to nerve NEC",
                "threedigit": "957",
                "isLeaf": true
              },
              {
                "key": "9578",
                "title": "9578 Injury to mult nerves",
                "threedigit": "957",
                "isLeaf": true
              },
              {
                "key": "9579",
                "title": "9579 Injury to nerve NOS",
                "threedigit": "957",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Certain Traumatic Complications And Unspecified Injuries",
        "title": "958-959 Certain Traumatic Complications And Unspecified Injuries",
        "threedigit": "958",
        "children": [
          {
            "key": "Certain early complications of trauma",
            "title": "958 - Certain early complications of trauma",
            "threedigit": "958",
            "children": [
              {
                "key": "9580",
                "title": "9580 Air embolism",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "9581",
                "title": "9581 Fat embolism",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "9582",
                "title": "9582 Secondary/recur hemorr",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "9583",
                "title": "9583 Posttraum wnd infec NEC",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "9584",
                "title": "9584 Traumatic shock",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "9585",
                "title": "9585 Traumatic anuria",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "9586",
                "title": "9586 Volkmann's isch contract",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "9587",
                "title": "9587 Traum subcutan emphysema",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "9588",
                "title": "9588 Early complic trauma NEC",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "95890",
                "title": "95890 Compartment syndrome NOS",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "95891",
                "title": "95891 Trauma comp synd up ext",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "95892",
                "title": "95892 Trauma comp synd low ext",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "95893",
                "title": "95893 Trauma compart synd abd",
                "threedigit": "958",
                "isLeaf": true
              },
              {
                "key": "95899",
                "title": "95899 Trauma compart synd NEC",
                "threedigit": "958",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury other and unspecified",
            "title": "959 - Injury other and unspecified",
            "threedigit": "959",
            "children": [
              {
                "key": "95901",
                "title": "95901 Head injury NOS",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "95909",
                "title": "95909 Face & neck injury",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "95911",
                "title": "95911 Injury of chest wall NEC",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "95912",
                "title": "95912 Injury of abdomen NEC",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "95913",
                "title": "95913 Fx corpus cavrnosm penis",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "95914",
                "title": "95914 Inj external genital NEC",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "95919",
                "title": "95919 Trunk injury-sites NEC",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "9592",
                "title": "9592 Shldr/upper arm inj NOS",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "9593",
                "title": "9593 Elb/forearm/wrst inj NOS",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "9594",
                "title": "9594 Hand injury NOS",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "9595",
                "title": "9595 Finger injury NOS",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "9596",
                "title": "9596 Hip & thigh injury NOS",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "9597",
                "title": "9597 Lower leg injury NOS",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "9598",
                "title": "9598 Injury mlt site/site NEC",
                "threedigit": "959",
                "isLeaf": true
              },
              {
                "key": "9599",
                "title": "9599 Injury-site NOS",
                "threedigit": "959",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Poisoning By Drugs, Medicinals And Biological Substances",
        "title": "960-979 Poisoning By Drugs, Medicinals And Biological Substances",
        "threedigit": "960",
        "children": [
          {
            "key": "Poisoning by antibiotics",
            "title": "960 - Poisoning by antibiotics",
            "threedigit": "960",
            "children": [
              {
                "key": "9600",
                "title": "9600 Poisoning-penicillins",
                "threedigit": "960",
                "isLeaf": true
              },
              {
                "key": "9601",
                "title": "9601 Pois-antifungal antibiot",
                "threedigit": "960",
                "isLeaf": true
              },
              {
                "key": "9602",
                "title": "9602 Poison-chloramphenicol",
                "threedigit": "960",
                "isLeaf": true
              },
              {
                "key": "9603",
                "title": "9603 Pois-erythromyc/macrolid",
                "threedigit": "960",
                "isLeaf": true
              },
              {
                "key": "9604",
                "title": "9604 Poisoning-tetracycline",
                "threedigit": "960",
                "isLeaf": true
              },
              {
                "key": "9605",
                "title": "9605 Pois-cephalosporin group",
                "threedigit": "960",
                "isLeaf": true
              },
              {
                "key": "9606",
                "title": "9606 Pois-antimycobac antibio",
                "threedigit": "960",
                "isLeaf": true
              },
              {
                "key": "9607",
                "title": "9607 Pois-antineop antibiotic",
                "threedigit": "960",
                "isLeaf": true
              },
              {
                "key": "9608",
                "title": "9608 Poisoning-antibiotic NEC",
                "threedigit": "960",
                "isLeaf": true
              },
              {
                "key": "9609",
                "title": "9609 Poisoning-antibiotic NOS",
                "threedigit": "960",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by other anti-infectives",
            "title": "961 - Poisoning by other anti-infectives",
            "threedigit": "961",
            "children": [
              {
                "key": "9610",
                "title": "9610 Poisoning-sulfonamides",
                "threedigit": "961",
                "isLeaf": true
              },
              {
                "key": "9611",
                "title": "9611 Pois-arsenic anti-infec",
                "threedigit": "961",
                "isLeaf": true
              },
              {
                "key": "9612",
                "title": "9612 Pois-heav met anti-infec",
                "threedigit": "961",
                "isLeaf": true
              },
              {
                "key": "9613",
                "title": "9613 Pois-quinoline/hydroxyqu",
                "threedigit": "961",
                "isLeaf": true
              },
              {
                "key": "9614",
                "title": "9614 Poisoning-antimalarials",
                "threedigit": "961",
                "isLeaf": true
              },
              {
                "key": "9615",
                "title": "9615 Pois-antiprotoz drug NEC",
                "threedigit": "961",
                "isLeaf": true
              },
              {
                "key": "9616",
                "title": "9616 Poisoning-anthelmintics",
                "threedigit": "961",
                "isLeaf": true
              },
              {
                "key": "9617",
                "title": "9617 Poisoning-antiviral drug",
                "threedigit": "961",
                "isLeaf": true
              },
              {
                "key": "9618",
                "title": "9618 Pois-antimycobac drg NEC",
                "threedigit": "961",
                "isLeaf": true
              },
              {
                "key": "9619",
                "title": "9619 Pois-anti-infect NEC/NOS",
                "threedigit": "961",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by hormones and synthetic substitutes",
            "title": "962 - Poisoning by hormones and synthetic substitutes",
            "threedigit": "962",
            "children": [
              {
                "key": "9620",
                "title": "9620 Pois-corticosteroids",
                "threedigit": "962",
                "isLeaf": true
              },
              {
                "key": "9621",
                "title": "9621 Poisoning-androgens",
                "threedigit": "962",
                "isLeaf": true
              },
              {
                "key": "9622",
                "title": "9622 Poisoning-ovarian hormon",
                "threedigit": "962",
                "isLeaf": true
              },
              {
                "key": "9623",
                "title": "9623 Poison-insulin/antidiab",
                "threedigit": "962",
                "isLeaf": true
              },
              {
                "key": "9624",
                "title": "9624 Pois-ant pituitary horm",
                "threedigit": "962",
                "isLeaf": true
              },
              {
                "key": "9625",
                "title": "9625 Pois-post pituitary horm",
                "threedigit": "962",
                "isLeaf": true
              },
              {
                "key": "9626",
                "title": "9626 Poisoning-parathyroids",
                "threedigit": "962",
                "isLeaf": true
              },
              {
                "key": "9627",
                "title": "9627 Poisoning-thyroid/deriv",
                "threedigit": "962",
                "isLeaf": true
              },
              {
                "key": "9628",
                "title": "9628 Poison-antithyroid agent",
                "threedigit": "962",
                "isLeaf": true
              },
              {
                "key": "9629",
                "title": "9629 Poisoning hormon NEC/NOS",
                "threedigit": "962",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by primarily systemic agents",
            "title": "963 - Poisoning by primarily systemic agents",
            "threedigit": "963",
            "children": [
              {
                "key": "9630",
                "title": "9630 Pois-antiallrg/antiemet",
                "threedigit": "963",
                "isLeaf": true
              },
              {
                "key": "9631",
                "title": "9631 Pois-antineopl/immunosup",
                "threedigit": "963",
                "isLeaf": true
              },
              {
                "key": "9632",
                "title": "9632 Poisoning-acidifying agt",
                "threedigit": "963",
                "isLeaf": true
              },
              {
                "key": "9633",
                "title": "9633 Poisoning-alkalizing agt",
                "threedigit": "963",
                "isLeaf": true
              },
              {
                "key": "9634",
                "title": "9634 Poisoning-enzymes NEC",
                "threedigit": "963",
                "isLeaf": true
              },
              {
                "key": "9635",
                "title": "9635 Poisoning-vitamins NEC",
                "threedigit": "963",
                "isLeaf": true
              },
              {
                "key": "9638",
                "title": "9638 Poisoning-system agt NEC",
                "threedigit": "963",
                "isLeaf": true
              },
              {
                "key": "9639",
                "title": "9639 Poisoning-system agt NOS",
                "threedigit": "963",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by agents primarily affecting blood constituents",
            "title": "964 - Poisoning by agents primarily affecting blood constituents",
            "threedigit": "964",
            "children": [
              {
                "key": "9640",
                "title": "9640 Poisoning-iron/compounds",
                "threedigit": "964",
                "isLeaf": true
              },
              {
                "key": "9641",
                "title": "9641 Poison-liver/antianemics",
                "threedigit": "964",
                "isLeaf": true
              },
              {
                "key": "9642",
                "title": "9642 Poisoning-anticoagulants",
                "threedigit": "964",
                "isLeaf": true
              },
              {
                "key": "9643",
                "title": "9643 Poisoning-vitamin k",
                "threedigit": "964",
                "isLeaf": true
              },
              {
                "key": "9644",
                "title": "9644 Poison-fibrinolysis agnt",
                "threedigit": "964",
                "isLeaf": true
              },
              {
                "key": "9645",
                "title": "9645 Poisoning-coagulants",
                "threedigit": "964",
                "isLeaf": true
              },
              {
                "key": "9646",
                "title": "9646 Poisoning-gamma globulin",
                "threedigit": "964",
                "isLeaf": true
              },
              {
                "key": "9647",
                "title": "9647 Poisoning-blood product",
                "threedigit": "964",
                "isLeaf": true
              },
              {
                "key": "9648",
                "title": "9648 Poisoning-blood agt NEC",
                "threedigit": "964",
                "isLeaf": true
              },
              {
                "key": "9649",
                "title": "9649 Poisoning-blood agt NOS",
                "threedigit": "964",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by analgesics antipyretics and antirheumatics",
            "title": "965 - Poisoning by analgesics antipyretics and antirheumatics",
            "threedigit": "965",
            "children": [
              {
                "key": "96500",
                "title": "96500 Poisoning-opium NOS",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "96501",
                "title": "96501 Poisoning-heroin",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "96502",
                "title": "96502 Poisoning-methadone",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "96509",
                "title": "96509 Poisoning-opiates NEC",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "9651",
                "title": "9651 Poisoning-salicylates",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "9654",
                "title": "9654 Pois-arom analgesics NEC",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "9655",
                "title": "9655 Poisoning-pyrazole deriv",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "96561",
                "title": "96561 Pois-propionic acid derv",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "96569",
                "title": "96569 Poison-antirheumatic NEC",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "9657",
                "title": "9657 Pois-no-narc analges NEC",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "9658",
                "title": "9658 Pois-analges/antipyr NEC",
                "threedigit": "965",
                "isLeaf": true
              },
              {
                "key": "9659",
                "title": "9659 Pois-analges/antipyr NOS",
                "threedigit": "965",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by anticonvulsants and anti-parkinsonism drugs",
            "title": "966 - Poisoning by anticonvulsants and anti-parkinsonism drugs",
            "threedigit": "966",
            "children": [
              {
                "key": "9660",
                "title": "9660 Poison-oxazolidine deriv",
                "threedigit": "966",
                "isLeaf": true
              },
              {
                "key": "9661",
                "title": "9661 Poison-hydantoin derivat",
                "threedigit": "966",
                "isLeaf": true
              },
              {
                "key": "9662",
                "title": "9662 Poisoning-succinimides",
                "threedigit": "966",
                "isLeaf": true
              },
              {
                "key": "9663",
                "title": "9663 Pois-anticonvul NEC/NOS",
                "threedigit": "966",
                "isLeaf": true
              },
              {
                "key": "9664",
                "title": "9664 Pois-anti-parkinson drug",
                "threedigit": "966",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by sedatives and hypnotics",
            "title": "967 - Poisoning by sedatives and hypnotics",
            "threedigit": "967",
            "children": [
              {
                "key": "9670",
                "title": "9670 Poisoning-barbiturates",
                "threedigit": "967",
                "isLeaf": true
              },
              {
                "key": "9671",
                "title": "9671 Poisoning-chloral hydrat",
                "threedigit": "967",
                "isLeaf": true
              },
              {
                "key": "9672",
                "title": "9672 Poisoning-paraldehyde",
                "threedigit": "967",
                "isLeaf": true
              },
              {
                "key": "9673",
                "title": "9673 Poisoning-bromine compnd",
                "threedigit": "967",
                "isLeaf": true
              },
              {
                "key": "9674",
                "title": "9674 Poisoning-methaqualone",
                "threedigit": "967",
                "isLeaf": true
              },
              {
                "key": "9675",
                "title": "9675 Poisoning-glutethimide",
                "threedigit": "967",
                "isLeaf": true
              },
              {
                "key": "9676",
                "title": "9676 Poison-mix sedative NEC",
                "threedigit": "967",
                "isLeaf": true
              },
              {
                "key": "9678",
                "title": "9678 Pois-sedative/hypnot NEC",
                "threedigit": "967",
                "isLeaf": true
              },
              {
                "key": "9679",
                "title": "9679 Pois-sedative/hypnot NOS",
                "threedigit": "967",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by other central nervous system depressants and anesthetics",
            "title": "968 - Poisoning by other central nervous system depressants and anesthetics",
            "threedigit": "968",
            "children": [
              {
                "key": "9680",
                "title": "9680 Pois-cns muscle depress",
                "threedigit": "968",
                "isLeaf": true
              },
              {
                "key": "9681",
                "title": "9681 Poisoning-halothane",
                "threedigit": "968",
                "isLeaf": true
              },
              {
                "key": "9682",
                "title": "9682 Poison-gas anesthet NEC",
                "threedigit": "968",
                "isLeaf": true
              },
              {
                "key": "9683",
                "title": "9683 Poison-intraven anesthet",
                "threedigit": "968",
                "isLeaf": true
              },
              {
                "key": "9684",
                "title": "9684 Pois-gen anesth NEC/NOS",
                "threedigit": "968",
                "isLeaf": true
              },
              {
                "key": "9685",
                "title": "9685 Surfce-topic/infilt anes",
                "threedigit": "968",
                "isLeaf": true
              },
              {
                "key": "9686",
                "title": "9686 Pois-nerve/plex-blk anes",
                "threedigit": "968",
                "isLeaf": true
              },
              {
                "key": "9687",
                "title": "9687 Poison-spinal anesthetic",
                "threedigit": "968",
                "isLeaf": true
              },
              {
                "key": "9689",
                "title": "9689 Pois-local anest NEC/NOS",
                "threedigit": "968",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by psychotropic agents",
            "title": "969 - Poisoning by psychotropic agents",
            "threedigit": "969",
            "children": [
              {
                "key": "96900",
                "title": "96900 Poison-antidepresnt NOS",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96901",
                "title": "96901 Pois monoamine oxidase",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96902",
                "title": "96902 Pois serotn/norepinephrn",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96903",
                "title": "96903 Pois serotinin reuptake",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96904",
                "title": "96904 Pois tetracyclc andepres",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96905",
                "title": "96905 Pois tricyclc antidepres",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96909",
                "title": "96909 Pois antidepressants NEC",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "9691",
                "title": "9691 Pois-phenothiazine tranq",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "9692",
                "title": "9692 Pois-butyrophenone tranq",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "9693",
                "title": "9693 Poison-antipsychotic NEC",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "9694",
                "title": "9694 Pois-benzodiazepine tran",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "9695",
                "title": "9695 Poison-tranquilizer NEC",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "9696",
                "title": "9696 Poisoning-hallucinogens",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96970",
                "title": "96970 Pois psychostimulant NOS",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96971",
                "title": "96971 Poisoning by caffeine",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96972",
                "title": "96972 Poisoning by amphetamine",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96973",
                "title": "96973 Poison by methylphendate",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "96979",
                "title": "96979 Poison by psychostim NEC",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "9698",
                "title": "9698 Poison-psychotropic NEC",
                "threedigit": "969",
                "isLeaf": true
              },
              {
                "key": "9699",
                "title": "9699 Poison-psychotropic NOS",
                "threedigit": "969",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by central nervous system stimulants",
            "title": "970 - Poisoning by central nervous system stimulants",
            "threedigit": "970",
            "children": [
              {
                "key": "9700",
                "title": "9700 Poisoning-analeptics",
                "threedigit": "970",
                "isLeaf": true
              },
              {
                "key": "9701",
                "title": "9701 Poison-opiate antagonist",
                "threedigit": "970",
                "isLeaf": true
              },
              {
                "key": "97081",
                "title": "97081 Poisoning by cocaine",
                "threedigit": "970",
                "isLeaf": true
              },
              {
                "key": "97089",
                "title": "97089 Poison-CNS stimulant NEC",
                "threedigit": "970",
                "isLeaf": true
              },
              {
                "key": "9709",
                "title": "9709 Pois-cns stimulant NOS",
                "threedigit": "970",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by drugs primarily affecting the autonomic nervous system",
            "title": "971 - Poisoning by drugs primarily affecting the autonomic nervous system",
            "threedigit": "971",
            "children": [
              {
                "key": "9710",
                "title": "9710 Pois-parasympathomimetic",
                "threedigit": "971",
                "isLeaf": true
              },
              {
                "key": "9711",
                "title": "9711 Pois-parasympatholytics",
                "threedigit": "971",
                "isLeaf": true
              },
              {
                "key": "9712",
                "title": "9712 Poison-sympathomimetics",
                "threedigit": "971",
                "isLeaf": true
              },
              {
                "key": "9713",
                "title": "9713 Poisoning-sympatholytics",
                "threedigit": "971",
                "isLeaf": true
              },
              {
                "key": "9719",
                "title": "9719 Pois-autonomic agent NOS",
                "threedigit": "971",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by agents primarily affecting the cardiovascular system",
            "title": "972 - Poisoning by agents primarily affecting the cardiovascular system",
            "threedigit": "972",
            "children": [
              {
                "key": "9720",
                "title": "9720 Pois-card rhythm regulat",
                "threedigit": "972",
                "isLeaf": true
              },
              {
                "key": "9721",
                "title": "9721 Poisoning-cardiotonics",
                "threedigit": "972",
                "isLeaf": true
              },
              {
                "key": "9722",
                "title": "9722 Poisoning-antilipemics",
                "threedigit": "972",
                "isLeaf": true
              },
              {
                "key": "9723",
                "title": "9723 Pois-ganglion block agt",
                "threedigit": "972",
                "isLeaf": true
              },
              {
                "key": "9724",
                "title": "9724 Pois-coronary vasodilat",
                "threedigit": "972",
                "isLeaf": true
              },
              {
                "key": "9725",
                "title": "9725 Poison-vasodilator NEC",
                "threedigit": "972",
                "isLeaf": true
              },
              {
                "key": "9726",
                "title": "9726 Pois-antihyperten agent",
                "threedigit": "972",
                "isLeaf": true
              },
              {
                "key": "9727",
                "title": "9727 Poison-antivaricose drug",
                "threedigit": "972",
                "isLeaf": true
              },
              {
                "key": "9728",
                "title": "9728 Poison-capillary act agt",
                "threedigit": "972",
                "isLeaf": true
              },
              {
                "key": "9729",
                "title": "9729 Pois-cardiovasc agt NEC",
                "threedigit": "972",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by agents primarily affecting the gastrointestinal system",
            "title": "973 - Poisoning by agents primarily affecting the gastrointestinal system",
            "threedigit": "973",
            "children": [
              {
                "key": "9730",
                "title": "9730 Pois-antacid/antigastric",
                "threedigit": "973",
                "isLeaf": true
              },
              {
                "key": "9731",
                "title": "9731 Pois-irritant cathartics",
                "threedigit": "973",
                "isLeaf": true
              },
              {
                "key": "9732",
                "title": "9732 Pois-emollient cathartic",
                "threedigit": "973",
                "isLeaf": true
              },
              {
                "key": "9733",
                "title": "9733 Poisoning-cathartic NEC",
                "threedigit": "973",
                "isLeaf": true
              },
              {
                "key": "9734",
                "title": "9734 Poisoning-digestants",
                "threedigit": "973",
                "isLeaf": true
              },
              {
                "key": "9735",
                "title": "9735 Poisoning-antidiarrh agt",
                "threedigit": "973",
                "isLeaf": true
              },
              {
                "key": "9736",
                "title": "9736 Poisoning-emetics",
                "threedigit": "973",
                "isLeaf": true
              },
              {
                "key": "9738",
                "title": "9738 Poisoning-gi agents NEC",
                "threedigit": "973",
                "isLeaf": true
              },
              {
                "key": "9739",
                "title": "9739 Poisoning-gi agent NOS",
                "threedigit": "973",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by water mineral and uric acid metabolism drugs",
            "title": "974 - Poisoning by water mineral and uric acid metabolism drugs",
            "threedigit": "974",
            "children": [
              {
                "key": "9740",
                "title": "9740 Pois-mercurial diuretics",
                "threedigit": "974",
                "isLeaf": true
              },
              {
                "key": "9741",
                "title": "9741 Pois-purine diuretics",
                "threedigit": "974",
                "isLeaf": true
              },
              {
                "key": "9742",
                "title": "9742 Pois-h2co3 anhydra inhib",
                "threedigit": "974",
                "isLeaf": true
              },
              {
                "key": "9743",
                "title": "9743 Poisoning-saluretics",
                "threedigit": "974",
                "isLeaf": true
              },
              {
                "key": "9744",
                "title": "9744 Poisoning-diuretics NEC",
                "threedigit": "974",
                "isLeaf": true
              },
              {
                "key": "9745",
                "title": "9745 Pois-electro/cal/wat agt",
                "threedigit": "974",
                "isLeaf": true
              },
              {
                "key": "9746",
                "title": "9746 Poison-mineral salts NEC",
                "threedigit": "974",
                "isLeaf": true
              },
              {
                "key": "9747",
                "title": "9747 Pois-uric acid metabol",
                "threedigit": "974",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by agents primarily acting on the smooth and skeletal muscles and respiratory system",
            "title": "975 - Poisoning by agents primarily acting on the smooth and skeletal muscles and respiratory system",
            "threedigit": "975",
            "children": [
              {
                "key": "9750",
                "title": "9750 Poisoning-oxytocic agent",
                "threedigit": "975",
                "isLeaf": true
              },
              {
                "key": "9751",
                "title": "9751 Pois-smooth muscle relax",
                "threedigit": "975",
                "isLeaf": true
              },
              {
                "key": "9752",
                "title": "9752 Pois-skelet muscle relax",
                "threedigit": "975",
                "isLeaf": true
              },
              {
                "key": "9753",
                "title": "9753 Poison-muscle agent NEC",
                "threedigit": "975",
                "isLeaf": true
              },
              {
                "key": "9754",
                "title": "9754 Poisoning-antitussives",
                "threedigit": "975",
                "isLeaf": true
              },
              {
                "key": "9755",
                "title": "9755 Poisoning-expectorants",
                "threedigit": "975",
                "isLeaf": true
              },
              {
                "key": "9756",
                "title": "9756 Pois-anti-cold drugs",
                "threedigit": "975",
                "isLeaf": true
              },
              {
                "key": "9757",
                "title": "9757 Poisoning-antiasthmatics",
                "threedigit": "975",
                "isLeaf": true
              },
              {
                "key": "9758",
                "title": "9758 Pois-respir drug NEC/NOS",
                "threedigit": "975",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by agents primarily affecting skin and mucous membrane ophthalmological otorhinolaryngological and dental drugs",
            "title": "976 - Poisoning by agents primarily affecting skin and mucous membrane ophthalmological otorhinolaryngological and dental drugs",
            "threedigit": "976",
            "children": [
              {
                "key": "9760",
                "title": "9760 Pois-local anti-infect",
                "threedigit": "976",
                "isLeaf": true
              },
              {
                "key": "9761",
                "title": "9761 Poisoning-antipruritics",
                "threedigit": "976",
                "isLeaf": true
              },
              {
                "key": "9762",
                "title": "9762 Pois-loc astring/deterg",
                "threedigit": "976",
                "isLeaf": true
              },
              {
                "key": "9763",
                "title": "9763 Pois-emol/demul/protect",
                "threedigit": "976",
                "isLeaf": true
              },
              {
                "key": "9764",
                "title": "9764 Poison-hair/scalp prep",
                "threedigit": "976",
                "isLeaf": true
              },
              {
                "key": "9765",
                "title": "9765 Pois-eye anti-infec/drug",
                "threedigit": "976",
                "isLeaf": true
              },
              {
                "key": "9766",
                "title": "9766 Poison-ent preparation",
                "threedigit": "976",
                "isLeaf": true
              },
              {
                "key": "9767",
                "title": "9767 Pois-topical dental drug",
                "threedigit": "976",
                "isLeaf": true
              },
              {
                "key": "9768",
                "title": "9768 Pois-skin/membr agnt NEC",
                "threedigit": "976",
                "isLeaf": true
              },
              {
                "key": "9769",
                "title": "9769 Pois-skin/membr agnt NOS",
                "threedigit": "976",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by other and unspecified drugs and medicinal substances",
            "title": "977 - Poisoning by other and unspecified drugs and medicinal substances",
            "threedigit": "977",
            "children": [
              {
                "key": "9770",
                "title": "9770 Poisoning-dietetics",
                "threedigit": "977",
                "isLeaf": true
              },
              {
                "key": "9771",
                "title": "9771 Poison-lipotropic drugs",
                "threedigit": "977",
                "isLeaf": true
              },
              {
                "key": "9772",
                "title": "9772 Poisoning-antidotes NEC",
                "threedigit": "977",
                "isLeaf": true
              },
              {
                "key": "9773",
                "title": "9773 Poison-alcohol deterrent",
                "threedigit": "977",
                "isLeaf": true
              },
              {
                "key": "9774",
                "title": "9774 Pois-pharmaceut excipien",
                "threedigit": "977",
                "isLeaf": true
              },
              {
                "key": "9778",
                "title": "9778 Poison-medicinal agt NEC",
                "threedigit": "977",
                "isLeaf": true
              },
              {
                "key": "9779",
                "title": "9779 Poison-medicinal agt NOS",
                "threedigit": "977",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by bacterial vaccines",
            "title": "978 - Poisoning by bacterial vaccines",
            "threedigit": "978",
            "children": [
              {
                "key": "9780",
                "title": "9780 Poisoning-bcg vaccine",
                "threedigit": "978",
                "isLeaf": true
              },
              {
                "key": "9781",
                "title": "9781 Pois-typh/paratyph vacc",
                "threedigit": "978",
                "isLeaf": true
              },
              {
                "key": "9782",
                "title": "9782 Poisoning-cholera vaccin",
                "threedigit": "978",
                "isLeaf": true
              },
              {
                "key": "9783",
                "title": "9783 Poisoning-plague vaccine",
                "threedigit": "978",
                "isLeaf": true
              },
              {
                "key": "9784",
                "title": "9784 Poisoning-tetanus vaccin",
                "threedigit": "978",
                "isLeaf": true
              },
              {
                "key": "9785",
                "title": "9785 Pois-diphtheria vaccine",
                "threedigit": "978",
                "isLeaf": true
              },
              {
                "key": "9786",
                "title": "9786 Pois-pertussis vaccine",
                "threedigit": "978",
                "isLeaf": true
              },
              {
                "key": "9788",
                "title": "9788 Pois-bact vaccin NEC/NOS",
                "threedigit": "978",
                "isLeaf": true
              },
              {
                "key": "9789",
                "title": "9789 Pois-mix bacter vaccines",
                "threedigit": "978",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by other vaccines and biological substances",
            "title": "979 - Poisoning by other vaccines and biological substances",
            "threedigit": "979",
            "children": [
              {
                "key": "9790",
                "title": "9790 Poison-smallpox vaccine",
                "threedigit": "979",
                "isLeaf": true
              },
              {
                "key": "9791",
                "title": "9791 Poison-rabies vaccine",
                "threedigit": "979",
                "isLeaf": true
              },
              {
                "key": "9792",
                "title": "9792 Poison-typhus vaccine",
                "threedigit": "979",
                "isLeaf": true
              },
              {
                "key": "9793",
                "title": "9793 Pois-yellow fever vaccin",
                "threedigit": "979",
                "isLeaf": true
              },
              {
                "key": "9794",
                "title": "9794 Poisoning-measles vaccin",
                "threedigit": "979",
                "isLeaf": true
              },
              {
                "key": "9795",
                "title": "9795 Pois-poliomyelit vaccine",
                "threedigit": "979",
                "isLeaf": true
              },
              {
                "key": "9796",
                "title": "9796 Pois-viral/rick vacc NEC",
                "threedigit": "979",
                "isLeaf": true
              },
              {
                "key": "9797",
                "title": "9797 Poisoning-mixed vaccine",
                "threedigit": "979",
                "isLeaf": true
              },
              {
                "key": "9799",
                "title": "9799 Pois-vaccine/biolog NEC",
                "threedigit": "979",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Toxic Effects Of Substances Chiefly Nonmedicinal As To Source",
        "title": "980-989 Toxic Effects Of Substances Chiefly Nonmedicinal As To Source",
        "threedigit": "980",
        "children": [
          {
            "key": "Toxic effect of alcohol",
            "title": "980 - Toxic effect of alcohol",
            "threedigit": "980",
            "children": [
              {
                "key": "9800",
                "title": "9800 Toxic eff ethyl alcohol",
                "threedigit": "980",
                "isLeaf": true
              },
              {
                "key": "9801",
                "title": "9801 Toxic eff methyl alcohol",
                "threedigit": "980",
                "isLeaf": true
              },
              {
                "key": "9802",
                "title": "9802 Toxic eff isopropyl alc",
                "threedigit": "980",
                "isLeaf": true
              },
              {
                "key": "9803",
                "title": "9803 Toxic effect fusel oil",
                "threedigit": "980",
                "isLeaf": true
              },
              {
                "key": "9808",
                "title": "9808 Toxic effect alcohol NEC",
                "threedigit": "980",
                "isLeaf": true
              },
              {
                "key": "9809",
                "title": "9809 Toxic effect alcohol NOS",
                "threedigit": "980",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Toxic effect of petroleum products",
            "title": "981 - Toxic effect of petroleum products",
            "threedigit": "981",
            "children": [
              {
                "key": "981",
                "title": "981 Toxic eff petroleum prod",
                "threedigit": "981",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Toxic effect of solvents other than petroleum-based",
            "title": "982 - Toxic effect of solvents other than petroleum-based",
            "threedigit": "982",
            "children": [
              {
                "key": "9820",
                "title": "9820 Toxic effect benzene",
                "threedigit": "982",
                "isLeaf": true
              },
              {
                "key": "9821",
                "title": "9821 Toxic eff carbon tetrach",
                "threedigit": "982",
                "isLeaf": true
              },
              {
                "key": "9822",
                "title": "9822 Toxic eff carbon disulfi",
                "threedigit": "982",
                "isLeaf": true
              },
              {
                "key": "9823",
                "title": "9823 Tx ef cl-hydcarb slv NEC",
                "threedigit": "982",
                "isLeaf": true
              },
              {
                "key": "9824",
                "title": "9824 Toxic effect nitroglycol",
                "threedigit": "982",
                "isLeaf": true
              },
              {
                "key": "9828",
                "title": "9828 Toxic eff nonpetrol solv",
                "threedigit": "982",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Toxic effect of corrosive aromatics acids and caustic alkalis",
            "title": "983 - Toxic effect of corrosive aromatics acids and caustic alkalis",
            "threedigit": "983",
            "children": [
              {
                "key": "9830",
                "title": "9830 Tox eff corrosive aromat",
                "threedigit": "983",
                "isLeaf": true
              },
              {
                "key": "9831",
                "title": "9831 Toxic effect acids",
                "threedigit": "983",
                "isLeaf": true
              },
              {
                "key": "9832",
                "title": "9832 Toxic eff caustic alkali",
                "threedigit": "983",
                "isLeaf": true
              },
              {
                "key": "9839",
                "title": "9839 Toxic effect caustic NOS",
                "threedigit": "983",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Toxic effect of lead and its compounds (including fumes)",
            "title": "984 - Toxic effect of lead and its compounds (including fumes)",
            "threedigit": "984",
            "children": [
              {
                "key": "9840",
                "title": "9840 Tx eff inorg lead compnd",
                "threedigit": "984",
                "isLeaf": true
              },
              {
                "key": "9841",
                "title": "9841 Tox eff org lead compnd",
                "threedigit": "984",
                "isLeaf": true
              },
              {
                "key": "9848",
                "title": "9848 Tox eff lead compnd NEC",
                "threedigit": "984",
                "isLeaf": true
              },
              {
                "key": "9849",
                "title": "9849 Tox eff lead compnd NOS",
                "threedigit": "984",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Toxic effect of other metals",
            "title": "985 - Toxic effect of other metals",
            "threedigit": "985",
            "children": [
              {
                "key": "9850",
                "title": "9850 Toxic effect mercury",
                "threedigit": "985",
                "isLeaf": true
              },
              {
                "key": "9851",
                "title": "9851 Toxic effect arsenic",
                "threedigit": "985",
                "isLeaf": true
              },
              {
                "key": "9852",
                "title": "9852 Toxic effect manganese",
                "threedigit": "985",
                "isLeaf": true
              },
              {
                "key": "9853",
                "title": "9853 Toxic effect beryllium",
                "threedigit": "985",
                "isLeaf": true
              },
              {
                "key": "9854",
                "title": "9854 Toxic effect antimony",
                "threedigit": "985",
                "isLeaf": true
              },
              {
                "key": "9855",
                "title": "9855 Toxic effect cadmium",
                "threedigit": "985",
                "isLeaf": true
              },
              {
                "key": "9856",
                "title": "9856 Toxic effect chromium",
                "threedigit": "985",
                "isLeaf": true
              },
              {
                "key": "9858",
                "title": "9858 Toxic effect metals NEC",
                "threedigit": "985",
                "isLeaf": true
              },
              {
                "key": "9859",
                "title": "9859 Toxic effect metal NOS",
                "threedigit": "985",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Toxic effect of carbon monoxide",
            "title": "986 - Toxic effect of carbon monoxide",
            "threedigit": "986",
            "children": [
              {
                "key": "986",
                "title": "986 Tox eff carbon monoxide",
                "threedigit": "986",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Toxic effect of other gases fumes or vapors",
            "title": "987 - Toxic effect of other gases fumes or vapors",
            "threedigit": "987",
            "children": [
              {
                "key": "9870",
                "title": "9870 Toxic eff liq petrol gas",
                "threedigit": "987",
                "isLeaf": true
              },
              {
                "key": "9871",
                "title": "9871 Tox ef hydrocarb gas NEC",
                "threedigit": "987",
                "isLeaf": true
              },
              {
                "key": "9872",
                "title": "9872 Toxic eff nitrogen oxide",
                "threedigit": "987",
                "isLeaf": true
              },
              {
                "key": "9873",
                "title": "9873 Toxic eff sulfur dioxide",
                "threedigit": "987",
                "isLeaf": true
              },
              {
                "key": "9874",
                "title": "9874 Toxic effect freon",
                "threedigit": "987",
                "isLeaf": true
              },
              {
                "key": "9875",
                "title": "9875 Tox eff lacrimogenic gas",
                "threedigit": "987",
                "isLeaf": true
              },
              {
                "key": "9876",
                "title": "9876 Toxic eff chlorine gas",
                "threedigit": "987",
                "isLeaf": true
              },
              {
                "key": "9877",
                "title": "9877 Tox eff hydrocyan acd gs",
                "threedigit": "987",
                "isLeaf": true
              },
              {
                "key": "9878",
                "title": "9878 Toxic eff gas/vapor NEC",
                "threedigit": "987",
                "isLeaf": true
              },
              {
                "key": "9879",
                "title": "9879 Toxic eff gas/vapor NOS",
                "threedigit": "987",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Toxic effect of noxious substances eaten as food",
            "title": "988 - Toxic effect of noxious substances eaten as food",
            "threedigit": "988",
            "children": [
              {
                "key": "9880",
                "title": "9880 Toxic eff fish/shellfish",
                "threedigit": "988",
                "isLeaf": true
              },
              {
                "key": "9881",
                "title": "9881 Toxic effect mushrooms",
                "threedigit": "988",
                "isLeaf": true
              },
              {
                "key": "9882",
                "title": "9882 Tox eff berry/plant NEC",
                "threedigit": "988",
                "isLeaf": true
              },
              {
                "key": "9888",
                "title": "9888 Tox eff noxious food NEC",
                "threedigit": "988",
                "isLeaf": true
              },
              {
                "key": "9889",
                "title": "9889 Tox eff noxious food NOS",
                "threedigit": "988",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Toxic effect of other substances chiefly nonmedicinal as to source",
            "title": "989 - Toxic effect of other substances chiefly nonmedicinal as to source",
            "threedigit": "989",
            "children": [
              {
                "key": "9890",
                "title": "9890 Toxic effect cyanides",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "9891",
                "title": "9891 Toxic effect strychnine",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "9892",
                "title": "9892 Tox eff chlor hydrocarb",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "9893",
                "title": "9893 Tox eff organphos/carbam",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "9894",
                "title": "9894 Toxic eff pesticides NEC",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "9895",
                "title": "9895 Toxic effect venom",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "9896",
                "title": "9896 Toxic eff soap/detergent",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "9897",
                "title": "9897 Tox eff aflatox/mycotox",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "98981",
                "title": "98981 Toxic effect of asbestos",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "98982",
                "title": "98982 Toxic effect of latex",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "98983",
                "title": "98983 Toxic effect of silicone",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "98984",
                "title": "98984 Toxic effect of tobacco",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "98989",
                "title": "98989 Tox eff nonmed subst NEC",
                "threedigit": "989",
                "isLeaf": true
              },
              {
                "key": "9899",
                "title": "9899 Tox eff nonmed subst NOS",
                "threedigit": "989",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other And Unspecified Effects Of External Causes",
        "title": "990-995 Other And Unspecified Effects Of External Causes",
        "threedigit": "990",
        "children": [
          {
            "key": "Effects of radiation, unspecified",
            "title": "990 - Effects of radiation, unspecified",
            "threedigit": "990",
            "children": [
              {
                "key": "990",
                "title": "990 Effects radiation NOS",
                "threedigit": "990",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Effects of reduced temperature",
            "title": "991 - Effects of reduced temperature",
            "threedigit": "991",
            "children": [
              {
                "key": "9910",
                "title": "9910 Frostbite of face",
                "threedigit": "991",
                "isLeaf": true
              },
              {
                "key": "9911",
                "title": "9911 Frostbite of hand",
                "threedigit": "991",
                "isLeaf": true
              },
              {
                "key": "9912",
                "title": "9912 Frostbite of foot",
                "threedigit": "991",
                "isLeaf": true
              },
              {
                "key": "9913",
                "title": "9913 Frostbite NEC/NOS",
                "threedigit": "991",
                "isLeaf": true
              },
              {
                "key": "9914",
                "title": "9914 Immersion foot",
                "threedigit": "991",
                "isLeaf": true
              },
              {
                "key": "9915",
                "title": "9915 Chilblains",
                "threedigit": "991",
                "isLeaf": true
              },
              {
                "key": "9916",
                "title": "9916 Hypothermia",
                "threedigit": "991",
                "isLeaf": true
              },
              {
                "key": "9918",
                "title": "9918 Effect reduced temp NEC",
                "threedigit": "991",
                "isLeaf": true
              },
              {
                "key": "9919",
                "title": "9919 Effect reduced temp NOS",
                "threedigit": "991",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Effects of heat and light",
            "title": "992 - Effects of heat and light",
            "threedigit": "992",
            "children": [
              {
                "key": "9920",
                "title": "9920 Heat stroke & sunstroke",
                "threedigit": "992",
                "isLeaf": true
              },
              {
                "key": "9921",
                "title": "9921 Heat syncope",
                "threedigit": "992",
                "isLeaf": true
              },
              {
                "key": "9922",
                "title": "9922 Heat cramps",
                "threedigit": "992",
                "isLeaf": true
              },
              {
                "key": "9923",
                "title": "9923 Heat exhaust-anhydrotic",
                "threedigit": "992",
                "isLeaf": true
              },
              {
                "key": "9924",
                "title": "9924 Heat exhaust-salt deple",
                "threedigit": "992",
                "isLeaf": true
              },
              {
                "key": "9925",
                "title": "9925 Heat exhaustion NOS",
                "threedigit": "992",
                "isLeaf": true
              },
              {
                "key": "9926",
                "title": "9926 Heat fatigue, transient",
                "threedigit": "992",
                "isLeaf": true
              },
              {
                "key": "9927",
                "title": "9927 Heat edema",
                "threedigit": "992",
                "isLeaf": true
              },
              {
                "key": "9928",
                "title": "9928 Heat effect NEC",
                "threedigit": "992",
                "isLeaf": true
              },
              {
                "key": "9929",
                "title": "9929 Heat effect NOS",
                "threedigit": "992",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Effects of air pressure",
            "title": "993 - Effects of air pressure",
            "threedigit": "993",
            "children": [
              {
                "key": "9930",
                "title": "9930 Barotrauma, otitic",
                "threedigit": "993",
                "isLeaf": true
              },
              {
                "key": "9931",
                "title": "9931 Barotrauma, sinus",
                "threedigit": "993",
                "isLeaf": true
              },
              {
                "key": "9932",
                "title": "9932 Eff high altitud NEC/NOS",
                "threedigit": "993",
                "isLeaf": true
              },
              {
                "key": "9933",
                "title": "9933 Caisson disease",
                "threedigit": "993",
                "isLeaf": true
              },
              {
                "key": "9934",
                "title": "9934 Eff air press by explos",
                "threedigit": "993",
                "isLeaf": true
              },
              {
                "key": "9938",
                "title": "9938 Effect air pressure NEC",
                "threedigit": "993",
                "isLeaf": true
              },
              {
                "key": "9939",
                "title": "9939 Effect air pressure NOS",
                "threedigit": "993",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Effects of other external causes",
            "title": "994 - Effects of other external causes",
            "threedigit": "994",
            "children": [
              {
                "key": "9940",
                "title": "9940 Effects of lightning",
                "threedigit": "994",
                "isLeaf": true
              },
              {
                "key": "9941",
                "title": "9941 Drowning/nonfatal submer",
                "threedigit": "994",
                "isLeaf": true
              },
              {
                "key": "9942",
                "title": "9942 Effects of hunger",
                "threedigit": "994",
                "isLeaf": true
              },
              {
                "key": "9943",
                "title": "9943 Effects of thirst",
                "threedigit": "994",
                "isLeaf": true
              },
              {
                "key": "9944",
                "title": "9944 Exhaustion-exposure",
                "threedigit": "994",
                "isLeaf": true
              },
              {
                "key": "9945",
                "title": "9945 Exhaustion-excess exert",
                "threedigit": "994",
                "isLeaf": true
              },
              {
                "key": "9946",
                "title": "9946 Motion sickness",
                "threedigit": "994",
                "isLeaf": true
              },
              {
                "key": "9947",
                "title": "9947 Asphyxiation/strangulat",
                "threedigit": "994",
                "isLeaf": true
              },
              {
                "key": "9948",
                "title": "9948 Effects electric current",
                "threedigit": "994",
                "isLeaf": true
              },
              {
                "key": "9949",
                "title": "9949 Effect external caus NEC",
                "threedigit": "994",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Certain adverse effects not elsewhere classified",
            "title": "995 - Certain adverse effects not elsewhere classified",
            "threedigit": "995",
            "children": [
              {
                "key": "9950",
                "title": "9950 Other anaphylactic react",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "9951",
                "title": "9951 Angioneurotic edema",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99520",
                "title": "99520 Adv eff med/biol sub NOS",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99521",
                "title": "99521 Arthus phenomenon",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99522",
                "title": "99522 Adv eff anesthesia NOS",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99523",
                "title": "99523 Adverse eff insulin NOS",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99524",
                "title": "99524 Fail mod sedate dur proc",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99527",
                "title": "99527 Drug allergy NEC",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99529",
                "title": "99529 Adv eff med/biol NEC/NOS",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "9953",
                "title": "9953 Allergy, unspecified",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "9954",
                "title": "9954 Shock due to anesthesia",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99550",
                "title": "99550 Child abuse NOS",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99551",
                "title": "99551 Child emotnl/psych abuse",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99552",
                "title": "99552 Child neglect-nutrition",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99553",
                "title": "99553 Child sexual abuse",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99554",
                "title": "99554 Child physical abuse",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99555",
                "title": "99555 Shaken infant syndrome",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99559",
                "title": "99559 Child abuse/neglect NEC",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99560",
                "title": "99560 Anphylct react food NOS",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99561",
                "title": "99561 Anphylct react peanuts",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99562",
                "title": "99562 Anphylct react crstacns",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99563",
                "title": "99563 Anphylct react frts veg",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99564",
                "title": "99564 Anphyl react tr nts seed",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99565",
                "title": "99565 Anphylct reaction fish",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99566",
                "title": "99566 Anphylct react food addv",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99567",
                "title": "99567 Anphylct react milk prod",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99568",
                "title": "99568 Anphylct reaction eggs",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99569",
                "title": "99569 Anphyl react oth sp food",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "9957",
                "title": "9957 Adverse food react NEC",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99580",
                "title": "99580 Adult maltreatment NOS",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99581",
                "title": "99581 Adult physical abuse",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99582",
                "title": "99582 Adult emotnl/psych abuse",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99583",
                "title": "99583 Adult sexual abuse",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99584",
                "title": "99584 Adult neglect-nutrition",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99585",
                "title": "99585 Oth adult abuse/neglect",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99586",
                "title": "99586 Malignant hyperthermia",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99589",
                "title": "99589 Adverse effect NEC",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99590",
                "title": "99590 SIRS, NOS",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99591",
                "title": "99591 Sepsis",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99592",
                "title": "99592 Severe sepsis",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99593",
                "title": "99593 SIRS-noninf w/o ac or ds",
                "threedigit": "995",
                "isLeaf": true
              },
              {
                "key": "99594",
                "title": "99594 SIRS-noninf w ac org dys",
                "threedigit": "995",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Complications Of Surgical And Medical Care, Not Elsewhere Classified",
        "title": "996-999 Complications Of Surgical And Medical Care, Not Elsewhere Classified",
        "threedigit": "996",
        "children": [
          {
            "key": "Complications peculiar to certain specified procedures",
            "title": "996 - Complications peculiar to certain specified procedures",
            "threedigit": "996",
            "children": [
              {
                "key": "99600",
                "title": "99600 Malfunc card dev/grf NOS",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99601",
                "title": "99601 Malfunc cardiac pacemake",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99602",
                "title": "99602 Malfunc prosth hrt valve",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99603",
                "title": "99603 Malfunc coron bypass grf",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99604",
                "title": "99604 Mch cmp autm mplnt dfbrl",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99609",
                "title": "99609 Malfunc card dev/grf NEC",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "9961",
                "title": "9961 Malfunc vasc device/graf",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "9962",
                "title": "9962 Malfun neuro device/graf",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99630",
                "title": "99630 Malfunc gu dev/graft NOS",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99631",
                "title": "99631 Malfunc urethral cath",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99632",
                "title": "99632 Malfunction iud",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99639",
                "title": "99639 Malfunc gu dev/graft NEC",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99640",
                "title": "99640 Cmp int orth dev/gft NOS",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99641",
                "title": "99641 Mech loosening pros jt",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99642",
                "title": "99642 Dislocate prosthetic jt",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99643",
                "title": "99643 Broke prosthtc jt implnt",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99644",
                "title": "99644 Periprosthetc fx-pros jt",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99645",
                "title": "99645 Periprosthetc osteolysis",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99646",
                "title": "99646 Articular wear prosth jt",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99647",
                "title": "99647 Mech com pros jt implant",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99649",
                "title": "99649 Mech com orth dev NEC",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99651",
                "title": "99651 Corneal grft malfunction",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99652",
                "title": "99652 Oth tissue graft malfunc",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99653",
                "title": "99653 Lens prosthesis malfunc",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99654",
                "title": "99654 Breast prosth malfunc",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99655",
                "title": "99655 Comp-artificial skin grf",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99656",
                "title": "99656 Comp-periton dialys cath",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99657",
                "title": "99657 Complcation-insulin pump",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99659",
                "title": "99659 Malfunc oth device/graft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99660",
                "title": "99660 Reaction-unsp devic/grft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99661",
                "title": "99661 React-cardiac dev/graft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99662",
                "title": "99662 React-oth vasc dev/graft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99663",
                "title": "99663 React-nerv sys dev/graft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99664",
                "title": "99664 React-indwell urin cath",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99665",
                "title": "99665 React-oth genitourin dev",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99666",
                "title": "99666 React-inter joint prost",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99667",
                "title": "99667 React-oth int ortho dev",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99668",
                "title": "99668 React-periton dialy cath",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99669",
                "title": "99669 React-int pros devic NEC",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99670",
                "title": "99670 Comp-unsp device/graft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99671",
                "title": "99671 Comp-heart valve prosth",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99672",
                "title": "99672 Comp-oth cardiac device",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99673",
                "title": "99673 Comp-ren dialys dev/grft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99674",
                "title": "99674 Comp-oth vasc dev/graft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99675",
                "title": "99675 Comp-nerv sys dev/graft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99676",
                "title": "99676 Comp-genitourin dev/grft",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99677",
                "title": "99677 Comp-internal joint pros",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99678",
                "title": "99678 Comp-oth int ortho devic",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99679",
                "title": "99679 Comp-int prost devic NEC",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99680",
                "title": "99680 Comp organ transplnt NOS",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99681",
                "title": "99681 Compl kidney transplant",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99682",
                "title": "99682 Compl liver transplant",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99683",
                "title": "99683 Compl heart transplant",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99684",
                "title": "99684 Compl lung transplant",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99685",
                "title": "99685 Compl marrow transplant",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99686",
                "title": "99686 Compl pancreas transplnt",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99687",
                "title": "99687 Comp intestine transplnt",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99688",
                "title": "99688 Comp tp organ-stem cell",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99689",
                "title": "99689 Comp oth organ transplnt",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99690",
                "title": "99690 Comp reattach extrem NOS",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99691",
                "title": "99691 Compl reattached forearm",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99692",
                "title": "99692 Compl reattached hand",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99693",
                "title": "99693 Compl reattached finger",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99694",
                "title": "99694 Compl reattached arm NEC",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99695",
                "title": "99695 Compl reattached foot",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99696",
                "title": "99696 Compl reattached leg NEC",
                "threedigit": "996",
                "isLeaf": true
              },
              {
                "key": "99699",
                "title": "99699 Compl reattach part NEC",
                "threedigit": "996",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Complications affecting specified body system not elsewhere classified",
            "title": "997 - Complications affecting specified body system not elsewhere classified",
            "threedigit": "997",
            "children": [
              {
                "key": "99700",
                "title": "99700 Nervous syst complc NOS",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99701",
                "title": "99701 Surg complication - cns",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99702",
                "title": "99702 Iatrogen CV infarc/hmrhg",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99709",
                "title": "99709 Surg comp nerv systm NEC",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "9971",
                "title": "9971 Surg compl-heart",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "9972",
                "title": "9972 Surg comp-peri vasc syst",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99731",
                "title": "99731 Ventltr assoc pneumonia",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99732",
                "title": "99732 Postproc aspiration pneu",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99739",
                "title": "99739 Respiratory comp NEC",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99741",
                "title": "99741 Ret cholelh fol cholecys",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99749",
                "title": "99749 Oth digestv system comp",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "9975",
                "title": "9975 Surg compl-urinary tract",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99760",
                "title": "99760 Amputat stump compl NOS",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99761",
                "title": "99761 Neuroma amputation stump",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99762",
                "title": "99762 Infection amputat stump",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99769",
                "title": "99769 Amputat stump compl NEC",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99771",
                "title": "99771 Vasc comp mesenteric art",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99772",
                "title": "99772 Vasc comp renal artery",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99779",
                "title": "99779 Vascular comp vessel NEC",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99791",
                "title": "99791 Surg comp - hypertension",
                "threedigit": "997",
                "isLeaf": true
              },
              {
                "key": "99799",
                "title": "99799 Surg compl-body syst NEC",
                "threedigit": "997",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other complications of procedures not elsewhere classified",
            "title": "998 - Other complications of procedures not elsewhere classified",
            "threedigit": "998",
            "children": [
              {
                "key": "99800",
                "title": "99800 Postoperative shock, NOS",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99801",
                "title": "99801 Postop shock,cardiogenic",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99802",
                "title": "99802 Postop shock, septic",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99809",
                "title": "99809 Postop shock, other",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99811",
                "title": "99811 Hemorrhage complic proc",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99812",
                "title": "99812 Hematoma complic proc",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99813",
                "title": "99813 Seroma complicting proc",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "9982",
                "title": "9982 Accidental op laceration",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99830",
                "title": "99830 Wound disruption NOS",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99831",
                "title": "99831 Disrup internal op wound",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99832",
                "title": "99832 Disrup-external op wound",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99833",
                "title": "99833 Disrpt trauma wound repr",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "9984",
                "title": "9984 FB left during procedure",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99851",
                "title": "99851 Infected postop seroma",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99859",
                "title": "99859 Other postop infection",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "9986",
                "title": "9986 Persist postop fistula",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "9987",
                "title": "9987 Postop forgn subst react",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99881",
                "title": "99881 Emphysema rsult frm proc",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99882",
                "title": "99882 Ctrct frgmt frm ctr surg",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99883",
                "title": "99883 Non-healing surgcl wound",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "99889",
                "title": "99889 Oth spcf cmplc procd NEC",
                "threedigit": "998",
                "isLeaf": true
              },
              {
                "key": "9989",
                "title": "9989 Surgical complicat NOS",
                "threedigit": "998",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Complications of medical care not elsewhere classified",
            "title": "999 - Complications of medical care not elsewhere classified",
            "threedigit": "999",
            "children": [
              {
                "key": "9990",
                "title": "9990 Generalized vaccinia",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "9991",
                "title": "9991 Air embol comp med care",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "9992",
                "title": "9992 Vasc comp med care NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99931",
                "title": "99931 Oth/uns inf-cen ven cath",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99932",
                "title": "99932 Blood inf dt cen ven cth",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99933",
                "title": "99933 Lcl inf dt cen ven cth",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99934",
                "title": "99934 Ac inf fol trans,inf bld",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99939",
                "title": "99939 Infect fol infus/inj/vac",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99941",
                "title": "99941 Anaphyl d/t adm bld/prod",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99942",
                "title": "99942 Anaphyl react d/t vaccin",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99949",
                "title": "99949 Anaph react d/t ot serum",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99951",
                "title": "99951 Ot serum react d/t blood",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99952",
                "title": "99952 Ot serum react d/t vacc",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99959",
                "title": "99959 Other serum reaction",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99960",
                "title": "99960 Abo incompat react NOS",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99961",
                "title": "99961 Abo incomp/HTR NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99962",
                "title": "99962 Abo incompat/acute HTR",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99963",
                "title": "99963 Abo incompat/delay HTR",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99969",
                "title": "99969 Abo incompat reactn NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99970",
                "title": "99970 Rh incompat reaction NOS",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99971",
                "title": "99971 Rh incomp/HTR NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99972",
                "title": "99972 Rh incompat/acute HTR",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99973",
                "title": "99973 Rh incompat/delay HTR",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99974",
                "title": "99974 Rh incompat reaction NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99975",
                "title": "99975 Non-abo incomp react NOS",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99976",
                "title": "99976 Non-abo incomp/HTR NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99977",
                "title": "99977 Non-abo incomp/acute HTR",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99978",
                "title": "99978 Non-abo incomp/delay HTR",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99979",
                "title": "99979 Non-abo incomp react NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99980",
                "title": "99980 Transfusion reaction NOS",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99981",
                "title": "99981 Extravstn vesicant chemo",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99982",
                "title": "99982 Extravasn vesicant NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99983",
                "title": "99983 Hemolytc trans react NOS",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99984",
                "title": "99984 Acute HTR NOS",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99985",
                "title": "99985 Delayed HTR NOS",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99988",
                "title": "99988 Infusion reaction NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "99989",
                "title": "99989 Transfusion reaction NEC",
                "threedigit": "999",
                "isLeaf": true
              },
              {
                "key": "9999",
                "title": "9999 Complic med care NEC/NOS",
                "threedigit": "999",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Supplementary Classification Of External Causes Of Injury And Poisoning",
    "title": "E000-E999 Supplementary Classification Of External Causes Of Injury And Poisoning",
    "threedigit": "E000",
    "children": [
      {
        "key": "External Cause Status",
        "title": "E000-E000 External Cause Status",
        "threedigit": "E000",
        "children": [
          {
            "key": "External cause status",
            "title": "E000 - External cause status",
            "threedigit": "E000",
            "children": [
              {
                "key": "E0000",
                "title": "E0000 Civilian activity-income",
                "threedigit": "E000",
                "isLeaf": true
              },
              {
                "key": "E0001",
                "title": "E0001 Military activity",
                "threedigit": "E000",
                "isLeaf": true
              },
              {
                "key": "E0002",
                "title": "E0002 Volunteer activity",
                "threedigit": "E000",
                "isLeaf": true
              },
              {
                "key": "E0008",
                "title": "E0008 Externl cause status NEC",
                "threedigit": "E000",
                "isLeaf": true
              },
              {
                "key": "E0009",
                "title": "E0009 Externl cause status NOS",
                "threedigit": "E000",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Activity",
        "title": "E001-E030 Activity",
        "threedigit": "E001",
        "children": [
          {
            "key": "Activities involving walking and running",
            "title": "E001 - Activities involving walking and running",
            "threedigit": "E001",
            "children": [
              {
                "key": "E0010",
                "title": "E0010 Walking,marching,hiking",
                "threedigit": "E001",
                "isLeaf": true
              },
              {
                "key": "E0011",
                "title": "E0011 Running",
                "threedigit": "E001",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving water and water craft",
            "title": "E002 - Activities involving water and water craft",
            "threedigit": "E002",
            "children": [
              {
                "key": "E0020",
                "title": "E0020 Swimming",
                "threedigit": "E002",
                "isLeaf": true
              },
              {
                "key": "E0021",
                "title": "E0021 Springboard/platfrm dive",
                "threedigit": "E002",
                "isLeaf": true
              },
              {
                "key": "E0022",
                "title": "E0022 Water polo",
                "threedigit": "E002",
                "isLeaf": true
              },
              {
                "key": "E0023",
                "title": "E0023 Water aerobics/exercise",
                "threedigit": "E002",
                "isLeaf": true
              },
              {
                "key": "E0024",
                "title": "E0024 Underwater dive/snorkel",
                "threedigit": "E002",
                "isLeaf": true
              },
              {
                "key": "E0025",
                "title": "E0025 Row,canoe,kayk,raft,tube",
                "threedigit": "E002",
                "isLeaf": true
              },
              {
                "key": "E0026",
                "title": "E0026 Water ski/wake boarding",
                "threedigit": "E002",
                "isLeaf": true
              },
              {
                "key": "E0027",
                "title": "E0027 Surf,windsrf,boogie brd",
                "threedigit": "E002",
                "isLeaf": true
              },
              {
                "key": "E0028",
                "title": "E0028 Water sliding",
                "threedigit": "E002",
                "isLeaf": true
              },
              {
                "key": "E0029",
                "title": "E0029 Water/watercraft",
                "threedigit": "E002",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving snow and ice",
            "title": "E003 - Activities involving snow and ice",
            "threedigit": "E003",
            "children": [
              {
                "key": "E0030",
                "title": "E0030 Ice Skating",
                "threedigit": "E003",
                "isLeaf": true
              },
              {
                "key": "E0031",
                "title": "E0031 Ice hockey",
                "threedigit": "E003",
                "isLeaf": true
              },
              {
                "key": "E0032",
                "title": "E0032 Ski,snow brd,sled,tobagn",
                "threedigit": "E003",
                "isLeaf": true
              },
              {
                "key": "E0033",
                "title": "E0033 Cross country skiing",
                "threedigit": "E003",
                "isLeaf": true
              },
              {
                "key": "E0039",
                "title": "E0039 Ice and snow",
                "threedigit": "E003",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving climbing, rappelling and jumping off",
            "title": "E004 - Activities involving climbing, rappelling and jumping off",
            "threedigit": "E004",
            "children": [
              {
                "key": "E0040",
                "title": "E0040 Mountain,rock/wall climb",
                "threedigit": "E004",
                "isLeaf": true
              },
              {
                "key": "E0041",
                "title": "E0041 Rappelling",
                "threedigit": "E004",
                "isLeaf": true
              },
              {
                "key": "E0042",
                "title": "E0042 BASE jumping",
                "threedigit": "E004",
                "isLeaf": true
              },
              {
                "key": "E0043",
                "title": "E0043 Bungee jumping",
                "threedigit": "E004",
                "isLeaf": true
              },
              {
                "key": "E0044",
                "title": "E0044 Hang gliding",
                "threedigit": "E004",
                "isLeaf": true
              },
              {
                "key": "E0049",
                "title": "E0049 Climb,rappell,jump off",
                "threedigit": "E004",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving dancing and other rhythmic movement",
            "title": "E005 - Activities involving dancing and other rhythmic movement",
            "threedigit": "E005",
            "children": [
              {
                "key": "E0050",
                "title": "E0050 Dancing",
                "threedigit": "E005",
                "isLeaf": true
              },
              {
                "key": "E0051",
                "title": "E0051 Yoga",
                "threedigit": "E005",
                "isLeaf": true
              },
              {
                "key": "E0052",
                "title": "E0052 Gymnastics",
                "threedigit": "E005",
                "isLeaf": true
              },
              {
                "key": "E0053",
                "title": "E0053 Trampoline",
                "threedigit": "E005",
                "isLeaf": true
              },
              {
                "key": "E0054",
                "title": "E0054 Cheerleading",
                "threedigit": "E005",
                "isLeaf": true
              },
              {
                "key": "E0059",
                "title": "E0059 Dancing,rhythm movements",
                "threedigit": "E005",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving other sports and athletics played individually",
            "title": "E006 - Activities involving other sports and athletics played individually",
            "threedigit": "E006",
            "children": [
              {
                "key": "E0060",
                "title": "E0060 Roller skate,skateboard",
                "threedigit": "E006",
                "isLeaf": true
              },
              {
                "key": "E0061",
                "title": "E0061 Horseback riding",
                "threedigit": "E006",
                "isLeaf": true
              },
              {
                "key": "E0062",
                "title": "E0062 Golf",
                "threedigit": "E006",
                "isLeaf": true
              },
              {
                "key": "E0063",
                "title": "E0063 Bowling",
                "threedigit": "E006",
                "isLeaf": true
              },
              {
                "key": "E0064",
                "title": "E0064 Bike riding",
                "threedigit": "E006",
                "isLeaf": true
              },
              {
                "key": "E0065",
                "title": "E0065 Jumping rope",
                "threedigit": "E006",
                "isLeaf": true
              },
              {
                "key": "E0066",
                "title": "E0066 Non-running track/field",
                "threedigit": "E006",
                "isLeaf": true
              },
              {
                "key": "E0069",
                "title": "E0069 Individ sports,athletics",
                "threedigit": "E006",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving other sports and athletics played as a team or group",
            "title": "E007 - Activities involving other sports and athletics played as a team or group",
            "threedigit": "E007",
            "children": [
              {
                "key": "E0070",
                "title": "E0070 Tackle football",
                "threedigit": "E007",
                "isLeaf": true
              },
              {
                "key": "E0071",
                "title": "E0071 Amer flag/touch football",
                "threedigit": "E007",
                "isLeaf": true
              },
              {
                "key": "E0072",
                "title": "E0072 Rugby",
                "threedigit": "E007",
                "isLeaf": true
              },
              {
                "key": "E0073",
                "title": "E0073 Baseball",
                "threedigit": "E007",
                "isLeaf": true
              },
              {
                "key": "E0074",
                "title": "E0074 Lacrosse,field hockey",
                "threedigit": "E007",
                "isLeaf": true
              },
              {
                "key": "E0075",
                "title": "E0075 Soccer",
                "threedigit": "E007",
                "isLeaf": true
              },
              {
                "key": "E0076",
                "title": "E0076 Basketball",
                "threedigit": "E007",
                "isLeaf": true
              },
              {
                "key": "E0077",
                "title": "E0077 Volleyball (beach,court)",
                "threedigit": "E007",
                "isLeaf": true
              },
              {
                "key": "E0078",
                "title": "E0078 School recess,camp",
                "threedigit": "E007",
                "isLeaf": true
              },
              {
                "key": "E0079",
                "title": "E0079 Team sports,athletes",
                "threedigit": "E007",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving other specified sports and athletics",
            "title": "E008 - Activities involving other specified sports and athletics",
            "threedigit": "E008",
            "children": [
              {
                "key": "E0080",
                "title": "E0080 Boxing",
                "threedigit": "E008",
                "isLeaf": true
              },
              {
                "key": "E0081",
                "title": "E0081 Wrestling",
                "threedigit": "E008",
                "isLeaf": true
              },
              {
                "key": "E0082",
                "title": "E0082 Racquet/hand sports",
                "threedigit": "E008",
                "isLeaf": true
              },
              {
                "key": "E0083",
                "title": "E0083 Frisbee",
                "threedigit": "E008",
                "isLeaf": true
              },
              {
                "key": "E0084",
                "title": "E0084 Martial arts",
                "threedigit": "E008",
                "isLeaf": true
              },
              {
                "key": "E0089",
                "title": "E0089 Sports,athletics NEC",
                "threedigit": "E008",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activity involving other cardiorespiratory exercise",
            "title": "E009 - Activity involving other cardiorespiratory exercise",
            "threedigit": "E009",
            "children": [
              {
                "key": "E0090",
                "title": "E0090 Exercise mach(cardiorsp)",
                "threedigit": "E009",
                "isLeaf": true
              },
              {
                "key": "E0091",
                "title": "E0091 Calisthenics",
                "threedigit": "E009",
                "isLeaf": true
              },
              {
                "key": "E0092",
                "title": "E0092 Aerobic,step exercise",
                "threedigit": "E009",
                "isLeaf": true
              },
              {
                "key": "E0093",
                "title": "E0093 Circuit training",
                "threedigit": "E009",
                "isLeaf": true
              },
              {
                "key": "E0094",
                "title": "E0094 Obstacle course",
                "threedigit": "E009",
                "isLeaf": true
              },
              {
                "key": "E0095",
                "title": "E0095 Grass drills",
                "threedigit": "E009",
                "isLeaf": true
              },
              {
                "key": "E0099",
                "title": "E0099 Cardioresp exercise NEC",
                "threedigit": "E009",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activity involving other muscle strengthening exercises",
            "title": "E010 - Activity involving other muscle strengthening exercises",
            "threedigit": "E010",
            "children": [
              {
                "key": "E0100",
                "title": "E0100 Muscle strength mach",
                "threedigit": "E010",
                "isLeaf": true
              },
              {
                "key": "E0101",
                "title": "E0101 Push-ups,pull-ups,sit-up",
                "threedigit": "E010",
                "isLeaf": true
              },
              {
                "key": "E0102",
                "title": "E0102 Free weights",
                "threedigit": "E010",
                "isLeaf": true
              },
              {
                "key": "E0103",
                "title": "E0103 Pilates",
                "threedigit": "E010",
                "isLeaf": true
              },
              {
                "key": "E0109",
                "title": "E0109 Muscle strength exer NEC",
                "threedigit": "E010",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving computer technology and electronic devices",
            "title": "E011 - Activities involving computer technology and electronic devices",
            "threedigit": "E011",
            "children": [
              {
                "key": "E0110",
                "title": "E0110 Computer keyboarding",
                "threedigit": "E011",
                "isLeaf": true
              },
              {
                "key": "E0111",
                "title": "E0111 Handheld interac elec dv",
                "threedigit": "E011",
                "isLeaf": true
              },
              {
                "key": "E0119",
                "title": "E0119 Computer tech NEC",
                "threedigit": "E011",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving arts and handcrafts",
            "title": "E012 - Activities involving arts and handcrafts",
            "threedigit": "E012",
            "children": [
              {
                "key": "E0120",
                "title": "E0120 Knitting & crocheting",
                "threedigit": "E012",
                "isLeaf": true
              },
              {
                "key": "E0121",
                "title": "E0121 Sewing",
                "threedigit": "E012",
                "isLeaf": true
              },
              {
                "key": "E0122",
                "title": "E0122 Furniture build/finish",
                "threedigit": "E012",
                "isLeaf": true
              },
              {
                "key": "E0129",
                "title": "E0129 Arts & handcrafts NEC",
                "threedigit": "E012",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving personal hygiene and household maintenance",
            "title": "E013 - Activities involving personal hygiene and household maintenance",
            "threedigit": "E013",
            "children": [
              {
                "key": "E0130",
                "title": "E0130 Personal bathing/shower",
                "threedigit": "E013",
                "isLeaf": true
              },
              {
                "key": "E0131",
                "title": "E0131 Laundry",
                "threedigit": "E013",
                "isLeaf": true
              },
              {
                "key": "E0132",
                "title": "E0132 Vacuuming",
                "threedigit": "E013",
                "isLeaf": true
              },
              {
                "key": "E0133",
                "title": "E0133 Ironing",
                "threedigit": "E013",
                "isLeaf": true
              },
              {
                "key": "E0134",
                "title": "E0134 Floor mopping/cleaning",
                "threedigit": "E013",
                "isLeaf": true
              },
              {
                "key": "E0135",
                "title": "E0135 Residential relocation",
                "threedigit": "E013",
                "isLeaf": true
              },
              {
                "key": "E0138",
                "title": "E0138 Personal hygiene NEC",
                "threedigit": "E013",
                "isLeaf": true
              },
              {
                "key": "E0139",
                "title": "E0139 Househld maintenance NEC",
                "threedigit": "E013",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving person providing caregiving",
            "title": "E014 - Activities involving person providing caregiving",
            "threedigit": "E014",
            "children": [
              {
                "key": "E0140",
                "title": "E0140 Caregiving-bathing",
                "threedigit": "E014",
                "isLeaf": true
              },
              {
                "key": "E0141",
                "title": "E0141 Caregiving-lifting",
                "threedigit": "E014",
                "isLeaf": true
              },
              {
                "key": "E0149",
                "title": "E0149 Caregiving NEC",
                "threedigit": "E014",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving food preparation, cooking and grilling",
            "title": "E015 - Activities involving food preparation, cooking and grilling",
            "threedigit": "E015",
            "children": [
              {
                "key": "E0150",
                "title": "E0150 Food prep & cleanup",
                "threedigit": "E015",
                "isLeaf": true
              },
              {
                "key": "E0151",
                "title": "E0151 Grilling & smoking food",
                "threedigit": "E015",
                "isLeaf": true
              },
              {
                "key": "E0152",
                "title": "E0152 Cooking & baking",
                "threedigit": "E015",
                "isLeaf": true
              },
              {
                "key": "E0159",
                "title": "E0159 Cooking & grilling NEC",
                "threedigit": "E015",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving property and land maintenance, building and construction",
            "title": "E016 - Activities involving property and land maintenance, building and construction",
            "threedigit": "E016",
            "children": [
              {
                "key": "E0160",
                "title": "E0160 Digging,shovel,rake",
                "threedigit": "E016",
                "isLeaf": true
              },
              {
                "key": "E0161",
                "title": "E0161 Gardening/landscaping",
                "threedigit": "E016",
                "isLeaf": true
              },
              {
                "key": "E0162",
                "title": "E0162 Building & construction",
                "threedigit": "E016",
                "isLeaf": true
              },
              {
                "key": "E0169",
                "title": "E0169 Land maintain/cnstrt NEC",
                "threedigit": "E016",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving roller coasters and other types of external motion",
            "title": "E017 - Activities involving roller coasters and other types of external motion",
            "threedigit": "E017",
            "children": [
              {
                "key": "E0170",
                "title": "E0170 Roller coaster riding",
                "threedigit": "E017",
                "isLeaf": true
              },
              {
                "key": "E0179",
                "title": "E0179 External motion NEC",
                "threedigit": "E017",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving playing musical instrument",
            "title": "E018 - Activities involving playing musical instrument",
            "threedigit": "E018",
            "children": [
              {
                "key": "E0180",
                "title": "E0180 Piano playing",
                "threedigit": "E018",
                "isLeaf": true
              },
              {
                "key": "E0181",
                "title": "E0181 Drum/percussion playing",
                "threedigit": "E018",
                "isLeaf": true
              },
              {
                "key": "E0182",
                "title": "E0182 String instrument play",
                "threedigit": "E018",
                "isLeaf": true
              },
              {
                "key": "E0183",
                "title": "E0183 Winds/brass instru play",
                "threedigit": "E018",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Activities involving animal care",
            "title": "E019 - Activities involving animal care",
            "threedigit": "E019",
            "children": [
              {
                "key": "E0190",
                "title": "E0190 Walking an animal",
                "threedigit": "E019",
                "isLeaf": true
              },
              {
                "key": "E0191",
                "title": "E0191 Milking an animal",
                "threedigit": "E019",
                "isLeaf": true
              },
              {
                "key": "E0192",
                "title": "E0192 Groom/shearing animal",
                "threedigit": "E019",
                "isLeaf": true
              },
              {
                "key": "E0199",
                "title": "E0199 Animal care NEC",
                "threedigit": "E019",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other activity",
            "title": "E029 - Other activity",
            "threedigit": "E029",
            "children": [
              {
                "key": "E0290",
                "title": "E0290 Refereeing sports",
                "threedigit": "E029",
                "isLeaf": true
              },
              {
                "key": "E0291",
                "title": "E0291 Spectator at event",
                "threedigit": "E029",
                "isLeaf": true
              },
              {
                "key": "E0292",
                "title": "E0292 Rough housing/horseplay",
                "threedigit": "E029",
                "isLeaf": true
              },
              {
                "key": "E0299",
                "title": "E0299 Activity NEC",
                "threedigit": "E029",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Unspecified activity",
            "title": "E030 - Unspecified activity",
            "threedigit": "E030",
            "children": [
              {
                "key": "E030",
                "title": "E030 Activity NOS",
                "threedigit": "E030",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Railway Accidents",
        "title": "E800-E807 Railway Accidents",
        "threedigit": "E800",
        "children": [
          {
            "key": "Railway accident involving collision with rolling stock",
            "title": "E800 - Railway accident involving collision with rolling stock",
            "threedigit": "E800",
            "children": [
              {
                "key": "E8000",
                "title": "E8000 RR collision NOS-employ",
                "threedigit": "E800",
                "isLeaf": true
              },
              {
                "key": "E8001",
                "title": "E8001 RR coll NOS-passenger",
                "threedigit": "E800",
                "isLeaf": true
              },
              {
                "key": "E8002",
                "title": "E8002 RR coll NOS-pedestrian",
                "threedigit": "E800",
                "isLeaf": true
              },
              {
                "key": "E8003",
                "title": "E8003 RR coll NOS-ped cyclist",
                "threedigit": "E800",
                "isLeaf": true
              },
              {
                "key": "E8008",
                "title": "E8008 RR coll NOS-person NEC",
                "threedigit": "E800",
                "isLeaf": true
              },
              {
                "key": "E8009",
                "title": "E8009 RR coll NOS-person NOS",
                "threedigit": "E800",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Railway accident involving collision with other object",
            "title": "E801 - Railway accident involving collision with other object",
            "threedigit": "E801",
            "children": [
              {
                "key": "E8010",
                "title": "E8010 RR coll w oth obj-employ",
                "threedigit": "E801",
                "isLeaf": true
              },
              {
                "key": "E8011",
                "title": "E8011 RR coll w oth obj-pasngr",
                "threedigit": "E801",
                "isLeaf": true
              },
              {
                "key": "E8012",
                "title": "E8012 RR coll w oth obj-pedest",
                "threedigit": "E801",
                "isLeaf": true
              },
              {
                "key": "E8013",
                "title": "E8013 RR coll w oth obj-cycl",
                "threedigit": "E801",
                "isLeaf": true
              },
              {
                "key": "E8018",
                "title": "E8018 RR col w oth obj-per NEC",
                "threedigit": "E801",
                "isLeaf": true
              },
              {
                "key": "E8019",
                "title": "E8019 RR col w oth obj-per NOS",
                "threedigit": "E801",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Railway accident involving derailment without antecedent collision",
            "title": "E802 - Railway accident involving derailment without antecedent collision",
            "threedigit": "E802",
            "children": [
              {
                "key": "E8020",
                "title": "E8020 RR acc w derail-employee",
                "threedigit": "E802",
                "isLeaf": true
              },
              {
                "key": "E8021",
                "title": "E8021 RR acc w derail-passeng",
                "threedigit": "E802",
                "isLeaf": true
              },
              {
                "key": "E8022",
                "title": "E8022 RR acc w derail-pedest",
                "threedigit": "E802",
                "isLeaf": true
              },
              {
                "key": "E8023",
                "title": "E8023 RR acc w derail-ped cycl",
                "threedigit": "E802",
                "isLeaf": true
              },
              {
                "key": "E8028",
                "title": "E8028 RR acc w derail-pers NEC",
                "threedigit": "E802",
                "isLeaf": true
              },
              {
                "key": "E8029",
                "title": "E8029 RR acc w derail-pers NOS",
                "threedigit": "E802",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Railway accident involving explosion fire or burning",
            "title": "E803 - Railway accident involving explosion fire or burning",
            "threedigit": "E803",
            "children": [
              {
                "key": "E8030",
                "title": "E8030 RR acc w explosion-empl",
                "threedigit": "E803",
                "isLeaf": true
              },
              {
                "key": "E8031",
                "title": "E8031 RR acc w explos-pasngr",
                "threedigit": "E803",
                "isLeaf": true
              },
              {
                "key": "E8032",
                "title": "E8032 RR acc w explos-pedest",
                "threedigit": "E803",
                "isLeaf": true
              },
              {
                "key": "E8033",
                "title": "E8033 RR acc w explos-ped cycl",
                "threedigit": "E803",
                "isLeaf": true
              },
              {
                "key": "E8038",
                "title": "E8038 RR acc w explos-pers NEC",
                "threedigit": "E803",
                "isLeaf": true
              },
              {
                "key": "E8039",
                "title": "E8039 RR acc w explos-pers NOS",
                "threedigit": "E803",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fall in on or from railway train",
            "title": "E804 - Fall in on or from railway train",
            "threedigit": "E804",
            "children": [
              {
                "key": "E8040",
                "title": "E8040 Fall on/from train-empl",
                "threedigit": "E804",
                "isLeaf": true
              },
              {
                "key": "E8041",
                "title": "E8041 Fall from train-passengr",
                "threedigit": "E804",
                "isLeaf": true
              },
              {
                "key": "E8042",
                "title": "E8042 Fall from train-pedest",
                "threedigit": "E804",
                "isLeaf": true
              },
              {
                "key": "E8043",
                "title": "E8043 Fall from train-ped cycl",
                "threedigit": "E804",
                "isLeaf": true
              },
              {
                "key": "E8048",
                "title": "E8048 Fall from train-pers NEC",
                "threedigit": "E804",
                "isLeaf": true
              },
              {
                "key": "E8049",
                "title": "E8049 Fall from train-pers NOS",
                "threedigit": "E804",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hit by rolling stock",
            "title": "E805 - Hit by rolling stock",
            "threedigit": "E805",
            "children": [
              {
                "key": "E8050",
                "title": "E8050 Hit by train-employee",
                "threedigit": "E805",
                "isLeaf": true
              },
              {
                "key": "E8051",
                "title": "E8051 Hit by train-passenger",
                "threedigit": "E805",
                "isLeaf": true
              },
              {
                "key": "E8052",
                "title": "E8052 Hit by train-pedestrian",
                "threedigit": "E805",
                "isLeaf": true
              },
              {
                "key": "E8053",
                "title": "E8053 Hit by train-ped cyclist",
                "threedigit": "E805",
                "isLeaf": true
              },
              {
                "key": "E8058",
                "title": "E8058 Hit by train-person NEC",
                "threedigit": "E805",
                "isLeaf": true
              },
              {
                "key": "E8059",
                "title": "E8059 Hit by train-person NOS",
                "threedigit": "E805",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other specified railway accident",
            "title": "E806 - Other specified railway accident",
            "threedigit": "E806",
            "children": [
              {
                "key": "E8060",
                "title": "E8060 RR acc NEC-employee",
                "threedigit": "E806",
                "isLeaf": true
              },
              {
                "key": "E8061",
                "title": "E8061 RR acc NEC-passenger",
                "threedigit": "E806",
                "isLeaf": true
              },
              {
                "key": "E8062",
                "title": "E8062 RR acc NEC-pedestrian",
                "threedigit": "E806",
                "isLeaf": true
              },
              {
                "key": "E8063",
                "title": "E8063 RR acc NEC-ped cyclist",
                "threedigit": "E806",
                "isLeaf": true
              },
              {
                "key": "E8068",
                "title": "E8068 RR acc NEC-person NEC",
                "threedigit": "E806",
                "isLeaf": true
              },
              {
                "key": "E8069",
                "title": "E8069 RR acc NEC-person NOS",
                "threedigit": "E806",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Railway accident of unspecified nature",
            "title": "E807 - Railway accident of unspecified nature",
            "threedigit": "E807",
            "children": [
              {
                "key": "E8070",
                "title": "E8070 RR accident NOS-employee",
                "threedigit": "E807",
                "isLeaf": true
              },
              {
                "key": "E8071",
                "title": "E8071 RR acc NOS-passenger",
                "threedigit": "E807",
                "isLeaf": true
              },
              {
                "key": "E8072",
                "title": "E8072 RR acc NOS-pedestrian",
                "threedigit": "E807",
                "isLeaf": true
              },
              {
                "key": "E8073",
                "title": "E8073 RR acc NOS-ped cyclist",
                "threedigit": "E807",
                "isLeaf": true
              },
              {
                "key": "E8078",
                "title": "E8078 RR acc NOS-person NEC",
                "threedigit": "E807",
                "isLeaf": true
              },
              {
                "key": "E8079",
                "title": "E8079 RR acc NOS-person NOS",
                "threedigit": "E807",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Motor Vehicle Traffic Accidents",
        "title": "E810-E819 Motor Vehicle Traffic Accidents",
        "threedigit": "E810",
        "children": [
          {
            "key": "Motor vehicle traffic accident involving collision with train",
            "title": "E810 - Motor vehicle traffic accident involving collision with train",
            "threedigit": "E810",
            "children": [
              {
                "key": "E8100",
                "title": "E8100 Mv-train coll-driver",
                "threedigit": "E810",
                "isLeaf": true
              },
              {
                "key": "E8101",
                "title": "E8101 Mv-train coll-pasngr",
                "threedigit": "E810",
                "isLeaf": true
              },
              {
                "key": "E8102",
                "title": "E8102 Mv-train coll-motorcycl",
                "threedigit": "E810",
                "isLeaf": true
              },
              {
                "key": "E8103",
                "title": "E8103 Mv-train coll-mcycl psgr",
                "threedigit": "E810",
                "isLeaf": true
              },
              {
                "key": "E8104",
                "title": "E8104 Mv-train coll-st car",
                "threedigit": "E810",
                "isLeaf": true
              },
              {
                "key": "E8105",
                "title": "E8105 Mv-train coll-anim rid",
                "threedigit": "E810",
                "isLeaf": true
              },
              {
                "key": "E8106",
                "title": "E8106 Mv-train coll-ped cycl",
                "threedigit": "E810",
                "isLeaf": true
              },
              {
                "key": "E8107",
                "title": "E8107 Mv-train coll-pedest",
                "threedigit": "E810",
                "isLeaf": true
              },
              {
                "key": "E8108",
                "title": "E8108 Mv-train coll-pers NEC",
                "threedigit": "E810",
                "isLeaf": true
              },
              {
                "key": "E8109",
                "title": "E8109 Mv-train coll-pers NOS",
                "threedigit": "E810",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Motor vehicle traffic accident involving re-entrant collision with another motor vehicle",
            "title": "E811 - Motor vehicle traffic accident involving re-entrant collision with another motor vehicle",
            "threedigit": "E811",
            "children": [
              {
                "key": "E8110",
                "title": "E8110 Reentrant mv coll-driver",
                "threedigit": "E811",
                "isLeaf": true
              },
              {
                "key": "E8111",
                "title": "E8111 Reentrant mv coll-pasngr",
                "threedigit": "E811",
                "isLeaf": true
              },
              {
                "key": "E8112",
                "title": "E8112 Reentrant coll-motcycl",
                "threedigit": "E811",
                "isLeaf": true
              },
              {
                "key": "E8113",
                "title": "E8113 Reentrant coll-mcyc psgr",
                "threedigit": "E811",
                "isLeaf": true
              },
              {
                "key": "E8114",
                "title": "E8114 Reentrant coll-st car",
                "threedigit": "E811",
                "isLeaf": true
              },
              {
                "key": "E8115",
                "title": "E8115 Reentrant coll-anim rid",
                "threedigit": "E811",
                "isLeaf": true
              },
              {
                "key": "E8116",
                "title": "E8116 Reentrant coll-ped cycl",
                "threedigit": "E811",
                "isLeaf": true
              },
              {
                "key": "E8117",
                "title": "E8117 Reentrant coll-pedest",
                "threedigit": "E811",
                "isLeaf": true
              },
              {
                "key": "E8118",
                "title": "E8118 Reentrant coll-pers NEC",
                "threedigit": "E811",
                "isLeaf": true
              },
              {
                "key": "E8119",
                "title": "E8119 Reentrant coll-pers NOS",
                "threedigit": "E811",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other motor vehicle traffic accident involving collision with motor vehicle",
            "title": "E812 - Other motor vehicle traffic accident involving collision with motor vehicle",
            "threedigit": "E812",
            "children": [
              {
                "key": "E8120",
                "title": "E8120 Mv collision NOS-driver",
                "threedigit": "E812",
                "isLeaf": true
              },
              {
                "key": "E8121",
                "title": "E8121 Mv collision NOS-pasngr",
                "threedigit": "E812",
                "isLeaf": true
              },
              {
                "key": "E8122",
                "title": "E8122 Mv collis NOS-motorcycl",
                "threedigit": "E812",
                "isLeaf": true
              },
              {
                "key": "E8123",
                "title": "E8123 Mv coll NOS-mcycl psngr",
                "threedigit": "E812",
                "isLeaf": true
              },
              {
                "key": "E8124",
                "title": "E8124 Mv collision NOS-st car",
                "threedigit": "E812",
                "isLeaf": true
              },
              {
                "key": "E8125",
                "title": "E8125 Mv coll NOS-anim rid",
                "threedigit": "E812",
                "isLeaf": true
              },
              {
                "key": "E8126",
                "title": "E8126 Mv coll NOS-ped cycl",
                "threedigit": "E812",
                "isLeaf": true
              },
              {
                "key": "E8127",
                "title": "E8127 Mv collision NOS-pedest",
                "threedigit": "E812",
                "isLeaf": true
              },
              {
                "key": "E8128",
                "title": "E8128 Mv collis NOS-pers NEC",
                "threedigit": "E812",
                "isLeaf": true
              },
              {
                "key": "E8129",
                "title": "E8129 Mv collis NOS-pers NOS",
                "threedigit": "E812",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Motor vehicle traffic accident involving collision with other vehicle",
            "title": "E813 - Motor vehicle traffic accident involving collision with other vehicle",
            "threedigit": "E813",
            "children": [
              {
                "key": "E8130",
                "title": "E8130 Mv-oth veh coll-driver",
                "threedigit": "E813",
                "isLeaf": true
              },
              {
                "key": "E8131",
                "title": "E8131 Mv-oth veh coll-pasngr",
                "threedigit": "E813",
                "isLeaf": true
              },
              {
                "key": "E8132",
                "title": "E8132 Mv-oth veh coll-motcycl",
                "threedigit": "E813",
                "isLeaf": true
              },
              {
                "key": "E8133",
                "title": "E8133 Mv-oth veh coll-mcyc psg",
                "threedigit": "E813",
                "isLeaf": true
              },
              {
                "key": "E8134",
                "title": "E8134 Mv-oth veh coll-st car",
                "threedigit": "E813",
                "isLeaf": true
              },
              {
                "key": "E8135",
                "title": "E8135 Mv-oth veh coll-anim rid",
                "threedigit": "E813",
                "isLeaf": true
              },
              {
                "key": "E8136",
                "title": "E8136 Mv-oth veh coll-ped cycl",
                "threedigit": "E813",
                "isLeaf": true
              },
              {
                "key": "E8137",
                "title": "E8137 Mv-oth veh coll-pedest",
                "threedigit": "E813",
                "isLeaf": true
              },
              {
                "key": "E8138",
                "title": "E8138 Mv-oth veh coll-pers NEC",
                "threedigit": "E813",
                "isLeaf": true
              },
              {
                "key": "E8139",
                "title": "E8139 Mv-oth veh coll-pers NOS",
                "threedigit": "E813",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Motor vehicle traffic accident involving collision with pedestrian",
            "title": "E814 - Motor vehicle traffic accident involving collision with pedestrian",
            "threedigit": "E814",
            "children": [
              {
                "key": "E8140",
                "title": "E8140 Mv coll w pedest-driver",
                "threedigit": "E814",
                "isLeaf": true
              },
              {
                "key": "E8141",
                "title": "E8141 Mv coll w pedest-pasngr",
                "threedigit": "E814",
                "isLeaf": true
              },
              {
                "key": "E8142",
                "title": "E8142 Mv coll w pedest-motcycl",
                "threedigit": "E814",
                "isLeaf": true
              },
              {
                "key": "E8143",
                "title": "E8143 Mv coll w ped-mcycl psgr",
                "threedigit": "E814",
                "isLeaf": true
              },
              {
                "key": "E8144",
                "title": "E8144 Mv coll w pedest-st car",
                "threedigit": "E814",
                "isLeaf": true
              },
              {
                "key": "E8145",
                "title": "E8145 Mv coll w ped-anim rid",
                "threedigit": "E814",
                "isLeaf": true
              },
              {
                "key": "E8146",
                "title": "E8146 Mv coll w ped-ped cycl",
                "threedigit": "E814",
                "isLeaf": true
              },
              {
                "key": "E8147",
                "title": "E8147 Mv coll w pedest-pedest",
                "threedigit": "E814",
                "isLeaf": true
              },
              {
                "key": "E8148",
                "title": "E8148 Mv coll w pedes-pers NEC",
                "threedigit": "E814",
                "isLeaf": true
              },
              {
                "key": "E8149",
                "title": "E8149 Mv coll w pedes-pers NOS",
                "threedigit": "E814",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other motor vehicle traffic accident involving collision on the highway",
            "title": "E815 - Other motor vehicle traffic accident involving collision on the highway",
            "threedigit": "E815",
            "children": [
              {
                "key": "E8150",
                "title": "E8150 Mv coll w oth obj-driver",
                "threedigit": "E815",
                "isLeaf": true
              },
              {
                "key": "E8151",
                "title": "E8151 Mv coll w oth obj-pasngr",
                "threedigit": "E815",
                "isLeaf": true
              },
              {
                "key": "E8152",
                "title": "E8152 Mv coll w oth obj-mocycl",
                "threedigit": "E815",
                "isLeaf": true
              },
              {
                "key": "E8153",
                "title": "E8153 Mv coll w obj-mcycl psgr",
                "threedigit": "E815",
                "isLeaf": true
              },
              {
                "key": "E8154",
                "title": "E8154 Mv coll w obj-st car",
                "threedigit": "E815",
                "isLeaf": true
              },
              {
                "key": "E8155",
                "title": "E8155 Mv coll w obj-anim rider",
                "threedigit": "E815",
                "isLeaf": true
              },
              {
                "key": "E8156",
                "title": "E8156 Mv coll w obj-ped cycl",
                "threedigit": "E815",
                "isLeaf": true
              },
              {
                "key": "E8157",
                "title": "E8157 Mv coll w obj-pedest",
                "threedigit": "E815",
                "isLeaf": true
              },
              {
                "key": "E8158",
                "title": "E8158 Mv coll w obj-pers NEC",
                "threedigit": "E815",
                "isLeaf": true
              },
              {
                "key": "E8159",
                "title": "E8159 Mv coll w obj-pers NOS",
                "threedigit": "E815",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Motor vehicle traffic accident due to loss of control without collision on the highway",
            "title": "E816 - Motor vehicle traffic accident due to loss of control without collision on the highway",
            "threedigit": "E816",
            "children": [
              {
                "key": "E8160",
                "title": "E8160 Loss control mv acc-driv",
                "threedigit": "E816",
                "isLeaf": true
              },
              {
                "key": "E8161",
                "title": "E8161 Loss control mv acc-psgr",
                "threedigit": "E816",
                "isLeaf": true
              },
              {
                "key": "E8162",
                "title": "E8162 Loss control mv-mocycl",
                "threedigit": "E816",
                "isLeaf": true
              },
              {
                "key": "E8163",
                "title": "E8163 Loss control mv-mcyc psg",
                "threedigit": "E816",
                "isLeaf": true
              },
              {
                "key": "E8164",
                "title": "E8164 Loss cont mv acc-st car",
                "threedigit": "E816",
                "isLeaf": true
              },
              {
                "key": "E8165",
                "title": "E8165 Loss cont mv-anim rider",
                "threedigit": "E816",
                "isLeaf": true
              },
              {
                "key": "E8166",
                "title": "E8166 Loss control mv-ped cycl",
                "threedigit": "E816",
                "isLeaf": true
              },
              {
                "key": "E8167",
                "title": "E8167 Loss control mv-pedest",
                "threedigit": "E816",
                "isLeaf": true
              },
              {
                "key": "E8168",
                "title": "E8168 Loss control mv-pers NEC",
                "threedigit": "E816",
                "isLeaf": true
              },
              {
                "key": "E8169",
                "title": "E8169 Loss control mv-pers NOS",
                "threedigit": "E816",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Noncollision motor vehicle traffic accident while boarding or alighting",
            "title": "E817 - Noncollision motor vehicle traffic accident while boarding or alighting",
            "threedigit": "E817",
            "children": [
              {
                "key": "E8170",
                "title": "E8170 Mv acc board/alight-driv",
                "threedigit": "E817",
                "isLeaf": true
              },
              {
                "key": "E8171",
                "title": "E8171 Mv acc board/alight-psgr",
                "threedigit": "E817",
                "isLeaf": true
              },
              {
                "key": "E8172",
                "title": "E8172 Mv board/alight-motcycl",
                "threedigit": "E817",
                "isLeaf": true
              },
              {
                "key": "E8173",
                "title": "E8173 Mv brd/alight-mcycl psgr",
                "threedigit": "E817",
                "isLeaf": true
              },
              {
                "key": "E8174",
                "title": "E8174 Mv acc brd/alight-st car",
                "threedigit": "E817",
                "isLeaf": true
              },
              {
                "key": "E8175",
                "title": "E8175 Mv brd/alight-anim rider",
                "threedigit": "E817",
                "isLeaf": true
              },
              {
                "key": "E8176",
                "title": "E8176 Mv brd/alight-ped cycl",
                "threedigit": "E817",
                "isLeaf": true
              },
              {
                "key": "E8177",
                "title": "E8177 Mv brd/alight-pedestrian",
                "threedigit": "E817",
                "isLeaf": true
              },
              {
                "key": "E8178",
                "title": "E8178 Mv board/alight-pers NEC",
                "threedigit": "E817",
                "isLeaf": true
              },
              {
                "key": "E8179",
                "title": "E8179 Mv board/alight-pers NOS",
                "threedigit": "E817",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other noncollision motor vehicle traffic accident",
            "title": "E818 - Other noncollision motor vehicle traffic accident",
            "threedigit": "E818",
            "children": [
              {
                "key": "E8180",
                "title": "E8180 Mv traff acc NEC-driver",
                "threedigit": "E818",
                "isLeaf": true
              },
              {
                "key": "E8181",
                "title": "E8181 Mv traff acc NEC-pasngr",
                "threedigit": "E818",
                "isLeaf": true
              },
              {
                "key": "E8182",
                "title": "E8182 Mv traff acc NEC-mocycl",
                "threedigit": "E818",
                "isLeaf": true
              },
              {
                "key": "E8183",
                "title": "E8183 Mv traff acc-mcycl psgr",
                "threedigit": "E818",
                "isLeaf": true
              },
              {
                "key": "E8184",
                "title": "E8184 Mv traff acc NEC-st car",
                "threedigit": "E818",
                "isLeaf": true
              },
              {
                "key": "E8185",
                "title": "E8185 Mv traff acc-anim rider",
                "threedigit": "E818",
                "isLeaf": true
              },
              {
                "key": "E8186",
                "title": "E8186 Mv traff acc-ped cyc",
                "threedigit": "E818",
                "isLeaf": true
              },
              {
                "key": "E8187",
                "title": "E8187 Mv traff acc NEC-pedest",
                "threedigit": "E818",
                "isLeaf": true
              },
              {
                "key": "E8188",
                "title": "E8188 Mv traff acc-pers NEC",
                "threedigit": "E818",
                "isLeaf": true
              },
              {
                "key": "E8189",
                "title": "E8189 Mv traff acc-pers NOS",
                "threedigit": "E818",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Motor vehicle traffic accident of unspecified nature",
            "title": "E819 - Motor vehicle traffic accident of unspecified nature",
            "threedigit": "E819",
            "children": [
              {
                "key": "E8190",
                "title": "E8190 Traffic acc NOS-driver",
                "threedigit": "E819",
                "isLeaf": true
              },
              {
                "key": "E8191",
                "title": "E8191 Traffic acc NOS-pasngr",
                "threedigit": "E819",
                "isLeaf": true
              },
              {
                "key": "E8192",
                "title": "E8192 Traffic acc NOS-motcycl",
                "threedigit": "E819",
                "isLeaf": true
              },
              {
                "key": "E8193",
                "title": "E8193 Traff acc NOS-mcycl psgr",
                "threedigit": "E819",
                "isLeaf": true
              },
              {
                "key": "E8194",
                "title": "E8194 Traffic acc NOS-st car",
                "threedigit": "E819",
                "isLeaf": true
              },
              {
                "key": "E8195",
                "title": "E8195 Traff acc NOS-anim rider",
                "threedigit": "E819",
                "isLeaf": true
              },
              {
                "key": "E8196",
                "title": "E8196 Traffic acc NOS-ped cycl",
                "threedigit": "E819",
                "isLeaf": true
              },
              {
                "key": "E8197",
                "title": "E8197 Traffic acc NOS-pedest",
                "threedigit": "E819",
                "isLeaf": true
              },
              {
                "key": "E8198",
                "title": "E8198 Traffic acc NOS-pers NEC",
                "threedigit": "E819",
                "isLeaf": true
              },
              {
                "key": "E8199",
                "title": "E8199 Traffic acc NOS-pers NOS",
                "threedigit": "E819",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Motor Vehicle Nontraffic Accidents",
        "title": "E820-E825 Motor Vehicle Nontraffic Accidents",
        "threedigit": "E820",
        "children": [
          {
            "key": "Nontraffic accident involving motor-driven snow vehicle",
            "title": "E820 - Nontraffic accident involving motor-driven snow vehicle",
            "threedigit": "E820",
            "children": [
              {
                "key": "E8200",
                "title": "E8200 Snow veh acc-driver",
                "threedigit": "E820",
                "isLeaf": true
              },
              {
                "key": "E8201",
                "title": "E8201 Snow veh acc-pasngr",
                "threedigit": "E820",
                "isLeaf": true
              },
              {
                "key": "E8202",
                "title": "E8202 Snow veh acc-motorcycl",
                "threedigit": "E820",
                "isLeaf": true
              },
              {
                "key": "E8203",
                "title": "E8203 Snow veh acc-mcycl psgr",
                "threedigit": "E820",
                "isLeaf": true
              },
              {
                "key": "E8204",
                "title": "E8204 Snow veh acc-st car",
                "threedigit": "E820",
                "isLeaf": true
              },
              {
                "key": "E8205",
                "title": "E8205 Snow veh acc-anim rider",
                "threedigit": "E820",
                "isLeaf": true
              },
              {
                "key": "E8206",
                "title": "E8206 Snow veh acc-ped cycl",
                "threedigit": "E820",
                "isLeaf": true
              },
              {
                "key": "E8207",
                "title": "E8207 Snow veh acc-pedest",
                "threedigit": "E820",
                "isLeaf": true
              },
              {
                "key": "E8208",
                "title": "E8208 Snow veh acc-pers NEC",
                "threedigit": "E820",
                "isLeaf": true
              },
              {
                "key": "E8209",
                "title": "E8209 Snow veh acc-pers NOS",
                "threedigit": "E820",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Nontraffic accident involving other off-road motor vehicle",
            "title": "E821 - Nontraffic accident involving other off-road motor vehicle",
            "threedigit": "E821",
            "children": [
              {
                "key": "E8210",
                "title": "E8210 Oth off-road mv acc-driv",
                "threedigit": "E821",
                "isLeaf": true
              },
              {
                "key": "E8211",
                "title": "E8211 Oth off-road mv acc-psgr",
                "threedigit": "E821",
                "isLeaf": true
              },
              {
                "key": "E8212",
                "title": "E8212 Oth off-road mv-mocycl",
                "threedigit": "E821",
                "isLeaf": true
              },
              {
                "key": "E8213",
                "title": "E8213 Oth off-road mv-mcyc psg",
                "threedigit": "E821",
                "isLeaf": true
              },
              {
                "key": "E8214",
                "title": "E8214 Oth off-road mv-st car",
                "threedigit": "E821",
                "isLeaf": true
              },
              {
                "key": "E8215",
                "title": "E8215 Oth off-road mv-anim rid",
                "threedigit": "E821",
                "isLeaf": true
              },
              {
                "key": "E8216",
                "title": "E8216 Oth off-road mv-ped cycl",
                "threedigit": "E821",
                "isLeaf": true
              },
              {
                "key": "E8217",
                "title": "E8217 Oth off-road mv-pedest",
                "threedigit": "E821",
                "isLeaf": true
              },
              {
                "key": "E8218",
                "title": "E8218 Oth off-road mv-pers NEC",
                "threedigit": "E821",
                "isLeaf": true
              },
              {
                "key": "E8219",
                "title": "E8219 Oth off-road mv-pers NOS",
                "threedigit": "E821",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other motor vehicle nontraffic accident involving collision with moving object",
            "title": "E822 - Other motor vehicle nontraffic accident involving collision with moving object",
            "threedigit": "E822",
            "children": [
              {
                "key": "E8220",
                "title": "E8220 Oth coll w mov obj-driv",
                "threedigit": "E822",
                "isLeaf": true
              },
              {
                "key": "E8221",
                "title": "E8221 Oth coll w mov obj-psgr",
                "threedigit": "E822",
                "isLeaf": true
              },
              {
                "key": "E8222",
                "title": "E8222 Oth coll mov obj-mocycl",
                "threedigit": "E822",
                "isLeaf": true
              },
              {
                "key": "E8223",
                "title": "E8223 Oth coll mov obj-cyc psg",
                "threedigit": "E822",
                "isLeaf": true
              },
              {
                "key": "E8224",
                "title": "E8224 Oth coll mov obj-st car",
                "threedigit": "E822",
                "isLeaf": true
              },
              {
                "key": "E8225",
                "title": "E8225 Oth coll mov obj-rider",
                "threedigit": "E822",
                "isLeaf": true
              },
              {
                "key": "E8226",
                "title": "E8226 Oth coll mov obj-ped cyc",
                "threedigit": "E822",
                "isLeaf": true
              },
              {
                "key": "E8227",
                "title": "E8227 Oth coll mov obj-pedest",
                "threedigit": "E822",
                "isLeaf": true
              },
              {
                "key": "E8228",
                "title": "E8228 Oth coll mov obj-per NEC",
                "threedigit": "E822",
                "isLeaf": true
              },
              {
                "key": "E8229",
                "title": "E8229 Oth coll mov obj-per NOS",
                "threedigit": "E822",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other motor vehicle nontraffic accident involving collision with stationary object",
            "title": "E823 - Other motor vehicle nontraffic accident involving collision with stationary object",
            "threedigit": "E823",
            "children": [
              {
                "key": "E8230",
                "title": "E8230 Oth coll stndng obj-driv",
                "threedigit": "E823",
                "isLeaf": true
              },
              {
                "key": "E8231",
                "title": "E8231 Oth coll stndng obj-psgr",
                "threedigit": "E823",
                "isLeaf": true
              },
              {
                "key": "E8232",
                "title": "E8232 Oth coll stnd obj-mocycl",
                "threedigit": "E823",
                "isLeaf": true
              },
              {
                "key": "E8233",
                "title": "E8233 Oth coll stn obj-cyc psg",
                "threedigit": "E823",
                "isLeaf": true
              },
              {
                "key": "E8234",
                "title": "E8234 Oth coll stnd obj-st car",
                "threedigit": "E823",
                "isLeaf": true
              },
              {
                "key": "E8235",
                "title": "E8235 Oth coll stnd obj-rider",
                "threedigit": "E823",
                "isLeaf": true
              },
              {
                "key": "E8236",
                "title": "E8236 Oth coll stn obj-ped cyc",
                "threedigit": "E823",
                "isLeaf": true
              },
              {
                "key": "E8237",
                "title": "E8237 Oth coll stnd obj-pedest",
                "threedigit": "E823",
                "isLeaf": true
              },
              {
                "key": "E8238",
                "title": "E8238 Oth coll stn obj-per NEC",
                "threedigit": "E823",
                "isLeaf": true
              },
              {
                "key": "E8239",
                "title": "E8239 Oth col-stnd-obj-per NOS",
                "threedigit": "E823",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other motor vehicle nontraffic accident while boarding and alighting",
            "title": "E824 - Other motor vehicle nontraffic accident while boarding and alighting",
            "threedigit": "E824",
            "children": [
              {
                "key": "E8240",
                "title": "E8240 N-traf board/alight-driv",
                "threedigit": "E824",
                "isLeaf": true
              },
              {
                "key": "E8241",
                "title": "E8241 N-traf board/alight-psgr",
                "threedigit": "E824",
                "isLeaf": true
              },
              {
                "key": "E8242",
                "title": "E8242 N-traf brd/alight-mocycl",
                "threedigit": "E824",
                "isLeaf": true
              },
              {
                "key": "E8243",
                "title": "E8243 N-traf brd/alit-mcyc psg",
                "threedigit": "E824",
                "isLeaf": true
              },
              {
                "key": "E8244",
                "title": "E8244 N-traf brd/alit-st car",
                "threedigit": "E824",
                "isLeaf": true
              },
              {
                "key": "E8245",
                "title": "E8245 N-traf brd/alit-anim rid",
                "threedigit": "E824",
                "isLeaf": true
              },
              {
                "key": "E8246",
                "title": "E8246 N-traf brd/alit-ped cycl",
                "threedigit": "E824",
                "isLeaf": true
              },
              {
                "key": "E8247",
                "title": "E8247 N-traf brd/alit-pedest",
                "threedigit": "E824",
                "isLeaf": true
              },
              {
                "key": "E8248",
                "title": "E8248 N-traf brd/alit-pers NEC",
                "threedigit": "E824",
                "isLeaf": true
              },
              {
                "key": "E8249",
                "title": "E8249 N-traf brd/alit-pers NOS",
                "threedigit": "E824",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other motor vehicle nontraffic accident of other and unspecified nature",
            "title": "E825 - Other motor vehicle nontraffic accident of other and unspecified nature",
            "threedigit": "E825",
            "children": [
              {
                "key": "E8250",
                "title": "E8250 Mv n-traff acc NEC-driv",
                "threedigit": "E825",
                "isLeaf": true
              },
              {
                "key": "E8251",
                "title": "E8251 Mv n-traff NEC/NOS-psgr",
                "threedigit": "E825",
                "isLeaf": true
              },
              {
                "key": "E8252",
                "title": "E8252 Mv n-traf acc NEC-mocycl",
                "threedigit": "E825",
                "isLeaf": true
              },
              {
                "key": "E8253",
                "title": "E8253 Mv n-traff NEC-mcyc psgr",
                "threedigit": "E825",
                "isLeaf": true
              },
              {
                "key": "E8254",
                "title": "E8254 Mv n-traff NEC-st car",
                "threedigit": "E825",
                "isLeaf": true
              },
              {
                "key": "E8255",
                "title": "E8255 Mv n-traf NEC-anim rider",
                "threedigit": "E825",
                "isLeaf": true
              },
              {
                "key": "E8256",
                "title": "E8256 Mv n-traff NEC-ped cycl",
                "threedigit": "E825",
                "isLeaf": true
              },
              {
                "key": "E8257",
                "title": "E8257 Mv n-traff NEC-pedest",
                "threedigit": "E825",
                "isLeaf": true
              },
              {
                "key": "E8258",
                "title": "E8258 Mv n-traff NEC-pers NEC",
                "threedigit": "E825",
                "isLeaf": true
              },
              {
                "key": "E8259",
                "title": "E8259 Mv n-traff NEC-pers NOS",
                "threedigit": "E825",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Road Vehicle Accidents",
        "title": "E826-E829 Other Road Vehicle Accidents",
        "threedigit": "E826",
        "children": [
          {
            "key": "Pedal cycle accident",
            "title": "E826 - Pedal cycle accident",
            "threedigit": "E826",
            "children": [
              {
                "key": "E8260",
                "title": "E8260 Pedal cycle acc-pedest",
                "threedigit": "E826",
                "isLeaf": true
              },
              {
                "key": "E8261",
                "title": "E8261 Ped cycl acc-ped cyclist",
                "threedigit": "E826",
                "isLeaf": true
              },
              {
                "key": "E8262",
                "title": "E8262 Ped cycle acc-anim rider",
                "threedigit": "E826",
                "isLeaf": true
              },
              {
                "key": "E8263",
                "title": "E8263 Ped cyc acc-occ anim veh",
                "threedigit": "E826",
                "isLeaf": true
              },
              {
                "key": "E8264",
                "title": "E8264 Ped cycle acc-occ st car",
                "threedigit": "E826",
                "isLeaf": true
              },
              {
                "key": "E8268",
                "title": "E8268 Ped cycle acc-pers NEC",
                "threedigit": "E826",
                "isLeaf": true
              },
              {
                "key": "E8269",
                "title": "E8269 Ped cycle acc-pers NOS",
                "threedigit": "E826",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Animal-drawn vehicle accident",
            "title": "E827 - Animal-drawn vehicle accident",
            "threedigit": "E827",
            "children": [
              {
                "key": "E8270",
                "title": "E8270 Animal drawn veh-pedest",
                "threedigit": "E827",
                "isLeaf": true
              },
              {
                "key": "E8272",
                "title": "E8272 Anim drawn veh-anim rid",
                "threedigit": "E827",
                "isLeaf": true
              },
              {
                "key": "E8273",
                "title": "E8273 Animal drawn veh-occupan",
                "threedigit": "E827",
                "isLeaf": true
              },
              {
                "key": "E8274",
                "title": "E8274 Anim drawn-occ st car",
                "threedigit": "E827",
                "isLeaf": true
              },
              {
                "key": "E8278",
                "title": "E8278 Anim drawn veh-pers NEC",
                "threedigit": "E827",
                "isLeaf": true
              },
              {
                "key": "E8279",
                "title": "E8279 Anim drawn veh-pers NOS",
                "threedigit": "E827",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident involving animal being ridden",
            "title": "E828 - Accident involving animal being ridden",
            "threedigit": "E828",
            "children": [
              {
                "key": "E8280",
                "title": "E8280 Ridden animal acc-pedest",
                "threedigit": "E828",
                "isLeaf": true
              },
              {
                "key": "E8282",
                "title": "E8282 Ridden animal acc-rider",
                "threedigit": "E828",
                "isLeaf": true
              },
              {
                "key": "E8284",
                "title": "E8284 Ridden animal acc-st car",
                "threedigit": "E828",
                "isLeaf": true
              },
              {
                "key": "E8288",
                "title": "E8288 Ridden anim acc-pers NEC",
                "threedigit": "E828",
                "isLeaf": true
              },
              {
                "key": "E8289",
                "title": "E8289 Ridden anim acc-pers NOS",
                "threedigit": "E828",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other road vehicle accidents",
            "title": "E829 - Other road vehicle accidents",
            "threedigit": "E829",
            "children": [
              {
                "key": "E8290",
                "title": "E8290 Oth road veh acc-pedest",
                "threedigit": "E829",
                "isLeaf": true
              },
              {
                "key": "E8294",
                "title": "E8294 Oth rd veh acc-st car",
                "threedigit": "E829",
                "isLeaf": true
              },
              {
                "key": "E8298",
                "title": "E8298 Oth rd veh acc-pers NEC",
                "threedigit": "E829",
                "isLeaf": true
              },
              {
                "key": "E8299",
                "title": "E8299 Oth rd veh acc-pers NOS",
                "threedigit": "E829",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Water Transport Accidents",
        "title": "E830-E838 Water Transport Accidents",
        "threedigit": "E830",
        "children": [
          {
            "key": "Accident to watercraft causing submersion",
            "title": "E830 - Accident to watercraft causing submersion",
            "threedigit": "E830",
            "children": [
              {
                "key": "E8300",
                "title": "E8300 Boat acc w submers-unpow",
                "threedigit": "E830",
                "isLeaf": true
              },
              {
                "key": "E8301",
                "title": "E8301 Boat acc w submers-power",
                "threedigit": "E830",
                "isLeaf": true
              },
              {
                "key": "E8302",
                "title": "E8302 Boat acc w submers-crew",
                "threedigit": "E830",
                "isLeaf": true
              },
              {
                "key": "E8303",
                "title": "E8303 Boat acc w submers-psgr",
                "threedigit": "E830",
                "isLeaf": true
              },
              {
                "key": "E8304",
                "title": "E8304 Boat submers-water skier",
                "threedigit": "E830",
                "isLeaf": true
              },
              {
                "key": "E8305",
                "title": "E8305 Boat submers-swimmer",
                "threedigit": "E830",
                "isLeaf": true
              },
              {
                "key": "E8306",
                "title": "E8306 Boat submers-dockers",
                "threedigit": "E830",
                "isLeaf": true
              },
              {
                "key": "E8307",
                "title": "E8307 Boat submers-military",
                "threedigit": "E830",
                "isLeaf": true
              },
              {
                "key": "E8308",
                "title": "E8308 Boat submers-pers NEC",
                "threedigit": "E830",
                "isLeaf": true
              },
              {
                "key": "E8309",
                "title": "E8309 Boat submers-pers NOS",
                "threedigit": "E830",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident to watercraft causing other injury",
            "title": "E831 - Accident to watercraft causing other injury",
            "threedigit": "E831",
            "children": [
              {
                "key": "E8310",
                "title": "E8310 Boat acc inj NEC-unpower",
                "threedigit": "E831",
                "isLeaf": true
              },
              {
                "key": "E8311",
                "title": "E8311 Boat acc inj NEC-power",
                "threedigit": "E831",
                "isLeaf": true
              },
              {
                "key": "E8312",
                "title": "E8312 Boat acc inj NEC-crew",
                "threedigit": "E831",
                "isLeaf": true
              },
              {
                "key": "E8313",
                "title": "E8313 Boat acc inj NEC-passeng",
                "threedigit": "E831",
                "isLeaf": true
              },
              {
                "key": "E8314",
                "title": "E8314 Boat acc inj NEC-skier",
                "threedigit": "E831",
                "isLeaf": true
              },
              {
                "key": "E8315",
                "title": "E8315 Boat acc inj NEC-swim",
                "threedigit": "E831",
                "isLeaf": true
              },
              {
                "key": "E8316",
                "title": "E8316 Boat acc inj NEC-docker",
                "threedigit": "E831",
                "isLeaf": true
              },
              {
                "key": "E8317",
                "title": "E8317 Boat acc inj NEC-militry",
                "threedigit": "E831",
                "isLeaf": true
              },
              {
                "key": "E8318",
                "title": "E8318 Boat inj NEC-person NEC",
                "threedigit": "E831",
                "isLeaf": true
              },
              {
                "key": "E8319",
                "title": "E8319 Boat inj NEC-person NOS",
                "threedigit": "E831",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other accidental submersion or drowning in water transport accident",
            "title": "E832 - Other accidental submersion or drowning in water transport accident",
            "threedigit": "E832",
            "children": [
              {
                "key": "E8320",
                "title": "E8320 Submers NEC-unpow boat",
                "threedigit": "E832",
                "isLeaf": true
              },
              {
                "key": "E8321",
                "title": "E8321 Submers NEC-power boat",
                "threedigit": "E832",
                "isLeaf": true
              },
              {
                "key": "E8322",
                "title": "E8322 Submers NEC-crew",
                "threedigit": "E832",
                "isLeaf": true
              },
              {
                "key": "E8323",
                "title": "E8323 Submers NEC-passenger",
                "threedigit": "E832",
                "isLeaf": true
              },
              {
                "key": "E8324",
                "title": "E8324 Submers NEC-water skier",
                "threedigit": "E832",
                "isLeaf": true
              },
              {
                "key": "E8325",
                "title": "E8325 Submers NEC-swimmer",
                "threedigit": "E832",
                "isLeaf": true
              },
              {
                "key": "E8326",
                "title": "E8326 Submers NEC-docker",
                "threedigit": "E832",
                "isLeaf": true
              },
              {
                "key": "E8327",
                "title": "E8327 Submersion NEC-military",
                "threedigit": "E832",
                "isLeaf": true
              },
              {
                "key": "E8328",
                "title": "E8328 Submers NEC-person NEC",
                "threedigit": "E832",
                "isLeaf": true
              },
              {
                "key": "E8329",
                "title": "E8329 Submers NEC-person NOS",
                "threedigit": "E832",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fall on stairs or ladders in water transport",
            "title": "E833 - Fall on stairs or ladders in water transport",
            "threedigit": "E833",
            "children": [
              {
                "key": "E8330",
                "title": "E8330 W/craft stair fall-unpow",
                "threedigit": "E833",
                "isLeaf": true
              },
              {
                "key": "E8331",
                "title": "E8331 W/craft stair fall-power",
                "threedigit": "E833",
                "isLeaf": true
              },
              {
                "key": "E8332",
                "title": "E8332 Wtrcraft stair fall-crew",
                "threedigit": "E833",
                "isLeaf": true
              },
              {
                "key": "E8333",
                "title": "E8333 Wtrcraft stair fall-psgr",
                "threedigit": "E833",
                "isLeaf": true
              },
              {
                "key": "E8334",
                "title": "E8334 W/craft stair fall-skier",
                "threedigit": "E833",
                "isLeaf": true
              },
              {
                "key": "E8335",
                "title": "E8335 W/craft stair fall-swim",
                "threedigit": "E833",
                "isLeaf": true
              },
              {
                "key": "E8336",
                "title": "E8336 W/crf stair fall-docker",
                "threedigit": "E833",
                "isLeaf": true
              },
              {
                "key": "E8337",
                "title": "E8337 W/crf stair fall-militry",
                "threedigit": "E833",
                "isLeaf": true
              },
              {
                "key": "E8338",
                "title": "E8338 W/crf stair fall-per NEC",
                "threedigit": "E833",
                "isLeaf": true
              },
              {
                "key": "E8339",
                "title": "E8339 W/crf stair fall-per NOS",
                "threedigit": "E833",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other fall from one level to another in water transport",
            "title": "E834 - Other fall from one level to another in water transport",
            "threedigit": "E834",
            "children": [
              {
                "key": "E8340",
                "title": "E8340 W/craft fall NEC-unpow",
                "threedigit": "E834",
                "isLeaf": true
              },
              {
                "key": "E8341",
                "title": "E8341 W/craft fall NEC-power",
                "threedigit": "E834",
                "isLeaf": true
              },
              {
                "key": "E8342",
                "title": "E8342 Watercraft fall NEC-crew",
                "threedigit": "E834",
                "isLeaf": true
              },
              {
                "key": "E8343",
                "title": "E8343 Wtrcraft fall NEC-pasngr",
                "threedigit": "E834",
                "isLeaf": true
              },
              {
                "key": "E8344",
                "title": "E8344 W/craft fall NEC-skier",
                "threedigit": "E834",
                "isLeaf": true
              },
              {
                "key": "E8345",
                "title": "E8345 W/craft fall NEC-swim",
                "threedigit": "E834",
                "isLeaf": true
              },
              {
                "key": "E8346",
                "title": "E8346 Wtrcraft fall NEC-docker",
                "threedigit": "E834",
                "isLeaf": true
              },
              {
                "key": "E8347",
                "title": "E8347 W/crft fall NEC-military",
                "threedigit": "E834",
                "isLeaf": true
              },
              {
                "key": "E8348",
                "title": "E8348 W/crft fall NEC-pers NEC",
                "threedigit": "E834",
                "isLeaf": true
              },
              {
                "key": "E8349",
                "title": "E8349 W/crft fall NEC-pers NOS",
                "threedigit": "E834",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified fall in water transport",
            "title": "E835 - Other and unspecified fall in water transport",
            "threedigit": "E835",
            "children": [
              {
                "key": "E8350",
                "title": "E8350 W/craft fall NOS-unpow",
                "threedigit": "E835",
                "isLeaf": true
              },
              {
                "key": "E8351",
                "title": "E8351 W/craft fall NOS-power",
                "threedigit": "E835",
                "isLeaf": true
              },
              {
                "key": "E8352",
                "title": "E8352 Wtrcraft fall NOS-crew",
                "threedigit": "E835",
                "isLeaf": true
              },
              {
                "key": "E8353",
                "title": "E8353 Wtrcraft fall NOS-pasngr",
                "threedigit": "E835",
                "isLeaf": true
              },
              {
                "key": "E8354",
                "title": "E8354 W/craft fall NOS-skier",
                "threedigit": "E835",
                "isLeaf": true
              },
              {
                "key": "E8355",
                "title": "E8355 W/craft fall NOS-swim",
                "threedigit": "E835",
                "isLeaf": true
              },
              {
                "key": "E8356",
                "title": "E8356 Wtrcraft fall NOS-docker",
                "threedigit": "E835",
                "isLeaf": true
              },
              {
                "key": "E8357",
                "title": "E8357 W/crft fall NEC/NOS-mil",
                "threedigit": "E835",
                "isLeaf": true
              },
              {
                "key": "E8358",
                "title": "E8358 W/crft fall NOS-pers NEC",
                "threedigit": "E835",
                "isLeaf": true
              },
              {
                "key": "E8359",
                "title": "E8359 W/crft fall NOS-pers NOS",
                "threedigit": "E835",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Machinery accident in water transport",
            "title": "E836 - Machinery accident in water transport",
            "threedigit": "E836",
            "children": [
              {
                "key": "E8360",
                "title": "E8360 Machine acc-unpow boat",
                "threedigit": "E836",
                "isLeaf": true
              },
              {
                "key": "E8361",
                "title": "E8361 Mach acc-occ power boat",
                "threedigit": "E836",
                "isLeaf": true
              },
              {
                "key": "E8362",
                "title": "E8362 Machinery accident-crew",
                "threedigit": "E836",
                "isLeaf": true
              },
              {
                "key": "E8363",
                "title": "E8363 Machinery acc-pasngr",
                "threedigit": "E836",
                "isLeaf": true
              },
              {
                "key": "E8364",
                "title": "E8364 Machine accident-skier",
                "threedigit": "E836",
                "isLeaf": true
              },
              {
                "key": "E8365",
                "title": "E8365 Machine accident-swim",
                "threedigit": "E836",
                "isLeaf": true
              },
              {
                "key": "E8366",
                "title": "E8366 Machinery acc-docker",
                "threedigit": "E836",
                "isLeaf": true
              },
              {
                "key": "E8367",
                "title": "E8367 W/crft machine-military",
                "threedigit": "E836",
                "isLeaf": true
              },
              {
                "key": "E8368",
                "title": "E8368 Machinery acc-pers NEC",
                "threedigit": "E836",
                "isLeaf": true
              },
              {
                "key": "E8369",
                "title": "E8369 Machinery acc-pers NOS",
                "threedigit": "E836",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Explosion fire or burning in watercraft",
            "title": "E837 - Explosion fire or burning in watercraft",
            "threedigit": "E837",
            "children": [
              {
                "key": "E8370",
                "title": "E8370 Explosion-occ unpow boat",
                "threedigit": "E837",
                "isLeaf": true
              },
              {
                "key": "E8371",
                "title": "E8371 Explosion-occ power boat",
                "threedigit": "E837",
                "isLeaf": true
              },
              {
                "key": "E8372",
                "title": "E8372 Watercraft explos-crew",
                "threedigit": "E837",
                "isLeaf": true
              },
              {
                "key": "E8373",
                "title": "E8373 Watercraft explos-pasngr",
                "threedigit": "E837",
                "isLeaf": true
              },
              {
                "key": "E8374",
                "title": "E8374 Watercraft explos-skier",
                "threedigit": "E837",
                "isLeaf": true
              },
              {
                "key": "E8375",
                "title": "E8375 Watercraft explos-swim",
                "threedigit": "E837",
                "isLeaf": true
              },
              {
                "key": "E8376",
                "title": "E8376 Watercraft explos-docker",
                "threedigit": "E837",
                "isLeaf": true
              },
              {
                "key": "E8377",
                "title": "E8377 W/crft explosn-military",
                "threedigit": "E837",
                "isLeaf": true
              },
              {
                "key": "E8378",
                "title": "E8378 Watercraft expl-pers NEC",
                "threedigit": "E837",
                "isLeaf": true
              },
              {
                "key": "E8379",
                "title": "E8379 Watercraft expl-pers NOS",
                "threedigit": "E837",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified water transport accident",
            "title": "E838 - Other and unspecified water transport accident",
            "threedigit": "E838",
            "children": [
              {
                "key": "E8380",
                "title": "E8380 Watercraft acc NEC-unpow",
                "threedigit": "E838",
                "isLeaf": true
              },
              {
                "key": "E8381",
                "title": "E8381 Watercraft acc NEC-power",
                "threedigit": "E838",
                "isLeaf": true
              },
              {
                "key": "E8382",
                "title": "E8382 Watercraft acc NEC-crew",
                "threedigit": "E838",
                "isLeaf": true
              },
              {
                "key": "E8383",
                "title": "E8383 Watercrft acc NEC-pasngr",
                "threedigit": "E838",
                "isLeaf": true
              },
              {
                "key": "E8384",
                "title": "E8384 Watercraft acc NEC-skier",
                "threedigit": "E838",
                "isLeaf": true
              },
              {
                "key": "E8385",
                "title": "E8385 Watrcrft acc NEC-swimmer",
                "threedigit": "E838",
                "isLeaf": true
              },
              {
                "key": "E8386",
                "title": "E8386 Watercrft acc NEC-docker",
                "threedigit": "E838",
                "isLeaf": true
              },
              {
                "key": "E8387",
                "title": "E8387 W/crft-military NEC/NOS",
                "threedigit": "E838",
                "isLeaf": true
              },
              {
                "key": "E8388",
                "title": "E8388 Wtrcrft acc NEC-pers NEC",
                "threedigit": "E838",
                "isLeaf": true
              },
              {
                "key": "E8389",
                "title": "E8389 Wtrcrft acc NEC-pers NOS",
                "threedigit": "E838",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Air And Space Transport Accidents",
        "title": "E840-E845 Air And Space Transport Accidents",
        "threedigit": "E840",
        "children": [
          {
            "key": "Accident to powered aircraft at takeoff or landing",
            "title": "E840 - Accident to powered aircraft at takeoff or landing",
            "threedigit": "E840",
            "children": [
              {
                "key": "E8400",
                "title": "E8400 Tk off/land-spcrft",
                "threedigit": "E840",
                "isLeaf": true
              },
              {
                "key": "E8401",
                "title": "E8401 Tk off/land-milit craft",
                "threedigit": "E840",
                "isLeaf": true
              },
              {
                "key": "E8402",
                "title": "E8402 Tk off/land-crew aircrft",
                "threedigit": "E840",
                "isLeaf": true
              },
              {
                "key": "E8403",
                "title": "E8403 Tk off/land-psng aircrft",
                "threedigit": "E840",
                "isLeaf": true
              },
              {
                "key": "E8404",
                "title": "E8404 Tk off/land-comm crf NEC",
                "threedigit": "E840",
                "isLeaf": true
              },
              {
                "key": "E8405",
                "title": "E8405 Tk off/land-aircraft NEC",
                "threedigit": "E840",
                "isLeaf": true
              },
              {
                "key": "E8406",
                "title": "E8406 Tk off/land-unp aircrft",
                "threedigit": "E840",
                "isLeaf": true
              },
              {
                "key": "E8407",
                "title": "E8407 Tk off/land-parachutist",
                "threedigit": "E840",
                "isLeaf": true
              },
              {
                "key": "E8408",
                "title": "E8408 Tk off/land-ground crew",
                "threedigit": "E840",
                "isLeaf": true
              },
              {
                "key": "E8409",
                "title": "E8409 Tk off/land-pers NEC",
                "threedigit": "E840",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident to powered aircraft other and unspecified",
            "title": "E841 - Accident to powered aircraft other and unspecified",
            "threedigit": "E841",
            "children": [
              {
                "key": "E8410",
                "title": "E8410 Pow aircraft acc-spcrft",
                "threedigit": "E841",
                "isLeaf": true
              },
              {
                "key": "E8411",
                "title": "E8411 Power aircraft acc-milit",
                "threedigit": "E841",
                "isLeaf": true
              },
              {
                "key": "E8412",
                "title": "E8412 Power aircraft acc-crew",
                "threedigit": "E841",
                "isLeaf": true
              },
              {
                "key": "E8413",
                "title": "E8413 Power aircraft acc-psngr",
                "threedigit": "E841",
                "isLeaf": true
              },
              {
                "key": "E8414",
                "title": "E8414 Aircraf acc-occ comm NEC",
                "threedigit": "E841",
                "isLeaf": true
              },
              {
                "key": "E8415",
                "title": "E8415 Oth powered aircraft acc",
                "threedigit": "E841",
                "isLeaf": true
              },
              {
                "key": "E8416",
                "title": "E8416 Pow airc acc-unp aircr",
                "threedigit": "E841",
                "isLeaf": true
              },
              {
                "key": "E8417",
                "title": "E8417 Aircraft acc-parachutist",
                "threedigit": "E841",
                "isLeaf": true
              },
              {
                "key": "E8418",
                "title": "E8418 Aircraft acc-ground crew",
                "threedigit": "E841",
                "isLeaf": true
              },
              {
                "key": "E8419",
                "title": "E8419 Aircrft acc NOS-pers NEC",
                "threedigit": "E841",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident to unpowered aircraft",
            "title": "E842 - Accident to unpowered aircraft",
            "threedigit": "E842",
            "children": [
              {
                "key": "E8426",
                "title": "E8426 Unpower aircraft acc-occ",
                "threedigit": "E842",
                "isLeaf": true
              },
              {
                "key": "E8427",
                "title": "E8427 Unpow aircrf acc-chutist",
                "threedigit": "E842",
                "isLeaf": true
              },
              {
                "key": "E8428",
                "title": "E8428 Unpow aircrf acc-gr crew",
                "threedigit": "E842",
                "isLeaf": true
              },
              {
                "key": "E8429",
                "title": "E8429 Unpow aircrf acc-per NEC",
                "threedigit": "E842",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fall in on or from aircraft",
            "title": "E843 - Fall in on or from aircraft",
            "threedigit": "E843",
            "children": [
              {
                "key": "E8430",
                "title": "E8430 Fall-occ spacecraft",
                "threedigit": "E843",
                "isLeaf": true
              },
              {
                "key": "E8431",
                "title": "E8431 Fall-milit aircraft occp",
                "threedigit": "E843",
                "isLeaf": true
              },
              {
                "key": "E8432",
                "title": "E8432 Fall-crew comm aircraft",
                "threedigit": "E843",
                "isLeaf": true
              },
              {
                "key": "E8433",
                "title": "E8433 Fall-psng comm aircraft",
                "threedigit": "E843",
                "isLeaf": true
              },
              {
                "key": "E8434",
                "title": "E8434 Fall-occ comm aircrf NEC",
                "threedigit": "E843",
                "isLeaf": true
              },
              {
                "key": "E8435",
                "title": "E8435 Fall-occup oth aircraft",
                "threedigit": "E843",
                "isLeaf": true
              },
              {
                "key": "E8436",
                "title": "E8436 Fall-occ unpower aircraf",
                "threedigit": "E843",
                "isLeaf": true
              },
              {
                "key": "E8437",
                "title": "E8437 Fall-parachutist",
                "threedigit": "E843",
                "isLeaf": true
              },
              {
                "key": "E8438",
                "title": "E8438 Aircrft fall-ground crew",
                "threedigit": "E843",
                "isLeaf": true
              },
              {
                "key": "E8439",
                "title": "E8439 Aircraft fall-person NEC",
                "threedigit": "E843",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other specified air transport accidents",
            "title": "E844 - Other specified air transport accidents",
            "threedigit": "E844",
            "children": [
              {
                "key": "E8440",
                "title": "E8440 Aircrft acc NEC-spcrft",
                "threedigit": "E844",
                "isLeaf": true
              },
              {
                "key": "E8441",
                "title": "E8441 Aircrft acc NEC-military",
                "threedigit": "E844",
                "isLeaf": true
              },
              {
                "key": "E8442",
                "title": "E8442 Aircrft acc NEC-crew",
                "threedigit": "E844",
                "isLeaf": true
              },
              {
                "key": "E8443",
                "title": "E8443 Aircrft acc NEC-pasngr",
                "threedigit": "E844",
                "isLeaf": true
              },
              {
                "key": "E8444",
                "title": "E8444 Aircrft acc NEC-comm NEC",
                "threedigit": "E844",
                "isLeaf": true
              },
              {
                "key": "E8445",
                "title": "E8445 Aircrft acc NEC-occp NEC",
                "threedigit": "E844",
                "isLeaf": true
              },
              {
                "key": "E8446",
                "title": "E8446 Aircr acc NEC-unp aircr",
                "threedigit": "E844",
                "isLeaf": true
              },
              {
                "key": "E8447",
                "title": "E8447 Aircrft acc-parachutist",
                "threedigit": "E844",
                "isLeaf": true
              },
              {
                "key": "E8448",
                "title": "E8448 Aircrft acc NEC-grd crew",
                "threedigit": "E844",
                "isLeaf": true
              },
              {
                "key": "E8449",
                "title": "E8449 Aircrft acc NEC-pers NEC",
                "threedigit": "E844",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident involving spacecraft",
            "title": "E845 - Accident involving spacecraft",
            "threedigit": "E845",
            "children": [
              {
                "key": "E8450",
                "title": "E8450 Spacecraft acc-occupant",
                "threedigit": "E845",
                "isLeaf": true
              },
              {
                "key": "E8458",
                "title": "E8458 Spacecraft acc-grnd crew",
                "threedigit": "E845",
                "isLeaf": true
              },
              {
                "key": "E8459",
                "title": "E8459 Spacecraft acc-pers NEC",
                "threedigit": "E845",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Vehicle Accidents, Not Elsewhere Classifiable",
        "title": "E846-E849 Vehicle Accidents, Not Elsewhere Classifiable",
        "threedigit": "E846",
        "children": [
          {
            "key": "Accidents involving powered vehicles used solely within the buildings and premises of industrial or commercial establishment",
            "title": "E846 - Accidents involving powered vehicles used solely within the buildings and premises of industrial or commercial establishment",
            "threedigit": "E846",
            "children": [
              {
                "key": "E846",
                "title": "E846 Indus veh acc on premise",
                "threedigit": "E846",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidents involving cable cars not running on rails",
            "title": "E847 - Accidents involving cable cars not running on rails",
            "threedigit": "E847",
            "children": [
              {
                "key": "E847",
                "title": "E847 Cabl car acc not on rail",
                "threedigit": "E847",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidents involving other vehicles, not elsewhere classifiable",
            "title": "E848 - Accidents involving other vehicles, not elsewhere classifiable",
            "threedigit": "E848",
            "children": [
              {
                "key": "E848",
                "title": "E848 Oth vehicle acc NEC",
                "threedigit": "E848",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Place of occurrence",
            "title": "E849 - Place of occurrence",
            "threedigit": "E849",
            "children": [
              {
                "key": "E8490",
                "title": "E8490 Accident in home",
                "threedigit": "E849",
                "isLeaf": true
              },
              {
                "key": "E8491",
                "title": "E8491 Accident on farm",
                "threedigit": "E849",
                "isLeaf": true
              },
              {
                "key": "E8492",
                "title": "E8492 Accident in mine/quarry",
                "threedigit": "E849",
                "isLeaf": true
              },
              {
                "key": "E8493",
                "title": "E8493 Acc on industr premises",
                "threedigit": "E849",
                "isLeaf": true
              },
              {
                "key": "E8494",
                "title": "E8494 Accid in recreation area",
                "threedigit": "E849",
                "isLeaf": true
              },
              {
                "key": "E8495",
                "title": "E8495 Accid on street/highway",
                "threedigit": "E849",
                "isLeaf": true
              },
              {
                "key": "E8496",
                "title": "E8496 Accident in public bldg",
                "threedigit": "E849",
                "isLeaf": true
              },
              {
                "key": "E8497",
                "title": "E8497 Accid in resident instit",
                "threedigit": "E849",
                "isLeaf": true
              },
              {
                "key": "E8498",
                "title": "E8498 Accident in place NEC",
                "threedigit": "E849",
                "isLeaf": true
              },
              {
                "key": "E8499",
                "title": "E8499 Accident in place NOS",
                "threedigit": "E849",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Accidental Poisoning By Drugs, Medicinal Substances, And Biologicals",
        "title": "E850-E858 Accidental Poisoning By Drugs, Medicinal Substances, And Biologicals",
        "threedigit": "E850",
        "children": [
          {
            "key": "Accidental poisoning by analgesics antipyretics and antirheumatics",
            "title": "E850 - Accidental poisoning by analgesics antipyretics and antirheumatics",
            "threedigit": "E850",
            "children": [
              {
                "key": "E8500",
                "title": "E8500 Acc poison-heroin",
                "threedigit": "E850",
                "isLeaf": true
              },
              {
                "key": "E8501",
                "title": "E8501 Acc poison-methadone",
                "threedigit": "E850",
                "isLeaf": true
              },
              {
                "key": "E8502",
                "title": "E8502 Acc poison-opiates NEC",
                "threedigit": "E850",
                "isLeaf": true
              },
              {
                "key": "E8503",
                "title": "E8503 Acc poison-salicylates",
                "threedigit": "E850",
                "isLeaf": true
              },
              {
                "key": "E8504",
                "title": "E8504 Acc poison-arom analgesc",
                "threedigit": "E850",
                "isLeaf": true
              },
              {
                "key": "E8505",
                "title": "E8505 Acc poison-pyrazole derv",
                "threedigit": "E850",
                "isLeaf": true
              },
              {
                "key": "E8506",
                "title": "E8506 Acc poison-antirheumatic",
                "threedigit": "E850",
                "isLeaf": true
              },
              {
                "key": "E8507",
                "title": "E8507 Acc poison-nonnarc analg",
                "threedigit": "E850",
                "isLeaf": true
              },
              {
                "key": "E8508",
                "title": "E8508 Acc poison-analgesic NEC",
                "threedigit": "E850",
                "isLeaf": true
              },
              {
                "key": "E8509",
                "title": "E8509 Acc poison-analgesic NOS",
                "threedigit": "E850",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by barbiturates",
            "title": "E851 - Accidental poisoning by barbiturates",
            "threedigit": "E851",
            "children": [
              {
                "key": "E851",
                "title": "E851 Acc poison-barbiturates",
                "threedigit": "E851",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by other sedatives and hypnotics",
            "title": "E852 - Accidental poisoning by other sedatives and hypnotics",
            "threedigit": "E852",
            "children": [
              {
                "key": "E8520",
                "title": "E8520 Acc poisn-chlorl hydrate",
                "threedigit": "E852",
                "isLeaf": true
              },
              {
                "key": "E8521",
                "title": "E8521 Acc poison-paraldehyde",
                "threedigit": "E852",
                "isLeaf": true
              },
              {
                "key": "E8522",
                "title": "E8522 Acc poison-bromine cmpnd",
                "threedigit": "E852",
                "isLeaf": true
              },
              {
                "key": "E8523",
                "title": "E8523 Acc poison-methaqualone",
                "threedigit": "E852",
                "isLeaf": true
              },
              {
                "key": "E8524",
                "title": "E8524 Acc poison-glutethimide",
                "threedigit": "E852",
                "isLeaf": true
              },
              {
                "key": "E8525",
                "title": "E8525 Acc poison-mix sedtv NEC",
                "threedigit": "E852",
                "isLeaf": true
              },
              {
                "key": "E8528",
                "title": "E8528 Acc poison-sedatives NEC",
                "threedigit": "E852",
                "isLeaf": true
              },
              {
                "key": "E8529",
                "title": "E8529 Acc poison-sedatives NOS",
                "threedigit": "E852",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by tranquilizers",
            "title": "E853 - Accidental poisoning by tranquilizers",
            "threedigit": "E853",
            "children": [
              {
                "key": "E8530",
                "title": "E8530 Acc pois-phenthiaz tranq",
                "threedigit": "E853",
                "isLeaf": true
              },
              {
                "key": "E8531",
                "title": "E8531 Acc pois-butyrphen tranq",
                "threedigit": "E853",
                "isLeaf": true
              },
              {
                "key": "E8532",
                "title": "E8532 Acc poisn-benzdiaz tranq",
                "threedigit": "E853",
                "isLeaf": true
              },
              {
                "key": "E8538",
                "title": "E8538 Acc poisn-tranquilzr NEC",
                "threedigit": "E853",
                "isLeaf": true
              },
              {
                "key": "E8539",
                "title": "E8539 Acc poisn-tranquilzr NOS",
                "threedigit": "E853",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by other psychotropic agents",
            "title": "E854 - Accidental poisoning by other psychotropic agents",
            "threedigit": "E854",
            "children": [
              {
                "key": "E8540",
                "title": "E8540 Acc poison-antidepressnt",
                "threedigit": "E854",
                "isLeaf": true
              },
              {
                "key": "E8541",
                "title": "E8541 Acc poison-hallucinogens",
                "threedigit": "E854",
                "isLeaf": true
              },
              {
                "key": "E8542",
                "title": "E8542 Acc poisn-psychstimulant",
                "threedigit": "E854",
                "isLeaf": true
              },
              {
                "key": "E8543",
                "title": "E8543 Acc poison-cns stimulant",
                "threedigit": "E854",
                "isLeaf": true
              },
              {
                "key": "E8548",
                "title": "E8548 Acc poisn psychotrop NEC",
                "threedigit": "E854",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by other drugs acting on central and autonomic nervous system",
            "title": "E855 - Accidental poisoning by other drugs acting on central and autonomic nervous system",
            "threedigit": "E855",
            "children": [
              {
                "key": "E8550",
                "title": "E8550 Acc poisn-anticonvulsant",
                "threedigit": "E855",
                "isLeaf": true
              },
              {
                "key": "E8551",
                "title": "E8551 Acc poisn-cns depres NEC",
                "threedigit": "E855",
                "isLeaf": true
              },
              {
                "key": "E8552",
                "title": "E8552 Acc poisn-local anesthet",
                "threedigit": "E855",
                "isLeaf": true
              },
              {
                "key": "E8553",
                "title": "E8553 Acc poison-cholinergics",
                "threedigit": "E855",
                "isLeaf": true
              },
              {
                "key": "E8554",
                "title": "E8554 Acc poisn-anticholinerg",
                "threedigit": "E855",
                "isLeaf": true
              },
              {
                "key": "E8555",
                "title": "E8555 Acc poison-adrenergics",
                "threedigit": "E855",
                "isLeaf": true
              },
              {
                "key": "E8556",
                "title": "E8556 Acc poisn-sympatholytics",
                "threedigit": "E855",
                "isLeaf": true
              },
              {
                "key": "E8558",
                "title": "E8558 Acc poison-cns drug NEC",
                "threedigit": "E855",
                "isLeaf": true
              },
              {
                "key": "E8559",
                "title": "E8559 Acc poison-cns drug NOS",
                "threedigit": "E855",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by antibiotics",
            "title": "E856 - Accidental poisoning by antibiotics",
            "threedigit": "E856",
            "children": [
              {
                "key": "E856",
                "title": "E856 Acc poison-antibiotics",
                "threedigit": "E856",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by other anti-infectives",
            "title": "E857 - Accidental poisoning by other anti-infectives",
            "threedigit": "E857",
            "children": [
              {
                "key": "E857",
                "title": "E857 Acc pois-oth anti-infect",
                "threedigit": "E857",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by other drugs",
            "title": "E858 - Accidental poisoning by other drugs",
            "threedigit": "E858",
            "children": [
              {
                "key": "E8580",
                "title": "E8580 Acc poison-hormones",
                "threedigit": "E858",
                "isLeaf": true
              },
              {
                "key": "E8581",
                "title": "E8581 Acc poisn-systemic agent",
                "threedigit": "E858",
                "isLeaf": true
              },
              {
                "key": "E8582",
                "title": "E8582 Acc poison-blood agent",
                "threedigit": "E858",
                "isLeaf": true
              },
              {
                "key": "E8583",
                "title": "E8583 Acc poisn-cardiovasc agt",
                "threedigit": "E858",
                "isLeaf": true
              },
              {
                "key": "E8584",
                "title": "E8584 Acc poison-gi agent",
                "threedigit": "E858",
                "isLeaf": true
              },
              {
                "key": "E8585",
                "title": "E8585 Acc poisn-metabol agnt",
                "threedigit": "E858",
                "isLeaf": true
              },
              {
                "key": "E8586",
                "title": "E8586 Acc poisn-muscl/resp agt",
                "threedigit": "E858",
                "isLeaf": true
              },
              {
                "key": "E8587",
                "title": "E8587 Acc poisn-skin/eent agnt",
                "threedigit": "E858",
                "isLeaf": true
              },
              {
                "key": "E8588",
                "title": "E8588 Acc poisoning-drug NEC",
                "threedigit": "E858",
                "isLeaf": true
              },
              {
                "key": "E8589",
                "title": "E8589 Acc poisoning-drug NOS",
                "threedigit": "E858",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Accidental Poisoning By Other Solid And Liquid Substances, Gases, And Vapors",
        "title": "E860-E869 Accidental Poisoning By Other Solid And Liquid Substances, Gases, And Vapors",
        "threedigit": "E860",
        "children": [
          {
            "key": "Accidental poisoning by alcohol not elsewhere classified",
            "title": "E860 - Accidental poisoning by alcohol not elsewhere classified",
            "threedigit": "E860",
            "children": [
              {
                "key": "E8600",
                "title": "E8600 Acc poisn-alcohol bevrag",
                "threedigit": "E860",
                "isLeaf": true
              },
              {
                "key": "E8601",
                "title": "E8601 Acc poison-ethyl alcohol",
                "threedigit": "E860",
                "isLeaf": true
              },
              {
                "key": "E8602",
                "title": "E8602 Acc poisn-methyl alcohol",
                "threedigit": "E860",
                "isLeaf": true
              },
              {
                "key": "E8603",
                "title": "E8603 Acc poisn-isopropyl alc",
                "threedigit": "E860",
                "isLeaf": true
              },
              {
                "key": "E8604",
                "title": "E8604 Acc poison-fusel oil",
                "threedigit": "E860",
                "isLeaf": true
              },
              {
                "key": "E8608",
                "title": "E8608 Acc poison-alcohol NEC",
                "threedigit": "E860",
                "isLeaf": true
              },
              {
                "key": "E8609",
                "title": "E8609 Acc poison-alcohol NOS",
                "threedigit": "E860",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by cleansing and polishing agents disinfectants paints and varnishes",
            "title": "E861 - Accidental poisoning by cleansing and polishing agents disinfectants paints and varnishes",
            "threedigit": "E861",
            "children": [
              {
                "key": "E8610",
                "title": "E8610 Acc pois-synth detergent",
                "threedigit": "E861",
                "isLeaf": true
              },
              {
                "key": "E8611",
                "title": "E8611 Acc poison-soap products",
                "threedigit": "E861",
                "isLeaf": true
              },
              {
                "key": "E8612",
                "title": "E8612 Acc poison-polishes",
                "threedigit": "E861",
                "isLeaf": true
              },
              {
                "key": "E8613",
                "title": "E8613 Acc poison-cleanser NEC",
                "threedigit": "E861",
                "isLeaf": true
              },
              {
                "key": "E8614",
                "title": "E8614 Acc poison-disinfectants",
                "threedigit": "E861",
                "isLeaf": true
              },
              {
                "key": "E8615",
                "title": "E8615 Acc poison-lead paints",
                "threedigit": "E861",
                "isLeaf": true
              },
              {
                "key": "E8616",
                "title": "E8616 Acc poison-paints NEC",
                "threedigit": "E861",
                "isLeaf": true
              },
              {
                "key": "E8619",
                "title": "E8619 Acc poison-cleanser NOS",
                "threedigit": "E861",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by petroleum products other solvents and their vapors not elsewhere classified",
            "title": "E862 - Accidental poisoning by petroleum products other solvents and their vapors not elsewhere classified",
            "threedigit": "E862",
            "children": [
              {
                "key": "E8620",
                "title": "E8620 Acc poisn-petrol solvent",
                "threedigit": "E862",
                "isLeaf": true
              },
              {
                "key": "E8621",
                "title": "E8621 Acc poisn-petroleum fuel",
                "threedigit": "E862",
                "isLeaf": true
              },
              {
                "key": "E8622",
                "title": "E8622 Acc pois-lubricating oil",
                "threedigit": "E862",
                "isLeaf": true
              },
              {
                "key": "E8623",
                "title": "E8623 Acc pois-petroleum solid",
                "threedigit": "E862",
                "isLeaf": true
              },
              {
                "key": "E8624",
                "title": "E8624 Acc poisn-solvents NEC",
                "threedigit": "E862",
                "isLeaf": true
              },
              {
                "key": "E8629",
                "title": "E8629 Acc poisn-solvent NOS",
                "threedigit": "E862",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by agricultural and horticultural chemical and pharmaceutical preparations other than plant foods and fertilizers",
            "title": "E863 - Accidental poisoning by agricultural and horticultural chemical and pharmaceutical preparations other than plant foods and fertilizers",
            "threedigit": "E863",
            "children": [
              {
                "key": "E8630",
                "title": "E8630 Acc pois-chlorine pestic",
                "threedigit": "E863",
                "isLeaf": true
              },
              {
                "key": "E8631",
                "title": "E8631 Acc pois-phosph pesticid",
                "threedigit": "E863",
                "isLeaf": true
              },
              {
                "key": "E8632",
                "title": "E8632 Acc poison-carbamates",
                "threedigit": "E863",
                "isLeaf": true
              },
              {
                "key": "E8633",
                "title": "E8633 Acc poisn-mixed pesticid",
                "threedigit": "E863",
                "isLeaf": true
              },
              {
                "key": "E8634",
                "title": "E8634 Acc poison-pesticide NEC",
                "threedigit": "E863",
                "isLeaf": true
              },
              {
                "key": "E8635",
                "title": "E8635 Acc poison-herbicides",
                "threedigit": "E863",
                "isLeaf": true
              },
              {
                "key": "E8636",
                "title": "E8636 Acc poison-fungicides",
                "threedigit": "E863",
                "isLeaf": true
              },
              {
                "key": "E8637",
                "title": "E8637 Acc poison-rodenticides",
                "threedigit": "E863",
                "isLeaf": true
              },
              {
                "key": "E8638",
                "title": "E8638 Acc poison-fumigants",
                "threedigit": "E863",
                "isLeaf": true
              },
              {
                "key": "E8639",
                "title": "E8639 Acc pois-agrcult NEC/NOS",
                "threedigit": "E863",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by corrosives and caustics not elsewhere classified",
            "title": "E864 - Accidental poisoning by corrosives and caustics not elsewhere classified",
            "threedigit": "E864",
            "children": [
              {
                "key": "E8640",
                "title": "E8640 Acc pois-corrosiv aromat",
                "threedigit": "E864",
                "isLeaf": true
              },
              {
                "key": "E8641",
                "title": "E8641 Acc poison-acids",
                "threedigit": "E864",
                "isLeaf": true
              },
              {
                "key": "E8642",
                "title": "E8642 Acc poisn-caustic alkali",
                "threedigit": "E864",
                "isLeaf": true
              },
              {
                "key": "E8643",
                "title": "E8643 Acc poison-caustic NEC",
                "threedigit": "E864",
                "isLeaf": true
              },
              {
                "key": "E8644",
                "title": "E8644 Acc poison-caustic NOS",
                "threedigit": "E864",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning from foodstuffs and poisonous plants",
            "title": "E865 - Accidental poisoning from foodstuffs and poisonous plants",
            "threedigit": "E865",
            "children": [
              {
                "key": "E8650",
                "title": "E8650 Acc poison-meat",
                "threedigit": "E865",
                "isLeaf": true
              },
              {
                "key": "E8651",
                "title": "E8651 Acc poison-shellfish",
                "threedigit": "E865",
                "isLeaf": true
              },
              {
                "key": "E8652",
                "title": "E8652 Acc poison-fish NEC",
                "threedigit": "E865",
                "isLeaf": true
              },
              {
                "key": "E8653",
                "title": "E8653 Acc poison-berries/seeds",
                "threedigit": "E865",
                "isLeaf": true
              },
              {
                "key": "E8654",
                "title": "E8654 Acc poison-plants NEC",
                "threedigit": "E865",
                "isLeaf": true
              },
              {
                "key": "E8655",
                "title": "E8655 Acc poison-mushrooms",
                "threedigit": "E865",
                "isLeaf": true
              },
              {
                "key": "E8658",
                "title": "E8658 Acc poison-food NEC",
                "threedigit": "E865",
                "isLeaf": true
              },
              {
                "key": "E8659",
                "title": "E8659 Acc poisn-food/plant NOS",
                "threedigit": "E865",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by other and unspecified solid and liquid substances",
            "title": "E866 - Accidental poisoning by other and unspecified solid and liquid substances",
            "threedigit": "E866",
            "children": [
              {
                "key": "E8660",
                "title": "E8660 Acc poisoning-lead",
                "threedigit": "E866",
                "isLeaf": true
              },
              {
                "key": "E8661",
                "title": "E8661 Acc poisoning-mercury",
                "threedigit": "E866",
                "isLeaf": true
              },
              {
                "key": "E8662",
                "title": "E8662 Acc poisoning-antimony",
                "threedigit": "E866",
                "isLeaf": true
              },
              {
                "key": "E8663",
                "title": "E8663 Acc poisoning-arsenic",
                "threedigit": "E866",
                "isLeaf": true
              },
              {
                "key": "E8664",
                "title": "E8664 Acc poison-metals NEC",
                "threedigit": "E866",
                "isLeaf": true
              },
              {
                "key": "E8665",
                "title": "E8665 Acc poison-plant food",
                "threedigit": "E866",
                "isLeaf": true
              },
              {
                "key": "E8666",
                "title": "E8666 Acc poison-glues",
                "threedigit": "E866",
                "isLeaf": true
              },
              {
                "key": "E8667",
                "title": "E8667 Acc poison-cosmetics",
                "threedigit": "E866",
                "isLeaf": true
              },
              {
                "key": "E8668",
                "title": "E8668 Acc pois-solid/liq NEC",
                "threedigit": "E866",
                "isLeaf": true
              },
              {
                "key": "E8669",
                "title": "E8669 Acc pois-solid/liq NOS",
                "threedigit": "E866",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by gas distributed by pipeline",
            "title": "E867 - Accidental poisoning by gas distributed by pipeline",
            "threedigit": "E867",
            "children": [
              {
                "key": "E867",
                "title": "E867 Acc poison-piped gas",
                "threedigit": "E867",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by other utility gas and other carbon monoxide",
            "title": "E868 - Accidental poisoning by other utility gas and other carbon monoxide",
            "threedigit": "E868",
            "children": [
              {
                "key": "E8680",
                "title": "E8680 Acc pois-liq petrol gas",
                "threedigit": "E868",
                "isLeaf": true
              },
              {
                "key": "E8681",
                "title": "E8681 Acc pois-utl gas NEC/NOS",
                "threedigit": "E868",
                "isLeaf": true
              },
              {
                "key": "E8682",
                "title": "E8682 Acc poison-exhaust gas",
                "threedigit": "E868",
                "isLeaf": true
              },
              {
                "key": "E8683",
                "title": "E8683 Acc pois-co/domestc fuel",
                "threedigit": "E868",
                "isLeaf": true
              },
              {
                "key": "E8688",
                "title": "E8688 Acc pois-carbn monox NEC",
                "threedigit": "E868",
                "isLeaf": true
              },
              {
                "key": "E8689",
                "title": "E8689 Acc pois-carbn monox NOS",
                "threedigit": "E868",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental poisoning by other gases and vapors",
            "title": "E869 - Accidental poisoning by other gases and vapors",
            "threedigit": "E869",
            "children": [
              {
                "key": "E8690",
                "title": "E8690 Acc poisn-nitrogen oxide",
                "threedigit": "E869",
                "isLeaf": true
              },
              {
                "key": "E8691",
                "title": "E8691 Acc poisn-sulfur dioxide",
                "threedigit": "E869",
                "isLeaf": true
              },
              {
                "key": "E8692",
                "title": "E8692 Acc poison-freon",
                "threedigit": "E869",
                "isLeaf": true
              },
              {
                "key": "E8693",
                "title": "E8693 Acc poison-tear gas",
                "threedigit": "E869",
                "isLeaf": true
              },
              {
                "key": "E8694",
                "title": "E8694 Scndhnd tbcco smoke",
                "threedigit": "E869",
                "isLeaf": true
              },
              {
                "key": "E8698",
                "title": "E8698 Acc poison-gas/vapor NEC",
                "threedigit": "E869",
                "isLeaf": true
              },
              {
                "key": "E8699",
                "title": "E8699 Acc poison-gas/vapor NOS",
                "threedigit": "E869",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Misadventures To Patients During Surgical And Medical Care",
        "title": "E870-E876 Misadventures To Patients During Surgical And Medical Care",
        "threedigit": "E870",
        "children": [
          {
            "key": "Accidental cut puncture perforation or hemorrhage during medical care",
            "title": "E870 - Accidental cut puncture perforation or hemorrhage during medical care",
            "threedigit": "E870",
            "children": [
              {
                "key": "E8700",
                "title": "E8700 Acc cut/hem in surgery",
                "threedigit": "E870",
                "isLeaf": true
              },
              {
                "key": "E8701",
                "title": "E8701 Acc cut/hem in infusion",
                "threedigit": "E870",
                "isLeaf": true
              },
              {
                "key": "E8702",
                "title": "E8702 Acc cut/hem-perfusn NEC",
                "threedigit": "E870",
                "isLeaf": true
              },
              {
                "key": "E8703",
                "title": "E8703 Acc cut/hem in injection",
                "threedigit": "E870",
                "isLeaf": true
              },
              {
                "key": "E8704",
                "title": "E8704 Acc cut/hem w scope exam",
                "threedigit": "E870",
                "isLeaf": true
              },
              {
                "key": "E8705",
                "title": "E8705 Acc cut/hem w catheteriz",
                "threedigit": "E870",
                "isLeaf": true
              },
              {
                "key": "E8706",
                "title": "E8706 Acc cut/hem w heart cath",
                "threedigit": "E870",
                "isLeaf": true
              },
              {
                "key": "E8707",
                "title": "E8707 Acc cut/hem w enema",
                "threedigit": "E870",
                "isLeaf": true
              },
              {
                "key": "E8708",
                "title": "E8708 Acc cut in med care NEC",
                "threedigit": "E870",
                "isLeaf": true
              },
              {
                "key": "E8709",
                "title": "E8709 Acc cut in med care NOS",
                "threedigit": "E870",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign object left in body during procedure",
            "title": "E871 - Foreign object left in body during procedure",
            "threedigit": "E871",
            "children": [
              {
                "key": "E8710",
                "title": "E8710 Post-surgical forgn body",
                "threedigit": "E871",
                "isLeaf": true
              },
              {
                "key": "E8711",
                "title": "E8711 Postinfusion foreign bdy",
                "threedigit": "E871",
                "isLeaf": true
              },
              {
                "key": "E8712",
                "title": "E8712 Postperfusion forgn body",
                "threedigit": "E871",
                "isLeaf": true
              },
              {
                "key": "E8713",
                "title": "E8713 Postinjection forgn body",
                "threedigit": "E871",
                "isLeaf": true
              },
              {
                "key": "E8714",
                "title": "E8714 Postendoscopy forgn body",
                "threedigit": "E871",
                "isLeaf": true
              },
              {
                "key": "E8715",
                "title": "E8715 Postcatheter forgn body",
                "threedigit": "E871",
                "isLeaf": true
              },
              {
                "key": "E8716",
                "title": "E8716 FB post heart catheter",
                "threedigit": "E871",
                "isLeaf": true
              },
              {
                "key": "E8717",
                "title": "E8717 FB post-catheter removal",
                "threedigit": "E871",
                "isLeaf": true
              },
              {
                "key": "E8718",
                "title": "E8718 Post-op foreign body NEC",
                "threedigit": "E871",
                "isLeaf": true
              },
              {
                "key": "E8719",
                "title": "E8719 Post-op foreign body NOS",
                "threedigit": "E871",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Failure of sterile precautions during procedure",
            "title": "E872 - Failure of sterile precautions during procedure",
            "threedigit": "E872",
            "children": [
              {
                "key": "E8720",
                "title": "E8720 Failure sterile surgery",
                "threedigit": "E872",
                "isLeaf": true
              },
              {
                "key": "E8721",
                "title": "E8721 Failure sterile infusion",
                "threedigit": "E872",
                "isLeaf": true
              },
              {
                "key": "E8722",
                "title": "E8722 Fail sterile perfusn NEC",
                "threedigit": "E872",
                "isLeaf": true
              },
              {
                "key": "E8723",
                "title": "E8723 Fail sterile injection",
                "threedigit": "E872",
                "isLeaf": true
              },
              {
                "key": "E8724",
                "title": "E8724 Fail sterile endoscopy",
                "threedigit": "E872",
                "isLeaf": true
              },
              {
                "key": "E8725",
                "title": "E8725 Fail sterile catheter",
                "threedigit": "E872",
                "isLeaf": true
              },
              {
                "key": "E8726",
                "title": "E8726 Fail sterile heart cath",
                "threedigit": "E872",
                "isLeaf": true
              },
              {
                "key": "E8728",
                "title": "E8728 Fail sterile proced NEC",
                "threedigit": "E872",
                "isLeaf": true
              },
              {
                "key": "E8729",
                "title": "E8729 Fail sterile proced NOS",
                "threedigit": "E872",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Failure in dosage",
            "title": "E873 - Failure in dosage",
            "threedigit": "E873",
            "children": [
              {
                "key": "E8730",
                "title": "E8730 Excess fluid in infusion",
                "threedigit": "E873",
                "isLeaf": true
              },
              {
                "key": "E8731",
                "title": "E8731 Incor dilut infusn fluid",
                "threedigit": "E873",
                "isLeaf": true
              },
              {
                "key": "E8732",
                "title": "E8732 Therap radiation overdos",
                "threedigit": "E873",
                "isLeaf": true
              },
              {
                "key": "E8733",
                "title": "E8733 Inadv radiat exp-medical",
                "threedigit": "E873",
                "isLeaf": true
              },
              {
                "key": "E8734",
                "title": "E8734 Dosag fail-shock therapy",
                "threedigit": "E873",
                "isLeaf": true
              },
              {
                "key": "E8735",
                "title": "E8735 Wrng temp in applic/pack",
                "threedigit": "E873",
                "isLeaf": true
              },
              {
                "key": "E8736",
                "title": "E8736 Nonadmin necess medicine",
                "threedigit": "E873",
                "isLeaf": true
              },
              {
                "key": "E8738",
                "title": "E8738 Failure in dosage NEC",
                "threedigit": "E873",
                "isLeaf": true
              },
              {
                "key": "E8739",
                "title": "E8739 Failure in dosage NOS",
                "threedigit": "E873",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Mechanical failure of instrument or apparatus during procedure",
            "title": "E874 - Mechanical failure of instrument or apparatus during procedure",
            "threedigit": "E874",
            "children": [
              {
                "key": "E8740",
                "title": "E8740 Instrmnt fail in surgery",
                "threedigit": "E874",
                "isLeaf": true
              },
              {
                "key": "E8741",
                "title": "E8741 Instrumnt fail-infusion",
                "threedigit": "E874",
                "isLeaf": true
              },
              {
                "key": "E8742",
                "title": "E8742 Instrmnt fail-perfus NEC",
                "threedigit": "E874",
                "isLeaf": true
              },
              {
                "key": "E8743",
                "title": "E8743 Instrumnt fail-endoscopy",
                "threedigit": "E874",
                "isLeaf": true
              },
              {
                "key": "E8744",
                "title": "E8744 Instrmnt fail-catheteriz",
                "threedigit": "E874",
                "isLeaf": true
              },
              {
                "key": "E8745",
                "title": "E8745 Instrmnt fail-heart cath",
                "threedigit": "E874",
                "isLeaf": true
              },
              {
                "key": "E8748",
                "title": "E8748 Instrmnt fail-proced NEC",
                "threedigit": "E874",
                "isLeaf": true
              },
              {
                "key": "E8749",
                "title": "E8749 Instrmnt fail-proced NOS",
                "threedigit": "E874",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Contaminated or infected blood other fluid drug or biological substance",
            "title": "E875 - Contaminated or infected blood other fluid drug or biological substance",
            "threedigit": "E875",
            "children": [
              {
                "key": "E8750",
                "title": "E8750 Contaminated transfusion",
                "threedigit": "E875",
                "isLeaf": true
              },
              {
                "key": "E8751",
                "title": "E8751 Contaminated injection",
                "threedigit": "E875",
                "isLeaf": true
              },
              {
                "key": "E8752",
                "title": "E8752 Contaminated drug NEC",
                "threedigit": "E875",
                "isLeaf": true
              },
              {
                "key": "E8758",
                "title": "E8758 Contamination NEC",
                "threedigit": "E875",
                "isLeaf": true
              },
              {
                "key": "E8759",
                "title": "E8759 Contamination NOS",
                "threedigit": "E875",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified misadventures during medical care",
            "title": "E876 - Other and unspecified misadventures during medical care",
            "threedigit": "E876",
            "children": [
              {
                "key": "E8760",
                "title": "E8760 Mismatch blood-transfusn",
                "threedigit": "E876",
                "isLeaf": true
              },
              {
                "key": "E8761",
                "title": "E8761 Wrong fluid in infusion",
                "threedigit": "E876",
                "isLeaf": true
              },
              {
                "key": "E8762",
                "title": "E8762 Failure in suture",
                "threedigit": "E876",
                "isLeaf": true
              },
              {
                "key": "E8763",
                "title": "E8763 Misplaced endotrach tube",
                "threedigit": "E876",
                "isLeaf": true
              },
              {
                "key": "E8764",
                "title": "E8764 Fail introd/remove tube",
                "threedigit": "E876",
                "isLeaf": true
              },
              {
                "key": "E8765",
                "title": "E8765 Perfrm wrong op/right pt",
                "threedigit": "E876",
                "isLeaf": true
              },
              {
                "key": "E8766",
                "title": "E8766 Proc-pt not sched surg",
                "threedigit": "E876",
                "isLeaf": true
              },
              {
                "key": "E8767",
                "title": "E8767 Rt proc-wrong side/part",
                "threedigit": "E876",
                "isLeaf": true
              },
              {
                "key": "E8768",
                "title": "E8768 Medical misadventure NEC",
                "threedigit": "E876",
                "isLeaf": true
              },
              {
                "key": "E8769",
                "title": "E8769 Medical misadventure NOS",
                "threedigit": "E876",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Surgical And Medical Procedures As The Cause Of Abnormal Reaction Of Patient Or Later Complication, Without Mention Of Misadventure At The Time Of Procedure",
        "title": "E878-E879 Surgical And Medical Procedures As The Cause Of Abnormal Reaction Of Patient Or Later Complication, Without Mention Of Misadventure At The Time Of Procedure",
        "threedigit": "E878",
        "children": [
          {
            "key": "Surgical operation and other surgical procedures as the cause of abnormal reaction of patient or of later complication without mention of misadventure at the time of operation",
            "title": "E878 - Surgical operation and other surgical procedures as the cause of abnormal reaction of patient or of later complication without mention of misadventure at the time of operation",
            "threedigit": "E878",
            "children": [
              {
                "key": "E8780",
                "title": "E8780 Abn react-org transplant",
                "threedigit": "E878",
                "isLeaf": true
              },
              {
                "key": "E8781",
                "title": "E8781 Abn react-artif implant",
                "threedigit": "E878",
                "isLeaf": true
              },
              {
                "key": "E8782",
                "title": "E8782 Abn react-anastom/graft",
                "threedigit": "E878",
                "isLeaf": true
              },
              {
                "key": "E8783",
                "title": "E8783 Abn react-external stoma",
                "threedigit": "E878",
                "isLeaf": true
              },
              {
                "key": "E8784",
                "title": "E8784 Abn react-plast surg NEC",
                "threedigit": "E878",
                "isLeaf": true
              },
              {
                "key": "E8785",
                "title": "E8785 Abn react-limb amputat",
                "threedigit": "E878",
                "isLeaf": true
              },
              {
                "key": "E8786",
                "title": "E8786 Abn reac-organ rem NEC",
                "threedigit": "E878",
                "isLeaf": true
              },
              {
                "key": "E8788",
                "title": "E8788 Abn react-surg proc NEC",
                "threedigit": "E878",
                "isLeaf": true
              },
              {
                "key": "E8789",
                "title": "E8789 Abn react-surg proc NOS",
                "threedigit": "E878",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other procedures without mention of misadventure at the time of procedure as the cause of abnormal reaction of patient or of later complication",
            "title": "E879 - Other procedures without mention of misadventure at the time of procedure as the cause of abnormal reaction of patient or of later complication",
            "threedigit": "E879",
            "children": [
              {
                "key": "E8790",
                "title": "E8790 Abn react-cardiac cath",
                "threedigit": "E879",
                "isLeaf": true
              },
              {
                "key": "E8791",
                "title": "E8791 Abn react-renal dialysis",
                "threedigit": "E879",
                "isLeaf": true
              },
              {
                "key": "E8792",
                "title": "E8792 Abn react-radiotherapy",
                "threedigit": "E879",
                "isLeaf": true
              },
              {
                "key": "E8793",
                "title": "E8793 Abn react-shock therapy",
                "threedigit": "E879",
                "isLeaf": true
              },
              {
                "key": "E8794",
                "title": "E8794 Abn react-fluid aspirat",
                "threedigit": "E879",
                "isLeaf": true
              },
              {
                "key": "E8795",
                "title": "E8795 Abn react-gastric sound",
                "threedigit": "E879",
                "isLeaf": true
              },
              {
                "key": "E8796",
                "title": "E8796 Abn react-urinary cath",
                "threedigit": "E879",
                "isLeaf": true
              },
              {
                "key": "E8797",
                "title": "E8797 Abn react-blood sampling",
                "threedigit": "E879",
                "isLeaf": true
              },
              {
                "key": "E8798",
                "title": "E8798 Abn react-procedure NEC",
                "threedigit": "E879",
                "isLeaf": true
              },
              {
                "key": "E8799",
                "title": "E8799 Abn react-procedure NOS",
                "threedigit": "E879",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Accidental Falls",
        "title": "E880-E888 Accidental Falls",
        "threedigit": "E880",
        "children": [
          {
            "key": "Accidental fall on or from stairs or steps",
            "title": "E880 - Accidental fall on or from stairs or steps",
            "threedigit": "E880",
            "children": [
              {
                "key": "E8800",
                "title": "E8800 Fall on escalator",
                "threedigit": "E880",
                "isLeaf": true
              },
              {
                "key": "E8801",
                "title": "E8801 Fall on sidewalk curb",
                "threedigit": "E880",
                "isLeaf": true
              },
              {
                "key": "E8809",
                "title": "E8809 Fall on stair/step NEC",
                "threedigit": "E880",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental fall on or from ladders or scaffolding",
            "title": "E881 - Accidental fall on or from ladders or scaffolding",
            "threedigit": "E881",
            "children": [
              {
                "key": "E8810",
                "title": "E8810 Fall from ladder",
                "threedigit": "E881",
                "isLeaf": true
              },
              {
                "key": "E8811",
                "title": "E8811 Fall from scaffolding",
                "threedigit": "E881",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental fall from or out of building or other structure",
            "title": "E882 - Accidental fall from or out of building or other structure",
            "threedigit": "E882",
            "children": [
              {
                "key": "E882",
                "title": "E882 Fall from building",
                "threedigit": "E882",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental fall into hole or other opening in surface",
            "title": "E883 - Accidental fall into hole or other opening in surface",
            "threedigit": "E883",
            "children": [
              {
                "key": "E8830",
                "title": "E8830 Diving accident",
                "threedigit": "E883",
                "isLeaf": true
              },
              {
                "key": "E8831",
                "title": "E8831 Fall into well",
                "threedigit": "E883",
                "isLeaf": true
              },
              {
                "key": "E8832",
                "title": "E8832 Fall into storm drain",
                "threedigit": "E883",
                "isLeaf": true
              },
              {
                "key": "E8839",
                "title": "E8839 Fall into other hole",
                "threedigit": "E883",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other accidental falls from one level to another",
            "title": "E884 - Other accidental falls from one level to another",
            "threedigit": "E884",
            "children": [
              {
                "key": "E8840",
                "title": "E8840 Fall from playgrnd equip",
                "threedigit": "E884",
                "isLeaf": true
              },
              {
                "key": "E8841",
                "title": "E8841 Fall from cliff",
                "threedigit": "E884",
                "isLeaf": true
              },
              {
                "key": "E8842",
                "title": "E8842 Fall from chair",
                "threedigit": "E884",
                "isLeaf": true
              },
              {
                "key": "E8843",
                "title": "E8843 Fall from wheelchair",
                "threedigit": "E884",
                "isLeaf": true
              },
              {
                "key": "E8844",
                "title": "E8844 Fall from bed",
                "threedigit": "E884",
                "isLeaf": true
              },
              {
                "key": "E8845",
                "title": "E8845 Fall from furniture NEC",
                "threedigit": "E884",
                "isLeaf": true
              },
              {
                "key": "E8846",
                "title": "E8846 Fall from commode",
                "threedigit": "E884",
                "isLeaf": true
              },
              {
                "key": "E8849",
                "title": "E8849 Fall-1 level to oth NEC",
                "threedigit": "E884",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental fall on same level from slipping tripping or stumbling",
            "title": "E885 - Accidental fall on same level from slipping tripping or stumbling",
            "threedigit": "E885",
            "children": [
              {
                "key": "E8850",
                "title": "E8850 Fall-nonmotor scooter",
                "threedigit": "E885",
                "isLeaf": true
              },
              {
                "key": "E8851",
                "title": "E8851 Fall from roller skates",
                "threedigit": "E885",
                "isLeaf": true
              },
              {
                "key": "E8852",
                "title": "E8852 Fall from skateboard",
                "threedigit": "E885",
                "isLeaf": true
              },
              {
                "key": "E8853",
                "title": "E8853 Fall from skis",
                "threedigit": "E885",
                "isLeaf": true
              },
              {
                "key": "E8854",
                "title": "E8854 Fall from snowboard",
                "threedigit": "E885",
                "isLeaf": true
              },
              {
                "key": "E8859",
                "title": "E8859 Fall from slipping NEC",
                "threedigit": "E885",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fall on same level from collision, pushing, or shoving, by or with other person",
            "title": "E886 - Fall on same level from collision, pushing, or shoving, by or with other person",
            "threedigit": "E886",
            "children": [
              {
                "key": "E8860",
                "title": "E8860 Fall in sports",
                "threedigit": "E886",
                "isLeaf": true
              },
              {
                "key": "E8869",
                "title": "E8869 Fall on level NEC/NOS",
                "threedigit": "E886",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fracture, cause unspecified",
            "title": "E887 - Fracture, cause unspecified",
            "threedigit": "E887",
            "children": [
              {
                "key": "E887",
                "title": "E887 Fracture, cause NOS",
                "threedigit": "E887",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified fall",
            "title": "E888 - Other and unspecified fall",
            "threedigit": "E888",
            "children": [
              {
                "key": "E8880",
                "title": "E8880 Fall striking sharp obj",
                "threedigit": "E888",
                "isLeaf": true
              },
              {
                "key": "E8881",
                "title": "E8881 Fall striking object NEC",
                "threedigit": "E888",
                "isLeaf": true
              },
              {
                "key": "E8888",
                "title": "E8888 Fall NEC",
                "threedigit": "E888",
                "isLeaf": true
              },
              {
                "key": "E8889",
                "title": "E8889 Fall NOS",
                "threedigit": "E888",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Accidents Caused By Fire And Flames",
        "title": "E890-E899 Accidents Caused By Fire And Flames",
        "threedigit": "E890",
        "children": [
          {
            "key": "Conflagration in private dwelling",
            "title": "E890 - Conflagration in private dwelling",
            "threedigit": "E890",
            "children": [
              {
                "key": "E8900",
                "title": "E8900 Privat dwell fire-explos",
                "threedigit": "E890",
                "isLeaf": true
              },
              {
                "key": "E8901",
                "title": "E8901 Priv dwel fire-pvc fumes",
                "threedigit": "E890",
                "isLeaf": true
              },
              {
                "key": "E8902",
                "title": "E8902 Priv dwel fire-fumes NOS",
                "threedigit": "E890",
                "isLeaf": true
              },
              {
                "key": "E8903",
                "title": "E8903 Priv dwel fire-burning",
                "threedigit": "E890",
                "isLeaf": true
              },
              {
                "key": "E8908",
                "title": "E8908 Priv dwel fire-accid NEC",
                "threedigit": "E890",
                "isLeaf": true
              },
              {
                "key": "E8909",
                "title": "E8909 Priv dwel fire-accid NOS",
                "threedigit": "E890",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Conflagration in other and unspecified building or structure",
            "title": "E891 - Conflagration in other and unspecified building or structure",
            "threedigit": "E891",
            "children": [
              {
                "key": "E8910",
                "title": "E8910 Fire in bldg-explosion",
                "threedigit": "E891",
                "isLeaf": true
              },
              {
                "key": "E8911",
                "title": "E8911 Fire in bldg-pvc fumes",
                "threedigit": "E891",
                "isLeaf": true
              },
              {
                "key": "E8912",
                "title": "E8912 Fire in bldg-fumes NOS",
                "threedigit": "E891",
                "isLeaf": true
              },
              {
                "key": "E8913",
                "title": "E8913 Fire in bldg-burning",
                "threedigit": "E891",
                "isLeaf": true
              },
              {
                "key": "E8918",
                "title": "E8918 Fire in bldg-accid NEC",
                "threedigit": "E891",
                "isLeaf": true
              },
              {
                "key": "E8919",
                "title": "E8919 Fire in bldg-accid NOS",
                "threedigit": "E891",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Conflagration not in building or structure",
            "title": "E892 - Conflagration not in building or structure",
            "threedigit": "E892",
            "children": [
              {
                "key": "E892",
                "title": "E892 Fire not in building",
                "threedigit": "E892",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by ignition of clothing",
            "title": "E893 - Accident caused by ignition of clothing",
            "threedigit": "E893",
            "children": [
              {
                "key": "E8930",
                "title": "E8930 Clothing fire-priv dwell",
                "threedigit": "E893",
                "isLeaf": true
              },
              {
                "key": "E8931",
                "title": "E8931 Clothing fire-bldg NEC",
                "threedigit": "E893",
                "isLeaf": true
              },
              {
                "key": "E8932",
                "title": "E8932 Clothing fire not in bld",
                "threedigit": "E893",
                "isLeaf": true
              },
              {
                "key": "E8938",
                "title": "E8938 Clothing fire NEC",
                "threedigit": "E893",
                "isLeaf": true
              },
              {
                "key": "E8939",
                "title": "E8939 Clothing fire NOS",
                "threedigit": "E893",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Ignition of highly inflammable material",
            "title": "E894 - Ignition of highly inflammable material",
            "threedigit": "E894",
            "children": [
              {
                "key": "E894",
                "title": "E894 Fire-highly inflam mater",
                "threedigit": "E894",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by controlled fire in private dwelling",
            "title": "E895 - Accident caused by controlled fire in private dwelling",
            "threedigit": "E895",
            "children": [
              {
                "key": "E895",
                "title": "E895 Burn acc in privat dwell",
                "threedigit": "E895",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by controlled fire in other and unspecified building or structure",
            "title": "E896 - Accident caused by controlled fire in other and unspecified building or structure",
            "threedigit": "E896",
            "children": [
              {
                "key": "E896",
                "title": "E896 Burn acc in bldg NEC",
                "threedigit": "E896",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by controlled fire not in building or structure",
            "title": "E897 - Accident caused by controlled fire not in building or structure",
            "threedigit": "E897",
            "children": [
              {
                "key": "E897",
                "title": "E897 Burn acc not in bldg",
                "threedigit": "E897",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by other specified fire and flames",
            "title": "E898 - Accident caused by other specified fire and flames",
            "threedigit": "E898",
            "children": [
              {
                "key": "E8980",
                "title": "E8980 Burning bedclothes",
                "threedigit": "E898",
                "isLeaf": true
              },
              {
                "key": "E8981",
                "title": "E8981 Fire accident NEC",
                "threedigit": "E898",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by unspecified fire",
            "title": "E899 - Accident caused by unspecified fire",
            "threedigit": "E899",
            "children": [
              {
                "key": "E899",
                "title": "E899 Fire accident NOS",
                "threedigit": "E899",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Accidents Due To Natural And Environmental Factors",
        "title": "E900-E909 Accidents Due To Natural And Environmental Factors",
        "threedigit": "E900",
        "children": [
          {
            "key": "Accident caused by excessive heat",
            "title": "E900 - Accident caused by excessive heat",
            "threedigit": "E900",
            "children": [
              {
                "key": "E9000",
                "title": "E9000 Excessive heat: weather",
                "threedigit": "E900",
                "isLeaf": true
              },
              {
                "key": "E9001",
                "title": "E9001 Excessive heat, man-made",
                "threedigit": "E900",
                "isLeaf": true
              },
              {
                "key": "E9009",
                "title": "E9009 Excessive heat NOS",
                "threedigit": "E900",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidents due to excessive cold",
            "title": "E901 - Accidents due to excessive cold",
            "threedigit": "E901",
            "children": [
              {
                "key": "E9010",
                "title": "E9010 Excessive cold: weather",
                "threedigit": "E901",
                "isLeaf": true
              },
              {
                "key": "E9011",
                "title": "E9011 Excessive cold, man-made",
                "threedigit": "E901",
                "isLeaf": true
              },
              {
                "key": "E9018",
                "title": "E9018 Excessive cold NEC",
                "threedigit": "E901",
                "isLeaf": true
              },
              {
                "key": "E9019",
                "title": "E9019 Excessive cold NOS",
                "threedigit": "E901",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident due to high and low air pressure and changes in air pressure",
            "title": "E902 - Accident due to high and low air pressure and changes in air pressure",
            "threedigit": "E902",
            "children": [
              {
                "key": "E9020",
                "title": "E9020 High altitude residence",
                "threedigit": "E902",
                "isLeaf": true
              },
              {
                "key": "E9021",
                "title": "E9021 Air press chnge: aircrft",
                "threedigit": "E902",
                "isLeaf": true
              },
              {
                "key": "E9022",
                "title": "E9022 Air press change: diving",
                "threedigit": "E902",
                "isLeaf": true
              },
              {
                "key": "E9028",
                "title": "E9028 Air pressure change NEC",
                "threedigit": "E902",
                "isLeaf": true
              },
              {
                "key": "E9029",
                "title": "E9029 Air pressure change NOS",
                "threedigit": "E902",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by travel and motion",
            "title": "E903 - Accident caused by travel and motion",
            "threedigit": "E903",
            "children": [
              {
                "key": "E903",
                "title": "E903 Travel and motion",
                "threedigit": "E903",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident due to hunger thirst exposure and neglect",
            "title": "E904 - Accident due to hunger thirst exposure and neglect",
            "threedigit": "E904",
            "children": [
              {
                "key": "E9040",
                "title": "E9040 Abandonment/lack of care",
                "threedigit": "E904",
                "isLeaf": true
              },
              {
                "key": "E9041",
                "title": "E9041 Lack of food",
                "threedigit": "E904",
                "isLeaf": true
              },
              {
                "key": "E9042",
                "title": "E9042 Lack of water",
                "threedigit": "E904",
                "isLeaf": true
              },
              {
                "key": "E9043",
                "title": "E9043 Exposure NEC",
                "threedigit": "E904",
                "isLeaf": true
              },
              {
                "key": "E9049",
                "title": "E9049 Privation NOS",
                "threedigit": "E904",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Venomous animals and plants as the cause of poisoning and toxic reactions",
            "title": "E905 - Venomous animals and plants as the cause of poisoning and toxic reactions",
            "threedigit": "E905",
            "children": [
              {
                "key": "E9050",
                "title": "E9050 Venomous snake bite",
                "threedigit": "E905",
                "isLeaf": true
              },
              {
                "key": "E9051",
                "title": "E9051 Venomous spider bite",
                "threedigit": "E905",
                "isLeaf": true
              },
              {
                "key": "E9052",
                "title": "E9052 Scorpion sting",
                "threedigit": "E905",
                "isLeaf": true
              },
              {
                "key": "E9053",
                "title": "E9053 Hornet/wasp/bee sting",
                "threedigit": "E905",
                "isLeaf": true
              },
              {
                "key": "E9054",
                "title": "E9054 Centipede bite",
                "threedigit": "E905",
                "isLeaf": true
              },
              {
                "key": "E9055",
                "title": "E9055 Venomous arthropods NEC",
                "threedigit": "E905",
                "isLeaf": true
              },
              {
                "key": "E9056",
                "title": "E9056 Venom sea animals/plants",
                "threedigit": "E905",
                "isLeaf": true
              },
              {
                "key": "E9057",
                "title": "E9057 Poisoning by other plant",
                "threedigit": "E905",
                "isLeaf": true
              },
              {
                "key": "E9058",
                "title": "E9058 Venomous bite/sting NEC",
                "threedigit": "E905",
                "isLeaf": true
              },
              {
                "key": "E9059",
                "title": "E9059 Venomous bite/sting NOS",
                "threedigit": "E905",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other injury caused by animals",
            "title": "E906 - Other injury caused by animals",
            "threedigit": "E906",
            "children": [
              {
                "key": "E9060",
                "title": "E9060 Dog bite",
                "threedigit": "E906",
                "isLeaf": true
              },
              {
                "key": "E9061",
                "title": "E9061 Rat bite",
                "threedigit": "E906",
                "isLeaf": true
              },
              {
                "key": "E9062",
                "title": "E9062 Nonvenomous snake bite",
                "threedigit": "E906",
                "isLeaf": true
              },
              {
                "key": "E9063",
                "title": "E9063 Animal bite NEC",
                "threedigit": "E906",
                "isLeaf": true
              },
              {
                "key": "E9064",
                "title": "E9064 Nonvenom arthropod bite",
                "threedigit": "E906",
                "isLeaf": true
              },
              {
                "key": "E9065",
                "title": "E9065 Animal bite NOS",
                "threedigit": "E906",
                "isLeaf": true
              },
              {
                "key": "E9068",
                "title": "E9068 Inj NEC caused by animal",
                "threedigit": "E906",
                "isLeaf": true
              },
              {
                "key": "E9069",
                "title": "E9069 Inj NOS caused by animal",
                "threedigit": "E906",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident due to lightning",
            "title": "E907 - Accident due to lightning",
            "threedigit": "E907",
            "children": [
              {
                "key": "E907",
                "title": "E907 Acc due to lightning",
                "threedigit": "E907",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident due to cataclysmic storms and floods resulting from storms",
            "title": "E908 - Accident due to cataclysmic storms and floods resulting from storms",
            "threedigit": "E908",
            "children": [
              {
                "key": "E9080",
                "title": "E9080 Accident d/t hurricane",
                "threedigit": "E908",
                "isLeaf": true
              },
              {
                "key": "E9081",
                "title": "E9081 Accident d/t tornado",
                "threedigit": "E908",
                "isLeaf": true
              },
              {
                "key": "E9082",
                "title": "E9082 Accident d/t floods",
                "threedigit": "E908",
                "isLeaf": true
              },
              {
                "key": "E9083",
                "title": "E9083 Acc d/t snow blizzard",
                "threedigit": "E908",
                "isLeaf": true
              },
              {
                "key": "E9084",
                "title": "E9084 Accident d/t dust storm",
                "threedigit": "E908",
                "isLeaf": true
              },
              {
                "key": "E9088",
                "title": "E9088 Accident d/t storm NEC",
                "threedigit": "E908",
                "isLeaf": true
              },
              {
                "key": "E9089",
                "title": "E9089 Acc d/t storm/flood NOS",
                "threedigit": "E908",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident due to cataclysmic earth surface movements and eruptions",
            "title": "E909 - Accident due to cataclysmic earth surface movements and eruptions",
            "threedigit": "E909",
            "children": [
              {
                "key": "E9090",
                "title": "E9090 Acc d/t earthquakes",
                "threedigit": "E909",
                "isLeaf": true
              },
              {
                "key": "E9091",
                "title": "E9091 Acc d/t volcanic erupt",
                "threedigit": "E909",
                "isLeaf": true
              },
              {
                "key": "E9092",
                "title": "E9092 Acc d/t avalanche",
                "threedigit": "E909",
                "isLeaf": true
              },
              {
                "key": "E9093",
                "title": "E9093 Acc d/t dam collapse",
                "threedigit": "E909",
                "isLeaf": true
              },
              {
                "key": "E9094",
                "title": "E9094 Acc d/t tidalwave NOS",
                "threedigit": "E909",
                "isLeaf": true
              },
              {
                "key": "E9098",
                "title": "E9098 Acc d/t eruptions NEC",
                "threedigit": "E909",
                "isLeaf": true
              },
              {
                "key": "E9099",
                "title": "E9099 Acc d/t eruptions NOS",
                "threedigit": "E909",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Accidents Caused By Submersion, Suffocation, And Foreign Bodies",
        "title": "E910-E915 Accidents Caused By Submersion, Suffocation, And Foreign Bodies",
        "threedigit": "E910",
        "children": [
          {
            "key": "Accidental drowning and submersion",
            "title": "E910 - Accidental drowning and submersion",
            "threedigit": "E910",
            "children": [
              {
                "key": "E9100",
                "title": "E9100 Water-skiing accident",
                "threedigit": "E910",
                "isLeaf": true
              },
              {
                "key": "E9101",
                "title": "E9101 Skin/scuba diving acc",
                "threedigit": "E910",
                "isLeaf": true
              },
              {
                "key": "E9102",
                "title": "E9102 Swimming accident NOS",
                "threedigit": "E910",
                "isLeaf": true
              },
              {
                "key": "E9103",
                "title": "E9103 Swimming/diving acc NEC",
                "threedigit": "E910",
                "isLeaf": true
              },
              {
                "key": "E9104",
                "title": "E9104 Drowning in bathtub",
                "threedigit": "E910",
                "isLeaf": true
              },
              {
                "key": "E9108",
                "title": "E9108 Accidental drowning NEC",
                "threedigit": "E910",
                "isLeaf": true
              },
              {
                "key": "E9109",
                "title": "E9109 Accidental drowning NOS",
                "threedigit": "E910",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Inhalation and ingestion of food causing obstruction of respiratory tract or suffocation",
            "title": "E911 - Inhalation and ingestion of food causing obstruction of respiratory tract or suffocation",
            "threedigit": "E911",
            "children": [
              {
                "key": "E911",
                "title": "E911 Resp obstr-food inhal",
                "threedigit": "E911",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Inhalation and ingestion of other object causing obstruction of respiratory tract or suffocation",
            "title": "E912 - Inhalation and ingestion of other object causing obstruction of respiratory tract or suffocation",
            "threedigit": "E912",
            "children": [
              {
                "key": "E912",
                "title": "E912 Resp obstr-inhal obj NEC",
                "threedigit": "E912",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidental mechanical suffocation",
            "title": "E913 - Accidental mechanical suffocation",
            "threedigit": "E913",
            "children": [
              {
                "key": "E9130",
                "title": "E9130 Suffocat in bed/cradle",
                "threedigit": "E913",
                "isLeaf": true
              },
              {
                "key": "E9131",
                "title": "E9131 Suffocation-plastic bag",
                "threedigit": "E913",
                "isLeaf": true
              },
              {
                "key": "E9132",
                "title": "E9132 Suffocation-lack of air",
                "threedigit": "E913",
                "isLeaf": true
              },
              {
                "key": "E9133",
                "title": "E9133 Cave-in NOS",
                "threedigit": "E913",
                "isLeaf": true
              },
              {
                "key": "E9138",
                "title": "E9138 Suffocation NEC",
                "threedigit": "E913",
                "isLeaf": true
              },
              {
                "key": "E9139",
                "title": "E9139 Suffocation NOS",
                "threedigit": "E913",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body accidentally entering eye and adnexa",
            "title": "E914 - Foreign body accidentally entering eye and adnexa",
            "threedigit": "E914",
            "children": [
              {
                "key": "E914",
                "title": "E914 FB entering eye",
                "threedigit": "E914",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Foreign body accidentally entering other orifice",
            "title": "E915 - Foreign body accidentally entering other orifice",
            "threedigit": "E915",
            "children": [
              {
                "key": "E915",
                "title": "E915 FB entering oth orifice",
                "threedigit": "E915",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Accidents",
        "title": "E916-E928 Other Accidents",
        "threedigit": "E916",
        "children": [
          {
            "key": "Struck accidentally by falling object",
            "title": "E916 - Struck accidentally by falling object",
            "threedigit": "E916",
            "children": [
              {
                "key": "E916",
                "title": "E916 Struck by falling object",
                "threedigit": "E916",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Striking against or struck accidentally by objects or persons",
            "title": "E917 - Striking against or struck accidentally by objects or persons",
            "threedigit": "E917",
            "children": [
              {
                "key": "E9170",
                "title": "E9170 Sports acc w/o sub fall",
                "threedigit": "E917",
                "isLeaf": true
              },
              {
                "key": "E9171",
                "title": "E9171 Crowd w/o sub fall",
                "threedigit": "E917",
                "isLeaf": true
              },
              {
                "key": "E9172",
                "title": "E9172 Run water w/o sub fall",
                "threedigit": "E917",
                "isLeaf": true
              },
              {
                "key": "E9173",
                "title": "E9173 Furnit w/o sub fall",
                "threedigit": "E917",
                "isLeaf": true
              },
              {
                "key": "E9174",
                "title": "E9174 Stat ob w/o sub fall NEC",
                "threedigit": "E917",
                "isLeaf": true
              },
              {
                "key": "E9175",
                "title": "E9175 Sports acc w sub fall",
                "threedigit": "E917",
                "isLeaf": true
              },
              {
                "key": "E9176",
                "title": "E9176 Crowd accidnt w sub fall",
                "threedigit": "E917",
                "isLeaf": true
              },
              {
                "key": "E9177",
                "title": "E9177 Furniture acc w sub fall",
                "threedigit": "E917",
                "isLeaf": true
              },
              {
                "key": "E9178",
                "title": "E9178 Stat obj w sub fall NEC",
                "threedigit": "E917",
                "isLeaf": true
              },
              {
                "key": "E9179",
                "title": "E9179 Obj w-w/o sub fall NEC",
                "threedigit": "E917",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Caught accidentally in or between objects",
            "title": "E918 - Caught accidentally in or between objects",
            "threedigit": "E918",
            "children": [
              {
                "key": "E918",
                "title": "E918 Caught between objects",
                "threedigit": "E918",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidents caused by machinery",
            "title": "E919 - Accidents caused by machinery",
            "threedigit": "E919",
            "children": [
              {
                "key": "E9190",
                "title": "E9190 Machine accid-agricult",
                "threedigit": "E919",
                "isLeaf": true
              },
              {
                "key": "E9191",
                "title": "E9191 Machine accid-mining",
                "threedigit": "E919",
                "isLeaf": true
              },
              {
                "key": "E9192",
                "title": "E9192 Lifting machine accident",
                "threedigit": "E919",
                "isLeaf": true
              },
              {
                "key": "E9193",
                "title": "E9193 Metalworking machine acc",
                "threedigit": "E919",
                "isLeaf": true
              },
              {
                "key": "E9194",
                "title": "E9194 Woodworking machine acc",
                "threedigit": "E919",
                "isLeaf": true
              },
              {
                "key": "E9195",
                "title": "E9195 Prime mover machine acc",
                "threedigit": "E919",
                "isLeaf": true
              },
              {
                "key": "E9196",
                "title": "E9196 Transmission machine acc",
                "threedigit": "E919",
                "isLeaf": true
              },
              {
                "key": "E9197",
                "title": "E9197 Earth moving machine acc",
                "threedigit": "E919",
                "isLeaf": true
              },
              {
                "key": "E9198",
                "title": "E9198 Machinery accident NEC",
                "threedigit": "E919",
                "isLeaf": true
              },
              {
                "key": "E9199",
                "title": "E9199 Machinery accident NOS",
                "threedigit": "E919",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accidents caused by cutting and piercing instruments or objects",
            "title": "E920 - Accidents caused by cutting and piercing instruments or objects",
            "threedigit": "E920",
            "children": [
              {
                "key": "E9200",
                "title": "E9200 Acc-powered lawn mower",
                "threedigit": "E920",
                "isLeaf": true
              },
              {
                "key": "E9201",
                "title": "E9201 Acc-power hand tool NEC",
                "threedigit": "E920",
                "isLeaf": true
              },
              {
                "key": "E9202",
                "title": "E9202 Acc-power house applianc",
                "threedigit": "E920",
                "isLeaf": true
              },
              {
                "key": "E9203",
                "title": "E9203 Knife/sword/dagger acc",
                "threedigit": "E920",
                "isLeaf": true
              },
              {
                "key": "E9204",
                "title": "E9204 Accid-other hand tools",
                "threedigit": "E920",
                "isLeaf": true
              },
              {
                "key": "E9205",
                "title": "E9205 Acc-hypodermic needle",
                "threedigit": "E920",
                "isLeaf": true
              },
              {
                "key": "E9208",
                "title": "E9208 Acc-cutting instrum NEC",
                "threedigit": "E920",
                "isLeaf": true
              },
              {
                "key": "E9209",
                "title": "E9209 Acc-cutting instrum NOS",
                "threedigit": "E920",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by explosion of pressure vessel",
            "title": "E921 - Accident caused by explosion of pressure vessel",
            "threedigit": "E921",
            "children": [
              {
                "key": "E9210",
                "title": "E9210 Boiler explosion",
                "threedigit": "E921",
                "isLeaf": true
              },
              {
                "key": "E9211",
                "title": "E9211 Gas cylinder explosion",
                "threedigit": "E921",
                "isLeaf": true
              },
              {
                "key": "E9218",
                "title": "E9218 Press vessel explos NEC",
                "threedigit": "E921",
                "isLeaf": true
              },
              {
                "key": "E9219",
                "title": "E9219 Press vessel explos NOS",
                "threedigit": "E921",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by firearm and air gun missile",
            "title": "E922 - Accident caused by firearm and air gun missile",
            "threedigit": "E922",
            "children": [
              {
                "key": "E9220",
                "title": "E9220 Handgun accident",
                "threedigit": "E922",
                "isLeaf": true
              },
              {
                "key": "E9221",
                "title": "E9221 Shotgun accident",
                "threedigit": "E922",
                "isLeaf": true
              },
              {
                "key": "E9222",
                "title": "E9222 Hunting rifle accident",
                "threedigit": "E922",
                "isLeaf": true
              },
              {
                "key": "E9223",
                "title": "E9223 Military firearm accid",
                "threedigit": "E922",
                "isLeaf": true
              },
              {
                "key": "E9224",
                "title": "E9224 Accident - air gun",
                "threedigit": "E922",
                "isLeaf": true
              },
              {
                "key": "E9225",
                "title": "E9225 Accident-paintball gun",
                "threedigit": "E922",
                "isLeaf": true
              },
              {
                "key": "E9228",
                "title": "E9228 Firearm accident NEC",
                "threedigit": "E922",
                "isLeaf": true
              },
              {
                "key": "E9229",
                "title": "E9229 Firearm accident NOS",
                "threedigit": "E922",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by explosive material",
            "title": "E923 - Accident caused by explosive material",
            "threedigit": "E923",
            "children": [
              {
                "key": "E9230",
                "title": "E9230 Fireworks accident",
                "threedigit": "E923",
                "isLeaf": true
              },
              {
                "key": "E9231",
                "title": "E9231 Blasting materials accid",
                "threedigit": "E923",
                "isLeaf": true
              },
              {
                "key": "E9232",
                "title": "E9232 Explosive gases accident",
                "threedigit": "E923",
                "isLeaf": true
              },
              {
                "key": "E9238",
                "title": "E9238 Explosives accident NEC",
                "threedigit": "E923",
                "isLeaf": true
              },
              {
                "key": "E9239",
                "title": "E9239 Explosives accident NOS",
                "threedigit": "E923",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by hot substance or object caustic or corrosive material and steam",
            "title": "E924 - Accident caused by hot substance or object caustic or corrosive material and steam",
            "threedigit": "E924",
            "children": [
              {
                "key": "E9240",
                "title": "E9240 Acc-hot liquid & steam",
                "threedigit": "E924",
                "isLeaf": true
              },
              {
                "key": "E9241",
                "title": "E9241 Accid-caustic substance",
                "threedigit": "E924",
                "isLeaf": true
              },
              {
                "key": "E9242",
                "title": "E9242 Acc-hot tap water",
                "threedigit": "E924",
                "isLeaf": true
              },
              {
                "key": "E9248",
                "title": "E9248 Hot substance accid NEC",
                "threedigit": "E924",
                "isLeaf": true
              },
              {
                "key": "E9249",
                "title": "E9249 Hot substance accid NOS",
                "threedigit": "E924",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Accident caused by electric current",
            "title": "E925 - Accident caused by electric current",
            "threedigit": "E925",
            "children": [
              {
                "key": "E9250",
                "title": "E9250 Domestic wiring accident",
                "threedigit": "E925",
                "isLeaf": true
              },
              {
                "key": "E9251",
                "title": "E9251 Electr power generat acc",
                "threedigit": "E925",
                "isLeaf": true
              },
              {
                "key": "E9252",
                "title": "E9252 Indust wiring/machin acc",
                "threedigit": "E925",
                "isLeaf": true
              },
              {
                "key": "E9258",
                "title": "E9258 Electric current acc NEC",
                "threedigit": "E925",
                "isLeaf": true
              },
              {
                "key": "E9259",
                "title": "E9259 Electric current acc NOS",
                "threedigit": "E925",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Exposure to radiation",
            "title": "E926 - Exposure to radiation",
            "threedigit": "E926",
            "children": [
              {
                "key": "E9260",
                "title": "E9260 Radiofreq radiat exposur",
                "threedigit": "E926",
                "isLeaf": true
              },
              {
                "key": "E9261",
                "title": "E9261 Infra-red appl rad exos",
                "threedigit": "E926",
                "isLeaf": true
              },
              {
                "key": "E9262",
                "title": "E9262 Vis/ultraviol lght expos",
                "threedigit": "E926",
                "isLeaf": true
              },
              {
                "key": "E9263",
                "title": "E9263 X-ray/gamma ray exposure",
                "threedigit": "E926",
                "isLeaf": true
              },
              {
                "key": "E9264",
                "title": "E9264 Laser exposure",
                "threedigit": "E926",
                "isLeaf": true
              },
              {
                "key": "E9265",
                "title": "E9265 Radioact isotope exposur",
                "threedigit": "E926",
                "isLeaf": true
              },
              {
                "key": "E9268",
                "title": "E9268 Radiation exposure NEC",
                "threedigit": "E926",
                "isLeaf": true
              },
              {
                "key": "E9269",
                "title": "E9269 Radiation exposure NOS",
                "threedigit": "E926",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Overexertion and strenuous movements",
            "title": "E927 - Overexertion and strenuous movements",
            "threedigit": "E927",
            "children": [
              {
                "key": "E9270",
                "title": "E9270 Overxrt-sudn stren mvmt",
                "threedigit": "E927",
                "isLeaf": true
              },
              {
                "key": "E9271",
                "title": "E9271 Overxrt-prolng stc postn",
                "threedigit": "E927",
                "isLeaf": true
              },
              {
                "key": "E9272",
                "title": "E9272 Excess physical exert",
                "threedigit": "E927",
                "isLeaf": true
              },
              {
                "key": "E9273",
                "title": "E9273 Cumltv trma-repetv motn",
                "threedigit": "E927",
                "isLeaf": true
              },
              {
                "key": "E9274",
                "title": "E9274 Cumltv trma-repetv impct",
                "threedigit": "E927",
                "isLeaf": true
              },
              {
                "key": "E9278",
                "title": "E9278 Overexert reptv mvmt NEC",
                "threedigit": "E927",
                "isLeaf": true
              },
              {
                "key": "E9279",
                "title": "E9279 Overexert reptv mvmt NOS",
                "threedigit": "E927",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified environmental and accidental causes",
            "title": "E928 - Other and unspecified environmental and accidental causes",
            "threedigit": "E928",
            "children": [
              {
                "key": "E9280",
                "title": "E9280 Acc d/t weightless envir",
                "threedigit": "E928",
                "isLeaf": true
              },
              {
                "key": "E9281",
                "title": "E9281 Exposure to noise",
                "threedigit": "E928",
                "isLeaf": true
              },
              {
                "key": "E9282",
                "title": "E9282 Exposure to vibration",
                "threedigit": "E928",
                "isLeaf": true
              },
              {
                "key": "E9283",
                "title": "E9283 Human bite - accidental",
                "threedigit": "E928",
                "isLeaf": true
              },
              {
                "key": "E9284",
                "title": "E9284 Ext constriction-hair",
                "threedigit": "E928",
                "isLeaf": true
              },
              {
                "key": "E9285",
                "title": "E9285 Ext constriction-obj NEC",
                "threedigit": "E928",
                "isLeaf": true
              },
              {
                "key": "E9286",
                "title": "E9286 Envir expose algae/toxin",
                "threedigit": "E928",
                "isLeaf": true
              },
              {
                "key": "E9287",
                "title": "E9287 Accidnt-mech firearm/gun",
                "threedigit": "E928",
                "isLeaf": true
              },
              {
                "key": "E9288",
                "title": "E9288 Accident NEC",
                "threedigit": "E928",
                "isLeaf": true
              },
              {
                "key": "E9289",
                "title": "E9289 Accident NOS",
                "threedigit": "E928",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Late Effects Of Accidental Injury",
        "title": "E929-E929 Late Effects Of Accidental Injury",
        "threedigit": "E929",
        "children": [
          {
            "key": "Late effects of accidental injury",
            "title": "E929 - Late effects of accidental injury",
            "threedigit": "E929",
            "children": [
              {
                "key": "E9290",
                "title": "E9290 Late eff motor vehic acc",
                "threedigit": "E929",
                "isLeaf": true
              },
              {
                "key": "E9291",
                "title": "E9291 Late eff transport acc",
                "threedigit": "E929",
                "isLeaf": true
              },
              {
                "key": "E9292",
                "title": "E9292 Late eff acc poisoning",
                "threedigit": "E929",
                "isLeaf": true
              },
              {
                "key": "E9293",
                "title": "E9293 Late eff accidental fall",
                "threedigit": "E929",
                "isLeaf": true
              },
              {
                "key": "E9294",
                "title": "E9294 Late eff fire acc",
                "threedigit": "E929",
                "isLeaf": true
              },
              {
                "key": "E9295",
                "title": "E9295 Late eff environment acc",
                "threedigit": "E929",
                "isLeaf": true
              },
              {
                "key": "E9298",
                "title": "E9298 Late eff accident NEC",
                "threedigit": "E929",
                "isLeaf": true
              },
              {
                "key": "E9299",
                "title": "E9299 Late eff accident NOS",
                "threedigit": "E929",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Drugs, Medicinal And Biological Substances Causing Adverse Effects In Therapeutic Use",
        "title": "E930-E949 Drugs, Medicinal And Biological Substances Causing Adverse Effects In Therapeutic Use",
        "threedigit": "E930",
        "children": [
          {
            "key": "Antibiotics causing adverse effects in therapeutic use",
            "title": "E930 - Antibiotics causing adverse effects in therapeutic use",
            "threedigit": "E930",
            "children": [
              {
                "key": "E9300",
                "title": "E9300 Adv eff penicillins",
                "threedigit": "E930",
                "isLeaf": true
              },
              {
                "key": "E9301",
                "title": "E9301 Adv eff antifung antbiot",
                "threedigit": "E930",
                "isLeaf": true
              },
              {
                "key": "E9302",
                "title": "E9302 Adv eff chloramphenicol",
                "threedigit": "E930",
                "isLeaf": true
              },
              {
                "key": "E9303",
                "title": "E9303 Adv eff erythromycin",
                "threedigit": "E930",
                "isLeaf": true
              },
              {
                "key": "E9304",
                "title": "E9304 Adv eff tetracycline",
                "threedigit": "E930",
                "isLeaf": true
              },
              {
                "key": "E9305",
                "title": "E9305 Adv eff cephalosporin",
                "threedigit": "E930",
                "isLeaf": true
              },
              {
                "key": "E9306",
                "title": "E9306 Adv eff antmycob antbiot",
                "threedigit": "E930",
                "isLeaf": true
              },
              {
                "key": "E9307",
                "title": "E9307 Adv eff antineop antbiot",
                "threedigit": "E930",
                "isLeaf": true
              },
              {
                "key": "E9308",
                "title": "E9308 Adv eff antibiotics NEC",
                "threedigit": "E930",
                "isLeaf": true
              },
              {
                "key": "E9309",
                "title": "E9309 Adv eff antibiotic NOS",
                "threedigit": "E930",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other anti-infectives causing adverse effects in therapeutic use",
            "title": "E931 - Other anti-infectives causing adverse effects in therapeutic use",
            "threedigit": "E931",
            "children": [
              {
                "key": "E9310",
                "title": "E9310 Adv eff sulfonamides",
                "threedigit": "E931",
                "isLeaf": true
              },
              {
                "key": "E9311",
                "title": "E9311 Adv eff arsenic anti-inf",
                "threedigit": "E931",
                "isLeaf": true
              },
              {
                "key": "E9312",
                "title": "E9312 Adv eff metal anti-inf",
                "threedigit": "E931",
                "isLeaf": true
              },
              {
                "key": "E9313",
                "title": "E9313 Adv eff quinoline",
                "threedigit": "E931",
                "isLeaf": true
              },
              {
                "key": "E9314",
                "title": "E9314 Adv eff antimalarials",
                "threedigit": "E931",
                "isLeaf": true
              },
              {
                "key": "E9315",
                "title": "E9315 Adv eff antprotazoal NEC",
                "threedigit": "E931",
                "isLeaf": true
              },
              {
                "key": "E9316",
                "title": "E9316 Adv eff anthelmintics",
                "threedigit": "E931",
                "isLeaf": true
              },
              {
                "key": "E9317",
                "title": "E9317 Adv eff antiviral drugs",
                "threedigit": "E931",
                "isLeaf": true
              },
              {
                "key": "E9318",
                "title": "E9318 Adv eff antimycobac NEC",
                "threedigit": "E931",
                "isLeaf": true
              },
              {
                "key": "E9319",
                "title": "E9319 Adv eff antinfct NEC/NOS",
                "threedigit": "E931",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hormones and synthetic substitutes causing adverse effects in therapeutic use",
            "title": "E932 - Hormones and synthetic substitutes causing adverse effects in therapeutic use",
            "threedigit": "E932",
            "children": [
              {
                "key": "E9320",
                "title": "E9320 Adv eff corticosteroids",
                "threedigit": "E932",
                "isLeaf": true
              },
              {
                "key": "E9321",
                "title": "E9321 Adv eff androgens",
                "threedigit": "E932",
                "isLeaf": true
              },
              {
                "key": "E9322",
                "title": "E9322 Adv eff ovarian hormones",
                "threedigit": "E932",
                "isLeaf": true
              },
              {
                "key": "E9323",
                "title": "E9323 Adv eff insulin/antidiab",
                "threedigit": "E932",
                "isLeaf": true
              },
              {
                "key": "E9324",
                "title": "E9324 Adv eff ant pituitary",
                "threedigit": "E932",
                "isLeaf": true
              },
              {
                "key": "E9325",
                "title": "E9325 Adv eff post pituitary",
                "threedigit": "E932",
                "isLeaf": true
              },
              {
                "key": "E9326",
                "title": "E9326 Adv eff parathyroid",
                "threedigit": "E932",
                "isLeaf": true
              },
              {
                "key": "E9327",
                "title": "E9327 Adv eff thyroid & deriv",
                "threedigit": "E932",
                "isLeaf": true
              },
              {
                "key": "E9328",
                "title": "E9328 Adv eff antithyroid agnt",
                "threedigit": "E932",
                "isLeaf": true
              },
              {
                "key": "E9329",
                "title": "E9329 Adv eff hormones NEC/NOS",
                "threedigit": "E932",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Primarily systemic agents causing adverse effects in therapeutic use",
            "title": "E933 - Primarily systemic agents causing adverse effects in therapeutic use",
            "threedigit": "E933",
            "children": [
              {
                "key": "E9330",
                "title": "E9330 Adv eff anallrg/antemet",
                "threedigit": "E933",
                "isLeaf": true
              },
              {
                "key": "E9331",
                "title": "E9331 Adv eff antineoplastic",
                "threedigit": "E933",
                "isLeaf": true
              },
              {
                "key": "E9332",
                "title": "E9332 Adv eff acidifying agent",
                "threedigit": "E933",
                "isLeaf": true
              },
              {
                "key": "E9333",
                "title": "E9333 Adv eff alkalizing agent",
                "threedigit": "E933",
                "isLeaf": true
              },
              {
                "key": "E9334",
                "title": "E9334 Adv eff enzymes NEC",
                "threedigit": "E933",
                "isLeaf": true
              },
              {
                "key": "E9335",
                "title": "E9335 Adv eff vitamins NEC",
                "threedigit": "E933",
                "isLeaf": true
              },
              {
                "key": "E9336",
                "title": "E9336 Oral bisphosphonates",
                "threedigit": "E933",
                "isLeaf": true
              },
              {
                "key": "E9337",
                "title": "E9337 IV bisphosphonates",
                "threedigit": "E933",
                "isLeaf": true
              },
              {
                "key": "E9338",
                "title": "E9338 Adv eff systemic agt NEC",
                "threedigit": "E933",
                "isLeaf": true
              },
              {
                "key": "E9339",
                "title": "E9339 Adv eff systemic agt NOS",
                "threedigit": "E933",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Agents primarily affecting blood constituents causing adverse effects in therapeutic use",
            "title": "E934 - Agents primarily affecting blood constituents causing adverse effects in therapeutic use",
            "threedigit": "E934",
            "children": [
              {
                "key": "E9340",
                "title": "E9340 Adv eff iron & compounds",
                "threedigit": "E934",
                "isLeaf": true
              },
              {
                "key": "E9341",
                "title": "E9341 Adv eff liver/antianemic",
                "threedigit": "E934",
                "isLeaf": true
              },
              {
                "key": "E9342",
                "title": "E9342 Adv eff anticoagulants",
                "threedigit": "E934",
                "isLeaf": true
              },
              {
                "key": "E9343",
                "title": "E9343 Adv eff vitamin k",
                "threedigit": "E934",
                "isLeaf": true
              },
              {
                "key": "E9344",
                "title": "E9344 Adv eff fibrinolysis agt",
                "threedigit": "E934",
                "isLeaf": true
              },
              {
                "key": "E9345",
                "title": "E9345 Adv eff coagulants",
                "threedigit": "E934",
                "isLeaf": true
              },
              {
                "key": "E9346",
                "title": "E9346 Adv eff gamma globulin",
                "threedigit": "E934",
                "isLeaf": true
              },
              {
                "key": "E9347",
                "title": "E9347 Adv eff blood products",
                "threedigit": "E934",
                "isLeaf": true
              },
              {
                "key": "E9348",
                "title": "E9348 Adv eff blood agent NEC",
                "threedigit": "E934",
                "isLeaf": true
              },
              {
                "key": "E9349",
                "title": "E9349 Adv eff blood agent NOS",
                "threedigit": "E934",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Analgesics antipyretics and antirheumatics causing adverse effects in therapeutic use",
            "title": "E935 - Analgesics antipyretics and antirheumatics causing adverse effects in therapeutic use",
            "threedigit": "E935",
            "children": [
              {
                "key": "E9350",
                "title": "E9350 Adv eff heroin",
                "threedigit": "E935",
                "isLeaf": true
              },
              {
                "key": "E9351",
                "title": "E9351 Adv eff methadone",
                "threedigit": "E935",
                "isLeaf": true
              },
              {
                "key": "E9352",
                "title": "E9352 Adv eff opiates",
                "threedigit": "E935",
                "isLeaf": true
              },
              {
                "key": "E9353",
                "title": "E9353 Adv eff salicylates",
                "threedigit": "E935",
                "isLeaf": true
              },
              {
                "key": "E9354",
                "title": "E9354 Adv eff arom analgsc NEC",
                "threedigit": "E935",
                "isLeaf": true
              },
              {
                "key": "E9355",
                "title": "E9355 Adv eff pyrazole deriv",
                "threedigit": "E935",
                "isLeaf": true
              },
              {
                "key": "E9356",
                "title": "E9356 Adv eff antirheumatics",
                "threedigit": "E935",
                "isLeaf": true
              },
              {
                "key": "E9357",
                "title": "E9357 Adv eff non-narc analgsc",
                "threedigit": "E935",
                "isLeaf": true
              },
              {
                "key": "E9358",
                "title": "E9358 Adv eff analgesics NEC",
                "threedigit": "E935",
                "isLeaf": true
              },
              {
                "key": "E9359",
                "title": "E9359 Adv eff analgesic NOS",
                "threedigit": "E935",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Anticonvulsants and anti-parkinsonism drugs causing adverse effects in therapeutic use",
            "title": "E936 - Anticonvulsants and anti-parkinsonism drugs causing adverse effects in therapeutic use",
            "threedigit": "E936",
            "children": [
              {
                "key": "E9360",
                "title": "E9360 Adv eff oxazolidin deriv",
                "threedigit": "E936",
                "isLeaf": true
              },
              {
                "key": "E9361",
                "title": "E9361 Adv eff hydantoin deriv",
                "threedigit": "E936",
                "isLeaf": true
              },
              {
                "key": "E9362",
                "title": "E9362 Adv eff succinimides",
                "threedigit": "E936",
                "isLeaf": true
              },
              {
                "key": "E9363",
                "title": "E9363 Adv eff antconvl NEC/NOS",
                "threedigit": "E936",
                "isLeaf": true
              },
              {
                "key": "E9364",
                "title": "E9364 Adv eff anti-parkinson",
                "threedigit": "E936",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Sedatives and hypnotics causing adverse effects in therapeutic use",
            "title": "E937 - Sedatives and hypnotics causing adverse effects in therapeutic use",
            "threedigit": "E937",
            "children": [
              {
                "key": "E9370",
                "title": "E9370 Adv eff barbiturates",
                "threedigit": "E937",
                "isLeaf": true
              },
              {
                "key": "E9371",
                "title": "E9371 Adv eff chloral hydrate",
                "threedigit": "E937",
                "isLeaf": true
              },
              {
                "key": "E9372",
                "title": "E9372 Adv eff paraldehyde",
                "threedigit": "E937",
                "isLeaf": true
              },
              {
                "key": "E9373",
                "title": "E9373 Adv eff bromine compnds",
                "threedigit": "E937",
                "isLeaf": true
              },
              {
                "key": "E9374",
                "title": "E9374 Adv eff methaqualone",
                "threedigit": "E937",
                "isLeaf": true
              },
              {
                "key": "E9375",
                "title": "E9375 Adv eff glutethimide",
                "threedigit": "E937",
                "isLeaf": true
              },
              {
                "key": "E9376",
                "title": "E9376 Adv eff mix sedative",
                "threedigit": "E937",
                "isLeaf": true
              },
              {
                "key": "E9378",
                "title": "E9378 Adv eff sedat/hypnot NEC",
                "threedigit": "E937",
                "isLeaf": true
              },
              {
                "key": "E9379",
                "title": "E9379 Adv eff sedat/hypnot NOS",
                "threedigit": "E937",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other central nervous system depressants and anesthetics causing adverse effects in therapeutic use",
            "title": "E938 - Other central nervous system depressants and anesthetics causing adverse effects in therapeutic use",
            "threedigit": "E938",
            "children": [
              {
                "key": "E9380",
                "title": "E9380 Adv eff cns muscl depres",
                "threedigit": "E938",
                "isLeaf": true
              },
              {
                "key": "E9381",
                "title": "E9381 Adv eff halothane",
                "threedigit": "E938",
                "isLeaf": true
              },
              {
                "key": "E9382",
                "title": "E9382 Adv eff gas anesthet NEC",
                "threedigit": "E938",
                "isLeaf": true
              },
              {
                "key": "E9383",
                "title": "E9383 Adv eff intraven anesth",
                "threedigit": "E938",
                "isLeaf": true
              },
              {
                "key": "E9384",
                "title": "E9384 Adv eff gen anes NEC/NOS",
                "threedigit": "E938",
                "isLeaf": true
              },
              {
                "key": "E9385",
                "title": "E9385 Adv eff topic/infil anes",
                "threedigit": "E938",
                "isLeaf": true
              },
              {
                "key": "E9386",
                "title": "E9386 Adv eff nerve-block anes",
                "threedigit": "E938",
                "isLeaf": true
              },
              {
                "key": "E9387",
                "title": "E9387 Adv eff spinal anesthet",
                "threedigit": "E938",
                "isLeaf": true
              },
              {
                "key": "E9389",
                "title": "E9389 Adv eff loc anes NEC/NOS",
                "threedigit": "E938",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Psychotropic agents causing adverse effects in therapeutic use",
            "title": "E939 - Psychotropic agents causing adverse effects in therapeutic use",
            "threedigit": "E939",
            "children": [
              {
                "key": "E9390",
                "title": "E9390 Adv eff antidepressants",
                "threedigit": "E939",
                "isLeaf": true
              },
              {
                "key": "E9391",
                "title": "E9391 Adv eff phenothiaz tranq",
                "threedigit": "E939",
                "isLeaf": true
              },
              {
                "key": "E9392",
                "title": "E9392 Adv eff butyrophen tranq",
                "threedigit": "E939",
                "isLeaf": true
              },
              {
                "key": "E9393",
                "title": "E9393 Adv eff antipsychotc NEC",
                "threedigit": "E939",
                "isLeaf": true
              },
              {
                "key": "E9394",
                "title": "E9394 Adv eff benzodiaz tranq",
                "threedigit": "E939",
                "isLeaf": true
              },
              {
                "key": "E9395",
                "title": "E9395 Adv eff tranquilizer NEC",
                "threedigit": "E939",
                "isLeaf": true
              },
              {
                "key": "E9396",
                "title": "E9396 Adv eff hallucinogens",
                "threedigit": "E939",
                "isLeaf": true
              },
              {
                "key": "E9397",
                "title": "E9397 Adv eff psychostimulants",
                "threedigit": "E939",
                "isLeaf": true
              },
              {
                "key": "E9398",
                "title": "E9398 Adv eff psychotropic NEC",
                "threedigit": "E939",
                "isLeaf": true
              },
              {
                "key": "E9399",
                "title": "E9399 Adv eff psychotropic NOS",
                "threedigit": "E939",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Central nervous system stimulants causing adverse effects in therapeutic use",
            "title": "E940 - Central nervous system stimulants causing adverse effects in therapeutic use",
            "threedigit": "E940",
            "children": [
              {
                "key": "E9400",
                "title": "E9400 Adv eff analeptics",
                "threedigit": "E940",
                "isLeaf": true
              },
              {
                "key": "E9401",
                "title": "E9401 Adv eff opiat antagonist",
                "threedigit": "E940",
                "isLeaf": true
              },
              {
                "key": "E9408",
                "title": "E9408 Adv eff cns stimulnt NEC",
                "threedigit": "E940",
                "isLeaf": true
              },
              {
                "key": "E9409",
                "title": "E9409 Adv eff cns stimulnt NOS",
                "threedigit": "E940",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Drugs primarily affecting the autonomic nervous system causing adverse effects in therapeutic use",
            "title": "E941 - Drugs primarily affecting the autonomic nervous system causing adverse effects in therapeutic use",
            "threedigit": "E941",
            "children": [
              {
                "key": "E9410",
                "title": "E9410 Adv eff cholinergics",
                "threedigit": "E941",
                "isLeaf": true
              },
              {
                "key": "E9411",
                "title": "E9411 Adv eff parasympatholytc",
                "threedigit": "E941",
                "isLeaf": true
              },
              {
                "key": "E9412",
                "title": "E9412 Adv eff sympathomimetics",
                "threedigit": "E941",
                "isLeaf": true
              },
              {
                "key": "E9413",
                "title": "E9413 Adv eff sympatholytics",
                "threedigit": "E941",
                "isLeaf": true
              },
              {
                "key": "E9419",
                "title": "E9419 Adv eff autonom agnt NOS",
                "threedigit": "E941",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Agents primarily affecting the cardiovascular system causing adverse effects in therapeutic use",
            "title": "E942 - Agents primarily affecting the cardiovascular system causing adverse effects in therapeutic use",
            "threedigit": "E942",
            "children": [
              {
                "key": "E9420",
                "title": "E9420 Adv eff card rhyth regul",
                "threedigit": "E942",
                "isLeaf": true
              },
              {
                "key": "E9421",
                "title": "E9421 Adv eff cardiotonics",
                "threedigit": "E942",
                "isLeaf": true
              },
              {
                "key": "E9422",
                "title": "E9422 Adv eff antilipemics",
                "threedigit": "E942",
                "isLeaf": true
              },
              {
                "key": "E9423",
                "title": "E9423 Adv eff ganglion-block",
                "threedigit": "E942",
                "isLeaf": true
              },
              {
                "key": "E9424",
                "title": "E9424 Adv eff coronary vasodil",
                "threedigit": "E942",
                "isLeaf": true
              },
              {
                "key": "E9425",
                "title": "E9425 Adv eff vasodilators NEC",
                "threedigit": "E942",
                "isLeaf": true
              },
              {
                "key": "E9426",
                "title": "E9426 Adv eff antihyperten agt",
                "threedigit": "E942",
                "isLeaf": true
              },
              {
                "key": "E9427",
                "title": "E9427 Adv eff antivaricose",
                "threedigit": "E942",
                "isLeaf": true
              },
              {
                "key": "E9428",
                "title": "E9428 Adv eff capillary-act",
                "threedigit": "E942",
                "isLeaf": true
              },
              {
                "key": "E9429",
                "title": "E9429 Adv eff cardiovasc NEC",
                "threedigit": "E942",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Agents primarily affecting gastrointestinal system causing adverse effects in therapeutic use",
            "title": "E943 - Agents primarily affecting gastrointestinal system causing adverse effects in therapeutic use",
            "threedigit": "E943",
            "children": [
              {
                "key": "E9430",
                "title": "E9430 Adv eff antacids",
                "threedigit": "E943",
                "isLeaf": true
              },
              {
                "key": "E9431",
                "title": "E9431 Adv eff irrit cathartic",
                "threedigit": "E943",
                "isLeaf": true
              },
              {
                "key": "E9432",
                "title": "E9432 Adv eff emoll cathartics",
                "threedigit": "E943",
                "isLeaf": true
              },
              {
                "key": "E9433",
                "title": "E9433 Adv eff cathartics NEC",
                "threedigit": "E943",
                "isLeaf": true
              },
              {
                "key": "E9434",
                "title": "E9434 Adv eff digestants",
                "threedigit": "E943",
                "isLeaf": true
              },
              {
                "key": "E9435",
                "title": "E9435 Adv eff antidiarrhea agt",
                "threedigit": "E943",
                "isLeaf": true
              },
              {
                "key": "E9436",
                "title": "E9436 Adv eff emetics",
                "threedigit": "E943",
                "isLeaf": true
              },
              {
                "key": "E9438",
                "title": "E9438 Adv eff GI agent NEC",
                "threedigit": "E943",
                "isLeaf": true
              },
              {
                "key": "E9439",
                "title": "E9439 Adv eff GI agent NOS",
                "threedigit": "E943",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Water mineral and uric acid metabolism drugs causing adverse effects in therapeutic use",
            "title": "E944 - Water mineral and uric acid metabolism drugs causing adverse effects in therapeutic use",
            "threedigit": "E944",
            "children": [
              {
                "key": "E9440",
                "title": "E9440 Adv eff mercury diuretic",
                "threedigit": "E944",
                "isLeaf": true
              },
              {
                "key": "E9441",
                "title": "E9441 Adv eff purine diuretics",
                "threedigit": "E944",
                "isLeaf": true
              },
              {
                "key": "E9442",
                "title": "E9442 Adv eff acetazolamide",
                "threedigit": "E944",
                "isLeaf": true
              },
              {
                "key": "E9443",
                "title": "E9443 Adv eff saluretics",
                "threedigit": "E944",
                "isLeaf": true
              },
              {
                "key": "E9444",
                "title": "E9444 Adv eff diuretics NEC",
                "threedigit": "E944",
                "isLeaf": true
              },
              {
                "key": "E9445",
                "title": "E9445 Adv eff electrolyte agnt",
                "threedigit": "E944",
                "isLeaf": true
              },
              {
                "key": "E9446",
                "title": "E9446 Adv eff mineral salt NEC",
                "threedigit": "E944",
                "isLeaf": true
              },
              {
                "key": "E9447",
                "title": "E9447 Adv eff uric acid metab",
                "threedigit": "E944",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Agents primarily acting on the smooth and skeletal muscles and respiratory system causing adverse effects in therapeutic use",
            "title": "E945 - Agents primarily acting on the smooth and skeletal muscles and respiratory system causing adverse effects in therapeutic use",
            "threedigit": "E945",
            "children": [
              {
                "key": "E9450",
                "title": "E9450 Adv eff oxytocic agents",
                "threedigit": "E945",
                "isLeaf": true
              },
              {
                "key": "E9451",
                "title": "E9451 Adv eff smooth musc relx",
                "threedigit": "E945",
                "isLeaf": true
              },
              {
                "key": "E9452",
                "title": "E9452 Adv eff skelet musc relx",
                "threedigit": "E945",
                "isLeaf": true
              },
              {
                "key": "E9453",
                "title": "E9453 Adv eff musc agt NEC/NOS",
                "threedigit": "E945",
                "isLeaf": true
              },
              {
                "key": "E9454",
                "title": "E9454 Adv eff antitussives",
                "threedigit": "E945",
                "isLeaf": true
              },
              {
                "key": "E9455",
                "title": "E9455 Adv eff expectorants",
                "threedigit": "E945",
                "isLeaf": true
              },
              {
                "key": "E9456",
                "title": "E9456 Adv eff anti-common cold",
                "threedigit": "E945",
                "isLeaf": true
              },
              {
                "key": "E9457",
                "title": "E9457 Adv eff antiasthmatics",
                "threedigit": "E945",
                "isLeaf": true
              },
              {
                "key": "E9458",
                "title": "E9458 Adv eff resp drg NEC/NOS",
                "threedigit": "E945",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Agents primarily affecting skin and mucous membrane ophthalmological otorhinolaryngological and dental drugs causing adverse effects in therapeutic use",
            "title": "E946 - Agents primarily affecting skin and mucous membrane ophthalmological otorhinolaryngological and dental drugs causing adverse effects in therapeutic use",
            "threedigit": "E946",
            "children": [
              {
                "key": "E9460",
                "title": "E9460 Adv eff loc anti-infectv",
                "threedigit": "E946",
                "isLeaf": true
              },
              {
                "key": "E9461",
                "title": "E9461 Adv eff antipruritics",
                "threedigit": "E946",
                "isLeaf": true
              },
              {
                "key": "E9462",
                "title": "E9462 Adv eff local astringent",
                "threedigit": "E946",
                "isLeaf": true
              },
              {
                "key": "E9463",
                "title": "E9463 Adv eff emollient/demulc",
                "threedigit": "E946",
                "isLeaf": true
              },
              {
                "key": "E9464",
                "title": "E9464 Adv eff hair/scalp prep",
                "threedigit": "E946",
                "isLeaf": true
              },
              {
                "key": "E9465",
                "title": "E9465 Adv eff eye anti-inf/drg",
                "threedigit": "E946",
                "isLeaf": true
              },
              {
                "key": "E9466",
                "title": "E9466 Adv eff ent anti-inf/drg",
                "threedigit": "E946",
                "isLeaf": true
              },
              {
                "key": "E9467",
                "title": "E9467 Adv eff topic dental drg",
                "threedigit": "E946",
                "isLeaf": true
              },
              {
                "key": "E9468",
                "title": "E9468 Adv eff skin agent NEC",
                "threedigit": "E946",
                "isLeaf": true
              },
              {
                "key": "E9469",
                "title": "E9469 Adv eff skin agent NOS",
                "threedigit": "E946",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other and unspecified drugs and medicinal substances causing adverse effects in therapeutic use",
            "title": "E947 - Other and unspecified drugs and medicinal substances causing adverse effects in therapeutic use",
            "threedigit": "E947",
            "children": [
              {
                "key": "E9470",
                "title": "E9470 Adv eff dietetics",
                "threedigit": "E947",
                "isLeaf": true
              },
              {
                "key": "E9471",
                "title": "E9471 Adv eff lipotropic drugs",
                "threedigit": "E947",
                "isLeaf": true
              },
              {
                "key": "E9472",
                "title": "E9472 Adv eff antidotes NEC",
                "threedigit": "E947",
                "isLeaf": true
              },
              {
                "key": "E9473",
                "title": "E9473 Adv eff alcohol deter",
                "threedigit": "E947",
                "isLeaf": true
              },
              {
                "key": "E9474",
                "title": "E9474 Adv eff pharmaceut excip",
                "threedigit": "E947",
                "isLeaf": true
              },
              {
                "key": "E9478",
                "title": "E9478 Adv eff medicinal NEC",
                "threedigit": "E947",
                "isLeaf": true
              },
              {
                "key": "E9479",
                "title": "E9479 Adv eff medicinal NOS",
                "threedigit": "E947",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Bacterial vaccines causing adverse effects in therapeutic use",
            "title": "E948 - Bacterial vaccines causing adverse effects in therapeutic use",
            "threedigit": "E948",
            "children": [
              {
                "key": "E9480",
                "title": "E9480 Adv eff bcg vaccine",
                "threedigit": "E948",
                "isLeaf": true
              },
              {
                "key": "E9481",
                "title": "E9481 Adv eff typhoid vaccine",
                "threedigit": "E948",
                "isLeaf": true
              },
              {
                "key": "E9482",
                "title": "E9482 Adv eff cholera vaccine",
                "threedigit": "E948",
                "isLeaf": true
              },
              {
                "key": "E9483",
                "title": "E9483 Adv eff plague vaccine",
                "threedigit": "E948",
                "isLeaf": true
              },
              {
                "key": "E9484",
                "title": "E9484 Adv eff tetanus vaccine",
                "threedigit": "E948",
                "isLeaf": true
              },
              {
                "key": "E9485",
                "title": "E9485 Adv eff diphther vaccine",
                "threedigit": "E948",
                "isLeaf": true
              },
              {
                "key": "E9486",
                "title": "E9486 Adv eff pertussis vaccin",
                "threedigit": "E948",
                "isLeaf": true
              },
              {
                "key": "E9488",
                "title": "E9488 Adv eff bact vac NEC/NOS",
                "threedigit": "E948",
                "isLeaf": true
              },
              {
                "key": "E9489",
                "title": "E9489 Adv eff mix bact vaccine",
                "threedigit": "E948",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other vaccines and biological substances causing adverse effects in therapeutic use",
            "title": "E949 - Other vaccines and biological substances causing adverse effects in therapeutic use",
            "threedigit": "E949",
            "children": [
              {
                "key": "E9490",
                "title": "E9490 Adv eff smallpox vaccine",
                "threedigit": "E949",
                "isLeaf": true
              },
              {
                "key": "E9491",
                "title": "E9491 Adv eff rabies vaccine",
                "threedigit": "E949",
                "isLeaf": true
              },
              {
                "key": "E9492",
                "title": "E9492 Adv eff typhus vaccine",
                "threedigit": "E949",
                "isLeaf": true
              },
              {
                "key": "E9493",
                "title": "E9493 Adv eff yellow fever vac",
                "threedigit": "E949",
                "isLeaf": true
              },
              {
                "key": "E9494",
                "title": "E9494 Adv eff measles vaccine",
                "threedigit": "E949",
                "isLeaf": true
              },
              {
                "key": "E9495",
                "title": "E9495 Adv eff polio vaccine",
                "threedigit": "E949",
                "isLeaf": true
              },
              {
                "key": "E9496",
                "title": "E9496 Adv eff viral vacc NEC",
                "threedigit": "E949",
                "isLeaf": true
              },
              {
                "key": "E9497",
                "title": "E9497 Adv eff mixed viral-bact",
                "threedigit": "E949",
                "isLeaf": true
              },
              {
                "key": "E9499",
                "title": "E9499 Adv eff biologic NEC/NOS",
                "threedigit": "E949",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Suicide And Self-Inflicted Injury",
        "title": "E950-E959 Suicide And Self-Inflicted Injury",
        "threedigit": "E950",
        "children": [
          {
            "key": "Suicide and self-inflicted poisoning by solid or liquid substances",
            "title": "E950 - Suicide and self-inflicted poisoning by solid or liquid substances",
            "threedigit": "E950",
            "children": [
              {
                "key": "E9500",
                "title": "E9500 Poison-analgesics",
                "threedigit": "E950",
                "isLeaf": true
              },
              {
                "key": "E9501",
                "title": "E9501 Poison-barbiturates",
                "threedigit": "E950",
                "isLeaf": true
              },
              {
                "key": "E9502",
                "title": "E9502 Poison-sedat/hypnotic",
                "threedigit": "E950",
                "isLeaf": true
              },
              {
                "key": "E9503",
                "title": "E9503 Poison-psychotropic agt",
                "threedigit": "E950",
                "isLeaf": true
              },
              {
                "key": "E9504",
                "title": "E9504 Poison-drug/medicin NEC",
                "threedigit": "E950",
                "isLeaf": true
              },
              {
                "key": "E9505",
                "title": "E9505 Poison-drug/medicin NOS",
                "threedigit": "E950",
                "isLeaf": true
              },
              {
                "key": "E9506",
                "title": "E9506 Poison-agricult agent",
                "threedigit": "E950",
                "isLeaf": true
              },
              {
                "key": "E9507",
                "title": "E9507 Poison-corrosiv/caustic",
                "threedigit": "E950",
                "isLeaf": true
              },
              {
                "key": "E9508",
                "title": "E9508 Poison-arsenic",
                "threedigit": "E950",
                "isLeaf": true
              },
              {
                "key": "E9509",
                "title": "E9509 Poison-solid/liquid NEC",
                "threedigit": "E950",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Suicide and self-inflicted poisoning by gases in domestic use",
            "title": "E951 - Suicide and self-inflicted poisoning by gases in domestic use",
            "threedigit": "E951",
            "children": [
              {
                "key": "E9510",
                "title": "E9510 Poison-piped gas",
                "threedigit": "E951",
                "isLeaf": true
              },
              {
                "key": "E9511",
                "title": "E9511 Poison-gas in container",
                "threedigit": "E951",
                "isLeaf": true
              },
              {
                "key": "E9518",
                "title": "E9518 Poison-utility gas NEC",
                "threedigit": "E951",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Suicide and self-inflicted poisoning by other gases and vapors",
            "title": "E952 - Suicide and self-inflicted poisoning by other gases and vapors",
            "threedigit": "E952",
            "children": [
              {
                "key": "E9520",
                "title": "E9520 Poison-exhaust gas",
                "threedigit": "E952",
                "isLeaf": true
              },
              {
                "key": "E9521",
                "title": "E9521 Poison-co NEC",
                "threedigit": "E952",
                "isLeaf": true
              },
              {
                "key": "E9528",
                "title": "E9528 Poison-gas/vapor NEC",
                "threedigit": "E952",
                "isLeaf": true
              },
              {
                "key": "E9529",
                "title": "E9529 Poison-gas/vapor NOS",
                "threedigit": "E952",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Suicide and self-inflicted injury by hanging strangulation and suffocation",
            "title": "E953 - Suicide and self-inflicted injury by hanging strangulation and suffocation",
            "threedigit": "E953",
            "children": [
              {
                "key": "E9530",
                "title": "E9530 Injury-hanging",
                "threedigit": "E953",
                "isLeaf": true
              },
              {
                "key": "E9531",
                "title": "E9531 Injury-suff w plas bag",
                "threedigit": "E953",
                "isLeaf": true
              },
              {
                "key": "E9538",
                "title": "E9538 Injury-strang/suff NEC",
                "threedigit": "E953",
                "isLeaf": true
              },
              {
                "key": "E9539",
                "title": "E9539 Injury-strang/suff NOS",
                "threedigit": "E953",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Suicide and self-inflicted injury by submersion [drowning]",
            "title": "E954 - Suicide and self-inflicted injury by submersion [drowning]",
            "threedigit": "E954",
            "children": [
              {
                "key": "E954",
                "title": "E954 Injury-submersion",
                "threedigit": "E954",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Suicide and self-inflicted injury by firearms air guns and explosives",
            "title": "E955 - Suicide and self-inflicted injury by firearms air guns and explosives",
            "threedigit": "E955",
            "children": [
              {
                "key": "E9550",
                "title": "E9550 Injury-handgun",
                "threedigit": "E955",
                "isLeaf": true
              },
              {
                "key": "E9551",
                "title": "E9551 Injury-shotgun",
                "threedigit": "E955",
                "isLeaf": true
              },
              {
                "key": "E9552",
                "title": "E9552 Injury-hunting rifle",
                "threedigit": "E955",
                "isLeaf": true
              },
              {
                "key": "E9553",
                "title": "E9553 Injury-military firearm",
                "threedigit": "E955",
                "isLeaf": true
              },
              {
                "key": "E9554",
                "title": "E9554 Injury-firearm NEC",
                "threedigit": "E955",
                "isLeaf": true
              },
              {
                "key": "E9555",
                "title": "E9555 Injury-explosives",
                "threedigit": "E955",
                "isLeaf": true
              },
              {
                "key": "E9556",
                "title": "E9556 Self inflict acc-air gun",
                "threedigit": "E955",
                "isLeaf": true
              },
              {
                "key": "E9557",
                "title": "E9557 Self inj-paintball gun",
                "threedigit": "E955",
                "isLeaf": true
              },
              {
                "key": "E9559",
                "title": "E9559 Injury-firearm/expl NOS",
                "threedigit": "E955",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Suicide and self-inflicted injury by cutting and piercing instrument",
            "title": "E956 - Suicide and self-inflicted injury by cutting and piercing instrument",
            "threedigit": "E956",
            "children": [
              {
                "key": "E956",
                "title": "E956 Injury-cut instrument",
                "threedigit": "E956",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Suicide and self-inflicted injuries by jumping from high place",
            "title": "E957 - Suicide and self-inflicted injuries by jumping from high place",
            "threedigit": "E957",
            "children": [
              {
                "key": "E9570",
                "title": "E9570 Injury-jump fm residence",
                "threedigit": "E957",
                "isLeaf": true
              },
              {
                "key": "E9571",
                "title": "E9571 Injury-jump fm struc NEC",
                "threedigit": "E957",
                "isLeaf": true
              },
              {
                "key": "E9572",
                "title": "E9572 Injury-jump fm natur sit",
                "threedigit": "E957",
                "isLeaf": true
              },
              {
                "key": "E9579",
                "title": "E9579 Injury-jump NEC",
                "threedigit": "E957",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Suicide and self-inflicted injury by other and unspecified means",
            "title": "E958 - Suicide and self-inflicted injury by other and unspecified means",
            "threedigit": "E958",
            "children": [
              {
                "key": "E9580",
                "title": "E9580 Injury-moving object",
                "threedigit": "E958",
                "isLeaf": true
              },
              {
                "key": "E9581",
                "title": "E9581 Injury-burn, fire",
                "threedigit": "E958",
                "isLeaf": true
              },
              {
                "key": "E9582",
                "title": "E9582 Injury-scald",
                "threedigit": "E958",
                "isLeaf": true
              },
              {
                "key": "E9583",
                "title": "E9583 Injury-extreme cold",
                "threedigit": "E958",
                "isLeaf": true
              },
              {
                "key": "E9584",
                "title": "E9584 Injury-electrocution",
                "threedigit": "E958",
                "isLeaf": true
              },
              {
                "key": "E9585",
                "title": "E9585 Injury-motor veh crash",
                "threedigit": "E958",
                "isLeaf": true
              },
              {
                "key": "E9586",
                "title": "E9586 Injury-aircraft crash",
                "threedigit": "E958",
                "isLeaf": true
              },
              {
                "key": "E9587",
                "title": "E9587 Injury-caustic substance",
                "threedigit": "E958",
                "isLeaf": true
              },
              {
                "key": "E9588",
                "title": "E9588 Injury-NEC",
                "threedigit": "E958",
                "isLeaf": true
              },
              {
                "key": "E9589",
                "title": "E9589 Injury-NOS",
                "threedigit": "E958",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of self-inflicted injury",
            "title": "E959 - Late effects of self-inflicted injury",
            "threedigit": "E959",
            "children": [
              {
                "key": "E959",
                "title": "E959 Late eff of self-injury",
                "threedigit": "E959",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Homicide And Injury Purposely Inflicted By Other Persons",
        "title": "E960-E969 Homicide And Injury Purposely Inflicted By Other Persons",
        "threedigit": "E960",
        "children": [
          {
            "key": "Fight brawl rape",
            "title": "E960 - Fight brawl rape",
            "threedigit": "E960",
            "children": [
              {
                "key": "E9600",
                "title": "E9600 Unarmed fight or brawl",
                "threedigit": "E960",
                "isLeaf": true
              },
              {
                "key": "E9601",
                "title": "E9601 Rape",
                "threedigit": "E960",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Assault by corrosive or caustic substance, except poisoning",
            "title": "E961 - Assault by corrosive or caustic substance, except poisoning",
            "threedigit": "E961",
            "children": [
              {
                "key": "E961",
                "title": "E961 Assault-corrosiv/caust",
                "threedigit": "E961",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Assault by poisoning",
            "title": "E962 - Assault by poisoning",
            "threedigit": "E962",
            "children": [
              {
                "key": "E9620",
                "title": "E9620 Assault-pois w medic agt",
                "threedigit": "E962",
                "isLeaf": true
              },
              {
                "key": "E9621",
                "title": "E9621 Assault-pois w solid/liq",
                "threedigit": "E962",
                "isLeaf": true
              },
              {
                "key": "E9622",
                "title": "E9622 Assault-pois w gas/vapor",
                "threedigit": "E962",
                "isLeaf": true
              },
              {
                "key": "E9629",
                "title": "E9629 Assault-poisoning NOS",
                "threedigit": "E962",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Assault by hanging and strangulation",
            "title": "E963 - Assault by hanging and strangulation",
            "threedigit": "E963",
            "children": [
              {
                "key": "E963",
                "title": "E963 Assault-hanging/strangul",
                "threedigit": "E963",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Assault by submersion [drowning]",
            "title": "E964 - Assault by submersion [drowning]",
            "threedigit": "E964",
            "children": [
              {
                "key": "E964",
                "title": "E964 Assault-submersion",
                "threedigit": "E964",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Assault by firearms and explosives",
            "title": "E965 - Assault by firearms and explosives",
            "threedigit": "E965",
            "children": [
              {
                "key": "E9650",
                "title": "E9650 Assault-handgun",
                "threedigit": "E965",
                "isLeaf": true
              },
              {
                "key": "E9651",
                "title": "E9651 Assault-shotgun",
                "threedigit": "E965",
                "isLeaf": true
              },
              {
                "key": "E9652",
                "title": "E9652 Assault-hunting rifle",
                "threedigit": "E965",
                "isLeaf": true
              },
              {
                "key": "E9653",
                "title": "E9653 Assault-military weapon",
                "threedigit": "E965",
                "isLeaf": true
              },
              {
                "key": "E9654",
                "title": "E9654 Assault-firearm NEC",
                "threedigit": "E965",
                "isLeaf": true
              },
              {
                "key": "E9655",
                "title": "E9655 Assault-antiperson bomb",
                "threedigit": "E965",
                "isLeaf": true
              },
              {
                "key": "E9656",
                "title": "E9656 Assault-gasoline bomb",
                "threedigit": "E965",
                "isLeaf": true
              },
              {
                "key": "E9657",
                "title": "E9657 Assault-letter bomb",
                "threedigit": "E965",
                "isLeaf": true
              },
              {
                "key": "E9658",
                "title": "E9658 Assault-explosive NEC",
                "threedigit": "E965",
                "isLeaf": true
              },
              {
                "key": "E9659",
                "title": "E9659 Assault-explosive NOS",
                "threedigit": "E965",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Assault by cutting and piercing instrument",
            "title": "E966 - Assault by cutting and piercing instrument",
            "threedigit": "E966",
            "children": [
              {
                "key": "E966",
                "title": "E966 Assault-cutting instr",
                "threedigit": "E966",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Perpetrator of child and adult abuse",
            "title": "E967 - Perpetrator of child and adult abuse",
            "threedigit": "E967",
            "children": [
              {
                "key": "E9670",
                "title": "E9670 Abuse by fther/stpfth/bf",
                "threedigit": "E967",
                "isLeaf": true
              },
              {
                "key": "E9671",
                "title": "E9671 Child abuse by pers NEC",
                "threedigit": "E967",
                "isLeaf": true
              },
              {
                "key": "E9672",
                "title": "E9672 Abuse by mther/stpmth/gf",
                "threedigit": "E967",
                "isLeaf": true
              },
              {
                "key": "E9673",
                "title": "E9673 Batter by spouse/partner",
                "threedigit": "E967",
                "isLeaf": true
              },
              {
                "key": "E9674",
                "title": "E9674 Battering by child",
                "threedigit": "E967",
                "isLeaf": true
              },
              {
                "key": "E9675",
                "title": "E9675 Battering by sibling",
                "threedigit": "E967",
                "isLeaf": true
              },
              {
                "key": "E9676",
                "title": "E9676 Battering by grandparent",
                "threedigit": "E967",
                "isLeaf": true
              },
              {
                "key": "E9677",
                "title": "E9677 Batter by other relative",
                "threedigit": "E967",
                "isLeaf": true
              },
              {
                "key": "E9678",
                "title": "E9678 Batter by non-relative",
                "threedigit": "E967",
                "isLeaf": true
              },
              {
                "key": "E9679",
                "title": "E9679 Child abuse NOS",
                "threedigit": "E967",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Assault by other and unspecified means",
            "title": "E968 - Assault by other and unspecified means",
            "threedigit": "E968",
            "children": [
              {
                "key": "E9680",
                "title": "E9680 Assault-fire",
                "threedigit": "E968",
                "isLeaf": true
              },
              {
                "key": "E9681",
                "title": "E9681 Asslt-push from hi place",
                "threedigit": "E968",
                "isLeaf": true
              },
              {
                "key": "E9682",
                "title": "E9682 Assault-striking w obj",
                "threedigit": "E968",
                "isLeaf": true
              },
              {
                "key": "E9683",
                "title": "E9683 Assault-hot liquid",
                "threedigit": "E968",
                "isLeaf": true
              },
              {
                "key": "E9684",
                "title": "E9684 Assault-criminal neglect",
                "threedigit": "E968",
                "isLeaf": true
              },
              {
                "key": "E9685",
                "title": "E9685 Asslt-transport vehicle",
                "threedigit": "E968",
                "isLeaf": true
              },
              {
                "key": "E9686",
                "title": "E9686 Assault - air gun",
                "threedigit": "E968",
                "isLeaf": true
              },
              {
                "key": "E9687",
                "title": "E9687 Human bite - assault",
                "threedigit": "E968",
                "isLeaf": true
              },
              {
                "key": "E9688",
                "title": "E9688 Assault NEC",
                "threedigit": "E968",
                "isLeaf": true
              },
              {
                "key": "E9689",
                "title": "E9689 Assault NOS",
                "threedigit": "E968",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of injury purposely inflicted by other person",
            "title": "E969 - Late effects of injury purposely inflicted by other person",
            "threedigit": "E969",
            "children": [
              {
                "key": "E969",
                "title": "E969 Late effect assault",
                "threedigit": "E969",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Legal Intervention",
        "title": "E970-E979 Legal Intervention",
        "threedigit": "E970",
        "children": [
          {
            "key": "Injury due to legal intervention by firearms",
            "title": "E970 - Injury due to legal intervention by firearms",
            "threedigit": "E970",
            "children": [
              {
                "key": "E970",
                "title": "E970 Legal intervent-firearm",
                "threedigit": "E970",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to legal intervention by explosives",
            "title": "E971 - Injury due to legal intervention by explosives",
            "threedigit": "E971",
            "children": [
              {
                "key": "E971",
                "title": "E971 Legal intervent-explosiv",
                "threedigit": "E971",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to legal intervention by gas",
            "title": "E972 - Injury due to legal intervention by gas",
            "threedigit": "E972",
            "children": [
              {
                "key": "E972",
                "title": "E972 Legal intervent-gas",
                "threedigit": "E972",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to legal intervention by blunt object",
            "title": "E973 - Injury due to legal intervention by blunt object",
            "threedigit": "E973",
            "children": [
              {
                "key": "E973",
                "title": "E973 Legal interven-blunt obj",
                "threedigit": "E973",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to legal intervention by cutting and piercing instrument",
            "title": "E974 - Injury due to legal intervention by cutting and piercing instrument",
            "threedigit": "E974",
            "children": [
              {
                "key": "E974",
                "title": "E974 Legal interven-cut instr",
                "threedigit": "E974",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to legal intervention by other specified means",
            "title": "E975 - Injury due to legal intervention by other specified means",
            "threedigit": "E975",
            "children": [
              {
                "key": "E975",
                "title": "E975 Legal intervention NEC",
                "threedigit": "E975",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to legal intervention by unspecified means",
            "title": "E976 - Injury due to legal intervention by unspecified means",
            "threedigit": "E976",
            "children": [
              {
                "key": "E976",
                "title": "E976 Legal intervention NOS",
                "threedigit": "E976",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of injuries due to legal intervention",
            "title": "E977 - Late effects of injuries due to legal intervention",
            "threedigit": "E977",
            "children": [
              {
                "key": "E977",
                "title": "E977 Late eff-legal intervent",
                "threedigit": "E977",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Legal execution",
            "title": "E978 - Legal execution",
            "threedigit": "E978",
            "children": [
              {
                "key": "E978",
                "title": "E978 Legal execution",
                "threedigit": "E978",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Terrorism",
            "title": "E979 - Terrorism",
            "threedigit": "E979",
            "children": [
              {
                "key": "E9790",
                "title": "E9790 Terrorism,marine weapons",
                "threedigit": "E979",
                "isLeaf": true
              },
              {
                "key": "E9791",
                "title": "E9791 Terrorism,dest aircraft",
                "threedigit": "E979",
                "isLeaf": true
              },
              {
                "key": "E9792",
                "title": "E9792 Terrorism,explosions",
                "threedigit": "E979",
                "isLeaf": true
              },
              {
                "key": "E9793",
                "title": "E9793 Terrorism, fires",
                "threedigit": "E979",
                "isLeaf": true
              },
              {
                "key": "E9794",
                "title": "E9794 Terrorism, firearms",
                "threedigit": "E979",
                "isLeaf": true
              },
              {
                "key": "E9795",
                "title": "E9795 Terrorism, nuc weapons",
                "threedigit": "E979",
                "isLeaf": true
              },
              {
                "key": "E9796",
                "title": "E9796 Terrorism, biologicals",
                "threedigit": "E979",
                "isLeaf": true
              },
              {
                "key": "E9797",
                "title": "E9797 Terrorism, chemicals",
                "threedigit": "E979",
                "isLeaf": true
              },
              {
                "key": "E9798",
                "title": "E9798 Terrorism, NEC/NOS",
                "threedigit": "E979",
                "isLeaf": true
              },
              {
                "key": "E9799",
                "title": "E9799 Terrorism, secondary",
                "threedigit": "E979",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Injury Undetermined Whether Accidentally Or Purposely Inflicted",
        "title": "E980-E989 Injury Undetermined Whether Accidentally Or Purposely Inflicted",
        "threedigit": "E980",
        "children": [
          {
            "key": "Poisoning by solid or liquid substances undetermined whether accidentally or purposely inflicted",
            "title": "E980 - Poisoning by solid or liquid substances undetermined whether accidentally or purposely inflicted",
            "threedigit": "E980",
            "children": [
              {
                "key": "E9800",
                "title": "E9800 Undeterm pois-analgesics",
                "threedigit": "E980",
                "isLeaf": true
              },
              {
                "key": "E9801",
                "title": "E9801 Undeterm pois-barbiturat",
                "threedigit": "E980",
                "isLeaf": true
              },
              {
                "key": "E9802",
                "title": "E9802 Undet pois-sed/hypn NEC",
                "threedigit": "E980",
                "isLeaf": true
              },
              {
                "key": "E9803",
                "title": "E9803 Undeterm pois-psychotrop",
                "threedigit": "E980",
                "isLeaf": true
              },
              {
                "key": "E9804",
                "title": "E9804 Undet pois-med agnt NEC",
                "threedigit": "E980",
                "isLeaf": true
              },
              {
                "key": "E9805",
                "title": "E9805 Undet pois-med agnt NOS",
                "threedigit": "E980",
                "isLeaf": true
              },
              {
                "key": "E9806",
                "title": "E9806 Undet pois-corros/caust",
                "threedigit": "E980",
                "isLeaf": true
              },
              {
                "key": "E9807",
                "title": "E9807 Undet pois-agricult agnt",
                "threedigit": "E980",
                "isLeaf": true
              },
              {
                "key": "E9808",
                "title": "E9808 Undeter pois-arsenic",
                "threedigit": "E980",
                "isLeaf": true
              },
              {
                "key": "E9809",
                "title": "E9809 Undeter pois-sol/liq NEC",
                "threedigit": "E980",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by gases in domestic use undetermined whether accidentally or purposely inflicted",
            "title": "E981 - Poisoning by gases in domestic use undetermined whether accidentally or purposely inflicted",
            "threedigit": "E981",
            "children": [
              {
                "key": "E9810",
                "title": "E9810 Undeter pois-piped gas",
                "threedigit": "E981",
                "isLeaf": true
              },
              {
                "key": "E9811",
                "title": "E9811 Undet pois-container gas",
                "threedigit": "E981",
                "isLeaf": true
              },
              {
                "key": "E9818",
                "title": "E9818 Undet pois-util gas NEC",
                "threedigit": "E981",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Poisoning by other gases undetermined whether accidentally or purposely inflicted",
            "title": "E982 - Poisoning by other gases undetermined whether accidentally or purposely inflicted",
            "threedigit": "E982",
            "children": [
              {
                "key": "E9820",
                "title": "E9820 Undeter pois-exhaust gas",
                "threedigit": "E982",
                "isLeaf": true
              },
              {
                "key": "E9821",
                "title": "E9821 Undetermin poison-co NEC",
                "threedigit": "E982",
                "isLeaf": true
              },
              {
                "key": "E9828",
                "title": "E9828 Undet pois-gas/vapor NEC",
                "threedigit": "E982",
                "isLeaf": true
              },
              {
                "key": "E9829",
                "title": "E9829 Undet pois-gas/vapor NOS",
                "threedigit": "E982",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Hanging strangulation or suffocation undetermined whether accidentally or purposely inflicted",
            "title": "E983 - Hanging strangulation or suffocation undetermined whether accidentally or purposely inflicted",
            "threedigit": "E983",
            "children": [
              {
                "key": "E9830",
                "title": "E9830 Undetermin circ-hanging",
                "threedigit": "E983",
                "isLeaf": true
              },
              {
                "key": "E9831",
                "title": "E9831 Undet circ-suf plast bag",
                "threedigit": "E983",
                "isLeaf": true
              },
              {
                "key": "E9838",
                "title": "E9838 Undet circ-suffocate NEC",
                "threedigit": "E983",
                "isLeaf": true
              },
              {
                "key": "E9839",
                "title": "E9839 Undet circ-suffocate NOS",
                "threedigit": "E983",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Submersion (drowning), undetermined whether accidentally or purposely inflicted",
            "title": "E984 - Submersion (drowning), undetermined whether accidentally or purposely inflicted",
            "threedigit": "E984",
            "children": [
              {
                "key": "E984",
                "title": "E984 Undeterm circ-submersion",
                "threedigit": "E984",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury by firearms air guns and explosives undetermined whether accidentally or purposely inflicted",
            "title": "E985 - Injury by firearms air guns and explosives undetermined whether accidentally or purposely inflicted",
            "threedigit": "E985",
            "children": [
              {
                "key": "E9850",
                "title": "E9850 Undetermin circ-handgun",
                "threedigit": "E985",
                "isLeaf": true
              },
              {
                "key": "E9851",
                "title": "E9851 Undetermin circ-shotgun",
                "threedigit": "E985",
                "isLeaf": true
              },
              {
                "key": "E9852",
                "title": "E9852 Undet circ-hunting rifle",
                "threedigit": "E985",
                "isLeaf": true
              },
              {
                "key": "E9853",
                "title": "E9853 Undet circ-military arms",
                "threedigit": "E985",
                "isLeaf": true
              },
              {
                "key": "E9854",
                "title": "E9854 Undeter circ-firearm NEC",
                "threedigit": "E985",
                "isLeaf": true
              },
              {
                "key": "E9855",
                "title": "E9855 Undeterm circ-explosive",
                "threedigit": "E985",
                "isLeaf": true
              },
              {
                "key": "E9856",
                "title": "E9856 Undetrmine accid-air gun",
                "threedigit": "E985",
                "isLeaf": true
              },
              {
                "key": "E9857",
                "title": "E9857 Injury paintball gun NOS",
                "threedigit": "E985",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury by cutting and piercing instruments, undetermined whether accidentally or purposely inflicted",
            "title": "E986 - Injury by cutting and piercing instruments, undetermined whether accidentally or purposely inflicted",
            "threedigit": "E986",
            "children": [
              {
                "key": "E986",
                "title": "E986 Undet circ-cut instrumnt",
                "threedigit": "E986",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Falling from high place undetermined whether accidentally or purposely inflicted",
            "title": "E987 - Falling from high place undetermined whether accidentally or purposely inflicted",
            "threedigit": "E987",
            "children": [
              {
                "key": "E9870",
                "title": "E9870 Undet circ-fall residenc",
                "threedigit": "E987",
                "isLeaf": true
              },
              {
                "key": "E9871",
                "title": "E9871 Undet fall structure NEC",
                "threedigit": "E987",
                "isLeaf": true
              },
              {
                "key": "E9872",
                "title": "E9872 Undet fall natural site",
                "threedigit": "E987",
                "isLeaf": true
              },
              {
                "key": "E9879",
                "title": "E9879 Undet circ-fall site NOS",
                "threedigit": "E987",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury by other and unspecified means undetermined whether accidentally or purposely inflicted",
            "title": "E988 - Injury by other and unspecified means undetermined whether accidentally or purposely inflicted",
            "threedigit": "E988",
            "children": [
              {
                "key": "E9880",
                "title": "E9880 Undeterm circ-moving obj",
                "threedigit": "E988",
                "isLeaf": true
              },
              {
                "key": "E9881",
                "title": "E9881 Undeterm circ-burn, fire",
                "threedigit": "E988",
                "isLeaf": true
              },
              {
                "key": "E9882",
                "title": "E9882 Undeterm circ-scald",
                "threedigit": "E988",
                "isLeaf": true
              },
              {
                "key": "E9883",
                "title": "E9883 Undeterm circ-extrm cold",
                "threedigit": "E988",
                "isLeaf": true
              },
              {
                "key": "E9884",
                "title": "E9884 Undeterm circ-electrocut",
                "threedigit": "E988",
                "isLeaf": true
              },
              {
                "key": "E9885",
                "title": "E9885 Undet circ-mot veh crash",
                "threedigit": "E988",
                "isLeaf": true
              },
              {
                "key": "E9886",
                "title": "E9886 Undet circ-aircrft crash",
                "threedigit": "E988",
                "isLeaf": true
              },
              {
                "key": "E9887",
                "title": "E9887 Undet circ-caustic subst",
                "threedigit": "E988",
                "isLeaf": true
              },
              {
                "key": "E9888",
                "title": "E9888 Undetermin circumst NEC",
                "threedigit": "E988",
                "isLeaf": true
              },
              {
                "key": "E9889",
                "title": "E9889 Undetermin circumst NOS",
                "threedigit": "E988",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effects of injury, undetermined whether accidentally or purposely inflicted",
            "title": "E989 - Late effects of injury, undetermined whether accidentally or purposely inflicted",
            "threedigit": "E989",
            "children": [
              {
                "key": "E989",
                "title": "E989 Late eff inj-undet circ",
                "threedigit": "E989",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Injury Resulting From Operations Of War",
        "title": "E990-E999 Injury Resulting From Operations Of War",
        "threedigit": "E990",
        "children": [
          {
            "key": "Injury due to war operations by fires and conflagrations",
            "title": "E990 - Injury due to war operations by fires and conflagrations",
            "threedigit": "E990",
            "children": [
              {
                "key": "E9900",
                "title": "E9900 War inj:gasoline bomb",
                "threedigit": "E990",
                "isLeaf": true
              },
              {
                "key": "E9901",
                "title": "E9901 War inj:flamethrower",
                "threedigit": "E990",
                "isLeaf": true
              },
              {
                "key": "E9902",
                "title": "E9902 War inj:incndiary bullet",
                "threedigit": "E990",
                "isLeaf": true
              },
              {
                "key": "E9903",
                "title": "E9903 War inj:ind convn weapn",
                "threedigit": "E990",
                "isLeaf": true
              },
              {
                "key": "E9909",
                "title": "E9909 War injury:fire NEC",
                "threedigit": "E990",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to war operations by bullets and fragments",
            "title": "E991 - Injury due to war operations by bullets and fragments",
            "threedigit": "E991",
            "children": [
              {
                "key": "E9910",
                "title": "E9910 War inj:rubber bullet",
                "threedigit": "E991",
                "isLeaf": true
              },
              {
                "key": "E9911",
                "title": "E9911 War injury:pellets",
                "threedigit": "E991",
                "isLeaf": true
              },
              {
                "key": "E9912",
                "title": "E9912 War injury:bullet NEC",
                "threedigit": "E991",
                "isLeaf": true
              },
              {
                "key": "E9913",
                "title": "E9913 War inj:antiperson bomb",
                "threedigit": "E991",
                "isLeaf": true
              },
              {
                "key": "E9914",
                "title": "E9914 War inj:munition fragmnt",
                "threedigit": "E991",
                "isLeaf": true
              },
              {
                "key": "E9915",
                "title": "E9915 War inj:prsn-brn fragmnt",
                "threedigit": "E991",
                "isLeaf": true
              },
              {
                "key": "E9916",
                "title": "E9916 War inj:vehic-borne IED",
                "threedigit": "E991",
                "isLeaf": true
              },
              {
                "key": "E9917",
                "title": "E9917 War inj:fragment IED NEC",
                "threedigit": "E991",
                "isLeaf": true
              },
              {
                "key": "E9918",
                "title": "E9918 War inj:weapon fragments",
                "threedigit": "E991",
                "isLeaf": true
              },
              {
                "key": "E9919",
                "title": "E9919 War inj:fragments NEC",
                "threedigit": "E991",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to war operations by explosion of marine weapons",
            "title": "E992 - Injury due to war operations by explosion of marine weapons",
            "threedigit": "E992",
            "children": [
              {
                "key": "E9920",
                "title": "E9920 War inj:torpedo",
                "threedigit": "E992",
                "isLeaf": true
              },
              {
                "key": "E9921",
                "title": "E9921 War inj:depth charge",
                "threedigit": "E992",
                "isLeaf": true
              },
              {
                "key": "E9922",
                "title": "E9922 War inj:marine mines",
                "threedigit": "E992",
                "isLeaf": true
              },
              {
                "key": "E9923",
                "title": "E9923 War inj:seabase art shel",
                "threedigit": "E992",
                "isLeaf": true
              },
              {
                "key": "E9928",
                "title": "E9928 War inj:marine weapn NEC",
                "threedigit": "E992",
                "isLeaf": true
              },
              {
                "key": "E9929",
                "title": "E9929 War inj:marine weapn NOS",
                "threedigit": "E992",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to war operations by other explosion",
            "title": "E993 - Injury due to war operations by other explosion",
            "threedigit": "E993",
            "children": [
              {
                "key": "E9930",
                "title": "E9930 War inj:aerial bomb",
                "threedigit": "E993",
                "isLeaf": true
              },
              {
                "key": "E9931",
                "title": "E9931 War inj:guided missile",
                "threedigit": "E993",
                "isLeaf": true
              },
              {
                "key": "E9932",
                "title": "E9932 War inj:mortar",
                "threedigit": "E993",
                "isLeaf": true
              },
              {
                "key": "E9933",
                "title": "E9933 War inj:person IED",
                "threedigit": "E993",
                "isLeaf": true
              },
              {
                "key": "E9934",
                "title": "E9934 War inj:vehicle IED",
                "threedigit": "E993",
                "isLeaf": true
              },
              {
                "key": "E9935",
                "title": "E9935 War inj:IED NEC",
                "threedigit": "E993",
                "isLeaf": true
              },
              {
                "key": "E9936",
                "title": "E9936 War inj:acc own munition",
                "threedigit": "E993",
                "isLeaf": true
              },
              {
                "key": "E9937",
                "title": "E9937 War inj:acc disch launch",
                "threedigit": "E993",
                "isLeaf": true
              },
              {
                "key": "E9938",
                "title": "E9938 War inj:explosion NEC",
                "threedigit": "E993",
                "isLeaf": true
              },
              {
                "key": "E9939",
                "title": "E9939 War inj:explosion NOS",
                "threedigit": "E993",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to war operations by destruction of aircraft",
            "title": "E994 - Injury due to war operations by destruction of aircraft",
            "threedigit": "E994",
            "children": [
              {
                "key": "E9940",
                "title": "E9940 War inj:aircrft des-enmy",
                "threedigit": "E994",
                "isLeaf": true
              },
              {
                "key": "E9941",
                "title": "E9941 War inj:aircrft-own expl",
                "threedigit": "E994",
                "isLeaf": true
              },
              {
                "key": "E9942",
                "title": "E9942 War inj:aircrft collisn",
                "threedigit": "E994",
                "isLeaf": true
              },
              {
                "key": "E9943",
                "title": "E9943 War inj:aircraft fire",
                "threedigit": "E994",
                "isLeaf": true
              },
              {
                "key": "E9948",
                "title": "E9948 War inj:aircrft dest NEC",
                "threedigit": "E994",
                "isLeaf": true
              },
              {
                "key": "E9949",
                "title": "E9949 War inj:aircrft dest NOS",
                "threedigit": "E994",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to war operations by other and unspecified forms of conventional warfare",
            "title": "E995 - Injury due to war operations by other and unspecified forms of conventional warfare",
            "threedigit": "E995",
            "children": [
              {
                "key": "E9950",
                "title": "E9950 War inj:hnd-hnd combat",
                "threedigit": "E995",
                "isLeaf": true
              },
              {
                "key": "E9951",
                "title": "E9951 War inj:blunt object",
                "threedigit": "E995",
                "isLeaf": true
              },
              {
                "key": "E9952",
                "title": "E9952 War inj:piercing object",
                "threedigit": "E995",
                "isLeaf": true
              },
              {
                "key": "E9953",
                "title": "E9953 War inj:intn restrct air",
                "threedigit": "E995",
                "isLeaf": true
              },
              {
                "key": "E9954",
                "title": "E9954 War inj:unintentl drown",
                "threedigit": "E995",
                "isLeaf": true
              },
              {
                "key": "E9958",
                "title": "E9958 War inj:con warfare NEC",
                "threedigit": "E995",
                "isLeaf": true
              },
              {
                "key": "E9959",
                "title": "E9959 War inj:con warfare NOS",
                "threedigit": "E995",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to war operations by nuclear weapons",
            "title": "E996 - Injury due to war operations by nuclear weapons",
            "threedigit": "E996",
            "children": [
              {
                "key": "E9960",
                "title": "E9960 War inj:dir nucl weapon",
                "threedigit": "E996",
                "isLeaf": true
              },
              {
                "key": "E9961",
                "title": "E9961 War inj:indir nucl weapn",
                "threedigit": "E996",
                "isLeaf": true
              },
              {
                "key": "E9962",
                "title": "E9962 War inj:therml radiation",
                "threedigit": "E996",
                "isLeaf": true
              },
              {
                "key": "E9963",
                "title": "E9963 War inj:nuclear rad eff",
                "threedigit": "E996",
                "isLeaf": true
              },
              {
                "key": "E9968",
                "title": "E9968 War inj:nucl weapon NEC",
                "threedigit": "E996",
                "isLeaf": true
              },
              {
                "key": "E9969",
                "title": "E9969 War inj:nucl weapon NOS",
                "threedigit": "E996",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to war operations by other forms of unconventional warfare",
            "title": "E997 - Injury due to war operations by other forms of unconventional warfare",
            "threedigit": "E997",
            "children": [
              {
                "key": "E9970",
                "title": "E9970 War injury:lasers",
                "threedigit": "E997",
                "isLeaf": true
              },
              {
                "key": "E9971",
                "title": "E9971 War injury:biol warfare",
                "threedigit": "E997",
                "isLeaf": true
              },
              {
                "key": "E9972",
                "title": "E9972 War injury:gas/fum/chem",
                "threedigit": "E997",
                "isLeaf": true
              },
              {
                "key": "E9973",
                "title": "E9973 War inj:WMD NOS",
                "threedigit": "E997",
                "isLeaf": true
              },
              {
                "key": "E9978",
                "title": "E9978 War inj-unconven war NEC",
                "threedigit": "E997",
                "isLeaf": true
              },
              {
                "key": "E9979",
                "title": "E9979 War inj-unconven war NOS",
                "threedigit": "E997",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Injury due to war operations but occurring after cessation of hostilities",
            "title": "E998 - Injury due to war operations but occurring after cessation of hostilities",
            "threedigit": "E998",
            "children": [
              {
                "key": "E9980",
                "title": "E9980 War inj:expl mine-cease",
                "threedigit": "E998",
                "isLeaf": true
              },
              {
                "key": "E9981",
                "title": "E9981 War inj:expl bomb-cease",
                "threedigit": "E998",
                "isLeaf": true
              },
              {
                "key": "E9988",
                "title": "E9988 War inj:after cease NEC",
                "threedigit": "E998",
                "isLeaf": true
              },
              {
                "key": "E9989",
                "title": "E9989 War inj:after cease NOS",
                "threedigit": "E998",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Late effect of injury due to war operations and terrorism",
            "title": "E999 - Late effect of injury due to war operations and terrorism",
            "threedigit": "E999",
            "children": [
              {
                "key": "E9990",
                "title": "E9990 Late effect, war injury",
                "threedigit": "E999",
                "isLeaf": true
              },
              {
                "key": "E9991",
                "title": "E9991 Late effect, terrorism",
                "threedigit": "E999",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "key": "Supplementary Classification Of Factors Influencing Health Status And Contact With Health Services",
    "title": "V01-V91 Supplementary Classification Of Factors Influencing Health Status And Contact With Health Services",
    "threedigit": "V01",
    "children": [
      {
        "key": "Persons With Potential Health Hazards Related To Communicable Diseases",
        "title": "V01-V09 Persons With Potential Health Hazards Related To Communicable Diseases",
        "threedigit": "V01",
        "children": [
          {
            "key": "Contact with or exposure to communicable diseases",
            "title": "V01 - Contact with or exposure to communicable diseases",
            "threedigit": "V01",
            "children": [
              {
                "key": "V010",
                "title": "V010 Cholera contact",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V011",
                "title": "V011 Tuberculosis contact",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V012",
                "title": "V012 Poliomyelitis contact",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V013",
                "title": "V013 Smallpox contact",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V014",
                "title": "V014 Rubella contact",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V015",
                "title": "V015 Rabies contact",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V016",
                "title": "V016 Venereal dis contact",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V0171",
                "title": "V0171 Varicella contact/exp",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V0179",
                "title": "V0179 Viral dis contact NEC",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V0181",
                "title": "V0181 Contact/exposure-anthrax",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V0182",
                "title": "V0182 Exposure to SARS",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V0183",
                "title": "V0183 E. coli contact/exp",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V0184",
                "title": "V0184 Meningococcus contact",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V0189",
                "title": "V0189 Communic dis contact NEC",
                "threedigit": "V01",
                "isLeaf": true
              },
              {
                "key": "V019",
                "title": "V019 Communic dis contact NOS",
                "threedigit": "V01",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Carrier or suspected carrier of infectious diseases",
            "title": "V02 - Carrier or suspected carrier of infectious diseases",
            "threedigit": "V02",
            "children": [
              {
                "key": "V020",
                "title": "V020 Cholera carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V021",
                "title": "V021 Typhoid carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V022",
                "title": "V022 Amebiasis carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V023",
                "title": "V023 GI pathogen carrier NEC",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V024",
                "title": "V024 Diphtheria carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V0251",
                "title": "V0251 Group b streptoc carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V0252",
                "title": "V0252 Streptococus carrier NEC",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V0253",
                "title": "V0253 Meth susc Staph carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V0254",
                "title": "V0254 Meth resis Staph carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V0259",
                "title": "V0259 Bacteria dis carrier NEC",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V0260",
                "title": "V0260 Viral hep carrier NOS",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V0261",
                "title": "V0261 Hepatitis B carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V0262",
                "title": "V0262 Hepatitis C carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V0269",
                "title": "V0269 Viral hep carrier NEC",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V027",
                "title": "V027 Gonorrhea carrier",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V028",
                "title": "V028 Venereal dis carrier NEC",
                "threedigit": "V02",
                "isLeaf": true
              },
              {
                "key": "V029",
                "title": "V029 Carrier NEC",
                "threedigit": "V02",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Need for prophylactic vaccination and inoculation against bacterial diseases",
            "title": "V03 - Need for prophylactic vaccination and inoculation against bacterial diseases",
            "threedigit": "V03",
            "children": [
              {
                "key": "V030",
                "title": "V030 Vaccin for cholera",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V031",
                "title": "V031 Vacc-typhoid-paratyphoid",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V032",
                "title": "V032 Vaccin for tuberculosis",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V033",
                "title": "V033 Vaccin for plague",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V034",
                "title": "V034 Vaccin for tularemia",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V035",
                "title": "V035 Vaccin for diphtheria",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V036",
                "title": "V036 Vaccin for pertussis",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V037",
                "title": "V037 Tetanus toxoid inoculat",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V0381",
                "title": "V0381 Nd vac hmophlus inflnz b",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V0382",
                "title": "V0382 Nd vac strptcs pneumni b",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V0389",
                "title": "V0389 Nd other specf vacnation",
                "threedigit": "V03",
                "isLeaf": true
              },
              {
                "key": "V039",
                "title": "V039 Vaccin for bact dis NOS",
                "threedigit": "V03",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Need for prophylactic vaccination and inoculation against certain viral diseases",
            "title": "V04 - Need for prophylactic vaccination and inoculation against certain viral diseases",
            "threedigit": "V04",
            "children": [
              {
                "key": "V040",
                "title": "V040 Vaccin for poliomyelitis",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V041",
                "title": "V041 Vaccin for smallpox",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V042",
                "title": "V042 Vaccin for measles",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V043",
                "title": "V043 Vaccin for rubella",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V044",
                "title": "V044 Vaccin for yellow fever",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V045",
                "title": "V045 Vaccin for rabies",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V046",
                "title": "V046 Vaccin for mumps",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V047",
                "title": "V047 Vaccin for common cold",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V0481",
                "title": "V0481 Vaccin for influenza",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V0482",
                "title": "V0482 Vaccination for RSV",
                "threedigit": "V04",
                "isLeaf": true
              },
              {
                "key": "V0489",
                "title": "V0489 Vaccn/inoc viral dis NEC",
                "threedigit": "V04",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Need for other prophylactic vaccination and inoculation against single diseases",
            "title": "V05 - Need for other prophylactic vaccination and inoculation against single diseases",
            "threedigit": "V05",
            "children": [
              {
                "key": "V050",
                "title": "V050 Arbovirus enceph vaccin",
                "threedigit": "V05",
                "isLeaf": true
              },
              {
                "key": "V051",
                "title": "V051 Vacc arboviral dis NEC",
                "threedigit": "V05",
                "isLeaf": true
              },
              {
                "key": "V052",
                "title": "V052 Vaccin for leishmaniasis",
                "threedigit": "V05",
                "isLeaf": true
              },
              {
                "key": "V053",
                "title": "V053 Need prphyl vc vrl hepat",
                "threedigit": "V05",
                "isLeaf": true
              },
              {
                "key": "V054",
                "title": "V054 Need prphyl vc varicella",
                "threedigit": "V05",
                "isLeaf": true
              },
              {
                "key": "V058",
                "title": "V058 Vaccin for disease NEC",
                "threedigit": "V05",
                "isLeaf": true
              },
              {
                "key": "V059",
                "title": "V059 Vaccin for singl dis NOS",
                "threedigit": "V05",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Need for prophylactic vaccination and inoculation against combinations of diseases",
            "title": "V06 - Need for prophylactic vaccination and inoculation against combinations of diseases",
            "threedigit": "V06",
            "children": [
              {
                "key": "V060",
                "title": "V060 Vaccin for cholera + tab",
                "threedigit": "V06",
                "isLeaf": true
              },
              {
                "key": "V061",
                "title": "V061 Vaccination for DTP-DTaP",
                "threedigit": "V06",
                "isLeaf": true
              },
              {
                "key": "V062",
                "title": "V062 Vaccin for dtp + tab",
                "threedigit": "V06",
                "isLeaf": true
              },
              {
                "key": "V063",
                "title": "V063 Vaccin for dtp + polio",
                "threedigit": "V06",
                "isLeaf": true
              },
              {
                "key": "V064",
                "title": "V064 Vac-measle-mumps-rubella",
                "threedigit": "V06",
                "isLeaf": true
              },
              {
                "key": "V065",
                "title": "V065 Vaccination for Td-DT",
                "threedigit": "V06",
                "isLeaf": true
              },
              {
                "key": "V066",
                "title": "V066 Nd vac strp pnumn/inflnz",
                "threedigit": "V06",
                "isLeaf": true
              },
              {
                "key": "V068",
                "title": "V068 Vac-dis combinations NEC",
                "threedigit": "V06",
                "isLeaf": true
              },
              {
                "key": "V069",
                "title": "V069 Vac-dis combinations NOS",
                "threedigit": "V06",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Need for isolation and other prophylactic measures",
            "title": "V07 - Need for isolation and other prophylactic measures",
            "threedigit": "V07",
            "children": [
              {
                "key": "V070",
                "title": "V070 Prophylactic isolation",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V071",
                "title": "V071 Desensitiza to allergens",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V072",
                "title": "V072 Prophylact immunotherapy",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V0731",
                "title": "V0731 Prophylac fluoride admin",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V0739",
                "title": "V0739 Other prophylac chemothr",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V074",
                "title": "V074 Hormone replace postmeno",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V0751",
                "title": "V0751 Use of SERMs",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V0752",
                "title": "V0752 Use aromatase inhibitors",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V0759",
                "title": "V0759 Use oth agnt af estrogen",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V078",
                "title": "V078 Prophyl or tx meas NEC",
                "threedigit": "V07",
                "isLeaf": true
              },
              {
                "key": "V079",
                "title": "V079 Prophyl or tx meas NOS",
                "threedigit": "V07",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Asymptomatic human immunodeficiency virus [HIV] infection status",
            "title": "V08 - Asymptomatic human immunodeficiency virus [HIV] infection status",
            "threedigit": "V08",
            "children": [
              {
                "key": "V08",
                "title": "V08 Asymp hiv infectn status",
                "threedigit": "V08",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Infection with drug-resistant microorganisms",
            "title": "V09 - Infection with drug-resistant microorganisms",
            "threedigit": "V09",
            "children": [
              {
                "key": "V090",
                "title": "V090 Inf mcrg rstn pncllins",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V091",
                "title": "V091 Inf mcrg rstn b-lactam",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V092",
                "title": "V092 Inf mcrg rstn macrolides",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V093",
                "title": "V093 Inf mcrg rstn ttrcycln",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V094",
                "title": "V094 Inf mcrg rstn amnglcsds",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V0950",
                "title": "V0950 Inf mcr rst qn flr nt ml",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V0951",
                "title": "V0951 Inf mcrg rstn qn flrq ml",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V096",
                "title": "V096 Inf mcrg rstn sulfnmides",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V0970",
                "title": "V0970 Inf mcr rst oth ag nt ml",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V0971",
                "title": "V0971 Inf mcrg rstn oth ag mlt",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V0980",
                "title": "V0980 Inf mcr rst ot drg nt ml",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V0981",
                "title": "V0981 Inf mcrg rstn oth drg ml",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V0990",
                "title": "V0990 Infc mcrg drgrst nt mult",
                "threedigit": "V09",
                "isLeaf": true
              },
              {
                "key": "V0991",
                "title": "V0991 Infc mcrg drgrst mult",
                "threedigit": "V09",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Persons With Potential Health Hazards Related To Personal And Family History",
        "title": "V10-V19 Persons With Potential Health Hazards Related To Personal And Family History",
        "threedigit": "V10",
        "children": [
          {
            "key": "Personal history of malignant neoplasm",
            "title": "V10 - Personal history of malignant neoplasm",
            "threedigit": "V10",
            "children": [
              {
                "key": "V1000",
                "title": "V1000 Hx of GI malignancy NOS",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1001",
                "title": "V1001 Hx of tongue malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1002",
                "title": "V1002 Hx-oral/pharynx malg NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1003",
                "title": "V1003 Hx-esophageal malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1004",
                "title": "V1004 Hx of gastric malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1005",
                "title": "V1005 Hx of colonic malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1006",
                "title": "V1006 Hx-rectal & anal malign",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1007",
                "title": "V1007 Hx of liver malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1009",
                "title": "V1009 Hx of GI malignancy NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1011",
                "title": "V1011 Hx-bronchogenic malignan",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1012",
                "title": "V1012 Hx-tracheal malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1020",
                "title": "V1020 Hx-resp org malignan NOS",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1021",
                "title": "V1021 Hx-laryngeal malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1022",
                "title": "V1022 Hx-nose/ear/sinus malig",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1029",
                "title": "V1029 Hx-intrathoracic mal NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V103",
                "title": "V103 Hx of breast malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1040",
                "title": "V1040 Hx-female genit malg NOS",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1041",
                "title": "V1041 Hx-cervical malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1042",
                "title": "V1042 Hx-uterus malignancy NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1043",
                "title": "V1043 Hx of ovarian malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1044",
                "title": "V1044 Hx-female genit malg NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1045",
                "title": "V1045 Hx-male genit malig NOS",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1046",
                "title": "V1046 Hx-prostatic malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1047",
                "title": "V1047 Hx-testicular malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1048",
                "title": "V1048 Hx-epididymis malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1049",
                "title": "V1049 Hx-male genit malig NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1050",
                "title": "V1050 Hx-urinary malignan NOS",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1051",
                "title": "V1051 Hx of bladder malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1052",
                "title": "V1052 Hx of kidney malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1053",
                "title": "V1053 Hx malig renal pelvis",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1059",
                "title": "V1059 Hx-urinary malignan NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1060",
                "title": "V1060 Hx of leukemia NOS",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1061",
                "title": "V1061 Hx of lymphoid leukemia",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1062",
                "title": "V1062 Hx of myeloid leukemia",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1063",
                "title": "V1063 Hx of monocytic leukemia",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1069",
                "title": "V1069 Hx of leukemia NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1071",
                "title": "V1071 Hx-lymphosarcoma",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1072",
                "title": "V1072 Hx-hodgkin's disease",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1079",
                "title": "V1079 Hx-lymphatic malign NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1081",
                "title": "V1081 Hx of bone malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1082",
                "title": "V1082 Hx-malig skin melanoma",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1083",
                "title": "V1083 Hx-skin malignancy NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1084",
                "title": "V1084 Hx of eye malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1085",
                "title": "V1085 Hx of brain malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1086",
                "title": "V1086 Hx-malign nerve syst NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1087",
                "title": "V1087 Hx of thyroid malignancy",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1088",
                "title": "V1088 Hx-endocrine malign NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1089",
                "title": "V1089 Hx of malignancy NEC",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1090",
                "title": "V1090 Hx malig neoplasm NOS",
                "threedigit": "V10",
                "isLeaf": true
              },
              {
                "key": "V1091",
                "title": "V1091 Hx malig neuroendo tumor",
                "threedigit": "V10",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Personal history of mental disorder",
            "title": "V11 - Personal history of mental disorder",
            "threedigit": "V11",
            "children": [
              {
                "key": "V110",
                "title": "V110 Hx of schizophrenia",
                "threedigit": "V11",
                "isLeaf": true
              },
              {
                "key": "V111",
                "title": "V111 Hx of affective disorder",
                "threedigit": "V11",
                "isLeaf": true
              },
              {
                "key": "V112",
                "title": "V112 Hx of neurosis",
                "threedigit": "V11",
                "isLeaf": true
              },
              {
                "key": "V113",
                "title": "V113 Hx of alcoholism",
                "threedigit": "V11",
                "isLeaf": true
              },
              {
                "key": "V114",
                "title": "V114 Hx combat/stress reactn",
                "threedigit": "V11",
                "isLeaf": true
              },
              {
                "key": "V118",
                "title": "V118 Hx-mental disorder NEC",
                "threedigit": "V11",
                "isLeaf": true
              },
              {
                "key": "V119",
                "title": "V119 Hx-mental disorder NOS",
                "threedigit": "V11",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Personal history of certain other diseases",
            "title": "V12 - Personal history of certain other diseases",
            "threedigit": "V12",
            "children": [
              {
                "key": "V1200",
                "title": "V1200 Prsnl hst unsp nfct prst",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1201",
                "title": "V1201 Prsnl hst tuberculosis",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1202",
                "title": "V1202 Prsnl hst poliomyelitis",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1203",
                "title": "V1203 Personal histry malaria",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1204",
                "title": "V1204 Hx Methicln resist Staph",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1209",
                "title": "V1209 Prsnl hst oth nfct parst",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V121",
                "title": "V121 Hx-nutrition deficiency",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1221",
                "title": "V1221 Hx gestational diabetes",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1229",
                "title": "V1229 Hx-endocr/meta/immun dis",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V123",
                "title": "V123 Hx-blood diseases",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1240",
                "title": "V1240 Hx nerv sys/snse org NOS",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1241",
                "title": "V1241 Hx benign neoplasm brain",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1242",
                "title": "V1242 Personl hx infection CNS",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1249",
                "title": "V1249 Hx nerv sys/snse org NEC",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1250",
                "title": "V1250 Hx-circulatory dis NOS",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1251",
                "title": "V1251 Hx-ven thrombosis/embols",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1252",
                "title": "V1252 Hx-thrombophlebitis",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1253",
                "title": "V1253 Hx sudden cardiac arrest",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1254",
                "title": "V1254 Hx TIA/stroke w/o resid",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1255",
                "title": "V1255 Hx pulmonary embolism",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1259",
                "title": "V1259 Hx-circulatory dis NEC",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1260",
                "title": "V1260 Hx resp system dis NOS",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1261",
                "title": "V1261 Prsnl hx recur pneumonia",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1269",
                "title": "V1269 Hx resp system dis NEC",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1270",
                "title": "V1270 Prsnl hst unspc dgstv ds",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1271",
                "title": "V1271 Prsnl hst peptic ulcr ds",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1272",
                "title": "V1272 Prsnl hst colonic polyps",
                "threedigit": "V12",
                "isLeaf": true
              },
              {
                "key": "V1279",
                "title": "V1279 Prsnl hst ot spf dgst ds",
                "threedigit": "V12",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Personal history of other diseases",
            "title": "V13 - Personal history of other diseases",
            "threedigit": "V13",
            "children": [
              {
                "key": "V1300",
                "title": "V1300 Prsnl hst urnr dsrd unsp",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1301",
                "title": "V1301 Prsnl hst urnr dsrd calc",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1302",
                "title": "V1302 Personal history UTI",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1303",
                "title": "V1303 Personl hx nephrotic syn",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1309",
                "title": "V1309 Prsn hst ot spf urn dsrd",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V131",
                "title": "V131 Hx-trophoblastic disease",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1321",
                "title": "V1321 History-pre-term labor",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1322",
                "title": "V1322 Hx of cervical dysplasia",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1323",
                "title": "V1323 Hx vaginal dysplasia",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1324",
                "title": "V1324 Hx vulvar dysplasia",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1329",
                "title": "V1329 Hx-genital/obs dis NEC",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V133",
                "title": "V133 Hx-skin/subcutan tis dis",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V134",
                "title": "V134 Hx of arthritis",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1351",
                "title": "V1351 Hx pathological fracture",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1352",
                "title": "V1352 Hx stress fracture",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1359",
                "title": "V1359 Hx musculoskletl dis NEC",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1361",
                "title": "V1361 Hx-hypospadias",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1362",
                "title": "V1362 Hx-cong malform-gu",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1363",
                "title": "V1363 Hx-cong malform-nervous",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1364",
                "title": "V1364 Hx-cong malform-eye,face",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1365",
                "title": "V1365 Hx-cong malform-heart",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1366",
                "title": "V1366 Hx-cong malform-resp sys",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1367",
                "title": "V1367 Hx-cong malform-digest",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1368",
                "title": "V1368 Hx-cong malform-skin,ms",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1369",
                "title": "V1369 Hx-congenital malfor NEC",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V137",
                "title": "V137 Hx-perinatal problems",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1381",
                "title": "V1381 Hx of anaphylaxis",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V1389",
                "title": "V1389 Hx diseases NEC",
                "threedigit": "V13",
                "isLeaf": true
              },
              {
                "key": "V139",
                "title": "V139 Hx of disease NOS",
                "threedigit": "V13",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Personal history of allergy to medicinal agents",
            "title": "V14 - Personal history of allergy to medicinal agents",
            "threedigit": "V14",
            "children": [
              {
                "key": "V140",
                "title": "V140 Hx-penicillin allergy",
                "threedigit": "V14",
                "isLeaf": true
              },
              {
                "key": "V141",
                "title": "V141 Hx-antibiot allergy NEC",
                "threedigit": "V14",
                "isLeaf": true
              },
              {
                "key": "V142",
                "title": "V142 Hx-sulfonamides allergy",
                "threedigit": "V14",
                "isLeaf": true
              },
              {
                "key": "V143",
                "title": "V143 Hx-anti-infect allergy",
                "threedigit": "V14",
                "isLeaf": true
              },
              {
                "key": "V144",
                "title": "V144 Hx-anesthetic allergy",
                "threedigit": "V14",
                "isLeaf": true
              },
              {
                "key": "V145",
                "title": "V145 Hx-narcotic allergy",
                "threedigit": "V14",
                "isLeaf": true
              },
              {
                "key": "V146",
                "title": "V146 Hx-analgesic allergy",
                "threedigit": "V14",
                "isLeaf": true
              },
              {
                "key": "V147",
                "title": "V147 Hx-vaccine allergy",
                "threedigit": "V14",
                "isLeaf": true
              },
              {
                "key": "V148",
                "title": "V148 Hx-drug allergy NEC",
                "threedigit": "V14",
                "isLeaf": true
              },
              {
                "key": "V149",
                "title": "V149 Hx-drug allergy NOS",
                "threedigit": "V14",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other personal history presenting hazards to health",
            "title": "V15 - Other personal history presenting hazards to health",
            "threedigit": "V15",
            "children": [
              {
                "key": "V1501",
                "title": "V1501 Hx-peanut allergy",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1502",
                "title": "V1502 Hx-milk prod allergy",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1503",
                "title": "V1503 Hx-eggs allergy",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1504",
                "title": "V1504 Hx-seafood allergy",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1505",
                "title": "V1505 Hx-other food allergy",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1506",
                "title": "V1506 Hx-allergy insct/arachnd",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1507",
                "title": "V1507 Hx-latex allergy",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1508",
                "title": "V1508 Hx-radiogrphc dye allrgy",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1509",
                "title": "V1509 Hx-allergy NEC",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V151",
                "title": "V151 Hx-major cardiovasc surg",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1521",
                "title": "V1521 Hx in utero proc in preg",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1522",
                "title": "V1522 Hx in utero proc fetus",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1529",
                "title": "V1529 Hx surgery to organs NEC",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V153",
                "title": "V153 Hx of irradiation",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1541",
                "title": "V1541 Hx of physical abuse",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1542",
                "title": "V1542 Hx of emotional abuse",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1549",
                "title": "V1549 Psychological trauma NEC",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1551",
                "title": "V1551 Hx traumatic fracture",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1552",
                "title": "V1552 Hx traumatc brain injury",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1553",
                "title": "V1553 Hx retained FB, rem",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1559",
                "title": "V1559 Hx injury NEC",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V156",
                "title": "V156 Hx of poisoning",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V157",
                "title": "V157 Hx of contraception",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1580",
                "title": "V1580 Hx failed mod sedation",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1581",
                "title": "V1581 Hx of past noncompliance",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1582",
                "title": "V1582 History of tobacco use",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1583",
                "title": "V1583 Hx underimmunizn status",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1584",
                "title": "V1584 Hx-contct/expos asbestos",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1585",
                "title": "V1585 Hx-cont/exps haz bdy fld",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1586",
                "title": "V1586 Hx-contact/exposure lead",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1587",
                "title": "V1587 Hx of ECMO",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1588",
                "title": "V1588 Personal history of fall",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V1589",
                "title": "V1589 Hx-health hazards NEC",
                "threedigit": "V15",
                "isLeaf": true
              },
              {
                "key": "V159",
                "title": "V159 Hx-health hazard NOS",
                "threedigit": "V15",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Family history of malignant neoplasm",
            "title": "V16 - Family history of malignant neoplasm",
            "threedigit": "V16",
            "children": [
              {
                "key": "V160",
                "title": "V160 Family hx-gi malignancy",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V161",
                "title": "V161 Fm hx-trach/bronchog mal",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V162",
                "title": "V162 Fam hx-intrathoracic mal",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V163",
                "title": "V163 Family hx-breast malig",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V1640",
                "title": "V1640 Fm hx genital malig NOS",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V1641",
                "title": "V1641 Fm hx ovary malignancy",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V1642",
                "title": "V1642 Fm hx prostate malig",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V1643",
                "title": "V1643 Fm hx testis malig",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V1649",
                "title": "V1649 Fm hx genital malig NEC",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V1651",
                "title": "V1651 Family hx-kidney malig",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V1652",
                "title": "V1652 Fam hx-bladder malig",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V1659",
                "title": "V1659 Fam hx-urinry malig NEC",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V166",
                "title": "V166 Family hx-leukemia",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V167",
                "title": "V167 Fam hx-lymph neoplas NEC",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V168",
                "title": "V168 Family hx-malignancy NEC",
                "threedigit": "V16",
                "isLeaf": true
              },
              {
                "key": "V169",
                "title": "V169 Family hx-malignancy NOS",
                "threedigit": "V16",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Family history of certain chronic disabling diseases",
            "title": "V17 - Family history of certain chronic disabling diseases",
            "threedigit": "V17",
            "children": [
              {
                "key": "V170",
                "title": "V170 Fam hx-psychiatric cond",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V171",
                "title": "V171 Family hx-stroke",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V172",
                "title": "V172 Fam hx-neurolog dis NEC",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V173",
                "title": "V173 Fam hx-ischem heart dis",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V1741",
                "title": "V1741 Fam hx sudden card death",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V1749",
                "title": "V1749 Fam hx-cardiovas dis NEC",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V175",
                "title": "V175 Family hx-asthma",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V176",
                "title": "V176 Fam hx-chr resp cond NEC",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V177",
                "title": "V177 Family hx-arthritis",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V1781",
                "title": "V1781 Family hx osteoporosis",
                "threedigit": "V17",
                "isLeaf": true
              },
              {
                "key": "V1789",
                "title": "V1789 Fam hx musculosk dis NEC",
                "threedigit": "V17",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Family history of certain other specific conditions",
            "title": "V18 - Family history of certain other specific conditions",
            "threedigit": "V18",
            "children": [
              {
                "key": "V180",
                "title": "V180 Fam hx-diabetes mellitus",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V1811",
                "title": "V1811 Fam hx MEN syndrome",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V1819",
                "title": "V1819 Fm hx endo/metab dis NEC",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V182",
                "title": "V182 Family hx-anemia",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V183",
                "title": "V183 Fam hx-blood disord NEC",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V184",
                "title": "V184 Fm hx-intellect disablty",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V1851",
                "title": "V1851 Family hx colonic polyps",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V1859",
                "title": "V1859 Fam hx digest disord NEC",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V1861",
                "title": "V1861 Fam hx-polycystic kidney",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V1869",
                "title": "V1869 Fam hx-kidney dis NEC",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V187",
                "title": "V187 Family hx-gu disease NEC",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V188",
                "title": "V188 Fm hx-infect/parasit dis",
                "threedigit": "V18",
                "isLeaf": true
              },
              {
                "key": "V189",
                "title": "V189 Fam hx genet dis carrier",
                "threedigit": "V18",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Family history of other conditions",
            "title": "V19 - Family history of other conditions",
            "threedigit": "V19",
            "children": [
              {
                "key": "V190",
                "title": "V190 Family hx-blindness",
                "threedigit": "V19",
                "isLeaf": true
              },
              {
                "key": "V1911",
                "title": "V1911 Family history glaucoma",
                "threedigit": "V19",
                "isLeaf": true
              },
              {
                "key": "V1919",
                "title": "V1919 Family hx-eye disord NEC",
                "threedigit": "V19",
                "isLeaf": true
              },
              {
                "key": "V192",
                "title": "V192 Family hx-deafness",
                "threedigit": "V19",
                "isLeaf": true
              },
              {
                "key": "V193",
                "title": "V193 Family hx-ear disord NEC",
                "threedigit": "V19",
                "isLeaf": true
              },
              {
                "key": "V194",
                "title": "V194 Family hx-skin condition",
                "threedigit": "V19",
                "isLeaf": true
              },
              {
                "key": "V195",
                "title": "V195 Fam hx-congen anomalies",
                "threedigit": "V19",
                "isLeaf": true
              },
              {
                "key": "V196",
                "title": "V196 Family hx-allergic dis",
                "threedigit": "V19",
                "isLeaf": true
              },
              {
                "key": "V197",
                "title": "V197 Consanguinity",
                "threedigit": "V19",
                "isLeaf": true
              },
              {
                "key": "V198",
                "title": "V198 Family hx-condition NEC",
                "threedigit": "V19",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Persons Encountering Health Services In Circumstances Related To Reproduction And Development",
        "title": "V20-V29 Persons Encountering Health Services In Circumstances Related To Reproduction And Development",
        "threedigit": "V20",
        "children": [
          {
            "key": "Health supervision of infant or child",
            "title": "V20 - Health supervision of infant or child",
            "threedigit": "V20",
            "children": [
              {
                "key": "V200",
                "title": "V200 Foundling health care",
                "threedigit": "V20",
                "isLeaf": true
              },
              {
                "key": "V201",
                "title": "V201 Care of healthy chld NEC",
                "threedigit": "V20",
                "isLeaf": true
              },
              {
                "key": "V202",
                "title": "V202 Routin child health exam",
                "threedigit": "V20",
                "isLeaf": true
              },
              {
                "key": "V2031",
                "title": "V2031 Health supvsn nb <8 days",
                "threedigit": "V20",
                "isLeaf": true
              },
              {
                "key": "V2032",
                "title": "V2032 Health supv nb 8-28 days",
                "threedigit": "V20",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Constitutional states in development",
            "title": "V21 - Constitutional states in development",
            "threedigit": "V21",
            "children": [
              {
                "key": "V210",
                "title": "V210 Rapid childhood growth",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V211",
                "title": "V211 Puberty",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V212",
                "title": "V212 Adolescence growth NEC",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V2130",
                "title": "V2130 Low birthwt status NOS",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V2131",
                "title": "V2131 Low birthwt status <500g",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V2132",
                "title": "V2132 Low birthwt 500-999g",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V2133",
                "title": "V2133 Low birthwt 1000-1499g",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V2134",
                "title": "V2134 Low birthwt 1500-1999g",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V2135",
                "title": "V2135 Low birthwt 2000-2500g",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V218",
                "title": "V218 Constit state in dev NEC",
                "threedigit": "V21",
                "isLeaf": true
              },
              {
                "key": "V219",
                "title": "V219 Constit state in dev NOS",
                "threedigit": "V21",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Normal pregnancy",
            "title": "V22 - Normal pregnancy",
            "threedigit": "V22",
            "children": [
              {
                "key": "V220",
                "title": "V220 Supervis normal 1st preg",
                "threedigit": "V22",
                "isLeaf": true
              },
              {
                "key": "V221",
                "title": "V221 Supervis oth normal preg",
                "threedigit": "V22",
                "isLeaf": true
              },
              {
                "key": "V222",
                "title": "V222 Preg state, incidental",
                "threedigit": "V22",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Supervision of high-risk pregnancy",
            "title": "V23 - Supervision of high-risk pregnancy",
            "threedigit": "V23",
            "children": [
              {
                "key": "V230",
                "title": "V230 Preg w hx of infertility",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V231",
                "title": "V231 Preg w hx-trophoblas dis",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V232",
                "title": "V232 Preg w hx of abortion",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V233",
                "title": "V233 Grand multiparity",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2341",
                "title": "V2341 Preg w hx pre-term labor",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2342",
                "title": "V2342 Preg w hx ectopic preg",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2349",
                "title": "V2349 Preg w poor obs hx NEC",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V235",
                "title": "V235 Preg w poor reproduct hx",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V237",
                "title": "V237 Insufficnt prenatal care",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2381",
                "title": "V2381 Suprv elderly primigrav",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2382",
                "title": "V2382 Suprv elderly multigrav",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2383",
                "title": "V2383 Suprv young primigravida",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2384",
                "title": "V2384 Suprv young multigravida",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2385",
                "title": "V2385 Pregnt-assist repro tech",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2386",
                "title": "V2386 Preg-hx in utro prev prg",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2387",
                "title": "V2387 Preg w incon fetl viabil",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V2389",
                "title": "V2389 Suprv high-risk preg NEC",
                "threedigit": "V23",
                "isLeaf": true
              },
              {
                "key": "V239",
                "title": "V239 Suprv high-risk preg NOS",
                "threedigit": "V23",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Postpartum care and examination",
            "title": "V24 - Postpartum care and examination",
            "threedigit": "V24",
            "children": [
              {
                "key": "V240",
                "title": "V240 Postpart care after del",
                "threedigit": "V24",
                "isLeaf": true
              },
              {
                "key": "V241",
                "title": "V241 Postpart care-lactation",
                "threedigit": "V24",
                "isLeaf": true
              },
              {
                "key": "V242",
                "title": "V242 Rout postpart follow-up",
                "threedigit": "V24",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Encounter for contraceptive management",
            "title": "V25 - Encounter for contraceptive management",
            "threedigit": "V25",
            "children": [
              {
                "key": "V2501",
                "title": "V2501 Prescrip-oral contracept",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2502",
                "title": "V2502 Initiate contracept NEC",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2503",
                "title": "V2503 Contracept mgmt-emergncy",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2504",
                "title": "V2504 Natrl fam pln-avoid preg",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2509",
                "title": "V2509 Contraceptive mangmt NEC",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2511",
                "title": "V2511 Insertion of iud",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2512",
                "title": "V2512 Removal of iud",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2513",
                "title": "V2513 Remove/insert iud",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V252",
                "title": "V252 Sterilization",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V253",
                "title": "V253 Menstrual extraction",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2540",
                "title": "V2540 Contracept surveill NOS",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2541",
                "title": "V2541 Contracept pill surveill",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2542",
                "title": "V2542 Iud surveillance",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2543",
                "title": "V2543 Srvl mplnt sbdrm cntrcep",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V2549",
                "title": "V2549 Contracept surveill NEC",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V255",
                "title": "V255 Nsrt mplnt sbdrm cntrcep",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V258",
                "title": "V258 Contraceptive mangmt NEC",
                "threedigit": "V25",
                "isLeaf": true
              },
              {
                "key": "V259",
                "title": "V259 Contraceptive mangmt NOS",
                "threedigit": "V25",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Procreative management",
            "title": "V26 - Procreative management",
            "threedigit": "V26",
            "children": [
              {
                "key": "V260",
                "title": "V260 Tuboplasty or vasoplasty",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V261",
                "title": "V261 Artificial insemination",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2621",
                "title": "V2621 Fertility testing",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2622",
                "title": "V2622 Sterilzation rev aftcare",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2629",
                "title": "V2629 Investigate & test NEC",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2631",
                "title": "V2631 Fem genetic test dis car",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2632",
                "title": "V2632 Female genetic test NEC",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2633",
                "title": "V2633 Genetic counseling",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2634",
                "title": "V2634 Male genetc test dis car",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2635",
                "title": "V2635 Test male/fem preg loss",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2639",
                "title": "V2639 Male genetic test NEC",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2641",
                "title": "V2641 Natrl family plan counsl",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2642",
                "title": "V2642 Fertlity preserv counsel",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2649",
                "title": "V2649 Procr mgmt cnsl/adv NEC",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2651",
                "title": "V2651 Tubal ligation status",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2652",
                "title": "V2652 Vasectomy status",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2681",
                "title": "V2681 Assist repro fertility",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2682",
                "title": "V2682 Fertility preserv proc",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V2689",
                "title": "V2689 Procreative managemt NEC",
                "threedigit": "V26",
                "isLeaf": true
              },
              {
                "key": "V269",
                "title": "V269 Procreative mangmt NOS",
                "threedigit": "V26",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Outcome of delivery",
            "title": "V27 - Outcome of delivery",
            "threedigit": "V27",
            "children": [
              {
                "key": "V270",
                "title": "V270 Deliver-single liveborn",
                "threedigit": "V27",
                "isLeaf": true
              },
              {
                "key": "V271",
                "title": "V271 Deliver-single stillborn",
                "threedigit": "V27",
                "isLeaf": true
              },
              {
                "key": "V272",
                "title": "V272 Deliver-twins, both live",
                "threedigit": "V27",
                "isLeaf": true
              },
              {
                "key": "V273",
                "title": "V273 Del-twins, 1 nb, 1 sb",
                "threedigit": "V27",
                "isLeaf": true
              },
              {
                "key": "V274",
                "title": "V274 Deliver-twins, both sb",
                "threedigit": "V27",
                "isLeaf": true
              },
              {
                "key": "V275",
                "title": "V275 Del-mult birth, all live",
                "threedigit": "V27",
                "isLeaf": true
              },
              {
                "key": "V276",
                "title": "V276 Del-mult brth, some live",
                "threedigit": "V27",
                "isLeaf": true
              },
              {
                "key": "V277",
                "title": "V277 Del-mult birth, all sb",
                "threedigit": "V27",
                "isLeaf": true
              },
              {
                "key": "V279",
                "title": "V279 Outcome of delivery NOS",
                "threedigit": "V27",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Encounter for antenatal screening of mother",
            "title": "V28 - Encounter for antenatal screening of mother",
            "threedigit": "V28",
            "children": [
              {
                "key": "V280",
                "title": "V280 Screening-chromosom anom",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V281",
                "title": "V281 Screen-alphafetoprotein",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V282",
                "title": "V282 Screen by amniocent NEC",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V283",
                "title": "V283 Scr fetl malfrm-ultrasnd",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V284",
                "title": "V284 Screen-fetal retardation",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V285",
                "title": "V285 Screen-isoimmunization",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V286",
                "title": "V286 Antenatal screen strep b",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V2881",
                "title": "V2881 Scrn fetal anatmc survey",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V2882",
                "title": "V2882 Scrn risk preterm labor",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V2889",
                "title": "V2889 Antenatal screening NEC",
                "threedigit": "V28",
                "isLeaf": true
              },
              {
                "key": "V289",
                "title": "V289 Antenatal screening NOS",
                "threedigit": "V28",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Observation and evaluation of newborns for suspected conditions not found",
            "title": "V29 - Observation and evaluation of newborns for suspected conditions not found",
            "threedigit": "V29",
            "children": [
              {
                "key": "V290",
                "title": "V290 NB obsrv suspct infect",
                "threedigit": "V29",
                "isLeaf": true
              },
              {
                "key": "V291",
                "title": "V291 NB obsrv suspct neurlgcl",
                "threedigit": "V29",
                "isLeaf": true
              },
              {
                "key": "V292",
                "title": "V292 Obsrv NB suspc resp cond",
                "threedigit": "V29",
                "isLeaf": true
              },
              {
                "key": "V293",
                "title": "V293 NB obs genetc/metabl cnd",
                "threedigit": "V29",
                "isLeaf": true
              },
              {
                "key": "V298",
                "title": "V298 NB obsrv oth suspct cond",
                "threedigit": "V29",
                "isLeaf": true
              },
              {
                "key": "V299",
                "title": "V299 NB obsrv unsp suspct cnd",
                "threedigit": "V29",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Liveborn Infants According To Type Of Birth",
        "title": "V30-V39 Liveborn Infants According To Type Of Birth",
        "threedigit": "V30",
        "children": [
          {
            "key": "Single liveborn",
            "title": "V30 - Single liveborn",
            "threedigit": "V30",
            "children": [
              {
                "key": "V3000",
                "title": "V3000 Single lb in-hosp w/o cs",
                "threedigit": "V30",
                "isLeaf": true
              },
              {
                "key": "V3001",
                "title": "V3001 Single lb in-hosp w cs",
                "threedigit": "V30",
                "isLeaf": true
              },
              {
                "key": "V301",
                "title": "V301 Singl livebrn-before adm",
                "threedigit": "V30",
                "isLeaf": true
              },
              {
                "key": "V302",
                "title": "V302 Single liveborn-nonhosp",
                "threedigit": "V30",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Twin birth mate liveborn",
            "title": "V31 - Twin birth mate liveborn",
            "threedigit": "V31",
            "children": [
              {
                "key": "V3100",
                "title": "V3100 Twin-mate lb-hosp w/o cs",
                "threedigit": "V31",
                "isLeaf": true
              },
              {
                "key": "V3101",
                "title": "V3101 Twin-mate lb-in hos w cs",
                "threedigit": "V31",
                "isLeaf": true
              },
              {
                "key": "V311",
                "title": "V311 Twin, mate lb-before adm",
                "threedigit": "V31",
                "isLeaf": true
              },
              {
                "key": "V312",
                "title": "V312 Twin, mate lb-nonhosp",
                "threedigit": "V31",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Twin birth mate stillborn",
            "title": "V32 - Twin birth mate stillborn",
            "threedigit": "V32",
            "children": [
              {
                "key": "V3200",
                "title": "V3200 Twin-mate sb-hosp w/o cs",
                "threedigit": "V32",
                "isLeaf": true
              },
              {
                "key": "V3201",
                "title": "V3201 Twin-mate sb-hosp w cs",
                "threedigit": "V32",
                "isLeaf": true
              },
              {
                "key": "V321",
                "title": "V321 Twin, mate sb-before adm",
                "threedigit": "V32",
                "isLeaf": true
              },
              {
                "key": "V322",
                "title": "V322 Twin, mate sb-nonhosp",
                "threedigit": "V32",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Twin birth unspecified whether mate liveborn or stillborn",
            "title": "V33 - Twin birth unspecified whether mate liveborn or stillborn",
            "threedigit": "V33",
            "children": [
              {
                "key": "V3300",
                "title": "V3300 Twin-NOS-in hosp w/o cs",
                "threedigit": "V33",
                "isLeaf": true
              },
              {
                "key": "V3301",
                "title": "V3301 Twin-NOS-in hosp w cs",
                "threedigit": "V33",
                "isLeaf": true
              },
              {
                "key": "V331",
                "title": "V331 Twin NOS-before admissn",
                "threedigit": "V33",
                "isLeaf": true
              },
              {
                "key": "V332",
                "title": "V332 Twin NOS-nonhosp",
                "threedigit": "V33",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other multiple birth (three or more) mates all liveborn",
            "title": "V34 - Other multiple birth (three or more) mates all liveborn",
            "threedigit": "V34",
            "children": [
              {
                "key": "V3400",
                "title": "V3400 Oth mult lb-hosp w/o cs",
                "threedigit": "V34",
                "isLeaf": true
              },
              {
                "key": "V3401",
                "title": "V3401 Oth mult lb-in hosp w cs",
                "threedigit": "V34",
                "isLeaf": true
              },
              {
                "key": "V341",
                "title": "V341 Oth mult nb-before adm",
                "threedigit": "V34",
                "isLeaf": true
              },
              {
                "key": "V342",
                "title": "V342 Oth multiple nb-nonhosp",
                "threedigit": "V34",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other multiple birth (three or more) mates all stillborn",
            "title": "V35 - Other multiple birth (three or more) mates all stillborn",
            "threedigit": "V35",
            "children": [
              {
                "key": "V3500",
                "title": "V3500 Oth mult sb-hosp w/o cs",
                "threedigit": "V35",
                "isLeaf": true
              },
              {
                "key": "V3501",
                "title": "V3501 Oth mult sb-in hosp w cs",
                "threedigit": "V35",
                "isLeaf": true
              },
              {
                "key": "V351",
                "title": "V351 Oth mult sb-before adm",
                "threedigit": "V35",
                "isLeaf": true
              },
              {
                "key": "V352",
                "title": "V352 Oth multiple sb-nonhosp",
                "threedigit": "V35",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other multiple birth (three or more) mates liveborn and stillborn",
            "title": "V36 - Other multiple birth (three or more) mates liveborn and stillborn",
            "threedigit": "V36",
            "children": [
              {
                "key": "V3600",
                "title": "V3600 Mult lb/sb-in hos w/o cs",
                "threedigit": "V36",
                "isLeaf": true
              },
              {
                "key": "V3601",
                "title": "V3601 Mult lb/sb-in hosp w cs",
                "threedigit": "V36",
                "isLeaf": true
              },
              {
                "key": "V361",
                "title": "V361 Mult nb/sb-before adm",
                "threedigit": "V36",
                "isLeaf": true
              },
              {
                "key": "V362",
                "title": "V362 Multiple nb/sb-nonhosp",
                "threedigit": "V36",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other multiple birth (three or more) unspecified whether mates liveborn or stillborn",
            "title": "V37 - Other multiple birth (three or more) unspecified whether mates liveborn or stillborn",
            "threedigit": "V37",
            "children": [
              {
                "key": "V3700",
                "title": "V3700 Mult brth NOS-hos w/o cs",
                "threedigit": "V37",
                "isLeaf": true
              },
              {
                "key": "V3701",
                "title": "V3701 Mult birth NOS-hosp w cs",
                "threedigit": "V37",
                "isLeaf": true
              },
              {
                "key": "V371",
                "title": "V371 Mult brth NOS-before adm",
                "threedigit": "V37",
                "isLeaf": true
              },
              {
                "key": "V372",
                "title": "V372 Mult birth NOS-nonhosp",
                "threedigit": "V37",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Liveborn unspecified whether single twin or multiple",
            "title": "V39 - Liveborn unspecified whether single twin or multiple",
            "threedigit": "V39",
            "children": [
              {
                "key": "V3900",
                "title": "V3900 Liveborn NOS-hosp w/o cs",
                "threedigit": "V39",
                "isLeaf": true
              },
              {
                "key": "V3901",
                "title": "V3901 Liveborn NOS-hosp w cs",
                "threedigit": "V39",
                "isLeaf": true
              },
              {
                "key": "V391",
                "title": "V391 Liveborn NOS-before adm",
                "threedigit": "V39",
                "isLeaf": true
              },
              {
                "key": "V392",
                "title": "V392 Liveborn NOS-nonhosp",
                "threedigit": "V39",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Persons With A Condition Influencing Their Health Status",
        "title": "V40-V49 Persons With A Condition Influencing Their Health Status",
        "threedigit": "V40",
        "children": [
          {
            "key": "Mental and behavioral problems",
            "title": "V40 - Mental and behavioral problems",
            "threedigit": "V40",
            "children": [
              {
                "key": "V400",
                "title": "V400 Problems with learning",
                "threedigit": "V40",
                "isLeaf": true
              },
              {
                "key": "V401",
                "title": "V401 Prob with communication",
                "threedigit": "V40",
                "isLeaf": true
              },
              {
                "key": "V402",
                "title": "V402 Mental problems NEC",
                "threedigit": "V40",
                "isLeaf": true
              },
              {
                "key": "V4031",
                "title": "V4031 Wandering-dis elsewhere",
                "threedigit": "V40",
                "isLeaf": true
              },
              {
                "key": "V4039",
                "title": "V4039 Oth spc behavior problem",
                "threedigit": "V40",
                "isLeaf": true
              },
              {
                "key": "V409",
                "title": "V409 Mental/behavior prob NOS",
                "threedigit": "V40",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Problems with special senses and other special functions",
            "title": "V41 - Problems with special senses and other special functions",
            "threedigit": "V41",
            "children": [
              {
                "key": "V410",
                "title": "V410 Problems with sight",
                "threedigit": "V41",
                "isLeaf": true
              },
              {
                "key": "V411",
                "title": "V411 Eye problems NEC",
                "threedigit": "V41",
                "isLeaf": true
              },
              {
                "key": "V412",
                "title": "V412 Problems with hearing",
                "threedigit": "V41",
                "isLeaf": true
              },
              {
                "key": "V413",
                "title": "V413 Ear problems NEC",
                "threedigit": "V41",
                "isLeaf": true
              },
              {
                "key": "V414",
                "title": "V414 Voice production problem",
                "threedigit": "V41",
                "isLeaf": true
              },
              {
                "key": "V415",
                "title": "V415 Smell and taste problem",
                "threedigit": "V41",
                "isLeaf": true
              },
              {
                "key": "V416",
                "title": "V416 Problem w swallowing",
                "threedigit": "V41",
                "isLeaf": true
              },
              {
                "key": "V417",
                "title": "V417 Sexual function problem",
                "threedigit": "V41",
                "isLeaf": true
              },
              {
                "key": "V418",
                "title": "V418 Probl w special func NEC",
                "threedigit": "V41",
                "isLeaf": true
              },
              {
                "key": "V419",
                "title": "V419 Probl w special func NOS",
                "threedigit": "V41",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Organ or tissue replaced by transplant",
            "title": "V42 - Organ or tissue replaced by transplant",
            "threedigit": "V42",
            "children": [
              {
                "key": "V420",
                "title": "V420 Kidney transplant status",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V421",
                "title": "V421 Heart transplant status",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V422",
                "title": "V422 Heart valve transplant",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V423",
                "title": "V423 Skin transplant status",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V424",
                "title": "V424 Bone transplant status",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V425",
                "title": "V425 Cornea transplant status",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V426",
                "title": "V426 Lung transplant status",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V427",
                "title": "V427 Liver transplant status",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V4281",
                "title": "V4281 Trnspl status-bne marrow",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V4282",
                "title": "V4282 Trspl sts-perip stm cell",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V4283",
                "title": "V4283 Trnspl status-pancreas",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V4284",
                "title": "V4284 Trnspl status-intestines",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V4289",
                "title": "V4289 Trnspl status organ NEC",
                "threedigit": "V42",
                "isLeaf": true
              },
              {
                "key": "V429",
                "title": "V429 Transplant status NOS",
                "threedigit": "V42",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Organ or tissue replaced by other means",
            "title": "V43 - Organ or tissue replaced by other means",
            "threedigit": "V43",
            "children": [
              {
                "key": "V430",
                "title": "V430 Eye replacement NEC",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V431",
                "title": "V431 Lens replacement NEC",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4321",
                "title": "V4321 Heart assist dev replace",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4322",
                "title": "V4322 Artficial heart replace",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V433",
                "title": "V433 Heart valve replac NEC",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V434",
                "title": "V434 Blood vessel replac NEC",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V435",
                "title": "V435 Bladder replacement NEC",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4360",
                "title": "V4360 Joint replaced unspcf",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4361",
                "title": "V4361 Joint replaced shoulder",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4362",
                "title": "V4362 Joint replaced elbow",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4363",
                "title": "V4363 Joint replaced wrist",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4364",
                "title": "V4364 Joint replaced hip",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4365",
                "title": "V4365 Joint replaced knee",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4366",
                "title": "V4366 Joint replaced ankle",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4369",
                "title": "V4369 Oth spcf joint replaced",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V437",
                "title": "V437 Limb replacement NEC",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4381",
                "title": "V4381 Larynx replacement",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4382",
                "title": "V4382 Breast replacement",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4383",
                "title": "V4383 Artific skin repl status",
                "threedigit": "V43",
                "isLeaf": true
              },
              {
                "key": "V4389",
                "title": "V4389 Organ/tiss replacmnt NEC",
                "threedigit": "V43",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Artificial opening status",
            "title": "V44 - Artificial opening status",
            "threedigit": "V44",
            "children": [
              {
                "key": "V440",
                "title": "V440 Tracheostomy status",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V441",
                "title": "V441 Gastrostomy status",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V442",
                "title": "V442 Ileostomy status",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V443",
                "title": "V443 Colostomy status",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V444",
                "title": "V444 Enterostomy status NEC",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V4450",
                "title": "V4450 Cystostomy status NOS",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V4451",
                "title": "V4451 Cutaneous-vesicos status",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V4452",
                "title": "V4452 Appendico-vesicos status",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V4459",
                "title": "V4459 Cystostomy status NEC",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V446",
                "title": "V446 Urinostomy status NEC",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V447",
                "title": "V447 Artificial vagina status",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V448",
                "title": "V448 Artif open status NEC",
                "threedigit": "V44",
                "isLeaf": true
              },
              {
                "key": "V449",
                "title": "V449 Artif open status NOS",
                "threedigit": "V44",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other postprocedural states",
            "title": "V45 - Other postprocedural states",
            "threedigit": "V45",
            "children": [
              {
                "key": "V4500",
                "title": "V4500 Status cardc dvce unspcf",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4501",
                "title": "V4501 Status cardiac pacemaker",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4502",
                "title": "V4502 Status autm crd dfbrltr",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4509",
                "title": "V4509 Status oth spcf crdc dvc",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4511",
                "title": "V4511 Renal dialysis status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4512",
                "title": "V4512 Noncmplnt w renal dialys",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V452",
                "title": "V452 Ventricular shunt status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V453",
                "title": "V453 Intestinal bypass status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V454",
                "title": "V454 Arthrodesis status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4551",
                "title": "V4551 Prsc ntrutr cntrcptv dvc",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4552",
                "title": "V4552 Prsc sbdrml cntrcp mplnt",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4559",
                "title": "V4559 Prsc other cntrcptv dvc",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4561",
                "title": "V4561 Cataract extract status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4569",
                "title": "V4569 Post-proc st eye/adn NEC",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4571",
                "title": "V4571 Acq absnce breast/nipple",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4572",
                "title": "V4572 Acquire absnce intestine",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4573",
                "title": "V4573 Acquired absence kidney",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4574",
                "title": "V4574 Acq absence urinary trct",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4575",
                "title": "V4575 Acq absence of stomach",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4576",
                "title": "V4576 Acq absence of lung",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4577",
                "title": "V4577 Acq absnce genital organ",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4578",
                "title": "V4578 Acquired absence of eye",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4579",
                "title": "V4579 Acq absence of organ NEC",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4581",
                "title": "V4581 Aortocoronary bypass",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4582",
                "title": "V4582 Status-post ptca",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4583",
                "title": "V4583 Breast impl remov status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4584",
                "title": "V4584 Dental restoratn status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4585",
                "title": "V4585 Insulin pump status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4586",
                "title": "V4586 Bariatric surgery status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4587",
                "title": "V4587 Trnsplnt orgn rem status",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4588",
                "title": "V4588 TPA adm status 24 hr pta",
                "threedigit": "V45",
                "isLeaf": true
              },
              {
                "key": "V4589",
                "title": "V4589 Post-proc states NEC",
                "threedigit": "V45",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other dependence on machines and devices",
            "title": "V46 - Other dependence on machines and devices",
            "threedigit": "V46",
            "children": [
              {
                "key": "V460",
                "title": "V460 Dependence on aspirator",
                "threedigit": "V46",
                "isLeaf": true
              },
              {
                "key": "V4611",
                "title": "V4611 Respirator depend status",
                "threedigit": "V46",
                "isLeaf": true
              },
              {
                "key": "V4612",
                "title": "V4612 Resp depend-powr failure",
                "threedigit": "V46",
                "isLeaf": true
              },
              {
                "key": "V4613",
                "title": "V4613 Weaning from respirator",
                "threedigit": "V46",
                "isLeaf": true
              },
              {
                "key": "V4614",
                "title": "V4614 Mech comp respirator",
                "threedigit": "V46",
                "isLeaf": true
              },
              {
                "key": "V462",
                "title": "V462 Depend-supplement oxygen",
                "threedigit": "V46",
                "isLeaf": true
              },
              {
                "key": "V463",
                "title": "V463 Wheelchair dependence",
                "threedigit": "V46",
                "isLeaf": true
              },
              {
                "key": "V468",
                "title": "V468 Machine dependence NEC",
                "threedigit": "V46",
                "isLeaf": true
              },
              {
                "key": "V469",
                "title": "V469 Machine dependence NOS",
                "threedigit": "V46",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other problems with internal organs",
            "title": "V47 - Other problems with internal organs",
            "threedigit": "V47",
            "children": [
              {
                "key": "V470",
                "title": "V470 Intern organ deficiency",
                "threedigit": "V47",
                "isLeaf": true
              },
              {
                "key": "V471",
                "title": "V471 Mech prob w internal org",
                "threedigit": "V47",
                "isLeaf": true
              },
              {
                "key": "V472",
                "title": "V472 Cardiorespirat probl NEC",
                "threedigit": "V47",
                "isLeaf": true
              },
              {
                "key": "V473",
                "title": "V473 Digestive problems NEC",
                "threedigit": "V47",
                "isLeaf": true
              },
              {
                "key": "V474",
                "title": "V474 Urinary problems NEC",
                "threedigit": "V47",
                "isLeaf": true
              },
              {
                "key": "V475",
                "title": "V475 Genital problems NEC",
                "threedigit": "V47",
                "isLeaf": true
              },
              {
                "key": "V479",
                "title": "V479 Probl w internal org NOS",
                "threedigit": "V47",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Problems with head neck and trunk",
            "title": "V48 - Problems with head neck and trunk",
            "threedigit": "V48",
            "children": [
              {
                "key": "V480",
                "title": "V480 Deficiencies of head",
                "threedigit": "V48",
                "isLeaf": true
              },
              {
                "key": "V481",
                "title": "V481 Deficiencies neck/trunk",
                "threedigit": "V48",
                "isLeaf": true
              },
              {
                "key": "V482",
                "title": "V482 Mechanical prob w head",
                "threedigit": "V48",
                "isLeaf": true
              },
              {
                "key": "V483",
                "title": "V483 Mech prob w neck & trunk",
                "threedigit": "V48",
                "isLeaf": true
              },
              {
                "key": "V484",
                "title": "V484 Sensory problem w head",
                "threedigit": "V48",
                "isLeaf": true
              },
              {
                "key": "V485",
                "title": "V485 Sensor prob w neck/trunk",
                "threedigit": "V48",
                "isLeaf": true
              },
              {
                "key": "V486",
                "title": "V486 Disfigurements of head",
                "threedigit": "V48",
                "isLeaf": true
              },
              {
                "key": "V487",
                "title": "V487 Disfigurement neck/trunk",
                "threedigit": "V48",
                "isLeaf": true
              },
              {
                "key": "V488",
                "title": "V488 Prob-head/neck/trunk NEC",
                "threedigit": "V48",
                "isLeaf": true
              },
              {
                "key": "V489",
                "title": "V489 Prob-head/neck/trunk NOS",
                "threedigit": "V48",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other conditions influencing health status",
            "title": "V49 - Other conditions influencing health status",
            "threedigit": "V49",
            "children": [
              {
                "key": "V490",
                "title": "V490 Deficiencies of limbs",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V491",
                "title": "V491 Mechanical prob w limbs",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V492",
                "title": "V492 Motor problems w limbs",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V493",
                "title": "V493 Sensory problems w limbs",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V494",
                "title": "V494 Disfigurements of limbs",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V495",
                "title": "V495 Limb problems NEC",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4960",
                "title": "V4960 Status amput up lmb NOS",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4961",
                "title": "V4961 Status amput thumb",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4962",
                "title": "V4962 Status amput oth fingers",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4963",
                "title": "V4963 Status amput hand",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4964",
                "title": "V4964 Status amput wrist",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4965",
                "title": "V4965 Status amput below elbow",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4966",
                "title": "V4966 Status amput above elbow",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4967",
                "title": "V4967 Status amput shoulder",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4970",
                "title": "V4970 Status amput lwr lmb NOS",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4971",
                "title": "V4971 Status amput great toe",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4972",
                "title": "V4972 Status amput othr toe(s)",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4973",
                "title": "V4973 Status amput foot",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4974",
                "title": "V4974 Status amput ankle",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4975",
                "title": "V4975 Status amput below knee",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4976",
                "title": "V4976 Status amput above knee",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4977",
                "title": "V4977 Status amput hip",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4981",
                "title": "V4981 Asympt postmeno status",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4982",
                "title": "V4982 Dental sealant status",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4983",
                "title": "V4983 Await organ transplnt st",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4984",
                "title": "V4984 Bed confinement status",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4985",
                "title": "V4985 Dual sensory impairment",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4986",
                "title": "V4986 Do not resusctate status",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4987",
                "title": "V4987 Physical restrain status",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V4989",
                "title": "V4989 Conditn influ health NEC",
                "threedigit": "V49",
                "isLeaf": true
              },
              {
                "key": "V499",
                "title": "V499 Probl influ health NOS",
                "threedigit": "V49",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Persons Encountering Health Services For Specific Procedures And Aftercare",
        "title": "V50-V59 Persons Encountering Health Services For Specific Procedures And Aftercare",
        "threedigit": "V50",
        "children": [
          {
            "key": "Elective surgery for purposes other than remedying health states",
            "title": "V50 - Elective surgery for purposes other than remedying health states",
            "threedigit": "V50",
            "children": [
              {
                "key": "V500",
                "title": "V500 Hair transplant",
                "threedigit": "V50",
                "isLeaf": true
              },
              {
                "key": "V501",
                "title": "V501 Plastic surgery NEC",
                "threedigit": "V50",
                "isLeaf": true
              },
              {
                "key": "V502",
                "title": "V502 Routine circumcision",
                "threedigit": "V50",
                "isLeaf": true
              },
              {
                "key": "V503",
                "title": "V503 Ear piercing",
                "threedigit": "V50",
                "isLeaf": true
              },
              {
                "key": "V5041",
                "title": "V5041 Prphylct orgn rmvl brst",
                "threedigit": "V50",
                "isLeaf": true
              },
              {
                "key": "V5042",
                "title": "V5042 Prphylct orgn rmvl ovary",
                "threedigit": "V50",
                "isLeaf": true
              },
              {
                "key": "V5049",
                "title": "V5049 Prphylct orgn rmvl other",
                "threedigit": "V50",
                "isLeaf": true
              },
              {
                "key": "V508",
                "title": "V508 Elective surgery NEC",
                "threedigit": "V50",
                "isLeaf": true
              },
              {
                "key": "V509",
                "title": "V509 Elective surgery NOS",
                "threedigit": "V50",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Aftercare involving the use of plastic surgery",
            "title": "V51 - Aftercare involving the use of plastic surgery",
            "threedigit": "V51",
            "children": [
              {
                "key": "V510",
                "title": "V510 Brst reconst fol mastect",
                "threedigit": "V51",
                "isLeaf": true
              },
              {
                "key": "V518",
                "title": "V518 Aftercre plastc surg NEC",
                "threedigit": "V51",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fitting and adjustment of prosthetic device",
            "title": "V52 - Fitting and adjustment of prosthetic device",
            "threedigit": "V52",
            "children": [
              {
                "key": "V520",
                "title": "V520 Fitting artificial arm",
                "threedigit": "V52",
                "isLeaf": true
              },
              {
                "key": "V521",
                "title": "V521 Fitting artificial leg",
                "threedigit": "V52",
                "isLeaf": true
              },
              {
                "key": "V522",
                "title": "V522 Fitting artificial eye",
                "threedigit": "V52",
                "isLeaf": true
              },
              {
                "key": "V523",
                "title": "V523 Fitting dental prosthes",
                "threedigit": "V52",
                "isLeaf": true
              },
              {
                "key": "V524",
                "title": "V524 Fit/adj breast pros/impl",
                "threedigit": "V52",
                "isLeaf": true
              },
              {
                "key": "V528",
                "title": "V528 Fitting prosthesis NEC",
                "threedigit": "V52",
                "isLeaf": true
              },
              {
                "key": "V529",
                "title": "V529 Fitting prosthesis NOS",
                "threedigit": "V52",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Fitting and adjustment of other device",
            "title": "V53 - Fitting and adjustment of other device",
            "threedigit": "V53",
            "children": [
              {
                "key": "V5301",
                "title": "V5301 Adj cerebral vent shunt",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5302",
                "title": "V5302 Adjust neuropacemaker",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5309",
                "title": "V5309 Adj nerv syst device NEC",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V531",
                "title": "V531 Fit contact lens/glasses",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V532",
                "title": "V532 Adjustment hearing aid",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5331",
                "title": "V5331 Ftng cardiac pacemaker",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5332",
                "title": "V5332 Ftng autmtc dfibrillator",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5339",
                "title": "V5339 Ftng oth cardiac device",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V534",
                "title": "V534 Fit orthodontic device",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5350",
                "title": "V5350 Fit/adjust intestinl dev",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5351",
                "title": "V5351 Fit/adj gastric lap band",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5359",
                "title": "V5359 Fit/adjust gi app-device",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V536",
                "title": "V536 Fitting urinary devices",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V537",
                "title": "V537 Fit orthopedic devices",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V538",
                "title": "V538 Adjustment of wheelchair",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5390",
                "title": "V5390 Fit/adjust device NOS",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5391",
                "title": "V5391 Fit/adjust insulin pump",
                "threedigit": "V53",
                "isLeaf": true
              },
              {
                "key": "V5399",
                "title": "V5399 Fit/adjust device NEC",
                "threedigit": "V53",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other orthopedic aftercare",
            "title": "V54 - Other orthopedic aftercare",
            "threedigit": "V54",
            "children": [
              {
                "key": "V5401",
                "title": "V5401 Removal int fixation dev",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5402",
                "title": "V5402 Length/adjust growth rod",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5409",
                "title": "V5409 Aftrcre int fixation dev",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5410",
                "title": "V5410 Aftrcre traum fx arm NOS",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5411",
                "title": "V5411 Aftrcare traum fx up arm",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5412",
                "title": "V5412 Aftrcre traum fx low arm",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5413",
                "title": "V5413 Aftrcre traumatic fx hip",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5414",
                "title": "V5414 Aftrcre traum fx leg NOS",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5415",
                "title": "V5415 Aftrcare traum fx up leg",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5416",
                "title": "V5416 Aftrcre traum fx low leg",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5417",
                "title": "V5417 Aftrcre traum fx vertebr",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5419",
                "title": "V5419 Aftrce traum fx bone NEC",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5420",
                "title": "V5420 Aftrcare path fx arm NOS",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5421",
                "title": "V5421 Aftercare path fx up arm",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5422",
                "title": "V5422 Aftrcare path fx low arm",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5423",
                "title": "V5423 Aftercare path fx hip",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5424",
                "title": "V5424 Aftrcare path fx leg NOS",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5425",
                "title": "V5425 Aftrcare path fx up leg",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5426",
                "title": "V5426 Aftrcare path fx low leg",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5427",
                "title": "V5427 Aftrcare path fx vertebr",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5429",
                "title": "V5429 Aftrcre path fx bone NEC",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5481",
                "title": "V5481 Aftercare joint replace",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5482",
                "title": "V5482 Aftcr explantatn jt pros",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V5489",
                "title": "V5489 Orthopedic aftercare NEC",
                "threedigit": "V54",
                "isLeaf": true
              },
              {
                "key": "V549",
                "title": "V549 Orthopedic aftercare NOS",
                "threedigit": "V54",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Attention to artificial openings",
            "title": "V55 - Attention to artificial openings",
            "threedigit": "V55",
            "children": [
              {
                "key": "V550",
                "title": "V550 Atten to tracheostomy",
                "threedigit": "V55",
                "isLeaf": true
              },
              {
                "key": "V551",
                "title": "V551 Atten to gastrostomy",
                "threedigit": "V55",
                "isLeaf": true
              },
              {
                "key": "V552",
                "title": "V552 Atten to ileostomy",
                "threedigit": "V55",
                "isLeaf": true
              },
              {
                "key": "V553",
                "title": "V553 Atten to colostomy",
                "threedigit": "V55",
                "isLeaf": true
              },
              {
                "key": "V554",
                "title": "V554 Atten to enterostomy NEC",
                "threedigit": "V55",
                "isLeaf": true
              },
              {
                "key": "V555",
                "title": "V555 Atten to cystostomy",
                "threedigit": "V55",
                "isLeaf": true
              },
              {
                "key": "V556",
                "title": "V556 Atten to urinostomy NEC",
                "threedigit": "V55",
                "isLeaf": true
              },
              {
                "key": "V557",
                "title": "V557 Atten artificial vagina",
                "threedigit": "V55",
                "isLeaf": true
              },
              {
                "key": "V558",
                "title": "V558 Attn to artif open NEC",
                "threedigit": "V55",
                "isLeaf": true
              },
              {
                "key": "V559",
                "title": "V559 Attn to artif open NOS",
                "threedigit": "V55",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Encounter for dialysis and dialysis catheter care",
            "title": "V56 - Encounter for dialysis and dialysis catheter care",
            "threedigit": "V56",
            "children": [
              {
                "key": "V560",
                "title": "V560 Renal dialysis encounter",
                "threedigit": "V56",
                "isLeaf": true
              },
              {
                "key": "V561",
                "title": "V561 Ft/adj xtrcorp dial cath",
                "threedigit": "V56",
                "isLeaf": true
              },
              {
                "key": "V562",
                "title": "V562 Fit/adj perit dial cath",
                "threedigit": "V56",
                "isLeaf": true
              },
              {
                "key": "V5631",
                "title": "V5631 Hemodialysis testing",
                "threedigit": "V56",
                "isLeaf": true
              },
              {
                "key": "V5632",
                "title": "V5632 Peritoneal dialysis test",
                "threedigit": "V56",
                "isLeaf": true
              },
              {
                "key": "V568",
                "title": "V568 Dialysis encounter, NEC",
                "threedigit": "V56",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Care involving use of rehabilitation procedures",
            "title": "V57 - Care involving use of rehabilitation procedures",
            "threedigit": "V57",
            "children": [
              {
                "key": "V570",
                "title": "V570 Breathing exercises",
                "threedigit": "V57",
                "isLeaf": true
              },
              {
                "key": "V571",
                "title": "V571 Physical therapy NEC",
                "threedigit": "V57",
                "isLeaf": true
              },
              {
                "key": "V5721",
                "title": "V5721 Encntr occupatnal thrpy",
                "threedigit": "V57",
                "isLeaf": true
              },
              {
                "key": "V5722",
                "title": "V5722 Encntr vocational thrpy",
                "threedigit": "V57",
                "isLeaf": true
              },
              {
                "key": "V573",
                "title": "V573 Speech-language therapy",
                "threedigit": "V57",
                "isLeaf": true
              },
              {
                "key": "V574",
                "title": "V574 Orthoptic training",
                "threedigit": "V57",
                "isLeaf": true
              },
              {
                "key": "V5781",
                "title": "V5781 Orthotic training",
                "threedigit": "V57",
                "isLeaf": true
              },
              {
                "key": "V5789",
                "title": "V5789 Rehabilitation proc NEC",
                "threedigit": "V57",
                "isLeaf": true
              },
              {
                "key": "V579",
                "title": "V579 Rehabilitation proc NOS",
                "threedigit": "V57",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Encounter for other and unspecified procedures and aftercare",
            "title": "V58 - Encounter for other and unspecified procedures and aftercare",
            "threedigit": "V58",
            "children": [
              {
                "key": "V580",
                "title": "V580 Radiotherapy encounter",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5811",
                "title": "V5811 Antineoplastic chemo enc",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5812",
                "title": "V5812 Immunotherapy encounter",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V582",
                "title": "V582 Blood transfusion, no dx",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5830",
                "title": "V5830 Attn rem nonsurg dressng",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5831",
                "title": "V5831 Attn rem surg dressing",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5832",
                "title": "V5832 Attn removal of sutures",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5841",
                "title": "V5841 Encntr plnd po wnd clsr",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5842",
                "title": "V5842 Aftercare neoplasm surg",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5843",
                "title": "V5843 Aftrcare inj/trauma surg",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5844",
                "title": "V5844 Aftercare organ transplt",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5849",
                "title": "V5849 Postop oth specfd aftrcr",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V585",
                "title": "V585 Orthodontics aftercare",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5861",
                "title": "V5861 Long-term use anticoagul",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5862",
                "title": "V5862 Long-term use antibiotic",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5863",
                "title": "V5863 Lng use antiplte/thrmbtc",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5864",
                "title": "V5864 Long-term anti-inflamtry",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5865",
                "title": "V5865 Long-term use steroids",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5866",
                "title": "V5866 Long-term use of aspirin",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5867",
                "title": "V5867 Long-term use of insulin",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5868",
                "title": "V5868 Lng term bisphosphonates",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5869",
                "title": "V5869 Long-term use meds NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5871",
                "title": "V5871 Aft surg sense org NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5872",
                "title": "V5872 Aftcre surg nerv sys NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5873",
                "title": "V5873 Aft surg circ syst NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5874",
                "title": "V5874 Aftrcre surg respsys NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5875",
                "title": "V5875 Aft oral cav/dig sys NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5876",
                "title": "V5876 Aftrcre surg GU syst NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5877",
                "title": "V5877 Aft surg skin/subcu NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5878",
                "title": "V5878 Aftrcre surg MS syst NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5881",
                "title": "V5881 Fit/adj vascular cathetr",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5882",
                "title": "V5882 Fit/adj non-vsc cath NEC",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5883",
                "title": "V5883 Therapeutic drug monitor",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V5889",
                "title": "V5889 Other specfied aftercare",
                "threedigit": "V58",
                "isLeaf": true
              },
              {
                "key": "V589",
                "title": "V589 Aftercare NOS",
                "threedigit": "V58",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Donors",
            "title": "V59 - Donors",
            "threedigit": "V59",
            "children": [
              {
                "key": "V5901",
                "title": "V5901 Blood donor-whole blood",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V5902",
                "title": "V5902 Blood donor-stem cells",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V5909",
                "title": "V5909 Blood donor NEC",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V591",
                "title": "V591 Skin donor",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V592",
                "title": "V592 Bone donor",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V593",
                "title": "V593 Bone marrow donor",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V594",
                "title": "V594 Kidney donor",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V595",
                "title": "V595 Cornea donor",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V596",
                "title": "V596 Liver donor",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V5970",
                "title": "V5970 Egg donor NEC",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V5971",
                "title": "V5971 Egg donor age <35 anon",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V5972",
                "title": "V5972 Egg donor age <35 desig",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V5973",
                "title": "V5973 Egg donor age 35+ anon",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V5974",
                "title": "V5974 Egg donor age 35+ desig",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V598",
                "title": "V598 Org or tissue donor NEC",
                "threedigit": "V59",
                "isLeaf": true
              },
              {
                "key": "V599",
                "title": "V599 Org or tissue donor NOS",
                "threedigit": "V59",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Persons Encountering Health Services In Other Circumstances",
        "title": "V60-V69 Persons Encountering Health Services In Other Circumstances",
        "threedigit": "V60",
        "children": [
          {
            "key": "Housing household and economic circumstances",
            "title": "V60 - Housing household and economic circumstances",
            "threedigit": "V60",
            "children": [
              {
                "key": "V600",
                "title": "V600 Lack of housing",
                "threedigit": "V60",
                "isLeaf": true
              },
              {
                "key": "V601",
                "title": "V601 Inadequate housing",
                "threedigit": "V60",
                "isLeaf": true
              },
              {
                "key": "V602",
                "title": "V602 Economic problem",
                "threedigit": "V60",
                "isLeaf": true
              },
              {
                "key": "V603",
                "title": "V603 Person living alone",
                "threedigit": "V60",
                "isLeaf": true
              },
              {
                "key": "V604",
                "title": "V604 No family able to care",
                "threedigit": "V60",
                "isLeaf": true
              },
              {
                "key": "V605",
                "title": "V605 Holiday relief care",
                "threedigit": "V60",
                "isLeaf": true
              },
              {
                "key": "V606",
                "title": "V606 Person in resident inst",
                "threedigit": "V60",
                "isLeaf": true
              },
              {
                "key": "V6081",
                "title": "V6081 Foster care (status)",
                "threedigit": "V60",
                "isLeaf": true
              },
              {
                "key": "V6089",
                "title": "V6089 Housing/econo circum NEC",
                "threedigit": "V60",
                "isLeaf": true
              },
              {
                "key": "V609",
                "title": "V609 Housing/econo circum NOS",
                "threedigit": "V60",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other family circumstances",
            "title": "V61 - Other family circumstances",
            "threedigit": "V61",
            "children": [
              {
                "key": "V6101",
                "title": "V6101 Fmily dsrpt-fam military",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6102",
                "title": "V6102 Fmily dsrpt-ret military",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6103",
                "title": "V6103 Fmily dsrpt- divorce/sep",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6104",
                "title": "V6104 Family dsrpt-estrangmemt",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6105",
                "title": "V6105 Famly dsrpt-chld custody",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6106",
                "title": "V6106 Family dsrpt-foster care",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6107",
                "title": "V6107 Family dsrpt-death membr",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6108",
                "title": "V6108 Fmly dsrp-fam absnce NEC",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6109",
                "title": "V6109 Family disruption NEC",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6110",
                "title": "V6110 Consl partner prob",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6111",
                "title": "V6111 Cnsl victm partner abuse",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6112",
                "title": "V6112 Cnsl perp partner abuse",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6120",
                "title": "V6120 Cnsl prnt-chld prob NOS",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6121",
                "title": "V6121 Cnsl victim child abuse",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6122",
                "title": "V6122 Cnsl perp parent chld ab",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6123",
                "title": "V6123 Cnsl prnt-biol chld prob",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6124",
                "title": "V6124 Cnsl prnt-adpt chld prob",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6125",
                "title": "V6125 Cnsl prnt-fstr chld prob",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6129",
                "title": "V6129 Oth parent-child problem",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V613",
                "title": "V613 Problem w aged parent",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6141",
                "title": "V6141 Alcoholism in family",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6142",
                "title": "V6142 Substance abuse-family",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V6149",
                "title": "V6149 Family health probl NEC",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V615",
                "title": "V615 Multiparity",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V616",
                "title": "V616 Illegitimate pregnancy",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V617",
                "title": "V617 Unwanted pregnancy NEC",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V618",
                "title": "V618 Family circumstances NEC",
                "threedigit": "V61",
                "isLeaf": true
              },
              {
                "key": "V619",
                "title": "V619 Family circumstance NOS",
                "threedigit": "V61",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other psychosocial circumstances",
            "title": "V62 - Other psychosocial circumstances",
            "threedigit": "V62",
            "children": [
              {
                "key": "V620",
                "title": "V620 Unemployment",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V621",
                "title": "V621 Adverse eff-work environ",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V6221",
                "title": "V6221 Hx military deployment",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V6222",
                "title": "V6222 Hx retrn military deploy",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V6229",
                "title": "V6229 Occupationl circumst NEC",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V623",
                "title": "V623 Educational circumstance",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V624",
                "title": "V624 Social maladjustment",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V625",
                "title": "V625 Legal circumstances",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V626",
                "title": "V626 Refusal of treatment",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V6281",
                "title": "V6281 Interpersonal probl NEC",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V6282",
                "title": "V6282 Bereavement, uncomplicat",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V6283",
                "title": "V6283 Cnsl perp phys/sex abuse",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V6284",
                "title": "V6284 Suicidal ideation",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V6285",
                "title": "V6285 Homicidal ideation",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V6289",
                "title": "V6289 Psychological stress NEC",
                "threedigit": "V62",
                "isLeaf": true
              },
              {
                "key": "V629",
                "title": "V629 Psychosocial circum NOS",
                "threedigit": "V62",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Unavailability of other medical facilities for care",
            "title": "V63 - Unavailability of other medical facilities for care",
            "threedigit": "V63",
            "children": [
              {
                "key": "V630",
                "title": "V630 Home remote from hospitl",
                "threedigit": "V63",
                "isLeaf": true
              },
              {
                "key": "V631",
                "title": "V631 No medical serv in home",
                "threedigit": "V63",
                "isLeaf": true
              },
              {
                "key": "V632",
                "title": "V632 Wait adm to oth facility",
                "threedigit": "V63",
                "isLeaf": true
              },
              {
                "key": "V638",
                "title": "V638 No med facilities NEC",
                "threedigit": "V63",
                "isLeaf": true
              },
              {
                "key": "V639",
                "title": "V639 No med facilities NOS",
                "threedigit": "V63",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Persons encountering health services for specific procedures not carried out",
            "title": "V64 - Persons encountering health services for specific procedures not carried out",
            "threedigit": "V64",
            "children": [
              {
                "key": "V6400",
                "title": "V6400 No vaccination NOS",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6401",
                "title": "V6401 No vaccin-acute illness",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6402",
                "title": "V6402 No vaccin-chronc illness",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6403",
                "title": "V6403 No vaccin-immune comp",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6404",
                "title": "V6404 No vaccin-allergy to vac",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6405",
                "title": "V6405 No vaccin-caregiv refuse",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6406",
                "title": "V6406 No vaccination-pt refuse",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6407",
                "title": "V6407 No vaccination-religion",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6408",
                "title": "V6408 No vaccin-prev disease",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6409",
                "title": "V6409 No vaccination NEC",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V641",
                "title": "V641 No proc/contraindication",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V642",
                "title": "V642 No proc/patient decision",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V643",
                "title": "V643 No proc for reasons NEC",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6441",
                "title": "V6441 Lap surg convert to open",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6442",
                "title": "V6442 Thoracoscop conv to open",
                "threedigit": "V64",
                "isLeaf": true
              },
              {
                "key": "V6443",
                "title": "V6443 Arthroscopc conv to open",
                "threedigit": "V64",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Other persons seeking consultation",
            "title": "V65 - Other persons seeking consultation",
            "threedigit": "V65",
            "children": [
              {
                "key": "V650",
                "title": "V650 Healthy person w sick",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6511",
                "title": "V6511 Ped pre-brth vst-parent",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6519",
                "title": "V6519 Person consult for anoth",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V652",
                "title": "V652 Person feigning illness",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V653",
                "title": "V653 Dietary surveil/counsel",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6540",
                "title": "V6540 Counseling NOS",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6541",
                "title": "V6541 Exercise counseling",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6542",
                "title": "V6542 Counslng sbstn use abuse",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6543",
                "title": "V6543 Counseling injry prevent",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6544",
                "title": "V6544 Hiv counseling",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6545",
                "title": "V6545 Consln ot sex trnsmt dis",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6546",
                "title": "V6546 Insulin pump training",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V6549",
                "title": "V6549 Other specfd counseling",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V655",
                "title": "V655 Persn w feared complaint",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V658",
                "title": "V658 Reason for consult NEC",
                "threedigit": "V65",
                "isLeaf": true
              },
              {
                "key": "V659",
                "title": "V659 Reason for consult NOS",
                "threedigit": "V65",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Convalescence and palliative care",
            "title": "V66 - Convalescence and palliative care",
            "threedigit": "V66",
            "children": [
              {
                "key": "V660",
                "title": "V660 Surgical convalescence",
                "threedigit": "V66",
                "isLeaf": true
              },
              {
                "key": "V661",
                "title": "V661 Radiotherapy convalescen",
                "threedigit": "V66",
                "isLeaf": true
              },
              {
                "key": "V662",
                "title": "V662 Chemotherapy convalescen",
                "threedigit": "V66",
                "isLeaf": true
              },
              {
                "key": "V663",
                "title": "V663 Mental dis convalescence",
                "threedigit": "V66",
                "isLeaf": true
              },
              {
                "key": "V664",
                "title": "V664 Fracture treatmnt conval",
                "threedigit": "V66",
                "isLeaf": true
              },
              {
                "key": "V665",
                "title": "V665 Convalescence NEC",
                "threedigit": "V66",
                "isLeaf": true
              },
              {
                "key": "V666",
                "title": "V666 Comb treatment convales",
                "threedigit": "V66",
                "isLeaf": true
              },
              {
                "key": "V667",
                "title": "V667 Encountr palliative care",
                "threedigit": "V66",
                "isLeaf": true
              },
              {
                "key": "V669",
                "title": "V669 Convalescence NOS",
                "threedigit": "V66",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Follow-up examination",
            "title": "V67 - Follow-up examination",
            "threedigit": "V67",
            "children": [
              {
                "key": "V6700",
                "title": "V6700 Follow-up surgery NOS",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V6701",
                "title": "V6701 Follow-up vag pap smear",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V6709",
                "title": "V6709 Follow-up surgery NEC",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V671",
                "title": "V671 Radiotherapy follow-up",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V672",
                "title": "V672 Chemotherapy follow-up",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V673",
                "title": "V673 Psychiatric follow-up",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V674",
                "title": "V674 FU exam treatd healed fx",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V6751",
                "title": "V6751 High-risk rx NEC exam",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V6759",
                "title": "V6759 Follow-up exam NEC",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V676",
                "title": "V676 Comb treatment follow-up",
                "threedigit": "V67",
                "isLeaf": true
              },
              {
                "key": "V679",
                "title": "V679 Follow-up exam NOS",
                "threedigit": "V67",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Encounters for administrative purposes",
            "title": "V68 - Encounters for administrative purposes",
            "threedigit": "V68",
            "children": [
              {
                "key": "V6801",
                "title": "V6801 Disability examination",
                "threedigit": "V68",
                "isLeaf": true
              },
              {
                "key": "V6809",
                "title": "V6809 Issue of med certif NEC",
                "threedigit": "V68",
                "isLeaf": true
              },
              {
                "key": "V681",
                "title": "V681 Issue repeat prescript",
                "threedigit": "V68",
                "isLeaf": true
              },
              {
                "key": "V682",
                "title": "V682 Request expert evidence",
                "threedigit": "V68",
                "isLeaf": true
              },
              {
                "key": "V6881",
                "title": "V6881 Referral-no exam/treat",
                "threedigit": "V68",
                "isLeaf": true
              },
              {
                "key": "V6889",
                "title": "V6889 Administrtve encount NEC",
                "threedigit": "V68",
                "isLeaf": true
              },
              {
                "key": "V689",
                "title": "V689 Administrtve encount NOS",
                "threedigit": "V68",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Problems related to lifestyle",
            "title": "V69 - Problems related to lifestyle",
            "threedigit": "V69",
            "children": [
              {
                "key": "V690",
                "title": "V690 Lack of physical exercse",
                "threedigit": "V69",
                "isLeaf": true
              },
              {
                "key": "V691",
                "title": "V691 Inapprt diet eat habits",
                "threedigit": "V69",
                "isLeaf": true
              },
              {
                "key": "V692",
                "title": "V692 High-risk sexual behavr",
                "threedigit": "V69",
                "isLeaf": true
              },
              {
                "key": "V693",
                "title": "V693 Gambling and betting",
                "threedigit": "V69",
                "isLeaf": true
              },
              {
                "key": "V694",
                "title": "V694 Lack of adequate sleep",
                "threedigit": "V69",
                "isLeaf": true
              },
              {
                "key": "V695",
                "title": "V695 Behav insomnia-childhood",
                "threedigit": "V69",
                "isLeaf": true
              },
              {
                "key": "V698",
                "title": "V698 Oth prblms rltd lfstyle",
                "threedigit": "V69",
                "isLeaf": true
              },
              {
                "key": "V699",
                "title": "V699 Prblm rltd lfstyle NOS",
                "threedigit": "V69",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Persons Without Reported Diagnosis Encountered During Examination And Investigation Of Individuals And Populations",
        "title": "V70-V82 Persons Without Reported Diagnosis Encountered During Examination And Investigation Of Individuals And Populations",
        "threedigit": "V70",
        "children": [
          {
            "key": "General medical examination",
            "title": "V70 - General medical examination",
            "threedigit": "V70",
            "children": [
              {
                "key": "V700",
                "title": "V700 Routine medical exam",
                "threedigit": "V70",
                "isLeaf": true
              },
              {
                "key": "V701",
                "title": "V701 Psych exam-authority req",
                "threedigit": "V70",
                "isLeaf": true
              },
              {
                "key": "V702",
                "title": "V702 Gen psychiatric exam NEC",
                "threedigit": "V70",
                "isLeaf": true
              },
              {
                "key": "V703",
                "title": "V703 Med exam NEC-admin purp",
                "threedigit": "V70",
                "isLeaf": true
              },
              {
                "key": "V704",
                "title": "V704 Exam-medicolegal reasons",
                "threedigit": "V70",
                "isLeaf": true
              },
              {
                "key": "V705",
                "title": "V705 Health exam-group survey",
                "threedigit": "V70",
                "isLeaf": true
              },
              {
                "key": "V706",
                "title": "V706 Health exam-pop survey",
                "threedigit": "V70",
                "isLeaf": true
              },
              {
                "key": "V707",
                "title": "V707 Exam-clincal trial",
                "threedigit": "V70",
                "isLeaf": true
              },
              {
                "key": "V708",
                "title": "V708 General medical exam NEC",
                "threedigit": "V70",
                "isLeaf": true
              },
              {
                "key": "V709",
                "title": "V709 General medical exam NOS",
                "threedigit": "V70",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Observation and evaluation for suspected conditions not found",
            "title": "V71 - Observation and evaluation for suspected conditions not found",
            "threedigit": "V71",
            "children": [
              {
                "key": "V7101",
                "title": "V7101 Obsv-adult antisoc behav",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V7102",
                "title": "V7102 Obsv-adolesc antisoc beh",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V7109",
                "title": "V7109 Observ-mental cond NEC",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V711",
                "title": "V711 Obsv-suspct mal neoplasm",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V712",
                "title": "V712 Observ-suspect TB",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V713",
                "title": "V713 Observ-work accident",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V714",
                "title": "V714 Observ-accident NEC",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V715",
                "title": "V715 Observ following rape",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V716",
                "title": "V716 Observ-inflicted inj NEC",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V717",
                "title": "V717 Obs-susp cardiovasc dis",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V7181",
                "title": "V7181 Observe-abuse & neglect",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V7182",
                "title": "V7182 Obs/eval sus exp anthrax",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V7183",
                "title": "V7183 Obs/eval exp biol NEC",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V7189",
                "title": "V7189 Observ-suspect cond NEC",
                "threedigit": "V71",
                "isLeaf": true
              },
              {
                "key": "V719",
                "title": "V719 Observ-suspect cond NOS",
                "threedigit": "V71",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special investigations and examinations",
            "title": "V72 - Special investigations and examinations",
            "threedigit": "V72",
            "children": [
              {
                "key": "V720",
                "title": "V720 Eye & vision examination",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7211",
                "title": "V7211 Hearing exam-fail screen",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7212",
                "title": "V7212 Hearing conservatn/trtmt",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7219",
                "title": "V7219 Exam ears & hearing NEC",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V722",
                "title": "V722 Dental examination",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7231",
                "title": "V7231 Routine gyn examination",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7232",
                "title": "V7232 Pap smear confirmation",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7240",
                "title": "V7240 Pregnancy test unconfirm",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7241",
                "title": "V7241 Pregnancy test negative",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7242",
                "title": "V7242 Pregnancy test-positive",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V725",
                "title": "V725 Radiological exam NEC",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7260",
                "title": "V7260 Laboratory exam NOS",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7261",
                "title": "V7261 Antibody response exam",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7262",
                "title": "V7262 Routine physicl lab exam",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7263",
                "title": "V7263 Pre-procedure lab exam",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7269",
                "title": "V7269 Laboratory exam NEC",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V727",
                "title": "V727 Skin/sensitization tests",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7281",
                "title": "V7281 Preop cardiovsclr exam",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7282",
                "title": "V7282 Preop respiratory exam",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7283",
                "title": "V7283 Oth spcf preop exam",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7284",
                "title": "V7284 Preop exam unspcf",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7285",
                "title": "V7285 Oth specified exam",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V7286",
                "title": "V7286 Blood typing encounter",
                "threedigit": "V72",
                "isLeaf": true
              },
              {
                "key": "V729",
                "title": "V729 Examination NOS",
                "threedigit": "V72",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening examination for viral and chlamydial diseases",
            "title": "V73 - Special screening examination for viral and chlamydial diseases",
            "threedigit": "V73",
            "children": [
              {
                "key": "V730",
                "title": "V730 Screening-poliomyelitis",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V731",
                "title": "V731 Screening for smallpox",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V732",
                "title": "V732 Screening for measles",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V733",
                "title": "V733 Screening for rubella",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V734",
                "title": "V734 Screening-yellow fever",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V735",
                "title": "V735 Screening-arbovirus dis",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V736",
                "title": "V736 Screening for trachoma",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V7381",
                "title": "V7381 Special screen exam HPV",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V7388",
                "title": "V7388 Scrn oth spcf chlmyd dis",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V7389",
                "title": "V7389 Scrn oth spcf viral dis",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V7398",
                "title": "V7398 Scrn unspcf chlmyd dis",
                "threedigit": "V73",
                "isLeaf": true
              },
              {
                "key": "V7399",
                "title": "V7399 Scrn unspcf viral dis",
                "threedigit": "V73",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening examination for bacterial and spirochetal diseases",
            "title": "V74 - Special screening examination for bacterial and spirochetal diseases",
            "threedigit": "V74",
            "children": [
              {
                "key": "V740",
                "title": "V740 Screening for cholera",
                "threedigit": "V74",
                "isLeaf": true
              },
              {
                "key": "V741",
                "title": "V741 Screening-pulmonary TB",
                "threedigit": "V74",
                "isLeaf": true
              },
              {
                "key": "V742",
                "title": "V742 Screening for leprosy",
                "threedigit": "V74",
                "isLeaf": true
              },
              {
                "key": "V743",
                "title": "V743 Screening for diphtheria",
                "threedigit": "V74",
                "isLeaf": true
              },
              {
                "key": "V744",
                "title": "V744 Screen-bact conjunctivit",
                "threedigit": "V74",
                "isLeaf": true
              },
              {
                "key": "V745",
                "title": "V745 Screen for veneral dis",
                "threedigit": "V74",
                "isLeaf": true
              },
              {
                "key": "V746",
                "title": "V746 Screening for yaws",
                "threedigit": "V74",
                "isLeaf": true
              },
              {
                "key": "V748",
                "title": "V748 Screen-bacterial dis NEC",
                "threedigit": "V74",
                "isLeaf": true
              },
              {
                "key": "V749",
                "title": "V749 Screen-bacterial dis NOS",
                "threedigit": "V74",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening examination for other infectious diseases",
            "title": "V75 - Special screening examination for other infectious diseases",
            "threedigit": "V75",
            "children": [
              {
                "key": "V750",
                "title": "V750 Screen-rickettsial dis",
                "threedigit": "V75",
                "isLeaf": true
              },
              {
                "key": "V751",
                "title": "V751 Screening for malaria",
                "threedigit": "V75",
                "isLeaf": true
              },
              {
                "key": "V752",
                "title": "V752 Screen for leishmaniasis",
                "threedigit": "V75",
                "isLeaf": true
              },
              {
                "key": "V753",
                "title": "V753 Screen-trypanosomiasis",
                "threedigit": "V75",
                "isLeaf": true
              },
              {
                "key": "V754",
                "title": "V754 Screen-mycotic infect",
                "threedigit": "V75",
                "isLeaf": true
              },
              {
                "key": "V755",
                "title": "V755 Screen-schistosomiasis",
                "threedigit": "V75",
                "isLeaf": true
              },
              {
                "key": "V756",
                "title": "V756 Screen for filariasis",
                "threedigit": "V75",
                "isLeaf": true
              },
              {
                "key": "V757",
                "title": "V757 Screen for helminthiasis",
                "threedigit": "V75",
                "isLeaf": true
              },
              {
                "key": "V758",
                "title": "V758 Screen-parasitic dis NEC",
                "threedigit": "V75",
                "isLeaf": true
              },
              {
                "key": "V759",
                "title": "V759 Screen for infec dis NOS",
                "threedigit": "V75",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening for malignant neoplasms",
            "title": "V76 - Special screening for malignant neoplasms",
            "threedigit": "V76",
            "children": [
              {
                "key": "V760",
                "title": "V760 Screen mal neop-resp org",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7610",
                "title": "V7610 Scrn mal neo breast NOS",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7611",
                "title": "V7611 Screen mammogram hi risk",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7612",
                "title": "V7612 Screen mammogram NEC",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7619",
                "title": "V7619 Scrn mal neo breast NEC",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V762",
                "title": "V762 Screen mal neop-cervix",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V763",
                "title": "V763 Screen mal neop-bladder",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7641",
                "title": "V7641 Screen mal neop-rectum",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7642",
                "title": "V7642 Screen mal neop-oral cav",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7643",
                "title": "V7643 Screen mal neop-skin",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7644",
                "title": "V7644 Scrn malig neop-prostate",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7645",
                "title": "V7645 Screen malig neop-testis",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7646",
                "title": "V7646 Screen malig neop-ovary",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7647",
                "title": "V7647 Screen malig neop-vagina",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7649",
                "title": "V7649 Screen mal neop oth site",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7650",
                "title": "V7650 Scrn malig neo-intes NOS",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7651",
                "title": "V7651 Screen malig neop-colon",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7652",
                "title": "V7652 Scrn mal neo-small intes",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7681",
                "title": "V7681 Screen neop-nervous syst",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V7689",
                "title": "V7689 Screen neoplasm NEC",
                "threedigit": "V76",
                "isLeaf": true
              },
              {
                "key": "V769",
                "title": "V769 Screen-neoplasm NOS",
                "threedigit": "V76",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening for endocrine nutritional metabolic and immunity disorders",
            "title": "V77 - Special screening for endocrine nutritional metabolic and immunity disorders",
            "threedigit": "V77",
            "children": [
              {
                "key": "V770",
                "title": "V770 Screen-thyroid disorder",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V771",
                "title": "V771 Screen-diabetes mellitus",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V772",
                "title": "V772 Screen for malnutrition",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V773",
                "title": "V773 Screen-phenylketonuria",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V774",
                "title": "V774 Screen for galactosemia",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V775",
                "title": "V775 Screening for gout",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V776",
                "title": "V776 Screen-cystic fibrosis",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V777",
                "title": "V777 Screen-inborn err metab",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V778",
                "title": "V778 Screening for obesity",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V7791",
                "title": "V7791 Screen lipoid disorders",
                "threedigit": "V77",
                "isLeaf": true
              },
              {
                "key": "V7799",
                "title": "V7799 Screen-endoc/nut/met NEC",
                "threedigit": "V77",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening for disorders of blood and blood-forming organs",
            "title": "V78 - Special screening for disorders of blood and blood-forming organs",
            "threedigit": "V78",
            "children": [
              {
                "key": "V780",
                "title": "V780 Screen-iron defic anemia",
                "threedigit": "V78",
                "isLeaf": true
              },
              {
                "key": "V781",
                "title": "V781 Screen-defic anemia NEC",
                "threedigit": "V78",
                "isLeaf": true
              },
              {
                "key": "V782",
                "title": "V782 Screen-sickle cell dis",
                "threedigit": "V78",
                "isLeaf": true
              },
              {
                "key": "V783",
                "title": "V783 Scrn-hemoglobinopath NEC",
                "threedigit": "V78",
                "isLeaf": true
              },
              {
                "key": "V788",
                "title": "V788 Screen-blood dis NEC",
                "threedigit": "V78",
                "isLeaf": true
              },
              {
                "key": "V789",
                "title": "V789 Screen-blood dis NOS",
                "threedigit": "V78",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening for mental disorders and developmental handicaps",
            "title": "V79 - Special screening for mental disorders and developmental handicaps",
            "threedigit": "V79",
            "children": [
              {
                "key": "V790",
                "title": "V790 Screening for depression",
                "threedigit": "V79",
                "isLeaf": true
              },
              {
                "key": "V791",
                "title": "V791 Screening for alcoholism",
                "threedigit": "V79",
                "isLeaf": true
              },
              {
                "key": "V792",
                "title": "V792 Scrn intellect disabilty",
                "threedigit": "V79",
                "isLeaf": true
              },
              {
                "key": "V793",
                "title": "V793 Screen-development prob",
                "threedigit": "V79",
                "isLeaf": true
              },
              {
                "key": "V798",
                "title": "V798 Screen-mental dis NEC",
                "threedigit": "V79",
                "isLeaf": true
              },
              {
                "key": "V799",
                "title": "V799 Screen-mental dis NOS",
                "threedigit": "V79",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening for neurological eye and ear diseases",
            "title": "V80 - Special screening for neurological eye and ear diseases",
            "threedigit": "V80",
            "children": [
              {
                "key": "V8001",
                "title": "V8001 Screen-traumtc brain inj",
                "threedigit": "V80",
                "isLeaf": true
              },
              {
                "key": "V8009",
                "title": "V8009 Screen-neuro condition",
                "threedigit": "V80",
                "isLeaf": true
              },
              {
                "key": "V801",
                "title": "V801 Screening for glaucoma",
                "threedigit": "V80",
                "isLeaf": true
              },
              {
                "key": "V802",
                "title": "V802 Screening-eye cond NEC",
                "threedigit": "V80",
                "isLeaf": true
              },
              {
                "key": "V803",
                "title": "V803 Screening for ear dis",
                "threedigit": "V80",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening for cardiovascular respiratory and genitourinary diseases",
            "title": "V81 - Special screening for cardiovascular respiratory and genitourinary diseases",
            "threedigit": "V81",
            "children": [
              {
                "key": "V810",
                "title": "V810 Scrn-ischemic heart dis",
                "threedigit": "V81",
                "isLeaf": true
              },
              {
                "key": "V811",
                "title": "V811 Screen for hypertension",
                "threedigit": "V81",
                "isLeaf": true
              },
              {
                "key": "V812",
                "title": "V812 Screen-cardiovasc NEC",
                "threedigit": "V81",
                "isLeaf": true
              },
              {
                "key": "V813",
                "title": "V813 Screen-bronch/emphysema",
                "threedigit": "V81",
                "isLeaf": true
              },
              {
                "key": "V814",
                "title": "V814 Screen-respir cond NEC",
                "threedigit": "V81",
                "isLeaf": true
              },
              {
                "key": "V815",
                "title": "V815 Screen for nephropathy",
                "threedigit": "V81",
                "isLeaf": true
              },
              {
                "key": "V816",
                "title": "V816 Screen for gu cond NEC",
                "threedigit": "V81",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Special screening for other conditions",
            "title": "V82 - Special screening for other conditions",
            "threedigit": "V82",
            "children": [
              {
                "key": "V820",
                "title": "V820 Screen for skin cond",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V821",
                "title": "V821 Screen-rheumatoid arthr",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V822",
                "title": "V822 Screen-rheumat dis NEC",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V823",
                "title": "V823 Screen-cong hip dislocat",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V824",
                "title": "V824 Mat pstntl scr-chrm anom",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V825",
                "title": "V825 Screen-contamination NEC",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V826",
                "title": "V826 Multiphasic screening",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V8271",
                "title": "V8271 Screen-gentc dis carrier",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V8279",
                "title": "V8279 Genetic screening NEC",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V8281",
                "title": "V8281 Screen - osteoporosis",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V8289",
                "title": "V8289 Screen for condition NEC",
                "threedigit": "V82",
                "isLeaf": true
              },
              {
                "key": "V829",
                "title": "V829 Screen for condition NOS",
                "threedigit": "V82",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Genetics",
        "title": "V83-V84 Genetics",
        "threedigit": "V83",
        "children": [
          {
            "key": "Genetic carrier status",
            "title": "V83 - Genetic carrier status",
            "threedigit": "V83",
            "children": [
              {
                "key": "V8301",
                "title": "V8301 Asympt hemoph a carrier",
                "threedigit": "V83",
                "isLeaf": true
              },
              {
                "key": "V8302",
                "title": "V8302 Sympt hemophil a carrier",
                "threedigit": "V83",
                "isLeaf": true
              },
              {
                "key": "V8381",
                "title": "V8381 Cystic fibrosis gene car",
                "threedigit": "V83",
                "isLeaf": true
              },
              {
                "key": "V8389",
                "title": "V8389 Genetic carrier stat NEC",
                "threedigit": "V83",
                "isLeaf": true
              }
            ]
          },
          {
            "key": "Genetic susceptibility to disease",
            "title": "V84 - Genetic susceptibility to disease",
            "threedigit": "V84",
            "children": [
              {
                "key": "V8401",
                "title": "V8401 Genetc sus mal neo brest",
                "threedigit": "V84",
                "isLeaf": true
              },
              {
                "key": "V8402",
                "title": "V8402 Genetc sus mal neo ovary",
                "threedigit": "V84",
                "isLeaf": true
              },
              {
                "key": "V8403",
                "title": "V8403 Genetc sus mal neo prost",
                "threedigit": "V84",
                "isLeaf": true
              },
              {
                "key": "V8404",
                "title": "V8404 Genetc susc mal neo endo",
                "threedigit": "V84",
                "isLeaf": true
              },
              {
                "key": "V8409",
                "title": "V8409 Genetic susc mal neo NEC",
                "threedigit": "V84",
                "isLeaf": true
              },
              {
                "key": "V8481",
                "title": "V8481 Genetc sus mult endo neo",
                "threedigit": "V84",
                "isLeaf": true
              },
              {
                "key": "V8489",
                "title": "V8489 Genetic suscept dis NEC",
                "threedigit": "V84",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Body Mass Index",
        "title": "V85-V85 Body Mass Index",
        "threedigit": "V85",
        "children": [
          {
            "key": "Body mass index (bmi)",
            "title": "V85 - Body mass index (bmi)",
            "threedigit": "V85",
            "children": [
              {
                "key": "V850",
                "title": "V850 BMI less than 19,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V851",
                "title": "V851 BMI between 19-24,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8521",
                "title": "V8521 BMI 25.0-25.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8522",
                "title": "V8522 BMI 26.0-26.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8523",
                "title": "V8523 BMI 27.0-27.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8524",
                "title": "V8524 BMI 28.0-28.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8525",
                "title": "V8525 BMI 29.0-29.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8530",
                "title": "V8530 BMI 30.0-30.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8531",
                "title": "V8531 BMI 31.0-31.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8532",
                "title": "V8532 BMI 32.0-32.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8533",
                "title": "V8533 BMI 33.0-33.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8534",
                "title": "V8534 BMI 34.0-34.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8535",
                "title": "V8535 BMI 35.0-35.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8536",
                "title": "V8536 BMI 36.0-36.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8537",
                "title": "V8537 BMI 37.0-37.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8538",
                "title": "V8538 BMI 38.0-38.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8539",
                "title": "V8539 BMI 39.0-39.9,adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8541",
                "title": "V8541 BMI 40.0-44.9, adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8542",
                "title": "V8542 BMI 45.0-49.9, adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8543",
                "title": "V8543 BMI 50.0-59.9, adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8544",
                "title": "V8544 BMI 60.0-69.9, adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8545",
                "title": "V8545 BMI 70 and over, adult",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8551",
                "title": "V8551 BMI,pediatric <5%",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8552",
                "title": "V8552 BMI,pediatric 5% - <85%",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8553",
                "title": "V8553 BMI,pediatric 85% - <95%",
                "threedigit": "V85",
                "isLeaf": true
              },
              {
                "key": "V8554",
                "title": "V8554 BMI,pediatric >= 95%",
                "threedigit": "V85",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Estrogen Receptor Status",
        "title": "V86-V86 Estrogen Receptor Status",
        "threedigit": "V86",
        "children": [
          {
            "key": "Estrogen receptor status",
            "title": "V86 - Estrogen receptor status",
            "threedigit": "V86",
            "children": [
              {
                "key": "V860",
                "title": "V860 Estrogen recep pstv stat",
                "threedigit": "V86",
                "isLeaf": true
              },
              {
                "key": "V861",
                "title": "V861 Estrogen recep neg stat",
                "threedigit": "V86",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Specified Personal Exposures And History Presenting Hazards To Health",
        "title": "V87-V87 Other Specified Personal Exposures And History Presenting Hazards To Health",
        "threedigit": "V87",
        "children": [
          {
            "key": "Other specified personal exposures and history presenting hazards to health",
            "title": "V87 - Other specified personal exposures and history presenting hazards to health",
            "threedigit": "V87",
            "children": [
              {
                "key": "V8701",
                "title": "V8701 Contact/exposure arsenic",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8702",
                "title": "V8702 Cont/susp expose uranium",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8709",
                "title": "V8709 Cntct/exp hazrd metl NEC",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8711",
                "title": "V8711 Cntct/exp aromatc amines",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8712",
                "title": "V8712 Contact/exposure benzene",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8719",
                "title": "V8719 Cont/exp haz aromat NEC",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V872",
                "title": "V872 Cont/exp hazard chem NEC",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8731",
                "title": "V8731 Contact/exposure mold",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8732",
                "title": "V8732 Contact/exp algae bloom",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8739",
                "title": "V8739 Cont/exp hazard sub NEC",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8741",
                "title": "V8741 Hx antineoplastic chemo",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8742",
                "title": "V8742 Hx monoclonal drug thrpy",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8743",
                "title": "V8743 Hx estrogen therapy",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8744",
                "title": "V8744 Hx inhaled steroid thrpy",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8745",
                "title": "V8745 Hx systemc steroid thrpy",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8746",
                "title": "V8746 Hx immunosuppres thrpy",
                "threedigit": "V87",
                "isLeaf": true
              },
              {
                "key": "V8749",
                "title": "V8749 Hx drug therapy NEC",
                "threedigit": "V87",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Acquired Absence Of Other Organs And Tissue",
        "title": "V88-V88 Acquired Absence Of Other Organs And Tissue",
        "threedigit": "V88",
        "children": [
          {
            "key": "Acquired absence of other organs and tissue",
            "title": "V88 - Acquired absence of other organs and tissue",
            "threedigit": "V88",
            "children": [
              {
                "key": "V8801",
                "title": "V8801 Acq absnce cervix/uterus",
                "threedigit": "V88",
                "isLeaf": true
              },
              {
                "key": "V8802",
                "title": "V8802 Acq ab ut remn cerv stmp",
                "threedigit": "V88",
                "isLeaf": true
              },
              {
                "key": "V8803",
                "title": "V8803 Acq absnc cerv/remain ut",
                "threedigit": "V88",
                "isLeaf": true
              },
              {
                "key": "V8811",
                "title": "V8811 Acq total absnc pancreas",
                "threedigit": "V88",
                "isLeaf": true
              },
              {
                "key": "V8812",
                "title": "V8812 Acq part absnce pancreas",
                "threedigit": "V88",
                "isLeaf": true
              },
              {
                "key": "V8821",
                "title": "V8821 Acq absence of hip joint",
                "threedigit": "V88",
                "isLeaf": true
              },
              {
                "key": "V8822",
                "title": "V8822 Acq absence knee joint",
                "threedigit": "V88",
                "isLeaf": true
              },
              {
                "key": "V8829",
                "title": "V8829 Acq absence of oth joint",
                "threedigit": "V88",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Other Suspected Conditions Not Found",
        "title": "V89-V89 Other Suspected Conditions Not Found",
        "threedigit": "V89",
        "children": [
          {
            "key": "Other suspected conditions not found",
            "title": "V89 - Other suspected conditions not found",
            "threedigit": "V89",
            "children": [
              {
                "key": "V8901",
                "title": "V8901 Sus amntc cav/mem nt fnd",
                "threedigit": "V89",
                "isLeaf": true
              },
              {
                "key": "V8902",
                "title": "V8902 Sus placentl prob nt fnd",
                "threedigit": "V89",
                "isLeaf": true
              },
              {
                "key": "V8903",
                "title": "V8903 Sus fetal anomaly nt fnd",
                "threedigit": "V89",
                "isLeaf": true
              },
              {
                "key": "V8904",
                "title": "V8904 Sus fetal growth not fnd",
                "threedigit": "V89",
                "isLeaf": true
              },
              {
                "key": "V8905",
                "title": "V8905 Sus cervcl shortn nt fnd",
                "threedigit": "V89",
                "isLeaf": true
              },
              {
                "key": "V8909",
                "title": "V8909 Oth sus mat/ftl nt fnd",
                "threedigit": "V89",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Retained Foreign Body",
        "title": "V90-V90 Retained Foreign Body",
        "threedigit": "V90",
        "children": [
          {
            "key": "Retained foreign body",
            "title": "V90 - Retained foreign body",
            "threedigit": "V90",
            "children": [
              {
                "key": "V9001",
                "title": "V9001 Retain deplete uran frag",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9009",
                "title": "V9009 Retain radioac frag NEC",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9010",
                "title": "V9010 Retained metal frag NOS",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9011",
                "title": "V9011 Retain magnet metal frag",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9012",
                "title": "V9012 Retain nonmag meta frag",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V902",
                "title": "V902 Retain plastic fragments",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9031",
                "title": "V9031 Retained quills/spines",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9032",
                "title": "V9032 Retained tooth",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9033",
                "title": "V9033 Retained wood fragments",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9039",
                "title": "V9039 Retain organic frag NEC",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9081",
                "title": "V9081 Retained glass fragments",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9083",
                "title": "V9083 Retain stone/crystl frag",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V9089",
                "title": "V9089 Retain FB NEC",
                "threedigit": "V90",
                "isLeaf": true
              },
              {
                "key": "V909",
                "title": "V909 Retain FB, mat NOS",
                "threedigit": "V90",
                "isLeaf": true
              }
            ]
          }
        ]
      },
      {
        "key": "Multiple Gestation Placenta Status",
        "title": "V91-V91 Multiple Gestation Placenta Status",
        "threedigit": "V91",
        "children": [
          {
            "key": "Multiple gestation placenta status",
            "title": "V91 - Multiple gestation placenta status",
            "threedigit": "V91",
            "children": [
              {
                "key": "V9100",
                "title": "V9100 Twin gest-plac/sac NOS",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9101",
                "title": "V9101 Twin gest-monochr/monoam",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9102",
                "title": "V9102 Twin gest-monochr/diamni",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9103",
                "title": "V9103 Twin gest-dich/diamniotc",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9109",
                "title": "V9109 Twin gest-plac/sac undet",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9110",
                "title": "V9110 Tripl gest-plac/sac NOS",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9111",
                "title": "V9111 Triplet gest 2+ monochor",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9112",
                "title": "V9112 Triplet gest 2+ monoamn",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9119",
                "title": "V9119 Tripl gest-plac/sac und",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9120",
                "title": "V9120 Quad gest-plac/sac NOS",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9121",
                "title": "V9121 Quad gest 2+ monochorion",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9122",
                "title": "V9122 Quad gest 2+ monoamniotc",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9129",
                "title": "V9129 Quad gest-plac/sac undet",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9190",
                "title": "V9190 Mult gest-plac/sac NOS",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9191",
                "title": "V9191 Mult gest 2+ monochr NEC",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9192",
                "title": "V9192 Mult gest 2+ monoamn NEC",
                "threedigit": "V91",
                "isLeaf": true
              },
              {
                "key": "V9199",
                "title": "V9199 Mult gest-plac/sac undet",
                "threedigit": "V91",
                "isLeaf": true
              }
            ]
          }
        ]
      }
    ]
  }
]