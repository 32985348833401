import React from "react";
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Input } from "antd";

export default class EditableField extends React.Component {
  state = {
    value: this.props.value,
    editable: false
  };

  componentWillReceiveProps(nextProps) {
    // Should be a controlled component.
    if ('value' in nextProps) {
      const value = nextProps.value;
      this.setState({value:value});
    }
  }


  handleChange = e => {
    const value = e.target.value;
    this.setState({ value });
  };

  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  };

  cancel=()=>{
    this.setState({editable:false});
  }

  edit = () => {
    this.setState({ editable: true });
  };

  render() {
    const { editable,value } = this.state;


    //const readValue=typeof(value)=="string"?value.substring(0,30)+'...':value;

    return (
      <div className="editable-cell">
        {editable ? (
          <Input
            value={value}
            onChange={this.handleChange}
            onPressEnter={this.check}
            suffix={
                <div >
              <CheckOutlined className="editable-cell-icon-check" onClick={this.check} />
              <CloseOutlined className="editable-cell-icon-check" onClick={this.cancel} /></div>}
          />
        ) : (
          <div style={{...{ paddingRight: 24, marginLeft:5,...this.props.style}}}>
            {/* <span style={{color:"#428bca",borderBottom:"dashed 1px #0088cc",cursor:"pointer" }}>{value || ""}</span> */}
            <span>{value}</span>
            {!this.props.readOnly&&<EditOutlined style={{cursor:"pointer",marginLeft:5}} onClick={this.edit} />}
          </div>
        )}
      </div>
    );
  }
}

