import React, { Component, useState, useEffect } from "react";
import { Select, InputNumber, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

const Periodicty = (props) => {
  const [t] = useTranslation();

  const [unit, setUnit] = useState("y");
  const [amount, setAmount] = useState(1);

  useEffect(() => {
    if (!props.value) return;
    setUnit(props.value.substring(0, 1));
    setAmount(props.value.substring(1));
  }, [props.value]);

  const changeAmount = (newAmount) => {
    setAmount(newAmount);
    props.onChange(unit + newAmount);
  };
  const changeUnit = (newUnit) => {
    setUnit(newUnit);
    props.onChange(newUnit + amount);
  };
  const periodicityExpression = {
    m: t("Monthly"),
    q: t("Quarterly"),
    s: t("SemiAnnual"),
    y: t("Annual"),
  };
  const getCustomOptions=(customOptsArray)=>{
    return customOptsArray.map(p=>(<Select.Option value={p.expression} key={p.expression}>{p.name}</Select.Option>))
  }
  if (props.periodicityOptions) {
    //constrained to product config options
    return (
      <Select onChange={(v) => props.onChange(v)} value={props.value} disabled={props.disabled}>
        {(Array.isArray(props.periodicityOptions)?props.periodicityOptions:[]).map((p) => p.custom?getCustomOptions(p.custom):(
          <Select.Option value={p} key={p}>
            {periodicityExpression[p]}
          </Select.Option>
        ))}
      </Select>
    );
  }
  return (
    <div style={{ display: "flex" }}>
      <InputNumber style={{ width: 80, marginRight: 3 }} onChange={changeAmount} value={amount} min={1} />
      <Select onChange={changeUnit} value={unit}>
        <Select.Option value="d">{t("Day(s)")}</Select.Option>
        <Select.Option value="w">{t("Week(s)")}</Select.Option>
        <Select.Option value="m">{t("Month(s)")}</Select.Option>
        <Select.Option value="y">{t("Year(s)")}</Select.Option>
      </Select>
    </div>
  );
};

export default Periodicty;
