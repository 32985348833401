import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const TransactionTemplateSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoading(true);
    exe("DoQuery", { sql:"SELECT code,name FROM TransactionTemplate" }).then((r) => {
      setLoading(false);
      if (r.ok) setData(r.outData);
      else message.error(r.msg);
    });
  };
  const onSelect = (v, opt) => {
    props.onSelect && props.onSelect(opt.props.record);
  };
  return (
    <Select
      value={props.value}
      onChange={(v) => props.onChange(v)}
      onSelect={onSelect}
      loading={loading}
      style={{ width: "100%", ...props.style }}
      placeholder={t("Please select...")}
      disabled={props.disabled}
      allowClear>
      {data.map((p) => (
        <Select.Option value={p.code} key={p.code} record={p}>
          {p.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default TransactionTemplateSelect;