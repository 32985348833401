import React, {useState} from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { SaveOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {useTranslation} from "react-i18next";
import CalculationSchemeExe from "../Bpm/CalculationSchemeExe";
import DatePickerW from "../Shared/DatePickerW";
import { safeGet } from '../../Lib/Dal';

const ProfitCommissionLiquidationDetail = (props) => {
    const [calculationSchemeResult,setCalculationSchemeResult]=useState(null);
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            //merging calculation scheme results
            values.calculationSchemeResult=calculationSchemeResult;
            setLoading(true);
            console.log(values);
            props.onOk&&props.onOk(values);
            
        })
    }
    return (
        <div>
            <Button type={"link"} icon={<LegacyIcon type={"left"} />} onClick={props.onBack}>{t("Back")}</Button>

            {!calculationSchemeResult&&<CalculationSchemeExe schemeId={props.schemeId} context={props.context} onResult={r=>setCalculationSchemeResult(r)} />}
            {calculationSchemeResult&&<div>
                <Form.Item label={t("Liquidation Name")}>{field("liquidationName",{rules:[{required:true}]})(<Input style={{width:250}} />)}</Form.Item>
                <Form.Item label={t("Period Start")}>{field("start",{rules:[{required:true}],initialValue:safeGet(["parameters","start"],calculationSchemeResult,undefined)})(<DatePickerW />)}</Form.Item>
                <Form.Item label={t("Period End")}>{field("end",{rules:[{required:true}],initialValue:safeGet(["parameters","end"],calculationSchemeResult,undefined)})(<DatePickerW />)}</Form.Item>
                <Form.Item label={t("Total")} hasFeedback validateStatus={(calculationSchemeResult.total||calculationSchemeResult.total==0)?"success":"error"} help={(calculationSchemeResult.total||calculationSchemeResult.total==0)?"":t("Variable 'total' not found in calculation scheme results")} >{field("total",{
                    rules:[{required:true}],
                    initialValue:calculationSchemeResult.total
                })(<InputNumber disabled style={{width:250}} />)}</Form.Item>
                
                <Button style={{marginTop:10}} type="primary" icon={<SaveOutlined />} onClick={onSave} loading={loading}>{t("Save")}</Button>
            </div>}
        </div>
    );
};

export default Form.create()(ProfitCommissionLiquidationDetail);