import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Popconfirm, Row, Space, Switch, Tabs, message } from "antd";
import { useTranslation } from "react-i18next";
import { SaveOutlined, BuildOutlined, GlobalOutlined, DeleteOutlined, ReloadOutlined, PlusOutlined, BankOutlined, TeamOutlined, FormOutlined } from '@ant-design/icons';
import { exe, safeGet } from "../../Lib/Dal";
import Currency from "../Shared/Currency";
import DefaultPage from "../Shared/DefaultPage";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import FundAccounts from "./FundAccounts";
import FundPlans from "./FundPlans";
import FundRoles from "./FundRoles";
import { getConfigProfile } from "../../Lib/Helpers";
import CustomForm from "../Shared/CustomForm";

const Fund = (props) => {
    const [loading,setLoading]=useState(false);
    const [fundFormId,setFundFormId]=useState();
    const [t] = useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        lodCustomForm();
        if(props.match.params.fundId==0){
            form.resetFields();
        }else{
            load(props.match.params.fundId);
        }
    }
    ,[props.match.params.fundId])

    const lodCustomForm=()=>{
        getConfigProfile().then(profile=>{
            const formId=safeGet(["Pensions","fundFormId"],profile);
            if(formId){
                setFundFormId(formId);
            }
        })
    }

    const load=id=>{
        setLoading(true);
        exe("RepoFund",{operation:"GET",filter:`id=${id}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                form.setFieldsValue(r.outData[0]);
            }else{
                message.error(r.msg);
            }
        })
    }

    const save=()=>{
        form.validateFields().then(values=>{
            console.log(values);
            const operation=values.id?"UPDATE":"ADD";
            //custom form
            if (fundFormId && window.customFundForm && window.customFundForm.instanceContainers[0]) {
                if (window.customFundForm.instanceContainers[0].reportValidity()) {
                    values.jCustomForm = JSON.stringify(window.customFundForm.userData);
                } else {
                    message.error(t("Please check custom form validation errors"));
                    return;
                }
            }
            setLoading(true);
            exe("RepoFund",{operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    //redirecting using id if this is a new record
                    if(operation=="ADD") props.history.push("/fund/"+r.outData[0].id);

                }else{
                    message.error(r.msg);
                }
            })

        })
    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoFund",{operation:"DELETE",entity:{id:fundId}}).then(r=>{
            setLoading(false);
            if(r.ok){
                //redirecting to the fund list
                props.history.push("/funds");
            }else message.error(r.msg);
        })
    }
    const onAdd=()=>{
        props.history.push("/fund/0");
    }

    const name=Form.useWatch("name",form);
    const jCustomForm=Form.useWatch("jCustomForm",form);
    const fundId=props.match.params.fundId;
    return <DefaultPage title={t("Fund")+' '+(name?name:'')} icon="profile" routes={{
        routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Fund List"), path: "funds" },
            { breadcrumbName: t("Fund"), path: "fund" }
        ]}}
        extra={<Space>
             <Button type="link" icon={<PlusOutlined />} onClick={onAdd}>{t("Add")}</Button>
               <Button type="link" icon={<ReloadOutlined />} onClick={()=>load(props.match.params.fundId)}>{t("Reload")}</Button>
            <Popconfirm  title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={!fundId}><Button type="link" icon={<DeleteOutlined />}  disabled={!fundId} >{t("Delete")}</Button></Popconfirm> 
            <Button type="primary" icon={<SaveOutlined />} onClick={save} loading={loading}>{t("Save")}</Button>
            </Space>}>
                <Tabs>
                    <Tabs.TabPane key="general" tab={<span><BankOutlined />{t("General")}</span>} >
                        <Form form={form} layout="vertical">
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item name={"jCustomForm"} hidden><Input/></Form.Item>
                                    <Form.Item label={t("ID")} name={"id"}><Input disabled/></Form.Item>
                                    <Form.Item label={t("Name")} name={"name"} rules={[{required:true}]}><Input/></Form.Item>
                                    <Form.Item label={t("Holder")} name={"contactId"} rules={[{required:true}]}><SimpleContactSelect/></Form.Item>
                                    <Form.Item label={t("Currency")} name={"currency"} rules={[{required:true}]} ><Currency/></Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label={t("Active")} name={"active"} valuePropName="checked" ><Switch/></Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane key="accounts" tab={<span><GlobalOutlined />{t("Accounts")}</span>} disabled={!fundId}>
                        <FundAccounts fundId={fundId} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="plans" tab={<span><BuildOutlined />{t("Investment Plans")}</span>} disabled={!fundId}>
                        <FundPlans fundId={fundId} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="roles" tab={<span><TeamOutlined />{t("Fund Roles")}</span>} disabled={!fundId}>
                        <FundRoles fundId={fundId} />
                    </Tabs.TabPane>
                    <Tabs.TabPane key="custom" tab={<span><FormOutlined />{t("Fund Form")}</span>} disabled={!fundId}>
                        <CustomForm formId={fundFormId} variable="customFundForm" value={jCustomForm}/>
                    </Tabs.TabPane>
                
                </Tabs>
    </DefaultPage>
}
export default Fund;