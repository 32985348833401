import React from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import "inputmask/dist/jquery.inputmask.min"
import { get, exe, downloadFile } from "../Lib/Dal";
import { message } from "antd";
import { withTranslation } from "react-i18next";
import '../scoped-bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const formRender = require("formBuilder/dist/form-render.min.js");
//require("bootstrap-4-grid/css/grid.min.css");

class FormRender extends React.Component {
  componentDidMount() {
    if (this.props.modal) {
      this.setState({ mounted: true });
    }
  }

  evalInContext(js, context) {
    return function () {
      return eval(js);
    }.call(context);
  }
  loadCatalog = (catalog, element, filter, valueField = "id", displayField = "name") => {
    exe(catalog, { operation: "GET", filter: filter }).then((r) => {
      r.outData.map((r) => {
        $(element).append($("<option></option>").attr("value", r[valueField]).text(r[displayField]));
      });
    });
  };
  shouldComponentUpdate(nextProps, nextState) {
    //validacion y envio de form
    if (nextProps.next) {
      if (nextProps.formId) {
        if(window.formInstance1){
          try {
            this.fbRender = window.formInstance1.instanceContainers[0]; //updating if there has been html changes
            this.formInstance=window.formInstance1;
          }catch (e){
            console.error("Error while parsing formInstance1. Value not updated");
            console.error(e);
          }
        }
        if (this.fbRender && this.fbRender.reportValidity()) {
          this.props.onFormOk(this.formInstance.userData);
        }
      } else {
        this.props.onFormOk({}); //no tiene form
      }
      return false;
    }
    if (!this.props.modal && nextProps.formId == this.props.formId && nextProps.formId != 0) return false; //nada pasa en form, es un update del padre

    //cambio de form
    const formId = nextProps.formId;
    const me = this;
    if (formId) {
      if (!nextProps.valores) {
        //no tiene valores guardados
        if (nextProps.closing) return false; //no need to make request when closing
        exe("GetForms", { filter: "id=" + formId }).then((v) => {
          me.fbRender = document.getElementById("fb-render");
          me.formInstance = $(this.fbRender).formRender({
            container: this.fbRender,
            formData: v.outData[0].json,
          });
          window.formInstance1=me.formInstance; //temp
          if (v.outData[0].logic) {
            try {
              console.log("Executing custom logic:", v.outData[0].logic, { processId: this.props.processId });

              me.evalInContext(v.outData[0].logic, {
                exe: exe,
                loadCatalog: this.loadCatalog,
                processId: this.props.processId,
                downloadFile: downloadFile,
                message,
              });
              console.log("Success");
            } catch (error) {
              console.warn(error);
            }
          }
        });
      } else {
        //tiene valores guardados
        me.fbRender = document.getElementById("fb-render");
        me.formInstance = $(this.fbRender).formRender({
          container: this.fbRender,
          formData: nextProps.valores.json,
        });
      }
    } else {
      //no tiene form
      me.fbRender = document.getElementById("fb-render");
      me.formInstance = $(this.fbRender).formRender({
        container: this.fbRender,
        formData: '[{"type":"header","subtype":"h3","label":"' + this.props.t("No form configured for this step. Click next to advance.") + '"}]',
      });
    }
    return false; //no quiero renders, lo maneja jquery
  }

  render() {
    return <form id="fb-render"></form>;
  }
}

export default withTranslation()(FormRender);
