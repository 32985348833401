import React, { useEffect, useState } from 'react';
import {
  List,
  Drawer,
  Button,
  Table,
  Tabs,
  Space,
} from 'antd';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';
import { StarOutlined, CloseOutlined } from '@ant-design/icons';
import { Icon } from '@ant-design/compatible';
import moment from 'moment';

const RecentMenu = (props) => {
  const [menuItems, setMenuItems] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const [t]=useTranslation();
  const data = props.history || [];

  useEffect(() => {
    if(props.menuItems) {
      const menuItems = props.menuItems.flatMap(item => item.children ? item.children : item);
      setMenuItems(menuItems);
    }
  }, [props.menuItems]);

  useEffect(() => {
    if(localStorage.bookmarks&&props.visible) {
      const bookmarks = JSON.parse(localStorage.bookmarks);
      setBookmarks(bookmarks);
    }
  }, [props.visible]);

  const onRemove = (item) => {
    const newBookmarks = bookmarks.filter((p) => p.url !== item.url);
    localStorage.setItem('bookmarks', JSON.stringify(newBookmarks));
    setBookmarks(newBookmarks);
  };
  
  const getIcon = (url) => {
    if(!url) return "File";
    if(url.includes("#")){
      //comes from bookmark url
      url=url.split("#")[1];
      url=url.split("?")[0];
    }
    const onlyAlphabetsUrl = url.replace(/[^a-zA-Z]/g, "").toLowerCase();
    const p = menuItems.find(p => p.link.toLowerCase().includes(onlyAlphabetsUrl));
    return p ? p.icono : "File";
  }
  const getText = (url) => {
    if(!url) return "";
    if(url.includes("#")){
      //comes from bookmark url
      url=url.split("#")[1];
      url=url.split("?")[0];
    }
    const onlyAlphabetsUrl = url.replace(/[^a-zA-Z]/g, "").toLowerCase();
    const onlyNumbersUrl = url.replace(/[^0-9]/g, "").toLowerCase();
    const p = menuItems.find(p => p.link.toLowerCase().includes(onlyAlphabetsUrl));
    let text= p ? p.texto.replace(t("New "),"") : url;
    text=`${text} ${onlyNumbersUrl}`
    return text;
  }

  return  <Drawer bodyStyle={{paddingTop:0}}
  title={
    <span>
      <StarOutlined style={{ marginRight: 5 }} />
      {t("Bookmarks")}
    </span>
  }
  placement="left"
  width={600}
  closable={false}
  onClose={props.onClose}
  visible={props.visible}>
  
  <Tabs>
    <Tabs.TabPane tab={t("Recent")} key="recent">
      <Table dataSource={data.slice(Math.max(data.length - 20, 0)).reverse()} pagination={false} size='small' rowKey={"location"} >
      <Table.Column title={t("Location")} dataIndex={"location"} render={(v,r)=><a href={"#" + v}>
                <Space>
                  <Icon type={getIcon(v)} onClick={() => props.onClose()} style={{ fontSize: 15 }} />
                    <span onClick={() => props.onClose()}>
                      {getText(v)}
                    </span>
                </Space>
              </a>} />
              
      <Table.Column title={t("Last Viewed")} dataIndex="visited" render={v=>moment(v).fromNow()}  />
    </Table>
    </Tabs.TabPane>
    <Tabs.TabPane tab={t("Bookmarks")} key="bookmarks">
    <Table dataSource={bookmarks} pagination={false} size='small' rowKey={"url"} >
      <Table.Column title={t("Bookmark")} dataIndex={"url"} render={(v,r)=><a href={v}>
                <Space>
                  <Icon type={getIcon(v)} onClick={() => props.onClose()} style={{ fontSize: 15 }} />
                    <span onClick={() => props.onClose()}>
                      {getText(v)}
                    </span>
                </Space>
              </a>} />
              
      <Table.Column title={t("Actions")} key="actions" render={(v,r)=> <Button type="link" icon={<CloseOutlined />} onClick={() => onRemove(r)} style={{ padding: 0 }} />}  />
    </Table>
    </Tabs.TabPane>
  </Tabs>
  
</Drawer>
};

export default RecentMenu;