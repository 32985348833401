import React, {useEffect} from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, message, Row } from "antd";
import {useTranslation} from "react-i18next";
import AuditStatusSelect from "./AuditStatusSelect";
import moment from "moment";
import Section from "../Shared/Section";
import CustomForm from "../Shared/CustomForm";
import DatePickerW from "../Shared/DatePickerW";

const AuditForm = (props) => {
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.value&&props.value.id){
            props.form.setFieldsValue({...props.value,auditDate:moment(props.value.auditDate)});
        }
    },[props.value])
    
    const formId=props.formId;
    
    const save=()=>{
       

        props.form.validateFields((err,values)=>{
            if (window.auditForm && window.auditForm.instanceContainers[0]) {
                if (window.auditForm.instanceContainers[0].reportValidity()) {
                    values.jValues = JSON.stringify(window.auditForm.userData);
                    values.formId=formId;
                } else {
                    message.error(t("Please check custom form validation errors"));
                    return;
                }
            }
            if(err) return;
            props.onSave(values);
        })
    }
    
    const field=props.form.getFieldDecorator;
    return (
        <div>
            <Row gutter={16}>
                <Col span={8}>
                    {field("id")}
                    <Section text={t("Main Info")}>
                        <Form.Item label={t("Date")}>{field("auditDate",{initialValue:new moment(),rules:[{required:true}]})(<DatePickerW />)}</Form.Item>
                        <Form.Item label={t("Status")}>{field("status",{rules:[{required:true}]})(<AuditStatusSelect />)}</Form.Item>
                        <Form.Item label={t("Notes")}>{field("summary")(<Input.TextArea autoSize={{ minRows: 3}} />)}</Form.Item>
                        <Button type="primary" icon={<SaveOutlined />} onClick={save}>{t("Save")}</Button>
                    </Section>
                </Col>
                <Col span={16}>
                    {formId&&<CustomForm formId={formId} variable="auditForm" value={props.value&&props.value.jValues}  />}
                </Col>
            </Row>
           
                
        </div>
    );
};

export default Form.create()(AuditForm);