import React, {useEffect} from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { SaveOutlined } from '@ant-design/icons';
import { Button } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {useTranslation} from "react-i18next";
import UserSelect from "../Bpm/UserSelect";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";

const CashFundsForm = (props) => {
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    
    useEffect(()=>{
        if(props.selected&&props.selected.id){
            props.form.setFieldsValue(props.selected);//update
        }
    },[props.selected])
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            console.log(values);
            props.onOk&&props.onOk(values);
            
        })
    }
    const values=props.form.getFieldsValue();
    return (
        <div style={{width:400}}>
            <Button type={"link" } icon={<LegacyIcon type={"left"} />} onClick={()=>props.onCancel()}>{t("Back")}</Button>
            {field("id")}
            {field("transferWorkspaceId")}
            <Form.Item label={t("User")}>{field("user",{rules:[{required:true}]})(<UserSelect />)}</Form.Item>
            <Form.Item label={t("Currency")}>{field("currency",{rules:[{required:true}]})(<Currency style={{width:"100%"}} />)}</Form.Item>
            <Form.Item label={t("Balance")}>{field("balance",{rules:[{required:true}]})(<Money currency={values.currency} />)}</Form.Item>
            <Button type="primary" icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
        </div>
    );
};

export default Form.create()(CashFundsForm);