import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const ChainSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (props.filter) load(); //filter mandatory
  }, [props.filter]);

  const load = () => {
    setLoading(true);
    exe("RepoChain", { operation: "GET", filter: props.filter }).then((r) => {
      setLoading(false);
      if (r.ok) setData(r.outData);
      else message.error(r.msg);
    });
  };
  const valueField = props.valueField || "name";
  return (
    <Select
      value={props.value}
      onChange={(v) => props.onChange(v)}
      loading={loading}
      style={{ width: 200, ...props.style }}
      placeholder={t("Please select operation")}
      disabled={props.disabled}
      allowClear>
      {data.map((p) => (
        <Select.Option value={p[valueField]} key={p.id}>
          {p.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ChainSelect;
