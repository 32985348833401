import React, { useEffect } from "react";
import { CaretRightOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { List, Card } from "antd";

const Operations = (props) => {
  const data = props.value;
  useEffect(() => {
    if (props.onLoad) props.onLoad();
  }, []);
  return (
    <div>
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 4,
          lg: 4,
          xl: 6,
          xxl: 6,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Card
              bodyStyle={{ height: 128, overflow: "hidden", fontSize: "0.7vw" }}
              hoverable
              actions={[
                <CaretRightOutlined key="play" onClick={item.onPlay} />,
                // <Icon type="edit" key="edit" />,
                // <Icon type="ellipsis" key="ellipsis" />,
              ]}>
              <Card.Meta avatar={<LegacyIcon type={item.icon} style={{ fontSize: 22 }} theme="twoTone" />} title={item.title} description={item.description} />
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

export default Operations;
