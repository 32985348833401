import { message, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney, getColor } from "../../Lib/Helpers";
import Status from "../Shared/Status";

const ExchangeOperations = (props) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.accountId) load(props.accountId);
  }, [props.accountId, props.reload]);

  const load = (accountId) => {
    setLoading(true);
    exe("RepoExchangeOperation", { operation: "GET", filter: "accountId=" + accountId, include: ["Process"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };
  return (
    <div>
      <Table dataSource={data} loading={loading}>
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("Amount")} dataIndex="amount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Operation")} dataIndex="operationName" />
        <Table.Column title={t("Reference")} dataIndex="reference" />
        <Table.Column title={t("Date")} dataIndex="created" render={(v, r) => formatDateShort(r)} />
        <Table.Column title={t("Converted")} dataIndex="amountEx" render={(v, r) => r.targetCurrency?formatMoney(v, r.targetCurrency):null} />
        <Table.Column title={t("State")} dataIndex="entityState" render={(v) => <Tag>{v}</Tag>} />
        <Table.Column
          dataIndex="Process"
          title={t("Workflow")}
          render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load(props.accountId)} />}
        />
      </Table>
    </div>
  );
};

export default ExchangeOperations;
