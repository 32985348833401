import { message, Select } from "antd";
import { load } from "js-yaml";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const PolicyTags = (props) => {
  const [t] = useTranslation();

  const loadTags = () => {
    exe("RepoPolicyTag", { operation: "GET", filter: "lifePolicyId=" + props.policyId }).then((r) => {
      props.onChange(r.outData);
    });
  };
  const addTag = (tag) => {
    exe("RepoPolicyTag", { operation: "ADD", entity: { lifePolicyId: props.policyId, tag: tag } }).then((r) => {
      if (r.ok) {
        message.success("Category Added");
        loadTags();
      }
    });
  };
  const onChange = (tags) => {
    console.log(tags);
    if (!tags) return;

    const existing = props.value || [];
    const added = tags.filter((p) => existing.map((q) => q.tag).indexOf(p) == -1);
    const removed = existing.filter((p) => tags.indexOf(p.tag) == -1);

    if (!props.policyId) {
      //pre save, policy will save them
      props.onChange(tags.map((q) => ({ id: 0, lifePolicyId: props.policyId, tag: q })));
    } else {
      //post save, making changes
      if (removed.length > 0) {
        removeTag(removed[0].id);
      } else if (added.length > 0) {
        addTag(added[0]);
      }
    }
  };
  const removeTag = (id) => {
    exe("RepoPolicyTag", { operation: "DELETE", entity: { id: id } }).then((r) => {
      if (r.ok) {
        message.success(t("Category removed"));
        loadTags();
      }
    });
  };
  const value = (props.value || []).map((p) => p.tag); //normalizing
  return (
    <div>
      <Select
        value={value}
        mode="tags"
        style={{ width: "100%" }}
        tokenSeparators={[","]}
        onChange={(v) => onChange(v)}
        placeholder={t("Type and enter to add category")}
      />
    </div>
  );
};

export default PolicyTags;
