import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const OperationSelect = (props) => {
  const [data, setData] = useState([]);
  const [t, i18n] = useTranslation();
  useEffect(() => load(), []);
  const load = () => {
    exe("RepoChain", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <Select
      allowClear
      placeholder={t("Operations")}
      onChange={(v) => props.onChange(v)}
      value={props.value || undefined}
      disabled={props.disabled}
      style={{ width: "100%" }}>
      {data.map((p) => (
        <Select.Option value={p.id} key={p.id}>
          {p.operation}
        </Select.Option>
      ))}
    </Select>
  );
};

export default OperationSelect;
