import React, {useEffect, useState} from 'react';
import config from "../config";
import {downloadFile} from "../Lib/Dal";
import FileUpload from "./Life/FileUpload";

const UserSignature = () => {
    const [loading,setLoading] = useState(false);
    const [imgBlob,setImgBlob] = useState(null);
    
    useEffect(() => {
        load();
    }, []);

 const load = () => {
     setLoading(true);
     fetch(`${config.ssApiUrl}/proxy/uploaded/${localStorage.email}.png`, {
         method: "GET",
         headers: {
             "Content-Type": "application/json",
             Authorization: "Bearer " + localStorage.token,
         },
     })
         .then((response) => {
             if (!response.ok) throw new Error("Not 2xx response", {cause: response});
             return response.blob();
         }
         )
         .then((data) => setImgBlob(URL.createObjectURL(data)))
         .catch((err) => console.log(err));
 }
    
    return (
        <div>
            <FileUpload fileName={`${localStorage.email}.png`}  onChange={load} />
            <img src={imgBlob} alt="user signature" style={{display:imgBlob?"block":"none"}} />

            
        </div>
    );
};

export default UserSignature;