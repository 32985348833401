import React, {useState} from 'react';
import { CalendarOutlined, ClockCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Modal, Table, message, Tag, Button } from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import {formatDateShort, formatMoney} from "../../Lib/Helpers";
import BillUnique from "./BillUnique";

const UniqueBills = ({policyId,changes,currency}) => {
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [selected,setSelected]=useState();
    const [t]=useTranslation();
    
    const load=()=>{
        setLoading(true);
        exe("GetUniqueBills",{policyId:policyId}).then(r=>{
            setLoading(false);
            if(r.ok) setData(r.outData.Changes);else message.error(r.msg);
        })
    }
    const statusCodes=[
        {code:0,name:t("Proposal")},
        {code:1,name:t("Executed")},
        {code:2,name:t("Scheduled")},
        {code:3,name:t("Scheduled and Executed")},
        {code:4,name:t("Execution failed")},
        {code:5,name:t("Aborted")},
        {code:6,name:t("Amended")},
    ]
    const parseStatusCode = (statusCode) => {
        switch (statusCode) {
            case 0:
                return <Tag color="blue">{statusCodes[0].name}</Tag>;
            case 1:
                return <Tag color="green">{statusCodes[1].name}</Tag>;
            case 2:
                return (
                    <Tag color="orange">
                        <ClockCircleOutlined style={{ marginRight: 5 }} />
                        {statusCodes[2].name}
                    </Tag>
                );
            case 3:
                return (
                    <Tag color="green">
                        <CalendarOutlined style={{ marginRight: 5 }} />
                        {statusCodes[3].name}
                    </Tag>
                );
            case 4:
                return <Tag color="red">{statusCodes[4].name}</Tag>;
            case 5:
                return (
                    <Tag>
                        <DeleteOutlined style={{ marginRight: 5 }} />
                        {statusCodes[5].name}
                    </Tag>
                );
            case 6:
                return (
                    <Tag color="geekblue">
                        <EditOutlined style={{ marginRight: 5 }} />
                        {statusCodes[6].name}
                    </Tag>
                );
            default:
                return statusCode;
        }
    };
    
    return (
        <div>
            {!selected&&<Table dataSource={changes} rowKey={"id"}>
               <Table.Column dataIndex={"id"} title={t("Change Id")} />
               <Table.Column dataIndex={"code"} title={t("Code")} />
               <Table.Column dataIndex={"status"} title={t("Status")} render={v=>parseStatusCode(v)} />
               <Table.Column dataIndex={"Discriminator"} title={t("Change")} />
               <Table.Column dataIndex={"effectiveDate"} title={t("Effective")} render={v=>formatDateShort(v)} />
               <Table.Column dataIndex={"BillUnique.uniqueTotal"} title={t("Total")} render={(v,r)=>r.BillUnique?formatMoney(v,currency):t("No Bill Unique")} />
               <Table.Column key={"actions"} title={t("Actions")} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"folder-open"} />} onClick={()=>setSelected(r.BillUnique)} disabled={!r.BillUnique} /> </div>} />
           </Table>}
            {selected&&<div>
                <Button type={"link"} icon={<LegacyIcon type={"left"} />} onClick={()=>setSelected(undefined)}>{t("Back")}</Button>
                <BillUnique value={selected} onChange={() => {}} currency={currency} />
            </div>}
        </div>
    );
};

export default UniqueBills;