import React, { useEffect, useState } from 'react';
import { exe } from "../../Lib/Dal";
import { message, Select } from "antd";

const ClaimExpenseSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        load();
    }, [])

    const load = () => {
        setLoading(true);
        exe("RepoClaimExpenseCatalog", { operation: "GET" }).then(r => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData)
            } else message.error(r.msg)
        })
    }
    return (
        <div>
            <Select value={props.value} onChange={props.onChange} disabled={props.disabled} loading={loading} allowClear>
                {data.map(p => <Select.Option value={p.code} key={p.code}>{p.name}</Select.Option>)}
            </Select>
        </div>
    );
};

export default ClaimExpenseSelect;