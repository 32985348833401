import React, { useState, useEffect } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { CheckOutlined, CloseOutlined, LeftOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Tabs, Table, Button, Row, Col, Tag, Switch } from "antd";
import Job from "./Job";
import { exe } from "../../Lib/Dal";
import { formatDateFromNow } from "../../Lib/Helpers";
import Log from "./Log";
import TriggerForm from "./TriggerForm";
import TriggerLog from "./TriggerLog";
import { useTranslation } from "react-i18next";

const Triggers = () => {
  const [t] = useTranslation();

  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [listensToFilters, setListensToFilters] = useState([]);

  useEffect(() => {
    loadTriggers();
  }, []);

  const loadTriggers = () => {
    setLoading(true);
    exe("RepoTrigger", { operation: "GET" }).then((r) => {
      setLoading(false);
      setData(r.outData);
      setListensToFilters(r.outData.map((d) => ({text:d.listensTo,value:d.listensTo})).filter((v,i,s)=>s.findIndex(t=>t.value===v.value)===i));  //distinct listensTo values
    });
  };
  const onTriggerExit = () => {
    setSelectedTrigger(null);
    loadTriggers();
  };

  return (
    <DefaultPage title={t("Triggers")} icon="robot">
      {!selectedTrigger && (
        <div>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => setSelectedTrigger({})}>
            {t("New")}
          </Button>
          <Button type="link" icon={<SyncOutlined />} onClick={() => loadTriggers()}>
            {t("Refresh")}
          </Button>
          <Table style={{ marginTop: 5 }} dataSource={data} rowKey="id" loading={loading}>
            <Table.Column title={t("Id")} dataIndex="id" />
            <Table.Column title={t("Code")} dataIndex="code" />
            <Table.Column title={t("Name")} dataIndex="name" />
            <Table.Column title={t("Command")} dataIndex="listensTo" filters={listensToFilters} onFilter={(value, record) => record.listensTo.startsWith(value)} filterSearch={true} />

            <Table.Column
              title={t("Status")}
              dataIndex="enabled"
              filters={[{ text: t("Enabled"), value: true }, { text: t("Disabled"), value: false }]}
              onFilter={(value, record) => record.enabled === value}
              render={(v) => <Switch disabled checked={v} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />}
            />
            <Table.Column
              title={t("Actions")}
              render={(v, r) => (
                <Button type="link" onClick={() => setSelectedTrigger(r)}>
                  {t("Open")}
                </Button>
              )}
            />
          </Table>
        </div>
      )}
      {selectedTrigger && (
        <div>
          <Button type="link" icon={<LeftOutlined />} onClick={() => setSelectedTrigger(null)}>
            {t("Back")}
          </Button>
          <TriggerForm value={selectedTrigger} onExit={onTriggerExit} triggerId={selectedTrigger.id} />
        </div>
      )}
    </DefaultPage>
  );
};

export default Triggers;
