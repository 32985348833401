import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { safeGetRaw } from '../../Lib/Dal';
import { parseFieldConfigString } from '../../Lib/Helpers';
const ClonePolicyOptions = (props) => {
    const [includeCertificatesConfig, setIncludeCertificatesConfig] = useState({});
    const [skipInactiveCertificatesConfig, setSkipInactiveCertificatesConfig] = useState({});
    const [form]=useForm();
    const [t]=useTranslation();
    
    useEffect(() => {
        if(props.config){
            parseFieldConfig(props.config);
        }
    }
    , [props.config]);

    const parseFieldConfig=(config)=>{
        const includeCertificatesConfig = parseFieldConfigString(safeGetRaw(["Main", "clonePolicyFieldConfig","includeCertificates"], config, "110"));
        const skipInactiveCertificatesConfig = parseFieldConfigString(safeGetRaw(["Main", "clonePolicyFieldConfig","skipInactiveCertificates"], config, "110"));
        setIncludeCertificatesConfig(includeCertificatesConfig);
        setSkipInactiveCertificatesConfig(skipInactiveCertificatesConfig);
        form.setFieldsValue({
            includeCertificates: includeCertificatesConfig.value,
            skipInactiveCertificates: skipInactiveCertificatesConfig.value
        });

    }
    const onOk=()=>{
        form.validateFields().then(values=>{
            props.onOk(values);
        })
    }
    return <Modal title={t("Clone Policy Options")} visible={props.visible} onCancel={props.onCancel} onOk={onOk}>
            <Form form={form} layout="vertical">
            <span style={{ display: includeCertificatesConfig.visible ? "block" : "none" }}>
                <Form.Item label={t("Include Certificates")} name={"includeCertificates"} valuePropName='checked'>
                    <Checkbox disabled={!includeCertificatesConfig.editable}/>
                </Form.Item>
            </span>
                <span style={{ display: skipInactiveCertificatesConfig.visible ? "block" : "none" }}>
                <Form.Item label={t("Skip Inactive Certificates")} name={"skipInactiveCertificates"} valuePropName='checked'>
                    <Checkbox disabled={!skipInactiveCertificatesConfig.editable}/>
                </Form.Item>
                </span>
            </Form>
        </Modal>
}
export default ClonePolicyOptions;