import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Drawer, Input, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const CoaSearchFilter = (props) => {
    const [t] = useTranslation();
    const field = props.form.getFieldDecorator;
    const onSearch = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            props.onSearch(values);
        });
    };
    return (
        <Drawer title={t("Account filter")} visible={props.visible} onClose={props.onClose} width={512}>
            <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
                    {t("Reset")}
                </Button>
            </div>
            <Form>
                <Form.Item label={t("Account Starts With")}>{field("RLIKEcode")(<Input />)}</Form.Item>
                <Form.Item label={t("Exact Account")}>{field("code")(<Input />)}</Form.Item>
                <Form.Item label={t("Name")}>{field("LIKEname")(<Input />)}</Form.Item>
                <Form.Item label={t("Parent Account")}>{field("parentCode")(<Input />)}</Form.Item>
                <Form.Item label={t("Currency")}>{field("currency")(<Input />)}</Form.Item>
                <Form.Item label={t("Level")}>{field("level")(<InputNumber />)}</Form.Item>
                
                <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()}>
                    {t("Search")}
                </Button>
            </Form>
        </Drawer>
    );
};

export default Form.create({})(CoaSearchFilter);
