import React, { Component } from "react";
import { ClockCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Timeline,
  Button,
  Modal,
  Input,
  Select,
  Row,
  Col,
  Switch,
  DatePicker,
  Typography,
  Table,
} from "antd";
import { withTranslation } from "react-i18next";
const { Column } = Table;
const { Text, Title } = Typography;
const { Option } = Select;

class ClaimTimeline extends Component {
  state = {
    modal: false,
  };
  showModal = () => {
    this.setState({ modal: true });
  };
  handleOk = () => {
    this.setState({ modal: false });
  };
  handleCancel = () => {
    this.setState({ modal: false });
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const data = this.props.data ? this.props.data : [];
    const { t, i18n } = this.props;
    return (
      <div>
        <Button type="primary" icon={<PlusOutlined />} onClick={this.showModal}>
          {t("Add")}
        </Button>
        <Timeline mode="alternate">
          {data.map((p) => {
            return (
              <Timeline.Item>
                {p.estado} {p.fecha.split(".")[0]}
              </Timeline.Item>
            );
          })}
          <Timeline.Item dot={<ClockCircleOutlined style={{ fontSize: "16px" }} />}>{t("Waiting")}</Timeline.Item>
        </Timeline>

        <Modal title={t("New Event")} visible={this.state.modal} onOk={this.handleOk} onCancel={this.handleCancel}>
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <Form.Item style={{ display: "none" }}>{getFieldDecorator("id")(<Input hidden />)}</Form.Item>
            <Form.Item style={{ display: "none" }}>
              {getFieldDecorator("claimId", {
                initialValue: this.props.claimId,
              })(<Input hidden />)}
            </Form.Item>
            <Form.Item label={t("Notification Channel")}>
              {getFieldDecorator("type")(
                <Select mode="multiple">
                  <Option value={0}>{t("None")}</Option>
                  <Option value={1}>{t("App")}</Option>
                  <Option value={2}>{t("SMS")}</Option>
                  <Option value={3}>{t("Email")}</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label={t("Event")}>{getFieldDecorator("event")(<Input.TextArea placeholder={t("Event or message")} autosize />)}</Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const Wrapped = Form.create()(ClaimTimeline);
export default withTranslation()(Wrapped);
