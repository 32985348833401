import { Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const LobTypeSelect = (props) => {
  const [t] = useTranslation();


  return (
    <div>
      <Select
        value={props.value}
        onChange={props.onChange}
        onClear={()=>props.onChange(null)}
        placeholder={t("Plase select LoB type")}
        allowClear>
        <Select.Option value="HEALTH">
          {t("Health")}
        </Select.Option>
        <Select.Option value="PROPERTY">
          {t("Property")}
        </Select.Option>
        <Select.Option value="LIFE">
          {t("Life")}
        </Select.Option>
      </Select>
    </div>
  );
};

export default LobTypeSelect;
