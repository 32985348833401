import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import CoverageSelect from "../Life/CoverageSelect";
import CoverageSelector from "../Life/CoverageSelector";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";
import PolicyCoverageSelect from "./PolicyCoverageSelect";
import moment from "moment";
import DatePickerW from "../Shared/DatePickerW";

const ClaimSalvageDetail = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;

  useEffect(() => {
    if (props.selected) {
      const values = props.selected;
      values.start = moment(values.start);
      props.form.setFieldsValue(values);
    }
  }, [props.selected]);

  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onOk(values);
      props.form.resetFields();
    });
  };
  const values = props.form.getFieldsValue();
  const policy = props.claim.Policy;
  return (
    <div>
      <Modal title={t("New Salvage Procedure")} visible={props.visible} onOk={onSubmit} onCancel={props.onCancel}>
        {field("id")}
        {field("claimId")}
        <Form.Item label={t("Procedure Type")}>
          {field("type", { rules: [{ required: true }] })(
            <Select style={{ width: "100%" }}>
              <Select.Option value={0}>{t("Salvage")}</Select.Option>
              <Select.Option value={1}>{t("Recovery")}</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t("Coverage")}>
          {field("coverageId", { rules: [{ required: true }] })(
            <PolicyCoverageSelect style={{ width: "100%" }} coverages={policy.Coverages} currency={policy.currency} />
          )}
        </Form.Item>
        <Form.Item label={t("Buyer")}>{field("buyerId", {})(<SimpleContactSelect />)}</Form.Item>
        <Form.Item label={t("Recoverer")}>{field("recovererId", {})(<SimpleContactSelect />)}</Form.Item>
        <div style={{ display: "flex" }}>
          <Form.Item label={t("Procedure Start")}>{field("start", {})(<DatePickerW />)}</Form.Item>
          <Form.Item label={t("Currency")} style={{ width: "100%" }}>
            {field("currency", { rules: [{ required: true }] })(<Currency style={{ width: "100%", marginLeft: 5 }} />)}
          </Form.Item>
        </div>
        <Form.Item label={t("Income")}>{field("income", {})(<Money currency={values.currency} />)}</Form.Item>
        <Form.Item label={t("Expenses")}>{field("expenses", {})(<Money currency={values.currency} />)}</Form.Item>
        <Form.Item label={t("Net Income")}>
          {field("netIncome", { normalize: (v, p, all) => (isNaN(all.income) ? 0 : all.income) - (isNaN(all.expenses) ? 0 : all.expenses) })(
            <Money currency={values.currency} disabled />
          )}
        </Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(ClaimSalvageDetail);
