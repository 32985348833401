import React, { Component } from "react";
import "handsontable/dist/handsontable.full.css";
import { HotTable } from "@handsontable/react";

import {
  AppstoreOutlined,
  CaretRightOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileOutlined,
  FolderOpenOutlined,
  SaveOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Menu, Modal, Select, message, Input, Upload } from "antd";
import { exe } from "../../Lib/Dal";
import { exeTable } from "../../Lib/Helpers";
import Inspector from "react-inspector";
import showPrompt from "../Shared/showPrompt";
import { withTranslation } from "react-i18next";

class RuleTable extends Component {
  componentDidMount() {
    this.loadTableNames();
    const initialSettings = {
      colHeaders: true,
      rowHeaders: true,
      contextMenu: true,
      comments: true,
      //formulas:true
    };

    this.table = React.createRef();

    window.table = this.table; //temp, debugging

    this.setState({ settings: initialSettings });
  }
  state = {
    id: 0,
    name: "New",
  };
  loadTableNames() {
    this.setState({ loading: true });
    exe("GetTableNames").then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState({ tableNames: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  }
  onNew() {
    this.setState({ id: 0, name: "new", settings: { ...this.state.settings, data: [[, , , ,]] } });
  }
  onSaveAs() {
    let name;
    const me = this;
    const modal = Modal.confirm({
      title: this.props.t("Save As..."),
      maskClosable: true,
      content: <Input onChange={(v) => (name = v.target.value)} />,
      onOk() {
        const data = { name: name, data: JSON.stringify(me.table.current.hotInstance.getData()) };
        exe("AddOrUpdateTable", data, { operation: "ADD" }, true).then((r) => {
          if (r.ok) {
            me.setState({ id: r.outData.id, name: r.outData.name });
            modal.destroy();
            me.loadTableNames();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }
  onSave() {
    if (this.state.id == 0) {
      this.onSaveAs();
    } else {
      const data = { id: this.state.id, name: this.state.name, data: JSON.stringify(this.table.current.hotInstance.getData()) };
      exe("AddOrUpdateTable", data, { operation: "UPDATE" }, true).then((r) => {
        if (r.ok) {
          this.setState({ id: r.outData.id, name: r.outData.name });
        }
      });
    }
  }
  onRename() {
    const me = this;
    showPrompt({
      title: this.props.t("Please enter new name"),
      placeholder: this.props.t("New name"),
      rules: [
        {
          required: true,
          message: this.props.t("You must enter name"),
        },
      ],
    }).then((newName) => {
      const data = { id: this.state.id, name: newName, data: JSON.stringify(this.table.current.hotInstance.getData()) };
      exe("AddOrUpdateTable", data, { operation: "UPDATE" }, true).then((r) => {
        if (r.ok) {
          this.setState({ id: r.outData.id, name: r.outData.name });
        } else {
          message.error(r.outData.msg);
        }
      });
    });
  }
  loadTable(id, modal) {
    this.setState({ loading: true });
    exe("GetTables", { filter: "id=" + id }).then((r) => {
      this.setState({ loading: false });
      if (modal) modal.destroy();
      if (r.ok) {
        const table = r.outData[0];
        this.setState({ id: table.id, name: table.name, settings: { ...this.state.settings, data: JSON.parse(table.data) } });
      } else {
        message.error(r.msg);
      }
    });
  }
  onOpen() {
    const openModal = Modal.info({
      title: this.props.t("Select File"),
      maskClosable: true,
      content: (
        <Select
          style={{ width: "100%", marginTop: 5 }}
          placeholder={this.props.t("Please select file")}
          loading={this.state.loading}
          showSearch
          filterOption={(input, option) => option.props.text.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onSelect={(id) => {
            this.loadTable(id, openModal);
          }}>
          {this.state.tableNames.map((p) => (
            <Select.Option key={p.id} value={p.id} text={p.id + "." + p.name}>
              {p.id}.{p.name}
            </Select.Option>
          ))}
        </Select>
      ),
    });
  }
  onDelete() {
    const me = this;
    Modal.confirm({
      title: this.props.t("Are you sure delete this file?"),
      content: this.props.t("This action cannot be undone"),
      okText: this.props.t("Yes"),
      okType: "danger",
      maskClosable: true,
      cancelText: this.props.t("No"),
      onOk() {
        if (me.state.id == 0) {
          message.error(this.props.t("No file selected"));
        } else {
          exe("DeleteTable", { id: me.state.id }, undefined, true).then((r) => {
            me.loadTableNames();
          });
          me.onNew();
        }
      },
    });
  }
  onTest() {
    const results = exeTable(this.table.current.hotInstance.getData());
    Modal.info({
      title: this.props.t("Results"),
      maskClosable: true,
      content: <Inspector data={results} expandLevel={3} />,
    });
  }
  onExport() {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    download(JSON.stringify(this.state.settings.data), this.state.name + ".json", "text/json");
  }
  onImport(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        this.setState({ id: 0, name: "new", settings: { ...this.state.settings, data: JSON.parse(e.target.result) } });
      } catch (error) {
        message.error(this.props.t("Invalid file"));
      }
    };
    reader.readAsText(file);
    return false;
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div style={{ height: "100%" }}>
        <Menu style={{ marginTop: "-10px", marginBottom: "20px" }} mode="horizontal" selectable={false}>
          <Menu.SubMenu
            title={
              <span className="submenu-title-wrapper">
                <AppstoreOutlined />
                {t("File")}
              </span>
            }>
            <Menu.ItemGroup title="Actions">
              <Menu.Item key="nuevo" onClick={() => this.onNew()}>
                <FileOutlined />
                {t("New")}
              </Menu.Item>
              <Menu.Item key="cargar" onClick={() => this.onOpen()}>
                <FolderOpenOutlined />
                {t("Open")}
              </Menu.Item>
              <Menu.Item key="guardar" onClick={() => this.onSave()}>
                <SaveOutlined />
                {t("Save")}
              </Menu.Item>
              <Menu.Item key="guardarComo" onClick={() => this.onSaveAs()}>
                <SaveOutlined />
                {t("Save As...")}
              </Menu.Item>
              <Menu.Item key="rename" onClick={() => this.onRename()}>
                <EditOutlined />
                {t("Rename")}
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => this.onDelete()}>
                <DeleteOutlined />
                {t("Delete")}
              </Menu.Item>
              <Menu.Item key="export" onClick={() => this.onExport()}>
                <DownloadOutlined />
                {t("Export")}
              </Menu.Item>
              <Menu.Item>
                <Upload showUploadList={false} beforeUpload={(f) => this.onImport(f)}>
                  <UploadOutlined />
                  {t("Import")}
                </Upload>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu.SubMenu>
          <Menu.Item onClick={() => this.onTest()}>
            <CaretRightOutlined />
            {t("Simulate")}
          </Menu.Item>
        </Menu>
        <h2>
          {this.state.id}.{this.state.name}
        </h2>
        <HotTable ref={this.table} settings={this.state.settings} licenseKey="non-commercial-and-evaluation" />
      </div>
    );
  }
}

export default withTranslation()(RuleTable);
