import React, {useEffect, useState} from "react";

import {
  BankOutlined,
  BlockOutlined,
  CalendarOutlined,
  CameraOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CloseSquareOutlined,
  DashboardOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EuroOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  FormOutlined,
  GiftOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  LineChartOutlined,
  MinusCircleOutlined,
  MinusOutlined,
  OrderedListOutlined,
  PercentageOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  PullRequestOutlined,
  RedoOutlined,
  RiseOutlined,
  ScheduleOutlined,
  ScissorOutlined,
  SearchOutlined,
  ShoppingOutlined,
  SolutionOutlined,
  StopOutlined,
  SwapOutlined,
  TeamOutlined,
  ToolOutlined,
  ToTopOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  CompassOutlined
} from '@ant-design/icons';

import {
  Table,
  Button,
  Dropdown,
  Menu,
  Divider,
  Tag,
  Typography,
  Popconfirm,
  message,
  Modal,
  Input,
  Select,
  DatePicker,
} from "antd";
import ChangeCapital from "./ChangeCapital";
import ChangeBeneficiary from "./ChangeBeneficiary";
import ChangeInvestment from "./ChangeInvestment";
import ChangeCancellation from "./ChangeCancellation";
import ChangeRescue from "./ChangeRescue";
import ChangeTotalRescue from "./ChangeTotalRescue";
import ChangeReinstatement from "./ChangeReinstatement";
import ChangePaidUpReinstatement from "./ChangePaidUpReinstatement";
import ChangeIndexation from "./ChangeIndexation";
import ChangeAddCoverage from "./ChangeAddCoverage";
import ChangeRemoveCoverage from "./ChangeRemoveCoverage";
import ChangePlan from "./ChangePlan";
import ChangePaidUp from "./ChangePaidUp";
import { formatDate, getColor } from "../../Lib/Helpers";
import Status from "../Shared/Status";
import ChangeBonus from "./ChangeBonus";
import ChangeFrequency from "./ChangeFrequency";
import ChangeCoverage from "./ChangeCoverage";
import ChangeCessionBeneficiary from "./ChangeCessionBeneficiary";
import ChangeNotTakenUp from "./ChangeNotTakenUp";
import ChangePaymentMethod from "./ChangePaymentMethod";
import ChangeIntermediary from "./ChangeIntermediary";
import {exe, safeGet, safeValue} from "../../Lib/Dal";
import ChangePolicyholder from "./ChangePolicyholder";
import ChangeMaturity from "./ChangeMaturity";
import ChangeRedirection from "./ChangeRedirection";
import ChangeSwitch from "./ChangeSwitch";
import Amendments from "./Amendments";
import ChangeLoading from "./ChangeLoading";
import ChangeClause from "./ChangeClause";
import ChangeExclusion from "./ChangeExclusion";
import ChangeTerm from "./ChangeTerm";
import { useTranslation } from "react-i18next";
import ChangeAddCertificate from "./ChangeAddCertificate";
import ChangeRemoveCertificate from "./ChangeRemoveCertificate";
import ChangeInsuredObject from "./ChangeInsuredObject";
import ChangeReAdjustment from "./ChangeReAdjustment";
import ChangeReEvaluation from "./ChangeReEvaluation";
import ChangeCoInsured from "./ChangeCoInsured";
import ChangeAnnuityBeneficiary from "./ChangeAnnuityBeneficiary";
import ChangeGroup from "./ChangeGroup";
import moment from "moment";
import ChangePayPlan from "./ChangePayPlan";
import ChangeTemporalStatus from "./ChangeTemporalStatus";
import ChangeBenefit from "./ChangeBenefit";
import ChangeContingentBeneficiary from "./ChangeContingentBeneficiary";
import PolicySnapshot from "./PolicySnapshot";
import ChangeInformative from "./ChangeInformative";
import ChangePolicySurcharge from "./ChangePolicySurcharge";

const Changes = (props) => {
  const [t] = useTranslation();
  const [changeType, setChangeType] = useState("notSelected");
  const [change, setChange] = useState({});
  const [changeToBeAmended,setChangeToBeAmended]=useState(undefined);
  const [policyPreview,setPolicyPreview]=useState(undefined);
  const [loading,setLoading]=useState(false);
  const [snapshot,setSnapshot]=useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  
  const data = props.value || [];
  const policy = policyPreview?policyPreview:props.policy;
  const insuredSum = policy.insuredSum;
  const currentInvestment = policy.investmentPlanCode;
  const currentInvestmentSelection = policy.InvestmentSelections;
  const currentStatus = policy.entityState;
  const currency = policy.currency;
  const policyId = policy.id;
  const config = props.config || {};

  const changesEnabled =
    (props.userActions && props.userActions.includes("CHANGES")) ||
    (props.userActions && props.userActions.length == 0 && policy.policyType == "C" && policy.active); //certificates issued from master dont have workflow
  const productDisabledChanges = safeValue("Main.disabledChanges", config, []);
  const productDisabledAmendments = safeValue("Main.disabledAmendments", config, []);
  const inactivePolicyChanges = safeValue("Main.inactivePolicyChanges", config, []);
  const allowPastEffectDateInChanges = safeValue("Main.allowPastEffectDateInChanges", config, []);
  
  const selectableSegments = safeGet(["Main", "segments"], config, []);
  
const validAmendments=["ChangeAddCoverage","ChangeCoverage","ChangeFrequency","ChangeIndexation","ChangeLoading",
  "ChangePaymentMethod","ChangePlan","ChangePolicyCapital","ChangePolicyholder","ChangeRemoveCoverage","ChangeTerm","ChangeExclusion"].filter(p=>!productDisabledAmendments.includes(p));

useEffect(()=>console.log("CHANGES MENU!!",getChangesMenu()),[])

  const onChangeType = (e,amended) => {
    if(amended) {
      setLoading(true);
      //get policy preview with the change to be amended applied
      exe("GetPolicyPreview",{changeId:amended.id,policyId:amended.lifePolicyId}).then(r=>{
        setLoading(false);
        if(r.ok){
          setPolicyPreview(r.outData.policyPreview);
          setChangeToBeAmended(amended);
          setChange({});
          setChangeType(e.key);
        }else message.error(r.msg);
      })
    }else{
      setChange({});
      setChangeType(e.key);
      setPolicyPreview(undefined);
      setChangeToBeAmended(undefined);
    }
  };
  const open = (r) => {
    setChangeType(r.Discriminator);
    setChange(r);
  };
  const reload = () => {
    props.reload();
    setChangeType("notSelected");
  };
  const onAbort = (r) => {
    exe("AbortChange", { changeId: r.id }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
        reload();
      } else {
        message.error(r.msg);
      }
    });
  };
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          {["id","code","processState"].includes(dataIndex)&&<Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`${t("Search")} ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
          />}
          
          {dataIndex==="status"&&<Select
              placeholder={t("Search by status")}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e!==undefined ? [e] : [])}
              style={{ width: 188, marginBottom: 8, display: "block" }}
          >{statusCodes.map(p=><Select.Option value={p.code}>{p.name}</Select.Option>)}</Select>}  
          
          {dataIndex==="effectiveDate"&&<DatePicker.RangePicker 
              placeholder={t("Search by date")}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e!==undefined ? [e] : [])}
              style={{ width: 250, marginBottom: 8, display: "block" }}
          />}

          {dataIndex==="Discriminator"&&<Select
              placeholder={t("Search by type")}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e!==undefined ? [e] : [])}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >{getChangesMenu().props.children.filter(p=>p.key).map(p=><Select.Option value={p.key}>{p.props.children[1]}</Select.Option>)}</Select>}

          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            {t("Search")}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {t("Reset")}
          </Button>
        </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value,record)=>onFilter(value,record,dataIndex),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput&&searchInput.select());
      }
    },
  });
  const onFilter=(value,record,dataIndex)=>{
    if(typeof value=== 'string'){
      return record[dataIndex]?record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()):false;
    }else if(typeof value=== 'object'){
      //moment array
      return moment(record[dataIndex]).isBetween(value[0],value[1],'day',"[]");
    }else{
      //number
      return record[dataIndex]==value;
    }
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end
  const statusCodes=[
      {code:0,name:t("Proposal")},
      {code:1,name:t("Executed")},
      {code:2,name:t("Scheduled")},
      {code:3,name:t("Scheduled and Executed")},
      {code:4,name:t("Execution failed")},
      {code:5,name:t("Aborted")},
      {code:6,name:t("Amended")},
  ]
  const parseStatusCode = (statusCode) => {
    switch (statusCode) {
      case 0:
        return <Tag color="blue">{statusCodes[0].name}</Tag>;
      case 1:
        return <Tag color="green">{statusCodes[1].name}</Tag>;
      case 2:
        return (
          <Tag color="orange">
            <ClockCircleOutlined style={{ marginRight: 5 }} />
            {statusCodes[2].name}
          </Tag>
        );
      case 3:
        return (
          <Tag color="green">
            <CalendarOutlined style={{ marginRight: 5 }} />
            {statusCodes[3].name}
          </Tag>
        );
      case 4:
        return <Tag color="red">{statusCodes[4].name}</Tag>;
      case 5:
        return (
          <Tag>
            <DeleteOutlined style={{ marginRight: 5 }} />
            {statusCodes[5].name}
          </Tag>
        );
      case 6:
        return (
          <Tag color="geekblue">
            <EditOutlined style={{ marginRight: 5 }} />
            {statusCodes[6].name}
          </Tag>
        );
      default:
        return statusCode;
    }
  };
  
  const checkDisabledChange=(changeName,changeToBeAmended)=>!changesEnabled&&!["ChangeTemporalStatus","ChangeReinstatement"].includes(changeName)&&!inactivePolicyChanges.includes(changeName) || productDisabledChanges.includes(changeName) ||changeToBeAmended&&!validAmendments.includes(changeName)

  const getChangesMenu=(changeToBeAmended=undefined)=>  <Menu onClick={(e)=>onChangeType(e,changeToBeAmended)} className="smallMenu">
    {policy.policyType == "G" && (
        <Menu.Item key="AddCertificateChange" disabled={checkDisabledChange("ChangeAddCertificate",changeToBeAmended)} >
          <PlusOutlined />
          {t("Add Certificate")}
        </Menu.Item>
    )}
    {policy.policyType == "G" && (
        <Menu.Item key="RemoveCertificateChange" disabled={checkDisabledChange("ChangeRemoveCertificate",changeToBeAmended)}>
          <MinusOutlined />
          {t("Remove Certificate")}
        </Menu.Item>
    )}
    <Menu.Item key="CapitalChange" disabled={checkDisabledChange("ChangePolicyCapital",changeToBeAmended)}>
      <EuroOutlined />
      {t("Sum Insured")}
    </Menu.Item>
    <Menu.Item key="TermChange" disabled={checkDisabledChange("ChangeTerm",changeToBeAmended)}>
      <ClockCircleOutlined />
      {t("Term")}
    </Menu.Item>
    <Menu.Item key="PolicyholderChange" disabled={checkDisabledChange("ChangePolicyholder",changeToBeAmended)}>
      <SolutionOutlined />
      {t("Policyholder")}
    </Menu.Item>
    <Menu.Item key="CoInsuredChange" disabled={checkDisabledChange("ChangeCoInsured",changeToBeAmended)}>
      <TeamOutlined />
      {t("Co-Insured")}
    </Menu.Item>
    <Menu.Item key="BeneficiaryChange" disabled={checkDisabledChange("ChangeBeneficiary",changeToBeAmended)}>
      <UserOutlined />
      {t("Beneficiary")}
    </Menu.Item>
    <Menu.Item key="CessionBeneficiaryChange" disabled={checkDisabledChange("ChangeCessionBeneficiary",changeToBeAmended)}>
      <BankOutlined />
      {t("Cession Beneficiary")}
    </Menu.Item>
    <Menu.Item key="ContingentBeneficiaryChange" disabled={checkDisabledChange("ChangeContingentBeneficiary",changeToBeAmended)}>
      <UserAddOutlined />
      {t("Contingent Beneficiary")}
    </Menu.Item>
    <Menu.Item key="InvestmentChange" disabled={checkDisabledChange("ChangeInvestment",changeToBeAmended)}>
      <LineChartOutlined />
      {t("Investment Product")}
    </Menu.Item>
    <Menu.Item key="RescueChange" disabled={checkDisabledChange("ChangeRescue",changeToBeAmended)}>
      <ScissorOutlined />
      {t("Partial Surrender")}
    </Menu.Item>
    <Menu.Item key="TotalRescueChange" disabled={checkDisabledChange("ChangeTotalRescue",changeToBeAmended)}>
      <ExclamationCircleOutlined />
      {t("Total Surrender")}
    </Menu.Item>
    <Menu.Item key="MaturityChange" disabled={checkDisabledChange("ChangeMaturity",changeToBeAmended)}>
      <CloseOutlined />
      {t("Maturity")}
    </Menu.Item>
    <Menu.Item key="IndexationChange" disabled={checkDisabledChange("ChangeIndexation",changeToBeAmended)}>
      <RiseOutlined />
      {t("Indexation Option")}
    </Menu.Item>
    <Menu.Item key="ReAdjustmentChange" disabled={checkDisabledChange("ChangeReAdjustment",changeToBeAmended)}>
      <DashboardOutlined />
      {t("Re-Adjustment")}
    </Menu.Item>
    <Menu.Item key="ReEvaluationChange" disabled={checkDisabledChange("ChangeReEvaluation",changeToBeAmended)}>
      <ExperimentOutlined />
      {t("Re-Evaluation")}
    </Menu.Item>
    <Menu.Item key="AddCoverageChange" disabled={checkDisabledChange("ChangeAddCoverage",changeToBeAmended)}>
      <PlusCircleOutlined />
      {t("Attach Coverage")}
    </Menu.Item>
    <Menu.Item key="RemoveCoverageChange" disabled={checkDisabledChange("ChangeRemoveCoverage",changeToBeAmended)}>
      <MinusCircleOutlined />
      {t("Detach Coverage")}
    </Menu.Item>
    <Menu.Item key="PlanChange" disabled={checkDisabledChange("ChangePlan",changeToBeAmended)}>
      <BlockOutlined />
      {t("Plan")}
    </Menu.Item>
    <Menu.Item key="PaidUpChange" disabled={checkDisabledChange("ChangePaidUp",changeToBeAmended)}>
      <ToTopOutlined />
      {t("Paid-Up")}
    </Menu.Item>
    <Menu.Item key="PaidUpReinstatementChange" disabled={checkDisabledChange("ChangePaidUpReinstatement",changeToBeAmended)}>
      <RedoOutlined />
      {t("Paid-Up Reinstatement")}
    </Menu.Item>
    <Menu.Item key="BonusChange" disabled={checkDisabledChange("ChangeBonus",changeToBeAmended)}>
      <GiftOutlined />
      {t("Happy Event")}
    </Menu.Item>
    <Menu.Item key="FrequencyChange" disabled={checkDisabledChange("ChangeFrequency",changeToBeAmended)}>
      <CalendarOutlined />
      {t("Premium Frequency")}
    </Menu.Item>
    <Menu.Item key="PayPlanChange" disabled={checkDisabledChange("PayPlanChange",changeToBeAmended)}>
      <FormOutlined />
      {t("Installment Edition")}
    </Menu.Item>
    <Menu.Item key="PaymentMethodChange" disabled={checkDisabledChange("ChangePaymentMethod",changeToBeAmended)}>
      <EuroOutlined />
      {t("Payment Method")}
    </Menu.Item>
    <Menu.Item key="CoverageChange" disabled={checkDisabledChange("ChangeCoverage",changeToBeAmended)}>
      <UnorderedListOutlined />
      {t("Coverage Edition")}
    </Menu.Item>
    <Menu.Item key="BenefitChange" disabled={checkDisabledChange("ChangeBenefit",changeToBeAmended)}>
      <OrderedListOutlined />
      {t("Benefit Edition")}
    </Menu.Item>
  
    <Menu.Item key="IntermediaryChange" disabled={checkDisabledChange("ChangeIntermediary",changeToBeAmended)}>
      <ShoppingOutlined />
      {t("Intermediary")}
    </Menu.Item>

    <Menu.Item key="ReinstatementChange" disabled={policy.active||checkDisabledChange("ChangeReinstatement",changeToBeAmended)}>
      <RedoOutlined />
      {t("Reinstatement")}
    </Menu.Item>

    <Menu.Item key="NotTakenUpChange" disabled={checkDisabledChange("ChangeNotTakenUp",changeToBeAmended)}>
      <CloseSquareOutlined />
      {t("Not Taken Up")}
    </Menu.Item>
    <Menu.Item key="RedirectionChange" disabled={checkDisabledChange("ChangeRedirection",changeToBeAmended)}>
      <CompassOutlined />
      {t("Redirection")}
    </Menu.Item>
    <Menu.Item key="SwitchChange" disabled={checkDisabledChange("ChangeSwitch",changeToBeAmended)}>
      <SwapOutlined />
      {t("Switch")}
    </Menu.Item>
    <Menu.Item key="LoadingChange" disabled={checkDisabledChange("ChangeLoading",changeToBeAmended)}>
      <ToolOutlined />
      {t("Loading")}
    </Menu.Item>
    <Menu.Item key="ClauseChange" disabled={checkDisabledChange("ChangeClause",changeToBeAmended)}>
      <CheckSquareOutlined />
      {t("Clauses")}
    </Menu.Item>
    <Menu.Item key="ExclusionChange" disabled={checkDisabledChange("ChangeExclusion",changeToBeAmended)}>
      <StopOutlined />
      {t("Exclusions")}
    </Menu.Item>
    <Menu.Item key="InsuredObjectChange" disabled={checkDisabledChange("ChangeInsuredObject",changeToBeAmended)}>
      <HomeOutlined />
      {t("Insured Object")}
    </Menu.Item>
    <Menu.Item key="AnnuityBeneficiaryChange" disabled={checkDisabledChange("ChangeAnnuityBeneficiary",changeToBeAmended)}>
      <PullRequestOutlined />
      {t("Annuity Beneficiary")}
    </Menu.Item>
    {policy.policyType == "C" && (
        <Menu.Item key="GroupChange" ddisabled={checkDisabledChange("ChangeGroup",changeToBeAmended)}>
          <UsergroupAddOutlined />
          {t("Certificate Group")}
        </Menu.Item>
    )}
    <Menu.Item key="TemporalStatusChange" disabled={checkDisabledChange("ChangeTemporalStatus",changeToBeAmended)}>
      <ScheduleOutlined />
      {t("Temporal Status")}
    </Menu.Item>
    <Menu.Item key="InformativeChange" disabled={checkDisabledChange("ChangeInformative",changeToBeAmended)}>
      <InfoCircleOutlined />
      {t("Informative")}
    </Menu.Item>
    <Menu.Item key="PolicySurchargeChange" disabled={checkDisabledChange("ChangePolicySurcharge",changeToBeAmended)}>
      <PercentageOutlined />
      {t("Surcharges and Discounts")}
    </Menu.Item>
    <Menu.Item key="CancellationChange" disabled={checkDisabledChange("ChangeCancellation",changeToBeAmended)}>
      <DeleteOutlined />
      {t("Cancel")}
    </Menu.Item>
  </Menu>
  const getCommonInfo=()=>({
    isAmendment:policyPreview!==undefined,
    changeName:changeType,
    allowPastEffectDate: allowPastEffectDateInChanges.includes(changeType),
    toServer:{changeIdToBeAmended:changeToBeAmended?changeToBeAmended.id:undefined},
    currency:policy.currency});
  return (
    <div style={{ padding: 5 }}>
      {changeType == "notSelected" && (
        <div>
          <Dropdown
            trigger={["click"]}
            overlay={getChangesMenu()}>
            <Button type="primary" icon={<PlusOutlined />}>
              {t("New")} <DownOutlined />
            </Button>
          </Dropdown>

          <Table dataSource={data} style={{ marginTop: 5 }} loading={loading}>
            <Table.Column
              title={t("Id")}
              {...getColumnSearchProps("id")}
              dataIndex="id"
              render={(v) => (window.location.hash.indexOf("/tab12?" + v) > -1 ? <Typography.Text mark>{v}</Typography.Text> : v)}
            />
            <Table.Column title={t("Status")} dataIndex="status" render={(v) => parseStatusCode(v)} {...getColumnSearchProps("status")} />
            <Table.Column title={t("Effect Date")} dataIndex="effectiveDate" render={(v) => formatDate(v)} {...getColumnSearchProps("effectiveDate")}/>
            <Table.Column title={t("Code")} dataIndex="code" {...getColumnSearchProps("code")} />
            <Table.Column title={t("Type")} dataIndex="Discriminator" {...getColumnSearchProps("Discriminator")} />
            <Table.Column
              dataIndex="Process"
              title={t("Workflow")}
              {...getColumnSearchProps("processState")}
              render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => reload()}  />}
            />
            <Table.Column
              title={t("Actions")}
              render={(v, r) => (
                <div style={{whiteSpace:"nowrap"}}>
                  <Button type="link" onClick={() => open(r)}>
                    {t("Open")}
                  </Button>
                  <Divider type="vertical" />
                  <Popconfirm title={t("Are you sure you want to abort this change?")} onConfirm={() => onAbort(r)} disabled={r.status !== 0 && r.status !== 2}>
                    <Button type="link" disabled={r.status !== 0 && r.status !== 2}>
                      {t("Abort")}
                    </Button>
                  </Popconfirm>
                  <Divider type="vertical" />
                  <Dropdown
                      trigger={["click"]}
                      disabled={![0,2].includes(r.status)||r.Process&&r.Process.entityState != "APROVED"}
                      overlay={
                        getChangesMenu(r)
                      }>
                    <Button type="link" icon={<PlusCircleOutlined />} style={{ paddingLeft: 0 }} >
                      {t("Amend")}
                    </Button>
                  </Dropdown>
                  <Divider type="vertical" />
                  <Button type="link" icon={<CameraOutlined />} onClick={() => setSnapshot(r.jSnapshot)} disabled={!r.jSnapshot}>
                    {t("Snapshot")}
                  </Button>
                </div>
              )}
            />
          </Table>
        </div>
      )}
      <Modal title={t("Policy Snapshot Before Change")} visible={snapshot !== null} onOk={() => setSnapshot(null)} onCancel={() => setSnapshot(null)} width="900px">
        <PolicySnapshot value={snapshot} parsedValues config={config} />
      </Modal>
      {changeType !== "notSelected" && (
        <Button type="link" icon={<LeftOutlined />} onClick={() => setChangeType("notSelected")}>
          {t("Back")}
        </Button>
      )}
      {changeType == "CapitalChange" && (
        <div>
          <ChangeCapital
            policyId={policyId}
            currentCapital={insuredSum}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo(changeType)}
          />
        </div>
      )}
      {changeType == "TermChange" && (
        <div>
          <ChangeTerm
            policyId={policyId}
            currentStart={moment.isMoment(policy.start)?policy.start:moment(policy.start)}
            currentEnd={moment.isMoment(policy.end)?policy.end:moment(policy.end)}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "BeneficiaryChange" && (
        <div>
          <ChangeBeneficiary
            policyId={policyId}
            currentBeneficiaries={policy.Beneficiaries}
            currency={currency}
            value={change}
            policy={policy}
            config={config}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
            configProfile={props.configProfile}
          />
        </div>
      )}
      {changeType == "CoInsuredChange" && (
        <div>
          <ChangeCoInsured
            policyId={policyId}
            currentCoInsureds={policy.Coinsureds}
            currency={currency}
            value={change}
            policy={policy}
            config={config}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
            configProfile={props.configProfile}
          />
        </div>
      )}
      {changeType == "ClauseChange" && (
        <div>
          <ChangeClause
            policyId={policyId}
            currentClauses={policy.Clauses}
            currency={currency}
            value={change}
            policy={policy}
            config={config}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "ExclusionChange" && (
        <div>
          <ChangeExclusion
            policyId={policyId}
            currentExclusions={policy.Exclusions}
            currency={currency}
            value={change}
            policy={policy}
            config={config}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "InvestmentChange" && (
        <div>
          <ChangeInvestment
            policyId={policyId}
            currentInvestment={currentInvestment}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "RedirectionChange" && (
        <div>
          <ChangeRedirection
            policyId={policyId}
            currentInvestment={currentInvestmentSelection}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
            config={config}
          />
        </div>
      )}
      {changeType == "SwitchChange" && (
        <div>
          <ChangeSwitch
            policyId={policyId}
            currentInvestment={currentInvestmentSelection}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
            config={config}
          />
        </div>
      )}
      {changeType == "PolicyholderChange" && (
        <div>
          <ChangePolicyholder
            policyId={policyId}
            policy={policy}
            config={config}
            currentPolicyholder={policy.holderId}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "CancellationChange" && (
        <div>
          <ChangeCancellation
            policyId={policyId}
            currentStatus={currentStatus}
            currency={currency}
            value={change}
            reload={reload}
            config={config}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "TemporalStatusChange" && (
          <div>
            <ChangeTemporalStatus
                policyId={policyId}
                currentStatus={policy.active}
                currency={currency}
                value={change}
                reload={reload}
                config={config}
                anniversary={policy.nextAnniversary}
                common={getCommonInfo()}
            />
          </div>
      )}
      {changeType == "RescueChange" && (
        <div>
          <ChangeRescue policyId={policyId} Accounts={policy.Accounts} currency={currency} value={change} reload={reload} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "TotalRescueChange" && (
        <div>
          <ChangeTotalRescue policyId={policyId} Accounts={policy.Accounts} currency={currency} value={change} reload={reload} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "MaturityChange" && (
        <div>
          <ChangeMaturity policyId={policyId} Accounts={policy.Accounts} currency={currency} value={change} reload={reload} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "IndexationChange" && (
        <div>
          <ChangeIndexation
            policyId={policyId}
            currentIndexation={policy.indexation}
            currentIndexationPeriod={policy.indexationPeriod}
            currentIndexationStart={policy.indexationStart}
            currentIndexationFrequency={policy.indexationFrequency}
            currentJIndexationPlan={policy.jIndexationPlan}
            duration={policy.duration}
            coverages={policy.Coverages}
            currency={currency}
            anniversary={policy.nextAnniversary}
            value={change}
            reload={reload}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "ReAdjustmentChange" && (
        <div>
          <ChangeReAdjustment
            policyId={policyId}
            config={config}
            currentReAdjustment={policy.reAdjustment}
            duration={policy.duration}
            coverages={policy.Coverages}
            currency={currency}
            anniversary={policy.nextAnniversary}
            value={change}
            reload={reload}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "ReEvaluationChange" && (
        <div>
          <ChangeReEvaluation
            policyId={policyId}
            config={config}
            currentReEvaluation={policy.reEvaluation}
            duration={policy.duration}
            coverages={policy.Coverages}
            currency={currency}
            anniversary={policy.nextAnniversary}
            value={change}
            reload={reload}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "IntermediaryChange" && (
        <div>
          <ChangeIntermediary
            policyId={policyId}
            currentIntermediary={policy.comContractId}
            currentSellerId={policy.sellerId}
            currentComPercentage={policy.comPercentage}
            currentJComParticipants={policy.jComParticipants}
            multipleRoles={props.multipleRoles}
            currency={currency}
            anniversary={policy.nextAnniversary}
            value={change}
            reload={reload}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "InformativeChange" && (
          <div>
            <ChangeInformative
                selectableSegments={selectableSegments}
                policyId={policyId}
                currentSegment={policy.segment}
                currentChannel={policy.channel}
                currentBranchCode={policy.branchCode}
                currency={currency}
                anniversary={policy.nextAnniversary}
                value={change}
                reload={reload}
                common={getCommonInfo()}
            />
          </div>
      )}
      {changeType == "AddCertificateChange" && (
        <div>
          <ChangeAddCertificate
            policyId={policyId}
            policy={policy}
            config={config}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "AddCoverageChange" && (
        <div>
          <ChangeAddCoverage
            policyId={policyId}
            policy={policy}
            currentCoverages={policy.Coverages}
            config={config}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "RemoveCertificateChange" && (
        <div>
          <ChangeRemoveCertificate
            policyId={policyId}
            policy={policy}
            config={config}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "RemoveCoverageChange" && (
        <div>
          <ChangeRemoveCoverage
            policyId={policyId}
            policy={policy}
            currentCoverages={policy.Coverages}
            config={config}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "PlanChange" && (
        <div>
          <ChangePlan
            policyId={policyId}
            currentPlan={policy.plan}
            config={config}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "PaidUpChange" && (
        <div>
          <ChangePaidUp
            policyId={policyId}
            config={config}
            Accounts={policy.Accounts}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "BonusChange" && (
        <div>
          <ChangeBonus
            policyId={policyId}
            config={config}
            Accounts={policy.Accounts}
            currency={currency}
            value={change}
            reload={reload}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "FrequencyChange" && (
        <div>
          <ChangeFrequency policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "PayPlanChange" && (
          <div>
            <ChangePayPlan policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
          </div>
      )}
      {changeType == "CoverageChange" && (
        <div>
          <ChangeCoverage policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "BenefitChange" && (
          <div>
            <ChangeBenefit policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
          </div>
      )}
      {changeType == "LoadingChange" && (
        <div>
          <ChangeLoading policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "PolicySurchargeChange" && (
        <div>
          <ChangePolicySurcharge policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "PaymentMethodChange" && (
        <div>
          <ChangePaymentMethod policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "CessionBeneficiaryChange" && (
        <div>
          <ChangeCessionBeneficiary policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "ContingentBeneficiaryChange" && (
          <div>
            <ChangeContingentBeneficiary policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
          </div>
      )}
      {changeType == "AnnuityBeneficiaryChange" && (
        <div>
          <ChangeAnnuityBeneficiary policy={policy} config={config} currency={currency} value={change} reload={reload} anniversary={policy.nextAnniversary} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "NotTakenUpChange" && (
        <div>
          <ChangeNotTakenUp policy={policy} config={config} currency={currency} value={change} reload={reload} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "InsuredObjectChange" && (
        <div>
          <ChangeInsuredObject policy={policy} config={config} currency={currency} value={change} reload={reload} common={getCommonInfo()} />
        </div>
      )}
      {changeType == "ReinstatementChange" && (
        <div>
          <ChangeReinstatement
            policyId={policyId}
            inactiveDate={policy.inactiveDate}
            currentStatus={currentStatus}
            currency={currency}
            value={change}
            reload={reload}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "PaidUpReinstatementChange" && (
        <div>
          <ChangePaidUpReinstatement
            policyId={policyId}
            inactiveDate={policy.inactiveDate}
            currentStatus={policy.paidUp}
            currency={currency}
            value={change}
            reload={reload}
            common={getCommonInfo()}
          />
        </div>
      )}
      {changeType == "GroupChange" && (
        <div>
          <ChangeGroup
            policyId={policyId}
            currentPlan={policy.plan}
            config={config}
            currency={currency}
            value={change}
            reload={reload}
            currentGroup={policy.group}
            anniversary={policy.nextAnniversary}
            common={getCommonInfo()}
            groupPolicyId={policy.groupPolicyId}
          />
        </div>
      )}
      {changeType == "loan" && <div>Not enabled</div>}
    </div>
  );
};

export default Changes;
