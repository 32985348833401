import React from "react";
import { UploadOutlined } from '@ant-design/icons';

const PaymentLink = (props) => {
    const paymentId = props.paymentId || props.value;
    if (!paymentId) return "N/A";
    return (
        <div style={{ whiteSpace: "nowrap" }}>
            <a href={"#/payments/" + paymentId}>
                <UploadOutlined style={{ marginRight: 5 }} />
                {paymentId}
            </a>
        </div>
    );
};

export default PaymentLink;
