import React from "react";
import { ReconciliationOutlined } from '@ant-design/icons';

const TransactionLink = (props) => {
    const transactionId=props.value;
    if (!transactionId) return "N/A";
    return (
        <div style={{ whiteSpace: "nowrap" }}>
            <a href={"#/accounting/tx?id=" + transactionId}>
                <ReconciliationOutlined style={{ marginRight: 5 }} />
                {transactionId}
            </a>
        </div>
    );
};

export default TransactionLink;
