import { Modal, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const UserGroups = (props) => {
  const [t] = useTranslation();
  return (
    <div>
      <Modal title={t("Group Membership")} visible={props.visible} onCancel={() => props.onCancel()} onOk={() => props.onOk()}>
        <Table dataSource={props.value ? props.value.Groups : undefined} pagination={false} size="middle">
          <Table.Column title={t("Group Id")} dataIndex="usrGroupId" />
          <Table.Column title={t("Group Name")} dataIndex="name" />
        </Table>
      </Modal>
    </div>
  );
};

export default UserGroups;
