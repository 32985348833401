import { Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const PolicyTypeSelect = (props) => {
  const [t] = useTranslation();

  const options = props.options || ["I", "G", "C"];

  useEffect(() => {
    if (!props.allowClear && options.indexOf(props.value) == -1) props.onChange(options[0]); //first item by default. used in issuance
  }, [options.length]);

  return (
    <div>
      <Select
        value={props.value}
        onChange={props.onChange}
        placeholder={t("Plase select policy type")}
        disabled={options.length == 1}
        allowClear={props.allowClear}>
        <Select.Option value="I" disabled={options.indexOf("I") == -1}>
          {t("Individual Policy")}
        </Select.Option>
        <Select.Option value="G" disabled={options.indexOf("G") == -1}>
          {t("Group Policy")}
        </Select.Option>
        <Select.Option value="C" disabled={options.indexOf("C") == -1}>
          {t("Certificate")}
        </Select.Option>
      </Select>
    </div>
  );
};

export default PolicyTypeSelect;
