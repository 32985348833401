import {message, Select, Tag} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import {formatDateShort} from "../../Lib/Helpers";

const ContractCodeSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [t] = useTranslation();

    useEffect(() => load(), []);

    const load = () => {
        setLoading(true);
        exe("GetContractCodes", { operation: "GET" }).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData.result);
            } else {
                message.error(r.msg);
            }
        });
    };
    return (
        <div>
            <Select
                placeholder={t("Please select contract code")}
                value={props.value}
                onChange={props.onChange}
                style={props.style}
                loading={loading}
                disabled={props.disabled}
                allowClear
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.value.toLowerCase().includes(input.toLowerCase())}
            >
                {data.map((p) => (
                    <Select.Option value={p.code} key={p.code}>
                        {p.code} <span style={{fontSize:9}}>{p.name}</span>
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default ContractCodeSelect;
