import React, {Component, useEffect, useState} from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Divider, message, Input } from "antd";
import { exe } from "Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import { formatDate, formatDateShort } from "../../Lib/Helpers";
import {useTranslation, withTranslation} from "react-i18next";
import CoContractListFilter from "./CoContractListFilter";

const ButtonGroup = Button.Group;
const { Column } = Table;

const CoContractList =props=> {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState(false);
    const [searchText, setSearchText] = useState();
    const [searchedColumn, setSearchedColumn] = useState();
    const [t]=useTranslation();
    
    useEffect(()=>{
        load();
    },[]);
    const load=()=>{
        exe("RepoCoContract", {operation:"GET", include: ["Contact"] }).then((r) => {
        console.log(r);
        if (r.ok) {
            setData(r.outData);
        } else {
            message.error(r.msg);
        }
    });
    }
    

    //table search ini
    let searchInput;
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(ele) => (searchInput = ele)}
                    placeholder={`${t("Search")} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    {t("Search")}
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    {t("Reset")}
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
        onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText( selectedKeys ? selectedKeys[0] : "");
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    //table search end


        return (
            <DefaultPage
                title={t("Coinsurance Contracts")}
                icon="bars"
                loading={loading}
                extra={
                    <div>
                        <ButtonGroup>
                            <Button type="primary" onClick={() => setFilter(true)} icon={<SearchOutlined />}>
                                {t("Filter")}
                            </Button>
                        </ButtonGroup>
                    </div>
                }>
                <Table dataSource={data}>
                    <Column title={t("Id")} dataIndex="id" {...getColumnSearchProps("id")}/>
                    <Column title={t("Contract Name")} dataIndex="name" render={(v, r) => <a href={"#/coContract/" + r.id}>{v}</a>} {...getColumnSearchProps("name")} />
                    <Column title={t("Contract Code")} dataIndex="code" {...getColumnSearchProps("code")}/>
                    <Column title={t("Company")} dataIndex="contactId" render={(v, r) => <a href={"#/contact/" + v}>{r.Contact ? r.Contact.FullName : "-"}</a>} />
                    <Column title={t("Direction")} dataIndex="inward" render={(field) => (field ? "Inward" : "Outward")} />
                    <Column title={t("Active")} dataIndex="active" render={(v) => (v ? <Tag color="green">Active</Tag> : <Tag>Draft</Tag>)} />
                    <Column title={t("Effective")} dataIndex="effectiveDate" render={(v, r) => (v ? formatDateShort(v) : "-")} />
                    <Column title={t("End")} dataIndex="endDate" render={(v, r) => (v ? formatDateShort(v) : "-")} />

                </Table>
                <CoContractListFilter
                    visible={filter}
                    onClose={() => {
                        setFilter(false);
                    }}
                    onSearch={(d) => setData(d)}
                />
            </DefaultPage>
        );
    
}
export default CoContractList;
