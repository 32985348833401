import React, {useState, useEffect} from "react";
import DefaultPage from "../Shared/DefaultPage";
import {useTranslation} from "react-i18next";
import { Icon as LegacyIcon } from '@ant-design/compatible';

import {
    CheckOutlined,
    DeleteOutlined,
    FileOutlined,
    FilePdfOutlined,
    FolderOpenOutlined,
    ReloadOutlined,
    UndoOutlined,
    WarningOutlined,
} from '@ant-design/icons';

import {
    Badge,
    Button,
    Divider,
    Dropdown,
    message,
    Menu,
    Popconfirm,
    Result,
    Table,
    Tabs,
    Tag,
    Tooltip,
    Modal,
    Statistic,
} from "antd";
import { formatDateShortUtc, formatMoney, getConfigProfile} from "../../Lib/Helpers";
import {exe, safeGet, safeGetRaw} from "../../Lib/Dal";
import WorkspaceDetail from "./WorkspaceDetail";
import Status from "../Shared/Status";
import TransferList from "../Transfers/TransferList";
import IncomeTransfer from "./IncomeTransfer";
import CollectionData from "../Collection/CollectionData";
import AllocationTask from "../Transfers/AllocationTask";
import CashierPayments from "./CashierPayments";
import GroupCollection from "./GroupCollection";
import OtherReceivables from "./OtherReceivables";
import DepositControl from "./DepositControl";
import CashFunds from "./CashFunds";

const Workspaces = (props) => {
    const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [newWorkspace, setNewWorkspace] = useState(false);
    const [selected,setSelected]=useState();
    const [selectedRowKeys,setSelectedRowKeys]=useState();
    const [refreshList, doRefreshList] = useState(0);
    const [refreshPremiums, doRefreshPremiums] = useState(0);
    const [refreshOther, doRefreshOther] = useState(0);
    const [selectedTab,setSelectedTab]=useState("workspaces");
    const [selectedPremiums,setSelectedPremiums]=useState([]);
    const [selectedTransfers,setSelectedTransfers]=useState([]);
    const [allocationVisible,setAllocationVisible]=useState(false);
    const [saveAllocation,doSaveAllocation]=useState(0);
    const [resetAllocation,doResetAllocation]=useState(0);
    const [defaultCurrency,setDefaultCurrency]=useState();
    const [selectedPayment,setSelectedPayment]=useState();
    const [reports,setReports]=useState([]);
    const [groupMode,setGroupMode]=useState(false);
    const [selectedOtherReceivables,setSelectedOtherReceivables]=useState([]);
    const [isSupervisor,setIsSupervisor]=useState(false);
    const [allowDateDefinition,setAllowDateDefinition]=useState(false);
    const [cashFundControl,setCashFundControl]=useState(false);
    const [premiumTableKey, setPremiumTableKey] = useState(0);
    const [warning,setWarning]=useState();
    
    useEffect(()=>{
        getConfigProfile().then((profile) => {
            setDefaultCurrency(safeGet(["Main","defaultCurrency"], profile, ""));
            const allReports=safeGet(["Cashier","reports"], profile, []);
            //filtering reports by user group if has group property set
            const userGroups=safeGet(["GLOBAL","currentUser","Groups"], window, []);
            const userReports=[];
            allReports.forEach(report=>{
                //checking if the report is specific to a group
                if(report.group){
                    const belongsToGroup=userGroups.find(p=>p.name===report.group);
                    if(belongsToGroup) userReports.push(report);
                }else userReports.push(report);
            })
            setReports(userReports);

            setAllowDateDefinition(safeGetRaw(["Cashier","allowDateDefinition"], profile, false));
            const supervisorGroupId=safeGet(["Cashier","supervisorGroupId"], profile, 0);
            if(supervisorGroupId>0) {
                //check if user belongs to that group
                const userGroups=safeGet(["GLOBAL","currentUser","Groups"], window, []);
                const belongsToGroup=userGroups.find(p=>p.usrGroupId===supervisorGroupId);
                if(belongsToGroup) setIsSupervisor(true);else setIsSupervisor(false);
            }else setIsSupervisor(false);
            setCashFundControl(safeGetRaw(["Cashier","cashFundControl"], profile, false));
        });
        load();
    },[]);

    //branch report filtering, once workspace is selected
    useEffect(()=>{
        if(selected){
            reports.forEach(report=>{
                //checking if the report is specific to a branchCode
                if(report.branchCode){
                    if(report.branchCode!==selected.branchCode){
                        //removing report from list
                        const newReports=reports.filter(p=>p!==report);
                        setReports(newReports);
                    }
                }
            })
        }
    },[selected])
    
    useEffect(()=>{
        //selecting workspace id passed in url
        if(props.match.params.workspaceId&&data.length>0){
            const workspace=data.find(p=>p.id==props.match.params.workspaceId);
            if(workspace) onOpenWorkspace(workspace);
        }
    },[props.match.params.workspaceId,data.length])
    
    useEffect(()=>{
        //selecting tab passed in url
        if(props.match.params.tab) {
            changeTab(props.match.params.tab.replace("tab", ""));
        }
    },[props.match.params.tab])
    
    
    const load = () => {
        setLoading(true);
        exe("RepoTransferWorkspace", {operation: "GET",include:["Branch"]}).then(r => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else message.error(r.msg);
        })
    }
    const onNewWorkspace = (values) => {
        console.log(values);
        setNewWorkspace(false);
        setLoading(true);
        exe("RepoTransferWorkspace",{operation:"ADD",entity:values}).then(r=>{
            setLoading(false);
            if(r.ok){
                load();
            }else message.error(r.msg);
        })
    }
    const onOpenWorkspace=(workspace)=>{
        console.log(workspace,"open");
        setSelected(workspace);
        setSelectedRowKeys([workspace.id]);
        //updating url
        if(props.match.params.workspaceId!==workspace.id.toString()) window.history.replaceState(null, null, "#/cashier/"+workspace.id);
    }
    const onRemoveWorkspace=(workspace)=>{
        setLoading(true);
        exe("RepoTransferWorkspace",{operation:"DELETE",entity: {id:workspace.id}}).then(r=>{
            setLoading(false);
            if(r.ok){
                load();
            }else message.error(r.msg);
        })}
    const onCloseWorkspace=(workspace)=>{
        setLoading(true);
        exe("CloseTransferWorkspace",{workspaceId:workspace.id}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(t("Workspace closed"));
                setSelected(undefined);
                setSelectedRowKeys([]);
                load();
            }else message.error(r.msg);
        })}
    const onReopenWorkspace=(workspace)=>{
        setLoading(true);
        exe("OpenTransferWorkspace",{workspaceId:workspace.id}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(t("Workspace reopened"));
                setSelected(r.outData.workspace)
                setSelectedRowKeys([workspace.id])
                load();
            }else message.error(r.msg);
        })}
    
    const rowSelection = {
        selectedRowKeys,
        onChange: (keys,rows)=>onOpenWorkspace(rows[0]),
        type: 'radio'
    };
    const renderWorkspaceTitle=(workspace)=>{
        return `${t("Cashier")} ${workspace.id}.${formatDateShortUtc(workspace.date)}.${workspace.Branch&&workspace.Branch.name}`;
    }
    const renderStatus = (value, record) => {
        switch (value) {
            case false:
                return <Tag>{t("Open")}</Tag>;
            case true:
                return <Tag color="green">{t("Closed")}</Tag>;
            default:
                return null;
        }
    };
    const onNewTransfer=()=>{
        doRefreshList(refreshList + 1);
        setSelectedTab("transfers");
    }
    const transferRowSelection = {
        selectedRowKeys:selectedTransfers.map(p=>p.id),
        onChange: (selectedRowKeys, selectedRows) => {
            if (!selectedRows) return;
            if (selectedRows.some((p) => p.currency !== selectedRows[0].currency)) {
                message.error(t("Currencies must be of same type"));
                return;
            }
            setSelectedTransfers(selectedRows);
        },
    }
    const premiumRowSelection = {
        preserveSelectedRowKeys:true,
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
            setSelectedOtherReceivables([]);
            //to preserve selected rows between pagination changes
            const currentlySelectedRows=selectedPremiums.filter(p=>selectedRowKeys.find(q=>q===p.id));
            setSelectedPremiums([...currentlySelectedRows,...selectedRows.filter(p=>!currentlySelectedRows.find(q=>q.id===p.id))]); 
        },
        getCheckboxProps: (record) => ({
            disabled: !record.pending,
        }),
        onSelectAll:(selected, selectedRows, changeRows)=>{
            if(!selected) setSelectedPremiums([]); //clearing selections
        }
    };
    const otherReceivablesRowSelection={
        selectedRowKeys:selectedOtherReceivables.map(p=>p.key),
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
            setSelectedPremiums([]);
            setSelectedOtherReceivables(selectedRows);
        },
        getCheckboxProps: (record) => ({
            disabled: record.executed,
        }),
    }
    const onNewWorkspaceClick=()=>{
        setSelectedTab("workspaces");
        setNewWorkspace(true)
    }
    
    const onDraftAllocation=(draft)=>{
        setLoading(false);
        setAllocationVisible(true);
        setWarning(draft.warning);
        console.log(draft);
    }
    
    const onConfirmAllocation=()=>{
        setLoading(true);
        doSaveAllocation(c=>c+1);
    }
    
    const onAllocation=()=>{
        setAllocationVisible(false);
        setLoading(false);
        doRefreshList(c=>c+1); //transfer list
        doRefreshPremiums(c=>c+1);//premium list
        setSelectedTransfers([]);
        setSelectedPremiums([]);
        doResetAllocation(c=>c+1);
        setSelectedTab("transfers");
        setPremiumTableKey(c=>c+1); //resetting premium table key to force refresh internal select state
    }
   const onOpenPayment=transfer=>{
       setSelectedPayment(transfer);
       setSelectedTab("income");
       console.log(transfer);
   }
   const onOtherExecuted=id=>{
        setSelectedOtherReceivables([]);
        doRefreshOther(c=>c+1);//other receivables
   }
   
   const changeTab=(tab)=> {
        props.checkTabPermissions(`/cashier/tab${tab}`).then(authorized=>{
            if(authorized){
                setSelectedTab(tab);
                changeHash(tab); 
            }else message.error(t("Your user does not have permission to access this tab"));
        })
   }
   const changeHash=(tab)=>{
       let newHash = window.location.hash;
       newHash = newHash.split("/tab")[0] + "/tab" + tab;
       window.history.replaceState(null, null, newHash);
   }
    return (
        <div>
            <DefaultPage
                title={selected?renderWorkspaceTitle(selected):t("Cashier")}
                icon="folder"
                routes={{
                    routes: [
                        { breadcrumbName: t("Home"), path: "/" },
                        { breadcrumbName: t("Cashier"), path: "cashier" },
                    ],
                }}
                extra={<div style={{display:"flex",alignItems:"center"}}>
                    {isSupervisor&&<Tag color={"geekblue"}><LegacyIcon type={"crown"} style={{marginRight:3}} />{t("Supervisor")}</Tag>}
                    {selectedPremiums.length>0&&selectedPremiums.every(p=>p.currency===selectedPremiums[0].currency)&&<Statistic title={t("Selected Premiums")} value={formatMoney(selectedPremiums.reduce((p,c)=>p+c.pendingAmount,0),selectedPremiums[0].currency)} />}
                    {selectedOtherReceivables.length>0&&selectedOtherReceivables.every(p=>p.currency===selectedOtherReceivables[0].currency)&&<Statistic title={t("Other Receivables")} value={formatMoney(selectedOtherReceivables.reduce((p,c)=>p+c.total,0),selectedOtherReceivables[0].currency)} />}
                        <Button style={{marginLeft:15}} type="primary" icon={<FileOutlined />} onClick={onNewWorkspaceClick }>{t("New")}</Button>
            </div>}
            >
                <Tabs activeKey={selectedTab} onChange={(v)=>changeTab(v)}>
                    <Tabs.TabPane tab={t("Workspaces")} key="workspaces">
                        {!newWorkspace && <div>
                            <Button type="link" icon={<FileOutlined />} onClick={() => setNewWorkspace(true)}>{t("New")}</Button>
                            <Button type="link" icon={<ReloadOutlined />} onClick={() => load()}>{t("Reload")}</Button>
                            <Table dataSource={data} loading={loading} rowKey="id" rowSelection={rowSelection}>
                                <Table.Column title={t("id")} dataIndex="id"/>
                                <Table.Column title={t("Date")} dataIndex="date" render={v => formatDateShortUtc(v)}/>
                                <Table.Column title={t("Branch")} dataIndex={["Branch","name"]}/>
                                <Table.Column title={t("User")} dataIndex="user"/>
                                <Table.Column title={t("Status")} dataIndex="closed" render={(v,r)=>renderStatus(v,r) }/>
                                <Table.Column title={t("Actions")} key="actions" render={(v,r)=><div>
                                    <Button type="link" icon={<FolderOpenOutlined />} onClick={()=>onOpenWorkspace(r)}>{t("Select")}</Button>
                                    {!r.closed&&<span>
                                    <Divider type="vertical" />
                                   <Popconfirm title={t("Are you sure you want to remove this workspace?")} onConfirm={()=>onRemoveWorkspace(r)}>
                                        <Button type="link" icon={<DeleteOutlined />}>{t("Remove")}</Button>
                                    </Popconfirm>
                                    </span>}
                                    <Divider type="vertical" />
                                    {!r.closed&&<Button type="link" icon={<CheckOutlined />} onClick={()=>onCloseWorkspace(r)}>{t("Close")}</Button>}
                                    {r.closed&&<Button type="link" icon={<UndoOutlined />} onClick={()=>onReopenWorkspace(r)}>{t("Reopen")}</Button>}
                                    
                                </div>}/>
                            </Table>
                        </div>}
                        {newWorkspace &&
                            <WorkspaceDetail onOk={onNewWorkspace} 
                                             onCancel={() => setNewWorkspace(false)}
                                             cashFundControl={cashFundControl}
                                             allowDateDefinition={allowDateDefinition&&isSupervisor}/>
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<div>{t("Premiums")}<Badge count={selectedPremiums.length} showZero style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset',marginLeft:3 }}/>  </div>} key="premiums" disabled={!selected||selected&&selected.closed}>
                        <div style={{display:groupMode?"none":"block"}}>
                            <CollectionData rowSelection={premiumRowSelection} refresh={refreshPremiums} setGroupMode={((v)=>setGroupMode(v)) } key={premiumTableKey} />
                        </div>
                        <div style={{display:groupMode?"block":"none"}}>
                            <GroupCollection rowSelection={premiumRowSelection} refresh={refreshPremiums} setGroupMode={((v)=>setGroupMode(v))} key={premiumTableKey+1} />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<div>{t("Other")}<Badge count={selectedOtherReceivables.length} showZero style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset',marginLeft:3 }}/>  </div>} key="other" disabled={!selected||selected&&selected.closed}>
                        <OtherReceivables rowSelection={otherReceivablesRowSelection} refresh={refreshOther} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("New Income")} key="income" disabled={!selected||selected&&selected.closed} forceRender>
                        <IncomeTransfer onNewManualTransfer={onNewTransfer} workspaceId={selected&&selected.id}  disabled={selected&&selected.closed} defaultCurrency={defaultCurrency} currencyDate={selected&&selected.date} selectedPayment={selectedPayment} onNew={()=>setSelectedPayment(undefined)} selectedPremiums={selectedPremiums} selectedOtherReceivables={selectedOtherReceivables} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<div>{t("Movements")}<Badge count={selectedTransfers.length} showZero style={{ backgroundColor: '#fff', color: '#999', boxShadow: '0 0 0 1px #d9d9d9 inset',marginLeft:3 }}/>  </div>} key="transfers" disabled={!selected}>
                        <Dropdown overlay={<Menu>
                            {reports.map(p=><Menu.Item><a href={`#/reportview/${p.report}/workspaceId=${selected&&selected.id}&transferId=${selectedTransfers&&selectedTransfers.length?JSON.stringify(selectedTransfers.map(p=>p.id)):"[0]"}`}> {p.name}</a></Menu.Item> )}
                        </Menu>}>
                            
                            <Button type="link" icon={<FilePdfOutlined />} style={{float:"left"}}>{t("Reports")}</Button>
                        </Dropdown>
                        <TransferList refresh={refreshList} workspaceId={selected&&selected.id}  rowSelection={transferRowSelection} workspace readOny={selected&&selected.closed} onOpenPayment={t=>onOpenPayment(t)} onOtherExecuted={onOtherExecuted}  />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Allocation")} key="allocation" disabled={!selected||selected&&selected.closed}>
                        <AllocationTask
                            selectedTransfers={selectedTransfers}
                            selectedPremiums={groupMode?selectedPremiums.flatMap(p=>p.Installments):selectedPremiums}
                            onAllocation={onAllocation}
                            setLoading={()=>setLoading(true)}
                            onDraftAllocation={onDraftAllocation}
                            doSave={saveAllocation}
                            workspaceId={selected&&selected.id}
                            doReset={resetAllocation}
                        />
                        <Modal title={t("Allocation")} visible={allocationVisible} footer={null}>
                            <Result
                                status="success"
                                title={t("Successful validation")}
                                subTitle={warning?<><WarningOutlined style={{marginRight:5,marginLeft:5}} />{warning}</>:<span>{t("Please confirm operation")}</span>}
                                extra={[
                                    <Button type="primary" key="confirm" onClick={onConfirmAllocation} loading={loading}>
                                        {t("Confirm")}
                                    </Button>,
                                    <Button key="cancel" onClick={()=>setAllocationVisible(false)}>{t("Cancel")}</Button>,
                                ]}
                            />
                        </Modal>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Balance")} key="balance" disabled={!selected}>
                        <CashierPayments workspaceId={selected&&selected.id} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Deposit Control")} key="depositControl" disabled={!isSupervisor}>
                        <DepositControl workspaceId={selected&&selected.id} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Cash Funds")} key="cashFunds" disabled={!isSupervisor||!cashFundControl}>
                        <CashFunds  />
                    </Tabs.TabPane>
                </Tabs>
            </DefaultPage>
        </div>
    );
};

export default Workspaces;
