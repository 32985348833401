import React, {useState} from 'react';
import {Button, Skeleton, Table, Tooltip} from "antd";
import Operations from "../Financial/Operations";
import DefaultPage from "../Shared/DefaultPage";
import Status from "../Shared/Status";
import {getColor} from "../../Lib/Helpers";
import {useTranslation} from "react-i18next";
import {safeGetRaw} from "../../Lib/Dal";

const SsAccounts = (props) => {
    const [loadingOperations,setLoadingOperations]=useState(true);
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();

    return (
        <div>
            <DefaultPage
                title={ t("My Accounts")}
                subTitle={<span>{t("Banking Products")}</span>}
                iconStyle={{ backgroundColor: safeGetRaw(["Style","primaryColor"],props.configProfile,"rgb(69,156,84)") }}
                routes={{
                    routes: [
                        { breadcrumbName: t("Home"), path: "/SelfService/home" },
                        { breadcrumbName: t("Accounts"), path: "accounts" },
                    ],
                }}
                icon="global"
                loading={loading}
                extra={<div><Button type={"primary"}>{t("Options")}</Button> </div>}>

                <Table dataSource={props.accounts} rowKey={"id"}>
                    <Table.Column dataIndex={"accNo"} title={t("Account Number")} />
                    <Table.Column dataIndex={"type"} title={t("Type")} />
                    <Table.Column dataIndex={"code"} title={t("Code")} />
                </Table>
            </DefaultPage>
        </div>
    );
};

export default SsAccounts;