import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import getCache from "../../Lib/Cache";

const WorkflowStateSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("GetWorkflowStates",{entity:"Contact"}).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData.states);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select style={props.style}
        value={props.value||undefined}
        onChange={props.onChange}
        loading={loading}
        showSearch
        optionFilterProp="children"
        allowClear>
        {data.map((p) => (
          <Select.Option value={p} key={p}>
            {t(p)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default WorkflowStateSelect;
