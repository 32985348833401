import React from "react";
import { Select } from "antd";
import { safeValue } from "../../Lib/Dal";

const GroupSelect = (props) => {
  const groups=safeValue("config.Main.certificateGroups", props, ["DEFAULT"]).concat(props.groups?props.groups:[]);//concat product groups with custom groups
    return <div>
      <Select style={{ width: "100%" }} value={props.value} onChange={props.onChange} disabled={props.disabled}>
        {groups.map((group) => (
          <Select.Option key={group} value={group}>
            {group}
          </Select.Option>
        ))}
      </Select>
    </div>
};

export default GroupSelect;
