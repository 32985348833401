import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
const RecentSelect=props=>{
    const [items,setItems]=useState([]);
    const [selectedKey,setSelectedKey]=useState();
    const [t]=useTranslation();

    useEffect(()=>{
        if(props.variable) load()},[props.variable]);

    const load=()=>{
        //checking if local storage varaible is set
        if(localStorage.getItem(props.variable)){
            //if set, parse de json array
            const arr=JSON.parse(localStorage.getItem(props.variable));
            setItems(arr.map((item,index)=>({key:item.key ,label:(<a onClick={(e)=>{e.preventDefault();setSelectedKey(item.key)}}>{item.label}</a>)})));

        }
    }
    useEffect(()=>{
      //need to do it his way because if done above, old version of data state is used (stale closure)
      if(selectedKey) props.onSelect(selectedKey)
    },[selectedKey])

    return <Dropdown
    menu={{items}}>
    <a onClick={(e) => e.preventDefault()}>
      <Space>
        {t("Recent")}
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
}
export default RecentSelect;