import React, { useState } from "react";
import Title from "antd/lib/typography/Title";
import { CheckCircleOutlined, ShoppingOutlined, SolutionOutlined } from '@ant-design/icons';
import { Select, Button, Steps, Tabs, Skeleton, Carousel, message } from "antd";

import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import PolicySelectStep from "./PolicySelectStep";
import ClaimInfoStep from "./ClaimInfoStep";
import SubmissionStep from "./SubmissionStep";

const SelfServiceClaim = (props) => {
    const Step = Steps.Step;
    const [t]=useTranslation();
    const [current, setCurrent] = useState(0);
    const [policyId,setPolicyId]=useState();
    const [productConfig,setProductConfig]=useState({});
    const [loading,setLoading]=useState(false);
   const [success,setSuccess]=useState();
   const [resultMsg,setResultMsg]=useState();

    const steps=["selection","claimInfo","submission"];
    

    const onChange = (newCurrent) => {
        setCurrent(newCurrent);
    };
    const onPolicySelect=(v,opt)=>{
        setPolicyId(v);
        setLoading(true);
        exe("RepoProduct",{operation:"GET",filter:`code='${opt.props.record.productCode}'`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setProductConfig(JSON.parse(r.outData[0].configJson));
            }else message.error(r.msg);
        })
    }
    const onStartClaim = () => {
        setCurrent(1);
    };
    const onFinishClaimInfo=info=>{
        console.log("Sending claim",info);
        setLoading(true);
        exe("StartNewClaim",{policyId:policyId,claimInfo:info}).then(r=>{
            setLoading(false);
            setSuccess(r.ok);
            setResultMsg(r.msg)
            setCurrent(2);
            if(r.ok){
                props.reload();
            }
        })
    }
  
    const onRestart=()=> {
        setPolicyId(undefined)
        setCurrent(0);
    }

    return (
        <div style={{ height: "100%", width: 800, marginLeft: "auto", marginRight: "auto" }}>
            <Steps type="navigation" current={current} onChange={onChange} style={{marginBottom:50}}>
                <Step title={t("Policy Selection")} icon={<ShoppingOutlined />}></Step>
                <Step title={t("Claim Info")} icon={<SolutionOutlined />} />
                <Step title={t("Submission")} icon={<CheckCircleOutlined />} />
            </Steps>

            {steps[current] === "selection" && (
                <div>
                    <Title>{t("Submit your claim easily and comfortably")}</Title>
                    <Title level={4}>{t("Select a policy to start")} </Title>
                    <PolicySelectStep size="large" style={{ width: 300, marginBottom: 5 }} onChange={onPolicySelect} value={policyId} contact={props.contact}  />
                    <br />
                    <Button type="primary" size="large" onClick={onStartClaim}>
                        {t("Start Claim")}
                    </Button>
                </div>
            )}
            {steps[current] === "claimInfo" && <ClaimInfoStep productConfig={productConfig} onFinish={onFinishClaimInfo} loading={loading} />}
            {steps[current] === "submission" && <SubmissionStep success={success} msg={resultMsg} restart={onRestart} />}
        </div>
    );
};

export default SelfServiceClaim
