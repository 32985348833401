import React, {useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
    Avatar,
    Button,
    Card,
    Col,
    Progress,
    Row,
    Skeleton,
    Table,
    Tooltip,
    Typography,
} from "antd";
import Operations from "../Financial/Operations";
import DefaultPage from "../Shared/DefaultPage";
import Status from "../Shared/Status";
import {getColor} from "../../Lib/Helpers";
import {useTranslation} from "react-i18next";
import promoImage from "./travelinsurance.svg";

const SsHome = (props) => {
    const [loadingOperations,setLoadingOperations]=useState(true);
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    const userName=props.name;
    return (
        <div style={{padding:20}}>
            <Typography.Title>{t("Hello")}, {userName}</Typography.Title>
            <Row gutter={16}>
                <Col span={12}>
                 
                </Col>
                <Col span={12}>
                    
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Typography.Title level={3}>{t("Recent Transactions")}</Typography.Title>
                    <Table>

                    </Table>

                    
                  
                </Col>
                <Col span={12}>
               {/* <Card style={{width:300}} bordered={false}>
                    <Card.Meta
                        avatar={<Icon type={"heart"} theme={"twoTone"} style={{fontSize:28}} />}
                        title="Term Life Policy"
                        description="Extra Family Plan"
                    />
                    <div style={{display:"flex",alignItems:"center"}}>
                        <Progress type="circle" percent={75} style={{marginTop:15,marginRight:15}} />
                        <div>
                            <Typography.Title level={4}>21 days to renewal</Typography.Title>
                            <Button type={"link"} icon={"folder-open"} onClick={()=>window.location.hash="/SelfService/policies"} style={{padding:0}}>{t("Open")} </Button>
                        </div>
                    </div>
                </Card>*/}

                </Col>
            </Row>
            <Row gutter={16} style={{marginTop:30}}>
                <Col span={12}>
                    <div style={{display:"flex"}}>
                    <img src={promoImage} style={{width:300}} />
                        <div>
                            <Typography.Title level={3}>{t("Protect your next vacation in 3 simple steps")}</Typography.Title>
                            <p>{t("Vacation insurance is an important consideration during the leisure travel planning process, and it covers the items most critical for individuals on vacation, including emergency assistance, medical coverage, cancellation, travel delays and more")}</p>
                            <Button type={"primary"} onClick={()=>window.location.hash="/SelfService/buy"} >{t("Get started")}<LegacyIcon type={"right"} /> </Button>
                            
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                 
                </Col>
            </Row>
        </div>
    );
};

export default SsHome;