import React, {useState} from 'react';
import {exe, safeGet} from "../../Lib/Dal";
import {useTranslation} from "react-i18next";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, message, Table} from "antd";
import CalcSheetGrid from "./CalcSheetGrid";

const CalcView = (props) => {
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    const data=safeGet(["CalcViews"],props.config,[]);
    const onOpen=(record)=>{
      
        if(!record.chainId){
            message.error(t("No chainId provided in config"));
            return;
        }
        if(!record.calcSheetId){
            message.error(t("No calcSheetId provided in config"));
            return;
        }
        setLoading(true);
        exe("ExeChain",{chainId:record.chainId,context:JSON.stringify({id:props.policyId})}).then(r=>{
            setLoading(false);
            if(r.ok){
            
                const computedData=r.outData;
                setLoading(true);
                exe("RepoCalcSheet", { operation: "GET", filter: "id=" + record.calcSheetId }).then((r) => {
                    setLoading(false);
                    if (r.ok) {
                        try {
                            const calcSheetObject=JSON.parse(r.outData[0].jData);
                            window.luckysheet.create({...calcSheetObject,...{showtoolbar:false,showsheetbar:false,hook:{
                                        workbookCreateAfter:function(){
                                            //value substitution
                                            for (let i = 0; i <30 ; i++) {
                                                for (let j = 0; j < 30; j++) {
                                                    const celValue=window.luckysheet.getCellValue(i,j);
                                                    if(celValue&&celValue.toString().startsWith("$")){
                                                        window.luckysheet.setCellValue(i,j,computedData[i][j])
                                                    }
                                                }
                                            }
                                        }
                                    }}});
                            setSelected(record);
                            
                        }catch (e){
                            message.error("Error computing calcview. Please see console.");
                            console.error(e);
                        }
                       
                    } else {
                        message.error(r.msg);
                    }
                });
                
            }else message.error(r.msg);
        })
    }    
    return (
        <div>
            {!selected&&<Table dataSource={data} rowKey={"name"} loading={loading}>
                <Table.Column title={t("Name")} dataIndex={"name"} />
                <Table.Column title={t("Description")} dataIndex={"description"} />
                <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"folder-open"} />} onClick={()=>onOpen(r)}>{t("Open")}</Button> </div>} />
            </Table>}
            <div style={{display:selected?"block":"none"}}>
                <Button type={"link"} icon={<LegacyIcon type={"left"} />} onClick={()=>setSelected(null)} >{t("Back")}</Button>
                <div  style={{width:"100%",height:"800px"}} >
                    <CalcSheetGrid />
                </div>
            </div>
        </div>
    );
};

export default CalcView;