import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {Button, message, Popconfirm, Select, Tooltip,Space} from "antd";
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const SelectionSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.visible&&props.entity) load();
    },[props.visible]) //refresh when visible 
    
    const load=()=>{
        setLoading(true);
        exe("RepoSelection",{operation:"GET",filter:`entity='${props.entity}'`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
            }else message.error(r.msg)
        })
    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoSelection",{operation:"DELETE",entity:{id:props.value}}).then(r=>{
            setLoading(false);
            if(r.ok){
                props.onChange(undefined);
                load();
            }else message.error(r.msg);
        })
    }

    return (
        <div style={{display:"flex"}}>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled} loading={loading}>
              {data.map(p=><Select.Option value={p.id} key={p.id}>{p.name}</Select.Option> )}
          </Select>
          <Space>
            <Tooltip title={t("Delete Selected Record")} placement='left'>
                <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} okText={t("Yes")} cancelText={t("No")} disabled={!props.value}  >
                    <Button type="link" icon={<DeleteOutlined />} disabled={!props.value}   />  
                </Popconfirm>
            </Tooltip>
            {props.showView&&<Tooltip title={t("View Selection Items")} placement='left'>
                <div style={{display:"flex"}}>
                    <a href={`#/cessions?savedSelectionId=${props.value}`} disabled={!props.value} ><EyeOutlined /></a> 
                </div>
                </Tooltip>}
          </Space>
          
        </div>
    );
};

export default SelectionSelect;