import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const DistributionModeSelect = (props) => {
  const [t, i18n] = useTranslation();
  return (
    <div>
      <Select value={props.value} onChange={(v) => props.onChange && props.onChange(v)} style={{ width: "100%" }}>
        <Select.Option value={0}>{t("Standard")}</Select.Option>
        <Select.Option value={1}>{t("Accrued end of month")}</Select.Option>
      </Select>
    </div>
  );
};

export default DistributionModeSelect;
