import { Button, Form, InputNumber, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
const FastForm=props=>{
    const [t,i18n]=useTranslation();
    const [form]=Form.useForm();

    const onOk=()=>{
        console.log("onOk");
        //validating fields
        form.validateFields().then(values=>{
            console.log(values);
            props.onOk(values);
        })
    }
    const renderControls=()=>{
        return props.controls.map((control,i)=>{
            return <Form.Item key={i} label={t(control.label)} name={control.name} rules={control.rules}>
                {control.component}
            </Form.Item>
        })
    }
    return <div><Form form={form}>
        {renderControls()}
         {/* <Form.Item label={t("Paid Amount")} name={"paid"} rules={[{required:true}]}><InputNumber  style={{width:"100%"}} /></Form.Item> */}
        </Form>
    <Button type="primary" onClick={onOk}>{t("Ok")}</Button></div>
}
export default FastForm;