import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Checkbox,
    Divider,
    Input,
    InputNumber,
    message,
    Modal,
    Popconfirm,
    Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {exe, safeGet, safeGetRaw} from "../../Lib/Dal";
import { getConfigProfile } from "../../Lib/Helpers";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import AccountSelect from "../Shared/AccountSelect";

const ProfitCommissionParticipantDetail = (props) => {
    const field = props.form.getFieldDecorator;
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation();
    const [contactRoles, setContactRoles] = useState([]);

    useEffect(() => {
        if (props.selected) {
            props.form.setFieldsValue(props.selected);
        }
    }, [props.selected]);

    useEffect(() => {
        getConfigProfile().then((profile) => {
            setContactRoles(safeGet(["Reinsurance", "contactRoles"], window.global.configProfile, []));
        });
    }, []);
    

    const onSubmit = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            values.profitCommissionId=props.profitCommissionId;
            setLoading(true);
            exe("RepoProfitCommissionParticipant", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
                setLoading(false);
                if (r.ok) {
                    props.onSave && props.onSave(values);
                    props.form.resetFields();
                } else {
                    message.error(r.msg);
                }
            });
        });
    };
    const onCancel = () => {
        props.form.resetFields();
        props.onCancel && props.onCancel();
    };

    return (
        <div>
            <Modal title={t("Participant")} visible={props.visible} onCancel={onCancel} onOk={onSubmit} okButtonProps={{ loading: loading }}>
                {field("id")}
                {field("contractId", { initialValue: props.contractId })}
                <Form.Item label={t("Participant")}>
                    {field("contactId", { rules: [{ required: true }] })(
                        <SimpleContactSelect  allowNew
                                             filter={
                                                 contactRoles.length == 0
                                                     ? undefined
                                                     : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                                             }
                        />
                    )}
                </Form.Item>
                <Form.Item label={t("Split %")}>{field("split", { rules: [{ required: true }] })(<InputNumber />)}</Form.Item>
                <Form.Item label={t("Account")}>
                    {field("accountId", { rules: [{ required: false }] })(<AccountSelect style={{width:"100%"}} />)}
                </Form.Item>
                

            </Modal>
        </div>
    );
};

export default Form.create()(ProfitCommissionParticipantDetail);
