import { message, Modal, Transfer } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const GroupOrganizations = (props) => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const onChange = (nextTargetKeys, direction, moveKeys) => {
        setTargetKeys(nextTargetKeys);
    };

    useEffect(() => loadOrganizations(), []);
    useEffect(() => load(), [props.record]);

    const loadOrganizations = () => {
        exe("RepoOrganization", { operation: "GET" }).then((r) => {
            if (r.ok) setData(r.outData);
            else message.error(r.msg);
        });
    };

    const load = () => {
        if (props.record && props.record.Organizations) setTargetKeys(props.record.Organizations.map(p=>p.organizationId));
        else setTargetKeys([]);
    };

    const save = () => {
        setLoading(true);
        exe("SetGroupOrganizations", { groupId: props.record.id, organizationIds:targetKeys }).then((r) => {
            setLoading(false);
            if (r.ok) {
                message.success(t("Organizations updated"));
                props.onUpdate();
            } else message.error(r.msg);
        });
    };

    return (
        <div>
            <Modal title={t("Organization Permissions")} visible={props.visible} onCancel={props.onCancel} onOk={save} width="800px" okButtonProps={{ loading: loading }}>
                <Transfer
                    dataSource={data}
                    titles={[t("Deny"), t("Allow")]}
                    rowKey={(record) => record.id}
                    onChange={onChange}
                    targetKeys={targetKeys}
                    render={(item) => `${item.name}`}
                    listStyle={{
                        width: 300,
                        height: 300,
                    }}
                />
            </Modal>
        </div>
    );
};

export default GroupOrganizations;
