import React, { useState, useEffect } from "react";
import { EnterOutlined, PlusOutlined, UnlockOutlined, WarningOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Input,
  Table,
  Button,
  Descriptions,
  Select,
  message,
  notification,
  Tag,
} from "antd";
import FormLabel from "../Shared/FormLabel";
import Money, { formatter } from "../Shared/Money";
import { exe, safeGetRaw } from "../../Lib/Dal";
import { formatDate, getConfigProfile } from "../../Lib/Helpers";
import Paragraph from "antd/lib/typography/Paragraph";
import { useTranslation } from "react-i18next";
import PayoutRequest from "./PayoutRequest";
import PendingPremiums from "./PendingPremiums";
import LongTextColumn from "../Shared/LongTextColumn";
import ClaimExpenseSelect from "./ClaimExpenseSelect";

const PolicyCoverageClaim = (props) => {
  const [t, i18n] = useTranslation();
  //state
  const [data, setData] = useState([]);
  const [newOperation, setNewOperation] = useState(false);
  const [updatedDeductible, setUpdatedDeductible] = useState(null);
  const [requestPayoutVisible, setRequestPayoutVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [automaticReserve, setAutomaticReserve] = useState(false);
  const [doPendingPremiumsReload, setDoPendingPremiumsReload] = useState(0);
  const [showExpenseTypes, setShowExpenseTypes] = useState(false);

  useEffect(() => {
    setData(props.coverage.payouts || [])
    getConfigProfile().then(profile => {
      const showExpenseTypes = safeGetRaw(["Claim", "showExpenseTypes"], profile, false);
      setShowExpenseTypes(showExpenseTypes);
    })
  }, [props.coverage.payouts]);
  //dependencies
  const form = props.form;
  const field = props.form.getFieldDecorator;
  const coverage = props.coverage;
  const money = formatter(props.currency);
  const claimId = props.claimId;
  const currency = props.currency;
  const refreshParent = props.doRefresh;
  //commands
  const onExecute = () => {
    form.validateFields((err, values) => {
      if (err) return;
      values.lifePolicyId = coverage.lifePolicyId;
      values.claimId = claimId;
      values.lifeCoverageId = coverage.id;
      if (values.operation == "RESERVE") onReserve(values);
      else onPay(values);
    });
  };
  const onReserve = (values) => {
    values.reserved = values.amount;
    values.payed = 0;
    exe("RepoLifeCoveragePayout", { operation: "ADD", entity: values }, undefined, true).then((r) => {
      if (r.ok) {
        setData([...data, ...r.outData]);
        if (r.opt && r.opt.length) setUpdatedDeductible(+r.opt);
        form.resetFields();
        setNewOperation(false);
        refreshParent();
      }
    });
  };
  const onPay = (values) => {
    values.payed = values.amount;
    values.reserved = -values.payed;
    if (values.reserveType !== "EX" && (values.payed > reserved) || values.reserveType === "EX" && (values.payed > reservedExpenses)) {
      notification.error({
        message: t("Invalid Payment"),
        description: t("Payment cannot be greater than the reserved amount. Action canceled."),
      });
      return;
    }
    exe("RepoLifeCoveragePayout", { operation: "ADD", entity: values }).then((r) => {
      if (r.ok) {
        if (r.opt && r.opt.length) setUpdatedDeductible(+r.opt);
        setData([...data, ...r.outData]);
        form.resetFields();
        setNewOperation(false);
        refreshParent();
        setDoPendingPremiumsReload(doPendingPremiumsReload + 1);
        message.success(r.msg);
      } else message.error(r.msg);
    });
  };
  const onAprove = (id) => {
    exe("DoPayment", { lifeCoveragePayoutId: id }, undefined, true).then((r) => {
      if (r.ok) {
        const updatedData = data.map((x) => (x.id === id ? { ...x, status: 1 } : x));
        setData(updatedData);
        refreshParent();
      }
    });
  };
  const onUndo = (id) => {
    exe("UndoPayment", { lifeCoveragePayoutId: id }, undefined, true).then((r) => {
      if (r.ok) {
        const updatedData = data.map((x) => (x.id === id ? { ...x, status: 2 } : x));
        setData(updatedData);
        refreshParent();
      }
    });
  };
  //calulated values
  const reserved = data.filter(p => p.reserveType !== "EX").reduce((sum, x) => sum + x.reserved, 0);
  const payed = data.filter(p => p.reserveType !== "EX").reduce((sum, x) => sum + x.payed, 0);
  const reservedExpenses = data.filter(p => p.reserveType === "EX").reduce((sum, x) => sum + x.reserved, 0);
  const payedExpenses = data.filter(p => p.reserveType === "EX").reduce((sum, x) => sum + x.payed, 0);
  const opeations = [
    { name: t("Reserve"), value: "RESERVE" },
    { name: t("Payment"), value: "PAY" },
  ];
  const getRecordTag = (v, r) => {
    if (r.payed == 0) return null;
    if (v == 0) {
      return <Tag>{t("Pending")}</Tag>;
    } else if (r.status == 1) {
      return <Tag color="green">{t("Approved")}</Tag>;
    } else if (r.status == 2) {
      return <Tag color="red">{t("Reverted")}</Tag>;
    } else if (r.status == 3) {
      return <Tag color="#87d068">{t("Executed")}</Tag>;
    } else {
      return null;
    }
  };
  const getRecordActions = (id, r) => {
    if (r.payed == 0) return null;
    if (r.status == 0) {
      return (
        <Button type="link" onClick={() => onAprove(id)}>
          {t("Approve")}
        </Button>
      );
    } else if (r.status == 1) {
      return (
        <Button type="link" onClick={() => onUndo(id)}>
          {t("Undo")}
        </Button>
      );
    } else if (r.status == 2) {
      return (
        <Button type="link" onClick={() => onAprove(id)}>
          {t("Approve")}
        </Button>
      );
    } else {
      return null;
    }
  };
  const onRequestPayout = () => {
    form.validateFields((err, values) => {
      if (err) return;
      values.claimId = claimId;
      values.lifePolicyId = coverage.lifePolicyId;
      values.lifeCoverageId = coverage.id;
      setLoading(true);
      exe("RepoPayoutRequest", { operation: "ADD", entity: values }).then(r => {
        setLoading(false);
        if (r.ok) {
          setRequestPayoutVisible(true);
          form.resetFields();
        } else message.error(r.msg);
      })

    });

  }
  const onRequestApproved = () => {
    setRequestPayoutVisible(false);
    refreshParent();
    form.resetFields();
  }
  const onSelectOperation = (v) => {
    if (v === "RESERVE") {
      //checking if automatic reserve is configured
      if (props.config) {
        //getting coverage config
        const covConfig = props.config.Coverages.find(p => p.code === props.coverage.code);
        if (covConfig && covConfig.automaticReserve) {
          setAutomaticReserve(true);
          //setting type to indemnity by default
          form.setFieldsValue({ reserveType: "IN" });
          //making request to command to get the reserve amount
          setLoading(true);
          exe("GetAutomaticReserve", { claimId: claimId, policyId: coverage.lifePolicyId, coverageId: coverage.id }).then(r => {
            setLoading(false);
            if (r.ok) {
              form.setFieldsValue({ amount: r.outData.reserve, concept: t("Automatic reserve") });
            } else message.error(r.msg);
          });


        } else setAutomaticReserve(false);
      }
    } else {
      //disabling automatic reserve
      setAutomaticReserve(false);
      form.setFieldsValue({ concept: undefined, amount: undefined });
    }
  }
  const onSelectReserveType = (v) => {
    if (values.operation === "RESERVE") {
      if (v === "EX") {
        //disabling automatic reserve for expenses
        setAutomaticReserve(false);
        form.setFieldsValue({ concept: undefined, amount: undefined });
      } else {
        //checking if automatic reserve is configured
        onSelectOperation(values.operation);
      }
    }
  }

  const values = props.form.getFieldsValue();
  return (
    <div>
      <Form>
        <Row gutter={16}>
          <Col span={12}>
            <Button style={{ marginBottom: 5, display: newOperation ? "none" : "block" }} type="primary" icon={<PlusOutlined />} onClick={() => setNewOperation(true)}>
              {t("New operation")}
            </Button>
            <div style={{ display: newOperation ? "block" : "none" }}>
              <Form.Item label={t("Operation")}>
                {field(
                  "operation",
                  { rules: [{ required: true }] },
                  { initialValue: "RESERVE" }
                )(
                  <Select onSelect={onSelectOperation}>
                    {opeations.map((p) => (
                      <Select.Option value={p.value} key={p.value}>
                        {p.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item label={t("Type")}>{field("reserveType", { rules: [{ required: true }] })(<Select onSelect={onSelectReserveType}>
                <Select.Option value="IN">{t("Indemnity")}</Select.Option>
                <Select.Option value="EX">{t("Expense")}</Select.Option>
                <Select.Option value="LATEPREMIUM" disabled={values.operation == "RESERVE"}>{t("Late premiums deduction")}</Select.Option>
                <Select.Option value="PENDINGPREMIUM" disabled={values.operation == "RESERVE"}>{t("Pending premiums deduction")}</Select.Option>
              </Select>)}</Form.Item>
              <Form.Item label={t("Amount")}>{field("amount", { rules: [{ required: true }] })(<Money currency={currency} disabled={automaticReserve} />)}</Form.Item>
              <Form.Item label={t("Concept")}>{field("concept", { rules: [{ required: true }] })(<Input disabled={automaticReserve} />)}</Form.Item>
              <div style={{ display: (showExpenseTypes && values.reserveType == "EX") ? "block" : "none" }}>
                <Form.Item label={t("Expense Type")}>{field("expenseType", { rules: [{ required: false }] })(<ClaimExpenseSelect disabled={automaticReserve} />)}</Form.Item>
              </div>
              <Button style={{ marginBottom: 5 }} type="primary" icon={<EnterOutlined />} onClick={() => onExecute()}>
                {t("Execute")}
              </Button>
              <Button style={{ marginLeft: 5, marginBottom: 5 }} icon={<UnlockOutlined />} onClick={() => onRequestPayout()} loading={loading}>
                {t("Request Authorization")}
              </Button>
              <Button style={{ marginLeft: 5, marginBottom: 5 }} icon={<LegacyIcon type={"folder-open"} />} type={"link"} onClick={() => setRequestPayoutVisible(true)} >
                {t("Authorizations")}
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <Descriptions title={t("Coverage Situation")} column={2}>
              <Descriptions.Item label={t("Limit")}>
                {reserved + payed > coverage.limit ? (
                  <span style={{ color: "red" }}>
                    <WarningOutlined />
                    {money.format(coverage.limit)}
                  </span>
                ) : (
                  money.format(coverage.limit)
                )}
              </Descriptions.Item>
              <Descriptions.Item label={t("Reserved")}>{money.format(reserved)}</Descriptions.Item>
              <Descriptions.Item label={t("Expenses Reserve")}>{money.format(reservedExpenses)}</Descriptions.Item>
              <Descriptions.Item label={t("Payments")}>{money.format(payed)}</Descriptions.Item>
              <Descriptions.Item label={t("Expenses Payments")}>{money.format(payedExpenses)}</Descriptions.Item>
              <Descriptions.Item label={t("Deductible")}>{money.format(updatedDeductible !== null ? updatedDeductible : coverage.deductible)}</Descriptions.Item>
            </Descriptions>
            <PendingPremiums policyId={coverage.lifePolicyId} doReload={doPendingPremiumsReload} />
            <div>{t("Conditions")}:</div>
            <LongTextColumn value={coverage.description} rows={2} />
          </Col>
        </Row>
      </Form>
      <Table dataSource={data} rowKey="id">
        <Table.Column dataIndex="id" title={t("Id")} />
        <Table.Column dataIndex="date" title={t("Date")} render={(v) => formatDate(v)} />
        <Table.Column dataIndex="user" title={t("User")} />
        <Table.Column dataIndex="concept" title={t("Concept")} />
        <Table.Column dataIndex="reserved" title={t("Reserved")} render={(v, r) => r.reserveType === "EX" ? <span style={{ color: "blue", whiteSpace: "nowrap" }}>{money.format(v)}</span> : <span style={{ whiteSpace: "nowrap" }}>{money.format(v)}</span>} />
        <Table.Column dataIndex="payed" title={t("Paid")} render={(v) => <span style={{ whiteSpace: "nowrap" }}>{money.format(v)}</span>} />
        <Table.Column dataIndex="deductible" title={t("Deductible")} render={(v) => money.format(v)} />
        <Table.Column dataIndex="claimId" title={t("Claim")} />
        {showExpenseTypes && <Table.Column dataIndex="expenseType" title={t("Expense Type")} />}
        <Table.Column dataIndex="status" title={t("Status")} render={(v, r) => getRecordTag(v, r)} />
        <Table.Column dataIndex="id" title={t("Actions")} render={(v, r) => getRecordActions(v, r)} />
      </Table>
      <PayoutRequest visible={requestPayoutVisible}
        onCancel={() => setRequestPayoutVisible(false)}
        onOk={onRequestApproved}
        claimId={claimId}
        currency={props.currency} reload={() => refreshParent()} />
    </div>
  );
};

export default Form.create()(PolicyCoverageClaim);
