import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message, Divider, Popconfirm} from "antd";
import ContractReinstatementForm from "./ContractReinstatementForm";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import SubParticipantsForm from "./SubParticipantsForm";

const SubParticipants = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.participantId) load();
    },[props.participantId])
    
    const load=()=>{
        setLoading(true);
        exe("RepoSubParticipant",{operation:"GET",filter:`participantId=${props.participantId}`,include:["Contact"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    
    const onSave=values=>{
        let operation;
        if(values.id){
            operation="UPDATE";
        }else{
            operation="ADD"
            values.participantId=props.participantId;
        }
        setLoading(true);
        exe("RepoSubParticipant",{operation:operation,entity:values}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })
        
    }
    const onDelete=record=>{
        setLoading(true);
        exe("RepoSubParticipant",{operation:"DELETE",entity:record}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })

    }
    
    return (
        <div>
            {!selected&&<div>
                <Button type={"primary"} icon={<LegacyIcon type={"plus"} />} onClick={()=>setSelected({id:0})}>{t("New")}</Button>
                <Table dataSource={data} rowKey={"id"} style={{marginTop:5}}>
                    <Table.Column title={t("Participant")} dataIndex={["Contact","FullName"]} />
                    <Table.Column title={t("Split %")} dataIndex="split" />
                    <Table.Column title={t("Commission %")} dataIndex="commissionRate" />
                    <Table.Column title={t("Tax %")} dataIndex="taxRate" />
                    <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} /> </div>}/>
                </Table>
            </div>}
            {selected&&<SubParticipantsForm onBack={()=>setSelected(null)} onSave={onSave} selected={selected} onDelete={onDelete} />}
        </div>
    );
};

export default SubParticipants;