import { Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const EnvironmentLocationSelect = () => {
    const [currentEnvironment,setCurrentEnvironment]=useState("main");
    const BACKUPURL = "https://sisos-back.azurewebsites.net";
    const [t]=useTranslation();

    useEffect(()=>{
        setCurrentEnvironment(checkCurrentEnvironment());
    },[])
    const hasBackupServer = window.location.href.includes("axxis-systems.net");

    const items=[
        {
          key: "main",
          label: t("Primary Location"),
        },
        {
          key: "backup",
          label: t("Backup Location"),
          disabled: !hasBackupServer,
        }
      ]
      const checkCurrentEnvironment=()=>{
        if(localStorage.sis11api) return "backup";
        else return "main";
      }
      const onClick = (key) => {
        switch(key){
            case "main":
                localStorage.removeItem("sis11api");
                break;
            case "backup":
                localStorage.sis11api = BACKUPURL;
                break;
        }
        //checking if reload is needed
        if(currentEnvironment!==key) window.location.reload();
      }
    return <div>
               
    <Dropdown
    menu={{
    items,
    selectable: true,
    selectedKeys: [currentEnvironment],
    onClick: ({ key }) => {
      onClick(key);
    },
    }}>
    <Typography.Link>
      <Space>
        {t("Environment Location")}
        <DownOutlined />
      </Space>
    </Typography.Link>
  </Dropdown>
    </div>
}
export default EnvironmentLocationSelect;