import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, DatePicker, Drawer, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Compare from "../Shared/Compare";
import LobSelect from "../Shared/LobSelect";
import Currency from "../Shared/Currency";

const LocationCumulusFilter = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const onSearch = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onSearch(values);
    });
  };
  return (
    <Drawer title={t("Location parameters")} visible={props.visible} onClose={props.onClose} width={512}>
      <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
        <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
          {t("Reset")}
        </Button>
      </div>
      <Form>
        <Form.Item label={t("Distance Threshold")}>{field("distance")(<InputNumber placeholder={t("Distance in meters")} />)}</Form.Item>
        <Form.Item label={t("LoB")}>{field("lob")(<LobSelect />)}</Form.Item>
        <Form.Item label={t("Currency")}>{field("currency")(<Currency />)}</Form.Item>
        <Form.Item label={t("Include Proposals")}>{field("includeProposals",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()}>
          {t("Search")}
        </Button>
      </Form>
    </Drawer>
  );
};

export default Form.create({})(LocationCumulusFilter);
