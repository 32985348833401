import React, { useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { FilterOutlined } from '@ant-design/icons';
import { Button } from "antd";
import MovData from "./MovData";
import MovFilter from "./MovFilter";
import { useTranslation } from "react-i18next";

const Movements = (props) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [t, i18n] = useTranslation();

  const [data, setData] = useState([]);
  return (
    <div>
      <DefaultPage
        title={t("Movements")}
        icon="diff"
        extra={
          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        }>
        <MovData data={data} />
        <MovFilter visible={filterVisible} onClose={() => setFilterVisible(false)} onResults={(r) => setData(r)} />
      </DefaultPage>
    </div>
  );
};

export default Movements;
