import { Icon as LegacyIcon } from '@ant-design/compatible';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Table } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import config from "../../config";
import moment from "moment";
import { exe } from "../../Lib/Dal";
import Title from "antd/lib/typography/Title";
import { useTranslation } from "react-i18next";
const { Column, ColumnGroup } = Table;

const ContactRelatedDocuments = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [claimData, setClaimData] = useState([]);
  const [processData, setProcessData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.contactId) {
      load(props.contactId);
      loadClaims(props.contactId);
      loadProcessFiles(props.contactId);
    }
  }, [props.contactId]);

  const load = (contactId) => {
    setLoading(true);
    exe("RepoDocument", { operation: "GET", filter: `contactId=${contactId} OR lifePolicyId in (SELECT id from lifePolicy WHERE holderId=${contactId})` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const loadClaims = (contactId) => {
    setLoading(true);
    exe("RepoClaimDocument", { operation: "GET", filter: `contactId=${contactId} OR lifePolicyId in (SELECT id from lifePolicy WHERE holderId=${contactId})`}).then((r) => {
      setLoading(false);
      if (r.ok) {
        setClaimData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const loadProcessFiles = (contactId) => {
    setLoading(true);
    exe("GetContactsProcessFiles", { contactId:contactId}).then((r) => {
      setLoading(false);
      if (r.ok) {
        setProcessData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };

  const getIconType = (record) => {
    switch (record.name) {
      case "Uploaded":
        return "upload";
        break;
      default:
        return "file-pdf";
        break;
    }
  };
  const openFile = (v) => {
    try {
      fetch(config.ssApiUrl + "/proxy" + v, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => response.blob())
        .then((data) => window.open(URL.createObjectURL(data)))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Title level={4}>{t("Policy Documents")}</Title>
      <Table dataSource={data} scroll={{ x: true }} rowKey="id" loading={loading}>
        <Column render={(t, r) => <LegacyIcon type={getIconType(r)} style={{ fontSize: "20px" }} />} />
        <Column title={t("ID")} dataIndex="id" />
        <Column title={t("Policy Id")} dataIndex="LifePolicyid" render={(v, r) => <a href={"#/lifepolicy/" + v}>{v}</a>} />
        <Column title={t("Type")} dataIndex="name" />
        <Column
          title={t("File")}
          dataIndex="url"
          render={(v, r) => (
            <Button type="link" onClick={() => openFile(r.url)}>
              {t("Open")}
            </Button>
          )}
        />
        <Column title={t("File Name")} dataIndex="fileName" />

        <Column title={t("Created")} dataIndex="created" render={(t) => moment(t).fromNow()} />
      </Table>

      <Title level={4}>{t("Claim Documents")}</Title>
      <Table dataSource={claimData} scroll={{ x: true }} rowKey="id" loading={loading}>
        <Column render={(t, r) => <LegacyIcon type={getIconType(r)} style={{ fontSize: "20px" }} />} />
        <Column title={t("ID")} dataIndex="id" />
        <Column title={t("Claim Id")} dataIndex="claimId" render={(v, r) => <a href={"#/healthclaim/" + v}>{v}</a>} />
        <Column title={t("Type")} dataIndex="name" />
        <Column
          title={t("File")}
          dataIndex="url"
          render={(v, r) => (
            <Button type="link" onClick={() => openFile(r.url)}>
              {t("Open")}
            </Button>
          )}
        />
        <Column title={t("File Name")} dataIndex="fileName" />

        <Column title={t("Created")} dataIndex="created" render={(t) => moment(t).fromNow()} />
      </Table>

      <Title level={4}>{t("Process Documents")}</Title>
      <Table dataSource={processData} scroll={{ x: true }} rowKey="id" loading={loading}>
        <Column render={(t, r) => <UploadOutlined style={{ fontSize: "20px" }} />} />
        <Column title={t("ID")} dataIndex="id" />
        <Column title={t("Process Id")} dataIndex="procesoId" render={(v, r) => <a href={"#/activity/" + v}>{v}</a>} />
        <Column title={t("Step")} dataIndex="stepName" />
        <Column
            title={t("File")}
            dataIndex="url"
            render={(v, r) => (
                <Button type="link" onClick={() => openFile(r.url)}>
                  {t("Open")}
                </Button>
            )}
        />
        <Column title={t("File Name")} dataIndex="fileName" />

        <Column title={t("Created")} dataIndex="created" render={(t) => moment(t).fromNow()} />
      </Table>
    </div>
  );
};

export default ContactRelatedDocuments;
