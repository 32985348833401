import { Select } from "antd";
import React from "react";

const ContactTags = (props) => {
  const onChange = (value) => {
    if (value) props.onChange(value.map((p) => ({ tag: p, contactId: props.contactId }))); //denormalizing for BD
  };
  const value = (props.value || []).map((p) => p.tag); //normalizing
  return (
    <div>
      <Select value={value} mode="tags" style={{ width: "100%" }} tokenSeparators={[","]} onChange={(v) => onChange(v)} />
    </div>
  );
};

export default ContactTags;
