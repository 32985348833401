import { EditOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const AmendedChange = (props) => {
  const [t]=useTranslation();
  return (
    <div style={{display:props.value?"block":"none"}}>
          <Form.Item label={t("Amended Change")}>
            <Tag color="geekblue">  <EditOutlined style={{ marginRight: 5 }} />{props.value}</Tag>
          </Form.Item>
    </div>
  );
};

export default AmendedChange;