import React from "react";
import moment from "moment";
import DatePickerW from "../Shared/DatePickerW";

const DateField = (props) => {
  const onChange = (v) => {
    console.log(v, "CHANGE");
    if (v) {
      props.onChange(v.format("YYYY-MM-DD"));
    } else {
      props.onChange(null);
    }
  };
  return (
    <div>
      <DatePickerW {...props} onChange={onChange} value={props.value ? moment(props.value) : undefined} onOk={onChange} />
    </div>
  );
};

export default DateField;
