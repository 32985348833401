import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, message, Row, Table, Button } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import ReEventSelect from "./ReEventSelect";

const ClaimsPerEvent = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [reEventId, setReEventId] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (reEventId) load();
  }, [reEventId]);

  useEffect(() => {
    if (!props.value) {
      //resting
      setReEventId();
      setData([]);
    }
  }, [props.value]);

  const load = () => {
    setLoading(true);
    exe("GetClaimsPerEvent", { reEventId: reEventId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };

  const onChangeEvent = (v) => {
    setReEventId(v);
    if (props.onChange) props.onChange(v);
  };
  return (
    <div>
      <Row gutter={16} type="flex" align="middle">
        <Col span={8}>
          <Form.Item label={t("Catastrophic Event")}>
            <ReEventSelect value={reEventId} onChange={(v) => onChangeEvent(v)} />
          </Form.Item>
        </Col>
        <Col span={8}></Col>
      </Row>
      <Table dataSource={data} loading={loading}>
        <Table.Column title={t("Claim ID")} dataIndex="claimId" render={(v, r) => <a href={"#/healthclaim/" + v}>{v}</a>} />
        <Table.Column title={t("Claim Occurrence")} dataIndex="claimOccurrence" render={(v, r) => formatDateShort(v)} />
        <Table.Column title={t("Policy")} dataIndex="policyCode" render={(v, r) => <a href={"#/lifepolicy/" + r.lifePolicyId}>{v}</a>} />
        <Table.Column title={t("Loss")} dataIndex="loss" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Retained Loss")} dataIndex="retainedLoss" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Retained Reserve")} dataIndex="retainedReserve" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Cession Layer")} dataIndex="cessionId" />
      </Table>
    </div>
  );
};

export default ClaimsPerEvent;
