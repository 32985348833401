import React from "react";
import Viewer from "bpmn-js/lib/NavigatedViewer";
import "diagram-js-minimap/assets/diagram-js-minimap.css";
import minimapModule from "diagram-js-minimap";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";

const htmlSeleccion = '<div id="actual" style="background-color:rgb(135, 208, 104);opacity:0.9;border-radius:5px;width:25px;height:10px"></div>';
class Visor extends React.Component {
  overlayActual = null;
  componentDidUpdate() {
    if (!this.viewer) {
      this.carga();
    }
    const estadoActual = this.props.estadoActual;
    if (estadoActual) {
      //overlay
      var overlays = this.viewer.get("overlays");
      if (this.overlayActual) overlays.remove(this.overlayActual);
      try {
        this.overlayActual = overlays.add(estadoActual, {
          position: {
            top: 5,
            left: 5,
          },
          html: htmlSeleccion,
        });
      } catch (error) {}
    }
  }

  carga() {
    let bpmnXML = (this.props.actividad && this.props.actividad.Definicion.xml) || (this.props.proceso && this.props.proceso.xml);
    if (!bpmnXML) return;

    const estadoActual = this.props.estadoActual;
    const viewer = new Viewer({ container: "#canvas", additionalModules: [minimapModule] });
    this.viewer = viewer;
    var me = this;

    viewer.importXML(bpmnXML, function (err) {
      if (err) {
        console.log(err);
      } else {
        var canvas = viewer.get("canvas");
        canvas.zoom("fit-viewport");

        var a = document.getElementsByClassName("bjs-powered-by")[0];
        a.parentNode.removeChild(a);

        if (estadoActual) {
          //overlay
          var overlays = viewer.get("overlays");
          me.overlayActual = overlays.add(estadoActual, {
            position: {
              top: 5,
              left: 5,
            },
            html: htmlSeleccion,
          });
        }
      }

      window.bpmnViewer = viewer;
    });
  }
  render() {
    return (
      <div
        id="canvas"
        style={{
          //   width: "100%",
          height: this.props.fullScreen ? "100vh" : "400px",
          //   overflow: "hidden",
          //   position: "fixed"
        }}
      />
    );
  }
}
export default Visor;
