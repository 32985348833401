import React, { Component } from "react";
import { DeleteOutlined } from '@ant-design/icons';
import { InputNumber, Button } from "antd";
import { withTranslation } from "react-i18next";

class RangeSelect extends Component {
  state = {
    steps: 1,
    value0: undefined,
    step0: 1,
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== prevProps.value) {
      this.parse(this.props.value);
    }
  }

  onChange(value, step) {
    this.setState({ ["value" + step]: value }, () => this.propagate());
    this.propagate();
  }
  onChangeStep(value, step) {
    this.setState({ ["step" + step]: value }, () => this.propagate());
    this.propagate();
  }
  propagate() {
    //propagate to props (form)
    if (this.props.onChange) {
      const values = [];
      for (let index = 0; index < this.state.steps; index++) {
        values.push({ value: this.state["value" + index], step: this.state["step" + index] });
      }
      this.props.onChange(JSON.stringify(values));
    }
  }
  parse(v) {
    //from string to array
    if (!v) return;
    const values = JSON.parse(v) || [];
    let state = {};
    values.forEach((item, index) => {
      state["value" + index] = item.value;
      state["step" + index] = item.step;
      state.steps = index + 1;
    });
    this.setState({ ...state });
  }
  render() {
    const { t } = this.props;
    const arrSteps = [...Array(this.state.steps).keys()];
    return (
      <div>
        {arrSteps.map((p, i) => (
          <span key={"range" + i}>
            <InputNumber style={{ marginRight: 3, marginBottom: 3 }} onChange={(v) => this.onChange(v, p)} value={this.state["value" + p]} />
            {t("starting year")}
            <InputNumber
              style={{ width: 50, marginRight: 3, marginLeft: 3, marginBottom: 3 }}
              onChange={(v) => this.onChangeStep(v, p)}
              min={1}
              max={99}
              value={this.state["step" + p]}
            />
          </span>
        ))}
        <span>
          <Button type="link" onClick={() => this.setState({ steps: this.state.steps + 1, ["value" + this.state.steps]: this.state["value0"] })}>
            {t("Then")}
          </Button>
          <Button disabled={this.state.steps == 1} type="link" onClick={() => this.setState({ steps: this.state.steps - 1 })}>
            <DeleteOutlined></DeleteOutlined>{" "}
          </Button>
        </span>
      </div>
    );
  }
}

export default withTranslation()(RangeSelect);
