import React, {useEffect} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Select } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {useTranslation} from "react-i18next";
import moment from "moment";
import SupervisorSelect from "./SupervisorSelect";
import ReasonSelect from "../Life/ReasonSelect";
import DatePickerW from "../Shared/DatePickerW";

const ComTreeMemberChange = (props) => {
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    
    useEffect(()=>{
        if(props.selected){
            props.form.setFieldsValue(props.selected)
        }
    },[props.selected])

    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            console.log(values);
            props.onOk&&props.onOk(values);

        })
    }
    const values=props.form.getFieldsValue();
    return (
        <Modal title={t("Member Change")} visible={props.visible} onOk={onSave} onCancel={props.onCancel}>
            {field("comTreeId",{initialValue:props.comTreeId})}
            <Form.Item label={t("Change Type")}>{field("changeType",{rules:[{required:true}]})(<Select style={{width:"100%"}} allowClear>
                <Select.Option value={0}>{t("Termination")}</Select.Option>
                <Select.Option value={1}>{t("Reactivation")}</Select.Option>
            </Select>)}</Form.Item>
            <Form.Item label={t("Reason")}>{field("changeReasonId",{rules:[{required:true}]})(<ReasonSelect style={{width:"100%"}} noDefault getId catalog={values.changeType===0?"MemberTermination":"MemberReactivation"} />)}</Form.Item>
            <div style={{display:values.changeType===0?"block":"none"}}>
                <Form.Item label={t("End Date")}>{field("end",{initialValue:moment(),normalize:v=>v?moment(v):undefined,rules:[{required:values.changeType===0}]})(<DatePickerW />)}</Form.Item>
            </div>
        </Modal>
    );
};

export default Form.create()(ComTreeMemberChange);