import React, { useEffect, useState } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message, notification, Checkbox, Button } from "antd";
import { KeyOutlined } from '@ant-design/icons';
import {exe, safeGetRaw} from "../../Lib/Dal";
import {useTranslation, withTranslation} from "react-i18next";
import BranchSelect from "../Financial/BranchSelect";

const NewUser =props=>{
  const [alwaysForcePasswordChange,setAlwaysForcePasswordChange]=useState(false);
  const [disableShowPassword,setDisableShowPassword]=useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    const forcePasswordChange=safeGetRaw(["Security","alwaysForcePasswordChange"],props.configProfile,false);
    setAlwaysForcePasswordChange(forcePasswordChange);
    props.form.setFieldsValue({forcePasswordChange:forcePasswordChange});
    setDisableShowPassword(safeGetRaw(["Security","disableShowPassword"],props.configProfile,false));
  }, [props.configProfile]);

  const handleSubmit = (e) => {
    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        exe("AddOrUpdateUser", values, { operation: "ADD",allowTrigger:true }).then((r) => {
          message[r.ok ? "success" : "error"](r.msg);
          if (r.ok) {
            props.onHide();
            props.afterAdd();
          }else message.error(r.msg);
        });
      }
    });
  };
  const getRules=()=>{
    let rules=[{required:true,message:t("The password field is required")}];
    if(props.configProfile&&props.configProfile.Security&&props.configProfile.Security.passwordRules){
      rules=[...rules,...props.configProfile.Security.passwordRules]
    }
    return rules;
  }
  const onGenerateStrongPassword=()=>{
    //getting password length from password rules
    const passwordLengthRule=safeGetRaw(["Security","passwordRules"],props.configProfile,[]).find(r=>r.min);
    //generating a strong password of passwordLength random characters, special symbols, numbers, lower and upper case letters. 
    //Ensuring that the password has at least one of each type of character
    const passwordLength=passwordLengthRule?passwordLengthRule.min:10;
    const specialChar = String.fromCharCode(Math.floor(Math.random() * 15) + 33);
    const number = String.fromCharCode(Math.floor(Math.random() * 10) + 48);
    const lowerCase = String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    const upperCase = String.fromCharCode(Math.floor(Math.random() * 26) + 65);

    const basePassword = [specialChar, number, lowerCase, upperCase];
    const restLength = passwordLength - basePassword.length;

    const fullPassword = basePassword.concat(Array.from({length: restLength}, () => 
        String.fromCharCode(Math.floor(Math.random() * 94) + 33)));
    
    // Shuffle the password to avoid any predictable order
    const strongPassword = fullPassword.sort(() => Math.random() - 0.5).join('');

    props.form.setFieldsValue({clave:strongPassword});
  }
    const { getFieldDecorator } = props.form;
 
  return (
      <Modal title={t("New User")} visible={props.visible} onOk={handleSubmit} onCancel={props.onHide}>
        <Form onSubmit={handleSubmit}>
          <Form.Item label={t("E-mail")}>
            {getFieldDecorator("email", {
              rules: [
                { type: "email", message: t("Please insert a valid email") },
                { required: true, message: t("Please insert email") },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t("Name")}>
            {getFieldDecorator("nombre", {
              rules: [{ required: true, message: t("Please insert name") }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t("Password")}>
            {getFieldDecorator("clave",{rules:getRules()})( <Input.Password  placeholder={t("New Password")} visibilityToggle={!disableShowPassword}/>)}
          </Form.Item> 
          <Form.Item >
              {getFieldDecorator("forcePasswordChange", { valuePropName: "checked" })(<Checkbox disabled={alwaysForcePasswordChange}>{t("Ask user to change their password when they sign in")}</Checkbox>)}
          </Form.Item>
          <Button type="link" onClick={onGenerateStrongPassword} icon={<KeyOutlined />} style={{paddingLeft:0,paddingRight:0}}>{t("Generate a strong password")}</Button>
          <Form.Item label={t("Branch")}>
            {getFieldDecorator("branchCode",{rules:[{required:safeGetRaw(["Security","branchRequired"],props.configProfile,false)}]})( <BranchSelect />)}
          </Form.Item>
        </Form>
      </Modal>
    );
}

export default Form.create()(NewUser);
