import React from 'react';


import {post,get, put} from '../Lib/Dal';
import { AppstoreOutlined, EditOutlined, FolderOpenOutlined, SettingOutlined } from '@ant-design/icons';
import {
  Menu,
  Modal,
  Input,
  Table,
  message,
  Drawer,
  Button,
  Select,
  InputNumber,
  Card,
  Tabs,
  Row,
  Col,
} from 'antd';
import ProcesosDetalle from './ProcesosDetalle';
import VisorCalor from './VisorCalor';
import InspectorTable from './InspectorData';
import InspectorData from './InspectorData';
const { Column, ColumnGroup } = Table;
const Option=Select.Option;
const { TextArea } = Input;
const TabPane=Tabs.TabPane;

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup


class Inspector extends React.Component {
  state={
    modalNombre:false,
    modalCargar:false,
    propiedadesVisible:false,
    detalleVisible:false,
    selected:[],
    elementoSeleccionado:false,
    stats:[]
  }
  grupos=[]
    loadData(){
      get('/api/definicion/tiempos').then(r=>{
        console.log(r,'TIMEPOS');
        this.setState({stats:r});
      });

      get('/api/definicion').then(r=>{
        this.setState({data:r});
      })
    }
   
    componentDidMount(){
        this.loadData();
    }

    cargar(){
      this.setState({modalCargar:true});
       
    }
 
    handleClick = (e) => {
        console.log('click ', e);
        switch (e.key) {
            case 'guardar':
                this.guardar();
                break;
            case 'guardarComo':
                this.guardarComo();
                break;
            case 'nuevo':
                this.nuevo();
                break;
            case 'cargar':
                this.cargar();
                break;
            case 'editar':
                this.editar();
                break;
            case 'opciones':
                this.onOpciones();
                break;
            case 'detalle':
                this.onClickDetalle();
                break;
            default:
                break;
        }
      }
      onClickDetalle(){
        this.setState({detalleVisible:true});
      }
      onOpciones(){
        this.setState({
          propiedadesVisible:true,
          nombreElemento:this.state.element.businessObject.name,
          formaElemento:+this.state.element.businessObject.$attrs.form,
          grupoElemento:+this.state.element.businessObject.$attrs.grupo,
          slaElemento:+this.state.element.businessObject.$attrs.sla,
          condicionGateway:this.state.element.businessObject.$attrs.condicion
        });

      }
      handleOkModalCargar(){
        const miXML=this.state.data.find(p=>p.id==this.state.selected[0]);
        this.setState({modalCargar:false,xml:miXML.xml});
      }
      handleCancelModalCargar(){
        this.setState({modalCargar:false});
      }
    
    onClosePropiedades=()=>{
      this.setState({
        propiedadesVisible: false,
      });
    }

    asigna(valor,atributo,objeto){

      if(valor!=objeto[atributo]){
        if(valor){
          objeto[atributo]=valor;
        }else{
          delete objeto[atributo];
        }
      }
    }

    guardarElemento=()=>{
      let elem=this.state.element;

      this.asigna(this.state.nombreElemento,'name',elem.businessObject);
      this.asigna(this.state.formaElemento,'form',elem.businessObject.$attrs);
      this.asigna(this.state.condicionGateway,'condicion',elem.businessObject.$attrs);
      this.asigna(this.state.grupoElemento,'grupo',elem.businessObject.$attrs);
      this.asigna(this.state.slaElemento,'sla',elem.businessObject.$attrs);

      this.setState({element:elem, propiedadesVisible:false, nombreElemento:null});
      
      this.modeler.importDefinitions(this.modeler.getDefinitions(),()=>console.log('hecho'));
    }
    okModalDetalle=(r)=>{
      const index=this.state.data.findIndex(p=>p.id==r.id);
      const data=this.state.data;

      if(r.delete){
        delete data[index];
        this.nuevo();
        this.setState({detalleVisible:false,data:data,selected:[]});
      }else{
        data[index]=r;
        this.setState({detalleVisible:false,data:data});
      }

      
    }
    cancelModalDetalle=()=>{
      this.setState({detalleVisible:false});
    }

  render() {
    const definicion=(this.state.data&&this.state.selected[0])&&this.state.data.find(p=>p.id==this.state.selected[0]);

return (
  <div>

           <Menu style={{marginTop:'-10px', marginBottom:'20px'}} mode="horizontal"  onClick={this.handleClick} selectable={false} >
          
          <SubMenu title={<span className="submenu-title-wrapper"><AppstoreOutlined />Archivo</span>}>
            <MenuItemGroup title="Procesos">
              <Menu.Item key="cargar"><FolderOpenOutlined />Abrir</Menu.Item>
            </MenuItemGroup>
          </SubMenu>
          <Menu.Item key="opciones" disabled={!this.state.elementoSeleccionado}>
            <SettingOutlined />{this.state.element?this.state.element.type:'Opciones'}
          </Menu.Item>

          <Menu.Item key="detalle" disabled={!definicion}>
            <EditOutlined />Propiedades
          </Menu.Item>
        
        </Menu>

        <Modal
          title="Guardar Proceso"
          visible={this.state.modalNombre}
          onOk={() => this.handleOkModal()}
          onCancel={() => this.handleCancelModal()}
        >
          <Input placeholder="Inserte nombre" onChange={(v) => this.setState({ nombreProceso: v.target.value })} />
        </Modal>
        <Modal
          title="Cargar Proceso"
          visible={this.state.modalCargar}
          onOk={() => this.handleOkModalCargar()}
          onCancel={() => this.handleCancelModalCargar()}
        >
          <Table rowKey="id" showHeader={false} dataSource={this.state.data} pagination={false} 
          rowSelection={{
            selectedRowKeys: this.state.selected,
            onChange: (sel) => { this.setState({ selected: sel }) }
          }} 
          onRow={(record) => ({
            onClick: () => {
              this.setState({ selected: [record.id] });
            },
          })}>
          <Column
              title="Id"
              dataIndex="id"/>
            <Column
              title="Nombre"
              dataIndex="nombre"/>
          </Table>

        </Modal>
        <Modal
          title="Detalle del proceso"
          destroyOnClose
          visible={this.state.detalleVisible}
          onOk={() => this.formDetalle.handleSubmit()}
          onCancel={() => this.cancelModalDetalle()}
        >
          <ProcesosDetalle wrappedComponentRef={(form) => this.formDetalle = form} onOk={(r)=>this.okModalDetalle(r)} definicion={definicion} />
        </Modal>

           <Row>
              <Col md={18} xs={24}>
          <Card title="Análisis de proceso">
          <VisorCalor xml={this.state.xml} stats={this.state.stats} selected={this.state.selected[0]} />
          <Tabs defaultActiveKey="1">
                  <TabPane tab="Tiempos" key="1">
                    <InspectorData data={this.state.stats.filter(p=>p.procesoId==this.state.selected[0]&&p.estado).sort((a,b)=>b.tiempo-a.tiempo)} />
                  </TabPane>
          </Tabs>
          </Card>
          </Col>
          </Row>
          <Row>
          </Row>
          
         
          </div>
);
  }
}
export default Inspector;