import React, { useState, useEffect } from "react";
import { exe } from "../../Lib/Dal";
import { SearchOutlined } from '@ant-design/icons';
import { Select, Divider } from "antd";
import { useTranslation } from "react-i18next";

const HoldingSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.value) {
      getDisplay(props.value);
    }
  }, [props.value]);

  const getDisplay = (id) => {
    exe("GetHoldings", { filter: "id=" + id }).then((r) => {
      if (r.ok) setData([{ id: props.value, name: r.outData[0].FullName }]);
    });
  };

  const handleSearch = (v) => {
    if (!v) return;
    exe("GetHoldings", { filter: "surname2 LIKE N'%" + v + "%'" }).then((r) => {
      if (r.ok) setData((r.outData || []).map((p) => ({ ...p, name: p.FullName })));
    });
  };
  const Options = data.map((d) => (
    <Select.Option key={d.id} value={d.id}>
      {d.name}
      <Divider type="vertical" />
      <a href={"#/contact/" + d.id}>
        <SearchOutlined />
      </a>
    </Select.Option>
  ));

  return (
    <Select
      allowClear
      showSearch
      disabled={props.disabled}
      value={props.value ? props.value : undefined}
      placeholder={t("Type to search")}
      style={props.style}
      onSearch={handleSearch}
      notFoundContent={null}
      filterOption={false}
      onChange={props.onChange}>
      {Options}
    </Select>
  );
};

export default HoldingSelect;
