import React, { useState, useEffect } from "react";

import {
  BarcodeOutlined,
  DownOutlined,
  EditOutlined,
  EnterOutlined,
  LeftOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SaveOutlined,
  CheckCircleFilled
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  Table,
  Button,
  Divider,
  Tag,
  Radio,
  Dropdown,
  Menu,
  message,
  Modal,
  Input,
  Select,
  InputNumber,
  Typography,
  Popover,
  Checkbox,
} from "antd";
import { exe, safeValue } from "../../Lib/Dal";
import {formatDate, formatDateShortUtc, formatDateUtc, formatMoney, round2} from "../../Lib/Helpers";
import Barcode from "../Shared/Barcode";
import { useTranslation } from "react-i18next";
import Spreadsheet from "../Shared/Spreadsheet";
import PayPlanDetailLine from "./PayPlanDetailLine";

const PaymentPlan = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payPlanMode, setPayPlanMode] = useState("G");
  const [barcodeVisible, setBarcodeVisible] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState(undefined);
  const [barcodePremiumType, setBarcodePremiumType] = useState("E");
  const [barcodePremiumAmount, setBarcodePremiumAmount] = useState(undefined);
  const [editMode,setEditMode]=useState(props.editMode);
  const [spreadsheet,setSpreadsheet]=useState();
  const [editDetailLine,setEditDetailLine]=useState();
  const [expandedRowKeys,setExpandedRowKeys]=useState([]);
  const [showCancelled,setShowCancelled]=useState(false);
  const [showCreation,setShowCreation]=useState(false);
  const [showNormalDueDate,setShowNormalDueDate]=useState(false);
  const [showChangeId,setShowChangeId]=useState(false);

  useEffect(() => load(), [props.policyId, props.value]);
  useEffect(() => generateCode(), [barcodePremiumType, barcodePremiumAmount]);
  
  //used in pay plan change
  useEffect(()=>{
    if(props.serialized){
      const newData=JSON.parse(props.value).sort((a, b) => parseFloat(a.contractYear*100+a.numberInYear) - parseFloat(b.contractYear*100+b.numberInYear));
      setData(newData);
    }
    if(props.parsedValues){ //used in snapshot of changes
      const newData=(props.value||[]).sort((a, b) => parseFloat(a.contractYear*100+a.numberInYear) - parseFloat(b.contractYear*100+b.numberInYear));
      setData(newData);
    }
    
  },[props.value])

  const readOnly=props.readOnly;
  
  const load = () => {
    if (!props.policyId) return;
    if(props.serialized||props.parsedValues) return;
    setLoading(true);
    exe("RepoPayPlan", { operation: "GET", filter: "lifePolicyId=" + props.policyId,include:["PayPlanDetail"] }).then((r) => {
      setLoading(false);
      setData(r.outData);
    });
  };
  const loadCertificates = (mode) => {
    if (!props.policyId) return;
    setLoading(true);
    exe("GetCertificatePayPlan", { groupPolicyId: props.policyId, grouped: mode == "CG" }).then((r) => {
      setLoading(false);
      if(r.ok){
        setData(r.outData);
      }else message.error(r.msg);
    });
  };

  const onGeneratePayPlan = () => {
    setLoading(true);
    exe("MakePayPlan", { policyId: props.policyId }, null, true).then((r) => {
      setLoading(false);
      setData(r.outData);
    });
  };
  const payWithBalance = (id) => {
    setLoading(true);
    exe("DoPolicyInstallmentAllocation", { policyId: props.policyId, payPlanId: id }, null, true).then((r) => {
      setLoading(false);
      load();
    });
  };
  const onCharge = (id) => {
    setLoading(true);
    exe("DoCharge", { payPlanId: id }, null, true).then((r) => {
      setLoading(false);
      load();
    });
  };
  const onUndoCharge = (id) => {
    setLoading(true);
    exe("UndoCharge", { payPlanId: id }, null, true).then((r) => {
      setLoading(false);
      load();
    });
  };
  const onChangePolicyType = (v) => {
    setExpandedRowKeys([]);
    setPayPlanMode(v);
    if (v == "G") {
      load();
    } else {
      loadCertificates(v);
    }
  };
  const onGeneratePremiumDocument = (template, id) => {
    setLoading(true);
    exe("GeneratePremiumDoc", { template: template, lifePolicyId: props.policyId, payPlanId: id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        props.reload && props.reload();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onShowBarcode = () => {
    if (!barcodeValue) generateCode();
    setBarcodeVisible(true);
  };
  const generateCode = () => {
    if (!props.policyCode) return;
    const contractCode = props.policyCode.replace("-", "");
    const amount = barcodePremiumAmount ? barcodePremiumAmount : 0;
    const premium = round2(+amount)
      .toString()
      .replace(".", "")
      .replace(",", "")
      .padStart(8, 0);

    setBarcodeValue(contractCode + barcodePremiumType + premium);
  };
  const filteredData = showCancelled?data:data.filter((p) => !p.cancellationDate);
  const pastDate = (date) => new Date(date) < new Date();
  const totalPayed = (filteredData && filteredData.reduce((a, b) => a + b.payed, 0)) || 0;
  const totalMinimum = (filteredData && filteredData.reduce((a, b) => a + b.minimum, 0)) || 0;
  const hasPremiumPlan = props.hasPremiumPlan || false;
  const hasPenaltyInterest = props.hasPenaltyInterest || false;
  const isGroupPolicy = props.policyType == "G";
  const fiscalDocuments = safeValue("config.Documents", props, []).filter((p) => p.entity == "PREMIUM");
  const allowInstallmentEdition = safeValue("config.Premium.allowInstallmentEdition", props, false);
  const setTableStyle=(records,instance)=>{
    const styleObj={};
    for (let i = 0; i < records; i++) {
      styleObj["B"+(i+1)]="font-weight:bold;text-align:left";
      styleObj["C"+(i+1)]="text-align:left";
    }
    instance.setStyle(styleObj);
  }
  const onSaveInstallmentEdition=()=>{
    console.log(spreadsheet.getData());
    const editedInstallments=spreadsheet.getData().map(p=>({id:p[0],concept:p[1],amount:p[2],dueDate:p[3],numberInYear:p[4],contractYear:p[5]}));
    setLoading(true);
    exe("EditPayPlan",{policyId:props.policyId,Installments:editedInstallments}).then(r=>{
      setLoading(false);
      if(r.ok){
        setData(r.outData);
        setEditMode(false);
        message.success(r.msg);
      }else message.error(r.msg)
    })
  }
  const onSubmitInstallmentEdition=()=>{
    console.log(spreadsheet.getData());
    const editedInstallments=spreadsheet.getData().filter(p=>p[0]).map(p=>({id:p[0],concept:p[1],minimum:p[2],dueDate:p[3],numberInYear:p[4],contractYear:p[5]}));
    let originalInstallments=[...filteredData];
    originalInstallments.forEach(p=>{
      //comparing changes
      const edited=editedInstallments.find(q=>q.id===p.id);
      if(!edited){
        //deleted
        if(p.payed) p.error=true;
        p.edited=true;
        p.minimum=0;
      }else{
        if(p.concept!==edited.concept) {
          if(p.payed) p.error=true;
          p.concept=edited.concept;
          p.edited=true;
        }
        if(p.minimum!==edited.minimum) {
          if(p.payed) p.error=true;
          p.minimum=edited.minimum;
          p.edited=true;
        }
        if(formatDateShortUtc(p.dueDate)!==edited.dueDate) {
          if(p.payed) p.error=true;
          p.dueDate=edited.dueDate;
          p.edited=true;
        }
      }
    });
    if(originalInstallments.some(p=>p.error)){
      message.error(t("Modification of paid installments is not allowed"));
      return;
    }
    if(originalInstallments.some(p=>!p.concept||p.minimum===""||!p.dueDate||p.numberInYear===""||!p.contractYear)){
      message.error(t("Please fill all row fields"));
      console.log(originalInstallments,"ORIGINAL");
      return;
    }
    //added
    const addedInstallments=spreadsheet.getData().filter(p=>!p[0]||p[0]=='0'||p[0]==0).map(p=>({id:p[0],concept:p[1],minimum:p[2],dueDate:p[3],numberInYear:p[4],contractYear:p[5],edited:true,added:true}));
    if(addedInstallments.length>0){
      //validations
      if(addedInstallments.some(p=>!p.id)){
        message.error(t("Please use Ref=0 for new installments"));
        return;
      }
      if(addedInstallments.some(p=>!p.concept||!p.minimum||!p.dueDate||p.numberInYear===""||!p.contractYear)){
        message.error(t("Please fill all row fields"));
        console.log(addedInstallments,"ADDED");
        return;
      }
      originalInstallments=originalInstallments.filter(p=>!p.added);//removing previously added, if not, duplicates
      originalInstallments.push(...addedInstallments)
    }
    console.log("Added",addedInstallments,originalInstallments);
    
    if(originalInstallments.some(p=>p.edited)){
      console.log("Submitting...",originalInstallments)
      props.onChange&&props.onChange(JSON.stringify(originalInstallments));
      message.success(t("Changes submitted"))
    }else message.error(t("No changes detected"))
  }
  const remove=id=>{
    setLoading(true);
    exe("RepoPayPlan",{operation:"DELETE",entity:{id:id}}).then(r=>{
      setLoading(false);
      if(r.ok){
        load()
      }else message.error(r.msg);
    })
  }
  const onEditDetailLine = (v) => {
    setLoading(true);
    exe("RepoPayPlanDetail",{operation:v.id===0?"ADD":"UPDATE",entity:v}).then(r=>{
      setLoading(false);
      if(r.ok){
        load();
        setEditDetailLine(undefined);
      }else message.error(r.msg)
    })
  }
  const onDeleteDetailLine=(v)=>{
    setLoading(true);
    exe("RepoPayPlanDetail",{operation:"DELETE",entity:v}).then(r=>{
      setLoading(false);
      if(r.ok){
        load();
        setEditDetailLine(undefined);
      }else message.error(r.msg)

    })
  }
  const CustomExpandIcon = (props) => {
    if (!props.record.PayPlanDetail || props.record.PayPlanDetail.length === 0) return null;
    if (props.expanded) {
      return (
        <a onClick={(e) => props.onExpand(props.record, e)}>
          <MinusCircleOutlined />
        </a>
      );
    } else {
      return (
        <a onClick={(e) => props.onExpand(props.record, e)}>
          <PlusCircleOutlined />
        </a>
      );
    }
  };
  return (
    <div>
      <div id="toolbar" style={{display:props.noActions?"none":"block"}}>
      {!editMode&&<div>
      {hasPremiumPlan && (
        <Button type="link" onClick={onGeneratePayPlan}>
          {t("Regenerate")}
        </Button>
      )}
      <Button type="link" onClick={() => load()} icon={<ReloadOutlined />}>
        {t("Reload")}
      </Button>
      {isGroupPolicy && (
        <Radio.Group onChange={(e) => onChangePolicyType(e.target.value)} value={payPlanMode}>
          <Radio value={"CG"}>{t("Grouped")}</Radio>
          <Radio value={"C"}>{t("Per Certificate")}</Radio>
          <Radio value={"G"}>{t("Master Policy")}</Radio>
        </Radio.Group>
      )}
      <Button type="link" onClick={onShowBarcode} icon={<BarcodeOutlined />}>
        {t("Barcode")}
      </Button>
      <Button type="link" onClick={()=>setEditMode(true)} icon={<EditOutlined />} disabled={readOnly||!allowInstallmentEdition}>
        {t("Edit")}
      </Button>
        <Popover content={<div style={{display:"flex",flexDirection:"column"}}>
          <Checkbox style={{marginLeft:0}} value={showCancelled} onChange={(v)=>setShowCancelled(v.target.checked)}>{t("Show Cancelled")}</Checkbox>
          <Checkbox style={{marginLeft:0}} value={showCreation} onChange={(v)=>setShowCreation(v.target.checked)}>{t("Show Creation date")}</Checkbox>
          <Checkbox style={{marginLeft:0}} value={showNormalDueDate} onChange={(v)=>setShowNormalDueDate(v.target.checked)}>{t("Show Original Due Date")}</Checkbox>
          <Checkbox style={{marginLeft:0}} value={showChangeId} onChange={(v)=>setShowChangeId(v.target.checked)}>{t("Show Change Id")}</Checkbox>
        </div>} title={t("View Options")}>
          <Button type="link" icon={<LegacyIcon type={"control"} />}>{t("View Options")}</Button>
        </Popover>
      </div>}
        
      {editMode&&<div >
        {!props.serialized&&<Button type="link" onClick={()=>setEditMode(false)} icon={<LeftOutlined />}>
        {t("Back")}
      </Button>}
        {props.serialized&&<Button type="link" onClick={()=>onSubmitInstallmentEdition()} icon={<EnterOutlined />}>
          {t("Submit")}
        </Button>}
        {!props.serialized&&<Button type="link" onClick={()=>onSaveInstallmentEdition()} icon={<SaveOutlined />} loading={loading}>
          {t("Save")}
        </Button>}
      </div>}
    </div>
      {!editMode&&<Table
        dataSource={filteredData.sort((a, b) => {
          if (a.contractYear < b.contractYear) {
              return -1;
          }
          if (a.contractYear > b.contractYear) {
              return 1;
          }
          // contractYear is equal, compare numberInYear
          if (a.numberInYear < b.numberInYear) {
              return -1;
          }
          if (a.numberInYear > b.numberInYear) {
              return 1;
          }
          return 0;
      })}
        size={props.size?props.size:"middle"}
        loading={loading}
        pagination={{ pageSize: 24 }}
        scroll={{ x: true }}
        rowKey={"id"}
        footer={(d) => (
          <span>
            <b style={{marginRight:3}}>{t("Total Min")}:</b>
            {formatMoney(totalMinimum,props.currency)}
            <Divider type="vertical" />
            <b style={{marginRight:3}}>{t("Total Paid")}:</b>
            {formatMoney(totalPayed,props.currency)}
            <Divider type="vertical" />
             <b style={{marginRight:3}}>{t("Total Pending")}:</b>
            {formatMoney(totalMinimum-totalPayed,props.currency)}
          </span>
        )}
        expandable={{
          expandIcon:(p) => <CustomExpandIcon {...p} />,
          onExpandedRowsChange:rows=>setExpandedRowKeys(rows),
          expandedRowKeys:expandedRowKeys,
          expandedRowRender:r=>
            <Table dataSource={r.PayPlanDetail} rowKey={"id"}>
              <Table.Column title={t("Concept")} dataIndex={"concept"} />
              <Table.Column title={t("Detail")} dataIndex={"detail"}  />
              <Table.Column title={t("Amount")} dataIndex={"amount"} render={(v, d) =>  <span style={{ whiteSpace: "nowrap" }}>{formatMoney(v, r.currency)}</span>} />
              <Table.Column title={t("Paid")} dataIndex={"paid"} render={(v, d) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Actions")} key={"actions"} render={(v,line)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setEditDetailLine({...line,currency:r.currency})} disabled={!line.id} /> </div>} />
            </Table>}}
      >
         <Table.Column title={t("Ref")} dataIndex="id" key="ref" render={(v, r) => (
          <span style={{whiteSpace:"nowrap"}}>
            {v}
            {r.pendingAmount === 0 && <CheckCircleFilled style={{ color: 'green', marginLeft: 5 }} />}
          </span>
        )} />
        {isGroupPolicy && payPlanMode == "C" && (
          <Table.Column title={t("Certificate")} dataIndex="lifePolicyId" render={(v) => <a href={"#/lifepolicy/" + v}>{v}</a>} />
        )}
        <Table.Column title={t("Concept")} dataIndex="concept" render={(v) => <span style={{ whiteSpace: "nowrap" }}>{v}</span>} />
        {hasPremiumPlan && <Table.Column title={t("Expected")} dataIndex="expected" />}
        <Table.Column title={hasPremiumPlan ? t("Minimum") : t("Amount Due")} dataIndex="minimum"  render={(v, r) =>  <span style={{ whiteSpace: "nowrap" }}>{formatMoney(v, r.currency)}</span>} />
        {hasPenaltyInterest && <Table.Column title={t("Penalty Interest")} dataIndex="penaltyInterest" render={(v, r) =>  <span style={{ whiteSpace: "nowrap" }}>{formatMoney(v, r.currency)}</span>} />}
        <Table.Column title={t("Paid")} dataIndex="payed" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Paid Date")} dataIndex="payedDate" render={(v) => v && formatDate(v)} />
        {showNormalDueDate&&<Table.Column
          title={t("Original Due Date")}
          dataIndex="normalDueDate"
          render={(v, r) => (!r.payed && pastDate(v) ? <Tag color="red">{formatDate(v)}</Tag> : formatDate(v))}
        />}
        <Table.Column
          title={t("Due Date")}
          dataIndex="dueDate"
          render={(v, r) => (!r.payed && pastDate(v) ? <Tag color="red">{formatDate(v)}</Tag> : formatDate(v))}
        />
        <Table.Column title={props.shortTerm?t("# in Period"):t("# in Year")} dataIndex="numberInYear" />
        <Table.Column title={props.shortTerm?t("Contract Period"):t("Contract Year")} dataIndex="contractYear" />
        <Table.Column title={t("Allocation")} dataIndex="allocationId" render={(v) => <a href={"#/allocation?id=" + v}>{v}</a>} />
        {showCancelled&&<Table.Column title={t("Cancellation")} dataIndex="cancellationDate" render={v=>v?<span style={{color:"red"}}>{formatDate(v)}</span>:null} />}
        {showCreation&&<Table.Column title={t("Creation")} dataIndex="created" render={v=>v?formatDate(v):null} />}
        {showChangeId&&<Table.Column title={t("Change")} dataIndex="changeId"/>}
        
        <Table.Column
          title={t("Actions")}
          key="actions"
          dataIndex="id"
          render={(v, r) => (
            <div style={{display:(props.noActions||!r.id)?"none":"block"}}>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item>
                      <Button type="link" onClick={() => payWithBalance(v)} disabled={!r.pendingAmount||props.parsedValues}>
                        {r.minimum>=0?t("Pay with balance"):t("Pay to balance")}
                      </Button>
                    </Menu.Item>
                    <Menu.Item>
                      <Button type="link" onClick={() => remove(v)} disabled={!r.custom}>
                        {t("Remove")}
                      </Button>
                    </Menu.Item> 
                    <Menu.Item>
                      <Button type="link" onClick={() => setEditDetailLine({id:0,payPlanId:r.id,currency:r.currency})} >
                        {t("Add Detail Line")}
                      </Button>
                    </Menu.Item>
                   {/* <Menu.Item>
                      <Button type="link" onClick={() => onCharge(v)} disabled={r.payedDate || !hasPremiumPlan}>
                        {t("Charge Now")}
                      </Button>
                    </Menu.Item>
                    <Menu.Item>
                      <Button type="link" onClick={() => onUndoCharge(v)} disabled={!r.payedDate || !hasPremiumPlan}>
                        {t("Undo")}
                      </Button>
                    </Menu.Item>*/}
                  </Menu>
                }>
                <Button type="link">
                  {t("Options")} <DownOutlined />
                </Button>
              </Dropdown>
              <Divider type="vertical" />
              <Dropdown
                overlay={
                  <Menu>
                    {fiscalDocuments.map((p) => (
                      <Menu.Item onClick={() => onGeneratePremiumDocument(p.template, r.id)}>{p.name}</Menu.Item>
                    ))}
                  </Menu>
                }>
                <Button type="link">
                  {t("Documents")} <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          )}
        />
      </Table>}
      
      {editMode&&<Spreadsheet
          instance={(t) => {window.spreadsheet=t;setSpreadsheet(t); setTableStyle(filteredData.length,t);}}
          data={filteredData.map(p=>[p.id,p.concept,p.minimum,formatDateShortUtc(p.dueDate),p.numberInYear,p.contractYear])}
          columns={[
            { title: t("Ref"), width: 100 },
            { title: t("Concept"), width: 200 },
            { title: t("Amount"), width: 100 },
            { title: t("Due Date"), width: 100 },
            { title: t("Number In Year"), width: 100 },
            { title: t("Contract Year"), width: 100 },
          ]}
      />}
      <Modal title={t("Manual Barcode Generation")} visible={barcodeVisible} onOk={() => setBarcodeVisible(false)} onCancel={() => setBarcodeVisible(false)}>
        <div style={{ display: "flex", marginBottom: 5 }}>
          <Select
            placeholder={t("Premium type")}
            style={{ width: "100%", marginRight: 5 }}
            value={barcodePremiumType}
            onSelect={(v) => setBarcodePremiumType(v)}>
            <Select.Option value="E">{t("Installment Premium")}</Select.Option>
            <Select.Option value="S">{t("Supplementary Premium")}</Select.Option>
          </Select>
          <InputNumber placeholder={t("Amount")} style={{ width: "100%" }} value={barcodePremiumAmount} onChange={(v) => setBarcodePremiumAmount(v)} />
        </div>

        <Barcode value={barcodeValue} fileName={props.policyCode ? props.policyCode.replace("-", "") : "image"} onChange={(v) => setBarcodeValue(v)} />
      </Modal>
      <PayPlanDetailLine visible={editDetailLine} value={editDetailLine} onCancel={()=>setEditDetailLine(undefined)} onOk={onEditDetailLine} onDelete={onDeleteDetailLine} />
    </div>
  );
};

export default PaymentPlan;
