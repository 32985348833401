import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const CostCenterValueSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [t] = useTranslation();

    useEffect(() => {
        if(props.costCenterCode){
            load(props.costCenterCode);
        }
    }, [props.costCenterCode]);

    const load = (code) => {
        setLoading(true);
        exe("GetCostCenterValues",{costCenterCode:code}).then(r=>{
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else {
                message.error(r.msg);
            }
        })
    };
    const onSelect=(v,opt)=>{
        if(props.onSelect) props.onSelect(v,opt);
    }
    return (
        <div >
            <Select style={{width:"100%"}}
                    showArrow
                    placeholder={t("Please select cost value")}
                    value={props.value}
                    onChange={props.onChange}
                    onSelect={onSelect}
                    loading={loading}
                    allowClear
                    showSearch
                    optionFilterProp="children">
                {data.map((p) => (
                    <Select.Option value={p.code} key={p.code} record={p}>
                        {p.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default CostCenterValueSelect;
