import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select, Tag} from "antd";
import { useTranslation } from 'react-i18next';

const PolicySelectContact = (props) => {
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.contactId) load(props.contactId);
    },[props.contactId])
    
    const load=contactId=>{
        setLoading(true);
        exe("GetContactPolicies",{contactId:contactId,asInsured:true,asBeneficiary:true}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData.records.map((r,index)=>({...r,index:index})));
            }else message.error(r.msg)
        })
    }
    const getRole=(p)=>{
        if(p.Insureds){
            const insuredType=p.Insureds.find(q=>q.contactId==props.contactId);
            if(insuredType) return insuredType==1?t("As Coinsured"):t("As Main Insured");
            return t("As Insured");
        }
        if(p.Beneficiaries) return t("As Beneficiary");
        return t("As Policy Holder");
    }
    return (
        <div>
          <Select style={{width:"100%"}} value={props.value} onChange={props.onChange} key={"index"} allowClear
          showSearch
          onSelect={props.onSelect}
          onClear={props.onClear}
          optionFilterProp="children"
          filterOption={(input, option) => (option.props.record.code||"").toLowerCase().indexOf(input.toLowerCase()) >= 0}
          disabled={props.disabled} loading={loading} placeholder={t("Please select policy")}>
            {data.map(p=><Select.Option key={p.index} value={p.id} record={p}><Tag>{getRole(p)}</Tag>{p.code}</Select.Option>)}
          </Select>  
        </div>
    );
};

export default PolicySelectContact;