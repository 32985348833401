import { message, Select,ConfigProvider } from "antd";
import React, { useEffect, useState,useContext } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const BenefitPaymentMethod = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const configContext = useContext(ConfigProvider.ConfigContext);
  const direction = configContext.direction;

  useEffect(() => {
    if (props.productCode) load(props.productCode);
  }, [props.productCode]);

  const load = (productCode) => {
    exe("GetTableRows", { table: "BenefitPaymentMethod", column: productCode, filterValue: "x", getColumn1: "code", getColumn2: "name" }).then((r) => {
      if (r.ok) {
        setData(r.outData.map((p, index) => ({ code: p.column1, name: p.column2 })));
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        placeholder={t("Payment Method")}
        style={{ width: 175,...(direction=="rtl"?{marginRight:3}:{marginLeft:3})}}
        dropdownMatchSelectWidth={false}
        value={props.value}
        disabled={props.disabled}
        onChange={(v) => props.onChange(v)}>
        {data.map((p) => (
          <Select.Option value={p.code}>{p.name}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default BenefitPaymentMethod;
