import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";

const ReportSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => getReports(), []);

  const getReports = () => {
    setLoading(true);
    exe("GetReports").then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        loading={loading}
        value={props.value}
        onChange={props.onChange}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showSearch
        allowClear>
        {data.map((p) => (
          <Select.Option value={p.fileName} key={p.fileName}>
            {p.fileName}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ReportSelect;
