import React, { useState, useEffect } from "react";
import { Select, Tag } from "antd";
import { useTranslation } from "react-i18next";

const BirthdaySelect = (props) => {
  const [t] = useTranslation();
  const [year, setYear] = useState(undefined);
  const [month, setMonth] = useState(undefined);
  const [day, setDay] = useState(undefined);
  const [currentAge, setCurrentAge] = useState(undefined);

  useEffect(() => {
    if (isCompleteDate(props.value)) {
      const yyyy = props.value.split("-")[0];
      const mm = props.value.split("-")[1];
      const dd = props.value.split("-")[2].split("T")[0];

      setYear(yyyy);
      setMonth(mm);
      setDay(dd);

      computeAge(yyyy, mm, dd);
    }
    if (!props.value) {
      //reset
      setYear(undefined);
      setMonth(undefined);
      setDay(undefined);
      setCurrentAge(undefined);
    }
  }, [props.value]);

  const isCompleteDate = (v) => {
    if (!v) return false;
    const splited = v.split("-");
    if (splited.length < 3) return false;
    if (splited[0] == "undefined" || splited[1] == "undefined" || splited[2] == "undefined") return false;

    return true;
  };
  const computeAge = (yyyy, mm, dd) => {
    const today = new Date();
    const birthDate = new Date(`${yyyy}-${mm}-${dd}`);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) age--;

    setCurrentAge(age);
  };

  const currentYear = new Date().getFullYear();
  const years = [...Array(100).keys()].map((p) => currentYear - p);
  const months = [...Array(13).keys()].slice(1);
  //setting the number of days according to the selected month
  const days1 = () => {
    if (month == 2) {
      if ((year % 4 == 0 && year % 100 != 0) || year % 400 == 0) {
        return [...Array(30).keys()].slice(1);
      } else {
        return [...Array(29).keys()].slice(1);
      }
    } else if ([4, 6, 9, 11].includes(+month)) {
      return [...Array(31).keys()].slice(1);
    } else {
      return [...Array(32).keys()].slice(1);
    }
  };
  const validDays=days1();


  const onChangeYear = (v) => {
    setYear(v);
    props.onChange(`${v}-${month}-${day}`);
  };
  const onChangeMonth = (v) => {
    setMonth(v);
    props.onChange(`${year}-${v}-${day}`);
  };
  const onChangeDay = (v) => {
    setDay(v);
    props.onChange(`${year}-${month}-${v}`);
  };

  return (
    <div style={{ display: "flex" }}>
      <Select style={{ width: 75, marginRight: 3 }} placeholder={t("Year")} showSearch onChange={(v) => onChangeYear(v)} value={year}>
        {years.map((p) => (
          <Select.Option value={p}>{p}</Select.Option>
        ))}
      </Select>
      <Select style={{ width: 75, marginRight: 3 }} placeholder={t("Month")} showSearch onChange={(v) => onChangeMonth(v)} value={month}>
        {months.map((p) => (
          <Select.Option value={p}>{p}</Select.Option>
        ))}
      </Select>
      <Select style={{ width: 75, marginRight: 3 }} placeholder={t("Day")} showSearch onChange={(v) => onChangeDay(v)} value={validDays.includes(+day)?day:undefined}>
        {validDays.map((p) => (
          <Select.Option value={p}>{p}</Select.Option>
        ))}
      </Select>
      {currentAge !== undefined && (
        <span>
          <Tag>{currentAge}y</Tag>
        </span>
      )}
    </div>
  );
};

export default BirthdaySelect;
