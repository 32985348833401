import { FileOutlined, LeftOutlined, SaveOutlined, SolutionOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  Input,
  message,
  notification,
  Row,
  Select,
  Switch,
  Tabs,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FormLabel from "../Shared/FormLabel";
import moment from "moment";
import yaml from "js-yaml";
import LobSelect from "../Shared/LobSelect";
import ProductSelect from "../Life/ProductSelect";
import Money from "../Shared/Money";
import Currency from "../Shared/Currency";
import CalcSheetSelect from "../Shared/CalcSheetSelect";
import { Link } from "react-router-dom";
import CodeEditorW from '../Shared/CodeEditorW';

const Measurement = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.match.params.measurementId) return;
    if (props.match.params.measurementId == 0) {
      props.form.resetFields();
    } else {
      load(props.match.params.measurementId);
    }
  }, [props.match.params.measurementId]);

  const load = (id) => {
    setLoading(true);
    exe("RepoMeasurementDefinition", { operation: "GET", filter: `id=${id}` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.form.setFieldsValue(r.outData[0]);
        if (r.outData[0].config) window.global.myCodeMirror.setValue(r.outData[0].config);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      //extra fields
      values.config = window.global.myCodeMirror.getValue();
      //yaml validation
      try {
        const result = yaml.safeLoad(values.config);
        values.configJson = JSON.stringify(result);
      } catch (error) {
        notification["error"]({
          message: error.reason,
          description: error.message,
        });
        return;
      }
      const operation = values.id ? "UPDATE" : "ADD";
      setLoading(true);
      exe("RepoMeasurementDefinition", { operation: operation, entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          if (operation == "ADD") {
            window.location = "#/measurements";
          } else {
            window.location = "#/measurement/" + r.outData[0].id;
          }
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onNew = () => {
    window.location.hash = "#/measurement/0";
  };
  const onBack = () => {
    window.location.hash = "#/measurements";
  };

  const field = props.form.getFieldDecorator;
  const values = props.form.getFieldsValue();

  return (
    <DefaultPage
      title={values.name ? values.name : t("Measurement Definition")}
      icon="control"
      routes={{
        routes: [
          { path: "/", breadcrumbName: t("Home") },
          { path: "measurements", breadcrumbName: t("Measurements") },
          { path: "", breadcrumbName: t("Measurement Definition") },
        ],
      }}
      extra={
        <div>
          <Button type="primary" onClick={onSave} icon={<SaveOutlined />} loading={loading} style={{ marginRight: 5 }}>
            {t("Save")}
          </Button>
          <Button onClick={onNew} icon={<FileOutlined />}>
            {t("New")}
          </Button>
        </div>
      }>
      <Button type="link" onClick={onBack} icon={<LeftOutlined />}>
        {t("Back")}
      </Button>
      <Tabs>
        <Tabs.TabPane tab={t("Main")} key="main">
          <Title level={4}>{t("Measurement Model Definition")}</Title>
          <Row gutter={16}>
            <Col span={8}>
              {field("id")}
              <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
              <Form.Item label={t("Actuarial Sheet")}>{field("calcSheetId", { rules: [{ required: true }] })(<CalcSheetSelect />)}</Form.Item>
              <Link to={{ pathname: "/calcsheet", calcSheetId: values.calcSheetId }}>
                <Button type="link" icon={<SolutionOutlined />}>
                  {t("Open Actuarial Sheet")}
                </Button>
              </Link>
            </Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Behaviour")} key="behaviour" forceRender>
          <Title level={4}>{t("Measurement Behaviour")}</Title>
          <CodeEditorW />
        </Tabs.TabPane>
      </Tabs>
    </DefaultPage>
  );
};

export default Form.create()(Measurement);
