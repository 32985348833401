import React, {useEffect, useState} from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {useTranslation} from "react-i18next";
import Money from "../Shared/Money";
import Section from "../Shared/Section";
import {getConfigProfile} from "../../Lib/Helpers";
import {safeGet} from "../../Lib/Dal";

const CessionParticipantForm = (props) => {
    const [contactRoles, setContactRoles] = useState([]);
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    const currency=props.cession.currency;
    
    useEffect(()=>{
        if(props.value) props.form.setFieldsValue(props.value);
    },[props.value])

    useEffect(() => {
        getConfigProfile().then((profile) => {
            setContactRoles(safeGet(["Reinsurance", "contactRoles"], profile, []));
        });
    }, []);
    
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onOk&&props.onOk(values);
        })
    }
    return (
        <div style={{width:400}}>
            {field("id")}
            {field("cessionId")}
            {field("name")}
            {field("lineId")}
            {field("split")}
            {field("currency")}
            {field("liquidationId")}
            <Section text={t("Main info")}>
                <Form.Item label={t("Participant")}>{field("contactId",{rules:[{required:true}]})(<SimpleContactSelect  filter={
                    contactRoles.length === 0 ? undefined 
                        : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `} />)}
                </Form.Item>
                <Form.Item label={t("Sum Insured")}>{field("sumInsured",{rules:[{required:true}]})(<Money currency={currency} />)}</Form.Item>
                <Form.Item label={t("Premium")}>{field("premium",{rules:[{required:true}]})(<Money currency={currency} />)}</Form.Item>
                <Form.Item label={t("Commission")}>{field("commission",{rules:[{required:true}]})(<Money currency={currency} />)}</Form.Item>
                <Form.Item label={t("Tax")}>{field("tax",{rules:[{required:true}]})(<Money currency={currency} />)}</Form.Item>
                <Form.Item label={t("Reserve")}>{field("reserve",{rules:[{required:true}]})(<Money currency={currency} />)}</Form.Item>
                <Form.Item label={t("Broker")}>
                    {field("brokerId", { rules: [{ required: false }] })(
                        <SimpleContactSelect filter={
                            contactRoles.length === 0
                                ? undefined
                                : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                        }
                        />
                    )}
                </Form.Item>
                <Button type="primary" icon={<SaveOutlined />} onClick={onSave} >{t("Save")}</Button>
            </Section>
        </div>
    );
};

export default Form.create()(CessionParticipantForm);