import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Table, message } from "antd";
import useUpdateEffect, {formatDate, formatMoney, getColor} from "../../Lib/Helpers";
import Status from "../Shared/Status";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import OtherReceivablesFilter from "./OtherReceivablesFilter";

const OtherReceivables = (props) => {
    const [t]=useTranslation();
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [filterVisible,setFilterVisible]=useState(false);
    
    useEffect(()=>{
        getData()
    },[props.refresh]);
    
    const getData=()=>{
        setLoading(true);
        exe("GetOtherReceivables").then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData.results)
            }else message.error(r.msg)
        })
    }
    const getOrigin = (r) => {
        let url = "";
        let origin = "";
        let originId = 0;
        let icon = "";
        if (r.claimId) {
            origin = t("Claim");
            originId = r.claimId;
            icon = "warning";
            url = "#/healthclaim/" + originId;
        } else if (r.lifePolicyId) {
            origin = t("Policy");
            originId = r.lifePolicyId;
            icon = "safety";
            url = "#/lifepolicy/" + originId;
        } else if (r.liquidationId && r.producer == "COM") {
            origin = t("Commission liquidation");
            originId = r.liquidationId;
            icon = "shopping";
            url = "#/liquidations?id=" + originId;
        } else if (r.liquidationId && r.producer == "TAX") {
            origin = t("Tax liquidation");
            originId = r.liquidationId;
            icon = "transaction";
            url = "#/taxscheme/1?id=" + originId;
        } else if (r.liquidationId && r.producer == "RE") {
            origin = t("Reinsurance liquidation");
            originId = r.liquidationId;
            icon = "calculator";
            const refInfo = r.reference.split("-"); //RELIQ-{liquidation.contractId}-{liquidation.id}-PART-{participant.contactId}"
            url = refInfo.length > 0 ? `#/contract/${refInfo[1]}` : "#/contractlist";
        } else if (r.liquidationId && r.producer == "COI") {
            origin = t("Coinsurance liquidation");
            originId = r.liquidationId;
            icon = "pie-chart";
            url = "#/coLiquidations";
        } else if (r.producer === "MERGED") {
            origin = t("Merged Payments");
            icon = "block";
            originId = "";
            url = "#";
        } else {
            //manual
            origin = t("Manual Request");
            icon = "user";
            originId = "";
            url = "#";
        }
    }
    return (
        <div>
            <Button type={"link"} icon={<LegacyIcon type={"reload"} />} onClick={()=>getData()} >{t("Reload")}</Button>
            <Button type={"link"} icon={<LegacyIcon type={"filter"} />} onClick={()=>setFilterVisible(true)} >{t("Filter")}</Button>
            <Table dataSource={data} loading={loading} rowSelection={props.rowSelection}>
                <Table.Column title={t("Key")} dataIndex="key" />
                <Table.Column title={t("Beneficiary")} dataIndex="contactId" render={(v, r) => <a href={"#/contact/" + v}>{r.contactName}</a>} />
                <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
                {/*<Table.Column title={t("Origin")} key="origin" render={(v, r) => getOrigin(r)} />*/}
                <Table.Column title={t("Producer")} dataIndex="producer" />
                <Table.Column title={t("Ref")} dataIndex="reference" />
                <Table.Column title={t("Amount")} dataIndex="total" fixed="right" render={(v, r) => formatMoney(v, r.currency)} />
            </Table>
            <OtherReceivablesFilter visible={filterVisible} onData={data=>setData(data)} onClose={()=>setFilterVisible(false)} />
        </div>
    );
};

export default OtherReceivables;