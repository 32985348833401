import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const BenefitSelect = (props) => {
  const data = props.benefits || [];
  const [t] = useTranslation();

  const Options = data.map((d) => (
    <Select.Option key={d.id} value={d.id} label={d.code + " - " + d.name} benefit={d}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          {d.code} - {d.name}
        </div>
      </div>
    </Select.Option>
  ));
  const onSelect = (benefit,benefitObj) => {
    if (props.onSelect) props.onSelect(benefit,benefitObj);
  };
  return (
    <Select
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      optionLabelProp="label"
      placeholder={t("Please select benefit")}
      style={props.style}
      onSelect={(v, o) => onSelect(v, o.props.benefit)}
      showArrow
      allowClear
      filterOption={false}>
      {Options}
    </Select>
  );
};

export default BenefitSelect;
