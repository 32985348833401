import React, { Component } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { CopyOutlined, ExclamationCircleOutlined, FileOutlined } from '@ant-design/icons';
import { Table, Tag, Divider, Button, message, Popover, Input } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { exe } from "../../Lib/Dal";
import InvestmentPlan from "./InvestmentPlan";
import Paragraph from "antd/lib/skeleton/Paragraph";
import { withTranslation } from "react-i18next";

class InvestmentPlans extends Component {
  state = {
    investmentPlans: [],
  };
  componentDidMount() {
    this.load();
  }
  columns = [
    {
      title: this.props.t("Code"),
      dataIndex: "code",
      render: (text) => <a href={"#/InvestmentPlans/" + text}>{text}</a>,
    },
    {
      title: this.props.t("Name"),
      dataIndex: "name",
    },
    {
      title: this.props.t("Type"),
      dataIndex: "type",
    },
    {
      title: this.props.t("Tags"),
      key: "tags",
      dataIndex: "tags",
      // render: tags => (
      //   <span>
      //     {tags.map(tag => {
      //       let color = tag.length > 5 ? "geekblue" : "green";
      //       if (tag === "loser") {
      //         color = "volcano";
      //       }
      //       return (
      //         <Tag color={color} key={tag}>
      //           {tag.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </span>
      // )
    },
    {
      title: this.props.t("Action"),
      key: "action",
      render: (text, record) => (
        <span>
          <a href={"#/InvestmentPlans/" + record.code}>{this.props.t("Edit")}</a>
          <Divider type="vertical" />
          <Popover
            content={
              <div style={{ width: 150 }}>
                {this.props.t("Please specify unique investment plan code for the cloned plan")}
                <Input
                  style={{ marginTop: 5, marginBottom: 5 }}
                  value={this.state.investmentPlanCode}
                  placeholder={this.props.t("Please insert code")}
                  onChange={(e) => this.setState({ investmentPlanCode: e.target.value })}
                />
                <Button type="primary" icon={<CopyOutlined />} onClick={() => this.onClone(record)}>
                  {this.props.t("Clone")}
                </Button>
              </div>
            }
            title={
              <div>
                <ExclamationCircleOutlined style={{ marginRight: 5, color: "orange" }} />
                {this.props.t("Financial Product Code")}
              </div>
            }
            trigger="click">
            <Button type="link">{this.props.t("Clone")}</Button>
          </Popover>

          <Divider type="vertical" />
          <a>{this.props.t("Export")}</a>
        </span>
      ),
    },
  ];
  load() {
    this.setState({ loading: true });
    exe("RepoInvestmentPlan", {
      operation: "GET",
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState({ investmentPlans: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  }
  onClone(record) {
    record.code = this.state.investmentPlanCode;
    record.name = record.name + " (copy " + new Date().toLocaleString() + ")";
    this.setState({ loading: true });
    exe("RepoInvestmentPlan", { operation: "ADD", entity: record }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.load();
      } else {
        message.error(r.msg);
      }
    });
  }
  newInvestmentPlan() {
    window.location.href = "#/InvestmentPlans/0";
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <DefaultPage
        title={t("Financial Products")}
        icon="euro"
        extra={
          <ButtonGroup>
            <Button icon={<FileOutlined />} onClick={() => this.newInvestmentPlan()}>
              {t("New")}
            </Button>
            <Button onClick={() => this.load()}>{t("Reload")}</Button>
            <Button>{t("Import")}</Button>
            <Button>{t("Export")}</Button>
          </ButtonGroup>
        }>
        <Table columns={this.columns} dataSource={this.state.investmentPlans} />
      </DefaultPage>
    );
  }
}

export default withTranslation()(InvestmentPlans);
