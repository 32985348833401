import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  DatePicker,
  Tag,
  Switch,
  Checkbox,
  InputNumber,
} from "antd";
import { exe } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";
import moment from "moment";
import Compare from "../Shared/Compare";
import ProductSelect from "../Life/ProductSelect";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const MovFilter = (props) => {
  const [matches, setMatches] = useState(null);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (props.filter) {
      props.form.setFieldsValue(props.filter);
    }
    if (window.location.hash.includes("?date=")) {
      const date = window.location.hash.split("?date=")[1];
      props.form.setFieldsValue({ date: moment(date) });
      submit();
    } else {
      submit();
    }
  }, []);

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      console.log(values, "FORM VALUES");
      let queries = [];
      if (values.date)
        queries.push(
          `YEAR(calculationDate)=${values.date.format("YYYY")} AND MONTH(calculationDate)=${values.date.format(
            "M"
          )} AND DAY(calculationDate)=${values.date.format("D")}`
        );
      if (values.month) queries.push(`YEAR(calculationDate)=${values.month.format("YYYY")} AND MONTH(calculationDate)=${values.month.format("M")}`);

      if (values.code !== undefined) queries.push(`code='${values.code}'`);
      if (values.policyId) queries.push(`lifePolicyId=${values.policyId}`);
      if (values.currency !== undefined) queries.push(`currency='${values.currency}'`);

      const filterQuery = queries.join(" AND ");
      console.log(queries, filterQuery);

      exe("RepoMovement", { operation: "GET", filter: filterQuery }).then((r) => {
        props.onResults(r.outData.records);
        setMatches(r.total);
      });
    });
  };

  return (
    <div>
      <Drawer title={t("Movement Filter")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Specific Day")}>{getFieldDecorator("date")(<DatePickerW />)}</Form.Item>
            <Form.Item label={t("Month")} style={{ marginLeft: 5 }}>
              {getFieldDecorator("month")(<DatePicker.MonthPicker />)}
            </Form.Item>
          </div>
          <Form.Item label={t("Code")}>{getFieldDecorator("code")(<Input placeholder={t("Movement code")} />)}</Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Policy ID")} style={{ width: "100%" }}>
              {getFieldDecorator("policyId")(<InputNumber style={{ width: "100%" }} />)}
            </Form.Item>
            <Form.Item label={t("Currency")} style={{ marginLeft: 5, width: "100%" }}>
              {getFieldDecorator("currency")(<Currency />)}
            </Form.Item>
          </div>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> Results
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(MovFilter);
