import React, { useEffect, useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Row, Col, Checkbox, Switch } from "antd";
import {useTranslation} from "react-i18next";
import EventReason from './EventReason';
import InsuredEventSelect from './InsuredEventSelect';

const SubClaimNew = ({visible,onOk,onCancel,form,insuredEventsTable,productCode,validEventReasons}) => {
    const [validInsuredEvents,setValidInsuredEvents]=useState("");
    const [newEvent,setNewEvent]=useState(false);
    const [t]=useTranslation();
    const field=form.getFieldDecorator;

    useEffect(()=>{
        form.resetFields();
    },[visible])
    
    const onOkInternal=()=>{
        form.validateFields((err,values)=>{
            if(err) return;
            onOk&&onOk(values);
        })
    }
    const onEventReasonChange=(v)=> {
        const filtered = (insuredEventsTable).filter((p) => (p[2].includes("ALL") || p[2].includes(productCode)) && p[3] == v);
        setValidInsuredEvents(filtered.map((p) => p[4]).join(","));
    
      }
      const onNewEventChange=(v)=>{
        setNewEvent(v);
        if(!v) form.setFieldsValue({InsuredEventObject:undefined,insuredEvent:undefined,description:undefined,eventReason:undefined});
      }
    return (
        <Modal title={t("New sub-claim")} visible={visible} onOk={onOkInternal} onCancel={onCancel}>
            <Form.Item label={t("New Event")}>{field("newEvent",{valuePropName:"checked",rules:[{required:false}]})(<Switch onChange={onNewEventChange} />)}</Form.Item>
            <div style={{display:newEvent?"flex":"none",flexDirection:"column"}}>
                <Form.Item label={t("Event Reason")} >
                    {field("eventReason", {
                        rules: [{ required: false }],
                    })(<EventReason style={{width:"100%"}} onChange={(v) => onEventReasonChange(v)}  validValues={validEventReasons} noValidation={insuredEventsTable.length===0} />)}
                </Form.Item>
                {field("insuredEvent")}
                <Form.Item label={t("Insured Event")}>
                    {field("InsuredEventObject", {
                        rules: [{ required: false }],
                    })(<InsuredEventSelect onChange={v=>v?form.setFieldsValue({insuredEvent:v.code}):null} style={{width:"100%"}}  validValues={validInsuredEvents} noValidation={insuredEventsTable.length===0} />)}
                </Form.Item>
            </div>
            
            <Form.Item label={t("Description")}>{field("description",{rules:[{required:false}]})(<Input.TextArea />)}</Form.Item>
        </Modal>
    );
};

export default Form.create()(SubClaimNew);