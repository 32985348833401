import { FileOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Col,
  Input,
  message,
  notification,
  Row,
  Switch,
  Tabs,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FormLabel from "../Shared/FormLabel";
import moment from "moment";
import yaml from "js-yaml";
import DatePickerW from "../Shared/DatePickerW";
import CodeEditorW from '../Shared/CodeEditorW';

const InstallmentScheme = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.match.params.installmentSchemeId) return;
    if (props.match.params.installmentSchemeId == 0) {
      props.form.resetFields();
    } else {
      load(props.match.params.installmentSchemeId);
    }
  }, [props.match.params.installmentSchemeId]);

  const load = (id) => {
    setLoading(true);
    exe("RepoInstallmentScheme", { operation: "GET", filter: `id=${id}` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.form.setFieldsValue(r.outData[0]);
        if (r.outData[0].config) window.global.myCodeMirror.setValue(r.outData[0].config);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      //extra fields
      values.config = window.global.myCodeMirror.getValue();
      //yaml validation
      try {
        const result = yaml.safeLoad(values.config);
        values.configJson = JSON.stringify(result);
      } catch (error) {
        notification["error"]({
          message: error.reason,
          description: error.message,
        });
        return;
      }
      setLoading(true);
      exe("RepoInstallmentScheme", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          window.location = "#/installmentscheme/" + r.outData[0].id;
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onNew = () => {
    window.location.hash = "#/installmentscheme/0";
  };
  const field = props.form.getFieldDecorator;
  return (
    <DefaultPage
      title={t("Installment Scheme")}
      icon="gold"
      routes={{
        routes: [
          { path: "/", breadcrumbName: t("Home") },
          { path: "installmentschemelist", breadcrumbName: t("Installment Schemes") },
          { path: "", breadcrumbName: t("Installment Scheme") },
        ],
      }}
      extra={
        <div>
          <Button type="primary" onClick={onSave} icon={<SaveOutlined />} loading={loading} style={{ marginRight: 5 }}>
            {t("Save")}
          </Button>
          <Button onClick={onNew} icon={<FileOutlined />}>
            {t("New")}
          </Button>
        </div>
      }>
      <Tabs>
        <Tabs.TabPane tab={t("Main")} key="main">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Code")}>{field("code", { rules: [{ required: true }] })(<Input />)}</Form.Item>
              <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
              <Form.Item label={t("Notes")}>{field("notes")(<Input.TextArea />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Start")}>{field("start", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("End")}>{field("end", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Active")}>{field("active", { valuePropName: "checked" })(<Switch />)}</Form.Item>
              <Form.Item label={t("ID")}>{field("id")(<FormLabel />)}</Form.Item>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("Behaviour")} key="behaviour" forceRender>
          <Title level={4}>{t("Scheme Behaviour")}</Title>
          <CodeEditorW />
        </Tabs.TabPane>
      </Tabs>
    </DefaultPage>
  );
};

export default Form.create()(InstallmentScheme);
