import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";

const ContractAccountSelect = (props) => {
  const [data, setData] = useState([]);
  useEffect(() => load(), [props.contactId,props.contractId]);

  const load = () => {
    let filter=`contractId=${props.contractId}`;
    //checking if we should filter by contactId
    if(props.contactId) filter+=` AND holderId=${props.contactId}`;
    exe("RepoAccount", { operation: "GET", filter: filter}).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select value={props.value} dataSource={data} onChange={(v) => props.onChange(v)} style={{ width: "100%" }}>
        {data.map((v) => (
          <Select.Option key={v.id} value={v.id}>{v.accNo}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ContractAccountSelect;
