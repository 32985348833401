import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {Alert, Button, Divider, message, Modal, Popconfirm, Table} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import CoContractParticipantDetail from "./CoContractParticipantDetail";

const CoContractParticipants = (props) => {
    const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [participantDetailVisible, setParticipantDetailVisible] = useState(false);
    const [selected, setSelected] = useState();
    const [warning, setWarning] = useState([]);
    const [selectedBroker,setSelectedBroker]=useState();

    useEffect(() => {
        if (props.contractId) load();
    }, [props.contractId]);

    useEffect(() => {
        checkSum();
    }, [data]);

    const load = () => {
        setLoading(true);
        exe("RepoCoContractParticipant", { operation: "GET", filter: `coContractId=${props.contractId}`, include: ["Contact"] }).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else {
                message.error(r.msg);
            }
        });
    };
    const onSaveParticipant = () => {
        message.success(t("Record updated"));
        setParticipantDetailVisible(false);
        load();
    };
    const onEditParticipant = (r) => {
        setSelected(r);
        setParticipantDetailVisible(true);
    };
    const onCloseParticipantDetail = () => {
        setParticipantDetailVisible(false);
        setSelected(null);
    };
    const onDelete = (r) => {
        setLoading(true);
        exe("RepoCoContractParticipant", { operation: "DELETE", entity: { id: r.id } }).then((r) => {
            setLoading(false);
            if (r.ok) {
                message.success(r.msg);
                load();
            } else {
                message.error(r.msg);
            }
        });
    };
    const checkSum = () => {
        const result = [];
        data.reduce((res, value) => {
            if (!res[value.lineId]) {
                res[value.lineId] = { lineId: value.lineId, split: 0 };
                result.push(res[value.lineId]);
            }
            res[value.lineId].split += value.split;
            return res;
        }, {});
        setWarning(result);
    };
    return (
        <div>
            {warning
                .filter((p) => p.split !== 100)
                .map((p) => (
                    <Alert
                        message={t("Warning")}
                        description={t("Participation must sum 100% for each line. Please check participants for line ") + p.lineId}
                        type="warning"
                        showIcon
                    />
                ))}
            <Button type="link" icon={<PlusOutlined />} onClick={() => setParticipantDetailVisible(true)}>
                Add
            </Button>
            <Table dataSource={data} loading={loading} rowKey={"id"}>
                <Table.Column title={t("Participant")} dataIndex={["Contact","FullName"]} />
                <Table.Column title={t("Line ID")} dataIndex="lineId" />
                <Table.Column title={t("Split %")} dataIndex="split" />
                <Table.Column
                    title={t("Actions")}
                    key="actions"
                    render={(v, r) => (
                        <div>
                            <Button type="link" icon={<EditOutlined />} onClick={() => onEditParticipant(r)} />
                            <Divider type="vertical" />
                            <Popconfirm title={t("Are you sure you want to delete this participant?")} onConfirm={() => onDelete(r)}>
                                <Button type="link" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </div>
                    )}
                />
            </Table>
            <CoContractParticipantDetail
                selected={selected}
                contractId={props.contractId}
                visible={participantDetailVisible}
                onSave={() => onSaveParticipant()}
                onCancel={onCloseParticipantDetail}
            />
         
        </div>
    );
};

export default CoContractParticipants;
