import React from "react";
import { Table, Divider, Tag } from "antd";
import moment from "moment";

const { Column, ColumnGroup } = Table;

class InspectorData extends React.Component {
  render() {
    return (
      <Table dataSource={this.props.data}>
        <Column title="Estado" dataIndex="estado" />
        <Column title="Tiempo" dataIndex="tiempo" render={v => moment.duration(parseFloat(v), "hours").humanize()} />
        <Column title="Cantidad" dataIndex="cantidad" />
      </Table>
    );
  }
}
export default InspectorData;
