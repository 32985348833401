import { Button, Modal, Radio, Table, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exe } from '../../Lib/Dal';
import { ReloadOutlined, CheckOutlined, ClockCircleOutlined, UploadOutlined, PaperClipOutlined } from '@ant-design/icons';
import moment from 'moment';
import FileUpload from '../Life/FileUpload';

const ProviderRequirements = (props) => {
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [fileUploadVisible, setFileUploadVisible] = useState(false);
    const [t]=useTranslation();

    useEffect(()=>{
        load();
    },[props.providerCode]);

    const load=()=>{
        if(!props.providerCode) return;
        setLoading(true);
        exe("RepoProviderRequirement",{operation:"GET",filter:`providerCode='${props.providerCode}'`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    const requestDoc = (record) => {
        setLoading(true);
        exe("RepoProviderRequirement",{operation:"UPDATE",entity:{...record,request:true,requestDate:new Date()}}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(t("Record updated"));
                load();
            }else message.error(r.msg);
        })
      };
      const receiveDoc = (record) => {
        setLoading(true);
        exe("RepoProviderRequirement",{operation:"UPDATE",entity:{...record,response:true,responseDate:new Date()}}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(t("Record updated"));
                load();
            }else message.error(r.msg);
        })
      };
      const onRaiseRequirements = () => {
        setLoading(true);
        exe("RaiseProviderRequirements", { providerCode: props.providerCode }).then((r) => {
          setLoading(false);
          if (r.ok) {
            message.success(r.msg);
            load();
          } else {
            message.error(r.msg);
          }
        });
      };
      const onCheckOption = (value, record) => {
        // console.log(value, record);
        // const updatedrecord = props.value.find((p) => p.code === record.code);
        // updatedrecord.jFormValues = value;
        // if (value == "COMPLETE") {
        //   updatedrecord.request = true;
        //   updatedrecord.requestDate = new Date();
        //   updatedrecord.response = true;
        //   updatedrecord.responseDate = new Date();
        // } else {
        //   updatedrecord.request = true;
        //   updatedrecord.requestDate = new Date();
        //   updatedrecord.response = false;
        //   updatedrecord.responseDate = null;
        // }
        // props.onChange(props.value);
        // updateRecord(updatedrecord);
      };
      const onUploadPdfForm = (record) => {
        setFileUploadVisible(true);
        //setUploadedRequirement(record);
      };
      const onUploadFile = (record) => {
        setFileUploadVisible(true);
      }
      const onFileUploaded = (files) => {
        setFileUploadVisible(false);
        console.log(files);
      }
      const onPdfFormUpload = (files) => {
        console.log(files);
        // setFileUploadVisible(false);
        // uploadedRequirement.response = true;
        // uploadedRequirement.responseDate = new Date();
        // uploadedRequirement.responseLink = files[0].url;
        // updateRecord(uploadedRequirement);
        // files[0].association = uploadedRequirement.code;
        // props.onAddPolicyFile && props.onAddPolicyFile(files);
      };
    const renderStatus=(text,record)=> {

        if (record.response)
          return (
            <span>
              <Tooltip title={t("Received") + " " + moment.utc(record.responseDate).fromNow()}>
                <CheckOutlined style={{ color: "green" }} />
                <CheckOutlined style={{ color: "green" }} />
              </Tooltip>
            </span>
          );
        if (record.request)
          return (
            <Tooltip title={t("Requested") + " " + moment.utc(record.requestDate).fromNow()}>
              <CheckOutlined style={{ color: "green" }} />

              {record.type == "PROCESS" || record.type == "AMLCASE" ? (
                <ClockCircleOutlined style={{ marginLeft: 3 }} />
              ) : record.type=="FILE"?<Button type="link" onClick={()=>props.gotoFiles()}>{t("Upload")}</Button>:(
                <Button type="link" onClick={() => receiveDoc(record)}>
                  {t("Receive")}
                </Button>
              )}
            </Tooltip>
          );
         
        // if (record.type == "PDFFORM")
        //   return (
        //     <Button type="link" onClick={() => onUploadPdfForm(record)} icon={<UploadOutlined />} disabled={record.readOnly}>
        //       {t("Upload")}
        //     </Button>
        //   );
        // if (record.type !== "FORM")
        //   return (
        //     <Button type="link" onClick={() => requestDoc(record)} disabled={record.readOnly}>
        //       {t("Request")}
        //     </Button>
        //   );
        // else return null;
      }

    return (<div>
        <Button type="link" icon={<ReloadOutlined />} onClick={load}>{t("Reload")}</Button>
        <Button type="link" icon={<PaperClipOutlined />} onClick={() => onRaiseRequirements()} loading={loading}>
        {t("Raise Requirements Now")}
      </Button>
        <Table dataSource={data} loading={loading}>
            <Table.Column title={t("ID")} dataIndex="id" />
            <Table.Column title={t("Requirement")} dataIndex="name" />
            <Table.Column title={t("Code")} dataIndex="code" />
            <Table.Column title={t("Status")} dataIndex="request" render={renderStatus} />
            <Table.Column title={t("Block Level")} dataIndex="blockLevel" />
            <Table.Column title={t("Type")} dataIndex="type" />
        </Table>
        <Modal visible={fileUploadVisible} title={t("File Upload")} onOk={() => setFileUploadVisible(false)} onCancel={() => setFileUploadVisible(false)}>
        <FileUpload onChange={(files) => onFileUploaded(files)} />
      </Modal>
    </div>);
}
 
export default ProviderRequirements;