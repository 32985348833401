import { DeleteOutlined, RocketOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, notification, Popconfirm, Switch } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import yaml from "js-yaml";
import CodeEditorW from '../Shared/CodeEditorW';

const TransactionTemplate = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("ADD");
  const [executing, setExecuting] = useState(false);

  useEffect(() => {
    if (props.value) {
      props.form.setFieldsValue(props.value);
      window.global.templateBehaviour.setValue(props.value.config ? props.value.config : "");
      setMode("UPDATE");
    } else {
      props.form.resetFields();
      setMode("ADD");
    }
  }, [props.value]);

  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      values.config = window.global.templateBehaviour.getValue();
      //yaml validation
      try {
        const result = yaml.safeLoad(values.config);
        values.jConfig = JSON.stringify(result);
      } catch (error) {
        notification["error"]({
          message: error.reason,
          description: error.message,
        });
        return;
      }
      setLoading(true);

      exe("RepoTransactionTemplate", { operation: mode, entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          if (mode == "ADD") {
            props.onAdd(values);
            setMode("UPDATE");
          } else {
            props.onUpdate(values);
          }
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onDelete = () => {
    const code = props.form.getFieldValue("code");
    exe("RepoTransactionTemplate", { operation: "DELETE", entity: { code: code } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        props.onDelete();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExecute = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setExecuting(true);
      exe("ExeTransactionTemplate", { code: values.code }).then((r) => {
        setExecuting(false);
        if (r.ok) {
          message.success(r.msg);
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const field = props.form.getFieldDecorator;
  return (
    <div>
      <Button type="link" icon={<SaveOutlined />} onClick={onSave} loading={loading}>
        {t("Save")}
      </Button>
      <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={mode == "ADD"}>
        <Button type="link" icon={<DeleteOutlined />} disabled={mode == "ADD"}>
          {t("Delete")}
        </Button>
      </Popconfirm>
      <Button type="link" icon={<RocketOutlined />} disabled={mode == "ADD"} onClick={onExecute} loading={executing}>
        {t("Execute Now")}
      </Button>
      <div style={{ display: "flex" }}>
        <Form.Item label={t("Template Code")}>{field("code", { rules: [{ required: true }] })(<Input disabled={mode == "UPDATE"} />)}</Form.Item>
        <Form.Item label={t("Template Name")} style={{ marginLeft: 5, flex: 1 }}>
          {field("name", { rules: [{ required: true }] })(<Input />)}
        </Form.Item>
        <Form.Item label={t("Active")} style={{ marginLeft: 5 }}>
          {field("active", { valuePropName: "checked" })(<Switch />)}
        </Form.Item>
      </div>

      <Form.Item label={t("Behaviour")} />
      <CodeEditorW divId="templateBehaviour" />
    </div>
  );
};

export default Form.create()(TransactionTemplate);
