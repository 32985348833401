import React, { Component, useEffect, useState } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Drawer,
    Input,
    Table,
    message,
    Button,
    Select,
    Radio,
    Row,
    Col,
    DatePicker,
    Tag,
    InputNumber,
} from "antd";
import { useTranslation } from "react-i18next";
import StatusSelect from "../Shared/StatusSelect";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

const ComContractListFilter = (props) => {
    const [t, i18n] = useTranslation();
    const [matches, setMatches] = useState(undefined);
    const [loading, setLoading] = useState(false);
    

    const submit = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            console.log("Received values of form: ", values);
            props.onSearch(values);
        });
    };
    const { getFieldDecorator } = props.form;
    return (
        <Drawer title={t("Commission Contract Filter")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
            <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
                    {t("Reset")}
                </Button>
            </div>

            <Form layout="vertical">
                <Form.Item label={t("ID")}>{getFieldDecorator("id")(<InputNumber />)}</Form.Item>
                <Form.Item label={t("Status")}>{getFieldDecorator("active")(<StatusSelect />)}</Form.Item>
         
            </Form>
            <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()} loading={loading}>
                {t("Search")}
            </Button>
            {matches >= 0 && (
                <div style={{ marginTop: 15 }}>
                    <Tag>{matches}</Tag> {t("Results")}
                </div>
            )}
        </Drawer>
    );
};
export default Form.create()(ComContractListFilter);
