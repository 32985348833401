import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import {useTranslation} from "react-i18next";

const EventReason = (props) => {
  const [data, setData] = useState([]);
  const [t]=useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    exe("RepoEventReasonCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const validValues = props.validValues || []; 
  return (
    <div>
      <Select disabled={props.disabled} value={props.value ? props.value : undefined} style={props.style}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(v, opt) => props.onChange(v)}>
        {data.filter(q=>validValues.includes(q.code)||props.noValidation).map((p) => (
          <Select.Option value={p.code} record={p}  disabled={p.disabled}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default EventReason;
