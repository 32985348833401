import React from "react";
import { Typography } from "antd";
import { diff, formatters } from "jsondiffpatch";
import "jsondiffpatch/dist/formatters-styles/html.css";

const { Text } = Typography;

const FieldComparison = (props) => {
  if (!props.before || !props.after) return null;
  const before = JSON.parse(props.before.replace("T00:00:00", ""));
  const after = JSON.parse(props.after.replace("T00:00:00", ""));

  //const fields = Object.keys(before);

  const getDiff = () => {
    const differences = diff(before, after);
    return formatters.html.format(differences, before);
  };

  return <div dangerouslySetInnerHTML={{ __html: getDiff() }} />;
};

export default FieldComparison;
