import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const ClosureUndoMotiveSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const defaultValues=[
    {
      "code": "COM",
      "name": "Communication received",
    },
    {
      "code": "ERR",
      "name": "Closed by mistake",
    },
    {
      "code": "LIT",
      "name": "Went to litigation",
    },
    {
      "code": "OTH",
      "name": "Other",
    }
  ]
  const load = () => {
    setLoading(true);
    exe("RepoClosureUndoMotiveCatalog", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        if(r.total===0) setData(defaultValues);
        else {
          setData(r.outData.map(p=>({...p,lobsArray:p.lobs?p.lobs.split(","):[]})) );
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        showArrow
        value={props.value}
        onChange={props.onChange}
        style={props.style}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children">
        {data.filter(p=>(p.lobsArray.length&&props.lob)?p.lobsArray.includes(props.lob):true).map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ClosureUndoMotiveSelect;
