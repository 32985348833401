import { Select, Table, Tag, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate, formatMoney } from "../../Lib/Helpers";

const BillingPayPlan = (props) => {
  const [t] = useTranslation();
  const data = JSON.parse(props.value);
  const [viewMode, setViewMode] = useState(0);
  const currency = props.currency;
  const isGroupPolicy = props.isGroupPolicy;
  const hasPremiumPlan = props.hasPremiumPlan;
  const payPlanMode = props.payPlanMode;
  const pastDate = (date) => new Date(date) < new Date();
  const filteredData = viewMode == 0 ? data.filter((p) => !p.cancellationDate) : data;
  return (
    <div>
      <Select style={{ marginBottom: 5 }} value={viewMode} onChange={(v) => setViewMode(v)}>
        <Select.Option value={0}>{t("Show Final")}</Select.Option>
        <Select.Option value={2}>{t("Compare with previous")}</Select.Option>
      </Select>
      <Table dataSource={filteredData} pagination={{ pageSize: 24 }} scroll={{ x: true }}>
        <Table.Column title={t("Ref")} dataIndex="id" key="ref" />
        {isGroupPolicy && payPlanMode == "C" && (
          <Table.Column title={t("Certificate")} dataIndex="lifePolicyId" render={(v) => <a href={"#/lifepolicy/" + v}>{v}</a>} />
        )}
        <Table.Column title={t("Concept")} dataIndex="concept" render={(v) => <span style={{ whiteSpace: "nowrap" }}>{v}</span>} />
        {hasPremiumPlan && <Table.Column title={t("Expected")} dataIndex="expected" />}
        <Table.Column
          title={hasPremiumPlan ? t("Minimum") : t("Amount Due")}
          dataIndex="minimum"
          render={(v, r) => (r.cancellationDate ? <Typography.Text delete>{formatMoney(v, r.currency)}</Typography.Text> : <span style={{whiteSpace:"nowrap"}}> {formatMoney(v, r.currency)}</span>)}
        />
        <Table.Column title={t("Paid")} dataIndex="payed" />
        <Table.Column title={t("Paid Date")} dataIndex="payedDate" render={(v) => v && formatDate(v)} />
        <Table.Column
          title={t("Due Date")}
          dataIndex="dueDate"
          render={(v, r) => (!r.payed && pastDate(v) ? <Tag color="red">{formatDate(v)}</Tag> : formatDate(v))}
        />
        <Table.Column title={t("# in Year")} dataIndex="numberInYear" />
        <Table.Column title={t("Contract Year")} dataIndex="contractYear" />
        <Table.Column title={t("Allocation")} dataIndex="allocationId" render={(v) => <a href={"#/allocation?id=" + v}>{v}</a>} />
      </Table>
    </div>
  );
};

export default BillingPayPlan;
