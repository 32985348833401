import React from "react";

const Section = props => {
  return (
    <div style={{display:props.hidden?"none":"block"}}>
      <div
        style={{
          backgroundColor: "#f7f7fa",
          borderRadius: 8,
          paddingLeft: 12,
          paddingRight: 12,
          paddingTop: 8,
          paddingBottom: 8,
          color: "#232333",
          fontSize: 13,
          fontWeight: 600,
          marginTop: 8,
          marginBottom: 8,
          lineHeight: "16px",
          boxSizing: "border-box"
        }}>
        {props.text}
      </div>
      <div style={{ paddingLeft: 12 }}>{props.children}</div>
    </div>
  );
};

export default Section;
