import React, {useEffect} from 'react';
import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, InputNumber, Popconfirm, Row } from "antd";
import {useTranslation} from "react-i18next";
import FormLabel from "../Shared/FormLabel";

const CustomPlanOptionsForm = (props) => {
    const [t]=useTranslation();

    useEffect(()=>{
        if(props.selected) props.form.setFieldsValue(props.selected);
    },[props.selected])

    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onSave(values);
        })
    }
    const field=props.form.getFieldDecorator;
    return (
        <div>
            <Button type="link" icon={<LeftOutlined />} onClick={props.onBack}>{t("Back")}</Button>
            <Button type="link" icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
            <Row gutter={16}>
                <Col span={12}>
                    {field("Coverages")}
                    {field("computedSumInsured")}
                    <Form.Item label={t("Code")}>{field("code")(<FormLabel />)}</Form.Item>
                    <Form.Item label={t("Name")}>{field("name")(<FormLabel />)}</Form.Item>
                    <Form.Item label={t("Sum Insured")}>{field("sumInsured",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>

                </Col>
            </Row>
        </div>
    );
};

export default Form.create()(CustomPlanOptionsForm);