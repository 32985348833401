import { CheckOutlined, WarningOutlined } from '@ant-design/icons';
import { Table } from "antd";
import React from "react";
import { formatDate } from " ../../Lib/Helpers";
import { useTranslation } from "react-i18next";

const ProcessEvents = (props) => {
  const [t, i18n] = useTranslation();
  return (
    <div>
      <Table dataSource={props.value} rowKey="id">
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
        <Table.Column title={t("Event Name")} dataIndex="name" />
        <Table.Column title={t("Result")} dataIndex="ok" render={(v) => (v ? <CheckOutlined /> : <WarningOutlined />)} />
        <Table.Column title={t("Message")} dataIndex="msg" />
      </Table>
    </div>
  );
};

export default ProcessEvents;
