import React, {useEffect, useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Modal, message, Input } from "antd";
import {useTranslation} from "react-i18next";
import AccountSelect from "../Shared/AccountSelect";
import {exe} from "../../Lib/Dal";
import Currency from "../Shared/Currency";
import ExternalSourceSelect from "./ExternalSourceSelect";
import AccountGroupSelect from "./AcountGroupSelect";
import PaymentMethods from "../Life/PaymentMethods";
import {round2} from "../../Lib/Helpers";
import CutOff from "./CutOff";
import Money from "../Shared/Money";

const CashierDeposit = (props) => {
    const [t]=useTranslation();
    const [loading,setLoading]=useState();
    
    useEffect(()=>{
        if(props.currency){
            props.form.setFieldsValue({currency:props.currency});
            if(props.paymentMethod){
                props.form.setFieldsValue({paymentMethod:props.paymentMethod});
                getExpectedAmount(props.paymentMethod,props.currency);
            }
        }
        
    },[props.currency,props.paymentMethod])
    
    const clearCutoff=()=> {
        //checking if cutOff key exists in local storage
        const savedData=localStorage.getItem("cutOff");
        if(savedData){
            const parsed=JSON.parse(savedData);
            const updatedData=parsed.filter(p=>!(p.workspaceId===props.workspaceId&&p.currency===values.currency&&p.paymentMethod===values.paymentMethod));
            localStorage.setItem("cutOff",JSON.stringify(updatedData));
        }
        
    }
    const onOk=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            setLoading(true);
            exe("DepositCashierPayments",{workspaceId:props.workspaceId,...values,amount:values.amountAtSight}).then(r=>{
                setLoading(false);
                if(r.ok){
                    props.onOk(r);
                    //clearing cut-off control for this currency and payment method
                    clearCutoff();
                    
                }else message.error(r.msg);
            })
        })
    }
    const onChangePaymentMethod=v=> {
        if(v&&values.currency){
            getExpectedAmount(v,values.currency);
        }
    }
    const onChangeCurrency=v=> {
        if(v&&values.paymentMethod){
            getExpectedAmount(values.paymentMethod,v);
        }
    }
    
    const getExpectedAmount=(paymentMethod,currency)=> {
        setLoading(true);
        exe("GetExpectedDeposit",{workspaceId:props.workspaceId,currency:currency,paymentMethod:paymentMethod}).then(r=>{
            setLoading(false);
            if(r.ok){
                props.form.setFieldsValue({amount:r.outData.expectedAmount});
            }else message.error(r.msg);
        })
    }
    const onChangeAmountAtSight=v=> {
        props.form.setFieldsValue({dif:round2(v-props.form.getFieldValue("amount"))});
    }
    
    const field=props.form.getFieldDecorator;
    const values=props.form.getFieldsValue();
    const mismatch=values.amountAtSight!==values.amount;
    return (
        <div>
            <Modal title={t("Deposit")} visible={props.visible} onCancel={props.onCancel} okText={t("Deposit")} 
                   onOk={onOk} okButtonProps={{loading:loading}} destroyOnClose>
                <Form.Item label={t("Payment Method")} >
                    {field("paymentMethod",{initialValue:props.paymentMethod,rules:[{required:true}]})(<PaymentMethods module="INCOME" onChange={onChangePaymentMethod}  style={{width:"100%"}}/>)}
                </Form.Item>
                <Form.Item label={t("Currency")}>
                    {field("currency",{initialValue:props.currency,rules:[{required:true}]})(<Currency onChange={onChangeCurrency}  style={{width:"100%"}} />)}
                </Form.Item>
                <div style={{display:"flex"}}>
                    <Form.Item label={t("Amount Deposited")}  >
                        {field("amountAtSight",{rules:[{required:true}]})(<Money currency={values.currency} onChange={onChangeAmountAtSight} min={0} style={{width:"100%"}} />)}
                    </Form.Item>                    
                    <Form.Item label={t("Cut-off")}  >
                        <CutOff workspaceId={props.workspaceId} currency={values.currency} paymentMethod={values.paymentMethod} expected={values.amount} />
                    </Form.Item>
                </div>
                <Form.Item label={t("Amount Expected")}>
                    {field("amount",{rules:[{required:true}]})(<Money currency={values.currency} min={0} disabled style={{width:"100%"}} />)}
                </Form.Item>
                <Form.Item label={t("Difference")} hasFeedback validateStatus={mismatch?"warning":"success"}>
                    {field("dif",)(<Money currency={values.currency}  disabled style={{width:"100%"}} />)}
                </Form.Item>
                <Form.Item label={t("Destination")} >
                    {field("destinationAccountId",{rules:[{required:true}]})(<AccountGroupSelect group={"DEPOSITS"} currency={values.currency} style={{width:"100%"}}/>)}
                </Form.Item>
                <Form.Item label={t("Reference")} >
                    {field("concept",)(<Input />)}
                </Form.Item>
            </Modal>
        </div>
    );
};

export default Form.create()(CashierDeposit);