import React, { Component } from "react";
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Modal, Col, InputNumber, Select, Button, Tooltip } from "antd";
import Money from "../Shared/Money";
import Percentage from "../Shared/Percentage";
import { equal } from "../../Lib/Dal";
import RangeSelect from "../Shared/RangeSelect";
import { withTranslation } from "react-i18next";

class ProjectionParameters extends Component {
  state = {
    multiPremium: false,
  };
  componentDidMount() {
    if (this.props.defaultValues) this.props.form.setFieldsValue(this.props.defaultValues);
  }
  componentDidUpdate(prevProps, prevState) {
    if (!equal(this.props.defaultValues, prevProps.defaultValues)) {
      this.props.form.setFieldsValue(this.props.defaultValues);
    }
  }
  onOk() {
    this.props.onOk(this.props.form.getFieldsValue());
  }
  onMultiPremium() {
    this.setState({ multiPremium: !this.state.multiPremium });
  }
  render() {
    const { t, i18n } = this.props;
    const field = this.props.form.getFieldDecorator;
    const age = this.props.form.getFieldValue("age");

    return (
      <Modal title={t("Projection Parameters")} visible={this.props.visible} onCancel={() => this.props.onCancel()} onOk={() => this.onOk()}>
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={t("Age")}>{field("age", { initialValue: 30, rules: [{ required: true }] })(<InputNumber />)}</Form.Item>
              <Form.Item label={t("Benefit")}>{field("benefit", { initialValue: 1000000 })(<Money currency={this.props.currency} />)}</Form.Item>
              <Form.Item label={t("Minimum ROE")}>{field("roe_min", { initialValue: 5, rules: [{ required: true }] })(<Percentage />)}</Form.Item>
              <Form.Item label={t("Assumed ROE")}>{field("roe", { initialValue: 5, rules: [{ required: true }] })(<Percentage />)}</Form.Item>
              <Form.Item
                label={
                  <span>
                    {t("Premium")}
                    {this.state.multiPremium && " " + t("Plan")}
                    <Tooltip title={t("Toggle premium plan")}>
                      <Button type="link" onClick={() => this.onMultiPremium()}>
                        <LegacyIcon type={!this.state.multiPremium ? "plus-circle" : "minus-circle"} />
                      </Button>
                    </Tooltip>
                  </span>
                }>
                {!this.state.multiPremium && field("premium", { initialValue: 2500 })(<Money currency={this.props.currency} />)}
                {this.state.multiPremium && field("premiumJson")(<RangeSelect />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t("Option")}>
                {field("option", { initialValue: 1 })(
                  <Select>
                    <Select.Option value={1}>{t("1-Benefit")}</Select.Option>
                    <Select.Option value={2}>{t("2-Benefit+Cash Value")}</Select.Option>
                  </Select>
                )}
              </Form.Item>
              <Form.Item label={t("Duration")}>
                {field("duration", { initialValue: 100 - age, rules: [{ required: true, max: 100 - age, min: 1, type: "integer" }] })(<InputNumber />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

const wrappedProjectionParameters = Form.create()(ProjectionParameters);
export default withTranslation()(wrappedProjectionParameters);
