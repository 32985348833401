import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const OccupationSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  useEffect(() => load(), []);

  useEffect(() => {
    if (props.sectorCode) {
      const filteredData = allData.filter((p) => p.sectorCode == props.sectorCode);
      setData(filteredData);
      if (filteredData.length > 0 && props.value && !filteredData.some((p) => p.id == props.value)) props.onChange(undefined);
    } else {
      setData(allData);
    }
  }, [props.sectorCode]);

  const load = () => {
    const filter = props.sectorCode ? `sectorCode='${props.sectorCode}'` : undefined;
    exe("RepoOccupation", { operation: "GET", filter: filter }).then((r) => {
      setData(r.outData);
      setAllData(r.outData);
    });
  };
  const onChange = (v) => {
    props.onChange(v);
  };
  const value = props.value;
  return (
    <div>
      <Select
        style={{ width: "100%" }}
        placeholder={t("Please select occupation")}
        onChange={onChange}
        value={value}
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {data.map((p) => (
          <Select.Option value={p.id} key={p.id}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default OccupationSelect;
