import { RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, message, Result, Row, Steps, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import ClaimsPerEvent from "./ClaimsPerEvent";

const LiquidationCatRecord = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [reEventId, setReEventId] = useState();
  const [liquidations, setLiquidations] = useState([]);

  const field = props.form.getFieldDecorator;
  const contractId = props.contractId;

  const onStepTwo = () => {
    computeContract();
    setCurrentStep(1);
  };
  const computeContract = (values, save) => {
    setLoading(true);
    exe("ComputeCatRe", {
      contractId: contractId,
      reEventId: reEventId,
      save: false,
    }).then((r) => {
      setLoading(false);
      setLiquidations(r.outData);
      if (r.ok) {
      } else message.error(r.msg);
    });
  };
  const onStepThree = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("ComputeCatRe", {
        name: values.liquidationName,
        contractId: contractId,
        reEventId: reEventId,
        save: true,
      }).then((r) => {
        setLoading(false);
        setLiquidations(r.outData);
        setCurrentStep(2);
        if (r.ok) {
        } else message.error(r.msg);
      });
    });
  };

  const onExecute = () => {
    props.onExecute && props.onExecute(liquidations);
    setCurrentStep(0);
    props.form.resetFields();
    setReEventId(); //reseting event selection
  };
  const onExecuteLater = () => {
    props.onSave && props.onSave();
    setCurrentStep(0);
    props.form.resetFields();
    setReEventId(); //reseting event selection
  };

  return (
    <div>
      <Button onClick={() => props.onCancel && props.onCancel()}>Cancel</Button>
      <Steps current={currentStep} type="navigation" onChange={(p) => setCurrentStep(p)}>
        <Steps.Step title={t("Event Selection")} />
        <Steps.Step title={t("Liquidation Info")} />
        <Steps.Step title={t("Result")} />
      </Steps>
      <div style={{ marginTop: 10, display: currentStep == 0 ? "block" : "none" }}>
        <ClaimsPerEvent contractId={props.contractId} value={reEventId} onChange={(v) => setReEventId(v)} />

        <Button type="primary" icon={<RightOutlined />} onClick={() => onStepTwo()}>
          {t("Next")}
        </Button>
      </div>
      <div style={{ marginTop: 10, display: currentStep == 1 ? "block" : "none" }}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label={t("Liquidation Name")}>{field("liquidationName", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          </Col>
        </Row>
        <Table dataSource={liquidations}>
          <Table.Column title={t("Line")} dataIndex="line" />
          <Table.Column title={t("Retained Loss")} dataIndex="retainedLoss" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Ceded Loss")} dataIndex="cededLoss" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Claims")} dataIndex="claims" />
        </Table>
        <Button type="primary" icon={<SaveOutlined />} onClick={() => onStepThree()}>
          {t("Next")}
        </Button>
      </div>
      <div style={{ marginTop: 10, display: currentStep == 2 ? "block" : "none" }}>
        <Result
          status="success"
          title={t("Liquidation saved")}
          subTitle={t("When executed, this process will perform the corresponding transfers between accounts")}
          extra={[
            <Button type="primary" key="execute" onClick={() => onExecute()}>
              {t("Execute")}
            </Button>,
            <Button key="later" onClick={() => onExecuteLater()}>
              {t("Execute later")}
            </Button>,
          ]}
        />
      </div>
    </div>
  );
};

export default Form.create()(LiquidationCatRecord);
