import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { MinusCircleOutlined, PlusCircleOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip, Menu, Dropdown, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PolicySelect from "../Health/PolicySelect";
import Money from "../Shared/Money";
import PremiumSelect from "./PremiumSelect";

const InstallmentPremiumAllocation = (props) => {
  const [t] = useTranslation();
  //const [rows, setRows] = useState([{ policy: null, premium: null, moneyInAmount: undefined, transitAmount: undefined }]);
  const initialValue = {
    lifePolicyId: null,
    payPlanId: null,
    dueAmount: undefined,
    moneyInAmount: 0,
    transitAmount: 0,
    compensationAmount: 0,
    currency: props.currency,
  };
  const rows = props.value || [];
  const setRows = (v) => props.onChange(v);
  const onChangeFromMoneyInAmount = (amount, index, row) => {
    console.log("From Money In", amount, index, row);
    if (amount == row.moneyInAmount) return; //no change, just a click
    if (isNaN(amount)) return;

    setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, moneyInAmount: amount } : r)));
  };
  const onChangeFromTransitAmount = (amount, index, row) => {
    console.log("From Transit", amount, index, row);
    if (amount == row.transitAmount) return; //no change, just a click
    if (isNaN(amount)) return;

    setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, transitAmount: amount } : r)));
  };
  return (
    <div>
      {rows.map((row, index) => (
        <div>
          <div style={{ display: "flex", width: "100%", marginTop: 5 }}>
            <div style={{ width: "100%" }}>
              {index === 0 && t("Policy")}
              <PolicySelect
                value={row.lifePolicyId}
                proposals
                onSelect={(v, p) => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, lifePolicyId: v } : r)))}
              />
            </div>
            <div
              style={{
                width: "100%",
                marginLeft: 5,
              }}>
              {index === 0 && t("Premium")}
              <div style={{ display: "flex", alignItems: "center" }}>
                <PremiumSelect
                  value={row.payPlanId}
                  policyId={row.lifePolicyId}
                  onSelect={(v, p) => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, payPlanId: v, dueAmount: p.minimum,currency:p.currency } : r)))}
                />
                <Tooltip title={t("Full Premium Payment Status")}>
                  <LegacyIcon type={row.dueAmount !== row.moneyInAmount + row.transitAmount + row.compensationAmount ? "stop" : "check"} style={{ marginLeft: 3 }} />
                </Tooltip>
              </div>
            </div>
            <div style={{ width: "100%", marginLeft: 5 }}>
              {index === 0 && t("From Money In")}
              <Money value={row.moneyInAmount} onChange={(v, p) => onChangeFromMoneyInAmount(v, index, row)} currency={row.currency} min={0} />
            </div>
            <div style={{ marginLeft: 5, width: "100%" }}>
              {index === 0 && t("From Transit Account")}
              <div style={{ display: "flex" }}>
                <Money
                  value={row.transitAmount}
                  onChange={(v, p) => onChangeFromTransitAmount(v, index, row)}
                  currency={row.currency}
                  style={{ width: "100%" }}
                  min={0}
                />
                <MinusCircleOutlined
                  style={{ marginLeft: 5, cursor: "pointer", fontSize: 24, color: "#999", alignSelf: "center" }}
                  onClick={() => setRows(rows.filter((r, rIndex) => rIndex !== index))} />
              </div>
            </div>
          </div>

          {row.compensationType && (
            <div style={{ display: "flex", marginTop: 5 }}>
              <div style={{ width: "100%" }}>
                <Select
                  placeholder={t("Source Type")}
                  value={row.compensationType}
                  onChange={(v, p) => {
                    
                    if(v==="PARTIAL"){
                      //setting transit amount as default value for partial installment
                        setRows(rows.map((r, rIndex) => (rIndex === index ? { ...row,compensationType: v,compensationAmount:row.transitAmount, transitAmount: 0 } : r)));
                        }else setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, compensationType: v } : r)));
                  }
                  }>
                  <Select.Option value="SMALL">{t("From small amounts account")}</Select.Option>
                  <Select.Option value="WAIVER">{t("Waiver of premium")}</Select.Option>
                  <Select.Option value="COMMISSION">{t("Partner commission")}</Select.Option>
                  <Select.Option value="PARTIAL">{t("Partial Installment")}</Select.Option>
                </Select>
              </div>
              <div style={{ width: "100%", marginLeft: 5 }}>
                <Money
                  value={row.compensationAmount}
                  onChange={(v, p) => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, compensationAmount: v } : r)))}
                  currency={row.currency}
                  min={0}
                />
              </div>
              <div style={{ width: "100%", marginLeft: 5 }}>
                <Button
                  type="link"
                  icon={<MinusCircleOutlined />}
                  style={{ marginTop: 5, marginLeft: 0 }}
                  onClick={() => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, compensationType: null, compensationAmount: 0 } : r)))}>
                  {t("Remove Source")}
                </Button>
              </div>
              <div style={{ width: "100%", marginLeft: 5 }}></div>
            </div>
          )}

          {!row.compensationType && (
            <Button
              type="link"
              icon={<PlusOutlined />}
              style={{ marginTop: 5, marginLeft: 0 }}
              onClick={() => setRows(rows.map((r, rIndex) => (rIndex == index ? { ...row, compensationType: "SMALL" } : r)))}>
              {t("Other Source")}
            </Button>
          )}
        </div>
      ))}

      <Button type="link" icon={<PlusCircleOutlined />} style={{ marginTop: 5, marginLeft: 0 }} onClick={() => setRows([...rows, initialValue])}>
        {t("Add Row")}
      </Button>
      <Dropdown overlay={<Menu>
            <Menu.Item onClick={props.completeWithCommission}>
                    {t("Complete with available commission")}
            </Menu.Item>
            </Menu>}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Space>{t("Options")}<DownOutlined /></Space>
            </a>
        </Dropdown>
    </div>
  );
};

export default InstallmentPremiumAllocation;
