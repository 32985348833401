import { LoadingOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from "react";
import config from "../../config";

const ReportView = (props) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.getElementById("reportFrame").onload = function () {
      setLoading(false);
    };
  }, []);

  const reportName = props.match.params.report;
  const queryString = props.match.params.queryString ? "&" + props.match.params.queryString : "";

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {loading && (
        <span style={{ marginTop: 8, marginLeft: 8 }}>
          <LoadingOutlined style={{ fontSize: 18, marginRight: 8 }} />
        </span>
      )}
      <iframe
        frameBorder="0"
        id="reportFrame"
        style={{ width: "100%", height: "100%", marginLeft: "-24px", marginTop: "-12px" }}
        src={config.reportsUrl + `/Home/Viewer29ZQJsnwb8BsVR9a?token=${localStorage.token}&report=${reportName}${queryString}`}
      />
    </div>
  );
};

export default ReportView;
