import React, {useEffect, useState} from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, message } from "antd";
import Money from "../Shared/Money";
import Periodicty from "../Shared/Periodicty";
import PaymentMethods from "./PaymentMethods";
import { useTranslation } from "react-i18next";
import { round2 } from "../../Lib/Helpers";
import {exe, safeGet} from "../../Lib/Dal";
import ReceiptTypeSelect from "../Tax/ReceiptTypeSelect";
import FeeDetailField from "./FeeDetailField";
import TaxDetailField from "../Tax/TaxDetailField";

const BillDiff = (props) => {
    const [t] = useTranslation();
    const field = props.form.getFieldDecorator;
    const currency = props.currency;

    useEffect(() => {
        props.form.setFieldsValue(props.value);
    }, [props.value]);
    
    const values=props.form.getFieldsValue();
    return (
        <Form layout="vertical">
            <Row gutter={16}>
                <Col span={12}>
                    {field("id")}
                    {field("jFees")}
                    {field("jTaxes")}
                    <Form.Item label={t("Coverage Premiums")}>{field("coverages", {})(<Money currency={currency} disabled />)}</Form.Item>
                    <Form.Item label={t("Surcharges")}>{field("surcharges", {})(<Money currency={currency} disabled />)}</Form.Item>
                    <Form.Item label={t("Discounts")}>{field("discounts", {})(<Money currency={currency} disabled />)}</Form.Item>
                    <Form.Item label={t("Annual Premium")}>{field("annualPremium", {})(<Money currency={currency} disabled />)}</Form.Item>
                    <Form.Item label={t("Tax")}>{field("tax", {})(<TaxDetailField currency={currency} jTaxes={values.jTaxes||-1} disabled />)}</Form.Item>
                    <Form.Item label={t("Fee")}>{field("fee", {})(<FeeDetailField currency={currency} jFees={values.jFees||-1} disabled/>)}</Form.Item>
                    <Form.Item label={t("Total")}>{field("annualTotal", {})(<Money currency={currency} disabled />)}</Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={t("Modal Premium")}>{field("installment", {})(<Money currency={currency} disabled/>)}</Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default Form.create()(BillDiff);
