import React, { useState, useEffect } from "react";
import { Typography, Button, Input, message } from "antd";
import QRCode from "qrcode.react";
import { exe } from "../Lib/Dal";
import { useTranslation } from "react-i18next";

const Otp = (props) => {
  const [t] = useTranslation();
  const [token, setToken] = useState(null);
  const [qrLink, setQrLink] = useState("https://www.microsoft.com/es-es/account/authenticator");
  const [secret, setSecret] = useState("");

  useEffect(() => {
    if (props.creating) getQr();
  }, []);

  const getQr = () => {
    exe("GetOtp").then((r) => {
      setQrLink(r.outData.otpauth_url.replace("SecretKey", "SISos"));
      setSecret(r.outData.base32);
    });
  };

  const verifyToken = () => {
    if (props.creating || props.disabling) {
      exe("ValidateOtp", { secret: props.disabling ? props.secret : secret, token: token, creating: props.creating, disabling: props.disabling }).then((r) => {
        if (!r.ok) return;
        if (r.outData.valid) {
          if (props.creating) {
            message.success(t("Token verified and linked to account"));
            window.location.reload(false);
          }
          if (props.disabling) {
            message.success(t("2FA disabled"));
            window.location.reload(false);
          }
        } else {
          message.error(t("Invalid Token"));
        }
      });
    } else {
      props.onTryOtp(token);
    }
  };

  return (
    <div>
      <Typography.Text>
        <ol>
          <li>{t("Download Microsoft Authenticator app for your smartphone")}</li>
          <li>{t("Scan de QR code")}</li>
          <li>
            {t("Once you have scanned the QR code, the Authenticator app will provide you with a unique code. Enter the code in the confirmation box below")}:
          </li>
        </ol>
      </Typography.Text>
      <Button type="link" onClick={() => getQr()} disabled={!props.creating}>
        {t("Renew QR Code")}
      </Button>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <QRCode value={qrLink} />
      </div>
      <br />
      <Input
        onKeyDown={(e) => {
          if (e.keyCode === 13) verifyToken();
        }}
        autoFocus
        placeholder={t("Verification Code")}
        value={token}
        onChange={(v) => setToken(v.target.value)}
      />
      <Button style={{ marginTop: 15 }} type="primary" onClick={() => verifyToken()}>
        {props.creating ? "Enable" : props.disabling ? "Disable" : "Verify"}
      </Button>
    </div>
  );
};

export default Otp;
