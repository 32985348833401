import { FileOutlined, SettingOutlined, ThunderboltOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Alert,
  Button,
  Checkbox,
  Dropdown,
  Input,
  Menu,
  message,
  Select,
  Table,
  Tabs,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import OperationSelect from "../Bpm/OperationSelect";
import DefaultPage from "../Shared/DefaultPage";
import Section from "../Shared/Section";
import Spreadsheet from "../Shared/Spreadsheet";
import Batch from "./Batch";
import BatchList from "./BatchList";
import ChainSelect from "../Bpm/ChainSelect";
import RecordImportListModal from './RecordImportListModal';

const RecordImport = (props) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [batchVisible, setBatchVisible] = useState(false);
  const [rows, setRows] = useState([]);
  const [reloadBatches, setReloadBatches] = useState(0);
  const [activeTab, setActiveTab] = useState("records");

  const [settingsSS, setSettingsSS] = useState(null);
  const [importTypes, setImportTypes] = useState([]);
  const [selectedImportType, setSelectedImportType] = useState(null);
  const [processingType, setProcessingType] = useState(0);
  const [fileName,setFileName]=useState();
  const [recordImportListVisible, setRecordImportListVisible] = useState(false);
  
  const [largeDataRows,setLargeDataRows]=useState();

  useEffect(() => load(), []);
  useEffect(() => {
    //when spreadsheet loaded
    if (props.match.params.importId) {
      const record = importTypes.find((p) => p.id == props.match.params.importId);
      if (record) onSelectImportType(props.match.params.importId, record);
    }
  }, [importTypes]);

  useEffect(() => {

    // returned function will be called on component unmount 
    return () => {
      window.largeData=null;
    }
  }, [])

  const load = () => {
    exe("RepoImportConfig", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setImportTypes(r.outData);
        if (selectedImportType) {
          //updateing selected record
          onSelectImportType(selectedImportType.id, r.outData.filter((p) => p.id === selectedImportType.id)[0]);
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  const save = () => {
    if (!selectedImportType) return;
    const newRecord = {
      id: selectedImportType.id ? selectedImportType.id : 0,
      name: selectedImportType.name,
      targetEntity: selectedImportType.targetEntity,
      operation: selectedImportType.operation,
      preProcessorId: selectedImportType.preProcessorId,
      postOperationId: selectedImportType.postOperationId,
      preOperationId: selectedImportType.preOperationId,
      category: selectedImportType.category,
      bulk: selectedImportType.bulk,
      jMappings: JSON.stringify(settingsSS.getData()),
    };
    exe("RepoImportConfig", { operation: newRecord.id ? "UPDATE" : "ADD", entity: newRecord }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSelectImportType = (type, record) => {
    if (type) {
      setSelectedImportType(record);
      const mappingData = JSON.parse(record.jMappings);
      settingsSS.setData(mappingData);
      const columns = mappingData.map((p) => ({ title: p[0], width: 100 }));
      //recreating
      window.spreadsheet.destroy();
      const jexcel = window.spreadsheet.jexcel;
      window.spreadsheet = window.spreadsheet.jexcel(window.spreadsheet.el, { columns: columns, data: [[]], ...jexcel.myDefaultOptions });
      window.spreadsheet.jexcel = jexcel;
      window.location.hash = "#/import/" + record.id;
      setLargeDataRows(undefined);
      window.largeData=null;
    } else {
      setSelectedImportType(null);
      settingsSS.setData([]);
    }
  };
  // const importData = () => {
  //   setLoading(true);
  //   exe("Import", { importConfigName: selectedImportType.name, rows: JSON.stringify(window.spreadsheet.getData()) }).then((r) => {
  //     setLoading(false);
  //     if (r.ok) {
  //       message.success(r.msg);
  //       //spreadsheet.insertColumn(0,null,null,[{title:"Result",width:100}]);
  //       // if (window.spreadsheet.getHeaders().indexOf("Msg,Id,Result") > -1) {
  //       //   //already has results. Replacing
  //       //   const cols = window.spreadsheet.getConfig().columns.length;
  //       //   window.spreadsheet.deleteColumn(cols - 3, 3);
  //       // }
  //       window.spreadsheet.insertColumn(r.outData.map((p) => p.id).concat(0), null, null, [{ title: "Id", width: 100 }]);
  //       window.spreadsheet.insertColumn(r.outData.map((p) => p.ok).concat(0), null, null, [{ title: "Result", width: 50, type: "checkbox" }]);
  //       window.spreadsheet.insertColumn(r.outData.map((p) => p.msg).concat(0), null, null, [{ title: "Result Msg", width: 300 }]);
  //       console.log(r.outData);
  //     } else {
  //       message.error(r.msg);
  //     }
  //   });
  // };

  const onShowBatch = (type = 0) => {
    setProcessingType(type);
    const rows = window.spreadsheet.getData();
    setRows(rows);
    setBatchVisible(true);
  };
  const openBatch = (batch,withResult=true) => {
    const importType = importTypes.find((p) => p.id == batch.importConfigId);
    onSelectImportType(batch.importConfigId, importType);
    const parsedData = JSON.parse(batch.jData);
    window.spreadsheet.setData(parsedData);
    if(withResult){
      //appending results along each record
      window.spreadsheet.insertColumn(parsedData.map((p) => p[p.length - 2]).concat(0), null, null, [{ title: "Result", width: 50, type: "checkbox" }]);
      window.spreadsheet.insertColumn(parsedData.map((p) => p[p.length - 1]).concat(0), null, null, [{ title: "Result Msg", width: 300 }]);
    }
    window.spreadsheet.allData= window.spreadsheet.getData(); //filtering purposes

    setActiveTab("records");
    window.largeData=null;
    setLargeDataRows(undefined);
  };
  const settingsColumns = [
    //window.spreadsheet columns
    { title: t("Title"), width: 100 },
    { title: t("Map"), width: 400 },
    { title: t("Column"), width: 100 },
    // { title: "Name", width: 200 },
    // { title: "Parent", width: 200 },
    // { title: "Level", readOnly: true },
    // { title: "Leaf", readOnly: true, type: "checkbox" },
  ];
  return (
    <div>
      <DefaultPage title={t("Import & Batch Operations")} icon="upload" loading={loading}>
        <Tabs activeKey={activeTab} onTabClick={(tab) => setActiveTab(tab)}>
          {/* -------------------------DATA TAB ------------------------ */}
          <Tabs.TabPane tab={t("Records")} key="records" forceRender ac>
            <div style={{ display:"flex", marginBottom: 15 }}>
              <Select
                style={{ width: 250, marginRight: 5 }}
                allowClear
                placeholder={t("Import type")}
                showSearch
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                value={selectedImportType ? selectedImportType.id : undefined}
                onChange={(v, opt) => onSelectImportType(v, v ? opt.props.record : null)}>
                {importTypes.map((d) => (
                  <Select.Option value={d.id} record={d}>
                    {d.name}
                  </Select.Option>
                ))}
              </Select>
              <Button type="link" icon={<SearchOutlined />} style={{marginRight:10}} onClick={() => setRecordImportListVisible(true)}  />
              <Dropdown.Button
                type="primary"
                onClick={() => onShowBatch()}
                loading={loading}
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={() => onShowBatch(1)} disabled={!selectedImportType} loading={loading}>
                      <ThunderboltOutlined />
                      {t("Import Now")}
                    </Menu.Item>
                    <Menu.Item key="2" onClick={() => onShowBatch(2)} disabled={!selectedImportType} loading={loading}>
                      <FileOutlined />
                      {t("File on server")}
                    </Menu.Item>
                  </Menu>
                }>
                {t("Create Batch")}
              </Dropdown.Button>
            </div>

            {largeDataRows&&<Alert type="warning" showIcon message={t("Large dataset mode")} />}
            <div style={{ overflow: "auto" }}>
              <Spreadsheet 
                  showExcel
                showFileImport
                showFilterErrors
                onLargeData={(dataRows)=>{setLargeDataRows(dataRows)}}
                onLoading={v=>v?setLoading(true):setLoading(false)}
                  onFileLoaded={name=>setFileName(name)}
                  preProcessorId={selectedImportType?selectedImportType.preProcessorId:null}
                instance={(t) => {
                  //setSpreadsheet(t);
                  //t.hideColumn(0);
                  window.spreadsheet = t;
                }}
                data={data}
                columns={[{ width: 100 }, { width: 100 }, { width: 100 }, { width: 100 }, { width: 100 }, { width: 100 }]}
              />
            </div>
            <RecordImportListModal data={importTypes} visible={recordImportListVisible} onCancel={()=>setRecordImportListVisible(false)} onOk={record=>onSelectImportType(record.id,record)} />
          </Tabs.TabPane>
          {/* -------------------------SETTING TAB ------------------------ */}
          <Tabs.TabPane tab={t("Settings")} key="settings" forceRender>
            <Section text={t("Operation Selection")}>
              <Form.Item label={t("Operation type")}>
                <Select
                  style={{ width: 200, marginRight: 5 }}
                  allowClear
                  placeholder={t("Import type")}
                  value={selectedImportType ? selectedImportType.id : undefined}
                  onChange={(v, opt) => onSelectImportType(v, v ? opt.props.record : null)}>
                  {importTypes.map((d) => (
                    <Select.Option value={d.id} record={d}>
                      {d.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Button type="primary" style={{ marginBottom: 5, marginRight: 5 }} onClick={() => save()}>
                {t("Save")}
              </Button>
            </Section>
            <Section text={t("Configuration")}>
              <Form.Item label={t("Name")}>
                <Input
                  style={{ width: 200}}
                  placeholder={t("Name")}
                  value={selectedImportType ? selectedImportType.name : undefined}
                  onChange={(v) => setSelectedImportType({ ...selectedImportType, name: v.target.value })}
                />
              </Form.Item>
              <Form.Item label={t("Category")}>
                <Input
                  style={{ width: 200}}
                  placeholder={t("Category")}
                  value={selectedImportType ? selectedImportType.category : undefined}
                  onChange={(v) => setSelectedImportType({ ...selectedImportType, category: v.target.value })}
                />
              </Form.Item>
              {/* Entity:
              <Input
                style={{ width: 120, marginLeft: 5, marginRight: 10 }}
                placeholder="Target Entity"
                value={selectedImportType ? selectedImportType.targetEntity : undefined}
                onChange={(v) => setSelectedImportType({ ...selectedImportType, targetEntity: v.target.value })}
              /> */}
              <Form.Item label={t("Operation")} style={{ width: 600 }}>
                <div style={{ display: "flex" }}>
                  <OperationSelect
                    value={selectedImportType ? selectedImportType.operation : undefined}
                    onChange={(v) => setSelectedImportType({ ...selectedImportType, operation: v })}></OperationSelect>

                  <Button
                    type="link"
                    icon={<SettingOutlined />}
                    onClick={() => (window.location.hash = "#/chains/" + selectedImportType.operation)}
                    disabled={!selectedImportType}>
                    {t("Configure")}
                  </Button>
                </div>
              </Form.Item>
              <Form.Item label={t("Pre-Operation")} style={{ width: 600 }}>
                <div style={{ display: "flex" }}>
                  <OperationSelect
                    value={selectedImportType ? selectedImportType.preOperationId : undefined}
                    onChange={(v) => setSelectedImportType({ ...selectedImportType, preOperationId: v })}></OperationSelect>

                  <Button
                    type="link"
                    icon={<SettingOutlined />}
                    onClick={() => (window.location.hash = "#/chains/" + selectedImportType.preOperationId)}
                    disabled={!selectedImportType}>
                    {t("Configure")}
                  </Button>
                </div>
              </Form.Item>
              <Form.Item label={t("Post-Operation")} style={{ width: 600 }}>
                <div style={{ display: "flex" }}>
                  <OperationSelect
                    value={selectedImportType ? selectedImportType.postOperationId : undefined}
                    onChange={(v) => setSelectedImportType({ ...selectedImportType, postOperationId: v })}></OperationSelect>

                  <Button
                    type="link"
                    icon={<SettingOutlined />}
                    onClick={() => (window.location.hash = "#/chains/" + selectedImportType.postOperationId)}
                    disabled={!selectedImportType}>
                    {t("Configure")}
                  </Button>
                </div>
              </Form.Item>
              <Form.Item label={t("File Pre-Processor")} style={{ width: 600 }}>
                <div style={{ display: "flex" }}>
                  <ChainSelect
                      filter={"category='PREPROCESSOR'"}
                      valueField={"id"}
                      value={selectedImportType ? selectedImportType.preProcessorId : undefined}
                      onChange={(v) => setSelectedImportType({ ...selectedImportType, preProcessorId: v })}></ChainSelect>

                  <Button
                      type="link"
                      icon={<SettingOutlined />}
                      onClick={() => (window.location.hash = "#/chains/" + selectedImportType.preProcessorId)}
                      disabled={!selectedImportType}>
                    {t("Configure")}
                  </Button>
                </div>
              </Form.Item>
              <Form.Item label={t("Bulk Mode")} style={{ width: 600 }}>
                <Checkbox checked={selectedImportType ? selectedImportType.bulk : false} onChange={(v) => setSelectedImportType({ ...selectedImportType, bulk: v.target.checked })} />
              </Form.Item>
            </Section>
            <Section text={t("Field Mapping")}>
              <Spreadsheet instance={(t) => setSettingsSS(t)} columns={settingsColumns} data={[]} />
            </Section>
          </Tabs.TabPane>
          {/* -------------------------BATCHES TAB ------------------------ */}
          <Tabs.TabPane tab={t("Batches")} key="batches" forceRender>
            <BatchList reload={reloadBatches} onOpen={openBatch} />
          </Tabs.TabPane>
        </Tabs>
      </DefaultPage>
      <Batch
        visible={batchVisible}
        rows={largeDataRows?window.largeData:rows}
        importType={selectedImportType}
        processingType={processingType}
        onCancel={() => setBatchVisible(false)}
        onAdded={() => {
          setBatchVisible(false);
          setReloadBatches(reloadBatches + 1);
          setFileName();
        }}
        defaultName={fileName}
      />
    </div>
  );
};

export default RecordImport;
