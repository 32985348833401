import React from "react";
import { AutoComplete, Spin,message } from "antd";
import Search from "antd/lib/input/Search";
import equal from "fast-deep-equal";
import { exe, safeGet } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";
import { getConfigProfile } from "../../Lib/Helpers";
const codes = require("./icd9Hierarchy.json");

//props onChange [value]
class DiagnosisSearch extends React.Component {
  state = {
    filteredData: [],
    value: undefined,
    customRecords: [],
    loading: false,
    icdFlat:[]
  };
  componentDidMount() {
    getConfigProfile().then((profile) => {
      const icdTree=safeGet(["Health", "icdTree"], profile, null);
      if(icdTree){
        //loading data
        this.setState({ loading: true });
        exe("RepoChain", { operation: "GET", filter: `name='${icdTree}'` }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            if(r.total==0) return message.error(`Icd tree ${icdTree} not found`);
            const icdTreeParsed=JSON.parse(r.outData[0].code);
            const icdFlat=this.getLeafItems(icdTreeParsed);
            this.setState({ icdFlat: icdFlat });
          }else message.error(r.msg);
        });
      }
    });
    this.loadCustom();
    //when used in forms
    if (this.props.value) {
      this.setState({ value: this.props.value });
    }
  }
  getLeafItems = (nodes) => {
    let leaves = [];

    // Recursive function to traverse the hierarchy
    const traverse = (node) => {
        if (!node.children || node.children.length === 0) {
            // If the node has no children, it's a leaf node
            leaves.push(node);
        } else {
            // If the node has children, traverse each child
            if(Array.isArray(node.children)) node.children.forEach(child => traverse(child));
        }
    }

    // Start the traversal for each root node
    nodes.forEach(node => traverse(node));

    return leaves;
};
  loadCustom() {
    this.setState({ loading: true });
    exe("RepoDiagnosis", { operation: "GET" }).then((r) => {
      this.setState({ loading: false });

      const customRecords = r.outData.map((p) => ({
        icd9: p.code,
        descLong: p.description,
        descShort: p.name,
        threedigit: p.code,
        major: "CUSTOM",
        subchapter: "CUSTOM",
        chapter: "CUSTOM",
      }));

      this.setState({ customRecords });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    //when used in forms
    if (this.props.value && this.props.value != prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }
  handleChange = (v) => {
    this.setState({ value: v });
  };
  handleSearch = (value) => {
    value = value.toUpperCase();
    if(this.state.icdFlat.length>0){
      const filteredData = [...this.state.icdFlat, ...this.state.customRecords.map(p=>({code:p.icd9,name:p.descShort}))]
        .filter((p) => p.name&&p.name.toUpperCase().includes(value) ||p.code&&p.code.includes(value))
        .slice(0, 20);
      this.setState({ filteredData });
    }
    else{
      //legacy mode
      const filteredData = [...codes, ...this.state.customRecords]
        .filter((p) => p.descShort.toUpperCase().includes(value) || p.major.toUpperCase().includes(value) || p.icd9.includes(value))
        .slice(0, 20);
      this.setState({ filteredData });

    }
  };
  onAutoCompleteSelect = (v, o) => {
    this.props.onChange(v);
  };

  render() {
    const { t, i18n } = this.props;
    const results = this.state.icdFlat.length?this.state.filteredData.map((e) => (
      <AutoComplete.Option key={e.code}>{e.code + "-" + e.name}</AutoComplete.Option>
    )):this.state.filteredData.map((e) => (
      <AutoComplete.Option key={e.icd9}>{e.icd9 + "-" + e.chapter + "-" + e.subchapter + "-" + e.major + "-" + e.descShort}</AutoComplete.Option>
    ));

    return (
      <AutoComplete
        style={{ marginBottom: 8 }}
        allowClear
        placeholder={t("Search Diagnosis")}
        value={this.state.value}
        onChange={this.handleChange}
        onSearch={this.handleSearch}
        datasource={this.state.filteredData}
        onSelect={this.onAutoCompleteSelect}>
        {this.state.loading && <Spin size="small" />}
        {results}
      </AutoComplete>
    );
  }
}

export default withTranslation()(DiagnosisSearch);
