import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Modal } from "antd";
import {useTranslation} from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const AddExchangeRecord = ({visible,onOk,onCancel,form}) => {
    const [t]=useTranslation();
    const field=form.getFieldDecorator;
    
    const onOkInternal=()=>{
        form.validateFields((err,values)=>{
            if(err) return;
            onOk&&onOk(values);
        })
    }
    return (
        <Modal title={t("Add Record")} visible={visible} onOk={onOkInternal} onCancel={onCancel}>
            <Form.Item label={t("Date")}>{field("date",{rules:[{required:true}]})(<DatePickerW />)}</Form.Item>
            <div style={{display:"flex"}}>
                <Form.Item label={t("Bid")} style={{marginRight:3}}>{field("bid",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>
                <Form.Item label={t("Ask")}>{field("ask",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>
            </div>
            <div style={{display:"flex"}}>
                <Form.Item label={t("Open")} style={{marginRight:3}}>{field("open",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>
                <Form.Item label={t("High")} style={{marginRight:3}}>{field("high",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>
                <Form.Item label={t("Low")} style={{marginRight:3}}>{field("low",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>
                <Form.Item label={t("Close")}>{field("close",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>
            </div>
            <Form.Item label={t("Yield")}>{field("yield",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>
        </Modal>
    );
};

export default Form.create()(AddExchangeRecord);