import React, { Component } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Modal, InputNumber } from "antd";
import { withTranslation } from "react-i18next";
import Percentage from "../Shared/Percentage";
import {round2} from "../../Lib/Helpers";
import Money from "../Shared/Money";
import {safeGet, safeGetRaw} from "../../Lib/Dal";
import CoverageCombo from "./CoverageCombo";
import DatePickerW from "../Shared/DatePickerW";
import moment from "moment";

class ManualSurcharge extends Component {
  onOk() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.okOk(values);
      }
    });
  }
  onChangePercentage(v){
    const premiums=this.props.policy.coverages;
    this.props.form.setFieldsValue({value:round2(v*premiums/100)});
  }
  onChangeValue(v){
    const premiums=this.props.policy.coverages;
    this.props.form.setFieldsValue({percentage:round2(v/premiums*100)});
  }
  onChangeDuration=v=>{
    //changing the end date when duration changes
    const start=this.props.form.getFieldValue("start");
    this.props.form.setFieldsValue({end:moment(start).clone().add(v, 'years')});

  }

  render() {
    const field = this.props.form.getFieldDecorator;
    const { t } = this.props;
    const coverages=safeGetRaw(["Coverages"],this.props.policy,[]);
    const values=this.props.form.getFieldsValue();

    return (
      <Modal title={t("Add Manual Surcharge")} visible={this.props.visible} onCancel={this.props.onCancel} onOk={() => this.onOk()}>
        <Form>
          <Form.Item label={t("Surcharge Summary")}>
            {field("name", {
              rules: [{ required: true }],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={t("Description")}>
            {field("description", {
              rules: [{ required: true }],
            })(<Input.TextArea />)}
          </Form.Item>
          <Form.Item label={t("Value")}>
            {field("value", {rules: [{ required: true }],
            })(<Money currency={this.props.policy.currency} onChange={v=>this.onChangeValue(v)} min={this.props.policy.coverages*-1} />)}
          </Form.Item>
          <Form.Item label={t("Premium %")}>
            {field("percentage", {
            })(<Percentage min={-100} max={+Infinity} onChange={(v)=>this.onChangePercentage(v)} />)}
          </Form.Item>
          <Form.Item label={t("Coverage")}>{field("coverageCode")(<CoverageCombo coverages={coverages} />)}</Form.Item>
          <Form.Item label={t("Duration")}>{field("duration",{initialValue:this.props.policy.duration})(<InputNumber min={0} onChange={this.onChangeDuration} />)}</Form.Item>
          <Form.Item label={t("Start")}>{field("start", { 
            initialValue: this.props.policy.start?moment(this.props.policy.start):moment(), 
            rules: [
              { 
                validator: (rule, value, callback) => { 
                  if (value && value.isBefore(this.props.policy.start, 'day')) { 
                    callback(t('Selected date must be after policy start date')); 
                  } else { 
                    callback(); 
                  } 
                } 
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value.isAfter(values.end, 'day')) {
                    callback(t('Selected date must be before end date'));
                  } else {
                    callback();
                  }
                }
              }
            ] 
          })(<DatePickerW min={moment()} />)}</Form.Item>
          <Form.Item label={t("End")}>{field("end", { initialValue: this.props.policy.end?this.props.policy.end:moment(values.start).clone().add(values.duration, 'years')})(<DatePickerW disabled />)}</Form.Item>

        </Form>
      </Modal>
    );
  }
}
const fManualSurcharge = Form.create()(ManualSurcharge);
export default withTranslation()(fManualSurcharge);
