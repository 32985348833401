import React from "react";
import { Bar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../Lib/Helpers";

const PolicySituationGraph = (props) => {
  const [t, i18n] = useTranslation();
  const rawData = props.data || [];
  const claimId = props.claimId;
  const data = [
    {
      situation: t("All Claims"),
      reserved: rawData.reduce((total, x) => total + x.reserved, 0),
      payed: rawData.reduce((total, x) => total + x.payed, 0),
    },
    {
      situation: t("Current Claim"),
      reserved: rawData.filter((p) => p.claimId == claimId).reduce((total, x) => total + x.reserved, 0),
      payed: rawData.filter((p) => p.claimId == claimId).reduce((total, x) => total + x.payed, 0),
    },
  ];

  const commonProps = {
    width: 900,
    height: 200,
    margin: { top: 5, right: 80, bottom: 40, left: 80 },
    data: data,
    keys: ["reserved", "payed"],
    tooltip: (data) => {
      return `${data.id=="reserved"?t("Reserved"):t("Paid")}: ${formatNumber(data.value)}`;
    },
    indexBy: "situation",
    padding: 0.2,
    labelTextColor: "inherit:darker(1.4)",
    labelSkipWidth: 16,
    labelSkipHeight: 16,
  };

  if (rawData.length == 0) return null;

  return (
    <div>
      <Bar {...commonProps} layout="horizontal" enableGridY={false} enableGridX={true} />
    </div>
  );
};

export default PolicySituationGraph;
