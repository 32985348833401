import React, {useEffect, useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Select, message } from "antd";
import ReceiptTypeSelect from "./ReceiptTypeSelect";
import {useTranslation} from "react-i18next";
import {exe, safeGet, safeGetRaw} from "../../Lib/Dal";
import ReasonSelect from "../Life/ReasonSelect";
import {getConfigProfile} from "../../Lib/Helpers";

const FiscalDocsGeneratedActions = (props) => {
    const [t]=useTranslation();
    const [loading,setLoading]=useState(false);
    const [actionCompatibleTypes,setActionCompatibleTypes]=useState([]);
    const [allowFiscalDocumentRelease, setAllowFiscalDocumentRelease]=useState(true);


    useEffect(()=>{
        if(props.selected) props.form.setFieldsValue(props.selected);
    },[props.selected])

    useEffect(()=>{
        getConfigProfile().then((profile) => {
            setActionCompatibleTypes(safeGet(["Billing","changeCompatibility"], profile, []));
            setAllowFiscalDocumentRelease(safeGetRaw(["Billing","allowFiscalDocumentRelease"], profile, true))
        });
    },[]);
    
    const onOk=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            
            setLoading(true);
            exe("UpdateFiscalDocGenerated",values).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onOk();
                }else message.error(r.msg);
            })
            
        })
    }
    
    const field=props.form.getFieldDecorator;
    const values=props.form.getFieldsValue();
    
    return (
        <div>
            {field("id")}
            {field("action")}
            {field("releasedDate")}
            {field("reassignedDate")}
            {field("cancelledDate")}
            <Modal title={t("Change Actions")} visible={props.visible} onCancel={props.onCancel} 
                   onOk={onOk} okButtonProps={{loading:loading,disabled:values.releasedDate||values.reassignedDate||values.cancelledDate}}>
                <Form.Item label={t("Receipt Type")}>
                    {field("receiptTypeCode")(<ReceiptTypeSelect disabled />)}
                </Form.Item>
                <Form.Item label={t("Fiscal Number")}>
                    {field("fiscalNumber")(<Input disabled />)}
                </Form.Item>
                <Form.Item label={t("Previous Fiscal Document Number Action")}>
                   {field("changeType",{rules:[{required:true}]})(<Select style={{width:"100%"}}>
                       <Select.Option value="release" disabled={!allowFiscalDocumentRelease}>{t("Release")}</Select.Option>
                       <Select.Option value="cancel">{t("Cancel")}</Select.Option>
                   </Select>)}
               </Form.Item>
                <div style={{display:values.changeType==="release"?"none":"block"}}>
                    <Form.Item label={t("Change Reason")} style={{width:"100%"}}>
                        {field("changeReason",{rules:[{required:values.changeType==="cancel"}]})(<ReasonSelect style={{width:"100%"}} catalog={"FiscalDocumentCancellation"} />)}
                    </Form.Item>
                </div>
                <div style={{display:values.changeType==="release"?"block":"none"}}>
                    <Form.Item label={t("New Receipt Type")}>
                        {field("newReceiptTypeCode",{rules:[{required:values.changeType==="release"}]})(<ReceiptTypeSelect enabledItems={actionCompatibleTypes.find(p=>p.action===values.action)} />)}
                    </Form.Item>
                </div>
            </Modal>
        </div>
    );
};

export default Form.create()(FiscalDocsGeneratedActions);