import React, { useState, useEffect } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { FilterOutlined, ThunderboltOutlined, SafetyOutlined, LockOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Space, Table, Tag } from "antd";
import Column from "antd/lib/table/Column";
import { exe } from "../../Lib/Dal";
import { formatDate, formatDateShort, getColor, formatMoney } from "../../Lib/Helpers";
import LifePolicyListFilter from "./LifePolicyListFilter";
import { useTranslation } from "react-i18next";
import LifePolicyListBulkActions from "./LifePolicyListBulkActions";
import SaveSelectedRecords from "../Contract/SaveSelectedRecords";

const LifePolicyList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [bulkActionsVisible, setBulkActionsVisible] = useState(false);
  const { t, i18n } = useTranslation();

  // useEffect(() => {
  //   if (!window.location.hash.includes("?")) load();
  // }, [pagination.current]);
  // const load = (filter) => {
  //   setLoading(true);
  //   exe("RepoLifePolicy", { operation: "GET", include: ["Holder", "Process"], size: pagination.pageSize, page: pagination.current - 1 }).then((r) => {
  //     setLoading(false);
  //     setData(r.outData);
  //     setPagination({ ...pagination, total: r.total });
  //   });
  // };
  const onFilteredResults = (filteredData, totalResults) => {
    setData(filteredData);
    setPagination({ ...pagination, total: totalResults, current: pagination.total !== totalResults ? 1 : pagination.current });
  };
  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
    console.log(keys);
  };

  return (
    <DefaultPage
      title={t("Policy Search")}
      icon="file-search"
      loading={false}
      extra={
        <div>
          <SaveSelectedRecords selectedRowKeys={selectedRowKeys} entity="LifePolicy" />
          <Button icon={<ThunderboltOutlined />} onClick={() => setBulkActionsVisible(true)} disabled={selectedRowKeys.length == 0} style={{ marginRight: 3 }}>
            {t("Bulk Actions")}
          </Button>

          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        </div>
      }>
      <Table
        dataSource={data}
        loading={loading}
        pagination={pagination}
        scroll={{x:true}}
        onChange={(pag) => setPagination(pag)}
        rowKey="id"
        rowSelection={{ selectedRowKeys, onChange: onSelectChange }}>
        <Column key={"indicator"} dataIndex="policyType" render={(v,r)=><Space><Avatar size={"small"} style={v=="G" ? { backgroundColor: "#feca57" } : v=="C" ? { backgroundColor: "#74b9ff" } : undefined } icon={<SafetyOutlined />} /><LockOutlined style={{display:r.restricted?"inline":"none"}} /> </Space>}  />
        <Column title={t("Id")} dataIndex="id" render={(v, r) => <a href={"#/lifePolicy/" + r.id}>{v}</a>} />
        <Column title={t("Type")} dataIndex="policyType" />
        <Column title={t("Code")} dataIndex="code" />
        {/* <Column title="Status" dataIndex="entityState" render={(v, r) => <Tag color={getColor(r.entityState)}>{v}</Tag>} /> */}
        <Column title={t("Status")} dataIndex="entityState" render={(v, r) => <Tag color={getColor(r.Process ? r.Process.entityState : null)}>{v}</Tag>} />
        <Column title={t("LoB")} dataIndex="lob" />
        <Column title={t("Product")} dataIndex="productCode" />
        <Column title={t("Issued")} dataIndex="activeDate" render={(v) => (v ? formatDate(v) : "Not Issued")} />
        <Column title={t("Holder")} dataIndex={["Holder","FullName"]} render={(v, r) => <a href={"#/contact/" + r.holderId}>{v}</a>} />
        {/* <Column title="Created" dataIndex="created" render={(v) => <div style={{ whiteSpace: "nowrap" }}>{formatDateShort(v)}</div>} /> */}
        <Column title={t("Start")} dataIndex="start" render={(v) => <div style={{ whiteSpace: "nowrap" }}>{formatDateShort(v)}</div>} />
        <Column title={t("End")} dataIndex="end" render={(v) => <div style={{ whiteSpace: "nowrap" }}>{formatDateShort(v)}</div>} />
        <Column title={t("Anniversary")} dataIndex="nextAnniversary" render={(v) => <div style={{ whiteSpace: "nowrap" }}>{formatDateShort(v)}</div>} />
        <Column title={t("Gross Value")} dataIndex="grossValue" render={(v, r) => formatMoney(r.grossValue, r.currency)} />
        <Column title={t("Insured  Sum")} dataIndex="insuredSum" render={(v, r) => formatMoney(v, r.currency)} />
        <Column title={t("Action")} render={(v, r) => <a href={"#/lifePolicy/" + r.id}>Open</a>} />
      </Table>
      <LifePolicyListBulkActions visible={bulkActionsVisible} onClose={() => setBulkActionsVisible(false)} selected={selectedRowKeys} />
      <LifePolicyListFilter
        visible={filterVisible}
        onClose={() => setFilterVisible(false)}
        onResults={(data, total) => onFilteredResults(data, total)}
        pagination={pagination}
        onLoading={v=>setLoading(v)}
      />
    </DefaultPage>
  );
};

export default LifePolicyList;
