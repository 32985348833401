import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Table, Select, DatePicker, message, Badge, Checkbox } from "antd";
import { exe } from "../../Lib/Dal";
import { formatMoney, formatDateShort } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";
import DefaultPage from "../Shared/DefaultPage";
import PolicyLink from "../Life/PolicyLink";
import TaxGeneratedFilter from "./TaxGeneratedFilter";
import PaymentLink from "../Payments/PaymentLink";

const TaxGenerated = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);

  const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    if (props.onSelectChange) props.onSelectChange(data.filter((row) => selectedRowKeys.includes(row.id)));
  };
  const toggleSelectAll = () => {
    setSelectedRowKeys((keys) => (keys.length === data.length ? [] : data.map((r) => r.id)));
  };

  const headerCheckbox = (
    <Checkbox checked={selectedRowKeys.length} indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length} onChange={toggleSelectAll} />
  );
  const rowSelection = {
    selectedRowKeys,
    columnTitle: headerCheckbox,
    onChange: onSelectChange,
  };
const grouped=data.some(p=>p.action==="GROUPED");
  return (
    <DefaultPage
      title={t("Tax Generated")}
      icon="pound"
      extra={
        <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
          {t("Filter")}
        </Button>
      }>
      <CSVLink data={data} filename={"taxes.csv"} target="_blank" disabled={data.length == 0}>
        <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
          {t("Download")}
        </Button>
      </CSVLink>
      <Table
        dataSource={data}
        loading={loading}
        style={{ marginTop: 5 }}
        scroll={{ x: true }}
        size="middle"
        rowSelection={props.selectable ? rowSelection : {}}
        rowKey="id">
          {!grouped&&<Table.Column title={t("Id")} dataIndex="id" />}
        <Table.Column title={t("Tax Name")} dataIndex="taxName" />
        <Table.Column title={t("Amount")} dataIndex="amount" render={(v, r) => formatMoney(v, r.currency)} />
          {grouped&&<Table.Column title={t("Records")} dataIndex="liquidationId" />}
          {!grouped&&<Table.Column title={t("Date")} dataIndex="created" render={(v) => formatDateShort(v)} />}
          {!grouped&&<Table.Column title={t("Scheme")} dataIndex="taxSchemeId" render={(v, r) => <a href={"#/taxscheme/" + v}>{v}</a>} />}
          {!grouped&&<Table.Column title={t("Action")} dataIndex="action" />}
          {!grouped&&<Table.Column title={t("Entity")} dataIndex="lifePolicyId" render={(v, r) => r.lifePolicyId?<PolicyLink policyId={v} />:<PaymentLink paymentId={r.claimPaymentId} />}/>}
      </Table>
      <TaxGeneratedFilter visible={filterVisible} onResults={(r) => setData(r)} onClose={() => setFilterVisible(false)} />
    </DefaultPage>
  );
};

export default TaxGenerated;
