import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeGet } from "../../Lib/Dal";
import { getConfigProfile } from "../../Lib/Helpers";

const ContactIncomeTypeSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [t] = useTranslation();

    useEffect(() => {
        load();
    }, []);

    const load=()=>{
        setLoading(true);
        exe("RepoContactIncomeType",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }
    
    return (
        <div>
            <Select
                style={{width:"100%"}}
                showArrow
                value={props.value}
                onChange={(v,opt)=>props.onChange(v,opt)}
                onClear={()=>props.onChange(undefined,undefined)}
                loading={loading}
                allowClear
                showSearch
                optionFilterProp="children">
                {data.map((p) => (
                    <Select.Option value={p.code} key={p.code} record={p}>
                        {t(p.name)}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default ContactIncomeTypeSelect;
