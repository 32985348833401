import React from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, Table, Divider, Modal, Select, message} from "antd";
import ContactSelect from "./ContactSelect";
import { exe } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";
import {formatDate, formatDateShort} from "../../Lib/Helpers";
import moment from "moment";
import DatePickerW from "../Shared/DatePickerW";

const Option = Select.Option;
const { Column } = Table;

/**
 * Contact relationship admin. C(modal) RUD(grid)
 * @property {array} data - initial relationship data
 * @property {int} contactId - to be excluded from relationships
 * @property {function} dataChanged(newData) - returns DB updated data
 */
class Relationships extends React.Component {
  static defaultProps = {
    data: [],
  };
  state = {
    modalVisible: false,
    relatedId: null,
    name: null,
    type: null,
    data: [],
    filteredData:[],
    start:null,
    end:null,
    expires:false,
    id:undefined
  };
  componentDidMount() {
    this.loadRelationships();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPerson !== this.props.isPerson) this.loadRelationships();
  }
  /////////modal new record
  loadRelationships() {
    if (this.props.isPerson !== undefined) {
      exe("RepoRelationshipCatalog", { operation: "GET", filter: `principalType='${this.props.isPerson ? "PERSON" : "LEGAL"}'` }).then((r) => {
        if (r.ok) this.setState({ data: r.outData,filteredData:r.outData });
        else message.error(r.msg);
      });
    }
  }
  modalOk() {
    //validation
    if(!this.state.relatedId||!this.state.type||(this.state.expires&&(!this.state.start&&!this.state.end))){
      message.error(this.props.t("Please fill in all fields"));
      return;
    }
    const dto = {
      contactId: this.props.contactId,
      relatedId: this.state.relatedId,
      name: this.state.name,
      type: this.state.type,
      start:this.state.start,
      end:this.state.end,
      id:this.state.id
    };
    exe("SetRelationship", dto,{operation:dto.id?"UPDATE":"ADD"}).then((r) => {
      if (r.ok) {
        message.success(r.msg);
        if(this.state.id){
          this.props.dataChanged(this.props.data.map(p=>p.id===this.state.id?r.outData:p)) //record updated
        }else  this.props.dataChanged([...this.props.data, r.outData]); // new record
        
        this.setState({ modalVisible: false,start:null,end:null,id:undefined });
      } else {
        message.error(r.msg);
      }
    });
  }
  modalCancel() {
    this.setState({ modalVisible: false });
  }
  relationshipChange = (v,opt) => {
    this.setState({ type: v,expires:opt.props.record.expires });
  };
  contactChange = (v) => {
    //checking selected contact type
    if(v&&v.contact){
      const contactType = v.contact.isPerson?"PERSON":"LEGAL";
      //filtering data allowing only targetType=same type of relationships or targetType is not defined
      const filteredData=this.state.data.filter(p=>!p.targetType||p.targetType===contactType);
      this.setState({  filteredData });
    }else{
      this.setState({  filteredData:this.state.data });
    }
    this.setState({ relatedId: +v.key, name: v.label });
  };

  //////////////grid of records
  deleteRelationship(record) {
    exe("SetRelationship", record, { operation: "DELETE" }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
        if(r.operation==="UPDATE"){
          //validity date updated, not deleted
          this.props.dataChanged(this.props.data.map(p=>p.id===record.id?r.outData:p));
        }else this.props.dataChanged(this.props.data.filter((p) => p.id !== record.id));
      } else {
        message.error(r.msg);
      }
    });
  }
  editRelationship(record){
    const relationshipRecord=this.state.data.find(p=>p.name===record.type);
    
    this.setState({modalVisible:true,id:record.id,relatedId:record.relatedId,name:record.name,
      type:record.type,start:record.start?moment(record.start):undefined,end:record.end?moment(record.end):undefined,expires:relationshipRecord?relationshipRecord.expires:false});
  }
  viewRelationship(record) {
    window.location.hash = "#/contact/" + record.relatedId;
  }
  onNewRelationship() {
    this.setState({ modalVisible: true, relatedId: undefined, type: undefined,expires:false,validity:null });
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <div>
        <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: 5 }} onClick={() => this.onNewRelationship()}>
          {t("Add")}
        </Button>
        <Table dataSource={this.props.data} rowKey="id" size="medium">
          <Column title={t("Name")} dataIndex="name" />
          <Column title={t("Relationship")} dataIndex="type" />
          <Column title={t("ID")} dataIndex="relatedId" />
          <Column title={t("Start")} dataIndex="start" render={v=>v?formatDateShort(v):null} />
          <Column title={t("End")} dataIndex="end" render={v=>v?moment(v).isBefore(moment())?<span style={{color:"red"}}>{formatDateShort(v)}</span>:formatDateShort(v):null} />
          <Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <Button size="small" onClick={() => this.viewRelationship(record)}>
                  {t("View")}
                </Button>
                <Divider type="vertical" />
                <Button type="danger" size="small" onClick={() => this.deleteRelationship(record)} disabled={record.end&&moment(record.end).isBefore(moment())}>
                  {t("Delete")}
                </Button>
                  <Divider type="vertical" />
                 <Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={() => this.editRelationship(record)}  />
              </span>
            )}
          />
        </Table>
        <Modal title={t("Relationship")} visible={this.state.modalVisible} onOk={() => this.modalOk()} onCancel={() => this.modalCancel()}>
          <ContactSelect contactChange={this.contactChange} exclude={[+this.props.contactId]} value={this.state.relatedId} display={this.state.name} />
          <Select style={{ width: "100%", marginTop: 5 }} value={this.state.type} onChange={this.relationshipChange} placeholder={t("Relationship type")}>
            {this.state.filteredData.map((r) => (
              <Option value={r.name} record={r} key={r.type}>{r.name}</Option>
            ))}
          </Select>
          {this.state.expires&&<div style={{ display:"flex", marginTop: 5 }} >
            <DatePickerW style={{ width: "100%"}} placeholder={t("Start")} value={this.state.start} onChange={v=>this.setState({start:v})} />
            <DatePickerW style={{ width: "100%",marginLeft:5}} placeholder={t("End")}  value={this.state.end} onChange={v=>this.setState({end:v})}/>
          </div>}
        </Modal>
      </div>
    );
  }
}
export default withTranslation()(Relationships);
