import { Alert, Descriptions, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const DetailViewer = (props) => {
  const [t] = useTranslation();
  const data = props.value ? JSON.parse(props.value) : {};
  const rules = data.Rules || [];
  const accounts = data.Accounts || [];
  const coverages = data.Coverages || [];
  delete data.Rules;
  delete data.Accounts;
  delete data.Coverages;
  const keys = Object.keys(data);

  return (
    <div>
      {rules.map((p) => (p.ok ? null : <Alert style={{ marginTop: 5 }} message={p.msg} type="error" showIcon />))}
      {/* <Table dataSource={rules} pagination={false} size="small">
        <Table.Column title="Rule" dataIndex="name" />
        <Table.Column
          title="Result"
          dataIndex="msg"
          render={(v, r) => (r.ok ? <span style={{ color: "green" }}>OK</span> : <span style={{ color: "red" }}>{v}</span>)}
        />
      </Table> */}
      {accounts.map((account) => (
        <Descriptions title={t("Account ") + account.accNo} bordered column={1} size="small" style={{ marginTop: 10 }}>
          {Object.keys(account).map((key) => (
            <Descriptions.Item label={key}>{account[key]}</Descriptions.Item>
          ))}
        </Descriptions>
      ))}

      <Descriptions title={t("Calculation Parameters")} bordered column={1} size="small" style={{ marginTop: 10 }}>
        {keys.map((key) => (
          <Descriptions.Item label={key}>{data[key]}</Descriptions.Item>
        ))}
      </Descriptions>
      
      {coverages.map((cov) => (
          <Descriptions title={t("Coverage ") + cov.code} bordered column={1} size="small" style={{ marginTop: 10 }}>
            {Object.keys(cov).map((key) => (
                <Descriptions.Item label={key}>{cov[key]}</Descriptions.Item>
            ))}
          </Descriptions>
      ))}
    </div>
  );
};

export default DetailViewer;
