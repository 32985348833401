import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultPage from "../Shared/DefaultPage";
import { FilterOutlined } from '@ant-design/icons';
import { Button} from "antd";
import ComLiquidationList from './ComLiquidationList';
import ComAllLiquidationsFilter from './ComAllLiquidationsFilter';

const ComAllLiquidations = (props) => {
    const [t]= useTranslation();
    const [filterVisible,setFilterVisible]=useState(false);
    const [data, setData] = useState([]);
    return (
        <div>
            <DefaultPage title={t("Commission Liquidation Orders")} icon="wallet" extra={<div><Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>{t("Filter")}</Button></div>}>
            <ComLiquidationList data={data}  />
            <ComAllLiquidationsFilter visible={filterVisible} onResults={(r) => setData(r)} onClose={() => setFilterVisible(false)}/>
            </DefaultPage>
        </div>
    );
};

export default ComAllLiquidations;