import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber, Drawer, Input, DatePicker, Radio } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import IssueTypeSelect from "./IssueTypeSelect";
import IssueReasonSelect from "./IssueReasonSelect";
import PrioritySelect from "./PrioritySelect";
import PolicySelect from "../Health/PolicySelect";
import ContactSelect from './ContactSelect';

const ContactIssuesFilter = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const onSearch = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onSearch(values);
    });
  };
  return (
    <Drawer title={t("Contact Issues Filter")} visible={props.visible} onClose={props.onClose} width={512}>
      <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
        <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
          {t("Reset")}
        </Button>
      </div>
      <Form>
        <Form.Item label={t("Id")}>{field("id")(<InputNumber />)}</Form.Item>
        <Form.Item label={t("Status")}>
          {field("closed")(
            <Radio.Group>
              <Radio value={undefined}>{t("All")}</Radio>
              <Radio value={0}>{t("Open")}</Radio>
              <Radio value={1}>{t("Closed")}</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Form.Item label={t("Type")}>{field("issueType")(<IssueTypeSelect />)}</Form.Item>
        <Form.Item label={t("Contact")}>{field("contactId")(<ContactSelect />)}</Form.Item>
        <Form.Item label={t("Priority")}>{field("priorityCode")(<PrioritySelect />)}</Form.Item>
        <Form.Item label={t("External Id")}>{field("externalId")(<Input />)}</Form.Item>
        <Form.Item label={t("Policy")}>{field("policyId")(<PolicySelect />)}</Form.Item>
        <Form.Item label={t("Reason")}>{field("issueReason")(<IssueReasonSelect />)}</Form.Item>
        <Form.Item label={t("Opened")}>{field("created")(<DatePicker.RangePicker />)}</Form.Item>
        <Form.Item label={t("Closed")}>{field("closeDate")(<DatePicker.RangePicker />)}</Form.Item>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()} style={{ marginTop: 5 }}>
          {t("Search")}
        </Button>
      </Form>
    </Drawer>
  );
};

export default Form.create({})(ContactIssuesFilter);
