import React, {useState} from 'react';
import { FileExcelOutlined, RetweetOutlined } from '@ant-design/icons';
import {Button, message, Modal, Table} from "antd";
import {useTranslation} from "react-i18next";
import {Excel} from "antd-table-saveas-excel";
import { exe } from '../../Lib/Dal';

const ShowImportErrors = (props) => {
    const [loading, setLoading] = useState(false);
    const [t]=useTranslation();
    const exportXlsx = () => {
        const exportData = data;
        const fields = Object.keys(exportData[0]);
        const excel = new Excel();
        excel
            .addSheet("Sheet1")
            .addColumns(fields.map((p) => ({title: p, dataIndex: p}))) //antd column format
            .addDataSource(exportData)
            .saveAs("importErrors.xlsx");
    };
    const createBatchFromFailed = () => {
        setLoading(true);
        exe("CreateBatchFromFailed", {batchId:props.batchId}).then((r)=>{
            setLoading(false);
            if(r.ok){
                message.success(t("Batch created with id "+r.outData.newBatchId));
                props.onOk();
                props.reload();
            }else{
                message.error(r.msg);
            }
        }
        );
    }
    const data=props.data?JSON.parse(props.data):[];
    return (
        <div>
            <Modal title={t("Import Errors")} width={800} visible={props.visible} onOk={props.onOk} onCancel={props.onCancel}>
                <Button type="link" icon={<FileExcelOutlined />} onClick={exportXlsx}>
                    {t("Excel")}
                </Button>
                <Button type="link" icon={<RetweetOutlined />} onClick={createBatchFromFailed} loading={loading}>
                    {t("Create batch from failed")}
                </Button>
                <Table dataSource={data}>
                    <Table.Column title={t("Message Id")} dataIndex={"id"} />
                    <Table.Column title={t("Line Index")} dataIndex={"index"} />
                    <Table.Column title={t("Result")} dataIndex={"msg"} />
                    <Table.Column title={t("Date")} dataIndex={"date"} />
                </Table>
            </Modal>
        </div>
    );
};

export default ShowImportErrors;