import React from "react";
import { Tree, AutoComplete, Spin, message } from "antd";
import Search from "antd/lib/input/Search";
import equal from "fast-deep-equal";
import DiagnosisSearch from "./DiagnosisSearch";
import treeData from "./treeData"; //hardcoded
import { exe, safeGet } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";
import { getConfigProfile } from "../../Lib/Helpers";

//const chapters = require('./icd9Chapters.json');
const codes = require("./icd9Hierarchy.json");
const { TreeNode } = Tree;
//const treeData = [];

//props fn onSelect
class DiagnosisTree extends React.Component {
  state = {
    expandedKeys: [],
    autoExpandParent: true,
    selectedKeys: [],
    loading: false,
    customNode: [],
    icdTreeData: [],
    icdFlatData: [],
    loaded: false,
  };
  componentDidMount() {
    getConfigProfile().then((profile) => {
      const icdTree=safeGet(["Health", "icdTree"], profile, null);
      if(icdTree){
        //loading data
        this.setState({ loading: true });
        exe("RepoChain", { operation: "GET", filter: `name='${icdTree}'` }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            if(r.total==0) return message.error(`Tree ${icdTree} not found`);
            const treeData=JSON.parse(r.outData[0].code);
            const flatData=this.getLeafItems(treeData);
            this.setState({ icdTreeData:treeData ,loaded:true,icdFlatData:flatData });
          }else message.error(r.msg);
        });
      }else{
        this.setState({loaded:true})
      }
  });
    this.loadCustom(); 
  }
  loadCustom() {
    this.setState({ loading: true });
    exe("RepoDiagnosis", { operation: "GET" }).then((r) => {
      this.setState({ loading: false });
      const customNode = {
        key: "Custom",
        title: this.props.t("1000-1100 Custom Diagnostics"),
        threedigit: "C01",
        children: r.outData.map((p) => ({ key: p.code, title: p.name, threedigit: p.code, isLeaf: true })),
      };
      this.setState({ customNode });
    });
  }

  //used to build tree as structured object ( saved to treeData.js)
  // buildTree=()=>{
  //     codes.forEach(element => {
  //         let node0 = treeData.find(p => p.key == element.chapter);
  //         if (!node0) {
  //             node0 = { key: element.chapter, title: element.chapter, threedigit: element.threedigit, children: [] };
  //             treeData.push(node0);
  //         }
  //         let node1 = node0.children.find(p => p.key == element.subchapter);
  //         if (!node1) {
  //             node1 = { key: element.subchapter, title: element.subchapter, threedigit: element.threedigit, children: [] };
  //             node0.children.push(node1);
  //         }
  //         let node2 = node1.children.find(p => p.key == element.major);
  //         if (!node2) {
  //             node2 = { key: element.major, title: `${element.threedigit} - ${element.major}`, threedigit: element.threedigit, children: [] };
  //             node1.children.push(node2);
  //         }
  //         node2.children.push({ key: element.icd9, title: `${element.icd9} ${element.descShort}`, threedigit: element.threedigit, isLeaf: true })
  //     });

  //     //titles
  //     treeData.forEach(element => {
  //         element.title = chapters[element.title][0] + '-' + chapters[element.title][1] + ' ' + element.title;
  //         element.children.forEach(r => {
  //             r.title = `${r.children[0].threedigit}-${r.children[r.children.length - 1].threedigit} ${r.title}`
  //         });
  //     });

  // }
  getLeafItems = (nodes) => {
    let leaves = [];

    // Recursive function to traverse the hierarchy
    const traverse = (node) => {
        if (!node.children || node.children.length === 0) {
            // If the node has no children, it's a leaf node
            leaves.push(node);
        } else {
            // If the node has children, traverse each child
            if(Array.isArray(node.children)) node.children.forEach(child => traverse(child));
        }
    }

    // Start the traversal for each root node
    nodes.forEach(node => traverse(node));

    return leaves;
};
  onExpand = (expandedKeys) => {
    console.log("onExpand", expandedKeys);
    this.setState({
      expandedKeys,
      autoExpandParent: false,
    });
  };
  onSelect = (selectedKeys, info) => {
    if (info && !info.node.props.isLeaf) return;
    if(this.state.icdFlatData.length>0){
      const r = this.state.icdFlatData.find((p) => p.code == selectedKeys[0]);
      this.props.onSelect(r);
    }else{
      //legacy mode
      const r = codes.find((p) => p.icd9 == selectedKeys[0]);
      this.props.onSelect(r);
    }
    this.setState({ selectedKeys });
  };
  onAutoCompleteSelect = (v, o) => {
    this.onSelect([v]);
    this.setState({ expandedKeys: [v], selectedKeys: [v], autoExpandParent: true });
  };
  renderTreeNodesLegacy = (data) =>
    Array.isArray(data)&&data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodesLegacy(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode key={item.key} {...item} />;
    });

    renderTreeNodes = (data) =>
    Array.isArray(data)&&data.map((item) => {
      if (item.children) {
        return (
          <TreeNode title={`${item.code} ${item.name}`} key={item.code} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode title={`${item.code} ${item.name}`} key={item.code} dataRef={item} isLeaf={true}/>;
    });
  shouldComponentUpdate(nextProps, nextState) {
    //preventing parent triggered renders from form
    if (equal(this.state, nextState)) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <div>
        <DiagnosisSearch onChange={this.onAutoCompleteSelect} />
        {(this.state.loading||!this.state.loaded) && <Spin />}
        <Tree.DirectoryTree
          onExpand={this.onExpand}
          expandedKeys={this.state.expandedKeys}
          autoExpandParent={this.state.autoExpandParent}
          onSelect={this.onSelect}
          selectedKeys={this.state.selectedKeys}>

          {this.state.loaded&this.state.icdTreeData.length&&this.renderTreeNodes([...this.state.icdTreeData, this.state.customNode])}
          {this.state.loaded&!this.state.icdTreeData.length&&this.renderTreeNodesLegacy([...treeData, this.state.customNode])}

        </Tree.DirectoryTree>
      </div>
    );
  }
}
export default withTranslation()(DiagnosisTree);
