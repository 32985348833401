import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import {formatDate} from "../../Lib/Helpers";
import DepositControlApproval from "./DepositControlApproval";

const DepositControl = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        load();
    },[])
    
    const load=()=>{
        setLoading(true);
        exe("GetDeposits",).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData.deposits);
            }else message.error(r.msg);
        })
    }
    
    
    return (
        <div>
            <Button type={"link"} icon={<LegacyIcon type={"reload"} />} onClick={load}>{t("Reload")}</Button>
            <Table dataSource={data} rowKey={"id"} style={{marginTop:5}} loading={loading}>
                <Table.Column title={t("Id")} dataIndex={"transferId"} />
                <Table.Column title={t("Date")} dataIndex={"date"} render={v=>formatDate(v)} />
                <Table.Column title={t("Branch")} dataIndex={"branch"} />
                <Table.Column title={t("User")} dataIndex={"user"} />
                <Table.Column title={t("Payment Method")} dataIndex={"paymentMethod"} />
                <Table.Column title={t("Reference")} dataIndex={"concept"} />
                <Table.Column title={t("Currency")} dataIndex={"currency"} />
                <Table.Column title={t("Amount")} dataIndex={"amount"} />
                <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"upload"} />} onClick={()=>setSelected(r)} /> </div>}/>
            </Table>
            <DepositControlApproval visible={!!selected} onCancel={()=>setSelected(null)} onOk={()=>{setSelected(null);load();}} deposit={selected} workspaceId={props.workspaceId} />
        </div>
    );
};

export default DepositControl;