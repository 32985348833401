import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select} from "antd";

const ClaimPaymentSchemeSelect = (props) => {
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        setData(props.claimPaymentSchemes);
    },[props.claimPaymentSchemes])
    
    return (
        <div>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled}>
              {data.map(p=><Select.Option value={p.code} key={p.code}>{p.name}</Select.Option> )}
          </Select>  
        </div>
    );
};

export default ClaimPaymentSchemeSelect;