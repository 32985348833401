import React from "react";
import ReactDOM from "react-dom";
import "./i18n.js";
import App from "./App";
import "./index.css";
import {ConfigProvider, Spin} from "antd";
import { HashRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import * as newVersion from "./newVersion";
import { Suspense } from "react";
import SelfServiceApp from "./Components/SelfService/SelfServiceApp";

window.global = {};
ReactDOM.render(
  <Suspense fallback={<Spin style={{ marginLeft: 10, marginTop: 10 }} />}>
    <ConfigProvider >
    <HashRouter>
      {window.location.hash&&window.location.hash.includes("SelfService")?<SelfServiceApp />:<App />}
    </HashRouter>
    </ConfigProvider>
  </Suspense>,
  document.getElementById("root")
);
//registerServiceWorker();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          //window.location.reload();
          newVersion.showUpdateVersion();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

if (module.hot) {
  module.hot.accept("./App", () => {
    ReactDOM.render(<App />, document.getElementById("root"));
  });
}
