import { DeleteOutlined, EditOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Select, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ClauseManual from "./ClauseManual";

const Clauses = (props) => {
  const [newClauseVisible, setNewClauseVisible] = useState(false);
  const [newSelectedClauses, setNewSelectedClauses] = useState([]);
  const [sectionFilter, setSectionFilter] = useState(null);
  const [t] = useTranslation();
  const [manualClauseVisible, setManualClauseVisible] = useState(false);
  const [editClause,setEditClause]=useState();

  useEffect(() => {
    if (props.config) load();
  }, [props.config]); //when config is available or cahnges

  const onNewClausesSelected = () => {
    console.log("selected", newSelectedClauses);
    props.onChange([...props.value, ...newSelectedClauses]);
    setNewSelectedClauses([]); //clearing selection
    setNewClauseVisible(false);
  };

  const load = () => {
    if (props.policy.id) {
      //already saved. Respecting current values
    } else {
      //initial view. No user interaction yet, setting config values (mandatory and selected by default)
      props.onChange(productConfigCaluses.filter((p) => p.mandatory || p.selected));
    }
  };
  const onManualClause = (values) => {
    if(values.edit){
      //UPDATE
      if(props.value.filter(p=>p.code===values.code)>1){
        message.error(t("Duplicated code"));
        return;
      }
      props.onChange(props.value.map(p=>p.code===values.code?values:p));
    }else{
      //ADD
      if(props.value.some(p=>p.code===values.code)){
        message.error(t("Duplicated code"));
        return;
      }
      props.onChange([...props.value, ...[values]]);
    }
    setManualClauseVisible(false);
    setEditClause(undefined);
  };
  const onRemoveClause = (record) => {
    props.onChange(props.value.filter((p) => p.code !== record.code));
  };
  const onSelectSection = (v) => {
    console.log(v);
    setSectionFilter(v);
  };
  const included = props.value || [];
  const productConfigCaluses = (props.config && props.config.Clauses) || [];
  const selectableClauses = productConfigCaluses
    .filter((p) => !included.some((q) => q.code == p.code))
    .filter((p) => (sectionFilter ? p.section == sectionFilter : true));
  
  const onEditClause=r=>{
    setEditClause(r);
    setManualClauseVisible(true);
  }
  return (
    <div className={props.disabled ? "disabled" : ""}>
      <div style={{ display: props.disabled ? "none" : "block" }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setNewClauseVisible(true)} style={{ marginBottom: 5 }} disabled={props.readOnly}>
          {t("Add")}
        </Button>
        <Button type="link" icon={<PlusCircleOutlined />} onClick={() => setManualClauseVisible(true)} disabled={props.readOnly}>
          {t("Add manual clause")}
        </Button>
      </div>
      <Table dataSource={props.value} rowKey={"code"}>
        <Table.Column title={t("Code")} dataIndex="code" width="100px" />
        <Table.Column title={t("Section")} dataIndex="section" width="200px" />
        <Table.Column title={t("Text")} dataIndex="text" ellipsis />
        <Table.Column
          title={t("Actions")}
          dataIndex="id"
          key="actions"
          width="100px"
          render={(v, r) => (
            <div>
              <Button type="link" icon={<DeleteOutlined />} onClick={() => onRemoveClause(r)} disabled={r.mandatory || props.readOnly} />
              <Button type="link" icon={<EditOutlined />} onClick={() => onEditClause(r)} disabled={ props.readOnly} />
            </div>
          )}
        />
      </Table>
      <Modal title={t("Policy Clauses")} visible={newClauseVisible} onCancel={() => setNewClauseVisible(false)} onOk={() => onNewClausesSelected()}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ whiteSpace: "nowrap" }}>Section filter:</span>
          <Select
            placeholder={t("Section filter")}
            style={{ width: "100%", marginBottom: 5, marginLeft: 5 }}
            allowClear
            showSearch
            onChange={(v) => onSelectSection(v)}
            filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0}>
            {[...new Set(productConfigCaluses.map((p) => p.section))].map((p) => (
              <Select.Option value={p}>{p}</Select.Option>
            ))}
          </Select>
        </div>
        <Table
          rowKey="code"
          dataSource={selectableClauses}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => setNewSelectedClauses(selectedRows),
            getCheckboxProps: (r) => ({ disabled: r.mandatory }),
          }}>
          <Table.Column title={t("Code")} dataIndex="code" width="100px" />
          <Table.Column title={t("Section")} dataIndex="section" width="100px" />
          <Table.Column title={t("Text")} dataIndex="text" ellipsis />
        </Table>
      </Modal>
      <ClauseManual selected={editClause} visible={manualClauseVisible} onCancel={() => setManualClauseVisible(false)} onOk={(v) => onManualClause(v)} />
    </div>
  );
};

export default Clauses;
