import React, { useEffect, useState } from 'react';
import { exe } from '../../Lib/Dal';
import { Button, Form, Input, Modal, Table, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import BatchSelect from './BatchSelect';
import { getColor } from '../../Lib/Helpers';
import Status from '../Shared/Status';
const BatchOperations = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newOperationVisible, setNewOperationVisible] = useState(false);
    const [form] = Form.useForm();
    const [t]=useTranslation();

    useEffect(() => {
        load();
    }, []);

    const load=()=>{
        setLoading(true);
        exe("RepoPensionBatchOperation",{operation:"GET",filter:`pensionSchemeId=${props.pensionId}`,include:["Process","Batch"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    const createNewBatchOperation=()=>{
        //validate form
        form.validateFields().then(values=>{
            exe("RepoPensionBatchOperation",{operation:"ADD",entity:{...values,pensionSchemeId:props.pensionId}}).then(r=>{
                if(r.ok){
                    message.success(r.msg);
                    setNewOperationVisible(false);
                    load();
                }else message.error(r.msg);
            })
        })
    }

    return (
        <div>
            <Button icon={<ReloadOutlined/>} type='link' onClick={()=>load()}>{t("Reload")}</Button>
            <Button icon={<PlusOutlined/>} type='link' onClick={()=>setNewOperationVisible(true)}>{t("New Batch Operation")}</Button>
            <Table dataSource={data} loading={loading} rowKey="id">
                <Table.Column title="ID" dataIndex="id"  />
                <Table.Column title="Name" dataIndex="name" />
                <Table.Column title="Status" dataIndex={["Batch","status"]} />
                <Table.Column
                    dataIndex="Process"
                    fixed="right"
                    title={t("Workflow")}
                    render={(v,r) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load()} />}
                    />
            </Table>
            <Modal visible={newOperationVisible} onCancel={()=>setNewOperationVisible(false)} onOk={createNewBatchOperation} title={t("New Batch Operation")}>
                <Form form={form}>
                    <Form.Item label={t("Name")} name="name" rules={[{required:true}]}><Input /></Form.Item>
                    <Form.Item label={t("Batch")} name="batchId" rules={[{required:true}]}><BatchSelect /></Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
export default BatchOperations;