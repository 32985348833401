import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import { Button, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from '@ant-design/icons';

const PensionDocumentSelect=props=>{
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();

    useEffect(() => {
        if(props.pensionSchemeId) load()
    }, [props.pensionSchemeId]);
    
      const load = () => {
        setLoading(true);
        exe("RepoPensionDocument", { operation: "GET",filter:`pensionSchemeId=${props.pensionSchemeId}` }).then((r) => {
          setLoading(false);
          if (r.ok) {
            setData(r.outData);
          } else {
            message.error(r.msg);
          }
        });
      };
      return (
        <div style={{display:"flex"}}>
          <Select
            value={props.value}
            onChange={props.onChange}
            onClear={()=>props.onChange(null)}
            loading={loading}
            allowClear
            //showSearch
            disabled={props.disabled}
            placeholder={t("Select a document")}
            optionFilterProp="children">
            {data.map((p) => (
              <Select.Option value={p.id} key={p.id}>
                {p.fileName}
              </Select.Option>
            ))}
          </Select> 
            {data.length === 0 && <Button type="link" icon={<ReloadOutlined />} onClick={load} />}
        </div>
      );
}
export default PensionDocumentSelect;