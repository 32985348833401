import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import { Select } from "antd";
import Currency from "../Shared/Currency";

const BeneficiaryAccountSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (props.value && !data.find((p) => p.id == props.value)) loadValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (props.contactId) loadContactAccounts(props.contactId);
  }, [props.contactId,props.policyId,props.currency]);

  const loadContactAccounts = (contactId) => {
    setLoading(true);
    const currencyFilter=props.currency?` AND currency='${props.currency}'`:"";
    const policyFilter=props.policyId?` AND lifePolicyId=${props.policyId}`:""
    
    exe("RepoAccount", { operation: "GET", filter: `holderId=${contactId}${currencyFilter}${policyFilter}` }).then((r) => {
      if (r.ok) setData(r.outData);
    });
    setLoading(false);
  };

  const loadValue = (value) => {
    if (!value) return;
    exe("RepoAccount", { operation: "GET", filter: `id=${value}` }).then((r) => {
      if (r.ok) setData(r.outData);
    });
  };

  const filterFunction = props.filter?eval(props.filter):()=>true;// Convert the string to a function.
  const Options = data.filter(filterFunction).map((d) => (
    <Select.Option value={d.id} key={d.id} currency={d.currency}>
      {d.accNo} {d.lifePolicyId && <a href={"#/lifepolicy/" + d.lifePolicyId}>{d.lifePolicyId}</a>}
      {<Currency.Flag currency={d.currency} />}
    </Select.Option>
  ));

  return (
    <Select
      showSearch
      allowClear
      disabled={props.disabled}
      value={props.value}
      style={props.style}
      onChange={props.onChange}
      onSelect={props.onSelect && props.onSelect}
      loading={loading}
      optionFilterProp="children">
      {Options}
    </Select>
  );
};

export default BeneficiaryAccountSelect;
