import React, { useEffect,useRef,useState } from "react";
import {basicSetup, EditorView} from "codemirror"
import {keymap} from "@codemirror/view"
import {indentWithTab} from "@codemirror/commands"
import {EditorState} from "@codemirror/state"
import {javascript,javascriptLanguage} from "@codemirror/lang-javascript"
import * as yamlMode from '@codemirror/legacy-modes/mode/yaml'; //yaml support
import { StreamLanguage, LanguageSupport } from "@codemirror/language" //yaml support
import { exe } from 'Lib/Dal';
import { autocompletion } from "@codemirror/autocomplete";

const CodeEditorW=(props)=> {
    const [editor,setEditor]=useState();
    const commandList=useRef([]);
  
    const divId = props.divId ? props.divId : "myCodeMirror"; 

    useEffect(()=>loadCommands(),[]);

    const loadCommands=()=>{
        exe("GetCommands").then(r=>{
            commandList.current=r.outData;
        })
    }

    const myCompletions= async(context)=> {
        let word = context.matchBefore(/\w*/);
      
        if (word === null || (word.from == word.to && !context.explicit)) return null;
        //discard if word does not start with uppercase
        if(word.text.length>0 && word.text[0]!==word.text[0].toUpperCase()) return null;
        return {
          from: word.from,
          options:commandList.current.map(p=>({label:p.name,type:"variable",info:"(SISos Command)"}))
        //   options: [
        //     { label: 'true', type: 'keyword' },
        //     { label: 'UploadBytes', type: 'variable', info: '(Command)(string bytes,string fileName)' },
        //     { label: 'magic', type: 'text', apply: '⠁⭒*.✩.*⭒⠁', detail: 'macro' },
        //   ],
        };
      }

      useEffect(()=>{
        //if value is set via formField
        if(props.formField&&editor&&props.value&&editor.state.doc.toString()===""){
            editor.dispatch({
                changes: {from: 0, to: editor.state.doc.length, insert: props.value}
              })
        }
      },[props.value])

    useEffect(()=>{
        if(!editor){
            const view = new EditorView({
                state: EditorState.create({
                    doc: props.value,
                    extensions: [
                        basicSetup,
                        keymap.of([
                            { key: "Ctrl-1", run: ()=>{if (props.saveHotKey) props.saveHotKey()} },
                            indentWithTab
                          ]),
                          props.javascript?javascriptLanguage.data.of({autocomplete: myCompletions}):autocompletion({
                            override: [myCompletions],activateOnTyping:false
                          }),
                        props.javascript?javascript({jsx:true}):new LanguageSupport(StreamLanguage.define(yamlMode.yaml)),
                        EditorView.updateListener.of(e=> {
                            props.onChange&&props.onChange(e.state.doc.toString());
                        })
                    ]
                }),
                parent: document.getElementById(divId)
            })
            
            //back compatibility
            window.global[divId]=view; 
            view.setValue=v=>view.dispatch({
                changes: {from: 0, to: view.state.doc.length, insert: v}
              });
            view.getValue=()=>view.state.doc.toString();
            
            setEditor(view);
            //fix line numbering height in modals
            setTimeout(()=>view.requestMeasure(),400); //todo: explore this npm install react-intersection-observer --save
          
        }else{
            //not used. Dependency not set
            if(props.value){
                editor.dispatch({
                    changes: {from: 0, to: editor.state.doc.length, insert: props.value}
                  })
            }
        }

    },[])
   
  return (
    <div id={divId} className="CodeMirror" dir="ltr">
      
    </div>
  );
}
export default CodeEditorW