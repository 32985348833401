import React, { useEffect, useState } from "react";
import { message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const ProrateModeSelect = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(defaultReasons);
  }, []);

  useEffect(() => {
    if (props.config && props.config.Main && props.config.Main.cancellationProrateMode) {
      props.onChange(props.config.Main.cancellationProrateMode);
    } else {
      props.onChange(0);
    }
  }, [props.config]);

  const defaultReasons = [
    { code: 0, name: t("Daily") },
    {
      code: 1,
      name: t("Monthly"),
    },
  ];
  return (
    <div>
      <Select value={props.value} loading={loading} disabled>
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ProrateModeSelect;
