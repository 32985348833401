import { Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GroupCoverageHandlingSelect = (props) => {
  const [t] = useTranslation();
  const options = props.options;
  const defaultValue = options[0];

  useEffect(() => {
    if(props.value) props.onChange(props.value);
    else props.onChange(defaultValue);
  }, [props.value]);
  
  return (
    <div>
      <Select
        value={props.value}
        onChange={(v) => props.onChange(v)}
        placeholder={t("Plase select coverage handling")}
        disabled={options.length == 1}>
        <Select.Option value="SAME" disabled={options.indexOf("SAME") == -1}>
          {t("Same coverages for every certificate")}
        </Select.Option>
        <Select.Option value="SAMEGROUP" disabled={options.indexOf("SAMEGROUP") == -1}>
          {t("Same coverages per certificate group")}
        </Select.Option>
        <Select.Option value="DIFFERENT" disabled={options.indexOf("DIFFERENT") == -1}>
          {t("Different coverages in every certificate")}
        </Select.Option>
      </Select>
    </div>
  );
};

export default GroupCoverageHandlingSelect;
