import React, { useState, useEffect } from "react";
import { exe } from "../../Lib/Dal";
import {Radio, Select, Table, Tag} from "antd";
import { formatMoney, getColor, formatDate } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";

const ContactPoliciesSelect = (props) => {
    const [t, i18n] = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.contactId) {
            switch (props.role) {
                case "policyholder":
                    load("holderId=" + props.contactId);
                    break;
                case "insured":
                    load(`id in (SELECT lifePolicyId from insured where contactId=${props.contactId})`);
                    break;
                case "beneficiary":
                    load(`id in (SELECT lifePolicyId from beneficiary where contactId=${props.contactId})`);
                    break;
                default:
                    load("holderId=" + props.contactId);
                    break;
            }
        }
    }, [props.contactId]);

    const load = (filter) => {
        setLoading(true);
        exe("RepoLifePolicy", { operation: "GET", filter: filter, }).then((r) => {
            setLoading(false);
            setData(r.outData);
        });
    };
    return (
        <div style={{display:"flex"}}>
            <Select value={props.value} onChange={props.onChange} dataSource={data} allowClear style={{width:"100%"}} placeholder={t("Please select")} loading={loading}>
                {data.map(p=><Select.Option key={p.id} value={p.id}>{p.code?p.code:p.id}</Select.Option> )}
            </Select>
            {props.value&&<a style={{marginLeft:5, whiteSpace:"nowrap"}} href={"#/lifepolicy/"+props.value}>{t("Open")}</a>}
        </div>
    );
};

export default ContactPoliciesSelect;
