import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  DatePicker,
  Tag,
  Switch,
  Checkbox,
  InputNumber,
} from "antd";
import { exe } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";
import moment from "moment";
import Compare from "../Shared/Compare";
import ProductSelect from "../Life/ProductSelect";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";
import SimpleContactSelect from "../Contact/SimpleContactSelect";

const FundFilter = (props) => {
  const [matches, setMatches] = useState(null);
  const [t, i18n] = useTranslation();



  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      console.log(values, "FORM VALUES");
      let queries = [];
      

      if (values.id) queries.push(`id=${values.id}`);
      if(values.contactId) queries.push(`contactId=${values.contactId}`);
      if (values.currency !== undefined) queries.push(`currency='${values.currency}'`);
      if (values.name) queries.push(`name like '%${values.name}%'`);


      const filterQuery = queries.join(" AND ");
      console.log(queries, filterQuery);

      exe("RepoFund", { operation: "GET", filter: filterQuery,include:["Contact"] }).then((r) => {
        props.onResults(r.outData);
        setMatches(r.total);
      });
    });
  };

  return (
    <div>
      <Drawer title={t("Movement Filter")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">

          <Form.Item label={t("ID")} style={{ width: "100%" }}>
              {getFieldDecorator("id")(<InputNumber style={{ width: "100%" }} />)}
            </Form.Item>
            <Form.Item label={t("Name")} style={{ width: "100%" }}>
              {getFieldDecorator("name")(<Input style={{ width: "100%" }} />)}
            </Form.Item>
          <Form.Item label={t("Holder")}>{getFieldDecorator("contactId")(<SimpleContactSelect />)}</Form.Item>
          <Form.Item label={t("Currency")} style={{ width: "100%" }}>
              {getFieldDecorator("currency")(<Currency />)}
            </Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> Results
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(FundFilter);
