import React, { useState, useEffect } from "react";
import { FileProtectOutlined } from '@ant-design/icons';
import { Select, Tooltip, Divider, Radio } from "antd";
import { exe } from "Lib/Dal";
import { useTranslation } from "react-i18next";

//used inside forms = props = fn onChange, string value
const PolicySelect = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [searchBy, setSearchBy] = useState("code");
  
  useEffect(() => {
    if (props.display) {
      setData([{ id: props.value, code: props.display }]);
    }
  }, []);
  useEffect(() => {
    if (!props.display && data.length == 0 && props.value) {
      exe("RepoLifePolicy", { operation: "GET", filter: `id=${props.value}` }).then((r) => {
        if (r.outData) {
          setData(r.outData || []);
          onSelect(r.outData[0].id, r.outData[0]);
          if (props.onChange) props.onChange(r.outData[0].id);
        }
      });
    }
  }, [props.value]);

  const handleSearch = (v) => {
    if (!v) return;
    let filter=`code LIKE N'%${v}%'`;
    let include=[];
    if(searchBy==="name") {
        filter = `holderId in (SELECT id from Contact WHERE (RTRIM(ISNULL([name],''))+' '+RTRIM(ISNULL(surname1,''))+' '+RTRIM(ISNULL(surname2,''))) like '%${v}%')`;
        include=["Holder"];
    }else if(searchBy==="nationalId") {
        filter = `holderId in (SELECT id from Contact WHERE nationalId LIKE N'%${v}%')`;
        include=["Holder"];
    }else if(searchBy==="passport") {
        filter = `holderId in (SELECT id from Contact WHERE passport LIKE N'%${v}%')`;
        include=["Holder"];
    }else if(searchBy==="nif") {
        filter = `holderId in (SELECT id from Contact WHERE nif LIKE N'%${v}%')`;
        include=["Holder"];
    }else if(searchBy==="id") {
        filter = `id=${v}`;
        include=[];
    }
    
    if(props.filter) filter+=" AND "+props.filter;
    if(!props.proposals) filter+=" AND active=1";
    exe("RepoLifePolicy", { operation: "GET", filter: filter,size:15,include:include }).then((r) => {
      setData((r.outData || []).map((p) => ({ ...p, label: `${p.code} ${searchBy==="code"?"":`(${searchBy==="name"? p.Holder.FullName:
              searchBy==="nationalId"? p.Holder.nationalId: 
                  searchBy==="passport"?p.Holder.passport: 
                      searchBy==="nif"?p.Holder.nif: 
                          searchBy==="id"?p.id:""})`}` })));
    });
  };
  const onSelect = (policy, policyObj) => {
    if (props.onSelect) props.onSelect(policy, policyObj);
  };
  const  onSelectRadioItem=(e,item)=> {
        //workaround for antd bug
        e.preventDefault();
        setSearchBy(item);
  }

  return (
    <div style={{ position: "relative" }}>
      <Select
        style={{ width: "100%" }}
        onSearch={handleSearch}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
        showSearch
        filterOption={false}
        allowClear
        showArrow={false}
        onSelect={(v, o) => onSelect(v, o.props.policy)}
        onClear={()=>props.onClear && props.onClear()}
        dropdownRender={(menu) => <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div onMouseDown={e => e.preventDefault()}
                 style={{ margin: '4px 4px 4px 4px', padding:"4px 4px 8px 4px" }}>
                <Radio.Group onChange={e=>setSearchBy(e.target.value)} value={searchBy}>
                    <Radio value={"code"}>{<span onClick={e=>onSelectRadioItem(e,"code")}>
                       <Tooltip title={t("Policy code")}> {t("Code")}</Tooltip>
                    </span>}</Radio>
                    <Radio value={"id"}>{<span onClick={e=>onSelectRadioItem(e,"id")}>
                        <Tooltip title={t("Policy id")}> {t("Id")}</Tooltip></span>}
                    </Radio>
                    <Radio value={"name"}>{<span onClick={e=>onSelectRadioItem(e,"name")}>
                        <Tooltip title={t("Policyholder's")+" "+t("Name")}> {t("Name")}</Tooltip>
                    </span>}</Radio>
                    <Radio value={"nationalId"}>{<span onClick={e=>onSelectRadioItem(e,"nationalId")}>
                        <Tooltip title={t("Policyholder's")+" "+t("National ID")}>  {t("National ID")}</Tooltip>
                    </span>}</Radio>
                    <Radio value={"passport"}>{<span onClick={e=>onSelectRadioItem(e,"passport")}>
                        <Tooltip title={t("Policyholder's")+" "+t("Passport")}> {t("Passport")}</Tooltip>
                    </span>}</Radio>
                    <Radio value={"nif"}>{<span onClick={e=>onSelectRadioItem(e,"nif")}>
                        <Tooltip title={t("Policyholder's")+" "+t("NIF")}> {t("NIF")}</Tooltip>
                    </span>}</Radio>
                </Radio.Group>
            </div>
        </div>}
        placeholder={t("Type to search policy...")}>
        {data.map((d) => (
          <Select.Option key={d.id} value={d.id} policy={d}>
            {d.label?d.label:d.code}
          </Select.Option>
        ))}
      </Select>
      <a href={"#/lifePolicy/" + props.value}>
        <Tooltip title={t("Open Policy")}>
          <FileProtectOutlined
            style={{
              position: "absolute",
              right: "30px",
              top: "9px",
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.25)",
              fontSize: 12,
            }} />
        </Tooltip>
      </a>
    </div>
  );
};

export default PolicySelect;
