import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import AccountSelect from "../Shared/AccountSelect";

const GoalPayment = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onOk(values);
    });
  };
  return (
    <div>
      <Modal title={t("Reward Payment")} visible={props.visible} onOk={onOk} onCancel={props.onCancel}>
        <Form.Item label={t("Member Destination Account")}>
          {field("accountId", { rules: [{ required: true }] })(<AccountSelect style={{ width: "100%" }} holderId={props.contactId} />)}
        </Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(GoalPayment);
