import React, {Component, useState} from "react";
import { FilterOutlined, LockOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Divider, message } from "antd";
import { exe } from "Lib/Dal";
import Filter from "./ClaimListFilter";
import DefaultPage from "../Shared/DefaultPage";
import { formatMoney, formatDate } from "../../Lib/Helpers";
import {useTranslation, withTranslation} from "react-i18next";
import DownloadOptions from "../Shared/DownloadOptions";

const ButtonGroup = Button.Group;
const { Column } = Table;

const ClaimList=props=>{
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [filter,setFilter]=useState(false);
    const [t]=useTranslation();
    const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
    const onFilteredResults = (filteredData, totalResults) => {
        setData(filteredData);
        setPagination({ ...pagination, total: totalResults, current: pagination.total !== totalResults ? 1 : pagination.current });
    };
    return (
      <DefaultPage
        title={t("Claim List")}
        icon="folder-open"
        loading={loading}
        extra={
          <div>
            <ButtonGroup>
             <DownloadOptions data={data.map(p=>({
                 [t("ID")]:p.id,
                 [t("Code")]:p.code,
                 [t("Notified")]:formatDate(p.created),
                 [t("Claimer")]:p.Claimer.FullName,
                 [t("Insured")]:p.Contact.FullName,
                 [t("Policy")]:p.Policy.code,
                 [t("Type")]:p.claimType,
                 [t("ICD")]:p.icd,
                 [t("Sum Insured")]:formatMoney(p.Policy.insuredSum, p.Policy.currency),
                 [t("LoB")]:p.Policy.lob,
                 [t("Product")]:p.Policy.productCode,
                 [t("Status")]:p.Process ? p.Process.estado : t("N/A")
             }))} filename={"claims"} />
              <Button type="primary" onClick={() => setFilter(true)} icon={<FilterOutlined />}>
                {t("Filter")}
              </Button>
            </ButtonGroup>
          </div>
        }>
        <Table dataSource={data} loading={loading} rowKey="id" pagination={pagination}
               onChange={(pag) => setPagination(pag)}>
          <Column title={t("ID")} dataIndex="id" render={(v, record) => <a href={"#/healthclaim/" + record.id}>{v}</a>} />
          <Column title={t("Code")} dataIndex="code" render={(v,r)=><span style={{whiteSpace:"nowrap"}}>{v}{r.Policy&&r.Policy.restricted&&<LockOutlined style={{marginLeft:3,marginRight:3}} />}</span>} />
          <Column title={t("Notified")} dataIndex="created" render={(v) => formatDate(v)} />
          <Column title={t("Claimer")} dataIndex="Claimer" render={(f, record) => <a href={"#/contact/" + f.id}>{f.FullName}</a>} />
          <Column title={t("Insured")} dataIndex="Contact" render={(f, record) => <a href={"#/contact/" + f.id}>{f.FullName}</a>} />
          <Column title={t("Policy")} dataIndex="Policy" render={(f, record) => <a href={"#/lifepolicy/" + f.id}>{f.code}</a>} />
          <Column title={t("Type")} dataIndex="claimType" />
          <Column title={t("ICD")} dataIndex="icd" />
          <Column title={t("Sum Insured")} key={"sumInsured"} dataIndex="Policy" render={(f, record) => formatMoney(f.insuredSum, f.currency)} />
          <Column title={t("LoB")} key={"lob"} dataIndex="Policy" render={(f, record) => f.lob} />
          <Column title={t("Product")} key={"productCode"} dataIndex="Policy" render={(f, record) => f.productCode} />
          <Column title={t("Status")} dataIndex="status" render={(text, r) => <Tag> {r.Process ? r.Process.estado : t("N/A")}</Tag>} />
          <Column
            title={t("Action")}
            render={(text, record) => (
              <span>
                <a href={"#/healthclaim/" + record.id}>{t("View")}</a>
                <Divider type="vertical" />
                <a href={"#/contact/" + record.contactId} />
              </span>
            )}
          />
        </Table>
        <Filter
          visible={filter}
          onClose={() => setFilter(false)}
          onResults={(d,total) => onFilteredResults(d,total)}
          onLoading={v=>setLoading(v)}
          pagination={pagination}
        />
      </DefaultPage>
    );}
export default ClaimList;
