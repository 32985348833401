import { LeftOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import CoverageClaim from "./CoverageClaim";

const CoverageClaimList = (props) => {
  const [t] = useTranslation();
  const [operation, setOperation] = useState("LIST");
  const [data, setData] = useState([]);
  const [record, setRecord] = useState(null);
  const [loading, setLoading] = useState(false);

  const onNewCoverageClaim = () => {
    if (data.some((p) => p.claimId == props.claimId)) {
      //indemnity screen opened for current claim by default
      message.error(t("There already exists an indemnity record for this claim"));
    } else {
      setRecord(null);
      setOperation("ADD");
    }
  };
  const onOpenCoverageClaim = (r) => {
    setRecord(r);
    setOperation("UPDATE");
  };
  const coverageClaimType = props.claimType;
  useEffect(() => load(), [props.coverage.id]);
  const load = (list) => {
    if (!props.coverage || !props.coverage.id) return;
    setLoading(true);
    exe("RepoLifeCoverageClaim", { operation: "GET", filter: "lifeCoverageId=" + props.coverage.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        if (!list && r.outData.some((p) => p.claimId == props.claimId)) {
          //indemnity screen opened for current claim by default
          onOpenCoverageClaim(r.outData.find((p) => p.claimId == props.claimId));
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  const reload = () => load(true);
  return (
    <div>
      {operation === "LIST" && (
        <div>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => onNewCoverageClaim()}
            disabled={!props.claimId}
            style={{ marginBottom: 5 }}
            disabled={!props.elegibleCoverages || props.elegibleCoverages.indexOf(props.coverage.code) == -1}>
            {t("New")}
          </Button>
          <Button type="link" icon={<ReloadOutlined />} onClick={() => reload()} style={{ marginBottom: 5 }}>
            {t("Reload")}
          </Button>
          <Table dataSource={data} loading={loading}>
            <Table.Column title={t("Id")} dataIndex="id" />
            <Table.Column title={t("Event Date")} dataIndex="eventDate" />
            <Table.Column title={t("Claim")} dataIndex="claimId" />
            <Table.Column title={t("Final Indemnity Value")} dataIndex="finalIndemnityValue" />
            <Table.Column
              title={t("Actions")}
              render={(v, r) => (
                <Button type="link" onClick={() => onOpenCoverageClaim(r)}>
                  {t("Open")}
                </Button>
              )}
            />
          </Table>
        </div>
      )}
      {operation !== "LIST" && (
        <div>
          <Button type="link" icon={<LeftOutlined />} onClick={() => setOperation("LIST")} style={{ float: "left" }}>
            {t("Claims")}
          </Button>
          <CoverageClaim
            record={record}
            config={props.config}
            operation={operation}
            claimId={props.claimId}
            claimType={coverageClaimType}
            coverageId={props.coverage.id}
            coverageCode={props.coverage.code}
            currency={props.currency}
            reload={() => reload()}
          />
        </div>
      )}
    </div>
  );
};

export default CoverageClaimList;
