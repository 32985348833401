import React, { Component } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Typography,
  Input,
  Select,
  Row,
  Col,
  Slider,
  Button,
  Table,
  Divider,
  message,
  Tag,
  Checkbox,
} from "antd";
import { exe } from "Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import { withTranslation } from "react-i18next";
import SpecialtySelect from "./SpecialtySelect";
import AreaServedSelect from "./AreaServedSelect";
import NetworkSelect from "./NetworkSelect";
import ProviderTypeSelect from "./ProviderTypeSelect";

const { Title } = Typography;
const { Column } = Table;

class ProviderSearch extends Component {
  state = {
    loading: false,
    data: [],
    specialties:[]
  };
  componentDidMount() {
    this.loadSpecialties();
  }

  handleSearch = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //data transform - ini
        let filter = "1=1 ";
        Object.keys(values).forEach((key) => {
          if (values[key] && typeof values[key] == "string") filter += "AND " + key + " LIKE N'%" + values[key] + "%' ";
          if (values[key] && typeof values[key] == "number") filter += "AND " + key + ">=" + values[key] + " ";
          if (values[key] && Array.isArray(values[key])) {
            filter += "AND (1=1 ";
            values[key].forEach((element) => {
              filter += "AND " + key + " LIKE N'%" + element + "%' ";
            });
            filter += ")";
          }
        });
        //values.specialties;
        //data transform - end
        console.log("Received values of form: ", values, filter);
        this.setState({ loading: true });
        exe("RepoProvider", {
          operation: "GET",
          filter: filter,
        }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            this.setState({ modalVisible: false, data: r.outData });
          } else {
            message.error(r.msg);
          }
        });
      } else {
        console.log(err, "ERROR VAL");
        message.error(this.props.t("Please fill all required fields"));
      }
    });
  };
   loadSpecialties = () => {
    exe("RepoSpecialtyCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) {
        this.setState({specialties:r.outData});
    }else console.error(r.msg);
  })};
  handleOpenRecord = (record) => {
    window.location.hash = "#/provider/" + record.code;
  };

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <DefaultPage title={t("Provider Search")} icon="medicine-box" loading={this.state.loading}>
        <Form layout="vertical">
          <Title level={4}>{t("Search Criteria")}</Title>
          <Row gutter={16}>
            {/* ////////////////////////////////ROW1-COL1 ////////////////////////////////////////////////////////////////// */}
            <Col span={8}>
              <Form.Item label={t("Name")}>
                {getFieldDecorator("name", {
                  rules: [{ required: false }],
                })(<Input placeholder={t("Full provider name")} />)}
              </Form.Item>
              <Form.Item label={t("Specialties")}>
                {getFieldDecorator("specialties", {
                  rules: [{ required: false }],
                })(
                    <SpecialtySelect />
                )}
              </Form.Item>
              <Form.Item label={t("Networks")}>
                {getFieldDecorator("networks", {
                  rules: [{ required: false }],
                })(
                    <NetworkSelect />
                )}
              </Form.Item>
            </Col>
            {/* ////////////////////////////////ROW1-COL2 ////////////////////////////////////////////////////////////////// */}
            <Col span={8}>
              <Form.Item label={t("Medical License or Provider Code")}>
                {getFieldDecorator("code", {
                  rules: [{ required: false }],
                })(<Input placeholder="MD-XXXX-ZZ" />)}
              </Form.Item>
              <Form.Item label={t("ID Card")}>
                {getFieldDecorator("legalId", {
                  rules: [{ required: false }],
                })(<Input />)}
              </Form.Item>
              <Form.Item label={t("Area Served")}>
                {getFieldDecorator("areaServed", {
                  rules: [{ required: false }],
                })(
                    <AreaServedSelect />
                )}
              </Form.Item>
            </Col>
            {/* ////////////////////////////////ROW1-COL3 ////////////////////////////////////////////////////////////////// */}
            <Col span={8}>
              <Form.Item label={t("Provider Type")}>{getFieldDecorator("providerType")(<ProviderTypeSelect  />)}</Form.Item>
              <Form.Item label={t("Priority")}>
                {getFieldDecorator("priority", {
                  rules: [{ required: false }],
                })(
                  <Slider
                    marks={{
                      0: "F",
                      20: "E",
                      40: "D",
                      60: "C",
                      80: "B",
                      100: "A",
                    }}
                  />
                )}
              </Form.Item>
            
            </Col>
          </Row>

          <Button icon={<SearchOutlined />} type="primary" onClick={this.handleSearch} style={{ marginBottom: 5, marginTop: 5 }}>
            {t("Search")}
          </Button>
        </Form>
        <Title level={4}>{t("Results")}</Title>
        <Table dataSource={this.state.data} rowKey="code">
          <Column title={t("Name")} dataIndex="name" />
          <Column title={t("Medical License / Code")} dataIndex="code" />
          <Column title={t("ID Card")} dataIndex="legalId" />
          <Column title={t("Active")} dataIndex="active" render={(v) => <Checkbox checked={v} />} />
          <Column title={t("Priority")} dataIndex="priority" />
          <Column
            title={t("Specialties")}
            dataIndex="specialties"
            render={(v) => {
              const values = JSON.parse(v);
              if (!values) return;
              return values.map((q) => <Tag key={"sp" + q}>{this.state.specialties.find((s) => s.code === q)?t(this.state.specialties.find((s) => s.code === q).name):q}</Tag>);
            }}
          />

          <Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <Button type="link" onClick={() => this.handleOpenRecord(record)}>
                  {t("Open")}
                </Button>
              </span>
            )}
          />
        </Table>
      </DefaultPage>
    );
  }
}

const WrappedForm = Form.create()(ProviderSearch);
export default withTranslation()(WrappedForm);
