import React, {useEffect, useState} from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, InputNumber, Modal, Popconfirm } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {useTranslation} from "react-i18next";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";

const PayPlanDetailLine = (props) => {
    const [loading,setLoading]=useState(false);
    
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    
    useEffect(()=>{
        if(props.value){
            props.form.setFieldsValue(props.value);
        }else props.form.resetFields();
    },[props.visible])
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            console.log(values);
            props.onOk&&props.onOk(values);
            
        })
    }
    const onDelete=()=>{
        const values=props.form.getFieldsValue();
        props.onDelete&&props.onDelete(values);
    }
    return (
        <div>
            <Modal visible={props.visible} onOk={onSave} onCancel={props.onCancel}>
                <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={onDelete}>
                    <Button type={"link"} icon={<LegacyIcon type={"delete"} />} loading={loading} >{t("Delete")}</Button>
                </Popconfirm>
                {field("id")}
                {field("payPlanId")}
                {field("paid")}
                <Form.Item label={t("Concept")}>{field("concept",{rules:[{required:true}]})(<Input />)}</Form.Item>
                <Form.Item label={t("Detail")}>{field("detail",{rules:[{required:false}]})(<Input.TextArea />)}</Form.Item>
                <Form.Item label={t("Amount")}>{field("amount",{rules:[{required:true}]})(<Money currency={props.value&&props.value.currency} />)}</Form.Item>
                <Form.Item label={t("Payment Order")}>{field("order",{rules:[{required:false}]})(<InputNumber  />)}</Form.Item>
            </Modal>
        </div>
    );
};

export default Form.create()(PayPlanDetailLine);