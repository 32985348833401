import { Button, Table, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { exe } from '../../Lib/Dal';
import { PaperClipOutlined, ReloadOutlined, ClockCircleOutlined, CheckCircleOutlined, WarningOutlined,CloseCircleOutlined } from '@ant-design/icons';
import { formatDate } from '../../Lib/Helpers';
import { Space } from 'antd';
import ContactDocumentSelect from './ContectDocumentSelect';
import PensionDocumentSelect from './PensionDocumentSelect';

const PensionRequirements = (props) => {
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();

    useEffect(()=>{
        load();
    },[props.pensionSchemeId])

    const load=()=>{
        if(!props.pensionSchemeId) return;
        setLoading(true);
        exe("RepoPensionRequirement",{operation:"GET",filter:`pensionSchemeId=${props.pensionSchemeId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    const riaseRequirements=()=>{
        setLoading(true);
        exe("RaisePensionRequirements",{pensionSchemeId:props.pensionSchemeId}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setData(r.outData.requirements);
            }else message.error(r.msg);
        })  
    }
    const renderStatus=(status)=>{
        switch(status){
            case 0: return <Space><ClockCircleOutlined />{t("Pending")}</Space>;
            case 1: return <Space><CheckCircleOutlined />{t("Completed")}</Space>;
            case 2: return <Space><CloseCircleOutlined />{t("Failed")}</Space>;
            case 3: return <Space><WarningOutlined />{t("Skipped")}</Space>;

            default: return status;
        }
    }
    const onDocumentChange=(documentId,requirementId)=>{
        setLoading(true);
        exe("UpdatePensionRequirement",{requirementId:requirementId,pensionDocumentId:documentId}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                load();
            }else message.error(r.msg);
        })
    }
    const renderBlockLevel=v=>{
        switch(v){
            case 0: return t("Mandatory");
            case 1: return t("Optional");
            default: return t("Mandatory");
        }
    }
    return (
        <div>
            <Button type="link" onClick={load} icon={<ReloadOutlined />}>{t("Reload")}</Button>
            <Button type="link" onClick={riaseRequirements} icon={<PaperClipOutlined />}>{t("Raise Now")}</Button>
            <Table dataSource={data} rowKey="id" loading={loading}>
                <Table.Column title={t("Code")} dataIndex="code" />
                <Table.Column title={t("Name")} dataIndex="name" />
                <Table.Column title={t("Block Level")} dataIndex="blockLevel" render={renderBlockLevel} />
                <Table.Column title={t("Status")} dataIndex="status" render={renderStatus} />
                <Table.Column title={t("Status Date")} dataIndex="statusDate" render={v=>formatDate(v)} />
                <Table.Column title={t("Document")} dataIndex="pensionDocumentId" width={200} render={(v,r)=><PensionDocumentSelect value={v} onChange={(v)=>onDocumentChange(v,r.id)} pensionSchemeId={props.pensionSchemeId} />} />
            </Table>
        </div>
    )
}
export default PensionRequirements;