import { FileOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Breadcrumb,
    Button,
    Checkbox,
    Col,
    Input,
    InputNumber,
    message,
    notification,
    Row,
    Switch,
    Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FormLabel from "../Shared/FormLabel";
import moment from "moment";
import ContractSelect from "../Contract/ContractSelect";
import CalculationSchemeSelect from "./CalculationSchemeSelect";
import CalculationSchemeExe from "../Bpm/CalculationSchemeExe";
import ProfitCommissionParticipants from "./ProfitCommissionParticipants";
import ProfitCommissionLiquidations from "./ProfitCommissionLiquidations";
import DatePickerW from "../Shared/DatePickerW";

const ProfitCommission = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("main");
    const [showResult, setShowResult] = useState();

    useEffect(() => {
        if (!props.match.params.profitCommissionId) return;
        if (props.match.params.profitCommissionId == 0) {
            props.form.resetFields();
        } else {
            load(props.match.params.profitCommissionId);
        }
    }, [props.match.params.profitCommissionId]);

    const load = (id) => {
        setLoading(true);
        exe("RepoProfitCommission", { operation: "GET", filter: `id=${id}` }).then((r) => {
            setLoading(false);
            if (r.ok) {
                props.form.setFieldsValue(r.outData[0]);
            } else {
                message.error(r.msg);
            }
        });
    };
    const onSave = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
          
            const operation = values.id ? "UPDATE" : "ADD";
            setLoading(true);
            exe("RepoProfitCommission", { operation: operation, entity: values }).then((r) => {
                setLoading(false);
                if (r.ok) {
                    message.success(r.msg);
                    if (operation == "ADD") window.location = "#/profitCommission";
                } else {
                    message.error(r.msg);
                }
            });
        });
    };
    const onNew = () => {
        window.location.hash = "#/profitCommission/0";
    };
    const showCalculationDetail=(record)=>{
        setActiveTab("compute");
        setShowResult(record);
    }
    const field = props.form.getFieldDecorator;
    const values= props.form.getFieldsValue();

    return (
        <DefaultPage
            title={
                <span>
          {field("name")(<FormLabel />)} {t("Profit Commission Scheme")}
        </span>
            }
            icon="carry-out"
            routes={{
                routes: [
                    { path: "/", breadcrumbName: t("Home") },
                    { path: "profitCommission", breadcrumbName: t("Profit Commission List") },
                    { path: "", breadcrumbName: t("Profit Commission Definition") },
                ],
            }}
            extra={
                <div>
                    <Button type="primary" onClick={onSave} icon={<SaveOutlined />} loading={loading} style={{ marginRight: 5 }}>
                        {t("Save")}
                    </Button>
                    <Button onClick={onNew} icon={<FileOutlined />}>
                        {t("New")}
                    </Button>
                </div>
            }>
            <Tabs activeKey={activeTab} onChange={tab=>setActiveTab(tab)}>
                <Tabs.TabPane tab={t("Main")} key="main">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
                            <Form.Item label={t("Contract")}>
                                {field("contractId", { rules: [{ required: true }] })(<ContractSelect style={{ width: "100%" }} />)}
                            </Form.Item>
                            <Form.Item label={t("Calculation Scheme")}>
                                {field("schemeId", { rules: [{ required: true }] })(<CalculationSchemeSelect style={{ width: "100%" }} />)}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <div style={{ display: "flex" }}>
                                <Form.Item label={t("Start")}>
                                    {field("start", { rules: [{ required: false }], normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}
                                </Form.Item>
                                <Form.Item label={t("End")} style={{marginLeft:3}}>
                                    {field("end", { rules: [{ required: false }], normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}
                                </Form.Item>
                                
                            </div>
                              
                            <Form.Item label={t("Code")}>{field("code")(<Input />)}</Form.Item>
                            <Form.Item label={t("Compute with participant iterator")}>{field("perParticipant",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
                                
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t("ID")}>{field("id")(<FormLabel />)}</Form.Item>
                               
                        </Col>
                    </Row>
                </Tabs.TabPane>
                {!values.perParticipant&&<Tabs.TabPane tab={t("Participants")} key="members" disabled={props.match.params.profitCommissionId == 0}>
                    <ProfitCommissionParticipants profitCommissionId={values.id} />
                </Tabs.TabPane>}
                <Tabs.TabPane tab={t("Compute")} key="compute" disabled={props.match.params.profitCommissionId == 0}>
                    <CalculationSchemeExe schemeId={values.schemeId} context={{contractId:values.contractId,schemeId:values.schemeId}} showResult={showResult} />
                </Tabs.TabPane>

                <Tabs.TabPane tab={t("Liquidations")} key="liquidations" disabled={props.match.params.profitCommissionId == 0}>
                    <ProfitCommissionLiquidations profitCommissionId={values.id} 
                    schemeId={values.schemeId} 
                    context={{contractId:values.contractId}}
                    showCalculationDetail={record=>showCalculationDetail(record)}
                     />
                </Tabs.TabPane>
                
            </Tabs>
        </DefaultPage>
    );
};

export default Form.create()(ProfitCommission);
