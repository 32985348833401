import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import {formatDateShort} from "../../Lib/Helpers";

const ContractSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("GetContracts", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        placeholder={t("Please select contract")}
        value={props.value}
        onChange={props.onChange}
        style={props.style}
        loading={loading}
        disabled={props.disabled}
        allowClear
        showSearch  optionFilterProp="children"
        filterOption={(input, option) => {return option.props.children.join("").toLowerCase().indexOf(input.toLowerCase()) >= 0}}
      >
        {data.map((p) => (
          <Select.Option value={p.id} key={p.id}>
            {p.code}-{p.name}{p.endDate?("-"+formatDateShort(p.endDate)):""}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ContractSelect;
