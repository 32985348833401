import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  DownloadOutlined,
  DownOutlined,
  FileExcelOutlined,
  FilterOutlined,
  FolderOpenOutlined,
  LeftOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Table, Select, DatePicker, message, Tooltip, Dropdown, Menu, Badge } from "antd";
import { exe } from "../../Lib/Dal";
import useUpdateEffect, { formatMoney, formatDateShort } from "../../Lib/Helpers";
import moment from "moment";
import CessionEdit from "./CessionEdit";
import { useTranslation } from "react-i18next";
import CessionsFilter from "./CessionsFilter";
import { Excel } from "antd-table-saveas-excel";
import ClaimCessionsFilter from "./ClaimCessionsFilter";
import ClaimCessionEdit from "./ClaimCessionEdit";

const ContractCessions = (props) => {
  const [t, i18n] = useTranslation();
  const [date, setDate] = useState(moment());
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRecord, setEditRecord] = useState(null);
  const [claimEditRecord, setClaimEditRecord] = useState(null);
  const [type, setType] = useState("U");
  const [filterVisible, setFilterVisible] = useState(false);

  useEffect(() => {
    if (props.contractId) load();
  }, [props.contractId]);

  useUpdateEffect(() => {
    loadType();
  }, [date]);

  const loadType = () => {
    if (date) {
      if (type == "C") loadClaims();
      else load();
    } else setData([]);
  };
  const load = () => {
    setLoading(true);
    exe("RepoCession", { operation: "GET", filter: `contractId=${props.contractId} AND YEAR(start)=${date.year()} AND MONTH(start)=${date.month() + 1}` }).then(
      (r) => {
        setLoading(false);
        if (r.ok) {
          setData(r.outData);
        } else {
          message.error(r.msg);
        }
      }
    );
  };
  const loadClaims = () => {
    setLoading(true);
    exe("RepoLossCession", {
      operation: "GET",
      include: ["Cession", "Payout"],
      filter: `contractId=${props.contractId} AND YEAR(claimOccurrence)=${date.year()} AND MONTH(claimOccurrence)=${date.month() + 1}`,
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };

  const onChangeType = (v) => {
    setType(v);
    if (v == "C") loadClaims();
    else load();
  };
  const onFilterSearch = (values) => {
    setData(values);
  };
  const exportXlsx = () => {
    const exportData = data;
    const fields = Object.keys(exportData[0]);
    const excel = new Excel();
    excel
      .addSheet("Sheet1")
      .addColumns(fields.map((p) => ({ title: p, dataIndex: p }))) //antd column format
      .addDataSource(exportData)
      .saveAs("cessions.xlsx");
  };
  return (
    <div>
      {editRecord == null &&claimEditRecord==null&& (
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DatePicker.MonthPicker onChange={(v) => setDate(v)} value={date} />
            <Select style={{ width: 140, marginLeft: 5 }} defaultValue="U" onChange={(v) => onChangeType(v)} value={type}>
              <Select.Option value="U">{t("Underwritting")}</Select.Option>
              <Select.Option value="C">{t("Claims")}</Select.Option>
            </Select>

            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item>
                    <CSVLink data={data} filename={"cessions.csv"} target="_blank" disabled={data.length == 0}>
                      <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
                        {t("Csv")}
                      </Button>
                    </CSVLink>
                  </Menu.Item>
                  <Menu.Item>
                    <Button type="link" icon={<FileExcelOutlined />} onClick={exportXlsx}>
                      {t("Excel")}
                    </Button>
                  </Menu.Item>
                </Menu>
              }>
              <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
                {t("Download")}
                <DownOutlined />
              </Button>
            </Dropdown>

            <Button type="link" icon={<ReloadOutlined />} onClick={() => loadType()}>
              {t("Reload")}
            </Button>
            <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
              {t("Filter")}
            </Button>
          </div>
          {type == "U" && (
            <Table dataSource={data} loading={loading} style={{ marginTop: 5 }} rowKey="id" scroll={{ x: true }}>
              <Table.Column title={t("Cession ID")} dataIndex="id" />
              <Table.Column title={t("Treaty ID")} dataIndex="contractId" render={(v, r) => <a href={"#/contract/" + v}>{v}</a>} />
              <Table.Column title={t("Status")} dataIndex="err" render={(v, r) => <div>
                {v?<Tooltip title={t("Invalid")}> <Badge status={"error"}/></Tooltip>:<Tooltip title={t("Valid")}><Badge status={"success"}/></Tooltip> }
                {r.coSumInsured>0&&<Tooltip title={t("Coinsurance")}> <LegacyIcon type={"pie-chart"} /></Tooltip>}
              </div> } />
              <Table.Column title={t("Lob")} dataIndex="LoB" />
              <Table.Column title={t("Policy")} dataIndex="policyCode" />
              <Table.Column title={t("Insured")} dataIndex="insuredName" />
              <Table.Column title={t("Coverage")} dataIndex="coverageId" render={(v, r) => <Tooltip title={r.cover}>{v}</Tooltip>} />
              <Table.Column title={t("Date")} dataIndex="start" render={(v) => formatDateShort(v)} />
              <Table.Column title={t("Line")} dataIndex="lineId" />
              <Table.Column title={t("Type")} dataIndex="premiumType" />

              <Table.Column title={t("Sum Insured")} dataIndex="sumInsured" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Premium Sum")} dataIndex="premium" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Msg")} dataIndex="msg" />

              <Table.Column title={t("Cedant Sum Insured")} dataIndex="sumInsuredCedant" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Cedant Premium")} dataIndex="premiumCedant" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Commission")} dataIndex="comissionCedant" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Tax")} dataIndex="participantTax" render={(v, r) => formatMoney(v, r.currency)} />

              <Table.Column title={t("Re Sum Insured")} dataIndex="sumInsuredRe" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Re Premium")} dataIndex="premiumRe" render={(v, r) => formatMoney(v, r.currency)} />

              <Table.Column
                title={t("Action")}
                key="action"
                fixed="right"
                render={(v, r) => <Button type="link" icon={<FolderOpenOutlined />} onClick={() => setEditRecord(r)} />}
              />
            </Table>
          )}
          {type == "C" && (
            <Table dataSource={data} loading={loading} style={{ marginTop: 5 }} rowKey="id" scroll={{ x: true }}>
              <Table.Column title={t("ID")} dataIndex="id" />
              <Table.Column title={t("Cession ID")} dataIndex={["Cession","id"]} />
              <Table.Column title={t("Treaty ID")} dataIndex={["Cession","contractId"]} render={(v, r) => <a href={"#/contract/" + v}>{v}</a>} />
              <Table.Column title={t("Lob")} dataIndex={["Cession","LoB"]} />
              <Table.Column title={t("Policy")} dataIndex={["Cession","policyCode"]} />
              <Table.Column title={t("Insured")} dataIndex="insuredName" />
              <Table.Column title={t("Coverage")} dataIndex={["Cession","coverageId"]} render={(v, r) => <Tooltip title={r.coverageName}>{v}</Tooltip>} />
              <Table.Column title={t("Deductible")} dataIndex="coverageDeductible" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Claim")} dataIndex={["Payout","claimId"]} render={(v, r) => <a href={"#/healthclaim/" + v}>{v}</a>} />
              <Table.Column title={t("Occurrence")} dataIndex="claimOccurrence" render={(v) => <nobr>{formatDateShort(v)}</nobr>} />
              <Table.Column title={t("Notification")} dataIndex="claimNotification" render={(v) => <nobr>{formatDateShort(v)}</nobr>} />
              <Table.Column title={t("Line")} dataIndex={["Cession","lineId"]} />
              <Table.Column title={t("Event Reason")} dataIndex="eventReason" />
              <Table.Column title={t("Insured Event")} dataIndex="insuredEvent" />

              <Table.Column title={t("Reserved")} dataIndex="reserve" render={(v, r) => <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />
              <Table.Column title={t("Loss")} dataIndex="loss" render={(v, r) => <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />

              <Table.Column title={t("Retained Reserve")} dataIndex="retainedReserve" render={(v, r) =>  <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />
              <Table.Column title={t("Retained Loss")} dataIndex="retainedLoss" render={(v, r) =>  <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />

              <Table.Column title={t("Ceded Reserve")} dataIndex="cededReserve" render={(v, r) =>  <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />
              <Table.Column title={t("Ceded Loss")} dataIndex="cededLoss" render={(v, r) =>  <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />

              <Table.Column
                  title={t("Action")}
                  key="action"
                  fixed="right"
                  render={(v, r) => <Button type="link" icon={<FolderOpenOutlined />} onClick={() => setClaimEditRecord(r)} />}
              />
            </Table>
          )}
        </div>
      )}
      {editRecord !== null && (
        <div>
          <Button type="link" icon={<LeftOutlined />} onClick={() => setEditRecord(null)} style={{ float: "left" }}>
            {t("Back")}
          </Button>
          <CessionEdit value={editRecord} />
        </div>
      )}
      {claimEditRecord !== null && (
          <div>
            <Button type="link" icon={<LeftOutlined />} onClick={() => setClaimEditRecord(null)} style={{ float: "left" }}>
              {t("Back")}
            </Button>
            <ClaimCessionEdit value={claimEditRecord} onEdit={()=>{setClaimEditRecord(null);loadType()}} />
          </div>
      )}
      {type==="U"&&<CessionsFilter visible={filterVisible} onSearch={onFilterSearch} onClose={() => setFilterVisible(false)} contractId={props.contractId} />}
      {type === "C"&&<ClaimCessionsFilter visible={filterVisible} onSearch={onFilterSearch} onClose={() => setFilterVisible(false)} contractId={props.contractId} />}
    </div>
  );
};

export default ContractCessions;
