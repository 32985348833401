import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";

const BankSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => load(), []);

  const load = () => {
    getCache("RepoBank").then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onChange = (v) => {
    props.onChange(v);
  };
  const value = props.value;
  if (props.value == 0) props.onChange(undefined);
  return (
    <div>
      <Select style={{ width: "100%" }} placeholder={t("Please select")} onChange={onChange} value={value}>
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default BankSelect;
