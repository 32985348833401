import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, message, Table} from "antd";
import {exe} from "../../Lib/Dal";
import CashFundsForm from "./CashFundsForm";
import {useTranslation} from "react-i18next";
import {formatDate, formatMoney} from "../../Lib/Helpers";

const CashFunds = (props) => {
    const [t]=useTranslation();
    const [data,setData] = useState([])
    const [loading,setLoading]=useState(false);
    const [selected,setSelected]=useState(null);
    
    useEffect(()=>{
        load();
    },[]);
    
    const load=()=> {
        setLoading(true);
        exe("RepoCashFund",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg)
        });
    }
    const onEdit=(r)=> {
        setSelected(r);
    }
    const onAdd=()=> {
        setSelected({id:0});
    }
    const onSave=r=>{
        setLoading(true);
        exe("RepoCashFund",{operation:r.id?"UPDATE":"ADD",entity:r}).then(r=>{
            setLoading(false);
            if(r.ok){
                setSelected(null);
                load();
            }else message.error(r.msg)
        });
    }
    return (
        <div>
            {!selected&&<div>
                <Button type={"link"} icon={<LegacyIcon type={"plus"} />} onClick={()=>onAdd()}>{t("Add")}</Button>
                <Table loading={loading} dataSource={data} rowKey={"id"}>
                    <Table.Column title={"User"} dataIndex={"user"} />
                    <Table.Column title={"Currency"} dataIndex={"currency"} />
                    <Table.Column title={"Balance"} dataIndex={"balance"} render={(v,r)=>formatMoney(v,r.currency)} />
                    <Table.Column title={"Date updated"} dataIndex={"updated"} render={v=>formatDate(v)} />
                    <Table.Column title={"Actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>onEdit(r)} /> </div>} />
                </Table>
            </div>}
                {selected&&<CashFundsForm selected={selected} onCancel={()=>setSelected(null)} onOk={onSave} />}
        </div>
    );
};

export default CashFunds;