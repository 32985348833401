import { message, Modal, Transfer } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const GroupPermissions = (props) => {
  const { t } = useTranslation();
  const [targetKeys, setTarketKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTarketKeys(nextTargetKeys);
  };

  useEffect(() => load(), [props.record]);

  const load = () => {
    if (props.record && props.record.jPermissions) setTarketKeys(JSON.parse(props.record.jPermissions));
    else setTarketKeys([]);
  };

  const save = () => {
    setLoading(true);
    const jPermissions = JSON.stringify(targetKeys);
    exe("SetGroupPermissions", { groupId: props.record.id, jPermissions: jPermissions }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Permissions updated"));
        props.onUpdate(r.outData);
      } else message.error(r.msg);
    });
  };
  const policyTabs=[
    { tab: "tab1", name: t("General") },
    { tab: "tab2", name: t("Conditions") },
    { tab: "tab3", name: t("Coverages") },
    { tab: "tab4", name: t("Projection") },
    { tab: "tab5", name: t("Beneficiaries") },
    { tab: "tab6", name: t("Billing") },
    { tab: "tab7", name: t("Surcharges and Discounts") },
    { tab: "tab8", name: t("Exclusions") },
    { tab: "tab9", name: t("Documents") },
    { tab: "tab10", name: t("Requirements") },
    { tab: "tab11", name: t("Accounts") },
    { tab: "tab12", name: t("Changes") },
    { tab: "tab13", name: t("Incoming Payments") },
    { tab: "tab14", name: t("More Info") },
    { tab: "tab15", name: t("Risk Analysis") },
    { tab: "tab16", name: t("Anniversaries") },
    { tab: "tab17", name: t("Certificates") },
    { tab: "tab18", name: t("Policy Tasks") },
    { tab: "tab19", name: t("Policy Clauses") },
    { tab: "tab20", name: t("Objects") },
    { tab: "tab21", name: t("Coinsurance") }
  ];

  const policyTabLinks = policyTabs.map(tab => ({
    link: `/lifepolicy/${tab.tab}`,
    parent: t("Policy"),
    texto: t("tab")+" "+tab.name
  }));
  const restrictedPolicyTabLinks = policyTabs.map(tab => ({
    link: `/lifepolicy/${tab.tab}restricted`,
    parent: t("Restricted Policy"),
    texto: t("tab")+" "+tab.name
  })); 
  const contactTabs = [
    { tab: "1", name: t("Main") },
    { tab: "2", name: t("Contact Info") },
    { tab: "details", name: t("More Info") },
    { tab: "other", name: t("Custom") },
    { tab: "aml", name: t("AML") },
    { tab: "changes", name: t("Changes") },
    { tab: "issues", name: t("Issues") },
    { tab: "communications", name: t("Communications") },
    { tab: "documents", name: t("Documents") },
    { tab: "4", name: t("Policies") },
    { tab: "9", name: t("Billing") },
    { tab: "10", name: t("Payments") },
    { tab: "5", name: t("Relationships") },
    { tab: "7", name: t("Claims") },
    { tab: "11", name: t("Branches") },
    { tab: "8", name: t("Pending Requirements") },
    { tab: "6", name: t("Medical Records") },
    { tab: "3", name: t("SelfService") },
    { tab: "accounts", name: t("Accounts") }
  ];
  const contactTabLinks = contactTabs.map(tab => ({
    link: `/contact/tab${tab.tab}`,
    parent: t("Contact"),
    texto: t("tab")+" " +tab.name
  }));
  const restrictedContactTabLinks = contactTabs.map(tab => ({
    link: `/contact/tab${tab.tab}restricted`,
    parent: t("Restricted Contact"),
    texto: t("tab")+" " +tab.name
  }));
  const claimTabs = [
    { tab: "notification", name: t("Notification") },
    { tab: "requirements", name: t("Requirements") },
    { tab: "custom", name: t("Custom") },
    { tab: "procedures", name: t("Procedures") },
    { tab: "lifePolicy", name: t("Policy") },
    { tab: "affectedCoverages", name: t("Coverages") },
    { tab: "reserves", name: t("Reserves") },
    { tab: "decision", name: t("Decision") },
    { tab: "payment", name: t("Payment") },
    { tab: "accounts", name: t("Accounts") },
    { tab: "salvage", name: t("Salvage") },
    { tab: "closure", name: t("Closure") },
    { tab: "files", name: t("Files") },
    { tab: "report", name: t("Report") },
    { tab: "timeline", name: t("Timeline") },
    { tab: "fraudAnalaysis", name: t("Fraud Analysis") },
    { tab: "tasks", name: t("Tasks") },
    { tab: "relationships", name: t("Related") },
    { tab: "audits", name: t("Audit") },
    { tab: "subClaims", name: t("Sub-Claims") }
  ];
  const claimTabLinks = claimTabs.map(tab => ({
    link: `/claim/${tab.tab}`,
    parent: t("Claim"),
    texto: t("tab")+" "+tab.name
  }));
  const restrictedClaimTabLinks = claimTabs.map(tab => ({
    link: `/claim/${tab.tab}restricted`,
    parent: t("Restricted Claim"),
    texto: t("tab")+" "+tab.name
  }));
  const tabLinks = [
    {link:"/cashier/tabworkspaces",parent:t("Money In"),texto:t("Cashier tab workspaces")},
    {link:"/cashier/tabpremiums",parent:t("Money In"),texto:t("Cashier tab premiums")},
    {link:"/cashier/tabother",parent:t("Money In"),texto:t("Cashier tab other")}, 
    {link:"/cashier/tabincome",parent:t("Money In"),texto:t("Cashier tab income")},
    {link:"/cashier/tabtransfers",parent:t("Money In"),texto:t("Cashier tab movements")},
    {link:"/cashier/taballocation",parent:t("Money In"),texto:t("Cashier tab allocation")},
    {link:"/cashier/tabbalance",parent:t("Money In"),texto:t("Cashier tab balance")},
    ...policyTabLinks,
    ...contactTabLinks,
    ...claimTabLinks,
    ...restrictedPolicyTabLinks,
    ...restrictedContactTabLinks,
    ...restrictedClaimTabLinks
  ];
  const buildItems = () => {
    const items = [];
    props.allMenuItems
      .filter((p) => p.children)
      .filter((item, index, array) => array.findIndex((t) => t.texto == item.texto) == index)
      .forEach((parent) => {
        parent.children.forEach((child) => {
          items.push({ ...child, parent: parent.texto,disabled:false });
        });
      });
    return items
        .filter((item, index, array) => array.findIndex((t) => t.link == item.link) == index)
        .concat(tabLinks)
        .sort((a, b) => a.parent.localeCompare(b.parent) || a.texto.localeCompare(b.texto));
  };

  return (
    <div>
      <Modal title={t("Screen Permissions")} visible={props.visible} onCancel={props.onCancel} onOk={save} width="800px" okButtonProps={{ loading: loading }}>
        <Transfer
          dataSource={buildItems()}
          titles={[t("Allow"), t("Deny")]}
          rowKey={(record) => record.link}
          onChange={onChange}
          targetKeys={targetKeys}
          render={(item) => `${item.parent}.${item.texto}`}
          listStyle={{
            width: 300,
            height: 300,
          }}
        />
      </Modal>
    </div>
  );
};

export default GroupPermissions;
