export default {
  nombre: process.env.REACT_APP_NAME,
  ssApiUrl: localStorage.sis11api || process.env.REACT_APP_APIURL,
  ssAuthUrl: process.env.REACT_APP_AUTHURL,
  version: process.env.REACT_APP_VERSION,
  ambiente: process.env.NODE_ENV,
  docServerUrl: process.env.REACT_APP_DOCURL,
  reportsUrl: process.env.REACT_APP_REPORTSURL,

  /*    ssApiUrl:"//localhost:61507/api",
    ssAuthUrl:"//localhost:61507/Token" */
};
