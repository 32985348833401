import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, message, Spin } from "antd";
import React, {useEffect, useState,} from "react";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import Money from "../Shared/Money";
import {useTranslation} from "react-i18next";
import {computeFormula, getConfigProfile, round2} from "../../Lib/Helpers";
import {exe, safeGet, safeGetRaw} from "../../Lib/Dal";
import Percentage from "../Shared/Percentage";

const FacultativeEditorForm=props=>{
    const [loading,setLoading]=useState(false);
    const [contactRoles, setContactRoles] = useState([]);
    const [facultativeTaxEditable, setFacultativeTaxEditable] = useState(true);
    const [facultativeTaxRule, setFacultativeTaxRule] = useState();
    const [selectedContact,setSelectedContact]=useState(undefined);
    const [t]=useTranslation();

    useEffect(() => {
        getConfigProfile().then((profile) => {
            setContactRoles(safeGet(["Reinsurance", "contactRoles"], profile, []));
            setFacultativeTaxEditable(safeGetRaw(["Reinsurance", "facultativeTaxEditable"], profile, true));
            setFacultativeTaxRule(safeGetRaw(["Reinsurance", "facultativeTaxRule"], profile, null));
        });
    }, []);
    
    useEffect(()=>{
        if(props.record){
            //setting percentages
            props.record.insuredSumUncoveredP=props.record.insuredSum===0?0:round2(props.record.insuredSumUncovered/props.record.insuredSumCoverage*100);
            props.record.insuredSumP=props.record.insuredSum===0?0:round2(props.record.insuredSum/props.record.insuredSumUncovered*100);
            props.record.commissionP=props.record.premium===0?0:round2(props.record.commission/props.record.premium*100);
            props.record.taxP=props.record.premium===0?0:round2(props.record.tax/props.record.premium*100);
            
            props.form.setFieldsValue(props.record);
        }
    },[props.record])
    
    useEffect(()=>{
        //need both dependencies
        if(selectedContact && facultativeTaxRule){
            //executing expression
            const taxP=computeFormula(facultativeTaxRule, {con:selectedContact});
            props.form.setFieldsValue({taxP:taxP});
            onChangeTaxP(taxP)
        }
        
    },[selectedContact, facultativeTaxRule])
    
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            values.dirty=true;
            props.onSave(values);
        })
    }
    const onSelectContact=(contact)=>{
        setSelectedContact(contact);
        props.form.setFieldsValue({reinsurer:contact?contact.FullName:undefined});
    }
    const onChangeInsuredSumP=v=>{
        //calculating ceded %
        const insuredSum=values.insuredSumUncovered*v/100;
        const cededP=insuredSum/values.insuredSumCoverage*100;
        const premium=values.premiumCoverage*cededP/100;
        //calculating commission with new premium
        const commission=values.commissionP*premium/100;
        //calculating tax with new premium
        const tax=values.taxP*premium/100;
        
        props.form.setFieldsValue({
            insuredSum:round2(insuredSum),
            premium:round2(premium),
            commission:round2(commission),
            tax:round2(tax),
        });

    }
    const onChangeCededPremium=premium=> {
        //calculating commission with new premium
        const commission=values.commissionP*premium/100;
        //calculating tax with new premium
        const tax=values.taxP*premium/100;

        props.form.setFieldsValue({
            commission:round2(commission),
            tax:round2(tax),
        });
    }
    const onChangeCommissionP=v=> {
        const commission=values.premium*v/100;
        props.form.setFieldsValue({
            commission:round2(commission),
        });
    }
    const onChangeTaxP=v=> {
        const tax=values.premium*v/100;
        props.form.setFieldsValue({
            tax:round2(tax),
        });
    }
    const field=props.form.getFieldDecorator;
    const record=props.record;
    const values=props.form.getFieldsValue();
    
    return (
        <div>
            {field("cessionId")}
            {field("cessionPartId")}
            {field("coverage")}
            {field("coverageCode")}
            {field("movementType")}
            {field("reinsurer")}
            {field("insuredSumUncovered")}
            {field("premiumCoverage")}
            {field("err")}
            {field("currency")}
            {field("contractId")}
            {field("dirty")}
            {field("split")}
            
            <Button type={"link"} icon={<LegacyIcon type={"left"} />} onClick={()=>props.onCancel()} >{t("Back")}</Button>
            <Button type={"link"} icon={<LegacyIcon type={"save"} />} onClick={onSave}>{t("Save")}</Button>
            <Form.Item label={t("Reinsurer")}>
                {field("contactId",{rules:[{required:true}]})(
                    <SimpleContactSelect forceSelect onSelectContact={onSelectContact} filter={contactRoles.length == 0 ? undefined : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `}
                />)}
            </Form.Item>
            <div style={{display:"flex"}}>
            <Form.Item label={t("Coverage Insured Sum")} style={{flex:1,marginRight:5}}>
                {field("insuredSumCoverage",{rules:[{required:true}]})(<Money currency={record.currency} disabled />)}
            </Form.Item>
            <Form.Item label={t("Uncovered Insured Sum")} style={{flex:1,marginRight:5}}>
                {field("insuredSumUncovered",{rules:[{required:true}]})(<Money currency={record.currency} disabled />)}
            </Form.Item>
            <Form.Item label={t("Facultative")}>
                {field("insuredSumUncoveredP",{rules:[{required:false}]})(<Percentage disabled />)}
            </Form.Item>

            </div>
            <Form.Item label={t("Coverage Premium")}>
                {field("premiumCoverage",{rules:[{required:true}]})(<Money currency={record.currency} disabled />)}
            </Form.Item>
            <div style={{display:"flex"}}>
                <Form.Item label={t("Ceded Insured Sum")} style={{flex:1,marginRight:5}}>
                    {field("insuredSum",{rules:[{required:true}]})(<Money currency={record.currency} min={0} max={values.insuredSumUncovered} />)}
                </Form.Item> 
                <Form.Item label={t("Split")}>
                    {field("insuredSumP",{rules:[{required:false}]})(<Percentage onChange={onChangeInsuredSumP} />)}
                </Form.Item>     
               
            </div>
            <Form.Item label={t("Ceded Premium")}>
                {field("premium",{rules:[{required:true}]})(<Money currency={record.currency} onChange={onChangeCededPremium} min={0} max={values.premiumCoverage}  />)}
            </Form.Item>
            <div style={{display:"flex"}}>
                <Form.Item label={t("Commission")} style={{flex:1,marginRight:5}}>
                    {field("commission",{rules:[{required:true}]})(<Money currency={record.currency}  />)}
                </Form.Item>
                <Form.Item label={t("Rate")}>
                    {field("commissionP",{rules:[{required:false}]})(<Percentage onChange={onChangeCommissionP} />)}
                </Form.Item>
                
            </div>
            <div style={{display:"flex"}}>
                <Form.Item label={t("Tax")} style={{flex:1,marginRight:5}}>
                    {field("tax",{rules:[{required:true}]})(<Money currency={record.currency} disabled={!facultativeTaxEditable} />)}
                </Form.Item>
                <Form.Item label={t("Rate")}>
                    {field("taxP",{rules:[{required:false}]})(<Percentage onChange={onChangeTaxP} disabled={!facultativeTaxEditable} />)}
                </Form.Item>
                
            </div>
            <Form.Item label={t("Net Reinsurance Premium")}>
                {field("netReinsurancePremium",{rules:[{required:false}]})(<Money currency={record.currency} disabled />)}
            </Form.Item>
            <Form.Item label={t("Broker")}>
                {field("brokerId", { rules: [{ required: false }] })(
                    <SimpleContactSelect filter={
                                             contactRoles.length === 0
                                                 ? undefined
                                                 : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                                         }
                    />
                )}
            </Form.Item>
            {loading&&<Spin />}
           

        </div>
    );}

export default Form.create({onValuesChange:(props, changedValues, allValues)=>{
    if(changedValues.premium||changedValues.commission||changedValues.tax) {
        //setting net reinsurance premium
        const netReinsurancePremium = allValues.premium - allValues.commission - allValues.tax;
        props.form.setFieldsValue({
            netReinsurancePremium: round2(netReinsurancePremium),
        });
    }
    }})(FacultativeEditorForm);