import React, { useState } from "react";
import {
  CheckOutlined,
  ClockCircleOutlined,
  EditOutlined,
  PaperClipOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Table, Button, Tooltip, message, Drawer, Radio, Modal } from "antd";
import moment from "moment";
import ReqirementsForm from "./ReqirementsForm";
import { exe } from "../../Lib/Dal";
import RequirementDetail from "./RequirementDetail";
import FileUpload from "./FileUpload";
import { useTranslation } from "react-i18next";
const { Column } = Table;

const Requirements = (props) => {
  const [t] = useTranslation();
  const [formVisible, setFormVisible] = useState(false);
  const [formRecord, setFormRecord] = useState(null);
  const [detailVisible, setDetailVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUploadVisible, setFileUploadVisible] = useState(false);
  const [uploadedRequirement, setUploadedRequirement] = useState(null);

  const data=props.value||[];
  // let data = props.config ? props.config.Requirements : []; //product config data
  // if (props.value && props.value.length > 0) {
  //   //DB or computed data
  //   data = props.value;
  // }
  const updateRecord = (record) => {
    exe("RepoLifeRequirement", { operation: "UPDATE", entity: record }).then((r) => {
      if (r.ok) {
        message.success(t("Record updated"));
      }
    });
  };
  const requestDoc = (record) => {
    const updatedrecord = props.value.find((p) => p.code === record.code);
    updatedrecord.request = true;
    updatedrecord.requestDate = new Date();

    props.onChange(props.value);
    updateRecord(updatedrecord);
  };
  const receiveDoc = (record) => {
    const updatedrecord = props.value.find((p) => p.code === record.code);
    updatedrecord.response = true;
    updatedrecord.responseDate = new Date();

    props.onChange(props.value);
    updateRecord(updatedrecord);
  };
  const onOpenRequirement = (record) => {
    if (record.type == "AMLCASE") {
      window.location.hash = "#/contact/" + props.policy.holderId + "/tabaml";
      return;
    }
    if (record.type == "PROCESS") {
      window.location.hash = "#/activity/" + record.processId;
      return;
    }
    setFormVisible(true);
    record.lifePolicyId = props.policyId; //because @ pre quote, reqs are extractrd from product config and whe need policy if for updates
    setFormRecord(record);
  };
  const onCloseRequirement = (record) => {
    setFormVisible(false);
    if (record) {
      //if record updated, update grid datasource
      //const updatedData = [...props.value.filter((p) => p.code !== record.code), record];
      //props.onChange(updatedData);
      const updatedrecord = props.value.find((p) => p.code === record.code);
      updatedrecord.response = true;
      updatedrecord.responseDate = new Date();
      updatedrecord.jFormValues = record.jFormValues;

      props.onChange(props.value);
    }
  };
  const onNewRequirement = (record) => {
    props.onChange([...props.value, record]);
    setDetailVisible(false);
  };

  const onRaiseRequirements = () => {
    setLoading(true);
    exe("RaiseRequirements", { policyId: props.policyId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        props.onChange(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };

  const onCheckOption = (value, record) => {
    console.log(value, record);
    const updatedrecord = props.value.find((p) => p.code === record.code);
    updatedrecord.jFormValues = value;
    if (value == "COMPLETE") {
      updatedrecord.request = true;
      updatedrecord.requestDate = new Date();
      updatedrecord.response = true;
      updatedrecord.responseDate = new Date();
    } else {
      updatedrecord.request = true;
      updatedrecord.requestDate = new Date();
      updatedrecord.response = false;
      updatedrecord.responseDate = null;
    }
    props.onChange(props.value);
    updateRecord(updatedrecord);
  };

  const onUploadPdfForm = (record) => {
    setFileUploadVisible(true);
    setUploadedRequirement(record);
  };
  const onPdfFormUpload = (files) => {
    console.log(files, uploadedRequirement);
    setFileUploadVisible(false);
    uploadedRequirement.response = true;
    uploadedRequirement.responseDate = new Date();
    uploadedRequirement.responseLink = files[0].url;
    updateRecord(uploadedRequirement);
    files[0].association = uploadedRequirement.code;
    props.onAddPolicyFile && props.onAddPolicyFile(files);
  };
  return (
    <div>
      <Button type="link" icon={<PlusOutlined />} onClick={() => setDetailVisible(true)}>
        {t("Add")}
      </Button>
      <Button type="link" icon={<PaperClipOutlined />} onClick={() => onRaiseRequirements()} loading={loading}>
        {t("Raise Requirements Now")}
      </Button>
      <Table dataSource={data} scroll={{ x: true }} loading={loading} rowKey="id">
        <Column title={t("Id")} dataIndex="id" />
        <Column title={t("Requirement")} dataIndex="name" />
        <Column title={t("Code")} dataIndex="code" />
        <Column title={t("Condition")} dataIndex="rule" />
        <Column
          title={t("Status")}
          dataIndex="request"
          render={(text, record) => {
            if (record.type == "CHECK")
              return (
                <span>
                  <Radio.Group value={record.jFormValues || undefined} onChange={(v) => onCheckOption(v.target.value, record)} disabled={record.readOnly}>
                    <Radio value="COMPLETE" key="ck1">
                      {t("Complete")}
                    </Radio>
                    <Radio value="INCOMPLETE" key="ck2">
                      {t("Incomplete")}
                    </Radio>
                    <Radio value="MISSING" key="ck3">
                      {t("Missing")}
                    </Radio>
                  </Radio.Group>
                </span>
              );

            if (record.response)
              return (
                <span>
                  <Tooltip title={t("Received") + " " + moment(record.responseDate).fromNow()}>
                    <CheckOutlined style={{ color: "green" }} />
                    <CheckOutlined style={{ color: "green" }} />
                  </Tooltip>
                </span>
              );
            if (record.request)
              return (
                <Tooltip title={t("Requested") + " " + moment(record.requestDate).fromNow()}>
                  <CheckOutlined style={{ color: "green" }} />

                  {record.type == "PROCESS" || record.type == "AMLCASE" ? (
                    <ClockCircleOutlined style={{ marginLeft: 3 }} />
                  ) : (
                    <Button type="link" onClick={() => receiveDoc(record)}>
                      {t("Receive")}
                    </Button>
                  )}
                </Tooltip>
              );
            if (record.type == "PDFFORM")
              return (
                <Button type="link" onClick={() => onUploadPdfForm(record)} icon={<UploadOutlined />} disabled={record.readOnly}>
                  {t("Upload")}
                </Button>
              );
            if (record.type !== "FORM")
              return (
                <Button type="link" onClick={() => requestDoc(record)} disabled={record.readOnly}>
                  {t("Request")}
                </Button>
              );
            else return null;
          }}
        />
        <Column title={t("Block Level")} dataIndex="blockLevel" />
        <Column title={t("Type")} dataIndex="type" />
        <Column title={t("Comments")} dataIndex="comments" />
        <Column
          title={t("Actions")}
          fixed="right"
          dataIndex="code"
          key="actions"
          render={(v, r) => (
            <div>
              <Button type="link" onClick={() => onOpenRequirement(r)} disabled={r.type !== "FORM" && r.type !== "PROCESS" && r.type !== "AMLCASE"}>
                {t("Open")}
              </Button>
              {r.type == "PDFFORM" && r.response && <Button type="link" onClick={() => onUploadPdfForm(r)} icon={<EditOutlined />} disabled={r.readOnly}></Button>}
            </div>
          )}
        />
      </Table>
      <ReqirementsForm visible={formVisible} formRecord={formRecord} onClose={onCloseRequirement} />
      <Drawer width={500} title={t("Document Requirement")} visible={detailVisible} onClose={() => setDetailVisible(false)}>
        <RequirementDetail onOk={(record) => onNewRequirement(record)} policyId={props.policyId} />
      </Drawer>
      <Modal visible={fileUploadVisible} title={t("File Upload")} onOk={() => setFileUploadVisible(false)} onCancel={() => setFileUploadVisible(false)}>
        <FileUpload onChange={(files) => onPdfFormUpload(files)} />
      </Modal>
    </div>
  );
};

export default Requirements;
