import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Alert, Input, InputNumber, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {exe, safeGetRaw} from "../../Lib/Dal";
import Coverages from "./Coverages";
import CoverageSelect from "./CoverageSelect";
import CoverageCombo from "./CoverageCombo";
import DatePickerW from "../Shared/DatePickerW";
import moment from "moment";

const SurchargeEdit = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.value) props.form.setFieldsValue(props.value);
  }, [props.value]);

  const field = props.form.getFieldDecorator;
  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("RepoLifeSurcharge", { operation: "UPDATE", entity: values }).then((r) => {
        setLoading(false);
        props.onOk(r.outData[0]);
      });
    });
  };
  const onChangeDuration=v=>{
    //changing the end date when duration changes
    this.props.form.setFieldsValue({end:moment(values.start).clone().add(v, 'years')});

  }
  const coverages=safeGetRaw(["Coverages"],props.config,[]);
  const values = props.form.getFieldsValue();
  return (
    <div>
      {field("id")}
      {field("lifePolicyId")}
      {field("mandatory")}
      <Modal title={t("Surcharge Details")} visible={props.visible} onOk={onOk} onCancel={props.onCancel}>
        <Spin spinning={loading}>
          <Form.Item label={t("Code")}>{field("code", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          <Form.Item label={t("Description")}>{field("description")(<Input />)}</Form.Item>
          <Form.Item label={t("Rule")}>{field("rule")(<Input />)}</Form.Item>
          {values.rule && values.value > 0 && (
            <Alert message={t("If a rule is defined, the explicit amount will be overwritten after quoting")} type="warning" showIcon />
          )}
          <Form.Item label={t("Amount")}>{field("value")(<InputNumber min={0} />)}</Form.Item>
          <Form.Item label={t("Coverage")} style={{width:"100%"}}>{field("coverageCode")(<CoverageCombo coverages={coverages} style={{width:"100%"}} />)}</Form.Item>
          <Form.Item label={t("Duration")}>{field("duration",{initialValue:props.policy.duration})(<InputNumber min={0} onChange={onChangeDuration} />)}</Form.Item>
          <Form.Item label={t("Start")}>{field("start", { 
            initialValue: props.policy.start?moment(props.policy.start):moment(), 
            rules: [
              { 
                validator: (rule, value, callback) => { 
                  if (value && value.isBefore(props.policy.start, 'day')) { 
                    callback(t('Selected date must be after policy start date')); 
                  } else { 
                    callback(); 
                  } 
                }
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value.isAfter(values.end, 'day')) {
                    callback(t('Selected date must be before end date'));
                  } else {
                    callback();
                  }
                }
              }
            ] 
          })(<DatePickerW min={moment()} />)}</Form.Item>
          <Form.Item label={t("End")}>{field("end", { initialValue: props.policy.end?props.policy.end:moment(values.start).clone().add(values.duration, 'years')})(<DatePickerW disabled />)}</Form.Item>

        </Spin>
      </Modal>
    </div>
  );
};

export default Form.create()(SurchargeEdit);
