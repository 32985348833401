import React from "react";
import DefaultPage from "../Shared/DefaultPage";
import { Table } from "antd";
import { useTranslation } from "react-i18next";

const TransactionLog = (props) => {
  const [t, i18n] = useTranslation();

  return (
    <div>
      <DefaultPage icon="book" title={t("Accounting Transaction Log")}>
        <Table dataSource={[]} />
      </DefaultPage>
    </div>
  );
};

export default TransactionLog;
