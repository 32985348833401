import React from 'react';
import Viewer from "bpmn-js/lib/Viewer";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import h337 from 'heatmap.js/build/heatmap.js';

const xml = `<?xml version="1.0" encoding="UTF-8"?>
        <bpmn2:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn2="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" xmlns:di="http://www.omg.org/spec/DD/20100524/DI" xsi:schemaLocation="http://www.omg.org/spec/BPMN/20100524/MODEL BPMN20.xsd" id="sample-diagram" targetNamespace="http://bpmn.io/schema/bpmn">
          <bpmn2:process id="Process_1" isExecutable="false">
            <bpmn2:startEvent id="StartEvent_1"/>
          </bpmn2:process>
          <bpmndi:BPMNDiagram id="BPMNDiagram_1">
            <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
              <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
                <dc:Bounds height="36.0" width="36.0" x="412.0" y="240.0"/>
              </bpmndi:BPMNShape>
            </bpmndi:BPMNPlane>
          </bpmndi:BPMNDiagram>
        </bpmn2:definitions>`;

class VisorCalor extends React.Component {
  componentDidMount() {
    const viewer = new Viewer({ container: "#canvas" });
    this.viewer = viewer;
    var me = this;

    viewer.importXML(xml, function(err) {
      if (err) {
        console.log(err);
      } else {
        var canvas = viewer.get("canvas");
        canvas.zoom("fit-viewport");

        var a = document.getElementsByClassName("bjs-powered-by")[0];
        a.parentNode.removeChild(a);
      }
    });
  }
  componentDidUpdate(){
      this.stats=this.props.stats;
      this.loadXml(this.props.xml);
  }
  loadXml = xml => {
    const me = this;
    this.viewer.importXML(xml, function(err) {
      if (err) {
        console.log(err);
      } else {
        var canvas = me.viewer.get("canvas");
        canvas.zoom("fit-viewport");

        //var a=document.getElementsByClassName("bjs-powered-by")[0];
        //a.parentNode.removeChild(a);

        me.loadHeatMap();
      }
    });
  };
  loadHeatMap = () => {
    if (!this.heatmapInstance) {
      this.heatmapInstance = h337.create({
        container: document.querySelector("#canvas")
      });
      //demo data points
      const me=this;
      document.querySelector('#canvas').onclick = function(ev) {
        me.heatmapInstance.addData({
          x: ev.layerX,
          y: ev.layerY,
          value: 1
        });
    }
    }

    var points = [];
    var max = 5;

    //offsets por menus laterales y anchura de shape
    const offSetX = -260 + 56;
    const offSetY = -238 + 46;

    //obteniendo estadisitcas de tiempo en cada task
    const tasks = document.querySelectorAll('[data-element-id*="Task"]');
    console.log("tasks", tasks);
    tasks.forEach(p => {
      const stat = this.stats.find(
        s =>
          s.procesoId == this.props.selected &&
          s.estadoId == p.getAttribute("data-element-id")
      );
      if (stat) {
        const coord = p.getBoundingClientRect();
        points.push({
          x: coord.x + offSetX,
          y: coord.y + offSetY,
          value: stat.tiempo
        });
      }
    });

    //cantidad de instancias
    // const shapes=document.querySelectorAll('[data-element-id]');
    // shapes.forEach(p=>{
    //   if(p.getAttribute('data-element-id').indexOf('Task')==-1){
    //     const qty=this.stats.find(s=>s.procesoId==this.state.selected[0]&&s.estadoId==p.getAttribute('data-element-id'));
    //     if(qty){
    //       const coord=p.getBoundingClientRect();
    //       points.push({x:coord.x-234+25,y:coord.y-156+25,value:qty.cantidad});
    //     }

    //   }
    // });

    var data = {
      max: max,
      data: points
    };
    this.heatmapInstance.setData(data);
  };

  render() {
    return (
      <div
        id="canvas"
        style={{
          //   width: "100%",
          height: "300px"
          //   overflow: "hidden",
          //   position: "fixed"
        }}
      />
    );
  }
}
export default VisorCalor;
