import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined, FilterOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Button,
  Drawer,
  Switch,
  DatePicker,
  InputNumber,
  Select,
  Tag,
  Divider,
} from "antd";
import { exe } from "../../Lib/Dal";
import { formatDate, formatMoney } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";

const ClaimReserveDetail = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);

  useEffect(() => load(), []);

  const load = () => {
    const sideFilter = props.form.getFieldsValue();
    const filter = [];
    if (sideFilter.payments) filter.push("payed<>0");
    if (sideFilter.reserves) filter.push("reserved>0");
    if (sideFilter.status !== undefined) filter.push("status=" + sideFilter.status);
    if (sideFilter.movementMonth)
      filter.push("MONTH(date)=" + sideFilter.movementMonth.format("M") + " AND YEAR(date)=" + sideFilter.movementMonth.format("Y"));

    if (sideFilter.movementYear) filter.push("YEAR(date)=" + sideFilter.movementYear);
    setLoading(true);
    exe("RepoLifeCoveragePayout", { operation: "GET", filter: filter.join(" AND ") }).then((r) => {
      setLoading(false);
      setData(r.outData || []);
    });
  };
  const getTag = (status) => {
    if (status == 1) {
      return <Tag color="green">{t("Executed")}</Tag>;
    } else if (status == 2) {
      return <Tag color="red">{t("Reverted")}</Tag>;
    } else {
      return <Tag>{t("Requested")}</Tag>;
    }
  };
  const field = props.form.getFieldDecorator;
  const totalPayments = data.reduce((t, c) => t + c.payed, 0);
  const totalReserves = data.reduce((t, c) => t + c.reserved, 0);
  return (
    <div>
      <div>
        <Button type="link" icon={<ThunderboltOutlined />} onClick={load}>
          {t("Generate")}
        </Button>
        <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
          {t("Filter")}
        </Button>
        <CSVLink data={data} filename={"reserves.csv"} target="_blank" disabled={data.length == 0}>
          <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
            {t("Download")}
          </Button>
        </CSVLink>
      </div>
      <Table
        dataSource={data}
        loading={loading}
        footer={() => (
          <span>
            <b>{t("Total Reserved")}:</b>
            {formatMoney(totalReserves)}
            <Divider type="vertical" />
            <b>{t("Total Payments")}:</b>
            {formatMoney(totalPayments)}
          </span>
        )}>
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
        <Table.Column title={t("Claim")} dataIndex="claimId" render={(v) => <a href={"#/healthclaim/" + v}>{v}</a>} />
        <Table.Column title={t("Policy")} dataIndex="lifePolicyId" render={(v) => <a href={"#/lifePolicy/" + v}>{v}</a>} />
        <Table.Column title={t("Cover")} dataIndex="lifeCoverageId" />
        <Table.Column title={t("User")} dataIndex="user" />
        <Table.Column title={t("Reserve")} dataIndex="reserved" />
        <Table.Column title={t("Payment")} dataIndex="payed" />
        <Table.Column title={t("Concept")} dataIndex="concept" />
        <Table.Column title={t("Status")} dataIndex="status" render={(v) => getTag(v)} />
      </Table>

      <Drawer title={t("Filter")} visible={filterVisible} width={512} onClose={() => setFilterVisible(false)}>
        <Form layout="vertical">
          <Form.Item label={t("Reserves")}>{field("reserves", { valuePropName: "checked", initialValue: props.mode == "inProcess" })(<Switch />)}</Form.Item>
          <Form.Item label={t("Payments")}>
            {field("payments", { valuePropName: "checked", initialValue: props.mode == "paymentPending" })(<Switch />)}
          </Form.Item>
          <Form.Item label={t("Status")}>
            {field("status", { initialValue: 0 })(
              <Select placeholder={t("All statuses")}>
                <Select.Option value={undefined}>{t("All")}</Select.Option>
                <Select.Option value={0}>{t("Requested")}</Select.Option>
                <Select.Option value={1}>{t("Executed")}</Select.Option>
                <Select.Option value={2}>{t("Reverted")}</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={t("Movement Month")}>{field("movementMonth")(<DatePicker.MonthPicker />)}</Form.Item>
          <Form.Item label={t("Movement Year")}>{field("movementYear")(<InputNumber />)}</Form.Item>
        </Form>
        <Button type="link" icon={<ThunderboltOutlined />} onClick={load}>
          {t("Compute now")}
        </Button>
      </Drawer>
    </div>
  );
};

export default Form.create()(ClaimReserveDetail);
