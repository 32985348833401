import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import CommonChangeSurcharge from "./CommonChangeSurcharge";

const CommonChangeSection = (props) => {
    const t=props.t;
    const field=props.form.getFieldDecorator;
    const change=props.change;
    const common=props.common;
    
    return (
        <div>
            <Form.Item label={t("Surcharges")}>{field("Surcharges")(<CommonChangeSurcharge changeId={change&&props.change.id} currency={common.currency} />)}</Form.Item>
        </div>
    );
};

export default CommonChangeSection;