import React, {useEffect, useState} from "react";
import {
    DeleteOutlined,
    EyeOutlined,
    FileOutlined,
    LeftOutlined,
    PrinterOutlined,
    ReloadOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { Table, Button, Divider, Tag, Popconfirm, message } from "antd";
import moment from "moment";
import { formatter } from "../Shared/Money";
import AccountMov from "../Financial/AccountMov";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import ContactAccountNew from "./ContactAccountNew";
const { Column, ColumnGroup } = Table;

const ContactAccounts = ({contactId}) => {
    const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState(0);
    const [data,setData]=useState([]);
    const [newAccountVisible,setNewAccountVisible]=useState(false);
    
    useEffect(()=>{
        if(contactId) load();
    },[contactId])

    const load=()=>{
        setLoading(true);
        exe("RepoAccount",{operation:"GET",filter:`holderId=${contactId}`,include:["Movements"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)                
            }else message.error(r.msg)
        })
    }
    const selectedAccount = data.find((p) => p.id === account);
    const movements = selectedAccount ? selectedAccount.Movements : [];
    
    const deleteAccount = (record) => {
        setLoading(true);
        exe("RepoAccount", { operation: "DELETE", entity: record }).then((r) => {
            setLoading(false);
            if (r.ok) {
                load();
            } else {
                message.error(r.msg);
            }
        });
    };
    const onNewAccount=(values)=>{
        setLoading(true);
        values.contactId=contactId;
        exe("NewContactAccount",{...values}).then(r=>{
            setLoading(false);
            if(r.ok){
                load();
                setNewAccountVisible(false);
            }else message.error(r.msg)
        })
    }
    if (account === 0) {
        return (
            <div>
                <Button type="link" onClick={() =>setNewAccountVisible(true)} icon={<FileOutlined />} disabled={!contactId}>
                    {t("New Account")}
                </Button>
                <Button type="link" onClick={() =>load()} icon={<ReloadOutlined />} disabled={!contactId}>
                    {t("Reload")}
                </Button>
                <Table dataSource={data} scroll={{ x: true }} loading={loading}>
                    <Column title={t("Id")} dataIndex="id"></Column>
                    <Column title={t("Name")} dataIndex="name"></Column>
                    <Column title={t("Type")} dataIndex="type" render={(v) => <Tag>{v}</Tag>}></Column>
                    <Column title={t("Currency")} dataIndex="currency"></Column>
                    <Column title={t("Acc No")} dataIndex="accNo"></Column>
                    <Column title={t("Balance")} dataIndex="currentValue" render={(v, r) => formatter(r.currency).format(v ? v : 0)}></Column>
                    <Column
                        title={t("Actions")}
                        fixed="right"
                        dataIndex="id"
                        render={(v, r) => (
                            <div>
                                <Popconfirm title={t("Are you sure you want to delete this account?")} onConfirm={() => deleteAccount(r)}>
                                    <Button type="link" icon={<DeleteOutlined />} />
                                </Popconfirm>
                                <Divider type="vertical" />
                                <Button type="link" onClick={() => setAccount(v)}>
                                    {t("Transactions")}
                                    <RightOutlined />
                                </Button>
                            </div>
                        )}></Column>
                </Table>
                <ContactAccountNew visible={newAccountVisible} onCancel={()=>setNewAccountVisible(false)} onOk={values=>onNewAccount(values)} />
            </div>
        );
    } else {
        return (
            <div>
                <Button type="link" onClick={() => setAccount(0)}>
                    <LeftOutlined />
                    {t("Back")}
                </Button>
                <Button type="link" onClick={() => setAccount(0)}>
                    <PrinterOutlined />
                    {t("Print")}
                </Button>
                <Button type="link" onClick={() => (window.location.hash = "#/Account/" + account)}>
                    <EyeOutlined />
                    {t("Open")}
                </Button>
                <AccountMov value={movements} currency={selectedAccount.currency} account={selectedAccount} />
            </div>
        );
    }
};

export default ContactAccounts;
