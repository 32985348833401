import { message, Modal, Transfer } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const GroupActions = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [targetKeys, setTarketKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const onChange = (nextTargetKeys, direction, moveKeys) => {
    setTarketKeys(nextTargetKeys);
  };

  useEffect(() => loadActions(), []);
  useEffect(() => load(), [props.record]);

  const loadActions = () => {
    exe("RepoActionCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) setData(r.outData);
      else message.error(r.msg);
    });
  };

  const load = () => {
    if (props.record && props.record.jActions) setTarketKeys(JSON.parse(props.record.jActions));
    else setTarketKeys([]);
  };

  const save = () => {
    setLoading(true);
    const jActions = JSON.stringify(targetKeys);
    exe("SetGroupActions", { groupId: props.record.id, jActions: jActions }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Actions updated"));
        props.onUpdate(r.outData);
      } else message.error(r.msg);
    });
  };

  return (
    <div>
      <Modal title={t("Action Permissions")} visible={props.visible} onCancel={props.onCancel} onOk={save} width="800px" okButtonProps={{ loading: loading }}>
        <Transfer
          dataSource={data}
          titles={[t("Allow"), t("Deny")]}
          rowKey={(record) => record.code}
          onChange={onChange}
          targetKeys={targetKeys}
          render={(item) => `${item.name}`}
          listStyle={{
            width: 300,
            height: 300,
          }}
        />
      </Modal>
    </div>
  );
};

export default GroupActions;
