import React, { useState, useEffect } from "react";
import { CheckCircleOutlined, LeftOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Input,
  Button,
  Steps,
  DatePicker,
  message,
  Result,
  Collapse,
  Popconfirm,
} from "antd";
import Section from "../Shared/Section";
import Billing from "./Bill";
import Money from "../Shared/Money";
import Currency from "../Shared/Currency";
import Bill from "./Bill";
import { exe, safeParse } from "../../Lib/Dal";
import moment from "moment";
import { load } from "js-yaml";
import MultiContactSelect from "../Contact/MultiContactSelect";
import BillUnique from "./BillUnique";
import CoveragesDisplay from "./CoveragesDisplay";
import CoverageSelector from "./CoverageSelector";
import Text from "antd/lib/typography/Text";
import { formatDate } from "../../Lib/Helpers";
import EffectiveDate from "./EffectiveDate";
import { useTranslation } from "react-i18next";
import Amendments from "./Amendments";
import ChangeBilling from "./ChangeBilling";
import AmendedChange from "./AmendedChange";
import CommonChangeSection from "./CommonChangeSection";

const ChangeAddCoverage = (props) => {
  const [t] = useTranslation();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [readOnly, setReadOnly] = useState(false);
  const [selected, setSelected] = useState([]);

  useEffect(() => setValues(props.value), props.value);

  const field = props.form.getFieldDecorator;
  const currency = props.currency;
  const currentCoverages = props.currentCoverages || [];
  const policyId = props.policyId;

  const onQuote = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        if (!validateCoverages(values)) return;
        const operation = values.id ? "UPDATE" : "ADD";
        console.log(values);
        //validation OK
        setLoading(true);
        exe("ChangeAddCoverage", {
          ...values, policyId: policyId,
          jBeforeCoverages: JSON.stringify(values.jBeforeCoverages),
          jAddedCoverages: JSON.stringify(values.jAddedCoverages),
          jAmendments: JSON.stringify(values.Amendments),
          effectiveDate: values.effectiveDate,
          ...props.common.toServer
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            if (operation == "ADD") {
              props.form.setFieldsValue({
                ...r.outData,
                jBeforeCoverages: safeParse(r.outData.jBeforeCoverages),
                jAddedCoverages: safeParse(r.outData.jAddedCoverages),
                Amendments: safeParse(r.outData.jAmendments),
              });
            } else {
              //if the user re-quotes after saving
              props.form.setFieldsValue({
                ...r.outData,
                jBeforeCoverages: safeParse(r.outData.jBeforeCoverages),
                jAddedCoverages: safeParse(r.outData.jAddedCoverages),
                id: values.id,
                code: values.code,
                note: values.note,
                Bill: { ...r.outData.Bill, id: values.Bill.id },
                Amendments: safeParse(r.outData.jAmendments),
              });
            }
            setStep(step + 1);
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };
  const validateCoverages = (values) => {
    if (values.jAddedCoverages.length == 0) {
      message.error(t("Please specify at least one new coverage"));
      return false;
    } else {
      return true;
    }
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        if (!validateCoverages(values)) return;
        //validation OK
        const operation = values.id == 0 ? "ADD" : "UPDATE";
        if (operation == "UPDATE") {
          onUpdate(values);
          return;
        }
        setLoading(true);
        exe("ChangeAddCoverage", {
          ...values, policyId: policyId,
          jAddedCoverages: JSON.stringify(values.jAddedCoverages),
          jAmendments: JSON.stringify(values.Amendments),
          effectiveDate: values.effectiveDate,
          operation: "ADD",
          code: values.code,
          note: values.note,
          ...props.common.toServer
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            props.form.setFieldsValue({
              ...r.outData,
              jBeforeCoverages: safeParse(r.outData.jBeforeCoverages),
              jAddedCoverages: safeParse(r.outData.jAddedCoverages),
              Amendments: safeParse(r.outData.jAmendments),
            });
            setStep(step + 1);
            setResult({ status: "success", title: t("Successfully Saved Policy Change"), subTitle: t("Change number") + ":" + r.outData.id });
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };
  const onUpdate = (values) => {
    if (!validateCoverages(values)) return;
    const updated = {
      ...values,
      jAddedCoverages: JSON.stringify(values.jAddedCoverages),
      jBeforeCoverages: JSON.stringify(values.jBeforeCoverages),
      jAmendments: JSON.stringify(values.Amendments),
    };
    setLoading(true);
    exe("ChangeAddCoverage", { Entity: updated, operation: "UPDATE" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setStep(step + 1);
        setResult({ status: "success", title: t("Successfully Updated Policy Change"), subTitle: t("Change number") + ":" + r.outData.id });
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExecute = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (!validateCoverages(values)) return;
      setLoading(true);
      exe("ExeChangeAddCoverage", { changeId: values.id, operation: "EXECUTE" }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setValues(r.outData);
          setResult({
            status: "success",
            title: r.outData.status == 1 ? t("Successfully Executed Policy Change") : t("Policy Change Scheduled"),
            subTitle:
              t("Change number") +
              ":" +
              r.outData.id +
              (r.outData.status == 1
                ? ". " + t("Please refresh policy or click on 'Change List'")
                : t("Execution date") + ":" + formatDate(r.outData.effectiveDate)),
          });
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const setValues = (r) => {
    r.jBeforeCoverages = r.jBeforeCoverages ? (typeof r.jBeforeCoverages == "object" ? r.jBeforeCoverages : safeParse(r.jBeforeCoverages)) : currentCoverages;
    r.jAddedCoverages = r.jAddedCoverages ? (typeof r.jAddedCoverages == "object" ? r.jAddedCoverages : safeParse(r.jAddedCoverages)) : currentCoverages;
    r.Amendments = r.jAmendments ? safeParse(r.jAmendments) : [];
    props.form.setFieldsValue(r);

    if (r.status == 0) {
      setResult({ status: "info", title: t("Saved Policy Change Ready To Be Executed"), subTitle: t("Change number") + ":" + r.id });
    } else if (r.status == 1 || r.status == 3) {
      setResult({ status: "success", title: t("Policy Change Executed"), subTitle: t("Change Execution Date") + ":" + r.executionDate });
      setReadOnly(true);
    } else if (r.status === 6) {
      setResult({ status: "warning", title: t("Policy Change Amended"),subTitle: t("This change has been amended by another change") });
      setReadOnly(true);
    }
  };

  return (
    <div style={{ marginTop: 10 }}>
      <Form layout="vertical">
        <Steps current={step} onChange={(v) => setStep(v)} style={{ margin: 5, marginBottom: 15 }}>
          <Steps.Step title={t("Change")} description={t("Change options")} />
          <Steps.Step title={t("Quote")} description={t("Cost of changes")} />
          <Steps.Step title={t("Result")} description={t("Execution")} />
        </Steps>
        <Row gutter={16} hidden={step !== 0}>
          <Col span={24}>
            <Section text={t("Change Options")}>
              {/* CHANGE OPTIONS ***********************************************************************/}
              <Form.Item label={<Text strong>{t("Coverages before change")}</Text>}>
                {field("jBeforeCoverages", { initialValue: currentCoverages })(<CoveragesDisplay />)}
              </Form.Item>
              <Form.Item label={<Text strong>{t("Coverages after change")}</Text>}>
                {field("jAddedCoverages", { initialValue: currentCoverages, rules: [{ required: true }] })(
                  <CoverageSelector config={props.config} excluded={currentCoverages.map((p) => p.code)} value={selected} onChange={(p) => setSelected(p)} />
                )}
              </Form.Item>
              <Form.Item label={t("Effective Date")}>
                {field("effectiveDate", {
                  rules: [
                    {
                      required: true,
                      validator: (rule, v, cb) => (v ? cb(v.validationError) : cb(t("Effective date is required"))),
                    },
                  ],
                })(<EffectiveDate anniversary={props.anniversary} allowPastDates={props.common.allowPastEffectDate} />)}
              </Form.Item>
              <Form.Item label={t("Amendments")}>{field("Amendments")(<Amendments config={props.config} policy={props.policy} />)}</Form.Item>
              {field("changeIdToBeAmended",{initialValue:props.common.toServer.changeIdToBeAmended})(<AmendedChange />)}
             <CommonChangeSection t={t} form={props.form} common={props.common} change={props.value}  /> 
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
            <Button style={{ marginTop: 5 }} type="primary" onClick={onQuote} loading={loading} disabled={readOnly}>
              {t("Quote")}
              <RightOutlined />
            </Button>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 1}>
          <Col span={16}>
                                  {field("processId")}
                        {field("contractYear")}
                        {field("creationDate")}
                        {field("anniversary")}
                        {field("Bill")}
            {field("BillUnique")}
            {field("jNewPayPlan")}
            <ChangeBilling config={props.config} currency={currency} change={props.form.getFieldsValue()} form={props.form} />
          </Col>
          <Col span={8}>
            <Section text={t("Options")}>
              <Form.Item label={t("Id")}>{field("id", {})(<Input disabled />)}</Form.Item>
              <Form.Item label={t("Code")}>{field("code", {})(<Input placeholder={t("Optional manual code")} />)}</Form.Item>
              <Form.Item label={t("Note")}>
                {field("note", {})(<Input.TextArea rows={4} placeholder={t("Optional printable change note or remark")} />)}
              </Form.Item>
              <div style={{ visibility: "hidden" }}>
                {field("lifePolicyId", {})(<Input disabled />)}
                {field("jOldBeneficiaries", {})(<Input disabled />)}
                {field("jNewBeneficiaries", {})(<Input disabled />)}
              </div>
              <div style={{ marginTop: 20 }}>
                <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={() => setStep(step - 1)}>
                  {t("Back")}
                </Button>
                <Button type="primary" icon={<SaveOutlined />} style={{ marginRight: 5 }} onClick={onSave} loading={loading} disabled={readOnly}>
                  {t("Save")}
                </Button>
              </div>
            </Section>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 2}>
          <Col span={24}>
            <Result
              {...result}
              extra={[
                <Button key="console" onClick={() => props.reload()}>
                  {readOnly ? t("Change List") : t("Execute later")}
                </Button>,
                <Popconfirm
                  title={t("Are you sure you want to execute this policy change?")}
                  onConfirm={onExecute}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  disabled={readOnly}>
                  <Button icon={<CheckCircleOutlined />} type="primary" style={{ marginRight: 5 }} disabled={readOnly} loading={loading}>
                    {t("Execute")}
                  </Button>
                </Popconfirm>,
              ]}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(ChangeAddCoverage);
