import React from 'react';
import {Tooltip} from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

const LongTextColumn = (props) => {
    return (
        <div>
            <Tooltip title={props.value}>
                <Paragraph  ellipsis={{ rows: props.rows}}>
                    {props.value}
                </Paragraph>
            </Tooltip>
        </div>
    );
};

export default LongTextColumn;