import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Table, message } from "antd";
import { exe } from "../../Lib/Dal";
import { formatMoney, formatDateShort } from "../../Lib/Helpers";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const MovData = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [computeDate, setComputeDate] = useState(undefined);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (props.data) setData(props.data);
  }, [props.data]);

  const onCompute = () => {
    setLoading(true);
    exe("ComputeMovements", { computeDate }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success("Computed");
        exe("RepoMovement", { operation: "GET" }).then((r) => {
          setData(r.outData.records);
        });
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    if (props.onSelectChange) props.onSelectChange(data.filter((row) => selectedRowKeys.includes(row.id)));
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div>
      <CSVLink data={data} filename={"movements.csv"} target="_blank" disabled={data.length == 0}>
        <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
          {t("Download")}
        </Button>
      </CSVLink>
      <DatePickerW value={computeDate} onChange={(v) => setComputeDate(v)} />
      <Button type="link" icon={<ThunderboltOutlined />} onClick={onCompute} disabled={!computeDate}>
        {t("Compute now")}
      </Button>
      <Table
        dataSource={data}
        loading={loading}
        style={{ marginTop: 5 }}
        scroll={{ x: true }}
        size="middle"
        rowSelection={props.selectable ? rowSelection : {}}
        pagination={props.selectable ? false : true}
        rowKey="id">
        <Table.Column title={t("Event Date")} dataIndex="calculationDate" render={(v) => formatDateShort(v)} />
        <Table.Column title={t("Code")} dataIndex="code" render={(v) => <Text code>{v}</Text>} />
        <Table.Column title={t("Name")} dataIndex="description" />
        <Table.Column title={t("Ref")} dataIndex="reference" />
        <Table.Column title={t("Amount")} dataIndex="amount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("t")} dataIndex="valueCurrent" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("t-1")} dataIndex="valuePrevious" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Contact")} dataIndex="contactId" render={(v, r) => <a href={"#/contact/" + v}>{v}</a>} />
        <Table.Column title={t("Policy")} dataIndex="lifePolicyId" render={(v, r) => <a href={"#/lifePolicy/" + v}>{v}</a>} />
        <Table.Column title={t("Account")} dataIndex="accountId" />
        <Table.Column title={t("Coverage")} dataIndex="lifeCoverageId" />
      </Table>
    </div>
  );
};

export default MovData;
