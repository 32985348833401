import React from "react";
import { Button, Table, Tag, message } from "antd";
import { useEffect, useState } from "react";
import {  ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDate} from "../../Lib/Helpers";

const UserChangeHistory = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        load();
    },[])

    const load=()=>{
        setLoading(true);
        exe("RepoUsrChangeHistory",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }


    return <div>
            <Button type="link" icon={<ReloadOutlined />} onClick={load}>{t("Reload")}</Button>
            <Table dataSource={data} rowKey={"id"} loading={loading}>
                <Table.Column title={t("Id")} dataIndex="id" />
                <Table.Column title={t("Date")} dataIndex="date" render={v=>formatDate(v)} />
                <Table.Column title={t("By")} dataIndex="by" />
                <Table.Column title={t("User")} dataIndex="user" />
                <Table.Column title={t("User Name")} dataIndex="userName" />
                <Table.Column title={t("Action")} dataIndex="action" render={v=><Tag>{v}</Tag>} />
                <Table.Column title={t("Description")} dataIndex="description" />

                
            </Table>
    </div>
       
    };

    export default UserChangeHistory;