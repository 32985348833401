import {
  CopyOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  EditOutlined,
  EnterOutlined,
  FileOutlined,
  LeftOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  SaveOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, InputNumber, message, Popconfirm, Switch } from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import TransactionLine from "./TransactionLine";
import moment from "moment";
import Currency from "../Shared/Currency";
import Section from "../Shared/Section";
import TransactionStatusSelect from "./TransactionStatusSelect";
import {formatMoney, getColor, setDate} from "../../Lib/Helpers";
import Status from "../Shared/Status";
import Spreadsheet from "../Shared/Spreadsheet";
import CostCenters from "./CostCenters";
import DatePickerW from "../Shared/DatePickerW";

const Transaction = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [lines, setLines] = useState([{ order: 1 }]);
  const [spreadsheetMode,setSpreadsheetMode]=useState(false);
  const [spreadsheet,setSpreadsheet]=useState();
  const [posting,setPosting]=useState(false);
  const [costCentersVisible,setCostCentersVisible]=useState(false);
  const [updatedTransaction,setUpdatedTransaction]=useState();
  const [auditAdjustment,setAuditAdjustment]=useState(false);

  useEffect(() => {
    if (props.selected) {
      props.form.setFieldsValue(props.selected);
      setLines(props.selected.Lines);
    } else {
      onNew();
      if(props.auditAdjustment){
        //setting audit adjustment mode
        setAuditAdjustment(true);
        //getting the last day of previous year
        const lastDayOfPreviousYear = setDate().subtract(1, 'years').endOf('year');
        //setting 12:00 of the last day of previous year
        lastDayOfPreviousYear.set({hour:12,minute:0,second:0,millisecond:0});
        
        console.log(lastDayOfPreviousYear,"POSTING DATE");
        props.form.setFieldsValue({code:'AUDIT-ADJUSTMENT',effectiveDate:lastDayOfPreviousYear})
      
      }
    }
  }, [props.selected]);
  

  const addLine = () => {
    setLines([...lines, { order: lines.length + 1 }]);
  };
  const removeLine = (l) => {
    setLines(lines.filter((p) => p.order !== l.order));
  };
  const onChangeLine = (l, order) => {
    console.log(l, order);
    l.order = order;
    setLines(lines.map((p) => (p.order == order ? l : p)));
  };
  const getTotals=()=>{
    const totals={debit:0,credit:0,xDebit:0,xCredit:0,xCurrency:'USD'};
    totals.debit=lines.filter(p=>p.debit).reduce((p,c)=>p+c.debit,0);
    totals.credit=lines.filter(p=>p.credit).reduce((p,c)=>p+c.credit,0);
    totals.xDebit=lines.filter(p=>p.xDebit).reduce((p,c)=>p+c.xDebit,0);
    totals.xCredit=lines.filter(p=>p.xCredit).reduce((p,c)=>p+c.xCredit,0);
    totals.xCurrency=lines.find(p => p.xCurrency !== undefined)?lines.find(p => p.xCurrency !== undefined).xCurrency:'USD';
    return totals;
  }
  const onNew=()=>{
    props.form.resetFields();
    setLines([]);
  }
  const save = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (lines.length == 0) {
        message.error(t("No lines inserted"));
        return;
      }
      console.log(values, lines);
      values.Lines = lines;
      values.Lines.forEach((p) => {
        if (p.debit == undefined) p.debit = 0;
        if (p.credit == undefined) p.credit = 0;
      });
      if(values.Lines.some(p=>!p.account)){
        message.error(t("All lines must have an account"));
        return;
      }
      setLoading(true);
      exe("RepoTransaction", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          props.form.setFieldsValue(r.outData[0]);
          props.onAdd && props.onAdd(r.outData[0]);
          setUpdatedTransaction(r.outData[0]);
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onDelete = (id) => {
    exe("RepoTransaction", { operation: "DELETE", entity: { id: id } }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
        props.onDelete(id);
      } else {
        message.error(r.msg);
      }
    });
  };
  const post=(id)=>{
    setPosting(true);
    exe("PostTransaction",{transactionId:id,auditAdjustment:auditAdjustment}).then(r=>{
      setPosting(false);
      if(r.ok){
        message.success(r.msg);
        props.form.setFieldsValue(r.outData);
      }else message.error(r.msg);
    })
  }
  const reload=()=>{
    if(!values.id) return;
    setLoading(true);
    exe("RepoTransaction",{ operation: "GET", filter: `id=${values.id}`,include:["Lines","Process","Lines.CostCenters","Lines.CostCenters.CostCenter","Lines.Account"]}).then(r=>{
      setLoading(false);
      if(r.ok){
        props.form.setFieldsValue(r.outData[0]);
        setLines(r.outData[0].Lines);
        setUpdatedTransaction(r.outData[0]);
      }else message.error(r.msg);
    })
  }
  const submitEditMode=()=>{
    const parsedData = spreadsheet
        .getData()
        .filter((p) => p[0] !== "" && p[1] !== "")
        .map((p) => ({ 
          order: +p[0], 
          account: p[1], 
          comments: p[2] ,
          debit:p[3]?parseFloat(p[3]):0,
          credit:p[4]?parseFloat(p[4]):0,
          xDebit:p[5]?parseFloat(p[5]):undefined,
          xCredit:p[6]?parseFloat(p[6]):undefined,
          auxId:p[7],
          xChangeRate:p[8]?parseFloat(p[8]):undefined,
          xCurrency:p[9],
           }));
    if (parsedData.length === 0) {
      message.error("No data detected");
    }else{
      setLines(parsedData);
      setSpreadsheetMode(false);
    }
  }

  const setTableStyle=(records,instance)=>{
    const styleObj={};
    for (let i = 0; i < records; i++) {
      styleObj["B"+(i+1)]="font-weight:bold;text-align:left";
      styleObj["C"+(i+1)]="text-align:left";
    }
    instance.setStyle(styleObj);
  }
  const clone=()=>{
    const copy={...values,id:0};
    copy.Lines=[...lines.map(p=>({...p,id:0}))];
    props.form.setFieldsValue(copy);
    setLines(copy.Lines);
    message.success(t("Record cloned"));
  }
  const revert=()=>{
    const copy={...values,id:0};
    copy.Lines=[...lines.map(p=>({...p,id:0,debit:p.credit,credit:p.debit,xDebit:p.xCredit,xCredit:p.xDebit}))];
    props.form.setFieldsValue(copy);
    setLines(copy.Lines);
    message.success(t("Record reverted"));
  }
  const field = props.form.getFieldDecorator;
  const values = props.form.getFieldsValue();
  return (
    <div>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => reload()} >
        {t("Reload")}
      </Button>
      <Button type="link" icon={<FileOutlined />} onClick={() => onNew()} >
        {t("New")}
      </Button>
      <Button type="link" icon={<SaveOutlined />} onClick={() => save()} loading={loading} disabled={!values.manual}>
        {t("Save")}
      </Button>
      <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={() => onDelete(values.id)} disabled={values.status || !values.id||!values.manual}>
        <Button type="link" icon={<DeleteOutlined />} disabled={values.status || !values.id||!values.manual}>
          {t("Delete Draft")}
        </Button>
      </Popconfirm>
      <Button type="link" icon={<EnterOutlined />} onClick={() => post(values.id)} loading={posting} disabled={!values.id||values.status===1}>
        {t("Post")}
      </Button>
      <Button type="link" icon={<TransactionOutlined />} onClick={() => revert()}  disabled={!values.id}>
        {t("Revert")}
      </Button>
      <Button type="link" icon={<CopyOutlined />} onClick={() => clone()}  disabled={!values.id}>
        {t("Clone")}
      </Button>
      <Section text={t("Main")}>
        <Form style={{marginBottom:20}} >
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Creation Date")} style={{ width: 171 }}>
              {field("date", { normalize: (v) => (v ? moment(v) : undefined),initialValue:moment() })(<DatePickerW disabled style={{width:"100%"}}  />)}
            </Form.Item>
            <Form.Item label={t("Code")} style={{ marginLeft: 5, width: 300 }}>
              {field("code")(<Input disabled={auditAdjustment} />)}
            </Form.Item>
            <Form.Item label={t("Reference")} style={{ marginLeft: 5, width: 300 }}>
              {field("reference")(<Input />)}
            </Form.Item>
            <Form.Item label={t("Description")} style={{ flex: 1, marginLeft: 5 }}>
              {field("description")(<Input />)}
            </Form.Item>
            <div style={{ display: "flex", width: 300 }}>
              <Form.Item label={t("Manual")} style={{ marginLeft:5 }}>
                {field("manual", {valuePropName:"checked",initialValue:true})(<Switch disabled />)}
              </Form.Item>
              <Form.Item label={t("ID")} style={{ marginLeft: 5 }}>
                {field("id")(<InputNumber disabled />)}
              </Form.Item>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Posting Date")} style={{ width: 171 }}>
              {field("effectiveDate")(<DatePickerW disabled={auditAdjustment} style={{width:"100%"}}  />)}
            </Form.Item>
            <Form.Item label={t("Template")} style={{ marginLeft: 5, width: 300 }}>
              {field("templateCode")(<Input disabled />)}
            </Form.Item>
            <Form.Item label={t("Operation")} style={{ marginLeft: 5, width: 300 }}>
              {field("operationCode")(<Input disabled />)}
            </Form.Item>
            <Form.Item label={t("Notes")} style={{ marginLeft: 5, flex: 1 }}>
              {field("notes")(<Input />)}
            </Form.Item>
            <div style={{ display: "flex", width: 300 }}>
              <Form.Item label={t("Entity")} style={{ marginLeft: 5, flex: 1 }}>
                {field("entity")(<Input disabled />)}
              </Form.Item>
              <Form.Item label={t("Entity ID")} style={{ marginLeft: 5 }}>
                {field("entityId")(<InputNumber disabled />)}
              </Form.Item>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Status")} style={{ width: 171}}>
              {field("status", { initialValue: 0, rules: [{ required: true }] })(<TransactionStatusSelect disabled />)}
            </Form.Item>
            <Form.Item label={t("User")} style={{ marginLeft: 5, width: 300 }}>
              {field("user")(<Input disabled />)}
            </Form.Item>
            {field("processId")}
            {field("Process")}
            <Form.Item label={t("Workflow")} style={{ marginLeft: 5, width: 300 }}>
              <Status process={values.Process} color={getColor(values.Process && values.Process.entityState)} reload={() => reload()} />
            </Form.Item>
          </div>
        </Form>
      </Section>
      <Section text={t("Accounting Entries")}>
        {spreadsheetMode&&<div>
          <Button type="link" icon={<LeftOutlined />} onClick={() => setSpreadsheetMode(false)} style={{float:"left"}}>
            {t("Back")}
          </Button>
          <Button type="link" icon={<EnterOutlined />} onClick={() => submitEditMode()} style={{float:"left"}}>
            {t("Submit")}
          </Button>
          <Spreadsheet
              instance={(t) => {window.spreadsheet=t;setSpreadsheet(t); setTableStyle(lines.length,t);}}
              data={lines.map(p=>[p.order,p.account,p.comments,p.debit,p.credit,p.xDebit,p.xCredit,p.xChangeRate,p.xCurrency,p.auxId])}
              columns={[
                { title: t("Line"), width: 50 },
                { title: t("Account"), width: 200 },
                { title: t("Concept"), width: 200 },
                { title: t("Debit"), width: 100 },
                { title: t("Credit"), width: 100 },
                { title: t("X Debit"), width: 100 },
                { title: t("X Credit"), width: 100 },
                { title: t("Exchange Rate"), width: 100 },
                { title: t("Currency"), width: 100 },
                { title: t("Aux"), width: 100 },
              ]}
              showFileImport
          />
        </div>}
        {!spreadsheetMode&&<div>
        <Button type="link" icon={<EditOutlined />} onClick={() => setSpreadsheetMode(true)}>
          {t("Edit Mode")}
        </Button>
        <Button type="link" icon={<DeploymentUnitOutlined />} onClick={() => setCostCentersVisible(true)}>
          {t("Cost Centers")}
          </Button>
        {(lines || []).length > 0 && (
          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, fontWeight: 600,maxWidth:55,minWidth:55,marginRight:5 }}>{t("Line")}</div>
            <div style={{ flex: 1, fontWeight: 600,maxWidth:400,minWidth:200, marginRight:5 }}>{t("Account")}</div>
            <div style={{ flex: 1, fontWeight: 600,maxWidth:200,marginRight:25 }}>{t("Concept")}</div>
            <div style={{ flex: 1, fontWeight: 600,maxWidth:200,marginRight:5 }}>{t("Debit")}</div>
            <div style={{ flex: 1, fontWeight: 600,maxWidth:200,marginRight:5 }}>{t("Credit")}</div>
            <div style={{ flex: 1, fontWeight: 600,maxWidth:200,marginRight:5, whiteSpace:"nowrap" }}>{t("X Debit")}</div>
            <div style={{ flex: 1, fontWeight: 600,maxWidth:200,marginRight:5, whiteSpace:"nowrap" }}>{t("X Credit")}</div>
            <div style={{ flex: 1, fontWeight: 600,maxWidth:100,marginRight:5, whiteSpace:"nowrap" }}>{t("Exchange Rate")}</div>
            <div style={{ width:32 }}></div>
            <div style={{ flex: 1, fontWeight: 600,maxWidth:200,marginRight:5, whiteSpace:"nowrap" }}>{t("Aux")}</div>
            <div style={{ width: 24 }}></div>
          </div>
        )}
        {(lines || []).map((l) => (
          <span style={{ display: "flex" }} key={l.order}>
            <TransactionLine key={l.order} value={l} onChange={(changedLine) => onChangeLine(changedLine, l.order)} defaultCurrency={props.defaultCurrency} postingDate={values.effectiveDate} />
            <MinusCircleOutlined
              style={{ marginLeft: 5, cursor: "pointer", fontSize: 24, color: "#999", alignSelf: "center" }}
              onClick={() => removeLine(l)} />
          </span>
        ))}
        {(lines || []).length > 0 && (
            <div style={{ display: "flex",marginTop:10 }}>
              <div style={{ flex: 1, fontWeight: 600,maxWidth:55,minWidth:55,marginRight:5 }}/>
              <div style={{ flex: 1, fontWeight: 600,maxWidth:400,minWidth:200,marginRight:5 }}/>
              <div style={{ flex: 1, fontWeight: 600,maxWidth:200,marginRight:25 }}></div>
              <div style={{ flex: 1, color:"darkgrey", maxWidth:200,marginRight:5 }}>{formatMoney(getTotals().debit,props.defaultCurrency)}</div>
              <div style={{ flex: 1, color:"darkgrey", maxWidth:200,marginRight:5 }}>{formatMoney(getTotals().credit,props.defaultCurrency)}</div>
              <div style={{ flex: 1, color:"darkgrey", maxWidth:200,marginRight:5 }}>{formatMoney(getTotals().xDebit,getTotals().xCurrency)}</div>
              <div style={{ flex: 1, color:"darkgrey", maxWidth:200,marginRight:5 }}>{formatMoney(getTotals().xCredit,getTotals().xCurrency)}</div>
              <div style={{ flex: 1, fontWeight: 600,maxWidth:100,marginRight:5 }}/>
              <div style={{ width:32 }}></div>
              <div style={{ flex: 1, fontWeight: 600,maxWidth:200,marginRight:5, whiteSpace:"nowrap" }}></div>
              <div style={{ width: 24 }}></div>
            </div>
        )}
      <Button type="link" icon={<PlusCircleOutlined />} onClick={() => addLine()}>
        {t("Add Line")}
      </Button>
        </div>}
      </Section>
      <CostCenters visible={costCentersVisible} transaction={updatedTransaction||props.selected} onCancel={()=>setCostCentersVisible(false)} onOk={()=>setCostCentersVisible(false)} reload={()=>reload()}  />
    </div>
  );
};

export default Form.create()(Transaction);
