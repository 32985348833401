import Title from 'antd/lib/typography/Title';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InvestmentStrategy from '../Financial/InvestmentStrategy';
import InvestmentSelection from '../Financial/InvestmentSelection';
import { safeGet } from '../../Lib/Dal';
const PensionInvestments = (props) => {
    const [t]=useTranslation();

    const investmentStrategies=[];

    return (
        <div>
            <Title level={4}>{t("Investment Options")}</Title>
              
        </div>
    )
}
export default PensionInvestments;