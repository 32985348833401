import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Modal, Progress, Select, Slider } from "antd";
import Text from "antd/lib/typography/Text";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import ProcessingType from "./ProcessingType";
import FileSelect from '../Shared/FileSelect';

const Batch = (props) => {
  const [t] = useTranslation();
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState(false);
  const [progress,setProgress]=useState(0);
  
useEffect(()=>{
  setRecord({name:props.defaultName,records:props.rows ? props.rows.length : 0})  ;
},[props.visible]);

  const onSave = (record) => {
    setLoading(true);
    setProgress(0);
    if (props.importType == null) {
      message.error(t("Please select import type"));
      return;
    }
    
    const name=record.name;
    const pageSize=10000;
    const pages=Math.ceil(props.rows.length/pageSize);
    const remainder=pageSize-(pageSize*pages-props.rows.length);
    let counter=1;

    for (let i = 0; i < pages; i++) {
      record.importConfigId = props.importType.id;
      record.jData = JSON.stringify(props.rows.slice(i*pageSize,(i+1)*pageSize));
      const last= (i===pages-1);
      record.records=last?remainder:pageSize;
      record.processingType = props.processingType;
      record.name=(pages>1)?`${name}--${(i+1)}/${pages}`:name;
      
      exe("RepoBatch", { operation: record.id ? "UPDATE" : "ADD", entity: record }).then((r) => {
        counter++;
        setProgress(counter/pages);
        if(last) setLoading(false);
        if (r.ok) {
          if(last) {
            props.onAdded();
            message.success(r.msg);
          }
        } else {
          message.error(r.msg);
        }
      });
      
    }
  };
  return (
    <div>
      <Modal title={t("Batch Information")} onOk={() => {setLoading(true);setTimeout(()=>onSave(record),200)}} {...props} okButtonProps={{loading:loading}} >
        <Form.Item label={t("Processing Type")}>
          <ProcessingType value={props.processingType} disabled />
        </Form.Item>
        <Form.Item label={props.processingType===2?t("Server file name"):t("Batch name")}>
          {props.processingType===2&&<FileSelect value={record?record.name:undefined}  onChange={(v) => setRecord({ ...record, name: v })} />}
          {props.processingType!==2&&<Input value={record?record.name:undefined} onChange={(v) => setRecord({ ...record, name: v.target.value })} />}
        </Form.Item>
        {props.processingType!==2&&<Form.Item label={t("Records")}>
          <Text code onChange={(v) => setRecord({ ...record, records: v })}>
            {props.rows ? props.rows.length : 0}
          </Text>
        </Form.Item>}
        {/* <Form.Item label={t("Priority")}>
          <Slider step={10} value={record ? record.priority : undefined} onChange={(v) => setRecord({ ...record, priority: v })} />
        </Form.Item> */}
        {progress>0&&progress<1&&<Progress percent={progress*100} size="small" showInfo={false} />}
      </Modal>
    </div>
  );
};

export default Batch;
