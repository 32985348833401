import React, {useEffect} from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, message } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";

const GuardianForm = (props) => {
    const [loading,setLoading]=React.useState(false);
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
   
    useEffect(()=>{
        if(props.contactId){
            checkStatus();
        }
    },[props.contactId]);
    
    const checkStatus=()=>{
        setLoading(true);
        exe("GetMinorStatus",{contactId:props.contactId}).then(r=>{
            setLoading(false);
            if(r.ok){
                if(r.outData.noGuardian){
                    props.form.setFieldsValue({guardianId:null});
                    props.onNoGuardian(true)
                }else{
                    props.form.setFieldsValue({guardianId:r.outData.guardianId});
                    props.onNoGuardian(false)
                }
            }else message.error(r.message);
        })
    }
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            console.log(values);
            setLoading(true);
            exe("SetMinorStatus",{contactId:props.contactId,guardianId:values.guardianId}).then(r=>{
                setLoading(false);
                if(r.ok) {
                    message.success(t("Saved"));
                    props.onOk&&props.onOk(values);
                }else message.error(r.message);
            });
        })
    }
    return (
        <div >
            <Form.Item label={t("Guardian")}>{field("guardianId",{rules:[{required:true}]})(<SimpleContactSelect allowNew showLink />)}</Form.Item>
            <Button type="primary" icon={<SaveOutlined />} onClick={onSave} style={{marginTop:15}} loading={loading}>{t("Save")}</Button>
        </div>
    );
};

export default Form.create()(GuardianForm);