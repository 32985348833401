import React, { useState, useEffect } from "react";
import { CheckCircleOutlined, LeftOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Input,
  Button,
  Steps,
  DatePicker,
  message,
  Result,
  Collapse,
  Popconfirm,
  Select,
} from "antd";
import Section from "../Shared/Section";
import { exe, safeParse } from "../../Lib/Dal";
import moment from "moment";
import BillUnique from "./BillUnique";
import { formatDate } from "../../Lib/Helpers";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import EffectiveDate from "./EffectiveDate";
import { useTranslation } from "react-i18next";
import ChangeBilling from "./ChangeBilling";
import CommonChangeSection from "./CommonChangeSection";

const ChangeAnnuityBeneficiary = (props) => {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => setValues(props.value), props.value);

  const field = props.form.getFieldDecorator;
  const currency = props.currency;
  const policyId = props.policy.id;

  const onQuote = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        setLoading(true);
        exe("ChangeAnnuityBeneficiary", {
          ...values, policyId: policyId,
          newAnnuityBeneficiary: values.newAnnuityBeneficiary,
          oldAnnuityBeneficiary: values.oldAnnuityBeneficiary,
          effectiveDate: values.effectiveDate,
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            props.form.setFieldsValue(r.outData);
            setStep(step + 1);
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        const operation = values.id == 0 ? "ADD" : "UPDATE";
        if (operation == "UPDATE") {
          onUpdate(values);
          return;
        }
        setLoading(true);
        exe("ChangeAnnuityBeneficiary", {
          ...values, policyId: policyId,
          newAnnuityBeneficiary: values.newAnnuityBeneficiary,
          oldAnnuityBeneficiary: values.oldAnnuityBeneficiary,
          effectiveDate: values.effectiveDate,
          operation: "ADD",
          code: values.code,
          note: values.note,
        }).then((r) => {
          setLoading(false);
          if (r.ok) {
            props.form.setFieldsValue(r.outData);
            setStep(step + 1);
            setResult({ status: "success", title: t("Successfully Saved Policy Change"), subTitle: t("Change number") + ":" + r.outData.id });
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
      }
    });
  };
  const onUpdate = (values) => {
    setLoading(true);
    exe("ChangeAnnuityBeneficiary", { Entity: values, operation: "UPDATE" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.form.setFieldsValue(r.outData);
        setStep(step + 1);
        setResult({ status: "success", title: t("Successfully Updated Policy Change"), subTitle: t("Change number") + ":" + r.outData.id });
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExecute = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("ExeChangeAnnuityBeneficiary", { changeId: values.id, operation: "EXECUTE" }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setValues(r.outData);
          setResult({
            status: "success",
            title: r.outData.status == 1 ? t("Successfully Executed Policy Change") : t("Policy Change Scheduled"),
            subTitle:
              t("Change number") +
              ":" +
              r.outData.id +
              (r.outData.status == 1
                ? t(". Please refresh policy or click on 'Change List'")
                : t("Execution date") + ":" + formatDate(r.outData.effectiveDate)),
          });
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const setValues = (r) => {
    if (r.oldAnnuityBeneficiary) {
      props.form.setFieldsValue(r);
    } else {
      //checking current annuity beneficiary value
      exe("RepoAnnuity", { operation: "GET", filter: "lifePolicyId=" + props.policy.id }).then((p) => {
        if (p.ok) {
          if (p.outData.length == 0) {
            message.error("No annuity found for this policy");
          } else {
            r.oldAnnuityBeneficiary = p.outData[0].beneficiaryId;
            props.form.setFieldsValue(r);
          }
        } else message.error(p.msg);
      });
    }

    if (r.status == 0) {
      setResult({ status: "info", title: t("Saved Policy Change Ready To Be Executed"), subTitle: t("Change number") + ":" + r.id });
    } else if (r.status == 1 || r.status == 3) {
      setResult({ status: "info", title: t("Policy Change Executed"), subTitle: t("Change Execution Date") + ":" + r.executionDate });
      setReadOnly(true);
    }
  };

  return (
    <div style={{ marginTop: 10 }}>
      <Form layout="vertical">
        <Steps current={step} onChange={(v) => setStep(v)} style={{ margin: 5, marginBottom: 15 }}>
          <Steps.Step title={t("Change")} description={t("Change options")} />
          <Steps.Step title={t("Quote")} description={t("Cost of changes")} />
          <Steps.Step title={t("Result")} description={t("Execution")} />
        </Steps>
        <Row gutter={16} hidden={step !== 0}>
          <Col span={12}>
            <Section text={t("Change Options")}>
              {/* CHANGE OPTIONS ***********************************************************************/}
              <Form.Item label={t("Current Annuity Beneficiary Value")}>
                {field("oldAnnuityBeneficiary", { initialValue: props.policy.cessionBeneficiary })(<SimpleContactSelect disabled />)}
              </Form.Item>
              <Form.Item label={t("New Annuity Beneficiary Value")}>{field("newAnnuityBeneficiary")(<SimpleContactSelect />)}</Form.Item>
              <Form.Item label={t("Effective Date")}>
                {field("effectiveDate", {
                  rules: [
                    {
                      required: true,
                      validator: (rule, v, cb) => (v ? cb(v.validationError) : cb(t("Effective date is required"))),
                    },
                  ],
                })(<EffectiveDate anniversary={props.anniversary} allowPastDates={props.common.allowPastEffectDate} />)}
              </Form.Item>
             <CommonChangeSection t={t} form={props.form} common={props.common} change={props.value}  /> 
              {/* CHANGE OPTIONS ***********************************************************************/}
            </Section>
            <Button style={{ marginTop: 5 }} type="primary" onClick={onQuote} loading={loading} disabled={readOnly}>
              {t("Quote")}
              <RightOutlined />
            </Button>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 1}>
          <Col span={16}>
            {field("processId")}{field("contractYear")} {field("creationDate")} {field("anniversary")}{field("Bill")}
            {field("BillUnique")}
            {field("jNewPayPlan")}
            <ChangeBilling config={props.config} currency={currency} change={props.form.getFieldsValue()} form={props.form} />
          </Col>
          <Col span={8}>
            <Section text={t("Options")}>
              <Form.Item label={t("Id")}>{field("id", {})(<Input disabled />)}</Form.Item>
              <Form.Item label={t("Code")}>{field("code", {})(<Input placeholder={t("Optional manual code")} />)}</Form.Item>
              <Form.Item label={t("Note")}>
                {field("note", {})(<Input.TextArea rows={4} placeholder={t("Optional printable change note or remark")} />)}
              </Form.Item>
              <div style={{ visibility: "hidden" }}>{field("lifePolicyId", {})(<Input disabled />)}</div>
              <div style={{ marginTop: 20 }}>
                <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={() => setStep(step - 1)}>
                  {t("Back")}
                </Button>
                <Button type="primary" icon={<SaveOutlined />} style={{ marginRight: 5 }} onClick={onSave} loading={loading} disabled={readOnly}>
                  {t("Save")}
                </Button>
              </div>
            </Section>
          </Col>
        </Row>
        <Row gutter={16} hidden={step !== 2}>
          <Col span={24}>
            <Result
              {...result}
              extra={[
                <Button key="console" onClick={() => props.reload()}>
                  {readOnly ? t("Change List") : t("Execute later")}
                </Button>,
                <Popconfirm
                  title={t("Are you sure you want to execute this policy change?")}
                  onConfirm={onExecute}
                  okText={t("Yes")}
                  cancelText={t("No")}
                  disabled={readOnly}>
                  <Button icon={<CheckCircleOutlined />} type="primary" style={{ marginRight: 5 }} disabled={readOnly} loading={loading}>
                    {t("Execute")}
                  </Button>
                </Popconfirm>,
              ]}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(ChangeAnnuityBeneficiary);
