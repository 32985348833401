import React from "react";

const MoneyLabel = (props) => {
  const formatter = (currency = "USD") =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    });

  const money = formatter(props.currency?props.currency:"USD");
  return <div style={props.style}>{money.format(props.value)}</div>;
};

export default MoneyLabel;
