import React, { useState, useEffect } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { useTranslation } from "react-i18next";
import { EuroOutlined, RightOutlined, RocketOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, InputNumber, message, Row, Tabs, Tooltip } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import PolicySelect from "../Health/PolicySelect";
import InvestmentSelection from "../Financial/InvestmentSelection";
import moment from "moment";
import Money from "../Shared/Money";
import Currency from "../Shared/Currency";
import InstallmentScheme from "../Installments/InstallmentScheme";
import InstallmentSchemeSelect from "../Installments/InstallmentSchemeSelect";
import Periodicty from "../Shared/Periodicty";
import CreditProductSelect from "./CreditProductSelect";
import FrequencySelect from "../Shared/FrequencySelect";
import Accounts from "../Life/Accounts";
import CapitalizationModeSelect from "./CapitalizationModeSelect";
import {exe, safe, safeGet, safeParse} from "../../Lib/Dal";
import CreditCharges from "./CreditCharges";
import Status from "../Shared/Status";
import {getColor} from "../../Lib/Helpers";
import ButtonGroup from "antd/es/button/button-group";
import CreditHeader from "./CreditHeader";
import CreditInstallments from "./CreditInstallments";
import TaskSelect from "../Bpm/TaskSelect";
import EntityTasks from "../Life/EntityTasks";
import CustomForm from "../Shared/CustomForm";
import ContactPoliciesSelect from "../Shared/ContactPoliciesSelect";
import HiddenField from "../Shared/HiddenField";
import DatePickerW from "../Shared/DatePickerW";

const Credit = (props) => {
    const [selectedTabKey,setSelectedTabKey]=useState("main");
    const [t, i18n] = useTranslation();
    const [loading,setLoading]=useState(false)
    const [process,setProcess]=useState();
    const [quoting,setQuoting]=useState(false);
    const [issuing,setIssuing]=useState(false)
    const [contact,setContact]=useState();
    const [reloadChild,doReloadChild]=useState(0);
    const [product,setProduct]=useState();
    const [formId,setFormId]=useState();
    
    useEffect(()=>{
        if(props.match.params.creditId) load(props.match.params.creditId);
    },[props.match.params.creditId])
    
    
    const load=(id)=>{
        setLoading(true);
        exe("RepoCredit",{operation:"GET",filter:`id=${id}`,include:["Process","Accounts","Accounts.Movements"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                if(r.total>0){
                    props.form.setFieldsValue(r.outData[0]);
                    setProcess(r.outData[0].Process);
                }
                else message.error(t("Record not found"));

            }else message.error(r.msg);
        })
    }
    const save=()=>{
        props.form.validateFields((err,values)=>{
            if(err){
                message.error(t("Validation errors. Please check mandatory fields."));
                return;;
            }
            //validating custom form
            if (window.customCreditForm &&window.customCreditForm.instanceContainers&& window.customCreditForm.instanceContainers[0]) {
                if (window.customCreditForm.instanceContainers[0].reportValidity()) {
                    values.jForm = JSON.stringify(window.customCreditForm.userData);
                    values.formId=formId;
                } else {
                    message.error(t("Please check custom form validation errors"));
                    setSelectedTabKey("customForm");
                    return;
                }
            }
            const operation=values.id?"UPDATE":"ADD"
            setLoading(true);
            exe("RepoCredit",{operation:operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    reEnter(r.outData[0].id);
                    message.success(r.msg);
                    
                }else message.error(r.msg);
            })
        })
    }
    const quote=()=>{
        setQuoting(true);
        exe("QuoteCredit",{creditId:props.match.params.creditId,action:"QUOTE"}).then(r=>{
            setQuoting(false);
            if(r.ok){
                message.success(r.msg);
                reload();
                doReloadChild(c=>c+1);
            }else message.error(r.msg);
        })
    }
    const issue=()=>{
        setIssuing(true);
        exe("IssueCredit",{creditId:props.match.params.creditId}).then(r=>{
            setIssuing(false);
            if(r.ok){
                message.success(r.msg);
                reload()
            }else message.error(r.msg);
        })
    }
    const selectProduct=(v)=>{
        const parsedProduct=safeParse(v.configJson);
        setProduct(parsedProduct);
        const formId=safeGet(["Credit","formId"],parsedProduct,null);
        setFormId(formId);
        
        if(values.id) return; //already saved
        
        //setting default values defined in product, before saving
        if(safeGet(["Credit","currency"],parsedProduct,false))
        {
            props.form.setFieldsValue({currency:parsedProduct.Credit.currency})
        }
        if(safeGet(["Credit","interest"],parsedProduct,false))
        {
            props.form.setFieldsValue({interest:parsedProduct.Credit.interest})
        }
        if(safeGet(["Credit","capitalizationMode"],parsedProduct,false))
        {
            props.form.setFieldsValue({capitalizationMode:parsedProduct.Credit.capitalizationMode})
        }
        if(safeGet(["Credit","capitalizationFrequency"],parsedProduct,false))
        {
            props.form.setFieldsValue({capitalizationFrequency:parsedProduct.Credit.capitalizationFrequency})
        }
        if(safeGet(["Credit","installmentSchemeId"],parsedProduct,false))
        {
            props.form.setFieldsValue({installmentSchemeId:parsedProduct.Credit.installmentSchemeId})
        }
        if(safeGet(["Credit","frequency"],parsedProduct,false))
        {
            props.form.setFieldsValue({frequency:parsedProduct.Credit.frequency})
        }
        if(safeGet(["Credit","installments"],parsedProduct,false))
        {
            props.form.setFieldsValue({installments:parsedProduct.Credit.installments})
        }
    }
    const reEnter=(id=props.match.params.creditId)=>window.location="#/credit/"+id;
    const reload=()=>load(props.match.params.creditId);
    const userActions = process ? safeParse(process.userActions) || [] : [];
    const field=props.form.getFieldDecorator;
    const values=props.form.getFieldsValue();
    const titleCode=values.id?(values.code?values.code:values.id):t("New");
  
    return (
        <div>
            <DefaultPage
                title={t("Credit")+" "+titleCode}
                icon="credit-card"
                tags={<Status process={process} color={getColor(process && process.entityState)} reload={() => reload()} />}
                routes={{
                    routes: [
                        { breadcrumbName: t("Home"), path: "/" },
                        { breadcrumbName: t("Credit List"), path: "creditList" },
                        { breadcrumbName: t("Credit"), path: "" },
                    ],
                }}
                content={<div><CreditHeader credit={values} contact={contact}/></div>}
                extra={<div>
                    <TaskSelect entity="credit" entityId={values.id} entityPath={ "credit/" + values.id} />
                    <ButtonGroup>
                        <Button
                            loading={loading}
                            onClick={()=>reload()}
                            icon={<LegacyIcon type={"reload"} />}>
                            {t("Reload")}
                        </Button>
                        <Button 
                            type={!userActions.includes("QUOTE") && !userActions.includes("ISSUE") ? "primary" : "default"}
                            disabled={values.active||values.blocked}
                            loading={loading}
                            onClick={()=>save()} 
                            icon={<LegacyIcon type={"save"} />}>
                            {t("Save")}
                        </Button>
                        <Button
                            type="primary"
                            disabled={!userActions.includes("QUOTE")}
                            loading={quoting}
                            onClick={quote}
                            icon={<EuroOutlined />}>
                            {t("Quote")}
                        </Button>
                        <Button
                            type="primary"
                            disabled={!userActions.includes("ISSUE")}
                            loading={issuing}
                            onClick={issue}
                            icon={<RocketOutlined />}>
                            {t("Issue")}
                        </Button>
                    </ButtonGroup>
            </div>}>
                <Tabs activeKey={selectedTabKey} onChange={v=>setSelectedTabKey(v)}>
                    <Tabs.TabPane tab={t("Main")} key="main">
                        <Row gutter={16}>
                            <Col span={6}>
                                {field("id")}
                                {field("code")}
                                {field("processId")}
                                {field("entityState")}
                                {field("formId")}
                                {field("jForm")}
                                {field("active")}
                                {field("blocked")}
                                {field("externalId")}
                                <Form.Item label={t("Credit Product")}>
                                    {field("productCode",{rules:[{required:true}]})(<CreditProductSelect  forceSelect={selectProduct} />)}
                                </Form.Item>
                                {field("productCode")(<HiddenField name="productCode"  />)}
                                <Form.Item label={t("Start")}>
                                    {field("start",{rules:[{required:true}]})(<DatePickerW style={{width:"100%"}} />)}
                                </Form.Item>
                                {field("start")(<HiddenField name="start"  />)}
                                <Form.Item label={t("Created")}>
                                    {field("created",{initialValue:new moment() })(<DatePickerW disabled style={{width:"100%"}} />)}
                                </Form.Item>
                              
                            </Col>
                            <Col span={10}>
                                <Form.Item label={t("Contact")}>
                                    {field("contactId",{rules:[{required:true}]})(<SimpleContactSelect onSelectContact={c=>setContact(c)} forceSelect />)}
                                </Form.Item>
                                {field("contactId")(<HiddenField name="contactId"  />)}
                                <Form.Item label={t("Policy")}>
                                    {field("policyId")(<ContactPoliciesSelect contactId={values.contactId} role={"insured"} />)}
                                </Form.Item>
                                {field("policyId")(<HiddenField name="policyId"  />)}
                            </Col>
                            <Col span={8} />
                        </Row>
                        <Button type="primary" onClick={()=>setSelectedTabKey("conditions")} style={{marginTop:10}}>{t("Next")}
                            <RightOutlined />
                        </Button>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Conditions")} key="conditions">
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item label={t("Currency")}>
                                    {field("currency",{rules:[{required:true}]})(<Currency style={{width:"100%"}}  />)}
                                </Form.Item>
                                {field("currency")(<HiddenField name="currency"  />)}
                                <Form.Item label={t("Capital")}>
                                    {field("capital",{rules:[{required:true}]})(<Money currency={values.currency} min={0} />)}
                                </Form.Item>
                                {field("capital")(<HiddenField name="capital"  />)}
                            
                                <Form.Item label={t("Capitalization")}>
                                    {field("capitalizationMode")(<CapitalizationModeSelect />)}
                                </Form.Item>  
                                <Form.Item label={t("Capitalization Frequency")}>
                                    {field("capitalizationFrequency")(<FrequencySelect style={{width:"100%"}}/>)}
                                </Form.Item>
                                <Form.Item label={t("Interest Rate")}>
                                    {field("interest")(<InputNumber />)}
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label={t("Installment Scheme")} >
                                    {field("installmentSchemeId")(<InstallmentSchemeSelect style={{width:"100%"}} />)}
                                </Form.Item>
                                <Form.Item label={t("Frequency")}>
                                    {field("frequency",{rules:[{required:true}]})(<FrequencySelect style={{width:"100%"}} />)}
                                </Form.Item>
                                <Form.Item label={t("Installments")}>
                                    {field("installments",{rules:[{required:true}]})(<InputNumber min={1} />)}
                                </Form.Item>
                                <Form.Item label={t("Total")}>
                                    {field("total")(<InputNumber disabled min={0} />)}
                                </Form.Item>
                            </Col>
                            <Col span={8}></Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Product Form")} key="customForm" disabled={!formId} forceRender>
                        <CustomForm formId={formId} variable="customCreditForm" value={values.jForm} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Charges")} key="charges" disabled={!values.id}>
                        <CreditCharges creditId={values.id} currency={values.currency} readOnly={values.active||values.blocked} reload={reloadChild}  />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Accounts")} key="accounts" disabled={!values.id}>
                        {field("Accounts")(<Accounts />)}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Installments")} key="installments" disabled={!values.id}>
                        <CreditInstallments creditId={values.id} reload={reloadChild} onPay={reload} />
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        key="tasks"
                        tab={t("Tasks")}>
                        <EntityTasks entity="credit" entityId={values.id} />
                    </Tabs.TabPane>
                </Tabs>
            </DefaultPage>
        </div>
    );
};

export default Form.create()(Credit);