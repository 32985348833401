import { exe } from "./Lib/Dal";

//Custom backend for SISos translations
class Backend {
  constructor(services, backendOptions = {}, i18nextOptions = {}) {
    this.init(services, backendOptions, i18nextOptions);
  }
  init(services, backendOptions, i18nextOptions) {
    // Validate backendOptions
    console.log("------INIT");
    this.pendingNewKeys = [];
  }
  translationsLoaded = false;
  read(language, namespace, callback) {
    /* return resources */
    console.log(language, namespace, "------READ");
    if (!localStorage.token) callback(null, {});
    exe("GetTranslation", { language: language, section: namespace }).then((r) => {
      if (r.ok) {
        this.translationsLoaded = true;
        callback(null, r.outData);
      } else {
        console.error(r.msg);
        callback(null, {});
      }
    });

    /* if method fails/returns an error, call this: */
    /* callback(truthyValue, null); */
  }
  // optional
  readMulti(languages, namespaces, callback) {
    /* return multiple resources - useful eg. for bundling loading in one xhr request */
    console.log(languages, namespaces, "------READ MULTUI");
    callback(null, {
      en: {
        translations: {
          key: "value",
        },
      },
      de: {
        translations: {
          key: "value",
        },
      },
    });
  }
  // only used in backends acting as cache layer
  save(language, namespace, data) {
    // store the translations
  }
  pendingNewKeys;
  create(languages, namespace, key, fallbackValue) {
    /* save the missing translation */
    if (!localStorage.token) return;
    if (!localStorage.language) return;
    if (!this.translationsLoaded) return; //not yet loaded
    console.log("Found new keys at " + window.document.location.hash + " sending pending in 5s");
    this.pendingNewKeys.push(key);
    if (this.pendingNewKeys.length == 1) {
      //creating 5s batch with the first found key
      const me = this;
      setTimeout(() => {
        exe("AddMissingKey", { keys: JSON.stringify(me.pendingNewKeys), location: window.document.location.hash }).then((r) => {
          me.pendingNewKeys = [];
          if (r.ok) {
            console.log("Keys Added");
          } else {
            console.error(r.msg);
          }
        });
      }, 5000);
    }
  }
}
Backend.type = "backend";
export default Backend;
