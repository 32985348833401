import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  Tag,
  Switch,
  Checkbox,
  InputNumber,
  message,
} from "antd";
import { exe } from "../../Lib/Dal";
import moment from "moment";
import Compare from "../Shared/Compare";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const AuditLogFilter = (props) => {
  const [t] = useTranslation();
  const [matches, setMatches] = useState(null);

  useEffect(() => {
    submit();
  }, [props.pagination.current, props.refresh]);

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      console.log(values, "FORM VALUES");
      let queries = [];
      if (values.date != undefined)
        queries.push(`YEAR(date)=${values.date.format("YYYY")} AND MONTH(date)=${values.date.format("M")} AND DAY(date)=${values.date.format("D")}`);
      if (values.user) queries.push(`[user]='${values.user}'`);
      if (values.excludeSupervisor) queries.push(`[user]<>'SUPERVISOR'`);
      if (values.result != undefined) queries.push(`ok=${values.result}`);
      if (values.command) queries.push(`cmd='${values.command}'`);
      if (values.texe != undefined) queries.push(`texe${values.texe}`);
      const filterQuery = queries.join(" AND ");

      exe("GetAuditLog", {
        operation: "GET",
        filter: filterQuery,
        size: props.pagination.pageSize,
        page: props.pagination.current - 1,
      }).then((r) => {
        if (r.ok) {
          props.onResults(r.outData, r.total);
          setMatches(r.total);
        } else {
          message.error(r.msg);
        }
      });
    });
  };

  return (
    <div>
      <Drawer title={t("Log Filter Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Form.Item label={t("Date")}>{getFieldDecorator("date")(<DatePickerW />)}</Form.Item>
          <Form.Item label={t("User")}>{getFieldDecorator("user")(<Input allowClear />)}</Form.Item>
          <Form.Item label={t("Command")}>{getFieldDecorator("command")(<Input allowClear />)}</Form.Item>
          <Form.Item label={t("Result")}>
            {getFieldDecorator("result")(
              <Select allowClear>
                <Select.Option value={1}>{t("Succesful")}</Select.Option>
                <Select.Option value={0}>{t("Unsuccessful")}</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={t("Execution Time")}>{getFieldDecorator("texe")(<Compare />)}</Form.Item>
        </Form>
        <Form.Item label={t("Exclude Supervisor")}>{getFieldDecorator("excludeSupervisor",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(AuditLogFilter);
