import { CalculatorOutlined, FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import {Button, Checkbox, message, Table, Tabs} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import ContactLink from "../Contact/ContactLink";
import DefaultPage from "../Shared/DefaultPage";
import CoCessionsFilter from "./CoCessionsFilter";
import CoLiquidation from "./CoLiquidation";
import CoClaimsFilter from "./CoClaimsFilter";
import CurrencyCell from "../Shared/CurrencyCell";

const CoCessions = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [claimData, setClaimData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [claimFilterVisible, setClaimFilterVisible] = useState(false);
  const [reload, doReload] = useState(0);
  const [selectedRowKeys,setSelectedRowKeys]=useState([]);
  const [selectedRowKeysClaims,setSelectedRowKeysClaims]=useState([]);
  const [liquidationVisible,setLiquidationVisible]=useState(false);
  const [liquidationValues,setLiquidationValues]=useState();
  
  useEffect(()=>{
    loadClaimData()
  },[])

  const loadClaimData=()=>{
    setLoading(true);
    exe("RepoCoLossCession",{operation:"GET",include:["CoCession"]}).then(r=>{
      setLoading(false);
      if(r.ok){
        setClaimData(r.outData);
      }
    })
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: v=>setSelectedRowKeys(v),
    getCheckboxProps: r=>({disabled:(r.liquidationId||r.paidOnCollection)})
  };
  const rowSelectionClaims = {
    selectedRowKeys:selectedRowKeysClaims,
    onChange: v=>setSelectedRowKeysClaims(v),
    getCheckboxProps: r=>({disabled:(r.liquidationId)})
  };
  const onSearch = (d) => {
    setData(d);
  };
  const onSearchClaims = (d) => {
    setClaimData(d);
  };
  const onLiquidation=()=>{
    //selected premiums validation
    const selectedRecords=data.filter(p=>selectedRowKeys.includes(p.id));
    const contactId=selectedRecords[0].contactId;
    if(selectedRecords.some(p=>p.contactId!==contactId)){
      message.error(t("All cessions must have the same coinsurer"));
      return;
    }
    const currency=selectedRecords[0].currency;
    if(selectedRecords.some(p=>p.currency!==currency)){
      message.error(t("All cessions must have the same currency"));
      return;
    }
    if(selectedRecords.some(p=>p.liquidationId)){
      message.error(t("Some cessions have already been liquidated. Please check selection"));
      return;
    }
    //selected claims validations
    const selectedClaimRecords=claimData.filter(p=>selectedRowKeysClaims.includes(p.id));
    if(selectedClaimRecords.length>0) {
      const claimContactId = selectedClaimRecords[0].contactId;
      if (selectedClaimRecords.some(p => p.contactId !== claimContactId)) {
        message.error(t("All claims must have the same coinsurer"));
        return;
      }
      const claimCurrency = selectedClaimRecords[0].currency;
      if (selectedClaimRecords.some(p => p.currency !== claimCurrency)) {
        message.error(t("All claims must have the same currency"));
        return;
      }
    }
    if(selectedClaimRecords.some(p=>p.liquidationId)){
      message.error(t("Some claims have already been liquidated. Please check selection"));
      return;
    }
    
    //liquidation values
    const premiumCeded=selectedRecords.reduce((p,c)=>p+c.premiumCeded,0);
    const commission=selectedRecords.reduce((p,c)=>p+c.commission,0);
    const tax=selectedRecords.reduce((p,c)=>p+c.tax,0);
    const brokerCommission=selectedRecords.reduce((p,c)=>p+c.brokerCommission,0);
    const claimsCeded=selectedClaimRecords.reduce((p,c)=>p+c.cededLoss,0)
    const amount=premiumCeded-commission+tax-brokerCommission-claimsCeded;
    
    
    setLiquidationValues({
      premiumCeded:premiumCeded,
      commission:commission,
      tax:tax,
      brokerCommission:brokerCommission,
      claimsCeded:claimsCeded,
      amount:amount,
      currency:currency,
      contactId:contactId,
      selectedCessions:selectedRowKeys,
      selectedClaims:selectedRowKeysClaims,
    });
    setLiquidationVisible(true);
  }
  const onLiquidationSubmit=()=>{
    doReload(p=>p+1);
    setLiquidationVisible(false);
    setSelectedRowKeys([]);
    setSelectedRowKeysClaims([]);
  } 
  
  return (
    <div>
      <DefaultPage
        title={t("Coinsurance Cessions")}
        icon="pie-chart"
        extra={
          <div>
            <Button type="link" icon={<ReloadOutlined />} onClick={() => doReload((p) => p + 1)}>
              {t("Reload")}
            </Button>
            <Button type="primary" icon={<CalculatorOutlined />} onClick={onLiquidation} disabled={selectedRowKeys.length===0}>{t("Liquidation")}</Button>
          </div>
        }>
       
        <Tabs>
          <Tabs.TabPane tab={t("Cessions")} key={"cessions"}>
            <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
              {t("Filter")}
            </Button>
            <Table dataSource={data} loading={loading} rowSelection={rowSelection} rowKey="id">
              <Table.Column title={t("Id")} dataIndex="id" />
              <Table.Column title={t("Policy")} dataIndex="lifePolicyId" render={(v) => <a href={"#/lifepolicy/" + v}>{v}</a>} />
              <Table.Column title={t("Active")} dataIndex="policyActive" render={(v) => <Checkbox checked={v} />} />
              <Table.Column title={t("Coinsurer")} dataIndex="coinsurerName" render={(v, r) => <ContactLink contact={{ id: r.contactId, FullName: v }} />} />
              
              <Table.Column title={t("Sum Insured")} dataIndex="sumInsured" render={(v,r)=><CurrencyCell value={v} currency={r.currency} />} />
              <Table.Column title={t("Premium")} dataIndex="premium" render={(v,r)=><CurrencyCell value={v} currency={r.currency} />} />
              <Table.Column title={t("Sum Insured Ceded")} dataIndex="sumInsuredCeded" render={(v,r)=><CurrencyCell value={v} currency={r.currency} />} />
              
              <Table.Column title={t("Premium Ceded")} dataIndex="premiumCeded" render={(v,r)=><CurrencyCell value={v} currency={r.currency} />} />
              <Table.Column title={t("Tax")} dataIndex="tax" render={(v,r)=><CurrencyCell value={v} currency={r.currency} />} />
              <Table.Column title={t("Broker Commission")} dataIndex="brokerCommission" render={(v,r)=><CurrencyCell value={v} currency={r.currency} />} />
              <Table.Column title={t("Commission")} dataIndex="commission" render={(v,r)=><CurrencyCell value={v} currency={r.currency} />} />
              
              <Table.Column title={t("Percentage")} dataIndex="percentage" />
              <Table.Column title={t("Leader")} dataIndex="leader" render={(v) => <Checkbox checked={v} />} />
              <Table.Column title={t("Created")} dataIndex="created" render={(v, r) => <span style={{whiteSpace:"nowrap"}}> {formatDateShort(v)}</span>} />
              <Table.Column title={t("Liquidation")} dataIndex="liquidationId"  />
              <Table.Column title={t("Allocation")} dataIndex="allocationId"  />
              <Table.Column title={t("Payable")} dataIndex="paidOnCollection" render={(v) => <Checkbox checked={!v} />}  />
              <Table.Column title={t("Overwritten")} dataIndex="overwritten" render={(v) => <Checkbox checked={v} />} />
              <Table.Column title={t("Coverage")} dataIndex="Coverage" render={v=>v?v.code:t("Policy Level")} />
              <Table.Column title={t("Broker")} dataIndex="Broker" render={v=>v?v.FullName:null} />
              <Table.Column title={t("Broker Commission")} dataIndex="brokerCommission" />
            </Table>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Claims")} key={"claims"}>
            <Button type="link" icon={<FilterOutlined />} onClick={() => setClaimFilterVisible(true)}>
              {t("Filter")}
            </Button>
            <Table dataSource={claimData} loading={loading} rowSelection={rowSelectionClaims}  rowKey="id">
              <Table.Column title={t("Id")} dataIndex="id" />
              <Table.Column title={t("Claim")} dataIndex="claimId" render={(v) => <a href={"#/claim/" + v}>{v}</a>} />
              <Table.Column title={t("Occurrence")} dataIndex="claimOccurrence" render={(v, r) => <span style={{whiteSpace:"nowrap"}}> {formatDateShort(v)}</span>} />
              <Table.Column title={t("Policy")} dataIndex={["CoCession","lifePolicyId"]} render={(v) => <a href={"#/lifepolicy/" + v}>{v}</a>} />
              <Table.Column title={t("Coverage")} dataIndex="coverageName" />
              <Table.Column title={t("Coinsurer")} dataIndex="coinsurerName" render={(v, r) => <ContactLink contact={{ id: r.contactId, FullName: v }} />} />
              <Table.Column title={t("Percentage")} dataIndex={["CoCession","percentage"]} />
              <Table.Column title={t("Reserved")} dataIndex="reserve" render={(v, r) => <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />
              <Table.Column title={t("Retained Reserve")} dataIndex="retainedReserve" render={(v, r) => <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />
              <Table.Column title={t("Ceded Reserve")} dataIndex="cededReserve" render={(v, r) => <span style={{whiteSpace:"nowrap"}}>{formatMoney(v, r.currency)}</span>} />
              <Table.Column title={t("Loss")} dataIndex="loss" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Retained Loss")} dataIndex="retainedLoss" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Ceded Loss")} dataIndex="cededLoss" render={(v, r) => formatMoney(v, r.currency)} />
              <Table.Column title={t("Liquidation")} dataIndex="liquidationId"  />
            </Table>
          </Tabs.TabPane>
        </Tabs>
        <CoCessionsFilter visible={filterVisible} onClose={() => setFilterVisible(false)} onSearch={onSearch} doReload={reload} />
        <CoClaimsFilter visible={claimFilterVisible} onClose={() => setClaimFilterVisible(false)} onSearch={onSearchClaims} doReload={reload} />
        <CoLiquidation visible={liquidationVisible} onClose={() => setLiquidationVisible(false)} onOk={onLiquidationSubmit}  liquidationValues={liquidationValues} />
      </DefaultPage>
    </div>
  );
};

export default CoCessions;
