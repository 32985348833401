import React, { Component } from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Table,
  Empty,
  Button,
  Modal,
  Badge,
  Dropdown,
  Select,
  Popover,
  Divider,
  Checkbox,
  Switch,
} from "antd";
import ManualSurcharge from "./ManualSurcharge";
import { withTranslation } from "react-i18next";
import SurchargeEdit from "./SurchargeEdit";
const { Column, ColumnGroup } = Table;

class Surcharges extends Component {
  state = {
    selectedKeys: [],
    editRecord: undefined,
      hideNoValueRecords:false
  };
  onAdd() {
    this.setState({ addVisible: false });
  }
  onSelect(key) {
    this.setState({ selectedKeys: [...this.state.selectedKeys, key] });
  }
  onDeselect(key) {
    this.setState({ selectedKeys: this.state.selectedKeys.filter((p) => p !== key) });
  }
  onChange(e, selectableData, policyData) {
    //reporting to ext4ernal form
    const newData = [...policyData, ...selectableData.filter((p) => e.includes(p.code))];
    this.props.onChange(newData); //dbdata and currentl selected (dirty)
  }
  onRemove(record, policyData) {
    if (record.id) {
      //mark for deletion
      const p = policyData.find((p) => p.id == record.id);
      p.id = p.id * -1;
      this.props.onChange(policyData);
    } else {
      this.props.onChange(policyData.filter((q) => q.code !== record.code));
      this.setState({ selectedKeys: this.state.selectedKeys.filter((p) => p !== record.code) });
    }
  }
  onEdit(record) {
    this.setState({ editRecord: record });
  }
  onEditRecord(editedRecord) {
    this.setState({ editRecord: undefined });
    this.props.onChange([...this.props.value.filter((q) => q.code !== editedRecord.code), editedRecord]);
  }
  render() {
    const { t, i18n } = this.props;
    const configData = this.props.config ? (this.props.config.Surcharges ? this.props.config.Surcharges : []) : [];
    let policyData = this.props.value || []; //passed by DB or by initial form value or props (updated onchange)
    const selectableData = configData.filter((p) => !p.mandatory && !policyData.map((q) => q.code).includes(p.code)); //optional and not already included
      const visibleData=this.state.hideNoValueRecords?policyData.filter(p=>p.value):policyData;
    return (
      <div style={{ marginTop: 5, marginBottom: 5 }}>
        <Popover
          trigger="click"
          title={t("Add Surcharge")}
          placement="right"
          content={
            <div style={{width:"100%"}}>
              <Select
                disabled={this.props.readOnly}
                mode="multiple"
                showArrow
                style={{ width: "100%" }}
                placeholder={t("Please select")}
                dropdownMatchSelectWidth={false}
                optionLabelProp="value"
                value={this.state.selectedKeys}
                onChange={(e) => this.onChange(e, selectableData, policyData)}
                onSelect={(key) => this.onSelect(key)}
                onDeselect={(key) => this.onDeselect(key)}>
                {selectableData.map((p) => (
                  <Select.Option value={p.code} key={p.code}>
                    <div>
                      {p.code} {p.name}
                    </div>
                    <div>{p.text}</div>
                  </Select.Option>
                ))}
              </Select>
            </div>
          }>
          <Button type="primary" icon={<PlusOutlined />} disabled={this.props.readOnly}>
            {t("Add")}
          </Button>
        </Popover>

        <ManualSurcharge
          visible={this.state.manualSurchargeVisible}
          onCancel={() => this.setState({ manualSurchargeVisible: false })}
          policy={this.props.policy}
          config={this.props.config}
          okOk={(values) => {
            this.props.onChange([
              ...policyData,
              {
                code: "MAN" + policyData.filter((p) => p.mandatory == 2).length,
                mandatory: 2,
                name: values.name,
                description: values.description,
                value: values.value,
                coverageCode:values.coverageCode,
                duration: values.duration,
                start: values.start.format("YYYY-MM-DD"),
                end: values.end.format("YYYY-MM-DD"),
              },
            ]);
            this.setState({ manualSurchargeVisible: false });
          }}
        />
        <Button type="link" icon={<EllipsisOutlined />} onClick={() => this.setState({ manualSurchargeVisible: true })} disabled={this.props.readOnly}>
          {t("Manual")}
        </Button>

          {t("Hide 0 value")}:
          <Switch style={{marginLeft:5}} checked={this.state.hideNoValueRecords} onChange={v=>this.setState({hideNoValueRecords:v})} />

        <Table rowKey="code" dataSource={visibleData} style={{ marginTop: 5 }} scroll={{ x: 1300 }}>
          <Column title={t("Code")} dataIndex="code" width={100} />
          <Column title={t("Name")} dataIndex="name" />
          <Column title={t("Description")} dataIndex="description" />
          <Column title={t("Automatic")} dataIndex="mandatory" render={(t) => <LegacyIcon type={t == 1 ? "lock" : t == 2 ? "user" : "unlock"} />} />
          <Column title={t("Value")} dataIndex="value" />
          <Column title={t("Status")} dataIndex="id" render={(t) => <Badge status={t > 0 ? "success" : t < 0 ? "error" : "warning"} />} />
            <Column title={t("Coverage")} dataIndex="coverageCode" />
          <Column title={t("Rule")} dataIndex="rule" />
          <Column
            title={t("Action")}
            fixed="right"
            render={(p, record) => (
              <div>
                <Button type="link" disabled={record.mandatory == 1 || this.props.readOnly} onClick={(r) => this.onRemove(record, policyData)}>
                  {t("Remove")}
                </Button>
                <Divider type="vertical" />
                <Button
                  type="link"
                  disabled={record.mandatory==1&&configData.filter((q) => q.code == record.code && q.editable).length == 0 || this.props.readOnly}
                  onClick={(r) => this.onEdit(record)}>
                  {t("Edit")}
                </Button>
              </div>
            )}
          />
        </Table>
        <SurchargeEdit
          visible={this.state.editRecord}
          value={this.state.editRecord}
          onCancel={() => this.setState({ editRecord: undefined })}
          onOk={(r) => this.onEditRecord(r)}
          config={this.props.config}
          policy={this.props.policy}
        />
      </div>
    );
  }
}

export default withTranslation()(Surcharges);
