import React, {useEffect, useState, useTransition} from 'react';
import {Select} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";

const BeneficiaryBranchSelect = (props) => {
    const [data,setData]=useState([]);
    const [t]=useTranslation();
    useEffect(()=>{
        if(props.contactId) loadContactBranches();
    },[props.contactId])
    
    const loadContactBranches=()=>{
        exe("RepoContactBranch",{operation:"GET",filter:`contactId=${props.contactId}`}).then(r=>{
            if(r.ok){
                setData(r.outData)
            }
        })
    }
    return (
        <div>
            <Select value={props.value} onChange={v=>props.onBranchChange(v)} allowClear style={{marginTop:3}} placeholder={t("Select Branch")}>
                {data.map(p=><Select.Option key={p.id} value={p.id}>{p.name}</Select.Option>)}
            </Select>
        </div>
    );
};

export default BeneficiaryBranchSelect;