import React, { useState, useEffect } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { exe, safeGet } from "../../Lib/Dal";
import { FileOutlined, MessageOutlined, RedoOutlined, SaveOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Tag, Button, Row, Col, Input, message, Tabs, Badge } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import InvestmentPlanSelect from "../InvestmentPlans/InvestmentPlanSelect";
import AccountMov from "./AccountMov";
import Title from "antd/lib/typography/Title";
import Operations from "./Operations";
import MasterDetail from "../Shared/MasterDetail";
import ConvertToCheck from "./ConvertToCheck";
import Currency from "../Shared/Currency";
import queryString from "query-string";
import AccountTypeSelect from "./AccountTypeSelect";
import BankSelect from "../Contact/BankSelect";
import Money from "../Shared/Money";
import BankAccountTypeSelect from "./BankAccountType";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ExchangeOperations from "./ExchangeOperations";
import ExchangeOperationNew from "./ExchangeOperationNew";
import { getConfigProfile } from "../../Lib/Helpers";
import Comments from "../Life/Comments";
import { isValidIBAN, electronicFormatIBAN } from "ibantools";
import BranchSelect from "./BranchSelect";
import AccountSelectDetail from "../Accounting/AccountSelectDetail";
import DatePickerW from "../Shared/DatePickerW";
import CheckBookSelect from "./CheckBookSelect";
import PensionAccountTypeSelect from "../Pensions/PensionAccountTypeSelect";

const Account = (props) => {
  const [t, i18n] = useTranslation();
  const [account, setAccount] = useState();
  const [operation, setOperation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("info");
  const [exchangeOperationVisible, setExchangeOperationVisible] = useState(false);
  const [reload, doReload] = useState(0);
  const [operationTypes, setOperationTypes] = useState([]);
  const [activityPanel, setActivityPanel] = useState(false);
  const [numberOfComments, setNumberOfComments] = useState(0);

  const field = props.form.getFieldDecorator;
  const accountId = props.form.getFieldValue("id") || 0;
  const currency = props.form.getFieldValue("currency");
  const entityState = "Active"; //temp
  const accountOperations = operationTypes.map((p) => ({ ...p, title: p.name, onPlay: () => onOperation(p.code) }));

  const onOperation = (selectedOperation) => {
    setOperation(selectedOperation);
    setExchangeOperationVisible(true);
  };
  useEffect(() => {
    if (props.match.params.accountId !== "0") {
      load(props.match.params.accountId);
    } else {
      props.form.resetFields();
      props.form.setFieldsValue({ id: 0 });
    }
  }, [props.match.params.accountId]);

  useEffect(() => {
    if (window.location.hash.indexOf("tab=") > 0) {
      const qs = queryString.parse(window.location.hash);
      console.log(qs, "QUERY");
      setActiveTab(qs.tab);
    }
  }, [window.location.hash]);

  useEffect(() => {
    getConfigProfile().then((profile) => {
      setOperationTypes(safeGet(["Exchange"], window.global.configProfile, []));
    });
  }, []);
  const load = (id) => {
    setLoading(true);
    exe("RepoAccount", { operation: "GET", include: ["Movements"], filter: "ID=" + id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        if (r.outData.length == 0) {
          message.error(`${t("Record")} ${id} ${t("not found")}`);
          return;
        }
        props.form.setFieldsValue(r.outData[0]);
        setAccount(r.outData[0]);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      exe("RepoAccount", { operation: values.id == 0 ? "ADD" : "UPDATE", entity: values }, null, true).then((r) => {
        if (r.ok && props.onOk) props.onOk();
        if (!props.onOk) {
          props.form.setFieldsValue(r.outData[0]);
          setAccount(r.outData[0]);
        }
      });
    });
  };
  const onReload = () => {
    load(props.match.params.accountId);
  };
  const onNewAccount = () => (window.location = "#/Account/0");
  const onExchangeOperation = (record) => {
    record.accountId = props.match.params.accountId;
    record.currency = values.currency;

    setLoading(true);
    exe("RepoExchangeOperation", { operation: "ADD", entity: record }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setExchangeOperationVisible(false);
        setActiveTab("operationLog");
        doReload((v) => v + 1);
        onReload();
      } else message.error(r.msg);
    });
  };
  const validateIban = (r, v, cb) => {
    if (!v) return cb(); //empty field
    const iban = electronicFormatIBAN(v); //with spaces or without spaces
    if (isValidIBAN(iban)) cb();
    else cb(t("Invalid IBAN"));
  };
  const values = props.form.getFieldsValue();
  return (
    <div>
      <DefaultPage
        routes={{
          routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Accounts"), path: "Accounts" },
            { breadcrumbName: t("Account"), path: "" },
          ],
        }}
        loading={loading}
        title={
          accountId == 0 ? (
            t("New Account")
          ) : (
            <span>
              {`${t("Account")} ${accountId}  `}
              <Tag>{entityState}</Tag>
            </span>
          )
        }
        extra={
          <div>
            <Button type="primary" icon={<SaveOutlined />} onClick={() => onSave()}>
              {t("Save")}
            </Button>
            <Button style={{ marginLeft: 5 }} icon={<FileOutlined />} onClick={() => onNewAccount()}>
              {t("New")}
            </Button>
            <Button style={{ marginLeft: 5 }} icon={<RedoOutlined />} onClick={() => onReload()}>
              {t("Reload")}
            </Button>
            <Button style={{ marginLeft: 5 }} onClick={() => setActivityPanel(!activityPanel)} icon={<MessageOutlined />}>
              {t("Comments")} <Badge count={numberOfComments} style={{ marginLeft: 5 }}></Badge>
            </Button>
          </div>
        }
        icon="global">
        {activityPanel && (
          <div style={{ position: "absolute", right: 0, top: -15, zIndex: 9 }}>
            <Button type="link" icon={<LegacyIcon type={"close"} />} onClick={() => setActivityPanel(false)} className="collapseButton"></Button>
          </div>
        )}
        <Row gutter={32}>
          <Col span={activityPanel ? 16 : 24}>
            <Form layout="vertical">
              <Tabs onChange={(tab) => setActiveTab(tab)} activeKey={activeTab}>
                <Tabs.TabPane tab={t("Info")} key="info">
                  <Title style={{ marginTop: 5 }} level={4}>
                    {t("Account Info")}
                  </Title>
                  <Row gutter={16}>
                    <Col span={8}>
                      {field("code")(<Input type="hidden" />)}
                      <Form.Item label={t("Id")}>
                        {field("id", { rules: [{ required: true }] })(<Input disabled={props.match.params.accountId == 0} />)}
                      </Form.Item>
                      <Form.Item label={t("Holder")}>{field("holderId", { rules: [{ required: true }] })(<SimpleContactSelect />)}</Form.Item>
                      <Form.Item label={t("Policy")}>{field("lifePolicyId")(<Input />)}</Form.Item>
                      <Form.Item label={t("Opening Date")}>
                        {field("bankAccountOpenDate", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW style={{ width: "100%" }} />)}
                      </Form.Item>
                      <Form.Item label={t("IBAN")}>{field("iban", { rules: [{ validator: validateIban }] })(<Input />)}</Form.Item>
                      <Form.Item label={t("Checkbook")}>{field("checkBookCode")(<CheckBookSelect />)}</Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label={t("Financial Product")}>{field("investmentPlanCode")(<InvestmentPlanSelect />)}</Form.Item>
                      <Form.Item label={t("AccNo")}>{field("accNo")(<Input />)}</Form.Item>
                      <Form.Item label={t("Name")}>{field("name")(<Input />)}</Form.Item>
                      <Form.Item label={t("Opening Amount")}>{field("openingAmount")(<Money currency={currency} />)}</Form.Item>
                      <Form.Item label={t("Branch")}>{field("branchCode")(<BranchSelect  />)}</Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item label={t("Currency")}>{field("currency", { rules: [{ required: true }] })(<Currency />)}</Form.Item>
                      <Form.Item label={t("Type")}>{field("type")(<AccountTypeSelect />)}</Form.Item>
                      <div style={{display:values.type==="PEN"?"block":"none"}}>
                        <Form.Item label={t("Pension Account Type")}>{field("pensionAccountType")(<PensionAccountTypeSelect />)}</Form.Item>
                      </div>
                      <Form.Item label={t("Bank")}>{field("bankCode")(<BankSelect />)}</Form.Item>
                      <Form.Item label={t("Bank Account Type")}>{field("bankAccountType")(<BankAccountTypeSelect />)}</Form.Item>
                      <Form.Item label={t("Accounting Account")}>{field("catalogAccountCode")(<AccountSelectDetail detailOnly />)}</Form.Item>
                    </Col>
                  </Row>
                  <Title style={{ marginTop: 5 }} level={4}>
                    {t("Movements")}
                  </Title>
                  {field("Movements")(<AccountMov currency={currency} account={account} />)}
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("Operations")} key="operations">
                  <Operations value={accountOperations} />
                  {/* <MasterDetail activeKey={operation} onBack={() => setOperation("")} master={}>
                <ConvertToCheck key="CHECK" />
                <div key="CASH">CASH INTERFACE</div>
                <div key="EWALLET">EWALLET INTERFACE</div>
                <div key="CRYPTO">CRYPTO INTERFACE</div>
                <div key="API">API INTERFACE</div>
              </MasterDetail> */}
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("Operation Tasks")} key="operationLog">
                  <ExchangeOperations accountId={props.match.params.accountId} reload={reload} />
                </Tabs.TabPane>
              </Tabs>
            </Form>
          </Col>
          <Col span={activityPanel ? 8 : 0}>
            <Tabs>
              <Tabs.TabPane
                forceRender
                key="activityPanel"
                tab={
                  <span>
                    <MessageOutlined />
                    {t("Comments")}
                  </span>
                }>
                <Comments accountId={values.id} numberOfComments={(q) => setNumberOfComments(q)} />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
        <ExchangeOperationNew
          visible={exchangeOperationVisible}
          operation={operation}
          onCancel={() => setExchangeOperationVisible(false)}
          onOk={(v) => onExchangeOperation(v)}
          reload={reload}
          currency={currency}
        />
      </DefaultPage>
    </div>
  );
};

export default Form.create()(Account);
