import React, {useEffect, useState} from 'react';
import { CheckOutlined } from '@ant-design/icons';
import {Table, Button, message, Modal, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {exe, safeGet} from "../../Lib/Dal";
import {formatDate, getColor} from "../../Lib/Helpers";
import Status from "../Shared/Status";
import CurrencyCell from "../Shared/CurrencyCell";

const PayoutRequest = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.visible&&props.claimId) load();
    },[props.visible])
    
    const load=()=>{
        setLoading(true);
        exe("RepoPayoutRequest",{operation:"GET",filter:`claimId=${props.claimId}`,include:["Process"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    
    const onSave=values=>{
        let operation;
        if(values.id){
            operation="UPDATE";
        }else{
            operation="ADD"
            values.contractId=props.contractId;
        }
        setLoading(true);
        exe("RepoContractInstallment",{operation:operation,entity:values}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })
        
    }
    const onDelete=record=>{
        setLoading(true);
        exe("RepoContractInstallment",{operation:"DELETE",entity:record}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })

    }
    const getAction = (r) => {
        if (r.status == 0) {
            const enityState = safeGet(["Process", "entityState"], r, "");
            if (enityState == "APROVED") {
                return (
                    <Button icon={<CheckOutlined />} onClick={() => executeRequest(r.id)}>
                        {t("Execute")}
                    </Button>
                );
            } else {
                return <Tag>{t("Pending")}</Tag>;
            }
        } else if (r.status == 1) return <Tag color="green">{t("Executed")}</Tag>;
    };
    const executeRequest=(requestId)=>{
        setLoading(true);
        exe("DoPayoutRequest",{requestId:requestId}).then(r=>{
            setLoading(false);
            if(r.ok){
                props.onOk();
            }else message.error(r.msg)
        })
    }
    return (
        <Modal title={t("Authorization Requests")} visible={props.visible} onCancel={props.onCancel} onOk={props.onOk} width={900}>
                <Table dataSource={data} rowKey={"id"} style={{marginTop:5}} loading={loading}>
                    <Table.Column dataIndex="date" title={t("Date")} render={(v) => formatDate(v)} />
                    <Table.Column dataIndex="user" title={t("User")} />
                    <Table.Column dataIndex="concept" title={t("Concept")} />
                    <Table.Column dataIndex="operation" title={t("Operation")} />
                    <Table.Column dataIndex="reserveType" title={t("Type")} />
                    <Table.Column dataIndex="amount" title={t("Amount")} render={(v,r) => r.reserveType==="EX"?<span style={{color:"blue",whiteSpace:"nowrap"}}>{<CurrencyCell currency={props.currency} value={v} />}</span>:<span style={{whiteSpace:"nowrap"}}>{<CurrencyCell currency={props.currency} value={v} />}</span>} />
                    <Table.Column key="action" dataIndex="id" title={t("Action")} render={(v, r) => getAction(r)} />
                    <Table.Column
                        dataIndex="Process"
                        title={t("Workflow")}
                        render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load()} />}
                    />
                    <Table.Column dataIndex="payoutId" title={t("Approval Id")} />
                </Table>
        </Modal>
    );
};

export default PayoutRequest;