import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const PreferedCommunicationMethod = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => load(), []);

  const load = () => {
    exe("RepoCommunicationsCatalog", { operation: "GET", filter: "field='PREFERED'" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onChange = (v) => {
    props.onChange(JSON.stringify(v));
  };
  const safeParse= (v) => {
    if(v.includes("[")) {
      return JSON.parse(v);
    }else{
      //backward compatibility, before multi select
      return [v];
    }}
    
  const value = props.value?safeParse(props.value):undefined;
  if (props.value == 0) props.onChange(undefined);
  return (
    <div>
      <Select style={{ width: "100%" }} 
              placeholder={t("Please select")} 
              mode="multiple"
              optionFilterProp={"children"}
              onChange={onChange} value={value}>
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default PreferedCommunicationMethod;
