import React, { Component } from "react";
import { SearchOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Divider, message } from "antd";
import { exe } from "Lib/Dal";
import Filter from "./Filter";
import DefaultPage from "../Shared/DefaultPage";
import { withTranslation } from "react-i18next";

const ButtonGroup = Button.Group;
const { Column } = Table;

class ContactList extends Component {
  state = {
    loading: false,
    data: [],
    filter: false, 
      filterObject:{},
    pagination: { pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` },
      
  };
  componentDidMount() {
      this.load();
  }
  load(){
      this.setState({loading:true});
    exe("GetContacts", {...this.state.filterObject, include: ["Tags", "Roles"],size: this.state.pagination.pageSize,page: this.state.pagination.current - 1 }).then((r) => {
      this.setState({loading:false});
        if (r.ok) {
        this.setState({ data: r.outData,pagination:{...this.state.pagination,total:r.total} });
      } else {
        message.error(r.msg);
      }
    });
  }

  filterByTag(tag) {
    //this.setState({data:this.state.data.filter(p=>p.Tags.some(t=>t.tag==tag))});
    exe("GetContacts", { include: ["Tags", "Roles"], tagFilterAny: [tag],size: this.state.pagination.pageSize,page: this.state.pagination.current - 1 }).then((r) => {
      if (r.ok) {
        this.setState({ data: r.outData,pagination:{...this.state.pagination,total:r.total} });
      } else {
        message.error(r.msg);
      }
    });
  }
  onSearch(filterObject)
  {
      this.setState({filterObject:filterObject,pagination:{...this.state.pagination,current:1}},()=>this.load())
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <DefaultPage
        title={t("Contact List")}
        icon="team"
        loading={this.state.loading}
        extra={
          <div>
            <ButtonGroup>
              <Button type="primary" onClick={() => this.setState({ filter: true })} icon={<SearchOutlined />}>
                {t("Filter")}
              </Button>
            </ButtonGroup>
          </div>
        }>
        <Table dataSource={this.state.data} pagination={this.state.pagination} onChange={(pag) => this.setState({pagination:pag},()=>this.load())}>
          <Column title={t("ID")} dataIndex="id" />
          <Column
            title={t("Name")}
            dataIndex="name"
            render={(f, record) => {
              return <a href={"#/contact/" + record.id}>{record.FullName}</a>;
            }}
          />
          <Column title={t("Type")} dataIndex="isPerson" render={(f) => (f ? t("Person") : t("Company"))} />
          <Column
            title={t("Roles")}
            dataIndex="Roles"
            render={(f) => (
              <span>
                {(f || []).map(q=>q.role).filter((v, i, a) => a.indexOf(v) === i).map((role) => (
                  <Tag key={role}>{role}</Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={t("Tags")}
            dataIndex="Tags"
            render={(f) => (
              <span>
                {f.map((tag) => (
                  <Tag color="blue" key={tag.tag} onClick={(p) => this.filterByTag(tag.tag)}>
                    {tag.tag}
                  </Tag>
                ))}
              </span>
            )}
          />
          <Column
            title={t("Action")}
            render={(text, record) => (
              <span>
                <a href={"#/contact/" + record.id}>{t("View")}</a>
              </span>
            )}
          />
        </Table>
        <Filter
          visible={this.state.filter}
          onClose={() => {
            this.setState({ filter: false });
          }}
          onSearch={(q) => this.onSearch(q)}
          pagination={this.state.pagination}
        />
      </DefaultPage>
    );
  }
}
export default withTranslation()(ContactList);
