import React from "react";
import { SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Input, Select, Row, Col, Switch, DatePicker, Button } from "antd";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";

const RequirementDetail = (props) => {
  const [t] = useTranslation();

  const save = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        const operation = values.id == undefined ? "ADD" : "UPDATE";
        exe("RepoLifeRequirement", { entity: values, operation: operation }).then((r) => {
          if (r.ok) {
            message.success(r.msg);
            props.onOk(r.outData[0]);
            props.form.resetFields();
            // const d = this.state.data;
            // if (r.data.operation == "ADD") d.push(values);
            // if (r.data.operation == "UPDATE") d[d.findIndex((p) => p.id == values.id)] = values;

            //this.setState({ visible: false, data: d }, () => this.props.onChange(this.state.data));
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
        console.log(err, "ERROR VAL");
        message.error(t("Please fill all required fields"));
      }
    });
  };

  const { getFieldDecorator } = props.form;

  getFieldDecorator("id");
  getFieldDecorator("lifePolicyId", { initialValue: props.policyId });
  getFieldDecorator("type", { initialValue: "DOC" });
  return (
    <div>
      <Form layout="vertical">
        <Form.Item label={t("Requirement Block Level")}>
          {getFieldDecorator("blockLevel", { rules: [{ required: true }] })(
            <Select style={{ width: "100%" }}>
              <Select.Option value={1}>{t("Required before claim")}</Select.Option>
              <Select.Option value={2}>{t("Required for issuance")}</Select.Option>
              <Select.Option value={3}>{t("Required to proceed")}</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t("Requirement Type")}>
          {getFieldDecorator("type", { rules: [{ required: true }] })(
            <Select style={{ width: "100%" }}>
              <Select.Option value="DOC">{t("Document")}</Select.Option>
              <Select.Option value="CHECK">{t("Check")}</Select.Option>
            </Select>
          )}
        </Form.Item>
        <Row gutter={6}>
          <Col span={6}>
            <Form.Item label={t("Code")}>{getFieldDecorator("code", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item label={t("Document Name")}>{getFieldDecorator("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          </Col>
        </Row>

        <Form.Item label={t("Description")}>
          {getFieldDecorator("comments")(<Input.TextArea rows={4} placeholder={t("Autosize height based on content lines")} />)}
        </Form.Item>
        <Button type="primary" icon={<SaveOutlined />} onClick={save}>
          {t("Save")}
        </Button>
      </Form>
    </div>
  );
};

export default Form.create()(RequirementDetail);
