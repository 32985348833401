import { Icon as LegacyIcon } from '@ant-design/compatible';
import { FileOutlined, FolderOpenOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, message, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import TransactionTemplate from "./TransactionTemplate";

const TransactionTemplates = (props) => {
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [activeTab, setActiveTab] = useState("list");
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("RepoTransactionTemplate", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setTemplates(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onOpenTemplate = (record) => {
    setSelectedTemplate(record);
    setActiveTab("template");
    console.log(record, "OPEN");
  };
  const onNew = () => {
    setSelectedTemplate(undefined);
    setActiveTab("template");
  };
  const onDelete = () => {
    setActiveTab("list");
    load();
  };
  const onAdd = (record) => {
    setTemplates([...templates, record]);
  };
  const onUpdate = (record) => {
    setTemplates(templates.map((r) => (r.code == record.code ? record : r)));
  };
  return (
    <DefaultPage title={t("Transaction Templates")} icon="transaction">
      <Tabs activeKey={activeTab} onChange={(v) => setActiveTab(v)}>
        <Tabs.TabPane key="list" tab={t("Template List")}>
          <Button type="link" icon={<FileOutlined />} onClick={onNew}>
            {t("New")}
          </Button>
          <Button type="link" icon={<ReloadOutlined />} onClick={load}>
            {t("Reload")}
          </Button>
          <Table dataSource={templates} loading={loading} size="middle">
            <Table.Column title={t("Code")} dataIndex="code" />
            <Table.Column title={t("Title")} dataIndex="name" />
            <Table.Column title={t("Active")} dataIndex="active" render={(v) => <LegacyIcon type={v ? "check" : "stop"} />} />
            <Table.Column
              title={t("Actions")}
              key="actions"
              render={(v, r) => (
                <span>
                  <Button type="link" icon={<FolderOpenOutlined />} onClick={() => onOpenTemplate(r)}>
                    {t("Open")}
                  </Button>
                </span>
              )}
            />
          </Table>
        </Tabs.TabPane>
        <Tabs.TabPane key="template" tab={t("Transaction Template")}>
          <TransactionTemplate value={selectedTemplate} onAdd={onAdd} onUpdate={onUpdate} onDelete={onDelete} />
        </Tabs.TabPane>
      </Tabs>
    </DefaultPage>
  );
};

export default TransactionTemplates;
