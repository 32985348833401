import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ParentCoverageSelect = (props) => {
  const [t] = useTranslation();
  
  //excluding the current coverage and coverages with a parent coverage
  const data=(props.coverages||[]).filter((p)=>p.code!==props.coverageCode && !p.parent)
  
  return (
    <div>
      <Select
        showArrow
        placeholder={t("Please select parent coverage")}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        allowClear
        showSearch
        optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ParentCoverageSelect
