import React, {useEffect, useState} from "react";
import { Select } from "antd";
import { formatter } from "../Shared/Money";
import { useTranslation } from "react-i18next";
import {exe} from "../../Lib/Dal";

const CoverageSelectLoad = (props) => {
    const [t] = useTranslation();
    const [policy,setPolicy]=useState({Coverages:[]});
    const [loading,setLoading]=useState(false);
    const money = formatter(policy.currency);
    
    const policyId=props.policyId;
    
    useEffect(()=>{
        if(props.policyId) load();}
        ,[props.policyId])
    
    const load=()=>{
        setLoading(true);
        exe("RepoLifePolicy",{operation:"GET",filter:`id=${policyId}`,include:["Coverages"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setPolicy(r.outData[0])
            }
        })
    }

    const Options = policy.Coverages.map((d) => (
        <Select.Option key={d.id} value={d.id} label={d.code + " - " + d.name} >
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div>
                    {d.code} - {d.name}
                </div>
                <i>
                    {money.format(d.limit)}
                </i>
            </div>
        </Select.Option>
    ));
    return (
        <Select
            value={props.value ? +props.value : undefined}
            onChange={props.onChange}
            placeholder={t("Please select coverage")}
            style={props.style}
            allowClear>
            {Options}
        </Select>
    );
};

export default CoverageSelectLoad;
