import React from 'react';
import {Form} from "@ant-design/compatible"
import {Input, Modal} from "antd";
import {useTranslation} from "react-i18next";
import ServerSelect from "./ServerSelect";

const NewWorker = ({visible,onOk,onCancel,form,workers}) => {
    const [t]=useTranslation();
    const field=form.getFieldDecorator;
    
    const onOkInternal=()=>{
        form.validateFields((err,values)=>{
            //checking the worker name is not duplicated
            if(workers.find(x=>x.name===values.name)){
                form.setFields({
                    name:{
                        value:values.name,
                        errors:[new Error(t("Worker name already exists"))]
                    }
                });
                return;
            }
            if(err) return;
            onOk&&onOk(values);
        })
    }
    return (
        <Modal title={t("New Worker")} visible={visible} onOk={onOkInternal} onCancel={onCancel}>
            <Form.Item label={t("Server")}>{field("serverName",{rules:[{required:true}]})(<ServerSelect style={{width:"100%"}} />)}</Form.Item>
            <Form.Item label={t("Name")}>{field("name",{rules:[{required:true}]})(<Input />)}</Form.Item>
        </Modal>
    );
};

export default Form.create()(NewWorker);