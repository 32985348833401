import React, {useEffect, useState} from 'react';
import { DeleteOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, InputNumber, Popconfirm, Row } from "antd";

import Currency from "../Shared/Currency";
import {useTranslation} from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {getConfigProfile} from "../../Lib/Helpers";
import {safeGet} from "../../Lib/Dal";

const SubParticipantForm = (props) => {
    const [t]=useTranslation();
    const [contactRoles, setContactRoles] = useState([]);
    
    useEffect(()=>{
        if(props.selected) props.form.setFieldsValue(props.selected);
    },[props.selected])
    useEffect(() => {
        getConfigProfile().then((profile) => {
            setContactRoles(safeGet(["Reinsurance", "contactRoles"], window.global.configProfile, []));
        });
    }, []);
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onSave(values);
        })
    }
    const onDelete=()=>{
        props.onDelete(props.selected);
    }
    
    const field=props.form.getFieldDecorator;
    return (
        <div>
            <Row gutter={16}>
                <Col span={24}>
                    <Button type="link" icon={<LeftOutlined />} onClick={props.onBack}>{t("Back")}</Button> 
                    <Button type="link" icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
                    {props.selected.id>0&&<Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={onDelete}>
                        <Button type="link" icon={<DeleteOutlined />}>{t("Delete")}</Button>
                    </Popconfirm>}

                    {field("id")}
                    {field("participantId")}

                    <Form.Item label={t("Participant")}>
                        {field("contactId", { rules: [{ required: true }] })(
                            <SimpleContactSelect
                                filter={
                                    contactRoles.length == 0
                                        ? undefined
                                        : `id in (SELECT contactId from ContactRole WHERE role IN (${contactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                                }
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={t("Split %")}>{field("split", { rules: [{ required: true }] })(<InputNumber />)}</Form.Item>
                    <Form.Item label={t("Commission %")}>{field("commissionRate", { rules: [{ required: true }] })(<InputNumber />)}</Form.Item>
                    <Form.Item label={t("Withholding tax %")}>{field("taxRate", { rules: [{ required: true }] })(<InputNumber />)}</Form.Item>
                    
                </Col>
            </Row>
        </div>
    );
};

export default Form.create()(SubParticipantForm);