import React, { Component } from "react";
import { FileOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Select,
  Button,
  Input,
  message,
  notification,
  Typography,
  Modal,
  Tabs,
} from "antd";
import yaml from "js-yaml";
import DefaultPage from "../Shared/DefaultPage";
import { exe } from "../../Lib/Dal";
import FormulaSimulator from "../Products/FormulaSimulator";
import InvestmentChart from "./InvestmentChart";
import InvestmentPlanData from "./InvestmentPlanData";
import { withTranslation } from "react-i18next";
import CodeEditorW from "../Shared/CodeEditorW";
const { Option } = Select;
const { Paragraph, Title } = Typography;
const ButtonGroup = Button.Group;
class InvestmentPlan extends Component {
  state = {
    loading: false,
    investmentPlan: {},
    simulatorVisible: false,
  };
  componentDidMount() {
    if (this.props.match.params.investmentPlanCode && this.props.match.params.investmentPlanCode !== "0") {
      this.load(this.props.match.params.investmentPlanCode);
    } else {
      this.setState({ operation: "ADD" });
    }
  }
  load(investmentPlanCode) {
    this.setState({ loading: true });
    exe("RepoInvestmentPlan", {
      operation: "GET",
      filter: "code='" + investmentPlanCode + "'",
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        //extra fields
        if (r.outData[0].config) window.global.myCodeMirror.setValue(r.outData[0].config);
        this.props.form.setFieldsValue(r.outData[0]);
        this.setState({ investmentPlan: r.outData[0], operation: "UPDATE" });
      } else {
        message.error(r.msg);
      }
    });
  }
  delete() {
    exe("RepoInvestmentPlan", { operation: "DELETE", entity: this.state.investmentPlan }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);

        window.location.hash = "#/investmentPlans";
      } else {
        message.error(this.props.t("Investment plan in use by a policy. Please remove de policy first"));
      }
    });
  }
  save() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //extra fields
        values.config = window.global.myCodeMirror.getValue();
        //yaml validation
        try {
          const result = yaml.safeLoad(values.config);
          values.configJson = JSON.stringify(result);
        } catch (error) {
          notification["error"]({
            message: error.reason,
            description: error.message,
          });
          return;
        }

        //validation OK
        console.log("Received values of form: ", values);
        this.setState({ loading: true });
        exe("RepoInvestmentPlan", { operation: this.state.operation, entity: values }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            window.location.hash = "#/investmentPlans/" + r.outData[0].code;
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
      }
    });
  }
  openSimulator() {
    this.setState({ simulatorVisible: true });
  }
  closeSimulator() {
    this.setState({ simulatorVisible: false });
  }
  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <DefaultPage
        title={this.state.operation == "ADD" ? t("New Financial Product") : this.state.investmentPlan.name}
        subTitle={this.state.investmentPlan.code}
        icon="euro"
        loading={this.state.loading}
        onBack={() => (window.location = "#/investmentPlans")}
        routes={{
          routes: [
            { path: "/", breadcrumbName: "Home" },
            { path: "/investmentPlans", breadcrumbName: "Financial Products" },
            { path: "", breadcrumbName: "Financial Product" },
          ],
        }}
        extra={
          <ButtonGroup>
            <Button icon={<FileOutlined />}>{t("New")}</Button>
            <Button onClick={() => this.load(this.props.match.params.investmentPlanCode)}>{t("Reload")}</Button>
            <Button type="danger" onClick={() => this.delete()}>
              {t("Delete")}
            </Button>
            <Button>{t("Import")}</Button>
            <Button>{t("Export")}</Button>
            <Button onClick={() => this.openSimulator()}>{t("Simulator")}</Button>
            <Button icon={<SaveOutlined />} type="primary" onClick={() => this.save()}>
              {t("Save")}
            </Button>
          </ButtonGroup>
        }>
        <Tabs>
          <Tabs.TabPane tab={t("Configuration")} key="1">
            <Title level={4}>{t("Basic Info")}</Title>
            <Form layout="vertical">
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item label={t("Code")}>{getFieldDecorator("code", {})(<Input disabled={this.state.operation != "ADD"} />)}</Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={t("Name")}>{getFieldDecorator("name", {})(<Input />)}</Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={t("Type")}>{getFieldDecorator("type", {})(<Input />)}</Form.Item>
                </Col>
                <Col span={6}></Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={t("Description")}>{getFieldDecorator("description", {})(<Input.TextArea autosize />)}</Form.Item>
                </Col>
              </Row>
            </Form>
            <Title level={4}>{t("Behaviour")}</Title>
            <CodeEditorW />
          </Tabs.TabPane>

          <Tabs.TabPane tab={t("Chart")} key="3" >
            <Title style={{ marginTop: 5 }} level={4}>
              {t("Chart")}
            </Title>
            <InvestmentChart code={this.props.match.params.investmentPlanCode} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Historical Data")} key="4">
            <InvestmentPlanData code={this.props.match.params.investmentPlanCode} />
          </Tabs.TabPane>
        </Tabs>

        <FormulaSimulator visible={this.state.simulatorVisible} onCancel={() => this.closeSimulator()} />
      </DefaultPage>
    );
  }
}

const WrappedInvestmentPlan = Form.create()(InvestmentPlan);
export default withTranslation()(WrappedInvestmentPlan);
