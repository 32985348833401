import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";


const BenefitTypeSelect = (props) => {
    
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation();
    
    const defaultData=[
        {code:"PE",name:t("Pension")},
        {code:"PR",name:t("Provident")},
        {code:"HY",name:t("Hybrid")},
    ]
 
  return (
      <Select
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        optionFilterProp="children">
        {defaultData.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
  );
};

export default BenefitTypeSelect;
