import React, {useEffect, useState} from 'react';
import Money from "../Shared/Money";
import {Alert, Badge, Button, InputNumber, message, Tooltip} from "antd";
import {Icon} from "@ant-design/compatible"
import {useTranslation} from "react-i18next";
import { SaveOutlined } from '@ant-design/icons';

const CutOff = (props) => {
    const [t]   =useTranslation();
    const [value,setValue]=useState();
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        load();
    },[props.workspaceId,props.currency,props.paymentMethod]);
    
    const load=()=> {
        //checking if key exists in local storage
        const savedData=localStorage.getItem("cutOff");
        if(savedData){
            const parsed=JSON.parse(savedData);
            setData(parsed);
            const current=parsed.find(p=>p.workspaceId===props.workspaceId&&p.currency===props.currency&&p.paymentMethod===props.paymentMethod);
            if(current) {
                setValue(current.amount);
            }else {
                setValue(0);
            }
        }else{
           //creating default data
              const defaultRecord={workspaceId:props.workspaceId, amount:0,currency:props.currency,paymentMethod:props.paymentMethod};
              setData([defaultRecord]);
              setValue(0);
        }
    }
    
    const onSave=v=>{
        const current={workspaceId:props.workspaceId, amount:value,currency:props.currency,paymentMethod:props.paymentMethod};
        //saving to local storage
        //checking if record exists in data
        const exists=data.find(p=>p.workspaceId===props.workspaceId&&p.currency===props.currency&&p.paymentMethod===props.paymentMethod);
        let updatedData;
        if(exists){
            updatedData=data.map(p=>(p.workspaceId===props.workspaceId&&p.currency===props.currency&&p.paymentMethod===props.paymentMethod)?current:p);
        }else{
            updatedData=[...data,current];
        }
        
        localStorage.setItem("cutOff",JSON.stringify(updatedData));
        setData(updatedData);
        message.success(t("Saved"));
    }
    const disabled=!props.currency||!props.paymentMethod;
    return (
        <div style={{display:"flex",marginTop:3,marginLeft:3}}>
            <Money value={value} onChange={v=>setValue(v)} currency={props.currency} disabled={disabled}/>
            <Button type={"link"} icon={<SaveOutlined />} onClick={onSave} disabled={disabled} />
            {props.expected!==value&&!disabled&&<Tooltip title={t("The cash register cut-off differs from the expected amount for this currency and payment method.")}><Icon type="warning" style={{color:"#faad14",marginLeft:3,alignSelf:"center"}} /></Tooltip> }
        </div>
    );
};

export default CutOff;