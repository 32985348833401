import { FilterOutlined } from '@ant-design/icons';
import { Button, message, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShortUtc, formatMoney } from "../../Lib/Helpers";
import DefaultPage from "../Shared/DefaultPage";
import CumulusFilter from "./CumulusFilter";
import ReData from "./ReData";

const Cumulus = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    exe("GetGroupedCession", { month: moment().format("YYYY-MM-DD") }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSearch = (d) => {
    setData(d);
    setFilterVisible(false);
  };

  return (
    <div>
      <DefaultPage
        title={t("Cumulus")}
        icon="menu-unfold"
        extra={
          <div>
            <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
              {t("Filter")}
            </Button>
          </div>
        }>
        <Table dataSource={data} loading={loading} scroll={{ x: true }} expandedRowRender={(r) => <ReData value={r.Cessions} />}>
          <Table.Column title={t("Treaty ID")} dataIndex="contractId" render={(v, r) => <a href={"#/contract/" + v}>{v}</a>} />
          <Table.Column title={t("Lob")} dataIndex="lob" />
          <Table.Column title={t("Contact")} dataIndex="contactId" />
          <Table.Column title={t("Count")} dataIndex="count" />
          <Table.Column title={t("Coverage")} dataIndex="coverageCode" />
          <Table.Column title={t("Start")} dataIndex="start" render={(v) => formatDateShortUtc(v)} />
          <Table.Column title={t("End")} dataIndex="end" render={(v) => formatDateShortUtc(v)} />

          <Table.Column title={t("Sum Insured")} dataIndex="sumInsured" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Premium Sum")} dataIndex="premium" render={(v, r) => formatMoney(v, r.currency)} />

          <Table.Column title={t("Cedant Sum Insured")} dataIndex="sumInsuredCedant" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Cedant Premium")} dataIndex="premiumCedant" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Commission")} dataIndex="comissionCedant" render={(v, r) => formatMoney(v, r.currency)} />

          <Table.Column title={t("Re Sum Insured")} dataIndex="sumInsuredRe" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Re Premium")} dataIndex="premiumRe" render={(v, r) => formatMoney(v, r.currency)} />
        </Table>
        <CumulusFilter onSearch={(d) => onSearch(d)} visible={filterVisible} onClose={() => setFilterVisible(false)} />
      </DefaultPage>
    </div>
  );
};

export default Cumulus;
