import { InfoCircleOutlined } from '@ant-design/icons';
import {Button, message, Modal, Table, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import {formatMoney} from "../../Lib/Helpers";
import Money from "../Shared/Money";

const CoveragesDetailField = (props) => {
    const {t, i18n} = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [detailVisible, setDetailVisible] = useState(false);

    useEffect(()=>{
        if (props.jCoverages) {
            setData(props.jCoverages===-1?[]:JSON.parse(props.jCoverages));
        }
    },[props.jCoverages])
    
    const onLoadDetail = () => {
        setDetailVisible(true);
    };
    return (
        <div style={{display: "flex"}}>
            <Money value={props.value} onChange={props.onChange} currency={props.currency} disabled={props.disabled}/>
            {data.length>0&&<Tooltip title={t("Click for coverages breakdown")}>
                <Button type="link" icon={<InfoCircleOutlined />} onClick={() => onLoadDetail()}/>
            </Tooltip>}
            <Modal
                title={t("Coverages Detail")}
                visible={detailVisible}
                onCancel={() => setDetailVisible(false)}
                footer={
                    <div>
                        <Button type="primary" onClick={() => setDetailVisible(false)}>
                            {t("Ok")}
                        </Button>
                    </div>
                }>
                <Table dataSource={data} loading={loading} size="middle" pagination={false}>
                    <Table.Column title={t("Code")} dataIndex="code"/>
                    <Table.Column title={t("Name")} dataIndex="name"/>
                    <Table.Column title={t("Premium")} dataIndex="premium"
                                  render={(v) => <span style={{whiteSpace:"noWrap"}}>{formatMoney(v, props.currency)}</span>}/>
                </Table>
            </Modal>
        </div>
    );
};

export default CoveragesDetailField;
