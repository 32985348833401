import React, { useEffect, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { exe } from "../../Lib/Dal";
import { ArrowLeftOutlined, FileOutlined, ReloadOutlined } from '@ant-design/icons';
import { Table, Button, message } from "antd";
import HtmlTemplateForm from "./HtmlTemplateForm";
import { useTranslation } from "react-i18next";

const HtmlTemplates = (props) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => load(), []);

  const load = () => {
    exe("RepoHtmlTemplate", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onNew = () => {
    setSelected({});
  };
  return (
    <div>
      <DefaultPage title={t("HTML Templates")} icon="html5">
        {!selected && (
          <div>
            <Button type="link" icon={<FileOutlined />} onClick={onNew}>
              {t("New")}
            </Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={load}>
              {t("Reload")}
            </Button>
            <Table dataSource={data}>
              <Table.Column title={t("Name")} dataIndex="name" />
              <Table.Column title={t("Description")} dataIndex="description" />
              <Table.Column
                title={t("Action")}
                render={(v, r) => (
                  <Button type="link" onClick={() => setSelected(r)}>
                    {t("Open")}
                  </Button>
                )}
              />
            </Table>
          </div>
        )}
        {selected && (
          <div>
            <Button type="link" icon={<ArrowLeftOutlined />} onClick={() => setSelected(null)}>
              {t("Back")}
            </Button>
            <HtmlTemplateForm record={selected} onUpdate={load} />
          </div>
        )}
      </DefaultPage>
    </div>
  );
};

export default HtmlTemplates;
