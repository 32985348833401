import React, { useState, useEffect } from "react";
import { Select, Tooltip, Divider, message } from "antd";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";

//used inside forms = props = fn onChange, string value
const CountrySelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    getCache("RepoCountryCatalog").then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };

  return (
    <div style={{ position: "relative", ...props.style }}>
      <Select
        id="countrySelect"
        style={{ width: "100%" }}
        onChange={props.onChange}
        value={props.value}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showArrow={true}
        placeholder={t("Please select country")}>
        {data.map((d) => (
          <Select.Option key={d.code} value={d.code}>
            {d.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default CountrySelect;
