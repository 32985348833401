import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import EmailSelect from "./EmailSelect";
import HtmlTemplateSelect from "./HtmlTemplateSelect";

const NewCommunication = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const [loading, setLoading] = useState(false);

  const contactId = props.contactId;

  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      values.email = values.email[0]; //only 1 for now
      values.contactId = contactId;
      values.channel = "EMAIL";
      setLoading(true);
      sendEmail(values).then((r) => {
        setLoading(false);
        if (r.ok) addRecord(values);
      });
    });
  };
  const sendEmail = (email) => {
    return new Promise((resolve, reject) => {
      exe("SendEmail", { email: email.email, text: email.message, subject: email.subject }).then((r) => {
        if (r.ok) {
          message.success(t("Message sent successfully"));
          return resolve({ ok: true });
        } else {
          message.error(r.msg);
          return resolve({ ok: false });
        }
      });
    });
  };
  const addRecord = (record) => {
    record.delivered = new Date();
    exe("RepoMessage", { operation: "ADD", entity: record }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.form.resetFields();
        props.onOk();
      } else message.error(r.msg);
    });
  };
  const onSelectTemplate = (record) => {
    props.form.setFieldsValue({ message: record.template, template: record.template, templateName: record.name });
    if (record.chainId) {
      //trying to execute context loader
      try {
        console.log(`executing chain id ${record.chainId} with context: {contactId:${contactId}}`);
        exe("ExeChain", { chainId: record.chainId, context: `{contactId:${contactId}}` }).then((r) => {
          if (r.ok) {
            const loadedContext = r.outData;
            console.log("chain returned this context:", loadedContext);
            if (loadedContext) {
              //executing template with retreived context
              exe("RenderHtmlTemplate", { template: record.template, context: JSON.stringify(loadedContext) }).then((r) => {
                if (r.ok) {
                  props.form.setFieldsValue({ message: r.outData.result });
                } else message.error(r.msg);
              });
            }
          } else message.error(r.msg);
        });
      } catch (error) {
        console.log(error);
        message.error("Error executing cutom command chain. Please see console");
      }
    }
  };
  return (
    <div>
      {field("templateName")}
      {field("template")}
      <Modal
        title={t("New communication")}
        visible={props.visible}
        width={800}
        onOk={onOk}
        onCancel={props.onCancel}
        okText={t("Send")}
        okButtonProps={{ loading: loading, icon: "mail" }}>
        <Form.Item label={t("Email")}>{field("email", { rules: [{ required: true }] })(<EmailSelect contactId={contactId} />)}</Form.Item>
        <Form.Item label={t("Template")}>{field("templateId")(<HtmlTemplateSelect onSelect={onSelectTemplate} />)}</Form.Item>
        <Form.Item label={t("Subject")}>{field("subject", { rules: [{ required: true }] })(<Input />)}</Form.Item>
        <Form.Item label={t("Message")}>{field("message", { rules: [{ required: true }] })(<Input.TextArea autoSize={{ minRows: 6 }} />)}</Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(NewCommunication);
