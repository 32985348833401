import React, {useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from "antd";
import {useTranslation} from "react-i18next";
import PolicySelect from "../Health/PolicySelect";
import ContractSelect from "./ContractSelect";
import CoverageSelectLoad from "./CoverageSelectLoad";

const CessionAdd = props => {
    const [t]=useTranslation();
    
    const field=props.form.getFieldDecorator;
    const values=props.form.getFieldsValue();
    const onOk=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onOk(values);
        })
    }
    return (
        <div>
            <Modal title={t("Add Record")} visible={props.visible} onCancel={props.onCancel} onOk={onOk}>
                <Form.Item label={t("Policy")}>{field("policyId",{rules:[{required:true}]})(<PolicySelect proposals  />)}</Form.Item>   
                <Form.Item label={t("Coverage")} >{field("coverageId",{rules:[{required:true}]})(<CoverageSelectLoad policyId={values.policyId} style={{width:"100%"}} />)}</Form.Item>   
                <Form.Item label={t("Contract")} >{field("contractId",{rules:[{required:true}]})(<ContractSelect style={{width:"100%"}} />)}</Form.Item>   
            </Modal>
        </div>
    );
};

export default Form.create()(CessionAdd);