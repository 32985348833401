import React, { Component } from "react";
import { MailOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Descriptions,
  Divider,
  Button,
  Modal,
  Input,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Badge,
  message,
  Drawer,
  Typography,
  Tooltip,
} from "antd";
import { exe } from "Lib/Dal";
import { withTranslation } from "react-i18next";
const { Column, ColumnGroup } = Table;
const { Text, Title } = Typography;

const Summary = (props) => {
  const t = props.t;
  const total = props.data.reduce((acc, obj) => acc + obj.total, 0);
  const covered = props.data.reduce((acc, obj) => acc + obj.company, 0);
  const insured = props.data.reduce((acc, obj) => acc + obj.insured, 0);
  const deductible = props.data.reduce((acc, obj) => acc + obj.deductible, 0);
  const copayment = props.data.reduce((acc, obj) => acc + obj.copayment, 0);
  const balance = insured + deductible + copayment;
  return (
    <Descriptions column={3} bordered size="small">
      <Descriptions.Item label={t("Total")}>{total}</Descriptions.Item>
      <Descriptions.Item label={t("Covered")}>{covered}</Descriptions.Item>
      <Descriptions.Item label={t("Not Covered")}>{insured}</Descriptions.Item>

      <Descriptions.Item label={t("Deductible")}>{deductible}</Descriptions.Item>
      <Descriptions.Item label={t("Copayment")}>{copayment}</Descriptions.Item>
      <Descriptions.Item label={t("Balance")}>{balance}</Descriptions.Item>
    </Descriptions>
  );
};
class Report extends Component {
  state = {
    data: [],
  };
  componentDidMount() {
    this.load(this.props.claimId);
  }

  load = (claimId) => {
    if (claimId == 0) {
      return;
    }
    this.setState({ loading: true });
    exe("RepoProcedure", {
      operation: "GET",
      filter: "claimId=" + claimId,
      include: ["ProcedureAdjudication", "ProcedureAdjudication.PolicyBenefit"],
    }).then((r) => {
      if (r.ok) {
        this.setState({ loading: false, data: r.outData });
      } else {
        this.setState({ loading: false });
        message.error(r.msg);
      }
    });
  };

  render() {
    const { t, i18n } = this.props;
    return (
      <div>
        <Title level={4}>{t("Adjudication Report")}</Title>
        <Button type="primary" icon={<MailOutlined />} onClick={() => {}} style={{ marginBottom: 5 }}>
          {t("Send")}
        </Button>

        <Table size="small" style={{ marginTop: 10 }} dataSource={this.state.data} rowKey="id">
          <Column
            title={t("Provider")}
            dataIndex="providerCode"
            render={(v) => (
              <Button type="link" onClick={() => (window.location.hash = "#/provider/" + v)}>
                {v}
              </Button>
            )}
          />
          <Column title={t("Date")} dataIndex="from" render={(v) => (v ? v.split("T")[0] : "N/A")} />
          <Column title={t("CPT Code")} dataIndex="cpt" />
          <Column title={t("Service Name")} dataIndex="service" />
          <Column title={t("Total")} dataIndex="total" />
          <Column title={t("Covered")} dataIndex="company" />
          <Column title={t("Not Covered")} dataIndex="insured" />
          <Column title={t("Deductible")} dataIndex="deductible" />
          <Column title={t("Copayment")} dataIndex="copayment" />
          <Column
            title={t("Status")}
            dataIndex="status"
            //render={v => ProcedureStatus[v].name}
            render={(v) => (
              <span>
                <Badge status="success" />
              </span>
            )}
          />
          <Column title={t("Notes")} dataIndex="rejectionText" />
        </Table>
        <Summary data={this.state.data} t={t} />
      </div>
    );
  }
}

export default withTranslation()(Report);
