import React, {useEffect, useState} from 'react';

import {
    EditOutlined,
    FileExcelOutlined,
    HomeOutlined,
    PlusSquareOutlined,
    SearchOutlined,
} from '@ant-design/icons';

import {Button, Checkbox, message, Table} from "antd";
import {useTranslation} from "react-i18next";
import CoaSearchFilter from "./CoaSearchFilter";
import {exe, objectToFilter} from "../../Lib/Dal";
import useUpdateEffect from "../../Lib/Helpers";
import AccountDetail from "./AccountDetail";
import {Excel} from "antd-table-saveas-excel";

const CoaSearch = (props) => {
    const [t]=useTranslation();
    const [data,setData]=useState();
    const [loading,setLoading]=useState(false);
    const [filterVisible,setFilterVisible]=useState(false);
    const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
    const [searchValues,setSearchValues]=useState({});
    const [selected,setSelected]=useState();
    
    useEffect(()=>onSearch(searchValues),[pagination.current])
    
    const onSearch=(values)=>{
        setLoading(true);
        setSearchValues(values); //required for pagination re-entry
        const filter=objectToFilter(values);
        
        exe("RepoCatalogAccount",{operation:"GET",filter:filter,size: pagination.pageSize,
            page: pagination.current - 1}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
                setPagination({ ...pagination, total: r.total, current: pagination.total !== r.total ? 1 : pagination.current });
            }else message.error(r.msg);
        })
    }
    const onAccountEdited=(acc)=>{
        setSelected(undefined);
        onSearch(searchValues);
    }
    const exportXlsx = () => {
        setLoading(true);
        getDataWithoutPagination().then((r) => {
            setLoading(false);
            if (r.ok) {
                const exportData = r.outData;
                const fields = Object.keys(exportData[0]).filter(f=>!["Children"].includes(f));
                const excel = new Excel();
                excel
                    .addSheet("Sheet1")
                    .addColumns(fields.map((p) => ({ title: p, dataIndex: p }))) //antd column format
                    .addDataSource(exportData)
                    .saveAs("coaExport.xlsx");
            } else message.error(r.msg);
        });
    };
    const getDataWithoutPagination = () =>
        new Promise((resolve, reject) => {
            const filter=objectToFilter(searchValues);
            exe("RepoCatalogAccount",{operation:"GET",filter:filter,size: 0,}).then(r=>{
                if(r.ok){
                    resolve({ ok: true, outData: r.outData,msg:r.msg })
                }else {
                    message.error(r.msg);
                    resolve({ ok: false, outData: [],msg:r.msg })
                }
            })
        });
    const navigateParent=parentCode=>{
        onSearch({parentCode:parentCode})
    }
    const navigateAccount=code=>{
        onSearch({code:code})
    }
    return (
        <div>
            <Button type="link" icon={<HomeOutlined />} onClick={()=>onSearch({})}>{t("Root")}</Button>
            <Button type="link" icon={<SearchOutlined />} onClick={()=>setFilterVisible(true)}>{t("Search")}</Button>
            <Button type="link" icon={<FileExcelOutlined />} onClick={exportXlsx}>
                {t("Excel")}
            </Button>
            <Table size="middle" dataSource={data} loading={loading} pagination={pagination} onChange={(pag) => setPagination(pag)}>
                <Table.Column key="navigate"  render={(v,r)=><Button type="link" icon={<PlusSquareOutlined />} disabled={r.isDetail} onClick={()=>navigateParent(r.code)}/> } /> 
                <Table.Column dataIndex="code" title={t("Code")} />
                <Table.Column dataIndex="name" title={t("Name")} />
                <Table.Column dataIndex="parentCode" title={t("Parent")} render={(v,r)=><Button type="link" onClick={()=>navigateAccount(v)} >{v}</Button>} />
                <Table.Column dataIndex="currency" title={t("Currency")} />
                <Table.Column dataIndex="accountType" title={t("Type")} />
                <Table.Column dataIndex="nature" title={t("Nature")} />
                <Table.Column dataIndex="auxType" title={t("Aux Type")} />
                <Table.Column dataIndex="level" title={t("Level")} />
                <Table.Column dataIndex="isDetail" title={t("Detail")} render={v=><Checkbox checked={v} /> } />
                <Table.Column key="actions" title={t("Actions")} render={(v,r)=><div>
                    <Button type="link" icon={<EditOutlined />} onClick={()=>setSelected(r)} />
                </div>} />
            </Table>
            <CoaSearchFilter visible={filterVisible} onSearch={onSearch} onClose={()=>setFilterVisible(false)} />
            <AccountDetail visible={selected} selected={selected} onOk={onAccountEdited} onCancel={()=>setSelected(undefined)}  />
        </div>
    );
};

export default CoaSearch;