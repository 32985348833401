import { DeleteOutlined, LeftOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, message, Modal, Popconfirm, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import FormLabel from "../Shared/FormLabel";
import FormSelect from "./FormSelect";
import ObjectRender from "./ObjectRender";

const ObjectDefinitionDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (props.selected) load(props.selected);
    else props.form.resetFields();
  }, [props.selected]);

  const field = props.form.getFieldDecorator;
  const onBack = () => {
    props.onBack && props.onBack();
  };
  const onUpdate = (record) => {
    props.onUpdate && props.onUpdate(record);
  };
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("RepoObjectDefinition", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          onUpdate(r.outData[0]);
          props.form.resetFields();
          onBack();
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onDelete = () => {
    exe("RepoObjectDefinition", { operation: "DELETE", entity: { id: props.selected.id } }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
        onUpdate();
        props.form.resetFields();
        onBack();
      } else {
        message.error(r.msg);
      }
    });
  };
  const load = (record) => {
    props.form.setFieldsValue(record);
  };
  const values = props.form.getFieldsValue();
  return (
    <div>
      <Button type="link" icon={<LeftOutlined />} onClick={onBack}>
        {t("Back")}
      </Button>
      <Button type="primary" icon={<SaveOutlined />} onClick={onSave} loading={loading}>
        {t("Save")}
      </Button>
      <Button type="link" icon={<SearchOutlined />} onClick={() => setPreviewVisible(true)}>
        {t("Preview")}
      </Button>
      <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={!props.selected}>
        <Button type="danger" icon={<DeleteOutlined />} disabled={!props.selected} style={{ float: "right" }}>
          {t("Delete")}
        </Button>
      </Popconfirm>

      <Row gutter={16}>
        <Col span={12}>
          {field("id")}
          <Form.Item label={t("Code")}>{field("code", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          <Form.Item label={t("Form")}>{field("formId")(<FormSelect />)}</Form.Item>
          <Form.Item label={t("Map")}>{field("hasMap", { valuePropName: "checked" })(<Switch />)}</Form.Item>
          <Form.Item label={t("Files Upload")}>{field("hasFileUpload", { valuePropName: "checked" })(<Switch />)}</Form.Item>
        </Col>
        <Col span={12}></Col>
      </Row>
      <Modal
        width={800}
        destroyOnClose
        title={t("Object Preview")}
        visible={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        onOk={() => setPreviewVisible(false)}>
        <ObjectRender objectDefinitionId={values.id} />
      </Modal>
    </div>
  );
};

export default Form.create()(ObjectDefinitionDetail);
