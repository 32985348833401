import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Collapse, Input } from "antd";
import React, { useEffect } from "react";
import Loadings from "./Loadings";

const LoadingEdition = (props) => {
  const coverages = JSON.parse(props.value || []);
  const onChangeLoadings = (v, coverage) => {
    coverage.Loadings = v;
    const updatedCovs = coverages.map((p) => (p.code == coverage.code ? coverage : p));
    console.log("updated covs", updatedCovs);
    props.onChange(JSON.stringify(updatedCovs));
  };
  return (
    <div>
      <Collapse>
        {coverages.map((coverage, index) => (
          <Collapse.Panel header={`${coverage.code}-${coverage.name}`} key={coverage.code}>
            <Loadings coverage={coverage} disabled={props.disabled} value={coverage.Loadings} onChange={(v) => onChangeLoadings(v || [], coverage)} enableDateEdition={props.enableDateEdition} />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default LoadingEdition;
