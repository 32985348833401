import React, { useState, useEffect } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { Table, message, Button, Modal, Divider } from "antd";
import { exe } from "../../Lib/Dal";
import CommunicationsData from "./CommunicationsData";
import { useTranslation } from "react-i18next";

const Communications = (props) => {
  const [t, i18n] = useTranslation();

  return (
    <div>
      <DefaultPage title={t("Communications")} icon="message">
        {/* <Button type="link" icon="reload" onClick={load} style={{ position: "relative" }}>
          {t("Reload")}
        </Button> */}
        <CommunicationsData all />
      </DefaultPage>
    </div>
  );
};

export default Communications;
