import React from "react";
import { Badge, Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import { LeftOutlined, PlusOutlined, ReloadOutlined, EditOutlined, FolderOpenOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FundFilter from "./FundFilter";

const FundList = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [selected,setSelected] = useState(null);
    const [loading,setLoading] = useState(false);
    const [filterVisible,setFilterVisible] = useState(false);

    useEffect(()=>{
        load();
    },[])

    const load=()=>{
        setLoading(true);
        exe("RepoFund",{operation:"GET",include:["Contact"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }

    const onAdd=()=>{
        //redirecting to the fund page
        props.history.push("/fund/0");
    }
    const onOpen=r=>{
        //redirecting to the fund page
        props.history.push("/fund/"+r.id);
    }

    return <DefaultPage title={t("Fund List")} icon="profile" routes={{
        routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Fund List"), path: "funds" }
        ]}}
        extra={<div><Button type="primary" icon={<SearchOutlined />} onClick={() => setFilterVisible(true)} loading={loading}>{t("Filter")}</Button></div>}>

        <Button type="link" icon={<PlusOutlined />} onClick={onAdd}>{t("Add")}</Button>
        <Button type="link" icon={<ReloadOutlined />} onClick={load}>{t("Reload")}</Button>
        <Table dataSource={data} rowKey={"id"} loading={loading}>
            <Table.Column title={t("Id")} dataIndex="id" />
            <Table.Column title={t("Name")} dataIndex="name" />
            <Table.Column title={t("Currency")} dataIndex="currency" />
            <Table.Column title={t("Holder")} dataIndex={["Contact","FullName"]} />
            <Table.Column title={t("Active")} dataIndex="active" render={v=>v?<Badge status="success"/>:<Badge status="error"/>} />
            <Table.Column title={t("Actions")} render={(v,r)=><div>
                <Button type="link" icon={<FolderOpenOutlined />} onClick={()=>onOpen(r)} />
            </div>} />
        </Table>

        <FundFilter visible={filterVisible} onClose={()=>setFilterVisible(false)} onResults={results=>setData(results)} />
    </DefaultPage>
       
    };

    export default FundList;