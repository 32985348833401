import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Collapse } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Bill from "./Bill";
import BillUnique from "./BillUnique";
import BillingPayPlan from "./BillingPayPlan";
import BillDiff from "./BillDiff";
import DetailViewer from "./DetailViewer";

const ChangeBilling = (props) => {
  const [t] = useTranslation();
  const currency = props.currency;
  const config = props.config;
  const bill = props.change.Bill;
  const billUnique = props.change.BillUnique;
  const jNewPayPlan = props.change.jNewPayPlan;
  const cancellationBill = props.change.CancellationBill;
  const billDiff = props.change.BillDiff;
  const jDetail=props.change.jDetail

  const field = props.form.getFieldDecorator;
  return (
    <div>
      {field("jDetail")}
      {field("jNewCoverages")}
      {field("jOldCoverages")}
      {field("BillDiff")}
      <Collapse defaultActiveKey={["changeBill"]}>
        
        <Collapse.Panel header={t("Change Bill")} key="changeBill">
          <Bill value={bill} onChange={() => {}} currency={currency} config={config} change={props.change} />
        </Collapse.Panel>
        
        {billDiff&&<Collapse.Panel header={t("Bill Difference")} key="changeBillDiff">
            <BillDiff value={billDiff}  currency={currency} config={config} />
        </Collapse.Panel>}
        
        {billUnique && (
          <Collapse.Panel header={t("Change Bill Unique")} key="changeBillUnique">
            <BillUnique value={billUnique} change={props.change} onChange={() => {}} currency={currency} />
          </Collapse.Panel>
        )}
     
        {jNewPayPlan && (
          <Collapse.Panel header={t("Pay plan")} key="billingPayPlan">
            <BillingPayPlan value={jNewPayPlan} currency={currency} />
          </Collapse.Panel>
        )}

        {cancellationBill&&<Collapse.Panel header={t("Cancellation Bill")} key="cancellationBill">
          <div className="disabled">
            <Bill value={cancellationBill} onChange={() => {}} currency={currency} config={config} />
          </div>
        </Collapse.Panel>}

        {jDetail&&<Collapse.Panel header={t("Calculation Detail")} key="calculationDetail">
          <Form.Item>{field("jDetail", {})(<DetailViewer />)}</Form.Item>
        </Collapse.Panel>}
        
        
      </Collapse>
    </div>
  );
};

export default ChangeBilling;
