import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from "antd";
import {Link} from "react-router-dom";
import {exe} from "../../Lib/Dal";
import {useTranslation} from "react-i18next";

const TaskSelect = (props) => {
    const [processes,setProcesses]=useState([]);
    const [t]=useTranslation();
    
    useEffect(()=>loadProcesses(),[]);
    
    const entity=props.entity;
    const entityId=props.entityId;
    const entityPath=props.entityPath; //example: "LifePolicy/" + policy.id
    
    const loadProcesses=()=>{
        exe("GetDefinitions", { filter: `entity='${entity}' and enabled=1` }).then((r) => {
            setProcesses(r.outData || []);
        });
    }
    
    return (
        <Dropdown
               onClick={() => null}
               overlay={
                   <Menu onClick={() => null}>
                       {processes.map((p) => (
                           <Menu.Item key="1">
                               <Link to={{ pathname: "/activity/0", proceso: p, entity: entity, entityId: entityId, entityPath: entityPath }}>
                                   <LegacyIcon type={p.icon || "rocket"} style={{ marginRight: 5 }} />
                                   {p.id}.{p.name}
                               </Link>
                           </Menu.Item>
                       ))}
                   </Menu>
               }>
               <Button type="link">
                   {t("Tasks")} <DownOutlined />
               </Button>
           </Dropdown>
    );
};

export default TaskSelect;