import { ControlOutlined } from '@ant-design/icons';
import {Badge, Button, Checkbox, message, Table} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import PolicyLink from "../Life/PolicyLink";
import CurrencyCell from "../Shared/CurrencyCell";
import CustomCumulusFilter from "./CustomCumulusFilter";
import {formatDateShortUtc, formatMoney, formatNumber} from "../../Lib/Helpers";

const CustomCumulus = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filterVisible,setFilterVisible]=useState(false);

    const onSearch=(values)=>{
        console.log(values);
        setLoading(true);
        exe("GetCustomCumulus",values).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }

    return (
        <DefaultPage
            title={t("Custom Cumulus")}
            icon="dot-chart"
            extra={
                <div>
                    <Button type="primary" onClick={()=>setFilterVisible(true)} icon={<ControlOutlined />}>
                        {t("Parameters")}
                    </Button>
                </div>
            }>
           
            <Table loading={loading} dataSource={data} style={{marginTop:5}} expandedRowRender={m=>
                <Table dataSource={m.Items} expandedRowRender={l=>
                    <Table dataSource={l.Items} expandedRowRender={c=>
                        <Table dataSource={c.Policy.Cessions}>
                            {/*// -------------- Cessions Table ---------------- //*/}
                            <Table.Column title={t("Cession ID")} dataIndex="id" render={(v,r)=><div style={r.overwritten?{textDecoration:"line-through"}:{}}>{v}</div>} />
                            <Table.Column title={t("Treaty ID")} dataIndex="contractId" render={(v, r) => <a href={"#/contract/" + v}>{v}</a>} />
                            <Table.Column title={t("Valid")} dataIndex="err" render={(v, r) => v?<Badge status={"error"}/>:<Badge status={"success"}/> } />
                            <Table.Column title={t("Lob")} dataIndex="LoB" />
                            <Table.Column title={t("Policy")} dataIndex="policyCode" render={(v, r) => <PolicyLink policyId={r.lifePolicyId} code={v} />} />
                            <Table.Column title={t("Coverage")} dataIndex="coverageCode" />
                            <Table.Column title={t("Start")} dataIndex="start" render={(v) => formatDateShortUtc(v)} />
                            <Table.Column title={t("End")} dataIndex="end" render={(v) => formatDateShortUtc(v)} />
                            <Table.Column title={t("Line")} dataIndex="lineId" />
                            <Table.Column title={t("Type")} dataIndex="premiumType" />
    
                            <Table.Column title={t("Sum Insured")} dataIndex="sumInsured" render={(v, r) => formatMoney(v, r.currency)} />
                            <Table.Column title={t("Premium Sum")} dataIndex="premium" render={(v, r) => formatMoney(v, r.currency)} />
    
                            <Table.Column title={t("Cedant Sum Insured")} dataIndex="sumInsuredCedant" render={(v, r) => formatMoney(v, r.currency)} />
                            <Table.Column title={t("Cedant Premium")} dataIndex="premiumCedant" render={(v, r) => formatMoney(v, r.currency)} />
                            <Table.Column title={t("Commission")} dataIndex="comissionCedant" render={(v, r) => formatMoney(v, r.currency)} />
    
                            <Table.Column title={t("Re Sum Insured")} dataIndex="sumInsuredRe" render={(v, r) => formatMoney(v, r.currency)} />
                            <Table.Column title={t("Re Premium")} dataIndex="premiumRe" render={(v, r) => formatMoney(v, r.currency)} />
                        </Table>}>
                        {/*// -------------- Policy Table ---------------- //*/}
                        <Table.Column title={t("Policy")} dataIndex={["Policy","id"]} render={(v,r)=><PolicyLink policyId={v} code={r.Policy.code}  />} />
                        <Table.Column title={t("Insured Sum")} dataIndex={["Policy","insuredSum"]} render={(v,r)=><CurrencyCell value={r.Policy.insuredSum} currency={r.Policy.currency} />} />
                        <Table.Column title={t("Premium")} dataIndex={["Policy","annualPremium"]} render={(v,r)=><CurrencyCell value={r.Policy.annualPremium} currency={r.Policy.currency} />} />
                    </Table>}>
                    {/*// -------------- Lob Table ---------------- //*/}
                    <Table.Column title={t("LoB")} dataIndex="lob"  />
                    <Table.Column title={t("Policies")} dataIndex={"num"} />
                    <Table.Column title={t("Sum Insured")} dataIndex="sumInsured" render={v=>formatNumber(v)} />
                    <Table.Column title={t("Premium Sum")} dataIndex="premium" render={v=>formatNumber(v)} />
                    <Table.Column title={t("Cedant Sum Insured")} dataIndex="sumInsuredCedant" render={v=>formatNumber(v)} />
                    <Table.Column title={t("Cedant Premium")} dataIndex="premiumCedant" render={v=>formatNumber(v)} />
                    <Table.Column title={t("Re Sum Insured")} dataIndex="sumInsuredRe" render={v=>formatNumber(v)} />
                    <Table.Column title={t("Re Premium")} dataIndex="premiumRe" render={v=>formatNumber(v)} />

                </Table>}>
                {/*--------- MAIN TABLE -------*/}
                <Table.Column title={t("Cumulus Field")} dataIndex={"cumulusField"}  />} />
                <Table.Column title={t("Policies")} dataIndex={"num"}  />} />
                
                <Table.Column title={t("Sum Insured")} dataIndex="sumInsured" render={v=>formatNumber(v)}  />
                <Table.Column title={t("Premium Sum")} dataIndex="premium" render={v=>formatNumber(v)} />
                <Table.Column title={t("Cedant Sum Insured")} dataIndex="sumInsuredCedant" render={v=>formatNumber(v)} />
                <Table.Column title={t("Cedant Premium")} dataIndex="premiumCedant" render={v=>formatNumber(v)} />
                <Table.Column title={t("Re Sum Insured")} dataIndex="sumInsuredRe" render={v=>formatNumber(v)} />
                <Table.Column title={t("Re Premium")} dataIndex="premiumRe" render={v=>formatNumber(v)} />

                
            </Table>
            <CustomCumulusFilter visible={filterVisible} onClose={()=>setFilterVisible(false)} onSearch={onSearch} />
        </DefaultPage>
    );
};

export default CustomCumulus;
