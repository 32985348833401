import { EditOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Collapse, Input, Table, Typography } from "antd";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {formatPercent, round2} from "../../Lib/Helpers";
import Money from "../Shared/Money";
import Column from "antd/lib/table/Column";
import BenefitGroupSelect from "./BenefitGroupSelect";
import {safeGet} from "../../Lib/Dal";
import BenefitDetail from "./BenefitDetail";

const CoverageEdition = (props) => {
    const [benefitDetail,setBenefitDetail]=useState();
    const [t]=useTranslation();
    
    const onChangeBenefit=benefit=>{
        const updatedCov = coverages.find((p) => p.id === benefit.lifeCoverageId);
        updatedCov.Benefits = updatedCov.Benefits.map((p) => (p.code === benefit.code ? benefit : p));
        setBenefitDetail(undefined);
        const newCovs=coverages.map(p=>p.id===updatedCov.id?updatedCov:p);
        props.onChange(JSON.stringify(newCovs));
    }
    const onChangeBenefitGroup=(coverageCode, benefitCode, groupCode)=>{
        const updatedCov = coverages.find((p) => p.code === coverageCode);
        const updatedBenefit = updatedCov.Benefits.find((p) => p.code === benefitCode);
        updatedBenefit.group = groupCode;
        onChangeBenefit(updatedBenefit)
    }
    const coverages = JSON.parse(props.value || []);
    const getConfig = (code) => props.config.Coverages.find((p) => p.code == code);

    return (
        <div>
            <Collapse>
                {coverages.map((coverage, index) => (
                    <Collapse.Panel header={`${coverage.code}-${coverage.name}`} key={coverage.code}>
                        <Table dataSource={coverage.Benefits}>
                            <Column
                                title={t("Action")}
                                key="actions"
                                render={(v, benefit) => (
                                    <div>
                                        <Button type="link" icon={<EditOutlined />} onClick={()=>setBenefitDetail(benefit)}  />
                                    </div>
                                )}
                            />
                            <Column title={t("Code")} dataIndex="code" />
                            <Column title={t("Benefit")} dataIndex="name" render={(v, r) => <Typography.Text delete={r.covered == 0}>{v}</Typography.Text>} />
                            <Column title={t("Description")} dataIndex="description" />

                            <Column
                                title={t("Group")}
                                dataIndex="group"
                                render={(v, benefit) => (
                                    <BenefitGroupSelect
                                        groups={safeGet(["BenefitGroups"], props.config, [])}
                                        value={v}
                                        readOnly={props.readOnly}
                                        onChange={(groupCode) => onChangeBenefitGroup(coverage.code, benefit.code, groupCode)}
                                    />
                                )}
                            />

                            <Column title={t("Copay")} dataIndex="copay" />
                            <Column title={t("Covered")} dataIndex="covered" render={(v) => formatPercent(v)} />
                            <Column title={t("Deductible")} dataIndex="deductible" />
                            <Column title={t("Annual Deductible")} dataIndex="aDeductible" />
                            <Column title={t("Quarter Deductible")} dataIndex="qDeductible" />
                            <Column title={t("Monthly Deductible")} dataIndex="mDeductible" />

                            <Column title={t("Limit")} dataIndex="limit" />
                            <Column title={t("Family Limit")} dataIndex="familyLimit" />
                            <Column title={t("Annual Limit")} dataIndex="aLimit" />
                            <Column title={t("Quarter Limit")} dataIndex="qLimit" />
                            <Column title={t("Monthly Limit")} dataIndex="mLimit" />
                            <Column title={t("Lifetime Limit")} dataIndex="lifetimeLimit" />

                            <Column title={t("Event Annual Times")} dataIndex="eventaTimes" />
                            <Column title={t("Event Quarter Times")} dataIndex="eventqTimes" />
                            <Column title={t("Event Monthly Times")} dataIndex="eventmTimes" />
                            <Column title={t("Event Daily Times")} dataIndex="eventdTimes" />

                            <Column title={t("Out Of Pocket Annual")} dataIndex="outOfPocketAnualLimit" />

                            <Column title={t("Waiting Period")} dataIndex="waitingPeriod" />

                            <Column title={t("Special Instructions")} dataIndex="notes" />
                        </Table>
                        )}
                        pagination={false}
                        />
                    </Collapse.Panel>
                ))}
            </Collapse>
            <BenefitDetail
                visible={benefitDetail}
                value={benefitDetail}
                onCancel={() => setBenefitDetail(undefined)}
                onOk={(v) => onChangeBenefit(v)}
                currency={props.currency}
                groups={safeGet(["BenefitGroups"], props.config, [])}
                readOnly={props.readOnly}
            />
        </div>
    );
};

export default CoverageEdition;
