import {
  DeleteOutlined,
  FileSyncOutlined,
  FilterOutlined,
  InboxOutlined,
  ReloadOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Col, message, Popconfirm, Popover, Row, Select, Table } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { load } from "js-yaml";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";
import {exe, safeGet} from "../../Lib/Dal";
import { formatDate } from "../../Lib/Helpers";
import TemplateSelect from "../Shared/TemplateSelect";
import DocStatusSelect from "../Shared/DocStatusSelect";

const ClaimDocs = (props) => {
  const [t, i18n] = useTranslation();
  const [uploadVisible, setUploadVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [templateName, setTemplateName] = useState(undefined);
  const [statusFilter,setStatusFilter]=useState();

  useEffect(() => {
    if (props.claimId) load(props.claimId);
  }, [props.claimId]);

  const uploadProps = {
    name: "file",
    multiple: true,
    action: config.ssApiUrl + "/proxy/upload",
    headers: {
      Authorization: "Bearer " + localStorage.token,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} ${t("file uploaded successfully")}.`);
        saveDocument(info.file);
      } else if (status === "error") {
        message.error(`${info.file.name} ${t("file upload failed")}.`);
      }
    },
  };
  const load = (claimId) => {
    setLoading(true);
    exe("RepoClaimDocument", { operation: "GET", filter: "claimId=" + claimId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const saveDocument = (file) => {
    setLoading(true);
    const document = { claimId: props.claimId, name: "uploaded", filename: file.name, url: file.response.url };
    exe("RepoClaimDocument", { operation: "ADD", entity: document }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.claimId);
      } else {
        message.error(r.msg);
      }
    });
  };
  const openFile = (v) => {
    try {
      fetch(config.ssApiUrl + "/proxy" + v, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => response.blob())
        .then((data) => window.open(URL.createObjectURL(data)))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };
  const generateClaimDoc = (name) => {
    setLoading(true);
    exe("GenerateClaimDoc", { claimId: props.claimId, template: name }).then((r) => {
      setLoading(false);
      setTemplateName(undefined);
      if (r.ok) {
        message.success(r.msg);
        load(props.claimId);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onStatusChange=(doc,status)=>
  {
    doc.status=status;
    setLoading(true)
    exe("RepoClaimDocument", { operation: "UPDATE", entity: doc }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Status updated"));
        setData(data.map((p) => p.id == doc.id?({...p,status:status}):p));
      }
    });
  }
  const onDelete=(doc)=> {
    setLoading(true)
    exe("RepoClaimDocument", { operation: "DELETE", entity: doc }, undefined, true).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.claimId);
        props.reload();
      }
    });
  }
  const save=(record)=>
  {
    setLoading(true)
    exe("RepoClaimDocument", { operation: "UPDATE", entity: record }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Record updated"));
        load(props.claimId);
        props.reload();
      }else message.error(r.msg)
    });
  }
  const onSelectAssociation=(value, record)=> {
    record.association = value;
    save(record);
  }
  const requirements = props.requirements || [];
  return (
    <div>
      <Button type="link" icon={<UploadOutlined />} onClick={() => setUploadVisible(!uploadVisible)}>
        {t("Upload")}
      </Button>
      <Popover
        content={
          <div>
            <TemplateSelect onChange={(v) => generateClaimDoc(v)} 
                            value={templateName} 
                            disabled={loading} 
                            loading={loading}
                            userSelectableTemplates={safeGet(["Main", "userSelectableClaimTemplates"], props.config, [])}
            />
          </div>
        }
        title={t("On demand document generation")}
        trigger="click">
        <Button type="link" icon={<FileSyncOutlined />}>
          {t("Generate")}
        </Button>
      </Popover>
      <Popover
          content={
            <div>
              <DocStatusSelect value={statusFilter} onChange={(v)=>setStatusFilter(v)} style={{width:"100%"}} />
            </div>
          }
          title={t("Document Status Filter")}
          trigger="click">
        <Button type="link" icon={<FilterOutlined />}>
          {t("Filter")}
        </Button>
      </Popover>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => load(props.claimId)}>
        {t("Reload")}
      </Button>
      {uploadVisible && (
        <Row gutter={16} style={{ marginBottom: 10 }}>
          {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
          <Col span={12}>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
              <p className="ant-upload-hint">{t("Support for a single or bulk upload. UB04/CMS1500 Form supported")}</p>
            </Dragger>
          </Col>
          {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
          <Col span={6}></Col>
          {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
          <Col span={6}></Col>
        </Row>
      )}
      <Table dataSource={statusFilter?data.filter(p=>p.status===statusFilter):data} loading={loading}>
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("File")} dataIndex="fileName" />
        <Table.Column title={t("Type")} dataIndex="name" />
        <Table.Column title={t("Created")} dataIndex="created" render={(v) => formatDate(v)} />
        <Table.Column
            title={t("Status")}
            dataIndex="status"
            width={150}
            render={(v, r) => (<DocStatusSelect value={v} onChange={(status)=>onStatusChange(r,status)} />)}
        />
        <Table.Column
          title={t("File")}
          dataInex="url"
          render={(v, r) => (
            <Button type="link" onClick={() => openFile(r.url, r.name)}>
              {t("Open")}
            </Button>
          )}
        />
        <Table.Column
            title={t("Association")}
            dataIndex="association"
            render={(t, r) => (
                <Select disabled={r.name.toLowerCase() !== "uploaded"} dropdownMatchSelectWidth={false} value={r.association} onSelect={(v) => onSelectAssociation(v, r)}>
                  {requirements.map((p) => (
                      <Select.Option value={p.code}>{p.document}</Select.Option>
                  ))}
                </Select>
            )}
        />
        <Table.Column
            render={(v, r) => (
                <Popconfirm title={t("Are you sure you want to delete this document?")} onConfirm={() => onDelete(r)}>
                  <Button type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
            )}
        />
      </Table>
    </div>
  );
};

export default ClaimDocs;
