import React from "react";
import { Table } from "antd";
import MoneyLabel from "../Shared/MoneyLabel";
import { formatDate } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";

const AnnuityPayments = (props) => {
  const [t, i18n] = useTranslation();
  return (
    <div>
      <Table dataSource={props.value} rowKey="id">
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("Date")} dataIndex="due" render={(v) => formatDate(v)} />
        <Table.Column title={t("Amount")} dataIndex="amount" render={(v, r) => <MoneyLabel currency={r.currency} value={v} />} />
        <Table.Column title={t("Result")} dataIndex="ok" render={(v) => (v ? <span>✅</span> : <span>❌</span>)} />
        <Table.Column title={t("Message")} dataIndex="msg" />
        <Table.Column title={t("Exe Date")} dataIndex="lastExeDate" render={(v) => formatDate(v)} />
        <Table.Column title={t("Payment Request")} dataIndex="requestId" render={(v) => <a href={"#/payments/" + v}>{v}</a>} />
      </Table>
    </div>
  );
};

export default AnnuityPayments;
