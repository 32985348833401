import {
  DownOutlined,
  FileOutlined,
  LeftOutlined,
  RightOutlined,
  UndoOutlined,
  UserAddOutlined,
  SettingOutlined
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Menu,
  Row,
  Select,
  Steps,
  Tabs,
  Tag,
  message,
  notification,
  Badge,
  Table,
  Tooltip,
  Modal,
} from "antd";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import {exe, safeGetRaw} from "Lib/Dal";
import Etiquetas from "./Etiquetas";
import FormHistory from "./FormHistory";
import FormRender from "../FormRender";
import Historia from "./Historia";
import Subir from "./Subir";
import Visor from "../Visor";
import Comentario from "./Comentario";
import ComentarioRender from "./ComentarioRender";
import ProcessEvents from "./ProcessEvents";
import { safeGet, safeValue } from "../../Lib/Dal";
import GroupSelect from "./GroupSelect";
import { withTranslation } from "react-i18next";
import FormRender2 from "../FormRender2";

const Option = Select.Option;
const Step = Steps.Step;
const TabPane = Tabs.TabPane;
const SubMenu = Menu.SubMenu;

class Actividad extends React.Component {
  usuarios = [];
  componentDidMount() {
    //carga de usuarios para combo responsbale
    exe("GetUsers").then((r) => this.setState({ usuarios: r.outData, grupos: [] }));

    if (this.props.location.proceso) {
      //entra desde launcher

      //obteniendo formulario inicial, si existe
      const parser = new DOMParser();
      this.xmlDoc = parser.parseFromString(this.props.location.proceso.xml, "text/xml");
      const formId = this.xmlDoc.getElementsByTagName("bpmn2:process")[0].children[0].getAttribute("form");
      const grupoId = this.xmlDoc.getElementsByTagName("bpmn2:process")[0].children[0].getAttribute("grupo");
      const estadoInicial = this.xmlDoc.getElementsByTagName("bpmn2:process")[0].children[0].getAttribute("id");
      this.setState({
        formId: formId ? formId : undefined,
        grupo: grupoId,
        nombre: this.props.location.proceso.name,
        estadoId: estadoInicial,
        responsable: localStorage.email,
        entity: this.props.location.entity,
        entityId: this.props.location.entityId,
        entityPath: this.props.location.entityPath
      });
      // } else if (this.props.location.actividadCargada) {
      //   //entra desde listado de actividades
      //   const actividad = this.props.location.actividadCargada;
      //   this.setState({
      //     nombre: actividad.name,
      //     actividad: actividad,
      //     formId: actividad.formId,
      //     valores: actividad.UserValues.find((p) => p.estadoId == actividad.estadoId),
      //     responsable: actividad.responsable,
      //     grupo: actividad.grupoId,
      //     estadoId: actividad.estadoId,
      //   });
    } else if (this.props.match.params.actividadId > 0) {
      //reload con carga desde BD
      exe("GetProcesses", {
        include: ["UserValues", "Archivos", "Comentarios", "Definicion", "Etiquetas", "Pasos", "UsuarioResponsable", "Events","Pasos.Group"],
        filter: "id=" + this.props.match.params.actividadId,
      }).then((r) => {
        if(r.ok){
          if (r.outData.length == 0) {
            message.error(this.props.t("Process not found"));
            return;
          }
          const process = r.outData[0];
          this.setState({
            nombre: process.name,
            actividad: process,
            formId: process.formId,
            grupo: process.grupoId,
            valores: process.UserValues.find((p) => p.estadoId == r.estadoId),
            responsable: process.responsable,
            estadoId: process.estadoId,
          });
        }else message.error(r.msg);
      });
    }
  }
  evalInContext(js, context) {
    return function () {
      return eval(js);
    }.call(context);
  }
  componentDidUpdate(prevProps, prevState) {
    if (!this.state.actividad) return;
    if (!prevState.actividad || this.state.actividad.entryLogic !== prevState.actividad.entryLogic) {
      if (this.state.actividad.entryLogic)
        this.evalInContext(this.state.actividad.entryLogic, { message: message, notification: notification, exe: exe, process: this.state.actividad });
    }
  }
  state = {
    loading: false,
    next: false,
    previous: false,
    formId: 0,
    actividad: null,
    estadoId: null,
    currentUser: window.localStorage.email,
    activeTab:"1"
  };

  onNext = () => {
    this.setState({ next: true }); //provoca validacion en FormRender
  };
  onPrevious = () => {
    this.setState({ next: true, previous: true }); //provoca validacion en FormRender
  };
  onFormOk = (f) => {
    console.log("form OK", f);
    if(!f) {
      //validation not passed in FormRender2
      this.setState({next: false});
      return;
    }
    //listo para avanzar
    if (!this.state.actividad) {
      //primer paso
      let dto = {
        definicionId: this.props.location.proceso.id,
        usuario: localStorage.email,
        userValues: JSON.stringify(f),
        responsable: this.state.responsable,
        entity: this.state.entity,
        entityId: this.state.entityId,
        entityPath: this.state.entityPath,
      };
      exe("StartProcess", dto).then((r) => {
        message.success(this.props.t("Task created"));
        const act = r.outData;
        this.setState({
          actividad: act,
          next: false,
          formId: act.formId,
          grupo: act.grupoId,
          responsable: act.responsable,
          estadoId: act.estadoId,
        });
      });
    } else {
      //actividad ya creada
      let dto = { procesoId: this.state.actividad.id, estado: this.state.previous ? "_previous" : "_next", userValues: JSON.stringify(f) };
      exe("GotoStep", dto).then((r) => {
        if (!r.ok) {
          message.error(r.msg);
          this.setState({ previous: false, next: false });
          return;
        }

        let valores = null;
        if (this.state.actividad.userValues && this.state.actividad.userValues.length > 0) {
          valores = this.state.actividad.userValues.find((p) => p.estadoId == r.outData.estadoId);
        }
        //message.success("Estado cambiaddo");
        this.setState({
          actividad: { ...r.outData, Comentarios: this.state.actividad.Comentarios },
          next: false,
          previous: false,
          formId: r.outData.formId,
          grupo: r.outData.grupoId,
          valores: valores,
          estadoId: r.outData.estadoId,
          responsable: r.outData.responsable,
        });
      });
    }
  };
  onChangeResponsable = (v) => {
    if (this.state.actividad) {
      //proceso cargado
      exe("SetResponsible", { procesoId: this.state.actividad.id, responsable: v }, {}, true).then((r) => {
        this.setState({ responsable: v });
      });
    } else {
      //proceso nuevo
      this.setState({ responsable: v });
    }
  };
  onChangeGrupo = (v) => {
    this.setState({loading:true});
    exe("SetGroup",{processId:this.state.actividad.id,groupId:v}).then(r=>{
      this.setState({loading:false});
      if(r.ok){
        message.success(r.msg);
        const historyRecord=r.outData.HistoryRecord;
        historyRecord.Group=r.outData.NewGroup;
        this.setState({ 
          grupo: v,actividad:{...this.state.actividad,groupId:v,Pasos:[...this.state.actividad.Pasos,historyRecord]} });
      }else message.error(r.msg);
    })
  };
  eliminar = () => {
    exe("DeleteProcess", this.state.actividad, {}, true).then((r) => {
      if (r.ok) {
        this.props.history.push("/launcher");
      }
    });
  };
  enableProcess = () => {
    exe("EnableProcessEvents", { processId: this.state.actividad.id }).then((r) => {
      if (r.ok) {
        this.setState({ actividad: { ...this.state.actividad, eventsDisabled: false } });
        message.success(r.msg);
      } else {
        message.error(r.msg);
      }
    });
  };
  //comentarios
  onComentario = (v) => {
    const dto = {
      procesoId: this.state.actividad.id,
      html: v,
      estado: this.state.actividad.estado,
      estadoId: this.state.actividad.estadoId,
    };
    exe("AddOrUpdateProcessMsg", dto, { operation: "ADD" }, true).then((r) => {
      if (r.ok) {
        const act = this.state.actividad;
        if (act.Comentarios) {
          act.Comentarios.push(r.outData);
        } else {
          act.Comentarios = [r.outData];
        }
        this.setState({ actividad: act });
      } else {
        notification.error({
          message: this.props.t("Error adding comment"),
          description: r.msg,
        });
      }
    });
  };
  onTakeTask = () => {
    const responsible = this.state.currentUser == this.state.responsable ? null : this.state.currentUser;

    if (this.state.actividad) {
      //proceso cargado
      exe("SetResponsible", { procesoId: this.state.actividad.id, responsable: responsible }, {}, true).then((r) => {
        if (r.ok) {
          this.setState({ responsable: responsible });
        }
      });
    }
  };
  onTabChange=tab=>{
    const me=this;
    if(this.state.activeTab==="1"&&tab==="5"){
      Modal.confirm({
        title: me.props.t('Leaving the tab. Are you sure?'),
        content: me.props.t('Switching to this tab will reset the current form'),
        onOk() {
          me.setState({activeTab: tab})
        }});
    }else {
      this.setState({activeTab:tab})
    }
  }

  render() {
    const { t, i18n } = this.props;
    const navegacion = (
      <Breadcrumb style={{ marginBottom: "20px" }}>
        <Breadcrumb.Item>
          <Link to="/launcher">{t("Home")}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {this.props.match.params.actividadId > 0 ? <Link to="/tasks">{t("Activities")}</Link> : <Link to="/launcher">{t("Process")}</Link>}
        </Breadcrumb.Item>
        <Breadcrumb.Item>{this.state.soloLectura ? t("Instance") : t("New")}</Breadcrumb.Item>
      </Breadcrumb>
    );
    const userActions = this.state.actividad && this.state.actividad.userActions ? JSON.parse(this.state.actividad.userActions) : [];

    let estadoGeneral = 0;
    if (this.state.actividad) {
      if (this.state.actividad.iniciando) {
        estadoGeneral = 0;
      } else if (this.state.actividad.finalizado || userActions.length > 0) {
        estadoGeneral = 2;
      } else {
        estadoGeneral = 1;
      }
    }

    let paso = t("Initial State");
    if (this.state.actividad) {
      paso = this.state.actividad.estado;
    }

    let nombreActividad = this.state.nombre;
    if (this.state.actividad) {
      nombreActividad = this.state.actividad.id + "." + this.state.actividad.nombre;
    }

    //etiquetas
    let etiquetas = [];
    if (this.state.actividad) {
      etiquetas = this.state.actividad.Etiquetas;
      // if(etiquetas){
      //     etiquetas.reverse();
      //     //etiquetas.unshift({nombre:"En Curso",id:0}); //temp
      // }
    }

    const procesoId = this.state.actividad ? this.state.actividad.id : 0;

    const usuarios = this.state.usuarios && this.state.usuarios.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((u) => <Option value={u.email}>{u.nombre}</Option>);
    let tiempo = false;
    if (this.state.actividad && this.state.actividad.sla > 0) {
      tiempo = moment(this.state.actividad.fSla).fromNow();
    }

    //comentarios
    const comentariosData =
      (this.state.actividad && this.state.actividad.Comentarios && this.state.actividad.Comentarios.length > 0 && this.state.actividad.Comentarios) || [];
    const Comentarios = comentariosData.map((p) => <ComentarioRender msg={p} />);
    
    return (
      <div>
        {navegacion}
        <Row>
          <Col md={18} xs={24}>
            <Card
              title={nombreActividad}
              extra={
                <div style={{ marginTop: -8, marginBottom: -8 }}>
                  <Tag color="#87d068">{paso}</Tag>
                  <Button
                    type="link"
                    icon={<LegacyIcon type={this.state.fullscreenWorkflow ? "minus-square" : "plus-square"} />}
                    onClick={() => this.setState({ fullscreenWorkflow: !this.state.fullscreenWorkflow })}
                  />
                </div>
              }
              style={{ margin: "5px" }}>
              <div style={{ display: this.state.hideWorkflow ? "none" : "block" }}>
                <Visor
                  actividad={this.state.actividad}
                  proceso={this.props.location.proceso}
                  estadoActual={this.state.estadoId}
                  fullScreen={this.state.fullscreenWorkflow}
                />
              </div>
              <div>
                <Button
                  style={{ float: "right" }}
                  type="link"
                  icon={<LegacyIcon type={this.state.hideWorkflow ? "minus-square" : "plus-square"} />}
                  onClick={() => this.setState({ hideWorkflow: !this.state.hideWorkflow })}
                />
                <Tabs onChange={tab=>this.onTabChange(tab)} activeKey={this.state.activeTab} >
                  <TabPane tab={t("Form")} key="1">
                    {/*{this.state.activeTab==="1"&&<FormRender modal formId={this.state.formId} next={this.state.next} onFormOk={this.onFormOk} valores={this.state.valores} processId={procesoId}  />}*/}
                    {this.state.activeTab!=="5"&&<FormRender2 formId={this.state.formId} next={this.state.next} onFormOk={this.onFormOk} valores={this.state.valores} processId={procesoId} />}
                  </TabPane>
                  <TabPane tab={t("Steps")+` (${safeGetRaw(["UserValues","length"],this.state.actividad,0)})`} key="5">
                    <FormHistory actividad={this.state.actividad} />
                  </TabPane>
                  <TabPane tab={t("Files")} key="2">
                    <Subir procesoId={procesoId} stepName={safeValue("actividad.estado", this.state, "")} />
                  </TabPane>
                  <TabPane
                    tab={
                      <Badge count={comentariosData.length} offset={[12, 0]} showZero={false}>
                        {t("Comments")}
                      </Badge>
                    }
                    key="3">
                    {Comentarios}
                    <Comentario onOk={this.onComentario} />
                  </TabPane>
                  <TabPane tab={t("History")} key="4">
                    <Historia actividad={this.state.actividad} />
                  </TabPane>
                  <TabPane tab={t("Events")} key="events">
                    <div>
                      <ProcessEvents value={safeGet(["actividad", "Events"], this.state, [])} />
                    </div>
                  </TabPane>
                </Tabs>
              </div>
            </Card>
          </Col>
          <Col md={6} xs={24}>
            <Card
              title={t("Control")}
              style={{ margin: "5px" }}
              extra={
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item key="nuevo" onClick={this.eliminar}>
                        <FileOutlined />
                        {t("Delete")}
                      </Menu.Item>
                      <Menu.Item key="enable" onClick={this.enableProcess} disabled={this.state.actividad ? !this.state.actividad.eventsDisabled : true}>
                        <UndoOutlined />
                        {t("Enable process")}
                      </Menu.Item>
                      <Menu.Item key="configure">
                        <Link to={{ pathname: "/proc", proceso: safeGet(["actividad","Definicion"],this.state,null) }}>
                            <SettingOutlined type="setting" style={{marginRight:5}} />
                            {t("Configure process")}
                        </Link>
                      </Menu.Item>
                    </Menu>
                  }>
                  <a className="ant-dropdown-link" href="javascript:void(0);">
                    {t("Options")} <DownOutlined />
                  </a>
                </Dropdown>
              }>
              <Button style={{ margin: "5px", width: "100%" }} type="primary" size="large" onClick={this.onNext} disabled={estadoGeneral == 2}>
                {t("Next")}
                <RightOutlined />
              </Button>{" "}
              <br />
              <Button style={{ margin: "5px", width: "100%" }} size="large" onClick={this.onPrevious} disabled={estadoGeneral == 0}>
                <LeftOutlined />
                {t("Previous")}
              </Button>
              <br />
              <Divider orientation="left"></Divider>
              <GroupSelect onChange={this.onChangeGrupo} value={this.state.grupo ? this.state.grupo : undefined} />
              <Select
                style={{ margin: "5px", width: "100%" }}
                allowClear={true}
                onChange={this.onChangeResponsable}
                value={this.state.responsable ? this.state.responsable : undefined}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                placeholder={t("Responsible")}>
                {usuarios}
              </Select>
              <Button icon={<UserAddOutlined />} onClick={() => this.onTakeTask()} style={{ margin: "5px", width: "100%" }}>
                {this.state.currentUser == this.state.responsable ? t("Release Task") : t("Take task")}
              </Button>
              {tiempo && (
                <Tag style={{ margin: "5px" }} color="red">
                  {t("Expires")} {tiempo}
                </Tag>
              )}
              <Divider orientation="left"></Divider>
              <Etiquetas etiquetas={etiquetas} procesoId={procesoId} />
              <Divider orientation="left"></Divider>
              {this.state.actividad && this.state.actividad.entityPath && (
                <a style={{ marginTop: 15 }} href={"#/" + this.state.actividad.entityPath}>
                  {t("Business Entity")}
                </a>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withTranslation()(Actividad);
