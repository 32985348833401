import { DeleteOutlined, DollarOutlined, FileOutlined, ReloadOutlined } from '@ant-design/icons';
import {Button, Divider, message, Popconfirm, Table, Tag, Tooltip} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import {formatDateShort, formatMoney, getColor} from "../../Lib/Helpers";
import TaxLiquidationRecord from "./TaxLiquidationRecord";
import Status from "../Shared/Status";

const TaxLiquidation = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [newLiquidationVisible, setNewLiquidationVisible] = useState(false);

  useEffect(() => {
    load();
  }, [props.taxSchemeId]);

  const load = () => {
    setLoading(true);
    exe("RepoTaxLiquidation", { operation: "GET",filter:`taxSchemeId=${props.taxSchemeId}`,include:["Process"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSave = () => {
    load();
    setNewLiquidationVisible(false);
  };
  const onExecute = (liquidations) => {
    setLoading(true);
    liquidations.forEach((liquidation) => {
      exe("ExeTaxLiquidation", { liquidationId: liquidation.id }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          load();
        } else {
          message.error(r.msg);
        }
      });
    });

    setNewLiquidationVisible(false);
  };

  const onDelete = (r) => {
    setLoading(true);
    exe("RepoTaxLiquidation", { operation: "DELETE", entity: r }).then((r) => {
      setLoading(false);
      load();
    });
  };
  const getStatusText = (v) => {
    if (v == 0) {
      return <Tag>{t("Draft")}</Tag>;
    } else if (v == 1) {
      return <Tag color="green">{t("Executed")}</Tag>;
    } else {
      return <Tag>{v}</Tag>;
    }
  };
  return (
    <div>
      <div style={{ display: !newLiquidationVisible ? "block" : "none" }}>
        <Button type="primary" icon={<FileOutlined />} onClick={() => setNewLiquidationVisible(true)}>
          {t("New")}
        </Button>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => load()}>
          {t("Reload")}
        </Button>
        <Table dataSource={data} loading={loading} rowKey="id">
          <Table.Column title={t("Id")} dataIndex="id" />
          <Table.Column title={t("Liquidation Name")} dataIndex="liquidationName" />
          <Table.Column title={t("Tax Name")} dataIndex="taxName" />
          <Table.Column title={t("Date")} dataIndex="creation" render={(v, r) => formatDateShort(v)} />
          <Table.Column title={t("Taxes")} dataIndex="taxes" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Deductions")} dataIndex="deducted" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Total")} dataIndex="total" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Status")} dataIndex="status" render={(v) => getStatusText(v)} />
          <Table.Column
              dataIndex="Process"
              title={t("Workflow")}
              render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load()} />}
          />
          <Table.Column
            title={t("Actions")}
            key="actions"
            render={(v, r) => (
              <div>
                <div style={{ display: r.id && r.status == 0 ? "block" : "none" }}>
                  <Tooltip title={t("Delete")}>
                    <Popconfirm title={t("Are you sure you want to delete this entry?")} onConfirm={() => onDelete(r)}>
                      <Button type="link" icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                  <Divider type="vertical" />
                  <Tooltip title={"Execute"}>
                    <Popconfirm title={t("Are you sure you want to execute this liquidation?")} onConfirm={() => onExecute([r])}>
                      <Button type="link" icon={<DollarOutlined />} />
                    </Popconfirm>
                  </Tooltip>
                </div>
                {r.status === 1 && <a href="#/payments">{t("Requests")}</a>}
              </div>
            )}
          />
        </Table>
      </div>
      <div style={{ display: newLiquidationVisible ? "block" : "none" }}>
        <TaxLiquidationRecord taxSchemeId={props.taxSchemeId} onCancel={() => setNewLiquidationVisible(false)} onSave={onSave} onExecute={onExecute} />
      </div>
    </div>
  );
};

export default TaxLiquidation;
