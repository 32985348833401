import React, {useEffect, useState, useTransition} from 'react';
import {exe, safeGet} from "../../Lib/Dal";
import { LeftOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, Table, message, Badge,Typography} from "antd";
import {formatDateShort, getConfigProfile} from "../../Lib/Helpers";
import {useTranslation} from "react-i18next";
import AuditForm from "./AuditForm";

const Audits = (props) => {
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [auditFormId,setAuditFormId]=useState();
    const [t]=useTranslation();
    
    useEffect(()=>{
        load();
    },[])

    useEffect(()=>{
        getConfigProfile().then((profile) => {
            setAuditFormId(safeGet(["Claim","auditFormId"], window.global.configProfile, undefined));
        });
    },[]);
    
    const claimId=props.claimId;
    
    const load=()=>{
        if(!claimId){
            setData([]);
            return;
        }
        setLoading(true);
        exe("RepoAudit",{operation:"GET",filter:`claimId=${claimId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    const onAuditFormSave=values=>{
        
        setLoading(true);
        values.claimId=props.claimId;
        const operation=values.id?"UPDATE":"ADD";
        exe("RepoAudit",{operation:operation,entity:values}).then(r=>{
            setLoading(false);
            if(r.ok){
                load();
                setSelected();
            }else message.error(r.msg);
        })
    }
    const renderStatus=v=>{
        switch (v) {
            case 0:
                return <span><Badge status="default" />{t("None")}</span>
            case 1:
                return <span><Badge status="processing" />{t("Ready for audit")}</span>
            case 2:
                return <span><Badge status="success" />{t("Audited")}</span>
        }
    }
    return (
        <div>
            {!selected&&<div>
                <Button type="primary" onClick={()=>setSelected({})} icon={<PlusOutlined />}>
                    {t("Add")}
                </Button>
                <Button type="link" icon={<ReloadOutlined />} onClick={() => load()}>
                    {t("Reload")}
                </Button>
                <Table dataSource={data} loading={loading} rowKey="id" style={{marginTop:5}}>
                    <Table.Column dataIndex="id" title={t("ID")} width={100} />
                    <Table.Column dataIndex="status" title={t("Status")} render={v=>renderStatus(v)} />
                    <Table.Column dataIndex="auditDate" title={t("Date")} render={v=>formatDateShort(v)} />
                    <Table.Column dataIndex="summary" title={t("Notes")} width={200} ellipsis  />
                    <Table.Column key="actions" title={t("Actions")} render={(v,r)=><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} />} />
                </Table>
            </div>}
            {selected&&<div>
                <Button type="link" icon={<LeftOutlined />} onClick={() => setSelected()}>
                    {t("Back")}
                </Button>
                <AuditForm onSave={onAuditFormSave} value={selected} formId={auditFormId} />
            </div>}
        </div>
    );
};

export default Audits;