import { FilterOutlined, PlusOutlined, ReloadOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, DatePicker, message, Modal, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import CessionsFilter from "./CessionsFilter";
import ReData from "./ReData";
import CessionAdd from "./CessionAdd";
import ReCessionGraph from '../Re/ReCessionGraph';
import { BarChartOutlined } from '@ant-design/icons';

const Cessions = (props) => {
  const [t, i18n] = useTranslation();
  const [filterVisible, setFilterVisible] = useState(false);
  const [data, setData] = useState(undefined);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [period, setPeriod] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [reload, doReload] = useState(0);
  const [operation, setOperation] = useState(undefined);
  const [recompute, setRecompute] = useState(false);
  const [cessionAddVisible,setCessionAddVisible]=useState(false);
  const [cessionGraphVisible,setCessionGraphVisible]=useState(false);
  const onSearch = (d) => {
    setData(d);
    setFilterVisible(false);
  };
  const computeRePeriod = () => {
    setLoading(true);
    exe("ComputeRePeriod", { date: period, recompute: recompute }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        message.success(r.msg);
      } else {
        message.error(r.msg);
      }
    });
  };
  const computeRe = () => {
    setLoading(true);
    exe("ComputeRe", { date: period, recompute: recompute }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        message.success(r.msg);
      } else {
        message.error(r.msg);
      }
    });
  };
  const computeRePeriodBackground = () => {
    setLoading(true);
    exe("PutMessage", {
      batch: "Reinsurance",
      value: `{cmd:"ComputeRePeriod",data:{date:"${period.format("YYYY-MM-DD")}",recompute:${recompute}}}`,
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success("Operation succesfuly sent to event bus");
      } else message.error(r.msg);
    });
  };
  const computeReBackground = () => {
    setLoading(true);
    exe("PutMessage", {
      batch: "Reinsurance",
      value: `{cmd:"ComputeRe",data:{date:"${period.format("YYYY-MM-DD")}",recompute:${recompute}}}`,
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success("Operation successfully sent to event bus");
      } else message.error(r.msg);
    });
  };
  const onExecute = () => {
    console.log(operation);
    if (operation == "ACCRUED") computeRePeriod();
    if (operation == "ISSUANCE") computeRe();
    if (operation == "ACCRUEDBACK") computeRePeriodBackground();
    if (operation == "ISSUANCEBACK") computeReBackground();
    setActionsVisible(false);
  };
const onCessionAdd=cession=>{
  setLoading(true);
  exe("AddCession",cession).then(r=>{
    setLoading(false);
    if(r.ok){
      setCessionAddVisible(false);
      message.success(r.msg);
      doReload(p=>p+1);
    }else message.error(r.msg);
  })
}
//getting policy id from url location.hash
const policyId=window.location.hash.split("?policyId=")[1];

console.log(policyId,props.match);
  return (
    <div>
      <DefaultPage
        title={t("Cessions")}
        icon="interaction"
        loading={loading}
        extra={
          <div>
            {policyId&&<Button icon={ <BarChartOutlined />} onClick={() => setCessionGraphVisible(true)} style={{ marginRight: 5 }}>
              {t("Cession Graph")}
            </Button>}
            <Button icon={<ReloadOutlined />} onClick={() => doReload((p) => p + 1)} style={{ marginRight: 5 }}>
              {t("Reload")}
            </Button>
            <Button icon={<PlusOutlined />} onClick={() =>setCessionAddVisible(true) } style={{ marginRight: 5 }}>
              {t("Add")}
            </Button>
            <Button icon={<ThunderboltOutlined />} onClick={() => setActionsVisible(true)} style={{ marginRight: 5 }}>
              {t("Actions")}
            </Button>
            <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
              {t("Filter")}
            </Button>
          </div>
        }>
          <Modal title={t("Cession Graph")} visible={cessionGraphVisible} onCancel={()=>setCessionGraphVisible(false)} onOk={()=>setCessionGraphVisible(false)} width={900} >
            <ReCessionGraph policyId={policyId} />
          </Modal>
        <ReData value={data} doReload={() => doReload((p) => p + 1)} reload={reload} />
        <CessionsFilter onSearch={(d) => onSearch(d)} visible={filterVisible} onClose={() => setFilterVisible(false)} doReload={reload} onLoading={v=>setLoading(v)} />
        <Modal
          title={t("Actions")}
          visible={actionsVisible}
          onCancel={() => setActionsVisible(false)}
          onOk={onExecute}
          okText="Execute"
          okButtonProps={{ disabled: period == null || !operation, loading: loading }}>
          <div style={{ width: 300 }}>
            <Form.Item label={t("Operation")}>
              <Select value={operation} onChange={(v) => setOperation(v)} style={{ width: "100%" }}>
                <Select.Option value="ACCRUED" disabled>
                  {t("Compute accrued period")}
                </Select.Option>
                <Select.Option value="ISSUANCE" disabled>{t("Compute issuance")}</Select.Option>
                <Select.Option value="ACCRUEDBACK">{t("Compute accrued period in background")}</Select.Option>
                <Select.Option value="ISSUANCEBACK">{t("Compute issuance in background")}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label={t("Recompute")}>
              <Checkbox onChange={(e) => setRecompute(e.target.checked)} value={recompute} />
            </Form.Item>
            <Form.Item label={t("Period")}>
              <DatePicker.MonthPicker onChange={(v) => setPeriod(v)} value={period} />
            </Form.Item>
          </div>
        </Modal>
        <CessionAdd visible={cessionAddVisible} onCancel={()=>setCessionAddVisible(false)} onOk={onCessionAdd} />
      </DefaultPage>
    </div>
  );
};

export default Cessions;
