import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Section from "../Shared/Section";
import LoadingEdition from "./LoadingEdition";

const AmendmentLoading = (props) => {
  const { t } = useTranslation();
  useEffect(() => {
    if (props.value) props.form.setFieldsValue(props.value);
  }, [props.value]);
  const field = props.form.getFieldDecorator;
  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <Section text={t("Amendment Options")}>
            {/* AMENDMENT OPTIONS ***********************************************************************/}
            <Form.Item label="Current Loading Values">
              {field("jOldCoverges", { rules: [{ required: true }], initialValue: JSON.stringify(props.policy.Coverages) })(
                <LoadingEdition disabled config={props.config} />
              )}
            </Form.Item>
            <Form.Item label="New Loading Values">
              {field("jNewCoverages", { rules: [{ required: true }], initialValue: JSON.stringify(props.policy.Coverages) })(
                <LoadingEdition config={props.config} policy={props.policy} />
              )}
            </Form.Item>
            {/* AMENDMENT OPTIONS ***********************************************************************/}
          </Section>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create({ onValuesChange: (props, changedValues, allValues) => props.onChange(allValues) })(AmendmentLoading);
