import React from 'react';
import {formatDate, formatDateShort} from "../../Lib/Helpers";
import {Tooltip} from "antd";

const DateCell = (props) => {
    return (
        <div style={{whiteSpace:"nowrap"}}>
            <Tooltip title={formatDate(props.value)}>
            {props.value?formatDateShort(props.value):null}
            </Tooltip>
        </div>
    );
};

export default DateCell;