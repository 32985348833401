import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {AutoComplete, message, Select} from "antd";

const ZipSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [options,setOptions]=useState([]);
    const [lastSearch,setLastSearch]=useState(''); //to avoid searching for same text again and again

    const onSearch = (searchText) => {
        if(!searchText){
            setOptions([]);
            onSelect(undefined);
            return;
        }
        if(!props.hasSector) return;
        //saving network request by not searching if the searchText returned no results in previous search
        if(lastSearch&options.length==0 && searchText.startsWith(lastSearch)) return;
        
        setLoading(true);
        exe("RepoSectorCatalog", {operation: "GET", filter: "code like '" + searchText + "%'", limit: 10}).then((r) => {
            if (r.ok) {
                setOptions(r.outData.map((d) => ({
                    value: d.id*-1,//antd requires unique values so this must be used as key. I want code to be used as key but it is not unique
                    label: `${d.code} - ${d.name}`,
                    key: d.id,
                    code: d.code,
                })));
            }
            setLoading(false);
        });
        setLastSearch(searchText);
    };
    const onSelect = (value,op) => {
        props.onChange(value);
        //sending key and value to parent
        if(op&&props.onSelect){
            props.onSelect(op.key,op.value);
        }
    }
    const itemSelected=options.find((o)=>o.value==props.value);
    const computedValue = props.value<0?itemSelected?itemSelected.code:undefined:props.value; //negative values are used for sector selection id
    return (
        <AutoComplete loading={loading}
        value={computedValue}
        onChange={props.onChange}
        options={options}
        onSelect={onSelect}
        onSearch={onSearch}
      />
    );
};

export default ZipSelect;