import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, Checkbox, message, Table} from "antd";
import {exe} from "../../Lib/Dal";
import Status from "../Shared/Status";
import {formatDate, formatMoney, getColor} from "../../Lib/Helpers";
import {useTranslation} from "react-i18next";

const Imbalances = (props) => {
    const [data,setData] = useState([]);
    const [loading,setLoading] = useState(false);
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.workspaceId) load(props.workspaceId);
    },[props.workspaceId]);

    useEffect(()=>{
        if(props.workspaceId) load(props.workspaceId);
    },[props.reload]);
    
    
    const load = (workspaceId) => {
        setLoading(true);
        exe("RepoImbalance",{operation:"GET",include:["Process","PaymentMethod"],filter:`workspaceId=${workspaceId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    const onExecuteImbalance = record => {
        setLoading(true);
        exe("DepositImbalance",{imbalanceId:record.id}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(t("Imbalance executed"));
                load(props.workspaceId);
            }else message.error(r.msg);
        })
    }
    return (
        <div>
            <Button type={"link"} icon={<LegacyIcon type={"reload"} />} onClick={()=>load(props.workspaceId)}>{t("Reload")}</Button>
            <Table dataSource={data} loading={loading}>
                <Table.Column title="Date" dataIndex="date" render={v=>formatDate(v)}/>
                <Table.Column title="Payment Method" dataIndex={["PaymentMethod","name"]}/>
                <Table.Column title="Currency" dataIndex="currency"/>
                <Table.Column title="Amount" dataIndex="amount" render={(v,r)=>formatMoney(v,r.currency)}/>
                <Table.Column title="User" dataIndex="user"/>
                <Table.Column title="Executed" dataIndex="executed" render={v=><Checkbox checked={v} /> }/>
                <Table.Column title="Actions" key="action" render={(v,r)=><div>
                    <Button type={"link"} icon={<LegacyIcon type={"upload"} />} onClick={()=>onExecuteImbalance(r)} disabled={r.executed}>{t("Execute")}</Button> 
                </div>}/>
                <Table.Column
                    dataIndex="Process"
                    title={t("Workflow")}
                    render={(v) => <Status process={v} color={getColor(v && v.entityState)}  reload={() => load(props.workspaceId)} />}
                />
                
            </Table>
        </div>
    );
};

export default Imbalances;