import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message, Tag} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import {formatDate, formatMoney} from "../../Lib/Helpers";
import SubClaimNew from "./SubClaimNew";
import Paragraph from "antd/lib/typography/Paragraph";
import LongTextColumn from "../Shared/LongTextColumn";

const SubClaims = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [newSubClaimVisible,setNewSubClaimVisible]=useState(false);
    
    useEffect(()=>{
        if(props.claimId) load();
    },[props.claimId])
    
    const load=()=>{
        setLoading(true);
        exe("RepoClaim",{operation:"GET",filter:`masterClaimId=${props.claimId}`,include:["Process","Payouts"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }

    const onSubClaim=(values)=> {
        setLoading(true);
        exe("AddSubClaim", { claimId: props.claimId,...values }).then((r) => {
            setLoading(false);
            if (r.ok) {
                setNewSubClaimVisible(false);
                message.success(r.msg);
                load();
            } else {
                message.error(r.msg);
            }
        });
    }

    
    return (
        <div>
            <Button type={"primary"} icon={<LegacyIcon type={"plus"} />} onClick={()=>setNewSubClaimVisible(true)}>{t("New")}</Button>
            <Button type={"link"} icon={<LegacyIcon type={"reload"} />} onClick={()=>load()}>{t("Reload")}</Button>
            <Table dataSource={data} rowKey={"id"} style={{marginTop:5}} loading={loading}>
                <Table.Column title={t("Id")} dataIndex={"id"} />
                <Table.Column title={t("Code")} dataIndex={"code"} render={v=><span style={{whiteSpace:"nowrap"}}>{v}</span>} />
                <Table.Column title={t("Created")} dataIndex={"created"} render={v=><LongTextColumn value={formatDate(v)} rows={2} />} />
                <Table.Column title={t("Reserved")} dataIndex={"Payouts"} render={v=>formatMoney(v.reduce((acc,p)=>p.reserved+acc,0),props.currency)} />
                <Table.Column title={t("Paid")} dataIndex={"Payouts"} render={v=>formatMoney(v.reduce((acc,p)=>p.payed+acc,0),props.currency)} />
                <Table.Column title={t("Description")} dataIndex={"description"} width={250}  render={v => <LongTextColumn value={v} rows={2} />}  />
                <Table.Column title={t("Status")} dataIndex={"Process"} render={v=><Tag>{v?v.estado:null}</Tag>} />
                <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div>
                    <a href={`#/healthclaim/${r.id}`}>{t("Open")}</a>
                    {/*<Button type={"link"} icon={"folder-open"} onClick={()=>onOpenSubClaim(r.id)}>{t("Open")}</Button>*/}
                </div>} />
            </Table>
            <SubClaimNew visible={newSubClaimVisible} onOk={onSubClaim} onCancel={()=>setNewSubClaimVisible(false)} 
            productCode={props.productCode}
            validEventReasons={props.validEventReasons}
            insuredEventsTable={props.insuredEventsTable} />
        </div>
    );
};

export default SubClaims;