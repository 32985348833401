import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, fetchProxy } from "../../Lib/Dal";

const TemplateSelect = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => getTemplates(), []);
  const getTemplates = () => {
    fetchProxy("/templates", "GET").then((r) => {
      if ((props.userSelectableTemplates || []).length == 0) {
        //all templates
        setData(r.outData || []);
      } else setData((r.outData || []).filter((p) => (props.userSelectableTemplates || []).includes(p.name)));
    });
  };
  return (
    <div>
      <Select
        style={{ width: "100%" }}
        allowClear
        placeholder={t("Select Template")}
        onChange={(v) => props.onChange(v)}
        showSearch
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={props.value || undefined}
        loading={props.loading}
        disabled={props.disabled}>
        {data.map((p) => (
          <Select.Option value={p.name} key={p.name}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default TemplateSelect;
