import { useTranslation } from "react-i18next";
import DefaultPage from "../Shared/DefaultPage";
import { SearchOutlined} from '@ant-design/icons';
import React,{ useEffect, useState } from "react";
import { Button } from "antd";
import ContributionData from "./ContributionData";

const Contributions = props => {
    const [filterVisible, setFilterVisible] = useState(false);
    const [t]=useTranslation();
   
    return <DefaultPage title={t("Contributions")} icon="download" routes={{
        routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Contributions"), path: "contributions" }
        ]
    }}
        extra={<div>
            <Button type="primary" icon={<SearchOutlined />} onClick={() => setFilterVisible(true)} >{t("Filter")}</Button>
            </div>}>
        <ContributionData filterVisible={filterVisible} />
    </DefaultPage>
}
export default Contributions;