import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";

const SupervisorSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => load(), [props.comTreeId]);

    const load = () => {
        if (!props.comTreeId) return;
        setLoading(true);
        exe("RepoComTreeMember", {operation:"GET", filter: "comTreeId=" + props.comTreeId,include:["Contact"]}).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else {
                message.error(r.msg);
            }
        });
    };
    return (
        <div>
            <Select
                value={props.value}
                style={{width:"100%"}}
                onChange={props.onChange}
                loading={loading}
                disabled={!props.comTreeId || props.disabled}
                allowClear
                showSearch
                optionFilterProp="children">
                {data.map((v) => (
                    <Select.Option value={v.id} key={v.id}>
                       {v.Contact.FullName} - {v.position}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default SupervisorSelect;
