import { FlagOutlined } from '@ant-design/icons';
import { Button, message, Table } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDate, getColor } from "../../Lib/Helpers";
import Status from "../Shared/Status";

const AmlCases = (props) => {
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);
  const onStartCase = () => {
    setLoading(true);
    exe("AddAmlCase", { contactId: props.contactId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        props.onChange([...props.value, r.outData]);
      } else {
        message.error(r.msg);
      }
    });
  };
  const reload = () => {
    setLoading(true);
    exe("GetAmlCase", { filter: "contactId=" + props.contactId, include: ["Process"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.onChange(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Button type="link" icon={<FlagOutlined />} onClick={() => onStartCase()} loading={loading}>
        {t("Start Case")}
      </Button>
      <Table dataSource={props.value}>
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("Created")} dataIndex="created" render={(v) => formatDate(v)} />
        <Table.Column title={t("Note")} dataIndex="note" />
        <Table.Column title={t("Policy")} dataIndex="policyId" render={(v) => <a href={"#/lifePolicy/" + v}>{v}</a>} />
        <Table.Column title={t("Status")} dataIndex="entityState" render={(v) => <Text code>{v}</Text>} />
        <Table.Column title={t("Process")} dataIndex="processId" />
        <Table.Column
          dataIndex="Process"
          title={t("Workflow")}
          render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => reload()} />}
        />
      </Table>
    </div>
  );
};

export default AmlCases;
