import React from "react";
import { UserOutlined } from '@ant-design/icons';

const ContactLink = (props) => {
  const contact = props.contact;
  if (!contact || !contact.id) return null;

  return (
    <div style={{ whiteSpace: "nowrap" }}>
      <a href={"#/contact/" + contact.id}>
        <UserOutlined style={{ marginRight: 5 }} />
        {contact.FullName ? contact.FullName : contact.id}
      </a>
    </div>
  );
};

export default ContactLink;
