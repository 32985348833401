import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
} from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import FormLabel from "../Shared/FormLabel";
import Money from "../Shared/Money";
import Section from "../Shared/Section";
import moment from "moment";
import CustomForm from "../Shared/CustomForm";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const CoverageClaim = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [claimFormId, setClaimFormId] = useState();
  const [appliesTo, setAppliesTo] = useState();
  const [customIndemnityValues, setCustomIndemnityValues] = useState();
  const field = props.form.getFieldDecorator;
  const coverageType = props.claimType;

  useEffect(() => {
    if (props.record !== null) {
      props.form.setFieldsValue(props.record);
    } else {
      props.form.resetFields();
    }
    const covConfig = props.config.Coverages.find((p) => p.code == props.coverageCode);
    if (covConfig && covConfig.claimFormId) {
      setClaimFormId(covConfig.claimFormId);
    } else setClaimFormId(undefined);
    if (covConfig && covConfig.appliesTo) {
      setAppliesTo(covConfig.appliesTo);
    } else setAppliesTo(undefined);
    if (covConfig && covConfig.customIndemnityValues) {
      setCustomIndemnityValues(covConfig.customIndemnityValues);
    } else setCustomIndemnityValues(false);
  }, [props.record]);

  const save = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      //validating custom form
      if (window.claimForm) {
        if (window.claimForm.instanceContainers[0].reportValidity()) {
          values.jClaimForm = JSON.stringify(window.claimForm.userData);
        } else {
          message.error(t("Please check claim form validation errors"));
          return;
        }
      }
      setLoading(true);
      values.claimId = props.claimId;
      values.lifeCoverageId = props.coverageId;
      const repoName =
        !coverageType || customIndemnityValues
          ? "RepoLifeCoverageClaim"
          : "RepoLifeCoverageClaim" + coverageType.charAt(0) + coverageType.substring(1).toLowerCase();
      exe(repoName, { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          props.form.setFieldsValue(r.outData[0]);
          props.reload&&props.reload();
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onAdmissionChange = (v) => {
    const discharge = props.form.getFieldValue("dateOfDischarge");
    if (v && discharge) {
      props.form.setFieldsValue({ daysOfHospitalization: discharge.diff(v, "days") });
    } else {
      props.form.setFieldsValue({ daysOfHospitalization: 0 });
    }
  };
  const onDischargeChange = (v) => {
    const admission = props.form.getFieldValue("dateOfAdmission");
    if (v && admission) {
      props.form.setFieldsValue({ daysOfHospitalization: v.diff(admission, "days") });
    } else {
      props.form.setFieldsValue({ daysOfHospitalization: 0 });
    }
  };
  const onDelete = () => {
    const recordId = props.form.getFieldValue("id");
    if (!recordId) return;
    const repoName =
      !coverageType || customIndemnityValues
        ? "RepoLifeCoverageClaim"
        : "RepoLifeCoverageClaim" + coverageType.charAt(0) + coverageType.substring(1).toLowerCase();
    setLoading(true);
    exe(repoName, { operation: "DELETE", entity: { id: recordId } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        props.form.resetFields();
        props.reload&&props.reload();
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      {field("id")}
      {field("claimId")}
      {field("lifeCoverageId")}
      {field("jClaimForm")}
      <div>
        <Button type="link" icon={<SaveOutlined />} onClick={save} disabled={!props.claimId}>
          {t("Save")}
        </Button>
        <Popconfirm title={t("Are you sure you want to delete this indemnity record?")} onConfirm={onDelete}>
          <Button type="link" icon={<DeleteOutlined />} disabled={!props.claimId}>
            {t("Remove")}
          </Button>
        </Popconfirm>
        <Section text={t("Main Information")}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Elegibility Date")}>
                {field("eventDate", { normalize: (v) => (v ? moment(v) : undefined), rules: [{ required: true }] })(<DatePickerW />)}
              </Form.Item>
              <Form.Item label={t("Event Details")}>{field("eventDetails", {})(<Input.TextArea />)}</Form.Item>
              <Form.Item label={t("Claim Type")}>{field("claimType", { initialValue: coverageType })(<FormLabel code />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Contract Status Reason")}>{field("contractStatusReason", {})(<Input disabled={!customIndemnityValues} />)}</Form.Item>
              <Form.Item label={t("Calculation Details")}>{field("calculationDetails", {})(<Input.TextArea />)}</Form.Item>
              <Form.Item label={t("Applies To")}>{field("appliesTo", { initialValue: appliesTo })(<FormLabel code />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Sum Assured")}>{field("sumAssured", {})(<Money currency={props.currency} disabled={!customIndemnityValues} />)}</Form.Item>
              <Form.Item label={t("Final Indemnity Value")}>
                {field("finalIndemnityValue", {})(<Money currency={props.currency} disabled={!customIndemnityValues} />)}
              </Form.Item>
            </Col>
          </Row>
        </Section>
        {claimFormId && (
          <Section text={t("Coverage Form")}>
            <CustomForm formId={claimFormId} variable="claimForm" value={props.form.getFieldValue("jClaimForm")} />
          </Section>
        )}
      </div>
      {coverageType == "DTH" && !customIndemnityValues && (
        <div>
          <Section text={t("Death by any cause")}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label={t("Claims File Creation Date")}>
                  {field("claimsFileCreation", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW disabled />)}
                </Form.Item>
                {/* <Form.Item label="Annuity Value">{field("annuityValue", {})(<Money currency={props.currency} />)}</Form.Item> */}
                {/* <Form.Item label="Last Annual Premium Value">{field("lastAnnualPremium", {})(<Money currency={props.currency} />)}</Form.Item> */}
                <Form.Item label={t("Contract Start Date")}>
                  {field("contractStartDate", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW disabled />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                {/* <Form.Item label="Next Due Date">{field("nextDueDate", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW disabled />)}</Form.Item>
                <Form.Item label="Frequency">{field("frequency", {})(<Input disabled />)}</Form.Item> */}
                {/* <Form.Item label="Fraction of Years">{field("fractionOfYears", {})(<Input />)}</Form.Item> */}
                {/* <Form.Item label="Profit Sharing Value">{field("profitSharingValue", {})(<Input />)}</Form.Item> */}
                <Form.Item label={t("Reduced Sum Assured")}>{field("reducedSumAssured", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Account Value")}>{field("accountValue", {})(<Money currency={props.currency} />)}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Partial Surrender Values")}>{field("partialSurrenderValues", {})(<Money currency={props.currency} />)}</Form.Item>
              </Col>
            </Row>
          </Section>
        </div>
      )}

      {coverageType == "HOSP" && !customIndemnityValues && (
        <div>
          <Section text={t("Hospitalization by accident")}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label={t("Date of Admission")}>
                  {field("dateOfAdmission", { normalize: (v) => (v ? moment(v) : undefined), rules: [{ required: true }] })(<DatePickerW />)}
                </Form.Item>
                <Form.Item label={t("Date of Discharge")}>
                  {field("dateOfDischarge", { normalize: (v) => (v ? moment(v) : undefined), rules: [{ required: true }] })(<DatePickerW />)}
                </Form.Item>
                <Form.Item label={t("Next Anniversary Date")}>
                  {field("nextAnniversaryDate", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW disabled />)}
                </Form.Item>
                <Form.Item label={t("Deductible Days")}>{field("deductibleDays", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Max Hospitalization Days")}>{field("maxHospitalizationDays", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Coverage End Date")}>
                  {field("coverageEndDate", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW disabled />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Max Hospitalization Days after Maturity")}>
                  {field("maxHospitalizationDaysAfterMaturity", {})(<InputNumber disabled />)}
                </Form.Item>
                <Form.Item label={t("Number of Active Days")}>{field("numberOfActiveDays", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Event Number")}>{field("eventNumber", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Hospitalization Days in contractual year")}>{field("hospitalizationDaysNumber", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Min Sum Assured Ever")}>{field("minSumAssuredEver", {})(<Money currency={props.currency} disabled />)}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Bonus Percentage")}>{field("bonusPercentage", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Bonus Sum Assured")}>{field("bonusSumAssured", {})(<Money currency={props.currency} disabled />)}</Form.Item>
                <Form.Item label={t("Days of Hospitalization")}>{field("daysOfHospitalization", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Days of Hospitalization after Anniversary")}>
                  {field("daysOfHospitalizationAfterAnniversary", {})(<InputNumber disabled />)}
                </Form.Item>

                <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
              </Col>
            </Row>
          </Section>
        </div>
      )}
      {coverageType == "CONV" && !customIndemnityValues && (
        <div>
          <Section text={t("Convalescence by accident")}>
            <Row gutter={16}>
              <Col span={8}>
                {/* <Form.Item label="Start Date of Incapacity">{field("startDateOfIncapacity", {})(<DatePickerW />)}</Form.Item> */}
                {/* <Form.Item label="End Date of Incapacity">{field("endDateOfIncapacity", {})(<DatePickerW />)}</Form.Item> */}
                {/* <Form.Item label="Hospitalized">{field("hospitalized", {})(<Checkbox />)}</Form.Item> */}
                <Form.Item label={t("Date of Admission")}>
                  {field("dateOfAdmission", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}
                </Form.Item>
                <Form.Item label={t("Date of Discharge")}>
                  {field("dateOfDischarge", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}
                </Form.Item>
                <Form.Item label={t("Deductible Days")}>{field("deductibleDays", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Max Hospitalization Days")}>{field("maxHospitalizationDays", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Coverage End Date")}>
                  {field("coverageEndDate", { normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW disabled />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Max Hospitalization Days after Maturity")}>
                  {field("maxHospitalizationDaysAfterMaturity", {})(<InputNumber disabled />)}
                </Form.Item>
                <Form.Item label={t("Event Number")}>{field("eventNumber", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Convalescence Days Number")}>{field("convalescenceDaysNumber", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Min Sum Assured Ever")}>{field("minSumAssuredEver", {})(<Money currency={props.currency} disabled />)}</Form.Item>
                <Form.Item label={t("Bonus Percentage")}>{field("bonusPercentage", {})(<InputNumber disabled />)}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Bonus Sum Assured")}>{field("bonusSumAssured", {})(<Money currency={props.currency} disabled />)}</Form.Item>
                <Form.Item label={t("Days of Convalescence")}>{field("daysOfConvalescence", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Multiplication Factor")}>{field("multiplicationFactor", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
              </Col>
            </Row>
          </Section>
        </div>
      )}
      {/* {coverageType == "INC.HACJ" && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Date of Admission">{field("dateOfAdmission", {})(<Input />)}</Form.Item>
              <Form.Item label="Date of Discharge">{field("dateOfDischarge", {})(<Input />)}</Form.Item>
              <Form.Item label="Event Details">{field("eventDetails", {})(<Input />)}</Form.Item>
              <Form.Item label="Next Anniversary Date">{field("nextAnniversaryDate", {})(<Input />)}</Form.Item>
              <Form.Item label="Deductible Days">{field("deductibleDays", {})(<Input />)}</Form.Item>
              <Form.Item label="Contract Status Reason">{field("contractStatusReason", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Max Hospitalization Days">{field("maxHospitalizationDays", {})(<Input />)}</Form.Item>
              <Form.Item label="Coverage End Date">{field("coverageEndDate", {})(<Input />)}</Form.Item>
              <Form.Item label="Max Hospitalization Days after Maturity">{field("maxHospitalizationDaysAfterMaturity", {})(<Input />)}</Form.Item>
              <Form.Item label="Event Number">{field("eventNumber", {})(<Input />)}</Form.Item>
              <Form.Item label="Hospitalization Days Number">{field("hospitalizationDaysNumber", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Min Sum Assured Ever">{field("minSumAssuredEver", {})(<Input />)}</Form.Item>
              <Form.Item label="Bonus Percentage">{field("bonusPercentage", {})(<Input />)}</Form.Item>
              <Form.Item label="Sum Assured">{field("sumAssured", {})(<Input />)}</Form.Item>
              <Form.Item label="Bonus Sum Assured">{field("bonusSumAssured", {})(<Input />)}</Form.Item>
              <Form.Item label="Days of Hospitalization">{field("daysOfHospitalization", {})(<Input />)}</Form.Item>
              <Form.Item label="Days of Hospitalization after Anniversary">{field("daysOfHospitalizationAfterAnniversary", {})(<Input />)}</Form.Item>
              <Form.Item label="Retained Value">{field("retainedValue", {})(<Input />)}</Form.Item>
              <Form.Item label="Calculation Details">{field("calculationDetails", {})(<Input />)}</Form.Item>
              <Form.Item label="Final Indemnity Value">{field("finalIndemnityValue", {})(<Input />)}</Form.Item>
            </Col>
          </Row>
        </div>
      )} */}

      {coverageType == "SURG" && !customIndemnityValues && (
        <div>
          <Section text={t("Surgical intervention by accident")}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label={t("Max Percentage")}>{field("maxPercentage", {})(<InputNumber />)}</Form.Item>
                <Form.Item label={t("Used Percentage")}>{field("usedPercentage", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("New Percentage")}>{field("newPercentage", {})(<InputNumber />)}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Final Percentage")}>{field("finalPercentage", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Min Sum Assured Ever")}>{field("minSumAssuredEver", {})(<Money currency={props.currency} disabled />)}</Form.Item>
                <Form.Item label={t("Bonus Percentage")}>{field("bonusPercentage", {})(<InputNumber disabled />)}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Bonus Sum Assured")}>{field("bonusSumAssured", {})(<Money currency={props.currency} disabled />)}</Form.Item>
                <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
              </Col>
            </Row>
          </Section>
        </div>
      )}

      {/* {coverageType == "INC.SIACJ" && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Elegibility Date">{field("eligibilityDate", {})(<Input />)}</Form.Item>
              <Form.Item label="Contract Status Reason">{field("contractStatusReason", {})(<Input />)}</Form.Item>
              <Form.Item label="Event Details">{field("eventDetails", {})(<Input />)}</Form.Item>
              <Form.Item label="Max Percentage">{field("maxPercentage", {})(<Input />)}</Form.Item>
              <Form.Item label="Used Percentage">{field("usedPercentage", {})(<Input />)}</Form.Item>
              <Form.Item label="New Percentage">{field("newPercentage", {})(<Input />)}</Form.Item>
              <Form.Item label="Final Percentage">{field("finalPercentage", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Min Sum Assured Ever">{field("minSumAssuredEver", {})(<Input />)}</Form.Item>
              <Form.Item label="Bonus Percentage">{field("bonusPercentage", {})(<Input />)}</Form.Item>
              <Form.Item label="Sum Assured">{field("sumAssured", {})(<Input />)}</Form.Item>
              <Form.Item label="Bonus Sum Assured">{field("bonusSumAssured", {})(<Input />)}</Form.Item>
              <Form.Item label="Retained Value">{field("retainedValue", {})(<Input />)}</Form.Item>
              <Form.Item label="Calculation Details">{field("calculationDetails", {})(<Input />)}</Form.Item>
              <Form.Item label="Final Indemnity Value">{field("finalIndemnityValue", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}></Col>
          </Row>
        </div>
      )} */}

      {coverageType == "DISAB" && !customIndemnityValues && (
        <div>
          <Section text={t("Permanent Disability by Accident")}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label={t("Max Percentage")}>{field("maxPercentage", {})(<InputNumber />)}</Form.Item>
                <Form.Item label={t("Used Percentage")}>{field("usedPercentage", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("New Percentage")}>{field("newPercentage", {})(<InputNumber />)}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Final Percentage")}>{field("finalPercentage", {})(<InputNumber disabled />)}</Form.Item>
                <Form.Item label={t("Min Sum Assured Ever")}>{field("minSumAssuredEver", {})(<Money currency={props.currency} disabled />)}</Form.Item>
                <Form.Item label={t("Bonus Percentage")}>{field("bonusPercentage", {})(<InputNumber disabled />)}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Bonus Sum Assured")}>{field("bonusSumAssured", {})(<Money currency={props.currency} disabled />)}</Form.Item>
                <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
              </Col>
            </Row>
          </Section>
        </div>
      )}

      {coverageType == "ACC.AD" && !customIndemnityValues && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Min Sum Assured Ever")}>{field("minSumAssuredEver", {})(<Money currency={props.currency} />)}</Form.Item>
              <Form.Item label={t("Bonus Percentage")}>{field("bonusPercentage", {})(<InputNumber />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Bonus Sum Assured")}>{field("bonusSumAssured", {})(<Money currency={props.currency} />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
            </Col>
          </Row>
        </div>
      )}

      {coverageType == "ACC.ADC" && !customIndemnityValues && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
        </div>
      )}

      {coverageType == "DIS.DD" && !customIndemnityValues && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Diagnosis Date")}>{field("diagnosisDate", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Days of Waiting Period")}>{field("daysOfWaitingPeriod", {})(<InputNumber />)}</Form.Item>
              <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
              <Form.Item label={t("Contract Start Date")}>{field("contractStartDate", {})(<DatePickerW />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Dread Diseases List")}>{field("dreadDiseasesList", {})(<Input />)}</Form.Item>
              <Form.Item label={t("Payment Date 1")}>{field("paymentDate1", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Payment Date 2")}>{field("paymentDate2", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Payment Date 3")}>{field("paymentDate3", {})(<DatePickerW />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Eligible for Payment")}>{field("eligibleForPayment", {})(<Input />)}</Form.Item>
              <Form.Item label={t("Payment Date 1 Eligible")}>{field("paymentDate1Eligible", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Payment Date 2 Eligible")}>{field("paymentDate2Eligible", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Payment Date 3 Eligible")}>{field("paymentDate3Eligible", {})(<DatePickerW />)}</Form.Item>
            </Col>
          </Row>
        </div>
      )}

      {coverageType == "ACCDIS.ADPD" && !customIndemnityValues && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
              <Form.Item label={t("Guaranteed Maturity Capital")}>{field("guaranteedMaturityCapital", {})(<Money currency={props.currency} />)}</Form.Item>
              <Form.Item label={t("Diasbility percentage")}>{field("diasbilityPercentage", {})(<InputNumber />)}</Form.Item>
              <Form.Item label={t("Max Value")}>{field("maxValue", {})(<Money currency={props.currency} />)}</Form.Item>
              <Form.Item label={t("Double Indemnity")}>{field("doubleIndemnity", {})(<Checkbox />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Contract Start Date")}>{field("contractStartDate", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Dread Diseases List")}>{field("dreadDiseasesList", {})(<Input />)}</Form.Item>
              <Form.Item label={t("Payment Date 1")}>{field("paymentDate1", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Payment Date 2")}>{field("paymentDate2", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Payment Date 3")}>{field("paymentDate3", {})(<DatePickerW />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Eligible for Payment")}>{field("eligibleForPayment", {})(<Input />)}</Form.Item>
              <Form.Item label={t("Payment Date 1 Eligible")}>{field("paymentDate1Eligible", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Payment Date 2 Eligible")}>{field("paymentDate2Eligible", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Payment Date 3 Eligible")}>{field("paymentDate3Eligible", {})(<DatePickerW />)}</Form.Item>
            </Col>
          </Row>
        </div>
      )}

      {coverageType == "DIS.TPDIS" && !customIndemnityValues && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
              <Form.Item label={t("Diasbility percentage 1")}>{field("diasbilityPercentage1", {})(<InputNumber />)}</Form.Item>
              <Form.Item label={t("Diasbility percentage rest")}>{field("diasbilityPercentageRest", {})(<InputNumber />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Full Payment")}>{field("fullPayment", {})(<Input />)}</Form.Item>
              <Form.Item label={t("Final Indemnity Value 1")}>{field("finalIndemnityValue1", {})(<Money currency={props.currency} />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Final Indemnity Value Rest")}>{field("finalIndemnityValueRest", {})(<Money currency={props.currency} />)}</Form.Item>
              <Form.Item label={t("Final Indemnity Value Full")}>{field("finalIndemnityValueFull", {})(<Money currency={props.currency} />)}</Form.Item>
            </Col>
          </Row>
        </div>
      )}

      {coverageType == "DIS.DD" && !customIndemnityValues && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Dread Disease Percentage 1")}>{field("dreadDiseasePercentage1", {})(<Input />)}</Form.Item>
              <Form.Item label={t("Dread Disease Percentage 2")}>{field("dreadDiseasePercentage2", {})(<Input />)}</Form.Item>
              <Form.Item label={t("Dread Disease Percentage 3")}>{field("dreadDiseasePercentage3", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Final Indemnity Value 1")}>{field("finalIndemnityValue1", {})(<Money currency={props.currency} />)}</Form.Item>
              <Form.Item label={t("Final Indemnity Value 2")}>{field("finalIndemnityValue2", {})(<Money currency={props.currency} />)}</Form.Item>
              <Form.Item label={t("Final Indemnity Value 3")}>{field("finalIndemnityValue3", {})(<Money currency={props.currency} />)}</Form.Item>
            </Col>
            <Col span={8}></Col>
          </Row>
        </div>
      )}

      {coverageType == "DTH.DAC" && !customIndemnityValues && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Money currency={props.currency} />)}</Form.Item>
            </Col>
            <Col span={8}></Col>
            <Col span={8}></Col>
          </Row>
        </div>
      )}

      {coverageType == "INC.HACCO" && !customIndemnityValues && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label={t("Date of Admission")}>{field("dateOfAdmission", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Date of Discharge")}>{field("dateOfDischarge", {})(<DatePickerW />)}</Form.Item>
              <Form.Item label={t("Max Hospitalization Days")}>{field("maxHospitalizationDays", {})(<InputNumber />)}</Form.Item>
              <Form.Item label={t("Coverage End Date")}>{field("coverageEndDate", {})(<DatePickerW />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Days of Hospitalization")}>{field("daysOfHospitalization", {})(<InputNumber />)}</Form.Item>
              <Form.Item label={t("Convalescence Multiplication Factor")}>{field("convalescenceMultiplicationFactor", {})(<InputNumber />)}</Form.Item>
              <Form.Item label={t("Double the Indemnity")}>{field("doubleTheIndemnity", {})(<Input />)}</Form.Item>
              <Form.Item label={t("Retained Value")}>{field("retainedValue", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Event Type Birth")}>{field("eventTypeBirth", {})(<Input />)}</Form.Item>
              <Form.Item label={t("Final Indemnity Value Hospitalization")}>
                {field("finalIndemnityValueHospitalization", {})(<Money currency={props.currency} />)}
              </Form.Item>
              <Form.Item label={t("Final Indemnity Value Convalescence")}>
                {field("finalIndemnityValueConvalescence", {})(<Money currency={props.currency} />)}
              </Form.Item>
            </Col>
          </Row>
        </div>
      )}

      {/* {coverageType == "INC.SIA" && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Elegibility Date">{field("eligibilityDate", {})(<Input />)}</Form.Item>
              <Form.Item label="Contract Status Reason">{field("contractStatusReason", {})(<Input />)}</Form.Item>
              <Form.Item label="Event Details">{field("eventDetails", {})(<Input />)}</Form.Item>
              <Form.Item label="Max Percentage">{field("maxPercentage", {})(<Input />)}</Form.Item>
              <Form.Item label="Used Percentage">{field("usedPercentage", {})(<Input />)}</Form.Item>
              <Form.Item label="New Percentage">{field("newPercentage", {})(<Input />)}</Form.Item>
              <Form.Item label="Final Percentage">{field("finalPercentage", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Sum Assured">{field("sumAssured", {})(<Input />)}</Form.Item>
              <Form.Item label="Retained Value">{field("retainedValue", {})(<Input />)}</Form.Item>
              <Form.Item label="Calculation Details">{field("calculationDetails", {})(<Input />)}</Form.Item>
              <Form.Item label="Final Indemnity Value">{field("finalIndemnityValue", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}></Col>
          </Row>
        </div>
      )} */}

      {/* {coverageType == "INC.TIWA" && (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Date of Admission">{field("dateOfAdmission", {})(<Input />)}</Form.Item>
              <Form.Item label="Date of Discharge">{field("dateOfDischarge", {})(<Input />)}</Form.Item>
              <Form.Item label="Start Date of Incapacity">{field("startDateOfIncapacity", {})(<Input />)}</Form.Item>
              <Form.Item label="End Date of Incapacity">{field("endDateOfIncapacity", {})(<Input />)}</Form.Item>
              <Form.Item label="Event Details">{field("eventDetails", {})(<Input />)}</Form.Item>
              <Form.Item label="Next Anniversary Date">{field("nextAnniversaryDate", {})(<Input />)}</Form.Item>
              <Form.Item label="Deductible Days">{field("deductibleDays", {})(<Input />)}</Form.Item>
              <Form.Item label="Retired">{field("retired", {})(<Input />)}</Form.Item>
              <Form.Item label="Contract Status Reason">{field("contractStatusReason", {})(<Input />)}</Form.Item>
              <Form.Item label="Coverage End Date">{field("coverageEndDate", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Sum Assured">{field("sumAssured", {})(<Input />)}</Form.Item>
              <Form.Item label="Days of Incapacity">{field("daysOfIncapacity", {})(<Input />)}</Form.Item>
              <Form.Item label="Retained Value">{field("retainedValue", {})(<Input />)}</Form.Item>
              <Form.Item label="Calculation Details">{field("calculationDetails", {})(<Input />)}</Form.Item>
              <Form.Item label="Final Indemnity Value">{field("finalIndemnityValue", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={8}></Col>
          </Row>
        </div>
      )} */}
    </div>
  );
};

export default Form.create()(CoverageClaim);
