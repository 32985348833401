import { DollarOutlined,ReloadOutlined } from '@ant-design/icons';
import {Button, Checkbox, message, Select, Table, Tag} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import {formatDateShort, formatDateShortUtc, formatDateUtc, formatMoney} from "../../Lib/Helpers";

const CreditInstallments = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if(props.creditId) load(props.creditId);
  }, [props.creditId,props.reload]);
  
  const load=(id)=>{
      setLoading(true);
      exe("RepoCreditInstallment",{operation:"GET",filter:`creditId=${id}`}).then(r=>{
          setLoading(false);
          if(r.ok){
              setData(r.outData)
          }else message.error(r.msg);
      })
  }
  const onPay=r=>{
      setLoading(true);
      exe("PayCreditInstallment",{installmentId:r.id}).then(r=>{
          setLoading(false);
          if(r.ok){
              load(props.creditId);
              props.onPay&&props.onPay();
          }else message.error(r.msg);
      })
      
  }
    const pastDate = (date) => new Date(date) < new Date();
  return (
      <div>
        <Button type="link" icon={<ReloadOutlined />} onClick={()=>load(props.creditId)}>{t("Reload")}</Button>
          <Table dataSource={data} loading={loading} pagination={false}>
              <Table.Column title={t("Num")} dataIndex="num"  />
              <Table.Column title={t("Date Due")} dataIndex="dueDate" render={(v, r) => (!r.paid && pastDate(v) ? <Tag color="red">{formatDateShortUtc(v)}</Tag> : formatDateShortUtc(v))} />
              <Table.Column title={t("Capital")} dataIndex="capital" render={(v,r)=>formatMoney(v,r.currency)} />
              <Table.Column title={t("Interest")} dataIndex="interest" render={(v,r)=>formatMoney(v,r.currency)}/>
              <Table.Column title={t("Value")} dataIndex="value" render={(v,r)=>formatMoney(v,r.currency)}/>
              <Table.Column title={t("Insurance")} dataIndex="insurance" render={(v,r)=>formatMoney(v,r.currency)}/>
              <Table.Column title={t("Expenses")} dataIndex="expenses" render={(v,r)=>formatMoney(v,r.currency)}/>
              <Table.Column title={t("Penalty")} dataIndex="penaltyInterest" render={(v,r)=>formatMoney(v,r.currency)}/>
              <Table.Column title={t("Total")} dataIndex="amount" render={(v,r)=>formatMoney(v,r.currency)}/>
              <Table.Column title={t("Balance")} dataIndex="balance" render={(v,r)=>formatMoney(v,r.currency)}/>
              <Table.Column title={t("Paid")} dataIndex="paid" render={v=><Checkbox checked={v} /> } />
              <Table.Column title={t("Date Paid")} dataIndex="paidDate" render={v=>v?formatDateShortUtc(v):"-" } />
              <Table.Column title={t("Actions")} key="actions" render={(v,r)=><div><Button type="link" icon={<DollarOutlined />} onClick={()=>onPay(r)} disabled={r.paid}>{t("Pay")}</Button> </div>} />
          </Table>
      </div>
  );
};

export default CreditInstallments;