import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const InsuredSelect = (props) => {
  const [t] = useTranslation();
  let data = props.insureds || [];
  const noData = data.length == 0;
  if (props.holder) {
    data = [...data, { contactId: props.holder.id, name: props.holder.FullName }];
  }
  return (
    <div>
      <Select
        allowClear
        value={props.value ? +props.value : undefined}
        onChange={(v) => props.onChange(v)}
        disabled={noData}
        placeholder={noData ? t("Select policy first") : t("Select insured")}>
        {data.map((r) => (
          <Select.Option key={r.contactId} value={r.contactId}>
            {r.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default InsuredSelect;
