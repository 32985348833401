import React, { useState, useEffect } from "react";
import { UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  DatePicker,
  Tag,
  Switch,
  Checkbox,
  InputNumber,
  message,
} from "antd";
import { exe } from "../../Lib/Dal";

import Compare from "../Shared/Compare";

import { useTranslation } from "react-i18next";
import ReportSelect from "../Reports/ReportSelect";
import ChainSelect from "../Bpm/ChainSelect";
import PolicyRestrictions from "./PolicyRestrictions";

const LifePolicyBulkActions = (props) => {
  const [t] = useTranslation();
  const [matches, setMatches] = useState(null);
  const [loading, setLoading] = useState(false);
  const [policyRestrictionsVisible, setPolicyRestrictionsVisible] = useState(false);

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if (values.toReport) {
        window.location.hash = `#/reportview/${values.toReport}/policyId=${JSON.stringify(props.selected)}`;
      } 
      if (values.toChain) {
        console.log(props.selected);
        //crating context
        const ids = props.selected;
        const context = {ids: ids};
        setLoading(true);
        exe("ExeChain", {chainId: values.toChain, context: JSON.stringify(context)}).then((r) => {
              setLoading(false);
              if (r.ok) {
                //trying to parse result msg from chain
                if(r.outData) {
                  if(r.outData.ok){
                    message.success(r.outData.msg);
                  } else {
                    message.error(r.outData.msg);
                  }
                }else message.success(t("Bulk operation executed"));
              } else message.error(r.msg);
            }
        );
      }
    });}

  return (
    <div>
      <Drawer title={t("Bulk Actions")} placement="right" onClose={props.onClose} visible={props.visible} mask={false}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Button type="link" style={{padding:0}} onClick={() => setPolicyRestrictionsVisible(true)}>{t("Define Restrictions")}</Button>

          <Form.Item label={t("Send to Report")}>{getFieldDecorator("toReport")(<ReportSelect />)}</Form.Item>
          <Form.Item label={t("Send to Bulk Operation")}>{getFieldDecorator("toChain")(<ChainSelect filter={"category='POLICYBULKOPERATION'"} valueField={"id"} />)}</Form.Item>
        </Form>
        <Button type="primary" onClick={() => submit()} loading={loading}>
          {t("Submit")}
        </Button>
        <div style={{ marginTop: 15 }}>
          <Tag>{props.selected.length}</Tag> {t("Selected")}
        </div>
      </Drawer>
      <PolicyRestrictions
        visible={policyRestrictionsVisible}
        onCancel={() => setPolicyRestrictionsVisible(false)}
        onOk={() => setPolicyRestrictionsVisible(false)}
        selected={props.selected}
      />
    </div>
  );
};

export default Form.create()(LifePolicyBulkActions);
