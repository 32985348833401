import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { exe, fetchProxy } from "../../Lib/Dal";

const MessageAttachment = (props) => {
  const [t, i18n] = useTranslation();
  const data = props.value || [];
  const onSelect = (v) => {
    if (v) openFile(v);
  };
  const openFile = (v) => {
    try {
      fetch(config.ssApiUrl + "/proxy" + v, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => response.blob())
        .then((data) => window.open(URL.createObjectURL(data)))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };
  if (data.length == 0) return null;
  return (
    <div>
      <Select
        style={{ width: "100%" }}
        allowClear
        onChange={(v) => onSelect(v)}
        showSearch
        placeholder={data.length + " " + t("attachments")}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        loading={props.loading}
        disabled={props.disabled}>
        {data.map((p) => (
          <Select.Option value={p.Document.url} key={p.id}>
            {p.Document.fileName}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default MessageAttachment;
