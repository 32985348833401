import React, { useState } from "react";
import Spreadsheet from "../Shared/Spreadsheet";
import { ApiOutlined, ThunderboltOutlined, UploadOutlined } from '@ant-design/icons';
import { Tooltip, Button, Typography, Alert, message, Upload, Table } from "antd";
import Papa from "papaparse";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";
import ChainSelect from "../Bpm/ChainSelect";

const BulkTransfer = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [spreadsheet, setSpreadsheet] = useState(null);
  const [data, setData] = useState([]);
  const [mt940Data, setMt940Data] = useState([]);
  const [selectedChain, setSelectedChain] = useState();

  const columns = [
    //spreadsheet columns
    { title: t("Source"), width: 120 },
    { title: t("Destination"), width: 120 },
    { title: t("Concept"), width: 120 },
    { title: t("Currency"), width: 120 },
    { title: t("Amount"), width: 120 },
  ];
  const save = () => {
    const parsedData = spreadsheet
      .getData()
      .filter((p) => p[0] !== "" && p[1] !== "")
      .map((p) => ({ source: p[0], destination: p[1], concept: p[2], currency: p[3], amount: p[4] }));
    if (parsedData.length == 0) {
      message.error(t("No data detected"));
      return;
    }
    setLoading(true);
    exe("RepoTransfer", { operation: "MERGE", bulkJson: JSON.stringify(parsedData) }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        props.onNewImport && props.onNewImport();
      } else {
        message.error(r.msg);
      }
    });
  };

  const readFile = (file) => {
    Papa.parse(file, {
      complete: function (results) {
        console.log(results);
        setData(results.data);
      },
    });
    return false;
  };
  const customImportFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const myFile = e.target.result;
        console.log(myFile);
        setLoading(true);
        exe("ExeChain", { chain: selectedChain, context: JSON.stringify({ file: myFile }) }).then((r) => {
          setLoading(false);
          if (r.ok) {
            spreadsheet.setData(r.outData.outData);
            setData(r.outData.outData);
          } else message.error(r.msg);
        });
      } catch (error) {
        message.error("Invalid file");
      }
    };
    reader.readAsText(file);
    return false;
  };

  return (
    <div>
      <Typography.Title style={{ marginTop: 5 }} level={4}>
        {t("Bulk Transfer")}
      </Typography.Title>
      <Tooltip title="Last sync:2020-03-13">
        <Button type="link" icon={<ApiOutlined />}>
          {t("Sync")}
        </Button>
      </Tooltip>
      <Button loading={loading} type="link" icon={<ThunderboltOutlined />} onClick={() => save()}>
        {t("Import")}
      </Button>
      <Upload beforeUpload={(file, fileList) => readFile(file)} showUploadList={false}>
        <Button type="link" icon={<UploadOutlined />}>
          {t("Upload CSV Transfers File")}
        </Button>
      </Upload>
      <ChainSelect filter="category='IMPORTER'" value={selectedChain} onChange={(v) => setSelectedChain(v)} />
      <Upload beforeUpload={(file, fileList) => customImportFile(file)} showUploadList={false}>
        <Button type="link" icon={<UploadOutlined />} disabled={!selectedChain} style={{ marginBottom: 10 }}>
          {t("Select File")}
        </Button>
      </Upload>

      <Spreadsheet instance={(t) => setSpreadsheet(t)} data={data} columns={columns} />
    </div>
  );
};

export default BulkTransfer;
