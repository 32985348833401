import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {Select,message} from "antd";
import {useTranslation} from "react-i18next";

const PortalProductSelect = (props) => {
    const [t]=useTranslation();
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    
    useEffect(()=>load(),[]);
    
    const load=()=>{
        setLoading(true);
        exe("GetPortalProducts").then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
            }else message.error(r.msg);
        })
    }
    return (
        <div>
            <Select placeholder={t("Please select")} {...props} value={props.value} onChange={props.onChange}>
                {data.map(p=><Select.Option key={p.code} value={p.code} record={p}>{p.name}</Select.Option>)}
            </Select>
        </div>
    );
};

export default PortalProductSelect;