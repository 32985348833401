import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Modal } from "antd";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import {getConfigProfile, round2} from "../../Lib/Helpers";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import Money from "../Shared/Money";
import Percentage from "../Shared/Percentage";
import {safeGet, safeGetRaw} from "../../Lib/Dal";

const PolicyCoinsuranceDetail = (props) => {
  const [t] = useTranslation();
  const [selectedContact, setSelectedContact] = useState();
  const [brokerContactRoles, setBrokerContactRoles] = useState([]);

  useEffect(() => {
    getConfigProfile().then((profile) => {
      setBrokerContactRoles(safeGet(["Coinsurance", "brokerContactRoles"],profile, []));
    });
  }, []);
  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      values.coinsurerName = selectedContact.FullName;
      props.onOk(values);
      props.form.resetFields();
    });
  };
  const onChangePercentage = (v) => {
    props.form.setFieldsValue({ sumInsuredCeded: round2((values.sumInsured * v) / 100), premiumCeded: round2((values.premium * v) / 100) });
  };
  const onBrokerCommissionPercentageChange = (v) => {
    props.form.setFieldsValue({ brokerCommission: round2((values.premiumCeded * v) / 100) });
  }
  const onBrokerCommissionChange = (v) => {
    props.form.setFieldsValue({ brokerCommissionP: !values.premiumCeded?0:round2((v * 100) / values.premiumCeded) });
  }
  const onCommissionChange = (v) => {
    props.form.setFieldsValue({ commissionP: !values.premiumCeded?0:round2((v * 100) / values.premiumCeded) });
  }
  const onCommissionPercentageChange = (v) => {
    props.form.setFieldsValue({ commission: round2((values.premiumCeded * v) / 100) });
  }
  
  const values = props.form.getFieldsValue();
  const paidOnCollectionMode=safeGet(["configProfile","Coinsurance","paidOnCollection"],props,"OPTIONAL");
  const noCommission=safeGetRaw(["configProfile","Coinsurance","noCommission"],props,false);
  const field = props.form.getFieldDecorator;
  const brokerId=props.form.getFieldValue("brokerId");

  return (
    <div>
      <Modal title={t("Coinsurer Selection")} visible={props.visible} onOk={onOk} onCancel={props.onCancel} destroyOnClose>
        {field("id", {})}
        {field("lifePolicyId", { initialValue: props.policyId })}
        {field("currency", { initialValue: props.currency })}
        <Form.Item label={t("Coinsurer")}>
          {field(
            "contactId",
            {}
          )(
            <SimpleContactSelect
              onSelectContact={(v) => setSelectedContact(v)}
              showLink
              filter={`id in (SELECT contactId from ContactRole WHERE role='COI') AND `}
            />
          )}
        </Form.Item>
        <Form.Item label={t("% Ceded")}>{field("percentage", {})(<Percentage onChange={(v) => onChangePercentage(v)} />)}</Form.Item>
        <div style={{ display: "flex" }}>
          <Form.Item label={t("Sum Insured")}>
            {field("sumInsured", { initialValue: props.sumInsured })(<Money currency={props.currency} disabled />)}
          </Form.Item>
          <Form.Item label={t("Premium")}>
            {field("premium", { initialValue: props.premium })(<Money currency={props.currency} disabled style={{ marginLeft: 5 }} />)}
          </Form.Item>
        </div>
        <div style={{ display: "flex" }}>
          <Form.Item label={t("Sum Insured Ceded")}>{field("sumInsuredCeded", {})(<Money currency={props.currency} />)}</Form.Item>
          <Form.Item label={t("Premium Ceded")}>{field("premiumCeded", {})(<Money currency={props.currency} style={{ marginLeft: 5 }} />)}</Form.Item>
        </div>
        <div style={{ display: "flex" }}>
          <Form.Item label={t("Commission")}>{field("commission", {})(<Money onChange={onCommissionChange} currency={props.currency} disabled={noCommission} />)}</Form.Item>
          <Form.Item label={t("% Commission")} style={{ marginLeft: 5 }}>{field("commissionP", {})(<Percentage onChange={onCommissionPercentageChange} disabled={noCommission}  />)}</Form.Item>
        </div>
          <Form.Item label={t("Broker")}>
          {field(
              "brokerId",
              {}
          )(
              <SimpleContactSelect
                  onSelectContact={(v) => setSelectedContact(v)}
                  showLink
                  filter={brokerContactRoles.length?`id in (SELECT contactId from ContactRole WHERE role IN (${brokerContactRoles.map((p) => "'" + p + "'").join(",")})) AND `:null}
              />
          )}
        </Form.Item>
        <div style={{display:brokerId ?"flex":"none",marginRight:5}}>
          <Form.Item label={t("Broker Commission")} style={{marginRight:5}}>{field("brokerCommission", {})(<Money onChange={onBrokerCommissionChange} currency={props.currency} />)}</Form.Item>
          <Form.Item label={t("% Commission")}>{field("brokerCommissionP", {})(<Percentage onChange={onBrokerCommissionPercentageChange} />)}</Form.Item>
          
        </div>
        <Form.Item label={t("Paid on collection")}>{field("paidOnCollection", { valuePropName: "checked",initialValue:paidOnCollectionMode==="ALWAYS" })(<Checkbox disabled={paidOnCollectionMode==="ALWAYS"||paidOnCollectionMode==="NEVER"}  />)}</Form.Item>
        <Form.Item label={t("Leader")}>{field("leader", { valuePropName: "checked" })(<Checkbox disabled={props.leader} />)}</Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(PolicyCoinsuranceDetail);
