import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal } from "antd";
import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";

const ClauseManual = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  
  useEffect(()=>{
    if(props.selected){
      props.form.setFieldsValue(props.selected);
      props.form.setFieldsValue({edit:true});
    }
  },[props.selected])
  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onOk(values);
      props.form.resetFields();
    });
  };
  return (
    <div>
      <Modal visible={props.visible} title={t("Manual Clause")} onOk={onOk} onCancel={props.onCancel}>
        {field("id")}
        {field("lifePolicyId")}
        {field("mandatory")}
        {field("edit")}
        <Form.Item label={t("Code")}>{field("code", { rules: [{ required: true }] })(<Input />)} </Form.Item>
        <Form.Item label={t("Section")}>{field("section", { rules: [{ required: true }] })(<Input />)} </Form.Item>
        <Form.Item label={t("Text")}>{field("text", { rules: [{ required: true }] })(<Input.TextArea />)} </Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(ClauseManual);
