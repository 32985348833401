import { DownloadOutlined, ThunderboltOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber, message, Result, Row, Upload } from "antd";
import React, { useState } from "react";
import PolicySelect from "../Health/PolicySelect";
import DefaultPage from "../Shared/DefaultPage";
import Spreadsheet from "../Shared/Spreadsheet";
import moment from "moment";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const TimeLapse = (props) => {
  const [t] = useTranslation();
  const [spreadsheet, setSpreadsheet] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newPolicyId, setNewPolicyId] = useState();

  const columns = [
    { title: "Date", width: 120 },
    { title: "Command", width: 120 },
    { title: "Data", width: 120 },
    { title: "Notes", width: 120 },
  ];
  const field = props.form.getFieldDecorator;
  const onGenerateRows = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      const duration = values.duration;
      const start = values.start;
      const rows = [];
      for (let index = 0; index < duration; index++) {
        rows.push([start.clone().add(index, "days").format("YYYY-MM-DD")]);
      }
      setData(rows);
    });
  };
  const onDownload = () => {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    download(JSON.stringify(spreadsheet.getData()), "data.txt", "text/json");
  };
  const onLoad = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setData(e.target.result);
    };
    reader.readAsText(file);
    return false;
  };
  const execute = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      const jRows = JSON.stringify(spreadsheet.getData());
      exe("DoTimeLapse", { policyId: values.policyId, jRows: jRows }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          setNewPolicyId(r.outData.newPolicyId);
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  return (
    <DefaultPage
      title={t("Time Lapse")}
      icon="clock-circle"
      extra={
        <span>
          <Button type="primary" onClick={execute} loading={loading}>
            {t("Execute")}
          </Button>
        </span>
      }>
      {newPolicyId && (
        <Result
          status="success"
          title={t("Successfully timelapsed selected policy")}
          subTitle={t(
            "Please note that the generated record is suitable for testing purposes. Production uses should be assisted by your system administrator."
          )}
          extra={[
            <a href={"#/lifepolicy/" + newPolicyId}> Go to policy</a>,
            <Button type="link" onClick={() => setNewPolicyId(undefined)}>
              {t("Dismiss")}
            </Button>,
          ]}
        />
      )}
      <div style={{ visibility: newPolicyId ? "hidden" : "visible" }}>
        <div style={{ width: 300 }}>
          <Form.Item label={t("Policy")}>{field("policyId", { rules: [{ required: true }] })(<PolicySelect proposals />)}</Form.Item>
          <Form.Item label={t("Start")}>{field("start", { rules: [{ required: false }] })(<DatePickerW />)}</Form.Item>

          <Form.Item label={t("Duration (days)")}>{field("duration", { rules: [{ required: false }] })(<InputNumber />)}</Form.Item>
        </div>
        <Button style={{ paddingLeft: 0 }} type="link" icon={<ThunderboltOutlined />} onClick={onGenerateRows}>
          {t("Generate Rows")}
        </Button>
        <Button style={{ paddingLeft: 0 }} type="link" icon={<DownloadOutlined />} onClick={onDownload}>
          {t("Download")}
        </Button>
        <Upload showUploadList={false} beforeUpload={(f) => onLoad(f)}>
          <Button style={{ paddingLeft: 0 }} type="link" icon={<UploadOutlined />}>
            {t("Upload")}
          </Button>
        </Upload>

        <Spreadsheet instance={(t) => setSpreadsheet(t)} data={data} columns={columns} />
      </div>
    </DefaultPage>
  );
};

export default Form.create()(TimeLapse);
