import React from "react";
import { Table, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";

const CoveragesDisplay = (props) => {
  const [t] = useTranslation();
  return (
    <div>
      <Table dataSource={props.value} pagination={false} size="small">
        <Table.Header title={t("Code")} dataIndex="code" render={(v) => <Text code>{v}</Text>} />
        <Table.Header title={t("Name")} dataIndex="name" />
        {/* <Table.Column
          title="Description"
          dataIndex="description"
          render={(t) => <Typography.Paragraph ellipsis={{ rows: 3, expandable: true }}>{t}</Typography.Paragraph>}
        /> */}
      </Table>
    </div>
  );
};

export default CoveragesDisplay;
