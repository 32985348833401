import React from "react";
import { Tag } from "antd";
import { useTranslation } from "react-i18next";

const BillingStatus = (props) => {
  const [t] = useTranslation();
  const code = props.code;
  const getStatus = (code) => {
    switch (code) {
      case 0:
        return <Tag color="green">{t("Up to date")}</Tag>;
      case 1:
        return <Tag color="orange">{t("Grace period")}</Tag>;
      case -1:
        return <Tag color="red">{t("Lapsed")}</Tag>;
      default:
        return <Tag>{t("N/A")}</Tag>;
    }
  };
  return <div>{getStatus(code)}</div>;
};

export default BillingStatus;
