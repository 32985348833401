import React from "react";
import { WarningOutlined } from '@ant-design/icons';

const ClaimLink = (props) => {
  const claimId = props.claimId || props.value;
  if (!claimId) return "N/A";
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      <a href={"#/healthclaim/" + claimId}>
        <WarningOutlined style={{ marginRight: 5 }} />
        {props.code ? props.code : claimId}
      </a>
    </div>
  );
};

export default ClaimLink;
