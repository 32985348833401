import React, { Component } from "react";

import {
  EditOutlined,
  FileOutlined,
  ReloadOutlined,
  SaveOutlined,
  VerticalAlignBottomOutlined,
  VerticalAlignTopOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Select,
  Button,
  Input,
  message,
  notification,
  Typography,
  Popover,
  Switch,
} from "antd";
import yaml from "js-yaml";
import DefaultPage from "../Shared/DefaultPage";
import { exe } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";
import { withTranslation } from "react-i18next";
import IsoLanguageSelect from "./IsoLanguageSelect";
import CodeEditorW from "../Shared/CodeEditorW";
const { Paragraph, Title } = Typography;
const { Option } = Select;
const ButtonGroup = Button.Group;
class Language extends Component {
  state = {
    loading: false,
    data: [],
    selected: undefined,
  };
  componentDidMount() {
    this.load();
  }
  load() {
    this.setState({ loading: true });
    exe("RepoLanguage", {
      operation: "GET",
    }).then((r) => {
      this.setState({ loading: false, data: r.outData, selected: localStorage.language ? localStorage.language : undefined });
      if (localStorage.language) this.setValues(localStorage.language);
    });
  }
  reload() {
    this.load();
  }
  onSelect(v) {
    this.setValues(v);
    this.setState({ selected: v });
  }
  setValues(code) {
    const profile = this.state.data.find((p) => p.code == code);
    window.global.myCodeMirror.setValue(profile.config);
    this.setState({ configActive: profile.active,code:code,name:profile.name,isoCode:profile.isoCode });
  }
  addNew() {
    if (!this.state.code || !this.state.name) {
      message.error(this.props.t("Please fill code and name"));
      return;
    }
    this.save("ADD");
  }
  editLanguage(){
    if (!this.state.code || !this.state.name) {
      message.error(this.props.t("Please fill code and name"));
      return;
    }
    this.setState({loading:true});
    exe("EditLanguage",{code:this.state.code,name:this.state.name,isoCode:this.state.isoCode}).then(r=>{
      this.setState({loading:false});
      if(r.ok){
        message.success(r.msg);
      }else message.error(r.msg)
    })
  }
  save(operation = "UPDATE") {
    let values;
    if (operation == "ADD") {
      values = { code: this.state.code, name: this.state.name };
    } else {
      values = this.state.data.find((p) => p.code == this.state.selected);
    }
    //extra fields
    values.active = this.state.configActive;
    values.config = window.global.myCodeMirror.getValue();
    //yaml validation
    try {
      const result = yaml.load(values.config,{schema:yaml.JSON_SCHEMA,json:true});
      values.configJson = JSON.stringify(result);
    } catch (error) {
      notification["error"]({
        message: error.reason,
        description: error.message,
      });
      return;
    }
    //validation OK
    this.setState({ loading: true });
    exe("RepoLanguage", { operation: operation, entity: values }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        if (operation == "ADD") this.load();
      } else {
        message.error(r.msg);
      }
    });
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <DefaultPage
        title={t("Language")}
        icon="flag"
        loading={false}
        extra={
          <div>
            <Button icon={<SaveOutlined />} type="primary" onClick={() => this.save()}>
              {t("Save")}
            </Button>
          </div>
        }>
        <div style={{ display: "flex" }}>
          <Popover
            trigger="click"
            placement="right"
            title={t("New Language")}
            content={
              <div style={{ width: 300 }}>
                {t("Code")}
                <Input onChange={(e) => this.setState({ code: e.target.value })} value={this.state.code} />
                {t("Name")}
                <Input onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />
                {t("Iso Language")}
                <IsoLanguageSelect onChange={(v) => this.setState({ isoCode: v })} value={this.state.isoCode} />
                <Button style={{ marginTop: 5 }} type="primary" icon={<SaveOutlined />} onClick={() => this.addNew()}>
                  {t("Save")}
                </Button>
              </div>
            }>
            <Button style={{ marginRight: 5 }} icon={<FileOutlined />} type="link">
              {t("New")}
            </Button>
          </Popover>
          <Popover
              trigger="click"
              placement="right"
              title={t("Edit Language")}
              content={
                <div style={{ width: 300 }}>
                  {t("Code")}
                  <Input onChange={(e) => this.setState({ code: e.target.value })} value={this.state.code} />
                  {t("Name")}
                  <Input onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />
                  {t("Iso Language")}
                  <IsoLanguageSelect onChange={(v) => this.setState({ isoCode: v })} value={this.state.isoCode} />
                  <Button style={{ marginTop: 5 }} type="primary" icon={<SaveOutlined />} onClick={() => this.editLanguage()}>
                    {t("Save")}
                  </Button>
                </div>
              }>
            <Button style={{ marginRight: 5 }} icon={<EditOutlined />} type="link">
              {t("Edit")}
            </Button>
          </Popover>

          <Button icon={<ReloadOutlined />} type="link" onClick={() => this.reload()}>
            {t("Reload")}
          </Button>
          <Button icon={<VerticalAlignTopOutlined />} type="link" onClick={() =>document.querySelector("#translationFile").scrollTo(0, 0)}>
            {t("Go to start")}
          </Button> <Button icon={<VerticalAlignBottomOutlined />} type="link" onClick={() =>document.querySelector("#translationFile").scrollTo(0, document.querySelector("#translationFile").scrollHeight)}>
            {t("Go to end")}
          </Button>
        </div>
        <Select style={{ width: 250, marginRight: 5 }} value={this.state.selected} onSelect={(v) => this.onSelect(v)}>
          {this.state.data.map((p) => (
            <Select.Option value={p.code} key={p.code}>
              {p.name}
            </Select.Option>
          ))}
        </Select>
        <Title style={{ marginTop: 15 }} level={4}>
          {t("Translation File")}
        </Title>
        <div id="translationFile" style={{overflow:"scroll",maxHeight: "70vh"}}>
          <CodeEditorW />
        </div>
      </DefaultPage>
    );
  }
}

export default withTranslation()(Language);
