import React, { useState, useEffect } from "react";
import { Table, Typography, Button, Modal, message, Tooltip, Empty } from "antd";
import { createChart } from "lightweight-charts";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";

let chart;
let series;
const InvestmentChart = (props) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!chart) chart = createChart(document.querySelector("#fchart"), { height: 500 });
    load(props.code);
  }, [props.code]);

  useEffect(() => {
    if (chart) onLineChart();
  }, [data]);

  useEffect(
    () => () => {
      if (chart && series) {
        chart.removeSeries(series);
        chart = null;
        series = null;
      }

      console.log("unmount");
    },
    []
  );

  const load = (code) => {
    exe("RepoInvestmentPlanData", { operation: "GET", filter: "investmentPlanCode='" + code + "'" }).then((r) => {
      if (r.ok) {
        const rawData = r.outData || [];
        const distinctData = rawData.filter((value, index, self) => self.findIndex((v) => v.date === value.date) === index);
        setData(distinctData.map((p) => ({ time: p.date, open: p.open, high: p.high, low: p.low, close: p.close })));
      } else {
        message.error(r.msg);
      }
    });
  };
  const onLineChart = () => {
    if (series) chart.removeSeries(series);
    series = chart.addAreaSeries({
      topColor: "rgba(76, 175, 80, 0.56)",
      bottomColor: "rgba(76, 175, 80, 0.04)",
      lineColor: "rgba(76, 175, 80, 1)",
      lineWidth: 2,
      title: props.code,
    });
    series.setData(data.map((p) => ({ time: p.time, value: p.close })));
    chart.timeScale().fitContent();
  };
  const onCandleChart = () => {
    if (series) chart.removeSeries(series);
    series = chart.addCandlestickSeries();
    series.setData(data);
    chart.timeScale().fitContent();
  };

  return (
    <div>
      <Button type="link" onClick={() => onLineChart()}>
        {t("Line Chart")}
      </Button>
      <Button type="link" onClick={() => onCandleChart()}>
        {t("Candle Chart")}
      </Button>
      {data.length == 0 && <Empty />}
      <div id="fchart" style={{ width: "100%", height: "100%" }}></div>
    </div>
  );
};

export default InvestmentChart;
