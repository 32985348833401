import React, { useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import ComData from "./ComData";
import ComFilter from "./ComFilter";
import { FilterOutlined } from '@ant-design/icons';
import { Button } from "antd";
import ComLiquidation from "./ComLiquidation";
import { useTranslation } from "react-i18next";

const Commissions = (props) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [t, i18n] = useTranslation();

  const [data, setData] = useState([]);
  return (
    <div>
      <DefaultPage
        title={t("Commissions")}
        icon="shopping"
        extra={
          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        }>
        <ComData data={data} />
        <ComFilter visible={filterVisible} onClose={() => setFilterVisible(false)} onResults={(r) => setData(r)} />
      </DefaultPage>
    </div>
  );
};

export default Commissions;
