import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { DeleteOutlined, FlagOutlined } from '@ant-design/icons';
import {Alert, Button, Input} from "antd";
import { safeGet } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";
import {getConfigProfile} from "../../Lib/Helpers";
const { googleMapLoader } = GoogleMapReact;
const bootstrapURLKeys = {
  key:"",
  libraries: ['places'].join(','),
}
const MapRender = (props) => {
  const { t } = useTranslation();
  const [hasMarker,setHasMarker]=useState(false);
  const [googleMapsKey,setGoogleMapsKey]=useState();

  const _map = useRef();
  const _maps = useRef();
  const _marker=useRef();

  useEffect(() => {
    getConfigProfile().then((profile) => {
      setGoogleMapsKey(safeGet(["Maps", "googleMapsKey"], profile, undefined));
    });
  }, []);

  
  const loadPlaces=()=>{
    //places
    const center = {  lat: configProfileDefaults.lat, lng: configProfileDefaults.lng};
    // Create a bounding box with sides ~10km away from the center point
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };
    const input = document.getElementById("autocomplete");
    const options = {
      bounds: defaultBounds,
      //componentRestrictions: { country: "us" },
      fields: ["address_components", "geometry", "icon", "name"],
      strictBounds: false,
      types: ["geocode"],
    };
   
    const autocomplete = new window.google.maps.places.Autocomplete(input, options);
    autocomplete.addListener('place_changed',  ()=> {
      const placeSelected=autocomplete.getPlace();
      console.log(_marker.current,placeSelected,_map,_maps);
      const values = { marker: { lat: placeSelected.geometry.location.lat(), lng: placeSelected.geometry.location.lng() }, zoom:  _map.current.getZoom(), mapType:  _map.current.getMapTypeId() };
      setValues(JSON.stringify(values));
      reportValues(placeSelected.geometry.location.lat(), placeSelected.geometry.location.lng(), _map.current.getZoom(), _map.current.getMapTypeId())
    });
  }

  const configProfileDefaults = safeGet(["Maps"], window.global.configProfile, {
    lat: 40.41724042782377,
    lng: -3.683430463683006,
    zoom: 11,
    mapType: "hybrid",
  });

  const setValues = (valuesString) => {
    const values = JSON.parse(valuesString);
    if (values.marker) {
      //if (marker) marker.setMap(null); //removing previous marker
      if(_marker.current) removeMarker();
      const newMarker = new _maps.current.Marker({
        //adding new marker
        position: values.marker,
        map: _map.current,
        draggable: true,
        animation: _maps.current.Animation.DROP,
      });
      _map.current.panTo(newMarker.getPosition()); //Centering map to marker position
      _maps.current.event.addListener(newMarker, "dragend", () =>
        reportValues(newMarker.position.lat(), newMarker.position.lng(), _map.current.getZoom(), _map.current.getMapTypeId())
      );
      _marker.current=newMarker;
      setHasMarker(true);
    }
    _map.current.setZoom(values.zoom);
    _map.current.setMapTypeId(values.mapType);
  };
  const reportValues = (lat, lng, zoom, mapType) => {
    const values = { marker: { lat: lat, lng: lng }, zoom: zoom, mapType: mapType };
    console.log(values);
    if (props.onChange) props.onChange(values);
  };
  const putMarker = () => {
    if (_marker.current) _marker.current.setMap(null); //removing previous marker
    const newMarker = new _maps.current.Marker({
      position: _map.current.getCenter(),
      map: _map.current,
      draggable: true,
      animation: _maps.current.Animation.DROP,
    });
    _marker.current=newMarker;
    setHasMarker(true);
    _maps.current.event.addListener(newMarker, "dragend", () =>
      reportValues(newMarker.position.lat(), newMarker.position.lng(), _map.current.getZoom(), _map.current.getMapTypeId())
    );
    reportValues(newMarker.position.lat(), newMarker.position.lng(), _map.current.getZoom(), _map.current.getMapTypeId());
  };
  const removeMarker = () => {
    if (_marker.current) {
      _marker.current.setMap(null);
      _marker.current=null;
      setHasMarker(false);
    }
  };
  const handleApiLoaded = (map, maps) => {
    _map.current = map;
    _maps.current = maps;
    if (props.value) setValues(props.value);
    loadPlaces();
    
  };
  return (
    <div style={{  width: "100%" }}>
      {!hasMarker && (
        <Button type="link" icon={<FlagOutlined />} onClick={putMarker}>
          {t("Set Marker")}
        </Button>
      )}
      {hasMarker && (
        <Button type="link" icon={<DeleteOutlined />} onClick={removeMarker}>
          {t("Remove Marker")}
        </Button>
      )}
      {t("Search Place")}:<Input id={"autocomplete"} style={{width:"400px",marginBottom:10,marginLeft:3}} />
      {googleMapsKey&&<div style={{ height: "600px", width: "100%" }}><GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsKey,libraries: ['places'].join(',') }}
        defaultCenter={{ lat: configProfileDefaults.lat, lng: configProfileDefaults.lng }}
        defaultZoom={configProfileDefaults.zoom}
        options={{ mapTypeControl: true, mapTypeId: configProfileDefaults.mapType,streetViewControl: true }}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}></GoogleMapReact></div>}
      {!googleMapsKey&& <Alert
          message={t("Key not found in configuration")}
          description={t("Please enter a Google Maps key in the Maps.googleMapsKey field of the config profile")}
          type="error"
          showIcon
      />}
    </div>
  );
};

export default MapRender;
