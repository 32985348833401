import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DeleteOutlined, DownloadOutlined, FolderOpenOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Table,
  Select,
  DatePicker,
  message,
  Divider,
  Popconfirm,
  Badge,
  Tooltip,
  Popover,
  Form,
  Input,
} from "antd";
import { exe } from "../../Lib/Dal";
import { formatMoney, formatDateShort, formatDateShortUtc } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";
import Text from "antd/lib/typography/Text";
import Paragraph from "antd/lib/typography/Paragraph";
import CessionEdit from "./CessionEdit";
import CessionParticipants from "./CessionParticipants";
import PolicyLink from "../Life/PolicyLink";
import SaveSelectedRecords from "./SaveSelectedRecords";

const ReData = (props) => {
  const [t, i18n] = useTranslation();
  const [date, setDate] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRecord, setEditRecord] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    if (props.value) {
      setData(props.value);
      //refreshing if in edit mode
      if(editRecord){
        const updatedRecord=props.value.find(p=>p.id===editRecord.id);
        setEditRecord(updatedRecord);
      }
    }
  }, [props.value,props.reload]);

  const onDelete = (id) => {
    setLoading(true);
    exe("RepoCession", { operation: "DELETE", entity: { id: id } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        props.doReload && props.doReload();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSaveSelection=()=>{
    console.log(selectedRowKeys);

  }
  const coverageFilters=data.map(p=>p.coverageCode).filter((v,i,s)=>s.indexOf(v)===i).map(p=>({text:p,value:p}));
  const lineFilters=data.map(p=>p.lineId).filter((v,i,s)=>s.indexOf(v)===i).map(p=>({text:p,value:p}));
  return (
    <div>
      {!editRecord && (
        <div>
          <CSVLink data={data} filename={"cessions.csv"} target="_blank" disabled={data.length == 0}>
            <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
              {t("Download")}
            </Button>
          </CSVLink>
          <SaveSelectedRecords selectedRowKeys={selectedRowKeys} entity="Cession" />
          <Table
            dataSource={data}
            loading={loading || props.loading}
            style={{ marginTop: 5 }}
            scroll={{ x: true }}
            size="small"
            rowSelection={{selectedRowKeys,onChange:(keys)=>setSelectedRowKeys(keys),selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
              Table.SELECTION_NONE,
              ]}}

            rowKey="id"
            expandedRowRender={(r) => <CessionParticipants value={r.Participants} cession={r} reload={()=>props.doReload && props.doReload()} />}>
            <Table.Column title={t("Cession ID")} dataIndex="id" render={(v,r)=><div style={r.overwritten?{textDecoration:"line-through"}:{}}>{v}</div>} />
            <Table.Column title={t("Treaty ID")} dataIndex="contractId" render={(v, r) => <a href={"#/contract/" + v}>{v}</a>} />
            <Table.Column title={t("Status")} dataIndex="err" render={(v, r) => <div>
              {v?<Tooltip title={t("Invalid")}> <Badge status={"error"}/></Tooltip>:<Tooltip title={t("Valid")}><Badge status={"success"}/></Tooltip> }
              {r.coSumInsured!==0&&<Tooltip title={t("Coinsurance")}> <LegacyIcon type={"pie-chart"} /></Tooltip>}
            </div> } />
            <Table.Column title={t("Lob")} dataIndex="LoB" />
            <Table.Column title={t("Policy")} dataIndex="policyCode" render={(v, r) => <PolicyLink policyId={r.lifePolicyId} code={v} />} />
            <Table.Column title={t("Coverage")} dataIndex="coverageCode" onFilter={(value, record) => record.coverageCode === value} filters={coverageFilters} />
            <Table.Column title={t("Start")} dataIndex="start" render={(v) => formatDateShortUtc(v)} />
            <Table.Column title={t("End")} dataIndex="end" render={(v) => formatDateShortUtc(v)} />
            <Table.Column title={t("Line")} dataIndex="lineId" onFilter={(value, record) => record.lineId === value} filters={lineFilters} />
            <Table.Column title={t("Type")} dataIndex="premiumType" />

            <Table.Column title={t("Sum Insured")} dataIndex="sumInsured" render={(v, r) => formatMoney(v, r.currency,true)} />
            <Table.Column title={t("Premium Sum")} dataIndex="premium" render={(v, r) => formatMoney(v, r.currency,true)} />

            <Table.Column title={t("Cedant Sum Insured")} dataIndex="sumInsuredCedant" render={(v, r) => formatMoney(v, r.currency,true)} />
            <Table.Column title={t("Cedant Premium")} dataIndex="premiumCedant" render={(v, r) => formatMoney(v, r.currency,true)}/>

            <Table.Column title={t("Re Sum Insured")} dataIndex="sumInsuredRe" render={(v, r) => <span style={{fontWeight:600}}>{formatMoney(v, r.currency,true)}</span> } />
            <Table.Column title={t("Re Premium")} dataIndex="premiumRe" render={(v, r) => <span style={{fontWeight:600}}>{formatMoney(v, r.currency,true)}</span>} />
            <Table.Column title={t("Commission")} dataIndex="participantCommission" render={(v, r) => formatMoney(v, r.currency,true)} />
            <Table.Column title={t("Tax")} dataIndex="participantTax" render={(v, r) => formatMoney(v, r.currency,true)} />

            <Table.Column
              title={t("Actions")}
              dataIndex="msg"
              fixed="right"
              key="actions"
              render={(v, r) => (
                <div>
                  <Button type="link" icon={<FolderOpenOutlined />} onClick={() => setEditRecord(r)}></Button>
                  <Divider type="vertical" />
                  <Popconfirm title={t("Are you sure you want to remove this record?")} onConfirm={() => onDelete(r.id)}>
                    <Button type="link" icon={<DeleteOutlined />}></Button>
                  </Popconfirm>
                </div>
              )}
            />
          </Table>
        </div>
      )}
      {editRecord && (
        <div>
          <Button type="link" icon={<LeftOutlined />} onClick={() => setEditRecord(null)} style={{ float: "left" }}>
            {t("Back")}
          </Button>
          <CessionEdit value={editRecord} onEdit={() => props.doReload && props.doReload()} onBack={() => setEditRecord(null)} />
        </div>
      )}
    </div>
  );
};

export default ReData;
