import React, { Component } from "react";
import { ResponsiveLine } from "@nivo/line";
import { useTranslation } from "react-i18next";
const data1 = [
  {
    id: "scenario 1",
    color: "hsl(112, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 126,
      },
      {
        x: "helicopter",
        y: 70,
      },
      {
        x: "boat",
        y: 199,
      },
      {
        x: "train",
        y: 214,
      },
      {
        x: "subway",
        y: 38,
      },
      {
        x: "bus",
        y: 81,
      },
      {
        x: "car",
        y: 96,
      },
      {
        x: "moto",
        y: 161,
      },
      {
        x: "bicycle",
        y: 170,
      },
      {
        x: "horse",
        y: 217,
      },
      {
        x: "skateboard",
        y: 264,
      },
      {
        x: "others",
        y: 211,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(213, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 56,
      },
      {
        x: "helicopter",
        y: 274,
      },
      {
        x: "boat",
        y: 193,
      },
      {
        x: "train",
        y: 23,
      },
      {
        x: "subway",
        y: 289,
      },
      {
        x: "bus",
        y: 84,
      },
      {
        x: "car",
        y: 200,
      },
      {
        x: "moto",
        y: 5,
      },
      {
        x: "bicycle",
        y: 160,
      },
      {
        x: "horse",
        y: 282,
      },
      {
        x: "skateboard",
        y: 274,
      },
      {
        x: "others",
        y: 242,
      },
    ],
  },
  {
    id: "us",
    color: "hsl(344, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 63,
      },
      {
        x: "helicopter",
        y: 4,
      },
      {
        x: "boat",
        y: 261,
      },
      {
        x: "train",
        y: 295,
      },
      {
        x: "subway",
        y: 136,
      },
      {
        x: "bus",
        y: 2,
      },
      {
        x: "car",
        y: 228,
      },
      {
        x: "moto",
        y: 164,
      },
      {
        x: "bicycle",
        y: 158,
      },
      {
        x: "horse",
        y: 115,
      },
      {
        x: "skateboard",
        y: 248,
      },
      {
        x: "others",
        y: 198,
      },
    ],
  },
  {
    id: "germany",
    color: "hsl(38, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 160,
      },
      {
        x: "helicopter",
        y: 52,
      },
      {
        x: "boat",
        y: 161,
      },
      {
        x: "train",
        y: 167,
      },
      {
        x: "subway",
        y: 22,
      },
      {
        x: "bus",
        y: 105,
      },
      {
        x: "car",
        y: 210,
      },
      {
        x: "moto",
        y: 101,
      },
      {
        x: "bicycle",
        y: 128,
      },
      {
        x: "horse",
        y: 293,
      },
      {
        x: "skateboard",
        y: 52,
      },
      {
        x: "others",
        y: 118,
      },
    ],
  },
  {
    id: "norway",
    color: "hsl(140, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 99,
      },
      {
        x: "helicopter",
        y: 57,
      },
      {
        x: "boat",
        y: 133,
      },
      {
        x: "train",
        y: 129,
      },
      {
        x: "subway",
        y: 278,
      },
      {
        x: "bus",
        y: 181,
      },
      {
        x: "car",
        y: 289,
      },
      {
        x: "moto",
        y: 100,
      },
      {
        x: "bicycle",
        y: 239,
      },
      {
        x: "horse",
        y: 289,
      },
      {
        x: "skateboard",
        y: 148,
      },
      {
        x: "others",
        y: 57,
      },
    ],
  },
];
const ProjectionGraph = ({ data /* see data tab */ }) => {
  const [t] = useTranslation();

  return (
    <ResponsiveLine
      data={data}
      curve="monotoneX"
      margin={{ top: 10, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "linear" }}
      yScale={{ type: "linear", min: "auto", max: "auto" }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        legend: t("Year"),
        legendOffset: -12,
      }}
      axisLeft={{
        legend: t("Amount"),
        legendOffset: 12,
      }}
      colors={{ scheme: "nivo" }}
      pointBorderWidth={1}
      pointBorderColor={{ from: "serieColor" }}
      pointLabel={t("Amount")}
      pointLabelYOffset={-12}
      useMesh={true}
      colors={["rgb(97, 205, 187)", "rgb(244, 117, 96)", "orange"]}
      //enableArea={true}
      //areaOpacity={0.07}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};
export default ProjectionGraph;
