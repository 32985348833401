import React, { useState } from "react";
import { UploadOutlined } from '@ant-design/icons';
import { Table, Typography, Upload, Button, Descriptions, Empty, message } from "antd";
import { exe } from "../../Lib/Dal";
import { formatMoney, formatDateShort } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";
const MT940Parser = (props) => {
  const [t] = useTranslation();
  const [allData, setAllData] = useState(undefined);
  const readBankFile = (file) => {
    const reader = new FileReader();
    reader.readAsText(file, "UTF-8");
    reader.onload = function (evt) {
      console.log(evt.target.result);
      exe("ParseMt940", { fileContent: evt.target.result }).then((r) => {
        if (r.ok) {
          setAllData(r.outData);
        } else {
          message.error(r.msg);
        }
      });
    };
    return false;
  };

  const Account = (props) => (
    <div>
      <Descriptions title={t("Account")}>
        <Descriptions.Item label={t("Account Identification")}>{props.data.AccountIdentification}</Descriptions.Item>
        <Descriptions.Item label={t("Transaction Ref Num")}>{props.data.TransactionReferenceNumber}</Descriptions.Item>
        <Descriptions.Item label={t("Statement Number")}>{props.data.StatementNumber}</Descriptions.Item>
        <Descriptions.Item label={t("Opening Balance")}>{formatMoney(props.data.OpeningBalance.Amount, props.data.OpeningBalance.Currency)}</Descriptions.Item>
        <Descriptions.Item label={t("Date")}>{formatDateShort(props.data.OpeningBalance.Date)}</Descriptions.Item>
        <Descriptions.Item label={t("Sequence")}>{props.data.SequenceNumber}</Descriptions.Item>
        <Descriptions.Item label={t("Closing Balance")}>{formatMoney(props.data.ClosingBalance.Amount, props.data.ClosingBalance.Currency)}</Descriptions.Item>
        <Descriptions.Item label={t("Date")}>{formatDateShort(props.data.ClosingBalance.Date)}</Descriptions.Item>
        <Descriptions.Item label={t("Currency")}>{props.data.ClosingBalance.Currency}</Descriptions.Item>
      </Descriptions>

      <Table dataSource={props.data.Lines} size="middle">
        <Table.Column dataIndex="TransactionTypeIdCode" title={t("Type")} />
        <Table.Column dataIndex="Amount" title={t("Amount")} />
        <Table.Column dataIndex="BankReference" title={t("Bank Ref")} />
        <Table.Column dataIndex="CustomerReference" title={t("Customer Ref")} />
        <Table.Column dataIndex="EntryDate" title={t("Entry Date")} render={(v) => formatDateShort(v)} />
        <Table.Column dataIndex="ValueDate" title={t("Value Date")} render={(v) => formatDateShort(v)} /> />
        <Table.Column dataIndex={["InformationToOwner","UnstructuredData"]} title={t("Info")} />
      </Table>
    </div>
  );
  return (
    <div>
      <Typography.Title style={{ marginTop: 5 }} level={4}>
        {t("MT940 Parser")}
      </Typography.Title>
      <Upload beforeUpload={(file, fileList) => readBankFile(file)} showUploadList={false}>
        <Button type="link" icon={<UploadOutlined />}>
          {t("Upload MT940 File")}
        </Button>
      </Upload>

      {!allData ? <Empty /> : allData.map((p) => <Account data={p} />)}
    </div>
  );
};

export default MT940Parser;
