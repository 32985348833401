import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Table, message} from "antd";
import {useTranslation} from "react-i18next";
import {PlusOutlined, SaveOutlined,LeftOutlined,EditOutlined,DeleteOutlined} from "@ant-design/icons";
import { exe } from '../../Lib/Dal';
import DatePickerW from './../Shared/DatePickerW';

const CurrencyOptions = (props) => {
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    const [form]=Form.useForm();


    const onOk=()=>{
        form.validateFields().then(values=>{
           if(values.exchangeDate){
               setLoading(true);
               exe("SetExchangeRateDate",{date:values.exchangeDate}).then(r=>{
                   setLoading(false);
                   if(r.ok){
                        props.onOk(values);
                   }else{
                       message.error(r.msg);
                   }
               });
           }else{
            props.onCancel();
           }
        });
    }


    return (
        <Modal title={t("Currency Options")} visible={props.visible} okButtonProps={{loading:loading}}
        onOk={onOk} onCancel={props.onCancel}>
       <div>
            <Form layout={"vertical"} form={form}>
                <Form.Item label={t("Exchange Rate Date")} name={"exchangeDate"} rules={[{required:false}]}><DatePickerW/></Form.Item>
            </Form>
            </div>
        </Modal>
    );
};

export default CurrencyOptions;