import React, {useEffect, useState} from 'react';
import { DeleteOutlined, PlusCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Table, message } from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";
import FormRenderModal from "../FormRenderModal";
import CustomForm from "../Shared/CustomForm";
import {formatMoney} from "../../Lib/Helpers";

const CreditCharges = (props) => {
    const [t]=useTranslation();
    const [loading,setLoading]=useState(false);
    const [newChargeVisible,setNewChargeVisible]=useState(false);
    const [data,setData]=useState([])
    const [selected,setSelected]=useState();
    
    useEffect(()=>{
        if(props.creditId) load(props.creditId);
    },[props.creditId,props.reload])
    
    const load=(creditId)=>{
        setLoading(true);
        exe("RepoCreditCharge",{operation:"GET",filter:`creditId=${creditId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
            }else message.error(r.msg);
        })
    }
    const saveNew=(values)=>{
        setLoading(true);
        setNewChargeVisible(false);
        values.creditId=props.creditId;
        values.code="MC"+values.code; //manual charge
        exe("RepoCreditCharge",{operation:"ADD",entity:values}).then(r=>{
            setLoading(false);
            if(r.ok) {
                message.success(r.msg);
                load(props.creditId);
            }
            else message.error(r.msg);
        })
    }
    const onDelete=id=>{
        setLoading(true);
        exe("RepoCreditCharge",{operation:"DELETE",entity: {id:id}}).then(r=>{
            setLoading(false);
            if(r.ok) {
                message.success(r.msg);
                load(props.creditId);
            }
            else message.error(r.msg);
        })
    }
    
    const NewChargeForm=Form.create()(props=> {
        const onSubmit=()=>{
            props.form.validateFields((err,values)=>{
                if(err) return;
                props.onSubmit&&props.onSubmit(values);
            })
        }
        const field=props.form.getFieldDecorator;
        return (
            <div>
               <Form.Item label={t("Code")}>{field("code",{rules:[{required:true}]})(<Input />)}</Form.Item>
               <Form.Item label={t("Concept")}>{field("concept",{rules:[{required:true}]})(<Input />)}</Form.Item>
               <Form.Item label={t("Currency")}>{field("currency",{rules:[{required:true}],initialValue:props.currency})(<Currency disabled />)}</Form.Item>
               <Form.Item label={t("Amount")}>{field("amount",{rules:[{required:true}]})(<Money currency={props.currency} />)}</Form.Item>
               <Button type="primary" icon={<SaveOutlined />} onClick={onSubmit}>{t("Save")}</Button>
           </div>
        );
    })
    const onFormOk=()=>{
        const record=selected;
        //custom form validation
        if (window.chargeForm &&window.chargeForm.instanceContainers&& window.chargeForm.instanceContainers[0]) {
            if (window.chargeForm.instanceContainers[0].reportValidity()) {
                record.jValues = JSON.stringify(window.chargeForm.userData);
            } else {
                message.error(t("Please check charge form validation errors"));
                return;
            }
        }
        setLoading(true);
        exe("RepoCreditCharge",{operation:"UPDATE",entity:record}).then(r=>{
            setLoading(false);
            if(r.ok) {
                message.success(r.msg);
                load(props.creditId);
                setSelected(undefined);
            }
            else message.error(r.msg);
        })
    }
    const onFormClick=r=>{
        setSelected(r);
    }
    return (
        <div>
            <Button type="link" icon={<PlusCircleOutlined />} onClick={()=>setNewChargeVisible(true)} disabled={props.readOnly}>{t("New")}</Button>
            <Table dataSource={data} footer={()=><div>
                {t("Total")} {formatMoney(data.reduce((p,c)=>p+c.amount,0),props.currency)}
            </div>}>
                <Table.Column title={t("ID")} dataIndex="id" />
                <Table.Column title={t("Code")} dataIndex="code" />
                <Table.Column title={t("Concept")} dataIndex="concept" />
                <Table.Column title={t("Currency")} dataIndex="currency" />
                <Table.Column title={t("Amount")} dataIndex="amount" render={(v,r)=>formatMoney(v,r.currency)} />
                <Table.Column title={t("Form")} dataIndex="formId" render={(v,r)=>v?<Button type={"link"} icon={<LegacyIcon type={"form"} />} onClick={()=>onFormClick(r)} /> :null} />
                <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button icon={<DeleteOutlined />} disabled={props.readOnly} type="link" onClick={()=>onDelete(r.id)} /> </div>} />
            </Table>
            <Modal visible={newChargeVisible} footer={false} onCancel={()=>setNewChargeVisible(false)} title={t("New Manual Charge")}>
                <NewChargeForm onSubmit={v=>saveNew(v)} currency={props.currency} />
            </Modal>
            <Modal title={t("Charge Form")} visible={selected} onCancel={()=>setSelected()} onOk={()=>onFormOk()} okText={t("Save")} destroyOnClose>
                {selected&&<CustomForm formId={selected.formId} variable={"chargeForm"} value={selected.jValues}  />}
            </Modal>
        </div>
    );
    
};

export default CreditCharges;