import { DeleteOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Checkbox, message, Modal, Table } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeValue } from "../../Lib/Dal";
import { formatMoney } from "../../Lib/Helpers";

const CertificateRemove = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const dataPresent = useRef(false); //because of function closures and network delay, props.value is overwritten. This prevents it.
  useEffect(() => {
    if (props.value) {
      setData(JSON.parse(props.value));
      dataPresent.current = true;
    } else {
      load();
    }
  }, [props.value]);

  const load = () => {
    if (dataPresent.current) return; //data loaded. ignoring
    setLoading(true);
    exe("GetPolicyCertificates", { groupPolicyId:props.groupPolicyId }).then((r) => {
      setLoading(false);
      if (dataPresent.current) return; //data loaded. ignoring
      if (r.ok) {
        setData((r.outData.certificates || []).reverse()); //ignoring if data already set
      } else {
        message.error(r.msg);
      }
    });
  };

  const onRemove = (record) => {
    record.removed = true;
    const newData = data.map((p) => (p.id == record.id ? record : p));
    setData(newData);
    props.onChange(JSON.stringify(newData));
  };
  const onUndo = (record) => {
    record.removed = false;
    const newData = data.map((p) => (p.id == record.id ? record : p));
    setData(newData);
    props.onChange(JSON.stringify(newData));
  };
  return (
    <div>
      <Table dataSource={data} loading={loading}>
        <Table.Column
          title={t("ID")}
          dataIndex="id"
          render={(v, r) =>
            r.removed ? (
              <Text delete mark>
                {v}
              </Text>
            ) : (
              v
            )
          }
        />
        <Table.Column title={t("Insured")} dataIndex="insured" render={(v, r) => (r.removed ? <Text delete>{v}</Text> : v)} />
        <Table.Column
          title={t("Base Insured Sum")}
          dataIndex="insuredSum"
          render={(v, r) => (r.removed ? <Text delete>{formatMoney(v, props.currency)}</Text> : formatMoney(v, props.currency))}
        />
        <Table.Column title={t("Group")} dataIndex="group" render={(v, r) => (r.removed ? <Text delete>{v}</Text> : v)} />
        <Table.Column
          title={t("Actions")}
          render={(v, r) => (
            <div>
              {!r.removed && (
                <Button type="link" icon={<DeleteOutlined />} onClick={() => onRemove(r)} disabled={props.readOnly} disabled={props.readOnly}>
                  {t("Remove")}
                </Button>
              )}
              {r.removed && (
                <Button type="link" icon={<UndoOutlined />} onClick={() => onUndo(r)} disabled={props.readOnly} disabled={props.readOnly}>
                  {t("Undo")}
                </Button>
              )}
            </div>
          )}
        />
      </Table>
    </div>
  );
};

export default CertificateRemove;
