import { ControlOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { Button, Checkbox, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import {formatDateShort} from "../../Lib/Helpers";
import LocationCumulusFilter from "./LocationCumulusFilter";
import PolicyLink from "../Life/PolicyLink";
import Money from "../Shared/Money";
import Currency from "../Shared/Currency";
import CurrencyCell from "../Shared/CurrencyCell";

const LocationCumulus = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filterVisible,setFilterVisible]=useState(false);    
    
    const onSearch=(values)=>{
        console.log(values);
        setLoading(true);
        exe("GetLocationCumulus",values).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    
    return (
        <DefaultPage
            title={t("Location Cumulus")}
            icon="compass"
            extra={
                <div>
                </div>
            }>
                <Button type="primary" onClick={()=>setFilterVisible(true)} icon={<ControlOutlined />}>
                    {t("Parameters")}
                </Button>
            <Table loading={loading} dataSource={data} style={{marginTop:5}} expandedRowRender={r=>
                // --------- SUB TABLE -------
                <Table dataSource={r.NearPolicies}>
                    <Table.Column title={t("Policy")} dataIndex="policyId" render={(v,r)=><PolicyLink policyId={v} code={r.policyCode}  />} />
                    <Table.Column title={t("Distance to parent")} dataIndex="distanceToParent" />
                    <Table.Column title={t("Insured Sum")} dataIndex="insuredSum" render={(v,r)=><CurrencyCell value={v} currency={r.currency} />} />

                    
                </Table>}>
                {/*--------- MAIN TABLE -------*/}
                    <Table.Column title={t("Policy")} key={"policyId"} render={(v,r)=><PolicyLink policyId={r.Policy.id} code={r.Policy.code} />} />
                    <Table.Column title={t("Near Policies")} key={"nearPolicies"} render={(v,r)=>r.NearPolicies.length} />
                <Table.Column title={t("Insured Sum")} key="insuredSum" render={(v,r)=><CurrencyCell value={r.Policy.insuredSum} currency={r.Policy.currency} />} />
                    <Table.Column title={t("Total Insured Sum")} dataIndex="insuredSumTotal" render={(v,r)=><CurrencyCell value={v} currency={r.Policy.currency} />} />
                <Table.Column title={t("Insured Sum Retained")} dataIndex="insuredSumCedantTotal" render={(v,r)=><CurrencyCell value={v} currency={r.Policy.currency} />} />
                <Table.Column title={t("Insured Sum Ceded")} dataIndex="insuredSumReTotal" render={(v,r)=><CurrencyCell value={v} currency={r.Policy.currency} />} />
                <Table.Column title={t("LoB")} dataIndex={["Policy","lob"]} />
                    <Table.Column
                        title={t("Actions")}
                        key="actions"
                        render={(v, r) => (
                            <div>
                                <Button type="link" icon={<FolderOpenOutlined />}  />
                            </div>
                        )}
                    />
                </Table>
            <LocationCumulusFilter visible={filterVisible} onClose={()=>setFilterVisible(false)} onSearch={onSearch} />
        </DefaultPage>
    );
};

export default LocationCumulus;
