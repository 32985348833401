import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, InputNumber, message, Alert } from "antd";
import Money from "../Shared/Money";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {exe} from "../../Lib/Dal";

const MergePayments = ({form,visible,onOk,onCancel,items}) => {
    const [loading,setLoading]=useState(false);
    const [error,setError]=useState();
    const [t]=useTranslation();
    
    useEffect(()=>{
        console.log(items)

        const errors=validate(items);
        if(errors){
            setError(errors);
            form.setFieldsValue({amount:0,items:items.length});
        }else{
            const amount=items.reduce((sum,x)=>sum+x.total,0);
            form.setFieldsValue({amount:amount,items:items.length,beneficiary:items[0].contactId,currency:items[0].currency});
            setError();
        }
        
    },[visible])
    
    const validate=(items)=>{
        let myErrors = "";
        if(items.length>1) {
            const sameCurrency = items.every(p => p.currency === items[0].currency);
            if (!sameCurrency) {
                myErrors = t("All selected items must have the same currency");
            }
            const sameBeneficiary = items.every(p => p.contactId === items[0].contactId);
            if (!sameBeneficiary) {
                myErrors = t("All selected items must have the same beneficiary");
            }
            const notMerged = items.every(p => p.entityState !== "MERGED");
            if (!notMerged) {
                myErrors = t("Some items have already been merged. Please check selection");
            }
            const notExecuted = items.every(p => p.entityState !== "EXECUTED");
            if (!notExecuted) {
                myErrors = t("Some items have already been executed. Please check selection");
            }
            const notCancelled = items.every(p => p.entityState !== "CANCELLED");
            if (!notCancelled) {
                myErrors = t("Some items are cancelled. Please check selection");
            }
            const notReverted = items.every(p => p.entityState !== "REVERTED");
            if (!notReverted) {
                myErrors = t("Some items are reverted. Please check selection");
            }
            const notParent = items.every(p => p.producer !== "MERGED");
            if (!notParent) {
                myErrors = t("Parent items cannot be merged. Please check selection");
            }
        }else myErrors=t("At least 2 items must be selected");
        return myErrors;
    }
    
    const doMerge=()=>{
        const paymentIds=items.map(p=>p.id);
        setLoading(true);
        exe("MergePayments",{paymentIds}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                onOk();
            }else message.error(r.msg);
        })
    }
    const field=form.getFieldDecorator;
    return (
        <div>
            {field("currency")}
            <Modal title={t("Merge payments")} visible={visible} onOk={doMerge} onCancel={onCancel} okText={t("Confirm")} okButtonProps={{loading:loading,disabled:error}}>
                {error&&<Alert
                    message={t("Error")}
                    description={error}
                    type="error"
                    showIcon
                />}
                <Form.Item label={t("Beneficiary")}>
                    {field("beneficiary")(<SimpleContactSelect disabled />)}
                </Form.Item>
                <Form.Item label={t("Amount")}>
                    {field("amount")(<Money currency={form.getFieldValue("currency")} disabled/>)}
                </Form.Item>
                <Form.Item label={t("Items Selected")}>
                    {field("items")(<InputNumber disabled/>)}
                </Form.Item>
            </Modal>
        </div>
    );
};

export default Form.create()(MergePayments);