import { FileOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { Button, Checkbox, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";

const ProfitCommissionList = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        load();
    }, []);
    const load = () => {
        setLoading(true);
        exe("RepoProfitCommission", { operation: "GET" }).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else {
                message.error(r.msg);
            }
        });
    };
    const onOpen = (id) => {
        window.location.hash = "#/ProfitCommission/" + id;
    };
    const onNew = () => {
        window.location.hash = "#/ProfitCommission/0";
    };
    return (
        <DefaultPage
            title={t("Profit Commission Schemes")}
            icon="carry-out"
            extra={
                <div>
                    <Button type="primary" onClick={onNew} icon={<FileOutlined />}>
                        {t("New")}
                    </Button>
                </div>
            }>
            <Table loading={loading} dataSource={data}>
                <Table.Column title={t("Id")} dataIndex="id" />
                <Table.Column title={t("Code")} dataIndex="code" />
                <Table.Column title={t("Name")} dataIndex="name" />
                <Table.Column title={t("Start")} dataIndex="start" />
                <Table.Column title={t("End")} dataIndex="end" />
                <Table.Column
                    title={t("Actions")}
                    key="actions"
                    render={(v, r) => (
                        <div>
                            <Button type="link" icon={<FolderOpenOutlined />} onClick={() => onOpen(r.id)} />
                        </div>
                    )}
                />
            </Table>
        </DefaultPage>
    );
};

export default ProfitCommissionList;
