import React from "react";
import { SafetyOutlined } from '@ant-design/icons';

const PolicyLink = (props) => {
  const policyId = props.policyId || props.value;
  const tab=props.tab||"";
  if (!policyId) return "N/A";
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      <a href={"#/lifepolicy/" + policyId+tab}>
        <SafetyOutlined style={{ marginRight: 5 }} />
        {props.code ? props.code : policyId}
      </a>
    </div>
  );
};

export default PolicyLink;
