import { Button, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import moment from "moment";
import { useTranslation } from "react-i18next";

const EntityTasks = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => load(props.entity, props.entityId), [props.entityId]);

  const load = (entity, entityId) => {
    if (!entityId) return;
    setLoading(true);
    exe("GetProcesses", { filter: `entity='${entity}' and entityId='${entityId}'`, include: ["UsuarioResponsable", "Grupo"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      }
    });
  };
  return (
    <div>
      <Table dataSource={data} loading={loading}>
        <Table.Column title={t("Task")} dataIndex="nombre" />
        <Table.Column title={t("Status")} dataIndex="estado" render={(v) => <Tag>{v}</Tag>} />
        <Table.Column title={t("Responsible")} dataIndex={["UsuarioResponsable","nombre"]} />
        <Table.Column title={t("Group")} dataIndex={["Grupo","name"]} />
        <Table.Column title={t("Started")} dataIndex="fInicio" render={(v) => moment.utc(v).fromNow()} />

        <Table.Column title={t("Action")} dataIndex="id" render={(v) => <a href={"#/activity/" + v}>Open</a>} />
      </Table>
    </div>
  );
};

export default EntityTasks;
