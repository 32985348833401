import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const ExternalSourceSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currencyRestriction,setCurrencyRestriction]=useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    load();
  }, []);
  
  useEffect(()=>{
    if(props.value&&props.onSelect){ //forcing select when there is a value loaded
      const record=data.find(p=>p.code===props.value);
      if(record) props.onSelect(record);
      console.log("selected",record,props.value)
    }
  },[props.value,data.length])

  useEffect(()=>{
    //default selection based on currency compatibility
    if(props.currency&&currencyRestriction){
      
      const compatible=data.filter(p=>p.currency===props.currency);
      if(compatible.length===1) props.onChange(compatible[0].code);
      else if(compatible.length===0) props.onChange(undefined);
      else if(compatible.length>1){
        //selecting by payment method if functionality used
        if(props.paymentMethods&&data.some(p=>p.paymentMethods)){
          let compatible2=compatible;
          props.paymentMethods.forEach(paymentMethod=>{
            if(paymentMethod) compatible2=compatible2.filter(p=>p.paymentMethods&&p.paymentMethods.includes(paymentMethod))
          })
          const selected=data.find(p=>p.code===props.value);
          if(compatible2.length===1) props.onChange(compatible2[0].code);
          else if(compatible2.length===0) props.onChange(undefined);
          else if(selected&&!compatible2.map(p=>p.currency).includes(selected.currency)) props.onChange(undefined);
        }
      }
    }

  },[props.currency,data.length,JSON.stringify(props.paymentMethods||[])])
  


  const load = () => {
    setLoading(true);
    exe("RepoExternalSourceCatalog", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        setCurrencyRestriction(r.outData.some(p=>p.currency));
      }
      else message.error(r.msg);
    });
  };
  const onSelect = (v, opt) => {
    console.log("SELECTED", opt.props.record);
    props.onSelect && props.onSelect(opt.props.record);
  };
  return (
    <Select
      value={props.value}
      onChange={(v) => props.onChange(v)}
      onSelect={onSelect}
      loading={loading}
      style={{ width: "100%", ...props.style }}
      placeholder={t("External source")}
      disabled={props.disabled}
      allowClear>
      {data.map((p) => (
        <Select.Option value={p.code} key={p.code} record={p} disabled={currencyRestriction&&props.currency&&p.currency!==props.currency} >
          {t(p.name)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ExternalSourceSelect;
