import React, { useState, useEffect } from "react";
import { exe } from "../../Lib/Dal";
import { Table, Tag, message, Divider } from "antd";
import { formatMoney, getColor, formatDate } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";

const ContactClaims = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.contactId > 0) {
      load("contactId=" + props.contactId);
    } else {
      setData([]);
      if (props.onLoad) props.onLoad(0);
    }
  }, [props.contactId]);

  const load = (filter) => {
    exe("RepoClaim", {
      operation: "GET",
      filter: filter,
      include: ["Contact", "Process", "Claimer", "Policy"],
    }).then((r) => {
      if (r.ok) {
        setData(r.outData.reverse());
        if (props.onLoad) props.onLoad(r.outData.length);
      } else {
        message.error(r.msg);
      }
    });
  };
  const Column = Table.Column;
  return (
    <div>
      <Table dataSource={data} rowKey="id">
        <Column title={t("ID")} dataIndex="id" render={(v, record) => <a href={"#/healthclaim/" + record.id}>{v}</a>} />
        <Column title={t("Notified")} dataIndex="created" render={(v) => formatDate(v)} />
        <Column title={t("Claimer")} dataIndex="Claimer" render={(f, record) => <a href={"#/contact/" + f.id}>{f.FullName}</a>} />
        <Column title={t("Insured")} dataIndex="Contact" render={(f, record) => <a href={"#/contact/" + f.id}>{f.FullName}</a>} />
        <Column title={t("Policy")} dataIndex="Policy" key="policyId" render={(f, record) => <a href={"#/lifepolicy/" + f.id}>{f.code}</a>} />
        <Column title={t("Certificate")} dataIndex="Policy" key="certificate" render={(f, record) => f.certificate} />
        <Column title={t("Type")} dataIndex="claimType" />
        <Column title={t("ICD")} dataIndex="icd" />
        <Column title={t("Sum Insured")} dataIndex="Policy" key="policySI" render={(f, record) => formatMoney(f.insuredSum, f.currency)} />
        <Column title={t("LoB")} dataIndex="Policy" key="policyLob" render={(f, record) => f.lob} />
        <Column title={t("Product")} dataIndex="Policy" key="policyProductCode" render={(f, record) => f.productCode} />
        <Column title={t("Status")} dataIndex="status" render={(text, r) => <Tag> {r.Process ? r.Process.estado : "N/A"}</Tag>} />

        {/* <Column title="Tags" dataIndex="Tags"
                render={f => (
                    <span>
                    {f.map(tag => <Tag color="blue" key={tag.tag} onClick={p=>this.filterByTag(tag.tag)}>{tag.tag}</Tag>)}
                    </span>
                )}
            /> */}
        <Column
          title={t("Action")}
          render={(text, record) => (
            <span>
              <a href={"#/healthclaim/" + record.id}>{t("View")}</a>
              <Divider type="vertical" />
              <a href={"#/contact/" + record.contactId} />
            </span>
          )}
        />
      </Table>
    </div>
  );
};

export default ContactClaims;
