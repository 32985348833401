import React, { useEffect } from "react";
import { Select } from "antd";

const InvestmentStrategy = (props) => {
  const options = props.options || [];
  const defaultInvestmentStrategy = options.find((p) => p.default);
  useEffect(() => {
    if (defaultInvestmentStrategy != null) {
      props.onChange(defaultInvestmentStrategy.code);
    }
  }, []);
  return (
    <div>
      <Select onChange={(v) => props.onChange(v)} value={props.value} disabled={defaultInvestmentStrategy != null}>
        {options.map((p) => (
          <Select.Option key={p.code} value={p.code}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default InvestmentStrategy;
