import React, {useEffect} from 'react';
import { DeleteOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, InputNumber, Popconfirm, Row } from "antd";

import Currency from "../Shared/Currency";
import {useTranslation} from "react-i18next";

const ContactBranchForm = (props) => {
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.selected) props.form.setFieldsValue(props.selected);
    },[props.selected])
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onSave(values);
        })
    }
    const onDelete=()=>{
        props.onDelete(props.selected);
    }
    
    const field=props.form.getFieldDecorator;
    return (
        <div>
            <Row gutter={16}>
                <Col span={12}>
                    <Button type="link" icon={<LeftOutlined />} onClick={props.onBack}>{t("Back")}</Button> 
                    <Button type="link" icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
                    {props.selected.id>0&&<Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={onDelete}>
                        <Button type="link" icon={<DeleteOutlined />}>{t("Delete")}</Button>
                    </Popconfirm>}

                    {field("id")}
                    {field("contactId")}
                    
                    <Form.Item label={t("Code")}>
                        {field("code",{rules:[{required:true}]})(<Input />)}
                    </Form.Item>
                    <Form.Item label={t("Name")}>
                        {field("name",{rules:[{required:true}]})(<Input />)}
                    </Form.Item>
                    <Form.Item label={t("Id Aux")}>
                        {field("idAux",{rules:[{required:false}]})(<Input />)}
                    </Form.Item>
                    
                </Col>
            </Row>
        </div>
    );
};

export default Form.create()(ContactBranchForm);