import { Badge, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";


const ContributionStatusSelect = (props) => {
    
    const [loading, setLoading] = useState(false);
    const [t] = useTranslation();
    
    const defaultData=[
        {code:0,name:t("Draft")},
        {code:1,name:t("Posted")},
        {code:-1,name:t("Invalid")},
    ]
 
    const renderStatus=(code)=>{
        switch(code){
            case 0:
                return  <span><Badge status="default" />{t("Draft")}</span>
            case 1:
                return  <span><Badge status="success" />{t("Posted")}</span>
            case -1:
                return  <span><Badge status="error" />{t("Invalid")}</span>
            default:
                return  <span><Badge status="default" />{code}</span>
        }
        }
  return (
      <Select
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        optionFilterProp="children">
        {defaultData.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {renderStatus(p.code)}
          </Select.Option>
        ))}
      </Select>
  );
};

export default ContributionStatusSelect;
