import {
  FileSyncOutlined,
  FilterOutlined,
  InboxOutlined,
  ReloadOutlined,
  SearchOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Col, DatePicker, Input, message, Popover, Row, Select, Table } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import { load } from "js-yaml";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";
import { exe } from "../../Lib/Dal";
import { formatDate } from "../../Lib/Helpers";
import TemplateSelect from "../Shared/TemplateSelect";
import DocStatusSelect from "../Shared/DocStatusSelect";
import moment from "moment";
import ProviderRequirementSelect from './ProviderRequirementSelect';

const ProviderDocs = (props) => {
  const [t, i18n] = useTranslation();
  const [uploadVisible, setUploadVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [templateName, setTemplateName] = useState(undefined);
  const [statusFilter,setStatusFilter]=useState();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    if (props.providerCode) load(props.providerCode);
  }, [props.providerCode]);

  const uploadProps = {
    name: "file",
    multiple: true,
    action: config.ssApiUrl + "/proxy/upload",
    headers: {
      Authorization: "Bearer " + localStorage.token,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} ${t("file uploaded successfully")}.`);
        saveDocument(info.file);
      } else if (status === "error") {
        message.error(`${info.file.name} ${t("file upload failed")}.`);
      }
    },
  };
  const load = (providerCode) => {
    setLoading(true);
    exe("RepoProviderDocument", { operation: "GET", filter: `providercode='${providerCode}'` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const saveDocument = (file) => {
    setLoading(true);
    const document = { providerCode: props.providerCode, templateName: "uploaded", filename: file.name, url: file.response.url };
    exe("RepoProviderDocument", { operation: "ADD", entity: document }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.providerCode);
      } else {
        message.error(r.msg);
      }
    });
  };
  const openFile = (v) => {
    try {
      fetch(config.ssApiUrl + "/proxy" + v, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => response.blob())
        .then((data) => window.open(URL.createObjectURL(data)))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };
  const generateClaimDoc = (name) => {
    setLoading(true);
    exe("GenerateProviderDoc", { providerCode: props.providerCode, template: name }).then((r) => {
      setLoading(false);
      setTemplateName(undefined);
      if (r.ok) {
        message.success(r.msg);
        load(props.providerCode);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onStatusChange=(doc,status)=>
  {
    doc.status=status;
    setLoading(true)
    exe("RepoProviderDocument", { operation: "UPDATE", entity: doc }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Status updated"));
        setData(data.map((p) => p.id == doc.id?({...p,status:status}):p));
      }
    });
  }
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          {["fileName","id","templateName"].includes(dataIndex)&&<Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`${t("Search")} ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
          />}

          {dataIndex==="created"&&<DatePicker.RangePicker
              placeholder={t("Search by date")}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e!==undefined ? [e] : [])}
              style={{ width: 250, marginBottom: 8, display: "block" }}
          />}
          {dataIndex==="status"&&<DocStatusSelect
              placeholder={t("Search by status")}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e!==undefined ? [e] :[])}
              style={{ width: 250, marginBottom: 8, display: "block" }}
          />}

          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            {t("Search")}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {t("Reset")}
          </Button>
        </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value,record)=>onFilter(value,record,dataIndex),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput&&searchInput.select());
      }
    },
  });
  const onFilter=(value,record,dataIndex)=>{
    if(typeof value=== 'string'){
      return record[dataIndex]?record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()):false;
    }else if(typeof value=== 'object'){
      //moment array
      return moment(record[dataIndex]).isBetween(value[0],value[1],'day',"[]");
    }else{
      //number
      return record[dataIndex]==value;
    }
  }
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end
  const onAssociaationChange = (requirementId, record) => {
    //setting doc association
    setLoading(true);
    record.requirementId = requirementId;
    exe("SetProviderDocAssociation", { documentId:record.id,requirementId:requirementId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Association updated"));
        setData(data.map((p) => p.id == record.id?({...p,requirementId:requirementId}):p));
      }else {
        message.error(r.msg);
      }
    });

  }
  return (
    <div>
      <Button type="link" icon={<UploadOutlined />} onClick={() => setUploadVisible(!uploadVisible)}>
        {t("Upload")}
      </Button>
      <Popover
        content={
          <div>
            <TemplateSelect onChange={(v) => generateClaimDoc(v)} value={templateName} disabled={loading} loading={loading} />
          </div>
        }
        title={t("On demand document generation")}
        trigger="click">
        <Button type="link" icon={<FileSyncOutlined />}>
          {t("Generate")}
        </Button>
      </Popover>
      <Popover
          content={
            <div>
              <DocStatusSelect value={statusFilter} onChange={(v)=>setStatusFilter(v)} style={{width:"100%"}} />
            </div>
          }
          title={t("Document Status Filter")}
          trigger="click">
        <Button type="link" icon={<FilterOutlined />}>
          {t("Filter")}
        </Button>
      </Popover>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => load(props.providerCode)}>
        {t("Reload")}
      </Button>
      {uploadVisible && (
        <Row gutter={16} style={{ marginBottom: 10 }}>
          {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
          <Col span={12}>
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{t("Click or drag file to this area to upload")}</p>
              <p className="ant-upload-hint">{t("Support for a single or bulk upload. UB04/CMS1500 Form supported")}</p>
            </Dragger>
          </Col>
          {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
          <Col span={6}></Col>
          {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
          <Col span={6}></Col>
        </Row>
      )}
      <Table dataSource={statusFilter?data.filter(p=>p.status===statusFilter):data} loading={loading}>
        <Table.Column title={t("Id")} {...getColumnSearchProps("id")} dataIndex="id" />
        <Table.Column title={t("File")} {...getColumnSearchProps("fileName")} dataIndex="fileName" />
        <Table.Column title={t("Type")}  {...getColumnSearchProps("templateName")} dataIndex="templateName" />
        <Table.Column title={t("Created")} {...getColumnSearchProps("created")} dataIndex="created" render={(v) => formatDate(v)} />
        <Table.Column
            title={t("Status")}
            {...getColumnSearchProps("status")}
            dataIndex="status"
            width={150}
            render={(v, r) => (<DocStatusSelect style={{width:"100%"}} value={v} onChange={(status)=>onStatusChange(r,status)} />)}
        />
        <Table.Column
            title={t("Association")}
            dataIndex="requirementId"
            render={(t, r) => (
              <ProviderRequirementSelect providerCode={props.providerCode} value={t} onChange={(requirementId) => onAssociaationChange(requirementId,r)} />
            )}
          />
        <Table.Column
          title={t("File")}
          dataInex="url"
          render={(v, r) => (
            <Button type="link" onClick={() => openFile(r.url, r.name)}>
              {t("Open")}
            </Button>
          )}
        />
      </Table>
    </div>
  );
};

export default ProviderDocs;
