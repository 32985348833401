import React, {useEffect, useState} from 'react';
import {Checkbox, DatePicker} from "antd";
import {useTranslation} from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const DateRange = (props) => {
    const [open,setOpen]=useState();
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.value){
            setOpen(!props.value[1]);
        }
    },[props.value])
    
    const onChangeOpen=v=>{
        props.onChange([v?v:null,null])
    }
const onChangeCheck=e=>{
    setOpen(e.target.checked);
    if(e.target.checked){
        props.onChange([props.value?props.value[0]:undefined,null]);
    }
}
    return (
        <div style={{display:"flex"}}>
            {!open&&<DatePicker.RangePicker value={props.value} onChange={props.onChange}  />}
            {open&&<DatePickerW value={props.value?props.value[0]:undefined} onChange={onChangeOpen}  />}
            <span style={{whiteSpace:"nowrap"}}><Checkbox checked={open} onChange={onChangeCheck} style={{marginLeft:5}}>{t("Open Validity")}</Checkbox></span>
        </div>
    );
};

export default DateRange;