import { ReloadOutlined, SearchOutlined, UploadOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Input, message, Modal, Popconfirm, Table, Upload } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, fetchReportProxy } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import ReportRestrictions from './ReportRestrictions';

const ReportList = (props) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [editReport, setEditReport] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [reportIdRestrictions, setReportIdRestrictions] = useState(null);

  useEffect(() => getReports(), []);

  const getReports = () => {
    setLoading(true);
    exe("GetReports").then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const openReport = (report) => {
    if (report.restricted) {
      //checking if user is authorized
      if(report.AllowedUsers && report.AllowedUsers.map(q=>q.user).indexOf(localStorage.email)===-1){
        message.error(t("This report is restricted and only authorized users can access it"));
        return
      }
    }
    window.location.href = "#/reportview/" + report.fileName;
  };

  const updateReport = () => {
    if (!editReport.name) {
      message.error(t("Please enter a name"));
      return;
    }
    setLoading(true);
    exe("RepoReport", { operation: editReport.id ? "UPDATE" : "ADD", entity: editReport }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        setEditReport({});
        getReports();
      } else {
        message.error(r.msg);
      }
    });
  };
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
          {t("Search")}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />
    //   ) : (
    //     text
    //   ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const onExport = (report) => {
    setLoading(true);
    exe("ExportReport", { reportName: report.fileName }).then((r) => {
      setLoading(false);
      if (r.ok) {
        function download(content, fileName, contentType) {
          var a = document.createElement("a");
          var file = new Blob([content], { type: contentType });
          a.href = URL.createObjectURL(file);
          a.download = fileName;
          a.click();
        }
        download(r.outData.pdf, report.fileName + ".repx", "application/octet-stream");
      } else message.error(r.msg);
    });
  };
  const onImport = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const myData = e.target.result;
        exe("ImportReport", { reportName: file.name, file: myData }).then((r) => {
          if (r.ok) {
            message.success(r.msg);
            getReports();
          } else message.error(r.msg);
        });
      } catch (error) {
        message.errort("Invalid file");
      }
    };
    console.log(file);
    reader.readAsText(file);
    return false;
  };
  const onDelete = (report) => {
    setLoading(true);
    exe("DeleteReport", { reportName: report.fileName }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        getReports();
      } else message.error(r.msg);
    });
  };
  // table search end
  const showReportRestrictions = (report) => {
    if(report.id) setReportIdRestrictions(report.id);
    else message.error(t("Please define a report name first"));
  }
  const onReportRestrictionsChanged = (report) => {
    setReportIdRestrictions(null);
    getReports();
  }

  return (
    <div>
      <DefaultPage
        title={t("User Reports")}
        icon="profile"
        extra={
          <div>
            <Upload showUploadList={false} beforeUpload={(f) => onImport(f)}>
              <Button icon={<UploadOutlined />}>{t("Import")}</Button>
            </Upload>
          </div>
        }>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => getReports()}>
          {t("Reload")}
        </Button>
        <Table dataSource={data} loading={loading} rowKey={"fileName"}>
          <Table.Column title={t("File Name")} dataIndex="fileName" render={(v,r) => <span><Text code>{v}</Text>{r.restricted&&<LockOutlined />}</span>} {...getColumnSearchProps("fileName")} />
          <Table.Column title={t("Name")} dataIndex="name" {...getColumnSearchProps("name")} />
          <Table.Column title={t("Area")} dataIndex="area" {...getColumnSearchProps("area")} />
          <Table.Column title={t("Description")} dataIndex="description" />
          <Table.Column
            title={t("Action")}
            render={(v, r) => (
              <div>
                <Button type="link" onClick={() => openReport(r)}>
                  {t("Open")}
                </Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => setEditReport({ ...r, fileName: r.fileName })}>
                  {t("Edit Info")}
                </Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => onExport(r)}>
                  {t("Export")}
                </Button>
                <Divider type="vertical" />
                <Button type="link" icon={<LockOutlined />} onClick={() => showReportRestrictions(r)} />
                <Divider type="vertical" />
                <Popconfirm title={t("Are you sure you want to delete this report?")} onConfirm={() => onDelete(r)}>
                  <Button type="link">{t("Delete")}</Button>
                </Popconfirm>
              </div>
            )}
          />
        </Table>
        <Modal
          title={t("Edit Report Info for") + " " + editReport.name}
          visible={editReport.fileName}
          onCancel={() => setEditReport({})}
          onOk={() => updateReport()}>
          <Form.Item label={t("Name")}>
            <Input value={editReport.name} onChange={(v) => setEditReport({ ...editReport, name: v.target.value })} />
          </Form.Item>
          <Form.Item label={t("Area")}>
            <Input value={editReport.area} onChange={(v) => setEditReport({ ...editReport, area: v.target.value })} />
          </Form.Item>
          <Form.Item label={t("Description")}>
            <Input.TextArea value={editReport.description} onChange={(v) => setEditReport({ ...editReport, description: v.target.value })} />
          </Form.Item>
        </Modal>
        <ReportRestrictions reportId={reportIdRestrictions} visible={reportIdRestrictions!==null} 
        onCancel={() => setReportIdRestrictions(null)}
        onOk={() => onReportRestrictionsChanged()}
         />
      </DefaultPage>
    </div>
  );
};

export default ReportList;
