import { exe } from "./Dal";

const getCache = (item) => {
  if (!Window.Cache) Window.Cache = {};
  return new Promise((resolve) => {
    const cache = Window.Cache;
    if (cache[item]) {
      resolve({ ok: true, outData: cache[item], cache: true });
    } else {
      if(!cache["fetching_"+item]) {
        cache["fetching_"+item]=true;
        exe(item, { operation: "GET" }).then((r) => {
          if (r.ok) {
            cache[item] = r.outData;
            cache["fetching_"+item]=false;
            resolve({ ok: true, outData: r.outData });
          } else {
            resolve({ ok: false, msg: r.msg });
          }
        });
    }else{
        (async() => {
          console.log("waiting for cache");
          while(cache["fetching_"+item])
            await new Promise(r => setTimeout(r, 500));
          console.log("cache is defined");
          resolve({ ok: true, outData: cache[item], cache: true });
        })();
      }
    }
  });
};
//cache.get = get; //exposed for testing purposes
export default getCache;
