import React, { Component, useState, useEffect } from "react";
import { WarningTwoTone } from '@ant-design/icons';
import { Table, Divider, Tag, Skeleton } from "antd";
import { exe } from "../Lib/Dal";
import moment from "moment";
import { useTranslation } from "react-i18next";
const { Column, ColumnGroup } = Table;

const MyTasks = (props) => {
  const [t] = useTranslation();
  const [pagination, setPagination] = useState({ pageSize: 10 });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  //useEffect(() => window.GLOBAL.checkGlobalIsLoaded().then(() => load()), []);
  useEffect(() => load(), []);
  const Now = moment();

  const load = (page = 0) => {
    exe("GetMyTasks", {
      include: ["Etiquetas", "UsuarioResponsable", "Grupo", "Pasos"],
      size: pagination.pageSize,
      page: page,
    }).then((r) => {
      const updatedPagination = { ...pagination, total: r.total, current: page };
      setPagination(updatedPagination);
      setData(r.outData);
      setLoading(false);
    });
  };
  const handleTableChange = (changedPagination, filters, sorter) => {
    load(changedPagination.current);
  };
  if (loading) return <Skeleton active />;
  return (
    <div>
      <Table dataSource={data} size="small" pagination={pagination} onChange={handleTableChange}>
        <Column title={t("ID")} dataIndex="id" />
        <Column title={t("Task")} dataIndex="nombre" key="nombre" />
        <Column title={t("Status")} dataIndex="estado" render={(v) => <Tag color="#87d068">{v}</Tag>} />
        <Column title={t("Responsible")} dataIndex="responsable" key="responsible" />
        <Column title={t("Group")} dataIndex={['Grupo','name']} key="address" />
        <Column
          title={t("Expires")}
          dataIndex="fSla"
          render={(v, r) => {
            if (r.sla == 0) {
              return "";
            } else {
              const f = moment(v);

              return f.isBefore(Now) ? (
                <span style={{ color: "red" }}>
                  <WarningTwoTone twoToneColor="red" style={{ fontSize: 16, marginRight: 5 }} />
                  <span>{f.fromNow()}</span>
                </span>
              ) : (
                f.fromNow()
              );
            }
          }}
        />

        <Column
          title={t("Action")}
          key="action"
          dataIndex="id"
          render={(v, record) => (
            <span>
              <a href={"/#/activity/" + v}>{t("Process")} </a>
              <Divider type="vertical" />
              <a href={"#/" + record.entityPath}>{t("Entity")}</a>
            </span>
          )}
        />
      </Table>
    </div>
  );
};

export default MyTasks;
