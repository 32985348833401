import { Button, message, Popover, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";
import { exe, safeGet } from "../../Lib/Dal";
import { getConfigProfile } from "../../Lib/Helpers";
import { ReloadOutlined, InfoCircleOutlined } from "@ant-design/icons";

const PaymentMethods = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [paymentMethodsConfig, setPaymentMethodsConfig] = useState([]);
  const [showInfo, setShowInfo] = useState();
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    load();
    getConfigProfile().then((profile) => {
      setPaymentMethodsConfig(safeGet(["Claim", "paymentMethods"], profile, []));
    });
}, []);

  useEffect(() => {
    checkExtraInfo();
  }
  , [props.value,paymentMethodsConfig,data,props.context]);

  const checkExtraInfo=()=>{
    //checking if payment method requires extra info to be shown to the user
    if(props.showInfo&&props.value&&paymentMethodsConfig.length>0&&data.length>0){
      const pmConfig=paymentMethodsConfig.find(p=>p.code===props.value);
      if(pmConfig&&pmConfig.showInfo){
        computeShowInfo(pmConfig.showInfo);
      }else setShowInfo();
    }else setShowInfo();
  }

  const load = () => {
    getCache("RepoPaymentMethodCatalog").then((r) => {
      if (r.ok) {
        //filtering. Only payment methods with module left blank or equal to the current module
        const paymentMethods=r.outData.filter(p=>!p.module||p.module===props.module);
        setData(paymentMethods);
      } else {
        message.error(r.msg);
      }
    });
  };
  const computeShowInfo=(showInfoConfig)=>{
    if(!props.context)return;
    const context=JSON.stringify(props.context);
    setLoading(true);
    exe("ExeFormula",{formula:showInfoConfig,context:context}).then(r=>{
      setLoading(false);
      if(r.ok){
        setShowInfo(r.outData);
      }else{
        console.error("Error computing showInfo",r.msg);
        setShowInfo(t("Could not fetch extra info"));
      }
    });

  }
  const getExtraContent=()=>{
    if(props.showInfo) return <div style={{marginTop:5,marginLeft:3}}>
      {showInfo}
      {showInfo&&<Button type="link" icon={<ReloadOutlined />} loading={loading} onClick={()=>computeShowInfo(paymentMethodsConfig.find(p=>p.code===props.value).showInfo)} />}
    </div>

    return null;
}
  return (<div>
    <div style={{whiteSpace:"nowrap"}}>
      <Select
        allowClear
        placeholder={t("Payment Method")}
        onChange={(v,opt) => props.onChange&&props.onChange(v,opt&&opt.props.record)}
        value={props.value || undefined}
        disabled={props.disabled}
        style={props.style}>
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code} record={p}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
      {props.small&&showInfo&&<Popover content={getExtraContent()}><InfoCircleOutlined style={{marginLeft:5,marginRight:5}} /></Popover>}
      </div>
      {!props.small&&getExtraContent()}
      </div>);
};

export default PaymentMethods;
