import React, { useEffect, useState } from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  Tabs,
  Select,
  Button,
  DatePicker,
  Input,
  Result,
  Statistic,
  Card,
  Divider,
  Modal,
} from "antd";
import Title from "antd/lib/typography/Title";
import Meta from "antd/lib/card/Meta";
import {safeGet} from "../../Lib/Dal";
import {useTranslation} from "react-i18next";
import View from "../Bpm/View";

const ProductQuote = (props) => {
  const [step, setStep] = useState(0);
  const [t]=useTranslation();
  const [loadingIndex,setLoadingIndex]=useState(-1);
  const [loadingBuyIndex,setLoadingBuyIndex]=useState(-1);
  const [action,setAction]=useState();
    const options=safeGet(["SelfService","Quote","Options"],props.productConfig,[]);
    const result=props.result;

    useEffect(() => {
      if(props.hasError>0){
      setLoadingBuyIndex(false);
      setLoadingIndex(false);
      }
    }, [props.hasError]);
    
  const onContinue = (step) => {
    setStep(step + 1);
  };
  const onBuy = (policy,index) => {
    setLoadingBuyIndex(index);
    props.onBuy(policy,index);
  };
  
  const onSave = (policy,index) => {
    console.log("onSave index",index)
    setLoadingIndex(index);
    props.onSave(policy);
    };
  const onAction = (policy,index,action) => {
    console.log(action,index);
    props.onAction(policy,action);
  }
  return (
    <div style={{ display: "flex" }}>
        {options.map((option,index)=><Card
            hoverable
            style={{ width: 240, marginRight: 5 }}>
                <div style={{display:"flex", flexDirection:"column",marginLeft:10}}>
                    <Meta title={option.title} description={option.description} />
                  <Divider />
                  {option.Info.map(p=><Statistic title={p.text} value={result[index][p.field]} prefix={<LegacyIcon type={p.icon} />} />)}
                </div>
          <Button type="primary" style={{ marginTop: 15, width: "100%" }} onClick={() => onBuy(result[index],index)} disabled={!option.allowBuy} loading={loadingBuyIndex===index}>
                {t("Buy now")}
            </Button>
          {option.allowSave&&<Button  style={{ marginTop: 15, width: "100%" }} loading={loadingIndex===index} onClick={() => onSave(result[index],index)}>
                {t("Save quote")}
            </Button>}
          {(option.Actions||[]).map(action=> <Button type={action.buttonType}  style={{ marginTop: 15, width: "100%" }} onClick={() => onAction(result[index],index,action)}>{action.buttonText}</Button>)}
        </Card>)}
    </div>
  );
};

export default ProductQuote;
