import { RocketOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, InputNumber, message, Result, Row } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { exe } from "../../Lib/Dal";

const PresentationExecution = (props) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [calcSheetId, setCalcSheetId] = useState(undefined);
  const onExecute = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      values.year = values.period.year();
      values.month = values.period.month() + 1;
      console.log(values);
      setLoading(true);
      exe("ExePresentation", { presentationId: props.presentationId }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setCalcSheetId(r.outData.calcSheetId);
        } else message.error(r.msg);
      });
    });
  };
  const field = props.form.getFieldDecorator;
  //const values = props.form.getFieldsValue();
  return (
    <div>
      {calcSheetId && (
        <Result
          status="success"
          title="Successfully executed actuarial sheet"
          extra={[
            <Link to={{ pathname: "/calcsheet", calcSheetId: calcSheetId }}>
              <Button type="primary" key="console">
                {t("Go to actuarial sheet")}
              </Button>
              ,
            </Link>,
            <Button key="buy" onClick={() => setCalcSheetId(undefined)}>
              {t("Execute Again")}
            </Button>,
          ]}
        />
      )}
      {!calcSheetId && (
        <Row gutter={16}>
          <Col span={8}>
            {field("id")}

            <Form.Item label={t("Period")}>{field("period", { rules: [{ required: true }] })(<DatePicker.MonthPicker />)}</Form.Item>
            <Button type="primary" icon={<RocketOutlined />} onClick={onExecute} loading={loading} style={{ marginTop: 5 }}>
              {t("Execute")}
            </Button>
          </Col>
          <Col span={8}></Col>
          <Col span={8}></Col>
        </Row>
      )}
    </div>
  );
};

export default Form.create()(PresentationExecution);
