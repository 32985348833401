import React, { useState, useEffect } from "react";
import { DeleteOutlined, EyeOutlined, SyncOutlined } from '@ant-design/icons';
import { message, Table, Tag, Popconfirm, Button, Modal, Tooltip } from "antd";
import { formatDate } from "../../Lib/Helpers";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";
import Inspector from "react-inspector";

const TriggerLog = (props) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.triggerId) load(props.triggerId);
  }, [props.triggerId]);

  const load = (triggerId) => {
    setLoading(true);
    exe("RepoTriggerLog", { operation: "GET", filter: "triggerId=" + triggerId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };

  const deleteLog = () => {
    setLoading(true);
    exe("EmptyTriggerLog", { triggerId: data[0].triggerId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        setData([]);
      } else message.error(r.msg);
    });
  };

  const getTag = (level) => {
    switch (level) {
      case false:
        return <Tag color="red">{t("Error")}</Tag>;
        break;
      case true:
        return <Tag color="green">{t("Success")}</Tag>;
        break;
      default:
        return <Tag>{t("Other")}</Tag>;
        break;
    }
  };
  const onOpenDto = (record) => {
    Modal[record.ok ? "success" : "error"]({
      title: record.cmd,
      maskClosable: true,
      width: "60%",
      zIndex: 2000,
      content: (
        <div>
          <Inspector data={JSON.parse(record.dto)} expandLevel={1} />
        </div>
      ),
      onOk() {},
    });
  };
  return (
    <div>
      <Button type="link" icon={<SyncOutlined />} onClick={() => load(props.triggerId)}>{t("Refresh")}</Button>
      <Popconfirm title={t("Are you sure you want to delete this log entries?")} onConfirm={deleteLog} disabled={data.length == 0}>
        <Button type="danger" icon={<DeleteOutlined />} disabled={data.length == 0} style={{ marginBottom: 5 }}>
          {t("Empty log")}
        </Button>
      </Popconfirm>
      <Table dataSource={data} loading={loading}>
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
        <Table.Column title={t("Result")} dataIndex="ok" render={(v) => getTag(v)} />
        <Table.Column title={t("Msg")} dataIndex="msg" />
        <Table.Column
          title={t("Dto")}
          render={(v, r) => (
            <span>
              <Tooltip title={t("Open DTO")}>
                <Button type="link" icon={<EyeOutlined />} onClick={() => onOpenDto(r)} disabled={!r.dto}></Button>
              </Tooltip>
            </span>
          )}
        />
      </Table>
    </div>
  );
};

export default TriggerLog;
