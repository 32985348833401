import {
  FileOutlined,
  FilterOutlined,
  FolderOpenOutlined,
  ReloadOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Badge, Button, Checkbox, message, Table, Tabs, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import AccountSelect from "./AccountSelect";
import Transaction from "./Transaction";
import { formatDate } from "../../Lib/Helpers";
import TransactionsFilter from "./TransactionsFilter";
import getCache from "../../Lib/Cache";

const Transactions = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState();
  const [activeTab, setActiveTab] = useState("list");
  const [filterVisible, setFilterVisible] = useState(false);
  const [currencies,setCurrencies]=useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
  const [reload,doReload]=useState(0);
  const [auditAdjustment,setAuditAdjustment]=useState(false);


  const [loading, setLoading] = useState(false);
  useEffect(() => {
    loadCurrencies()
  }, []);

  const loadCurrencies=()=>{
    getCache("RepoCurrency").then((r) => {
      if (r.ok) setCurrencies(r.outData);
  })}
  
  const load = () => {
    setLoading(true);
    doReload(c=>c+1);
  };
  const onTransactionAdded = (t) => {
    load();
  };
  const onOpenTransaction = (t) => {
    console.log(t);
    setSelected(t);
    setActiveTab("transaction");
  };
  const onNewTransaction = () => {
    setAuditAdjustment(false)
    setSelected(null);
    setActiveTab("transaction");
  };
  const onDelete = (record) => {
    setData(data.filter((p) => p.id !== record.id));
    setActiveTab("list");
  };
  const onFilterResults = (r) => {
    setData(r.outData);
    setPagination({ ...pagination, total: r.total, current: pagination.total !== r.total ? 1 : pagination.current });
    setLoading(false);
  };
  const getStatusColumn = (status) => {
    //warning, states also set in TransactionStatusSelect.js
    switch (status) {
      case 0:
        return <span><Badge status="warning"/>{t("Draft")}</span>
      case 1:
        return <span><Badge status="success"/>{t("Posted")}</span>
      case -1:
        return <span><Badge status="error"/>{t("Rejected")}</span>
    }
  }
  const handleMenuClick = (e) => {
    if(e.key==="audit") {
      setAuditAdjustment(true);
      setSelected(null);
      setActiveTab("transaction");
    }
  }
  return (
    <div>
      <DefaultPage
        icon="reconciliation"
        title={t("Transactions")}
        extra={
          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        }>
        <Tabs activeKey={activeTab} onChange={(t) => setActiveTab(t)}>
          <Tabs.TabPane tab={t("Transaction List")} key="list">
            <Dropdown.Button  onClick={onNewTransaction} overlay={<Menu onClick={handleMenuClick}>
              <Menu.Item key="audit">
                <ToolOutlined />
                {t("Audit Adjustment")}
              </Menu.Item>
            </Menu>}>
              <FileOutlined />
              {t("New Transaction")}
            </Dropdown.Button>

            <Button type="link" icon={<ReloadOutlined />} onClick={() => load()}>
              {t("Reload")}
            </Button>
            <Table style={{marginTop:5}} dataSource={data} loading={loading} size="middle" pagination={pagination} onChange={(pag) => setPagination(pag)}>
              <Table.Column title={t("ID")} dataIndex="id" />
              <Table.Column title={t("Created")} dataIndex="date" render={(v) => formatDate(v)} />
              <Table.Column title={t("Code")} dataIndex="code" />
              <Table.Column title={t("Ref")} dataIndex="reference" />
              <Table.Column title={t("Operation")} dataIndex="operationCode" />
              <Table.Column title={t("Description")} dataIndex="description" />
              <Table.Column title={t("Amount")} dataIndex="amount" />
              <Table.Column
                title={t("Status")}
                dataIndex="status"
                render={(v) => getStatusColumn(v)}
              />
              <Table.Column title={t("Manual")} dataIndex="manual" render={v=><Checkbox checked={v} /> } />
              <Table.Column
                title={t("Actions")}
                key="actions"
                render={(v, r) => (
                  <div>
                    <Button type="link" icon={<FolderOpenOutlined />} onClick={async (e) =>{
                      setLoading(true);
                      let promise = new Promise((resolve, reject) => {
                        setTimeout(() => {
                          onOpenTransaction(r);
                          setLoading(false);
                          resolve();
                        }, 50)
                      });

                      await promise;
                      }}>
                      {t("Open")}
                    </Button>
                  </div>
                )}
              />
            </Table>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Transaction")} key="transaction">
            <div>
              <Transaction onAdd={onTransactionAdded} selected={selected} defaultCurrency={props.defaultCurrency} onDelete={onDelete} auditAdjustment={auditAdjustment} />
            </div>
          </Tabs.TabPane>
        </Tabs>
        <TransactionsFilter visible={filterVisible} onClose={() => setFilterVisible(false)} onResults={(r) => onFilterResults(r)} pagination={pagination} reload={reload} />
      </DefaultPage>
    </div>
  );
};

export default Transactions;
