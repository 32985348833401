import { DeleteOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, message, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {exe, safeGet} from "../../Lib/Dal";
import ContactLink from "../Contact/ContactLink";
import PolicyCoinsuranceDetail from "./PolicyCoinsuranceDetail";
import {formatMoney, getConfigProfile} from "../../Lib/Helpers";

const PolicyCoinsurance = (props) => {
  const [t] = useTranslation();
  const [detailVisible, setDetailVisible] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  

  useEffect(() => {
    if (props.policy && props.policy.id) load(props.policy.id);
  }, [props.policy.id]);

  const load = (policyId) => {
    setLoading(true);
    exe("RepoCoCession", { operation: "GET", filter: `lifePolicyId=${policyId} AND parentCoCession IS NULL`,include:["Coverage","Broker"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };
  const onSaveCoinsurer = (values) => {
    setLoading(true);
    exe("RepoCoCession", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setDetailVisible(false);
        setData([...data, ...r.outData]);
        if(r.opt=="QUOTE") props.doQuote();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onDelete = (record) => {
    setLoading(true);
    exe("RepoCoCession", { operation: "DELETE", entity: record }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(data.filter((p) => p.id !== record.id));
      } else message.error(r.msg);
    });
  };
  return (
    <div>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setDetailVisible(true)} disabled={props.policy.active}>
        {t("Add Coinsurer")}
      </Button>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => load(props.policy.id)}>
        {t("Reload")}
      </Button>
      <Table loading={loading} dataSource={data}>
        <Table.Column title={t("Coinsurer")} dataIndex="coinsurerName" render={(v, r) => <ContactLink contact={{ id: r.contactId, FullName: v }} />} />
        <Table.Column title={t("Leader")} dataIndex="leader" render={(v) => <Checkbox checked={v} />} />
        <Table.Column title={t("Sum Insured")} dataIndex="sumInsuredCeded" render={(v,r)=>formatMoney(v,r.currency)} />
        <Table.Column title={t("Premium")} dataIndex="premiumCeded" render={(v,r)=>formatMoney(v,r.currency)} />
        <Table.Column title={t("Commission")} dataIndex="commission" render={(v,r)=>formatMoney(v,r.currency)} />
        <Table.Column title={t("%")} dataIndex="percentage" />
        <Table.Column title={t("Overwritten")} dataIndex="overwritten" render={(v) => <Checkbox checked={v} />} />
        <Table.Column title={t("Change")} dataIndex="changeId" />
        <Table.Column title={t("Coverage")} dataIndex="Coverage" render={v=>v?v.code:t("Policy Level")} />
        <Table.Column title={t("Broker")} dataIndex="Broker" render={v=>v?v.FullName:null} />
        <Table.Column title={t("Broker Commission")} dataIndex="brokerCommission" render={(v,r)=>formatMoney(v,r.currency)} />
        <Table.Column
          title={t("Actions")}
          key="actions"
          render={(v, r) => (
            <div>
              <Popconfirm title={t("Are you sure you want to remove this record?")} onConfirm={() => onDelete(r)} disabled={props.policy.active}>
                <Button type="link" icon={<DeleteOutlined />} disabled={props.policy.active}></Button>
              </Popconfirm>
            </div>
          )}
        />
      </Table>
      <PolicyCoinsuranceDetail
        visible={detailVisible}
        onCancel={() => setDetailVisible(false)}
        currency={props.policy.currency}
        policyId={props.policy.id}
        sumInsured={props.policy.insuredSum}
        premium={props.policy.anualPremium}
        onOk={onSaveCoinsurer}
        leader={props.policy.coinsurance == 1}
        configProfile={props.configProfile}
      />
    </div>
  );
};

export default PolicyCoinsurance;
