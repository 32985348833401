import React, {useEffect} from 'react';
import { DeleteOutlined, EnterOutlined, LeftOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, InputNumber, Popconfirm, Row } from "antd";
import {useTranslation} from "react-i18next";
import Money from "../Shared/Money";
import SurchargeSelect from "./SurchargeSelect";

const CommonChangeSurchargeForm = (props) => {
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.selected) props.form.setFieldsValue(props.selected);
    },[props.selected])
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onSave(values);
        })
    }
    const onDelete=()=>{
        props.onDelete(values);
    }
    
    const field=props.form.getFieldDecorator;
    const values=props.form.getFieldsValue();
    return (
        <div>
            <Row gutter={16}>
                <Col span={24}>
                    <Button type="link" icon={<LeftOutlined />} onClick={props.onBack}>{t("Back")}</Button> 
                    <Button type="link" icon={<EnterOutlined />} onClick={onSave}>{t("Submit")}</Button>
                    <Popconfirm title={t("Are you sure you want to remove this record?")} onConfirm={onDelete}>
                        <Button type="link" icon={<DeleteOutlined />} onClick={onDelete}>{t("Delete")}</Button>
                    </Popconfirm>

                    {field("id")}
                    {field("changeId")}
                    {field("currency")}
                    {field("description")}

                    <Form.Item label={t("Surcharge Type")}>
                        {field("name",{rules:[{required:true}]})(<SurchargeSelect onSelect={(v,opt)=>props.form.setFieldsValue({description:opt.props.record.name})} />)}
                    </Form.Item>
                    
                    {/*<Form.Item label={t("Name")}>*/}
                    {/*    {field("name",{rules:[{required:true}]})(<Input min={0} />)}*/}
                    {/*</Form.Item>*/}
                    {/*<Form.Item label={t("Description")}>*/}
                    {/*    {field("description",{rules:[{required:false}]})(<Input.TextArea />)}*/}
                    {/*</Form.Item>*/}
                    <Form.Item label={t("Amount")}>
                        {field("value",{rules:[{required:true}]})(<Money currency={values.currency||props.currency} />)}
                    </Form.Item>

                    
                </Col>
            </Row>
        </div>
    );
};

export default Form.create()(CommonChangeSurchargeForm);