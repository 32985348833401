import {
  DeleteOutlined,
  DownloadOutlined,
  ExperimentOutlined,
  FileOutlined,
  LeftOutlined,
  SaveOutlined,
  SearchOutlined,
  UploadOutlined,FolderOpenOutlined
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Input, message, Popconfirm, Table, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FormulaSimulator from "../Products/FormulaSimulator";
import CodeEditorW from '../Shared/CodeEditorW';
import RecentSelect from '../Shared/RecentSelect';

const CommandChains = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [code, setCode] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const [simulatorVisible,setSimulatorVisible]=useState(false);

  useEffect(() => load(props.match.params.chainId ? props.match.params.chainId : 0), []);

  const load = (chainId) => {
    exe("RepoChain", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
        if (chainId) doSetSelected(r.outData.find((p) => p.id == chainId));
      } else {
        message.error(r.msg);
      }
    });
  };
  const save = (r) => {
    exe("RepoChain", { operation: selected.id ? "UPDATE" : "ADD", entity: {...selected,code:code} }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
        setSelected({...selected,id:r.data.entity.id}); //if not set, the next save without going back will be an add
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
          {t("Search")}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex]&&record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />
    //   ) : (
    //     text
    //   ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end
  const onDelete = (record) => {
    setLoading(true);
    exe("RepoChain", { operation: "DELETE", entity: record }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExport = () => {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    download(JSON.stringify({ ...selected,code:code, id: 0 }), selected.name + ".json", "text/json");
  };
  const onImport = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const myForm = JSON.parse(e.target.result);
        doSetSelected(null);
        doSetSelected(myForm);
      } catch (error) {
        message.error("Invalid file");
      }
    };
    reader.readAsText(file);
    return false;
  };
  const openSimulator=()=>{
    setSimulatorVisible(true);
  }
  const doSetSelected=(r)=>{
    if(r&&r.code) {
      setCode(r.code);
    }else setCode("");

    setSelected(r);
    //inserting in localsotrage history. Limiting to 10 items
    if(r&&r.id){
      let arr=JSON.parse(localStorage.getItem("chainHistory"));
      if(!arr) arr=[];
      arr=arr.filter(item=>item.key!=r.id);
      arr.unshift({key:r.id,label:r.name});
      arr=arr.slice(0,10);
      localStorage.setItem("chainHistory",JSON.stringify(arr));
    }
  }
  const onRecentKeySelect=(v)=>{
    doSetSelected(data.find(p=>p.id==+v));
  }
  return (
    <div>
      <DefaultPage
        title={t("Command Chains")}
        icon="link"
        extra={
          <div>
            <Button.Group>
              <Button icon={<ExperimentOutlined />} onClick={openSimulator}>
                {t("Simulator")}
              </Button>
              <Button icon={<DownloadOutlined />} onClick={onExport} disabled={!selected}>
                {t("Export")}
              </Button>
              <Upload showUploadList={false} beforeUpload={(f) => onImport(f)}>
                <Button icon={<UploadOutlined />}>{t("Import")}</Button>
              </Upload>
              {selected && (
                <Button type="primary" icon={<SaveOutlined />} onClick={save}>
                  {t("Save")}
                </Button>
              )}
            </Button.Group>
          </div>
        }>
        {!selected && (
          <div>
            <Button type="link" icon={<FileOutlined />} onClick={() => doSetSelected({})}>
              {t("New")}
            </Button>
            <RecentSelect variable="chainHistory" onSelect={v=>onRecentKeySelect(v)} />
    
            <Table dataSource={data} size="small" rowKey="id" >
              <Table.Column title={t("ID")} dataIndex="id" {...getColumnSearchProps("id")} />
              <Table.Column title={t("Name")} dataIndex="name" {...getColumnSearchProps("name")} />
              <Table.Column title={t("Operation")} dataIndex="operation" {...getColumnSearchProps("operation")} />
              <Table.Column title={t("Category")} dataIndex="category" {...getColumnSearchProps("category")} />
              <Table.Column
                title={t("Actions")}
                render={(v, r) => (
                  <span style={{whiteSpace:"nowrap"}}>
                    <Button type="link" onClick={() => doSetSelected(r)} icon={<FolderOpenOutlined />} />
                    <Divider type="vertical" />
                    <Popconfirm title={t("Are you sure you want to delete this item?")} onConfirm={() => onDelete(r)}>
                      <Button type="link" icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                  </span>
                )}
              />
            </Table>
          </div>
        )}

        {selected && (
          <div>
            <Button type="link" icon={<LeftOutlined />} onClick={() => doSetSelected(null)}>
              {t("Back")}
            </Button>
            <div style={{ display: "flex" }}>
              <Form.Item label={t("Name")} style={{ flex: 1 }}>
                <Input value={selected.name} onChange={(v) => setSelected({ ...selected, name: v.target.value })} />
              </Form.Item>
              <Form.Item label={t("Operation Description")} style={{ flex: 1, marginLeft: 5 }}>
                <Input value={selected.operation} onChange={(v) => setSelected({ ...selected, operation: v.target.value })} />
              </Form.Item>
              <Form.Item label={t("Category")} style={{ flex: 1, marginLeft: 5 }}>
                <Input value={selected.category} onChange={(v) => setSelected({ ...selected, category: v.target.value })} />
              </Form.Item>
            </div>
            <CodeEditorW javascript value={code} onChange={(v) => setCode(v)} />
          </div>
        )}
        <FormulaSimulator visible={simulatorVisible} onCancel={()=>setSimulatorVisible(false)} formula={code} />
      </DefaultPage>
    </div>
  );
};

export default CommandChains;
