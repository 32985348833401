import React, { useRef, useState } from "react";
import { exe } from "../../Lib/Dal";
import { DownloadOutlined, ThunderboltOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Button, Input, Checkbox, Upload, InputNumber } from "antd";
import Spreadsheet from "../Shared/Spreadsheet";
import ProductSelect from "../Life/ProductSelect";
import BirthdaySelect from "../Shared/BirthdaySelect";
import * as moment from "moment-timezone";
import InvestmentStrategy from "../Financial/InvestmentStrategy";
import DatePickerW from "../Shared/DatePickerW";
//moment.tz.setDefault("Etc/UTC");

const EvolutionSimulation = (props) => {
  const [loading, setLoading] = useState(false);
  const [product,setProduct]=useState(undefined);

  const spreadsheet = useRef();

  const onExecuteSimulation = () => {
    props.form.validateFields((err, values) => {
      console.log(values, "FORM VALUES");
      if (err) return;
      const data = spreadsheet.current.getData().map((p) => ({ date: p[0], nav1: p[1], nav2: p[2], paid: p[3], premium1: p[4], premium2: p[5],yield:p[6] }));
      if (data.length == 0 || data.some((d) => d.date == "")) {
        message.error("Please insert valid data in the data grid");
        return;
      }
      setLoading(true);
      exe("DoAccountEvolution", {
        ...values,
        productCode: values.productCode.key,
        lineList: data,
        sourceAccountId: 1144, //temp
      }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          props.onSimulation(r.outData);
        } else message.error(r.msg);
      });
    });
  };
  const onDownloadData = () => {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    console.log(JSON.stringify(spreadsheet.current.getData()), "saving this data");
    download(JSON.stringify(spreadsheet.current.getData()), "data.txt", "text/json");
  };
  const onLoadData = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      spreadsheet.current.setData(e.target.result);
    };
    reader.readAsText(file);
    return false;
  };
  const simulationColumns = [
    //spreadsheet columns
    { title: "Date", width: 120 },
    { title: "Nav1", width: 120 },
    { title: "Nav2", width: 120 },
    { title: "Paid", width: 120 },
    { title: "Premium1", width: 120 },
    { title: "Premium2", width: 120 },
    { title: "Yield", width: 120 },
  ];
  const onProductSelect=p=>{
    setProduct(JSON.parse(p.configJson));
  }
  const investmentStrategies = product ? product.InvestmentStrategies : [];
  const field = props.form.getFieldDecorator;
  return (
    <div>
      <Form style={{ width: 300 }}>
        <Form.Item label="Product">{field("productCode", { rules: [{ required: true }] })(<ProductSelect onSelect={onProductSelect} />)}</Form.Item>
        <Form.Item label="Contract start date">
          {field("startDate", { initialValue: new moment("2014-01-22"), rules: [{ required: true }] })(<DatePickerW format="YYYY-MM-DD" />)}
        </Form.Item>
        <Form.Item label="Insured Birthday">{field("birthday", { initialValue: "1978-07-21", rules: [{ required: true }] })(<BirthdaySelect />)}</Form.Item>
        <Form.Item label="Gender">{field("male", { initialValue: true, valuePropName: "checked" })(<Checkbox>Male</Checkbox>)}</Form.Item>
        <Form.Item label="Strategy code">{field("strategyCode", {  rules: [{ required: true }] })( <InvestmentStrategy options={investmentStrategies}  />)}</Form.Item>
        <Form.Item label="Insured Sum">{field("insuredSum", { initialValue: 20000, rules: [{ required: true }] })(<InputNumber />)}</Form.Item>
        <Form.Item label="Data"></Form.Item>
      </Form>
      <Upload showUploadList={false} beforeUpload={(f) => onLoadData(f)}>
        <Button type="link" icon={<UploadOutlined />}>
          Load
        </Button>
      </Upload>
      <Button type="link" icon={<DownloadOutlined />} onClick={() => onDownloadData()}>
        Download
      </Button>
      <Button type="link" icon={<ThunderboltOutlined />} onClick={onExecuteSimulation} loading={loading}>
        Execute
      </Button>

      <Spreadsheet instance={(t) => (spreadsheet.current = t)} data={[]} columns={simulationColumns} />
    </div>
  );
};

export default Form.create()(EvolutionSimulation);
