import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Col, Input, Modal, Row, Switch } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormLabel from "../Shared/FormLabel";
import DatePickerW from "../Shared/DatePickerW";

const ReEventDetail = (props) => {
  const [t] = useTranslation();

  useEffect(() => {
    if (props.value) {
      const values = props.value;
      if (values.start) values.start = moment(values.start);
      if (values.end) values.end = moment(values.end);
      props.form.setFieldsValue(values);
    }
  }, [props.value]);

  const field = props.form.getFieldDecorator;
  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        props.onOk(values);
        props.form.resetFields();
      }
    });
  };
  return (
    <Modal title={t("Reinsurance Event Detail")} visible={props.visible} onOk={onOk} onCancel={props.onCancel} okText={t("Save")} cancelText={t("Cancel")}>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label={t("ID")}>{field("id")(<FormLabel />)}</Form.Item>
          <Form.Item label={t("Code")}>{field("code", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          <Form.Item label={t("Title")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
          <Form.Item label={t("Description")}>{field("description", { rules: [{ required: true }] })(<Input.TextArea />)}</Form.Item>
          <Form.Item label={t("Start")}>{field("start", { rules: [{ required: true }] })(<DatePickerW />)}</Form.Item>
          <Form.Item label={t("End")}>{field("end", { rules: [{ required: true }] })(<DatePickerW />)}</Form.Item>
          <Form.Item label={t("Catastrophic")}>{field("catastrophic", { valuePropName: "checked" })(<Switch />)}</Form.Item>
        </Col>
      </Row>
    </Modal>
  );
};

export default Form.create()(ReEventDetail);
