import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import {useTranslation} from "react-i18next";

const ClosureTypeSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t]=useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("RepoClosureTypeCatalog", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      }
    });
  };
  return (
    <div>
      <Radio.Group buttonStyle="solid" loading={loading} value={props.value} onChange={props.onChange}>
        {data.map((p) => (
          <Radio.Button value={p.code}>
            <LegacyIcon type={p.icon} style={{ marginRight: 5 }} />
            {t(p.name)}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default ClosureTypeSelect;
