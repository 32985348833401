import React, { useState, useEffect } from "react";
import { ApiOutlined, EditOutlined, PlusOutlined, ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Typography, Tooltip, Button, Table, message } from "antd";
import Spreadsheet from "../Shared/Spreadsheet";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";
import {formatDateShortUtc} from "../../Lib/Helpers";
import AddExchangeRecord from "./AddExchangeRecord";
import EditExchangeRecord from "./EditExchangeRecord";

const InvestmentPlanData = (props) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [spreadsheetVisible, setSpreadsheetVisible] = useState(false);
  const [newRecord,setNewRecord] = useState(false);
  const [selectedRecord,setSelectedRecord]=useState(null);
  useEffect(() => {
    load(props.code);
  }, []);

  const load = (code) => {
    setIsLoading(true);
    exe("RepoInvestmentPlanData", { operation: "GET", filter: "investmentPlanCode='" + code + "'" }).then((r) => {
      setIsLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  let spreadsheet; //spreadsheet instance set on component did mount of Spreadsheet component
  const columns = [
    //spreadsheet columns
    { title: t("Date"), width: 120 },
    { title: t("Bid"), width: 120 },
    { title: t("Ask"), width: 120 },
    { title: t("Open"), width: 120 },
    { title: t("High"), width: 120 },
    { title: t("Low"), width: 120 },
    { title: t("Close"), width: 120 },
    { title: t("Yield"), width: 120 },
  ];

  const save = () => {
    const parsedData = spreadsheet
      .getData()
      .map((p) => ({
        investmentPlanCode: props.code,
        date: p[0],
        bid: p[1] || 0,
        ask: p[2] || 0,
        open: p[3] || 0,
        high: p[4] || 0,
        low: p[5] || 0,
        close: p[6] || 0,
        yield: p[7] || 0,
      }));
    exe("RepoInvestmentPlanData", { operation: "MERGE", bulkKey: props.code, bulkJson: JSON.stringify(parsedData) }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSyncData = (code) => {
    exe("SyncDailyQuote").then((r) => {
      if (r.ok) {
        message.success("Quotes sync'd");
        load(props.code);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onNewRecord=r=>{
    setIsLoading(true);
    exe("AddExchangeRecord",{entity:{...r,investmentPlanCode:props.code}}).then(r=> {
        setIsLoading(false);
        if(r.ok){
            message.success(r.msg);
            setNewRecord(false);
            load(props.code);
        }else{
            message.error(r.msg);
        }
        });
  }
  const onEditRecord=r=> {
      setIsLoading(true);
      exe("EditExchangeRecord",{entity:{...r,investmentPlanCode:props.code}}).then(r=> {
          setIsLoading(false);
          if(r.ok){
              message.success(r.msg);
              setSelectedRecord(null)
              load(props.code);
          }else{
              message.error(r.msg);
          }
      });
  }
  return (
    <div>
      <Typography.Title style={{ marginTop: 5 }} level={4}>
        {t("Historical Data")}
      </Typography.Title>
        <Button type="link" icon={<PlusOutlined />} onClick={() => setNewRecord(true)}>
            {t("Add")}
        </Button>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => load(props.code)}>
        {t("Reload")}
      </Button>
      <Tooltip title="Last sync:2020-03-13">
        <Button type="link" icon={<ApiOutlined />} onClick={() => onSyncData(props.code)}>
          {t("Sync")}
        </Button>
      </Tooltip>
      {spreadsheetVisible && (
        <Button type="link" icon={<SaveOutlined />} onClick={() => save()}>
          {t("Save Data")}
        </Button>
      )}
      <Button type="link" icon={<EditOutlined />} onClick={() => setSpreadsheetVisible(!spreadsheetVisible)}>
        {spreadsheetVisible ? t("Table Mode") : t("Spreadsheet Mode")}
      </Button>
      {!spreadsheetVisible && (
        <Table dataSource={data} loading={isLoading} rowKey="id">
          <Table.Column dataIndex="date" title={t("Date")} render={v=>formatDateShortUtc(v)}></Table.Column>
          <Table.Column dataIndex="bid" title={t("Bid")}></Table.Column>
          <Table.Column dataIndex="ask" title={t("Ask")}></Table.Column>
          <Table.Column dataIndex="open" title={t("Open")}></Table.Column>
          <Table.Column dataIndex="high" title={t("High")}></Table.Column>
          <Table.Column dataIndex="low" title={t("Low")}></Table.Column>
          <Table.Column dataIndex="close" title={t("Close")}></Table.Column>
          <Table.Column dataIndex="yield" title={t("Yield")}></Table.Column>
            <Table.Column key="actions" title={t("Actions")} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelectedRecord(r)} /></div>}></Table.Column>
        </Table>
      )}
      {spreadsheetVisible && (
        <Spreadsheet
          instance={(t) => (spreadsheet = t)}
          data={data.map((p) => [p.date, p.bid, p.ask, p.open, p.high, p.low, p.close, p.yield])}
          columns={columns}
        />
      )}
        <AddExchangeRecord visible={newRecord} onCancel={()=>setNewRecord(false)} onOk={onNewRecord} />
        <EditExchangeRecord visible={selectedRecord} onCancel={()=>setSelectedRecord(null)} onOk={onEditRecord} record={selectedRecord} />
    </div>
  );
};

export default InvestmentPlanData;
