import React, { Component, useEffect, useState } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Input,
  Table,
  message,
  Button,
  Select,
  Radio,
  Row,
  Col,
  DatePicker,
  Tag,
  InputNumber,
} from "antd";
import { exe } from "Lib/Dal";
import ContactSelect from "../Contact/ContactSelect";
import moment from "moment";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import PolicySelect from "./PolicySelect";
import LobSelect from "../Shared/LobSelect";
import ProductSelect from "../Life/ProductSelect";
import ClaimTypeSelect from "./ClaimTypeSelect";
import AuditStatusSelect from "./AuditStatusSelect";
import DatePickerW from "../Shared/DatePickerW";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

const Filter = (props) => {
  const [t, i18n] = useTranslation();
  const [matches, setMatches] = useState(undefined);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (window.location.hash.includes("?date=")) {
      const date = window.location.hash.split("?date=")[1];
      props.form.setFieldsValue({ date: moment(date) });
    } 
    submit();
  }, [window.location.hash,props.pagination.current]);

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      console.log("Received values of form: ", values);
      let queries = [];
      if (values.claimer !== undefined) queries.push(`claimerId=${values.claimer}`);
      if (values.status) queries.push(`processId in (select id from Proceso WHERE estado='${values.status}')`);
      if (values.date)
        queries.push(`YEAR(created)=${values.date.format("YYYY")} AND MONTH(created)=${values.date.format("M")} AND DAY(created)=${values.date.format("D")}`);

      if (values.id !== undefined) queries.push(`id=${values.id}`);
      if (values.masterClaimId !== undefined) queries.push(`masterClaimId=${values.masterClaimId}`);
      if (values.code) queries.push(`code LIKE '%${values.code}%'`);
      if (values.insuredId !== undefined) queries.push(`insuredId='${values.insuredId}'`);
      if (values.contactId !== undefined) queries.push(`contactId=${values.contactId}`);
      if (values.policyId !== undefined) queries.push(`lifePolicyId=${values.policyId}`);
      if (values.claimType !== undefined) queries.push(`claimType='${values.claimType}'`);
      if (values.lob !== undefined) queries.push(`lifePolicyId in (SELECT id from lifePolicy WHERE lob='${values.lob}')`);
      if (values.auditStatus !== undefined) queries.push(`id in (SELECT claimId from Audit WHERE status=${values.auditStatus})`);
      if (values.product !== undefined) queries.push(`lifePolicyId in (SELECT id from lifePolicy WHERE productCode='${values.product.key}')`);
      if (values.occurrence && values.occurrence.length > 0) {
        queries.push(`occurrence between '${values.occurrence[0].format("YYYY-MM-DD")}' and  '${values.occurrence[1].format("YYYY-MM-DD")}'`);
      }
      if (values.notification && values.notification.length > 0) {
        queries.push(`notification between '${values.notification[0].format("YYYY-MM-DD")}' and  '${values.notification[1].format("YYYY-MM-DD")}'`);
      }

      const filterQuery = queries.join(" AND ");
      console.log(queries, filterQuery);
      setLoading(true);
      props.onLoading&&props.onLoading(true);
      exe("RepoClaim", { operation: "GET", include: ["Contact", "Process", "Claimer", "Policy"], filter: filterQuery,
        size: props.pagination.pageSize,
        page: props.pagination.current - 1, }).then((r) => {
        setLoading(false);
        props.onLoading&&props.onLoading(false);
        props.onResults && props.onResults((r.outData || []),r.total);
        setMatches(r.total);
        
      });
    });
  };
  const { getFieldDecorator } = props.form;
  return (
    <Drawer title={t("Claim Filter Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
      <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
        <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
          {t("Reset")}
        </Button>
      </div>

      <Form layout="vertical">
        <div style={{display:"flex"}}>
          <Form.Item label={t("ID")} style={{marginRight:5,width:"100%"}}>{getFieldDecorator("id")(<InputNumber style={{marginRight:5,width:"100%"}} />)}</Form.Item>
          <Form.Item label={t("Master Claim ID")} style={{width:"100%"}}>{getFieldDecorator("masterClaimId")(<InputNumber style={{width:"100%"}} />)}</Form.Item>
        </div>
        <Form.Item label={t("Code")}>{getFieldDecorator("code")(<Input />)}</Form.Item>
        <Form.Item label={t("Date")}>{getFieldDecorator("date")(<DatePickerW placeholder={t("Select date")} />)}</Form.Item>
        <Form.Item label={t("Claimer")}>{getFieldDecorator("claimer")(<ContactSelect />)}</Form.Item>
        <Form.Item label={t("Insured")}>{getFieldDecorator("contactId")(<SimpleContactSelect />)}</Form.Item>
        <div style={{display:"flex"}}>
          <Form.Item style={{marginRight:5,width:"100%"}} label={t("Policy")}>{getFieldDecorator("policyId")(<PolicySelect />)}</Form.Item>
          <Form.Item style={{width:"100%"}} label={t("Status")}>{getFieldDecorator("status")(<Input />)}</Form.Item>
        </div>
        <div style={{display:"flex"}}>
          <Form.Item style={{marginRight:5,width:"100%"}} label={t("LoB")}>{getFieldDecorator("lob")(<LobSelect />)}</Form.Item>
          <Form.Item style={{width:"100%"}} label={t("Product")}>{getFieldDecorator("product")(<ProductSelect all />)}</Form.Item>
        </div>
        <div style={{display:"flex"}}>
          <Form.Item style={{marginRight:5,width:"100%"}} label={t("Claim Type")}>{getFieldDecorator("claimType")(<ClaimTypeSelect />)}</Form.Item>
          <Form.Item style={{width:"100%"}} label={t("Audit Status")}>{getFieldDecorator("auditStatus")(<AuditStatusSelect />)}</Form.Item>
        </div>
        <Form.Item label={t("Occurrence")}>{getFieldDecorator("occurrence")(<DatePicker.RangePicker  />)}</Form.Item>
        <Form.Item label={t("Notification")}>{getFieldDecorator("notification")(<DatePicker.RangePicker />)}</Form.Item>
        <Form.Item label={t("ID card num")}>{getFieldDecorator("insuredId")(<Input />)}</Form.Item>
      </Form>
      <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()} loading={loading}>
        {t("Search")}
      </Button>
      {matches >= 0 && (
        <div style={{ marginTop: 15 }}>
          <Tag>{matches}</Tag> {t("Results")}
        </div>
      )}
    </Drawer>
  );
};
export default Form.create()(Filter);
