import React from "react";

import {
  AppstoreFilled,
  BankOutlined,
  CloseCircleOutlined,
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, Checkbox, message, Typography, Modal, Spin } from "antd";
import { post } from "../Lib/Dal";
import Otp from "./Otp";
import { withTranslation } from "react-i18next";
import { encryptText } from "../Lib/Helpers";
import getCache from "../Lib/Cache";
import EnvironmentLocationSelect from "./Shared/EnvironmentLocationSelect";

const FormItem = Form.Item;

class NormalLoginForm extends React.Component {
  constructor(props) {
    super(props);
    console.log("LOGIN", this.props);
    this.state = {
      loading: false,
      otpVisible: false,
      otp: undefined,
      organizationEdit: false,
      loadingAd: false,
    };
  }
  componentDidMount() {
    //window.location.href = 'login.html';
    this.props.login(true);
    //checking if token is in url
    if (window.location.hash.includes("access_token=")) this.computeToken();
  }

  computeToken = () => {
    //comes from authAd, getting token and user data from url
    const url=window.location.hash;
    //checking if token is present
    if(!url.includes("access_token")) return;

    const token=url.split("access_token=")[1].split("&")[0];
    localStorage.token = token;
    //getting user data with valid token
    getCache("GetCurrentUser").then(r=>{
      if(r.ok){
        const user=r.outData;
        localStorage.email = user.email;
        localStorage.esAdmin = user.esAdmin;
        localStorage.nombre = user.nombre;

        window.GLOBAL.currentUser = user
        this.props.onLogin(user);
        this.props.login(false);
        this.props.history.push("/home");
      }else{
        message.error(r.msg);
      }
    }).catch(e=>{ 
      message.error(this.props.t("Problem connecting to API"));
      console.log(e);
    })
  }

  doLogin = (otp) => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        if(values.organizationCode){
          values.userName+=`:${values.organizationCode}`;
        }
        this.setState({ loading: true, otpVisible: false });

        const dto = { email: values.userName, clave: values.password, otp: otp,language:localStorage.language,timestamp:new Date().toISOString() };
        //dto encryption
        dto.passCode=await encryptText(JSON.stringify(dto));
        //deleting all clear text
        delete dto.email;
        delete dto.clave;
        delete dto.otp;
        delete dto.language;
        delete dto.timestamp;
        post("/api/pub/authenticate", dto)
          .then((r) => {
            console.log(r);
            this.setState({ loading: false });
            if (!r.ok) {
              message.error(r.msg);
            } else {
              if (r.outData.otp == "-1") {
                this.setState({ otpVisible: true });
                return;
              }
              localStorage.token = r.outData.token;
              localStorage.email = r.outData.email;
              localStorage.esAdmin = r.outData.esAdmin;
              localStorage.nombre = r.outData.nombre;

              //renaming cases, serialization problem when body not json()
              r.outData.Groups = r.outData.groups;
              delete r.outData.groups;
              r.outData.Organization=r.outData.organization;
              delete r.outData.organization;
              window.GLOBAL.currentUser = r.outData;
              
              //self service user
                if (r.outData.contactId) {
                  message.error(this.props.t("Unauthorized"));
                  return;
                }

              this.props.onLogin(r.outData);
              this.props.login(false);
              this.props.history.push("/home");
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            message.error(this.props.t("Problem connecting to API"));
            console.log(error, "ERROR");
          });
      }
    });
  };
  onOtpOk() {}
  onTryOtp(v) {
    this.doLogin(v);
  }
  doAdLogin=()=>{
    const adAuthValue=window.location.hash.split("adAuth=")[1].split("&")[0];
    //request to server to get ad login url
    this.setState({loadingAd:true});
    post("/api/pub/getAdLoginUrl",{adAuthValue:adAuthValue}).then(r=>{
      this.setState({loadingAd:false});
      if(r.ok){
        window.sessionStorage.adTenant=r.outData.tenant;
        window.location.href=r.outData.url;
      }else{
        message.error(r.msg);
      }
    }).catch(e=>{
      this.setState({loading:false});
      message.error(this.props.t("Problem connecting to API"));
      console.log(e);
    })
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t } = this.props;
    const organizationSelector=window.location.hash.includes("?org=1")||localStorage.organizationCode;
    const adLogin=window.location.hash.includes("adAuth=");
    if(window.location.hash.includes("access_token=")) return <Spin spinning={true} />
    return (
      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div style={{ width: "300px" }}>
          <div style={{ textAlign: "center", width: "100%", marginBottom: 50 }}>
            <AppstoreFilled style={{ fontSize: 50 }} />
            <Typography.Title level={3}>{t("Welcome to SISos")}</Typography.Title>
          </div>

          <Form onSubmit={this.handleSubmit} style={{ marginTop: "10px" }}>
            <FormItem label={t("Username")}>
              {getFieldDecorator("userName", {
                rules: [{ required: true, message: t("Please input your username") }],
              })(<Input prefix={<UserOutlined style={{ fontSize: 13 }} />} placeholder={t("Username")} onPressEnter={()=>this.doLogin()} />)}
            </FormItem>
            <FormItem label={t("Password")}>
              {getFieldDecorator("password", {
                rules: [{ required: true, message: t("Please input your Password") }],
              })(<Input prefix={<LockOutlined style={{ fontSize: 13 }} />} type="password" placeholder={t("Password")} onPressEnter={()=>this.doLogin()} />)}
            </FormItem>
            <FormItem>
              {/* {getFieldDecorator("remember", {
                valuePropName: "checked",
                initialValue: true,
              })(<Checkbox>Remember me</Checkbox>)} */}
              {/* <a style={{ float: "right" }} >Recordar contraseña</a> */}
              <Button type="primary" size="large" style={{ width: "100%", marginTop: 15 }} loading={this.state.loading} onClick={() => this.doLogin()}>
                {t("Login")}
              </Button>
            </FormItem>
            <div style={{display:adLogin?"block":"none"}}>
              <Button size="large" style={{ width: "100%", marginTop: 15 }} loading={this.state.loadingAd} onClick={() => this.doAdLogin()}>{t("Login with AD")}</Button>
              </div>
            <div style={{display:organizationSelector?"block":"none"}}>
              <div style={{display:this.state.organizationEdit?"block":"none"}}>
                <FormItem label={t("Organization Code")}>
                  {getFieldDecorator("organizationCode",{initialValue:localStorage.organizationCode})(
                     <Input prefix={<BankOutlined style={{ fontSize: 13 }} />} 
                            placeholder={t("Organization Code")}
                            autocomplete="one-time-code"
                            type={"password"} 
                            onPressEnter={()=>this.doLogin()} 
                            suffix={<CloseCircleOutlined onClick={()=>this.setState({organizationEdit:false})} />}
                     />)}
                </FormItem>
              </div>
              <div style={{display:this.state.organizationEdit?"none":"block"}}>
                <FormItem label={t("Organization")}>
                {<div >{localStorage.organizationName?localStorage.organizationName:t("Main")}</div>}
                  </FormItem>
                <Button style={{ marginTop: 15 }} onClick={()=>this.setState({organizationEdit:!this.state.organizationEdit})}>{t("Switch Organization")}</Button>
                </div>
            </div>
          </Form>
        </div>
        <Modal footer={null} visible={this.state.otpVisible} onCancel={() => this.setState({ otpVisible: false })}>
          <Otp onOtpOk={() => this.onOtpOk()} onTryOtp={(v) => this.onTryOtp(v)} />
        </Modal>
        <div style={{ position: "absolute", right: 10, top: 10 }}>
          <EnvironmentLocationSelect />
        </div>
        <div style={{ position: "absolute", left: 10, bottom: 10 }}>Copyright © {new Date().getFullYear()} Axxis Systems</div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(NormalLoginForm);
export default withTranslation()(WrappedNormalLoginForm);
