import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message, Tag} from "antd";
import ContractReinstatementForm from "./ContractReinstatementForm";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";

const ContractReinstatement = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.contractId) load();
    },[props.contractId])
    
    const load=()=>{
        setLoading(true);
        exe("RepoContractInstallment",{operation:"GET",filter:`contractId=${props.contractId}`,include:["Contact"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    
    const onSave=values=>{
        let operation;
        if(values.id){
            operation="UPDATE";
        }else{
            operation="ADD"
            values.contractId=props.contractId;
        }
        setLoading(true);
        exe("RepoContractInstallment",{operation:operation,entity:values}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })
        
    }
    const onDelete=record=>{
        setLoading(true);
        exe("RepoContractInstallment",{operation:"DELETE",entity:record}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })

    }
    const getStatus=v=>{
        switch (v){
            case 1:
                return <Tag color={"green"}>{t("Paid")}</Tag>
            default:
                return <Tag> {t("Pending")}</Tag>
        }
    }
    
    return (
        <div>
            {!selected&&<div>
                <Button type={"primary"} icon={<LegacyIcon type={"plus"} />} onClick={()=>setSelected({id:0})}>{t("New")}</Button>
                <Table dataSource={data} rowKey={"id"} style={{marginTop:5}}>
                    <Table.Column title={t("Installment")} dataIndex={"installmentNum"} />
                    <Table.Column title={t("Payment Date")} dataIndex={"payDate"} />
                    <Table.Column title={t("Beneficiary")} dataIndex={"contactId"} render={(v,r)=>r.Contact?r.Contact.FullName:""} />
                    <Table.Column title={t("Line Id")} dataIndex={"lineId"} />
                    <Table.Column title={t("Status")} dataIndex={"status"} render={v=>getStatus(v)} />
                    <Table.Column title={t("Amount")} dataIndex={"amount"} />
                    <Table.Column title={t("Currency")} dataIndex={"currency"} />
                    <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} /> </div>}/>
                </Table>
            </div>}
            {selected&&<ContractReinstatementForm onBack={()=>setSelected(null)} onSave={onSave} selected={selected} onDelete={onDelete} />}
        </div>
    );
};

export default ContractReinstatement;