import React, {useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, Skeleton, Table, Tag, Tooltip} from "antd";
import Operations from "../Financial/Operations";
import DefaultPage from "../Shared/DefaultPage";
import Status from "../Shared/Status";
import {formatDate, getColor} from "../../Lib/Helpers";
import {useTranslation} from "react-i18next";
import {safeGetRaw} from "../../Lib/Dal";

const SsClaims = (props) => {
    const [loadingOperations,setLoadingOperations]=useState(true);
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();

    const isDisabled=name=>{
        //checking if the button is disabled by configuration
        if(props.configProfile && props.configProfile.disabledActions){
            return props.configProfile.disabledActions.includes(name);
        }
        return false;
    }
    const getCode=lifePolicyId=>{
        const policy=props.policies.find(p=>p.id===lifePolicyId);
        return policy?policy.code:lifePolicyId+" (id)";
    }

    return (
        <div>
            <DefaultPage
                title={ t("My Claims")}
                subTitle={<span>{t("Insurance Products")}</span>}
                iconStyle={{ backgroundColor: safeGetRaw(["Style","primaryColor"],props.configProfile,"#feca57") }}
                routes={{
                    routes: [
                        { breadcrumbName: t("Home"), path: "/SelfService/home" },
                        { breadcrumbName: t("Claims"), path: "quoter" },
                    ],
                }}
                icon="medicine-box"
                loading={loading}
                extra={<div>
                    <Button type={"primary"} disabled={isDisabled("addClaim")} icon={<LegacyIcon type={"plus"} />} onClick={()=>window.location="#/SelfService/newClaim"}>{t("New")}</Button> 
            </div>}>

                <Table dataSource={props.claims} rowKey={"id"}>
                    <Table.Column dataIndex={"id"} title={t("Id")} />
                    <Table.Column dataIndex={"code"} title={t("Code")} />
                    <Table.Column dataIndex={"lifePolicyId"} title={t("Policy")} render={v=><Tag>{getCode(v)}</Tag>} />
                    <Table.Column dataIndex={["EventReason","name"]} title={t("Event Reason")} />
                    <Table.Column dataIndex={["InsuredEvent","name"]} title={t("Insured Event")} />
                    <Table.Column dataIndex={"occurrence"} title={t("Date of occurrence")} render={v=>v?formatDate(v):"-"} />
                    <Table.Column dataIndex={"claimType"} title={t("Type")} />
                    <Table.Column title={t("Status")} dataIndex="status" render={(text, r) => <Tag> {r.Process ? r.Process.estado : t("N/A")}</Tag>} />
                </Table>
            </DefaultPage>
        </div>
    );
};

export default SsClaims;