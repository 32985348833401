import React from "react";
import { GlobalOutlined } from '@ant-design/icons';
import { Select, Spin, Tooltip } from "antd";
import { exe } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";

const Option = Select.Option;

class CptSelect extends React.Component {
  static getDerivedStateFromProps(nextProps, state) {
    // Should be a controlled component if inside form
    if ("value" in nextProps) return { value: nextProps.value };
    return null;
  }

  static defaultProps = {
    exclude: [], //excluded contacts from list
    contactChange: (value) => {
      console.log("Contact selected:", value);
    },
  };
  state = {
    data: [],
    fetching: false,
  };

  fetchData = (value) => {
    if(!value) return;
    console.log("fetching data", value, this.props.exclude);
    this.setState({ data: [], fetching: true });
    const searchByDescription=value.indexOf("-")===0;
    if(searchByDescription&&value.length<3) return;
    const filter=searchByDescription?`description LIKE '%${value.substring(1)}%' AND LEVEL=0`:`code LIKE '${value}%' AND LEVEL=0`;
    exe("RepoCpt", {
      operation: "GET",
      filter: filter,
    }).then((r) => {
      const data = r.outData
        .filter((item) => this.props.exclude.indexOf(item.id) == -1)
        .map((record) => ({
          text: `${record.code} ${record.description}`,
          value: record.code,
          ...record,
        }));
      this.setState({ data, fetching: false });
    });
  };
  onSelect = (v) => {
    this.props.onChange && this.props.onChange(v.key ? v.key : 0);
    const r = this.state.data.find((p) => p.code == v.key);
    this.props.onSelect && this.props.onSelect(r);
  };

  handleChange = (value) => {
    this.setState({
      value: value ? value.key : 0,
      display: value ? value.label : undefined,
      data: [],
      fetching: false,
      selectValue: value ? value : undefined,
    });
  };
  render() {
    const { fetching, data, selectValue } = this.state;
    const { t, i18n } = this.props;
    //mapping component value to Select
    let comboValue = selectValue || {
      key: this.state.value,
      label: this.props.display,
    }; //2. user selected value or 1. initial passed prop values
    if (comboValue.key !== this.state.value) comboValue = { key: this.state.value, label: this.props.display }; //3.update from outside
    if (!this.state.value) comboValue = undefined; //new redord or reseted value

    return (
      <div style={{ position: "relative" }}>
        <Select
          labelInValue
          value={comboValue}
          onSelect={this.onSelect}
          showSearch
          allowClear
          onDropdownVisibleChange={(open) => console.log(open)}
          suffixIcon={<GlobalOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          showArrow={true}
          placeholder={t("Type to search code...")}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={this.fetchData}
          onChange={this.handleChange}
          style={{ width: "100%" }}>
          {data.map((d) => (
            <Option key={d.value}>{d.text}</Option>
          ))}
        </Select>
      </div>
    );
  }
}
export default withTranslation()(CptSelect);
