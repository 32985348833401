import {
  DeleteOutlined,
  DollarOutlined,
  ReloadOutlined,
  ThunderboltOutlined,
  UndoOutlined,
} from '@ant-design/icons';

import { Button, Checkbox, Divider, message, Progress, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import {formatDate, formatMoney, formatNumber, round2} from "../../Lib/Helpers";
import Currency from "../Shared/Currency";
import DefaultPage from "../Shared/DefaultPage";
import GoalPayment from "./GoalPayment";

const GoalStatus = (props) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [goalPaymentVisible, setGoalPaymentVisible] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(undefined);
  const isDefinitionPage = props.definitionPage;
  useEffect(() => {
    load();
  }, []);
  const load = () => {
    let filter = "";
    if (props.definitionPage) filter = "goalDefinitionId=" + props.goalDefinitionId;
    setLoading(true);
    exe("RepoGoalStatus", { operation: "GET", include: ["GoalDefinition", "GoalMember", "GoalMember.Contact"], filter }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onOpen = (id) => {
    window.location.hash = "#/GoalDefinition/" + id;
  };
  const onCompute = () => {
    setLoading(true);
    exe("ComputeGoals",{goalDefinitionId:props.definitionPage?props.goalDefinitionId:undefined}).then((r) => {
      setLoading(false);
      if (r.ok) load();
      else message.error(r.msg);
    });
  };
  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
    console.log(keys);
  };
  const onPay = (goalStatusRecord) => {
    setSelectedGoal(goalStatusRecord);
    setGoalPaymentVisible(true);
  };
  const onGoalPayment = (values) => {
    setGoalPaymentVisible(false);
    setLoading(true);
    values.goalStatusId = selectedGoal.id;
    exe("PayGoal", { ...values }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load();
      } else message.error(r.msg);
    });
  };
  const undoPay = (record) => {
    setLoading(true);
    exe("UndoPayGoal", { goalStatusId: record.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load();
      } else message.error(r.msg);
    });
  };
  const onReset = () => {
    setLoading(true);
    exe("ResetGoalStatus", { goalDefinitionId: props.goalDefinitionId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else message.error(r.msg);
    });
  };
  const Content = (props) => (
    <div>
      <Button type="link" onClick={() => load()} icon={<ReloadOutlined />}>
        {t("Reload")}
      </Button>
      <Button type="link" onClick={onCompute} icon={<ThunderboltOutlined />}>
        {t("Compute Now")}
      </Button>
      {isDefinitionPage && (
        <Button type="link" onClick={onReset} icon={<DeleteOutlined />}>
          {t("Reset Goal Status")}
        </Button>
      )}

      <Table loading={loading} dataSource={data} rowKey="id">
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("Goal")} dataIndex={["GoalDefinition","name"]} render={(v, r) => <a href={"#/GoalDefinition/" + r.goalDefinitionId}>{v}</a>} />
        <Table.Column title={t("Objective")} dataIndex="name" />
        <Table.Column title={t("Period")} dataIndex="period" />
        <Table.Column title={t("Member")} dataIndex={["GoalMember","Contact","FullName"]} />
        <Table.Column title={t("Goal")} dataIndex="goal" render={v=>formatNumber(v)} />
        <Table.Column title={t("Goal Status")} dataIndex="goalStatus" render={v=>formatNumber(v)} />
        <Table.Column title={t("Goal Progress")} key="progress" render={(v, r) => <Progress percent={round2((r.goalStatus / r.goal) * 100)} size="small" />} />
        <Table.Column title={t("Reached")} dataIndex="reached" render={(v) => <Checkbox checked={v} />} />
        <Table.Column title={t("Paid")} key="payed" render={(v, r) => <Checkbox checked={r.paymentId} />} />
        <Table.Column title={t("Reward")} dataIndex="reward" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Updated")} dataIndex="goalUpdated" render={(v) => formatDate(v)} />
        <Table.Column title={t("Payment")} dataIndex="paymentId" render={(v) => <a href={"#/Payments/" + v}>{v}</a>} />
        <Table.Column
          title={t("Actions")}
          key="actions"
          render={(v, r) => (
            <div style={{ whiteSpace: "nowrap" }}>
              <Button type="link" onClick={() => onPay(r)} icon={<DollarOutlined />} disabled={!r.reached || r.paymentId || !r.reward}>
                {t("Pay")}
              </Button>
              <Divider type="vertical" />
              <Button type="link" onClick={() => undoPay(r)} icon={<UndoOutlined />} disabled={!r.paymentId}>
                {t("Revert")}
              </Button>
            </div>
          )}
        />
      </Table>
      <GoalPayment
        visible={goalPaymentVisible}
        onOk={onGoalPayment}
        onCancel={() => setGoalPaymentVisible(false)}
        contactId={selectedGoal && selectedGoal.GoalMember.contactId}
      />
    </div>
  );
  return (
    <div>
      {!props.definitionPage && (
        <DefaultPage title={t("Goal Status")} icon="gift" extra={<div></div>}>
          <Content />
        </DefaultPage>
      )}
      {props.definitionPage && <Content />}
    </div>
  );
};

export default GoalStatus;
