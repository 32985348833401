import { DownloadOutlined, FolderOutlined, UploadOutlined } from '@ant-design/icons';
import {Button, Divider, Input, message, Popconfirm, Table} from "antd";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import config from "../../config";
import {fetchProxy, getProxy} from "../../Lib/Dal";
import DefaultPage from "./DefaultPage";
import FileUpload from "../Life/FileUpload";

const FileManager = (props) => {
  const [t, i18n] = useTranslation();
  const [url, setUrl] = useState();
  const [folder, setFolder] = useState("/");
  const [data, setData] = useState([]);
  const [upload,setUpload]=useState(false);
  const [loading,setLoading]=useState(false);
  
  const onDownload = () => {
    if (!url) {
      message.error("Please specify a file url");
      return;
    }
    openFile(url);
  };
  const openFile = (v) => {
    try {
      fetch(config.ssApiUrl + "/proxy" + v, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => response.blob())
        .then((data) => window.open(URL.createObjectURL(data)))
        .catch((err) => message.error(t("Unable to open the file. Please verify that you have the necessary permissions")));
    } catch (error) {
      console.log(error);
      message.error(error);
    }
  };
  const getFiles = () => {
    if (!folder) {
      message.error(t("Please specify a folder. (/uploaded for example)"));
      return;
    }
    setLoading(true);
    getProxy("/dir?url=" + folder, "GET").then((r) => {
      setLoading(false)
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(t("Error in request"));
        console.log(r.msg);
      }
    });
  };
  const onDownloadListFile = (v) => {
    openFile(folder + v);
  };
  const onUploadFile=file=>{
    message.success(t("File uploaded to the uploaded folder"))
    setUpload(false);
    setFolder("/uploaded")
    getProxy("/dir?url=/uploaded", "GET").then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(t("Error in request"));
        console.log(r.msg);
      }
    });
  }
  const onDeleteFile = (fileName) => {
    if(folder!=="/uploaded"){
        message.error(t("You can only delete files in the uploaded folder"))
        return;
    }
    fetchProxy(`/file${folder}/${fileName}`, "DELETE",).then((r) => {
      if (r.outData.ok) {
        message.success(r.outData.msg);
        getFiles();
      } else {
        message.error(r.outData.msg);
      }
    });
  }

  return (
    <div>
      <DefaultPage title={t("File Manager")} icon="folder">
        <Button type="link" icon={<UploadOutlined />} onClick={()=>setUpload(!upload)}>
          {t("Upload")}
        </Button>
        {upload&&<FileUpload onChange={(file) => onUploadFile(file)} />}
        <div style={{ display: "flex" }}>
          <Input style={{ width: 400 }} placeholder={t("Doc Server Url")} value={url} onChange={(v) => setUrl(v.target.value)} />
          <Button type="link" icon={<DownloadOutlined />} onClick={onDownload}>
            {t("Download")}
          </Button>
        </div>
        <div style={{ display: "flex", marginTop: 5, marginBottom: 5 }}>
          <Input style={{ width: 400 }} placeholder={t("Doc Server Folder")} value={folder} onChange={(v) => setFolder(v.target.value)} />
          <Button type="link" onClick={getFiles} icon={<FolderOutlined />} loading={loading}>
            {t("List Directory")}
          </Button>
        </div>
        <Table dataSource={data}>
          <Table.Column title={t("Name")} dataIndex="name" />
          <Table.Column title={t("Date")} dataIndex="date" />
          <Table.Column title={t("Action")} key="action" render={(v, r) => <div>
            <Button type="link" icon={<DownloadOutlined />} onClick={() => onDownloadListFile(r.name)} />
            <Divider type="vertical" />
            <Popconfirm title={t("Are you sure you want to delete this file?")} onConfirm={() => onDeleteFile(r.name)}>
              <Button type="link" icon="delete" />
            </Popconfirm>
            </div>
          } />
        </Table>
      </DefaultPage>
    </div>
  );
};

export default FileManager;
