import React from "react";
import {
  CaretRightOutlined,
  RocketOutlined,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Skeleton, Card, Table, Row, Col, Radio, Button, Divider, Input } from "antd";
import { Link } from "react-router-dom";
import { exe } from "../Lib/Dal";
import DefaultPage from "./Shared/DefaultPage";
import Paragraph from "antd/lib/typography/Paragraph";
import { withTranslation } from "react-i18next";
const { Meta } = Card;

class Launcher extends React.Component {
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    this.setState({ loading: true });
    //demo data Array.from(Array(100).keys(), n => {return {nombre:n}}
    //get("/api/definicion").then(r => this.setState({ data:r, loading: false }));
    exe("GetDefinitions").then((r) => {
      this.setState({ data: r.outData, loading: false });
    });
  };
  state = {
    loading: false,
    data: [0],
    viewMode: "list",
  };
  launch(e) {
    console.log(e);
  }
  onChangeViewMode = (e) => {
    console.log("radio checked", e.target.value);
    this.setState({
      viewMode: e.target.value,
    });
  };

  tarjeta = (d) => {
    return (
      <Card
        vis
        style={{ width: 270, marginTop: 16, visibility: d ? "visible" : "hidden" }}
        actions={[
          <Link to={{ pathname: "/proc", proceso: d }}>
            <SettingOutlined />
          </Link>,
          <Link to={{ pathname: "/tasks", proceso: d }}>
            <SearchOutlined />
          </Link>,
          d && d.enabled ? (
            <Link to={{ pathname: "/activity/0", proceso: d }}>
              <CaretRightOutlined />
            </Link>
          ) : (
            <StopOutlined />
          ),
        ]}>
        <Skeleton loading={this.state.loading} avatar active>
          <Meta
            avatar={<LegacyIcon type={d && d.icon ? d.icon : ""} style={{ fontSize: 22 }} theme="twoTone" />}
            title={d ? d.name : this.props.t("N/A")}
            description={d ? <Paragraph ellipsis={{ rows: 2 }}>{d.description ? d.description : this.props.t("No description")}</Paragraph> : null}
          />
        </Skeleton>
      </Card>
    );
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(ele) => (this.searchInput = ele)}
          placeholder={`${this.props.t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(this.state.selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          {this.props.t("Search")}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {this.props.t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys ? selectedKeys[0] : "", searchedColumn: dataIndex });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  render() {
    const t = this.props.t;
    const filas = Array(Math.ceil(this.state.data.length / 4) || 1).fill(1);

    return (
      <div>
        <DefaultPage
          title={t("Process Launcher")}
          icon="rocket"
          extra={
            <Radio.Group value={this.state.viewMode} onChange={this.onChangeViewMode}>
              <Radio value="list">{t("List")}</Radio>
              <Radio value="cards">{t("Cards")}</Radio>
            </Radio.Group>
          }>
          {this.state.viewMode == "cards" &&
            filas.map((e, i) => (
              <Row>
                <Col sm={24} md={6}>
                  {this.tarjeta(this.state.data[0 + i * 4])}
                </Col>
                <Col sm={24} md={6}>
                  {this.tarjeta(this.state.data[1 + i * 4])}
                </Col>
                <Col sm={24} md={6}>
                  {this.tarjeta(this.state.data[2 + i * 4])}
                </Col>
                <Col sm={24} md={6}>
                  {this.tarjeta(this.state.data[3 + i * 4])}
                </Col>
              </Row>
            ))}
          {this.state.viewMode == "list" && (
            <Table dataSource={this.state.data} rowKey="id">
              <Table.Column dataIndex="icon" render={(v) => <LegacyIcon type={v} />} width={32} />
              <Table.Column dataIndex="id" title={t("ID")} width={100} {...this.getColumnSearchProps("id")} />
              <Table.Column dataIndex="name" title={t("Name")} {...this.getColumnSearchProps("name")} />
              <Table.Column dataIndex="category" title={t("Category")} {...this.getColumnSearchProps("category")} />
              <Table.Column dataIndex="entity" title={t("Entity")} {...this.getColumnSearchProps("entity")} />
              <Table.Column dataIndex="description" title={t("Description")} ellipsis {...this.getColumnSearchProps("description")} />
              <Table.Column
                key="actions"
                title={t("Actions")}
                render={(v, r) => (
                  <div>
                    <Link to={{ pathname: "/activity/0", proceso: r }}>
                      <Button type="link" icon={<RocketOutlined />} disabled={!r.enabled}></Button>
                    </Link>
                    <Divider type="vertical" />
                    <Link to={{ pathname: "/proc", proceso: r }}>
                      <Button type="link" icon={<SettingOutlined />}></Button>
                    </Link>
                  </div>
                )}
              />
            </Table>
          )}
        </DefaultPage>
      </div>
    );
  }
}
export default withTranslation()(Launcher);
