import React from 'react';
import DefaultPage from '../Shared/DefaultPage';
import { useTranslation } from 'react-i18next';
import ContactIssues from './ContactIssues';
const IssueTracker = props =>{
    const [t, i18n] = useTranslation();
return <div>
     <DefaultPage title={t("Issue Tracker")} icon="alert">
        <ContactIssues />
      </DefaultPage>
</div>
}
export default IssueTracker;