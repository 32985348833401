import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Table, InputNumber } from "antd";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";

const Oynr = (props) => {
  const [t] = useTranslation();
  const [years, setYears] = useState(10);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => load(), []);

  const load = () => {
    exe("GetOynr", { years: years }).then((r) => {
      const cols = Object.keys(r.outData[0]).map((p) => ({
        title: p - 1 + " " + t("Year"),
        dataIndex: p,
        key: p,
        render: (v, r, i) =>
          i >= years ? <span style={{ color: "blue" }}>{v}</span> : i == years - p ? <b style={{ color: "red" }}>{v}</b> : i > years - p ? <span /> : v,
      }));
      cols[0].title = t("Reported-Gap");
      //computing factors last row

      const factorsRow = [
        ["F1", 3468, 2577],
        ["F2", 344, 7777],
      ];

      setColumns(cols);
      setData(r.outData.concat(factorsRow));
    });
  };

  return (
    <div>
      <div>
        <InputNumber style={{ marginRight: 5 }} value={years} onChange={(v) => setYears(v)} />
        {t("Years")}
        <Button type="link" icon={<ThunderboltOutlined />} onClick={load}>
          {t("Generate")}
        </Button>
        <CSVLink data={data} filename={"oynr.csv"} target="_blank" disabled={data.length == 0}>
          <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
            {t("Download")}
          </Button>
        </CSVLink>
      </div>
      <Table style={{ marginTop: 5 }} dataSource={data} columns={columns} pagination={false} />
    </div>
  );
};

export default Oynr;
