import React, { useState, useEffect } from "react";
import { Parser } from "json2csv";
import { FilterOutlined, SafetyOutlined, SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Table,
    Menu,
    DatePicker,
    message,
    Tag,
    Drawer,
    InputNumber,
    Switch,
    Dropdown,
} from "antd";
import { exe } from "../../Lib/Dal";
import useUpdateEffect, { formatMoney, formatDateShort } from "../../Lib/Helpers";
import Currency from "../Shared/Currency";
import PolicySelect from "../Health/PolicySelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import GroupPolicySelect from "../Life/GroupPolicySelect";
import LobSelect from "../Shared/LobSelect";
import ProductSelect from "../Life/ProductSelect";
import { useTranslation } from "react-i18next";
import PaymentMethods from "../Life/PaymentMethods";
import ChainSelect from "../Bpm/ChainSelect";
import ComContractSelect from "../Commissions/ComContractSelect";
import SellerSelect from "../Commissions/SellerSelect";
import ColumnSelect from "../Shared/ColumnSelect";
import { Excel } from "antd-table-saveas-excel";

const GroupCollection = (props) => {
    const [t, i18n] = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [pagination, setPagination] = useState({ pageSize: 15, total: 0, current: 1, showTotal: (total) => `${t("Total")} ${total} ${t("items")}` });
    const [selectedChain, setSelectedChain] = useState();
    const [selectedColumns, setSelectedColumns] = useState([]);

    useEffect(() => setCurrency(props.currency), [props.currency]);
    useUpdateEffect(() => onCompute(), [pagination.current,props.refresh]);

    const setCurrency = (currency) => {
        props.form.setFieldsValue({ currency: currency });
        onCompute();
    };

    const onCompute = () => {
        const v = props.form.getFieldsValue();
        v.page = pagination.current;
        v.size = pagination.pageSize;
        if (v.policyId) v.lifePolicyId = v.policyId;
        if (v.productCode) v.productCode = v.productCode.key;
        setLoading(true);
        exe("ComputeGroupCollection", v).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
                setPagination({ ...pagination, total: r.total });
            } else {
                message.error(r.msg);
            }
        });
    };
    const onPaginationChange = (currentPagination) => {
        setPagination(currentPagination);
    };
    const onResetform=()=>{
        props.form.resetFields();
        setPagination({...pagination,current: 1})
    }

    const field = props.form.getFieldDecorator;
    const values = props.form.getFieldsValue();
    return (
        <div>

            <Button type="link" icon={<SafetyOutlined />} onClick={() => props.setGroupMode(false)}>
                {t("Individual Mode")}
            </Button>

            <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
                {t("Filter")}
            </Button>
            
            <ColumnSelect
                style={{ float: "right" }}
                columns={[t("Policy Code"), t("Scheme"), t("Seller")]}
                onChange={(p) => setSelectedColumns(p)}
                value={selectedColumns}
            />

            <Table
                onChange={(pag) => onPaginationChange(pag)}
                pagination={pagination}
                dataSource={data}
                loading={loading}
                style={{ marginTop: 5 }}
                rowSelection={props.rowSelection}
                key={props.key} //used to reset internal selection state
                rowKey="key">
                <Table.Column title={t("Policy")} dataIndex="groupPolicyId" render={(v) => <a href={"#/lifePolicy/" + v}>{v}</a>} />
                <Table.Column title={t("Policy Code")} dataIndex="policyCode" />
                <Table.Column title={t("Certificates")} dataIndex="certificates"  />
                {selectedColumns.includes(t("Scheme")) && <Table.Column title={t("Scheme")} dataIndex="comContractName" />}
                {selectedColumns.includes(t("Seller")) && <Table.Column title={t("Seller")} dataIndex="sellerName" />}
                <Table.Column
                    title={t("Due")}
                    dataIndex="dueDate"
                    render={(v, r) => (r.dueDays > 0  ? <Tag color="red">{formatDateShort(v)}</Tag> : formatDateShort(v))}
                />
                <Table.Column title={t("Currency")} dataIndex="currency" render={(v) => <Currency.Flag currency={v} />} />
                <Table.Column title={t("Amount")} dataIndex="dueAmount" render={(v, r) => formatMoney(v, r.currency)} />
                <Table.Column title={t("Days Due")} dataIndex="dueDays" />
                <Table.Column title={t("# In Year")} dataIndex="numberInYear" />
                <Table.Column title={t("Contract Year")} dataIndex="contractYear" />
                <Table.Column
                    title={t("Paid")}
                    dataIndex="payed"
                    render={(v, r) => (!r.payed ? formatMoney(v, r.currency) : <Tag color="green">{formatMoney(v, r.currency)}</Tag>)}
                />

            </Table>
            <Drawer
                title={t("Filter")}
                visible={props.parentFilter ? props.filterVisible : filterVisible}
                width={512}
                onClose={() => (props.parentFilter ? props.setFilterVisible(false) : setFilterVisible(false))}>
                <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                    <Button icon={<UndoOutlined />} size="small" onClick={onResetform}>
                        {t("Reset")}
                    </Button>
                </div>
                <Form layout="vertical">
                    <Form.Item label={t("Currency")}>{field("currency")(<Currency />)}</Form.Item>
                    <Form.Item label={t("LoB")}>{field("lob")(<LobSelect />)}</Form.Item>
                    <Form.Item label={t("Product")}>{field("productCode")(<ProductSelect />)}</Form.Item>
                    <Form.Item label={t("Payment Method")}>{field("paymentMethod")(<PaymentMethods module="INCOME" />)}</Form.Item>
                    <div style={{display: "flex"}}>
                        <Form.Item label={t("Due Month")} style={{flex: 1, marginRight: 5}}>{field("dueMonth")(<DatePicker.MonthPicker style={{width:"100%"}}/>)}</Form.Item>
                        <Form.Item label={t("Creation Month")} style={{flex: 1}}>{field("creationMonth")(<DatePicker.MonthPicker style={{width:"100%"}}/>)}</Form.Item>

                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Paid")} style={{ marginRight: 10 }}>
                            {field("payed", { valuePropName: "checked" })(<Switch />)}
                        </Form.Item>
                        <Form.Item label={t("Active")}>{field("active", { valuePropName: "checked", initialValue: true })(<Switch />)}</Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Policy ID")}>{field("lifePolicyId")(<InputNumber />)}</Form.Item>
                        <Form.Item label={t("Min Due Days")} style={{ marginLeft: 10 }}>
                            {field("dueDaysMin")(<InputNumber />)}
                        </Form.Item>
                        <Form.Item label={t("Max Due Days")} style={{ marginLeft: 10 }}>
                            {field("dueDaysMax")(<InputNumber />)}
                        </Form.Item>
                    </div>
                    <Form.Item label={t("Policy")}>{field("policyId")(<PolicySelect proposals />)}</Form.Item>
                    <Form.Item label={t("Policyholder")}>{field("contactId")(<SimpleContactSelect />)}</Form.Item>
                    <div style={{ display: "flex" }}>
                    <Form.Item label={t("Group Policy")} style={{width:"100%", marginRight: 5 }}>{field("groupPolicyId")(<GroupPolicySelect />)}</Form.Item>
                    <Form.Item label={t("Group Policy Id")} style={{width:"100%"}}>{field("groupPolicyIdNum")(<InputNumber style={{width:"100%"}} min={1} />)}</Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Commission Scheme")} style={{ width: "100%", marginRight: 5 }}>
                            {field("comContractId")(<ComContractSelect active />)}
                        </Form.Item>
                        <Form.Item label={t("Seller")} style={{ width: "100%" }}>
                            {field("sellerId", { normalize: (v) => (v ? v : undefined) })(
                                <SellerSelect comContractId={values.comContractId} disabled={!values.comContractId} />
                            )}
                        </Form.Item>
                    </div>
                </Form>
                <Button type="primary" icon={<SearchOutlined />} onClick={onCompute}>
                    {t("Search")}
                </Button>
                {pagination.total !== null && (
                    <div style={{ marginTop: 15 }}>
                        <Tag>{pagination.total}</Tag> {t("Results")}
                    </div>
                )}
            </Drawer>
        </div>
    );

};
export default Form.create()(GroupCollection);
