import React, { useState } from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Tabs,
  Table,
  Typography,
  Row,
  Col,
  Select,
  Input,
  InputNumber,
  message,
  Switch,
} from "antd";
import Section from "../Shared/Section";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";
import { exe } from "../../Lib/Dal";
import AccountSelect from "../Shared/AccountSelect";
import { useTranslation } from "react-i18next";
import ExternalSourceSelect from "../Financial/ExternalSourceSelect";
import IncomeTypeSelect from "./IncomeTypeSelect";

const ManualTransfer = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [externalSource, setExternalSource] = useState();

  const field = props.form.getFieldDecorator;
  const currency = props.form.getFieldValue("currency") || "EUR";

  const onExecute = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        if (props.workspaceId) values.transferWorkspaceId = props.workspaceId;
        console.log("Received values of form: ", values);
        setLoading(true);
        exe("RepoTransfer", { operation: "ADD", entity: values }).then((r) => {
          setLoading(false);
          if (r.ok) {
            message.success(r.msg);
            props.onNewManualTransfer && props.onNewManualTransfer();
          } else {
            message.error(r.msg);
          }
        });
      }
    });
  };
  const validateDestinationAccount = (rule, value, callback) => {
    const currency = props.form.getFieldValue("currency");
    console.log(window.selectedDestCurrency, currency, "VALIDATOR");
    if (window.selectedDestCurrency == currency) callback();
    else callback(false);
  };
  const isExternal = props.form.getFieldValue("isExternal");
  return (
    <div>
      <Typography.Title level={4}>{t("Manual Transfer")}</Typography.Title>
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={8}>
            <Section text={t("Source Information")}>
              <Form.Item label={t("Currency")}>{field("currency", { initialValue: "EUR", rules: [{ required: true }] })(<Currency />)}</Form.Item>
              <Form.Item label={t("Amount")}>{field("amount", { rules: [{ required: true }] })(<Money currency={currency} />)}</Form.Item>
              <Form.Item label={t("Reference")}>{field("concept", { rules: [{ required: true }] })(<Input />)}</Form.Item>
              <Form.Item label={t("External Source")}>{field("isExternal", { valuePropName: "checked" })(<Switch />)}</Form.Item>

              <div style={{ display: isExternal ? "block" : "none" }}>
                <Form.Item label={t("External Source")}>
                  {field("sourceExternal", { rules: [{ required: true }] })(<ExternalSourceSelect currency={currency} onSelect={(v) => setExternalSource(v)} />)}
                </Form.Item>
                <Form.Item label={t("Income Type")}>{field("incomeType")(<IncomeTypeSelect />)}</Form.Item>
              </div>
              <div style={{ display: isExternal ? "none" : "block" }}>
                <Form.Item label={t("Source Account")}>{field("sourceAccountId", { rules: [{ required: !isExternal }] })(<AccountSelect />)}</Form.Item>
                <Form.Item label={t("Source Name")}>{field("sourceName", {})(<Input />)}</Form.Item>
              </div>
            </Section>
          </Col>
          <Col span={8}>
            <Section text={t("Destination Information")}>
              <Form.Item label={t("Destination Account")}>
                {field("destinationAccountId", { rules: [{ required: true }] })(
                  <AccountSelect accNo={externalSource ? externalSource.destinationAccNo : undefined} />
                )}
              </Form.Item>
              <Form.Item label={t("Destination Name")}>{field("destinationName", {})(<Input />)}</Form.Item>
              <Button type="primary" onClick={() => onExecute()} loading={loading}>
                {t("Execute")}
              </Button>
            </Section>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(ManualTransfer);
