import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import CostCenterEdit from "./CostCenterEdit";
import {exe} from "../../Lib/Dal";

const CostCenters = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    
    const onSave=values=>{
        setSelected(null);
        if(values.id){
            //updating
            console.log("updating",values);
            setLoading(true);
            exe("RepoTransactionLineCostCenter",{operation:"UPDATE",entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    props.reload();
                }else message.error(r.msg)
            })
            
        }else{
            //adding
            console.log("adding",values);
            const affectedLine=props.transaction.Lines.find(p=>p.order===values.line);
            if(!affectedLine){
                message.error(t("Line not found in transaction --> "+values.line));
                return;
            }
            values.transactionLineId=affectedLine.id;
            setLoading(true);
            exe("RepoTransactionLineCostCenter",{operation:"ADD",entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    props.reload();
                }else message.error(r.msg)
            })
        }
    }
    const onDelete=record=>{
        setSelected(null);
        setLoading(true);
        exe("RepoTransactionLineCostCenter",{operation:"DELETE",entity:record}).then(r=>{
            setLoading(false);
            if(r.ok){
                props.reload();
            }else message.error(r.msg)
        })
    }

    const onNew=()=>{
        setSelected({id:0})
    }
    
    const data=props.transaction&&props.transaction.Lines.flatMap(l=>(l.CostCenters||[]).map(p=>({line:l.order,transactionLineId:l.id,...p})))||[];
    
    return (
        <Modal visible={props.visible} title={t("Cost Centers")} onCancel={props.onCancel} onOk={props.onOk}>
            {!selected&&<div>
                <Button type={"link"} icon={<LegacyIcon type={"plus"} />} onClick={onNew}>{t("Add")} </Button>
                <Table dataSource={data} rowKey={"id"} style={{marginTop:5}} pagination={false} loading={loading}>
                    <Table.Column title={t("Line")} dataIndex={"line"} />
                    <Table.Column title={t("Cost Center")} dataIndex={"CostCenter.name"} />
                    <Table.Column title={t("Value")} dataIndex={"value"} />
                    <Table.Column title={t("Code")} dataIndex={"valueCode"} />
                    <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} /> </div>} />
                </Table>
            </div>}
            {selected&&<div>
                <CostCenterEdit selected={selected} onBack={()=>setSelected(null)} onOk={r=>onSave(r)} onDelete={onDelete} />
            </div>}
        </Modal>
    );
};

export default CostCenters;