import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Card,
  Table,
  Menu,
  Dropdown,
  Breadcrumb,
  Tag,
  Row,
  Col,
  Input,
  Drawer,
  Radio,
  Switch,
} from "antd";
import { Link } from "react-router-dom";
import { get } from "../../Lib/Dal";
import ActividadBuscar from "./ActividadadBuscar";
import moment from "moment";

const ButtonGroup = Button.Group;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class Equipo extends React.Component {
  state = {
    data: [],
    filtroVisible: false,
    pagination: {
      pageSize: 10
    }
  };
  filtro = {
    grupos: "mios",
    finalizada: false
  };
  componentWillMount() {
    this.getData();
  }
  getData = (page = 1, filtro) => {
    this.setState({ loading: true });

    const filtroTotal = Object.keys(this.filtro)
      .filter(f => this.filtro[f] != undefined)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(this.filtro[k])}`)
      .join(";");
    console.log(filtroTotal, "aplicando filtro");

    get("/api/proceso/equipo/?filtro=" + filtroTotal + "&page=" + page).then(r => {
      console.log(filtroTotal, r);
      const pagination = { ...this.state.pagination };
      pagination.total = r.total;
      this.setState({ data: r.data, loading: false, pagination });
    });
  };
  onClickBuscar = () => {
    this.setState({ filtroVisible: true });
  };
  onCloseFiltro = () => {
    this.setState({ filtroVisible: false });
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(pager, "PAGINATION");
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });

    this.getData(pagination.current);

    // this.fetch({
    //   results: pagination.pageSize,
    //   page: pagination.current,
    //   sortField: sorter.field,
    //   sortOrder: sorter.order,
    //   ...filters,
    // });
  };
  onSearch = filtro => {
    this.filtro = filtro;
    this.getData(undefined, filtro);
  };

  filtroFinalizadas = v => {
    this.filtro.finalizada = !v;
    this.getData();
  };
  onClickReset = () => {
    this.filtro = { grupos: "mios" };
    this.getData();
  };

  columns = [
    {
      title: "Nombre",
      dataIndex: "nombre"
    },

    {
      title: "Procesos",
      dataIndex: "procesos"
    },
    {
      title: "Finalizados",
      dataIndex: "finalizados"
    }
  ];
  render() {
    return (
      <div style={{}}>
        <Breadcrumb style={{ marginBottom: "20px" }}>
          <Breadcrumb.Item>
            <Link to="/">Inicio</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/tasks">
              <a>Equipo</a>
            </Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Card title="Equipo" style={{ margin: "10px" }}>
          <Table
            //rowSelection={rowSelection}
            columns={this.columns}
            loading={this.state.loading}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            onChange={this.handleTableChange}
            size="small"
            rowKey="id"
          />
        </Card>
        <Drawer title="Buscar" placement="right" onClose={this.onCloseFiltro} visible={this.state.filtroVisible}>
          <ActividadBuscar onSearch={this.onSearch} />
        </Drawer>
      </div>
    );
  }
}

export default Equipo;
