import { MoreOutlined } from '@ant-design/icons';
import { Select } from "antd";
import React from "react";

const PolicyAccountSelect = (props) => {
  const data = props.Accounts || [];

  return (
    <div>
      <Select style={props.style} value={props.value} onChange={props.onChange} disabled={props.disabled}>
        {data.map((p) => (
          <Select.Option value={p.id}>
            {p.name}
            <MoreOutlined />
            {p.currentAmountBalance}
            <MoreOutlined />
            {p.currency}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default PolicyAccountSelect;
