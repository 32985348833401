import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const GroupSelect = (props) => {
  const [groups, setGroups] = useState([]);
  const [t, i18n] = useTranslation();

  useEffect(() => loadGroups(groups), []);

  const loadGroups = () => {
    exe("RepoUsrGroup", { operation: "GET" }).then((r) => setGroups(r.outData));
  };
  return (
    <div>
      <Select style={{ margin: "5px", width: "100%" }} allowClear={true} placeholder={t("Group")} value={props.value} onChange={(v) => props.onChange(v)}
              optionFilterProp="children" showSearch
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {groups.map((u) => (
          <Select.Option value={u.id} key={u.id}>
            {u.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default GroupSelect;
