import { DeleteOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import GoalMemberDetail from "./GoalMemberDetail";

const GoalMembers = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMemberVisible, setNewMemberVisible] = useState(false);
  const [t] = useTranslation();

  useEffect(() => loadMembers(), []);

  const addMember = (values) => {
    values.goalDefinitionId = props.goalDefinitionId;
    setLoading(true);
    exe("RepoGoalMember", { operation: "ADD", entity: values }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setNewMemberVisible(false);
        loadMembers();
      }
    });
  };
  const loadMembers = () => {
    setLoading(true);
    exe("RepoGoalMember", { operation: "GET", include: ["Contact"], filter: `goalDefinitionId=${props.goalDefinitionId}` }).then((r) => {
      setLoading(false);
      if (r.ok) setData(r.outData);
    });
  };
  const removeMember = (memberId) => {
    setLoading(true);
    exe("RepoGoalMember", { operation: "DELETE", entity: { id: memberId } }).then((r) => {
      setLoading(false);
      if (r.ok) setData(data.filter((p) => p.id !== memberId));
    });
  };
  return (
    <div>
      <Button type="link" icon={<PlusOutlined />} onClick={() => setNewMemberVisible(true)}>
        {t("Add Member")}
      </Button>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => loadMembers()}>
        {t("Reload")}
      </Button>
      <Table dataSource={data} loading={loading} rowKey="id">
        <Table.Column title={t("ID")} dataIndex="id" />
        <Table.Column title={t("Contact ID")} dataIndex="contactId" />
        <Table.Column title={t("Contact")} dataIndex={["Contact","FullName"]} />
        <Table.Column
          title={t("Actions")}
          key="actions"
          render={(v, r) => (
            <div>
              <Button type="link" icon={<DeleteOutlined />} onClick={() => removeMember(r.id)}></Button>
            </div>
          )}
        />
      </Table>
      <GoalMemberDetail visible={newMemberVisible} onOk={(v) => addMember(v)} onCancel={() => setNewMemberVisible(false)} />
    </div>
  );
};

export default GoalMembers;
