import React, {useEffect, useState} from "react";
import * as A from 'antd'
import {
    LiveProvider,
    // LiveEditor,
    LiveError,
    LivePreview
} from 'react-live'
import {exe} from "../../Lib/Dal";
import {Spin} from "antd";
import DefaultPage from "../Shared/DefaultPage";
import {useTranslation} from "react-i18next";

class LiveViewWrapper extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.code !== this.props.code) {
            return true;
        }
      return false;
    }
  
    render() {
        const {useState,useEffect}=React;
        const {t}=this.props;

        return <LiveProvider code={this.props.code} scope={{useState,A,useEffect,exe,DefaultPage,t,context:this.props.context}}>
             <LiveError />
        <LivePreview />
    </LiveProvider>
    }
  }
   

const View = (props) => {
    const [code,setCode]=useState();
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.match&&props.match.params.id) load(props.match.params.id);else if(props.code) setCode(props.code);
        
    },[props.match&&props.match.params.id,props.code])
    
    const load=id=>{
        exe("RepoLiveView",{operation:"GET",filter:`id=${id}`}).then(r=>{
          if(r.ok){
              const record=r.outData[0];
              setCode(record.code);
          }  
        })
    }

    return code?<LiveViewWrapper t={t} code={code} context={props.context} />:<Spin />


} 




export default View;