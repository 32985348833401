import React, { useState, useEffect } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { useTranslation } from "react-i18next";
import { FilterOutlined } from '@ant-design/icons';
import {Badge, Button, message, Table, Tabs} from "antd";
import {exe, objectToFilter} from "../../Lib/Dal";
import {formatDate, formatMoney} from "../../Lib/Helpers";
import CreditListFilter from "./CreditListFilter";

const CreditList = (props) => {
    const [t, i18n] = useTranslation();
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [filterVisible,setFilterVisible]=useState(false);
    const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
    const [filter,setFilter]=useState();
    
    
    useEffect(()=>load(filter),[filter,pagination.current])
    
    const load=(filter="")=>{
        delete filter.q;
        setLoading(true);
        exe("RepoCredit",{operation:"GET",filter:filter, size: pagination.pageSize, page: pagination.current - 1}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
                setPagination({ ...pagination, total: r.total, current: pagination.current });
            }else message.error(r.msg);
        })
    }
    const onSearch=(values)=>{
        if(values.status) {
            values.active = values.status === "active"?1:0;
            delete values.status;
        }
        console.log(values)
        const sqFilter = objectToFilter(values);
        setFilter(sqFilter);
    }
    
    return (
        <div>
            <DefaultPage
                title={t("Credit List")}
                icon="folder-open" extra={<div><Button type="primary" icon={<FilterOutlined />} onClick={()=>setFilterVisible(true)} >{t("Filter")}</Button> </div>}>
                <Table dataSource={data} rowKey="id" pagination={pagination} onChange={(pag) => setPagination(pag)}>
                    <Table.Column title={t("ID")} dataIndex="id" render={(v,r)=><a href={"#/credit/"+r.id}>{v}</a> } />
                    <Table.Column title={t("Active")} dataIndex="active" render={(v,r)=>v? <Badge status="success" />: <Badge status="error" /> } />
                    <Table.Column title={t("Product")} dataIndex="productCode" />
                    <Table.Column title={t("Code")} dataIndex="code" />
                    <Table.Column title={t("Status")} dataIndex="entityState" />
                    <Table.Column title={t("Contact")} dataIndex="contactName" />
                    <Table.Column title={t("Capital")} dataIndex="capital" render={(v,r)=>formatMoney(v,r.currency)} />
                    <Table.Column title={t("Currency")} dataIndex="currency" />
                    <Table.Column title={t("Start")} dataIndex="start" render={v=>formatDate(v)} />
                    <Table.Column title={t("External Id")} dataIndex="externalId" />
                    <Table.Column title={t("Actions")} key="actions" render={(v,r)=><a href={"#/credit/"+r.id}>{t("Open")}</a> } />
                </Table>
                <CreditListFilter onSearch={onSearch} visible={filterVisible} onClose={()=>setFilterVisible(false)} />
            </DefaultPage>
        </div>
    );
};

export default CreditList;