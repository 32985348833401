import { DeleteOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import ClaimSelect from "../Shared/ClaimSelect";
import ClaimLink from "./ClaimLink";

const RelatedClaims = (props) => {
  const [t] = useTranslation();
  const [newRelationVisible, setNewRelationVisible] = useState(false);
  const [relationship, setRelationship] = useState({ claimId: undefined, relationship: undefined });
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("related", props.jRelated);
    if (props.jRelated) setData(JSON.parse(props.jRelated));
  }, [props.jRelated]);

  const load = (claimId) => {
    setLoading(true);
    exe("RepoClaim", { operation: "GET", filter: "id=" + claimId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(JSON.parse(r.outData[0].jRelated || "[]"));
      } else message.error(r.msg);
    });
  };
  const onNew = () => {
    setNewRelationVisible(true);
    setRelationship({ claimId: undefined, relationship: undefined });
  };
  const onSubmitNewRelation = () => {
    if (props.claimId == relationship.claimId) {
      message.error(t("Related claim cannot be the same as parent claim"));
      return;
    }
    if (!relationship.claimId || !relationship.relationship) {
      message.error(t("Please fill in all fields"));
      return;
    }
    setLoading(true);
    exe("AddClaimRelationship", { claimId: props.claimId, relatedClaimId: relationship.claimId, relationship: relationship.relationship }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        setData([...data, relationship]);
        setNewRelationVisible(false);
      } else message.error(r.msg);
    });
  };
  const onRemoveRelation = (id) => {
    setLoading(true);
    exe("RemoveClaimRelationship", { claimId: props.claimId, relatedClaimId: id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load(props.claimId);
        setNewRelationVisible(false);
      } else message.error(r.msg);
    });
  };
  return (
    <div>
      <Button type="primary" onClick={onNew} icon={<PlusOutlined />}>
        {t("Add")}
      </Button>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => load(props.claimId)}>
        {t("Reload")}
      </Button>
      <Table loading={loading} dataSource={data}>
        <Table.Column title={t("Claim ID")} dataIndex="claimId" render={(v, r) => <ClaimLink claimId={v} />} />
        <Table.Column title={t("Relationship")} dataIndex="relationship" />
        <Table.Column title={t("Actions")} key="actions" render={(v, r) => <Button type="link" icon={<DeleteOutlined />} onClick={() => onRemoveRelation(r.claimId)} />} />
      </Table>
      <Modal title={t("New Relationship")} visible={newRelationVisible} onOk={onSubmitNewRelation} onCancel={() => setNewRelationVisible(false)}>
        <Form.Item label={t("Claim")}>
          <ClaimSelect value={relationship.claimId} onChange={(v) => setRelationship({ ...relationship, claimId: v })} />
        </Form.Item>
        <Form.Item label={t("Relationship")}>
          <Input
            placeholder={t("Please describe relationship")}
            value={relationship.relationship}
            onChange={(v) => setRelationship({ ...relationship, relationship: v.target.value })}
          />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default RelatedClaims;
