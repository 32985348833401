import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";

const InstallmentSchemeSelect = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if(props.value) return;
    const defaultScheme = data.find((p) => p.code == props.defaultScheme);
    if (defaultScheme) props.onChange(defaultScheme.id);
  }, [data, props.defaultScheme]);

  const load = () => {
    setLoading(true);
    getCache("RepoInstallmentScheme").then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };
  const onChange = (v) => {
    props.onChange(v);
    if (props.onSchemeChange) {
      const selected = data.find((p) => p.id == v);
      props.onSchemeChange(selected);
    }
  };
  return (
    <div>
      <Select style={props.style} value={props.value} onChange={onChange} loading={loading} disabled={props.disabled} allowClear placeholder={t("Installment Scheme")}>
        {data.map((p) => (
          <Select.Option value={p.id} disabled={!p.active} key={p.id}>
            {p.name} ({p.code})
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default InstallmentSchemeSelect;
