import {
    DeleteOutlined,
    EnterOutlined,
    FileOutlined,
    ReloadOutlined,
    SaveOutlined,
    UndoOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
    Badge,
    Button,
    Checkbox,
    DatePicker,
    Input,
    InputNumber,
    message,
    Popconfirm,
    Select,
    Switch,
    Table,
} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {exe, get, safeGet} from "../../Lib/Dal";
import moment from "moment";
import Section from "../Shared/Section";
import {formatDate, getColor} from "../../Lib/Helpers";
import Status from "../Shared/Status";

const ClosureDetail = (props) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [closing,setClosing]=useState(false);

    useEffect(() => {
        if (props.selected) {
            props.form.setFieldsValue(props.selected);
        } else {
            onNew();
        }
    }, [props.selected]);
    
    const onNew = () => {
        props.form.resetFields();
    }
    const save = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            setLoading(true);
            const operation= values.id ? "UPDATE" : "ADD";
            exe("RepoClosure", {operation:operation, entity: values}).then((r) => {
                setLoading(false);
                if (r.ok) {
                    message.success(r.msg);
                    props.form.setFieldsValue(r.outData[0]);
                    if(operation==="ADD"){
                        props.onAdd && props.onAdd(r.outData[0]);
                    }else{
                        props.onEdit&&props.onEdit(r.outData[0]);
                    }
                } else {
                    message.error(r.msg);
                }
            });
        });
    };
    const onDelete = (id) => {
        exe("RepoClosure", {operation: "DELETE", entity: {id: id}}).then((r) => {
            if (r.ok) {
                message.success(r.msg);
                props.onDelete&&props.onDelete(id);
            } else {
                message.error(r.msg);
            }
        });
    };
    const onUndo = (id) => {
        exe("UndoClosure", {closureId:id}).then((r) => {
            if (r.ok) {
                message.success(r.msg);
                reload();
            } else {
                message.error(r.msg);
            }
        });
    };
    const reload = () => {
        if (!values.id) return;
        setLoading(true);
        exe("RepoClosure", {operation: "GET", filter: `id=${values.id}`, include: ["Transactions"]}).then(r => {
            setLoading(false);
            if (r.ok) {
                props.form.setFieldsValue(r.outData[0]);
            } else message.error(r.msg);
        })
    }
   
    const onOpenTransaction=(r)=>{
        console.log(r)
    }
    const onClose=()=>{
        setClosing(true);
        exe("ClosePeriod",{year:values.year,month:values.month}).then(r=>{
            setClosing(false);
            if(r.ok)
            {
                message.success(r.msg);
                props.onClose&&props.onClose();
            }else message.error(r.msg);
        })
    }
    
    const field = props.form.getFieldDecorator;
    const values = props.form.getFieldsValue();
    return (
        <div>
            <Button type="link" icon={<ReloadOutlined />} onClick={() => reload()}>
                {t("Reload")}
            </Button>
            <Button type="link" icon={<FileOutlined />} onClick={() => onNew()}>
                {t("New")}
            </Button>
            <Button type="link" icon={<SaveOutlined />} onClick={() => save()} loading={loading}>
                {t("Save")}
            </Button>
            <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={() => onDelete(values.id)}
                        disabled={values.status || !values.id}>
                <Button type="link" icon={<DeleteOutlined />} disabled={values.status || !values.id}>
                    {t("Delete")}
                </Button>
            </Popconfirm>
            <Popconfirm title={t("Are you sure you want to undo this record and its transactions?")} onConfirm={() => onUndo(values.id)}
                        disabled={!values.status || !values.id}>
                <Button type="link" icon={<UndoOutlined />} disabled={!values.status || !values.id}>
                    {t("Undo")}
                </Button>
            </Popconfirm>
            <Section text={t("Main")}>
                <Form style={{marginBottom: 20}}>
                    {field("id")}
                    {field("status")}
                    <div style={{display:"flex"}}>
                        <Form.Item label={t("Year")} style={{marginRight:5}} >
                            {field("year",{rules:[{required:true}],initialValue:new Date().getFullYear() })(<InputNumber min={1}/>)}
                        </Form.Item>
                        <Form.Item label={t("Month")} >
                            {field("month",{rules:[{required:true}],initialValue:new Date().getMonth()+1 })(<InputNumber min={1}/>)}
                        </Form.Item>
                    </div>
                </Form>
                <Button type="primary" icon={<EnterOutlined />} disabled={!values.id||values.status===1} onClick={onClose} loading={closing}>{t("Close")}</Button>
                {field("processId")}
                {field("Process")}
              
            </Section>
            <Section text={t("Closure Transactions")}>
                {field("Transactions")}
            <Table dataSource={values.Transactions||[]} loading={loading}>
                <Table.Column title={t("ID")} dataIndex="id" />
                <Table.Column title={t("Created")} dataIndex="date" render={(v) => formatDate(v)} />
                <Table.Column title={t("Code")} dataIndex="code" />
                <Table.Column title={t("Ref")} dataIndex="reference" />
                <Table.Column title={t("Operation")} dataIndex="operationCode" />
                <Table.Column title={t("Description")} dataIndex="description" />
                <Table.Column title={t("Amount")} dataIndex="amount" />
                <Table.Column
                    title={t("Status")}
                    dataIndex="status"
                    render={(v) =>
                        v ? (
                            <span>
                      <Badge status="success" />
                                {t("Posted")}
                    </span>
                        ) : (
                            <span>
                      <Badge status="error" />
                                {t("Draft")}
                    </span>
                        )
                    }
                />
                <Table.Column title={t("Manual")} dataIndex="manual" render={v=><Checkbox checked={v} /> } />
                {/*<Table.Column*/}
                {/*    title={t("Actions")}*/}
                {/*    key="actions"*/}
                {/*    render={(v, r) => (*/}
                {/*        <div>*/}
                {/*            <Button type="link" icon="folder-open" onClick={() => onOpenTransaction(r)}>*/}
                {/*                {t("Open")}*/}
                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*/>*/}
            </Table>
            </Section>
        </div>
    );
};

export default Form.create()(ClosureDetail);