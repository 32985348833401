import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, message } from 'antd';
import { exe } from '../../Lib/Dal';

const ProjectOpen = ({ visible, onCancel, onOpen, onOk }) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadProjects();
  }, [visible]);

  const loadProjects = async () => {
    setLoading(true);
    exe("RepoProject",{operation:"GET"}).then(r=>{
        setLoading(false);
        if(r.ok){
            setProjects(r.outData);
        }else{
            message.error(r.msg);
        }
    })
   
  };


  const handleProjectSelect = (project) => {
    onOpen(project);

  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button type="link" onClick={() => handleProjectSelect(record)}>Select</Button>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Available Projects"
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        width={800}
      >
        <Table
          dataSource={projects}
          columns={columns}
          rowKey="id"
          loading={loading}
        />
      </Modal>
    </>
  );
}

export default ProjectOpen;
