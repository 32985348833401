import React, {useEffect} from 'react';
import { LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, InputNumber, Row, Tabs } from "antd";
import {useTranslation} from "react-i18next";
import FormLabel from "../Shared/FormLabel";
import ReceiptTypeSelect from "./ReceiptTypeSelect";
import FiscalDocsChanges from "./FiscalDocsChanges";
import DatePickerW from "../Shared/DatePickerW";

const FiscalDocForm = (props) => {
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.selected&&props.selected.id) props.form.setFieldsValue(props.selected);
        if(props.selected&&!props.selected.id) props.form.resetFields();
    },[props.selected])
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onSave(values);
        })
    }
    
    const field=props.form.getFieldDecorator;
    //const values=props.form.getFieldsValue();
    return (
        <div>
            <Button icon={<LeftOutlined />} type={"link"} onClick={props.onCancel}>{t("Back")}</Button>
            <Tabs>
                <Tabs.TabPane tab={t("Record")} key={"record"}>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label={t("Record Name")}>{field("name",{rules:[{required:true}]})(<Input />)}</Form.Item>   
                            <Form.Item label={t("Receipt Type")}>{field("receiptTypeCode",{rules:[{required:true}]})(<ReceiptTypeSelect />)}</Form.Item>   
                            <Form.Item label={t("Active")}>{field("active",{valuePropName:"checked",initialValue:true})(<Checkbox disabled />)}</Form.Item>   
                            <Form.Item label={t("Official Request Number")}>{field("requestNumber",{rules:[{required:true}]})(<InputNumber min={0} />)}</Form.Item>   
                            <Form.Item label={t("Expiration")}>{field("expires",{rules:[{required:false}]})(<DatePickerW  />)}</Form.Item>   
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t("Numbering Start")}>{field("numStart",{rules:[{required:true}]})(<InputNumber min={0} />)}</Form.Item>   
                            <Form.Item label={t("Numbering End")}>{field("numEnd",{rules:[{required:true}]})(<InputNumber min={0} />)}</Form.Item>   
                            <Form.Item label={t("Numbering Last")}>{field("numLast",{rules:[{required:false}]})(<InputNumber min={0} disabled />)}</Form.Item>   
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t("ID")}>{field("id",)(<FormLabel disabled />)}</Form.Item>   
                            <Form.Item label={t("User")}>{field("user",)(<FormLabel disabled />)}</Form.Item>   
                            <Form.Item label={t("Created")}>{field("created",)(<FormLabel type={"Date"} disabled />)}</Form.Item>   
                            
                        </Col>
                    </Row>        
                    <Button type="primary" icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
                    
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("Changes")} key={"changes"}>
                    <FiscalDocsChanges fiscalDoc={props.selected} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};

export default Form.create()(FiscalDocForm);