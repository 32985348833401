import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message, notification } from "antd";
import { post, put, exe } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";

class EstadoDetalle extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    //mode edicion
    if (this.props.record && this.props.record !== prevProps.record) {
      //form validator hace un update con cada cambio
      this.props.form.setFieldsValue(this.props.record);
      return;
    }
    //modo nuevo registro
    if (this.props.new && this.props.new !== prevProps.new) {
      //form validator hace un update con cada cambio. Con esto solo entra la 1a vez
      this.props.form.resetFields();
    }
  }

  handleOk = (e) => {
    //e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.props.new) {
          exe("RepoUsrGroup", { operation: "ADD", entity: values }).then((r) => {
            if (r.ok) {
              message.success(this.props.t("Group added"));
              this.props.afterAdd();
            } else {
              notification.error({
                message: this.props.t("Error"),
                description: r.msg,
              });
            }
          });
        } else {
          const dto = { ...this.props.record, ...values };
          exe("RepoUsrGroup", { operation: "UPDATE", entity: dto }).then((r) => {
            if (r.ok) {
              message.success(this.props.t("OK"));
              this.props.afterAdd();
            } else {
              notification.error({
                message: this.props.t("Error"),
                description: r.msg,
              });
            }
          });
        }
      }
    });
  };

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    return (
      <Modal title={this.props.new ? t("New Group") : t("Edit Group")} visible={this.props.visible} onOk={this.handleOk} onCancel={this.props.onClose}>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item {...formItemLayout} label={t("Group Name")}>
            {getFieldDecorator("name", {
              rules: [{ required: true, message: t("Please insert group name") }],
            })(<Input />)}
          </Form.Item>

          {/* <Form.Item {...formItemLayout} label="Id Interno">
            {getFieldDecorator("idInterno", {
              rules: [
                { required: true, message: "Por favor ingrese id interno" }
              ]
            })(<Input />)}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Id Externo">
            {getFieldDecorator("idExterno", {
              rules: [
                { required: true, message: "Por favor ingrese id externo" }
              ]
            })(<Input />)}
          </Form.Item>

          {this.props.tipoMaestro=="estadosiniestro"&&(<Form.Item {...formItemLayout} label="% Avance">
            {getFieldDecorator("pAvance", {
              rules: [
                { required: true, message: "Por favor ingrese % avance" }
              ]
            })(<Input />)}
          </Form.Item>)} */}
        </Form>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(EstadoDetalle);
export default withTranslation()(WrappedForm);
