import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  DatePicker,
  Tag,
  Switch,
  Checkbox,
  InputNumber,
} from "antd";
import { exe } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";
import moment from "moment";
import Compare from "../Shared/Compare";
import ProductSelect from "../Life/ProductSelect";
import ComContractSelect from "./ComContractSelect";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import DatePickerW from "../Shared/DatePickerW";

const ComFilter = (props) => {
  const [matches, setMatches] = useState(null);
  const [loading,setLoading]=useState(false);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (props.filter) {
      props.form.setFieldsValue(props.filter);
    }
    if (window.location.hash.includes("?date=")) {
      const date = window.location.hash.split("?date=")[1];
      props.form.setFieldsValue({ date: moment(date) });
      submit();
    } else if (window.location.hash.includes("?policyId=")) {
      const policy = window.location.hash.split("?policyId=")[1];
      props.form.setFieldsValue({ policyId: policy });
      submit();
    } else {
      submit();
    }
  }, []);
  
  useEffect(()=>{
    if(props.reload) submit();
  },[props.reload])

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      console.log(values, "FORM VALUES");
      let queries = [];
      if (values.date)
        queries.push(`YEAR(created)=${values.date.format("YYYY")} AND MONTH(created)=${values.date.format("M")} AND DAY(created)=${values.date.format("D")}`);
      if (values.month) queries.push(`YEAR(created)=${values.month.format("YYYY")} AND MONTH(created)=${values.month.format("M")}`);
      if (values.contract !== undefined) queries.push(`comContractId=${values.contract}`);
      if (values.type !== undefined) queries.push(`type='${values.type}'`);
      if (values.lob !== undefined) queries.push(`id in (SELECT id FROM commission WHERE lifepolicyId in (SELECT id from lifepolicy WHERE lob='${values.lob}'))`);
      if (values.masterCode !== undefined) queries.push(`masterCode='${values.masterCode}'`);
      if (values.product !== undefined) queries.push(`productCode='${values.product.key}'`);
      if (values.version !== undefined) queries.push(`version=${values.version}`);
      if (values.ofnGroup !== undefined) queries.push(`ofnGroup='${values.ofnGroup}'`);
      if (values.ofnCode !== undefined) queries.push(`ofnCode='${values.ofnCode}'`);
      if (values.commission !== undefined) queries.push(`commission${values.commission}`);
      if (values.policyId !== undefined) queries.push(`lifePolicyId=${values.policyId}`);
      if (values.currency !== undefined) queries.push(`currency='${values.currency}'`);
      if (values.comLiquidationId !== undefined) queries.push(`comLiquidationId=${values.comLiquidationId}`);
      if (values.allocationId !== undefined) queries.push(`allocationId=${values.allocationId}`);
      if (values.pending) queries.push(`comLiquidationId IS NULL`);
      if (values.range) {
        queries.push(`created between '${values.range[0].format("YYYY-MM-DD")}' and  '${values.range[1].format("YYYY-MM-DD")}'`);
      }
      if (values.sellerId !== undefined) queries.push(`sellerId=${values.sellerId}`);
      if (values.certificate !== undefined)
        queries.push(`id in (SELECT id FROM commission WHERE lifepolicyId in (SELECT id from lifepolicy WHERE certificate=${values.certificate}))`);

      const filterQuery = queries.join(" AND ");
      console.log(queries, filterQuery);

      props.setLoading && props.setLoading(true);
      setLoading(true);
      exe("RepoCommission", { operation: "GET", filter: filterQuery, groupBy:values.groupBy }).then((r) => {
        props.setLoading && props.setLoading(false);
        setLoading(false);
        console.log(r);
        props.onResults(r.outData);
        setMatches(r.total);
      });
    });
  };

  return (
    <div>
      <Drawer title={t("Commission Filter")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Specific Day")}>{getFieldDecorator("date")(<DatePickerW />)}</Form.Item>
            <Form.Item label={t("Month")} style={{ marginLeft: 5 }}>
              {getFieldDecorator("month")(<DatePicker.MonthPicker />)}
            </Form.Item>
          </div>
          <Form.Item label={t("Date Range")}>{getFieldDecorator("range")(<DatePicker.RangePicker />)}</Form.Item>
          <Form.Item label={t("Contract")}>{getFieldDecorator("contract")(<ComContractSelect />)}</Form.Item>
          <Form.Item label={t("Type")}>{getFieldDecorator("type")(<Input placeholder="Commission type" />)}</Form.Item>
          <Form.Item label={t("Seller")}>{getFieldDecorator("sellerId")(<SimpleContactSelect />)}</Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("LoB")} style={{ width: "100%" }}>
              {getFieldDecorator("lob")(<LobSelect />)}
            </Form.Item>
            <Form.Item label={t("Product")} style={{ marginLeft: 5, width: "100%" }}>
              {getFieldDecorator("product")(<ProductSelect />)}
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Master Product")}>{getFieldDecorator("masterCode")(<Input />)}</Form.Item>
            <Form.Item label={t("Version")} style={{ marginLeft: 5 }}>
              {getFieldDecorator("version")(<InputNumber />)}
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("OFN Group")}>{getFieldDecorator("ofnGroup")(<Input />)}</Form.Item>
            <Form.Item label={t("OFN Code")} style={{ marginLeft: 5 }}>
              {getFieldDecorator("ofnCode")(<Input />)}
            </Form.Item>
          </div>
          <Form.Item label={t("Commission Amount")}>{getFieldDecorator("commission")(<Compare />)}</Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Policy ID")} style={{ width: "100%" }}>
              {getFieldDecorator("policyId")(<InputNumber style={{ width: "100%" }} />)}
            </Form.Item>
            <Form.Item label={t("Certificate")} style={{ marginLeft: 5, width: "100%" }}>
              {getFieldDecorator("certificate")(<InputNumber style={{ width: "100%" }} />)}
            </Form.Item>
            <Form.Item label={t("Currency")} style={{ marginLeft: 5, width: "100%" }}>
              {getFieldDecorator("currency")(<Currency />)}
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Liquidation ID")} style={{ width: "100%" }}>
              {getFieldDecorator("comLiquidationId")(<InputNumber style={{ width: "100%" }} />)}
            </Form.Item>
            <Form.Item label={t("Allocation ID")} style={{ marginLeft: 5,width: "100%" }}>
              {getFieldDecorator("allocationId")(<InputNumber style={{ width: "100%"  }} />)}
            </Form.Item>
            <Form.Item label={t("Pending Liquidation")} style={{ marginLeft: 5,width: "100%" }}>{getFieldDecorator("pending", { valuePropName: "checked" })(<Switch   />)}</Form.Item>
          </div>
          <Form.Item label={t("Group By")}>
            {getFieldDecorator("groupBy")(
                <Select style={{width:"100%"}} allowClear>
              <Select.Option value={"groupPolicyId"}>{t("Group Policy")}</Select.Option>
                <Select.Option value={"sellerId"}>{t("Seller")}</Select.Option>
                <Select.Option value={"type"}>{t("Type")}</Select.Option>
                <Select.Option value={"date"}>{t("Day")}</Select.Option>
                <Select.Option value={"month"}>{t("Month")}</Select.Option>
                <Select.Option value={"comContractId"}>{t("Contract")}</Select.Option>
                <Select.Option value={"covCode"}>{t("Coverage")}</Select.Option>
                <Select.Option value={"lob"}>{t("LoB")}</Select.Option>
                <Select.Option value={"productCode"}>{t("Product")}</Select.Option>
                <Select.Option value={"masterCode"}>{t("Master Product")}</Select.Option>
                <Select.Option value={"version"}>{t("Product Version")}</Select.Option>
                <Select.Option value={"ofnGroup"}>{t("OFN Group")}</Select.Option>
                <Select.Option value={"ofnCode"}>{t("OFN Code")}</Select.Option>
                <Select.Option value={"currency"}>{t("Currency")}</Select.Option>
                <Select.Option value={"allocationId"}>{t("Allocation")}</Select.Option>
                <Select.Option value={"comLiquidationId"}>{t("Liquidation")}</Select.Option>
            </Select>)}
          </Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()} loading={loading}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(ComFilter);
