import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber, message, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import Money from "../Shared/Money";
import ValueAddedSelect from "./ValueAddedSelect";
import {round2} from "../../Lib/Helpers";

const TaxField = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [detailVisible,setDetailVisible]=useState(false);

  const detail=props.detail||[];
  const taxes=detail.filter(p=>p.taxType===props.taxType)||[];
  const otherTaxes=detail.filter(p=>p.taxType!==props.taxType)||[];
  const grossAmount=props.grossAmount||0;
  
  useEffect(()=>{
if(props.grossAmount&&taxes.length>0&&taxes.some(p=>p.grossAmount!==props.grossAmount)){
    const newTaxes=taxes.map(p=>({...p,grossAmount:props.grossAmount,amount:round2(p.rate*props.grossAmount)}));
    const newTotal=newTaxes.reduce((p,c)=>p+c.amount,0);
    props.onChange(newTotal);
    props.onDetailChange([...otherTaxes,...newTaxes]);
}
  },[props.grossAmount])
 
  const onAddTax=(record)=>{
    if(taxes.find(p=>p.valueAddedCode===record.code)){
      message.error(t("Tax already included"));
      return;
    }
    record.amount=round2(record.rate*grossAmount);
    record.grossAmount=grossAmount;
    record.valueAddedCode=record.code;
    record.taxType=record.taxType;
    record.taxName=record.name;
    
    const newTaxes=[...taxes,record]; 
    const newTotal=newTaxes.reduce((p,c)=>p+c.amount,0);
    props.onChange(newTotal);
    props.onDetailChange([...otherTaxes,...newTaxes]);
    
  }
  const onRemoveTax=code=>{
    const newTaxes=taxes.filter(p=>p.code!==code);
    const newTotal=newTaxes.reduce((p,c)=>p+c.amount,0);
    props.onChange(newTotal);
    props.onDetailChange([...otherTaxes,...newTaxes]);
  }
  const onSubmit=()=>{
      setDetailVisible(false)
  }
  const onChangeAmount=(record,newValue)=>{
    record.amount=newValue;

    const newTaxes=[...taxes];
    const newTotal=newTaxes.reduce((p,c)=>p+c.amount,0);
    props.onChange(newTotal);
    props.onDetailChange([...otherTaxes,...newTaxes]);
  }
  return (
    <div style={{display:"flex"}}>
        <Money value={props.value} currency={props.currency} disabled />
        <Button type="link" icon={<PlusCircleOutlined />} onClick={()=>setDetailVisible(true)} disabled={!grossAmount} />

      <Modal title={t("Detail")} visible={detailVisible} onCancel={()=>setDetailVisible(false)} onOk={onSubmit}>
        <ValueAddedSelect onSelect={onAddTax} taxType={props.taxType} />
        <Table style={{marginTop:5}} dataSource={taxes} pagination={false} size={"small"} rowKey={"code"}>
          <Table.Column title={t("Code")} dataIndex="valueAddedCode" />
          <Table.Column title={t("Name")} dataIndex="taxName" />
          <Table.Column title={t("Rate")} dataIndex="rate" />
          <Table.Column title={t("Amount")} dataIndex="amount" render={(v,r)=>r.taxType==="DED"?<InputNumber value={v} onChange={value=>onChangeAmount(r,value)} />:v} />
          <Table.Column title={t("Actions")} key="actions" render={(v,r)=><div>
            <Button type="link" icon={<DeleteOutlined />} onClick={()=>onRemoveTax(r.code)} /> 
          </div>} />
        </Table>
      </Modal>
    </div>
  );
};

export default TaxField;