import { DeleteOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, Input, InputNumber, Radio, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import RiskTypeSelect from "./RiskTypeSelect";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const LoadingDetail = (props) => {
  const [t] = useTranslation();

  useEffect(() => {
    if (props.value) {
        const formValues = props.value;
        if (formValues.perMille) formValues.loadingPerMille = formValues.loading * 10;
        props.form.setFieldsValue(formValues);
    }
  }, [props.value]);

  const save = () => {
    props.form.validateFields((err, values) => {
      console.log(values,err);
      if (err) return;
      values.manual = true;
      if(values.perMille)values.loading=values.loadingPerMille/10;
      props.onChange(values);
      props.form.resetFields();
      props.onBack();
    });
  };
  const remove = () => {
    props.form.setFieldsValue({ loading: 0 });
    props.onChange(props.form.getFieldsValue());
    props.form.resetFields();
    props.onBack();
  };
  const onChangeSumInsuredBased=(e)=>{
      props.form.setFieldsValue({perMille:e.target.checked}); //sum insured based uses per-mille by default
  }
  const onChangeDuration=v=>{
    //changing the end date when duration changes
    props.form.setFieldsValue({end:moment(values.start).clone().add(v, 'years')});

  }
  const field = props.form.getFieldDecorator;
  const values=props.form.getFieldsValue();
  return (
    <div>
      <Button type="link" icon={<LeftOutlined />} onClick={() => props.onBack()}>
        {t("Back")}
      </Button>
      <Button type="link" icon={<SaveOutlined />} onClick={() => save()}>
        {t("Save Loading")}
      </Button>
      <Button type="link" icon={<DeleteOutlined />} onClick={() => remove()}>
        {t("Remove Loading")}
      </Button>
      {field("id", { initialValue: 0 })}
      {field("tempId", { initialValue: 0 })}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t("Risk")}>{field("riskType", {})(<RiskTypeSelect />)}</Form.Item>
          <Form.Item label={t("Duration Years")}>
            {field("duration", {
              initialValue: props.duration,
              // rules: [
              //   {
              //     validator: (rule, value, callback) => {
              //       if (value && value > props.duration) {
              //         callback(t("Duration must be less than policy duration"));
              //       } else {
              //         callback();
              //       }
              //     },
              //   },
              // ],
            })(<InputNumber min={0} onChange={onChangeDuration} />)}
          </Form.Item>
          <Form.Item label={t("Start")}>{field("start", { 
            initialValue: props.coverage.start?moment(props.coverage.start):moment(), 
            rules: [
              { 
                validator: (rule, value, callback) => { 
                  if (value && value.isBefore(props.coverage.start, 'day')) { 
                    callback(t('Selected date must be after coverage start date'));
                  } else { 
                    callback(); 
                  } 
                }
              },
              {
                validator: (rule, value, callback) => {
                  if (value && value.isAfter(values.end, 'day')) {
                    callback(t('Selected date must be before end date'));
                  } else {
                    callback();
                  }
                }
              }
            ] 
          })(<DatePickerW min={moment()} />)}</Form.Item>
          <Form.Item label={t("End")}>{field("end", { initialValue: props.coverage.end?props.coverage.end:moment(values.start).clone().add(values.duration, 'years')})(<DatePickerW disabled />)}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("Loading %")} style={{ display: values.perMille ? "none" : "block" }}>{field("loading", { rules: [{ required: !values.perMille }] })(<InputNumber step={10} f />)}</Form.Item>
          <Form.Item label={<span>{t("Loading")} ‰</span>} style={{ display: values.perMille ? "block" : "none" }}>{field("loadingPerMille", { rules: [{ required: values.perMille }] })(<InputNumber step={10} f />)}</Form.Item>
          <Form.Item label={t("Manual")}>{field("manual", { initialValue: true, valuePropName: "checked" })(<Checkbox disabled />)}</Form.Item>
          <Form.Item label={t("Insured sum based")}>{field("insuredSumBased", { valuePropName: "checked" })(<Checkbox onChange={onChangeSumInsuredBased} />)}</Form.Item>
          <Form.Item label={t("Per-mille")}>{field("perMille", { valuePropName: "checked" })(<Checkbox />)}</Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label={t("Notes")}>{field("notes", {})(<Input.TextArea />)}</Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Form.create()(LoadingDetail);
