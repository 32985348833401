import { get } from "./Dal";
import { Global } from "./Global";
import moment from "moment";
import { useRef, useEffect } from "react";
import React from "react";

export const isUserInRole = (user, role) => {
  return get("/Account/UserRol?RolId=" + role);
};
export const getUserInfo = () => {
  return get("/Account/User");
};
export const getAge = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const exeTable = (data, arg = {}) => {
  try {
    const mode = data[1][0];
    const titleRow = data[0];
    const varRow = data[1];
    let varValues = [];
    let outValues = [];
    let result = {};
    let results = [];
    //computings var values
    for (let col = 1; col < varRow.length; col++) {
      if (titleRow[col] == "Condition") {
        varValues.push({ name: varRow[col], col: col, value: window.eval(varRow[col]) });
      } else if (titleRow[col] == "Action") {
        outValues.push({ name: varRow[col], col: col, value: null });
      }
    }
    //intable variables
    let v = varValues; //shortcut, intable use
    console.log("Variables", varValues, outValues);
    //computing rules
    for (let row = 2; row < data.length; row++) {
      if (isNaN(data[row][0])) break; //end of rules
      let next = false;
      for (let column = 1; column < varValues.length + 1; column++) {
        let left = varValues[column - 1].value;
        let right = data[row][column];
        if (typeof left == "string") left = "'" + left + "'==";
        if (right == null || right == "") {
          left = true;
          right = true;
        }
        const myExpr = left + right;
        console.log("Testing:", myExpr, "row:", row, "col", column);
        if (!window.eval(myExpr)) {
          //next rule
          console.log("FALSE");
          next = true;
          break;
        } else {
          console.log("TRUE");
        }
      }
      if (!next) {
        //matching rule
        result.match = true;
        result.row = row;
        result.rule = +data[row][0];
        result.in = {};
        result.conditions = {};
        result.out = {};
        //outputs
        outValues.forEach((element) => {
          result.out[element.name] = data[row][element.col];
        });
        varValues.forEach((element) => {
          result.in[element.name] = element.value;
          result.conditions[element.name] = data[row][element.col];
        });

        results.push({ ...result });

        if (mode == "U") break;
      }
      console.log("NEXT ROW", row);
    }
    if (!result.match) {
      result.match = false;
      result.row = 0;
      result.rule = 0;
      result.in = {};
      varValues.forEach((element) => {
        result.in[element.name] = element.value;
      });
      results.push({ ...result });
    }

    console.log(results.length > 1 ? results : result);
    return results;
  } catch (error) {
    console.error(error);
    return [{ error: error.message }];
  }
};

export const getViewState = (arg = {}) => {
  console.log(Global.loaded, "CARGADO?");
  const viewStateTable = Global.tables.find((p) => p.name == "viewstatef"); //temp
  const ret = exeTable(JSON.parse(viewStateTable.data), arg);
  const viewState = ret[0].match ? ret[0].out.view : "none";
  return viewState;
};
export const getCurrencyDecimals=currency=>{
  const formatter = new Intl.NumberFormat(localStorage.isoCode || "en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  });
  const parts = formatter.formatToParts(0.123);
  const decimalPart = parts.find(part => part.type === 'decimal');
  const fractionDigits = parts.find(part => part.type === 'fraction')
  return decimalPart ? fractionDigits.value.length : 0;
}


//export const currencyDecimals=global.configProfile&&global.configProfile.Main.currencyDecimals||0; //default 2 decimals
export const currencyFormatter = (currency = "USD") => {
  if (!currency || currency == null) currency = "USD"; //null not considered empty...
  const currencyDecimals=getCurrencyDecimals(currency);
    try {
      return new Intl.NumberFormat(localStorage.isoCode || "en-US", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: currencyDecimals,
      });
    } catch (error) {
      console.error(error);
      //using default if currency not found
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: currencyDecimals,
      })
    }
}
export const formatMoney = (v, currency = "USD",withStyle=false) =>{
  ///getting only the first 3 chars
  currency=(currency||"USD").substring(0,3);
  if(withStyle){
    return <span style={{whiteSpace:"nowrap",color:v<0?"red":"unset"}}>{currencyFormatter(currency).format(v)}</span>
  }else return currencyFormatter(currency).format(v);
} 
  
  
export const formatNumber = (v) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
  }).format(v);
};
export const hasCurrencyDecimals=(currencyCode, locale = 'en-US')=> {
  // Create a number formatter for the given currency and locale
  const formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode
  });
  
  // Format a number that would show decimals if they exist
  const formatted = formatter.format(1.01);
  
  // Check if the formatted string includes a decimal point or similar character
  const hasDecimals = formatted.match(/[.,]/);
  
  return !!hasDecimals;
}

export const formatPercent = (num) => num * 100 + "%";
export const formatPercentRound2 = (num) => round2(num * 100) + "%";
export const formatDate = (dateString) => moment.utc(dateString).local().format("MMMM Do YYYY, h:mm:ss a");
export const formatDateUtc = (dateString) => moment.utc(dateString).format("MMMM Do YYYY, h:mm:ss a");
export const formatDateShort = (dateString) => moment.utc(dateString).local().format("YYYY-MM-DD");
export const formatDateShortUtc = (dateString) => moment.utc(dateString).format("YYYY-MM-DD");
export const formatDateFromNow = (dateString) => moment.utc(dateString).fromNow();
export const setDate = (dateString) => moment.utc(dateString).local();
export const getColor = (entityState) => {
  switch (entityState) {
    case "DRAFT":
    case "OPEN":
      return "blue";
      break;
    case "ACTIVE":
    case "APROVED":
      return "green";
      break;
    case "INACTIVE":
    case "REJECTED":
      return "red";
    default:
      return undefined;
      break;
  }
};

export const round2 = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * @param {Function} effect
 * @param {Array<any>} dependencies
 */
export default function useUpdateEffect(effect, dependencies = []) {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      effect();
    }
  }, dependencies);
}

export function useIsFirstRender() {
  const isFirst = useRef(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}

export const getModalPremium = (frequency, annualPremium) => {
  if (!frequency || !annualPremium) return 0;

  const period = frequency.substring(0, 1);
  let newModalPremium;

  if (period == "d") {
    const days = frequency.length == 1 ? 1 : frequency.substring(1);
    newModalPremium = round2(annualPremium / (365 / days));
  }
  if (period == "w") {
    const weeks = frequency.length == 1 ? 1 : frequency.substring(1);
    newModalPremium = round2(annualPremium / (52 / weeks));
  }
  if (period == "m") {
    const months = frequency.length == 1 ? 1 : frequency.substring(1);
    newModalPremium = round2(annualPremium / (12 / months));
  }
  if (period == "q") {
    const months = frequency.length == 1 ? 3 : frequency.substring(1);
    newModalPremium = round2(annualPremium / (12 / months));
  }
  if (period == "s") {
    const months = frequency.length == 1 ? 6 : frequency.substring(1);
    newModalPremium = round2(annualPremium / (12 / months));
  }
  if (period == "y") {
    const years = frequency.length == 1 ? 1 : frequency.substring(1);
    newModalPremium = round2(annualPremium / (1 / years));
  }
  return newModalPremium;
};
export const getConfigProfile = () =>
  new Promise((resolve, reject) => {
    const checkExists = () => {
      console.log("Checking variable");
      if (window.global.configProfile) return resolve(window.global.configProfile);
      setTimeout(checkExists, 500);
    };
    checkExists();
  });
export function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}
//expected min 3 char string: VISIBLE|EDITABLE|REQUIRED|[VALUE]
export const parseFieldConfigString = (inputConfigString) => {
  const fieldConfigString = "" + inputConfigString; //string conversion
  const fieldConfig = {
    visible: false,
    editable: false,
    required: false,
    value: undefined,
  };
  if (fieldConfigString && fieldConfigString.length >= 3) {
    fieldConfig.visible = fieldConfigString.charAt(0) == "1";
    fieldConfig.editable = fieldConfigString.charAt(1) == "1";
    fieldConfig.required = fieldConfigString.charAt(2) == "1";
    fieldConfig.value = fieldConfigString.length>3?fieldConfigString.slice(3):undefined;
  }
  return fieldConfig;
};

//computes formula with a provided context
export const computeFormula=(formula, context = {})=> {
  try {
    formula = formula.replaceAll("_", "context.");
    console.log(formula, context, "CONTEXT");
    return eval(formula);
  } catch (error) {
    console.log("Unable to evaluate formula client side", error);
    return undefined;
  }
}
export const setMarginRight=(value,direction)=>{
  return direction=="rtl"?{marginLeft:value}:{marginRight:value}
}
export const setMarginLeft=(value,direction)=>{
  return direction=="rtl"?{marginRight:value}:{marginLeft:value}
}
export const setPaddingLeft=(value,direction)=>{
  return direction=="rtl"?{paddingRight:value}:{paddingLeft:value}
}
export const setPaddingRight=(value,direction)=>{
  return direction=="rtl"?{paddingRight:value}:{paddingLeft:value}
}
//encrypt 
const str2ab = str => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
} 
const importKey = async (pem, opt) => {
    const binaryDerString = window.atob(pem);
    const binaryDer = str2ab(binaryDerString);
    return await window.crypto.subtle.importKey(
        "spki",
        binaryDer,
        {
            name: "RSA-OAEP",
            hash: "SHA-256"
        },
        true,
        opt
    );
}
const encryptMessage = async (key, msg) => {
    return await window.crypto.subtle.encrypt(
        {
            name: "RSA-OAEP"
        },
        key,
        str2ab(msg)
    )
}
 
function arrayBufferToBase64String(arrayBuffer) {
    var byteArray = new Uint8Array(arrayBuffer)
    var byteString = ''
    for (var i=0; i<byteArray.byteLength; i++) {
      byteString += String.fromCharCode(byteArray[i])
    }
    return btoa(byteString)
}
export const encryptText=async (text)=> {
  try{
    const publicKey = await importKey('MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAklkWV2HWycTzdrxPf6x696UKpS/4xdu0qo6VMX6fOlrC9vx7UvgsqkreaV48XP8P0XE5+L3p538zBHFJVQ2imKburK3qWHunbqE8BKHdHJ5OvGb3cgbcSpi98PxEYDu4mqyQnQWolvuvOmXeWIz5CrM1HyX9M+NAIsJKCS12KiQBDE9w7DVUw/oJJyLousVFnX5802zRRo3UcDFI/EDiI+yU7wLMpz2NT4zuxyVPEa9cL58U1n63CNaLy55z2WUrKXBPaYFqQgA5APNmWc6Ip5fC7lKd5DII0ZmSJxFhKPLfNOj3lvQPmsgb+j4zEys2gg3LD0hfYHa75mAoq9HRhQIDAQAB', ["encrypt"]);
    const msgEncrypted = await encryptMessage(publicKey,  text);
    return arrayBufferToBase64String(msgEncrypted);
}
catch(e){
    console.log(e)
}
}
 
