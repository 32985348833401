import React, { useState, useEffect } from "react";
import { Select, Tooltip, Divider } from "antd";
import {exe, safeGet, safeGetRaw} from "Lib/Dal";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";
import queryString from "query-string";
import {getConfigProfile} from "../../Lib/Helpers";

//used inside forms = props = fn onChange, string value
const LobSelect = (props) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [filter,setFilter]=useState();

  useEffect(() => {
    if (data.length == 0) load();
  }, []);
  
  useEffect(() => {
    if(props.filterLobBySection){
      getConfigProfile().then((profile) => {
        const filterLobBySection=safeGetRaw(["Main","filterLobBySection"],profile,false);
        if(filterLobBySection){
          const qs = queryString.parse(window.location.hash);
          if (qs.section) setFilter(qs.section.toUpperCase());else setFilter();
        }else setFilter();
      });
    }else setFilter();
  }, [window.location.hash]);
  
  useEffect(()=>{
    if(props.filterLobBySection&&props.excludedLobs){
      if (filter) {
        props.excludedLobs(data.filter((f) => f.productType.toUpperCase() !== filter).map((m) => m.code));
      } else {
        props.excludedLobs([]);
      }
    }
  },[data.length,filter])
  
  const load = () => {
    getCache("RepoLob").then((r) => {
      setData(r.outData || []);
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Select
        style={{ width: "100%" }}
        onChange={props.onChange}
        value={props.value}
        showSearch
        allowClear
        showArrow={true}
        placeholder={t("Please select LoB")}>
        {data.filter(f=>filter?f.productType.toUpperCase()===filter:true).map((d) => (
          <Select.Option key={d.code} value={d.code}>
            {t(d.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default LobSelect;
