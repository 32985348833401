import { FileOutlined, SaveOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Breadcrumb,
    Button,
    Col,
    Input,
    InputNumber,
    message,
    notification,
    Row,
    Switch,
    Table,
    Tabs,
} from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FormLabel from "../Shared/FormLabel";
import moment from "moment";
import yaml from "js-yaml";
import {formatDateShort} from "../../Lib/Helpers";
import ComTreeMember from "./ComTreeMember";
import ComTreeMemberChange from "./ComTreeMemberChange";
import DatePickerW from "../Shared/DatePickerW";

const ComTree = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [memberVisible,setMemberVisible]=useState(false);
    const [memberChangeSelected,setMemberChangeSelected]=useState(false);
    const [memberData,setMemberData]=useState([]);

    useEffect(() => {
        if (!props.match.params.comTreeId) return;
        if (props.match.params.comTreeId == 0) {
            props.form.resetFields();
        } else {
            load(props.match.params.comTreeId);
        }
    }, [props.match.params.comTreeId]);

    const load = (id) => {
        setLoading(true);
        exe("RepoComTree", { operation: "GET", filter: `id=${id}` }).then((r) => {
            setLoading(false);
            if (r.ok) {
                props.form.setFieldsValue(r.outData[0]);
            } else {
                message.error(r.msg);
            }
        });
        loadMembers(id);
        
    };
    const onSave = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            const operation = values.id ? "UPDATE" : "ADD";
            setLoading(true);
            exe("RepoComTree", { operation: operation, entity: values }).then((r) => {
                setLoading(false);
                if (r.ok) {
                    message.success(r.msg);
                    if (operation == "ADD") window.location = "#/comTreeList";
                } else {
                    message.error(r.msg);
                }
            });
        });
    };
    const loadMembers=(comTreeId)=>{
        setLoading(true);
        exe("RepoComTreeMember",{operation:"GET",filter:"comTreeId="+comTreeId,include:["Contact","Supervisor"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setMemberData(r.outData);
            }else message.error(r.msg);
        })
    }
    const onAddMember=record=>{
        record.comTreeId=values.id;
        setLoading(true);
        exe("RepoComTreeMember",{operation:"ADD",entity:record}).then(r=>{
            setLoading(false);
            if(r.ok){
                loadMembers(record.comTreeId);
                setMemberVisible(false);
            }else message.error(r.msg);
        })
    }
    const onDeleteMember=id=>{
        setLoading(true);
        exe("RepoComTreeMember",{operation:"DELETE",entity: {id:id}}).then(r=>{
            setLoading(false);
            if(r.ok){
                loadMembers(values.id);
            }else message.error(r.msg);
        })
    }
    const onChangeMember=values=>{
        values.memberId=memberChangeSelected.id;
        setLoading(true);
        exe("ChangeComTreeMember",values).then(r=>{
            setLoading(false);
            if(r.ok){
                loadMembers(values.comTreeId);
                setMemberChangeSelected(undefined);
            }else message.error(r.msg);
        })
    }
    const onNew = () => {
        window.location.hash = "#/comTree/0";
    };
    const field = props.form.getFieldDecorator;
    const values=props.form.getFieldsValue();

    return (
        <DefaultPage
            title={
                <span>
          {field("name")(<FormLabel />)} {t("Definition")}
        </span>
            }
            icon="gold"
            routes={{
                routes: [
                    { path: "/", breadcrumbName: t("Home") },
                    { path: "comTreeList", breadcrumbName: t("Multi Level Schemes") },
                    { path: "", breadcrumbName: t("Scheme Definition") },
                ],
            }}
            extra={
                <div>
                    <Button type="primary" onClick={onSave} icon={<SaveOutlined />} loading={loading} style={{ marginRight: 5 }}>
                        {t("Save")}
                    </Button>
                    <Button onClick={onNew} icon={<FileOutlined />}>
                        {t("New")}
                    </Button>
                </div>
            }>
            <Tabs>
                <Tabs.TabPane tab={t("Main")} key="main">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item label={t("Code")}>{field("code",{ rules: [{ required: true }] } )(<Input />)}</Form.Item>
                            <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
                        </Col>
                        <Col span={8}>
                            <div style={{ display: "flex" }}>
                                <Form.Item label={t("Start")}>
                                    {field("start", { rules: [{ required: true }], normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}
                                </Form.Item>
                                <Form.Item label={t("End")}>
                                    {field("end",{ rules: [{ required: false }], normalize: (v) => (v ? moment(v) : undefined) } )(<DatePickerW style={{ marginLeft: 5 }} />)}
                                </Form.Item>
                            </div>
                          
                        </Col>
                        <Col span={8}>
                            <Form.Item label={t("ID")}>{field("id")(<FormLabel />)}</Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("Members")} key="members" disabled={props.match.params.comTreeId == 0}>
                    <Button type={"link"} icon={<LegacyIcon type={"user-add"} />} onClick={()=>setMemberVisible(true)}>{t("Add Member")}</Button>
                    <Button type={"link"} icon={<LegacyIcon type={"reload"} />} onClick={()=>loadMembers(values.id)}>{t("Reload")}</Button>
                    <Table dataSource={memberData} rowKey={"id"}>
                        <Table.Column title={t("Id")} dataIndex="id" />
                        <Table.Column title={t("Position")} dataIndex="position" />
                        <Table.Column title={t("Tier")} dataIndex="tier" />
                        <Table.Column title={t("Name")} dataIndex={["Contact","FullName"]} />
                        <Table.Column title={t("Supervisor")} dataIndex={["Supervisor","position"]} />
                        <Table.Column title={t("Percentage")} dataIndex="percentage" />
                        <Table.Column title={t("Start")} dataIndex="start" render={v=>formatDateShort(v)} />
                        <Table.Column title={t("End")} dataIndex="end" render={v=>v?moment(v).isBefore(moment())?<span style={{color:"red"}}>{formatDateShort(v)}</span>:formatDateShort(v):null} />
                        <Table.Column title={t("Changed")} dataIndex="changeDate" render={v=>v?formatDateShort(v):"-"} />
                        <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div>
                            {/*<Button type={"link"} icon={"close"} onClick={()=>onDeleteMember(r.id)} disabled={r.end&&moment(r.end).isBefore(moment())} />*/}
                            <Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setMemberChangeSelected(r)} />
                        </div>} />
                    </Table>
                    <ComTreeMember visible={memberVisible} onCancel={()=>setMemberVisible(false)} onOk={r=>onAddMember(r)} comTreeId={values.id} />
                    <ComTreeMemberChange selected={memberChangeSelected} visible={memberChangeSelected} onCancel={()=>setMemberChangeSelected(undefined)} onOk={r=>onChangeMember(r)} comTreeId={values.id} />
                </Tabs.TabPane>
             
            </Tabs>
        </DefaultPage>
    );
};

export default Form.create()(ComTree);
