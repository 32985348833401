import {Empty, message, Select, Spin, Tag, Tooltip} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeGetRaw } from "../../Lib/Dal";
import { getConfigProfile } from "../../Lib/Helpers";

const AccountSelectDetail = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetching,setFetching]=useState(false);
  const [t] = useTranslation();
  const [showParentLevelsInCode, setShowParentLevelsInCode] = useState(true);

  useEffect(() => {
    getConfigProfile().then(profile=>{
      setShowParentLevelsInCode(safeGetRaw(["Accounting","showParentLevelsInCode"],profile,true));
    })}, []);

  useEffect(()=>{
    if(props.value&&props.accountRecord){
      setData([props.accountRecord]) //so that name is also displayed
    }
  },[props.value])


  const onSearch = (value) => {
    if (!value) return;
    setFetching(true);
    const filter = `code LIKE '${value}%'`;
    exe("RepoCatalogAccount", {operation:"GET",filter: filter,size:10}).then((r) => {
      setFetching(false);
      if(r.ok) setData(r.outData);else setData([]);
    });
  };
  
  const onSelect = (v, opt) => {
    if(props.detailOnly){
      if(!opt.props.record.isDetail){
        props.onChange&&props.onChange(undefined);
        message.error(t("Not a detail account"))
        return;
      }
    }
    props.onSelect && props.onSelect(opt.props.record);
  };
  const renderCode=(code)=>{
    if(showParentLevelsInCode){
      return code;
    }else{
      //getting the separator, which is the first non numeric character
      const separator=code.match(/\D/);
      // returning the last part of the code, after the separator
      if(separator){
        return code.substr(code.lastIndexOf(separator)+1);
      }else return code;
    }
  }
  return (
    <Select
      value={props.value}
      onChange={(v) => props.onChange(v)}
      onSearch={onSearch}
      onSelect={onSelect}
      loading={loading}
      showSearch
      notFoundContent={fetching ? <Spin size="small" /> : <Empty description={t("Please type account code to search...")} />}
      style={{ width: "100%", ...props.style }}
      placeholder={t("Please type account code to search...")}
      disabled={props.disabled}
      allowClear>
      {data.map((p) => (
        <Select.Option value={p.code} key={p.code} record={p}>
          <Tooltip title={`${p.code} ${p.name}`}><Tag>{renderCode(p.code)}</Tag> {p.name}</Tooltip>
        </Select.Option>
      ))}
    </Select>
  );
};

export default AccountSelectDetail;