import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { message, Modal } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import Currency from "../Shared/Currency";

const NewCurrentAccount = (props) => {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [contactId, setContactId] = useState();
  const [currency, setCurrency] = useState();

  const newCurrentAccount = () => {
    if (!contactId) {
      message.error(t("Please select a contact"));
      return;
    }
    setLoading(true);
    exe("NewCurrentAccount", { contractId: props.contractId, contactId: contactId, currency: currency }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.onOk && props.onOk();
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Modal title={t("New Account")} visible={props.visible} onCancel={() => props.onCancel && props.onCancel()} onOk={() => newCurrentAccount()}>
        <Form.Item label={t("Reinsurer")}>
          <SimpleContactSelect value={contactId} onChange={(v) => setContactId(v)} />
        </Form.Item>
        <Form.Item label={t("Currency")}>
          <Currency value={currency} onChange={(v) => setCurrency(v)} style={{ width: "100%" }} />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default NewCurrentAccount;
