import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const CoinsuranceSelect = (props) => {
  const [t] = useTranslation();
  return (
    <div>
      <Select value={props.value} onChange={(v) => props.onChange(v)}>
        <Select.Option value={0}>{t("None")}</Select.Option>
        <Select.Option value={1}>{t("Leader")}</Select.Option>
        <Select.Option value={2}>{t("Minoritary")}</Select.Option>
        <Select.Option value={3}>{t("Automatic Contract")}</Select.Option>
        <Select.Option value={4}>{t("User-Defined Contract")}</Select.Option>
      </Select>
    </div>
  );
};

export default CoinsuranceSelect;
