import React, { useState, useEffect } from "react";
import { CheckOutlined, CloseOutlined, CopyOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Divider, message, Modal, Table, Tag, Tooltip } from "antd";
import { exe } from "../../Lib/Dal";
import { formatDate } from "../../Lib/Helpers";
import AuditLogFilter from "./AuditLogFilter";
import Inspector from "react-inspector";
import { useTranslation } from "react-i18next";

const AuditLog = (props) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });

  //   useEffect(() => {
  //     getData();
  //   }, [pagination.current, props.refresh]);

  //   const getData = () => {
  //     setLoading(true);
  //     exe("GetAuditLog", { size: pagination.pageSize, page: pagination.current - 1 }).then((r) => {
  //       setLoading(false);
  //       setData(r.outData);
  //       setPagination({ ...pagination, total: r.total });
  //     });
  //   };
  const onPaginationChange = (currentPagination) => {
    setPagination(currentPagination);
  };

  const onOpenDto = (record) => {
    Modal[record.ok ? "success" : "error"]({
      title: record.cmd,
      maskClosable: true,
      width: "60%",
      zIndex: 2000,
      content: (
        <div>
          <Inspector data={JSON.parse(record.dto)} expandLevel={1} />
        </div>
      ),
      onOk() {},
    });
  };
  const onFilteredResults = (filteredData, totalResults) => {
    setData(filteredData);
    setPagination({ ...pagination, total: totalResults });
  };
  function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);
    } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
  const onCopyDto = (record) => {
    const dtoObj = JSON.parse(record.dto);
    const dtoCopied = { cmd: dtoObj.cmd, data: dtoObj.data };
    console.log(dtoCopied);
    copyToClipboard(JSON.stringify(dtoCopied));
    message.success(t("Command copied to clipboard"));
  };
  return (
    <div>
      <Table dataSource={data} loading={loading} pagination={pagination} onChange={(pag) => onPaginationChange(pag)}>
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
        <Table.Column title={t("Command")} dataIndex="cmd" render={(v) => <Tag>{v}</Tag>} />
        <Table.Column title={t("Result")} dataIndex="ok" render={(v) => (v ? <CheckOutlined /> : <CloseOutlined style={{ color: "red" }} />)} />
        <Table.Column title={t("Message")} dataIndex="msg" />
        <Table.Column
          title={t("Dto")}
          render={(v, r) => (
            <span>
              <Tooltip title={t("Open DTO")}>
                <Button type="link" icon={<EyeOutlined />} onClick={() => onOpenDto(r)}></Button>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Copy command to clipboard")}>
                <Button type="link" icon={<CopyOutlined />} onClick={() => onCopyDto(r)}></Button>
              </Tooltip>
            </span>
          )}
        />
        <Table.Column title={t("Exe Time")} dataIndex="texe" />
        <Table.Column title={t("User")} dataIndex="user" />
      </Table>
      <AuditLogFilter
        visible={props.filterVisible}
        onClose={() => props.onCloseFilter()}
        onResults={(data, total) => onFilteredResults(data, total)}
        pagination={pagination}
        refresh={props.refresh}
      />
    </div>
  );
};

export default AuditLog;
