import React, { useEffect, useState } from "react";

import {
  ApartmentOutlined,
  DollarOutlined,
  GiftOutlined,
  HeartOutlined,
  MedicineBoxOutlined,
  PhoneOutlined,
  StarOutlined,
  UserOutlined,
  WarningOutlined,
} from '@ant-design/icons';

import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Tree } from "antd";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";

const Related = (props) => {
  const [t] = useTranslation();

  const [data, setData] = useState({
    Claims: [],
    PoliciesAsHolder: [],
    PoliciesAsInsured: [],
    PoliciesAsBeneficiary: [],
    Relationships: [],
    Payments: [],
    ClaimsAsClaimer: [],
  });
  useEffect(() => {
    if (props.policyId) load(props.policyId);
  }, [props.policyId]);

  const load = (policyId) => {
    exe("GetRelated", { policyId: policyId }).then((r) => {
      setData(r.outData);
    });
  };
  return (
    <div>
      <Tree showLine={true} selectable={false} showIcon defaultExpandedKeys={["0-0", "0-1"]}>
        <Tree.TreeNode icon={<StarOutlined />} title={t("Related to this policy")} key="0">
          {/* ********CLAIMS************ */}
          <Tree.TreeNode icon={<MedicineBoxOutlined />} title={t("Claims")} key="0-0">
            {data.Claims.map((p) => (
              <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-0-" + p.id} />
            ))}
          </Tree.TreeNode>
          {/* ********HOLDER************ */}
          <Tree.TreeNode icon={<UserOutlined />} title={t("Holder")} key="0-1">
            <Tree.TreeNode icon={<HeartOutlined />} title={t("Policies as holder")} key="0-1-0">
              {data.PoliciesAsHolder.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-1-0-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<HeartOutlined />} title={t("Policies as insured")} key="0-1-1">
              {data.PoliciesAsInsured.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-1-1-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<HeartOutlined />} title={t("Policies as beneficiary")} key="0-1-2">
              {data.PoliciesAsBeneficiary.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-1-2-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<ApartmentOutlined />} title={t("Relationships")} key="0-1-3">
              {data.Relationships.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-1-3-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<DollarOutlined />} title={t("Payments")} key="0-1-4">
              {data.Payments.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-1-4-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<WarningOutlined />} title={t("Claims as claimer")} key="0-1-5">
              {data.ClaimsAsClaimer.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-1-5-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<WarningOutlined />} title={t("Claims as insured")} key="0-1-6" />
            <Tree.TreeNode icon={<WarningOutlined />} title={t("Claims as beneficiary")} key="0-1-7" />
            <Tree.TreeNode icon={<GiftOutlined />} title={t("Proposals")} key="0-1-8" />
            <Tree.TreeNode icon={<PhoneOutlined />} title={t("Contacts")} key="0-1-9" />
          </Tree.TreeNode>

          {/* ********INSURED************ */}
          <Tree.TreeNode icon={<UserOutlined />} title={t("Holder")} key="0-2">
            <Tree.TreeNode icon={<HeartOutlined />} title={t("Policies as holder")} key="0-2-0">
              {data.PoliciesAsHolder.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-2-0-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<HeartOutlined />} title={t("Policies as insured")} key="0-2-1">
              {data.PoliciesAsInsured.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-2-1-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<HeartOutlined />} title={t("Policies as beneficiary")} key="0-2-2">
              {data.PoliciesAsBeneficiary.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-2-2-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<ApartmentOutlined />} title={t("Relationships")} key="0-2-3">
              {data.Relationships.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-2-3-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<DollarOutlined />} title={t("Payments")} key="0-2-4">
              {data.Payments.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-2-4-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<WarningOutlined />} title={t("Claims as claimer")} key="0-2-5">
              {data.ClaimsAsClaimer.map((p) => (
                <Tree.TreeNode icon={<LegacyIcon type={p.icon} />} title={<a href={p.link}>{p.title}</a>} key={"0-2-5-" + p.id} />
              ))}
            </Tree.TreeNode>
            <Tree.TreeNode icon={<WarningOutlined />} title={t("Claims as insured")} key="0-2-6" />
            <Tree.TreeNode icon={<WarningOutlined />} title={t("Claims as beneficiary")} key="0-2-7" />
            <Tree.TreeNode icon={<GiftOutlined />} title={t("Proposals")} key="0-2-8" />
            <Tree.TreeNode icon={<PhoneOutlined />} title={t("Contacts")} key="0-2-9" />
          </Tree.TreeNode>
        </Tree.TreeNode>
      </Tree>
    </div>
  );
};

export default Related;
