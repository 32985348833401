import React from "react";
import {
  DownloadOutlined,
  DownOutlined,
  FileExcelOutlined,
  FileTextOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { Table, Divider, Empty, Button, message, Dropdown, Menu } from "antd";
import { formatter } from "../Shared/Money";
import Column from "antd/lib/table/Column";
import { formatDateShort, formatDateShortUtc, formatDateUtc, formatMoney, round2 } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";
import AccountMovFilter from "./AccountMovFilter";
import { Excel } from "antd-table-saveas-excel";
import { Parser } from "json2csv";

import { useState } from "react";
import { useEffect } from "react";

const AccountMov = (props) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({ value: 0, units: 0, units_af: 0 });

  useEffect(() => {
    if (props.value) {
      setData(props.value.sort((a, b) => new Date(a.date) - new Date(b.date)));
      updateTotals(props.value);
    }
  }, [props.value]);

  const currency = props.currency;

  const onSearch = (values) => {
    console.log(values);
    let filteredData = props.value.sort((a, b) => new Date(a.date) - new Date(b.date));

    //amount filter
    if (values.amount) {
      switch (values.amount.charAt(0)) {
        case ">":
          filteredData = filteredData.filter((p) => p.amount > +values.amount.slice(1));
          break;
        case "<":
          filteredData = filteredData.filter((p) => p.amount < +values.amount.slice(1));
          break;
        case "=":
          filteredData = filteredData.filter((p) => p.amount == +values.amount.slice(1));
          break;
      }
    }
    //date filter
    if (values.date) {
      filteredData = filteredData.filter((p) => values.date[0].isBefore(p.date) && values.date[1].isAfter(p.date));
    }

    setData(filteredData);
    updateTotals(filteredData);
  };

  const updateTotals = (movs) => {
    setTotals({
      value: movs.reduce((a, c) => a + c.amount, 0),
      units: movs.reduce((a, c) => a + c.units, 0),
      units_af: movs.reduce((a, c) => a + c.units_af, 0),
    });
  };
  const onExport = (data, fileName) => {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    download(data, fileName, "text/json");
  };

  const exportCsv = () => {
    getDataWithoutPagination().then((r) => {
      if (r.ok) {
        const exportData = r.outData;
        const fields = Object.keys(exportData[0]);
        const parser = new Parser({ fields });
        const csv = parser.parse(exportData);
        onExport(csv, "movements.csv");
      } else message.error(r.msg);
    });
  };
  const exportXlsx = () => {
    getDataWithoutPagination().then((r) => {
      if (r.ok) {
        const exportData = r.outData;
        const fields = Object.keys(exportData[0]);
        const excel = new Excel();
        excel
          .addSheet("Sheet1")
          .addColumns(fields.map((p) => ({ title: p, dataIndex: p }))) //antd column format
          .addDataSource(exportData)
          .saveAs("movements.xlsx");
      } else message.error(r.msg);
    });
  };
  const getDataWithoutPagination = () =>
    new Promise((resolve, reject) => {
      resolve({ ok: true, outData: data || [] }); //no pagination for now
    });

  const Download = (props) => (
    <Dropdown
      overlay={
        <Menu>
          <Menu.Item>
            <Button type="link" icon={<FileTextOutlined />} onClick={exportCsv}>
              {t("Csv")}
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button type="link" icon={<FileExcelOutlined />} onClick={exportXlsx}>
              {t("Excel")}
            </Button>
          </Menu.Item>
        </Menu>
      }>
      <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
        {t("Download")}
        <DownOutlined />
      </Button>
    </Dropdown>
  );

  if (props.account && props.account.type == "DAILY") {
    return (
      <div>
        {!props.noFilter && (
          <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        )}
        <Download />
        <Table
          dataSource={props.account.PendingMovements}
          footer={(d) => (
            <span>
              <b>{t("Current Balance")}:</b>
              {formatter(currency).format(props.account.currentAmountBalance)}
            </span>
          )}>
          <Column title={t("Date")} dataIndex="date" render={(v) => formatDateShortUtc(v)}></Column>
          <Column title={t("Transaction Code")} dataIndex="transactionCode"></Column>
          <Column title={t("Transaction")} dataIndex="transaction"></Column>
          <Column title={t("Amount")} dataIndex="amount"></Column>
          <Column title={t("Amount AF")} dataIndex="amount_af"></Column>
          <Column title={t("Year-Month")} dataIndex="yearMonth"></Column>
        </Table>
        <AccountMovFilter visible={filterVisible} onSearch={onSearch} onClose={() => setFilterVisible(false)} />
      </div>
    );
  }

  if (props.account && ["INI", "DIS", "TOP"].includes(props.account.type)) {
    return (
      <div>
        {!props.noFilter && (
          <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        )}
        <Download />
        <Table
          dataSource={data}
          footer={(d) => (
            <span>
              <b>{t("Total Units")}:</b>
              {totals.units.toFixed(5)}
              <Divider type="vertical" />
              <b>{t("Total AF Units")}:</b>
              {totals.units_af.toFixed(5)}
              <Divider type="vertical" />
            </span>
          )}>
          <Column title={t("Effective Date")} dataIndex="date" render={(v) => formatDateShortUtc(v)}></Column>
          <Column title={t("Bid")} dataIndex="bid"></Column>
          <Column title={t("Ask")} dataIndex="ask"></Column>
          <Column title={t("Code")} dataIndex="transactionCode"></Column>
          <Column title={t("Transaction")} dataIndex="transaction"></Column>
          <Column title={t("Funding Factor")} dataIndex="fundingFactor" render={(v) => v.toFixed(5)}></Column>
          <Column title={t("Amount")} dataIndex="amount" render={(v) => round2(v)}></Column>
          <Column title={t("Units")} dataIndex="units" render={(v) => v.toFixed(5)}></Column>
          <Column title={t("Amount AF")} dataIndex="amount_af" render={(v) => round2(v)}></Column>
          <Column title={t("Units AF")} dataIndex="units_af" render={(v) => v.toFixed(5)}></Column>
          <Column title={t("Value")} dataIndex="amountBalance" render={(v) => formatMoney(v, currency)}></Column>
          <Column title={t("Units Balance")} dataIndex="unitBalance" render={(v) => v.toFixed(5)}></Column>
        </Table>
        <AccountMovFilter visible={filterVisible} onSearch={onSearch} onClose={() => setFilterVisible(false)} />
      </div>
    );
  }

  return (
    <div>
      {!props.noFilter && (
        <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
          {t("Filter")}
        </Button>
      )}
      <Download />
      <Table
        dataSource={data}
        footer={(d) => (
          <span>
            <b style={{marginLeft:5,marginRight:3}}>{t("Current Balance")}:</b>
            {formatter(currency).format(props.account ? props.account.currentAmountBalance : 0)}
            <b style={{marginLeft:5,marginRight:3}}>{t("Available Balance")}:</b>
            {formatter(currency).format(props.account ? props.account.currentAmountAvailableBalance : 0)}
          </span>
        )}>
        <Column title={t("Effective Date")} dataIndex="date" render={(v) => formatDateUtc(v)}></Column>
        <Column title={t("Transaction Code")} dataIndex="transactionCode"></Column>
        <Column title={t("Transaction")} dataIndex="transaction"></Column>
        <Column title={t("Amount")} dataIndex="amount" render={(v) => formatMoney(v, currency)}></Column>
      </Table>
      <AccountMovFilter visible={filterVisible} onSearch={onSearch} onClose={() => setFilterVisible(false)} />
    </div>
  );
};

export default AccountMov;
