import React,{useEffect, useState} from 'react';
import {Checkbox, Form, Input, InputNumber, Modal, message} from "antd";
import {useTranslation} from "react-i18next";
import { exe, safeGetRaw } from '../../Lib/Dal';

const NewPolicyVersion = (props) => {
    const [loading, setLoading] = useState(false);
    const [t]=useTranslation();
    const [form] = Form.useForm();

    useEffect(() => {
        if(props.visible)
            form.setFieldsValue({policyVersion:props.policy.policyVersion+1,code:props.policy.code});
    }, [props.visible]);
    
    const onOk = () => {
      form
          .validateFields()
          .then((values) => {
            values.policyId=props.policy.id;
            setLoading(true);
            exe("AddPolicyVersion",values).then((r)=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg)
                    props.onOk(r.outData.newPolicyVersion);
                }else message.error(r.msg);
            }
          )})
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
    }
    
    return (
        <Modal title={t("New Policy Version")} visible={props.visible} onOk={onOk} onCancel={props.onCancel} okButtonProps={{loading:loading}}>
            <Form layout={"vertical"} form={form}>
              <Form.Item label={t("Version")} name={"policyVersion"} ><InputNumber min={1}/></Form.Item>
              <Form.Item label={t("Code")} name={"code"} ><Input/></Form.Item>
              <div style={{display:safeGetRaw(["policy","policyType"],props,"I")=="G"?"block":"none"}}>
                <Form.Item label={t("Include Certificates")} name={"includeCertificates"} valuePropName='checked' ><Checkbox/></Form.Item>
                <Form.Item label={t("Skip Inactive Certificates")} name={"skipInactiveCertificates"} valuePropName='checked'><Checkbox/></Form.Item>
                <Form.Item label={t("No Workflow For Certificates")} name={"skipCertificateWorkflow"} valuePropName='checked' ><Checkbox /></Form.Item>
              </div>
            </Form>
        </Modal>
    );
};

export default NewPolicyVersion