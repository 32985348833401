import React, { Component } from "react";
import { LineChartOutlined, SaveOutlined, ThunderboltOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Tabs, Dropdown, Menu, message } from "antd";
import { exe, safeGet } from "../../Lib/Dal";
import ButtonGroup from "antd/lib/button/button-group";
import ProjectionParameters from "../Life/ProjectionParameters";
import ProjectionGraph from "../Life/ProjectionGraph";
import { formatter } from "../Shared/Money";
import { timeYear } from "d3";
import { withTranslation } from "react-i18next";
const { Column, ColumnGroup } = Table;

class PensionProjection extends Component {
  state = {
    data_scenario1: [],
    tabNum: 1,
    activeKey: "scenario1",
    graphVisible: false,
    loading: false,
    config: {},
  };
  componentDidMount() {
    this.money = formatter(this.props.currency || "USD");
    if (this.props.projections) this.loadSavedProjections(this.props.projections);
    if (this.props.productCode) this.loadProduct(this.props.productCode);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.currency !== prevProps.currency) {
      this.money = formatter(this.props.currency);
    }
    if (this.props.projections !== prevProps.projections) {
      if (this.props.projections) this.loadSavedProjections(this.props.projections);
    }
    if (this.props.productCode !== prevProps.productCode) {
      if (this.props.productCode) this.loadProduct(this.props.productCode);
    }
  }
  loadProduct(productCode){
    exe("RepoPensionProduct", {operation:"GET",filter:`code='${productCode}'`}).then((r) => {
      if(r.ok){
        if(r.total>0){
            this.setState({config:JSON.parse(r.outData[0].configJson)});
        }else {
            message.error(this.props.t("Product not found"));
            }}
    });
  }
  loadSavedProjections(projectionsString) {
    const projections = JSON.parse(projectionsString);
    const state = { tabNum: projections.length };
    projections.forEach((p) => {
      state["data_scenario" + p.index] = p.data;
      state["columns_scenario" + p.index] = p.columns;
      state["name_scenario" + p.index] = p.name;
    });
    console.log("setting new state", state);
    this.setState({ ...this.state, ...state });
  }
  columnsProjection = [
    {
      title: this.props.t("Year"),
      dataIndex: "year",
    },
    {
      title: this.props.t("Age"),
      dataIndex: "age",
    },
    {
      title: this.props.t("Premium"),
      dataIndex: "premium",
      render: (t) => this.money.format(t),
    },
    {
      title: this.props.t("Value"),
      dataIndex: "value",
      render: (t) => this.money.format(t),
    },
    {
      title: this.props.t("Surrender"),
      dataIndex: "surrender",
      render: (t) => this.money.format(t),
    },
    {
      title: this.props.t("Benefit"),
      dataIndex: "benefit",
      render: (t) => this.money.format(t),
    },
  ];

  onGenerate(v) {
    exe("GenerateProjection", { type: 1, ...v }, true).then((r) => {
      this.setState({ ["data_" + this.state.activeKey]: r.outData, ["columns_" + this.state.activeKey]: undefined, paramtersVisible: false });
    });
  }
  onParamtersChange(v) {
    this.onGenerate(v);
  }
  onEdit = (targetKey, action) => {
    console.log(targetKey, action);
    this[action](targetKey);
  };
  add(t) {
    this.setState({ tabNum: this.state.tabNum + 1 });
  }
  remove(targetKey) {
    const newState = {};
    newState["columns_" + targetKey] = undefined;
    newState["data_" + targetKey] = undefined;
    newState["name_" + targetKey] = undefined;
    newState.tabNum = this.state.tabNum - 1;
    newState.activeKey = "scenario1";

    this.setState({ ...this.state, ...newState });
  }
  getColumns(index) {
    const defaultColumns = [
      {
        title: this.props.t("Year"),
        dataIndex: "year",
      },
      {
        title: this.props.t("Age"),
        dataIndex: "age",
      },
      {
        title: this.props.t("Premium"),
        dataIndex: "premium",
        render: (t) => this.money.format(t),
      },
      {
        title: this.props.t("Guaranteed"),
        children: [
          { title: this.props.t("Value"), dataIndex: "value_min", render: (t) => this.money.format(t) },
          { title: this.props.t("Surrender"), dataIndex: "surrender_min", render: (t) => this.money.format(t) },
          { title: this.props.t("Benefit"), dataIndex: "benefit_min", render: (t) => this.money.format(t) },
        ],
      },
      {
        title: this.props.t("Assumed"),
        children: [
          { title: this.props.t("Value"), dataIndex: "value", render: (t) => this.money.format(t) },
          { title: this.props.t("Surrender"), dataIndex: "surrender", render: (t) => this.money.format(t) },
          { title: this.props.t("Benefit"), dataIndex: "benefit", render: (t) => this.money.format(t) },
        ],
      },
    ];
    if (this.state["columns_" + this.state.activeKey]) {
      const actuarialProjectionColumns = this.state["columns_" + this.state.activeKey].map((p) => ({ title: p.title, dataIndex: p.actuarialName }));
      return actuarialProjectionColumns;
    } else {
      return defaultColumns;
    }
  }
  getTabs = (index) => {
    const tabName = this.state["name_scenario" + (+index + 1)] ? this.state["name_scenario" + (+index + 1)] : this.props.t("Scenario") + " " + (+index + 1);
    return (
      <Tabs.TabPane key={"scenario" + (+index + 1)} tab={tabName} closable={index !== 0}>
        <Table
          scroll={{ x: true }}
          bordered
          dataSource={this.state["data_" + "scenario" + (+index + 1)]}
          size="small"
          pagination={false}
          loading={this.state.loading}
          columns={this.getColumns(index)}></Table>
      </Tabs.TabPane>
    );
  };
  onProjectionClick = (item, defaultParameters) => {
      this.generateActuarialProjection(item.key, item.item.props.projection);
  };
  generateActuarialProjection(code, projectionConfig) {
    this.setState({ loading: true });
    exe("GeneratePensionProjection", { pensionId: this.props.pensionId, projectionCode: code,memberId:this.props.memberId }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        //transforming to jsond
        const projectionData = [];
        const titles = r.outData[0];
        for (let index = 1; index < r.outData.length; index++) {
          const element = r.outData[index];
          const line = {};
          for (let j = 0; j < element.length; j++) {
            line[titles[j]] = element[j];
          }
          projectionData.push(line);
        }

        this.setState({
          ["data_" + this.state.activeKey]: projectionData,
          ["columns_" + this.state.activeKey]: projectionConfig.columns || [],
          ["name_" + this.state.activeKey]: projectionConfig.name,
          paramtersVisible: false,
        });
      } else {
        message.error(r.msg);
      }
    });
  }
  generateActuarialChangeProjection(changeId) {
    this.setState({ loading: true });
    exe("GenerateActuarialChangeProjection", { changeId }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        //transforming to jsond
        const projectionData = [];
        const titles = r.outData.data[0];
        for (let index = 1; index < r.outData.data.length; index++) {
          const element = r.outData.data[index];
          const line = {};
          for (let j = 0; j < element.length; j++) {
            line[titles[j]] = element[j];
          }
          projectionData.push(line);
        }
        const projectionConfig = r.outData.jProjection;
        this.setState({
          ["data_" + this.state.activeKey]: projectionData,
          ["columns_" + this.state.activeKey]: projectionConfig.columns || [],
          ["name_" + this.state.activeKey]: projectionConfig.name,
          paramtersVisible: false,
        });
      } else {
        message.error(r.msg);
      }
    });
  }

  onSave = () => {
    const projections = [];
    for (let i = 1; i <= this.state.tabNum; i++) {
      const projectionData = {
        name: this.state["name_scenario" + i],
        index: i,
        data: this.state["data_scenario" + i],
        columns: this.state["columns_scenario" + i],
      };
      projections.push(projectionData);
    }
    const projectionString = JSON.stringify(projections);
    this.setState({ loading: true });
    exe("SavePensionProjections", { pensionId: this.props.pensionId, jProjections: projectionString, memberId:this.props.memberId }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
      } else {
        message.error(r.msg);
      }
    });
  };
  render() {
    const { t, i18n } = this.props;
    let graphData = [
      { id: "Value", data: [] },
      { id: "Surrender", data: [] },
      { id: "Benefit", data: [] },
    ];
    if (this.state.graphVisible) {
      const d = this.state["data_" + this.state.activeKey];
      if (d) {
        graphData[0].data = d.map((p) => ({ x: p.year, y: p.value }));
        graphData[1].data = d.map((p) => ({ x: p.year, y: p.surrender }));
        graphData[2].data = d.map((p) => ({ x: p.year, y: p.benefit }));
      }
    }

    const projections = safeGet(["Projections"], this.state.config, []).filter((p) => p.type == this.props.type);

    return (
      <div style={{ marginTop: 5, marginBottom: 5 }}>
        <Dropdown
          overlay={
            <Menu onClick={(t) => this.onProjectionClick(t)}>
              {projections.map((p) => (
                <Menu.Item key={p.code} projection={p}>
                  {p.name}
                </Menu.Item>
              ))}
            </Menu>
          }>
          <Button type="primary" icon={<ThunderboltOutlined />} style={{ marginBottom: 5 }}>
            {t("Generate")}
          </Button>
        </Dropdown>
        {/* <Button icon={<LineChartOutlined />} type="link" onClick={() => this.setState({ graphVisible: !this.state.graphVisible })}>
          {t("Graph Mode")}
        </Button> */}
        <Button icon={<SaveOutlined />} type="link" onClick={() => this.onSave()}>
          {t("Save")}
        </Button>
        {this.state.graphVisible && (
          <div style={{ width: "100%", height: 600 }}>
            <ProjectionGraph data={graphData} />
          </div>
        )}
        <Tabs
          tabPosition="right"
          type="editable-card"
          onEdit={this.onEdit}
          onChange={(activeKey) => this.setState({ activeKey })}
          activeKey={this.state.activeKey}>
          {[...Array(this.state.tabNum).keys()].map((index) => this.getTabs(index))}
        </Tabs>
       
      </div>
    );
  }
}

export default withTranslation()(PensionProjection);
