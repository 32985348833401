import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Table, Switch, Checkbox, Input, Button, Popconfirm, message } from "antd";
import { exe } from "../../Lib/Dal";
import { formatMoney, formatDateShort, formatDate } from "../../Lib/Helpers";
import Currency from "../Shared/Currency";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
import ReversalDetail from "../Financial/ReversalDetail";

const AllocationTaskList = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const [reversalRecord,setReversalRecord]=useState();

  useEffect(() => load(), [props.refresh]);

  const load = () => {
    exe("RepoAllocation", { operation: "GET", include: ["InstallmentPremiums", "SupplementaryPremiums"] }).then((r) => {
      setData(r.outData);
    });
  };
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${t("Search")} ${dataIndex}`}
          value={selectedKeys && selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
          {t("Search")}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />
    //   ) : (
    //     text
    //   ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end
  const onRevert = (allocation) => {
    setLoading(true);
    exe("UnDoPaymentAllocation", { allocationId: allocation.id,
        reversalCause:allocation.reversalCause, 
        reversalSubcause:allocation.reversalSubcause, 
        jReversalFormValues:allocation.jReversalFormValues,
        creditFundsToAccountId:allocation.creditFundsToAccountId,
        workspaceId:allocation.workspaceId
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success("Allocation reverted");
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
    const onReversalDetail=reversalFormValues=>{
        const allocationToBeReverted={...reversalRecord,...reversalFormValues};
        onRevert(allocationToBeReverted);
        setReversalRecord(undefined);
    }
  return (
    <div>
      <Table
        dataSource={data}
        rowKey="id"
        loading={loading}
        expandedRowRender={(record) => (
          <div style={{ margin: 0 }}>
            <b>{t("Installment Premiums")}:</b>
            <div>
              {record.InstallmentPremiums.map((p) => (
                <div>
                  <span style={{ marginLeft: 5 }}>
                    {t("Ref")}:{p.payPlanId} {t("Amount")}:{p.dueAmount} ({p.moneyInAmount}+{p.transitAmount}+{p.compensationAmount}) {p.currency} {t("Policy")}
                    :<a href={"#/lifePolicy/" + p.lifePolicyId}>{p.lifePolicyId}</a>
                    {p.compensationType && (
                      <span style={{ marginLeft: 5 }}>
                        {t("Compensation Type")}: {p.compensationType} {t("Compensation Amount")}: {p.compensationAmount}
                      </span>
                    )}
                  </span>
                </div>
              ))}
            </div>
            <b>{t("Supplementary Premiums")}:</b>
            <div>
              {record.SupplementaryPremiums.map((p) => (
                <div>
                  <span style={{ marginLeft: 5 }}>
                    {t("Dest")}:{p.destination} {t("Amount")}:{p.moneyInAmount}+{p.transitAmount} {p.currency} {t("Policy")}:
                    <a href={"#/lifePolicy/" + p.lifePolicyId}>{p.lifePolicyId}</a>
                  </span>
                </div>
              ))}
            </div>
            {record.reverted && (
              <div>
                <b style={{ color: "red" }}>{t("Reversion Date")}:</b>
                {formatDate(record.reversalDate)}
              </div>
            )}
          </div>
        )}>
        <Table.Column
          title={t("ID")}
          dataIndex="id"
          render={(v) => (props.mark && props.mark == v ? <Text mark>{v}</Text> : v)}
          {...getColumnSearchProps("id")}
          defaultFilteredValue={props.mark && [props.mark]}
        />
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDateShort(v)} />
        <Table.Column title={t("Automatic")} dataIndex="automatic" render={(v) => <Checkbox checked={v} />} />
        <Table.Column title={t("Currency")} dataIndex="currency" render={(v) => <Currency.Flag currency={v} />} />
        <Table.Column title={t("Transfer")} dataIndex="transferAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("From Transit")} dataIndex="fromTransitAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Compensation")} dataIndex="compensationAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Premium")} dataIndex="premiumAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Supplementary")} dataIndex="supplementaryAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Difference")} dataIndex="differenceAmount" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("User")} dataIndex="user" />
        <Table.Column
          title={t("Actions")}
          key="actions"
          render={(v, r) => (
            <div>
              {r.reverted ? (
                <span style={{ color: "red" }}>Reverted</span>
              ) : (
                  <Button type="link" icon={<UndoOutlined />} onClick={()=>setReversalRecord(r)}>
                    {t("Revert")}
                  </Button>
              )}
            </div>
          )}
        />
      </Table>
        <ReversalDetail visible={reversalRecord} onCancel={()=>setReversalRecord(undefined)} onOk={r=>onReversalDetail(r)} selected={reversalRecord} />
    </div>
  );
};

export default AllocationTaskList;
