import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeGet } from "../Lib/Dal";
import FormLabel from "./Shared/FormLabel";

const License = (props) => {
  const [t] = useTranslation();
  const [license, setLicense] = useState("No license");
  const noLicense = { company: "UNLICENSED", country: "-", type: "-" };
  const [licenseInfo, setLicenseInfo] = useState(noLicense);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.visible) loadInfo();
  }, [props.visible]);

  const loadInfo = () => {
    const license = safeGet(["configProfile", "Main", "license"], props, "-");
    setLicense(license);
    if (license == "-") return;
    setLoading(true);
    exe("CheckLicense", { license: license }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setLicenseInfo(r.outData);
      } else {
        setLicenseInfo(noLicense);
      }
    });
  };
  const getLicenseType = (type) =>
    type == "I"
      ? t("Perpetual Individual License")
      : type == "C"
      ? t("Perpetual Corporate License")
      : type == "R"
      ? t("Perpetual Regional License")
      : type == "G"
      ? t("Perpetual Global License")
      : "UNLICENSED";
  return (
    <div>
      <Modal title={t("About this license")} visible={props.visible} onOk={props.onClose} onCancel={props.onClose} width={800}>
        <Form.Item label={t("Key")}>
          <FormLabel code paragraph value={license} />
        </Form.Item>
        <Form.Item label={t("License Type")}>
          <FormLabel code paragraph value={getLicenseType(licenseInfo.type)} />
        </Form.Item>
        <Form.Item label={t("Company")}>
          <FormLabel code paragraph value={licenseInfo.company} />
        </Form.Item>
        <Form.Item label={t("Region")}>
          <FormLabel code paragraph value={licenseInfo.country} />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default License;
