import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Table, message} from "antd";
import {useTranslation} from "react-i18next";
import {PlusOutlined, SaveOutlined,LeftOutlined,EditOutlined,DeleteOutlined} from "@ant-design/icons";
import { exe } from '../../Lib/Dal';

const CertificateGroups = (props) => {
    const [selected,setSelected]=useState(null);
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [addCoveragesVisible,setAddCoveragesVisible]=useState(false);
    const [t]=useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        if(props.value) setData(JSON.parse(props.value));
    },[props.value])
    
    useEffect(()=>{
        if(selected) form.setFieldsValue(selected);
    },[selected])


    const onSave=()=> {
        form.validateFields().then(values=>{
            
            values.Coverages=selected.Coverages;
            const existingRecord=data.find(x=>x.name===values.name);
            let newData=data;
            //checking if the user changed the name, if so, removing the old record
            if(selected&&selected.name!==values.name){
                newData=data.filter(x=>x.name!==selected.name);
            }
            if(existingRecord){
                newData=newData.map(x=>x.name===values.name?values:x);
            }else{
                newData=newData.concat(values);
            }
            setLoading(true);
            exe("SaveCertificateGroups",{jCertificateGroups:newData,policyId:props.policyId}).then(r=>{
                setLoading(false);
                if(r.ok){
                    setData(newData);
                    setSelected(null);
                    message.success(r.msg);
                    form.resetFields();
                }else{
                    message.error(r.msg);
                }
            });
        });
    }
    const onDelete=(name)=>{
        const newData=data.filter(x=>x.name!==name);
        setLoading(true);
        exe("SaveCertificateGroups",{jCertificateGroups:newData,policyId:props.policyId}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(newData);
                message.success(r.msg);
            }else{
                message.error(r.msg);
            }
        });
    }
    const onDeleteCoverage=(code)=>{
        const newData=selected.Coverages.filter(x=>x.code!==code);
        const newSelected={...selected,Coverages:newData};
        setSelected(newSelected);
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const newSelected={...selected,Coverages:selectedRows};
            setSelected(newSelected);
        },
        selectedRowKeys:selected?(selected.Coverages||[]).map(p=>p.code):null
      };
      const allConfigCoverages=props.config&&props.config.Coverages||[];
      //this is done because datasource is faltted and altered in policy legacy code. Records can not have same code
      //getting a flat list of subcoverage codes
      const subCoverages=allConfigCoverages.flatMap(x=>x.Subcoverages||[]).map(x=>x.code);
      //setting available coverages filtering out subcoverages
      const configCoverages=allConfigCoverages.filter(x=>!subCoverages.includes(x.code));
      //injecting parent code to subcoverages
        if(configCoverages) configCoverages.forEach(x=>x.Subcoverages&&x.Subcoverages.forEach(y=>y.parent=x.code));
    return (
        <Modal title={t("Custom Certificate Groups")} width={800} visible={props.visible} okButtonProps={{disabled:selected}}
        onOk={()=>props.onOk(data)} onCancel={props.onCancel}>
            {selected==null&&<div>
                <Button type={"link"} icon={<PlusOutlined/>} onClick={()=>setSelected({})}>{t("Add")}</Button>
                <Table dataSource={data} pagination={false}>
                    <Table.Column dataIndex={"name"} title={t("Name")}/>
                    <Table.Column dataIndex={"description"} title={t("Description")}/>
                    <Table.Column key="actions" render={(v,r)=><div>
                        <Button type={"link"} icon={<EditOutlined />} onClick={()=>setSelected(r)} />
                        <Button type={"link"} icon={<DeleteOutlined/>} onClick={()=>onDelete(r.name)} />
                    </div>} />
                </Table>
            </div>}
            {selected!==null&&<div>
                {!addCoveragesVisible&&<div>
                <Button type={"link"} icon={<LeftOutlined/>} onClick={()=>setSelected(null)}>{t("Back")}</Button>
                <Button type={"link"} icon={<SaveOutlined/>} onClick={onSave} loading={loading}>{t("Save")}</Button>
                <Form layout={"vertical"} form={form}>
                    <Form.Item label={t("Name")} name={"name"} rules={[{required:true}]}><Input/></Form.Item>
                    <Form.Item label={t("Description")} name={"description"} rules={[{required:false}]}><Input/></Form.Item>
                </Form>
                    <Button type={"link"} icon={<PlusOutlined/>} onClick={()=>setAddCoveragesVisible(true)}>{t("Add Coverages")}</Button>
                    <Table dataSource={selected.Coverages||[]} pagination={false} rowKey={"code"} 
                    expandable={{childrenColumnName:"None"}}>
                        <Table.Column dataIndex={"code"} title={t("Code")}/>
                        <Table.Column dataIndex={"name"} title={t("Coverage")}/>
                        <Table.Column dataIndex={"parent"} title={t("Parent")}/>
                        <Table.Column key="actions" render={(v,r)=><div>
                            <Button type={"link"} icon={<DeleteOutlined/>} onClick={()=>onDeleteCoverage(r.code)} />
                        </div>} />
                    </Table>
                </div>}
                {addCoveragesVisible&&<div>
                    <Button type={"link"} icon={<LeftOutlined/>} onClick={()=>setAddCoveragesVisible(false)}>{t("Back")}</Button>
                    <Table dataSource={configCoverages} pagination={false} rowSelection={rowSelection} 
                    rowKey={"code"} expandable={{childrenColumnName:"Subcoverages"}} >
                        <Table.Column dataIndex={"code"} title={t("Code")}/>
                        <Table.Column dataIndex={"name"} title={t("Coverage")}/>
                        </Table>
                    </div>}
            </div>}
        </Modal>
    );
};

export default CertificateGroups;