import React, { Component } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Input,
  Table,
  message,
  Button,
  Select,
  Radio,
  Row,
  Col,
  InputNumber,
} from "antd";
import { exe } from "Lib/Dal";
import { withTranslation } from "react-i18next";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

class Filter extends Component {
  state = {
    state: [],
  };
  submit() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        let queries = [];
        if (values.name) queries.push(`name like N'%${values.name}%'`);
        if (values.inward !== undefined) queries.push(`inward='${values.inward}'`);
        if (values.code) queries.push(`code='${values.code}'`);
        if (values.id) queries.push(`id=${values.id}`);

        const filterQuery = queries.join(" AND ");
        console.log(queries, filterQuery);

        exe("GetContracts", { include: ["Tags", "Contact"], filter: filterQuery, tagFilterAny: values.tagsAny, tagFilterAll: values.tagsAll }).then((r) => {
          console.log(r);
          this.props.onSearch(r.outData);
        });
      }
    });
  }
  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Drawer title={t("Contract Filter Search")} placement="right" width={512} onClose={this.props.onClose} visible={this.props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => this.props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Form.Item label={t("Name")}>{getFieldDecorator("name")(<Input />)}</Form.Item>
          <Form.Item label={t("Code")}>{getFieldDecorator("code")(<Input />)}</Form.Item>
          <Form.Item label={t("ID")}>{getFieldDecorator("id")(<InputNumber />)}</Form.Item>
          <Form.Item label={t("Direction")}>
            {getFieldDecorator("inward")(
              <Radio.Group name="radiogroup" type="">
                <Radio.Button value={true}>{t("Inward")}</Radio.Button>
                <Radio.Button value={false}>{t("Outward")}</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
          <Form.Item label={t("Tags (Any)")}>
            {getFieldDecorator(
              "tagsAny",
              {}
            )(<Select mode="tags" placeholder={t("Insert comma separated tags")} style={{ width: "100%", marginBottom: 5 }} tokenSeparators={[","]}></Select>)}
          </Form.Item>
          <Form.Item label={t("Tags (All)")}>
            {getFieldDecorator(
              "tagsAll",
              {}
            )(<Select mode="tags" placeholder={t("Insert comma separated tags")} style={{ width: "100%", marginBottom: 5 }} tokenSeparators={[","]}></Select>)}
          </Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => this.submit()}>
          {t("Search")}
        </Button>
      </Drawer>
    );
  }
}
const WrappedForm = Form.create()(Filter);
export default withTranslation()(WrappedForm);
