import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const ReceiptTypeSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("RepoReceiptTypeCatalog", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const enabledItems=props.enabledItems&&props.enabledItems.receiptTypes||[];
  return (
    <div>
      <Select
          style={{width:"100%"}}
          showArrow
          value={props.value}
          onChange={props.onChange}
          onSelect={props.onSelect&&props.onSelect}
          disabled={props.disabled}
          loading={loading}
          allowClear
          showSearch
          optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code} record={p} disabled={enabledItems.length>0&&!enabledItems.includes(p.code)}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ReceiptTypeSelect;
