import React, { useEffect } from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Modal } from "antd";
import { useState } from "react";
import MapRender from "./MapRender";
import { useTranslation } from "react-i18next";

const MapLocationField = (props) => {
  const [t] = useTranslation();
  const [locationVisible, setLocationVisible] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  const setLocation = () => {
    setLocationVisible(true);
  };
  const onOk = () => {
    setLocationVisible(false);
    props.onChange(JSON.stringify(value));
  };
  const onCancel = () => {
    setLocationVisible(false);
  };
  return (
    <div>
      <Button type="link" icon={<LegacyIcon type={value ? "eye" : "flag"} />} onClick={setLocation}>
        {value ? t("View location") : t("Set map location")}
      </Button>
      <Modal title="Location" onCancel={onCancel} visible={locationVisible} width="800px" onOk={onOk}>
        <MapRender onChange={(v) => setValue(v)} value={value} />
      </Modal>
    </div>
  );
};

export default MapLocationField;
