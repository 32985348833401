import React from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";

import {
  AppstoreOutlined,
  CodeOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FileOutlined,
  FolderOpenOutlined,
  SaveOutlined,
  SearchOutlined,
  ToolOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import { Menu, message, Modal, Input, Table, Popover, Button, Upload } from "antd";
import { post, get, put, exe } from "../Lib/Dal";
import FormRender from "./FormRender";
import { withTranslation } from "react-i18next";
import CodeEditorW from "./Shared/CodeEditorW";

const Column = Table.Column;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const formBuilder = require("formBuilder");
window.formBuilder = formBuilder;

class FormBuilder extends React.Component {
  state = {
    modalNombre: false,
    modalCargar: false,
    selected: [],
    searchText: "",
    searchedColumn: "",
    previewVisible: false,
    logicVisible: false,
    logicValue: "",
    submitPreviewForm: false,
  };
  loadData() {
    let data = [];
    exe("GetForms").then((r) => {
      data = r.outData;
      if (this.props.match.params.formId) {
        this.setState({ data: data, selected: [+this.props.match.params.formId] }, () => this.handleOkModalCargar());
      } else {
        this.setState({ data: data });
      }
    });
  }
  getLanguage = () => {
    switch (window.localStorage.language) {
      case "en":
        return "en-US";
        break;
      case "es":
        return "es-ES";
      default:
        return "en-US";
        break;
    }
  };
  componentDidMount() {
    const me = this;
    window.jQuery(function ($) {
      const options = {
        showActionButtons: false,
        disableFields: ["file"],
        i18n: {
          locale: me.getLanguage(),
          //location: 'http://languagefile.url/directory/'
          //extension: '.ext'
          //preloaded: {
          //    'en-US': {...}
          //}
        },
      };
      window
        .$(document.getElementById("fb-editor"))
        .formBuilder(options)
        .promise.then((r) => {
          me.fb = r;
          me.loadData();
        });
    });
  }
  handleClick = (e) => {
    switch (e.key) {
      case "guardar":
        this.guardar();
        break;
      case "guardarComo":
        this.guardarComo();
        break;
      case "cargar":
        this.cargar();
        break;
      case "nuevo":
        this.nuevo();
        break;
      case "renombrar":
        this.renombrar();
        break;
      case "borrar":
        this.borrar();
        break;
      case "preview":
        this.onPreview();
        break;
      case "logic":
        this.onLogic();
        break;
      case "export":
        this.onExport();
        break;
      default:
        break;
    }
  };

  onLogic() {
    this.setState({ logicVisible: true });
  }
  onPreview() {
    this.setState({ previewVisible: true });
  }
  renombrar() {
    const newName = window.prompt(this.props.t("Please enter new form name"));
    if (newName) {
      const forma = this.state.data.find((f) => f.id == this.state.selected[0]);
      exe("AddOrUpdateForm", { ...forma, name: newName }).then((r) => {
        message[r.ok ? "success" : "error"](r.msg);
        this.loadData();
      });
    }
  }
  borrar() {
    const confirmation = window.prompt(this.props.t("Please type DELETE to confirm"));
    if (confirmation == "DELETE") {
      const forma = this.state.data.find((f) => f.id == this.state.selected[0]);
      exe("DeleteForm", forma).then((r) => {
        message[r.ok ? "success" : "error"](r.msg);
        this.loadData();
      });
    }
  }
  nuevo() {
    this.setState({ selected: [] });
    this.fb.actions.clearFields();
  }
  cargar() {
    this.setState({ modalCargar: true });
  }
  guardar() {
    if (this.state.selected.length == 0) {
      this.guardarComo();
    } else {
      //localStorage[this.state.selected[0]]=this.fb.actions.getData('json');
      let forma = this.state.data.find((f) => f.id == this.state.selected[0]);
      forma.json = this.fb.actions.getData("json");
      forma.logic = this.state.logicValue;

      exe("AddOrUpdateForm", forma).then((r) => {
        message[r.ok ? "success" : "error"](r.msg);
        this.loadData();
      });
    }
  }
  guardarComo() {
    this.setState({ modalNombre: true });
  }
  handleCancelModalNombre() {
    this.setState({ modalNombre: false });
  }
  handleOkModalNombre() {
    const json = this.fb.actions.getData("json");
    let form = { name: this.state.nombreForm, json: json };

    exe("AddOrUpdateForm", form).then((r) => {
      message[r.ok ? "success" : "error"](r.msg);
      this.setState({ modalNombre: false, nombreForm: "" });
      this.loadData();
    });
  }
  handleOkModalCargar() {
    const miForma = this.state.data.find((p) => p.id == this.state.selected[0]);
    this.fb.actions.setData(miForma.json);
    this.setState({ modalCargar: false, logicValue: miForma.logic });
  }
  handleCancelModalCargar() {
    this.setState({ modalCargar: false });
  }
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(ele) => (this.searchInput = ele)}
          placeholder={`${this.props.t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(this.state.selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          {this.props.t("Search")}
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {this.props.t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
  });
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({ searchText: selectedKeys ? selectedKeys[0] : "", searchedColumn: dataIndex });
  };
  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  onOkPreview = () => {
    this.setState({ submitPreviewForm: true });
  };
  onFormOkPreview = (values) => {
    console.log("Form Values", values);
    this.setState({ previewVisible: false, submitPreviewForm: false });
  };
  onExport() {
    const myForm = this.state.data.find((p) => p.id == this.state.selected[0]);

    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
    download(JSON.stringify(myForm), myForm.name + ".json", "text/json");
  }
  onImport(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ selected: [] });
      this.fb.actions.clearFields();
      try {
        const myForm = JSON.parse(e.target.result);
        this.fb.actions.setData(myForm.json);
        this.setState({ logicValue: myForm.logic });
      } catch (error) {
        message.error(this.props.t("Invalid file"));
      }
    };
    reader.readAsText(file);
    return false;
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Menu style={{ marginTop: "-10px", marginBottom: "20px" }} mode="horizontal" onClick={this.handleClick} selectable={false}>
          <SubMenu
            title={
              <span className="submenu-title-wrapper">
                <AppstoreOutlined />
                {t("File")}
              </span>
            }>
            <MenuItemGroup title="Forms">
              <Menu.Item key="nuevo">
                <FileOutlined />
                {t("New")}
              </Menu.Item>
              <Menu.Item key="cargar">
                <FolderOpenOutlined />
                {t("Open")}
              </Menu.Item>
              <Menu.Item key="renombrar">
                <EditOutlined />
                {t("Rename")}
              </Menu.Item>
              <Menu.Item key="borrar">
                <DeleteOutlined />
                {t("Delete")}
              </Menu.Item>
              <Menu.Item key="guardar">
                <SaveOutlined />
                {t("Save")}
              </Menu.Item>
              <Menu.Item key="guardarComo">
                <SaveOutlined />
                {t("Save As...")}
              </Menu.Item>
              <Menu.Item key="export" disabled={!this.state.selected[0]}>
                <DownloadOutlined />
                {t("Export")}
              </Menu.Item>
                <Upload showUploadList={false} beforeUpload={(f) => this.onImport(f)}>
              <Menu.Item>
                  <UploadOutlined />
                  {t("Import")}
              </Menu.Item>
                </Upload>
            </MenuItemGroup>
          </SubMenu>
          <SubMenu
            title={
              <span>
                <ToolOutlined />
                {t("Tools")}
              </span>
            }>
            <Menu.Item key="preview" disabled={this.state.selected.length == 0}>
              <SearchOutlined />
              {t("Preview")}
            </Menu.Item>
            <Menu.Item key="logic">
              <CodeOutlined />
              {t("Logic")}
            </Menu.Item>
          </SubMenu>
        </Menu>
        <Modal title={t("Save Form")} visible={this.state.modalNombre} onOk={() => this.handleOkModalNombre()} onCancel={() => this.handleCancelModalNombre()}>
          <Input placeholder={t("Insert Form Name")} onChange={(v) => this.setState({ nombreForm: v.target.value })} />
        </Modal>

        <Modal title={t("Load Form")} visible={this.state.modalCargar} onOk={() => this.handleOkModalCargar()} onCancel={() => this.handleCancelModalCargar()}>
          <Table
            rowKey="id"
            dataSource={this.state.data}
            pagination={false}
            scroll={{ y: 400 }} 
            rowSelection={{
              columnTitle: <></>,
              selectedRowKeys: this.state.selected,
              onSelect: (sel) => {
                this.setState({ selected: [sel.id] });
              },
            }}
            onRow={(record) => ({
              onClick: () => {
                this.setState({ selected: [record.id] });
              },
            })}>
            <Column title={t("Id")} dataIndex="id" {...this.getColumnSearchProps("id")} />
            <Column title={t("Name")} dataIndex="name" {...this.getColumnSearchProps("name")} />
          </Table>
        </Modal>
        <Modal
          title={t("Preview")}
          destroyOnClose
          visible={this.state.previewVisible}
          onCancel={() => this.setState({ previewVisible: false })}
          onOk={this.onOkPreview}>
          <FormRender
            modal
            formId={this.state.selected[0]}
            closing={!this.state.previewVisible}
            onFormOk={this.onFormOkPreview}
            next={this.state.submitPreviewForm}
          />
        </Modal>
        <Modal
          width={800}
          title={t("Logic")}
          visible={this.state.logicVisible}
          onCancel={() => this.setState({ logicVisible: false })}
          destroyOnClose
          onOk={() => this.setState({ logicVisible: false })}>
          {/* <Input.TextArea rows={5} value={this.state.logicValue} onChange={(v) => this.setState({ logicValue: v.target.value })} /> */}
          <CodeEditorW javascript value={this.state.logicValue} onChange={(v) => this.setState({ logicValue: v })} />
        </Modal>
        <div id="fb-editor"></div>
      </div>
    );
  }
}
export default withTranslation()(FormBuilder);
