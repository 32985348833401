import React, { useState } from "react";
import Operations from "../Financial/Operations";
import DefaultPage from "../Shared/DefaultPage";
import MasterDetail from "../Shared/MasterDetail";
import ClaimReserveDetail from "../Reserves/ClaimReserveDetail";
import PremiumSummary from "./PremiumSummary";
import { useTranslation } from "react-i18next";

const Production = () => {
  const [t, i18n] = useTranslation();
  const [activeKey, setActiveKey] = useState(null);

  const operations = [
    {
      title: t("Premium Summary"),
      description: t("Summary of premium amounts by LOB, Product and other criteria."),
      icon: "dollar",
      onPlay: () => {
        setActiveKey("premiumSummary");
      },
    },
  ];
  return (
    <DefaultPage title={t("Production")} icon="rocket">
      <MasterDetail activeKey={activeKey} onBack={() => setActiveKey(null)} master={<Operations value={operations} />}>
        <PremiumSummary key="premiumSummary" />
        <div></div>
      </MasterDetail>
    </DefaultPage>
  );
};

export default Production;
