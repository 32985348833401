import { Bar } from "@nivo/bar";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { message, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDate, formatDateShort, formatMoney } from "../../Lib/Helpers";
import Currency from "../Shared/Currency";

const BillingSummaryData = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (props.contactId) load(props.contactId);
  }, [props.contactId]);

  const groupByKey = (list, key) => list.reduce((hash, obj) => ({ ...hash, [obj[key]]: (hash[obj[key]] || []).concat(obj) }), {});
  const load = (contactId) => {
    setLoading(true);
    exe("ComputeCollectionSummaryGrouped", { contactId: contactId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const colors = { paid: "#2ecc71", pending: "#fdcb6e", late: "#ff7675" };
  const getColor = (bar) => colors[bar.id];
  const commonProps = (record) => ({
    width: 400,
    height: 70,
    data: [
      {
        paid: record.paid,
        pending: record.pending,
        late: record.late,
        currency: record.currency,
      },
      {
        paid: record.paid,
        pending: record.pending,
        late: record.late,
        currency: record.currency,
      },
    ],
    keys: ["paid", "pending", "late"],
    colors: getColor,
    padding: 0.2,
    indexBy: "currency",
    labelTextColor: "#ffffff",
    labelSkipWidth: 16,
    labelSkipHeight: 16,
  });

  return (
    <div>
      <Table //CURRENCY
        loading={loading}
        pagination={false}
        dataSource={data}
        rowKey={(r) => r.currency}
        expandedRowRender={(r) => (
          <Table //LOB
            pagination={false}
            dataSource={r.lobs}
            rowKey={(r) => r.lob}
            expandedRowRender={(r1) => (
              <Table //POLICY
                dataSource={r1.policies}
                rowKey={(r) => r.policyId}
                expandedRowRender={(r2) => (
                  <Table dataSource={r2.premiums} rowKey={r=>r.itemId}>
                    {/* //PREMIUMS  */}
                    <Table.Column title={t("Item ID")} dataIndex="itemId" />
                    <Table.Column title={t("Concept")} dataIndex="concept" />
                    <Table.Column
                      title={t("Due")}
                      dataIndex="dueDate"
                      render={(v, r) => (r.dueDays > 0 && r.pending ? <Tag color="red">{formatDateShort(v)}</Tag> : formatDateShort(v))}
                    />
                    <Table.Column title={t("Days Due")} dataIndex="dueDays" render={(v,r)=>r.pending?v:<LegacyIcon type={"check-circle"} /> } />
                    <Table.Column title={t("Contract Year")} dataIndex="contractYear" />
                    <Table.Column title={t("# in Year")} dataIndex="numberInYear" />
                    <Table.Column title={t("Paid")} dataIndex="paid" render={(v) => formatMoney(v, r.currency)} />
                    <Table.Column title={t("Pending")} dataIndex="pending" render={(v) => formatMoney(v, r.currency)} />
                  </Table>
                )}>
                <Table.Column title={t("Policy")} dataIndex="policyCode" render={(v, record) => <a href={"#/lifePolicy/" + record.policyId}>{v}</a>} />
                <Table.Column title={t("Paid")} dataIndex="paid" render={(v) => formatMoney(v, r.currency)} />
                <Table.Column title={t("Pending")} dataIndex="pending" render={(v) => formatMoney(v, r.currency)} />
                <Table.Column title={t("Late")} dataIndex="late" render={(v) => formatMoney(v, r.currency)} />
                <Table.Column title={t("Due 0-30")} dataIndex="late30" render={(v) => formatMoney(v, r.currency)} />
                <Table.Column title={t("Due 30-60")} dataIndex="late60" render={(v) => formatMoney(v, r.currency)} />
                <Table.Column title={t("Due 60-90")} dataIndex="late90" render={(v) => formatMoney(v, r.currency)} />
                <Table.Column title={t("Due 90-120")} dataIndex="late120" render={(v) => formatMoney(v, r.currency)} />
                <Table.Column title={t("Due 120+")} dataIndex="late120plus" render={(v) => formatMoney(v, r.currency)} />
              </Table>
            )}>
            <Table.Column title={t("LoB")} dataIndex="lob" />
            <Table.Column title={t("Paid")} dataIndex="paid" render={(v) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Pending")} dataIndex="pending" render={(v) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Late")} dataIndex="late" render={(v) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Due 0-30")} dataIndex="late30" render={(v) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Due 30-60")} dataIndex="late60" render={(v) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Due 60-90")} dataIndex="late90" render={(v) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Due 90-120")} dataIndex="late120" render={(v) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Due 120+")} dataIndex="late120plus" render={(v) => formatMoney(v, r.currency)} />
            <Table.Column
              title={t("Situation Graph")}
              render={(v, record) => <Bar {...commonProps({ ...record, currency: r.currency })} layout="horizontal" enableGridY={false} enableGridX={false} />}
            />
          </Table>
        )}>
        <Table.Column title={t("Currency")} dataIndex="currency" render={(v) => <Currency.Flag currency={v} />} />
        <Table.Column title={t("Paid")} dataIndex="paid" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Pending")} dataIndex="pending" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Late")} dataIndex="late" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Due 0-30")} dataIndex="late30" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Due 30-60")} dataIndex="late60" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Due 60-90")} dataIndex="late90" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Due 90-120")} dataIndex="late120" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Due 120+")} dataIndex="late120plus" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Situation Graph")} render={(v, r) => <Bar {...commonProps(r)} layout="horizontal" enableGridY={false} enableGridX={false} />} />
      </Table>
    </div>
  );
};

export default BillingSummaryData;
