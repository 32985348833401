import React, { Component } from "react";
import { Input, Modal, message, notification } from "antd";
import Inspector from "react-inspector";
import { exe } from "../../Lib/Dal";
import yaml from "js-yaml";
import { withTranslation } from "react-i18next";
import CodeEditorW from "../Shared/CodeEditorW";

class FormulaSimulator extends Component {
  state = {
    formula: undefined,
    data: [],
    loading: false,
  };
  componentDidMount() {
    if(this.props.formula)
    {
      this.setState({formula:this.props.formula});
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.formula!==this.props.formula){
      this.setState({formula:this.props.formula});
    }
  }

  send() {
    let context;
    try {
      context = yaml.safeLoad(this.state.context);
    } catch (error) {
      notification["error"]({
        message: error.reason,
        description: error.message,
      });
      return;
    }
    this.setState({ loading: true });
    exe("ExeFormula", { formula: this.state.formula, context: JSON.stringify(context) }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState({ data: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  }
  render() {
    const { t, i18n } = this.props;
    const r = [];
    return (
      <Modal
        title={t("Formula simulator")}
        okText={t("Send")}
        okButtonProps={{ loading: this.state.loading }}
        visible={this.props.visible}
        onOk={() => this.send()}
        onCancel={this.props.onCancel}>
        {t("Formula")}:
        <Input.TextArea rows={4} onChange={(e) => this.setState({ formula: e.target.value })} value={this.state.formula} />
        {t("Context")}:
        <CodeEditorW divId="formulaSimCode" onChange={(v) => this.setState({ context: v })} />
        {t("Result")}:
        <div>
          <Inspector data={this.state.data} expandLevel={1} />
          <div style={{ marginTop: 10 }}>
            <Inspector table data={this.state.data[0]} />
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(FormulaSimulator);
