import { DeleteOutlined, ReloadOutlined, SelectOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Divider, message, Table } from "antd";
import { load } from "js-yaml";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import PolicyLink from "../Life/PolicyLink";

const PortfolioContracts = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (props.portfolioId) onLoad();
  }, []);

  const onLoad = () => {
    setLoading(true);
    exe("RepoPortfolioContract", { operation: "GET", filter: `portfolioDefinitionId=${props.portfolioId}` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };

  const onCompute = () => {
    setLoading(true);
    exe("ComputePortfolio", { portfolioId: props.portfolioId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load();
      } else message.error(r.msg);
    });
  };
  const onRemoveContract = (id) => {
    setLoading(true);
    exe("RepoPortfolioContract", { operation: "DELETE", entity: { id: id } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(data.filter((p) => p.id !== id));
      } else message.error(r.msg);
    });
  };

  const contractTypes = [{ code: "COV", name: "Primary Insurance" }];

  return (
    <div>
      <Button type="link" icon={<ReloadOutlined />} onClick={onLoad}>
        {t("Reload")}
      </Button>
      <Button type="link" icon={<ThunderboltOutlined />} onClick={onCompute}>
        {t("Update Now")}
      </Button>
      <Table dataSource={data} loading={loading}>
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column
          title={t("Contract Type")}
          dataIndex="contractType"
          render={(v) => (contractTypes.find((p) => p.code == v) ? contractTypes.find((p) => p.code == v).name : v)}
        />
        <Table.Column title={t("Contract")} dataIndex="policyCode" render={(v, r) => <PolicyLink value={r.lifePolicyId} code={v} />} />
        <Table.Column title={t("Coverage")} dataIndex="coverageCode" />
        <Table.Column title={t("Start")} dataIndex="start" render={(v) => formatDateShort(v)} />
        <Table.Column title={t("End")} dataIndex="end" render={(v) => (v ? formatDateShort(v) : null)} />
        <Table.Column title={t("Insured Sum")} dataIndex="insuredSum" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Value")} dataIndex="value" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column
          title={t("Actions")}
          key="actions"
          render={(r) => (
            <div>
              <Button type="link" icon={<DeleteOutlined />} onClick={() => onRemoveContract(r.id)} />
              <Divider type="vertical" />
              <Button type="link" icon={<SelectOutlined />} onClick={() => (window.location = `#/lifepolicy/${r.lifePolicyId}`)} />
            </div>
          )}
        />
      </Table>
    </div>
  );
};

export default PortfolioContracts;
