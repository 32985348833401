import React, { Component } from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import "trix";
import "trix/dist/trix.css";
import { withTranslation } from "react-i18next";

class ReactTrixEditor extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onEditor: PropTypes.func,
    autofocus: PropTypes.bool,
    input: PropTypes.string,
    placeholder: PropTypes.string,
    initialValue: PropTypes.string,
  };

  static defaultProps = {
    autofocus: false,
    input: "react-trix-editor",
    placeholder: "Insert comment here...",
  };

  constructor() {
    super();
    this.id = Math.random().toString(36);
    this.updateStateValue = this.updateStateValue.bind(this);
  }

  componentDidMount() {
    // Provide editor to callback on initialisation
    document.getElementById(this.id).addEventListener("trix-initialize", () => {
      this.editor = document.getElementById(this.id).editor;
      if (this.props.onEditor) {
        // Pass the editor & node
        this.props.onEditor(this.editor, document.getElementById(this.id));
      }
    });

    document.getElementById(this.id).addEventListener("trix-change", (e) => this.updateStateValue(e));
  }

  updateStateValue(e) {
    const value = e.target.value;
    this.html = value;
    //this.props.onChange(value);
  }

  getValue = () => {
    this.props.onOk(this.html);
    this.editor.loadHTML("");
  };

  render() {
    const { input, initialValue, placeholder, autofocus } = this.props;
    const { t, i18n } = this.props;

    return (
      <div>
        <input id={input} value={initialValue} type="hidden" name="content" />
        <trix-editor id={this.id} input={input} placeholder={placeholder} autofocus={autofocus} />

        <Button style={{ marginTop: 5 }} type="primary" onClick={this.getValue}>
          {t("Send")}
        </Button>
      </div>
    );
  }
}

export default withTranslation()(ReactTrixEditor);
