import React, { useState } from "react";
import { Result, Button } from "antd";
import {useTranslation} from "react-i18next";

const SubmissionStep = (props) => {
    const [t]=useTranslation();
    const success=props.success;
    const msg=props.msg;

    return (
        <div>
            {!success &&  <Result
                status="error"
                title={t("Submission Failed")}
                subTitle={msg}
                extra={[
                    <Button type="primary" key="console" onClick={()=>window.location="#/SelfService/home"}>
                        {t("Home")}
                    </Button>,
                    <Button key="buy" onClick={()=>props.restart()}>{t("Try Again")}</Button>,
                ]}
             />}
            {success && (
                <Result
                    status="success"
                    title={t("Successfully submitted claim!")}
                    subTitle={msg}
                    extra={[
                        <Button type="primary" key="console" onClick={()=>window.location="#/SelfService/home"}>
                            {t("Home")}
                        </Button>,
                        <Button key="buy" onClick={()=>props.restart()}>{t("Submit another claim")}</Button>,
                    ]}
                />
            )}
        </div>
    );
};

export default SubmissionStep
