import React, { useEffect, useState } from "react";
import { Result, Button } from "antd";
import NewContactModal from "../Contact/NewContactModal";
import PaymentForm from "./PaymentForm";
import {safeGet} from "../../Lib/Dal";
import { useTranslation } from "react-i18next";

const ProductBuy = (props) => {
  const [newContact, setNewContact] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [t,i18n] = useTranslation();

  useEffect(()=>{
    setLoading(false);
    setSuccess(props.success);
  },[props.success]);

  const onPay=()=>{
    setLoading(true);
    props.onPay();
  }
  
  return (
    <div>
      {!success && (
        <div>
          {/*<Button type="primary" size="large" onClick={() => setNewContact(true)} style={{ marginRight: 10, width: 140 }}>
            New Customer
          </Button>
          <Button size="large" style={{ width: 140, marginBottom: 10 }}>
            Login
          </Button>*/}
        </div>
      )}
      <NewContactModal visible={newContact} onClose={() => setNewContact(false)} />
      {!success && <PaymentForm onPay={onPay} loading={loading} />}
      {success && (
        <Result
          status="success"
          title={t("Successfully Purchased Insurance!")}
          subTitle={<div><span>{t("Policy Code")}: {props.success.code}</span><br/><span>{t("Policy ID")}: {props.success.id}</span></div>}
          extra={[
            <Button type="primary" key="console" onClick={()=>window.location="#/SelfService/home"}>
              {t("Home")}
            </Button>,
            <Button key="buy" onClick={()=>window.location="#/SelfService/home"}>{t("Buy Again")}</Button>,
          ]}
        />
      )}
    </div>
  );
};

export default ProductBuy;
