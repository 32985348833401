import React, { useEffect, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { BlockOutlined, FilterOutlined, PlusCircleOutlined,DownOutlined } from '@ant-design/icons';
import {Button, Dropdown, Icon, Menu, message} from "antd";
import PaymentData from "./PaymentData";
import PaymentFilter from "./PaymentFilter";
import { useTranslation } from "react-i18next";
import ManualPaymentRequest from "./ManualPaymentRequest";
import MergePayments from "./MergePayments";
import {getConfigProfile} from "../../Lib/Helpers";
import {exe, safeGetRaw} from "../../Lib/Dal";

const Payments = (props) => {
  const [t] = useTranslation();
  const [filterVisible, setFilterVisible] = useState(false);
  const [reload, doReload] = useState(0);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [manualPaymentVisible, setManualPaymentVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(-1);
  const [mergeVisible,setMergeVisible]=useState(false);
  const [selected,setSelected]=useState([]);
  const [bulkActions, setBulkActions] = useState([]);
  const [loading,setLoading]=useState(false);

  useEffect(() => {
    if (props.match.params.paymentId) setFilter({ id: props.match.params.paymentId });
  }, [props.match.params.paymentId]);
  
    useEffect(() => {
      getConfigProfile().then((profile) => {
        setBulkActions(safeGetRaw(["Payment","bulkActions"],profile,[]))
      });
    }, []);
  const onManualPaymentRequest = () => {
    setManualPaymentVisible(false);
    doReload((q) => q + 1);
  };
  const onMergeOk=()=>{
    doReload(reload + 1)
    setMergeVisible(false);
    setSelected([]);
  }
  const exeAction=(chain)=> {
    setLoading(true);
    exe("ExeChain", { chain: chain, context:JSON.stringify({paymentIds: selected.map(p=>p.id) })}).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Action executed"));
        doReload(reload + 1);
        if(r.outData){
          //chain result
          if(r.outData.ok){
            if(r.outData.msg) message.success(r.outData.msg);
          }else{
            if(r.outData.msg) message.error(r.outData.msg);
          }
        }
      } else {
        message.error(r.msg);
      }
    });
  }
  return (
    <div>
      <DefaultPage
        title={t("Payments")}
        icon="upload"
        extra={
          <div>
            <Dropdown disabled={selected.length===0||bulkActions.length===0}  overlay={<Menu>
              {bulkActions.map((action,i)=><Menu.Item key={i} onClick={()=>exeAction(action.chain)}>{action.name}</Menu.Item>)}
            </Menu>}>
              <a onClick={e => e.preventDefault()}>
                {t("Bulk Actions")} <DownOutlined />
              </a>
            </Dropdown>
            <Button icon={<BlockOutlined />} type="link" onClick={() => setMergeVisible(true)} disabled={selected.length===0}>
              {t("Merge Payments")}
            </Button>
            <Button icon={<PlusCircleOutlined />} type="link" onClick={() => setManualPaymentVisible(true)}>
              {t("Payment Request")}
            </Button>
            <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
              {t("Filter")}
            </Button>
          </div>
        }>
        <PaymentData data={data} reload={() => doReload(reload + 1)} onPageChange={(v) => setCurrentPage(v)} total={total} onSelectChange={items=>setSelected(items)} selectable pagination />
        <PaymentFilter
          filter={filter}
          visible={filterVisible}
          onClose={() => setFilterVisible(false)}
          onResults={(r) => setData(r)}
          reload={reload}
          currentPage={currentPage}
          onTotal={(v) => setTotal(v)}
        />
        <ManualPaymentRequest visible={manualPaymentVisible} onCancel={() => setManualPaymentVisible(false)} onOk={onManualPaymentRequest} />
        <MergePayments visible={mergeVisible} onCancel={()=>setMergeVisible(false)} onOk={onMergeOk} items={selected} />
      </DefaultPage>
    </div>
  );
};

export default Payments;
