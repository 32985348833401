import React, { useState, useEffect } from "react";
import { Select, Tooltip, Divider } from "antd";
import { exe } from "Lib/Dal";
import { formatDate, formatDateShort, formatMoney } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";

//used inside forms = props = fn onChange, string value
const PremiumSelect = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.display) {
      setData([{ id: props.value, code: props.display }]);
    }
  }, []);
  useEffect(() => {
    console.log("PREMIUM", props.value);
    if (!props.display && data.length == 0 && props.value) {
      exe("RepoPayPlan", { operation: "GET", filter: `payed=0 and id=${props.value}` }).then((r) => {
        if (r.outData) {
          setData(r.outData || []);
          //theese lines generate a racing condition
          // onSelect(r.outData[0].id, r.outData[0]);
          // if (props.onChange) props.onChange(r.outData[0].id);
        }
      });
    }
  }, [props.value]);

  useEffect(() => {
    if (!props.value) loadPremiums(props.policyId);
  }, [props.policyId]);

  const loadPremiums = (policyId) => {
    if (!policyId) return;
    exe("RepoPayPlan", { operation: "GET", filter: `payed=0 and lifePolicyId=${policyId} and cancellationDate is null` }).then((r) => {
      setData(r.outData || []);
    });
  };

  const onSelect = (id, record) => {
    if (props.onSelect) props.onSelect(id, record);
  };

  return (
    <Select
      style={{ width: "100%", ...props.style }}
      onChange={props.onChange && props.onChange}
      value={props.value}
      showSearch
      filterOption={false}
      allowClear
      onSelect={(v, o) => onSelect(v, o.props.record)}
      placeholder={t("Type to search premium...")}>
      {data.map((d) => (
        <Select.Option key={d.id} value={d.id} record={d}>
          {d.numberInYear}-<b>{formatMoney(d.minimum, d.currency)}</b>-{formatDateShort(d.dueDate)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PremiumSelect;
