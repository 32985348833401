import { InfoCircleOutlined } from '@ant-design/icons';
import {Button, message, Modal, Table, Tooltip} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import {formatMoney} from "../../Lib/Helpers";
import Money from "../Shared/Money";

const SurchargesDetailField = (props) => {
    const {t, i18n} = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [detailVisible, setDetailVisible] = useState(false);

    const onLoadDetail = () => {
        if (props.Surcharges) {
            setData(props.Surcharges);
        }
        setDetailVisible(true);
    };
    return (
        <div style={{display: "flex"}}>
            <Money value={props.value} onChange={props.onChange} currency={props.currency} disabled={props.disabled}/>
            <Tooltip title={t("Click for surcharges breakdown")}>
                <Button type="link" icon={<InfoCircleOutlined />} onClick={() => onLoadDetail()}/>
            </Tooltip>
            <Modal
                title={t("Surcharges Detail")}
                visible={detailVisible}
                onCancel={() => setDetailVisible(false)}
                footer={
                    <div>
                        <Button type="primary" onClick={() => setDetailVisible(false)}>
                            {t("Ok")}
                        </Button>
                    </div>
                }>
                <Table dataSource={data} loading={loading} size="middle" pagination={false}>
                    <Table.Column title={t("Code")} dataIndex="name"/>
                    <Table.Column title={t("Name")} dataIndex="description"/>
                    <Table.Column title={t("Amount")} dataIndex="value"
                                  render={(v,r) => formatMoney(v, props.currency)}/>
                </Table>
            </Modal>
        </div>
    );
};

export default SurchargesDetailField;
