import React from "react";
import { Select } from "antd";
import { formatter } from "../Shared/Money";
import { useTranslation } from "react-i18next";

const PolicyCoverageSelect = (props) => {
  const [t] = useTranslation();
  const data = props.coverages || [];
  const money = formatter(props.currency);

  const Options = data.map((d) => (
    <Select.Option key={d.id} value={d.id} label={d.code + " - " + d.name} disabled={props.elegibleCoverages && props.elegibleCoverages.indexOf(d.code) == -1}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          {d.code} - {d.name}
        </div>
        <i>
          {money.format(d.limit)} - {money.format(d.deductible)}
        </i>
      </div>
    </Select.Option>
  ));
  return (
    <Select
      value={props.value ? +props.value : undefined}
      onChange={props.onChange}
      optionLabelProp="label"
      placeholder={t("Please select affected coverage")}
      style={props.style}
      showArrow
      allowClear
      filterOption={false}>
      {Options}
    </Select>
  );
};

export default PolicyCoverageSelect;
