import React, { useEffect, useState } from "react";
import { InfoCircleOutlined, LeftOutlined, RightOutlined, PlusOutlined, ReloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Table, Button, Space, Tag, message, Tooltip, Modal, Form } from "antd";
import { formatter } from "../Shared/Money";
import AccountMov from "../Financial/AccountMov";
import { exe } from "../../Lib/Dal";
import { round2 } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";
import AccountSelect from './../Shared/AccountSelect';
import AccountGroupSelect from './../Financial/AcountGroupSelect';
const { Column, ColumnGroup } = Table;

const FundAccounts = (props) => {
  const [t] = useTranslation();
  const [account, setAccount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [navs, setNavs] = useState([]);
  const [data,setData]=useState([]);
  const [newAccountVisible, setNewAccountVisible] = useState(false);
  const [form]=Form.useForm();
  const [newAccountSelected, setNewAccountSelected] = useState(null);

    useEffect(() => {
        if(props.fundId) load(props.fundId);
        else setData([]);
    },[props.fundId,props.refresh])

 
  const load=(fundId)=>{
    setLoading(true);
    exe("RepoAccount",{operation:"GET",filter:`fundId=${fundId}`}).then(r=>{
        setLoading(false);
        if(r.ok){
            setData(r.outData);
        }else message.error(r.msg);
    })
}


  const selectedAccount = data.find((p) => p.id == account);
  const movements = selectedAccount ? selectedAccount.Movements : [];

  const getAccountNavs = (accounts) => {
    setLoading(true);
    exe("GetNavs", { assets: accounts.map((p) => p.investmentPlanCode) }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setNavs(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const getNav = (assetCode) => {
    const nav = navs.find((p) => p.assetCode == assetCode);
    if (nav) return nav.value;
    else return 0;
  };
  const getValue = (assetCode, units) => {
    return round2(getNav(assetCode) * units);
  };
  const onAccountAdded=()=>{
    //validating
    form.validateFields().then(values=>{
      setLoading(true);
      exe("RepoAccount",{operation:"UPDATE",entity:{...newAccountSelected,fundId:props.fundId}}).then(r=>{
          setLoading(false);
          if(r.ok){
              message.success(r.msg);
              setNewAccountVisible(false);
              load(props.fundId);
          }else message.error(r.msg);
      })
    })
    
  }
  const accountSelected=(v,opt)=>{
    console.log(v,opt);
    setNewAccountSelected(opt.record);
  }
  const onDelete=(record)=>{
    setLoading(true);
      exe("RepoAccount",{operation:"UPDATE",entity:{...record,fundId:null}}).then(r=>{
          setLoading(false);
          if(r.ok){
              message.success(r.msg);
              load(props.fundId);
          }else message.error(r.msg);
      })
    }


  if (account === 0) {
    return (
      <div>
        <Button type="link" icon={<PlusOutlined />} onClick={()=>setNewAccountVisible(true)}>{t("Add")}</Button>
        <Button type="link" icon={<ReloadOutlined />} onClick={()=>load(props.fundId)}>{t("Reload")}</Button>
        <Table dataSource={data} pagination={false} scroll={{ x: true }} loading={loading} rowKey={"id"}>
          <Column title={t("Name")} dataIndex="name"/>
          <Column title={t("Type")} dataIndex="type" render={(v) => <Tag>{v}</Tag>} />
          <Column title={t("Product")} dataIndex="investmentPlanCode"/>
          <Column title={t("Currency")} dataIndex="currency"/>
          <Column title={t("Acc No")} dataIndex="accNo"/>
          <Column title={t("Units")} dataIndex="currentBalance" render={(v, r) => (r.isInvestmentAccount ? round2(v) : "-")}/>
          <Column
            title={
              <span>
                Value
                <Tooltip title={t("If investment account, value will be based on today's NAV (0 if not available)")}>
                  <InfoCircleOutlined style={{ marginLeft: 5 }} />
                </Tooltip>
              </span>
            }
            dataIndex="currentValue"
            render={(v, r) => formatter(r.currency).format(v ? v : getValue(r.investmentPlanCode, r.currentBalance))}/>
          <Column
            title={t("Actions")}
            fixed="right"
            dataIndex="id"
            render={(v,r) => <Space>
              <Button type="link" icon={<DeleteOutlined/>} onClick={() => onDelete(r)} />
              <Button type="link" onClick={() => setAccount(v)}>
                {t("Transactions")}
                <RightOutlined />
              </Button>
              </Space>
            }/>
        </Table>
        <Modal title={t("New Account")} visible={newAccountVisible} onCancel={()=>setNewAccountVisible(false)} onOk={onAccountAdded}>
          <Form form={form}>
          <Form.Item label={t("Account")} name={"id"} rules={[{required:true}]} >
            <AccountSelect onSelect={accountSelected} />
          </Form.Item>
          </Form>
          </Modal>
      </div>
    );
  } else {
    return (
      <div>
        <Button type="link" onClick={() => setAccount(0)} style={{ float: "left" }}>
          <LeftOutlined />
          {t("Back")}
        </Button>

        <AccountMov value={movements} currency={selectedAccount&&selectedAccount.currency} account={selectedAccount} />
      </div>
    );
  }
};

export default FundAccounts;
