import React from "react";

import {
  CalculatorOutlined,
  CodeOutlined,
  CoffeeOutlined,
  CopyOutlined,
  DollarOutlined,
  EditOutlined,
  ExceptionOutlined,
  FileOutlined,
  FilePdfOutlined,
  FormOutlined,
  HomeOutlined,
  InsuranceOutlined,
  ReadOutlined,
  RedoOutlined,
  RetweetOutlined,
  RocketOutlined,
  SaveOutlined,
  TeamOutlined,
  ThunderboltOutlined,
  UndoOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Button,
  Table,
  Select,
  Tabs,
  Row,
  Col,
  Input,
  message,
  Radio,
  Modal,
  Dropdown,
  Menu,
  Tag,
  notification,
  Switch,
  Alert,
  InputNumber,
} from "antd";
import yaml from "js-yaml";
import { exe } from "Lib/Dal";
import moment from "moment";
import ContactSelect from "../Contact/ContactSelect";
import Status from "../Shared/Status";
import { exeTable, getViewState } from "../../Lib/Helpers";
import { Global } from "../../Lib/Global";
import DefaultPage from "../Shared/DefaultPage";
import Title from "antd/lib/typography/Title";
import ReData from "./ReData";
import Currency from "../Shared/Currency";
import DistributionSimulator from "./DistributionSimulator";
import ReTechnicalAccount from "./ReTechnicalAccount";
import ContractCessions from "./ContractCessions";
import Accounts from "../Life/Accounts";
import CurrentAccounts from "./CurrentAccount";
import { withTranslation } from "react-i18next";
import DistributionModeSelect from "./DistributionModeSelect";
import Participants from "./Participants";
import Liquidation from "./Liquidation";
import LiquidationList from "./LiquidationList";
import NewCurrentAccount from "./NewCurrentAccount";
import AccountSelect from "../Shared/AccountSelect";
import ClaimsPerEvent from "./ClaimsPerEvent";
import LiquidationCat from "./LiquidationCat";
import ComContractChanges from "../Commissions/ComContractChanges";
import ContractChanges from "./ContractChanges";
import ContractReinstatement from "./ContractReinstatement";
import Money from "../Shared/Money";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import ReinsuranceDocs from "./ReinsuranceDocs";
import FormSelect from "../Property/FormSelect";
import CustomForm from "../Shared/CustomForm";
import DatePickerW from "../Shared/DatePickerW";
import CodeEditorW from "../Shared/CodeEditorW";

const ButtonGroup = Button.Group;
const RadioGroup = Radio.Group;
const Option = Select.Option;
const { Column, ColumnGroup } = Table;
const { TextArea } = Input;

class Contract extends React.Component {
  state = {
    contract: {},
    loading: false,
    activeKeyTab: "1",
    simulatorVisible: false,
    newAccountVisible: false,
    proportional:true
  };
  componentDidMount() {
    this.load(this.props.match.params.contractId); //carga inicial
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.contractId !== this.props.match.params.contractId) this.load(this.props.match.params.contractId); //update de url
  }
  save = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //extra fields
        values.config = window.global.myCodeMirror.getValue();
        //yaml validation
        try {
          const result = yaml.safeLoad(values.config);
          values.configJson = JSON.stringify(result);
        } catch (error) {
          notification["error"]({
            message: error.reason,
            description: error.message,
          });
          return;
        }
        //custom form
        if (values.formId&&window.customReForm && window.customReForm.instanceContainers[0]) {
          if (window.customReForm.instanceContainers[0].reportValidity()) {
            values.jFormValues = JSON.stringify(window.customReForm.userData);
          } else {
            message.error(this.props.t("Please check custom form validation errors"));
            this.setState({ activeKeyTab: "customForm" });
            return;
          }
        }

        //validation OK
        values.id = this.props.match.params.contractId;
        console.log("Received values of form: ", values);

        if (values.Tags && values.Tags.length > 0)
          values.Tags = values.Tags.map((p) => ({
            tag: p,
            contractId: values.id,
          })); //mapenado tags

        this.setState({ loading: true });
        exe("AddOrUpdateContract", values, { operation: "ADD" }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            if (r.operation == "ADD") window.location.hash = "#/contract/" + r.outData.id;
          } else {
            if (r.changeRequest) {
              const before = {};
              const after = {};
              r.unauthorized.forEach((element) => {
                before[element] = r.outData[element];
                after[element] = r.data[element];
              });
              this.props.form.setFieldsValue(before); //reverting unauth changes
              if (before.config) window.global.myCodeMirror.setValue(before.config);
              if(after.config) after.configJson =  r.data.configJson; //YAML-JSON sync
              const me = this;
              Modal.confirm({
                title: me.props.t("Restricted change"),
                content: r.msg,
                onOk() {
                  const change = {
                    contractId: r.outData.id,
                    jBefore: JSON.stringify(before),
                    JAfter: JSON.stringify(after),
                    operation: r.changeOperation,
                  };
                  exe("AddContractChange", change).then((r) => {
                    if (r.ok) {
                      message.success(r.msg);
                      me.props.form.setFieldsValue({ Changes: r.outData });
                    } else {
                      message.error(r.msg);
                    }
                  });
                },
              });
            } else {
              message.error(r.msg);
            }
          }
        });
      } else {
        //validation error
      }
    });
  };
  onSimulate = () => {
    const values = {};
    //extra fields
    values.config = window.global.myCodeMirror.getValue();
    //yaml validation
    try {
      const result = yaml.safeLoad(values.config);
      values.configJson = JSON.stringify(result);
      this.setState({ configJson: values.configJson, simulatorVisible: true });
    } catch (error) {
      notification["error"]({
        message: error.reason,
        description: error.message,
      });
      return;
    }
  };
  new = () => {
    window.location.hash = "#/contract/0";
    this.setState({ isPerson: true, contract: {} });
  };
  reset = () => {
    this.props.form.resetFields();
  };
  clone=()=>{
    this.setState({ loading: true });
    exe("CloneContract", {contractId:this.props.match.params.contractId}).then((r) => {
        this.setState({ loading: false });
        if (r.ok) {
            message.success(r.msg);
            window.location.hash = "#/contract/" + r.outData.contract.id;
        } else {
            message.error(r.msg);
        }
        });
  };
  load = (contractId) => {
    if (contractId == 0) {
      this.props.form.resetFields();
      return;
    }
    this.setState({ loading: true });
    exe("GetContracts", {
      filter: "id=" + contractId,
      include: ["Tags", "Contact", "Process", "Accounts", "Accounts.Movements", "Changes", "Changes.Process"],
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        const d = r.outData[0];
        if (!d) {
          message.error(r.msg);
        } else {
          //adaptando data del DTO
          if (d.Tags && d.Tags.length > 0) d.Tags = d.Tags.map((p) => p.tag);
          d.createdDate = moment(d.createdDate);
          if (d.effectiveDate) d.effectiveDate = moment(d.effectiveDate);
          if (d.endDate) d.endDate = moment(d.endDate);
          if (d.nextAccountDate) d.nextAccountDate = moment(d.nextAccountDate);

          //extra fields
          if (r.outData[0].config) window.global.myCodeMirror.setValue(r.outData[0].config);

          this.props.form.setFieldsValue(d);
          
          //triggering onchange events
          this.changeIsProportional(d.proportional);

          //executing viewstate rules
          // const ret=getViewState({process:d.Process.nombre,status:d.Process.estado});
          //console.log(ret,'retorno');

          this.setState({ contract: d });
        }
      } else {
        message.error(r.msg);
      }
    });
  };

  activateContract = () => {
    const { t, i18n } = this.props;
    const openModal = Modal.info({
      title: t("Activation"),
      maskClosable: true,
      onOk: this.activate,
      content: (
        <div>
          <DatePickerW />
          <Select
            style={{ width: "100%", marginTop: 5 }}
            placeholder={t("Activation mode")}
            onSelect={(id) => {
              //openModal.destroy();
            }}>
            <Select.Option key="a">{t("Traditional")}</Select.Option>
            <Select.Option key="b">{t("Special")}</Select.Option>
          </Select>
          <Input style={{ marginTop: 5 }} placeholder={t("Signature")}></Input>
        </div>
      ),
    });

    // exe("ActivateContract",{contractId:this.state.contract.id},undefined,true).then(r=>{
    //   const c=this.state.contract;
    //   c.active=true;
    //   c.activedDate=r.activeDate;
    //   this.setState({contract:c});
    // })
  };
  activate = () => {
    exe("ActivateContract", { contractId: this.state.contract.id, next: true }, null, true).then((r) => {
      if (r.ok) this.reload();
    });
  };
  reload() {
    this.load(this.state.contract.id);
  }
  onTemplateClick(v) {
    let templateValue;
    switch (v.key) {
      case "quotashare":
        templateValue = `#Quota Share 20-80 limit=10 000 0000 
Lines:
  - ced: Math.min(_cov.limit*0.2,2500000)
    re: Math.min(_cov.limit*0.8,10000000)
    commission: 0.15*_re
    id: Layer 0 QS`;
        break;
      case "surplus":
        templateValue = `#Surplus 20 lines, retention 1 000 000
Lines:
  - ced: Math.min(_cov.limit,1000000)
    re: Math.min(_rsi,20000000)
    commission: 0.15*_re
    id: Layer 0 QS`;
        break;
      case "mixed":
        templateValue = `#Retention=1 0000 000, Quota Share 10-90 limit=9 000 0000 , 1st surplus 10 lines, 2nd surplus 10 lines, fac-man 5 000 000
Lines:
  - ced: Math.min(_cov.limit*0.1,1000000)
    re: Math.min(_cov.limit*0.9,9000000)
    commission: 0.15*_re
    id: Layer 0 QS
  - ced: 0
    re: Math.min(_rsi,10000000)
    commission: 0.15*_re
    id: Layer 1 1st surplus
  - ced: 0
    re: Math.min(_rsi,10000000)
    commission: 0.15*_re
    id: Layer 2 2nd surplus
  - ced: 0
    re: Math.min(_rsi,5000000)
    commission: 0.15*_re
    id: Layer 3 Facultative-Mandatory`;
        break;
    }

    window.global.myCodeMirror.setValue(templateValue);
  }
  onNewAccount() {
    this.setState({ newAccountVisible: false });
    this.reload();
  }
  changeIsProportional(value){
    this.setState({proportional:value});
  }

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const contractId = this.props.match.params.contractId; //obtenido de la url

    const contactFullName = this.state.contract.Contact && this.state.contract.Contact.FullName;

    const entityState = this.state.contract.Process ? this.state.contract.Process.entityState : "";
    const currency = this.props.form.getFieldValue("currency");
    const values = this.props.form.getFieldsValue();

    return (
      <div style={{}}>
        <DefaultPage
          routes={{
            routes: [
              { breadcrumbName: t("Home"), path: "/" },
              { breadcrumbName: t("Contract List"), path: "contractlist" },
              { breadcrumbName: t("Contract"), path: "" },
            ],
          }}
          title={
            contractId == 0 ? (
              t("New Treaty")
            ) : (
              <span>
                {`${t("Treaty")} ${values.name}  `}
                <Tag>{entityState}</Tag>
              </span>
            )
          }
          icon="file-protect"
          tags={<Status process={this.state.contract.Process} reload={() => this.reload()} />}
          loading={this.state.loading}
          extra={
            <div>
              <ButtonGroup>
                <Button onClick={() => this.reload()} icon={<RedoOutlined />}>
                  {t("Reload")}
                </Button>
                <Button onClick={this.clone} icon={<CopyOutlined />}>
                  {t("Clone")}
                </Button>
                <Button onClick={this.new} icon={<FileOutlined />}>
                  {t("New")}
                </Button>
                <Button onClick={this.save} icon={<SaveOutlined />} type="primary">
                  {t("Save")}
                </Button>
                <Button onClick={this.reset} icon={<UndoOutlined />}>
                  {t("Reset")}
                </Button>
              </ButtonGroup>
            </div>
          }>
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            <Tabs defaultActiveKey="1" activeKey={this.state.activeKeyTab} onTabClick={(tab) => this.setState({ activeKeyTab: tab })}>
              <Tabs.TabPane
                tab={
                  <span>
                    <HomeOutlined />
                    {t("Main")}
                  </span>
                }
                key="1">
                <Row gutter={16}>
                  {/* ////////////////////////////////COLUMNA 1 ////////////////////////////////////////////////////////////////// */}
                  <Col span={12}>
                    {getFieldDecorator("jFormValues")}
                    {getFieldDecorator("processId")}
                    {getFieldDecorator("oldContractId")}
                    <Form.Item label={t("Contract Name")}>
                      {getFieldDecorator("name", {
                        rules: [{ required: true }],
                      })(<Input placeholder={t("Contract Name")} />)}
                    </Form.Item>

                    <Form.Item label={values.inward ? t("Cedant") : t("Lead Reinsurer")}>
                      {getFieldDecorator("contactId", {
                        rules: [{ required: true }],
                      })(<SimpleContactSelect allowNew/>)}
                    </Form.Item>
                    <Form.Item label={t("Code")}>
                      {getFieldDecorator("code", {
                        rules: [{ required: true }],
                      })(<Input />)}
                    </Form.Item>
                    <Form.Item label={t("Tags")}>
                      {getFieldDecorator(
                        "Tags",
                        {}
                      )(<Select mode="tags" style={{ width: "100%" }} tokenSeparators={[","]} placeholder={t("Optional categorization tags")} />)}
                    </Form.Item>
                    <Form.Item label={t("Description")}>
                      {getFieldDecorator("description", {})(<TextArea rows={4} placeholder={t("Free text contact description")} />)}
                    </Form.Item>
                  
                  </Col>
                  {/* ////////////////////////////////COLUMNA 2 ////////////////////////////////////////////////////////////////// */}
                  <Col span={12}>
                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label={t("Direction")}>
                          {getFieldDecorator("inward", {
                            rules: [{ required: true }],
                          })(
                            <RadioGroup name="radiogroup" type="">
                              <Radio.Button value={true}>{t("Inward")}</Radio.Button>
                              <Radio.Button value={false}>{t("Outward")}</Radio.Button>
                            </RadioGroup>
                          )}
                        </Form.Item>
                        <Form.Item label={t("Type")}>
                          {getFieldDecorator("proportional", {
                            rules: [{ required: true }],
                          })(
                            <RadioGroup name="radiogroup" onChange={v=>this.changeIsProportional(v.target.value)}>
                              <Radio.Button value={true}>{t("Proportional")}</Radio.Button>
                              <Radio.Button value={false}>{t("Non-Proportional")}</Radio.Button>
                            </RadioGroup>
                          )}
                        </Form.Item>
                        <Form.Item label={t("Faculty")}>
                          {getFieldDecorator("facultive", {
                            rules: [{ required: true }],
                          })(
                            <RadioGroup name="radiogroup" type="">
                              <Radio.Button value={true}>{t("Facultive")}</Radio.Button>
                              <Radio.Button value={false}>{t("Automatic")}</Radio.Button>
                            </RadioGroup>
                          )}
                        </Form.Item>
                        <Form.Item label={t("Operational Method")}>
                          {getFieldDecorator("operationalMethod", { normalize: (v) => (v ? v : undefined) })(
                            <Select>
                              <Option value={1}>{t("Risk attaching (underwritting year)")}</Option>
                              <Option value={2}>{t("Loss occuring during (accident year)")}</Option>
                              <Option value={3}>{t("Clean-cut (accounting year)")}</Option>
                            </Select>
                          )}
                        </Form.Item>
                        <Form.Item label={t("Account Periodicity")}>
                          {getFieldDecorator("accountPeriodicty", { rules: [{ required: !values.facultive&&values.proportional }], normalize: (v) => (v ? v : undefined) })(
                            <Select>
                              <Option value={3}>{t("Quarterly")}</Option>
                              <Option value={12}>{t("Yearly")}</Option>
                              <Option value={1}>{t("Monthly")}</Option>
                            </Select>
                          )}
                        </Form.Item>
                        <Form.Item label={t("Distribution Mode")}>
                          {getFieldDecorator("distributionMode", {initialValue:0, rules: [{ required: true }] })(<DistributionModeSelect />)}
                        </Form.Item>
                        <Form.Item label={t("Operating Account")}>
                          {getFieldDecorator("operatingAccountId", { rules: [{ required: false }] })(<AccountSelect />)}
                        </Form.Item>
                        <Form.Item label={t("Cash Call")}>
                          {getFieldDecorator("cashCall", {})(<Money currency={values.currency} />)}
                        </Form.Item>
                      </Col>
                      {/* ////////////////////////////////COLUMNA 3 ////////////////////////////////////////////////////////////////// */}
                      <Col span={12}>
                        <div style={{ display: "flex" }}>
                          <Form.Item label={t("System ID")}>{getFieldDecorator("id", {})(<Input readOnly />)}</Form.Item>
                          <Form.Item label={t("Active")} style={{ marginBottom: 4, marginLeft: 10 }}>
                            {getFieldDecorator("active", { valuePropName: "checked" })(<Switch />)}
                          </Form.Item>
                        </div>
                        <div style={{ display: "flex", marginBottom: 4 }}>
                          <Form.Item label={t("Catastrophic")}>{getFieldDecorator("catastrophic", { valuePropName: "checked" })(<Switch />)}</Form.Item>
                          <div style={{ display: values.catastrophic ? "block" : "none" }}>
                            <Form.Item label={t("Min Claims Per Event")} style={{ position: "absolute", left: 120 }}>
                              {getFieldDecorator("catMinClaimsPerEvent", { rules: [{ required: values.catastrophic ? true : false }] })(<InputNumber />)}
                            </Form.Item>
                          </div>
                        </div>

                        <div style={{ display: values.catastrophic ? "flex" : "none", marginBottom: 4 }}>
                          <Form.Item label={t("Reinstatement")}>{getFieldDecorator("autoReinstatement", { valuePropName: "checked" })(<Switch />)}</Form.Item>
                          <Form.Item label={t("Min Deposit Premium")} style={{ position: "absolute", left: 120 }}>
                            {getFieldDecorator("minDepositPremium", { rules: [{ required: values.catastrophic ? true : false }] })(<InputNumber />)}
                          </Form.Item>
                        </div>

                        <Form.Item label={t("Currency")}>{getFieldDecorator("currency", {})(<Currency />)}</Form.Item>
                        <Form.Item label={t("Created")}>{getFieldDecorator("createdDate", {})(<DatePickerW style={{ width: "100%" }} />)}</Form.Item>
                        <Form.Item label={t("Effective")}>{getFieldDecorator("effectiveDate", {})(<DatePickerW style={{ width: "100%" }} />)}</Form.Item>
                        <Form.Item label={t("End")}>{getFieldDecorator("endDate", {})(<DatePickerW style={{ width: "100%" }} />)}</Form.Item>
                        <Form.Item label={t("Next Account Date")}>
                          {getFieldDecorator("nextAccountDate", {})(<DatePickerW style={{ width: "100%" }} />)}
                        </Form.Item>
                        <Form.Item label={t("Custom Form")}>
                          {getFieldDecorator("formId", {})(<FormSelect  />)}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12} />
                      <Col span={12} />
                    </Row>
                  </Col>
                </Row>
              </Tabs.TabPane>
              
              {values.formId&&<Tabs.TabPane
                  forceRender
                  tab={
                    <span>
                        <FormOutlined />
                      {t("Custom")}
                      </span>
                  }
                  key="customForm">
                {values.formId && (
                    <CustomForm formId={values.formId} variable="customReForm" value={values.jFormValues} />
                )}
              </Tabs.TabPane>}
              <Tabs.TabPane forceRender
                tab={
                  <span>
                    <CodeOutlined />
                    {t("Behaviour")}
                  </span>
                }
                key="2">
                <Title style={{ marginTop: 5 }} level={4}>
                  {t("Advanced Contract Behaviour")}
                </Title>
                <Button type="link" icon={<RocketOutlined />} onClick={() => this.onSimulate()}>
                  {t("Simulator")}
                </Button>
                <Dropdown
                  overlay={
                    <Menu onClick={(v) => this.onTemplateClick(v)}>
                      <Menu.Item key="quotashare">{t("Quota Share")}</Menu.Item>
                      <Menu.Item key="surplus">{t("Surplus")}</Menu.Item>
                      <Menu.Item key="mixed">{t("Mixed")}</Menu.Item>
                    </Menu>
                  }>
                  <Button type="link" icon={<CoffeeOutlined />}>
                    {t("Templates")}
                  </Button>
                </Dropdown>
                <CodeEditorW />
              </Tabs.TabPane>
              {!this.state.proportional&&this.state.contract.id&&<Tabs.TabPane
                  tab={
                    <span>
                    <RetweetOutlined />
                      {t("Reinstatement")}
                  </span>
                  }
                  key="reinstatement">
                <ContractReinstatement contractId={this.state.contract.id} />
              </Tabs.TabPane>}
              <Tabs.TabPane
                tab={
                  <span>
                    <InsuranceOutlined />
                    {t("Bordereau")}
                  </span>
                }
                key="3">
                <ContractCessions contractId={values.id} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <ReadOutlined />
                    {t("Technical Account")}
                  </span>
                }
                key="4">
                <ReTechnicalAccount contractId={values.id} periodicity={values.accountPeriodicty} />
              </Tabs.TabPane>
              <Tabs.TabPane
                key="participants"
                tab={
                  <span>
                    <TeamOutlined />
                    {t("Participants")}
                  </span>
                }>
                <Participants contractId={values.id} reloadContract={()=>this.reload()} />
              </Tabs.TabPane>
              <Tabs.TabPane
                key="liquidation"
                tab={
                  <span>
                    <CalculatorOutlined />
                    {t("Liquidation")}
                  </span>
                }>
                <LiquidationList contractId={values.id} />
              </Tabs.TabPane>
              <Tabs.TabPane
                tab={
                  <span>
                    <DollarOutlined />
                    {t("Current Accounts")}
                  </span>
                }
                key="5">
                <Button type="link" onClick={() => this.setState({ newAccountVisible: true })} icon={<FileOutlined />}>
                  {t("New Account")}
                </Button>
                {getFieldDecorator("Accounts")(<CurrentAccounts reload={() => this.reload()} />)}
              </Tabs.TabPane>
              {values.catastrophic && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <ThunderboltOutlined />
                      {t("Cat Events")}
                    </span>
                  }
                  key="6">
                  <ClaimsPerEvent contractId={values.id} />
                </Tabs.TabPane>
              )}
              {values.catastrophic && (
                <Tabs.TabPane
                  tab={
                    <span>
                      <ExceptionOutlined />
                      {t("Cat Liquidation")}
                    </span>
                  }
                  key="7">
                  <LiquidationCat contractId={values.id} />
                </Tabs.TabPane>
              )}
              {/* ////////////////////////////////Changes  ////////////////////////////////////////////////////////////////// */}
              <Tabs.TabPane
                tab={
                  <span>
                    <EditOutlined />
                    {t("Changes")}
                  </span>
                }
                key="changes">
                <Title level={4}>{t("Change Requests")}</Title>
                {getFieldDecorator("Changes", {})(<ContractChanges onRefresh={() => this.reload()} />)}
              </Tabs.TabPane>
              {/* ////////////////////////////////Files  ////////////////////////////////////////////////////////////////// */}
              <Tabs.TabPane
                  tab={
                    <span>
                  <FilePdfOutlined />
                      {t("Files")}
                </span>
                  }
                  key="files">
                <ReinsuranceDocs contractCode={values.code} configProfile={this.props.configProfile} />
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </DefaultPage>
        <DistributionSimulator
          visible={this.state.simulatorVisible}
          onClose={() => this.setState({ simulatorVisible: false })}
          configJson={this.state.configJson}
          contractId={values.id}
        />
        <NewCurrentAccount
          contractId={values.id}
          visible={this.state.newAccountVisible}
          onCancel={() => this.setState({ newAccountVisible: false })}
          onOk={() => this.onNewAccount()}
        />
      </div>
    );
  }
}

const WrappedContactForm = Form.create({ name: "register" })(Contract);
export default withTranslation()(WrappedContactForm);
