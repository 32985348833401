import React from "react";
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import "inputmask/dist/jquery.inputmask.min";
import { get, exe, downloadFile } from "../Lib/Dal";
import { message } from "antd";
import { withTranslation } from "react-i18next";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
const formRender = require("formBuilder/dist/form-render.min.js");

class FormRenderModal extends React.Component {
  componentWillMount() {
    this.setState({ uniqueId: new Date().getTime() });
  }
  componentDidMount() {
    console.log("montando form render");
    if (this.props.modal) {
      this.setState({ mounted: true });
    }
  }

  evalInContext(js, context) {
    return function () {
      return eval(js);
    }.call(context);
  }
  loadCatalog = (catalog, element, filter, valueField = "id", displayField = "name") => {
    exe(catalog, { operation: "GET", filter: filter }).then((r) => {
      r.outData.map((r) => {
        $(element).append($("<option></option>").attr("value", r[valueField]).text(r[displayField]));
      });
    });
  };
  exeCustomLogic(logic) {
    try {
      console.log("Executing custom logic:", logic);
      this.evalInContext(logic, {
        exe: exe,
        loadCatalog: this.loadCatalog,
        processId: this.props.processId,
        downloadFile: downloadFile,
        message,
      });
      console.log("Success");
    } catch (error) {
      console.warn(error);
    }
  }
  formRendered;
  shouldComponentUpdate(nextProps, nextState) {
    console.log("should update?",this.props.visible,nextProps.visible);
    //validacion y envio de form
    if (nextProps.next > this.props.next) {
      if (nextProps.formId) {
        if (this.fbRender && this.fbRender.reportValidity()) {
          this.props.onFormOk(this.formInstance.userData);
        }
      } else {
        this.props.onFormOk({}); //no tiene form
      }
      return false;
    }
    if (!this.props.modal && nextProps.formId == this.props.formId && nextProps.formId != 0) return false; //nada pasa en form, es un update del padre
    if (this.props.modal && nextProps.closing) return false; //closing modal, skipiing processing
    if(this.formRendered===nextProps.formId) return false; //preventing loss focus re-render

    console.log("llego a cambio de form");
    //cambio de form
    const formId = nextProps.formId;
    this.formRendered=formId;
    const me = this;
    if (formId) {
      if (!nextProps.valores) {
        //no tiene valores guardados
        const filter = { filter: isNaN(formId) ? "name='" + formId + "'" : "id=" + formId };
        exe("GetForms", filter).then((v) => {
          if (v.outData.length == 0) {
            message.error(`Form ${formId} not found`);
            return false;
          }
          me.fbRender = document.getElementById("fb-render-" + this.state.uniqueId);
          me.formInstance = $(this.fbRender).formRender({
            container: this.fbRender,
            formData: v.outData[0].json,
          });
          if (v.outData[0].logic) {
            this.exeCustomLogic(v.outData[0].logic);
            this.customLogic = v.outData[0].logic; //storing for execution on show
          }
        });
      } else {
        //tiene valores guardados
        console.log("has values");
        me.fbRender = document.getElementById("fb-render-" + this.state.uniqueId);
        me.formInstance = $(this.fbRender).formRender({
          container: this.fbRender,
          formData: nextProps.valores.json || nextProps.valores,
        });
        //exe custom logic
        if (this.customLogic) {
          this.exeCustomLogic(this.customLogic);
        } else {
          const filter = { filter: isNaN(formId) ? "name='" + formId + "'" : "id=" + formId };
          exe("GetForms", filter).then((v) => {
            if (v.outData.length == 0) {
              message.error(`Form ${formId} not found`);
              return false;
            }
            this.customLogic = v.outData[0].logic;
            this.exeCustomLogic(this.customLogic);
          });
        }
      }
    } else {
      //no tiene form
      me.fbRender = document.getElementById("fb-render-" + this.state.uniqueId);
      me.formInstance = $(this.fbRender).formRender({
        container: this.fbRender,
        formData: '[{"type":"header","subtype":"h3","label":"' + this.props.t("No form configured for this step. Click next to advance.") + '"}]',
      });
    }
    return false; //no quiero renders, lo maneja jquery
  }

  render() {
    return <form id={"fb-render-" + this.state.uniqueId}></form>;
  }
}

export default withTranslation()(FormRenderModal);
