import { Input, message, Modal, notification } from "antd";
import React, { useState } from "react";
import TemplateSelect from "../Shared/TemplateSelect";
import yaml from "js-yaml";
import { exe } from "../../Lib/Dal";
import config from "../../config";
import { useTranslation } from "react-i18next";
import CodeEditorW from "../Shared/CodeEditorW";

const TemplateSimulator = (props) => {
  const [t] = useTranslation();

  const [template, setTemplate] = useState(undefined);
  const [context, setContext] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const generateDoc = () => {
    let jContext;
    try {
      jContext = yaml.safeLoad(context);
    } catch (error) {
      notification["error"]({
        message: error.reason,
        description: error.message,
      });
      return;
    }
    setLoading(true);
    exe("GenerateDoc", { async: false, template: template, data: jContext }).then((r) => {
      setLoading(false);
      if (r.ok) {
        openFile(r.outData.url);
      } else {
        message.error(r.msg);
      }
    });
  };
  const openFile = (fileName) => {
    try {
      fetch(config.ssApiUrl + "/proxy" + fileName, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => response.blob())
        .then((data) => window.open(URL.createObjectURL(data)))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      title={t("Template Simulator")}
      visible={props.visible}
      onOk={() => generateDoc()}
      onCancel={props.onCancel}
      cancelText={t("Close")}
      destroyOnClose
      okButtonProps={{ loading: loading }}>
      {t("Template")}:
      <TemplateSelect onChange={(v) => setTemplate(v)} value={template} />
      {t("YAML Context")}:
      <CodeEditorW divId="templateSim" onChange={(v) => setContext(v)} />
    </Modal>
  );
};

export default TemplateSimulator;
