import React, { useState, useEffect } from "react";
import { Select, Tooltip, Divider } from "antd";
import { exe } from "Lib/Dal";
import { useTranslation } from "react-i18next";

//used inside forms = props = fn onChange, string value
const HtmlTemplateSelect = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length == 0) load();
  }, []);

  const load = () => {
    exe("RepoHtmlTemplate", { operation: "GET" }).then((r) => {
      setData(r.outData || []);
    });
  };
  const onSelectTemplate = (v, opt) => {
    const record = opt.props.record;
    props.onSelect && props.onSelect(record);
  };

  return (
    <div>
      <Select
        style={{ width: "100%" }}
        onChange={props.onChange}
        value={props.value}
        showSearch
        onSelect={onSelectTemplate}
        allowClear
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showArrow={true}
        placeholder={t("Please select template")}>
        {data.map((d) => (
          <Select.Option key={d.id} value={d.id} record={d}>
            {d.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default HtmlTemplateSelect;
