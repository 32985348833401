import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";

const GoalMemberDetail = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onOk(values);
      props.form.resetFields();
    });
  };
  return (
    <div>
      <Modal title title={t("New Member")} visible={props.visible} onCancel={() => props.onCancel()} onOk={onOk}>
        <Form.Item label={t("Contact")}>{field("contactId")(<SimpleContactSelect />)}</Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(GoalMemberDetail);
