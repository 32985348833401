import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Modal, Switch } from "antd";
import {useTranslation} from "react-i18next";
import DateField from "../Shared/DateField";
import Money from "../Shared/Money";
import FrequencySelect from "../Shared/FrequencySelect";
import moment from "moment";
import {round2} from "../../Lib/Helpers";
import DatePickerW from "../Shared/DatePickerW";

const BuyAnnuity = props => {
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    const onOk=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onOk(values);
        })
    }
    const onChangeForm=(values)=>{
        let payments=0;
        let annuityAmount=0;
        switch (values.frequency) {
            case "m1":
                payments  = Math.round(values.end.diff(values.start, 'months', true));
                annuityAmount=values.limit/payments;
                break;
            case "y1":
                payments  = Math.round(values.end.diff(values.start, 'years', true));
                annuityAmount=values.limit/(payments||1);
                break;
        }
        console.log(payments,annuityAmount,values);
        props.form.setFieldsValue({annuityAmount:annuityAmount,payments:payments});
    }
    return (
        <Modal title={t("Buy Annuity")} visible={props.visible} onCancel={props.onCancel} onOk={onOk}>
            <Form.Item label={t("Start")}>{field("start", {rules:[{required:true}]})(<DatePickerW  />)}</Form.Item>
            <Form.Item label={t("End")}>{field("end", {rules:[{required:true}]})(<DatePickerW  />)}</Form.Item>
            <Form.Item label={t("Frequency")}>{field("frequency", { rules: [{ required: true }] })(<FrequencySelect disabledValues={["q1","w1","d1"]} style={{width:"100%"}} />)}</Form.Item>
            <Form.Item label={t("Payments")}>{field("payments", {initialValue:props.limit, rules: [{ required: true }] })(<InputNumber disabled min={0} />)}</Form.Item>
            <Form.Item label={t("Annuity Amount")}>{field("annuityAmount", { rules: [{ required: true }] })(<InputNumber disabled min={0} />)}</Form.Item>
            <Form.Item label={t("Limit")}>{field("limit", {initialValue:props.limit, rules: [{ required: true }] })(<InputNumber disabled min={0} />)}</Form.Item>
        </Modal>
    );
};

export default Form.create({onValuesChange:(props, changedValues, values)=>{
    if(Object.keys(changedValues).some(p=>["annuityAmount","payments"].includes(p))) return; //to avoid circular calling
    if(changedValues.payments) return;
        let payments=0;
        let annuityAmount=0;
        switch (values.frequency) {
            case "m1":
                payments  = Math.round(values.end.diff(values.start, 'months', true));
                annuityAmount=values.limit/payments;
                break;
            case "y1":
                payments  = Math.round(values.end.diff(values.start, 'years', true));
                annuityAmount=values.limit/(payments||1);
                break;
        }
        props.form.setFieldsValue({annuityAmount:annuityAmount,payments:payments});
    }})(BuyAnnuity);