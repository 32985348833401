import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PlanOptionSelect = (props) => {
  const [t] = useTranslation();
  const handleChange = (value) => {
    console.log(`selected ${value}`);
    if (value) props.onChange(JSON.stringify(value));
  };
  const planOptions = props.planOptions;

  return (
    <div>
      <Select
        mode="multiple"
        style={{ width: "100%" }}
        placeholder={t("Please select")}
        onChange={handleChange}
        value={props.value ? JSON.parse(props.value) : undefined}>
        {planOptions.map((p) => (
          <Select.Option value={p.code}>{p.name}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default PlanOptionSelect;
