import { CheckCircleOutlined } from '@ant-design/icons';
import { Select,ConfigProvider } from "antd";
import React, { useEffect, useState,useContext } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import ContactSelect from "./ContactSelect";
import getCache from "../../Lib/Cache";

const MainInsuredSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [relationships, setRelationships] = useState([]);
  const [contact, setContact] = useState({});
  const [relationship, setRelationship] = useState(undefined);
  const configContext = useContext(ConfigProvider.ConfigContext);
  const direction = configContext.direction;

  useEffect(() => {
    if(relationships.length===0){
      getCache("RepoRelationshipCatalog").then((r) => {
        if (r.ok) {
          setRelationships([...r.outData, { id: 0, name: "N/A", principalType: "PERSON" },{ id: 0, name: "N/A", principalType: "LEGAL" }]);
        }
        setValue(); //wait before setting value
    })
    }else setValue();
   
  }, [props.value]);

  const setValue = () => {
    if (props.value) {
      setContact({ key: props.value.contactId, label: props.value.name, contact: props.value.Contact });
      setRelationship(props.value.relationship);
    } else {
      setContact({});
      setRelationship(undefined);
    }
  };

  const onChangeContact = (v) => {
    setContact(v);
    if (v) {
      //if (props.holderContact && props.holderContact.id == v) setRelationship(0);
      props.onChange({ id:props.value?props.value.id:0,role: 0, contactId: v.key, name: v.label, relationship: relationship, Contact: v.contact }); //transforming to DB format
    } else {
      props.onChange({ id:props.value?props.value.id:0,role: 0, contactId: 0, name: undefined, relationship: undefined, Contact: null }); //transforming to DB format
    }
  };
  const onChangeRelationship = (v) => {
    setRelationship(v);
    props.onChange({ id:props.value?props.value.id:0,role: 0, contactId: contact.key, name: contact.label, relationship: v, Contact: contact.contact }); //transforming to DB format
  };

  const isPerson = props.holder ? props.holder.isPerson : true;

  const sameAsHolder = props.holder && props.value ? props.holder.id == props.value.contactId : false;
  if (sameAsHolder && relationship !== 0) setRelationship(0);
  if (!sameAsHolder && relationship == 0) setRelationship(undefined);

  return (
    <div style={{ display: "flex" }}>
      <ContactSelect value={contact} style={{ flex: 1,...(direction=="rtl"?{marginLeft:3}:{marginRight: 3})  }} contactChange={(v) => onChangeContact(v)} configProfile={props.configProfile} />
      <Select
        style={{ width: 150 }}
        dropdownMatchSelectWidth={false}
        placeholder={t("Relationship")}
        value={relationship}
        onChange={(v) => onChangeRelationship(v)}
        disabled={sameAsHolder}>
        {relationships
          .filter((relationship) => relationship.principalType == (isPerson ? "PERSON" : "LEGAL"))
          .map((relationship) => (
            <Select.Option value={relationship.id} key={relationship.id}>
              {t(relationship.name)}
            </Select.Option>
          ))}
      </Select>
      <CheckCircleOutlined
        disabled
        style={{ fontSize: 24, color: "#999", alignSelf: "center",...(direction=="rtl"?{marginRight:5}:{marginLeft: 5}) }} />
    </div>
  );
};

export default MainInsuredSelect;
