import React, { useState, useEffect, useRef } from "react";
import {SearchOutlined} from '@ant-design/icons';
import { Input, AutoComplete, Button } from "antd";
import { exe } from "../Lib/Dal";
import { useTranslation } from "react-i18next";

const SearchSmall = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [width, setWidth] = useState(200);
  const [boxVisible, setBoxVisible] = useState(false);
  const [flatMenu, setFlatMenu] = useState([]);

  const inputRef = useRef();

  useEffect(() => {
    if (props.navigation) {
      setFlatMenu(flaten(props.navigation));
    }
  }, []);

  useEffect(() => setBoxVisible(props.open), [props.open]);

  // useEffect(() => {
  //   if (boxVisible) document.getElementById("sisosSearchBox").focus();
  // }, [boxVisible]);

  const flaten = (a) => {
    const temp = [];
    a.forEach((p) => {
      if (p.children) {
        temp.push(...flaten(p.children));
      } else {
        temp.push(p);
      }
    });
    return temp;
  };

  const onSearch = (v) => {
    if (v == "") return;
    exe("DoSearch", { searchString: v }).then((r) => {
      //navigation data
      if (props.navigation) {
        //search in navigation wide matches of the search string in texto
        const navMatches = flatMenu.filter((p) => p.texto.toLowerCase().indexOf(v.toLowerCase()) !== -1);
        r.outData = r.outData.concat(navMatches.map((p) => ({ result: p.texto, type: "Navigation", link: "#" + p.link })));
      }
      setData(r.outData);
    });
  };

  const onSelect = (v,opt) => {
    window.location = v;
    if (props.onSelect) {
      props.onSelect(); //managed outside
    } else {
      setBoxVisible(false);
    }
  };
  const onFocus = () => {
    setWidth(500);
  };
  const onBlur = () => {
    //setWidth(200);
    if (!props.onSelect) {
      setBoxVisible(false); //managed outside
    }
  };
  const groups = [
    { name: t("Policies"), type: "Policy", moreLink: "#/policies" },
    { name: t("Claims"), type: "Claim", moreLink: "#/claimList" },
    { name: t("Contacts"), type: "Contact", moreLink: "#/contactList" },
    { name: t("Navigation"), type: "Navigation", moreLink: "#/home" },
  ];
  const renderTitle = (title,link) => <a href={link}>{title}</a>;
  const renderItem = (title, link) => ({
    value: link,
    label: (
        <span>
          {title}
        </span>
    ),
  });
  return (
    <div style={{display:"flex"}}>
      {!boxVisible && (
        <Button shape="circle" size="large" style={{ backgroundColor: "transparent", border: "none",marginTop:5 }} onClick={() => setBoxVisible(true)}>
          <SearchOutlined style={{ color: "white", marginLeft: 5, marginRight: 5, fontSize: 18 }} />
        </Button>
      )}
      {boxVisible && (
        <AutoComplete
            autoFocus={true}
          style={{ width: width,marginTop:5 }}
          onSearch={onSearch}
          onSelect={onSelect}
          onFocus={onFocus}
          onBlur={onBlur}
          options={groups.map((group) => ({label: renderTitle(group.name,group.moreLink), 
            options: data.filter((p) => p.type == group.type).map((p) => renderItem(p.result, p.link))
          }))}>
          <Input suffix={<SearchOutlined />}  size={"large"}  placeholder={t("Type a command or search text...")} id="sisosSearchBox" />
        </AutoComplete>
      )}
    </div>
  );
};

export default SearchSmall;
