import { message, Modal, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import Address from "./Address";

const Addresses = (props) => {
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    //contact load
    if (props.value) {
      if (props.value.length > 0) setData(props.value);
      else setData([{ id: 0, contactId: props.contactId, addressType: t("Please enter new address") }]);
    }
  }, [JSON.stringify(props.value)]);

  const onEditAddressTab = (index, operation) => {
    if (operation == "add") {
      const newdata = [...data, { id: 0, contactId: props.contactId, addressType: t("Please enter new address") }];
      props.onChange(newdata);
    }
    if (operation == "remove") {
      if (data.length == 1) return; //must have at leat one address
      if (data[index].id) {
        showDeleteConfirm(index);
      }else{
        props.onChange(data.filter((p,i)=>i!==+index));
      }
    }
  };

  const removeAddress = (id) => {
    exe("RepoContactAddress", { operation: "DELETE", entity: { id: id } }).then((r) => {
      if (r.ok) {
        message.success(r.msg);
      } else {
        message.error(r.msg);
      }
    });
  };
  const showDeleteConfirm = (index) => {
    Modal.confirm({
      title: t("Are you sure you want to remove this address?"),
      content: t("Please save the contact to persist changes"),
      okText: t("Yes"),
      okType: "danger",
      cancelText: t("No"),
      onOk() {
        //removeAddress(data[index].id); done through AddOrUpdateContact
        const newdata = [...data];
        newdata.splice(index, 1);
        props.onChange(newdata);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //   const load = (contactId) => {
  //     setLoading(true);
  //     exe("RepoContactAddress", { operation: "GET", filter: `contactId=${props.contactId}` }).then((r) => {
  //       setLoading(false);
  //       if (r.ok) {
  //         if (r.outData.length > 0) setData(r.outData);
  //       } else {
  //         message.error(r.msg);
  //       }
  //     });
  //   };
  const loadAdresstypes = () => {};

  const onAddressChange = (value, index) => {
    const newData = [...data];
    newData[index] = value;
    props.onChange(newData);
  };

  return (
    <div>
      <Tabs type="editable-card" onEdit={(e, operation) => onEditAddressTab(e, operation)}>
        {data.map((p, index) => (
          <Tabs.TabPane tab={p.addressType} closable={index > 0} key={index}>
            <Address
              value={p}
              onChange={(v) => onAddressChange(v, index)}
              contactId={props.contactId}
              onRefresh={(v) => onAddressChange(v, index)}
              isMandatory={props.isMandatory}
              onAddAddress={() => onEditAddressTab(null, "add")}
              defaultCountry={props.defaultCountry}
              hasSector={props.hasSector}
              index={index}
              t={t} //for use in Form.create
              personType={props.personType}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default Addresses;
