import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DollarOutlined, EyeOutlined } from '@ant-design/icons';
import {Table, Button, message, Popconfirm, Divider, Tag, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import ProfitCommissionLiquidationDetail from "./ProfitCommissionLiquidationDetail";
import Status from "../Shared/Status";
import {formatDateShort, formatMoney, getColor} from "../../Lib/Helpers";

const ProfitCommissionLiquidations = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.profitCommissionId) load();
    },[props.profitCommissionId])
    
    const load=()=>{
        setLoading(true);
        exe("RepoProfitCommissionLiquidation",{operation:"GET",filter:`profitCommissionId=${props.profitCommissionId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    
    const onSave=values=>{
        values.profitCommissionId=props.profitCommissionId;
        setLoading(true);
        exe("AddProfitCommissionLiquidation",{...values}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })
        
    }
    const onDelete=record=>{
        setLoading(true);
        exe("RepoProfitCommissionLiquidation",{operation:"DELETE",entity:record}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected(null);
                load();
            }else message.error(r.msg);
        })

    }
    const getStatusText = (v) => {
        if (v == 0) {
            return <Tag>{t("Draft")}</Tag>;
        } else if (v == 1) {
            return <Tag color="green">{t("Executed")}</Tag>;
        }else if (v == 2) {
            return <Tag color="red">{t("Reverted")}</Tag>;}
        else {
            return <Tag>{v}</Tag>;
        }
    };
    const onExecuteLiquidation=record=> {
        setLoading(true);
        exe("ExeProfitCommissionLiquidation", {liquidationId: record.id}).then(r => {
            setLoading(false);
            if (r.ok) {
                message.success(r.msg);
                load();
            } else message.error(r.msg);
        })
    }

    return (
        <div>
            {!selected&&<div>
                <Button type={"primary"} icon={<LegacyIcon type={"plus"} />} onClick={()=>setSelected({id:0})}>{t("New")}</Button>
                <Table dataSource={data} rowKey={"id"} style={{marginTop:5}} loading={loading}>
                    <Table.Column title={t("Id")} dataIndex={"id"} />
                    <Table.Column title={t("Name")} dataIndex={"liquidationName"} />
                    <Table.Column title={t("Participant")} dataIndex={"participantName"} />
                    <Table.Column title={t("Start")} dataIndex={"start"} render={v=>formatDateShort(v)} />
                    <Table.Column title={t("End")} dataIndex={"end"} render={v=>formatDateShort(v)} />
                    <Table.Column title={t("Creation")} dataIndex={"creation"} render={v=>formatDateShort(v)} />
                    <Table.Column title={t("Amount")} dataIndex={"total"} render={(v,r)=>formatMoney(v,r.currency)} />
                    <Table.Column title={t("Currency")} dataIndex={"currency"} />
                    <Table.Column title={t("Status")} dataIndex="status" render={(v) => getStatusText(v)} />
                   
                    <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div style={{whiteSpace:"nowrap"}}>
                        <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={()=>onDelete(r)}>
                            <Button type={"link"} icon={<LegacyIcon type={"delete"} />} disabled={r.status!==0}  />
                        </Popconfirm>
                        <Divider type="vertical" />
                        <Popconfirm
                            title={t("Are you sure you want to execute this liquidation?")}
                            onConfirm={() => onExecuteLiquidation(r)}>
                            <Button type="link" icon={<DollarOutlined />} disabled={r.status!==0} />
                        </Popconfirm>
                        <Divider type="vertical" />
                        <Tooltip title={t("Calculation Detail")}>
                            <Button type={"link"} icon={<EyeOutlined />} disabled={r.status!==0||!r.jDetail} onClick={()=> props.showCalculationDetail(r)}  />
                        </Tooltip>
                    </div>}/>
                </Table>
            </div>}
            {selected&&<ProfitCommissionLiquidationDetail 
                onBack={()=>setSelected(null)} 
                onOk={onSave} 
                selected={selected} 
                onDelete={onDelete}
                profitCommissionId={props.profitCommissionId} schemeId={props.schemeId} context={props.context}
            />}
        </div>
    );
};

export default ProfitCommissionLiquidations;