import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort } from "../../Lib/Helpers";

const BatchSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("RepoBatch", { operation: "GETINFO" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData||[]);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.id} key={p.id}>
            {p.name} - {p.records} {t("records")} - {formatDateShort(p.created)} - {p.status}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default BatchSelect;
