import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ClaimTypeSelect = (props) => {
  const [t] = useTranslation();
  return (
    <div>
      <Select value={props.value} onChange={props.onChange} disabled={props.disabled}>
        <Select.Option value="HEALTH">{t("Health Coverage")}</Select.Option>
        <Select.Option value="DISABILITY">{t("Disability Coverage")}</Select.Option>
        <Select.Option value="DEATH">{t("Death Coverage")}</Select.Option>
        <Select.Option value="ONCOLOGICAL">{t("Oncological Coverage")}</Select.Option>
        <Select.Option value="OTHER">{t("Other")}</Select.Option>
      </Select>
    </div>
  );
};

export default ClaimTypeSelect;
