import React, { useState, useEffect } from "react";
import FileUpload from "../Life/FileUpload";
import DefaultPage from "../Shared/DefaultPage";
import {
  DeleteOutlined,
  EditOutlined,
  ExperimentOutlined,
  FolderOpenOutlined,
  ReloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Table, Button, Divider, Popconfirm, message, Input, Modal } from "antd";
import { fetchProxy } from "../../Lib/Dal";
import config from "../../config";
import { formatDate } from "../../Lib/Helpers";
import showPrompt from "../Shared/showPrompt";
import TemplateSimulator from "./TemplateSimulator";
import { useTranslation } from "react-i18next";

const Templates = (props) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  const [refresh, doRefresh] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [simulatorVisible, setSimulatorVisible] = useState(false);

  useEffect(() => getTemplates(), [refresh]);

  const getTemplates = () => {
    fetchProxy("/templates", "GET").then((r) => {
      setData(r.outData);
    });
  };
  const openFile = (f) => {
    fetch(config.ssApiUrl + "/proxy/templates/" + f, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => response.blob())
      .then((data) => window.open(URL.createObjectURL(data)));
  };
  const deleteFile = (f) => {
    fetchProxy("/templates/" + f, "DELETE").then((r) => {
      if (r.outData.ok) {
        message.success(r.outData.msg);
        getTemplates();
      } else {
        message.error(r.outData.msg);
      }
    });
  };
  const renameFile = (f) => {
    showPrompt({
      title: t("Please enter new name"),
      placeholder: t("New name"),
      rules: [
        {
          required: true,
          message: t("You must enter name"),
        },
      ],
    })
      .then((newName) => {
        fetchProxy("/templates/rename/" + f + "/" + newName, "PUT").then((r) => {
          if (r.outData.ok) {
            message.success(r.outData.msg);
            getTemplates();
          } else {
            message.error(r.outData.msg);
          }
        });
      })
      .catch((e) => console.error(e));
  };
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
          {t("Search")}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />
    //   ) : (
    //     text
    //   ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end
  return (
    <DefaultPage
      icon="file-word"
      title={t("Document Templates")}
      extra={
        <div>
          <Button type="link" icon={<ReloadOutlined />} onClick={() => doRefresh(refresh + 1)}>
            {t("Reload")}
          </Button>
          <Button type="primary" icon={<ExperimentOutlined />} onClick={() => setSimulatorVisible(true)}>
            {t("Simulator")}
          </Button>
        </div>
      }>
      <FileUpload destinationFolder="/templates" preserveName={true} onChange={() => doRefresh(refresh + 1)} />
      <Table dataSource={data}>
        <Table.Column title={t("Name")} dataIndex="name" {...getColumnSearchProps("name")} />
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
        <Table.Column
          title={t("Actions")}
          render={(v, r) => (
            <div>
              <Button type="link" icon={<FolderOpenOutlined />} onClick={() => openFile(r.name)} />
              <Divider type="vertical" />
              <Popconfirm title={t("Are you sure you want to delete this file?")} onConfirm={() => deleteFile(r.name)}>
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
              <Divider type="vertical" />
              <Button type="link" icon={<EditOutlined />} onClick={() => renameFile(r.name)} />
            </div>
          )}
        />
      </Table>

      <TemplateSimulator visible={simulatorVisible} onCancel={() => setSimulatorVisible(false)} />
    </DefaultPage>
  );
};

export default Templates;
