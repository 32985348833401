import React, { useEffect } from "react";
import { RocketOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Button, Row, Col } from "antd";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";
import ChainSelect from "../Bpm/ChainSelect";

const HtmlTemplateForm = (props) => {
  const [t] = useTranslation();

  useEffect(() => {
    if (props.record) {
      props.form.setFieldsValue(props.record);
    }
  }, [props.record]);
  const field = props.form.getFieldDecorator;
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      exe("RepoHtmlTemplate", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        if (r.ok) {
          message.success("Done");
          props.form.setFieldsValue(r.outData[0]);
          props.onUpdate();
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const onTest = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      exe("RenderHtmlTemplate", { template: values.template, context: values.testContext }).then((r) => {
        if (r.ok) {
          props.form.setFieldsValue({ output: r.outData.result });
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  return (
    <div>
      <Form>
        <Row gutter={16}>
          <Col span={12}>
            {field("id")}
            <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
            <Form.Item label={t("Description")}>{field("description", { rules: [{}] })(<Input />)}</Form.Item>
            <Form.Item label={t("Context Loader")}>{field("chainId")(<ChainSelect filter="1=1" valueField="id" />)}</Form.Item>
            <Form.Item label={t("Template")}>{field("template", { rules: [{}] })(<Input.TextArea rows={8} />)}</Form.Item>
            {!props.readOnly && (
              <Button type="primary" icon={<SaveOutlined />} onClick={onSave}>
                {t("Save")}
              </Button>
            )}
          </Col>
          <Col span={12}>
            <Form.Item label={t("Test Context")}>{field("testContext", { rules: [{}] })(<Input.TextArea rows={4} />)}</Form.Item>
            <Form.Item label={t("Output")}>{field("output", { rules: [{}] })(<Input.TextArea rows={8} />)}</Form.Item>
            <Button icon={<RocketOutlined />} onClick={onTest}>
              {t("Test")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Form.create()(HtmlTemplateForm);
