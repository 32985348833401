import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, DatePicker, Drawer, Input, InputNumber } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Compare from "../Shared/Compare";
import LobSelect from "../Shared/LobSelect";
import Currency from "../Shared/Currency";

const CustomCumulusFilter = (props) => {
    const [t] = useTranslation();
    const field = props.form.getFieldDecorator;
    const onSearch = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            console.log(values);
            if(Array.isArray(values.range)){
                values.rangeStart=values.range[0];
                values.rangeEnd=values.range[1];
            }
            props.onSearch(values);
        });
    };
    return (
        <Drawer title={t("Custom parameters")} visible={props.visible} onClose={props.onClose} width={512}>
            <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
                    {t("Reset")}
                </Button>
            </div>
            <Form>
                <Form.Item label={t("Cumulus Field")}>{field("cumulusField",{rules:[{required:true}]})(<Input placeholder={t("Please enter a custom field to group by")} />)}</Form.Item>
                <Form.Item label={t("LoB")}>{field("lob")(<LobSelect />)}</Form.Item>
                <Form.Item label={t("Currency")}>{field("currency")(<Currency />)}</Form.Item>
                <Form.Item label={t("Issuance Period")}>{field("period")(<DatePicker.MonthPicker />)}</Form.Item>
                <Form.Item label={t("Issuance Range")}>{field("range")(<DatePicker.RangePicker />)}</Form.Item>
                <Form.Item label={t("Include Proposals")}>{field("includeProposals",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
                <Form.Item label={t("Include Empty Values")}>{field("includeEmpty",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
                <Form.Item label={t("Recompute Results")}>{field("recompute",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
                <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()}>
                    {t("Search")}
                </Button>
            </Form>
        </Drawer>
    );
};

export default Form.create({})(CustomCumulusFilter);
