import { FlagOutlined } from '@ant-design/icons';
import { Button, message, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDate } from "../../Lib/Helpers";

const RiskAnalysis = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const onCheckNow = () => {
    setLoading(true);
    exe("DoRiskAnalysis", { policyId: props.policyId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        props.onChange(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Button type="link" icon={<FlagOutlined />} onClick={() => onCheckNow()} loading={loading} disabled={!props.policyId}>
        {t("Check Now")}
      </Button>
      <Table dataSource={props.value}>
        <Table.Column title={t("Code")} dataIndex="code" />
        <Table.Column title={t("Name")} dataIndex="name" />
        <Table.Column title={t("Factor")} dataIndex="factor" />
        <Table.Column title={t("Action")} dataIndex="action" />
        <Table.Column title={t("Checked")} dataIndex="lastChecked" render={(v) => formatDate(v)} />
      </Table>
    </div>
  );
};

export default RiskAnalysis;
