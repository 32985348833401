import React, { useEffect, useState } from "react";
import JsBarcode from "jsbarcode";
import { DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input } from "antd";
import { useTranslation } from "react-i18next";

const Barcode = (props) => {
  const [t] = useTranslation();

  const [editCode, setEditCode] = useState(false);
  useEffect(() => {
    if (props.value) generatecode(props.value);
  }, [props.value]);

  const generatecode = (value) => {
    JsBarcode("#barcode", value, { format: "CODE128" });
  };
  const triggerDownload = (imgURI) => {
    var evt = new MouseEvent("click", {
      view: window,
      bubbles: false,
      cancelable: true,
    });

    var a = document.createElement("a");
    a.setAttribute("download", props.fileName + ".jpg");
    a.setAttribute("href", imgURI);
    a.setAttribute("target", "_blank");

    a.dispatchEvent(evt);
  };
  const onDownload = () => {
    var canvas = document.getElementById("barcode");
    var img = canvas.toDataURL("image/jpg");
    triggerDownload(img);
  };
  return (
    <div>
      <canvas id="barcode"></canvas>
      <Input
        value={props.value}
        onChange={(v) => props.onChange && props.onChange(v.target.value)}
        placeholder={t("Barcode value")}
        style={{ display: editCode ? "block" : "none" }}
      />
      <div>
        <Button type="link" icon={<DownloadOutlined />} onClick={onDownload}>
          {t("Download")}
        </Button>
        <Button type="link" icon={<EditOutlined />} onClick={() => setEditCode(!editCode)}>
          {editCode ? t("Hide Editor") : t("Edit Barcode")}
        </Button>
      </div>
    </div>
  );
};

export default Barcode;
