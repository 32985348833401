import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, InputNumber, Select, Button, message } from 'antd';
import { exe } from '../../Lib/Dal';

const { TextArea } = Input;

const ProjectEdit = ({ visible, onCancel, onSave, initialValues }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (visible && inputRef.current) {
            setTimeout(() => inputRef.current.focus(), 100);
        }
        if (initialValues) {
            form.setFieldsValue(initialValues);
        }
    }, [visible, initialValues]);

    const handleOk = () => {
        form.validateFields().then((values) => {
            setLoading(true);
            exe("RepoProject",{operation:"ADD",entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    onSave(r.outData);
                }else{
                    message.error(r.msg);
                }
            })

        }).catch((info) => {
            console.log('Validate Failed:', info);
        });
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.ctrlKey) {
            event.preventDefault();
            handleOk();
        }
    };

    return (
        <Modal
            visible={visible}
            title={initialValues ? "Edit Project" : "Add Project"}
            onCancel={onCancel}
            onOk={handleOk}
            footer={[
                <Button key="cancel" onClick={onCancel} >
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleOk} loading={loading}>
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onKeyDown={handleKeyDown}
            >
                <Form.Item name="id" hidden>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item
                    name="code"
                    label="Code"
                    rules={[{ required: true, message: 'Please input the project code!' }]}
                >
                    <Input ref={inputRef} />
                </Form.Item>
                <Form.Item
                    name="name"
                    label="Name"
                    rules={[{ required: true, message: 'Please input the project name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Description"
                >
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    name="progress"
                    label="Progress"
                    hidden
                    rules={[{ required: false, message: 'Please input the progress!' }]}
                >
                    <InputNumber min={0} max={100} formatter={(value) => `${value}%`} parser={(value) => value.replace('%', '')} />
                </Form.Item>
                <Form.Item
                    name="version"
                    label="Version"
                    rules={[{ required: true, message: 'Please input the version!' }]}
                >
                    <InputNumber min={1} />
                </Form.Item>
                <Form.Item
                    name="status"
                    label="Status"
                    hidden
                    rules={[{ required: false, message: 'Please select the status!' }]}
                >
                    <Select>
                        <Select.Option value="active">Active</Select.Option>
                        <Select.Option value="inactive">Inactive</Select.Option>
                        <Select.Option value="completed">Completed</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name="owner"
                    label="Owner"
                    rules={[{ required: false, message: 'Please input the owner!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="jConfig"
                    label="JSON Configuration"
                    hidden
                >
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ProjectEdit;
