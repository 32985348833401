import React, {useEffect, useState} from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Input, message } from "antd";
import Money from "../Shared/Money";
import Periodicty from "../Shared/Periodicty";
import PaymentMethods from "./PaymentMethods";
import { useTranslation } from "react-i18next";
import { round2 } from "../../Lib/Helpers";
import {exe, safeGet} from "../../Lib/Dal";
import ReceiptTypeSelect from "../Tax/ReceiptTypeSelect";
import FeeDetailField from "./FeeDetailField";
import TaxDetailField from "../Tax/TaxDetailField";
import CoveragesDetailField from "./CoveragesDetailField";

const Bill = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const currency = props.currency;

  useEffect(() => {
    setValues(props.value);
  }, [props.value]);

  const setValues = (v) => {
    props.form.setFieldsValue(v);
    props.onChange(v);
  };
  const onChangePeriodicty = (v) => {
    if (!v) return;
    const values = props.form.getFieldsValue();
    values.periodicity = v;
    const period = v.substring(0, 1);

    if (period == "d") {
      const days = v.substring(1);
      values.installment = round2(values.anualTotal / (365 / days));
    }
    if (period == "w") {
      const weeks = v.substring(1);
      values.installment = round2(values.anualTotal / (52 / weeks));
    }
    if (period == "m") {
      const months = v.substring(1);
      values.installment = round2(values.anualTotal / (12 / months));
    }
    if (period == "y") {
      const years = v.substring(1);
      values.installment = round2(values.anualTotal / (1 / years));
    }
    setValues(values);
  };
  const periodicityOptions = props.config ? safeGet(["Premium", "periodicity"], props.config, null) : null;
  const values=props.form.getFieldsValue();
  return (
    <Form layout="vertical">
      <Row gutter={16}>
        <Col span={12}>
          {field("jFees")}
          {field("jTaxes")}
          <Form.Item label={t("Coverage Premiums")}>{field("coverages", {})(<CoveragesDetailField currency={currency} disabled jCoverages={props.change&&props.change.jNewCoverages||-1} />)}</Form.Item>
          <Form.Item label={t("Surcharges")}>{field("surcharges", {})(<Money currency={currency} disabled />)}</Form.Item>
          <Form.Item label={t("Discounts")}>{field("discounts", {})(<Money currency={currency} disabled />)}</Form.Item>
          <Form.Item label={t("Annual Premium")}>{field("anualPremium", {})(<Money currency={currency} disabled />)}</Form.Item>
          <Form.Item label={t("Tax")}>{field("tax", {})(<TaxDetailField currency={currency} jTaxes={values.jTaxes||-1} disabled />)}</Form.Item>
          <Form.Item label={t("Total")}>{field("anualTotal", {})(<Money currency={currency} disabled />)}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("Payment Method")}>{field("paymentMethod", {})(<PaymentMethods module="ISSUANCE" disabled/>)}</Form.Item>
          <Form.Item label={t("Premium Periodicity")}>
            {field("periodicity", {})(<Periodicty periodicityOptions={periodicityOptions} onChange={onChangePeriodicty} disabled />)}
          </Form.Item>
          <Form.Item label={t("Fee")}>{field("fee", {})(<FeeDetailField currency={currency} jFees={values.jFees||-1} disabled/>)}</Form.Item>
          <Form.Item label={t("Modal Premium")}>{field("installment", {})(<Money currency={currency} disabled/>)}</Form.Item>
          <Form.Item label={t("Fiscal Document Type")}>
            {field("receiptTypeCode")(<ReceiptTypeSelect  disabled/>)}
          </Form.Item>
          <Form.Item label={t("Fiscal Document Number")}>
            {field("fiscalNumber")(<Input disabled   />)}
          </Form.Item>
          <div style={{ visibility: "hidden" }}>
            <Form.Item>{field("id", {})(<Input />)}</Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

const onValuesChange = (props, changed, all) => {
  props.onChange(all);
};
export default Form.create({ onValuesChange: onValuesChange, name: "Bill" })(Bill);
