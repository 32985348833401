import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";

const ComTreeSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => load(), []);

    const load = () => {
        setLoading(true);
        exe("RepoComTree", {operation:"GET"}).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else {
                message.error(r.msg);
            }
        });
    };
    return (
        <div>
            <Select
                value={props.value}
                style={{width:"100%"}}
                onChange={props.onChange}
                loading={loading}
                disabled={ props.disabled}
                allowClear
                showSearch
                optionFilterProp="children">
                {data.map((v) => (
                    <Select.Option value={v.id} key={v.id}>
                        {v.name}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default ComTreeSelect;
