import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";

const EffectiveDate = (props) => {
  const [t] = useTranslation();
  const [dateSelected, setDateSelected] = useState(1);
  useEffect(() => {
    if (props.value) {
      if (props.value instanceof moment) {
        if (props.value.diff(props.anniversary, "day") == 0) {
          setDateSelected(1); //anniversary)
        } else setDateSelected(2); //custom
      } else {
        //not a moment object. convert
        props.onChange(moment(props.value));
      }
    } else {
      //default Values
      setDateSelected(1);
      onChangeDateOption(1);
    }
  }, [props.value]);

  const onChangeDateOption = (v) => {
    if (v == 1) {
      onChangeDate(moment(props.anniversary));
    } else {
      onChangeDate(moment());
    }
    setDateSelected(v);
  };
  const onChangeDate = (v) => {
    if (v) {
      if (!props.allowPastDates && v.isBefore(moment(), "day")) {
        v.validationError = t("Past dates are not allowed");
        props.onChange(v);
      } else {
        v.validationError = undefined;
        props.onChange(v);
      }
    } else {
      //v.validationError = "Effective date is required";
      props.onChange(undefined);
    }
  };
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <DatePickerW value={props.value} onChange={onChangeDate} disabled={props.disabled} />

      <Radio.Group style={{ marginLeft: 10 }} value={dateSelected} onChange={(e) => onChangeDateOption(e.target.value)} disabled={props.disabled}>
        <Radio value={1} style={{ display: "block" }}>
          {t("Next anniversary")}
        </Radio>
        <Radio value={2} style={{ display: "block" }}>
          {t("Custom")}
        </Radio>
      </Radio.Group>
    </div>
  );
};

export default EffectiveDate;
