import React, {useEffect, useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Row, Skeleton, Table, Typography, message } from "antd";
import DefaultPage from "../Shared/DefaultPage";
import {useTranslation} from "react-i18next";
import LanguageSelect from "./LanguageSelect";
import {exe, safeGetRaw} from "../../Lib/Dal";
import View from "../Bpm/View";

const SsFiles = (props) => {
    const [loadingOperations,setLoadingOperations]=useState(true);
    const [loading,setLoading]=useState(false);
    const [filesViewCode,setFilesViewCode]=useState();
    const [t]=useTranslation();


    useEffect(()=>{
        if(props.configProfile){
            const filesViewId=safeGetRaw(["configProfile","filesViewId"],props,null);
            if(!filesViewId) return;
            setLoading(true);
            exe("RepoLiveView",{operation:"GET",filter:`id=${filesViewId}`}).then(r=>{
                setLoading(false);
                if(r.ok){
                    const record=r.outData[0];
                    setFilesViewCode(record.code);
                }else message.error(r.msg);
            })
        }
    },[props.configProfile])

    return (
        <div>
            <DefaultPage
                title={ t("Files") }
                subTitle={<span>{props.contact&&props.contact.FullName}</span>}
                iconStyle={{ backgroundColor: safeGetRaw(["Style","primaryColor"],props.configProfile,"rgb(69,156,84)") }}
                routes={{
                    routes: [
                        { breadcrumbName: t("Home"), path: "/SelfService/home" },
                        { breadcrumbName: t("Files"), path: "files" },
                    ],
                }}
                icon="file"
                loading={loading}
                extra={<div></div>}>
                    <View code={filesViewCode} context={{contact:props.contact}} />
            </DefaultPage>
        </div>
    );
};

export default SsFiles;