import { InfoCircleOutlined } from '@ant-design/icons';
import {Button, message, Modal, Table, Tooltip} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import {formatMoney} from "../../Lib/Helpers";
import Money from "../Shared/Money";

const TaxDetailField = (props) => {
    const {t, i18n} = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [detailVisible, setDetailVisible] = useState(false);

    const onLoadDetail = () => {
        if (props.jTaxes) {
            setData(props.jTaxes===-1?[]:JSON.parse(props.jTaxes));
        } else {
            setLoading(true);   
            exe("GetTaxGenerated", {lifePolicyId: props.lifePolicyId}).then((r) => {
                setLoading(false);
                if (r.ok) {
                    setData(r.outData);
                } else {
                    message.error(r.msg);
                }
            });
        }
        setDetailVisible(true);
    }
    return (
        <div style={{display: "flex"}}>
            <Money value={props.value} onChange={props.onChange} currency={props.currency} disabled={props.disabled}/>
            <Tooltip title={t("Click for tax breakdown")}>
                <Button type="link" icon={<InfoCircleOutlined />} onClick={() => onLoadDetail()}/>
            </Tooltip>
            <Modal
                title={t("Tax Detail")}
                visible={detailVisible}
                onCancel={() => setDetailVisible(false)}
                footer={
                    <div>
                        <Button type="primary" onClick={() => setDetailVisible(false)}>
                            {t("Ok")}
                        </Button>
                    </div>
                }>
                <Table dataSource={data} loading={loading} size="middle" pagination={false}>
                    <Table.Column title={t("Name")} dataIndex="taxName"/>
                    <Table.Column title={t("Amount")} dataIndex="amount"
                                  render={(v) => formatMoney(v, props.currency)}/>
                    <Table.Column title={t("Action")} dataIndex="action"/>
                </Table>
            </Modal>
        </div>
    );
};

export default TaxDetailField;
