import React, { useEffect, useState } from "react";
import {
  CalendarOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  DeploymentUnitOutlined,
  DesktopOutlined,
  EditOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileOutlined,
  FileSyncOutlined,
  FolderOpenOutlined,
  LineChartOutlined,
  LoadingOutlined,
  PlusOutlined,
  RollbackOutlined,
  SafetyCertificateOutlined,
  SaveOutlined,
  ThunderboltOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Button, Dropdown, Input, InputNumber, Menu, message, Modal, Popconfirm, Table } from "antd";
import CalcSheetGrid from "./CalcSheetGrid";
import { exe } from "../../Lib/Dal";
import showPrompt from "./showPrompt";
import Inspector from "react-inspector";
import { useTranslation } from "react-i18next";
import RecalculateModal from "../Life/RecalculateModal";
import {formatDateShort} from "../../Lib/Helpers";

const CalcSheet = (props) => {
  const [t] = useTranslation();

  const [showLoad, setShowLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(null);
  const [data, setData] = useState([]);
  const [recalculateModalVisible,setRecalculateModalVisible]=useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    if (props.match.params.policyId) {
      loadPolicyActuarialSheet(props.match.params.policyId);
    }
  }, [props.match.params.policyId]);

  useEffect(() => {
    if (props.location.calcSheetId) {
      openRecord({ id: props.location.calcSheetId });
    }
  }, [props.location.calcSheetId]);

  const save = (saveAs) => {
    const jData = window.luckysheet.toJson();
    if (loaded && !saveAs) {
      setLoading(true);
      const data = { id: loaded.id, name: loaded.name, jData: JSON.stringify(jData) };
      exe("RepoCalcSheet", { operation: "UPDATE", entity: data }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setLoaded(r.outData[0]);
          message.success(r.msg);
        } else {
          message.error(r.msg);
        }
      });
    } else {
      showPrompt({
        title: t("Please enter document name"),
        placeholder: t("New name"),
        rules: [
          {
            required: true,
            message: t("You must enter name"),
          },
        ],
      }).then((newName) => {
        setLoading(true);
        const data = { id: 0, name: newName, jData: JSON.stringify(jData) };
        exe("RepoCalcSheet", { operation: "ADD", entity: data }).then((r) => {
          setLoading(false);
          if (r.ok) {
            setLoaded(r.outData[0]);
            message.success(r.msg);
          } else {
            message.error(r.msg);
          }
        });
      });
    }
  };
  const load = () => {
    setShowLoad(true);
    setLoading(true);
    exe("GetCalcSheets").then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onRename = () => {
    showPrompt({
      title: t("Please enter new name"),
      placeholder: t("New name"),
      rules: [
        {
          required: true,
          message: t("You must enter name"),
        },
      ],
    })
      .then((newName) => {
        loaded.name = newName;

        exe("RepoCalcSheet", { operation: "UPDATE", entity: loaded }).then((r) => {
          if (r.ok) {
            message.success(t("Name changed"));
            setData(data);
          } else {
            message.error(t("Error saving"));
          }
        });
      })
      .catch((e) => console.error(e));
  };
  const openRecord = (row) => {
    setLoading(true);
    exe("RepoCalcSheet", { operation: "GET", filter: "id=" + row.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        window.luckysheet.create(JSON.parse(r.outData[0].jData));
        setShowLoad(false);
        setLoaded(r.outData[0]);
      } else {
        message.error(r.msg);
      }
    });
  };
  const doSimulation = () => {
    setLoading(true);
    exe("DoCalcSheet", { name: loaded.name, simulation: true }).then((r) => {
      setLoading(false);
      Modal[r.ok ? "success" : "error"]({
        title: t("Simulation Output"),
        maskClosable: true,
        width: "60%",
        zIndex: 2000,
        content: (
          <div>
            <Inspector data={r} expandLevel={1} />
            <div style={{ marginTop: 10 }}>
              <Inspector table data={r.outData} />
            </div>
          </div>
        ),
        onOk() {},
      });
    });
  };
  const doSimulationAddSheet = () => {
    setLoading(true);
    exe("DoCalcSheet", { name: loaded.name, simulation: true }).then((r) => {
      setLoading(false);
      if (r.ok) {
        const normalizedData = r.outData.map((p) => p.map((q) => q.toString()));
        window.luckysheet.setSheetAdd({
          sheetObject: { name: "SimulationResult", data: normalizedData },
          order: window.luckysheet.getAllSheets().length,
        });
      } else {
        message.error(r.msg);
      }
    });
  };
  const doExcelSimulation = () => {
    setLoading(true);
    exe("DoCalcSheet", { name: loaded.name, simulation: true, excel: true }).then((r) => {
      setLoading(false);
      if (r.ok) {
        {
          const binary = atob(r.outData);
          const length = binary.length;
          const arrayBuffer = new ArrayBuffer(length);
          const uintArray = new Uint8Array(arrayBuffer);
          for (let i = 0; i < length; i++) {
            uintArray[i] = binary.charCodeAt(i);
          }
          const blob = new Blob([uintArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "SimulationResult.xlsx";
          link.click();
        }
      } else {
        message.error(r.msg);
      }
    });
  };
  const onDelete = () => {
    setLoading(true);
    exe("RepoCalcSheet", { operation: "DELETE", entity: { id: loaded.id } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        onNew();
      } else {
        message.error(r.msg);
      }
    });
  };
  const saveAs = () => {
    save(true);
  };
  const onNew = () => {
    window.luckysheet.create({ showinfobar: false });
    setLoaded(null);
  };
  const loadActuarialSheet = () => {
    showPrompt({
      title: t("Please enter policy ID"),
      placeholder: t("policy id"),
      rules: [
        {
          required: true,
          message: t("You must enter policy ID"),
        },
      ],
    }).then((policyId) => loadPolicyActuarialSheet(policyId));
  };

  const loadPolicyActuarialSheet = (policyId) => {
    setLoading(true);
    exe("RepoLifePolicy", { operation: "GET", filter: "id=" + policyId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        if (r.outData.length == 0) {
          message.error(`${t("Record")} ${policyId} ${t("not found")}`);
          return;
        } else {
          const loadedData = r.outData[0].jActuarialSheet;
          putDataToSheet(JSON.parse(loadedData ? loadedData : "[]"), "Policy " + policyId);
        }
      } else {
        message.error(r.msg);
      }
    });
  };

  const exportExcel = () => {
    setLoading(true);
    exe("SheetToExcel", { jData: JSON.stringify(window.luckysheet.toJson()) }).then((r) => {
      setLoading(false);
      if (r.ok) {
        //downloading data
        const binary = atob(r.outData);
        const length = binary.length;
        const arrayBuffer = new ArrayBuffer(length);
        const uintArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < length; i++) {
          uintArray[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([uintArray], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Export.xlsx";
        link.click();
      } else {
        message.error(r.msg);
      }
    });
  };
  const putDataToSheet = (data, sheetName) => {
    const normalizedData = data.map((p) => p.map((q) => (q == null ? null : q.toString())));
    window.luckysheet.setSheetAdd({
      sheetObject: { name: sheetName, data: normalizedData },
      order: window.luckysheet.getAllSheets().length,
    });
  };

  const doToday = (recalculate = false, save = false, background = false) => {
    if (!loaded) {
      message.error(t("Please load first an actuarial sheet"));
      return;
    }
    showPrompt({
      title: t("Please enter policy ID"),
      placeholder: t("policy id"),
      rules: [
        {
          required: true,
          message: t("You must enter policy ID"),
        },
      ],
    }).then((policyId) =>compute(policyId,recalculate,save,background));
  };
  const compute=(policyId,recalculate = false, save = false, background = false,startDate=null)=>{
    if(!loaded) {
      message.error(t("Please load an actuarial sheet first"));
      return;
    }
    setLoading(true);
    if (background) {
      exe("PutMessage", {
        batch: "Actuarial",
        value: `{cmd:"DoPolicyAccounts",data:{name:"${loaded.name}",save:${save},policyId:${policyId},recalculate:${recalculate},recalculationPeriodStart:${startDate?`"${startDate}"`:null}}}`,
      }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success("Actuarial operation successfully sent to event bus");
        } else message.error(r.msg);
      });
    } else {
      exe("DoPolicyAccounts", { name: loaded.name, save: save, policyId: policyId, recalculate: recalculate,recalculationPeriodStart:startDate }).then((r) => {
        setLoading(false);
        if (r.ok) {
          if (r.outData.length == 0) {
            message.error(`${t("Record")} ${policyId} ${t("not found")}`);
            return;
          } else {
            if (r.outData.transfers.length > 0) {
              const transfers = [Object.keys(r.outData.transfers[0]), ...r.outData.transfers.map((obj) => Object.values(obj))];
              putDataToSheet(transfers, "Transfers " + policyId + " EoD");
            }
            putDataToSheet(r.outData.actuarialSheet, "Policy " + policyId + " EoD");
          }
        } else {
          message.error(r.msg);
        }
      });
    }
  }
  const recalculatePeriod=values=>{
    values.start=formatDateShort(values.start);
    console.log(values);
    setRecalculateModalVisible(false);
    compute(values.policyId,true,true,values.background,values.start);
    }
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{position:"relative", padding: 8,zIndex:99999 }}>
          <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`${t("Search")} ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            {t("Search")}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {t("Reset")}
          </Button>
        </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex]&&record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end
  return (
    <div style={{ width: "100%", height: "100%", overflow: "hidden", paddingTop: 20 }} className="calcSheetStyle">
      <div style={{ display: "flex", paddingLeft: 0, position: "absolute", top: 50 }}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Button type="link" icon={<FileOutlined />} onClick={onNew}>
                  {t("New")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<FolderOpenOutlined />} onClick={load}>
                  {t("Open")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<SaveOutlined />} onClick={() => save()}>
                  {t("Save")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<SaveOutlined />} onClick={() => saveAs()}>
                  {t("Save As...")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<FileExcelOutlined />} onClick={() => exportExcel()}>
                  {t("Export to Excel")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<EditOutlined />} onClick={() => onRename()} disabled={!loaded}>
                  {t("Rename")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Popconfirm title={t("Are you sure you want to delete this file?")} onConfirm={onDelete}>
                  <Button type="link" icon={<DeleteOutlined />} disabled={!loaded}>
                    {t("Delete")}
                  </Button>
                </Popconfirm>
              </Menu.Item>
            </Menu>
          }>
          <Button type="link" icon={<FileOutlined />} loading={loading}>
            {t("File")}
          </Button>
        </Dropdown>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Button type="link" icon={<PlusOutlined />} onClick={doSimulationAddSheet} disabled={!loaded}>
                  {t("To New Sheet")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<DesktopOutlined />} onClick={doSimulation} disabled={!loaded}>
                  {t("To Debug Screen")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<FileExcelOutlined />} onClick={doExcelSimulation} disabled={!loaded}>
                  {t("To Excel")}
                </Button>
              </Menu.Item>
            </Menu>
          }>
          <Button type="link" icon={<LineChartOutlined />}>
            {t("Simulation")}
          </Button>
        </Dropdown>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item>
                <Button type="link" icon={<CalendarOutlined />} onClick={loadActuarialSheet}>
                  {t("Load Actuarial Sheet")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<ThunderboltOutlined />} onClick={() => doToday()} disabled>
                  {t("Simulate Daily Update")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<RollbackOutlined />} onClick={() => doToday(true)} disabled>
                  {t("Simulate Recalculate")}
                </Button>
              </Menu.Item>
              <Menu.Item>
                <Popconfirm
                  title={t(
                    "Warning, this operation will redo the policy's actuarial sheet and all policy's actuarial movements. Are you sure you want to proceed?"
                  )}
                  onConfirm={() => doToday(true, true)}>
                  <Button type="link" icon={<FileSyncOutlined />} disabled={localStorage.esAdmin!=='true'}>
                    {t("Recalculate & Save")}
                  </Button>
                </Popconfirm>
              </Menu.Item>
              <Menu.Item>
                <Popconfirm
                  title={t(
                    "Warning, this operation will redo the policy's actuarial sheet and all policy's actuarial movements. Are you sure you want to proceed?"
                  )}
                  onConfirm={() => doToday(true, true, true)}>
                  <Button type="link" icon={<DeploymentUnitOutlined />}>
                    {t("Recalculate & Save In Background")}
                  </Button>
                </Popconfirm>
              </Menu.Item>
              <Menu.Item>
               
                  <Button type="link" icon={<ClockCircleOutlined />} onClick={() => setRecalculateModalVisible(true)}>
                    {t("Recalculate & Save Period")}
                  </Button>
              </Menu.Item>
              <Menu.Item>
                <Button type="link" icon={<FileDoneOutlined />} onClick={() => doToday(false, true)} disabled>
                  {t("Daily Update & Save")}
                </Button>
              </Menu.Item>  
              <Menu.Item>
                <Button type="link" icon={<FileDoneOutlined />} onClick={() => doToday(false, true,true)} >
                  {t("Daily Update & Save In Background")}
                </Button>
              </Menu.Item>
            </Menu>
          }>
          <Button type="link" icon={<SafetyCertificateOutlined />}>
            {t("Policy")}
          </Button>
        </Dropdown>
        {loading && (
          <span style={{ marginTop: 8, marginLeft: 8 }}>
            <LoadingOutlined style={{ fontSize: 18, marginRight: 8 }} />
            {t("Loading...")}
          </span>
        )}
      </div>
      <CalcSheetGrid />
      <Modal title={t("Load")} visible={showLoad} onCancel={() => setShowLoad(false)} zIndex={1010} maskStyle={{ zIndex: 1005 }}>
        <Table dataSource={data} rowKey="id" loading={loading}>
          <Table.Column title={t("Id")} dataIndex="id" {...getColumnSearchProps("id")}  />
          <Table.Column title={t("Name")} dataIndex="name" {...getColumnSearchProps("name")}  />
          <Table.Column
            title={t("Action")}
            render={(v, r) => (
              <Button type="link" onClick={() => openRecord(r)}>
                {t("Open")}
              </Button>
            )}
          />
        </Table>
      </Modal>
      <RecalculateModal visible={recalculateModalVisible} onCancel={()=>setRecalculateModalVisible(false)} onOk={values=>recalculatePeriod(values)} />
    </div>
  );
};

export default CalcSheet;
