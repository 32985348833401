import React, {useEffect, useState} from 'react';
import {message, Modal, Table} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import {formatMoney} from "../../Lib/Helpers";

const CashierIncomeSummary = ({visible,onCancel,onOk,workspaceId}) => {
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState();
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(workspaceId&&visible) load();
    },[visible]);
    
    const load=()=> {
        setLoading(true);
        exe("GetCashierIncomeSummary",{workspaceId:workspaceId}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData.summary);
            }else message.error(r.msg);
        })
    }
    
    
    return (
        <div>
            <Modal visible={visible} onCancel={onCancel} onOk={onOk} title={t("Income Summary")} width={800}>
                <Table dataSource={data} loading={loading} rowKey="code" pagination={false} >
                    <Table.Column dataIndex="paymentMethodName" title={t("Payment Method")} />
                    <Table.Column dataIndex="currency" title={t("Currency")} />
                    <Table.Column dataIndex="cashFund" title={t("Cash fund")} render={(v,r)=>formatMoney(v,r.currency)} />
                    <Table.Column dataIndex="amount" title={t("Amount")} render={(v,r)=>formatMoney(v,r.currency)} />
                    <Table.Column dataIndex="deposit" title={t("Deposit")} render={(v,r)=>formatMoney(v,r.currency)} />
                    <Table.Column dataIndex="dif" title={t("Difference")} render={(v,r)=><span style={{color:v?"red":"green"}}>{formatMoney(v,r.currency)}</span>} />
                </Table>
            </Modal>
        </div>
    );
};

export default CashierIncomeSummary;