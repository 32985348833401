import React, {useEffect, useState} from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Checkbox,
    Input,
    InputNumber,
    message,
    Steps,
    Descriptions,
} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import moment from "moment";
import {formatNumber} from "../../Lib/Helpers";
import DatePickerW from "../Shared/DatePickerW";

const CalculationSchemeExe = (props) => {
    const [loading, setLoading] = useState(false);
    const [scheme, setScheme] = useState();
    const [step, setStep] = useState(0);
    const [results, setResults] = useState({});
    const [iteratorResults, setIteratorResults] = useState([]); 
    const [parameters, setParameters] = useState({});
    const [config, setConfig] = useState({});
    const [t] =useTranslation();
    const { Step } = Steps;
    
    useEffect(() => {
        if(props.scheme){
            //loading from props
            setScheme(props.scheme);
            iniForm(props.scheme);
        }else{
            //loading from server
            load(props.schemeId);
        }
    }, []);

    useEffect(() => {
        if(props.showResult) load(props.schemeId);//we need to load scheme to show results
    }, [props.showResult]);
    
    const load = (schemeId) => {
        if(!schemeId){
            message.error("schemeId is not provided");
            return;
        }
        exe("RepoCalculationScheme", { operation: "GET", filter:`id=${schemeId}` }).then((r) => {
            if (r.ok) {
                if(r.outData.length>0) {
                    setScheme(r.outData[0]);
                    iniForm(r.outData[0]);
                    if(props.showResult) renderPropResults(); //triggered from useEffect
                }
            } else {
                message.error(r.msg);
            }
        });
    }
    const renderPropResults=()=>{
        //loading from props
        const parsedDetail=JSON.parse(props.showResult.jDetail);
        console.log("parsedDetail",parsedDetail);
        const resultsCopy = {...parsedDetail};
        //excluding iteratorResults array from object
        delete resultsCopy.iteratorResults;
        setResults(resultsCopy);
        setIteratorResults(parsedDetail.iteratorResults);
        setStep(1);
    }
    const iniForm=(schemeRecord)=>{
        const config = JSON.parse(schemeRecord.configJson);
        setConfig(config);
        //creating object with default values
        var fields=config.Parameters.map(p=>p.name);
        const defaultValues = {};
        fields.forEach(f=>{
            //checking if value is available in context
            if(props.context&&props.context[f]) {
                defaultValues[f] = props.context[f];
            }else {
                defaultValues[f] = null
            }
        });
        //setting formula default values
        var formulas=config.Parameters.filter(p=>p.formula);
        if(formulas.length>0){
            formulas.forEach(f=>{
                //executing formula
                exe("ExeFormula",{formula:f.formula,context:JSON.stringify(props.context)}).then(r=>{
                    if(r.ok&&r.outData.length>0) {
                        defaultValues[f.name]=r.outData[0];
                        setParameters(defaultValues);
                    }
                    else console.error("Error executing formula",f.formula,r.msg);
                });
            });
        }else setParameters(defaultValues);
    }
    const renderScheme=()=> {
        if (!scheme) return null;
        const config = JSON.parse(scheme.configJson);
        const controlArray = [];
        config.Parameters.forEach(p=>{
            switch (p.type) {
                case "number":
                    controlArray.push(<Form.Item label={p.label} required><InputNumber disabled={p.disabled} value={parameters[p.name]} onChange={(v)=>setParameters({...parameters,[p.name]:v})}/></Form.Item>) ;
                    break;
                case "string":
                    controlArray.push(<Form.Item label={p.label} required><Input disabled={p.disabled} style={{marginTop:5}} value={parameters[p.name]} onChange={(v)=>setParameters({...parameters,[p.name]:v})}/></Form.Item>) ;
                    break;
                case "boolean":
                    controlArray.push(<Form.Item label={p.label} required><Checkbox disabled={p.disabled} style={{marginTop:5}} checked={parameters[p.name]} onChange={(e)=>setParameters({...parameters,[p.name]:e.target.checked})}/></Form.Item>) ;
                    break;
                case "date":
                    controlArray.push(<Form.Item label={p.label} required><DatePickerW disabled={p.disabled} style={{marginTop:5,width:120}} value={parameters[p.name]} onChange={(v)=>setParameters({...parameters,[p.name]:v})}/></Form.Item>) ;
                    break;
                default:
                    controlArray.push(<Form.Item label={p.label} required><Input disabled={p.disabled} style={{marginTop:5}} value={parameters[p.name]} onChange={(v)=>setParameters({...parameters,[p.name]:v})}/></Form.Item>) ;
                    break;
            }
        })
        return <div style={{display:"flex",flexDirection:"column",marginTop:10}}> {controlArray}</div>
    }
    const executeScheme=()=> {
        const parsedParameters = {...parameters};
        //converting dates from moment to string
        let hasError=false;
        Object.keys(parsedParameters).forEach(k=>{
            //checking all parameters have values
            if(!parsedParameters[k]){
                message.error(t("Please fill all fields"));
                hasError=true;
            }
            if(parsedParameters[k] instanceof moment){
                parsedParameters[k]=parsedParameters[k].format("YYYY-MM-DD");
            }
        })
        if(hasError) return;
        
        console.log("Executing scheme",scheme,parsedParameters);
        setLoading(true);
        exe("ExeCalculationScheme",{schemeId:scheme.id,parameters:parsedParameters}).then(r=>{
            setLoading(false);
            if(r.ok){
                setResults(r.outData.results)
                setIteratorResults(r.outData.iteratorResults)
                setStep(1);
            }else{
                message.error(r.msg);
            }
        });
    }
    
    const renderResults=(results)=> {
        if(!results) return null;
        const config = JSON.parse(scheme.configJson);
        const controlArray = [];
        //iterate over results and render them
        Object.keys(results).forEach(k=> {
            const resultLabel= config.Results.find(p=>p.name===k);
            const label = resultLabel?resultLabel.label:k;
            const resultWithFormat=isNaN(results[k])?results[k]:formatNumber(results[k]);   //apply number format if result is a number
            controlArray.push(<Descriptions.Item label={label}>{resultWithFormat}</Descriptions.Item>);
        })
        return <Descriptions style={{marginTop:15}} bordered column={1}>{controlArray}</Descriptions>
    }
    const renderIteratorResults=(iteratorResults)=> {
        if(!iteratorResults) return null;
        const config = JSON.parse(scheme.configJson);
        const controlArray = [];
        //iterate over each item of iterator
        iteratorResults.forEach((item,index)=> {
            //iterate over results and render them
            Object.keys(item).forEach(k=> {
                const resultLabel= config.Iterator.ItemResults.find(p=>p.name===k);
                const label = resultLabel?resultLabel.label:k;
                const resultWithFormat=isNaN(item[k])?item[k]:formatNumber(item[k]);   //apply number format if result is a number
                if(k==="title"){
                    controlArray.push(<Descriptions.Item label={<b>{label}</b>}>{<b>{resultWithFormat}</b>}</Descriptions.Item>);
                }else controlArray.push(<Descriptions.Item label={label}>{resultWithFormat}</Descriptions.Item>);
            })
            //adding empty item to separate each item of iterator, except last item
            if(index<iteratorResults.length-1) controlArray.push(<Descriptions.Item span={1} />);
        }
        )
        return controlArray.length>0?<Descriptions style={{marginTop:15}} bordered column={1}>{controlArray}</Descriptions>:null;
    }
    
    return (
        <div>
            <Steps current={step}>
                <Step title={t("Input")} />
                <Step title={t("Results")} />
            </Steps>
            {step===0&&<div>
                {renderScheme()}
                <Button style={{marginTop:10}} type={"primary"} loading={loading} onClick={executeScheme}>{t("Next")}<ArrowRightOutlined /></Button>
            </div>}
            {step===1&&<div>
                {renderIteratorResults(iteratorResults)}
                {renderResults(results)}
                <Button style={{marginTop:10}} type={"link"} loading={loading} onClick={()=>setStep(0)} icon={<LegacyIcon type={"arrow-left"} />}>{t("Back")}</Button>
                {props.onResult&&<Button style={{marginTop:10}} type={"primary"} loading={loading} onClick={()=>props.onResult({...results,iteratorResults:iteratorResults,parameters:parameters})}>{t("Next")}<ArrowRightOutlined /></Button>}
            </div>}
        </div>
    );
};

export default CalculationSchemeExe;