import React, { useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import Log from "./Log";
import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const LogExplorer = () => {
  const [t] = useTranslation();
  const [refresh, doRefresh] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);

  return (
    <div>
      <DefaultPage
        title={t("System Log")}
        icon="audit"
        extra={
          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        }>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => doRefresh(refresh + 1)}>
          {t("Refresh")}
        </Button>
        <Log refresh={refresh} filterVisible={filterVisible} onCloseFilter={() => setFilterVisible(false)} />
      </DefaultPage>
    </div>
  );
};

export default LogExplorer;
