import { Button, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, fetchProxy } from "../../Lib/Dal";
import FileUpload from "../Life/FileUpload";

const FileSelect = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [manual, setManual] = useState(false);
  
  useEffect(() => getTemplates(), []);

  const getTemplates = () => {
    fetchProxy("/uploaded", "GET").then((r) => {
        if(r.ok){
            setData(r.outData || []);
        }else console.error(r.msg);
    });
  };
  const onFileUpload = (files) => {
    getTemplates();
    console.log(files);
    props.onChange("uploaded/"+files[0].fileName);
    }
  return (
    <div>
      {!manual&&<Select
        style={{ width: "100%" }}
        allowClear
        placeholder={t("Select an uploaded File")}
        onChange={(v) => props.onChange("uploaded/"+v)}
        showSearch
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        value={props.value?props.value.replace("uploaded/",""):undefined}
        loading={props.loading}
        disabled={props.disabled}>
        {data.map((p) => (
          <Select.Option value={p.name} key={p.name}>
            {p.name}
          </Select.Option>
        ))}
      </Select>}
      {manual&&<Input value={props.value} onChange={(e)=>props.onChange(e.target.value)} placeholder={t("Specify a custom path")}/>}
      <Button type="link" style={{paddingRight:0,paddingLeft:0}} onClick={()=>setManual(!manual)}>{manual?t("Show file selector"):t("Show custom path")}</Button> 
      <FileUpload onChange={onFileUpload} />
    </div>
  );
};

export default FileSelect;
