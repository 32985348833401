import { ReloadOutlined } from '@ant-design/icons';
import { Button, Divider, message, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../Lib/Helpers";
import ReactDiffViewer from "react-diff-viewer";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";

const ProductHistory = (props) => {
  const [t] = useTranslation();
  const [diffVisible, setDiffVisible] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
  const currentVersion = props.current ? props.current : null;

  const onDiff = (record) => {
    setSelected(record);
    setDiffVisible(true);
  };
  useEffect(() => {
    if (props.productCode) load(props.productCode);
  }, [props.productCode, pagination.current]);

  const load = (productCode) => {
    setLoading(true);
    exe("RepoProductHistory", { operation: "GET", filter: `code='${productCode}'`, size: pagination.pageSize, page: pagination.current - 1 }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        setPagination({ ...pagination, total: r.total });
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => load(props.productCode)}>
        {t("Reload")}
      </Button>
      <Table dataSource={data} loading={loading} pagination={pagination} onChange={(pag) => setPagination(pag)}>
        <Table.Column title={t("Date")} dataIndex="created" render={(v) => formatDate(v)} />
        <Table.Column title={t("User")} dataIndex="user" />
        <Table.Column
          title={t("Action")}
          dataIndex="id"
          render={(v, r) => (
            <div>
              <Button type="link" onClick={() => props.onSelect(r)}>
                {t("Open")}
              </Button>
              <Divider type="vertical" />
              <Button type="link" onClick={() => onDiff(r)}>
                {t("Diff")}
              </Button>
            </div>
          )}
        />
      </Table>
      <Modal visible={diffVisible} width="90%" onCancel={() => setDiffVisible(false)} onOk={() => setDiffVisible(false)}>
        <ReactDiffViewer oldValue={selected ? selected.config : null} newValue={currentVersion} splitView={true} />
      </Modal>
    </div>
  );
};

export default ProductHistory;
