import { MailOutlined } from '@ant-design/icons';
import { Button, Divider, message, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../Lib/Helpers";
import HtmlTemplateForm from "../Settings/HtmlTemplateForm";
import MessageAttachment from "./MessageAttachment";
import Title from "antd/lib/typography/Title";
import { useTranslation } from "react-i18next";
import NewCommunication from "./NewCommunication";
import { exe } from "../../Lib/Dal";

const CommunicationsData = (props) => {
  const [t, i18n] = useTranslation();
  const [selected, setSelected] = useState(null);
  const [viewSelected, setViewSelected] = useState(null);
  const [newCommunicationVisible, setNewCommunicationVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });

  useEffect(() => {
    if (props.all) load();
  }, [pagination.current]);

  useEffect(() => {
    if (props.contactId) load();
  }, [props.contactId,pagination.current]);

  const onPreview = (r) => {
    setSelected({ name: r.templateName, template: r.template, testContext: r.context });
  };
  const onViewMessage = (r) => {
    setViewSelected({ subject: r.subject, message: r.message });
  };
  const load = () => {
    const filter = props.all ? "" : `contactId=${props.contactId}`;
    setLoading(true);
    exe("RepoMessage", { operation: "GET", filter: filter, size: pagination.pageSize, page: pagination.current - 1 }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        setPagination({ ...pagination, total: r.total });
      } else message.error(r.msg);
    });
  };
  const onNewMessage = (r) => {
    setNewCommunicationVisible(false);
    load();
  };
  return (
    <div>
      <Button type="link" icon={<MailOutlined />} onClick={() => setNewCommunicationVisible(true)} disabled={!props.contactId}>
        {t("New")}
      </Button>
      <Table dataSource={data} rowKey="id" loading={loading} pagination={pagination} onChange={(pag) => setPagination(pag)}>
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("Contact")} dataIndex="contactId" render={(v) => <a href={"#/contact/" + v}>{v}</a>} />
        <Table.Column title={t("Template")} dataIndex="templateName" />
        <Table.Column title={t("Channel")} dataIndex="channel" />
        <Table.Column title={t("Created")} dataIndex="created" render={(d) => formatDate(d)} />
        <Table.Column title={t("Delivered")} dataIndex="delivered" render={(d) => (d ? formatDate(d) : "Not yet")} />
        <Table.Column title={t("Attachments")} dataIndex="Attachments" render={(v) => <MessageAttachment value={v} />} />
        <Table.Column
          title={t("Preview")}
          key="preview"
          dataIndex="id"
          render={(v, r) => (
            <div>
              <Button type="link" onClick={() => onViewMessage(r)}>
                {t("Message")}
              </Button>
              <Divider type="vertical" />
              <Button type="link" onClick={() => onPreview(r)}>
                {t("Template")}
              </Button>
            </div>
          )}
        />
      </Table>
      <Modal visible={selected} onCancel={() => setSelected(null)} width={800} onOk={() => setSelected(null)}>
        <HtmlTemplateForm record={selected} readOnly />
      </Modal>
      <Modal visible={viewSelected} onCancel={() => setViewSelected(null)} width={800} onOk={() => setViewSelected(null)}>
        {viewSelected && (
          <div>
            <Title level={4}>{viewSelected.subject}</Title>
            <div
              style={{ borderRadius: 4, margin: 5, padding: 5, borderStyle: "solid", borderColor: "#ccc", borderWidth: "1px" }}
              dangerouslySetInnerHTML={{ __html: viewSelected.message }}></div>
          </div>
        )}
      </Modal>
      <NewCommunication contactId={props.contactId} visible={newCommunicationVisible} onCancel={() => setNewCommunicationVisible(false)} onOk={onNewMessage} />
    </div>
  );
};

export default CommunicationsData;
