import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const PolicyRoleSelect = (props) => {
  const [t] = useTranslation();

  return (
    <div>
      <Select {...props}>
        <Select.Option value="INS">{t("Insured")}</Select.Option>
        <Select.Option value="POL">{t("Policyholder")}</Select.Option>
        <Select.Option value="COI">{t("Co-Insured")}</Select.Option>
      </Select>
    </div>
  );
};

export default PolicyRoleSelect;
