import React, {useEffect, useState} from 'react';
import {Button, Checkbox, message, Modal, Skeleton, Statistic, Table, Tag, Tooltip} from "antd";
import Operations from "../Financial/Operations";
import DefaultPage from "../Shared/DefaultPage";
import Status from "../Shared/Status";
import {formatDateShortUtc, formatDateUtc, formatMoney, getColor} from "../../Lib/Helpers";
import {useTranslation} from "react-i18next";
import View from "../Bpm/View";
import {exe, safeGetRaw} from "../../Lib/Dal";

const SsCredits = (props) => {
    const [loadingOperations, setLoadingOperations] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedPremiums, setSelectedPremiums] = useState([]);
    const [payModalVisible, setPayModalVisible] = useState(false);
    const [paymentViewCode, setPaymentViewCode] = useState();
    const [t] = useTranslation();
    
useEffect(()=>init(),[props.configProfile])
    
    
    const init = () => {
        //loading custom view defined in config profile
        if(props.configProfile&&props.configProfile.paymentViewId){
            setLoading(true);
            exe("RepoLiveView",{operation:"GET",filter:`id=${props.configProfile.creditPaymentViewId}`}).then(r=>{
                setLoading(false);
                if(r.ok){
                    const record=r.outData[0];
                    setPaymentViewCode(record.code);
                }else message.error(r.msg);
            })
        }
    }
    
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedPremiums(selectedRows);
        },
        selectedRowKeys: selectedPremiums.map(p=>p.id),
    }
    const isDisabled=name=>{
        //checking if the button is disabled by configuration
        if(props.configProfile && props.configProfile.disabledActions){
            return props.configProfile.disabledActions.includes(name);
        }
        return false;
    }

    const pastDate = (date) => new Date(date) < new Date();
    return (
        <div>
            <DefaultPage
                title={t("My Credits")}
                subTitle={<span>{t("Credit Installments")}</span>}
                iconStyle={{ backgroundColor: safeGetRaw(["Style","primaryColor"],props.configProfile,"rgb(69,156,84)") }}
                routes={{
                    routes: [
                        {breadcrumbName: t("Home"), path: "/SelfService/home"},
                        {breadcrumbName: t("Credits"), path: "credits"},
                    ],
                }}
                icon="global"
                loading={loading}
                extra={<div style={{display:"flex",alignItems:"center",height:69}}>
                    {selectedPremiums.length>0&&selectedPremiums.every(p=>p.currency===selectedPremiums[0].currency)&&<Statistic title={t("Selected Installments")} value={formatMoney(selectedPremiums.reduce((p,c)=>p+c.amount,0),selectedPremiums[0].currency)} />}

                    <Button type={"primary"} style={{marginLeft:20}} disabled={!selectedPremiums.length||isDisabled("payCredit")} onClick={()=>setPayModalVisible(true)}>{t("Pay")}</Button>
            </div>}>

                <Table dataSource={props.credits} rowKey={"id"} rowSelection={rowSelection}>
                    <Table.Column title={t("Ref")} dataIndex="id"/>
                    <Table.Column title={t("Credit")} dataIndex="creditId"/>
                    <Table.Column title={t("Num")} dataIndex="num"  />
                    <Table.Column title={t("Date Due")} dataIndex="dueDate" render={(v, r) => (!r.paid && pastDate(v) ? <Tag color="red">{formatDateShortUtc(v)}</Tag> : formatDateShortUtc(v))} />
                    <Table.Column title={t("Capital")} dataIndex="capital" render={(v,r)=>formatMoney(v,r.currency)} />
                    <Table.Column title={t("Interest")} dataIndex="interest" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Value")} dataIndex="value" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Insurance")} dataIndex="insurance" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Expenses")} dataIndex="expenses" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Total")} dataIndex="amount" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Balance")} dataIndex="balance" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Paid")} dataIndex="paid" render={v=><Checkbox checked={v} /> } />
                    <Table.Column title={t("Date Paid")} dataIndex="paidDate" render={v=>v?formatDateShortUtc(v):"-" } />

                </Table>
                <Modal title={t("Installment Payment")} visible={payModalVisible} onCancel={()=>setPayModalVisible(false)} okButtonProps={{style:{display:"none"}}} >
                    <View code={paymentViewCode} context={{installments:selectedPremiums,close:()=>setPayModalVisible(false)}} />
                </Modal>
            </DefaultPage>
        </div>
    );
};

export default SsCredits;