import { Descriptions } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatDate, formatDateShort } from "../../Lib/Helpers";
import ContactLink from "../Contact/ContactLink";
import MultiContactSelect from "../Contact/MultiContactSelect";
import ClaimPolicyLife from "../Health/ClaimPolicyLife";
import Currency from "../Shared/Currency";
import Money, { formatter } from "../Shared/Money";
import BillingStatus from "./BillingStatus";
import Coverages from "./Coverages";
import PaymentPlan from "./PaymentPlan";

const PolicySnapshot = (props) => {
  const [t] = useTranslation();
  const [policy, setPolicy] = useState(null);
  useEffect(() => {
    if (props.value) {
      const pol = JSON.parse(props.value);
      if(pol.Product) pol.config = JSON.parse(pol.Product.configJson);else pol.config=props.config;//using current config if config at change time not available
      setPolicy(pol);
      console.log(pol,"POLICY SNAPSHOT");
    }
  }, [props.value]);
  if (!policy) return null;
  return (
    <div>
      <Title level={4}>{t("Policy Summary")}</Title>
      <Descriptions column={3} bordered size="small">
        <Descriptions.Item label={t("Insured Sum")}>{formatter(policy.currency).format(policy.insuredSum)}</Descriptions.Item>
        <Descriptions.Item label={t("Start")}>{formatDateShort(policy.start)}</Descriptions.Item>
        <Descriptions.Item label={t("End")}>{formatDateShort(policy.end)}</Descriptions.Item>
      </Descriptions>
      <Title style={{ marginTop: 5 }} level={4}>
        {t("Premiums")}
      </Title>
      <PaymentPlan
        value={policy.PayPlan}
        parsedValues={props.parsedValues}
        policyId={policy.id}
        currency={policy.currency}
        hasPremiumPlan={false}
        policyType={policy.policyType}
        config={policy.config}
      />
      <Title style={{ marginTop: 5 }} level={4}>
        {t("Coverages")}
      </Title>
      <Coverages policy={policy} coverages={policy.Coverages} currency={policy.currency} readOnly config={policy.config} />
      <Title style={{ marginTop: 5 }} level={4}>
        {t("Beneficiaries")}
      </Title>
      <MultiContactSelect disabled showPercentage value={policy.Beneficiaries} />
    </div>
  );
};

export default PolicySnapshot;
