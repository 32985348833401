import React from "react";
import { CaretRightOutlined, SearchOutlined, UndoOutlined, WarningTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Card,
  Table,
  Menu,
  Dropdown,
  Breadcrumb,
  Tag,
  Row,
  Col,
  Input,
  Drawer,
  Radio,
  Switch,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import { get, exe, safeGet } from "../../Lib/Dal";
import ActividadBuscar from "./ActividadadBuscar";
import moment from "moment";
import DefaultPage from "../Shared/DefaultPage";
import { withTranslation } from "react-i18next";
import { formatDate } from "../../Lib/Helpers";

const ButtonGroup = Button.Group;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const Now = moment();

class Actividades extends React.Component {
  state = {
    data: [],
    filtroVisible: false,
    currentUser: undefined,
    pagination: {
      pageSize: 10,
    },
  };
  filtro = {
    grupos: "mios",
    finalizado: false,
  };
  componentWillMount() {
    //viene desde launcher
    if (this.props.location.proceso) {
      const p = this.props.location.proceso;
      this.filtro.proceso = p.nombre;
    }
    this.getCurrentUser();
  }
  getCurrentUser() {
    exe("GetCurrentUser").then((r) => {
      if (r.ok) {
        this.setState({ currentUser: r.outData });
        this.getData();
      }
    });
  }

  getData = (page = 1, filtro) => {
    this.setState({ loading: true });
    console.log(this.filtro, "filtro");

    let filter = "1=1";
    if (this.filtro.grupos == "mios" && this.state.currentUser.Groups.length > 0) {
      filter = filter + " AND grupoId in (" + this.state.currentUser.Groups.map((p) => p.usrGroupId).join(",") + ")";
    }
    if (typeof this.filtro.grupos == "object" && this.filtro.grupos.length > 0) {
      filter = filter + " AND grupoId in (" + this.filtro.grupos.join(",") + ")";
    }
    if (this.filtro.responsable) {
      filter = filter + " AND responsable='" + this.filtro.responsable + "'";
    }
    if (this.filtro.usuario) {
      filter = filter + " AND usuario='" + this.filtro.usuario + "'";
    }
    if (this.filtro.unAssigned !== undefined) {
      filter = filter + ` AND responsable ${this.filtro.unAssigned ? "IS" : "IS NOT"}  NULL`;
    }
    if (this.filtro.id !== undefined) {
      filter = filter + ` AND id=${this.filtro.id}`;
    }
    if (this.filtro.eventsDisabled !== undefined) {
      filter = filter + ` AND eventsDisabled=${this.filtro.eventsDisabled ? 1 : 0}`;
    }
    if (this.filtro.expirationRange) {
      filter =
        filter +
        ` AND EXISTS (SELECT procesoId as id,sla,MAX(Id) as stepId from ProcesoPaso o WHERE proceso.id=o.procesoId 
        GROUP BY procesoId,sla,fecha HAVING sla>0 AND DATEADD(HOUR,sla,fecha) BETWEEN '${this.filtro.expirationRange[0].format(
          "YYYY-MM-DD"
        )}' and '${this.filtro.expirationRange[1].format("YYYY-MM-DD")}')`;
    }
    if (this.filtro.created) {
      filter = filter + ` AND fInicio BETWEEN '${this.filtro.created[0].format("YYYY-MM-DD")}' and '${this.filtro.created[1].format("YYYY-MM-DD")}'`;
    }
    if (this.filtro.inStatus) {
      filter =
        filter +
        ` AND EXISTS (SELECT procesoId as id,fecha,MAX(Id) as stepId from ProcesoPaso o WHERE proceso.id=o.procesoId 
        GROUP BY procesoId,fecha HAVING fecha BETWEEN '${this.filtro.inStatus[0].format("YYYY-MM-DD")}' and '${this.filtro.inStatus[1].format("YYYY-MM-DD")}')`;
    }
    if(this.filtro.estado&&this.filtro.estado.length>0){
      filter=filter+` AND estado in (${this.filtro.estado.map(p=>"'"+p+"'").join(",")})`
    }
    if(this.filtro.etiquetas&&this.filtro.etiquetas.length>0){
      filter=filter+` AND id in (SELECT procesoId from ProcesoEtiqueta WHERE nombre in (${this.filtro.etiquetas.map(p=>"'"+p+"'").join(",")}))`
    }
    if(this.filtro.proceso){
      filter=filter+` AND definitionId=${this.filtro.proceso}`
    }
    //groups from search panel
    if(this.filtro.groups&&this.filtro.groups.length>0){
      filter=filter+` AND grupoId in (${this.filtro.groups.join(",")})`
    }
    if(this.filtro.finalizado!==undefined){
        filter=filter+` AND finalizado=${this.filtro.finalizado?1:0}`
    }
    if(this.filtro.vencido!==undefined) {
      //records with expiration date in the past
        filter=filter+` AND dueDate is not null AND dueDate<GETUTCDATE()`
    }

    exe("GetProcesses", {
      filter: filter,
      include: ["Etiquetas", "UsuarioResponsable", "Grupo", "Pasos"],
      size: 10,
      page: page - 1,
    }).then((r) => {
      const pagination = { ...this.state.pagination };
      pagination.total = r.total;
      this.setState({ data: r.outData, loading: false, pagination });
    });
  };
  onClickBuscar = () => {
    this.setState({ filtroVisible: true });
  };
  onCloseFiltro = () => {
    this.setState({ filtroVisible: false });
  };
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    console.log(pager, "PAGINATION");
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });

    this.getData(pagination.current);
  };
  onSearch = (filtro) => {
    this.filtro = filtro;
    this.getData(undefined, filtro);
  };
  filtroResponsable = (e) => {
    const tipo = e.target.value;
    if (tipo == "mias") {
      this.filtro.responsable = this.state.currentUser.email;
      delete this.filtro.grupos;
      delete this.filtro.usuario;
    } else if (tipo == "enviadas") {
      this.filtro.usuario = this.state.currentUser.email;
      delete this.filtro.grupos;
      delete this.filtro.responsable;
    } else if (tipo == "misGrupos") {
      this.filtro.grupos = "mios";
      delete this.filtro.responsable;
      delete this.filtro.usuario;
    } else if (tipo == "todas") {
      delete this.filtro.grupos;
      delete this.filtro.responsable;
      delete this.filtro.usuario;
    }
    this.getData();
  };
  filtroFinalizadas = (v) => {
    this.filtro.finalizado = !v;
    this.getData();
  };
  onUnassigned = (v) => {
    if (v) {
      this.filtro.unAssigned = v;
    } else {
      delete this.filtro.unAssigned;
    }
    this.getData();
  };
  onClickReset = () => {
    this.filtro = { grupos: "mios" };
    this.getData();
  };

  columns = [
    {
      title: this.props.t("ID"),
      dataIndex: "id",
    },
    {
      title: this.props.t("Process"),
      dataIndex: "nombre",
    },
    {
      title: this.props.t("Status"),
      dataIndex: "estado",
      render: (v) => <Tag color="#87d068">{v}</Tag>,
    },
    {
      title: this.props.t("Completed"),
      dataIndex: "finalizado",
      render: (v) => (v ? this.props.t("YES") : this.props.t("NO")),
    },
    {
      title: this.props.t("Responsible"),
      dataIndex: ["UsuarioResponsable","nombre"],
    },
    {
      title: this.props.t("Group"),
      dataIndex: ["Grupo","name"],
    },
    {
      title: this.props.t("Tags"),
      dataIndex: "Etiquetas",
      render: (v) => v.map((p) => <Tag>{p.nombre}</Tag>),
    },
    {
      title: this.props.t("Started"),
      dataIndex: "fInicio",
      render: (v) => <Tooltip title={formatDate(v)}>{moment.utc(v).fromNow()}</Tooltip>,
    },
    {
      title: this.props.t("In status"),
      dataIndex: "fEstado",
      render: (v) => <Tooltip title={formatDate(v)}>{moment.utc(v).fromNow()}</Tooltip>,
    },
    {
      title: this.props.t("Expires"),
      dataIndex: "fSla",
      render: (v, r) => {
        if (r.sla == 0) {
          return "";
        } else {
          const f = moment(v);

          return f.isBefore(Now) ? (
            <span style={{ color: "red" }}>
              <WarningTwoTone twoToneColor="red" style={{ fontSize: 16, marginRight: 5 }} />
              <span>{f.fromNow()}</span>
            </span>
          ) : (
            <span>{f.fromNow()}</span>
          );
        }
      },
    },
    {
      render: (r) => (
        <Link to={{ pathname: "/activity/" + r.id, actividadCargada: r }}>
          <Button type="primary" icon={<CaretRightOutlined />} />
        </Link>
      ),
    },
  ];
  render() {
    const { t, i18n } = this.props;
    return (
      <DefaultPage
        title={t("My Work")}
        icon="pushpin"
        extra={
          <div>
            <RadioGroup style={{ marginRight: 10 }} defaultValue="misGrupos" onChange={this.filtroResponsable}>
              <RadioButton value="misGrupos">{t("My groups")}</RadioButton>
              <RadioButton value="mias">{t("My tasks")}</RadioButton>
              <RadioButton value="enviadas">{t("Sent")}</RadioButton>
              <RadioButton value="todas">{t("All")}</RadioButton>
            </RadioGroup>
            <span>{t("Pending")} </span>
            <Switch onChange={this.filtroFinalizadas} defaultChecked style={{ marginRight: 10 }} />
            <span>{t("Unassigned")} </span>
            <Switch onChange={this.onUnassigned} style={{ marginRight: 10 }} checked={safeGet(["filtro", "unAssigned"], this, false) ? true : false} />

            <ButtonGroup>
              <Button onClick={this.onClickBuscar} icon={<SearchOutlined />}>
                {t("Search")}
              </Button>
              <Button onClick={this.onClickReset} icon={<UndoOutlined />}>
                {t("Reset")}
              </Button>
            </ButtonGroup>
          </div>
        }>
        <Table
          columns={this.columns}
          loading={this.state.loading}
          dataSource={this.state.data}
          pagination={this.state.pagination}
          onChange={this.handleTableChange}
          size="medium"
          rowKey="id"
        />
        <Drawer title={t("Find")} placement="right" onClose={this.onCloseFiltro} visible={this.state.filtroVisible} width={512}>
          <ActividadBuscar onSearch={this.onSearch} />
        </Drawer>
      </DefaultPage>
    );
  }
}

export default withTranslation()(Actividades);
