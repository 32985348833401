import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DeleteOutlined, EditOutlined, LeftOutlined, ShareAltOutlined } from '@ant-design/icons';
import {Alert, Button, Divider, message, Popconfirm, Table} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CessionParticipantForm from "./CessionParticipantForm";
import {exe} from "../../Lib/Dal";
import { formatMoney } from '../../Lib/Helpers';

const CessionParticipants = (props) => {
    const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState();

    const cession = props.cession;

    const load = () => {
        setLoading(true);
        exe("RepoCessionPart", {operation: "GET", filter: `cessionId=${cession.id}`}).then(r => {
            setLoading(false);
            if (r.ok) {
                props.reload(r.outData);
            } else message.error(r.msg);
        })
    }

    const onParticipantSave = values => {
        setLoading(true);
        exe("RepoCessionPart", {operation: values.id ? "UPDATE" : "ADD", entity: values}).then(r => {
            setLoading(false);
            if (r.ok) {
                message.success(r.msg);
                setSelected(undefined);
                load();
                props.reloadParent();
            } else message.error(r.msg);
        })
    }
    const onRemove = (r) => {
        setLoading(true);
        exe("RepoCessionPart", {operation: "DELETE", entity: r}).then(r => {
            setLoading(false);
            if (r.ok) {
                message.success(r.msg);
                setSelected(undefined);
                load();
                props.reloadParent();
            } else message.error(r.msg);
        })
    }
    const updateParticipants=()=>{
        setLoading(true);
        exe("UpdateParticipants", {cessionId: cession.id}).then(r => {
            setLoading(false);
            if (r.ok) {
                message.success(r.msg);
                load();
            } else message.error(r.msg);
        })
    }
    const warning=(props.value||[]).reduce((p,c)=>p+c.split,0)!==100; 
    return (
        <div>
            {!selected && <div>
                <Button type="primary" icon={<LegacyIcon type="add" />}
                        onClick={() => setSelected({cessionId: cession.id})}>{t("Add")}</Button>
                <Button type="link" icon={<ShareAltOutlined />} onClick={updateParticipants}>{t("Update Participants")}</Button>
                {warning&&<Alert
                    message={t("Warning")}
                    style={{marginTop:5,marginBottom:5}}
                    description={t("Participation must sum 100%. Please check participants sum insured")}
                    type="warning"
                    showIcon
                />}
                <Table dataSource={props.value} loading={props.loading}>
                    <Table.Column title={t("Participant")} dataIndex="name"/>
                    <Table.Column title={t("Line ID")} dataIndex="lineId"/>
                    <Table.Column title={t("Split %")} dataIndex="split"/>
                    <Table.Column title={t("Sum Insured")} dataIndex="sumInsured" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Premium")} dataIndex="premium" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Commission")} dataIndex="commission" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Tax")} dataIndex="tax" render={(v,r)=>formatMoney(v,r.currency)}/>
                    <Table.Column title={t("Actions")} key="actions" render={(v, r) => <div>
                        <Button type="link" icon={<EditOutlined />} onClick={() => setSelected(r)}/>
                        <Divider type={"vertical"}/>
                        <Popconfirm title={t("Are you sure you want to remove this participant?")}
                                    onConfirm={() => onRemove(r)}>
                            <Button type="link" icon={<DeleteOutlined />}/>
                        </Popconfirm>
                    </div>}/>
                </Table>
            </div>}
            {selected && <div>
                <Button type="link" icon={<LeftOutlined />} onClick={() => setSelected(undefined)}>{t("Back")}</Button>
                <CessionParticipantForm cession={cession} value={selected} onOk={onParticipantSave}/>
            </div>}
        </div>
    );
};

export default CessionParticipants;
