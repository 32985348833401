import {
  ClockCircleOutlined,
  CloudUploadOutlined,
  ClusterOutlined,
  DeleteOutlined,
  EyeOutlined,
  HddOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
  RocketOutlined,
  ThunderboltOutlined,
  WarningOutlined,
  FileExcelOutlined,
  DownOutlined,
  StopOutlined,
  LockOutlined
} from '@ant-design/icons';

import {
  Badge,
  Button,
  Divider,
  Dropdown,
  message,
  Popconfirm,
  Progress,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDate, formatDateShort, round2 } from "../../Lib/Helpers";
import ShowImportErrors from "../EventBus/ShowImportErrors";
import showPrompt from "../Shared/showPrompt";
import BatchRestrictions from './BatchRestrictions';

const BatchList = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
  const [groupProgress, setGroupProgress] = useState(0);
  const [importErrors, setImportErrors] = useState();
  const [batchIdSelected, setBatchIdSelected] = useState();
  const [batchRestrictionsVisible, setBatchRestrictionsVisible] = useState(false);
  const [selectedBatchId, setSelectedBatchId] = useState();

  useEffect(() => {
    load();
  }, [props.reload]);

  const load = (current = 1) => {
    setLoading(true);
    exe("RepoBatch", { operation: "GET", size: pagination.pageSize, page: current - 1 }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setPagination({ ...pagination, total: r.total, current: current });
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };

  const remove = (id, name) => {
    setLoading(true);
    exe("RepoBatch", { operation: "DELETE", entity: { id: id, name: name } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const deleteBatchGroup = (groupName) => {
    setLoading(true);
    exe("DeleteBatchGroup", { groupName: groupName }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };

  const open = (record) => {
    //loading full record on demand
    setLoading(true);
    exe("GetFullBatch", { batchId: record.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.onOpen(r.outData.batch);
      } else {
        message.error(r.msg);
      }
    });
  };
  const openErrors = (record) => {
    if (!props.onOpen) {
      message.error(t("This option is only available in the record import screen"));
      return;
    }
    if (record.processingType == 2) {
      openErrorsFileMode(record);
      return;
    }

    const dataOptions = record.groupName ? { groupName: record.groupName } : { batchId: record.id };
    setLoading(true);
    exe("GetBatchErrors", dataOptions).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.onOpen(r.outData.errors);
      } else {
        message.error(r.msg);
      }
    });
  };
  const openErrorsFileMode = record => {
    setLoading(true);
    exe("GetBatchErrorsFileMode", { batchName: record.name }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setImportErrors(r.outData.errors.jData);
        setBatchIdSelected(record.id);
      } else {
        message.error(r.msg);
      }
    });
  }
  const openPending = (record) => {
    if (!props.onOpen) {
      message.error(t("This option is only available in the record import screen"));
      return;
    }

    const dataOptions = record.groupName ? { groupName: record.groupName } : { batchId: record.id };
    setLoading(true);
    exe("GetBatchPending", dataOptions).then((r) => {
      setLoading(false);
      if (r.ok) {
        props.onOpen(r.outData.pending, false);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onProcessBatch = (record) => {
    setLoading(true);
    exe("DoBatch", { batchId: record.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onProcessBatchGroup = record => {
    let progress = 0;
    const total = record.Children.length;
    setLoading(true);
    for (let i = 0; i < total; i++) {
      exe("DoBatch", { batchId: record.Children[i].id }).then((r) => {
        progress++;
        setGroupProgress(progress / total);
        if (progress / total >= 1) {
          setLoading(false);
          load();
        }
        if (r.ok) {
        } else {
          message.error(r.msg);
        }
      });
    }
  }
  const onUpdateBatchStatus = (batch) => {
    if (batch.processingType === 2) {
      onUpdateBatchStatusFileMode(batch);
      return;
    }
    setLoading(true);
    exe("SetBatchResults", { batchId: batch.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(data.map((p) => (p.id == batch.id ? r.outData : p))); //updating the batch status
        if (batch.groupName) onReload(); //reloading master record if update triggered from children
      }
    });
  };
  const onUpdateBatchStatusFileMode = (batch) => {
    exe("SetBatchResultsFileMode", { batchId: batch.id }).then((r) => {
      if (r.ok) {
        setData(data.map((p) => (p.id === batch.id ? r.outData : p))); //updating the batch status
      } else message.error(r.msg);
    });
  };
  const onUpdateGroupStatus = batch => {
    setLoading(true);
    exe("SetGroupBatchResults", { groupName: batch.name }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        message.success(t("Refreshing"));
        onReload();
      } else message.error(r.msg);
    });
  }
  const getProgress = (r) => {
    if (r.status == "FINISHED") return 100;
    if (r.records > 0) {
      return round2((r.processed / r.records) * 100);
    } else return 0;
  };
  const onChangePagination = (pag) => {
    setPagination(pag);
    load(pag.current);
  };
  const CustomExpandIcon = (props) => {
    if (!props.record.Children || props.record.Children.length === 0) return null;
    if (props.expanded) {
      return (
        <a onClick={(e) => props.onExpand(props.record, e)}>
          <MinusCircleOutlined />
        </a>
      );
    } else {
      return (
        <a onClick={(e) => props.onExpand(props.record, e)}>
          <PlusCircleOutlined />
        </a>
      );
    }
  };
  const downloadFaildToExcel = record => {
    setLoading(true);
    exe("SendToExcel", { batchId: record.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        const base64String = r.outData.base64File;
        // Function to convert base64 to a Blob
        function base64ToBlob(base64, mimeType) {
          const byteCharacters = atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          return new Blob([byteArray], { type: mimeType });
        }

        // Convert Base64 string to a Blob
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = base64ToBlob(base64String, mimeType);

        // Create an object URL from the Blob
        const url = URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.download = 'failed.xlsx';

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);

        // Revoke the object URL to free up memory
        URL.revokeObjectURL(url);
      } else {
        message.error(r.msg);
      }
    });

  }
  const expandedRowRender = (r, index) => {
    return (
      <Table dataSource={r.Children} rowKey={"id"} pagination={false}>
        <Table.Column title={t("ID")} dataIndex="id" />
        <Table.Column title={t("Name")} dataIndex="name" />
        <Table.Column
          title={t("Records")}
          dataIndex="records"
          render={(v) => <Badge count={v} overflowCount={999999999} style={{
            backgroundColor: "#fff",
            color: "#999",
            boxShadow: "0 0 0 1px #d9d9d9 inset"
          }} />}
        />
        <Table.Column title={t("Success")} dataIndex="success"
          render={(v) => <Badge count={v} overflowCount={999999999} showZero style={{ backgroundColor: "#52c41a" }} />} />
        <Table.Column title={t("Error")} dataIndex="error" render={(v) => <Badge count={v} overflowCount={999999999} />} />
        <Table.Column title={t("Processing Progress")} width={300}
          render={(v, r) => <Progress percent={getProgress(r)} />} />
        <Table.Column
          title={t("Status")}
          dataIndex="status"
          render={(v) => <Tag
            color={v == "FINISHED" ? "#87d068" : v == "PENDING" ? "orange" : v == "ERROR" ? "red" : "blue"}>{v}</Tag>}
        />
        <Table.Column
          title={t("Actions")}
          key="actions"
          render={(v, r) => (
            <div style={{ whiteSpace: "nowrap" }}>
              <Tooltip title={t("Launch")}>
                <Button type="link" onClick={() => onProcessBatch(r)} disabled={r.status} icon={<RocketOutlined />} />
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Delete")}>
                <Button type="link" onClick={() => remove(r.id, r.name)} icon={<DeleteOutlined />} />
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Show all records")}>
                <Popconfirm disabled={r.records < 10000} title={t("This batch contains over 10,000 records. This action may fail if your computer does not have sufficient memory.")} onConfirm={() => open(r)}>
                  <Button type="link" onClick={() => r.records < 10000 ? open(r) : null} icon={<EyeOutlined />} />
                </Popconfirm>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Update status")}>
                <Button type="link" onClick={() => onUpdateBatchStatus(r)} icon={<ThunderboltOutlined />} disabled={r.status == "FINISHED" || r.processingType == 1} />
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Download failed to Excel")}>
                <Button type="link" onClick={() => downloadFaildToExcel(r)} icon={<FileExcelOutlined />} />
              </Tooltip>
            </div>
          )}
        />
      </Table>
    );
  }
  const getProcesingTypeIcon = (v) => {
    if (v == 0)
      return (
        <Tooltip title="Cluster of workers">
          <ClusterOutlined />
        </Tooltip>
      );
    if (v == 1)
      return (
        <Tooltip title="App Server">
          <HddOutlined />
        </Tooltip>
      );
    if (v == 2)
      return (
        <Tooltip title="File on server">
          <CloudUploadOutlined />
        </Tooltip>
      );
    return null;
  };
  const onReload = () => {
    load(pagination.current);
  }
  const onExpand = (expanded, record) => {
    console.log(expanded, record);
  }
  const onForceStop = () => {
    showPrompt({
      title: t("Please enter batch id"),
      placeholder: t("Batch Id"),
      rules: [
        {
          required: true,
          message: t("You must enter id"),
        },
      ],
    }).then((batchId) => {
      setLoading(true);
      exe("ForceBatchStop", { batchId: batchId }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          onReload();
        } else {
          message.error(r.msg);
        }
      });
    });
  }
  const onRestrictBatch = () => {
    showPrompt({
      title: t("Please enter batch id"),
      placeholder: t("Batch Id"),
      rules: [
        {
          required: true,
          message: t("You must enter id"),
        },
      ],
    }).then((batchId) => {
        setBatchRestrictionsVisible(true);
        setSelectedBatchId(batchId);
      
    });
  }
  const optionItems = [
    {
      key: 'forceStop',
      label: (<div>{t("Force Stop")}</div>),
      icon: <StopOutlined />,
      onClick: onForceStop,
      disabled: false,
    },
    {
      key: 'restrictBatch',
      label: (<div>{t("Batch Restrictions")}</div>),
      icon: <LockOutlined />,
      onClick: onRestrictBatch,
      disabled: false,
    }
  ];

  return (
    <div>
      <Button type="link" icon={<ReloadOutlined />} onClick={onReload}>
        {t("Reload")}
      </Button>
      <Dropdown menu={{ items: optionItems }} >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            {t("Options")}
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
      {groupProgress > 0 && groupProgress < 1 && <Progress percent={groupProgress * 100} size="small" showInfo={false} />}

      <Table dataSource={data} loading={loading} pagination={pagination}
        onChange={(pag) => onChangePagination(pag)}
        expandIcon={(p) => <CustomExpandIcon {...p} />}
        rowKey="id" expandedRowRender={expandedRowRender} onExpand={onExpand}>
        <Table.Column title={t("ID")} dataIndex="id" render={(v, r) => <span style={{whiteSpace:"nowrap"}}>{v > 0 ? v : t("Grouped")}{r.restricted&&<LockOutlined style={{marginLeft:3,marginRight:3}} />}</span>} />
        <Table.Column title={t("Name")} dataIndex="name" style={{ whiteSpace: "nowrap" }} />
        <Table.Column title={t("Mode")} dataIndex="processingType" render={(v) => getProcesingTypeIcon(v)} />
        <Table.Column title={t("Created")} dataIndex="created" style={{ whiteSpace: "nowrap" }} render={(v) => <Tooltip title={formatDate(v)}>{formatDateShort(v)}</Tooltip>} />
        <Table.Column
          title={t("Records")}
          dataIndex="records"
          render={(v) => <Badge count={v} overflowCount={999999999} style={{ backgroundColor: "#fff", color: "#999", boxShadow: "0 0 0 1px #d9d9d9 inset" }} />}
        />
        <Table.Column title={t("Success")} dataIndex="success" render={(v) => <Badge count={v} overflowCount={999999999} showZero style={{ backgroundColor: "#52c41a" }} />} />
        <Table.Column title={t("Error")} dataIndex="error" render={(v) => <Badge count={v} overflowCount={999999999} />} />
        <Table.Column title={t("Processing Progress")} width={300} key={"progress"} render={(v, r) => <Progress percent={getProgress(r)} />} />
        <Table.Column
          title={t("Status")}
          dataIndex="status"
          render={(v) => <Tag color={v == "FINISHED" ? "#87d068" : v == "PENDING" ? "orange" : v == "ERROR" ? "red" : "blue"}>{v}</Tag>}
        />
        <Table.Column title={t("User")} dataIndex="user" />
        <Table.Column
          title={t("Actions")}
          key="actions"
          render={(v, r) => (
            <div style={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>
              <Tooltip title={t("Launch")}>
                <Button type="link" onClick={() => r.Children ? onProcessBatchGroup(r) : onProcessBatch(r)} disabled={r.status && r.status !== "READY"} icon={<RocketOutlined />} />
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Delete")}>
                <Popconfirm title={t("Are you sure you want to delete this item?")} onConfirm={() => r.Children ? deleteBatchGroup(r.groupName) : remove(r.id, r.name)}>
                  <Button type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Show all records")}>
                <Popconfirm disabled={r.records < 10000} title={t("This batch contains over 10,000 records. This action may fail if your computer does not have sufficient memory.")} onConfirm={() => open(r)}>
                  <Button type="link" onClick={() => r.records < 10000 ? open(r) : null} icon={<EyeOutlined />} disabled={r.Children || r.processingType === 2} />
                </Popconfirm>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Update status")}>
                <Button type="link" onClick={() => r.Children ? onUpdateGroupStatus(r) : onUpdateBatchStatus(r)} icon={<ThunderboltOutlined />} disabled={r.status == "FINISHED" || r.processingType == 1}>
                </Button>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Show errors")}>
                <Button type="link" onClick={() => openErrors(r)} icon={<WarningOutlined />} />
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Show pending")}>
                <Button type="link" onClick={() => openPending(r)} icon={<ClockCircleOutlined />} disabled={r.processingType === 2} />
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title={t("Download failed to Excel")}>
                <Button type="link" onClick={() => downloadFaildToExcel(r)} icon={<FileExcelOutlined />} />
              </Tooltip>
            </div>
          )}
        />
      </Table>
      <ShowImportErrors batchId={batchIdSelected} reload={onReload} visible={importErrors} onOk={() => setImportErrors()} onCancel={() => setImportErrors()} data={importErrors} />
      <BatchRestrictions visible={batchRestrictionsVisible} onCancel={() => setBatchRestrictionsVisible(false)} batchId={selectedBatchId} onOk={()=>setBatchRestrictionsVisible(false)} />
    </div>
  );
};

export default BatchList;
