import {
    DeleteOutlined,
    DownloadOutlined,
    ExperimentOutlined,
    FileOutlined,
    LeftOutlined,
    PlayCircleOutlined,
    SaveOutlined,
    SearchOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Input, message, Modal, Popconfirm, Table, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import View from "./View";
import CodeEditorW from '../Shared/CodeEditorW';

const LiveViews = (props) => {
    const [t, i18n] = useTranslation();
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [loading, setLoading] = useState(false);
    const [simulatorVisible,setSimulatorVisible]=useState(false);

    useEffect(() => load(props.match.params.viewId ? props.match.params.viewId : 0), []);

    const load = (viewId) => {
        exe("RepoLiveView", { operation: "GET" }).then((r) => {
            if (r.ok) {
                setData(r.outData);
                if (viewId) setSelected(r.outData.find((p) => p.id == viewId));
            } else {
                message.error(r.msg);
            }
        });
    };
    const save = (r) => {
        exe("RepoLiveView", { operation: selected.id ? "UPDATE" : "ADD", entity: selected }).then((r) => {
            if (r.ok) {
                message.success(r.msg);
                load();
            } else {
                message.error(r.msg);
            }
        });
    };
    // search by column
    let searchInput;
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder={`${t("Search")} ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: "block" }}
                />
                <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
                    {t("Search")}
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    {t("Reset")}
                </Button>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        // render: (text) =>
        //   searchedColumn === dataIndex ? (
        //     <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />
        //   ) : (
        //     text
        //   ),
    });
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    // table search end
    const onDelete = (record) => {
        setLoading(true);
        exe("RepoLiveView", { operation: "DELETE", entity: record }).then((r) => {
            setLoading(false);
            if (r.ok) {
                message.success(r.msg);
                load();
            } else {
                message.error(r.msg);
            }
        });
    };
    const onExport = () => {
        function download(content, fileName, contentType) {
            var a = document.createElement("a");
            var file = new Blob([content], { type: contentType });
            a.href = URL.createObjectURL(file);
            a.download = fileName;
            a.click();
        }
        download(JSON.stringify({ ...selected, id: 0 }), selected.name + ".json", "text/json");
    };
    const onImport = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const myForm = JSON.parse(e.target.result);
                setSelected(myForm);
            } catch (error) {
                message.error("Invalid file");
            }
        };
        reader.readAsText(file);
        return false;
    };
    const openSimulator=()=>{
        setSimulatorVisible(true);
    }
    return (
        <div>
            <DefaultPage
                title={t("Views")}
                icon="codepen"
                extra={
                    <div>
                        <Button.Group>
                            <Button icon={<ExperimentOutlined />} onClick={openSimulator} disabled={!selected}>
                                {t("Simulator")}
                            </Button>
                            <Button icon={<PlayCircleOutlined />} onClick={()=>window.open("#/view/" + selected.id, '_blank').focus()} disabled={!selected}>
                                {t("Preview")}
                            </Button>
                            <Button icon={<DownloadOutlined />} onClick={onExport} disabled={!selected}>
                                {t("Export")}
                            </Button>
                            <Upload showUploadList={false} beforeUpload={(f) => onImport(f)}>
                                <Button icon={<UploadOutlined />}>{t("Import")}</Button>
                            </Upload>
                            {selected && (
                                <Button type="primary" icon={<SaveOutlined />} onClick={save}>
                                    {t("Save")}
                                </Button>
                            )}
                        </Button.Group>
                    </div>
                }>
                {!selected && (
                    <div>
                        <Button type="link" icon={<FileOutlined />} onClick={() => setSelected({})}>
                            {t("New")}
                        </Button>
                        <Table dataSource={data} size="medium">
                            <Table.Column title={t("ID")} dataIndex="id" {...getColumnSearchProps("id")} />
                            <Table.Column title={t("Name")} dataIndex="name" {...getColumnSearchProps("name")} />
                            <Table.Column title={t("Description")} dataIndex="operation" {...getColumnSearchProps("operation")} />
                            <Table.Column title={t("Category")} dataIndex="category" {...getColumnSearchProps("category")} />
                            <Table.Column
                                title={t("Actions")}
                                render={(v, r) => (
                                    <span>
                    <Button type="link" onClick={() => setSelected(r)}>
                      {t("Open")}
                    </Button>
                    <Divider type="vertical" />
                    <Popconfirm title={t("Are you sure you want to delete this item?")} onConfirm={() => onDelete(r)}>
                      <Button type="link" icon={<DeleteOutlined />}></Button>
                    </Popconfirm>
                  </span>
                                )}
                            />
                        </Table>
                    </div>
                )}

                {selected && (
                    <div>
                        <Button type="link" icon={<LeftOutlined />} onClick={() => setSelected(null)}>
                            {t("Back")}
                        </Button>
                        <div style={{ display: "flex" }}>
                            <Form.Item label={t("Name")} style={{ flex: 1 }}>
                                <Input value={selected.name} onChange={(v) => setSelected({ ...selected, name: v.target.value })} />
                            </Form.Item>
                            <Form.Item label={t("Operation Description")} style={{ flex: 1, marginLeft: 5 }}>
                                <Input value={selected.operation} onChange={(v) => setSelected({ ...selected, operation: v.target.value })} />
                            </Form.Item>
                            <Form.Item label={t("Category")} style={{ flex: 1, marginLeft: 5 }}>
                                <Input value={selected.category} onChange={(v) => setSelected({ ...selected, category: v.target.value })} />
                            </Form.Item>
                        </div>
                        <CodeEditorW javascript value={selected.code} onChange={(v) => setSelected({ ...selected, code: v })} />
                    </div>
                )}
              
                <Modal visible={simulatorVisible} onCancel={()=>setSimulatorVisible(false)} onOk={()=>setSimulatorVisible(false)}>
                    <View code={selected&&selected.code} />
                </Modal>
            </DefaultPage>
        </div>
    );
};

export default LiveViews;
