import { InputNumber, Select } from "antd";
import React from "react";

const DaySelect = (props) => {
    return (
        <div style={{ display: "flex" }}>
            <Select value={props.value} onChange={props.onChange} style={props.style} disabled={props.disabled}>
                {[...Array(32).keys()].map(v=><Select.Option key={v+"d"} value={v}>
                    {v}
                </Select.Option>)}
            </Select>
        </div>
    );
};

export default DaySelect;
