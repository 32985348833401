import React, { useState, useEffect } from "react";
import { exe } from "../../Lib/Dal";
import { Radio, Table, Tag } from "antd";
import { formatMoney, getColor, formatDate } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";

const ContactPolicies = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 20 });
  const [currentFilter, setCurrentFilter] = useState("");


  useEffect(() => {
    if (props.contactId > 0) {
      load("holderId=" + props.contactId);
    } else {
      setData([]);
      if (props.onLoad) props.onLoad(0);
    }
  }, [props.contactId]);

  useEffect(() => {
    if (pagination.current > 0&&props.contactId) load(currentFilter);
  }
    , [pagination.current]);

  const load = (filter) => {
    setLoading(true);
    setCurrentFilter(filter);
    exe("RepoLifePolicy", { operation: "GET", filter: filter, include: ["Holder"],size:pagination.pageSize,page:pagination.current-1 }).then((r) => {
      setLoading(false);
      setData(r.outData);
      setPagination({ ...pagination, total: r.total });
      if (props.onLoad) props.onLoad(r.total);
    });
  };
  const onOptionChange = (e) => {
    switch (e.target.value) {
      case "policyholder":
        load("holderId=" + props.contactId);
        break;
      case "insured":
        load(`id in (SELECT lifePolicyId from insured where contactId=${props.contactId})`);
        break;
      case "beneficiary":
        load(`id in (SELECT lifePolicyId from beneficiary where contactId=${props.contactId})`);
        break;

      default:
        break;
    }
  };
  const Column = Table.Column;
  return (
    <div>
      <Radio.Group defaultValue="policyholder" style={{ marginBottom: 5 }} onChange={onOptionChange}>
        <Radio.Button value="policyholder">{t("Policyholder")}</Radio.Button>
        <Radio.Button value="insured">{t("Insured")}</Radio.Button>
        <Radio.Button value="beneficiary">{t("Beneficiary")}</Radio.Button>
      </Radio.Group>
      <Table dataSource={data} loading={loading} pagination={pagination} onChange={pag=>setPagination(pag)} rowKey="id">
        <Column title={t("Id")} dataIndex="id" render={(v, r) => <a href={"#/lifePolicy/" + r.id}>{v}</a>} />
        <Column title={t("Code")} dataIndex="code" />
        <Column title={t("Certificate")} dataIndex="certificate" />
        <Column title={t("Status")} dataIndex="entityState" render={(v, r) => <Tag color={getColor(r.entityState)}>{v}</Tag>} />
        <Column title={t("LoB")} dataIndex="lob" />
        <Column title={t("Product")} dataIndex="productCode" />
        <Column title={t("Issued")} dataIndex="activeDate" render={(v) => formatDate(v)} />
        <Column title={t("Holder")} dataIndex={["Holder","FullName"]} />
        <Column title={t("Created")} dataIndex="created" render={(v) => formatDate(v)} />
        <Column title={t("Gross Value")} dataIndex="grossValue" render={(v, r) => formatMoney(r.grossValue, r.currency)} />
        <Column title={t("Action")} render={(v, r) => <a href={"#/lifePolicy/" + r.id}>Open</a>} />
      </Table>
    </div>
  );
};

export default ContactPolicies;
