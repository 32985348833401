import React, { useState, useEffect } from "react";
import { Select, Tooltip, Divider, message } from "antd";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";

//used inside forms = props = fn onChange, string value
const SegmentSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    getCache("RepoSegmentCatalog").then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };
const selectableSegments=props.selectableSegments||[];
  return (
    <div style={props.style}>
      <Select
        id="segmentSelect"
        style={{ width: "100%" }}
        onChange={props.onChange}
        value={props.value}
        disabled={props.disabled}
        showSearch
        loading={loading}
        allowClear
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showArrow={true}
        placeholder={t("Please select segment")}>
        {data.map((d) => (
          <Select.Option key={d.code} value={d.code} disabled={!selectableSegments.includes(d.code)}>
            {t(d.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default SegmentSelect;
