import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import Currency from "../Shared/Currency";

const AccountGroupSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), [props.currency]);
  useEffect(()=>{
    if(props.value){
      if(!data.find(p=>p.accountId===props.value)) props.onChange(undefined); //clearing invalid values
    }
  },[props.value])
    
  const group=props.group;
  const currency=props.currency;

  const load = () => {
    setLoading(true);
    let filter=`[group]='${group}'`;
    if(currency) filter+= ` AND currency='${currency}'`;
    exe("RepoAccountGroup", { operation: "GET",filter:filter }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
      <Select
          style={props.style}
        placeholder={t("Please select account")}
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear1>
        {data.map((p) => (
          <Select.Option value={p.accountId} key={p.code}>
            {p.name}{<Currency.Flag currency={p.currency} />}
          </Select.Option>
        ))}
      </Select>
  );
};

export default AccountGroupSelect;
