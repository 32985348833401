import React, { useState, useEffect } from "react";
import { CodeOutlined, DeleteOutlined, DownOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Table,
  DatePicker,
  message,
  Badge,
  Checkbox,
  Tooltip,
  Dropdown,
  Menu,
  Modal,
  Popconfirm,
} from "antd";
import { exe } from "../../Lib/Dal";
import {formatMoney, formatDateShort, formatNumber, formatDate} from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";
import ContactLink from "../Contact/ContactLink";
import DownloadOptions from "../Shared/DownloadOptions";
import CustomEventSelect from "./CustomEventSelect";

const ComData = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [customComputeVisible, setCustomComputeVisible] = useState(false);
  const [customEvent, setCustomEvent] = useState(null);

  useEffect(() => {
    if (props.data.records) setData(props.data.records);
  }, [props.data.records]);

  const onCompute = () => {
    //replaced by computeInBackground
    setLoading(true);
    exe("ComputeCom", {}).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Computed"));
        exe("RepoCommission", { operation: "GET", include: ["Seller"] }).then((r) => {
          setData(r.outData.records);
        });
      } else {
        message.error(r.msg);
      }
    });
  };
  const computeInBackground = () => {
    setLoading(true);
    exe("PutMessage", {
      batch: "Commissions",
      value: `{cmd:"ComputeCom",data:{}}`,
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success("Operation succesfuly sent to event bus");
      } else message.error(r.msg);
    });
  };
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    if (props.onSelectChange) props.onSelectChange(data.filter((row) => selectedRowKeys.includes(row.id)));
  };
  const toggleSelectAll = () => {
    const selectableItems=data.filter(p=>!p.comLiquidationId);
    onSelectChange((selectedRowKeys.length === selectableItems.length ? [] : selectableItems.map((r) => r.id)));
  };

  const headerCheckbox =()=> {
    const selectableItemsKeys=data.filter(p=>!p.comLiquidationId).map((r) => r.id);
    const allSelected=selectableItemsKeys.every(p=>selectedRowKeys.includes(p))&&selectableItemsKeys.length>0;
  return <Checkbox checked={allSelected}
            indeterminate={!allSelected&&selectableItemsKeys.some(p=>selectedRowKeys.includes(p))}
            onChange={toggleSelectAll}/>
};
  const groupToggleSelectAll = (groupData) => {
    const selectableItemsKeys=groupData.filter(p=>!p.comLiquidationId).map((r) => r.id);
    onSelectChange((selectableItemsKeys.every(p=>selectedRowKeys.includes(p)) ? selectedRowKeys.filter(p=>!selectableItemsKeys.includes(p)) :[...selectedRowKeys,...selectableItemsKeys]));
  };
  const groupHeaderCheckbox = groupData=>{
    const selectableItemsKeys=groupData.filter(p=>!p.comLiquidationId).map((r) => r.id);
    const allSelected=selectableItemsKeys.every(p=>selectedRowKeys.includes(p))&&selectableItemsKeys.length>0;
    return <Checkbox
        checked={allSelected}
        indeterminate={!allSelected&&selectableItemsKeys.some(p=>selectedRowKeys.includes(p))}
        onChange={()=>groupToggleSelectAll(groupData)} />
  }

  const getPaymentStatusBadge = (status) => {
    switch (status) {
      case 2:
        return <Tooltip title={t("Paid")}><Badge status="success" /></Tooltip>
      case 1:
        return <Tooltip title={t("Some participants have been paid")}><Badge status="warning" /></Tooltip>
      default:
        return <Tooltip title={t("No payment information")}><Badge status="default" /></Tooltip>
    }
  };
  const getPaymentStatusText = (status) => {
    switch (status) {
      case 2:
        return t("Paid");
      case 1:
        return t("Some participants have been paid")
      default:
        return t("No payment information")
    }
  };
  const onComputeCustomEvent = () => {
    if(!customEvent){
        message.error(t("Custom event not selected"));
        return;
    }
    setLoading(true);
    exe("ComputeCustomEventCom", {comContractId:props.comContractId,eventName:customEvent}).then((r) => {
      setLoading(false);
        if (r.ok) {
          message.success(t("Custom event computed"));
          props.reload&&props.reload();
          setCustomComputeVisible(false);
        } else {
          message.error(r.msg);
        }
        });
    };
  const onDeleteSelection = () => {
    setLoading(true);
    exe("DeleteCommissions", {comIds:selectedRowKeys}).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Selection removed"));
        onSelectChange([]);
        props.reload&&props.reload();
      } else {
        message.error(r.msg);
      }
    });
  }
  
  const renderTable=groupData=><Table
      dataSource={groupData}
      loading={loading}
      style={{ marginTop: 5 }}
      scroll={{ x: true }}
      size="middle"
      rowSelection={props.selectable ?  {selectedRowKeys,
        columnTitle: props.data.grouped?groupHeaderCheckbox(groupData):headerCheckbox,
        onChange: onSelectChange,
        getCheckboxProps:r=>({disabled:r.comLiquidationId})} : {}}
      rowKey="id">
    <Table.Column title={t("Id")} dataIndex="id" />
    <Table.Column title={t("Date")} dataIndex="created" render={(v) => formatDateShort(v)} />
    <Table.Column title={t("Liq")} dataIndex="comLiquidationId" render={(v) => (v ? v : <Badge status="default" />)} />
    <Table.Column title={t("Payment")} dataIndex="paymentStatus" render={v=>getPaymentStatusBadge(v)} />
    <Table.Column title={t("Contract")} dataIndex="comContractId" render={(v, r) => <a href={"#/comContract/" + v}>{v}</a>} />
    <Table.Column title={t("Policy")} dataIndex="lifePolicyId" render={(v, r) => <a href={"#/lifePolicy/" + v}>{v}</a>} />
    <Table.Column title={t("Cert")} dataIndex="certificate" />
    <Table.Column title={t("Coverage")} dataIndex="covCode" />
    {/*<Table.Column title={t("Master Product")} dataIndex="masterCode" />*/}
    {/*<Table.Column title={t("Version")} dataIndex="version" />*/}
    <Table.Column title={t("Product")} dataIndex="productCode" />
    <Table.Column title={t("Distributor")} dataIndex="distributor" />
    <Table.Column title={t("Type")} dataIndex="type" />
    <Table.Column title={t("Group")} dataIndex="ofnGroup" />
    <Table.Column title={t("Code")} dataIndex="ofnCode" />
    <Table.Column title={t("Credit")} dataIndex="credit" />
    <Table.Column title={t("Seller")} dataIndex="sellerId" render={(v, r) => <ContactLink contact={{ id: v, FullName: r.sellerName }} />} />
    {/* <Table.Column title="Ext Code" dataIndex="extCode" /> */}

    <Table.Column title={t("Line")} dataIndex="lineId" />
    <Table.Column title={t("Event")} dataIndex="eventName" />
    <Table.Column title={t("Allocation")} dataIndex="allocationId" />
    <Table.Column title={t("Period")} dataIndex="contractYear" />
    <Table.Column title={t("Change")} dataIndex="changeId" />
    <Table.Column title={t("Msg")} dataIndex="msg" />

    <Table.Column
        title={t("Commission")}
        dataIndex="commission"
        render={(v, r) => (v < 0 ? <span style={{ color: "red" }}>{formatMoney(v, r.currency)}</span> : formatMoney(v, r.currency))}
        fixed="right"
    />
  </Table>

  return (
    <div>
      <DownloadOptions data={data.map(p=>({
        [t("Id")]:p.id,
        [t("Date")]:formatDateShort(p.created),
        [t("Liq")]:p.comLiquidationId,
        [t("Payment")]:getPaymentStatusText(p.paymentStatus),
        [t("Contract")]:p.comContractId,
        [t("Policy")]:p.lifePolicyId,
        [t("Cert")]:p.certificate,
        [t("Coverage")]:p.covCode,
        [t("Product")]:p.productCode,
        [t("Distributor")]:p.distributor,
        [t("Type")]:p.type,
        [t("Group")]:p.ofnGroup,
        [t("Code")]:p.ofnCode,
        [t("Credit")]:p.credit,
        [t("Seller")]:p.sellerName,
        [t("Line")]:p.lineId,
        [t("Event")]:p.eventName,
        [t("Allocation")]:p.allocationId,
        [t("Msg")]:p.msg,
        [t("Commission")]:p.commission,
        [t("Currency")]:p.currency,
      }))} filename={"commissions"} />
      <Dropdown overlay={<Menu>
        <Menu.Item>
          <Button type="link" icon={<ThunderboltOutlined />} onClick={computeInBackground}>
            {t("All Events")}
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button type="link" icon={<CodeOutlined />} onClick={()=>setCustomComputeVisible(true)} disabled={!props.comContractId}>
            {t("Custom Events")}
          </Button>
        </Menu.Item>
      </Menu>}>
        <Button type="link" icon={<ThunderboltOutlined />} >
          {t("Compute now")}<DownOutlined />
        </Button>
      </Dropdown>
      <Popconfirm title={t("Are you sure you want to delete the selected records?")} onConfirm={onDeleteSelection} disabled={selectedRowKeys.length===0}>
        <Button type="link" icon={<DeleteOutlined />} disabled={selectedRowKeys.length===0} >{t("Delete")}</Button>
      </Popconfirm>
      
      {props.data.grouped&&<Table rowKey={"key"} dataSource={props.data.grouped} expandedRowRender={(record,index,indent,expanded)=>renderTable(record.records)}>
        <Table.Column title={t("Group")} key="key" dataIndex={"key"} />
        <Table.Column title={t("Records")} key="records" render={(v,r)=>r.records.length} />
        <Table.Column title={t("Total")} key="sum" render={(v,r)=>formatNumber(r.records.reduce((a,b)=>a+b.commission,0))}  />
      </Table>}
      {!props.data.grouped&&renderTable(data)}
      <Modal title={t("Custom Events")} visible={customComputeVisible} onCancel={()=>setCustomComputeVisible(false)} onOk={onComputeCustomEvent} >
        <Form.Item label={t("Event")}>
        <CustomEventSelect comContractId={props.comContractId} style={{width:"100%"}} value={customEvent} onChange={v=>setCustomEvent(v)} />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default ComData;
