import React from "react";
import MedicalHistory from "./MedicalHistory";
import FamilylHistory from "./FamilyHistory";

const MedicalRecords = (props) => {
  return (
    <div>
      <MedicalHistory contactId={props.contactId} />
      <FamilylHistory contactId={props.contactId} />
    </div>
  );
};

export default MedicalRecords;
