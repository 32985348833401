import React, { useEffect, useState } from "react";
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Row, Col, message, Typography, Anchor, Tabs, Select, Button } from "antd";
import { exe } from "../../Lib/Dal";
import { HashLink } from "react-router-hash-link";
import DefaultPage from "../Shared/DefaultPage";
import EditableFormField from "../Shared/EditableFormField";
import yaml from "js-yaml";
import NewProduct from "./NewProduct";
import { useTranslation } from "react-i18next";

const Configurator = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [productConfig, setProductConfig] = useState({});
  const [product, setProduct] = useState({});
  const productCode = props.match.params.productCode;
  const [changes, setChanges] = useState([]);
  const [metadata, setMetadata] = useState([]);
  const [newProductVisible, setNewProductVisible] = useState(false);

  useEffect(() => load(productCode), [productCode]);
  useEffect(() => loadLabels(), []);

  const loadLabels = () => {
    exe("RepoLabel", { operation: "GET", filter: "module='PRODUCT'" }).then((r) => {
      const toObject = r.outData.map((p) => ({ path: p.path, label: p.label, help: p.help, type: p.type, options: p.options, validation: p.validation }));
      setMetadata(toObject);
    });
  };

  const load = (productCode) => {
    if (!productCode) return;
    setLoading(true);
    exe("GetProductTemplate", {
      productCode: productCode,
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setProductConfig(r.outData.template);
        setProduct(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onNewProduct = (product) => {
    setNewProductVisible(false);
    load(product.code);
  };
  const onSave = () => {
    changes.forEach((p) => {
      setToValue(productConfig, p.value, p.path);
    });
    const updatedProductConfig = productConfig;

    try {
      product.config = yaml.safeDump(updatedProductConfig, { lineWidth: 600 });
      product.configJson = JSON.stringify(updatedProductConfig);
    } catch (error) {
      message.error(t("Validation error. Please check console."));
      console.log(productConfig, error);
      return;
    }

    setLoading(true);
    exe("RepoProduct", { operation: "UPDATE", entity: product }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        setProductConfig(JSON.parse(r.outData[0].configJson));
        setProduct(r.outData[0]);
        setChanges([]);
      } else {
        message.error(r.msg);
      }
    });
  };

  const resolve = (path, obj, separator = ".") => {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  };
  const setToValue = (obj, value, path) => {
    var i;
    path = path.split(".");
    for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

    obj[path[i]] = value;
  };
  const getObject = (obj, path) => {
    const properties = path.split(".");
    let current = productConfig;
    properties.forEach((p) => {
      current = current[p];
    });
    return current;
  };
  const onChange = (v, path) => {
    const exists = changes.find((p) => p.path == path);
    if (exists) {
      exists.value = v;
      setChanges([...changes.filter((p) => p.path !== path), exists]);
    } else {
      const change = { path: path, value: v };
      setChanges([...changes, change]);
    }
  };
  const onAddArrayItem = (path) => {
    const normalizedPath = path.replace(/\..*\./, "."); //normalizing arrays paths
    const labels = metadata.filter((p) => p.path.startsWith(normalizedPath));
    const array = getObject(productConfig, path);

    const newItem = {};
    labels.forEach((p) => {
      const relativePath = p.path.replace(normalizedPath + ".", "");
      const splitted = relativePath.split(".");
      if (splitted.length > 1) {
        //subarray
        const subArray = newItem[splitted[0]];
        if (!subArray) {
          newItem[splitted[0]] = [{}];
        }
        newItem[splitted[0]][0][splitted[1]] = "";
        //object
      } else {
        const fieldName = splitted[0];
        newItem[fieldName] = "";
      }
    });
    array.push(newItem);
    setProductConfig({ ...productConfig });
  };
  const getLastField = (path) => {
    const splitted = path.split(".");
    return splitted[splitted.length - 1];
  };
  const onRemoveArrayItem = (path) => {
    //ie. Coverages.6
    const lastField = getLastField(path);
    const removeLast = path.split(".").slice(0, -1).join(".");
    const parent = getObject(productConfig, removeLast);
    parent.splice(lastField);

    setProductConfig({ ...productConfig });
  };
  const getValue = (path, currentValue) => {
    const exists = changes.find((p) => p.path == path);
    if (exists) {
      return exists.value;
    } else {
      return currentValue;
    }
  };
  const getMetadata = (path, field, notFound) => {
    const normalizedPath = path.replace(/\..*\./, "."); //normalizing arrays paths
    const result = metadata.find((p) => p.path == normalizedPath);
    return result ? result[field] : notFound;
  };
  const onEditTab = (key, operation, path) => {
    if (operation == "add") {
      onAddArrayItem(path);
      return;
    }
    if (operation == "remove") {
      console.log("remove", key, path);
      onRemoveArrayItem(key);
    }
  };
  const renderObject = (object, path) => {
    if (object == null) return null;

    return Object.keys(object).map((key) => (
      <div style={{ marginLeft: 15, marginTop: 10 }}>
        <Typography.Text strong>{getMetadata(path + "." + key, "label", key)}</Typography.Text>
        <div>
          <Typography.Text type="secondary">{getMetadata(path + "." + key, "help", null)}</Typography.Text>
        </div>
        {object[key] == null && (
          <EditableFormField value={getValue(path + "." + key, object[key] ? object[key].toString() : null)} onChange={(v) => onChange(v, path + "." + key)} />
        )}
        {typeof object[key] !== "object" && (
          <EditableFormField value={getValue(path + "." + key, object[key].toString())} onChange={(v) => onChange(v, path + "." + key)} />
        )}
        {typeof object[key] == "object" && object[key] !== null && !Array.isArray(object[key]) && renderObject(object[key], path + "." + key)}
        {typeof object[key] == "object" && object[key] !== null && Array.isArray(object[key]) && renderArray(key, object[key], path + "." + key)}
      </div>
    ));
  };

  const renderArray = (name, array, path) => {
    if (array == null) return null;
    if (array.length == 0) {
      //empty array, allowing add
      return (
        <div>
          <Button type="dashed" style={{ marginTop: 3 }} icon={<PlusOutlined />} onClick={(e) => onEditTab(e, "add", path)}>
            {t("Add")}
          </Button>
        </div>
      );
    }
    if (typeof array[0] !== "object") {
      //string arrays
      return (
        <div>
          <Select
            mode="tags"
            value={getValue(path, array)}
            style={{ width: 300, marginTop: 5 }}
            onSelect={(v) => onChange([...getValue(path, array), v], path)}
            onDeselect={(v) =>
              onChange(
                getValue(path, array).filter((p) => p !== v),
                path
              )
            }>
            {array.map((item, index) => (
              <Select.Option key={item}>{item}</Select.Option>
            ))}
          </Select>
        </div>
      );
    }

    return (
      <div>
        <Tabs type="editable-card" onEdit={(e, operation) => onEditTab(e, operation, path)}>
          {array.map((p, index) => (
            <Tabs.TabPane tab={name + " " + index} key={path + "." + index} closable>
              {renderObject(p, path + "." + index)}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
    );
  };

  const keys = Object.keys(productConfig);
  return (
    <DefaultPage
      title={t("Product Inspector")}
      subTitle={productCode}
      icon="dropbox"
      onBack={() => (window.location = "#/products")}
      routes={{
        routes: [
          { path: "/", breadcrumbName: t("Home") },
          { path: "/products", breadcrumbName: t("Products") },
          { path: "", breadcrumbName: t("Product") },
        ],
      }}
      extra={
        <div>
          <Button icon={<PlusOutlined />} onClick={() => setNewProductVisible(true)} style={{ marginRight: 3 }}>
            {t("New")}
          </Button>
          <Button type="primary" icon={<SaveOutlined />} onClick={() => onSave()} loading={loading}>
            {t("Save")}
          </Button>
        </div>
      }>
      <div id="configuratorDiv">
        {/* <Row gutter={16}>
        <Col span={4}></Col>
        <Col span={16}>
          <Input.Search placeholder="input search text" onSearch={(value) => console.log(value)} enterButton size="large" style={{ marginBottom: 15 }} />
        </Col>
      </Row> */}
        <Row gutter={16}>
          <Col span={4}>
            <Anchor affix showInkInFixed getContainer={() => document.getElementById("my-scroll-layout")}>
              {keys.map((k) => (
                <div>
                  <HashLink to={"#" + k} smooth>
                    <Anchor.Link href={"#" + k} title={k} />
                  </HashLink>
                </div>
              ))}
            </Anchor>
          </Col>
          <Col span={20}>
            <div id="my-scroll-layout" style={{ height: "80vh", overflowY: "scroll" }}>
              {keys.map((k, index) => (
                <div style={{ marginTop: 25 }}>
                  {(index == 0 || keys[index - 1] !== keys[index]) && (
                    <Typography.Title level={3} id={k}>
                      {k}
                    </Typography.Title>
                  )}
                  {/* --------ARRAYS-------- */}
                  <div>{Array.isArray(productConfig[k]) && renderArray(k, productConfig[k], k)}</div>
                  {/* --------OBJECTS-------- */}
                  {typeof productConfig[k] == "object" && !Array.isArray(productConfig[k]) && renderObject(productConfig[k], k)}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <NewProduct visible={newProductVisible} onOk={onNewProduct} onCancel={() => setNewProductVisible(false)} />
    </DefaultPage>
  );
};

export default Configurator;
