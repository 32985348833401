import { ReloadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, message, Select, Table } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import useUpdateEffect, { formatMoney } from "../../Lib/Helpers";

const ReTechnicalAccount = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [periodicity, setPeriodicity] = useState(props.periodicity);

  useEffect(() => {
    if (props.contractId) load(props.contractId);
  }, [props.contractId]);

  useUpdateEffect(() => {
    if (props.contractId) load(props.contractId);
  }, [periodicity]);

  const load = (contractId) => {
    setLoading(true);
    exe("ReComputeTechnicalAccount", { contractId: contractId, periodicity }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => load(props.contractId)}>
          {t("Reload")}
        </Button>
        <span>{t("Periodicity")}:</span>
        <Select onChange={(v) => setPeriodicity(v)} value={periodicity} style={{ width: 150, marginLeft: 5 }}>
          <Select.Option value={3}>{t("Quarterly")}</Select.Option>
          <Select.Option value={12}>{t("Yearly")}</Select.Option>
          <Select.Option value={1}>{t("Monthly")}</Select.Option>
        </Select>
      </div>
      <Table dataSource={data} loading={loading}>
        <Table.Column title={t("Year")} dataIndex="year" />
        {periodicity !== 12 && <Table.Column title={periodicity == 3 ? t("Quarter") : t("Month")} dataIndex="month" />}
        <Table.Column title={t("Premium Ceded")} dataIndex="premiumCeded" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Cedant Commission")} dataIndex="comissionCedant" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Claims Ceded")} dataIndex="claimsCeded" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Reserve Retention")} dataIndex="reserveRetention" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Reserve Liberation")} dataIndex="reserveLiberation" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Reserve Interests")} dataIndex="reserveInterests" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Tax Interests")} dataIndex="taxInterests" render={(v, r) => formatMoney(v, r.currency)} />
      </Table>
    </div>
  );
};

export default ReTechnicalAccount;
