import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import CustomPolicyGroupsForm from "./CustomPolicyGroupsForm";

const CustomPolicyGroups = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.visible) load();
    },[props.visible])
    
    const load=()=>{
        console.log(props.groups)
        setData(props.groups);
    }
    
    const onSave=values=>{
        let operation;
        if(values.operation==="ADD"){
            //validation code unique
            if(data.find(p=>p.code===values.code)){
                message.error(t("Code must be unique"));
                return;
            }
            
            setData([...data,values]);
            delete values.operation;//dont want it serialized
        }else{
            setData([...data.filter(p=>p.code!==values.code),values])
        }
        setSelected(null);
        
    }
    const onDelete=record=>{
        setData(data.filter(p=>p.code!==record.code));
        setSelected(null);
    }
    
    const onSaveChanges=()=>{
        setLoading(true);
        props.onOk(data)
    }
    
    return (
        <div>
            <Modal title={t("Custom Benefit Groups")} visible={props.visible} onCancel={props.onCancel} onOk={onSaveChanges} okText={t("Save")} okButtonProps={{disabled:selected}} >
            {!selected&&<div>
                <Button type={"link"} icon={<LegacyIcon type={"plus"} />} onClick={()=>setSelected({operation:'ADD'})}>{t("New")}</Button>
                <Table dataSource={data} rowKey={"code"} style={{marginTop:5}} pagination={false}>
                    <Table.Column title={t("Code")} dataIndex={"code"} />
                    <Table.Column title={t("Annual Limit")} dataIndex={"aLimit"} />
                    <Table.Column title={t("Event Limit")} dataIndex={"limit"} />
                    <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} /> </div>}/>
                </Table>
            </div>}
            {selected&&<CustomPolicyGroupsForm onBack={()=>setSelected(null)} onSave={onSave} selected={selected} onDelete={onDelete} />}
            </Modal>
        </div>
    );
};

export default CustomPolicyGroups;