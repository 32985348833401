import React, { useState, useEffect } from "react";
import { exe } from "../../Lib/Dal";
import { SearchOutlined } from '@ant-design/icons';
import { Select, Divider } from "antd";
import { useTranslation } from "react-i18next";

const ComContractSelect = (props) => {
  const [data, setData] = useState([]);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (props.display && props.value && data.length == 0) {
      setData([{ id: props.value, name: props.display }]);
    } else if (props.value) {
      getDisplay(props.value);
    }
  }, [props.value, props.display]);

  const getDisplay = (id) => {
    exe("RepoComContract", { operation: "GET", filter: "id=" + id }).then((r) => {
      if (r.ok) {
        setData([{id: props.value, name: r.outData[0] && r.outData[0].name}]);
        if(props.onSelect&&r.outData[0]) props.onSelect(r.outData[0]);
      }
    });
  };

  const handleSearch = (v) => {
    if (!v) return;
    const filter = props.active ? "active=1 AND name LIKE N'%" + v + "%'" : "name LIKE N'%" + v + "%'";
    exe("RepoComContract", { operation: "GET", filter: filter }).then((r) => {
      if (r.ok) setData(r.outData);
    });
  };
  const onSelect=(v,opt)=>{
    props.onSelect&&props.onSelect(opt.props.record);
  }
  const Options = data.map((d) => (
    <Select.Option key={d.id} value={d.id} record={d}>
      {d.name}
      <Divider type="vertical" />
      <a href={"#/comcontract/" + d.id}>
        <SearchOutlined />
      </a>
    </Select.Option>
  ));

  return (
    <Select
      allowClear
      showSearch
      disabled={props.disabled}
      value={props.value}
      placeholder={t("Type to search")}
      style={props.style}
      defaultActiveFirstOption={true}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onSelect={onSelect}
      onChange={props.onChange}
      notFoundContent={null}>
      {Options}
    </Select>
  );
};

export default ComContractSelect;
