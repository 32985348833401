import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Input, message, Modal, Row, Slider } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import PolicyAccountSelect from "../Financial/PolicyAccountSelect";
import PaymentMethods from "../Life/PaymentMethods";
import AccountSelect from "../Shared/AccountSelect";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";

const PaymentRequest = (props) => {
  const [t] = useTranslation();

  useEffect(() => {
    if (props.visible) {
      props.form.resetFields();
    }
  }, [props.visible]);

  const [loading, setLoading] = useState(false);
  const field = props.form.getFieldDecorator;
  const doRequest = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      values.noWorkflow=props.noWorkflow; //when called from policy
      values.claimId=props.claimId; //when performed in a claim
      exe("DoPaymentRequest", values).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          props.onOk && props.onOk();
        } else message.error(r.msg);
      });
    });
  };
  const sliderChange = (v) => {
    const selectedAccId = props.form.getFieldValue("sourceAccountId");
    const selectedAccount = accounts.find((p) => p.id == selectedAccId);
    props.form.setFieldsValue({ total: (selectedAccount.currentAmountAvailableBalance * v) / 100 });
  };
  const amountValidator = (rule, v, cb) => {
    if (v && v > transitAccount.currentAmountAvailableBalance) return cb(t("Insufficient balance"));
    else return cb();
  };
  const accounts = props.Accounts || [];
  const currency = props.currency;
  const transitAccount = accounts.find((p) => p.type == "TRANSIT");
  return (
    <div>
      <Modal
        visible={props.visible}
        title={t("Money Out Request")}
        onOk={doRequest}
        onCancel={props.onCancel}
        okButtonProps={{ loading: loading }}
        okText={t("Request")}>
        <Row gutter={16}>
          <Col span={12}>
            {field("lifePolicyId", { initialValue: props.policyId })}
            <Form.Item label={t("Currency")}>{field("currency", { initialValue: currency })(<Currency style={{ width: "100%" }} disabled />)}</Form.Item>
            <Form.Item label={t("Amount")}>
              {field("total", { initialValue: 0, rules: [{ required: true, validator: amountValidator }] })(<Money currency={currency} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t("Source Account")}>
              {field("sourceAccountId", { initialValue: transitAccount ? transitAccount.id : undefined })(
                <PolicyAccountSelect disabled style={{ width: "100%" }} Accounts={accounts} />
              )}
            </Form.Item>
            <Form.Item label={t("Source %")}>{field("perc", {})(<Slider onChange={sliderChange} tipFormatter={(v) => `${v}%`} />)}</Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={t("Method of Payment")}>
              {field("paymentMethodCode", { rules: [{ required: true }] })(<PaymentMethods module="PAYMENT" style={{ width: "100%" }} />)}
            </Form.Item>
            <Form.Item label={t("Beneficiary")}>
              {field("contactId", { initialValue: props.holderId, rules: [{ required: true }] })(<SimpleContactSelect disabled />)}
            </Form.Item>
            <Form.Item label={t("Reference")}>{field("reference", {})(<Input />)}</Form.Item>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default Form.create()(PaymentRequest);
