import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Divider, Input, message, Modal, Row, Table } from "antd";
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DatePickerW from "../Shared/DatePickerW";

const MedicalHistory = (props) => {
  const [t, i18n] = useTranslation();
  const [medicalHistoryVisible, setMedicalHistoryVisible] = useState(false);
  const [medicalRecord, setMedicalRecord] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.contactId) load(props.contactId);
  }, []);

  const load = (contactId) => {
    setLoading(true);
    exe("RepoContactMedicalHistory", { operation: "GET", filter: "contactId=" + contactId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const save = () => {
    setLoading(true);
    exe("RepoContactMedicalHistory", { operation: "ADD", entity: { ...medicalRecord, contactId: props.contactId } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.contactId);
        setMedicalHistoryVisible(false);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onRemove = (record) => {
    setLoading(true);
    exe("RepoContactMedicalHistory", { operation: "DELETE", entity: { id: record.id } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.contactId);
        setMedicalHistoryVisible(false);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Title level={4}>{t("Medical History")}</Title>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setMedicalHistoryVisible(true)}>
        {t("Add")}
      </Button>
      <Row gutter={16}>
        <Table dataSource={data}>
          <Table.Column title={t("Id")} dataIndex="id" />
          <Table.Column title={t("Date")} dataIndex="date" />
          <Table.Column title={t("Diagnosis")} dataIndex="diagnosis" />
          <Table.Column title={t("Procedure")} dataIndex="procedure" />
          <Table.Column title={t("Medical Provider")} dataIndex="medicalProvider" />

          <Table.Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <Button type="link" onClick={() => onRemove(record)}>
                  {t("Remove")}
                </Button>
                <Divider type="vertical" />
              </span>
            )}
          />
        </Table>
      </Row>
      <Modal title={t("Medical Record")} visible={medicalHistoryVisible} onCancel={() => setMedicalHistoryVisible(false)} onOk={() => save()}>
        <Form.Item label={t("Date")} dataIndex="date">
          <DatePickerW onChange={(v) => setMedicalRecord({ ...medicalRecord, date: v })} value={medicalRecord.date} />
        </Form.Item>
        <Form.Item label={t("Diagnosis")} dataIndex="diagnosis">
          <Input onChange={(v) => setMedicalRecord({ ...medicalRecord, diagnosis: v.target.value })} value={medicalRecord.diagnosis} />
        </Form.Item>
        <Form.Item label={t("Procedure")} dataIndex="procedure">
          <Input onChange={(v) => setMedicalRecord({ ...medicalRecord, procedure: v.target.value })} value={medicalRecord.procedure} />
        </Form.Item>
        <Form.Item label={t("Medical Provider")} dataIndex="medicalProvider">
          <Input onChange={(v) => setMedicalRecord({ ...medicalRecord, medicalProvider: v.target.value })} value={medicalRecord.medicalProvider} />
        </Form.Item>
      </Modal>
    </div>
  );
};

export default MedicalHistory;
