import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { DownloadOutlined, FolderOpenOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Table, Select, DatePicker, message, Divider, Popconfirm } from "antd";
import { exe } from "../../Lib/Dal";
import { formatMoney, formatDateShort } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";
import Text from "antd/lib/typography/Text";
import Paragraph from "antd/lib/typography/Paragraph";
import CessionEdit from "./CessionEdit";
import CessionParticipants from "./CessionParticipants";

const ReDataPart = (props) => {
  const [t, i18n] = useTranslation();
  const [date, setDate] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editRecord, setEditRecord] = useState();

  useEffect(() => {
    if (props.value) setData(props.value);
  }, [props.value]);

  return (
    <div>
      {!editRecord && (
        <div>
          <CSVLink data={data} filename={"cessions.csv"} target="_blank" disabled={data.length == 0}>
            <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
              {t("Download")}
            </Button>
          </CSVLink>
          <Table dataSource={data} loading={loading || props.loading} style={{ marginTop: 5 }} scroll={{ x: true }}>
            <Table.Column title={t("Treaty ID")} dataIndex="contractId" render={(v, r) => <a href={"#/contract/" + v}>{v}</a>} />
            <Table.Column title={t("Start")} dataIndex="start" render={(v) => formatDateShort(v)} />
            <Table.Column title={t("End")} dataIndex="end" render={(v) => formatDateShort(v)} />
            <Table.Column title={t("Lob")} dataIndex="LoB" />
            <Table.Column title={t("Policy")} dataIndex="policyCode" />
            <Table.Column title={t("Coverage")} dataIndex="cover" />
            <Table.Column title={t("Line")} dataIndex="lineId" />

            <Table.Column title={t("Sum Insured")} dataIndex="sumInsured" render={(v, r) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Premium Sum")} dataIndex="premium" render={(v, r) => formatMoney(v, r.currency)} />

            <Table.Column title={t("Cedant Sum Insured")} dataIndex="sumInsuredCedant" render={(v, r) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Cedant Premium")} dataIndex="premiumCedant" render={(v, r) => formatMoney(v, r.currency)} />

            <Table.Column title={t("Re Sum Insured")} dataIndex="sumInsuredRe" render={(v, r) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Re Premium")} dataIndex="premiumRe" render={(v, r) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Reserve")} dataIndex="reserve" render={(v, r) => formatMoney(v, r.currency)} />

            <Table.Column title={t("Participant")} fixed="right" dataIndex="participantName" />
            <Table.Column title={t("Split %")} fixed="right" dataIndex="split" />
            <Table.Column title={t("Part Sum Insured")} fixed="right" dataIndex="sumInsuredPart" render={(v, r) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Part Premium")} fixed="right" dataIndex="premiumPart" render={(v, r) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Part Commission")} fixed="right" dataIndex="commissionPart" render={(v, r) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Part Tax")} fixed="right" dataIndex="taxPart" render={(v, r) => formatMoney(v, r.currency)} />

            <Table.Column
              title={t("Actions")}
              dataIndex="msg"
              fixed="right"
              key="actions"
              render={(v, r) => (
                <div>
                  <Button type="link" icon={<FolderOpenOutlined />} onClick={() => setEditRecord(r)}></Button>
                </div>
              )}
            />
          </Table>
        </div>
      )}
      {editRecord && (
        <div>
          <Button type="link" icon={<LeftOutlined />} onClick={() => setEditRecord(null)} style={{ float: "left" }}>
            {t("Back")}
          </Button>
          <CessionEdit value={editRecord} onEdit={() => props.doReload && props.doReload()} />
        </div>
      )}
    </div>
  );
};

export default ReDataPart;
