import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const AccountTypeSelect = (props) => {
  const [t, i18n] = useTranslation();
  return (
    <div>
      <Select allowClear value={props.value} onChange={props.onChange}>
        <Select.Option value="TRANSIT">{t("Transit")}</Select.Option>
        <Select.Option value="OPER">{t("Operating")}</Select.Option>
        <Select.Option value="BANK">{t("Banking")}</Select.Option>
        <Select.Option value="INI">{t("Initial")}</Select.Option>
        <Select.Option value="DIS">{t("Distribution")}</Select.Option>
        <Select.Option value="TOP">{t("Top-Up")}</Select.Option>
        <Select.Option value="DAILY">{t("Daily")}</Select.Option>
        <Select.Option value="PEN">{t("Pension")}</Select.Option>
      </Select>
    </div>
  );
};

export default AccountTypeSelect;
