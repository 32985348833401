import React, {useEffect, useState} from "react";
import { Bar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import {exe} from "../../Lib/Dal";
import {Button, Dropdown, Menu, message} from "antd";
import {FaAdjust} from "react-icons/fa";
import { ReloadOutlined, DownOutlined,NumberOutlined,PercentageOutlined } from '@ant-design/icons';

const ReCessionGraph = (props) => {
    const [t, i18n] = useTranslation();
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [mode,setMode]=useState("p");
    
    useEffect(()=>{
        if(props.policyId) load();
    },[props.policyId,mode]);
 
    const load=()=>{
        setLoading(true);
        exe("GetCessionGraph",{policyId:props.policyId,mode:mode}).then((r)=>{
            setLoading(false);
            if(r.ok) {
                setData(r.outData.graphData);
            }else message.error(r.msg);
        })
    }

    const commonProps = {
        width: 900,
        height: 200,
        margin: { top: 5, right: 80, bottom: 40, left: 80 },
        data: data,
        keys: ["retained", "ceded","facultative","coinsurance","unallocated"],
        indexBy: "coverageCode",
        padding: 0.2,
        labelTextColor: "inherit:darker(1.4)",
        labelSkipWidth: 16,
        labelSkipHeight: 16,
       
    };

    if (data.length === 0) return null;

    return (
        <div>
            <div>
                <Dropdown
                    overlay={
                        <Menu>
                            <Menu.Item onClick={() => setMode("v")}>
                                <NumberOutlined />
                                {t("Values")}
                            </Menu.Item>
                            <Menu.Item onClick={() => setMode("p")}>
                                <PercentageOutlined />
                                {t("Percentages")}
                            </Menu.Item>
                        </Menu>
                    }
                    trigger1={["click"]}>
                    <Button type="link" style={{ marginTop: 5, paddingLeft: 0 }}>
                        <FaAdjust style={{ marginRight: 5, marginBottom: 2, verticalAlign: "middle" }} />
                        {t("Mode")}
                        <DownOutlined />
                    </Button>
                </Dropdown>
                <Button type={"link"} icon={<ReloadOutlined />} onClick={load} loading={loading}>{t("Reload")}</Button>
            </div>
            <Bar {...commonProps} layout="horizontal" enableGridY={false} enableGridX={true} label={r=> mode==="p"?`${r.value}%`:r.value}  />
        </div>
    );
};

export default ReCessionGraph;
