import React, {useState} from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message, notification } from "antd";
import {exe} from "../../Lib/Dal";
import {useTranslation, withTranslation} from "react-i18next";

const CambioPass = props => {
    const [t] = useTranslation();
    const handleOk = (e) => {
      props.form.validateFields((err,values)=>{
          console.log(props.configProfile);
        if(err) return;
        const dto = {email: props.email, pass: values.newPassword};
        exe("ChangePass", dto).then((r) => {
            if (r.ok) {
                props.onHide();
                message.success(t("Password changed"));
            }else message.error(r.msg)
        });
      })
    };
    const getRules=()=>{
        let rules=[{required:true,message:t("The password field is required")}];
        if(props.configProfile&&props.configProfile.Security&&props.configProfile.Security.passwordRules){
            rules=[...rules,...props.configProfile.Security.passwordRules]
        }
        return rules;
    }
    const field=props.form.getFieldDecorator;
    return (
        <Modal title={t("Change Password")} visible={props.visible} onOk={handleOk} onCancel={props.onHide}>
          <Form.Item label={t("New Password")}>
            {field("newPassword",{rules:getRules()})( <Input type="password" placeholder={t("New Password")}/>)}
          </Form.Item>
        </Modal>
    );
}
export default Form.create()(CambioPass);