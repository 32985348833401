import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Popconfirm, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const Phones = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => load(), []);

  const load = () => {
    exe("RepoCommunicationsCatalog", { operation: "GET", filter: "field='PHONETYPE'" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onChangeType = (v, index) => {
    phones[index] = { ...phones[index], type: v };
    props.onChange(phones);
  };
  const onChangeNum = (v, index) => {
    const value = v.target.value;
    props.form.setFieldsValue({ ["phone" + index]: value }, () => {
      props.form.validateFields((err, values) => {
        phones[index] = { ...phones[index], num: value, err: err };
        props.onChange(phones);
      });
    });
  };
  const onAddPhone = () => {
    props.onChange([...phones, { num: "", type: undefined }]);
  };
  const onRemovePhone = (r, index) => {
    if (r.id) {
      exe("RepoContactPhone", { operation: "DELETE", entity: { id: r.id } }).then((r) => {
        if (r.ok) {
          message.success(r.msg);
          phones.splice(index, 1);
          props.onChange([...phones]);
        } else {
          message.error(r.msg);
        }
      });
    } else {
      phones.splice(index, 1);
      props.onChange([...phones]);
    }
  };
  const phones = props.value || [];
  const field = props.form.getFieldDecorator;
  return (
    <div>
      {phones.map((p, index) => (
        <div style={{ display: "flex", marginTop: 3 }} key={"phone" + index}>
          <Form.Item>
            {field("phone" + index, { initialValue: p.num, rules: [...props.validation] })(
              <Input placeholder={t("Phone Num")} onChange={(v) => onChangeNum(v, index)} />
            )}
          </Form.Item>
          <Select style={{ width: "100%", marginLeft: 3 }} placeholder={t("Please select phone type")} onChange={(v) => onChangeType(v, index)} value={p.type}>
            {data.map((p) => (
              <Select.Option value={p.code} key={p.code}>
                {t(p.name)}
              </Select.Option>
            ))}
          </Select>
          <Popconfirm title={t("Are you sure you want to delete this phone?")} onConfirm={() => onRemovePhone(p, index)}>
            <MinusCircleOutlined
              style={{ marginLeft: 5, cursor: "pointer", fontSize: 24, color: "#999", alignSelf: "center" }} />
          </Popconfirm>
        </div>
      ))}
      <Button type="link" icon={<PlusCircleOutlined />} onClick={() => onAddPhone()} style={{ paddingLeft: 2 }}>
        {t("Add Phone")}
      </Button>
    </div>
  );
};

export default Form.create()(Phones);
