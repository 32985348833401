import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import getCache from "../../Lib/Cache";

const ParticipantRoleSelect = (props) => {
  const [roles, setRoles] = useState([]);
  const [t] = useTranslation();
  useEffect(() => loadRoles(), []);

  const participants = JSON.parse(props.value || "[]");
  const loadRoles = () => {
    getCache("RepoComRoleCatalog").then((r) => {
      if (r.ok) {
        setRoles(r.outData);
      } else message.error(r.msg);
    });
  };

  const addParticipant = () => {
    //checking if there is a participant limit configured
    if(props.maxParticipants && props.maxParticipants <= participants.length) {
        message.error(t("Participant limit reached for this product"));
        return;
    }
    
    props.onChange(JSON.stringify([...participants, { contactId: undefined, role: undefined }]));
  };
  const removeParticipant = () => {
    props.onChange(JSON.stringify(participants.slice(0, participants.length - 1)));
  };

  return (
    <div>
      {participants.map((participant, index) => (
        <div style={{ display: "flex", marginTop: 5 }}>
          <SimpleContactSelect
            disabled={props.disabled}
            value={participant.contactId}
            onChange={(v) => props.onChange(JSON.stringify(participants.map((p, i) => (i == index ? { contactId: v, role: p.role } : p))))}
          />
          <Select
            style={{ marginLeft: 5 }}
            value={participant.role}
            disabled={props.disabled}
            placeholder={t("Select participant role")}
            onChange={(v) => props.onChange(JSON.stringify(participants.map((p, i) => (i == index ? { contactId: p.contactId, role: v } : p))))}>
            {roles.map((r) => (
              <Select.Option key={r.code} value={r.code}>
                {t(r.name)}
              </Select.Option>
            ))}
          </Select>
        </div>
      ))}
      <Button type="link" icon={<PlusCircleOutlined />} onClick={() => addParticipant()} disabled={props.disabled}>
        {t("Add")}
      </Button>
      <Button type="link" icon={<MinusCircleOutlined />} onClick={() => removeParticipant()} disabled={participants.length == 0 || props.disabled}>
        {t("Remove")}
      </Button>
    </div>
  );
};

export default ParticipantRoleSelect;
