import React, {useState} from 'react';
import { DownloadOutlined, DownOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from "antd";
import {CSVLink} from "react-csv";
import {useTranslation} from "react-i18next";
import {Excel} from "antd-table-saveas-excel";

const DownloadOptions = ({data,filename}) => {
const [t]=useTranslation();

    const exportXlsx = () => {
        const exportData = data;
        const fields = Object.keys(exportData[0]);
        const excel = new Excel();
        excel
            .addSheet("Sheet1")
            .addColumns(fields.map((p) => ({ title: p, dataIndex: p }))) //antd column format
            .addDataSource(exportData)
            .saveAs(filename+".xlsx");
    };

    return (
        <Dropdown
            overlay={
                <Menu>
                    <Menu.Item>
                        <CSVLink data={data} filename={filename+".csv"} target="_blank" disabled={data.length === 0}>
                            <Button type="link" icon={<DownloadOutlined />} disabled={data.length === 0}>
                                {t("Csv")}
                            </Button>
                        </CSVLink>
                    </Menu.Item>
                    <Menu.Item>
                        <Button type="link" icon={<FileExcelOutlined />} onClick={exportXlsx}>
                            {t("Excel")}
                        </Button>
                    </Menu.Item>
                </Menu>
            }>
            <Button type="link" icon={<DownloadOutlined />} disabled={data.length === 0}>
                {t("Download")}
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};

export default DownloadOptions;