import { CodeOutlined, EnterOutlined, ReloadOutlined, RocketOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, Input, message, Popover, Table, Tooltip} from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import showPrompt from "../Shared/showPrompt";
import Percentage from "../Shared/Percentage";
import NewWorker from "./NewWorker";
import ServerSelect from "./ServerSelect";

const Workers = () => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [workerName,setWorkerName]=useState();
  const [newWorkerVisible, setNewWorkerVisible] = useState(false);
  const [serverName,setServerName]=useState(); //used in get log by worker name

  useEffect(() => loadWorkers(), []);

  const loadWorkers = () => {
    setLoading(true);
    exe("ClusterGetStatus").then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData.result);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onNewWorker = (values) => {
    //adding worker
    setLoading(true);
    exe("ClusterAdd", values).then((r) => {
      setLoading(false);
      if (r.ok) {
        setNewWorkerVisible(false);
        loadWorkers();
      } else {
        message.error(r.msg);
      }
    });
  }

  const onKill=record=>{
    setLoading(true);
    exe("ClusterKill",{pid:record.pid,serverName:record.serverName}).then((r) => {
      setLoading(false);
      if (r.ok) {
        loadWorkers();
      } else {
        message.error(r.msg);
      }
    });
  }
  const getLog=record=>{
    setLoading(true);
    exe("GetWorkerLog",{name:record.name,serverName:record.serverName}).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Log downloaded successfully please see the console of your browser"));
        console.log(r.outData.log);
      } else {
        message.error(r.msg);
      }
    });
  }
  // const loadWorkers1 = () => {
  //   setLoading(true);
  //   exe("GetWorkers").then((r) => {
  //     setLoading(false);
  //     if (r.ok) {
  //       setWorkers(r.outData);
  //     } else {
  //       message.error(r.msg);
  //     }
  //   });
  // };

  return (
    <div>
      <Button type="link" icon={<RocketOutlined />} onClick={() => setNewWorkerVisible(true)}>
        {t("New")}
      </Button>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => loadWorkers()}>
        {t("Refresh")}
      </Button>
      <Popover title={t("Get worker log by name")} trigger="click" content={<div>
        <span style={{marginBottom:8}}>
          {t("Server")}:&nbsp;
          <ServerSelect placeHolder={t("Server")} value={serverName} onChange={setServerName} style={{width:"100%"}} />
        </span>
        <span>
            {t("Worker name")}:&nbsp;
          <Input placeholder={t("Worker name")} value={workerName} onChange={(e)=>setWorkerName(e.target.value)} />
        </span>
        <Button style={{marginTop:5}} type="primary" icon={<EnterOutlined />} onClick={() => getLog({name:workerName,serverName:(serverName||"DEFAULT")})}>
          {t("Submit")}
        </Button>
      </div>} >
        <Button type="link" icon={<CodeOutlined />}>
          {t("Get worker log")}
        </Button>
      </Popover>
    
      <Table dataSource={data} loading={loading}>
        <Table.Column title={t("Server Id")} dataIndex="serverName" />
        <Table.Column title={t("Process Id")} dataIndex="pid" />
        <Table.Column title={t("Name")} dataIndex="name" />
        <Table.Column title={t("Memory")} dataIndex="vMem" render={v=>(+v).toLocaleString()} />
        <Table.Column title={t("% Mem")} dataIndex="mem"  />
        <Table.Column title={t("% Cpu")} dataIndex="cpu" />
        <Table.Column title={t("Cpu Time")} dataIndex="time" />
        <Table.Column title={t("Actions")} key="actions" render={(v,r)=><div>
          <Tooltip title={t("Kill worker")}>
            <Button type={"link"} icon={<LegacyIcon type={"delete"} />} onClick={()=>onKill(r)} /> 
          </Tooltip>
          <Tooltip title={t("Worker log")}>
            <Button type={"link"} icon={<LegacyIcon type={"code"} />} onClick={()=>getLog(r)} /> 
          </Tooltip>
        </div>} />
      </Table>
      <NewWorker visible={newWorkerVisible} onCancel={() => setNewWorkerVisible(false)} onOk={onNewWorker} workers={data} />
    </div>
  );
};

export default Workers;
