import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, message } from "antd";
import {exe} from "../../Lib/Dal";

const TrimMessages = ({visible,onOk,onCancel}) => {
    const [minId,setMinId]=useState();
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    
    const onLocalOk=()=>{
        setLoading(true);
        exe("TrimMessages",{minId:minId}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.outData.trimmed+" "+t("messages trimmed"));
                onOk();
            }else message.error(r.msg);
        })
    }
    
    return (
        <div>
            <Modal title={t("Trim Messages")} visible={visible} onOk={onLocalOk} onCancel={onCancel} okButtonProps={{loading:loading,disabled:!minId}}>
                <Form.Item label={t("Min Id")}>
                    <Input value={minId} onChange={e=>setMinId(e.target.value)} placeholder={t("Evicts entries with IDs lower than inserted value")} />
                </Form.Item>
            </Modal>
        </div>
    );
};

export default TrimMessages;