import React, { Component } from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DeleteOutlined, FileSyncOutlined, FilterOutlined } from '@ant-design/icons';
import { Table, Select, Button, message, Popover, Input, Popconfirm } from "antd";
import moment from "moment";
import { exe, fetchProxy, safeGet } from "../../Lib/Dal";
import config from "../../config";
import TemplateSelect from "../Shared/TemplateSelect";
import { withTranslation } from "react-i18next";
import DocStatusSelect from "../Shared/DocStatusSelect";

const { Column, ColumnGroup } = Table;
const typeMap = {
  pdf: "application/pdf",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  bpm: "image/bpm",
  gif: "image/gif",
  tif: "image/tif",
  tiff: "image/tiff",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  pps: "application/vnd.ms-powerpoint",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  txt: "text/plain",
  zip: "application/zip",
  mp3: "audio/mpeg",
  wav: "audio/wav",
  mov: "video/quicktime",
  mp4: "video/mp4",
  m4v: "video/mp4",
};

class PolicyDocs extends Component {
  state = {
    loading: false,
    fileData: null,
    statusFilter:undefined
  };
  componentDidMount() {}
  onSelectAssociation(value, record) {
    record.association = value;
    this.saveRecord(record, true);
  }
  saveRecord(record, reload = false) {
    record.LifePolicyid = this.props.policyId;
    const operation = record.id ? "UPDATE" : "ADD";
    exe("RepoDocument", { operation: operation, entity: record }, undefined, true).then((r) => {
      this.props.onChange([...this.props.value.filter((p) => p.fileName !== r.outData[0].fileName), r.outData[0]]);
      if (reload) this.props.reload();
    });
  }
  getIconType(record) {
    switch (record.name) {
      case "Uploaded":
        return "upload";
        break;
      default:
        return "file-pdf";
        break;
    }
  }

  getFile(v, name) {
    const extension = v.split(".").reverse()[0];
    const type = typeMap[extension];
    if (!type) {
      message.error(this.props.t("Unathorized extension") + " " + extension);
      return;
    }
    exe("GetDoc", { url: v }).then((r) => {
      if (!r.ok) {
        message.error(r.msg);
        return;
      }
      const binaryImg = atob(r.outData.file);
      const length = binaryImg.length;
      const arrayBuffer = new ArrayBuffer(length);
      const uintArray = new Uint8Array(arrayBuffer);
      for (let i = 0; i < length; i++) {
        uintArray[i] = binaryImg.charCodeAt(i);
      }
      const fileBlob = new Blob([uintArray], { type: type });

      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(fileBlob);
      link.target = "_blank";
      //link.download = name;
      link.click();
    });
  }
  openFile(v) {
    console.log(v);
    try {
      //fixes backward compatibility
      if (v.indexOf("http") > -1) {
        if (v.indexOf("upload") > -1) {
          v = "/uploaded" + v.split("uploaded")[1];
        } else {
          const splited = v.split("/");
          v = "/" + splited[splited.length - 1];
          console.log(v);
        }
      }
      fetch(config.ssApiUrl + "/proxy" + v, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.token,
        },
      })
        .then((response) => response.blob())
        .then((data) => window.open(URL.createObjectURL(data)))
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  }

  generatePolicyDoc() {
    if (!this.state.templateName) return;
    this.setState({ loading: true });
    exe("GeneratePolicyDoc", { policyId: this.props.policyId, template: this.state.templateName }).then((r) => {
      this.setState({ loading: false, templateName: undefined });
      if (r.ok) {
        message.success(r.msg);
        this.props.reload();
      } else {
        message.error(r.msg);
      }
    });
  }
  onDelete(doc) {
    this.setState({ loading: true });
    exe("RepoDocument", { operation: "DELETE", entity: doc }, undefined, true).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.props.onChange(this.props.value.filter((p) => p.id !== doc.id));
        this.props.reload();
      }
    });
  }
  onStatusChange(doc,status)
  {
    doc.status=status;
    this.setState({ loading: true });
    exe("RepoDocument", { operation: "UPDATE", entity: doc }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(this.props.t("Status updated"));
        this.props.onChange(this.props.value.map((p) => p.id == doc.id?({...p,status:status}):p));
      }
    });
  }

  render() {
    const { t, i18n } = this.props;
    const data = this.props.value;
    const requirements = this.props.requirements || [];

    return (
      <div>
        <Popover
          content={
            <div>
              <TemplateSelect
                onChange={(v) => this.setState({ templateName: v }, () => this.generatePolicyDoc())}
                value={this.state.templateName}
                disabled={this.state.loading}
                loading={this.state.loading}
                userSelectableTemplates={safeGet(["Main", "userSelectableTemplates"], this.props.config, [])}
              />
            </div>
          }
          title={t("On demand document generation")}
          trigger="click">
          <Button type="link" icon={<FileSyncOutlined />}>
            {t("Generate")}
          </Button>
        </Popover>
        <Popover
            content={
              <div>
                <DocStatusSelect value={this.state.statusFilter} onChange={(v)=>this.setState({statusFilter:v})} style={{width:"100%"}} />
              </div>
            }
            title={t("Document Status Filter")}
            trigger="click">
          <Button type="link" icon={<FilterOutlined />}>
            {t("Filter")}
          </Button>
        </Popover>
       
        <Table dataSource={this.state.statusFilter?data.filter(p=>p.status===this.state.statusFilter):data} scroll={{ x: true }}>
          <Column render={(t, r) => <LegacyIcon type={this.getIconType(r)} style={{ fontSize: "20px" }} />} />
          <Column title={t("ID")} dataIndex="id" />
          <Column title={t("Type")} dataIndex="name" />
          <Column
            title={t("File")}
            dataInex="url"
            render={(v, r) => (
              <Button type="link" onClick={() => this.openFile(r.url, r.name)}>
                {t("Open")}
              </Button>
            )}
          />
          <Column title={t("File Name")} dataIndex="fileName" />
          <Column
            title={t("Association")}
            dataIndex="association"
            render={(t, r) => (
              <Select disabled={r.name !== "Uploaded"} dropdownMatchSelectWidth={false} value={r.association} onSelect={(v) => this.onSelectAssociation(v, r)}>
                {requirements.map((p) => (
                  <Select.Option value={p.code}>{p.name}</Select.Option>
                ))}
              </Select>
            )}
          />
          <Column title={t("Created")} dataIndex="created" render={(t) => moment.utc(t).fromNow()} />
          <Column
              title={t("Status")}
              dataIndex="status"
              width={150}
              render={(v, r) => (<DocStatusSelect value={v} onChange={(status)=>this.onStatusChange(r,status)} />)}
          />
          <Column
            render={(v, r) => (
              <Popconfirm title={t("Are you sure you want to delete this document?")} onConfirm={() => this.onDelete(r)}>
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          />
        </Table>
      </div>
    );
  }
}

export default withTranslation()(PolicyDocs);
