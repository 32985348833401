import React, { useEffect, useState } from "react"
import {getColor} from "../../Lib/Helpers";
import DefaultPage from "../Shared/DefaultPage";
import { useTranslation } from "react-i18next";
import Status from "../Shared/Status";
import { load } from 'js-yaml';
import { Button, Col, Dropdown, Form, Input, InputNumber, Menu, Row, Space, Switch, Tabs, message } from "antd";
import DatePickerW from "../Shared/DatePickerW";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import PensionTypeSelect from "./PensionTypeSelect";
import { SaveOutlined, FileOutlined, RocketOutlined, LineChartOutlined, UserOutlined, CalculatorOutlined, BankOutlined, PushpinOutlined, DollarOutlined, GlobalOutlined, FileSyncOutlined, DownloadOutlined, ReloadOutlined, UploadOutlined, DownOutlined, WarningOutlined } from '@ant-design/icons';
import ContactSelect from "../Contact/ContactSelect";
import BenefitTypeSelect from "./BenefitTypeSelect";
import FundTypeSelect from "./FundTypeSelect";
import MemberTypeSelect from "./MemberTypeSelect";
import Currency from "../Shared/Currency";
import Percentage from "../Shared/Percentage";
import Money from "../Shared/Money";
import TextArea from "antd/lib/input/TextArea";
import Title from "antd/lib/typography/Title";
import { exe } from "../../Lib/Dal";
import PensionHeader from "./PensionHeader";
import PensionMembers from "./PensionMembers";
import PensionInvestments from './PensionInvestments';
import PensionAccounts from './PensionAccounts';
import Batch from "../Life/Batch";
import BatchOperations from "./BatchOperations";
import ContributionData from "./ContributionData";
import PensionProductSelect from "./PensionProductSelect";
import BenefitOperations from "./BenefitOperations";
import PensionDocuments from "./PensionDocuments";
import { Link } from "react-router-dom";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import EntityTasks from "../Life/EntityTasks";
import FundSelect from "./FundSelect";
import PensionRequirements from "./PensionRequirements";
import PensionProjection from "./PensionProjection";
import FormLabel from "../Shared/FormLabel";

const PensionScheme=props=>{
    const [t]=useTranslation();
    const [process,setProcess]=useState()
    const [loading,setLoading]=useState(false);
    const [pension, setPension] = useState({});
    const [refresh,doRefresh]=useState(0);
    const [processes,setProcesses]=useState([]);
    const [form]=Form.useForm();

    const currency = Form.useWatch('currency', form);
    const name = Form.useWatch('name', form);
    const pensionId=props.match.params.pensionId;

    useEffect(() => {
        if(props.match.params.pensionId) load(props.match.params.pensionId);
        else {
            setPension({});
            setProcess();
            form.resetFields();
        }
        loadProcesses();
    }, [props.match.params.pensionId]);

    const reload=()=>load(props.match.params.pensionId);
    const onNew=()=>props.history.push("/pensionScheme");

    const loadProcesses = () => {
        exe("GetDefinitions", { filter: "entity='pensionScheme' and enabled=1" }).then((r) => {
          setProcesses(r.outData || []);
        });
      };

    const load=(pensionId)=>{
        setLoading(true);
        exe("RepoPensionScheme",{operation:"GET",filter:`id=${pensionId}`,include:["Process"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setPension(r.outData[0]);
                form.setFieldsValue(r.outData[0]);
                if(r.outData[0].Process) setProcess(r.outData[0].Process);
            }else message.error(r.msg);
        })
    }
    const onSave=()=>{
        form.validateFields().then(values=>{
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoPensionScheme",{operation:operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    setPension(r.outData[0]);
                    if(operation==="ADD") props.history.push(`/pensionScheme/${r.outData[0].id}`);
                }else message.error(r.msg);
            })
        })
        .catch(info => {
            console.log('Validate Failed:', info);
            message.error(t("Please fill all required fields in all the tabs"));
        });

    }
    const onActivate=()=>{
        setLoading(true);
        exe("ActivatePensionScheme",{pensionSchemeId:pension.id}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setPension(r.outData.pension);
                setProcess(r.outData.pension.Process);
                doRefresh(c=>c+1)
            }else message.error(r.msg);
        })
    }

    return <DefaultPage
    title={name?name:t("Pension Scheme")}
    icon="fund"
    tags={<Status process={process} color={getColor(process && process.entityState)} reload={() => reload()} />}
    routes={{
        routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Pension Scheme List"), path: "pensionList" },
            { breadcrumbName: t("Pension Scheme"), path: "" },
        ],
    }}
    // content={<PensionHeader pension={pension} />}
    extra={<div>
        <Space>
            <Button type="link" icon={<ReloadOutlined />} onClick={reload} disabled={!props.match.params.pensionId} >{t("Reload")}</Button>
            <Button type="link" icon={<FileOutlined />} onClick={onNew} >{t("New")}</Button>
            <Button type="link" icon={<RocketOutlined />} onClick={onActivate} disabled={!pensionId||pension.active}>{t("Activate")}</Button>
            <Dropdown
                onClick={() => null}
                overlay={
                  <Menu onClick={() => null}>
                    {processes.map((p,index) => (
                      <Menu.Item key={"task"+index}>
                        <Link to={{ pathname: "/activity/0", proceso: p, entity: "pensionScheme", entityId: pension.id, entityPath: "pensionScheme/" + pension.id }}>
                          <LegacyIcon type={p.icon || "rocket"} style={{ marginRight: 5,marginLeft:5 }} />
                          {p.id}.{p.name}
                        </Link>
                      </Menu.Item>
                    ))}
                  </Menu>
                }>
                <Button type="link">
                <PushpinOutlined /> {t("Tasks")} <DownOutlined />
                </Button>
              </Dropdown>
            <Button type="primary" icon={<SaveOutlined />} onClick={onSave} loading={loading}>{t("Save")}</Button>
        </Space>
        </div>}>
        <Form layout="vertical" form={form}>
            <Tabs>
                <Tabs.TabPane tab={<span><BankOutlined />{t("General")}</span>} key={"general"} forceRender>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Title level={4}>{t("Basic Info")}</Title>
                            <Form.Item name="id" hidden />
                            <Form.Item name="jProjections" hidden />
                            <Form.Item name="processId" hidden />
                            <Form.Item label={t("Name")} name={"name"} rules={[{required:true}]}><Input placeholder={t("Name of the pension scheme")}/></Form.Item>
                            <Form.Item label={t("Product")} name={"productCode"} rules={[{required:true}]}><PensionProductSelect /></Form.Item>
                            <Form.Item label={t("Fund")} name={"fundId"} rules={[{required:true}]}><FundSelect /></Form.Item>
                            <Form.Item label={t("Currency")} name={"currency"} rules={[{required:true}]}><Currency /></Form.Item>
                            <Form.Item label={t("Holder")} name={"holderId"} rules={[{required:true}]}><ContactSelect/></Form.Item>
                            <Form.Item label={t("Pension Type")} name={"pensionType"} rules={[{required:true}]}><PensionTypeSelect/></Form.Item>
                            <Form.Item label={t("Benefit Type")} name={"benefitType"} rules={[{required:true}]}><BenefitTypeSelect/></Form.Item>
                            <Form.Item label={t("Fund Type")} name={"fundType"}><FundTypeSelect/></Form.Item>
                            <Form.Item label={t("Member Type")} name={"memberType"}><MemberTypeSelect/></Form.Item>

                        </Col>
                        <Col span={10}>
                            <Title level={4}>{t("Validity")}</Title>
                            <Form.Item label={t("Active")} name={"active"} valuePropName="checked"><Switch disabled /></Form.Item>
                            <Form.Item label={t("Start")} name={"start"} rules={[{required:true}]}><DatePickerW /></Form.Item>
                            <Form.Item label={t("End")} name={"end"}><DatePickerW /></Form.Item>
                            <Form.Item label={t("Establishment Date")} name={"establishmentDate"}><DatePickerW /></Form.Item>
                            <Form.Item label={t("Description")} name={"description"}><TextArea autoSize={{ minRows: 2}} placeholder={t("Brief description or additional details about the scheme")}/></Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item label={t("ID")} name="id"><FormLabel /></Form.Item>
                            <Form.Item label={t("Code")} name="code"><FormLabel /></Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><CalculatorOutlined />{t("Conditions")}</span>} key="conditions" forceRender>
                <Row gutter={16}>
                        <Col span={12}>
                            <Title level={4}>{t("Rates")}</Title>
                            <Form.Item label={t("Employer Contribution Rate")} name={"employerContributionRate"}><Percentage min={0}/></Form.Item>
                            <Form.Item label={t("Member Contribution Rate")} name={"memberContributionRate"}><Percentage min={0}/></Form.Item>
                            <Form.Item label={t("Voluntary Contribution Rate")} name={"voluntaryContributionRate"}><Percentage min={0}/></Form.Item>
                            <Form.Item label={t("Tax Exemption Limit")} name={"taxExemptLimit"} rules={[{required:true}]}><Money min={0} currency={currency}/></Form.Item>
                        </Col>
                        <Col span={12}>
                            <Title level={4}>{t("Ages")}</Title>
                            <Form.Item label={t("Minimum Membership Age")} name={"minMembershipAge"}><InputNumber min={0}/></Form.Item>
                            <Form.Item label={t("Maximum Membership Age")} name={"maxMembershipAge"}><InputNumber min={0}/></Form.Item>
                            <Form.Item label={t("Minimum Service Duration")} name={"minServiceDuration"}><InputNumber min={0}/></Form.Item>
                            <Form.Item label={t("Early Retirement Age")} name={"earlyRetirementAge"}><InputNumber min={0}/></Form.Item>
                            <Form.Item label={t("Normal Retirement Age")} name={"normalRetirementAge"}><InputNumber min={0}/></Form.Item>
                            <Form.Item label={t("Late Retirement Age")} name={"lateRetirementAge"}><InputNumber min={0}/></Form.Item>
                            <Form.Item label={t("Ill Retirement Age")} name={"illRetirementAge"}><InputNumber min={0}/></Form.Item>
                        </Col>
                </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><UserOutlined />{t("Members")}</span>} key="members" disabled={!pensionId}>
                    <PensionMembers pensionId={pensionId} productCode={pension.productCode} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><DownloadOutlined />{t("Contributions")}</span>} key="contributions" disabled={!pensionId}>
                    <ContributionData pensionId={pensionId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><LineChartOutlined />{t("Projections")}</span>} key="projections">
                    <PensionProjection pensionId={pensionId} productCode={pension.productCode} projections={pension.jProjections} type="SCHEME"  />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><DollarOutlined />{t("Investments")}</span>} key="investments" disabled>
                    <PensionInvestments pensionId={pensionId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><GlobalOutlined />{t("Accounts")}</span>} key="accounts" disabled={!pensionId} >
                    <PensionAccounts pensionId={pensionId} refresh={refresh} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><FileSyncOutlined />{t("Batch Operations")}</span>} key="operations">
                    <BatchOperations pensionId={pensionId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><UploadOutlined />{t("Benefit Management")}</span>} key="benefits">
                    <BenefitOperations pensionSchemeId={pensionId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><WarningOutlined />{t("Requirements")}</span>} key="requirements">
                    <PensionRequirements pensionSchemeId={pensionId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><FileOutlined />{t("Documents")}</span>} key="documents">
                    <PensionDocuments pensionSchemeId={pensionId} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={<span><PushpinOutlined />{t("Pension Tasks")}</span>} key="pensionTasks" disabled={!pension.id}>
                    <EntityTasks entity="pensionScheme" entityId={pension.id} />
                </Tabs.TabPane>
                
            </Tabs>

        </Form>

    </DefaultPage>
}
export default PensionScheme