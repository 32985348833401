import { ArrowRightOutlined } from '@ant-design/icons';
import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import { formatDateShort } from "../../Lib/Helpers";

const ReEventSelect = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    exe("RepoReEvent", { operation: "GET" }).then((r) => {
      if (r.ok) setData(r.outData);
    });
  };
  return (
    <div>
      <Select value={props.value} onChange={(v) => props.onChange(v)} allowClear style={{ width: "100%" }}>
        {data.map((p) => (
          <Select.Option value={p.id} key={p.id}>
            {p.name + " (" + formatDateShort(p.start)}
            <ArrowRightOutlined />
            {formatDateShort(p.end) + ")"}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ReEventSelect;
