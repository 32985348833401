import { WarningOutlined } from '@ant-design/icons';
import { Badge, Checkbox, Table, Tooltip } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ComLiquidationListPart = (props) => {
  const [t, i18n] = useTranslation();
    const getBadge = (status) => {
        switch (status) {
            case 0:
                return <Badge status="success" />;
            case 1:
                return <Badge status="warning" />;
            case 2:
                return <Tooltip title={t("Reverted")}> <Badge status="error" /></Tooltip>;
            default:
                return null;
        }
    };
  return (
    <div>
      <Table dataSource={props.value} loading={props.loading} size="middle">
        <Table.Column title={t("Participant")} dataIndex="contactName" />
        <Table.Column title={t("Split %")} dataIndex="split" />
        <Table.Column title={t("Commission")} dataIndex="splitCommission" />
        <Table.Column title={t("Account ID")} dataIndex="accountId" />
        <Table.Column title={t("Paid")} dataIndex="paid" render={v=><Checkbox checked={v} /> } />
        <Table.Column title={t("Status")} dataIndex="status" render={v=>getBadge(v)} />
        <Table.Column
          title={t("Payment Request")}
          dataIndex="paymentRequestId"
          render={(v, r) =>
            r.status == 1 ? (
              <div>
                <WarningOutlined style={{ marginRight: 3, color: "red" }} />
                {t("Inactive participant")}
              </div>
            ) : (
              <a href={"#/payments/" + v}>{v}</a>
            )
          }
        />
      </Table>
    </div>
  );
};

export default ComLiquidationListPart;
