import React from "react";
import { Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import { FileOutlined,RedoOutlined, PlusOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import AnnuityProduct from "./AnnuityProduct";

const AnnuityProducts = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [selected,setSelected] = useState(null);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
            load();
    },[])

    const load=()=>{
        setLoading(true);
        exe("RepoAnnuityProduct",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }
    const onNew=()=>{
        setSelected({new:true});
    }


    return <div>
        <DefaultPage
        routes={{
          routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Annuity Products"), path: "annuityProducts" },
          ],
        }}
        loading={loading}
        title={t("Annuity Products")}
        extra={
          <div>
            <Button type="primary" icon={<FileOutlined />} onClick={onNew}>
              {t("New")}
            </Button>
            <Button style={{ marginLeft: 5 }} icon={<RedoOutlined />} onClick={load}>
              {t("Reload")}
            </Button>
          </div>
        }
        icon="control"></DefaultPage>
        {!selected&&<div>
            <Table dataSource={data} rowKey={"code"} loading={loading}>
                <Table.Column title={t("Code")} dataIndex="code" />
                <Table.Column title={t("Name")} dataIndex="name" />
                <Table.Column title={t("Actions")} render={(v,r)=><div>
                    <Button type="link" icon={<EditOutlined />} onClick={()=>setSelected(r)} />
                </div>} />
            </Table>
        </div>}
    {selected&&<div>
        <AnnuityProduct value={selected} onSave={load} onCancel={()=>setSelected(null)} />
    </div>}
    </div>
       
    };

    export default AnnuityProducts;