import React, {useEffect, useState} from "react";
import { Collapse } from "antd";
import FormRenderReadOnly from "../FormRenderReadOnly";
import FormRender from "../FormRender";
import {safeGetRaw} from "../../Lib/Dal";
import {useTranslation} from "react-i18next";
const Panel = Collapse.Panel;

const FormHistory = (props) => {
  const [t, i18n ] = useTranslation();
  const [activePanel,setActivePanel]=useState("0");
  
  if (!props.actividad || !props.actividad.UserValues) return <p>{t("No registered steps")}</p>;
  
  const userValues = props.actividad.UserValues;

  console.log(userValues,'HISTORY VALUES')

  const paneles = userValues.map((p, i) => (
      <Panel header={`${t("Step")} ${i} ${p.estado}`} key={i} >
          {activePanel==i&&<FormRenderReadOnly value={p.json} itemId={i} formId={p.formualarioId} processId={p.procesoId} />}
      </Panel>
  ));
  return (
        <Collapse accordion destroyInactivePanel onChange={t=>setActivePanel(t)} activeKey={activePanel} >{paneles}</Collapse>
  );
};

export default FormHistory;

