import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ColumnSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (props.columns) setData(props.columns);
  }, []);
  const onChange = (v) => {
    console.log(v);
    props.onChange(v);
  };
  return (
    <Select
      mode="multiple"
      showArrow
      value={props.value}
      onChange={onChange}
      loading={loading}
      style={{ minWidth: 200, ...props.style }}
      placeholder={t("Column Selection")}
      disabled={props.disabled}
      allowClear>
      {data.map((p) => (
        <Select.Option value={p} key={p}>
          {p}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ColumnSelect;
