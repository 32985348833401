import React, { useEffect, useState } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Input,
  Table,
  message,
  Button,
  Select,
  Radio,
  Row,
  Col,
  InputNumber,
  DatePicker,
} from "antd";
import { exe } from "Lib/Dal";
import PolicySelect from "../Health/PolicySelect";
import Compare from "../Shared/Compare";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import DistributionModeSelect from "./DistributionModeSelect";
import useUpdateEffect from "../../Lib/Helpers";
import moment from "moment";
import SimpleContactSelect from "../Contact/SimpleContactSelect";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

const CumulusFilter = (props) => {
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.location.hash.includes("?policyId=")) {
      const policy = window.location.hash.split("?policyId=")[1];
      props.form.setFieldsValue({ policyId: policy });
      onSearch();
    } else {
      props.form.setFieldsValue({ date: new moment() });
      onSearch();
    }
  }, []);

  useUpdateEffect(() => {
    onSearch();
  }, [props.doReload]);

  const onSearch = () => {
    props.form.validateFields((err, values) => {
      if (values.month) (values.month = values.month).format("YYYY-MM-DD");
      if (!err) {
        console.log("Received values of form: ", values);
        exe("GetGroupedCession", values).then((r) => {
          if (r.ok) {
            props.onSearch(r.outData);
          } else {
            message.error(r.msg);
          }
        });
      }
    });
  };
  const { getFieldDecorator } = props.form;
  return (
    <div>
      <Drawer title={t("Cession Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Form.Item label={t("Issuance Period")}>{getFieldDecorator("month", { initialValue: moment() })(<DatePicker.MonthPicker />)}</Form.Item>
          <Form.Item label={t("Treaty Id")}>{getFieldDecorator("contractId")(<InputNumber />)}</Form.Item>
          <Form.Item label={t("LoB")}>{getFieldDecorator("lob")(<Input />)}</Form.Item>
          <Form.Item label={t("Contact")}>{getFieldDecorator("contactId")(<SimpleContactSelect />)}</Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()}>
          {t("Search")}
        </Button>
      </Drawer>
    </div>
  );
};

export default Form.create()(CumulusFilter);
