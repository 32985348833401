import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Popconfirm, Row, Space, message, notification } from "antd";
import { useTranslation } from "react-i18next";
import Money from "../Shared/Money";
import { SaveOutlined, LeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import CodeEditorW from "../Shared/CodeEditorW";
import Title from "antd/lib/typography/Title";
import yaml from "js-yaml";

const AnnuityProduct = (props) => {
    const [loading,setLoading]=useState(false);
    const [t] = useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        if(props.value){
            if(props.value.new){
                form.resetFields();
                form.setFieldValue("new",true);
                window.global.annuityProduct.setValue("");
            }else {
                form.setFieldsValue(props.value);
                if (props.value.config) window.global.annuityProduct.setValue(props.value.config);
            }
        }
    }
    ,[props.value])

    const save=()=>{
        form.validateFields().then(values=>{
            values.config = window.global.annuityProduct.getValue();
            //yaml validation
            try {
                const result = yaml.safeLoad(values.config);
                values.configJson = JSON.stringify(result);
            } catch (error) {
                notification["error"]({
                message: error.reason,
                description: error.message,
                });
                return;
            }
            console.log(values);
            const operation=values.new?"ADD":"UPDATE";
            setLoading(true);
            exe("RepoAnnuityProduct",{operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onSave(values);
                    props.onCancel();

                }else{
                    message.error(r.msg);
                }
            })
            })
    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoAnnuityProduct",{operation:"DELETE",entity:props.value}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                props.onSave(props.value);
                props.onCancel();
            }else message.error(r.msg);
        })
    }

    return <div>
        <Button type="link" icon={<LeftOutlined />} onClick={props.onCancel}>{t("Back")}</Button>
        <Button type="link" icon={<SaveOutlined />} onClick={save} loading={loading}>{t("Save")}</Button>
        <Popconfirm  title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={props.value.new}><Button type="link" icon={<DeleteOutlined />}  disabled={props.value.new} >{t("Delete")}</Button></Popconfirm> 
        <Form form={form} layout="vertical">
            <Form.Item name={"new"} hidden><Input /></Form.Item>
            <Space>
                <Form.Item label={t("Code")} name={"code"} rules={[{required:true}]} ><Input disabled={!props.value.new} /></Form.Item>
                <Form.Item label={t("Name")} name={"name"} rules={[{required:true}]}><Input /></Form.Item>
            </Space>
            <Title level={4}>{t("Behaviour")}</Title>
            <CodeEditorW saveHotKey={() => save()} divId="annuityProduct" />
           

        </Form>
    </div>
}
export default AnnuityProduct;