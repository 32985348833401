import React, { useState, useEffect } from 'react';
import { Button, Table, Progress, Menu, Select, message } from 'antd';
import { EditOutlined, PlusOutlined, SettingOutlined,FileOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TaskEdit from './TaskEdit';
import ProjectOpen from './ProjectOpen';
import ProjectEdit from './ProjectEdit';
import { exe } from '../../Lib/Dal';

const Gantt = () => {
  const { t } = useTranslation();
  const dayWidth = 20;
  const taskHeight = 30;
  const leftPaneWidth = 200;
  const headerHeight = 90; 
  const [taskEdit, setTaskEdit] = useState(null);
  const [projectOpenVisible, setProjectOpenVisible] = useState(false);
  const [newProjectVisible, setNewProjectVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [expandLevel, setExpandLevel] = useState(1);
  const [currentDate, setCurrentDate] = useState(moment());
  const [tableRef, setTableRef] = useState(null);
  const [tasks, setTasks] = useState([]);

  const [timeRange, setTimeRange] = useState({
    start: moment().subtract(5, 'days').startOf('day'),
    end: moment().add(5, 'days').endOf('day')
  });

  useEffect(() => {
    if (tasks.length > 0) {
      const allDates = tasks.flatMap(task => [moment(task.start), moment(task.end)]);
      const minDate = moment.min(allDates).subtract(5, 'days').startOf('day');
      let maxDate = moment.max(allDates).add(5, 'days').endOf('day');
      
      const today = moment().endOf('day');
      if (maxDate.isBefore(today)) {
        maxDate = today.add(5, 'days').endOf('day');
      }

      setTimeRange({
        start: minDate,
        end: maxDate,
      });
    }
  }, [tasks]);



  const totalDays = timeRange.end.diff(timeRange.start, 'days') + 1;

  const processTasksToTreeData = (tasks) => {
    const taskMap = new Map();
    const rootTasks = [];
    // First pass: create all nodes
    tasks.forEach(task => {
      const { id, code, name, start, end, progress } = task;
      const level = code.split('.').length;
      const treeNode = {
        key: id.toString(),
        id,
        code,
        name,
        start: moment(start),
        end: moment(end),
        progress: progress || 0,
        children: null,
        level,  // Add level property
      };
      taskMap.set(code, treeNode);
    });

    // Second pass: build the tree structure
    tasks.forEach(task => {
      const treeNode = taskMap.get(task.code);
      const parentCode = task.code.split('.').slice(0, -1).join('.');
      
      if (parentCode && taskMap.has(parentCode)) {
        const parentNode = taskMap.get(parentCode);
        if (parentNode.children === null) {
          parentNode.children = [];
        }
        parentNode.children.push(treeNode);
      } else {
        rootTasks.push(treeNode);
      }
    });

    return rootTasks;
  };

  const treeData = processTasksToTreeData(tasks);

  const renderGanttChart = (task) => {
    const startDiff = Math.max(0, task.start.diff(timeRange.start, 'days'));
    const endDiff = Math.min(totalDays, task.end.diff(timeRange.start, 'days') + 1);
    const x = startDiff * dayWidth;
    const width = (endDiff - startDiff) * dayWidth;

    const currentDayDiff = currentDate.diff(timeRange.start, 'days');
    const currentDayX = currentDayDiff * dayWidth;

    // Use the level property instead of calculating depth
    const taskColor = `hsl(195, 80%, ${30 + task.level * 20}%)`;
    const progressColor = `hsl(141, 71%, 80%)`;

    return (
      <div style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}>
        <div style={{
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: `repeating-linear-gradient(
    to right,
    ${Array.from({ length: 7 }).map((_, i) => {
      const isWeekend = i % 7 === 6 || i % 7 === 0; // Saturday or Sunday
      return `
        ${isWeekend ? '#f9f9f9' : 'transparent'} ${i * dayWidth}px,
        ${isWeekend ? '#f9f9f9' : 'transparent'} ${(i + 1) * dayWidth}px
      `;
    }).join(',')},
    transparent ${7 * dayWidth}px
  ),
  repeating-linear-gradient(
    to right,
    #f0f0f0,
    #f0f0f0 1px,
    transparent 1px,
    transparent ${dayWidth}px
  )`,
  backgroundSize: `${7 * dayWidth}px 100%, ${dayWidth}px 100%`,
  backgroundPositionX: `${-timeRange.start.day() * dayWidth}px, 0`,
}} />
    
        <svg width={totalDays * dayWidth} height={taskHeight} style={{ display: 'block', position: 'relative', marginTop: -1 }}>
          <rect
            x={x}
            y={2}
            width={width}
            height={taskHeight - 4}
            fill={taskColor}
            rx="3"
            ry="3"
          />
          <rect
            x={x}
            y={2}
            width={width * (task.progress / 100)}
            height={5}
            fill={progressColor}
            rx="3"
            ry="3"
          />
          <text x={x - 5} y={taskHeight / 2} textAnchor="end" dominantBaseline="middle" fontSize="10">
            {task.start.format('DD/MM')}
          </text>
          <text x={x + width + 5} y={taskHeight / 2} textAnchor="start" dominantBaseline="middle" fontSize="10">
            {task.end.format('DD/MM')}
          </text>
          {currentDayX >= 0 && currentDayX <= totalDays * dayWidth && (
            <line
              x1={currentDayX}
              y1={0}
              x2={currentDayX}
              y2={taskHeight}
              stroke="red"
              strokeWidth="2"
              style={{ }}
            />
          )}
        </svg>
      </div>
    );
  };

  const generateTimeHeader = () => {
    const months = [];
    const days = [];
    let currentDate = timeRange.start.clone();

    while (currentDate.isSameOrBefore(timeRange.end)) {
      // Month
      if (currentDate.date() === 1) {
        const monthStart = currentDate.clone();
        const monthEnd = currentDate.clone().endOf('month');
        const daysInMonth = monthEnd.diff(monthStart, 'days') + 1;
        months.push({
          title: currentDate.format('MMMM YYYY'),
          width: daysInMonth * dayWidth,
        });
      }

      // Day
      days.push({
        title: currentDate.format('D'),
        width: dayWidth,
      });

      currentDate.add(1, 'day');
    }

    return { months, days };
  };

  const { months, days } = generateTimeHeader();

  const columns = [
    {
      title: 'WBS',
      dataIndex: 'code',
      key: 'code',
      width: 100,
      render: (text) => <span style={{ fontSize: '10px' }}>{text}</span>,
      fixed: 'left',
    },
    {
      title: 'Task',
      dataIndex: 'name',
      key: 'name',
      width: leftPaneWidth,
      fixed: 'left',
    },
  /*   {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      fixed: 'left',
      width: 100,
      render: (date) => date.format('YYYY-MM-DD'),
    },
    {
      title: 'End',
      dataIndex: 'end',
      key: 'end',
      fixed: 'left',
      width: 100,
      render: (date) => date.format('YYYY-MM-DD'),
    }, */
   
    {
      title: 'Variance',
      dataIndex: 'progress',
      key: 'progress',
      fixed: 'left',
      width: 100,
      render: (progress) => (
        <Progress percent={progress} size="small" style={{paddingRight: 10}} />
      ),
    },
    {
      fixed: 'left',
      dataIndex: 'actions',
      width:50,
      key: 'actions',
      render: (v,r) => (
        <Button type="link" icon={<EditOutlined />} style={{padding: 0, margin: 0, height: 20, width: 20, minWidth: 20, fontSize: 10}} onClick={() => setTaskEdit(r)} />
      ),
    },
    
    {
      title: () => (
        <div style={{ height: headerHeight - 30 }}> 
          <div style={{ display: 'flex', height: '40px' }}> 
            {months.map((month, index) => (
              <div key={index} style={{ width: month.width, borderRight: '1px solid #f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {month.title}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', height: '20px' }}> 
            {days.map((day, index) => (
              <div key={index} style={{ width: day.width, borderRight: '1px solid #f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'normal', fontSize:5 }}> 
                {day.title}
              </div>
            ))}
          </div>
        </div>
      ),
      key: 'gantt',
      render: (_, record) => renderGanttChart(record),
      width: totalDays * dayWidth,
    },
   
  ];

  const scrollToToday = () => {
    if (tableRef) {
      const currentDayDiff = currentDate.diff(timeRange.start, 'days');
      const currentDayX = currentDayDiff * dayWidth;
      const scrollContainer = tableRef.querySelector('.ant-table-body');
      const containerWidth = scrollContainer.clientWidth;
      const scrollLeft = currentDayX - containerWidth / 2 + leftPaneWidth;
      scrollContainer.scrollLeft = Math.max(0, scrollLeft);
    }
  };



  // Update getAllKeys function
  const getAllKeys = (data, targetDepth, currentDepth = 0) => {
    return data.reduce((keys, item) => {
      if (currentDepth < targetDepth - 1) {
        keys.push(item.key);
        if (item.children) {
          keys.push(...getAllKeys(item.children, targetDepth, currentDepth + 1));
        }
      }
      return keys;
    }, []);
  };

  // Update this effect to set initial expanded keys
  useEffect(() => {
    setExpandedRowKeys(expandLevel > 1 ? getAllKeys(treeData, expandLevel) : []);
  }, [expandLevel]);

  // Update handleLevelChange
  const handleLevelChange = (value) => {
    setExpandLevel(value);
    //setExpandedRowKeys(value > 1 ? getAllKeys(treeData, value) : []);
  };


  // Add this function to get the maximum depth of the task tree
  const getMaxDepth = (tasks, currentDepth = 0) => {
    return tasks.reduce((maxDepth, task) => {
      if (task.children) {
        return Math.max(maxDepth, getMaxDepth(task.children, currentDepth + 1));
      }
      return Math.max(maxDepth, currentDepth);
    }, currentDepth);
  };

  const maxDepth = getMaxDepth(treeData);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(moment());
    }, 24 * 60 * 60 * 1000); // Update every 24 hours

    return () => clearInterval(timer);
  }, []);

  const onMenuClick = (e) => {
    console.log('menu clicked', e);
    if(e.key === 'openProject'){
      setProjectOpenVisible(true);
    }
    if(e.key === 'newProject'){
      setNewProjectVisible(true);
    }
  };
  const menuItems = [

    {
      label: t("Projects"),
      key: 'projects',
      icon: <FileOutlined />,
      children: [
        {
          type: 'group',
          label: 'Projects',
          children: [
            {
              label: 'New',
              key: 'newProject',
            },
            {
              label: 'Open',
              key: 'openProject',
            },
            {
              label: 'Save',
              key: 'saveProject',
            },
          ],
        },
      ],
    },
  ];
 const handleSave = (values) => {
  values.projectId=project.id;
  //assign parentId (search for the parent id)
  const codeParts = values.code.split('.');
  const codePrefix = codeParts.slice(0, -1).join('.');
  const parentId = tasks.find(task => task.code === codePrefix);
  if(parentId) values.parentId = parentId.id;
  // Check if there's another task with the same code
  const existingTask = tasks.find(task => task.code === values.code && task.id !== values.id);
  if (existingTask) {
    const isTopLevel = codeParts.length === 1;

    if (isTopLevel) {
      // Handle top-level codes
      const topLevelCodes = tasks
        .filter(task => !task.code.includes('.'))
        .map(task => parseInt(task.code));
      const nextCode = Math.max(...topLevelCodes, 0) + 1;
      values.code = nextCode.toString();
    } else {
      // Handle nested codes
      const existingCodes = tasks
        .filter(task => task.code.startsWith(codePrefix + '.'))
        .map(task => {
          const lastPart = task.code.split('.').pop();
          return isNaN(lastPart) ? 0 : parseInt(lastPart);
        });
      const nextCode = existingCodes.length > 0 ? Math.max(...existingCodes) + 1 : 1;
      values.code = `${codePrefix}.${nextCode}`;
    }
  }

  const taskIndex = tasks.findIndex(task => task.id === values.id);
  const operation = taskIndex === -1 ? "ADD" : "UPDATE";
  if (operation === "ADD") values.id = 0;
  exe("RepoProjectTask",{operation:operation,entity:values}).then(r=>{
    if(r.ok){
      //updating parent task
      const savedTask = r.outData[0];
      const currentTasks = [...tasks];
      if(savedTask.parentId){
        const parentTaskIndex = currentTasks.findIndex(task => task.id === savedTask.parentId);
        if(parentTaskIndex !== -1){
          currentTasks[parentTaskIndex] = savedTask.Parent;
        }
      }
      if(operation === "ADD"){
        setTasks([...currentTasks, savedTask]);
      }else{
        const updatedTasks = [...currentTasks];
        updatedTasks[taskIndex] = savedTask;
        setTasks(updatedTasks);
      }
    }else{
      message.error(r.msg);
    }
  })

  setTaskEdit(null);
 }
 const onTaskDelete = (id) => {
  setLoading(true);
  exe("RepoProjectTask",{operation:"DELETE",entity:{id:id}}).then(r=>{
    setLoading(false);
    if(r.ok){
      setTasks(tasks.filter(task => task.id !== id));
      setTaskEdit(null);
    }else{
      message.error(r.msg);
    }
  })
 }
 const onProjectOpen = (selectedProject) => {
  console.log('project', selectedProject);
  setProject(selectedProject)
  setProjectOpenVisible(false);
   //loading project tasks
   setLoading(true);
   exe("RepoProjectTask",{operation:"GET",entity:{projectId:selectedProject.id}}).then(r=>{
     setLoading(false);
     if(r.ok){
       setTasks(r.outData);
       setExpandLevel(2);
     }else{
       message.error(r.msg);
     }
   })
 }
 const onProjectSave = (selectedProject) => {
  console.log('project', selectedProject);
  setNewProjectVisible(false);

 }
  return (
    <div>
      <Menu onClick={onMenuClick} selectable= {false} mode="horizontal" items={menuItems} />
      <div style={{padding: '5px 0px 5px 0px'}}>
        <Button type="link" icon={<PlusOutlined />} onClick={() => {
          if(!project) {
            message.error(t("Please open a project first"));
            return;
          }
          setTaskEdit({
            id: tasks.length > 0 ? Math.max(...tasks.map(t => t.id)) + 1 : 1, 
            code: tasks.length > 0 
              ? `${Math.max(...tasks.filter(task => !task.code.includes('.')).map(task => parseInt(task.code||0))) + 1}`
              : '1',
            name: '',
            start: moment(),
            end: moment(),
            progress: 0,
            projectId: project.id,
          });
        }}>New</Button>
        <Button type="link" onClick={() => {
          setCurrentDate(moment());
          scrollToToday();
        }}>Today</Button>
        <Select
          style={{ width: 120, marginLeft: 8 }}
          placeholder="Select level"
          onChange={handleLevelChange}
          value={expandLevel}
        >
          {[...Array(maxDepth + 1)].map((_, index) => (
            <Select.Option key={index + 1} value={index + 1}>
              Level {index + 1}
            </Select.Option>
          ))}
        </Select>
      </div>
      <Table
        ref={setTableRef}
        expandedRowKeys={expandedRowKeys}
        onExpandedRowsChange={setExpandedRowKeys}
        columns={columns}
        dataSource={treeData}
        loading={loading}
        pagination={false}
        size="small"
        scroll={{ x: leftPaneWidth + totalDays * dayWidth, y: 'calc(100vh - 8px)' }}
        components={{
          body: {
            row: ({ children, ...props }) => (
              <tr {...props} style={{ height: `${taskHeight}px`, padding: 0, margin: 0 }}>
                {children}
              </tr>
            ),
            cell: ({ children, ...props }) => (
              <td {...props} style={{ ...props.style, padding: 0 }}>
                {children}
              </td>
            ),
          },
        }}
        style={{ height: '100vh' }}
      />
      <TaskEdit visible={taskEdit !== null} onCancel={() => setTaskEdit(null)} onSave={handleSave} initialValues={taskEdit} tasks={tasks} onDelete={onTaskDelete} />
      <ProjectOpen visible={projectOpenVisible} onCancel={() => setProjectOpenVisible(false)} onOpen={onProjectOpen}/>
      <ProjectEdit visible={newProjectVisible} onCancel={() => setNewProjectVisible(false)} onSave={onProjectSave} />
    </div>
  );
};

export default Gantt;