import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Modal, notification, Radio, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatMoney, formatPercent, formatPercentRound2, round2 } from "../../Lib/Helpers";
import yaml from "js-yaml";
import CodeEditorW from '../Shared/CodeEditorW';

const DistributionSimulator = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [inputData, setInputData] = useState({});
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("basic");

  useEffect(() => {
    if (mode == "advanced") {
      window.global.templateSimRe.setValue(
        `cov:\n  limit: ${inputData.insuredSum ? inputData.insuredSum : 0}\n  premium: ${inputData.premium ? inputData.premium : 0}\npol:\n  id: 1`
      );
    }
  }, [mode]);

  const onSimulate = () => {
    if (mode == "basic") {
      inputData.context = undefined;
    } else {
      try {
        const context = yaml.safeLoad(inputData.context);
        inputData.context = context ? JSON.stringify(context) : undefined;
        inputData.insuredSum = context.cov.limit;
        inputData.premium = context.cov.premium;
      } catch (error) {
        notification["error"]({
          message: error.reason,
          description: error.message,
        });
        return;
      }
    }

    setLoading(true);
    exe(props.coinsurance?"ComputeCoCoverage":"ComputeCovereage", { ...inputData, configJson: props.configJson,contractId:props.contractId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Modal
        title={t("Distribution Simulator")}
        okText={t("Simulate")}
        cancelText={t("Close")}
        visible={props.visible}
        width="950px"
        onOk={() => onSimulate()}
        onCancel={() => props.onClose()}>
        <Radio.Group onChange={(v) => setMode(v.target.value)} value={mode}>
          <Radio value="basic">{t("Basic")}</Radio>
          <Radio value="advanced">{t("Advanced")}</Radio>
        </Radio.Group>
        {mode == "basic" && (
          <div>
            <Form.Item label={t("Insured Sum")}>
              <Input value={inputData.insuredSum} onChange={(v) => setInputData({ ...inputData, insuredSum: v.target.value })} />
            </Form.Item>
            <Form.Item label={t("Premium")}>
              <Input value={inputData.premium} onChange={(v) => setInputData({ ...inputData, premium: v.target.value })} />
            </Form.Item>
          </div>
        )}
        {mode == "advanced" && (
          <div style={{ marginTop: 10, marginBottom: 5 }}>
            {t("YAML context")}:
            <CodeEditorW divId="templateSimRe" value={inputData.context} onChange={(v) => setInputData({ ...inputData, context: v })} />
          </div>
        )}

        <Table dataSource={data} loading={loading}>
          <Table.Column title={t("Line")} dataIndex="lineId" />
          <Table.ColumnGroup title={t("Cedant")}>
            <Table.Column
              title={t("Sum Insured")}
              dataIndex="sumInsuredCedant"
              render={(v, r) => <span style={{ color: "blue" }}>{formatMoney(v, r.currency)}</span>}
            />
            <Table.Column title="%" dataIndex="proportionCed" render={(v, r) => formatPercentRound2(v)} />
            <Table.Column title={t("Premium")} dataIndex="premiumCedant" render={(v, r) => formatMoney(v, r.currency)} />
            <Table.Column title={t("Commission")} dataIndex="comissionCedant" render={(v, r) => formatMoney(v, r.currency)} />
          </Table.ColumnGroup>
          <Table.ColumnGroup title={t("Reinsurer")}>
            <Table.Column
              title={t("Sum Insured")}
              dataIndex="sumInsuredRe"
              render={(v, r) => <span style={{ color: "blue" }}>{formatMoney(v, r.currency)}</span>}
            />
            <Table.Column title="%" dataIndex="proportionRe" render={(v, r) => formatPercentRound2(v)} />
            <Table.Column title={t("Premium")} dataIndex="premiumRe" render={(v, r) => formatMoney(v, r.currency)} />
          </Table.ColumnGroup>
          <Table.Column title={t("Msg")} dataIndex="msg" />
        </Table>
      </Modal>
    </div>
  );
};

export default DistributionSimulator;
