import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const AmlRiskSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => load(), []);

  const load = () => {
    exe("RepoAmlRiskCatalog", { operation: "GET" }).then((r) => {
      setData(r.outData);
    });
  };
  const onChange = (v) => {
    props.onChange(v);
  };
  const value = props.value;
  return (
    <div>
      <Select
        style={{ width: "100%" }}
        placeholder={t("Please select AML Risk Class")}
        onChange={onChange}
        value={value}
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {data.map((p) => (
          <Select.Option value={p.id} key={p.id}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default AmlRiskSelect;
