import React, { useEffect, useState,useContext } from "react";
import { Select,ConfigProvider } from "antd";
import "currency-flags/dist/currency-flags.min.css";
import getCache from "../../Lib/Cache";
import {useTranslation} from "react-i18next";

const { Option } = Select;

const Currency = (props) => {
  const currencies = [
    { code: "EUR", name: "EUR" },
    { code: "USD", name: "USD" },
    { code: "GBP", name: "GBP" },
    { code: "JPY", name: "JPY" },
    { code: "CNY", name: "CNY" },
    { code: "KRW", name: "KRW" },
    { code: "RON", name: "RON" },
    { code: "LOC", name: "LOC" },
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(currencies);
  const [t]=useTranslation();
  const configContext = useContext(ConfigProvider.ConfigContext);
  const direction = configContext.direction;

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (props.filter) {
      const allowedCurrencies = props.filter.split(",");
      setData(data.filter((p) => allowedCurrencies.includes(p.code)));
    }
  }, [props.filter]);

  const load = () => {
    setLoading(true);
    getCache("RepoCurrency").then((r) => {
      setLoading(false);
      setData(r.outData.filter((d) => d.enabled));
    });
  };
  return (
    <Select disabled={props.disabled} style={props.style} value={props.value} onChange={props.onChange} allowClear loading={loading} placeholder={t("Currency")}>
      {data.map((p) => (
        <Option value={p.code} key={p.code} disabled={props.excluded&&props.excluded.includes(p.code)}>
          <div style={{ marginTop: 3,...(direction=="rtl"?{marginLeft:5}:{marginRight:5}) }} className={"currency-flag currency-flag-sm currency-flag-" + p.code.toLowerCase()} />
          {p.name}
        </Option>
      ))}
    </Select>
  );
};
Currency.Flag = (props) => {
  if (props.currency)
    return (
      <div style={{ marginLeft: 5, marginRight: 5, marginTop: 3 }} className={"currency-flag currency-flag-sm currency-flag-" + props.currency.toLowerCase()} />
    );
  else return null;
};

export default Currency;
