import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Badge, Checkbox, message, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const CheckFacultative = (props) => {
  const [checkFacultative, setCheckFacultative] = useState({});
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if(props.policyId>0) load();
  }, [props.policyId]);

  const load = () => {
    setLoading(true);
    exe("CheckFacultative", { policyId: props.policyId }).then((r) => {
      setLoading(false);
      if (r.ok) setCheckFacultative(r.outData);
      else message.error(r.msg);
    });
  };

  //return <Badge status="warning" text={checkFacultative.hasFacultative?t("Facultative"):"-"} />
  //return <Checkbox checked={checkFacultative.hasFacultative}>{t("Facultative")}</Checkbox>
  return <Form.Item label={t("Facultative")}><Switch disabled checked={checkFacultative.hasFacultative} /></Form.Item>
};

export default CheckFacultative;