import React from "react";
import { Timeline, Empty } from "antd";
import { formatDate } from "../../Lib/Helpers";

const Events = (props) => {
  const events = props.value || [];
  const getColor = (type) => {
    switch (type) {
      case "FILE":
        return "blue";
        break;
      case ("ACTION", "SUCCESS"):
        return "green";
        break;
      case ("FINAL", "WARNING"):
        return "orange";
        break;
      case "ERROR":
        return "red";
        break;

      default:
        return "gray";
        break;
    }
  };
  if (events.length == 0) return <Empty />;
  return (
    <div>
      <Timeline>
        {events.map((p, index) => (
          <Timeline.Item key={"treeItem" + index} color={getColor(p.type)}>
            <p style={{ marginBottom: 0 }}>
              <b>{p.name}</b>
            </p>
            <p style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: p.description }} />
            <p style={{ marginBottom: 0 }}>{p.user}</p>
            <p style={{ marginBottom: 0 }}>{formatDate(p.date)}</p>
          </Timeline.Item>
        ))}
      </Timeline>
    </div>
  );
};

export default Events;
