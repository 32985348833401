import { DeleteOutlined, DollarOutlined, FileOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Divider, message, Popconfirm, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import LiquidationCatRecord from "./LiquidationCatRecord";

const LiquidationCat = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [newLiquidationVisible, setNewLiquidationVisible] = useState(false);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setLoading(true);
    exe("RepoLiquidationCat", { operation: "GET", include: ["ReEvent"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSave = () => {
    load();
    setNewLiquidationVisible(false);
  };
  const onExecute = (liquidations) => {
    setLoading(true);
    liquidations.forEach((liquidation) => {
      exe("ExeLiquidationCat", { liquidationId: liquidation.id }).then((r) => {
        setLoading(false);
        if (r.ok) {
          message.success(r.msg);
          load();
        } else {
          message.error(r.msg);
        }
      });
    });

    setNewLiquidationVisible(false);
  };

  const onDelete = (r) => {
    setLoading(true);
    exe("RepoLiquidationCat", { operation: "DELETE", entity: r }).then((r) => {
      setLoading(false);
      load();
    });
  };
  const getStatusText = (v) => {
    if (v == 0) {
      return <Tag>{t("Draft")}</Tag>;
    } else if (v == 1) {
      return <Tag color="green">{t("Executed")}</Tag>;
    } else {
      return <Tag>{v}</Tag>;
    }
  };
  return (
    <div>
      <div style={{ display: !newLiquidationVisible ? "block" : "none" }}>
        <Button type="primary" icon={<FileOutlined />} onClick={() => setNewLiquidationVisible(true)}>
          {t("New")}
        </Button>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => load()}>
          {t("Reload")}
        </Button>
        <Table dataSource={data} loading={loading} rowKey="id">
          <Table.Column title={t("Id")} dataIndex="id" />
          <Table.Column title={t("Event")} dataIndex={["ReEvent","name"]} />
          <Table.Column title={t("Layer")} dataIndex="line" />
          <Table.Column title={t("Name")} dataIndex="liquidationName" />
          <Table.Column title={t("Date")} dataIndex="creation" render={(v, r) => formatDateShort(v)} />
          <Table.Column title={t("Retained Loss")} dataIndex="retainedLoss" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Cedded Loss")} dataIndex="cededLoss" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Claims")} dataIndex="claims" />
          <Table.Column title={t("Status")} dataIndex="status" render={(v) => getStatusText(v)} />
          <Table.Column
            title={t("Actions")}
            key="actions"
            render={(v, r) => (
              <div style={{ display: r.id && r.status == 0 ? "block" : "none" }}>
                <Popconfirm title={t("Are you sure you want to delete this entry?")} onConfirm={() => onDelete(r)}>
                  <Button type="link" icon={<DeleteOutlined />} />
                </Popconfirm>
                <Divider type="vertical" />
                <Popconfirm title={t("Are you sure you want to execute this liquidation?")} onConfirm={() => onExecute([r])}>
                  <Button type="link" icon={<DollarOutlined />} />
                </Popconfirm>
              </div>
            )}
          />
        </Table>
      </div>
      <div style={{ display: newLiquidationVisible ? "block" : "none" }}>
        <LiquidationCatRecord contractId={props.contractId} onCancel={() => setNewLiquidationVisible(false)} onSave={onSave} onExecute={onExecute} />
      </div>
    </div>
  );
};

export default LiquidationCat;
