import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, message, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeValue } from "../../Lib/Dal";
import { formatMoney } from "../../Lib/Helpers";
import AddCertificate from "./AddCertificate";
import PolicyLink from "./PolicyLink";

const CertificateImage = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [addCertificateVisible, setAddCertificateVisible] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.value) setData(JSON.parse(props.value));
    //saved serialized in change record
  }, [props.value]);

  const onAddCertificate = () => {
    setAddCertificateVisible(true);
  };
  const onSubmitNewCertificate = (values) => {
    setAddCertificateVisible(false);
    const newData = [...data, values];
    setData(newData);
    props.onChange(JSON.stringify(newData));
  };
  const onRemove = (record) => {
    const newData = data.filter((p) => p.Insureds[0].name !== record.Insureds[0].name);
    setData(newData);
    props.onChange(JSON.stringify(newData));
  };
  return (
    <div>
      {props.editable && (
        <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: 5 }} onClick={onAddCertificate} disabled={!props.groupPolicyId}>
          {t("Add")}
        </Button>
      )}
      <Table dataSource={data}>
        <Table.Column title={t("ID")} dataIndex="id" render={v=><PolicyLink policyId={v} tab="/tab1" />} />
        <Table.Column title={t("Insured")} dataIndex={["Insureds",0,"name"]} />
        <Table.Column title={t("Base Insured Sum")} dataIndex="insuredSum" render={(v, r) => formatMoney(v, props.currency)} />
        <Table.Column title={t("Group")} dataIndex="group" />
        <Table.Column
          title={t("Actions")}
          render={(v, r) => (
            <div>
              <Button type="link" icon={<DeleteOutlined />} onClick={() => onRemove(r)} disabled={props.readOnly}>
                {t("Remove")}
              </Button>
            </div>
          )}
        />
      </Table>
      <Modal title={t("Add Certificate")} visible={addCertificateVisible} footer={false} onCancel={() => setAddCertificateVisible(false)}>
        <AddCertificate currency={props.currency} onSubmit={(v) => onSubmitNewCertificate(v)} groups={props.groups} config={props.config} />
      </Modal>
    </div>
  );
};

export default CertificateImage;
