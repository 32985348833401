import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";

const RoleSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => load(), []);

  const load = () => {
    getCache("RepoRoleCatalog").then((r) => {
      setData(r.outData);
    });
  };
  const onChange = (v) => {
    props.onChange(v.map((p) => ({ role: p })));
  };
  const value = props.value || [];
  return (
    <div>
      <Select mode="multiple" style={{ width: "100%" }} placeholder={t("Please select roles")} onChange={onChange} optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              value={value.map((p) => p.role)} onDeselect={v=>props.onChange(value.map((p) => p.role).filter(q=>q!==v))}>
        {data.filter(p=>props.personType?(p.personType==props.personType||!p.personType):true).map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default RoleSelect;
