import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Popconfirm, Row, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { SaveOutlined, LeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import ContractSelect from "./ContractSelect";
import DatePickerW from "../Shared/DatePickerW";
import SelectionSelect from "./SelectionSelect";

const PortfolioTransferForm = (props) => {
    const [loading,setLoading]=useState(false);
    const [t] = useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        if(props.value){
            if(props.value.id==0){
                form.resetFields();
            }else form.setFieldsValue(props.value);
        }
    }
    ,[props.value])

    const save=()=>{
        form.validateFields().then(values=>{
            console.log(values);
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoPortfolioTransfer",{operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onSave(values);
                    props.onCancel();

                }else{
                    message.error(r.msg);
                }
            })

        })
    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoPortfolioTransfer",{operation:"DELETE",entity:props.value}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                props.onSave(props.value);
                props.onCancel();
            }else message.error(r.msg);
        })
    }
    const scheduledValidator=(rule,value)=>{
        //allowing only future dates
        if(value && value.isBefore(new Date(),"day")) return Promise.reject(t("Scheduled date must be in the future"));
        return Promise.resolve();
    }
    

const status=Form.useWatch("status",form);
    return <div>
        <Button type="link" icon={<LeftOutlined />} onClick={props.onCancel}>{t("Back")}</Button>
        <Button type="link" icon={<SaveOutlined />} onClick={save} loading={loading} disabled={status=="EXECUTED"}>{t("Save")}</Button>
        <Popconfirm  title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={!props.value||!props.value.id||status=="EXECUTED"}><Button type="link" icon={<DeleteOutlined />}  disabled={!props.value||!props.value.id||status=="EXECUTED"} >{t("Delete")}</Button></Popconfirm> 
        <Form form={form} layout="vertical">
            <Form.Item label={t("id")} name={"id"} hidden><Input disabled/></Form.Item>
            <Form.Item label={t("created")} name={"created"} hidden><Input disabled/></Form.Item>
            <Form.Item label={t("createdBy")} name={"createdBy"} hidden><Input disabled/></Form.Item>
            <Form.Item label={t("status")} name={"status"} hidden><Input disabled/></Form.Item>
            <Form.Item label={t("executed")} name={"executed"} hidden><Input disabled/></Form.Item>
            <Form.Item label={t("Name")} name={"name"} rules={[{required:true}]}><Input /></Form.Item>
            <Form.Item label={t("Destination Contract")} name={"destinationContractId"} rules={[{required:true}]}><ContractSelect /></Form.Item>
            <Form.Item label={t("Cession Selection")} name={"selectionId"} rules={[{required:true}]}><SelectionSelect visible entity="Cession" showView /></Form.Item>
            <Form.Item label={t("Scheduled Execution Date")} name={"scheduled"} rules={[{required:false,validator:scheduledValidator}]}><DatePickerW/></Form.Item>
        </Form>
    </div>
}
export default PortfolioTransferForm;