import React, {useEffect, useState} from "react";
import { FolderOpenOutlined } from '@ant-design/icons';
import { Select, Tooltip, Divider } from "antd";
import { exe } from "Lib/Dal";
import { formatDateShort } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";

//used inside forms = props = fn onChange, string value
const ClaimSelect = (props) => {
  const [t] = useTranslation();
  
  useEffect(()=>{
      if(props.value){
          exe("RepoClaim", { operation: "GET", filter: `id=${props.value}` }).then((r) => {
              setData(r.outData || []);
              if(r.count>0){
                  const claim=r.outData[0];
                  props.onSelect&&props.onSelect(claim.id,{props:{record:claim}});
              }
          });
      }
  },[props.value])

  const [data, setData] = useState([]);
  const handleSearch = (v) => {
    if (!v) return;
    exe("RepoClaim", { operation: "GET", filter: `id LIKE N'%${v}%'` }).then((r) => {
      setData(r.outData || []);
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Select
        style={{ width: "100%" }}
        onSearch={handleSearch}
        onChange={props.onChange}
        value={props.value}
        showSearch
        filterOption={false}
        allowClear
        showArrow={false}
        placeholder={t("Type to search claim...")}>
        {data.map((d) => (
          <Select.Option key={d.id} value={d.id}>
            {d.id}-{formatDateShort(d.created)}
          </Select.Option>
        ))}
      </Select>
      <a href={"#/healthclaim/" + props.value} onClick1={()=>window.open("#/healthclaim/" + props.value, '_blank').focus()}>
        <Tooltip title={t("Open Claim")}>
          <FolderOpenOutlined
            style={{
              position: "absolute",
              right: "30px",
              top: "9px",
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.25)",
              fontSize: 12,
            }} />
        </Tooltip>
      </a>
    </div>
  );
};

export default ClaimSelect;
