import { Button, Form, Modal, Table, message, Space, Popconfirm, Input, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { exe } from '../../Lib/Dal';
import Contact from '../Contact';
import ContactSelect from '../Contact/ContactSelect';
import { PlusOutlined, LineChartOutlined, DeleteOutlined, ReloadOutlined, UndoOutlined, SearchOutlined, FilterOutlined, FolderOpenOutlined, UserOutlined, SaveOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PensionMemberStatusSelect from './PensionMemberStatusSelect';
import SimpleContactSelect from '../Contact/SimpleContactSelect';
import { Drawer } from 'antd';
import { InputNumber } from 'antd';
import ContributionStatusSelect from './ContributionStatusSelect';
import { Badge } from 'antd';
import { Descriptions } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import PensionMemberRequirements from './PensionMemberRequirements';
import Accounts from '../Life/Accounts';
import useUpdateEffect from '../../Lib/Helpers';
import PensionProjection from './PensionProjection';
import FormLabel from '../Shared/FormLabel';


const PensionMembers=props=>{
    const [data,setData]=useState([]);
    const [totals,setTotals]=useState({inactive:0,invalid:0,active:0,records:0});
    const [selected,setSelected]=useState();
    const [form]=Form.useForm();
    const [filterForm]=Form.useForm();
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    const [filterVisible,setFilterVisible]=useState(false); 
    const [pagination,setPagination]=useState({pageSize:10,current:1,total:0,showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`});

    useEffect(()=>{
        if(props.pensionId) onSearch();
    },[props.pensionId])
    useEffect(()=>{
        if(selected) form.setFieldsValue(selected);
    },[selected])

    useUpdateEffect(() => {
        onSearch();
      }, [pagination.current]);
    

    const onMemeberSave=(close=true)=>{
        form.validateFields().then(values=>{
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoPensionMember",{operation:operation,entity:{...values,pensionSchemeId:props.pensionId}}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    onSearch();
                    if(close){
                        setSelected();
                    }else{
                        form.setFieldsValue(r.outData[0])
                    }
                }else message.error(r.msg);
            })
        })
    }
    const onNewMember=()=>{
        setSelected({id:0,pensionSchemeId:props.pensionId});
        form.resetFields();
    }
    const onDeleteMember=(id)=>{
        setLoading(true);
        exe("RepoPensionMember",{operation:"DELETE",entity:{id:id}}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                onSearch();
            }else message.error(r.msg);
        })
    }
    const onSearch=()=>{
        if(!props.pensionId) return;

        const values=filterForm.getFieldsValue();
        
        let filter=`pensionSchemeId=${props.pensionId}`;
        if(values.id) filter+=` and id=${values.id}`;
        if(values.contactId) filter+=` and contactId=${values.contactId}`;
        if(values.nationalId) filter+=` and contactId in (SELECT id from Contact WHERE nationalId=${values.nationalId})`;
        if(values.statusCode!==undefined) filter+=` and statusCode='${values.statusCode}'`;
        if(values.name!==undefined) filter+=` and contactId in (SELECT id from Contact WHERE (RTRIM(ISNULL([name],''))+' '+RTRIM(ISNULL(surname1,''))+' '+RTRIM(ISNULL(surname2,''))) LIKE N'%${values.name}%')`;
        if(values.code) filter+=` and code='${values.code}'`;

        setLoading(true);
        exe("RepoPensionMember",{operation:"GET",size:pagination.pageSize,page:pagination.current-1,
        filter:filter,include:["Contact","PensionMemberStatus","Accounts"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
                setTotals(r.outDataAux);
                setPagination({...pagination,total:r.total,current:pagination.total!==r.total?1:pagination.current,showTotal:(total, range) => `${range[0]}-${range[1]} of ${total} items`});
            }else message.error(r.msg);
        })
    }
    return (
        <div>
            <Button type="link" icon={<ReloadOutlined />} onClick={()=>onSearch()}>{t("Reload")}</Button>
            <Button type="link" icon={<PlusOutlined />} onClick={onNewMember}>{t("Manual Add")}</Button>
            <Button type="link" onClick={()=>setFilterVisible(true)} icon={<FilterOutlined />} >{t("Filter")}</Button>

            <Descriptions  column={4} size="small" bordered>
            <Descriptions.Item label={t("Active")}>{totals.active}</Descriptions.Item>
            <Descriptions.Item label={t("Inactive")}>{totals.inactive}</Descriptions.Item>
            <Descriptions.Item label={t("Records")}>{totals.records}</Descriptions.Item>
            <Descriptions.Item label={t("Invalid")}>{totals.invalid}</Descriptions.Item>
        </Descriptions>

            <Table dataSource={data} rowKey={"id"} pagination={pagination} onChange={p=>setPagination(p)}>
                <Table.Column title={t("Actions")} key="action" render={(text, record) => (<div>
                        <Button type="link" icon={<FolderOpenOutlined/>} onClick={()=>setSelected(record)} />
                        </div>
                )} />                
                <Table.Column title={t("Member Id")} dataIndex="id" />
                <Table.Column title={t("Member Code")} dataIndex="code" />
                <Table.Column title={t("Contact Id")} dataIndex="contactId" />
                <Table.Column title={t("National Id")} dataIndex={["Contact","nationalId"]}  />
                <Table.Column title={t("Name")} dataIndex={["Contact","FullName"]} render={(v,r)=><a href={`#/contact/${r.contactId}`}>{v}</a>} />
                <Table.Column title={t("Age")} dataIndex={["Contact","currentAge"]} />
                <Table.Column title={t("Status")} dataIndex={["PensionMemberStatus","name"]} />
                <Table.Column title={t("Valid")} dataIndex={["PensionMemberStatus","invalid"]} render={v=><Badge status={v?"error":"success"} />} />
            </Table>

            <Modal title={t("Pension Member")} visible={selected} okText={t("Save")} cancelText={t("Cancel")} destroyOnClose
            onCancel={()=>setSelected()} onOk={onMemeberSave} okButtonProps={{loading:loading}} width={800} bodyStyle={{paddingTop:5}}>
          
            <Space>
                {/* <Button type="link" icon={<SaveOutlined />} style={{padding:0}} loading={loading} onClick={()=>onMemeberSave(false)}>{t("Save")}</Button> */}
                <Popconfirm title={t("Are you sure to delete this member?")} onConfirm={()=>onDeleteMember(selected.id)} disabled={!selected||!selected.id}>
                    <Button type="link" icon={<DeleteOutlined />} style={{padding:0}} disabled={!selected||!selected.id}>{t("Delete")}</Button>
                </Popconfirm> 
                <a href={`#/contact/${selected?selected.contactId:0}`}><Button type="link" icon={<UserOutlined />} style={{padding:0}} disabled={!selected||!selected.id} >{t("Contact")}</Button></a>
            </Space>
                <Form form={form} layout='vertical'>
                    <Tabs>
                        <TabPane tab={t("General")} key="general">
                            <Form.Item name="id" hidden/>
                            <Form.Item name="jProjections" hidden/>
                            <Form.Item name="contactId" label="Contact" rules={[{required:true}]}><SimpleContactSelect allowNew /></Form.Item>
                            <Form.Item name="statusCode" label="Status" rules={[{required:true}]}><PensionMemberStatusSelect /></Form.Item>
                            <Form.Item name="statusMsg" label="Status Message"><Input.TextArea /></Form.Item>
                            <Form.Item name="code" label="Code"><FormLabel /></Form.Item>
                        </TabPane>
                        <TabPane tab={t("Requirements")} key="requirements" disabled={!selected||!selected.id}>
                            <PensionMemberRequirements pensionMemberId={selected?selected.id:0} contactId={selected?selected.contactId:0} />
                        </TabPane>
                        <TabPane tab={t("Accounts")} key="accounts" disabled={!selected||!selected.id}>
                            <Accounts value={selected?selected.Accounts:[]} />
                        </TabPane>
                        <Tabs.TabPane tab={<span><LineChartOutlined />{t("Projections")}</span>} key="projections" disabled={!selected||!selected.id}>
                             <PensionProjection pensionId={props.pensionId} productCode={props.productCode} projections={selected?selected.jProjections:undefined} type="MEMBER" memberId={selected&&selected.id}  />
                        </Tabs.TabPane>
                    </Tabs>
                </Form>
            </Modal>
        
        <Drawer title={t("Member Search")} placement="right" width={512} 
        visible={filterVisible} onClose={()=>setFilterVisible(false)}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => filterForm.resetFields()}>
            {t("Reset")}
          </Button>
        </div>
            <Form layout="vertical" form={filterForm}>
            <Form.Item name="id" label={t("Member Id")}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="code" label={t("Member Code")}>
                <Input />
            </Form.Item>
            <Form.Item name="contactId" label={t("Contact Id")}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="nationalId" label={t("National Id")}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="name" label={t("Name")}>
                <Input />
            </Form.Item>
            <Form.Item name="statusCode" label={t("Status")}>
                <PensionMemberStatusSelect />
            </Form.Item>
    
            
            <Button type="primary" icon={<SearchOutlined />} onClick={onSearch} loading={loading}>{t("Search")}</Button>
            </Form>
        </Drawer>
        </div>
    );
}
export default PensionMembers;