import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import {useTranslation} from "react-i18next";

const BankAccountTypeSelect = (props) => {
  const [data, setData] = useState([]);
  const [t]=useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    exe("RepoBankAccountTypeCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) setData(r.outData);
      else message.error(r.msg);
    });
  };
  return (
    <div>
      <Select allowClear value={props.value} onChange={props.onChange}>
        {data.map((p) => (
          <Select.Option value={p.code}>{t(p.name)}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default BankAccountTypeSelect;
