import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Modal, Switch } from "antd";
import {useTranslation} from "react-i18next";
import DateField from "../Shared/DateField";

const RecalculateModal = props => {
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    const onOk=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onOk(values);
        })
    }
    return (
        <Modal title={t("Period recalculation")} visible={props.visible} onCancel={props.onCancel} onOk={onOk} >
            <Form.Item label={t("Policy")}>{field("policyId",{rules:[{required:true}]})(<InputNumber />)}</Form.Item>
            <Form.Item label={t("Start Date")}>{field("start",{rules:[{required:true}]})(<DateField />)}</Form.Item>
            <Form.Item label={t("Background")}>{field("background",{valuePropName:"checked",initialValue:true})(<Switch disabled />)}</Form.Item>
        </Modal>
    );
};

export default Form.create()(RecalculateModal);