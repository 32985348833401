import React, { useEffect, useState } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Drawer,
    Input,
    Table,
    message,
    Button,
    Select,
    Radio,
    Row,
    Col,
    InputNumber,
    DatePicker,
    Checkbox,
} from "antd";
import { exe } from "Lib/Dal";
import PolicySelect from "../Health/PolicySelect";
import Compare from "../Shared/Compare";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import DistributionModeSelect from "./DistributionModeSelect";
import useUpdateEffect from "../../Lib/Helpers";
import moment from "moment";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import ContractCodeSelect from "./ContractCodeSelect";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

const ClaimCessionsFilter = (props) => {
    const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (window.location.hash.includes("?policyId=")) {
            const policy = window.location.hash.split("?policyId=")[1];
            props.form.setFieldsValue({ policyId: policy });
            onSearch();
        } else {
            if (props.contractId) {
                //called from the contract/borderau screen
                props.form.setFieldsValue({ date: new moment(), contractId: props.contractId });
            } else {
                //called from cessions
                props.form.setFieldsValue({ date: new moment() });
                onSearch();
            }
        }
    }, []);

    useUpdateEffect(() => {
        onSearch();
    }, [props.doReload]);

    const onSearch = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
                let queries = [];
                if (values.lifepolicyId) queries.push(`cessionId in (SELECT id FROM cession WHERE lifepolicyId=${values.lifepolicyId})`);
                if (values.policyId) queries.push(`cessionId in (SELECT id FROM cession WHERE lifepolicyId=${values.policyId})`);
                if (values.contractId) queries.push(`contractId=${values.contractId}`);
                if (values.id) queries.push(`id=${values.id}`);
                if (values.cessionId) queries.push(`cessionId=${values.cessionId}`);
                if (values.claimId) queries.push(`lifeCoveragePayoutId in (SELECT id FROM LifeCoveragePayout WHERE claimId=${values.claimId})`);
                if (values.lob) queries.push(`cessionId in (SELECT id FROM cession WHERE LoB='${values.lob}')`);
                if (values.coverageCode) queries.push(`cessionId in (SELECT id FROM cession WHERE coverageCode='${values.coverageCode}')`);
                if (values.currency) queries.push(`currency='${values.currency}'`);
                if (values.sumInsured) queries.push(`cessionId in (SELECT id FROM cession WHERE sumInsured${values.sumInsured})`);
                if (values.date) queries.push(`YEAR(claimOccurrence)=${values.date.year()} AND MONTH(claimOccurrence)=${values.date.month() + 1}`);
                if (values.distributionMode) queries.push(`cessionId in (SELECT id FROM cession WHERE distributionMode=${values.distributionMode})`);
                if (values.lineId) queries.push(`cessionId in (SELECT id FROM cession WHERE lineId='${values.lineId}')`);
                if (values.code) queries.push(`contractId in (SELECT id from Contract where code='${values.code}')`);
                // if (values.period) {
                //     const year = values.period.year();
                //     const month = values.period.month() + 1;
                //     const lastDay = new Date(year, month, 0).getDate();
                //     queries.push(
                //         `YEAR(start)=${year} AND MONTH(start)=${month} AND DAY(start)=1 AND YEAR([end])=${year} AND MONTH([end])=${month} AND DAY([end])=${lastDay}`
                //     );
                // }
                if (values.range) {
                    queries.push(`claimOccurrence between '${values.range[0].format("YYYY-MM-DD")}' and  '${values.range[1].format("YYYY-MM-DD")}'`);
                }
                if (values.creationRange) {
                    queries.push(`lifeCoveragePayoutId in (SELECT id FROM LifeCoveragePayout WHERE date between '${values.creationRange[0].format("YYYY-MM-DD")}' AND  '${values.creationRange[1].format("YYYY-MM-DD")}')`);
                }

                if (values.participantId !== undefined) {
                    queries.push(`id in (select cessionId from LossCessionPart where contactId=${values.participantId})`);
                }
                if (values.facultative) queries.push(`cessionId in (SELECT id in cession WHERE lineId='FAC')`);
                //filtering by policyholder ie. loss cession -> cession -> policy -> policyholder
                if(values.holderId) queries.push(`cessionId in (SELECT id FROM cession WHERE lifepolicyId in (SELECT id FROM LifePolicy WHERE holderId=${values.holderId}))`);

                const filterQuery = queries.join(" AND ");
                console.log(queries, filterQuery);

                props.onLoading&&props.onLoading(true);
                exe("RepoLossCession", { operation: "GET", filter: filterQuery, include: ["Cession", "Payout"] }).then((r) => {
                    props.onLoading&&props.onLoading(false);
                    if (r.ok) {
                        props.onSearch(r.outData);
                    } else {
                        message.error(r.msg);
                    }
                });
            }
        });
    };
    const { getFieldDecorator } = props.form;
    return (
        <div>
            <Drawer title={t("Claim Cession Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
                <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                    <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
                        {t("Reset")}
                    </Button>
                </div>

                <Form layout="vertical">
                    <Form.Item label={t("Occurrence Period")}>{getFieldDecorator("date")(<DatePicker.MonthPicker />)}</Form.Item>
                    <Form.Item label={t("Occurrence Range")}>{getFieldDecorator("range")(<DatePicker.RangePicker />)}</Form.Item>
                    <Form.Item label={t("Creation Range")}>{getFieldDecorator("creationRange")(<DatePicker.RangePicker />)}</Form.Item>
                    <Form.Item label={t("Policy")}>{getFieldDecorator("lifepolicyId")(<PolicySelect />)}</Form.Item>
                    <Form.Item label={t("Policyholder")}>{getFieldDecorator("holderId")(<SimpleContactSelect />)}</Form.Item>
                    <Form.Item label={t("LoB")}>{getFieldDecorator("lob")(<Input />)}</Form.Item>
                    <Form.Item label={t("Coverage Code")}>{getFieldDecorator("coverageCode")(<Input />)}</Form.Item>
                    <Form.Item label={t("Contract Code")}>{getFieldDecorator("code")(<ContractCodeSelect style={{width:"100%"}} />)}</Form.Item>
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Policy Id")} style={{ marginRight: 5 }}>
                            {getFieldDecorator("policyId")(<InputNumber />)}
                        </Form.Item>
                        <Form.Item label={t("Claim Id")} style={{ marginRight: 5 }}>
                            {getFieldDecorator("claimId")(<InputNumber />)}
                        </Form.Item>
                        <Form.Item label={t("Treaty Id")} style={{ marginRight: 5 }}>
                            {getFieldDecorator("contractId")(<InputNumber />)}
                        </Form.Item>
                        <Form.Item label={t("Loss Cession Id")}>{getFieldDecorator("id")(<InputNumber />)}</Form.Item>
                    </div>
                    <div style={{display:"flex"}}>
                        <Form.Item label={t("Cession Id")} style={{ marginRight: 5 }}>{getFieldDecorator("cessionId")(<InputNumber />)}</Form.Item>
                        <Form.Item label={t("Line Id")} >{getFieldDecorator("lineId")(<Input />)}</Form.Item>
                        
                    </div>
                    <Form.Item label={t("Currency")}>{getFieldDecorator("currency")(<Currency />)}</Form.Item>
                    <Form.Item label={t("Sum Insured")}>{getFieldDecorator("sumInsured")(<Compare />)}</Form.Item>
                    <Form.Item label={t("Distribution Mode")}>{getFieldDecorator("distributionMode")(<DistributionModeSelect />)}</Form.Item>
                    {/*<Form.Item label={t("Accrued Period")}>{getFieldDecorator("period")(<DatePicker.MonthPicker />)}</Form.Item>*/}
                    <Form.Item label={t("Participant")}>{getFieldDecorator("participantId")(<SimpleContactSelect />)}</Form.Item>
                    <Form.Item label={t("Facultative Only")}>{getFieldDecorator("facultative",{valuePropName:"checked"})(<Checkbox />)}</Form.Item>
                </Form>
                <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()}>
                    {t("Search")}
                </Button>
            </Drawer>
        </div>
    );
};

export default Form.create()(ClaimCessionsFilter);
