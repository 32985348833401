import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Col, Input, InputNumber, message, Modal, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import Currency from "../Shared/Currency";
import CatalogAccountTypeSelect from "./CatalogAccountTypeSelect";
import AccountNatureSelect from "./AccountNatureSelect";
import CatalogAuxTypeSelect from "./CatalogAuxTypeSelect";

const AccountDetail = (props) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.new) {
      //new child
      if (props.selected) {
        props.form.resetFields();
        props.form.setFieldsValue({
          parentCode: props.selected.code,
          code: props.selected.code + props.separator,
          level: props.selected.level + 1,
          currency: props.selected.currency,
          accountType: props.selected.accountType,
          nature: props.selected.nature,

        });
      } else {
        //new root
        props.form.setFieldsValue({ parentCode: null });
      }
    } else {
      //edit
      props.form.setFieldsValue(props.selected);
    }
  }, [props.visible]);

  const [t] = useTranslation();
  const onSave = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      let oldCode;
      if (!props.new && props.selected.code !== values.code) oldCode = props.selected.code;
      exe("RepoCatalogAccount", { operation: props.new ? "ADD" : "UPDATE", entity: values, oldCode: oldCode }).then((r) => {
        setLoading(false);
        if (r.ok) {
          props.onOk(r.outData);
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const field = props.form.getFieldDecorator;

  return (
    <div>
      <Modal visible={props.visible} title={t(props.new ? "New Account" : "Account Detail")} onOk={onSave} onCancel={() => props.onCancel()} okButtonProps={{ loading: loading }}>
        <Form.Item label={t("Code")}>{field("code", { rules: [{ required: true }] })(<Input placeholder={t("Please enter account code")} />)}</Form.Item>
        <Form.Item label={t("Name")}>{field("name", { rules: [{ required: true }] })(<Input placeholder={t("Please enter account name")} />)}</Form.Item>
        <Form.Item label={t("Currency")}>{field("currency", { rules: [{ required: false }] })(<Currency style={{ width: "100%" }} />)}</Form.Item>
        <Form.Item label={t("Parent Code")}>{field("parentCode", { rules: [{ required: false }] })(<Input disabled />)}</Form.Item>
        <Form.Item label={t("Account Type")}>{field("accountType", { rules: [{ required: true }] })(<CatalogAccountTypeSelect />)}</Form.Item>
        <Form.Item label={t("Account Nature")}>{field("nature", { rules: [{ required: true }] })(<AccountNatureSelect />)}</Form.Item>
        <Form.Item label={t("Auxiliary Type")}>{field("auxType")(<CatalogAuxTypeSelect />)}</Form.Item>
        <Form.Item label={t("Level")}>{field("level", { rules: [{ required: true }] })(<InputNumber />)}</Form.Item>
        <Form.Item label={t("Is Detail Account")}>{field("isDetail", { valuePropName: "checked" })(<Switch />)}</Form.Item>
      </Modal>
    </div>
  );
};

export default Form.create()(AccountDetail);
