import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

const YesNoSelect = (props) => {
  const [t] = useTranslation();
  return (
    <Select allowClear value={props.value} onChange={props.onChange} placeholder={t("All values")}>
      <Select.Option value={true} key="yesOption">
        {t("Yes")}
      </Select.Option>
      <Select.Option value={false} key="noOption">
        {t("No")}
      </Select.Option>
    </Select>
  );
};

export default YesNoSelect;
