import React, {useEffect, useState} from 'react';
import {Button, message, Modal, Skeleton, Statistic, Table, Tag, Tooltip} from "antd";
import Operations from "../Financial/Operations";
import DefaultPage from "../Shared/DefaultPage";
import Status from "../Shared/Status";
import {formatDateUtc, formatMoney, getColor} from "../../Lib/Helpers";
import {useTranslation} from "react-i18next";
import View from "../Bpm/View";
import {exe, safeGetRaw} from "../../Lib/Dal";

const SsAccounts = (props) => {
    const [loadingOperations, setLoadingOperations] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedPremiums, setSelectedPremiums] = useState([]);
    const [payModalVisible, setPayModalVisible] = useState(false);
    const [paymentViewCode, setPaymentViewCode] = useState();
    const [t] = useTranslation();
    
useEffect(()=>init(),[props.configProfile])
    
    
    const init = () => {
        //loading custom view defined in config profile
        if(props.configProfile&&props.configProfile.paymentViewId){
            setLoading(true);
            exe("RepoLiveView",{operation:"GET",filter:`id=${props.configProfile.paymentViewId}`}).then(r=>{
                setLoading(false);
                if(r.ok){
                    const record=r.outData[0];
                    setPaymentViewCode(record.code);
                }else message.error(r.msg);
            })
        }
    }
    
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedPremiums(selectedRows);
        },
        selectedRowKeys: selectedPremiums.map(p=>p.id),
    }
    const isDisabled=name=>{
        //checking if the button is disabled by configuration
        if(props.configProfile && props.configProfile.disabledActions){
            return props.configProfile.disabledActions.includes(name);
        }
        return false;
    }

    const pastDate = (date) => new Date(date) < new Date();
    return (
        <div>
            <DefaultPage
                title={t("My Premiums")}
                subTitle={<span>{t("Policy Premiums")}</span>}
                iconStyle={{ backgroundColor: safeGetRaw(["Style","primaryColor"],props.configProfile,"rgb(69,156,84)") }}
                routes={{
                    routes: [
                        {breadcrumbName: t("Home"), path: "/SelfService/home"},
                        {breadcrumbName: t("Premiums"), path: "premiums"},
                    ],
                }}
                icon="global"
                loading={loading}
                extra={<div style={{display:"flex",alignItems:"center",height:69}}>
                    {selectedPremiums.length>0&&selectedPremiums.every(p=>p.currency===selectedPremiums[0].currency)&&<Statistic title={t("Selected Premiums")} value={formatMoney(selectedPremiums.reduce((p,c)=>p+c.amount,0),selectedPremiums[0].currency)} />}

                    <Button type={"primary"} style={{marginLeft:20}} disabled={!selectedPremiums.length||isDisabled("payPremium")} onClick={()=>setPayModalVisible(true)}>{t("Pay")}</Button>
            </div>}>

                <Table dataSource={props.premiums} rowKey={"id"} rowSelection={rowSelection}>
                    <Table.Column title={t("Ref")} dataIndex="id"/>
                    <Table.Column title={t("Policy")} dataIndex="policyCode"/>
                    <Table.Column title={t("Concept")} dataIndex="concept" render={(v) => <span style={{whiteSpace: "nowrap"}}>{v}</span>}/>
                    <Table.Column title={t("Amount Due")} dataIndex="amount" render={(v, r) => <span style={{whiteSpace: "nowrap"}}>{formatMoney(v, r.currency)}</span>}/>
                    <Table.Column title={t("Paid")} dataIndex="amountPaid" render={(v, r) => formatMoney(v, r.currency)}/>
                    <Table.Column title={t("Paid Date")} dataIndex="payDate" render={(v) => v && formatDateUtc(v)}/>
                    <Table.Column
                        title={t("Due Date")}
                        dataIndex="dueDate"
                        render={(v, r) => (!r.payed && pastDate(v) ?
                            <Tag color="red">{formatDateUtc(v)}</Tag> : formatDateUtc(v))}
                    />
                    <Table.Column title={t("# in Year")} dataIndex="numberInYear"/>
                    <Table.Column title={t("Contract Year")} dataIndex="contractYear"/>
                </Table>
                <Modal title={t("Premium Payment")} visible={payModalVisible} onCancel={()=>setPayModalVisible(false)} okButtonProps={{style:{display:"none"}}} >
                    <View code={paymentViewCode} context={{premiums:selectedPremiums,close:()=>setPayModalVisible(false)}} />
                </Modal>
            </DefaultPage>
        </div>
    );
};

export default SsAccounts;