import React, { useState, useEffect } from "react";
import { Table, Tag, Tooltip } from "antd";
import { exe } from "../../Lib/Dal";
import { formatDate } from "../../Lib/Helpers";
import Text from "antd/lib/typography/Text";
import LogFilter from "./LogFilter";
import { useTranslation } from "react-i18next";

const Log = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 15, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });

  // useEffect(() => {
  //   getData();
  // }, [pagination.current, props.refresh]);

  // const getData = () => {
  //   setLoading(true);
  //   exe("GetLog", { size: pagination.pageSize, page: pagination.current - 1 }).then((r) => {
  //     setLoading(false);
  //     setData(r.outData);
  //     setPagination({ ...pagination, total: r.total });
  //   });
  // };
  const onPaginationChange = (currentPagination) => {
    setPagination(currentPagination);
  };
  const onFilteredResults = (filteredData, totalResults) => {
    setData(filteredData);
    setPagination({ ...pagination, total: totalResults });
  };
  const getTag = (level) => {
    switch (level) {
      case 0:
        return <Tag color="red">{t("Error")}</Tag>;
        break;
      case 1:
        return <Tag color="orange">{t("Warning")}</Tag>;
        break;
      case 2:
        return <Tag color="blue">{t("Info")}</Tag>;
        break;
      case 3:
        return <Tag color="green">{t("Trace")}</Tag>;
        break;
      default:
        return <Tag>{t("Other")}</Tag>;
        break;
    }
  };
  return (
    <div>
      <Table dataSource={data} loading={loading} pagination={pagination} onChange={(pag) => onPaginationChange(pag)}>
        <Table.Column title={t("Date")} dataIndex="date" width={270} render={(v) => formatDate(v)} />
        <Table.Column title={t("Level")} dataIndex="level" width={100} render={(v) => getTag(v)} />
        <Table.Column title={t("Command")} dataIndex="transactionCode" width={270} />
        <Table.Column
          title={t("Msg")}
          dataIndex="msg"
          render={(v) => (
            <Tooltip title={v}>
              <Text ellipsis style={{ width: 500 }}>
                {v}
              </Text>
            </Tooltip>
          )}
        />
        <Table.Column title={t("User")} dataIndex="user" />
      </Table>
      <LogFilter
        visible={props.filterVisible}
        onClose={() => props.onCloseFilter()}
        onResults={(data, total) => onFilteredResults(data, total)}
        pagination={pagination}
        refresh={props.refresh}
      />
    </div>
  );
};

export default Log;
