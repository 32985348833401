import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";

const SellerSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => load(), [props.comContractId]);

  const load = () => {
    if (!props.comContractId) return;
    setLoading(true);
    exe("GetContacts", { filter: "id in (SELECT contactId FROM ComParticipant where comContractId=" + props.comContractId + ")" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        disabled={!props.comContractId || props.disabled}
        allowClear
        showSearch
        optionFilterProp="children">
        {data.map((v) => (
          <Select.Option value={v.id} key={v.id}>
            {v.FullName}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default SellerSelect;
