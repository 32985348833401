import React, { Component } from "react";
import { CodeOutlined, EnterOutlined } from '@ant-design/icons';
import { Drawer, Input, Table, message, Modal, Dropdown, Menu, Button } from "antd";
import { exe } from "Lib/Dal";
import Inspector from "react-inspector";
import { withTranslation } from "react-i18next";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

class Sisos extends Component {
  state = {
    state: [],
    cmdHistory: [],
    searchValue: "",
    loading: false,
  };

  componentDidMount() {
    if (localStorage.cmdHistory) {
      this.setState({ cmdHistory: JSON.parse(localStorage.cmdHistory) });
    }
  }

  sendCommand = (value) => {
    if (!this.state.cmdHistory.includes(value)) this.setState({ cmdHistory: [...this.state.cmdHistory, value] });
    if(value&&value.startsWith("{")){
      //direct command
      const parsedCmd=JSON.parse(value);
      this.setState({ loading: true });
      exe(parsedCmd.cmd, parsedCmd.data).then((r) => {
        this.setState({ loading: false });
        Modal[r.ok ? "success" : "error"]({
          title: parsedCmd.cmd + " " + this.props.t("Output"),
          maskClosable: true,
          width: "60%",
          zIndex: 2000,
          content: (
              <div>
                <Inspector data={r} expandLevel={1} />
                <div style={{ marginTop: 10 }}>
                  <Inspector table data={r.outData} />
                </div>
              </div>
          ),
          onOk() {},
        });
      });
      return;
}else if (value.slice(0, 1) == ">") {
      //direct command
      try {
        const fullCmd = value.substring(1);
        const splited = fullCmd.split(" ");
        const cmd = splited[0];
        let data, json;
        if (splited.length == 1) {
          //nodata
          data = null;
        } else {
          data = fullCmd.replace(cmd + " ", "");
          //no braces option
          if (data.substring(0, 1) !== "{") data = "{" + data + "}";
          let a;
          json = JSON.stringify(eval("a=" + data));
        }
        //const data = ssv[1] ? ssv[1] : "{}";
        //const opt = ssv.length > 2 ? ssv[2] : "{}";
        this.setState({ loading: true });
        exe(cmd, data ? JSON.parse(json) : {}).then((r) => {
          this.setState({ loading: false });
          console.log(r);

          Modal[r.ok ? "success" : "error"]({
            title: cmd + " " + this.props.t("Output"),
            maskClosable: true,
            width: "60%",
            zIndex: 2000,
            content: (
              <div>
                <Inspector data={r} expandLevel={1} />
                <div style={{ marginTop: 10 }}>
                  <Inspector table data={r.outData} />
                </div>
              </div>
            ),
            onOk() {},
          });
        });
      } catch (err) {
        console.log(err);
        Modal.error({ title: this.props.t("Error parsing command"), zIndex: 2000, maskClosable: true, content: <Inspector data={err.toString()} /> });
      }

      return;
    }
    this.setState({ loading: true });
    exe("Sisos", { cmdText: value }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setState({ data: r.outData });
      } else {
        message.error(r.msg);
      }
    });
  };
  historyClick(value) {
    this.setState({ searchValue: value });
    this.searchRef.input.focus();
  }
  saveHistory() {
    let his = this.state.cmdHistory;
    if (this.state.cmdHistory.length > 20) {
      his = his.slice(0, his.length - 20);
    }
    localStorage.cmdHistory = JSON.stringify(his);
  }
  componentDidUpdate(previousProps) {
    if (previousProps.command != this.props.command) {
      //this.searchRef.input.setValue(this.props.command);
      //this.searchRef.input.focus();
      this.sendCommand(this.props.command.trim());
    }
  }

  render() {
    const { t, i18n } = this.props;
    const searchMenu = (
      <Menu>
        {this.state.cmdHistory.map((p, i) => (
          <Menu.Item key={i} onClick={() => this.historyClick(p)}>
            <CodeOutlined />
            {p}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Drawer
        title={t("Command Launcher")}
        placement="right"
        width={612}
        onClose={() => {
          this.saveHistory();
          this.props.onClose();
        }}
        visible={this.props.visible}>
        <Dropdown overlay={searchMenu} trigger={["click"]}>
          <Search
            placeholder={t("Input search text or command")}
            size="large"
            ref={(ref) => (this.searchRef = ref)}
            value={this.state.searchValue}
            onChange={(e) => this.setState({ searchValue: e.target.value })}
            onSearch={(value) => this.sendCommand(value)}
            enterButton={<Button type="primary" icon={<EnterOutlined />} loading={this.state.loading} />}
          />
        </Dropdown>
        <Table dataSource={this.state.data} size="small" style={{ marginTop: 5 }} rowKey="linkId">
          <Column title={t("Item")} dataIndex="item" />
          <Column title={t("Type")} dataIndex="type" />
          <Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <a href={"#/contact/" + record.linkId}>View</a>
              </span>
            )}
          />
        </Table>
      </Drawer>
    );
  }
}
export default withTranslation()(Sisos);
