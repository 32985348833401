import React, { useState,useContext } from "react";
import { EnterOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Input, AutoComplete, Button, Tooltip,ConfigProvider } from "antd";
import { exe } from "../Lib/Dal";
import Sisos from "./Sisos";
import { useTranslation } from "react-i18next";

const Search = (props) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [command, setCommand] = useState(null);
  const [searchGroups, setSearchGroups] = useState([]);
  const configContext = useContext(ConfigProvider.ConfigContext);
  const direction = configContext.direction;

  const onSearch = (v) => {
    if (v == "") {
      setSearchGroups([]);
      return;
    }
    if (v.substring(0, 1) == ">") return;
    setSearchGroups(groups);

    exe("DoSearch", { searchString: v }).then((r) => {
      setData(r.outData);
    });
  };
  const onSelect = (v) => {
    window.location = v;
  };
  const onEnterBak = (v) => {
    if (v == "") return;
    if (v.substring(0, 1) == ">") {
      //command
      if (v == command) v = v + " "; //repeating command doesnt launch effect
      setCommand(v);
      setSearchGroups([]);
    }
  };
  const onEnter = (v) => {
    if (v == "") return;
    const firstChar = v.substring(0, 1);

    if (firstChar == ">") {
      onEnterBak(v);
      return;
    } else if (firstChar == "/") {
      const args = v.split(" ");
      const cmdName = args[0].replace("/", ">");
      const jArgs = {};
      args.slice(1).forEach((element, i) => {
        if (element.substring(0, 1) == "-") {
          jArgs[element.substring(1)] = args[i + 2];
        }
      });
      let cmd = cmdName + " " + JSON.stringify(jArgs);
      if (cmd == command) cmd = cmd + " "; //repeating command
      setCommand(cmd);
      console.log("sending command", cmd, args);
    }
  };

  const groups = [
    { name: t("Policies"), type: "Policy", moreLink: "#/policies" },
    { name: t("Claims"), type: "Claim", moreLink: "#/claimList" },
    { name: t("Contacts"), type: "Contact", moreLink: "#/contactList" },
  ];
  return (
    <div style={{ display: "flex" }}>
      <AutoComplete
        size="large"
        style={{ width: "100%" }}
        onSearch={onSearch}
        onSelect={onSelect}
        options={searchGroups.map((group) => ({
          label: <a href={group.moreLink}>{group.name}</a>,
          options: data
            .filter((p) => p.type === group.type)
            .map((p) => ({
              value: p.link,
              label: p.result,
            })),
        }))}>
        <Input.Search
          style={{ marginBottom: 10 }}
          placeholder={t("Insert command or search text")}
          prefix={
            <Tooltip title={t("Type search text or issue a command starting your text with > character")}>
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
          size="large"
          onSearch={onEnter}
          enterButton={<Button type="primary" icon={<EnterOutlined style={direction=="rtl"?{transform:"scaleX(-1)"}:{}}/>} />}
        />
      </AutoComplete>
      <Sisos command={command} />
    </div>
  );
};

export default Search;
