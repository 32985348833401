import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, InputNumber, Popconfirm, Row, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import Money from "../Shared/Money";
import { SaveOutlined, LeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import Currency from "../Shared/Currency";
import DatePickerW from "../Shared/DatePickerW";
import InvestmentPlanSelect from "../InvestmentPlans/InvestmentPlanSelect";

const FundPlanLineForm = (props) => {
    const [loading,setLoading]=useState(false);
    const [t] = useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        if(props.value){
            if(props.value.id==0){
                form.resetFields();
                form.setFieldValue("fundPlanId",props.fundPlanId);
            }else form.setFieldsValue(props.value);
        }
    }
    ,[props.value])

    const save=()=>{
        form.validateFields().then(values=>{
            console.log(values);
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoFundPlanLine",{operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onSave(values);
                    props.onCancel();

                }else{
                    message.error(r.msg);
                }
            })

        })
    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoFundPlanLine",{operation:"DELETE",entity:props.value}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                props.onSave(props.value);
                props.onCancel();
            }else message.error(r.msg);
        })
    }

    return <div>
        <Button type="link" icon={<LeftOutlined />} onClick={props.onCancel}>{t("Back")}</Button>
        <Button type="link" icon={<SaveOutlined />} onClick={save} loading={loading}>{t("Save")}</Button>
        <Popconfirm  title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={!props.value||!props.value.id}><Button type="link" icon={<DeleteOutlined />}  disabled={!props.value||!props.value.id} >{t("Delete")}</Button></Popconfirm> 
        <Form form={form} layout="vertical">
            <Form.Item label={t("id")} name={"id"} hidden><Input disabled/></Form.Item>
            <Form.Item label={t("fundPlanId")} name={"fundPlanId"} hidden ><Input disabled/></Form.Item>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label={t("Investment Product")} name={"investmentPlanCode"} rules={[{required:true}]}><InvestmentPlanSelect/></Form.Item>
                    <Form.Item label={t("Percentage")} name={"percentage"} rules={[{required:true}]}><InputNumber min={0} max={100}/></Form.Item>
                </Col>
                <Col span={12}>
                </Col>
            </Row>

        </Form>
    </div>
}
export default FundPlanLineForm;