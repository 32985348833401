import React from "react";
import { Badge } from "antd";

const TabBadge = props => {
  return (
    <Badge {...props} offset={[4, 0]} style={{ backgroundColor: "#fff", color: "#999", boxShadow: "0 0 0 1px #d9d9d9 inset" }}>
      {props.children}
    </Badge>
  );
};

export default TabBadge;
