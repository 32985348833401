import { Badge, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const TransactionStatusSelect = (props) => {
  const [t] = useTranslation();
  //warning, states also set in Transaction.js
  return (
    <Select style={{ ...props.style, width: "100%" }} allowClear value={props.value} onChange={props.onChange} disabled={props.disabled}>
      <Select.Option value={0}>
        <Badge status="warning" />
        {t("Draft")}
      </Select.Option>
      <Select.Option value={1}>
        <Badge status="success" />
        {t("Posted")}
      </Select.Option>  
        <Select.Option value={-1}>
        <Badge status="error" />
        {t("Rejected")}
      </Select.Option>
    </Select>
  );
};

export default TransactionStatusSelect;


