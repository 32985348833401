import { DoubleLeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Table } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Text from "antd/lib/typography/Text";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { formatMoney } from "../../Lib/Helpers";
import CoverageClaimList from "../Life/CoverageClaimList";

const AffectedCoverages = (props) => {
  const [t, i18n] = useTranslation();
  const [selectedCoverage, setSelectedCoverage] = useState(null);
  if (!props.policy) return null;
  const config = props.config;
  const checkIfInternalIsHidden=(covergeCode)=>{
    if(config&&config.Coverages){
        const coverageConfig = config.Coverages.find(p=>p.code==covergeCode);
        if(coverageConfig&&coverageConfig.hidden){
            return true;
        }
    }
    return false;
}
  const coverages = (props.policy.Coverages || []).filter((p) => !(p.isInternal && checkIfInternalIsHidden(p.code)));
  const currency = props.policy.currency;
  const claim = props.claim;


  const onCoverageClick = (cov) => {
    setSelectedCoverage(cov);
  };
  return (
    <div>
      {!selectedCoverage && (
        <div>
          <Table dataSource={coverages}>
            <Table.Column title={t("Code")} dataIndex="code" width={120} render={(v) => <Text code>{v}</Text>} />
            <Table.Column
              title={t("Name")}
              dataIndex="name"
              width={250}
              render={(t, r) => (
                <Paragraph strong ellipsis={{ rows: 2 }}>
                  {t}
                </Paragraph>
              )}
            />
            <Table.Column title={t("Sum Assured")} dataIndex="limit" render={(v, r) => formatMoney(v, currency)} />
            <Table.Column title={t("Claims")} width={100} render={(v, r) => <Text code>{r.Claims.length}</Text>} />
            <Table.Column
              title={t("Actions")}
              render={(v, r) => (
                <div>
                  <Button onClick={() => onCoverageClick(r)}>
                    {t("Indemnities")} <RightOutlined />
                  </Button>
                </div>
              )}
            />
          </Table>
        </div>
      )}
      {selectedCoverage && (
        <div>
          <Button type="link" icon={<DoubleLeftOutlined />} onClick={() => setSelectedCoverage(null)} style={{ float: "left" }}>
            {t("Coverages")}
          </Button>
          <CoverageClaimList
            coverage={selectedCoverage}
            claimId={claim.id}
            elegibleCoverages={claim.elegibleCoverages}
            config={props.config}
            claimType={
              config.Coverages.find((q) => q.code == selectedCoverage.code) ? config.Coverages.find((q) => q.code == selectedCoverage.code).claimType : null
            }
            currency={currency}
          />
        </div>
      )}
    </div>
  );
};

export default AffectedCoverages;
