import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";

const OccupationSectorSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => load(), []);

  const load = () => {
    getCache("RepoOccupationSector").then((r) => {
      setData(r.outData);
    });
  };
  const onChange = (v) => {
    props.onChange(v);
  };
  const value = props.value;
  return (
    <div>
      <Select
        style={{ width: "100%" }}
        placeholder={t("Please select occupation sector")}
        onChange={onChange}
        value={value}
        allowClear
        optionFilterProp="children"
        showSearch
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default OccupationSectorSelect;
