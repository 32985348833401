import { InputNumber, Select } from "antd";
import React from "react";

const MonthSelect = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <Select value={props.value} onChange={props.onChange} style={props.style} disabled={props.disabled}>
        <Select.Option key="0m" value={0}>
          0
        </Select.Option>
        <Select.Option key="1m" value={1}>
          1
        </Select.Option>
        <Select.Option key="2m" value={2}>
          2
        </Select.Option>
        <Select.Option key="3m" value={3}>
          3
        </Select.Option>
        <Select.Option key="4m" value={4}>
          4
        </Select.Option>
        <Select.Option key="5m" value={5}>
          5
        </Select.Option>
        <Select.Option key="6m" value={6}>
          6
        </Select.Option>
        <Select.Option key="7m" value={7}>
          7
        </Select.Option>
        <Select.Option key="8m" value={8}>
          8
        </Select.Option>
        <Select.Option key="9m" value={9}>
          9
        </Select.Option>
        <Select.Option key="10m" value={10}>
          10
        </Select.Option>
        <Select.Option key="11m" value={11}>
          11
        </Select.Option>
        <Select.Option key="12m" value={12}>
          12
        </Select.Option>
      </Select>
    </div>
  );
};

export default MonthSelect;
