import React, { Component } from "react";
import { MedicineBoxOutlined, PlusOutlined } from '@ant-design/icons';
import { Select, Tooltip, Divider } from "antd";
import { exe } from "Lib/Dal";
import { withTranslation } from "react-i18next";

//used inside forms = props = fn onChange, string value
class ProviderSelect extends Component {
  state = {
    data: [],
    selected: null,
  };
  componentDidMount() {
    exe("RepoProvider", { operation: "GET", filter: "active=1" }).then((r) => {
      this.setState({ data: r.outData, selected: this.props.value });
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.value != prevProps.value) this.setState({ selected: this.props.value });
  }
  handleChange = (v) => {
    this.setState({ selected: v });
    this.props.onChange(v);
  };
  render() {
    const { t, i18n } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <Select
          style={{ width: "100%" }}
          onChange={this.handleChange}
          value={this.state.selected}
          showSearch
          allowClear
          showArrow={true}
          onSelect={(v, o) => this.props.onSelect && this.props.onSelect(v, o)}
          placeholder={t("Type to search provider...")}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <div style={{ padding: "8px", cursor: "pointer", color: "blue" }} onMouseDown={() => (window.location.hash = "#/provider/0")}>
                <PlusOutlined /> {t("Add new provider")}
              </div>
            </div>
          )}>
          {this.state.data.map((r) => (
            <Select.Option value={r.code} key={r.code} record={r}>
              {r.name}
            </Select.Option>
          ))}
        </Select>
        <Tooltip title="View provider">
          <MedicineBoxOutlined
            style={{
              position: "absolute",
              right: "30px",
              top: "9px",
              cursor: "pointer",
              color: "rgba(0, 0, 0, 0.25)",
              fontSize: 12,
            }}
            onClick={() => (window.location.hash = "#/provider/" + this.state.selected)} />
        </Tooltip>
      </div>
    );
  }
}

export default withTranslation()(ProviderSelect);
