import React from "react";
import { Table, Empty, Typography } from "antd";
import Text from "antd/lib/typography/Text";
import { useTranslation } from "react-i18next";
//Used in add/remove cooverage change.
//Differs from CoverageSelect in that the component accepts, respects and returns the full object, not only the coverage code.
const CoverageSelector = (props) => {
  const [t] = useTranslation();
  if (!props.config || !props.config.Coverages) return <Empty />;
  const excluded = props.excluded || [];
  const selectable = props.config.Coverages.filter((p) => !excluded.includes(p.code)); //used in add coverage change. Excluded covs already present in policy
  const data = props.config.Coverages;

  const onSelectChange = (selectedRowKeys) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    //checking linked covs
    selectedRowKeys.forEach((selectedCode) => {
      const conf = props.config.Coverages.find((q) => q.code == selectedCode);
      if (conf.linked) {
        //has linked covs
        conf.linked.forEach((l) => {
          const linkedCov = props.config.Coverages.find((r) => r.code == l);
          selectedRowKeys.push(linkedCov.code);
        });
      }
    });
    props.onChange(data.filter((p) => selectedRowKeys.includes(p.code)));
  };

  //   const clickOnRow = (record) => {
  //     const selectedRowKeys = [...props.value].map((p) => p.code);
  //     if (selectedRowKeys.indexOf(record.code) >= 0) {
  //       selectedRowKeys.splice(selectedRowKeys.indexOf(record.code), 1);
  //     } else {
  //       selectedRowKeys.push(record.code);
  //     }
  //     onSelectChange(selectedRowKeys);
  //   };
  const rowSelection = {
    selectedRowKeys: props.value.map((p) => p.code),
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: !selectable.map((p) => p.code).includes(record.code),
      name: record.code,
    }),
  };
  const strikeThrough = (v) => (props.strikeRemoved ? (props.value && props.value.map((r) => r.code).includes(v) ? false : true) : false);
  return (
    <div>
      <Table
        pagination={false}
        dataSource={data}
        rowSelection={rowSelection}
        size="small"
        // onRow={(record) => ({
        //   onClick: () => {
        //     clickOnRow(record);
        //   },
        // })}
        rowKey="code">
        <Table.Column
          title={t("Code")}
          dataIndex="code"
          render={(v, r) => (
            <Text code delete={strikeThrough(r.code)}>
              {v}
            </Text>
          )}
        />
        <Table.Column title={t("Name")} dataIndex="name" render={(v, r) => <Text delete={strikeThrough(r.code)}>{v}</Text>} />
        <Table.Column
          title={t("Description")}
          dataIndex="description"
          render={(t, r) => (
            <Typography.Paragraph ellipsis={{ rows: 3, expandable: true }} delete={strikeThrough(r.code)}>
              {t}
            </Typography.Paragraph>
          )}
        />
      </Table>
    </div>
  );
};

export default CoverageSelector;
