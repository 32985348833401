import React, {useEffect, useState} from 'react';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Breadcrumb, Button, Divider, Layout, Menu, Result, message, Tooltip , Skeleton} from "antd";
import {useTranslation} from "react-i18next";
import {Route} from "react-router-dom";
import SsHome from "./SsHome";
import Portal from "../SelfServiceBuy";
import SsPolicies from "./SsPolicies";
import SsClaims from "./SsClaims";
import SsAccounts from "./SsAccounts";
import {exe, safeGetRaw} from "../../Lib/Dal";
import User from "../User";
import SsUserProfile from "./SsUserProfile";
import SelfServiceClaim from "../SelfServiceClaim";
import SsCreateAccount from "./SsCreateAccount";
import SsPremiums from "./SsPremiums";
import SsFiles from "./SsFiles";
import SsCalculators from "./SsCalculators";
import SsCredits from "./SsCredits";

const SelfServiceApp = () => {
    const [t]=useTranslation();
    const [unauthorized,setUnauthorized]=useState(false);
    const [data,setData]=useState({});
    const [name,setName]=useState();
    const [policies,setPolicies]=useState([]);
    const [claims,setClaims]=useState([]);
    const [accounts,setAccounts]=useState([]);
    const [premiums,setPremiums]=useState([]);
    const [credits,setCredits]=useState([]);
    const [configProfile,setConfigProfile]=useState({});
    const [sections,setSections]=useState([]);
    
    useEffect(()=>{
        if(!publicMode) login();
    },[])
    
    //const menuColor="rgb(69,156,84)";
    const menuColor="transparent";
    const company="Axxis Systems Corp";
    
    const login=()=>{
        console.log("login");
        const contactId=window.sessionStorage.contactId?window.sessionStorage.contactId:null;//login as user if present
        exe("GetContactData",{contactId:contactId!=="null"?contactId:null}).then(r=>{
            if(r.ok){
                setData(r.outData);
                setName(r.outData.Contact.FullName);
                setPolicies(r.outData.PoliciesAsHolder);
                setClaims(r.outData.Claims);
                setAccounts(r.outData.Accounts);
                setPremiums(r.outData.Premiums);
                setCredits(r.outData.CreditInstallments);
                setConfigProfile(r.outData.configProfile);
                window.sessionStorage.userContactId=r.outData.Contact.id;
                //applying custom styles
                applyStyles(safeGetRaw(["outData","configProfile","Style"],r,null));
                //setting custom sections
                setSections(safeGetRaw(["outData","configProfile","Sections"],r,[]));
            }else {
                message.error(r.msg);
                setUnauthorized(true);
            }
        })
    }
    
    const applyStyles=style=>{
        if(!style) return;

        document.head.insertAdjacentHTML("beforeend", `<style>
.ant-btn-primary{
    background-color:${style.primaryColor};
    border-color:${style.primaryColor};
}
.ant-btn-primary:hover{
    background-color:${style.primaryColorHover};
    border-color:${style.primaryColorHover};
}
.ssHeader{
    background-color:${style.headerBackground} !important ;
    color:${style.headerColor} !important ;
}
.ssHeader > .ssMenu > .ant-menu-item{
    background-color:${style.headerBackground} ;
    color:${style.headerColor} ;
    text-transform: ${style.headerTextTransform};
    font-family: ${style.headerFontFamily};
    font-size: ${style.headerFontSize} !important;
}
.ssHeader> * .anticon{
 color:${style.headerColor} !important ;
}
.ssMenu > .ant-menu-item-selected{
    background-color:${style.headerBackground} !important ; 
    color:${style.headerColor} !important;
    border-radius: 0px;
    font-weight: 700;
} 
.ssMenu > .ant-menu-item:hover
{
    color:${style.primaryColor} !important ;
}
.ssMenu > .ant-menu-item:hover:after
{
    border-bottom-color:${style.underlineColor} !important ;
}
.ssMenu > .ant-menu-item-selected::after
{
    border-bottom-color:${style.underlineColor} !important ;
}
.ant-menu-dark .ant-menu-item-selected .anticon{
    color:${style.headerColor};
}
.logoImage > img{
    height: 50px;
}
.ant-steps-navigation .ant-steps-item::before{
    background-color:${style.primaryColor};
}
.ant-steps-icon{
    color:${style.primaryColor} !important;
}
.ant-tabs-nav .ant-tabs-tab-active{
    color:${style.primaryColor} !important;
}
.ant-tabs-ink-bar{
    background-color:${style.primaryColor} !important;
}

</style>`)
    }
    
    const onExit=()=>{
        window.sessionStorage.removeItem("contactId");
        //window.location.hash="/home";
        window.location=window.location.origin+"/login.html";
        //window.location.reload();

    }
    if(unauthorized) return <Result
        status="403"
        title="403"
        subTitle={t("Sorry, you are not authorized to access this page.")}
        extra={
            <Button type="primary" onClick={() => onExit()}>
                {t("Back Home")}
            </Button>
        }
    />
    
    
    const onMenuItemClick=(e)=>{
        switch (e.key){
            case "home":
                window.location.hash="/SelfService/home";
                break
            case "buy":
                window.location.hash="/SelfService/buy";
                break;
                default:
                    window.location.hash="/SelfService/"+e.key;
                    
        }
    }
    const isHiddenSection=section=>{
        //checking if there is a section with this name
        const sectionFound=sections.find(s=>s.name===section);
        if(!sectionFound) return false;
        return sectionFound.hidden;
    }
    
    const publicMode=window.location.hash.includes("/pub/");
    
    if(!configProfile||Object.keys(configProfile).length === 0&&!publicMode) return <div style={{padding:20}}>
        <Skeleton active avatar paragraph={{ rows: 4 }} />
    </div>//loading
    return (
        <div>
            <Layout >
                {!publicMode&&<Layout.Header className={"ssHeader"} style={{ display:'flex',position: 'fixed', zIndex: 1, width: '100%',background:menuColor }}>
                   <div style={{display:safeGetRaw(["Style","logoUrl"],configProfile,false)?"none":"flex"}}>
                    <CheckCircleOutlined style={{alignSelf:"center",fontSize:25,marginRight:8}} />
                    <span style={{fontWeight:500,marginRight:5}}>SelfService</span>
                   </div>
                    <span className={"logoImage"} style={{display:safeGetRaw(["Style","logoUrl"],configProfile,false)?"block":"none"}}>
                        <img src={safeGetRaw(["Style","logoUrl"],configProfile,null)} />
                    </span>
                    <Divider type={"vertical"} style={{alignSelf:"center",height:14}} />
                    <Menu
                        className="ssMenu"
                        theme="light"
                        mode="horizontal"
                        defaultSelectedKeys={['home']}
                        style={{ lineHeight: '64px',background:menuColor,flexGrow:1 }}
        
                        onClick={onMenuItemClick}
                    >
                        <Menu.Item key="home"><div style={{display:"flex"}}><LegacyIcon type={"home"} style={{fontSize:25,alignSelf:"center"}}/>{t("Home")}</div></Menu.Item>
                        <Menu.Item key="policies" hidden={isHiddenSection("policies")}><div style={{display:"flex"}}><LegacyIcon type={"heart"} style={{fontSize:25,alignSelf:"center"}}/>{t("Policies")}</div> </Menu.Item>
                        <Menu.Item key="claims" hidden={isHiddenSection("claims")}><div style={{display:"flex"}}><LegacyIcon type={"medicine-box"} style={{fontSize:25,alignSelf:"center"}}/>{t("Claims")}</div> </Menu.Item>
                        <Menu.Item key="accounts" hidden={isHiddenSection("accounts")}><div style={{display:"flex"}}><LegacyIcon type={"global"} style={{fontSize:25,alignSelf:"center"}}/>{t("Accounts")}</div> </Menu.Item>
                        <Menu.Item key="premiums" hidden={isHiddenSection("premiums")}><div style={{display:"flex"}}><LegacyIcon type={"dollar"} style={{fontSize:25,alignSelf:"center"}}/>{t("Premiums")}</div> </Menu.Item>
                        <Menu.Item key="credits" hidden={isHiddenSection("credits")}><div style={{display:"flex"}}><LegacyIcon type={"credit-card"} style={{fontSize:25,alignSelf:"center"}}/>{t("Credits")}</div> </Menu.Item>
                        <Menu.Item key="buy" hidden={isHiddenSection("buy")}><div style={{display:"flex"}}><LegacyIcon type={"select"} style={{fontSize:25,alignSelf:"center"}}/>{t("Buy")}</div> </Menu.Item>
                        <Menu.Item key="newClaim" hidden={isHiddenSection("newClaim")}><div style={{display:"flex"}}><LegacyIcon type={"warning"} style={{fontSize:25,alignSelf:"center"}}/>{t("New Claim")}</div> </Menu.Item>
                        <Menu.Item key="files" hidden={isHiddenSection("files")}><div style={{display:"flex"}}><LegacyIcon type={"folder-open"} style={{fontSize:25,alignSelf:"center"}}/>{t("Files")}</div> </Menu.Item>

                    </Menu>
                    <div style={{display:"flex",marginLeft:"auto",
                    color:safeGetRaw(["Style","headerColor"],configProfile,""),
                    fontFamily:safeGetRaw(["Style","headerFontFamily"],configProfile,"unset"),
                    fontSize:safeGetRaw(["Style","headerFontSize"],configProfile,"unset"),
                    }}>
                        <Tooltip title={t("User Preferences")}>
                            <LegacyIcon type={"user"} style={{alignSelf:"center",fontSize:25}} onClick={()=>window.location.hash="/SelfService/user"} />
                        </Tooltip>
                        <div style={{display:"flex",flexDirection:"column",alignSelf:"center",lineHeight:"normal",marginLeft:10,marginRight:10}}>
                            <span style={{fontWeight:600}}>{name}</span>
                            <span>{company}</span>
                        </div>
                        <Button onClick={onExit}  type={"link"} style={{alignSelf:"center",padding:0}} >  <LegacyIcon type={"poweroff"} style={{alignSelf:"center",fontSize:25,color:"rgb(0,0,0,0.65"}} /></Button>
                    </div>
                </Layout.Header>}
                <Layout.Content style={{  marginTop: publicMode?0:64}}>
                  
                    <div style={{ background: '#fff', padding: 24, minHeight: 380 }}>
                        <Route exact path="/SelfService/home" render={v=><SsHome name={name} configProfile={configProfile}/>} />
                        <Route exact path="/SelfService/policies" render={v=><SsPolicies policies={policies} configProfile={configProfile} />} />
                        <Route exact path="/SelfService/claims" render={v=><SsClaims claims={claims} policies={policies} configProfile={configProfile}/>} />
                        <Route exact path="/SelfService/accounts" render={v=><SsAccounts accounts={accounts} configProfile={configProfile} />} />
                        <Route exact path="/SelfService/premiums" render={v=><SsPremiums premiums={premiums} configProfile={configProfile} />} />
                        <Route exact path="/SelfService/credits" render={v=><SsCredits credits={credits} configProfile={configProfile} />} />
                        <Route exact path="/SelfService/buy" render={v=><Portal contact={data.Contact} reload={()=>login()} configProfile={configProfile} />} />
                        <Route exact path="/SelfService/user" render={v=><SsUserProfile contact={data.Contact} configProfile={configProfile} />} />
                        <Route exact path="/SelfService/files" render={v=><SsFiles contact={data.Contact} configProfile={configProfile} />} />
                        <Route exact path="/SelfService/newClaim" render={v=><SelfServiceClaim contact={data.Contact} reload={()=>login()} configProfile={configProfile} />} />
                        
                        <Route exact path="/SelfService/pub/createAccount" render={v=><SsCreateAccount />} />
                        <Route exact path="/SelfService/pub/calculators" render={v=><SsCalculators  /> } />
                        
                    </div>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center',background:"white" }}>© {new Date().getFullYear()} Axxis Systems</Layout.Footer>
            </Layout>,
        </div>
    );
};

export default SelfServiceApp;