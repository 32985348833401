import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import {message, Select} from "antd";

const ContactBranchSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    
    useEffect(()=>{
        if(props.contactId&&props.isCertificate) load(props.contactId);
    },[props.contactId])
    
    const load=contactId=>{
        setLoading(true);
        exe("RepoContactBranch",{operation:"GET",filter:`contactId=${contactId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData)
            }else message.error(r.msg)
        })
    }
    return (
        <div>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled} loading={loading}>
              {data.map(p=><Select.Option value={p.id} key={p.id}>{p.name}</Select.Option> )}
          </Select>  
        </div>
    );
};

export default ContactBranchSelect;