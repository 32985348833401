import React, { Component } from "react";
import { FileOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Row,
  Col,
  Select,
  Button,
  Input,
  message,
  notification,
  Typography,
  Popover,
  Switch,
} from "antd";
import yaml from "js-yaml";
import DefaultPage from "../Shared/DefaultPage";
import { exe } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";
import { withTranslation } from "react-i18next";
import CodeEditorW from "../Shared/CodeEditorW";
const { Paragraph, Title } = Typography;
const { Option } = Select;
const ButtonGroup = Button.Group;
class ConfigProfile extends Component {
  state = {
    loading: false,
    data: [],
    selected: undefined,
  };
  componentDidMount() {
    this.load();
  }
  load() {
    this.setState({ loading: true });
    exe("RepoConfigProfile", {
      operation: "GET",
    }).then((r) => {
      this.setState({ loading: false, data: r.outData, selected: r.total == 1 ? r.outData[0].code : undefined });
      if (r.total == 1) this.setValues(r.outData[0].code);
    });
  }
  onSelect(v) {
    this.setValues(v);
    this.setState({ selected: v });
  }
  setValues(code) {
    const profile = this.state.data.find((p) => p.code == code);
    window.global.myCodeMirror.setValue(profile.config);
    this.setState({ configActive: profile.active });
  }
  addNew() {
    if (!this.state.code || !this.state.name) {
      message.error(this.props.t("Please fill code and name"));
      return;
    }
    this.save("ADD");
  }
  save(operation = "UPDATE") {
    let values;
    if (operation == "ADD") {
      values = { code: this.state.code, name: this.state.name };
    } else {
      values = this.state.data.find((p) => p.code == this.state.selected);
    }
    //extra fields
    values.active = this.state.configActive;
    values.config = window.global.myCodeMirror.getValue();
    //yaml validation
    try {
      const result = yaml.safeLoad(values.config);
      values.configJson = JSON.stringify(result);
    } catch (error) {
      console.log(error, "TENGO ERROR");
      notification["error"]({
        message: error.reason,
        description: error.message,
      });
      return;
    }
    //validation OK
    this.setState({ loading: true });
    exe("RepoConfigProfile", { operation: operation, entity: values }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        if (operation == "ADD") this.load();
      } else {
        message.error(r.msg);
      }
    });
  }

  render() {
    const { t, i18n } = this.props;
    return (
      <div>
        <div style={{ display: "flex" }}>
          <Popover
            trigger="click"
            placement="right"
            title={t("New Profle")}
            content={
              <div style={{ width: 300 }}>
                {t("Code")}:
                <Input onChange={(e) => this.setState({ code: e.target.value })} value={this.state.code} />
                {t("Name")}:
                <Input onChange={(e) => this.setState({ name: e.target.value })} value={this.state.name} />
                <Button style={{ marginTop: 5 }} type="primary" icon={<SaveOutlined />} onClick={() => this.addNew()}>
                  {t("Save")}
                </Button>
              </div>
            }>
            <Button style={{ marginRight: 5 }} icon={<FileOutlined />} type="link">
              {t("New")}
            </Button>
          </Popover>
          <Button icon={<SaveOutlined />} type="link" onClick={() => this.save()}>
            {t("Save")}
          </Button>
        </div>
        <Select style={{ width: 250, marginRight: 5 }} value={this.state.selected} onSelect={(v) => this.onSelect(v)}>
          {this.state.data.map((p) => (
            <Select.Option value={p.code} key={p.code}>
              {p.name}
            </Select.Option>
          ))}
        </Select>
        <Switch checkedChildren="On" unCheckedChildren="Off" checked={this.state.configActive} onChange={(v) => this.setState({ configActive: v })} />
        <Title style={{ marginTop: 15 }} level={4}>
          {t("Behaviour")}
        </Title>
        <CodeEditorW saveHotKey={()=>this.save()}  />
      </div>
    );
  }
}

export default withTranslation()(ConfigProfile);
