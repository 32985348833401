import React, { Component } from 'react';
import { Select } from 'antd'
import { exe } from "Lib/Dal";
import {withTranslation} from "react-i18next";

//used inside forms = props = fn onChange, string value
class RequirementSelect extends Component {
    state={
        data:[],
        selected:null
    }
    componentDidMount() {
        exe('RepoReqCatalog',{operation:'GET'}).then(r=>{
            this.setState({data:r.outData,selected:this.props.value})
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if(this.props.value!=prevProps.value) this.setState({selected:this.props.value})
    }
    handleChange=(v)=>{
        this.setState({selected:v});
        this.props.onChange(v);
    }
    render() {
        const { t, i18n } = this.props;
        return (
            <Select style={{ width: "100%" }} onChange={this.handleChange} value={this.state.selected}>
                {this.state.data.map(r=><Select.Option value={r.code}>{r.code}-{t(r.document)}</Select.Option>)}
            </Select>
        );
    }
}

export default withTranslation()(RequirementSelect);