import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Checkbox, Input, InputNumber, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from '../../Lib/Dal';
import Currency from '../Shared/Currency';
import Money from '../Shared/Money';
import { round2 } from '../../Lib/Helpers';

const ExchangeOperationNew = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [props.reload]);

  const onOk = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      props.onOk(values);
      props.form.resetFields();
    });
  };
  const onChangeAmount=(v)=>{
    //checking if currency conversion is required
    if(values.targetCurrency){
      //dividing by exchange rate
      props.form.setFieldsValue({amountEx:round2(v/values.exchangeRate)});
    }
  }
  const getExchangeRate=(destinationCurrency)=>{
    //getting exchange rate
    setLoading(true);
    exe("GetExchangeRate",{currency:destinationCurrency,date:new Date().toISOString().slice(0,10)}).then(r=>{
        setLoading(false);
        if(r.ok){
            props.form.setFieldsValue({exchangeRate:r.outData,amountEx:round2(props.form.getFieldValue("amount")/r.outData)});
        }else {
            message.error(r.msg);
        }})
    };

  const field = props.form.getFieldDecorator;
  const values=props.form.getFieldsValue();
  return (
    <div>
      <Modal title={t("New Exchange Operation")} visible={props.visible} onOk={onOk} onCancel={props.onCancel} okButtonProps={{ loading: loading }}>
        <Form.Item label={t("Amount")}>{field("amount", { rules: [{ required: true }] })(<InputNumber min={0} onChange={onChangeAmount} />)}</Form.Item>
        <Form.Item label={t("Reference")}>{field("reference", { rules: [{ required: false }] })(<Input />)}</Form.Item>
        <Form.Item label={t("Operation")}>{field("operationName", { initialValue: props.operation })(<Input disabled />)}</Form.Item>
        <Form.Item label={t("Convert Currency")}>{field("convertCurrency",{valuePropName:"checked"})(<Checkbox  />)}</Form.Item>
        <div style={{display:values.convertCurrency?"block":"none"}}>
        <Form.Item label={t("Target Currency")}>
                {field("targetCurrency")(<Currency excluded={[props.currency]} onChange={(v)=>getExchangeRate(v)} />)}
            </Form.Item>
            <Form.Item label={t("Target Amount")} >
                {field("amountEx")(<Money min={0} currency={values.targetCurrency} disabled />)}
            </Form.Item>
            <Form.Item label={t("Exchange Rate")}>
                {field("exchangeRate")(<InputNumber min={0} disabled placeholder={t("Exchange Rate")} />)}
            </Form.Item>
        </div>
      </Modal>
    </div>
  );
};

export default Form.create()(ExchangeOperationNew);
