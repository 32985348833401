import React from "react";
import { DeleteOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message, notification, Switch, Button, Popconfirm } from "antd";
import { put, del, exe } from "../Lib/Dal";
import IconSelector from "./IconSelector";
import GroupSelect from "./Bpm/GroupSelect";
import { withTranslation } from "react-i18next";

class ProcesosDetalle extends React.Component {
  componentDidMount() {
    this.props.form.setFieldsValue(this.props.definicion);
  }
  prueba() {
    console.log("hola");
  }
  handleSubmit = (e) => {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values, this.state);
        const dto = { ...this.props.definicion, ...values };
        exe("AddOrUpdateDefinition", dto).then((r) => {
          message[r.ok ? "success" : "error"](r.msg);
          if (r.ok) {
            this.props.onOk(r.outData);
          }
        });
      }
    });
  };
  onEliminar = () => {
    exe("DeleteDefinition", { id: this.props.definicion.id }).then((r) => {
      message[r.ok ? "success" : "error"](r.msg);

      if (r.ok) {
        r.outData.delete = true; //bandera para diferenciar de un put
        this.props.onOk(r.outData);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const { t } = this.props;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item {...formItemLayout} label={t("Name")}>
          {getFieldDecorator("name", {
            rules: [{ required: true, message: t("Please enter name") }],
          })(<Input />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label={t("Description")}>
          {getFieldDecorator("description", {})(<Input.TextArea />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label={t("Category")}>
          {getFieldDecorator("category", {})(<Input />)}
        </Form.Item>

        <Form.Item {...formItemLayout} label={t("Enabled")}>
          {getFieldDecorator("enabled", { valuePropName: "checked" })(<Switch />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label={t("Entity")}>
          {getFieldDecorator("entity", {})(<Input />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label={t("Back Permission")}>
          {getFieldDecorator("backPermissionGroupId", {})(<GroupSelect />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label={t("Disable event check")}>
          {getFieldDecorator("eventsDisabled", { valuePropName: "checked" })(<Switch />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label={t("Icon")}>
          {getFieldDecorator("icon", {})(<IconSelector />)}
        </Form.Item>

        <Popconfirm title={t("Confirm delete this process definition?")} onConfirm={(p) => this.onEliminar()} okText={t("Yes")} cancelText={t("No")}>
          <Button type="danger" icon={<DeleteOutlined />}>
            {t("Delete")}
          </Button>
        </Popconfirm>
      </Form>
    );
  }
}
const wrappedProcesoDetalle = Form.create()(ProcesosDetalle);
export default withTranslation()(wrappedProcesoDetalle);
