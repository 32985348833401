import React from "react";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import DatePickerW from "../Shared/DatePickerW";

const ExpiratiionField = (props) => {
  return (
    <div>
      <DatePickerW style={{ width: "100%" }} value={props.value} onChange={props.onChange} disabled={props.disabled} />
      <Text style={{ marginLeft: 3 }}>{props.value ? moment(props.value).fromNow() : null}</Text>
    </div>
  );
};

export default ExpiratiionField;
