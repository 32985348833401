import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeGet } from "../../Lib/Dal";
import { getConfigProfile } from "../../Lib/Helpers";

const DistributionSchemeSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [distributionSchemes, setDistributionSchemes] = useState([]);
    const [t] = useTranslation();

    useEffect(() => {
    getConfigProfile().then((profile) => {
        setDistributionSchemes(safeGet(["Policy", "familyDistributionSchemes"], profile, []));
    });
}, []);
    
    return (
        <div>
            <Select
                style={{width:"100%"}}
                showArrow
                placeholder={t("Optional distribution scheme")}
                value={props.value}
                onChange={(v,opt)=>props.onChange(v,opt)}
                onClear={()=>props.onChange(undefined,undefined)}
                loading={loading}
                allowClear
                showSearch
                optionFilterProp="children">
                {distributionSchemes.map((p) => (
                    <Select.Option value={p.code} key={p.code} record={p}>
                        {t(p.name)}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default DistributionSchemeSelect;
