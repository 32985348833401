import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Popconfirm, Row, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { KeyOutlined} from '@ant-design/icons';
import { exe } from "../../Lib/Dal";

const PasswordChange = (props) => {
    const [loading,setLoading]=useState(false);
    const [t] = useTranslation();
    const [form]=Form.useForm();

    const save=()=>{
        form.validateFields().then(values=>{
            console.log(values);
            const email=localStorage.email;
            setLoading(true);
            exe("ChangePass",{email:email,pass:values.newPassword}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onOk();

                }else{
                    message.error(r.msg);
                }
            })
        })
    }
    const onGenerateStrongPassword=()=>{
        //generating a strong password of 10 random characters, special symbols, numbers, lower and upper case letters
        const strongPassword=Array.from({length:10},()=>String.fromCharCode(Math.floor(Math.random()*94)+33)).join("");
        form.setFieldsValue({newPassword:strongPassword});
      }
      const getRules=()=>{
        let rules=[{required:true,message:t("The password field is required")}];
        if(props.configProfile&&props.configProfile.Security&&props.configProfile.Security.passwordRules){
          rules=[...rules,...props.configProfile.Security.passwordRules]
        }
        return rules;
      }

    return <div>
        <Modal title={t("Change Password")} visible={props.visible} closable={false} footer={[
            <Button key="submit" type="primary" loading={loading} onClick={save}>
                {t("Change Password")}
            </Button>,
       
        ]} destroyOnClose>
        <Form form={form} layout="vertical">
            <Form.Item label={t("New Password")} name={"newPassword"} rules={getRules()}><Input.Password/></Form.Item>
            <Button type="link" onClick={onGenerateStrongPassword} icon={<KeyOutlined />} style={{paddingLeft:0,paddingRight:0}}>{t("Generate a strong password")}</Button>
        </Form>
        </Modal>
    </div>
}
export default PasswordChange;