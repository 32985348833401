import React, { useEffect, useState } from "react";
import { ReloadOutlined, ThunderboltTwoTone, WarningOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {
  Drawer,
  Tabs,
  Empty,
  Table,
  Badge,
  Result,
  Button,
  Tag,
  Tooltip,
  Switch,
  Alert,
} from "antd";
import { exe } from "../Lib/Dal";
import { useTranslation } from "react-i18next";
import config from "../config";
import {round2} from "../Lib/Helpers";

const StatusPage = (props) => {
  const [t] = useTranslation();
  const iniData = [
    { id:"CORE",code:"APP",server: t("Core Server"), ok: true },
    { server: t("Document Server"), ok: true },
    { server: t("Reports"), ok: true },
  ];
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(iniData);
  const [backup, setBackup] = useState(localStorage.sis11api !== undefined);
  const [appCpu,setAppCpu]=useState();
  const [appMem,setAppMem]=useState();
  const [dbStats,setDbStats]=useState();
  const BACKUPURL = "https://sisos-back.azurewebsites.net";

  useEffect(() => {
      check();
      //getPerformanceInfo();
  }, []);
  useEffect(()=>{
      if(props.disconnection){
        setData(data.map(p => p.id === "CORE" ? {...p, ok: false} : p));
      }
  },[props.disconnection])

  const check = () => {
    if (!localStorage.token) return;
    setLoading(true);
    exe("GetServerStatus").then((r) => {
      setLoading(false);
      setData([...iniData, ...r.outData]);
    });
  };

  const onUpdateVersion = () => {
    window.location.reload(true);
  };
  const onAlternateServerChange = (v) => {
    if (v) {
      localStorage.sis11api = BACKUPURL;
    } else {
      localStorage.removeItem("sis11api");
    }
    setBackup(v);
  };
  
  const onClose=()=>{
      props.onClose();
      setData(data.map(p => p.id === "CORE" ? {...p, ok: true} : p));
  }
  
  const getPerformanceInfo=()=>{
      //app server
      exe("GetCpu").then(r=>{
          if(r.ok){
              setAppCpu(round2(r.outData.cpuUsage));
          }
      })
      exe("GetMem").then(r=>{
          if(r.ok){
              setAppMem(r.outData.mem);
          }
      })
  }
    const getDbPerformanceInfo=()=>{
        //db server
        exe("GetDbStats").then(r=>{
            if(r.ok){
                setDbStats(r.outData.stats);
            }
        })
    }
  
  const renderMoreInfo=code=>{
      switch (code){
          case "APP":
              return <div>{appMem?<span>Cpu: {appCpu}% Mem: {appMem}GB</span>:null}<Button type={"link"} icon={<LegacyIcon type={"reload"} />} onClick={()=>getPerformanceInfo()} /> </div>;
          case "DB":
              return <div>{dbStats?<span>Cpu: {dbStats.avg_cpu_percent}% Mem: {dbStats.avg_memory_usage_percent}%</span>:null}<Button type={"link"} icon={<LegacyIcon type={"reload"} />} onClick={()=>getDbPerformanceInfo()} /> </div>;
          default:
              return null;
      }
  }
  //only url on axxis-systems.net have backup server
  const hasBackupServer = window.location.href.includes("axxis-systems.net");

  return (
    <Drawer
      title={
        <span>
          <ThunderboltTwoTone style={{ marginRight: 5, fontSize: 18 }} />
          {t("Server Status")}
        </span>
      }
      visible={props.visible}
      onClose={onClose}
      placement="left"
      width={500}>
      <span style={{ bottom: 5, left: 10 }}>
        {t("Version")}:<Tag style={{ marginLeft: 3 }}>{process.env.REACT_APP_VERSION}</Tag>
      </span>
      {data.some((p) => !p.ok) ? (
        <Result status="warning" title={t("Some servers are having issues")} subTitle={t("Please contact Axxis Systems support center")} />
      ) : (
        <Result
          status="success"
          title={t("All servers are fully operational")}
          subTitle={t("If you encounter any degraded servers please contact Axxis Systems support center")}
        />
      )}
      <Table dataSource={data} pagination={false} loading={loading}>
        <Table.Column
          title={t("Server")}
          dataIndex="server"
          render={(v, r) =>
            r.ok ? (
              v
            ) : (
              <Tooltip title={r.msg}>
                <span>{v}</span>
                <WarningOutlined style={{ color: "orange", marginLeft: 5 }} />
              </Tooltip>
            )
          }
        />
        <Table.Column title={t("Status")} dataIndex="ok" render={(v) => <Badge status={v ? "success" : "error"} />} />
        <Table.Column title={t("Performance")} dataIndex={"code"} render={(v,r)=>renderMoreInfo(v)}  />
      </Table>
      <Button type="primary" style={{ marginTop: 15 }} icon={<ReloadOutlined />} onClick={onUpdateVersion}>
        {t("Update Version")}
      </Button>
      <div style={{ marginTop: 15, marginBottom: 15 }}>
        <Switch checked={backup} onChange={onAlternateServerChange} style={{ marginRight: 15 }} disabled={!hasBackupServer} />
        {t("Use backup environment")}
      </div>
      {((backup && config.ssApiUrl !== BACKUPURL) || (!backup && config.ssApiUrl == BACKUPURL)) && (
        <Alert type="warning" showIcon message={t("Please refresh browser to apply changes")}></Alert>
      )}
    </Drawer>
  );
};

export default StatusPage;
