import React from 'react';
import {Select} from "antd";
const Option=Select.Option;
const IsoLanguageSelect = (props) => {
    return (
        <div>
            <Select value={props.value} 
                    onChange={v=>props.onChange(v)} style={{width:"100%"}}   
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                <Option value="af">Afrikaans</Option>
                <Option value="ar-dz">Arabic (Algeria)</Option>
                <Option value="ar-kw">Arabic (Kuwait)</Option>
                <Option value="ar-ly">Arabic (Libya)</Option>
                <Option value="ar-ma">Arabic (Morocco)</Option>
                <Option value="ar-sa">Arabic (Saudi Arabia)</Option>
                <Option value="ar-tn">Arabic (Tunisia)</Option>
                <Option value="ar">Arabic</Option>
                <Option value="az">Azerbaijani</Option>
                <Option value="be">Belarusian</Option>
                <Option value="bg">Bulgarian</Option>
                <Option value="bm">Bambara</Option>
                <Option value="bn-bd">Bengali (Bangladesh)</Option>
                <Option value="bn">Bengali</Option>
                <Option value="bo">Tibetan</Option>
                <Option value="br">Breton</Option>
                <Option value="bs">Bosnian</Option>
                <Option value="ca">Catalan</Option>
                <Option value="cs">Czech</Option>
                <Option value="cv">Chuvash</Option>
                <Option value="cy">Welsh</Option>
                <Option value="da">Danish</Option>
                <Option value="de-at">German (Austria)</Option>
                <Option value="de-ch">German (Switzerland)</Option>
                <Option value="de">German</Option>
                <Option value="dv">Divehi</Option>
                <Option value="el">Greek</Option>
                <Option value="en-au">English (Australia)</Option>
                <Option value="en-ca">English (Canada)</Option>
                <Option value="en-gb">English (United Kingdom)</Option>
                <Option value="en-ie">English (Ireland)</Option>
                <Option value="en-il">English (Israel)</Option>
                <Option value="en-in">English (India)</Option>
                <Option value="en-nz">English (New Zealand)</Option>
                <Option value="en-sg">English (Singapore)</Option>
                <Option value="eo">Esperanto</Option>
                <Option value="es-cl">Spanish (Chile)</Option>
                <Option value="es-do">Spanish (Dominican Republic)</Option>
                <Option value="es-mx">Spanish (Mexico)</Option>
                <Option value="es-us">Spanish (United States)</Option>
                <Option value="es">Spanish</Option>
                <Option value="et">Estonian</Option>
                <Option value="eu">Basque</Option>
                <Option value="fa">Persian</Option>
                <Option value="fi">Finnish</Option>
                <Option value="fil">Filipino</Option>
                <Option value="fo">Faroese</Option>
                <Option value="fr-ca">French (Canada)</Option>
                <Option value="fr-ch">French (Switzerland)</Option>
                <Option value="fr">French</Option>
                <Option value="fy">Western Frisian</Option>
                <Option value="gd">Scottish Gaelic</Option>
                <Option value="gl">Galician</Option>
                <Option value="gom-latn">gom (Latin)</Option>
                <Option value="gu">Gujarati</Option>
                <Option value="he">Hebrew</Option>
                <Option value="hi">Hindi</Option>
                <Option value="hr">Croatian</Option>
                <Option value="hu">Hungarian</Option>
                <Option value="hy-am">Armenian (Armenia)</Option>
                <Option value="id">Indonesian</Option>
                <Option value="is">Icelandic</Option>
                <Option value="it-ch">Italian (Switzerland)</Option>
                <Option value="it">Italian</Option>
                <Option value="ja">Japanese</Option>
                <Option value="jv">Javanese</Option>
                <Option value="ka">Georgian</Option>
                <Option value="kk">Kazakh</Option>
                <Option value="km">Khmer</Option>
                <Option value="kn">Kannada</Option>
                <Option value="ko">Korean</Option>
                <Option value="ku">Kurdish</Option>
                <Option value="ky">Kirghiz</Option>
                <Option value="lb">Luxembourgish</Option>
                <Option value="lo">Lao</Option>
                <Option value="lt">Lithuanian</Option>
                <Option value="lv">Latvian</Option>
                <Option value="me">Montenegrin</Option>
                <Option value="mi">Maori</Option>
                <Option value="mk">Macedonian</Option>
                <Option value="ml">Malayalam</Option>
                <Option value="mn">Mongolian</Option>
                <Option value="mr">Marathi</Option>
                <Option value="ms-my">Malay (Malaysia)</Option>
                <Option value="ms">Malay</Option>
                <Option value="mt">Maltese</Option>
                <Option value="my">Burmese</Option>
                <Option value="nb">Norwegian Bokmål</Option>
                <Option value="ne">Nepali</Option>
                <Option value="nl-be">Dutch (Belgium)</Option>
                <Option value="nl">Dutch</Option>
                <Option value="nn">Norwegian Nynorsk</Option>
                <Option value="pa-in">Punjabi (India)</Option>
                <Option value="pl">Polish</Option>
                <Option value="pt-br">Portuguese (Brazil)</Option>
                <Option value="pt">Portuguese</Option>
                <Option value="ro">Romanian</Option>
                <Option value="ru">Russian</Option>
                <Option value="sd">Sindhi</Option>
                <Option value="se">Northern Sami</Option>
                <Option value="si">Sinhala</Option>
                <Option value="sk">Slovak</Option>
                <Option value="sl">Slovenian</Option>
                <Option value="sq">Albanian</Option>
                <Option value="sr-cyrl">Serbian (Cyrillic)</Option>
                <Option value="sr">Serbian</Option>
                <Option value="ss">Swati</Option>
                <Option value="sv">Swedish</Option>
                <Option value="sw">Swahili</Option>
                <Option value="ta">Tamil</Option>
                <Option value="te">Telugu</Option>
                <Option value="tet">Tetum</Option>
                <Option value="tg">Tajik</Option>
                <Option value="th">Thai</Option>
                <Option value="tk">Turkmen</Option>
                <Option value="tl-ph">Tagalog (Philippines)</Option>
                <Option value="tlh">Klingon</Option>
                <Option value="tr">Turkish</Option>
                <Option value="tzl">Talossan</Option>
                <Option value="tzm-latn">Central Atlas Tamazight (Latin)</Option>
                <Option value="tzm">Central Atlas Tamazight</Option>
                <Option value="ug-cn">Uyghur (China)</Option>
                <Option value="uk">Ukrainian</Option>
                <Option value="ur">Urdu</Option>
                <Option value="uz-latn">Uzbek (Latin)</Option>
                <Option value="uz">Uzbek</Option>
                <Option value="vi">Vietnamese</Option>
                <Option value="x-pseudo">Pseudo</Option>
                <Option value="yo">Yoruba (Nigeria)</Option>
                <Option value="zh-cn">Chinese (China)</Option>
                <Option value="zh-hk">Chinese (Hong Kong)</Option>
                <Option value="zh-mo">Chinese (Macau)</Option>
                <Option value="zh-tw">Chinese (Taiwan)</Option>
            </Select>
        </div>
    );
};

export default IsoLanguageSelect;