import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const IncomeTypeSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [t] = useTranslation();

    useEffect(() => load(), []);
    
    useEffect(()=>{
        if(props.onSelect){ //forcing onselect event when value changes
            const selectedRecord=data.find(p=>p.code===props.value);
            props.onSelect(props.value,selectedRecord);
        }
    },[props.value])
    
    useEffect(()=>{
        if(props.default){
            const defaultRecord=data.find(p=>p.internalType==props.default);
            if(defaultRecord) props.onChange(defaultRecord.code);
        }
    },[props.default])
    

    const load = () => {
        setLoading(true);
        exe("RepoIncomeTypeCatalog", { operation: "GET" }).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else {
                message.error(r.msg);
            }
        });
    };
    return (
        <div>
            <Select
                showArrow
                value={props.value}
                onChange={props.onChange}
                loading={loading}
                allowClear
                showSearch
                //onSelect={(v,option)=>props.onSelect&&props.onSelect(v,option)}
                optionFilterProp="children">
                {data.map((p) => (
                    <Select.Option value={p.code} key={p.code} record={p}>
                        {t(p.name)}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default IncomeTypeSelect;
