import React, { useEffect, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import {
  DollarOutlined,
  EditOutlined,
  FileOutlined,
  FilterOutlined,
  ReloadOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Tabs,
  Row,
  Col,
  Switch,
  Input,
  message,
  Button,
  notification,
  Statistic,
  Modal,
} from "antd";
import yaml from "js-yaml";
import ContactSelect from "../Contact/ContactSelect";
import Periodicty from "../Shared/Periodicty";
import Title from "antd/lib/typography/Title";
import { exe } from "../../Lib/Dal";
import ComData from "./ComData";
import ComFilter from "./ComFilter";
import ComLiquidation from "./ComLiquidation";
import ComLiquidationList from "./ComLiquidationList";
import { useTranslation } from "react-i18next";
import ComParticipants from "./ComParticipants";
import AccountSelect from "../Shared/AccountSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import Status from "../Shared/Status";
import ComContractChanges from "./ComContractChanges";
import ComTreeSelect from "./ComTreeSelect";
import DatePickerW from "../Shared/DatePickerW";
import CodeEditorW from "../Shared/CodeEditorW";

const ComContract = (props) => {
  const [t, i18n] = useTranslation();
  const field = props.form.getFieldDecorator;
  const id = props.form.getFieldValue("id");
  const [data, setData] = useState([]);
  const [filterVisible, setFilterVisible] = useState(false);
  const [liquidationVisible, setLiquidationVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState();
  const [reloadComData, doReloadComData] = useState(0);

  useEffect(() => {
    if (props.match.params.comContractId !== "0") {
      load(props.match.params.comContractId);
    } else {
      props.form.resetFields();
      setProcess();
    }
  }, [props.match.params.comContractId]);

  const save = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      //extra fields
      values.config = window.global.myCodeMirror.getValue();
      //yaml validation
      try {
        const result = yaml.safeLoad(values.config);
        values.configJson = JSON.stringify(result);
      } catch (error) {
        notification["error"]({
          message: error.reason,
          description: error.message,
        });
        return;
      }
      exe("RepoComContract", { operation: values.id ? "UPDATE" : "ADD", entity: values }).then((r) => {
        if (r.ok) {
          props.form.setFieldsValue(r.outData[0]);
          if (r.outData[0].Process) setProcess(r.outData[0].Process);
          message.success(r.msg);
          window.location = "#/comContract/" + r.outData[0].id;
        } else {
          if (r.changeRequest) {
            const before = {};
            const after = {};
            r.unauthorized.forEach((element) => {
              before[element] = r.outData[0][element];
              after[element] = r.data.entity[element];
            });
            props.form.setFieldsValue(before); //reverting unauth changes
            window.global.myCodeMirror.setValue(before.config);
            if(after.config) after.configJson =  r.data.entity.configJson; //YAML-JSON sync
            Modal.confirm({
              title: t("Restricted change"),
              content: r.msg,
              onOk() {
                const change = {
                  comContractId: r.outData[0].id,
                  jBefore: JSON.stringify(before),
                  JAfter: JSON.stringify(after),
                  operation: r.changeOperation,
                };
                exe("AddComContractChange", change).then((r) => {
                  if (r.ok) {
                    message.success(r.msg);
                    props.form.setFieldsValue({ Changes: r.outData });
                  } else {
                    message.error(r.msg);
                  }
                });
              },
            });
          } else {
            message.error(r.msg);
          }
        }
      });
    });
  };
  const load = (id) => {
    setLoading(true);
    exe("RepoComContract", { operation: "GET", filter: "id=" + id, include: ["Process", "Changes", "Changes.Process"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        if (r.outData.length == 0) return message.error(`${t("Record")} ${id} ${t("not found")}`);
        props.form.setFieldsValue(r.outData[0]);
        //extra fields
        if (r.outData[0].config) window.global.myCodeMirror.setValue(r.outData[0].config);
        if (r.outData[0].Process) setProcess(r.outData[0].Process);
      } else {
        message.error(r.msg);
      }
    });
  };

  const values = props.form.getFieldsValue();
  return (
    <DefaultPage
      title={t("Commission Contract") + " " + (id ? id : "")}
      icon="snippets"
      loading={loading}
      tags={<Status process={process} reload={() => load(id)} />}
      extra={
        <div>
          <Button icon={<ReloadOutlined />} type="link" onClick={() => load(values.id)} disabled={!values.id}>
            {t("Reload")}
          </Button>
          <Button icon={<FileOutlined />} type="link" onClick={() => (window.location = "#/comContract/0")}>
            {t("New")}
          </Button>
          <Button type="primary" icon={<SaveOutlined />} onClick={() => save()}>
            {t("Save")}
          </Button>
        </div>
      }>
      {field("processId")}
      <Tabs>
        {/* ////////////////////////////////Contract  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Contract")} key="contract">
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={12}>
                {field("id")}
                <Form.Item label={t("Name")}> {field("name", { rules: [{ required: true }] })(<Input />)}</Form.Item>
                <Form.Item label={t("Contact")}> {field("contactId", { rules: [{ required: true }] })(<SimpleContactSelect allowNew/>)}</Form.Item>
                <Form.Item label={t("Periodicity")}> {field("periodicity")(<Periodicty />)}</Form.Item>
                <Form.Item label={t("Notes")}> {field("notes")(<Input.TextArea />)}</Form.Item>
                  <div style={{ display: "flex",flexDirection:"column",  marginBottom: 4 }}>
                    <Form.Item label={t("Active")}>
                      {field("active", { valuePropName: "checked" })(<Switch disabled={!values.id || (process && process.entityState !== "APROVED")} />)}
                    </Form.Item>
                    <Form.Item label={t("Umbrella Contract")} >
                      {field("isUmbrella", { valuePropName: "checked" })(<Switch />)}
                    </Form.Item>
                    <Form.Item label={t("Ad-hoc Commission")} >
                      {field("isAdHoc", { valuePropName: "checked" })(<Switch />)}
                    </Form.Item>
                  </div>
              </Col>
              <Col span={12}>
               
                <Form.Item label={t("Code")}> {field("code")(<Input />)}</Form.Item>
                <Form.Item label={t("Operating Account")}>{field("operatingAccountId", { rules: [{ required: false }] })(<AccountSelect />)}</Form.Item>
                <div style={{ display: "flex" }}>
                  <Form.Item label={t("Start")}> {field("start")(<DatePickerW />)}</Form.Item>
                  <Form.Item label={t("End")} style={{ marginLeft: 5 }}>
                    {field("end")(<DatePickerW />)}
                  </Form.Item>
                </div>
                <Form.Item label={t("Multi Level Scheme")}> {field("comTreeId")(<ComTreeSelect />)}</Form.Item>
              </Col>
            </Row>
          </Form>
        </Tabs.TabPane>
        {/* ////////////////////////////////Behaviour  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Behaviour")} key="behaviour" forceRender>
          <Title level={4}>{t("Contract Behaviour")}</Title>
          <CodeEditorW />
        </Tabs.TabPane>
        {/* ////////////////////////////////Participants  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Participants")} key="Participants">
          <Title level={4}>{t("Contract Participants")}</Title>
          <ComParticipants contractId={values.id} isUmbrella={values.isUmbrella} />
        </Tabs.TabPane>
        {/* ////////////////////////////////Commissions  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Commissions")} key="commissions">
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: -20 }}>
            <Statistic style={{ marginRight: 40 }} title={t("Current Month")} value={data.currentMonth} />
            <Statistic style={{ marginRight: 40 }} title={t("Last Month")} value={data.lastMonth} />
            <Statistic style={{ marginRight: 40 }} title={t("Total")} value={data.total} />
          </div>
          <Button type="link" icon={<DollarOutlined />} onClick={() => setLiquidationVisible(true)} style={{ float: "left" }} disabled={selected.length == 0}>
            {t("Liquidation")}
          </Button>
          <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)} style={{ float: "left" }}>
            {t("Filter")}
          </Button>
          <Button type="link" icon={<ReloadOutlined />} onClick={() =>doReloadComData(c=>c+1)} style={{ float: "left" }}>
            {t("Reload")}
          </Button>
          <ComData data={data} selectable onSelectChange={(s) => setSelected(s)} comContractId={id} reload={()=>doReloadComData(c=>c+1)} />
          <ComFilter
            filter={{ contract: id }}
            onResults={(r) => setData(r)}
            visible={filterVisible}
            onClose={() => setFilterVisible(false)}
            setLoading={(v) => setLoading(v)}
            reload={reloadComData}
          />
          <ComLiquidation
            visible={liquidationVisible}
            onClose={(success) => {setLiquidationVisible(false);if(success) doReloadComData(c=>c+1)}}
            selected={selected}
            contract={props.form.getFieldsValue()}
          />
        </Tabs.TabPane>
        {/* ////////////////////////////////Liquidations  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane tab={t("Liquidations")} key="liquidations" disabled={!id}>
          <ComLiquidationList comContractId={id} />
        </Tabs.TabPane>
        {/* ////////////////////////////////Changes  ////////////////////////////////////////////////////////////////// */}
        <Tabs.TabPane
          tab={
            <span>
              <EditOutlined />
              {t("Changes")}
            </span>
          }
          key="changes">
          <Title level={4}>{t("Change Requests")}</Title>
          {field("Changes", {})(<ComContractChanges onRefresh={() => load(id)} />)}
        </Tabs.TabPane>
      </Tabs>
    </DefaultPage>
  );
};

export default Form.create()(ComContract);
