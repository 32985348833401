import React from "react";
import { Select, Space } from "antd";
import { useTranslation } from "react-i18next";
import {
    PlusOutlined,
    MinusOutlined,
    EuroOutlined,
    ClockCircleOutlined,
    SolutionOutlined,
    TeamOutlined,
    UserOutlined,
    BankOutlined,
    UserAddOutlined,
    LineChartOutlined,
    ScissorOutlined,
    ExclamationCircleOutlined,
    CloseOutlined,
    RiseOutlined,
    DashboardOutlined,
    ExperimentOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined,
    BlockOutlined,
    ToTopOutlined,
    RedoOutlined,
    GiftOutlined,
    CalendarOutlined,
    FormOutlined,
    UnorderedListOutlined,
    OrderedListOutlined,
    ShoppingOutlined,
    CloseSquareOutlined,
    PercentageOutlined,
    SwapOutlined,
    ToolOutlined,
    CheckSquareOutlined,
    StopOutlined,
    HomeOutlined,
    PullRequestOutlined,
    UsergroupAddOutlined,
    ScheduleOutlined,
    InfoCircleOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
  
const ChangeSelect = (props) => {
    const [t]= useTranslation();

return <Select onChange={props.onChange} onClear={()=>props.onChange(undefined)} value={props.value} style={{ width: "100%" }} placeholder={t("Select a change")} allowClear> 
<Select.Option value="AddCertificateChange">
  <Space>
    <PlusOutlined />
    {t("Add Certificate")}
  </Space>
</Select.Option>

<Select.Option value="RemoveCertificateChange">
  <Space>
    <MinusOutlined />
    {t("Remove Certificate")}
  </Space>
</Select.Option>

<Select.Option value="CapitalChange">
  <Space>
    <EuroOutlined />
    {t("Sum Insured")}
  </Space>
</Select.Option>

<Select.Option value="TermChange">
  <Space>
    <ClockCircleOutlined />
    {t("Term")}
  </Space>
</Select.Option>

<Select.Option value="PolicyholderChange">
  <Space>
    <SolutionOutlined />
    {t("Policyholder")}
  </Space>
</Select.Option>

<Select.Option value="CoInsuredChange">
  <Space>
    <TeamOutlined />
    {t("Co-Insured")}
  </Space>
</Select.Option>

<Select.Option value="BeneficiaryChange">
  <Space>
    <UserOutlined />
    {t("Beneficiary")}
  </Space>
</Select.Option>

<Select.Option value="CessionBeneficiaryChange">
  <Space>
    <BankOutlined />
    {t("Cession Beneficiary")}
  </Space>
</Select.Option>

<Select.Option value="ContingentBeneficiaryChange">
  <Space>
    <UserAddOutlined />
    {t("Contingent Beneficiary")}
  </Space>
</Select.Option>

<Select.Option value="InvestmentChange">
  <Space>
    <LineChartOutlined />
    {t("Investment Product")}
  </Space>
</Select.Option>

<Select.Option value="RescueChange">
  <Space>
    <ScissorOutlined />
    {t("Partial Surrender")}
  </Space>
</Select.Option>

<Select.Option value="TotalRescueChange">
  <Space>
    <ExclamationCircleOutlined />
    {t("Total Surrender")}
  </Space>
</Select.Option>

<Select.Option value="MaturityChange">
  <Space>
    <CloseOutlined />
    {t("Maturity")}
  </Space>
</Select.Option>

<Select.Option value="IndexationChange">
  <Space>
    <RiseOutlined />
    {t("Indexation Option")}
  </Space>
</Select.Option>

<Select.Option value="ReAdjustmentChange">
  <Space>
    <DashboardOutlined />
    {t("Re-Adjustment")}
  </Space>
</Select.Option>

<Select.Option value="ReEvaluationChange">
  <Space>
    <ExperimentOutlined />
    {t("Re-Evaluation")}
  </Space>
</Select.Option>

<Select.Option value="AddCoverageChange">
  <Space>
    <PlusCircleOutlined />
    {t("Attach Coverage")}
  </Space>
</Select.Option>

<Select.Option value="RemoveCoverageChange">
  <Space>
    <MinusCircleOutlined />
    {t("Detach Coverage")}
  </Space>
</Select.Option>

<Select.Option value="PlanChange">
  <Space>
    <BlockOutlined />
    {t("Plan")}
  </Space>
</Select.Option>

<Select.Option value="PaidUpChange">
  <Space>
    <ToTopOutlined />
    {t("Paid-Up")}
  </Space>
</Select.Option>

<Select.Option value="PaidUpReinstatementChange">
  <Space>
    <RedoOutlined />
    {t("Paid-Up Reinstatement")}
  </Space>
</Select.Option>

<Select.Option value="BonusChange">
  <Space>
    <GiftOutlined />
    {t("Happy Event")}
  </Space>
</Select.Option>

<Select.Option value="FrequencyChange">
  <Space>
    <CalendarOutlined />
    {t("Premium Frequency")}
  </Space>
</Select.Option>

<Select.Option value="PayPlanChange">
  <Space>
    <FormOutlined />
    {t("Installment Edition")}
  </Space>
</Select.Option>

<Select.Option value="PaymentMethodChange">
  <Space>
    <EuroOutlined />
    {t("Payment Method")}
  </Space>
</Select.Option>

<Select.Option value="CoverageChange">
  <Space>
    <UnorderedListOutlined />
    {t("Coverage Edition")}
  </Space>
</Select.Option>

<Select.Option value="BenefitChange">
  <Space>
    <OrderedListOutlined />
    {t("Benefit Edition")}
  </Space>
</Select.Option>

<Select.Option value="IntermediaryChange">
  <Space>
    <ShoppingOutlined />
    {t("Intermediary")}
  </Space>
</Select.Option>

<Select.Option value="ReinstatementChange">
  <Space>
    <RedoOutlined />
    {t("Reinstatement")}
  </Space>
</Select.Option>

<Select.Option value="NotTakenUpChange">
  <Space>
    <CloseSquareOutlined />
    {t("Not Taken Up")}
  </Space>
</Select.Option>

<Select.Option value="RedirectionChange">
  <Space>
    <PercentageOutlined />
    {t("Redirection")}
  </Space>
</Select.Option>

<Select.Option value="SwitchChange">
  <Space>
    <SwapOutlined />
    {t("Switch")}
  </Space>
</Select.Option>

<Select.Option value="LoadingChange">
  <Space>
    <ToolOutlined />
    {t("Loading")}
  </Space>
</Select.Option>

<Select.Option value="ClauseChange">
  <Space>
    <CheckSquareOutlined />
    {t("Clauses")}
  </Space>
</Select.Option>

<Select.Option value="ExclusionChange">
  <Space>
    <StopOutlined />
    {t("Exclusions")}
  </Space>
</Select.Option>

<Select.Option value="InsuredObjectChange">
  <Space>
    <HomeOutlined />
    {t("Insured Object")}
  </Space>
</Select.Option>

<Select.Option value="AnnuityBeneficiaryChange">
  <Space>
    <PullRequestOutlined />
    {t("Annuity Beneficiary")}
  </Space>
</Select.Option>

<Select.Option value="GroupChange">
  <Space>
    <UsergroupAddOutlined />
    {t("Certificate Group")}
  </Space>
</Select.Option>

<Select.Option value="TemporalStatusChange">
  <Space>
    <ScheduleOutlined />
    {t("Temporal Status")}
  </Space>
</Select.Option>

<Select.Option value="InformativeChange">
  <Space>
    <InfoCircleOutlined />
    {t("Informative")}
  </Space>
</Select.Option>

<Select.Option value="CancellationChange">
  <Space>
    <DeleteOutlined />
    {t("Cancel")}
  </Space>
</Select.Option>
</Select>



}
export default ChangeSelect;