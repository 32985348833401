import React, { useState } from "react";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import config from "../../config";
import { message } from "antd";
import { useTranslation } from "react-i18next";

const serverUrl = config.ssApiUrl + "/proxy";
const docserver = "http:" + config.docServerUrl;
export default function FileUpload(props) {
  const [t] = useTranslation();
  const [files, setFiles] = useState([]);

  const addProcessedFile = (response) => {
    const r = JSON.parse(response.serverId);
    const newFilerecord = { fileName: r.fileName, url: r.url, name: "Uploaded", created: new Date(r.created) };
    setFiles([...files, newFilerecord]);
  };
  const propagate = () => {
    if (props.onChange) {
      props.onChange(files);
      setFiles([]);
    }
  };
  if (props.hidden) return null;
  const destinationFolder = props.destinationFolder || "/uploaded";
  const preserveName = props.preserveName || false;
  return (
    <div className="App">
      <FilePond
        allowMultiple={true}
        allowRevert={false}
        onaddfilestart={(file) => {
          if (file.filename.includes("#")) {
            message.error(t("Please remove special character (#) from file name"));
            file.abortLoad();
            return;
          }
          if(props.beforeAddFile) props.beforeAddFile(file);
        }}
        onprocessfile={(err, file) => {
          if (!err) addProcessedFile(file);
        }}
        onprocessfiles={() => {
          propagate();
        }}
        server={{
          url: serverUrl + "/upload",
          process: {
            headers: {
              Authorization: "Bearer " + localStorage.token,
              Folder: destinationFolder,
              PreserveName: preserveName,
              [props.fileName?"FileName":"N"]: props.fileName,
            },
          },
        }}
        labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
      />
    </div>
  );
}
