import { useTranslation } from "react-i18next";
import DefaultPage from "../Shared/DefaultPage";
import { SearchOutlined, FileOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import React,{ useEffect, useState } from "react";
import { Button, Col, Form, Input, Popconfirm, Row, Table, message, notification } from "antd";
import ContributionData from "./ContributionData";
import { EditOutlined } from '@ant-design/icons';
import { LeftOutlined } from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import Title from "antd/lib/skeleton/Title";
import CodeEditorW from "../Shared/CodeEditorW";
import yaml from 'js-yaml';

const PensionProducts = props => {
    const [filterVisible, setFilterVisible] = useState(false);
    const [operation,setOperation]=useState();
    const [data,setData]=useState([]);
    const [loading,setLoading]=useState(false);
    const [selected,setSelected]=useState();
    const [t]=useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        load();
    },[])

    const load=()=>{
        setLoading(true);
        exe("RepoPensionProduct",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    const save=()=>{
        //validatingform
        form.validateFields().then(values=>{
            //extra fields
            values.config = window.global.pensionProductCode.getValue();
            //yaml validation
            try {
            const result = yaml.safeLoad(values.config);
            values.configJson = JSON.stringify(result);
            } catch (error) {
            notification["error"]({
                message: error.reason,
                description: error.message,
            });
            return;
            }
            setLoading(true);
            exe("RepoPensionProduct",{operation:operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    setOperation("UPDATE");
                    message.success(r.msg);
                    load();
                }else message.error(r.msg);
            })
        })

    }
    const loadProduct=record=>{
        setSelected(record);
        form.setFieldsValue(record);
        window.global.pensionProductCode.setValue(record.config);
        setOperation("UPDATE");
    }
    const onNew=()=>{
        setSelected({});
        form.resetFields();
        window.global.pensionProductCode.setValue("");
        setOperation("ADD");

    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoPensionProduct",{operation:"DELETE",entity:selected}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                setSelected();
                load();
            }else message.error(r.msg);
        })
    }
   
    return <DefaultPage title={t("Pension Products")} icon="control" routes={{
        routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Pension Products"), path: "" }
        ]
    }}
        extra={<div>
            {!selected&&<Button type="primary" icon={<FileOutlined />} onClick={onNew} >{t("New")}</Button>}
            {selected&&<Button type="primary" icon={<SaveOutlined />} onClick={save} >{t("Save")}</Button>}
            </div>}>
        
        <div style={{display:selected?"none":"block"}}>
            <Table dataSource={data} loading={loading}>
                <Table.Column title={t("Code")} dataIndex="code" />
                <Table.Column title={t("Name")} dataIndex="name" />
                <Table.Column title={t("Actions")} key={"actions"} render={(text,record)=>(<Button type="link" icon={<EditOutlined />} onClick={()=>loadProduct(record)}>{t("Edit")}</Button>)} />
            </Table>
        </div>
        <div style={{display:selected?"block":"none"}}>
               <Button type="link" icon={<LeftOutlined />} onClick={()=>setSelected()}>{t("Back")}</Button> 
               <Popconfirm title={t("Are you sure to delete this product?")} onConfirm={()=>onDelete()} okText={t("Yes")} cancelText={t("No")}>
                    <Button type="link" icon={<DeleteOutlined />}>{t("Delete")}</Button> 
                </Popconfirm>
               {/* <Button type="link" icon={<SaveOutlined />} onClick={()=>save()}>{t("Save")}</Button>  */}
               <Title level={4}>{t("Basic Info")}</Title>
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item label={t("Code")} name="code" rules={[{required:true}]}><Input disabled={operation=="UPDATE"} /></Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={t("Name")} name="name" rules={[{required:true}]}><Input /></Form.Item>
            </Col>
          </Row>
        </Form>
        <Title level={4}>{t("Behaviour")}</Title>
        <CodeEditorW saveHotKey={() => save()} divId="pensionProductCode" />
        </div>
    </DefaultPage>
}
export default PensionProducts;