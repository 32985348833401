import React from "react";
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, message, notification } from "antd";
import { post, put } from "../../Lib/Dal";

class EstadoDetalle extends React.Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    //mode edicion
    if (this.props.record && this.props.record !== prevProps.record) {
      //form validator hace un update con cada cambio
      this.props.form.setFieldsValue(this.props.record);
      return;
    }
    //modo nuevo registro
    if (this.props.new && this.props.new !== prevProps.new) {
      //form validator hace un update con cada cambio. Con esto solo entra la 1a vez
      this.props.form.resetFields();
    }
  }

  handleOk = e => {
    //e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values, this.state);
        if (this.props.new) {
          post("/"+this.props.tipoMaestro, values).then(r => {
            console.log(r, "respuesta de post");
            if (r.ok) {
              message.success("OK");
              this.props.afterAdd();
            } else {
              notification.error({
                message: "Error",
                description: r.msg
              });
            }
          });
        } else {
          put("/"+this.props.tipoMaestro+"/" + this.props.record.idInterno, values).then(r => {
            console.log(r, "respuesta de put");
            if (r.ok) {
              message.success("OK");
              this.props.afterAdd();
            } else {
              notification.error({
                message: "Error",
                description: r.msg
              });
            }
          });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    return (
      <Modal
        title={this.props.new ? "Nuevo Estado" : "Editar Estado"}
        visible={this.props.visible}
        onOk={this.handleOk}
        onCancel={this.props.onClose}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item {...formItemLayout} label="Nombre">
            {getFieldDecorator("nombre", {
              rules: [{ required: true, message: "Por favor ingrese nombre" }]
            })(<Input />)}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Id Interno">
            {getFieldDecorator("idInterno", {
              rules: [
                { required: true, message: "Por favor ingrese id interno" }
              ]
            })(<Input />)}
          </Form.Item>

          <Form.Item {...formItemLayout} label="Id Externo">
            {getFieldDecorator("idExterno", {
              rules: [
                { required: true, message: "Por favor ingrese id externo" }
              ]
            })(<Input />)}
          </Form.Item>

          {this.props.tipoMaestro=="estadosiniestro"&&(<Form.Item {...formItemLayout} label="% Avance">
            {getFieldDecorator("pAvance", {
              rules: [
                { required: true, message: "Por favor ingrese % avance" }
              ]
            })(<Input />)}
          </Form.Item>)}
        </Form>
      </Modal>
    );
  }
}

const WrappedForm = Form.create()(EstadoDetalle);
export default WrappedForm;
