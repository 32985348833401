import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Select, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";

const Contingent = (props) => {
  const [t] = useTranslation();

  const onSelectPrimary = (beneficiaryId, index) => {
    contingents[index].beneficiaryId = beneficiaryId;
    props.onChange(contingents);
  };
  const onSelectContingent = (contact, index) => {
    contingents[index].contactId = contact.id;
    props.onChange(contingents);
  };
  const onAdd = () => {
    props.onChange([...contingents, { beneficiaryId: undefined, contactId: 0 }]);
  };
  const onRemoveContingent = (item, index) => {
    if (props.disabled) return;
    contingents.splice(index, 1);
    props.onChange(contingents);
  };
  const contingents = props.value || [];
  const includedBeneficiaries = contingents.map((c) => c.beneficiaryId);
  const beneficiaries = props.beneficiaries || [];
  //.filter((p) => !includedBeneficiaries.includes(p.id));
  return (
    <div>
      {contingents.length > 0 && (
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: -15 }}>{t("Primary Beneficiary")}</div>
          <div style={{ flex: 1 }}>{t("Contingent Beneficiary")}</div>
        </div>
      )}
      {contingents.map((p, index) => (
        <div style={{ display: "flex", marginTop: 5 }}>
          <Select
            value={p.beneficiaryId}
            onChange={(v) => onSelectPrimary(v, index)}
            style={{ marginRight: 3 }}
            placeholder={t("Please select primary beneficiary")}>
            {beneficiaries.map((p) => (
              <Select.Option value={p.id} disabled={includedBeneficiaries.includes(p.id)}>
                {p.name + " (" + p.coverageCode + ")"}
              </Select.Option>
            ))}
          </Select>
          <SimpleContactSelect allowNew onSelectContact={(contact) => onSelectContingent(contact, index)} value={p ? p.contactId : undefined} />
          <MinusCircleOutlined
            style={{ marginLeft: 5, cursor: "pointer", fontSize: 24, color: "#999", alignSelf: "center" }}
            onClick={() => onRemoveContingent(p, index)} />
        </div>
      ))}
      <Button type="link" icon={<PlusCircleOutlined />} onClick={() => onAdd()} style={{ paddingLeft: 0, marginTop: 5 }} disabled={props.disabled}>
        {t("Add Contingent Beneficiary")}
      </Button>
    </div>
  );
};

export default Contingent;
