import React from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Typography,
  Row,
  Col,
  Input,
  Select,
  InputNumber,
  DatePicker,
  Button,
  message,
  Modal,
  Table,
  Divider,
  Checkbox,
} from "antd";
import { exe } from "Lib/Dal";
import CptSelect from "./CptSelect";
import moment from "moment";
import Currency from "../Shared/Currency";
import { formatter } from "../Shared/Money";
import { withTranslation } from "react-i18next";
import DateRange from "../Shared/DateRange";

const { Column } = Table;
const { Title } = Typography;

class ProviderAgreements extends React.Component {
  state = {
    modalVisible: false,
  };
  componentDidMount() {
    if (this.props.providerCode) this.load(this.props.providerCode);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.providerCode != prevProps.providerCode) this.load(this.props.providerCode);
  }

  load = (providerCode) => {
    this.setState({ loading: true });
    exe("RepoProviderPrice", {
      operation: "GET",
      filter: "providerCode='" + providerCode + "'",
    }).then((r) => {
      this.setState({ loading: false, data: r.outData });
    });
  };
  handleModalVisible = () => {
    this.setState({ modalVisible: true, operation: "ADD" }, () => {
      this.props.form.resetFields();
    });
  };
  handleModalCancel = () => this.setState({ modalVisible: false });
  handleModalOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //data transform - ini
        values.providerCode = this.props.providerCode;
        values.start = values.validity[0].format("YYYY-MM-DD");
        values.end = values.validity[1]?values.validity[1].format("YYYY-MM-DD"):null;
        //data transform - end
        console.log("Received values of form: ", values);
        this.setState({ loading: true });
        exe("RepoProviderPrice", {
          entity: values,
          operation: this.state.operation,
        }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            this.setState({ modalVisible: false });
            this.load(this.props.providerCode); //reloading table
          } else {
            message.error(r.msg);
          }
        });
      } else {
        console.log(err, "ERROR VAL");
        message.error(this.props.t("Please fill all required fields"));
      }
    });
  };

  handleOpenRecord = (r) => {
    //data transform - ini
    r.validity = [];
    r.validity[0] = moment(r.start);
    r.validity[1] = r.end?moment(r.end):null;
    //data transform - end
    this.setState(
      { modalVisible: true, operation: "UPDATE" },

      this.props.form.setFieldsValue(r)
    );
  };
  handleRemoveRecord = (record) => {
    this.setState({ loading: true });
    exe("RepoProviderPrice", { entity: record, operation: "DELETE" }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.load(this.props.providerCode); //reloading table
      } else {
        message.error(r.msg);
      }
    });
  };

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const values=this.props.form.getFieldsValue();
    return (
      <div>
        <Modal title={t("Price Agreement")} visible={this.state.modalVisible} onOk={this.handleModalOk} onCancel={this.handleModalCancel}>
          <Form layout="vertical" onSubmit={this.handleSubmit}>
            {getFieldDecorator("id")}
            <Form.Item label={t("Procedure")}>
              {getFieldDecorator("procedureCode", {
                rules: [{ required: true }],
              })(<CptSelect />)}
            </Form.Item>
            <Form.Item label={t("Validity")}>
              {getFieldDecorator("validity", {
                rules: [{ required: true }],
              })(<DateRange  />)}
            </Form.Item>
            <Form.Item label={t("Currency")}>
              {getFieldDecorator("currency", {
                rules: [{ required: false }],
              })(<Currency />)}
            </Form.Item>
            <Form.Item label={t("Agreed Price")}>
              {getFieldDecorator("agreedPrice", {
                rules: [{ required: true }],
              })(<InputNumber style={{ width: "100%" }} />)}
            </Form.Item>
            <Form.Item label={t("Public Price")}>
              {getFieldDecorator("publicPrice", {
                rules: [{ required: false }],
              })(<InputNumber style={{ width: "100%" }} />)}
            </Form.Item>
            <Form.Item label={t("Notes")}>
              {getFieldDecorator("notes", {
                rules: [{ required: false }],
              })(<Input.TextArea autosize placeholder={t("Optional notes")} />)}
            </Form.Item>
          </Form>
        </Modal>

        <Title level={4}>{t("Explicit Agreement List")}</Title>

        <Button type="primary" icon={<PlusOutlined />} onClick={this.handleModalVisible} style={{ marginBottom: 5 }}>
          {t("Add")}
        </Button>

        <Table dataSource={this.state.data}>
          <Column title={t("Procedure Code")} dataIndex="procedureCode" />
          <Column title={t("Agreed Price")} dataIndex="agreedPrice" render={(v, r) => formatter(r.currency).format(v)} />
          <Column title={t("Public Price")} dataIndex="publicPrice" render={(v, r) => formatter(r.currency).format(v)} />
          <Column title={t("Start")} dataIndex="start" render={(v) => v.split("T")[0]} />
          <Column title={t("End")} dataIndex="end" render={(v) => v?v.split("T")[0]:"-"} />
          <Column title={t("Notes")} dataIndex="notes" />
          <Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <Button type="link" onClick={() => this.handleOpenRecord(record)}>
                  {t("Open")}
                </Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => this.handleRemoveRecord(record)}>
                  {t("Remove")}
                </Button>
              </span>
            )}
          />
        </Table>
      </div>
    );
  }
}
const WrappedForm = Form.create()(ProviderAgreements);
export default withTranslation()(WrappedForm);
