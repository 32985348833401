import React, {useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, notification, Typography, Result } from 'antd';
import {useTranslation} from "react-i18next";

const RegistrationForm = (props) => {
    const [loading,setLoading]=useState(false);
    const [success,setSuccess]=useState(false);
    const [t]=useTranslation();
    const { form } = props;
    const { getFieldDecorator } = form;

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields(async (err, values) => {
            if (!err) {
                try {
                    values.clave=values.password;
                    values.nombre=values.name;
                    setLoading(true);
                    const endpoint=window.location.hostname==="localhost"?"//localhost:5000":"//sisos-eu.azurewebsites.net";
                    const response = await fetch(endpoint+"/api/pub/createUser", {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(values),
                    });

                    if (!response.ok) {
                        throw new Error(await response.text());
                    }
                    const r= await response.json();
                    console.log(r);
                    if(r.ok){
                        // Success
                        setSuccess(true);
                        form.resetFields();
                        
                    }else{
                        // Error
                        notification.error({
                            message: 'Error',
                            description: r.msg,
                        });
                    }

                } catch (error) {
                    // Handle error...
                    notification.error({
                        message: 'Error',
                        description: 'An error occurred. Please try again.',
                    });
                } finally {
                    setLoading(false);
                }
            }
        });
    };

    return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {!success&&<div>
                
            <Typography.Title level={1} style={{textAlign:"center",marginTop:40}} >{t("Welcome")}</Typography.Title>
                <div  style={{ width: '400px', margin: '0 auto', boxShadow: '0 0 10px rgba(0,0,0,0.1)', padding: '20px', borderRadius: '5px'}}>
                    <Typography.Title level={3} >{t("Create your SelfService account")}</Typography.Title>
                    <Form onSubmit={handleSubmit} style={{flex:1}}>
                        <Form.Item label={t("Full Name")}>
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: t('Please input your name') }],
                            })(<Input placeholder={t("Full Name")} />)}
                        </Form.Item>
                        <Form.Item label={t("Email")}>
                            {getFieldDecorator('email', {
                                rules: [{ required: true, message: t('Please input your email') }],
                            })(<Input placeholder={t("Email")} />)}
                        </Form.Item>
                        <Form.Item label={t("Password")}>
                            {getFieldDecorator('password', {
                                rules: [{ required: true, message: t('Please input your password') }],
                            })(<Input.Password placeholder={t("Password")} />)}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                {t("Create account")}
                            </Button>
                        </Form.Item>
                    </Form>
        </div>
        </div>}
            {success&&<div>
                <Result
                    status="success"
                    title={t("User created successfully")}
                    subTitle={t("Please login with your credentials (email and password)")}
                    extra={[
                        <Button type="primary" key="login" onClick={ ()=>window.location=window.location.origin+"/login.html"}>
                            {t("Login")}
                        </Button>
                    ]}
                />
            </div>}
        </div>
            
    );
};

export default Form.create({ name: 'registration' })(RegistrationForm);
