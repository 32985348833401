import React, { useState, useEffect } from "react";
import { Parser } from "json2csv";
import {
    DownloadOutlined,
    DownOutlined,
    FileExcelOutlined,
    FileTextOutlined,
    FilterOutlined,
    SearchOutlined,
    TeamOutlined,
    UndoOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Button,
    Table,
    Menu,
    DatePicker,
    message,
    Tag,
    Drawer,
    InputNumber,
    Switch,
    Dropdown,
    Input,
    Tooltip,
} from "antd";
import { exe, safeGet } from "../../Lib/Dal";
import useUpdateEffect, { formatMoney, formatDateShort, getConfigProfile } from "../../Lib/Helpers";
import Currency from "../Shared/Currency";
import PolicySelect from "../Health/PolicySelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import GroupPolicySelect from "../Life/GroupPolicySelect";
import LobSelect from "../Shared/LobSelect";
import ProductSelect from "../Life/ProductSelect";
import { useTranslation } from "react-i18next";
import PaymentMethods from "../Life/PaymentMethods";
import ChainSelect from "../Bpm/ChainSelect";
import ComContractSelect from "../Commissions/ComContractSelect";
import SellerSelect from "../Commissions/SellerSelect";
import ColumnSelect from "../Shared/ColumnSelect";
import { Excel } from "antd-table-saveas-excel";
import PolicyTypeSelect from "../Life/PolicyTypeSelect";

const CollectionData = (props) => {
    const [t, i18n] = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filterVisible, setFilterVisible] = useState(false);
    const [pagination, setPagination] = useState({
        pageSize: 15,
        total: 0,
        current: 1,
        showTotal: (total) => `${t("Total")} ${total} ${t("items")}`
    });
    const [selectedChain, setSelectedChain] = useState();
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [reinsuranceContactRoles, setReinsuranceContactRoles] = useState([]);
    const [coinsuranceContactRoles, setCoinsuranceContactRoles] = useState([]);
    const [commissionsContactRoles, setCommissionsContactRoles] = useState([]);

    useEffect(() => {
        getConfigProfile().then((profile) => {
            setReinsuranceContactRoles(safeGet(["Reinsurance", "contactRoles"], window.global.configProfile, []));
            setCoinsuranceContactRoles(safeGet(["Coinsurance", "contactRoles"], window.global.configProfile, []));
            setCommissionsContactRoles(safeGet(["Commissions", "contactRoles"], window.global.configProfile, []));
        });
    }, []);

    useEffect(() => setCurrency(props.currency), [props.currency]);
    useUpdateEffect(() => onCompute(true), [pagination.current]);
    useUpdateEffect(() => onCompute(false), [props.refresh]);

    const setCurrency = (currency) => {
        props.form.setFieldsValue({ currency: currency });
        onCompute(false);
    };

    const onfilter = () => {
        onCompute(false);
    }
    const onCompute = (isPaging = false) => {
        const v = props.form.getFieldsValue();
        v.page = pagination.current;
        v.size = pagination.pageSize;
        if (v.policyId) v.lifePolicyId = v.policyId;
        if (v.productCode) v.productCode = v.productCode.key;
        setLoading(true);
        exe("ComputeCollection", v).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
                setPagination({ ...pagination, total: r.total });
                //isPaging argument not used since 11.1.578 Selected rows preservation is done in parent

            } else {
                message.error(r.msg);
            }
        });
    };
    const onPaginationChange = (currentPagination) => {
        setPagination(currentPagination);
    };
    const onExport = (data, fileName) => {
        function download(content, fileName, contentType) {
            var a = document.createElement("a");
            var file = new Blob([content], { type: contentType });
            a.href = URL.createObjectURL(file);
            a.download = fileName;
            a.click();
        }

        download(data, fileName, "text/json");
    };
    const onSelectOperation = (operation) => {
        setSelectedChain(operation);
        if (!operation) return;
        exe("ExeChain", { chain: operation, context: JSON.stringify({ input: data }) }).then((r) => {
            if (r.ok) {
                onExport(r.outData.outData, r.outData.fileName ? r.outData.fileName : operation + "_export_" + +new Date());
            } else message.error(r.msg);
        });
    };
    const exportCsv = () => {
        getDataWithoutPagination().then((r) => {
            if (r.ok) {
                const exportData = r.outData.map((p) => ({ ...p.Installment, ...p, Installment: undefined })); //flattening
                const fields = Object.keys(exportData[0]);
                const parser = new Parser({ fields });
                const csv = parser.parse(exportData);
                onExport(csv, "collection.csv");
            } else message.error(r.msg);
        });
    };
    const exportXlsx = () => {
        getDataWithoutPagination().then((r) => {
            if (r.ok) {
                const exportData = r.outData.map((p) => ({ ...p.Installment, ...p, Installment: undefined })); //flattening
                const fields = Object.keys(exportData[0]);
                const excel = new Excel();
                excel
                    .addSheet("Sheet1")
                    .addColumns(fields.map((p) => ({ title: p, dataIndex: p }))) //antd column format
                    .addDataSource(exportData)
                    .saveAs("collection.xlsx");
            } else message.error(r.msg);
        });
    };
    const getDataWithoutPagination = () =>
        new Promise((resolve, reject) => {
            var v = props.form.getFieldsValue();
            v.size = 0;
            if (v.policyId) v.lifePolicyId = v.policyId;
            if (v.productCode) v.productCode = v.productCode.key;
            setLoading(true);
            exe("ComputeCollection", v).then((r) => {
                setLoading(false);
                resolve(r);
            });
        });

    const field = props.form.getFieldDecorator;
    const values = props.form.getFieldsValue();
    return (
        <div>
            <ChainSelect filter="category='EXPORTER'" value={selectedChain} onChange={onSelectOperation}
                disabled={data.length == 0} />
            <Dropdown
                overlay={
                    <Menu>
                        <Menu.Item>
                            <Button type="link" icon={<FileTextOutlined />} onClick={exportCsv}>
                                {t("Csv")}
                            </Button>
                        </Menu.Item>
                        <Menu.Item>
                            <Button type="link" icon={<FileExcelOutlined />} onClick={exportXlsx}>
                                {t("Excel")}
                            </Button>
                        </Menu.Item>
                    </Menu>
                }>
                <Button type="link" icon={<DownloadOutlined />} disabled={data.length == 0}>
                    {t("Download")}
                    <DownOutlined />
                </Button>
            </Dropdown>

            {props.setGroupMode && (
                <Button type="link" icon={<TeamOutlined />} onClick={() => props.setGroupMode(true)}>
                    {t("Group Mode")}
                </Button>
            )}

            {!props.parentFilter && (
                <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
                    {t("Filter")}
                </Button>
            )}
            <ColumnSelect
                style={{ float: "right" }}
                columns={[t("Policy Code"), t("Scheme"), t("Seller"), t("Fiscal Document")]}
                onChange={(p) => setSelectedColumns(p)}
                value={selectedColumns}
            />

            <Table
                onChange={(pag) => onPaginationChange(pag)}
                pagination={pagination}
                dataSource={data.map((p) => ({ ...p.Installment, ...p }))}
                loading={loading}
                style={{ marginTop: 5 }}
                rowSelection={props.rowSelection}
                key={props.key} //used to reset internal selection state
                rowKey="id">
                <Table.Column title={t("Item ID")} dataIndex="id" />
                <Table.Column title={t("Policy")} dataIndex="lifePolicyId"
                    render={(v) => <a href={"#/lifePolicy/" + v}>{v}</a>} />
                <Table.Column title={t("Policyholder")} dataIndex="policyHolderName" render={(v) => <Tooltip title={v}>
                    <div style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "120px" }}>{v}</div></Tooltip>} />
                {selectedColumns.includes(t("Fiscal Document")) &&
                    <Table.Column title={t("Fiscal Document")} dataIndex="fiscalNumber" />}
                {selectedColumns.includes(t("Policy Code")) &&
                    <Table.Column title={t("Policy Code")} dataIndex="policyCode" />}
                {selectedColumns.includes(t("Scheme")) &&
                    <Table.Column title={t("Scheme")} dataIndex="comContractName" />}
                {selectedColumns.includes(t("Seller")) && <Table.Column title={t("Seller")} dataIndex="sellerName" />}
                <Table.Column title={t("Concept")} dataIndex="concept" />
                <Table.Column
                    title={t("Due")}
                    dataIndex="dueDate"
                    render={(v, r) => (r.dueDays > 0 && r.pending ?
                        <Tag color="red">{formatDateShort(v)}</Tag> : formatDateShort(v))}
                />
                <Table.Column title={t("Currency")} dataIndex="currency" render={(v) => <Currency.Flag currency={v} />} />
                <Table.Column title={t("Expected")} dataIndex="minimum" key="expected"
                    render={(v, r) => formatMoney(v, r.currency)} />
                <Table.Column title={t("Minimum")} dataIndex="minimum" render={(v, r) => formatMoney(v, r.currency)} />
                <Table.Column
                    title={t("Paid")}
                    dataIndex="payed"
                    render={(v, r) => (r.pending ? formatMoney(v, r.currency) :
                        <Tag color="green">{formatMoney(v, r.currency)}</Tag>)}
                />
                <Table.Column title={t("Transfer")} dataIndex="transferId" />
                <Table.Column title={t("Covered Until")} dataIndex="coveredUntil" render={(v) => formatDateShort(v)} />
                <Table.Column title={t("Days Due")} dataIndex="dueDays" />
                <Table.Column title={t("# in Year")} dataIndex="numberInYear" />
                <Table.Column title={t("Contract Year")} dataIndex="contractYear" />
            </Table>
            <Drawer
                title={t("Filter")}
                visible={props.parentFilter ? props.filterVisible : filterVisible}
                width={512}
                onClose={() => (props.parentFilter ? props.setFilterVisible(false) : setFilterVisible(false))}>
                <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                    <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
                        {t("Reset")}
                    </Button>
                </div>
                <Form layout="vertical">
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Currency")} style={{ flex: 1, marginRight: 5 }}>{field("currency")(
                            <Currency />)}</Form.Item>
                        <Form.Item label={t("Payment Method")} style={{ flex: 1 }}>{field("paymentMethod")(
                            <PaymentMethods module="INCOME" />)}</Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("LoB")} style={{ marginRight: 5, flex: 1 }}>{field("lob")(
                            <LobSelect />)}</Form.Item>
                        <Form.Item label={t("Product")} style={{ flex: 1 }}>{field("productCode")(
                            <ProductSelect />)}</Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Due Month")} style={{ flex: 1, marginRight: 5 }}>{field("dueMonth")(<DatePicker.MonthPicker style={{ width: "100%" }} />)}</Form.Item>
                        <Form.Item label={t("Creation Month")} style={{ flex: 1 }}>{field("creationMonth")(<DatePicker.MonthPicker style={{ width: "100%" }} />)}</Form.Item>

                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Paid")} style={{ marginRight: 10 }}>
                            {field("payed", { valuePropName: "checked" })(<Switch />)}
                        </Form.Item>
                        <Form.Item label={t("Active")}>{field("active", { valuePropName: "checked", initialValue: true })(
                            <Switch />)}</Form.Item>
                    </div>

                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Policy ID")}>{field("lifePolicyId")(<InputNumber />)}</Form.Item>
                        <Form.Item label={t("Min Due Days")} style={{ marginLeft: 10 }}>
                            {field("dueDaysMin")(<InputNumber />)}
                        </Form.Item>
                        <Form.Item label={t("Max Due Days")} style={{ marginLeft: 10 }}>
                            {field("dueDaysMax")(<InputNumber />)}
                        </Form.Item>
                    </div>

                    <Form.Item label={t("Policy")}>{field("policyId")(<PolicySelect proposals />)}</Form.Item>
                    <Form.Item label={t("Policyholder")}>{field("contactId")(<SimpleContactSelect />)}</Form.Item>

                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Policy Type")} style={{ marginRight: 5, flex: 1 }}>{field("policyType")(
                            <PolicyTypeSelect allowClear />)}</Form.Item>
                        <Form.Item label={t("Policyholder Document")} style={{ flex: 1 }}>{field("passport")(
                            <Input />)}</Form.Item>
                    </div>
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Group Policy")} style={{ flex: 1, marginRight: 5 }}>{field("groupPolicyId")(
                            <GroupPolicySelect />)}</Form.Item>
                        <Form.Item label={t("Fiscal Document")} style={{ flex: 1 }}>{field("fiscalNumber")(
                            <Input />)}</Form.Item>
                    </div>

                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Commission Scheme")} style={{ width: "100%", marginRight: 5 }}>
                            {field("comContractId")(<ComContractSelect active />)}
                        </Form.Item>
                        <Form.Item label={t("Contract Seller")} style={{ width: "100%" }}>
                            {field("sellerId", { normalize: (v) => (v ? v : undefined) })(
                                <SellerSelect comContractId={values.comContractId} disabled={!values.comContractId} />
                            )}
                        </Form.Item>
                    </div>
                    <Form.Item label={t("Payer")}>{field("payerId")(<SimpleContactSelect />)}</Form.Item>
                    <Form.Item label={t("Seller")}>{field("specificSellerId")(<SimpleContactSelect filter={
                        commissionsContactRoles.length === 0 ? undefined
                            : `id in (SELECT contactId from ContactRole WHERE role IN (${commissionsContactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                    } />)}</Form.Item>
                    <div style={{ display: "flex" }}>
                        <Form.Item label={t("Reinsurer")} style={{ flex: 1, marginRight: 5 }}>{field("reinsurerId")(
                            <SimpleContactSelect showArrow filter={
                                reinsuranceContactRoles.length === 0 ? undefined
                                    : `id in (SELECT contactId from ContactRole WHERE role IN (${reinsuranceContactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                            } />)}</Form.Item>
                        <Form.Item label={t("Coinsurer")} style={{ flex: 1 }}>{field("coinsurerId")(<SimpleContactSelect
                            filter={
                                coinsuranceContactRoles.length === 0 ? undefined
                                    : `id in (SELECT contactId from ContactRole WHERE role IN (${coinsuranceContactRoles.map((p) => "'" + p + "'").join(",")})) AND `
                            } />)}</Form.Item>

                    </div>
                </Form>
                <Button type="primary" icon={<SearchOutlined />} onClick={onfilter}>
                    {t("Search")}
                </Button>
                {pagination.total !== null && (
                    <div style={{ marginTop: 15 }}>
                        <Tag>{pagination.total}</Tag> {t("Results")}
                    </div>
                )}
            </Drawer>
        </div>
    );
};

export default Form.create()(CollectionData);
