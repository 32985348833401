import React, {useEffect} from 'react';
import $ from "jquery";
import "jquery-ui-bundle";
import "jquery-ui-bundle/jquery-ui.css";
import "inputmask/dist/jquery.inputmask.min"
import {message} from "antd";
import { exe, downloadFile } from "../Lib/Dal";
import {useTranslation} from "react-i18next";
import '../scoped-bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
//require("bootstrap-4-grid/css/grid.min.css");

const FormRender2 = ({formId,valores,processId,onFormOk,next}) => {
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(formId) loadForm(formId); else emptyForm();
    },[formId]);
    
    useEffect(()=>{
        if(valores){
            fbRender = document.getElementById("fb-render");
            formInstance = $(fbRender).formRender({
                container: fbRender,
                formData: valores.json,
            });
        }
    },[valores])
    
    useEffect(()=>{
        if(next) validateAndGoToNextStep();
    },[next]);
    
    const validateAndGoToNextStep=()=>{
        if (formId) {
            if(window.formInstance1){
                try {
                    fbRender = window.formInstance1.instanceContainers[0]; //updating if there has been html changes
                    formInstance=window.formInstance1;
                }catch (e){
                    console.error("Error while parsing formInstance1. Value not updated");
                    console.error(e);
                }
            }
            if (fbRender && fbRender.reportValidity()) {
                onFormOk(formInstance.userData);
            }else{
                onFormOk(false);
            }
        } else {
            onFormOk({}); //no form
        }
    }
    const emptyForm=()=>{
        //has no form
        fbRender = document.getElementById("fb-render");
        formInstance = $(fbRender).formRender({
            container: fbRender,
            formData: '[{"type":"header","subtype":"h3","label":"' + t("No form configured for this step. Click next to advance.") + '"}]',
        });
    }
    let fbRender;
    let formInstance;
    const loadForm=(formId)=>{
        exe("GetForms", { filter: "id=" + formId }).then((v) => {
            fbRender = document.getElementById("fb-render");
            formInstance = $(fbRender).formRender({
                container: fbRender,
                formData: v.outData[0].json,
            });
            window.formInstance1=formInstance; //temp
            if (v.outData[0].logic) {
                try {
                    console.log("Executing custom logic:", v.outData[0].logic, { processId: processId });

                    evalInContext(v.outData[0].logic, {
                        exe: exe,
                        loadCatalog: loadCatalog,
                        processId: processId,
                        downloadFile: downloadFile,
                        message,
                    });
                    console.log("Success");
                } catch (error) {
                    console.warn(error);
                }
            }
        });
    }
    const evalInContext=(js, context)=> {
        return function () {
            return eval(js);
        }.call(context);
    }
    const loadCatalog = (catalog, element, filter, valueField = "id", displayField = "name") => {
        exe(catalog, { operation: "GET", filter: filter }).then((r) => {
            r.outData.map((r) => {
                $(element).append($("<option></option>").attr("value", r[valueField]).text(r[displayField]));
            });
        });
    };
    return (
        <form id="fb-render"></form>
    );
};

export default FormRender2;