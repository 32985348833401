import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '../../Lib/Helpers';

const PremiumDetail = ({ visible, onClose, premiumData }) => {
    const [t]=useTranslation();
    const [data,setData]=useState([]);

    useEffect(()=>{
        if(premiumData) setData(JSON.parse(premiumData));
    },[premiumData]);

  const columns = [
    {
      title: t('Component'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Value'),
      dataIndex: 'value',
      key: 'value',
      render:(v,r)=>formatMoney(v,r.currency)
    },
    {
      title: t('Code'),
      dataIndex: 'code',
      key: 'code',
    },

  ];

  return (
    <Modal
      title={t("Premium Detail")}
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <Table columns={columns} dataSource={data} pagination={false} size="small" />
    </Modal>
  );
};

export default PremiumDetail;
