import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import ReDataPart from './ReDataPart';
import ReDataLossPart from './ReDataLossPart';
import { exe } from '../../Lib/Dal';
import { message } from 'antd';
const LiquidationDetail = (props) => {
    const [loading, setLoading] = useState(false);
    const [premiums, setPremiums] = useState([]);
    const [losses, setLosses] = useState([]);
    useEffect(() => {
        if(props.liquidationRecord) load();
    }, [props.liquidationRecord]);

    const load = () => {
        setLoading(true);
        exe("GetLiquidationDetail",{liquidationId:props.liquidationRecord.id,participantId:props.liquidationRecord.participantId}).then((r) => {
            setLoading(false);
            if (r.ok) {
                setPremiums(r.outData.premiums);
                setLosses(r.outData.losses);
            } else {
                message.error(r.msg);
            }});
        }
    return <div>
        <Tabs>
            <Tabs.TabPane tab="Premiums" key="premiums">
                <ReDataPart value={premiums} loading={loading} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Claims" key="claims" >
                <ReDataLossPart value={losses} loading={loading} />
            </Tabs.TabPane>
        </Tabs>
    </div>
}
export default LiquidationDetail;