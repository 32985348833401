import React from "react";
import Title from "antd/lib/typography/Title";
import { Descriptions, Table } from "antd";
import { formatDate } from "../../Lib/Helpers";
import Coverages from "../Life/Coverages";
import Exclusions from "../Life/Exclusions";
import MultiContactSelect from "../Contact/MultiContactSelect";
import ContactLink from "../Contact/ContactLink";
import PolicyHeader from "../Life/PolicyHeader";
import BillingStatus from "../Life/BillingStatus";
import { useTranslation } from "react-i18next";

const ClaimPolicyLife = (props) => {
  const [t, i18n] = useTranslation();
  const policy = props.policy;
  return (
    <div>
      <Title level={4}>{t("Policy Summary")}</Title>
      <Descriptions column={3} bordered size="small">
        <Descriptions.Item label={t("Holder")}>
          <ContactLink contact={policy&&policy.Holder} />
        </Descriptions.Item>
        <Descriptions.Item label={t("Product")}>{policy.productCode}</Descriptions.Item>
        <Descriptions.Item label={t("Start")}>{formatDate(policy.start)}</Descriptions.Item>

        <Descriptions.Item label={t("Code")}>
          <a href={"#/lifePolicy/" + policy.id} style={{ whiteSpace: "nowrap" }}>
            {policy.code}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={t("Insured Since")}>{formatDate(policy.activeDate)}</Descriptions.Item>
        <Descriptions.Item label={t("End")}>{formatDate(policy.end)}</Descriptions.Item>

        <Descriptions.Item label={t("Status")}>{policy.entityState}</Descriptions.Item>
        <Descriptions.Item label={t("Billing Status")}>
          <BillingStatus code={policy.billingStatus} />
        </Descriptions.Item>
      </Descriptions>
      <Title style={{ marginTop: 5 }} level={4}>
        {t("Coverages")}
      </Title>
      <Coverages coverages={policy.Coverages} currency={policy.currency} readOnly config={props.config} claimId={props.claim.id} />

      <Title style={{ marginTop: 5 }} level={4}>
        {t("Exclusions")}
      </Title>
      <Exclusions value={policy.Exclusions} readOnly />
      <Title style={{ marginTop: 5 }} level={4}>
        {t("Beneficiaries")}
      </Title>
      <MultiContactSelect disabled showPercentage value={policy.Beneficiaries} />
    </div>
  );
};

export default ClaimPolicyLife;
