import React, { useState, useEffect } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { useTranslation } from "react-i18next";
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, Col, DatePicker, message, Row, Table, Tabs, Tag } from "antd";
import AccountDetail from "./AccountDetail";
import { exe, safeGetRaw } from "../../Lib/Dal";
import AccountSelectDetail from "./AccountSelectDetail";
import moment from "moment";
import { formatDateShortUtc, formatMoney, formatNumber, getConfigProfile } from "../../Lib/Helpers";
import { formatter } from "../Shared/Money";
import TransactionLink from "./TransactionLink";

const AccountingMovements = (props) => {
    const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([{ Lines: [], balance: 0, xBalance: 0 }]);
    const [activeTabKey, setActiveTabKey] = useState("selection");
    const [yCurrency, setYCurrency] = useState(false);
    const [zCurrency, setZCurrency] = useState(false);
    const [yCurrencyName, setYCurrencyName] = useState(false);
    const [zCurrencyName, setZCurrencyName] = useState(false);

    useEffect(() => {
        //checking if account code is passed in the url as a parameter named code
        const urlObj = new URL(window.location.href);
        const hash = urlObj.hash;

        // Extract the query string after the hash
        const queryString = hash.substring(hash.indexOf('?') + 1);
        const searchParams = new URLSearchParams(queryString);
        const code = searchParams.get('code');

        if (code) {
            props.form.setFieldsValue({ accountCode: code });
            onSubmit();
        }

        //checking if there are reporting currencies defined in the config profile
        getConfigProfile().then(profile => {
            const yCurrency = safeGetRaw(["Accounting", "yCurrency"], profile, false);
            const zCurrency = safeGetRaw(["Accounting", "zCurrency"], profile, false);
            setYCurrency(yCurrency);
            setZCurrency(zCurrency);
            setYCurrencyName(safeGetRaw(["Accounting", "yCurrencyName"], profile, yCurrency));
            setZCurrencyName(safeGetRaw(["Accounting", "zCurrencyName"], profile, zCurrency));
        });
    }, []);


    const onSubmit = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            setLoading(true);
            exe("GetAccountingMovements", { from: values.dateRange[0], to: values.dateRange[1], accountCode: values.accountCode, groupByAux: values.groupByAux, groupByCurrency: values.groupByCurrency }).then(r => {
                setLoading(false);
                if (r.ok) {
                    setData(r.outData);
                    setActiveTabKey("movements");
                } else message.error(r.msg);
            })
        })
    }
    const field = props.form.getFieldDecorator;
    const values = props.form.getFieldsValue();
    return (
        <div>
            <DefaultPage
                title={t("Account Movements")}
                icon="diff">
                <Tabs activeKey={activeTabKey} onChange={t => setActiveTabKey(t)}>
                    <Tabs.TabPane tab={t("Account Selection")} key="selection">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form>
                                    <Form.Item label={t("Date Range")}>
                                        {field("dateRange", { initialValue: [moment().startOf("month"), moment()], rules: [{ required: true }] })(<DatePicker.RangePicker />)}
                                    </Form.Item>
                                    <Form.Item label={t("Account")}>
                                        {field("accountCode", { rules: [{ required: true }] })(<AccountSelectDetail />)}
                                    </Form.Item>
                                    <Form.Item label={t("Options")}>
                                        <div>
                                            {field("groupByAux", { valuePropName: "checked" })(<Checkbox>{t("Group by auxiliary")}</Checkbox>)}
                                            {field("groupByCurrency", { valuePropName: "checked" })(<Checkbox disabled={!values.groupByAux}>{t("Group by currency")}</Checkbox>)}
                                        </div>
                                    </Form.Item>
                                    <Button type="primary" icon={<LegacyIcon type={"enter"} />} onClick={onSubmit} loading={loading} >{t("Submit")}</Button>
                                </Form>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t("Movements")} key="movements">

                        {/*STANDARD TABLE*/}
                        {!values.groupByAux && <Table dataSource={data[0] ? data[0].Lines : []} scroll={{ x: 1500 }}

                            // expandable={{
                            //     expandedRowRender: s => <Table dataSource={data[0] ? data[0].Lines : []} pagination={false} >
                            //         <Table.Column title={t("yCurrency")} key={"yCurrency"} render={v => "EUR"} />
                            //         <Table.Column title={t("yDebit")} dataIndex="yDebit" width={100} />
                            //         <Table.Column title={t("yCredit")} dataIndex="yCredit" width={100} />
                            //         <Table.Column title={t("zCurrency")} key={"<Currency"} render={v => "USD"} />
                            //         <Table.Column title={t("zDebit")} dataIndex="zDebit" width={100} />
                            //         <Table.Column title={t("zCredit")} dataIndex="zCredit" width={100} />
                            //     </Table>
                            // }}

                            loading={loading} footer={(d) => (<div>
                                <div>
                                    <b>{t("Starting Balance")}: </b>{data[0] ? formatMoney(data[0].startBalance, props.defaultCurrency) : undefined}
                                </div>
                                <div>
                                    <b>{t("End Balance")}: </b>{data[0] ? formatMoney(data[0].balance, props.defaultCurrency) : undefined}
                                </div>
                                <div>
                                    <b>{t("Foreign Currency Starting Balance")}: </b>{data[0] ? formatMoney(data[0].xStartBalance, data[0].xCurrency) : undefined}
                                </div>
                                <div>
                                    <b>{t("Foreign Currency End Balance")}: </b>{data[0] ? formatMoney(data[0].xBalance, data[0].xCurrency) : undefined}
                                </div>
                            </div>
                            )}>
                            <Table.Column title={t("Transaction")} dataIndex="transactionId" render={v => <TransactionLink value={v} />} />
                            <Table.Column title={t("Line")} dataIndex="order" />
                            <Table.Column title={t("Date")} dataIndex="date" render={v => formatDateShortUtc(v)} />
                            <Table.Column title={t("Concept")} dataIndex="concept" />

                            <Table.Column title={t("Start Balance")} dataIndex="startBalance" render={(v, r) => formatMoney(v, props.defaultCurrency)} />
                            <Table.Column title={t("Debit")} dataIndex="debit" render={(v, r) => formatMoney(v, props.defaultCurrency)} />
                            <Table.Column title={t("Credit")} dataIndex="credit" render={(v, r) => formatMoney(v, props.defaultCurrency)} />
                            <Table.Column title={t("Balance")} dataIndex="balance" render={(v, r) => formatMoney(v, props.defaultCurrency)} />

                            <Table.Column title={t("xStart Balance")} dataIndex="xStartBalance" render={(v, r) => v ? formatMoney(v, r.xCurrency) : 0} />
                            <Table.Column title={t("xDebit")} dataIndex="xDebit" render={(v, r) => v ? formatMoney(v, r.xCurrency) : 0} />
                            <Table.Column title={t("xCredit")} dataIndex="xCredit" render={(v, r) => v ? formatMoney(v, r.xCurrency) : 0} />
                            <Table.Column title={t("xBalance")} dataIndex="xBalance" render={(v, r) => v ? formatMoney(v, r.xCurrency) : 0} />

                            <Table.Column title={t("Currency")} dataIndex="xCurrency" />
                            <Table.Column title={t("Exchange Rate")} dataIndex="xChangeRate" />
                            <Table.Column title={t("Acc")} dataIndex="account" render={v => <Tag><span style={{ whiteSpace: "nowrap" }}>{v}</span></Tag>} />
                            <Table.Column title={t("Aux")} dataIndex="auxId" />

                            {yCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{yCurrencyName + " " + t("Debit")}</span>} dataIndex="yDebit" render={(v, r) => formatMoney(v, yCurrency)} />}
                            {yCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{yCurrencyName + " " + t("Credit")}</span>} dataIndex="yCredit" render={(v, r) => formatMoney(v, yCurrency)} />}
                            {zCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{zCurrencyName + " " + t("Debit")}</span>} dataIndex="zDebit" render={(v, r) => formatMoney(v, zCurrency)} />}
                            {zCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{zCurrencyName + " " + t("Credit")}</span>} dataIndex="zCredit" render={(v, r) => formatMoney(v, zCurrency)} />}
                        </Table>}

                        {/*TABLE GROUPED BY AUX*/}
                        {values.groupByAux &&
                            <Table dataSource={data} loading={loading} rowKey={r => r.auxId + r.xCurrency} pagination={false} scroll={{ x: 1500 }} footer={(d) => (<div>
                                <div>
                                    <b>{t("Starting Balance")}: </b>{formatMoney(data.reduce((p, c) => p + c.startBalance, 0), props.defaultCurrency)}
                                </div>
                                <div>
                                    <b>{t("End Balance")}: </b>{formatMoney(data.reduce((p, c) => p + c.balance, 0), props.defaultCurrency)}
                                </div>
                                <div>
                                    <b>{t("Foreign Currency Starting Balance")}: </b>{data[0] ? formatMoney(data.reduce((p, c) => p + c.xStartBalance, 0), data[0].xCurrency) : undefined}
                                </div>
                                <div>
                                    <b>{t("Foreign Currency End Balance")}: </b>{data[0] ? formatMoney(data.reduce((p, c) => p + c.xBalance, 0), data[0].xCurrency) : undefined}
                                </div>
                            </div>
                            )}
                                expandedRowRender={(r, index) => <Table dataSource={r.Lines} pagination={false} rowKey={"lineId"} >
                                    <Table.Column title={t("Transaction")} dataIndex="transactionId" render={v => <TransactionLink value={v} />} />
                                    <Table.Column title={t("Line")} dataIndex="order" />
                                    <Table.Column title={t("Date")} dataIndex="date" render={v => formatDateShortUtc(v)} />
                                    <Table.Column title={t("Concept")} dataIndex="concept" />

                                    <Table.Column title={t("Start Balance")} dataIndex="startBalance" render={(v, r) => formatMoney(v, props.defaultCurrency)} />
                                    <Table.Column title={t("Debit")} dataIndex="debit" render={(v, r) => formatMoney(v, props.defaultCurrency)} />
                                    <Table.Column title={t("Credit")} dataIndex="credit" render={(v, r) => formatMoney(v, props.defaultCurrency)} />
                                    <Table.Column title={t("Balance")} dataIndex="balance" render={(v, r) => formatMoney(v, props.defaultCurrency)} />

                                    <Table.Column title={t("xStart Balance")} dataIndex="xStartBalance" render={(v, r) => v ? formatMoney(v, r.xCurrency) : 0} />
                                    <Table.Column title={t("xDebit")} dataIndex="xDebit" render={(v, r) => v ? formatMoney(v, r.xCurrency) : 0} />
                                    <Table.Column title={t("xCredit")} dataIndex="xCredit" render={(v, r) => v ? formatMoney(v, r.xCurrency) : 0} />
                                    <Table.Column title={t("xBalance")} dataIndex="xBalance" render={(v, r) => v ? formatMoney(v, r.xCurrency) : 0} />

                                    <Table.Column title={t("Currency")} dataIndex="xCurrency" />
                                    <Table.Column title={t("Exchange Rate")} dataIndex="xChangeRate" />

                                    {yCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{yCurrencyName + " " + t("Debit")}</span>} dataIndex="yDebit" render={(v, r) => formatMoney(v, yCurrency)} />}
                                    {yCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{yCurrencyName + " " + t("Credit")}</span>} dataIndex="yCredit" render={(v, r) => formatMoney(v, yCurrency)} />}
                                    {zCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{zCurrencyName + " " + t("Debit")}</span>} dataIndex="zDebit" render={(v, r) => formatMoney(v, zCurrency)} />}
                                    {zCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{zCurrencyName + " " + t("Credit")}</span>} dataIndex="zCredit" render={(v, r) => formatMoney(v, zCurrency)} />}
                                    <Table.Column title={t("Acc")} dataIndex="account" render={v => <Tag><span style={{ whiteSpace: "nowrap" }}>{v}</span></Tag>} />
                                </Table>}>
                                <Table.Column title={t("Aux")} dataIndex="auxName" />
                                {values.groupByCurrency && <Table.Column title={t("Currency")} dataIndex="xCurrency" render={v => v || props.defaultCurrency} />}
                                <Table.Column title={t("Start Balance")} dataIndex="startBalance" render={(v, r) => formatMoney(v, props.defaultCurrency)} />
                                <Table.Column title={t("Debit")} dataIndex="auxTotalDebit" render={(v, r) => formatMoney(v, props.defaultCurrency)} />
                                <Table.Column title={t("Credit")} dataIndex="auxTotalCredit" render={(v, r) => formatMoney(v, props.defaultCurrency)} />
                                <Table.Column title={t("End Balance")} dataIndex="balance" render={(v, r) => formatMoney(v, props.defaultCurrency)} />

                                <Table.Column title={t("xStart Balance")} dataIndex="xStartBalance" render={v => formatNumber(v)} />
                                <Table.Column title={t("xDebit")} dataIndex="xAuxTotalDebit" render={v => formatNumber(v)} />
                                <Table.Column title={t("xCredit")} dataIndex="xAuxTotalCredit" render={v => formatNumber(v)} />
                                <Table.Column title={t("xEnd Balance")} dataIndex="xBalance" render={v => formatNumber(v)} />

                                {yCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{yCurrencyName + " " + t("Debit")}</span>} dataIndex="yDebit" render={(v, r) => formatMoney(v, yCurrency)} />}
                                {yCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{yCurrencyName + " " + t("Credit")}</span>} dataIndex="yCredit" render={(v, r) => formatMoney(v, yCurrency)} />}
                                {zCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{zCurrencyName + " " + t("Debit")}</span>} dataIndex="zDebit" render={(v, r) => formatMoney(v, zCurrency)} />}
                                {zCurrency && <Table.Column title={<span style={{whiteSpace:"nowrap"}}>{zCurrencyName + " " + t("Credit")}</span>} dataIndex="zCredit" render={(v, r) => formatMoney(v, zCurrency)} />}

                                <Table.Column title={t("Aux ID")} dataIndex="auxId" />
                                <Table.Column title={t("Aux Type")} dataIndex="auxType" />
                            </Table>}
                    </Tabs.TabPane>
                </Tabs>
            </DefaultPage>
        </div>
    );
};

export default Form.create()(AccountingMovements);