import React, { Component } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Drawer,
    Input,
    Table,
    message,
    Button,
    Select,
    Radio,
    Row,
    Col,
    InputNumber,
} from "antd";
import { exe } from "Lib/Dal";
import {useTranslation, withTranslation} from "react-i18next";



const CoContractListFilter=props=> {
    const [t]=useTranslation();

    const submit=()=> {
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
                let queries = [];
                if (values.name) queries.push(`name like N'%${values.name}%'`);
                if (values.inward !== undefined) queries.push(`inward='${values.inward}'`);
                if (values.code) queries.push(`code like N'%${values.code}%'`);
                if (values.id) queries.push(`id=${values.id}`);

                const filterQuery = queries.join(" AND ");

                exe("RepoCoContract", {operation:"GET", include: [ "Contact"], filter: filterQuery, }).then((r) => {
                    if(r.ok){
                        props.onSearch(r.outData);
                    }else{
                        message.error(r.msg);
                    }
                });
            }
        });
    }
    const onReset=()=> {
        props.form.resetFields();
        submit()
    }
    const { getFieldDecorator } = props.form;
        return (
            <Drawer title={t("Contract Filter Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
                <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                    <Button icon={<UndoOutlined />} size="small" onClick={onReset}>
                        {t("Reset")}
                    </Button>
                </div>

                <Form layout="vertical">
                    <Form.Item label={t("Name")}>{getFieldDecorator("name")(<Input />)}</Form.Item>
                    <Form.Item label={t("Code")}>{getFieldDecorator("code")(<Input />)}</Form.Item>
                    <Form.Item label={t("ID")}>{getFieldDecorator("id")(<InputNumber />)}</Form.Item>
                    <Form.Item label={t("Direction")}>
                        {getFieldDecorator("inward")(
                            <Radio.Group name="radiogroup" type="">
                                <Radio.Button value={true}>{t("Inward")}</Radio.Button>
                                <Radio.Button value={false}>{t("Outward")}</Radio.Button>
                            </Radio.Group>
                        )}
                    </Form.Item>
                   
                </Form>
                <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()}>
                    {t("Search")}
                </Button>
            </Drawer>
        );
}
export default  Form.create()(CoContractListFilter);
