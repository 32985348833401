import { Button, Table,Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
const RecordImportListModal = (props) => {
    const { t } = useTranslation();
    const onSelect=record=>{
        props.onOk(record);
        props.onCancel();
    }
    return <Modal title={t("Import Operations")} visible={props.visible} onCancel={props.onCancel} footer={null}>
        <Table
            dataSource={props.data}
            rowKey="id"
            size="small">
            <Table.Column title={t("Name")} dataIndex="name" key="name" 
            defaultSortOrder={"ascend"}
            sorter={(a, b) => a.name.localeCompare(b.name)}  />
            <Table.Column title={t("Category")} dataIndex="category" 
            onFilter={(value, record) => value?(record.category?record.category.indexOf(value) === 0:false):!record.category}
            filters={[...new Set(props.data.map(d=>d.category))].map(c=>({text:c,value:c}))}
            sorter={(a, b) => a.category?a.category.localeCompare(b.category?b.category:''):''} 
              />
            <Table.Column title={t("Actions")} dataIndex="id" render={(v,r)=><div><Button type='link' onClick={()=>onSelect(r)}>{t("Select")}</Button> </div>}  />
        
        </Table>
    </Modal>;
}
export default RecordImportListModal;