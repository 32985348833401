import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const BeneficiaryTypeSelect = (props) => {
  const [t] = useTranslation();
  const disabledBeneficiaryTypes = props.disabledBeneficiaryTypes || [];
  return (
    <Select style={{ width: "100%" }} placeholder={t("Please select...")} value={props.value} onChange={props.onChange}>
      <Select.Option value="BEN" disabled={disabledBeneficiaryTypes.some((p) => p == "BEN")}>
        {t("Policy Beneficiary")}
      </Select.Option>
      <Select.Option value="PRO" disabled={disabledBeneficiaryTypes.some((p) => p == "PRO")}>
        {t("Provider")}
      </Select.Option>
      <Select.Option value="OTH" disabled={disabledBeneficiaryTypes.some((p) => p == "OTH")}>
        {t("Other")}
      </Select.Option>
    </Select>
  );
};

export default BeneficiaryTypeSelect;
