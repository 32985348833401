import {
  DeleteOutlined,
  EyeOutlined,
  FileOutlined,
  FilterOutlined,
  RedoOutlined,
  ReloadOutlined,
  ScissorOutlined,
  StopOutlined,
} from '@ant-design/icons';

import { Button, Input, message, Modal, Table, Tabs, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import BatchList from "../Life/BatchList";
import { exe } from "../../Lib/Dal";
import moment from "moment";
import Workers from "./Workers";
import Inspector from "react-inspector";
import MessageFilter from "./MessageFilter";
import { useTranslation } from "react-i18next";
import TrimMessages from "./TrimMessages";
import WorkerActivity from "./WorkerActivity";

const Dashboard = () => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newMessageVisible, setNewMessageVisible] = useState(false);
  const [busMessage, setBusMessage] = useState({});
  const [filterVisible, setFilterVisible] = useState(false);
  const [reload,doReload]=useState(0);
  const [trimMessagesVisible,setTrimMessagesVisible]=useState(false);

  const load = () => {
   doReload(c=>c+1);
  };
  const showResult = (result, msg) => Modal[result == "ERROR" ? "error" : "success"]({ title: t("Command Result"), content: msg, maskClosable: true });
  const sendMesage = (msg) => {
    exe("PutMessage", msg).then((r) => {
      if (r.ok) {
        load();
        setNewMessageVisible(false);
      } else message.error(r.msg);
    });
  };
  const retry = (result, msg) => {
    setBusMessage({ batch: msg.batch, value: msg.dto });
    setNewMessageVisible(true);
  };
  const stop = (msg) => {
    setLoading(true);
    exe("StopMessage", { messageId: msg.messageId, batch: msg.batch }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const cancel = (messageId) => {
    setLoading(true);
    exe("DelMessage", { messageId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(data.filter((p) => p.messageId !== messageId));
      } else {
        message.error(r.msg);
      }
    });
  };
  const onOpenDto = (record) => {
    let parsedDto;
    try {
      parsedDto = JSON.parse(record.dto);
    } catch (error) {
      parsedDto = record.dto; //cant parse, showing text
    }
    Modal.info({
      title: record.messageId,
      maskClosable: true,
      width: "60%",
      zIndex: 2000,
      content: (
        <div>
          <Inspector data={parsedDto} expandLevel={1} />
        </div>
      ),
      onOk() {},
    });
  };
  const onFilteredResults = (data, total) => {
    setData(data);
  };
  const onMessagesTrimmed=()=>{
    load();
    setTrimMessagesVisible(false);
  }
  return (
    <div>
      <DefaultPage title={t("Event Bus")} icon="deployment-unit">
        <Tabs>
          <Tabs.TabPane tab={t("Messages")} key="events">
            <Button type="link" icon={<FileOutlined />} onClick={() => setNewMessageVisible(true)}>
              {t("New")}
            </Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={() => load()}>
              {t("Reload")}
            </Button>
            <Button type="link" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
              {t("Filter")}
            </Button>
            <Button type="link" icon={<ScissorOutlined />} onClick={()=>setTrimMessagesVisible(true)}>
              {t("Trim Messages")}
            </Button>
            <TrimMessages visible={trimMessagesVisible} onCancel={()=>setTrimMessagesVisible(false)} onOk={onMessagesTrimmed} />

            <Table loading={loading} dataSource={data} rowKey="messageId">
              <Table.Column title={t("Id")} dataIndex="messageId" key="id" />
              <Table.Column
                title={t("Date")}
                dataIndex="messageId"
                render={(v) =>
                  moment(new Date(+v.split("-")[0]))
                    .local()
                    .format("MMMM Do YYYY, h:mm:ss a")
                }
              />
              <Table.Column title={t("Batch")} dataIndex="batch" />
              <Table.Column
                dataIndex="dto"
                title={t("Message")}
                render={(v, r) => (
                  <Button type="link" onClick={() => onOpenDto(r)}>
                    {t("Open")}
                  </Button>
                )}
              />
              <Table.Column
                title={t("Status")}
                dataIndex="status"
                render={(v) => <Tag color={v == "FINISHED" ? "#87d068" : v == "PENDING" ? "orange" : v == "ERROR" ? "red" : "blue"}>{v}</Tag>}
              />
              <Table.Column title={t("Worker")} dataIndex="consumer" render={(v) => <Tag>{v}</Tag>} />
              <Table.Column title={t("Actions")}
                render={(v, r) => (
                  <div>
                    {r.status === "ERROR" || r.status === "FINISHED" ? (
                      <Tooltip title={t("Result")}>
                        <Button type="link" icon={<EyeOutlined />} onClick={() => showResult(r.status, r.msg)} />
                      </Tooltip>
                    ) : null}
                    {r.status === "ERROR" ? (
                      <Tooltip title={t("Retry")}>
                        <Button style={{ marginleft: 3 }} type="link" icon={<RedoOutlined />} onClick={() => retry(r.status, r)} />
                      </Tooltip>
                    ) : null}
                    {r.status === "PENDING" ? (
                      <Tooltip title={t("Stop Job")}>
                        <Button style={{ marginleft: 3 }} type="link" icon={<StopOutlined />} onClick={() => stop(r)}></Button>
                      </Tooltip>
                    ) : null}
                    {r.status === "WAITING" ? (
                      <Tooltip title={t("Remove message from queue")}>
                        <Button style={{ marginleft: 3 }} type="link" icon={<DeleteOutlined />} onClick={() => cancel(r.messageId)}></Button>
                      </Tooltip>
                    ) : null}
                  </div>
                )}></Table.Column>
            </Table>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Batches")} key="batches">
            <BatchList />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Workers")} key="workers">
            <Workers />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Activity")} key="activity">
            <WorkerActivity />
          </Tabs.TabPane>
        </Tabs>
        <Modal
          title={t("Manual Event Bus Message")}
          visible={newMessageVisible}
          onOk={() => sendMesage(busMessage)}
          onCancel={() => setNewMessageVisible(false)}>
          <Input placeholder={t("Batch Name")} onChange={(v) => setBusMessage({ ...busMessage, batch: v.target.value })} value={busMessage.batch} />
          <Input.TextArea
            placeholder={t("Message")}
            style={{ marginTop: 5 }}
            onChange={(v) => setBusMessage({ ...busMessage, value: v.target.value })}
            value={busMessage.value}
          />
        </Modal>
        <MessageFilter visible={filterVisible} onClose={() => setFilterVisible(false)} onResults={(data, total) => onFilteredResults(data, total)} reload={reload} />
      </DefaultPage>
    </div>
  );
};

export default Dashboard;
