import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import myBackend from "./i18nBackend";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(myBackend)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: localStorage.getItem("language") || "en",
    fallbackLng: "en",
    debug: true,
    keySeparator: false, // we use content as keys
    saveMissing: true,
    saveMissingTo: "all",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // resources: {
    //   en: { translation: { Policies: "Policies", "New Policy": "New Policy" } },
    //   es: { translation: { Policies: "Pólizas", "New Policy": "Nueva Póliza", Code: "Código" } },
    // },
  });

export default i18n;
