import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMoney } from '../../Lib/Helpers';
import { exe } from '../../Lib/Dal';
import { Descriptions, message } from 'antd';

const PendingPremiums = (props) => {
    const [t] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({pending:0,late:0,currency:""});

    useEffect(() => {
        getPendingPremiums();
    }, [props.doReload]);
    
    const getPendingPremiums = () => {
        setLoading(true);
        exe("GetPendingPremiums", { policyId:props.policyId}).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
                console.log(r.outDataº)
            }
            else {
                message.error(r.msg);
            }
        });
    }
    return (
        <Descriptions column={2}>
            <Descriptions.Item label={t("Pending Premiums")}>{formatMoney(data.pending,data.currency)}</Descriptions.Item>
            <Descriptions.Item label={t("Late Premiums")}><span style={{color:data.late>0?"red":"unset"}}>{formatMoney(data.late,data.currency)}</span></Descriptions.Item>
        </Descriptions>

    );
}
export default PendingPremiums;