import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  DatePicker,
  Tag,
  Switch,
  Checkbox,
  InputNumber,
  message,
} from "antd";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";
const MessageFilter = (props) => {
  const [matches, setMatches] = useState(null);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    submit();
  }, [props.reload]);
  

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      if(!values.maxMessageId) values.maxMessageId="+";
      console.log(values, "FORM VALUES");

      exe("GetMessages", values).then((r) => {
        if (r.ok) {
          props.onResults(r.outData, r.outData.length);
          setMatches(r.outData.length);
        } else {
          message.error(r.msg);
        }
      });
    });
  };

  return (
    <div>
      <Drawer title={t("Event Bus Filter Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Form.Item label={t("Message ID")}>{getFieldDecorator("messageId")(<Input allowClear />)}</Form.Item>
          <Form.Item label={t("Batch")}>{getFieldDecorator("batch")(<Input allowClear />)}</Form.Item>
          <Form.Item label={t("Status")}>
            {getFieldDecorator("status")(
              <Select allowClear placeholder={t("Current status")}>
                <Select.Option value="FINISHED">{t("Finished")}</Select.Option>
                <Select.Option value="ERROR">{t("Error")}</Select.Option>
                <Select.Option value="PENDING">{t("Pending")}</Select.Option>
                <Select.Option value="WAITING">{t("Waiting")}</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={t("Record Limit")}>{getFieldDecorator("count",{initialValue:20,rules:[{required:true}]})(<InputNumber />)}</Form.Item>
          <Form.Item label={t("Max Message ID")}>{getFieldDecorator("maxMessageId")(<Input allowClear />)}</Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> Results
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(MessageFilter);
