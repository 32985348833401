import React, {useEffect} from 'react';
import { DeleteOutlined, LeftOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, InputNumber, Popconfirm, Row } from "antd";
import {useTranslation} from "react-i18next";

const CustomPolicyGroupsForm = (props) => {
    const [t]=useTranslation();
    
    useEffect(()=>{
        if(props.selected) props.form.setFieldsValue(props.selected);
    },[props.selected])
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            props.onSave(values);
        })
    }
    const onDelete=()=>{
        props.onDelete(props.selected);
    }
    
    const field=props.form.getFieldDecorator;
    return (
        <div>
            <Button type="link" icon={<LeftOutlined />} onClick={props.onBack}>{t("Back")}</Button> 
            <Button type="link" icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
            <Button type="link" icon={<DeleteOutlined />} onClick={onDelete}>{t("Delete")}</Button>
            <Row gutter={16}>
                <Col span={12}>
                    {field("operation")}
                    <Form.Item label={t("Code")}>{field("code",{rules:[{required:true}]})(<Input />)}</Form.Item>
                    <Form.Item label={t("Annual Limit")}>{field("aLimit",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>
                    <Form.Item label={t("Event Limit")}>{field("limit",{rules:[{required:false}]})(<InputNumber />)}</Form.Item>
                    
                </Col>
            </Row>
        </div>
    );
};

export default Form.create()(CustomPolicyGroupsForm);