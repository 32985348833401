import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DollarOutlined } from '@ant-design/icons';
import { message, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDate, formatMoney } from "../../Lib/Helpers";

const ContactPayments = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.contactId) load(props.contactId);
  }, [props.contactId]);

  const load = (contactId) => {
    setLoading(true);
    exe("RepoClaimPayment", { operation: "GET", filter: "contactId=" + contactId }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };
  const getStatus = (r) => {
    switch (r.entityState) {
      case "CANCELLED":
        return <Tag color="red">{t("Cancelled")}</Tag>;
      default:
        return <Tag>{r.entityState}</Tag>;
    }
  };

  const getOrigin = (r) => {
    let url = "";
    let origin = "";
    let originId = 0;
    let icon = "";
    if (r.claimId) {
      origin = t("Claim");
      originId = r.claimId;
      icon = "warning";
      url = "#/healthclaim/" + originId;
    } else if (r.lifePolicyId) {
      origin = t("Policy");
      originId = r.lifePolicyId;
      icon = "safety";
      url = "#/lifepolicy/" + originId;
    } else if (r.liquidationId && r.producer == "COM") {
      origin = t("Commission liquidation");
      originId = r.liquidationId;
      icon = "shopping";
      url = "#/liquidations?id=" + originId;
    } else if (r.liquidationId && r.producer == "TAX") {
      origin = t("Tax liquidation");
      originId = r.liquidationId;
      icon = "transaction";
      url = "#/taxscheme/1?id=" + originId;
    }

    return (
      <Tooltip title={origin}>
        <LegacyIcon type={icon} style={{ marginRight: 3 }} />
        <a href={url}>{originId}</a>
      </Tooltip>
    );
  };

  return (
    <div>
      <Table dataSource={data} loading={loading} style={{ marginTop: 5 }} scroll={{ x: true }} size="middle" rowKey="id">
        <Table.Column title={t("Id")} dataIndex="id" />
        <Table.Column title={t("Date")} dataIndex="date" render={(v) => formatDate(v)} />
        <Table.Column title={t("Origin")} key="origin" render={(v, r) => getOrigin(r)} />
        <Table.Column title={t("Ref")} dataIndex="reference" />
        <Table.Column title={t("Destination Acc")} dataIndex="accountId" render={(v, r) => <a href={"#/account/" + v}>{v}</a>} />
        <Table.Column title={t("Amount")} dataIndex="total" render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column title={t("Status")} dataIndex="entityState" render={(v, r) => getStatus(r)} />
        <Table.Column
          title={t("Exchange")}
          key="exchange"
          render={(v, r) =>
            r.entityState == "EXECUTED" ? (
              <a href={"#/account/" + r.accountId + "?&tab=operations"}>
                <DollarOutlined />
              </a>
            ) : null
          }
        />
      </Table>
    </div>
  );
};

export default ContactPayments;
