import React, { Component } from "react";

import {
  DeleteOutlined,
  ExperimentOutlined,
  FileAddOutlined,
  FileOutlined,
  HistoryOutlined,
  ReloadOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
  Row,
  Col,
  Select,
  Button,
  Input,
  message,
  notification,
  Typography,
  Switch,
  Modal,
  Checkbox,
} from "antd";
import yaml from "js-yaml";
import DefaultPage from "../Shared/DefaultPage";
import { exe } from "../../Lib/Dal";
import FormulaSimulator from "./FormulaSimulator";
import LobSelect from "../Shared/LobSelect";
import ProductConfigurator from "./ProductConfigurator";
import ProductSelect from "../Life/ProductSelect";
import ProductHistory from "./ProductHistory";
import Mousetrap from "mousetrap";
import SubLobSelect from "../Shared/SubLobSelect";
import { withTranslation } from "react-i18next";
import ProductChannelSelect from "./ProductChannelSelect";
import CodeEditorW from "../Shared/CodeEditorW";

const { Paragraph, Title } = Typography;
const { Option } = Select;
const ButtonGroup = Button.Group;

class Product extends Component {
  state = {
    loading: false,
    product: {},
    simulatorVisible: false,
    advancedMode: false,
    historyVisible: false,
  };
  componentDidMount() {
    if (this.props.match.params.productCode && this.props.match.params.productCode !== "0") {
      this.load(this.props.match.params.productCode);
    } else {
      this.setState({ operation: "ADD" });
    }
    Mousetrap.bind("ctrl+1", () => {
      this.save();
    });
  }
  load(productCode) {
    this.setState({ loading: true });
    exe("RepoProduct", {
      operation: "GET",
      filter: "code='" + productCode + "'",
    }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        this.setValues(r.outData[0]);
        try {
          //inserting in localsotrage history. Limiting to 10 items
          let arr=JSON.parse(localStorage.getItem("productHistory"));
          if(!arr) arr=[];
          arr=arr.filter(item=>item.key!=productCode);
          arr.unshift({key:productCode,label:r.outData[0].name});
          arr=arr.slice(0,10);
          localStorage.setItem("productHistory",JSON.stringify(arr));
        } catch (error) {
          console.error(error);
        }
        
      } else {
        message.error(r.msg);
      }
    });
  }
  setValues(product) {
    //extra fields
    if (product.config) window.global.myCodeMirror.setValue(product.config);
    this.props.form.setFieldsValue(product);
    this.setState({ product: product, operation: "UPDATE" });
  }
  delete() {
    exe("RepoProduct", { operation: "DELETE", entity: this.state.product }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);

        window.location.hash = "#/products";
      } else {
        message.error(this.props.t("Product in use by a policy. Please remove de policy first"));
      }
    });
  }
  save() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //extra fields
        values.config = window.global.myCodeMirror.getValue();
        //yaml validation
        try {
          const result = yaml.safeLoad(values.config);
          values.configJson = JSON.stringify(result);
        } catch (error) {
          notification["error"]({
            message: error.reason,
            description: error.message,
          });
          return;
        }
        //validation OK
        this.setState({ loading: true });
        exe("RepoProduct", { operation: this.state.operation, entity: values }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            window.location.hash = "#/products/" + r.outData[0].code;
            //this.load(r.outData[0].code);
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
      }
    });
  }
  openSimulator() {
    this.setState({ simulatorVisible: true });
  }
  closeSimulator() {
    this.setState({ simulatorVisible: false });
  }

  onSelectProduct(product) {
    this.setValues(product);
    this.setState({ historyVisible: false });
  }
  openHistory() {
    this.setState({ historyVisible: true });
  }
  //read file
  onImport() {
    this.openFile(function (txt) {
      if (txt) window.global.myCodeMirror.setValue(txt);
    });
  }
  openFile(callBack) {
    var element = document.createElement("input");
    element.setAttribute("type", "file");
    element.setAttribute("id", "btnOpenFile");
    var me = this;
    element.onchange = function () {
      me.readText(this, callBack);
      document.body.removeChild(this);
    };

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
  }
  readText(filePath, callBack) {
    var reader;
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      reader = new FileReader();
    } else {
      alert(this.props.t("The File APIs are not fully supported by your browser. Fallback required."));
      return false;
    }
    var output = ""; //placeholder for text output
    if (filePath.files && filePath.files[0]) {
      reader.onload = function (e) {
        output = e.target.result;
        callBack(output);
      }; //end onload()
      reader.readAsText(filePath.files[0]);
    } //end if html5 filelist support
    else {
      //this is where you could fallback to Java Applet, Flash or similar
      return false;
    }
    return true;
  }
  //end read file
  onNew() {
    window.location.hash = "#/products/0";
    this.setState({ operation: "ADD" });
    this.props.form.resetFields();
  }
  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const lobCodeSelected = this.props.form.getFieldValue("lobCode");
    const values=this.props.form.getFieldsValue();
    return (
      <DefaultPage
        title={this.state.operation == "ADD" ? t("New Product") : this.state.product.name}
        subTitle={this.state.product.code}
        icon="dropbox"
        loading={this.state.loading}
        onBack={() => (window.location = "#/products")}
        routes={{
          routes: [
            { path: "/", breadcrumbName: t("Home") },
            { path: "/products", breadcrumbName: t("Products") },
            { path: "", breadcrumbName: t("Product") },
          ],
        }}
        extra={
          <ButtonGroup>
            <Button icon={<FileOutlined />} onClick={() => this.onNew()}>
              {t("New")}
            </Button>
            <Button icon={<HistoryOutlined />} onClick={() => this.openHistory()}>
              {t("History")}
            </Button>
            <Button icon={<ReloadOutlined />} onClick={() => this.load(this.props.match.params.productCode)}>
              {t("Reload")}
            </Button>
            <Button icon={<DeleteOutlined />} type="danger" onClick={() => this.delete()}>
              {t("Delete")}
            </Button>
            <Button icon={<FileAddOutlined />} onClick={() => this.onImport()}>
              {t("Import")}
            </Button>
            {/* 
            <Button>Export</Button> */}
            <Button icon={<ExperimentOutlined />} onClick={() => this.openSimulator()}>
              {t("Simulator")}
            </Button>
            <Button icon={<SaveOutlined />} type="primary" onClick={() => this.save()}>
              {t("Save")}
            </Button>
          </ButtonGroup>
        }>
        <Title level={4}>{t("Basic Info")}</Title>
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={4}>
              <Form.Item label={t("Code")}>{getFieldDecorator("code", {})(<Input disabled={this.state.operation != "ADD"} />)}</Form.Item>
              {getFieldDecorator("selfService")}
              <Form.Item label={t("Channels")}><ProductChannelSelect value={[values.selfService]} onChange={v=>this.props.form.setFieldsValue(v)} /></Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t("Name")}>{getFieldDecorator("name", {})(<Input />)}</Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={t("LoB")}>{getFieldDecorator("lobCode", {})(<LobSelect />)}</Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={t("Sub LoB")}>{getFieldDecorator("subLobCode", {})(<SubLobSelect lobCode={lobCodeSelected} />)}</Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label={t("Parent")}>{getFieldDecorator("parentCode", {})(<ProductSelect onlyValue all />)}</Form.Item>
            </Col>
            {/* <Col span={6}>
              <Form.Item label="Advanced">
                <Switch onChange={(checked) => this.setState({ advancedMode: checked })} />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
        <Title level={4}>{t("Behaviour")}</Title>
        <CodeEditorW saveHotKey={() => this.save()} />
        <FormulaSimulator visible={this.state.simulatorVisible} onCancel={() => this.closeSimulator()} />
        <Modal
          width={700}
          title={t("Product History")}
          visible={this.state.historyVisible}
          onCancel={() => this.setState({ historyVisible: false })}
          onOk={() => this.setState({ historyVisible: false })}>
          <ProductHistory
            productCode={this.props.match.params.productCode}
            onSelect={(p) => this.onSelectProduct(p)}
            current={window.global.myCodeMirror ? window.global.myCodeMirror.getValue() : null}
          />
        </Modal>
        {/* {this.state.advancedMode && (
          <div>
            <Title level={4}>Configuration</Title>
            <ProductConfigurator />
          </div>
        )} */}
      </DefaultPage>
    );
  }
}

const WrappedProduct = Form.create()(Product);
export default withTranslation()(WrappedProduct);
