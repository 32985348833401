import React, { Component } from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Button,
  Modal,
  Table,
  Tooltip,
  DatePicker,
  Tabs,
  Row,
  Col,
  Input,
  message,
  Radio,
  InputNumber,
  Divider,
  Upload,
  Switch,
  Tag,
  Typography,
  Checkbox,
  Alert,
  Timeline,
} from "antd";
import CptSelect from "./CptSelect";
import { exe } from "Lib/Dal";
import { withTranslation } from "react-i18next";
const { Text, Title } = Typography;
const { Column, ColumnGroup } = Table;

//props string diagnosisCode,
class ProcedureRestrictions extends Component {
  state = {
    modalVisible: false,
  };
  componentDidMount() {
    if (this.props.diagnosisCode) this.load(this.props.diagnosisCode);
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.diagnosisCode != prevProps.diagnosisCode) this.load(this.props.diagnosisCode);
  }

  load = (diagnosisCode) => {
    this.setState({ loading: true });
    exe("RepoDiagProcedure", { operation: "GET", filter: `diagnosisCode='${diagnosisCode}'`, include: ["Cpt"] }).then((r) => {
      this.setState({ loading: false, data: r.outData });
    });
  };
  handleModalVisible = () => {
    this.setState({ modalVisible: true, operation: "ADD" }, () => {
      this.props.form.resetFields();
      this.props.form.setFieldsValue({ diagnosisCode: this.props.diagnosisCode });
    });
  };
  handleModalCancel = () => this.setState({ modalVisible: false });
  handleModalOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.setState({ loading: true });
        exe("RepoDiagProcedure", { entity: values, operation: this.state.operation }).then((r) => {
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            this.setState({ modalVisible: false });
            this.load(this.props.diagnosisCode); //reloading table
          } else {
            message.error(r.msg);
          }
        });
      } else {
        console.log(err, "ERROR VAL");
        message.error(this.props.t("Please fill all required fields"));
      }
    });
  };
  handleOpenRecord = (r) => {
    this.setState({ modalVisible: true, operation: "UPDATE" }, this.props.form.setFieldsValue(r));
  };
  handleRemoveRecord = (record) => {
    this.setState({ loading: true });
    exe("RepoDiagProcedure", { entity: record, operation: "DELETE" }).then((r) => {
      this.setState({ loading: false });
      if (r.ok) {
        message.success(r.msg);
        this.load(this.props.diagnosisCode); //reloading table
      } else {
        message.error(r.msg);
      }
    });
  };

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const addDisabled = this.props.disabled;
    return (
      <div>
        <Modal title={t("Procedure")} visible={this.state.modalVisible} onOk={this.handleModalOk} onCancel={this.handleModalCancel}>
          <Form layout="vertical">
            <Form.Item label={t("Diagnosis Code ICD9")}>{getFieldDecorator("diagnosisCode")(<Input disabled />)}</Form.Item>
            <Form.Item label={t("CPT Code")}>{getFieldDecorator("cptCode")(<CptSelect />)}</Form.Item>
            <Form.Item label={t("Excluded")}>{getFieldDecorator("excluded")(<Switch />)}</Form.Item>
            <Form.Item label={t("Rule")}>
              {getFieldDecorator("rule")(<Input.TextArea placeholder="Autosize height based on content lines" autosize />)}
            </Form.Item>
            <Form.Item label={t("Audit Notes")}>
              {getFieldDecorator("auditNotes")(<Input.TextArea placeholder="Autosize height based on content lines" autosize />)}
            </Form.Item>
            <Form.Item label={t("Relationship ID")}>{getFieldDecorator("id")(<Input disabled />)}</Form.Item>
          </Form>
        </Modal>
        <Title level={4}>{t("Procedure Restrictions")}</Title>
        <Tooltip title={addDisabled ? t("Save diagnosis first") : t("Add Restriction")}>
          <Button type="primary" disabled={addDisabled} icon={<PlusOutlined />} onClick={this.handleModalVisible} style={{ marginBottom: 5 }}>
            {t("Add")}
          </Button>
        </Tooltip>
        <Table dataSource={this.state.data}>
          <Column title={t("CPT Code")} dataIndex="cptCode" />
          <Column title={t("Procedure")} dataIndex={["Cpt","description"]} />
          <Column title={t("Excluded")} dataIndex="excluded" render={(v) => (v ? "EXC" : "INC")} />
          <Column title={t("Audit Notes")} dataIndex="auditNotes" />
          <Column title={t("Rule")} dataIndex="rule" />
          <Column
            title={t("Action")}
            key="action"
            render={(text, record) => (
              <span>
                <Button type="link" onClick={() => this.handleOpenRecord(record)}>
                  {t("Open")}
                </Button>
                <Divider type="vertical" />
                <Button type="link" onClick={() => this.handleRemoveRecord(record)}>
                  {t("Remove")}
                </Button>
              </span>
            )}
          />
        </Table>
      </div>
    );
  }
}

const Wrapped = Form.create()(ProcedureRestrictions);
export default withTranslation()(Wrapped);
