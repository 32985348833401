import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const CostCenterSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("RepoCostCenterCatalog", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div >
      <Select style={{width:"100%"}}
          showArrow
          placeholder={t("Please select cost center")}
          value={props.value}
          onChange={props.onChange} 
              onSelect={props.onSelect&&props.onSelect}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code} record={p}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default CostCenterSelect;
