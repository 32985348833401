import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal } from "antd";
import {useTranslation} from "react-i18next";
import Currency from "../Shared/Currency";

const ContactAccountNew = ({visible,onOk,onCancel,form}) => {
    const [t]=useTranslation();
    const field=form.getFieldDecorator;
    
    const onOkInternal=()=>{
        form.validateFields((err,values)=>{
            if(err) return;
            onOk&&onOk(values);
        })
    }
    return (
        <Modal visible={visible} onOk={onOkInternal} onCancel={onCancel}>
            <Form.Item label={t("Currency")}>{field("currency",{rules:[{required:true}]})(<Currency style={{width:"100%"}}  />)}</Form.Item>
        </Modal>
    );
};

export default Form.create()(ContactAccountNew);