import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Popconfirm, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const Emails = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [emailValidationError, setEmailValidationError] = useState(null);
  useEffect(() => load(), []);

  const load = () => {
    exe("RepoCommunicationsCatalog", { operation: "GET", filter: "field='EMAILTYPE'" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onChangeType = (v, index) => {
    emails[index] = { ...emails[index], type: v };
    props.onChange(emails);
  };
  const onChangeEmail = (v, index) => {
    emails[index] = { ...emails[index], email: v.target.value, err: validateEmail(v.target.value) };
    props.onChange(emails);
  };
  const validateEmail = (email) => {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(email)) {
      setEmailValidationError({});
      return false;
    } else {
      setEmailValidationError({ validateStatus: "error", hasFeedback: true, help: "Invalid eMail address" });
      return true;
    }
  };
  const onAddEmail = () => {
    props.onChange([...emails, { num: "", type: undefined }]);
  };
  const onRemoveEmail = (r, index) => {
    if (r.id) {
      exe("RepoContactEmail", { operation: "DELETE", entity: { id: r.id } }).then((r) => {
        if (r.ok) {
          message.success(r.msg);
          emails.splice(index, 1);
          props.onChange([...emails]);
        } else {
          message.error(r.msg);
        }
      });
    } else {
      emails.splice(index, 1);
      props.onChange([...emails]);
    }
  };
  const emails = props.value || [];

  return (
    <div>
      {emails.map((p, index) => (
        <div style={{ display: "flex", marginTop: 3 }} key={"email" + index}>
          <Form.Item {...emailValidationError} style={{ flex: 1 }}>
            <Input placeholder={t("Email address")} value={p.email} onChange={(v) => onChangeEmail(v, index)} />
          </Form.Item>
          <Select
            style={{ width: "100%", marginLeft: 3, flex: 1 }}
            placeholder={t("Please select email type")}
            onChange={(v) => onChangeType(v, index)}
            value={p.type}>
            {data.map((p) => (
              <Select.Option value={p.code} key={p.code}>
                {t(p.name)}
              </Select.Option>
            ))}
          </Select>
          <Popconfirm title={t("Are you sure you want to delete this email?")} onConfirm={() => onRemoveEmail(p, index)}>
            <MinusCircleOutlined
              style={{ marginLeft: 5, cursor: "pointer", fontSize: 24, color: "#999", alignSelf: "center" }} />
          </Popconfirm>
        </div>
      ))}
      <Button type="link" icon={<PlusCircleOutlined />} onClick={() => onAddEmail()} style={{ paddingLeft: 2 }}>
        {t("Add Email")}
      </Button>
    </div>
  );
};

export default Emails;
