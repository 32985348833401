import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const TransferReversalCauseSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);
  
  useEffect(() => {
    if(props.value&&props.onSelect) props.onSelect(data.find(p=>p.code===props.value)); //triggering on select if there is a value
    }, [props.value]);

  const load = () => {
    setLoading(true);
    exe("RepoTransferReversalCause", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        if(props.value&&props.onSelect) props.onSelect(r.outData.find(p=>p.code===props.value)); //triggering on select if there is a value
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        placeholder={t("Please select reversal cause")}
        style={props.style}
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        onSelect={(v,opt)=>props.onSelect&&props.onSelect(opt.props.record)}
        allowClear>
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code} record={p}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default TransferReversalCauseSelect;
