import React, { useState, useEffect } from "react";
import { DeleteOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Table, message, Badge, Divider, Popconfirm, Input } from "antd";
import {formatDate, formatDateShort, getColor} from "../../Lib/Helpers";
import { exe } from "../../Lib/Dal";
import { useTranslation } from "react-i18next";
import ComLiquidationListPart from "./ComLiquidationListPart";
import Status from "../Shared/Status";
import DownloadOptions from "../Shared/DownloadOptions";

const ComLiquidationList = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t, i18n] = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    load();
  }, [props.reload]);

  useEffect(() => {
    if (props.data) {
      //external search (ie filter) showing results
      setData(props.data);
    }
  }, [props.data]);

  const load = () => {
    if (window.location.hash.includes("?id=")) return; //ignoring. specific result obtained from external filter
    setLoading(true);
    const filter = props.comContractId ? "comContractId=" + props.comContractId : "";
    exe("RepoComLiquidation", { operation: "GET", include: ["ParticipantLiquidation","Process"], filter: filter }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExecute = (id) => {
    setLoading(true);
    exe("ExecuteLiquidation", { comLiquidationId: id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        const updatedData = data.map((x) => (x.id === r.outData.id ? r.outData : x));
        setData(updatedData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onRevert = (id) => {
    setLoading(true);
    exe("RevertLiquidation", { comLiquidationId: id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        const updatedData = data.map((x) => (x.id === r.outData.id ? r.outData : x));
        setData(updatedData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onDelete = (id) => {
    setLoading(true);
    exe("RepoComLiquidation", { operation: "DELETE", entity: { id: id } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const getBadge = (status) => {
    switch (status) {
      case 0:
        return <Badge status="processing" />;
      case 1:
        return <Badge status="success" />;
      case 2:
        return <Badge status="warning" />;
      case 3:
        return <Badge status="error" />;
      default:
        return null;
    }
  };
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`${t("Search")} ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
          {t("Search")}
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          {t("Reset")}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => (record[dataIndex] == undefined ? "" : record[dataIndex]).toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end
  return (
    <div>
      <Button icon={<ReloadOutlined />} type="link" onClick={load}>
        {t("Reload")}
      </Button>
      <DownloadOptions data={data.map(p=>({
        [t("Id")]:p.id,
        [t("Contract")]:p.comContractId,
        [t("Period")]:p.yearMonth,
        [t("Name")]:p.liquidationName,
        [t("Currency")]:p.currency,
        [t("Commissions")]:p.commissions,
        [t("Created")]:formatDate(p.created),
        [t("Status")]:p.status,
        [t("Workflow")]:p.Process&&p.Process.estado
      
      }))} filename={"liquidations"} />
      <Table
        dataSource={data}
        loading={loading}
        style={{ marginTop: 5 }}
        scroll={{ x: true }}
        size="middle"
        rowKey="id"
        expandedRowRender={(r) => <ComLiquidationListPart value={r.ParticipantLiquidation} />}>
        <Table.Column title={t("Id")} dataIndex="id" {...getColumnSearchProps("id")} />
        <Table.Column
          title={t("Contract")}
          dataIndex="comContractId"
          render={(v, r) => <a href={"#/comContract/" + v}>{v}</a>}
          {...getColumnSearchProps("comContractId")}
        />
        <Table.Column title={t("Period")} dataIndex="yearMonth" {...getColumnSearchProps("yearMonth")} />
        <Table.Column title={t("Name")} dataIndex="liquidationName" {...getColumnSearchProps("liquidationName")} />
        <Table.Column title={t("Currency")} dataIndex="currency" {...getColumnSearchProps("currency")} />
        <Table.Column title={t("Commissions")} dataIndex="commissions" {...getColumnSearchProps("commissions")} />
        <Table.Column title={t("Created")} dataIndex="created" render={(v) => formatDate(v)} {...getColumnSearchProps("created")} />
        <Table.Column title={t("Status")} dataIndex="status" render={(v) => getBadge(v)} {...getColumnSearchProps("status")} />
        <Table.Column
            dataIndex="Process"
            title={t("Workflow")}
            render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load()} />}
        />
        
        <Table.Column
          title={t("Actions")}
          dataIndex="status"
          key="actions"
          render={(v, r) => (
            <div>
              <Button type="link" onClick={() => onExecute(r.id)} disabled={r.status !== 0}>
                {t("Request")}
              </Button>
              <Divider type="vertical" />
              <Button type="link" onClick={() => onRevert(r.id)} disabled={r.status !== 1}>
                {t("Cancel")}
              </Button>
              <Divider type="vertical" />
              <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={() => onDelete(r.id)} disabled={r.status !== 0}>
                <Button type="link" icon={<DeleteOutlined />} disabled={r.status !== 0}>
                  {t("Delete")}
                </Button>
              </Popconfirm>
            </div>
          )}
        />
      </Table>
    </div>
  );
};

export default ComLiquidationList;
