import React from "react";
import { Button, Popconfirm, Space, Table, Tag, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { SendOutlined, PlusOutlined, ReloadOutlined, EditOutlined, UndoOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDate, formatDateShort, formatMoney } from "../../Lib/Helpers";
import PortfolioTransferForm from "./PortfolioTransferForm";
import DefaultPage from "../Shared/DefaultPage";

const PortfolioTransfers = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [selected,setSelected] = useState(null);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        load();
    },[])

    const load=()=>{
        setLoading(true);
        exe("RepoPortfolioTransfer",{operation:"GET"}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }
    const onExecute=(id)=>{
        setLoading(true);
        exe("ExePortfolioTransfer",{portfolioTransferId:id}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                load();
            }else{
                message.error(r.msg);
            }
        })
    }
    const onRevert=(id)=>{
        setLoading(true);
        exe("ExePortfolioTransferReversal",{portfolioTransferId:id}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                load();
            }else{
                message.error(r.msg);
            }
        })
    }
    const renderStatus = (statusCode,record) => {
        switch (statusCode) {
          case "PENDING":
            return <Tag color="blue">{t("Pending")}</Tag>;
          case "EXECUTED":
            return <Tag color="green">{t("Executed")}</Tag>;
          case "SCHEDULED":
            return (
              <Tag color="orange">
                <Tooltip title={formatDate(record.scheduled)}>
                    <ClockCircleOutlined style={{ marginRight: 5 }} />
                    {t("Scheduled")}
                </Tooltip>
              </Tag>
            );
          default:
            return statusCode;
        }
      };

    return <DefaultPage title={t("Portfolio Transfers")} icon="pullRequest" routes={{
        routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Portfolio Transfers"), path: "portfolioTransfers" },
        ]}}
        extra={<Space>
            </Space>}>
            {!selected&&<div>
            <Button type="link" icon={<PlusOutlined />} onClick={()=>setSelected({id:0})}>{t("Add")}</Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={load}>{t("Reload")}</Button>
            <Table dataSource={data} rowKey={"id"} loading={loading}>
                <Table.Column title={t("Id")} dataIndex="id" />
                <Table.Column title={t("Name")} dataIndex="name" />
                <Table.Column title={t("Created")} dataIndex="created" render={v=>formatDateShort(v)} />
                <Table.Column title={t("User")} dataIndex="createdBy" />
                <Table.Column title={t("Status")} dataIndex="status" render={renderStatus} />
                <Table.Column title={t("Executed")} dataIndex="executed" render={v=>v?formatDateShort(v):t("No")} />
                <Table.Column title={t("Actions")} render={(v,r)=><div>
                    <Tooltip title={t("Edit")}>
                        <Button type="link" icon={<EditOutlined />} onClick={()=>setSelected(r)} />
                    </Tooltip>
                    <Tooltip title={t("Execute")}>
                        <Popconfirm title={t("Are you sure yo want to execute this transfer?")} onConfirm={()=>onExecute(r.id)} okText={t("Yes")} cancelText={t("No")} disabled={r.status=="EXECUTED"}>
                            <Button type="link" icon={<SendOutlined />} disabled={r.status=="EXECUTED"} />
                        </Popconfirm>
                    </Tooltip>
                    <Tooltip title={t("Revert")}>
                        <Popconfirm title={t("Are you sure yo want to revert this transfer?")} onConfirm={()=>onRevert(r.id)} okText={t("Yes")} cancelText={t("No")} disabled={r.status!=="EXECUTED"}>
                            <Button type="link" icon={<UndoOutlined />} disabled={r.status!=="EXECUTED"} />
                        </Popconfirm>
                    </Tooltip>
                </div>} />
            </Table>
        </div>}
    {selected&&<div>
        <PortfolioTransferForm portfolioTransferId={props.contactId} value={selected} onSave={load} onCancel={()=>setSelected(null)} />
    </div>}
              
    </DefaultPage>
       
    };

    export default PortfolioTransfers;