import React, {useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Button, Skeleton, Table, Tag, Tooltip} from "antd";
import Operations from "../Financial/Operations";
import DefaultPage from "../Shared/DefaultPage";
import Status from "../Shared/Status";
import {formatDateShort, getColor} from "../../Lib/Helpers";
import {useTranslation} from "react-i18next";
import Column from "antd/lib/table/Column";
import {safeGetRaw} from "../../Lib/Dal";

const SsPolicies = (props) => {
    const [loadingOperations,setLoadingOperations]=useState(true);
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();

     const getColor = (entityState) => {
        switch (entityState) {
            case "DRAFT":
            case "OPEN":
                return "blue";
                break;
            case "ACTIVE":
            case ("APROVED", "APPROVED"):
                return "green";
                break;
            case "INACTIVE":
            case "REJECTED":
                return "red";
            default:
                return undefined;
                break;
        }
    };

    const isDisabled=name=>{
        //checking if the button is disabled by configuration
        if(props.configProfile && props.configProfile.disabledActions){
            return props.configProfile.disabledActions.includes(name);
        }
        return false;
    }
    const formatEndDate=endDateString=>{
        if(!endDateString) return "-";
        //checking if the date is in the past
        const endDate=new Date(endDateString);
        if(endDate<new Date()){
            return <Tag color="red">{formatDateShort(endDate)}</Tag>
        }
        //checking if the end date is in the next 30 days or less
        const today=new Date();
        const nextMonth=new Date(today);
        nextMonth.setDate(today.getDate()+30);
        if(endDate<nextMonth){
            return <Tag color="orange">{formatDateShort(endDate)}</Tag>
        }
        return formatDateShort(endDate);

    }

    return (
        <div>
            <DefaultPage
                title={ t("My Policies")}
                subTitle={<span>{t("Insurance Products")}</span>}
                iconStyle={{ backgroundColor: safeGetRaw(["Style","primaryColor"],props.configProfile,"#74b9ff") }}
                routes={{
                    routes: [
                        { breadcrumbName: t("Home"), path: "/SelfService/home" },
                        { breadcrumbName: t("Policies"), path: "quoter" },
                    ],
                }}
                icon="heart"
                loading={loading}
                extra={<div><Button type={"primary"} disabled={isDisabled("addPolicy")} icon={<LegacyIcon type={"plus"} />} onClick={()=>window.location="#/SelfService/buy"}>{t("Add")}</Button> </div>}>

                <Table dataSource={props.policies} rowKey={"id"}>
                    <Table.Column dataIndex={"id"} title={t("ID")} />
                    <Table.Column dataIndex={"code"} title={t("Code")} />
                    <Table.Column title={t("Status")} dataIndex="entityState" render={(v, r) => <Tag color={getColor(r.Process ? r.Process.entityState : r.active?"ACTIVE":null)}>{v||r.active&&t("ACTIVE")}</Tag>} />
                    <Table.Column dataIndex={"lob"} title={t("LoB")} />
                    <Table.Column dataIndex={"productCode"} title={t("Product")} />
                    <Table.Column dataIndex={"insuredSum"} title={t("Sum Insured")} />
                    <Table.Column dataIndex={"start"} title={t("Starts")} render={v=>formatDateShort(v)} />
                    <Table.Column dataIndex={"end"} title={t("Ends")} render={v=>formatEndDate(v)} />
                </Table>
            </DefaultPage>
        </div>
    );
};

export default SsPolicies;