import React from "react";
import * as d3 from "d3";
import eventDrops from "event-drops";
import { exe } from "Lib/Dal";
import { withTranslation } from "react-i18next";
import moment from "moment/moment";

class Events extends React.Component {
  componentDidMount() {
    exe("GetStats").then((r) => {
      if (!r.ok) return;
      if (this.props.onLoad) this.props.onLoad();
      //if needs translation
      if(localStorage.isoCode&& !localStorage.isoCode.includes("en")){
        try {
          import('d3-time-format/locale/'+this.getLocaleCode(localStorage.isoCode)+".json").then(l=>{
            this.locale=l;
            d3.timeFormatDefaultLocale(l);
            this.load(r);
          })
        } catch (error) {
          console.error("locale lib not found",error);
          this.load(r);
        }
      }else {
        this.load(r)
      }});
    }
    getLocaleCode(code){
    if(code.startsWith("ar")) code="ar_EG"; //only egyptian arabic is supported in this library
      else if(code.startsWith("es")) return "es-ES"; 
    //assure matches with d3-time-format library
    if(code.includes("_")) return code.replace("_","-");
    else return `${code}-${code.toUpperCase()}`
    }
  load(r){
      const createdtData = r.outData.map((p) => {
        return { id: p.claimId, name: p.lifePolicyId, date: new Date(p.cCreated) };
      });
      const blockedData = r.outData
          .filter((p) => p.blocked)
          .map((p) => {
            return { id: p.claimId, name: p.lifePolicyId, date: new Date(p.cCreated) };
          });
      const expiringData = r.outData
          .filter((p) => !p.process.finalizado && !p.expired)
          .map((p) => {
            return { id: p.claimId, name: p.lifePolicyId, date: new Date(p.process.fSla) };
          });
      const expiredData = r.outData
          .filter((p) => !p.process.finalizado && p.expired)
          .map((p) => {
            return { id: p.claimId, name: p.lifePolicyId, date: new Date(p.process.fSla) };
          });
      const closedData = r.outData
          .filter((p) => p.process.finalizado)
          .map((p) => {
            return { id: p.claimId, name: p.lifePolicyId, date: new Date(p.process.fFin) };
          });
      this.loadGraph([
        { name: this.props.t("Created"), data: createdtData },
        { name: this.props.t("Blocked"), data: blockedData },
        { name: this.props.t("Expiring"), data: expiringData },
        { name: this.props.t("Expired"), data: expiredData },
        { name: this.props.t("Closed"), data: closedData },
      ]);
  }
  locale;


  loadGraph(repositoriesData) {

    const tooltip = d3.select("#eventdrops").append("div").attr("class", "d3tooltip").style("opacity", 0);
    const me = this;
    const config = {
      d3,
      locale: this.locale,
      drops: (row) => row.data,
      drop: {
        color: null,
        radius: 5,
        date: (d) => d.date,
        onClick: (data) => {
          tooltip.transition().duration(100).style("opacity", 0);
          window.location.hash = "#/healthclaim/" + data.id;
        },
        onMouseOver: (data) => {
          tooltip.transition().duration(200).style("opacity", 0.9);
          tooltip
            .html(this.props.t("Claim") + " : " + data.name + "<br/>" +  moment(data.date).local().format("dddd, MMMM Do, YYYY"))
            .style("left", d3.event.pageX + "px")
            .style("top", d3.event.pageY - 28 + "px");
        },
        onMouseOut: () => {
          tooltip.transition().duration(500).style("opacity", 0);
        },
      },
      label: {
        padding: 20,
        text: (d) => `${d.name} (${d.data.length})`,
        width: 100,
      },
      line: {
        color: (_, index) => d3.schemeCategory10[index],
        height: 30,
      },
      margin: {
        top: 20,
        right: 10,
        bottom: 20,
        left: 10,
      },
      indicator:false, //bacause RTL overlaps label
      range: {
        start: new Date(new Date().getFullYear()+(me.props.direction=="rtl"?1:0), 0, 1),
        end: new Date(new Date().getFullYear()+(me.props.direction=="rtl"?0:1), 0, 1),
      },
    };
    const chart = eventDrops(config);
    d3.select("#eventdrops").data([repositoriesData]).call(chart);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return <div id="eventdrops" style={{ width: "95%", marginTop: 15 }} />;
  }
}
export default withTranslation()(Events);
