import React, {useEffect, useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, Button, message, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import {formatMoney} from "../../Lib/Helpers";
import CommonChangeSurchargeForm from "./CommonChangeSurchargeForm";

const CommonChangeSurcharge = (props) => {
    const [t]=useTranslation();
    const [selected,setSelected]=useState();
    const [loading,setLoading]=useState(false);
    const [data,setData]=useState([]);
    const [surchargesVisible,setSurchargesVisible]=useState(false);
    
    useEffect(()=>{
        if(props.changeId) load();
    },[props.changeId])
    
    const load=()=>{
        setLoading(true);
        exe("RepoChangeSurcharge",{operation:"GET",filter:`changeId=${props.changeId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }
    
    const onSave=values=>{
        const operation=data.find(p=>p.name===values.name)?"UPDATE":"ADD";
        if(operation==="UPDATE"){
            const updatedData=data.map(p=>p.name===values.name?values:p);
            setData(updatedData);
        }else{
            values.changeId=props.changeId;
            values.currency=props.currency;
            setData([...data,values]);
        }
        setSelected(null);
    }
    const onDelete=record=>{
        const removed=data.filter(p=>p.name!==record.name&&p.description!==record.description);
        setData(removed);
        setSelected(null);

    }
    const onOk=()=>{
        props.onChange(data.map(p=>({...p,id:0,changeId:0}))); //new record, even if updating
        setSurchargesVisible(false);
    }
    
    return (
        <div>
            <Button style={{paddingLeft:0}} type={"link"} icon={<LegacyIcon type={data.length===0?"plus-circle":"edit"} />} onClick={()=>setSurchargesVisible(true)}>{data.length===0?t("Add Surcharges"):t("Edit Surcharges")}</Button>  
                <Modal title={t("Change Surcharges")} visible={surchargesVisible} onOk={onOk} onCancel={()=>setSurchargesVisible(false)} okButtonProps={{disabled:selected}}>
                    {!selected&&<div>
                        <Button type={"link"} icon={<LegacyIcon type={"plus"} />} onClick={()=>setSelected({id:0})}>{t("New")}</Button>
                        <Table dataSource={data} rowKey={"id"} style={{marginTop:5}} pagination={false}>
                            <Table.Column title={t("Name")} dataIndex={"name"} />
                            <Table.Column title={t("Description")} dataIndex={"description"} />
                            <Table.Column title={t("Amount")} dataIndex={"value"} render={(v,r)=>formatMoney(v,r.currency)} />
                            <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} /> </div>}/>
                        </Table>
                    </div>}
                    {selected&&<CommonChangeSurchargeForm onBack={()=>setSelected(null)} onSave={onSave} selected={selected} onDelete={onDelete} currency={props.currency} />}
                </Modal>
        </div>
    );
};

export default CommonChangeSurcharge;