import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Switch, Table, message } from "antd";
import { useTranslation } from "react-i18next";
import { UnlockOutlined,LockOutlined, PlusOutlined, LeftOutlined, SaveOutlined, DeleteOutlined } from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import UserSelect from "../Bpm/UserSelect";


const BatchRestrictions = (props) => {
    const [loading,setLoading]=useState(false);
    const [addUserVisible, setAddUserVisible]=useState(false);
    const [users,setUsers]=useState([]);
    const [t] = useTranslation();
    const [form]=Form.useForm();
    const [userForm]=Form.useForm();

    useEffect(()=>{
        form.resetFields();
        setUsers([]);
        if(props.batchId) loadBatchRestrictions();
    },[props.visible,props.batchId])
            

   
    const loadBatchRestrictions=()=>{
        exe("GetBatchRestrictions",{batchId:props.batchId}).then(r=>{
            if(r.ok){
                form.setFieldsValue({restricted:r.outData.restricted});
                setUsers(r.outData.users);
            }else{
                message.error(r.msg);
            }
        })
    }
 
    const onOk=()=>{
        //validating
        form.validateFields().then(formValues=>{
            //asking for confirmation
            Modal.confirm({title:t("Are you sure?"),
                content:t("This action will limit access to this batch to the selected users only. If you are not among the selected users, you will lose your access."),
                onOk:()=>save(formValues),
                okText: t("Yes"),
                cancelText:t("No")
        });
        })
    }
    const save=(formValues)=>{
        setLoading(true);
        exe("SetBatchRestrictions",{batchId:props.batchId,...formValues,users:users,batchIds:props.selected}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(t("Saved"));
                props.onOk();
            }else{
                message.error(r.msg);
            }
        })
    }
    const onAddUser=()=>{ 
        userForm.validateFields().then(values=>{
            //checking if user is already in the list
            if(users.find(u=>u.user===values.user)){
                message.error(t("User already added"));
                return;
            }
            setUsers([...users,values]);
            setAddUserVisible(false);
            userForm.resetFields();
        })
    }
    const onRemoveUser=(record)=>{
        console.log("onRemoveUser",record,users);
        setUsers(users.filter(u=>u.user!==record.user));
    }


    const isRestricted=Form.useWatch("restricted",form);
    return <div>
        <Modal visible={props.visible} onCancel={props.onCancel} title={t("Batch Restrictions")} 
        onOk={onOk} okText={t("Save")} okButtonProps={{loading}}>

        <Form form={form} layout="vertical">
            <Form.Item label={t("Restricted")} name={"restricted"} valuePropName="checked" ><Switch checkedChildren={<LockOutlined />}
      unCheckedChildren={<UnlockOutlined />} /></Form.Item>
        </Form>

        {isRestricted&&<div>
            {!addUserVisible&&<div>
                <Button type="link" style={{padding:0}} icon={<PlusOutlined />} onClick={()=>setAddUserVisible(true)}>{t("Add")}</Button>
                <Table dataSource={users} pagination={false} size="small">
                    <Table.Column title={t("Authorized Users")} dataIndex="user" />
                    <Table.Column title={t("Actions")} render={(v,r,index)=><Button type="link" icon={<DeleteOutlined />} onClick={()=>onRemoveUser(r)} />} />
                </Table>
            </div>}
            {addUserVisible&&<div>
                <Button type="link" icon={<LeftOutlined />} onClick={()=>setAddUserVisible(false)}>{t("Back")}</Button>
                <Button type="link" icon={<SaveOutlined />} onClick={onAddUser}>{t("Save")}</Button>
                <Form form={userForm} layout="vertical">
                    <Form.Item label={t("User")} name={"user"} rules={[{required:true}]}><UserSelect filterByGroups/></Form.Item>
                </Form>
            </div>}
        </div>}
        </Modal>
    </div>
}
export default BatchRestrictions;