import React, { useEffect, useState } from "react";
import { EnterOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
    Drawer,
    Input,
    Table,
    message,
    Button,
    Select,
    Radio,
    Row,
    Col,
    InputNumber,
    DatePicker,
} from "antd";
import { exe } from "Lib/Dal";
import PolicySelect from "../Health/PolicySelect";
import Compare from "../Shared/Compare";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import useUpdateEffect from "../../Lib/Helpers";
import moment from "moment";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import AccountSelect from "../Shared/AccountSelect";
import BeneficiaryAccountSelect from "../Health/BeneficiaryAccountSelect";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

const CoLiquidation = (props) => {
    const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);
    
    useEffect(()=>{
        if(props.liquidationValues){
            props.form.setFieldsValue(props.liquidationValues)
        }
    },[props.liquidationValues])
    
    
    const onOk = () => {
        props.form.validateFields((err, values) => {
            if (!err) {
                console.log("Received values of form: ", values);
                setLoading(true);
                exe("AddCoLiquidation",{
                    Liquidation:values,
                    selectedCessions:props.liquidationValues.selectedCessions,
                    selectedClaims:props.liquidationValues.selectedClaims})
                    .then(r=>{
                    setLoading(false);
                    if(r.ok){
                        message.success(t("Successful operation"))
                        props.onOk();
                    }else message.error(r.msg);
                })
              
            }
        });
    };
    const { getFieldDecorator } = props.form;
    const values=props.form.getFieldsValue();
    return (
        <div>
            <Drawer title={t("Coinsurance Liquidation")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
                <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                    <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
                        {t("Reset")}
                    </Button>
                </div>

                <Form layout="vertical">
                    <Form.Item label={t("Coinsurer")}>{getFieldDecorator("contactId",{initialValue:props.contactId})(<SimpleContactSelect disabled />)}</Form.Item>
                    <Form.Item label={t("Description")}>{getFieldDecorator("description",{rules:[{required:true}]})(<Input />)}</Form.Item>
                    <Form.Item label={t("Currency")}>{getFieldDecorator("currency",{initialValue:props.currency})(<Currency disabled />)}</Form.Item>
                    <Form.Item label={t("Premium Ceded")}>{getFieldDecorator("premiumCeded")(<InputNumber disabled />)}</Form.Item>
                    <Form.Item label={t("Commission")}>{getFieldDecorator("commission")(<InputNumber disabled />)}</Form.Item>
                    <Form.Item label={t("Tax")}>{getFieldDecorator("tax")(<InputNumber disabled />)}</Form.Item>
                    <Form.Item label={t("Broker Commission")}>{getFieldDecorator("brokerCommission")(<InputNumber disabled />)}</Form.Item>
                    <Form.Item label={t("Claims Ceded")}>{getFieldDecorator("claimsCeded")(<InputNumber disabled />)}</Form.Item>
                    <Form.Item label={t("Total Amount")}>{getFieldDecorator("amount")(<InputNumber disabled />)}</Form.Item>
                    <Form.Item label={t("Source Account")}>{getFieldDecorator("sourceAccountId",{rules:[{required:true}]})(<AccountSelect />)}</Form.Item>
                    <Form.Item label={t("Destination Account")}>{getFieldDecorator("destinationAccountId",{rules:[{required:true}]})(<BeneficiaryAccountSelect contactId={values.contactId} currency={values.currency} />)}</Form.Item>
                    
                </Form>
                <Button type="primary" icon={<EnterOutlined />} onClick={() => onOk()} loading={loading}>
                    {t("Submit")}
                </Button>
            </Drawer>
        </div>
    );
};

export default Form.create()(CoLiquidation);
