import React, {useState} from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Descriptions, Tag } from "antd";
import {formatDate, formatDateUtc, formatMoney} from "../../Lib/Helpers";
import PolicyLink from "../Life/PolicyLink";
import {useTranslation} from "react-i18next";
import FormLabel from "../Shared/FormLabel";

const CreditHeader = (props) => {
    const [t]=useTranslation();
    const credit=props.credit;
    const contact=props.contact||{};
    return (
        <div>
            {credit.id&&<Descriptions size="small" column={3}>
                {/* ********************** FIRST ROW *********************** */}
                <Descriptions.Item label={t("Holder")}>
                    <a href={"#/contact/" + credit.contactId}>
                        <LegacyIcon type={contact.isPerson ? "user" : "bank"} style={{ marginLeft: 5, marginRight: 5 }} />
                        {contact.FullName}
                    </a>
                </Descriptions.Item>
                <Descriptions.Item label={t("Capital")}>{formatMoney(credit.capital,credit.currency)}</Descriptions.Item>
                <Descriptions.Item label={t("External Id")}><FormLabel value={credit.externalId} code paragraph /></Descriptions.Item>
                {/* ********************** SECOND  ROW *********************** */}

                <Descriptions.Item label={t("Effective")}>{formatDateUtc(credit.start)}</Descriptions.Item>
                <Descriptions.Item label={t("Status")}>
                    <Tag color={credit.active ? "green" : "red"}>{credit.active ? t("Active") : t("Inactive")}</Tag>
                </Descriptions.Item>
                {/* ********************** THIRD  ROW *********************** */}

            </Descriptions>}
        </div>
    );
};

export default CreditHeader;