import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const TransferReversalSubCauseSelect = (props) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [t] = useTranslation();

    useEffect(() => load(), [props.causeCode]);

    const load = () => {
        if(!props.causeCode) return;
        setLoading(true);
        exe("RepoTransferReversalSubcause", { operation: "GET",filter:`causeCode='${props.causeCode}'` }).then((r) => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
                //if(props.value) props.onChange(undefined); //clearing value if was selected
                props.required(r.total>0)
            } else {
                message.error(r.msg);
            }
        });
    };
    return (
        <div>
            <Select
                placeholder={t("Please select reversal subcause")}
                style={props.style}
                value={props.value}
                disabled={props.disabled}
                onChange={props.onChange}
                loading={loading}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                allowClear>
                {data.map((p) => (
                    <Select.Option value={p.code} key={p.code}>
                        {t(p.name)}
                    </Select.Option>
                ))}
            </Select>
        </div>
    );
};

export default TransferReversalSubCauseSelect;
