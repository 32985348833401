import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "antd";

const YearMonth = (props) => {
  const [date, setDate] = useState(undefined);

  useEffect(() => {
    if (props.value) {
      const momentDate = moment();
      const year = Math.floor(props.value / 100);
      const month = props.value - year * 100 - 1;
      momentDate.year(year);
      momentDate.month(month);
      setDate(momentDate);
    } else {
      setDate(undefined);
    }
  }, [props.value]);

  const onChange = (m) => {
    if (m) {
      const year = m.year();
      const month = m.month() + 1;
      const yearMonth = year * 100 + month;
      console.log(yearMonth);
      props.onChange(yearMonth);
    } else {
      props.onChange(m);
    }
  };
  return (
    <div>
      <DatePicker.MonthPicker value={date} onChange={onChange} />
    </div>
  );
};

export default YearMonth;
