import { FilterOutlined } from '@ant-design/icons';
import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../Shared/DefaultPage";
import CollectionData from "./CollectionData";

const Collection = (props) => {
  const [filterVisible, setFilterVisible] = useState(false);
  const [t, i18n] = useTranslation();
  return (
    <div>
      <DefaultPage
        title={t("Collection")}
        icon="schedule"
        extra={
          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        }>
        <CollectionData parentFilter={true} filterVisible={filterVisible} setFilterVisible={setFilterVisible} />
      </DefaultPage>
    </div>
  );
};

export default Collection;
