import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Modal, Popconfirm, Table, Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IssueTypeSelect from "./IssueTypeSelect";
import IssueReasonSelect from "./IssueReasonSelect";
import PrioritySelect from "./PrioritySelect";
import PolicySelect from "../Health/PolicySelect";
import CoverageCombo from '../Life/CoverageCombo';
import { exe, safeGetRaw } from '../../Lib/Dal';
import BenefitSelect from '../Health/BenefitSelect';
import FileUpload from '../Life/FileUpload';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import ContactIssueDocs from './ContactIssueDocs';
import ContactSelect from './ContactSelect';
import PolicySelectContact from './PolicySelectContact';
import { getConfigProfile } from '../../Lib/Helpers';

const ContactIssueDetail = (props) => {
  const [loading, setLoading] = useState(false);
  const [coverages, setCoverages] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [uploadVisible, setUploadVisible] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [onlyRelatedPolicies, setOnlyRelatedPolicies] = useState(false);

  useEffect(() => {
    getConfigProfile().then(profile=>{
      const onlyRelated=safeGetRaw(["Contacts","contactIssueOnlyRelatedPolicies"],profile,false);
      console.log("onlyRelated",onlyRelated,profile);
      setOnlyRelatedPolicies(onlyRelated);
    });
  }, []);

  useEffect(() => {

    if(props.selected){
      console.log("selected",props.selected);
      props.form.setFieldsValue(props.selected);
      if(props.selected.policyId) onPolicySelect(props.selected.policyId);
    }else{
      props.form.resetFields();
      setUploadedFiles([]);
      setUploadVisible(false);
    }}, [props.selected]);  

  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onOk(values,uploadedFiles);
      props.form.resetFields();
      setUploadedFiles([]);
      setUploadVisible(false);
    });
  };
  const onPolicySelect=(policyId,policy)=>{
    //loading coverages and benefits
    setLoading(true);
    exe("GetCoverages", { policyId: policyId,includeBenefits:true }).then((r) => {
      setLoading(false);
      if(r.ok) {
        setCoverages(r.outData.coverages);
        //if there is a selected coverage, set it
        if(props.selected&&props.selected.lifeCoverageId){
          const coverage=r.outData.coverages.find(p=>p.id===props.selected.lifeCoverageId);
          if(coverage) setBenefits(coverage.Benefits);
        }
      }
      else message.error(r.msg);
    });
  }
  const onCoverageSelect=(coverageId,coverage)=>{
    setBenefits(coverage.Benefits);
  }
  const onPolicyClear=()=>{
    setCoverages([]);
    setBenefits([]);
    props.form.setFieldsValue({lifeCoverageId:null,benefitId:null});
  }
  const onCoverageClear=()=>{
    setBenefits([]);
    props.form.setFieldsValue({benefitId:null});
  }
  const onFileUploaded=files=>{
    console.log(files);
    setUploadedFiles(files);
  }

  const values=props.form.getFieldsValue();
  return (
    <div>
      <Modal title={t("New Issue")} visible={props.visible} onOk={onSubmit} onCancel={props.onCancel} bodyStyle={{paddingTop:0}} okButtonProps={{loading:loading}}>
        {field("id")}
        {field("contactName")}
        {field("created")}
        {field("processId")}
        {field("closed")}
        {field("closedDate")}
        <Tabs>
          <Tabs.TabPane tab={t("Description")} key="description">
            <div style={{display:props.contactId?"none":"block"}}>
              <Form.Item label={t("Contact")}>{field("contactId",{initialValue:props.contactId,rules:[{required:props.contactId?false:true}]})(<ContactSelect disableAddContact display={values.contactName} />)}</Form.Item>
            </div>
            <Form.Item label={t("Title")}>{field("title", { rules: [{ required: true }] })(<Input />)}</Form.Item>
            <Form.Item label={t("Description")}>{field("description")(<Input.TextArea />)}</Form.Item>
            <Form.Item label={t("Issue Type")}>{field("issueType")(<IssueTypeSelect />)}</Form.Item>
            <Form.Item label={t("Issue Reason")}>{field("issueReason")(<IssueReasonSelect issueType={values.issueType} />)}</Form.Item>
            <Form.Item label={t("Priority")}>{field("priorityCode")(<PrioritySelect  />)}</Form.Item>
            <div style={{display:onlyRelatedPolicies?"block":"none"}}>
              <Form.Item label={t("Policy related to the contact")}>{field("policyId")(<PolicySelectContact contactId={props.contactId||values.contactId} onSelect={onPolicySelect} onClear={onPolicyClear} />)}</Form.Item>
            </div>
            <div style={{display:!onlyRelatedPolicies?"block":"none"}}>
              <Form.Item label={t("Policy")}>{field("policyId")(<PolicySelect onSelect={onPolicySelect} onClear={onPolicyClear} />)}</Form.Item>
            </div>
            <div style={{display:coverages.length>0?"block":"none"}}>
              <Form.Item label={t("Coverage")}>{field("lifeCoverageId")(<CoverageCombo style={{width:"100%"}} valueField="id"
              coverages={coverages} onSelect={onCoverageSelect} onClear={onCoverageClear} />)}</Form.Item>
            </div>
            <div style={{display:coverages.length>0&&benefits.length>0?"block":"none"}}>
              <Form.Item label={t("Benefit")}>{field("benefitId")(<BenefitSelect style={{width:"100%"}} benefits={benefits} />)}</Form.Item>
            </div>

            <Form.Item label={t("External Id")}>{field("externalId")(<Input  />)}</Form.Item>

          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Files")} key="files">
            <FileUpload hidden={!uploadVisible} onChange={(file) => onFileUploaded(file)} />
            <Button type="link" icon={<UploadOutlined />} onClick={() => setUploadVisible(!uploadVisible)}>
              {uploadVisible?t("Cancel Upload"):t("Upload")}
            </Button>
            <ContactIssueDocs contactId={props.contactId?props.contactId:values.contactId} issueId={values.id} />
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default Form.create()(ContactIssueDetail);
