import { useTranslation } from "react-i18next";
import DefaultPage from "../Shared/DefaultPage";
import { SearchOutlined } from '@ant-design/icons';
import React,{ useEffect, useState } from "react";
import { Button, Checkbox, Drawer, Form, Input, InputNumber, Space, Table, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import Currency from "../Shared/Currency";
import { exe } from "../../Lib/Dal";

const PensionList = props => {
    const [filterVisible, setFilterVisible] = useState(false);
    const [data, setData] = useState([]);
    const [loading,setLoading]=useState(false);
    const [t] = useTranslation();;
    const [form]=useForm();

    useEffect(()=>{
        onSearch(); //todo pagination
    },[])

    const onSearch=()=>{
        const values=form.getFieldsValue();
        let filter="1=1";
        if(values.id) filter+=` and id=${values.id}`;
        if(values.name) filter+=` and name like '%${values.name}%'`;
        if(values.currency) filter+=` and currency='${values.currency}'`;
        if(values.code) filter+=` and code='${values.code}'`;

        setLoading(true);
        exe("RepoPensionScheme",{operation:"GET",filter:filter}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else message.error(r.msg);
        })
    }

    return <DefaultPage title={t("Pension Scheme List")} icon="search" routes={{
        routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Pension Scheme List"), path: "pensionSchemeList" }
        ]
    }}
        extra={<div><Button type="primary" icon={<SearchOutlined />} onClick={() => setFilterVisible(true)} loading={loading}>{t("Filter")}</Button></div>}>

        <Table dataSource={data} rowKey={"id"}>
            <Table.Column title={t("Id")} dataIndex="id" />
            <Table.Column title={t("Code")} dataIndex="code" key="code" />
            <Table.Column title={t("Name")} dataIndex="name" key="name" />
            <Table.Column title={t("Currency")} dataIndex="currency" key="currency" />
            <Table.Column title={t("Active")} dataIndex="active" key="active" render={v=><Checkbox checked={v} />} />
            <Table.Column title={t("Actions")} key="action" render={(text, record) => (
                <Space size="middle">
                    <a onClick={() => props.history.push(`/pensionScheme/${record.id}`)}>{t("Open")}</a>
                </Space>
            )} />
        </Table>
        <Drawer title={t("Pension Scheme Filter Search")} placement="right" width={512} visible={filterVisible} onClose={()=>setFilterVisible(false)}>
            <Form layout="vertical" form={form}>
            <Form.Item name="id" label={t("Id")}>
                <InputNumber min={0} />
            </Form.Item>
            <Form.Item name="code" label={t("Code")}>
                <Input />
            </Form.Item>
            <Form.Item name="name" label={t("Name")}>
                <Input />
            </Form.Item>
            <Form.Item name="currency" label={t("Currency")}>
                <Currency />
            </Form.Item>
                    
                    <Button type="primary" icon={<SearchOutlined />} onClick={onSearch} loading={loading}>{t("Search")}</Button>
            </Form>
        </Drawer>

    </DefaultPage>
}
export default PensionList;