import React from "react";
import { FolderOpenTwoTone, SafetyCertificateTwoTone, UserOutlined } from '@ant-design/icons';
import { Row, Button, Spin, Descriptions, Tag, Empty } from "antd";
import { formatDate } from "../../Lib/Helpers";
import { formatter } from "../Shared/Money";
import { useTranslation } from "react-i18next";

const ClaimHeader = (props) => {
  const [t, i18n] = useTranslation();
  if (!props.claim.Policy) return null;
  const claim = props.claim;
  const policy = claim.Policy;

  const claimerId = claim.Claimer ? claim.Claimer.id : 0;
  const insuredId = claim.Contact.id;
  const claimer = claim.Claimer ? claim.Claimer.FullName : "";
  const insured = claim.Contact ? claim.Contact.FullName : "";
  const policyCode = policy.code;
  const policyId = policy.id;

  const money = formatter(policy.currency);

  const product = policy.Product ? policy.Product.name : "";
  const capital = policy ? money.format(policy.insuredSum) : t("Not Defined");

  const reserved = claim.Payouts ? claim.Payouts.reduce((total, x) => total + x.reserved, 0) : 0;
  const payed = claim.Payouts ? claim.Payouts.reduce((total, x) => total + x.payed, 0) : 0;

  return (
    <div>
      <Descriptions size="small" column={policy.inactiveReason ? 4 : 3}>
        {/* ********************** FIRST ROW *********************** */}
        <Descriptions.Item label={t("Claimer")}>
          <a href={"#/contact/" + claimerId}>
            <UserOutlined style={{ marginLeft: 5, marginRight: 5 }} />
            {claimer}
          </a>
        </Descriptions.Item>

        <Descriptions.Item label={t("Policy")}>
          <a href={"#/lifePolicy/" + policyId}>
            <SafetyCertificateTwoTone style={{ marginLeft: 12, marginRight: 5 }} />
            {policyCode}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={t("Reserved")}>{money.format(reserved)}</Descriptions.Item>
        <Descriptions.Item label={t("Capital")}>{capital}</Descriptions.Item>
        {/* ********************** SECOND  ROW *********************** */}
        <Descriptions.Item label={t("Insured")}>
          <a href={"#/contact/" + insuredId}>
            <UserOutlined style={{ marginLeft: 5, marginRight: 5 }} />
            {insured}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={t("Product")}>
          <a href="#/policies">
            <FolderOpenTwoTone style={{ marginRight: 5 }} />
            {product}
          </a>
        </Descriptions.Item>
        <Descriptions.Item label={t("Paid")}>{money.format(payed)}</Descriptions.Item>
        <Descriptions.Item label={t("Code")}>{claim.code}</Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default ClaimHeader;
