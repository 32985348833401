import React from "react";
import { formatDate } from "../../Lib/Helpers";
import { Checkbox } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import Text from "antd/lib/typography/Text";

const FormLabel = (props) => {
  let value = props.value;
  const emptyValue = props.emptyValue || "-";

  if (props.type == "Boolean") {
    return <Checkbox checked={props.value} />;
  }

  if (value) {
    if (props.type == "Date") {
      value = formatDate(value);
    }
  } else {
    if(props.showZero&&value===0) value=0;
    else value = emptyValue;
  }
  if (props.paragraph)
    return (
      <Paragraph {...props} style={{ marginBottom: 0 }}>
        {value}
      </Paragraph>
    );

  return <Text {...props}>{value}</Text>;
};

export default FormLabel;
