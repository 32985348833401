import React, {useEffect, useState} from 'react';
import {message, Select} from "antd";
import { useTranslation } from 'react-i18next';

const PolicyRestrictedStatusSelect = (props) => {
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();

    const [data,setData]=useState([
        {id:1,value:"RESTRICTED", name:t("Restricted")},
        {id:2,value:"UNRESTRICTED", name:t("Unrestricted")}
    ]);
    
    
    return (
        <div>
          <Select value={props.value} onChange={props.onChange} disabled={props.disabled} loading={loading} allowClear onClear={()=>props.onChange(undefined)} placeholder={t("Please select restriction status")}>
              {data.map(p=><Select.Option value={p.value} key={p.id}>{p.name}</Select.Option> )}
          </Select>  
        </div>
    );
};

export default PolicyRestrictedStatusSelect;