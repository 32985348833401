import { RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, DatePicker, Input, message, Result, Row, Steps, Table } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import EditableCell from "../Shared/EditableCell";

const TaxLiquidationRecord = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [reEventId, setReEventId] = useState();
  const [liquidations, setLiquidations] = useState([]);

  const field = props.form.getFieldDecorator;
  const contractId = props.contractId;

  const onStepTwo = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      setLoading(true);
      exe("ComputeTaxLiquidation", {
        taxSchemeId: props.taxSchemeId,
        from: values.from,
        to: values.to,
      }).then((r) => {
        setLoading(false);
        if (r.ok) {
          setLiquidations(r.outData);
          setCurrentStep(1);
        } else message.error(r.msg);
      });
    });
  };
  const onStepThree = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      const data = liquidations.map((p) => ({
        ...p,
        taxSchemeId: props.taxSchemeId,
        liquidationName: values.liquidationName,
        taxes: p.amount,
        jAffected: JSON.stringify(p.jAffected),
      }));
      setLoading(true);
      exe("RepoTaxLiquidation", { operation: "BULKINSERT", bulkJson: JSON.stringify(data) }).then((r) => {
        setLoading(false);
        setLiquidations(r.outData);
        setCurrentStep(2);
        if (r.ok) {
        } else message.error(r.msg);
      });
    });
  };

  const onExecute = () => {
    props.onExecute && props.onExecute(liquidations);
    setCurrentStep(0);
    props.form.resetFields();
    setReEventId(); //reseting event selection
  };
  const onExecuteLater = () => {
    props.onSave && props.onSave();
    setCurrentStep(0);
    props.form.resetFields();
    setReEventId(); //reseting event selection
  };
  const onChangeDeducted = (record, value) => {
    const changedData = liquidations.map((p) => (p.taxName == record.taxName ? { ...p, deducted: value, total: record.amount - value } : p));
    setLiquidations(changedData);
  };
  return (
    <div>
      <Button onClick={() => props.onCancel && props.onCancel()}>Cancel</Button>
      <Steps current={currentStep} type="navigation" onChange={(p) => setCurrentStep(p)}>
        <Steps.Step title={t("Period Selection")} />
        <Steps.Step title={t("Liquidation Info")} />
        <Steps.Step title={t("Result")} />
      </Steps>
      <div style={{ marginTop: 10, display: currentStep == 0 ? "block" : "none" }}>
        <Form.Item label={t("Liquidation Name")}>{field("liquidationName", { rules: [{ required: false }] })(<Input style={{ width: 300 }} />)}</Form.Item>
        <Form.Item label={t("From")}>{field("from", { rules: [{ required: true }] })(<DatePicker.MonthPicker />)}</Form.Item>
        <Form.Item label={t("To")}>{field("to", { rules: [{ required: true }] })(<DatePicker.MonthPicker />)}</Form.Item>
        <Button type="primary" icon={<RightOutlined />} onClick={() => onStepTwo()} loading={loading}>
          {t("Next")}
        </Button>
      </div>
      <div style={{ marginTop: 10, display: currentStep == 1 ? "block" : "none" }}>
        <Table dataSource={liquidations}>
          <Table.Column title={t("Tax Name")} dataIndex="taxName" />
          <Table.Column title={t("Amount")} dataIndex="amount" render={(v, r) => formatMoney(v, r.currency)} />
          <Table.Column title={t("Deducted")} dataIndex="deducted" render={(v, r) => <EditableCell onChange={(v) => onChangeDeducted(r, v)} />} />
          <Table.Column title={t("Total")} dataIndex="total" render={(v, r) => formatMoney(v, r.currency)} />
        </Table>
        <Button type="primary" icon={<SaveOutlined />} onClick={() => onStepThree()}>
          {t("Next")}
        </Button>
      </div>
      <div style={{ marginTop: 10, display: currentStep == 2 ? "block" : "none" }}>
        <Result
          status="success"
          title={t("Liquidation saved")}
          subTitle={t("When executed, this process will perform the corresponding transfers between accounts")}
          extra={[
            // <Button type="primary" key="execute" onClick={() => onExecute()}>
            //   {t("Execute")}
            // </Button>,
            <Button key="later" type="primary" onClick={() => onExecuteLater()}>
              {t("Open Liquidations")}
            </Button>,
          ]}
        />
      </div>
    </div>
  );
};

export default Form.create()(TaxLiquidationRecord);
