import React, { useState, useEffect } from "react";
import { CheckOutlined, DiffOutlined } from '@ant-design/icons';
import { Table, Tag, Button, message, Modal } from "antd";
import { exe, safeGet } from "../../Lib/Dal";
import { formatDate, getColor } from "../../Lib/Helpers";
import Status from "../Shared/Status";
import FieldComparison from "./FieldComparison";
import { useTranslation } from "react-i18next";

const ContactChanges = (props) => {
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [recordChanges, setRecordChanges] = useState(null);

  useEffect(() => {
    setData(props.value || []);
  }, [props.value]);

  const loadChanges = (id) => {
    setLoading(true);
    exe("RepoContactChange", { operation: "GET", filter: "" });
  };
  const reload = () => props.onRefresh();
  const execute = (id) => {
    exe("ExeContactChange", { changeId: id }).then((r) => {
      if (r.ok) {
        window.location.reload();
      } else {
        message.error(r.msg);
      }
    });
  };
  const getAction = (r) => {
    if (r.status == 0) {
      const enityState = safeGet(["Process", "entityState"], r, "");
      if (enityState == "APROVED") {
        return (
          <Button icon={<CheckOutlined />} onClick={() => execute(r.id)}>
            {t("Execute")}
          </Button>
        );
      } else {
        return <Tag>{t("Pending")}</Tag>;
      }
    } else if (r.status == 1) return <Tag color="green">{t("Executed")}</Tag>;
  };
  const showChanges = (record) => {
    setRecordChanges(record);
  };
  return (
    <div>
      <Table dataSource={data}>
        <Table.Column dataIndex="id" title={t("Id")} />
        <Table.Column dataIndex="creation" title={t("Date")} render={(v) => formatDate(v)} />
        <Table.Column dataIndex="user" title={t("User")} />
        <Table.Column
          dataIndex="jBefore"
          title={t("Changes")}
          render={(v, r) => (
            <Button type="link" icon={<DiffOutlined />} onClick={() => showChanges(r)}>
              {t("Changes")}
            </Button>
          )}
        />
        <Table.Column key="action" dataIndex="id" title={t("Action")} render={(v, r) => getAction(r)} />

        <Table.Column
          dataIndex="Process"
          title={t("Workflow")}
          render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => reload()} />}
        />
      </Table>
      <Modal title={t("Changes")} visible={recordChanges !== null} onOk={() => setRecordChanges(null)} onCancel={() => setRecordChanges(null)}>
        <FieldComparison before={recordChanges ? recordChanges.jBefore : null} after={recordChanges ? recordChanges.jAfter : null} />
      </Modal>
    </div>
  );
};

export default ContactChanges;
