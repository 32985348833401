import React from "react";
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Input, Tooltip } from "antd";
import { exe } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";

class Etiquetas extends React.Component {
  state = {
    tags: [],
    inputVisible: false,
    inputValue: "",
  };
  //   componentDidMount(){
  //       console.log('mount',this.props.etiquetas);
  //       const tags=this.props.etiquetas.map(p=>p.nombre);
  //       this.setState({tags:tags});
  //   }
  cambios(a, b) {
    if (!b) return false; //montandose
    return !b.every((e) => a.includes(e));
  }
  componentDidUpdate(prevProps, prevState) {
    //si vienen etiquetas nuevas desde props actualizao estado
    if (this.cambios(prevProps.etiquetas, this.props.etiquetas)) {
      //const tags=this.props.etiquetas.map(p=>p.nombre);
      this.setState({ tags: this.props.etiquetas });
    }
  }
  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag.nombre !== removedTag.nombre);
    exe("AddOrDeleteProcessTag", removedTag, { operation: "DELETE" }, true);
    this.setState({ tags });
  };

  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };

  handleInputChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  handleInputConfirm = () => {
    const state = this.state;
    const inputValue = state.inputValue;
    let nombres = state.tags.map((p) => p.nombre);
    if (!inputValue || nombres.indexOf(inputValue) !== -1) return;

    const dto = { procesoId: this.props.procesoId, nombre: inputValue };
    if (this.props.procesoId != 0) {
      exe("AddOrDeleteProcessTag", dto, { operation: "ADD" }, true).then((r) => {
        let newArray = this.state.tags;
        newArray.push(r.outData);
        this.setState({
          tags: newArray,
          inputVisible: false,
          inputValue: "",
        });
      });
    } else {
      console.error("Eiqueta no guardada. Debe primero crear la actividad");
    }
  };

  saveInputRef = (input) => (this.input = input);
  render() {
    const { t, i18n } = this.props;
    const { tags, inputVisible, inputValue } = this.state;

    return (
      <div>
        {tags.map((tag, index) => {
          const isLongTag = tag.length > 20;
          const tagElem = (
            <Tag color="#2db7f5" style={{ marginBottom: 5 }} key={tag.id} closable /* closable={index !== 0} */ afterClose={() => this.handleClose(tag)}>
              {isLongTag ? `${tag.nombre.slice(0, 20)}...` : tag.nombre}
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag.nombre} key={tag.id}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={this.saveInputRef}
            type="text"
            size="small"
            style={{ width: 78 }}
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag onClick={this.showInput} style={{ background: "#fff", borderStyle: "dashed" }}>
            <PlusOutlined /> {t("New Tag")}
          </Tag>
        )}
      </div>
    );
  }
}
export default withTranslation()(Etiquetas);
