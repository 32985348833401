import React from "react";
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Input } from "antd";
import BranchSelect from "../Financial/BranchSelect";

export default class EditableCell extends React.Component {
  state = {
    value: this.props.value,
    editable: false,
    display:undefined
  };

  handleChange = e => {
    const value = e.target.value;
    this.setState({ value });
  };
  handleValueChange = v => {
    this.setState({ value:v });
  };
  handleSelect=record=>{
    this.setState({ display:record?record.name:undefined });
  }

  check = () => {
    this.setState({ editable: false });
    if (this.props.onChange) {
      if(this.props.onChange(this.state.value)==-1){
        //error de vaidacion
        this.setState({ editable: true });
      }
    }
  };

  cancel=()=>{
    this.setState({editable:false,value:this.props.value});
  }

  edit = () => {
    this.setState({ editable: true });
  };
  
  renderEditorField=(fieldType)=>{
    switch(fieldType){
      case "branches":
        return (
          <div style={{width:150}}><BranchSelect value={this.state.value} onChange={this.handleValueChange} onSelect={this.handleSelect} />  <div >
            <CheckOutlined className="editable-cell-icon-check" onClick={this.check} />
            <CloseOutlined className="editable-cell-icon-check" onClick={this.cancel} /></div></div>
        );
      default:
        return (
          <Input
              value={this.state.value}
              onChange={this.handleChange}
              onPressEnter={this.check}
              suffix={
                <div >
                  <CheckOutlined className="editable-cell-icon-check" onClick={this.check} />
                  <CloseOutlined className="editable-cell-icon-check" onClick={this.cancel} /></div>}
          />
        );
    }
    
  }

  render() {
    const { value, editable,display } = this.state;
    //const readValue=typeof(value)=="string"?value.substring(0,30)+'...':value;

    return (
      <div className="editable-cell">
        {editable ?this.renderEditorField(this.props.type)
        : (
          <div style={{ paddingRight: 24 }}>
            {display||value || " "}
            <EditOutlined className="editable-cell-icon" onClick={this.edit} />
          </div>
        )}
      </div>
    );
  }
}

