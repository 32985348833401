import { FileOutlined, FolderOpenOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import ObjectDefinitionDetail from "./ObjectDefinitionDetail";

const ObjectDefinition = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [detailVisible, setDetailVisible] = useState(false);
  const [selected, setSelected] = useState();
  const { t } = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("RepoObjectDefinition", { operation: "GET", include: ["Form"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
    setLoading(true);
  };
  const onNew = () => {
    setDetailVisible(true);
    setSelected(undefined);
  };

  const onUpdate = (record) => {
    if (!record) {
      //delete
      setData(data.filter((r) => r.id !== selected.id));
      return;
    }
    if (data.find((r) => r.id == record.id)) {
      //update
      setData(data.map((r) => (r.id == record.id ? record : r)));
    } else {
      //new
      setData([...data, record]);
    }
  };
  const onOpen = (r) => {
    setSelected(r);
    setDetailVisible(true);
  };
  return (
    <div>
      <DefaultPage title={t("Object Definition")} icon="build">
        <div style={{ display: !detailVisible ? "block" : "none" }}>
          <div>
            <Button type="primary" icon={<FileOutlined />} onClick={onNew} style={{ marginBottom: 5, marginRight: 5 }}>
              {t("New")}
            </Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={load} style={{ marginBottom: 5, marginRight: 5 }}>
              {t("Reload")}
            </Button>
          </div>
          <Table dataSource={data} loading={loading} size="middle">
            <Table.Column title={t("Id")} dataIndex="id" />
            <Table.Column title={t("Code")} dataIndex="code" />
            <Table.Column title={t("Name")} dataIndex="name" />
            <Table.Column title={t("Form")} dataIndex={["Form","name"]} render={(v, r) => <a href={"#fb/" + r.formId}>{v}</a>} />
            <Table.Column
              title={t("Actions")}
              key="actions"
              render={(v, r) => (
                <div>
                  <Button type="link" icon={<FolderOpenOutlined />} onClick={() => onOpen(r)} />
                </div>
              )}
            />
          </Table>
        </div>
        <div style={{ display: detailVisible ? "block" : "none" }}>
          <ObjectDefinitionDetail selected={selected} onBack={() => setDetailVisible(false)} onUpdate={(record) => onUpdate(record)} />
        </div>
      </DefaultPage>
    </div>
  );
};

export default ObjectDefinition;
