import { FileOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import Liquidation from "./Liquidation";
import LiquidationRecord from "./LiquidationRecord";
import SimpleContactSelect from "../Contact/SimpleContactSelect";

const LiquidationList = (props) => {
  const [t] = useTranslation();
  const [liquidations, setLiquidations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newLiquidationVisible, setNewLiquidationVisible] = useState(false);
  const [participantId,setParticipantId]=useState();

  useEffect(() => load(), [participantId]);
  
  const contractId=props.contractId;

  const load = () => {
    setLoading(true);
    let filter;
    if(contractId&&participantId)
      filter=`contractId=${contractId} AND participantId=${participantId}`;
    else if(contractId&&!participantId)
      filter=`contractId=${contractId}`;
    else if(!contractId&&participantId)
      filter=`participantId=${participantId}`;
   
    exe("RepoLiquidation", { operation: "GET", filter:filter,include:["Process"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setLiquidations(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onNewLiquidationSaved = () => {
    load();
    setNewLiquidationVisible(false);
  };
  const onDelete = (record) => {
    setLoading(true);
    exe("RemoveLiquidation", { liquidationId: record.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  const onExecuteLiquidation = (record) => {
    setLoading(true);
    exe("ExeLiquidation", { liquidationId: record.id }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(r.msg);
        load();
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <div style={{ display: !newLiquidationVisible ? "block" : "none" }}>
        <Button type="primary" icon={<FileOutlined />} onClick={() => setNewLiquidationVisible(true)} >
          {t("New")}
        </Button>
        <Button type="link" icon={<ReloadOutlined />} onClick={() => load()}>
          {t("Reload")}
        </Button>
        <SimpleContactSelect  onChange={v=>setParticipantId(v)} value={participantId} style={{width:400,display:props.showReinsurerFilter?"inline-block":"none",marginBottom:5}} />
        <LiquidationRecord
          value={liquidations}
          loading={loading}
          onDelete={(record) => onDelete(record)}
          onExecuteLiquidation={(r) => onExecuteLiquidation(r)}
          reload={()=>load()}
        />
      </div>
      <div style={{ display: newLiquidationVisible ? "block" : "none" }}>
        <Liquidation contractId={contractId} participantId={participantId} onSave={onNewLiquidationSaved} onCancel={() => setNewLiquidationVisible(false)} />
      </div>
    </div>
  );
};

export default LiquidationList;
