import React, { useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import Operations from "../Financial/Operations";
import MasterDetail from "../Shared/MasterDetail";
import ClaimReserveDetail from "./ClaimReserveDetail";
import Oynr from "./Oynr";
import { useTranslation } from "react-i18next";

const ClaimReserves = (props) => {
  const [t] = useTranslation();
  const [activeKey, setActiveKey] = useState(null);

  const operations = [
    {
      title: t("Payment Pending"),
      description: t("Claims which have been liquidated and are waiting to be payed"),
      icon: "dollar",
      onPlay: () => {
        setActiveKey("paymentPending");
      },
    },
    {
      title: t("In process"),
      description: t("Claims which are in the process of liquidation"),
      icon: "clock-circle",
      onPlay: () => {
        setActiveKey("inProcess");
      },
    },
    {
      title: t("OYNR"),
      description: t("Ocurred Yet Not Reporteed. Chain Ladder Method."),
      icon: "calendar",
      onPlay: () => {
        setActiveKey("oynr");
      },
    },
  ];
  return (
    <DefaultPage title={t("Claim Reserves")} icon="database">
      <MasterDetail activeKey={activeKey} onBack={() => setActiveKey(null)} master={<Operations value={operations} />}>
        <ClaimReserveDetail key="paymentPending" mode="paymentPending" />
        <ClaimReserveDetail key="inProcess" mode="inProcess" />
        <Oynr key="oynr" />
      </MasterDetail>
    </DefaultPage>
  );
};

export default ClaimReserves;
