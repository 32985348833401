import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, InputNumber, Modal } from "antd";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import {useTranslation} from "react-i18next";
import moment from "moment";
import SupervisorSelect from "./SupervisorSelect";
import DatePickerW from "../Shared/DatePickerW";

const ComTreeMember = (props) => {
    const [t]=useTranslation();
    const field=props.form.getFieldDecorator;
    
    const onSave=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            console.log(values);
            props.onOk&&props.onOk(values);
            
        })
    }
    return (
        <Modal title={t("Member")} visible={props.visible} onOk={onSave} onCancel={props.onCancel}>
            {field("id")}
            {field("comTreeId",{initialValue:props.comTreeId})}
            <Form.Item label={t("Contact")}>{field("contactId",{rules:[{required:true}]})(<SimpleContactSelect />)}</Form.Item>
            <Form.Item label={t("Position")}>{field("position",{rules:[{required:true}]})(<Input />)}</Form.Item>
            <Form.Item label={t("Tier")}>{field("tier",{rules:[{required:false}]})(<InputNumber min={0} />)}</Form.Item>
            <Form.Item label={t("Percentage")}>{field("percentage",{rules:[{required:false}]})(<InputNumber min={0} />)}</Form.Item>
            <Form.Item label={t("Supervisor")}>{field("supervisorId",{rules:[{required:false}]})(<SupervisorSelect comTreeId={props.comTreeId} />)}</Form.Item>
            <div style={{ display: "flex" }}>
                <Form.Item label={t("Start")}>
                    {field("start", { rules: [{ required: true }], normalize: (v) => (v ? moment(v) : undefined) })(<DatePickerW />)}
                </Form.Item>
                <Form.Item label={t("End")}>
                    {field("end",{ rules: [{ required: false }], normalize: (v) => (v ? moment(v) : undefined) } )(<DatePickerW style={{ marginLeft: 5 }} />)}
                </Form.Item>
            </div>
            
        </Modal>
    );
};

export default Form.create()(ComTreeMember);