import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, DatePicker, Drawer, Input, InputNumber, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import Compare from "../Shared/Compare";
import ContactSelect from "../Contact/ContactSelect";
import CreditProductSelect from "./CreditProductSelect";
import Currency from "../Shared/Currency";

const CreditListFilter = (props) => {
    const [t] = useTranslation();
    const field = props.form.getFieldDecorator;
    const onSearch = () => {
        props.form.validateFields((err, values) => {
            if (err) return;
            props.onSearch(values);
        });
    };
    return (
        <Drawer title={t("Credit Filter")} visible={props.visible} onClose={props.onClose} width={512}>
            <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
                <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
                    {t("Reset")}
                </Button>
            </div>
            <Form>
                <Form.Item label={t("Id")}>{field("id")(<InputNumber />)}</Form.Item>
                <Form.Item label={t("Code")}>{field("code")(<Input />)}</Form.Item>
                <Form.Item label={t("Currency")}>{field("currency")(<Currency />)}</Form.Item>
                <Form.Item label={t("Status")}>{field("status")(<Select allowClear>
                    <Select.Option value={"active"}>{t("Active")}</Select.Option>
                    <Select.Option value={"inactive"}>{t("Inactive")}</Select.Option>
                </Select>)}</Form.Item>
                <Form.Item label={t("Contact")}>{field("contactId")(<ContactSelect />)}</Form.Item>
                {/*<Form.Item label={t("Cnp")}>{field("cnp")(<Input />)}</Form.Item>*/}
                {/*<Form.Item label={t("Nif")}>{field("nif")(<Input />)}</Form.Item>*/}
                <Form.Item label={t("Product")}>{field("productCode")(<CreditProductSelect />)}</Form.Item>
                <Form.Item label={t("External Id")}>{field("externalIdLIKE")(<Input />)}</Form.Item>
                <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()}>
                    {t("Search")}
                </Button>
            </Form>
        </Drawer>
    );
};

export default Form.create({})(CreditListFilter);