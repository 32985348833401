import React, {useEffect, useState} from 'react';
import {exe} from "../../Lib/Dal";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import {Table, message, Button, Divider, Row, Col, Tabs} from "antd";
import {useTranslation} from "react-i18next";
import Currency from "../Shared/Currency";
import PaymentMethods from "../Life/PaymentMethods";
import CashierDeposit from "./CashierDeposit";
import CashierIncomeSummary from "./CashierIncomeSummary";
import Imbalances from "./Imbalances";
import ImbalanceDeposit from "./ImbalanceDeposit";
import {formatMoney} from "../../Lib/Helpers";

const CashierPayments = (props) => {
    const [loading,setLoading]=useState();
    const [data,setData]=useState([]);
    const [t]=useTranslation();
    const [selectedRowKeys,setSelectedRowKeys]=useState([]);
    const [paymentsTransferVisible,setPaymentsTransferVisible]=useState(false);
    const [amount,setAmount]=useState(0);
    const [currency,setCurrency]=useState();
    const [paymentMethod,setPaymentMethod]=useState();
    const [currencyFilter,setCurrencyFilter]=useState();
    const [externalSource,setExternalSource]=useState();
    const [incomeSummaryVisible,setIncomeSummaryVisible]=useState(false);
    const [depositPaymentMethod,setDepositPaymentMethod]=useState();
    const [imbalanceVisible,setImbalanceVisible]=useState(false);
    
    useEffect(()=>{
        if(props.workspaceId) load(props.workspaceId,paymentMethod,currencyFilter,externalSource);
    },[props.workspaceId]);
    
    const load=(workspaceId,paymentMethod,currency,externalSource)=>{
        setLoading(true);
        exe("GetCashierPayments",{workspaceId:workspaceId,paymentMethod:paymentMethod,currency:currency,externalSource:externalSource}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
                setSelectedRowKeys([]);
                setAmount(0);
            }else message.error(r.msg);
        })
    }
    const onChangeSelected=(keys,rows)=>{
        if(rows.length>0&&rows.some(p=>p.currency!==rows[0].currency)){
            message.error(t("Selected movements must have the same currency"));
            return;
        }
        if(rows.length>0&&rows.some(p=>p.paymentMethod!==rows[0].paymentMethod)){
            message.error(t("Selected movements must have the same payment method"));
            return;
        }
        setDepositPaymentMethod(rows.length>0?rows[0].paymentMethod:undefined);
        setAmount(rows.reduce((p,c)=>(c.conversion?c.amountEx:c.amount)+p,0));
        setCurrency(rows.length&&rows[0].currency);
        setSelectedRowKeys(keys);
    }
    const onChangePaymentMethod=v=>{
        setPaymentMethod(v);
        load(props.workspaceId,v,currencyFilter,externalSource);
    }
    const onChangeCurrency=v=>{
        setCurrencyFilter(v);
        load(props.workspaceId,paymentMethod,v,externalSource);
    }
    const onChangeExternalSource=v=>{
        setExternalSource(v);
        load(props.workspaceId,paymentMethod,currencyFilter,v);
    }
    const transfer=()=>{
        setPaymentsTransferVisible(true);
    }
    const onDepositOk=()=>{
        setPaymentsTransferVisible(false);
        reload();
    }
    const onImbalanceOk=()=> {
        setImbalanceVisible(false);
    }
    const reload=()=>{
        load(props.workspaceId,paymentMethod,currencyFilter,externalSource);
    }
    const rowSelection={
        selectedRowKeys:selectedRowKeys,
        onChange:onChangeSelected}
    const renderAmount=(v,r)=>{
        if(r.amount<0){
            return <span style={{color:"red"}}>{formatMoney(r.amount,r.currency)}</span>
        }else{
            return r.conversion?formatMoney(r.amountEx,r.currency):formatMoney(r.amount,r.currency)
        }
    }
    return (
        <div>
            <Button type="link" icon={<LegacyIcon type={"upload"} />} onClick={()=>transfer()} >{t("Deposit")}</Button>
            <Button type="link" icon={<LegacyIcon type={"bars"} />} onClick={()=>setIncomeSummaryVisible(true)} >{t("Income Summary")}</Button>
            <Tabs>
                <Tabs.TabPane tab={t("Movements")} key="1">
                    <Button type="link" icon={<LegacyIcon type={"reload"} />} onClick={()=>reload()}>{t("Reload")}</Button>
                    {/*<ExternalSourceSelect onChange={onChangeExternalSource} value={externalSource}  style={{width:200,marginBottom:5,marginLeft:3}}/>*/}
                    <PaymentMethods module="INCOME" onChange={onChangePaymentMethod} value={paymentMethod} style={{width:200,marginBottom:5,marginLeft:3}} />
                    <Currency onChange={onChangeCurrency} value={currencyFilter} style={{width:200,marginBottom:5,marginLeft:3}} />
                    <Table dataSource={data} loading={loading} rowKey={"id"} rowSelection={rowSelection} pagination={false}  footer={(d) => (
                        <span>
                            <b style={{marginRight:5}}>{t("Total")}:</b>
                            {amount.toFixed(2)}
                            <Divider type="vertical" />
                        </span>
                    )}>
                        <Table.Column dataIndex="id" title={t("ID")} />
                        <Table.Column dataIndex="transferId" title={t("Transfer ID")} />
                        <Table.Column dataIndex={["PaymentMethod","name"]} title={t("Payment Method")} />
                        <Table.Column dataIndex="concept" title={t("Reference")} />
                        <Table.Column key="amount" title={t("Amount")} render={renderAmount}  />
                        <Table.Column dataIndex="currency" title={t("Currency")} render={(v) => <Currency.Flag currency={v} />} />
                    </Table>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("Imbalances")} key="2">
                    <Button type="link" icon={<LegacyIcon type={"plus"} />} onClick={()=>setImbalanceVisible(true)} style={{float:"left"}}>{t("New")}</Button>
                    <Imbalances workspaceId={props.workspaceId} reload={imbalanceVisible} />
                </Tabs.TabPane>
            </Tabs>
            

    <CashierIncomeSummary workspaceId={props.workspaceId} visible={incomeSummaryVisible} onCancel={()=>setIncomeSummaryVisible(false)} onOk={()=>setIncomeSummaryVisible(false)} />
    <CashierDeposit visible={paymentsTransferVisible} onCancel={()=>setPaymentsTransferVisible(false)} 
                    amount={amount} currency={currency} 
                    workspaceId={props.workspaceId} externalSource={externalSource}
                    onOk={onDepositOk}
                    paymentMethod={depositPaymentMethod}
    />
    <ImbalanceDeposit visible={imbalanceVisible} onCancel={()=>setImbalanceVisible(false)}
                    workspaceId={props.workspaceId} externalSource={externalSource}
                    onOk={onImbalanceOk}
    />
        </div>
    );
};

export default CashierPayments;