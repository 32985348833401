import React, {useEffect, useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Col, Row, Skeleton, Table, Typography, message } from "antd";
import DefaultPage from "../Shared/DefaultPage";
import {useTranslation} from "react-i18next";
import LanguageSelect from "./LanguageSelect";
import {exe, safeGetRaw} from "../../Lib/Dal";
import View from "../Bpm/View";

const SsUserProfile = (props) => {
    const [loadingOperations,setLoadingOperations]=useState(true);
    const [loading,setLoading]=useState(false);
    const [userDataViewCode,setUserDataViewCode]=useState();
    const [t]=useTranslation();
    
    
    useEffect(()=>{
        if(props.configProfile){
            const userDataViewId=safeGetRaw(["configProfile","userDataViewId"],props,null);
            if(!userDataViewId) return;
            setLoading(true);
            exe("RepoLiveView",{operation:"GET",filter:`id=${userDataViewId}`}).then(r=>{
                setLoading(false);
                if(r.ok){
                    const record=r.outData[0];
                    setUserDataViewCode(record.code);
                }else message.error(r.msg);
            })
        }
    },[props.configProfile])

    return (
        <div>
            <DefaultPage
                title={ t("User Profile")}
                subTitle={<span>{props.contact&&props.contact.FullName}</span>}
                iconStyle={{ backgroundColor: safeGetRaw(["Style","primaryColor"],props.configProfile,"rgb(69,156,84)") }}
                routes={{
                    routes: [
                        { breadcrumbName: t("Home"), path: "/SelfService/home" },
                        { breadcrumbName: t("User"), path: "user" },
                    ],
                }}
                icon="user"
                loading={loading}
                extra={<div></div>}>
            <Typography.Title level={4}>{t("Preferences")}</Typography.Title>
                <Row>
                    <Col span={8}>
                        <Form.Item label={t("Language")}>
                            <LanguageSelect />
                        </Form.Item>
                    </Col>
                </Row>
                {userDataViewCode&&<div style={{marginTop:10}}>
                    <Typography.Title level={4}>{t("User Data")}</Typography.Title>
                    <View code={userDataViewCode} context={{contact:props.contact}} />
                </div>}
                
            </DefaultPage>
        </div>
    );
};

export default SsUserProfile;