import React, {useEffect, useState} from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber, Modal, message, Input } from "antd";
import {useTranslation} from "react-i18next";
import AccountSelect from "../Shared/AccountSelect";
import {exe} from "../../Lib/Dal";
import Currency from "../Shared/Currency";
import ExternalSourceSelect from "./ExternalSourceSelect";
import AccountGroupSelect from "./AcountGroupSelect";
import PaymentMethods from "../Life/PaymentMethods";
import {round2} from "../../Lib/Helpers";
import Money from "../Shared/Money";

const ImbalanceDeposit = (props) => {
    const [t]=useTranslation();
    const [loading,setLoading]=useState();
    
    const onOk=()=>{
        props.form.validateFields((err,values)=>{
            if(err) return;
            setLoading(true);
            exe("RepoImbalance",{operation:"ADD",entity:{workspaceId:props.workspaceId,...values}}).then(r=>{
                setLoading(false);
                if(r.ok){
                    props.onOk(r);
                }else message.error(r.msg);
            })
        })
    }
    const onChangePaymentMethod=v=> {
        if(v&&values.currency){
            getExpectedAmount(v,values.currency);
        }
    }
    const onChangeCurrency=v=> {
        if(v&&values.paymentMethod){
            getExpectedAmount(values.paymentMethod,v);
        }
    }

    const getExpectedAmount=(paymentMethod,currency)=> {
        setLoading(true);
        exe("GetExpectedDeposit",{workspaceId:props.workspaceId,currency:currency,paymentMethod:paymentMethod}).then(r=>{
            setLoading(false);
            if(r.ok){
                props.form.setFieldsValue({amount:r.outData.expectedAmount});
            }else message.error(r.msg);
        })
    }

    const field=props.form.getFieldDecorator;
    const values=props.form.getFieldsValue();
    return (
        <div>
            <Modal title={t("Imbalance")} visible={props.visible} onCancel={props.onCancel} okText={t("Request")}
                   onOk={onOk} okButtonProps={{loading:loading}} destroyOnClose>
                <Form.Item label={t("Payment Method")} >
                    {field("paymentMethod",{initialValue:props.paymentMethod,rules:[{required:true}]})(<PaymentMethods module="INCOME" onChange={onChangePaymentMethod}  style={{width:"100%"}}/>)}
                </Form.Item>
                <Form.Item label={t("Currency")}>
                    {field("currency",{initialValue:props.currency,rules:[{required:true}]})(<Currency onChange={onChangeCurrency}  style={{width:"100%"}} />)}
                </Form.Item>
                <Form.Item label={t("Imbalance Amount")}>
                    {field("amount",{rules:[{required:true}]})(<Money currency={values.currency} min={0} style={{width:"100%"}} />)}
                </Form.Item>
                <Form.Item label={t("Destination")} >
                    {field("destinationAccountId",{rules:[{required:true}]})(<AccountGroupSelect group={"IMBALANCES"} currency={values.currency} style={{width:"100%"}}/>)}
                </Form.Item>
                <Form.Item label={t("Reason")} >
                    {field("reason",)(<Input />)}
                </Form.Item>
            </Modal>
        </div>
    );
};

export default Form.create()(ImbalanceDeposit);