import React, { useState } from "react";
import Title from "antd/lib/typography/Title";
import { CheckCircleOutlined, DashboardOutlined, ShoppingOutlined, SolutionOutlined } from '@ant-design/icons';
import { Select, Button, Steps, Tabs, Skeleton, Carousel, message, Result, Modal } from "antd";
import ProductQuote from "./ProductQuote";
import ProductInfo from "./ProductInfo";
import ProductBuy from "./ProductBuy";
import PortalProductSelect from "./PortalProductSelect";
import {useTranslation} from "react-i18next";
import {exe} from "../../Lib/Dal";
import View from "../Bpm/View";

const Portal = (props) => {
  const Step = Steps.Step;
  const [t]=useTranslation();
  const [current, setCurrent] = useState(0);
  const [step, setStep] = useState("selection");
  const [productConfig,setProductConfig]=useState({});
  const [productCode,setProductCode]=useState();
  const [loading,setLoading]=useState(false);
  const [quoteResult,setQuoteResult]=useState([]);
  const [savedQuote,setSavedQuote]=useState();
  const [actionPolicy,setActionPolicy]=useState();
  const [actionViewCode,setActionViewCode]=useState();
  const [issuedPolicy,setIssuedPolicy]=useState();
  const [hasError,setHasError]=useState(0);
  
  const onProductSelect=(v,opt)=>{
    setProductCode(v);
    if(v){
      setProductConfig(JSON.parse(opt.props.record.configJson))
    }else setProductConfig({})
  }

  const onChange = (newCurrent) => {
    if (newCurrent > current) return; //disabled. skipping steps not allowed
    
    setCurrent(newCurrent);
    if (newCurrent == 0) {
      setStep("selection");
    }
    if (newCurrent == 1) {
      setStep("product");
    }
    if (newCurrent == 2) {
      setStep("quote");
    }
    if (newCurrent == 3) {
      setStep("buy");
    }
  };
  const onQuote = () => {
    if(!productCode){
        message.error(t("Please select a product"));
        return;
    }
    setStep("product");
    setCurrent(1);
  };
  const onFinishProductInfo = (formValues) => {
    formValues.holderId=props.contact.id;
    setLoading(true);
    exe("QuotePortalProduct",{jFormValues:formValues,productCode:productCode}).then(r=> {
      setLoading(false);
      if (r.ok) {
        setQuoteResult(r.outData)
        setStep("quote");
        setCurrent(2);
      } else message.error(r.msg);
    });
    
  };
  const onBuy = (policy,index) => {
     //saving policy
     console.log(policy);
     setLoading(true);
     exe("QuotePolicy", {  policy, dbMode: false, save: true, action: "QuotePortalProduct" }).then((r) => {
       setLoading(false);
       if (r.ok) {
        setSavedQuote(r.outData[0]);
        setStep("buy");
        setCurrent(3);
        setHasError(0);
       } else {
        message.error(r.msg);
        setHasError(c=>c+1);
       }

     })
   
  };
  const onIssue = () => {
    //issuing policy
    setLoading(true);
    exe("IssuePolicy", {  policyId:savedQuote.id,state:"ACTIVE" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Policy issued"));
        setIssuedPolicy(r.outData[0]);
        setStep("buy");
        setCurrent(3);
        props.reload&&props.reload();
      } else {
        message.error(r.msg);
        setIssuedPolicy(false);
      }
    })
  }
  const onSave = (policy) => {
    //saving policy
    console.log(policy);
    setLoading(true);
    exe("QuotePolicy", {  policy, dbMode: false, save: true, action: "QuotePortalProduct" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        message.success(t("Quotation saved"));
        props.reload&&props.reload();
        setSavedQuote(r.outData[0]);
        setStep("saved");
      } else message.error(r.msg);
    })
    
  }
  const onAction= (policy,action,index) => {
    //getting policy
    setActionPolicy(policy);
    //loading live view
    setLoading(true);
    exe("RepoLiveView",{operation:"GET",filter:`id=${action.viewId}`}).then(r=>{
      setLoading(false);
      if(r.ok){
        const record=r.outData[0];
        setActionViewCode(record.code);
      }else message.error(r.msg);
    })
  }
  return (
    <div style={{ height: "100%", width: 800, marginLeft: "auto", marginRight: "auto" }}>
      <Steps type="navigation" current={current} onChange={onChange} style={{marginBottom:50}}>
        <Step title={t("Selection")} icon={<ShoppingOutlined />}></Step>
        <Step title={t("Insured Info")} icon={<SolutionOutlined />} />
        <Step title={t("Quote")} icon={<DashboardOutlined />}  />
        <Step title={t("Info & Payment")} icon={<CheckCircleOutlined />} />
      </Steps>

      {step == "selection" && (
        <div>
          <Title>{t("Surprisingly great rates")}</Title>
          <Title level={4}>{t("Select a product to start")} </Title>
          <PortalProductSelect size="large" style={{ width: 300, marginBottom: 5 }} onChange={onProductSelect} value={productCode} />
          <br />
          <Button type="primary" size="large" onClick={() => onQuote()}>
            {t("Start a quote")}
          </Button>
        </div>
      )}
      {step == "product" && <ProductInfo productConfig={productConfig} onFinish={onFinishProductInfo} loading={loading} />}
      {step == "quote" && <ProductQuote productConfig={productConfig} result={quoteResult} onBuy={onBuy} onSave={onSave} onAction={onAction} hasError={hasError} />}
      {step == "buy" && <ProductBuy onPay={onIssue} savedQuote={savedQuote} success={issuedPolicy} />}
      {step=="saved"&& <Result
          status="success"
          title={t("Successfully saved the quotation")}
          subTitle={t("Quote number is ")+savedQuote.id}
          extra={[
            <Button type="primary" key="console" onClick={()=>window.location="#/SelfService/home"}>
              {t("Home")}
            </Button>,
            <Button key="buy" onClick={()=>setStep("selection")}>{t("Quote again")}</Button>,
          ]}
      />}
      <Modal visible={actionViewCode} onCancel={()=>setActionViewCode(null)} okButtonProps={{style:{display:"none"}}} >
        <View code={actionViewCode} context={{policy:actionPolicy,close:()=>setActionViewCode(null)}} />
      </Modal>
    </div>
  );
};

export default Portal;
