import React from "react";
import { Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import { LeftOutlined, PlusOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import FundPlanForm from "./FundPlanForm";

const FundPlans = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [selected,setSelected] = useState(null);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        if(props.fundId){
            load();
        }
    },[props.fundId])

    const load=()=>{
        setLoading(true);
        exe("RepoFundPlan",{operation:"GET",filter:`fundId=${props.fundId}`}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }


    return <div>
        {!selected&&<div>
            <Button type="link" icon={<PlusOutlined />} onClick={()=>setSelected({id:0})}>{t("Add")}</Button>
            <Button type="link" icon={<ReloadOutlined />} onClick={load}>{t("Reload")}</Button>
            <Table dataSource={data} rowKey={"id"} loading={loading}>
                <Table.Column title={t("Id")} dataIndex="id"  />
                <Table.Column title={t("Name")} dataIndex="name"  />
                <Table.Column title={t("Actions")} render={(v,r)=><div>
                    <Button type="link" icon={<EditOutlined />} onClick={()=>setSelected(r)} />
                </div>} />
            </Table>
        </div>}
    {selected&&<div>
        <FundPlanForm fundId={props.fundId} value={selected} onSave={load} onCancel={()=>setSelected(null)} />
    </div>}
    </div>
       
    };

    export default FundPlans;