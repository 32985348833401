import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import PaymentMethods from "../Life/PaymentMethods";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";
import PaymentLine from "./PaymentLine";
import {round2} from "../../Lib/Helpers";

const SplitPayment = (props) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [paymentLines,setPaymentLines]=useState([{}]);

  const total=paymentLines.reduce((a,c)=>(c.amount||0)+a,0);
  
  useEffect(()=>{
    props.onChangetotal(total)
  },[total]);
  
  useEffect(()=>{
    if(props.value) setPaymentLines(props.value)
  },[props.value])
  
  useEffect(()=>{
    const debouncedHandler = setTimeout(() => {
        props.onChange(paymentLines);
    }, 500);
    
    return () => {
        clearTimeout(debouncedHandler);
    }
    
  },[JSON.stringify(paymentLines)]);

  useEffect(()=>{
    if(props.currency&&props.currencyDate&&paymentLines.length>0){
      //using an update version of paymentLines in onChangeField for currency exchange rate calculation update
      const payLinesCopy=[...paymentLines];
      let updatedPayLines=payLinesCopy.map((p,i)=>({...p,...{currency:undefined,exchangeRate:undefined,amountEx:undefined}}));
      payLinesCopy.forEach((p,i)=>{
        if(p.currency) onChangeField("currency",p.currency,i,undefined,undefined,updatedPayLines);
      })
      setPaymentLines(updatedPayLines);
    }

  },[props.currencyDate])


  
  const onChangeField=(field,value,index,formId=undefined,paymentMethodName=undefined,updatedPaymentLines=undefined)=>{
      if(field==="currency"){
        //obtain exchange rate
        const line=paymentLines[index];
        let financialProductColumn="bid";
        let financialProduct=value||"USD";
        //checking the base currency
        if(props.currency!==props.defaultCurrency&&value!==props.defaultCurrency) {
          //financial product needs base specification. Default currency not involved. Only bid operation
          financialProduct=financialProduct+"-"+props.currency;
        }else{
          //checking operation
          if(value===props.defaultCurrency){
            //ask operation, receives default->foreign
            financialProduct=props.currency;
            financialProductColumn="ask";
          }
        }
        
        setLoading(true);
        exe("GetExchangeRate",{currency:financialProduct,date:props.currencyDate||new Date(),column:financialProductColumn}).then(r=> {
          setLoading(false);
          if (r.ok) {
            const exchangeRate = r.outData;
            //const amount=exchangeRate&&line.amountEx?round2(exchangeRate*line.amountEx):line.amount;
            if(updatedPaymentLines){
              //used in effect to update exchange rate of all lines
              updatedPaymentLines[index]={...line,...{[field]:value,exchangeRate:exchangeRate,amountEx:exchangeRate===0?0:round2(line.amount/exchangeRate)}};
            }else{
              //deafult behaviour
                setPaymentLines([...paymentLines.map((p,i)=>i===index?{...p,...{[field]:value,exchangeRate:exchangeRate,amountEx:exchangeRate===0?0:round2(p.amount/exchangeRate)}}:p)])
            }

          } else message.error(r.msg);
        })
          
      } else if(field==="amountEx"){
        //making conversion
        const line=paymentLines[index];
        const amount=line.exchangeRate?round2(line.exchangeRate*value):line.amount;
        setPaymentLines([...paymentLines.map((p,i)=>i===index?{...p,...{[field]:value,amount:amount}}:p)])
      } else if(field==="exchangeRate") {
        //manual exchange rate
        const line = paymentLines[index];
        const amount = value && line.amountEx ? round2(line.amountEx * value) : line.amount;
        setPaymentLines([...paymentLines.map((p, i) => i === index ? {...p, ...{[field]: value, amount: amount}} : p)])
      }else if(field==="paymentMethod") {
        setPaymentLines([...paymentLines.map((p,i)=>i===index?{...p,...{[field]:value,formId:formId,paymentMethodName:paymentMethodName}}:p)])
      }else if(field==="conversion"){
        setPaymentLines([...paymentLines.map((p,i)=>i===index?{...p,...{[field]:value,currency:undefined,exchangeRate:undefined,amountEx:undefined}}:p)])
      }
       else setPaymentLines([...paymentLines.map((p,i)=>i===index?{...p,...{[field]:value}}:p)])
  }
  
  const onAddLine=()=>{
    const defaultAmount=props.selectedAmount-paymentLines.reduce((p,c)=>p+c.amount,0);
      setPaymentLines([...paymentLines,{amount:defaultAmount>0?defaultAmount:undefined}]);
  }
  const onRemoveLine=(index)=>{
      setPaymentLines(paymentLines.filter((r,i)=>i!==index)) 
  }

return <div>
    {paymentLines.map((p,index)=><div><PaymentLine value={p} index={index} onChangeField={onChangeField} onAddLine={onAddLine} onRemoveLine={()=>onRemoveLine(index)} currency={props.currency}  /></div>)}
</div>
}

export default SplitPayment;


