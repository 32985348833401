import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import DefaultPage from "../Shared/DefaultPage";
import DebtStatusData from "./DebtStatusData";

const DebtStatus = () => {
    const [t, i18n] = useTranslation();
    const [contactId, setContactId] = useState(null);
    return (
        <div>
            <DefaultPage title={t("Debt Status")} icon="file-sync">
                <div style={{ marginLeft: 5, marginBottom: 5, width: 400, display: "flex", alignItems: "center" }}>
                    <span style={{ marginRight: 5 }}>{t("Debtor")}:</span>
                    <SimpleContactSelect value={contactId} onChange={(v) => setContactId(v)} />
                </div>
                <DebtStatusData contactId={contactId} />

            </DefaultPage>
        </div>
    );
};

export default DebtStatus;
