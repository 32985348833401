import React, { Component } from "react";
import "dmn-js/dist/assets/diagram-js.css";
import "dmn-js/dist/assets/dmn-js-shared.css";
import "dmn-js/dist/assets/dmn-js-drd.css";
import "dmn-js/dist/assets/dmn-js-decision-table.css";
import "dmn-js/dist/assets/dmn-js-decision-table-controls.css";
import "dmn-js/dist/assets/dmn-js-literal-expression.css";
import "dmn-js/dist/assets/dmn-font/css/dmn.css";

import {
  AppstoreOutlined,
  DeleteOutlined,
  FileOutlined,
  FolderOpenOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import { Menu, message, Modal, Select } from "antd";
import DmnJS from "dmn-js/dist/dmn-modeler.development";
import { exe } from "../../Lib/Dal";
import { withTranslation } from "react-i18next";

const initialXML = `
<?xml version="1.0" encoding="UTF-8"?>
<definitions xmlns="http://www.omg.org/spec/DMN/20151101/dmn.xsd" xmlns:biodi="http://bpmn.io/schema/dmn/biodi/1.0" id="insertName" name="My Bussiness Definitions" namespace="http://camunda.org/schema/1.0/dmn"><decision id="decisionId" name="Blank Decision Table"><extensionElements><biodi:bounds x="150" y="150" width="180" height="80" /></extensionElements><decisionTable id="DecisionTable_1rw2s7r"><input id="InputClause_1sdn58d"><inputExpression id="LiteralExpression_1iewjq9" typeRef="string" /></input><output id="OutputClause_1cyfi1v" typeRef="string" /></decisionTable></decision></definitions>
`;
let dmn; //instancia de modelador dmn

class Rules extends Component {
  componentDidMount() {
    dmn = new DmnJS({
      container: "#canvas",
      keyboard: {
        bindTo: window,
      },
    });
    window.dmnModeler = dmn; //temp, tests
    this.onNew();
  }
  currentRuleId = 0;
  onNew() {
    this._openXml(initialXML);
    this.currentRuleId = 0;
  }
  onSave() {
    dmn.saveXML({ format: true }, (err, xml) => {
      if (err) {
        message.error(err);
      } else {
        const data = { name: dmn.getViews()[0].element.id, xml: xml, id: this.currentRuleId };
        exe("AddOrUpdateRule", data, undefined, true).then((r) => {
          if (r.ok) this.currentRuleId = r.outData.id;
        });
      }
    });
  }
  onOpen() {
    exe("GetRules").then((r) => {
      const openModal = Modal.info({
        title: "Select File",
        maskClosable: true,
        content: (
          <Select
            style={{ width: "100%", marginTop: 5 }}
            placeholder={this.props.t("Please select file")}
            onSelect={(id) => {
              this._openXml(r.outData.find((p) => p.id == id).xml);
              openModal.destroy();
              this.currentRuleId = id;
            }}>
            {r.outData.map((p) => (
              <Select.Option key={p.id} value={p.id}>
                {p.name}
              </Select.Option>
            ))}
          </Select>
        ),
      });
    });
  }

  onCopy() {
    this.currentRuleId = 0;
    dmn.getViews()[0].element.id = "Copy of " + dmn.getViews()[0].element.id + +new Date();
    dmn._switchView(dmn.getViews()[0]); //refreshing
    this.onSave();
  }
  onDelete() {
    const me = this;
    Modal.confirm({
      title: this.props.t("Are you sure delete this file?"),
      content: this.props.t("This action cannot be undone"),
      okText: this.props.t("Yes"),
      okType: "danger",
      maskClosable: true,
      cancelText: this.props.t("No"),
      onOk() {
        if (me.currentRuleId == 0) {
          message.error(this.props.t("No file selected"));
        } else {
          exe("DeleteRule", { id: me.currentRuleId }, undefined, true);
          me.onNew();
        }
      },
    });
  }
  _openXml(xml) {
    dmn.importXML(xml, (err) => {
      if (err) {
        return console.error("could not import DMN 1.1 diagram", err);
      }
      var a = document.getElementsByClassName("bjs-powered-by")[0];
      if (a) a.parentNode.removeChild(a);

      var activeView = dmn.getActiveView();
      if (activeView.type === "drd") {
        var activeEditor = dmn.getActiveViewer();
        var canvas = activeEditor.get("canvas");
        canvas.zoom("fit-viewport");
      }
      dmn._switchView(dmn.getViews()[1]);
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div style={{ height: "100%" }}>
        <Menu style={{ marginTop: "-10px", marginBottom: "20px" }} mode="horizontal" selectable={false}>
          <Menu.SubMenu
            title={
              <span className="submenu-title-wrapper">
                <AppstoreOutlined />
                {t("File")}
              </span>
            }>
            <Menu.ItemGroup title={t("Actions")}>
              <Menu.Item key="nuevo" onClick={() => this.onNew()}>
                <FileOutlined />
                {t("New")}
              </Menu.Item>
              <Menu.Item key="cargar" onClick={() => this.onOpen()}>
                <FolderOpenOutlined />
                {t("Open")}
              </Menu.Item>
              <Menu.Item key="guardar" onClick={() => this.onSave()}>
                <SaveOutlined />
                {t("Save")}
              </Menu.Item>
              <Menu.Item key="guardarComo" onClick={() => this.onCopy()}>
                <SaveOutlined />
                {t("Make Copy")}
              </Menu.Item>
              <Menu.Item key="delete" onClick={() => this.onDelete()}>
                <DeleteOutlined />
                {t("Delete")}
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu.SubMenu>
        </Menu>
        <div style={{ height: "100%" }} id="canvas">
          <Modal></Modal>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Rules);
