import { ReloadOutlined } from '@ant-design/icons';
import {Button, Checkbox, message, Table, Tag} from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import {formatDate, formatDateShort, formatMoney, getColor} from "../../Lib/Helpers";
import ContactLink from "../Contact/ContactLink";
import DefaultPage from "../Shared/DefaultPage";
import CoCessionsFilter from "./CoCessionsFilter";
import CoLiquidation from "./CoLiquidation";
import Status from "../Shared/Status";

const CoLiquidations = (props) => {
    const [t] = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(()=>load(),[]);

const load=()=>{
    setLoading(true);
    exe("RepoCoLiquidation",{operation:"GET",include:["Process"]}).then(r=>{
        setLoading(false);
        if(r.ok){
            setData(r.outData);
        }else message.error(r.msg);
        
    })
}
const onExecute=record=>{
    setLoading(true);
    exe("DoCoLiquidation",{Liquidation:record}).then(r=>{
        setLoading(false);
        if(r.ok){
            message.success(r.msg);
            load();
        }else message.error(r.msg);
    })
}
    const getStatusText = (v,r) => {
        if (v == 1) {
            return <div style={{display:"flex",alignItems:"baseline"}}>
                <Tag>{t("Draft")}</Tag>
                <Button type={"link"} onClick={()=>onExecute(r)} icon={"check"}>{t("Execute")}</Button>
            </div>
                
        } else if (v == 0) {
            return <Tag color="green">{t("Executed")}</Tag>;
        }else if (v == 2) {
            return <Tag color="red">{t("Reverted")}</Tag>;}
        else {
            return <Tag>{v}</Tag>;
        }
    };

    return (
        <div>
            <DefaultPage
                title={t("Coinsurance Liquidations")}
                icon="dollar">
                <Button type="link" icon={<ReloadOutlined />} onClick={()=>load()}>
                    {t("Reload")}
                </Button>
                <Table dataSource={data} loading={loading}  rowKey="id">
                    <Table.Column title={t("Id")} dataIndex="id" />
                    <Table.Column title={t("Description")} dataIndex="description" />
                    <Table.Column title={t("Currency")} dataIndex="currency" />
                    <Table.Column title={t("Premium Ceded")} dataIndex="premiumCeded" />
                    <Table.Column title={t("Commission")} dataIndex="commission" />
                    <Table.Column title={t("Tax")} dataIndex="tax" />
                    <Table.Column title={t("Broker Commission")} dataIndex="brokerCommission" />
                    <Table.Column title={t("Loss")} dataIndex="claimsCeded" />
                    <Table.Column title={t("Total Amount")} dataIndex="amount" />
                    <Table.Column title={t("Created")} dataIndex="created" render={v=>formatDate(v)} />
                    <Table.Column title={t("Status")} dataIndex="status" render={(v,r)=>getStatusText(v,r)} />
                    <Table.Column
                        dataIndex="Process"
                        title={t("Workflow")}
                        render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load()} />}
                    />

                </Table>
            </DefaultPage>
        </div>
    );
};

export default CoLiquidations;
