import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import {useTranslation} from "react-i18next";

const ProviderTypeSelect = (props) => {
  const [data, setData] = useState([]);
  const [t]=useTranslation();

  useEffect(() => {
    if (data.length == 0) load();
  }, []);

  const load = () => {
    exe("RepoProviderTypeCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else message.error(r.msg);
    });
  };
  const onSelect = (opt) => {
    console.log(opt);
    if (props.onSelect) props.onSelect(opt.props.record.subType);
  };
  return (
    <div>
      <Select value={props.value} onChange={(v) => props.onChange(v)} onSelect={(v, opt) => onSelect(opt)} allowClear>
        {data.map((p) => (
          <Select.Option value={p.code} record={p}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ProviderTypeSelect;
