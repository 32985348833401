import React, { useState } from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DownOutlined } from '@ant-design/icons';
import { Menu, Dropdown, Button } from "antd";
import { useTranslation } from "react-i18next";

const allIcons = [
  "account-book",
  "alert",
  "alipay-circle",
  "aliwangwang",
  "android",
  "api",
  "appstore",
  "audio",
  "apple",
  "backward",
  "bank",
  "bell",
  "behance-square",
  "book",
  "box-plot",
  "bug",
  "bulb",
  "calculator",
  "build",
  "calendar",
  "camera",
  "car",
  "caret-down",
  "caret-left",
  "caret-right",
  "carry-out",
  "caret-up",
  "check-circle",
  "check-square",
  "chrome",
  "clock-circle",
  "close-circle",
  "cloud",
  "close-square",
  "code",
  "codepen-circle",
  "compass",
  "contacts",
  "container",
  "control",
  "copy",
  "credit-card",
  "crown",
  "customer-service",
  "dashboard",
  "delete",
  "diff",
  "database",
  "dislike",
  "down-circle",
  "down-square",
  "dribbble-square",
  "environment",
  "edit",
  "exclamation-circle",
  "experiment",
  "eye-invisible",
  "eye",
  "facebook",
  "fast-backward",
  "fast-forward",
  "file-add",
  "file-excel",
  "file-exclamation",
  "file-image",
  "file-markdown",
  "file-pdf",
  "file-ppt",
  "file-text",
  "file-unknown",
  "file-word",
  "file-zip",
  "file",
  "filter",
  "fire",
  "flag",
  "folder-add",
  "folder",
  "folder-open",
  "forward",
  "frown",
  "fund",
  "funnel-plot",
  "gift",
  "github",
  "gitlab",
  "hdd",
  "heart",
  "highlight",
  "home",
  "hourglass",
  "html5",
  "idcard",
  "info-circle",
  "instagram",
  "insurance",
  "interaction",
  "interation",
  "layout",
  "left-circle",
  "left-square",
  "like",
  "linkedin",
  "lock",
  "mail",
  "medicine-box",
  "meh",
  "message",
  "minus-circle",
  "minus-square",
  "mobile",
  "money-collect",
  "pause-circle",
  "pay-circle",
  "notification",
  "phone",
  "picture",
  "pie-chart",
  "play-circle",
  "play-square",
  "plus-circle",
  "plus-square",
  "printer",
  "profile",
  "project",
  "pushpin",
  "property-safety",
  "question-circle",
  "read",
  "reconciliation",
  "red-envelope",
  "rest",
  "right-circle",
  "rocket",
  "right-square",
  "safety-certificate",
  "save",
  "schedule",
  "security-scan",
  "setting",
  "shop",
  "shopping",
  "skin",
  "skype",
  "slack-square",
  "sliders",
  "smile",
  "snippets",
  "sound",
  "star",
  "step-backward",
  "step-forward",
  "stop",
  "switcher",
  "tablet",
  "tag",
  "tags",
  "taobao-circle",
  "tool",
  "thunderbolt",
  "trophy",
  "unlock",
  "up-circle",
  "up-square",
  "usb",
  "video-camera",
  "wallet",
  "warning",
  "wechat",
  "weibo-circle",
  "windows",
  "yahoo",
  "weibo-square",
  "yuque",
  "youtube",
  "alibaba",
  "align-center",
  "align-left",
  "align-right",
  "alipay",
  "aliyun",
  "amazon",
  "ant-cloud",
  "apartment",
  "ant-design",
  "area-chart",
  "arrow-left",
  "arrow-down",
  "arrow-up",
  "arrows-alt",
  "arrow-right",
  "audit",
  "bar-chart",
  "barcode",
  "bars",
  "behance",
  "bg-colors",
  "block",
  "bold",
  "border-bottom",
  "border-left",
  "border-outer",
  "border-inner",
  "border-right",
  "border-horizontal",
  "border-top",
  "border-verticle",
  "border",
  "branches",
  "check",
  "ci",
  "close",
  "cloud-download",
  "cloud-server",
  "cloud-sync",
  "cloud-upload",
  "cluster",
  "codepen",
  "code-sandbox",
  "colum-height",
  "column-width",
  "column-height",
  "coffee",
  "copyright",
  "dash",
  "deployment-unit",
  "desktop",
  "dingding",
  "disconnect",
  "dollar",
  "double-left",
  "dot-chart",
  "double-right",
  "down",
  "drag",
  "download",
  "dribbble",
  "dropbox",
  "ellipsis",
  "enter",
  "euro",
  "exception",
  "exclamation",
  "export",
  "fall",
  "file-done",
  "file-jpg",
  "file-protect",
  "file-sync",
  "file-search",
  "font-colors",
  "font-size",
  "fork",
  "form",
  "fullscreen-exit",
  "fullscreen",
  "gateway",
  "global",
  "google-plus",
  "gold",
  "google",
  "heat-map",
  "history",
  "ie",
  "import",
  "inbox",
  "info",
  "italic",
  "key",
  "issues-close",
  "laptop",
  "left",
  "line-chart",
  "link",
  "line-height",
  "line",
  "loading-3-quarters",
  "loading",
  "login",
  "logout",
  "man",
  "medium",
  "medium-workmark",
  "menu-unfold",
  "menu-fold",
  "menu",
  "minus",
  "monitor",
  "more",
  "ordered-list",
  "number",
  "pause",
  "percentage",
  "paper-clip",
  "pic-center",
  "pic-left",
  "pic-right",
  "plus",
  "pound",
  "poweroff",
  "pull-request",
  "qq",
  "question",
  "radar-chart",
  "qrcode",
  "radius-bottomleft",
  "radius-bottomright",
  "radius-upleft",
  "radius-setting",
  "radius-upright",
  "reddit",
  "redo",
  "reload",
  "retweet",
  "right",
  "rise",
  "rollback",
  "safety",
  "robot",
  "scan",
  "search",
  "scissor",
  "select",
  "shake",
  "share-alt",
  "shopping-cart",
  "shrink",
  "sketch",
  "slack",
  "small-dash",
  "solution",
  "sort-descending",
  "sort-ascending",
  "stock",
  "swap-left",
  "swap-right",
  "strikethrough",
  "swap",
  "sync",
  "table",
  "team",
  "taobao",
  "to-top",
  "trademark",
  "transaction",
  "twitter",
  "underline",
  "undo",
  "unordered-list",
  "up",
  "upload",
  "user-add",
  "user-delete",
  "usergroup-add",
  "user",
  "usergroup-delete",
  "vertical-align-bottom",
  "vertical-align-middle",
  "vertical-align-top",
  "vertical-left",
  "vertical-right",
  "weibo",
  "wifi",
  "zhihu",
  "woman",
  "zoom-out",
  "zoom-in",
];
const IconSelector = (props) => {
  const [t] = useTranslation();
  const menu = (
    <Menu style={{ height: 400, overflow: "auto" }}>
      {allIcons.map((p) => (
        <Menu.Item
          onClick={() => {
            if (props.onChange) props.onChange(p);
          }}>
          <LegacyIcon type={p} style={{ fontSize: 20 }} />
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div>
      <Dropdown overlay={menu} trigger={["click"]}>
        <Button type="link" onClick={() => null} style={{ paddingLeft: 0 }}>
          {t("Select Icon")} <DownOutlined />
        </Button>
      </Dropdown>
      <div>
        <LegacyIcon type={props.value} style={{ fontSize: 30 }} />
      </div>
    </div>
  );
};

export default IconSelector;
