import { Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ProcessingType = (props) => {
  const [t] = useTranslation();
  return (
    <Select value={props.value} style={{ width: "100%" }} disabled={props.disabled}>
      <Select.Option value={0}>{t("Cluster of workers")}</Select.Option>
      <Select.Option value={1}>{t("App Server")}</Select.Option>
      <Select.Option value={2}>{t("File")}</Select.Option>
    </Select>
  );
};

export default ProcessingType;
