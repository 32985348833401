import React, { useState, useEffect } from "react";
import { SaveOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Drawer, Button, message, Input } from "antd";
import { exe } from "../../Lib/Dal";
import Currency from "../Shared/Currency";
import Money from "../Shared/Money";
import AccountSelect from "../Shared/AccountSelect";
import YearMonth from "../Shared/YearMonth";
import { useTranslation } from "react-i18next";
import { round2 } from "../../Lib/Helpers";

const ComLiquidation = (props) => {
  const { getFieldDecorator } = props.form;
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (props.selected.length > 0) {
      const total = props.selected.reduce((p, c) => p + c.commission, 0);
      props.form.setFieldsValue({ commissions: round2(total), currency: props.selected[0].currency });
    }
  }, [props.selected]);

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      values.Commissions = props.selected;
      values.comContractId = props.contract.id;
      console.log(values, "FORM VALUES");
      exe("RepoComLiquidation", { operation: "ADD", entity: values }).then((r) => {
        if (r.ok) {
          message.success(r.msg);
          props.onClose(true);
        } else {
          message.error(r.msg);
        }
      });
    });
  };
  const currency = props.form.getFieldValue("currency");
  return (
    <div>
      <Drawer title={t("Commission Liquidation")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Form.Item label={t("Period")} style={{ marginLeft: 5 }}>
            {getFieldDecorator("yearMonth")(<YearMonth />)}
          </Form.Item>
          <Form.Item label={t("Liquidation name")}>{getFieldDecorator("liquidationName")(<Input />)}</Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Currency")}>{getFieldDecorator("currency")(<Currency />)}</Form.Item>
            <Form.Item label={t("Total Commissions")} style={{ marginLeft: 5 }}>
              {getFieldDecorator("commissions")(<Money currency={currency} disabled />)}
            </Form.Item>
          </div>
        </Form>
        <Button type="primary" icon={<SaveOutlined />} onClick={() => submit()}>
          {t("Save")}
        </Button>
      </Drawer>
    </div>
  );
};

export default Form.create()(ComLiquidation);
