import React, { useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { GlobalOutlined } from '@ant-design/icons';
import { Tabs, Avatar, Tag } from "antd";
import AccountMov from "../Financial/AccountMov";
import Title from "antd/lib/typography/Title";
import EvolutionSimulation from "../Transfers/EvolutionSimulation";
import { useTranslation } from "react-i18next";

const AccountSimulator = () => {
  const [t, i18n] = useTranslation();
  const [accounts, setAccounts] = useState([]);
  const [activeTab, setActiveTab] = useState("simulation");

  const onSimulation = (simulation) => {
    setAccounts(simulation.Accounts);
    setActiveTab("draftAllocation");
  };
  return (
    <div>
      <DefaultPage title={t("Account Simulator")} icon="experiment">
        <Tabs onChange={(tab) => setActiveTab(tab)} activeKey={activeTab}>
          <Tabs.TabPane tab={t("Parameters")} key="simulation">
            <EvolutionSimulation onSimulation={onSimulation} />
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Simulation")} key="draftAllocation" disabled={accounts.length === 0}>
            {accounts.map((p) => (
              <div>
                <Title level={4}>
                  <Avatar icon={<GlobalOutlined />} style={{ marginRight: 5 }} size="small" />
                  {p.name}
                  <Tag style={{ marginLeft: 5 }}>{p.InvestmentPlan ? p.InvestmentPlan.name : p.type}</Tag>
                </Title>
                <AccountMov value={p.Movements} currency={undefined} account={p} />
              </div>
            ))}
          </Tabs.TabPane>
        </Tabs>
      </DefaultPage>
    </div>
  );
};

export default AccountSimulator;
