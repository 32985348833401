import React, { Component } from "react";
import { BankOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Input, Select, message, Switch } from "antd";
import { exe } from "Lib/Dal";
import { withTranslation } from "react-i18next";
import DatePickerW from "../Shared/DatePickerW";
const { Option } = Select;

class NewContactModal extends Component {
  state = {
    isPerson: true,
  };
  handleOk = () => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        //validation OK
        values.isPerson = values.isPerson?1:0;
        console.log("Received values of form: ", values);
        this.setState({ loading: true });
        exe("AddOrUpdateContact", values).then((r) => {
          console.log(r);
          this.setState({ loading: false });
          if (r.ok) {
            message.success(r.msg);
            this.props.onClose(r.outData);
          } else {
            message.error(r.msg);
          }
        });
      } else {
        //validation error
      }
    });
  };
  handleCancel = () => {
    this.props.onClose();
  };

  render() {
    const { t, i18n } = this.props;
    const { getFieldDecorator } = this.props.form;
    const isPerson = this.state.isPerson;
    return (
      <Modal title={t("New Contact")} visible={this.props.visible} onOk={this.handleOk} onCancel={this.handleCancel}>
        <Form layout="vertical">
          <Form.Item label={t("Type")}>
            {getFieldDecorator("isPerson", { valuePropName: "checked", initialValue: true })(
              <Switch
                onChange={(checked) => this.setState({ isPerson: checked })}
                checkedChildren={<UserOutlined />}
                unCheckedChildren={<BankOutlined />}
              />
            )}
          </Form.Item>
          {isPerson && (
            <Form.Item label={t("First Name")}>
              {getFieldDecorator("name", {
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
          )}
          {isPerson && (
            <Form.Item label={t("Last Name")}>
              {getFieldDecorator("surname1", {
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
          )}
          <Form.Item label={isPerson ? t("Second Surname") : t("Company Name")}>
            {getFieldDecorator("surname2", { rules: [{ required: !isPerson, message: t("This field is required") }] })(<Input />)}
          </Form.Item>

          {isPerson && (
            <Form.Item label={t("ID / Passport")}>
              {getFieldDecorator("passport", {
                rules: [{ required: true }],
              })(<Input />)}
            </Form.Item>
          )}
          {isPerson && (
            <Form.Item label={t("Date of birth")}>
              {getFieldDecorator("birth", {
                rules: [{ required: true }],
              })(<DatePickerW />)}
            </Form.Item>
          )}
          {isPerson && (
            <Form.Item label={t("Gender")}>
              {getFieldDecorator("gender", {
                rules: [{ required: true }],
              })(
                <Select allowClear={true}>
                  <Option value="M">{t("Male")}</Option>
                  <Option value="F">{t("Female")}</Option>
                </Select>
              )}
            </Form.Item>
          )}
        </Form>
      </Modal>
    );
  }
}

const WrappedNewContactModal = Form.create()(NewContactModal);
export default withTranslation()(WrappedNewContactModal);
