import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const ProviderRequirementSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (props.providerCode) load(); 
  }, [props.providerCode]);

  const load = () => {
    setLoading(true);
    exe("RepoProviderRequirement", { operation: "GET", filter:`providerCode='${props.providerCode}'` }).then((r) => {
      setLoading(false);
      if (r.ok) setData(r.outData);
      else message.error(r.msg);
    });
  };
  return (
    <Select
      value={props.value}
      onChange={(v) => props.onChange(v)}
      loading={loading}
      style={{ width: 200, ...props.style }}
      placeholder={t("Please select requirement")}
      disabled={props.disabled}
      allowClear>
      {data.map((p) => (
        <Select.Option value={p.id} key={p.id}>
          {p.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProviderRequirementSelect;
