import { PlusOutlined } from '@ant-design/icons';
import { Button, Select,message } from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

const BenefitGroupSelect = (props) =>{
    const [t]=useTranslation();
    
 return props.value ? (
    <Select style={{ width: 120 }} value={props.value} onChange={(v) => props.onChange(v)} allowClear disabled={props.readOnly}>
      {props.groups.map((group) => (
        <Select.Option value={group.code}>{group.code}</Select.Option>
      ))}
    </Select>
  ) : (
    <Button type="link" icon={<PlusOutlined />} onClick={() => props.groups.length>0?props.onChange(props.groups[0].code):message.error(t("No groups configured in this product"))} disabled={props.readOnly} />
  );
}

export default BenefitGroupSelect;
