import React, { useState, useEffect } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Button,
  Input,
  Radio,
  Select,
  DatePicker,
  Tag,
  Switch,
  Checkbox,
  InputNumber,
} from "antd";
import { exe } from "../../Lib/Dal";
import LobSelect from "../Shared/LobSelect";
import moment from "moment";
import Compare from "../Shared/Compare";
import ProductSelect from "../Life/ProductSelect";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import {formatNumber} from "../../Lib/Helpers";
import DatePickerW from "../Shared/DatePickerW";

const TaxGeneratedFilter = (props) => {
  const [matches, setMatches] = useState(null);
  const [sum, setSum] = useState(null);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (props.filter) {
      props.form.setFieldsValue(props.filter);
    }
    if (window.location.hash.includes("?date=")) {
      const date = window.location.hash.split("?date=")[1];
      props.form.setFieldsValue({ date: moment(date) });
      submit();
    } else {
      submit();
    }
  }, []);

  const { getFieldDecorator } = props.form;

  const submit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      console.log(values, "FORM VALUES");
      let queries = [];
      if (values.date)
        queries.push(`YEAR(created)=${values.date.format("YYYY")} AND MONTH(created)=${values.date.format("M")} AND DAY(created)=${values.date.format("D")}`);
      if (values.month) queries.push(`YEAR(created)=${values.month.format("YYYY")} AND MONTH(created)=${values.month.format("M")}`);
      if (values.taxName) queries.push(`taxName='${values.taxName}'`);
      if (values.action) queries.push(`action='${values.action}'`);
      if (values.policyId) queries.push(`lifePolicyId=${values.policyId}`);
      if (values.id) queries.push(`id=${values.id}`);
      if (values.amount !== undefined) queries.push(`amount${values.amount}`);
      if (values.currency !== undefined) queries.push(`currency='${values.currency}'`);
      if (values.taxSchemeId !== undefined) queries.push(`taxSchemeId=${values.taxSchemeId}`);
      if(values.start){
        queries.push(`created>='${values.start.format("YYYY-MM-DD")}'`);
      }
      if(values.end){
        queries.push(`created<='${values.end.format("YYYY-MM-DD")}'`);
      }

      const filterQuery = queries.join(" AND ");
      console.log(queries, filterQuery);

      props.setLoading && props.setLoading(true);
      exe("RepoTaxGenerated", { operation: "GET", filter: filterQuery,grouped:values.grouped }).then((r) => {
        props.setLoading && props.setLoading(false);
        console.log(r);
        props.onResults(r.outData);
        setMatches(r.total);
        setSum(r.sum);
      });
    });
  };

  return (
    <div>
      <Drawer title={t("Tax Generated Filter")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Specific Day")}>{getFieldDecorator("date")(<DatePickerW />)}</Form.Item>
            <Form.Item label={t("Month")} style={{ marginLeft: 5 }}>
              {getFieldDecorator("month")(<DatePicker.MonthPicker />)}
            </Form.Item>
          </div>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Start")}>{getFieldDecorator("start")(<DatePickerW />)}</Form.Item>
            <Form.Item label={t("End")} style={{ marginLeft: 5 }}>
              {getFieldDecorator("end")(<DatePickerW />)}
            </Form.Item>
          </div>
          <Form.Item label={t("Tax Name")}>{getFieldDecorator("taxName")(<Input placeholder="Tax Name" />)}</Form.Item>
          <Form.Item label={t("Generating Action")}>{getFieldDecorator("action")(<Input placeholder="Action" />)}</Form.Item>

          <Form.Item label={t("Amount")}>{getFieldDecorator("amount")(<Compare />)}</Form.Item>
          <Form.Item label={t("Currency")} style={{ marginLeft: 5, width: "100%" }}>
            {getFieldDecorator("currency")(<Currency />)}
          </Form.Item>

          <div style={{ display: "flex" }}>
            <Form.Item label={t("ID")} style={{ marginRight: 15 }}>
              {getFieldDecorator("id")(<InputNumber />)}
            </Form.Item>
            <Form.Item label={t("Tax Scheme Id")} style={{ marginRight: 15 }}>
              {getFieldDecorator("taxSchemeId")(<InputNumber />)}
            </Form.Item>
          </div>
          <Form.Item label={t("Grouped Summary")} style={{ marginRight: 15 }}>
            {getFieldDecorator("grouped",{valuePropName:"checked"})(<Checkbox />)}
          </Form.Item>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => submit()}>
          {t("Search")}
        </Button>
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
        {sum !== null && (
            <div style={{ marginTop: 15 }}>
              <Tag>{formatNumber(sum)}</Tag> {t("Sum")}
            </div>
        )}
      </Drawer>
    </div>
  );
};

export default Form.create()(TaxGeneratedFilter);
