import React, { Component, useState, useEffect,useContext } from "react";
import { exe, safeGet, safeValue } from "../../Lib/Dal";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Select, Tooltip,ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";

const ProductSelect = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const configContext = useContext(ConfigProvider.ConfigContext);
  const direction = configContext.direction;

  useEffect(() => load(), []);

  useEffect(() => filterByLob(props.lobFilter), [props.lobFilter, props.excludedLobs]);

  const filterByLob = (lobCode) => {
    if (lobCode) {
      setData(allData.filter((p) => p.lobCode == lobCode));
    } else {
      if(props.excludedLobs){ //comes from a section with filtered lobs
        if (props.excludedLobs.length > 0) {
          setData(allData.filter((p) => !props.excludedLobs.includes(p.lobCode))); //excluding filtered lobs
        } else {
          setData(allData); //no filters
        }
      }else setData(allData);
    }
  };
  const load = () => {
    getCache("RepoProduct").then((r) => {
      r.outData = r.outData.map((p) => ({ ...p, configJson: JSON.parse(p.configJson) }));
      r.outData.forEach((element) => {
        try {
          if (element.configJson.Main.active) {
            element.disabled = !eval(element.configJson.Main.active);
            element.hidden = element.configJson.Main.hideIfInactive && element.disabled;
          }
        } catch (error) {
          element.disabled = true;
          element.hidden = safeValue("configJson.Main.hideIfInactive", element, false);
        }
      });
      setData(r.outData);
      setAllData(r.outData);
      if (props.lobFilter) filterByLob(props.lobFilter); //filtered before finished loading.
    });
  };
  const onProductChange = (v) => {
    props.onChange(v);
    if (props.onSelect) {
      props.onSelect(data.find((p) => (p.code == props.onlyValue ? v : v.key)));
    }
  };
  let ProductInfo = undefined;
  const productCode = props.value ? (props.onlyValue ? props.value : props.value.key) : undefined;
  if (props.value) {
    ProductInfo = (
      <Tooltip title={t("View product configuration")}>
        <a href={"#/products/" + productCode}>
          <InfoCircleOutlined
            style={{ position: "absolute", top: "8px", cursor: "pointer", 
            color: "rgba(0, 0, 0, 0.25)", fontSize: 16,...(direction=="rtl"?{left:"30px"}:{right:"30px"}) }} />
        </a>
      </Tooltip>
    );
  }
  return (
    <div style={{ position: "relative" }}>
      <Select
        labelInValue={!props.onlyValue}
        onChange={(v) => onProductChange(v)}
        value={props.value}
        style={props.style}
        placeholder={t("Please select product")}
        allowClear
        showSearch>
        {data
          .filter((p) => (props.all ? true : !p.hidden))
          .map((p) => (
            <Select.Option key={p.code} value={p.code} disabled={p.disabled & !props.all}>
              {p.name}
              {p.disabled && props.all ? " " + t("(Inactive)") : ""}
            </Select.Option>
          ))}
      </Select>
      {ProductInfo}
    </div>
  );
};

export default ProductSelect;
