import { DeleteOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Divider, message, Popconfirm, Table, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe, safeGet } from "../../Lib/Dal";
import { formatDate, formatMoney, getColor } from "../../Lib/Helpers";
import { formatter } from "../Shared/Money";
import ClaimSalvageDetail from "./ClaimSalvageDetail";

const ClaimSalvage = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailVisible, setDetailVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [t] = useTranslation();

  useEffect(() => {
    if (props.claim) load(props.claim.id);
  }, [props.claim]);

  const load = (claimId) => {
    setLoading(true);
    exe("RepoSalvage", { operation: "GET", filter: `claimId=${claimId}` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
        if (props.count) props.count(r.outData.filter((p) => !p.closed).length);
      } else message.error(r.msg);
    });
  };
  const onNewSalvage = () => {
    setDetailVisible(true);
  };
  const saveSalvage = (values) => {
    const operation = values.id ? "UPDATE" : "ADD";
    values.claimId = props.claim.id;
    setLoading(true);
    exe("RepoSalvage", { operation: operation, entity: values }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.claim.id);
        setDetailVisible(false);
      } else message.error(r.msg);
    });
  };
  const onDelete = (issueId) => {
    setLoading(true);
    exe("RepoSalvage", { operation: "DELETE", entity: { id: issueId } }).then((r) => {
      setLoading(false);
      if (r.ok) {
        load(props.claim.id);
      } else message.error(r.msg);
    });
  };
  const onEdit = (record) => {
    setDetailVisible(true);
    setSelectedRecord(record);
  };
  return (
    <div>
      <Button type="link" icon={<PlusOutlined />} onClick={onNewSalvage}>
        {t("New Salvage")}
      </Button>
      <Button type="link" icon={<ReloadOutlined />} onClick={() => load(props.claim.id)}>
        {t("Reload")}
      </Button>
      <Table dataSource={data} loading={loading}>
        <Table.Column dataIndex="id" title={t("Id")} />
        <Table.Column dataIndex="type" title={t("Type")} render={(v) => (v ? t("Recovery") : t("Salvage"))} />
        <Table.Column dataIndex="coverageId" title={t("Coverage")} />
        <Table.Column dataIndex="start" title={t("Start")} render={(v) => formatDate(v)} />
        <Table.Column dataIndex="income" title={t("Income")} render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column dataIndex="expenses" title={t("Expense")} render={(v, r) => formatMoney(v, r.currency)} />
        <Table.Column dataIndex="netIncome" title={t("Net Income")} render={(v, r) => formatMoney(v, r.currency)} />

        <Table.Column
          key="actions"
          title={t("Actions")}
          render={(v, r) => (
            <div>
              <Popconfirm title={t("Are you sure you want to delete this record?")} onConfirm={() => onDelete(r.id)}>
                <Button type="link" icon={<DeleteOutlined />} />
              </Popconfirm>
              <Divider type="vertical" />
              <Button type="link" icon={<EditOutlined />} onClick={() => onEdit(r)} />
            </div>
          )}
        />
      </Table>
      <ClaimSalvageDetail
        claim={props.claim}
        visible={detailVisible}
        onCancel={() => setDetailVisible(false)}
        onOk={(v) => saveSalvage(v)}
        selected={selectedRecord}
      />
    </div>
  );
};

export default ClaimSalvage;
