import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Popconfirm, Row, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { SaveOutlined, LeftOutlined, DeleteOutlined } from '@ant-design/icons';
import { exe } from "../../Lib/Dal";
import DatePickerW from "../Shared/DatePickerW";
import Spreadsheet from "../Shared/Spreadsheet";


const BulkTransactionRecord = (props) => {
    const [loading,setLoading]=useState(false);
    const [lines,setLines]=useState([]);
    const [spreadsheet,setSpreadsheet]=useState(null);
    const [t] = useTranslation();
    const [form]=Form.useForm();

    useEffect(()=>{
        if(props.value){
            if(props.value.id==0){
                form.resetFields();
                form.setFieldValue("contactId",props.contactId);
            }else {
                form.setFieldsValue(props.value);
                const parsedData = JSON.parse(props.value.jTransactions);
                setLines(parsedData);
            }
        }
    }
    ,[props.value])

    const save=()=>{
        form.validateFields().then(values=>{
            const parsedData = spreadsheet
            .getData()
            .filter((p) => p[0] !== "" && p[1] !== "")
            .map((p) => ({ 
              ref: p[0], 
              account: p[1], 
              amount: p[2],
              comments: p[3],
               }));

               values.jTransactions=JSON.stringify(parsedData);
            console.log(values);
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoBulkTransactions",{operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    props.onSave(values);
                    props.onCancel();

                }else{
                    message.error(r.msg);
                }
            })

        })
    }
    const onDelete=()=>{
        setLoading(true);
        exe("RepoBulkTransactions",{operation:"DELETE",entity:props.value}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                props.onSave(props.value);
                props.onCancel();
            }else message.error(r.msg);
        })
    }

    return <div>
        <Button type="link" icon={<LeftOutlined />} onClick={props.onCancel}>{t("Back")}</Button>
        <Button type="link" icon={<SaveOutlined />} onClick={save} loading={loading}>{t("Save")}</Button>
        <Popconfirm  title={t("Are you sure you want to delete this record?")} onConfirm={onDelete} disabled={!props.value||!props.value.id}><Button type="link" icon={<DeleteOutlined />}  disabled={!props.value||!props.value.id} >{t("Delete")}</Button></Popconfirm> 
        <Form form={form} layout="vertical">
            <Form.Item label={t("id")} name={"id"} hidden><Input disabled/></Form.Item>
            <Space>
                <Form.Item label={t("Name")} name={"name"} rules={[{required:true}]}><Input /></Form.Item>
                <Form.Item label={t("Transaction Date")} name={"transactionDate"} rules={[{required:true}]}><DatePickerW /></Form.Item>
            </Space>
        </Form>

        <Spreadsheet
              instance={(t) => {window.spreadsheet=t;setSpreadsheet(t);}}
              data={lines.map(p=>[p.ref,p.account,p.amount,p.comments])}
              showFileImport
              columns={[
                { title: t("Ref"), width: 200,align:'left'},
                { title: t("Account"), width: 200,align:'left' },
                { title: t("Amount"), width: 200,align:'right' },
                { title: t("Comments"), width: 200,align:'right' },
              ]}
              
          />
    </div>
}
export default BulkTransactionRecord;