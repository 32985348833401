import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const AreaServedSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => load(), []);

  const load = () => {
    setLoading(true);
    exe("RepoAreaServedCatalog", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <Select
        mode="multiple"
        showArrow
        placeholder={t("Please select areas served")}
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        showSearch
        optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.code} key={p.code}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default AreaServedSelect;
