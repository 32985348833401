import React, { useState, useEffect } from "react";
import { exe } from "../../Lib/Dal";
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Select, Spin, Empty, Tooltip, Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import Contact from "../Contact";

const SimpleContactSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [keyLabel, setKeyLabel] = useState(undefined);
  const [newContactVisible,setNewContactVisible]=useState(false);

  useEffect(() => {
    if (props.value) findContact(props.value);
    else setKeyLabel(undefined);
  }, [props.value]);

  const onSearchContact = (value) => {
    if (!value) return;
    const searchNif= props.searchNif?` OR nif LIKE N'%${value}%'`:"";
    setFetching(true);
    let query;
    if(isNaN(value)){
      query = `(RTRIM(ISNULL([name],''))+' '+RTRIM(ISNULL(surname1,''))+' '+RTRIM(ISNULL(surname2,''))) like N'%${value}%'`;
    }else{
      query=`cnp LIKE N'%${value}%'${searchNif}`;
    }
    exe("GetContacts", {
      filter: (props.filter || "") + `(${query})`,
      size:10
    }).then((r) => {
      setFetching(false);
      const contacts = (r.outData||[]).map((contact) => ({
        text: isNaN(value) ? `${contact.FullName}${props.searchNif&&contact.nif?" ["+contact.nif+"]":""}` : `${contact.FullName} (${contact.cnp})${props.searchNif&&contact.nif?" ["+contact.nif+"]":""}`,
        value: +contact.id,
        contact: contact,
      }));
      setData(contacts);
    });
  };
  const findContact = (id) => {
    if (!id) return;
    const cache = data.find((p) => p.value == id);
    if (cache) {
      setKeyLabel({ key: id, label: cache.text });
      return;
    }

    setFetching(true);
    exe("GetContacts", { filter: "ID=" + id }).then((r) => {
      setFetching(false);
      const contact = r.outData[0];
      setKeyLabel({ key: id, label: `${contact.FullName}` });
      if(props.forceSelect) props.onSelectContact(contact);
    });
  };

  const onSelectContact = (v, opt) => {
    if (props.onSelectContact) props.onSelectContact(opt.props.contact);
  };
  const onCloseNewContact=(contact)=> {
    if (contact&&contact.id) {
      props.onChange &&props.onChange(contact.id);
      if (props.onSelectContact) props.onSelectContact(contact);
    }
    setNewContactVisible(false)
  }
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div style={{ width: "100%", position: props.showLink ? "relative" : "static" ,...props.style}}>
      <Select
        value={keyLabel}
        showSearch
        labelInValue
        allowClear
        disabled={props.disabled}
        showArrow={false}
        placeholder={t("Type to search contact...")}
        notFoundContent={fetching ? <Spin size="small" /> : <Empty />}
        filterOption={false}
        onSearch={onSearchContact}
        onChange={(v) => props.onChange && (v ? props.onChange(v.key) : props.onChange(undefined))}
        onSelect={(v, opt) => onSelectContact(v, opt)}
        style={{ width: "100%" }}>
        {data.map((d) => (
          <Select.Option key={d.value} contact={d.contact}>
            <Tooltip title={d.text}> {d.text}</Tooltip>
          </Select.Option>
        ))}
      </Select>
      {props.allowNew&&<Tooltip title={t("New Contact")}>
        <PlusOutlined
          style={{ position: "absolute", right: "5px", top: "8px", cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }}
          onClick={() => props.newInTab?openInNewTab("#/Contact/0"):setNewContactVisible(true)} />
      </Tooltip>}
      {props.showLink && (
        <Tooltip title={t("Open contact")}>
          <UserOutlined
            style={{ position: "absolute", right: "30px", top: "8px", cursor: "pointer", color: "rgba(0, 0, 0, 0.25)", fontSize: 16 }}
            onClick={() => (window.location.hash = "#/contact/" + keyLabel.key)} />
        </Tooltip>
      )}
      {props.allowNew&&<Modal visible={newContactVisible} width={"90%"} onCancel={(newContact) => onCloseNewContact(newContact)} footer={[
        <Button  onClick={() => onCloseNewContact()}>
          {t("Close")}
        </Button>,
      ]}>
        <Contact match={{params:{contactId:0}}} configProfile={props.configProfile} modal onAdd={(newContact) => onCloseNewContact(newContact)}/>
      </Modal>}
    </div>
  );
};

export default SimpleContactSelect;
