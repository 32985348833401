import React, { Component } from "react";
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Empty, Button, Modal, Badge, Dropdown, Select, Popover } from "antd";
import ManualExclusion from "./ManualExclusion";
import Text from "antd/lib/typography/Text";
import { withTranslation } from "react-i18next";
const { Column, ColumnGroup } = Table;

class Exclusions extends Component {
  state = {
    selectedKeys: [],
    editRecord:undefined
  };
  onAdd() {
    this.setState({ addVisible: false });
  }
  onSelect(key) {
    this.setState({ selectedKeys: [...this.state.selectedKeys, key] });
  }
  onDeselect(key) {
    this.setState({ selectedKeys: this.state.selectedKeys.filter((p) => p !== key) });
  }
  onChange(e, selectableData, policyData) {
    //reporting to ext4ernal form
    const newData = [...policyData, ...selectableData.filter((p) => e.includes(p.code))];
    this.props.onChange && this.props.onChange(newData); //dbdata and currentl selected (dirty)
  }
  onRemove(record, policyData) {
    if (record.id) {
      //mark for deletion
      const p = policyData.find((p) => p.id == record.id);
      p.id = p.id * -1;
      this.props.onChange(policyData);
    } else {
      this.props.onChange(policyData.filter((q) => q.code !== record.code));
      this.setState({ selectedKeys: this.state.selectedKeys.filter((p) => p !== record.code) });
    }
  }
  onEdit(record,policyData){
    this.setState({editRecord:record,manualExclusionVisible:true});
    
  }
  onModalOk(values,policyData){
    if(values.code){
      //EXCLUSION EDITION
      const newData=policyData.map(p=>p.code==values.code?values:p);
      this.props.onChange(newData);
      this.setState({ manualExclusionVisible: false,editRecord:undefined });
    }else{
      //NEW MANUAL EXCLUSION
      this.props.onChange([
        ...policyData,
        {
          id:0,
          code: "MAN" + policyData.filter((p) => p.mandatory == 2).length,
          mandatory: 2,
          name: values.name,
          coverageCode: values.coverageCode,
          description: values.description,
          rule: values.rule,
        },
      ]);
      this.setState({ manualExclusionVisible: false });
    }
  }
  render() {
    const { t, i18n } = this.props;
    const configData = this.props.config ? (this.props.config.Exclusions ? this.props.config.Exclusions : []) : [];
    let policyData = this.props.value || []; //passed by DB or by initial form value or props (updated onchange)
    const selectableData = configData.filter((p) => !p.mandatory && !policyData.map((q) => q.code).includes(p.code)); //optional and not already included
    return (
      <div style={{ marginTop: 5, marginBottom: 5 }}>
        {!this.props.readOnly && (
          <div>
            <Popover
              trigger="click"
              title={t("Add Exclusion")}
              placement="right"
              content={
                <div>
                  <Select
                    mode="multiple"
                    showArrow
                    style={{ width: "100%" }}
                    placeholder={t("Please select")}
                    dropdownMatchSelectWidth={false}
                    optionLabelProp="value"
                    value={this.state.selectedKeys}
                    onChange={(e) => this.onChange(e, selectableData, policyData)}
                    onSelect={(key) => this.onSelect(key)}
                    onDeselect={(key) => this.onDeselect(key)}>
                    {selectableData.map((p) => (
                      <Select.Option value={p.code} key={p.code}>
                        <div>
                          {p.code} {p.name}
                        </div>
                        <div>{p.text}</div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              }>
              <Button type="primary" icon={<PlusOutlined />} style={{ marginBottom: 5 }}>
                {t("Add")}
              </Button>
            </Popover>
            <Button type="link" icon={<EllipsisOutlined />} onClick={() => this.setState({ manualExclusionVisible: true })}>
              {t("Manual")}
            </Button>
            <ManualExclusion
              visible={this.state.manualExclusionVisible}
              onCancel={() => this.setState({ manualExclusionVisible: false })}
              coverages={this.props.coverages}
              editRecord={this.state.editRecord}
              okOk={(values) => this.onModalOk(values,policyData)}
            />
          </div>
        )}
        <Table rowKey="code" dataSource={policyData} scroll={{ x: true }}>
          <Column title={t("Code")} dataIndex="code" />
          <Column title={t("Coverage")} dataIndex="coverageCode" render={(v) => (v ? <Text code>{v}</Text> : t("ALL"))} />
          <Column title={t("Name")} dataIndex="name" render={(v,r)=>r.id&&r.id<0?<Text delete>{v}</Text>:v} />
          <Column title={t("Description")} dataIndex="description" />
          <Column title={t("Automatic")} dataIndex="mandatory" render={(t) => <LegacyIcon type={t == 1 ? "lock" : t == 2 ? "user" : "unlock"} />} />
          <Column title={t("Rule")} dataIndex="rule" />
          <Column title={t("Status")} dataIndex="id" render={(t) => <Badge status={t > 0 ? "success" : t < 0 ? "error" : "warning"} />} />
          <Column
            title={t("Action")}
            fixed="right"
            render={(p, record) => (
              <div>
                <Button
                type="link"
                icon={<DeleteOutlined />}
                disabled={!this.props.allowRemove && ( this.props.readOnly)}
                onClick={(r) => this.onRemove(record, policyData)}>
                {t("Remove")}
              </Button>
                <Button
                    type="link"
                    icon={<EditOutlined />}
                    disabled={this.props.readOnly}
                    onClick={(r) => this.onEdit(record, policyData)}>
                  {t("Edit")}
                </Button>
              </div>
            )}
          />
        </Table>
      </div>
    );
  }
}

export default withTranslation()(Exclusions);
