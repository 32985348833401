import React, { useEffect, useState } from 'react';
import DefaultPage from '../Shared/DefaultPage';
import { useTranslation } from 'react-i18next';
import { exe, safeGet } from '../../Lib/Dal';
import { Button, DatePicker, Form, InputNumber, Modal, Space, Table, Tooltip, message } from 'antd';
import { formatDateShortUtc, formatMoney, getConfigProfile } from '../../Lib/Helpers';
import { ReloadOutlined, FilterOutlined, UndoOutlined, SearchOutlined, FolderOpenOutlined,LikeOutlined,DislikeOutlined } from '@ant-design/icons';
import { Drawer,Input } from 'antd';
import { Tag } from 'antd';
import PaymentMethods from './../Life/PaymentMethods';
import DatePickerW from './../Shared/DatePickerW';
import BranchSelect from './BranchSelect';
import UserSelect from '../Bpm/UserSelect';
import IncomeTransfer from './IncomeTransfer';
import IncomeTypeSelect from '../Transfers/IncomeTypeSelect';
import CustomForm from '../Shared/CustomForm';
import DisputeStatusSelect from './DisputeStatusSelect';
import Currency from '../Shared/Currency';
import moment from 'moment';
import useUpdateEffect from "../../Lib/Helpers";

const PaymentMonitor = props =>{
    const [data,setData]=useState([]);
    const [pagination, setPagination] = useState({ pageSize: 10, current: 1, total: 0, showTotal: (total) => `Total ${total} items` });
    const [loading,setLoading]=useState(false);
    const [filterVisible,setFilterVisible]=useState(false);
    const [matches, setMatches] = useState(null);
    const [customForm,setCustomForm]=useState(null);
    const [disputeRecord,setDisputeRecord]=useState(null);
    const [defaultPaymentMethod,setDefaultPaymentMethod]=useState(undefined);
    const [form]=Form.useForm();
    const [formDispute]=Form.useForm();
    const [t, i18n] = useTranslation();
    

    useUpdateEffect(()=>{load()},[pagination.current]);

    useEffect(()=>{
      getConfigProfile().then((profile) => {
        const dpm=safeGet(["PaymentMonitor", "defaultPaymentMethod"], profile, undefined);
        if(dpm){
          setDefaultPaymentMethod(dpm);
          form.setFieldsValue({paymentMethodCode:dpm});
        }
        load();
    });
    },[]);

    const load=()=>{
        form.validateFields().then(values=>{
            console.log(values);
            setLoading(true);
            exe("GetPaymentMonitor",{size:pagination.pageSize,page:pagination.current-1,...values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    setData(r.outData)
                    setMatches(r.total);
                    setPagination({ ...pagination,page:1, total: r.total});
                }else message.error(r.msg);
        })});
    }
    const onEndDispute=r=>{
      setLoading(true);
      exe("EndPaymentDispute",{paymentId:r.id}).then(r=>{
        setLoading(false);
        if(r.ok){
          message.success(r.msg);
          load();
        }else message.error(r.msg);
      })
    }
const onSubmitDisputeForm=()=>{
  formDispute.validateFields().then(values=>{
    exe("StartPaymentDispute",{paymentId:disputeRecord.id,disputeStart:values.disputeStart}).then(r=>{
      if(r.ok){
        message.success(r.msg);
        setDisputeRecord(null);
        load();
      }else message.error(r.msg);
    })
  })
}
return <div>
     <DefaultPage title={t("Payment Monitor")} icon="monitor" extra={
        <div>
          <Button type="primary" icon={<FilterOutlined />} onClick={() => setFilterVisible(true)}>
            {t("Filter")}
          </Button>
        </div>
      }>
        <Button type="link" icon={<ReloadOutlined />} onClick={()=>load()}>{t("Refresh")}</Button>
        <Table dataSource={data} pagination={pagination} onChange={(pag) => setPagination(pag)} rowKey={"id"}>
            <Table.Column title={t("Id")} dataIndex="id" />
            <Table.Column title={t("Workspace Id")} dataIndex="workspaceId" />
            <Table.Column title={t("Movement Id")} dataIndex="transferId" />
            <Table.Column title={t("User")} dataIndex="user"  />
            <Table.Column title={t("Branch")} dataIndex="workspaceBranch" />
            <Table.Column title={t("Workspace Date")} dataIndex="workspaceDate" render={v=>formatDateShortUtc(v)}  />
            <Table.Column title={t("Income Date")} dataIndex="date" render={v=>formatDateShortUtc(v)} />
            <Table.Column title={t("Income Type")} dataIndex="incomeType" />
            <Table.Column title={t("Payment Method")} dataIndex="paymentMethodName" />
            <Table.Column title={t("Dispute Start")} dataIndex="disputeStart" render={v=>v?formatDateShortUtc(v):null} />
            <Table.Column title={t("Dispute End")} dataIndex="disputeEnd" render={v=>v?formatDateShortUtc(v):null}/>
            <Table.Column title={t("Amount")} dataIndex="amount" render={(v,r)=>formatMoney(v,r.currency,true)} />
            <Table.Column title={t("Currency")} dataIndex="currency" />
            <Table.Column title={t("Actions")} key="actions" render={(v,r)=><div style={{whiteSpace:"nowrap"}}>
              <Tooltip title={t("Open Detail")}><Button type="link" icon={<FolderOpenOutlined />} disabled={!r.jValues} onClick={()=>setCustomForm({value:r.jValues,formId:r.formId})}/></Tooltip>
              {!r.disputeStart&&<Tooltip title={t("Start Dispute")}><Button type="link" icon={<DislikeOutlined />} disabled={r.disputeStart} onClick={()=>setDisputeRecord(r)}/></Tooltip>}
              {(!r.disputeEnd&&r.disputeStart)&&<Tooltip title={t("End Dispute")}><Button type="link" icon={<LikeOutlined />} disabled={r.disputeEnd||!r.disputeStart} onClick={()=>onEndDispute(r)}/></Tooltip>}
            </div>} />
        </Table>
        <Modal title={t("Dispute")} visible={disputeRecord} onCancel={()=>setDisputeRecord(null)} onOk={onSubmitDisputeForm} destroyOnClose>
          <Form form={formDispute} layout='vertical'>
            <Form.Item label={t("Dispute Start Date")} name="disputeStart" initialValue={new moment()} rules={[{required:true}]}><DatePickerW /></Form.Item>
          </Form>
        </Modal>
      </DefaultPage>
    
      {/* ---------------------------FILTER-------------------------- */}
      <Drawer title={t("Filter Search")} placement="right" width={512} onClose={()=>setFilterVisible(false)} visible={filterVisible} forceRender>
        <div style={{marginTop:-12,display:"flex",justifyContent:"flex-end" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>
        <Form layout="vertical" form={form}>
            <Space>
                <Form.Item label={t("Id")} name={"id"} ><InputNumber /></Form.Item>
                <Form.Item label={t("Workspace Id")} name={"workspaceId"} ><InputNumber /></Form.Item>
                <Form.Item label={t("Movement Id")} name={"transferId"} ><InputNumber /></Form.Item>
            </Space>
            <Space>
                <Form.Item label={t("Workspace Date")} name={"workspaceDate"} ><DatePicker.RangePicker /></Form.Item>
                <Form.Item label={t("Income Date")} name={"date"} ><DatePicker.RangePicker /></Form.Item>
            </Space>
            <Space>
                <Form.Item label={t("Dispute Start")} name={"disputeStart"} ><DatePicker.RangePicker /></Form.Item>
                <Form.Item label={t("Dispute End")} name={"disputeEnd"} ><DatePicker.RangePicker /></Form.Item>
            </Space>
            <Space>
                <Form.Item label={t("Amount Min")} name={"amountMin"} ><InputNumber style={{width:"100%"}} /></Form.Item>
                <Form.Item label={t("Amount Max")} name={"amountMax"} ><InputNumber style={{width:"100%"}} /></Form.Item>
            </Space>
            <Form.Item label={t("Currency")} name={"currency"} ><Currency style={{width:"100%"}} /></Form.Item>
            <Form.Item label={t("Payment Method")} name={"paymentMethodCode"} ><PaymentMethods module="INCOME" /></Form.Item>
            <Form.Item label={t("Income Type")} name={"incomeTypeCode"} ><IncomeTypeSelect /></Form.Item>
            <Form.Item label={t("User")} name={"user"} ><UserSelect /></Form.Item>
            <Form.Item label={t("Branch")} name={"workspaceBranchCode"} ><BranchSelect /></Form.Item>
            <Form.Item label={t("Dispute Status")} name={"disputeStatus"} ><DisputeStatusSelect /></Form.Item>
        </Form>
        <Button style={{marginTop:10}} type="primary" icon={<SearchOutlined />} onClick={() => load()} loading={loading}>
          {t("Search")}
        </Button>
        
        {matches !== null && (
          <div style={{ marginTop: 15 }}>
            <Tag>{matches}</Tag> {t("Results")}
          </div>
        )}
      </Drawer>
      <Modal title={t("Detail")} visible={customForm} onCancel={()=>setCustomForm(null)} footer={null} destroyOnClose>
        {customForm && <CustomForm value={customForm.value} formId={customForm.formId} variable="pMonitorCustomForm" />}
        </Modal>
</div>
}
export default PaymentMonitor;