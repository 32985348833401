import { Button, Modal, Transfer, message,ConfigProvider, Select } from "antd";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import { setPaddingLeft } from "../../Lib/Helpers";
import { PlusCircleOutlined } from '@ant-design/icons';
import DistributionSchemeSelect from "./DistributionSchemeSelect";

const FamilyDistribution = (props) => {
    const [data, setData] = useState([]);
    const [targetKeys, setTarketKeys] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [distributionScheme,setDistributionScheme]=useState();
    const [loading,setLoading]=useState(false);
    const [t]=useTranslation();
    const configContext = useContext(ConfigProvider.ConfigContext);
    const direction = configContext.direction;
    
    useEffect(() => loadRelationships(), []);

    const setPaddingLeft=(value)=>{
        return direction=="rtl"?{paddingRight:value}:{paddingLeft:value};
    }

    const loadRelationships = () => {
        exe("RepoRelationshipCatalog", { operation: "GET",filter:`principalType='BENEFICIARY'` }).then((r) => {
          if (r.ok) setData(r.outData);
          else message.error(r.msg);
        });
      }
    const onOk=()=>{
        //checking if a distribution scheme is selected
        if(distributionScheme){
            //executing distribution scheme
            const context={
                distributionSchemeCode:distributionScheme.code,
                ben:targetKeys,
            }
            setLoading(true);
            exe("ExeFormula",{formula:distributionScheme.formula,context:JSON.stringify(context)}).then(r=>{
                setLoading(false);
                if(r.ok){
                    if(r.outData && r.outData.length>0){
                        props.onOk(r.outData&&r.outData[0])
                    }else{
                        message.error(t("The distribution scheme did not return any result"));
                        props.onOk([]);
                    }
                }else{
                    message.error(r.msg);
                }
            }
            )
            
        }else{
            props.onOk(targetKeys.map(k=>({relationshipId:k})));
        }

        setModalVisible(false);
        
      }
    return <div>
        <Button type="link" style={setPaddingLeft(0)} icon={<PlusCircleOutlined />}  onClick={()=>setModalVisible(true)}>{t("Family Distribution")}</Button>
        <Modal title={t("Family Distribution")} visible={modalVisible} onOk={onOk} onCancel={()=>setModalVisible(false)}>
            {t("Distribution Scheme")}:&nbsp;<DistributionSchemeSelect onChange={(v,opt)=>setDistributionScheme(v?opt.record:undefined)} />
            
            <Transfer
              dataSource={data}
              style={{marginTop:10}}
              titles={[t("Exclude"), t("Include")]}
              rowKey={(record) => record.id}
              onChange={(nextTargetKeys, direction, moveKeys) =>setTarketKeys(nextTargetKeys)}
              targetKeys={targetKeys}
              render={(item) => `${item.name}`}
              listStyle={{
                width: 300,
                height: 300,
              }}
            />
            <div>
            </div>
            </Modal>

    </div>
}
 
export default FamilyDistribution;