import React, { useState, useEffect } from "react";
import { Select, Tooltip, Divider } from "antd";
import { useTranslation } from "react-i18next";
import getCache from "../../Lib/Cache";

//used inside forms = props = fn onChange, string value
const IdTypeSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();
  const [filter, setFilter] = useState();

  useEffect(() => load(), []);

  useEffect(() => {
    if (props.personType){
      //filtering
      setFilter(props.personType);
    }else setFilter();
  }, [props.personType]);

  const load = () => {
    setLoading(true);
    getCache("RepoIdTypeCatalog").then((r) => {
      setLoading(false);
      if (r.ok) setData(r.outData);
    });
  };

  return (
    <div style={{ position: "relative" }}>
      <Select
        style={{ width: "100%" }}
        onChange={props.onChange}
        value={props.value}
        showSearch
        loading={loading}
        allowClear
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        showArrow={true}
        placeholder={t("Please select Id Type")}>
        {data.filter(p=>filter?(p.personType==filter||!p.personType):true).map((d) => (
          <Select.Option key={d.code} value={d.code}>
            {t(d.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default IdTypeSelect;
