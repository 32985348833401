import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import CountrySelect from "../Shared/CountrySelect";

const LocationSelect = (props) => {
  const [t, i18n] = useTranslation();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [sectors, setSectors] = useState([]);

  const hasSector = props.hasSector;

  useEffect(() => {
    if (props.value && (states.length == 0 || cities.length == 0 || sectors.length == 0)) {
      loadStates(location.country);
      loadCities(location.state);
      loadSectors(location.city);
    }
    //zip field sync
    props.onSectorChange(location.sector);
  }, [props.value]);

  useEffect(() => {
    //if only one state is available, select it
    if (states.length == 1) {
      onChange("state", states[0].code);
    }

  }, [states]);
  useEffect(() => {
    //if only one city is available, select it
    if (cities.length == 1) {
      onChange("city", hasSector ? cities[0].code : cities[0].id);
    }
  }, [cities]);
  useEffect(() => {
    //if only one sector is available, select it
    if (sectors.length == 1) {
      onChange("sector", sectors[0].id);
    }
  }, [sectors]);

  useEffect(() => {
    if(props.sector){
      //selecting the sector according to the zip
      exe("RepoSectorCatalog", { operation: "GET", filter: `id=${props.sector}` }).then((r) => {
        if (r.ok&&r.outData.length>0) {
          setSectors(r.outData);
        }}
      );
    }
  },[props.sector]);

  const loadStates = (country) => {
    if (country) {
      exe("RepoStateCatalog", { operation: "GET", filter: "countryCode='" + country + "'" }).then((r) => {
        if (r.ok){
          //only setiing the new array if it is different, to avoid infinite loop in useEffect
          if(JSON.stringify(states)!=JSON.stringify(r.outData)) setStates(r.outData);
        } 
      });
    }
  };
  const loadCities = (state) => {
    if (state) {
      exe("RepoCityCatalog", { operation: "GET", filter: "stateCode='" + state + "'" }).then((r) => {
        if (r.ok) {
          //only setiing the new array if it is different, to avoid infinite loop in useEffect
          if(JSON.stringify(cities)!=JSON.stringify(r.outData)) setCities(r.outData);
        }
      });
    }
  };
  const loadSectors = (city) => {
    if (city) {
      exe("RepoSectorCatalog", { operation: "GET", filter: "cityCode='" + city + "'" }).then((r) => {
        if (r.ok) {
          //only setiing the new array if it is different, to avoid infinite loop in useEffect
          if(JSON.stringify(sectors)!=JSON.stringify(r.outData)) setSectors(r.outData);
        }
      });
    }
  };

  const onChange = (field, value) => {
    console.log("onChange", field, value);
    switch (field) {
      case "country":
        props.onChange({ country: value, state: undefined, city: undefined });
        loadStates(value);
        break;
      case "state":
        let newLocation={ ...location, state: value};
        //checking if the city, if selected is compatible with the state
        if(location.city){
          const cityRecord=hasSector?cities.find(c=>c.code==location.city):cities.find(c=>c.id==location.city);
          if(cityRecord.stateCode!=value){
            newLocation.city=undefined;
            newLocation.sector=undefined;
          }
        }

        if(!value) {
          props.onChange(newLocation);
          break;
        }
        //if no country is selected, find the possible countries for the state
        props.onChange(newLocation);
        findCountries(newLocation);
        loadCities(value);
        break;
      case "city":
        if(!value){
          props.onChange({ ...location, city: undefined, sector: undefined });
          break;
        }
        //if no state is selected, find the possible states for the city
        const newLocationC={ ...location, city: hasSector ? value : +value, sector: location.sector?location.sector:undefined };
        findStates(newLocationC);
        props.onChange(newLocationC);
        if (hasSector) loadSectors(value);
        break;
      case "sector":
        //if no city is selected, find the possible citites for the sector
        const newLocationCity={ ...location, sector: +value };
        findCities(newLocationCity);
        props.onChange(newLocationCity);
        break;
    }
  };
  //search functions
  const onStateSearch = (value) => {
    if (value) {
      //restricting the search to the selected country, if not selected no restriction
      const countryFilter=location.country?`countryCode='${location.country}' AND`:"";

      exe("RepoStateCatalog", { operation: "GET", filter:`${countryFilter} name like '${value}%'`,size:15 }).then((r) => {
        if (r.ok) setStates(r.outData);
      });
    }
  };
  const onCitySearch = (value) => {
    if (value) {
      //restricting the search to the selected state, if not selected no restriction
      const stateFilter=location.state?`stateCode='${location.state}' AND`:"";

      exe("RepoCityCatalog", { operation: "GET", filter: `${stateFilter} name like '${value}%'`,size:15 }).then((r) => {
        if (r.ok) setCities(r.outData);
      });
    }
  };
  const onSectorSearch = (value) => {
    if (value) {
      //restricting the search to the selected city, if not selected no restriction
      const cityFilter=location.city?`cityCode='${location.city}' AND`:"";
      exe("RepoSectorCatalog", { operation: "GET", filter:`${cityFilter} name like '${value}%'`  ,size:15 }).then((r) => {
        if (r.ok) setSectors(r.outData);
      });
    }
  };
  //inverse search
  const findCities=newLocation=>{
    const sectorRecord=sectors.find(s=>s.id==newLocation.sector);
    if(sectorRecord){
      exe("RepoCityCatalog", { operation: "GET", filter: `code='${sectorRecord.cityCode}'` }).then((r) => {
        if (r.ok) {
          setCities(r.outData);
          //selecting of there is only one city
          //if(r.outData.length==1) props.onChange({...location,city:hasSector?r.outData[0].code:r.outData[0].id,sector:+sector});
          //if(r.outData.length==1) onChange("city",hasSector?r.outData[0].code:r.outData[0].id);

      }});
    }
  }
  const findStates=newLocation=>{
    const cityRecord=hasSector?cities.find(c=>c.code==newLocation.city):cities.find(c=>c.id==newLocation.city);
    console.log("findStates",cityRecord);
    if(cityRecord){
      exe("RepoStateCatalog", { operation: "GET", filter: `code='${cityRecord.stateCode}'` }).then((r) => {
        if (r.ok) {
          setStates(r.outData);
          //selecting of there is only one state
          //if(r.outData.length==1) props.onChange({...location,state:r.outData[0].code,city:hasSector?city:+city,sector:location.sector?location.sector:undefined});
      }});
    }
  }
  const findCountries=(newLocation)=>{
    const stateRecord=states.find(s=>s.code==newLocation.state);
    if(stateRecord){
      exe("RepoCountryCatalog", { operation: "GET", filter: `code='${stateRecord.countryCode}'` }).then((r) => {
        if (r.ok) {
          //selecting of there is only one country
          if(r.outData.length==1) props.onChange({...newLocation,country:r.outData[0].code});
      }});
    }
  }


  const location = props.value || { country: undefined, state: undefined, city: undefined, sector: undefined };

  return (
    <div style={{ display: "flex" }}>
      <CountrySelect onChange={(v) => onChange("country", v)} value={location.country} style={{ width: "100%", marginRight: 2 }} />

      <Select
        id="stateSelect"
        onChange={(v) => onChange("state", v)}
        value={location.state}
        allowClear
        onClear={()=>console.log("clear")}
        style={{ marginRight: 2 }}
        placeholder={t("States")}
        showSearch
        onSearch={onStateSearch}
        optionFilterProp="children"
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {states.map((state) => (
          <Select.Option value={state.code} key={state.code}>
            {state.name}
          </Select.Option>
        ))}
      </Select>
      <Select
        id="citySelect"
        allowClear
        placeholder={t("Cities")}
        onChange={(v) => onChange("city", hasSector ? v : +v)}
        style={{ marginRight: 2 }}
        value={hasSector ? location.city : (isNaN(location.city)||!location.city) ? undefined : +location.city}
        showSearch
        onSearch={onCitySearch}
        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
        {cities.map((city) => (
          <Select.Option value={hasSector ? city.code : city.id} key={city.id}>
            {city.name}
          </Select.Option>
        ))}
      </Select>
      {hasSector && (
        <Select
          id="sectorSelect"
          allowClear
          placeholder={t("Sectors")}
          onChange={(v) => onChange("sector", +v)}
          value={(isNaN(location.sector)||!location.sector) ? undefined : +location.sector}
          showSearch
          onSearch={onSectorSearch}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
          {sectors.map((sector) => (
            <Select.Option value={sector.id} key={sector.id}>
              {sector.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </div>
  );
};

export default LocationSelect;
