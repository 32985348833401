import { RightOutlined, SaveOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Checkbox, DatePicker, Input, InputNumber, message, Result, Space, Steps } from "antd";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort } from "../../Lib/Helpers";
import CessionParticipants from "./CessionParticipants";
import LiquidationRecord from "./LiquidationRecord";
import ReData from "./ReData";
import ReDataLossPart from "./ReDataLossPart";
import ReDataPart from "./ReDataPart";
import PolicySelect from "../Health/PolicySelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import ContractSelect from "./ContractSelect";
import ContractCessions from "./ContractCessions";
import Currency from "../Shared/Currency";
import ContractCodeSelect from "./ContractCodeSelect";
import GroupPolicySelect from "../Life/GroupPolicySelect";
import ClaimSelect from '../Shared/ClaimSelect';
import DatePickerW from '../Shared/DatePickerW';
const Liquidation = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cession, setCession] = useState([]);
  const [lossCession, setLossCession] = useState([]);
  const [liquidation, setLiquidation] = useState([]);
  const [dayDate, setDayDate] = useState(false);
  const field = props.form.getFieldDecorator;
  
  useEffect(()=>{
    if(props.participantId){
      props.form.setFieldsValue({participantId:props.participantId})
    }else props.form.setFieldsValue({participantId:undefined});
    
    if(props.contractId){
      props.form.setFieldsValue({contractId:props.contractId})
    }else props.form.setFieldsValue({contractId:undefined});
  },[props.participantId,props.contractId])


  const onStepTwo = () => {
    props.form.validateFields((err, values) => {
      if (err) return;

      computePeriod(values);
      setCurrentStep(1);
    });
  };
  const computePeriod = (values, save) => {
    setLoading(true);
    exe("ComputeLiquidation", {
      from: formatDateShort(values.from),
      to: formatDateShort(values.to),
      creationDates: values.creationDates,
      contractId: values.contractId,
      contractCode:values.contractCode,
      participantId:values.participantId,
      name: values.name,
      save: save,
      lineId:values.lineId,
      policyId:values.policyId,
      claimId:values.claimId,
      currency:values.currency,
      groupPolicyId:values.groupPolicyId
    }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setCession(r.outData.premiumCession);
        setLossCession(r.outData.lossCession);
        setLiquidation(r.outData.liquidation);
      } else message.error(r.msg);
    });
  };
  const onStepThree = () => {
    setCurrentStep(2);
  };
  const onStepFour = () => {
    setCurrentStep(3);
  };
  const onStepFive = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      computePeriod(values, true);
      setCurrentStep(4);
    });
  };

  const onExecute = () => {
    props.onExecute && props.onExecute();
    props.onSave && props.onSave(); //temp
    setCurrentStep(0);
    setCession([]);
    setLossCession([]);
    setLiquidation([]);
    props.form.resetFields();
  };
  const onExecuteLater = () => {
    props.onSave && props.onSave();
    setCurrentStep(0);
    setCession([]);
    setLossCession([]);
    setLiquidation([]);
    props.form.resetFields();
  };

  return (
    <div>
      <Button onClick={() => props.onCancel && props.onCancel()}>{t("Cancel")}</Button>
      <Steps current={currentStep} type="navigation" onChange={(p) => setCurrentStep(p)}>
        <Steps.Step title={t("Period Selection")} />
        <Steps.Step title={t("Premiums")} />
        <Steps.Step title={t("Claims")} />
        <Steps.Step title={t("Summary")} />
      </Steps>
      <div style={{ width: 400, marginTop: 10, display: currentStep == 0 ? "block" : "none" }}>
        <Form.Item label={t("Liquidation Name")}>{field("name", { rules: [{ required: false }] })(<Input />)}</Form.Item>
        <div style={{ display: "flex" }}>
          <Space style={{display:dayDate?"none":"flex"}}>
            <Form.Item label={t("From")}>{field("from", { rules: [{ required: true }] })(<DatePicker.MonthPicker />)}</Form.Item>
            <Form.Item label={t("To")}>{field("to", { rules: [{ required: true }] })(<DatePicker.MonthPicker />)}</Form.Item>
          </Space>
          <Space style={{display:dayDate?"flex":"none"}}>
            <Form.Item label={t("From")}>{field("from", { rules: [{ required: true }] })(<DatePickerW />)}</Form.Item>
            <Form.Item label={t("To")}>{field("to", { rules: [{ required: true }] })(<DatePickerW />)}</Form.Item>
          </Space>
          <Checkbox style={{marginBottom:1,marginLeft:5,alignSelf:"flex-end"}} onChange={(e) => setDayDate(e.target.checked)}>{t("Select Days")}</Checkbox>
        </div>
        <Form.Item label={t("Reinsurer")}>{field("participantId", {defaultValue:props.participantId, rules: [{ required: false }] })( <SimpleContactSelect   />)}</Form.Item>
        <Form.Item label={t("Contract Code")}>{field("contractCode", {defaultValue:props.contractId, rules: [{ required: false }] })( <ContractCodeSelect style={{width:"100%"}}  />)}</Form.Item>
        <Form.Item label={t("Contract")}>{field("contractId", {defaultValue:props.contractId, rules: [{ required: false }] })( <ContractSelect style={{width:"100%"}}  />)}</Form.Item>
        <Form.Item label={t("Line ID")}>{field("lineId", { rules: [{ required: false }] })(<Input placeholder={t("Optional line type")} />)}</Form.Item>
        <Form.Item label={t("Policy")}>{field("policyId", { rules: [{ required: false }] })(<PolicySelect proposals placeholder={t("Optional policy")} />)}</Form.Item>
        <Form.Item label={t("Group Policy")}>{field("groupPolicyId", { rules: [{ required: false }] })(<GroupPolicySelect  />)}</Form.Item>
        <Form.Item label={t("Claim")}>{field("claimId", { rules: [{ required: false }] })(<ClaimSelect placeholder={t("Optional claim")}  />)}</Form.Item>
        <Form.Item label={t("Currency")}>{field("currency", { rules: [{ required: false }] })(<Currency style={{width:"100%"}}  />)}</Form.Item>
        <Form.Item label={t("Use Record Creation Dates")}>{field("creationDates", { rules: [{ required: false }] })(<Checkbox />)}</Form.Item>

        <Button type="primary" icon={<RightOutlined />} onClick={() => onStepTwo()}>
          {t("Next")}
        </Button>
      </div>
      <div style={{ marginTop: 10, display: currentStep == 1 ? "block" : "none" }}>
        <ReDataPart value={cession} loading={loading} />
        <Button type="primary" icon={<RightOutlined />} onClick={() => onStepThree()}>
          {t("Next")}
        </Button>
      </div>
      <div style={{ marginTop: 10, display: currentStep == 2 ? "block" : "none" }}>
        <ReDataLossPart value={lossCession} loading={loading} />
        <Button type="primary" icon={<RightOutlined />} onClick={() => onStepFour()}>
          {t("Next")}
        </Button>
      </div>
      <div style={{ marginTop: 10, display: currentStep == 3 ? "block" : "none" }}>
        <LiquidationRecord value={liquidation} loading={loading} />
        <Button type="primary" icon={<SaveOutlined />} onClick={() => onStepFive()}>
          {t("Save")}
        </Button>
      </div>
      <div style={{ marginTop: 10, display: currentStep == 4 ? "block" : "none" }}>
        <Result
          status="success"
          title={t("Liquidation saved")}
          subTitle={t("When executed, this process will perform the corresponding transfers between accounts")}
          extra={[
            // <Button key="execute" onClick={() => onExecute()}>
            //   Execute
            // </Button>,
            <Button type="primary" key="later" onClick={() => onExecuteLater()}>
              {t("Ok")}
            </Button>,
          ]}
        />
      </div>
    </div>
  );
};

export default Form.create()(Liquidation);
