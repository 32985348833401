import { AutoComplete, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const PensionMemberSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    if(props.pensionSchemeId) load()
}, [props.pensionSchemeId]);

  const load = () => {
    setLoading(true);
    exe("RepoPensionMember", { operation: "GET",filter:`pensionSchemeId=${props.pensionSchemeId}`,include:["Contact"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSearch = (value) => {
    setLoading(true);
    exe("RepoPensionMember", { operation: "GET",size:20,filter:`pensionSchemeId=${props.pensionSchemeId} AND contactId in (SELECT id from Contact WHERE name LIKE '%${value}')`,include:["Contact"] }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  }
  return (
    <div>
      {/* <AutoComplete allowClear placeholder={t("Search Member")} loading={loading}
      value={props.value} 
      onChange={props.onChange}
      onSearch={onSearch}
      dataSource={data}>
        {data.map((p) => <AutoComplete.Option key={p.id} value={p.id}>{p.Contact.FullName}</AutoComplete.Option>)}
      </AutoComplete> */}
      <Select
        value={props.value}
        onChange={props.onChange}
        loading={loading}
        allowClear
        showSearch
        disabled={props.disabled}
        optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.id} key={p.id}>
            {p.Contact.FullName}
          </Select.Option>
        ))}
      </Select> 
    </div>
  );
};

export default PensionMemberSelect;
