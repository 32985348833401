import { FolderOpenOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, message, Table, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShortUtc, formatMoney } from "../../Lib/Helpers";
import DefaultPage from "../Shared/DefaultPage";
import ReEventDetail from "./ReEventDetail";
import ReData from "./ReData";
import ClaimsPerEvent from "./ClaimsPerEvent";

const ReEvents = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [eventDetail, setEventDetail] = useState();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    exe("RepoReEvent", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onOkDetail = (values) => {
    setLoading(true);
    const operation = values.id ? "UPDATE" : "ADD";
    exe("RepoReEvent", { operation: operation, entity: values }).then((r) => {
      setLoading(false);
      if (r.ok) {
        setEventDetail();
        message.success(r.msg);
        if (operation === "ADD") setData([...data, ...r.outData]);
        else if (operation === "UPDATE") setData([...data.filter((q) => q.id !== values.id), ...r.outData]);
      } else {
        message.error(r.msg);
      }
    });
  };
  return (
    <div>
      <DefaultPage
        title={t("Reinsurance Events")}
        icon="thunderbolt"
        extra={
          <div>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => setEventDetail(true)}>
              {t("New")}
            </Button>
          </div>
        }>
        <Tabs>
          <Tabs.TabPane tab={t("Events")} key="events">
            <Table dataSource={data} loading={loading} scroll={{ x: true }}>
              <Table.Column title={t("Id")} dataIndex="id" />
              <Table.Column title={t("Code")} dataIndex="code" />
              <Table.Column title={t("Title")} dataIndex="name" />
              <Table.Column title={t("Catastrophic")} dataIndex="catastrophic" render={(v) => <Checkbox checked={v} />} />
              <Table.Column title={t("Start")} dataIndex="start" render={(v) => formatDateShortUtc(v)} />
              <Table.Column title={t("End")} dataIndex="end" render={(v) => (v ? formatDateShortUtc(v) : "-")} />
              <Table.Column title={t("Open")} key="open" render={(v, r) => <Button type="link" onClick={() => setEventDetail(r)} icon={<FolderOpenOutlined />} />} />
            </Table>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Incidence")} key="incidence">
            <ClaimsPerEvent />
          </Tabs.TabPane>
        </Tabs>
      </DefaultPage>
      <ReEventDetail value={eventDetail} visible={eventDetail} onOk={onOkDetail} onCancel={() => setEventDetail()} />
    </div>
  );
};

export default ReEvents;
