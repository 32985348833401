import { DeleteOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { Button, Divider, message, Popconfirm, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { exe, fetchProxy } from "../../Lib/Dal";
import CustomForm from "../Shared/CustomForm";
import MapRender from "./MapRender";
import FileUpload from "../Life/FileUpload";
import { useTranslation } from "react-i18next";
import config from "../../config";
import showPrompt from "../Shared/showPrompt";

const ObjectRender = (props) => {
  const [t] = useTranslation();
  const [record, setRecord] = useState({});
  const [insuredObject, setInsuredObject] = useState();

  useEffect(() => {
    if (props.objectDefinitionId) load(props.objectDefinitionId);
  }, [props.objectDefinitionId]);

  useEffect(() => {
    if (props.value) {
      setRecord(props.value.ObjectDefinition);
      setInsuredObject(props.value);
    }
  }, [props.value]);

  const load = (objectDefinitionId) => {
    exe("RepoObjectDefinition", { operation: "GET", filter: "id=" + objectDefinitionId }).then((r) => {
      if (r.ok) {
        setRecord(r.outData[0]);
      } else {
        message.error(r.msg);
      }
    });
  };
  const openFile = (f) => {
    fetch(config.ssApiUrl + "/proxy" + f, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.token,
      },
    })
      .then((response) => response.blob())
      .then((data) => window.open(URL.createObjectURL(data)));
  };
  const deleteFile = (v) => {
    props.onFileUploadChange(files.filter((p) => p.url !== v));
    message.success(t("File removed. Please save changes"));
  };
  const files = props.fileUploadValue ? JSON.parse(props.fileUploadValue) : [];
  return (
    <div>
      <Tabs>
        <Tabs.TabPane tab={record.name} key="form">
          <CustomForm formId={record.formId} variable={props.dynamicFormId || "objectForm"} value={props.value ? props.value.jValues : undefined} newRecord={!props.value} />
        </Tabs.TabPane>
        {record.hasMap && (
          <Tabs.TabPane tab="Map" key="map">
            <MapRender onChange={props.onMapChange} value={props.mapValue} />
          </Tabs.TabPane>
        )}
        {record.hasFileUpload && (
          <Tabs.TabPane tab="Files" key="files">
            <FileUpload onChange={props.onFileUploadChange} />
            {files.length > 0 && (
              <Table dataSource={files}>
                <Table.Column title={t("File")} dataIndex="fileName" />
                <Table.Column
                  title={t("Actions")}
                  dataIndex="url"
                  render={(v, r) => (
                    <div>
                      <Button type="link" icon={<FolderOpenOutlined />} onClick={() => openFile(v)} />
                      <Divider type="vertical" />
                      <Popconfirm title={t("Are you sure you want to remove this file?")} onConfirm={() => deleteFile(v)}>
                        <Button type="link" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    </div>
                  )}
                />
              </Table>
            )}
          </Tabs.TabPane>
        )}
        {/* <Tabs.TabPane tab="Pictures" key="pictures"></Tabs.TabPane>
        <Tabs.TabPane tab="Files" key="files"></Tabs.TabPane> */}
      </Tabs>
    </div>
  );
};

export default ObjectRender;
