import React from "react";
import { LeftOutlined } from '@ant-design/icons';
import { Button } from "antd";
import { useTranslation } from "react-i18next";

const MasterDetail = (props) => {
  const [t] = useTranslation();

  const children = props.children;
  const Master = props.master;
  const activeKey = props.activeKey;
  return (
    <div>
      {!activeKey && Master}
      {activeKey && (
        <Button style={{ float: "left" }} type="link" icon={<LeftOutlined />} onClick={() => props.onBack()}>
          {t("Back")}
        </Button>
      )}
      {children.filter((p) => p.key == activeKey)}
    </div>
  );
};

export default MasterDetail;
