import React, { useEffect, useState } from "react";
import { message, Select } from "antd";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const ReasonSelect = (props) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (props.catalog) load(props.catalog);
    else if(!props.noDefault) setData(defaultReasons);
  }, [props.catalog]);

  const defaultReasons = [
    { code: "COM", name: t("Went to competitor") },
    {
      code: "PAY",
      name: t("No payment"),
    },
    {
      code: "OTH",
      name: t("Other"),
    },
  ];
  const load = (catalog) => {
    setLoading(true);
    exe("RepoReasonsCatalog", { operation: "GET", filter: `catalog='${catalog}'` }).then((r) => {
      setLoading(false);
      if (r.ok) {
        if (r.total == 0&&!props.noDefault) setData(defaultReasons);
        else setData(r.outData);
      } else message.error(r.msg);
    });
  };
  return (
    <div>
      <Select value={props.value} onSelect={(v) => props.onChange(v)} loading={loading} placeholder={t("Please select reason")} style={props.style}>
        {data.map((p) => (
          <Select.Option value={props.getId?p.id:p.code}>{t(p.name)}</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ReasonSelect;
