import React from "react";
import { Button, Table, message } from "antd";
import { useEffect, useState } from "react";
import { LeftOutlined, PlusOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import { formatDateShort, formatMoney } from "../../Lib/Helpers";
import FundPlanLineForm from "./FundPlanLineForm";

const FundPlanLines = (props) => {
    const [t] = useTranslation();
    const [data,setData] = useState([]);
    const [selected,setSelected] = useState(null);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        if(props.fundPlanId){
            load();
        }
    },[props.fundPlanId])

    const load=()=>{
        setLoading(true);
        exe("RepoFundPlanLine",{operation:"GET",filter:`fundPlanId=${props.fundPlanId}`,include:["InvestmentPlan"]}).then(r=>{
            setLoading(false);
            if(r.ok){
                setData(r.outData);
            }else{
                message.error(r.msg);
            }
        })
    }


    return <div>
        {!selected&&<div>
            <Button type="link" icon={<PlusOutlined />} disabled={!props.fundPlanId} onClick={()=>setSelected({id:0})}>{t("Add")}</Button>
            <Button type="link" icon={<ReloadOutlined />} disabled={!props.fundPlanId}  onClick={load}>{t("Reload")}</Button>
            <Table dataSource={data} rowKey={"id"} loading={loading}>
                <Table.Column title={t("Invesment Product")} dataIndex={["InvestmentPlan","name"]} />
                <Table.Column title={t("Percentage")} dataIndex="percentage" />
                <Table.Column title={t("Actions")} render={(v,r)=><div>
                    <Button type="link" icon={<EditOutlined />} onClick={()=>setSelected(r)} />
                </div>} />
            </Table>
        </div>}
    {selected&&<div>
        <FundPlanLineForm fundPlanId={props.fundPlanId} value={selected} onSave={load} onCancel={()=>setSelected(null)} />
    </div>}
    </div>
       
    };

    export default FundPlanLines;