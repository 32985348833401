import React, {useState, useEffect} from "react";
import DefaultPage from "../Shared/DefaultPage";
import {useTranslation} from "react-i18next";
import { FileOutlined, LeftOutlined, ReloadOutlined } from '@ant-design/icons';
import {Table, Tabs, message, Button, Badge} from "antd";
import {exe} from "../../Lib/Dal";
import ClosureDetail from "./ClosureDetail";
import Status from "../Shared/Status";
import {getColor} from "../../Lib/Helpers";

const Closure = (props) => {
    const [t, i18n] = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState();

    useEffect(() => load(), []);

    const load = () => {
        setLoading(true);
        exe("RepoClosure", {operation: "GET",include:["Transactions","Process"]}).then(r => {
            setLoading(false);
            if (r.ok) {
                setData(r.outData);
            } else message.error(r.msg);
        })
    }
    const onNewClosure = () => {
        setSelected({id: 0})
    }
    const onRecordAdded = record => {
        setData([...data, record]);
        setSelected(null);
    }
    const onRecordDeleted = (id) => {
        setData(data.filter(p => p.id !== id));
        setSelected(null);
    }
    const onClose=()=>{
        setSelected(null);
        load();
    }
    const renderStatus = (v) => {
        if (!v) return <span><Badge status="success"/>{t("Open")}</span>
        else return <span><Badge status="error"/>{t("Closed")}</span>
    }
    return (
        <div>
            <DefaultPage
                title={t("Accounting Closure")}
                icon="safety">
                <Tabs>
                    <Tabs.TabPane tab={t("Periods")} key="periods">
                        {!selected && <div>
                            <Button type="link" icon={<FileOutlined />} onClick={onNewClosure}>{t("New")}</Button>
                            <Button type="link" icon={<ReloadOutlined />} onClick={() => load()}>
                                {t("Reload")}
                            </Button>
                            <Table dataSource={data} loading={loading}>
                                <Table.Column dataIndex="id" title={t("ID")}/>
                                <Table.Column dataIndex="year" title={t("Year")}/>
                                <Table.Column dataIndex="month" title={t("Month")}/>
                                <Table.Column dataIndex="status" title={t("Status")} render={v=>renderStatus(v)}/>
                                <Table.Column
                                    dataIndex="Process"
                                    title={t("Workflow")}
                                    render={(v) => <Status process={v} color={getColor(v && v.entityState)} reload={() => load()} />}
                                />

                                <Table.Column key="actions" title={t("Actions")}
                                              render={(v, r) => <div><Button type="link"
                                                                             onClick={() => setSelected(r)}>{t("Select")}</Button>
                                              </div>}/>
                            </Table>
                        </div>}
                        {selected && <div>
                            <Button style={{float: "left"}} type="link" icon={<LeftOutlined />}
                                    onClick={() => setSelected(null)}>{t("Back")}</Button>
                            <ClosureDetail selected={selected} onAdd={onRecordAdded} onDelete={onRecordDeleted}
                                           onEdit={() => load()} onClose={onClose}/>
                        </div>}

                    </Tabs.TabPane>
                </Tabs>
            </DefaultPage>
        </div>
    );
};

export default Closure;