import { FolderOpenOutlined } from '@ant-design/icons';
import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const FormSelect = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  useEffect(() => load(), []);

  const load = () => {
    exe("GetForms").then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const onChange = (v, option) => {
    props.onChange(v);
  };
  const value = props.value;
  if (props.value == 0) props.onChange(undefined);
  return (
    <div style={{ display: "flex" }}>
      <Select style={{ width: "100%" }} placeholder={t("Please select")} onChange={onChange} value={value} showSearch allowClear optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.id} key={p.code} record={p}>
            {p.id}.{p.name}
          </Select.Option>
        ))}
      </Select>
      <a href={"#/fb/" + props.value}>
        <FolderOpenOutlined style={{ marginLeft: 5 }} />
      </a>
    </div>
  );
};

export default FormSelect;
