import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {exe, safeGet, safeGetRaw} from "../../Lib/Dal";
import {getConfigProfile} from "../../Lib/Helpers";

const ServerSelect = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [t] = useTranslation();
  const defaultValues=[{name:"DEFAULT"}]

    useEffect(() => {
        getConfigProfile().then((profile) => {
            const values=safeGet(["WorkerCluster"], profile, []);
            if(values.length===0) {
                //setting default values and selecting it
                setData(defaultValues);
                props.onChange("DEFAULT");
            }else{
                setData(values);
            }
        });
    }, []);
  
  return (
    <div>
      <Select
        showArrow
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeHolder}
        loading={loading}
        showSearch
        style={props.style}
        optionFilterProp="children">
        {data.map((p) => (
          <Select.Option value={p.name} key={p.name}>
            {p.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ServerSelect;
