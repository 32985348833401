import React, { Component } from "react";
import { PageHeader, Spin } from "antd";
import { withTranslation } from "react-i18next";
import {Icon} from "@ant-design/compatible";

class DefaultPage extends Component {
  render() {
    const routes = [
      {
        path: "/home",
        breadcrumbName: this.props.t("Home"),
      },
      {
        path: "",
        breadcrumbName: this.props.title,
      },
    ];
    return (
      <div>
        <PageHeader
          style={{
            border: "1px solid #dee2e6",
            borderRadius: 5,
            marginBottom: 20,
            boxShadow: "0 1px 2px 0 rgba(31,45,61,.07)",
            background: "#f9fafc",
          }}
          onBack={this.props.onBack}
          breadcrumb={this.props.routes ? this.props.routes : { routes }}
          title={this.props.title}
          subTitle={this.props.subTitle}
          tags={this.props.tags}
          //src: "https://avatars1.githubusercontent.com/u/8186664?s=460&v=4"
          // bank  #B2C300, blue #1890ff  style: { backgroundColor: "#1890ff" }
          avatar={{ icon:<Icon type={this.props.icon} />, size: "large", style: this.props.iconStyle }}
          extra={this.props.extra}>
          {this.props.content}
        </PageHeader>
        <Spin spinning={this.props.loading == true}>{this.props.children}</Spin>
      </div>
    );
  }
}

export default withTranslation()(DefaultPage);
