import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { exe } from "../../Lib/Dal";
import {useTranslation} from "react-i18next";

const InsuredEventSelect = (props) => {
  const [data, setData] = useState([]);
  const [t]=useTranslation();

  useEffect(() => load(), []);

  useEffect(() => {
    if (props.validValues && props.value) {
      if (props.validValues.indexOf(props.value.code) == -1) {
        //deselection disabled value
        props.onChange(undefined);
      }
    }
    if(!props.validValues&&props.value) props.onChange(undefined);
  }, [props.validValues]);

  const load = () => {
    exe("RepoInsuredEventCatalog", { operation: "GET" }).then((r) => {
      if (r.ok) {
        setData(r.outData);
      } else {
        message.error(r.msg);
      }
    });
  };
  const validValues = props.validValues || ""; //csv with valid values
  return (
    <div>
      <Select disabled={props.disabled||!validValues&&!props.noValidation} value={props.value ? props.value.code : undefined}
              showSearch
              style={props.style}
              optionFilterProp="children"
              filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(v, opt) => props.onChange(opt.props.record)}>
        {data.filter(q=>validValues.includes(q.code)||props.noValidation).map((p) => (
          <Select.Option value={p.code} record={p} disabled={p.disabled}>
            {t(p.name)}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default InsuredEventSelect;
