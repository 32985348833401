import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Modal, Row, Input, Col, Tabs, InputNumber } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormLabel from "../Shared/FormLabel";
import Money from "../Shared/Money";
import Percentage from "../Shared/Percentage";
import BenefitGroupSelect from "./BenefitGroupSelect";

const BenefitDetail = (props) => {
  const [t] = useTranslation();

  useEffect(() => {
    if (props.value) props.form.setFieldsValue(props.value);
  }, [props.value]);

  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onOk(values);
    });
  };
  const field = props.form.getFieldDecorator;
  return (
    <div>
      {field("id")}
      {field("lifeCoverageId")}
      {field("coverageCode")}
      <Modal title={t("Benefit Detail")} visible={props.visible} width={820} bodyStyle={{ paddingTop: 0 }} onOk={onSubmit} onCancel={props.onCancel} okButtonProps={{disabled:props.readOnly}}>
        <Tabs>
          <Tabs.TabPane tab={t("Main")} key="main">
            <Row gutter={16} className={props.readOnly ? "disabled" : ""}>
              <Col span={12}>
                <Form.Item label={t("Name")}>{field("name", {})(<Input />)}</Form.Item>
                <Form.Item label={t("Description")}>{field("description", {})(<Input.TextArea />)}</Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label={t("Code")}>{field("code", {})(<FormLabel code />)}</Form.Item>
                <Form.Item label={t("Group")}>{field("group", {})(<BenefitGroupSelect groups={props.groups} />)}</Form.Item>
                <Form.Item label={t("Secondary Group")}>{field("group2", {})(<BenefitGroupSelect groups={props.groups} />)}</Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label={t("Covered")}>{field("covered", {})(<Percentage decimal />)}</Form.Item>
                <Form.Item label={t("Copay")}>{field("copay", {})(<Money currency={props.currency} />)}</Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Limits")} key="limits">
            <Row gutter={16} className={props.readOnly ? "disabled" : ""}>
              <Col span={8}>
                <Form.Item label={t("Event Limit")}>{field("limit", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Annual Limit")}>{field("aLimit", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Quarter Limit")}>{field("qLimit", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Month Limit")}>{field("mLimit", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Family Limit")}>{field("familyLimit", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Lifetime Limit")}>{field("lifetimeLimit", {})(<Money currency={props.currency} />)}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label={t("Annual Occurence")}>{field("eventaTimes", {})(<InputNumber style={{ width: "100%" }} />)}</Form.Item>
                <Form.Item label={t("Quarter Occurence")}>{field("eventqTimes", {})(<InputNumber style={{ width: "100%" }} />)}</Form.Item>
                <Form.Item label={t("Month Occurence")}>{field("eventmTimes", {})(<InputNumber style={{ width: "100%" }} />)}</Form.Item>
                <Form.Item label={t("Day Occurence")}>{field("eventdTimes", {})(<InputNumber style={{ width: "100%" }} />)}</Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={t("Out of pocket annual limit")}>{field("outOfPocketAnualLimit", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Waiting Period")}>{field("waitingPeriod", {})(<InputNumber />)}</Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("Deductibles")} key="deductibles">
            <Row gutter={16} className={props.readOnly ? "disabled" : ""}>
              <Col span={8}>
                <Form.Item label={t("Event Deductible")}>{field("deductible", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Annual Deductible")}>{field("aDeductible", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Quarter Deductible")}>{field("qDeductible", {})(<Money currency={props.currency} />)}</Form.Item>
                <Form.Item label={t("Month Deductible")}>{field("mDeductible", {})(<Money currency={props.currency} />)}</Form.Item>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default Form.create()(BenefitDetail);
