import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import MainInsuredSelect from "../Contact/MainInsuredSelect";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import Money from "../Shared/Money";
import {safeValue} from "../../Lib/Dal";

const AddCertificate = (props) => {
  const [t] = useTranslation();
  const [contact, setContact] = useState(null);
  const field = props.form.getFieldDecorator;
  const onSubmit = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      values.Insureds = [{ contactId: values.contactId, role: 0, name: contact.FullName, Contact: contact }];
      props.onSubmit(values);
      props.form.resetFields();
    });
  };
  const onContactSelected = (contact) => {
    setContact(contact);
  };
  return (
    <div>
      <Form.Item label={t("Main Insured")}>
        {field("contactId", { rules: [{ required: true }] })(<SimpleContactSelect allowNew onSelectContact={(contact) => onContactSelected(contact)} />)}
      </Form.Item>

        <div style={{ display: safeValue("Main.payerVisible", props.config, false) ? "block" : "none" }}>
            <Form.Item label={t("Payer")}>
                {field("payerId", { rules: [{ required: safeValue("Main.payerMandatory", props.config, false) }] })(
                    <SimpleContactSelect allowNew />
                )}
            </Form.Item>
        </div>

      <Form.Item label={t("Certificate group")}>
        {field("group", { rules: [{ required: true }] })(
          <Select style={{ width: "100%" }}>
            {props.groups.map((group) => (
              <Select.Option key={group} value={group}>
                {group}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
      <Form.Item label={t("Base Insured Sum")}>{field("insuredSum", { rules: [{ required: true }] })(<Money currency={props.currency} />)}</Form.Item>
      <Form.Item label={t("Description")}>{field("description", { rules: [{ required: false }] })(<Input.TextArea />)}</Form.Item>

      <Button type="primary" onClick={onSubmit} style={{ marginTop: 5 }}>
        {t("Submit")}
      </Button>
    </div>
  );
};

export default Form.create()(AddCertificate);
