import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, InputNumber, Drawer } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import Compare from "../Shared/Compare";
import Currency from "../Shared/Currency";

const AnnuityFilter = (props) => {
  const [t] = useTranslation();
  const field = props.form.getFieldDecorator;
  const onSearch = () => {
    props.form.validateFields((err, values) => {
      if (err) return;
      props.onSearch(values);
    });
  };
  return (
    <Drawer title={t("Annuity filter")} visible={props.visible} onClose={props.onClose} width={512}>
      <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
        <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
          {t("Reset")}
        </Button>
      </div>
      <Form>
        <Form.Item label={t("Id")}>{field("id")(<InputNumber />)}</Form.Item>
        <Form.Item label={t("Amount")}>{field("amount")(<Compare />)}</Form.Item>
        <Form.Item label={t("Currency")}>{field("currency")(<Currency />)}</Form.Item>
        <Form.Item label={t("Beneficiary")}>{field("beneficiaryId")(<SimpleContactSelect />)}</Form.Item>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()} style={{ marginTop: 5 }}>
          {t("Search")}
        </Button>
      </Form>
    </Drawer>
  );
};

export default Form.create({})(AnnuityFilter);
