import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DefaultPage from "../Shared/DefaultPage";
import LiquidationList from "../Contract/LiquidationList";

const ReLiquidations = (props) => {
    const [t] = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);


    return (
        <div>
            <DefaultPage
                title={t("Reinsurance Liquidations")}
                icon="dollar">
               
                <LiquidationList showReinsurerFilter  />
                
                {/* <Button type="link" icon="reload" onClick={()=>load()}>
                    {t("Reload")}
                </Button>
                <Table dataSource={data} loading={loading}  rowKey="id">
                    <Table.Column title={t("Id")} dataIndex="id" />
                    <Table.Column title={t("Name")} dataIndex="name" />
                    <Table.Column title={t("Contract")} dataIndex="contractId" />
                    <Table.Column title={t("Participant")} dataIndex="participantName" />
                    <Table.Column title={t("Currency")} dataIndex="currency" />
                    <Table.Column title={t("Premiums Ceded")} dataIndex="premiums" />
                    <Table.Column title={t("Claims Ceded")} dataIndex="claims" />
                    <Table.Column title={t("Commissions")} dataIndex="commissions" />
                    <Table.Column title={t("Taxes")} dataIndex="tax" />
                    <Table.Column title={t("Status")} dataIndex="status" />
                    <Table.Column title={t("Request")} dataIndex="paymentRequestId" />
                    <Table.Column title={t("Created")} dataIndex="creation" render={v=>formatDate(v)} />
                </Table>*/}
            </DefaultPage>
        </div>
    );
};

export default ReLiquidations;
