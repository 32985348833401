import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Money from "../Shared/Money";
import AccountSelect from "./AccountSelect";
import { DollarOutlined } from '@ant-design/icons';
import {Button, Input, InputNumber, message} from "antd";
import AccountSelectDetail from "./AccountSelectDetail";
import {exe} from "../../Lib/Dal";
import {round2} from "../../Lib/Helpers";

const TransactionLine = (props) => {
  const [account, setAccount] = useState(undefined);
  const [debit, setDebit] = useState(undefined);
  const [credit, setCredit] = useState(undefined);
  const [defaultCurrency, setDefaultCurrency] = useState("USD");
  const [order,setOrder]=useState();
  const [xDebit, setXdebit] = useState(undefined);
  const [xCredit, setXcredit] = useState(undefined);
  const [xChangeRate, setXchangeRate] = useState();
  const [xCurrency,setXcurrency]=useState();
  const [t]=useTranslation();
  const [loading,setLoading]=useState(false);
  const [auxId,setAuxId]=useState();
  const [auxType,setAuxType]=useState();
  const [comments,setComments]=useState();

  useEffect(() => {
    if (props.defaultCurrency) setDefaultCurrency(props.defaultCurrency);
  }, [props.defaultCurrency]);

  useEffect(() => {
    if (props.value) {
      setAccount(props.value.account);
      setDebit(props.value.debit);
      setCredit(props.value.credit);
      setOrder(props.value.order);
      
      setXdebit(props.value.xDebit);
      setXcredit(props.value.xCredit);
      setXchangeRate(props.value.xChangeRate);
      setXcurrency(props.value.xCurrency);
      setAuxId(props.value.auxId);
      setComments(props.value.comments);
    }
  }, [props.value]);


  const onChangeOrder=v=>{
    setOrder(v);
    if (props.onChange) props.onChange({...props.value, order:v });
  }
  const onSelectAccountRecord=record=>{
    if(!record.isDetail) {
      message.error(t("Not a detail account"))
      return;
    }
    setAccount(record.code);
    setXcurrency(record.currency);
    setAuxType(record.auxType);
    if (props.onChange) props.onChange({...props.value,account: record.code,xCurrency:record.currency });
  }
  const onChangeDebit = (v) => {
    setDebit(v);
    if (props.value.debit!==v) props.onChange({...props.value, debit: v });
  };
  const onChangeCredit = (v) => {
    setCredit(v);
      if (props.value.credit!==v) props.onChange({...props.value, credit: v });
  };
  const onChangeXdebit = (v) => {
    setXdebit(v);
      if (props.value.xDebit!==v) props.onChange({ ...props.value, xDebit: v });
  };
  const onChangeXcredit = (v) => {
    setXcredit(v);
      if (props.value.xCredit!==v) props.onChange({...props.value, xCredit: v });
  };
const onChangeXChangeRate = (v,newDebit=null,newCredit=null) => {
    setXchangeRate(v);
    if (props.onChange) props.onChange({...props.value, debit:newDebit==null?debit:newDebit, credit:newCredit==null?credit:newCredit,xChangeRate:v });
};
 const getExchangeRate=()=>{
   if(!props.postingDate){
       message.error(t("Please define a posting date"));
       return;
   }
   setLoading(true);
   exe("GetExchangeRate",{currency:xCurrency||"USD",date:props.postingDate}).then(r=>{
     setLoading(false);
     if(r.ok){
         const newXchangeRate=r.outData;
         const newDebit=isNaN(xDebit)?0:round2(r.outData*xDebit);
         const newCredit=isNaN(xCredit)?0:round2(r.outData*xCredit);
         
         setXchangeRate(newXchangeRate);
         setDebit(newDebit);
         setCredit(newCredit);
         
         //propagating to parent
         onChangeXChangeRate(newXchangeRate,newDebit,newCredit);
       
   }else message.error(r.msg);
 })
 }
  const onChangeAuxId = (v) => {
    setAuxId(v);
    if (props.onChange) props.onChange({...props.value,auxId:v });
  };
  const onChangeComments = (v) => {
    setComments(v);
    if (props.onChange) props.onChange({...props.value,comments:v  });
  };
  return (
    <div style={{ display: "flex", marginTop: 5, flex: 1 }} >
      <InputNumber style={{width:"100%",minWidth:55,maxWidth:55,marginRight:5}}  value={order} onChange={v=>onChangeOrder(v)} min={1}  />
      {/*<AccountSelect style={{ width: "100%",maxWidth:500, marginRight:5 }} value={account} onChange={(v) => onChangeAccount(v)}  />*/}
      <AccountSelectDetail style={{ flex: 1,maxWidth:400,minWidth:200, marginRight:5 }} value={account} onChange={v=>v}  onSelect={onSelectAccountRecord} accountRecord={props.value&&props.value.Account}/>
      <Input style={{ maxWidth:200,marginRight:5}}  value={comments} onChange={e=>onChangeComments(e.target.value)} />
      <Money style={{ maxWidth:200,marginRight:5 }} currency={defaultCurrency} value={debit} onChange={(v) => onChangeDebit(v)} />
      <Money style={{ maxWidth:200,marginRight:5 }} currency={defaultCurrency} value={credit} onChange={(v) => onChangeCredit(v)} />
      <Money style={{ maxWidth:200,marginRight:5 }} currency={xCurrency} value={xDebit} onChange={(v) => onChangeXdebit(v)} />
      <Money style={{ maxWidth:200,marginRight:5 }} currency={xCurrency} value={xCredit} onChange={(v) => onChangeXcredit(v)} />
      <InputNumber style={{width:"100%",maxWidth:100,marginRight:5}}  value={xChangeRate} onChange={v=>onChangeXChangeRate(v)} min={0} disabled={!xDebit&&!xCredit}  />
      <Button type="link" onClick={getExchangeRate} icon={<DollarOutlined />} disabled={!xDebit&&!xCredit} loading={loading} />
      <Input style={{maxWidth:200,marginLeft:5,marginRight:5}} value={auxId} onChange={e=>onChangeAuxId(e.target.value)} disabled={!auxType}  />
    </div>
  );
};

export default TransactionLine;
