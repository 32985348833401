import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Button } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import SimpleContactSelect from "../Contact/SimpleContactSelect";
import CustomForm from '../Shared/CustomForm';

const CessionBeneficiary = (props) => {
  const [t] = useTranslation();
  const onSelectCession = (contact) => {
    props.onChange(contact.id);
  };
  const onAdd = () => {
    props.onChange(0);
  };
  const onRemoveBeneficiary = () => {
    if (props.disabled) return;
    props.onChange(undefined);
  };
  const value = props.value;
  return (
    <div className={props.disabled ? "disabled" : ""}>
      {value !== undefined && value !== null && (
        <div>
          <div> {t("Cession Beneficiary")}</div>
          <div style={{ display: "flex", marginTop: 5 }}>
            <SimpleContactSelect onSelectContact={(contact) => onSelectCession(contact)} value={value ? value : undefined} filter="isPerson=0 AND " />
            <MinusCircleOutlined
              style={{ marginLeft: 5, cursor: "pointer", fontSize: 24, color: "#999", alignSelf: "center" }}
              onClick={() => onRemoveBeneficiary()} />
          </div>
          <CustomForm formId={props.formId} variable="customCessionBeneficieryForm" value={props.jCessionBeneficiary} />
        </div>
      )}
      {(value == undefined || value == null) && (
        <Button type="link" icon={<PlusCircleOutlined />} onClick={() => onAdd()} style={{ paddingLeft: 0, marginTop: 5 }} disabled={props.disabled}>
          {t("Add Cession Beneficiary")}
        </Button>
      )}
    </div>
  );
};

export default CessionBeneficiary;
