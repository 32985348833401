import React, { useEffect, useRef, useState } from 'react';
import {Button,Avatar,Input, message, Drawer, Comment, Tooltip, Skeleton, Alert} from 'antd';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import { exe, getProxy, safeGet, } from '../../Lib/Dal';
import { ArrowUpOutlined,UserOutlined, CodeOutlined, RobotOutlined } from '@ant-design/icons';
import CodeBlock from './CodeBlock';
import { getConfigProfile } from '../../Lib/Helpers';

const Ai = (props) => {
    const input = useRef();
    const [t] = useTranslation();
    useEffect(() => {
        getConfigProfile().then((profile) => {
            setEnabled(safeGet(["Main", "ai"], profile, false));
        });
    }, []);

    const [enabled, setEnabled] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    useEffect(() => {
        if(modalVisible){
            setTimeout(() => {
                input.current.focus()
            }, 0);
        }
      }, [modalVisible]);
    const [loading, setLoading] = useState(false);
    const [prompt, setPrompt] = useState('');
    const [threadId, setThreadId] = useState(null);
    const [messages, setMessages] = useState([
        {role:'Insuri',content:t('Hello, I am **Insuri**, your **SISos Ai assistant**. How can I help you? ✨')},
        {role:'Insuri',content:t("Please note - I'm in my beta phase, actively training and improving. Keep an eye on the changelog for new capabilities. Your patience and understanding during this phase are greatly appreciated!")}
    ]);
    
    useEffect(() => {
        const element = document.getElementById('content-container');
        if(element){
            element.scrollTop = element.scrollHeight+100;
        }
    }, [messages]);
   
    const onOk=()=>{
        if(!prompt) return;
        const newMessages=[...messages,{role:'User',content:prompt}];
        setMessages(newMessages);
        //sending request to server
        setLoading(true);
        setPrompt('');
        getProxy('/ai/ask','POST',{query:prompt,threadId:threadId}).then(r=>{
            setLoading(false);
            if(r.ok){
                setThreadId(r.outData.threadId);
                setPrompt('');
                setMessages([...newMessages,{role:'Insuri',content:r.outData.text}]);
                
            }else{
                message.error(r.msg);
            }
        })
    }

    const Sparkles=()=><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
    <path fill-rule="evenodd" d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5ZM18 1.5a.75.75 0 0 1 .728.568l.258 1.036c.236.94.97 1.674 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258c-.94.236-1.674.97-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.625 2.625 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395c-.447.15-.799.5-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395c.447-.15.799-.5.948-.948l.395-1.183A.75.75 0 0 1 16.5 15Z" clip-rule="evenodd" />
  </svg>
  
  
  
    return (
        <div style={{display:enabled?"flex":"none",padding:6}}>
            <Button type='link' shape="circle" style={{border: "none" }}  
            icon={<span style={{color: "white"}}><Sparkles /></span>} onClick={()=>setModalVisible(true)} loading={loading}></Button>
            <Drawer width={600} title={<span style={{fontSize:18}}>✨ {t("Ai")} (Beta)</span>} closable={false} onClose={()=>setModalVisible(false)} visible={modalVisible}>
            <div style={{display:"flex", flexDirection:"column", height:"100%"}}>
                <div style={{overflowY:"auto",flexGrow:1}} id="content-container">
                    {messages.map((m,i)=><Comment key={i} avatar={<Avatar size={"small"} icon={m.role=="User"?<UserOutlined />:<RobotOutlined />}/>} author={m.role} content={
                    <Markdown components={{
                        code({ node, inline, className, children, ...props }) {
                            const match = /language-(\w+)/.exec(className || '');
                            return !inline && match ? (
                                <CodeBlock language={match[1]} value={String(children).replace(/\n$/, '')} />
                                ) : (
                                    <code className={className} {...props}>
                          {children}
                        </code>
                      );
                    },
                }}
                >{m.content}</Markdown>} />)}
                <Skeleton loading={loading} active />
                </div>
                <div style={{display:"block"}}>
                    <Input style={{flex:1}} 
                    value={prompt}
                    ref={input}
                    onPressEnter={onOk}
                    suffix={<Button type='primary' onClick={onOk} icon={<ArrowUpOutlined />} />}
                    placeholder={t("Ask Insuri anything...")} onChange={e=>setPrompt(e.target.value)}></Input>
               
                </div>
            </div>

            </Drawer>
        </div>
    );
};

export default Ai;