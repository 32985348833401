import React, {useEffect, useState} from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Modal, Table, Tabs } from "antd";
import {useTranslation} from "react-i18next";
import CostCenterSelect from "../Accounting/CostCenterSelect";
import CostCenterValueSelect from "../Accounting/CostCenterValueSelect";

const CostCenterManagement = ({form,visible,style}) => {
    const [costCentersVisible,setCostCentersVisible]=useState(false);
    const [loading,setLoading]=useState(false);
    const [selected,setSelected]=useState();
    

    
    const [t]=useTranslation();
    const field=form.getFieldDecorator;
    const values=form.getFieldsValue();
    
    const onSave=(values)=>{
        form.setFieldsValue({CostCenters:[...data,values]})
        setSelected();
    }
    const onOk=()=>{
        form.setFieldsValue({CostCenters:data});
        setCostCentersVisible(false);
    }
    const onDelete=(r)=>{
        const filteredData=data.filter(p=>!(p.costCenterCode===r.costCenterCode&&p.valueCode===r.valueCode));
        console.log("DELETE",r,data,filteredData)
        form.setFieldsValue({CostCenters:filteredData})
    }
    
    const data=values.CostCenters||[];
    
    const CostCenterForm=Form.create()((props)=>{
        const onSave=()=>{
            props.form.validateFields((err, values) => {
                if(err) return;
                props.onSave(values);
            });
        }
        const onCostCenterSelect=(v,opt)=>{
            props.form.setFieldsValue({CostCenter:opt.props.record});
        }
        const onValueSelect=(v,opt)=>{
            props.form.setFieldsValue({value:opt.props.record.name});
        }

        const field=props.form.getFieldDecorator;
        const values=props.form.getFieldsValue();
        return (
            <div>
                    <Button type={"link"} icon={<LegacyIcon type={"left"} />} onClick={()=>setSelected()}>{t("Back")}</Button>
                    <Button type={"link"} icon={<LegacyIcon type={"save"} />} onClick={()=>onSave()}>{t("Save")}</Button>
                {field("id")}
                {field("claimPaymentId")}
                {field("value")}
                {field("CostCenter")}
                    <Form.Item label={t("Cost Center")}>{field("costCenterCode",{rules:[{required:true}]})(<CostCenterSelect onSelect={onCostCenterSelect} />)}</Form.Item>
                    <Form.Item label={t("Cost Center Value")}>{field("valueCode",{rules:[{required:true}]})(<CostCenterValueSelect onSelect={onValueSelect} costCenterCode={values.costCenterCode} />)}</Form.Item>
            </div>
        ); 
    })
    
    return (
        <div style={{...style,display:visible?"block":"none"}}>
            <Button type={"link"} icon={<LegacyIcon type={"deployment-unit"} />} onClick={()=>setCostCentersVisible(true)} style={{paddingLeft:0}}>{t("Cost Centers")}</Button>
            <Modal title={t("Cost Centers")} visible={costCentersVisible} onCancel={()=>setCostCentersVisible(false)} onOk={onOk}>
                {!selected&&<div>
                    <Button type={"link"} icon={<LegacyIcon type={"plus"} />} onClick={()=>setSelected({id:0})} >{t("New")}</Button>
                    <Table dataSource={data}>
                        <Table.Column dataIndex={"CostCenter.name"} title={"Cost Center"} />
                        {/*<Table.Column dataIndex={"costCenterCode"} title={"Cost Center"} />*/}
                        <Table.Column dataIndex={"value"} title={"Value"} />
                        {/*<Table.Column dataIndex={"valueCode"} title={"Value Code"} />*/}
                        <Table.Column key={"actions"} title={"Actions"} render={(v,r)=><div><Button type={"link"} icon={<LegacyIcon type={"delete"} />} onClick={()=>onDelete(r)} /> </div>} />
                    </Table>
                </div>}
                <div style={{display:selected?"block":"none"}}>
                    <CostCenterForm onSave={onSave}/>
                </div>
            </Modal>
        </div>
    );
};

export default CostCenterManagement;