import { FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button, Checkbox, message, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";
import DefaultPage from "../Shared/DefaultPage";
import FiscalDocForm from "./FiscalDocForm";
import {formatDateShort} from "../../Lib/Helpers";
import FiscalDocsGeneratedFilter from "./FiscalDocsGeneratedFilter";
import moment from "moment";
import FiscalDocsGeneratedActions from "./FiscalDocsGeneratedActions";
import PolicyLink from "../Life/PolicyLink";

const FiscalDocsGenerated = (props) => {
    const { t, i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [filterVisible,setFilterVisible]=useState(false);
    const [pagination, setPagination] = useState({ pageSize: 15, total: 0, current:1, showTotal: (total) => `Total ${total} items` });
    const [currentFilter,setCurrentFilter]=useState();
    const [selected,setSelected]=useState();
    
    useEffect(() => {
        if(pagination.current) load(currentFilter);
    }, [pagination.current]);
    
    const load = (filter=null) => {
        setLoading(true);
        exe("RepoFiscalDocGenerated", { operation: "GET",filter:filter,include:["ReceiptType"],
            size: pagination.pageSize,
            page: pagination.current - 1, })
            .then((r) => {
            setLoading(false);
            
            if (r.ok) {
                setData(r.outData);
                setPagination({ ...pagination, total: r.total });
            } else {
                message.error(r.msg);
            }
        });
    };
    const onSearch=values=>{
        console.log(values);
        let filter="";
        if(values.user){
            filter+=` AND [user]='${values.user}'`
        }
        if(values.receiptTypeCode){
            filter+=` AND receiptTypeCode='${values.receiptTypeCode}'`
        }
        if(values.fiscalNumber){
            filter+=` AND fiscalNumber='${values.fiscalNumber}'`
        }
        if(values.created&&values.created.length===2){
            filter+=` AND created between '${values.created[0].format("YYYY-MM-DD")}' and  '${values.created[1].format("YYYY-MM-DD")}'`;
        }
        if(values.policyId){
            filter+=` AND policyId=${values.policyId}`
        }
        if(values.policyIdNumber){
            filter+=` AND policyId=${values.policyIdNumber}`
        }
        if(values.holderId){
            filter+=` AND policyId IN (SELECT id from LifePolicy WHERE holderId=${values.holderId})`
        }
        if(filter) filter="1=1"+filter;
        load(filter);
        setCurrentFilter(filter);
    }
    const onPaginationChange = (currentPagination) => {
        setPagination(currentPagination);
    };
    const onActionOk=()=>{
        load(currentFilter);
        setSelected();
    }
    return (
        <DefaultPage
            title={t("Fiscal Documents Generated")}
            icon="tags"
            extra={
                <div>
                    <Button type="primary" onClick={()=>setFilterVisible(true)} icon={<FilterOutlined />}>
                        {t("Filter")}
                    </Button>
                </div>
            }>
                <Button type="link" onClick={()=>load(currentFilter)} icon={<ReloadOutlined />}>
                    {t("Reload")}
                </Button>
                <Table loading={loading} dataSource={data} style={{marginTop:5}} pagination={pagination} onChange={pag => onPaginationChange(pag)}>
                    <Table.Column title={t("Id")} dataIndex="id" />
                    <Table.Column title={t("Series Id")} dataIndex="fiscalDocId" />
                    <Table.Column title={t("Action")} dataIndex="action" />
                    <Table.Column title={t("Receipt Type")} dataIndex={["ReceiptType","name"]} />
                    <Table.Column title={t("Fiscal Number")} dataIndex="fiscalNumber" />
                    <Table.Column title={t("Policy")} dataIndex="policyId" render={v=>v?<PolicyLink policyId={v} />:null} />
                    <Table.Column title={t("Related")} dataIndex="policyFiscalNumber" />
                    <Table.Column title={t("User")} dataIndex="user" />
                    <Table.Column title={t("Created")} dataIndex="created" />
                    <Table.Column title={t("Released")} dataIndex="releasedDate" render={v=><Checkbox checked={v} /> } />
                    <Table.Column title={t("Reassigned")} dataIndex="reassignedDate" render={v=><Checkbox checked={v} /> } />
                    <Table.Column title={t("Cancelled")} dataIndex="cancelledDate" render={v=><Checkbox checked={v} /> } />
                    <Table.Column title={t("Actions")} key={"actions"} render={(v,r)=><Button type="link" icon={<LegacyIcon type={"edit"} />} onClick={()=>setSelected(r)} />}/>
                  
                </Table>
            <FiscalDocsGeneratedFilter visible={filterVisible} onClose={()=>setFilterVisible(false)} onSearch={onSearch} />
            <FiscalDocsGeneratedActions visible={selected} onCancel={()=>setSelected()} selected={selected} onOk={onActionOk} />
        </DefaultPage>
    );
};

export default FiscalDocsGenerated;
