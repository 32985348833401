import React, {useEffect, useState} from 'react';
import {Form, Input, Button, notification, Typography, Result, message} from 'antd';
import {useTranslation} from "react-i18next";
import {exe, safeGetRaw} from "../../Lib/Dal";
import {getConfigProfile} from "../../Lib/Helpers";
import View from "../Bpm/View";

const Calculators = (props) => {
    const [loading,setLoading]=useState(false);
    const [success,setSuccess]=useState(false);
    const [t]=useTranslation();
    const [viewCode,setViewCode]=useState();


   useEffect(()=>{
       console.log("loading calculators");
       //getting config profile
       setLoading(true);
       exe("GetConfigProfile").then(r=>{
           setLoading(false);
           if(r.ok){
               const viewId=safeGetRaw(["configProfile","SelfService","calculatorsViewId"],r.outData,null);
               if(viewId){
                   //loading view
                   setLoading(true)
                   exe("RepoLiveView",{operation:"GET",filter:"id="+viewId}).then(r=>{
                       setLoading(false);
                       if(r.ok){
                           if(r.total>0) setViewCode(r.outData[0].code);
                           else message.error("View not found");
                       }else{
                           message.error(r.msg)
                       }
               })}
           }
       })
   },[])

    return (
            <View code={viewCode} />
    );
};

export default Calculators;
