import React, { Component } from "react";
import { Dropdown, Button, Icon, Avatar } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export default class User extends Component {
  render() {
    return (
      <div>
        {/* <span style={{ color: "white" }}>{this.props.userName}</span> */}
        <Dropdown overlay={this.props.menu}>
          <Button size="large" type="primary" shape="circle" style={{ backgroundColor: "transparent", border: "none" }}>
            {/* <Icon type="user" style={{ fontSize: 20 }} /> */}
            <FontAwesomeIcon icon={faUser} style={{ fontSize: 18, color: "white", cursor: "pointer" }} />
          </Button>
          {/* <Avatar size="large" icon="user" style={{ cursor: "pointer" }}>
            {this.props.userName}
          </Avatar> */}
        </Dropdown>
        {/* <b style={{marginLeft:5,marginRight:15}}>{this.props.userName}</b> */}
      </div>
    );
  }
}
