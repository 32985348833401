import { Checkbox, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUpdateEffect from "../../Lib/Helpers";

const IndexationPlan = (props) => {
  const [t] = useTranslation();
  const [data, setData] = useState([]);
  const coverages = (props.coverages || []).filter((p) => !p.ignoreIndexation);
  const indexationPeriod = props.indexationPeriod;

  useEffect(() => {
    if (props.value) {
      setData(JSON.parse(props.value));
    } else {
      props.onChange(JSON.stringify(buildPlan));
    }
  }, [props.value]);

  // useUpdateEffect(() => {
  //   props.onChange(JSON.stringify(buildPlan));
  // }, [props.indexationPeriod]);

  const buildPlan =()=> {
    return new Array(props.duration || 0).fill().map((p, index) => {
      const obj = {};
      coverages.forEach((cov) => {
        obj[cov.code] = index + 1 <= indexationPeriod ? true : false;
      });
      obj.year = index + 1;
      return obj;
    });
  }

  const onCheckChanged = (value, covCode, record) => {
    record[covCode] = value;
    const newData = [...data.filter((p) => p.year !== record.year), record];
    setData(newData.sort((a, b) => a.year - b.year));
    props.onChange(JSON.stringify(data));
  };

  return (
    <div>
      <Table dataSource={data} rowKey="year">
        <Table.Column title={t("Year")} dataIndex="year" />
        {coverages.map((cover) => (
          <Table.Column
            title={cover.code}
            dataIndex={cover.code}
            render={(v, r) => <Checkbox disabled={props.disabled} checked={v} onChange={(v) => onCheckChanged(v.target.checked, cover.code, r)} />}
          />
        ))}
      </Table>
    </div>
  );
};

export default IndexationPlan;
