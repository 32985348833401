import { Form, Tabs, InputNumber,Modal, message, Col,Row,Input, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Currency from '../Shared/Currency';
import DatePickerW from '../Shared/DatePickerW';
import Money from '../Shared/Money';
import { exe } from '../../Lib/Dal';
import TabPane from 'antd/lib/tabs/TabPane';
import PensionSchemeSelect from './PensionSchemeSelect';
import PensionMemberSelect from './PensionMemberSelect';
import ContributionTypeSelect from './ContributionTypeSelect';
import ContributionStatusSelect from './ContributionStatusSelect';
import ContributionPayPeriodSelect from './ContributionPayPeriodSelect';
import { CheckCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Space } from 'antd';

const ContributionLine = (props) => {
    const [t]=useTranslation();
    const [form]=Form.useForm();
    const [loading,setLoading]=useState(false);

    useEffect(()=>{
        if(props.record&&props.record.id){
            form.setFieldsValue(props.record);
        }else form.resetFields();
        if(props.pensionId) form.setFieldsValue({pensionSchemeId:+props.pensionId});
    },[props.record])

    const onOk=(close=true)=>{
        //validating form
        form.validateFields().then(values=>{
            const operation=values.id?"UPDATE":"ADD";
            setLoading(true);
            exe("RepoContributionLine",{operation:operation,entity:values}).then(r=>{
                setLoading(false);
                if(r.ok){
                    message.success(r.msg);
                    if(close) props.onOk(values);
                }else message.error(r.msg);
        }
        )
    }).catch(info=>{
        console.log(info);
    })
    }
    const onValidate=()=>{
        const contributionId=form.getFieldValue("id");
        setLoading(true);
        exe("ValidateContribution",{contributionId:contributionId}).then(r=>{
            setLoading(false);
            if(r.ok){
                message.success(r.msg);
                props.onOk();
            }else message.error(r.msg);
        })
    }
    const onSave=()=>onOk(false)



    const currency = Form.useWatch('currency', form);
    const pensionSchemeId = Form.useWatch('pensionSchemeId', form);

    return <Modal title={t("Contribution Line")} visible={props.visible} okText={t("Save")} cancelText={t("Cancel")} 
    onCancel={props.onCancel} onOk={onOk} okButtonProps={{loading:loading}} width={800}>
        <Form layout="vertical" form={form}>
            <Space>
                <Button type="link" style={{padding:0}} icon={<CheckCircleOutlined />} onClick={onValidate}>{t("Validate")}</Button>
                <Button type="link" style={{padding:0}} icon={<SaveOutlined />} onClick={onSave}>{t("Save")}</Button>
            </Space>
            <Tabs>
                <TabPane tab={t("General")} key="general">
                    <Row gutter={16}>
                        <Col>
                            <Form.Item name="id" label={t("Id")} ><InputNumber disabled /></Form.Item>
                            <Form.Item name="status" label={t("Status")} initialValue={0}><ContributionStatusSelect /></Form.Item>
                            <Form.Item name="currency" label={t("Currency")} rules={[{required:true}]}><Currency /></Form.Item>
                            <Form.Item name="date" label={t("Date")}><DatePickerW disabled /></Form.Item>

                        </Col>
                        <Col>
                            <Form.Item name="pensionSchemeId" label={t("Pension Scheme")} rules={[{required:true}]}><PensionSchemeSelect /></Form.Item>
                            <Form.Item name="pensionMemberId" label={t("Member")} rules={[{required:true}]}><PensionMemberSelect disabled={!pensionSchemeId} pensionSchemeId={pensionSchemeId} /></Form.Item>
                            <Form.Item name="contributionType" label={t("Contribution Type")}><ContributionTypeSelect/></Form.Item>
                            <Form.Item name="payPeriod" label={t("Pay Period")}><ContributionPayPeriodSelect /></Form.Item>

                        </Col>
                        <Col>
                            <Form.Item name="year" label={t("Year")} rules={[{required:true}]} initialValue={new Date().getFullYear()}><InputNumber /></Form.Item>
                            <Form.Item name="month" label={t("Month")} rules={[{required:true}]} initialValue={new Date().getMonth()+1}><InputNumber /></Form.Item>
                            <Form.Item name="day" label={t("Day")} rules={[{required:true}]} ><InputNumber /></Form.Item>

                           
                        </Col>
                        <Col>
                            <Form.Item name="basicSalary" label={t("Basic Salary")}><Money currency={currency} /></Form.Item>
                            <Form.Item name="houseAllowance" label={t("House Allowance")}><Money currency={currency} /></Form.Item>
                            <Form.Item name="grossSalary" label={t("Gross Salary")}><Money currency={currency} /></Form.Item>
                        </Col>

                    </Row>
                </TabPane>
                <TabPane tab={t("Normal Contributions")} key="normal">
                    <Form.Item name="ee" label={t("Employee Contribution")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="er" label={t("Employer Contribution")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="avcEe" label={t("Employee Additional Voluntary Contribution")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="avcEr" label={t("Employer Additional Voluntary Contribution")}><Money currency={currency} /></Form.Item>
                </TabPane>
                <TabPane tab={t("Statutory Contributions")} key="statutory">
                    <Form.Item name="statutoryEe1" label={t("Employee Statutory Contribution Tier 1")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="statutoryEr1" label={t("Employer Statutory Contribution Tier 1")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="statutoryEe2" label={t("Employee Statutory Contribution Tier 2")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="statutoryEr2" label={t("Employer Statutory Contribution Tier 2")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="statutoryEe3" label={t("Employee Statutory Contribution Tier 3")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="statutoryEr3" label={t("Employer Statutory Contribution Tier 3")}><Money currency={currency} /></Form.Item>
                </TabPane>
                <TabPane tab={t("Post Retirement")} key="postRetirement">
                    <Form.Item name="medicalEe" label={t("Employee Post Retirement Medical Contribution")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="medicalEr" label={t("Employer Post Retirement Medical Contribution")}><Money currency={currency} /></Form.Item>
                </TabPane>
                <TabPane tab={t("Insurance")} key="insurance">
                    <Form.Item name="groupLifeEr" label={t("Employer Group Life Contribution")}><Money currency={currency} /></Form.Item>
                    <Form.Item name="groupLastExpenseEr" label={t("Employer Group Last Expenses Contribution")}><Money currency={currency} /></Form.Item>
                </TabPane>
                <TabPane tab={t("Validation")} key="validation">
                    <Form.Item name="statusMsg" label={t("Status Message")}><Input.TextArea /></Form.Item>
                </TabPane>
            </Tabs>




        </Form>
        </Modal>
}
export default ContributionLine;