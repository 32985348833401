import { message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { exe } from "../../Lib/Dal";

const PaymentTypeSelect = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    load();
  }, []);
  
  useEffect(()=>{
    //this effect triggers onSelect in parent when data is set by form.setFieldsValue since onChange nor onSelect are triggered
      const selected=data.find(p=>p.code==props.value);
      props.onSelect && props.onSelect(selected);
  },[props.triggerSelect,data]);
  

  const load = () => {
    setLoading(true);
    exe("RepoPaymentTypeCatalog", { operation: "GET" }).then((r) => {
      setLoading(false);
      if (r.ok) setData(r.outData);
      else message.error(r.msg);
    });
  };
  const onSelect = (v, opt) => {
    props.onSelect && props.onSelect(opt.props.record);
  };
  return (
    <Select
      value={props.value}
      onChange={(v) => props.onChange(v)}
      onSelect={onSelect}
      loading={loading}
      style={{ width: "100%", ...props.style }}
      placeholder={t("Please select...")}
      disabled={props.disabled}
      allowClear>
      {data.map((p) => (
        <Select.Option value={p.code} key={p.code} record={p}>
          {t(p.name)}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentTypeSelect;