import React from "react";
import { Breadcrumb, Card, Timeline, Table } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import { withTranslation } from "react-i18next";
class Historia extends React.Component {
  state = {
    data: [],
  };
  componentDidMount() {
    if (this.props.actividad) {
      this.setState({ data: this.props.actividad.Pasos });
    }
  }
  componentDidUpdate(pProps, pState) {
    console.log("did update");
    const anterior = pProps.actividad && pProps.actividad.Pasos.length;
    const ahora = this.props.actividad && this.props.actividad.Pasos.length;

    if (anterior != ahora) {
      this.setState({ data: this.props.actividad.Pasos });
    }
  }
  render() {
    const { t, i18n } = this.props;
    return (
      <div>
        <Table dataSource={this.state.data} rowKey="id">
          <Table.Column title={t("State")} dataIndex="estado" />
          <Table.Column title={t("Entity State")} dataIndex="entityState" />
          <Table.Column title={t("State Reason")} dataIndex="entityStateReason" />
          <Table.Column title={t("Status")} dataIndex="estado" />
          <Table.Column title={t("Date")} dataIndex="fecha" render={(v) => moment(v).format("L LTS")} />
          <Table.Column title={t("User")} dataIndex="user" />
          <Table.Column title={t("Group")} dataIndex={["Group","name"]} />
          <Table.Column title={t("SLA(h)")} dataIndex="sla" />
          <Table.Column title={t("Time")} dataIndex="tiempo" render={(v) => moment.duration(parseFloat(v), "hours").humanize()} />
          <Table.Column title={t("Action")} dataIndex="evento" />
        </Table>
      </div>
    );
  }
}
export default withTranslation()(Historia);
