import React, { useEffect, useState } from "react";
import DefaultPage from "../Shared/DefaultPage";
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Table,
  Tabs,
  Tag,
  Input,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Button,
  Switch,
  message,
  Divider,
} from "antd";

import { exe } from "../../Lib/Dal";
import ButtonGroup from "antd/lib/button/button-group";
import Filter from "../Health/ClaimListFilter";
import { formatDate } from "../../Lib/Helpers";
import { useTranslation } from "react-i18next";
import ComContractListFilter from "./ComContractListFilter";

const ComContractList = (props) => {
  const [t, i18n] = useTranslation();
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [loading,setLoading]=useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    exe("RepoComContract", {
      operation: "GET",
      include: ["Contact"],
    }).then((r) => {
      if (r.ok) {
        setData(r.outData.reverse());
      } else {
        message.error(r.msg);
      }
    });
  };
  const onSearch=values=>{
    const queries = [];
    if (values.id) queries.push(`id=${values.id}`);
    if (values.active!==undefined) queries.push(`active=${values.active}`);
    const filter= queries.join(" AND ");
    setLoading(true);
    exe("RepoComContract",{operation:"GET",include: ["Contact"],filter:filter}).then(r=>{
      setLoading(false);
      if(r.ok){
        setData(r.outData)
      }else message.error(r.msg);
    })
  }
  // search by column
  let searchInput;
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`${t("Search")} ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90, marginRight: 8 }}>
            {t("Search")}
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            {t("Reset")}
          </Button>
        </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined, fontSize: 15 }} />,
    onFilter: (value, record) => record[dataIndex]&&record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    // render: (text) =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }} searchWords={[searchText]} autoEscape textToHighlight={text.toString()} />
    //   ) : (
    //     text
    //   ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // table search end

  return (
    <div>
      <DefaultPage
        routes={{
          routes: [
            { breadcrumbName: t("Home"), path: "/" },
            { breadcrumbName: t("Commission Contracts"), path: "ComContracts" },
            { breadcrumbName: t("Contract"), path: "" },
          ],
        }}
        title={t("Commission Contracts")}
        icon="euro"
        extra={
          <div>
            <ButtonGroup>
              <Button type="primary" onClick={() => {setShowFilter(true)}} icon={<SearchOutlined />} >
                {t("Filter")}
              </Button>
            </ButtonGroup>
          </div>
        }>
        <Table dataSource={data} rowKey="id">
          <Table.Column title={t("Id")} dataIndex="id"  {...getColumnSearchProps("id")} />
          <Table.Column title={t("Name")} dataIndex="name" render={(v, r) => <a href={"#/comcontract/" + r.id}>{r.name}</a>}  {...getColumnSearchProps("name")} />
          <Table.Column title={t("Contact")} dataIndex="Contact" render={(v, r) => v.FullName} />
          <Table.Column title={t("Start")} dataIndex="start" render={(v, r) => formatDate(v)} />
          <Table.Column title={t("Status")} dataIndex="active" render={(v, r) => <Checkbox checked={v} />} />
          <Table.Column
            title={t("Action")}
            render={(text, record) => (
              <span>
                <a href={"#/comcontract/" + record.id}>{t("Open")}</a>
                <Divider type="vertical" />
                <a href={"#/contact/" + record.contactId}>
                  <UserOutlined></UserOutlined>
                </a>
              </span>
            )}
          />
        </Table>
        <ComContractListFilter
          visible={showFilter}
          onClose={() => {
            setShowFilter(false);
          }}
          onSearch={(d) => onSearch(d)}
        />
      </DefaultPage>
    </div>
  );
};

export default ComContractList;
