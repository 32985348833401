import "luckysheet/dist/plugins/css/pluginsCss.css";
import "luckysheet/dist/plugins/plugins.css";
import "luckysheet/dist/css/luckysheet.css";
import "luckysheet/dist/assets/iconfont/iconfont.css";

import $ from "jquery";
//import * as plug from "luckysheet/dist/plugins/js/plugin.js";
import * as luckysheet from "luckysheet/dist/luckysheet.umd.js";
import React, { useEffect } from "react";
require("jquery-mousewheel");
require("spectrum-colorpicker");

const CalcSheetGrid = React.memo(
  (props) => {
    useEffect(() => {
      $(function () {
        //Configuration item
        var options = {
          showinfobar: false,
          title: "Actuarial Sheet",
          container: "luckysheet",
          lang: "en",
        };
        luckysheet.create(options);
        window.luckysheet = luckysheet;
      }, []);
    });
    return <div id="luckysheet" style={{ margin: 0, padding: 0, width: "100%", height: "100%", top: 0, left: 0 }}></div>;
  },
  (prevProps, nextProps) => true
);

export default CalcSheetGrid;
