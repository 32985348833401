import React, { useEffect, useState } from "react";
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Drawer,
  Input,
  Table,
  message,
  Button,
  Select,
  Radio,
  Row,
  Col,
  InputNumber,
  DatePicker,
} from "antd";
import { exe } from "Lib/Dal";
import PolicySelect from "../Health/PolicySelect";
import Compare from "../Shared/Compare";
import Currency from "../Shared/Currency";
import { useTranslation } from "react-i18next";
import useUpdateEffect from "../../Lib/Helpers";
import moment from "moment";
import SimpleContactSelect from "../Contact/SimpleContactSelect";

const Search = Input.Search;
const { Column, ColumnGroup } = Table;

const CoCessionsFilter = (props) => {
  const [t, i18n] = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.location.hash.includes("?policyId=")) {
      const policy = window.location.hash.split("?policyId=")[1];
      props.form.setFieldsValue({ policyId: policy });
      onSearch();
    } else {
      //called from cessions
      props.form.setFieldsValue({ date: new moment() });
      onSearch();
    }
  }, []);

  useUpdateEffect(() => {
    onSearch();
  }, [props.doReload]);

  const onSearch = () => {
    props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        let queries = [];
        if (values.lifepolicyId) queries.push(`lifepolicyId=${values.lifepolicyId}`);
        if (values.liquidationId) queries.push(`liquidationId=${values.liquidationId}`);
        if (values.allocationId) queries.push(`allocationId=${values.allocationId}`);
        if (values.policyId) queries.push(`lifepolicyId=${values.policyId}`);
        if (values.id) queries.push(`id=${values.id}`);
        if (values.currency) queries.push(`currency='${values.currency}'`);
        if (values.sumInsured) queries.push(`sumInsured${values.sumInsured}`);
        if (values.date) queries.push(`YEAR(created)=${values.date.year()} AND MONTH(created)=${values.date.month() + 1}`);

        if (values.range) {
          queries.push(`created between '${values.range[0].format("YYYY-MM-DD")}' and  '${values.range[1].format("YYYY-MM-DD")}'`);
        }

        if (values.participantId) {
          queries.push(`contactId=${values.participantId}`);
        }

        const filterQuery = queries.join(" AND ");
        console.log(queries, filterQuery);

        exe("RepoCoCession", { operation: "GET", filter: filterQuery,include:["Coverage","Broker"] }).then((r) => {
          if (r.ok) {
            props.onSearch(r.outData);
          } else {
            message.error(r.msg);
          }
        });
      }
    });
  };
  const { getFieldDecorator } = props.form;
  return (
    <div>
      <Drawer title={t("Cession Search")} placement="right" width={512} onClose={props.onClose} visible={props.visible}>
        <div style={{ marginBottom: "15px", marginTop: "-15px" }}>
          <Button icon={<UndoOutlined />} size="small" onClick={() => props.form.resetFields()}>
            {t("Reset")}
          </Button>
        </div>

        <Form layout="vertical">
          <Form.Item label={t("Issuance Period")}>{getFieldDecorator("date")(<DatePicker.MonthPicker />)}</Form.Item>
          <Form.Item label={t("Issuance Range")}>{getFieldDecorator("range")(<DatePicker.RangePicker />)}</Form.Item>
          <Form.Item label={t("Policy")}>{getFieldDecorator("lifepolicyId")(<PolicySelect />)}</Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Policy Id")} style={{ marginRight: 5 }}>
              {getFieldDecorator("policyId")(<InputNumber />)}
            </Form.Item>

            <Form.Item label={t("Cession Id")}>{getFieldDecorator("id")(<InputNumber />)}</Form.Item>
          </div>
          <Form.Item label={t("Currency")}>{getFieldDecorator("currency")(<Currency />)}</Form.Item>
          <Form.Item label={t("Sum Insured")}>{getFieldDecorator("sumInsured")(<Compare />)}</Form.Item>
          <Form.Item label={t("Coinsurer")}>{getFieldDecorator("participantId")(<SimpleContactSelect />)}</Form.Item>
          <div style={{ display: "flex" }}>
            <Form.Item label={t("Liquidation Id")} style={{ marginRight: 5 }}>{getFieldDecorator("liquidationId")(<InputNumber />)}</Form.Item>
            <Form.Item label={t("Allocation Id")} style={{ marginRight: 5 }}>{getFieldDecorator("allocationId")(<InputNumber />)}</Form.Item>
            
          </div>
        </Form>
        <Button type="primary" icon={<SearchOutlined />} onClick={() => onSearch()}>
          {t("Search")}
        </Button>
      </Drawer>
    </div>
  );
};

export default Form.create()(CoCessionsFilter);
