import { Select } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { round2 } from "../../Lib/Helpers";

const ReAdjustmentSelect = (props) => {
  const [t] = useTranslation();
  const options = props.options || [];

  const onChangeYesNo = (v) => {
    if (v) props.onChange(options[0]);
    else props.onChange(null);
  };
  return (
    <div style={{ display: "flex" }}>
      <Select value={props.value ? true : false} onChange={onChangeYesNo} style={{ marginRight: 5, flex: 1 }} disabled={props.disabled}>
        <Select.Option value={false}>{t("No")}</Select.Option>
        <Select.Option value={true}>{t("Yes")}</Select.Option>
      </Select>
      <Select value={props.value} disabled={props.disabled} onChange={(v) => props.onChange(v)} style={{ flex: 1, display: props.value ? "block" : "none" }}>
        {options.map((p) => (
          <Select.Option value={p}>{round2(p * 100)}%</Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default ReAdjustmentSelect;
