import PaymentMethods from "../Life/PaymentMethods";
import Money from "../Shared/Money";
import Currency from "../Shared/Currency";
import { DollarOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {Button, InputNumber, Tooltip} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

const PaymentLine=(props)=>{
    const [t]=useTranslation();
    
    return (
        <div key={props.index}>
        <div style={{display:"flex",marginTop:3}}>
            <PaymentMethods module="INCOME" style={{flex:1,marginRight:3}} onChange={(v,r)=>props.onChangeField("paymentMethod",v,props.index,r.formId,r.name)} value={props.value.paymentMethod} />
            <Money style={{flex:1,marginRight:3}} onChange={v=>props.onChangeField("amount",v,props.index)} value={props.value.amount} min={0} currency={props.currency} disabled={props.value.conversion}/>
        </div>
        <div style={{display:props.value.conversion?"flex":"none",marginTop:3}}>
            <Currency style={{flex:1,marginRight:3}} onChange={v=>props.onChangeField("currency",v,props.index)} value={props.value.currency} excluded={[props.currency]} />
            <Money style={{flex:1,marginRight:3}} onChange={v=>props.onChangeField("amountEx",v,props.index)} value={props.value.amountEx} min={0} currency={props.value.currency} />
            <InputNumber style={{flex:1,marginRight:3}} onChange={v=>props.onChangeField("exchangeRate",v,props.index)} value={props.value.exchangeRate} min={0} disabled placeholder={t("Exchange Rate")} />
        </div>
            <Button icon={<DollarOutlined />} type="link" onClick={v=>props.onChangeField("conversion",!props.value.conversion,props.index)}  />
            <Button icon={<MinusCircleOutlined />} type="link" onClick={()=>props.onRemoveLine()} disabled={props.index===0} />
            <Button icon={<PlusCircleOutlined />} type="link" onClick={props.onAddLine}  />
    </div>
    );}
export default PaymentLine;