import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import { CaretRightOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Modal, Table, Collapse, Row, Col, Checkbox, message } from "antd";
import Spreadsheet from "../Shared/Spreadsheet";
import CoverageCombo from "./CoverageCombo";
import {exe} from "../../Lib/Dal";

const BenefitEditor = ({visible,onOk,onCancel,coverages=[],groups=[],policy}) => {
    
    const [t]=useTranslation();
    const [benefits,setBenefits]=useState([]);
    const [spreadsheet,setSpreadsheet]=useState();
    const [filterByCoverage,setFilterByCoverage]=useState();
    const [filterByBenefitName,setFilterByBenefitName]=useState();
    const [filterByBenefitCode,setFilterByBenefitCode]=useState();
    const [applyToCertificates,setApplyToCertificates]=useState(false);
    const [loading,setLoading]=useState(false);
    
    useEffect(()=>{
        if(visible) {
            loadBenefits();
            setFilterByCoverage();
            setFilterByBenefitName();
            setFilterByBenefitCode();
        }
    },[visible])
    
    useEffect(()=>{
        //filtering
        let allData=coverages.flatMap(p=>p.Benefits);
        if(filterByCoverage) allData=allData.filter(p=>p.coverageCode===filterByCoverage);
        if(filterByBenefitCode) allData=allData.filter(p=>p.code.includes(filterByBenefitCode));
        if(filterByBenefitName) allData=allData.filter(p=>p.name.includes(filterByBenefitName));
        setBenefits(allData);
    },[filterByCoverage,filterByBenefitCode,filterByBenefitName])
    
    const loadBenefits=()=>{
        setBenefits(coverages.flatMap(p=>p.Benefits));
    }
    const parseDataToGrid=benefits.map(p=>[
        p.coverageCode,//0
        p.code,//1
        p.name,//2
        p.covered*100,//3
        p.limit,//4
        p.aLimit,//5
        p.group,//6
        p.group2]);//7
    
    const onOkInternal=()=>{
        const data=spreadsheet.getData();
        const currentBenefits=benefits;
        for (let i = 0; i <data.length ; i++) {
            if(!data[i][0]||!data[i][1]||!data[i][2]) continue; //ignoring, missing columns
            if(data[i][3]!==benefits[i].covered/100) currentBenefits[i].covered=data[i][3]/100;
            if(data[i][4]!==benefits[i].limit) currentBenefits[i].limit=data[i][4];
            if(data[i][5]!==benefits[i].aLimit) currentBenefits[i].aLimit=data[i][5];
            if(data[i][6]!==benefits[i].group) currentBenefits[i].group=data[i][6];
            if(data[i][7]!==benefits[i].group2) currentBenefits[i].group2=data[i][7];
        }
        
        coverages.forEach(cov=>{
            cov.Benefits.forEach(ben=>{
                const updated=currentBenefits.find(p=>p.code===ben.code);
                if(updated) ben=updated;
            })
        })
        //onOk(coverages);
        
        setLoading(true);
        exe("SetPolicyBenefits",{policyId:policy.id,coverages:coverages,updateCertificates:applyToCertificates}).then(r=>{
            setLoading(false);
            if(r.ok) {
                message.success(r.msg);
                onOk(coverages);
            }else message.error(r.msg);
        });
    }
    const setTableStyle=(records,instance)=>{
        const styleObj={};
        for (let i = 0; i < records; i++) {
            styleObj["A"+(i+1)]="text-align:left";
            styleObj["B"+(i+1)]="font-weight:bold;text-align:left";
            styleObj["C"+(i+1)]="text-align:left";
        }
        instance.setStyle(styleObj);
        instance.options.autoIncrement=false;
        instance.hideIndex();
    }
    const onCoverageChange=(v)=>{
        setFilterByCoverage(v);
    }
    const onBenefitCodeChange=e=>{
        const v=e.target.value;
        setFilterByBenefitCode(v);
    }
    const onBenefitNameChange=e=>{
        const v=e.target.value;
        setFilterByBenefitName(v);
    }
    const customPanelStyle = {
        backgroundColor:"white",
        borderRadius: 4,
        marginBottom: 24,
        border: 0,
        overflow: 'hidden',
    };
    return (
        <div>
            <Modal title={t("Benefit Editor")} width={900} visible={visible} onOk={onOkInternal} onCancel={onCancel} okButtonProps={{loading:loading}}>
                <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}>
                    <Collapse.Panel header="Filters" key="filter" style={customPanelStyle}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item label={t("Coverage")}>
                                    <CoverageCombo value={filterByCoverage} coverages={coverages} onChange={onCoverageChange} style={{width:"100%"}} />
                                </Form.Item>   
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t("Benefit Code")}>
                                    <Input value={filterByBenefitCode} onChange={onBenefitCodeChange} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label={t("Benefit Name")}>
                                    <Input value={filterByBenefitName} onChange={onBenefitNameChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                       
                    </Collapse.Panel>
                </Collapse>
                        {policy&&policy.policyType==="G"&&<Checkbox style={{marginBottom:5}} value={applyToCertificates} onChange={e=>setApplyToCertificates(e.target.checked)}>{t("Apply changes to certificates")}</Checkbox>}
                        <Spreadsheet
                            instance={(t) => {window.spreadsheet=t;setSpreadsheet(t); setTableStyle(benefits.length,t);}}
                            data={parseDataToGrid}
                            columns={[
                                { title: t("Coverage"), width: 100,readOnly:true },
                                { title: t("Code"), width: 50,readOnly:true },
                                { title: t("Name"), width: 100,readOnly:true },
                                { title: t("Covered %"), width: 100,type: 'numeric',mask:'#.00' },
                                { title: t("Limit"), width: 100,type: 'numeric',mask:'#.00', decimal:'.' },
                                { title: t("Limit Annual"), width: 100,type: 'numeric',mask:'#.00', decimal:'.' },
                                { title: t("Group"), width: 100,type: 'dropdown', source:[...groups.map(p=>p.code),''] },
                                { title: t("Group 2"), width: 100,type: 'dropdown', source:[...groups.map(p=>p.code),''] },
                            ]}
                        />
              
            
               
                {/*<Table dataSource={benefits}>*/}
                {/*    <Table.Column title={t("Coverage")} dataIndex={"coverageCode"} />*/}
                {/*    <Table.Column title={t("Code")} dataIndex={"code"} />*/}
                {/*    <Table.Column title={t("Name")} dataIndex={"name"} />*/}
                {/*    <Table.Column title={t("Event Limit")} dataIndex={"limit"} />*/}
                {/*    <Table.Column title={t("Annual Limit")} dataIndex={"aLimit"} />*/}
                {/*    <Table.Column title={t("Group")} dataIndex={"group"} />*/}
                {/*    <Table.Column title={t("Group 2")} dataIndex={"group2"} />*/}
                {/*</Table>*/}
            </Modal>
        </div>
    );
};

export default BenefitEditor;